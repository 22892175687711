import { connect } from 'react-redux';
import View from './view';
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { saveStepData, setStepData } from '../../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { OPPORTUNITY_CREATION_STEPS } from '../../../constants';


const mapStateToProps = state => {
    const user = state.main.user && state.main.user.profile;
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData:
            state.main.ui.view.opportunityCreationFlow.stepData[
                OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION
            ],
        professions: state.main.ui.global.statics.userTypes || [],
        //opPrivacy: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP],
        opPrivacy: 'REFERRAL_PAST',
        candidates: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE],
        user: user,
        uid: state.main.user && state.main.user.uid,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION, data)),
        saveStepData: data => dispatch(saveStepData(data)),
    };
};

const ViewWithTransalation = withTranslation("sendReferral")(View);

const ReferralPastCompleteInformation = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTheme(ViewWithTransalation))
);

export { ReferralPastCompleteInformation }