import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainText, StepWrapper, selectStyle, Button, ButtonContainer } from "../../components";
import { OpportunityTypesOptions, typeOfOpportunities, opportunityTime } from "../../constants";
import Select from 'react-select';
import { Icon } from "../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import { Flex } from "rebass";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CheckBox from "../../../../atoms/shared/Inputs/Checkbox";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.mobileDevice ? '24px' : '32px'};
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5em;
    cursor: pointer;
`;

const ExtendedButtonContainer = styled(ButtonContainer)`
    justify-content: space-between;
    align-items: center;
`;

const ClickableLabel = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #057AFF;
    margin-left: 6px;
`;

const BottomLabel = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
`;

const OpportunityType = ({ setStepData, stepData, t, stepKey, goToPastJob }) => {

    const [selectedType, setSelectedType] = useState(stepData);
    const  [ options, setOptions ] = useState( stepData ? stepData : {
         type: '',
         opTime: '',
    })

   useEffect(() => {
      
   }, [options])

    const onClickHandler = () => {
        setStepData({
            ...options 
           })
        //setStepData(selectedType);
    };

    

    const info = t("tooltipInfo")
    const letsOpenSecondQuestion = options.type === 'REFERRAL' || options.type === 'JOB'
//    console.log('CHARGING STATE', {  options, letsOpenSecondQuestion})
  
   
    return (
        <StepWrapper>
            <Container>
               
                <SelectContainer>
                <Wrapper>
                    <MainText>What type of opportunity would you like to create?</MainText>
                    <IconContainer>
                        <Icon
                            icon="info"
                            size="md"
                            color="#057AFF"
                            data-tip
                            data-for={'Op-info'}
                        />
                    </IconContainer>
                </Wrapper>
                  {/*   <Select
                        options={OpportunityTypesOptions}
                        onChange={setSelectedType}
                        styles={selectStyle}
                        value={selectedType}
                        isClearable
                        placeholder="Select..."
                    /> */}
                    {typeOfOpportunities.map( ({  type, label, value }) => (
                         <Flex>
                            <CheckBox
                                inverted
                                round
                                // disabled={!!formValues.notifyToClient}
                                label={label}
                                onChange={() => setOptions({...options, type,  value})}
                                checkBoxColor={'#057AFF'}
                                checked={type === options.type}
                            />
                       </Flex>
                    ))}
   
                </SelectContainer>
                {letsOpenSecondQuestion && 
                        <SelectContainer>
                        <Wrapper>
                            <MainText>Is this a new op or one that was created in the past?</MainText>
                            <IconContainer>
                            {/*    <Icon
                                    icon="info"
                                    size="md"
                                    color="#057AFF"
                                    data-tip
                                    data-for={'Op-info'}
                                /> */}
                            </IconContainer>
                            
                        </Wrapper>
                            {opportunityTime.map(({ opTime, label }) => (
                                <Flex>
                                    <CheckBox
                                            inverted
                                            round
                                            // disabled={!!formValues.notifyToClient}
                                            label={label}
                                            onChange={() => setOptions({...options, opTime })}
                                            checkBoxColor={'#057AFF'}
                                            checked={opTime === options.opTime}
                                    />
                                </Flex>
                            ))}
                        </SelectContainer>
                 }  
               
                <ExtendedButtonContainer>
                     <BottomLabel>Would you like to create a recommendation?
                        <Link to={'/create-recommendation'} >
                             <ClickableLabel onClick={() => {}} >Click Here</ClickableLabel>
                        </Link>
                     </BottomLabel>
                    <Flex>
                    <Button
                        onClick={(options.type === 'REFERRAL' || options.type === 'JOB' ) && (options.opTime === 'CURRENT' || options.opTime === 'PAST') ?
                         onClickHandler : {}}
                        //disabled={!selectedType}
                    >
                        Next
                    </Button>
                </Flex>
                </ExtendedButtonContainer>
                
            </Container>
            <ReactTooltip
                id={'Op-info'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
         
            >
                <div dangerouslySetInnerHTML={{ __html: info }} />
            </ReactTooltip>
        </StepWrapper>
    );
};

export default OpportunityType;
