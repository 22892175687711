import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PeopleSearchBar from "./view";
import {
    peopleSearchBar,
    clearPeopleSearchBar
} from "../../../../../../redux/actions/creators/search";
import { cancelContactError } from "../../../../../../redux/actions/creators/opportunityCreationFlow";

const mapStateToProps = state => {
    const { contacts, results } = state.main.ui.global.peopleSearchBar;
    
    const currentUser =
        state.main.user && state.main.user.profile
            ? state.main.user.profile
            : {};

    return {
        contacts,
        searchResults: results.filter(u => u.uid !== currentUser.uid),
        searchLoading: state.main.ui.global.peopleSearchBar.isLoading,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            peopleSearchBar,
            clearPeopleSearchBar,
            cancelContactError
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PeopleSearchBar);
