import {
    CONTACTS_USER_LIST_FETCH_USERS,
    CONTACTS_USER_LIST_FETCH_USERS_SUCC,
    CONTACTS_USER_LIST_FETCH_USERS_FAIL,
    CONTACTS_SET_CONTACT_UIDS_LIST,
    CONTACTS_CREATE_CONTACT,
    CONTACTS_CREATE_CONTACT_SUCCCESS,
    CONTACTS_CREATE_CONTACT_FAIL,
} from '../../constants';
import Firebase from '../../../../libs/Firebase'
import { handleError } from '../modal'

//💩
const fetchContactList = (uidsList) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        dispatch({
            type: CONTACTS_USER_LIST_FETCH_USERS
        });
        try {
            const uidsArray = Array.isArray(uidsList) ? uidsList : Firebase.flatArrayFromFirebaseList(uidsList);
            let data = await firebase.fetchUserList(uidsArray);
          
            //TODO: Enjoy your life. 👽
            return dispatch({
                type: CONTACTS_USER_LIST_FETCH_USERS_SUCC,
                payload: data
            });
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: CONTACTS_USER_LIST_FETCH_USERS_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    }
};
const setContactUIDsList = contacts => {
    return {
        type: CONTACTS_SET_CONTACT_UIDS_LIST,
        payload: contacts
    }
};

// Not sure if this goes here but it's a good place to start
const createContact = (contact) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            dispatch({
                type: CONTACTS_CREATE_CONTACT
            })
            const state = await getState();
            const uid = state.main.user.uid;
            const { data } = await cloudfunctions.createContact(uid, contact);
            const { contact: newContactCreated, contact_id } = data;
            dispatch({
                type: CONTACTS_CREATE_CONTACT_SUCCCESS,
            })
            return {
                ...newContactCreated,
                uid: contact_id,
                type: "CONTACT"
            };
        } catch(error) {
            dispatch(handleError(error));
            return dispatch({
                type: CONTACTS_CREATE_CONTACT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    }
}

export {
    fetchContactList,
    setContactUIDsList,
    createContact
};