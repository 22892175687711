import React from 'react';
import styled, { css } from "styled-components";
import { getThemeColor, getThemeOnColor, theme } from "../../../../../../../theme";
import { Text } from '../../Text';
// import ArrowDownIcon from "../../../../../../../assets/svg/ArrowDownIcon";
import SquareButton2 from '../../Buttons/SquareButton2';
import { Box } from 'rebass';

// const inputStyles = {
//     control: {
//         // Styles that you want to place on the <input> element
//     },
//     menu: {
//         // Styles that you want to place on the menu
//     },
//     option: {
//         // Styles that you want to place on the option elements
//     },
//     valueContainer: {
//         // Styles that you want to place on the <span> elements that are wrapped around the current value
//     },
//     dropdownIndicator: {
//         // Styles that you want to place on the dropdown indicator element
//     },
//     indicatorSeparator: {
//         // Styles that you want to place on the separator element that comes after the dropdown indicator element
//     }
// }

export const multiSelectStyles = ({
    control,
    menu,
    option,
    valueContainer,
    dropdownIndicator,
    indicatorSeparator,
} = {
        control: {},
        menu: {},
        option: {},
        valueContainer: {},
        dropdownIndicator: {},
        indicatorSeparator: {},
    }) => ({
        control: (styles, { isFocused, ...rest }) => ({
            ...styles,
            // borderColor: "none",
            cursor: "pointer",
            WebkitAppearance: "none",
            appearance: "none",
            outline: "none",
            boxSizing: "border-box",
            MozBoxSizing: "border-box",
            WebkitBoxSizing: "border-box",
            width: "100%",
            // border: "none",
            fontSize: "16px",
            lineHeight: "18px",
            padding: ".5em 0",
            border: `1px solid ${isFocused ? theme.newBlue : theme.greyLight7}`,
            borderRadius: "3px",
            "&:hover": {
                border: `1px solid ${theme.newBlue}`,
            },
            ...(control ? control : {}),
        }),
        menu: styles => ({
            ...styles,
            border: "1px solid #DDE3E8",
            ...(menu ? menu : {}),
        }),
        option: (styles, { isFocused, isSelected, isDisabled }) => ({
            ...styles,
            backgroundColor: isFocused ? getThemeColor({ "primary": true }) : "transparent",
            color: isDisabled ? "#c4c4c4" : isFocused ? "white" : "inherit",
            transition: "all .1s ease",
            ...(option ? option : {}),
        }),
        valueContainer: styles => ({
            ...styles,
            padding: "0px 1em",
            paddingRight: "0.5em",
            ...(valueContainer ? valueContainer : {}),
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: getThemeColor({ "primary": true }),
            "&:hover": {
                color: getThemeColor({ "primary": true }),
            },
            ...(dropdownIndicator ? dropdownIndicator : {}),
        }),
        indicatorSeparator: styles => ({
            ...styles,
            width: "0px",
            ...(indicatorSeparator ? indicatorSeparator : {}),
        }),
        groupHeading: (base) => ({
            ...base,
            flex: '1 1',
            color: 'black',
            margin: 0,
            textTransform: "none"
        }),
    })

export const BoxContainer = styled.div`
    border: ${props => `1px solid ${props.theme.greyLight7}`};
    border-radius: 3px;
    background-color: ${props => (props.bg ? props.bg : "#ffffff")};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
   
    ${props =>
        props.error
            ? css`
                  color: ${getThemeOnColor({ invalid: true })};
                  background-color: white;
                  border: ${props => `1px solid ${props.theme.onInvalid}`};
              `
            : props.bg
                ? css`
                  background-color: ${props.bg};
              `
                : css`
                  background-color: ${getThemeColor(props)};
              `}
`;
export const InputSquare = styled.input`
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    border: none;
    font-size: 16px;
    line-height: 18px;
    padding: 1em;
    padding-left: ${props => (props.leftIcon ? "1em" : "0em")};
    padding-right: ${props => (props.rightIcon ? "1em" : "0em")};
    color: ${props =>
        props.inputStyles && props.inputStyles.color
            ? props.inputStyles.color
            : `${props.theme.blueMedium}`};
    background-color: ${props =>
        props.inputStyles && props.inputStyles.backgroundColor
            ? props.inputStyles.backgroundColor
            : `white`};
    & :focus {
        outline: none;
    }

    :disabled {
        ${props =>
        props.error
            ? css`
                      background-color: ${getThemeColor({ invalid: true })};
                      color: ${getThemeOnColor({ invalid: true })};
                  `
            : css`
                      background-color: ${getThemeColor({ disabled: true })};
                      color: ${getThemeOnColor({ disabled: true })};
                  `}
    }

    ::placeholder {
        color: ${props =>
        props.error
            ? props.theme.onInvalid
            : props.placeHolderStyles && props.placeHolderStyles.color
                ? props.placeHolderStyles.color
                : props.theme.placeHolder};
    }

    :-ms-input-placeholder {
        ${props =>
        props.error
            ? css`
                      color: ${getThemeOnColor({ invalid: true })};
                  `
            : css`
                      color: ${props.theme.placeHolder};
                  `}
    }

    option {
        color: ${props =>
        props.color ? props.color : `${props.theme.blueMedium}`};
        background: white;
        font-size: 16px;
    }
`;

export const InputLabel = styled.div`
    
    color: white;
    font-size: 12px;
    line-height: 16px;
    ${InputSquare}:focus ~ & {
        color: ${props => props.theme.blueMedium};
    }
`;

export const RightIcon = styled.div`
    position: absolute;
    top: calc(50% - 10px);
    right: 1em;
`;

export const FormGroup = styled.div`
    width: 100%;
`;

export const SelectOption = styled.div`
    color: ${props => props.theme.coolBlack};
    padding: 1em;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    & :hover {
        background-color: ${props => props.theme.blueMedium};
        color: white;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
    }
`;

export const NeedUpgradeLabel = styled(Text)`
    margin-left: 8px;
    color: ${props => props.theme.shamRockGreen};
    font-size: 12px;
`

export const UpgradeButton = styled(Box)`
text-align: center;
padding: 4px 1em 6px 1em;
background-color: ${props => props.theme.shamRockGreen};
color: ${props => props.theme.white};
border-radius: 4px;
margin-right: 8px;
margin-left: 25px;
text-decoration: none;
`
