import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import LocationForm from "../../ReferralCompleteInformation/Location/view"

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const AcceptanceOptions = [
    { value: 'FIRST_COME_FIRST_SERVE', label: 'The first candidate to accept it'},
    { value: 'ALL', label: 'All Candidates' },
]

const Acceptance = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
}) => {
    const key = "acceptance_"
    const [isCollapsed, setIsCollapsed] = useState(false)

    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Who can accept this job?
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <SelectContainer>
                    <Select
                        options={AcceptanceOptions}
                        onChange={option => {
                            handleChange({
                                target: {
                                    name: "acceptance",
                                    value: option
                                }
                            });
                        }}
                        styles={selectStyle}
                        value={values.acceptance}
                        isClearable
                        placeholder="Select...*"
                        error={errors.acceptance}
                    />
                </SelectContainer>
            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: t("contractorAcceptanceInfo") }}/>
            </ReactTooltip>
        </Box>
    );
}

export default Acceptance;
