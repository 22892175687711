import React from "react";
import ReactDOM from "react-dom";
import { Modal, ModalContent, TopModal, CloseButton } from "./popupModal-styles";
//import Card from "../Card";
import { Box } from "rebass";
const modalRoot = document.getElementById("modal-root");

class PopupModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.el = document.createElement("div");
        this.state = {
            firstClick: true
        };
    }
    componentDidMount = () => {
        modalRoot.appendChild(this.el);
        document.addEventListener("click", this.checkTargetClick, false);
        this.setState({ firstClick: true });
    };

    componentWillUnmount() {
        const body = document.getElementById("body");
        modalRoot.removeChild(this.el);
        body.removeAttribute("class", "blurred");
        document.removeEventListener("click", this.checkTargetClick, false);
    }

    checkTargetClick = e => {
        const modalId = this.props.modalId
            ? this.props.modalId
            : "modal-content";

        if (this.props.open && this.state.firstClick)
            this.setState({ firstClick: false });
        else if (
            this.props.open &&
            document.getElementById(modalId) &&
            !document.getElementById(modalId).contains(e.target)
        ) {
            if (this.props.onClose) this.props.onClose();
            this.setState({ firstClick: true });
        }
    };

    renderCloseButton = () => (
        <TopModal
            style={this.props.containerCloseButtonStyles && this.props.containerCloseButtonStyles}
        >
            <CloseButton
                style={
                    this.props.closeButtonStyles && this.props.closeButtonStyles
                }
                onClick={this.props.handleClose && this.props.handleClose}
            >
                <button
                    style={{
                        backgroundImage:
                            "url(" +
                            require("../../../../../../assets/svg/CloseIcon.svg") +
                            ")"
                    }}
                    type="button"
                />
            </CloseButton>
        </TopModal>
    );

    render() {
        const body = document.getElementById("body");
        if (body) {
            let currentClass = body.getAttribute("class");
            if (currentClass && currentClass !== null) {
                let currentClassWithoutBlur = currentClass
                    .split(" ")
                    .filter(className => className !== "blurred")
                    .reduce((current, next) => `${current} ${next}`, "");

                if (this.props.open) {
                    body.setAttribute(
                        "class",
                        `${currentClassWithoutBlur} blurred`
                    );
                } else {
                    body.setAttribute("class", currentClassWithoutBlur);
                }
            }
        }

        const props = this.props;
        const {
            open,
            width,
            modalStyles,
            modalId,
            children,
            withOutCard,
            handleClose,
            modalContentStyles
        } = props;

        return ReactDOM.createPortal(
            <Modal open={open} style={modalStyles ? modalStyles : null}>
                {!withOutCard ? (
                    <Box width={width ? width : [0.8, 0.8, 0.5, 0.3]}>
                        <ModalContent style={modalContentStyles} id={modalId ? modalId : "modal-content"}>
                            {handleClose && this.renderCloseButton()}
                            {children}
                        </ModalContent>
                    </Box>
                ) : (
                    <div id={modalId ? modalId : "modal-content"}>
                        {handleClose && this.renderCloseButton()}
                        {children}
                    </div>
                )}
            </Modal>,
            this.el
        );
    }
}

export default PopupModal;
