import {
    FETCH_PROFILE_FAIL,
    FETCH_PROFILE_SUCC,
    FETCH_PROFILE_SET,
    FETCH_PROFILE,
    CONNECT_FAIL,
    CONNECT_SUCC,
    CONNECT,
    ACCEPT_FAIL,
    ACCEPT_SUCC,
    ACCEPT,
    CANCEL_CONNECT_SUCC,
    CANCEL_CONNECT,
    DISPATCHEABLE_UID,
    USER_UNFRIEND_ISLOADING,
    PROFILE_EDITING,
    PROFILE_COUNTERS_LOADING,
    PROFILE_COUNTERS_SUCC,
    PROFILE_CLEAR,
    PROFILE_WHOISWATCHING,
    PROFILE_CONTACTINFO_OPEN,
    PROFILE_FETCH_COMMON_CONTACTS,
    PROFILE_FETCH_COMMON_CONTACTS_SUCC,
    PROFILE_FETCH_COMMON_CONTACTS_FAIL,
    PROFILE_FETCH_OPEN_OPS,
    PROFILE_FETCH_OPEN_OPS_SUCC,
    PROFILE_FETCH_OPEN_OPS_FAIL,
    PROFILE_CLEAR_OPEN_OPS,
    PROFILE_FAVORITE_USER,
    PROFILE_GET_USER_FAVORITES_LOADING,
    PROFILE_GET_USER_FAVORITES_SUCCESS,
    PROFILE_GET_USER_FAVORITES_FAIL,
    PROFILE_GET_USER_ENDORSEMENTS_LOADING,
    PROFILE_GET_USER_ENDORSEMENTS_SUCCESS,
    PROFILE_GET_USER_ENDORSEMENTS_FAIL,
    PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_FAIL,
    PROFILE_UPDATE_ENDORSEMENT_SUCCESS,
    PROFILE_UPDATE_ENDORSEMENT_FAIL,
    PROFILE_DELETE_ENDORSEMENT_SUCCESS,
    PROFILE_DELETE_ENDORSEMENT_FAIL,
    PROFILE_GET_USER_GRATITUDES_LOADING,
    PROFILE_GET_USER_GRATITUDES_SUCCESS,
    PROFILE_GET_USER_GRATITUDES_FAIL,
    PROFILE_ADD_GRATITUDE_SUCCESS,
    PROFILE_ADD_FIRST_GRATITUDE_SUCCESS,
    PROFILE_ADD_GRATITUDE_FAIL,
    PROFILE_UPDATE_GRATITUDE_SUCCESS,
    PROFILE_UPDATE_GRATITUDE_FAIL,
    PROFILE_DELETE_GRATITUDE_SUCCESS,
    PROFILE_DELETE_GRATITUDE_FAIL,
    PROFILE_OPEN_ENDORSEMENT_DETAILS,
    PROFILE_OPEN_GRATITUDE_DETAILS,
    PROFILE_OPEN_OP_REVIEW_DETAILS,
    PROFILE_HIDE_ENDORSEMENT_SUCCESS,
    PROFILE_HIDE_GRATITUDE_SUCCESS,
    PROFILE_GET_USER_OP_REVIEWS_LOADING,
    PROFILE_GET_USER_OP_REVIEWS_SUCCESS,
    PROFILE_GET_USER_OP_REVIEWS_FAIL,
    PROFILE_ADD_OP_REVIEW_SUCCESS,
    PROFILE_ADD_OP_REVIEW_FAIL,
    PROFILE_UPDATE_OP_REVIEW_SUCCESS,
    PROFILE_UPDATE_OP_REVIEW_FAIL,
    PROFILE_DELETE_OP_REVIEW_SUCCESS,
    PROFILE_DELETE_OP_REVIEW_FAIL
} from "../../../../actions/constants";
const initialState = {
    user: {
        loading: false,
        errors: [],
        data: {
            uid: "",
            name: "",
            lastname: "",
            license_state: "",
            license_number: "",
            email: "",
            avatar: ""
        }
    },
    accept: {
        loading: false,
        errors: []
    },
    connect: {
        loading: false,
        errors: []
    },
    cancelConnect: {
        loading: false,
        errors: []
    },
    unfriend: {
        isLoading: false,
        errors: []
    },
    editingCardName: "", //name of the current editing card
    counters: {
        isLoading: false,
        sent: 0,
        received: 0
    },
    commonContacts: {
        isLoading: false,
        errors: []
    },
    openOps: {
        data: [],
        isLoading: false,
        errors: []
    },
    favorites: {
        data: [],
        isLoading: false,
        errors: []
    },
    endorsements: {
        data: {
            received: [],
            sent: []
        },
        isLoading: false,
        errors: [],
        openDetails: false
    },
    gratitudes: {
        data: {
            received: [],
            sent: []
        },
        isLoading: false,
        errors: [],
        openDetails: false
    },
    opReviews: {
        data: {
            received: [],
            sent: []
        },
        isLoading: false,
        errors: [],
        openDetails: false,
        latestOpReview: null
    },
    whoIsWatching: null,
    contactInfoOpen: false
};

function profileReducer(state = initialState, action) {
    switch (action.type) {
        case PROFILE_CONTACTINFO_OPEN: {
            const _state = {
                ...state
            };
            _state.contactInfoOpen = action.payload.contactInfoOpen;
            return _state;
        }
        case PROFILE_WHOISWATCHING: {
            const _state = {
                ...state
            };
            _state.whoIsWatching = action.payload.whoIsWatching
                ? Object.keys(action.payload.whoIsWatching).length
                : null;
            return _state;
        }
        case PROFILE_CLEAR: {
            return {
                user: {
                    loading: false,
                    errors: [],
                    data: {
                        uid: "",
                        name: "",
                        lastname: "",
                        license_state: "",
                        license_number: "",
                        email: "",
                        avatar: ""
                    }
                },
                accept: {
                    loading: false,
                    errors: []
                },
                connect: {
                    loading: false,
                    errors: []
                },
                cancelConnect: {
                    loading: false,
                    errors: []
                },
                unfriend: {
                    isLoading: false,
                    errors: []
                },
                editingCardName: "", //name of the current editing card
                counters: {
                    isLoading: false,
                    sent: {},
                    received: {}
                },
                commonContacts: {
                    isLoading: false,
                    errors: []
                },
                openOps: {
                    data: [],
                    isLoading: false,
                    errors: []
                },
                favorites: {
                    data: [],
                    isLoading: false,
                    errors: []
                },
                endorsements: {
                    data: {
                        received: [],
                        sent: []
                    },
                    isLoading: false,
                    errors: []
                },
                gratitudes: {
                    data: {
                        received: [],
                        sent: []
                    },
                    isLoading: false,
                    errors: []
                },
                opReviews: {
                    data: {
                        received: [],
                        sent: []
                    },
                    isLoading: false,
                    errors: [],
                    openDetails: false,
                    latestOpReview: null
                }
            };
        }
        case FETCH_PROFILE_FAIL: {
            const _state = {
                ...state
            };
            _state.user.loading = false;
            _state.user.errors = [action.errors];
            return _state;
        }
        case FETCH_PROFILE_SUCC: {
            console.log('AQUI...')
            const _state = {
                ...state
            };
            _state.user.data = { ..._state.user.data, ...action.payload };
            _state.user.loading = false;
            return _state;
        }
        case FETCH_PROFILE_SET: {
            const _state = {
                ...state
            };
            _state.user.data = { ...action.payload };
            _state.user.loading = false;
            return _state;
        }
        case FETCH_PROFILE: {
            const _state = {
                ...state
            };
            _state.user.loading = true;
            return _state;
        }
        case CONNECT_FAIL: {
            const _state = {
                ...state
            };
            _state.connect.loading = false;
            _state.connect.errors = [action.errors];
            return _state;
        }
        case CONNECT_SUCC: {
            const _state = {
                ...state
            };
            _state.connect.data = action.payload;
            _state.connect.loading = false;
            return _state;
        }
        case CONNECT: {
            const _state = {
                ...state
            };
            _state.connect.loading = true;
            return _state;
        }
        case CANCEL_CONNECT_SUCC: {
            const _state = {
                ...state
            };
            _state.connect.data = action.payload;
            _state.connect.loading = false;
            return _state;
        }
        case CANCEL_CONNECT: {
            const _state = {
                ...state
            };
            _state.connect.loading = true;
            return _state;
        }
        case USER_UNFRIEND_ISLOADING: {
            const _state = {
                ...state
            };
            _state.unfriend.isLoading = action.payload.isLoading;
            return _state;
        }
        case DISPATCHEABLE_UID: {
            const _state = {
                ...state
            };
            _state.dispatcheableUid = action.payload;
            return _state;
        }
        case ACCEPT_FAIL: {
            const _state = {
                ...state
            };
            _state.accept.loading = false;
            _state.accept.errors = [action.errors];
            return _state;
        }
        case ACCEPT_SUCC: {
            const _state = {
                ...state
            };
            _state.accept.data = action.payload;
            _state.accept.loading = false;
            return _state;
        }
        case ACCEPT: {
            const _state = {
                ...state
            };
            _state.accept.loading = true;
            return _state;
        }
        case PROFILE_EDITING: {
            const _state = {
                ...state
            };
            _state.editingCardName = action.payload.editingCardName;
            return _state;
        }

        case PROFILE_COUNTERS_LOADING: {
            const _state = {
                ...state
            };
            _state.counters.isLoading = true;
            return _state;
        }

        case PROFILE_COUNTERS_SUCC: {
            const _state = {
                ...state
            };
            _state.counters.isLoading = false;
            _state.counters.sent = action.payload.sent;
            _state.counters.received = action.payload.received;
            return _state;
        }
        case PROFILE_FETCH_COMMON_CONTACTS: {
            const _state = {
                ...state
            };
            _state.commonContacts.isLoading = true;
            return _state;
        }
        case PROFILE_FETCH_COMMON_CONTACTS_SUCC: {
            const _state = {
                ...state
            };
            _state.commonContacts.isLoading = false;

            _state.user.data.commonContacts = action.payload;
            return _state;
        }
        case PROFILE_FETCH_COMMON_CONTACTS_FAIL: {
            const _state = {
                ...state
            };
            _state.commonContacts.isLoading = false;
            _state.commonContacts.errors = [action.errors];
            return _state;
        }
        case PROFILE_FETCH_OPEN_OPS: {
            const _state = {
                ...state
            };
            _state.openOps.isLoading = true;
            return _state;
        }
        case PROFILE_FETCH_OPEN_OPS_SUCC: {
            const _state = {
                ...state
            };
            _state.openOps.isLoading = false;

            _state.openOps.data = action.payload;
            return _state;
        }
        case PROFILE_FETCH_OPEN_OPS_FAIL: {
            const _state = {
                ...state
            };
            _state.openOps.isLoading = false;
            _state.openOps.errors = [action.errors];
            return _state;
        }
        case PROFILE_CLEAR_OPEN_OPS: {
            const _state = {
                ...state
            };
            _state.openOps.isLoading = false;
            _state.openOps.data = [];
            _state.openOps.errors = [];
            return _state;
        }
        case PROFILE_FAVORITE_USER: {
            const _state = {
                ...state
            };
            _state.user.data.favoritedByMe = action.payload.favorited;
            return _state;
        }
        case PROFILE_GET_USER_FAVORITES_LOADING: {
            const _state = {
                ...state
            };
            _state.favorites.isLoading = true;
            return _state;
        }
        case PROFILE_GET_USER_FAVORITES_SUCCESS: {
            const _state = {
                ...state
            };
            _state.favorites.isLoading = false;
            _state.favorites.data = action.payload;
            return _state;
        }
        case PROFILE_GET_USER_FAVORITES_FAIL: {
            const _state = {
                ...state
            };
            _state.favorites.isLoading = false;
            _state.favorites.errors = [action.errors];
            return _state;
        }
        case PROFILE_GET_USER_ENDORSEMENTS_LOADING: {
            const _state = {
                ...state
            };
            _state.endorsements.isLoading = true;
            return _state;
        }
        case PROFILE_GET_USER_ENDORSEMENTS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.endorsements.isLoading = false;
            _state.endorsements.data = action.payload;
            return _state;
        }
        case PROFILE_GET_USER_ENDORSEMENTS_FAIL: {
            const _state = {
                ...state
            };
            _state.endorsements.isLoading = false;
            _state.endorsements.errors = [action.errors];
            return _state;
        }
        case PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS: {
            const _state = {
                ...state
            };
            // Update the user stats
            _state.user.data.stats = {
                ..._state.user.data.stats,
                endorsements_sent: _state.user.data.stats.endorsements_sent + 1
            };
            // Add the endorsement to the sent list
            _state.endorsements.data.sent = [
                action.payload,
                ..._state.endorsements.data.sent
            ].slice(0, 3);
            return _state;
        }
        case PROFILE_ADD_ENDORSEMENT_SUCCESS: {
            const _state = {
                ...state
            };
            // Mark the user as endorsed by me
            _state.user.data.endorsedByMe = true;
            // Update the user stats
            const endorsements_received =
                _state.user.data.stats &&
                _state.user.data.stats.endorsements_received
                    ? _state.user.data.stats.endorsements_received
                    : 0;
            _state.user.data.stats = {
                ..._state.user.data.stats,
                endorsements_received: endorsements_received + 1
            };
            _state.endorsements.data.received = [
                action.payload,
                ..._state.endorsements.data.received
            ].slice(0, 3);
            return _state;
        }
        case PROFILE_ADD_ENDORSEMENT_FAIL: {
            const _state = {
                ...state
            };

            _state.endorsements.errors = [action.errors];
            return _state;
        }
        case PROFILE_UPDATE_ENDORSEMENT_SUCCESS: {
            const _state = {
                ...state
            };

            _state.endorsements.data.sent = _state.endorsements.data.sent.map(
                endorsement =>
                    endorsement.endorsement_id === action.payload.endorsement_id
                        ? action.payload
                        : endorsement
            );
            return _state;
        }
        case PROFILE_HIDE_ENDORSEMENT_SUCCESS: {
            const _state = {
                ...state
            };

            _state.endorsements.data.received = _state.endorsements.data.received.map(
                endorsement =>
                    endorsement.endorsement_id === action.payload.endorsement_id
                        ? action.payload
                        : endorsement
            );
            return _state;
        }
        case PROFILE_UPDATE_ENDORSEMENT_FAIL: {
            const _state = {
                ...state
            };

            _state.endorsements.errors = [action.errors];
            return _state;
        }
        case PROFILE_DELETE_ENDORSEMENT_SUCCESS: {
            const _state = {
                ...state
            };
            _state.user.data.stats = {
                ..._state.user.data.stats,
                endorsements_sent: _state.user.data.stats.endorsements_sent - 1
            };
            _state.endorsements.data.sent = _state.endorsements.data.sent.filter(
                endorsement => endorsement.endorsement_id !== action.payload
            );
            return _state;
        }
        case PROFILE_DELETE_ENDORSEMENT_FAIL: {
            const _state = {
                ...state
            };

            _state.endorsements.errors = [action.errors];
            return _state;
        }
        case PROFILE_OPEN_ENDORSEMENT_DETAILS: {
            const _state = {
                ...state
            };

            _state.endorsements.openDetails = !_state.endorsements.openDetails;
            return _state;
        }
        case PROFILE_GET_USER_GRATITUDES_LOADING: {
            const _state = {
                ...state
            };
            _state.gratitudes.isLoading = true;
            return _state;
        }
        case PROFILE_GET_USER_GRATITUDES_SUCCESS: {
            const _state = {
                ...state
            };
            _state.gratitudes.isLoading = false;
            _state.gratitudes.data = action.payload;
            return _state;
        }
        case PROFILE_GET_USER_GRATITUDES_FAIL: {
            const _state = {
                ...state
            };
            _state.gratitudes.isLoading = false;
            _state.gratitudes.errors = [action.errors];
            return _state;
        }
        case PROFILE_ADD_FIRST_GRATITUDE_SUCCESS: {
            const _state = {
                ...state
            };
            // Update the user stats
            _state.user.data.stats = {
                ..._state.user.data.stats,
                gratitudes_sent: _state.user.data.stats.gratitudes_sent + 1
            };
            // Add the gratitude to the sent list
            _state.gratitudes.data.sent = [
                action.payload,
                ..._state.gratitudes.data.sent
            ].slice(0, 3);
            return _state;
        }
        case PROFILE_ADD_GRATITUDE_SUCCESS: {
            const _state = {
                ...state
            };
            // Update the user stats
            _state.user.data.stats = {
                ..._state.user.data.stats,
                gratitudes_received:
                    _state.user.data.stats.gratitudes_received + 1
            };
            // Add the gratitude to the received list
            _state.gratitudes.data.received = [
                action.payload,
                ..._state.gratitudes.data.received
            ].slice(0, 3);
            return _state;
        }
        case PROFILE_ADD_GRATITUDE_FAIL: {
            const _state = {
                ...state
            };

            _state.gratitudes.errors = [action.errors];
            return _state;
        }
        case PROFILE_UPDATE_GRATITUDE_SUCCESS: {
            const _state = {
                ...state
            };
            _state.gratitudes.data.sent = _state.gratitudes.data.sent.map(
                gratitude =>
                    gratitude.gratitude_id === action.payload.gratitude_id
                        ? action.payload
                        : gratitude
            );
            return _state;
        }
        case PROFILE_HIDE_GRATITUDE_SUCCESS: {
            const _state = {
                ...state
            };
            _state.gratitudes.data.received = _state.gratitudes.data.received.map(
                gratitude =>
                    gratitude.gratitude_id === action.payload.gratitude_id
                        ? action.payload
                        : gratitude
            );
            return _state;
        }
        case PROFILE_UPDATE_GRATITUDE_FAIL: {
            const _state = {
                ...state
            };

            _state.endorsements.errors = [action.errors];
            return _state;
        }
        case PROFILE_DELETE_GRATITUDE_SUCCESS: {
            const _state = {
                ...state
            };
            _state.user.data.stats = {
                ..._state.user.data.stats,
                gratitudes_sent: _state.user.data.stats.gratitudes_sent - 1
            };
            _state.gratitudes.data.sent = _state.gratitudes.data.sent.filter(
                gratitude => gratitude.gratitude_id !== action.payload
            );
            return _state;
        }
        case PROFILE_DELETE_GRATITUDE_FAIL: {
            const _state = {
                ...state
            };

            _state.gratitudes.errors = [action.errors];
            return _state;
        }
        case PROFILE_OPEN_GRATITUDE_DETAILS: {
            const _state = {
                ...state
            };

            _state.gratitudes.openDetails = !_state.gratitudes.openDetails;
            return _state;
        }
        case PROFILE_GET_USER_OP_REVIEWS_LOADING: {
            const _state = {
                ...state
            };
            _state.opReviews.isLoading = true;
            return _state;
        }
        case PROFILE_GET_USER_OP_REVIEWS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.opReviews.isLoading = false;
            // If there is a latestOpReview, it means that the user just added a new op review
            // and we need to add it to the list
            if (_state.opReviews.latestOpReview) {
                // Update the user stats
                _state.user.data.stats = {
                    ..._state.user.data.stats,
                    op_reviews_received:
                        _state.user.data.stats.op_reviews_received + 1
                };
                // Add the op review to the received list
                _state.opReviews.data.received = [
                    _state.opReviews.latestOpReview,
                    ..._state.opReviews.data.received
                ];
                // Reset the latestOpReview
                _state.opReviews.latestOpReview = null;
                return _state;
            }
            // Otherwise, just update the list
            _state.opReviews.data = action.payload;
            return _state;
        }
        case PROFILE_GET_USER_OP_REVIEWS_FAIL: {
            const _state = {
                ...state
            };
            _state.opReviews.isLoading = false;
            _state.opReviews.errors = [action.errors];
            return _state;
        }
        case PROFILE_ADD_OP_REVIEW_SUCCESS: {
            const _state = {
                ...state
            };
            state.opReviews.latestOpReview = action.payload;

            return _state;
        }
        case PROFILE_ADD_OP_REVIEW_FAIL: {
            const _state = {
                ...state
            };

            _state.opReviews.errors = [action.errors];
            return _state;
        }
        case PROFILE_UPDATE_OP_REVIEW_SUCCESS: {
            const _state = {
                ...state
            };
            _state.opReviews.data.sent = _state.opReviews.data.sent.map(
                review =>
                    review.review_id === action.payload.review_id
                        ? action.payload
                        : review
            );
            return _state;
        }
        case PROFILE_UPDATE_OP_REVIEW_FAIL: {
            const _state = {
                ...state
            };

            _state.opReviews.errors = [action.errors];
            return _state;
        }
        case PROFILE_DELETE_OP_REVIEW_SUCCESS: {
            const _state = {
                ...state
            };
            _state.user.data.stats = {
                ..._state.user.data.stats,
                op_reviews_sent: _state.user.data.stats.op_reviews_sent - 1
            };
            _state.opReviews.data.sent = _state.opReviews.data.sent.filter(
                gratitude => gratitude.gratitude_id !== action.payload
            );
            return _state;
        }
        case PROFILE_DELETE_OP_REVIEW_FAIL: {
            const _state = {
                ...state
            };

            _state.opReviews.errors = [action.errors];
            return _state;
        }
        case PROFILE_OPEN_OP_REVIEW_DETAILS: {
            const _state = {
                ...state
            };

            _state.opReviews.openDetails = !_state.opReviews.openDetails;
            return _state;
        }
        default:
            return state;
    }
}

export default profileReducer;
