import { connect } from "react-redux";
import View from "./view";
import { withTheme } from 'styled-components'

const mapStateToProps = state => {
    return {
        profile_verification_pending: state.main.user && state.main.user.profile && state.main.user.profile.profile_verification_pending,
        verified: state.main.user && state.main.user.profile && state.main.user.profile.verified,
    };
};
export default connect(mapStateToProps)(withTheme(View));
