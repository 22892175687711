import {
    SEND_REFERRAL_SIGNATORY_SET_USER,
    SEND_REFERRAL_SIGNATORY_SET_TYPE,
    SEND_REFERRAL_FORM_SET_ISVALIDFORM,
    SEND_REFERRAL_FORM_SET_VALUE,
    SEND_REFERRAL_CANDIDATES_SET_TYPE,
    SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL,
    SEND_REFERRAL_ISVALIDFORM,
    SEND_REFERRAL_SET_VALUE,
    SEND_REFERRAL_CURRENT_SECTION,
    SEND_REFERRAL_ADD_USER_TO_REFERRAL,
    SEND_REFERRAL_SAVE_FORM,
    SEND_REFERRAL_CLEAN_FORM,
    SEND_REFERRAL_REMOVE_USER_TO_REFERRAL,
    SEND_REFERRAL_FORM_SET_COLLAPSE,
    SEND_REFERRAL_SUCC,
    SEND_REFERRAL_PREVENT_PROMPT,
    SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO
} from "../../constants";
import {
    SECTION_SHARE,
    SECTION_SELECT_CANDIDATES,
    SECTIONS_ARRAY_POSITIONS,
    SECTION_FORM
} from '../../../../components/ui/presentation/views/SendReferral/constants'
import ReferralBusiness from '../../../../business/referral'
import { push } from "connected-react-router";

const sendReferralFormSetCollapseSection = (section, collapse) => {
    return {
        type: SEND_REFERRAL_FORM_SET_COLLAPSE,
        payload: { 
            section: section,
            collapse: collapse
        }
    };
};

const clearSuccesModalInfo = ()  => {
    return {
        type: SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO,
    };
}

const sendReferralSuccess = (success) => {
    return {
        type: SEND_REFERRAL_SUCC,
        payload: { success: success }
    };
};

const sendReferralPreventPrompt = (show) => {
    return {
        type: SEND_REFERRAL_PREVENT_PROMPT,
        payload: { preventPrompt: show }
    };
};

const addUserToReferral = (user) => {
    return {
        type: SEND_REFERRAL_ADD_USER_TO_REFERRAL,
        payload: { user: user }
    };
};

const removeUserFromReferral = (uid) => {
    return {
        type: SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL,
        payload: { uid: uid }
    };
};

const setCandidatesType = (insideReally) => {
    return {
        type: SEND_REFERRAL_CANDIDATES_SET_TYPE,
        payload: { insideReally: insideReally }
    };
};

const setSignatoryInsideOutsideTab = (insideReally) => {
    return {
        type: SEND_REFERRAL_SIGNATORY_SET_TYPE,
        payload: { insideReally: insideReally }
    };
};

const setSignatoryUser = (user) => {
    return {
        type: SEND_REFERRAL_SIGNATORY_SET_USER,
        payload: { user: user }
    };
};

const saveForm = (form) => {
    return {
        type: SEND_REFERRAL_SAVE_FORM,
        payload: form
    };
};
const removeUser = (uid) => {
    return {
        type: SEND_REFERRAL_REMOVE_USER_TO_REFERRAL,
        payload: uid
    };
};
const cleanForm = (form) => {
    return {
        type: SEND_REFERRAL_CLEAN_FORM
    };
};

const setValue = (section, name, value) => {
    return {
        type: SEND_REFERRAL_SET_VALUE,
        payload: {
            section: section,
            name: name,
            value: value
        }
    };
};

const setReferralFormValue = (section, formSection, name, value) => {
    return {
        type: SEND_REFERRAL_FORM_SET_VALUE
        ,
        payload: {
            section: section,
            name: name,
            value: value,
            formSection: formSection
        }
    };
};

const setReferralFormIsValid = (section, formSection, isValidForm) => {
    return {
        type: SEND_REFERRAL_FORM_SET_ISVALIDFORM
        ,
        payload: {
            section: section,
            isValidForm: isValidForm,
            formSection: formSection
        }
    };
};


const setIsValidForm = (isValidForm) => {
    return {
        type: SEND_REFERRAL_ISVALIDFORM,
        payload: {
            isValidForm: isValidForm
        }
    };
};

const setCurrentSection = (currentSection, subSection = null) => {
    return async (dispatch, getState) => {
        try {
            const statics = getState().main.ui.global.statics
            const shareWithKeys = statics && statics.referralShareWith && statics.referralShareWith.optionsKeys
            const isRER = getState().main.ui.view.sendReferral[SECTION_SHARE].values.shareWith === shareWithKeys.ALL
            const candidates = getState().main.ui.view.sendReferral[SECTION_SELECT_CANDIDATES].candidates
            const selectedSignatory = getState().main.ui.view.sendReferral[SECTION_FORM].signatory.values.signatory
            const stepCount = ReferralBusiness.getStepCount(SECTIONS_ARRAY_POSITIONS, currentSection, isRER, candidates, selectedSignatory)

            dispatch({
                type: SEND_REFERRAL_CURRENT_SECTION,
                payload: {
                    currentSection: currentSection,
                    stepCount: stepCount,
                    subSection: subSection
                }
            });
        } catch (error) {

        }

    };
}

const moveSection = (currentSection, next = true) => {
    return async (dispatch, getState) => {
        try {
            const signatoryTypesOptionsKeys = getState().main.ui.global.statics.referralSignatoryTypes.optionsKeys
            const sharedWithOptionsKeys = getState().main.ui.global.statics.referralShareWith.optionsKeys
            const isRER = getState().main.ui.view.sendReferral[SECTION_SHARE].values.shareWith === sharedWithOptionsKeys.ALL
            const candidates = getState().main.ui.view.sendReferral[SECTION_SELECT_CANDIDATES].candidates
            const selectedSignatory = getState().main.ui.view.sendReferral[SECTION_FORM].signatory.values.signatory
            const nextSection = ReferralBusiness.getSendReferralNextSection(SECTIONS_ARRAY_POSITIONS, currentSection, isRER, next, candidates, selectedSignatory, signatoryTypesOptionsKeys)
            let stepCount = getState().main.ui.view.sendReferral.stepCount
            stepCount = next ? stepCount + 1 : stepCount - 1;

            dispatch({
                type: SEND_REFERRAL_CURRENT_SECTION,
                payload: {
                    currentSection: nextSection,
                    stepCount: stepCount
                }
            });
        } catch (error) {

        }

    };
}


const cancelReferral = () => {
    return async (dispatch, getState) => {
        try {
            dispatch(push('/referrals'))
            cleanForm()
            dispatch(sendReferralSuccess(false))
        } catch (error) {

        }

    };
}
export {
    sendReferralFormSetCollapseSection,
    sendReferralPreventPrompt,
    cancelReferral,
    sendReferralSuccess,
    setSignatoryUser,
    setSignatoryInsideOutsideTab,
    setCurrentSection,
    setReferralFormIsValid,
    setReferralFormValue,
    setCandidatesType,
    moveSection,
    addUserToReferral,
    removeUserFromReferral,
    saveForm,
    removeUser,
    cleanForm,
    setValue,
    setIsValidForm,
    clearSuccesModalInfo
};