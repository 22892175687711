import React, { Component } from "react";
import { Box, Flex } from "rebass";
import NavBar from "./NavBar";
import { withTheme } from "styled-components";
import NotificationSideBar from "./NavBar/MobileNavBar/NotificationSideBar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import { Icon } from "../../ui/presentation/atoms/shared/Icon";
import { bindActionCreators } from "redux";
import { setChatBotOpen } from "../../../redux/actions/creators/shared";
import MaintenanceAlert from './MaintenanceAlert'
import ShareModal from "../../ui/presentation/atoms/shared/ShareModal";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import { knockEnv } from "../../../config";
import { CustomBellProvider } from "../../context/useKnockProvider";

const excludedPathsForPhoneValidation = ['/fill-profile', '/sign-up', '/sign-in', '/reset-password', '/invite']
class Layout extends Component {
    onClickReload = () => {
        if (caches) {
            console.log("Clearing cache and hard reloading...");
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };
    renderChatBotCloseIcon = () => {
        const { chatBotOpen } = this.props;

        return chatBotOpen && chatBotOpen === "show" ? (
            <div
                id="salesiqchatClose"
                style={{
                    position: "fixed",
                    bottom: 150,
                    right: 20,
                    zIndex: 9999,
                    cursor: "pointer"
                }}
                onClick={() => this.props.setChatBotOpen("hide")}
            >
                <Icon icon="close" color="grey" />
            </div>
        ) : null;
    };
    isDesktopMedia = (desktopDeviceWidth, actualWidth) =>
        actualWidth > desktopDeviceWidth;
    render() {
        const {
            title,
            color,
            LeftIcon,
            RightIcon,
            LeftAction,
            RightAction,
            rightIconProps,
            leftIconProps,
            chatBotOpen,
            isLoading,
            phone,
            theme
        } = this.props;
     

        const blurred =
            (this.props.searchBarOpen && this.props.searchBarBlured) ||
            this.props.avatarDropDownOpen;
        const { pathname } = this.props.location;

        return (
            <div
                style={{
                    backgroundColor: this.props.backgroundColor,
                    minHeight: "100vh"
                }}
            >
                {/* As per 08/21/23: Notifications Side Bar has been removed since Notifications on Mobile has their own Page */}
              {/* { theme.mobileDevice && <NotificationSideBar />} */}
              <CustomBellProvider userId={this.props.myId} >
               <KnockFeedProvider
                apiKey={knockEnv.knockPublicApiKey}
                feedId={knockEnv.feedChannelId}
                userId={this.props.myId}
               >
                <NavBar
                        color={color}
                        title={title}
                        LeftIcon={LeftIcon}
                        RightIcon={RightIcon}
                        LeftAction={LeftAction}
                        RightAction={RightAction}
                        rightIconProps={rightIconProps}
                        leftIconProps={leftIconProps}
                    />
               </KnockFeedProvider>
               </CustomBellProvider> 
                <Flex
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        width="100%"
                        mt={["3.125rem", "3.125rem", 0, 0]}
                        mr={[0, 0, "5rem", "5rem"]}
                        ml={[0, 0, "5rem", "5rem"]}
                        mb={[
                            `calc(11.33vh + ${chatBotOpen !== "hide" ? "4em" : "1em"
                            })`,
                            `calc(11.33vh + ${chatBotOpen !== "hide" ? "4em" : "1em"
                            })`,
                            0,
                            0
                        ]}
                        pl={["0.938rem", "0.938rem", 0, 0]}
                        pr={["0.938rem", "0.938rem", 0, 0]}
                        style={{ maxWidth: "1440px" }}
                        className={blurred ? "blurred" : ""}
                    >
                        {/* TODO: Uncomment Verify Phone Number when Twilio was working */}
                        {!isLoading && phone && !excludedPathsForPhoneValidation.includes(pathname) && <VerifyPhoneNumber />}
                        {!isLoading && <MaintenanceAlert />}
                        {!isLoading && <ShareModal />}
                        {this.props.children}
                        {this.renderChatBotCloseIcon()}

                    </Box>
                </Flex>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setChatBotOpen
        },
        dispatch
    );

const mapStateToProps = state => {
    return {
        searchBarOpen: state.main.ui.global.searchBar.isOpen,
        searchBarBlured: state.main.ui.global.searchBar.isBlured,
        avatarDropDownOpen: state.main.ui.global.navbar.avatarDropDownOpen,
        backgroundColor: state.main.ui.global.layoutBackgroundColor,
        chatBotOpen: state.main.ui.global.chatBotOpen,
        newPWAVersionAvailable: state.main.ui.global.newPWAVersionAvailable,
        isLoading: state.main.user.isLoading || (state.main.ui.view.signUp.loading || state.main.ui.view.signIn.loading),
        phone: state.main.user.profile.phone,
        myId: state.main.user.uid,
    };
};

export default withRouter(
    withTheme(connect(mapStateToProps, mapDispatchToProps)(Layout))
);
