import React, { useEffect } from 'react'
import styled, { withTheme } from 'styled-components'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PopupBlurModal from '../PopupBlurModal';
import { Flex } from 'rebass';
import { Title, Text } from '../Text';
import { FormContentRow, Input, InputContainer, InputLabel } from '../../../views/Settings/common/Components';
import useForm from '../../../views/Settings/common/useForm';
import SquareButton2 from '../Buttons/SquareButton2';
import { createContact } from '../../../../../../redux/actions/creators/contacts';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const containerStyle = {
    display: 'flex',
    flexBasis: '50%',
};

const inputStyle = {
    border: '1px solid #929292',
    height: '48px',
    fontSize: '16px',
    width: '100%',
};

const PhoneNumberInput = ({
    value,
    onChange,
    name,
}) => {

    const emitChange = (value) => {
        onChange({ name, value: `+${value}` })
    }

    return(
        <PhoneInput
            containerStyle={containerStyle}
            inputStyle={inputStyle}
            country={'us'}
            value={value}
            onChange={(value) => emitChange(value)}
        />
    );
};

const FormRow = styled(FormContentRow)`
    width: 100%;
`

const CreateContact = ({
    theme,
    open,
    onClose,
    loading,
    createContact,
    onCreateContact = () => { }
}) => {

    const {
        formValues,
        touched,
        reset,
        initialize,
        update,
    } = useForm();

    useEffect(() => {
        initialize({
            first_name: "",
            last_name: "",
            email: "",
            mobile_phone: '',
        });
    }, [open]);

    const isValidForm = (() => {
        const { email, mobile_phone, ...restValues } = formValues;
        const nonEmptyValues = Object.values(restValues).every(Boolean)
        const isEmailValid = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        const isPhoneValid = String(mobile_phone).length === 12;
        return nonEmptyValues && (isEmailValid || isPhoneValid);
    })();

    const onChange = (ev) => update({ key: ev.name, value: ev.value });

    const onSubmit = async () => {
        const contact = await createContact(formValues);
        onCreateContact(contact);
        reset();
        onClose();
    }

    return (
        <PopupBlurModal
            open={open}
            handleClose={(e) => onClose(e)}
            width={[0.8, 0.8, 0.5, 0.5]}
        >
            <Flex flexDirection={"column"} px={theme.spacing[16]} py={theme.spacing[10]}>
                <Flex width={1} justifyContent="center">
                    <Text fontSize="L" fontWeight="bold">Create Contact</Text>
                </Flex>
                <Flex width={1} mt={theme.spacing[4]} justifyContent="center">
                    <Text color={theme.taupeGray} fontSize="XXS">This will create a new contact and invite them to review this opportunity</Text>
                </Flex>
                <Flex flexWrap={"wrap"} mt={theme.spacing[10]}>
                    <FormRow>
                        <InputContainer>
                            <InputLabel>
                                First Name
                            </InputLabel>
                            <Input
                                type="text"
                                name="first_name"
                                required
                                value={formValues.first_name}
                                onChange={ev => onChange(ev.target)}
                            />
                        </InputContainer>
                        <InputContainer
                        // style={{
                        //     width: theme.mobileDevice ? "100%" : null
                        // }}
                        >
                            <InputLabel>Last Name</InputLabel>
                            <Input
                                type="text"
                                name="last_name"
                                required
                                value={formValues.last_name}
                                onChange={ev => onChange(ev.target)}
                            />
                        </InputContainer>
                    </FormRow>
                    <FormRow>
                        <InputContainer>
                            <InputLabel>Email</InputLabel>
                            <Input
                                type="email"
                                name="email"
                                required
                                value={formValues.email}
                                onChange={ev => onChange(ev.target)}
                            />
                        </InputContainer>
                    </FormRow>
                    <FormRow>
                        <InputContainer>
                            <InputLabel>Phone number</InputLabel>
                            <PhoneNumberInput
                                name="mobile_phone"
                                required
                                value={formValues.mobile_phone}
                                onChange={ev => onChange(ev)}
                            />
                        </InputContainer>
                    </FormRow>
                </Flex>
                <Flex mt={theme.spacing[4]}>
                    <Text color={theme.taupeGray} fontSize="XXS">*Required fields: First Name, Last Name, and either Email or Phone.</Text>
                </Flex>
                <Flex justifyContent={"flex-end"}>
                    <SquareButton2
                        text="Create"
                        disabled={!isValidForm}
                        isLoading={loading}
                        onClick={onSubmit}
                    />
                </Flex>
            </Flex>
        </PopupBlurModal>
    )
}

const mapStateToProps = state => ({
    loading: state.main.ui.view.contacts.loading,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createContact
        },
        dispatch
    );

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(CreateContact))