import React from "react";
import styled from "styled-components";
import { Icon } from "../../atoms/shared/Icon";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 46px;
    justify-content: spacer-between;
    padding: 0px 20px;
    align-items: center;
    background: ${(props) => props.background};
    cursor: pointer;
`;

const UserInfoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    & + & {
        margin-left: 1em;
    }
`;

const Label = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding-left: 0.5em;
    cursor: pointer;
`;

const MainLabel = styled(Label)`
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    padding-left: 0.5em;
`;

const Avatar = styled.img`
    height: 27px;
    width: 27px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
`;

const ProMemberText = styled(Label)`
    color: #00A15F;
    cursor: pointer;
`;

const EssentialPlusText = styled(Label)`
    color: #1D55B6;
    cursor: pointer;
`;

const EssentialText = styled(Label)`
    color: #929292;
    cursor: pointer;
    padding: 0;
`;

const SuscriptionLevels = {
    PRO: 'PRO',
    ESSENTIALPLUS: 'ESSENTIAL+',
    ESSENTIAL: 'ESSENTIAL',
};


const EssentialPlusCheck = () => <Icon icon="check-filled" width={20} height={20} color="#1D55B6" />;

const ProMemberCheck = () => <Icon icon="check-filled" width={20} height={20} color="#00A15F" />;

const ProMember = () => (
    <UserInfoSection>
        <ProMemberCheck />                
        <ProMemberText>
            Pro Member
        </ProMemberText>
    </UserInfoSection>
);

const EssentialPlus = () => (
    <UserInfoSection>
        <EssentialPlusCheck />                
        <EssentialPlusText>
            Essential+
        </EssentialPlusText>
    </UserInfoSection>
);

const Essential = () => (
    <UserInfoSection>              
        <EssentialText>
            Essential
        </EssentialText>
    </UserInfoSection>
);

const NotMemberLabel = styled.div`
    width: 136px;
    font-size: 14px;
    font-weight: 500;
    color: #057AFF;
    text-align: center;
    border-radius: 4px;
    background: rgba(5, 122, 255, 0.2);
    padding: 2px 8px;
`;

export const NotAMemberInfo = ({ firstName, lastName, email, mobilePhone,  background = '#FFFFFF' }) => {

    return (
        <Wrapper background={background}>
            <UserInfoSection>
                <Icon icon="profile" width={14} height={10} color={'#057AFF'} />
                <MainLabel>
                    {firstName} {lastName}
                </MainLabel>
            </UserInfoSection>
            <UserInfoSection>
                <Icon icon="mail" width={14} height={10} color={'#057AFF'} />
                <Label>
                    {email}
                </Label>
            </UserInfoSection>
            {mobilePhone && (
                <UserInfoSection>
                    <Icon icon="phone" width={14} height={14} color={'#057AFF'} />
                    <Label>
                        {mobilePhone}
                    </Label>
                </UserInfoSection>
            )}
            <UserInfoSection>
                <NotMemberLabel>Not a member yet</NotMemberLabel>
            </UserInfoSection>
        </Wrapper>
    )
}

const UserInfo = ({ id, avatarUrl, name, organization, location, tier, background = '#FFFFFF', onClick = () => {} }) => {
    return (
        <Wrapper background={background} onClick={() => onClick(id)}>
            <UserInfoSection>
                <Avatar src={avatarUrl}/>
            </UserInfoSection>
            <UserInfoSection>
                <MainLabel>
                    {name}
                </MainLabel>
            </UserInfoSection>
            {
                organization && <UserInfoSection>
                    <Icon icon="building" size="md" color="#057AFF" />
                    <Label>
                        {organization}
                    </Label>
                </UserInfoSection>
            }
            {
                location && <UserInfoSection>
                    <Icon icon="location" size="md" color="#057AFF" />
                    <Label>
                        {location}
                    </Label>
                </UserInfoSection>
            }
            { tier === SuscriptionLevels.PRO && <ProMember /> }
            { tier === SuscriptionLevels.ESSENTIALPLUS && <EssentialPlus /> }
            { tier === SuscriptionLevels.ESSENTIAL && <Essential /> }
          
        </Wrapper>
    );
};

export default UserInfo;
