import React, { useEffect, useState } from 'react'
import { Flex } from "rebass"
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../../../Settings/common/Components";
import { selectStyle } from "../../../../components";
import { TextLabel, CheckBoxContainer, FieldsContainer, InputContainerVariation } from './PastTimingInfo.styles'
import CheckBox from '../../../../../../atoms/shared/Inputs/Checkbox';
import PriceField from '../../../ReferralCompleteInformation/RealStateDetails/Fields/_PriceField';
import { partyConfirmationOptions } from '../../../../constants';
import { Description } from '../../../../../../atoms/shared/Text';
import styled from 'styled-components';
import { object } from 'yup';


const DummyPicker = styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
    padding: 0 10px;
    display: flex;
    flex-grow: 1;
    height: 48px;
    border: 1px solid #929292;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 0 0 16px;
    width: 100%;
    margin-top: 24px;
    &:focus {
        border: none;
        outline: none;
    }

`


const TimingInfo = ({ theme, formValues, onChange, errors, clientSourcesOptions, clientStatusOptions }) => {
    const [ clientSourceError, setClientSourceError  ] = useState(false);
    const [ lastContactError, setLastContactError ] = useState(false);
    const [ referralCreatorError, setReferralCreatorError ] = useState(false)
    const MaxPriceFieldSelector = 'amount';
    const referralFeeExchangedSelector = 'fee';


    const [openCompletionFields, setOpenCompletionFields] = useState(false)
    useEffect(() => {
        if (formValues.pastReferralStatus.key === 'COMPLETED') setOpenCompletionFields(true)
        else setOpenCompletionFields(false)
    }, [formValues.pastReferralStatus.key])

    console.log('fa typeof', typeof formValues.lastContact)

    useEffect(() => {
        if ( formValues.lastContact === undefined && typeof errors.lastContact === 'string') setLastContactError(true)
       else setLastContactError(false)
        
    }, [formValues.lastContact , errors.lastContact ])
  
  useEffect(() => {
      if ( formValues.clientSource === null && typeof errors.clientSource === 'string') setClientSourceError(true)
     else setClientSourceError(false)
      
  }, [formValues.clientSource , errors.clientSource ])

  useEffect(() => {
    if ( formValues.referralCreator === '' && errors.referralCreator !== undefined) setReferralCreatorError(true)
   else setReferralCreatorError(false)
    
}, [formValues.referralCreator , errors.referralCreator ])


   console.log('referral creator',  formValues.referralCreator === '', typeof errors.referralCreator )

    const referralSenderInputs = [
        {
            label: "I created it.",
            formikKey: "SENT",
            value: "iSentIt"
        },
        {
            label: "I received it.",
            formikKey: "RECEIVED",
            value: "iReceivedIt"
        }
    ];

    const pastReferralStatus = [
        {
            label: "In Progress",
            value: "In Progress",
            key: "IN_PROGRESS"
        },
        {
            label: "On Hold",
            value: "On Hold",
            key: "ON_HOLD"
        },
        {
            label: "Completed",
            value: "closed",
            key: "COMPLETED"
        },
        {
            label: "Dead",
            value: "dead",
            key: "DEAD"
        }
    ]


    const [renderDatePicker, setDatePicker] = useState(false)
    return (
        <FieldsContainer>
            <InputContainerVariation
                triggerShowLabel={
                    formValues.lastContact ? "show" : "hide"
                }
                showLabelOnValue

            >
                <InputLabel>When was this referral exchanged?*</InputLabel>
                {/*   <TextLabel>When was this referral exchanged?*</TextLabel> */}
                <DatePicker
                    tabIndex="0"
                    placeholderText={"When was this referral exchanged?*"}
                   
                    selected={
                        formValues.lastContact
                            ? moment(formValues.lastContact).toDate()
                            : ""
                    }
                    onChange={date =>
                        onChange({
                            target: {
                                name: "lastContact",
                                value: date
                            }
                        })
                    }
                    // placeholderText={"When was this referral exchanged?*"}
                    required
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    customInput={
                        <Input
                            error={lastContactError}
                            
                            style={{ width: "calc(100% - 16px)" }}
                            value={
                                formValues.lastContact
                                    ? moment(formValues.lastContact).format("L")
                                    : null
                            }
                        />
                    }
                    name={"lastContact"}
                    maxDate={new Date()}
                />


            </InputContainerVariation>
            <InputContainerVariation
                triggerShowLabel={
                    formValues.referralCreator ? "show" : "hide"
                }
                showLabelOnValue


            >
                {/* <TextLabel>Did you create or receive this referral?*</TextLabel> */}
                <InputLabel>Did you create or receive this referral?*</InputLabel>
                <CheckBoxContainer>
                    <Select
                        options={referralSenderInputs}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "referralCreator",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="currentSatus"
                        placeholder="Did you create or receive this referral?*"
                        error={referralCreatorError}
                        value={formValues.referralCreator}
                    />

                </CheckBoxContainer>
            </InputContainerVariation>

            <InputContainerVariation
             triggerShowLabel={
                formValues.clientSource ? "show" : "hide"
            }
            showLabelOnValue
            
            
            >
                <InputLabel>What was the source of this Client?*</InputLabel>
                {/*  <TextLabel>What was the source of this Client?*</TextLabel> */}
                <CheckBoxContainer>
                    <Select
                        options={clientSourcesOptions}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "clientSource",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="clientSource"
                        placeholder="What was the source of this Client?*"
                        error={clientSourceError}
                        value={formValues.clientSource}
                    />
                </CheckBoxContainer>
            </InputContainerVariation>
            <InputContainerVariation
            triggerShowLabel={
                formValues.pastReferralStatus ? "show" : "hide"
            }
            showLabelOnValue
            
            
            >
                {/*   <TextLabel>
                    What is the current status of this referral?*
                </TextLabel> */}
                 <InputLabel>What is the current status of this referral?*</InputLabel>
                <InputContainer
                    
                >
                    <Select
                        options={pastReferralStatus}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "pastReferralStatus",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="currentSatus"
                        placeholder="What is the current status of this referral?*"
                        error={!!errors.pastReferralStatus}
                        value={formValues.pastReferralStatus}
                    />
                </InputContainer>

            </InputContainerVariation>


            {openCompletionFields &&
                <>
                    <InputContainerVariation>
                        {/*    <TextLabel>If revenue was generated please enter the amount</TextLabel> */}
                        <CheckBoxContainer>
                            <PriceField
                                width={1}
                                pr={2}
                                formValues={formValues}
                                onChange={onChange}
                                errors={errors}
                                fieldSelector={MaxPriceFieldSelector}
                                label='If revenue was generated please enter the amount'

                            />

                        </CheckBoxContainer>
                    </InputContainerVariation>

                    <InputContainerVariation>
                        {/*        <TextLabel>If a referral fee was exchanged please enter the amount</TextLabel> */}
                        <CheckBoxContainer>
                            <PriceField
                                width={1}
                                pr={2}
                                formValues={formValues}
                                onChange={onChange}
                                errors={errors}
                                fieldSelector={referralFeeExchangedSelector}
                                label='If a referral fee was exchanged please enter the amount'

                            />
                        </CheckBoxContainer>
                    </InputContainerVariation>
                </>
            }
        </FieldsContainer>
    )
}

export default TimingInfo;