import React from "react";
import SquareButton from "../../../atoms/shared/Buttons/SquareButton2";
import PopupBlurModal from "../../../atoms/shared/PopupBlurModal";
import { Title } from "../referralInformation-styles";
import { withTheme } from "styled-components";
import { Description, Text } from "../../../atoms/shared/Text";
import { withTranslation } from "react-i18next";
import { Box, Flex } from "rebass";
import { Icon } from "../../../atoms/shared/Icon";
import styled from "styled-components";

const HelpText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #929292;
    padding: 1em 0em;
`;

const AcceptDeclineConfirmationModal = (props) => {
    const {
        t,
        theme,
        onModalClose,
        fee,
        feeDueOptions, 
        feeCommencementOptions, 
        feeDurationOptions, 
        feeFrequencyOptions, 
        feeTypesOptions,
        referenceMode,
    } = props

    const { type, fee : feeAmount, frequency, duration, due, comments, commencement } = fee
    const selectedType = feeTypesOptions && feeTypesOptions.options && feeTypesOptions.options.find(feeType => feeType.key === type)
    let selectedTypelabel;
    if (selectedType) {
        selectedTypelabel = selectedType.label.split(" ")
        selectedTypelabel.splice(0, 1)
        selectedTypelabel = selectedTypelabel.join(" ")
    }
    let durationValue;
    const selectedFrequency = feeFrequencyOptions && feeFrequencyOptions.options && feeFrequencyOptions.options.find(feeF => feeF.key === frequency)
    const selectedCommencement = feeCommencementOptions && feeCommencementOptions.options && feeCommencementOptions.options.find(feeC => feeC.key === commencement)
    if (duration && feeDurationOptions && feeDurationOptions.optionsKeys.OTHER === duration) {
        durationValue = 'See Comments'
    } else {
        const selectedDuration = feeDurationOptions && feeDurationOptions.options && feeDurationOptions.options.find(feeD => feeD.key === duration)
        durationValue = selectedDuration && ` ${selectedDuration.label}`
    }

    const selectedDue = feeDueOptions && feeDueOptions.options && feeDueOptions.options.find(dueItem => dueItem.key === due)
    let dueTime;
    const otherOptionDue = feeDueOptions.optionsKeys && feeDueOptions.optionsKeys.OTHER
    if (selectedDue && otherOptionDue && selectedDue.key === otherOptionDue) {
        dueTime = 'See Comments'
    } else {
        dueTime = selectedDue && selectedDue.label
    }

    const modalContent = {
        title: t("termsDialogTitle") + t(fee && fee.type, { ns: "dictionary_referralFeeTypes" }),
        message: t(`termsDialogBody_${fee && fee.type}`, {
            fee: !referenceMode ? feeAmount : '[Referral Fee % goes here ]',
            duration: !referenceMode ? durationValue : '[ Duration goes here ]',
            comments: !referenceMode ? comments : '[ Comments created by NuOp Member goes here ]',
            frequency: !referenceMode ? (selectedFrequency && selectedFrequency.label) : '[ Frequency goes here ]',
            commencement: !referenceMode ? selectedCommencement && selectedCommencement.label : '[ Commencement goes here ]',
            due: !referenceMode ? dueTime : '[ Due date goes here ]'
        })
    };

    return (
        <PopupBlurModal
            open={true}
            width={theme.mobileDevice ? null : "860px"}
            modalContentStyles={{ borderRadius: theme.spacing[2] }}
        >
            <Flex p={["1em"]} alignItems="center" flexDirection="column">
                <Flex width="100%" justifyContent="flex-start">
                    <Icon icon="document" color={theme.newBlue} width={20} height={24} />
                </Flex>
                { referenceMode && (
                    <HelpText>
                        Below is the wordage that will be inserted into your referral agreement along with any specific referral fee data you enter into the referral fee fields.  If you are not satisfied with this approach please then consider choosing, "other" as your referral fee type so that you can fully customize the terms of your required fee.
                    </HelpText>
                )}
                <Flex
                    mt={theme.spacing[3]}
                    width="100%"
                    justifyContent="flex-start"
                >
                    <Text
                        fontSize="M"
                        fontWeight="bold"
                        lineHeight="S"
                        style={{ marginBottom: "1em" }}
                    >
                        {modalContent.title}
                    </Text>
                </Flex>
                <Description
                    color={theme.text}
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{ __html: modalContent.message }}
                />
                <Flex
                    width={[1]}
                    alignItems="center"
                    justifyContent="center"
                    mt={["2em"]}
                >
                    <Box width={[0.3]} pr={["1em"]}>
                        <SquareButton text={referenceMode ? 'Close' : 'Agree'} onClick={onModalClose} />
                    </Box>
                </Flex>
            </Flex>
        </PopupBlurModal>
    );
}

export default withTranslation([
    "referralInformation",
    "dictionary_referralFeeTypes"
])(withTheme(AcceptDeclineConfirmationModal));