import { useState, useEffect } from 'react'

export const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false)

    useEffect(() => {
        //** Checking media query  */
        const mediaAPI = window.matchMedia(query)
        if (mediaAPI.matches !== matches) {
            setMatches(mediaAPI.matches)
        }
        const listener = () => setMatches(mediaAPI.matches)
        window.addEventListener('resize', listener)
        // Cleaning and UnMounting
        return () => window.removeEventListener('resize', listener)
    }, [matches, query])

    return matches
}
