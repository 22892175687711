import {
    CONNECT,
    CONNECT_SUCC,
    CONNECT_FAIL,
    ACCEPT,
    ACCEPT_SUCC,
    CANCEL_CONNECTION,
    CANCEL_CONNECTION_SUCC,
    USER_CONNECT_INVITE_SUCC,
    USER_CONNECT_PENDING_INVITES,
    USER_ACCEPT_INVITE_SUCC,
    USER_UNFRIEND_SUCC,
    USER_UNFRIEND_ISLOADING,
    USER_CONTACTS,
    USER_CONNECT_SENT_INVITES,
    USER_GET_CONNECTED,
    USER_GET_CONNECTED_ISLOADING,
    FILTER_GET_CONNECTED_USER,
    NEAR_USERS_BROWSER_ISLOADING,
    NEAR_USERS_BROWSER_SUCCESS,
    NEAR_USERS_ISLOADING,
    NEAR_USERS_SUCCESS,
    NEAR_USERS_FAIL,
    NEAR_USERS_BROWSER_FAIL,
    USER_BLOCK_SUCCESS,
    FETCH_PROFILE_SUCC,
    USER_UNBLOCK_SUCCESS
} from "../../constants";
import { handleError } from "../modal";
import UserBusiness from "../../../../business/user";
import { RELATION_STATUS } from "../../../../assets/strings";

const connect = him => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const userProfile = getState().main.ui.view.profile.user.data;
            dispatch({
                type: CONNECT,
                payload: him
            });
            
            dispatch({
                type: USER_CONNECT_INVITE_SUCC,
                uid: him
            });

            dispatch({
                type: CONNECT_SUCC
            });

            if (userProfile.uid === him) {
                dispatch({
                    type: FETCH_PROFILE_SUCC,
                    payload: {
                        relationStatus: RELATION_STATUS.sentPending
                    }
                });
            }

            
            return await cloudfunctions.connect(him);
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: CONNECT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const acceptKnockConnectRequest = (id, notificationId, callback) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        let loading = true
        await cloudfunctions.accept(id, notificationId);
        callback(notificationId);
        loading = false
        return {
            loading
        }
    };
}

const declineKnockRequest = (id, notificationId, callback) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        await cloudfunctions.decline(id);
        callback(notificationId);
    };
}

const accept = (him, me, cb, notificationId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: ACCEPT
        });
        await cloudfunctions.accept(him, notificationId);
        dispatch({
            type: FETCH_PROFILE_SUCC,
            payload: {
                relationStatus: RELATION_STATUS.connected
            }
        });
        dispatch({
            type: USER_ACCEPT_INVITE_SUCC,
            him,
            me
        });
        if (cb) cb();
        return dispatch({
            type: ACCEPT_SUCC
        });
    };
};

const decline = (him, cb) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: ACCEPT
        });
        dispatch({
            type: FETCH_PROFILE_SUCC,
            payload: {
                relationStatus: RELATION_STATUS.notConnected
            }
        });
        await cloudfunctions.decline(him);
        dispatch({
            type: ACCEPT_SUCC
        });
        if (cb) cb();
        return;
    };
};

const cancelConnect = (him, cb) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: CANCEL_CONNECTION
        });
        dispatch({
            type: FETCH_PROFILE_SUCC,
            payload: {
                relationStatus: RELATION_STATUS.notConnected
            }
        });
        await cloudfunctions.cancelConnect(him);
        dispatch({
            type: CANCEL_CONNECTION_SUCC
        });
        if (cb) cb();
        return;
    };
};

const unfriend = (myId, uId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: USER_UNFRIEND_ISLOADING,
            payload: {
                isLoading: true
            }
        });
        try {
            dispatch({
                type: USER_UNFRIEND_SUCC,
                payload: {
                    him: uId
                }
            });
            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload: {
                    relationStatus: RELATION_STATUS.notConnected
                }
            });
            await cloudfunctions.unfriend(uId);
            return dispatch({
                type: USER_UNFRIEND_ISLOADING,
                payload: {
                    isLoading: false
                }
            });
        } catch (error) {
            dispatch(handleError(error));
        }
    };
};

const block = uid => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: ""
        });
        try {
            dispatch({
                type: USER_BLOCK_SUCCESS,
                payload: {
                    uid: uid
                }
            });
            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload: {
                    relationStatus: RELATION_STATUS.blockedByMe
                }
            });
            return await cloudfunctions.blockUser(uid);
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: "",
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const unblock = uid => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: ""
        });
        try {
            dispatch({
                type: USER_UNBLOCK_SUCCESS,
                payload: {
                    uid: uid
                }
            });
            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload: {
                    relationStatus: RELATION_STATUS.notConnected
                }
            });
            return await cloudfunctions.unblockUser(uid);
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: "",
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const subscribeToUserContactsChanged = userId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        firebase.subscribeToUserContactsChanged(userId, contacts => {
            dispatch({
                type: USER_CONTACTS,
                payload: {
                    contacts: contacts
                }
            });
        });
    };
};

const subscribeToUserContactSentInvites = userId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        firebase.subscribeToUserContactsSentInvitesChanged(
            userId,
            sent_invites => {
                dispatch({
                    type: USER_CONNECT_SENT_INVITES,
                    payload: {
                        sent_invites: sent_invites
                    }
                });
            }
        );
    };
};

const userPendingInvites = pending_invites => ({
    type: USER_CONNECT_PENDING_INVITES,
    payload: {
        pending_invites: pending_invites
    }
});

const getUsersToConnect = userId => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch({
                type: USER_GET_CONNECTED_ISLOADING,
                payload: {
                    isLoading: true
                }
            });
            let data = await cloudfunctions.getConnected();

            const me = getState().main.user.profile;

            let users =
                data && data.data && data.data.results ? data.data.results : [];
            //let users = data && data.results ? data.results : [];

            users = users.filter(user => {
                const relationStatus = UserBusiness.getUserRelationStatus(
                    me,
                    user._id
                );

                return relationStatus === RELATION_STATUS.notConnected;
            });
            if (users.length > 0) data.data.results = users;

            dispatch({
                type: USER_GET_CONNECTED,
                payload: {
                    // getConnected: data ? data.data : {}
                    getConnected: data ? data : {}
                }
            });
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            dispatch({
                type: USER_GET_CONNECTED_ISLOADING,
                payload: {
                    isLoading: false
                }
            });
        }
    };
};

/* const getUsersToConnect = (userId, lat, lon) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const data = await cloudfunctions.getConnected(userId, lat, lon)
        let users = getState().main.user.getConnected
        
        data.data.map(user => {
            const source = UserBusiness.getUserToConnectSource(user)
            if(!users[source]) users[source] = []
            if(!users[source].find(u => u.uid === user.uid)) users[source].push(user)
        })
            dispatch({
                type: USER_GET_CONNECTED,
                payload: {
                    getConnected: users,
                }
            });
    };
}; */

const getNearUsersByProfileLocation = (userId, lat, lon) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch({
                type: NEAR_USERS_ISLOADING,
                payload: {
                    isLoading: true
                }
            });
            // const data = await cloudfunctions.getNearUsers(userId, lat, lon);
            const data = await cloudfunctions.getNearUsers2(userId, lat, lon);
            dispatch({
                type: NEAR_USERS_SUCCESS,
                payload: {
                    nearUsers: data ? data : {}
                }
            });
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            dispatch({
                type: NEAR_USERS_FAIL,
                payload: {}
            });
            dispatch({
                type: NEAR_USERS_ISLOADING,
                payload: {
                    isLoading: false
                }
            });
        }
    };
};

const getNearUsersByBrowserLocation = (userId, lat, lon) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch({
                type: NEAR_USERS_BROWSER_ISLOADING,
                payload: {
                    isLoading: true
                }
            });

            const data = await cloudfunctions.getNearUsers2(userId, lat, lon);

            dispatch({
                type: NEAR_USERS_BROWSER_SUCCESS,
                payload: {
                    nearUsersBrowser: data && data ? data : {}
                }
            });
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            dispatch({
                type: NEAR_USERS_ISLOADING,
                payload: {
                    isLoading: false
                }
            });
            dispatch({
                type: NEAR_USERS_BROWSER_FAIL,
                payload: {}
            });
        }
    };
};

export {
    userPendingInvites,
    getNearUsersByBrowserLocation,
    getNearUsersByProfileLocation,
    getUsersToConnect,
    connect,
    accept,
    decline,
    cancelConnect,
    block,
    unblock,
    unfriend,
    subscribeToUserContactsChanged,
    subscribeToUserContactSentInvites,
    acceptKnockConnectRequest,
    declineKnockRequest
};
