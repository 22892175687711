import { Flex } from "rebass";
import styled from "styled-components";



export const CandidatesView = styled.div`
    box-sizing: border-box;
    border: 0.5px solid rgba(172, 172, 172, 0.5);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 12px;
    max-width: 860px;
    //åmargin: 0 auto;
`;
//repeat(2,380px)
export const CandidateCardContainer = styled.div`   
  padding-top: 16px;
   display: grid;
   gap: 8px;
  grid-template-columns: ${({ theme }) => (theme.mobileDevice) ? '100%' : 'repeat(2,380px)'};
  justify-content: center;
`

export const CandidateCard = styled.div`
   // width: 414px;
    min-height: 58px;
    display: flex;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid rgb(216, 216, 216);
    padding: 12px;
`

export const CandidateAvatar = styled.div`
   justify-content: center;
    display: flex;
    align-items: center;
    img {
        width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    }

`

export const DummyAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fafafa;

`

export const CandidateInfo = styled.div` 
justify-content: center;
  
    flex-direction: column;
    display: flex;
    gap: 8px;

`

export const CandidateName = styled.h2`  
    margin: 0px;
    color: rgb(0, 41, 84);
    font-size: 14px;
    font-weight: 500;
`

export const CandidateAddress = styled.div`
    color: rgb(0, 41, 84);
    font-size: 12px;
    font-weight: 500;
    
`


/// TAB CODE

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${props => props.marginBottom || "0"};
`;

export const Badge = styled(Flex)`
    position: absolute;
    top: 0.2rem;
    right: -0.1rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 0.5rem;
    margin: auto;
    background-color: #1FC07F;
`;

export const Tab = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    /* Previous styles for tabs */
    // border: ${props =>
        `1px solid ${
            props.current ? props.theme.blueMedium : props.theme.darkGray
        }`};
    //border-right: ${({ theme, first, last, current }) =>
        last
            ? `1px solid ${current ? theme.blueMedium : theme.darkGray}`
            : "none"};
    //border-left: ${({ theme, first, last, current }) =>
        first
            ? `1px solid ${current ? theme.blueMedium : theme.darkGray}`
            : "none"};
    // font-weight: ${props => (props.current ? "600" : "500")};

    border-bottom: ${props =>
        `5px solid ${
            props.current ? props.theme.blueMedium : props.theme.blueMedium
        } `};
    // border-right: ${({ theme, first, last, current }) =>
        last ? `1px solid ${theme.blueMedium}` : "none"};
    // border-left: ${({ theme, first, last, current }) =>
        first ? `1px solid ${theme.blueMedium}` : "none"};
    // border-radius: ${({ first, last }) =>
        first ? "4px 0 0 4px" : last ? "0 4px 4px 0" : "0 0 0 0"};
    padding: .5em 1em .5em 1em;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    line-height: 19px;
    color: ${props => (props.current ? "black" : "black")};
    // background-color: ${props =>
        props.current ? props.theme.blueMedium : "transparent"};
    transition: all .25s ease;
    min-width: 110px;
`;
