import React, { useContext, useState } from 'react'

export const StripeContext = React.createContext()

export function StripeProvider({ children }) {
  const [ customer_id, setCustomer_id ] = useState('')
  
  return (
    <StripeContext.Provider value={{
      customer_id,
      setCustomer_id
    }} >
      { children  }
    </StripeContext.Provider>
  )
}

export function useStripe() {
  return useContext(StripeContext)
}