import { PROFILE_WHOISWATCHING } from '../../constants';
import { handleError } from '../modal'
import { setValue, setCurrentSection, addUserToReferral } from '../sendReferral'
import { SECTION_SHARE, SECTION_FORM } from '../../../../components/ui/presentation/views/SendReferral/constants'
import ElasticBusiness from '../../../../business/elastic'
import {
    PROFILE_CONTACTINFO_OPEN,
    PROFILE_FETCH_COMMON_CONTACTS,
    PROFILE_FETCH_COMMON_CONTACTS_SUCC,
    PROFILE_FETCH_COMMON_CONTACTS_FAIL,
    PROFILE_FETCH_OPEN_OPS,
    PROFILE_FETCH_OPEN_OPS_SUCC,
    PROFILE_FETCH_OPEN_OPS_FAIL,
    PROFILE_CLEAR_OPEN_OPS,
    PROFILE_FAVORITE_USER,
    PROFILE_GET_USER_FAVORITES_LOADING,
    PROFILE_GET_USER_FAVORITES_SUCCESS,
    PROFILE_GET_USER_FAVORITES_FAIL,
    PROFILE_GET_USER_ENDORSEMENTS_LOADING,
    PROFILE_GET_USER_ENDORSEMENTS_SUCCESS,
    PROFILE_GET_USER_ENDORSEMENTS_FAIL,
    PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_FAIL,
    PROFILE_UPDATE_ENDORSEMENT_SUCCESS,
    PROFILE_UPDATE_ENDORSEMENT_FAIL,
    PROFILE_DELETE_ENDORSEMENT_SUCCESS,
    PROFILE_DELETE_ENDORSEMENT_FAIL,
    PROFILE_GET_USER_GRATITUDES_LOADING,
    PROFILE_GET_USER_GRATITUDES_SUCCESS,
    PROFILE_GET_USER_GRATITUDES_FAIL,
    PROFILE_ADD_FIRST_GRATITUDE_SUCCESS,
    PROFILE_ADD_GRATITUDE_SUCCESS,
    PROFILE_ADD_GRATITUDE_FAIL,
    PROFILE_UPDATE_GRATITUDE_SUCCESS,
    PROFILE_UPDATE_GRATITUDE_FAIL,
    PROFILE_DELETE_GRATITUDE_SUCCESS,
    PROFILE_DELETE_GRATITUDE_FAIL,
    PROFILE_OPEN_ENDORSEMENT_DETAILS,
    PROFILE_OPEN_GRATITUDE_DETAILS,
    PROFILE_OPEN_OP_REVIEW_DETAILS,
    PROFILE_HIDE_ENDORSEMENT_SUCCESS,
    PROFILE_HIDE_GRATITUDE_SUCCESS,
    PROFILE_GET_USER_OP_REVIEWS_LOADING,
    PROFILE_GET_USER_OP_REVIEWS_SUCCESS,
    PROFILE_GET_USER_OP_REVIEWS_FAIL,
    PROFILE_ADD_OP_REVIEW_SUCCESS,
    PROFILE_ADD_OP_REVIEW_FAIL,
    PROFILE_UPDATE_OP_REVIEW_SUCCESS,
    PROFILE_UPDATE_OP_REVIEW_FAIL,
    PROFILE_DELETE_OP_REVIEW_SUCCESS,
    PROFILE_DELETE_OP_REVIEW_FAIL
} from "../../constants";
import { push } from 'connected-react-router';
import Firebase from '../../../../libs/Firebase';

const clearUserOpenOps = () => ({
    type: PROFILE_CLEAR_OPEN_OPS
})

const toggleFavoriteStatus = favorited => ({
    type: PROFILE_FAVORITE_USER,
    payload: {
        favorited
    }
});

const toggleShowEndorsementDetails = () => ({
    type: PROFILE_OPEN_ENDORSEMENT_DETAILS,
})

const toggleShowGratitudeDetails = () => ({
    type: PROFILE_OPEN_GRATITUDE_DETAILS
});

const toggleShowOpReviewDetails = () => ({
    type: PROFILE_OPEN_OP_REVIEW_DETAILS
});

const sendReferral = (user) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            const statics = getState().main.ui.global.statics
            const shareWithKeys = statics.referralShareWith && statics.referralShareWith.optionsKeys
            dispatch(setValue(SECTION_SHARE, "shareWith", shareWithKeys.MEMBERS));
            const normUser = ElasticBusiness.elasticUser(user.uid, user.address, user.company_info && user.company_info.name, user.email, user.lastname, user.name, user.phone, user.state, user.type, user.avatar, user.userName)
            dispatch(addUserToReferral(normUser))
            dispatch(setCurrentSection(SECTION_FORM))
            dispatch(push("/send-referral"))
        } catch (error) {
            dispatch(handleError(error));
            console.error('error', error)
        }
    }
};

const whoIsWatchingMyProfileListener = () => {
    return async (dispatch, getState, {
        firebase
    }) => {
        try {
            const myId = getState().main.user.uid
            firebase.subscribeToWhoIsWatchingMyProfile(myId, (data) => {
                dispatch({
                    type: PROFILE_WHOISWATCHING,
                    payload: {
                        whoIsWatching: data
                    }
                })
            })
        } catch (error) {
            dispatch(handleError(error));
            console.error('error', error)
        }
    }
};

const unsubscribeWhoIsWatchingMyProfile = () => {
    return async (dispatch, getState, {
        firebase
    }) => {
        try {
            return await firebase.unsubscribeToWhoIsWatchingMyProfile()
        } catch (error) {
            dispatch(handleError(error));
            console.error('unsubscribeWhoIsWatchingMyProfile error', error)
        }
    }
};

const setWatchingProfile = (myId, id, add = true) => {
    return async (dispatch, getState, {
        firebase
    }) => {
        try {
            return await firebase.setWatchingProfile(myId, id, add)
        } catch (error) {
            dispatch(handleError(error));
            console.error('setWatchingProfile error', error)
        }
    }
};

const setContactInfoOpen = (open) => {
    return {
        type: PROFILE_CONTACTINFO_OPEN,
        payload: {
            contactInfoOpen: open
        }
    };
};

const fetchCommonContacts = uidsList => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: PROFILE_FETCH_COMMON_CONTACTS
        });
        try {
            const uidsArray = Array.isArray(uidsList)
                ? uidsList
                : Firebase.flatArrayFromFirebaseList(uidsList);
            let data = await firebase.fetchUserList(uidsArray);

            //TODO: Enjoy your life. 👽
            return dispatch({
                type: PROFILE_FETCH_COMMON_CONTACTS_SUCC,
                payload: data
            });
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: PROFILE_FETCH_COMMON_CONTACTS_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const fetchUserOpenOps = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: PROFILE_FETCH_OPEN_OPS
        });
            try {
                const user = getState().main.ui.view.profile.user.data;
                const {
                    name,
                    lastname,
                } = user;
                const response = await cloudfunctions.fetchBigBoard({
                    from: 0,
                    size: 3,
                    member_name: `${name} ${lastname}`,
                    status: ["SUBMITTED"]
                });

                if (response && response.data) {
                    let { data } = response && response.data;
                    data = Object.values(data);

                    dispatch({
                        type: PROFILE_FETCH_OPEN_OPS_SUCC,
                        payload: data
                    });
                } else {
                    dispatch({
                        type: PROFILE_FETCH_OPEN_OPS_SUCC,
                        payload: []
                    });
                }
                return response;
            } catch (error) {
                dispatch(handleError(error));
                return dispatch({
                    type: PROFILE_FETCH_OPEN_OPS_FAIL,
                    errors: cloudfunctions.parseRequestError(error)
                });
        }
    };
};

const favoriteUser = (userId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(toggleFavoriteStatus(true));
            const response = await cloudfunctions.favoriteUser(userId);
            return response;
        } catch (error) {
            dispatch(handleError(error));
            console.error('favoriteUser error', error)
        }
    }
}

const unfavoriteUser = (userId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(toggleFavoriteStatus(false));
            const response = await cloudfunctions.unfavoriteUser(userId);
            return response;
        } catch (error) {
            dispatch(handleError(error));
            console.error('favoriteUser error', error)
        }
    }
}

const getUserFavorites = (userId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({
                type: PROFILE_GET_USER_FAVORITES_LOADING
            });
            const response = await cloudfunctions.getUserFavorites(userId);
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_GET_USER_FAVORITES_SUCCESS,
                    payload: data
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("getUserFavorites error", error);
            return dispatch({
                type: PROFILE_GET_USER_FAVORITES_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    }
}

const getUserEndorsements = (userId, filters) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({
                type: PROFILE_GET_USER_ENDORSEMENTS_LOADING
            });
            const itsMe = getState().main.ui.view.profile.user.data.itsMe;
            const [receivedResponse, sentResponse] = await Promise.all([
                cloudfunctions.getUserEndorsements(userId, "received", { ...filters, show_hidden: itsMe }),
                cloudfunctions.getUserEndorsements(userId, "sent", filters)
            ]);
            const received =
                receivedResponse && receivedResponse.data
                    ? receivedResponse.data
                    : [];
            const sent =
                sentResponse && sentResponse.data ? sentResponse.data : [];
            dispatch({
                type: PROFILE_GET_USER_ENDORSEMENTS_SUCCESS,
                payload: {
                    received,
                    sent
                }
            });
            return { received, sent };
        } catch (error) {
            dispatch(handleError(error));
            console.error("getUserEndorsements error", error);
            return dispatch({
                type: PROFILE_GET_USER_ENDORSEMENTS_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const mapUserToElasticStructure = user => ({
    ...user,
    full_name: user.full_name || `${user.name} ${user.lastname}`,
    user_type: user.user_type || user.type
});

const addEndorsement = (endorsement, addressedUserObject) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const endorsing_user = getState().main.user.profile;
            // If the user is endorsing from his own profile, we don't need to fetch the user data again
            // We will add the data to the endorsements sent list
            const itsMe = getState().main.ui.view.profile.user.data.itsMe;
            const response = await cloudfunctions.addEndorsement(endorsement);
            if (response && response.data) {
                const { data } = response;

                dispatch({
                    // If user is endorsing from his own profile, we should add the endorsement to the sent list
                    // Otherwise, we should add it to the received list of current profile user
                    type: itsMe
                        ? PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS
                        : PROFILE_ADD_ENDORSEMENT_SUCCESS,
                    payload: {
                        ...data.endorsement,
                        endorsing_user: mapUserToElasticStructure(
                            endorsing_user
                        ),
                        addressed_user: mapUserToElasticStructure(
                            addressedUserObject
                        )
                    }
                });
                return response;
            }
        } catch (error) {
            // dispatch(handleError(error));
            console.error("addEndorsement error", error);
            dispatch({
                type: PROFILE_ADD_ENDORSEMENT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
            return error.response;
        }
    };
};

const updateEndorsement = endorsement => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.updateEndorsement(
                endorsement
            );
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_UPDATE_ENDORSEMENT_SUCCESS,
                    payload: {
                        ...data.updated_endorsement,
                        endorsing_user: endorsement.endorsing_user,
                        addressed_user: endorsement.addressed_user
                    }
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("updateEndorsement error", error);
            return dispatch({
                type: PROFILE_UPDATE_ENDORSEMENT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const toggleHideEndorsement = endorsement => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const hiddenEndorsement = {
                ...endorsement,
                hidden: !endorsement.hidden
            };
            console.error("toggleHideEndorsement", hiddenEndorsement)
            dispatch({
                type: PROFILE_HIDE_ENDORSEMENT_SUCCESS,
                payload: {
                    ...hiddenEndorsement
                }
            });
            const response = await cloudfunctions.updateEndorsement(
                hiddenEndorsement
            );
            if (response && response.data) {
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("toggleHideEndorsement error", error);
            return dispatch({
                type: PROFILE_UPDATE_ENDORSEMENT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const deleteEndorsement = endorsementId => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.deleteEndorsement(
                endorsementId
            );
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_DELETE_ENDORSEMENT_SUCCESS,
                    payload: data.endorsement_id
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("deleteEndorsement error", error);
            return dispatch({
                type: PROFILE_DELETE_ENDORSEMENT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const getUserGratitudes = (userId, filters) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({
                type: PROFILE_GET_USER_GRATITUDES_LOADING
            });
            const [receivedResponse, sentResponse] = await Promise.all([
                cloudfunctions.getUserGratitudes(userId, "received", filters),
                cloudfunctions.getUserGratitudes(userId, "sent", filters)
            ]);
            const received =
                receivedResponse && receivedResponse.data
                    ? receivedResponse.data
                    : [];
            const sent =
                sentResponse && sentResponse.data ? sentResponse.data : [];
            dispatch({
                type: PROFILE_GET_USER_GRATITUDES_SUCCESS,
                payload: {
                    received,
                    sent
                }
            });
            return { received, sent };
        } catch (error) {
            dispatch(handleError(error));
            console.error("getUserGratitudes error", error);
            return dispatch({
                type: PROFILE_GET_USER_GRATITUDES_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const addGratitude = (gratitude, addressedUserObject) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const grateful_user = getState().main.user.profile;
            // If the user is making gratitude from his own profile, we don't need to fetch the user data again
            // We will add the data to the gratitudes sent list
            const itsMe = getState().main.ui.view.profile.user.data.itsMe;
            const response = await cloudfunctions.addGratitude(gratitude);
            if (response && response.data) {
                const { data } = response;

                dispatch({
                    // If user is making gratitude from his own profile, we should add the gratitude to the sent list
                    // Otherwise, we should add it to the received list of current profile user
                    type: itsMe
                        ? PROFILE_ADD_FIRST_GRATITUDE_SUCCESS
                        : PROFILE_ADD_GRATITUDE_SUCCESS,
                    payload: {
                        ...data.gratitude,
                        grateful_user: mapUserToElasticStructure(grateful_user),
                        addressed_user: mapUserToElasticStructure(
                            addressedUserObject
                        )
                    }
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("addGratitude error", error);
            return dispatch({
                type: PROFILE_ADD_GRATITUDE_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const updateGratitude = gratitude => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.updateGratitude(gratitude);
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_UPDATE_GRATITUDE_SUCCESS,
                    payload: {
                        ...data.updated_gratitude,
                        grateful_user: gratitude.grateful_user,
                        addressed_user: gratitude.addressed_user
                    }
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("updateGratitude error", error);
            return dispatch({
                type: PROFILE_UPDATE_GRATITUDE_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const toggleHideGratitude = gratitude => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const hiddenGratitude = {
                ...gratitude,
                hidden: !gratitude.hidden
            };

            dispatch({
                type: PROFILE_HIDE_GRATITUDE_SUCCESS,
                payload: {
                    ...hiddenGratitude
                }
            });
            const response = await cloudfunctions.updateGratitude(
                hiddenGratitude
            );
            if (response && response.data) {
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("toggleHideGratitude error", error);
            return dispatch({
                type: PROFILE_UPDATE_ENDORSEMENT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const deleteGratitude = gratitudeId => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.deleteGratitude(gratitudeId);
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_DELETE_GRATITUDE_SUCCESS,
                    payload: data.gratitude_id
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("deleteGratitude error", error);
            return dispatch({
                type: PROFILE_DELETE_GRATITUDE_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const getUserOpsReviews = (userId, filters) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({
                type: PROFILE_GET_USER_OP_REVIEWS_LOADING
            });
            const itsMe = getState().main.ui.view.profile.user.data.itsMe;
            const [receivedResponse, sentResponse] = await Promise.all([
                cloudfunctions.getUserOpsReviews(userId, "received", filters),
                itsMe && cloudfunctions.getUserOpsReviews(userId, "sent", filters)
            ]);
            const received =
                receivedResponse && receivedResponse.data
                    ? receivedResponse.data
                    : [];
            const sent =
                sentResponse && sentResponse.data ? sentResponse.data : [];
            dispatch({
                type: PROFILE_GET_USER_OP_REVIEWS_SUCCESS,
                payload: {
                    received,
                    sent
                }
            });
            return { received, sent };
        } catch (error) {
            dispatch(handleError(error));
            console.error("getUserOpsReviews error", error);
            return dispatch({
                type: PROFILE_GET_USER_OP_REVIEWS_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const addOpReview = (opReview) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const reviewer_user = getState().main.user.profile;
            const { reviewed_user } = opReview;

            opReview.reviewed_user = reviewed_user.uid;
            opReview.reviewer_user = reviewer_user.uid;

            const response = await cloudfunctions.addOpReview(opReview);
            if (response && response.data) {
                const { data } = response;

                dispatch({
                    type: PROFILE_ADD_OP_REVIEW_SUCCESS,
                    payload: {
                        ...data.created_op_review,
                        reviewed_user,
                        reviewer_user
                    }
                });
                dispatch(
                    push(
                        `/profile/${reviewed_user.username}?op-review-success=true`
                    )
                );
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("addOpReview error", error);
            return dispatch({
                type: PROFILE_ADD_OP_REVIEW_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const updateOpReview = opReview => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.updateOpReview(opReview);
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_UPDATE_OP_REVIEW_SUCCESS,
                    payload: {
                        ...data.updated_op_review,
                        reviewed_user: opReview.reviewed_user,
                        reviewer_user: opReview.reviewer_user
                    }
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("updateOpReview error", error);
            return dispatch({
                type: PROFILE_UPDATE_OP_REVIEW_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const deleteOpReview = review_id => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const response = await cloudfunctions.deleteOpReview(review_id);
            if (response && response.data) {
                const { data } = response;
                dispatch({
                    type: PROFILE_DELETE_OP_REVIEW_SUCCESS,
                    payload: data.review_id
                });
                return response;
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("deleteOpReview error", error);
            return dispatch({
                type: PROFILE_DELETE_OP_REVIEW_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

export {
    setContactInfoOpen,
    setWatchingProfile,
    sendReferral,
    whoIsWatchingMyProfileListener,
    unsubscribeWhoIsWatchingMyProfile,
    fetchCommonContacts,
    fetchUserOpenOps,
    clearUserOpenOps,
    favoriteUser,
    unfavoriteUser,
    getUserFavorites,
    getUserEndorsements,
    addEndorsement,
    updateEndorsement,
    toggleHideEndorsement,
    deleteEndorsement,
    getUserGratitudes,
    addGratitude,
    updateGratitude,
    toggleHideGratitude,
    deleteGratitude,
    getUserOpsReviews,
    addOpReview,
    updateOpReview,
    deleteOpReview,
    toggleShowEndorsementDetails,
    toggleShowGratitudeDetails,
    toggleShowOpReviewDetails
};