import React from "react";
import { Heading, Text, Flex, Box } from "rebass";
import styled, { withTheme } from "styled-components";
import CompanyName from "../../../../../assets/svg/NuOpRectangleTransparent.svg";
import { MEDIABREAKPOINTS, theme } from "../../../../../theme";
import SquareButton2 from "../../atoms/shared/Buttons/SquareButton2";
import { updatePWA } from "../../../../../redux/actions/creators/shared";

const FlexGreenContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
    min-width: 100vw;
`;

const Head = styled(Heading)`
    font-size: 3em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 2em;
    }
`;

const Description = styled(Text)`
    font-size: 2em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 1em;
    }
`;

class ErrorPage extends React.PureComponent {

    handleUpdatePwa = () => this.props.dispatch(updatePWA())

    render() {
        return (
            <FlexGreenContainer>
                <img
                        alt="NuOp"
                        src={CompanyName}
                        style={{
                            width: this.props.theme.mobileDevice
                                ? "120px"
                                : "260px",
                            paddingLeft: "1rem"
                        }}
                    />
                <br />
                <Head color={'#00A15F'}>{'Whoops! Something happened!'}</Head>
                <br />
                <Description color='#28372E'> {'Please update to the latest version'}</Description>
                <Box mt={[theme.spacing[4]]}>
                    <SquareButton2
                        text="Update"
                        bg={theme.shamRockGreen}
                        onClick={this.handleUpdatePwa}
                    />
                </Box>
            </FlexGreenContainer>
        );
    }
}

export default withTheme(ErrorPage);
