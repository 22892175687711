import { cleaningObject } from "../libs/Utils"


export function realEstateDetailsHandler(formValues)  {
    
   const  {
    credit,
    homeSaleContingency,
    maxLotSize,
    minBeds,
    minLotSize,
    minUnits,
    pets,
    acres,
    baths,
    beds,
    clientType,
    estimatedPrice,
    financingInfo,
    maxPrice,
    maxSize,
    maxSquareFeet,
    maxUnits,
    minSize,
    minSquareFeet,
    propertySubType,
    propertyType,
    size,
    sizeUnit,
    squareFeet,
    units,
    retailSpace,
   } = formValues
//    console.log(
//        minLotSize.value.replace(/[^a-zA-Z]+/g, "").toUpperCase(),
//        minLotSize.value.replace(/[^a-zA-Z]+/g, ""),
//        "minLotSize"
//    );
//    return;
  let realEstateDetailKeys = {
     propertySubType: (propertySubType && propertySubType.value) ? propertySubType.value : null,
     propertyType:    (propertyType && propertyType.value) ? propertyType.value : null,
     financingInfo:   financingInfo ? financingInfo.value : null,
   }

    // console.log('formValues', formValues)
   
    if (  clientType.value === 'BUYER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'APARTMENT'  ) {
         realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : maxPrice,
            beds:                minBeds ? parseInt(minBeds) : null,
            minSize:             minSquareFeet ? parseInt(minSquareFeet) : null,
            sizeUnit:            minSquareFeet ? "SQFT" : null,
            homeSaleContingency: homeSaleContingency.value,
         }
    } 

    if (  clientType.value === 'BUYER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'CONDO'  ) {
        realEstateDetailKeys = {
           ...realEstateDetailKeys,
           maxPrice:            maxPrice      ? parseInt(maxPrice.substring(1)) : null,
           beds:                minBeds       ? parseInt(minBeds) : null,
           minSize:             minSquareFeet ? parseInt(minSquareFeet) : null,
           sizeUnit:            minSquareFeet ? "SQFT" : null,
           homeSaleContingency: homeSaleContingency.value,
           //minLotSize:          minLotSize ? parseInt(minLotSize.value) : null
        }
   }

   if (  clientType.value === 'BUYER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'MULTI_FAMILY'  ) {
            realEstateDetailKeys = {
                ...realEstateDetailKeys,
                maxPrice:            parseInt(maxPrice.substring(1)),
                homeSaleContingency: homeSaleContingency.value,
                minUnits:            minUnits ? parseInt(minUnits.value) : null
            }
   }

   if (  clientType.value === 'BUYER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'SINGLE_FAMILY'  ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : maxPrice,
            beds:                minBeds ? parseInt(minBeds) : null,
            minSize:             minSquareFeet ? parseInt(minSquareFeet) : null,
            sizeUnit:            minSquareFeet ?  "SQFT" : null,
            homeSaleContingency: homeSaleContingency.value,
            lotSizeUnit:         minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase(),
            minLotSize:          minLotSize ? parseInt(minLotSize.value) : null
        }
   }

   if (  clientType.value === 'BUYER' && propertyType.value === 'COMMERCIAL' ) {
        realEstateDetailKeys = {
                ...realEstateDetailKeys,
                maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : maxPrice,
                sizeUnit:            (minSquareFeet || maxSquareFeet) ? "SQFT" : null,
                minUnits:            minUnits ? parseInt(minUnits) : null, // POSITIVE NUMBER
                maxUnits:            maxUnits ? parseInt(maxUnits) : null,
                minSize:             minSize ? parseInt(minSize) : minSquareFeet ? parseInt(minSquareFeet) : null,
                maxSize:             maxSize ? parseInt(maxSize) : maxSquareFeet ? parseInt(maxSquareFeet) : null,
        }
   }

   if (  clientType.value === 'BUYER' && propertyType.value === 'LAND' ) {
        realEstateDetailKeys = {
                ...realEstateDetailKeys,
                maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : maxPrice,
                minLotSize:          minLotSize ? parseInt(minLotSize.value) : null,
                lotSizeUnit:         minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase(),
        }
   }



    /***    SELLER INPUTS FIELDS  ***/
   if (clientType.value === 'SELLER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'APARTMENT') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice:  estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            beds:            beds ? parseInt(beds) : null,
            baths:           baths ? parseInt(baths) : null,
            size:            squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:        squareFeet ? "SQFT" : null,
        }
    } 


    if (clientType.value === 'SELLER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'CONDO') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice:      estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            beds:                beds ? parseInt(beds) : null,
            baths:               baths ? parseInt(baths) : null,
            size:                squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:            squareFeet ? "SQFT" : null,
        }
    }

    if (clientType.value === 'SELLER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'MULTI_FAMILY') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            units:          units ? parseInt(units) : null
        }
    }

    if (clientType.value === 'SELLER' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'SINGLE_FAMILY') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            baths:          baths ? parseInt(baths) : null,    
            beds:           beds ? parseInt(beds) : null,
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? "SQFT" : null,
            acres:          acres ? parseInt(acres) : null,
        }
    }

    if (clientType.value === 'SELLER' && propertyType.value === 'COMMERCIAL') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? "SQFT" : null,
            units:          units ? parseInt(units) : null
        }
    }

    if (clientType.value === 'SELLER' && propertyType.value === 'LAND') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            acres:               acres ? parseInt(acres) : null,
        }
    }


    if ( clientType.value === 'TENANT' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'APARTMENT'  ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:      maxPrice ? parseInt(maxPrice.substring(1)) : null,
            timePeriod:    "MONTH",
            beds:          minBeds ? parseInt(minBeds) : null,
            size:          minSquareFeet ? parseInt(minSquareFeet) : null,
            credit:        credit ? credit.value : null,
            sizeUnit:      minSquareFeet ? "SQFT" : null,
            pets:          (pets && pets.label === 'Yes') ? 'YES' : (pets && pets.label === 'Not Sure') ? 'NOT_SURE' : 'NO'
        }
    }
    if (  clientType.value === 'TENANT' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'CONDO'  ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:      maxPrice ? parseInt(maxPrice.substring(1)) : null,
            timePeriod:    "MONTH",
            beds:          minBeds ? parseInt(minBeds) : null,
            size:          minSquareFeet ? parseInt(minSquareFeet) : null,
            credit:        credit ? credit.value : null,
            sizeUnit:      minSquareFeet ? "SQFT" : null,
            pets:          (pets && pets.label === 'Yes') ? 'YES' : (pets && pets.label === 'Not Sure') ? 'NOT_SURE' : 'NO'
        }
    }
    
    if (  clientType.value === 'TENANT' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'SINGLE_FAMILY') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:      maxPrice ? parseInt(maxPrice.substring(1)) : null,
            timePeriod:    "MONTH",
            beds:          minBeds ? parseInt(minBeds) : null,
            size:          minSquareFeet ? parseInt(minSquareFeet) : null,
            minLotSize:    minLotSize ? parseInt(minLotSize.value) : null,
            lotSizeUnit:   minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase(),
            credit:        credit ? credit.value : null,
            sizeUnit:      minSquareFeet ? "SQFT" : null,
            pets:          (pets && pets.label === 'Yes') ? 'YES' : (pets && pets.label === 'Not Sure') ? 'NOT_SURE' : 'NO'
        }
    }
   
    if (  clientType.value === 'TENANT' && propertyType.value === 'COMMERCIAL' ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:      maxPrice ? parseInt(maxPrice.substring(1)) : null,
            beds:          minBeds ? parseInt(minBeds) : null,
            size:          minSquareFeet ? parseInt(minSquareFeet) : null,
            minLotSize:    minLotSize ? parseInt(minLotSize.value) : null,
            minUnits:      minUnits ? parseInt(minUnits) : null,
            maxUnits:      maxUnits ? parseInt(maxUnits) : null,
            timePeriod:    (retailSpace.value === 'SQUARE_FEET_PER_MONTH') ? 'MONTH' : 'YEAR',
            sizeUnit:      minSquareFeet ? "SQFT" : null,
        }
    }

    if (  clientType.value === 'TENANT' && propertyType.value === 'LAND' ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            maxPrice:      maxPrice ? parseInt(maxPrice.substring(1)) : null,
            minLotSize:    minLotSize ? parseInt(minLotSize.value) : null,
            lotSizeUnit:   minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase(),
            timePeriod:    'MONTH',
        }
    }


    /** LANDLORD INPUTS FIELDS */

    if ( clientType.value === 'LANDLORD' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'APARTMENT'  ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            beds:           beds ? parseInt(beds) : null,
            baths:          baths ? parseInt(baths) : null,
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? 'SQFT' : null
        }
    }
    if ( clientType.value === 'LANDLORD' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'CONDO'  ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            beds:           beds ? parseInt(beds) : null,
            baths:          baths ? parseInt(baths) : null,
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? 'SQFT' : null
        }
    }
    
    if (  clientType.value === 'LANDLORD' && propertyType.value === 'RESIDENTIAL' && propertySubType.value === 'SINGLE_FAMILY') {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            acres:          acres ? parseInt(acres) : null,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            beds:           beds ? parseInt(beds) : null,
            baths:          baths ? parseInt(baths) : null,
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? 'SQFT' : null
        }
    }

    if (  clientType.value === 'LANDLORD' && propertyType.value === 'COMMERCIAL' ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            timePeriod:     (retailSpace.value === 'SQUARE_FEET_PER_MONTH') ? 'MONTH' : 'YEAR',
            size:           squareFeet ? parseInt(squareFeet) : null,
            sizeUnit:       squareFeet ? 'SQFT' : null,
            units:          units ? parseInt(units) : null 
        }
    }

    if (  clientType.value === 'LANDLORD' && propertyType.value === 'LAND' ) {
        realEstateDetailKeys = {
            ...realEstateDetailKeys,
            estimatedPrice: estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
            acres:          acres ? parseInt(acres) : null
        }
    }

  


    return cleaningObject(realEstateDetailKeys)
}