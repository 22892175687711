import React, { useState } from "react";
import { InputSquare, InputLabel, FormGroup, IconContainer } from "./styles";
import { withTheme } from "styled-components";

const SquareInput = props => {
    const {
        theme,
        type,
        required,
        value,
        name,
        innerRef,
        label,
        icon,
        id,
        labelStyles,
        rightIcon: RightIcon,
        leftIcon: LeftIcon,
        onClickRightIcon,
        color,
        height,
        rightIconStyles,
        leftIconStyles,
        inputStyles,
        onFocus,
        onBlur,
        wrongPass,
        ...other
    } = props;
    const [focused, setFocused] = useState(false)
   console.log('SquareWhiteInput!', wrongPass)
    const h = height ? height * 0.45 : 20

    return (
        <FormGroup>
            {label && (
                <InputLabel htmlFor={id ? id : name} style={{ color: color, ...labelStyles }}>
                    {label}
                </InputLabel>
            )}

            {LeftIcon && (
                //  <LeftIconContainer height={h}  >
                <LeftIcon
                    style={{
                        display: 'block',
                        position: 'absolute',
                        left: '1em',
                        bottom: `calc(50% - ${(h / 2) - 1}px)`,
                        cursor: 'pointer',
                        // zIndex: 9
                    }}
                    color={focused ? theme.blueMedium : theme.taupeGray}
                    width={h}
                    height={h}
                    {...leftIconStyles}
                />
                //</LeftIconContainer>
            )}
            <InputSquare
                label={label}
                color={color}
                rightIcon={RightIcon}
                leftIcon={LeftIcon}
                required={required}
                value={value}
                ref={innerRef}
                icon={icon}
                type={type}
                name={name}
                id={id ? id : name}
                height={height}
                style={{ ...inputStyles }}
                {...other}
                onFocus={(e) => {setFocused(true); onFocus && onFocus(e)}}
                onBlur={(e) => {setFocused(false); onBlur && onBlur(e)}}
            />
            {RightIcon && (
                <IconContainer onClick={onClickRightIcon}>
                    <RightIcon color={theme.blueMedium} width={h} height={`${h}px`} {...rightIconStyles} />
                </IconContainer>
            )}
        </FormGroup>
    );
};

export default withTheme(SquareInput);