import axios from "axios";
import envConfig from "../config";
import {
    SEARCH_TYPE_LOCATION,
} from '../components/ui/presentation/views/Search/constants'
import qs from "qs";
const emptyAPIMock = {
    results: [],
    related: [],
    count: 0,
    facets: {
        "Focus Other": {
            doc_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
            buckets: []
        },
        "Type Filter": {
            doc_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
            buckets: []
        },
        "Focus Residential": {
            doc_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
            buckets: []
        },
        "Focus Commercial": {
            doc_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
            buckets: []
        },
        Languages: {
            doc_count_error_upper_bound: 0,
            sum_other_doc_count: 0,
            buckets: []
        }
    }
};


class CloudFunctions {
    constructor(config) {
        this.DEFAULT_OPTIONS = {
            maxAttempts: 1,
            waitTime: 1000,
            errorCodes: []
        };
        this.ALLOWED_RETRY_METHODS = [
            "get",
            "put",
            "delete",
            "head",
            "options",
            "post"
        ];

        this.shouldRetry = error => {
            const { method: httpMethod, errorCodes } = error.config;
            const { maxAttempts, __retryCount: retryCount = 0 } = error.config;
            const { response: { status: statusCode } = {} } = error;

            let shouldRetryForMethod = false;
            let shouldRetryForStatus = false;

            if (this.ALLOWED_RETRY_METHODS.includes(httpMethod))
                shouldRetryForMethod = true;
            if (
                (errorCodes.length === 0 &&
                    statusCode >= 500 &&
                    statusCode < 600) ||
                errorCodes.includes(statusCode) ||
                !statusCode
            ) {
                shouldRetryForStatus = true;
            }
            if (
                shouldRetryForMethod &&
                shouldRetryForStatus &&
                retryCount < maxAttempts
            ) {
                return true;
            }
            return false;
        };

        this.axiosSetCFInterceptors = (axios, options = {}) => {
            const retryConfig = {
                maxAttempts: 0 /* options.maxAttempts
                    ? options.maxAttempts
                    : this.DEFAULT_OPTIONS.maxAttempts */,
                waitTime: options.waitTime
                    ? options.waitTime
                    : this.DEFAULT_OPTIONS.waitTime,
                errorCodes: Array.isArray(options.errorCodes)
                    ? options.errorCodes
                    : this.DEFAULT_OPTIONS.errorCodes
            };

            axios.interceptors.request.use(
                config => {
                    let jwt = this.jwt;
                    return Object.assign(
                        {},
                        config,
                        jwt
                            ? {
                                  headers: {
                                      Authorization: jwt,
                                      "X-Origin": "PWA",
                                      "X-Version": envConfig.version,
                                      ...config.headers
                                  }
                              }
                            : {
                                  headers: {
                                      "X-Origin": "PWA",
                                      "X-Version": envConfig.version,
                                      ...config.headers
                                  }
                              },
                        retryConfig
                    );
                },
                error => Promise.reject(error)
            );

            axios.interceptors.response.use(null, error => {
                if (error.config && this.shouldRetry(error)) {
                    const { __retryCount: retryCount = 0 } = error.config;
                    error.config.__retryCount = retryCount + 1;
                    error.config.__isRetryRequest = true;
                    const waitTime = error.config.waitTime
                        ? error.config.waitTime
                        : 0;

                    if (waitTime > 0) {
                        // eslint-disable-next-line no-unused-vars
                        return new Promise((resolve, reject) => {
                            setTimeout(
                                () => resolve(axios(error.config)),
                                waitTime
                            );
                        });
                    }
                    return axios(error.config);
                }
                return Promise.reject(error);
            });
        };

        this.axiosSetRESTInterceptors = (axios, options = {}) => {
            const retryConfig = {
                maxAttempts: 0 /* options.maxAttempts
                    ? options.maxAttempts
                    : this.DEFAULT_OPTIONS.maxAttempts */,
                waitTime: options.waitTime
                    ? options.waitTime
                    : this.DEFAULT_OPTIONS.waitTime,
                errorCodes: Array.isArray(options.errorCodes)
                    ? options.errorCodes
                    : this.DEFAULT_OPTIONS.errorCodes
            };

            axios.interceptors.request.use(
                config =>
                    Object.assign(
                        config,
                        retryConfig,
                        this.jwt
                            ? {
                                  headers: {
                                      Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                                      "X-Origin": "PWA",
                                      "X-Version": envConfig.version,
                                      ...config.headers
                                  }
                              }
                            : {
                                  headers: {
                                      Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                                      "X-Origin": "PWA",
                                      "X-Version": envConfig.version,
                                      ...config.headers
                                  }
                              }
                    ),
                error => Promise.reject(error)
            );
            axios.interceptors.request.use(
                config => Object.assign(config, retryConfig),
                error => Promise.reject(error)
            );

            axios.interceptors.response.use(null, error => {
                if (error.config && this.shouldRetry(error)) {
                    const { __retryCount: retryCount = 0 } = error.config;
                    error.config.__retryCount = retryCount + 1;
                    error.config.__isRetryRequest = true;
                    const waitTime = error.config.waitTime
                        ? error.config.waitTime
                        : 0;

                    if (waitTime > 0) {
                        // eslint-disable-next-line no-unused-vars
                        return new Promise((resolve, reject) => {
                            setTimeout(
                                () => resolve(axios(error.config)),
                                waitTime
                            );
                        });
                    }
                    return axios(error.config);
                }
                return Promise.reject(error);
            });
        };

        this.cf = axios.create({
            baseURL: config.baseURL,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.axiosSetCFInterceptors(this.cf, this.DEFAULT_OPTIONS);
        //TODO: envify
        this.rest = axios.create({
            baseURL: config.apiRestUrl,
            // baseURL: "https://1-4-6-dot-ms-referral-dot-really-a4091.appspot.com/referral/api",
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.axiosSetRESTInterceptors(this.rest, this.DEFAULT_OPTIONS);

        this.elasticPython = axios.create({
            baseURL: config.elasticPythonApiUrl,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true,
            //bearer token mHk9x26Y8WRh5xxJS4bBsYFp
            //application/json
            headers: {
                Authorization: "Bearer mHk9x26Y8WRh5xxJS4bBsYFp",
                "Content-Type": "application/json"
            }
        });

        this.searchRest = axios.create({
            baseURL: config.searchApiRestUrl,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });

        this.axiosSetRESTInterceptors(this.searchRest, this.DEFAULT_OPTIONS);
        this.megaSearchRest = axios.create({
            baseURL: `${config.elasticPythonApiUrl}/search/mega`,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });

        this.axiosSetRESTInterceptors(
            this.megaSearchRest,
            this.DEFAULT_OPTIONS
        );

        //TODO: envify
        this.phone = axios.create({
            baseURL: "https://rest.messagebird.com",
            timeout: config.timeout,
            headers: {
                "Content-Type": "application/json",
                Authorization: "AccessKey live_aiwyAbt0lZCCdm2esqRcDPN0u"
            }
        });

        this.emailRest = axios.create({
            baseURL: config.emailApiURL,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.axiosSetRESTInterceptors(this.emailRest, this.DEFAULT_OPTIONS);

        this.account = axios.create({
            baseURL: config.accountApiUrl,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.axiosSetCFInterceptors(this.account, this.DEFAULT_OPTIONS);

        this.msTransactionalApi = axios.create({
            baseURL: config.msTransactionalApi,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.axiosSetCFInterceptors(
            this.msTransactionalApi,
            this.DEFAULT_OPTIONS
        );
    }

    // *** Agency REST API ***

    sendVerificationCode = phone =>
        this.cf.post(`/sendVerificationCode`, {
            phoneNumber: phone
        });

    validateVerificationCode = (code, phone) =>
        this.cf.post(`/validateVerificationCode`, {
            phoneNumber: phone,
            code
        });

    appendToken = (token, uid) => {
        if (token && uid)
            localStorage.setItem(`${uid}-token`, JSON.stringify(token));
        this.jwt = `Bearer ${token}`;
    };

    revokeToken = uid => {
        if (uid) localStorage.removeItem(`${uid}-token`);
        delete this.jwt;
    };

    logLastLogin = () => this.cf.get("/logLastLogin");

    allowAccessToDev = code => this.cf.post("/allowAccessToDev", { code });

    connect = uid =>
        //this.cf.post(`/inviteToConnectLTS`, {
        this.cf.post(`/inviteToConnect`, {
            uid
        });
    unfriend = uid =>
        //this.cf.post(`/inviteToConnectLTS`, {
        this.cf.post(`/unfriend`, {
            uid
        });

    accept = (uid, notificationId) =>
        //  this.cf.post(`/acceptInviteToConnectLTS`, {
        this.cf.post(`/acceptInviteToConnect`, {
            uid,
            notificationId
        });

    decline = uid =>
        this.cf.post(`/declineInviteToConnect`, {
            uid
        });

    cancelConnect = uid =>
        this.cf.post(`/cancelInviteToConnect`, {
            uid
        });

    replyCommunityPost = (post_id, body) => {
        return this.cf.post(`/replyCommunityPost`, {
            post_id,
            body
        });
    };

    createUser = (name, lastname, email, token, uid, avatar, emailVerified) => {
        this.appendToken(token, uid);
        return this.cf.post(
            "/createUser",
            {
                name,
                lastname,
                email,
                avatar,
                emailVerified
            },
            {
                headers: {
                    Authorization: this.jwt,
                    "X-Origin": "PWA",
                    "X-Version": envConfig.version
                }
            }
        );
    };

    passwordUpdate = () =>
        this.cf.post("/updateUser", {
            profileSection: "PASSWORD",
            payload: {}
        });

    addUserToCompany = (companyId, addToChat, owner) =>
        this.cf.post("/addUserToCompany", {
            id: companyId,
            chat: addToChat,
            owner: owner
        });

    removeUserFromCompany = companyId =>
        this.cf.post("/removeUserFromCompany", {
            id: companyId
        });

    updateUserProfile = payload => this.cf.post("/updateUser", payload);
    fillProfile = (payload, step) =>
        this.cf.post("/fillProfile", {
            ...payload,
            step
        });
    claimRER = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "CLAIMED"
        });

    declineRER = (referralId, authorId) =>
        this.cf.get(`/declineRER?id=${referralId}@${authorId}`);

    declineReferral = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "REJECTED"
        });

    acceptReferral = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "ASSIGNED"
        });

    confirmPastReferral = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "CONFIRMED"
        });

    cancelReferral = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "CANCELED"
        });

    unclaimReferral = (referralId, authorId) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: "UNCLAIM"
        });

    changeJobStatus = (jobId, authorId, status) =>
        this.cf.post(`/changeJobStatus`, { jobId, authorId, status });

    sendReferral = (
        origin,
        type,
        profession,
        fromId,
        from,
        to,
        toExternal,
        description,
        clientName,
        clientAddress,
        clientPhone,
        clientEmail,
        clientSource,
        clientStatus,
        lastContact,
        financingInfo,
        minPrice,
        maxPrice,
        estimatedPrice,
        clientType,
        propertyType,
        minSize,
        maxSize,
        size,
        beds,
        baths,
        minAcres,
        maxAcres,
        acres,
        // otherInfo,
        state,
        stateShort,
        zipcode,
        subAdministrativeArea,
        locationCoords,
        locationFullAddress,
        feeType,
        fee,
        feeDuration,
        feeFrequency,
        feeCommencement,
        feeDue,
        feeComments,
        notificationRadius,
        signatoryType,
        signatoryInsideReally,
        signatoryInfo,
        sizeUnit
    ) => {
        return this.cf.post(`/sendReferral2`, {
            origin,
            type,
            profession,
            fromId,
            from,
            to,
            toExternal,
            description,
            clientName,
            clientAddress,
            clientPhone,
            clientEmail,
            clientSource,
            clientStatus,
            lastContact,
            financingInfo,
            minPrice,
            maxPrice,
            estimatedPrice,
            clientType,
            propertyType,
            minSize,
            maxSize,
            size,
            beds,
            baths,
            minAcres,
            maxAcres,
            acres,
            // otherInfo,
            state,
            stateShort,
            zipcode,
            subAdministrativeArea,
            locationCoords,
            locationFullAddress,
            feeType,
            fee,
            feeDuration,
            feeFrequency,
            feeCommencement,
            feeDue,
            feeComments,
            notificationRadius,
            signatoryType,
            signatoryInsideReally,
            signatoryInfo,
            sizeUnit
        });
    };

    /** REFERRAL CURRENT ENDPOINT */
    newSendReferral = async referralData =>
        this.cf.post(`/newSendReferral`, referralData);
    /**   PAST REFERRAL */
    sendPastReferral = async pastReferralData =>
        this.cf.post(`/sendPastReferral`, pastReferralData);
    /**  RECOMMENDATION ENDPOINT TO CF */
    sendRecommendation = async recommendationData =>
        this.cf.post("/sendRecommendation", recommendationData);

    cancelRecommendation = (recommendationId, authorId) =>
        this.cf.post(`/changeRecommendationStatus`, {
            recommendationId,
            authorId,
            status: "CANCELED"
        });

    changeRecommendationStatus = (recommendationId, authorId, status) =>
        this.cf.post(`/changeRecommendationStatus`, {
            recommendationId,
            authorId,
            status
        });

    declineOpenRecommendation = async recommendationId =>
        this.cf.post(`/declineOpenRecommendation`, {
            recommendationId
        });

    /* MARK: SENDING JOB FLOW INFORMATION */
    sendJob = async jobData => this.cf.post(`/sendJob`, jobData);
    sendPastJob = async pastJobData => this.cf.post(`/sendJob`, pastJobData);
    // OP CHANGE STATUS TO FLOW
    changeReferralStatus = (referralId, authorId, newStatus) =>
        this.cf.post(`/changeReferralStatus`, {
            referralId,
            authorId,
            status: newStatus
        });

    /* REFERRALS REST API */

    validateOpenRefLTS = refId => this.cf.get(`/validateOpenRef?id=${refId}`);

    /*  claimRER = (refId, userId) =>
         this.rest.get(`/rer-claim-app/PWAPP/${refId}?uid=${userId}`); */
    cancelRER = refId => this.cf.get(`/deleteLiveRER?id=${refId}`);
    openReferralNewCandidate = referral =>
        this.rest.post("/easy-referral-new-candidate", referral);

    referralInvitation = referral =>
        this.rest.post("/referral-invitation", referral);

    getUser = username => this.cf.get(`/getUser?username=${username}`);

    getUserByUid = uid => this.cf.get(`/getUser?id=${uid}`);

    fetchUserList = (text, call) => {
        return this.cf.post(
            `/masterSearchLTS`,
            {
                params: text,
                location: {
                    type: "should",
                    coordinates: [0, 0],
                    region: 1
                }
            },
            {
                headers: {
                    Authorization: this.jwt,
                    "X-Origin": "PWA",
                    "X-Version": envConfig.version
                },
                cancelToken: call.token
            }
        );
    };

    fetchProfileOpsCounters = uid => this.cf.get(`/getOpsCount?uid=${uid}`);

    assignRER = (uid, openRefID, isRecommendation, isContact) =>
        this.cf.post(`/assignRER`, {
            candidateId: uid,
            referralId: openRefID,
            isRecommendation,
            isContact,
            source: "PWAAPP"
        });
    redeemReferral = code => this.cf.get(`/redeemReferral?code=${code}`);
    OpenReferralID = () => this.cf.get("/OpenReferralID");
    getRERZoneUsers = referral => {
        return this.cf.post("/getRERZoneUsers", {
            referral: referral
        });
    };

    rerCandidatesLeftOver = losers => {
        return this.cf.post("/rer-candidates-leftover", {
            data: losers
        });
    };

    /**-------------- Jobs API  --------------**/

    assignJob = (candidateId, jobId, isRecommendation, isContact) =>
        this.cf.post(`/assignJob`, {
            jobId,
            candidateId,
            isRecommendation,
            isContact
        });

    claimJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "CLAIMED"
        });

    declineJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "REJECTED"
        });

    acceptJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "ASSIGNED"
        });

    cancelJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "CANCELED"
        });

    unclaimJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "UNCLAIM"
        });

    completeJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "COMPLETED"
        });

    confirmPastJob = (jobId, authorId) =>
        this.cf.post(`/changeJobStatus`, {
            jobId,
            authorId,
            status: "CONFIRMED"
        });

    declineOpenJob = jobId => {
        this.cf.post(`/declineOpenJob`, {
            jobId
        });
    };

    /**-------------- Big Board API  --------------**/

    fetchBigBoard = async query => {
        return this.cf.post(`/bigboard`, query);
    };

    /**-------------- News Feed API  --------------**/

    ESdiscussionsByID = id => {
        return this.cf.get(`/ESdiscussionsByID/?id=${id}`);
    };

    // ESnewestDiscussionsLTS = (limit, from = 0) => {
    //     return this.cf.get(`/ESnewestDiscussions/?limit=${limit}&from=${from}`);
    // };
    ESnewestDiscussionsLTS = async (uid, limit, from = 0) => {
        const { data } = await this.searchRest.get(
            `/newest_posts/${uid}/${from}/${limit}`
        );
        return data.results;
    };

    createCompany = agency => {
        return this.cf.post("/createCompany", agency);
    };

    createCommunityPost = post => {
        return this.cf.post("/createCommunityPost", post);
    };

    updateCommunityPost = (postId, post) => {
        return this.cf.post("/updateCommunityPost", {
            ...post,
            postId: postId
        });
    };

    upvoteDiscussion = postId => {
        return this.cf.post("/upvoteDiscussion", { id: postId });
    };

    downvoteDiscussion = postId => {
        return this.cf.delete("/upvoteDiscussion", { data: { id: postId } });
    };

    blockUser = uid => {
        return this.cf.post("/blockUser", { uid: uid });
    };

    unblockUser = uid => {
        return this.cf.post("/unblockUser", { uid: uid });
    };

    upvoteReply = (post_id, reply_id) => {
        return this.cf.post("/upvoteReply", { post_id, reply_id });
    };

    downvoteReply = (post_id, reply_id) => {
        return this.cf.delete("/upvoteReply", { post_id, reply_id });
    };

    gamificationLogAppInvite = qrCode => {
        return this.cf.post("/gamificationLogAppInvite", { code: qrCode });
    };

    invitedBy = uid => {
        return this.cf.post("/invitedBy", { uid: uid });
    };

    setBillingDetails = payload => {
        return this.cf.post("/setBillingDetails", payload);
    };

    getNearUsers2 = (uid, lat, lon) => {
        if (lat && lon)
            return this.cf.get(`/getNearUsers2?lat=${lat}&long=${lon}`);
    };

    generateProfileQRID = () => {
        return this.cf.get(`/generateProfileQRID`);
    };

    setDismissInviteNotification = uid => {
        return this.cf.post("/gamificationSetDismissInvite", {
            uid: uid,
            dismissed: true
        });
    };

    updateUser = (profileSection, payload) => {
        return this.cf.post("/updateUser", { profileSection, payload });
    };
    updateUser2 = (profileSection, payload) => {
        return this.cf.post("/updateUser2", { [profileSection]: payload });
    };
    getReferralByCode = referralCode => {
        return this.cf.get(`/getReferral?code=${referralCode}`);
    };

    getRecommendationByCode = recommendationCode => {
        return this.cf.get(`/getRecommendation?code=${recommendationCode}`);
    };

    getJobByCode = jobCode => {
        return this.cf.get(`/getJob?code=${jobCode}`);
    };

    getConnected = (from = 0, size = 5) => {
        return this.cf.get(`/getConnected2`);
    };

    /**-------------- My Network API  --------------**/

    fetchMyContacts = (
        from,
        size,
        search,
        filters,
        uid,
        sortBy = "",
        sortOrder = ""
    ) => {
        return this.elasticPython.post(
            `users/my_networks/contacts/${uid}`,
            {
                ...filters,
              ...(search && { search: search }),
                uid
            },
            {
                params: {
                    from_: from,
                    size: size,
                    sort_by: sortBy,
                    sort_order: sortOrder
                }
            }
        );
    };

    fetchContactDetails = (uid, contactId) => {
        return this.elasticPython.get(
            `my_networks/contacts/${uid}/read/${contactId}`
        );
    };

    importContactFromOtherUser = (contact_id, contact_of) => {
        return this.cf.post(`/importContactFromOtherUser`, {
            contact_id,
            contact_of
        });
    }

    fetchMyConnections = (uid, filters, query) => {
        return this.elasticPython.post(
            `users/my_networks/connections/${uid}`,
            filters,
            { params: query, paramsSerializer: params => qs.stringify(params) }
        );
    };

    deleteContact = (contact_id, userId) => {
        return this.cf.delete(`removeContactFromAgenda/`, {
            data: { contact_id }
        });
    };

    updateContact = (uid, contactBody) => {
        return this.cf.put(`/updateContactToAgenda`, contactBody);
    };

    createContact = (uid, contactBody) => {
        return this.cf.post(`/addContactToAgenda`, contactBody);
    };

    bulkCreateContacts = (uid, contacts) => {
        return this.cf.post(`/addImportsContactToAgenda`, contacts, {
            timeout: 180000
        });
    };

    inviteContact = ({ contact_id, email, phone }) => {
        return this.cf.post(`/inviteContact`, {
            contact_id,
            email,
            phone
        });
    };

    fetchIncomingOpenReferrals = (
        lat,
        lon,
        distance = 50,
        from = 0,
        size = 100
    ) => {
        return this.cf.get(
            `/getIncomingOpenReferrals?lat=${lat}&lon=${lon}&distance=${distance}&from=${from}&size=${size}`
        );
    };

    addActivityFeedNote = (contact_id, title, note, rendered_note) => {
        return this.cf.post(`/addActivityFeedNote`, {
            contact_id,
            title,
            note,
            rendered_note
        });
    };

    updateActivityFeedNote = (
        contact_id,
        activity_id,
        title,
        note,
        rendered_note
    ) => {
        return this.cf.put(`/updateActivityFeedNote`, {
            contact_id,
            activity_id,
            title,
            note,
            rendered_note
        });
    };

    deleteActivityFeedNote = (contact_id, activity_id) => {
        return this.cf.delete(`/removeActivityFeedNote`, {
            data: {
                contact_id,
                activity_id
            }
        });
    };

    fetchMyReferrals = (
        from,
        size,
        filters,
        meId,
        sortBy = "",
        sortOrder = ""
    ) => {
        return this.elasticPython.post(
            `/opportunities/my_ops`,
            {
                // status: [],
                // filters: { ...filters },
                ...filters,
                uid: meId
            },
            {
                params: {
                    from_: from,
                    size: size,
                    sort_by: sortBy,
                    sort_order: sortOrder
                },
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer mHk9x26Y8WRh5xxJS4bBsYFp"
                }
            }
        );
    };

    fetchMyOpsAnalyticsSummary = uid => {
        return this.elasticPython.get(`/analytics/opportunities/my_ops/${uid}`);
    };

    fetchOpsActivityCount = (uid, filters) => {
        return this.elasticPython.post(
            `/analytics/opportunities/ops_activity/count/${uid}`,
            filters
        );
    };

    fetchOpsActivityAnalytics = (uid, filters) => {
        return this.elasticPython.post(
            `/analytics/opportunities/ops_activity/time_period/${uid}`,
            filters
        );
    };

    fetchMemberComparison = (uid, filters) => {
        return this.elasticPython.post(
            `/analytics/opportunities/ops_activity/by_connections/${uid}`,
            filters
        );
    };

    fetchReferralTimeline = referralCode => {
        return this.cf.get(`/timeline?referralCode=${referralCode}`);
    };

    fetchRecommendationTimeline = recommendationCode => {
        return this.cf.get(
            `/timeline?recommendationCode=${recommendationCode}`
        );
    };

    addReferralTimelineItem = (referralCode, message) => {
        return this.cf.post(`/timeline?referralCode`, {
            message: message,
            referralCode: referralCode
        });
    };

    addJobComment = (jobCode, message) => {
        return this.cf.post(`/addJobComment`, {
            message: message,
            jobCode
        });
    };

    favoriteUser = uid => {
        return this.cf.post(`/favoriteUser`, { uid });
    };

    unfavoriteUser = uid => {
        return this.cf.post(`/unfavoriteUser`, { uid });
    };

    getUserFavorites = uid => {
        return this.cf.get(`/getUserFavorites?uid=${uid}`);
    };

    /**-------------- Endorsements API  --------------**/

    getUserEndorsements = (uid, userRelation = "received", filters = {}) => {
        return this.elasticPython.post(
            `endorsements/${uid}?user_relation=${userRelation}&from_=${filters.from ||
                0}&size=${filters.size || 100}`,
            filters
        );
    };

    addEndorsement = payload => {
        return this.cf.post(`/addEndorsement`, payload);
    };

    updateEndorsement = payload => {
        return this.cf.put(`/updateEndorsement`, payload);
    };

    deleteEndorsement = endorsement_id => {
        return this.cf.delete(`/removeEndorsement`, {
            data: { endorsement_id }
        });
    };

    /**-------------- Gratitudes API  --------------**/

    getUserGratitudes = (uid, userRelation = "received", filters = {}) => {
        return this.elasticPython.post(
            `gratitudes/${uid}?user_relation=${userRelation}&from_=${filters.from ||
                0}&size=${filters.size || 100}`,
            filters
        );
    };

    addGratitude = payload => {
        return this.cf.post(`/addGratitude`, payload);
    };

    updateGratitude = payload => {
        return this.cf.put(`/updateGratitude`, payload);
    };

    deleteGratitude = gratitude_id => {
        return this.cf.delete(`/removeGratitude`, {
            data: { gratitude_id }
        });
    };

    /**-------------- Ops Reviews API  --------------**/

    getUserOpsReviews = (uid, userRelation = "received", filters = {}) => {
        return this.elasticPython.post(
            `ops_reviews/${uid}?user_relation=${userRelation}&from_=${filters.from ||
                0}&size=${filters.size || 100}`,
            filters
        );
    };

    addOpReview = payload => {
        return this.cf.post(`/addOpsReviews`, payload);
    };

    updateOpReview = payload => {
        return this.cf.put(`/updateOpsReviews`, payload);
    };

    deleteOpReview = review_id => {
        return this.cf.delete(`/removeOpsReviews`, {
            data: { review_id }
        });
    };

    /**  OPS REVENUES */

    addOpRevenue = (uid, revenuePayload) =>
        this.elasticPython.post(`/ops_revenues/${uid}`, revenuePayload);

    // updateRevenue = payload =>
    //     this.elasticPython.put(`/ops_reviews/sync`, payload);
    /* Search API */

    getNearUsers = (uid, lat, lon) => {
        if (lat && lon)
            return this.searchRest.get(`/connected/${uid}/${lat}/${lon}`);
        return this.searchRest.get(`/connected/${uid}`);
    };

    searchPeople = (
        term = "",
        location,
        page = 0,
        size = 10,
        call,
        filtersQueryStrings = "",
        locationOrUser,
        myId,
        ambassadors_only = 0
    ) => {
        let promise;
        if (location && Object.keys(location).length > 0) {
            if (locationOrUser === SEARCH_TYPE_LOCATION) {
                promise = this.searchPeopleByLocation(
                    location,
                    page,
                    size,
                    call,
                    filtersQueryStrings,
                    myId,
                    ambassadors_only
                );
            } else {
                promise = this.searchPeopleByTermAndLocation(
                    term,
                    location,
                    page,
                    size,
                    call,
                    filtersQueryStrings,
                    myId,
                    ambassadors_only
                );
            }
        } else {
            if (!term || term === "") {
                promise = this.searchPeopleByTerm(
                    term,
                    page,
                    size,
                    call,
                    filtersQueryStrings,
                    myId,
                    ambassadors_only
                );
            } else {
                promise = this.searchPeopleByTerm(
                    term,
                    page,
                    size,
                    call,
                    filtersQueryStrings,
                    myId,
                    ambassadors_only
                );
            }
        }
        return promise;
    };

    searchPeopleByTermAndLocation = (
        term,
        location,
        page = 0,
        size = 10,
        call,
        filtersQueryStrings = "",
        myId,
        ambassadors_only
    ) => {
        const state = filtersQueryStrings.length > 0 ? "&state=1" : "?state=1";

        return term && location
            ? location.state
                ? this.searchRest.get(
                      `/all/${myId}/${term}/${encodeURI(
                          location.short_name
                      )}/${page}/${size}${filtersQueryStrings}${state}&ambassadors_only=${ambassadors_only}`,
                      {
                          headers: {
                              Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                              "X-Origin": "PWA",
                              "X-Version": envConfig.version
                          },
                          cancelToken: call.token
                      }
                  )
                : this.searchRest.get(
                      `/all/${myId}/${term}/${encodeURI(
                          location.long_name
                      )},US/${page}/${size}${filtersQueryStrings}&ambassadors_only=${ambassadors_only}`,
                      {
                          headers: {
                              Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                              "X-Origin": "PWA",
                              "X-Version": envConfig.version
                          },
                          cancelToken: call.token
                      }
                  )
            : new Promise(res => emptyAPIMock);
    };

    searchPeopleByTerm = (
        term = "",
        page = 0,
        size = 10,
        call,
        filtersQueryStrings = "",
        myId,
        ambassadors_only
    ) => {
        const qsSeparator = filtersQueryStrings.length > 0 ? "&" : "?";
        const ambassador = `${qsSeparator}ambassadors_only=${ambassadors_only}`;
        return term
            ? this.searchRest.get(
                  `/people/${myId}/${term}/${page}/${size}${filtersQueryStrings}${ambassador}`,
                  {
                      headers: {
                          Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                          "X-Origin": "PWA",
                          "X-Version": envConfig.version
                      },
                      cancelToken: call.token
                  }
              )
            : new Promise((resolve, reject) => resolve(emptyAPIMock));
    };

    searchPeopleByLocation = (
        location,
        page = 0,
        size = 10,
        call,
        filtersQueryStrings = "",
        myId,
        ambassadors_only
    ) => {
        const qsSeparator = filtersQueryStrings.length > 0 ? "&" : "?";
        const state = `${qsSeparator}state=1`;
        const ambassador = `${qsSeparator}ambassadors_only=${ambassadors_only}`;
        return location
            ? location.state
                ? this.searchRest.get(
                      `/location/${myId}/${encodeURI(
                          location.short_name
                      )}/${page}/${size}${filtersQueryStrings}${state}&ambassadors_only=${ambassadors_only}`,
                      {
                          headers: {
                              Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                              "X-Origin": "PWA",
                              "X-Version": envConfig.version
                          },
                          cancelToken: call.token
                      }
                  )
                : this.searchRest.get(
                      `/location/${myId}/${encodeURI(
                          location.long_name
                      )},US/${page}/${size}${filtersQueryStrings}${ambassador}`,
                      {
                          headers: {
                              Authorization: "mHk9x26Y8WRh5xxJS4bBsYFp",
                              "X-Origin": "PWA",
                              "X-Version": envConfig.version
                          },
                          cancelToken: call.token
                      }
                  )
            : new Promise(res => emptyAPIMock);
    };

    searchAcrossAllPlatform = (
        term = null,
        myId,
        section = "",
        filters = {},
        call
    ) => {
        return this.megaSearchRest.post(
                  "",
                  {
                      search: term,
                      me: myId,
                      uid: myId,
                      section,
                      ...filters
                  },
                  {
                      headers: {
                          Authorization: "Bearer mHk9x26Y8WRh5xxJS4bBsYFp",
                          "X-Origin": "PWA",
                          "X-Version": envConfig.version
                      },
                      cancelToken: call.token
                  }
              )
            // : new Promise((resolve, reject) => resolve(emptyAPIMock));
    };

    /* Email verification */
    sendVerificationEmail = () => {
        return this.cf.get(`/resendValidationEmail`);
    };

    shareReferral = payload => {
        // return this.emailRest.post(`/send/notifications-pm`, payload);
        return this.cf.post("/opShare", payload);
    };

    sendRecommendationEmail = payload => {
        return this.emailRest.post("/send/notifications-pm", payload);
    };

    sendInviteEmails = emails => {
        return this.cf.post("/inviteAndEarnSendInvites", emails);
    };

    setOnboardingComplete = onboardingView => {
        return this.cf.post("/setOnboardingComplete", { onboardingView });
    };

    /*msTransactionalApi*/
    fetchTotalInvites = uid => {
        return this.msTransactionalApi.get(`/virality-invited/${uid}`);
    };

    updateBillingDetails = (acct, payload) => {
        return this.msTransactionalApi.post(`/new-card/${acct}`, payload);
    };

    getStripeCustomerId = async (uid, payload) => {
        return await this.msTransactionalApi.post(
            `/get-stripecusid/${uid}`,
            payload
        );
    };

    getBillingDetailsStatus = async acct => {
        return await this.msTransactionalApi.get(`/acctstatus/${acct}`);
    };

    /* Hugo Account API */
    getInvitesAccountBalance = () => {
        return this.account.get(`/balance`);
    };

    getEarningHistory = () => {
        return this.account.get(`/earnings-history`);
    };

    /* HELPERS */
    parseRequestError = error => {
        let errorParsed = {
            message: "Our service is not working for a while 😑. Sorry.",
            title: "Error"
        };
        try {
            const errorExist = error && error.response && error.response.data;
            console.log("parsedError", errorExist, error.response);
            if (
                errorExist /* && error.response.data.status === "Error" */ &&
                error.response.data.message
            ) {
                errorParsed.message = error.response.data.message;
            } else if (
                errorExist &&
                Array.isArray(error.response.data.errors) &&
                error.response.data.errors.length > 0
            ) {
                errorParsed.message = error.response.data.errors[0].description;
            } else if (error.message) {
                errorParsed.message = error.message;
                errorParsed.stack = error.stack;
            }
        } catch (error) {
            errorParsed.message = error.message;
            errorParsed.stack = error.stack;
        }
        return errorParsed;
    };

    logAppError = error => {
        return this.cf.post("/appErrorLogger", error);
    };

    sendRecommendation = payload => {
        return this.cf.post("/sendRecommendation", payload);
    };

    recommendToOpportunity = payload => {
        return this.cf.post("/recommendToOpportunity", payload);
    };

    upvoteRecommendation = payload => {
        return this.cf.post("/upvoteRecommendation", payload);
    };

    removeUpvoteFromRecommendation = payload => {
        return this.cf.post("/removeUpvoteFromRecommendation", payload);
    };

    updateRevenue = payload => this.cf.post("/addOpsRevenue", payload);

    // -----------------  Leaderboard  ----------------- //

    fetchLeaderboard = ({ from, size, date_range, search }) => {
        return this.cf.get(
            `getLeaderboard?from=${from}&size=${size}&date_range=${date_range}&search=${search}`
        );
    };
}

export default CloudFunctions;
