import {
    FILLPROFILE_ISLOADING,
    FILLPROFILE_VALUESCHANGED,
    FILLPROFILE_CLEAN,
    FILLPROFILE_STEP,
    FILLPROFILE_SUCCESS,
    FILLPROFILE_SKIP_EMAIL_VERIFICATION,
    FILLPROFILE_PHONE_ALREADY_IN_USE
} from "../../../../actions/constants";
const initialState = {
    isLoading: false,
    skipEmailVerification: false,
    currentStep: 1,
    showSuccessView: false,
    errors: {
        phoneAlreadyInUse: false,
    }
};

function fillProfileReducer(state = initialState, action) {
    switch (action.type) {
        case FILLPROFILE_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case FILLPROFILE_SUCCESS: {
            const _state = {
                ...initialState
            };
            _state.showSuccessView = action.payload.showSuccessView;
            return _state;
        }
        case FILLPROFILE_STEP: {
            const _state = {
                ...state
            };
            _state.currentStep = action.payload.currentStep;
            return _state;
        }
        case FILLPROFILE_VALUESCHANGED: {
            const _state = {
                ...state
            };
            _state.profile = { ..._state.profile, ...action.payload.profile };
            return _state;
        }
        case FILLPROFILE_SKIP_EMAIL_VERIFICATION: {
            const _state = {
                ...state
            };
            _state.skipEmailVerification = true;
            return _state;
        }
        case FILLPROFILE_PHONE_ALREADY_IN_USE: {
            const _state = {
                ...state
            };
            _state.errors.phoneAlreadyInUse = action.payload.phoneAlreadyInUse;
            return _state;
        }
        case FILLPROFILE_CLEAN: {
            const _state = {
                ...initialState
            };
            return _state;
        }
        default:
            return state;
    }
}

export default fillProfileReducer;
