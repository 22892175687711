import styled from 'styled-components';
import { changeColorOnHover } from "../../../../atoms/shared/ChangeColorOnHover"

export const AvatarContainer = styled.div`
    margin-right: 10px;
    cursor: pointer;
`;

export const CardDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
    font-size: 15px;
  }
`;

export const CardTitle = styled.div`
    cursor: pointer;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    color: ${props => props.theme.coolBlack};
    margin-bottom: .3em;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    // overflow-x: hidden;                
    word-break: break-word;
    ${changeColorOnHover}
    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
        font-size: 15px;
    }
`;

export const CardText = styled.div`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    color: ${props => props.theme.coolBlack};
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    word-break: break-all;  
`;

export const CardFooter = styled.div`
    padding-top: .3em;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CardStatus = styled.div`
    box-sizing: border-box;
    border: ${props => `1px solid ${props.status === 'SUBMITTED' ? props.theme.coolBlack : props.status === 'CLAIMED' ? props.theme.blueMedium : props.status === 'PENDING' ? '#F6C944' : props.status === 'ASSIGNED' ? props.theme.newBlue : props.status === 'PAID' ? props.theme.greenMedium : props.theme.text}`};
    border-radius: 4px;
    color: ${props => props.status === 'SUBMITTED' ? props.theme.coolBlack : props.status === 'CLAIMED' ? props.theme.blueMedium : props.status === 'PENDING' ? '#F6C944' : props.status === 'ASSIGNED' ? props.theme.newBlue : props.status === 'PAID' ? props.theme.greenMedium : props.theme.text};
    background: #FFFFFF; 
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.85px;
    line-height: 16px;
    text-align: center;
    padding: .5em 2em .5em 2em;
`;



export const CardDate = styled.div`
    min-width: 70px;
    position: absolute;
    top: 10px;
    right: 11px;
    min-height: 20px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;

`;

export const CardDateIcon = styled.img`
    margin-left: 8px;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    `;

export const FooterLine = styled.div`
    width: 80%;
    border: 0.5px solid #D8D8D8;

`;

