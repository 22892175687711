import React, { useEffect } from "react";
import { connect } from "react-redux";
import useDebounce from '../../../../../../libs/UseDebounce'
import {
    SEARCH_SIZE_MOBILE,
    SEARCH_SIZE_DESKTOP
} from '../../../views/Search/constants'
import { bindActionCreators } from "redux";
import {
    search,
    searchLocation,
    searchValue,
    toggleSearchBar,
    clearSearch,
    megaSearch
} from "../../../../../../redux/actions/creators/search";
import { withTheme } from "styled-components";
let lastTermValue = ''

const SearchHook = (Input) => {
    return connect(mapStateToprops, mapDispatchToProps)(withTheme((props) => {
        const { 
            theme,
            history,
            isLoading,
            onDebouncedValChange,
            clearSearch,
            toggleSearchBar,
            placeholderStyles,
            value,
            searchValue,
            selectedLocation,
            locationOrUser,
            searchLocation,
            search,
            megaSearch,
            pushToUser,
            showSuggestions,
            isMegaSearch,
            ...rest 
        } = props
        const size = theme.mobileDevice ? SEARCH_SIZE_MOBILE : SEARCH_SIZE_DESKTOP
        const debouncedSearchTerm = useDebounce(value, 700);

        useEffect(() => {

            if (debouncedSearchTerm && debouncedSearchTerm !== lastTermValue) {
                if (onDebouncedValChange) onDebouncedValChange()
                if (showSuggestions && !locationOrUser) {
                    fetchLocationAPI(value);
                } else if (!isMegaSearch) {
                    fetchAPI(debouncedSearchTerm);
                } else {
                    fetchMegaSearch(debouncedSearchTerm);
                }
                return () => {
                    lastTermValue = ''
                }
            }
            lastTermValue = debouncedSearchTerm
        }, [debouncedSearchTerm]);

        const fetchAPI = (val) =>
            search(val, selectedLocation, 0, size, true, 1);

        const fetchLocationAPI = val =>
            searchLocation(val);


        const fetchMegaSearch = val => megaSearch(val);

        const onChangeHandler = event => {
            event.persist();
            const val = event.target.value;
            searchValue(val);

        };

        const onSelectUser = key => {
            pushToUser(key);
        };


        return (
            <Input
                placeholderStyles={placeholderStyles}
                locationOrUser={locationOrUser}
                history={history}
                isLoading={isLoading}
                searchValue={searchValue}
                clearSearch={clearSearch}
                toggleSearchBar={toggleSearchBar}
                value={value}
                onChange={onChangeHandler}
                onSelectUser={onSelectUser}
                {...rest}
            />
        );


    }))
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            search,
            megaSearch,
            searchLocation,
            searchValue,
            toggleSearchBar,
            clearSearch
        },
        dispatch
    );

const mapStateToprops = state => {
    return {
        isLoading: state.main.ui.global.searchBar.isLoading || state.main.ui.global.searchBar.isLocationLoading,
        //size: state.main.ui.global.searchBar.size,
        location: state.main.ui.global.searchBar.location,
        page: state.main.ui.global.searchBar.size,
        value: state.main.ui.global.searchBar.value,
        locationOrUser: state.main.ui.global.searchBar.locationOrUser,
        isLocationSelected: state.main.ui.global.searchBar.location && Object.keys(state.main.ui.global.searchBar.location).length > 0,
        selectedLocation: state.main.ui.global.searchBar.location
    }
}
export default SearchHook;
