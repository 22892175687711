import React, { useRef, useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { CardContainer, Poligon, CardScrollContainer, FilterButton } from "./styles";
import NotificationsList from '../../NotificationsList'
import {
  KnockFeedProvider, useKnockFeed,
} from "@knocklabs/react-notification-feed";
import Knock from "@knocklabs/client";
import { CustomKnockContext, useKnockConsumer } from "../../../../../context/useKnockProvider";
import { MarkAllAsRead, Container } from "../../NotificationsList/styles";
import { Flex, Box } from "rebass";
import { Icon } from "../../../../../ui/presentation/atoms/shared/Icon";
import { Text } from "../../../../../ui/presentation/atoms/shared/Text";
import Skeleton from "react-loading-skeleton";



const AvatarDropdown = props => {
  const [markThisNotificationAsRead, setMarkThisNotificationAsRead] = useState(null);
  const [removeNotification, setRemoveNotification] = useState(null);
  const [markingAllSeen, setMarkingAllSeen] = useState(false)


  const { feedClient, useFeedStore } = useKnockFeed();
  const { loading } = useFeedStore()

  const _new_items = useFeedStore((state) => state.items);
  const _unseen_count_ = _new_items.filter(idx => idx.seen_at === null);
  useEffect(() => {
    feedClient.on("items.received.realtime", ({ items }) => {

    });
    feedClient.fetch();
    return () => { }
  }, [feedClient]);

  useEffect(() => {
    if (removeNotification !== null) {
      feedClient.markAsArchived({ id: removeNotification })
        .then(({ statusCode }) => {
          if (statusCode === 'ok') feedClient.fetch();
        })
        .catch((error) => console.log('error archiving notification', error))
    }
  }, [removeNotification])

  useEffect(() => {
    if (markingAllSeen) feedClient.markAllAsRead()
  }, [markingAllSeen])

  useEffect(() => {
    if (markThisNotificationAsRead !== null) {
      feedClient.markAsRead({
        id: markThisNotificationAsRead
      })
        .then((_) => {
          feedClient.fetch()
        })
        .catch((e) => console.log(e))
    }
  }, [])


  const { theme, handleClickOutside, notificationsOpen, history } = props;
  const wrapperRef = useRef(null);
  const scrollRef = useRef(null);
  const [keepNotificationsOpen, setKeepNotificationsOpen] = useState(true);

  const [tab, setTab] = useState('all')


  useEffect(() => {
    if (notificationsOpen && wrapperRef.current) {
      wrapperRef.current.focus();
      setTimeout(() => {
        setKeepNotificationsOpen(false);
      }, 500);
    }
  }, [notificationsOpen, wrapperRef.current]);


  const handleGoToNotificationSettings = () => {
    handleClickOutside();
    history.push(`/settings?section=notifications`)
  }





  return (
    <CardContainer
      id="notifications-container"
      ref={wrapperRef}
      tabIndex={0}
      onBlur={!keepNotificationsOpen && handleClickOutside}
    >
      <Poligon />
      <CardScrollContainer
        padding={theme.spacing["6"]}
        ref={scrollRef}
        id={"scrollContainer"}
      >
        <Flex flexDirection={'column'} marginBottom={10} >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb={theme.spacing["4"]}
          >
            <Text fontSize="M" fontWeight="bold" color={theme.coolBlack}>
              Notifications
            </Text>

            <MarkAllAsRead>
              <Text
                fontSize="XXS"
                fontWeight="medium"
                color={theme.newBlue}
                style={{ marginRight: theme.spacing["4"] }}
                onClick={() => setMarkingAllSeen(true)}
              >
                Mark all as read
              </Text>
              <Icon
                icon="settings"
                onClick={handleGoToNotificationSettings}
                color={theme.newBlue}
                width="13px"
                height="14px"
              />
            </MarkAllAsRead>
          </Flex>
          {_new_items.length > 0 &&
            <Flex flexDirection={'row'} style={{ gap: 6 }}>
              <FilterButton style={{ fontSize: 14 }} buttonActive={tab === 'all'} onClick={() => setTab('all')}>All</FilterButton>
              <FilterButton style={{ fontSize: 14 }} buttonActive={tab === 'unread'} onClick={() => setTab('unread')}>Unread</FilterButton>
            </Flex>
          }
        </Flex>
        {(loading) ?
          <Flex flexDirection={'column'} style={{ gap: 12 }} mt={'20px'} >
            {_new_items.map((_) => <Skeleton width="305px" height={'72px'} />)}
          </Flex>
          :
          (tab === 'all') ?
            <NotificationsList
              scrollRef={scrollRef}
              tab={tab}
              handleClickOutside={handleClickOutside}
              setRemoveNotification={setRemoveNotification}
              _new_items={_new_items}
              setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
            />

            :

            <NotificationsList
              scrollRef={scrollRef}
              tab={'unread'}
              handleClickOutside={handleClickOutside}
              setRemoveNotification={setRemoveNotification}
              _new_items={_new_items}
              setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
            />

        }
        {_new_items.length === 0 && <EmptyStatus theme={theme} />}
      </CardScrollContainer>
    </CardContainer>
  );
};

export default withRouter(withTheme(AvatarDropdown));


const EmptyStatus = (props) => {
  const { theme } = props
  return (
    <Flex width={[1]} justifyContent='center' p={[theme.spacing['16']]}>
      <Text fontSize={theme.fonts['M']} color={theme.taupeGray} fontWeight='normal' style={{ textAlign: 'center' }}>
        For now you don’t have any notification.
      </Text>
    </Flex>

  )
}