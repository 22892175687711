import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";
import { OPPORTUNITY_CREATION_STEPS } from "../../../constants";

const mapStateToProps = state => {
    return {
        candidates: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
        pastCandidate: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
