import styled from "styled-components";

export const CardContainer = styled.div`
    height: ${({ height }) => height || "100%"};
    padding: ${props => (props.padding ? props.padding : "30px")};
    border-top: ${({ status, theme, isOpFeedCard }) => (status && !isOpFeedCard ? `4px solid ${theme[status]}` : "none")};
    border-left: ${({ status, theme, isOpFeedCard }) => (status && isOpFeedCard ?  `${theme.mobileDevice ? "24px" : "8px"} solid ${theme[status]}` : "none")};
    border-radius: ${({ borderRadius }) => borderRadius || "4px"};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    position: relative;
    background-color: ${props => (props.bg ? props.bg : "white")};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
    opacity: ${props => (props.disabled ? ".4" : "1")};
    box-sizing: border-box;
    overflow-x: hidden;
    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
        padding: ${props => (props.padding ? props.padding : "20px")};
    }
`;

export const MobileCardContainer = styled.div`
    height: ${({ height }) => height || "100%"};
    padding: ${props => (props.padding ? props.padding : "30px")};
    border-top: ${({ status, theme, isOpFeedCard }) => (status && !isOpFeedCard ? `4px solid ${theme[status.key]}` : "none")};
    border-left: ${({ status, theme, isOpFeedCard }) => (status && isOpFeedCard ?  `${theme.mobileDevice ? "24px" : "8px"} solid ${theme[status.key]}` : "none")};
    border-radius: ${({ borderRadius }) => borderRadius || "4px"};
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    position: relative;
    background-color: ${props => (props.bg ? props.bg : "white")};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
    opacity: ${props => (props.disabled ? ".4" : "1")};
    box-sizing: border-box;
    overflow-x: hidden;
    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
        padding: ${props => (props.padding ? props.padding : "20px")};
    }

    ::after {
            content: '${({ status }) => status.label}';
            color: white;
            font-size: 12px;
            font-weight: bold;
            position: absolute;
            left: -12px;
            top: 50%;
            transform: translate(-50%, 0) rotate(-90deg);

        }
`;
