import styled from 'styled-components';
import { Description2 } from '../../../../ui/presentation/atoms/shared/Text';

export const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: ${props => props.theme.mobileDevice ? '1.5em' : '1.5em'};
`

export const MenuItemText = styled(Description2)`
    margin-left: 1em;
    &:hover {
        color: #057aff;
        transition: all .3s ease;
    }
`