import React from "react";
import { Heading, Text, Flex, Box } from "rebass";
import styled from "styled-components";
//import ReallyLogo from "../../../../../assets/svg/really";
import CompanyName from "../../../../../assets/svg/NuOpRectangleTransparent.svg";
import { MEDIABREAKPOINTS } from "../../../../../theme";
import { withTranslation } from "react-i18next";
import SquareButton from "../../atoms/shared/Buttons/SquareButton";
const FlexContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    min-height: 100vh;
`;
const Head = styled(Heading)`
    font-size: 6em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 2em;
    }
`;

const Description = styled(Text)`
    font-size: 2em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 1em;
    }
`;

class NewContentAvailable extends React.PureComponent {

    onClickReload = () => {
        localStorage.clear();
        if (caches) {
            console.log("Clearing cache and hard reloading...");
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async function(names) {
                await Promise.all(names.map(name => caches.delete(name)));
                });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };


    render() {
        const { t } = this.props;
        return (
            <FlexContainer>
                <Flex
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    {/* <ReallyLogo
                        width={this.props.theme.mobileDevice ? "60px" : "130px"}
                        height={
                            this.props.theme.mobileDevice ? "60px" : "130px"
                        }
                    /> */}
                    <img
                        alt="NuOp"
                        src={CompanyName}
                        style={{
                            width: this.props.theme.mobileDevice
                                ? "120px"
                                : "260px",
                            paddingLeft: "1rem"
                        }}
                    />
                </Flex>
                <br />
                <Head color={'#00A15F'}>{t("title")}</Head>
                <br />
                <Description color='#28372E'>{t("body")}</Description>
                <Box width={[0.5, 0.5, 0.1, 0.1]} mt={['2em']}>
                    <SquareButton text={"CONTINUE"} bg={'#28372E'} color={'white'} callback={this.onClickReload}/>
                </Box>
            </FlexContainer>
        );
    }
}

const NewContentPageWithTranslation = withTranslation("newContent")(
    NewContentAvailable
);

export default NewContentPageWithTranslation;
