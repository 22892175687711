import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import confetti from "canvas-confetti";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { connect as connectUser } from "../../../../../../../redux/actions/creators/relations";
import SquareButton from "../../../../atoms/shared/Buttons/SquareButton2";
import SquareOutlined from "../../../../atoms/shared/Buttons/SquareOutlinedButton";
import { Icon } from "../../../../atoms/shared/Icon";
import { Description as Desc, Title } from "../../../../atoms/shared/Text";
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";

const InvitationSentSuccess = props => {
    const {
        t,
        theme,
        onClose,
        invitationValue,
        alreadyRegisteredUser,
        connectUser,
        loading
    } = props;
    const [inviteSent, setInviteSent] = useState(false);

    useEffect(() => {
        if (userIsAlreadyRegistered) return;

        const animationEnd = Date.now() + 9000;
        const canvas = document.createElement("canvas");
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.width = "100%";
        const modalRoot = document.getElementById("success-content");
        modalRoot.appendChild(canvas);
        var confettiCelebration = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        confettiCelebration({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) return clearInterval(interval);

            confettiCelebration({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }, 3000);
    }, []);

    const { firstname = "" } = invitationValue;
    const userIsAlreadyRegistered =
        Object.keys(alreadyRegisteredUser).length > 0;

    const handleConnectUser = async () => {
        await connectUser(alreadyRegisteredUser.uid);
        setInviteSent(true);
    };

    const renderUserAlreadyRegistered = () =>
        inviteSent ? (
            <>
                <Icon
                    icon="check-filled"
                    color={theme.greenMedium}
                    width={"40px"}
                    height={"40px"}
                />
                <Title style={{ marginTop: ".5em", textAlign: "center" }}>
                    {t(
                        "sendInvites_InvitationAlready_Member_connect_success_title"
                    )}
                </Title>
                <Desc style={{ marginTop: "2em", textAlign: "center" }}>
                    {t(
                        "sendInvites_InvitationAlready_Member_connect_success_desc",
                        {
                            name: alreadyRegisteredUser.firstname
                        }
                    )}
                </Desc>
                <Flex mt={theme.spacing[3]}>
                    <SquareButton
                        text={t("invite_other")}
                        bg={theme.greenMedium}
                        style={{ height: 42 }}
                        onClick={onClose}
                    />
                </Flex>
            </>
        ) : (
            <>
                <Title
                    style={{
                        marginTop: "1em",
                        textAlign: "center"
                    }}
                >
                    {t("sendInvites_InvitationAlready_Member", {
                        name: alreadyRegisteredUser.firstname
                    })}
                </Title>
                <Desc
                    style={{
                        marginTop: "2em",
                        textAlign: "center"
                    }}
                >
                    {t("sendInvites_InvitationAlready_Member_connect", {
                        name: alreadyRegisteredUser.firstname
                    })}
                </Desc>
                <Flex
                    flexDirection="row"
                    justifyContent="center"
                    style={{
                        marginTop: "2em",
                        width: "100%",
                        gap: "2em"
                    }}
                >
                    <SquareButton
                        text={t("connect")}
                        bg={theme.greenMedium}
                        style={{ height: 42 }}
                        onClick={handleConnectUser}
                        isLoading={loading}
                    />
                    <SquareOutlined
                        text={t("invite_other")}
                        color={theme.taupeGray}
                        style={{ height: 42, zIndex: 2 }}
                        onClick={onClose}
                    />
                </Flex>
            </>
        );

    return (
        userIsAlreadyRegistered ? (
            renderUserAlreadyRegistered()
        ) : (
            <Flex
                id="success-content"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
                style={{ position: "relative" }}
            >
                <Icon
                    icon="feedback"
                    color={theme.blueMedium}
                    width={"70px"}
                    height={"70px"}
                />
                <Title
                    style={{
                        marginTop: "1em",
                        textAlign: "center"
                    }}
                >
                    {t("sendInvites_InvitationSent_Title")}
                </Title>
                <Desc
                    style={{
                        marginTop: "2em",
                        textAlign: "center"
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(
                            "sendInvites_InvitationSent_Message",
                            {
                                name: firstname
                            }
                        )
                    }}
                />
                <Flex
                    alignContent="center"
                    justifyContent={"center"}
                    mt={["2em"]}
                    width={[0.5, 0.5, 0.2, 0.2]}
                >
                    <SquareButton
                        text="Done"
                        fontSize={"14px"}
                        bg={theme.greenMedium}
                        onClick={onClose}
                    />
                </Flex>
            </Flex>
        )
    );
}

const mapStateToProps = state => {
    return {
        loading: state.main.ui.view.profile.connect.loading
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            connectUser
        },
        dispatch
    );
};


const viewWithTranslation = withTranslation("settings_invites")(InvitationSentSuccess);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(viewWithTranslation));
