import {
    CONTACTS_USER_LIST_FETCH_USERS_FAIL,
    CONTACTS_USER_LIST_FETCH_USERS_SUCC,
    CONTACTS_USER_LIST_FETCH_USERS,
    CONTACTS_SET_CONTACT_UIDS_LIST,
    CONTACTS_CREATE_CONTACT,
    CONTACTS_CREATE_CONTACT_SUCCCESS,
    CONTACTS_CREATE_CONTACT_FAIL
} from "../../../../actions/constants";

const initialState = {
    loading: false,
    errors: [],
    uids: [],
    contacts: []
};

function sendReferralUserListReducer(state = initialState, action) {
    switch (action.type) {
        case CONTACTS_SET_CONTACT_UIDS_LIST: {
            const _state = {
                ...state
            };
            _state.uids = action.payload ? action.payload : [];
            _state.contacts = [];
            return _state;
        }
        case CONTACTS_USER_LIST_FETCH_USERS_FAIL: {
            const _state = {
                ...state
            };
            _state.loading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case CONTACTS_USER_LIST_FETCH_USERS_SUCC: {
            const _state = {
                ...state
            };
            _state.loading = false;
            _state.contacts = action.payload;
            return _state;
        }
        case CONTACTS_USER_LIST_FETCH_USERS: {
            const _state = {
                ...state
            };
            _state.loading = true;
            return _state;
        }
        case CONTACTS_CREATE_CONTACT: {
            const _state = {
                ...state
            };
            _state.loading = true;
            return _state;
        }
        case CONTACTS_CREATE_CONTACT_SUCCCESS: {
            const _state = {
                ...state
            };
            _state.loading = false;
            return _state;
        }
        case CONTACTS_CREATE_CONTACT_FAIL: {
            const _state = {
                ...state
            };
            _state.loading = false;
            _state.errors = [action.errors];
            return _state;
        }
        default:
            return state;
    }
}

export default sendReferralUserListReducer;