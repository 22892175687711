import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StepWrapper, Button, ButtonContainer } from "../../../components";
import * as yup from 'yup'
import { Formik } from 'formik';
import Profession from './_Profession';
import Location from './_Location';
import Compensation from "./_Compensation";
import TimingInfo from "./_TimingInfo";
import Description from "./_Description";
import Acceptance from "./_Acceptance";
import VisibilityAfterExchange from "./_VisibilityAfterExchange";
import RealEstateDetails from "./_RealEstateDetails";
import { DraftModal, useDraft } from "../../../useDraft";
import { Box } from "rebass";

const formInitialValues = {
    profession: null,
    address:  '',
    description: '',
    acceptance: null,
    visibilityAfterExchange: { value: 'EVERYONE', label: 'Yes, display the accepted job to everyone!' },
    // Required if profession is Real Estate Agent
    clientType: null,
    propertyType: null,
    propertySubType: null,

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: '',

    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: '',

    minBeds: '',

    // Required if clientType is 'BUYER'
    financingInfo: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: null,

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: null,
    pets: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL' and propertySubtype is 'MULTI_FAMILY'
    minUnits: '',

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: null,
    maxBeds: '',
    minSquareFeet: '',
    maxSquareFeet: '',
    minAcres: '',
    maxAcres: '',
    maxUnits: '',
    units: '',
    acres: '',
    beds: '',
    baths: '',
    squareFeet: '',
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const formSchemaBuilder = (candidatesCount = 0, isOneOff, opMethod) => {
    return (
        yup.object().shape({
            compensation: yup.string().max(30)
            .when("profession", {
                is: (profession) => !isOneOff,
                then: yup.string().max(30).required()
            }),
            profession: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).nullable(candidatesCount === 1)
            .when("cannotFindProfession", {
                is: (cannotFindProfession) => cannotFindProfession === true,
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                    global: yup.boolean().required(),
                }).nullable()
            })
            .when("cannotFindProfession", {
                is: (cannotFindProfession) => cannotFindProfession === false,
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                    global: yup.boolean().required(),
                }).required()
            }),
            cannotFindProfession: yup.boolean(),
            professionDescription: yup.string().nullable()
            .when("cannotFindProfession", {
                is: (cannotFindProfession) => cannotFindProfession === true,
                then: yup.string().required()
            }),
            address: yup.object().nullable()
            .when("locationIsRemote", {
                is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
                then: yup.object().nullable()
            })
            .when("locationIsRemote", {
                is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
                then: yup.object({
                    description: yup.string().required(),
                    lat: yup.number().required(),
                    lng: yup.number().required(),
                }).required()
            }),
            locationIsRemote: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required(),
            timingInfo: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required(),
            acceptance: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable(candidatesCount === 0 || candidatesCount === 1),
            visibilityAfterExchange: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),  
            }).required(),
            description: yup.string().min(opMethod === 'DIRECT' ? 31 : 130).max(2000).required(),


            // Required if profession is Real Estate Agent
            clientType: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            })
            .nullable()
            .when("profession", {
                is: (profession) => profession && profession.value === '100',
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),


            // Required if profession is Real Estate Agent
            propertyType: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when("profession", {
                is: (profession) => profession && profession.value === '100',
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if profession is Real Estate Agent
            propertySubType: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when("profession", {
                is: (profession) => profession && profession.value === '100',
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if clientType is 'BUYER' or 'TENANT'
            maxPrice: yup.string()
            .nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') || (clientType && clientType.value === 'TENANT') ),
                then: yup.string().required()
            }),
            // Required if clientType is 'SELLER' or 'LANDLORD'
            estimatedPrice: yup.string()
            .nullable()
            .when(["clientType"], {
                is: (clientType) => ((clientType && clientType.value === 'SELLER') || (clientType && clientType.value === 'LANDLORD') ),
                then: yup.string().required()
            }),

            minBeds: yup.number().nullable(),

            // Required if clientType is 'BUYER'
            financingInfo: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when(["clientType"], {
                is: (clientType) => ((clientType && clientType.value === 'BUYER')),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
            homeSaleContingency: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when(["clientType", "propertyType"], {
                is: (clientType, propertyType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            minSquareFeet: yup.number().nullable(),
            maxSquareFeet: yup.number(),
            maxUnits: yup.number(),

            minUnits: yup.object()
            .nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY') ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
            credit: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
            pets: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
            minLotSize: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => (
                    ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'LAND')) ||
                    ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'LAND')) ||
                    ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
                ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),

            units: yup.object()
            .nullable()
            .when(["clientType", "propertyType", "propertySubType"], {
                is: (clientType, propertyType, propertySubType) => (
                    (clientType && clientType.value === 'SELLER') &&
                    (propertyType && propertyType.value === 'RESIDENTIAL') &&
                    (propertySubType && propertySubType.value === 'MULTI_FAMILY')
                ),
                then: yup.object({
                    value: yup.string().required(),
                    label: yup.string().required(),
                }).required()
            }),
        })
    );
}

const JobCurrentContractorCompleteInformation = ({
    setStepData,
    stepData,
    professions,
    theme,
    t,
    candidates,
    jobType,
    clientStatusOptions
}) => {
    const { contractorType } = jobType;
    const isOneOff = contractorType.value === 'ONEOFF'
    const [professionOptions, setProfessionOptions] = useState([]);
    const opMethod = candidates.length === 1 ? 'DIRECT' : 'NON-DIRECT-OP';
    const candidatesCount = candidates ? candidates.length : 0;
    const formSchema = formSchemaBuilder(candidatesCount, isOneOff, opMethod);
    const realEstateDetailsOptional =    false //candidatesCount === 1 && candidates[0].user_type === '100';
    const {saveDraft, loading, showModal, setShowModal, modalMessage} = useDraft();
  

    useEffect(() => {
        if (!!professions.length) {
            setProfessionOptions(professions.map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);

    const onSubmitHandler = (values) => {
        setStepData(values);
    }

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={stepData ?  ({ ...formInitialValues, ...stepData}) : formInitialValues}
                onSubmit={(values) => onSubmitHandler(values)}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Container>
                        { candidatesCount !== 1 && (
                            <Profession
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                professionOptions={professionOptions}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                            />
                        )}
                        <Location
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />
                        <Compensation
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            isRequired={!isOneOff}
                        />
                        <TimingInfo
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            clientStatusOptions={clientStatusOptions}
                        />
                        { (realEstateDetailsOptional || (values.profession && values.profession.value === '100')) && (
                            <RealEstateDetails
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                                optional={realEstateDetailsOptional}
                            />
                        )}
                        <Description
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            candidates={candidates}
                        />
                        { candidatesCount > 1 && (
                            <Acceptance
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                            />
                        )}
                        { candidatesCount > 0 && (
                            <VisibilityAfterExchange
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                            />
                        )}
                        <ButtonContainer>
                            <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={saveDraft}
                                    disabled={loading}
                                >
                                    Save Draft
                                </Button>
                            </Box>
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default JobCurrentContractorCompleteInformation;
