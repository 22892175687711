import React, { useEffect, useState, useRef } from 'react'
import { Flex } from "rebass"
import Select from "react-select";
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";
import {
    Input,
    InputContainer,
    InputLabel,
    TextArea,
} from "../../../../Settings/common/Components";
import { InputCounter } from "../../../../Settings/InformationAccount/styles";
import {  selectStyle } from "../../../components";

const Profession = ({
    theme,
    professions,
    formValues,
    onChange,
    errors,
}) => {
    const [professionOptions, setProfessionOptions] = useState([]);

    useEffect(() => {
        if (professions !== [] && professions.length) {
            setProfessionOptions(professions.filter(filteredProfessions => filteredProfessions.status !== 'pending').map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);
  
    const selectRef = useRef()
    return (
        <>
            <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                <InputContainer
                    triggerShowLabel={
                        formValues.state ? "show" : "hide"
                    }
                    showLabelOnValue
                >
                    <Select
                        options={professionOptions}
                        ref={selectRef} // <<< ADD THIS LINE

                        isClearable
                        isDisabled={!!formValues.cannotFindProfession}
                        onChange={option => {
                            onChange({
                                target: {
                                    name: "profession",
                                    value: option,
                                }
                            })
                            onChange({
                                target: {
                                    name: "propertyType",
                                    value: null,
                                }
                            })
                            onChange({
                                target: {
                                    name: "propertySubType",
                                    value: null,
                                }
                            })
                            onChange({
                                target: {
                                    name: "clientType",
                                    value: null,
                                }
                            })
                            onChange({
                                target: {
                                    name: "cannotFindProfession",
                                    value: false,
                                }
                            })
                            onChange({
                                target: {
                                    name: "professionDescription",
                                    value: null,
                                }
                            })
                        }}
                        styles={selectStyle}
                        name="profession"
                        placeholder="Select...*"
                        value={formValues.profession}
                        error={!!errors.profession}
                    />
                </InputContainer>
            </Flex>

            <Flex p={`${theme.spacing[5]} 0`}>
                <CheckBox
                    inverted
                    disabled={!!formValues.profession}
                    label="Check this box if you can't find the right profession."
                    onChange={ev =>
                        onChange({
                            target: {
                                name: "cannotFindProfession",
                                value: !formValues.cannotFindProfession
                            }
                        })
                    }
                    checkBoxColor={theme.blueMedium}
                    checked={formValues.cannotFindProfession}
                />
            </Flex>

            {formValues.cannotFindProfession && (<Flex p={`${theme.spacing[5]} 0`}>
                <InputContainer>
                    <TextArea
                        style={{ resize: "vertical" }}
                        type="text"
                        name="professionDescription"
                        value={formValues.professionDescription}
                        onChange={onChange}
                        maxLength="30"
                        minLength="1"
                        placeholder="State the profession in less than 30 characters*"
                        error={!!errors.professionDescription}
                    />
                    {!!formValues.professionDescription &&
                        !!formValues.professionDescription.length && (
                            <InputCounter>
                                {formValues.professionDescription.length}/30
                            </InputCounter>
                        )}
                </InputContainer>
            </Flex>)}
        </>
    )

};

export default Profession;
