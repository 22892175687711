import styled from "styled-components";

export const Title = styled.div`
    font-size: ${props => (props.theme.mobileDevice ? "18px" : "28px")};
    font-weight: 600;
    letter-spacing: ${props => (props.theme.mobileDevice ? "-0.43 " : "0")};
    line-height: ${props => (props.theme.mobileDevice ? "21px " : "34px")};
    color: ${props => props.theme.coolBlack};
`;

export const Subtitle = styled.div`
    font-size: ${props => (props.theme.mobileDevice ? "15px" : "24px")};
    font-weight: 600;
    letter-spacing: ${props => (props.theme.mobileDevice ? "-0.40 " : "0")};
    line-height: ${props => (props.theme.mobileDevice ? "18px " : "30px")};
    color: ${props => props.theme.coolBlack};
`;


export const CancelModalTitle = styled.div`
    color: ${props => props.theme.coolBlack};
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 2.5em;
`;


export const RTitle = styled.div`
    font-size: ${props => (props.theme.mobileDevice ? "18px" : "28px")};
    letter-spacing: ${props => (props.theme.mobileDevice ? "-0.43 " : "0")};
    line-height: ${props => (props.theme.mobileDevice ? "21px " : "34px")};
    color: white;
`;

export const Container = styled.div`
    z-index: 999;
    position: fixed;
    bottom: 0px;
    height: 130px;
    width: 100vw;
    background-color: ${props => props.theme.clearGray};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5em;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1.5em;
`;

export const ContainerMobile = styled.div`
    z-index: 999;
    position: fixed;
    bottom: 0px;
    height: 67px;
    width: 100vw;
    background-color: ${props => props.theme.clearGray};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: 0.5px solid #c8c8c8;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
`;

export const ArrowLeft = styled.div`
    position: fixed;
    left: 0px;
    top: calc(50vh - 26px);
`;
export const ArrowBox = styled.div`
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    box-sizing: border-box;
    padding: 1em;
    z-index: 999;
    width: 100%;
    border-radius: 3.49px;
    border: ${props =>
        `0.87px solid ${props.color ? props.color : props.theme.greenMedium}`};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        props.color ? props.color : props.theme.greenMedium};
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
`;

export const ProgressLine = styled.div`
    box-sizing: border-box;
    height: 1px;
    width: ${props => (props.width ? props.width : "100%")};
    border: ${props => `5px solid ${props.theme.greenMedium}`};
    position: fixed;
    bottom: 0px;
    left: 0px;
    transition: width 1s;
`;

export const CancelText = styled.div`
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    z-index: 999;
    color: ${props => props.theme.coolBlack};
    font-size: 15px;
    letter-spacing: -0.36px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
`;
