import React from 'react'
import PopupModal from '../PopupBlurModal'
import styled from 'styled-components'
import { Loader } from './Loader'
import { theme } from '../../../../../../theme'


export const PopupLoader = () => {
    return (
        <PopupModal
        open={ true }
        width={'100%'}
        modalContentStyles={{ 
         background: 'transparent',
         maxWidth: theme.mobileDevice ? "100vw" : "700px",
         minWidth: "393px",
         borderRadius: "16px",
         margin: "auto",
         position: "fixed",
         top: 80,
         left: 0,
         right: 0
        }}
        >
           <Containcer>
               <Loader />
           </Containcer>
        </PopupModal>
    )
}



export const Containcer = styled.div`
max-width: 1035px;
background: #ffffff;
border-radius: 8px;
display: flex;
    justify-content: center;
    align-items: center;
@media screen and (min-width: ${({ theme }) => theme.breakpoints["0"]}) {
   padding: 20px 20px;
}
@media screen and (min-width: ${({ theme }) => theme.breakpoints["1"]}) {
   padding: 40px 40px;
}
@media screen and (min-width: ${({ theme }) => theme.breakpoints["2"]}) {
   padding: 80px 60px;
}
`;


