import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass";
import { WhiteButton } from "../styles";
import {
    InputContainer,
    InputLabel,
    Input
} from "../../../../Settings/common/Components";
import { Icon } from "../../../../../atoms/shared/Icon";
import PeopleSearchBar from "../../../../../atoms/shared/PeopleSearchBar";
import UserInfo from "../../../../../atoms/shared/PeopleSearchBar/UserInfo";
import Select from 'react-select'
import { stateShortData } from './stateShort'
import { selectStyle } from "../../../components";
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";
import CreateContactModal from "../../../../../atoms/shared/CreateContactModal";
import { mapContactToUserInfo } from "../../../../../../../../libs/Utils";

const SearchSuggestionWrapper = styled.div`
    width: 100%;
    border: 1px solid #929292;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 50px;
`;

const HelpText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #929292;
    padding: .5em 0em;
`;

const ClientInformation = ({
    theme,
    formValues,
    onChange,
    errors,
}) => {

    const [suggestion, setSuggestion] = useState(formValues.clientInformation)

    // const resetManualClientInformation = () => {
    //     onChange({
    //         target: {
    //             name: "clientInformation",
    //             value: null,
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientFirstName",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientLastName",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientEmail",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientMobileNumber",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientStreetAddress",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientCity",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientState",
    //             value: '',
    //         }
    //     })
    //     onChange({
    //         target: {
    //             name: "clientZipCode",
    //             value: '',
    //         }
    //     })
    // }

    // const resetFields = () => {
    //     resetManualClientInformation()
    //     setSuggestion(null)
    // }

    const toggleCreateContactModal = () => {
        onChange({
            target: {
                name: "searchUserMode",
                value: !formValues.searchUserMode,
            }
        })
    }

    // const actionButtonHandler = () => {
    //     resetFields()
    //     toggleCreateContactModal()
    // }

    const onClickSuggestionHandler = (suggestion) => {
        setSuggestion(suggestion)
        onChange({
            target: {
                name: "clientInformation",
                value: suggestion,
            }
        })
    }

    const onContactCreatedHandler = (contact) => {
        contact = mapContactToUserInfo(contact);
        onClickSuggestionHandler(contact)
    };

    return (
        <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
{/*             {formValues.agreement && formValues.agreement.value && (<HelpText>Client information is required by NuOp to create a binding agreement for this referral*</HelpText>)}
 */}
            <Flex flexDirection={'column'}>
                <Flex mt={theme.spacing[3]}>
                    {!suggestion && <PeopleSearchBar error={!!errors.clientInformation} includeContacts onClickSuggestion={(user) => onClickSuggestionHandler(user)} onClearSuggestion={() => setSuggestion(null)} />}
                    {suggestion && (
                        <SearchSuggestionWrapper>
                            <UserInfo
                                key={suggestion._id}
                                id={suggestion._id}
                                avatarUrl={suggestion.avatar}
                                name={suggestion.full_name}
                                organization={suggestion.company_name}
                                tier={suggestion.subscription.plan}
                                location={suggestion.locationAddress}
                                background="#FAFAFA"
                                firstName={suggestion.first_name}
                                lastName={suggestion.last_name}
                            />
                            <Icon
                                icon="close"
                                color={"#929292"}
                                onClick={() => setSuggestion(null)}
                            />
                        </SearchSuggestionWrapper>
                    )}
                </Flex>
                <Flex mt={theme.spacing[3]}>
                    <WhiteButton onClick={() => toggleCreateContactModal()}>
                        <Icon
                            icon="profile"
                            width={12}
                            height={12}
                            color={theme.newBlue}
                            style={{ marginRight: theme.spacing[2] }}
                        />
                        Create Contact
                    </WhiteButton>
                </Flex>
            </Flex>
            <CreateContactModal
                open={!formValues.searchUserMode}
                onClose={() => toggleCreateContactModal()}
                onContactCreated={onContactCreatedHandler}
            />
            {/* {!formValues.searchUserMode && (
                <Flex flexDirection={'column'}>
                    <Flex mt={theme.spacing[3]}>
                        <Box width={1 / 2} pr={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientFirstName'}
                                    placeholder={'First Name*'}
                                    required
                                    value={formValues.clientFirstName}
                                    onChange={onChange}
                                    error={!!errors.clientFirstName}
                                />
                                <InputLabel> First Name* </InputLabel>
                            </InputContainer>
                        </Box>
                        <Box width={1 / 2} pl={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientLastName'}
                                    placeholder={'Last Name*'}
                                    required
                                    value={formValues.clientLastName}
                                    onChange={onChange}
                                    error={!!errors.clientLastName}
                                />
                                <InputLabel> Last Name* </InputLabel>
                            </InputContainer>
                        </Box>
                    </Flex>
                    <Flex mt={theme.spacing[3]}>
                        <Box width={1 / 2} pr={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientEmail'}
                                    placeholder={'Email'}
                                    required
                                    value={formValues.clientEmail}
                                    onChange={onChange}
                                    error={!!errors.clientEmail}
                                />
                                <InputLabel> Email </InputLabel>
                            </InputContainer>
                        </Box>
                        <Box width={1 / 2} pl={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientMobileNumber'}
                                    placeholder={'Mobile Number'}
                                    required
                                    value={formValues.clientMobileNumber}
                                    onChange={onChange}
                                    error={!!errors.clientMobileNumber}
                                />
                                <InputLabel> Mobile Number </InputLabel>
                            </InputContainer>
                        </Box>
                    </Flex>
                    <HelpText>Either mobile number or email is required*</HelpText>
                    <Flex>
                        <Box width={1 / 2} pr={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientStreetAddress'}
                                    placeholder={'Street Address'}
                                    required
                                    value={formValues.clientStreetAddress}
                                    onChange={onChange}
                                    error={!!errors.clientStreetAddress}
                                />
                                <InputLabel> Street Address </InputLabel>
                            </InputContainer>
                        </Box>
                        <Box width={1 / 2} pl={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientCity'}
                                    placeholder={'City'}
                                    required
                                    value={formValues.clientCity}
                                    onChange={onChange}
                                    error={!!errors.clientCity}
                                />
                                <InputLabel> City </InputLabel>
                            </InputContainer>
                        </Box>
                    </Flex>
                    <Flex mt={theme.spacing[3]}>
                        <Box width={1 / 2} pr={2}>
                            <InputContainer showLabelOnValue>
                                <Select
                                    styles={selectStyle}
                                    options={stateShortData}
                                    onChange={option =>
                                        onChange({
                                            target: {
                                                name: "clientState",
                                                value: option,
                                            }
                                        })
                                    }
                                    placeholder={'State'}
                                    name="clientState"
                                    value={formValues.clientState}
                                //  error={!!errors.clientState}

                                />
                                <InputLabel> State </InputLabel>
                            </InputContainer>
                        </Box>
                        <Box width={1 / 2} pl={2}>
                            <InputContainer showLabelOnValue>
                                <Input
                                    name={'clientZipCode'}
                                    placeholder={'Zip'}
                                    required
                                    value={formValues.clientZipCode}
                                    onChange={onChange}
                                    error={!!errors.clientZipCode}
                                />
                                <InputLabel> Zip </InputLabel>
                            </InputContainer>
                        </Box>
                    </Flex>
                    <Flex mt={theme.spacing[3]}>
                        <WhiteButton onClick={() => actionButtonHandler()}>
                            <Icon
                                icon="profile"
                                width={12}
                                height={12}
                                color={theme.newBlue}
                                style={{ marginRight: theme.spacing[2] }}
                            />
                            Search User
                        </WhiteButton>
                    </Flex>
                </Flex>
            )} */}
            {false && ( /** Checkbox is hidden , it's not ready yet */
                <Flex p={`${theme.spacing[5]} 0`}>
                    <CheckBox
                        inverted
                        disabled={!!formValues.notifyToClient}
                        label="Notify Client about this referral"
                        onChange={ev =>
                            onChange({
                                target: {
                                    name: "notifyToClient",
                                    value: !formValues.notifyToClient
                                }
                            })
                        }
                        checkBoxColor={theme.blueMedium}
                        checked={formValues.notifyToClient}
                    />
                </Flex>
            )}
        </Flex>
    );
}


export default ClientInformation;