import React from "react";
import {
    InputMaterial,
    FieldSet,
    InputLabel,
    After
} from "./materialInput-styles";
import { withTheme } from "styled-components";

const MaterialInput = ({ innerRef, ...props }) => {
  const inputStyles = props.inputStyles
            ? props.inputStyles
            : {
                color: props.theme.coolBlack,
                fontSize: "17px",
                lineHeight: "20px",
                fontWeight: "600",
                letterSpacing: "-0.41px"
            };
    return (
            <FieldSet style={{ margin: 0, padding: 0 }}>
                <InputMaterial
                    required
                    {...props}
                    {...inputStyles}
                    value={props.value}
                    ref={innerRef}
                    placeholder={props.label ? props.placeholder : ''}
                    style={{ ...inputStyles }}
                    icon={props.icon}
                    type={props.type}
                    name={props.name}
                    readOnly={props.readOnly}


                />
                <InputLabel
                    {...inputStyles}
                    icon={props.icon ? true : false}
                    htmlFor="first"
                >
                    {props.label ? props.label : props.placeholder}
                </InputLabel>
                {props.notUnderlined ? null : <After className="after" />}
            </FieldSet>

    )
}

export default withTheme(MaterialInput);
