export default class Search {
    static renderSuggestionFromDataSource = suggestion =>
        suggestion && suggestion._source && suggestion._source.first_name && suggestion._source.last_name ?
            `${suggestion._source.first_name} ${suggestion._source.last_name}` : ''

    static getSearchThread = (term, location) => {
        let searchThread;

        if (location) {
            if (!term || term === '') {
                searchThread = 'LOCATION';
            } else {
                searchThread = 'TERM_AND_LOCATION';
            }
        } else {
            if (!term || term === '') {
                searchThread = 'EMPTY';
            } else {
                searchThread = 'TERM';
            }
        }

        return searchThread;
    }

    static normalizeFilters =                                                             (newFilters, userTypes = []) => {
      
        let response = {}
        Object.keys(newFilters).forEach(facet => {
            let facetNorm = facet.toLowerCase()
            if (facetNorm === 'user_type') {
                let hasSalesType = false
                const newFacets = newFilters[facet].buckets.map(option => {
                    //RETROCOMPATIBILITY
                    if (Number(option.key) === 0 || Number(option.key) === 1 || Number(option.key) === 100) {
                        if (!hasSalesType) {
                            hasSalesType = true
                            return { ...option, key: 100, name: 'Real Estate Sales' }

                        }
                        return null
                    }

                    const ut = userTypes.find(type => Number(type.key) === Number(option.key))
                    if (ut) return { ...option, name: ut.name }
                    return option

                }).filter(filter => filter)
                response[facetNorm] = newFacets
            } else {
                response[facetNorm] = newFilters[facet].buckets
            }
        })
        return response
    }

    static getFilterName = {
        'user_type': 'Profile',
        'focus': "Service Focus",
        'languages': "Languages"
    }
    static getFilterOptionTitle = (filterName, option) => filterName === 'user_type' ? option.name : option.key
    static buildSearchFiltersQueryString = (selectedFilters = {}) => {
        let response = ''
        Object.keys(selectedFilters).forEach(filterName => {
            let facets = Object.keys(selectedFilters[filterName])
            if (filterName === 'user_type' && facets.indexOf(100) !== -1) {
                facets.push(0, 1)
            }

            if (facets.length === 1) {
                if (selectedFilters[filterName][facets[0]]) {
                    const qs = `${filterName}=${facets[0]}`
                    response = response.length > 0 ? `${response}&${qs}` : `?${qs}`
                }
            } else {
                facets.forEach((facet, index) => {
                    if (selectedFilters[filterName][facet]) {
                        const qs = `${filterName}[${index}]=${facet}`
                        response = response.length > 0 ? `${response}&${qs}` : `?${qs}`
                    }

                })
            }

        })
        return response
    }
}