import React from "react";
import { WithoutBordersInput } from "./styles";
import { withTheme } from "styled-components";
import { Flex } from "rebass";
export default withTheme(({ theme, autofocus, innerRef, rightIcon, inputStyles, ...otherProps }) => {
  
    return (
            <Flex alignItems="center" justifyContent="flex-start">
                <WithoutBordersInput
                    {...otherProps}
                    ref={innerRef}
                    style={inputStyles ? inputStyles : null}
                />

                {rightIcon}
            </Flex>
    );
});
