import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { Icon } from "../../../ui/presentation/atoms/shared/Icon";
import { CreditCardDesc } from "../../../ui/presentation/atoms/shared/Text";

const FlexContainer = styled.div`
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    position: ${props => props.theme.mobileDevice ? 'fixed' : 'absolute'};
    top: ${props => props.theme.mobileDevice ? '38px' : '60px'};
    z-index: 9999;
    background-color: ${props => props.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    flex-wrap: wrap;
`;

class VerifiedUserBadge extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            badgeOpen: true
        };
    }
    closeBadge = () => {
       
        this.setState({ badgeOpen: false });
    };
    render() {
        const { profile_verification_pending, verified } = this.props;
        if (verified || !profile_verification_pending || !this.state.badgeOpen) return null;
        else {
            const text = profile_verification_pending
                ? "Your account is pending to be verified. It usually it takes up to 24 hours"
                : !verified
                ? "Your account is not verified. Know about the benefits of being part of our network"
                : null;
            return (
                <FlexContainer
                    backgroundColor={
                        profile_verification_pending ? "#FFA057" : "#e56767"
                    }
                >
                    <Box
                        pt={[".5em", ".5em", "1em", "1em"]}
                        pb={[".5em", ".5em", "1em", "1em"]}
                        mr={[".5em",".5em","2em","2em"]}
                      
                    >
                        <CreditCardDesc color={"white"}>{text}</CreditCardDesc>
                    </Box>
                    <Box onClick={this.closeBadge}>
                        <Icon icon="close" color="white"  />
                    </Box>
                </FlexContainer>
            );
        }
    }
}

export default VerifiedUserBadge;
