export const checkOutlined = [
           "20 20",
           `     <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.25 10C1.25 5.16914 5.16914 1.25 10 1.25C14.8309 1.25 18.75 5.16914 18.75 10C18.75 14.8309 14.8309 18.75 10 18.75C5.16914 18.75 1.25 14.8309 1.25 10Z"
        stroke="#00A15F"
        stroke-miterlimit="10"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7451 6.39645C15.0472 6.61846 15.0864 7.0127 14.8326 7.27701L8.83264 13.527C8.69967 13.6655 8.50462 13.7468 8.29791 13.7499C8.0912 13.753 7.89309 13.6776 7.75479 13.5431L5.18337 11.0431C4.91947 10.7865 4.94324 10.3914 5.23646 10.1604C5.52968 9.92954 5.98131 9.95034 6.24521 10.2069L8.26712 12.1727L13.7388 6.47302C13.9925 6.20871 14.4431 6.17443 14.7451 6.39645Z"
        fill="#00A15F"
    />`
       ];