class GeolocationManager {
    constructor(){
        this.config = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        }
    }
    getCurrentPosition = () => new Promise((resolve,reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                resolve({
                    lat: position.coords.latitude,
                    lon: position.coords.longitude
                });
            }, function (error) {
                reject(error.message);
            }, this.config);
        } else {
            reject("Geolocation is not supported by this browser.");
        }
    });
}

export default GeolocationManager;