import PropTypes from 'prop-types'
import React, {  forwardRef, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'

const getSize = (size) => {
    switch (size) {
        case 'sm':
        return '10px'
        case 'lg':
        return '21px'
        case 'xl':
        return '24px'
        case 'xxl':
        return '28px'
        case '3xl':
        return '32px'
        case '4xl':
        return '35px'
        case "md":
        default:
        return '14px'
    }
}
// Wrapper is needed as a workaround for styled-components bug
// where it doesn't set the fill property on the svg element
const InconWrapper = styled.div`
    display: inherit;

    svg path, svg rect, svg rect[stroke=""], svg circle {
        transition: fill 0.2s ease-in-out;
    }

    svg path:not([fill]) {
        fill: ${({ color }) => (color ? color : null)};
    }
    svg rect:not([fill]) {
        fill: ${({ color }) => (color ? color : null)};
    }
    svg rect[stroke=""] {
        stroke: ${({ color }) => (color ? color : null)};
    }
    svg circle:not([fill]) {
        fill: ${({ color }) => (color ? color : null)};
    }

    svg:hover {
        path, rect, rect[stroke=""], circle {
            transition: fill 0.2s ease-in-out;
        }

        path:not([fill]) {
            fill: ${({ colorOnHover }) => (colorOnHover ? colorOnHover : null)};
        }
        rect:not([fill]) {
            fill: ${({ colorOnHover }) => (colorOnHover ? colorOnHover : null)};
        }
        rect[stroke=""] {
            stroke: ${({ colorOnHover }) => (colorOnHover ? colorOnHover : null)};
        }
        circle:not([fill]) {
            fill: ${({ colorOnHover }) => (colorOnHover ? colorOnHover : null)};
        }
    }
`;

const StyledIcon = styled.svg`
    display: inline-block;
    vertical-align: middle;
    fill: none;
    color: inherit;
    text-align: center;

    ${({ size }) => size && css`
        width: ${getSize(size)};
        height: ${getSize(size)};
    `}

    `

const toCamelCase = (str) => {
    return str
        .replace(/([-_][a-z0-9])/gi, ($1) => {
            return $1.toUpperCase();
        })
        .replace(/-/gi, "");
};

const Icon = forwardRef(
    (
        {
            className,
            height = 20,
            icon,
            name,
            size,
            title,
            use,
            width = 20,
            color,
            colorOnHover,
            ...rest
        },
        ref
    ) => {
        const [change, setChange] = useState(0);
        const _icon = icon || name;
        // Trigger change when icon is changed
        useMemo(() => setChange(change + 1), [_icon, JSON.stringify(_icon)]);
        // Get icon name
        const iconName = useMemo(
            () =>
                _icon && typeof _icon === "string" && _icon.includes("-")
                    ? toCamelCase(_icon)
                    : _icon,
            [change]
        );

        const titleCode = title ? `<title>${title}</title>` : "";
        // Get icon by name or use icon array if provided
        // Icon array format: [viewBox, iconCode]
        const code = useMemo(() => {
            if (Array.isArray(_icon)) {
                return _icon;
            }
            if (typeof _icon === "string" && React["icons"]) {
                return React["icons"][iconName];
            }
        }, [change]);

        if (!code) {
            throw new Error(`Icon "${iconName}" not found. Please check your icon name and make sure it exists in React.icons object.`)
        }

        // Get icon code
        const iconCode = useMemo(() => {
            return Array.isArray(code) ? code[1] || code[0] : code;
        }, [change]);
        // Get icon scale
        const scale = (() => {
            return Array.isArray(code) && code.length > 1 ? code[0] : "64 64";
        })();
        // Get viewBox
        const viewBox = (() => {
            return rest["viewBox"] || `0 0 ${scale}`;
        })();

        // render

        return use ? (
            <StyledIcon
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                {...(height && { height: height })}
                {...(width && { width: width })}
                role="img"
                size={size}
                {...rest}
                ref={ref}
            >
                <use href={use}></use>
            </StyledIcon>
        ) : (
            <InconWrapper color={color} colorOnHover={colorOnHover}>
                <StyledIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={viewBox}
                    className={className}
                    {...(height && { height: height })}
                    {...(width && { width: width })}
                    role="img"
                    size={size}
                    dangerouslySetInnerHTML={{ __html: titleCode + iconCode }}
                    {...rest}
                    ref={ref}
                />
            </InconWrapper>
        );
    }
);

Icon.propTypes = {
    /**
   * A string of all className you want applied to the component.
   */
    className: PropTypes.string,
    /**
   * The height attribute defines the vertical length of an icon.
   * @default 20
   */
    height: PropTypes.number,
    /**
   * Name of the icon placed in React object.
   */
    name: PropTypes.string,
    /**
   * Name of the icon placed in React object or SVG content.
   */
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    /**
   * Size of the icon. Available sizes: 'sm', 'lg', 'xl', 'xxl', '3xl....
   */
    size: PropTypes.oneOf([
        "sm",
        "md",
        "lg",
        "xl",
        "xxl",
        "3xl",
        "4xl",
    ]),
    title: PropTypes.any,
    /**
   * If defined component will be rendered using 'use' tag.
   */
    use: PropTypes.any,
    /**
   * The width attribute defines the horizontal length of an icon.
   * @default 20
   */
    width: PropTypes.number,
    /**
     * Color of the icon
     */
    color: PropTypes.string,
};

Icon.displayName = "Icon";

export { Icon }