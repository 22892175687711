import { initializeApp } from 'firebase/app'
import {
    getAuth,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithCredential,
    signInWithRedirect,
    signInWithPopup,
    signOut,
    getIdToken,
    onIdTokenChanged,
    onAuthStateChanged,
    getRedirectResult,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
    updatePassword,
    updateEmail,
} from 'firebase/auth'
import {
    getDatabase,
    ref,
    get,
    child,
    update,
    remove,
    set,
    orderByChild,
    query,
    equalTo,
    push,
    limitToFirst,
    startAt,
    orderByKey,
    onValue,
    off,
    onChildAdded,
    limitToLast,
    endAt,
    onChildChanged,
    serverTimestamp
} from 'firebase/database'
import {
    getStorage,
    ref as refStorage,
    uploadBytes,
    getDownloadURL,
} from 'firebase/storage'
import {
    getFirestore,
    doc,
    onSnapshot,
    getDoc,
    collection,
} from 'firebase/firestore';
import {
    getMessaging,
    getToken,
    isSupported,
    onMessage,
} from "firebase/messaging";
import { getAnalytics } from 'firebase/analytics'
import LocalStorage from "./LocalStorage";
import { GeoFire } from 'geofire';
import axios from "axios";
import UserBusiness from "../business/user"
class Firebase {
    constructor(config) {

        this.app = initializeApp(config)
        this.auth = getAuth(this.app)
        this.database = getDatabase(this.app)
        this.firestore = getFirestore(this.app)
        this.storage = getStorage(this.app)
        this.messaging = getMessaging(this.app)
        this.localStorage = new LocalStorage();
        this.geoFire = new GeoFire(ref(this.database, '/GeoFire/open_referrals'))
        this.geoQueries = {};

        this.tokenRestApi = axios.create({
            baseURL: `https://securetoken.googleapis.com/v1/token?key=${config.apiKey}`,
            timeout: 50000 /* config.timeout */,
            defaultInterceptors: true
        });
        this.tokenRestApi.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

        if (["PRODUCTION", "STAGING"].includes(process.env.REACT_APP_ENVIRONMENT)) {
            this.analytics = getAnalytics(this.app)
            // this.analyticsEventsName = this.analytics.EventName
        }

        this.GoogleAuthProvider = new GoogleAuthProvider();
        this.AppleProvider = new OAuthProvider("apple.com");
        this.AppleProvider.addScope("email");
        this.AppleProvider.addScope("name");
        this.AppleProvider.addScope('displayName');

        // // console.log("FCM supported", firebase.messaging.isSupported());
        // // if (firebase.messaging.isSupported()) {
        // //     this.messaging = app.messaging();
        // //     this.messaging.usePublicVapidKey(config.vapiKey);

        // //     this.messaging.onMessage(function(payload) {
        // //         console.log("onMessage", payload);
        // //         this.messaging.registration.showNotification(
        // //             payload.data.title,
        // //             {
        // //                 body:
        // //                     payload && payload.data && payload.data.body
        // //                         ? payload.data.body
        // //                         : "body handled",
        // //                 data: payload.data.link,
        // //                 icon:
        // //                     "https://dev.reallyhq.com/images/icons/icon-96x96.png"
        // //             }
        // //         );
        // //     });
        // // }
    }

    isValidKey = key => {
        const invalidKeys = {
            "": "",
            $: "$",
            ".": ".",
            "#": "#",
            "[": "[",
            "]": "]"
        };
        return invalidKeys[key] === undefined;
    };

    // *** Utils ***

    cleanObject = object => {
        const cleanProfile = {};

        Object.keys(object).forEach(profileKey => {
            if (typeof object[profileKey] !== "undefined") {
                cleanProfile[profileKey] = object[profileKey];
            }
        });

        return cleanProfile;
    };

    static flatArrayFromFirebaseList = object => {
        const list = [];

        Object.keys(object).forEach(profileKey => {
            if (object[profileKey]) {
                list.push(profileKey);
            }
        });

        return list;
    };

    static normalizeArrayFromFirebaseSnapshot = firebaseSnapshot => {
        return firebaseSnapshot
            ? Object.keys(firebaseSnapshot)
                  .map(key => {
                      let ref = firebaseSnapshot[key];
                      if (typeof ref === "object" || Array.isArray(ref))
                          ref.id = key;
                      return ref;
                  })
                  .filter(firebaseSnapshot => firebaseSnapshot)
            : [];
    };

    flatArrayFromSnapshot = snap => {
        let data = [];
        if (snap)
            snap.forEach(childSnap => {
                const object = {};
                object.item = childSnap.val();
                object.key = childSnap.key;
                data.push(object);
            });
        return data;
    };

    arrayToFirebaseObject = arr => {
        const list = {};

        arr.forEach(item => {
            list[item] = true;
        });

        return list;
    };

    firebaseStringParse = stringValue => {
        try {
            return stringValue
                .replace("%%%", ".")
                .replace("^^^", "#")
                .replace("~~~", "$")
                .replace("{{{", "[")
                .replace("}}}", "]")
                .replace("@@@", "/");
        } catch (error) {
            console.log(error);
            return stringValue;
        }
    };

    stringfirebasesify = stringValue => {
        try {
            return stringValue
                .replace(".", "%%%")
                .replace("#", "^^^")
                .replace("$", "~~~")
                .replace("[", "{{{")
                .replace("]", "}}}")
                .replace("/", "@@@");
        } catch (error) {
            console.log(error);
            return stringValue;
        }
    };

    stringfirebasesifyObject = (objectToFirebasesify = {}) => {
        let firebaserifyedObject = {
            ...objectToFirebasesify
        };
        Object.keys(firebaserifyedObject).forEach(key => {
            const firebasesifyString = this.stringfirebasesify(key);
            firebaserifyedObject[firebasesifyString] =
                firebaserifyedObject[key];
            if (key !== firebasesifyString) delete firebaserifyedObject[key];
        });

        return firebaserifyedObject;
    };

    parseFirebaseObjectKeys = objectToParse => {
        let firebaserifyedObject = {
            ...objectToParse
        };
        Object.keys(firebaserifyedObject).forEach(key => {
            const firebasesifyString = this.firebaseStringParse(key);
            firebaserifyedObject[firebasesifyString] =
                firebaserifyedObject[key];
            if (key !== firebasesifyString) delete firebaserifyedObject[key];
        });

        return firebaserifyedObject;
    };

    generateRandomFirebaseKey = (reference) => {
        const dbRef = ref(this.database, reference)
        return push(dbRef).key
    }
    // *** Analytics API ***

    googleAnalytics = () => {
        return this.analytics;
    }

    getAnalyticsEventNames = () => {
        return this.analyticsEventsName;
    }

    // *** Auth API ***

    getIdToken = () => {
        return getIdToken(this.auth.currentUser, true);
    }

    onIdTokenChanged = cb => {
        return onIdTokenChanged(this.auth, cb);
    }

    checkEmailVerified = uid => {
        const emailVerified =
            this.auth.currentUser && this.auth.currentUser.emailVerified;

        if (emailVerified) {
            try {
                const dbRef = ref(this.database, `/users/${uid}`)
                update(dbRef, { verified_email: emailVerified })
            } catch (error) {
                console.log("checkEmailVerified error", error);
            }
        }
        return emailVerified;
    };

    createUserWithEmailAndPassword = (email, password) => {
        return createUserWithEmailAndPassword(this.auth, email, password);
    }

    signInWithEmailAndPassword = (email, password) => {
        return signInWithEmailAndPassword(this.auth, email, password);
    };

    reauthenticateAndRetrieveDataWithCredential = (email, password) => {
        const credentials = EmailAuthProvider.credential(
            email,
            password
        );

        return reauthenticateWithCredential(
            this.auth.currentUser,
            credentials
        );
    };

    signInWithCredential = credential => {
        return signInWithCredential(this.auth, credential);
    }

    signInWithRedirect = provider => {
        return signInWithRedirect(this.auth, provider);
    }

    signInWithPopup = provider => {
        return signInWithPopup(this.auth, provider);
    }

    signOut = () => {
        return signOut(this.auth);
    }

    sendPasswordResetEmail = email => {
        return sendPasswordResetEmail(this.auth, email);
    }

    verifyPasswordResetCode = actionCode => {
        return verifyPasswordResetCode(this.auth, actionCode);
    }

    confirmPasswordReset = (actionCode, newPassword) => {
        return confirmPasswordReset(this.auth, actionCode, newPassword);
    }

    passwordUpdate = password => {
        return updatePassword(this.auth.currentUser, password);
    }

    providerData = () => {
        return this.auth.currentUser.providerData;
    }

    getGoogleAuthProvider = () => {
        return this.GoogleAuthProvider;
    }

    AppleAuthProvider = () => {
        return this.AppleProvider;
    };

    updateEmail = (email) => {
        return updateEmail(this.auth.currentUser, email);
    }

    // *** Messaging Api ***
    getPushToken = () => {
        return (
            this.messaging &&
            getToken(this.messaging, {
                vapidKey: process.env.REACT_APP_FB_VAPI_KEY
            })
        );
    };

    // onPushTokenRefresh = cb => {
    //     if (this.messaging) this.messaging.onTokenRefresh(cb);
    // };

    requestPermission = async () => {
        try {
            await Notification.requestPermission();
        } catch (error) {
            return new Promise((resolve, reject) => {
                Notification.requestPermission(() => {
                    return resolve();
                });
            });
        }
    };

    fcmSupported = () => isSupported();

    onMessageListener = () =>
        new Promise((resolve) => {
            onMessage(this.messaging, (payload) => {
                console.log(payload);
            });
        });
    // *** Realtime Database API ***

    getHiddenToken = async (key) => {
        const dbRef = ref(this.database)
        const snapshot = await get(child(dbRef, `/session_tokens/${key}`))
        if (snapshot.exists()) {
            return snapshot.val()
        }

        return null
    };

    deleteHiddenToken = async (key) => {
        return await remove(ref(this.database, `/session_tokens/${key}`));
    };

    exchangeRefreshToken = async currentRefreshToken => {
        return await this.tokenRestApi.post("", {
            refresh_token: currentRefreshToken,
            grant_type: "refresh_token"
        });
    };

    setDevicePushToken = async (uid, token) => {
        if (uid && token) {
            const now = new Date().getTime();
            console.log("setDevicePushToken", uid, token)
            const dbRef = ref(this.database, `/users/${uid}/push_tokens/${token}`)
            return await set(dbRef, now);
        }
        return;
    };

    createProfile = (uid, name, lastname, email, signupType = 0) => {
        const newProfile = {
            name,
            lastname,
            email,

            created_at: Date.now(),

            origin: "web",
            signupType
        };

        if (!name && name !== "") {
            delete newProfile.name;
        }

        if (!lastname && lastname !== "") {
            delete newProfile.lastname;
        }

        if (!email && email !== "") {
            delete newProfile.email;
        }

        const dbRef = ref(this.database, `/users/${uid}`)
        return set(dbRef, newProfile)
    };

    updateProfile = (profile) => {
        try {
            const {
                uid,
                phone,
                license_number,
                license_state,
                location,
                address,
                bio,
                name,
                lastname,
                avatar,
                verified_phone,
                zipcode,
                state,
                locationAddress,
                type
            } = profile;

            let newProfile = Object.assign(
                {},
                {
                    type:
                        typeof type !== "undefined" && type !== null
                            ? type
                            : undefined,
                    name: name ? name : undefined,
                    lastname: lastname ? lastname : undefined,
                    bio: bio ? bio : undefined,
                    phone: phone ? phone : undefined,
                    license_number: license_number ? license_number : undefined,
                    license_state: license_state ? license_state : undefined,
                    location,
                    zipcode,
                    state,
                    locationAddress: locationAddress
                        ? locationAddress
                        : undefined,
                    address: address ? address : undefined,
                    avatar: avatar ? avatar : undefined,
                    verified_phone:
                        typeof verified_phone === "boolean"
                            ? verified_phone
                            : undefined
                }
            );

            let newProfileWithoutLicenses = Object.assign({}, newProfile);
            if (license_state && license_number) {
                newProfile.licenses = {};
                newProfile.licenses[
                    `${this.stringfirebasesify(license_number)}`
                ] = {
                    number: license_number,
                    primary: true,
                    state: license_state ? license_state : ""
                };
            }

            const cleanProfile = this.cleanObject(newProfile);

            try {
                const dbRef = ref(this.database, `/users/${uid}`)
                update(dbRef, cleanProfile)
            } catch (error) {
                console.log("error", error);
                const cleanProfileWithoutLicenses = this.cleanObject(
                    newProfileWithoutLicenses
                );
                const dbRef = ref(this.database, `/users/${uid}`)
                update(dbRef, cleanProfileWithoutLicenses)
            }
        } catch (error) {
            console.log(error);
        }
    };

    updateProfileData = (uid, data) => {
        try {
            if (uid) {
                let newProfile = Object.assign({}, data);
                const { license_state, license_number } = newProfile;
                if (license_state && license_number) {
                    newProfile.licenses = {};
                    newProfile.licenses[
                        `${this.stringfirebasesify(license_number)}`
                    ] = {
                        number: license_number,
                        primary: true,
                        state: license_state ? license_state : ""
                    };
                }
                const cleanData = this.cleanObject(newProfile);
                const dbRef = ref(this.database, `/users/${uid}`)
                update(dbRef, cleanData)
            } else {
                throw new Error({
                    message: "userId and valid fields needed"
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    searchByQrProfile = async (qrProfile) => {
        const dbRef = ref(this.database, '/users/')
        const orderedByQrProfile = query(dbRef, orderByChild('qr_profile'))
        const equal = query(orderedByQrProfile, equalTo(qrProfile))
        const snap = await get(equal)

        if (snap.exists())
            return snap.val()

        return null
    };

    buildYourTeamCardOpen = (uid, open) => {
        const dbRef = ref(this.database, `/users/${uid}/buildYourTeamCardOpen`)
        return set(dbRef, open)
    };

    updateUserEmail = async (userId, password, oldEmail, newEmail) => {
        if (userId && password && oldEmail && newEmail) {
            await this.reauthenticateAndRetrieveDataWithCredential(
                oldEmail,
                password
            );
            await this.updateEmail(newEmail);
            return update(ref(this.database, `/users/${userId}`), {
                email: newEmail
            })
        } else {
            throw new Error({
                message: "userId and email fields needed"
            });
        }
    };

    setVerifiedEmail = (userId, verified) => {
        if (userId && typeof verified === "boolean") {
            return update(ref(this.database, `/users/${userId}`), { verified_email: verified })
        } else {
            throw new Error({
                message: "userId and verified fields needed"
            });
        }
    };

    userPhoneValid = (userId, valid) => {
        if (userId && typeof valid === "boolean") {
            return update(ref(this.database, `/users/${userId}`), { verified_phone: valid })
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileExperience = (userId, experience) => {
        if (userId && typeof experience !== "undefined") {
            return set(ref(this.database, `/users/${userId}/experience`), experience)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileRealStateFocus = (userId, focus) => {
        if (userId && typeof focus !== "undefined") {
            return set(ref(this.database, `/users/${userId}/focus`), focus);
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileServiceAreas = (userId, serviceAreas) => {
        if (userId && typeof serviceAreas !== "undefined") {
            return set(ref(this.database, `/users/${userId}/service_areas`), serviceAreas)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileHonors = async (userId, honors = []) => {
        if (userId && typeof honors !== "undefined") {
            const dbRef = ref(this.database, `/users/${userId}/honors`);
            let newHonors = {};
            Object.keys(honors).forEach(honorKey => {
                const key = dbRef.push().key;
                newHonors[key] = honors[honorKey];
            });

            await set(dbRef, newHonors);
            return newHonors;
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileHonorIndex = (userId, honor) => {
        if (userId && typeof honor !== "undefined") {
            return set(ref(this.database, `/users/${userId}/honors/${honor.id}/index`), honor.index)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileLicenses = (userId, licenses) => {
        if (userId && typeof licenses !== "undefined") {
            const firebaserifyedObject = this.stringfirebasesifyObject(
                licenses
            );
            return set(ref(this.database, `/users/${userId}/licenses`, firebaserifyedObject))
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileLicenseIndex = (userId, license) => {
        if (userId && typeof license !== "undefined") {
            return set(ref(this.database, `/users/${userId}/licenses/${license.id}/index`), license.index)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserPrimaryLicense = (
        userId,
        license_number,
        license_state,
        license_expiration
    ) => {
        if (
            userId &&
            typeof license_number !== "undefined" &&
            typeof license_state !== "undefined" &&
            typeof license_expiration !== "undefined"
        ) {
            return update(ref(this.database, `/users/${userId}`), {
                license_number,
                license_state,
                license_expiration
            })
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileCommunityIndex = (userId, community) => {
        if (userId && typeof community !== "undefined") {
            return set(ref(this.database, `/users/${userId}/community_involvement/${community.id}/index`), community.index)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileSocialNetworks = (userId, social) => {
        if (userId && typeof social !== "undefined") {
            const cleanSocial = this.cleanObject(social);
            return set(ref(this.database, `/users/${userId}/social`), cleanSocial)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileAbout = (userId, about) => {
        if (userId && typeof about !== "undefined") {
            return set(ref(this.database, `/users/${userId}/bio`), about)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileProfessionsUserReferTo = (
        userId,
        professionsUserReferTo
    ) => {
        if (userId && typeof professionsUserReferTo !== "undefined") {
            return set(ref(this.database, `/users/${userId}/professionsUserReferTo`), professionsUserReferTo)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    updateUserProfileCommunity = async (userId, community = {}) => {
        try {
            if (userId && typeof community !== "undefined") {
                const firebaserifyedObject = this.stringfirebasesifyObject(
                    community
                );

                const dbRef = ref(this.database, `/users/${userId}/other_titles`)

                await set(dbRef, firebaserifyedObject);

                return;
            } else {
                throw new Error({
                    message: "userId and valid fields needed"
                });
            }
        } catch (error) {
            console.log("updateUserProfileCommunity", error);
        }
    };

    setDismissInvitationNotification = (userId, uidToDismiss) => {
        if (userId && typeof uidToDismiss !== "undefined") {
            return set(ref(this.database, `/invited_people/${userId}/${uidToDismiss}/notification/dismissed`), true)
        } else {
            throw new Error({
                message: "userId and valid fields needed"
            });
        }
    };

    createAgency = (
        avatar,
        owner,
        key,
        enabled,
        address,
        lat,
        lng,
        zipcode,
        members,
        name
    ) => {
        return set(ref(this.database, `/companies_list`), {
            avatar,
            owner,
            key,
            enabled,
            location: {
                address,
                coordinates: {
                    lat,
                    lng
                },
                zipcode
            },
            members,
            name: name.toLowerCase(),
            displayName: name
        })
    }

    fetchCompanies = () => {
        return get(ref(this.database, `/companies`));
    }

    fetchStatic = () => {
        return get(ref(this.database, `/static/dictionaries`))
    }

    fetchAgenciesList = () => {
        return get(ref(this.database, `/companies_list`))
    }

    fetchTeamsList = () => {
        return get(ref(this.database, `/teams_list`))
    }

    fetchAgency = async key => {
        const snap = await get(ref(this.database, `/companies/${key}`));
        if (snap.exists())
            return snap.val()
        return null
    };

    fetchTeam = async key => {
        const snap = await get(ref(this.database, `/teams/${key}`))
        if (snap.exists())
            return snap.val()
        return null
    };

    fetchAgencyOrTeam = async key => {
        const agency = await this.fetchAgency(key);
        const team = await this.fetchTeam(key);
        return agency ? agency : team ? team : null;
    };

    fetchTeamsList = () => {
        return get(ref(this.database, `/teams_list`));
    }

    fetchProfile = async (userName) => {
        if (typeof userName === "string") {
            const dbRef = ref(this.database, '/users/')
            const orderedByUsername = query(dbRef, orderByChild('userName'))
            const equal = query(orderedByUsername, equalTo(userName))
            const snap = await get(equal)
            if (snap.exists()) {
                const data = snap.val()
                const uid = data && Object.keys(data) && Object.keys(data)[0];
                const result = data && Object.keys(data) && this.parseFirebaseProfile(data[Object.keys(data)[0]]);
                return Promise.resolve(result ? { ...result, uid: uid } : result);
            }
        } else {
            return Promise.resolve(null);
        }
    };

    fetchProfileById = async uid => {
        let profile = null
        if (typeof uid === "string") {
            const dbRef = ref(this.database)
            const snapshot = await get(child(dbRef, `/users/${uid}`))
            if (snapshot.exists()) {
                const value = snapshot.val()
                const parsedValue = this.parseFirebaseProfile(value);
                this.localStorage.setUser(uid, parsedValue);
                profile = parsedValue ? { ...parsedValue, uid: uid } : parsedValue;
            }
        }

        return Promise.resolve(profile);
    };

    userNameAvailable = async (myId, userName = "") => {
        // Users path in the database
        const dbRef = ref(this.database, "/users")
        // Order by userName
        const orderedByUsername = query(dbRef, orderByChild('userName'))
        // Get the user with the same userName
        const equal = query(orderedByUsername, equalTo(userName))
        // Get the snapshot
        const snap = await get(equal)
        // Check if the userName is available
        let userNameAvailable = !snap || !snap.exists() || !snap.val();
        // If the userName is not available, check if the user is the same
        if (!userNameAvailable) {
            const data = snap.val()
            const uid = data && Object.keys(data) && Object.keys(data)[0];
            // If the user is the same, the userName is available
            if (uid === myId) userNameAvailable = true;
        }
        // Return the result
        return userNameAvailable;
    };

    fetchProfileWithCache = async uid => {
        if (typeof uid === "string") {
            let user = this.localStorage.getUser(uid);

            if (user) {
                return UserBusiness.normalizeUser(user, false);
            }

            const snap = await get(ref(this.database, `/users/${uid}`))
            user = snap && typeof snap.val === "function" ? snap.val() : null;
            user = user && UserBusiness.normalizeUser(user, false);
            this.localStorage.setUser(uid, user);
            return user;
        } else {
            return null;
        }
    };

    fetchProfileSubscription = async uid => {
        if (typeof uid === "string") {
            const snap = await get(ref(this.database, `/subscriptions/${uid}`))
            const data = snap && typeof snap.val === "function" ? snap.val() : null;
            return Promise.resolve(data);
        } else {
            return Promise.resolve(null);
        }
    };

    fetchProfileCredits = async uid => {
        if (typeof uid === "string") {
            const snap = await get(ref(this.database, `/credits/${uid}`))
            const data = snap && typeof snap.val === "function" ? snap.val() : null;
            return Promise.resolve(data);
        } else {
            return Promise.resolve(null);
        }
    };

    parseFirebaseProfile = (data = {}) => {
        let newData = {
            ...data
        };
        if (newData.licenses)
            newData.licenses = this.parseFirebaseObjectKeys(newData.licenses);
        if (newData.other_titles)
            newData.other_titles = this.parseFirebaseObjectKeys(
                newData.other_titles
            );
        if (newData.honors) {
            newData.honors = this.normalizeOldSchoolHonors(newData.honors);
        }
        return newData;
    };

    normalizeOldSchoolHonors = honors => {
        const newHonors = {};
        Object.keys(honors).forEach((key, index) => {
            if (!honors[key].title) {
                newHonors[index] = {
                    title: key,
                    description: "",
                    year: ""
                };
            } else newHonors[key] = honors[key];
        });
        return newHonors;
    };

    fetchUserList = async uidsArray => {
        const promises = await Promise.all(
            uidsArray.map(async uid => {
                if (uid) {
                    const snap = await get(ref(this.database, `/users/${uid}`))
                    let data = snap && typeof snap.val === "function" ? snap.val() : null;
                    data = data ? { ...data, uid: uid } : data;
                    return Promise.resolve(data);
                } else {
                    return Promise.resolve(null);
                }
            })
        );

        return promises.map((data, i) => {
            if (data) {
                return {
                    ...data,
                    uid: uidsArray[i]
                };
            } else {
                return null;
            }
        }).filter(Boolean);
    };

    fetchRealStateFocus = async () => {
        const dbRef = await get(ref(this.database, `/static/focusArea`))
        return dbRef.val();
    };

    sendReferral = (key, payload) => {
        if (this.isValidKey(key) && payload)
            return set(ref(this.database, `/referrals/${key}`), payload);
        else throw new Error("Invalid Referral key or payload");
    };

    sendOpenReferral = (key, payload) => {
        return set(ref(this.database, `/pending_open_referrals/${key}`), payload);
    }

    removeOpenReferral = key => {
        return remove(ref(this.database, `/open_referrals/${key}`));
    }

    fetchReferral = async referralId => {
        let referral;
        const referralSnap = await get(ref(this.database, `/referrals/${referralId}`))
        referral = referralSnap.val();
        if (!referral || !referral.referral) {
            const openReferralSnap = await get(ref(this.database, `/open_referrals/${referralId}`))
            referral = openReferralSnap.val();
        }
        if (!referral || !referral.referral) {
            const openPendingReferralSnap = await get(ref(this.database, `/pending_open_referrals/${referralId}`))
            referral = openPendingReferralSnap.val();
        }
        return referral;
    };

    fetchOpenReferral = async referralId => {
        let referral;
        const referralSnap = await get(ref(this.database, `/open_referrals/${referralId}`))
        referral = referralSnap.val();
        return referral;
    };

    claimOpenReferral = (rId, uId) => {
        try {
            let promises = [];
            promises.push(
                set(ref(this.database, `/open_referrals/${rId}/postulants/${uId}`), true)
            );
            promises.push(
                set(ref(this.database, `/open_referrals/${rId}/badge`), true)
            );
            promises.push(
                set(ref(this.database, `/open_referrals/${rId}/postulants_news/${uId}`), true)
            );

            Promise.all(promises).then(values => {
                Promise.resolve();
            });
        } catch (error) {
            console.log(error);
            Promise.reject(error);
        }
    };

    unfriendUser = async (meUid, uid) => {
        if (uid && meUid) {
            try {
                await remove(ref(this.database, `users/${meUid}/contacts/${uid}`))
                await remove(ref(this.database, `users/${uid}/contacts/${meUid}`))
                return Promise.resolve();
            } catch (error) {
                Promise.reject(error);
            }
        }
    };

    fetchUserInvites = async uid => {
        const snap = await get(ref(this.database, `/invited_people/${uid}`))

        return snap && snap.val();
    };

    fetchReceivedReferrals = async uid => {
        const snap = await get(ref(this.database, `/incoming_referrals/${uid}`))
        return Firebase.normalizeArrayFromFirebaseSnapshot(
            snap ? snap.val() : []
        );
    };

    fetchSentReferrals = async uid => {
        const dbRef = ref(this.database, `/referrals`)
        const dbRefPendOpen = ref(this.database, `/pending_open_referrals`)
        const dbRefOpen = ref(this.database, `/open_referrals`)

        // const orderedByUsername = query(dbRef, orderByChild('userName'))
        // const equal = query(orderedByUsername, equalTo(userName))
        // const snap = get(equal)


        //SENT
        const sentOrdered = query(dbRef, orderByChild('from'))
        const equalTouidSent = query(sentOrdered, equalTo(uid))
        const snapSent = get(equalTouidSent)

        const sentPendOpenOrdered = query(dbRefPendOpen, orderByChild('from'))
        const equalSentPendOpenOrdered = query(sentPendOpenOrdered, equalTo(uid))
        const snapPendOpenSent = get(equalSentPendOpenOrdered)

        const sentOpenOrdered = query(dbRefOpen, orderByChild('from'))
        const equalSentOpenOrdered = query(sentOpenOrdered, equalTo(uid))
        const snapOpenSent = get(equalSentOpenOrdered)

        const sentPromises = await Promise.all([
            snapSent,
            snapPendOpenSent,
            snapOpenSent
        ]);

        const normalizedSentNormalReferrals = Firebase.normalizeArrayFromFirebaseSnapshot(
            sentPromises[0].val()
        );
        const normalizedSentOpenReferrals = Firebase.normalizeArrayFromFirebaseSnapshot(
            sentPromises[1].val()
        );

        const normalizedSentPendingOpenReferrals = Firebase.normalizeArrayFromFirebaseSnapshot(
            sentPromises[2].val()
        );

        const sentData = [].concat(
            normalizedSentNormalReferrals,
            normalizedSentOpenReferrals,
            normalizedSentPendingOpenReferrals
        );

        return sentData ? sentData : [];
    };

    fetchUserReferralExtraInfo = async (uid, ReferralBusiness) => {
        let sentReferralsCount = 0;
        let receivedReferralsCount = 0;
        try {
            if (typeof uid === "string") {
                child(ref(this.database), `/referrals/${uid}/sent`)
                const sentReferrals = child(ref(this.database), `/referrals/${uid}/sent`)
                let receivedReferrals = child(ref(this.database), `/referrals/${uid}/received`)
                const sent = await get(sentReferrals);
                const received = await get(receivedReferrals)
                sentReferralsCount = sent && sent.size;
                receivedReferrals = received.exists() && received.val();
                const receivedReferralsFiltered =
                    receivedReferrals &&
                    Object.keys(receivedReferrals).filter(rk => {
                        const status = ReferralBusiness.getStatus(
                            receivedReferrals[rk],
                            uid
                        );
                        return status === "ASSIGNED";
                    });
                receivedReferralsCount = receivedReferralsFiltered
                    ? receivedReferralsFiltered.length
                    : 0;
                return { sentReferralsCount, receivedReferralsCount };
            } else return;
        } catch (error) {
            console.log("error", error);
            return { sentReferralsCount, receivedReferralsCount };
        }
    };

    discussionsFeatured = async () => {
        const dbRef = ref(this.database, `/discussions_featured/id`)
        const snap = await get(dbRef);
        return snap.val();
    };

    discussionRank = async (postId, uId) => {
        const dbRef = ref(this.database, `discussions_rank/${postId}`)
        const snap = await get(dbRef);
        return snap.val();
    };

    setStripeCustomerId = (uid, customerId) => {
        return set(ref(this.database, `users/${uid}/customer_id`), customerId);
    };

    setStripePayoutMethodStatus = (uid, status) => {
        return set(ref(this.database, `users/${uid}/payout_method/status`), status);
    };

    addUserTypes = userTypes => {
        try {
            const dbRef = ref(this.database, `/static/user_types`)
            userTypes.forEach(async ut => {
                const pushedReference = await push(dbRef)
                await set(ref(this.database, `/static/user_types/${pushedReference.key}`), ut);
            });
            return "ok";
        } catch (error) {
            console.log("error", error);
            return error;
        }
    };

    getMaitenanceMode = async () => {
        try {
            const resp = await get(ref(this.database, `/maitenance`))
            return resp.exists() && resp.val();
        } catch (error) {
            return false;
        }
    };

    getMaitenanceModeAlert = async () => {
        try {
            const resp = await get(ref(this.database, `/version/status/maintenanceAlert`))
            return resp.exists() && resp.val();
        } catch (error) {
            return false;
        }
    };

    setWatchingProfile = async (myId, id, add = true) => {
        try {
            return add
                ? await set(ref(this.database, `/online_profile_watching/${id}/${myId}`), true)
                : await remove(ref(this.database, `/online_profile_watching/${id}/${myId}`))
        } catch (error) {
            return false;
        }
    };

    fetchNotifications = async (myId, lastVisibleId) => {
        try {
            const dbRef = ref(this.database, `/notifications/${myId}`);
            const last = query(dbRef, orderByKey(), startAt(lastVisibleId), limitToFirst(15))
            const all = query(dbRef, orderByKey(), limitToFirst(15))
            const documents = lastVisibleId
                ? await get(last)
                : await get(all)
            return documents;
        } catch (error) {
            return false;
        }
    };

    fetchConnectionRequests = myId => {
        try {
            return get(query(ref(this.database, `/notifications/${myId}`), orderByChild('type'), equalTo('NEW_CONNECTION')))
        } catch (error) {
            return false;
        }
    };

    removeNotification = (myId, notificationId) => {
        try {
            return remove(ref(this.database, `/notifications/${myId}/${notificationId}`))
        } catch (error) {
            return false;
        }
    };

    setNewBellNotification = (myId, value) => {
        return set(ref(this.database, `/newBellNotification/${myId}`), value);
    };

    setNotificationRead = (myId, notificationId, value) => {
        return set(ref(this.database, `/notifications/${myId}/${notificationId}/read`), value);
    };

    markAllNotificationsAsRead = async (myId, read) => {
        const snap = await get(query(ref(this.database, `/notifications/${myId}`), orderByChild('read'), equalTo(!read)))
        if (snap && snap.exists())
            snap.forEach(noti => {
                set(child(noti.ref, 'read'), read)
            });
    };

    generateRandomLocationIdForChat = () => {
        return push(ref(this.database, `/conversations`)).key;
    };

    setNewNewsNotification = (myId, value) => {
        return set(ref(this.database, `/newNewsNotification/${myId}`), value);
    };

    createConversationForUsers = (fromId, toId, conv) => {
        return Promise.all([
            set(child(child(ref(this.database, `/user_conversations`), fromId), toId), conv),
            set(child(child(ref(this.database, `/user_conversations`), toId), fromId), conv),
        ]);
    };

    chatSendMessage = (locationId, message) => {
        const dbRef = ref(this.database, `/conversations/${locationId}`);
        return set(push(dbRef), message);
    };

    chatSetLastMessage = (userId, toId, message) => {
        return set(ref(this.database, `/user_conversations/${userId}/${toId}/lastMessage`), message)
    };

    chatSetLastMessageRead = (userId, toId, isRead) => {
        return set(ref(this.database, `/user_conversations/${userId}/${toId}/lastMessage/isRead`), isRead)
    };

    getChatMessages = async (conversationId) => {
        const conversationMessages = await get(ref(this.database, `/conversations/${conversationId}`));
        let messages = conversationMessages.val() || [];
        messages = Object.keys(messages).map(key => {
            const item = messages[key];
            const value = this.transformChatTimestampsFromSecondsToMilliseconds(item);
            return value;
        });
        return messages;
    }

    getUserConversations = async (userId) => {
        const userConversations = await get(ref(this.database, `/user_conversations/${userId}`));
        let conversations = userConversations.val() || [];
        conversations = Object.keys(conversations).map(key => {
            const item = conversations[key];
            item.key = key;
            const value = this.transformChatTimestampsFromSecondsToMilliseconds(item);
            return value;
        });
        return conversations;
    }

    // *** Subscriptions ***

    subscribeToNotifications = (myId, cb) => {
        try {
            if (myId && cb) {
                const dbRef = ref(this.database, `/notifications/${myId}`);
                onValue(dbRef, (snapshot) => {
                    cb(snapshot.val())
                })
            }
        } catch (error) {
            console.log("fetchNotifications", error);
        }
    };

    subscribeToUserInvitesChanged = (uid, cb) => {
        try {
            if (uid && cb) {
                const dbRef = ref(this.database, `invited_people/${uid}`);
                onValue(dbRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    subscribeToWhoIsWatchingMyProfile = (myId, cb) => {
        try {
            if (myId && cb) {
                const whoIs = ref(this.database, `online_profile_watching/${myId}`);
                onValue(whoIs, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    unsubscribeToWhoIsWatchingMyProfile = (myId, cb) => {
        try {
            if (myId && cb) {
                const whoIs = ref(this.database, `online_profile_watching/${myId}`);
                return off(whoIs);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    isLoggedIn = cb => {
        return onAuthStateChanged(this.auth, cb);
    }

    subscribeToNewConnectionUser = (uid, cb) => {
        try {
            if (uid && cb) {
                const invitesRef = ref(this.database, `users/${uid}/pending_invites`);

                onValue(invitesRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) { }
    };

    subscribeBellOpened = (uid, cb) => {
        try {
            if (uid && cb) {
                const bellRef = ref(this.database, `newBellNotification/${uid}`);
                onValue(bellRef, (snapshot) => {
                    cb(snapshot.val());
                },
                );
            }
        } catch (error) {
            console.log("subscribeBellOpened error", error);
        }
    };

    /**
     * This function brings the latest notifications for the user. However, we dont need it anymore as we are subscribed to the notifications
     * We now listen to all changes from subscribeToNotifications function
     * @deprecated
     * @param {*} uid
     * @param {*} cb
     * @memberof Firebase
     */
    subscribeToBellNotificationsAdded = (uid, cb) => {
        try {
            if (uid && cb) {
                const invitesRef = query(ref(this.database, `notifications/${uid}`), orderByChild('timestamp'), startAt(Date.now()), limitToLast(1));

                onChildAdded(invitesRef, (snapshot) => {
                    cb(snapshot.val(), snapshot.key);
                });
            }
        } catch (error) {
            console.log("subscribeToBellNotficationsAdded error", error);
        }
    };

    subscribeToUserContactsChanged = (uid, cb) => {
        try {
            if (uid && cb) {
                const userRef = ref(this.database, `users/${uid}/contacts`);

                onValue(userRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    subscribeToUserChanged = (uid, cb) => {
        try {
            if (uid && cb) {
                const invitesRef = ref(this.database, `users/${uid}`);

                onValue(invitesRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) { }
    };

    subscribeToCreditsChanged = (uid, cb) => {
        try {
            if (uid && cb) {
                const invitesRef = ref(this.database, `credits/${uid}`);

                onValue(invitesRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) { }
    };
    subscribeToUserContactsSentInvitesChanged = (uid, cb) => {
        try {
            if (uid && cb) {
                const userRef = ref(this.database, `users/${uid}/sent_invites`);

                onValue(userRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    subscribeToMyReferrals = (uid, cb) => {
        const dbRef = ref(this.database, `/referrals/${uid}`);
        onValue(dbRef, (childSnapshot) => {
            cb(childSnapshot.val(), childSnapshot.key);
        });
    };

    transformChatTimestampsFromSecondsToMilliseconds = item => {
        if (item.timestamp) {
            item.timestamp = item.timestamp * 1000;
        }
        if (item.lastMessage && item.lastMessage.timestamp) {
            item.lastMessage.timestamp = item.lastMessage.timestamp * 1000;
        }

        return item;
    };

    subscribeToMyConversationsAdded = (uid, cb) => {
        // Conversation path in the database
        const dbRef = ref(this.database, `/user_conversations/${uid}`);
        // 'startAt' this key, equivalent to 'start from the present second'
        const latestConversations = query(dbRef, orderByChild("timestamp"))//, startAt(Date.now()));
        onChildAdded(latestConversations, childSnapshot => {
            const item = childSnapshot.val();
            const value = this.transformChatTimestampsFromSecondsToMilliseconds(
                item
            );
            cb(value, childSnapshot.key);
        });
    };

    subscribeToMyConversationsChanged = (uid, cb) => {
        const dbRef = ref(this.database, `/user_conversations/${uid}`);
        onChildChanged(dbRef, (childSnapshot) => {
            const item = childSnapshot.val();
            const value = this.transformChatTimestampsFromSecondsToMilliseconds(
                item
            );
            cb(value, childSnapshot.key);
        });
    };

    subscribeToConversationMessagesAdded = (conversationId, cb) => {
        // Conversation path in the database
        const dbRef = ref(this.database, `/conversations/${conversationId}`);
        // Get a firebase generated key, based on current time
        const startKey = push(dbRef).key;
        // 'startAt' this key, equivalent to 'start from the present second'
        const latestMessages = query(dbRef, orderByKey(), startAt(startKey));
        // Subscribe to the conversation
        onChildAdded(latestMessages, childSnapshot => {
            const item = childSnapshot.val();
            const value = this.transformChatTimestampsFromSecondsToMilliseconds(
                item
            );
            cb(value, childSnapshot.key);
        });
    };

    unsubscribeToConversationMessagesAdded = conversationId => {
        const dbRef = ref(this.database, `/conversations/${conversationId}`);
        return off(dbRef);
    };

    subscribeToChatUserStatus = (userId, cb) => {
        const dbRef = ref(this.database, `/user_status/${userId}`);
        onValue(dbRef, (childSnapshot) => {
            cb(childSnapshot.val(), childSnapshot.key);
        });
    };

    unsubscribeToChatUserStatus = userId => {
        const dbRef = ref(this.database, `/user_status/${userId}`);
        return off(dbRef);
    };

    subscribeToJobCommentAdded = async (jobCode, cb) => {
        try {
            let unsubscribe = () => {};
            if (jobCode && cb) {
                const jobCodeRef = await getDoc(doc(this.firestore, "jobsCode", jobCode));
                const jobId = jobCodeRef.data().id;
                const jobTimelineRef = doc(this.firestore, "jobsTimeline", jobId);

                unsubscribe = onSnapshot(jobTimelineRef, (snapshot) => {
                    const value = snapshot.data();
                    console.log("subscribeToJobCommentAdded", value.timeline, snapshot.id);
                    cb(value.timeline, snapshot.id);
                });
            }
            return unsubscribe;
        } catch(error) {
            console.log("subscribeToJobCommentAdded error", error);
        }
    };

    unsubscribeToJobCommentAdded = jobId => {
        try {
            if (jobId) {
                const jobTimelineRef = doc(this.firestore, "jobsTimeline", jobId);
                return off(jobTimelineRef);
            }
        } catch (error) {
            console.log("unsubscribeToJobCommentAdded error", error);
        }
    };

    subscribeToReferralCommentAdded = async (referralCode, cb) => {
        try {
            let unsubscribe = () => {};
            if (referralCode && cb) {
                let refId = await get(ref(this.database, `/referralsCode/${referralCode}`))
                refId = refId.exists() && refId.val();
                const [, referralId] = refId.split("@");

                const referralTimlineRef = ref(this.database,`/referralTimeline/${referralId}`);

                unsubscribe = onChildAdded(referralTimlineRef, function (snapshot, prevChildKey) {
                    const value = snapshot.val();
                    if (value.type === "MESSAGE")
                        cb(snapshot.val(), snapshot.key);
                });
                return unsubscribe;
            }
        } catch (error) {
            console.log("subscribeToReferralCommentAdded error", error);
        }
    };

    unsubscribeToReferralCommentAdded = referralCode => {
        try {
            if (referralCode) {
                const dbRef = ref(this.database, `/referralTimeline/${referralCode}`);
                return off(dbRef);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    subscribeToNewsAdded = cb => {
        try {
            if (cb) {
                const dbRef = ref(this.database, `/users_discussions`);
                onChildAdded(query(dbRef, endAt(), limitToLast(1)), (snapshot) => {
                    cb("child_added", snapshot.val(), snapshot.key);
                });
                // For child changed will trigger for every post added by an user that has posted before
                onChildChanged(dbRef, (snapshot) => {
                    cb("child_changed", snapshot.val(), snapshot.key);
                });
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    subscribeNewNews = (uid, cb) => {
        try {
            if (uid && cb) {
                const newNewsRef = ref(this.database, `newNewsNotification/${uid}`);
                onValue(newNewsRef, (snapshot) => {
                    cb(snapshot.val());
                });
            }
        } catch (error) {
            console.log("subscribeNewNews error", error);
        }
    };

    subscribeToReceivedOpenReferrals = async (center, cb, radius = 100) => {
        if (center && center[0] && center[1]) {
            this.geoQueries.receivedOpenReferrals = this.geoFire.query({
                center,
                radius
            });
            this.geoQueries.receivedOpenReferrals.on("key_entered", key =>
                cb(key)
            );
            return Promise.resolve();
        } else {
            return Promise.reject(
                "center should be structured as a [lat, lng] array."
            );
        }
    };

    subscribeToReceivedOpenReferralsExited = async (
        center,
        cb,
        radius = 100
    ) => {
        if (center && center[0] && center[1]) {
            this.geoQueries.receivedOpenReferrals = this.geoFire.query({
                center,
                radius
            });
            this.geoQueries.receivedOpenReferrals.on("key_exited", key =>
                cb(key)
            );
            return Promise.resolve();
        } else {
            return Promise.reject(
                "center should be structured as a [lat, lng] array."
            );
        }
    };

    unsubscribeToReceivedOpenReferrals = () => {
        return this.geoQueries.receivedOpenReferrals.cancel();
    }

    removeSubscriptions = uid => {
        try {
            if (uid) {
                const invitesRef = ref(this.database, `users/${uid}/pending_invites`);
                const userRef = ref(this.database, `users/${uid}/contacts`);
                const profileRef = ref(this.database, `users/${uid}`);
                const profileCreditsRef = ref(this.database, `credits/${uid}`);
                const sentInvitesRef = ref(this.database, `users/${uid}/sent_invites`);
                const referralsRef = ref(this.database, `/referrals`);
                const openReferralsRef = ref(this.database, `/open_referrals`);
                const pendingOpenReferralsRef = ref(this.database, `/pending_open_referrals`);
                const myRefs = ref(this.database, `/referrals/${uid}`);
                const bellRef = ref(this.database, `notifications/${uid}`);
                const newsRef = ref(this.database, `/users_discussions`);
                off(bellRef);
                off(myRefs);
                off(invitesRef);
                off(userRef);
                off(profileRef);
                off(sentInvitesRef);
                off(referralsRef);
                off(profileCreditsRef);
                off(openReferralsRef);
                off(pendingOpenReferralsRef);
                off(newsRef);
            }
        } catch (error) {
            console.log(error);
        }
    };
    getRedirectResult = () => {
        return getRedirectResult(this.auth);
    }

    getUniqueIdStorage = () => {
        const date = new Date();
        const components = [
            date.getYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        ];

        const uniqueId = components.join("");
        return uniqueId;
    };

    changeProfileAvatar = async (email, avatarFile) => {
        const uniqueId = this.getUniqueIdStorage();
        const storageRef = refStorage(this.storage, `/avatar/${email}/${uniqueId}.jpg`);
        const snapshot = await uploadBytes(storageRef, avatarFile);
        const urlProm = await getDownloadURL(snapshot.ref);
        return Promise.resolve(urlProm);
    };

    addImageToPost = async (uid, imageFile) => {
        const uniqueId = this.getUniqueIdStorage();
        const storageRef = this.storage.ref(`/community/${uid}/${uniqueId}.jpg`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        const urlProm = await getDownloadURL(snapshot.ref);
        return Promise.resolve(urlProm);
    };

    saveReferralDraft = async (draftBody, uid, draftId) => {
        try {
            let draftKey = draftId; // it this undefined, it will create a new draft
            // update if draftBody has draftId
            if (!draftKey) {
                draftKey = push(child(ref(this.database), "drafts")).key;
                draftBody.draftId = draftKey;
                // If draftBody does not have timestamps, it will create them
                draftBody.timestamps = {
                    created_at: serverTimestamp()
                };
            }
            // deep clear draftBody from undefined values and empty strings
            for (const key in draftBody) {
                for (const key2 in draftBody[key]) {
                    if (
                        draftBody[key][key2] === undefined ||
                        draftBody[key][key2] === ""
                    ) {
                        delete draftBody[key][key2];
                    }
                }
            }


            update(ref(this.database, `/drafts/${uid}/${draftKey}`), {
                ...draftBody,
                draftId: draftKey,
            });
            // In any case, update updated_at timestamp
            set(
                ref(this.database, `/drafts/${uid}/${draftKey}/timestamps/updated_at`),
                serverTimestamp()
            );
            return draftKey;
        } catch (error) {
            console.log("error", error);
        }
    };

    getDraftReferrals = async (uid) => {
        const drafts = [];
        const snapshot = await get(query(ref(this.database, `/drafts/${uid}`), orderByChild('timestamps/created_at')))
        snapshot.forEach((childSnapshot) => {
            drafts.push(childSnapshot.val());
        });
        return drafts.reverse();
    };

    deleteDraftReferral = async (uid, draftId) => {
        return await remove(ref(this.database, `/drafts/${uid}/${draftId}`));
    }

    fetchUserLeaderboardStats = async (uid) => {
        const myLeaderboardStats = doc(this.firestore, "leaderboard", "this_year", "users", uid);
        const docSnap = await getDoc(myLeaderboardStats);
        return docSnap.data();
    };
}

export default Firebase;
