import React, { useEffect } from "react";
import { Flex } from "rebass";
import { withTheme } from "styled-components";
import confetti from "canvas-confetti";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import { Icon } from "../../Icon";
import SquareButton from "../../Buttons/SquareButton2";
import {
    EngagementModalTitle as Title,
    EngagementModalDescription as Description
} from "../../Text";

const EndorsementSuccessMessage = ({ t, theme, buttonLabel, fullName, firstName, userName, onClick, isEdit, currentPlan }) => {
   
   const iReceiveEndorsement = ''
   const iGaveAnEndorsement = ''
   const renderCurrentPoints = currentPlan === 'ESSENTIAL+' ? 55 : currentPlan === 'PRO' ?  60 : 50
   const proPoints = ''
   
    const monetizationMessage = `Your earned <strong>${renderCurrentPoints}</strong>, but you cloud have earned <strong>${proPoints}</strong> if you were a Pro Member. Upgrade now and climb the leaderboard faster!  
    `;
   
   
    const { push } = useHistory();
    useEffect(() => {
        const modalRoot = document.getElementById(
            `endorsement-success`
        );
        const animationEnd = Date.now() + 9000;
        const canvas = document.createElement("canvas");
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.width = "100%";
        canvas.style.height = "100%";

        modalRoot.insertBefore(canvas, modalRoot.firstChild);
        var confettiCelebration = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        confettiCelebration({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) return clearInterval(interval);

            confettiCelebration({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }, 3000);

        return () => {
            clearInterval(interval);
            modalRoot.removeChild(canvas);
        };
    }, []);

    const goToProfile = () => push(`/profile/${userName}`);

    return (
        <Flex
            id="endorsement-success"
            flexDirection="column"
            alignItems="center"
            style={{ position: "relative" }}
            p={[
                theme.spacing[5],
                theme.spacing[5],
                theme.spacing[10],
                theme.spacing[10]
            ]}
        >
            <Icon name="endorsement" color={theme.blueLight} size="3xl" />
            <Title pointer>
                {t(isEdit ? "success_update_title" : "success_title")}
                {fullName && <span className="highlight" onClick={goToProfile}>{fullName}</span>}
            </Title>
            <Description pointer>
                {t("success_description_1")}
                {firstName && <span className="highlight" onClick={goToProfile}>{firstName}</span>}
                <span
                    dangerouslySetInnerHTML={{
                        __html: monetizationMessage
                    }}
                />
            </Description>
            <Flex width={[1]} mt={[theme.spacing[8]]} justifyContent={"center"}>
                <SquareButton
                    onClick={onClick}
                    bg={theme.shamRockGreen}
                    fontSize="XS"
                    style={{ zIndex: 1 }}
                    text={buttonLabel || t("success_button")}
                />
            </Flex>
        </Flex>
    );
}

export default withTranslation("endorsementModal")(
    withTheme(EndorsementSuccessMessage)
);