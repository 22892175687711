import {
    RELATION_STATUS,
    USER_TO_CONNECT_SOURCE,
    SUBSCRIPTION_PLANS
} from "../assets/strings";

export default class User {
    static getUserRelationStatus(user, uid) {
        if (this.isMyContact(user, uid)) return RELATION_STATUS.connected;
        else if (this.isSentPending(user, uid))
            return RELATION_STATUS.sentPending;
        else if (this.isReceivedPending(user, uid))
            return RELATION_STATUS.receivedPending;
        else if(this.blockedByMe(user, uid)) return RELATION_STATUS.blockedByMe
        else return RELATION_STATUS.notConnected;
    }

    static isMyContact(user = {}, uid) {
        let isMycontact = false;
        if (user.contacts && uid)
            isMycontact = Object.keys(user.contacts).includes(uid);
        return isMycontact;
    }

    static isSentPending(user = {}, uid) {
        let response = false;
        if (user.sent_invites && uid)
            response = Object.keys(user.sent_invites).includes(uid);
        return response;
    }

    static isReceivedPending(user = {}, uid) {
        let response = false;
        if (user.pending_invites && uid)
            response = Object.keys(user.pending_invites).includes(uid);
        return response;
    }

    static blockedByMe(me = {}, uid) {
        return me.blocked_users ? Object.keys(me.blocked_users).indexOf(uid) !== -1 : false
    }

    static blockedByHim(him = {}, myId) {
        return him.blocked_users ? Object.keys(him.blocked_users).indexOf(myId) !== -1 : false
    }

    static getUserToConnectSource(user = {}) {
        if (user.source && USER_TO_CONNECT_SOURCE[user.source])
            return USER_TO_CONNECT_SOURCE[user.source];
        return USER_TO_CONNECT_SOURCE[2];
    }

    static isAmbassador(user) {
        return user.ambassador && user.ambassador.status === true;
    }

    static minProfileStep1Complete = (profile = {}) => {
        return (profile.phone &&
            profile.verified_phone
            // typeof profile.type !== "undefined" &&
            // profile.type !== null &&
            // profile.name &&
            // profile.lastname &&
            // profile.company_info
        )
    };

    static minProfileStep2Complete = (profile = {}) => {
        return (
            profile.name &&
            profile.lastname
            // profile.location && profile.location[0] && profile.location[1]
        )
    };

    static minProfileStep3Complete = (profile = {}) => {
        return (
            typeof profile.type !== "undefined" &&
            profile.type !== null &&
            profile.company_info &&
            profile.location &&
            profile.location[0] &&
            profile.location[1]
        );
    };

    static creditPriceFromPlan(plan) {
        return plan &&
            (plan === SUBSCRIPTION_PLANS.ESSENTIAL_PLUS ||
                plan === SUBSCRIPTION_PLANS.PRO)
            ? 9.95
            : plan === SUBSCRIPTION_PLANS.FREE
                ? 19.95
                : null;
    }

    static userTypeValidForSendReferrals(userType) {
        // return [0, 1, 10, 11, 12, 13, 14, 15, 100].includes(Number(userType));
        return true
    }

    static userTypeValidForReceiveReferrals(userType) {
        //  return [0, 1, 10, 11, 12, 13, 14, 15, 100].includes(Number(userType));
        return true
    }

    static userTypeValidForWriteArticles(userType) {
        //return [0, 1, 10, 11, 12, 13, 14, 15, 100].includes(Number(userType));
        return true
    }

    static enableFullProfile(userType) {
        return [0, 1, 10, 11, 12, 13, 14, 15, 100].includes(Number(userType));
    }
    static enableEditLicenses(userType) {
        return [0, 1, 10, 11, 12, 13, 14, 15, 100, 101].includes(Number(userType));
    }

    static isPro(profile = {}) {
        return profile && profile.subscription && profile.subscription.plan === SUBSCRIPTION_PLANS.PRO;
    }

    static isEssentialPlus(profile = {}) {
        return profile && profile.subscription && profile.subscription.plan === SUBSCRIPTION_PLANS.ESSENTIAL_PLUS;
    }

    static professionsUserReferToByPlan(profile = {}) {
        if (this.isPro(profile)) return 15;
        else if (this.isEssentialPlus(profile)) return 10;
        else return 5;
    }

    static filterUniquesUsersArray(users) {
        const result = [];
        const map = new Map();
        for (const item of users) {
            if (!map.has(item.uid)) {
                map.set(item.uid, true); // set any value to Map
                result.push(item);
            }
        }
        return result;
    }

    static normalizeUserName (userName = '') {
        return userName.trim().toLocaleLowerCase()
    }

    static normalizeUser (user, blocked) {
        const {
            app_permissions,
            app_version,
            aux,
            config,
            customer_id,
            editions,
            fav_contacts,
            open_count,
            push_tokens,
            settings,
            payout_acct,
            payout_method,
            ...cleanUser
        } = user
    
        if (blocked) {
            const {
                bio,
                avatar,
                headLine,
                contacts,
                email,
                focus,
                honors,
                languages,
                license_number,
                license_state,
                license_expiration,
                licenses,
                location,
                other_licenses,
                other_titles,
                phone,
                score,
                sent_invites,
                service_areas,
                social,
                verified_email,
                verified_phone,
                ...blockedUser
            } = cleanUser
            return blockedUser
        }
        return cleanUser
    }
}
