import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StepWrapper, Button, ButtonContainer } from "../../../components";
import * as yup from 'yup'
import { Formik } from 'formik';
import PastJobType from "./_Type";
import Location from "./_Location";
import TimingInfo from "./_TimingInfo";
import RealEstateDetails from './_RealEstateDetails'
import Description from "./_JobDescription";
import VisibilityAfterExchange from "./_VisibilityAfterExchange";
import { PartyConfirmation } from "./_PartyConfirmation";
import { DraftModal, useDraft } from "../../../useDraft";
import { Box } from "rebass";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const formInitialValues = {
    // Required if profession is Real Estate Agent
    amount: null,
    fee: null,
    clientType: null,
    propertyType: null,
    propertySubType: null,
    pastReferralStatus: '',
    partyConfirmation: true,
    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: '',
  
    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: '',

    minBeds: '',

    // Required if clientType is 'BUYER'
    financingInfo: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: null,

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: null,
    pets: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL' and propertySubtype is 'MULTI_FAMILY'
    minUnits: '',

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: null,
    maxBeds: '',
    minSquareFeet: '',
    maxSquareFeet: '',
    minAcres: '',
    maxAcres: '',
    maxUnits: '',
    units: '',
    acres: '',
    beds: '',
    baths: '',
    squareFeet: '',
    visibilityAfterExchange: { value: 'EVERYONE', label: 'Yes, display the accepted job to everyone!' },
};

const formSchema = yup.object().shape({
    pastJobType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),
    description: yup.string().min(31).max(2000).required(), 
    address: yup.object().nullable()
    .when("locationIsRemote", {
        is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
        then: yup.object().nullable()
    })
    .when("locationIsRemote", {
        is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
        then: yup.object({
            description: yup.string().required(),
            lat: yup.number().required(),
            lng: yup.number().required(),
        }).required()
    }),
    locationIsRemote: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    creationOption: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),
    pastReferralStatus: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),
    jobCreationDate: yup.date().required(),
    // Required if profession is Real Estate Agent
    clientType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    })
    .nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),


    // Required if profession is Real Estate Agent
    propertyType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if profession is Real Estate Agent
    propertySubType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: yup.string()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') || (clientType && clientType.value === 'TENANT') ),
        then: yup.string().required()
    }),
    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: yup.string()
    .nullable()
    .when(["clientType"], {
        is: (clientType) => ((clientType && clientType.value === 'SELLER') || (clientType && clientType.value === 'LANDLORD') ),
        then: yup.string().required()
    }),

    minBeds: yup.number().nullable(),

    // Required if clientType is 'BUYER'
    financingInfo: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType"], {
        is: (clientType) => ((clientType && clientType.value === 'BUYER')),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType"], {
        is: (clientType, propertyType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    minSquareFeet: yup.number().nullable(),
    maxSquareFeet: yup.number(),
    maxUnits: yup.number(),

    minUnits: yup.object()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    pets: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => (
            ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'LAND')) ||
            ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'LAND')) ||
            ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
        ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    units: yup.object()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => (
            (clientType && clientType.value === 'SELLER') &&
            (propertyType && propertyType.value === 'RESIDENTIAL') &&
            (propertySubType && propertySubType.value === 'MULTI_FAMILY')
        ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),
    visibilityAfterExchange: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),  
    }).required(),
    
});


const JobPastCompleteInformation = ({
    setStepData,
    stepData,
    professions,
    theme,
    t,
    candidates,
}) => {
    const [professionOptions, setProfessionOptions] = useState([]);
    const { loading, saveDraft, modalMessage, setShowModal, showModal } = useDraft();

    const candidateIsRealEstateAgent =  (candidates.length > 0 && candidates) && candidates[0].user_type === '100'

    useEffect(() => {
        if (!!professions.length) {
            setProfessionOptions(professions.map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);

    const onSubmitHandler = (values) => {
        setStepData(values);
    }

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                // initialValues={stepData ? stepData : formInitialValues}
                initialValues={stepData ?  ({ ...formInitialValues, ...stepData}) : formInitialValues}
                onSubmit={(values) => onSubmitHandler(values)}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    /* and other goodies */
                }) => (
                    
                    <Container>

                        <PastJobType
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />
                        <Location
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />
                        <TimingInfo
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />
                        { candidateIsRealEstateAgent && (
                            <RealEstateDetails
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                                optional={false}
                            />
                        )}
                        <Description
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            optional={false}
                        />
                        <PartyConfirmation 
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                onChange={handleChange}
                                formValues={values}
                                t={t}
                                optional={false}
                        
                        
                        />
                        <VisibilityAfterExchange
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            optional={false}
                        />
                        <ButtonContainer>
                            {isValid && <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={saveDraft}
                                >
                                    Save Draft
                                </Button>
                            </Box>}
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default JobPastCompleteInformation;
