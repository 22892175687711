import { add } from "./src/add";
import { addUser } from "./src/add-user";
import { analytics } from "./src/analytics";
import { arrowDown } from "./src/arrow-down";
import { arrowLeft } from "./src/arrow-left";
import { arrowRight } from "./src/arrow-right";
import { arrowUp } from "./src/arrow-up";
import { birthday } from "./src/birthday";
import { block } from "./src/block";
import { briefcase } from "./src/briefcase";
import { building } from "./src/building";
import { calendar } from "./src/calendar";
import { camera } from "./src/camera";
import { candidate } from "./src/candidate";
import { chat } from "./src/chat";
import { checkFilled } from "./src/check-filled";
import { checkOutlined } from "./src/check-outlined";
import { checkedBox } from "./src/checked-box";
import { chevronDown } from "./src/chevron-down";
import { chevronLeft } from "./src/chevron-left";
import { chevronRight } from "./src/chevron-right";
import { chevronUp } from "./src/chevron-up";
import { circle } from "./src/circle";
import { clipboard } from "./src/clipboard";
import { clock } from "./src/clock";
import { close } from "./src/close";
import { columns } from "./src/columns";
import { comment } from "./src/comment";
import { contact } from "./src/contact";
import { copyToClipboard } from "./src/copy-to-clipboard";
import { create } from "./src/create";
import { document } from "./src/document";
import { downloadFromCloud } from "./src/download-from-cloud"
import { draggable } from "./src/draggable";
import { earn } from "./src/earn";
import { earth } from "./src/earth";
import { endorsement } from "./src/endorsement";
import { envelopeDownArrow } from "./src/envelope-down-arrow";
import { exclamationTriangle } from "./src/exclamation-triangle";
import { exclamationSquareSolid } from "./src/exclamation-square-solid";
import { eyeInvisible } from "./src/eye-invisible";
import { eyeVisible } from "./src/eye-visible";
import { facebook } from "./src/facebook";
import { feedback } from "./src/feedback";
import { filter } from "./src/filter";
import { fingersCrossed } from "./src/fingers-crossed";
import { gratitude } from "./src/gratitude";
import { group } from "./src/group";
import { handSpeaker } from "./src/hand-speaker";
import { heartFilled } from "./src/heart-filled";
import { heartOutlined } from "./src/heart-outlined";
import { help } from "./src/help";
import { home } from "./src/home";
import { info } from "./src/info";
import { instagram } from "./src/instagram";
import { invite } from "./src/invite";
import { link } from "./src/link";
import { linkedin } from "./src/linkedin";
import { location } from "./src/location";
import { lock } from "./src/lock";
import { logout } from "./src/logout";
import { mail } from "./src/mail";
import { menuVertical } from "./src/menu-vertical";
import { minus } from "./src/minus";
import { myOps } from "./src/my-ops";
import { negative } from "./src/negative";
import { neutral } from "./src/neutral"
import { news } from "./src/news";
import { noReminders } from "./src/no-reminders";
import { opportunity } from "./src/opportunity";
import { pen } from "./src/pen";
import { phone } from "./src/phone";
import { plan } from "./src/plan";
import { plus } from "./src/plus";
import { profile } from "./src/profile";
import { report } from "./src/report";
import { reply } from "./src/reply";
import { search } from "./src/search";
import { send } from "./src/send";
import { settings } from "./src/settings";
import { share } from "./src/share";
import { sort } from "./src/sort";
import { sortUp } from "./src/sort-up";
import { star } from "./src/star";
import { thickArrowUp } from "./src/thick-arrow-up";
import { thickFilledArrowUp } from "./src/thick-filled-arrow-up";
import { thumbsUp } from "./src/thumbs-up";
import { thumbsUpOutlined } from "./src/thumbs-up-outlined";
import { times } from "./src/times";
import { toolbox } from "./src/toolbox";
import { trash } from "./src/trash";
import { twitter } from "./src/twitter";
import { uncheckedBox } from "./src/unchecked-box";
import { upload } from "./src/upload";
import { youtube } from "./src/youtube";

// BEFORE ADDING A NEW ICON: all are listed in alphabetical order, please consider this when adding a new icon
// To add a new icon, add the icon to the src folder, then import it here and add it to the export default object
// You can check all icons at this link: localhost:3000/icons
export default {
    add,
    addUser,
    analytics,
    arrowDown,
    arrowLeft,
    arrowRight,
    arrowUp,
    birthday,
    block,
    briefcase,
    building,
    calendar,
    camera,
    candidate,
    chat,
    checkFilled,
    checkOutlined,
    checkedBox,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    circle,
    clipboard,
    clock,
    close,
    columns,
    comment,
    contact,
    copyToClipboard,
    create,
    document,
    downloadFromCloud,
    draggable,
    earn,
    earth,
    endorsement,
    envelopeDownArrow,
    exclamationTriangle,
    exclamationSquareSolid,
    eyeInvisible,
    eyeVisible,
    facebook,
    feedback,
    filter,
    fingersCrossed,
    gratitude,
    group,
    handSpeaker,
    heartFilled,
    heartOutlined,
    help,
    home,
    info,
    instagram,
    invite,
    link,
    linkedin,
    location,
    lock,
    logout,
    mail,
    menuVertical,
    minus,
    myOps,
    negative,
    neutral,
    news,
    noReminders,
    opportunity,
    pen,
    phone,
    plan,
    plus,
    profile,
    report,
    reply,
    search,
    send,
    settings,
    share,
    sort,
    sortUp,
    star,
    thickArrowUp,
    thickFilledArrowUp,
    thumbsUp,
    thumbsUpOutlined,
    times,
    toolbox,
    trash,
    twitter,
    uncheckedBox,
    upload,
    youtube
};
