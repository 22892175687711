import {
    FETCH_REFERRALINFORMATION_FAIL,
    FETCH_REFERRALINFORMATION_SUCCESS,
    FETCH_REFERRALINFORMATION_ISLOADING,
    REFERRALINFORMATION_INIT,
    REFERRALINFORMATION_CLAIM_MODAL,
    REFERRALS_UNCLAIM_SUCCESS,
    REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL,
    REFERRALINFORMATION_SET_REFERRAL,
    REFERRALINFORMATION_SET_SECTION,
    REFERRALINFORMATION_TIMELINE_LOADING,
    REFERRALINFORMATION_TIMELINE_SUCCESS,
    REFERRALINFORMATION_TIMELINE_ADD_ITEM,
    REFERRALINFORMATION_SELECT_CANDIDATE,
    REFERRALINFORMATION_CONFIRM_PAST,
    REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY,
    RECOMMEND_SEND_SUCCESS,
    UPVOTE_CANDIDATE,
    UPVOTE_RECOMMENDATION,
    DID_STATUS_CHANGED
} from "../../../../actions/constants";

import Utils from "../../../../../libs/Utils";
import { OPPORTUNITIES_MODEL } from "../../../../../assets/strings";

const initialState = {
    referralId: "",
    from: "",
    open: false,
    referral: {},
    isLoading: false,
    errors: [],
    easyReferralPotentialCandidates: {
        isLoading: false,
        users: []
    },
    claimSuccessModal: {
        dictKey: "",
        open: false
    },
    confirmAssignModal: {
        title: "",
        message: "",
        open: false,
        candidate: {},
        referralId: ""
    },
    timeline: {
        isLoading: false,
        data: []
    },
    currentSection: "OVERVIEW"
};

function referralInformationReducer(state = initialState, action) {
    switch (action.type) {
        case "POTENTIAL_CANDIDATES_ISLOADING": {
            const _state = {
                ...state
            };
            _state.easyReferralPotentialCandidates.isLoading =
                action.payload.isLoading;
            return _state;
        }

        case "POTENTIAL_CANDIDATES_SUCCESS": {
            const _state = {
                ...state
            };
            _state.easyReferralPotentialCandidates.users = [
                ...action.payload.users.map(candidate => ({
                    id: candidate.uid,
                    data: {
                        ...candidate,
                        name: Utils.capFirst(candidate.first_name),
                        lastname: Utils.capFirst(candidate.last_name),
                        company_info: {
                            name: Utils.capFirst(candidate.company_name)
                        }
                    }
                }))
            ];
            _state.easyReferralPotentialCandidates.isLoading = false;
            return _state;
        }
        case "POTENTIAL_CANDIDATES_FAIL": {
            const _state = {
                ...state
            };
            _state.easyReferralPotentialCandidates.errors = [action.errors];
            _state.easyReferralPotentialCandidates.isLoading = false;
            return _state;
        }
        //TODO: make this business model data
        case "CANDIDATES_SUCC": {
            const _state = {
                ...state
            };
            _state.easyReferralCandidates.users = [
                ..._state.easyReferralCandidates.users,
                {
                    id: action.payload.uid,
                    data: {
                        ...action.payload.easyReferralCandidates,
                        name: Utils.capFirst(
                            action.payload.easyReferralCandidates.name
                        ),
                        lastname: Utils.capFirst(
                            action.payload.easyReferralCandidates.lastname
                        )
                    }
                }
            ];
            return _state;
        }
        case FETCH_REFERRALINFORMATION_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case FETCH_REFERRALINFORMATION_SUCCESS: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.rawReferral = action.payload.raw;
            _state.referral = action.payload.referral;
            // It may happen that if comments are enabled, the subscription already fetched the comments
            // So we just need to add the new comments to the existing ones
            _state.timeline.data = [...action.payload.timeline, ..._state.timeline.data];
            _state.timeline.isLoading = false;
            _state.referral.fromId = action.payload.referral.fromUser.uid;
            _state.alreadyFetched = true;
            _state.opType = action.payload.opType;
            return _state;
        }
        case FETCH_REFERRALINFORMATION_ISLOADING: {
            const _state = Object.assign(
                {},
                {
                    ...state,
                    easyReferralCandidates: {
                        isLoading: false,
                        users: []
                    },
                    easyReferralPotentialCandidates: {
                        isLoading: false,
                        users: []
                    }
                }
            );

            _state.isLoading = action.payload.isLoading;
            _state.timeline.isLoading = action.payload.isLoading;
            return _state;
        }

        case REFERRALINFORMATION_CLAIM_MODAL: {
            const _state = Object.assign(
                {},
                {
                    ...state,
                    claimSuccessModal: {
                        ...action.payload.claimSuccessModal
                    }
                }
            );
            return _state;
        }

        case REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL: {
            const _state = Object.assign(
                {},
                {
                    ...state,
                    confirmAssignModal: {
                        ...action.payload.confirmAssignModal
                    }
                }
            );
            return _state;
        }
        case REFERRALINFORMATION_SET_REFERRAL: {
            const _state = {
                ...state,
                referralId: action.payload.referralId,
                from: action.payload.from,
                open: action.payload.open
            };
            return _state;
        }
        case REFERRALINFORMATION_SET_SECTION: {
            const _state = {
                ...state,
                currentSection: action.payload.currentSection
            };
            return _state;
        }
        case REFERRALINFORMATION_TIMELINE_SUCCESS: {
            const _state = {
                ...state,
                timeline: {
                    ...state.timeline,
                    data: [...action.payload.timeline],
                }
            };
            return _state;
        }
        case REFERRALINFORMATION_TIMELINE_LOADING: {
            const _state = {
                ...state,
                timeline: {
                    ...state.timeline,
                    isLoading: action.payload.isLoading
                }
            };
            return _state;
        }
        case REFERRALINFORMATION_TIMELINE_ADD_ITEM: {
            const _state = {
                ...state,
                timeline: {
                    ...state.timeline,
                    data: [
                        { ...action.payload.timelineItem },
                        ...state.timeline.data
                    ]
                }
            };
            return _state;
        }

        case REFERRALINFORMATION_INIT: {
            return { ...initialState };
        }

        case REFERRALINFORMATION_SELECT_CANDIDATE: {
            const _state = {
                ...state,
            }
            // Candidate to Assign the Opportunity
            const candidateId = action.payload.candidateId;
            // Check if the Candidate is a Recommendation
            const isRecommendation = action.payload.isRecommendation;
            // Update the Status of the Opportunity
            _state.referral.status = "ASSIGNED";
            // If the candidate is a recommendation, we need to remove it from the recommendations list
            // Add them to the `toUser` key with the status `ASSIGNED`
            if (isRecommendation) {
                // Get the Recommendations
                const recommended = _state.referral.recommended;
                // Get the Candidate from the Recommendations
                const recommendedCandidate = recommended.find(
                    recommendation =>
                        recommendation.uid === candidateId ||
                        recommendation.contactId === candidateId
                );
                // Remove the Candidate from the Recommendations
                _state.referral.recommended = recommended.filter(
                    recommendation =>
                        recommendation.uid !== candidateId ||
                        recommendation.contactId !== candidateId
                );
                // Add candidate whether it is a user or a contact to the `toUser` key or `toContact` key
                // depending on the type of the candidate with the status `ASSIGNED`
                const to = recommendedCandidate.contactId ? "toContact" : "toUser";
                _state.referral[to] = [
                    ...(_state.referral[to] || []),
                    {
                        ...recommendedCandidate,
                        opportunityStatus: "ASSIGNED"
                    }
                ];
                // Set the opportunity as unconfirmed
                _state.referral.isConfirmed = false;
            }
            // Update the Status of the Candidate in the Opportunity
            _state.referral.toUser = _state.referral.toUser.map(candidate => {
                if (candidate.uid === candidateId) {
                    candidate.opportunityStatus = "ASSIGNED";
                } else if (candidate.opportunityStatus !== "REJECTED") {
                    candidate.opportunityStatus = "DECLINED";
                }
                return candidate;
            });

            return _state;
        }
        case REFERRALINFORMATION_CONFIRM_PAST: {
            const _state = {
                ...state,
            };
            _state.referral.isConfirmed = true;
            return _state;
        }
        case REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY: {
            const _state = {
                ...state,
            };
            _state.referral.declined = {
                ...(_state.referral.declined || {}),
                [action.payload.userId]: {
                    timestamp: new Date().getTime(),
                    userId: action.payload.userId,
                    action: "DECLINED"
                }
            }
            return _state;
        }
        case RECOMMEND_SEND_SUCCESS: {
            if (!state.referral.recommended) {
                state.referral.recommended = [];
            } else {
                // it is object of objects, so we need to convert it to array
                state.referral.recommended = Object.values(state.referral.recommended);
            }
            // filter duplicates
            const recommendedMembers = action.payload.recommendedMembers.filter(
                member =>
                    !state.referral.recommended.some(
                        recommendedMember => recommendedMember.uid === member.uid
                    )
            );

            const recommendedContacts = action.payload.recommendedContacts.filter(
                contact =>
                    !state.referral.recommended.some(
                        recommendedContact =>
                            recommendedContact.contactId === contact.uid
                    )
            );

            const _state = {
                ...state,
                referral: {
                    ...state.referral,
                    recommended: [
                        ...state.referral.recommended,
                        ...recommendedMembers.map(member => ({
                            ...member,
                            name: member.first_name,
                            lastname: member.last_name,
                            totalUpvotes: 0,
                            recommendedBy: {
                                userName: action.payload.userName,
                                fullName: action.payload.fullName,
                                uid: action.payload.uid
                            }
                        })),
                        ...recommendedContacts.map(contact => ({
                            ...contact,
                            contactId: contact.uid,
                            name: contact.first_name,
                            lastname: contact.last_name,
                            totalUpvotes: 0,
                            recommendedBy: {
                                userName: action.payload.userName,
                                fullName: action.payload.fullName,
                                uid: action.payload.uid
                            }
                        }))
                    ]
                }
            };

            return _state;
        }
        case REFERRALS_UNCLAIM_SUCCESS: {
            const _state = {
                ...state,
            }
            // User to Unclaim the Opportunity
            const userId = action.payload.userId;
            // Remove user from `toUser` key
            _state.referral.toUser = _state.referral.toUser.filter(user => user.uid !== userId);
            return _state;
        }
        case UPVOTE_RECOMMENDATION: {
            const _state = {
                ...state,
                referral: {
                    ...state.referral,
                    // recommended is an object of objects, so we need to convert it to array
                    recommended: Object.values(state.referral.recommended).map(recommended => {
                        if (recommended.contactId) {
                            if (recommended.contactId === action.payload.recommendedId) {
                                recommended.totalUpvotes++;
                            }
                        } else {
                            if (recommended.uid === action.payload.recommendedId) {
                                recommended.totalUpvotes++;
                            }
                        }
                        return recommended;
                    })
                }
            };

            return _state;
        }
        case UPVOTE_CANDIDATE: {
            const _state = {
                ...state,
            };
            const { toUser } = _state.referral;

            _state.referral.toUser = toUser.map((candidate) => {
                if (candidate.uid === action.payload.recommendedId) {
                    candidate.totalUpvotes++;
                }
                return candidate;
            });

            return _state;
        }
        case DID_STATUS_CHANGED: {
            const _state = {
                ...state,
            }
            // Set new status
            _state.referral.status = action.payload.newStatus;

            return _state;
        }
        default:
            return state;
    }
}

export default referralInformationReducer;
