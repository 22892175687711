import ReferralBusiness from '../../../../business/referral';
import { OpportunityStepsOrder, REFERRAL_PRIVACY } from '../../../../components/ui/presentation/views/RecommendationCreation/constants';
import { cleaningObject, convertToTimeStamp  } from '../../../../libs/Utils';
import {
  OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
  OPPORTUNITY_FLOW_RESET,
  SAVE_REFERRAL_DRAFT,
  LOAD_REFERRAL_DRAFT,
  CURRENT_REFERRAL_LOADING,
  CURRENT_REFERRAL_SUCCESS,
  CURRENT_REFERRAL_ERROR,
  NEW_CONTACT_SAVED,
  CONTACT_IS_LOADING,
  CONTACT_ERROR,
  CONTACT_SAVED,
  CANCEL_CONTACT_ERROR
} from '../../constants';
import { getMembersAndContactsFromCandidates } from '../opportunityCreationFlow';


const loadRecommendationDraft = (draft) => {
  // first, get all of keys in draft (they meant to be steps but not all of them)
  const draftKeys = Object.keys(draft);
  // remove darftId, from and lastStep from keys
  const draftId = draft.draftId;
  draftKeys.splice(draftKeys.indexOf('draftId'), 1);
  draftKeys.splice(draftKeys.indexOf('from'), 1);


  // get order of each step and sort them
  const draftKeysOrder = draftKeys
      // Remove OPPORTUNITY_TYPE_STEP from draft since it is a recommendation
      .filter(key => key !== "OPPORTUNITY_TYPE_STEP")
      .map(key => OpportunityStepsOrder[key]);
  const sortedDraftKeys = draftKeysOrder.sort((a, b) => a - b).map(order => {
    return draftKeys.find(key => OpportunityStepsOrder[key] === order);
  });

  // dispatch setStepData for each step in order
  return (dispatch) => {
    dispatch({ type: LOAD_REFERRAL_DRAFT, payload: { draftId } })
    sortedDraftKeys.forEach(key => {
      dispatch(setStepData(key, draft[key]));
    });
  };
}

const setStepData = (step, data) => {
  return {
    type: step,
    payload: {
      data,
    }
  };
};

const saveStepData = (data) => {
  return {
    type: 'SAVE_STEP_DATA',
    payload: {
      data,
    }
  };
};

const setCurrentStep = (step) => {
  return {
    type: OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    payload: {
      step,
    }
  };
};

const resetState = () => {
  return {
    type: OPPORTUNITY_FLOW_RESET,
    payload: {},
  };
};

const saveRecommendationDraft = () => {
  return async (dispatch, getState, { firebase }) => {
    const { uid } = getState().main.user
    const { stepData, draftId } = getState().main.ui.view.recommendationCreationReducer;

    const savedDraftId = await firebase.saveReferralDraft(stepData, uid, draftId)
      dispatch({
          type: SAVE_REFERRAL_DRAFT,
          payload: {
              draftId: savedDraftId
          }
      });
  };
}



const cancelContactError = () => {
  return (dispatch) => {
          dispatch({ type: CANCEL_CONTACT_ERROR })
  }
}

// MARK CANDIDATE SELECTION UI VIEW - CREATE AND SAVE CONTACT


//MARK: SENT NEW CURRENT REFERRAL 




//MARK: SENDING RECOMMENDATION DATA
const sendRecommendationAction = () => {
    return async(dispatch, getState, { cloudfunctions, firebase }) => {
        try {
           dispatch({ type: CURRENT_REFERRAL_LOADING })
            const me =           getState().main.user.profile
            const meId =         getState().main.user.uid
            const { 
                address, 
                company_info, 
                email, 
                lastname, 
                name, 
                phone, 
                state, 
                type, 
                avatar, 
                userName, 
                subscription 
            } = me && me
            const { stepData, draftId } = getState().main.ui.view.recommendationCreationReducer;
            const { 
                RECOMMENDATION_REQUEST_INFORMATION,  
                RECOMMENDATION_DIRECT_INFORMATION ,
                CANDIDATES_SELECTION = [], 
                RECOMMENDATION_TYPE
            } = stepData && stepData;

           const { receiver, description, shareInfoBetweenContacts } = RECOMMENDATION_DIRECT_INFORMATION && RECOMMENDATION_DIRECT_INFORMATION;
           const {  profession, locationIsRemote, cannotFindProfession, professionDescription  } = RECOMMENDATION_REQUEST_INFORMATION && RECOMMENDATION_REQUEST_INFORMATION;
           const recommendationType =    (RECOMMENDATION_TYPE ) && RECOMMENDATION_TYPE.value;
           const isRemote =              (RECOMMENDATION_TYPE.value === "PROVIDER") ? locationIsRemote.value === 'REMOTE' : false
           const descriptionText =       (description )? description : '';
           const clientVisibilityValue = (shareInfoBetweenContacts) && shareInfoBetweenContacts;

           const locationData = {
                coords:                (RECOMMENDATION_REQUEST_INFORMATION.address) ? [RECOMMENDATION_REQUEST_INFORMATION.address.lat,  RECOMMENDATION_REQUEST_INFORMATION.address.lng ] : [],
                fullAddress:           (RECOMMENDATION_REQUEST_INFORMATION.address) ? RECOMMENDATION_REQUEST_INFORMATION.address.description : "",
                state:                 (RECOMMENDATION_REQUEST_INFORMATION.address) ? RECOMMENDATION_REQUEST_INFORMATION.address.state : "",
                stateShort:            (RECOMMENDATION_REQUEST_INFORMATION.address) ? RECOMMENDATION_REQUEST_INFORMATION.address.stateShort : "",
                subAdministrativeArea: (RECOMMENDATION_REQUEST_INFORMATION.address) ? RECOMMENDATION_REQUEST_INFORMATION.address.subAdministrativeArea : "",
                zipcode:                (RECOMMENDATION_REQUEST_INFORMATION.address) ? RECOMMENDATION_REQUEST_INFORMATION.address.zipCode : "",
            } 
        console.log(receiver, "receiver")
        const receiverIsContact = receiver && (!receiver.userName || receiver.type === "CONTACT");
        const receiverMemberData =
            receiver && !receiverIsContact ? receiver.uid : null;
        const receiverContactData = receiver && receiverIsContact ? (receiver.uid || receiver.contact_id) : null;


          const professionData =            (profession && profession.value) ?  parseInt(profession.value) : null
          const describedProfession =       (professionDescription) ? professionDescription: null



      // Get all the recommended members and contacts
      const { members: recommendedMembers, contacts: recommendedContacts } = getMembersAndContactsFromCandidates(CANDIDATES_SELECTION, true);

           const recommendationPayload = {
             type: recommendationType,
             from: meId,
             clientVisibility:    clientVisibilityValue && clientVisibilityValue,
             description:         descriptionText || RECOMMENDATION_REQUEST_INFORMATION.description || null ,
             recommendedMembers:  (recommendationType === 'I_KNOW') ? recommendedMembers  : [], 
             recommendedContacts: (recommendationType === 'I_KNOW') ? recommendedContacts : [], 
             receiverMember:      receiverMemberData, 
             receiverContact:     (recommendationType === 'I_KNOW' && receiverContactData) ? receiverContactData : null, 
             location:            (!isRemote && recommendationType === 'PROVIDER') ? locationData : null,
             isRemote:            (recommendationType === 'PROVIDER') ?  isRemote : (recommendationType === 'I_KNOW') ? true : null ,
             describedProfession: (recommendationType === 'PROVIDER' && describedProfession) ? describedProfession : null,
             profession:          (recommendationType === 'PROVIDER') ? professionData : null
           }


         await cloudfunctions.sendRecommendation(cleaningObject(recommendationPayload))
          // Remove draft if it exists
          if (draftId) {
              await firebase.deleteDraftReferral(meId, draftId);
          }
         dispatch({ type: CURRENT_REFERRAL_SUCCESS })
        } catch(error) {
             dispatch({ type: CURRENT_REFERRAL_ERROR });
             console.error('Recommendation Error ->', error)
        }

    }
}







export {
  setStepData,
  setCurrentStep,
  resetState,
  saveStepData,
  saveRecommendationDraft,
  loadRecommendationDraft,
  sendRecommendationAction,
  cancelContactError,


}
