import React, { useEffect } from 'react'
import VerifyPhoneNumberUI from '../../../ui/presentation/atoms/shared/VerifyByPhoneUI'
import { verifyPhoneNumberCode, showPhoneValidationModal, verifyPhoneNumber } from '../../../../redux/actions/creators/user'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PopupBlurModal from '../../../ui/presentation/atoms/shared/PopupBlurModal'
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

const BoldNumber = styled.b`
    color: ${props => props.theme.blueMedium};
    white-space: nowrap;
    font-weight: 600;
`

const VerifyPhoneNumber = (props) => {
    const {
        t,
        success,
        errors,
        loading,
        verifyPhoneNumberCode,
        showPhoneValidationModal,
        onClose,
        onSuccess,
        onFail,
        verifyPhoneNumber,
        phone,
        open,
        verified_phone
    } = props

    useEffect(() => {
        // if(!verified_phone && phone && open) verifyPhoneNumber(phone)
    }, [open])


    const exec = (code) => verifyPhoneNumberCode(code, onSuccess, onFail);
    const close = () => {
        showPhoneValidationModal(false);
        if (onClose) onClose()
    }
    const resendCode = () => {
        verifyPhoneNumber(phone);
    }

    const subheader = (
        <>
            {t("subheader_1")}
            <BoldNumber>
                <NumberFormat
                    material={"true"}
                    bg="#fff"
                    displayType="text"
                    name="phone"
                    type="number"
                    format="+# (###) ###-####"
                    mask="_"
                    value={phone}
                />
            </BoldNumber>
            {t("subheader_2")}
        </>
    );

    return (
        <PopupBlurModal
            open={open}
            onClose={() => {}}
        >
            <VerifyPhoneNumberUI
                title={t("title")}
                subheader={subheader}
                showPhoneValidationModal={showPhoneValidationModal}
                success={success}
                resendCode={resendCode}
                loading={loading}
                errors={errors}
                exec={exec}
                close={close}
            />
        </PopupBlurModal>
    );
}

const mapStateToProps = (state) => {
    return {
        verified_phone: state.main.user.profile.verified_phone,
        phone: state.main.user.profile.phone,
        open: state.main.user.phoneValidation.modalOpen && state.main.user.profile && !state.main.user.profile.verified_phone, //state.main.ui.view.fillProfile.phoneValidation.modalOpen,
        errors: state.main.user.phoneValidation.errors,
        loading: state.main.user.phoneValidation.loading,
        success: state.main.user.phoneValidation.success
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        verifyPhoneNumberCode,
        showPhoneValidationModal,
        verifyPhoneNumber
    },
    dispatch,
)

const VerifyPhoneNumberWithRouter = withRouter(VerifyPhoneNumber);

const VerifyPhoneNumberWithTranslation = withTranslation("verifyPhoneNumber")(VerifyPhoneNumberWithRouter)

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneNumberWithTranslation);