import styled from "styled-components";
import React from 'react'
import { Icon } from "../../../../../atoms/shared/Icon";

const SearchContainer = styled.div`
    border: 1px solid ${(props) => props.error ? '#DC3545' : '#929292'};
    height: 50px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    background-color: #FFFFFF;
    &:focus-within {
        border: 1px solid rgba(5, 122, 255, 1);
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        outline: none;
    }
`;

const SearchBox = styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
    padding: 0 10px;
    display: flex;
    flex-grow: 1;
    &:focus {
        border: none;
        outline: none;
    }
    :disabled {
        background: transparent;
    }
`;

export const DummySearch = () =>
    <SearchContainer>
        <Icon
            icon="search"
            height={20}
            width={20}
            color={`#929292`}
        />
        <SearchBox
            placeholder="Search by name"
            type="text"
            name="searchTerm"
            disabled

        />
    </SearchContainer>