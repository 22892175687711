import React, { Component } from 'react';
import { Gravity } from './styled';
import {Box, Flex} from 'rebass';

const isGlobalModal = (WrappedComponent) => {
    const render = typeof WrappedComponent === 'function';
    class GlobalModal extends Component {
        componentDidMount = () => {
        }
        render() {
            if (render && this.props.borrow)
                return <Gravity>
                    <Flex
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'>
                        <Box width={[0.7, 0.7, 0.7]}>
                            <WrappedComponent/>
                        </Box>
                    </Flex>
                </Gravity>;
            else
                return null;
        }
    }

    return {
        GlobalModal: GlobalModal,
        borrow: render
    }
}

export default isGlobalModal;