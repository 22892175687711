import {
    FETCH_REFERRALS_FAIL,
    FETCH_REFERRALSUSERS_SUCC,
    REFERRALS_INIT,
    OPEN_REFERRAL_SUCC,
    ADD_RECEIVED_COUNTER,
    ADD_SENT_COUNTER,
    REFERRALS_ONVALUE,
    REFERRALS_SET_SELECTED_TAB,
    REFERRALS_SET_FILTER_SEARCH,
    REFERRALS_SET_FILTER_SORT,
    REFERRALS_SET_FILTER_STATUS_ADD,
    REFERRALS_SET_FILTER_STATUS_REMOVE,
    REFERRALS_SET_FILTER_OPEN,
    REFERRALS_IS_LOADING,
    REFERRALS_FETCH_SUCCESS,
    REFERRALS_NOT_AUTHORIZED_MODAL,
    REFERRALS_FOOTER_OPEN,
    REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL,
    REFERRALS_RESET_FILTERS,
    REFERRALS_UPDATE_FILTERS,
    SHOW_REFERRALS_FILTERS,
    HIDE_REFERRALS_FILTERS,
    REFERRALS_APPLY_FILTERS,
    FETCH_DRAFT_REFERRALS,
    FETCH_MYOPS_ANALYTICS_LOADING,
    FETCH_MYOPS_ANALYTICS_SUCCESS,
    FETCH_MYOPS_ANALYTICS_FAIL,
} from "../../../../actions/constants";

const initialState = (initS = {}) => {

    return {
        search: {
            value: '',
            page: 0,
            isLoading: false,
            hasMore: true,
        },
        isloading: false,
        receivedCounter: 0,
        sentCounter: 0,
        referrals: {
            page: 0,
            data: [],
            count: 0,
            pendingsCount: 0,
            awardedsCount: 0
        },
        filters: {
        },
        openReferrals: [],
        referralsUsers: {},
        errors: [],
        selectedTab: 'MY_REFERRALS',
        footerOpen: true,
        opportunityNoLongerAvailableModal: false,
        statusOptions: [
            { value: 'SUBMITTED', label: 'Pending' },// 0
            { value: 'ASSIGNED', label: 'Awarded' }, // 1
            { value: 'CANCELED', label: 'Cancelled' }, // 2
            { value: 'CLAIMED', label: 'Claimed' }, // 3
            { value: 'EXPIRED', label: 'Expired' }, // 4
            { value: 'IN_PROGRESS', label: 'In Progress' }, //5 
            { value: 'ON_HOLD', label: 'On Hold' }, // 6
            { value: 'CLOSED', label: 'Closed' }, // 7
            { value: 'COMPLETED', label: 'Completed' }, // 8
        ],
        typeOptions: [
            { value: 'REFERRAL', label: 'Referral' },
            { value: 'RECOMMENDATION', label: 'Intro' },
            // { value: 'SERVICE_REQUEST', label: 'Service Request' },
            { value: 'JOB', label: 'Job' },
        ],
        onlyOptions: [
            { value: 'CREATED', label: 'Created' },
            // { value: 'CLAIMED', label: 'Claimed' },
            { value: 'RECEIVED', label: 'Received' },
        ],
        timingOptions: [
            {
                value: "NOW",
                label: "Ready NOW!"
            },
            {
                value: "SIX_TWELVE",
                label: "Ready in 6-12 Months"
            },
            {
                value: "TWELVE_AND_MORE",
                label: "Ready in 12+ Months"
            },
            {
                value: "TWO_SIX",
                label: "Ready in 2-6 Months"
            }
        ],
        dateOptions: {
            "PRO": [
                { value: 0, label: "Last 7 days" },
                { value: 1, label: "Last 30 days" },
                { value: 2, label: "Last 90 days" },
                { value: 3, label: "Last year" },
                { value: 4, label: "Between dates" }
            ],
            "ESSENTIAL+": [
                { value: 0, label: "Last 7 days" },
                { value: 1, label: "Last 30 days" },
            ],
            "ESSENTIAL": [
                { value: 0, label: "Last 7 days" },
            ]
        },
        opReviewOptions: [
            { value: "ALL", label: "All" },
            { value: "GIVEN", label: "Given" },
            { value: "RECEIVED", label: "Received" },
            { value: "PENDING", label: "Pending to Review" },
        ],
        clientTypes: [
            { value: 'BUYER', label: 'Buyer' },
            { value: 'LANDLORD', label: 'Landlord' },
            { value: 'SELLER', label: 'Seller' },
            { value: 'TENANT', label: 'Tenant' },
        ],
        propertyTypes: [
            //Single Family
            // Multi Family
            // Condo
            // Apartment
            // Land
            // Commercial as value and label
            { value: 'SINGLE_FAMILY', label: 'Single Family' },
            { value: 'MULTI_FAMILY', label: 'Multi Family' },
            { value: 'CONDO', label: 'Condo' },
            { value: 'APARTMENT', label: 'Apartment' },
            { value: 'LAND', label: 'Land' },
            { value: 'COMMERCIAL', label: 'Commercial' },
        ],
        //All Cash Buyer
        // Pre-approved for the Amount listed
        // Not yet pre-approved
        // Not sure
        financingTypes: [
            { value: 'ALL_CASH_BUYER', label: 'All Cash Buyer' },
            { value: 'PRE_APPROVED', label: 'Pre-approved for the Amount listed' },
            { value: 'NOT_YET_PRE_APPROVED', label: 'Not yet pre-approved' },
            { value: 'NOT_SURE', label: 'Not sure' },
        ],
        //Relative
        // Friend
        // Current Client
        // Past Client
        // Recent Lead
        // Other
        clientSources: [
            { value: 'RELATIVE', label: 'Relative' },
            { value: 'FRIEND', label: 'Friend' },
            { value: 'CURRENT_CLIENT', label: 'Current Client' },
            { value: 'PAST_CLIENT', label: 'Past Client' },
            { value: 'RECENT_LEAD', label: 'Recent Lead' },
            { value: 'OTHER', label: 'Other' },
        ],
        bedCounts: [
            // { value: '0', label: 'any' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '+4', label: '4+' },
        ],
        bathCounts: [
            // { value: '0', label: 'any' },
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '+4', label: '4+' },
        ],
        draftReferrals: [],
        analyticsSummary: {
            isLoading: false,
            data: {}
        },
        ...initS,
    };
};

function referralsReducer(state = initialState(), action) {
    switch (action.type) {

        case FETCH_DRAFT_REFERRALS: {
            return {
                ...state,
                draftReferrals: action.payload.draftReferrals
            }
        }

        case REFERRALS_APPLY_FILTERS: {
            return {
                ...state,
            }
        }

        case REFERRALS_UPDATE_FILTERS: {
            const _filters = { ...state.filters, ...action.payload.filters };
            return {
                ...state,
                filters: _filters
            }
        }
        case REFERRALS_RESET_FILTERS: {
            return {
                ...state,
                filters: {}
            }
        }
        case REFERRALS_INIT: {
            const newState = Object.assign(
                {},
                initialState({
                    footerOpen: state.footerOpen,
                    openReferrals: state.openReferrals
                })
            );
            return newState;
        }
        case REFERRALS_IS_LOADING: {
            const _state = {
                ...state,
                isLoading: action.payload.isLoading
            };
            return _state;
        }
        case REFERRALS_FETCH_SUCCESS: {
            const _state = {
                ...state,
                referrals: {
                    count: action.payload.count,
                    data: [...action.payload.data],
                    page:
                        typeof action.payload.page !== "undefined"
                            ? action.payload.page
                            : state.referrals.page + 1,
                    hasMore: action.payload.hasMore,
                    pendingsCount: action.payload.pendingsCount,
                    awardedsCount: action.payload.awardedsCount
                }
            };
            return _state;
        }
        case FETCH_REFERRALS_FAIL: {
            const _state = {
                ...state
            };
            _state.received.isLoading = false;
            _state.sent.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case REFERRALS_ONVALUE: {
            const _state = {
                ...state,
                ...action.payload.referrals
            };
            return _state;
        }
        case FETCH_REFERRALSUSERS_SUCC: {
            const _state = {
                ...state
            };
            _state.referralsUsers[action.payload.uid] = {
                ...action.payload.referralsUsers
            };
            return _state;
        }
        case OPEN_REFERRAL_SUCC: {
            const _state = {
                ...state
            };
            _state.openReferrals = [...action.payload.openReferrals];
            return _state;
        }
        case ADD_RECEIVED_COUNTER: {
            const _state = {
                ...state
            };
            _state.receivedCounter += 1;
            return _state;
        }
        case ADD_SENT_COUNTER: {
            const _state = {
                ...state
            };
            _state.sentCounter = _state.sentCounter + action.payload;
            return _state;
        }
        case REFERRALS_SET_SELECTED_TAB: {
            const _state = {
                ...state
            };
            _state.selectedTab = action.payload.selectedTab;
            return _state;
        }
        case REFERRALS_SET_FILTER_SEARCH: {
            const _state = {
                ...state
            };
            _state.filters = {
                ..._state.filters,
                search: action.payload.search
            };
            _state.referrals = {
                page: 0,
                count: 0,
                data: [..._state.referrals.data],
                hasMore: true
            };
            return _state;
        }
        case REFERRALS_SET_FILTER_SORT: {
            const _state = {
                ...state
            };
            _state.filters = {
                ..._state.filters,
                sortBy: action.payload.sortBy
            };
            _state.referrals = {
                page: 0,
                count: 0,
                data: [..._state.referrals.data],
                hasMore: true
            };
            return _state;
        }
        case REFERRALS_SET_FILTER_STATUS_ADD: {
            const _state = {
                ...state
            };
            let newStatus = [...action.payload.status];
            if (newStatus.includes("SUBMITTED")) {
                if (!newStatus.includes("CLAIMED")) newStatus.push("CLAIMED");
            } else newStatus = newStatus.filter(f => f !== "CLAIMED");
            _state.filters = { ..._state.filters, status: newStatus };
            _state.referrals = {
                page: 0,
                count: 0,
                data: [..._state.referrals.data],
                hasMore: true
            };
            return _state;
        }
        case REFERRALS_SET_FILTER_STATUS_REMOVE: {
            const _state = {
                ...state
            };

            const statusNew = [
                ..._state.filters.status.slice(0, action.payload.status),
                ..._state.filters.status.slice(action.payload.status + 1)
            ];
            _state.filters = { ..._state.filters, status: statusNew };
            return _state;
        }
        case REFERRALS_SET_FILTER_OPEN: {
            const _state = {
                ...state
            };
            _state.filters = { ..._state.filters, open: action.payload.open };
            return _state;
        }
        case REFERRALS_FOOTER_OPEN: {
            return {
                ...state,
                footerOpen: action.payload.footerOpen
            };
        }
        case REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL: {
            return {
                ...state,
                opportunityNoLongerAvailableModal: !state.opportunityNoLongerAvailableModal
            };
        }
        case REFERRALS_NOT_AUTHORIZED_MODAL: {
            return {
                ...state,
                opportunityNotAuthorizedModal: !state.opportunityNotAuthorizedModal
            };
        }

        case SHOW_REFERRALS_FILTERS: {
            return {
                ...state,
                filtersEnabled: true
            };
        }

        case HIDE_REFERRALS_FILTERS: {
            return {
                ...state,
                filtersEnabled: false
            };
        }

        case FETCH_MYOPS_ANALYTICS_LOADING: {
            return {
                ...state,
                analyticsSummary: {
                    ...state.analyticsSummary,
                    isLoading: true
                }
            };
        }

        case FETCH_MYOPS_ANALYTICS_SUCCESS: {
            return {
                ...state,
                analyticsSummary: {
                    ...state.analyticsSummary,
                    isLoading: false,
                    data: action.payload.data
                }
            };
        }

        case FETCH_MYOPS_ANALYTICS_FAIL: {
            return {
                ...state,
                analyticsSummary: {
                    ...state.analyticsSummary,
                    isLoading: false
                }
            };
        }

        default:
            return state;
    }
}

export default referralsReducer;
