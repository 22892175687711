import {
    createStore,
    compose,
    applyMiddleware
} from "redux";
import thunk from "redux-thunk";
import {
    createBrowserHistory
} from "history";
import {
    routerMiddleware
} from "connected-react-router";
import  searchMiddleware from '../middlewares/search';
import createReducers from "../reducers/index";
import Firebase from "../../libs/Firebase";
import CloudFunctions from "../../libs/CloudFunctions";
import {
    firebaseConfig,
    cfConfig
} from "../../config";
import LocalStorag from "../../libs/LocalStorage";
//import * as Sentry from "@sentry/browser";
/* import {
    SIGN_IN,
    SIGN_IN_SUCC,
    SIGN_IN_FAIL,
    SIGN_UP,
    SIGN_UP_SUCC,
    SIGN_UP_FAIL,
    SIGN_OUT,
    UNSUCCESS_LOGIN,
    RESET_PASSWORD_SUCC,
    RESET_PASSWORD_FAIL,
    VALIDATE_PHONE,
    VALIDATE_PHONE_SUCC,
    VALIDATE_PHONE_FAIL,
    VALIDATE_PHONE_ON_EDIT,
    VALIDATE_PHONE_ON_EDIT_SUCC,
    VALIDATE_PHONE_ON_EDIT_FAIL,
    VALIDATE_PHONE_CODE_ON_EDIT,
    VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
    CONNECT,
    ACCEPT,
    EDIT_PROFILE_SAVE,
    EDIT_PROFILE_SAVE_SUCC,
    EDIT_PROFILE_SAVE_FAIL,
    SEND_REFERRAL_ADD_USER_TO_REFERRAL,
    SEND_REFERRAL,
    SEND_REFERRAL_SUCC,
    SEND_REFERRAL_FAIL
} from '../actions/constants';
 */
export const firebase = new Firebase(firebaseConfig);
const cloudfunctions = new CloudFunctions(cfConfig);
const LocalStorage = new LocalStorag();

/* const loggerMiddleware = store => next => action => {
    try {
        const loggedActions = [
            SIGN_IN,
            SIGN_IN_SUCC,
            SIGN_IN_FAIL,
            SIGN_UP,
            SIGN_UP_SUCC,
            SIGN_UP_FAIL,
            SIGN_OUT,
            UNSUCCESS_LOGIN,
            RESET_PASSWORD_SUCC,
            RESET_PASSWORD_FAIL,
            VALIDATE_PHONE,
            VALIDATE_PHONE_SUCC,
            VALIDATE_PHONE_FAIL,
            VALIDATE_PHONE_ON_EDIT,
            VALIDATE_PHONE_ON_EDIT_SUCC,
            VALIDATE_PHONE_ON_EDIT_FAIL,
            VALIDATE_PHONE_CODE_ON_EDIT,
            VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
            VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
            CONNECT,
            ACCEPT,
            EDIT_PROFILE_SAVE,
            EDIT_PROFILE_SAVE_SUCC,
            EDIT_PROFILE_SAVE_FAIL,
            SEND_REFERRAL,
            SEND_REFERRAL_ADD_USER_TO_REFERRAL,
            SEND_REFERRAL_SUCC,
            SEND_REFERRAL_FAIL
        ]

        if (loggedActions.includes(action.type)) {
         
            
        }
        next(action);
    } catch (error) {
        next(action);
    }
}; */

export const history = createBrowserHistory();

export const configureStore = initialState => {
    const store = createStore(
        createReducers(history),
        initialState,
        compose(
            applyMiddleware(
                thunk.withExtraArgument({
                    firebase,
                    cloudfunctions,
                    LocalStorage
                }),
              //  loggerMiddleware,
                searchMiddleware,
                routerMiddleware(history)
            ),
            window.__REDUX_DEVTOOLS_EXTENSION__ ?
            window.devToolsExtension() :
            f => f // add support for Redux dev tools
        )
    );

    return store;
};