import {
    combineReducers
} from 'redux';
import globalReducer from './global';
import viewReducer from './view';

const rootReducer = combineReducers({
    global: globalReducer,
    view: viewReducer
})

export default rootReducer;