import React, { useState } from "react";
import { Flex } from "rebass";
import CheckBox from "../../../../../../../atoms/shared/Inputs/Checkbox";
import Link from "../../../../../../../atoms/Link";
import { Text } from "../../../../../../../atoms/shared/Text";
import AllTermsModal from "../../../../../../ReferralInformation/TermsModal";
import { YesNoOptions as options } from "../../../../../constants";
import { Icon } from "../../../../../../../atoms/shared/Icon";
import {
    InputContainer, InputLabel,
} from "../../../../../../Settings/common/Components";
import { selectStyle } from "../../../../../components";
import Select from "react-select";

const Agreenment = (props) => {
    const {
        theme,
        onChange,
        formValues,
        feeTypesOptions,
        feeDueOptions,
        feeFrequencyOptions,
        feeDurationOptions,
        feeCommencementOptions,
        errors,
    } = props;

    const feeAmount = {
        [feeTypesOptions.optionsKeys.FLAT]: formValues.flatFeeAmount,
        [feeTypesOptions.optionsKeys.GROSS_REVENUE]: formValues.grossRevenueFee,
        [feeTypesOptions.optionsKeys.NO_FEE]: null,
        [feeTypesOptions.optionsKeys.OTHER]: null,
        [feeTypesOptions.optionsKeys.REAL_ESTATE]: formValues.realEstateFee
    };

    const fee = {
        type: formValues.referralFee.value,
        fee: feeAmount[formValues.referralFee.value],
        frequency: formValues.feeFrequency ? formValues.feeFrequency.value : '',
        duration: formValues.feeDuration ? formValues.feeDuration.value : '',
        due: formValues.paymentDue ? formValues.paymentDue.value: '',
        comments: formValues.comments,
        commencement: formValues.feeCommencement ? formValues.feeCommencement.value : '',
    };

    const [allTermsModalOpen, setAllTermsOpen] = useState(false);

    const toogleAllTermsOpen = () => setAllTermsOpen(!allTermsModalOpen);



    return (
        <>
            <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                <InputContainer
                    triggerShowLabel={formValues.agreement && (formValues.agreement.value !== null) ? "show" : "hide"}
                    showLabelOnValue
                >
                    <Select
                        isClearable
                        options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "agreement",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="agreement"
                        placeholder="Do you want NuOp to create a binding agreement for this referral?*"
                        value={formValues.agreement}
                        error={!!errors.agreement}
                    />
                    <InputLabel>Do you want NuOp to create a binding agreement for this referral?*</InputLabel>
                </InputContainer>
            </Flex>
            {formValues.agreement && formValues.agreement.value && (
                <Flex mt={theme.spacing[6]} alignItems="center">
                    <Icon
                        icon="document"
                        width={10}
                        height={12}
                        color={theme.blueMedium}
                        style={{ marginRight: theme.spacing[2] }}
                    />
                    <Link
                        onClick={toogleAllTermsOpen}
                        fontSize="XXS"
                        color={theme.newBlue}
                        underlineColor={theme.newBlue}
                    >
                        Click here to review your terms
                    </Link>
                </Flex>
            )}
            {allTermsModalOpen && (
                <AllTermsModal
                    feeFrequencyOptions={feeFrequencyOptions}
                    feeDurationOptions={feeDurationOptions}
                    feeCommencementOptions={feeCommencementOptions}
                    feeDueOptions={feeDueOptions ? feeDueOptions : {}}
                    feeTypesOptions={feeTypesOptions}
                    fee={fee ? fee : {}}
                    onModalClose={toogleAllTermsOpen}
                    referenceMode
                />
            )}
            {/* {formValues.agreement && formValues.agreement.value &&(
                <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                    <InputContainer
                        triggerShowLabel={formValues.authorizedSignatureRequired && (formValues.authorizedSignatureRequired.value !== null) ? "show" : "hide"}
                        showLabelOnValue
                    >
                        <Select
                            options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                            onChange={option =>
                                onChange({
                                    target: {
                                        name: "authorizedSignatureRequired",
                                        value: option,
                                    }
                                })
                            }
                            styles={selectStyle}
                            name="authorizedSignatureRequired"
                            placeholder="Do you require an authorized signatory for your referral?*"
                            value={formValues.authorizedSignatureRequired}
                        />
                        <InputLabel>Do you require an authorized signatory for your referral?*</InputLabel>
                    </InputContainer>
                </Flex>
            )}
            {formValues.agreement && formValues.agreement.value && (
                <Flex mt={theme.spacing[4]}>
                    <Text
                        style={{ whiteSpace: "break-spaces" }}
                        color={theme.taupeGray}
                        fontSize="XXS"
                    >
                        {`Sometimes the individual with who you exchange a referral may not have the authority to agree to your referral fee on behalf of their organization. If this is of concern, you should require an "authorized signatory".  

An authorized signatory is an officer or representative vested (explicitly, implicitly, or through conduct) with the powers to commit the authorizing organization to a binding agreement.

If you are requiring an authorized signatory from the receiving party for your referral you too must provide your authorized signatory to them as well. 

If you are an Authorized Signatory for your organization you will simply select "I am the Authorized Signatory" on the next step.`}
                    </Text>
                </Flex>
            )} */}
            {/* {formValues.authorizedSignatureRequired && formValues.authorizedSignatureRequired.value && (
                <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                    <InputContainer
                        triggerShowLabel={formValues.referralFee && formValues.referralFee.value ? "show" : "hide"}
                        showLabelOnValue
                    >
                        <Select
                            options={[{label: 'Yes', value: true}, {label: 'No', value: false}]}
                            onChange={option =>
                                onChange({
                                    target: {
                                        name: "userIsAuthorizedSignatory",
                                        value: option,
                                    }
                                })
                            }
                            styles={selectStyle}
                            name="userIsAuthorizedSignatory"
                            placeholder="Are you an authorized signatory for your company/organization?*"
                            value={formValues.userIsAuthorizedSignatory}
                        />
                    </InputContainer>
                </Flex>
            )} */}
        </>
    );
}

export default Agreenment;
