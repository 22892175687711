import {
    FETCH_BIGBOARD_ISLOADING,
    FETCH_BIGBOARD_SUCCESS,
    FETCH_BIGBOARD_FAIL,
    BIGBOARD_UPDATE_FILTERS,
    BIGBOARD_RESET_FILTERS,
    BIGBOARD_SET_PAGE,
    BIGBOARD_SET_REFERRAL_POPUP,
    BIGBOARD_REMOVE_REFERRAL
} from "../../constants";
import { push } from "connected-react-router";
import { handleError } from "../modal";
import { getQueryDateObject } from "../shared";


const fetchBigBoard = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(bigBoardIsLoading(true));
            const bigBoard = getState().main.ui.view.bigBoard;

            const { filters, datesOptions } = bigBoard;

            if (filters.referralCode) {
                dispatch(push(`/referral/${filters.referralCode}`));
                dispatch(bigBoardUpdateFilters({ referralCode: "" }, false));
            }

            // Filter false, null, undefined, empty string, empty array
            const currentFilters = Object.entries(filters).reduce(
                (acc, [key, value]) =>
                    !!value || key === "from" // Value exists // Form is exception because it can be zero
                        ? { ...acc, [key]: value }
                        : acc,
                {}
            );
            // If profession key exists, then we convert it into an array with only the values, deleting the keys
            if (currentFilters.profession) {
                currentFilters.profession = currentFilters.profession.map(
                    ({ value }) => value
                );
            }

            if (currentFilters.date) {
                currentFilters.date = getQueryDateObject(
                    datesOptions,
                    currentFilters.date.id
                );
            }
            const response = await cloudfunctions.fetchBigBoard(currentFilters);

            if (response && response.data) {
                let { data } = response && response.data;
                let hasMore = false;
                data = Object.values(data)
                hasMore = data.length === filters.size;
                // If the status filter is not active, we remove the canceled referrals
                if (!currentFilters.status) {
                    data = data.filter(item => item.status !== "CANCELED");
                }
                dispatch({
                    type: FETCH_BIGBOARD_SUCCESS,
                    payload: {
                        data,
                        hasMore
                    }
                });
            } else {
                dispatch({
                    type: FETCH_BIGBOARD_SUCCESS,
                    payload: {
                        data: [],
                        hasMore: false
                    }
                });
            }

            return true;
        } catch (error) {
            console.error("Fetch OP Feed", error);
            dispatch(handleError(error));
            dispatch({
                type: FETCH_BIGBOARD_FAIL,
                payload: {
                    errors: error
                }
            });
            return false;
        }
    };
};

const bigBoardIsLoading = isLoading => ({
    type: FETCH_BIGBOARD_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

const bigBoardResetFilters = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: BIGBOARD_RESET_FILTERS,
        });
        dispatch(fetchBigBoard());
    };
}

const bigBoardUpdateFilters = (filters, fetchAfterUpdateFilter = true) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: BIGBOARD_UPDATE_FILTERS,
            payload: {
                filters
            }
        });
        if (fetchAfterUpdateFilter) dispatch(fetchBigBoard());
    };
};

const setBigBoardPage = page => ({
    type: BIGBOARD_SET_PAGE,
    payload: {
        page
    }
});

const setOpenReferralPop = openReferralPopup => ({
    type: BIGBOARD_SET_REFERRAL_POPUP,
    payload: {
        openReferralPopup
    }
})

const removeReferralFromBigBoard = referralCode => ({
    type: BIGBOARD_REMOVE_REFERRAL,
    payload: {
        referralCode
    }
})

export {
    bigBoardUpdateFilters,
    fetchBigBoard,
    bigBoardIsLoading,
    bigBoardResetFilters,
    setOpenReferralPop,
    removeReferralFromBigBoard,
    setBigBoardPage
};
