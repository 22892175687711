import React, { Component } from "react";
import { Flex, Text, Box } from "rebass";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { currentSelectedTab } from "../../../../../../redux/actions/creators/navbar";
import {
    BottomContainer,
    NavbarItem,
} from "./bottomNavBar-styles";
import { withRouter } from "react-router-dom";
import { Icon } from "../../../../../ui/presentation/atoms/shared/Icon";
import ChatIcon from "../../../../../../assets/svg/ChatIcon";
import NotificationIcon from "../../../../../../assets/svg/NotificationIcon";
import { withTheme } from "styled-components";
import LayoutBusiness from "../../../../../../business/global";
import PopupModal from "../../../../../ui/presentation/atoms/shared/PopupBlurModal";
import SendInvite from "../../../../../ui/presentation/views/Settings/Invites/SendInvite";

const initialState = {
    profileMenuOpen: false,
    notificationMenuOpen: false,
    pending_invites: [],
    inviteAndEarnModalOpen: false,
};

class MobileNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidUpdate = prevProps => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setInitialCurrentTab(this.props.location.pathname);
        }
    };

    setInitialCurrentTab = pathname => {
        const newSelectedTabOption = LayoutBusiness.getCurrentTab(
            this.props.tabsOptionsMobile,
            pathname,
            this.props.userName
        );

        this.props.currentSelectedTab(newSelectedTabOption);
    };

    onClickProfile = () => {
        const profileMenuOpen = !this.state.profileMenuOpen;
        this.setState({ profileMenuOpen: profileMenuOpen });
    };

    onClickTab = tab => {
        const {
            currentSelectedTab,
            userTypeValidForSendReferrals
        } = this.props;

        let url = tab.url;
        let enable = true;
        if (url === "/referrals" && !userTypeValidForSendReferrals)
            enable = false;
        if (enable) {
            currentSelectedTab(tab.text);
            this.props.history.push(url);
        }
    };

    getItemIcon = item => {
        const {
            theme,
            userTypeValidForSendReferrals,
            chatNewMessages,
            turnOnBell,
            moreOptionsMenuOpen
        } = this.props;
        const text = item && item.text;

        let optionIcon = false;
        const props = {
            width: "20px",
            height: "20px",
            color:
                item && item.selected && !moreOptionsMenuOpen
                    ? theme.newBlue
                    : theme.darkGray
        };

        const chatHaveNewMessages =
            chatNewMessages && Object.values(chatNewMessages).length > 0;
        const { pathname } = this.props.history.location;

        switch (text && text.toUpperCase()) {
            case "HOME": {
                optionIcon = <Icon icon="home" {...props} />;
                break;
            }
            case "NEWS": {
                optionIcon = <Icon icon="news" {...props} />;
                break;
            }
            case "NOTIFICATIONS": {
                optionIcon = (
                    <NotificationIcon enabled={turnOnBell} {...props} />
                );
                break;
            }
            case "MY OPS":
            case "OPPORTUNITIES": {
                const enabled = userTypeValidForSendReferrals;
                optionIcon = (
                    <Icon
                        icon="myOps"
                        {...props}
                        color={enabled ? props.color : theme.greyLight2}
                    />
                );
                break;
            }
            case "MY NETWORK": {
                optionIcon = <Icon icon="group" width={20} {...props} />;
                break;
            }
            case "CHAT": {
                optionIcon = (
                    <ChatIcon
                        {...props}
                        enabled={pathname !== "/chat" && chatHaveNewMessages}
                    />
                );
                break;
            }
            default:
                optionIcon = false;
                break;
        }
        return optionIcon;
    };


    render() {
        const {
            theme,
            moreOptionsMenuOpen,
            tabsOptionsMobile,
            userTypeValidForSendReferrals,
            userTypeValidForWriteArticles
        } = this.props;

        const { inviteAndEarnModalOpen } = this.state

        const homeOption = tabsOptionsMobile[0];
        const homeIcon = this.getItemIcon(homeOption);

        const referralsOption = tabsOptionsMobile[1];
        const referralsIcon = this.getItemIcon(referralsOption);
        const myOpsOption = tabsOptionsMobile[2];
        const myOpsIcon = this.getItemIcon(myOpsOption);
        const notificationsOption = tabsOptionsMobile[3];
        const notificationsIcon = this.getItemIcon(notificationsOption);

        const chatOption = tabsOptionsMobile[4];
        const chatIcon = this.getItemIcon(chatOption);
        return [
            <PopupModal
                open={inviteAndEarnModalOpen}
                handleClose={() =>
                    this.setState({ inviteAndEarnModalOpen: false })
                }
            >
                <Box
                    p={["2.5em 2em"]}
                >
                    <SendInvite isModalInvite />
                </Box>
            </PopupModal>,
            <BottomContainer
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                className="disable-select"
            >
                <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                    width={[1]}
                    flexWrap="nowrap"
                >
                    <NavbarItem
                        className="disable-select"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            return this.onClickTab(homeOption);
                        }}
                        selected={!moreOptionsMenuOpen && homeOption.selected}
                    >
                        {homeIcon ? homeIcon : null}
                        <Text mt={theme.spacing[1]}>{homeOption.text}</Text>
                    </NavbarItem>
                    <NavbarItem
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            return this.onClickTab(referralsOption);
                        }}
                        selected={
                            !moreOptionsMenuOpen && referralsOption.selected
                        }
                    >
                        {referralsIcon ? referralsIcon : null}
                        <Text mt={theme.spacing[1]}>
                            {referralsOption.text}
                        </Text>
                    </NavbarItem>
                    <NavbarItem
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            return this.onClickTab(myOpsOption);
                        }}
                        selected={
                            !moreOptionsMenuOpen && myOpsOption.selected
                        }
                    >
                        {myOpsIcon ? myOpsIcon : null}
                        <Text mt={theme.spacing[1]}>
                            {myOpsOption.text}
                        </Text>
                    </NavbarItem>
                    {/* <NavbarItem
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onMouseDown={this.onClickMoreOptions}
                        selected={moreOptionsMenuOpen}
                    >
                        <AddIcon
                            width={22}
                            height={22}
                            color={
                                moreOptionsMenuOpen
                                    ? theme.newBlue
                                    : theme.darkGray
                            }
                        />
                        <Text mt={theme.spacing[1]}>Create</Text>
                    </NavbarItem> */}

                    <NavbarItem
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            return this.onClickTab(notificationsOption);
                        }}
                        selected={
                            !moreOptionsMenuOpen && notificationsOption.selected
                        }
                    >
                        {notificationsIcon ? notificationsIcon : null}
                        <Text mt={theme.spacing[1]}>
                            {notificationsOption.text}
                        </Text>
                    </NavbarItem>
                    <NavbarItem
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                            return this.onClickTab(chatOption);
                        }}
                        selected={!moreOptionsMenuOpen && chatOption.selected}
                    >
                        {chatIcon ? chatIcon : null}
                        <Text mt={theme.spacing[1]}>{chatOption.text}</Text>
                    </NavbarItem>
                </Flex>
            </BottomContainer>
        ];
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.main.ui.view.profile.accept.loading,
        userName:
            state.main.user && state.main.user.profile.userName
                ? state.main.user.profile.userName
                : null,
        user:
            state.main.user && state.main.user.profile
                ? state.main.user.profile
                : {},
        pending_invites:
            state.main.ui.global &&
            state.main.ui.global.navbar &&
            state.main.ui.global.navbar.pending_invites
                ? state.main.ui.global.navbar.pending_invites
                : null,
        tabsOptionsMobile: state.main.ui.global.navbar.tabsOptionsMobile,
        moreOptionsMenuOpen: state.main.ui.global.navbar.moreOptionsMenuOpen,
        userTypeValidForSendReferrals:
            state.main.user && state.main.user.userTypeValidForSendReferrals,
        userTypeValidForWriteArticles:
            state.main.user && state.main.user.userTypeValidForWriteArticles,
        chatNewMessages: state.main.ui.global.navbar.chatNewMessages,
        turnOnBell: state.main.ui.global.notifications.turnOnBell
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            currentSelectedTab
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTheme(MobileNavbar))
);
