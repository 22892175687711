import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { OPPORTUNITY_CREATION_STEPS } from '../../../constants';
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE],
        allState: state.main.ui.view.opportunityCreationFlow.stepData,
        professions: state.main.ui.global.statics.userTypes || [],
       // opType: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP]
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE, data)),
    };
};

const Receiver = connect(mapStateToProps, mapDispatchToProps)(withTranslation("sendReferral")(View));


export { Receiver };