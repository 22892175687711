import React, { Fragment, useState } from "react";
import { Box, Flex } from "rebass";
import Card from "../Card";
import { withTheme } from "styled-components";
import { RightHandTitleLink, Copyright, AppLink } from "./styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Link from "../../Link";
import HorizontalLine from "../HorizontalLine";
import { ReactComponent as DownloadAppStore } from "../../../../../../assets/svg/DownloadAppStore.svg";
import { ReactComponent as DownloadPlayStore } from "../../../../../../assets/svg/DownloadPlayStore.svg";
import CollapsableSection from "../CollapsableSection";
import { Icon } from "../Icon";
import { Text } from "../Text";
import packageJson from "../../../../../../../package.json";

const RightHandLinks = props => {
    const { t, theme, isSigninPage } = props;
    const flexBasisShouldBe50 = window.innerWidth > 768 && window.innerWidth < 1450;
    const currentYear = new Date().getFullYear();

    const sections = [
        {
            key: "aboutInfo",
            title: t("about"),
            links: [
                {
                    key: "quickStart",
                    title: t("quickStart"),
                    href:
                        "https://support.nuop.com/portal/en/kb/articles/tips-to-start-fast"
                },
                {
                    key: "referralExchange",
                    title: t("referralExchange"),
                    href: "https://nuop.com/referral-exchange"
                },
                {
                    key: "realEstateReferrals",
                    title: t("realEstateReferrals"),
                    href: "https://nuop.com/real-estate-referrals"
                }
            ]
        },
        {
            key: "h&s",
            title: t("h&s"),
            links: [
                {
                    key: "contact",
                    title: t("contact"),
                    href: "https://nuop.com/contact-us/"
                },
                {
                    key: "support",
                    title: t("support"),
                    href: "https://support.nuop.com/portal/en/home"
                }
            ]
        },
        {
            key: "legalTerms",
            title: t("legalTerms"),
            links: [
                {
                    key: "tac",
                    title: t("tac"),
                    href: "https://site.nuop.com/terms-and-conditions/"
                },
                {
                    key: "privacyPolicy",
                    title: t("privacyPolicy"),
                    href: "https://site.nuop.com/privacy-policy/"
                },
                {
                    key: "eula",
                    title: "EULA",
                    href: "https://site.nuop.com/end-user-license-agreement/"
                },

                {
                    key: "coc",
                    title: t("coc"),
                    href: "https://site.nuop.com/code-of-conduct/"
                },
                {
                    key: "cookies",
                    title: t("cookiePolicy"),
                    href: "https://site.nuop.com/cookie-policy/"
                },
                {
                    key: "doNotSellMyPersonalInformation",
                    title: t("doNotSellMyPersonalInformation"),
                    href:
                        "https://site.nuop.com/do-not-sell-my-personal-information/"
                },
                {
                    key: "statementOfAccessibility",
                    title: t("statementOfAccessibility"),
                    href: "https://site.nuop.com/statement-of-accessibility/"
                }
            ]
        }
        /*         {
            key: "pricing",
            title: t("pricing"),
            href: "https://nuop.com/site/pricing/"
        },
        {
            key: "story",
            title: t("story"),
            href: "https://nuop.com/site/our-story/"
        },
        {
            key: "howItWorks",
            title: t("howItWorks"),
            links: [
                {
                    key: "takeATour",
                    title: t("takeATour"),
                    href: "https://nuop.com/site/"
                },
                {
                    key: "referralExchange",
                    title: t("referralExchange"),
                    href: "https://nuop.com/site/referral-exchange/"
                },
                {
                    key: "pathToSuccess",
                    title: t("pathToSuccess"),
                    href: "https://nuop.com/site/the-path-to-success/"
                }
            ]
        },
        {
            key: "resources",
            title: t("resources"),
            links: [
                {
                    key: "blog",
                    title: t("blog"),
                    href: "https://nuop.com/site/auto-draft-4/nuop-blog/"
                },
                {
                    key: "ambassadorProgram",
                    title: t("ambassadorProgram"),
                    href: "https://nuop.com/site/ambassador-program/"
                }
            ]
        } 
        ,*/
    ];

    const socialMediaLinks = [
        {
            key: "linkedin",
            title: "LinkedIn",
            href: "https://www.linkedin.com/company/mynuop/",
            icon: "linkedin"
        },
        {
            key: "facebook",
            title: "Facebook",
            href: "https://www.facebook.com/mynuop",
            icon:  "facebook"
        },
        {
            key: "twitter",
            title: "Twitter",
            href: "https://twitter.com/mynuop",
            icon:  "twitter"
        },
        {
            key: "instagram",
            title: "Instagram",
            href: "https://www.instagram.com/mynuop/",
            icon:  "instagram"
        },
        {
            key: "youtube",
            title: "YouTube",
            href: "https://www.youtube.com/channel/UCRWLILThYEaq74ohzx7qHvg",
            icon:  "youtube"
        }
    ];

    const collapsibleInitialState = sections
        .filter(section => Array.isArray(section.links))
        .reduce((acc, curr) => ({ ...acc, [curr.key]: true }), {});

    const [collapsibleSections, setCollapsibleSections] = useState(
        collapsibleInitialState
    );

    const onToogleCollapse = key =>
        setCollapsibleSections({
            ...collapsibleInitialState,
            [key]: !collapsibleSections[key]
        });

    const titleStyles = {
        fontSize: theme.mobileDevice ? "12px" : "14px",
        marginRight: "0.5em",
        // marginLeft: "0.5em",
        cursor: "pointer",
        color: isSigninPage ?  theme.white : theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: 0,
    };

    const renderCollapsableSection = ({ key, title, links }) => (
        <CollapsableSection
            key={key}
            onClickCollapse={() => onToogleCollapse(key)}
            collapsed={collapsibleSections[key]}
            title={title}
            flexJustify={"flex-start"}
            titleStyles={{
                ...titleStyles
            }}
            onClick={e => {}}
            arrowDimensions={{ height: 9, width: 10 }}
            arrowColor={isSigninPage ? theme.white : theme.coolBlack}
            showArrow
            rootStyles={{
                marginBottom: theme.spacing[4],
                flexBasis: isSigninPage ? flexBasisShouldBe50 ? "50%" : "33%" : null
            }}
        >
            {links.map(({ key, title, href }) => (
                <Box mb={theme.spacing[2]} key={key}>
                    <Link
                        color={isSigninPage ? theme.white : theme.text}
                        underlineColor={
                            isSigninPage ? theme.white : theme.newBlue
                        }
                        key={key}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        fontSize="XXXS"
                    >
                        {title}
                    </Link>
                </Box>
            ))}
        </CollapsableSection>
    );

    const renderLink = ({ key, title, href }) => (
        <RightHandTitleLink
            key={key}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            isSigninPage={isSigninPage}
        >
            {title}
        </RightHandTitleLink>
    );

    const renderSections = () =>
        sections.map(section =>
            section.links
                ? renderCollapsableSection(section)
                : renderLink(section)
        );

    const renderDownloadApp = () => (
        <Flex
            justifyContent={"center"}
            my={theme.spacing[4]}
            pr={theme.spacing[2]}
        >
            <AppLink
                isSigninPage={isSigninPage}
                href="https://play.google.com/store/apps/details?id=app.nuop.com"
                target="_blank"
                rel="noopener noreferer"
            >
                <DownloadPlayStore />
            </AppLink>
            <AppLink
                isSigninPage={isSigninPage}
                href="https://support.nuop.com/portal/en/kb/articles/how-to-install-nuop-to-your-mobile-device"
                target="_blank"
                rel="noopener noreferer"
            >
                <DownloadAppStore />
            </AppLink> 
        </Flex>
    );

    const renderCopyright = () => (
        <>
            <Box>
                {!isSigninPage && (
                    <Text
                        color={"grey"}
                        fontSize="XXXS"
                        style={{ display: "block", marginBottom: theme.spacing[4] }}
                    >
                        Version {packageJson.version} -{" "}
                        {currentYear}
                    </Text>
                )}
                {/** eslint-disable-next-line */}
                <Copyright
                    isSigninPage={isSigninPage}
                    ariaLabel={t("copyright", { currentYear })}
                    role="img"
                >
                    {t("copyright", { currentYear })}
                </Copyright>
            </Box>
        </>
    );

    const renderSeparator = (
        <HorizontalLine
            style={{ marginBottom: theme.spacing[6] }}
        ></HorizontalLine>
    );

    const renderSocialMediaLinks = () => (
        <Flex flexDirection={"column"} mt={theme.spacing[6]}>
            <Text
                color={theme.white}
                fontSize="XXXS"
                style={{ marginRight: theme.spacing[4] }}
            >
                {t("findUsOn")}:{" "}
            </Text>
            <Flex mt={theme.spacing[5]}>
                {socialMediaLinks.map((link, index) => (
                    <a
                        key={index}
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={link.title}
                        style={{ marginRight: theme.spacing[4] }}
                    >
                        <Icon icon={link.icon} color={theme.white} height={18} width={18} />
                    </a>
                ))}
            </Flex>
        </Flex>
    );


    return isSigninPage ? (
        <Flex
            bg={"#000"}
            mt={theme.spacing[6]}
            flexDirection={"column"}
            p={`${theme.spacing[10]} ${theme.spacing[5]}`}
        >
            {renderSections()}
            {renderDownloadApp()}
            {renderSeparator}
            {renderCopyright()}
            {renderSocialMediaLinks()}
        </Flex>
    ) : (
        <Card padding="20px">
            {renderSections()}
            {renderDownloadApp()}
            {renderCopyright()}
        </Card>
    );
};

const ViewTranslated = withTranslation("righthandLinks")(RightHandLinks);

export default withRouter(withTheme(ViewTranslated));
