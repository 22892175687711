import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { selectStyle } from "../../../components";
import {
    InputContainer,
    TextArea
} from "../../../../Settings/common/Components";
import { InputCounter } from "../../../../Settings/InformationAccount/styles";
import { Box, Flex } from "rebass";
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const JobTitle = ({
    titleStyles,
    theme,
    professionOptions,
    handleChange,
    values,
    t,
    errors
}) => {
    const key = "jobTitle_";
    const [isCollapsed, setIsCollapsed] = useState(false);
    return (
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {
                    setIsCollapsed(prev => !prev);
                }}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Job Title
                            </span>
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <Flex p={`${theme.spacing[5]} 0`}>
                    <InputContainer>
                        <TextArea
                            style={{ resize: "vertical" }}
                            type="text"
                            name="jobTitle"
                            value={values.jobTitle}
                            onChange={handleChange}
                            maxLength="30"
                            minLength="1"
                            placeholder="State the job title in less than 30 characters*"
                            error={!!errors.jobTitle}
                        />
                        {!!values.jobTitle &&
                            !!values.jobTitle.length && (
                                <InputCounter>
                                    {values.jobTitle.length}/30
                                </InputCounter>
                            )}
                    </InputContainer>
                </Flex>
            </CollapsableSection>
        </Box>
    );
};

export default JobTitle;
