import {
    OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    OPPORTUNITY_FLOW_RESET,
    SAVE_REFERRAL_DRAFT,
    LOAD_REFERRAL_DRAFT,
    CURRENT_REFERRAL_LOADING,
    CURRENT_REFERRAL_SUCCESS,
    CURRENT_REFERRAL_ERROR,
    NEW_CONTACT_SAVED,
    CONTACT_IS_LOADING,
    CONTACT_ERROR,
    CONTACT_SAVED,
    CANCEL_CONTACT_ERROR
  } from '../../constants';
  import { cleaningObject, convertToTimeStamp  } from '../../../../libs/Utils';
  import ReferralBusiness from '../../../../business/referral';


export const sendPastferral = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
  
      try {
        dispatch({ type: CURRENT_REFERRAL_LOADING })
        const me =           getState().main.user.profile
        const meId =         getState().main.user.uid
        const { address, company_info, email, lastname, name, phone, state, type, avatar, userName, subscription } = me && me
        const { stepData, draftId } = getState().main.ui.view.opportunityCreationFlow
        const {
          REFERRAL_PAST_COMPLETE_INFORMATION, 
          REFERRAL_PRIVACY_STEP, 
          REFERRAL_PAST_CANDIDATE
        } = stepData && stepData;
  

        const isRemote = REFERRAL_PAST_COMPLETE_INFORMATION.locationIsRemote.value !== 'PARTICULAR'
      
        const formValues =   JSON.parse(JSON.stringify(REFERRAL_PAST_COMPLETE_INFORMATION));
        const {
          amount,
          fee,
          credit,
          clientInformation,
          clientState,
          clientStreetAddress,
          homeSaleContingency,
          lotSizeUnit,
          maxLotSize,
          minBeds,
          minLotSize,
          minUnits,
          pets,
          professionDescription,
          acres,
          baths,
          beds,
          clientType,
          estimatedPrice,
          financingInfo,
          maxAcres,
          maxPrice,
          maxSize,
          maxSquareFeet,
          maxUnits,
          minAcres,
          minPrice,
          minSize,
          minSquareFeet,
          propertySubType,
          propertyType,
          size,
          sizeUnit,
          squareFeet,
          units,
          retailSpace,
          clientCity,
          clientEmail,
          clientFirstName,
          clientLastName,
          clientMobileNumber,
          clientZipCode,
          partyConfirmation,
        } = formValues && formValues;

        const shouldSendRealEstate = (formValues.profession  && formValues.profession.value === '100') ? true :
        (REFERRAL_PAST_CANDIDATE.length === 1 && REFERRAL_PAST_CANDIDATE[0] && REFERRAL_PAST_CANDIDATE[0].user_type === '100') ? true
        : false
        
        let feeData = ReferralBusiness.getReferralFeeToFlow(formValues);
      
        const allPropertiesValues = {
          acres:               acres ? acres : null,
          baths:               baths ? parseInt(baths) : null, // POSITIVE NUMBER
          beds:                beds ? parseInt(beds) : null,
          credit:              credit ? credit.value : null, // STRING VALUE
          estimatedPrice:      estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
          financingInfo:       financingInfo ? financingInfo.value : null,
          homeSaleContingency: homeSaleContingency ? homeSaleContingency.value : null,  // STRING VALUE
          lotSizeUnit:         minLotSize ? (minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase() === "ACRE") ? "ACRES" : minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase() : null, // STRING VALUE
          maxAcres:            maxAcres ? parseInt(maxAcres) : null, // POSITIVE NUMBER
          maxLotSize:          maxLotSize ? parseInt(maxLotSize.value) : null, // POSITIVE NUMBER
          maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : null, // POSITIVE NUMBER
          minPrice:            minPrice ? parseInt(minPrice) : null, // POSITIVE NUMBER
          maxSquareFeet:       maxSquareFeet ? parseInt(maxSquareFeet) : null,
          maxUnits:            maxUnits ? maxUnits.value : null, // POSITIVE NUMBER
          minAcres:            minAcres ? parseInt(minAcres) : null, // POSITIVE NUMBER
          minBeds:             minBeds ? parseInt(minBeds) : null, // POSITIVE NUMBER
          minLotSize:          minLotSize ? parseInt(minLotSize.value) : null, // POSITIVE NUMBER
          minSize:             minSize ? parseInt(minSize) : null,
          minSquareFeet:       minSquareFeet ? parseInt(minSquareFeet) : null,
          minUnits:            minUnits ? minUnits.value : null, // POSITIVE NUMBER
          pets:                (pets && pets.label === 'Yes') ? 'YES' : (pets && pets.label === 'Not sure') ? 'NOT_SURE' : 'NO', // STRING VALUE
          propertySubType:     propertySubType ? propertySubType.value : null,
          propertyType:        propertyType ? propertyType.value : null,
          size:                (size) ? parseInt(size) : null,  // POSITIVE NUMBER
          sizeUnit:            (maxSize || size) ? sizeUnit : null,
          squareFeet:          squareFeet ? parseInt(squareFeet) : null, // POSITIVE NUMBER
          timePeriod:          (retailSpace.value === 'SQUARE_FEET_PER_MONTH') ? 'MONTH' : 'YEAR',
          units:               (minUnits || maxUnits) ? (minUnits.value || maxUnits.value) : null, // POSITIVE NUMBER
        }
  
       
       
       const toMember = (REFERRAL_PAST_CANDIDATE.length > 0 && REFERRAL_PAST_CANDIDATE[0] !== undefined) ? REFERRAL_PAST_CANDIDATE.filter( i => i.user_type ).map( transform => {
        return {
                "address":transform.locationAddress,
                "avatar": transform.avatar,
                "companyName": transform.company_name,
                "email":transform.email,
                "lastname":transform.last_name,
                "name":transform.first_name,
                "phone":transform.phone,
                "plan":transform.subscription.plan,
                "state":"",
                "type": parseInt(transform.user_type),
                "uid":transform.uid,
                "userName": transform.userName
            }
    }) : [];
    
    const toContacts =  REFERRAL_PAST_CANDIDATE.length > 0 && REFERRAL_PAST_CANDIDATE[0] ? REFERRAL_PAST_CANDIDATE.filter(c =>  !c.user_type).map( contactID => {
      return {
         first_name: contactID.first_name,
         last_name: contactID.last_name,
         email: contactID.email ? contactID.email : "",
         phone: contactID.phone ? contactID.mobile_phone : "",
         contact_id: contactID.contact_id
     }
   }): []
  
      
  const locationData = {
    coords:                (formValues.address) ? [formValues.address.lat, formValues.address.lng] : [],
    fullAddress:           formValues.address ? formValues.address.description: '',
    state:                 formValues.address ? formValues.address.state : "",
    stateShort:            formValues.address ? formValues.address.stateShort : "",
    subAdministrativeArea: formValues.address ? formValues.address.subAdministrativeArea : "",
    zipcode:               formValues.address ? formValues.address.zipCode : "",
  }

     const getClientInfo = clientInformation ? cleaningObject({ 
      avatar:       clientInformation.avatar ?  clientInformation.avatar: null,
      address:      clientInformation.locationAddress,
      company_name: clientInformation.company_name,
      contact_id:   clientInformation.contact_id,
      firstName:    clientInformation.first_name,
      email:        clientInformation.email,
      lastName:     clientInformation.last_name,
      phone:        clientInformation.mobile_phone,
      status:       clientInformation.status,
      uid:          clientInformation.uid,
    }) : null;
      
      const pastReferralPayload = {
          description: REFERRAL_PAST_COMPLETE_INFORMATION.description,
          fee: feeData,
          type: "PAST",
          location: !isRemote ? locationData : null,
          isRemote: isRemote,
          client: getClientInfo,
          from: {
            address,
            avatar,
            company_info,
            email,
            lastname,
            name,
            phone,
            plan: subscription.plan,
            state,
            type: parseInt(type),
            uid: meId,
            userName,
          },
          fromId: meId,
          timingInfo:{
              exchangeDate: convertToTimeStamp(REFERRAL_PAST_COMPLETE_INFORMATION.lastContact),
              clientSource:REFERRAL_PAST_COMPLETE_INFORMATION.clientSource.key
           },
           roleInOpportunity:REFERRAL_PAST_COMPLETE_INFORMATION.referralCreator.formikKey,
           finalStatus:REFERRAL_PAST_COMPLETE_INFORMATION.pastReferralStatus.key,
           to:toMember,
           toContact: toContacts,
           "visibility": "PUBLIC",
           "origin":"PWA",
           realEstateDetails:  shouldSendRealEstate ? cleaningObject(allPropertiesValues) : null,
           profession: (formValues.profession  && formValues.profession.value)? parseInt(formValues.profession.value) : null,
           partyConfirmation,
          // amount: (REFERRAL_PAST_COMPLETE_INFORMATION.pastReferralStatus.key === 'COMPLETED') ? parseInt(2000) : null,
           //fee: (REFERRAL_PAST_COMPLETE_INFORMATION.pastReferralStatus.key === 'COMPLETED') ? parseInt(12) : null
      }
       
    
      
  
        /***  TAKING FEE DATA AND BUILDING THE FEE DATA OBJECT TO ENDPOINT ***/
      
        /*** SELECTING CANDIDATES, DIRECT OR FIRST TO COME OR ALL */
        
        let response = await cloudfunctions.sendPastReferral(cleaningObject(pastReferralPayload))  
        let doWeGotAmountValue = amount.length > 1;
        let doWeHaveFeeValue =   fee.length > 1;

         
          if (response.status === 200 && REFERRAL_PAST_COMPLETE_INFORMATION.pastReferralStatus.key === 'COMPLETED' && 
              ( doWeGotAmountValue || doWeHaveFeeValue )
          ) {
              await cloudfunctions.updateRevenue(cleaningObject(
                {
                  opportunity_id: response.data.id,
                  opportunity_code: response.data.code,
                  profiter_user: meId,
                  receiver_user: toMember.length > 0 ? toMember[0].uid : toContacts[0].contact_id,
                  amount: doWeGotAmountValue ?  parseInt(amount.substring(1)) : null,
                  opportunity_model: '',
                  fee: doWeHaveFeeValue ?  parseInt(fee.substring(1)) : null,
                  status: "COMPLETED"
             })
             )
          }

          // Remove draft if it exists
          if (draftId) {
              await firebase.deleteDraftReferral(meId, draftId);
          }
  
  

        
        dispatch({ type: CURRENT_REFERRAL_SUCCESS })
  
      } catch (error) {
        console.error('Referral Current: something went wrong', error)
        dispatch({ type: CURRENT_REFERRAL_ERROR });
      }
  
  
  
    }
  }