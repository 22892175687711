import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Flex } from "rebass";
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";
import PopupModal from "../../PopupBlurModal";
import { Icon } from "../../Icon";
import { TextArea } from "../../../../views/Settings/common/Components";
import { InputCounter } from "../../../../views/Settings/InformationAccount/styles";
import SquareButton from "../../Buttons/SquareButton2";
import SquareOutlinedButton from "../../Buttons/SquareOutlinedButton";
import {
    addEndorsement,
    updateEndorsement
} from "../../../../../../../redux/actions/creators/profile";
import { EngagementModalTitle as Title, EngagementModalDescription as Description } from "../../Text";
import EndorsementSuccessMessage from "./EndorsementSuccessMessage";
import Utils from "../../../../../../../libs/Utils";


const EndorsementModal = ({
    t,
    theme,
    open,
    handleClose,
    addressedUser,
    endorsementToEdit,
    addEndorsement,
    updateEndorsement
}) => {
    const { full_name, name, first_name, lastname, uid, userName } = addressedUser;
     console.log('addressedUser', addressedUser)
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("");

    const fullName = full_name || `${name} ${lastname}`;
    const submitDisabled = !message.length;

    useEffect(() => {
        if (endorsementToEdit && endorsementToEdit.content) {
            setMessage(endorsementToEdit.content);
        }
        return () => {
            setShowSuccessMessage(false);
            setMessage("");
        };
    }, [open]);

    const onSave = async () => {
        setIsLoading(true);
        await addEndorsement(
            {
                addressed_user: uid,
                content: message
            },
            addressedUser
        );
        setShowSuccessMessage(true);
        setIsLoading(false);
    };

    const onUpdate = async () => {
        setIsLoading(true);
        await updateEndorsement({
            ...endorsementToEdit,
            content: message
        });
        setShowSuccessMessage(true);
        setIsLoading(false);
    };
    const getFirstName = () => {
        const firstName = first_name || name;
        // Add s to the end of the name if it ends with s
        return Utils.getNameWithAnSAtTheEnd(firstName);
    };

    const renderForm = () => (
        <Flex
            flexDirection="column"
            alignItems="center"
            p={[
                theme.spacing[5],
                theme.spacing[5],
                theme.spacing[10],
                theme.spacing[10]
            ]}
        >
            <Icon name="endorsement" color={theme.blueLight} size="3xl" />
            <Title
                dangerouslySetInnerHTML={{
                    __html: t(endorsementToEdit ? "title_edit" : "title", {
                        name: fullName
                    })
                }}
            />
            {!endorsementToEdit && (
                <Description
                    dangerouslySetInnerHTML={{
                        __html: t("description", {
                            name: first_name || name
                        })
                    }}
                />
            )}
            <Flex width={[1]} flexDirection="column">
                <TextArea
                    type="text"
                    name="description"
                    value={message}
                    onChange={ev => setMessage(ev.target.value)}
                    maxLength="5000"
                    rows={6}
                    style={{ resize: "vertical", height: "100px" }}
                    placeholder={t("placeholder")}
                />
                <Flex
                    mt={theme.spacing[2]}
                    justifyContent={"flex-end"}
                    style={{ minHeight: "24px" }}
                >
                    {!!message && !!message.length && (
                        <InputCounter>
                            {t("characters_left", {
                                charactersLeft: 5000 - message.length
                            })}
                        </InputCounter>
                    )}
                </Flex>
            </Flex>
            <Flex
                width={[1]}
                mt={[theme.spacing[8]]}
                justifyContent={"flex-end"}
            >
                <SquareOutlinedButton
                    onClick={handleClose}
                    color={theme.shamRockGreen}
                    text={t("cancel")}
                    fontSize="XS"
                    style={{ marginRight: theme.spacing[6] }}
                />
                <SquareButton
                    disabled={submitDisabled}
                    onClick={endorsementToEdit ? onUpdate : onSave}
                    bg={theme.shamRockGreen}
                    isLoading={isLoading}
                    fontSize="XS"
                    text={t("save")}
                />
            </Flex>
        </Flex>
    );

    return (
        <PopupModal
            open={open}
            handleClose={handleClose}
            width={[0.9, 0.9, 0.5, 0.3]}
        >
            {showSuccessMessage 
            ? (
                <EndorsementSuccessMessage
                    fullName={fullName}
                    firstName={getFirstName()}
                    userName={userName}
                    buttonLabel={t("success_button")}
                    onClick={handleClose}
                    isEdit={!!endorsementToEdit}
                    currentPlan={(addressedUser &&  addressedUser.suscription.plan)? addressedUser.suscription.plan : 'Essential'}
                />
            ) : (
                renderForm()
            )}
        </PopupModal>
    );
};

const mapStateToProps = (state, props) => ({
    // If the user is passed as a prop, use it, otherwise use the user from the store (will use the user from current profile)
    addressedUser: props.addressedUser
        ? props.addressedUser
        : state.main.ui.view.profile.user.data
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addEndorsement,
            updateEndorsement
        },
        dispatch
    );

const EndorsementModalComponent = withTheme(
    withTranslation("endorsementModal")(EndorsementModal)
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EndorsementModalComponent);
