import {
    combineReducers
} from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './user';
import uiReducer from './ui';
import communityReducer from './community'
const rootReducer = combineReducers({
    user: userReducer,
    ui: uiReducer,
    community: communityReducer,
})

export default (history) => combineReducers({
    router: connectRouter(history),
    main: rootReducer
});