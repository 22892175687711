import React, { useRef, useEffect } from "react";
import { Flex, Box } from "rebass";
import Avatar from "../../../../../ui/presentation/atoms/shared/AvatarCircle2";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { CardContainer, NameContainer } from "./styles";
import Card from "../../../../../ui/presentation/atoms/shared/Card";
import { Title } from "../../../../../ui/presentation/atoms/shared/Text";
import HorizontalLine from "../../../../../ui/presentation/atoms/shared/HorizontalLine";
import ProfileMenu from "../../ProfileMenu";
import SignOut from "../../SignOut";

const AvatarDropdown = props => {
    const { user, theme } = props;
    const wrapperRef = useRef(null);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
         
            props.toogleAvatarDropdown();
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    //  useOutsideAlerter(wrapperRef);
    const {avatar, verified} = user
    return [
        <CardContainer ref={wrapperRef}>
            <Card>
                <Flex
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    {/* <Avatar src={avatar} size={"61px"} verified={verified} /> */}
                    <NameContainer /*ml={["1em"]}*/>
                        <Title
                            style={{
                                maxWidth: "100%",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}
                        >{`${user.name} ${user.lastname}`}</Title>
                    </NameContainer>
                </Flex>
                <Box mt={["1em"]}>
                    <HorizontalLine light />
                </Box>
                <Box className="navbar-dropdown" mt={["1em"]}>
                    <ProfileMenu
                        user={user}
                        toogleAvatarDropdown={props.toogleAvatarDropdown}
                    />
                </Box>
                <Box mt={["1em"]}>
                    <HorizontalLine light />
                </Box>
                <Box className="navbar-dropdown" mt={["1em"]}>
                    <SignOut
                        email={user.email}
                        theme={theme}
                        signOut={props.signOut}
                    />
                </Box>
            </Card>
        </CardContainer>
    ];
};

export default withRouter(withTheme(AvatarDropdown));
