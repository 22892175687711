import styled from 'styled-components';
import ReferralBusiness from '../../../../../../business/referral'
import { SUBSCRIPTION_PLANS } from "../../../../../../assets/strings";
import { Description } from "../../../atoms/shared/Text";

export const CardContainer = styled.div`
    box-sizing: border-box;
    border: 0.5px solid rgba(172,172,172,0.5);
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 -5px 20px 2px rgba(0,0,0,0.1);
    padding: 12px;
    border-top: ${props => `7px solid ${props.theme.greenMedium}`};
`;

export const DataTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 21px;
    color: ${props => props.theme.coolBlack};
    margin-right: ${props => props.theme.mobileDevice ? '.5em' : '1em'};
    white-space: nowrap;
    min-width: 110px;
`;

export const DataValue = styled.div`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${props => props.theme.taupeGray};
    `;

export const DataPlan = styled.div`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-top: .25rem;
    color: ${({ theme, plan, date }) => ReferralBusiness.ableToClaimReferralByPlan(plan, SUBSCRIPTION_PLANS, date) ? theme.shamRockGreen : theme.taupeGray};
    `;

export const Desc = styled(Description)`
    color: ${props => props.theme.taupeGray};
    white-space: pre-wrap;
    overflow-wrap: break-word;
`;