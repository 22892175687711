import React from 'react'
import { Flex, Box } from 'rebass'
import { InputContainerVariation } from '../../ReferralPastSteps/ReferralPastCompleteInformation/PastTimingInfo/PastTimingInfo.styles'
import { Description } from '../../../../../atoms/shared/Text'
import CheckBox from '../../../../../atoms/shared/Inputs/Checkbox'
import { partyConfirmationOptions } from '../../../constants'
import ReactTooltip from 'react-tooltip'
import { Icon } from '../../../../../atoms/shared/Icon'



export const PartyConfirmation = ( {  formValues, theme, onChange, errors  }) => {
    const toolTip = `Requesting confirmation of a past op is important.  First of all, if you do not request confirmation the other party will never know that you created this past op.  So, notifying the other party about this past op is the first reason why you should select yes.  <br/>  If the other party does confirm this op the following actions will happen automatically: <br/> <li>Both parties will have the chance to post a review of one another once the op has reached completed status.</li><li>The data from this op will then be part of each Members analytics which helps the reputations of each Member throughout the NuOp community.</li> `;
    return (
        <InputContainerVariation style={{ 
            marginTop: theme.mobileDevice ? '16px' : '0px',
            marginBottom: theme.mobileDevice ? '24px' : '60px'
             }}>
            <Flex  flexDirection="column" >
            <Flex style={{ gap: 4 }} >
                 <Description style={{ marginBottom: '24px' }}>
                        Do you want NuOp to request confirmation from the other party to this op ?*
                  </Description>
                  <Box marginTop={2}>
                        <Icon
                            icon="info"
                            size="md"
                            color="#057AFF"
                            data-tip
                            data-for={'Op-info'}
                                    
                        />
                  </Box>
            </Flex>    
            
            {partyConfirmationOptions.map(({ value, label }, index) => (
                <Flex key={value} mb={index === 0 ? theme.spacing[6] : 0}>
                    <CheckBox
                        round
                        inverted
                        label={label}
                        onChange={ev =>
                            onChange({
                                target: {
                                    name: "partyConfirmation",
                                    value: value,
                                }
                            })
                        }
                        checkBoxColor={theme.blueMedium}
                        checked={value === formValues.partyConfirmation}
                    />
                </Flex>
            ))}
        </Flex>
                                <ReactTooltip
                                    id={'Op-info'}
                                    delayHide={200}
                                    place="bottom"
                                    type="light"
                                    effect="solid"
                                    className="form-tooltip"
                                >
                                    <div dangerouslySetInnerHTML={{ __html: toolTip }}/>
                                </ReactTooltip>

    </InputContainerVariation>
    )
}
