import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";
const mapStateToProps = state => {
    return {
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
