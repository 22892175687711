import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const containerStyle = {
    display: 'flex',
    flexBasis: '50%',
};

const inputStyle = {
    border: '1px solid #929292',
    marginRight: '2em',
    height: '48px',
    fontSize: '16px',
    width: '100%',
};

const PhoneNumberInput = ({
    value,
    onChange,
    name,
    useCustomStyle,
}) => {

    const emitChange = (value) => {
        onChange({ name, value: `+${value}` })
    }

    return (
        <PhoneInput
            containerStyle={containerStyle}
            inputStyle={{ ...inputStyle, ...useCustomStyle }}
            country={'us'}
            value={value}
            preferredCountries={['us']}
            countryCodeEditable={false}
            onChange={(value) => emitChange(value)}
        />
    );
};

export default PhoneNumberInput;
