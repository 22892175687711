import React from 'react'
import { Box, Flex } from 'rebass';
import { withTheme } from 'styled-components'
import { Icon } from '../../../ui/presentation/atoms/shared/Icon';
import Link from '../../../ui/presentation/atoms/Link';
import HorizontalLine from '../../../ui/presentation/atoms/shared/HorizontalLine';
import { Text } from '../../../ui/presentation/atoms/shared/Text';

const signUpListItems = [
    "Unlimited Referral Notifications",
    "Exchange Referrals",
    "News Feed Engagement",
    "Binding Referral Agmts",
    "Get Connected",
    "Chat Support"
];

const ListItem = ({ label, theme }) => (
    <Flex width="48%" mb={theme.spacing[4]}>
        <Icon icon="check-outlined"
            style={{ marginRight: theme.spacing[4] }}
        />
        <Text fontSize={"XXS"} color={theme.white}>
            {label}
        </Text>
    </Flex>
);

const SignUpRightContent = ({ theme, lastLoggedInUser }) => {

    return (
        <>
            {lastLoggedInUser && (
                <Box mb={theme.spacing[10]}>
                    <Text
                        fontSize={theme.mobileDevice ? "L" : "XXXL"}
                        fontWeight="bold"
                        color={theme.clearGray}
                    >
                        Welcome Back
                        {lastLoggedInUser &&
                            lastLoggedInUser.name &&
                            ` ${lastLoggedInUser.name}!`}
                    </Text>
                </Box>
            )}
            <Box>
                <Text
                    fontSize={theme.mobileDevice ? "XS" : "XXXL"}
                    fontWeight="bold"
                    color={theme.clearGray}
                >
                    More opportunity,
                    <br /> less time, less worry.
                </Text>
            </Box>
            <Box mt={theme.spacing[8]} width={[0.75]} m={"0 auto"}>
                <Text
                    fontSize={theme.mobileDevice ? "XS" : "S"}
                    color={theme.white}
                    lineHeight="S"
                >
                    When you Sign Up you automatically become an{" "}
                    <b>Essential Member.</b>
                </Text>
            </Box>
            <HorizontalLine style={{ margin: `${theme.spacing[10]} 0` }} />
            <Box>
                <Text fontSize={"XXS"} color={theme.white} lineHeight="XS">
                    Our Essential plan is for those who want to get in the game.
                    It's free for life so you have nothing to lose. Jump right
                    in.
                </Text>
            </Box>
            <Flex
                m={`${theme.spacing[12]} 0`}
                flexWrap={"wrap"}
                justifyContent="space-between"
            >
                {signUpListItems.map((item, index) => (
                    <ListItem label={item} key={index} theme={theme} />
                ))}
            </Flex>
            <Box>
                <Text fontSize={"XXS"} color={theme.white}>
                    See our{" "}
                    <Link
                        href={"https://nuop.com/pricing"}
                        target="_blank"
                        style={{ fontWeight: "bold" }}
                        fontSize="XXS"
                        color={theme.shamRockGreen}
                        underlineColor={theme.shamRockGreen}
                    >
                        pricing page
                    </Link>{" "}
                    for other plan details.
                </Text>
            </Box>
        </>
    );
}

export default withTheme(SignUpRightContent)