import styled from 'styled-components';
//import { getThemeColor, getThemeOnColor } from '../../../../../../../theme';

export const TopbarContainer = styled.div`
    max-height: 3.25rem;
    height: 3.25rem;
    width: 100%;	
    border: ${props => `1px solid ${props.theme.greyLight5}` };	
    background-color: #FFFFFF;	
    /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3); */
    position: fixed;
    z-index: 9999;
    
`