import axios from "axios";
import moment from "moment";
import Utils from "../../../../libs/Utils";
import {
    CONNECTIONS_IS_LOADING,
    FETCH_MY_CONNECTIONS_FAIL,
    FETCH_MY_CONNECTIONS_SUCCESS,
    MY_CONNECTIONS_RESET_FILTERS,
    MY_CONNECTIONS_UPDATE_FILTERS,
    CONTACTS_IS_LOADING,
    UPDATE_CONTACT,
    FETCH_MY_CONTACTS_FAIL,
    FETCH_MY_CONTACTS_SUCCESS,
    MY_CONTACTS_RESET_FILTERS,
    MY_CONTACTS_UPDATE_FILTERS,
    HIDE_CREATE_CONTACT_ERROR,
    CREATE_CONTACT_FAIL,
    CREATE_CONTACT_SUCCESS,
    SHOW_CONTACT_FORM,
    HIDE_CONTACT_FORM,
    SHOW_CONTACT_PROFILE,
    HIDE_CONTACT_PROFILE,
    SHOW_DELETE_CONTACT_MODAL,
    HIDE_DELETE_CONTACT_MODAL,
    DELETE_CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,
    SHOW_CONTACT_NOTE_EDITOR,
    HIDE_CONTACT_NOTE_EDITOR,
    SHOW_CONTACT_FILTERS,
    HIDE_CONTACT_FILTERS,
    HIDE_CONNECTIONS_FILTERS,
    SHOW_CONNECTIONS_FILTERS,
    SHOW_CONTACT_INVITE_MODAL,
    HIDE_CONTACT_INVITE_MODAL,
    UPDATE_CONTACT_SUCCESS,
    HIDE_CONTACT_CREATED_MODAL,
    FETCH_CONTACT_DETAILS_SUCCESS,
    FETCH_CONTACT_DETAILS_FAIL,
    ADD_ACTIVITY_FEED_NOTE,
    UPDATE_ACTIVITY_FEED_NOTE,
    SHOW_CONTACT_CREATED_MODAL,
    HIDE_MESSAGE_POPUP,
    SHOW_MESSAGE_POPUP,
    CONTACT_SEND_CONNECTION_SUCCESS,
    IMPORT_CONTACTS_SUCCESS,
    SHOW_IMPORT_CONTACTS_SUCCESS,
    HIDE_IMPORT_CONTACTS_SUCCESS,
    SHOW_REINVITE_MODAL,
    HIDE_REINVITE_MODAL,
    IMPORT_CONTACT_FROM_OTHER_USER_LOADING,
    IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS,
    IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
    HIDE_IMPORT_FROM_OTHER_USER_SUCCESS
} from "../../constants";
import _ from "lodash";
import { push } from "connected-react-router";

function cleanObject(obj) {
    return _.transform(obj, (result, value, key) => {
        if (_.isObject(value)) {
            // Recursively clean nested objects
            const cleanedValue = cleanObject(value);
            if (!_.isEmpty(cleanedValue)) {
                result[key] = cleanedValue;
            }
        } else if (!_.isNil(value)) {
            // Add non-falsy values to the result
            result[key] = value;
        }
    });
}

const importContactsSuccess = importedContacts => ({
    type: IMPORT_CONTACTS_SUCCESS,
    payload: {
        importedContacts
    }
})

const inviteContact = (contact) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true
            }
        })
        const uid = getState().main.user.uid;
        // find contact in contacts list and set its loading to true
        const contacts = getState().main.ui.view.myNetwork.contacts;
        // find contact in contacts list and set its loading to true
        const contactIndex = contacts.findIndex(c => c.email === contact.email);
        contacts[contactIndex].isLoading = true;

        try {
            const { contact_id, email, phone } = contact
            const { data } = await cloudfunctions.inviteContact({ contact_id: contact_id, email, phone })
            // if response body have array "duplicated" show they are already invited
            // if (data.duplicated.length > 0) {
            //     throw new Error("You have already invited this contact")
            // }
            // if response body have array "sent" dispatch SHOW_CONTACT_INVITE_MODAL
            if (data.status) {
                dispatch({
                    type: SHOW_CONTACT_INVITE_MODAL,
                    payload: {
                        newStatus: data.status,
                        oldStatus: contact.status,
                        contact: {
                            ...contact,
                            status: data.status,
                            member_uid: data.member_uid
                        }
                    }
                })
                contacts[contactIndex].status = data.status;
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: SHOW_MESSAGE_POPUP,
                payload: {
                    error: error.message
                }
            })
        }

        contacts[contactIndex].isLoading = false;
        dispatch({
            type: FETCH_MY_CONTACTS_SUCCESS,
            payload: {
                contacts
            }
        });
    }
}

function formatPhoneNumber(phoneNumber) {
    // If no phone number, return null
    if (!phoneNumber) return null;
    // Check if the phone number is an object
    const { number } = phoneNumber;
    // If no number, return null
    if (!number) return null;
    // Remove spaces and non-numeric characters
    const cleanedNumber = number.replace(/[^0-9]/g, "");

    // Check if the phone number starts with a country code
    if (cleanedNumber.startsWith("+")) {
        return cleanedNumber; // Already has a country code
    } else if (cleanedNumber.startsWith("1")) {
        // Remove the leading "1" and add "+1" as the country code
        return "+1" + cleanedNumber.slice(1);
    } else {
        // Add "+1" as the country code
        return "+1" + cleanedNumber;
    }
}

const importContactsFromCloudSponge = (importedContacts) => {
    return async (dispatch) => {
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                importContactsIsLoading: true,
                isFetchingContacts: true
            }
        });
        try {
            const _contacts = importedContacts.map(contact => {
                const mobile_phone = formatPhoneNumber(
                    contact.phone.find(
                        phone =>
                            // Take the first phone number that matches one of these conditions
                            phone.selected ||
                            phone.primary ||
                            phone.type === "main" ||
                            phone.type === "mobile"
                    ) || contact.phone[0] // If none of the above conditions are met, take the first phone number
                );
                const home_phone = formatPhoneNumber(
                    contact.phone.find(phone => phone.type === "home" && formatPhoneNumber(phone) !== mobile_phone)
                );
                const office_phone = formatPhoneNumber(
                    contact.phone.find(
                        phone =>
                            (phone.type === "office" || phone.type === "work") &&
                            formatPhoneNumber(phone) !== mobile_phone &&
                            formatPhoneNumber(phone) !== home_phone
                    )
                );
                console.log("contact", contact)
                // Console log phone numbers
                // console.log("mobile_phone", mobile_phone);
                // console.log("home_phone", home_phone);
                // console.log("office_phone", office_phone);

                const address = contact.primaryAddress();

                const _contact = {
                    first_name: contact.first_name,
                    last_name: contact.last_name,
                    work_info: {
                        located_at: address && {
                            address: address.street,
                            city: address.city,
                            zip_code: address.postal_code,
                            state: address.region
                        },
                        company: contact.companies && contact.companies[0],
                        office_phone: office_phone && office_phone,
                    },
                    mobile_phone: mobile_phone && mobile_phone,
                    email: contact.primaryEmail(),
                    additional_info: {
                        birthday: contact.dob,
                        phone_home: home_phone && home_phone,
                    }
                };

                if (!_contact.work_info.located_at && !_contact.work_info.company && !_contact.work_info.office_phone) {
                    delete _contact.work_info;
                } else if (!_contact.work_info.located_at) {
                    delete _contact.work_info.located_at;
                } else if (!_contact.work_info.company) {
                    delete _contact.work_info.company;
                }

                if (!_contact.additional_info.birthday && !_contact.additional_info.phone_home) {
                    delete _contact.additional_info;
                } else if (!_contact.additional_info.birthday) {
                    delete _contact.additional_info.birthday;
                } else if (!_contact.additional_info.phone_home) {
                    delete _contact.additional_info.phone_home;
                }

                return cleanObject(_contact);
            });

            // console.log("imported contacts", _contacts);
            await dispatch(bulkCreateContacts(_contacts));
        } catch (error) {
            console.error(error);
        }
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                importContactsIsLoading: false,
                isFetchingContacts: false
            }
        });

    }
}

const bulkCreateContacts = (contacts) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const uid = getState().main.user.uid;
        try {
            const shouldWaitResponse = contacts.length < 100;
            let response = { data: {} };

            if (shouldWaitResponse) {
                response = await cloudfunctions.bulkCreateContacts(uid, contacts)
            } else {
                cloudfunctions.bulkCreateContacts(uid, contacts);
            }
            // then fetch contacts
            // await dispatch(fetchMyContacts(0, 10, "", "created_at", "desc"));
            const { importedContacts = [], importsIssue = 0 } = response.data || {};

            dispatch(importContactsSuccess(importedContacts));
            dispatch({
                type: SHOW_IMPORT_CONTACTS_SUCCESS,
                payload: {
                    importContactsSucessCount: importedContacts.length,
                    importContactsErrorCount: importsIssue
                }
            })
        } catch (error) {
            console.error(error);
            dispatch({
                type: SHOW_MESSAGE_POPUP,
                payload: {
                    error: error.message
                }
            })
        }

    }
}

const hideInviteSuccessModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_INVITE_MODAL
        })
    }
}

const hideImportSuccessModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_IMPORT_CONTACTS_SUCCESS
        })
    }
}

const hideContactCreatedModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_CREATED_MODAL
        })
    }
}

const fetchMyConnections = (from_, size, search, sort) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        // const plan = getState().main.user.subscription.plan;
        const convertDate = ({ from, to, value }) => {
            //for between dates
            let parsedFrom = Date.parse(from);
            let nextDay = moment(to).add(1, "days");
            let parsedTo = Date.parse(nextDay);

            // for last7, last30, last90
            if (value) {
                nextDay = moment().add(1, "days");
                parsedTo = Date.parse(nextDay);
                // subtract 7 days if value is last7 etc            
                if (value === "last7") parsedFrom = Date.parse(moment().subtract(7, "days"));
                if (value === "last30") parsedFrom = Date.parse(moment().subtract(30, "days"));
                if (value === "last90") parsedFrom = Date.parse(moment().subtract(90, "days"));
            }

            return { from_: parsedFrom, to: parsedTo };
        }
        const uid = getState().main.user.uid;
        let filters = getState().main.ui.view.myNetwork.connectionFilters;
        if (filters.lastLogin) filters.lastLogin = convertDate(filters.lastLogin);
        const filtersFormatted = Utils.formatFilters(filters)

        if (filtersFormatted.status) filtersFormatted.status = filtersFormatted.status.map(status => status.value);
        if (filtersFormatted.members_registered) filtersFormatted.members_registered = convertDate(filtersFormatted.members_registered);
        if (filtersFormatted.registered_at) filtersFormatted.registered_at = convertDate(filtersFormatted.registered_at);
        if (filtersFormatted.plan_between) filtersFormatted.plan_between = convertDate(filtersFormatted.plan_between);

        dispatch({
            type: CONNECTIONS_IS_LOADING,
            payload: {
                connectionsIsLoading: true
            }
        });
        try {
           
            const response = await cloudfunctions.fetchMyConnections(uid, filtersFormatted, { from_, size, search, sort });
            dispatch({
                type: FETCH_MY_CONNECTIONS_SUCCESS,
                payload: {
                    connections: response.data,
                }
            });
        } catch (error) {
            dispatch({
                type: FETCH_MY_CONNECTIONS_FAIL
            })
        }
        dispatch({
            type: HIDE_CONNECTIONS_FILTERS
        })
    };
};

const resetConnectionFilters = () => {
    return (dispatch) => {
        dispatch({
            type: MY_CONNECTIONS_RESET_FILTERS
        })
    }
}

const updateConnectionFilters = (filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: MY_CONNECTIONS_UPDATE_FILTERS,
            payload: {
                filters
            }
        })
    }
}

const fetchMyContacts = (from = 0, size = 100, search = '', order = '', order_direction = '') => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true,
                isFetchingContacts: true
            }
        });
        try {
            const uid = getState().main.user.uid;
            let filters = getState().main.ui.view.myNetwork.contactFilters;
            const filtersFormatted = Utils.formatFilters(filters)
            const response = await cloudfunctions.fetchMyContacts(
                from,
                size,
                search,
                filtersFormatted,
                uid,
                order,
                order_direction
            );

            let contacts = response.data.items;
            const count = response.data.total;

            // Add contacts imported from Cloud Sponge.
            // Might be imported from another view than My Network
            // so we need to check if there are any imported contacts
            // and add them to the list of contacts, if not already there
            const importedContacts = getState().main.ui.view.myNetwork.importedContacts;
            if (importedContacts.length > 0) {
                const contactsIds = contacts.map(contact => contact.contact_id);
                const importedContactsToAdd = importedContacts.filter(contact => !contactsIds.includes(contact.contact_id));
                contacts = [...importedContactsToAdd, ...contacts];
            }

            dispatch({
                type: FETCH_MY_CONTACTS_SUCCESS,
                payload: {
                    contacts,
                    count,
                    fetchMyContactsPayload: {
                        from,
                        size,
                        search,
                        filtersFormatted,
                        uid,
                        order,
                        order_direction

                    }
                }
            })
        } catch (error) {
            dispatch({
                type: FETCH_MY_CONTACTS_FAIL
            })
        }
        dispatch({
            type: HIDE_CONTACT_FILTERS
        })
    }
}

const resetContactFilters = () => {
    return (dispatch) => {
        dispatch({
            type: MY_CONTACTS_RESET_FILTERS
        })
    }
}

const updateContactFilters = (filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: MY_CONTACTS_UPDATE_FILTERS,
            payload: {
                filters
            }
        })
    }
}

const showDeleteContactModel = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_DELETE_CONTACT_MODAL
        })
    }
}

const hideDeleteContactModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_DELETE_CONTACT_MODAL
        })
    }
}

const deleteContact = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true
            }
        })
        try {
            const userId = getState().main.user.uid;
            const contactId = getState().main.ui.view.myNetwork.contactData.contact_id;

            const response = await cloudfunctions.deleteContact(contactId, userId);
            const {
                from,
                size,
                search,
                filtersFormatted,
                uid,
                order,
                order_direction
            } = getState().main.ui.view.myNetwork.fetchMyContactsPayload;
            const { data } = await cloudfunctions.fetchMyContacts(
                from + size + 1,
                1,
                search,
                filtersFormatted,
                uid,
                order,
                order_direction
            );

            let contacts = data.items;
            const count = data.total;
            dispatch({ type: DELETE_CONTACT_SUCCESS, payload: { contacts, count } });
        } catch (error) {
            console.error(error);
            dispatch({
                type: DELETE_CONTACT_FAIL,
                payload: {
                    error
                }
            });
        }
    }
}

const mapContactToElasticStructure = (contact) => {
    const { work_info = {}, additional_info = {} } = contact;
    const { phone_home } = additional_info;
    const { company, located_at = {}, office_phone } = work_info;
    const { country, state, city } = located_at;

    return {
        ...contact,
        company,
        country,
        state,
        city,
        office_phone,
        phone_home
    };
}

const removeElasticContactKeys = (contact) => {
    const { uid, company, country, state, city, activity_feed, ...rest } = contact;
    return rest;
}

const updateContact = (contactBody) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const userId = getState().main.user.uid;
            // remove null fields from contact
            Object.keys(contactBody).forEach(key => {
                if (contactBody[key] === null) delete contactBody[key];
                else if (typeof contactBody[key] === "object") {
                    Object.keys(contactBody[key]).forEach(key2 => {
                        if (contactBody[key][key2] === null)
                            delete contactBody[key][key2];
                        else if (contactBody[key][key2] === "")
                            delete contactBody[key][key2];
                    });
                } else if (contactBody[key] === "") delete contactBody[key];
            });

            const contactToUpdate = removeElasticContactKeys(contactBody);
            const response = await cloudfunctions.updateContact(userId, contactToUpdate);
            const { contact } = response.data;


            dispatch({
                type: UPDATE_CONTACT_SUCCESS,
                payload: {
                    contact: mapContactToElasticStructure(contact)
                }
            });
            return mapContactToElasticStructure(contact);
        } catch (error) {
            console.error(error);
            const errorMessage = error.response && error.response.data && error.response.data.message;
            dispatch({
                type: CREATE_CONTACT_FAIL,
                payload: {
                    error: errorMessage
                }
            });
        }
    }
}

const createContact = (contactBody) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const uid = getState().main.user.uid;
            // remove null fields from contact
            contactBody = cleanObject(contactBody);
            // Trim all string fields
            Object.keys(contactBody).forEach(key => {
                if (typeof contactBody[key] === "string") {
                    contactBody[key] = contactBody[key].trim();
                }
            });
            const response = await cloudfunctions.createContact(uid, contactBody);
            const { contact, contact_id } = response.data;
            return mapContactToElasticStructure({
                    contact_id,
                    ...contact
            })
        } catch (error) {
            console.error(error);
            dispatch({
                type: CREATE_CONTACT_FAIL,
                payload: {
                    error: error.response.data.message
                }
            })
            return null;
        }
    }
}

const onContactCreated = (contact) => {
    return (dispatch) => {
        dispatch({
            type: CREATE_CONTACT_SUCCESS,
            payload: {
                contact
            }
        })

        // if (contact.email && contact.status === "INVITE") {
            // console.log("dispatching show contact created modal");
            dispatch({
                type: SHOW_CONTACT_CREATED_MODAL,
                payload: {
                    contact
                }
            });
        // }
    }
}

const showContactForm = (contactData) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CONTACT_FORM,
            payload: {
                contactData
            }
        })
    }
}

const hideContactForm = (fromProfile = false) => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_FORM,
            payload: {
                fromProfile
            }
        })
    }
}

const showContactProfile = (contact) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const uid = getState().main.user.uid;
        const contactId = contact.contact_id;
        dispatch({
            type: SHOW_CONTACT_PROFILE
        })
        try {
            const response = await cloudfunctions.fetchContactDetails(uid, contactId);
            dispatch({
                type: FETCH_CONTACT_DETAILS_SUCCESS,
                payload: {
                    contact: response.data.data
                }
            })
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
}

const hideContactProfile = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_PROFILE
        })
    }
}

const showEditNote = (note = undefined) => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CONTACT_NOTE_EDITOR,
            payload: {
                note
            }
        })
    }
}

const hideEditNote = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_NOTE_EDITOR
        })
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: false
            }
        })
    }
}

const showContactFilters = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CONTACT_FILTERS
        })
    }
}

const hideContactFilters = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONTACT_FILTERS
        })
    }
}

const showConnectionFilters = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_CONNECTIONS_FILTERS
        })
    }
}

const hideConnectionFilters = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CONNECTIONS_FILTERS
        })
    }
}

const connectWithContact = (contact) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true
            }
        })
        let contacts = getState().main.ui.view.myNetwork.contacts;
        const contactIndex = contacts.findIndex(c => c.email === contact.email);
        contacts[contactIndex].isLoading = true;

        try {
            await cloudfunctions.connect(contact.member_uid);
            contacts[contactIndex].status = "PENDING"
            dispatch({
                type: CONTACT_SEND_CONNECTION_SUCCESS,
            })
        } catch (error) {
            console.error(error);
        }

        contacts[contactIndex].isLoading = false;
        dispatch({
            type: FETCH_MY_CONTACTS_SUCCESS,
            payload: {
                contacts
            }
        });
    }
}

const hideCreateContactErrorModal = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_CREATE_CONTACT_ERROR
        });
    }
}

const hideMessagePopup = () => {
    return (dispatch) => {
        dispatch({
            type: HIDE_MESSAGE_POPUP
        })
        // hideContactForm(true)(dispatch);
    }
}

const addActivityFeedNote = (note, rendered_note) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const contactId = getState().main.ui.view.myNetwork.contactData.contact_id;
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true
            }
        })

        try {
            const response = await cloudfunctions.addActivityFeedNote(contactId, "Custom Note", note, rendered_note);
            const addedNote = response.data.activity_feed;
            addedNote.timestamps = {
                created_at: +addedNote.timestamps.created_at,
                updated_at: +addedNote.timestamps.updated_at
            };
            dispatch({
                type: ADD_ACTIVITY_FEED_NOTE,
                payload: {
                    addedNote
                }
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: SHOW_MESSAGE_POPUP,
                payload: {
                    error: error.message
                }
            })
        }
        hideEditNote()(dispatch);
    }
}

const updateActivityFeedNote = (note, rendered_note, noteId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const contactId = getState().main.ui.view.myNetwork.contactData.contact_id;
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: true
            }
        })

        try {
            const response = await cloudfunctions.updateActivityFeedNote(contactId, noteId, "Custom Note", note, rendered_note);
            const updatedNote = response.data.activity_feed;
            updatedNote.timestamps = {
                created_at: +updatedNote.timestamps.created_at,
                updated_at: +updatedNote.timestamps.updated_at
            };
            dispatch({
                type: UPDATE_ACTIVITY_FEED_NOTE,
                payload: {
                    updatedNote
                }
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: SHOW_MESSAGE_POPUP,
                payload: {
                    error: error.message
                }
            })
        }
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: false
            }
        })
        hideEditNote()(dispatch);
    }
}

const deleteActivityFeedNote = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const contactId = getState().main.ui.view.myNetwork.contactData.contact_id;
        const activityId = getState().main.ui.view.myNetwork.note.activity_id;
        // dispatch({
        //     type: CONTACTS_IS_LOADING,
        //     payload: {
        //         contactsIsLoading: true
        //     }
        // })

        try {
            const response = await cloudfunctions.deleteActivityFeedNote(contactId, activityId);

            const contactData = getState().main.ui.view.myNetwork.contactData;
            const activityIndex = contactData.activity_feed.findIndex(a => a.activity_id === activityId);
            contactData.activity_feed.splice(activityIndex, 1);

            dispatch({
                type: FETCH_CONTACT_DETAILS_SUCCESS,
                payload: {
                    contact: contactData
                }
            })
        } catch (error) {
            console.error(error);
            dispatch({
                type: SHOW_MESSAGE_POPUP,
                payload: {
                    error: error.message
                }
            })
        }
        dispatch({
            type: CONTACTS_IS_LOADING,
            payload: {
                contactsIsLoading: false
            }
        })
        hideEditNote()(dispatch);
    }
}

const goToContactProfile = (contact) => {
    return (dispatch) => {
        dispatch(push("/my-network/contacts"));
        console.log("contact", contact);
        dispatch(showContactProfile(contact));
    }
}

const fetchContactDetails = (contactId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        const uid = getState().main.user.uid;
        try {
            const response = await cloudfunctions.fetchContactDetails(uid, contactId);
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
}

const closeSuccessfullImportModal = () => ({
    type: HIDE_IMPORT_FROM_OTHER_USER_SUCCESS
})

const closeImportContactFromOtherUserErrorModal = () => {
    return dispatch => {
        dispatch({
            type: IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
            payload: {
                userAlreadyHasContact: false,
                error: null
            }
        });
    };
}

const importContactFromOtherUser = (contactId, contactOf) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch({
                type: IMPORT_CONTACT_FROM_OTHER_USER_LOADING,
            })
            const { data } = await cloudfunctions.importContactFromOtherUser(contactId, contactOf);
            let { contact } = data
            dispatch({
                type: IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS,
                payload: {
                    contact: mapContactToElasticStructure(contact),
                    showSuccessfullyImportedModal: true
                }
            });
        } catch (error) {
            console.error(error);
            if (error.response && error.response.status === 409) {
                const contact = error.response.data.contact;
                return dispatch({
                    type: IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
                    payload: {
                        userAlreadyHasContact: true,
                        contact
                    }
                })
            }
            return dispatch({
                type: IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
                payload: {
                    error: error.message
                }
            });
        }
    }
}

const showReinviteModal = contact => {
    return dispatch => {
        dispatch({
            type: SHOW_REINVITE_MODAL,
            payload: {
                contact
            }
        });
    };
};

const hideReinviteModal = () => {
    return dispatch => {
        dispatch({
            type: HIDE_REINVITE_MODAL
        });
    };
};

export {
    addActivityFeedNote,
    updateActivityFeedNote,
    hideMessagePopup,
    showConnectionFilters,
    hideConnectionFilters,
    showContactFilters,
    hideContactFilters,
    fetchMyConnections,
    updateConnectionFilters,
    resetConnectionFilters,
    fetchMyContacts,
    updateContactFilters,
    resetContactFilters,
    deleteContact,
    updateContact,
    createContact,
    showContactForm,
    hideContactForm,
    showContactProfile,
    hideContactProfile,
    hideImportSuccessModal,
    showDeleteContactModel,
    hideDeleteContactModal,
    showEditNote,
    hideEditNote,
    inviteContact,
    hideInviteSuccessModal,
    hideContactCreatedModal,
    connectWithContact,
    bulkCreateContacts,
    importContactsFromCloudSponge,
    deleteActivityFeedNote,
    hideCreateContactErrorModal,
    onContactCreated,
    goToContactProfile,
    fetchContactDetails,
    showReinviteModal,
    hideReinviteModal,
    importContactFromOtherUser,
    closeImportContactFromOtherUserErrorModal,
    closeSuccessfullImportModal
};