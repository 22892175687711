import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Flex } from "rebass";
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";
import PopupModal from "../../PopupBlurModal";
import { Icon } from "../../Icon";
import { Input, TextArea } from "../../../../views/Settings/common/Components";
import { InputCounter } from "../../../../views/Settings/InformationAccount/styles";
import SquareButton from "../../Buttons/SquareButton2";
import SquareOutlinedButton from "../../Buttons/SquareOutlinedButton";
import { addGratitude, updateGratitude } from "../../../../../../../redux/actions/creators/profile";
import useForm from "../../../../views/Settings/common/useForm";
import Utils from "../../../../../../../libs/Utils";
import GratitudeSuccessMessage from "./GratitudeSuccessMessage";
import {
    EngagementModalTitle as Title,
    EngagementModalDescription as Description
} from "../../Text";

const GratitudeModal = ({
    t,
    theme,
    open,
    handleClose,
    addressedUser,
    gratitudeToEdit,
    addGratitude,
    updateGratitude
}) => {
    const { full_name, name, first_name, lastname, uid, userName } = addressedUser;

    const { formValues, reset, update, initialize } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const fullName = full_name || `${name} ${lastname}`;

    useEffect(() => {
        if (gratitudeToEdit && gratitudeToEdit.content) {
            initialize({
                title: gratitudeToEdit.title,
                message: gratitudeToEdit.content
            });
        }
        return () => {
            setShowSuccessMessage(false);
            reset();
        };
    }, [open]);

    const onChange = ev => update({ key: ev.name, value: ev.value });

    const onSave = async () => {
        const { title, message } = formValues;
        setIsLoading(true);
        await addGratitude(
            {
                title,
                content: message,
                addressed_user: uid
            },
            addressedUser
        );
        setShowSuccessMessage(true);
        setIsLoading(false);
    };

    const onUpdate = async () => {
        const { title, message } = formValues;
        setIsLoading(true);
        await updateGratitude({
            ...gratitudeToEdit,
            title,
            content: message
        });
        setShowSuccessMessage(true);
        setIsLoading(false);
    };

    const getFirstName = () => {
        const firstName = first_name || name;
        // Add s to the end of the name if it ends with s
        return Utils.getNameWithAnSAtTheEnd(firstName);
    };

    const submitDisabled =
        !formValues.title ||
        !formValues.message ||
        !formValues.title.length ||
        !formValues.message.length;

    const renderForm = () => (
        <Flex
            flexDirection="column"
            alignItems="center"
            p={[
                theme.spacing[5],
                theme.spacing[5],
                theme.spacing[10],
                theme.spacing[10]
            ]}
        >
            <Icon name="gratitude" color={theme.mountainMeadow} size="3xl" />
            <Title
                dangerouslySetInnerHTML={{
                    __html: t(gratitudeToEdit ? "title_edit" : "title", {
                        name: fullName
                    })
                }}
            />
            {!gratitudeToEdit && <Description
                dangerouslySetInnerHTML={{
                    __html: t("description", { name: getFirstName() })
                }}
            />}
            <Flex width={[1]} flexDirection="column" mb={[theme.spacing[4]]}>
                <Input
                    type="text"
                    name="title"
                    value={formValues.title}
                    onChange={({ target }) => onChange(target)}
                    placeholder={t("title_placeholder")}
                />
            </Flex>
            <Flex width={[1]} flexDirection="column">
                <TextArea
                    type="text"
                    name="message"
                    value={formValues.message}
                    onChange={({ target }) => onChange(target)}
                    maxLength="5000"
                    rows={6}
                    style={{ resize: "vertical", height: "100px" }}
                    placeholder={t("message_placeholder")}
                />
                <Flex
                    mt={theme.spacing[2]}
                    justifyContent={"flex-end"}
                    style={{ minHeight: "24px" }}
                >
                    {!!formValues.message && !!formValues.message.length && (
                        <InputCounter>
                            {t("characters_left", {
                                charactersLeft: 5000 - formValues.message.length
                            })}
                        </InputCounter>
                    )}
                </Flex>
            </Flex>
            <Flex
                width={[1]}
                mt={[theme.spacing[8]]}
                justifyContent={"flex-end"}
            >
                <SquareOutlinedButton
                    onClick={handleClose}
                    color={theme.shamRockGreen}
                    text={t("cancel")}
                    fontSize="XS"
                    style={{ marginRight: theme.spacing[6] }}
                />
                <SquareButton
                    disabled={submitDisabled}
                    onClick={gratitudeToEdit ? onUpdate : onSave}
                    bg={theme.shamRockGreen}
                    isLoading={isLoading}
                    fontSize="XS"
                    text={t("save")}
                />
            </Flex>
        </Flex>
    );

    return (
        <PopupModal
            open={open}
            handleClose={handleClose}
            width={[0.9, 0.9, 0.5, 0.3]}
        >
            {showSuccessMessage ? (
                <GratitudeSuccessMessage
                    fullName={getFirstName()}
                    buttonLabel={t("success_button")}
                    userName={userName}
                    onClick={handleClose}
                />
            ) : (
                renderForm()
            )}
        </PopupModal>
    );
};

const mapStateToProps = (state, props) => ({
    // If the user is passed as a prop, use it, otherwise use the user from the store (will use the user from current profile)
    addressedUser: props.addressedUser
        ? props.addressedUser
        : state.main.ui.view.profile.user.data
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addGratitude,
            updateGratitude
        },
        dispatch
    );

const GratitudeModalComponent = withTheme(
    withTranslation("gratitudeModal")(GratitudeModal)
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GratitudeModalComponent);
