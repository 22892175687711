import React from 'react'
import { Box, Flex } from 'rebass';
import { MaxPriceFieldSelector } from './constants'
import IntegerField from './_IntegerField';
import PriceField from './_PriceField';
import SelectField from './_SelectField';
import {
    LotSizeOptions,
    HomeSaleContingencyOptions,
    FinancingInfoOptions,
} from "../../../../constants"

const ResidentialSingleFamily = ({
    formValues,
    errors,
    onChange,
    theme,
}) => {

    return (
        <>
            <Flex mt={theme.spacing[3]}>
                <PriceField
                    width={1/2}
                    pr={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={MaxPriceFieldSelector}
                    label='Max Price*'
                />
            </Flex>
            <Flex mt={theme.spacing[3]}>
                <IntegerField
                    width={1/2}
                    pr={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={'minBeds'}
                    label='Min Beds'
                />
                <IntegerField
                    width={1/2}
                    pl={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={'minSquareFeet'}
                    label='Min SqFt'
                />
            </Flex>
            <Flex mt={theme.spacing[3]}>
                <SelectField
                    width={1/2}
                    pr={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={'minLotSize'}
                    label='Min Lot Size*'
                    options={LotSizeOptions}
                />
            </Flex>
            <Flex mt={theme.spacing[3]}>
                <SelectField
                    width={1/2}
                    pr={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={'financingInfo'}
                    label='Financing Info*'
                    options={FinancingInfoOptions}
                />
                <SelectField
                    width={1/2}
                    pl={2}
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    fieldSelector={'homeSaleContingency'}
                    label='Home Sale Contingency*'
                    options={HomeSaleContingencyOptions}
                />
            </Flex>
        </>
    );
};

export default ResidentialSingleFamily;
