import {
    EDITPROFILE_ISLOADING,
    EDITPROFILE_FETCHAGENCIESSUCCESS,
    EDITPROFILE_FETCHFAIL,
    EDITPROFILE_FETCHAGENCYSUCCESS,
    VALIDATE_PHONE_ON_EDIT,
    VALIDATE_PHONE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT,
    VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
    VALIDATE_PHONE_MODALOPEN_ON_EDIT,
    VALIDATE_PHONE_ON_EDIT_FAIL,
    PROFILE_EDITING
} from "../../constants";
import { uploadAvatarSuccess, setVerifiedPhone, editProfile } from "../user";
import { handleError } from "../modal";
import { currentSelectedTab } from "../navbar";
import { push } from "connected-react-router";
import Compressor from 'compressorjs';
import GlobalBusiness from '../../../../business/global'
const editProfileIsLoading = isLoading => ({
    type: EDITPROFILE_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

const editProfileFetchFail = errors => ({
    type: EDITPROFILE_FETCHFAIL,
    payload: {
        errors: errors
    }
});

const editProfileFetchAgenciesSuccess = agencies => ({
    type: EDITPROFILE_FETCHAGENCIESSUCCESS,
    payload: {
        agencies: agencies
    }
});

export const editProfileFetchAgencySuccess = selectedAgency => ({
    type: EDITPROFILE_FETCHAGENCYSUCCESS,
    payload: {
        selectedAgency: selectedAgency
    }
});

export const showPhoneValidationModal = open => ({
    type: VALIDATE_PHONE_MODALOPEN_ON_EDIT,
    payload: {
        open: open
    }
});

export const profileEditing = editingCardName => ({
    type: PROFILE_EDITING,
    payload: {
        editingCardName: editingCardName
    }
});

export const fetchAgencies = () => {
    return async (dispatch, getState, { firebase }) => {
        dispatch(editProfileIsLoading(true));
        try {
            //  const snap = await firebase.fetchAgenciesList();
            const snap = await firebase.fetchCompanies();

            const data = firebase.flatArrayFromSnapshot(snap)
            //const snapTeams = await firebase.fetchTeamsList();
            /* const data = firebase
                .flatArrayFromSnapshot(snap)
                .concat(firebase.flatArrayFromSnapshot(snapTeams)); */
            dispatch(editProfileFetchAgenciesSuccess(data));
        } catch (error) {
            dispatch(handleError(error));
            return dispatch(editProfileFetchFail(error));
        }
    };
};

export const uploadUserAvatar = (email, file) => {
    return async (dispatch, getState, { firebase }) => {
        dispatch(editProfileIsLoading(true));
        try {
            const actualUser = getState().main.user.profile;
            const compressedFile = await GlobalBusiness.compressFile(file, Compressor)
            const avatarUrl = await firebase.changeProfileAvatar(email, compressedFile);
            actualUser.avatar = avatarUrl;
            dispatch(uploadAvatarSuccess(avatarUrl));
            dispatch(editProfile(actualUser));
            dispatch(editProfileIsLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            return dispatch(editProfileFetchFail(error));
        }
    };
};

export const fetchAgency = key => {
    return async (dispatch, getState, { firebase }) => {
        dispatch(editProfileIsLoading(true));
        try {
            const data = await firebase.fetchAgency(key);
            return dispatch(editProfileFetchAgencySuccess(data));
        } catch (error) {
            return dispatch(editProfileFetchFail(error));
        }
    };
};

export const verifyPhoneNumberOnEdit = (phone, openGenericPhoneValidationModal = true) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: VALIDATE_PHONE_ON_EDIT
        });
        try {
            if (openGenericPhoneValidationModal) dispatch(showPhoneValidationModal(true));
            //  const nahuiPhone = '+543434629666'

            let response = await cloudfunctions.sendVerificationCode(phone);
            //let response = await cloudfunctions.sendVerificationCode(nahuiPhone);
            return dispatch({
                type: VALIDATE_PHONE_ON_EDIT_SUCC,
                payload: {
                    phone
                }
            });
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: VALIDATE_PHONE_ON_EDIT_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

export const verifyPhoneNumberCodeOnEdit = code => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: VALIDATE_PHONE_CODE_ON_EDIT
        });
        try {
            const state = await getState();
            const {
                phone
            } = state.main.ui.view.editProfile.phoneValidation;
                        // console.log("phone verifyPhoneNumberCodeOnEdit", phone);
            await cloudfunctions.validateVerificationCode(code, phone);
            const uid = state.main.user.uid;
            firebase.userPhoneValid(uid, true);
            dispatch(setVerifiedPhone(true));
            dispatch({
                type: VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
                code: code
            });
            setTimeout(() => {
                dispatch(showPhoneValidationModal(false));
                dispatch(push(`/profile/${uid}`));
                dispatch(currentSelectedTab("Home"));
                dispatch(profileEditing(''));

            }, 2000);
        } catch (errors) {
            try {
                dispatch(handleError(errors));
                const uid = getState().main.user.uid;
                firebase.userPhoneValid(uid, false);
                dispatch(setVerifiedPhone(false));
                return dispatch({
                    type: VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
                    errors: cloudfunctions.parseRequestError(errors)
                });
            } catch (err) {
                dispatch(handleError(err));
                dispatch(setVerifiedPhone(false));
                return dispatch({
                    type: VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
                    errors: cloudfunctions.parseRequestError(err)
                });
            }
        }
    };
};
