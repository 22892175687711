import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";

const mapStateToProps = state => {
    const statics = state.main.ui.global.statics;

    return {
        feeFrequencyOptions:
            statics.referralFeeFrequency &&
            statics.referralFeeFrequency.options,
        feeFrequencyKeys:
            statics.referralFeeFrequency &&
            statics.referralFeeFrequency.optionsKeys,
        feeDurationOptions:
            statics.referralFeeDuration && statics.referralFeeDuration.options,
        feeDurationKeys:
            statics.referralFeeDuration &&
            statics.referralFeeDuration.optionsKeys,
        feeCommencementOptions:
            statics.referralFeeCommencement &&
            statics.referralFeeCommencement.options
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
