import React from "react";
import styled from "styled-components";
import { Description } from "../../shared/Text";

const MaterialError = styled(Description)`
    position: relative;
    top: 0em;
    font-style: italic;
    font-size: 10px;
`;

const Error = props => {
    const messageExists = props.error && props.error.message;
    const wrongPassword = props.wrongPassword && props.wrongPassword
    console.log('wrongPassword!!', {props})
    if (messageExists)
        return (
            <MaterialError
                color={props.errorColor}
                id="error-card"
                style={{
                    color: props.color,
                    ...props.labelStyles,
                    ...props.errorStyles
                }}
            >
                
                {props.error.message}
                {wrongPassword ? ' Omar ' : 'leal'}
            </MaterialError>
        );
        if (wrongPassword && props.name === 'password') {
            return (
                <MaterialError
                color={props.errorColor}
                id="error-card"
                style={{
                    color: props.color,
                    ...props.labelStyles,
                    ...props.errorStyles
                }}
            >
                
              The provided password is not correct
            </MaterialError>
            )
        }
    else return null;
};

export default Error;
