import styled from "styled-components";
import { changeColorOnHover } from "../ChangeColorOnHover";

export const Title = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: ${props => (props.theme.mobileDevice ? "16px" : "20px")};;
    font-weight: bold;
    line-height: ${props => (props.theme.mobileDevice ? "19px" : "25px")};
    letter-spacing: 0;
    text-align: ${props => (props.centered ? "center" : "")};
`;

export const Title2 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 15px;
    font-weight: bold;
    line-height: 21px;
`;

export const Title3 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
`;

export const SubTitle = styled.div`
    text-align: center;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.336px;
    color: ${props => (props.color ? props.color : props.theme.onBackground)};
`;

export const SubHeader2 = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    color: ${props => (props.color ? props.color : props.theme.darkGray)};
`;

export const SubHeader3 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 15px;
    font-weight: 500;
    line-height: 12px;
`;

export const Description = styled.div`
    color: ${props => (props.color ? props.color :  (props.errors) ?  "#DC3545" : props.theme.coolBlack )};
    font-size: 16px;
    line-height: 19px;
    white-space: pre-wrap;
    text-align: ${props => (props.centered ? "center" : "")};
`;

export const Description2 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
`;

export const SubTitle3 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
`;
export const Description3 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.darkGray)};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
`;

export const SubTitle4 = styled.div`
    font-family: "SF UI Text Regular";
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 12px;
    line-height: 14px;
`;

export const PostBody = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 14px;
    line-height: 16px;
`;

export const Link = styled.a`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: ${props => (props.theme.mobileDevice ? "14px" : "16px")};
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;

export const Link2 = styled.a`
    color: ${props => (props.color ? props.color : props.theme.blueMedium)};
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;

export const Time = styled.a`
    color: ${props => props.theme.coolBlack};
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
`;

export const SubTitle2 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.43px;
    line-height: 21px;
`;

export const Header = styled.div`
    font-family: "Inter UI Semi Bold";
    color: ${props => (props.color ? props.color : props.theme.darkestGreen)};
    font-size: 14px;
    line-height: 16px;
    ${changeColorOnHover}
`;

export const Info = styled.div`
    color: ${props => (props.color ? props.color : props.theme.blueMedium)};
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
`;

export const Info2 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.41px;
    line-height: 20px;
`;

export const MontserratBold = styled.div`
    color: ${props => (props.color ? props.color : "black")};
    font-family: "Montserrat Bold";
    font-size: ${props => (props.fontSize ? props.fontSize : "52.31px")};
    font-weight: bold;
    line-height: ${props => (props.lineHeight ? props.lineHeight : "64px")};
    text-align: center;
`;

export const Montserrat1 = styled.div`
    color: ${props => (props.color ? props.color : "black")};
    font-family: Montserrat;
    font-size: ${props => (props.fontSize ? props.fontSize : "22.67px")};
    line-height: 28px;
`;

export const Montserrat2 = styled.div`
    color: ${props => (props.color ? props.color : "black")};
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    text-align: center;
`;

export const SubHeader = styled.div`
    font-size: 15px;
    font-weight: ${({ fontWeight }) => fontWeight || "normal"};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${props => (props.color ? props.color : props.theme.text)};
`;

export const SignOutEmail = styled.div`
    font-size: 11px;
    line-height: 13px;
    color: ${props => (props.color ? props.color : props.theme.text)};
    white-space: pre-wrap;
    -ms-word-break: break-all;
    word-break: break-all;
`;

export const Numbers = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
`;

export const ProfileName = styled.div`
    color: ${props => (props.color ? props.color : props.theme.darkestGreen)};
    font-size: ${props => (props.theme.mobileDevice ? "16px" : "28px")};
    font-weight: bold;
    line-height: ${props => (props.theme.mobileDevice ? "21px" : "34px")};
    letter-spacing: 0px;
`;

export const SectionTitle = styled.div`
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.41px;
`;

export const SectionContent = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 14px;
`;

export const SectionContent2 = styled.div`
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    white-space: pre-wrap;
    overflow-wrap: anywhere;
`;

export const SubHeader4 = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: -0.34px;
    color: ${props => (props.color ? props.color : props.theme.text)};
`;

export const Info3 = styled.div`
    font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
    letter-spacing: -0.6px;

    color: ${props => (props.color ? props.color : props.theme.text)};
`;

export const Description4 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
`;

export const Header2 = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
`;

export const UpgradeToPremium = styled.div`
    color: ${props => (props.color ? props.color : "white")};
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    text-align: center;
`;

export const PhoneSelect = styled.div`
    color: ${props => (props.color ? props.color : props.theme.blueMedium)};
    font-size: 14px;
    font-weight: bold;
`;

export const CreditCardDesc = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: ${props => (props.theme.mobileDevice ? "14px" : "16px")};
    letter-spacing: -0.38px;
    line-height: ${props => (props.theme.mobileDevice ? "20px" : "23px")};
    font-weight: normal;
`;

export const ReplyDate = styled.div`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: 12px;
    line-height: 16px;
`;

export const Text = styled.span`
    color: ${props => (props.color ? props.color : props.theme.text)};
    font-size: ${props => `${props.theme.fonts[props.fontSize]}`};
    font-weight: ${props => `${props.theme.fontWeights[props.fontWeight]}`};
    line-height: ${props => `${props.theme.lineHeights[props.lineHeight]}`};
`;

export const EngagementModalTitle = styled.h1`
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) =>
        theme.mobileDevice ? theme.fonts.XS : theme.fonts.L};
    font-weight: ${({ theme }) => theme.fonts.bold};
    margin-top: ${({ theme }) => theme.spacing[6]};
    text-align: center;
    width: ${({ theme }) => theme.mobileDevice && "80%"};
    z-index: 1;

    span.highlight {
        cursor: ${({ pointer }) => pointer && "pointer"};
        color: ${({ theme }) => theme.newBlue};
        margin-left: ${({ theme }) => theme.spacing[1]};
    }
`;

export const EngagementModalDescription = styled.p`
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) =>
        theme.mobileDevice ? theme.fonts.XXS : theme.fonts.XS};
    font-weight: ${({ theme }) => theme.fonts.medium};
    margin-top: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
    text-align: center;
    z-index: 1;

    span.highlight {
        cursor: ${({ pointer }) => pointer && "pointer"};
        color: ${({ theme }) => theme.newBlue};
        margin-left: ${({ theme }) => theme.spacing[1]};
    }
`;