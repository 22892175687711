import React from 'react';
import { Icon } from '../../../atoms/shared/Icon';
import { OPPORTUNITY_CREATION_STEPS, OPPORTUNITY_TYPES } from '../constants';
import ReferralProfession from './ReferralProfession/index';
import CandidateSelection from './CandidateSelection';
import RecommendationType from './RecommendationType';
import RecommendationPreview from './RecommendationDirectInformation';
import RecommendationInformation from './RecommendationInformation';
import RecommendationRequestPreview from './RecommendationRequestPreview';



export const CANDIDATES_SELECTION_STEP_ITEM = {
    icon: <Icon icon="profile" color="white" />,
    title: 'Select candidate(s)',
    code: OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION,
};

export const REFERRAL_PROFESSION_STEP_ITEM = {
    icon: <Icon icon="profile" color="white" />,
    title: 'Profession',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION,
};





export const RECOMMENDATION_TYPE_STEP_ITEM = {
    icon: <Icon icon="invite" color="white" />,
    title: 'Recommendation',
    code: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_TYPE,
};

export const RECOMMENDATION_DIRECT_INFORMATION = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Send recommendation',
    code: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION,
};

export const RECOMMENDATION_REQUEST_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Information',
    code: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION,
}

export const RECOMMENDATION_REQUEST_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_PREVIEW,
}

export const RECOMMENDATION_CANDIDATES_FLOW_STEPS_ITEMS = [
    RECOMMENDATION_TYPE_STEP_ITEM,
    CANDIDATES_SELECTION_STEP_ITEM,
    RECOMMENDATION_DIRECT_INFORMATION,
    RECOMMENDATION_REQUEST_PREVIEW_STEP_ITEM,
];

export const RECOMMENDATION_REQUEST_INFORMATION_FLOW_STEPS_ITEM = [
    RECOMMENDATION_TYPE_STEP_ITEM,
    RECOMMENDATION_REQUEST_INFORMATION_STEP_ITEM,
    RECOMMENDATION_REQUEST_PREVIEW_STEP_ITEM
];






export const OPPORTUNITY_CREATION_STEPS_VIEWS = {
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION]: <ReferralProfession />,
    [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: <CandidateSelection />,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_TYPE]: <RecommendationType />,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION]: <RecommendationPreview />,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]: <RecommendationInformation />,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_PREVIEW]: <RecommendationRequestPreview />,
   
}; 

export const OPPORTUNITY_CREATION_STEPS_ITEMS = {
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION]: REFERRAL_PROFESSION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: CANDIDATES_SELECTION_STEP_ITEM,
  
};

export const FLOW_STEPS_ITEMS = {
    [OPPORTUNITY_TYPES.RECOMMENDATION]: RECOMMENDATION_CANDIDATES_FLOW_STEPS_ITEMS,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]: RECOMMENDATION_REQUEST_INFORMATION_FLOW_STEPS_ITEM,
};