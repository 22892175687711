import {
    combineReducers
} from 'redux';
import editProfileReducer from './editProfile';
import fillProfileReducer from './fillProfile';
import profileReducer from './profile';
import referralsReducer from './referrals';
import contactsReducer from './contacts';
import sendReferralReducer from './sendReferral';
import sendReferralUserListReducer from './sendReferralUserList';
import signInReducer from './signIn';
import signUpReducer from './signUp';
import referralInformationReducer from './referralInformation';
import resetPasswordReducer from './resetPassword';
import passwordRecoveryReducer from './passwordRecovery';
import newArticleReducer from './newArticle';
import settingsReducer from './settings';
import chatReducer from './chat'
import newsReducer from './news'
import bigBoardReducer from './bigBoard'
import opportunityCreationFlowReducer from './opportunityCreationFlow'; 
import myNetworkReducer from './myNetwork';
import opportunityCompletionFlow from './opportunityCompletionFlow'
import recommendationCreationReducer from './recommendationCreation';
import referralsAnalyticsReducer from './referralsAnalytics';
import leaderboardReducer from './leaderboard';

const rootReducer = combineReducers({
    editProfile: editProfileReducer,
    fillProfile: fillProfileReducer,
    profile: profileReducer,
    referrals: referralsReducer,
    contacts: contactsReducer,
    sendReferral: sendReferralReducer,
    sendReferralUserList: sendReferralUserListReducer,
    signIn: signInReducer,
    signUp: signUpReducer,
    referralInformation: referralInformationReducer,
    resetPassword: resetPasswordReducer,
    passwordRecovery: passwordRecoveryReducer,
    newArticle: newArticleReducer,
    settings: settingsReducer,
    chat: chatReducer,
    news: newsReducer,
    bigBoard: bigBoardReducer,
    opportunityCreationFlow: opportunityCreationFlowReducer,
    opportunityCompletionFlow: opportunityCompletionFlow,
    myNetwork: myNetworkReducer,
    recommendationCreationReducer: recommendationCreationReducer,
    referralsAnalytics: referralsAnalyticsReducer,
    leaderboard: leaderboardReducer
});

export default rootReducer;