import {
    SIGN_IN_FAIL,
    SIGN_IN_SUCC,
    SIGN_IN,
    SIGN_OUT,
    UNSUCCESS_LOGIN,
    WRONG_PASSWORD,
    EMAIL_ERROR
} from "../../../../actions/constants";

const initialState = {
    loading: false,
    errors: [],
    captchaEnabled: false,
    wrongPass: false
};

function signInReducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_IN_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.errors = [action.errors];
                return _state;
            }
        case SIGN_IN_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.captchaEnabled = false;
                return _state;
            }
        case SIGN_IN:
            {
                const _state = {
                    ...state
                };
                _state.loading = action.payload ? action.payload.isLoading : true;
                return _state;
            }
        case SIGN_OUT:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                return _state;
            }
            case UNSUCCESS_LOGIN:
            {
                const _state = {
                    ...state
                };
                _state.captchaEnabled = action.payload.captchaEnabled;
                return _state;
            }
            case WRONG_PASSWORD: 
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.wrongPass = true;
                return _state;
            }
        default:
            return state
    }
}

export default signInReducer;