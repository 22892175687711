import {
    SEND_REFERRAL_FAIL,
    SEND_REFERRAL_SUCC,
    SEND_REFERRAL_IS_LOADING,
    SEND_REFERRAL_ADD_USER_TO_REFERRAL,
    SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL,
    SEND_REFERRAL_SAVE_FORM,
    SEND_REFERRAL_CLEAN_FORM,
    SEND_REFERRAL_REMOVE_USER_TO_REFERRAL,
    SEND_REFERRAL_CURRENT_SECTION,
    SEND_REFERRAL_SET_VALUE,
    SEND_REFERRAL_ISVALIDFORM,
    SEND_REFERRAL_CANDIDATES_SET_TYPE,
    SEND_REFERRAL_FORM_SET_VALUE,
    SEND_REFERRAL_FORM_SET_ISVALIDFORM,
    SEND_REFERRAL_SIGNATORY_SET_TYPE,
    SEND_REFERRAL_SIGNATORY_SET_USER,
    SEND_REFERRAL_PREVENT_PROMPT,
    SEND_REFERRAL_FORM_SET_COLLAPSE,
    SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO,
    SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO,
} from "../../../../actions/constants";
import {
    SECTION_FORM,
    SECTION_HOW_TO_SEND,
    SECTION_PROFESSION_TYPE,
    SECTION_REVIEW,
    SECTION_SELECT_CANDIDATES,
    SECTION_SHARE,
    SECTION_SIGNATORY
} from '../../../../../components/ui/presentation/views/SendReferral/constants'

const initShare = {
    title: 'Who do you want to share this referral with?',
    isLoading: false,
    values: {},
}

const initPType = {
    title: 'Select Profession Type',
    isLoading: false,
    values: {}
}
const initCandidates = {
    title: 'Select up to 5 Candidates',
    isLoading: false,
    candidates: [],
    insideReally: true,
    values: {}
}

const initForm = {
    title: 'Complete the Referral Form',
    isLoading: false,
    referralLocation: {
        isValidForm: false,
        values: {

        }
    },
    notificationRadius: {
        isValidForm: false,
        values: {
            notificationRadius: 'ALL'
        }
    },
    description: {
        isValidForm: false,
        values: {

        }
    },
    realStateDetails: {
        collapse: true,
        isValidForm: false,
        values: {
            sizeUnit: 'SQFT'
        }
    },
    timingInfo: {
        isValidForm: false,
        values: {

        }
    },
    fee: {
        isValidForm: false,
        values: {

        }
    },
    client: {
        collapse: true,
        isValidForm: false,
        values: {

        }
    },
    otherInfo: {
        isValidForm: false,
        values: {

        }
    },
    signatory: {
        isValidForm: false,
        values: {

        }
    }
}

const initReview = {
    title: 'Please review your Referral Information',
    isLoading: false,
    values: {}
}

const initSignatory = {
    title: 'Please provide your Authorized Signatory details',
    isLoading: false,
    selectedUser: null,
    insideReally: true
}

const initialState = {
    preventPrompt: true,
    currentSection: SECTION_SHARE,
    currentSubSection: null,
    stepCount: 1,
    isValidForm: false,
    success: false,
    successModalInfo: {
        isOnlyOneMember: false,
        theOnlyMember: null,
        shareWith: null,
    },
    [SECTION_SHARE]: {
        ...initShare
    },
    [SECTION_PROFESSION_TYPE]: {
        ...initPType
    },
    [SECTION_SELECT_CANDIDATES]: {
        ...initCandidates
    },
    [SECTION_HOW_TO_SEND]: {
        title: 'Sending Method',
        isLoading: false,
        values: {}
    },
    [SECTION_FORM]: {
        ...initForm,
        referralLocation: {
            isValidForm: false,
            values: {

            }
        },
        notificationRadius: {
            isValidForm: false,
            values: {
                notificationRadius: 'ALL'
            }
        },
        description: {
            isValidForm: false,
            values: {

            }
        },
        realStateDetails: {
            collapse: true,
            isValidForm: false,
            values: {
                sizeUnit: 'SQFT'
            }
        },
        timingInfo: {
            isValidForm: false,
            values: {

            }
        },
        fee: {
            isValidForm: false,
            values: {

            }
        },
        client: {
            collapse: true,
            isValidForm: false,
            values: {

            }
        },
        otherInfo: {
            isValidForm: false,
            values: {

            }
        },
        signatory: {
            isValidForm: false,
            values: {

            }
        }
    },
    [SECTION_REVIEW]: {
        ...initReview
    },
    [SECTION_SIGNATORY]: {
        ...initSignatory
    }
};

function sendReferralReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_REFERRAL_SUCC: {
            const _state = {
                ...state,
                success: action.payload.success
            };
            return _state;
        }
        case SEND_REFERRAL_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case SEND_REFERRAL_PREVENT_PROMPT: {
            const _state = {
                ...state
            };
            _state.preventPrompt = action.payload.preventPrompt;
            return _state;
        }

        case SEND_REFERRAL_IS_LOADING: {
            const _state = {
                ...state
            };
            _state[action.payload.section].isLoading = action.payload.isLoading;
            return _state;
        }
        case SEND_REFERRAL_ISVALIDFORM: {
            const _state = {
                ...state
            };
            _state.isValidForm = action.payload.isValidForm;
            return _state;
        }
        case SEND_REFERRAL_CURRENT_SECTION: {
            const _state = {
                ...state
            };
            _state.currentSection = action.payload.currentSection;
            _state.stepCount = action.payload.stepCount;
            _state.currentSubSection = action.payload.subSection;
            return _state;
        }
        case SEND_REFERRAL_SET_VALUE: {
            const _state = {
                ...state
            };
            _state[action.payload.section]["values"] = {
                ..._state[action.payload.section]["values"],
                [action.payload.name]: action.payload.value
            };
            return _state;
        }
        case SEND_REFERRAL_ADD_USER_TO_REFERRAL: {
            const _state = {
                ...state
            };
            let to =
                _state &&
                _state[SECTION_SELECT_CANDIDATES] &&
                Array.isArray(_state[SECTION_SELECT_CANDIDATES].candidates)
                    ? [..._state[SECTION_SELECT_CANDIDATES].candidates]
                    : [];
            let userExist = to.find(user => {
                return user.uid === action.payload.user.uid;
            });
            if (!userExist) to.push(action.payload.user);
            _state[SECTION_SELECT_CANDIDATES].candidates = to;
            return _state;
        }

        case SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL: {
            const _state = {
                ...state
            };
            _state[SECTION_SELECT_CANDIDATES].candidates = _state[
                SECTION_SELECT_CANDIDATES
            ].candidates.filter(user => user.uid !== action.payload.uid);

            return _state;
        }
        case SEND_REFERRAL_CANDIDATES_SET_TYPE: {
            const _state = {
                ...state
            };
            _state[SECTION_SELECT_CANDIDATES].insideReally =
                action.payload.insideReally;

            return _state;
        }

        case SEND_REFERRAL_SIGNATORY_SET_TYPE: {
            const _state = {
                ...state
            };
            _state[SECTION_SIGNATORY].insideReally =
                action.payload.insideReally;

            return _state;
        }

        case SEND_REFERRAL_REMOVE_USER_TO_REFERRAL: {
            const _state = {
                ...state
            };
            let to =
                _state && _state.to && Array.isArray(_state.to)
                    ? _state.to
                    : [];
            if (action.payload) {
                const uids = to.map(user => user.uid || user.user_id || "");
                const removableIndex = uids.indexOf(action.payload);
                if (removableIndex !== -1) {
                    to.splice(removableIndex, 1);
                }
            }
            _state.to = [...to];
            return _state;
        }
        case SEND_REFERRAL_SAVE_FORM: {
            const _state = {
                ...state
            };
            _state.form =
                action.payload && action.payload.form
                    ? Object.assign({}, _state.form, action.payload.form)
                    : null;
            let to =
                _state && _state.to && Array.isArray(_state.to)
                    ? _state.to
                    : [];
            if (action.payload && action.payload.to) {
                to.push(action.payload.to);
            }
            _state.to = [...to];
            return _state;
        }
        case SEND_REFERRAL_CLEAN_FORM: {
            const _state = {
                ...initialState,
                [SECTION_SHARE]: {
                    ...initShare
                },
                [SECTION_PROFESSION_TYPE]: {
                    ...initPType
                },
                [SECTION_SELECT_CANDIDATES]: {
                    ...initCandidates
                },
                [SECTION_HOW_TO_SEND]: {
                    title: "Sending Method",
                    isLoading: false,
                    values: {}
                },
                [SECTION_FORM]: {
                    ...initForm,
                    referralLocation: {
                        isValidForm: false,
                        values: {}
                    },
                    notificationRadius: {
                        isValidForm: false,
                        values: {
                            notificationRadius: "ALL"
                        }
                    },
                    description: {
                        isValidForm: false,
                        values: {}
                    },
                    realStateDetails: {
                        collapse: true,
                        isValidForm: false,
                        values: {
                            sizeUnit: "SQFT"
                        }
                    },
                    timingInfo: {
                        isValidForm: false,
                        values: {}
                    },
                    fee: {
                        isValidForm: false,
                        values: {}
                    },
                    client: {
                        collapse: true,
                        isValidForm: false,
                        values: {}
                    },
                    otherInfo: {
                        isValidForm: false,
                        values: {}
                    },
                    signatory: {
                        isValidForm: false,
                        values: {}
                    }
                },
                [SECTION_REVIEW]: {
                    ...initReview
                },
                [SECTION_SIGNATORY]: {
                    ...initSignatory
                }
            };
            _state.isLoading = false;
            return _state;
        }
        case SEND_REFERRAL_FORM_SET_VALUE: {
            let _state = {
                ...state
            };

            _state[SECTION_FORM] = { ..._state[SECTION_FORM] };
            _state[SECTION_FORM][action.payload.formSection]["values"] = {
                ..._state[SECTION_FORM][action.payload.formSection]["values"]
            };
            _state[SECTION_FORM][action.payload.formSection]["values"][
                action.payload.name
            ] = action.payload.value;
            return _state;
        }
        case SEND_REFERRAL_FORM_SET_ISVALIDFORM: {
            const _state = {
                ...state
            };

            _state[SECTION_FORM][action.payload.formSection]["isValidForm"] =
                action.payload.isValidForm;
            return _state;
        }
        case SEND_REFERRAL_FORM_SET_COLLAPSE: {
            const _state = {
                ...state
            };

            _state[SECTION_FORM][action.payload.section]["collapse"] =
                action.payload.collapse;
            return _state;
        }
        case SEND_REFERRAL_SIGNATORY_SET_USER: {
            const _state = {
                ...state
            };

            _state[SECTION_SIGNATORY]["selectedUser"] = {
                ...action.payload.user
            };
            return _state;
        }
        case SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO: {
            const _state = {
                ...state
            };
            _state.successModalInfo = { ...action.payload };
            return _state;
        }
        case SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO: {
            const _state = {
                ...state
            };
            _state.successModalInfo = { ...initialState.successModalInfo };
            return _state;
        }
        default:
            return state;
    }
}

export default sendReferralReducer;
