import { targetToNotifyOptions, visibilityOptions, retailSpaceOptions } from "../../../../constants";

export const formInitialValues = {
    // Static fields
    profession: null,
    address:  null,
    description: '',
    clientSource: null,
    currentSatus: null,
  //  lastContact: new Date(),
    referralFee: null,
    agreement: 'YES',
    cannotFindProfession: false,
    locationIsRemote: null,
    referralCreator: '', 
    pastReferralStatus: '',
    partyConfirmation: true,
    // Required if profession is global.
    targetToNotify: targetToNotifyOptions[0],
    amount: '',
    fee: '',
    // Required if multiple candidates are selected for this referral.
    acceptanceCondition: null,

    // Required if profession is Real Estate Agent
    clientType: null,
    propertyType: null,
    propertySubType: null,

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: '',

    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: '',

    minBeds: '',

    // Required if clientType is 'BUYER'
    financingInfo: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: null,

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: null,
    pets: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL' and propertySubtype is 'MULTI_FAMILY'
    minUnits: '',

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: null,

    realEstateFee: '',
    comments: '',
    flatFeeAmount: null,
    paymentDue: null,
    grossRevenueFee: null,
    feeDuration: null,
    feeFrequency: null,
    feeCommencement: null,
    visibility: visibilityOptions[0],
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    clientAddres: '',
    minPrice: '',
    maxBeds: '',
    minSquareFeet: '',
    maxSquareFeet: '',
    minAcres: '',
    maxAcres: '',
    maxUnits: '',
    units: '',
    acres: '',
    beds: '',
    baths: '',
    squareFeet: '',
    retailSpace: retailSpaceOptions[0],
    clientFirstName: '',
    clientLastName: '',
    clientEmail: '',
    clientMobileNumber: '',
    clientStreetAddress: '',
    clientCity: '',
    clientState: '',
    clientZipCode: '',
    clientInformation: null,
    searchUserMode: true,
};