class LocalStorage {
    constructor(expireTIme) {
        if (expireTIme) this.expireTIme = expireTIme;
        else this.expireTIme = 60000 * 15;
    }

    isExpired(data) {
        if (data) {
            const now = new Date().getTime();
            const date1_ms = data.lastUpdate;
            return date1_ms ? now - date1_ms > this.expireTIme : true;
        }
        return true;
    }

    getUser(uid) {
        if (uid) {
            try {
                let cachedUser = JSON.parse(localStorage.getItem(uid));
                if (cachedUser) {
                    if (this.isExpired(cachedUser)) {
                        // const deleted = delete cachedUsers[uid];
                        localStorage.removeItem(uid);
                        //localStorage.setItem('users', JSON.stringify(cachedUsers));
                        return null;
                    }
                    return cachedUser;
                }
                return null;
            } catch (error) {
                console.log(error);
            }
        } else {
            return null;
        }
    }

    setUser(uid, data) {
        if (uid) {
            try {
                let cachedUser = {
                    ...data
                };
                cachedUser.lastUpdate = new Date().getTime();
                localStorage.setItem(uid, JSON.stringify(cachedUser));
            } catch (error) {
                console.log(error);
            }
        } else {
            return null;
        }
    }

    setUserValue(uid, name, value) {
        if (uid) {
            const data = localStorage.getItem(uid);
            try {
                let cachedUser = {
                    ...data
                };
                cachedUser[name] = value;
                cachedUser.lastUpdate = new Date().getTime();
                localStorage.setItem(uid, JSON.stringify(cachedUser));
            } catch (error) {
                console.log(error);
            }
        } else {
            return null;
        }
    }

    setComeFromGoogle = come => {
        localStorage.setItem("comeFromGoogle", JSON.stringify(come));
    };

    getComeFromGoogle = () => {
        return JSON.parse(localStorage.getItem("comeFromGoogle"));
    };

    setReverseGeocodeResult = (searchVal, result) => {
        if (searchVal) {
            try {
                let reverseGeocodeResults = JSON.parse(
                    localStorage.getItem("reverseGeocodeResults")
                );
                if (!reverseGeocodeResults) reverseGeocodeResults = {};
                reverseGeocodeResults[searchVal] = {
                    data: result,
                    lastUpdate: new Date().getTime()
                };
                localStorage.setItem(
                    "reverseGeocodeResults",
                    JSON.stringify(reverseGeocodeResults)
                );
            } catch (error) {
                console.log("setReverseGeocodeResult localstorage", error);
            }
        } else {
            return null;
        }
    };

    setGeocodeResult(lat, lng, result) {
        if (lat && lng) {
            try {
                const key = `${lat}${lng}`;
                let geocodeResults = JSON.parse(
                    localStorage.getItem("geocodeResults")
                );
                if (!geocodeResults) geocodeResults = {};
                geocodeResults[key] = {
                    data: result,
                    lastUpdate: new Date().getTime()
                };
                localStorage.setItem(
                    "geocodeResults",
                    JSON.stringify(geocodeResults)
                );
            } catch (error) {
                console.log("setGeocodeResult localstorage", error);
            }
        } else {
            return null;
        }
    }

    getReverseGeocodeResult(searchVal) {
        if (searchVal) {
            try {
                let reverseGeocodeResults = JSON.parse(
                    localStorage.getItem("reverseGeocodeResults")
                );
                if (reverseGeocodeResults && reverseGeocodeResults[searchVal]) {
                    if (
                        reverseGeocodeResults[searchVal] &&
                        this.isExpired(reverseGeocodeResults[searchVal])
                    ) {
                        // const deleted = delete cachedUsers[uid];
                        //localStorage.removeItem(reverseGeocodeResults[searchVal]);
                        //localStorage.setItem('users', JSON.stringify(cachedUsers));
                        delete reverseGeocodeResults[searchVal];
                        localStorage.setItem(
                            "reverseGeocodeResults",
                            JSON.stringify(reverseGeocodeResults)
                        );
                        return null;
                    }
                    return reverseGeocodeResults[searchVal].data;
                }
                return null;
            } catch (error) {
                console.log(error);
            }
        } else {
            return null;
        }
    }

    getGeocodeResult(lat, lng) {
        if (lat && lng) {
            try {
                const key = `${lat}${lng}`;
                let geocodeResults = JSON.parse(
                    localStorage.getItem("geocodeResults")
                );

                if (geocodeResults && geocodeResults[key]) {
                    if (this.isExpired(geocodeResults[key])) {
                        // const deleted = delete cachedUsers[uid];
                        //localStorage.removeItem(reverseGeocodeResults[searchVal]);
                        //localStorage.setItem('users', JSON.stringify(cachedUsers));
                        delete geocodeResults[key];
                        localStorage.setItem(
                            "geocodeResults",
                            JSON.stringify(geocodeResults)
                        );
                        return null;
                    }
                    return geocodeResults[key].data;
                }
                return null;
            } catch (error) {
                console.log(error);
            }
        } else {
            return null;
        }
    }
    static setBuildYourTeamCardOpen = open => {
        localStorage.setItem("buildYourTeamCardOpen", JSON.stringify(open));
    };

    static getBuildYourTeamCardOpen = () => {
        return JSON.parse(localStorage.getItem("buildYourTeamCardOpen"));
    };

    static setMaintenanceAlertOpen = date => {
        localStorage.setItem("maintenanceAlertOpen", JSON.stringify(date));
    };
    
    static getMaintenanceAlertOpen = () => {
        return JSON.parse(localStorage.getItem("maintenanceAlertOpen"));
    };
}

export default LocalStorage;
