import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';

const GenericWrapper = styled(Flex)`
position: relative;
/* margin-right: 21px; */
`;

const Badge = styled(Flex)`
position: absolute;
top: 0.2rem;
right: -0.1rem;
height: 0.5rem;
width: 0.5rem;
border-radius: 0.5rem;
margin: auto;
background-color: #1FC07F;
`;

export default props => (
    <GenericWrapper onMouseDown={props.onMouseDown} ref={props.ref}>
        <svg
            id={props.id}
            width={props.width || "17"}
            height={props.height || "22"}
            viewBox="0 0 19 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.5 24C10.875 24 12 23.0609 12 21.913H7C7 23.0609 8.1125 24 9.5 24Z"
                fill={props.color ? props.color : "#B6B6B6"}
            />
            <path
                d="M16.625 17.0751V10.751C16.625 6.86798 14.6775 3.61739 11.2812 2.75731V1.89723C11.2812 0.847431 10.4856 0 9.5 0C8.51437 0 7.71875 0.847431 7.71875 1.89723V2.75731C4.31063 3.61739 2.375 6.85534 2.375 10.751V17.0751L0 19.6047V20.8696H19V19.6047L16.625 17.0751Z"
                fill={props.color ? props.color : "#546173"}
            />
        </svg>
        {props.enabled ? (
            <Badge justifyContent="center" alignItems="center"></Badge>
        ) : null}
    </GenericWrapper>
);
