import styled from 'styled-components';

export const Navbar = styled.div`
user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */  
    z-index: 22;
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '100%'};
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-bottom: ${props => props.withBorder ? '0.5px solid #D8D8D8' : 'none'};
    justify-content: space-around;
`;

export const NavbarItem = styled.div`
    height: 100%;
    padding-left: .5em;
    padding-right: .5em;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-bottom: ${props => props.selected ? `5px solid ${props.theme.greenMedium}`  : 'none' };
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    margin-left: 32px;
    margin-right: 28px;
    min-width: fit-content;
`;

export const NavbarText = styled.div`
    width: 100%;
    font-family: "Inter UI"!important;
    text-align: center;
    font-style: normal;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '16px'}!important;
    color: ${props => props.disabled ? props.theme.darkGray : props.selected ? props.theme.newBlue : props.theme.coolBlack};
    font-weight:  ${props => props.selected ? 'bold': 'normal' };	
    letter-spacing: -0.43px;	
    line-height: 21px;
    margin-bottom: ${props => props.selected ? `-5px`  : '0px' };
    display: flex;
    align-items: center;
`

export const NavbarDropdownText = styled(NavbarText)`
    margin-bottom: 0;
    margin-left: .5rem;
    color: ${({ selected, theme }) => selected ? theme.newBlue : theme.coolBlack};

`

export const DropdownNavbarItem = styled(NavbarText)`
    padding: ${({ isFirst, isLast }) => isFirst ? '0 0 1rem 0' : isLast ? '1rem 0 0 0' : '1rem 0' };
    &:hover {
        *:not([fill='white']) {
            transition: all .3s ease;
            color: ${({ theme }) => theme.newBlue};
            fill: ${({ theme }) => theme.newBlue};
            }
    }
`

export const NavbarDropdownContainer = styled.div`
    cursor: pointer;
    padding-right: 20px;
    position: relative;
    
`