import React from "react"

export const SecondLogo = (props) => {
  return (
    <svg
      width={480}
      height={200}
      viewBox="0 0 480 235"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0H480V235H0z" />
      <path
        d="M152.85 106.991c0-28.246-22.966-51.13-51.314-51.13s-51.315 22.884-51.315 51.13v51.131h22.807v-51.131c0-15.663 12.788-28.406 28.508-28.406 15.719 0 28.508 12.743 28.508 28.406v51.131h22.806v-51.131zM199.422 161.52c-21.794 0-39.485-17.68-39.485-39.343V82.833h22.807v39.344c0 9.185 7.46 16.619 16.678 16.619 9.219 0 16.679-7.434 16.679-16.619V82.833h22.806v39.344c0 21.716-17.691 39.343-39.485 39.343z"
        fill="#28372D"
      />
      <path
        d="M297.308 59.259c-28.348 0-51.314 22.884-51.314 51.131 0 28.246 22.966 51.13 51.314 51.13 28.349 0 51.315-22.884 51.315-51.13 0-28.247-22.966-51.131-51.315-51.131zm0 79.537c-15.719 0-28.508-12.743-28.508-28.406 0-15.663 12.789-28.406 28.508-28.406 15.72 0 28.508 12.743 28.508 28.406 0 15.663-12.788 28.406-28.508 28.406zM391.891 82.833h-37.78v21.769h37.78c8.792 0 15.932 7.115 15.932 15.876 0 8.76-7.14 15.875-15.932 15.875h-37.78V179.944h21.528v-21.822h16.252c20.835 0 37.78-16.884 37.78-37.644-.054-20.76-16.999-37.645-37.78-37.645z"
        fill="#00A15F"
      />
    </svg>
  )
}

