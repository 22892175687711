import {
    SIGN_UP_FAIL,
    SIGN_UP_SUCC,
    SIGN_UP
} from "../../../../actions/constants";
const initialState = {
    loading: false,
    errors: []
}

function signUpReducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_UP_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.errors = [action.errors];
                return _state;
            }
        case SIGN_UP_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                return _state;
            }
        case SIGN_UP:
            {
                const _state = {
                    ...state
                };
                _state.loading = true;
                return _state;
            }
        default:
            return state
    }
}

export default signUpReducer;