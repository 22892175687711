import React, { useState, useEffect } from "react";
import { Box, Flex } from "rebass";
import styled, { withTheme } from "styled-components";
import Link from "../../../ui/presentation/atoms/Link";
import HorizontalLine from "../../../ui/presentation/atoms/shared/HorizontalLine";
import { Text } from "../../../ui/presentation/atoms/shared/Text";

import { Icon } from "../../../ui/presentation/atoms/shared/Icon";
// import { CopyButton, ShareLinkContainer } from "../../../ui/presentation/views/Settings/Invites/SendInvite";
import Tooltip from "../../../ui/presentation/atoms/shared/Tooltip";

export const ShareLinkContainer = styled.div`
    border-radius: ${({ theme }) => theme.spacing[1]};
    background-color: ${({ theme }) => theme.clearGray};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[4]};
`;

export const CopyButton = styled(Text)`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    :hover {
        color: ${({ theme }) => theme.shamRockGreen};
        transition: all 0.2s ease-in-out;
    }
`;

const SignInKnowingUserRightContent = ({ theme, lastLoggedInUser }) => {
    const [urlToShare, setUrlToShare] = useState(null);
    const [copied, setCopied] = useState(false);
    const userName = lastLoggedInUser && lastLoggedInUser.userName;
    const makeShareUri = () => {
        const newUrl = `${process.env.REACT_APP_INVITESURL_SHORTER}${userName}`;
        const encodedUrl = encodeURIComponent(newUrl);
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=Sign+Up+NuOp&summary=NuOp&source=nuop.com`;
        const instagramUrl = `https://www.instagram.com?url=${encodedUrl}`;
        setUrlToShare({
            urlToShare: newUrl,
            facebookUrl,
            twitterUrl,
            linkedinUrl,
            instagramUrl
        });
        return;
    };

    useEffect(makeShareUri, [userName]);

    const copyToClipboard = e => {
        navigator.clipboard.writeText(urlToShare.urlToShare);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    const socialItem = (social = "", onClickHandler) => {
        let socialLow = social.toLowerCase();
        const size = 24;

        const shareLinkHandler = url => {
            window.open(url);
        };
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                onClick={e => shareLinkHandler(onClickHandler)}
                style={{ cursor: "pointer" }}
                mt={[16, 16, 0, 0]}
            >
                <Icon
                    icon={socialLow}
                    color={theme.clearGray}
                    width={size}
                    height={size}
                />
                <Link
                    style={{ marginLeft: ".8em" }}
                    fontSize="XS"
                    color={theme.clearGray}
                    underlineColor={theme.clearGray}
                >
                    {social}
                </Link>
            </Flex>
        );
    };
    return (
        <>
            <Box>
                <Text
                    fontSize={theme.mobileDevice ? "L" : "XXXL"}
                    fontWeight="bold"
                    color={theme.clearGray}
                >
                    Welcome Back
                    {lastLoggedInUser &&
                        lastLoggedInUser.name && ` ${lastLoggedInUser.name}!`}
                </Text>
            </Box>
            <Box mt={theme.spacing[10]} mb={theme.spacing[16]}>
                <Text
                    fontSize={theme.mobileDevice ? "M" : "L"}
                    color={theme.clearGray}
                    style={{ fontWeight: "600" }}
                >
                    Why not Invite & Earn right now?
                </Text>
            </Box>
            <Box>
                <Text
                    fontSize={theme.mobileDevice ? "S" : "M"}
                    color={theme.clearGray}
                    style={{ fontWeight: "400" }}
                    lineHeight="S"
                >
                    If any referral partners accept your invite to join, you
                    will earn 10% of all revenue that NuOp generates from your
                    referral partner within 24 months of their initial purchase
                    date.
                </Text>
            </Box>
            <HorizontalLine style={{ margin: `${theme.spacing[10]} 0` }} />
            <Box>
                <Text
                    fontSize={theme.mobileDevice ? "S" : "M"}
                    color={theme.timberwolf}
                    fontWeight="bold"
                >
                    Invite via social media
                </Text>
            </Box>
            <Flex justifyContent={"space-around"} mt={theme.spacing[5]}>
                {socialItem("Facebook", urlToShare && urlToShare.facebookUrl)}
                {socialItem("Twitter", urlToShare && urlToShare.twitterUrl)}
                {socialItem("Linkedin", urlToShare && urlToShare.linkedinUrl)}
            </Flex>
            <Box mt={theme.spacing[8]}>
                <Text
                    fontSize={theme.mobileDevice ? "S" : "M"}
                    color={theme.timberwolf}
                    fontWeight="bold"
                >
                    Share your link
                </Text>
            </Box>
            <ShareLinkContainer>
                <Text color={theme.taupeGray} fontSize="XXS">
                    {urlToShare && urlToShare.urlToShare}
                </Text>

                <Tooltip text={copied ? "Copied!" : "Copy to clipboard"}>
                    <CopyButton
                        onClick={copyToClipboard}
                        color={theme.greenMedium}
                        fontSize="XS"
                        fontWeight="bold"
                    >
                        Copy
                    </CopyButton>
                </Tooltip>
            </ShareLinkContainer>
        </>
    );
};

export default withTheme(SignInKnowingUserRightContent);