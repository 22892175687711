import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FormConsumer } from "../../../../../../../hocs/Form2";
import { BlueInputField } from "./datePicker-styles";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Utils from "../../../../../../../../libs/Utils";

class DateSafari extends PureComponent {
    onChange = val => {
        const value = val ? Utils.convertLongStringDatetoShortDate(val.toString()) : '';

        this.props._setValue(
            this.props.name,
            value,
            this.props.rules,
            true
        );
        if (this.props.onChange) this.props.onChange(value, this.props.name)
    };

    render() {
        const props = this.props;
        const {
            color,
            bg,
            name,
            values,
            rules,
            validation,
            placeholder,
            touched,
            leftIcon,
            customInput,
            inputStyles,
            placeHolderStyles,
            labelStyles,
            onBlur,
            error,
            ...others
        } = props;

        const selected = values && values[name] ? moment(values[name]).toDate() : '';
        const showedValue = values[name] ? moment(values[name]).format("L") : false;
        const InputToRender = customInput ? customInput : BlueInputField
        //{showedValue ? showedValue : placeholder}
        return (
            <DatePicker
                {...others}
                tabIndex='0'
                //value={value}
                // onSelect={this.onChange}
                placeholderText={placeholder}
                selected={selected}
                onChange={this.onChange}
                required
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                onBlur={onBlur}
                customInput={
                    <InputToRender
                        error={error}
                        onBlur={() => {return onBlur }}
                        rules={rules}
                        inputStyles={inputStyles}
                        placeHolderStyles={placeHolderStyles}
                        labelStyles={labelStyles}
                        autocomplete="false"
                        ref={input => {
                            this.nameInput = input;
                        }}
                        leftIcon={leftIcon}
                        type={props.type}
                        color={color}
                        bg={bg}
                        name={name}
                        value={showedValue}
                        validation={touched ? validation : null}
                        placeholder={placeholder}
                        onFocus={this.onFocus}
                    >

                    </InputToRender>
                }
            />
        );
    }
}

DateSafari.propTypes = {
    functions: PropTypes.shape({
        setValue: PropTypes.func
    }),
    type: PropTypes.oneOf(["date"]),
    name: PropTypes.string,
    value: PropTypes.string,
    validation: PropTypes.shape({
        isInvalid: PropTypes.bool,
        message: PropTypes.string
    }),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

export default ({ children, ...otherProps }) => (
    <FormConsumer>
        {({ ...consumerProps }) => {
            return <DateSafari {...otherProps} {...consumerProps} />;
        }}
    </FormConsumer>
);
