import React, { useState, useEffect, Component, useRef } from "react";
import isGlobalModal from "../../hocs/GlobalModal";
import {
    rehydrateUser,
    setSocialSignUpCallback,
    removeFirebaseSubscriptions,
    fetchUserIpData,
    logLastLogin
} from "../../../redux/actions/creators/user";
import {
    fetchMaitenanceMode, logAppError, startClarity, updatePWA
} from "../../../redux/actions/creators/shared";
import {
    getNearUsersByProfileLocation,
    getNearUsersByBrowserLocation
} from "../../../redux/actions/creators/relations";
import Router from "../Router";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { theme, MEDIABREAKPOINTS } from "../../../theme";

import {
    setServices,
    setChatBotOpen
} from "../../../redux/actions/creators/shared";
import { onIdTokenChanged, checkStripeCustomerId } from "../../../redux/actions/creators/user";
/* import {
    fetchIncomingOpenReferrals
} from "../../../redux/actions/creators/referrals"; */
import SplashScreen from "../../ui/presentation/atoms/SplashScreen";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewContentAvailable from "../../ui/presentation/views/NewContent";
import ErrorPage from "../../ui/presentation/views/ErrorPage";
import MaitenanceMode from "../../ui/presentation/views/MaintenanceModeView";
import packageJson from "../../../../package.json";
// import * as Sentry from "@sentry/browser";
import {  StripeContext } from "../../context/stripeContext";
import { enviroment } from "../../../config";
import AllowAccessToDevelopment from "./AllowAccessToDevelopment";
import { usePostHog } from "posthog-js/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function getWidth() {
    return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    );
}

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
);

const excludedPathsFromForceRefresh = ['/op-creation', '/create-recommendation', '/chat']

class ErrorBoundary extends Component {
    state = {
        hasError: false
    };

    componentDidCatch = error => {
        this.setState({ hasError: true });
        console.log(error);
        if (!toast.isActive("newPWAVersionAvailable")) {
            let message = "Whoops! Something went wrong.";

            if (this.props.newPWAVersionAvailable) message += " Click here to get the latest version!";

            toast.error(message, {
                position:
                    window.innerWidth < MEDIABREAKPOINTS.tablet
                        ? toast.POSITION.TOP_CENTER
                        : toast.POSITION.BOTTOM_RIGHT,
                autoClose: !this.props.newPWAVersionAvailable && 3000,
                closeOnClick: true,
                onClick: () =>
                    this.props.newPWAVersionAvailable &&
                    this.props.dispatch(updatePWA()),
                toastId: "errorMessage"
            });
        }
        this.props.dispatch(
            logAppError(error)
        )
    };

    render() {
        return this.props.children;
    }
}

const App = (props) => {
    const [width, setWidth] = useState(0)
    const {
        dispatch,
        rehydrated,
        services,
        uid,
        isLoading,
        isLoggedIn,
        center,
        customer_id,
        nearUsers,
        nearUsersBrowser,
        nearUsersRequestAttempts,
        nearUsersBrowserRequestAttempts,
        newPWAVersionAvailable,
        forceRefresh,
        modal,
        maitenanceMode,
        allowAccessToDev,
        user
    } = props;



    // ----------------- POSTHOG ----------------- //
    const posthog = usePostHog();

    useEffect(() => {
        if (user && posthog && uid) {
            posthog.identify(uid, {
                email: user.email,
                name: `${user.name} ${user.lastname}`
            })
        }
    }, [posthog, uid, user.email, user.name, user.lastname])

    // ----------------- END OF POSTHOG ----------------- //

    // ----------------- CLARITY ----------------- //
    useEffect(() => {
        if (user && uid) {
            dispatch(startClarity());
        }
    }, [uid, user.email, user.name, user.lastname])
    // ----------------- END OF CLARITY ----------------- //

    useEffect(() => {
        dispatch(fetchMaitenanceMode())
        onResizeEvent();
        dispatch(fetchUserIpData());
        console.log("VERSION: ", packageJson.version);
        if (!rehydrated) {
            const params = new URLSearchParams(window.location.search)

            dispatch(setSocialSignUpCallback());
            dispatch(rehydrateUser(params.get('auth')));
        }
        dispatch(onIdTokenChanged());
        if (window.addEventListener) {
            window.addEventListener("resize", onResizeEvent, true);
        }
        return () => {
            if (uid)
                dispatch(removeFirebaseSubscriptions(uid));
        }
    }, []);

    useEffect(() => {
        if (center && isLoggedIn && uid) {
            //incomingOpenReferrals();
            getNearUsersBrowserLocation();
            getNearUsersProfileLocation();
            setChatBot();
        }

    }, [center, isLoggedIn, uid])

    useEffect(() => {
        if (isLoggedIn && uid && !customer_id) {
            dispatch(checkStripeCustomerId)
        }

        if (isLoggedIn && uid) {
            dispatch(logLastLogin());
        }

    }, [isLoggedIn, uid])



    useEffect(() => {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDLVXuMljpeBE02_xg_9nCkgLP1YFguNSs&libraries=places`;
        googleMapScript.async = true;
        window.document.body.appendChild(googleMapScript);
        /* googleMapScript.addEventListener('load', () => {
            // getLatLng();
        }); */

    }, [])

    // geolocationManager = new GeolocationManager();

    /* const incomingOpenReferrals = (force = false) => {
        if (isLoggedIn && uid) {
            let userProfileLat =
                center && center[0]
                    ? center[0]
                    : false;
            let userProfileLon =
                center && center[1]
                    ? center[1]
                    : false;

            let currentLat = services &&
                services.center && services.center.lat
                ? services.center.lat
                : false;
            let currentLon =
                services.center && services.center.lon
                    ? services.center.lon
                    : false;

            const {
                geolocationInitialized,
                geolocationAvailiable,
                geolocationRealtimeSubscribed,
                geolocationProfileSubscribed
            } = services;

            if (
                geolocationInitialized &&
                geolocationAvailiable &&
                (!geolocationRealtimeSubscribed || force) &&
                currentLat &&
                currentLon
            ) {
                dispatch(
                    setServices({
                        geolocationLoading: false,
                        geolocationRealtimeSubscribed: true
                    })
                );

                dispatch(
                    fetchIncomingOpenReferrals(currentLat, currentLon)
                );
            }

            if (
                userProfileLat &&
                userProfileLon &&
                (!geolocationProfileSubscribed || force)
            ) {
                dispatch(
                    setServices({
                        geolocationLoading: false,
                        geolocationProfileSubscribed: true
                    })
                );

                dispatch(
                    fetchIncomingOpenReferrals(
                        userProfileLat,
                        userProfileLon
                    )
                );
            }
        }
    }; */

    const verifyUserEmailVerificated = () => {
        if (isLoggedIn && uid) {
            dispatch(verifyUserEmailVerificated(uid));
        }
    };

    const setChatBot = () => {
        const { chatBot } = services;
        if (!chatBot && width < MEDIABREAKPOINTS.tablet) {
            const response = dispatch(setChatBotOpen("hide"));
            if (response)
                dispatch(
                    setServices({
                        chatBot: true
                    })
                );
        }
    };

    const getNearUsersProfileLocation = () => {
        const nearUsersLoaded = nearUsers && nearUsers.loaded;
        const nearUsersLoading =
            nearUsers && nearUsers.isLoading;
        if (
            isLoggedIn &&
            uid &&
            !nearUsersLoaded &&
            !nearUsersLoading &&
            nearUsersRequestAttempts < 2
        ) {
            let userProfileLat =
                center && center[0]
                    ? center[0]
                    : false;
            let userProfileLon =
                center && center[1]
                    ? center[1]
                    : false;

            let lat, lon;

            if (userProfileLat && userProfileLon) {
                lat = userProfileLat;
                lon = userProfileLon;
            }
            if (lat && lon) {
                dispatch(
                    getNearUsersByProfileLocation(uid, lat, lon)
                );
            }
        }
    };

    const getNearUsersBrowserLocation = () => {

        const {
            geolocationInitialized,
            geolocationAvailiable
        } = services;
        const nearUsersLoaded =
            nearUsersBrowser && nearUsersBrowser.loaded;
        const nearUsersLoading =
            nearUsersBrowser && nearUsersBrowser.isLoading;

        if (
            isLoggedIn &&
            uid &&
            geolocationInitialized &&
            geolocationAvailiable &&
            !nearUsersLoaded &&
            !nearUsersLoading &&
            nearUsersBrowserRequestAttempts < 2
        ) {
            let currentLat =
                services.center && services.center.lat
                    ? services.center.lat
                    : false;
            let currentLon =
                services.center && services.center.lon
                    ? services.center.lon
                    : false;

            if (
                geolocationInitialized &&
                geolocationAvailiable &&
                currentLat &&
                currentLon
            ) {
                dispatch(
                    getNearUsersByBrowserLocation(
                        uid,
                        currentLat,
                        currentLon
                    )
                );
            }
        }
    };

    const onResizeEvent = () => {
        const newWidth = getWidth();
        setWidth(newWidth);
    };

    const shouldForceRefresh =
        forceRefresh &&
        !excludedPathsFromForceRefresh.includes(window.location.pathname);

    const geolocationLoading = services.geolocationLoading;
    const { GlobalModal, borrow } = isGlobalModal(modal);
    /* const ipCountry = this.props.ipInfo.country
    const ipCountry = "India"
    const blockedUser = this.props.blockedCountries.includes(ipCountry)
    */
    const mainTenanceUser = JSON.parse(
        localStorage.getItem("mainTenanceUser")
    );
    if (enviroment === "development" && !allowAccessToDev && !isLocalhost )
        return (
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <AllowAccessToDevelopment />
                </ErrorBoundary>
            </ThemeProvider>
        );
    if (!newPWAVersionAvailable && maitenanceMode &&  !mainTenanceUser)
        return <MaitenanceMode />;
    if (shouldForceRefresh)
        return (
            <NewContentAvailable
                theme={{
                    ...theme,
                    width: width,
                    mobileDevice: width < MEDIABREAKPOINTS.tablet,
                    mediaBreakpoints: MEDIABREAKPOINTS
                }}
            />
        );
    if (!shouldForceRefresh)
        return !isLoading ? (
            <ThemeProvider
                theme={{
                    ...theme,
                    width: width,
                    mobileDevice: width < MEDIABREAKPOINTS.tablet,
                    mediaBreakpoints: MEDIABREAKPOINTS
                }}
            >
                <ErrorBoundary
                    dispatch={dispatch}
                    newPWAVersionAvailable={newPWAVersionAvailable}
                >
                    <StripeContext.Provider
                        value={{
                            customer_id: customer_id ? customer_id : false
                        }}
                    >
                        <Router
                            geolocationLoading={geolocationLoading}
                            //user={this.props.user}
                        />
                    </StripeContext.Provider>
                    <GlobalModal borrow={borrow} />
                </ErrorBoundary>
                <ToastContainer />
            </ThemeProvider>
        ) : (
            <SplashScreen />
        );
}

const mapStateToProps = state => {
    const allowAccessToDev =
        Boolean(localStorage.getItem("allowAccessToDev")) ||
        state.main.ui.global.allowAccessToDev.result;
    return {
        user: state.main.user.profile,
        nearUsersBrowser: state.main.user.nearUsersBrowser,
        nearUsers: state.main.user.nearUsers,
        newPWAVersionAvailable: state.main.ui.global.newPWAVersionAvailable,
        forceRefresh: state.main.ui.global.forceRefresh,
        modal: state.main.ui.global.modal,
        allowAccessToDev,
        maitenanceMode: state.main.ui.global.maitenanceMode,
        rehydrated:
            state.main.user.rehydrated.profile &&
            state.main.user.rehydrated.firebase,
        isLoading: state.main.user.isLoading,
        isLoggedIn: state.main.user.isLoggedIn,
        uid: state.main.user.uid,
        center:
            state.main.user &&
            state.main.user.profile &&
            state.main.user.profile.location
                ? state.main.user.profile.location
                : null,
        services: state.main.ui.global.services,
        customer_id:
            state.main.user &&
            state.main.user.profile &&
            state.main.user.profile.customer_id,
        //blockedCountries: state.main.user.blockedCountries,
        // ipInfo: state.main.user.ipInfo,
        nearUsersRequestAttempts: state.main.user.nearUsers.attempts,
        nearUsersBrowserRequestAttempts:
            state.main.user.nearUsersBrowser.attempts
    };
    
};

export default connect(mapStateToProps)(App);
