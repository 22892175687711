import React from "react";
import styled from "styled-components";
import {
    MainText,
    StepWrapper,
    selectStyle,
    Button,
    ButtonContainer
} from "../../../components";
import Select from "react-select";
import { Icon } from "../../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import * as yup from "yup";
import { Formik } from "formik";
import Card from "../../../../../atoms/shared/Card2";
import { Box, Flex } from "rebass";
import { Title } from "../../../../../atoms/shared/Text";
import Avatar from "../../../../../atoms/shared/AvatarCircle2";
import {
    DataTitle,
    DataValue,
    Desc,
    DataPlan
} from "../../../../ReferralInformation/GeneralInfo/styles";
import { Status } from "../../../../ReferralInformation/referralInformation-styles";
import moment from "moment";
import { DraftModal, useDraft } from "../../../useDraft";
import Spinner from "../../../../../../../../assets/svg/Spinner";
import { ErrorMessage, SuccesReferralSent  } from './SuccessReferral_'
import { extractCandidateData } from "../../../../../../../../libs/Utils";
import { PopupLoader } from "../../../../../atoms/shared/PopupLoader";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5em;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Spacer = styled.div`
    margin: 1em 0;
`;

const JobContractorPreview = ({ setStepData, stepData, t, sendingJobData, referralIsLoading, successReferral, errorReferral }) => {
    const {
        saveDraft,
        loading,
        showModal,
        setShowModal,
        modalMessage
    } = useDraft();
  
const { 
    CANDIDATES_SELECTION, 
    JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION, 
    PAST_JOB
} = stepData && stepData



const { acceptance, 
    acres, 
    address, 
    baths, 
    beds, 
    cannotFindProfession, 
    clientType, 
    compensation, 
    credit, 
    description, 
    estimatedPrice,
    financingInfo,
    homeSaleContingency,
    locationIsRemote,
    maxAcres,
    maxBeds,
    maxPrice,
    maxSquareFeet,
    maxUnits,
    minAcres,
    minBeds,
    minLotSize,
    minSquareFeet,
    minUnits,
    pets,
    profession,
    professionDescription,
    propertySubType,
    propertyType,
    squareFeet,
    timingInfo,
    units,
    visibilityAfterExchange
} = JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION

const selectedCandidates = CANDIDATES_SELECTION.length > 0 ? CANDIDATES_SELECTION : ['no data']


const renderRealStateDetails = profession && profession.value === "100";

// job i know FC-FC
// 
 

  const openTo = CANDIDATES_SELECTION.length === 1 ? 'Direct' : CANDIDATES_SELECTION.length > 1 ? 'FC-FS' : 'OPEN'

    return (
        <StepWrapper>
            <DraftModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalMessage={modalMessage}
            />
            <MainText>Please review your Job information</MainText>
            <Spacer>
                <Card
                    borderRadius={"8px 8px 8px 8px"}
                    status="PENDING"
                    padding="30px" 
                >
                     <Title>General Information</Title>
     

                       <Box  mt={["1.5em"]}>
                                <Flex
                                        alignItems="flex-start"
                                        flexDirection={["column", "column", "row", "row"]}
                                        justifyContent={
                                            renderRealStateDetails
                                                ? "space-between"
                                                : "flex-start"
                                        }
                                        flexWrap="wrap"
                                    >
                            <Box mr={["1.5em"]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Method:</DataTitle>
                                    <DataValue>Job</DataValue>
                                </Flex>
                                {profession && profession.label && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Looking For:</DataTitle>
                                        <DataValue>
                                            {profession && profession.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {!profession && cannotFindProfession && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Looking For:</DataTitle>
                                        <DataValue>
                                            {professionDescription}
                                        </DataValue>
                                    </Flex>
                                )}
                                {address && address.description && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Location:</DataTitle>
                                        <DataValue>
                                            {address && address.description}
                                        </DataValue>
                                    </Flex>
                                )}
                                {locationIsRemote &&
                                    locationIsRemote.value === "REMOTE" && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>Location:</DataTitle>
                                            <DataValue>Remote</DataValue>
                                        </Flex>
                                    )}
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Created:</DataTitle>
                                    <DataValue>{`${moment(new Date()).format(
                                        "MMMM Do YYYY"
                                    )}`}</DataValue>
                                </Flex>
                                {compensation  && 
                                    <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>Compensation:</DataTitle>
                                            <DataValue>{ compensation }</DataValue>
                                    </Flex>
                                 }   
                                {clientType && clientType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Client Type:</DataTitle>
                                        <DataValue>
                                            {clientType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {propertyType && propertyType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Property Type:</DataTitle>
                                        <DataValue>
                                            {propertyType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {propertySubType && propertySubType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>
                                            Property Sub-Type:
                                        </DataTitle>
                                        <DataValue>
                                            {propertySubType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                            </Box>
                            <Box mr={["1.5em"]}>
                                {maxPrice && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Price:</DataTitle>
                                    {/*     <DataValue>
                                            Up to {maxPrice}{" "}
                                            {clientType &&
                                            clientType.value === "TENANT" &&
                                            propertyType &&
                                            propertyType.value === "COMMERCIAL"
                                                ? `per ${retailSpace.label}`
                                                : ""}
                                        </DataValue> */}
                                    </Flex>
                                )}
                                {minBeds && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min Beds:</DataTitle>
                                        <DataValue>{minBeds}</DataValue>
                                    </Flex>
                                )}
                                {estimatedPrice && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Estimated Price:</DataTitle>
                                        <DataValue>
                                       {/*      {estimatedPrice}{" "}
                                            {clientType &&
                                            clientType.value === "LANDLORD" &&
                                            propertyType &&
                                            propertyType.value === "COMMERCIAL"
                                                ? `per ${retailSpace.label}`
                                                : ""} */}
                                        </DataValue>
                                    </Flex>
                                )}
                                {financingInfo && financingInfo.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Financing Info:</DataTitle>
                                        <DataValue>
                                            {financingInfo.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {homeSaleContingency &&
                                    homeSaleContingency.value && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>
                                                Home Sale Contingency?:
                                            </DataTitle>
                                            <DataValue>
                                                {homeSaleContingency.label}
                                            </DataValue>
                                        </Flex>
                                    )}
                                {credit && credit.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Credit:</DataTitle>
                                        <DataValue>{credit.label}</DataValue>
                                    </Flex>
                                )}
                                {pets && pets.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Pets:</DataTitle>
                                        <DataValue>{pets.label}</DataValue>
                                    </Flex>
                                )}
                                {minLotSize && minLotSize.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min Lot Size:</DataTitle>
                                        <DataValue>
                                            {minLotSize.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {maxUnits && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units Max:</DataTitle>
                                        <DataValue>{maxUnits}</DataValue>
                                    </Flex>
                                )}
                                {units && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units:</DataTitle>
                                        <DataValue>{units}</DataValue>
                                    </Flex>
                                )}
                                {minUnits &&
                                    typeof minUnits === "object" &&
                                    minUnits.value && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>Units Min:</DataTitle>
                                            <DataValue>
                                                {minUnits.value}
                                            </DataValue>
                                        </Flex>
                                    )}
                                {minUnits && typeof minUnits !== "object" && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units Min:</DataTitle>
                                        <DataValue>{minUnits}</DataValue>
                                    </Flex>
                                )}
                                {minSquareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min SqFt:</DataTitle>
                                        <DataValue>{minSquareFeet}</DataValue>
                                    </Flex>
                                )}
                                {maxSquareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Max SqFt:</DataTitle>
                                        <DataValue>{maxSquareFeet}</DataValue>
                                    </Flex>
                                )}
                                {beds && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Beds:</DataTitle>
                                        <DataValue>{beds}</DataValue>
                                    </Flex>
                                )}
                                {baths && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Baths:</DataTitle>
                                        <DataValue>{baths}</DataValue>
                                    </Flex>
                                )}
                                {squareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>SqFt:</DataTitle>
                                        <DataValue>{squareFeet}</DataValue>
                                    </Flex>
                                )}
                                {acres && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Acres:</DataTitle>
                                        <DataValue>{acres}</DataValue>
                                    </Flex>
                                )}
                            </Box>
                            <Box>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Code:</DataTitle>
                                    <DataValue>To be defined</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Open To:</DataTitle>
                                    <DataValue>{openTo}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Status:</DataTitle>
                                    <Status statusColor={"PENDING"}>
                                        Available
                                    </Status>
                                </Flex>
                            </Box>
                        </Flex>
                       </Box>
                        <Box mt={["1.5em"]}>
                        <Flex width={[1]} mb={[".5em"]}>
                            <DataValue>{description}</DataValue>
                        </Flex>
                    </Box>
                </Card>
            </Spacer>
     
            <ButtonContainer>
                <Box mr={16}>
                    <Button
                        width="120px"
                        onClick={saveDraft}
                        disabled={loading}
                    >
                        Save Draft
                    </Button>
                </Box>
                {referralIsLoading ? 
                    <Button disabled={false} type="submit">
                      <Spinner color={"white"} width="14px" height="14px" />
                    </Button>

                  :
                  <Button onClick={() => sendingJobData()} disabled={false} type="submit">
                     Submit
                  </Button>

                }
            </ButtonContainer>
            { referralIsLoading && <PopupLoader />  }
            {successReferral && <SuccesReferralSent/>}
            {errorReferral && <ErrorMessage />}
        </StepWrapper>
    );
};

export default JobContractorPreview;
