import {
    PASSWORD_RECOVERY_FAIL,
    PASSWORD_RECOVERY_SUCC,
    PASSWORD_RECOVERY_ISLOADING,
    PASSWORD_RECOVERY_INIT,
    PASSWORD_RECOVERY_CLOSE_MODAL
} from "../../../../actions/constants";

const initialState = {
    isLoading: false,
    passwordChanged: false,
    errors: [],
    openModal: false
};

function passwordRecoveryReducer(state = initialState, action) {
    switch (action.type) {
        case PASSWORD_RECOVERY_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case PASSWORD_RECOVERY_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case PASSWORD_RECOVERY_SUCC: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.passwordChanged = true;
            _state.openModal = true;
            return _state;
        }
        case PASSWORD_RECOVERY_CLOSE_MODAL: {
            const _state = {
                ...state
            };
            _state.openModal = false;
            return _state;
        }
        case PASSWORD_RECOVERY_INIT: {
            return { ...initialState };
        }
        default:
            return state;
    }
}

export default passwordRecoveryReducer;