import React from "react";
import PopupModal from "../../../../../../atoms/shared/PopupBlurModal";
import {
    AttentionPopStyled,
    AttentionText,
    ButtonContainer,
    Button,
    ModalContainer
} from "./Attention.styles";

export const AttentionPoUp = ({ callback, setOpenAttentionModel, setStepData, values }) => {
    return (
        <PopupModal
            open={true}
            modalContentStyles={{
                background: "transparent"
            }}
        >
            <AttentionPopStyled>
                <ModalContainer>
                    <EyeIcon />
                    <AttentionText>
                        You are currently in the process of creating a past
                        referral. Please note that NuOp will not create a
                        binding referral agreement for a past referral. The
                        information you enter here is simply for tracking
                        purposes.{" "}
                    </AttentionText>
                    <ButtonContainer>
                        <Button
                            close
                            onClick={setOpenAttentionModel}
                        >
                            Close
                        </Button>
                        <Button onClick={() => setStepData(values)} type="submit" continue>
                            Continue
                        </Button>
                    </ButtonContainer>
                </ModalContainer>
            </AttentionPopStyled>
        </PopupModal>
    );
};



const EyeIcon = () => (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 6C6.48715 6 0.342387 15.2112 0.23125 15.3789C0.081657 15.5556 0.000161731 15.7744 0 16C0.00018923 16.1935 0.060256 16.3828 0.172917 16.5449L0.177083 16.5508C0.195423 16.5863 5.35342 26 16 26C26.6014 26 31.7518 16.6787 31.8146 16.5645C31.8188 16.558 31.823 16.5515 31.8271 16.5449C31.9397 16.3828 31.9998 16.1935 32 16C32 15.7753 31.9192 15.5572 31.7708 15.3809L31.7688 15.3789C31.6576 15.2112 25.5129 6 16 6ZM16 9C20.1237 9 23.4667 12.134 23.4667 16C23.4667 19.866 20.1237 23 16 23C11.8763 23 8.53333 19.866 8.53333 16C8.53333 12.134 11.8763 9 16 9ZM16 13C15.1513 13 14.3374 13.3161 13.7373 13.8787C13.1371 14.4413 12.8 15.2044 12.8 16C12.8 16.7956 13.1371 17.5587 13.7373 18.1213C14.3374 18.6839 15.1513 19 16 19C16.8487 19 17.6626 18.6839 18.2627 18.1213C18.8629 17.5587 19.2 16.7956 19.2 16C19.2 15.2044 18.8629 14.4413 18.2627 13.8787C17.6626 13.3161 16.8487 13 16 13Z"
            fill="#057AFF"
        />
    </svg>
);



