import React, {useState, useMemo } from 'react'

/**
 * 
 * The following hook is meant to work with simple boolean states operations.
 * 
 * Example case of use:
 * const [openSomething, setOpenSomething] = useToogle(false)
 * 
 * 
 */


export const useToggle = ( initialValue = false ) => {
    const [value, setValue] = useState( initialValue );
    
    const dummyMemo = useMemo(() => ({
                     on: () => setValue( true ),
                     off: () => setValue( false ),
                     toggle: () => setValue(( value ) => !value),
                     reset: () => setValue( initialValue ),
                    }),
        [initialValue]
    );

    return [ value, dummyMemo ];
}