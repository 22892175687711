import React, { useContext, useState, useEffect, useMemo } from 'react'
import Knock from "@knocklabs/client";
import { knockEnv } from '../../config';
import { useKnockFeed } from '@knocklabs/react-notification-feed';
const knockClient = new Knock("pk_test_zP2g4MEMRxEpfToDK05NlEbx8_AHQ9OlqwM51Pv0cD0");


const useNotificationsFeed = (knockClient, feedId, userId) => {
  return useMemo(() => {
    knockClient.authenticate(userId);
    const notificationFeed =       knockClient.feeds.initialize(feedId)
    const notificationStore =      notificationFeed.store
    const removeThisNotification = notificationFeed.markAsArchived
  
    notificationFeed.fetch()
   return [ removeThisNotification, notificationFeed, notificationStore ]
  }, [ knockClient, feedId ])
};

export const CustomKnockContext = React.createContext()

export function CustomBellProvider({ children , userId}) {
  const [ removeThisNotification, clientFeed, allKnockStore] = useNotificationsFeed(
    knockClient,
    knockEnv.feedChannelId,
    userId
  );
  const {  items, loading, metadata, pageInfo } = allKnockStore.getState();
  const _unseen_count = items.filter(n => n)
  const myCounter = 0
  const [  markAllNotificationAsSeen , setMarkAllAsSeen ] = useState(false);
 


    
  return (
    <CustomKnockContext.Provider value={{
      setMarkAllAsSeen,
      myCounter,

    }}>
      { children  }
    </CustomKnockContext.Provider>
  )
}

export const useKnockConsumer = () => useContext(CustomKnockContext);