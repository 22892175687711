import { Box } from "rebass";
import styled from "styled-components";

export const Container = styled(Box)`
    margin: 0 auto;
    margin-top: 3rem;
    max-width: 630px
`

export const WhiteButton = styled.button`
    background-color: ${({ theme }) => theme.clearGray};
    color: ${({ theme }) => theme.newBlue};
    font-size: ${({ theme }) => theme.fonts.XXS};
    border-radius: 8px;
    border: none;
    font: inherit;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    :hover {
        background-color: #ededed;
        transition: all 0.2s ease-in-out;
    }
`