import styled from "styled-components";

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const TextLabel = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
 
`;

export const InputContainerVariation = styled.div`
 width: 100%;
 margin-top: ${({triggerShowLabel})=> triggerShowLabel ? '24px' : 0};
 ${({ showLabelOnValue }) =>
        showLabelOnValue &&
        `
        input:placeholder-shown + label, textarea:placeholder-shown + label, input[aria-describedby="react-select-2-placeholder"] + label {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label,  input[aria-describedby=""] + label {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

    `}

${({ triggerShowLabel }) =>
        triggerShowLabel && triggerShowLabel === "hide"
            ? `
        label {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }`
            : `
        label {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
        
    `}
`;

