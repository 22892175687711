import styled from 'styled-components';

const Gravity = styled.div`
padding-top:3em;
padding-right:1em;
width: 100%;
position:fixed;
top: 0;
font-size: 24px;
`

export {
    Gravity
};