import React from "react";
import { TooltipContainer, StyledTooltip } from "./tooltip.styles";

const Tooltip = ({
    text,
    children,
    stylesContainer = {},
    stylesTooltip = {},
    noShow,
    textAlign,
    tooltipComponent
}) => {
    return (
        <TooltipContainer style={stylesContainer}>
            {children}
            {!noShow && (
                <StyledTooltip
                    onMouseEnter={e => e.stopPropagation()}
                    style={stylesTooltip}
                    textAlign={textAlign}
                >
                    {tooltipComponent ? tooltipComponent : text}
                </StyledTooltip>
            )}
        </TooltipContainer>
    );
};

export default Tooltip;
