import React from 'react'
import { partyConfirmationOptions } from '../../../../constants'
import { InputContainerVariation } from '../PastTimingInfo/PastTimingInfo.styles'
import { Description } from '../../../../../../atoms/shared/Text'
import { Flex } from 'rebass'
import CheckBox from '../../../../../../atoms/shared/Inputs/Checkbox'



export const PartyConfirmation = ( {  formValues, theme, onChange, errors  }) => {
    return (
        <InputContainerVariation style={{ marginTop: theme.mobileDevice ? '16px' : '24px' }}>
            <Flex  flexDirection="column" >
            <Description style={{ marginBottom: '24px' }}>
                Do you want NuOp to request confirmation from the other party to this op ?*
            </Description>
            {partyConfirmationOptions.map(({ value, label }, index) => (
                <Flex key={value} mb={index === 0 ? theme.spacing[6] : 0}>
                    <CheckBox
                        round
                        inverted
                        label={label}
                        onChange={ev =>
                            onChange({
                                target: {
                                    name: "partyConfirmation",
                                    value: value,
                                }
                            })
                        }
                        checkBoxColor={theme.blueMedium}
                        checked={value === formValues.partyConfirmation}
                    />
                </Flex>
            ))}
        </Flex>

    </InputContainerVariation>
    )
}
