import Utils from "./Utils";
import LStorage from "./LocalStorage";
const LocalStorage = new LStorage();

let googleGeocoder;
//let count = 0;

/* const initGoogle = () => {
    if (window.google) {
        googleGeocoder = new window.google.maps.Geocoder();
        clearInterval(tries);
        return;
    } else {
        if (count === 3) return clearInterval(tries);
        count++;
    }
}; */

//let tries = setInterval(initGoogle, 1000);

class GoogleApi {

    constructor(initCB) {
        this.tries = 0
        this.count = 0
        this.initGoogle(initCB)
    }

    initGoogle = (initCB) => {
        if (window.google) {
            googleGeocoder = new window.google.maps.Geocoder();
            clearInterval(this.tries);
            if(initCB) initCB()
            return;
        } else {
            if (this.count === 3) return clearInterval(this.tries);
            this.count++;
        }
    };

    geocodeAddress = address => {
        return new Promise(async (res, rej) => {
            try {
                const cachedResults = LocalStorage.getReverseGeocodeResult(
                    address
                );
                if (cachedResults) return res(cachedResults);
                else {
                    googleGeocoder.geocode(
                        { address: address },
                        (results, status) => {
                            res({ results, status });
                            LocalStorage.setGeocodeResult(address, results);
                        }
                    );

                    return;
                }
            } catch (error) {
                console.log(error);
                rej(error);
            }
        });
    };

    getStates = address => {
        return new Promise((res, rej) => {
            googleGeocoder.geocode(
                {
                    address: address
                },
                (results, status) => {
                    if (status === "OK" || status === "ZERO_RESULTS") {
                        const normalizedResults = results
                            .filter(result => Utils.getFullStateFromGMO(result))
                            .map(result => Utils.getFullStateFromGMO(result));
                        const finalResults = Utils.removeDuplicates(
                            normalizedResults
                        );

                        return res(finalResults);
                    } else {
                        return rej(status);
                    }
                }
            );
        });
    };

    normalizeAddresObjectFromGeocodeResult = (result, dataType) => {
        let data;
        if (dataType === "zipCode") {
            const zipCodeData = this.getDataFromGMO(result);
            data = {
                ...result,
                name: zipCodeData.long_name,
                key: result.place_id
            };
        } else
            data = {
                ...result,
                name: result.formatted_address,
                key: result.place_id
            };

        return data;
    };

    postalCodeExist = geocodedObject => {
        return (
            geocodedObject.address_components &&
            geocodedObject.address_components.find(component => {
                try {
                    return (
                        component.types &&
                        component.types.indexOf("postal_code") !== -1
                    );
                } catch (error) {
                    return false;
                }
            })
        );
    };

    getDataFromGMO = (
        geocodedObject,
        dataType = "administrative_area_level_1"
    ) => {
        const data = geocodedObject.address_components.find(component => {
            return component.types && component.types.indexOf(dataType) !== -1;
        });

        return data;
    };

    getZipCode = geocodedObject => {
        const data = geocodedObject.address_components.find(component => {
            return (
                component.types &&
                component.types.indexOf("administrative_area_level_1") !== -1
            );
        });

        return data;
    };
}

export default GoogleApi;
