import styled from "styled-components";
import React from "react";
import { css } from "styled-components";
import { getThemeColor } from "../../../../../../../theme";
//import PropTypes from "prop-types";
import { Image, Flex } from "rebass";
import Spinner from "../../../../../../../assets/svg/Spinner";

// padding is "8px 28px" default and if small is true, it is "4px 16px" 
const SquareButton = styled.div`
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    padding: ${(props) => props.padding ? props.padding : props.small ? "4px 16px" : "8px 28px"};
    border-radius: 3.49px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props =>
        props.bg ? props.bg : props.theme.greenMedium};
    color: ${props =>
        props.color ? props.color : 'white'};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    min-height: ${({ height, small }) => height ? height : small ? "32px" : "40px"};
    box-sizing: border-box;
    width: ${props => props.width ? props.width : 'auto'}; 
    ${props =>
        props.disabled
            ? css`
                  background-color: ${props.theme.darkGray};
                  cursor: not-allowed;
              `
            : props.bg
                ? css`
                  background-color: ${props.bg};
              `
                : css`
                  background-color: ${getThemeColor(props)};
              `
    }

    // check google prop and disable centering
    ${props =>
        props.google
            ? css`
                    background-color: ${props.theme.white};
                    border: 1px solid ${props.theme.grayMedium};
                    color: ${props.theme.grayMedium};
                    padding: 8px;
                    height: 40px;
                    min-width: 190px;
                    justify-content: flex-start;
                `
            : null
    }
`;

const Label = styled.span`
    font-size: ${({ fontSize, theme }) => theme.fonts[fontSize] || theme.fonts["XXS"]}};
    font-weight: bold;
    line-height: ${props => props.theme.lineHeights['XS']};
    text-align: center;
`;


const GoogleLabel = styled.span`
    font-size: ${({ fontSize, theme }) => theme.fonts[fontSize] || theme.fonts["XXS"]}};
    font-weight: bold;
    line-height: ${props => props.theme.lineHeights['XS']};
    text-align: center;
    font-family: Roboto;
    font-weight: 500;
`;


export default ({ callback, onClick, disabled, color, className, google, ...otherprops }) => (
    <SquareButton
        {...otherprops}
        onClick={disabled ? null : callback ? callback : onClick ? onClick : null}
        className={`${className} disable-select`}
        disabled={disabled}
        color={color}
        google={google}
    >
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
            {otherprops.icon && typeof otherprops.icon === "function" ? (
                <otherprops.icon />
            ) : null}
            {otherprops.icon && typeof otherprops.icon !== "function" ? (
                <Image src={otherprops.icon} mr={google ? "1.5rem" : 1} width={google ? 18 : null} />
            ) : null}
            {otherprops.isLoading ? (
                <Spinner color={color} width="14px" height="14px" />
            ) : null}
            {!otherprops.isLoading && !google ? <Label fontSize={otherprops.fontSize}>{otherprops.text}</Label> : null}
            {google ? <GoogleLabel>{otherprops.text}</GoogleLabel> : null}
        </Flex>
    </SquareButton>
);
