import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import TargetForm from "../../ReferralCompleteInformation/TargetToNotify/view"
import {
    InputContainer,
    TextArea,
} from "../../../../Settings/common/Components";
import { InputCounter } from "../../../../Settings/InformationAccount/styles";

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Description = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
}) => {
    const key = "description_"
    const [isCollapsed, setIsCollapsed] = useState(false)
    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Job Description
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <Flex p={`${theme.spacing[5]} 0`}>
                    <InputContainer>
                        <TextArea
                            style={{ resize: "vertical" }}
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            maxLength="2000"
                            minLength="130"
                            placeholder="Using a minimum of 30 characters, please consider providing a description that will help the person you are sending this to remember the past opportunity.*"
                            error={!!errors.description}
                        />
                        {!!values.description &&
                            !!values.description.length && (
                                <InputCounter>
                                    {values.description.length}/2000
                                </InputCounter>
                            )}
                    </InputContainer>
                </Flex>
                
            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: t("jobDescriptionInfo") }}/>
            </ReactTooltip>
        </Box>
    );
}

export default Description;
