import * as yup from "yup";



export const getFormSchema = (haveMoreThanOneCandidate = false, professionRequired) => yup.object().shape({
    // Static fields
    // profession: yup.object({
    //     value: yup.string().required(),
    //     label: yup.string().required(),
    //     global: yup.boolean().required(),
    // }).nullable(!professionRequired)
    // .when("cannotFindProfession", {
    //     is: (cannotFindProfession) => cannotFindProfession === true,
    //     then: yup.object({
    //         value: yup.string().required(),
    //         label: yup.string().required(),
    //         global: yup.boolean().required(),
    //     }).nullable()
    // }),
    // cannotFindProfession: yup.boolean(),
    // professionDescription: yup.string().nullable()
    // .when("cannotFindProfession", {
    //     is: (cannotFindProfession) => cannotFindProfession === true,
    //     then: yup.string().required()
    // }),
    address: yup.object().nullable()
    .when("locationIsRemote", {
        is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
        then: yup.object().nullable()
    })
    .when("locationIsRemote", {
        is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
        then: yup.object({
            description: yup.string().required(),
            lat: yup.number().required(),
            lng: yup.number().required(),
        }).required()
    }),
    locationIsRemote: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    description: yup.string().required().min(29),
    referralCreator:  yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    pastReferralStatus: yup.object({
        key: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    clientSource: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
   /*  currentSatus: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }), */
    lastContact: yup.date().required(),
    referralFee: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),

    // Required if profession is global.
    targetToNotify: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    // }).when("profession", {
    //     is: (profession) => profession && profession.global,
    //     then: yup.object({
    //         value: yup.string().required(),
    //         label: yup.string().required(),
    //     }).required()
    }).nullable().notRequired(),

    // Required if multiple candidates are selected for this referral.
    acceptanceCondition: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable(!haveMoreThanOneCandidate),

    // Required if profession is Real Estate Agent
    clientType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    })
    .nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if profession is Real Estate Agent
    propertyType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if profession is Real Estate Agent
    propertySubType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when("profession", {
        is: (profession) => profession && profession.value === '100',
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: yup.string()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') || (clientType && clientType.value === 'TENANT') ),
        then: yup.string().required()
    }),

    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: yup.string()
    .nullable()
    .when(["clientType"], {
        is: (clientType) => ((clientType && clientType.value === 'SELLER') || (clientType && clientType.value === 'LANDLORD') ),
        then: yup.string().required()
    }),

    minBeds: yup.number().nullable(),

    // Required if clientType is 'BUYER'
    financingInfo: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType"], {
        is: (clientType) => ((clientType && clientType.value === 'BUYER')),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType"], {
        is: (clientType, propertyType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    minSquareFeet: yup.number().nullable(),
    maxSquareFeet: yup.number(),
    maxUnits: yup.number(),

    minUnits: yup.object()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    pets: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL') ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => (
            ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'LAND')) ||
            ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'LAND')) ||
            ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
        ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),

    units: yup.object()
    .nullable()
    .when(["clientType", "propertyType", "propertySubType"], {
        is: (clientType, propertyType, propertySubType) => (
            (clientType && clientType.value === 'SELLER') &&
            (propertyType && propertyType.value === 'RESIDENTIAL') &&
            (propertySubType && propertySubType.value === 'MULTI_FAMILY')
        ),
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required()
    }),


    /*
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'APARTMENT')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'CONDO')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
    */


    realEstateFee: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "REAL_ESTATE",
        then: yup.number().min(0).max(100).required()
    }),

    comments: yup.string().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "OTHER",
        then: yup.string().required()
    }).when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.string()
    }).when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.string()
    }),

    flatFeeAmount: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.number().min(0).max(10000).required()
    }),

    paymentDue: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    grossRevenueFee: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.number().min(0).max(10000).required()
    }),

    feeDuration: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    feeFrequency: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    feeCommencement: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

   /*  agreement: yup.object()
    .nullable()
    .when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value !== "NO_FEE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }), */

    visibility: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),

    email: yup.string().nullable(),

    firstName: yup.string().nullable(),

    lastName: yup.string().nullable(),

    phone: yup.string().nullable(),

    clientAddres: yup.string().nullable(),

    minPrice: yup.number(),

    maxBeds: yup.number(),

    minAcres: yup.number(),

    maxAcres: yup.number(),

    acres: yup.number(),

    beds: yup.number(),

    baths: yup.number(),

    squareFeet: yup.number(),

    retailSpace: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable(),

    clientFirstName: yup.string()
        .when(["agreement", "clientInformation"], {
            is: (agreement, clientInformation) => (agreement && agreement.label === 'Yes') && !clientInformation,
            then: yup.string().required(),
        }),

    clientLastName: yup.string()
        .when(["agreement", "clientInformation"], {
            is: (agreement, clientInformation) => (agreement && agreement.label === 'Yes') && !clientInformation,
            then: yup.string().required(),
        }),

    clientEmail: yup.string()
        .when(["agreement", "clientMobileNumber", "clientInformation"], {
            is: (agreement, clientMobileNumber, clientInformation) =>
                ((agreement && agreement.label === 'Yes') && !clientMobileNumber && !clientInformation),
            then: yup.string().required(),
        }),

    clientMobileNumber: yup.string()
        .when(["agreement", "clientEmail", "clientInformation"], {
            is: (agreement, clientEmail, clientInformation) => (agreement && agreement.label === 'Yes') && !clientEmail && !clientInformation,
            then: yup.string().required(),
        }),

    clientStreetAddress: yup.string(),

    clientCity: yup.string(),
    searchUserMode: yup.boolean(),

    clientState: yup.string(),

    clientZipCode: yup.string(),

    clientInformation: yup.object().nullable(true)
        .when(["agreement", "clientEmail", "clientMobileNumber", "clientFirstName", "clientLastName"], {
            is: (agreement, clientEmail, clientMobileNumber, clientFirstName, clientLastName) =>
                (agreement && agreement.label === 'Yes') && !clientEmail && !clientMobileNumber && !clientFirstName && !clientLastName,
            then: yup.object(),
        }),
},[
    ['clientFirstName', 'clientInformation'],
    ['clientLastName', 'clientInformation'],
    ['clientEmail', 'clientMobileNumber'],
    ['clientEmail', 'clientInformation'],
    ['clientMobileNumber', 'clientInformation'],
]);



