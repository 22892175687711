import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import { selectStyle } from "../../../../components";
import Select from "react-select";
import {
    InputContainer,
    InputLabel,
    Input,
    TextArea
} from "../../../../../Settings/common/Components";
import { InputCounter } from "../../../../../Settings/InformationAccount/styles";


const GrossRevenue = props => {

    const [frequencyOptions, setFrequencyOptions] = useState([]);
    const {
        theme,
        formValues,
        onChange,
        feeFrequencyOptions,
        feeDurationOptions,
        feeCommencementOptions,
        feeDurationKeys,
        feeFrequencyKeys,
        errors,
    } = props;

    useEffect(() => {
        let filteredFrequencyOptions = feeFrequencyOptions ? [...feeFrequencyOptions] : []

        if(formValues && formValues.feeDuration === (feeDurationKeys && feeDurationKeys.ONE_TRANSACTION)){
            filteredFrequencyOptions = feeFrequencyOptions ? feeFrequencyOptions.filter(op => op.key === feeFrequencyKeys.ONE_TIME) : []
            onChange({ value: feeFrequencyKeys && feeFrequencyKeys.ONE_TIME, name: "feeFrequency"})
        } else if(formValues && formValues.feeDuration === (feeDurationKeys && feeDurationKeys.DAYS_30)){
            filteredFrequencyOptions = feeFrequencyOptions ? feeFrequencyOptions.filter(op => op.key === feeFrequencyKeys.MONTHLY) : []
            onChange({ value: feeFrequencyKeys && feeFrequencyKeys.MONTHLY, name: "feeFrequency"})
        } else if(formValues && formValues.feeDuration === (feeDurationKeys && feeDurationKeys.MONTH_6)){
            filteredFrequencyOptions = feeFrequencyOptions ? feeFrequencyOptions.filter(op => [feeFrequencyKeys.MONTHLY, feeFrequencyKeys.ONE_TIME].includes(op.key)) : []
        }

        setFrequencyOptions(filteredFrequencyOptions)
    }, [formValues.feeDuration, feeFrequencyOptions])


    return (
        <>
            <Flex mt={theme.spacing[3]}>
                <InputContainer
                    style={{ maxWidth: theme.mobileDevice ? "100%" : "calc(50% - 1em)" }}
                    showLabelOnValue
                >
                    <Input
                        type="number"
                        name="grossRevenueFee"
                        placeholder={"Enter %*"}
                        required
                        value={formValues.grossRevenueFee}
                        onChange={onChange}
                        error={!!errors.grossRevenueFee}
                    />
                    <InputLabel>Enter %*</InputLabel>
                </InputContainer>
            </Flex>
            <Flex mt={theme.spacing[3]}  flexDirection={['column', 'column', 'row']}>
                <InputContainer
                    style={{ maxWidth: theme.mobileDevice ? "100%" : "calc(50% - 1em)" }}
                    triggerShowLabel={formValues.feeDuration && formValues.feeDuration.value ? "show" : "hide"}
                    showLabelOnValue
                >
                    <Select
                        isClearable
                        options={feeDurationOptions}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "feeDuration",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="feeDuration"
                        placeholder="Duration for which your fee applies*"
                        value={formValues.feeDuration}
                        error={!!errors.feeDuration}
                    />
                    <InputLabel>Duration*</InputLabel>
                </InputContainer>
                <InputContainer
                    style={{ maxWidth: theme.mobileDevice ? "100%" : "calc(50% - 1em)" }}
                    triggerShowLabel={formValues.feeFrequency && formValues.feeFrequency.value ? "show" : "hide"}
                    showLabelOnValue
                >
                    <Select
                        isClearable
                        options={frequencyOptions}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "feeFrequency",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="feeFrequency"
                        placeholder="Please choose how often you expect to be paid*"
                        error={!!errors.feeFrequency}
                        value={formValues.feeFrequency}
                    />
                    <InputLabel>Payment Frequency*</InputLabel>
                </InputContainer>
            </Flex>
            <Flex mt={theme.spacing[3]} >
                <InputContainer
                style={{ maxWidth: theme.mobileDevice ? "100%" : "calc(50% - 1em)" }}
                    triggerShowLabel={
                        formValues.feeCommencement && formValues.feeCommencement.value ? "show" : "hide"
                    }
                    showLabelOnValue
                >
                    <Select
                        isClearable
                        options={feeCommencementOptions}
                        onChange={option =>
                            onChange({
                                target: {
                                    name: "feeCommencement",
                                    value: option,
                                }
                            })
                        }
                        styles={selectStyle}
                        name="feeCommencement"
                        placeholder="Please choose when payment(s) must commence*"
                        error={!!errors.feeCommencement}
                        value={formValues.feeCommencement}
                    />
                    <InputLabel>Commencement*</InputLabel>
                </InputContainer>
            </Flex>
            <Flex mt={theme.spacing[3]}>
                <InputContainer showLabelOnValue    style={{ maxWidth: theme.mobileDevice ? "100%" : "calc(50% - 1em)" }}>
                    {!!formValues.comments && !!formValues.comments.length && (
                        <InputCounter>
                            {formValues.comments.length}/2000
                        </InputCounter>
                    )}
                    <TextArea
                        style={{ resize: "vertical" }}
                        type="text"
                        name="comments"
                        value={formValues.comments}
                        onChange={onChange}
                        maxLength="2000"
                        minLength="60"
                        placeholder="Comments"
                    />
                    <InputLabel>Comments</InputLabel>
                </InputContainer>
            </Flex>
        </>
    );
}

export default GrossRevenue;
