import styled from 'styled-components';
import { NavbarText } from '../topNavbar-styles'

export const CardContainer = styled.div`
    z-index: 999999;
    position: absolute;
    min-width: 165px;
    top: 2.2rem;
    right: -15px;
    outline: none;
`

export const Poligon = styled.div`
    z-index: 99999;
    position: absolute;
    width: 24px;
    height: 27px;
    top: -13.5px;
    right: 47px;
    background-color: white;
    transform: rotate(45deg);
`
export const DropdownText = styled(NavbarText)`
    margin-bottom: 0;
    margin-left: .5rem;
    color: ${({ selected, disabled, theme }) => disabled ? theme.darkGray : selected ? theme.newBlue : theme.coolBlack};
`