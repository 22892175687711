import * as yup from 'yup'

// CLIENT TYPE
export const ClientTypeDefaultValue = null;

export const ClientTypeFormSchema = yup.object({
    value: yup.string().required(),
    label: yup.string().required(),
}).required();

export const ClientTypeFieldSelector = 'clientType';

// PROPERTY TYPE
export const PropertyTypeFieldDefaultValue = null;

export const PropertyTypeFieldFormSchema = yup.object({
    value: yup.string().required(),
    label: yup.string().required(),
}).required();

export const PropertyTypeFieldSelector = 'propertyType';

// PROPERTY SUB-TYPE
export const PropertySubTypeFieldDefaultValue = null;

export const PropertySubTypeFieldFormSchema = yup.object({
    value: yup.string().required(),
    label: yup.string().required(),
}).required();

export const PropertySubTypeFieldSelector = 'propertySubType';

// MAX PRICE
export const MaxPriceFieldDefaultValue = null;

export const MaxPriceFieldFormSchema = yup.number().min(0).integer().required();

export const MaxPriceFieldSelector = 'maxPrice';