import React from "react";
import { Flex } from "rebass";
import {
    InputContainer,
    Input,
    InputLabel
} from "../../../../../../Settings/common/Components";
import { Text } from "../../../../../../../atoms/shared/Text";

const RealStateComission = ({
        theme,
        formValues,
        onChange,
        errors,
    }) => (
        <>
            <Flex mb={theme.spacing[4]}>
                <InputContainer
                    style={{ maxWidth: "calc(50% - 1em)" }}
                    showLabelOnValue
                >
                    <Input
                        type="number"
                        name="realEstateFee"
                        placeholder={"Enter %*"}
                        required
                        value={formValues.realEstateFee}
                        onChange={onChange}
                        error={!!errors.realEstateFee}
                    />
                    <InputLabel>Enter %*</InputLabel>
                </InputContainer>
            </Flex>
            <Flex>
                <Text color={theme.taupeGray} fontSize="XXS">
                    This option should only be used for a referral exchanged
                    between Real Estate Professionals. Please input your
                    required fee as a % of the Gross Commission Income.
                </Text>
            </Flex>
        </>
    );


export default RealStateComission;