import {
    FETCH_MY_CONNECTIONS_FAIL,
    FETCH_MY_CONNECTIONS_SUCCESS,
    CONNECTIONS_IS_LOADING,
    MY_CONNECTIONS_UPDATE_FILTERS,
    MY_CONNECTIONS_RESET_FILTERS,
    CONTACTS_IS_LOADING,
    UPDATE_CONTACT,
    CREATE_CONTACT,
    MY_CONTACTS_RESET_FILTERS,
    MY_CONTACTS_UPDATE_FILTERS,
    FETCH_MY_CONTACTS_SUCCESS,
    FETCH_MY_CONTACTS_FAIL,
    SHOW_CONTACT_FORM,
    SHOW_CONTACT_PROFILE,
    HIDE_CONTACT_FORM,
    HIDE_CONTACT_PROFILE,
    SHOW_DELETE_CONTACT_MODAL,
    HIDE_DELETE_CONTACT_MODAL,
    DELETE_CONTACT_FAIL,
    DELETE_CONTACT_SUCCESS,
    SHOW_CONTACT_NOTE_EDITOR,
    HIDE_CONTACT_NOTE_EDITOR,
    SHOW_CONTACT_FILTERS,
    HIDE_CONTACT_FILTERS,
    SHOW_CONNECTIONS_FILTERS,
    HIDE_CONNECTIONS_FILTERS,
    SHOW_CONTACT_INVITE_MODAL,
    HIDE_CONTACT_INVITE_MODAL,
    UPDATE_CONTACT_SUCCESS,
    CREATE_CONTACT_SUCCESS,
    HIDE_CONTACT_CREATED_MODAL,
    FETCH_CONTACT_DETAILS_SUCCESS,
    SHOW_CONTACT_CREATED_MODAL,
    CREATE_CONTACT_FAIL,
    HIDE_CREATE_CONTACT_ERROR,
    HIDE_MESSAGE_POPUP,
    CONTACT_SEND_CONNECTION_SUCCESS,
    CONTACT_ALREADY_REGISTERED,
    SHOW_MESSAGE_POPUP,
    IMPORT_CONTACTS_SUCCESS,
    SHOW_IMPORT_CONTACTS_SUCCESS,
    HIDE_IMPORT_CONTACTS_SUCCESS,
    ADD_ACTIVITY_FEED_NOTE,
    UPDATE_ACTIVITY_FEED_NOTE,
    SHOW_REINVITE_MODAL,
    HIDE_REINVITE_MODAL,
    IMPORT_CONTACT_FROM_OTHER_USER_LOADING,
    IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS,
    IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
    HIDE_IMPORT_FROM_OTHER_USER_SUCCESS
} from "../../../../actions/constants";

const initialContactData = {
    // "work_info": {
    //     "located_at": {},
    //     "company": "",
    //     "office_phone": "",
    //     "professions": [],
    //     "fax": "",
    //     "website": ""
    // },
    // "additional_info": {
    //     "birthday": "", //"2019-08-24"
    //     "phone_home": "",
    // },
    // "social_networks": {
    //     "instagram": "",
    //     "twitter": "",
    //     "facebook": "",
    //     "linkedin": "",
    //     "youtube": ""
    // },
}

const initialState = (initS = {}) => {
    return {
        contactsIsLoading: true,
        connectionsIsLoading: true,
        isFetchingContacts: true,
        isFetchingConnections: true,
        importContactsIsLoading: false,
        connections: {
            items: []
        },
        contacts: [],
        importedContacts: [],
        connectionFilters: {},
        contactFilters: {},
        contactsCount: 0,
        contactData: initialContactData,
        contactFormEnabled: false,
        messagePopupEnabled: false,
        messagePopupDescription: "",
        connectionsFiltersEnabled: false,
        contactProfileEnabled: false,
        contactProfileLoading: false,
        deleteContactModalEnabled: false,
        noteEditorEnabled: false,
        inviteContactModalEnabled: false,
        contactCreatedModalEnabled: false,
        contactAlreadyRegisteredModalEnabled: false,
        importSuccessModalEnabled: false,
        reinviteModalEnabled: false,
        importContactsSummary: {
            success: 0,
            error: 0
        },
        note: undefined,
        createContactError: {
            open: false,
            message: ""
        },
        fetchMyContactsPayload: {
            from: 0,
            size: 10
        },
        importContactFromOtherUser: {
            isLoading: false,
            error: "",
            userAlreadyHasContact: false,
            contact: null,
            showSuccessfullyImportedModal: false
        },
        ...initS
    };
}

function myNetworkReducer(state = initialState(), action) {
    switch (action.type) {
        case CONTACT_SEND_CONNECTION_SUCCESS: {
            return {
                ...state,
                contactsIsLoading: false,
                isFetchingContacts: false
                // contacts: state.contacts.map(contact => {
                //     if (contact.uid === action.payload.uid) {
                //         return {
                //             ...contact,
                //             status: "Connected"
                //         };
                //     }
                //     return contact;
                // })
            };
        }

        case CONTACT_ALREADY_REGISTERED: {
            return {
                ...state,
                contactAlreadyRegisteredModalEnabled: true,
                contactsIsLoading: false,
                isFetchingContacts: false
            };
        }

        case FETCH_CONTACT_DETAILS_SUCCESS: {
            const { contact } = action.payload;
            return {
                ...state,
                contactProfileLoading: false,
                // update contact data with contact details. for fields that are not present in contact details, use the initial value
                contactData: {
                    ...initialContactData,
                    ...contact
                }
            };
        }

        case SHOW_CONTACT_INVITE_MODAL: {
            return {
                ...state,
                inviteContactModalEnabled: true,
                contactData: action.payload.contact,
                contactCreatedModalEnabled: false
            };
        }

        case HIDE_CONTACT_INVITE_MODAL: {
            return {
                ...state,
                inviteContactModalEnabled: false,
                contactData: initialContactData,
                contactsIsLoading: false
            };
        }

        case CONTACTS_IS_LOADING: {
            const _state = {
                ...state
            };
            _state.contactsIsLoading = action.payload.contactsIsLoading;
            _state.isFetchingContacts = action.payload.isFetchingContacts;
            _state.importContactsIsLoading =
                action.payload.importContactsIsLoading;
            return _state;
        }

        case FETCH_MY_CONTACTS_SUCCESS: {
            return {
                ...state,
                contacts: action.payload.contacts,
                contactsCount: action.payload.count,
                fetchMyContactsPayload: action.payload.fetchMyContactsPayload,
                contactsIsLoading: false,
                isFetchingContacts: false,
                contactFormEnabled: false
            };
        }

        case CREATE_CONTACT_SUCCESS: {
            const contacts = [action.payload.contact, ...state.contacts];

            return {
                ...state,
                contacts,
                contactFormEnabled: false
            };
        }

        case ADD_ACTIVITY_FEED_NOTE: {
            const _state = {
                ...state
            };
            const { addedNote } = action.payload;

            _state.contactData.activity_feed.push(addedNote);

            return _state;
        }

        case UPDATE_ACTIVITY_FEED_NOTE: {
            const _state = {
                ...state
            };
            const { updatedNote } = action.payload;

            _state.contactData.activity_feed = _state.contactData.activity_feed.map(
                note => {
                    if (note.activity_id === updatedNote.activity_id) {
                        return updatedNote;
                    }
                    return note;
                }
            );

            return _state;
        }

        case HIDE_CONTACT_CREATED_MODAL: {
            return {
                ...state,
                contactCreatedModalEnabled: false,
                contactData: initialContactData
            };
        }

        case SHOW_CONTACT_CREATED_MODAL: {
            return {
                ...state,
                contactCreatedModalEnabled: true,
                contactData: action.payload.contact
            };
        }

        case UPDATE_CONTACT_SUCCESS: {
            const contacts = state.contacts.map(contact =>
                contact.contact_id === action.payload.contact.contact_id
                    ? { ...contact, ...action.payload.contact }
                    : contact
            );

            return {
                ...state,
                contacts,
                contactData: action.payload.contact,
                contactFormEnabled: false,
                noteEditorEnabled: false,
                contactsIsLoading: false
            };
        }

        case FETCH_MY_CONTACTS_FAIL: {
            return {
                ...state,
                contactsIsLoading: false,
                contacts: []
            };
        }

        case CREATE_CONTACT: {
            return {
                ...state,
                contacts: [...state.contacts, action.payload.contact]
            };
        }

        case DELETE_CONTACT_FAIL: {
            return {
                ...state,
                contactsIsLoading: false
            };
        }

        case DELETE_CONTACT_SUCCESS: {
            let contacts = state.contacts.filter(
                contact => contact.contact_id !== state.contactData.contact_id
            );
            if (action.payload && action.payload.contacts) {
                contacts = [...contacts, ...action.payload.contacts];
            }
            return {
                ...state,
                contactsIsLoading: false,
                deleteContactModalEnabled: false,
                contactProfileEnabled: false,
                contacts,
                contactsCount: action.payload.count,
                contactData: initialContactData
            };
        }

        case SHOW_DELETE_CONTACT_MODAL: {
            return {
                ...state,
                deleteContactModalEnabled: true
            };
        }

        case HIDE_DELETE_CONTACT_MODAL: {
            return {
                ...state,
                deleteContactModalEnabled: false
            };
        }

        case SHOW_CONTACT_FORM: {
            return {
                ...state,
                contactFormEnabled: true,
                contactData: action.payload.contactData || initialContactData
            };
        }

        case HIDE_CONTACT_FORM: {
            const { fromProfile } = action.payload;
            return {
                ...state,
                contactFormEnabled: false,
                contactData: fromProfile
                    ? state.contactData
                    : initialContactData
            };
        }

        case SHOW_CONTACT_PROFILE: {
            return {
                ...state,
                contactProfileEnabled: true,
                contactProfileLoading: true
            };
        }

        case HIDE_CONTACT_PROFILE: {
            return {
                ...state,
                contactProfileEnabled: false,
                contactData: initialContactData
            };
        }

        case CONNECTIONS_IS_LOADING: {
            return {
                ...state,
                connectionsIsLoading: action.payload.connectionsIsLoading
            };
        }

        case FETCH_MY_CONNECTIONS_SUCCESS: {
            return {
                ...state,
                connectionsIsLoading: false,
                connections: action.payload.connections
            };
        }

        case FETCH_MY_CONNECTIONS_FAIL: {
            return {
                ...state,
                connectionsIsLoading: false,
                connections: {
                    items: [...state.connections.items]
                }
            };
        }

        case MY_CONNECTIONS_UPDATE_FILTERS: {
            const connectionFilters = {
                ...state.connectionFilters,
                ...action.payload.filters
                //[action.payload.key]: action.payload.value
            };

            return {
                ...state,
                connectionFilters
            };
        }

        case MY_CONNECTIONS_RESET_FILTERS: {
            return {
                ...state,
                connectionFilters: {}
            };
        }

        case MY_CONTACTS_UPDATE_FILTERS: {
            const contactFilters = {
                ...state.contactFilters,
                ...action.payload.filters
            };

            return {
                ...state,
                contactFilters
            };
        }

        case MY_CONTACTS_RESET_FILTERS: {
            return {
                ...state,
                contactFilters: {}
            };
        }

        case SHOW_CONTACT_NOTE_EDITOR: {
            return {
                ...state,
                noteEditorEnabled: true,
                note: action.payload.note
            };
        }

        case HIDE_CONTACT_NOTE_EDITOR: {
            return {
                ...state,
                noteEditorEnabled: false
            };
        }

        case SHOW_CONTACT_FILTERS: {
            return {
                ...state,
                contactFiltersEnabled: true
            };
        }

        case HIDE_CONTACT_FILTERS: {
            return {
                ...state,
                contactFiltersEnabled: false
            };
        }

        case SHOW_CONNECTIONS_FILTERS: {
            return {
                ...state,
                connectionsFiltersEnabled: true
            };
        }

        case HIDE_CONNECTIONS_FILTERS: {
            return {
                ...state,
                connectionsFiltersEnabled: false
            };
        }

        case CREATE_CONTACT_FAIL: {
            return {
                ...state,
                contactsIsLoading: false,
                isFetchingContacts: false,
                createContactError: {
                    open: true,
                    message: action.payload.error || "Error"
                }
            };
        }

        case HIDE_CREATE_CONTACT_ERROR: {
            return {
                ...state,
                createContactError: {
                    open: false,
                    message: ""
                }
            };
        }

        case HIDE_MESSAGE_POPUP: {
            return {
                ...state,
                messagePopupEnabled: false,
                messagePopupDescription: "",
                contactsIsLoading: false,
                connectionsIsLoading: false
            };
        }

        case SHOW_MESSAGE_POPUP: {
            return {
                ...state,
                messagePopupEnabled: true,
                messagePopupDescription: action.payload.error
            };
        }

        case IMPORT_CONTACTS_SUCCESS: {
            const _state = {
                ...state
            };

            const importedContacts = action.payload.importedContacts;

            _state.contacts = [...importedContacts, ..._state.contacts];
            _state.importedContacts = importedContacts;
            _state.contactsCount =
                _state.contactsCount + importedContacts.length;

            return _state;
        }
        case SHOW_IMPORT_CONTACTS_SUCCESS: {
            const {
                importContactsSucessCount,
                importContactsErrorCount
            } = action.payload;
            return {
                ...state,
                importSuccessModalEnabled: true,
                importContactsSummary: {
                    success: importContactsSucessCount,
                    error: importContactsErrorCount
                }
            };
        }

        case HIDE_IMPORT_CONTACTS_SUCCESS: {
            return {
                ...state,
                importSuccessModalEnabled: false,
                importContactsSummary: {
                    success: 0,
                    error: 0
                }
            };
        }

        case SHOW_REINVITE_MODAL: {
            return {
                ...state,
                reinviteModalEnabled: true,
                contactData: action.payload.contact
            };
        }

        case HIDE_REINVITE_MODAL: {
            return {
                ...state,
                reinviteModalEnabled: false,
                contactData: initialContactData
            };
        }
        case IMPORT_CONTACT_FROM_OTHER_USER_LOADING: {
            return {
                ...state,
                importContactFromOtherUser: {
                    ...state.importContactFromOtherUser,
                    isLoading: true
                }
            };
        }
        case IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS: {
            return {
                ...state,
                importContactFromOtherUser: {
                    ...state.importContactFromOtherUser,
                    isLoading: false,
                    contact: action.payload.contact,
                    showSuccessfullyImportedModal: true
                }
            };
        }
        case IMPORT_CONTACT_FROM_OTHER_USER_FAIL: {
            return {
                ...state,
                importContactFromOtherUser: {
                    ...state.importContactFromOtherUser,
                    isLoading: false,
                    error: action.payload.error,
                    userAlreadyHasContact: action.payload.userAlreadyHasContact,
                    contact: action.payload.contact || null
                }
            };
        }
        case HIDE_IMPORT_FROM_OTHER_USER_SUCCESS: {
            return {
                ...state,
                importContactFromOtherUser: {
                    ...state.importContactFromOtherUser,
                    showSuccessfullyImportedModal: false
                }
            };
        }
        default:
            return state;
    }
}

export default myNetworkReducer;
