import styled from "styled-components";
//import { getThemeColor, getThemeOnColor } from '../../../../../../theme';

export const Modal = styled.div`
    display: flex; /* Hidden by default */
    visibility: ${props =>
        props.open ? "auto" : "hidden"}; /* Hidden by default */
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background-color: rgb(0,0,0);  */
    // Black background with opacity
    background-color:rgba(0,0,0,0.2);
    // Blurred Background
    // backdrop-filter: blur(4px);
`;

export const ModalContent = styled.div`
    background-color: #fefefe;
    /* border: ${props => `1px solid ${props.theme.greenMedium}`}; */
    -webkit-border-radius: 16px;
    -moz-border-radius: 3px;
    /* border-radius: 3px; */
    box-shadow: 0 9px 17px 0 rgba(0,0,0,0.1);
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    font-size: 1.5em;
    color: ${props => props.theme.coolBlack};
    padding-top: 10px;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
`;

export const Button = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 50%;
    height: 50px;
    border: 0px transparent rgb(211, 211, 211, 0.5);
    -webkit-border-radius: 0px 0px 0px 10px;
    -moz-border-radius: 0px 0px 0px 10px;
    border-radius: 0px 0px 0px 10px;
    font-weight: 500;
    cursor: pointer;
`;

export const LeftButton = styled(Button)`
    -webkit-border-radius: 0px 0px 0px 10px;
    -moz-border-radius: 0px 0px 0px 10px;
    border-radius: 0px 0px 0px 10px;
    color: ${props => props.theme.secondary};
    cursor: pointer;
`;
export const RightButton = styled(Button)`
    -webkit-border-radius: 0px 0px 10px 0px;
    -moz-border-radius: 0px 0px 10px 0px;
    border-radius: 0px 0px 10px 0px;
    cursor: pointer;
`;

export const TopModal = styled.div`
 height: 20px;
 z-index: 5;
 display: flex;
 justify-content: flex-end;
 position: relative;
 width: 100%;
 & div {
  width: 15px;
  background-color: transparent;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  & button {
//    background-image: url('svg/closeModal.svg');
   background-color: transparent;
   width: 15px;
   height: 15px;
   background-repeat: no-repeat;
   background-size: auto;
   outline: none;
   border: none;
   background-size: cover;
   
 }:hover {
   background-color: #f2f2f2;
 }

 }
`;


export const CloseButton = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  height: 20px;
  justify-content: center;
  line-height: 20px;
  width: 20px;
  cursor: pointer;
  
  & button {
   background-color: transparent;
//    background-image: url('../svg/closeModal.svg ');
   background-repeat: no-repeat;
   background-size: auto;
   background-size: cover;
   border: none;
   height: 10px;
   outline: none;
   width: 10px;
  cursor: pointer;
   
 } :hover {
   background-color: #f2f2f2;
 }  

`;