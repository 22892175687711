import {
    FETCH_BIGBOARD_ISLOADING,
    FETCH_BIGBOARD_SUCCESS,
    FETCH_BIGBOARD_FAIL,
    BIGBOARD_UPDATE_FILTERS,
    BIGBOARD_RESET_FILTERS,
    BIGBOARD_SET_REFERRAL_POPUP,
    BIGBOARD_REMOVE_REFERRAL,
    BIGBOARD_SET_PAGE
} from "../../../../actions/constants";

const initialState = {
    errors: [],
    isLoading: false,
    data: [],
    page: 0,
    filters: {
        from: 0,
        size: 20,
        profession: [],
        location: false,
        date: false,
        // Other Filters
        // profession: ["100", "101"],
        // location: {
        //     lat: -41.138158,
        //     lon: -71.304211
        // },
        // date: {
        //     from: 1627842666000
        // },
        // uid: "ykrw8Cp7vscEtDdo5OmTWumxUin2",
        // status: ["ASSIGNED"],
        // timing: "TWO_SIX",
        // member_name: "mauro",
        // my_ops: false,
        // open_to_me: true
    },
    datesOptions: [
        { id: 0, label: "24 Hours" },
        { id: 1, label: "This Week" },
        { id: 2, label: "This Month" },
        { id: 3, label: "Last Month" }
    ],
    statusOptions: [
        {  value: 'SUBMITTED', label: 'Available' },
        {  value: 'ASSIGNED', label: 'Awarded'  },
        {  value: 'CLAIMED', label:  'Claimed' },
        {  value: 'CANCELED', label: 'Cancelled'  },
        {  value: 'CLOSED',  label: 'Closed'     },
        {  value: 'EXPIRED', label: 'Expired'   },
        {  value: 'IN_PROGRESS', label: 'In Progress'   },
    ],
    timingOptions: [
        {
            value: "NOW",
            label: "Ready NOW!"
        },

        {
            value: "SIX_TWELVE",
            label: "Ready in 6-12 Months"
        },

        {
            value: "TWELVE_AND_MORE",
            label: "Ready in 12+ Months"
        },

        {
            value: "TWO_SIX",
            label: "Ready in 2-6 Months"
        }
    ],
    hasMore: true,
    openReferralPopup: false
};

function bigBoardReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BIGBOARD_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case FETCH_BIGBOARD_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case FETCH_BIGBOARD_SUCCESS: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            if (_state.filters.from === 0) {
                // If it is the first query
                _state.data = action.payload.data;
            } else {
                // If it is a pagination query
                _state.data = _state.data.concat(action.payload.data);
            }
            _state.hasMore = action.payload.hasMore;
            return _state;
        }
        case BIGBOARD_UPDATE_FILTERS: {
            const filters = {
                ...state.filters,
                ...action.payload.filters
                //[action.payload.key]: action.payload.value
            };
            const _state = {
                ...state,
                filters
            };

            return _state;
        }
        case BIGBOARD_RESET_FILTERS: {
            const { filters } = initialState;
            const _state = {
                ...state,
                filters
            };

            return _state;
        }
        case BIGBOARD_SET_REFERRAL_POPUP: {
            const _state = {
                ...state
            };
            _state.openReferralPopup = action.payload.openReferralPopup;

            return _state;
        }
        case BIGBOARD_REMOVE_REFERRAL: {
            const _state = {
                ...state
            };
            _state.data = _state.data.filter((referral) => referral.code !== action.payload.referralCode)

            return _state;
        }
        case BIGBOARD_SET_PAGE: {
            const _state = {
                ...state
            };
            _state.page = action.payload.page;

            return _state;
        }

        default:
            return state;
    }
}

export default bigBoardReducer;
