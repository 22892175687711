import axios from 'axios';
import {
    TOGGLE_SEARCHBAR,
    SEARCHBAR_LOADING,
    SEARCHBAR_SUCCESS,
    SEARCHBAR_FAIL,
    SEARCHBAR_CLEAR,
    SEARCHBAR_VALUE,
    SEARCHBAR_BLUR_VALUE,
    SEARCHBAR_LOCATION_LOADING,
    SEARCHBAR_LOCATION_SUCCESS,
    SEARCHBAR_LOCATION_FAIL,
    SEARCHBAR_SET_LOCATION_VALUE,
    SEARCHBAR_SET_LOCATIONORPROFILE,
    SEARCHBAR_FILTER_OPEN,
    SEARCHBAR_SHOW_SUGGESTIONS,
    SEARCHBAR_SET_FILTER,
    SEARCHBAR_CLEAR_RESULTS,
    MEGASEARCH_SUCCESS,
    MEGASEARCH_FAIL,
    MEGASEARCH_RESET_FILTERS,
    MEGASEARCH_UPDATE_FILTERS,
    PEOPLE_SEARCHBAR_LOADING,
    PEOPLE_SEARCHBAR_SUCCESS,
    PEOPLE_SEARCHBAR_FAIL,
    PEOPLE_SEARCHBAR_CLEAR,
} from "../../constants";
import { handleError } from "../modal";
import Utils, { mapContactToUserInfo } from '../../../../libs/Utils';
import SearchBussiness from '../../../../business/search';
import { getQueryDateObject } from '../shared';
import Fuse from 'fuse.js';


function toggleSearchBar(isOpen) {
    return {
        type: TOGGLE_SEARCHBAR,
        payload: {
            isOpen: isOpen
        }
    };
}

function searchSetLocationValue(location) {
    return {
        type: SEARCHBAR_SET_LOCATION_VALUE,
        payload: {
            location
        }
    };
}

function clearSearch() {
    return {
        type: SEARCHBAR_CLEAR,
    };
}

function searchBarLoading(isLoading) {
    return {
        type: SEARCHBAR_LOADING,
        payload: {
            loading: isLoading
        }
    };
}

function searchBarShowSuggestions(show) {
    return {
        type: SEARCHBAR_SHOW_SUGGESTIONS,
        payload: {
            showSuggestions: show
        }
    };
}

function searchBarSuccess(payload) {
    return {
        type: SEARCHBAR_SUCCESS,
        payload
    };
}

function searchBarFail(payload) {
    return {
        type: SEARCHBAR_FAIL,
        payload
    };
}
function searchBarLocationLoading(isLoading) {
    return {
        type: SEARCHBAR_LOCATION_LOADING,
        payload: {
            isLoading
        }
    };
}

function searchBarLocationSuccess(payload) {
    return {
        type: SEARCHBAR_LOCATION_SUCCESS,
        payload: {
            locations: payload
        }
    };
}

function searchBarLocationFail(payload) {
    return {
        type: SEARCHBAR_LOCATION_FAIL,
        payload
    };
}

function setLocationOrProfile(locationOrUser) {
    return {
        type: SEARCHBAR_SET_LOCATIONORPROFILE,
        payload: {
            locationOrUser: locationOrUser
        }
    };
}

function searchValue(value) {
    return {
        type: SEARCHBAR_VALUE,
        payload: {
            value: value
        }
    };
}

function searchClearResults() {
    return {
        type: SEARCHBAR_CLEAR_RESULTS,
        payload: {

        }
    };
}



function searchBlurValue(value) {
    return {
        type: SEARCHBAR_BLUR_VALUE,
        payload: {
            value: value
        }
    };
}

function searchFilterOpen(filterName) {
    return {
        type: SEARCHBAR_FILTER_OPEN,
        payload: {
            openFilter: filterName
        }
    };
}

function searchFilterSet(filterName, filters) {
    return {
        type: SEARCHBAR_SET_FILTER,
        payload: {
            filterName: filterName,
            selectedFilters: filters
        }
    };
}

function megaSearchSuccess(payload) {
    return {
        type: MEGASEARCH_SUCCESS,
        payload
    };
}

function megaSearchFail(payload) {
    return {
        type: MEGASEARCH_FAIL,
        payload
    };
}

const megaSearchResetFilters = () => ({
    type: MEGASEARCH_RESET_FILTERS
});
function peopleSearchBarLoading(isLoading) {
    return {
        type: PEOPLE_SEARCHBAR_LOADING,
        payload: {
            loading: isLoading
        }
    };
}

function peopleSearchBarSuccess(payload) {
    return {
        type: PEOPLE_SEARCHBAR_SUCCESS,
        payload
    };
}

function peopleSearchBarFail(payload) {
    return {
        type: PEOPLE_SEARCHBAR_FAIL,
        payload
    };
}

function clearPeopleSearchBar() {
    return {
        type: PEOPLE_SEARCHBAR_CLEAR
    };
}




let call;
//let lastSearchThread;

const search = (text, location, page, size, first = true, ambassadors_only = 0) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(searchBarLoading(true));
            dispatch(searchBarShowSuggestions(false))
            const userTypes = getState().main.ui.global.statics.userTypes
            const selectedFilters = getState().main.ui.global.searchBar.selectedFilters
            const locationOrUser = getState().main.ui.global.searchBar.locationOrUser
            const myId = getState().main.user.uid
            const tmpText = !text || (text && text.replace(/\s/g, "").length === 0) ? '' : text;
            let response, newFilters;

            /*  if (call) {
                 //  call.cancel();
             } */

            call = axios.CancelToken.source();
            let ambassadors = null
            if (first) {
                response = await cloudfunctions.searchPeople(tmpText, location, 0, size, call, '', locationOrUser, myId, 0);
                const facets = response.data && response.data.facets ? response.data.facets : {}
                newFilters = SearchBussiness.normalizeFilters(facets, userTypes)

                if (ambassadors_only) {
                    const r = await cloudfunctions.searchPeople(tmpText, location, 0, size, call, '', locationOrUser, myId, ambassadors_only);
                    ambassadors = r.data ? r.data.results : []
                }
            } else {
                const filtersQueryString = SearchBussiness.buildSearchFiltersQueryString(selectedFilters)
                response = await cloudfunctions.searchPeople(tmpText, location, page, size, call, filtersQueryString, locationOrUser, myId, 0);

            }
            // console.log({
            //     ...response.data,
            //     size: size,
            //     filters: newFilters,
            //     ambassadors
            // }, "aqui debuggueando tu has visto");
            //   lastSearchThread = tmpText;
            dispatch(searchBarSuccess({ ...response.data, size: size, filters: newFilters, ambassadors }));
            return
        } catch (errors) {
            console.log('error', errors)
            if (!axios.isCancel(errors)) {
                dispatch(handleError(errors));

            }
            dispatch(searchBarLoading(false));
            return dispatch(
                searchBarFail(cloudfunctions.parseRequestError(errors))
            );
        }

    };
};

const searchLocation = (text) => {
    return async (dispatch) => {
        try {
            // If string is just spaces, keep it clean.
            const tmp = !text || (text && text.replace(/\s/g, "").length === 0) ? '' : text;
            if (!tmp || tmp === '') return dispatch(searchBarLocationLoading(false));
            dispatch(searchBarLocationLoading(true));
            const locations = await Utils.getLocations(text);
            dispatch(searchBarLocationSuccess(locations));
            dispatch(searchBarLocationLoading(false));
        } catch (errors) {
            dispatch(searchBarLocationLoading(false));
            return dispatch(searchBarLocationFail(errors));
        }
    };
};

const filterUsersByCriteria = (users, fuseOptions, searchKey) => new Fuse(users, fuseOptions).search(searchKey).map(({ item }) => item);

const megaSearch = (text, section) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {

        try {
            // Set loading to true
            dispatch(searchBarLoading(true));
            dispatch(searchBarShowSuggestions(false));
            // Get the user id
            const myId = getState().main.user.uid;
            // Get the filters
            const filters = getState().main.ui.global.searchBar
                .megaSearchFilters;
            // Get the dates options
            const datesOptions = getState().main.ui.global.searchBar
                .datesOptions;
            // If string is just spaces, keep it clean.
            const tmpText = !text || (text && text.replace(/\s/g, "").length === 0) ? null : text;
            // const mapToSoureData = array =>
            //     array.map(item => item._source && item._source);

            call = axios.CancelToken.source();

            const nonEmptyValue = (value) => {
                switch (typeof value) {
                    case "string":
                        return value.length > 0;
                    case "object":
                        return Object.keys(value).length > 0;
                    default:
                        return !!value;
                }
            }

            // Filter false, null, undefined, empty string, empty array
            const currentFilters = Object.entries(filters).reduce(
                (acc, [key, value]) =>
                    nonEmptyValue(value) // Value exists // Form is exception because it can be zero
                        ? { ...acc, [key]: value }
                        : acc,
                {}
            );
            // If there is no text and no filters, then we return
            if (!tmpText && !Object.keys(currentFilters).length) return dispatch(searchBarLoading(false));

            // If profession key exists, then we convert it into an array with only the values, deleting the keys
            if (currentFilters.profession) {
                currentFilters.profession = currentFilters.profession.map(
                    ({ value }) => value
                );
            }
            // If date key exists, then we convert it into an object with only the values, deleting the keys
            if (currentFilters.date) {
                currentFilters.date_filters = getQueryDateObject(
                    datesOptions,
                    currentFilters.date.id
                );
                delete currentFilters.date;
                delete currentFilters.date_filters.from;
            }
            // If location_address key exists, then we trim it
            if (currentFilters.location_address) {
                currentFilters.location_address = currentFilters.location_address.trim();
            }
            // If member_name key exists, then we trim it
            if (currentFilters.member_name) {
                currentFilters.member_name = currentFilters.member_name.trim();
            }
            // List of promises to fetch the data
            const promises = [
                cloudfunctions.searchAcrossAllPlatform(
                    tmpText,
                    myId,
                    "users",
                    currentFilters,
                    call
                ),
                cloudfunctions.fetchMyContacts(0, 1000, tmpText, {}, myId)
            ];
            // If the user needs to filter by member_name, then we fetch the data
            if (currentFilters.member_name) {
                promises.push(
                    cloudfunctions.searchAcrossAllPlatform(
                        currentFilters.member_name,
                        myId,
                        "users",
                        currentFilters,
                        call
                    )
                );
            }

            // Fetch the data
            let [{ data }, contacts, memberNameResponse] = await Promise.all(promises);

            const { result } = data;

            let usersData = result.users.data;
            // If we have the memberNameResponse, then we concat the data to the usersData
            if (memberNameResponse && memberNameResponse.data && memberNameResponse.data.result && memberNameResponse.data.result.users && memberNameResponse.data.result.users.data) {
                const usersDataIds = usersData.map(user => user.uid);
                const memberNameResponseIds = memberNameResponse.data.result.users.data.map(user => user.uid);
                const filteredUsers = memberNameResponseIds.filter(id => !usersDataIds.includes(id)).map(id => memberNameResponse.data.result.users.data.find(user => user.uid === id));
                usersData = [...usersData, ...filteredUsers];
            }
            // If the user entered a text, then we filter the results, otherwise we return all the results
            let users = tmpText
                ? filterUsersByCriteria(
                        usersData,
                        {
                            keys: [
                                "full_name",
                                "company_name",
                                "locationAddress"
                            ],
                            threshold: 0.4
                        },
                        tmpText
                    )
                : usersData;


            if (contacts && contacts.data && contacts.data.items) {
                contacts = tmpText
                        ? filterUsersByCriteria(
                                contacts.data.items,
                                {
                                    keys: ["full_name", "comapany", "state", "city"],
                                    threshold: 0.6
                                },
                                tmpText
                            )
                    : contacts.data.items;
            }

            // If the user entered a member_name, then we filter the results by member_name
            if (currentFilters.member_name) {
                users = filterUsersByCriteria(
                    users,
                    {
                        keys: ["full_name"],
                        threshold: 0.3
                    },
                    currentFilters.member_name
                );

                contacts = filterUsersByCriteria(
                    contacts,
                    {
                        keys: ["full_name"],
                        threshold: 0.3
                    },
                    currentFilters.member_name
                );
            }
            // If the user entered a location_address, then we filter the results by location_address
            if (currentFilters.location_address) {
                users = filterUsersByCriteria(
                    users,
                    {
                        keys: ["locationAddress"],
                        threshold: 0.3
                    },
                    currentFilters.location_address
                );

                contacts = filterUsersByCriteria(
                    contacts,
                    {
                        keys: ["city", "state"],
                        threshold: 0.3
                    },
                    currentFilters.location_address
                );
            }
            // If the user entered a profession, then we filter the contacts results by profession
            if (currentFilters.profession) {
                contacts = contacts.filter(contact => {
                    return currentFilters.profession.some(profession => contact.professions && contact.professions.includes(profession));
                });
            }
            // As Per 01/18/2024, we are not incluiding opportunities in the search
            // result.opportunities.data = result.opportunities.data
            //     .map(op => {
            //         if (!op.profession) return op;
            //         op.profession = op.profession.reduce(
            //             (acc, item) => ({ ...acc, [item]: true }),
            //             {}
            //         );
            //         return op;
            //     })
            //     .sort((a, b) => b.date - a.date);

            // As Per 01/18/2024, we are not incluiding news in the search
            // result.community.data = await Promise.all(result.community.data.map(async post => {
            //     const discussionRank = await firebase.discussionRank(
            //         post._id,
            //         myId
            //     );
            //     post.discussionRank = discussionRank;
            //     return post
            // }));

            // result.community.data = result.community.data.sort(
            //     (a, b) => b.created_at - a.created_at
            // );

            contacts = contacts.length > 0 ? contacts.map(mapContactToUserInfo) : [];

            dispatch(megaSearchSuccess({
                users,
                opportunities: [],//result.opportunities.data,
                community: [],//result.community.data,
                contacts,
            }));

        } catch (errors) {
            console.log("error", errors);
            if (!axios.isCancel(errors)) {
                dispatch(handleError(errors));
            }
            dispatch(searchBarLoading(false));
            return dispatch(
                megaSearchFail(cloudfunctions.parseRequestError(errors))
            );
        }
    }

}


const megaSearchUpdateFilters = (
    filters,
    text,
    section,
    fetchAfterUpdateFilter = true,
) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: MEGASEARCH_UPDATE_FILTERS,
            payload: {
                filters
            }
        });
        if (fetchAfterUpdateFilter) dispatch(megaSearch(text, section));
    };
};
const peopleSearchBar = (text, section) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(peopleSearchBarLoading(true));

            const myId = getState().main.user.uid;
            const tmpText =
                !text || (text && text.replace(/\s/g, "").length === 0)
                    ? ""
                    : text;
            call = axios.CancelToken.source();

            let [{ data }, contacts] = await Promise.all([
                cloudfunctions.searchAcrossAllPlatform(
                    tmpText,
                    myId,
                    section,
                    null,
                    call
                ),
                cloudfunctions.fetchMyContacts(0, 1000, tmpText, {}, myId)
            ])
           
            if (contacts && contacts.data && contacts.data.items) {
                contacts = contacts.data.items.filter(contact => {
                    return contact.full_name.toLowerCase().includes(text.toLowerCase());
                });

                // //map member fields to contacts fields
                // contacts = contacts.map(contact => {
                //     return {
                //         ...contact,
                //         photo: contact.photo,
                //         user_type: "",
                //         company_name: contact.work_info && contact.work_info.company ? contact.work_info.company : "",
                //         locationAddress: contact.work_info && contact.work_info.address && contact.work_info.address.city ? contact.work_info.address.city : "",
                //         subscription: {
                //             plan: ""
                //         },
                //         phone: contact.mobile_phone,
                //     };
                // });
            }
            const { result } = data;
            const { users } = result;
            console.log('CHECA ACA OMAR', { data, contacts, users})
            const results = new Fuse(users.data, {
                keys: ["full_name", "company_name", "locationAddress"],
                threshold: 0.4
            }).search(tmpText).map(({ item }) => item);

            dispatch(peopleSearchBarSuccess({ results, contacts }));
        } catch (errors) {
            console.log("error", errors);
            if (!axios.isCancel(errors)) {
                dispatch(handleError(errors));
            }
            dispatch(peopleSearchBarLoading(false));
            return dispatch(
                peopleSearchBarFail(cloudfunctions.parseRequestError(errors))
            );
        }
    };
};

export {
    searchFilterSet,
    searchBarShowSuggestions,
    searchFilterOpen,
    setLocationOrProfile,
    toggleSearchBar,
    searchBarLoading,
    searchBarSuccess,
    searchBarFail,
    search,
    clearSearch,
    searchValue,
    searchBlurValue,
    searchLocation,
    searchSetLocationValue,
    searchClearResults,
    megaSearch,
    megaSearchResetFilters,
    megaSearchUpdateFilters,
    peopleSearchBar,
    clearPeopleSearchBar
};
