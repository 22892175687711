export const MAIN = "MAIN";
export const INFORMATIONACCOUNT = "INFORMATIONACCOUNT";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const SPONSORSHIP = "SPONSORSHIP";
export const BLOCKED_CONTACTS = "BLOCKED_CONTACTS";
export const AGENCIES = "AGENCIES";
export const ACCOUNT = "ACCOUNT";
export const SUBSCRIPTION = "SUBSCRIPTION";
export const PRIVACY = "PRIVACY";
export const VERIFY_PHONE = "VERIFY_PHONE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const INVITES = "INVITES";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const BILLING_DETAILS = "BILLING_DETAILS";
export const PAYOUT_CONFIRM = "PAYOUT_CONFIRM";


