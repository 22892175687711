import { realEstateDetailsHandler } from "../../../../business/realEstateDetails"
import { CURRENT_REFERRAL_ERROR, CURRENT_REFERRAL_LOADING, CURRENT_REFERRAL_SUCCESS } from "../../constants"
import ReferralBusiness from '../../../../business/referral';
import { cleaningObject, convertToTimeStamp, extractCandidateData  } from '../../../../libs/Utils';
import { getMembersAndContactsFromCandidates } from ".";



//MARK: SENT NEW CURRENT REFERRAL 
export const sendCurrentReferral = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
  
      try {
        dispatch({ type: CURRENT_REFERRAL_LOADING })
        const me =           getState().main.user.profile
        const meId =         getState().main.user.uid
        const { address, company_info, email, lastname, name, phone, state, type, avatar, userName, subscription } = me && me
        const { stepData, draftId } = getState().main.ui.view.opportunityCreationFlow
        const {
           REFERRAL_COMPLETE_INFORMATION, 
          REFERRAL_PRIVACY_STEP, 
          CANDIDATES_SELECTION
        } = stepData && stepData;

        const formValues =   JSON.parse(JSON.stringify(REFERRAL_COMPLETE_INFORMATION));

        const {
          credit,
          clientInformation,
          clientState,
          clientStreetAddress,
          homeSaleContingency,
          lotSizeUnit,
          maxLotSize,
          minBeds,
          minLotSize,
          minUnits,
          pets,
          professionDescription,
          acres,
          baths,
          beds,
          clientType,
          estimatedPrice,
          financingInfo,
          maxAcres,
          maxPrice,
          maxSize,
          maxSquareFeet,
          maxUnits,
          minAcres,
          minPrice,
          minSize,
          minSquareFeet,
          propertySubType,
          propertyType,
          size,
          sizeUnit,
          squareFeet,
          units,
          retailSpace,
          clientCity,
          clientEmail,
          clientFirstName,
          clientLastName,
          clientMobileNumber,
          clientZipCode,

        } = formValues && formValues;
  
    
        //** GETTING THE REFFERAL TYPE */
        const referralType =     (CANDIDATES_SELECTION.length === 1) ? 'DIRECT' :
                                 (CANDIDATES_SELECTION.length > 1) ? 'FIRST_COME' : 'OPEN';
  
        /**  REMOTE LOCATION OR NO REMOTE LOCATION */
        const isRemoteLocation = formValues.locationIsRemote.value === 'REMOTE';
        

        /***  TAKING FEE DATA AND BUILDING THE FEE DATA OBJECT TO ENDPOINT ***/
        let feeData = ReferralBusiness.getReferralFeeToFlow(formValues);

        /***     BUILDING REAL ESTATE OPTIONAL DATA     ***/

        /** JUST REAL ESTAATE PROPERTYS VALUES */

        /** ULTIMATE REAL ESTATE DATA  */

        /** TIMING INFO DATA  */
        const timingInfoData = {
          clientSource:  formValues.clientSource.key,
          currentStatus: formValues.currentSatus.key,
          lastContact:   Math.round(new Date(formValues.lastContact).getTime() / 1000)
        }
  
        /**   LOCATION DATA  */
        let locationData = null;
        if (formValues.address && formValues.address.lat && formValues.address.lng) {
          locationData = {
            coords:                [formValues.address.lat, formValues.address.lng],
            fullAddress:           formValues.address.description,
            state:                 formValues.address.state,
            stateShort:            formValues.address.stateShort,
            subAdministrativeArea: formValues.address.subAdministrativeArea,
            zipcode:               formValues.address.zipCode,
          }
        }

  
        /***   PROFESSION DATA  ***/
        const professionData = formValues.profession ? parseInt(formValues.profession.value) :
                               (CANDIDATES_SELECTION.length === 1 && CANDIDATES_SELECTION[0].user_type === 100) ? null :
                               (CANDIDATES_SELECTION.length > 1) ? parseInt(formValues.profession.value)
                                : null;


       const sendReferralEstateDetails =   CANDIDATES_SELECTION.length === 1 && CANDIDATES_SELECTION[0] && CANDIDATES_SELECTION[0].user_type === 100 || professionData === 100 || false


       const realEstateOptionalData = {
        clientType:   clientType && clientType.value ?  clientType.value : null,
        propertyType: clientType && clientType.value && sendReferralEstateDetails && realEstateDetailsHandler(formValues)
      }
      // Get candidate members and candidate contacts
        let { members: toMembers, contacts: toContacts } = getMembersAndContactsFromCandidates(CANDIDATES_SELECTION, true);

        // Map members candidates to the proper format to send to the endpoint
        toMembers = toMembers.map(memberId =>  {
          const member = CANDIDATES_SELECTION.find(candidate => candidate.uid === memberId);
          return ({
            address: member.locationAddress,
            avatar: member.avatar,
            companyName: member.company_name,
            email: member.email,
            name: member.first_name,
            lastname: member.last_name,
            phone: member.phone,
            state: member.state || "",
            plan: member && member.subscription && member.subscription.plan,
            type: member.user_type && parseInt(member.user_type),
            uid: member.uid,
            userName: member.userName,
          })
        });

        // Map contacts candidates to the proper format to send to the endpoint
        toContacts = toContacts.map(contactId => {
          const contact = CANDIDATES_SELECTION.find(candidate => candidate.contact_id === contactId);
          return ({
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone: contact.phone,
            contact_id: contact.uid || contact.contact_id
          })
      })

        const clientInfoActive =  !!clientInformation   ||
                                  !!clientFirstName     ||
                                  !!clientLastName      ||
                                  !!clientEmail         ||
                                  !!clientMobileNumber  ||
                                  !!clientState         ||
                                  !!clientStreetAddress ||
                                  !!clientZipCode



  
        const clientInfo = {
          avatar:          (clientInformation) ? clientInformation.avatar : null,
          firstName:       (clientInformation) ? clientInformation.first_name : clientFirstName ? clientFirstName : null,
          lastName:        (clientInformation) ? clientInformation.last_name : clientLastName ? clientLastName : null,
          email:           (clientInformation) && clientInformation.email || clientEmail && clientEmail || "",
          mobile_phone:    (clientInformation) && clientInformation.mobile_phone || clientMobileNumber && clientMobileNumber || "",
          phone:           (clientInformation) && clientInformation.mobile_phone || clientMobileNumber && clientMobileNumber || "",
          address:         (clientInformation) ? clientInformation.locationAddress : clientCity ? clientCity : null,
          state:           (clientState) ? clientState.label : "",
          isMember:        (clientInformation) ? clientInformation.userName ? true : clientInformation.is_member : false,
          // Get client's ID if it's a member
          uid:             (clientInformation && clientInformation.userName) ? clientInformation.uid : null,
          // Get client's userName if it's a member
          userName:        (clientInformation && clientInformation.userName) ? clientInformation.userName : null,
          // If the client is a contact get the contact ID
          contactId:       (clientInformation && !clientInformation.userName) ? clientInformation.contact_id : null,
          contactOf:       (clientInformation && !clientInformation.userName) ? meId : null,
        }
  
       
  
  
  const createdClientToStorage = (
           !!clientFirstName
          || !!clientLastName
          || !!clientEmail
          || !!clientMobileNumber
          || !!clientState
          || !!clientStreetAddress
          || !!clientZipCode
        ) ? {
              first_name: clientFirstName ? clientFirstName : "",
              last_name:  clientLastName ? clientLastName : "",
              email:      clientEmail ? clientEmail : "",
              mobile_phone:      clientMobileNumber ? clientMobileNumber : "",
            }: {};
        // Add client as contact if it's not a member and not a contact
        try {
          if (Object.entries(createdClientToStorage).length > 0) {
            console.log('CREATING AN ORDER', createdClientToStorage)
              const { data } = await cloudfunctions.createContact(meId, createdClientToStorage);
              const { contact_id } = data;
              clientInfo.contactId = contact_id;
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.contact_id) {
              clientInfo.contactId = error.response.data.contact_id;
              console.log("Contact already exists")
          } else {
            console.log(error, "error creating contact")
          }
        }
           
  
      
    const clientInfoCleaned = Object.keys(clientInfo)
                              .filter((cangriB) => clientInfo[cangriB] != null)
                              .reduce((alfaCentauri, cangriB) => ({ ...alfaCentauri, [cangriB]: clientInfo[cangriB] }), {});

       
          

                                 
       
        
        
        /***  RECEIVING ALL THE DATA FROM ABOVE ***/
        const allReferralCurrentData = {
          type:                referralType,
          isRemote:            isRemoteLocation,
          description:         formValues.description,
          client:              clientInfoActive ? clientInfoCleaned : null,
          notificationRadius:  formValues.targetToNotify.value,
          location:            !isRemoteLocation ? locationData : null,
          realEstateDetails:   (sendReferralEstateDetails && clientType && clientType.value) ? realEstateOptionalData : null,
          describedProfession: professionDescription ? professionDescription : null,
          from: {
            address,
            avatar,
            company_info,
            email,
            lastname,
            name,
            phone,
            plan: subscription.plan,
            state,
            type: parseInt(type),
            uid: meId,
            userName,
          },
          fromId:              meId,
          fee:                 feeData,
          timingInfo:          timingInfoData,
          to:                  toMembers,
          profession:          professionData,
          toContact:           CANDIDATES_SELECTION.length > 0 ? toContacts : [],
          origin:             'PWA',
          visibilityOptions:   (referralType !== 'OPEN') ? formValues.visibility.value : null,
          candidateAcceptance: (referralType === 'FIRST_COME') ? formValues.acceptanceCondition.value : null,
        }
  
        /* Cleaning the referral data - removing the null values */
        let cleaningReferralData = Object.keys(allReferralCurrentData)
                                   .filter((cancriB) => allReferralCurrentData[cancriB] != null)
                                   .reduce((alfaCentauri, cancriB) => ({ ...alfaCentauri, [cancriB]: allReferralCurrentData[cancriB] }), {});
  
       console.log('DATA ERROR>', cleaningReferralData)
        await cloudfunctions.newSendReferral(cleaningReferralData)  
        if (draftId) {
          await firebase.deleteDraftReferral(meId, draftId);
        }
      dispatch({ type: CURRENT_REFERRAL_SUCCESS })
  
      } catch (error) {
        console.error('Referral Current: something went wrong', error)
        dispatch({ type: CURRENT_REFERRAL_ERROR });
      }
  
  
  
    }
  }