import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { selectStyle } from "../../../components";
import {
    InputContainer,
    TextArea
} from "../../../../Settings/common/Components";
import { InputCounter } from "../../../../Settings/InformationAccount/styles";
import { Box, Flex } from "rebass";
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Profession = ({
    titleStyles,
    theme,
    professionOptions,
    handleChange,
    values,
    t,
    errors
}) => {
    const key = "profession_";
    const [isCollapsed, setIsCollapsed] = useState(false);
    return (
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {
                    setIsCollapsed(prev => !prev);
                }}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Select the profession that this job pertains to:
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <SelectContainer>
                    <Select
                        isDisabled={!!values.cannotFindProfession}
                        options={professionOptions}
                        onChange={option => {
                            handleChange({
                                target: {
                                    name: "profession",
                                    value: option
                                }
                            });
                            handleChange({
                                target: {
                                    name: "cannotFindProfession",
                                    value: false
                                }
                            });
                            handleChange({
                                target: {
                                    name: "professionDescription",
                                    value: null
                                }
                            });
                        }}
                        styles={selectStyle}
                        value={values.profession}
                        isClearable
                        placeholder="Select...*"
                        error={errors.profession}
                    />
                </SelectContainer>
                <Flex p={`${theme.spacing[5]} 0`}>
                    <CheckBox
                        inverted
                        disabled={!!values.profession}
                        label="Check this box if you can't find the right profession."
                        onChange={ev =>
                            handleChange({
                                target: {
                                    name: "cannotFindProfession",
                                    value: !values.cannotFindProfession
                                }
                            })
                        }
                        checkBoxColor={theme.blueMedium}
                        checked={values.cannotFindProfession}
                    />
                </Flex>
                {values.cannotFindProfession && (
                    <Flex p={`${theme.spacing[5]} 0`}>
                        <InputContainer>
                            <TextArea
                                style={{ resize: "vertical" }}
                                type="text"
                                name="professionDescription"
                                value={values.professionDescription}
                                onChange={handleChange}
                                maxLength="30"
                                minLength="1"
                                placeholder="State the profession in less than 30 characters*"
                                error={!!errors.professionDescription}
                            />
                            {!!values.professionDescription &&
                                !!values.professionDescription.length && (
                                    <InputCounter>
                                        {values.professionDescription.length}/30
                                    </InputCounter>
                                )}
                        </InputContainer>
                    </Flex>
                )}
            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div
                    dangerouslySetInnerHTML={{ __html: t("employeeProfessionInfo") }}
                />
            </ReactTooltip>
        </Box>
    );
};

export default Profession;
