import React from 'react'
import Styles from './Loader.css'
import { LoaderContainer, LoaderLogo, LogoContainer, SpinningLoader } from './styles'
import { SecondLogo } from '../../../../../../../assets/svg/SecondLogo'


export const Loader = () => {
    return (
        <LoaderContainer>
            <div>
                <LogoContainer>
                    <SecondLogo />
                </LogoContainer>
                <SpinningLoader/>
            </div>
        </LoaderContainer>
    )
}
