import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex } from "rebass"
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../../../Settings/common/Components";
import GoogleMap from "../../../../../../../../../libs/GoogleMaps";
import { Icon } from '../../../../../../atoms/shared/Icon';
import { selectStyle } from "../../../../components";
import Select from 'react-select';
import useDebounce from '../../../../../../../../../libs/UseDebounce';

const SearchContainer = styled.div`
    border: 1px solid ${(props) => props.error ? '#DC3545' : '#929292' };
    height: 50px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    background-color: #FFFFFF;
    margin-top: 1em;

    &:focus-within {
        border: 1px solid rgba(5, 122, 255, 1);
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        outline: none;
    }
`;

const SearchSuggestions = styled.div`
    position: absolute;
    top: 120%;
    width: 100%;
    z-index: 1000;
    padding: .5em 0;
    max-height: 600%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
    overflow-y: scroll;
    left: 0;
`;

const SearchBox = styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 16px;
    padding: 0 10px;
    display: flex;
    flex-grow: 1;
    &:focus {
        border: none;
        outline: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 46px;
    justify-content: spacer-between;
    padding: 0px 20px;
    align-items: center;
    background: ${(props) => props.background};
    cursor: pointer;

    &:hover {
        background: #057AFF;
        color: white;
    }
`;

const SuggestionSelected = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LocationOptions = [
    { value: 'PARTICULAR', label: "This referral was for a particular location." },
    { value: 'REMOTE', label: "This referral was completed remotely." }
];

const LocationOptionsRecommendation = [
    { value: 'PARTICULAR', label: "This recommendation request is for a particular location." },
    { value: 'REMOTE', label: "This service can be completed remotely from anywhere." }
];

const LocationOptionsJob = [
    { value: 'PARTICULAR', label: "This job is for a particular location." },
    { value: 'REMOTE', label: "This job can be completed remotely from anywhere." }
];

const Location = ({ theme, formValues, onChange, errors, isReferral = true, isJob = false, }) => {
    const GoogleMaps =  new GoogleMap()
    const [searchTerm, setSearchTerm] = useState('');
    const debounceSearchTerm = useDebounce(searchTerm, 300);
    const [results, setResults] = useState([]);
    const [ throwErrorField, setThrowErrorField  ] = useState(false);
    const [suggestion, setSuggestion] = useState(
        (formValues && formValues.address && formValues.address.description)
        ? { formatted_address: formValues.address.description }
        : null
    );
    
    const performSearch = async () => {
        try {
            const { results } = await GoogleMaps.geocodeAddress(debounceSearchTerm)
            setResults(results)
        } catch (e) {
            // Pass
        }
    }

    const searchInputChangeHandler = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        performSearch()
    }, [debounceSearchTerm])

    const onClickSuggestion = (suggestion) => {
        setSuggestion(suggestion)
        const lat = typeof suggestion.geometry.location.lat === "function"
            ? suggestion.geometry.location.lat()
            : suggestion.geometry.location.lat;
        
        const lng = typeof suggestion.geometry.location.lng === "function"
            ? suggestion.geometry.location.lng()
            : suggestion.geometry.location.lng
        // Get proper State name
        const state = GoogleMaps.getDataFromGMO(suggestion)
        // Get proper City name
        const city = GoogleMaps.getDataFromGMO(suggestion, 'locality')
        // Get proper Postal Code name
        const zipCode = GoogleMaps.getDataFromGMO(suggestion, 'postal_code')
        onChange({
            target: {
                name: "address",
                value: {
                    description: suggestion.formatted_address,
                    lat,
                    lng,
                    state: state ? state.long_name : "",
                    stateShort: state ? state.short_name : "",
                    subAdministrativeArea: city ? city.long_name : "",
                    zipCode: zipCode ? zipCode.long_name : "",
                },
            }
        })
        setResults([])
        setSearchTerm('')
    }

  
    //const throwErrorField =  formikError && noValuesSelectedYet;
   

    useEffect(() => {
        const formikError = typeof errors.locationIsRemote === 'string';
        if ( formikError  ) setThrowErrorField(true)
         if ( formValues.locationIsRemote &&  typeof formValues.locationIsRemote.value !== null ) {
          setThrowErrorField(false)
        }
    }, [ errors.locationIsRemote, formValues.address, formValues.locationIsRemote ])

    return(
        <>
            <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                <InputContainer
                    triggerShowLabel={
                        formValues.state ? "show" : "hide"
                    }
                    showLabelOnValue
                >
                    <Select
                        options={isReferral ? LocationOptions : (isJob ? LocationOptionsJob : LocationOptionsRecommendation)}
                        isClearable
                        onChange={option => {
                            onChange({
                                target: {
                                    name: "locationIsRemote",
                                    value: option,
                                }
                            })
                        }}
                        styles={selectStyle}
                        name="locationIsRemote"
                        placeholder="Select...*"
                        value={formValues.locationIsRemote}
                        error={throwErrorField}
                    />
                </InputContainer>
            </Flex>
            { formValues.locationIsRemote && formValues.locationIsRemote.value === 'PARTICULAR' && (
                <SearchContainer error={throwErrorField}>
                    {!suggestion && (
                        <>
                            <SearchBox
                                placeholder="Enter an address, county, city or zip code*"
                                type="text"
                                name="searchTerm"
                                value={searchTerm}
                                onChange={(ev) => searchInputChangeHandler(ev)}
                            />
                            <>
                                { results && results.length > 0 && (
                                    <SearchSuggestions>
                                        { results.map((u, i) => (
                                            <Wrapper onClick={() => onClickSuggestion(u)} key={u.formatted_address} background={(i % 2 === 0) ? '#FAFAFA' : '#FFFFFF'}>
                                                {u.formatted_address}
                                            </Wrapper>
                                        ))}
                                    </SearchSuggestions>
                                ) }
                            </>
                        </>
                    )}
                    { suggestion && (
                        <SuggestionSelected>
                            <span> { suggestion.formatted_address} </span>
                            <Icon icon="close" color={"#929292"} onClick={() => setSuggestion(null)} />
                        </SuggestionSelected>
                    )}
                </SearchContainer>
            )}
        </>
    )
}

export default Location;