import { connect } from 'react-redux';
import View from './view';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';
import { bindActionCreators } from 'redux';
import { sendCurrentReferral } from '../../../../../../../redux/actions/creators/opportunityCreationFlow';

const mapStateToProps = (state) => {
    const statics = state.main.ui.global.statics;
    const feeDueOptions = statics.referralFeePaymentDue;
    const feeTypesOptions = statics.referralFeeTypes;
    const feeFrequencyOptions = statics.referralFeeFrequency;
    const feeDurationOptions = statics.referralFeeDuration;
    const feeCommencementOptions = statics.referralFeeCommencement;
    
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION],
        candidates: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
        clientTypes: state.main.ui.global.statics.clientTypes,
        propertyTypes: state.main.ui.global.statics.propertyTypes,
        financingInfoTypes: state.main.ui.global.statics.financingInfoTypes,
        referralIsLoading: state.main.ui.view.opportunityCreationFlow.referralIsLoading,
        successReferral: state.main.ui.view.opportunityCreationFlow.successReferral,
        errorReferral: state.main.ui.view.opportunityCreationFlow.errorReferral,
        user: state.main.user && state.main.user.profile
        ? state.main.user.profile
        : {},
        referralFeeTypes: state.main.ui.global.statics.referralFeeTypes,
        feeDueOptions,
        feeTypesOptions,
        feeFrequencyOptions,
        feeDurationOptions,
        feeCommencementOptions
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    sendCurrentReferral
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(View);