import { TOGGLE_SIDEBAR, TOOGLE_REFERRALNOTIFICATION } from "../../constants";
import { push } from "connected-react-router";

function toggleSidebar(payload) {
    return {
        type: TOGGLE_SIDEBAR,
        payload
    };
}

function goToUser(userName) {
    return dispatch => {
        return dispatch(push(`/profile/${userName}`));
    };
}

const toogleReferralNotification = enabled => ({
    type: TOOGLE_REFERRALNOTIFICATION,
    payload:{
        enabled: enabled
    }
});

export { toggleSidebar, goToUser, toogleReferralNotification };
