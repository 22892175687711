import React, { useState, useEffect } from 'react'
import confetti from 'canvas-confetti';
import {
    AttentionText,
    Button,
    ButtonContainer,
    ModalContainer,
    AttentionPopStyled,
    ContainerStyled,
    HorizontalLine,
    Title
} from './SuccessReferral.styles'
import { useHistory } from 'react-router-dom';
import { theme } from '../../../../../../../../theme';
import PopupModal from '../../../../../atoms/shared/PopupBlurModal';


export const SuccesReferralSentView = ({ resetState }) => {
    const { push } = useHistory();
    useEffect(() => {
        const animationEnd = Date.now() + 9000;
        const canvas = document.createElement("canvas");
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.width = "100%";
        const modalRoot = document.getElementById("success-referral");
        modalRoot.appendChild(canvas);
        var confettiCelebration = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        confettiCelebration({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) return clearInterval(interval);

            confettiCelebration({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }, 3000);
    }, []);

    return (
        <PopupModal
            open={true}
            width={'100%'}
            modalContentStyles={{
                background: 'transparent',
                maxWidth: theme.mobileDevice ? "100vw" : "455px",
                minWidth: "393px",
                borderRadius: "16px",
                margin: "auto",
                position: "fixed",
                top: "80px",
                left: 0,
                right: 0

            }}
        >
            <AttentionPopStyled>
                <ModalContainer id="success-referral">
                    <Title>Your Job has been successfully created!</Title>
                    <AttentionText>
                        The receiving party(s) will receive notifications about your opportunity, and will have the ability to accept or decline it.
                    </AttentionText>
                    <HorizontalLine />
                    <ButtonContainer>

                        <Button
                            close
                            to={`/referrals`}
                        >
                            Go To My Ops
                        </Button>

                        <Button onClick={() => resetState()} continue>
                            Create Op
                        </Button>
                    </ButtonContainer>
                </ModalContainer>
            </AttentionPopStyled>
        </PopupModal>
    )
}

export const ErrorMessageView = ({ resetState }) => {
    const { push } = useHistory();
    return (
        <PopupModal
            open={true}
            width={'100%'}
            modalContentStyles={{
                background: 'transparent',
                maxWidth: theme.mobileDevice ? "100vw" : "460px",
                minWidth: "393px",
                borderRadius: "16px",
                margin: "auto",
                position: "fixed",
                top: 180,
                left: 0,
                right: 0

            }}
        >
            <ContainerStyled>
                <ModalContainer id="success-referral">
                    <Title>Sorry! Something went wrong</Title>
                    <AttentionText>
                        Try again and create a new Op
                    </AttentionText>
                    <ButtonContainer style={{
                        display: 'flex',
                        justifyContent: 'center'

                    }}>
                        <Button onClick={() => resetState()} >
                            Create Op
                        </Button>
                    </ButtonContainer>
                </ModalContainer>
            </ContainerStyled>
        </PopupModal>
    )
}


