import React, { Component } from "react";
import CompanyLogo from "../../../../../../assets/svg/NuOpRectangleTransparent.svg";
import { Flex, Box } from "rebass";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    turnOnNotificationBell,
    currentSelectedTab,
    toogleMobileSideMenu
} from "../../../../../../redux/actions/creators/navbar";
import { signOut } from "../../../../../../redux/actions/creators/user";
import { accept } from "../../../../../../redux/actions/creators/relations";
import { goToUser } from "../../../../../../redux/actions/creators/navigation";
import { TopbarContainer } from "./topNavBar-styles";
import { withRouter } from "react-router-dom";
import { toggleSidebar } from "../../../../../../redux/actions/creators/navigation";
import BottomNavbar from "../BottomNavbar";
import MenuSideBar from "../MenuSideBar";
import { Icon } from "../../../../../ui/presentation/atoms/shared/Icon";
import { Header2 } from "../../../../../ui/presentation/atoms/shared/Text";
import { Link } from "react-router-dom";
import Avatar from "../../../../../ui/presentation/atoms/shared/AvatarCircle2";

const initialState = {};
class MobileNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onClickSearch = () => {
        this.props.history.push("/search");
    };

    toogleMobileSideMenu = () => {
        const open = !this.props.mobileSideMenuOpen;
        this.props.toogleMobileSideMenu(open);
    };
    render() {
        const { user, backButton, turnOnBell } = this.props;
        const currentPath = window.location.pathname;
        return (
            <React.Fragment>
                <TopbarContainer
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        pl={"1rem"}
                        pr={"1rem"}
                        width={"100%"}
                        style={{
                            height: "100%"
                        }}
                    >
                        {!backButton.enabled && (
                            <Box onClick={this.toogleMobileSideMenu} id="navbar-avatar-menu-button">
                                <Avatar size={"34px"} src={user.avatar} />
                            </Box>
                        )}

                        {backButton.enabled && (
                            <Box>
                                <Header2
                                    color="#546173"
                                    onClick={this.props.backButton.action}
                                >
                                    Back
                                </Header2>
                            </Box>
                        )}
                        <Link
                            to="/home"
                            onClick={() =>
                                this.props.currentSelectedTab("Home")
                            }
                        >
                            <Flex
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="NuOp"
                                    src={CompanyLogo}
                                    width={"60px"}
                                    height={"60px"}
                                />
                            </Flex>
                        </Link>

                        {user && (
                            <Box
                                className="navbar-search-icon"
                                style={{ cursor: "pointer" }}
                                onClick={this.onClickSearch}
                            >
                                <Icon icon="search" color="#B6B6B6" />
                            </Box>
                        )}
                    </Flex>
                </TopbarContainer>
                {!currentPath.includes("send-referral") && <BottomNavbar />}
                <MenuSideBar />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.main.ui.view.profile.accept.loading,
        turnOnBell: state.main.ui.global.notifications.turnOnBell,
        meUid:
            state.main.user && state.main.user.uid ? state.main.user.uid : null,
        user:
            state.main.user && state.main.user.profile
                ? state.main.user.profile
                : {},
        tabsOptionsMobile: state.main.ui.global.navbar.tabsOptionsMobile,
        mobileSideMenuOpen: state.main.ui.global.navbar.mobileSideMenuOpen,
        backButton: state.main.ui.global.navbar.backButton
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleSidebar,
            signOut,
            accept,
            goToUser,
            turnOnNotificationBell,
            currentSelectedTab,
            toogleMobileSideMenu
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MobileNavbar)
);
