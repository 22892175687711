import { OPPORTUNITIES_MODEL } from "../../../../../assets/strings";
import { MEDIABREAKPOINTS } from "../../../../../theme";
import {
    FETCH_OPS_ACTIVITY_COUNT_LOADING,
    FETCH_OPS_ACTIVITY_COUNT_SUCCESS,
    FETCH_OPS_ACTIVITY_COUNT_FAIL,
    OPS_ACTIVITY_COUNT_UPDATE_FILTERS,
    FETCH_OPS_ACTIVITY_ANALYTICS_LOADING,
    FETCH_OPS_ACTIVITY_ANALYTICS_SUCCESS,
    FETCH_OPS_ACTIVITY_ANALYTICS_FAIL,
    OPS_ACTIVITY_ANALYTICS_UPDATE_FILTERS,
    FETCH_MEMBER_COMPARISON_LOADING,
    FETCH_MEMBER_COMPARISON_SUCCESS,
    FETCH_MEMBER_COMPARISON_FAIL,
    MEMBER_COMPARISON_UPDATE_FILTERS,
    MEMBER_COMPARISON_RESET_FILTERS
} from "../../../../actions/constants";

const initialState = {
    opsActivityCount: {
        isLoading: false,
        data: {},
        // Filters:
        //  - activity_type: string
        //  - ops_type: ["REFERRAL","JOB"]
        //  - date_range: Enum: "LAST_THREE_MONTHS" "LAST_SIX_MONTHS" "LAST_TWELVE_MONTHS" "LAST_THIRTY_DAYS" "THIS_YEAR" "PREVIOUS_YEAR" "THIS_QUARTER" "PREVIOUS_QUARTER" "ALL_TIME"
        filters: {
            activity_type: "OPS_OUT",
            ops_type: [OPPORTUNITIES_MODEL.REFERRAL, OPPORTUNITIES_MODEL.JOB],
            date_range: "LAST_TWELVE_MONTHS"
        },
        errors: []
    },
    opsActivityAnalytics: {
        isLoading: false,
        data: {
            OPS_IN: [],
            OPS_OUT: []
        },
        filters: {
            status: "ALL",
            ops_type: [OPPORTUNITIES_MODEL.REFERRAL, OPPORTUNITIES_MODEL.JOB],
            date_range: window.innerWidth < MEDIABREAKPOINTS.tablet ? "THIS_QUARTER" : "LAST_TWELVE_MONTHS"
        },
        errors: []
    },
    memberComparison: {
        isLoading: false,
        data: [],
        filters: {},
        errors: []
    }
};

function referralsAnalyticsReducer(state = initialState, action) {
    switch (action.type) {

        case FETCH_OPS_ACTIVITY_COUNT_LOADING: {
            const _state = { ...state };
            _state.opsActivityCount.isLoading = true;
            return _state;
        }

        case FETCH_OPS_ACTIVITY_COUNT_SUCCESS: {
            const _state = { ...state };
            _state.opsActivityCount.isLoading = false;
            _state.opsActivityCount.data = action.payload;
            return _state;
        }

        case FETCH_OPS_ACTIVITY_COUNT_FAIL: {
            const _state = { ...state };
            _state.opsActivityCount.isLoading = false;
            _state.opsActivityCount.errors = action.payload;
            return _state;
        }

        case OPS_ACTIVITY_COUNT_UPDATE_FILTERS: {
            const _state = { ...state };
            _state.opsActivityCount.filters = {
                ..._state.opsActivityCount.filters,
                ...action.payload
            };
            return _state;
        }

        case FETCH_OPS_ACTIVITY_ANALYTICS_LOADING: {
            const _state = { ...state };
            _state.opsActivityAnalytics.isLoading = true;
            return _state;
        }

        case FETCH_OPS_ACTIVITY_ANALYTICS_SUCCESS: {
            const _state = { ...state };
            _state.opsActivityAnalytics.isLoading = false;
            _state.opsActivityAnalytics.data = action.payload;
            return _state;
        }

        case FETCH_OPS_ACTIVITY_ANALYTICS_FAIL: {
            const _state = { ...state };
            _state.opsActivityAnalytics.isLoading = false;
            _state.opsActivityAnalytics.errors = action.payload;
            return _state;
        }

        case OPS_ACTIVITY_ANALYTICS_UPDATE_FILTERS: {
            const _state = { ...state };
            _state.opsActivityAnalytics.filters = {
                ..._state.opsActivityAnalytics.filters,
                ...action.payload
            };
            return _state;
        }

        case FETCH_MEMBER_COMPARISON_LOADING: {
            const _state = { ...state };
            _state.memberComparison.isLoading = true;
            return _state;
        }

        case FETCH_MEMBER_COMPARISON_SUCCESS: {
            const _state = { ...state };
            _state.memberComparison.isLoading = false;
            _state.memberComparison.data = action.payload;
            return _state;
        }

        case FETCH_MEMBER_COMPARISON_FAIL: {
            const _state = { ...state };
            _state.memberComparison.isLoading = false;
            _state.memberComparison.errors = action.payload;
            return _state;
        }

        case MEMBER_COMPARISON_UPDATE_FILTERS: {
            const _state = { ...state };
            _state.memberComparison.filters = {
                ..._state.memberComparison.filters,
                ...action.payload
            };
            return _state;
        }

        case MEMBER_COMPARISON_RESET_FILTERS: {
            const _state = { ...state };
            _state.memberComparison.filters = {};
            return _state;
        }

        default:
            return state;
    }
}

export default referralsAnalyticsReducer;