import React, { useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components'
//import PersonSearchBar from '../../../../../shared/PeopleSearchBar';
import { MainText, StepWrapper, ButtonContainer, Button } from "../../../components";
import { Icon } from '../../../../../atoms/shared/Icon';
import CandidateCreation from './PastCandidateSelection';
import ReactTooltip from "react-tooltip";
import { WhiteButton } from '../../ReferralCompleteInformation/styles';
import CreateContactModal from '../../../../../atoms/shared/CreateContactModal';
import UserInfo from '../../../../../atoms/shared/PeopleSearchBar/UserInfo';
import _ from 'lodash';
import PeopleSearchBar from '../../../../../atoms/shared/PeopleSearchBar';
import  { DummySearch } from './DummyInput'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const SearchBoxContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 2em;
`;

export const SuggestionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1em; 
    width: 100%;
`;

const MainRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    padding: 16px 28px 16px 16px;
`;

const Avatar = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
`;

const SuggestionCard = styled.div`
    width: 100%;
    height: 46px;
   // box-shadow: 0px 2px 16px 0px #0000000D;
    background-color: transparent;
    border-radius: 4px;
    position: relative;
`;

const ContactCard = styled(SuggestionCard)`
   height: 46px;
    background: transparent;
`;

const MainAction = styled.div`
    display: flex;
    align-items: flex-start;
    position: absolute;
    right: 16px;
    top: 16px;

    &:hover {
        cursor: pointer;
    }
`

const UserName = styled(MainText)`
    font-size: 16px;
    text-align: left;
    flex: 1;
`;

const UserNameWithPadding = styled(UserName)`
    padding-left: 16px;
`;


const Row = styled.div`
    display: flex;
    flex-direction: row;
    color: #929292;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    flex: 1;
`;

const MainContainer = styled.div`
     display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background: transparent;
    div {
    background: transparent;
    }
`;

const IconContainer = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HelpText = styled.div`
    font-size: ${({ theme }) => (theme.mobileDevice) ? '14px' : '16px'};
    line-height: 24px;
    font-weight: 500;
    color: #929292;
   padding: 1em 0em;
`;

const ActionableText = styled.span`
    color: #057AFF;
    text-decoration: underline;
    cursor: pointer;
`;

const NotMemberLabel = styled.div`
    width: 136px;
    font-size: 14px;
    font-weight: 500;
    color: #057AFF;
    text-align: center;
    border-radius: 4px;
    background: rgba(5, 122, 255, 0.2);
    padding: 2px 8px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5em;
    cursor: pointer;
`;

const subtitleLabel = {
    REFERRAL: 'This will create a new contact, and invite them to review this referral.',
    RECOMMENDATION: 'This will create a new contact, and invite them to review this recommendation.',
    JOBEMPLOYEE: 'This will create a new contact, and invite them to review this job.',
    JOBCONTRACTOR: 'This will create a new contact, and invite them to review this job.',
    PASTREFERRAL: 'This will create a new contact, and invite them to review this past referral.'
};

export const Suggestion = ({ user, professions, deleteAction  } ) => {
    const [actionActive, setActionActive] = useState(false);
    const {
        uid,
        first_name,
        last_name,
        avatar,
        company_name,
        phone,
        email,
        user_type,
    } = user;

    const [proffesion] = professions.filter(p => p.key === user_type)
    const fullName = `${user.first_name} ${user.last_name}`
    
    return (
        <SuggestionCard>
            <MainContainer>
                
            <UserInfo
                    name={fullName}
                    avatarUrl={user.avatar}
                    organization={user.company_name}
                    profession={user.user_type}
                    location={user.locationAddress}
                    tier={user.subscription.plan}
                    px={0}
                    firstName={user.first_name}
                    lastName={user.last_name}

                />
               {deleteAction && (<MainAction
                    onMouseEnter={() => setActionActive(true)}
                    onMouseLeave={() => setActionActive(false)}
                    onClick={() => deleteAction(uid ? uid : "")}
                >
                    <Icon icon="trash" height={14} width={12} color={actionActive ? '#929292' : '#C4C4C4'} />
                </MainAction>)
                }
            </MainContainer>
        </SuggestionCard>
    );
};

export const Contact = ({ uuid, email, lastName, firstName, deleteAction }) => {
    const [actionActive, setActionActive] = useState(false);
    const fullName = `${firstName} ${lastName}`;
    return (
        <ContactCard>
            <MainContainer>
                 <UserInfo
                    name={fullName}
                    avatarUrl={""}

                    // profession={user_type}
                    // location={locationAddress}
                    //tier={subscription.plan}
                    px={0}
                    firstName={firstName}
                    lastName={lastName}
                />
                {deleteAction && (<MainAction
                    onMouseEnter={() => setActionActive(true)}
                    onMouseLeave={() => setActionActive(false)}
                    onClick={() => deleteAction(uuid)}
                >
                    <Icon icon="trash" height={14} width={12} color={actionActive ? '#929292' : '#C4C4C4'} />
                </MainAction>)
           }

                <InfoContainer>
                    {email &&
                        (<Row>
                            <IconContainer>
                                <Icon icon="mail" width={14} height={10} color={'#C4C4C4'} />
                            </IconContainer>
                            {email}
                        </Row>)
                    }
                    <NotMemberLabel>Not a member yet</NotMemberLabel>
                </InfoContainer>
            </MainContainer>
        </ContactCard>
    );
}

const CandidatesSelection = ({ theme, stepData, setStepData, professions, t, opType, currentStep, allState }) => {
    const [candidates, setCandidates] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [candidateCreationVisible, setCandidateCreationVisible] = useState(false);



    const onClickSuggestion = (user) => {

        user = _.omitBy(user || {}, _.isNil);
        if (!candidates.some(u => u.uid === user.uid))
            setCandidates((prev) => [...prev, user])
    };

    const onClickHandler = () => {
        setStepData([...candidates, ...contacts]);
    };

  
     
    useEffect(() => {
        if (stepData) {

            const stepContacts = stepData.filter(c => c.contact_id);
            const stepCandidates = stepData.filter(c => !c.contact_id);
              
            setCandidates(stepCandidates);
            setContacts(stepContacts);
        }
       
    }, [stepData]);

    const removeFromCandidates = (uid) => {
        const filteredCandidates = candidates.filter(c => uid !== c.uid)
        setCandidates(filteredCandidates)
    };

    const removeFromContacts = (uuid) => {
        const filteredContacts = contacts.filter(c => uuid !== c.contact_id)
        setContacts(filteredContacts)
    };
    

    const createContact = ({ email, first_name, last_name, phone, contact_id, work_info }) => {
        const contact = {
            email,
            first_name,
            last_name,
            phone,
            contact_id,
            uid: contact_id,
            company_name: work_info && work_info.company,
            locationAddress: work_info && work_info.address && work_info.address && work_info.address.city ? work_info.address.city : '',
            subscription: {
                plan: ""
            },
        }
        setContacts((prev) => [...prev, contact])
    };

  

    const count = candidates.length + contacts.length;
    const info = t("pastReferralCandidatesTooltipInfo") 
   

     const CreateContactButton = () =>
        <WhiteButton onClick={() => setCandidateCreationVisible(!candidateCreationVisible)}>
            <Icon
                icon="profile"
                width={12}
                height={12}
                color={theme.newBlue}
                style={{ marginRight: theme.spacing[2] }}
            />
            Create Contact
        </WhiteButton>


    return (
        <StepWrapper>
            <Container>
                <Wrapper>
                    <MainText>Who did you exchange this referral with?</MainText>
                    <InfoIconContainer>
                        <Icon
                            icon="info"
                            color="#057AFF"
                            size="md"
                            data-tip
                            data-for={'candidates-info'}
                        />
                    </InfoIconContainer>
                </Wrapper>
                <SearchBoxContainer>
                        {(contacts.length ===  0 && candidates.length === 0) ?
                        <PeopleSearchBar 
                        onClickSuggestion={onClickSuggestion}
                        includeContacts 
                        nestUnderSource 
                        clearSelection
                        /> 

                        : <DummySearch />
                    
                    
                    }
                    </SearchBoxContainer>
                <SuggestionsContainer>
                    {
                        candidates.map(u => (
                            <Suggestion
                                key={u.uid}
                                user={u}
                                deleteAction={removeFromCandidates}
                                professions={professions}
                            />
                        ))
                    }
                    {
                        contacts.map(c => (
                            <Contact
                            key={c.contact_id}
                            email={c.email}
                            firstName={c.first_name}
                            lastName={c.last_name}
                            
                            deleteAction={() => removeFromContacts(c.contact_id)}
                            />
                        ))
                    }
                </SuggestionsContainer>
                {(contacts.length ===  0 && candidates.length === 0)  && <CreateContactButton /> }
                <ButtonContainer>
                    <Button
                        onClick={onClickHandler}
                        disabled={!(count >= 1 && count <= 5)}
                    >
                        Next
                    </Button>
                </ButtonContainer>
            </Container>
            <ReactTooltip
                id={'candidates-info'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
                clickable
            >
                <div dangerouslySetInnerHTML={{ __html: info }}/>
            </ReactTooltip>
            <CreateContactModal
                open={candidateCreationVisible}
                onClose={() => setCandidateCreationVisible(false)}
                onContactCreated={createContact}
                subtitle={subtitleLabel['PASTREFERRAL']}


            />
        </StepWrapper>
    )
}

export default withTheme(CandidatesSelection);