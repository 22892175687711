import React from 'react';
import styled from 'styled-components';

export const InputContainer = styled.div`
    display: flex;
    flex-direction: ${({ showLabelOnValue }) =>
        showLabelOnValue ? "column-reverse" : "column"};
    flex: 1;

    & + & {
        margin-left: 1em;
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
        width: 100%;
        //margin-bottom: 1em;
        & + & {
            margin-left: 0;
        }
    }

    ${({ showLabelOnValue }) =>
        showLabelOnValue &&
        `
        input:placeholder-shown + label, textarea:placeholder-shown + label, input[aria-describedby="react-select-2-placeholder"] + label {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        input:not(:placeholder-shown) + label, textarea:not(:placeholder-shown) + label,  input[aria-describedby=""] + label {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }

    `}

    ${({ triggerShowLabel }) =>
        triggerShowLabel && triggerShowLabel === "hide"
            ? `
        label {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }`
            : `
        label {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
        
    `}
`;

export const HorizontalInputContainer = styled(InputContainer)`
    flex-direction: ${({theme }) => theme.mobileDevice ? "column" : "row"};
    align-items: ${({theme }) => theme.mobileDevice ? "flex-start" : "center"};
    justify-content: space-between;

    :first-child {
        margin-bottom: 1rem;
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
        margin-bottom: 0em;
        & + & {
            margin-left: 0;
        }
    }
`;

export const InputLabel = styled.label`
    font-size: 16px;
    color: #929292;
    font-weight: 500;
    line-height: 24px;
`;

export const Input = styled.input`
    height: 48px;
    border: 1px solid ${(props) => props.error ? '#DC3545' : '#929292' };
    border-radius: 4px;
    font-size: 16px;
    padding: 0 0 0 16px;

    &:focus {
        border: 1px solid ${(props) => props.error ? '#DC3545' : '#057AFF' };
        outline: none;
        box-shadow: ${(props) => props.error ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : '0 0 0 0.25rem rgb(13 110 253 / 25%)'};
    }
`;

export const TextArea = styled.textarea`
    height: ${({ expandDescription }) => expandDescription ? '200px' : '48px'};
    border: 1px solid ${(props) => props.error ? '#DC3545' : '#929292' };
    border-radius: 4px;
    font-size: 16px;
    padding: ${({ theme}) => theme.mobileDevice ? '14px 16px 120px 16px' : '14px 16px 42px 16px'};
    resize: none;
   // width: ${({ theme}) => theme.mobileDevice ? '100%' : '630px'};
  //  height: ${({ theme}) => theme.mobileDevice ? '48px' : '48px'};
   // max-width: 630px;
 
    ${(props) => props.isProfession && `
     caret-color: transparent;
      ::placeholder {
        font-size: 14px;
        font-weight: 800;
        color: #00A15F;
    }
    `};
   ${(props) => props.signalError && 'box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);'};
    //box-shadow: ${(props) => props.signalError ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : '0 0 0 0.25rem rgb(13 110 253 / 25%)'};
    &:focus {
        border: 1px solid ${(props) => props.error ? '#057AFF' : '#057AFF' };
        outline: none;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
       // box-shadow: ${(props) => props.error ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : '0 0 0 0.25rem rgb(13 110 253 / 25%)'};
    }
   ${({ expandDescription}) => expandDescription && 'overflow: hidden;'}
`;

export const FormContent = styled.div`
    padding-right: ${({ theme }) => theme.mobileDevice ? "0" : "5em"};
`;

export const FormContentRow = styled.div`
    display: flex;
    flex-direction: ${({ theme, rowOnly }) => theme.mobileDevice && !rowOnly ? "column" : "row"};
    align-items: flex-start;
    justify-content: space-between;

    & + & {
        margin-top: 1em;
    }
`;

export const InfoMessage = styled.div`
    line-height: 24px;
    font-size: 14px;
    color: #E56767;
`;

export const ButtonContainer = styled(FormContentRow)`
    justify-content: flex-end;
    flex-direction: ${({ theme }) => theme.mobileDevice && "row"};
`;

export const Button = styled.button`
    height: 40px;
    width: 94px;
    background: #00A15F;
    color: white;
    padding: 8px 22px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    margin: 0 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: #008750;
    }

    &:active {
        background: #43705E;
    }

    &:disabled {
        background: #257A57;
        cursor: not-allowed;
    }
`;

export const SecondaryButton = styled(Button)`
    background: transparent;
    border: 2px solid #00A15F;
    color: #00A15F;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: transparent;
        color: #00A15F;
        border: 2px solid #008750;
    }

    &:active {
        background: transparent;
        color: white;
        border: 2px solid #43705E;
    }
`;

export const SecondarySide = styled.div`
    display: flex;
    flex-basis: 30%;
    justify-content: flex-end;
    min-height: 38px;
`;

export const MainSide = styled.div`
    display: flex;
    flex-basis: 70%;
    justify-content: ${({ theme }) => theme.mobileDevice ? "space-between" : null};
    width: ${({ theme }) => theme.mobileDevice ? "100%" : null};
`;

export const Wrapper = styled.div`
    margin-top: 2em;
`;

export const HorizontalLabel = styled(InputLabel)`
    display: flex;
    flex-basis: 18%;
    margin-bottom: ${({ theme }) => theme.mobileDevice ? theme.spacing[2] : 0};
`;

export const HorizontalInput = styled(Input)`
    flex: 1;
    padding: ${({ theme }) => (theme.mobileDevice ? "16px" : "0 0 0 16px")};
    width: ${({ theme }) => (theme.mobileDevice ? "84%" : null)};
`;

const PrefixInput = styled(HorizontalInput)`
    border: none;
    outline: none;
    padding: 0;

    &:focus {
        border: none;
        outline: none;
    }
`;

const PrefixLabel = styled.div`
    color: #929292;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
`;

const PrefixInputContainer = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    border: 1px solid #929292;
    border-radius: 4px;
    font-size: 16px;
    padding: 0 0 0 16px;
    flex-grow: 1;

    &:focus-within {
        border: 2px solid #057AFF;
    }
`;

export const InputWithPrefix = ({
    type,
    name,
    value,
    onChange,
    prefix
}) => {
    return (
        <PrefixInputContainer>
            <PrefixLabel>{ prefix }</PrefixLabel>
            <PrefixInput
                type={type}
                name={name}
                value={value}
                onChange={onChange}
            />
        </PrefixInputContainer>
    );
};