import styled from "styled-components";

export const AttentionPopStyled = styled.div`
    max-height: 336px;
    background: #ffffff;
    border-radius: 8px;
     padding: 40px;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    
`;

export const AttentionText = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #333333;
`;

export const ButtonContainer = styled.div`
    width: 214px;
    min-height: 32px;
    display: flex;
    gap: 24px;
`;

export const Button = styled.button`
    width: 95px;
    height: 32px;
    background-color: ${props => (props.continue ? "#00A15F" : "white")};
    color: ${props => (props.continue ? "white" : "#929292")};
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    ${props => (props.close ? "border: 1px solid #929292;" : "border: none;")}
`;
