import styled from "styled-components";

export const CardContainer = styled.div`
    /* width: ${props => props.padding ? `calc(100% - ${props.padding} * 2 )` : "calc(100% - 60px)"};  */
    padding: ${props => (props.padding ? props.padding : "30px")};
    border-radius: 4px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    background-color: ${props => (props.bg ? props.bg : "white")};
    pointer-events: ${props => (props.disabled ? "none" : "auto")};
    opacity: ${props => (props.disabled ? ".4" : "1")};
    box-sizing: border-box;
    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
        /* width: ${props => props.padding ? `calc(100% - ${props.padding} * 2 )` : "calc(100% - 40px)"};  */
        padding: ${props => (props.padding ? props.padding : "20px")};
    }
`;

export const EditIconContainer = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @media (max-width: ${props => `${props.theme.mediaBreakpoints.tablet}px`}) {
        right: 20px;
        top: 20px;
    }
`;

export const ActionsButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
`;

export const ActionButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 180px;
    height: 50px;
`;
