import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    MainText,
    StepWrapper,
    Button,
    ButtonContainer
} from "../../../components";
import Card from "../../../../../atoms/shared/Card2";
import { Box, Flex } from "rebass";
import { Title } from "../../../../../atoms/shared/Text";
import Avatar from "../../../../../atoms/shared/AvatarCircle2";
import { Icon } from "../../../../../atoms/shared/Icon";
import {
    DataTitle,
    DataValue,
    Desc,
    DataPlan
} from "../../../../ReferralInformation/GeneralInfo/styles";
import { Status } from "../../../../ReferralInformation/referralInformation-styles";
import AllTermsModal from "../../../../ReferralInformation/TermsModal";
import Link from "../../../../../atoms/Link";
import moment from "moment";
import { withTheme } from "styled-components";
import {
    CardText,
    CardTitle,
    CardDetail,
    AvatarContainer
} from "../../../../ReferralInformation/Overview/SendingParty/referralCard-styles";
import {  ErrorMessage, SuccesReferralSent } from '../../Preview/SuccessReferral_'
import { PopupLoader } from "../../../../../atoms/shared/PopupLoader";
import Spinner from "../../../../../../../../assets/svg/Spinner";
import { DraftModal, useDraft } from "../../../useDraft";


const Container = styled(StepWrapper)`
    display: flex;
    flex-direction: column;
    margin: 2em 0 0 0;
`;

const Spacer = styled.div`
    margin: 1em 0;
`;

const SpacerRow = styled(Spacer)`
    display: flex;
    flex-direction: ${({ theme }) => (theme.mobileDevice ? "column" : "row")};
`;

const FooterContainer = styled(ButtonContainer)`
    justify-content: space-between;
    margin-bottom: 2em;
`;

const Subtitle = styled(Title)`
    font-size: 14px;
`;

const BoxWithBorder = styled.div`
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 1em;
    width: 100%;
    padding-bottom: 1em;
`;

const OpportunityPreview = ({
    stepData,
    theme,
    user,
    feeDueOptions,
    feeTypesOptions,
    feeFrequencyOptions,
    feeDurationOptions,
    feeCommencementOptions,
    sendPastferral,
    referralIsLoading,
    successReferral,
    errorReferral,
    candidates

}) => {
    const {
        avatar,
        name,
        lastname,
        address: userAddress,
        company_info: { name: companyName }
    } = user;

    const [modalOpen, setModalOpen] = useState(false);
    const {
        profession,
        locationIsRemote,
        cannotFindProfession,
        professionDescription,
        address,
        description,
        clientType,
        propertySubType,
        propertyType,
        maxPrice,
        minBeds,
        minSquareFeet,
        minLotSize,
        financingInfo,
        homeSaleContingency,
        minUnits,
        maxSquareFeet,
        maxUnits,
        credit,
        pets,
        estimatedPrice,
        beds,
        baths,
        squareFeet,
        units,
        retailSpace,
        acres,
        clientSource,
        currentSatus,
        lastContact,
        referralFee,
        agreement,
        feeFrequency,
        feeDuration,
        paymentDue,
        comments,
        feeCommencement,
        flatFeeAmount,
        grossRevenueFee,
        realEstateFee,

        searchUserMode,
        clientFirstName,
        clientLastName,
        clientEmail,
        clientMobileNumber,
        clientStreetAddress,
        clientCity,
        clientState,
        clientZipCode,
        clientInformation
    } = stepData;

    const {
        saveDraft,
        loading,
        showModal,
        setShowModal,
        modalMessage
    } = useDraft();

    const renderRealStateDetails = profession && profession.value === "100";
    const [allTermsModalOpen, setAllTermsOpen] = useState(false);
    const statusTo = (stepData.pastReferralStatus && stepData.pastReferralStatus.label) || ""
    const toogleAllTermsOpen = () => setAllTermsOpen(!allTermsModalOpen);

    const feeAmount = {
        [feeTypesOptions.optionsKeys.FLAT]: flatFeeAmount,
        [feeTypesOptions.optionsKeys.GROSS_REVENUE]: grossRevenueFee,
        [feeTypesOptions.optionsKeys.NO_FEE]: null,
        [feeTypesOptions.optionsKeys.OTHER]: null,
        [feeTypesOptions.optionsKeys.REAL_ESTATE]: realEstateFee
    };

    const fee = {
        type: referralFee.value,
        fee: feeAmount[referralFee.value],
        frequency: feeFrequency ? feeFrequency.value : "",
        duration: feeDuration ? feeDuration.value : "",
        due: paymentDue ? paymentDue.value : "",
        comments: comments,
        commencement: feeCommencement ? feeCommencement.value : ""
    };

    const clientInformationProvided = searchUserMode
        ? clientInformation !== null
        : clientFirstName !== "" && clientLastName !== "";
    const openTo = (candidates.length === 1) ? 'Direct' : (candidates.length > 1) ? "FC-FS" : 'Open';

   
    useEffect(() => {
        setModalOpen(successReferral);
    }, [successReferral]);



    return (
        <Container>
            <DraftModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalMessage={modalMessage}
            />
            <MainText>Please review your referral information</MainText>
            <Spacer>
                <Card
                    borderRadius={"8px 8px 0px 0px"}
                    status="ASSIGNED"
                    padding="30px"
                >
                    <Title>General Information</Title>
                    <Box mt={["1.5em"]}>
                        <Flex
                            alignItems="flex-start"
                            flexDirection={["column", "column", "row", "row"]}
                            justifyContent={
                                renderRealStateDetails
                                    ? "space-between"
                                    : "flex-start"
                            }
                            flexWrap="wrap"
                        >
                            <Box mr={["1.5em"]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Method:</DataTitle>
                                    <DataValue>Referral</DataValue>
                                </Flex>
                                {profession && profession.label && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Looking For:</DataTitle>
                                        <DataValue>
                                            {profession && profession.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {!profession && cannotFindProfession && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Looking For:</DataTitle>
                                        <DataValue>
                                            {professionDescription}
                                        </DataValue>
                                    </Flex>
                                )}
                                {address && address.description && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Location:</DataTitle>
                                        <DataValue>
                                            {address && address.description}
                                        </DataValue>
                                    </Flex>
                                )}
                                {locationIsRemote &&
                                    locationIsRemote.value === "REMOTE" && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>Location:</DataTitle>
                                            <DataValue>Remote</DataValue>
                                        </Flex>
                                    )}
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Created:</DataTitle>
                                    <DataValue>{`${moment(new Date()).format(
                                        "MMMM Do YYYY"
                                    )}`}</DataValue>
                                </Flex>
                                {clientType && clientType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Client Type:</DataTitle>
                                        <DataValue>
                                            {clientType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {propertyType && propertyType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Property Type:</DataTitle>
                                        <DataValue>
                                            {propertyType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {propertySubType && propertySubType.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>
                                            Property Sub-Type:
                                        </DataTitle>
                                        <DataValue>
                                            {propertySubType.label}
                                        </DataValue>
                                    </Flex>
                                )}
                            </Box>
                            <Box mr={["1.5em"]}>
                                {maxPrice && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Price:</DataTitle>
                                        <DataValue>
                                            Up to {maxPrice}{" "}
                                            {clientType &&
                                            clientType.value === "TENANT" &&
                                            propertyType &&
                                            propertyType.value === "COMMERCIAL"
                                                ? `per ${retailSpace.label}`
                                                : ""}
                                        </DataValue>
                                    </Flex>
                                )}
                                {minBeds && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min Beds:</DataTitle>
                                        <DataValue>{minBeds}</DataValue>
                                    </Flex>
                                )}
                                {estimatedPrice && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Estimated Price:</DataTitle>
                                        <DataValue>
                                            {estimatedPrice}{" "}
                                            {clientType &&
                                            clientType.value === "LANDLORD" &&
                                            propertyType &&
                                            propertyType.value === "COMMERCIAL"
                                                ? `per ${retailSpace.label}`
                                                : ""}
                                        </DataValue>
                                    </Flex>
                                )}
                                {financingInfo && financingInfo.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Financing Info:</DataTitle>
                                        <DataValue>
                                            {financingInfo.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {homeSaleContingency &&
                                    homeSaleContingency.value && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>
                                                Home Sale Contingency?:
                                            </DataTitle>
                                            <DataValue>
                                                {homeSaleContingency.label}
                                            </DataValue>
                                        </Flex>
                                    )}
                                {credit && credit.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Credit:</DataTitle>
                                        <DataValue>{credit.label}</DataValue>
                                    </Flex>
                                )}
                                {pets && pets.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Pets:</DataTitle>
                                        <DataValue>{pets.label}</DataValue>
                                    </Flex>
                                )}
                                {minLotSize && minLotSize.value && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min Lot Size:</DataTitle>
                                        <DataValue>
                                            {minLotSize.label}
                                        </DataValue>
                                    </Flex>
                                )}
                                {maxUnits && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units Max:</DataTitle>
                                        <DataValue>{maxUnits}</DataValue>
                                    </Flex>
                                )}
                                {units && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units:</DataTitle>
                                        <DataValue>{units}</DataValue>
                                    </Flex>
                                )}
                                {minUnits &&
                                    typeof minUnits === "object" &&
                                    minUnits.value && (
                                        <Flex width={[1]} mb={[".5em"]}>
                                            <DataTitle>Units Min:</DataTitle>
                                            <DataValue>
                                                {minUnits.value}
                                            </DataValue>
                                        </Flex>
                                    )}
                                {minUnits && typeof minUnits !== "object" && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Units Min:</DataTitle>
                                        <DataValue>{minUnits}</DataValue>
                                    </Flex>
                                )}
                                {minSquareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Min SqFt:</DataTitle>
                                        <DataValue>{minSquareFeet}</DataValue>
                                    </Flex>
                                )}
                                {maxSquareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Max SqFt:</DataTitle>
                                        <DataValue>{maxSquareFeet}</DataValue>
                                    </Flex>
                                )}
                                {beds && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Beds:</DataTitle>
                                        <DataValue>{beds}</DataValue>
                                    </Flex>
                                )}
                                {baths && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Baths:</DataTitle>
                                        <DataValue>{baths}</DataValue>
                                    </Flex>
                                )}
                                {squareFeet && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>SqFt:</DataTitle>
                                        <DataValue>{squareFeet}</DataValue>
                                    </Flex>
                                )}
                                {acres && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Acres:</DataTitle>
                                        <DataValue>{acres}</DataValue>
                                    </Flex>
                                )}
                            </Box>
                            <Box>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Code:</DataTitle>
                                    <DataValue>To be defined</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Open To:</DataTitle>
                                    <DataValue>{ openTo }</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Status:</DataTitle>
                                    <Status statusColor={"ASSIGNED"}>
                                        { statusTo }
                                    </Status>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt={["1.5em"]}>
                        <Flex width={[1]} mb={[".5em"]}>
                            <DataValue>{description}</DataValue>
                        </Flex>
                    </Box>
                </Card>
            </Spacer>
            <SpacerRow>
                <Box width={[1, 1, 0.33, 0.33]} mr={["1.5em"]}>
                    <Card padding={theme.spacing[4]} style={{ height: "auto" }}>
                        <Title>Sending Party</Title>
                        <Flex
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            width={[1]}
                            mt={["1em"]}
                        >
                            <AvatarContainer>
                                <Avatar src={avatar} size={"77px"} />
                            </AvatarContainer>
                            <CardDetail>
                                <CardTitle>
                                    {name} {lastname}
                                </CardTitle>
                                <Box width={[1]} mt={[".5em"]}>
                                    <Flex
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Icon
                                            icon="location"
                                            width="20px"
                                            height="20px"
                                            color={theme.blueMedium}
                                            style={{ minWidth: 20 }}
                                        />
                                        <CardText
                                            style={{ paddingLeft: ".4em" }}
                                        >
                                            {userAddress}
                                        </CardText>
                                    </Flex>
                                </Box>
                                {companyName && (
                                    <Flex
                                        width={[1]}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        mt={[".5em"]}
                                    >
                                        <Icon
                                            icon="building"
                                            width="20px"
                                            height="20px"
                                            color={theme.blueMedium}
                                            style={{ minWidth: 20 }}
                                        />
                                        <CardText
                                            style={{ paddingLeft: ".4em" }}
                                        >
                                            {companyName}
                                        </CardText>
                                    </Flex>
                                )}
                            </CardDetail>
                        </Flex>
                    </Card>
                </Box>
                <Box width={[1, 1, 0.33, 0.33]} mr={["1.5em"]}>
                    <Card padding={theme.spacing[4]} style={{ height: "auto" }}>
                        <Title>Referral Terms</Title>
                        <Flex
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            width={[1]}
                            mt={["1em"]}
                            flexDirection={["column"]}
                        >
                            <div>Fee: {referralFee.label}</div>
                        </Flex>
                        {agreement && agreement.value && (
                            <>
                                <BoxWithBorder />
                                <Flex mt={theme.spacing[6]} alignItems="center">
                                    <Icon
                                        icon="document"
                                        width={10}
                                        height={12}
                                        color={theme.blueMedium}
                                        style={{
                                            marginRight: theme.spacing[2]
                                        }}
                                    />
                                    <Link
                                        onClick={toogleAllTermsOpen}
                                        fontSize="XXS"
                                        color={theme.newBlue}
                                        underlineColor={theme.newBlue}
                                    >
                                        Click here to review your terms
                                    </Link>
                                </Flex>
                            </>
                        )}
                    </Card>
                </Box>
                <Box width={[1, 1, 0.33, 0.33]}>
                    <Card padding={theme.spacing[4]} style={{ height: "auto" }}>
                        <Title>Client Information</Title>
                        <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            width={[1]}
                            mt={["1em"]}
                        >
                            {clientInformationProvided && (
                                <>
                                    <Icon
                                        icon="lock"
                                        width={18}
                                        height={22}
                                        color="#057AFF"
                                    />
                                    <div>&nbsp; Released upon acceptance</div>
                                </>
                            )}
                            {!clientInformationProvided && (
                                <>
                                    No client information has been provided.{" "}
                                    {name} {lastname} will provide contact
                                    information after this referral has been
                                    awarded.
                                </>
                            )}
                        </Flex>
                        <BoxWithBorder />
                        <Title>Timing information</Title>
                        <Flex flexDirection={"column"} width={[1]} mt={["1em"]}>
                            <div>Client Source: {clientSource.label} </div>
                            <div>&nbsp;</div>
                            {currentSatus && (
                                <div>Current Status: {currentSatus.label} </div>
                            )}
                            <div>&nbsp;</div>
                            <div>
                                Last day of contact:{" "}
                                {moment(lastContact).format("MM/D/YYYY")}
                            </div>
                        </Flex>
                    </Card>
                </Box>
            </SpacerRow>
            <FooterContainer>
                <DataValue>
                    By clicking submit, you agree to our{" "}
                    <a
                        href="https://site.nuop.com/end-user-license-agreement/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        End User License Agreement
                    </a>
                    , including but not limited to, the "Opportunities" section.
                </DataValue>
                <Box mr={theme.mobileDevice ? 0 : 16}>
                    <Button
                        width="120px"
                        onClick={saveDraft}
                        disabled={loading}
                    >
                        Save Draft
                    </Button>
                </Box>
                {referralIsLoading ?
                  <Button width="94px">
                      <Spinner color={"white"} width="14px" height="14px" />
                  </Button>
                   :
                   <Button  width="94px" onClick={() => sendPastferral()}>
                       Submit
                   </Button>
                }
            </FooterContainer>
            {modalOpen && (
                <SuccesReferralSent
                    callback={() => {}}
                    setOpenAttentionModel={setModalOpen}
                />
            )}
            {referralIsLoading && <PopupLoader />   }
            {errorReferral && <ErrorMessage />}
            {allTermsModalOpen && (
                <AllTermsModal
                    feeFrequencyOptions={feeFrequencyOptions}
                    feeDurationOptions={feeDurationOptions}
                    feeCommencementOptions={feeCommencementOptions}
                    feeDueOptions={feeDueOptions ? feeDueOptions : {}}
                    feeTypesOptions={feeTypesOptions}
                    fee={fee ? fee : {}}
                    onModalClose={toogleAllTermsOpen}
                />
            )}
        </Container>
    );
};

export default withTheme(OpportunityPreview);
