//TODO: refactor tab to simplify render: compare selected Key
import React, { useState } from "react";
import {
    Navbar,
    NavbarItem,
    NavbarText,
    NavbarDropdownContainer,
    NavbarDropdownText
} from "./topNavbar-styles";
import CreateDropdown from "./NavbarDropdown"
import UserBusiness from "../../../../../../business/user";
import { withTheme } from "styled-components";
import Tooltip from "../Tooltip";
import { Icon } from "../Icon";
export default withTheme(props => {
    const [createDropdownOpen, setCreateDropdownOpen] = useState(false);

    const toggleCreateDropdown = () => setCreateDropdownOpen(!createDropdownOpen);

    const handleOnClick = (item, disabled) => disabled ? null : props.onClick(item);

    return (
        <Navbar width={props.width} height={props.height}>
            {props.items.map((item, key) => {
                const disabledItem =
                    ["REFERRALS"].includes(item.text.toUpperCase()) &&
                    !UserBusiness.userTypeValidForSendReferrals(
                        props.userType
                    ) &&
                    !UserBusiness.userTypeValidForReceiveReferrals(
                        props.userType
                    );
                return (
                    <NavbarItem
                        key={`${key}_topNavbar`}
                        onClick={() => handleOnClick(item, disabledItem)}
                        selected={item.selected}
                    >
                        <Tooltip
                            stylesTooltip={{ width: "150%" }}
                            text={item.tooltip}
                        >
                            <NavbarText
                                selected={item.selected}
                                fontSize={props.fontSize}
                                disabled={disabledItem}
                            >
                                {item.text}
                            </NavbarText>
                        </Tooltip>
                    </NavbarItem>
                );
            })}
            <NavbarDropdownContainer>
                {/* <NotificationIcon
                    onMouseDown={this.onClickNotification}
                    color={this.props.theme.darkGray}
                    enabled={turnOnBell}
                /> */}
                <Tooltip
                    noShow={createDropdownOpen}
                    stylesTooltip={{ width: "100%" }}
                    text={"Create an op, invite, or post"}
                >
                    <NavbarItem onMouseDown={toggleCreateDropdown} className="navbar-create">
                        <Icon
                            icon="add"
                            color={
                                createDropdownOpen
                                    ? props.theme.newBlue
                                    : props.theme.greenMedium
                            }
                        />
                        <NavbarDropdownText
                            style={{ transition: "all .3s ease" }}
                            selected={createDropdownOpen}
                            fontSize={props.fontSize}
                        >
                            Create
                        </NavbarDropdownText>
                    </NavbarItem>
                </Tooltip>
                {createDropdownOpen && (
                    <CreateDropdown
                        items={props.createOptions}
                        handleClickOutside={toggleCreateDropdown}
                        isOpen={createDropdownOpen}
                        onClick={(item, disabled) => (
                            handleOnClick(item, disabled),
                            toggleCreateDropdown()
                        )}
                        userTypeValidForSendReferrals={
                            props.userTypeValidForSendReferrals
                        }
                        userTypeValidForWriteArticles={
                            props.userTypeValidForWriteArticles
                        }
                    />
                )}
            </NavbarDropdownContainer>
            {props.withPricing && (
                <NavbarItem
                    onClick={() => {
                        window.open("https://nuop.com/pricing/", "_blank");
                    }}
                    selected={false}
                >
                    <NavbarText selected={false} fontSize={props.fontSize}>
                        {"Pricing"}
                    </NavbarText>
                </NavbarItem>
            )}
        </Navbar>
    );
});
