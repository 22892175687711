import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//import LayoutBusiness from "../../../../../business/global";
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import { withTheme } from "styled-components";
import { MenuItemContainer, MenuItemText } from "./styles";
import HorizontalLine from "../../../../ui/presentation/atoms/shared/HorizontalLine";
import CloudSpongeWidget from "../../../../ui/presentation/atoms/shared/CloudSpongeWidget";
//import Utils from "../../../../../libs/Utils";

const ProfileMenu = ({
    history,
    theme,
    toogleAvatarDropdown,
    toogleMobileSideMenu,
    user
}) => {
    const hoveredInitial = {
        opportunity: false,
        subscription: false,
        user: false,
        settings: false,
        inviteAndEarn: false,
        help: false,
        mail: false,
        addContact: false
    };
    const [hovered, setHovered] = useState(hoveredInitial);
    const iconWidth = "14px";
    const iconHeight = "14px";
    const mobileDevice = theme.mobileDevice;

    const onMouseEnter = icon =>
        setHovered(prev => ({ ...hoveredInitial, [icon]: true }));
    const onMouseLeave = icon => setHovered(hoveredInitial);

    const onClickProfileMenu = () => {
        if (toogleAvatarDropdown) toogleAvatarDropdown();
        if (toogleMobileSideMenu) toogleMobileSideMenu(false);
    };

    const redirectTo = () => {
        // window.open(url, "_blank");
        history.push(`/profile/${user.userName}`); //
        onClickProfileMenu();
    };

    return (
        <React.Fragment>
            {/* {theme.mobileDevice && (
                <Link
                    to={"/referrals"}
                    style={{ textDecoration: "none" }}
                    onClick={onClickProfileMenu}
                    onMouseEnter={() => onMouseEnter("opportunity")}
                    onMouseLeave={onMouseLeave}
                >
                    <MenuItemContainer>
                        <OpportunityIcon
                            color={
                                hovered.opportunity
                                    ? theme.newBlue
                                    : theme.darkGray
                            }
                            width={iconWidth}
                            height={iconHeight}
                        />
                        <MenuItemText>My Ops</MenuItemText>
                    </MenuItemContainer>
                </Link>
            )} */}
            {/* if (url === "/profile")
            url = `${url}/${this.props.user && this.props.user.userName}` */}
            {theme.mobileDevice && (
                <Link
                    // to={`/${this.props.user && this.props.user.userName}`}
                    style={{ textDecoration: "none" }}
                    onClick={redirectTo}
                    onMouseEnter={() => onMouseEnter("profile")}
                    onMouseLeave={onMouseLeave}
                >
                    <MenuItemContainer>
                        <Icon
                            icon="profile"
                            color={
                                hovered.profile ? theme.newBlue : theme.darkGray
                            }
                            width={iconWidth}
                            height={iconHeight}
                        />
                        <MenuItemText>Profile</MenuItemText>
                    </MenuItemContainer>
                </Link>
            )}
            {theme.mobileDevice && (
                <Link
                    to={"/referrals"}
                    style={{ textDecoration: "none" }}
                    onClick={onClickProfileMenu}
                    onMouseEnter={() => onMouseEnter("referrals")}
                    onMouseLeave={onMouseLeave}
                >
                    <MenuItemContainer>
                        <Icon
                            icon="opportunity"
                            color={
                                hovered.referrals
                                    ? theme.newBlue
                                    : theme.darkGray
                            }
                            width={iconWidth}
                            height={iconHeight}
                        />
                        <MenuItemText>My Ops</MenuItemText>
                    </MenuItemContainer>
                </Link>
            )}
            {!theme.mobileDevice && (
                <MenuItemContainer
                    onClick={redirectTo}
                    onMouseEnter={() => onMouseEnter("user")}
                    onMouseLeave={onMouseLeave}
                >
                    <Icon
                        icon="profile"
                        color={hovered.user ? theme.newBlue : theme.darkGray}
                        width={iconWidth}
                        height={iconHeight}
                    />
                    <MenuItemText>View Profile</MenuItemText>
                </MenuItemContainer>
            )}
            {/*  INVITE_AND_EARN_REMOVAL: As of 6/6/23 Invite & Earn program has been disbled on the platform*/}
            {/* <Link
                to={"/settings?section=invites"}
                style={{ textDecoration: "none" }}
                onClick={onClickProfileMenu}
                onMouseEnter={() => onMouseEnter("inviteAndEarn")}
                onMouseLeave={onMouseLeave}
            >
                <MenuItemContainer>
                    <Icon
                        icon="earn"
                        color={
                            hovered.inviteAndEarn
                                ? theme.newBlue
                                : theme.darkGray
                        }
                        width={"16px"}
                        height={"16px"}
                    />
                    <MenuItemText style={{ marginLeft: ".8rem" }}>
                        Invite & Earn
                    </MenuItemText>
                </MenuItemContainer>
            </Link> */}
            <Link
                style={{ textDecoration: "none" }}
                onClick={onClickProfileMenu}
                onMouseEnter={() => onMouseEnter("addContact")}
                onMouseLeave={onMouseLeave}
            >
                <CloudSpongeWidget>
                    <MenuItemContainer className="cloudsponge-launch">
                        <Icon
                            icon="add-user"
                            color={
                                hovered.addContact
                                    ? theme.newBlue
                                    : theme.darkGray
                            }
                            width={iconWidth}
                            height={iconHeight}
                        />
                        <MenuItemText>Add Contacts</MenuItemText>
                    </MenuItemContainer>
                </CloudSpongeWidget>
            </Link>{" "}
            <Link
                to={"/settings?section=subscription"}
                style={{ textDecoration: "none" }}
                onClick={onClickProfileMenu}
                onMouseEnter={() => onMouseEnter("subscription")}
                onMouseLeave={onMouseLeave}
            >
                <MenuItemContainer>
                    <Icon
                        icon="plan"
                        color={
                            hovered.subscription
                                ? theme.newBlue
                                : theme.darkGray
                        }
                        width={iconWidth}
                        height={iconHeight}
                    />
                    <MenuItemText>My Plan</MenuItemText>
                </MenuItemContainer>
            </Link>{" "}
            <HorizontalLine light style={{ marginBottom: theme.spacing[4] }} />
            <Link
                to={"/settings"}
                style={{ textDecoration: "none" }}
                onClick={onClickProfileMenu}
                onMouseEnter={() => onMouseEnter("settings")}
                onMouseLeave={onMouseLeave}
            >
                <MenuItemContainer>
                    <Icon
                        icon="settings"
                        color={
                            hovered.settings ? theme.newBlue : theme.darkGray
                        }
                        width={iconWidth}
                        height={iconHeight}
                    />
                    <MenuItemText>Settings</MenuItemText>
                </MenuItemContainer>
            </Link>
            <a
                href="https://support.nuop.com/portal/en/home"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                onMouseEnter={() => onMouseEnter("help")}
                onMouseLeave={onMouseLeave}
            >
                <MenuItemContainer>
                    <Icon
                        icon="help"
                        color={hovered.help ? theme.newBlue : theme.darkGray}
                        width={iconWidth}
                        height={iconHeight}
                    />
                    <MenuItemText>Help</MenuItemText>
                </MenuItemContainer>
            </a>
            <a
                href="https://nuop.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                onMouseEnter={() => onMouseEnter("mail")}
                onMouseLeave={onMouseLeave}
            >
                <MenuItemContainer>
                    <Icon
                        icon="mail"
                        color={hovered.mail ? theme.newBlue : theme.darkGray}
                        width={iconWidth}
                        height={iconHeight}
                    />
                    <MenuItemText>Contact Us</MenuItemText>
                </MenuItemContainer>
            </a>
        </React.Fragment>
    );
};

export default withRouter(withTheme(ProfileMenu));
