import {
    USER_STORE_SUCC,
    USER_STORE_FAIL,
    CLEAN_USER,
    REHYDRATE_USER,
    REHYDRATE_USER_IS_LOADING,
    REHYDRATE_FIREBASE_AUTH,
    USER_CONNECT_INVITE_SUCC,
    USER_ACCEPT_INVITE_SUCC,
    USER_UNFRIEND_SUCC,
    EDITPROFILE_UPLOADAVATARSUCCESS,
    IS_SOCIAL_LOGIN_FLAG_ON,
    IS_SOCIAL_LOGIN_FLAG_OFF,
    USER_CONNECT_PENDING_INVITES,
    USER_CONNECT_SENT_INVITES,
    USER_CONTACTS,
    USER_FOCUS_SUCCESS,
    USER_SERVICEAREAS_SUCCESS,
    USER_HONORS_SUCCESS,
    USER_GOOD_OPS_FOR_ME_SUCCESS,
    USER_EXPERIENCE_SUCCESS,
    USER_LICENCES_SUCCESS,
    USER_SOCIAL_SUCCESS,
    USER_COMMUNITY_SUCCESS,
    USER_GET_CONNECTED_ISLOADING,
    USER_GET_CONNECTED,
    SET_VERIFIED_PHONE,
    USER_IPINFO_SUCCESS,
    USER_SETSETTING,
    USER_UNBLOCK,
    USER_FETCH_SUBSCRIPTION_SUCCESS,
    USER_FETCH_CREDITS_SUCCESS,
    USER_FETCH_CREDITS_CHANGED_SUCCESS,
    NEAR_USERS_ISLOADING,
    NEAR_USERS_SUCCESS,
    NEAR_USERS_FAIL,
    NEAR_USERS_BROWSER_ISLOADING,
    NEAR_USERS_BROWSER_SUCCESS,
    NEAR_USERS_BROWSER_FAIL,
    USER_FETCHINVITES_SUCCESS,
    USER_INVITES_DISMISS_SUCCESS,
    USER_INVITES_ACCOUNTBALANCE_SUCCESS,
    USER_INVITES_EARNING_HISTORY_SUCCESS,
    USER_INVITES_INVITESCOUNT_SUCCESS,
    USER_EXTRAINFO_SUCCESS,
    VALIDATE_PHONE,
    VALIDATE_PHONE_CODE,
    VALIDATE_PHONE_FAIL,
    VALIDATE_PHONE_SUCC,
    VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE,
    VALIDATE_PHONE_CODE_FAIL,
    VALIDATE_PHONE_CODE_SUCC,
    USER_BLOCK_SUCCESS,
    USER_SET_ONBOARDING
} from "../../actions/constants";
const initialState = {
    phoneValidation: {
        modalOpen: true,
        loading: false,
        errors: [],
        phone: "",
        success: null,
        phoneAlreadyInUse: false
    },
    blockedCountries: ["China", "India"],
    ipInfo: {},
    isSocialLogin: false,
    isLoading: true,
    isLoggedIn: false,
    rehydrated: {
        profile: false,
        firebase: false
    },
    profile: {},
    subscription: {},
    idToken: null,
    getConnectedUsers: {
        isLoading: false,
        data: []
    },
    nearUsers: {
        loaded: false,
        isLoading: false,
        data: [],
        attempts: 0
    },
    nearUsersBrowser: {
        loaded: false,
        isLoading: false,
        data: [],
        attempts: 0
    },
    invitedPeople: {},
    invitesAccountBalance: {},
    earningHistory: []
};

function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_STORE_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoggedIn = false;
            return _state;
        }
        case REHYDRATE_USER_IS_LOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case USER_STORE_SUCC: {
            const _state = {
                ...state
            };
            _state.profile = Object.assign(
                {},
                state.profile,
                action.payload ? action.payload : {}
            );
            _state.uid = action.payload.uid ? action.payload.uid : _state.uid;
            _state.isLoggedIn = true;
            _state.isLoading = false;
            _state.token = action.payload.token
                ? action.payload.token
                : _state.token;
            return _state;
        }
        case IS_SOCIAL_LOGIN_FLAG_ON: {
            const _state = {
                ...state
            };
            _state.isSocialLogin = true;
            _state.idToken = action.payload.idToken;
            return _state;
        }
        case IS_SOCIAL_LOGIN_FLAG_OFF: {
            const _state = {
                ...state
            };
            _state.isSocialLogin = false;
            _state.idToken = null;
            return _state;
        }
        case USER_IPINFO_SUCCESS: {
            const _state = {
                ...state
            };
            _state.ipInfo = { ...action.payload.ipInfo };
            return _state;
        }
        case REHYDRATE_USER: {
            const _state = {
                ...state
            };
            try {
                _state.token = action.payload.token;

                _state.profile = action.payload.profile
                    ? action.payload.profile
                    : {};
                _state.uid = action.payload.uid;
                _state.rehydrated.profile = true;

                _state.sentReferralsCount = action.payload.sentReferralsCount;
                _state.receivedReferralsCount =
                    action.payload.receivedReferralsCount;
                _state.isLoggedIn = action.payload.isLoggedIn;
                _state.isLoading = false;
                _state.isSocialLogin = action.payload.isSocialLogin;
                return _state;
            } catch (error) {
                _state.rehydrated.profile = true;
                _state.isLoggedIn = false;
                _state.isLoading = false;
            }
            return _state;
        }
        case CLEAN_USER: {
            const _state = {
                ...initialState
            };
            _state.rehydrated.profile = false;
            _state.isLoading = false;
            _state.nearUsers = {
                loaded: false,
                isLoading: false,
                data: [],
                attempts: 0
            };
            _state.nearUsersBrowser = {
                loaded: false,
                isLoading: false,
                data: [],
                attempts: 0
            };
            return _state;
        }
        case USER_CONNECT_INVITE_SUCC: {
            const _state = {
                ...state
            };
            const sent_invites = _state.profile.sent_invites
                ? _state.profile.sent_invites
                : {};
            _state.profile.sent_invites = {
                ...sent_invites,
                [action.uid]: true
            };
            return _state;
        }
        case USER_BLOCK_SUCCESS: {
            const _state = {
                ...state
            };
            const blocked_contacts = _state.profile.blocked_contacts
                ? _state.profile.blocked_contacts
                : {};
            _state.profile.blocked_contacts = {
                ...blocked_contacts
            };
            _state.profile.blocked_contacts[action.payload.uid] = true;
            return _state;
        }
        case USER_CONNECT_PENDING_INVITES: {
            const _state = {
                ...state
            };

            _state.profile.pending_invites = {
                ...action.payload.pending_invites
            };

            return _state;
        }
        case USER_ACCEPT_INVITE_SUCC: {
            const _state = {
                ...state
            };
            const contacts = _state.profile.contacts
                ? _state.profile.contacts
                : {};

            _state.profile.contacts = {
                ...contacts
            };

            if (action.him) {
                _state.profile.contacts[action.him] = true;

                let pending_invites = {
                    ..._state.profile.pending_invites
                };
                delete pending_invites[action.him];

                _state.profile.pending_invites = pending_invites;
            }

            return _state;
        }

        case USER_UNFRIEND_SUCC: {
            const _state = {
                ...state
            };
            const contacts = _state.profile.contacts
                ? { ..._state.profile.contacts }
                : {};
            delete contacts[action.payload.him];
            _state.profile.contacts = { ...contacts };

            return _state;
        }

        case REHYDRATE_FIREBASE_AUTH: {
            const _state = {
                ...state
            };
            try {
                _state.rehydrated.firebase = true;
                _state.isLoggedIn = action.payload
                    ? _state.rehydrated.profile && _state.rehydrated.firebase
                    : false;

                return _state;
            } catch (error) {
                _state.rehydrated.firebase = true;
                _state.isLoggedIn = false;
            }
            return _state;
        }
        case EDITPROFILE_UPLOADAVATARSUCCESS: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.profile = {
                ..._state.profile,
                avatar: action.payload.avatarUrl
            };
            return _state;
        }

        /*  case USER_CONNECT_PENDING_INVITES: {
            const _state = {
                ...state
            };
            const pending_invites = action.payload.pending_invites;

            _state.profile.pending_invites = pending_invites;

            return _state;
        } */

        case USER_CONNECT_SENT_INVITES: {
            const _state = {
                ...state
            };
            const sent_invites = action.payload.sent_invites;

            _state.profile.sent_invites = sent_invites;

            return _state;
        }
        case USER_CONTACTS: {
            const _state = {
                ...state
            };
            const contacts = { ...action.payload.contacts };

            _state.profile.contacts = contacts;

            return _state;
        }

        case USER_FOCUS_SUCCESS: {
            const _state = {
                ...state
            };
            const focus = { ...action.payload.focus };

            _state.profile.focus = focus;

            return _state;
        }
        case USER_SERVICEAREAS_SUCCESS: {
            const _state = {
                ...state
            };
            const areas = { ...action.payload.service_areas };

            _state.profile.service_areas = areas;

            return _state;
        }
        case USER_GOOD_OPS_FOR_ME_SUCCESS: {
            const _state = {
                ...state
            };
            const goodOpsForMe = { ...action.payload.goodOpsForMe };

            _state.profile.goodOpsForMe = goodOpsForMe;

            return _state;
        }
        case USER_EXPERIENCE_SUCCESS: {
            const _state = {
                ...state
            };
            const experience = { ...action.payload.experience };

            _state.profile.experience = experience;

            return _state;
        }
        case USER_HONORS_SUCCESS: {
            const _state = {
                ...state
            };
            const honors = { ...action.payload.honors };

            _state.profile.honors = honors;

            return _state;
        }
        case USER_LICENCES_SUCCESS: {
            const _state = {
                ...state
            };
            const licenses = { ...action.payload.licenses };

            _state.profile.licenses = licenses;
            // _state.profile.license_number = action.payload.license_number;
            // _state.profile.license_state = action.payload.license_state;
            // _state.profile.license_expiration =
            //     action.payload.license_expiration;

            return _state;
        }
        case USER_SOCIAL_SUCCESS: {
            const _state = {
                ...state
            };
            const social = { ...action.payload.social };

            _state.profile.social = social;

            return _state;
        }
        case USER_COMMUNITY_SUCCESS: {
            const _state = {
                ...state
            };
            const community_involvement = { ...action.payload.community_involvement };

            _state.profile.community_involvement = community_involvement;

            return _state;
        }
        case USER_GET_CONNECTED: {
            const _state = {
                ...state
            };
            const getConnected = {
                isLoading: false,
                ...action.payload.getConnected
            };
            _state.getConnectedUsers = getConnected;

            return _state;
        }

        case USER_GET_CONNECTED_ISLOADING: {
            const _state = {
                ...state
            };
            _state.getConnectedUsers.isLoading = action.payload.isLoading;

            return _state;
        }
        case NEAR_USERS_ISLOADING: {
            const _state = {
                ...state
            };
            _state.nearUsers.isLoading = action.payload.isLoading;

            return _state;
        }
        case NEAR_USERS_SUCCESS: {
            const _state = {
                ...state
            };
            const nearUsers = {
                attempts: 0,
                isLoading: false,
                loaded: true,
                ...action.payload.nearUsers
            };
            _state.nearUsers = nearUsers;

            return _state;
        }
        case NEAR_USERS_FAIL: {
            const _state = {
                ...state
            };
            const nearUsers = {
                isLoading: false,
                loaded: false,
                attempts: _state.nearUsers.attempts + 1
            };
            _state.nearUsers = nearUsers;

            return _state;
        }
        case NEAR_USERS_BROWSER_ISLOADING: {
            const _state = {
                ...state
            };
            _state.nearUsersBrowser.isLoading = action.payload.isLoading;

            return _state;
        }
        case NEAR_USERS_BROWSER_SUCCESS: {
            const _state = {
                ...state
            };
            const nearUsersBrowser = {
                attempts: 0,
                isLoading: false,
                loaded: true,
                ...action.payload.nearUsersBrowser
            };
            _state.nearUsersBrowser = nearUsersBrowser;

            return _state;
        }
        case NEAR_USERS_BROWSER_FAIL: {
            const _state = {
                ...state
            };
            const nearUsersBrowser = {
                isLoading: false,
                loaded: false,
                attempts: _state.nearUsersBrowser.attempts + 1
            };
            _state.nearUsersBrowser = nearUsersBrowser;

            return _state;
        }
        case SET_VERIFIED_PHONE: {
            const _state = {
                ...state
            };
            const verified_phone = { ...action.payload };

            _state.profile.verified_phone = verified_phone;

            return _state;
        }
        case USER_SETSETTING: {
            switch (action.payload.section) {
                case "push": {
                    const _state = {
                        ...state
                    };
                    _state.profile.settings = _state.profile.settings
                        ? { ..._state.profile.settings }
                        : {};
                    _state.profile.settings.notifications = _state.profile
                        .settings.notifications
                        ? { ..._state.profile.settings.notifications }
                        : {};
                    _state.profile.settings.notifications.push = _state.profile
                        .settings.notifications.push
                        ? { ..._state.profile.settings.notifications.push }
                        : {};
                    _state.profile.settings.notifications.push = {
                        ..._state.profile.settings.notifications.push,
                        ...action.payload.setting
                    };
                    return _state;
                }
                case "email": {
                    const _state = {
                        ...state
                    };

                    _state.profile.settings = _state.profile.settings
                        ? { ..._state.profile.settings }
                        : {};
                    _state.profile.settings.notifications = _state.profile
                        .settings.notifications
                        ? { ..._state.profile.settings.notifications }
                        : {};

                    _state.profile.settings.notifications.email = {
                        ..._state.profile.settings.notifications.email,
                        ...action.payload.setting
                    };
                    return _state;
                }
                default: {
                    const _state = {
                        ...state
                    };
                    _state.profile.settings = _state.profile.settings
                        ? { ..._state.profile.settings }
                        : {};
                    _state.profile.settings[action.payload.section] = {
                        ..._state.profile.settings[action.payload.section],
                        ...action.payload.setting
                    };
                    return _state;
                }
            }
        }
        case USER_UNBLOCK: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            let uidKey = action.payload.uid;
            let {
                [uidKey]: toUnblockUser,
                ...blockedUsersWithoutTheUnblockedOne
            } = _state.profile.blocked_users;
            _state.profile.blocked_users = blockedUsersWithoutTheUnblockedOne;
            return _state;
        }
        case USER_FETCH_SUBSCRIPTION_SUCCESS: {
            const _state = {
                ...state
            };
            _state.subscription = { ...action.payload.subscription };
            return _state;
        }

        case USER_FETCH_CREDITS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.credits = { ...action.payload.credits };
            return _state;
        }
        case USER_FETCH_CREDITS_CHANGED_SUCCESS: {
            const _state = {
                ...state
            };
            _state.credits = { ..._state.credits, ...action.payload.credits };
            return _state;
        }
        case USER_FETCHINVITES_SUCCESS: {
            const _state = {
                ...state
            };
            _state.invitedPeople = {
                ..._state.invitedPeople,
                ...action.payload.invitedPeople
            };
            return _state;
        }
        case USER_INVITES_DISMISS_SUCCESS: {
            const _state = {
                ...state
            };

            _state.invitedPeople = { ..._state.invitedPeople };
            _state.invitedPeople.accepted = _state.invitedPeople.accepted.filter(
                uid => uid !== action.payload.uid
            );
            _state.invitedPeople.subscribed = _state.invitedPeople.subscribed.filter(
                uid => uid !== action.payload.uid
            );
            return _state;
        }
        case USER_INVITES_ACCOUNTBALANCE_SUCCESS: {
            const _state = {
                ...state
            };

            _state.invitesAccountBalance = {
                ...action.payload.invitesAccountBalance
            };
            return _state;
        }
        case USER_INVITES_EARNING_HISTORY_SUCCESS: {
            const _state = {
                ...state
            };

            _state.earningHistory = [...action.payload.earningHistory];
            return _state;
        }
        case USER_INVITES_INVITESCOUNT_SUCCESS: {
            const _state = {
                ...state
            };

            _state.invitedPeople = { ..._state.invitedPeople };
            _state.invitedPeople.totalInvites = action.payload.totalInvites;

            return _state;
        }
        case USER_EXTRAINFO_SUCCESS: {
            const _state = {
                ...state,
                ...action.payload
            };

            return _state;
        }
        case USER_SET_ONBOARDING: {
            const _state = {
                ...state
            };
            _state.profile = {
                ..._state.profile,
                onboarding: {
                    ..._state.profile.onboarding,
                    ...action.payload.onboarding
                }
            };

            return _state;
        }

        case VALIDATE_PHONE_FAIL: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = false;
            _state.phoneValidation.phoneAlreadyInUse = true;
            _state.phoneValidation.errors = [action.errors];
            return _state;
        }
        case VALIDATE_PHONE_SUCC: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = false;
            _state.phoneValidation.phone = action.payload.phone;
            _state.userProfileCreationCache = action.payload;
            return _state;
        }
        case VALIDATE_PHONE: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = true;
            return _state;
        }
        case VALIDATE_PHONE_CODE_FAIL: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = false;
            _state.phoneValidation.success = false;
            _state.phoneValidation.errors = [action.errors];
            return _state;
        }
        case VALIDATE_PHONE_CODE_SUCC: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = false;
            _state.phoneValidation.success = true;
            _state.phoneValidation.phone = action.code.phone;
            return _state;
        }
        case VALIDATE_PHONE_CODE: {
            const _state = {
                ...state
            };
            _state.phoneValidation.loading = true;
            return _state;
        }
        case VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE: {
            const _state = {
                ...state
            };
            _state.phoneValidation.modalOpen = action.payload.open;
            _state.phoneValidation.success = null;
            return _state;
        }

        default:
            return state;
    }
}

export default userReducer;
