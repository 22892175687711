import React from 'react'
import { Box } from 'rebass';
import { selectStyle } from "../../../../components";
import Select from "react-select";
import { InputContainer, InputLabel } from '../../../../../Settings/common/Components';

const SelectField = ({
    formValues,
    onChange,
    errors,
    fieldSelector,
    label,
    options = [],
    width = 1,
    pl = 0,
    pr = 0,
    mt = 0,
    onChangeSideAction = () => {},
}) => {

    return (
        <Box width={width} pr={pr} pl={pl} mt={mt}>
            <InputContainer
                triggerShowLabel={
                    formValues[fieldSelector] && formValues[fieldSelector].value ? "show" : "hide"
                }
                showLabelOnValue
            >
                <Select
                    options={options}
                    onChange={option => {
                        onChange({
                            target: {
                                name: fieldSelector,
                                value: option,
                            }
                        })
                        onChangeSideAction()
                    }}
                    styles={selectStyle}
                    name={fieldSelector}
                    placeholder={label}
                    value={formValues[fieldSelector]}
                    error={!!errors[fieldSelector]}
                />
                <InputLabel>{label}</InputLabel>
            </InputContainer>
        </Box>
    );
}

export default SelectField;
