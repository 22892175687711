import React, { useState } from "react";
import { Flex } from "rebass";
import PopupBlurModal from "../../../../atoms/shared/PopupBlurModal";
import InvitationSentSuccess from "./InvitationSentSuccess";

const InvitationSentSuccessModal = props => {
    const {
        onClose,
        open,
        invitationValue,
        alreadyRegisteredUser,
    } = props;

    return (
        <PopupBlurModal open={open} onClose={onClose}>
            <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={["2rem"]}
            >
                <InvitationSentSuccess
                    onClose={onClose}
                    invitationValue={invitationValue}
                    alreadyRegisteredUser={alreadyRegisteredUser || {}}
                />
            </Flex>
        </PopupBlurModal>
    );
};



export default InvitationSentSuccessModal;
