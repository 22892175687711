import { nullFormat } from 'numeral';
import ReferralBusiness from '../../../../business/referral';
import { OpportunityStepsOrder, REFERRAL_PRIVACY } from '../../../../components/ui/presentation/views/OpportunityCreation/constants';
import { cleaningObject, convertToTimeStamp  } from '../../../../libs/Utils';
import {
  OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
  OPPORTUNITY_FLOW_RESET,
  SAVE_REFERRAL_DRAFT,
  LOAD_REFERRAL_DRAFT,
  CURRENT_REFERRAL_LOADING,
  CURRENT_REFERRAL_SUCCESS,
  CURRENT_REFERRAL_ERROR,
  NEW_CONTACT_SAVED,
  CONTACT_IS_LOADING,
  CONTACT_ERROR,
  CONTACT_SAVED,
  CANCEL_CONTACT_ERROR
} from '../../constants';
import { realEstateDetailsHandler } from '../../../../business/realEstateDetails';
import { sendCurrentReferral } from './sendCurrentReferral'
import { sendPastferral  } from './sendPastferral'
import { sendRecommendationAction } from './sendRecommendationAction'
import { sendingJobData } from './sendingJobData'
import { sendingPastJobData } from './sendingPastJobData'

const loadDraft = (draft) => {
  // first, get all of keys in draft (they meant to be steps but not all of them)
  const draftKeys = Object.keys(draft);
  // remove darftId, from and lastStep from keys
  const draftId = draft.draftId;
  draftKeys.splice(draftKeys.indexOf('draftId'), 1);
  draftKeys.splice(draftKeys.indexOf('from'), 1);

  // get order of each step and sort them
  const draftKeysOrder = draftKeys.map(key => OpportunityStepsOrder[key]);
  const sortedDraftSteps = draftKeysOrder.sort((a, b) => a - b).map(order => {
    return draftKeys.find(key => OpportunityStepsOrder[key] === order);
  })
  // create a set of sorted draft keys
  const sortedDraftKeys = new Set(sortedDraftSteps);
  // console.log("sortedDraftKeys", sortedDraftKeys, draft)
  // dispatch setStepData for each step in order
  return (dispatch) => {
    dispatch({ type: LOAD_REFERRAL_DRAFT, payload: { draftId } })
    sortedDraftKeys.forEach(key => {
      dispatch(setStepData(key, draft[key]));
    });
  };
}

const setStepData = (step, data) => {
  return {
    type: step,
    payload: {
      data,
    }
  };
};

const saveStepData = (data) => {
  return {
    type: 'SAVE_STEP_DATA',
    payload: {
      data,
    }
  };
};

const setCurrentStep = (step) => {
  return {
    type: OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    payload: {
      step,
    }
  };
};

const resetState = () => {
  return {
    type: OPPORTUNITY_FLOW_RESET,
    payload: {
      
    },
  };
};

const saveReferralDraft = () => {
  return async (dispatch, getState, { firebase }) => {
    try {
      const { uid } = getState().main.user
      const { stepData, draftId } = getState().main.ui.view.opportunityCreationFlow;
      const savedDraftId = await firebase.saveReferralDraft(stepData, uid, draftId)

      dispatch({
        type: SAVE_REFERRAL_DRAFT,
        payload: {
          draftId: savedDraftId,
        },
      })
    } catch (error) {
      console.error("saveReferralDraft error", error);
    }
  };
}

const getMembersAndContactsFromCandidates = (CANDIDATES_SELECTION, mapToIds = false) => {
  if (!CANDIDATES_SELECTION || !CANDIDATES_SELECTION.length) return { members: [], contacts: [] };

  const members = CANDIDATES_SELECTION.reduce(
      (acc, current) => {
          // User information
          const user = current;
          // If the user has a userName, it means it's a member
          if (user.userName) {
              return [...acc, mapToIds ? user.uid : user];
          }
          // If the candidate has a member_uid, it means it's a member that was selected as a contact, so we take their uid
          if (user.member_uid) {
              return [...acc, mapToIds ? user.member_uid : user];
          }
          return acc;
      },
      []
  );
  // Get all the recommended contacts
  let contacts = CANDIDATES_SELECTION.filter(
      candidate => {
          // Contact information
          const contact = candidate;
          // If the contact has a userName, it means it's a member
          const isContact = !contact.userName;
          // If the contact is a member, we check if it's not already in the recommendedMembers array
          const contactIsNotMember = !members.includes(contact.member_uid);
          return isContact && contactIsNotMember;
      }
  )
  if (mapToIds) contacts = contacts.map(c => c.uid || c.contact_id);

  return { members, contacts }
};

const cancelContactError = () => {
  return (dispatch) => {
        dispatch({ type: CANCEL_CONTACT_ERROR })
  }
}

// MARK CANDIDATE SELECTION UI VIEW - CREATE AND SAVE CONTACT


//MARK: SENT NEW CURRENT REFERRAL 




//MARK: SENDING RECOMMENDATION DATA



//MARK: CURRENT JOB FLOW ACTION HANDLER










export {
  setStepData,
  sendingPastJobData,
  setCurrentStep,
  resetState,
  saveStepData,
  saveReferralDraft,
  loadDraft,
  cancelContactError,
  getMembersAndContactsFromCandidates,
  sendCurrentReferral,
  sendRecommendationAction,
  sendingJobData,
  sendPastferral,
}
