import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass"
import ReactTooltip from "react-tooltip";
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import { StepWrapper, Button, ButtonContainer } from "../../../components";
import { Icon } from "../../../../../atoms/shared/Icon";
import Location from "./PastLocation";
//import TargetToNotify from "./TargetToNotify";
import AcceptanceCondition from "../../ReferralCompleteInformation/AcceptanceCondition";
import Description from "../../ReferralCompleteInformation/Description";
import RealStateDetails from "../../ReferralCompleteInformation/RealStateDetails";
import TimingInfo from "../../ReferralCompleteInformation/TimingInfo";
import ReferralFee from "./ReferralFee";
import ClientInformation from "../../ReferralCompleteInformation/ClientInformation";
import Visibility from "../../ReferralCompleteInformation/Visibility";
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup'
import { targetToNotifyOptions, visibilityOptions, retailSpaceOptions, REFERRAL_PRIVACY } from "../../../constants"
import Profession from "../../ReferralCompleteInformation/Profession";
import { useEffect } from "react";
import { AttentionPoUp } from "./AttentionPopUp";
import PastTimingInfo from './PastTimingInfo'

// formValues and getSchemasValidators
import { formInitialValues, getFormSchema, Listener } from './utils'
import { useToggle } from "../../../../../../../../libs/useToggle";
import { PartyConfirmation } from "./PartyConfirmation";
import { DraftModal, useDraft } from "../../../useDraft";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
`;

const InputBox = styled.div`
    width: 100%;
   // border-bottom: 1px solid #D8D8D8;
    margin-bottom: 24px;
`


const ReferralPastCompleteInformation = ({t, theme, setStepData, stepData, opPrivacy, candidates, saveStepData }) => {
    const haveCandidates = opPrivacy && opPrivacy === REFERRAL_PRIVACY.REFERRAL_PAST && candidates.length >= 1;
  //  const isDirectOp = candidates.length === 1 ? 'DIRECT' : 'NON-DIRECT';
    const formSchema = getFormSchema(haveCandidates && candidates.length > 1, shouldRenderProfession);
    const haveOnlyOneCandidateRealEstate = haveCandidates && candidates.length === 1 && candidates[0] &&  candidates[0].user_type && candidates[0].user_type === '100';
    
    //
   //  console.log('ALL DATA',candidates)
    /// MARK: OpenThePopUp                        
   const [ open, setOpen ] = useToggle(false)
    const { loading, saveDraft, modalMessage, setShowModal, showModal } = useDraft();
          
   const shouldRenderProfession = candidates.some(c => c.contact_id) || false;

  
   /**
    * The following object is meant to disable the next button before the pop up appears who now has Formik submit function
    * NOTE: I know this is not a elegant solution, (gonna think of a better one)
    */
    const quickValidation = stepData !== undefined;


     const sections = [
        {
            key: "profession",
            title: "Select the profession type you are seeking:",
            Component: Profession,
            info: t("professionTooltipInfo"),
            shouldRender: shouldRenderProfession,
            initCollapsed: false,
        },
        // {
        //     key: "targetToNotify",
        //     title: "Profession Notification Radius",
        //     Component: TargetToNotify,
        //     info: t("targetToNotify_tooltip"),
        //     shouldRender: true,
        //     initCollapsed: false,
        // },
        {
            key: "location",
            title: "Location",
            Component: Location,
            info: t("pastLocation_tooltipInfo"),
            shouldRender: true,
            initCollapsed: false,
            
        },
        {
            key: "timingInfo",
            title: "Other Info",
            Component: PastTimingInfo,
            info: t("past_timingInfo_toolTipInfo"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "acceptanceCondition",
            title: "Who can accept this referral?",
            Component: AcceptanceCondition,
            info: t("acceptanceCondition_tooltip"),
            shouldRender: haveCandidates && candidates.length > 1,
            initCollapsed: false,
        },
        {
            key: "realEstateDetails",
            title: "Real Estate Details",
            Component: RealStateDetails,
            info: null,
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "description",
            title: "Description",
            Component: Description,
            info: t("past_description_toolTipsInfo"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "referralFee",
            title: "Referral Fee",
            Component: ReferralFee,
            info: t("past_referralFee_tooltips"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "clientInfomation",
            title: "Client Information",
            Component: ClientInformation,
            info: t("past_clientInfo_toopTips"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "partyConfirmation",
            title: "Past Op Confirmation",
            Component: PartyConfirmation,
            info: t("past_confirmation"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "visibility",
            title: "Visibility After Exchange",
            Component: Visibility,
            info: t("past_visibility_tooltip"),
            shouldRender: haveCandidates,
            initCollapsed: false,
        }
    ];

    const collapsibleInitialState = sections.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.initCollapsed }),
        {}
    );

    const [collapsibleSections, setCollapsibleSections] = useState(
        collapsibleInitialState
    );

    const onToogleCollapse = key =>
        setCollapsibleSections(prev => ({
            ...prev,
            [key]: !collapsibleSections[key]
        }));

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        // marginLeft: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    const displayRealStateDetailsSection = (profession, key) => {
        if (key === 'realEstateDetails')
            if (profession && profession.value === '100' || haveOnlyOneCandidateRealEstate)
                return true
            else
                return false
        return true;
    }

    const displayTargetToNotify = (profession, key) => {
        if (key === 'targetToNotify')
            if (profession && profession.global)
                return true;
            else 
                return false;
        return true;
    }

    const displayOptionalSectionLabel = (key, formValues) => {        
        if (key === 'clientInfomation' && (!formValues.agreement || (formValues.agreement && !formValues.agreement.value) ) ) 
            return '(Optional)'

        return ''
    }


 
    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={stepData ?  ({ ...formInitialValues, ...stepData}) : formInitialValues}
                onSubmit={() => setOpen.toggle()}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}  
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                     
                    isValid
                }) => (
                    <Container>
                        {sections.map(
                            ({ key, title, Component, info, shouldRender }) =>
                                shouldRender && displayTargetToNotify(values.profession, key) && displayRealStateDetailsSection(values.profession, key) && (
                                    <InputBox>
                                        <CollapsableSection
                                            onClickCollapse={() => onToogleCollapse(key)}
                                            collapsed={collapsibleSections[key]}
                                            title={
                                                <Flex justifyContent={"space-between"}>
                                                    <Flex alignItems={"center"}>
                                                        <span style={titleStyles}>
                                                            {title} {displayOptionalSectionLabel(key, values)}
                                                        </span>
                                                        {info && (<Icon
                                                            icon="info"
                                                            size="md"
                                                            color="#057AFF"
                                                            data-tip
                                                            data-for={key}
                                                        />)}
                                                    </Flex>
                                                </Flex>
                                            }
                                            flexJustify={"space-between"}
                                            titleStyles={{
                                                ...titleStyles
                                            }}
                                            onClick={e => {}}
                                            arrowDimensions={{ height: 9, width: 15 }}
                                            arrowColor={theme.coolBlack}
                                            showArrow
                                            rootStyles={{
                                                marginBottom: theme.spacing[10],
                                                overflow: "inherit"
                                            }}
                                        >
                                            <Component
                                                formValues={values}
                                                theme={theme}
                                                onChange={handleChange}
                                                errors={errors}
                                            />
                                        </CollapsableSection>
                                        <ReactTooltip
                                            id={key}
                                            delayHide={200}
                                            place="bottom"
                                            type="light"
                                            effect="solid"
                                            className="form-tooltip"
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: info }}/>
                                        </ReactTooltip>
                                    </InputBox>
                                )
                        )}
                        <Listener saveStepData={saveStepData}  formInitialValues={formInitialValues} />
                        <ButtonContainer>
                            {quickValidation && <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={saveDraft}
                                >
                                    Save Draft
                                </Button>
                            </Box>}
                            <Button
                             //   onClick={() => setOpen.toggle()}
                              onClick={handleSubmit}
                               // disabled={!quickValidation}
                                type='button'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                        {open &&  
                             <AttentionPoUp  
                                callback={handleSubmit} 
                                setOpenAttentionModel={setOpen.toggle}  
                                setStepData={setStepData}
                                values={values}
                             /> 
                        }
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default ReferralPastCompleteInformation;