import styled from 'styled-components';
import { NOTIFICATIONS_BELL_TYPES } from '../../../../../assets/strings';
import { Text } from '../../../../ui/presentation/atoms/shared/Text';

export const Container = styled.div`
`

export const AvatarContainer = styled.div`
    position: relative;
    opacity: ${props => !props.read || props.type === NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION ? 1 : .5};
`

export const AvatarIconContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -2px;
    bottom: -3px;
    border-radius: 50px;
    background-color: ${props => props.theme.clearGray};
    width: 20px;
    height: 20px;

`

export const MarkAllAsRead = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const CollapsableContainer = styled.div`
    overflow:hidden;
    transition: max-height .5s ease-out;
    height:auto;
    max-height:${props => props.collapsed ? 'auto' : '1000px'}; 
`;

export const NotificationBody = styled(Text)`
    p {
        margin-right: ${props => props.theme.spacing[1]};
        font-weight: bold;
        color: ${props => props.color || "inherit"};
        display: inline;
    }
`