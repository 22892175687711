import styled from 'styled-components';
//import { getThemeColor, getThemeOnColor } from '../../../../../../../theme';
import { Box, Flex } from 'rebass';

export const TopbarContainer = styled(Flex)`
    min-height: 4.813rem;	
    width: 100vw;	
    border: ${props => `1px solid ${props.theme.greyLight5}`};	
    background-color: #FFFFFF;	
    z-index: 99996;
    top: 0;
    position: sticky;
    
`;
export const SearchContainer = styled.div`
    min-height: 4.813rem;	
    width: 100vw;	
    border: ${props => `0px 1px 1px 1px solid ${props.theme.greyLight5}`};	
    background-color: #FFFFFF;	
    z-index: 999999;
    
`

export const ProfileText = styled.div`
    height: 0.688rem;	
    min-width: 3.125rem;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '0.625rem'};
    color: ${props => props.theme.coolBlack};	
    font-weight: 500;	
    letter-spacing: 0.004rem;	
    line-height: 0.688rem;
    margin-left: 0.750rem;
    cursor: pointer;
    z-index: 999;
`;

export const AvatarContainer = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`

export const NotificationContainer = styled.div`
    cursor: pointer;
    padding-right: 20px;
    position: relative;
    
`