import { connect } from "react-redux";
import { resetState } from "../../../../../../../../redux/actions/creators/opportunityCreationFlow";
import {  ErrorMessageView, SuccesReferralSentView } from './view'

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        check: state.main
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState()),
    };
};

const SuccesReferralSent = connect(mapStateToProps, mapDispatchToProps)(SuccesReferralSentView);
const ErrorMessage = connect(mapStateToProps, mapDispatchToProps)(ErrorMessageView);

export { SuccesReferralSent, ErrorMessage }





