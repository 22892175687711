import React from 'react'
import { Box } from 'rebass';
import { InputContainer, InputLabel, Input } from "../../../../../../Settings/common/Components"

const PriceField = ({
    formValues,
    onChange,
    errors,
    fieldSelector,
    label,
    width = 1,
    pl = 0,
    pr = 0,
    mt = 0,
    onChangeSideAction = () => {},
}) => {

    return (
        <Box width={width} pr={pr} pl={pl} mt={mt}>
            <InputContainer showLabelOnValue>
                <Input
                    name={fieldSelector}
                    placeholder={label}
                    required
                    value={formValues[fieldSelector]}
                    onChange={(ev) => {
                        const value = ev.target.value.replace('$', '')

                        if (value === '' || value === null) {
                            onChange({
                                target: {
                                    name: fieldSelector,
                                    value: '',
                                }
                            })
                        } else if (!isNaN(value) && Number(value) > 0) {
                            onChange({
                                target: {
                                    name: fieldSelector,
                                    value: `$${value.replace('.', '')}`,
                                }
                            })
                        }
                    }}
                    error={!!errors[fieldSelector]}
                />
                <InputLabel> { label } </InputLabel>
            </InputContainer>
        </Box>
    );
}

export default PriceField;
