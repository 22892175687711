import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import LocationForm from "../../ReferralCompleteInformation/Location/view"

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;


const TimingInfo = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
    clientStatusOptions
}) => {
    const key = "timing_"
    const [isCollapsed, setIsCollapsed] = useState(false)

    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Timing Info
                            </span>
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <SelectContainer>
                    <Select
                        options={clientStatusOptions}
                        onChange={option => {
                            handleChange({
                                target: {
                                    name: "timingInfo",
                                    value: option
                                }
                            });
                        }}
                        styles={selectStyle}
                        value={values.timingInfo}
                        isClearable
                        placeholder="Current Status...*"
                        error={errors.timingInfo}
                    />
                </SelectContainer>
            </CollapsableSection>
        </Box>
    );
}

export default TimingInfo;
