import React, { useState, useEffect, useRef } from "react";
import { Flex } from "rebass";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Icon } from "../../../ui/presentation/atoms/shared/Icon";
import SquareWhiteInput from '../../../ui/presentation/atoms/shared/Inputs/SquareWhiteInput/SquareWhiteInput';
import { VisibilityIconContainer, InputContainer } from "./styles";
import { withTheme } from "styled-components";
import useDebounce from "../../../../libs/UseDebounce";
import { allowAccessToDev } from "../../../../redux/actions/creators/shared";
import Spinner from "../../../../assets/svg/Spinner";

const inputTypes = {
    password: "password",
    text: "text"
}

const placeholderText = "Enter Access Code...";

const AllowAccessToDevelopment = ({ theme, allowAccessToDev, isLoading }) => {
    const [placeholder, setPlaceholder] = useState("");
    const [inputType, setInputType] = useState(inputTypes.password);
    const inputRef = useRef(null);
    const [accessCode, setAccessCode] = useState(localStorage.getItem("allowAccessCodeDev") || "");
    const debouncedAccessCode = useDebounce(accessCode, 500);

    const toggleInputType = () => {
        setInputType(
            inputType === inputTypes.password
                ? inputTypes.text
                : inputTypes.password
        );
    };

    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            if (i < placeholderText.length) {
                setPlaceholder(placeholder => placeholder + placeholderText[i]);
                i++;
            } else {
                clearInterval(interval);
                inputRef.current.focus();
            }
        }, 50);
    }, []);

    useEffect(() => {
        if (debouncedAccessCode) {
            allowAccessToDev(debouncedAccessCode);
        }
    }, [debouncedAccessCode]);

    const renderVisibleIcon =
        inputType === inputTypes.password ? "eye-visible" : "eye-invisible";

    return (
        <Flex
            flexDirection="column"
            width="100%"
            style={{ height: "100vh" }}
            alignContent="center"
            alignItems="center"
            justifyContent="center"
        >
            <InputContainer
                width={[.8, .8, .2, 0.2]}
                maxWidth="200px"
                mt={"2rem"}
                style={{ position: "relative" }}
            >
                <SquareWhiteInput
                    placeholder={placeholder}
                    innerRef={inputRef}
                    type={inputType}
                    inputStyles={{
                        backgroundColor: "rgb(240, 240, 240)",
                        borderRadius: "8px",
                        boxShadow: "none",
                        border: "none"
                    }}
                    onChange={e => {
                        e.preventDefault();
                        setAccessCode(e.target.value);
                    }}
                    value={accessCode}
                    rightIcon={accessCode && ((props) => <Icon icon="close" {...props} />)}
                    leftIcon={isLoading && Spinner}
                    onClickRightIcon={e => {
                        e.preventDefault();
                        setAccessCode("");
                    }}
                    height={40}
                />
                <VisibilityIconContainer onClick={toggleInputType}>
                    {accessCode && (
                        <Icon icon={renderVisibleIcon} color={theme.blueMedium} />
                    )}
                </VisibilityIconContainer>
            </InputContainer>
        </Flex>
    );
};

const mapStateToProps = state => ({
    isLoading: state.main.ui.global.allowAccessToDev.isLoading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            allowAccessToDev
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(AllowAccessToDevelopment));