import React from "react";
import styled from "styled-components";
import { MainText, StepWrapper, selectStyle, Button, ButtonContainer } from "../../../components";
import Select from 'react-select';
import { Icon } from "../../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import * as yup from 'yup'
import { Formik } from "formik";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5em;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const JobPrivacy = {
    EVERYONE: "EVERYONE",
    CANDIDATES: "CANDIDATES",
    GROUPS: "GROUPS",
};

const JobPrivacyOptions = [
    { value: JobPrivacy.EVERYONE, label: 'Everyone' },
    { value: JobPrivacy.CANDIDATES, label: 'Only the candidate(s) that I select' }
];

const formInitialValues = {
    jobType: null,
    contractorType: null,
    employeeType: null,
};

const formSchema = yup.object().shape({
    jobPrivacy: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
});

const JobCurrentPrivacy = ({
    setStepData,
    stepData,
    t,
}) => {
    const title = t("jobPrivacyStepTitle")
    const info = t("jobPrivacyStepInfo")

    return (
        <StepWrapper>
            <Wrapper>
                <MainText>{ title }</MainText>
                <InfoIconContainer>
                    <Icon
                        icon="info"
                        color="#057AFF"
                        size="md"
                        data-tip
                        data-for={'jobPrivacyStepInfo'}
                    />
                </InfoIconContainer>
            </Wrapper>
            <Formik
                initialValues={stepData ?  stepData : formInitialValues}
                onSubmit={(values) => setStepData(values)}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Container>
                    <SelectContainer>
                        <Select
                            options={JobPrivacyOptions}
                            onChange={option => {
                                handleChange({
                                    target: {
                                        name: "jobPrivacy",
                                        value: option,
                                    }
                                })
                            }}
                            styles={selectStyle}
                            value={values.jobPrivacy}
                            isClearable
                            placeholder="Select...*"
                            error={!!errors.jobPrivacy}
                        />
                    </SelectContainer>
                    <ButtonContainer>
                        <Button
                            onClick={handleSubmit}
                            disabled={false}
                            type='submit'
                        >
                            Next
                        </Button>
                    </ButtonContainer>
                    </Container>
                )}
            </Formik>
            <ReactTooltip
                id={'jobPrivacyStepInfo'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
                clickable
            >
                <div dangerouslySetInnerHTML={{ __html: info }}/>
            </ReactTooltip>
        </StepWrapper>
    )
}

export default JobCurrentPrivacy;
