import React from 'react'
import { withTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass'
import styled, { withTheme } from 'styled-components'
import Link from '../../../ui/presentation/atoms/Link';
import HorizontalLine from '../../../ui/presentation/atoms/shared/HorizontalLine';
import { AppLink } from '../../../ui/presentation/atoms/shared/RighthandLinks/styles';
import { Text } from '../../../ui/presentation/atoms/shared/Text';
import { ReactComponent as DownloadAppStore } from "../../../../assets/svg/DownloadAppStore.svg";
import { ReactComponent as DownloadPlayStore } from "../../../../assets/svg/DownloadPlayStore.svg";
import { Icon } from '../../../ui/presentation/atoms/shared/Icon';

const FooterContainer = styled(Flex)`
    min-height: 328px;
    background-color: #000;
    padding: ${props => `${props.theme.spacing[10]} ${props.theme.spacing[20]}`};
`

const SignInFooter = ({ t, theme }) => {

    const bottomLinks = [
        {
            key: "tac",
            title: t("tac"),
            href: "https://site.nuop.com/terms-and-conditions/"
        },
        {
            key: "privacyPolicy",
            title: t("privacyPolicy"),
            href: "https://site.nuop.com/privacy-policy/"
        },
        {
            key: "coc",
            title: t("coc"),
            href: "https://site.nuop.com/code-of-conduct/"
        },
        {
            key: "cookiePolicy",
            title: t("cookiePolicy"),
            href: "https://site.nuop.com/cookie-policy/"
        },
        {
            key: "eula",
            title: t("eula"),
            href: "https://site.nuop.com/end-user-license-agreement/"
        },
        {
            key: "dnsmpi",
            title: "Do Not Sell My Personal Information",
            href: "https://site.nuop.com/do-not-sell-my-personal-information/"
        },
        {
            key: "",
            title: "Statement of Accessibility",
            href: "https://site.nuop.com/statement-of-accessibility/"
        }

    ];

    const linksSections = [
        {
            key: "h&s",
            title: t("h&s"),
            links: [
                {
                    key: "contact",
                    title: t("contact"),
                    href: "https://nuop.com/contact-us/"
                },
                {
                    key: "support",
                    title: t("support"),
                    href: "https://support.nuop.com/portal/en/home"
                }
            ]
        },
        {
            key: "aboutInfo",
            title: "About",
            links: [
                {
                    key: "NuOp Quick Start",
                    title: "Tips to Start Fast",
                    href: "https://support.nuop.com/portal/en/kb/articles/quick-start"
                },
                {
                    key: "referralExchange",
                    title: t("referralExchange"),
                    href: "https://nuop.com/referral-exchange"
                },
                {
                    key: "RealEstateReferrals",
                    title: "Real Estate Referrals",
                    href: "https://nuop.com/real-estate-referrals"
                }
            ]
        },
        {
            key: "resources",
            title: t("resources"),
            links: [
                {
                   key: "pricing",
                   title: "Pricing",
                   href: "https://nuop.com/pricing"
                },
                {
                    key: "blog",
                    title: t("blog"),
                    href: "https://nuop.com/site/auto-draft-4/nuop-blog/"
                },
                {
                    key: "ambassadorProgram",
                    title: t("ambassadorProgram"),
                    href: "https://nuop.com/site/ambassador-program/"
                }
            ]
        },
       /*  {
            key: "single-links",
            title: "",
            links: [
                {
                    key: "pricing",
                    title: t("pricing"),
                    href: "https://nuop.com/site/pricing/",
                    style: { fontWeight: "bold" }
                },
                {
                    key: "story",
                    title: t("story"),
                    href: "https://nuop.com/site/our-story/",
                    style: { fontWeight: "bold" }
                }
            ]
        } */
    ];

    const socialMediaLinks = [
        {
            key: "linkedin",
            title: "LinkedIn",
            href: "https://www.linkedin.com/company/mynuop/",
            icon: "linkedin"
        },
        {
            key: "facebook",
            title: "Facebook",
            href: "https://www.facebook.com/mynuop",
            icon: "facebook"
        },
        {
            key: "twitter",
            title: "Twitter",
            href: "https://twitter.com/mynuop",
            icon: "twitter"
        },
        {
            key: "instagram",
            title: "Instagram",
            href: "https://www.instagram.com/mynuop/",
            icon: "instagram"
        },
        {
            key: "youtube",
            title: "YouTube",
            href: "https://www.youtube.com/channel/UCRWLILThYEaq74ohzx7qHvg",
            icon: "youtube"
        }
    ];

    const commonTextPros = {
        color: theme.white,
        fontSize: "XXXS",
        lineHeight: "XXXS",
    }

    return (
        <FooterContainer flexDirection={"column"} width={[1]}>
            <Flex justifyContent={"space-between"}>
                <Flex>
                    {linksSections.map((section, index) => (
                        <Flex
                            key={index}
                            flexDirection={"column"}
                            mr={theme.spacing[20]}
                            style={{
                                gap: 8
                            }}
                        >
                            <Text
                                {...commonTextPros}
                                fontWeight={"bold"}
                                mb={theme.spacing[4]}
                                style={{
                                    marginBottom: 8
                                }}
                            >
                                {section.title}  
                            </Text>
                            {section.links.map((link, index) => (
                                <Link
                                    key={index}
                                    href={link.href}
                                    target={"_blank"}
                                    fontSize="XXXS"
                                    color={theme.white}
                                    underlineColor={theme.white}
                                    style={{
                                        marginBottom: theme.spacing[2],
                                        ...(link.style && link.style)
                                    }}
                                >
                                    {link.title}
                                </Link>
                            ))}
                        </Flex>
                    ))}
                </Flex>
                <Flex>
                    <AppLink
                        isSigninPage
                        href="https://play.google.com/store/apps/details?id=app.nuop.com"
                        target="_blank"
                        rel="noopener noreferer"
                    >
                        <DownloadPlayStore />
                    </AppLink>
      
                    <AppLink
                        isSigninPage
                        href="https://support.nuop.com/portal/en/kb/articles/how-to-install-nuop-to-your-mobile-device"
                        target="_blank"
                        rel="noopener noreferer"
                    >
                        <DownloadAppStore />
                    </AppLink> 
                </Flex>
            </Flex>
            <HorizontalLine style={{ margin: `${theme.spacing[8]} 0` }} />
            
            <Flex justifyContent={"space-between"} mt={theme.spacing[4]}>
                <Flex>
                    {bottomLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            target={"_blank"}
                            style={{
                                marginRight: theme.spacing[4],
                                color: theme.white,
                                fontSize: "12px"
                            }}
                        >
                            {link.title}
                        </a>
                    ))}
                     <Text
                    {...commonTextPros}
                    ariaLabel={`Copyright 2022 Nuop, Inc. ALL RIGHTS RESERVED | The Business
                    Opportunity Exchange™ | 7 Main St., Warwick, NY | NuOp™,
                    and the phrase "The Business Opportunity Exchange" are
                    trademarks of Nuop, Inc.`}
                    role="img"
                >
                    ® Copyright 2018 - 2023 Nuop, Inc. ALL RIGHTS RESERVED - US PATENT PENDING
                </Text>
                </Flex>
                <Flex>
               
            </Flex>
                <Flex>
                    <Text
                        {...commonTextPros}
                        style={{ marginRight: theme.spacing[4] }}
                    >
                        Find us on:
                    </Text>
                    {socialMediaLinks.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={link.title}
                            style={{ marginRight: theme.spacing[4] }}
                        >
                            <Icon
                                icon={link.icon}
                                color={theme.white}
                                height={18}
                                width={18}
                            />
                        </a>
                    ))}
                </Flex>
            </Flex>
        </FooterContainer>
    );

}

const ViewTranslated = withTranslation("righthandLinks")(SignInFooter);

export default withTheme(ViewTranslated);