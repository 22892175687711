import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addGratitude } from "../../../../../../../redux/actions/creators/profile";
import CreateGratitude from "./view";

const mapStateToProps = state => {
    return {
        user: state.main.ui.view.profile.user.data
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addGratitude
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("createGratitude")(CreateGratitude));
