import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PopupBlurModal from '../../../ui/presentation/atoms/shared/PopupBlurModal'
import Button from '../../../ui/presentation/atoms/shared/Buttons/Button';
import { Flex } from 'rebass';
import { SubHeader, Title } from '../../../ui/presentation/atoms/shared/Text';
import moment from 'moment';
import LocalStorage from '../../../../libs/LocalStorage'
const MaintenanceAlert = (props) => {
    const [data, setData] = useState({
        open: false,
        minutesDifference: 0
    })
    const {
        maintenanceAlert,
        onClose
    } = props

    useEffect(() => {
        const lastClosedAlert = LocalStorage.getMaintenanceAlertOpen()
        let maitenanceDate = moment(maintenanceAlert);
        const now = moment(new Date());

        var duration = moment.duration(maitenanceDate.diff(now));
        var numminutes = Math.floor(duration.asMinutes())
        const open = (!lastClosedAlert || (lastClosedAlert && lastClosedAlert !== maintenanceAlert)) && numminutes > 0
        setData({
            open,
            minutesDifference: numminutes
        })
        return () => {
        }
    }, [maintenanceAlert])

    const close = () => {
        LocalStorage.setMaintenanceAlertOpen(maintenanceAlert)
        setData({
            open: false
        })
        if (onClose) onClose()
    }


    return (
        <PopupBlurModal open={data.open} onClose={() => { }}>
            <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                flexDirection="column"
                width="100%"
                p={["2em"]}
            >
                <Title style={{ marginBottom: "1em", textAlign: 'center', width: '100%' }}>
                    {"New Update Coming Soon!"}
                </Title>
                <SubHeader style={{ marginBottom: "2em", textAlign: 'center' }}>
                    We have been busy working on upgrading the platform. In <span style={{ fontWeight: 'bold' }}>{data.minutesDifference}  minutes </span> NuOp will go into <span style={{ fontWeight: 'bold' }}>maintenance mode</span>.  While we upgrade the system you will not be able to login.
                    We apologize for any inconvenience.  When we return you may begin using the most updated version of NuOp.
                </SubHeader>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    width={1}
                >
                    <Button type='BORDER' label='Close' onClick={close} />
                </Flex>
            </Flex>
        </PopupBlurModal>
    )
}

const mapStateToProps = (state) => {
    return {
        maintenanceAlert: state.main.ui.global.maintenanceAlert
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    {

    },
    dispatch,
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaintenanceAlert));