import styled from "styled-components";
import React from "react";
import { Flex, Image } from 'rebass'
import Spinner from "../../../../../../../assets/svg/Spinner";


const SquareOutlinedButton = styled.div`
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    padding: ${props => (props.padding ? props.padding : props.small ? "2px 16px" : "6px 28px")};
    box-sizing: border-box;
    border: ${props => `2px solid ${props.color ? props.color : props.theme.blueMedium}`};
    border-radius: 4px;
    color: ${props => props.color ? props.color : props.theme.blueMedium};
    outline: none;
    min-height: ${({ height, small }) => height ? height : small ? "32px" : "40px"};
    width: ${props => props.width ? props.width : 'auto'}; 
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    ${props =>
        props.disabled && `
                border: 2px solid ${props.theme.darkGray};
                color: ${props.theme.darkGray};
                cursor: not-allowed;
            `
    }
`;

const Label = styled.span`
    font-size: ${({ fontSize, theme }) =>
        theme.fonts[fontSize] || theme.fonts["XXS"]}};
    font-weight: bold;
    line-height: ${props => props.theme.lineHeights["XS"]};
    text-align: center;
    color: ${props => (props.disabled ? props.theme.darkGray : props.color ? props.color : props.theme.blueMedium)};
`;

export default props => {
    const {
        text,
        color,
        onClick,
        disabled,
        // icon: Icon,
        ...otherprops
    } = props
    return (
        <SquareOutlinedButton color={color} {...props} onClick={onClick}>
            <Flex
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
            >
                {otherprops.icon && typeof otherprops.icon === "function" ? (
                    <otherprops.icon />
                ) : null}
                {otherprops.icon && typeof otherprops.icon !== "function" ? (
                    <Image src={otherprops.icon} mr={1} />
                ) : null}
                {otherprops.isLoading ? (
                    <Spinner color={color} width="22px" height="22px" />
                ) : null}
                {!otherprops.isLoading ? (
                    <Label fontSize={otherprops.fontSize} color={color} disabled={disabled}>
                        {text}
                    </Label>
                ) : null}
            </Flex>
        </SquareOutlinedButton>
    );
};

