import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { Box, Flex, Text } from "rebass";
import useDebounce from "../../../../../../libs/UseDebounce";
import { Icon } from "../Icon";
import UserInfo from "./UserInfo";
import Spinner from "../../../../../../assets/svg/Spinner";
import { mapContactToUserInfo } from "../../../../../../libs/Utils";


const SearchContainer = styled.div`
    border: 1px solid #929292;
    min-height: 48px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    border: 1px solid ${(props) => props.error ? '#DC3545' : '#929292'};
    
    &:focus-within {
        border: 1px solid rgba(5, 122, 255, 1);
        outline: none;
    }
    
    #clear-search {
        
    }
`;

const SearchBox = styled.input`
border: none;
outline: none;
box-sizing: border-box;
font-size: 14px;
padding: 0 10px;
display: flex;
flex-grow: 1;
background: transparent;
&:focus {
        border: none;
        outline: none;
    }
`;

const SearchSuggestions = styled.div`
    position: absolute;
    top: 110%;
    width: 100%;
    z-index: 1000;
    max-height: 600%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
    overflow-y: scroll;
    left: 0;
`;

const SelectedUser = styled.div`
    display: flex;
    flex-grow: 1;
`;

const NoResultsFound = styled(SearchSuggestions)`
    padding: 1em;
    padding: 8px 8px 0px 8px;
    color: #929292;
    height: auto;
`;

const ClearSearch = ({ onClickHandler }) => {
    const [hover, setHover] = useState(false);

    return (
        <Icon
            icon="close"
            style={{ cursor: 'pointer' }}
            color={hover ? 'hsl(0, 0%, 60%)' : 'hsl(0, 0%, 80%)'}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={onClickHandler}
        />
    )
};

const PeopleSearchBar = ({
    error,
    theme,
    searchResults,
    searchLoading,
    peopleSearchBar,
    clearPeopleSearchBar,
    onClickSuggestion,
    onClearSuggestion,
    contacts,
    includeContacts = false,
    clearSelection = false,
    cancelContactError,
    searchInputPlaceholder = "Search by name",
    triggerCreactContact,
    clearCondition,

    
}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCreateButton, setShowCreateButton ] = useState(false);
    const debounceSearchTerm = useDebounce(searchTerm, 300);
    const [selectedUser, setSelectedUser] = useState(null);
    const [ inputIsActive, setInputIsActive ] = useState(false)
    const  [ hiddenCreateContact, setHiddenCreateContact ] = useState(false)
    const showInput = selectedUser === null;

    const performSearch = () => {
        peopleSearchBar(debounceSearchTerm.trim());
    };

    const searchInputChangeHandler = (event) => {
        cancelContactError()
        setSearchTerm(event.target.value);
    };

    const handleSuggestionClick = (id) => {
        let user = [...searchResults, ...contacts].find(u => u.uid === id || u.contact_id === id);
        if (contacts.find(c => c.contact_id === id)) {
            user = mapContactToUserInfo(user);
        }

        if (!clearSelection) {
            setSelectedUser(user);
        } else {
            setSearchTerm("");
        }

        onClickSuggestion(user);
        setShowCreateButton(true)
    }

    const clear = () => {
        setSearchTerm('');
        clearPeopleSearchBar();
        setSelectedUser(null);
    }

    const handleClearSearch = () => {
        if (onClearSuggestion) onClearSuggestion();
        clear();
    }

    useEffect(() => {
        if (debounceSearchTerm) {
            performSearch();
        } else {
            clearPeopleSearchBar();
        }
    }, [debounceSearchTerm]);

    useEffect(() => {
        return () => clear();
    }, [clearCondition])

    const RenderIcon = searchLoading ? Spinner : Icon

    //total length is searchResults + contacts
    const searchResultLength = searchResults ? searchResults.length : 0;
    const contactsLength = contacts ? contacts.length : 0;
    const totalLength = searchResultLength + contactsLength;
    
    return (
        <SearchContainer error={error}  >
            <RenderIcon
                icon="search"
                height={20}
                width={20}
                color={searchTerm ? theme.blueMedium : theme.taupeGray}
            />
            {showInput && <SearchBox
                placeholder={searchInputPlaceholder}//or mail
                type="text"
                autoComplete="off"
                name="searchTerm"
                value={searchTerm}
                onChange={(ev) => searchInputChangeHandler(ev)}
                onFocus={() => {
                    setInputIsActive(true);
                    setHiddenCreateContact(true);
                    
                }}
             
              
            />
            }
            {!showInput && <SelectedUser>
                <UserInfo
                    id={selectedUser.uid}
                    avatarUrl={selectedUser.avatar}
                    name={selectedUser.full_name}
                    organization={selectedUser.company_name}
                    tier={selectedUser.subscription.plan}
                    location={selectedUser.locationAddress}
                    // onClick={handleSuggestionClick}
                    firstName={selectedUser.first_name}
                    lastName={selectedUser.last_name}
                    setInputIsActive={setInputIsActive}
                    setHiddenCreateContact={setHiddenCreateContact}
                />
            </SelectedUser>
            }
            {(!showInput || debounceSearchTerm) && <ClearSearch onClickHandler={handleClearSearch} />}

            <SearchSuggestions>
                {
                    searchResults && searchResults.length > 0 && showInput && inputIsActive &&
                    searchResults.map((u, i) =>
                        <UserInfo
                            id={u.uid}
                            key={u.uid}
                            avatarUrl={u.avatar}
                            name={u.full_name}
                            organization={u.company_name}
                            tier={u.subscription && u.subscription.plan && u.subscription.plan}
                            location={u.locationAddress}
                            background={(i % 2 === 0) ? '#FAFAFA' : '#FFFFFF'}
                            onClick={handleSuggestionClick}
                            isSearchSuggestion
                            setInputIsActive={setInputIsActive}
                            setHiddenCreateContact={setHiddenCreateContact}
                            
                        />
                    )
                }

                {includeContacts && contacts && contacts.length > 0 && showInput &&
                    <Box ml="16px">
                        <Text fontSize={16} fontWeight={700} color={theme.coolBlack} lineHeight={"24px"} >
                            Contacts
                        </Text>
                    </Box>
                }

                {includeContacts && contacts && contacts.length > 0 && showInput &&
                    contacts.map((u, i) =>
                        <UserInfo
                            name={u.full_name}
                            id={u.contact_id}
                            key={u.contact_id}
                            organization={u.work_info && u.work_info.company}
                            location={u.work_info && u.work_info.address && u.work_info.address.city}
                            background={(i + searchResults.length % 2 === 0) ? '#FAFAFA' : '#FFFFFF'}
                            onClick={handleSuggestionClick}
                            firstName={u.first_name}
                            lastName={u.last_name}
                            isSearchSuggestion
                            setInputIsActive={setInputIsActive}
                            setHiddenCreateContact={setHiddenCreateContact}
                        />
                    )
                }
                {searchResults.length > 0 && showCreateButton === false && hiddenCreateContact   && 
                <Flex
                    alignItems={'center'}
                    onClick={() => triggerCreactContact(true)}
                    style={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        color: theme.newBlue,
                        height: 42,
                        paddingLeft: 16,
                        cursor: 'pointer'
                    }}>Create Contact</Flex>}
            </SearchSuggestions>

            {totalLength === 0 && !searchLoading && searchTerm && debounceSearchTerm && (
                <NoResultsFound>
                    No results found.
                    <Flex
                    alignItems={'center'}
                    onClick={() => triggerCreactContact(true)}
                    style={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        color: theme.newBlue,
                        height: 32,
                       
                        cursor: 'pointer'
                    }}>Create Contact</Flex>
                </NoResultsFound>
            )}
        </SearchContainer>
    )
}

export default withTheme(PeopleSearchBar);