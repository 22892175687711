import { connect } from 'react-redux';
import View from './view';
import { sendingJobData, setStepData } from '../../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { OPPORTUNITY_CREATION_STEPS } from '../../../constants';
import { withTranslation } from "react-i18next";
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData,
        referralIsLoading: state.main.ui.view.opportunityCreationFlow.referralIsLoading,
        successReferral: state.main.ui.view.opportunityCreationFlow.successReferral,
        errorReferral: state.main.ui.view.opportunityCreationFlow.errorReferral,
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    sendingJobData,
    setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_PREVIEW, data)),
    
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("referralJobCurrent")(View));