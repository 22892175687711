import {
    FETCH_REFERRALINFORMATION_FAIL,
    FETCH_REFERRALINFORMATION_ISLOADING,
    FETCH_REFERRALINFORMATION_SUCCESS,
    OPEN_POP_UP_MODAL,
    CLOSE_POP_UP_MODAL,
    REFERRALINFORMATION_INIT,
    REFERRALINFORMATION_CLAIM_MODAL,
    REFERRALS_UNCLAIM_SUCCESS,
    REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL,
    REFERRALINFORMATION_SET_SECTION,
    REFERRALINFORMATION_SET_REFERRAL,
    REFERRALINFORMATION_TIMELINE_SUCCESS,
    REFERRALINFORMATION_TIMELINE_LOADING,
    REFERRALINFORMATION_TIMELINE_ADD_ITEM,
    REFERRALINFORMATION_SELECT_CANDIDATE,
    REFERRALINFORMATION_CONFIRM_PAST,
    REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY,
    SHARE_OPEN,
    SHARE_CLOSE,
} from "../../constants";
import { handleError } from "../modal";

import { deleteIncomingOpenReferral, toggleNotAuhorizedModal } from '../referrals'
import Utils from '../../../../libs/Utils'
import ReferralBusiness from '../../../../business/referral'
import { push } from "connected-react-router";
import { removeReferralFromBigBoard } from "../bigBoard";
import { OPPORTUNITIES_MODEL } from "../../../../assets/strings";

const setSection = currentSection => ({
    type: REFERRALINFORMATION_SET_SECTION,
    payload: {
        currentSection: currentSection
    }
})

const getPotentialCandidatesIsLoading = isLoading => ({
    type: "POTENTIAL_CANDIDATES_ISLOADING",
    payload: {
        isLoading: isLoading
    }
})

const setReferral = (referralId, from, open) => ({
    type: REFERRALINFORMATION_SET_REFERRAL,
    payload: {
        referralId: referralId,
        from: from,
        open: open
    }
})

export const getPotentialCandidates = (referral = {}) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(getPotentialCandidatesIsLoading(true))
            try {
                Array.isArray(referral.location && referral.location.coords);
            } catch (error) {
                throw new Error('Ops, location_cords should be an [lat, lon] array...');
            }
            const response = await cloudfunctions.getRERZoneUsers(referral);
            dispatch({
                type: "POTENTIAL_CANDIDATES_SUCCESS",
                payload: {
                    users: response.data
                }
            })
        } catch (error) {
            // dispatch(handleError(error));
            return dispatch({
                type: "POTENTIAL_CANDIDATES_FAIL",
                errors: cloudfunctions.parseRequestError(error)
            });
        }


    }
}

const fetchReferralInformation = (referralCode) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase,
        LocalStorage
    }) => {
        dispatch({
            type: FETCH_REFERRALINFORMATION_ISLOADING,
            payload: {
                isLoading: true
            }
        });
        try {
            const referral = await cloudfunctions.getReferralByCode(referralCode);
            const meId = getState().main.user.uid
            // const referralStatusKeys = getState().main.ui.global.statics.referralStatus && getState().main.ui.global.statics.referralStatus.optionsKeys;

            // const referralIsNoLongerAvailable = ReferralBusiness.referralIsNoLongerAvailable(
            //     referral.data,
            //     meId,
            //     referralStatusKeys
            // );
            // console.log("referralssssssss", referral, meId);
            // console.log(referralIsNoLongerAvailable);
            // if (referralIsNoLongerAvailable) throw new Error("Referral no longer available")

            if (meId === (referral.data && referral.data.fromId) && (referral.data && referral.data.type) === 'OPEN') dispatch(getPotentialCandidates(referral.data));

            const timeline =
                (referral.data &&
                    referral.data.timeline &&  referral.data.timeline.filter(
                        t => t.type !== "MESSAGE"
                    )) ||
                [];

            return dispatch({
                type: FETCH_REFERRALINFORMATION_SUCCESS,
                payload: {
                    referral: referral.data,
                    timeline,
                    opType: OPPORTUNITIES_MODEL.REFERRAL
                }
            });
        } catch (error) {
            console.error('error', error && error.response && error.response.data)
            const message = error && error.response && error.response.data && error.response.data.message;
            if (message === "Sorry, this opportunity is no longer available") {
                dispatch(push("/"));
                dispatch(toggleNotAuhorizedModal());
            } else {
                dispatch(handleError(error));
            }
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const fetchRecommendationInformation = (recommendationCode) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase,
        LocalStorage
    }) => {
        dispatch({
            type: FETCH_REFERRALINFORMATION_ISLOADING,
            payload: {
                isLoading: true
            }
        });
        try {
            const recommendation = await cloudfunctions.getRecommendationByCode(recommendationCode);
            const meId = getState().main.user.uid

            if (meId === (recommendation.data && recommendation.data.fromId) && (recommendation.data && recommendation.data.type) === 'OPEN') dispatch(getPotentialCandidates(recommendation.data));

            return dispatch({
                type: FETCH_REFERRALINFORMATION_SUCCESS,
                payload: {
                    referral: recommendation.data,
                    timeline: recommendation.data.timeline,
                    opType: OPPORTUNITIES_MODEL.RECOMMENDATION
                }
            });
        } catch (error) {
            console.error('error', error && error.response && error.response.data)
            const message = error && error.response && error.response.data && error.response.data.message;
            if (message === "Sorry, this opportunity is no longer available") {
                dispatch(push("/"));
                dispatch(toggleNotAuhorizedModal());
            } else {
                dispatch(handleError(error));
            }
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const fetchJobInformation = (referralCode) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase,
        LocalStorage
    }) => {
        dispatch({
            type: FETCH_REFERRALINFORMATION_ISLOADING,
            payload: {
                isLoading: true
            }
        });
        try {
            const job = await cloudfunctions.getJobByCode(referralCode);
            const meId = getState().main.user.uid

            if (meId === (job.data && job.data.fromId) && (job.data && job.data.type) === 'OPEN') dispatch(getPotentialCandidates(job.data));

            return dispatch({
                type: FETCH_REFERRALINFORMATION_SUCCESS,
                payload: {
                    referral: job.data,
                    timeline: job.data.timeline || [],
                    opType: OPPORTUNITIES_MODEL.JOB
                }
            });
        } catch (error) {
            console.error('error', error && error.response && error.response.data)
            const message = error && error.response && error.response.data && error.response.data.message;
            if (message === "Sorry, this opportunity is no longer available") {
                dispatch(push("/"));
                dispatch(toggleNotAuhorizedModal());
            } else {
                dispatch(handleError(error));
            }
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    }
}

const declineOrAcceptIncomingOpportunity = (action, fromId, refId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const referralCode = getState().main.ui.view
                .referralInformation.referral.code;
            const openReferralPopup = getState().main.ui.view
                .bigBoard.openReferralPopup;
            const opportunityModel = getState().main.ui.view.referralInformation.referral.opportunityModel;
            const meId = getState().main.user.uid;

            const handleDeclineOrAcceptByOpportunityModel = {
                [OPPORTUNITIES_MODEL.REFERRAL]: async () =>
                    action === 'ACCEPT'   ?
                        await cloudfunctions.acceptReferral(refId, fromId)
                        : await cloudfunctions.declineReferral(refId, fromId),
                [OPPORTUNITIES_MODEL.RECOMMENDATION]: async () => null,
                [OPPORTUNITIES_MODEL.JOB]: async () =>
                    action === "ACCEPT"
                        ? await cloudfunctions.acceptJob(refId, fromId)
                        : await cloudfunctions.declineJob(refId, fromId)
            };

            await handleDeclineOrAcceptByOpportunityModel[opportunityModel]();
            // Update the candidate in the store
            dispatch({
                type: REFERRALINFORMATION_SELECT_CANDIDATE,
                payload: {
                    candidateId: meId
                }
            });
            // Refetch the opportunity
            setTimeout(async () => {
                const handleRefetchOpportunity = {
                    [OPPORTUNITIES_MODEL.REFERRAL]: async () =>
                        await cloudfunctions.getReferralByCode(referralCode),
                    [OPPORTUNITIES_MODEL.RECOMMENDATION]: async () => null,
                    [OPPORTUNITIES_MODEL.JOB]: async () =>
                        await cloudfunctions.getJobByCode(referralCode)
                };
                const opportunity =await handleRefetchOpportunity[opportunityModel]();
                dispatch({
                    type: FETCH_REFERRALINFORMATION_SUCCESS,
                    payload: {
                        referral: opportunity.data,
                        timeline: opportunity.data.timeline,
                        opType: opportunityModel
                    }
                });
            }, 1500);
            return
        } catch (error) {
            dispatch(handleError(error));
            dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
            return Promise.reject(new Error('fail'))

        }
    };
};

const confirmPastOpportunity = (refId, fromId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const opportunityModel = getState().main.ui.view.referralInformation
                .referral.opportunityModel;

            const handleConfirmPastOpportunity = {
                [OPPORTUNITIES_MODEL.REFERRAL]: async () =>
                    await cloudfunctions.confirmPastReferral(refId, fromId),
                [OPPORTUNITIES_MODEL.RECOMMENDATION]: async () => null,
                [OPPORTUNITIES_MODEL.JOB]: async () =>
                    await cloudfunctions.confirmPastJob(refId, fromId)
            };

            await handleConfirmPastOpportunity[opportunityModel]();
            // Update the candidate in the store
            dispatch({
                type: REFERRALINFORMATION_CONFIRM_PAST,
            });
        } catch (error) {
            dispatch(handleError(error));
            dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    }
};

const claimOpenOpportunity = (rId, fromId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const referralCode = getState().main.ui.view.referralInformation.referral.code;
            // TODO: As of 06/15/2023, openReferralPopup is not used. Remove if not needed.
            const openReferralPopup = getState().main.ui.view.bigBoard
                .openReferralPopup;
            const opportunityModel = getState().main.ui.view.referralInformation.referral.opportunityModel;
            if (opportunityModel === OPPORTUNITIES_MODEL.JOB) 
                await cloudfunctions.claimJob(rId, fromId);
            // else if (opportunityModel === OPPORTUNITIES_MODEL.RECOMMENDATION) 
            //     await cloudfunctions.claimRecommendation(rId, fromId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL)
                await cloudfunctions.claimRER(rId, fromId);
            dispatch(deleteIncomingOpenReferral(rId))
            if (openReferralPopup) {
                const referral = await cloudfunctions.getReferralByCode(
                    referralCode
                );
                dispatch({
                    type: FETCH_REFERRALINFORMATION_SUCCESS,
                    payload: {
                        referral: referral.data
                    }
                });
                setTimeout(() => {
                    dispatch(claimSuccessModal({ dictKey: "claimRERSuccess", open: true }));
                }, 100);
            } else {
                dispatch(claimSuccessModal({ dictKey: "claimRERSuccess", open: true }));
            }
            return
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};

const declineOpenOpportunity = (rId, fromId) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const referralCode = getState().main.ui.view
                .referralInformation.referral.code;
            const openReferralPopup = getState().main.ui.view
                .bigBoard.openReferralPopup;
            const opportunityModel = getState().main.ui.view
                .referralInformation.referral.opportunityModel;
            const meId = getState().main.user.uid;

            if (opportunityModel === OPPORTUNITIES_MODEL.JOB) 
                await cloudfunctions.declineOpenJob(rId, fromId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.RECOMMENDATION) 
                await cloudfunctions.declineOpenRecommendation(rId, fromId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL)
                await cloudfunctions.declineRER(rId, fromId);

            dispatch({
                type: REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY,
                payload: {
                    userId: meId
                }
            });

            if (openReferralPopup) {
                const referral = await cloudfunctions.getReferralByCode(
                    referralCode
                );
                dispatch({
                    type: FETCH_REFERRALINFORMATION_SUCCESS,
                    payload: {
                        referral: referral.data
                    }
                });
            }
            return;
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};

const cancelOpportunity = (rId, meId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const referralCode = getState().main.ui.view.referralInformation.referral.code;
            const openReferralPopup = getState().main.ui.view.bigBoard
                .openReferralPopup;
            const opportunityModel = getState().main.ui.view
                .referralInformation.referral.opportunityModel;

            if (opportunityModel === OPPORTUNITIES_MODEL.JOB) 
                await cloudfunctions.cancelJob(rId, meId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.RECOMMENDATION) 
                await cloudfunctions.cancelRecommendation(rId, meId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL)
                await cloudfunctions.cancelReferral(rId, meId);
            dispatch(removeReferralFromBigBoard(referralCode));
            if (openReferralPopup) {
                const referral = await cloudfunctions.getReferralByCode(
                    referralCode
                );
                dispatch({
                    type: FETCH_REFERRALINFORMATION_SUCCESS,
                    payload: {
                        referral: referral.data
                    }
                });
            }
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};

const unclaimOpportunity = (rId, authorId) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const opportunityModel = getState().main.ui.view
                .referralInformation.referral.opportunityModel;
            const meId = getState().main.user.uid;

            if (opportunityModel === OPPORTUNITIES_MODEL.JOB) 
                await cloudfunctions.unclaimJob(rId, authorId);
            // else if (opportunityModel === OPPORTUNITIES_MODEL.RECOMMENDATION) 
                // await cloudfunctions.cancelRecommendation(rId, authorId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL)
                await cloudfunctions.unclaimReferral(rId, authorId);
            dispatch({
                type: REFERRALS_UNCLAIM_SUCCESS,
                payload: {
                    userId: meId
                }
            });
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};


const claimSuccessModal = (claimSuccessModal) => ({
    type: REFERRALINFORMATION_CLAIM_MODAL,
    payload: {
        claimSuccessModal: claimSuccessModal
    }
})

const confirmAssignModal = (confirmAssignModal) => ({
    type: REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL,
    payload: {
        confirmAssignModal: confirmAssignModal
    }
})

const selectCandidate = (candidateId, rid, isRecommendation = false, isContact = false) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const referralCode = getState().main.ui.view.referralInformation.referral.code;
            const opportunityModel = getState().main.ui.view
                .referralInformation.referral.opportunityModel;
            if (opportunityModel === OPPORTUNITIES_MODEL.JOB) {
                await cloudfunctions.assignJob(
                    candidateId,
                    rid,
                    isRecommendation,
                    isContact
                );
            }
            // else if (opportunityModel === OPPORTUNITIES_MODEL.RECOMMENDATION) 
            //     await cloudfunctions.claimRecommendation(rId, fromId);
            else if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL) {
                await cloudfunctions.assignRER(
                    candidateId,
                    rid,
                    isRecommendation,
                    isContact
                );
            }
            dispatch(confirmAssignModal({ open: false }));
            // Update the candidate in the store
            dispatch({
                type: REFERRALINFORMATION_SELECT_CANDIDATE,
                payload: {
                    candidateId,
                    isRecommendation
                }
            });
            // dispatch(claimSuccessModal({ dictKey: "assignRERSuccess", open: true }));

            return
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};


const fetchReferralTimeline = (referralCode) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch(referralTimelineLoading(true));
            const response = await cloudfunctions.fetchReferralTimeline(referralCode);
            // console.log(response)
            // let data = response && response.data ? Utils.sortArrayBy(Object.keys(response.data).map(d => response.data[d]), "date", true) : []
            let data = response && response.data ? Utils.sortArrayBy(response.data, "date", true) : []
            dispatch({
                type: REFERRALINFORMATION_TIMELINE_SUCCESS,
                payload: {
                    timeline: data
                }
            });

            dispatch(referralTimelineLoading(false));

            return
        } catch (error) {
            dispatch(handleError(error));
            dispatch(referralTimelineLoading(false));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};

// const fetchRecommendationTimeline = (referralCode) => {
//     return async (dispatch, getState, { cloudfunctions }) => {
//         try {
//             dispatch(referralTimelineLoading(true));
//             const response = await cloudfunctions.fetchRecommendationTimeline(referralCode);
//             // let data = response && response.data ? Utils.sortArrayBy(Object.keys(response.data).map(d => response.data[d]), "date", true) : []
//             let data = response && response.data ? Utils.sortArrayBy(response.data, "date", true) : []
//             dispatch({
//                 type: REFERRALINFORMATION_TIMELINE_SUCCESS,
//                 payload: {
//                     timeline: data
//                 }
//             });

//             dispatch(referralTimelineLoading(false));

//             return
//         } catch (error) {
//             dispatch(handleError(error));
//             dispatch(referralTimelineLoading(false));
//             return dispatch({
//                 type: FETCH_REFERRALINFORMATION_FAIL,
//                 errors: error
//             });
//         }
//     };
// }

// const fetchJobTimeline = (referralCode) => {
//     return async (dispatch, getState, { cloudfunctions }) => {
//         try {
//             dispatch(referralTimelineLoading(true));
//             const response = await cloudfunctions.fetchJobTimeline(referralCode);
//             // let data = response && response.data ? Utils.sortArrayBy(Object.keys(response.data).map(d => response.data[d]), "date", true) : []
//             let data = response && response.data ? Utils.sortArrayBy(response.data, "date", true) : []
//             dispatch({
//                 type: REFERRALINFORMATION_TIMELINE_SUCCESS,
//                 payload: {
//                     timeline: data
//                 }
//             });
            
//             dispatch(referralTimelineLoading(false));

//             return
//         } catch (error) {
//             dispatch(handleError(error));
//             dispatch(referralTimelineLoading(false));
//             return dispatch({
//                 type: FETCH_REFERRALINFORMATION_FAIL,
//                 errors: error
//             });
//         }
//     };
// }

const addReferralTimelineItem = (referralCode, message) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            // const meId = getState().main.user.uid
            // const meProfile = getState().main.user.profile
            // const from = ReferralBusiness.referralUser(meId, meProfile.adddress, meProfile.company_info && meProfile.company_info.id, meProfile.company_info && meProfile.company_info.name, meProfile.email, meProfile.lastname, meProfile.name, meProfile.phone, meProfile.state, meProfile.type, meProfile.avatar)
            // const item = {
            //     type: 'MESSAGE',
            //     message: message,
            //     from: from,
            // }
            // dispatch({
            //     type: REFERRALINFORMATION_TIMELINE_ADD_ITEM,
            //     payload: {
            //         timelineItem: { ...item, date: new Date() }
            //     }
            // });
            const opportunityModel = getState().main.ui.view
                .referralInformation.referral.opportunityModel;
            if (opportunityModel === OPPORTUNITIES_MODEL.REFERRAL) {
                await cloudfunctions.addReferralTimelineItem(referralCode, message);
            } else if (opportunityModel === OPPORTUNITIES_MODEL.JOB) {
                await cloudfunctions.addJobComment(referralCode, message);
            }
            return
        } catch (error) {
            dispatch(handleError(error));
            dispatch(referralTimelineLoading(false));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    };
};

const subscribeToReferralComments = (referralCode) => {
    return async (dispatch, getState, { firebase }) => {
        try {
            const unsubscribe = firebase.subscribeToReferralCommentAdded(referralCode, (timelineItem, timelineItemId) => {
                timelineItem = {
                    ...timelineItem,
                    fromUser: [{
                        ...timelineItem.from,
                        username: timelineItem.from.userName
                    }]
                }
                dispatch({
                    type: REFERRALINFORMATION_TIMELINE_ADD_ITEM,
                    payload: {
                        timelineItem
                    }
                });
            });
            dispatch(referralTimelineLoading(false));
            return unsubscribe;
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    }
}

const subscribeToJobComments = (jobCode) => {
    return async (dispatch, getState, { firebase }) => {
        try {
            const unsubscribe = firebase.subscribeToJobCommentAdded(jobCode, (timeline, timelineId) => {
                timeline = timeline.map(t => {
                    if (t.from && t.type === "MESSAGE") {
                        t.fromUser = [{
                            ...t.from,
                            username: t.from.userName
                        }];
                        return t;
                    }
                    return t;
                })
                dispatch({
                    type: REFERRALINFORMATION_TIMELINE_SUCCESS,
                    payload: {
                        timeline
                    }
                });
            });
            dispatch(referralTimelineLoading(false));
            return unsubscribe;
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALINFORMATION_FAIL,
                errors: error
            });
        }
    }
}

const referralTimelineLoading = (isLoading) => {
    return {
        type: REFERRALINFORMATION_TIMELINE_LOADING,
        payload: {
            isLoading: isLoading
        }
    };
};



const openPopUpModal = () => {
    return {
        type: OPEN_POP_UP_MODAL
    };
};

const closePopUpModal = () => {
    return {
        type: CLOSE_POP_UP_MODAL
    };
};

const clearReferralInformation = () => {
    return {
        type: REFERRALINFORMATION_INIT
    };
};

// ------------------------------------ Share Handlers ------------------------------------

const openShareModal = ({ ownerUID, url, opCode, opType }) => ({
    type: SHARE_OPEN,
    payload: {
        ownerUID,
        url,
        opCode,
        opType
    }
});

const closeShareModal = () => ({
    type: SHARE_CLOSE,
});

const shareReferral = (memberUID, isMember) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const {
                opType,
                opCode
            } = getState().main.ui.global.shareModal.referralInfo;
            const payload = {
                memberUID,
                opCode,
                opType,
                isMember
            };
            const response = await cloudfunctions.shareReferral(payload);
            return
        } catch (error) {
            dispatch(handleError(error));
        }
    };
}

export {
    subscribeToReferralComments,
    addReferralTimelineItem,
    fetchReferralTimeline,
    fetchReferralInformation,
    fetchRecommendationInformation,
    // fetchRecommendationTimeline,
    declineOrAcceptIncomingOpportunity,
    openPopUpModal,
    closePopUpModal,
    clearReferralInformation,
    claimOpenOpportunity,
    declineOpenOpportunity,
    confirmPastOpportunity,
    selectCandidate,
    claimSuccessModal,
    confirmAssignModal,
    cancelOpportunity,
    unclaimOpportunity,
    setReferral,
    setSection,
    shareReferral,
    openShareModal,
    closeShareModal,
    fetchJobInformation,
    // fetchJobTimeline,
    subscribeToJobComments,
};
