import { useFormikContext } from "formik";
import React, { useEffect } from "react";


export const Listener = ({ saveStepData, formInitialValues }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        saveStepData({ ...formInitialValues, ...values})
    }, [values])

    return null
}