import {
    COMMUNITY_ISLOADING,
    COMMUNITY_FEATUREDPOSTSUCCESS,
    CLEAN_COMMUNITY,
    COMMUNITY_NEWESTPOSTSSUCCESS,
    COMMUNITY_ADD,
    COMMUNITY_OPEN_IMAGES_CAROUSEL,
    COMMUNITY_DISCUSSION_RANK,
    COMMUNITY_DOWNVOTE,
    COMMUNITY_UPVOTE,
    COMMUNITY_FETCHPOST_SUCCESS,
    COMMUNITY_REPLY_POST_SUCCESS,
    COMMUNITY_UPDATE,
    COMMUNITY_NEW_NEWS_NOTIFICATION
} from "../../constants";
import { handleError } from "../modal";
//import Utils from '../../../../libs/Utils'
import { normalizeBody } from "../newArticle";
import CommunityBussiness from "../../../../business/community";
import moment from "moment";

//import { parse } from "path";
const communityIsLoading = isLoading => ({
    type: COMMUNITY_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

const featuredPostSuccess = featuredPost => ({
    type: COMMUNITY_FEATUREDPOSTSUCCESS,
    payload: {
        featuredPost: featuredPost
    }
});

const fetchPostSuccess = post => ({
    type: COMMUNITY_FETCHPOST_SUCCESS,
    payload: {
        post: post
    }
});

const newestPostsSuccess = (posts, hasMore) => ({
    type: COMMUNITY_NEWESTPOSTSSUCCESS,
    payload: {
        newestPosts: posts,
        hasMore: hasMore
    }
});

export const cleanCommunity = () => ({
    type: CLEAN_COMMUNITY
});

export const addCommunityNewestPost = post => ({
    type: COMMUNITY_ADD,
    payload: {
        post: post
    }
});

export const updateCommunityNewestPost = (postId, post) => ({
    type: COMMUNITY_UPDATE,
    payload: {
        postId: postId,
        post: post
    }
});


export const toogleImagesCarousel = (open, images) => ({
    type: COMMUNITY_OPEN_IMAGES_CAROUSEL,
    payload: {
        open: open,
        images: images
    }
});

export const discussionRank = (postId, discussionRank) => ({
    type: COMMUNITY_DISCUSSION_RANK,
    payload: {
        postId: postId,
        discussionRank: discussionRank
    }
});

export const upvote = (postId, uid) => ({
    type: COMMUNITY_UPVOTE,
    payload: {
        postId: postId,
        uid: uid
    }
});

export const downvote = (postId, uid) => ({
    type: COMMUNITY_DOWNVOTE,
    payload: {
        postId: postId,
        uid: uid
    }
});

export const replyPostSuccess = (postId, reply) => ({
    type: COMMUNITY_REPLY_POST_SUCCESS,
    payload: {
        postId,
        reply
    }
});

const turnOnNewsNotification = touched => ({
    type: COMMUNITY_NEW_NEWS_NOTIFICATION,
    payload: {
        turnOnNewsNotification: touched
    }
});

export const subscribeToNewNewsNotification = userId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        firebase.subscribeNewNews(userId, value => {
            // console.log("subscribeNewNews", value);
            dispatch(turnOnNewsNotification(value));
        });
    };
};

export const setNewNewsNotification = (value) => {
    return async (dispatch, getState, { firebase }) => {
        try {
            const meUid = getState().main.user.uid;
            dispatch(turnOnNewsNotification(value));
            await firebase.setNewNewsNotification(meUid, value);
        } catch (error) {
            console.error("setNewNewsNotificationRead", error);
            dispatch(handleError(error));
            dispatch(turnOnNewsNotification(!value));
            return;
        }
    };
}

export const fetchFeaturedPost = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            const meUid = getState().main.user.uid;
            const postId = await firebase.discussionsFeatured();
            const post = await cloudfunctions.ESdiscussionsByID(postId);
            dispatch(featuredPostSuccess(post.data));

            const discRank = await firebase.discussionRank(postId, meUid);

            dispatch(discussionRank(postId, discRank));
            dispatch(communityIsLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(communityIsLoading(false));
            //return dispatch(editProfileFetchFail(error));
            console.error("error", error);
        }
    };
};

export const fetchPost = postId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            const meUid = getState().main.user.uid;

            const post = await cloudfunctions.ESdiscussionsByID(postId);
            dispatch(fetchPostSuccess(post.data));
            const discRank = await firebase.discussionRank(postId, meUid);
            dispatch(discussionRank(postId, discRank));

            dispatch(communityIsLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            dispatch(communityIsLoading(false));
            //return dispatch(editProfileFetchFail(error));
            console.error("error", error);
        }
    };
};


function sortByDateConsistentASC(itemA, itemB) {
    var valueA = itemA._source && itemA._source.created_at;
    var valueB = itemB._source && itemB._source.created_at;

    // 1. Adding parsing of strings to Date
    // Using moment.js, get last version of it from: http://momentjs.com/
    // Please be sure about using a valid ISO 8601 format:
    // "Deprecation warning: moment construction falls back to js Date.
    // This is discouraged and will be removed in upcoming major release. Please refer to https://github.com/moment/moment/issues/1407 for more info."
    var a = moment(valueA);
    var b = moment(valueB);
    var r = 0;

    // 2. Comparing two Dates (have to be valid dates)
    if (a.isValid() && b.isValid()) {
        r =
            a.valueOf() < b.valueOf()
                ? 1
                : a.valueOf() > b.valueOf()
                    ? -1
                    : 0;
    }

    // 3. In case of dates are equal apply same logic with the unique key to provide the stable sorting
    if (r === 0) {
        r =
            typeof itemA._id !== "undefined" &&
                typeof itemB._id !== "undefined"
                ? itemA._id - itemB._id
                : 0;
    }
    return r;
}

export const fetchNewestPosts = (limit, from = 0) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            const meUid = getState().main.user.uid;
            let newestPosts = getState().main.community.newestPosts ? getState().main.community.newestPosts : []
            const response = await cloudfunctions.ESnewestDiscussionsLTS(
                meUid,
                limit,
                from
            );

            if (response.length > 0) {
                const hasMore = response.length === limit ? true : false;
                newestPosts = response ? [...newestPosts, ...response] : newestPosts
                newestPosts.sort((postA, postB) => {
                    return sortByDateConsistentASC(postA, postB);

                });

                newestPosts = [...new Set(newestPosts.map(item => item._id))].map(
                    _id => {
                        return newestPosts.find(obj => obj._id === _id);
                    }
                );

                dispatch(newestPostsSuccess(newestPosts, hasMore));

                response.forEach(async post => {
                    const discRank = await firebase.discussionRank(
                        post._id,
                        meUid
                    );

                    dispatch(discussionRank(post._id, discRank));
                });
            } else {
                dispatch(newestPostsSuccess([], false));
            }
            dispatch(communityIsLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            //return dispatch(editProfileFetchFail(error));
            dispatch(communityIsLoading(false));
            console.error("error", error);
        }
    };
};

export const subscribeToNewsAdded = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            firebase.subscribeToNewsAdded((keyEvent) => {
                // Timeout to wait for the post to be synced with ElasticSearch
                setTimeout(() => {
                    // 5 for first time, 1 for the rest
                    const newestPostToFetch = keyEvent === "child_added" ? 5 : 1
                    dispatch(fetchNewestPosts(newestPostToFetch));
                }, 2000)
            });
        } catch (error) {
            dispatch(handleError(error));
            console.error("error", error);
        }
    };
}

export const upvotePost = postId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            const meUid = getState().main.user.uid;
            let post = null;
            if (
                getState().main.community.detailedPost &&
                getState().main.community.detailedPost._id === postId
            )
                post = getState().main.community.detailedPost;
            else {
                post = getState().main.community.newestPosts.find(newestPost => newestPost._id === postId)
                if (!post) post = getState().main.ui.global.searchBar.megaSearchResults.community.find(post => post._id === postId)
            }

            if (post && !CommunityBussiness.alreadyUpvoted(post, meUid)) {
                dispatch(upvote(postId, meUid));
                await cloudfunctions.upvoteDiscussion(postId);
            }
            dispatch(communityIsLoading(false));
        } catch (error) {
            const meUid = getState().main.user.uid;
            dispatch(handleError(error));
            dispatch(downvote(postId, meUid));
            dispatch(communityIsLoading(false));
            //return dispatch(editProfileFetchFail(error));
            console.error("error", error);
        }
    };
};

export const downvotePost = postId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            const meUid = getState().main.user.uid;
            dispatch(downvote(postId, meUid));
            await cloudfunctions.downvoteDiscussion(postId);
            dispatch(communityIsLoading(false));
        } catch (error) {
            dispatch(handleError(error));
            const meUid = getState().main.user.uid;
            dispatch(upvote(postId, meUid));
            dispatch(communityIsLoading(false));
            //return dispatch(editProfileFetchFail(error));
            console.error("error", error);
        }
    };
};

export const repplyCommunityPost = (postId, body) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            if (postId && body) {
                const newBody = normalizeBody(body);
                const me = getState().main.user;
                const {
                    company_info,
                    lastname,
                    name,
                    avatar,
                    location,
                    type
                } = me.profile;
                const reply = {
                    author: {
                        uid: me.uid,
                        user_type: type,
                        company_name: company_info ? company_info.name : "",
                        first_name: name,
                        last_name: lastname,
                        location: location,
                        avatar: avatar
                    },
                    created_at: Date.now() / 1000,
                    body: newBody
                };
                dispatch(replyPostSuccess(postId, reply));
                dispatch(communityIsLoading(false));
                await cloudfunctions.replyCommunityPost(
                    postId,
                    newBody
                );
            } else {
                throw new Error("Invalid Body or Post");
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("error", error);
            dispatch(communityIsLoading(false));
        }
    };
};

export const upvotePostReply = (post_id, reply_id) => {
    return async (dispatch, { cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            if (post_id && reply_id) {
                await cloudfunctions.upvoteReply(post_id, reply_id);
            } else {
                throw new Error("Invalid arguments");
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("error", error);
        }
    };
};

export const downvotePostReply = (post_id, reply_id) => {
    return async (dispatch, { cloudfunctions }) => {
        dispatch(communityIsLoading(true));
        try {
            if (post_id && reply_id) {
                await cloudfunctions.downvoteReply(post_id, reply_id);
            } else {
                throw new Error("Invalid arguments");
            }
        } catch (error) {
            dispatch(handleError(error));
            console.error("error", error);
        }
    };
};
