import {
    NEWS_ISLOADING,
    NEWS_COMMUNITYFILTER_SET
} from "../../../../actions/constants";

const initialState = {
    errors: [],
    isLoading: false,
    communityFilter: 0
};

function newsReducer(state = initialState, action) {
    switch (action.type) {
        case NEWS_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case NEWS_COMMUNITYFILTER_SET: {
            const _state = {
                ...state
            };
            _state.communityFilter = action.payload.communityFilter;
            return _state;
        }
        
        
        default:
        return state;
    }
}

export default newsReducer;
