import styled from "styled-components";

export const selectStyle = {
    container: (styles, { isFocused, selectProps: { error }}) => ({
        ...styles,
        width: '100%',
        border: `1px solid ${isFocused ? (error ? '#DC3545' : '#057AFF') : (error ? '#DC3545' : '#929292')}`,
        borderRadius: '4px',
        boxShadow: isFocused ? (error ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : '0 0 0 0.25rem rgb(13 110 253 / 25%)') : ''
    }),
    control: (styles) => ({
        ...styles,
        width: '100%',
        border: 'none',
        height: '50px',
        fontSize: '16px',
        boxShadow: 'none',
        caretColor: 'transparent',
    }),
    option: (styles, { isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isSelected
            ? '#057AFF'
            : isFocused
            ? '#057AFF'
            : 'transparent',
        color: isSelected || isFocused ? 'white' : 'black',
        fontWeight: isSelected ? 'bold' : 'normal',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#057AFF'
    }),
    indicatorSeparator: (styles) => ({
        display: 'none',
    }),
};

export const MainText = styled.div`
    color: #002954;
    font-size: 18px;
    font-weight: 700;
`;

export const StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    @media screen and (min-width: ${({ theme }) => theme.breakpoints["0"]}) {
       margin-left: 20px;
       margin-right: 20px;
    }
    @media screen and (min-width: ${({ theme }) => theme.breakpoints["2"]}) {
        margin-left: 10em;
       margin-right: 10em;
    }

`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
    width: 100%;
`;

export const Button = styled.button`
    height: 40px;
    width: ${({ width }) => width ? width :  "94px"};
    background: #00A15F;
    color: white;
    padding: 8px 22px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
        background: #71BD9F;
    }

    &:active {
        background: #43705E;
    }

    &:disabled {
        background: #00A15F;
        cursor: not-allowed;
    }

    
`;