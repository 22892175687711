import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import LocationForm from "../../ReferralCompleteInformation/Location/view"
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../../Settings/common/Components";
import moment from "moment";
import DatePicker from "react-datepicker";
import PriceField from "../../ReferralCompleteInformation/RealStateDetails/Fields/_PriceField";
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

export const InputContainerVariation = styled.div`
 width: 100%;
 margin-bottom: ${({ theme }) => theme.mobileDevice ? "20px" : "40px"};
`;



export const TextLabel = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
 
`;

const CreationOptions = [
    { value: 'SENT', label: 'I created it.' },
    { value: 'RECEIVED', label: 'I received it.'}
];

const CurrentStatusOptions = [
    { value: 'AWARDED', label: 'Awarded' },
    { value: 'COMPLETED', label: 'Completed' },
];

const TimingInfo = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
}) => {
  
    const [openCompletionFields, setOpenCompletionFields] = useState(false)
    const key = "timingInfo_"
    const [isCollapsed, setIsCollapsed] = useState(false)
    const MaxPriceFieldSelector = 'amount';

    useState(() =>  { 
        if (values.pastReferralStatus.value === 'COMPLETED') setOpenCompletionFields(true)
        else setOpenCompletionFields(false)
    }, [values.pastReferralStatus.value])

    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Other Info
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <InputContainer
                    triggerShowLabel={
                        values.jobCreationDate ? "show" : "hide"
                    }
                    showLabelOnValue
                >
                    <DatePicker
                        tabIndex="0"
                        placeholderText={"Last Day of Contact*"}
                        selected={
                            values.jobCreationDate
                                ? moment(
                                        values.jobCreationDate
                                    ).toDate()
                                : ""
                        }
                        onChange={date =>
                            handleChange({
                                target: {
                                    name: "jobCreationDate",
                                    value: date,
                                }
                            })
                        }
                        required
                        showYearDropdown
                        dateFormat="MM/dd/yyyy"
                        customInput={
                            <Input
                                error={!!errors.jobCreationDate}
                                style={{ width: "calc(100% - 16px)" }}
                                value={
                                    values.jobCreationDate
                                        ? moment(
                                                values.jobCreationDate
                                            ).format("L")
                                        : null
                                }
                            />
                        }
                        name={"jobCreationDate"}
                        maxDate={new Date()}
                    />
                    <InputLabel>When was this job created?*</InputLabel>
                </InputContainer>
                <SelectContainer>
                    <Select
                        options={CreationOptions}
                        onChange={option => {
                            handleChange({
                                target: {
                                    name: "creationOption",
                                    value: option
                                }
                            });
                        }}
                        styles={selectStyle}
                        value={values.creationOption}
                        isClearable
                        placeholder="Did you create or receive this job?*"
                        error={errors.creationOption}
                    />
                </SelectContainer>
                <SelectContainer>
                    <Select
                        options={CurrentStatusOptions}
                        onChange={option => {
                            handleChange({
                                target: {
                                    name: "pastReferralStatus",
                                    value: option
                                }
                            });
                        }}
                        styles={selectStyle}
                        value={values.pastReferralStatus}
                        isClearable
                        placeholder="What is the current status of this job?*"
                        error={errors.currentStatus}
                    />
                </SelectContainer>


            {(values.pastReferralStatus.value === 'COMPLETED') &&
                <>
                    <InputContainerVariation>
                        {/* <TextLabel>If revenue was generated please enter the amount</TextLabel> */}
                        <CheckBoxContainer>
                            <PriceField
                                width={1}
                                pr={2}
                                formValues={values}
                                onChange={handleChange}
                                errors={errors}
                                fieldSelector={MaxPriceFieldSelector}
                                label=' If revenue was generated please enter the amount.'

                            />

                        </CheckBoxContainer>
                    </InputContainerVariation>

                 



                </>

            }

            

            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: t("timingInfo") }}/>
            </ReactTooltip>
        </Box>
    );
}

export default TimingInfo;
