import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP],
        stepKey: OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP, data)),
        goToPastJob: () => dispatch(setStepData('PAST_JOB', 'PAST_JOB')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("opportunityType")(View));