import { useState } from "react";
import { useDispatch } from "react-redux";
import { saveRecommendationDraft } from "../../../../../redux/actions/creators/recommendationCreation";
import React from "react";
import { withTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import { Flex } from "rebass";
import PopupModal from "../../atoms/shared/PopupBlurModal";
import { Description, Title } from "../../atoms/shared/Text";
import { Icon } from "../../atoms/shared/Icon";
import SquareButton from "../../atoms/shared/Buttons/SquareButton2";

export const DraftModal = withTheme(
    withTranslation("opportunityCreation")(
        ({ showModal, setShowModal, modalMessage, t, theme }) => {
            return (
                <PopupModal
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                >
                    <Flex
                        id="contact-invite-success"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        textAlign="center"
                        p={40}
                    >
                        <Icon
                            icon="opportunity"
                            color={theme.blueMedium}
                            width="35px"
                            height="32px"
                        />
                        <Title
                            style={{
                                marginTop: "1em",
                                textAlign: "center"
                            }}
                        >
                            {t("success")}
                        </Title>
                        <Description
                            style={{
                                marginTop: "2em",
                                textAlign: "center"
                            }}
                        >
                            {t(modalMessage)}
                        </Description>
                        <Flex
                            alignContent="center"
                            justifyContent={"center"}
                            mt="40px"
                            width={[0.5, 0.5, 0.2, 0.2]}
                        >
                            <SquareButton
                                text="Done"
                                onClick={() => setShowModal(false)}
                                fontSize={"14px"}
                                small
                                bg={theme.shamRockGreen}
                            />
                        </Flex>
                    </Flex>
                </PopupModal>
            );
        }
    )
);

export const useDraft = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    function resetState() {
        setShowModal(false);
        setModalMessage("");
    }

    const saveDraft = async () => {
        resetState();
        setLoading(true);
        dispatch(saveRecommendationDraft())
            .then(() => {
                setShowModal(true);
                setModalMessage("draftSaveSuccess");
            })
            .catch(() => {
                setShowModal(true);
                setModalMessage("draftSaveError");
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        saveDraft,
        showModal,
        setShowModal,
        modalMessage
    };
};
