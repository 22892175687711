const SECTION_SHARE = 'SHARE';
const SECTION_PROFESSION_TYPE = 'PROFESSION_TYPE';
const SECTION_SELECT_CANDIDATES = 'SELECT_CANDIDATES';
const SECTION_HOW_TO_SEND = 'HOW_TO_SEND';
const SECTION_FORM = 'FORM';
const SECTION_REVIEW = 'REVIEW';
const SECTION_SIGNATORY = 'SIGNATORY';

const SECTIONS_ARRAY_POSITIONS = [
    SECTION_SHARE,
    SECTION_PROFESSION_TYPE,
    SECTION_SELECT_CANDIDATES,
    SECTION_HOW_TO_SEND,
    SECTION_FORM,
    SECTION_SIGNATORY,
    SECTION_REVIEW,
]

module.exports = {
    SECTION_SHARE,
    SECTION_PROFESSION_TYPE,
    SECTION_SELECT_CANDIDATES,
    SECTION_HOW_TO_SEND,
    SECTION_FORM,
    SECTION_REVIEW,
    SECTION_SIGNATORY,
    SECTIONS_ARRAY_POSITIONS
}