const identifyZohoUser = profile => {
   
    if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.visitor) {
        if (profile.name)
            window.$zoho.salesiq.visitor.name(
                `${profile.name} ${profile.lastname}`
            );
        if (profile.email) window.$zoho.salesiq.visitor.email(profile.email);
      
    }
    return;
};


const openChatWindow = (open) => {
    
    if (open && window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.floatwindow && window.$zoho.salesiq.floatwindow.open) {
        window.$zoho.salesiq.floatwindow.visible(open)
    }
    return;
};

const visibilityOptions = {
    show: 'show',
    hide: 'hide',
    delayinsecs: 'delayinsecs'
}
export {
    identifyZohoUser,
    openChatWindow,
    visibilityOptions
}