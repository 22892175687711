import React, { Fragment } from 'react'
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Link } from 'react-router-dom';
import { theme } from '../../../../../../theme';

export const UnverifiedEmailAlert = ({ emailIsNotVerified }) => (
    <Fragment>
            {emailIsNotVerified ? 
                <Link to={'/settings'}  style={{
                    textDecoration: 'none'
                }}>
                    <UnverifiedEmailContainer>
                        <Icon
                            icon="mail"
                            color={`#E56767`} width='18px' height='18px'
                        />
                        <AlertText underlineColor={theme.coolBlack} >Please verify your email</AlertText>
                    </UnverifiedEmailContainer>
                </Link>
                : <Fragment/>
            }
    </Fragment>
)


export const UnverifiedEmailContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    justify-content: center;
    text-decoration: none;
    //background-color: #fafafa;

`;

export const AlertText = styled.h6`
     font-style: normal;
     font-weight: 700;
     font-size: ${({ theme }) => (theme.mobileDevice) ? '12px' : '16px'};
     line-height: 24px;
     text-decoration: none;
     cursor: pointer;
     position: relative;
     color: #002954;
     &:after {
        content: "";
        position: absolute;
        bottom: ${({ underLineBottomDistance }) =>
            underLineBottomDistance || "-2px"};
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        background-color: ${({ theme, underlineColor }) => underlineColor};
        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }
`;