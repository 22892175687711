import {
    OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    OPPORTUNITY_FLOW_RESET,
    CURRENT_REFERRAL_LOADING,
    CURRENT_REFERRAL_SUCCESS,
    CURRENT_REFERRAL_ERROR,
    DID_STATUS_CHANGED,
    STATUS_HAS_CHANGE,
    STATUS_IS_LOADING,
    STATUS_CHANGE_ERROR,
    SAVE_COMPLETION_DATA
  } from "../../../../actions/constants";
  
import { COMPLETIONS_FLOW_STEPS } from "../../../../../components/ui/presentation/views/OpportunityCompletion/steps";
  
  const initialState = {
    currentStep: COMPLETIONS_FLOW_STEPS.CONFIRM_STATUS_CHANGE,
    completionData: {},
    statusChangeLoading: false,
    statusSuccessChanged: false,
    errorStatusChanging: false,
    didStatusChanged: false
  };
  
  function opportunityCompletionFlowReducer(state = initialState, action) {
    switch (action.type) {
      case OPPORTUNITY_FLOW_RESET:
        return initialState;
      case OPPORTUNITY_FLOW_SET_ACTIVE_STEP:
        const _state = {
          ...state,
          currentStep: action.payload.step,
        };
           return _state;
      case STATUS_IS_LOADING:
           return statusIsLoading(state, action)
      case DID_STATUS_CHANGED:
            return statusHasChanged(state, action.payload)
      case STATUS_HAS_CHANGE:
           return statusHasAlreadyChanged(state, action.payload)  
      case STATUS_CHANGE_ERROR:
           return statusChangeError(state, action.payload)
      case SAVE_COMPLETION_DATA:
           return saveCompletionData(state, action.payload)
  
      default:
        return state
    }
  }

  const statusIsLoading = ( state, action ) => {
    const _state = {
      ...state,
      statusChangeLoading: true
    }

    return _state
  }

  const statusChangeError = ( state, action ) => {
    const _state = {
      ...state,
      statusChangeLoading: false
    }

    return _state
  }
  
  const statusHasChanged = (state, action) => {
    const _state = {
      ...state,
      statusChangeLoading: false,
      didStatusChanged: true,
    }
  
    return _state
  }


  const statusHasAlreadyChanged = (state, action) => {
    const _state = {
        ...state,
        didStatusChanged: false,
      }
    
      return _state
  }
  
  
const saveCompletionData = (state, data) => {

  const _state = {
    ...state,
    completionData: {
      ...data
    }
  }

  return _state
}

  const saveStepData = (state, data) => {
    const _state = {
      ...state,
      completionData: {
        ...state.completionData,
        [state.currentStep]: data,
      }
    }
  
    return _state
  }
  

  
 
  
  

  

  


  

  

  

  



  
  const getNextStep = (currentStep, flow) => {
    const currentIndex = flow.findIndex((s) => s.code === currentStep);
    const nextIndex = (currentIndex + 1) % flow.length;
    return flow[nextIndex].code;
  };
  
  export default opportunityCompletionFlowReducer;
  