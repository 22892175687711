import React, { Component } from 'react';
import config from '../../../../../config';

export default class FirebaseExceptionHandler extends Component {
    constructor() {
        super();
        this.CONNECTION_DEFAULT_ERROR = "CONNECTION ERROR.";
    }
    presentation(message) {
        return (<div><h1>{message}</h1></div>);
    }
    render() {
        if (this.props.error) {
            const message = (config.env === "development") ?
                this.props.error.message :
                this.CONNECTION_DEFAULT_ERROR;
            return (this.presentation(message));
        } else {
            return (this.presentation(this.CONNECTION_DEFAULT_ERROR));
        }
    }
}
