/**
 * Class that handles community related functions
 *
 * @export
 * @class Community
 */
export default class Community {

    /**
     * Current two types of formats accepted for images.
     *
     * @static
     * @memberof Community
     */
    static allowedPostFilesImagesTypes = ["jpg", "png"];


    /**
     * Determine whether the user has liked the post.
     *
     * @static
     * @param {*} post Post to check.
     * @param {*} uid   User ID
     * @memberof Community
     */
    static postLikedFrom = (post, uid) => {
        return (
            post &&
            post.discussionRank &&
            post.discussionRank.upvotes_ &&
            post.discussionRank.upvotes_[uid]
        );
    };


    /**
     * Deprecated.
     *
     * @static
     * @param {*} id
     * @return {*} 
     * @memberof Community
     * @deprecated
     */
    static parseId(id) {
        return id ? id.split("@@@") : id;
    }


    /**
     * Handles down vote for a post logic.
     *
     * @static
     * @param {*} newPost Post to down vote.
     * @param {*} uid   User ID.
     * @memberof Community
     */
    static downVote = (newPost, uid) => {
        newPost["discussionRank"] = newPost["discussionRank"]
            ? { ...newPost["discussionRank"] }
            : {};
        let newUpvotes = {};
        let filteredKeys = Object.keys(
            newPost["discussionRank"]["upvotes_"]
        ).filter(key => key !== uid);
        filteredKeys.forEach(key => {
            newUpvotes[key] = true;
        });

        newPost["discussionRank"]["upvotes_"] = newUpvotes;
        return newPost;
    };


    /**
     * Handles up vote for a post logic.
     *
     * @static
     * @param {*} newPost Post to up vote.
     * @param {*} uid  User ID.
     * @memberof Community
     */
    static upvote = (newPost, uid) => {
        newPost["discussionRank"] = newPost["discussionRank"]
            ? { ...newPost["discussionRank"] }
            : {};
        newPost["discussionRank"]["upvotes_"] = newPost["discussionRank"][
            "upvotes_"
        ]
            ? { ...newPost["discussionRank"]["upvotes_"] }
            : {};
        newPost["discussionRank"]["upvotes_"][uid] = true;

        return newPost;
    };


    /**
     * Determine whether the user has liked the post.
     *
     * @static
     * @param {*} post Post to check.
     * @param {*} uid   User ID.
     * @memberof Community
     */
    static alreadyUpvoted = (post, uid) => {
      
        return (
            post["discussionRank"] &&
            post["discussionRank"]["upvotes_"] &&
            post["discussionRank"]["upvotes_"][uid]
        );
    };


    /**
     * Handles the reply to a post logic.
     *
     * @static
     * @param {*} newPost Post to reply.
     * @param {*} reply  Reply to post.
     * @memberof Community
     */
    static replyPost = (newPost, reply) => {
        newPost["_source"] = newPost["_source"]
            ? { ...newPost["_source"] }
            : {};
        newPost["_source"]["replies"] = newPost["_source"]["replies"]
            ? [...newPost["_source"]["replies"], { ...reply }]
            : [{ ...reply }];
        return newPost;
    };


    /**
     * Get the file extension from a file name.
     *
     * @static
     * @param {*} fileName File name.
     * @return {String} File extension.
     * @memberof Community
     */
    static getFileExtension(fileName) {
        return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    }


    /**
     * Check whether the file to upload is a valida image format.
     *
     * @static
     * @param {*} fileName File name.
     * @return {Boolean} True if the file is a valid image format.
     * @memberof Community
     */
    static validPostFile(fileName) {
        const ext = this.getFileExtension(fileName);
        return this.allowedPostFilesImagesTypes.includes(ext);
    }


    /**
     * Formats post 
     *
     * @static
     * @param {*} post Post to format.
     * @return {*} Post formatted.
     * @memberof Community
     */
    static formatFirebasePostToElastic(post){
        let newPost = {...post}
        if(post.images){
            newPost.images = Object.keys(post.images).map(key => {return {id: key, uri: post.images[key]}})
        } else post.images = []
        
        if(post.tags){
            newPost.tags = Object.keys(post.tags).map(key => post.tags[key])
        }
        /* if(post.replies){

            const reply = {
                author: {
                    uid: me.uid,
                    user_type: type,
                    company_name: company_info ? company_info.name : "",
                    first_name: name,
                    last_name: lastname,
                    location: location,
                    avatar: avatar
                },
                created_at: Date.now() / 1000,
                body: newBody
            };

            newPost.replies = Object.keys(post.replies).map(key => {
                post.replies[key]
            })
        } */
        return newPost
    }
}
