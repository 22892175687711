import {
    COMMUNITY_FEATUREDPOSTSUCCESS,
    COMMUNITY_ISLOADING,
    CLEAN_COMMUNITY,
    COMMUNITY_NEWESTPOSTSSUCCESS,
    COMMUNITY_NEW_NEWS_NOTIFICATION,
    COMMUNITY_ADD,
    COMMUNITY_OPEN_IMAGES_CAROUSEL,
    COMMUNITY_DISCUSSION_RANK,
    COMMUNITY_UPVOTE,
    COMMUNITY_DOWNVOTE,
    COMMUNITY_FETCHPOST_SUCCESS,
    COMMUNITY_REPLY_POST_SUCCESS,
    COMMUNITY_UPDATE
} from "../../actions/constants";
import CommunityBussiness from "../../../business/community";
const initialState = {
    isLoading: false,
    detailedPost: {},
    featuredPost: {},
    newestPosts: [],
    hasMore: true,
    errors: [],
    newNewsNotification: false,
    imagesCarousel: {
        open: false,
        images: []
    }
};

function communityReducer(state = initialState, action) {
    switch (action.type) {
        case COMMUNITY_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case COMMUNITY_FEATUREDPOSTSUCCESS: {
            const _state = {
                ...state
            };
            _state.featuredPost = { ...action.payload.featuredPost };
            return _state;
        }
        case COMMUNITY_FETCHPOST_SUCCESS: {
            const _state = {
                ...state
            };
            _state.detailedPost = { ...action.payload.post };
            return _state;
        }
        case COMMUNITY_NEWESTPOSTSSUCCESS: {
            const _state = {
                ...state
            };
            _state.newestPosts = [
                //   ..._state.newestPosts,
                ...action.payload.newestPosts
            ];
            _state.hasMore = action.payload.hasMore;
            return _state;
        }
        case COMMUNITY_ADD: {
            const _state = {
                ...state
            };
            _state.newestPosts = [action.payload.post, ..._state.newestPosts];
            return _state;
        }
        case COMMUNITY_UPDATE: {
            const _state = {
                ...state
            };
            const postIndex = _state.newestPosts.map(post => post._id).indexOf(action.payload.postId)
            _state.newestPosts[postIndex]._source = {
                ..._state.newestPosts[postIndex]._source,
                title: action.payload.post.title,
                text: action.payload.post.text,
                visibility: action.payload.post.visibility ? Object.keys(action.payload.post.visibility) : null,
                images: action.payload.post.images ? action.payload.post.images : null,
                tags: action.payload.post.tags ? action.payload.post.tags : null
            }
            return _state;
        }
        case CLEAN_COMMUNITY: {
            return Object.assign(
                {
                    ...state
                },
                initialState
            );
        }
        case COMMUNITY_OPEN_IMAGES_CAROUSEL: {
            const _state = {
                ...state
            };
            _state.imagesCarousel = {
                open: action.payload.open,
                images: [...action.payload.images]
            };
            return _state;
        }
        case COMMUNITY_DISCUSSION_RANK: {
            const _state = {
                ...state
            };
            _state.detailedPost =
                _state.detailedPost._id === action.payload.postId
                    ? {
                        ..._state.detailedPost,
                        discussionRank: action.payload.discussionRank
                    }
                    : _state.detailedPost;

            _state.featuredPost =
                _state.featuredPost._id === action.payload.postId
                    ? {
                        ..._state.featuredPost,
                        discussionRank: action.payload.discussionRank
                    }
                    : _state.featuredPost;

            /* _state.newestPosts = _state.newestPosts.map(post =>
                post._id === action.payload.postId
                    ? { ...post, discussionRank: action.payload.discussionRank }
                    : post
            ); */
            const postIndex = _state.newestPosts.findIndex(p => p._id === action.payload.postId)

            if (postIndex !== -1) {
                const newP = { ..._state.newestPosts[postIndex], discussionRank: action.payload.discussionRank }
                _state.newestPosts[postIndex] = newP

            }
            return _state;
        }
        case COMMUNITY_UPVOTE: {
            const _state = {
                ...state
            };
            _state.detailedPost =
                _state.detailedPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.upvote(
                            _state.detailedPost,
                            action.payload.uid
                        )
                    }
                    : _state.detailedPost;
            _state.featuredPost =
                _state.featuredPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.upvote(
                            _state.featuredPost,
                            action.payload.uid
                        )
                    }
                    : _state.featuredPost;

            /* _state.newestPosts = _state.newestPosts.map(post => {
                let newPost = { ...post };
                if (post._id === action.payload.postId) {
                    newPost = CommunityBussiness.upvote(
                        newPost,
                        action.payload.uid
                    );
                }
                return newPost;
            }); */

            const postIndex = _state.newestPosts.findIndex(p => p._id === action.payload.postId)

            if (postIndex !== -1) {
                const newP = CommunityBussiness.upvote(
                    { ..._state.newestPosts[postIndex] },
                    action.payload.uid
                );
                _state.newestPosts[postIndex] = newP

            }

            return _state;
        }

        case COMMUNITY_DOWNVOTE: {
            const _state = {
                ...state
            };
            _state.detailedPost =
                _state.detailedPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.downVote(
                            _state.detailedPost,
                            action.payload.uid
                        )
                    }
                    : _state.detailedPost;
            _state.featuredPost =
                _state.featuredPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.downVote(
                            _state.featuredPost,
                            action.payload.uid
                        )
                    }
                    : _state.featuredPost;
           /*  _state.newestPosts = _state.newestPosts.map(post => {
                let newPost = { ...post };
                if (post._id === action.payload.postId)
                    newPost = CommunityBussiness.downVote(
                        newPost,
                        action.payload.uid
                    );
                return newPost;
            }); */

            const postIndex = _state.newestPosts.findIndex(p => p._id === action.payload.postId)

            if (postIndex !== -1) {
                const newP = CommunityBussiness.downVote(
                    { ..._state.newestPosts[postIndex] },
                    action.payload.uid
                );
                _state.newestPosts[postIndex] = newP

            }

            return _state;
        }

        case COMMUNITY_REPLY_POST_SUCCESS: {
            const _state = {
                ...state
            };
            _state.detailedPost =
                _state.detailedPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.replyPost(
                            _state.detailedPost,
                            action.payload.reply
                        )
                    }
                    : _state.detailedPost;
            _state.featuredPost =
                _state.featuredPost._id === action.payload.postId
                    ? {
                        ...CommunityBussiness.replyPost(
                            _state.featuredPost,
                            action.payload.reply
                        )
                    }
                    : _state.featuredPost;
            _state.newestPosts = _state.newestPosts.map(post => {
                let newPost = { ...post };
                if (post._id === action.payload.postId)
                    newPost = CommunityBussiness.replyPost(
                        newPost,
                        action.payload.reply
                    );
                return newPost;
            });
            return _state;
        }
        case COMMUNITY_NEW_NEWS_NOTIFICATION: {
            const _state = {
                ...state
            };
            _state.newNewsNotification = action.payload.turnOnNewsNotification;
            return _state;
        }
        default:
            return state;
    }
}

export default communityReducer;
