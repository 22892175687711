import React, { useEffect } from "react";
import styled from "styled-components";
import {
    MainText,
    StepWrapper,
    Button,
    ButtonContainer
} from "../../components";
import Card from "../../../../atoms/shared/Card2";
import { Box, Flex } from "rebass";
import { Title } from "../../../../atoms/shared/Text";
import Avatar from "../../../../atoms/shared/AvatarCircle2";
import {
    DataTitle,
    DataValue,
    Desc,
    DataPlan
} from "../../../ReferralInformation/GeneralInfo/styles";
import { Status } from "../../../ReferralInformation/referralInformation-styles";
import moment from "moment";
import { withTheme } from "styled-components";
import {
    CardText,
    CardTitle,
    CardDetail,
    AvatarContainer
} from "../../../ReferralInformation/Overview/SendingParty/referralCard-styles";
import {useDraft, DraftModal} from "../../useDraft";
import Spinner from "../../../../../../../assets/svg/Spinner";
import { ErrorMessage, SuccesReferralSent  } from './SuccessReferral_'
import { PopupLoader } from "../../../../atoms/shared/PopupLoader";
import { extractCandidateData } from "../../../../../../../libs/Utils";
import { CandidatesView, CandidateCardContainer } from "../../../OpportunityCreation/Steps/Preview/CandidateCard/CandidatePreview.styles";
import { CandidateCardView , CandidateTabs } from "../../../OpportunityCreation/Steps/Preview/CandidateCard";
import { getMembersAndContactsFromCandidates } from "../../../../../../../redux/actions/creators/opportunityCreationFlow";

const Container = styled(StepWrapper)`
    display: flex;
    flex-direction: column;
    margin: 2em 0 0 0;
`;

const FooterContainer = styled(ButtonContainer)`
    justify-content: space-between;
`;

const Spacer = styled.div`
    margin: 1em 0;
`;

const parseRecommendationRequestInformation = info => {
    if (!info || Object.values(info).length === 0) {
        return {
            profession: "",
            location: "",
            description: ""
        };
    }

    const {
        profession,
        address,
        cannotFindProfession,
        professionDescription,
        locationIsRemote,
        description
    } = info;

    const professionLabel = cannotFindProfession
        ? professionDescription
        : profession.label;

    const locationLabel =
        locationIsRemote && locationIsRemote.value === "PARTICULAR"
            ? address.description
            : "Remote";

    return {
        profession: professionLabel,
        location: locationLabel,
        description
    };
};

const RecommendationRequestPreview = ({
    recommendationRequestInformation,
    recommendationDirectInformation,
    sendRecommendationAction,
    errorReferral,
    successReferral,
    referralIsLoading, 
    candidates
}) => {
    const {
        profession,
        location,
        description
    } = parseRecommendationRequestInformation(recommendationRequestInformation);

    const {
        loading,
        saveDraft,
        showModal,
        setShowModal,
        modalMessage
    } = useDraft();

    const {
        receiver,
        descriptionDirect,
        shareInfoBetweenContacts
    } = recommendationDirectInformation;

    const isDirectRecommendation =
        Object.values(recommendationDirectInformation).length > 0
            ? true
            : false;

    const opType = "Recommendation";

    let { members: selectedCandidate, contacts } = getMembersAndContactsFromCandidates(candidates);
    console.log('SELECTED CANDIDATE', selectedCandidate, contacts)

    return (
        <Container>
            <DraftModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalMessage={modalMessage}
            />
            <MainText>Please review your recommendation request</MainText>
            <Spacer>
                <Card
                    borderRadius={"8px 8px 8px 8px"}
                    status="PENDING"
                    padding="30px"
                >
                    <Title>General Information</Title>
                    <Box mt={["1.5em"]}>
                        <Flex
                            alignItems="flex-start"
                            flexDirection={["column", "column", "row", "row"]}
                            flexWrap="wrap"
                        >
                            <Box width={[1, 1, 0.33, 0.33]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Method:</DataTitle>
                                    <DataValue>{opType}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Visibility:</DataTitle>
                                    <DataValue>
                                        {isDirectRecommendation
                                            ? "Direct"
                                            : "Open"}
                                    </DataValue>
                                </Flex>
                                {!isDirectRecommendation && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Looking For:</DataTitle>
                                        <DataValue>{profession}</DataValue>
                                    </Flex>
                                )}
                                {!isDirectRecommendation && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>Location:</DataTitle>
                                        <DataValue>{location}</DataValue>
                                    </Flex>
                                )}
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Created:</DataTitle>
                                    <DataValue>{`${moment(new Date()).format(
                                        "MMMM Do YYYY"
                                    )}`}</DataValue>
                                </Flex>
                                {isDirectRecommendation && (
                                    <Flex width={[1]} mb={[".5em"]}>
                                        <DataTitle>
                                            Share Client Info:
                                        </DataTitle>
                                        <DataValue>
                                            {shareInfoBetweenContacts
                                                ? "Yes"
                                                : "No"}
                                        </DataValue>
                                    </Flex>
                                )}
                            </Box>
                            <Box width={[1, 1, 0.33, 0.33]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Code:</DataTitle>
                                    <DataValue>To be defined</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Status:</DataTitle>
                                    <Status statusColor={"PENDING"}>
                                        Available
                                    </Status>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt={["1.5em"]}>
                        <Flex width={[1]} mb={[".5em"]}>
                            <DataValue>
                                {isDirectRecommendation
                                    ? descriptionDirect
                                    : description}
                            </DataValue>
                        </Flex>
                    </Box>
                </Card>
            </Spacer>
            {candidates.length > 0 && (
                <CandidatesView>
                    <CandidateTabs />
                    <CandidateCardContainer>
                        {selectedCandidate.map(eachCandidate => (
                            <CandidateCardView
                                avatar={eachCandidate.avatar}
                                address={eachCandidate.address}
                                firstName={eachCandidate.first_name}
                                lastName={eachCandidate.last_name}
                                email={eachCandidate.email}
                            />
                        ))}
                        {contacts.map(eachCandidate => (
                            <CandidateCardView
                                avatar={eachCandidate.avatar}
                                // address={eachCandidate.address}
                                email={eachCandidate.email}
                                firstName={eachCandidate.first_name}
                                lastName={eachCandidate.last_name}
                            />
                        ))}
                    </CandidateCardContainer>
                </CandidatesView>
            )}
            <FooterContainer>
                <DataValue>
                    By clicking submit, you agree to our{" "}
                    <a
                        href="https://site.nuop.com/end-user-license-agreement/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        End User License Agreement
                    </a>
                    , including but not limited to, the "Opportunities" section.
                </DataValue>
                <Box mr={16}>
                    <Button
                        width="120px"
                        onClick={() => saveDraft(true)}
                        disabled={loading}
                    >
                        Save Draft
                    </Button>
                </Box>
                {referralIsLoading ?
                  <Button>
                      <Spinner color={'white'} width="14px" height="14px" />
                  </Button>
                  :
                  <Button onClick={() => sendRecommendationAction()}>
                      Submit
                  </Button>
                }
                
            </FooterContainer>
            { referralIsLoading && <PopupLoader />  }
            {successReferral && <SuccesReferralSent isDirectRecommendation={isDirectRecommendation} />}
            {errorReferral &&  <ErrorMessage />}
            
        </Container>
    );
};

export default RecommendationRequestPreview;
