import React, { useState } from "react";
import styled from "styled-components";
import { MainText, StepWrapper, selectStyle, Button, ButtonContainer } from "../../../components";
import { ReferralPrivacyOptions, REFERRAL_PRIVACY } from "../../../constants";
import Select from 'react-select';
import { Icon } from "../../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import * as yup from 'yup'
import { Formik } from "formik";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5em;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const ClickableLabel = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #057AFF;
    margin-left: 6px;
`;

const BottomLabel = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
    margin: 1em 0;
`;

const JobType = {
    CONTRACTOR: 'CONTRACTOR',
    EMPLOYEE: 'EMPLOYEE',
};

const ContractorType = {
    ONEOFF: 'ONEOFF',
    SHORTTERM: 'SHORTTERM',
    LONGTERM: 'LONGTERM'
};

const EmployeeType = {
    PARTTIME: 'PARTTIME',
    FULLTIME: 'FULLTIME',
};

const EmployeeTypeOptions = [
    { value: EmployeeType.PARTTIME, label: 'Part-time employee' },
    { value: EmployeeType.FULLTIME, label: 'Full-time employee' },
];

const ContractorTypeOptions = [
    { value: ContractorType.ONEOFF, label: 'One-off' },
    { value: ContractorType.SHORTTERM, label: 'Short-term - less than 6 months'},
    { value: ContractorType.LONGTERM, label: 'Long-term - more than 6 months'},
];

const JobTypeOptions = [
    { value: JobType.CONTRACTOR, label: 'Contractor'},
    { value: JobType.EMPLOYEE, label: 'Employee' }
];

const formInitialValues = {
    jobType: null,
    contractorType: null,
    employeeType: null,
};

const formSchema = yup.object().shape({
    jobType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),
    contractorType: yup.object().nullable().when('jobType', {
        is: (jobType) => jobType && jobType.value === JobType.CONTRACTOR,
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }),
    }),
    employeeType: yup.object().nullable().when('jobType', {
        is: (jobType) => jobType && jobType.value === JobType.EMPLOYEE,
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }),
    }),
});

const JobCurrentType = ({
    setStepData,
    stepData,
    goToPastJob,
    t,
}) => {
    const title = t("jobTypeStepTitle")
    const info = t("jobTypeStepInfo")

    const handlePastJob = () => {
        goToPastJob();
    };

    return (
        <StepWrapper>
            <Wrapper>
                <MainText>{ title }</MainText>
                <InfoIconContainer>
                    <Icon
                        icon="info"
                        color="#057AFF"
                        size="md"
                        data-tip
                        data-for={'jobTypeStepInfo'}
                    />
                </InfoIconContainer>
            </Wrapper>
            <Formik
                initialValues={stepData ?  stepData : formInitialValues}
                onSubmit={(values) => setStepData(values)}
                validationSchema={formSchema}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Container>
                    <SelectContainer>
                        <Select
                            options={JobTypeOptions}
                            onChange={option => {
                                handleChange({
                                    target: {
                                        name: "jobType",
                                        value: option,
                                    }
                                })
                            }}
                            styles={selectStyle}
                            value={values.jobType}
                            isClearable
                            placeholder="Select...*"
                            error={!!errors.jobType}
                        />
                    </SelectContainer>
                     <BottomLabel> 
                      {/*   <ClickableLabel onClick={handlePastJob} >click here</ClickableLabel> */}
                    </BottomLabel> 
                    { (values.jobType && values.jobType.value === JobType.CONTRACTOR) && (
                        <SelectContainer>
                            <Select
                                options={ContractorTypeOptions}
                                onChange={option => {
                                    handleChange({
                                        target: {
                                            name: "contractorType",
                                            value: option,
                                        }
                                    })
                                }}
                                styles={selectStyle}
                                value={values.contractorType}
                                isClearable
                                placeholder="Contractor type*"
                                error={!!errors.contractorType}
                            />
                        </SelectContainer>
                    )}
                    { (values.jobType && values.jobType.value === JobType.EMPLOYEE) && (
                        <SelectContainer>
                            <Select
                                options={EmployeeTypeOptions}
                                onChange={option => {
                                    handleChange({
                                        target: {
                                            name: "employeeType",
                                            value: option,
                                        }
                                    })
                                }}
                                styles={selectStyle}
                                value={values.employeeType}
                                isClearable
                                placeholder="Employee type*"
                                error={!!errors.employeeType}
                            />
                        </SelectContainer>
                    )}
                    <ButtonContainer>
                        <Button
                            onClick={handleSubmit}
                            disabled={false}
                            type='submit'
                        >
                            Next
                        </Button>
                    </ButtonContainer>
                    </Container>
                )}
            </Formik>
            <ReactTooltip
                id={'jobTypeStepInfo'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
                clickable
            >
                <div dangerouslySetInnerHTML={{ __html: info }}/>
            </ReactTooltip>
        </StepWrapper>
    )
}

export default JobCurrentType;
