import styled from 'styled-components';
//import { getThemeColor, getThemeOnColor } from '../../../../../../../theme';

export const StatsItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
`

export const MobileSidebarLabel = styled.p`
    font-size: ${({ size }) => size === "small" ? "14px" : "14px"};
    font-weight: 600;
    color: ${props => props.theme.taupeGray};
    line-height: 16px;
    margin-left: 1em;
    margin-block-start: 0;
    margin-block-end: 0;
    color: #333333;
`

export const ViewProfileButton = styled.div`
    font-size: ${({ theme }) => theme.fonts.XS};
    font-weight: 700;
    color: ${({ theme }) => theme.newBlue};
    line-height: 24px;
`