module.exports = {
    env: process.env.NODE_ENV,
    enviroment: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION || 'x.x.x',
    firebaseConfig: {
        apiKey: process.env.REACT_APP_FB_API_KEY,
        authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
        projectId: process.env.REACT_APP_FB_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FB_APP_ID,
        measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
        vapiKey: process.env.REACT_APP_FB_VAPI_KEY
    },
    cfConfig: {
        phoneValidationBaseURL: process.env.REACT_APP_PHONE_VALIDATON_API_BASE_URI,
        baseURL: process.env.REACT_APP_CF_BASE_URL,
        timeout: process.env.REACT_APP_CF_TIMEOUT || 10000,
        apiRestUrl: process.env.REACT_APP_API_REST,
        searchApiRestUrl: process.env.REACT_APP_API_REST_SEARCH,
        emailApiURL: process.env.REACT_APP_VERIFICATION_EMAIL_URL,
        accountApiUrl: process.env.REACT_APP_ACCOUNT_URI,
        msTransactionalApi: process.env.REACT_APP_MS_TRANSACTIONAL,
        elasticPythonApiUrl: process.env.REACT_APP_ELASTIC_PYTHON_API_URL
    },
    httpConfig: {
        baseURL: process.env.API_BASE_URL,
        timeout: process.env.HTTP_TIMEOUT || 10000
    },
    google: {
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        timeout: process.env.REACT_APP_CF_TIMEOUT || 10000
    },
    stripe: {
        baseURL: process.env.REACT_APP_STRIPE_URL
    },
    oneSignal: {
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    },
    localStorage: {
        USER_PROFILE: 'user.profile(1.0)',
        IS_LOGGED_IN: 'isLoggedIn(1.0)',
        LAST_LOGGED_IN_USER: "LAST_LOGGED_IN_USER"
    },
    knockEnv: {
        feedChannelId:     process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID,
        knockPublicApiKey: process.env.REACT_APP_KNOCK_PUBLIC_API_KEY,
        knockSecretApi:    process.env.REACT_APP_KNOCK_SECRET_API_KEY
    },
    clarity: {
        projectId: process.env.REACT_APP_CLARITY_PROJECT_ID
    },

}