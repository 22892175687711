import styled from "styled-components";
//import { getThemeColor, getThemeOnColor } from '../../../../../../../theme';
import { Flex, Box } from "rebass";

export const BottomContainer = styled(Flex)`
    height: 9vh;
    width: 100%;
    border: 1px solid #ededed;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 0vh;
    z-index: 10001;
`;
export const NavbarItem = styled(Flex)`
    color: ${props =>
        props.selected ? props.theme.newBlue : props.theme.darkGray};
    height: 100%;
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    cursor: pointer;
    width: 20%;
    min-height: 46px;
`;

export const MoreOptionsContainer = styled(Box)`
    -webkit-filter: drop-shadow(0px 4px 3px rgba(5, 122, 255, 0.4));
    filter: drop-shadow(0px 4px 3px rgba(5, 122, 255, 0.4));
    cursor: pointer;
`;

export const MoreOptionsMenuCard = styled(Flex)`
    min-height: 38.77vh;
    width: 100%;
    border-radius: 30px 30px 0 0;
    background-color: #ffffff;
    position: fixed;
    outline: none;
    /* bottom: 11.33vh; */
    bottom: -0vh;
    z-index: 10000;
    padding-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 4.5em;
    transition: transform .5s;
    /* transform: ${props =>
        props.open ? `translateY(-38.77vh)` : `translateY(38.77vh)`} */
    transform: ${props =>
        props.open ? `translateY(0)` : `translateY(55vh)`}
`;

export const MoreOptionsItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
    background-color: #ffffff;
    -webkit-box-shadow: 0 2px 10px 2px rgba(69, 69, 69, 0.07);
    -moz-box-shadow: 0 2px 10px 2px rgba(69, 69, 69, 0.07);

    box-shadow: 0 2px 10px 2px rgba(69, 69, 69, 0.07);
`;

export const MoreOptionsItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0.25em;
    margin-right: 0.25em;
    margin-bottom: 1em;
`;

export const MoreOptionsHeadLine = styled.div`
    height: 0.56vh;
    width: 29.07vw;
    opacity: 0.49;
    border-radius: 3px;
    background-color: ${props => props.theme.newBlue};
`;

export const HorizontalNameLine = styled.div`
    box-sizing: border-box;
    margin-top: 0.5em;
    border-top: ${props => `5px solid ${props.theme.caribbeanGreen}`};
    width: 1.5em;
`;

export const Modal = styled.div`
     /* Hidden by default */
    /*visibility: ${props =>
        props.open ? "auto" : "hidden"};  Hidden by default */
    position: fixed; /* Stay in place */
    z-index: ${({ open }) => open ? "9999" : "-5"};/* Sit on top */
    opacity: ${({ open }) => open ? "1" : "0"};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
`;