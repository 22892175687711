import React, { useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { CardContainer, Poligon, DropdownText } from "./navbarDropdown-style";
import { DropdownNavbarItem } from "../topNavbar-styles";
import Card from "../../Card";
import { Icon } from "../../Icon";
const NavbarDropdown = props => {
    const {
        theme,
        handleClickOutside,
        isOpen,
        userTypeValidForSendReferrals,
        userTypeValidForWriteArticles,
        onClick
    } = props;
    const wrapperRef = useRef(null);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (isOpen && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isOpen, wrapperRef.current]);

    const checkIfItemIsDisabled = item => {
        const text = item && item.text;
        switch (text && text.toUpperCase()) {
            case "OPPORTUNITY": 
                return !userTypeValidForSendReferrals;
            case "POST":
                return !userTypeValidForWriteArticles;
            default:
                return false;
        }
    }

    const getItemIcon = item => {
        const text = item && item.text;
        let optionIcon = false;
        const props = {
            color: item && item.selected ? theme.newBlue : theme.coolBlack
        };

        switch (text && text.toUpperCase()) {
           
            case "REFERRAL": {
                optionIcon = (
                    <Icon
                        icon="my-ops"
                        size="md"
                        {...props}
                        color={
                            userTypeValidForSendReferrals
                                ? props.color
                                : theme.greyLight2
                        }
                    />
                );
                break;
            }
            case "JOB": {
                optionIcon = (
                    <Icon
                        icon="my-ops"
                        size="md"
                        {...props}
                        color={
                            userTypeValidForSendReferrals
                                ? props.color
                                : theme.greyLight2
                        }
                    />
                );
                break;
            }
            case "INTRO": {
                optionIcon = (
                    <Icon
                        icon="my-ops"
                        size="md"
                        {...props}
                        color={
                            userTypeValidForSendReferrals
                                ? props.color
                                : theme.greyLight2
                        }
                    />
                );
                break;
            }
            case "POST": {
                optionIcon = (
                    <Icon
                        icon="news"
                        size="md"
                        {...props}
                        color={
                            userTypeValidForWriteArticles
                                ? props.color
                                : theme.greyLight2
                        }
                    />
                );
                break;
            }
            case "INVITATION": {
                optionIcon = <Icon icon="earn" size="md" {...props} />;
                break;
            }
            case "RECOMMENDATION": {
                optionIcon = <Icon icon="opportunity" size="md" {...props} />;
                break;
            }
            case "ENDORSEMENT": {
                optionIcon = <Icon icon="endorsement" size="md" {...props} />;
                break;
            }
            case "GRATITUDE": {
                optionIcon = <Icon icon="gratitude" size="md" {...props} />;
                break;
            }
            default:
                optionIcon = false;
                break;
        }
        return optionIcon;
    };


    return (
        <CardContainer
            // id="notifications-container"
            ref={wrapperRef}
            tabIndex={0}
            onBlur={handleClickOutside}
        >
            <Poligon />
            <Card
                padding={theme.spacing["6"]}
                ref={scrollRef}
                id={"scrollContainer"}
            >
                {props.items.map((item, index) => (
                    <DropdownNavbarItem
                        isFirst={index === 0}
                        isLast={index === props.items.length - 1}
                        onClick={() =>
                            onClick(item, checkIfItemIsDisabled(item))
                        }
                        disabled={checkIfItemIsDisabled(item)}
                    >
                        {getItemIcon(item)}
                        <DropdownText selected={item.selected}>
                            {item.text}
                        </DropdownText>
                    </DropdownNavbarItem>
                ))}
            </Card>
        </CardContainer>
    );
};

export default withRouter(withTheme(NavbarDropdown));
