import React, { useState, useEffect } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import NuopLogo from "../../../assets/img/NuOpRectangleTransparent.png";
import { ReactComponent as BackgroundGraphic } from '../../../assets/svg/SignInGraphic.svg'
import { withTheme } from "styled-components";
import Image from "../../ui/presentation/atoms/shared/Image";
import { Text } from '../../ui/presentation/atoms/shared/Text'
import { useLocation } from 'react-router-dom';
import SignUpRightContent from "./RightContent/SignUpRightContent";
import SignInKnowingUserRightContent from "./RightContent/SignInKnowingUserRightContent";
import SignInRightContent from "./RightContent/SignInRightContent";
import SignInFooter from "./Footer/SignInFooter";
import { scrollBarStyles } from "../../ui/presentation/atoms/shared/ScrollBar";
import RighthandLinks from "../../ui/presentation/atoms/shared/RighthandLinks";
import config from "../../../config";

const FlexBlueContainer = styled(Flex)`
    justify-content: flex-start;
    align-items: flex-start;
    min-height: calc(100vh + 75px);
    min-width: 100vw;
    max-height: ${props => props.theme.mobileDevice ? null : '100vh'};
    z-index: 9999;
    // position: absolute;
    top: 0px;
    left: 0px;
    background: ${props => props.theme.clearGray};
    box-sizing: border-box;
    ${scrollBarStyles}
`;

const RightContainer = styled(Flex)`
    // max-height: ${props => (props.theme.mobileDevice ? null : "100vh")};
    background: ${props => props.theme.coolBlack};
    // Old blue and green background
    //background: linear-gradient(198.7deg, #1FC07F 0.53%, #1D55B6 100%);
    min-height: ${props => (props.theme.mobileDevice ? null : "calc(100vh + 75px)")};
    box-sizing: border-box;
    position: relative;

    #background-graphic {
        position: absolute;
        right: -5%;
    }

    @media(max-width: 1440px) {
        #background-graphic {
            right: -20%;
        }
    }
    @media(max-width: 1200px) {
        #background-graphic {
            right: -40%;
        }
    }
    @media(max-width: 1050px) {
        #background-graphic {
            right: -60%;
        }
    }
    @media(max-width: 900px) {
        #background-graphic {
            right: -85%;
        }
    }
`;

const titles = {
    '/sign-in': 'Sign In',
    '/sign-up': 'Sign Up',
    '/reset-password': 'Reset Password'
}

const RIGHTSIDE_CONTENT = {
    SIGN_UP: "SIGN_UP",
    SIGN_IN: "SIGN_IN",
    SIGN_IN_KNOWING_USER: "SIGN_IN_KNOWING_USER",
}


const SigninLayout = (props) => {
    const location = useLocation();
    const { theme } = props
    const pathname = location.pathname.toLowerCase();
    const title = titles[pathname];

    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (searchParams.get('next')) {
            localStorage.setItem("comesFromUrl", searchParams.get('next'));
        }
    }, []);

    const lastLoggedInUser = JSON.parse(localStorage.getItem(config.localStorage.LAST_LOGGED_IN_USER))


    const RightSideContent = {
        [RIGHTSIDE_CONTENT.SIGN_UP]: SignUpRightContent,
        [RIGHTSIDE_CONTENT.SIGN_IN]: SignInRightContent,
        [RIGHTSIDE_CONTENT.SIGN_IN_KNOWING_USER]: SignInKnowingUserRightContent
    };

    const RightContentToRender = (() => {
        switch (pathname) {
            case "/sign-in":
            case "/sign-up":
                return RightSideContent[RIGHTSIDE_CONTENT.SIGN_UP];
            //INVITE_AND_EARN_REMOVAL: As of 6/6/23 Invite & Earn program has been disbled on the platform
            // case "/sign-in":
            //     return lastLoggedInUser && lastLoggedInUser.userName
            //         ? RightSideContent[RIGHTSIDE_CONTENT.SIGN_IN_KNOWING_USER]
            //         : RightSideContent[RIGHTSIDE_CONTENT.SIGN_IN];
            case "/reset-password":
                return RightSideContent[RIGHTSIDE_CONTENT.SIGN_UP];
            default:
                return RightSideContent[RIGHTSIDE_CONTENT.SIGN_IN];
        }
    })();

    const leftSubtitle = (() => {
        switch (pathname) {
            case "/sign-up":
                return "Get notified about referrals in your area.";
            case "/sign-in":
            case "/reset-password":
                return "";
            default:
                return "The Business Opportunity Exchange™";
        }
    })();

    return (
        <Flex id="body" flexDirection={"column"}>
            <FlexBlueContainer
                flexDirection={["column", "column", "row", "row"]}
                flexWrap={["wrap", "wrap", "nowrap", "nowrap"]}
            >
                <Flex
                    flexDirection="column"
                    justifyContent={"space-between"}
                    p={[
                        theme.spacing["5"],
                        theme.spacing["5"],
                        theme.spacing["10"],
                        theme.spacing["10"]
                    ]}
                    pb={[
                        theme.spacing["10"],
                        theme.spacing["10"],
                        theme.spacing["20"],
                        theme.spacing["20"]
                    ]}
                    width={[1, 1, 0.51, 0.51]}
                    style={{
                        minHeight: theme.mobileDevice ? null : "100vh",
                        boxSizing: "border-box"
                    }}
                >
                    <Flex
                        width={[1]}
                        justifyContent={[
                            "center",
                            "center",
                            "flex-start",
                            "flex-start"
                        ]}
                    >
                        <a href="https://nuop.com" target={"_blank"}>
                            <Image
                                alt="NuOp"
                                src={NuopLogo}
                                width={theme.mobileDevice ? "108px" : "124px"}
                                height={theme.mobileDevice ? "47.28px" : "54.3px"}
                            />
                        </a>
                    </Flex>

                    <Flex
                        mt={[
                            theme.spacing["4"],
                            theme.spacing["4"],
                            theme.spacing["8"],
                            theme.spacing["8"]
                        ]}
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="flex-start"
                        width={[1]}
                        flexWrap="nowrap"
                    >
                        <Text
                            fontSize={theme.mobileDevice ? "L" : "XL"}
                            fontWeight="bold"
                            color={theme.coolBlack}
                        >
                            {title}
                        </Text>
                        {leftSubtitle && 
                        <Text
                            style={{
                                marginTop: theme.mobileDevice
                                    ? theme.spacing["1"]
                                    : theme.spacing["4"]
                            }}
                            fontSize={theme.mobileDevice ? "XXS" : "XS"}
                            fontWeight="light"
                            color={theme.taupeGray}
                        >
                            {leftSubtitle}
                        </Text>}
                        <Box
                            width={[1]}
                            mt={[
                                theme.spacing["6"],
                                theme.spacing["6"],
                                theme.spacing["8"],
                                theme.spacing["8"]
                            ]}
                        >
                            {props.children}
                        </Box>
                    </Flex>
                    <span></span>
                </Flex>
                {!theme.mobileDevice && (
                    <RightContainer
                        width={[1, 1, 0.49, 0.49]}
                        pr={[
                            theme.spacing["5"],
                            theme.spacing["5"],
                            theme.spacing["20"],
                            theme.spacing["20"]
                        ]}
                        pl={[
                            theme.spacing["5"],
                            theme.spacing["5"],
                            theme.spacing["20"],
                            theme.spacing["20"]
                        ]}
                        pt={
                            (theme.spacing["32"],
                            theme.spacing["32"],
                            theme.spacing["12"],
                            theme.spacing["12"])
                        }
                        flexDirection="column"
                        alignItems={["center"]}
                        justifyContent={["center"]}
                    >
                        <BackgroundGraphic id="background-graphic" />

                        <Box
                            width={[1]}
                            // mt={"-70px"}
                            style={{ textAlign: "center", zIndex: 2 }}
                        >
                            <RightContentToRender
                                lastLoggedInUser={lastLoggedInUser}
                            />
                        </Box>
                        {/* <RighthandLinks isSigninPage /> */}
                    </RightContainer>
                )}
            </FlexBlueContainer>
            {theme.mobileDevice ? (
                <RighthandLinks isSigninPage />
            ) : (
                <SignInFooter />
            )}
        </Flex>
    );
}
export default withTheme(SigninLayout);
