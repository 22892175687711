import { getMembersAndContactsFromCandidates } from '.';
import { cleaningObject, convertToTimeStamp  } from '../../../../libs/Utils';
import {
  CURRENT_REFERRAL_LOADING,
  CURRENT_REFERRAL_SUCCESS,
  CURRENT_REFERRAL_ERROR,
} from '../../constants';



export const sendingPastJobData = () => {
    return async(dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({ type: CURRENT_REFERRAL_LOADING })
            const meId =         getState().main.user.uid
            const { stepData, draftId } = getState().main.ui.view.opportunityCreationFlow;
            const {  
                JOB_PAST_COMPLETE_INFORMATION,
                CANDIDATES_SELECTION,
            } = stepData;
        
             // Get all the members and contacts
             const { members: toMembers, contacts: toContacts } = getMembersAndContactsFromCandidates(CANDIDATES_SELECTION, true);
  
            const locationData = {
                coords:                (JOB_PAST_COMPLETE_INFORMATION.address) ? [JOB_PAST_COMPLETE_INFORMATION.address.lat, JOB_PAST_COMPLETE_INFORMATION.address.lng] : [],
                fullAddress:           JOB_PAST_COMPLETE_INFORMATION.address ? JOB_PAST_COMPLETE_INFORMATION.address.description: '',
                state:                 JOB_PAST_COMPLETE_INFORMATION.address ? JOB_PAST_COMPLETE_INFORMATION.address.state : "",
                stateShort:            JOB_PAST_COMPLETE_INFORMATION.address ? JOB_PAST_COMPLETE_INFORMATION.address.stateShort : "",
                subAdministrativeArea: JOB_PAST_COMPLETE_INFORMATION.address ? JOB_PAST_COMPLETE_INFORMATION.address.subAdministrativeArea : "",
                zipcode:               JOB_PAST_COMPLETE_INFORMATION.address ? JOB_PAST_COMPLETE_INFORMATION.address.zipCode : "",
              }
  
              const isRemote = JOB_PAST_COMPLETE_INFORMATION.locationIsRemote.value !== 'PARTICULAR' ? true : false
             
  
            
              const payload = {
                type: "PAST",
                jobType: "CONTRACTOR",
                jobSubType: JOB_PAST_COMPLETE_INFORMATION.pastJobType.value,
                lastContact: convertToTimeStamp(JOB_PAST_COMPLETE_INFORMATION.jobCreationDate),
                location: !isRemote ? locationData : null,
                isRemote: isRemote,
                roleInOpportunity: JOB_PAST_COMPLETE_INFORMATION.creationOption.value,
                finalStatus: JOB_PAST_COMPLETE_INFORMATION.pastReferralStatus.value,
                description: JOB_PAST_COMPLETE_INFORMATION.description,
                visibility: true,
                from: meId,
                toMembers,
                toContacts,
                partyConfirmation: JOB_PAST_COMPLETE_INFORMATION.partyConfirmation
              }
  
  
              let response = await cloudfunctions.sendPastJob(cleaningObject(payload))
             if ( response.status === 200 &&  JOB_PAST_COMPLETE_INFORMATION.pastReferralStatus.value === 'COMPLETED') {
                 await cloudfunctions.updateRevenue({
                      opportunity_id: response.data.id,
                      opportunity_code: response.data.code,
                      profiter_user: meId,
                      receiver_user: toMembers.length > 0 ? toMembers[0] : toContacts[0],
                      amount: parseInt(JOB_PAST_COMPLETE_INFORMATION.amount.substring(1)),
                      opportunity_model: 'JOB',
                      status: "COMPLETED"
                 })
             }

            // Remove draft if it exists
          if (draftId) {
              await firebase.deleteDraftReferral(meId, draftId);
          }
  
             dispatch({ type: CURRENT_REFERRAL_SUCCESS })
        } catch(error) {
            dispatch({ type: CURRENT_REFERRAL_ERROR });
             console.error('job flow error', error)
        }
    }
  }
  