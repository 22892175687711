import styled from 'styled-components';

const fontSize = {
    'L': 'XS',
    'M': 'XXS',
    'S': 'XXS'
}

const fontWeight = {
    'L': 'bold',
    'M': 'medium',
    'S': 'medium'
}

const paddings = (theme, size) => size === 'L' ? `${theme.spacing['2']} ${theme.spacing['7']}`
    : size === 'M' ? `${theme.spacing['2']} ${theme.spacing['6']}`
        : size === 'S' ? `${theme.spacing['1']} ${theme.spacing['4']}` : `${theme.spacing['1']} ${theme.spacing['4']}`
export const Button = styled.button`
    all: unset;
    max-height: 40px;
    box-sizing: border-box;
    background: ${({ theme, type }) => type === 'PRIMARY' ? theme.shamRockGreen : type === 'BORDER' ? theme.white : 'transparent'};
    border: ${({ theme, type }) => `2px solid ${theme.shamRockGreen}`}; 
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: ${props => paddings(props.theme, props.size)};
    font-size: ${props => props.theme.fonts[fontSize[props.size]]};
    font-weight: ${props => props.theme.fontWeights[fontWeight[props.size]]};
    line-height: ${props => props.theme.lineHeights[fontSize[props.size]]};
    color: ${({ theme, type }) => type === 'PRIMARY' ? theme.white : type === 'BORDER' ? theme.shamRockGreen : theme.shamRockGreen};
    &:hover {
        color: ${({ theme, type }) => type === 'PRIMARY' ? theme.white : type === 'BORDER' ? theme.bottleGreen : theme.bottleGreen};
        background: ${({ theme, type }) => type === 'PRIMARY' ? theme.bottleGreen : type === 'BORDER' ? theme.white : 'transparent'};
        border: ${({ theme, type }) => `2px solid ${theme.bottleGreen}`};
    }

    &:disabled {
        color: ${({ theme, type }) => type === 'PRIMARY' ? theme.white : type === 'BORDER' ? theme.taupeGray : theme.taupeGray};
        background: ${({ theme, type }) => type === 'PRIMARY' ? theme.taupeGray : type === 'BORDER' ? theme.white : 'transparent'};
        border: ${({ theme, type }) => type === 'BORDER' ? `2px solid ${theme.taupeGray}` : 'none'};
    }
`;

export const ButtonIcon = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    width: 14px;
    max-width: 14px;
`;

export const ButtonText = styled.span`
    text-align: center;
`;
