import React, { useMemo } from 'react'
import { Flex } from 'rebass';
import {
    ClientTypesOptions,
    PropertyTypesOptions,
    CommercialOptions,
    ResidentialOptions,
    ResidentialExtendedOptions,
    LandOptions,
    retailSpaceOptions,
 } from '../../../../constants';
import SelectField from './_SelectField';
import { ClientTypeFieldSelector, PropertyTypeFieldSelector, PropertySubTypeFieldSelector } from './constants';

const RealEstateTypeField = ({
    formValues,
    onChange,
    errors,
    theme,
}) => {

    const PropertySubtypeOptions = useMemo(() => {
        if ( formValues.propertyType && formValues.propertyType.value === 'RESIDENTIAL' ) {
            if (formValues.clientType && ( formValues.clientType.value === 'LANDLORD' || formValues.clientType.value === 'TENANT'))
                return ResidentialOptions;
            else 
                return ResidentialExtendedOptions;
        }

        if ( formValues.propertyType && formValues.propertyType.value === 'LAND' )
            return LandOptions

        if ( formValues.propertyType && formValues.propertyType.value === 'COMMERCIAL' )
            return CommercialOptions

        return [];
    }, [formValues.clientType, formValues.propertyType]);

    const showPropertySubType = formValues.clientType && formValues.propertyType;

    const resetRealStateFields = () => {
        onChange({
            target: {
                name: 'maxPrice',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'minBeds',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'minSquareFeet',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'minLotSize',
                value: null,
            }
        })

        onChange({
            target: {
                name: 'financingInfo',
                value: null,
            }
        })

        onChange({
            target: {
                name: 'homeSaleContingency',
                value: null,
            }
        })

        onChange({
            target: {
                name: 'minUnits',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'minUnits',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'maxSquareFeet',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'maxUnits',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'credit',
                value: null,
            }
        })

        onChange({
            target: {
                name: 'pets',
                value: null,
            }
        })

        onChange({
            target: {
                name: 'estimatedPrice',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'beds',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'baths',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'squareFeet',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'units',
                value: '',
            }
        })

        onChange({
            target: {
                name: 'retailSpace',
                value: retailSpaceOptions[0],
            }
        })

        onChange({
            target: {
                name: 'acres',
                value: '',
            }
        })
    };

    return (
        <Flex mt={theme.spacing[3]} flexWrap='wrap'>
            <SelectField
                formValues={formValues}
                onChange={onChange}
                errors={errors}
                options={ClientTypesOptions}
                fieldSelector={ClientTypeFieldSelector}
                label='Your Role*'
                mt={theme.spacing[3]}
                onChangeSideAction={
                    () => {
                        onChange({
                            target: {
                                name: PropertyTypeFieldSelector,
                                value: null,
                            }
                        })
                        onChange({
                            target: {
                                name: PropertySubTypeFieldSelector,
                                value: null,
                            }
                        })
                        resetRealStateFields()
                    }
                }
            />
            <SelectField
                formValues={formValues}
                onChange={onChange}
                errors={errors}
                options={PropertyTypesOptions}
                fieldSelector={PropertyTypeFieldSelector}
                label='Property Type*'
                mt={theme.spacing[3]}
                onChangeSideAction={
                    () => {
                        onChange({
                            target: {
                                name: PropertySubTypeFieldSelector,
                                value: null,
                            }
                        })
                        resetRealStateFields()
                    }
                }
            />
            { showPropertySubType && (
                <SelectField
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                    options={PropertySubtypeOptions}
                    fieldSelector={PropertySubTypeFieldSelector}
                    label='Property Sub-Type*'
                    mt={theme.spacing[3]}
                    onChangeSideAction={
                        () => {
                            resetRealStateFields()
                        }
                    }
                />
            )}
        </Flex>
    );
};

export default RealEstateTypeField;
