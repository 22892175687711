import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";
const mapStateToProps = state => {
    const statics = state.main.ui.global.statics;
    const feeDueOptions = statics.referralFeePaymentDue;
    const feeTypesOptions = statics.referralFeeTypes;
    const feeFrequencyOptions = statics.referralFeeFrequency;
    const feeDurationOptions = statics.referralFeeDuration;
    const feeCommencementOptions = statics.referralFeeCommencement;
    return {
        feeDueOptions,
        feeTypesOptions,
        feeFrequencyOptions,
        feeDurationOptions,
        feeCommencementOptions
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
