import React from "react";
import { Flex } from "rebass";
import { theme } from "../../../../../../theme";

export const ContactAvatar = ({ name, lastName, size = "40px", fontSize = "20px", ...otherProps }) => {
    const initials = `${name && name[0].toUpperCase()}${lastName && lastName[0].toUpperCase()}`;

    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            style={{
                minWidth: size,
                height: size,
                fontSize,
                backgroundColor: theme.taupeGray,
                borderRadius: "50%",
                color: "white"
            }}
        >
            {initials}
        </Flex>
    );
}