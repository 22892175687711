import React, { useState, useEffect } from "react";
import styled from "styled-components";
//import mask from "../../../../../../assets/img/mask.jpg";
import CompanyIcon from "../../../../../../assets/svg/NuOpCircleGreen.svg";
import { Icon } from "../Icon";
import { withTheme } from "styled-components";
import GlobalBusiness from "../../../../../../business/global";
import Skeleton from "react-loading-skeleton";

const GenericAvatarWrapper = styled.div`
    cursor: ${props => (props.onClick ? "pointer" : "normal")};
    width: fit-content;
    position: relative;
`;

const Avatar = styled.div`
     box-sizing: border-box;
    width: ${props => props.size ? props.size : '100%'};
    height: ${props => props.size ? props.size : '100%'};
    ${props => `background: url('${props.src}') center;`}
    text-align: center;
    background-size: cover;
    border-radius: 50%; 
`;

const AvatarMask = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size ? props.size : '100%'};
    height: ${props => props.size ? props.size : '100%'};
    border-radius: 50%;
    overflow: hidden;
`;
const Badge2 = styled.div`
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    top:  ${props => (props.size ? `calc(${props.size}* 0.7)` : "0.5rem")};
    left: ${props => (props.size ? `calc(${props.size}* 0.7)` : "0.5rem")};
    border-radius: 50%;
`;

const BlueEPlus = styled.div`
    background-color: ${({ theme }) => theme.darkBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    width:  ${props => (props.size ? `calc(${props.size}* 0.3)` : "10px")};
    height: ${props => (props.size ? `calc(${props.size}* 0.3)` : "10px")};
    border-radius: 50%;
    color: ${({ theme }) => theme.white};
    font-weight: bold;
    font-size:  ${props => (props.size ? `calc(${props.size}* 0.15)` : "16px")};
`;

export default withTheme(props => {
    const [avatarUrl, setAvatarUrl] = useState("");

    const {
        src,
        isLoading,
        size,
        onClick,
        onMouseDown,
        verified,
        isEssentialPlus,
        imageQuality = "SMALL"
    } = props;
    const imageFound = () => { };
    const imageNotFound = () => {
        setAvatarUrl(props.src);
    };
    const testImage = URL => {
        var tester = new Image();
        tester.onload = imageFound;
        tester.onerror = imageNotFound;
        tester.src = URL;
    };

    useEffect(() => {
        const resizeImageUri = src
            ? GlobalBusiness.getResizedImageUrl(src, imageQuality)
            : CompanyIcon;
        testImage(resizeImageUri);
        setAvatarUrl(resizeImageUri);
    }, [src]);

    return isLoading ? (
        <Skeleton circle={true} height={size} width={size} />
    ) : <GenericAvatarWrapper onClick={onClick} onMouseDown={onMouseDown}>
        <AvatarMask size={size}>
            <Avatar src={avatarUrl} size={size} />
        </AvatarMask>
        {verified && (
            <Badge2
                size={size}
                justifyContent="center"
                alignItems="center"
            >

                <Icon
                    icon="check-filled"
                    width={props.size ? `calc(${props.size}* 0.3)` : 10}
                    height={props.size ? `calc(${props.size}* 0.3)` : 10}
                    color={props.theme.greenMedium}
                />
            </Badge2>
        )}
        {isEssentialPlus && (
            <Badge2
                size={size}
                justifyContent="center"
                alignItems="center"
                style={{ backgroundColor: "transparent" }}
            >
                <BlueEPlus size={size}>
                    E+
                </BlueEPlus>
            </Badge2>
        )}
        {!props.src && props.showBadged && (
            <Badge2
                size={props.size}
                justifyContent="center"
                alignItems="center"
            >
                <Icon
                    icon="camera"
                    width={props.size ? `calc(${props.size}* 0.4)` : "20px"}
                    height={props.size ? `calc(${props.size}* 0.4)` : "20px"}
                    color={props.theme.darkGray}
                />
            </Badge2>
        )}
    </GenericAvatarWrapper>
});