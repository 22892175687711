import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";
const mapStateToProps = state => {
    return {
        clientSourcesOptions:
            state.main.ui.global.statics.clientSources &&
            state.main.ui.global.statics.clientSources.options,
        clientStatusOptions:
            state.main.ui.global.statics.clientStatusTypes &&
            state.main.ui.global.statics.clientStatusTypes.options
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTheme(View));
