import {
    VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
    VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT,
    VALIDATE_PHONE_ON_EDIT_FAIL,
    VALIDATE_PHONE_ON_EDIT_SUCC,
    VALIDATE_PHONE_ON_EDIT,
    EDIT_PROFILE_SAVE,
    EDIT_PROFILE_SAVE_SUCC,
    EDIT_PROFILE_SAVE_FAIL,
    EDITPROFILE_FETCHAGENCIESSUCCESS,
    EDITPROFILE_FETCHAGENCYSUCCESS,
    EDITPROFILE_ISLOADING,
    EDITPROFILE_FETCHFAIL,
    VALIDATE_PHONE_MODALOPEN_ON_EDIT,
    CHECK_USERNAME_IS_LOADING,
} from "../../../../actions/constants";
const initialState = {
    isLoading: false,
    checkUserNameLoading: false,
    //profile: {},
    agencies: {},
    errors: [],
    selectedAgency: {},
    phoneValidation: {
        modalOpen: false,
        phone: "",
        loading: false,
        errors: [],
        success: null,
    },
}

function editProfileReducer(state = initialState, action) {
    switch (action.type) {
        case VALIDATE_PHONE_ON_EDIT_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = false;
                _state.phoneValidation.errors = [action.errors];
                return _state;
            }
            /* FINAL STEP FOR SIGN UP */
        case VALIDATE_PHONE_ON_EDIT_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = false;
                _state.phoneValidation.phone = action.payload.phone;
                return _state;
            }
        case VALIDATE_PHONE_ON_EDIT:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = true;
                return _state;
            }
        case EDITPROFILE_ISLOADING:
            {
                const _state = {
                    ...state
                };
                _state.isLoading = action.payload.isLoading;
                return _state;
            }
        
        case EDITPROFILE_FETCHFAIL:
            {
                const _state = {
                    ...state
                };
                _state.isLoading = false;
                _state.errors = action.errors;
                return _state;
            }
        case EDIT_PROFILE_SAVE:
            {
                const _state = {
                    ...state,
                };
                _state.isLoading = true;
                return _state;
            }
        case EDIT_PROFILE_SAVE_SUCC:
            {
                const _state = {
                    ...state,
                };
                _state.isLoading = false;
                return _state;
            }
        case EDIT_PROFILE_SAVE_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.isLoading = false;
                _state.errors = action.payload && action.payload.errors ? action.payload.errors : null;
                return _state;
            }
        case EDITPROFILE_FETCHAGENCIESSUCCESS:
            {
                const _state = {
                    ...state
                };
                _state.isLoading = false;
                _state.agencies = [...action.payload.agencies];
                return _state;
            }
        case EDITPROFILE_FETCHAGENCYSUCCESS:
            {
                const _state = {
                    ...state
                };
                _state.isLoading = false;
                _state.selectedAgency = Object.assign({}, state.selectedAgency, action.payload.selectedAgency);
                return _state;
            }
        
        case VALIDATE_PHONE_CODE_ON_EDIT_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = false;
                _state.phoneValidation.success = false;
                _state.phoneValidation.errors = [action.errors];
                return _state;
            }
        case VALIDATE_PHONE_CODE_ON_EDIT_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = false;
                _state.phoneValidation.success = true;
                _state.phoneValidation.phone = action.code.phone;
                return _state;
            }
        case VALIDATE_PHONE_CODE_ON_EDIT:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.loading = true;
                return _state;
            }
        case VALIDATE_PHONE_MODALOPEN_ON_EDIT:
            {
                const _state = {
                    ...state
                };
                _state.phoneValidation.modalOpen = action.payload.open;
                return _state;
            }
        case CHECK_USERNAME_IS_LOADING:
            {
                const _state = {
                    ...state
                };
                _state.checkUserNameLoading = action.payload.isLoading;
                return _state;
            }
        default:
            return state
    }
}

export default editProfileReducer;