import styled from "styled-components";

const Link = styled.a`
    font-size: ${({ theme, fontSize }) =>
        fontSize ? theme.fonts[fontSize] : "10px"};
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme, color }) => color};
    position: relative;

    &:after {
        content: "";
        position: absolute;
        bottom: ${({ underLineBottomDistance }) =>
            underLineBottomDistance || "-2px"};
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        transform: scaleX(0);
        background-color: ${({ theme, underlineColor }) => underlineColor};

        transition: transform 0.3s;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }
`;

export default Link;
