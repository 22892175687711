import React from "react";
import {
    CardContainer,
    EditIconContainer,
    
} from "./card-styles";
import { Icon } from "../Icon";
import { withTheme } from "styled-components";

const Card = (props) => {
    const {onClickEdit, basicCardMode = true, children, theme, itsMe = false , disabled, bg, padding, id, isLoading,  ...others} = props;
    const showEditIcon = !basicCardMode && itsMe && !isLoading;
    const handleClickEdit = () => {
        if(onClickEdit) onClickEdit()
    };
    return (
        <CardContainer disabled={disabled} bg={bg} padding={padding} id={id} {...others}>
        {showEditIcon && (
            <EditIconContainer onClick={handleClickEdit}>
                <Icon icon="pen" size="md" color={theme.darkGray} />
            </EditIconContainer>
            )}
            {children}
            </CardContainer>
            );
        }
        
export default withTheme(Card);
        