import styled,{ keyframes } from 'styled-components';
import Card from "../../../../../ui/presentation/atoms/shared/Card";

const slide = keyframes`
  from { clip-path: inset(0 0 100% 0); }
  to { clip-path: inset(0); }
`

export const FilterButton =  styled.div`
    background-color: ${({ theme, buttonActive }) => buttonActive ? theme.newBlue : "white"};
    padding: 4px 8px;
    border-radius: 4px;
    color: ${({ theme, buttonActive }) => buttonActive ? 'white' : "black"};
    font-size: 10px;
`;

export const CardContainer = styled.div`
    animation: ${slide} 0.1s ease-in;
    z-index: 999999;
    position: absolute;
    width: 353px;
    top: 3.2rem;
    right: -30px;
    outline: none;
`

export const Poligon = styled.div`
    z-index: 99999;
    position: absolute;
    width: 24px;
    height: 27px;
    top: -13.5px;
    right: 47px;
    background-color: white;
    transform: rotate(45deg);
`

export const CardScrollContainer = styled(Card)`
    position: relative;
    max-height: 590px;
    height: 590px;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`