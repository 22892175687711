import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Flex } from "rebass";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";
import PopupBlurModal from "../../../atoms/shared/PopupBlurModal";
import { Icon } from "../Icon";
import CreateContactModal from "./CreateContact";
import SquareButton from "../Buttons/SquareButton2";
import confetti from "canvas-confetti";
import PeopleSearchBar from "../PeopleSearchBar";
import { WhiteButton } from "../../../views/OpportunityCreation/Steps/ReferralCompleteInformation/styles";

const Title = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
    margin-bottom: 1.5em;
    text-align: center;
`;

const Subtitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    margin-bottom: 0.5em;
`;

const MainContainer = styled.div`
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 3em;
`;

const SearchContainer = styled.div`
    border: 1px solid #929292;
    height: 48px;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;

    &:focus-within {
        border: 1px solid rgba(5, 122, 255, 1);
        outline: none;
    }
`;

const SearchBox = styled.input`
    border: none;
    outline: none;
    box-sizing: border-box;
    font-size: 14px;
    padding: 0 10px;
    display: flex;
    flex-grow: 1;
    &:focus {
        border: none;
        outline: none;
    }
`;

const Disclaimer = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #929292;
    flex-basis: 80%;
`;

const ActionContainer = styled.div`
    display: flex;
    margin-top: 0.5em;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;

        ${Disclaimer} {
            margin-bottom: 1rem;
        }
    }
`;



export const Button = styled.button`
    height: 40px;
    width: 94px;
    background: #00A15F;
    color: white;
    padding: 8px 22px;
    border-radius: 4px;
    border: none;
    outline: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;

    &:hover {
        background: #71BD9F;
    }

    &:active {
        background: #43705E;
    }

    &:disabled {
        background: #929292;
        cursor: not-allowed;
    }
`;

const ActionLabel = styled.span`
    color: #1FC07F;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 0.5em;
`;

const SocialContainer = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SocialActions = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1em;
`;

const SocialButton = styled.div`
    margin: 0 1em;
    cursor: pointer;
`;

const LinkContainer = styled.div`
    border-radius: 8px;
    background-color: #FAFAFA;
    height: 40px;
    width: 40%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #929292;

    @media (max-width: 1400px) {
        width: 60%;
    }

    @media (max-width: 1024px) {
        width: 80%;
    }

    @media (max-width: 768px) {
        width: 100%;
        font-size: 12px;
    }
`;

const LinkAction = styled.div`
    font-size: 16px;
    font-weight: 700;    
    color: #1FC07F;
    cursor: pointer;
`;

const SearchSuggestions = styled.div`
    position: absolute;
    top: 110%;
    width: 100%;
    z-index: 1000;
    max-height: 600%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 20px;
    overflow-y: scroll;
    left: 0;
`;

const SelectedUser = styled.div`
    display: flex;
    flex-grow: 1;
`;

const ShareModal = ({ 
    open,
    onClose,
    url,
    shareReferral,
    opCode,
    ownerUID,
    theme
}) => {

    const opLink = `${process.env.REACT_APP_URI}${url}`;
    const [selectedUser, setSelectedUser] = useState(null);
    const [createContactModal, setCreateContactModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessfullMessage, setShowSuccessfullMessage] = useState(false);
    const submitDisabled = selectedUser === null;
    const [candidateCreationVisible, setCandidateCreationVisible] = useState(false);
    const [contacts, setContacts] = useState([]);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(opLink);
        toast.success('Copied to clipboard!', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    useEffect(() => {
        return () => {
            setShowSuccessfullMessage(false)
        };
    }, [open])

    useEffect(() => {
        const modalRoot = document.getElementById(`success-content-${opCode}`);
        if (!showSuccessfullMessage) {
            const canvas = modalRoot.getElementsByTagName("canvas");
            for (const canva of canvas) {
                if (canva) modalRoot.removeChild(canva);
            }
            return;
        }
        const animationEnd = Date.now() + 9000;
        const canvas = document.createElement("canvas");
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.width = "100%";
        canvas.style.height = "100%";

        modalRoot.insertBefore(canvas, modalRoot.firstChild);
        var confettiCelebration = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        confettiCelebration({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) return clearInterval(interval);

            confettiCelebration({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }, 3000);
    }, [showSuccessfullMessage]);

    const clear = () => {
        setSelectedUser(null);
    }

    const handleSendOtherInvitation = () => {
        clear();
        setShowSuccessfullMessage(false);
    }

    const handleSendShare = async () => {
        console.log('SELECTED USER', selectedUser)
        if (selectedUser) {
            setIsLoading(true);
            const { uid, userName, is_member } = selectedUser;
            const isMember = !!is_member || !!userName
            shareReferral(uid, isMember);
            setShowSuccessfullMessage(true);
            setIsLoading(false);
        }
    };

    const createContact = ({ email, first_name, last_name, phone, contact_id, work_info }) => {

        const contact = {
            email,
            first_name,
            last_name,
            phone,
            contact_id,
            uid: contact_id,
            company_name: work_info && work_info.company,
            locationAddress: work_info && work_info.address && work_info.address && work_info.address.city ? work_info.address.city : '',
            subscription: {
                plan: ""
            },
            type: "CONTACT"
        }
       
        setContacts((prev) => [...prev, contact])
    };




    const renderShareModal = () => (
        <>
            <Title>
                <div>Share This Opportunity</div>
            </Title>
            <MainContainer>
                <Subtitle>Who would you like to share this opportunity with?</Subtitle>
                <PeopleSearchBar onClickSuggestion={setSelectedUser} onClearSuggestion={clear} includeContacts/>
                <ActionContainer>
                    <Disclaimer>
                        If the person who you want to share this opportunity with does not display in the search results it means they are not yet a member.  
                        <ActionLabel onClick={() => setCreateContactModal(true)}>Click here</ActionLabel> to continue.
                    </Disclaimer>
                    <SquareButton
                        disabled={submitDisabled}
                        onClick={handleSendShare}
                        isLoading={isLoading}
                        text="Send"
                    />
                </ActionContainer>
            </MainContainer>
            <SocialContainer>
                    <Disclaimer>
                        Or share the link
                    </Disclaimer>
                    <SocialActions>
                        <SocialButton>
                            <FacebookShareButton
                                url={opLink}
                            >
                                <Icon
                                    icon="facebook"
                                    height={30}
                                    width={30}
                                    color={'#057AFF'}
                                />
                            </FacebookShareButton>
                        </SocialButton>
                        <SocialButton>
                            <TwitterShareButton
                                url={opLink}
                            >
                                <Icon
                                    icon="twitter"
                                    height={30}
                                    width={30}
                                    color={'#057AFF'}
                                />
                            </TwitterShareButton>
                        </SocialButton>
                        <SocialButton>
                            <LinkedinShareButton
                                url={opLink}
                            >
                                <Icon
                                    icon="linkedin"
                                    height={30}
                                    width={30}
                                    color={'#057AFF'}
                                />
                            </LinkedinShareButton>
                        </SocialButton>
                    </SocialActions>
                    <LinkContainer>
                        { opLink }
                        <LinkAction onClick={copyToClipboard}>
                            Copy
                        </LinkAction>
                    </LinkContainer>
            </SocialContainer>
        </>
    )

    const renderSendSuccessModal = () => (
            <Flex flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                <Icon
                    icon="check-filled"
                    color={theme.greenMedium}
                    width={"60px"}
                    height={"60px"}
                    style={{ marginBottom: theme.spacing[2] }}
                    />
                <Title>
                    <div>Opportunity shared succesfully!</div>
                </Title>
                <Disclaimer style={{ fontSize: "16px" }}>{selectedUser.full_name} will be notified about this Opportunity</Disclaimer>
                <SquareButton
                    style={{ maxWidth: "300px", marginTop: theme.spacing[6], zIndex: 100 }}
                    onClick={handleSendOtherInvitation}
                    text="Send another invitation"
                />
            </Flex>
        )


    return (
        <>
        
            <PopupBlurModal
                open={open && !createContactModal}
                handleClose={(e) => onClose(e)}
                closeButtonStyles={{ top: "1rem" }}
                width={[0.9, 0.9, 0.7, 0.7]}
                modalStyles={{ zIndex: 100001 }}
            >
                <Box style={{ position: "relative" }} p={["1.5rem", "1.5rem", "5rem", "5rem"]} id={`success-content-${opCode}`}>
                    {showSuccessfullMessage ? renderSendSuccessModal() : renderShareModal()}
                </Box>
            </PopupBlurModal>
            <CreateContactModal
                open={createContactModal}
                onClose={() => setCreateContactModal(false)}
                onContactCreated={createContact}
                subtitle={'subtitleLabel[opTypeCode]'}
                
            />
        </>
    );
};

export default withTheme(ShareModal);