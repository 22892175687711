import React from 'react';
import PopupBlurModal from '../../../../atoms/shared/PopupBlurModal';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik } from 'formik';
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../Settings/common/Components";

import { Button } from "../../components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: center;
`;

const MainText = styled.div`
    color: #333333;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin: 1em 0;
`;

const HelpText = styled.div`
    color: #929292;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FormRow = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 1em;
    flex-direction: row;
    justify-content: space-between;
`;

const formInitialValues = {
    email: '',
    firstName: '',
    lastName: '',
};

const formSchema = yup.object().shape({
    email: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    mobilePhone: yup.string(),
});

const CandidateCreation = ({ open, onClose, onSuccess, subtitle }) => {
    const onSubmitHandler = (values) => {
        onSuccess(values)
        onClose();
    };

    return(
            <PopupBlurModal
                modalStyles={{ borderRadius: "16px" }}
                open={open}
                handleClose={(e) => onClose(e)}
                closeButtonStyles={{ top: "1rem" }}
                width={"50%"}
            >
                <Wrapper>
                    <MainText>Create Contact</MainText>
                    <HelpText>{subtitle}</HelpText>
                    <Formik
                        initialValues={formInitialValues}
                        onSubmit={(values, { resetForm }) => {
                            onSubmitHandler(values);
                            resetForm();
                        }}
                        validationSchema={formSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <>
                                <FormRow>
                                    <InputContainer showLabelOnValue>
                                        <Input
                                            type="text"
                                            name="firstName"
                                            placeholder="First name*"
                                            required
                                            value={values.firstName}
                                            onChange={handleChange}
                                            error={!!errors.firstName}
                                        />
                                        <InputLabel>First name*</InputLabel>
                                    </InputContainer>
                                    <InputContainer showLabelOnValue>
                                        <Input
                                            type="text"
                                            name="lastName"
                                            placeholder="Last name*"
                                            required
                                            value={values.lastName}
                                            onChange={handleChange}
                                            error={!!errors.lastName}
                                        />
                                        <InputLabel>Last name*</InputLabel>
                                    </InputContainer>
                                </FormRow>
                                <FormRow>
                                    <InputContainer showLabelOnValue>
                                        <Input
                                            type="text"
                                            name="email"
                                            placeholder="Email*"
                                            required
                                            value={values.email}
                                            onChange={handleChange}
                                            error={!!errors.email}
                                        />
                                        <InputLabel>Email*</InputLabel>
                                    </InputContainer>
                                </FormRow>
                                <FormRow>
                                    <InputContainer showLabelOnValue>
                                        <Input
                                            type="text"
                                            name="mobilePhone"
                                            placeholder="Mobile Phone"
                                            required
                                            value={values.mobilePhone}
                                            onChange={handleChange}
                                            error={!!errors.mobilePhone}
                                        />
                                        <InputLabel>Mobile Phone</InputLabel>
                                    </InputContainer>
                                </FormRow>
                                <FormRow>
                                    <HelpText>Note: You will be able to modify this contact and resend notification if needed.</HelpText>
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={false}
                                        type='submit'
                                    >
                                        Create
                                    </Button>
                                </FormRow>
                            </>
                        )}
                    </Formik>
                </Wrapper>
            </PopupBlurModal>
    
    );
};

export default CandidateCreation;