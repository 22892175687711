import React, { useEffect, useState } from 'react'
import { Flex } from "rebass"
import {
    InputContainer,
    TextArea,
} from "../../../../Settings/common/Components";
import { InputCounter } from "../../../../Settings/InformationAccount/styles";

const Description = ({ theme, formValues, onChange, errors, candidates, pastCandidate }) => {
    const [onField, setOnField] = useState(formValues.description.length < 30);
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [onPressField, setOnPressField] = useState(false);
    const [signalError, setSignalError] = useState(false)
    const charactersNumberByOpMethod =  (candidates.length === 1 || pastCandidate.length === 1 ) ? 31 : 130;
    useEffect(() => {

        setDescriptionLength(formValues.description.length)
        if (onPressField && descriptionLength < charactersNumberByOpMethod) {
            setOnField(true)
            setSignalError(true)
        }
        else if (descriptionLength < charactersNumberByOpMethod && typeof errors.description === 'string') {
            setOnField(true)
        }
        else {
            setOnField(false)
            setSignalError(false)
        }
    }, [formValues.description, descriptionLength, onField, errors.description])



    return (
        <Flex p={`${theme.spacing[5]} 0`}>
            <InputContainer>
                <TextArea
                    style={{ resize: "vertical" }}
                    onFocus={() => setOnPressField(true)}
                    onBlur={() => setOnPressField(true)}
                    type="text"
                    name="description"
                    value={formValues.description}
                    onChange={onChange}
                    maxLength="2000"
                    minLength="130"
                    placeholder={(candidates.length === 1 || pastCandidate.length === 1) ? 'Using a minimum of 30 characters, please tell potential candidates about your referral.*'
                        : 'Using a minimum of 130 characters, please tell potential candidates about your referral.*'}
                    //  error={!!errors.description}
                    error={onField}
                    signalError={signalError}
                />
                {!!formValues.description &&
                    !!formValues.description.length && (
                        <InputCounter>
                            {formValues.description.length}/2000
                        </InputCounter>
                    )}
            </InputContainer>
        </Flex>
    )
}

export default Description;