import React, { useState } from "react";
import styled from "styled-components";
import { MainText, StepWrapper, selectStyle, Button, ButtonContainer } from "../../components";
import { RecommendationTypeOptions } from "../../constants";
import Select from 'react-select';
import { Flex } from "rebass";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../atoms/shared/Icon";


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const ExtendedButtonContainer = styled(ButtonContainer)`
    justify-content: space-between;
    align-items: center;
`;

const ClickableLabel = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #057AFF;
    margin-left: 6px;
`;

const BottomLabel = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
`;

const RecommendationType = ({ stepData, setStepData, stepKey }) => {
    const [selectedType, setSelectedType] = useState(stepData);

    const onClickHandler = () => {
        setStepData(selectedType);
    };


    const _toolTip = `A recommendation is a good tool to use if you are not sure if business will actually be transacted or not.  If you are confident that business will be transacted then you should consider creating a referral or a job instead. 

    The two recommendation options listed here allow you to give or receive a recommendation.  
    
    Please note that a recommendation will not give you the ability to track revenue, referral fees or leave reviews.  Only referrals and jobs give you that functionality.  However, you are able to convert a recommendation to a referral or a job if a transaction actually results from your recommendation. `

    return (
        <StepWrapper>
            <Container>
                <Wrapper>
                <MainText>What would you like to do?</MainText>
                <Icon
                            icon="info"
                            color="#057AFF"
                            size="md"
                            data-tip
                            data-for={'candidates-info'}
                        />
                </Wrapper>
                
                <SelectContainer>
                    <Select
                        options={RecommendationTypeOptions}
                        onChange={setSelectedType}
                        styles={selectStyle}
                        value={selectedType}
                        isClearable
                        placeholder="Select...*"
                    />
                </SelectContainer>
               <ExtendedButtonContainer>
                    <BottomLabel>Would you like to create a referral or a job?
                        <Link to={'/op-creation'} >
                             <ClickableLabel onClick={() => {}} >Click Here</ClickableLabel>
                        </Link>
                        
                    </BottomLabel>
                    <Flex>
                    <Button
                        onClick={onClickHandler}
                        disabled={!selectedType}
                    >
                        Next
                    </Button>
                </Flex>
                </ExtendedButtonContainer> 
                
            </Container>
            <ReactTooltip
                id={'candidates-info'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
         
            >
                <div dangerouslySetInnerHTML={{ __html: _toolTip }} />
            </ReactTooltip>
        </StepWrapper>
    );
}

export default RecommendationType;
