import React, { useEffect } from "react";
import { Flex } from "rebass";
import styled, { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";
import { Icon } from "../../Icon";
import SquareButton from "../../Buttons/SquareButton2";
import confetti from "canvas-confetti";
import { useHistory } from "react-router";
import {
    EngagementModalTitle as Title,
    EngagementModalDescription as Description
} from "../../Text";

const GratitudeSuccessMessage = ({ t, theme, buttonLabel, fullName, userName, onClick }) => {
    const { push } = useHistory();
    useEffect(() => {
        const modalRoot = document.getElementById(`gratitude-success`);
        const animationEnd = Date.now() + 9000;
        const canvas = document.createElement("canvas");
        canvas.style.position = "absolute";
        canvas.style.top = 0;
        canvas.style.left = 0;
        canvas.style.width = "100%";
        canvas.style.height = "100%";

        modalRoot.insertBefore(canvas, modalRoot.firstChild);
        var confettiCelebration = confetti.create(canvas, {
            resize: true,
            useWorker: true
        });
        confettiCelebration({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });

        const interval = setInterval(() => {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) return clearInterval(interval);

            confettiCelebration({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }, 3000);

        return () => {
            clearInterval(interval);
            modalRoot.removeChild(canvas);
        };
    }, []);

    const goToProfile = () => push(`/profile/${userName}`);

    return (
        <Flex
            id="gratitude-success"
            flexDirection="column"
            alignItems="center"
            style={{ position: "relative" }}
            p={[
                theme.spacing[5],
                theme.spacing[5],
                theme.spacing[10],
                theme.spacing[10]
            ]}
        >
            <Icon name="gratitude" color={theme.mountainMeadow} size="3xl" />
            <Title pointer>{t("success_title")}</Title>
            <Description pointer>
                {t("success_description_1")}
                {fullName && (
                    <span className="highlight" onClick={goToProfile}>
                        {fullName}
                    </span>
                )}
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("success_description_2")
                    }}
                />
            </Description>
            <Flex width={[1]} mt={[theme.spacing[8]]} justifyContent={"center"}>
                <SquareButton
                    onClick={onClick}
                    bg={theme.shamRockGreen}
                    fontSize="XS"
                    style={{ zIndex: 1 }}
                    text={buttonLabel || t("success_button")}
                />
            </Flex>
        </Flex>
    );
}

export default withTranslation("gratitudeModal")(withTheme(GratitudeSuccessMessage));