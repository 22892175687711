import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShareModal from "./ShareModal";
import { clearPeopleSearchBar, peopleSearchBar } from '../../../../../../redux/actions/creators/search';
import { shareReferral, closeShareModal } from '../../../../../../redux/actions/creators/referralInformation';

const mapStateToProps = state => {
    const searchResults = state.main.ui.global.peopleSearchBar.results;
    const referralInfo = state.main.ui.global.shareModal.referralInfo;
    return {
        searchResults,
        searchLoading: state.main.ui.global.peopleSearchBar.isLoading,
        professions: state.main.ui.global.statics.userTypes || [],
        open: state.main.ui.global.shareModal.open,
        ...referralInfo
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            peopleSearchBar,
            clearPeopleSearchBar,
            peopleSearchBar,
            shareReferral,
            onClose: closeShareModal
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
