import styled, {  keyframes } from "styled-components";

const nuopLoader = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

export const LoaderContainer = styled.div`
    width: 630px;
height: 408px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    > div {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
`;
  
  
export const LogoContainer = styled.div`
`;
  
export const LoaderLogo = styled.div`
    max-width: 100%;
`
 
export const SpinningLoader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ::after {
        content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid  #00A15F;
    border-color: #00A15F transparent #00A15F transparent;
    animation: ${nuopLoader} .8s linear infinite;
    }
`;  
 
  
  
 
  
  /* Loader2 styles  */
  
  const Load2 = styled.div`
    

  `

  
  
  
  
  
  