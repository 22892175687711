import React from "react";
import { Flex } from "rebass";
import {
    InputContainer,
    TextArea
} from "../../../../../../Settings/common/Components";
import { InputCounter } from "../../../../../../Settings/InformationAccount/styles";
import { Text } from "../../../../../../../atoms/shared/Text";

const OtherFee = ({ theme, formValues, onChange, errors }) => (
    <>
        <Flex>
            <Text color={theme.taupeGray} fontSize="XXS">
                Please input the requirements of your referral fee
            </Text>
        </Flex>
        <Flex mt={theme.spacing[4]}>
            <InputContainer showLabelOnValue>
                {!!formValues.comments && !!formValues.comments.length && (
                    <InputCounter>
                        {formValues.comments.length}/2000
                    </InputCounter>
                )}
                <TextArea
                    style={{ resize: "vertical" }}
                    type="text"
                    name="comments"
                    value={formValues.comments}
                    onChange={onChange}
                    maxLength="2000"
                    minLength="60"
                    placeholder="Comments*"
                    error={!!errors.comments}
                />
            </InputContainer>
        </Flex>
    </>
);

export default OtherFee;