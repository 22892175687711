import React from "react";
import styled from "styled-components";
import {
    MainText,
    StepWrapper,
    selectStyle,
    Button,
    ButtonContainer
} from "../../../components";
import Select from "react-select";
import { Icon } from "../../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import * as yup from "yup";
import { Formik } from "formik";
import Card from "../../../../../atoms/shared/Card2";
import { Box, Flex } from "rebass";
import { Title } from "../../../../../atoms/shared/Text";
import Avatar from "../../../../../atoms/shared/AvatarCircle2";
import {
    DataTitle,
    DataValue,
    Desc,
    DataPlan
} from "../../../../ReferralInformation/GeneralInfo/styles";
import { Status } from "../../../../ReferralInformation/referralInformation-styles";
import moment from "moment";
import { DraftModal, useDraft } from "../../../useDraft";
import {  ErrorMessage, SuccesReferralSent } from '../JobSuccessMessages'
import Spinner from "../../../../../../../../assets/svg/Spinner";
import { PopupLoader } from "../../../../../atoms/shared/PopupLoader";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5em;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Spacer = styled.div`
    margin: 1em 0;
`;

const JobEmployeePreview = ({
    setStepData,
    stepData,
    t,
    jobType,
    candidates,
    sendingJobData,
    checkData,
    referralIsLoading,
    successReferral,
    errorReferral,
}) => {
    const {
        saveDraft,
        loading,
        showModal,
        setShowModal,
        modalMessage
    } = useDraft();
    const {
        locationIsRemote,
        address,
        cannotFindProfession,
        professionDescription,
        profession,
        description,
        compensation,
        jobTitle
    } = stepData;

    const {
        employeeType: { value: employeeTypeValue }
    } = jobType;

    const employeeTypeLabel = `${
        employeeTypeValue === "PARTTIME" ? "Part-Time" : "Full-Time"
    }`;

    const addressLabel = `${
        locationIsRemote.value === "PARTICULAR" ? address.description : "Remote"
    }`;
    const professionLabel = `${
        cannotFindProfession ? professionDescription : profession.label
    }`;

    return (
        <StepWrapper>
            <DraftModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalMessage={modalMessage}
            />
            <MainText>Please review your Job information</MainText>
            <Spacer>
                <Card
                    borderRadius={"8px 8px 8px 8px"}
                    status="PENDING"
                    padding="30px"
                >
                    <Title>General Information</Title>
                    <Box mt={["1.5em"]}>
                        <Flex
                            alignItems="flex-start"
                            flexDirection={["column", "column", "row", "row"]}
                            flexWrap="wrap"
                            justifyContent={'space-between'}
                        >
                            <Box width={[1, 1, 0.33, 0.33]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Method:</DataTitle>
                                    <DataValue>
                                        Job - Employee ({employeeTypeLabel})
                                    </DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Job Title:</DataTitle>
                                    <DataValue>{jobTitle}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Looking For:</DataTitle>
                                    <DataValue>{professionLabel}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Location:</DataTitle>
                                    <DataValue>{addressLabel}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Created:</DataTitle>
                                    <DataValue>{`${moment(new Date()).format(
                                        "MMMM Do YYYY"
                                    )}`}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Compensation:</DataTitle>
                                    <DataValue>{compensation}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Visibility:</DataTitle>
                                    <DataValue>
                                        {candidates.length ? "Direct" : "Open"}
                                    </DataValue>
                                </Flex>
                            </Box>
                            <Box width={[1, 1, 0.33, 0.33]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Code:</DataTitle>
                                    <DataValue>To be defined</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Status:</DataTitle>
                                    <Status statusColor={"PENDING"}>
                                        Available
                                    </Status>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box mt={["1.5em"]}>
                        <Flex width={[1]} mb={[".5em"]}>
                            <DataValue>{description}</DataValue>
                        </Flex>
                    </Box>
                </Card>
            </Spacer>
            <ButtonContainer>
                <Box mr={16}>
                    <Button
                        width="120px"
                        onClick={saveDraft}
                        disabled={loading}
                    >
                        Save Draft
                    </Button>
                </Box>
                {referralIsLoading ? 
                    <Button disabled={false} type="submit">
                      <Spinner color={"white"} width="14px" height="14px" />
                    </Button>

                  :
                  <Button onClick={() => sendingJobData()} disabled={false} type="submit">
                     Submit
                  </Button>

                }
            </ButtonContainer>
            {referralIsLoading && <PopupLoader />}
            {successReferral && <SuccesReferralSent/>}
            {errorReferral && <ErrorMessage />}
        </StepWrapper>
    );
};

export default JobEmployeePreview;
