import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import GlobalBusinness from "../../../../../../business/global";
import Skeleton from "react-loading-skeleton";

export default withTheme(props => {
    const [isLoading, setIsLoading] = useState(true);
    const [resizedImageUrl, setUrl] = useState("");
    const [firstLoad, setFirstLoad] = useState(true);
    const { src, size, imageStyles, theme, ...others } = props;

    useEffect(() => {
        let newUrl = GlobalBusinness.getResizedImageUrl(src, size);
        setUrl(newUrl);
    }, [src]);

    const onFinishLoad = () => {
        setIsLoading(false);
    };
    const onError = () => {
        if (firstLoad) {
            setUrl(src);
            setFirstLoad(false);
        } else setIsLoading(false);
    };

    return <Fragment>
        <img
            alt={resizedImageUrl}
            height={isLoading ? 0 : null}
            {...others}
            style={!isLoading ? { ...imageStyles } : null}
            src={resizedImageUrl}
            onLoad={onFinishLoad}
            onError={onError}
        />
        {isLoading && (
            <Skeleton
                width="100%"
                height={
                    imageStyles && imageStyles.height
                        ? imageStyles.height
                        : null /* '8em' */
                }
            />)}
    </Fragment>
});
