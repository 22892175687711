import styled from "styled-components";
import { SectionContent2 } from '../../../atoms/shared/Text'
import Icon from "../../../../../../assets/svg/EditIcon";
import { t } from "i18next";


export const Content = styled(SectionContent2)`
   font-weight: ${props => props.fontWeight ? props.fontWeight : null};
   color: ${props => props.theme.darkestGreen};
`;

export const EditIcon = styled(Icon)`
   cursor: pointer;
`;

export const EditIconContainer = styled.div`
   position: absolute;
   right: 30px;
   top: 30px;
`;

export const GeneralContainer = styled.div`
   margin-top: 2em;
`;

export const InputCounter = styled.label`
   margin-top: .5rem;
   text-align: right;
   color: ${props => props.theme.coolBlack};
   font-size: 12px;
   line-height: 16px;
   position: absolute;
    right: 0px;
    top: ${({ theme, referral }) => (theme.mobileDevice && referral) ? "186px" : "108px"};
`
