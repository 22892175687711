import moment from "moment";
import { isArray } from "util";
import { SECTION_SIGNATORY } from "../components/ui/presentation/views/SendReferral/constants";
import { OPPORTUNITIES_MODEL } from "../assets/strings";
export default class Referral {
    static isValidUserForOpenReferralClaiming = (user = {}) => {
        return (
            user.name &&
            user.lastname &&
            user.email &&
            user.verified_phone &&
            user.verified_email
            //(user.verified_email || user.verified)
        );
    };

    // if referral is open and the user is not the one who created it
    static ableToRecommend = (
        referral = {},
        referralStatusKeys = {},
        referralTypesKeys = {},
        meId
    ) => {
        const response =
            (referral.type === referralTypesKeys.OPEN ||
                referral.type === referralTypesKeys.PROVIDER) &&
            (referral.status === referralStatusKeys.PENDING ||
                referral.status === referralStatusKeys.SUBMITTED);
        // As per 11/22/2023, we are allowing Op Creator to recommend on their own Op
        // && meId !== (referral.fromUser && referral.fromUser.uid);
        return response;
    };

    static ableToClaimReferral = (
        referral = {},
        meId,
        referralTypesKeys = {},
        referralStatusKeys = {}
    ) => {
        const fromUser = referral.fromUser || {};
        const toUser = referral.toUser || [];
        const meInTheOpportunity = toUser.find(({ uid }) => uid === meId);
        const response =
            // User can claim If referral is open and the user is not the one who created it
            referral.type === referralTypesKeys.OPEN &&
            fromUser.uid !== meId &&
            // And the referral is Available (Submitted)
            referral.status === referralStatusKeys.SUBMITTED &&
            // And the user is not already in the opportunity
            (!toUser || !meInTheOpportunity);
        return response;
    };

    static ableToClaimReferralBy;

    static ableToCancelClaimReferral = (
        referral = {},
        meId,
        referralTypesKeys = {},
        referralStatusKeys = {}
    ) => {
        const fromUser = referral.fromUser || {};
        const toUser = referral.toUser || [];
        const meInTheOpportunity = toUser.find(({ uid }) => uid === meId);
        if (!meInTheOpportunity) return false;

        const response =
            referral.type === referralTypesKeys.OPEN &&
            fromUser.uid !== meId &&
            referral.status === referralStatusKeys.SUBMITTED &&
            meInTheOpportunity.opportunityStatus === referralStatusKeys.CLAIMED;
        return response;
    };

    static ableToClaimReferralByPlan = (
        subscriptionPlan,
        SUBSCRIPTION_PLANS,
        referralCreationDate
    ) => {
        if (!subscriptionPlan) return false;
        const oneHour = 1000 * 60 * 60;
        const twoHour = oneHour * 2;
        const timePassedSinceCreation =
            new Date().getTime() - referralCreationDate;
        const subscriptionPlans = {
            [SUBSCRIPTION_PLANS.ESSENTIAL]: () =>
                timePassedSinceCreation > twoHour,
            [SUBSCRIPTION_PLANS.ESSENTIAL_PLUS]: () =>
                timePassedSinceCreation > oneHour,
            [SUBSCRIPTION_PLANS.PRO]: () => true
        };

        return subscriptionPlans[subscriptionPlan]();
    };

    static ableToClaimReferralCredits = user => {
        const { credits } = user;
        return credits && credits.total > 0;
    };

    static isOpAssigned = (referralStatus, referralStatusKeys) => {
        const opIsAwarded =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.ASSIGNED;
        const opIsInProgress =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.IN_PROGRESS;
        const opIsCompleted =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.COMPLETED;
        const opIsClosed =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.CLOSED;
        const opIsOnHold =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.ON_HOLD;
        const opIsOnDead =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.DEAD;
        return (
            opIsAwarded ||
            opIsInProgress ||
            opIsCompleted ||
            opIsClosed ||
            opIsOnHold ||
            opIsOnDead
        );
    };

    static isOpAtFinalStatus = (referralStatus, referralStatusKeys) => {
        const opIsCompleted =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.COMPLETED;
        const opIsOnDead =
            referralStatus &&
            referralStatusKeys &&
            referralStatus === referralStatusKeys.DEAD;
        return opIsCompleted;
    };

    static isValidUserForReferralSending = (user, credits) =>
        user.name &&
        user.lastname &&
        user.email &&
        user.verified_phone &&
        user.verified_email /*  &&
        (user.verified_email || user.verified) */;

    static isValidUserForReferralSendingCredits = (user, shareWith) => {
        const { credits } = user;
        return (
            (credits && credits.total > 0) || !shareWith || shareWith === "ALL"
        );
    };

    static getReferralToUsers(ref) {
        let uids = [];
        if (
            ref.referral &&
            ref.referral.referral &&
            ref.referral.referral.to &&
            isArray(ref.referral.referral.to)
        ) {
            const toArray = ref.referral.referral.to;

            toArray.forEach(to => {
                if (uids.indexOf(to.user_id) === -1) uids.push(to.user_id);
            });
        } else if (
            ref.referral &&
            ref.referral.referral.to &&
            typeof ref.referral.referral.to === "object" &&
            ref.referral.referral.to.user_id &&
            ref.referral.referral.to.user_id.length > 0
        ) {
            uids.push(ref.referral.referral.to.user_id);
        }

        return uids;
    }

    static getReferralToUsersFromReferrals(referrals) {
        let uids = [];
        referrals.forEach(ref => {
            uids = [...uids, ...this.getReferralToUsers(ref)];
        });
        const uniqueUids = [...new Set(uids)];
        return uniqueUids;
    }

    static getWarningCardText = (user, translateObject) => {
        const t = translateObject;
        const { verified_phone, verified_email } = user ? user : {};
        let warningText = t(
            "referralInformation:completeProfileWarningBodyProfile"
        );
        let warningBy = "profile";
        if (!verified_phone) {
            warningText = t(
                "referralInformation:completeProfileWarningBodyPhone"
            );
            warningBy = "phone";
        } else if (!verified_email) {
            warningText = t(
                "referralInformation:completeProfileWarningBodyEmail"
            );
            warningBy = "email";
        }

        return { warningText, warningBy };
    };

    static getReferralStatus = (referral, uid) => {
        let status = referral.status || false;

        // If opportunity is not valid, return false
        if (!referral || !referral.fromUser || !referral.fromUser.uid)
            return status;
        // If opportunity is to me, return my status
        const toUser = referral.toUser.find(to => to.uid === uid);

        if (
            toUser &&
            toUser.opportunityStatus &&
            toUser.opportunityStatus !== "ASSIGNED" &&
            referral.status !== "UNCONFIRMED"
        ) {
            status = toUser.opportunityStatus;
        }
        // For any other case, return the status of the opportunity

        return status;
    };

    static getCurrentGlobalReferralStatus = referral => referral.status;

    static getMyOpportunityStatus = (referral = {}, uid = "") => {
        if (!referral || !referral.toUser) return false;
        const toUser = referral.toUser.find(to => to.uid === uid);
        return toUser && toUser.opportunityStatus
            ? toUser.opportunityStatus
            : false;
    };

    static getReferralDesiredAddress(referral, uid, referralStatusKeys) {
        let address = "";
        if (referral && uid) {
            const fullAddress =
                referral &&
                referral.location &&
                referral.location[0] &&
                referral.location[0]["fullAddress"];
            if (referral.fromId === uid) address = fullAddress;
            else {
                if (
                    referral.to &&
                    referral.to[uid] &&
                    referral.to[uid].status === referralStatusKeys.ASSIGNED
                )
                    address = fullAddress;
                else {
                    address = this.getShortAddressFromFullAddress(fullAddress);
                }
            }
        }

        return address;
    }

    static getShortAddressFromFullAddress = fullAddress => {
        if (fullAddress) {
            let newAddress = fullAddress;
            const splitedAddress = fullAddress.split(",");
            if (Array.isArray(splitedAddress) && splitedAddress.length > 3) {
                const slicedAddress = splitedAddress.slice(-3);
                newAddress = `${slicedAddress[0]}, ${slicedAddress[1]}, ${slicedAddress[2]}`;
            }

            return newAddress;
        }
        return fullAddress;
    };

    static getSendReferralNextSection = (
        sectionsArray,
        currentSection,
        isRER,
        next,
        candidates,
        selectedSignatory,
        signatoryTypesKeys = {}
    ) => {
        const newSections = [...sectionsArray];
        if (isRER) newSections.splice(2, 2);
        else {
            newSections.splice(1, 1);
            newSections.splice(2, 1);
        }
        if (
            !selectedSignatory ||
            !(selectedSignatory === signatoryTypesKeys.I_WILL)
        ) {
            const i = newSections.indexOf(SECTION_SIGNATORY);
            newSections.splice(i, 1);
        }
        let index = newSections.indexOf(currentSection);
        index = next ? index + 1 : index - 1;
        return newSections[index];
    };

    static getStepCount = (
        sectionsArray,
        currentSection,
        isRER,
        candidates
    ) => {
        const newSections = [...sectionsArray];
        if (isRER) newSections.splice(2, 2);
        else {
            newSections.splice(1, 1);
            if (candidates && candidates.length === 1) newSections.splice(2, 1);
        }
        let index = newSections.indexOf(currentSection);

        return index;
    };

    static showOpportunityReview = (
        opportunity = {},
        meId,
        referralStatusKeys
    ) => {
        const { opportunityModel, toUser, fromUser, status } = opportunity;
        if (!opportunityModel || !toUser || !fromUser) return false;
        const isNotRecommendation =
            opportunityModel !== OPPORTUNITIES_MODEL.RECOMMENDATION;
        const isOpCompleted = status === referralStatusKeys.COMPLETED;
        const isOpDead = status === referralStatusKeys.DEAD;
        const opportunityFromMe = fromUser.uid === meId;
        const opportunityToMe = toUser.find(to => to.uid === meId);
        const opportunityAssignedToMe =
            opportunityToMe &&
            opportunityToMe.opportunityStatus === referralStatusKeys.ASSIGNED;
        // If opportunity is not recommendation and is completed or dead and is to me or from me, show review
        return (
            isNotRecommendation &&
            (isOpCompleted || isOpDead) &&
            (opportunityFromMe || opportunityAssignedToMe)
        );
    };

    static showReferralDetailsSection = (
        selectedProfessions = [],
        selectedCandidates = []
    ) => {
        return (
            selectedProfessions.indexOf((100).toString()) !== -1 ||
            selectedCandidates.find(cand =>
                [0, 1, 100].includes(Number(cand.user_type))
            )
        );
    };

    static showReferralRadiusSection = (
        selectedProfessionType = [],
        professionTypesList = [],
        shareWith,
        shareWithKeys = {}
    ) => {
        if (shareWith !== shareWithKeys.ALL) return false;
        let showRadius = false;
        selectedProfessionType.forEach(prof => {
            const selectedProfession = professionTypesList.find(
                profession => Number(profession.key) === Number(prof)
            );
            if (selectedProfession && selectedProfession.global)
                showRadius = true;
        });
        return showRadius;
    };

    static showSignature = (
        selectedFeeType = "",
        clientInformation,
        clientFormValid,
        referralFeeTypes = {}
    ) => {
        const hasFee = Number(selectedFeeType) !== referralFeeTypes.NO_FEE;
        const clientInfoOk =
            clientInformation &&
            clientInformation.phone &&
            clientInformation.email;
        return hasFee && clientFormValid && clientInfoOk;
    };

    static showAcres = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isLandlord = client_type === clientTypesKeys.LANDLORD;

        const isLand = property_type === propertyTypesKeys.LAND;
        const isSingleFamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .SINGLE_FAMILY;

        return (isSeller || isLandlord) && (isLand || isSingleFamily);
    };

    static showBuyerResidentialSingleFamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isSingleFamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .SINGLE_FAMILY;

        return isBuyer && isSingleFamily;
    };

    static showBuyerResidentialCondoApartment = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isCondo =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO;
        const isApartment =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT;
        return isBuyer && (isCondo || isApartment);
    };

    static showBuyerResidentialMultifamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isMultifamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .MULTI_FAMILY;

        return isBuyer && isMultifamily;
    };

    static showBuyerLand = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isLand = property_type === propertyTypesKeys.LAND;

        return isBuyer && isLand;
    };

    static showBuyerCommercial = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isCommercial = property_type === propertyTypesKeys.COMMERCIAL;

        return isBuyer && isCommercial;
    };

    static showTenantResidentialSingleFamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isTenant = client_type === clientTypesKeys.TENANT;
        const isSingleFamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .SINGLE_FAMILY;

        return isTenant && isSingleFamily;
    };

    static showTenantResidentialCondoApartment = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isTenant = client_type === clientTypesKeys.TENANT;
        const isCondo =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO;
        const isApartment =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT;
        return isTenant && (isCondo || isApartment);
    };

    static showTenantLand = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isTenant = client_type === clientTypesKeys.TENANT;
        const isLand = property_type === propertyTypesKeys.LAND;

        return isTenant && isLand;
    };

    static showTenantCommercial = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isTenant = client_type === clientTypesKeys.TENANT;
        const isCommercial = property_type === propertyTypesKeys.COMMERCIAL;

        return isTenant && isCommercial;
    };

    static showLanlordResidentialSingleFamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isLanlord = client_type === clientTypesKeys.LANDLORD;
        const isSingleFamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .SINGLE_FAMILY;
        return isLanlord && isSingleFamily;
    };

    static showLanlordResidentialCondoApartment = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isLanlord = client_type === clientTypesKeys.LANDLORD;
        const isCondo =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO;
        const isApartment =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT;
        return isLanlord && (isCondo || isApartment);
    };

    static showLanlordLand = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isLanlord = client_type === clientTypesKeys.LANDLORD;
        const isLand = property_type === propertyTypesKeys.LAND;
        return isLanlord && isLand;
    };

    static showLanlordCommercial = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isLanlord = client_type === clientTypesKeys.LANDLORD;
        const isCommercial = property_type === propertyTypesKeys.COMMERCIAL;
        return isLanlord && isCommercial;
    };

    static showSellerResidentialSingleFamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isSingleFamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .SINGLE_FAMILY;
        return isSeller && isSingleFamily;
    };

    static showSellerCondoApartment = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isCondo =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO;
        const isApartment =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT;
        return isSeller && (isCondo || isApartment);
    };

    static showSellerResidentialMultifamily = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isMultifamily =
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .MULTI_FAMILY;
        return isSeller && isMultifamily;
    };

    static showSellerLand = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isLand = property_type === propertyTypesKeys.LAND;
        return isSeller && isLand;
    };

    static showSellerCommercial = (
        property_type = "",
        client_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        const isSeller = client_type === clientTypesKeys.SELLER;
        const isCommercial = property_type === propertyTypesKeys.COMMERCIAL;
        return isSeller && isCommercial;
    };

    /*
    static showMinMaxBeds = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {},
    ) => (
        (client_type === clientTypesKeys.BUYER ||
            client_type === clientTypesKeys.TENANT ) &&
        property_type === propertyTypesKeys.RESIDENTIAL &&
        (property_subtype === propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].SINGLE_FAMILY ||
            property_subtype === propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO ||
            property_subtype === propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT)
    );
*/

    // static showMinMaxAcres = (
    //     property_type = "",
    //     client_type = "",
    //     clientTypesKeys = {},
    //     propertyTypesKeys = {}
    // ) => {
    //     return (
    //         (client_type === clientTypesKeys.BUYER ||
    //             client_type === clientTypesKeys.TENANT) &&
    //         property_type === propertyTypesKeys.LAND
    //     );
    // };

    // static showFinancingInfo = (client_type = "", clientTypesKeys = {}) => {
    //     //  client_type = parseInt(client_type);
    //     return (
    //         client_type === clientTypesKeys.BUYER ||
    //         client_type === clientTypesKeys.TENANT
    //     );
    // };

    static showMinPrice = (selectedClientType = "", clientTypesKeys = {}) => {
        //client_type = parseInt(client_type);
        return (
            selectedClientType === clientTypesKeys.BUYER ||
            selectedClientType === clientTypesKeys.TENANT
        );
    };

    static showMaxPrice = (selectedClientType = "", clientTypesKeys = {}) => {
        return (
            selectedClientType === clientTypesKeys.BUYER ||
            selectedClientType === clientTypesKeys.TENANT
        );
    };

    static showEstimatedPrice = (
        selectedClientType = "",
        clientTypesKeys = {}
    ) => {
        return (
            selectedClientType === clientTypesKeys.SELLER ||
            selectedClientType === clientTypesKeys.LANDLORD
        );
    };

    static showSize = (
        property_type = "",
        client_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) => {
        //    client_type = parseInt(client_type);
        return (
            (client_type === clientTypesKeys.SELLER ||
                client_type === clientTypesKeys.LANDLORD) &&
            (property_type === propertyTypesKeys.SINGLE_FAMILY ||
                property_type === propertyTypesKeys.MULTI_FAMILY ||
                property_type === propertyTypesKeys.CONDO ||
                property_type === propertyTypesKeys.APARTMENT)
        );
    };

    static showMinSize = (
        property_type = "",
        client_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) => {
        //    client_type = parseInt(client_type);
        return (
            (client_type === clientTypesKeys.BUYER ||
                client_type === clientTypesKeys.TENANT) &&
            (property_type === propertyTypesKeys.SINGLE_FAMILY ||
                property_type === propertyTypesKeys.MULTI_FAMILY ||
                property_type === propertyTypesKeys.CONDO ||
                property_type === propertyTypesKeys.APARTMENT)
        );
    };

    static showMaxSize = (
        property_type = "",
        client_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) => {
        //    client_type = parseInt(client_type);
        return (
            (client_type === clientTypesKeys.BUYER ||
                client_type === clientTypesKeys.TENANT) &&
            (property_type === propertyTypesKeys.SINGLE_FAMILY ||
                property_type === propertyTypesKeys.MULTI_FAMILY ||
                property_type === propertyTypesKeys.CONDO ||
                property_type === propertyTypesKeys.APARTMENT)
        );
    };

    static showBeds = (
        property_type = "",
        client_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.SELLER ||
            client_type === clientTypesKeys.BUYER ||
            client_type === clientTypesKeys.TENANT ||
            client_type === clientTypesKeys.LANDLORD) &&
        (property_type === propertyTypesKeys.SINGLE_FAMILY ||
            property_type === propertyTypesKeys.MULTI_FAMILY ||
            property_type === propertyTypesKeys.CONDO ||
            property_type === propertyTypesKeys.APARTMENT);

    static showBaths = (
        property_type = "",
        client_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.BUYER ||
            client_type === clientTypesKeys.SELLER ||
            client_type === clientTypesKeys.TENANT ||
            client_type === clientTypesKeys.LANDLORD) &&
        (property_type === propertyTypesKeys.SINGLE_FAMILY ||
            property_type === propertyTypesKeys.MULTI_FAMILY ||
            property_type === propertyTypesKeys.CONDO ||
            property_type === propertyTypesKeys.APARTMENT);

    static referralUser = (
        uid,
        address,
        companyId,
        companyName,
        email,
        lastname,
        name,
        phone,
        state,
        type,
        avatar,
        userName
    ) => {
        return {
            uid,
            address,
            companyId,
            companyName,
            email,
            lastname,
            name,
            phone,
            state,
            type: Number(type),
            avatar: avatar ? avatar : "",
            userName
        };
    };
    static validateClientInfo = (values = {}) => {
        let valid = false;
        Object.keys(values).forEach(k => {
            if (values[k] && values[k].length > 0) valid = true;
        });
        return valid;
    };

    static showClientInfo = (referral = {}, meId, referralStatusKeys = {}) => {
        return (
            referral.fromId === meId ||
            (referral.to &&
                referral.to[meId] &&
                referral.to[meId].status === referralStatusKeys.ASSIGNED)
        );
    };

    static ableToAcceptDeclineReferral = (
        referral = {},
        meId,
        referralTypesKeys = {},
        referralStatusKeys = {}
    ) => {
        let valid = false;
        const fromUser = referral.fromUser || {};
        if (
            referral.type !== referralTypesKeys.OPEN &&
            fromUser.uid !== meId &&
            referral.toUser
        ) {
            const meInTheOpportunity = referral.toUser.find(
                ({ uid }) => uid === meId
            );
            valid =
                meInTheOpportunity &&
                meInTheOpportunity.opportunityStatus ===
                    referralStatusKeys.SUBMITTED;
        }
        return valid;
    };

    static userHasDeclinedOpenOpportunity = (
        referral = {},
        meId,
        referralTypesKeys = {},
        referralStatusKeys = {}
    ) => {
        let valid = false;
        const fromUser = referral.fromUser || {};
        const isOpenOp =
            referral.type === referralTypesKeys.OPEN ||
            referral.type === referralTypesKeys.PROVIDER;
        if (
            isOpenOp &&
            fromUser.uid !== meId &&
            referral.declined &&
            referral.declined.length > 0
        ) {
            const userHasDeclined = referral.declined.find(
                ({ user }) => user && user.uid === meId
            );
            valid =
                userHasDeclined &&
                referral.status === referralStatusKeys.SUBMITTED;
        }
        return valid;
    };

    static getStatus = (referral = {}, meId) => {
        return referral.fromId === meId
            ? referral.status
            : referral && referral.to && referral.to[meId]
            ? referral.to[meId].referralStatus
            : referral.status;
    };

    // DO NOT USE
    // static referralIsNoLongerAvailable = (referral = {}, meId, statusKeys = {}) => {
    //     const status =
    //         referral.fromId === meId
    //             ? referral.status
    //             : referral && referral.to && referral.to[meId]
    //             ? referral.to[meId].referralStatus
    //             : referral.status;
    //     const statusNoLongerAvailable =
    //         status === statusKeys.ASSIGNED ||
    //         status === statusKeys.CANCELED ||
    //         status === statusKeys.EXPIRED;
    //     // Referral is not avaliable if
    //     return (
    //         referral.fromId !== meId && // User is not the author
    //         !(meId in referral.to) && // User did not claimed referral
    //         statusNoLongerAvailable // Referral has been already assigned or cancelled
    //     );
    // }

    static getStatusColor = (statusKey, currentUserIsOwner) => {
        switch (statusKey) {
            case "PENDING":
                return "darkBlue";
            case "SUBMITTED":
                return "darkBlue";
            case "CLAIMED":
                return currentUserIsOwner ? "CLAIMED" : "ASSIGNED";
            case "DECLINED":
                return "lightCarminePink";
            case "REJECTED":
                return "lightCarminePink";
            case "CANCELED":
                return "lightCarminePink";
            case "EXPIRED":
                return "EXPIRED";
            case "ASSIGNED":
                return "ASSIGNED";
            default:
                return "primary";
        }
    };

    static getStatusLabel = (
        status = "",
        statusKeys = {},
        meId,
        fromId,
        userApplied = false
    ) => {
        let response = "";
        switch (status) {
            //PENDING = WAITING FOR APPROVAL TO POST
            case statusKeys.PENDING: {
                response = "Submitted";
                break;
            }
            case statusKeys.SUBMITTED: {
                response = meId === fromId ? "Pending" : "Available";
                break;
            }
            case statusKeys.CLAIMED: {
                response = meId === fromId ? "Pending" : "Claimed";
                break;
            }
            case statusKeys.ASSIGNED: {
                response = "Awarded";
                break;
            }
            case statusKeys.DECLINED: {
                response = "Almost";
                break;
            }
            case statusKeys.CANCELED: {
                response = "Cancelled";
                break;
            }
            case statusKeys.REJECTED: {
                // CHECK IF THIS EXIST, DELETE IF IT DOESN'T
                response = "Declined";
                break;
            }
            case statusKeys.EXPIRED: {
                response = "Expired";
                break;
            }
            case statusKeys.COMPLETED: {
                response = "Completed";
                break;
            }
            case statusKeys.ON_HOLD: {
                response = "On Hold";
                break;
            }
            case statusKeys.IN_PROGRESS: {
                response = "In Progress";
                break;
            }
            case statusKeys.CLOSED: {
                response = "Closed";
                break;
            }
            case statusKeys.DEAD: {
                response = "Dead";
                break;
            }
            case statusKeys.INVALID: {
                response = "Invalid";
                break;
            }
            case statusKeys.UNCONFIRMED: {
                response = "Unconfirmed";
                break;
            }
            default: {
                response = status;
                break;
            }
        }
        return response;
    };

    static getOpProfession = (profession = [], professions = []) => {
        if (!profession || !professions || !professions.length) return "";
        const opProfessions = profession.map(prof => {
            const professionFound = professions.find(
                p => p.key === String(prof)
            );
            return professionFound ? professionFound.name : "";
        });

        return opProfessions.join(", ");
    };

    static showPremiumTooltip = (date, timeline) => {
        if (!timeline || (Array.isArray(timeline) && !timeline.length))
            return false;

        const lastIndex = timeline.length - 1;
        if (moment(timeline[lastIndex].message !== "AWARDED")) {
            return false;
        }
        const dateCreated = moment(date);
        const assignDate = moment(timeline[lastIndex].date);
        return (
            moment.duration(moment(assignDate).diff(dateCreated)).asMinutes() <
            120
        );
    };

    static ableToCancelReferral = (
        referral = {},
        meId,
        referralStatusKeys = {}
    ) => {
        const fromUser = referral.fromUser || {};
        const toUser = referral.toUser || [];
        const meInTheOpportunity = toUser.find(({ uid }) => uid === meId);
        const response =
            fromUser.uid === meId &&
            (referral.status === referralStatusKeys.SUBMITTED ||
                referral.status === referralStatusKeys.PENDING) &&
            (!toUser || !meInTheOpportunity);
        return response;
    };

    static ableToSelectCandidate = (
        fromId,
        meId,
        referralStatus,
        referralStatusKeys = {}
    ) => {
        const response =
            fromId === meId &&
            referralStatus !== referralStatusKeys.ASSIGNED &&
            referralStatus !== referralStatusKeys.CANCELED &&
            referralStatus !== referralStatusKeys.EXPIRED &&
            referralStatus !== referralStatusKeys.DECLINED;
        return response;
    };

    static showCandidatesCard = (
        referral = {},
        meId,
        referralTypeKeys = {}
    ) => {
        const response =
            referral.fromId === meId &&
            (referral.type === referralTypeKeys.OPEN ||
                (referral.to && Object.keys(referral.to).length > 1));
        return response;
    };

    static showAgreementPDF = (
        referral = {},
        myReferralStatus,
        referralStatusKeys = {},
        referralFeeTypes
    ) => {
        const response =
            referral.agreementId &&
            this.isOpAssigned(myReferralStatus, referralStatusKeys) &&
            referral.client &&
            (referral.client.email || referral.client.phone) &&
            referral.fee &&
            referral.fee.type !== referralFeeTypes.NO_FEE;
        return response;
    };

    static showMinMaxBeds = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.BUYER ||
            client_type === clientTypesKeys.TENANT) &&
        property_type === propertyTypesKeys.RESIDENTIAL &&
        (property_subtype ===
            propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].SINGLE_FAMILY ||
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].CONDO ||
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].APARTMENT);

    static showMinMaxSquareFeet = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.BUYER ||
            client_type === clientTypesKeys.TENANT) &&
        ((property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype !==
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .MULTI_FAMILY) ||
            property_type === propertyTypesKeys.COMMERCIAL);

    static showRetailSpace = (
        client_type = "",
        property_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) => {
        const isLanlord = client_type === clientTypesKeys.LANDLORD;
        const isTenant = client_type === clientTypesKeys.TENANT;
        const isCommercial = property_type === propertyTypesKeys.COMMERCIAL;

        return (isLanlord || isTenant) && isCommercial;
    };

    static showMinMaxAcres = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        return (
            (client_type === clientTypesKeys.BUYER ||
                client_type === clientTypesKeys.TENANT) &&
            (property_type === propertyTypesKeys.LAND ||
                property_subtype ===
                    propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                        .SINGLE_FAMILY)
        );
    };

    static showUnits = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        return (
            client_type === clientTypesKeys.SELLER &&
            property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].MULTI_FAMILY
        );
    };

    static showMinMaxUnits = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) => {
        return (
            (client_type === clientTypesKeys.BUYER ||
                client_type === clientTypesKeys.TENANT) &&
            (property_type === propertyTypesKeys.COMMERCIAL ||
                property_subtype ===
                    propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                        .MULTI_FAMILY)
        );
    };

    static showCreditAndPets = (
        client_type = "",
        property_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) =>
        client_type === clientTypesKeys.TENANT &&
        property_type === propertyTypesKeys.RESIDENTIAL;

    static showBedsBathsAndSize = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.SELLER ||
            client_type === clientTypesKeys.LANDLORD) &&
        property_type === propertyTypesKeys.RESIDENTIAL &&
        property_subtype !==
            propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL].MULTI_FAMILY;

    static showSquareFeetAndUnits = (
        client_type = "",
        property_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) =>
        (client_type === clientTypesKeys.SELLER ||
            client_type === clientTypesKeys.LANDLORD) &&
        property_type === propertyTypesKeys.COMMERCIAL;

    static showFinancingInfo = (client_type = "", clientTypesKeys = {}) =>
        client_type === clientTypesKeys.BUYER;

    static showBottomLabel = (
        client_type = "",
        property_type = "",
        property_subtype = "",
        clientTypesKeys = {},
        propertyTypesKeys = {},
        propertySubTypesKeys = {}
    ) =>
        property_type === propertyTypesKeys.LAND ||
        property_type === propertyTypesKeys.COMMERCIAL ||
        (property_type === propertyTypesKeys.RESIDENTIAL &&
            property_subtype ===
                propertySubTypesKeys[propertyTypesKeys.RESIDENTIAL]
                    .MULTI_FAMILY);

    static showHomeSaleContingency = (
        client_type = "",
        property_type = "",
        clientTypesKeys = {},
        propertyTypesKeys = {}
    ) => {
        const isBuyer = client_type === clientTypesKeys.BUYER;
        const isResidential = property_type === propertyTypesKeys.RESIDENTIAL;

        return isBuyer && isResidential;
    };

    static showAgreenment = (
        fee_type = "",
        feeTypesOptions = {},
        formValues = {}
    ) => {
        const feeValidate = {
            [feeTypesOptions.FLAT]: () =>
                formValues.flatFeeAmount > 0 && formValues.paymentDue,
            [feeTypesOptions.GROSS_REVENUE]: () =>
                formValues.grossRevenueFee > 0 &&
                formValues.feeDuration &&
                formValues.feeFrequency &&
                formValues.feeCommencement,
            [feeTypesOptions.NO_FEE]: () => null,
            [feeTypesOptions.OTHER]: () =>
                formValues.comments && formValues.comments.length > 0,
            [feeTypesOptions.REAL_ESTATE]: () => formValues.realEstateFee > 0
        };

        return feeValidate[fee_type] && feeValidate[fee_type]();
    };

    static getReferralTimeRemainingByPlan = (date, isEssentialPlus) => {
        const hour = 60 * 60 * 1000;
        const timeToAdd = isEssentialPlus ? hour : hour * 2;
        const remainingTime = new Date(date).getTime() + timeToAdd;
        const now = new Date().getTime();
        return now > remainingTime
            ? "Now"
            : moment(remainingTime).format("h:mm a");
    };

    static getRouteByOpportunityType = opportunityType => {
        const routes = {
            [OPPORTUNITIES_MODEL.REFERRAL]: "referral",
            [OPPORTUNITIES_MODEL.RECOMMENDATION]: "intro",
            [OPPORTUNITIES_MODEL.JOB]: "job"
        };

        return routes[opportunityType] || "referral";
    };

    static getReferralDate = (referral = {}) => {
        // Date is creation date while submittedAt is the date when the referral was validated and successfully submitted
        const { date, submittedAt } = referral;
        // For retrocompatibility reasons, if submittedAt is not present, return date
        return submittedAt || date;
    };

    static getReferralFeeToFlow = form => {
        let feeData;
        if (form.referralFee.key === "NO_FEE") {
            return (feeData = {
                type: form.referralFee ? form.referralFee.key : null
            });
        } else if (form.referralFee.key === "GROSS_REVENUE") {
            return (feeData = {
                type: form.referralFee ? form.referralFee.key : null,
                data: {
                    fee: form.grossRevenueFee && form.grossRevenueFee,
                    duration: form.feeDuration && form.feeDuration.key,
                    commencement: form.feeCommencement
                        ? form.feeCommencement.key
                        : "",
                    comments: form.comments ? form.comments : "",
                    frequency: form.feeFrequency && form.feeFrequency.key
                }
            });
        } else if (form.referralFee.key === "FLAT") {
            return (feeData = {
                type: form.referralFee ? form.referralFee.key : null,
                data: {
                    due: form.paymentDue ? form.paymentDue.key : null,
                    comments: form.comments ? form.comments : "",
                    fee: form.flatFeeAmount ? form.flatFeeAmount : null
                }
            });
        } else if (form.referralFee.key === "REAL_ESTATE") {
            return (feeData = {
                type: form.referralFee ? form.referralFee.key : null,
                data: {
                    fee: form.realEstateFee ? form.realEstateFee : null
                }
            });
        } else if (form.referralFee.key === "OTHER") {
            return (feeData = {
                type: form.referralFee ? form.referralFee.key : null,
                data: {
                    comments: form.comments ? form.comments : ""
                }
            });
        }
    };
}
