import styled from "styled-components";
import React from "react";
import { css } from "styled-components";
import { getThemeColor, getThemeOnColor } from "../../../../../../../theme";
import PropTypes from "prop-types";
import { Image } from "rebass";

const SquareButton = styled.button`
    user-select: none;  
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    font-weight: bold;
    letter-spacing: 0;
    padding: .8em 1.5em .8em 1.5em;
    ${props =>
        props.disabled
            ? css`
                  background-color: lightGrey;
              `
            : props.bg
                ? css`
                  background-color: ${props.bg};
              `
                : css`
                  background-color: ${getThemeColor(props)};
              `}
    ${props =>
        props.color
            ? css`
                  color: ${props.color};
              `
            : css`
                  color: ${getThemeOnColor(props)};
              `}
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
    /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
    /* min-height: ${props => props.height ? props.height : '3em'}; */
    min-width: ${props => props.theme.mobileDevice ? '100px' : '137px'};
    outline: none;
    margin: 0;
    border: 0;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'};
`;



const GoogleImage = styled(Image)`
    max-height: 2rem;
    padding-right: 1em;
`;

export default props => (
    <SquareButton {...props} onClick={props.callback} type={props.type}  >
        {props.icon && <GoogleImage alt={props.text || ""} src={props.icon} width={props.height ? `${props.height / 2}px` : '30px'} height={props.height ? `${props.height / 2}px` : '30px'} />}
        {props.text && props.text}
    </SquareButton>
);

SquareButton.propTypes = {
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    bg: PropTypes.string,
    color: PropTypes.string
};
