import styled from "styled-components";
import { Flex } from "rebass";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const Badge = styled(Flex)`
    position: absolute;
    top: 0.2rem;
    right: -0.1rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 0.5rem;
    margin: auto;
    background-color: #1FC07F;
`;

export const NumberBadge = styled.div`
    position: absolute;
    top: 0rem;
    right: -0.75rem;
    height: 0.5rem;
    width: 0.5rem;
    background: ${({ color }) => color};
    color: ${({ theme }) => theme.white};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${({ theme }) => theme.spacing[2]};
    margin-top: ${({ marginTop }) => `${marginTop}px`};
    font-size: 10px;
`

export const Tab = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    /* Previous styles for tabs */
    // border: ${props =>
        `1px solid ${
            props.current ? props.theme.blueMedium : props.theme.darkGray
        }`};
    //border-right: ${({ theme, first, last, current }) =>
        last
            ? `1px solid ${current ? theme.blueMedium : theme.darkGray}`
            : "none"};
    //border-left: ${({ theme, first, last, current }) =>
        first
            ? `1px solid ${current ? theme.blueMedium : theme.darkGray}`
            : "none"};
    // font-weight: ${props => (props.current ? "600" : "500")};

    border-bottom: ${props =>
        `5px solid ${
            props.current ? props.theme.blueMedium : props.theme.darkGray
        } `};
    // border-right: ${({ theme, first, last, current }) =>
        last ? `1px solid ${theme.blueMedium}` : "none"};
    // border-left: ${({ theme, first, last, current }) =>
        first ? `1px solid ${theme.blueMedium}` : "none"};
    // border-radius: ${({ first, last }) =>
        first ? "4px 0 0 4px" : last ? "0 4px 4px 0" : "0 0 0 0"};
    padding: .5em 1em .5em 1em;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.34px;
    line-height: 19px;
    color: ${props => (props.current ? "black" : props.theme.darkGray)};
    // background-color: ${props =>
        props.current ? props.theme.blueMedium : "transparent"};
    transition: all .25s ease;
    min-width: 110px;
`;
