import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { MainText, StepWrapper, Button, ButtonContainer } from "../../components";
import { NotAMemberInfo } from '../../../../shared/PeopleSearchBar/UserInfo';
import { Formik, useFormikContext} from 'formik';
import * as yup from 'yup'
import { InputCounter } from "../../../Settings/InformationAccount/styles";
import { TextArea } from "../../../Settings/common/Components";
import { SuggestionsContainer, Suggestion, Contact } from '../CandidateSelection/view';
import CheckBox from "../../../../atoms/shared/Inputs/Checkbox";
import { Box, Flex } from "rebass";
import { Icon } from "../../../../atoms/shared/Icon";
import CreateContactModal from "../../../../atoms/shared/CreateContactModal";
import {DraftModal, useDraft} from "../../useDraft";
import _ from "lodash";
import UserInfo from "../../../../atoms/shared/PeopleSearchBar/UserInfo";
import { WhiteButton } from "../ReferralCompleteInformation/styles";
import PeopleSearchBar from "../../../../atoms/shared/PeopleSearchBar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;
`;

const InputContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 2em 0;
    flex-direction: column;
`;

const formSchema = (opMethod) => yup.object().shape({
    // receiver: yup.object().required(),
     description: yup.string().required().min(opMethod === 'DIRECT' ? 31 : 130).max(2000),
     shareInfoBetweenContacts: yup.boolean(),
 })

const SearchSuggestionWrapper = styled.div`
    width: 100%;
    border: 1px solid #929292;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 50px;
`;

const HelpText = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #929292;
    padding: 1em 0em;
`;

const ActionableText = styled.span`
    color: #057AFF;
    text-decoration: underline;
    cursor: pointer;
`;

const RecommendationDirectInformation = ({ candidates, professions, theme, setStepData, stepData }) => {
    const onSubmitForm = (values) => {
        setStepData(values)
    };

    const [suggestion, setSuggestion] = useState(null);
    const [recommendationContact, setRecommendationContact] = useState(null);
    const [candidateCreationVisible, setCandidateCreationVisible] = useState(false);
    const [candidatesList, setCandidates] = useState([]);
    const [contactsList, setContacts] = useState([]);
    const [candidatesNames, setCandidatesNames] = useState('')
    const { loading, saveDraft, showModal, setShowModal, modalMessage} = useDraft();
   const checkboxLabel = suggestion ? `Do you want to share ${suggestion.first_name}'s contact information with ${candidatesNames}?` 
                         : recommendationContact ? `Do you want to share ${recommendationContact.first_name}'s contact information with ${candidatesNames}` : '';
 
     const opMethod = candidates.length === 1 ? 'DIRECT' : 'NON-DIRECT';
     const formSchemaBuilder = formSchema(opMethod)                     


    useEffect(() => {
        if (candidates) {
            const stepContacts = candidates.filter(c => c.contactUuid);
            const stepCandidates = candidates.filter(c => !c.contactUuid);
            setCandidates(stepCandidates);
            setContacts(stepContacts);
            const names = candidates.map((c) =>c.first_name).join(', ')
            const lastIndex = names.lastIndexOf(',')
            const replacement = ' and'
            setCandidatesNames(lastIndex !== -1 ? names.substring(0, lastIndex) + replacement + names.substring(lastIndex + 1) : names)
        }
    }, [candidates]);

    const formInitialValues = useMemo(() => {
        if (!stepData) return {
            receiver: null,
            description: '',
            shareInfoBetweenContacts: false,
        }
        if (stepData.receiver) {

           if (stepData.receiver.noMemberYet) {
               setRecommendationContact(stepData.receiver)
           }  else {
            setSuggestion(stepData.receiver)
           }
            
        }
        return stepData;
    }, [stepData]);




    const createContact = ({ email, first_name, last_name, phone, contact_id, work_info }, setFieldValue) => {
        const contact = {
            noMemberYet: true,
            email,
            first_name,
            last_name,
            phone,
            contact_id,
            uid: contact_id,
            company_name: work_info && work_info.company,
            locationAddress: work_info && work_info.address && work_info.address && work_info.address.city ? work_info.address.city : '',
            subscription: {
                plan: ""
            },
            type: "CONTACT"
        }
        setFieldValue("receiver",  contact)
       setRecommendationContact(contact)
       //setSuggestion(contact)
    };



    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={formInitialValues}
                onSubmit={onSubmitForm}
                validationSchema={formSchemaBuilder}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    isValid, 
                    setFieldValue
                }) => (
                    <Container>
                        <MainText>{candidatesNames ? `Recommend ${candidatesNames} to:` : 'Send recomendation to:'}</MainText>
                        <InputContainer>
                            {(!suggestion && !recommendationContact) && (
                                <Container>
                                    <PeopleSearchBar
                                        error={errors.receiver}
                                        includeContacts
                                        clearSelection
                                        onClickSuggestion={
                                            (s) => {
                                                setSuggestion(s)
                                                handleChange({
                                                    target: {
                                                        name: "receiver",
                                                        value: s,
                                                    }
                                                })
                                            }
                                        }
                                    />
                                    <Flex mt={theme.spacing[3]}>
                                        <WhiteButton onClick={() => setCandidateCreationVisible(true)}>
                                            <Icon
                                                icon="profile"
                                                width={12}
                                                height={12}
                                                color={theme.newBlue}
                                                style={{ marginRight: theme.spacing[2] }}
                                            />
                                            Create Contact
                                        </WhiteButton>
                                    </Flex>
                                </Container>
                            )}
                            {(suggestion) && (
                                <SearchSuggestionWrapper>
                                   <UserInfo
                                        id={suggestion._id ? suggestion._id : ""}
                                        avatarUrl={(  suggestion && suggestion.avatar) ? suggestion.avatar : ""}
                                        name={( suggestion && suggestion.full_name )? suggestion.full_name : suggestion.first_name}
                                        organization={(suggestion && suggestion.company_nam) ? suggestion.company_name : ""}
                                        tier={(suggestion && suggestion.subscription.plan) ? suggestion.subscription.plan : ""}
                                        location={(suggestion && suggestion.locationAddress )? suggestion.locationAddress: ""}
                                        background={'#FAFAFA'}
                                        firstName={suggestion.first_name}
                                        lastName={suggestion.last_name}
                                    />
                                    <Icon
                                        icon={'close'}
                                        color={"#929"}
                                        onClick={() => {
                                            setSuggestion(null)
                                            handleChange({
                                                target: {
                                                    name: "receiver",
                                                    value: null,
                                                }
                                            })
                                        }}
                                    /> 
                                   
                                </SearchSuggestionWrapper>
                            )}
                            {recommendationContact && (
                                <SearchSuggestionWrapper>
                                    <NotAMemberInfo
                                        firstName={recommendationContact.first_name}
                                        lastName={recommendationContact.last_name}
                                        email={recommendationContact.email}
                                        mobilePhone={recommendationContact.mobilePhone}
                                        background={'#FAFAFA'}
                                    />
                                    <Icon
                                        icon={'close'}
                                        color={"#929292"}
                                        onClick={() => setRecommendationContact(null)}
                                    />
                                </SearchSuggestionWrapper>
                            )}
                        </InputContainer>
                        {(suggestion || recommendationContact) && (<Flex m={'2em 0'}>
                            <CheckBox
                                inverted
                                label={checkboxLabel}
                                onChange={ev => {
                                    handleChange({
                                        target: {
                                            name: "shareInfoBetweenContacts",
                                            value: !values.shareInfoBetweenContacts,
                                        }
                                    });
                                }}
                                checkBoxColor={theme.blueMedium}
                                checked={values.shareInfoBetweenContacts}
                            />
                        </Flex>)
                        }
                        <MainText>Description</MainText>
                        <InputContainer>
                            <TextArea
                                style={{ resize: "vertical" }}
                                type="text"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                maxLength="2000"
                                minLength="30"
                                placeholder={candidates.length === 1 ? 'Using a minimum of 31 characters, please tell potential candidates about your recommendation.*' 
                                : 'Using a minimum of 130 characters, please tell potential candidates about your referral.*'}
                                error={!!errors.description}
                            />
                            {!!values.description &&
                                !!values.description.length && (
                                    <InputCounter>
                                        {values.description.length}/2000
                                    </InputCounter>
                                )}
                        </InputContainer>
                        <SuggestionsContainer>
                            
                            {
                                candidatesList
                                .filter(o => o)
                                .map(u => (
                                    <Suggestion
                                    key={u.uid}
                                        user={u}
                                        professions={professions}
                                    />
                                ))
                            }
                            {
                                contactsList
                                .filter( c => c.contact_id)   
                                .map(c => (
                                    <Contact
                                        key={c.contact_id}
                                        email={c.email}
                                        firstName={c.first_name}
                                        lastName={c.first_name}
                                        uuid={c.first_name}
                                    />
                                ))
                            }
                        </SuggestionsContainer>
                        <ButtonContainer>
                            {isValid && <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={saveDraft}
                                >
                                    Save Draft
                                </Button>
                            </Box>}
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                        <CreateContactModal
                                open={candidateCreationVisible}
                                onClose={() => setCandidateCreationVisible(false)}
                                onContactCreated={(contact) =>  createContact(contact, setFieldValue)}
                                subtitle={'This will create a new contact, and invite them to review this recommendation.'}
                        />
                    </Container>
                )}
            </Formik>
           
        </StepWrapper >
    );
}

export default RecommendationDirectInformation;