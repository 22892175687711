import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../redux/actions/creators/recommendationCreation';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.recommendationCreationReducer.currentStep,
        stepData: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION],
        professions: state.main.ui.global.statics.userTypes || [],
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(View);