import styled from 'styled-components';


export const StyledTooltip = styled.div`
    display: inherit;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    background: rgba(60,64,67,0.90);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #ffffff;
    -webkit-letter-spacing: .8px;
    -moz-letter-spacing: .8px;
    -ms-letter-spacing: .8px;
    letter-spacing: .8px;
    line-height: 16px;
    margin-top: 8px;
    min-height: 14px;
    padding: 4px 8px;
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    z-index: 1000;
    transform: translateX(-50%);
    left: 50%;
    opacity: 0;
    transition: all .2s ease-in-out;
    pointer-events:none;
`

export const TooltipContainer = styled.div`
    position: relative;
    
`