import styled from "styled-components";

export const FieldSet = styled.div`
    position: relative;
    border: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const InputMaterial = styled.input`
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: ${props => `url(${props.icon}) no-repeat scroll 0px 0px`};
    font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
    color: ${props => (props.color ? props.color : props.theme.coolBlack)};
    width: 100%;
    max-width: 100%;
    border: none;
    padding-left: ${props => (props.icon ? "25px" : "0px")};
    padding-bottom: 5px;
    margin-top: 25px;
    height: 25px;
    /* margin-top: ${props => (props.icon ? "25px" : "25px")}; */
    & :focus {
        outline: none;
    }

    & ::placeholder {
        color: ${props =>
            props.color ? props.color : props.theme.darkGray};
        font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
        font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
        line-height: ${props => (props.lineHeight ? props.lineHeight : '20px')};
        letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : ' -0.41px')};
    }
`;

export const InputLabel = styled.label`
    
    position: absolute;
    font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
    
    top: 0px;
        left: ${props => (props.icon ? "0px" : "0px")};
        font-size: 11px;
        opacity: 0.6;
        
    ${InputMaterial}:focus ~ & {
       
        top: 0px;
        left: ${props => (props.icon ? "0px" : "0px")};
        font-size: 11px;
        opacity: 0.6;
      
    }

    ${InputMaterial}:valid ~ & {
       
        top: 0px;
        left: ${props => (props.icon ? "0px" : "0px")};
        font-size: 11px;
        opacity: 0.6;
        
    }
`;

export const After = styled.div`
    width: 100%;
    height: 1px;
    background: ${props =>
        props.color
            ? `linear-gradient(to right, ${props.color} 50%, transparent 50%)`
            : `linear-gradient(to right, ${
                  props.theme.coolBlack
              } 50%, transparent 50%)`};
    background-color: #e4e4e4;
    background-size: 200% 100%;
    background-position: 100% 0;
    transition: all 0.6s ease;

    ${InputMaterial}:focus ~ & {
        background-position: 0 0;
    }
`;
