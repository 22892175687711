export const downloadFromCloud = [
    "90 90",
    `<g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)" stroke="none">
<path d="M460 737 c-19 -6 -58 -34 -86 -62 -47 -47 -54 -51 -91 -47 -60 6
-120 -28 -143 -81 -11 -24 -34 -52 -57 -67 -44 -29 -83 -99 -83 -147 0 -38 23
-98 48 -127 9 -10 35 -27 57 -37 36 -17 70 -19 343 -19 318 0 345 3 396 48 27
24 56 91 56 132 0 51 -38 121 -82 149 -23 15 -38 32 -38 44 0 50 -27 104 -75
152 -70 71 -152 91 -245 62z m18 -296 l3 -94 24 23 c27 25 58 23 63 -4 5 -22
-93 -126 -118 -126 -25 0 -123 104 -118 126 5 28 36 29 64 3 l24 -22 0 90 c0
49 3 93 7 97 4 4 16 6 28 4 18 -3 20 -11 23 -97z"/>
</g>`
];
