import {
    OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    OPPORTUNITY_FLOW_RESET,
    SAVE_REFERRAL_DRAFT,
    LOAD_REFERRAL_DRAFT,
    CURRENT_REFERRAL_LOADING,
    CURRENT_REFERRAL_SUCCESS,
    CURRENT_REFERRAL_ERROR,
    NEW_CONTACT_SAVED,
    CONTACT_IS_LOADING,
    CONTACT_ERROR,
    CONTACT_SAVED,
    CANCEL_CONTACT_ERROR
  } from "../../../../actions/constants";
  
  import {
    OPPORTUNITY_CREATION_STEPS,
    REFERRAL_PRIVACY,
    RECOMMENDATION_TYPE,
    OPPORTUNITY_TYPES,
  } from "../../../../../components/ui/presentation/views/RecommendationCreation/constants";
  import {
    OPPORTUNITY_CREATION_STEPS_ITEMS,
    FLOW_STEPS_ITEMS,
    RECOMMENDATION_CANDIDATES_FLOW_STEPS_ITEMS,
 
  } from "../../../../../components/ui/presentation/views/RecommendationCreation/Steps/steps";
  
  const initialState = {
    currentStep: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_TYPE,
    navbarItems: FLOW_STEPS_ITEMS[OPPORTUNITY_TYPES.RECOMMENDATION],
    stepData: {
      OPPORTUNITY_TYPE_STEP: {
        value: OPPORTUNITY_TYPES.RECOMMENDATION, label: "Recommendation"
      }
    },
    newContact: {},
    draftId: null,
    referralIsLoading: false,
    successReferral: false,
    contactIsSaving: false,
    errorStoringSavingContact: false,
    errorReferral: false
  };
  
  function recommendationCreationReducer(state = initialState, action) {
  
    switch (action.type) {

        
      case OPPORTUNITY_FLOW_RESET:
           return initialState;

      case OPPORTUNITY_FLOW_SET_ACTIVE_STEP:
           return returnActiveStep(state, action);
 
      case SAVE_REFERRAL_DRAFT:
          return loadDraft(state, action.payload.draftId);
      
      case OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION:
           return referralProfessionStateTransform(state, action.payload.data);
  
      case OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION:
           return referralCandidatesSelectionStateTransform(state, action.payload.data);
    
      case OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_TYPE:
           return recommendationTypeSelectionStateTransform(state, action.payload.data);

      case OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION:
           return recommendationInformationStateTransform(state, action.payload.data);

      case OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION:
           return directRecommendationInformationStateTransform(state, action.payload.data);
     
      case 'SAVE_STEP_DATA':
            return saveStepData(state, action.payload.data);

      case LOAD_REFERRAL_DRAFT:
           return loadDraft(state, action.payload.draftId);

      case CURRENT_REFERRAL_LOADING:
           return loadingReferralCurrent(state, action.payload);

      case CURRENT_REFERRAL_SUCCESS:
           return successReferral(state, action.payload);

      case CURRENT_REFERRAL_ERROR:
           return referralErrror(state, action.payload);

      case CONTACT_IS_LOADING: 
           return contactIslogading(state, action.payload);

      case CONTACT_SAVED: 
           return contactSavedSuccess(state, action.payload);
           
      case CONTACT_ERROR:
           return contactError(state, action.payload);

      case CANCEL_CONTACT_ERROR:
           return cancelError(state, action.payload);

      case NEW_CONTACT_SAVED:
           return loadNewContactData(state, action.payload);

      default:
        return state
    }
  };


  const returnActiveStep = ( state, action ) => {
    const _state = {
      ...state,
      currentStep: action.payload.step,
    };
    
    return _state;
  }
  
  const contactSavedSuccess = (state, action) => {
    const _state = {
      ...state,
      contactIsSaving: false,
    }
  
    return _state
  }
  
  const contactIslogading = (state, action) => {
    const _state = {
      ...state,
      contactIsSaving: true,
      errorStoringSavingContact: false
    }
  
    return _state
  }
  
  const contactError = (state, action) => {
    const _state = {
      ...state,
      errorStoringSavingContact: true,
      contactIsSaving: false
    }
  
    return _state
  }
  
  const cancelError = (state, action) => {
    const _state = {
      ...state,
      errorStoringSavingContact: false,
      contactIsSaving: false
    }
  
    return _state
  }
  
  const loadNewContactData = (state, contacts) => {
    const _state = {
      ...state,
       newContact: contacts
    }
  
    return _state
  }
  
  const loadingReferralCurrent = (state, action) => {
    const _state = {
      ...state,
      referralIsLoading: true,
    }
  
    return _state
  }
  
  const successReferral = (state, action) => {
    const _state = {
      ...state,
      referralIsLoading: false,
      successReferral: true,
      draftId: null
    }
  
    return _state
  }
  
  
  const referralErrror = (state, action) => {
    const _state = {
      ...state,
      referralIsLoading: false,
      errorReferral: true
  
    }
  
    return _state
  }
  
  
  const loadDraft = (state, draftId) => {
    const _state = {
      ...state,
      draftId
    }
  
    return _state;
  }
  
  const saveStepData = (state, data) => {
    const _state = {
      ...state,
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
      }
    }
  
    return _state
  }
  
 
  
  const referralCandidatesSelectionStateTransform = (state, data) => {
    const _state = {
      ...state,
      currentStep: getNextStep(state.currentStep, state.navbarItems),
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
        [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: null,
      },
    };
  
    return _state;
  };
  

  
  const referralProfessionStateTransform = (state, data) => {
    const _state = {
      ...state,
      currentStep: getNextStep(state.currentStep, state.navbarItems),
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
        [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
      },
    };
    return _state;
  };
  
  
  
  const recommendationTypeSelectionStateTransform = (state, data) => {
    let flow = [...RECOMMENDATION_CANDIDATES_FLOW_STEPS_ITEMS];
  
    if (data.value === RECOMMENDATION_TYPE.PROVIDER) {
      flow = [...FLOW_STEPS_ITEMS[OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]]
    }
  
    const _state = {
      ...state,
      navbarItems: flow,
      currentStep: getNextStep(state.currentStep, flow),
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
        [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
        [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION]: null,
        [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]: null,
      }
    };
  
    return _state;
  };
  

  
  const recommendationInformationStateTransform = (state, data) => {
    const _state = {
      ...state,
      currentStep: getNextStep(state.currentStep, state.navbarItems),
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
        [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION]: {},
      },
    };
  
    return _state;
  };
  
  const directRecommendationInformationStateTransform = (state, data) => {
    const _state = {
      ...state,
      currentStep: getNextStep(state.currentStep, state.navbarItems),
      stepData: {
        ...state.stepData,
        [state.currentStep]: data,
        [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]: {},
      },
    };
  
    return _state;
  };
  
  const getNextStep = (currentStep, flow) => {
    const currentIndex = flow.findIndex((s) => s.code === currentStep);
    const nextIndex = (currentIndex + 1) % flow.length;
    return flow[nextIndex].code;
  };
  
  export default recommendationCreationReducer;
  