import {
    CHAT_ISLOADING,
    CHAT_CONVERSATIONS_LOADING,
    CHAT_SETCONVERSATIONS,
    CHAT_FETCHFAIL,
    CHAT_SELECT_CONVERSATION,
    CHAT_SET_CONVERSATION_MESSAGES,
    CHAT_ADD_CONVERSATION_MESSAGE,
    CHAT_RESET_CONVERSATIONS_MESSAGES,
    CHAT_CONVERSATION_SET_USER_STATUS,
    CHAT_UPDATECONVERSATION,
    CHAT_OPEN_PROFILE_DETAILS,
    CHAT_FETCH_CONTACT_SUCCESS,
    CHAT_FETCH_CONTACT_FAIL,
    CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS,
    CHAT_CLEAN
} from "../../../../actions/constants";
const initialState = (initState) => ({
    isLoading: false,
    conversationsLoading: false,
    selectedConversation: '',
    conversations: {},
    messages: {},
    openProfileDetails: false,
    contactList: [],
    ...initState
});

function chatReducer(state = initialState(), action) {
    switch (action.type) {
        case CHAT_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case CHAT_CONVERSATIONS_LOADING: {
            const _state = {
                ...state
            };
            _state.conversationsLoading = action.payload.conversationsLoading;
            return _state;
        }
        case CHAT_CLEAN: {
            const newState = Object.assign(
                {},
                initialState()
            );
            return newState;
        }
        case CHAT_SETCONVERSATIONS: {
            const _state = {
                ...state
            };
            _state.conversations = {
                ..._state.conversations,
                ...action.payload.conversations
            };
            return _state;
        }
        case CHAT_UPDATECONVERSATION: {
            const _state = {
                ...state
            };
            _state.conversations = {
                ..._state.conversations,
                [action.payload.uid]: {
                    ..._state.conversations[action.payload.uid],
                    data: { ...action.payload.conversation }
                }
            };
            return _state;
        }
        case CHAT_SELECT_CONVERSATION: {
            const _state = {
                ...state
            };
            _state.selectedConversation = action.payload.conversationId;
            return _state;
        }

        case CHAT_SET_CONVERSATION_MESSAGES: {
            const _state = {
                ...state
            };
            _state.messages = {
                ..._state.messages,
                [action.payload.conversationId]: action.payload.messages
            };
            return _state;
        }
        case CHAT_ADD_CONVERSATION_MESSAGE: {
            const _state = {
                ...state
            };
            _state.messages = {
                ..._state.messages,
                [action.payload.conversationId]: [
                    ...(_state.messages[action.payload.conversationId]
                        ? _state.messages[action.payload.conversationId]
                        : []),
                    action.payload.message
                ]
            };
            return _state;
        }
        case CHAT_CONVERSATION_SET_USER_STATUS: {
            const _state = {
                ...state
            };

            _state.conversations = {
                ..._state.conversations,
                [action.payload.conversationId]: {
                    ..._state.conversations[action.payload.conversationId],
                    user_status: { ...action.payload.user_status }
                }
            };
            return _state;
        }
        case CHAT_RESET_CONVERSATIONS_MESSAGES: {
            const _state = {
                ...state
            };

            _state.messages = initialState().messages;
            return _state;
        }
        case CHAT_OPEN_PROFILE_DETAILS: {
            const _state = {
                ...state
            };

            _state.openProfileDetails = action.payload.openProfileDetails;
            return _state;
        }
        case CHAT_FETCH_CONTACT_SUCCESS: {
            const _state = {
                ...state
            };

            _state.contactList = action.payload;
            return _state;
        }
        case CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS: {
            const _state = {
                ...state
            };
            _state.conversations = Object.entries(_state.conversations).reduce(
                (acc, [key, value]) => {
                    if (value.data.newConversation) return acc;
                    return { ...acc, [key]: value };
                },
                {}
            );
            return _state;
        }
        default:
            return state;
    }
}

export default chatReducer;
