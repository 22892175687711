import {
    REHYDRATE_USER,
    SHARED_REALSTATEFOCUS_SUCCESS,
    NEWPWAVERSION_AVAILABLE,
    SET_SERVICES,
    LAYOUT_BACKGROUND_COLOR,
    CHATBOT_OPEN,
    GLOBAL_STATICS_FETCH_SUCCESS,
    GLOBAL_MAITENANCE_MODE,
    MAINTENANCE_ALERT,
    GLOBAL_ALLOW_ACCESS_TO_DEV,
    GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING
} from "../constants";
import { push } from "connected-react-router";
import { cleanUser } from "./user";
import config from "../../../config";
import GeolocationManager from "../../../libs/GeolocationManager";
import {
    subscribeToUserDataChanged,
    fetchProfileSubscription
} from "./user";
import { getCurrentPushToken, onMessage } from "./pushNotifications";
import {
    // subscribeToBellNotficationsAdded,
    subscribeToNotifications,
    subscribeToBellOpened,
    fetchConnectionRequests
} from "./navbar";
import {
    subscribeToNewsAdded,
    subscribeToNewNewsNotification
} from "./community";
import { getUsersToConnect } from "./relations";
import { checkStripeCardStatus } from "./settings";
import { identifyZohoUser } from '../../../libs/Zoho'
import i18next from 'i18next';
import ReferralBusiness from '../../../business/referral'
import { subscribeToMyConversationsAdded, subscribeToMyConversationsChanged } from './chat'
import moment from "moment";
import { handleError } from "./modal";
import { toast } from "react-toastify";
import { MEDIABREAKPOINTS } from "../../../theme";
import { clarity } from "clarity-js"
import Utils from "../../../libs/Utils";
import packageJson from "../../../../package.json";
const geolocationManager = new GeolocationManager();
const rehydrateProfileOnStore = (
    uid,
    token,
    next,
    stayLoggedIn = true,
    addDataToProfile = {}
) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        if (stayLoggedIn && token) {
            /* Appending authorization token to HTTP Client */
            cloudfunctions.appendToken(token, uid);
            let profile = await firebase.fetchProfileById(uid);
            const {
                sentReferralsCount,
                receivedReferralsCount
            } = await firebase.fetchUserReferralExtraInfo(uid, ReferralBusiness);
            const providerData = await firebase.providerData();

            const isSocialLogin =
                providerData &&
                providerData[0] &&
                (providerData[0].providerId === "google.com" || providerData[0].providerId === "apple.com");

            profile = { ...profile, ...addDataToProfile };
            const payload = {
                uid: uid,
                profile,
                token,
                idToken: token,
                isLoggedIn: true,
                sentReferralsCount: sentReferralsCount,
                receivedReferralsCount: receivedReferralsCount,
                isSocialLogin: isSocialLogin
            };
            console.log(payload.profile)
            /* Setting user logged in state in local storage */
            localStorage.setItem(config.localStorage.IS_LOGGED_IN, "true");

            let newStorage;
            /* Getting last user connected */
            const currentUser = localStorage.getItem(
                config.localStorage.USER_PROFILE
            );
            if (!currentUser) {
                newStorage = Object.assign({}, payload);
            } else {
                newStorage = Object.assign({}, JSON.parse(currentUser), payload);
            }

            localStorage.setItem(
                config.localStorage.USER_PROFILE,
                JSON.stringify(newStorage)
            );

            if (!profile.qr_profile) {
                const result = await cloudfunctions.generateProfileQRID();
                console.log("generating QR_PROFILE", result);
            }

            localStorage.setItem(
                config.localStorage.LAST_LOGGED_IN_USER,
                JSON.stringify({
                    userName: profile.userName,
                    uid,
                    name: profile.name,
                    lastname: profile.lastname
                })
            )

            dispatch({
                type: REHYDRATE_USER,
                payload: {
                    uid,
                    ...newStorage,
                    profile,
                    isLoggedIn: true,
                    isSocialLogin: isSocialLogin,
                    idToken: token
                }
            });
            identifyZohoUser(profile);
            // identifyLogRocketUser(uid, profile);
            dispatch(startGeolocationService(profile));
            dispatch(subscribeAndFetchFirebaseData(uid));
            dispatch(getConnected(uid));
            //dispatch(checkStripeCustomerId());
            dispatch(checkStripeCardStatus());
            if (window.fbq) {

                window.fbq("trackCustom", {
                    name: `${profile.name} ${profile.lastname}`,
                    email: profile.email,
                    phone: profile.phone
                });
            }
            if (next) dispatch(push(next));
        } else {
            return dispatch(cleanUser());
        }
    }
}

const getConnected = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(
            setServices({
                getConnectedLoaded: true
            })
        );
        dispatch(getUsersToConnect(uid));
    };
};

/* const identifyZohoUser = profile => {
    console.log("identifyZohoUser", `${profile.name} ${profile.lastname}`);
    if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.visitor) {
        if (profile.name)
            window.$zoho.salesiq.visitor.name(
                `${profile.name} ${profile.lastname}`
            );
        if (profile.email) window.$zoho.salesiq.visitor.email(profile.email);
    }
    return;
}; */


const openChat = profile => {

    if (window.$zoho && window.$zoho.salesiq && window.$zoho.salesiq.visitor) {

    }
    return;
};


const startGeolocationService = profile => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            /* const securityAndLocationEnabled =
                (profile && !profile.settings) ||
                (profile.settings.privacy &&
                    (typeof profile.settings.privacy
                        .securityAndLocation === "undefined" ||
                        typeof profile.settings.privacy
                            .securityAndLocation)); */

            // if (securityAndLocationEnabled) {
            const locationPromise = geolocationManager.getCurrentPosition();

            locationPromise
                .then(location =>
                    dispatch(
                        setServices({
                            geolocationLoading: false,
                            geolocationInitialized: true,
                            geolocationAvailiable: true,
                            geolocationError: false,
                            center: {
                                lat: location.lat,
                                lon: location.lon
                            }
                        })
                    )
                )
                .catch(error => {
                    console.log("startGeolocationService error", error);
                    geolocationDisabled();
                });
        } catch (error) {
            console.log("geolocationDisabled error", error);
            geolocationDisabled();
        }
    };
};

const geolocationDisabled = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch(
            setServices({
                geolocationLoading: false,
                geolocationInitialized: true,
                geolocationAvailiable: false,
                geolocationError: true
            })
        );
    };
};

const subscribeAndFetchFirebaseData = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        /* INITIAL DATA */
        dispatch(fetchProfileSubscription(uid));
        dispatch(fetchStatics());
        /* SUBSCRIPTIONS */
        dispatch(subscribeToNotifications(uid, null))
        dispatch(fetchConnectionRequests(uid))
        dispatch(subscribeToBellOpened(uid))
        dispatch(subscribeToNewNewsNotification(uid));
        dispatch(subscribeToNewsAdded());
        // dispatch(subscribeToBellNotficationsAdded(uid));
        dispatch(setServices({ subscribedToFirebase: true }));
        dispatch(subscribeToUserDataChanged(uid));
        dispatch(subscribeToMyConversationsAdded(uid));
        dispatch(subscribeToMyConversationsChanged(uid));
        // dispatch(getCurrentPushToken(uid))
       // dispatch(subscribeToUserCreditsDataChanged(uid));
        // If user is on the fill profile flow or creating their account, we should not fetch the push token
        if (!['/fill-profile', "/sign-up", "/complete-profile"].includes(window.location.pathname)) {
            dispatch(getCurrentPushToken(uid));

        }
        // dispatch(onMessage());
        dispatch(maintenanceAlert())
    };
};

const fetchRealStateFocus = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            const realStateFocus = await firebase.fetchRealStateFocus();

            dispatch({
                type: SHARED_REALSTATEFOCUS_SUCCESS,
                payload: {
                    realStateFocus: realStateFocus
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
};

const fetchMaitenanceMode = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            const maitenanceMode = await firebase.getMaitenanceMode();
            console.log('fetchMaitenanceMode', maitenanceMode)
            return dispatch({
                type: GLOBAL_MAITENANCE_MODE,
                payload: {
                    maitenanceMode: maitenanceMode
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
};

const allowAccessToDev = (code) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch({
                type: GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING,
                payload: {
                    isLoading: true
                }
            })
            const { data } = await cloudfunctions.allowAccessToDev(code);

            const { success } = data;
            localStorage.setItem('allowAccessToDev', success);
            return dispatch({
                type: GLOBAL_ALLOW_ACCESS_TO_DEV,
                payload: {
                    allowAccessToDev: success
                }
            });
        } catch (error) {
            console.log(error);
            dispatch(handleError(error));
            dispatch({
                type: GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING,
                payload: {
                    isLoading: false
                }
            });
        }
    };
};

const setCookie = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime()+(hourToExpire*60*60*1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
}

const startClarity = () => {
    return async (dispatch, getState) => {
        try {
            if (!Utils.isLocalhost()) {
                clarity.consent();
                const user = getState().main.user;
                if (!user && !user.uid) return;
                const { uid, profile } = user;
                const { email, name, lastname, phone } = profile;
                // Pass some user data to Clarity
                setCookie('user_uid', uid, 5);
                setCookie('user_email', email, 5);
                setCookie('user_name', name, 5);
                setCookie('user_lastname', lastname, 5);
                setCookie('user_phone', phone, 5);
                clarity.set("user_uid", uid);
                clarity.set("user_email", email);
                clarity.set("user_name", name);
                clarity.set("user_lastname", lastname);
                clarity.set("user_phone", phone);

                const projectId = config.clarity.projectId;
                clarity.start({
                    projectId,
                    upload: "https://m.clarity.ms/collect",
                    track: true,
                    content: true,
                    cookies: ["user_uid", "user_email", "user_name", "user_lastname", "user_phone"]
                });
            }
            console.info("Clarity started")
        } catch (error) {
            console.log(`Error starting clarity: ${error}`);
        }
    };
};


const referralStatusImportance = {
    "SUBMITTED": 1,
    "ASSIGNED": 2,
    "DECLINED": 3,
    "CANCELED": 4,
    "REJECTED": 5,
    "EXPIRED": 6,
    "CLAIMED": 7,
    "ERROR": 8,
    "INVALID": 9,
    "PENDING": 10
}

const normalizeOptions = (data, dictionaryName) => {
    const optionsKeys = {}
    const options = Object.keys(data).map(
        swKey => {
            optionsKeys[swKey] = swKey
            const label = i18next.t(`${dictionaryName}:${swKey}`)
            const importance = dictionaryName === 'dictionary_referralStatus' ? referralStatusImportance[swKey] : data[swKey]
            return { key: swKey, value: swKey, label: label ? label : data[swKey], importance: importance };
        })
    options.sort((a, b) => {
        if (a.importance < b.importance) return -1
        else if (a.importance > b.importance) return 1
        return 0
    })
    options.forEach(option => {
        optionsKeys[option.key] = option.key
    })
    return { options, optionsKeys }
}
const fetchStatics = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            let statics = await firebase.fetchStatic();
            statics = statics && statics.val()
            let userTypes = statics ? statics.userTypes : {};

            const finalUserTypes = Object.keys(userTypes).map(
                userTypeKey => {
                    return userTypeKey !== 'fix' ? { key: userTypeKey, ...userTypes[userTypeKey] } : null;
                }
            ).filter(u => u)

            finalUserTypes.sort((ut1, ut2) => {
                if (ut1.name < ut2.name) return -1
                else if (ut1.name < ut2.name) return 1
                return 0
            });
            statics.userTypes = finalUserTypes
            const normalizedShareWith = normalizeOptions(statics.referralShareWith, 'dictionary_referralShareWith')
            statics.referralShareWith = normalizedShareWith

            const normalizedClientSources = normalizeOptions(statics.clientSources, 'dictionary_clientSources')
            statics.clientSources = normalizedClientSources

            const normalizedClientStatus = normalizeOptions(statics.clientStatusTypes, 'dictionary_clientStatusTypes')
            statics.clientStatusTypes = normalizedClientStatus

            const normalizedClientTypes = normalizeOptions(statics.clientTypes, "dictionary_clientTypes")
            statics.clientTypes = normalizedClientTypes

            const normalizedPropertyTypes = normalizeOptions(statics.propertyTypes, "dictionary_propertyTypes")
            statics.propertyTypes = normalizedPropertyTypes

            const normalizedFinancingInfoTypes = normalizeOptions(statics.financingInfoTypes, "dictionary_financingInfoTypes")
            statics.financingInfoTypes = normalizedFinancingInfoTypes

            const normalizedNotificationRadius = normalizeOptions(statics.referralNotificationRadius, "dictionary_referralNotificationRadius")
            statics.referralNotificationRadius = normalizedNotificationRadius

            const normalizedHowToSendReferral = normalizeOptions(statics.referralHowToSend, "dictionary_referralHowToSend")
            statics.referralHowToSend = normalizedHowToSendReferral

            const normalizedReferralSignature = normalizeOptions(statics.referralSignatoryTypes, "dictionary_referralSignatoryTypes")
            statics.referralSignatoryTypes = normalizedReferralSignature

            const normalizedReferralFeeTypes = normalizeOptions(statics.referralFeeTypes, "dictionary_referralFeeTypes")
            statics.referralFeeTypes = normalizedReferralFeeTypes

            const normalizedReferralFeePaymentDue = normalizeOptions(statics.referralFeePaymentDue, "dictionary_referralFeePaymentDue")
            statics.referralFeePaymentDue = normalizedReferralFeePaymentDue

            const normalizedReferralFeeFrequency = normalizeOptions(statics.referralFeeFrequency, "dictionary_referralFeeFrequency")
            statics.referralFeeFrequency = normalizedReferralFeeFrequency

            const normalizedReferralFeeDuration = normalizeOptions(statics.referralFeeDuration, "dictionary_referralFeeDuration")
            statics.referralFeeDuration = normalizedReferralFeeDuration

            const normalizedReferralFeeCommencement = normalizeOptions(statics.referralFeeCommencement, "dictionary_referralFeeCommencement")
            statics.referralFeeCommencement = normalizedReferralFeeCommencement

            const normalizedReferralStatus = normalizeOptions(statics.referralStatus, "dictionary_referralStatus")
            statics.referralStatus = normalizedReferralStatus

            const normalizedUserAspiration = normalizeOptions(statics.userAspiration, "dictionary_userAspiration")
            statics.userAspiration = normalizedUserAspiration;

            dispatch({
                type: GLOBAL_STATICS_FETCH_SUCCESS,
                payload: {
                    statics: statics
                }
            });

        } catch (error) {
            console.log(error);
        }
    };
};

const newPWAVersionAvailable = payload => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            const { isLoggedIn } = getState().main.user;
            dispatch({
                type: NEWPWAVERSION_AVAILABLE,
                payload: {
                    newPWAVersionAvailable: payload.newPWAVersionAvailable,
                    forceRefresh: payload.forceRefresh
                }
            });
            if (isLoggedIn && payload.newPWAVersionAvailable && !toast.isActive("newPWAVersionAvailable")) {
                toast.success(
                    "New version available! 🎉🎉🎉 Please click here to start using the new features",
                    {
                        position:
                            window.innerWidth < MEDIABREAKPOINTS.tablet
                                ? toast.POSITION.TOP_CENTER
                                : toast.POSITION.BOTTOM_RIGHT,
                        autoClose: false,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        pauseOnHover: false,
                        className: "toast-container",
                        onClick: () => dispatch(updatePWA()),
                        toastId: "newPWAVersionAvailable"
                    }
                );
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const updatePWA = () => {
    return async (dispatch) => {
        try {
            if (caches) {
                console.log("Clearing cache and hard reloading...");
                // Service worker cache should be cleared with caches.delete()
                const names = await caches.keys();
                await Promise.all(
                    names.map(name => caches.delete(name))
                );
            }
            dispatch({
                type: NEWPWAVERSION_AVAILABLE,
                payload: {
                    newPWAVersionAvailable: false,
                    forceRefresh: false
                }
            });
            // delete browser cache and hard reload
            window.location.reload(true);
        } catch (error) {
            console.log(error);
        }
    };
}

const setServices = services => ({
    type: SET_SERVICES,
    payload: {
        services: services
    }
});

const setLayoutBackgroundColor = color => ({
    type: LAYOUT_BACKGROUND_COLOR,
    payload: {
        layoutBackgroundColor: color
    }
});

const setChatBotOpen = open => {
    return dispatch => {
        try {
            if (
                window.$zoho &&
                window.$zoho.salesiq &&
                window.$zoho.salesiq.chatbutton
            ) {
                dispatch(chatBotOpen(open));
                return window.$zoho.salesiq.floatbutton.visible(open);
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const maintenanceAlert = () => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            const maintenanceAlert = await firebase.getMaitenanceModeAlert()
            console.log('maintenanceAlert', maintenanceAlert)
            dispatch({
                type: MAINTENANCE_ALERT,
                payload: {
                    maintenanceAlert: maintenanceAlert
                }
            })

        } catch (error) {
            console.log(error);
        }
    };
};

const chatBotOpen = open => ({
    type: CHATBOT_OPEN,
    payload: {
        chatBotOpen: open
    }
});

const logAppError = (error) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            // Get the user's ID
            const { uid } = getState().main.user;
            // Get user's device info
            const { userAgent } = navigator;
            const platform =
                navigator.userAgentData && navigator.userAgentData.platform;
            // Get the current URL
            const { pathname } = window.location;
            // Get the error message
            const { message, stack } = error;
            // Build the error object
            const errorObject = {
                uid,
                deviceInfo: {
                    userAgent,
                    platform,
                },
                version: packageJson.version,
                pathname,
                error: {
                    message,
                    stack,
                }
            };
            // Send error to the Cloud Function
            return await cloudfunctions.logAppError(errorObject);
        } catch (error) {
            console.error("Error while logging to Google Cloud:", error);
        }
    };
}

const getQueryDateObject = (dates, idDate) => {
    const dateCasesHandler = {
        [dates[0].id]: () => {
            const getLast24 = moment.utc().subtract(1, "day");
            const parsed = Date.parse(getLast24._d);
            return { from: parsed, from_: parsed };
        },
        [dates[1].id]: () => {
            const thisWeek = moment().startOf("isoWeek");
            const parsed = Date.parse(thisWeek._d);
            return { from: parsed, from_: parsed };
        },
        [dates[2].id]: () => {
            const thisMonth = moment().startOf("month");
            const parsed = Date.parse(thisMonth._d);
            return { from: parsed, from_: parsed };
        },
        [dates[3].id]: () => {
            const lastMonth = moment()
                .subtract(1, "months")
                .startOf("month");
            const parsed = Date.parse(lastMonth._d);
            const thisMonth = moment().startOf("month");
            const thisMonthParsed = Date.parse(thisMonth._d);
            return { from: parsed, from_: parsed, to: thisMonthParsed };
        }
    };

    const timestamp = dateCasesHandler[idDate]();

    return timestamp || 0;
};

export {
    fetchMaitenanceMode,
    openChat,
    fetchStatics,
    setChatBotOpen,
    chatBotOpen,
    setLayoutBackgroundColor,
    setServices,
    rehydrateProfileOnStore,
    newPWAVersionAvailable,
    fetchRealStateFocus,
    maintenanceAlert,
    getQueryDateObject,
    allowAccessToDev,
    updatePWA,
    logAppError,
    startClarity
};
