import { connect } from "react-redux";
import View from "./view";
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";

const mapStateToProps = state => {
    const statics = state.main.ui.global.statics;

    return {
        clientTypes: statics.clientTypes,
        propertyTypes: statics.propertyTypes,
        financingInfoTypes: statics.financingInfoTypes,
        referralFeeTypes: statics.referralFeeTypes,
        sizeUnit: statics.referralSizeUnit
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({}, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
