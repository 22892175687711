import {
    NEWARTICLE_ADDHASHTAG,
    NEWARTICLE_ADDIMAGE,
    NEWARTICLE_ADDIMAGESUCCESS,
    NEWARTICLE_ISLOADING,
    NEWARTICLE_REMOVEHASHTAG,
    NEWARTICLE_REMOVEIMAGE,
    NEWARTICLE_SETBODY,
    NEWARTICLE_SETTITLE,
    NEWARTICLE_FAIL,
    NEWARTICLE_SETPRIMARYIMAGE,
    NEWARTICLE_TOOGLEVISIBILITYMODAL,
    NEWARTICLE_SETVISIBILITY,
    NEWARTICLE_CLEARFORM
} from "../../constants";
import { handleError } from "../modal";
import { push } from "connected-react-router";
import { addCommunityNewestPost, updateCommunityNewestPost } from "../community";
import Utils from "../../../../libs/Utils";
import Compressor from 'compressorjs';
import GlobalBusiness from '../../../../business/global'
import CommunityBusiness from '../../../../business/community'
//import { hasToxicity } from '../../../../libs/TensorFlow'
export const newArticleIsLoading = isLoading => ({
    type: NEWARTICLE_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

export const newArticleAddImage = () => ({
    type: NEWARTICLE_ADDIMAGE
});

export const newArticleSetBody = body => ({
    type: NEWARTICLE_SETBODY,
    payload: {
        body: body
    }
});

export const newArticleSetTitle = title => ({
    type: NEWARTICLE_SETTITLE,
    payload: {
        title: title
    }
});

export const newArticleFail = errors => ({
    type: NEWARTICLE_FAIL,
    payload: {
        errors: errors
    }
});

export const newArticleAddImageSuccess = image => ({
    type: NEWARTICLE_ADDIMAGESUCCESS,
    payload: {
        image: image
    }
});

export const newArticleRemoveImage = imageIndex => ({
    type: NEWARTICLE_REMOVEIMAGE,
    payload: {
        imageIndex: imageIndex
    }
});

export const newArticleAddHashtag = hashtag => ({
    type: NEWARTICLE_ADDHASHTAG,
    payload: {
        hashtag: hashtag
    }
});

export const newArticleRemoveHashtag = hashtag => ({
    type: NEWARTICLE_REMOVEHASHTAG,
    payload: {
        hashtag: hashtag
    }
});

export const newArticleSetPrimaryImage = position => ({
    type: NEWARTICLE_SETPRIMARYIMAGE,
    payload: {
        position: position
    }
});

export const newArticleSetVisibility = groupId => ({
    type: NEWARTICLE_SETVISIBILITY,
    payload: {
        groupId: groupId
    }
});

export const newArticleToogleVisibilityModal = open => ({
    type: NEWARTICLE_TOOGLEVISIBILITYMODAL,
    payload: {
        open: open
    }
});

export const newArticleClearForm = () => ({
    type: NEWARTICLE_CLEARFORM
});

export const uploadImage = (position, file) => {
    return async (dispatch, getState, { firebase }) => {
        dispatch(newArticleAddImage());
        try {
            const uid = getState().main.user.uid;
            if(uid){
                const compressedFile = await GlobalBusiness.compressFile(file, Compressor)
                const imageUrl = await firebase.addImageToPost(uid, compressedFile);
                dispatch(
                    newArticleAddImageSuccess({ url: imageUrl, position: position })
                );
            }
            else throw Error('UID undefined')
        } catch (error) {
            dispatch(handleError(error));
            return dispatch(newArticleFail(error));
        }
    };
};

export const submitPost = post => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch(newArticleIsLoading(true));
            post["body"] = normalizeBody(post["body"]);
            const { title, body } = post
          //  const toxic = await hasToxicity([title, body])
          //  if (toxic) throw new Error("Toxicity detected")
            const newPost = await cloudfunctions.createCommunityPost(post);
            dispatch(addCommunityNewestPost(newPost.data));
            dispatch(newArticleIsLoading(false));
            dispatch(newArticleClearForm());
            dispatch(push("/news"));
        } catch (error) {
            dispatch(handleError(error));
            return dispatch(newArticleFail(error));
        }
    };
};

export const updatePost = (postId = '', post = {}) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch(newArticleIsLoading(true));
            post["body"] = normalizeBody(post["body"]);
            const parsedId = postId.split('@@@')
            const response = await cloudfunctions.updateCommunityPost(parsedId[1], post);
            const updatedPost = CommunityBusiness.formatFirebasePostToElastic(response.data)
            dispatch(updateCommunityNewestPost(postId, updatedPost));
            dispatch(newArticleIsLoading(false));
            dispatch(newArticleClearForm());
            dispatch(push("/news"));
        } catch (error) {
            dispatch(handleError(error));
            return dispatch(newArticleFail(error));
        }
    };
};


export const normalizeBody = (body = "") => {
    let newBody = body.replace(/\n/g, "<br />");

    const linksPos = Utils.getLinksPosition(newBody);

    linksPos.forEach(position => {
        const newText = `<a href="${position.text}" target="_blank">${position.text}</a>`;
        newBody =
            newBody.substr(0, position.index) +
            newBody
                .substr(position.index, body.length)
                .replace(position.text, newText);
    });

    return newBody;
};
