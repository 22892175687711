import { useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enviroment } from '../../../../../../config'
import { importContactsFromCloudSponge } from '../../../../../../redux/actions/creators/myNetwork';

const cloudspongeKey =
    enviroment === "development" ? "localhost-only" : "8WgXIWyJByNsvW1GDLkGyg";

const CloudSpongeWidget = ({
    children,
    options = {},
    importContactsFromCloudSponge,
    onImportContactsComplete
}) => {
    // adds an async script tag to the page and invokes a callback when the script has loaded
    function addJavascript(src, id, callback) {
        if (id && document.getElementById(id)) {
            // the script is already loaded so just invoke the callback and return
            callback && callback();
            return;
        }

        // create and add the scrpt tag
        const scriptTag = document.createElement("script");
        scriptTag.type = "text/javascript";
        scriptTag.async = 1;
        if (id) {
            scriptTag.id = id;
        }

        // set the script to invoke a callback after it loads
        if (callback) {
            if (scriptTag.readyState) {
                // IE7+
                scriptTag.onreadystatechange = () => {
                    if (
                        scriptTag.readyState == "loaded" ||
                        scriptTag.readyState == "complete"
                    ) {
                        // clear the callback so it only ever executes once
                        scriptTag.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                scriptTag.onload = () => {
                    // Other browsers support the onload attribute \o/
                    callback();
                };
            }
        }

        // assign the src attribute
        scriptTag.src = src;
        // add the script to the page
        document.body.appendChild(scriptTag);
    }

    const handleImportContacts = contacts => {
        if (!contacts || !contacts.length) return;
        importContactsFromCloudSponge(contacts);
        if (onImportContactsComplete) {
            onImportContactsComplete();
        }
    };

    useEffect(() => {
        addJavascript(
            `https://api.cloudsponge.com/widget/${cloudspongeKey}.js`,
            "__cloudsponge_widget_script",
            () => {
                // calling init attaches the cloudsponge.launch action to any cloudsponge-launch links on the page
                //  we need to wait until the script has loaded so we aren't "bandwidthist"
                if (window.cloudsponge) {
                    window.cloudsponge.init({
                        afterSubmitContacts: handleImportContacts,
                        ...options
                    });
                }
            }
        );
    }, []);

    // just render the children, if any.
    //  typically the children will include at least one link decorated with
    //  class="cloudsponge-launch" so that the cloudsponge library will attach
    //  to its onClick handler to launch the widget
    return children;
};

const mapStateToProps = _ => ({})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            importContactsFromCloudSponge
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CloudSpongeWidget);