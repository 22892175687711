import React from "react";
import { Box } from "rebass";
import { withTheme } from "styled-components";
import { Text } from "../../../ui/presentation/atoms/shared/Text";


const SignInRightContent = ({ theme }) => (
    <>
        <Box mt={`-${theme.spacing[16]}`}>
            <Text
                fontSize={theme.mobileDevice ? "L" : "XXXL"}
                fontWeight="bold"
                color={theme.clearGray}
            >
                Know people who would love NuOp?
            </Text>
        </Box>
        <Box mt={theme.spacing[10]} mb={theme.spacing[16]}>
            <Text
                fontSize={theme.mobileDevice ? "M" : "L"}
                color={theme.clearGray}
                style={{ fontWeight: "600" }}
            >
                Invite now and earn money
            </Text>
        </Box>
        <Box>
            <Text
                fontSize={theme.mobileDevice ? "S" : "M"}
                color={theme.clearGray}
                style={{ fontWeight: "400" }}
                lineHeight="S"
            >
                If any referral partners accept your invite to join, you will
                earn 10% of all revenue that NuOp generates from your referral
                partner within 24 months of their initial purchase date.
            </Text>
        </Box>
        <Box mt={theme.spacing[10]}>
            <Text
                fontSize={theme.mobileDevice ? "S" : "M"}
                color={theme.clearGray}
                style={{ fontWeight: "400" }}
            >
                Just <b>Sign In</b>, tap <b>Create</b> and start inviting.
            </Text>
        </Box>
    </>
);

export default withTheme(SignInRightContent);