import React from "react";
import { Description } from "../../Text";
import { Icon } from "../../Icon";
import { Container } from "./styles";

const CheckBox = props => {
    const { disabled, checked, onChange, label, labelStyles, checkBoxColor, round, boxProps = {}, inverted, errors, ...rest  } = props;

    const checkProps = {
        // onClick: onChange,
        style: { cursor: 'pointer' }
    }

    const icon = checked ? round ? "check-filled" : "checked-box" : round ? "circle" : "unchecked-box";

    return (
        <Container
            {...rest}
            disabled={disabled}
            alignItems="center"
            flexDirection={inverted ? 'row-reverse' : 'row'} 
            onClick={disabled ? () => {} : onChange}>
            <Description style={{ marginLeft: inverted ? "1em" : 0, marginRight: inverted ? 0 : "1em", ...labelStyles }} errors={errors} >
                {label}
            </Description>
            <Icon icon={icon} {...checkProps} {...boxProps} color={checkBoxColor} />
        </Container>
    );
};

export default CheckBox;
