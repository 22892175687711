import React from 'react'
import { Flex } from "rebass"
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../../Settings/common/Components";

import {  selectStyle } from "../../../components";

const TimingInfo = ({ theme, formValues, onChange, errors, clientSourcesOptions, clientStatusOptions }) => (
    <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
        <InputContainer
            triggerShowLabel={
                formValues.clientSource ? "show" : "hide"
            }
            showLabelOnValue
        >
            <Select
                options={clientSourcesOptions}
                onChange={option =>
                    onChange({
                        target: {
                            name: "clientSource",
                            value: option,
                        }
                    })
                }
                styles={selectStyle}
                name="clientSource"
                placeholder="Client Source*"
                value={formValues.clientSource}
                error={!!errors.clientSource}
            />
            <InputLabel>Client Source*</InputLabel>
        </InputContainer>
        <Flex mt={theme.spacing[3]}  flexDirection={[ 'column', 'column', 'row' ]} >
            <InputContainer
                triggerShowLabel={
                    formValues.currentSatus ? "show" : "hide"
                }
                showLabelOnValue
            >
                <Select
                    options={clientStatusOptions}
                    onChange={option =>
                        onChange({
                            target: {
                                name: "currentSatus",
                                value: option,
                            }
                        })
                    }
                    styles={selectStyle}
                    name="currentSatus"
                    placeholder="Current Status*"
                    error={!!errors.currentSatus}
                    value={formValues.currentSatus}
                />
                <InputLabel>Current Status*</InputLabel>
            </InputContainer>
            <InputContainer
                triggerShowLabel={
                    formValues.lastContact ? "show" : "hide"
                }
                showLabelOnValue
            >
                <DatePicker
                    tabIndex="0"
                    placeholderText={"Last Day of Contact*"}
                    selected={
                        formValues.lastContact
                            ? moment(
                                    formValues.lastContact
                                ).toDate()
                            : ""
                    }
                    onChange={date =>
                        onChange({
                            target: {
                                name: "lastContact",
                                value: date,
                            }
                        })
                    }
                    required
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    customInput={
                        <Input
                            error={!!errors.lastContact}
                            style={{ width: "calc(100% - 16px)" }}
                            value={
                                formValues.lastContact
                                    ? moment(
                                            formValues.lastContact
                                        ).format("L")
                                    : null
                            }
                        />
                    }
                    name={"lastContact"}
                    maxDate={new Date()}
                />
                <InputLabel>Last Day of Contact*</InputLabel>
            </InputContainer>
        </Flex>
    </Flex>
)

export default TimingInfo;