import React, { useState } from "react";
import styled from "styled-components";
import { MainText, StepWrapper, selectStyle, Button, ButtonContainer } from "../../components";
import { ReferralPrivacyOptions, REFERRAL_PRIVACY } from "../../constants";
import Select from 'react-select';
import { Icon } from "../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import { Flex } from "rebass";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 .5em;
    cursor: pointer;
`;

const ExtendedButtonContainer = styled(ButtonContainer)`
    justify-content: space-between;
    align-items: center;
`;

const ClickableLabel = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #057AFF;
    margin-left: 6px;
`;

const BottomLabel = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
`;

const ReferralPrivacy = ({ setStepData, stepData, t, stepKey }) => {
    
    const [selectedType, setSelectedType] = useState(stepData);

    const onClickHandler = () => {
        setStepData(selectedType);
    };

    const didTapReferralPastFlow = () => setStepData(REFERRAL_PRIVACY.REFERRAL_PAST)

    const info = t("referralPrivacyTooltipInfo")


    return (
        <StepWrapper>
            <Container>
                <Wrapper>
                    <MainText>Who can view this referral?</MainText>
                    <IconContainer>
                        <Icon
                            icon="info"
                            size="md"
                            color="#057AFF"
                            data-tip
                            data-for={'referral-privacy-info'}
                        />
                    </IconContainer>
                </Wrapper>
                <SelectContainer>
                    <Select
                        options={ReferralPrivacyOptions}
                        onChange={(ev) => { setSelectedType(ev) }}
                        styles={selectStyle}
                        value={selectedType}
                        isClearable
                        placeholder="Select..."
                    />
                </SelectContainer>
                <ExtendedButtonContainer>
                    <BottomLabel>
                       {/*  <ClickableLabel onClick={() => didTapReferralPastFlow()} >click here</ClickableLabel> */}
                    </BottomLabel>
                    <Flex>
                        <Button
                            onClick={onClickHandler}
                            disabled={!selectedType}
                        >
                            Next
                        </Button>
                    </Flex>
                </ExtendedButtonContainer>
            </Container>
            <ReactTooltip
                id={'referral-privacy-info'}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: info }} />
            </ReactTooltip>
        </StepWrapper>
    );
};

export default ReferralPrivacy;
