/* PURE ACTION TYPES */

const ACTION = "ACTION";
const SHARED_REALSTATEFOCUS_SUCCESS = "SHARED_REALSTATEFOCUS_SUCCESS";

const SETTINGS_SELECTSECTION = "SETTINGS_SELECTSECTION";
const SETTINGS_BLOCKEDCONTACTS_SUCCESS = "SETTINGS_BLOCKEDCONTACTS_SUCCESS";
const SETTINGS_ISLOADING = "SETTINGS_ISLOADING";
const SETTINGS_SETPHONE = "SETTINGS_SETPHONE";
const SETTINGS_UNBLOCKCONTACT_SUCCESS = "SETTINGS_UNBLOCKCONTACT_SUCCESS";
const SETTINGS_SET_SUBSCRIPTIONSTEP = "SETTINGS_SET_SUBSCRIPTIONSTEP";
const SETTINGS_SET_SUBSCRIPTIONOPTION = "SETTINGS_SET_SUBSCRIPTIONOPTION";
const SETTINGS_CHANGE_PASSWORD_SUCCESS = "SETTINGS_CHANGE_PASSWORD_SUCCESS";
const SETTINGS_BILLINGDETAILS_SUCCESS = "SETTINGS_BILLINGDETAILS_SUCCESS";
const SETTINGS_INVITEEMAILS_SUCCESS = "SETTINGS_INVITEEMAILS_SUCCESS";
const SETTINGS_UNBLOCK_CONTACTS_LOADING = "SETTINGS_UNBLOCK_CONTACTS_LOADING";
const GLOBAL_STATICS_FETCH_SUCCESS = "GLOBAL_STATICS_FETCH_SUCCESS";
const GLOBAL_MAITENANCE_MODE = "GLOBAL_MAITENANCE_MODE";
const GLOBAL_ALLOW_ACCESS_TO_DEV = "GLOBAL_ALLOW_ACCESS_TO_DEV";
const GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING = "GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING";

const SHOW_MODAL = "SHOW_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";
const SIGN_IN = "SIGN_IN";
const SIGN_IN_FAIL = "SIGN_IN_FAIL";
const SIGN_IN_SUCC = "SIGN_IN_SUCC";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const SIGN_UP_FAIL = "SIGN_UP_FAIL";
const SIGN_UP_SUCC = "SIGN_UP_SUCC";
const UNSUCCESS_LOGIN = "UNSUCCESS_LOGIN";
const WRONG_PASSWORD = "WRONG_PASSWORD"
const EMAIL_ERROR = "EMAIL_ERROR"
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
const RESET_PASSWORD_SUCC = "RESET_PASSWORD_SUCC";
const RESET_PASSWORD_ISLOADING = "RESET_PASSWORD_ISLOADING";
const RESET_PASSWORD_INIT = "RESET_PASSWORD_INIT";
const RESET_PASSWORD_CLOSE_MODAL = "RESET_PASSWORD_CLOSE_MODAL";

const PASSWORD_RECOVERY_FAIL = "PASSWORD_RECOVERY_FAIL";
const PASSWORD_RECOVERY_SUCC = "PASSWORD_RECOVERY_SUCC";
const PASSWORD_RECOVERY_ISLOADING = "PASSWORD_RECOVERY_ISLOADING";
const PASSWORD_RECOVERY_INIT = "PASSWORD_RECOVERY_INIT";
const PASSWORD_RECOVERY_CLOSE_MODAL = "PASSWORD_RECOVERY_CLOSE_MODAL";

const FETCH_PROFILE = "FETCH_PROFILE";
const FETCH_PROFILE_FAIL = "FETCH_PROFILE_FAIL";
const FETCH_PROFILE_SUCC = "FETCH_PROFILE_SUCC";
const FETCH_PROFILE_SET = "FETCH_PROFILE_SET";

const IS_SOCIAL_LOGIN_FLAG_ON = "IS_SOCIAL_LOGIN_FLAG_ON";
const IS_SOCIAL_LOGIN_FLAG_OFF = "IS_SOCIAL_LOGIN_FLAG_OFF";
const FETCH_AGENCIES = "FETCH_AGENCIES";
const FETCH_AGENCIES_FAIL = "FETCH_AGENCIES_FAIL";
const FETCH_AGENCIES_SUCC = "FETCH_AGENCIES_SUCC";
const FETCH_AGENCY = "FETCH_AGENCY";
const FETCH_AGENCY_FAIL = "FETCH_AGENCY_FAIL";
const FETCH_AGENCY_SUCC = "FETCH_AGENCY_SUCC";
const FETCH_REFERRALS = "FETCH_REFERRALS";
const FETCH_REFERRALS_FAIL = "FETCH_REFERRALS_FAIL";
const FETCH_SENT_REFERRALS_SUCC = "FETCH_SENT_REFERRALS_SUCC";
const FETCH_RECEIVED_REFERRALS_SUCC = "FETCH_RECEIVED_REFERRALS_SUCC";
const FETCH_RECEIVED_REFERRALS_ADD = "FETCH_RECEIVED_REFERRALS_ADD";
const FETCH_RECEIVED_REFERRALS_UPDATE = "FETCH_RECEIVED_REFERRALS_UPDATE";
const OPEN_REFERRAL = "OPEN_REFERRAL";
const OPEN_REFERRAL_FAIL = "OPEN_REFERRAL_FAIL";
const OPEN_REFERRAL_SUCC = "OPEN_REFERRAL_SUCC";
const OPEN_REFERRALS_UNSUB = "OPEN_REFERRALS_UNSUB";
const OPEN_REFERRALS_UNSUB_FAIL = "OPEN_REFERRALS_UNSUB_FAIL";
const OPEN_REFERRALS_UNSUB_SUCC = "OPEN_REFERRALS_UNSUB_SUCC";
const OPEN_REFERRALS = "OPEN_REFERRALS";
const OPEN_REFERRALS_FAIL = "OPEN_REFERRALS_FAIL";
const OPEN_REFERRALS_SUCC = "OPEN_REFERRALS_SUCC";
const REFERRALS_DELETE_SENT = "REFERRALS_DELETE_SENT";
const REFERRALS_ADD_SENT = "REFERRALS_ADD_SENT";
const ADD_RECEIVED_COUNTER = "ADD_RECEIVED_COUNTER";
const ADD_SENT_COUNTER = "ADD_SENT_COUNTER";

const FETCH_REFERRALS_SENT_ISLOADING = "FETCH_REFERRALS_SENT_ISLOADING";
const FETCH_REFERRALS_RECEIVED_ISLOADING = "FETCH_REFERRALS_RECEIVED_ISLOADING";
const FETCH_REFERRALSUSERS_SUCC = "FETCH_REFERRALSUSERS_SUCC";
const FETCH_SENT_REFERRALS_ADD = "FETCH_SENT_REFERRALS_ADD";
const FETCH_SENT_REFERRALS_UPDATE = "FETCH_SENT_REFERRALS_UPDATE";
const FETCH_SENT_REFERRALS_PENDING_REMOVED =
    "FETCH_SENT_REFERRALS_PENDING_REMOVED";

const REFERRALS_INIT = "REFERRALS_INIT";
const NAV_FAIL = "NAV_FAIL";
const CHAIN_FAIL = "CHAIN_FAIL";
const VALIDATE_PHONE_MODAL = "VALIDATE_PHONE_MODAL";
const VALIDATE_PHONE = "VALIDATE_PHONE";
const VALIDATE_PHONE_SUCC = "VALIDATE_PHONE_SUCC";
const VALIDATE_PHONE_FAIL = "VALIDATE_PHONE_FAIL";
const VALIDATE_PHONE_ON_EDIT = "VALIDATE_PHONE_ON_EDIT";
const VALIDATE_PHONE_MODALOPEN_ON_EDIT = "VALIDATE_PHONE_MODALOPEN_ON_EDIT";
const VALIDATE_PHONE_ON_EDIT_SUCC = "VALIDATE_PHONE_ON_EDIT_SUCC";
const VALIDATE_PHONE_ON_EDIT_FAIL = "VALIDATE_PHONE_ON_EDIT_FAIL";

const CHECK_USERNAME_IS_LOADING = "CHECK_USERNAME_IS_LOADING";

const FILLPROFILE_ISLOADING = "FILLPROFILE_ISLOADING";
const FILLPROFILE_ADDAGENCY_SUCCESS = "FILLPROFILE_ADDAGENCY_SUCCESS";
const FILLPROFILE_TOOGLE_ADD_AGENCY_MODAL =
    "FILLPROFILE_TOOGLE_ADD_AGENCY_MODAL";
const FILLPROFILE_VALUESCHANGED = "FILLPROFILE_VALUESCHANGED";
const FILLPROFILE_CLEAN = "FILLPROFILE_CLEAN";
const FILLPROFILE_STEP = "FILLPROFILE_STEP";
const FILLPROFILE_SUCCESS = "FILLPROFILE_SUCCESS";
const FILLPROFILE_SKIP_EMAIL_VERIFICATION = "FILLPROFILE_SKIP_EMAIL_VERIFICATION";
const FILLPROFILE_PHONE_ALREADY_IN_USE = "FILLPROFILE_PHONE_ALREADY_IN_USE";

const SEND_REFERRAL_USER_LIST_FETCH_USERS =
    "SEND_REFERRAL_USER_LIST_FETCH_USERS";
const SEND_REFERRAL_USER_LIST_FETCH_USERS_SUCC =
    "SEND_REFERRAL_USER_LIST_FETCH_USERS_SUCC";
const SEND_REFERRAL_USER_LIST_FETCH_USERS_FAIL =
    "SEND_REFERRAL_USER_LIST_FETCH_USERS_FAIL";
const SEND_REFERRAL_USERSCANDIDATES_MODALOPEN =
    "SEND_REFERRAL_USERSCANDIDATES_MODALOPEN";
const CONNECT = "CONNECT";
const CONNECT_SUCC = "CONNECT_SUCC";
const CONNECT_FAIL = "CONNECT_FAIL";
const CANCEL_CONNECTION = "CANCEL_CONNECTION";
const CANCEL_CONNECTION_SUCC = "CANCEL_CONNECTION_SUCC";
const CANCEL_CONNECTION_FAIL = "CANCEL_CONNECTION_FAIL";
const ACCEPT = "ACCEPT";
const ACCEPT_SUCC = "ACCEPT_SUCC";
const ACCEPT_FAIL = "ACCEPT_FAIL";
const DECLINE = "DECLINE";
const DECLINE_SUCC = "DECLINE_SUCC";
const DECLINE_FAIL = "DECLINE_FAIL";
const USER_DECLINE_INVITE_SUCC = "USER_DECLINE_INVITE_SUCC";
const SEND_REFERRAL_IS_LOADING = "SEND_REFERRAL_IS_LOADING";
const SEND_REFERRAL_SUCC = "SEND_REFERRAL_SUCC";
const SEND_REFERRAL_PREVENT_PROMPT = "SEND_REFERRAL_PREVENT_PROMPT";
const SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO = "SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO";
const SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO = "SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO";

const SEND_REFERRAL_FAIL = "SEND_REFERRAL_FAIL";
const SEND_REFERRAL_TOOGLE_CLEAR_FORM = "SEND_REFERRAL_TOOGLE_CLEAR_FORM";
const SEND_REFERRAL_TOOGLEMESSAGEMODAL = "SEND_REFERRAL_TOOGLEMESSAGEMODAL";

const NAVBAR_FETCH_USER_PENDING_LIST = "NAVBAR_FETCH_USER_PENDING_LIST";
const NAVBAR_FETCH_USER_PENDING_LIST_SUCC =
    "NAVBAR_FETCH_USER_PENDING_LIST_SUCC";
const NAVBAR_FETCH_USER_PENDING_LIST_FAIL =
    "NAVBAR_FETCH_USER_PENDING_LIST_FAIL";
const EDIT_PROFILE_SAVE = "EDIT_PROFILE_SAVE";
const EDIT_PROFILE_SAVE_SUCC = "EDIT_PROFILE_SAVE_SUCC";
const EDIT_PROFILE_SAVE_FAIL = "EDIT_PROFILE_SAVE_FAIL";
const PROFILE_EDITING = "PROFILE_EDITING";

const FETCH_USER_LIST = "FETCH_USER_LIST";
const FETCH_USER_LIST_SUCC = "FETCH_USER_LIST_SUCC";
const FETCH_USER_LIST_FAIL = "FETCH_USER_LIST_FAIL";
const USER_STORE_SUCC = "USER_STORE_SUCC";
const USER_STORE_FAIL = "USER_STORE_FAIL";
const VALIDATE_PHONE_CODE = "VALIDATE_PHONE_CODE";
const VALIDATE_PHONE_CODE_SUCC = "VALIDATE_PHONE_CODE_SUCC";
const VALIDATE_PHONE_CODE_FAIL = "VALIDATE_PHONE_CODE_FAIL";
const VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE =
    "VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE";

const VALIDATE_PHONE_CODE_ON_EDIT = "VALIDATE_PHONE_CODE_ON_EDIT";
const VALIDATE_PHONE_CODE_ON_EDIT_SUCC = "VALIDATE_PHONE_CODE_ON_EDIT_SUCC";
const VALIDATE_PHONE_CODE_ON_EDIT_FAIL = "VALIDATE_PHONE_CODE_ON_EDIT_FAIL";

const SEND_REFERRAL_SAVE_FORM = "SEND_REFERRAL_SAVE_FORM";
const SEND_REFERRAL_SAVE_FORM_SUCC = "SEND_REFERRAL_SAVE_FORM_SUCC";
const SEND_REFERRAL_SAVE_FORM_FAIL = "SEND_REFERRAL_SAVE_FORM_FAIL";
const SEND_REFERRAL_REMOVE_USER_TO_REFERRAL = "SEND_REFERRAL_REMOVE_USER_TO_REFERRAL";
const SEND_REFERRAL_CURRENT_SECTION = "SEND_REFERRAL_CURRENT_SECTION";
const SEND_REFERRAL_SET_VALUE = "SEND_REFERRAL_SET_VALUE";
const SEND_REFERRAL_ISVALIDFORM = "SEND_REFERRAL_ISVALIDFORM";
const SEND_REFERRAL_CANDIDATES_SET_TYPE = "SEND_REFERRAL_CANDIDATES_SET_TYPE";
const SEND_REFERRAL_SIGNATORY_SET_TYPE = "SEND_REFERRAL_SIGNATORY_SET_TYPE";
const SEND_REFERRAL_SIGNATORY_SET_USER = "SEND_REFERRAL_SIGNATORY_SET_USER";

const SEND_REFERRAL_FORM_SET_VALUE = "SEND_REFERRAL_FORM_SET_VALUE";
const SEND_REFERRAL_FORM_SET_ISVALIDFORM = "SEND_REFERRAL_FORM_SET_ISVALIDFORM";
const SEND_REFERRAL_FORM_SET_COLLAPSE = "SEND_REFERRAL_FORM_SET_COLLAPSE";

const AGENCY_ADDRESS_CHANGE = "AGENCY_ADDRESS_CHANGE";
const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
const TOGGLE_SEARCHBAR = "TOGGLE_SEARCHBAR";
const SEARCHBAR_LOADING = "SEARCHBAR_LOADING";
const SEARCHBAR_SUCCESS = "SEARCHBAR_SUCCESS";
const SEARCHBAR_FAIL = "SEARCHBAR_FAIL";
const MEGASEARCH_SUCCESS = "MEGASEARCH_SUCCESS";
const MEGASEARCH_FAIL = "MEGASEARCH_FAIL";
const MEGASEARCH_RESET_FILTERS = "MEGASEARCH_RESET_FILTERS";
const MEGASEARCH_UPDATE_FILTERS = "MEGASEARCH_UPDATE_FILTERS";

const SEARCHBAR_LOCATION_LOADING = "SEARCHBAR_LOCATION_LOADING";
const SEARCHBAR_LOCATION_SUCCESS = "SEARCHBAR_LOCATION_SUCCESS";
const SEARCHBAR_LOCATION_FAIL = "SEARCHBAR_LOCATION_FAIL";
const SEARCHBAR_CLEAR = "SEARCHBAR_CLEAR";
const SEARCHBAR_CLEAR_RESULTS = "SEARCHBAR_CLEAR_RESULTS";

const SEARCHBAR_VALUE = "SEARCHBAR_VALUE";
const SEARCHBAR_BLUR_VALUE = "SEARCHBAR_BLUR_VALUE";
const SEARCHBAR_PAGE = "SEARCHBAR_PAGE";
const SEARCHBAR_SET_LOCATION_VALUE = "SEARCHBAR_SET_LOCATION_VALUE";
const SEARCHBAR_SET_LOCATIONORPROFILE = "SEARCHBAR_SET_LOCATIONORPROFILE";
const SEARCHBAR_FILTER_OPEN = "SEARCHBAR_FILTER_OPEN";
const SEARCHBAR_SHOW_SUGGESTIONS = "SEARCHBAR_SHOW_SUGGESTIONS";
const SEARCHBAR_SET_FILTER = "SEARCHBAR_SET_FILTER";

const LAYOUT_BACKGROUND_COLOR = "LAYOUT_BACKGROUND_COLOR";
const CHATBOT_OPEN = "CHATBOT_OPEN";

const TOOGLE_REFERRALNOTIFICATION = "TOOGLE_REFERRALNOTIFICATION";
const NAVBAR_CURRENT_TAB = "NAVBAR_CURRENT_TAB";
const CREATE_DROPDOWN_CURRENT_TAB = "CREATE_DROPDOWN_CURRENT_TAB";
const NAVBAR_TOOGLE_AVATARDROPDOWN = "NAVBAR_TOOGLE_AVATARDROPDOWN";
const NAVBAR_TOOGLE_MOBILESIDEMENU = "NAVBAR_TOOGLE_MOBILESIDEMENU";
const GLOBAL_ADD_USER = "GLOBAL_ADD_USER";

const SUBSCRIBED_TO_FIREABSE = "SUBSCRIBED_TO_FIREABSE";

const REHYDRATE_FIREBASE_AUTH = "REHYDRATE_FIREBASE_AUTH";
const REHYDRATE_USER = "REHYDRATE_USER";
const CLEAN_USER = "CLEAN_USER";
const CLEAN_SEND_REFERRAL = "CLEAN_SEND_REFERRAL";
const REHYDRATE_USER_IS_LOADING = "REHYDRATE_USER_IS_LOADING";

const REHYDRATE_SEND_REFERRAL = "REHYDRATE_SEND_REFERRAL";
const EDITPROFILE_ISLOADING = "EDITPROFILE_ISLOADING";
const EDITPROFILE_FETCHAGENCIESSUCCESS = "EDITPROFILE_FETCHAGENCIESSUCCESS";
const EDITPROFILE_FETCHPROFILESUCCESS = "EDITPROFILE_FETCHPROFILESUCCESS";
const EDITPROFILE_FETCHPROFILEFAIL = "EDITPROFILE_FETCHPROFILEFAIL";
const EDITPROFILE_FETCHAGENCYSUCCESS = "EDITPROFILE_FETCHAGENCYSUCCESS";
const EDITPROFILE_FETCHFAIL = "EDITPROFILE_FETCHFAIL";
const EDITPROFILE_UPLOADAVATARSUCCESS = "EDITPROFILE_UPLOADAVATARSUCCESS";
const USER_FOCUS_SUCCESS = "USER_FOCUS_SUCCESS";
const USER_SERVICEAREAS_SUCCESS = "USER_SERVICEAREAS_SUCCESS";
const USER_HONORS_SUCCESS = "USER_HONORS_SUCCESS";
const USER_GOOD_OPS_FOR_ME_SUCCESS = "USER_GOOD_OPS_FOR_ME_SUCCESS";
const USER_EXPERIENCE_SUCCESS = "USER_EXPERIENCE_SUCCESS";
const USER_LICENCES_SUCCESS = "USER_LICENCES_SUCCESS";
const USER_SOCIAL_SUCCESS = "USER_SOCIAL_SUCCESS";
const USER_COMMUNITY_SUCCESS = "USER_COMMUNITY_SUCCESS";
const USER_GET_CONNECTED_ISLOADING = "USER_GET_CONNECTED_ISLOADING";
const USER_GET_CONNECTED = "USER_GET_CONNECTED";
const USER_SETSETTING = "USER_SETSETTING";
const USER_FETCHINVITES_SUCCESS = "USER_FETCHINVITES_SUCCESS";
const USER_INVITES_DISMISS_SUCCESS = "USER_INVITES_DISMISS_SUCCESS";
const USER_INVITES_ACCOUNTBALANCE_SUCCESS = "USER_INVITES_ACCOUNTBALANCE_SUCCESS";
const USER_INVITES_EARNING_HISTORY_SUCCESS = "USER_INVITES_EARNING_HISTORY_SUCCESS";
const USER_INVITES_INVITESCOUNT_SUCCESS = "USER_INVITES_INVITESCOUNT_SUCCESS";
const USER_EXTRAINFO_SUCCESS = "USER_EXTRAINFO_SUCCESS";
const VERYFYBYPHONE_ISLOADING = "VERYFYBYPHONE_ISLOADING";
const VERYFYBYPHONE_SENDVERIFICATIONCODE_SUCCESS = "VERYFYBYPHONE_SENDVERIFICATIONCODE_SUCCESS";
const VERYFYBYPHONE_VALIDATE_SUCCESS = "VERYFYBYPHONE_VALIDATE_SUCCESS";
const VERYFYBYPHONE_VALIDATE_FAILS = "VERYFYBYPHONE_VALIDATE_FAILS";

const USER_UNBLOCK = "USER_UNBLOCK";
const USER_FETCH_SUBSCRIPTION_SUCCESS = "USER_FETCH_SUBSCRIPTION_SUCCESS";
const USER_FETCH_CREDITS_SUCCESS = "USER_FETCH_CREDITS_SUCCESS";
const USER_FETCH_CREDITS_CHANGED_SUCCESS = "USER_FETCH_CREDITS_CHANGED_SUCCESS";
const USER_UPDATE = "USER_UPDATE";
const USER_CONNECT_INVITED_SUCC = "USER_CONNECT_INVITED_SUCC";
const USER_CONNECT_PENDING_INVITES = "USER_CONNECT_PENDING_INVITES";
const USER_CONNECT_INVITE_SUCC = "USER_CONNECT_INVITE_SUCC";
const USER_ACCEPT_INVITED_SUCC = "USER_ACCEPT_INVITED_SUCC";
const USER_ACCEPT_INVITE_SUCC = "USER_ACCEPT_INVITE_SUCC";
const USER_UNFRIEND_SUCC = "USER_UNFRIEND_SUCC";
const USER_UNFRIEND_ISLOADING = "USER_UNFRIEND_ISLOADING";
const USER_BLOCK_SUCCESS = 'USER_BLOCK_SUCCESS'
const USER_UNBLOCK_SUCCESS = 'USER_UNBLOCK_SUCCESS'

const NEAR_USERS_ISLOADING = "NEAR_USERS_ISLOADING";
const NEAR_USERS_SUCCESS = "NEAR_USERS_SUCCESS";
const NEAR_USERS_FAIL = "NEAR_USERS_FAIL";

const NEAR_USERS_BROWSER_ISLOADING = "NEAR_USERS_BROWSER_ISLOADING";
const NEAR_USERS_BROWSER_SUCCESS = "NEAR_USERS_BROWSER_SUCCESS";
const NEAR_USERS_BROWSER_FAIL = "NEAR_USERS_BROWSER_FAIL";

const PROFILE_SENT_REFERRAL_COUNTER = "PROFILE_SENT_REFERRAL_COUNTER";
const PROFILE_RECEIVED_REFERRAL_COUNTER = "PROFILE_RECEIVED_REFERRAL_COUNTER";
const PROFILE_COUNTERS_LOADING = "PROFILE_COUNTERS_LOADING";
const PROFILE_COUNTERS_SUCC = "PROFILE_COUNTERS_SUCC";
const PROFILE_CLEAR = "PROFILE_CLEAR";
const PROFILE_WHOISWATCHING = "PROFILE_WHOISWATCHING";
const PROFILE_CONTACTINFO_OPEN = "PROFILE_CONTACTINFO_OPEN";
const PROFILE_FETCH_COMMON_CONTACTS = "PROFILE_FETCH_COMMON_CONTACTS";
const PROFILE_FETCH_COMMON_CONTACTS_SUCC = "PROFILE_FETCH_COMMON_CONTACTS_SUCC";
const PROFILE_FETCH_COMMON_CONTACTS_FAIL = "PROFILE_FETCH_COMMON_CONTACTS_FAIL";
const PROFILE_FETCH_OPEN_OPS = "PROFILE_FETCH_OPEN_OPS";
const PROFILE_FETCH_OPEN_OPS_SUCC = "PROFILE_FETCH_OPEN_OPS_SUCC";
const PROFILE_FETCH_OPEN_OPS_FAIL = "PROFILE_FETCH_OPEN_OPS_FAIL";
const PROFILE_CLEAR_OPEN_OPS = "PROFILE_CLEAR_OPEN_OPS";
const PROFILE_FAVORITE_USER = "PROFILE_FAVORITE_USER";
const PROFILE_GET_USER_FAVORITES_LOADING = "PROFILE_GET_USER_FAVORITES_LOADING";
const PROFILE_GET_USER_FAVORITES_SUCCESS = "PROFILE_GET_USER_FAVORITES_SUCCESS";
const PROFILE_GET_USER_FAVORITES_FAIL = "PROFILE_GET_USER_FAVORITES_FAIL";
const PROFILE_GET_USER_ENDORSEMENTS_LOADING = "PROFILE_GET_USER_ENDORSEMENTS_LOADING";
const PROFILE_GET_USER_ENDORSEMENTS_SUCCESS = "PROFILE_GET_USER_ENDORSEMENTS_SUCCESS";
const PROFILE_GET_USER_ENDORSEMENTS_FAIL = "PROFILE_GET_USER_ENDORSEMENTS_FAIL";
const PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS = "PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS";
const PROFILE_ADD_ENDORSEMENT_SUCCESS = "PROFILE_ADD_ENDORSEMENT_SUCCESS";
const PROFILE_ADD_ENDORSEMENT_FAIL = "PROFILE_ADD_ENDORSEMENT_FAIL";
const PROFILE_UPDATE_ENDORSEMENT_SUCCESS = "PROFILE_UPDATE_ENDORSEMENT_SUCCESS";
const PROFILE_UPDATE_ENDORSEMENT_FAIL = "PROFILE_UPDATE_ENDORSEMENT_FAIL";
const PROFILE_DELETE_ENDORSEMENT_SUCCESS = "PROFILE_DELETE_ENDORSEMENT_SUCCESS";
const PROFILE_DELETE_ENDORSEMENT_FAIL = "PROFILE_DELETE_ENDORSEMENT_FAIL";
const PROFILE_HIDE_ENDORSEMENT_SUCCESS = "PROFILE_HIDE_ENDORSEMENT_SUCCESS";

const PROFILE_GET_USER_GRATITUDES_LOADING = "PROFILE_GET_USER_GRATITUDES_LOADING";
const PROFILE_GET_USER_GRATITUDES_SUCCESS = "PROFILE_GET_USER_GRATITUDES_SUCCESS";
const PROFILE_GET_USER_GRATITUDES_FAIL = "PROFILE_GET_USER_GRATITUDES_FAIL";
const PROFILE_ADD_FIRST_GRATITUDE_SUCCESS =
    "PROFILE_ADD_FIRST_GRATITUDE_SUCCESS";
const PROFILE_ADD_GRATITUDE_SUCCESS = "PROFILE_ADD_GRATITUDE_SUCCESS";
const PROFILE_ADD_GRATITUDE_FAIL = "PROFILE_ADD_GRATITUDE_FAIL";
const PROFILE_UPDATE_GRATITUDE_SUCCESS = "PROFILE_UPDATE_GRATITUDE_SUCCESS";
const PROFILE_UPDATE_GRATITUDE_FAIL = "PROFILE_UPDATE_GRATITUDE_FAIL";
const PROFILE_DELETE_GRATITUDE_SUCCESS = "PROFILE_DELETE_GRATITUDE_SUCCESS";
const PROFILE_DELETE_GRATITUDE_FAIL = "PROFILE_DELETE_GRATITUDE_FAIL";
const PROFILE_HIDE_GRATITUDE_SUCCESS = "PROFILE_HIDE_GRATITUDE_SUCCESS";

const PROFILE_GET_USER_OP_REVIEWS_LOADING = "PROFILE_GET_USER_OP_REVIEWS_LOADING";
const PROFILE_GET_USER_OP_REVIEWS_SUCCESS = "PROFILE_GET_USER_OP_REVIEWS_SUCCESS";
const PROFILE_GET_USER_OP_REVIEWS_FAIL = "PROFILE_GET_USER_OP_REVIEWS_FAIL";
const PROFILE_ADD_OP_REVIEW_SUCCESS = "PROFILE_ADD_OP_REVIEW_SUCCESS";
const PROFILE_ADD_OP_REVIEW_FAIL = "PROFILE_ADD_OP_REVIEW_FAIL";
const PROFILE_UPDATE_OP_REVIEW_SUCCESS = "PROFILE_UPDATE_OP_REVIEW_SUCCESS";
const PROFILE_UPDATE_OP_REVIEW_FAIL = "PROFILE_UPDATE_OP_REVIEW_FAIL";
const PROFILE_DELETE_OP_REVIEW_SUCCESS = "PROFILE_DELETE_OP_REVIEW_SUCCESS";
const PROFILE_DELETE_OP_REVIEW_FAIL = "PROFILE_DELETE_OP_REVIEW_FAIL";

const PROFILE_OPEN_ENDORSEMENT_DETAILS = "PROFILE_OPEN_ENDORSEMENT_DETAILS";
const PROFILE_OPEN_GRATITUDE_DETAILS = "PROFILE_OPEN_GRATITUDE_DETAILS";
const PROFILE_OPEN_OP_REVIEW_DETAILS = "PROFILE_OPEN_OP_REVIEW_DETAILS";

const FETCH_REFERRALINFORMATION_FAIL = "FETCH_REFERRALINFORMATION_FAIL";
const FETCH_REFERRALINFORMATION_SUCCESS = "FETCH_REFERRALINFORMATION_SUCCESS";
const FETCH_REFERRALINFORMATION_ISLOADING =
    "FETCH_REFERRALINFORMATION_ISLOADING";

const CONTACTS_USER_LIST_FETCH_USERS = "CONTACTS_USER_LIST_FETCH_USERS";
const CONTACTS_USER_LIST_FETCH_USERS_SUCC =
    "CONTACTS_USER_LIST_FETCH_USERS_SUCC";
const CONTACTS_USER_LIST_FETCH_USERS_FAIL =
    "CONTACTS_USER_LIST_FETCH_USERS_FAIL";
const CONTACTS_SET_CONTACT_UIDS_LIST = "CONTACTS_SET_CONTACT_UIDS_LIST";
const CONTACTS_CREATE_CONTACT = "CONTACTS_CREATE_CONTACT";
const CONTACTS_CREATE_CONTACT_SUCCCESS = "CONTACTS_CREATE_CONTACT_SUCCCESS";
const CONTACTS_CREATE_CONTACT_FAIL = "CONTACTS_CREATE_CONTACT_FAIL";
const REFERRALINFORMATION_INIT = "REFERRALINFORMATION_INIT";
const REFERRALINFORMATION_CLAIM_MODAL = "REFERRALINFORMATION_CLAIM_MODAL";
const REFERRALS_CLAIM_SUCCESS = "REFERRALS_CLAIM_SUCCESS";
const REFERRALS_UNCLAIM_SUCCESS = "REFERRALS_UNCLAIM_SUCCESS";

const REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL = "REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL";
const REFERRALINFORMATION_NO_LONGER_AVAILABLE_TOGGLE_MODAL = "REFERRALINFORMATION_NO_LONGER_AVAILABLE_TOGGLE_MODAL";
const REFERRALINFORMATION_SET_REFERRAL = "REFERRALINFORMATION_SET_REFERRAL";
const REFERRALINFORMATION_SET_SECTION = "REFERRALINFORMATION_SET_SECTION";
const REFERRALINFORMATION_TIMELINE_SUCCESS = "REFERRALINFORMATION_TIMELINE_SUCCESS";
const REFERRALINFORMATION_TIMELINE_LOADING = "REFERRALINFORMATION_TIMELINE_LOADING";
const REFERRALINFORMATION_TIMELINE_ADD_ITEM = "REFERRALINFORMATION_TIMELINE_ADD_ITEM";
const REFERRALINFORMATION_SELECT_CANDIDATE = "REFERRALINFORMATION_SELECT_CANDIDATE";
const REFERRALINFORMATION_CONFIRM_PAST = "REFERRALINFORMATION_CONFIRM_PAST";
const REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY = "REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY";

const REFERRALS_DELETE_INCOMING = "REFERRALS_DELETE_INCOMING";
const REFERRALS_DELETE_PENDING_SENT = "REFERRALS_DELETE_PENDING_SENT";
const REFERRALS_ONVALUE = "REFERRALS_ONVALUE";
const REFERRALS_SET_SELECTED_TAB = "REFERRALS_SET_SELECTED_TAB";
const REFERRALS_SET_FILTER_SEARCH = "REFERRALS_SET_FILTER_SEARCH";
const REFERRALS_SET_FILTER_SORT = "REFERRALS_SET_FILTER_SORT";
const REFERRALS_SET_FILTER_STATUS_ADD = "REFERRALS_SET_FILTER_STATUS_ADD";
const REFERRALS_SET_FILTER_STATUS_REMOVE = "REFERRALS_SET_FILTER_STATUS_REMOVE";
const REFERRALS_SET_FILTER_OPEN = "REFERRALS_SET_FILTER_OPEN";
const REFERRALS_IS_LOADING = "REFERRALS_IS_LOADING";
const REFERRALS_FETCH_SUCCESS = "REFERRALS_FETCH_SUCCESS";
const REFERRALS_FOOTER_OPEN = "REFERRALS_FOOTER_OPEN";
const REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL = "REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL";
const REFERRALS_NOT_AUTHORIZED_MODAL = "REFERRALS_NOT_AUTHORIZED_MODAL";

const SEND_REFERRAL_ADD_USER_TO_REFERRAL = "SEND_REFERRAL_ADD_USER_TO_REFERRAL";
const SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL = "SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL";

const NAVBAR_TOUCH_NOTIFICATION = "NAVBAR_TOUCH_NOTIFICATION";
const NAVBAR_MOREOPTIONS_OPEN = "NAVBAR_MOREOPTIONS_OPEN";
const NAVBAR_TOOGLE_BACKBUTTON = "NAVBAR_TOOGLE_BACKBUTTON";

const NOTIFICATIONS_BELL_FETCH_SUCCESS = "NOTIFICATIONS_BELL_FETCH_SUCCESS"
const NOTIFICATIONS_BELL_ISLOADING = "NOTIFICATIONS_BELL_ISLOADING"
const NOTIFICATIONS_BELL_REMOVE = "NOTIFICATIONS_BELL_REMOVE"
const NOTIFICATIONS_BELL_TOOGLE_NEW = "NOTIFICATIONS_BELL_TOOGLE_NEW"
const NOTIFICATIONS_BELL_ADD = "NOTIFICATIONS_BELL_ADD"
const NOTIFICATIONS_BELL_READ = "NOTIFICATIONS_BELL_READ"
const NOTIFICATIONS_BELL_ALL_READ = "NOTIFICATIONS_BELL_ALL_READ"

const CONNECTION_REQUESTS_FETCH_SUCCESS = "CONNECTION_REQUESTS_FETCH_SUCCESS"
const CONNECTION_REQUESTS_ACCEPT_DECLINE = "CONNECTION_REQUESTS_ACCEPT_DECLINE"
const MAINTENANCE_ALERT = "MAINTENANCE_ALERT"

const SHOW_TOAST = "SHOW_TOAST";
const CLOSE_TOAST = "CLOSE_TOAST";
const SET_VERIFIED_PHONE = "SET_VERIFIED_PHONE";

const OPEN_POP_UP_MODAL = "OPEN_POP_UP_MODAL";
const CLOSE_POP_UP_MODAL = "CLOSE_POP_UP_MODAL";

const USER_CONNECT_SENT_INVITES = "USER_CONNECT_SENT_INVITES";
const USER_CONTACTS = "USER_CONTACTS";
const USER_IPINFO_SUCCESS = "USER_IPINFO_SUCCESS";
const USER_SET_ONBOARDING = "USER_SET_ONBOARDING";

const RERZONEUSERS_ISLOADING = "RERZONEUSERS_ISLOADING";
const RERZONEUSERS_SUCCESS = "RERZONEUSERS_SUCCESS";
const RERZONEUSERS_FAIL = "RERZONEUSERS_FAIL";

const LOCATION_UPDATE = "LOCATION_UPDATE";
const SEND_REFERRAL_CLEAN_FORM = "SEND_REFERRAL_CLEAN_FORM";
const NEWPWAVERSION_AVAILABLE = "NEWPWAVERSION_AVAILABLE";
const SET_SERVICES = "SET_SERVICES";

const COMMUNITY_FEATUREDPOSTSUCCESS = "COMMUNITY_FEATUREDPOSTSUCCESS";
const COMMUNITY_ISLOADING = "COMMUNITY_ISLOADING";
const CLEAN_COMMUNITY = "CLEAN_COMMUNITY";
const COMMUNITY_NEWESTPOSTSSUCCESS = "COMMUNITY_NEWESTPOSTSSUCCESS";
const COMMUNITY_NEW_NEWS_NOTIFICATION = "COMMUNITY_NEW_NEWS_NOTIFICATION";
const COMMUNITY_ADD = "COMMUNITY_ADD";
const COMMUNITY_OPEN_IMAGES_CAROUSEL = "COMMUNITY_OPEN_IMAGES_CAROUSEL";
const COMMUNITY_DISCUSSION_RANK = "COMMUNITY_DISCUSSION_RANK";
const COMMUNITY_UPVOTE = "COMMUNITY_UPVOTE";
const COMMUNITY_DOWNVOTE = "COMMUNITY_DOWNVOTE";
const COMMUNITY_FETCHPOST_SUCCESS = "COMMUNITY_FETCHPOST_SUCCESS";
const COMMUNITY_REPLY_POST_SUCCESS = "COMMUNITY_REPLY_POST_SUCCESS";
const COMMUNITY_UPDATE = "COMMUNITY_UPDATE";

const SET_REFERRAL_TYPE = "SET_REFERRAL_TYPE";

const NEWARTICLE_ISLOADING = "NEWARTICLE_ISLOADING";
const NEWARTICLE_SETTITLE = "NEWARTICLE_SETTITLE";
const NEWARTICLE_SETBODY = "NEWARTICLE_SETBODY";
const NEWARTICLE_ADDHASHTAG = "NEWARTICLE_ADDHASHTAG";
const NEWARTICLE_REMOVEHASHTAG = "NEWARTICLE_REMOVEHASHTAG";
const NEWARTICLE_REMOVEIMAGE = "NEWARTICLE_REMOVEIMAGE";
const NEWARTICLE_ADDIMAGE = "NEWARTICLE_ADDIMAGE";
const NEWARTICLE_ADDIMAGESUCCESS = "NEWARTICLE_ADDIMAGESUCCESS";
const NEWARTICLE_FAIL = "NEWARTICLE_FAIL";
const NEWARTICLE_SETPRIMARYIMAGE = "NEWARTICLE_SETPRIMARYIMAGE";
const NEWARTICLE_TOOGLEVISIBILITYMODAL = "NEWARTICLE_TOOGLEVISIBILITYMODAL";
const NEWARTICLE_SETVISIBILITY = "NEWARTICLE_SETVISIBILITY";
const NEWARTICLE_CLEARFORM = "NEWARTICLE_CLEARFORM";
const NEWARTICLE_DELETEIMAGE = "NEWARTICLE_DELETEIMAGE";

const NEWS_ISLOADING = "NEWS_ISLOADING";
const NEWS_BUILDYOURTEAMCARD_OPEN = "NEWS_BUILDYOURTEAMCARD_OPEN";
const NEWS_COMMUNITYFILTER_SET = "NEWS_COMMUNITYFILTER_SET";

const FETCH_BIGBOARD_FAIL = "FETCH_BIGBOARD_FAIL";
const FETCH_BIGBOARD_SUCCESS = "FETCH_BIGBOARD_SUCCESS";
const FETCH_BIGBOARD_ISLOADING = "FETCH_BIGBOARD_ISLOADING";
const BIGBOARD_UPDATE_FILTERS = "BIGBOARD_UPDATE_FILTERS";
const BIGBOARD_RESET_FILTERS = "BIGBOARD_RESET_FILTERS";
const BIGBOARD_SET_PAGE = "BIGBOARD_SET_PAGE";
const BIGBOARD_SET_REFERRAL_POPUP = "BIGBOARD_SET_REFERRAL_POPUP";
const BIGBOARD_REMOVE_REFERRAL = "BIGBOARD_REMOVE_REFERRAL";

const CHAT_ISLOADING = "CHAT_ISLOADING";
const CHAT_CLEAN = "CHAT_CLEAN";
const CHAT_SETCONVERSATIONS = "CHAT_SETCONVERSATIONS";
const CHAT_CONVERSATIONS_LOADING = "CHAT_CONVERSATIONS_LOADING";
const CHAT_SELECT_CONVERSATION = "CHAT_SELECT_CONVERSATION";
const CHAT_ADD_CONVERSATION_MESSAGE = "CHAT_ADD_CONVERSATION_MESSAGE";
const CHAT_SET_CONVERSATION_MESSAGES = "CHAT_SET_CONVERSATION_MESSAGES";
const CHAT_RESET_CONVERSATIONS_MESSAGES = "CHAT_RESET_CONVERSATIONS_MESSAGES";
const CHAT_CONVERSATION_SET_USER_STATUS = "CHAT_CONVERSATION_SET_USER_STATUS";
const CHAT_UPDATECONVERSATION = "CHAT_UPDATECONVERSATION";
const CHAT_OPEN_PROFILE_DETAILS = "CHAT_OPEN_PROFILE_DETAILS";
const CHAT_FETCH_CONTACT_SUCCESS = "CHAT_FETCH_CONTACT_SUCCESS";
const CHAT_FETCH_CONTACT_FAIL = "CHAT_FETCH_CONTACT_FAIL";
const CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS = "CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS";

const NAVBAR_CHAT_NEWMESSAGE = "NAVBAR_CHAT_NEWMESSAGE";
const NAVBAR_CHAT_REMOVE_NEWMESSAGE = "NAVBAR_CHAT_REMOVE_NEWMESSAGE";

const OPPORTUNITY_FLOW_SET_ACTIVE_STEP = "OPPORTUNITY_FLOW_SET_ACTIVE_STEP";
const OPPORTUNITY_FLOW_RESET = "OPPORTUNITY_FLOW_RESET";

const SHOW_REFERRALS_FILTERS = "SHOW_REFERRALS_FILTERS";
const HIDE_REFERRALS_FILTERS = "HIDE_REFERRALS_FILTERS";
const REFERRALS_UPDATE_FILTERS = "REFERRALS_UPDATE_FILTERS";
const REFERRALS_RESET_FILTERS = "REFERRALS_RESET_FILTERS";
const REFERRALS_APPLY_FILTERS = "REFERRALS_APPLY_FILTERS";
const REFERRALS_SET_STATUS = "REFERRALS_SET_STATUS";

const CONNECTIONS_IS_LOADING = "CONNECTIONS_IS_LOADING";
const FETCH_MY_CONNECTIONS_SUCCESS = "FETCH_MY_CONNECTIONS_SUCCESS";
const FETCH_MY_CONNECTIONS_FAIL = "FETCH_MY_CONNECTIONS_FAIL";
const MY_CONNECTIONS_UPDATE_FILTERS = "MY_CONNECTIONS_UPDATE_FILTERS";
const MY_CONNECTIONS_RESET_FILTERS = "MY_CONNECTIONS_RESET_FILTERS";
const CONTACTS_IS_LOADING = "CONTACTS_IS_LOADING";
const FETCH_MY_CONTACTS_SUCCESS = "FETCH_MY_CONTACTS_SUCCESS";
const FETCH_MY_CONTACTS_FAIL = "FETCH_MY_CONTACTS_FAIL";
const MY_CONTACTS_UPDATE_FILTERS = "MY_CONTACTS_UPDATE_FILTERS";
const MY_CONTACTS_RESET_FILTERS = "MY_CONTACTS_RESET_FILTERS";
const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";
const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
const UPDATE_CONTACT = "UPDATE_CONTACT";
const CREATE_CONTACT = "CREATE_CONTACT";
const CREATE_CONTACT_FAIL = "CREATE_CONTACT_FAIL";
const HIDE_CREATE_CONTACT_ERROR = "HIDE_CREATE_CONTACT_ERROR";
const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";

const SHOW_CONTACT_FORM = "SHOW_CONTACT_FORM";
const HIDE_CONTACT_FORM = "HIDE_CONTACT_FORM";
const SHOW_CONTACT_FILTERS = "SHOW_CONTACT_FILTERS";
const HIDE_CONTACT_FILTERS = "HIDE_CONTACT_FILTERS";
const SHOW_CONNECTIONS_FILTERS = "SHOW_CONNECTIONS_FILTERS";
const HIDE_CONNECTIONS_FILTERS = "HIDE_CONNECTIONS_FILTERS";
const SHOW_CONTACT_PROFILE = "SHOW_CONTACT_PROFILE";
const HIDE_CONTACT_PROFILE = "HIDE_CONTACT_PROFILE";
const SHOW_DELETE_CONTACT_MODAL = "SHOW_DELETE_CONTACT_MODAL";
const HIDE_DELETE_CONTACT_MODAL = "HIDE_DELETE_CONTACT_MODAL";
const SHOW_CONTACT_NOTE_EDITOR = "SHOW_CONTACT_NOTE_EDITOR";
const HIDE_CONTACT_NOTE_EDITOR = "HIDE_CONTACT_NOTE_EDITOR";
const SHOW_CONTACT_INVITE_MODAL = "SHOW_CONTACT_INVITE_MODAL";
const HIDE_CONTACT_INVITE_MODAL = "HIDE_CONTACT_INVITE_MODAL";
const HIDE_CONTACT_CREATED_MODAL = "HIDE_CONTACT_CREATED_MODAL";
const SHOW_CONTACT_CREATED_MODAL = "SHOW_CONTACT_CREATED_MODAL";
const FETCH_CONTACT_DETAILS_SUCCESS = "FETCH_CONTACT_DETAILS_SUCCESS";
const FETCH_CONTACT_DETAILS_FAIL = "FETCH_CONTACT_DETAILS_FAIL";
const ADD_ACTIVITY_FEED_NOTE = "ADD_ACTIVITY_FEED_NOTE";
const UPDATE_ACTIVITY_FEED_NOTE = "UPDATE_ACTIVITY_FEED_NOTE";
const HIDE_MESSAGE_POPUP = "HIDE_MESSAGE_POPUP";
const SHOW_MESSAGE_POPUP = "SHOW_MESSAGE_POPUP";
const CONTACT_SEND_CONNECTION_SUCCESS = "CONTACT_SEND_CONNECTION_SUCCESS";
const CONTACT_ALREADY_REGISTERED = "CONTACT_ALREADY_REGISTERED";
const IMPORT_CONTACTS_SUCCESS = "IMPORT_CONTACTS_SUCCESS";
const SHOW_IMPORT_CONTACTS_SUCCESS = "SHOW_IMPORT_CONTACTS_SUCCESS";
const HIDE_IMPORT_CONTACTS_SUCCESS = "HIDE_IMPORT_CONTACTS_SUCCESS";
const SHOW_REINVITE_MODAL = "SHOW_REINVITE_MODAL";
const HIDE_REINVITE_MODAL = "HIDE_REINVITE_MODAL";
const IMPORT_CONTACT_FROM_OTHER_USER_LOADING = "IMPORT_CONTACT_FROM_OTHER_USER_LOADING";
const IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS = "IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS";
const IMPORT_CONTACT_FROM_OTHER_USER_FAIL = "IMPORT_CONTACT_FROM_OTHER_USER_FAIL";
const HIDE_IMPORT_FROM_OTHER_USER_SUCCESS = "HIDE_IMPORT_FROM_OTHER_USER_SUCCESS";

const SHARE_OPEN = "SHARE_OPEN";
const SHARE_CLOSE = "SHARE_CLOSE";
const PEOPLE_SEARCHBAR_LOADING = "PEOPLE_SEARCHBAR_LOADING";
const PEOPLE_SEARCHBAR_SUCCESS = "PEOPLE_SEARCHBAR_SUCCESS";
const PEOPLE_SEARCHBAR_FAIL = "PEOPLE_SEARCHBAR_FAIL";
const PEOPLE_SEARCHBAR_CLEAR = "PEOPLE_SEARCHBAR_CLEAR";

const SAVE_REFERRAL_DRAFT = "SAVE_REFERRAL_DRAFT";
const LOAD_REFERRAL_DRAFT = "LOAD_REFERRAL_DRAFT";
const FETCH_DRAFT_REFERRALS = "FETCH_DRAFT_REFERRALS";

const FETCH_MYOPS_ANALYTICS_LOADING = "FETCH_MYOPS_ANALYTICS_LOADING";
const FETCH_MYOPS_ANALYTICS_SUCCESS = "FETCH_MYOPS_ANALYTICS_SUCCESS";
const FETCH_MYOPS_ANALYTICS_FAIL = "FETCH_MYOPS_ANALYTICS_FAIL";

const FETCH_OPS_ACTIVITY_COUNT_LOADING = "FETCH_OPS_ACTIVITY_COUNT_LOADING";
const FETCH_OPS_ACTIVITY_COUNT_SUCCESS = "FETCH_OPS_ACTIVITY_COUNT_SUCCESS";
const FETCH_OPS_ACTIVITY_COUNT_FAIL = "FETCH_OPS_ACTIVITY_COUNT_FAIL";
const OPS_ACTIVITY_COUNT_UPDATE_FILTERS = "OPS_ACTIVITY_COUNT_UPDATE_FILTERS";
const FETCH_OPS_ACTIVITY_ANALYTICS_LOADING = "FETCH_OPS_ACTIVITY_ANALYTICS_LOADING";
const FETCH_OPS_ACTIVITY_ANALYTICS_SUCCESS =
    "FETCH_OPS_ACTIVITY_ANALYTICS_SUCCESS";
const FETCH_OPS_ACTIVITY_ANALYTICS_FAIL = "FETCH_OPS_ACTIVITY_ANALYTICS_FAIL";
const OPS_ACTIVITY_ANALYTICS_UPDATE_FILTERS = "OPS_ACTIVITY_ANALYTICS_UPDATE_FILTERS";
const FETCH_MEMBER_COMPARISON_LOADING = "FETCH_MEMBER_COMPARISON_LOADING";
const FETCH_MEMBER_COMPARISON_SUCCESS = "FETCH_MEMBER_COMPARISON_SUCCESS";
const FETCH_MEMBER_COMPARISON_FAIL = "FETCH_MEMBER_COMPARISON_FAIL";
const MEMBER_COMPARISON_UPDATE_FILTERS = "MEMBER_COMPARISON_UPDATE_FILTERS";
const MEMBER_COMPARISON_RESET_FILTERS = "MEMBER_COMPARISON_RESET_FILTERS";

/*  ----- Current referral Flow ----- */
const CURRENT_REFERRAL_LOADING = "CURRENT_REFERRAL_LOADING"
const CURRENT_REFERRAL_SUCCESS = "CURRENT_REFERRAL_SUCCESS"
const CURRENT_REFERRAL_ERROR =   "CURRENT_REFERRAL_ERROR"
const NEW_CONTACT_SAVED = "NEW_CONTACT_SAVED"
const CONTACT_IS_LOADING = "CONTACT_IS_LOADING"
const CONTACT_SAVED = "CONTACT_SAVED"
const CONTACT_ERROR = "CONTACT_ERROR"
const CANCEL_CONTACT_ERROR = "CANCEL_CONTACT_ERROR"
// MARK: STATUS CHANGE & COMPLETION FLOW
const DID_STATUS_CHANGED = "DID_STATUS_CHANGED"
const STATUS_HAS_CHANGE =  "STATUS_HAS_CHANGE"
const STATUS_IS_LOADING = "STATUS_IS_LOADING"
const STATUS_CHANGE_ERROR = "STATUS_CHANGE_ERROR"
const SAVE_COMPLETION_DATA = "SAVE_COMPLETION_DATA"

const RECOMMEND_MODAL_OPEN = "RECOMMEND_MODAL_OPEN";
const RECOMMEND_MODAL_CLOSE = "RECOMMEND_MODAL_CLOSE";
const RECOMMEND_SEND_SUCCESS = "RECOMMEND_SEND_SUCCESS";
const RECOMMEND_SEND_FAIL = "RECOMMEND_SEND_FAIL";
const RECOMMEND_SEND_LOADING = "RECOMMEND_SEND_LOADING";

const UPVOTE_CANDIDATE = "UPVOTE_CANDIDATE";
const UPVOTE_RECOMMENDATION = "UPVOTE_RECOMMENDATION";

// ------------- Leaderboard ------------- //
const FETCH_LEADERBOARD_ISLOADING = "FETCH_LEADERBOARD_ISLOADING";
const FETCH_LEADERBOARD_SUCCESS = "FETCH_LEADERBOARD_SUCCESS";
const FETCH_LEADERBOARD_FAIL = "FETCH_LEADERBOARD_FAIL";
const LEADERBOARD_UPDATE_FILTERS = "LEADERBOARD_UPDATE_FILTERS";
const LEADERBOARD_RESET_FILTERS = "LEADERBOARD_RESET_FILTERS";
const LEADERBOARD_SET_PAGE = "LEADERBOARD_SET_PAGE";
const FETCH_USER_LEADERBOARD_STATS_ISLOADING = "FETCH_USER_LEADERBOARD_STATS_ISLOADING";
const FETCH_USER_LEADERBOARD_STATS_SUCCESS = "FETCH_USER_LEADERBOARD_STATS_SUCCESS";
const FETCH_USER_LEADERBOARD_STATS_FAIL = "FETCH_USER_LEADERBOARD_STATS_FAIL";

module.exports = {
    CONTACT_IS_LOADING,
    CONTACT_ERROR,
    CONTACT_SAVED,
    CANCEL_CONTACT_ERROR,
    DID_STATUS_CHANGED,
    STATUS_HAS_CHANGE,
    STATUS_IS_LOADING,
    STATUS_CHANGE_ERROR,
    SAVE_COMPLETION_DATA,
    CURRENT_REFERRAL_LOADING,
    CURRENT_REFERRAL_SUCCESS,
    CURRENT_REFERRAL_ERROR,
    FETCH_DRAFT_REFERRALS,
    SAVE_REFERRAL_DRAFT,
    LOAD_REFERRAL_DRAFT,
    IMPORT_CONTACTS_SUCCESS,
    SHOW_IMPORT_CONTACTS_SUCCESS,
    HIDE_IMPORT_CONTACTS_SUCCESS,
    SHOW_REINVITE_MODAL,
    HIDE_REINVITE_MODAL,
    IMPORT_CONTACT_FROM_OTHER_USER_LOADING,
    IMPORT_CONTACT_FROM_OTHER_USER_SUCCESS,
    IMPORT_CONTACT_FROM_OTHER_USER_FAIL,
    HIDE_IMPORT_FROM_OTHER_USER_SUCCESS,
    HIDE_MESSAGE_POPUP,
    SHOW_MESSAGE_POPUP,
    CONTACT_ALREADY_REGISTERED,
    CONTACT_SEND_CONNECTION_SUCCESS,
    FETCH_CONTACT_DETAILS_SUCCESS,
    SHOW_CONTACT_CREATED_MODAL,
    FETCH_CONTACT_DETAILS_FAIL,
    ADD_ACTIVITY_FEED_NOTE,
    UPDATE_ACTIVITY_FEED_NOTE,
    HIDE_CONTACT_CREATED_MODAL,
    SHOW_CONTACT_INVITE_MODAL,
    HIDE_CONTACT_INVITE_MODAL,
    CREATE_CONTACT_FAIL,
    HIDE_CREATE_CONTACT_ERROR,
    CREATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_FAIL,
    SHOW_CONTACT_NOTE_EDITOR,
    HIDE_CONTACT_NOTE_EDITOR,
    DELETE_CONTACT_FAIL,
    DELETE_CONTACT_SUCCESS,
    SHOW_DELETE_CONTACT_MODAL,
    HIDE_DELETE_CONTACT_MODAL,
    SHOW_CONTACT_PROFILE,
    HIDE_CONTACT_PROFILE,
    SHOW_CONTACT_FORM,
    HIDE_CONTACT_FORM,
    SHOW_CONTACT_FILTERS,
    HIDE_CONTACT_FILTERS,
    SHOW_CONNECTIONS_FILTERS,
    HIDE_CONNECTIONS_FILTERS,
    CONNECTIONS_IS_LOADING,
    FETCH_MY_CONNECTIONS_SUCCESS,
    FETCH_MY_CONNECTIONS_FAIL,
    MY_CONNECTIONS_UPDATE_FILTERS,
    MY_CONNECTIONS_RESET_FILTERS,
    CONTACTS_IS_LOADING,
    FETCH_MY_CONTACTS_SUCCESS,
    FETCH_MY_CONTACTS_FAIL,
    MY_CONTACTS_UPDATE_FILTERS,
    MY_CONTACTS_RESET_FILTERS,
    UPDATE_CONTACT,
    CREATE_CONTACT,
    SHOW_REFERRALS_FILTERS,
    HIDE_REFERRALS_FILTERS,
    REFERRALS_UPDATE_FILTERS,
    REFERRALS_RESET_FILTERS,
    REFERRALS_APPLY_FILTERS,
    REFERRALS_SET_STATUS,
    NAVBAR_CHAT_NEWMESSAGE,
    NAVBAR_CHAT_REMOVE_NEWMESSAGE,
    NEWS_COMMUNITYFILTER_SET,
    NEWS_ISLOADING,
    NEWS_BUILDYOURTEAMCARD_OPEN,
    NEWARTICLE_ADDHASHTAG,
    NEWARTICLE_ADDIMAGE,
    NEWARTICLE_ISLOADING,
    NEWARTICLE_REMOVEHASHTAG,
    NEWARTICLE_REMOVEIMAGE,
    NEWARTICLE_SETBODY,
    NEWARTICLE_SETTITLE,
    NEWARTICLE_ADDIMAGESUCCESS,
    COMMUNITY_FEATUREDPOSTSUCCESS,
    COMMUNITY_ISLOADING,
    NEWARTICLE_FAIL,
    NEWARTICLE_SETPRIMARYIMAGE,
    NEWARTICLE_TOOGLEVISIBILITYMODAL,
    NEWARTICLE_SETVISIBILITY,
    NEWARTICLE_CLEARFORM,
    NEWARTICLE_DELETEIMAGE,
    SHARED_REALSTATEFOCUS_SUCCESS,
    FETCH_REFERRALINFORMATION_FAIL,
    FETCH_REFERRALINFORMATION_SUCCESS,
    FETCH_REFERRALINFORMATION_ISLOADING,
    REFERRALINFORMATION_INIT,
    REFERRALS_CLAIM_SUCCESS,
    REFERRALS_UNCLAIM_SUCCESS,
    REFERRALINFORMATION_CLAIM_MODAL,
    REFERRALINFORMATION_CONFIRM_ASSIGN_MODAL,
    REFERRALINFORMATION_NO_LONGER_AVAILABLE_TOGGLE_MODAL,
    REFERRALS_NOT_AUTHORIZED_MODAL,
    REFERRALINFORMATION_SET_REFERRAL,
    REFERRALINFORMATION_SET_SECTION,
    REFERRALINFORMATION_TIMELINE_SUCCESS,
    REFERRALINFORMATION_TIMELINE_LOADING,
    REFERRALINFORMATION_TIMELINE_ADD_ITEM,
    REFERRALINFORMATION_SELECT_CANDIDATE,
    REFERRALINFORMATION_CONFIRM_PAST,
    REFERRALINFORMATION_DECLINE_OPEN_OPPORTUNITY,
    EDITPROFILE_ISLOADING,
    EDITPROFILE_FETCHAGENCIESSUCCESS,
    EDITPROFILE_FETCHPROFILESUCCESS,
    EDITPROFILE_FETCHPROFILEFAIL,
    EDITPROFILE_FETCHAGENCYSUCCESS,
    EDITPROFILE_FETCHFAIL,
    EDITPROFILE_UPLOADAVATARSUCCESS,
    ACTION,
    SHOW_MODAL,
    CLOSE_MODAL,
    NAV_FAIL,
    CHAIN_FAIL,
    SIGN_UP,
    SIGN_UP_FAIL,
    SIGN_UP_SUCC,
    SIGN_IN,
    SIGN_OUT,
    SIGN_IN_FAIL,
    SIGN_IN_SUCC,
    UNSUCCESS_LOGIN,
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCC,
    RESET_PASSWORD_ISLOADING,
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_CLOSE_MODAL,
    PASSWORD_RECOVERY_FAIL,
    PASSWORD_RECOVERY_SUCC,
    PASSWORD_RECOVERY_ISLOADING,
    PASSWORD_RECOVERY_INIT,
    PASSWORD_RECOVERY_CLOSE_MODAL,
    FETCH_PROFILE,
    FETCH_PROFILE_FAIL,
    FETCH_PROFILE_SUCC,
    FETCH_PROFILE_SET,
    FETCH_AGENCIES,
    FETCH_AGENCIES_FAIL,
    FETCH_AGENCIES_SUCC,
    FETCH_AGENCY,
    FETCH_AGENCY_FAIL,
    FETCH_AGENCY_SUCC,
    FETCH_REFERRALS,
    FETCH_REFERRALS_FAIL,
    FETCH_RECEIVED_REFERRALS_SUCC,
    FETCH_RECEIVED_REFERRALS_ADD,
    FETCH_RECEIVED_REFERRALS_UPDATE,
    FETCH_SENT_REFERRALS_SUCC,
    FETCH_REFERRALS_SENT_ISLOADING,
    FETCH_REFERRALS_RECEIVED_ISLOADING,
    FETCH_REFERRALSUSERS_SUCC,
    FETCH_SENT_REFERRALS_ADD,
    FETCH_SENT_REFERRALS_UPDATE,
    FETCH_SENT_REFERRALS_PENDING_REMOVED,
    REFERRALS_INIT,
    VALIDATE_PHONE_MODAL,
    FETCH_USER_LIST,
    FETCH_USER_LIST_SUCC,
    FETCH_USER_LIST_FAIL,
    USER_STORE_SUCC,
    USER_STORE_FAIL,
    USER_UPDATE,
    USER_UNBLOCK_SUCCESS,
    VALIDATE_PHONE,
    VALIDATE_PHONE_SUCC,
    VALIDATE_PHONE_FAIL,
    VALIDATE_PHONE_CODE,
    VALIDATE_PHONE_CODE_SUCC,
    VALIDATE_PHONE_CODE_FAIL,
    VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE,
    VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
    VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT,
    VALIDATE_PHONE_ON_EDIT_FAIL,
    VALIDATE_PHONE_ON_EDIT_SUCC,
    VALIDATE_PHONE_ON_EDIT,
    VALIDATE_PHONE_MODALOPEN_ON_EDIT,
    FILLPROFILE_ISLOADING,
    FILLPROFILE_ADDAGENCY_SUCCESS,
    FILLPROFILE_TOOGLE_ADD_AGENCY_MODAL,
    FILLPROFILE_VALUESCHANGED,
    FILLPROFILE_CLEAN,
    FILLPROFILE_STEP,
    FILLPROFILE_SUCCESS,
    FILLPROFILE_SKIP_EMAIL_VERIFICATION,
    FILLPROFILE_PHONE_ALREADY_IN_USE,
    NAVBAR_FETCH_USER_PENDING_LIST_FAIL,
    NAVBAR_FETCH_USER_PENDING_LIST_SUCC,
    NAVBAR_FETCH_USER_PENDING_LIST,
    SEND_REFERRAL_SAVE_FORM_FAIL,
    SEND_REFERRAL_SAVE_FORM_SUCC,
    SEND_REFERRAL_SAVE_FORM,
    SEND_REFERRAL_IS_LOADING,
    SEND_REFERRAL_SUCC,
    SEND_REFERRAL_PREVENT_PROMPT,
    SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO,
    SEND_REFERRAL_CLEAR_CONGRATS_MODAL_INFO,
    SEND_REFERRAL_TOOGLEMESSAGEMODAL,
    SEND_REFERRAL_FAIL,
    AGENCY_ADDRESS_CHANGE,
    REHYDRATE_FIREBASE_AUTH,
    CLEAN_USER,
    CLEAN_SEND_REFERRAL,
    REHYDRATE_USER,
    REHYDRATE_USER_IS_LOADING,
    REHYDRATE_SEND_REFERRAL,
    CONNECT,
    CONNECT_SUCC,
    CONNECT_FAIL,
    ACCEPT,
    ACCEPT_SUCC,
    ACCEPT_FAIL,
    TOGGLE_SIDEBAR,
    TOOGLE_REFERRALNOTIFICATION,
    SUBSCRIBED_TO_FIREABSE,
    EDIT_PROFILE_SAVE,
    EDIT_PROFILE_SAVE_SUCC,
    EDIT_PROFILE_SAVE_FAIL,
    PROFILE_EDITING,
    SEND_REFERRAL_USER_LIST_FETCH_USERS,
    SEND_REFERRAL_USER_LIST_FETCH_USERS_SUCC,
    SEND_REFERRAL_USER_LIST_FETCH_USERS_FAIL,
    SEND_REFERRAL_TOOGLE_CLEAR_FORM,
    USER_CONNECT_INVITE_SUCC,
    USER_CONNECT_INVITED_SUCC,
    USER_CONNECT_PENDING_INVITES,
    USER_ACCEPT_INVITE_SUCC,
    USER_ACCEPT_INVITED_SUCC,
    USER_UNFRIEND_ISLOADING,
    USER_UNFRIEND_SUCC,
    USER_BLOCK_SUCCESS,
    SEND_REFERRAL_ADD_USER_TO_REFERRAL,
    SEND_REFERRAL_REMOVE_USER_FROM_REFERRAL,
    SHOW_TOAST,
    CLOSE_TOAST,
    DECLINE,
    DECLINE_SUCC,
    DECLINE_FAIL,
    USER_DECLINE_INVITE_SUCC,
    NAVBAR_TOUCH_NOTIFICATION,
    NAVBAR_MOREOPTIONS_OPEN,
    NAVBAR_TOOGLE_BACKBUTTON,
    IS_SOCIAL_LOGIN_FLAG_ON,
    IS_SOCIAL_LOGIN_FLAG_OFF,
    OPEN_POP_UP_MODAL,
    CLOSE_POP_UP_MODAL,
    USER_CONNECT_SENT_INVITES,
    USER_CONTACTS,
    RERZONEUSERS_ISLOADING,
    RERZONEUSERS_SUCCESS,
    RERZONEUSERS_FAIL,
    NEWPWAVERSION_AVAILABLE,
    SET_SERVICES,
    OPEN_REFERRAL,
    OPEN_REFERRAL_FAIL,
    OPEN_REFERRAL_SUCC,
    OPEN_REFERRALS_UNSUB,
    OPEN_REFERRALS_UNSUB_FAIL,
    OPEN_REFERRALS_UNSUB_SUCC,
    OPEN_REFERRALS,
    OPEN_REFERRALS_FAIL,
    OPEN_REFERRALS_SUCC,
    REFERRALS_DELETE_SENT,
    REFERRALS_ADD_SENT,
    ADD_RECEIVED_COUNTER,
    ADD_SENT_COUNTER,
    LOCATION_UPDATE,
    SEND_REFERRAL_CLEAN_FORM,
    SEND_REFERRAL_USERSCANDIDATES_MODALOPEN,
    NAVBAR_CURRENT_TAB,
    CREATE_DROPDOWN_CURRENT_TAB,
    NAVBAR_TOOGLE_AVATARDROPDOWN,
    NAVBAR_TOOGLE_MOBILESIDEMENU,
    GLOBAL_ADD_USER,
    NEAR_USERS_ISLOADING,
    NEAR_USERS_SUCCESS,
    NEAR_USERS_FAIL,
    NEAR_USERS_BROWSER_ISLOADING,
    NEAR_USERS_BROWSER_SUCCESS,
    NEAR_USERS_BROWSER_FAIL,
    USER_FETCH_SUBSCRIPTION_SUCCESS,
    USER_FETCH_CREDITS_SUCCESS,
    USER_FETCH_CREDITS_CHANGED_SUCCESS,
    USER_SETSETTING,
    USER_UNBLOCK,
    USER_FOCUS_SUCCESS,
    USER_SERVICEAREAS_SUCCESS,
    USER_HONORS_SUCCESS,
    USER_GOOD_OPS_FOR_ME_SUCCESS,
    USER_EXPERIENCE_SUCCESS,
    USER_LICENCES_SUCCESS,
    USER_SOCIAL_SUCCESS,
    USER_COMMUNITY_SUCCESS,
    SEND_REFERRAL_REMOVE_USER_TO_REFERRAL,
    SEND_REFERRAL_CURRENT_SECTION,
    SEND_REFERRAL_SET_VALUE,
    SEND_REFERRAL_ISVALIDFORM,
    SEND_REFERRAL_CANDIDATES_SET_TYPE,
    SEND_REFERRAL_SIGNATORY_SET_TYPE,
    SEND_REFERRAL_SIGNATORY_SET_USER,
    SEND_REFERRAL_FORM_SET_VALUE,
    SEND_REFERRAL_FORM_SET_ISVALIDFORM,
    SEND_REFERRAL_FORM_SET_COLLAPSE,
    USER_GET_CONNECTED,
    USER_GET_CONNECTED_ISLOADING,
    CLEAN_COMMUNITY,
    COMMUNITY_NEWESTPOSTSSUCCESS,
    COMMUNITY_NEW_NEWS_NOTIFICATION,
    COMMUNITY_ADD,
    COMMUNITY_OPEN_IMAGES_CAROUSEL,
    COMMUNITY_DISCUSSION_RANK,
    COMMUNITY_UPVOTE,
    COMMUNITY_DOWNVOTE,
    COMMUNITY_FETCHPOST_SUCCESS,
    COMMUNITY_REPLY_POST_SUCCESS,
    COMMUNITY_UPDATE,
    PROFILE_SENT_REFERRAL_COUNTER,
    PROFILE_RECEIVED_REFERRAL_COUNTER,
    PROFILE_COUNTERS_LOADING,
    PROFILE_COUNTERS_SUCC,
    PROFILE_CLEAR,
    PROFILE_WHOISWATCHING,
    PROFILE_CONTACTINFO_OPEN,
    PROFILE_FETCH_COMMON_CONTACTS,
    PROFILE_FETCH_COMMON_CONTACTS_SUCC,
    PROFILE_FETCH_COMMON_CONTACTS_FAIL,
    PROFILE_FETCH_OPEN_OPS,
    PROFILE_FETCH_OPEN_OPS_SUCC,
    PROFILE_FETCH_OPEN_OPS_FAIL,
    PROFILE_CLEAR_OPEN_OPS,
    PROFILE_FAVORITE_USER,
    PROFILE_GET_USER_FAVORITES_LOADING,
    PROFILE_GET_USER_FAVORITES_SUCCESS,
    PROFILE_GET_USER_FAVORITES_FAIL,
    PROFILE_GET_USER_ENDORSEMENTS_LOADING,
    PROFILE_GET_USER_ENDORSEMENTS_SUCCESS,
    PROFILE_GET_USER_ENDORSEMENTS_FAIL,
    PROFILE_ADD_FIRST_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_SUCCESS,
    PROFILE_ADD_ENDORSEMENT_FAIL,
    PROFILE_UPDATE_ENDORSEMENT_SUCCESS,
    PROFILE_UPDATE_ENDORSEMENT_FAIL,
    PROFILE_HIDE_ENDORSEMENT_SUCCESS,
    PROFILE_DELETE_ENDORSEMENT_SUCCESS,
    PROFILE_DELETE_ENDORSEMENT_FAIL,
    PROFILE_GET_USER_GRATITUDES_LOADING,
    PROFILE_GET_USER_GRATITUDES_SUCCESS,
    PROFILE_GET_USER_GRATITUDES_FAIL,
    PROFILE_ADD_FIRST_GRATITUDE_SUCCESS,
    PROFILE_ADD_GRATITUDE_SUCCESS,
    PROFILE_ADD_GRATITUDE_FAIL,
    PROFILE_UPDATE_GRATITUDE_SUCCESS,
    PROFILE_UPDATE_GRATITUDE_FAIL,
    PROFILE_HIDE_GRATITUDE_SUCCESS,
    PROFILE_DELETE_GRATITUDE_SUCCESS,
    PROFILE_DELETE_GRATITUDE_FAIL,
    PROFILE_GET_USER_OP_REVIEWS_LOADING,
    PROFILE_GET_USER_OP_REVIEWS_SUCCESS,
    PROFILE_GET_USER_OP_REVIEWS_FAIL,
    PROFILE_ADD_OP_REVIEW_SUCCESS,
    PROFILE_ADD_OP_REVIEW_FAIL,
    PROFILE_UPDATE_OP_REVIEW_SUCCESS,
    PROFILE_UPDATE_OP_REVIEW_FAIL,
    PROFILE_DELETE_OP_REVIEW_SUCCESS,
    PROFILE_DELETE_OP_REVIEW_FAIL,
    PROFILE_OPEN_ENDORSEMENT_DETAILS,
    PROFILE_OPEN_GRATITUDE_DETAILS,
    PROFILE_OPEN_OP_REVIEW_DETAILS,
    TOGGLE_SEARCHBAR,
    SEARCHBAR_LOADING,
    SEARCHBAR_SUCCESS,
    SEARCHBAR_FAIL,
    SEARCHBAR_CLEAR,
    SEARCHBAR_CLEAR_RESULTS,
    SEARCHBAR_VALUE,
    SEARCHBAR_LOCATION_SUCCESS,
    SEARCHBAR_LOCATION_FAIL,
    SEARCHBAR_LOCATION_LOADING,
    SEARCHBAR_BLUR_VALUE,
    SEARCHBAR_PAGE,
    SEARCHBAR_SET_LOCATION_VALUE,
    SEARCHBAR_SET_LOCATIONORPROFILE,
    SEARCHBAR_FILTER_OPEN,
    SEARCHBAR_SHOW_SUGGESTIONS,
    SEARCHBAR_SET_FILTER,
    MEGASEARCH_SUCCESS,
    MEGASEARCH_FAIL,
    MEGASEARCH_RESET_FILTERS,
    MEGASEARCH_UPDATE_FILTERS,
    NOTIFICATIONS_BELL_FETCH_SUCCESS,
    LAYOUT_BACKGROUND_COLOR,
    CHATBOT_OPEN,
    SET_VERIFIED_PHONE,
    SET_REFERRAL_TYPE,
    USER_IPINFO_SUCCESS,
    REFERRALS_DELETE_INCOMING,
    REFERRALS_DELETE_PENDING_SENT,
    REFERRALS_ONVALUE,
    REFERRALS_SET_SELECTED_TAB,
    REFERRALS_SET_FILTER_OPEN,
    REFERRALS_SET_FILTER_SEARCH,
    REFERRALS_SET_FILTER_SORT,
    REFERRALS_SET_FILTER_STATUS_ADD,
    REFERRALS_SET_FILTER_STATUS_REMOVE,
    REFERRALS_IS_LOADING,
    REFERRALS_FETCH_SUCCESS,
    REFERRALS_FOOTER_OPEN,
    REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL,
    CONTACTS_USER_LIST_FETCH_USERS,
    CONTACTS_USER_LIST_FETCH_USERS_SUCC,
    CONTACTS_USER_LIST_FETCH_USERS_FAIL,
    CONTACTS_SET_CONTACT_UIDS_LIST,
    CONTACTS_CREATE_CONTACT,
    CONTACTS_CREATE_CONTACT_SUCCCESS,
    CONTACTS_CREATE_CONTACT_FAIL,
    SETTINGS_SELECTSECTION,
    SETTINGS_BLOCKEDCONTACTS_SUCCESS,
    SETTINGS_ISLOADING,
    SETTINGS_SETPHONE,
    SETTINGS_UNBLOCKCONTACT_SUCCESS,
    SETTINGS_SET_SUBSCRIPTIONSTEP,
    SETTINGS_SET_SUBSCRIPTIONOPTION,
    SETTINGS_CHANGE_PASSWORD_SUCCESS,
    GLOBAL_STATICS_FETCH_SUCCESS,
    SETTINGS_BILLINGDETAILS_SUCCESS,
    SETTINGS_INVITEEMAILS_SUCCESS,
    SETTINGS_UNBLOCK_CONTACTS_LOADING,
    USER_FETCHINVITES_SUCCESS,
    USER_INVITES_DISMISS_SUCCESS,
    USER_INVITES_ACCOUNTBALANCE_SUCCESS,
    USER_INVITES_EARNING_HISTORY_SUCCESS,
    USER_INVITES_INVITESCOUNT_SUCCESS,
    USER_EXTRAINFO_SUCCESS,
    USER_SET_ONBOARDING,
    VERYFYBYPHONE_ISLOADING,
    VERYFYBYPHONE_VALIDATE_SUCCESS,
    VERYFYBYPHONE_SENDVERIFICATIONCODE_SUCCESS,
    VERYFYBYPHONE_VALIDATE_FAILS,
    GLOBAL_MAITENANCE_MODE,
    GLOBAL_ALLOW_ACCESS_TO_DEV,
    GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING,
    NOTIFICATIONS_BELL_ISLOADING,
    NOTIFICATIONS_BELL_REMOVE,
    NOTIFICATIONS_BELL_TOOGLE_NEW,
    NOTIFICATIONS_BELL_ADD,
    NOTIFICATIONS_BELL_READ,
    CONNECTION_REQUESTS_FETCH_SUCCESS,
    CONNECTION_REQUESTS_ACCEPT_DECLINE,
    NOTIFICATIONS_BELL_ALL_READ,
    MAINTENANCE_ALERT,
    CHAT_ISLOADING,
    CHAT_CLEAN,
    CHAT_SETCONVERSATIONS,
    CHAT_CONVERSATIONS_LOADING,
    CHAT_SELECT_CONVERSATION,
    CHAT_ADD_CONVERSATION_MESSAGE,
    CHAT_SET_CONVERSATION_MESSAGES,
    CHAT_RESET_CONVERSATIONS_MESSAGES,
    CHAT_CONVERSATION_SET_USER_STATUS,
    CHAT_UPDATECONVERSATION,
    CHAT_OPEN_PROFILE_DETAILS,
    CHAT_FETCH_CONTACT_SUCCESS,
    CHAT_FETCH_CONTACT_FAIL,
    CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS,
    CANCEL_CONNECTION,
    CANCEL_CONNECTION_SUCC,
    CANCEL_CONNECTION_FAIL,
    FETCH_BIGBOARD_FAIL,
    FETCH_BIGBOARD_SUCCESS,
    FETCH_BIGBOARD_ISLOADING,
    BIGBOARD_UPDATE_FILTERS,
    BIGBOARD_SET_PAGE,
    BIGBOARD_RESET_FILTERS,
    BIGBOARD_SET_REFERRAL_POPUP,
    BIGBOARD_REMOVE_REFERRAL,
    OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
    OPPORTUNITY_FLOW_RESET,
    PEOPLE_SEARCHBAR_LOADING,
    PEOPLE_SEARCHBAR_SUCCESS,
    PEOPLE_SEARCHBAR_FAIL,
    PEOPLE_SEARCHBAR_CLEAR,
    SHARE_OPEN,
    SHARE_CLOSE,
    RECOMMEND_MODAL_CLOSE,
    RECOMMEND_MODAL_OPEN,
    RECOMMEND_SEND_LOADING,
    RECOMMEND_SEND_SUCCESS,
    RECOMMEND_SEND_FAIL,
    UPVOTE_CANDIDATE,
    UPVOTE_RECOMMENDATION,
    NEW_CONTACT_SAVED,
    FETCH_MYOPS_ANALYTICS_LOADING,
    FETCH_MYOPS_ANALYTICS_SUCCESS,
    FETCH_MYOPS_ANALYTICS_FAIL,
    FETCH_OPS_ACTIVITY_COUNT_LOADING,
    FETCH_OPS_ACTIVITY_COUNT_SUCCESS,
    FETCH_OPS_ACTIVITY_COUNT_FAIL,
    OPS_ACTIVITY_COUNT_UPDATE_FILTERS,
    FETCH_OPS_ACTIVITY_ANALYTICS_LOADING,
    FETCH_OPS_ACTIVITY_ANALYTICS_SUCCESS,
    FETCH_OPS_ACTIVITY_ANALYTICS_FAIL,
    OPS_ACTIVITY_ANALYTICS_UPDATE_FILTERS,
    FETCH_MEMBER_COMPARISON_LOADING,
    FETCH_MEMBER_COMPARISON_SUCCESS,
    FETCH_MEMBER_COMPARISON_FAIL,
    MEMBER_COMPARISON_UPDATE_FILTERS,
    MEMBER_COMPARISON_RESET_FILTERS,
    CHECK_USERNAME_IS_LOADING,
    FETCH_LEADERBOARD_ISLOADING,
    FETCH_LEADERBOARD_SUCCESS,
    FETCH_LEADERBOARD_FAIL,
    LEADERBOARD_UPDATE_FILTERS,
    LEADERBOARD_RESET_FILTERS,
    LEADERBOARD_SET_PAGE,
    FETCH_USER_LEADERBOARD_STATS_ISLOADING,
    FETCH_USER_LEADERBOARD_STATS_SUCCESS,
    FETCH_USER_LEADERBOARD_STATS_FAIL,
    WRONG_PASSWORD,
    EMAIL_ERROR
};
