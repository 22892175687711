import {
    SETTINGS_SELECTSECTION,
    SETTINGS_BLOCKEDCONTACTS_SUCCESS,
    SETTINGS_ISLOADING,
    SETTINGS_SETPHONE,
    VALIDATE_PHONE_CODE_ON_EDIT,
    VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
    VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
    SETTINGS_UNBLOCKCONTACT_SUCCESS,
    SETTINGS_SET_SUBSCRIPTIONSTEP,
    SETTINGS_SET_SUBSCRIPTIONOPTION,
    SETTINGS_CHANGE_PASSWORD_SUCCESS,
    SETTINGS_BILLINGDETAILS_SUCCESS,
    SETTINGS_INVITEEMAILS_SUCCESS,
    SETTINGS_UNBLOCK_CONTACTS_LOADING
} from "../../constants";
import { handleError } from "../modal";
//import { push } from "connected-react-router";
import Firebase from "../../../../libs/Firebase";
import { addGlobaluser, setUserSetting } from "../user";
import { batch } from "react-redux";
import { setVerifiedPhone } from "../user";
import { BILLINGDETAILS_STATUS } from "../../../../assets/strings";
export const settingsIsLoading = isLoading => ({
    type: SETTINGS_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

export const billingDetailsSuccess = success => ({
    type: SETTINGS_BILLINGDETAILS_SUCCESS,
    payload: {
        success: success
    }
});

export const settingsChangePassswordSuccess = success => ({
    type: SETTINGS_CHANGE_PASSWORD_SUCCESS,
    payload: {
        changePasswordSuccess: success
    }
});

export const settingsInviteEmailsSuccess = success => ({
    type: SETTINGS_INVITEEMAILS_SUCCESS,
    payload: {
        success: success
    }
});

export const settingsSelectSection = section => ({
    type: SETTINGS_SELECTSECTION,
    payload: {
        selectedSection: section
    }
});

export const blockedContacts = contacts => ({
    type: SETTINGS_BLOCKEDCONTACTS_SUCCESS,
    payload: {
        blockedContacts: contacts
    }
});

export const setPhoneNumberToVerify = phone => ({
    type: SETTINGS_SETPHONE,
    payload: {
        phone: phone
    }
});

export const settingsUnblockContact = uid => ({
    type: SETTINGS_UNBLOCKCONTACT_SUCCESS,
    payload: {
        uid: uid
    }
});

export const settingsSetSubscriptionStep = subscriptionStep => ({
    type: SETTINGS_SET_SUBSCRIPTIONSTEP,
    payload: {
        subscriptionStep: subscriptionStep
    }
});

export const settingsSetSubscriptionOption = selectedSubscriptionOption => ({
    type: SETTINGS_SET_SUBSCRIPTIONOPTION,
    payload: {
        selectedSubscriptionOption: selectedSubscriptionOption
    }
});

export const unblockUserIsLoading = (uid) => ({
    type: SETTINGS_UNBLOCK_CONTACTS_LOADING,
    payload: {
        uid
    }
})

export const sendVerificationEmail = (email, uid, name, lastname) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(settingsIsLoading(true));
            // const emailPayload = {
            //     destemail: email,
            //     destname: name,
            //     destlastname: lastname,
            //     destfullname: `${name} ${lastname}`,
            //     userid: uid,
            //     emailtype: "v"
            // };
            await cloudfunctions.sendVerificationEmail();
            dispatch(settingsIsLoading(false));
        } catch (errors) {
            console.error("sendVerificationEmail", errors);
            dispatch(handleError(errors));
            dispatch(settingsIsLoading(false));
        }
    };
};

export const fetchBlockedContactList = uidsList => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        /*  dispatch({
                type: CONTACTS_USER_LIST_FETCH_USERS
            }); */
        try {
            // console.log(uidsList)
            let globalUsers = getState().main.ui.global.users.data;
            const uidsArray = Array.isArray(uidsList)
                ? uidsList
                : Firebase.flatArrayFromFirebaseList(uidsList);

            let toFetchUids = uidsArray.filter(
                uid => !globalUsers.find(user => user.uid === uid)
            );

            let toNotFetchUids = uidsArray.filter(
                x => !toFetchUids.includes(x)
            );

            let data = [];
            toNotFetchUids.forEach(uid => {
                data.push(globalUsers.find(user => user.uid === uid));
            });

            let fetchedUsers = [];
            if (toFetchUids.length > 0)
                fetchedUsers = await firebase.fetchUserList(toFetchUids);
            batch(() => {
                fetchedUsers.forEach(user => {
                    dispatch(addGlobaluser(user.uid, user));
                });
            });
            data = data.concat(fetchedUsers);

            dispatch(blockedContacts(data));
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
            /* return dispatch({
                            type: CONTACTS_USER_LIST_FETCH_USERS_FAIL,
                            errors: cloudfunctions.parseRequestError(error)
                        }); */
        }
    };
};

export const clearCache = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch(settingsIsLoading(true));
        try {
            const comeFromGoogle = JSON.parse(
                localStorage.getItem("comeFromGoogle")
            );
            const isLoggedIn = JSON.parse(
                localStorage.getItem("isLoggedIn(1.0)")
            );
            localStorage.clear();
            localStorage.setItem(
                "comeFromGoogle",
                JSON.stringify(comeFromGoogle)
            );
            localStorage.setItem("isLoggedIn(1.0)", JSON.stringify(isLoggedIn));
            dispatch(settingsIsLoading(false));
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
            /* return dispatch({
                                        type: CONTACTS_USER_LIST_FETCH_USERS_FAIL,
                                        errors: cloudfunctions.parseRequestError(error)
                                    }); */
        }
    };
};

export const setSettings = (setting, section) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            if (setting && section) {
                dispatch(setUserSetting(setting, section));
                const notificationsSubsection = Object.keys(setting)[0]
                const payload = {
                    section: notificationsSubsection,
                    settings: setting[notificationsSubsection]
                }
                // console.log("payload", payload);
                cloudfunctions.updateUser('SETTINGS_NOTIFICATIONS', payload)
            }
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
        }
    };
};



export const verifyPhoneNumberCode = code => {
    return (dispatch, getState, { cloudfunctions, firebase }) => {
        return new Promise(async (resolve, reject) => {
            dispatch({
                type: VALIDATE_PHONE_CODE_ON_EDIT
            });
            try {
                const state = await getState();
                const {
                    phone
                } = state.main.ui.view.editProfile.phoneValidation;

                await cloudfunctions.validateVerificationCode(
                    code,
                    phone
                );
                const uid = state.main.user.uid;
                firebase.userPhoneValid(uid, true);
                dispatch(setVerifiedPhone(true));
                dispatch({
                    type: VALIDATE_PHONE_CODE_ON_EDIT_SUCC,
                    code: code
                });
                resolve();
            } catch (errors) {
                try {
                    dispatch(handleError(errors));
                    const uid = getState().main.user.uid;
                    firebase.userPhoneValid(uid, false);
                    dispatch(setVerifiedPhone(false));
                    dispatch({
                        type: VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
                        errors: cloudfunctions.parseRequestError(errors)
                    });
                    return reject();
                } catch (err) {
                    dispatch(handleError(err));
                    dispatch(setVerifiedPhone(false));
                    dispatch({
                        type: VALIDATE_PHONE_CODE_ON_EDIT_FAIL,
                        errors: cloudfunctions.parseRequestError(err)
                    });
                    return reject();
                }
            }
        });
    };
};

export const passwordUpdate = (email, actualPassword, newPassword) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(settingsIsLoading(true));

            await firebase.reauthenticateAndRetrieveDataWithCredential(
                email,
                actualPassword,
                newPassword
            );
            await firebase.passwordUpdate(newPassword);
            await cloudfunctions.passwordUpdate();
            dispatch(settingsChangePassswordSuccess(true));
            dispatch(settingsIsLoading(false));
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            dispatch(settingsChangePassswordSuccess(false));
            dispatch(settingsIsLoading(false));
        }
    };
};

export const sendInviteEmails = usersToInvite => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(settingsIsLoading(true));
            if (usersToInvite && usersToInvite.length > 0) {
                const response = await cloudfunctions.sendInviteEmails({ to: usersToInvite });
                dispatch(settingsInviteEmailsSuccess(true));
                dispatch(settingsIsLoading(false));

                return response && response.data;
            } else {
                dispatch(settingsIsLoading(false));
                throw new Error("Incomplete Payload for sending invitations");
            }
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            dispatch(settingsIsLoading(false));
            dispatch(settingsInviteEmailsSuccess(false));
            throw new Error(error);
        }
    };
};

export const submitBillingDetails = (
    name,
    lastname,
    dob,
    phone,
    address,
    zipcode,
    city,
    state,
    ssn4,
    cardnumber,
    expDate,
    cvc
) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(settingsIsLoading(true));
            if (
                name &&
                lastname &&
                expDate &&
                phone &&
                address &&
                zipcode &&
                city &&
                state &&
                ssn4 &&
                cardnumber &&
                expDate &&
                cvc
            ) {
                // const { payout_method } = getState().main.user.profile;
                const expmonth = expDate.substring(0, 2);
                const expyear = expDate.substring(2, 4);
                const clientip =
                    getState().main.user.ipInfo &&
                    getState().main.user.ipInfo.ip;
                let finalPayload;

                finalPayload = {
                    name,
                    lastname,
                    dob,
                    phone,
                    address,
                    zipcode,
                    city,
                    state,
                    ssn4,
                    cardnumber,
                    expmonth,
                    expyear,
                    cvc,
                    clientip
                };

                await cloudfunctions.setBillingDetails(
                    finalPayload
                );
                dispatch(settingsIsLoading(false));
                dispatch(billingDetailsSuccess(true));
            } else {
                throw new Error("Incomplete Payload");
            }
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
            dispatch(settingsIsLoading(false));
            dispatch(billingDetailsSuccess(false));
        }
    };
};

export const updateBillingDetails = (cardnumber, expDate, cvc) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(settingsIsLoading(true));
            if (cardnumber && expDate && cvc) {
                const {
                    payout_method,
                    payout_acct,
                    name,
                    lastname,
                    email
                } = getState().main.user.profile;
                const meId = getState().main.user.uid;
                if (payout_method && payout_acct && meId) {
                    const expmonth = expDate.substring(0, 2);
                    const expyear = expDate.substring(2, 4);
                    const oldcardid = payout_method && payout_method.card_id;

                    let finalPayload;

                    finalPayload = {
                        name,
                        lastname,
                        oldcardid,
                        cardnumber,
                        expmonth,
                        expyear,
                        cvc,
                        destemail: email,
                        userid: meId
                    };

                    await cloudfunctions.updateBillingDetails(
                        payout_acct,
                        finalPayload
                    );
                    dispatch(settingsIsLoading(false));
                    dispatch(billingDetailsSuccess(true));
                } else {
                    throw new Error("You do not have a card to update");
                }
            } else {
                throw new Error("Incomplete Payload");
            }
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
            dispatch(settingsIsLoading(false));
            dispatch(billingDetailsSuccess(false));
        }
    };
};

export const checkStripeCardStatus = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch(settingsIsLoading(true));
            const { payout_acct, payout_method } = getState().main.user.profile;
            const { uid } = getState().main.user;
            const cardStatus = payout_method && payout_method.status;
            if (
                uid &&
                payout_acct &&
                payout_method &&
                cardStatus !== BILLINGDETAILS_STATUS.APPROVED
            ) {
                let response = await cloudfunctions.getBillingDetailsStatus(
                    payout_acct
                );
                response = response.data;
                const status = response
                    ? response.status === "ok"
                        ? BILLINGDETAILS_STATUS.APPROVED
                        : response.status === "ko"
                        ? BILLINGDETAILS_STATUS.REJECTED
                        : BILLINGDETAILS_STATUS.PENDING
                    : BILLINGDETAILS_STATUS.PENDING;
                firebase.setStripePayoutMethodStatus(uid, status);
                dispatch(settingsIsLoading(false));
            } else {
                dispatch(settingsIsLoading(false));
                // console.error(
                //     "payout method not configured or is already approved"
                // );
                //throw new Error("You haven't a card to update");
            }
        } catch (error) {
            console.error("error", error);
            dispatch(handleError(error));
            dispatch(settingsIsLoading(false));
        }
    };
};

export const unblockUser = uid => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch(settingsIsLoading(true))
        dispatch(unblockUserIsLoading(uid));
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            await cloudfunctions.unblockUser(uid);
            dispatch(settingsUnblockContact(uid));
            dispatch(settingsIsLoading(false))
        } catch (errors) {
            console.error(errors);
            dispatch(handleError(errors));
            dispatch(settingsIsLoading(false))
        }
    };
};