import React from "react";
import { Flex } from "rebass";
import { withTheme } from "styled-components";
import { useHistory } from "react-router";
import { withTranslation } from "react-i18next";
import { Icon } from "../../Icon";
import SquareButton from "../../Buttons/SquareButton2";
import {
    EngagementModalTitle as Title,
    EngagementModalDescription as Description
} from "../../Text";

const EndorsementFailMessage = ({
    t,
    theme,
    buttonLabel,
    fullName,
    firstName,
    userName,
    onClick,
}) => {
    const { push } = useHistory();
    const goToProfile = () => push(`/profile/${userName}`);
    const handleWriteAGratitude = () => push(`/profile/${userName}?send=gratitude`);

    return (
        <Flex
            id="endorsement-fail"
            flexDirection="column"
            alignItems="center"
            style={{ position: "relative" }}
            p={[
                theme.spacing[5],
                theme.spacing[5],
                theme.spacing[10],
                theme.spacing[10]
            ]}
        >
            <Icon name="times" color={theme.red} size="3xl" />
            <Title pointer>
                {t("fail_title")}
                {fullName && (
                    <span className="highlight" onClick={goToProfile}>
                        {fullName}
                    </span>
                )}
            </Title>
            <Description pointer>
                {t("fail_description_1")}
                <span
                    className="highlight"
                    onClick={handleWriteAGratitude}
                    dangerouslySetInnerHTML={{
                        __html: t("fail_link_gratitude")
                    }}
                />
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("fail_description_2")
                    }}
                />
                {firstName && (
                    <span className="highlight" onClick={goToProfile}>
                        {firstName}
                    </span>
                )}
                <span
                    dangerouslySetInnerHTML={{
                        __html: t("fail_description_3")
                    }}
                />
            </Description>
            <Flex width={[1]} mt={[theme.spacing[8]]} justifyContent={"center"}>
                <SquareButton
                    onClick={onClick}
                    bg={theme.shamRockGreen}
                    fontSize="XS"
                    style={{ zIndex: 1 }}
                    text={buttonLabel || t("fail_button")}
                />
            </Flex>
        </Flex>
    );
};

export default withTranslation("endorsementModal")(
    withTheme(EndorsementFailMessage)
);
