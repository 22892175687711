import React from "react";
import {
    CardContainer,
    MobileCardContainer
} from "./card-styles";

const Card = (props) => {
    const { children, bg, padding, ...others } = props
    return (
        <CardContainer bg={bg} padding={padding} {...others} >
            {children}
        </CardContainer>
    );

}

export const MobileCard = ({children, bg, padding, ...others }) =>  (
    <MobileCardContainer bg={bg} padding={padding} {...others}  >
    { children }
   </MobileCardContainer>
)

export default Card;
