export default class Elastic {
    static elasticUser = (uid, address, companyName, email, lastname, name, phone, state, type, avatar, userName) => {
        return {
            uid,
            locationAddress: address,
            //company_id: companyId,
            company_name: companyName,
            email,
            last_name: lastname,
            first_name: name,
            phone,
            state,
            user_type: Number(type),
            avatar: avatar ? avatar : '',
            userName: userName
        }

    }
}