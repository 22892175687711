import React, { useState } from "react";
import { Flex, Box } from "rebass";
import { withRouter } from "react-router-dom";
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import { withTheme } from "styled-components";
import {
    Description2,
    SignOutEmail
} from "../../../../ui/presentation/atoms/shared/Text";

const SignOut = ({ theme, signOut, email }) => {
    const [hovered, setHovered] = useState(false);
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    const iconWidth = "14px";
    const iconHeight = "14px";
    return (
        <Flex
            alignItems="center"
            onClick={signOut}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Icon
                icon="logout"
                color={hovered ? theme.newBlue : theme.darkGray}
                width={iconWidth}
                height={iconHeight}
            />
            <Box ml={["1em"]}>
                <Description2>Log Out</Description2>
                {/* <SignOutEmail>{email}</SignOutEmail> */}
            </Box>
        </Flex>
    );
} 
export default withRouter(withTheme(SignOut));
