import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/root/App";
import { FirebaseExceptionHandler } from "./components/root/Firebase";
import { configureStore } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
// import * as Sentry from "@sentry/browser";
import { newPWAVersionAvailable, updatePWA } from "./redux/actions/creators/shared";
import GlobalBusiness from "./business/global";
import icons from "./assets/icons";
import "./i18n";
import "./assets/fonts/montserrat/Montserrat-Bold.otf";
import "./assets/fonts/Inter-UI-Regular.woff2";
import "./assets/fonts/Inter-UI-Regular.woff";
import "./assets/fonts/Inter-UI-Medium.woff2";
import "./assets/fonts/Inter-UI-Medium.woff";
import "./assets/fonts/Inter-UI-Italic.woff2";
import "./assets/fonts/Inter-UI-Italic.woff";
import "./assets/fonts/Inter-UI-MediumItalic.woff2";
import "./assets/fonts/Inter-UI-MediumItalic.woff";
import "./assets/fonts/Inter-UI-SemiBold.woff2";
import "./assets/fonts/Inter-UI-SemiBold.woff";
import "./assets/fonts/Inter-UI-SemiBoldItalic.woff2";
import "./assets/fonts/Inter-UI-SemiBoldItalic.woff";
import "./assets/fonts/Inter-UI-Bold.woff2";
import "./assets/fonts/Inter-UI-Bold.woff";
import "./assets/fonts/Inter-UI-BoldItalic.woff2";
import "./assets/fonts/Inter-UI-BoldItalic.woff";
import "./assets/fonts/Roboto-Medium.ttf";

import ReactPixel from 'react-facebook-pixel';
//import Utils from "./libs/Utils";
import packageJson from "../package.json";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";


const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
ReactPixel.init('149809572506127', '', options);

React.icons = icons;


console.log('hostname', window.location.hostname)
//if (window.location.hostname === 'beta.reallyhq.com') window.location.replace('https://app.reallyhq.com/');

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_URL,
//     release: `really-${process.env.NODE_ENV}-${process.env.REACT_APP_ENV}@${process.env.REACT_APP_VERSION}`
// });

const isProd =
    process.env.REACT_APP_ENV !== "development" &&
    !window.location.host.includes("127.0.0.1") &&
    !window.location.host.includes("localhost");
if (isProd) {
    // Posthog init
    posthog.init("phc_F7JqCEBaNGe97IqB8Uss1OCaDCu5NcmCM2Arb7Fb56G", {
        api_host: "https://app.posthog.com",
    });

}


const documentRoot = document.getElementById("root");


const store = configureStore();

const onUpdate = config => {
    //localStorage.clear();
    let payload = {
        newPWAVersionAvailable: true,
        forceRefresh: false
    };

    fetch("/meta.json", { cache: "no-cache" })
        .then(response => response.json())
        .then(meta => {
            const latestVersion = meta.version ? meta.version : "";
            const currentVersion = packageJson.version
                ? packageJson.version
                : "";

            if (latestVersion.toString() !== currentVersion.toString()) {
                if (meta && meta.forceRefresh) payload.forceRefresh = true;
                store.dispatch(newPWAVersionAvailable(payload));
            }
        })
        .catch(error => {
            console.log(error);
            store.dispatch(newPWAVersionAvailable(payload));
        });
};

let sBrowser = GlobalBusiness.getCurrentBrowser();

try {
    ReactDOM.render(
        <Provider store={store}>
            <PostHogProvider client={posthog}>
                <App />
            </PostHogProvider>
        </Provider>,
        documentRoot
    );
} catch (error) {
    ReactDOM.render(<FirebaseExceptionHandler error={error} />, documentRoot);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (sBrowser !== "Safari") serviceWorker.register(onUpdate);
else serviceWorker.unregister();

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/firebase-messaging-sw.js")
            .then(function(registration) {
                console.log(
                    "Registration successful, scope is:",
                    registration.scope
                );
            })
            .catch(function(err) {
                console.log("Service worker registration failed, error:", err);
            });
    });
}
