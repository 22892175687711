import React from 'react'
import { Flex } from "rebass"
import Select from "react-select";
import {
    InputContainer,
} from "../../../../Settings/common/Components";
import {  selectStyle } from "../../../components";
import { targetToNotifyOptions } from "../../../constants"
import styled from 'styled-components';
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";

const HelpText = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
    margin: 1em 0;
`

const TargetToNotify = ({ theme, formValues, onChange, errors, isReferral = true }) => (
    <>
        <HelpText>{ isReferral ? 'How far from the referral location should notifications be sent?' : 'How far from the recommendation request location should notifications be sent?'}</HelpText>
        {targetToNotifyOptions.map(({ value, label }, index) => (
            <Flex key={value} mb={theme.spacing[4]}>
                <CheckBox
                    round
                    inverted
                    label={label}
                    onChange={ev =>
                        onChange({
                            target: {
                                name: "targetToNotify",
                                value: { value, label },
                            }
                        })
                    }
                    checkBoxColor={theme.blueMedium}
                    checked={value === formValues.targetToNotify.value}
                />
            </Flex>
        ))}
    </>
    )

export default TargetToNotify;