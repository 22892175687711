import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../atoms/shared/Icon";
import TargetForm from "../ReferralCompleteInformation/TargetToNotify/view"

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Target = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
}) => {
    const key = "target_"
    const [isCollapsed, setIsCollapsed] = useState(false)
    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Profession Notification Radius
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <TargetForm
                    theme={theme}
                    formValues={values}
                    onChange={handleChange}
                    errors={errors}
                    isReferral={false}
                />
            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: t("targetInfo") }}/>
            </ReactTooltip>
        </Box>
    );
}

export default Target;