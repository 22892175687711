import React, { useEffect, useState } from "react";
import { toogleMobileSideMenu } from "../../../../../../redux/actions/creators/navbar";
import Sidebar from "react-sidebar";
import { Box, Flex } from "rebass";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { withTheme } from "styled-components";
import {
    Text,
    Title,
} from "../../../../../ui/presentation/atoms/shared/Text";
import Avatar from "../../../../../ui/presentation/atoms/shared/AvatarCircle2";
// import UnderNameLine from "../../../../../ui/presentation/atoms/shared/UnderNameLine";
import { StatsItemContainer, ViewProfileButton } from "./styles";
// import ProfileMenu from "../../ProfileMenu";
// import SignOutView from "../../SignOut";
import { signOut } from "../../../../../../redux/actions/creators/user";
import { updatePWA } from "../../../../../../redux/actions/creators/shared";
import { setChatBotOpen } from "../../../../../../redux/actions/creators/shared";
import MenuSideBarItem from "./MenuSideBarItem";
import HorizontalLine from "../../../../../ui/presentation/atoms/shared/HorizontalLine";
import packageJson from "../../../../../../../package.json";
import { useToggle } from "../../../../../../libs/useToggle";
import { SideHandLinksView } from "../../../../../ui/presentation/atoms/shared/RighthandLinks/SideMenuLinks";
import CloudSpongeWidget from "../../../../../ui/presentation/atoms/shared/CloudSpongeWidget";
import SquareButton from "../../../../../ui/presentation/atoms/shared/Buttons/SquareButton2";
import { Icon } from "../../../../../ui/presentation/atoms/shared/Icon";


const sidebarLinks = [
    // {
    //     label: "Analytics",
    //     icon: "analytics",
    //     url: "/analytics"
    // },
    /*  INVITE_AND_EARN_REMOVAL: As of 6/6/23 Invite & Earn program has been disbled on the platform*/
    // {
    //     label: "Invite & Earn",
    //     icon: "earn",
    //     url: "/settings?section=invites"
    // },
     {
        label: "Add Contacts",
        icon: "add-user",
        url: "add-user"
     },

    {
        label: "My Plan",
        icon: "plan",
        url: "/settings?section=subscription"
    },
    // {
    //     label: "Groups",
    //     icon: "group",
    //     url: "/groups"
    // },
]

const MenuSideBar = React.memo(
    ({
        open,
        user,
        theme,
        toogleMobileSideMenu,
        setChatBotOpen,
        signOut,
        history,
        newPWAVersionAvailable,
        updatePWA
    }) => {
        const { sentReferralsCount, profile } = user;
        const contacts = profile.contacts ? profile.contacts : {};
        const contactsCount = Object.keys(contacts).length;
        const [zIndex, setZIndex] = useState(open ? 1000 : -100);

        // This is to allow the animation to work
        useEffect(() => {
            if (open) {
                setZIndex(9999999);
            } else {
                setTimeout(() => {
                    setZIndex(-100);
                }, 300);
            }
        }, [open]);

        const onSidebarSetOpen = () => toogleMobileSideMenu(!open);
       
        const handleOnClick = (url) => {
            if (url !== "add-user")  history.push(url);
            toogleMobileSideMenu(false);
        }


        const [ openRighHandFooter,  setopenRighHandFooter ]  = useToggle(false)

        const handleLegalTermPopUp = () => {
            toogleMobileSideMenu(false)
            setopenRighHandFooter.toggle()
        }

        return (
            <Sidebar
                styles={{
                    root: {
                        zIndex
                    },
                    sidebar: {
                        //  zIndex: 9999999,
                        backgroundColor: "#FFF",
                        position: "fixed",
                        width: "80vw"
                    },
                    overlay: {
                        backgroundColor: "rgba(0,0,0,0.3)"
                    }
                }}
                onSetOpen={onSidebarSetOpen}
                sidebar={
                    <Flex
                        p="3vh"
                        flexDirection="column"
                        justifyContent="space-between"
                        style={{ height: "100%", maxHeight: "100%" }}
                    >
                        <Flex
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Avatar src={user.profile.avatar} size="130px" />
                            <Box mt="2vh">
                                <Title style={{ fontSize: "20px" }}>{`${user.profile.name} ${user.profile.lastname}`}</Title>
                            </Box>
                            <Box mt="2vh">
                                {/* <UnderNameLine /> */}
                                <ViewProfileButton onClick={() => handleOnClick(`/profile/${user.profile.userName}`)}>
                                    View Profile
                                </ViewProfileButton>
                            </Box>

                            {/* <Flex
                                mt={["4vh"]}
                                mb={["4vh"]}
                                width={[1]}
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="space-around"
                            >
                                <StatsItemContainer>
                                    <SubTitle2>0</SubTitle2>
                                    <SubTitle3>Articles</SubTitle3>
                                </StatsItemContainer> 
                                <StatsItemContainer>
                                    <SubTitle2>
                                        {contactsCount ? contactsCount : 0}
                                    </SubTitle2>
                                    <SubTitle3>Contacts</SubTitle3>
                                </StatsItemContainer>
                                <StatsItemContainer>
                                    <SubTitle2>
                                        {sentReferralsCount
                                            ? sentReferralsCount
                                            : 0}
                                    </SubTitle2>
                                    <SubTitle3>Referrals</SubTitle3>
                                </StatsItemContainer>
                            </Flex> */}
                        </Flex>
                        <Box>
                            {/* <ProfileMenu
                                toogleMobileSideMenu={toogleMobileSideMenu}
                                setChatBotOpen={setChatBotOpen}
                                user={user.profile}
                            /> */}
                        
                                
                            {sidebarLinks.map(({ icon, label, url }, index) => {
                                if ( url === 'add-user') {
                                    return (
                                      <CloudSpongeWidget>
                                         <div className="cloudsponge-launch">
                                         <MenuSideBarItem
                                                key={index}
                                                icon={icon}
                                                text={label}
                                                onClick={() => handleOnClick(url)}
                                         />
                                         </div>
                                      </CloudSpongeWidget>
                                    )
                                }

                                return  (
                                    <MenuSideBarItem
                                    key={index}
                                    icon={icon}
                                    text={label}
                                    onClick={() => handleOnClick(url)}
                                />
                                )
                            })}
                            <HorizontalLine
                                style={{ marginBottom: theme.spacing[4] }}
                            />
                            <MenuSideBarItem
                                icon={"settings"}
                                text="Settings"
                                size="small"
                                onClick={() => handleOnClick("/settings")}
                            />
                            <a
                                href="https://support.nuop.com/portal/en/home"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <MenuSideBarItem
                                    icon={"help"}
                                    text="Help"
                                    size="small"
                                />
                            </a>
                            <a
                                href="https://nuop.com/contact-us/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <MenuSideBarItem
                                    icon={"mail"}
                                    text="Contact Us"
                                    size="small"
                                />
                            </a>
                            <MenuSideBarItem
                                    icon={"briefcase"}
                                    text="Legal Terms"
                                    size="small"
                                    onClick={() => handleLegalTermPopUp()}
                                />
                        </Box>
                        <Box pb="1vh">
                            {newPWAVersionAvailable ? (
                                <SquareButton
                                    text="Update Available"
                                    bg={theme.blueMedium}
                                    color={theme.white}
                                    fontSize="XXS"
                                    onClick={updatePWA}
                                    small
                                    icon={() => <Icon style={{ marginRight: theme.spacing[2] }} color="white" icon="download-from-cloud" />}
                                    style={{
                                        marginBottom: theme.spacing[4]
                                    }}
                                />
                            ) : (
                                <Text
                                    color={theme.darkGray}
                                    fontSize="XXXS"
                                    style={{ display: "block", marginBottom: theme.spacing[4] }}
                                >
                                    Version {packageJson.version} -{" "}
                                    {new Date().getFullYear()}
                                </Text>
                            )}
                            <MenuSideBarItem
                                icon={"logout"}
                                text="Log Out"
                                onClick={signOut}
                            />
                        </Box>
                        {openRighHandFooter  &&    <SideHandLinksView setopenRighHandFooter={setopenRighHandFooter.toggle}   /> }
                    </Flex>
                }
                open={open}
            />
        );
    }
);

const mapStateToProps = state => {
    return {
        isLoading:
            state.main.ui.global.navbar.loading ||
            state.main.ui.view.profile.accept.loading,
        open: state.main.ui.global.navbar.mobileSideMenuOpen,
        user: state.main.user,
        newPWAVersionAvailable: state.main.ui.global.newPWAVersionAvailable
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toogleMobileSideMenu,
            signOut,
            setChatBotOpen,
            updatePWA
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTheme(MenuSideBar))
);
