import { connect } from 'react-redux';
import View from './view';
import { sendRecommendationAction, setStepData } from '../../../../../../../redux/actions/creators/recommendationCreation';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.recommendationCreationReducer.currentStep,
        professions: state.main.ui.global.statics.userTypes || [],
        recommendationRequestInformation: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION],
        recommendationDirectInformation: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION],
        referralIsLoading: state.main.ui.view.recommendationCreationReducer.referralIsLoading,
        successReferral: state.main.ui.view.recommendationCreationReducer.successReferral,
        errorReferral: state.main.ui.view.recommendationCreationReducer.errorReferral,
        candidates: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    sendRecommendationAction,
    setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_PREVIEW, data)),
}, dispatch)








export default connect(mapStateToProps, mapDispatchToProps)(View);