import React from 'react'

const Testing = () => <div><h1>TESTING STATING | CONFIRM_STATUS_CHANGE</h1></div>


export const COMPLETIONS_FLOW_STEPS = {
    CONFIRM_STATUS_CHANGE: "CONFIRM_STATUS_CHANGE",
    REFERRAL_PRIVACY_STEP: "REFERRAL_PRIVACY_STEP",
    REFERRAL_PROFESSION:   "REFERRAL_PROFESSION",
  
};


export const OPPORTUNITY_CREATION_STEPS_VIEWS = {
    [COMPLETIONS_FLOW_STEPS.CONFIRM_STATUS_CHANGE]: <Testing />,
    [COMPLETIONS_FLOW_STEPS.REFERRAL_PRIVACY_STEP]: <></>,
    [COMPLETIONS_FLOW_STEPS.REFERRAL_PROFESSION]:   <></>,
 
}; 