import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import { selectStyle } from "../../../../../components";
import Select from "react-select";
import {
    InputContainer,
    InputLabel,
    Input,
    TextArea
} from "../../../../../../Settings/common/Components";
import { InputCounter } from "../../../../../../Settings/InformationAccount/styles";

const FlatFee = ({ theme, formValues, onChange, feePaymentDueOptions, errors }) => {
     const [ flatError, setFlatError ] = useState(false);
     const [ paymentDue, setPaymentDue ] = useState(false);
    useEffect(() => {
        if ( formValues.flatFeeAmount === null && typeof errors.flatFeeAmount === 'string') setFlatError(true)
        else setFlatError(false)
    }, [ errors.flatFeeAmount, formValues.flatFeeAmount ])

  

    return (
        <>
        <Flex >
            <InputContainer
                style={{ maxWidth: "calc(50% - 1em)" }}
                showLabelOnValue
            >
                <Input
                    type="number"
                    name="flatFeeAmount"
                    placeholder={"Please input the flat fee amount that was collected*"}
                    required
                    value={formValues.flatFeeAmount}
                    onChange={onChange}
                    error={flatError}
                   // error={!!errors.flatFeeAmount}
                />
                <InputLabel>Flat Fee Amount*</InputLabel>
            </InputContainer>
            <InputContainer
                style={{ width: "calc(50% - 1em)" }}
                triggerShowLabel={formValues.paymentDue ? "show" : "hide"}
                showLabelOnValue
            >
                <Select
                    options={feePaymentDueOptions}
                    onChange={option =>
                        onChange({
                            target: {
                                name: "paymentDue",
                                value: option,
                            }
                        })
                    }
                    styles={selectStyle}
                    name="paymentDue"
                    placeholder="Please choose when payment was received.*"
                    error={!!errors.paymentDue}
                />
                <InputLabel>Payment Due*</InputLabel>
            </InputContainer>
        </Flex>
        <Flex mt={theme.spacing[3]}>
            <InputContainer showLabelOnValue>
                {!!formValues.comments && !!formValues.comments.length && (
                    <InputCounter>
                        {formValues.comments.length}/2000
                    </InputCounter>
                )}
                <TextArea
                    style={{ resize: "vertical" }}
                    type="text"
                    name="comments"
                    value={formValues.comments}
                    onChange={onChange}
                    maxLength="2000"
                    minLength="60"
                    placeholder="Comments"
                />
                <InputLabel>Comments</InputLabel>
            </InputContainer>
        </Flex>
    </>
    )
}

export default FlatFee;