import React from "react";
import { Flex } from "rebass";
import { selectStyle } from "../../../../components";
import Select from "react-select";
//import ReferralBusiness from "../../../../../../../../business/referral";
import {
    InputContainer,
} from "../../../../../Settings/common/Components";
import RealStateComission from "./RealStateComission";
import GrossRevenue from "./GrossRevenue";
import FlatFee from "./FlatFee";
import OtherFee from "./OtherFee";
import Agreement from "./Agreenment";

const ReferralFee = (props) => {
    const {
        theme,
        formValues,
        referralFeeTypes,
        onChange,
        errors,
    } = props;

    console.log('FEE ERRORS', errors)
    const renderByReferralFeeType = {
        [referralFeeTypes.optionsKeys.FLAT]: FlatFee,
        [referralFeeTypes.optionsKeys.GROSS_REVENUE]: GrossRevenue,
        [referralFeeTypes.optionsKeys.NO_FEE]: () => <></>,
        [referralFeeTypes.optionsKeys.OTHER]: OtherFee,
        [referralFeeTypes.optionsKeys.REAL_ESTATE]: RealStateComission
    };

    const ReferralFeeComponent = formValues.referralFee ? renderByReferralFeeType[formValues.referralFee.value] : null;

    const showAgreenment = false;

    const referralFeeTypesOptions = referralFeeTypes.options.filter(feeType => {
        const isRealEstateAgent = formValues.profession && formValues.profession.value === '100';
        const isRealEstateFee = feeType.key === "REAL_ESTATE";
        if (isRealEstateAgent && isRealEstateFee) {
            return true
        } else if (!isRealEstateAgent && isRealEstateFee) {
            return false
        }
        return true
    });

    const pastReferralFeeOptions = [
        {
            "key": "NO_FEE",
            "value": "NO_FEE",
            "label": "No fee was required",
            "importance": 0
        },
        {
            "key": "GROSS_REVENUE",
            "value": "GROSS_REVENUE",
            "label": "% of Gross Revenue",
            "importance": 2
        },
        {
            "key": "FLAT",
            "value": "FLAT",
            "label": "Flat Fee",
            "importance": 3
        },
        {
            "key": "OTHER",
            "value": "OTHER",
            "label": "Other",
            "importance": 4
        }
    ]



    return (
        <>
            <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                <InputContainer
                    triggerShowLabel={formValues.referralFee && formValues.referralFee.value ? "show" : "hide"}
                    showLabelOnValue
                >
                    <Select
                        options={pastReferralFeeOptions}
                        onChange={option => {
                            onChange({
                                target: {
                                    name: "referralFee",
                                    value: option,
                                }
                            })

                            onChange({
                                target: {
                                    name: "realEstateFee",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "comments",
                                    value: '',
                                }
                            })

                            onChange({
                                target: {
                                    name: "flatFeeAmount",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "paymentDue",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "grossRevenueFee",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "feeDuration",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "feeFrequency",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "feeCommencement",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "agreement",
                                    value: null,
                                }
                            })

                            onChange({
                                target: {
                                    name: "authorizedSignatureRequired",
                                    value: null,
                                }
                            })
                        }}
                        styles={selectStyle}
                        name="referralFee"
                        placeholder="Select a Fee Option*"
                        isClearable
                        value={formValues.referralFee}
                        error={errors.referralFee}
                    />
                </InputContainer>
            </Flex>
            {formValues.referralFee && (
                <ReferralFeeComponent
                    formValues={formValues}
                    onChange={onChange}
                    errors={errors}
                />
            )}
            {formValues.referralFee &&
                formValues.referralFee.value !== referralFeeTypes.optionsKeys.NO_FEE &&
                showAgreenment && (
                    <Agreement formValues={formValues} onChange={onChange} errors={errors} />
                )}
        </>
    );
}

export default ReferralFee;