//import ReactDOM from "react-dom";
import { renderToStaticMarkup } from "react-dom/server";
import _ from "lodash";
import places from "./assets/places";
import fuzzySearch from 'fuzzysearch'
import { enviroment } from "../config";
/* const fuzzySearch = (value, query) => {
    console.log('fuzzySearch', query)
    const str = value.toLowerCase();
    let i = 0, n = -1, l;
    query = query.toLowerCase();
    for (; l = query[i++];) {
        if (!~(n = str.indexOf(l, n + 1))) {
            return false;
        };
    };
    return true;
};
 */

export default class Utils {
    static capFirst = string =>
        string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

    static debounce = (func, wait, immediate) => {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    };

    //The SVG must include the xmlns attribute otherwise Chrome won’t treat it as an image.
    //I’m using renderToStaticMarkup from react-dom/server in order to get the plain string value of the SVG.
    //React will not render the SVG data if it is not URI encoded. So be sure to add encodeURIComponent in order for your data URI to be rendered to the DOM.
    static convertComponentSvgToUri = svgComponent => {
        const svgString = encodeURIComponent(
            renderToStaticMarkup(svgComponent)
        );
        // const dataUri = `url("data:image/svg+xml,${svgString}")`;
        return svgString;
    };

    static getFormattedAddressFromGMO = gmapsObject =>
        gmapsObject && gmapsObject.formatted_address
            ? gmapsObject.formatted_address
            : "";

    static getZipCodeFromGMO = gmapsObject => {
        const TARGET_TYPE = "postal_code";
        const index =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;

        return (index || index === 0) &&
            gmapsObject.address_components[index].long_name
            ? gmapsObject.address_components[index].long_name
            : "";
    };

    static getStateFromGMO = gmapsObject => {
        const TARGET_TYPE = "administrative_area_level_1";
        const index =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;
        return (index || index === 0) && gmapsObject.address_components[index]
            ? gmapsObject.address_components[index]
            : "";
    };

    static getFullStateFromGMO = gmapsObject => {
        const TARGET_TYPE = "administrative_area_level_1";
        const index =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;
        return (index || index === 0) &&
            gmapsObject.address_components[index].short_name &&
            gmapsObject.address_components[index].long_name
            ? {
                long_name: gmapsObject.address_components[index].long_name,
                short_name: gmapsObject.address_components[index].short_name
            }
            : null;
    };

    static getSubAdministrativeAreaFromGMO = gmapsObject => {
        const TARGET_TYPE = "locality";
        const index =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;
        return (index || index === 0) &&
            gmapsObject.address_components[index].long_name
            ? gmapsObject.address_components[index].long_name
            : "";
    };

    static getLocationAddressFromGMO = gmapsObject => {
        const TARGET_TYPE_1 = "administrative_area_level_1";
        const index_1 =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE_1 ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;
        const state =
            index_1 && gmapsObject.address_components[index_1].long_name
                ? gmapsObject.address_components[index_1].long_name
                : "";

        const TARGET_TYPE_3 = "administrative_area_level_3";
        const index_3 =
            gmapsObject &&
                gmapsObject.address_components &&
                Array.isArray(gmapsObject.address_components)
                ? gmapsObject.address_components
                    .map((address, i) =>
                        address.types && Array.isArray(address.types)
                            ? address.types
                                .map((type, j) =>
                                    type === TARGET_TYPE_3 ? i : false
                                )
                                .reduce(
                                    (prev, current) =>
                                        Number.isInteger(prev)
                                            ? prev
                                            : Number.isInteger(current)
                                                ? current
                                                : false,
                                    false
                                )
                            : false
                    )
                    .filter(address_type => Number.isInteger(address_type))
                    .reduce(
                        (prev, current) =>
                            Number.isInteger(prev)
                                ? prev
                                : Number.isInteger(current)
                                    ? current
                                    : false,
                        false
                    )
                : null;
        const city =
            index_3 && gmapsObject.address_components[index_3].long_name
                ? gmapsObject.address_components[index_3].long_name
                : "";
        const result = city && state ? `${city}, ${state}` : state ? state : "";
        return result;
    };

    static getLocationCoordsFromGMO = gmapsObject =>
        gmapsObject && gmapsObject.geometry && gmapsObject.geometry.location
            ? [
                typeof gmapsObject.geometry.location.lat === "function"
                    ? gmapsObject.geometry.location.lat()
                    : gmapsObject.geometry.location.lat,
                typeof gmapsObject.geometry.location.lng === "function"
                    ? gmapsObject.geometry.location.lng()
                    : gmapsObject.geometry.location.lng
            ]
            : [0, 0];

    static isEmptyObject = obj => {
        // null and undefined are "empty"
        if (obj == null) return true;

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== "object") return true;

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }

        return true;
    };

    static isNonEmpty = (obj) => {
        return (
            (_.isString(obj) && !_.isEmpty(obj.trim())) ||
            (_.isPlainObject(obj) && !_.isEmpty(obj)) ||
            (_.isArray(obj) && !_.isEmpty(obj))
        );
    }

    static isState = (place, text) => {
        const isState = place.state === "";
        const isExpectedState = isState &&
            (place.city.toLowerCase().indexOf(text) >= 0 ||
                place.shortState.toLowerCase().indexOf(text) >= 0) && {
            state: true,
            long_name: place.city,
            short_name: place.shortState
        };
        return isExpectedState
    };



    static matchLocation = (place, text) => {
        const isCity = place.state !== "";
        const spaceSeparatorFormatedCity = `${place.city}, ${place.state}, ${place.shortState}`;
        const match = fuzzySearch(text, spaceSeparatorFormatedCity.toLowerCase())
        const isExpectedCity = match && {
            state: !isCity,
            long_name: place.formatedCity,
            short_name: place.shortState
        };
        return isExpectedCity;
    };


    /*   static getStates = text => {
          text = text.toLowerCase();
          const isStateArray = places.map(place => Utils.isState(place, text))
          return isStateArray.filter(e => e);
      };
  
      static getCities = text => {
          text = text.toLowerCase();
          const isCity = places.map(place => Utils.isCity(place, text))
          return isCity.filter(e => e);
      }; */

    static getLocations = text => {
        text = text.toLowerCase();
        const matchLocation = places.map(place => Utils.matchLocation(place, text))
        return matchLocation.filter(e => e);
    };

    static removeDuplicates = arr => {
        return _.uniqBy(arr, function (e) {
            return e.short_name;
        });
    };

    static detectBrowser = () => {
        if (
            (navigator.userAgent.indexOf("Opera") ||
                navigator.userAgent.indexOf("OPR")) !== -1
        ) {
            return "Opera";
        } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
            return "Chrome";
        } else if (navigator.userAgent.indexOf("Safari") !== -1) {
            return "Safari";
        } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
            return "Firefox";
        } else if (
            navigator.userAgent.indexOf("MSIE") !== -1 ||
            !!document.documentMode === true
        ) {
            return "IE"; //crap
        } else {
            return "Unknown";
        }
    };

    static convertLongStringDatetoShortDate = d => {
        var parts = d.split(" ");
        var months = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12"
        };
        return parts[3] + "-" + months[parts[1]] + "-" + parts[2];
    };

    static cleanObject = (object = null) => {
        if (!object) return object
        const cleanProfile = {};
        Object.keys(object).forEach(profileKey => {
            if (typeof object[profileKey] !== "undefined" && object[profileKey] != null) {
                cleanProfile[profileKey] = object[profileKey];
            }
        });

        return cleanProfile;
    };

    static getLinksPosition = text => {
        let indexs = []
        var regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        let result
        while ((result = regex.exec(text))) {
            indexs.push({ index: result.index, text: result[0] });

        }

        return indexs;
    };

    static getWordByPosition(str = '', pos = 0) {
        let strSplited = str.substr(pos, str.length)
        strSplited.split(' ', 1)
        return strSplited
    }


    // version from `meta.json` - first param
    // version in bundle file - second param
    static semverGreaterThan = (versionA, versionB) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());

            const b = Number(versionsB.shift());
            // eslint-disable-next-line no-continue
            if (a === b) continue;
            // eslint-disable-next-line no-restricted-globals
            return a > b || isNaN(b);
        }
        return false;
    };

    static sortArrayBy = (items, key, desc = false) => {

        const ascOrder = function (a, b) {
            if (a[key] > b[key]) {
                return 1;
            }
            if (a[key] < b[key]) {
                return -1;
            }
            // a must be equal to b
            return 0;
        }

        const descOrder = function (a, b) {
            if (a[key] < b[key]) {
                return 1;
            }
            if (a[key] > b[key]) {
                return -1;
            }
            // a must be equal to b
            return 0;
        }

        return items.sort(desc ? descOrder : ascOrder);
    }

    static formatFilters = (filters) => {
        //Our multi select options are stored as {value: '', label: ''} so we need to pass only the values to filters
        //Below code is to extract the field "value" inside. 

        let _filters = {}
        Object.keys(filters).forEach(key => {
            //key is for profession
            //if filters[key] exists and has field "value" then we need to extract it
            //.value can be false so we need to check if it exists
            if (filters[key] && filters[key].hasOwnProperty('value')) {
                _filters[key] = filters[key].value
            } else if (_.isArray(filters[key])) {
                _filters[key] = filters[key].map(item => item.value)
            } else {
                _filters[key] = filters[key]
            }
        })

        // turn all filter fields to snake case from camel case
        _filters = _.mapKeys(_filters, (value, key) => _.snakeCase(key))

        return _filters;
    }

    static onboardingCompleted = (module) => {
        // if (process.env.NODE_ENV === 'development') return false;
        const onboarding = localStorage.getItem(`${module}-onboarding-completed`)
        if (onboarding) {
            return true
        }
        return false
    }

    static setOnboardingCompleted = (module) => {
        localStorage
            .setItem(`${module}-onboarding-completed`, true)
    }

    static isLocalhost = () => (
        Boolean(
            window.location.hostname === "localhost" ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === "[::1]" ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(
                    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
                )
        )
    )

    static getYearsOptions = () => {
        const years = [];
        const currentYear = new Date().getFullYear();
        for (let i = currentYear; i >= currentYear - 100; i--) {
            years.push({ value: i, label: i });
        }
        return years;
    };

    static getNameWithAnSAtTheEnd = (name) => {
        const lastLetter = name.charAt(name.length - 1);
        return lastLetter === "s" ? `${name}'` : `${name}'s`;
    }

    static isDev = () => enviroment === "development"

    // If phone has country code, remove it:
    static parsePhoneNumber = number =>
        number && number.toString().indexOf("+1") !== -1
            ? number.slice(2, number.length)
            : number;

    static formatRankingPoints = points => points > 999 ? points > 999999 ? `${(points / 1000000).toFixed(1)}M` : `${(points / 1000).toFixed(1)}K` : points;
}

export function mapContactToUserInfo(contact) {
    return {
        ...contact,
        name: contact.full_name,
        company_name: (contact && contact.company) || null,
        location: `${contact.city ? `${contact.city}, `: ""}${contact.state ? contact.state : ""}`,
        locationAddress: `${contact.city ? `${contact.city}, `: ""}${contact.state ? contact.state : ""}`,
        subscription: {
            plan: ""
        },
        uid: contact.member_uid || contact.contact_id,
        type: "CONTACT",
    };
}


export function extractCandidateData(data) {
    let justContact = []
    let justCandidate = []
    for (let cand of data) {

        justCandidate.push({
            address: cand ? cand.locationAddress : "",
            avatar: cand ? cand.avatar : "",
            companyName: cand ? cand.company_name : "",
            email: cand ? cand.email : "nuop@gmail.com",
            lastname: cand ? cand.last_name : "",
            name: cand ? cand.first_name : "",
            phone: cand ? cand.phone : "",
            plan: cand ? cand.subscription.plan : "",
        //  state: "",
            type: cand && parseInt(cand.user_type),
            uid: cand ? cand.uid : "",
            userName: cand ? cand.userName : "",
        })
    }


    return {
      justContact,
      justCandidate
    }
  }

  export const cleaningObject = (objectData) => {
    const cleanedPayload = Object.keys(objectData)
                            .filter((cangriB) => objectData[cangriB] != null)
                            .reduce((alfaCentauri, cangriB) => ({ ...alfaCentauri, [cangriB]: objectData[cangriB] }), {});

    return cleanedPayload
  }


 export function convertToTimeStamp(strDate){
        let toTimeStamp = Date.parse(strDate);
        return toTimeStamp/1000;
}
     


export const getRealEstateValues = (formValues) => {
    const {
        credit,
        homeSaleContingency,
        maxLotSize,
        minBeds,
        minLotSize,
        minUnits,
        pets,
        acres,
        baths,
        beds,
        estimatedPrice,
        financingInfo,
        maxAcres,
        maxPrice,
        maxSize,
        maxSquareFeet,
        maxUnits,
        minAcres,
        minPrice,
        minSize,
        minSquareFeet,
        propertySubType,
        propertyType,
        size,
        sizeUnit,
        squareFeet,
        units,
        retailSpace,
    } = formValues && formValues
    
    let allPropertiesValues = {}
     allPropertiesValues = {
        acres:               acres ? acres : null,
        baths:               baths ? parseInt(baths) : null, // POSITIVE NUMBER
        beds:                beds ? parseInt(beds) : null,
        credit:              credit ? credit.value : null, // STRING VALUE
        estimatedPrice:      estimatedPrice ? parseInt(estimatedPrice.substring(1)) : null,
        financingInfo:       financingInfo ? financingInfo.value : null,
        homeSaleContingency: homeSaleContingency ? homeSaleContingency.value : null,  // STRING VALUE
        lotSizeUnit:         minLotSize ? (minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase() === "ACRE") ? "ACRES" : minLotSize.value.replace(/[^a-zA-Z]+/g, '').toUpperCase() : null, // STRING VALUE
        maxAcres:            maxAcres ? parseInt(maxAcres) : null, // POSITIVE NUMBER
        maxLotSize:          maxLotSize ? parseInt(maxLotSize.value) : null, // POSITIVE NUMBER
        maxPrice:            maxPrice ? parseInt(maxPrice.substring(1)) : null, // POSITIVE NUMBER
        minPrice:            minPrice ? parseInt(minPrice) : null, // POSITIVE NUMBER
        maxSquareFeet:       maxSquareFeet ? parseInt(maxSquareFeet) : null,
        maxUnits:            maxUnits ? maxUnits.value : null, // POSITIVE NUMBER
        minAcres:            minAcres ? parseInt(minAcres) : null, // POSITIVE NUMBER
        minBeds:             minBeds ? parseInt(minBeds) : null, // POSITIVE NUMBER
        minLotSize:          minLotSize ? parseInt(minLotSize.value) : null, // POSITIVE NUMBER
        minSize:             minSize ? parseInt(minSize) : null,
        minSquareFeet:       minSquareFeet ? parseInt(minSquareFeet) : null,
        minUnits:            minUnits ? minUnits.value : null, // POSITIVE NUMBER
        pets:                (pets && pets.label === 'Yes') ? 'YES' : (pets && pets.label === 'Not sure') ? 'NOT_SURE' : 'NO', // STRING VALUE
        propertySubType:     propertySubType ? propertySubType.value : null,
        propertyType:        propertyType ? propertyType.value : null,
        size:                (size) ? parseInt(size) : null,  // POSITIVE NUMBER
        sizeUnit:            (maxSize || size) ? sizeUnit : null,
        squareFeet:          squareFeet ? parseInt(squareFeet) : null, // POSITIVE NUMBER
        timePeriod:          (retailSpace.value === 'SQUARE_FEET_PER_MONTH') ? 'MONTH' : 'YEAR',
        units:               (minUnits || maxUnits) ? (minUnits.value || maxUnits.value) : null, // POSITIVE NUMBER
      }


      return allPropertiesValues;


}

