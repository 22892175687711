import { IMAGES_SIZE_PREFIX } from "../assets/strings";


/**
 * Gets the image extension from a file hosted in Firebase Storage
 *
 * @param {String} url
 * @return {String} 
 */
const getExtension = url => {
    return url
        .split(/\#|\?/)[0]
        .split(".")
        .pop()
        .trim();
};


/**
 * Class that handles global functions
 *
 * @export
 * @class Layout
 */
export default class Layout {

    /**
     * Gets the current selected tab in the navbar by the given pathname
     *
     * @static
     * @param {*} tabsOptions  Tabs options
     * @param {*} pathname Current pathname
     * @param {*} meUid User ID
     * @return {*} 
     * @memberof Layout
     */
    static getCurrentTab(tabsOptions, pathname, meUid) {
        let response = "";
        const pathName = pathname.toLowerCase();

        if (pathName.includes("referral")) response = "My Ops";
        else if (pathName === "/" || pathName.includes("news")) response = "Home";
        else if (pathname.includes("profile")) {
            const uid = pathname.split("/")[2];
            if (meUid === uid) response = "Profile";
        } else {
            tabsOptions.forEach(option => {
                if (pathName.includes(option.url)) response = option.text;
            });
        }
        return response;
    }


    /**
     * Returns the current browser. This could be: Chrome, Safari, Firefox, Opera, IE, Edge
     *
     * @static
     * @return {String} Current browser.
     * @memberof Layout
     */
    static getCurrentBrowser() {
        let sBrowser,
            sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Chrome";
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Safari";
        } else if (sUsrAg.indexOf("Opera") > -1) {
            sBrowser = "Opera";
        } else if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Firefox";
        } else if (sUsrAg.indexOf("MSIE") > -1) {
            sBrowser = "MSIE";
        }
        return sBrowser;
    }


    /**
     * Transforms image URL to formated URL with the size prefix.
     *
     * @static
     * @param {string} [url=""]
     * @param {string} [size=""]
     * @return {*} 
     * @memberof Layout
     */
    static getResizedImageUrl(url = "", size = "") {
        const storageDomain = process.env.REACT_APP_FB_STORAGE_BUCKET;
        let newUrl = url ? url : '';
        if (newUrl.toString().indexOf(storageDomain) !== -1) {
            const extension = getExtension(url);

            const splitedUrl = newUrl.split("?");
            if (extension) {
                switch (size.toUpperCase()) {
                    case "SMALL":
                        newUrl =
                            splitedUrl[0] +
                            "." +
                            IMAGES_SIZE_PREFIX.SMALL +
                            "." +
                            extension +
                            "?" +
                            splitedUrl[1];
                        break;
                    case "MEDIUM":
                        newUrl =
                            splitedUrl[0] +
                            "." +
                            IMAGES_SIZE_PREFIX.MEDIUM +
                            "." +
                            extension +
                            "?" +
                            splitedUrl[1];
                        break;
                    case "LARGE":
                        newUrl =
                            splitedUrl[0] +
                            "." +
                            IMAGES_SIZE_PREFIX.LARGE +
                            "." +
                            extension +
                            "?" +
                            splitedUrl[1];
                        break;
                    default:
                        newUrl = url;
                        break;
                }
            }
        }
        return newUrl;
    }


    /**
     * Compress the given file to a lower quality 
     *
     * @static
     * @param {*} file
     * @param {*} Compresor
     * @return {*} 
     * @memberof Layout
     */
    static compressFile(file, Compresor) {
        if (!file) {
            return file;
        }
        return new Promise((resolve, reject) => {
            new Compresor(file, {
                quality: 0.6,
                success(result) {
                  
                    return resolve(result);
                },
                error(err) {
                    return reject(err);
                }
            });
        });
    }
}
