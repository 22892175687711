import styled from "styled-components";

export const WithoutBordersInput = styled.input`
    width: 100%;
    border: none;
    min-height: 30px;
    height: 100%;
    font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
    color: ${props => props.theme.coolBlack};
    ::placeholder {
        font-style: ${props =>
            props.placeholderStyles
                ? props.placeholderStyles.fontStyle
                : "normal"};
        font-weight: ${props =>
            props.placeholderStyles
                ? props.placeholderStyles.fontWeight
                : "normal"};
        line-height: ${props =>
            props.placeholderStyles
                ? props.placeholderStyles.lineHeight
                : "22px"};
        font-size: ${props =>
            props.placeholderStyles
                ? props.placeholderStyles.fontSize
                : props.fontSize
                ? props.fontSize
                : "20px"};
        letter-spacing: ${props =>
            props.placeholderStyles
                ? props.placeholderStyles.letterSpacing
                : "-0.408px"};
        color: ${props =>
            props.placeholderStyles && props.placeholderStyles.color
                ? props.placeholderStyles.color
                : props.color
                ? props.color
                : props.theme.coolBlack};
    }
    :focus {
        outline: none;
    }
`;

/* export const RightIcon = styled.div`
    position: absolute;
    padding-right: 8px;
    padding-left: 8px;
    cursor: pointer;
    right: 1em;
    bottom: ${props => props.fontSize ? `calc(${props.fontSize} -  )`};
`;
 */
