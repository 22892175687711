import React, { useState } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { selectStyle } from "../../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../../atoms/shared/Icon";
import LocationForm from "../../ReferralCompleteInformation/Location/view"
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const VisibilityOptions = [
    { value: 'EVERYONE', label: 'Yes, display the accepted job to everyone!' },
    { value: 'PRIVATE', label: 'No, only allow visibility to the parties of the job.'},
]

const HelpText = styled.div`
    color: #929292;
    font-size: 16px;
    font-weight: 500;
    margin: 1em 0;
`

const VisibilityAfterExchange = ({
    titleStyles,
    theme,
    handleChange,
    values,
    t,
    errors,
}) => {
    const key = "visibility_"
    const [isCollapsed, setIsCollapsed] = useState(false)

    return(
        <Box key={key} width="100%">
            <CollapsableSection
                onClickCollapse={() => {setIsCollapsed(prev => !prev)}}
                collapsed={isCollapsed}
                title={
                    <Flex justifyContent={"space-between"}>
                        <Flex alignItems={"center"}>
                            <span style={titleStyles}>
                                Visibility After Acceptance
                            </span>
                            <Icon
                                icon="info"
                                size="md"
                                color="#057AFF"
                                data-tip
                                data-for={key}
                            />
                        </Flex>
                    </Flex>
                }
                flexJustify={"space-between"}
                titleStyles={{
                    ...titleStyles
                }}
                onClick={e => {}}
                arrowDimensions={{ height: 9, width: 15 }}
                arrowColor={theme.coolBlack}
                showArrow
                rootStyles={{
                    marginBottom: theme.spacing[10],
                    overflow: "inherit"
                }}
            >
                <>
                    <HelpText>Do you want to display the acceptance of this job?</HelpText>
                    {VisibilityOptions.map(({ value, label }) => (
                        <Flex key={value} mb={theme.spacing[4]}>
                            <CheckBox
                                round
                                inverted
                                label={label}
                                onChange={ev =>
                                    handleChange({
                                        target: {
                                            name: "visibilityAfterExchange",
                                            value: { value, label },
                                        }
                                    })
                                }
                                checkBoxColor={theme.blueMedium}
                                checked={value === values.visibilityAfterExchange.value}
                            />
                        </Flex>
                    ))}
                </>
            </CollapsableSection>
            <ReactTooltip
                id={key}
                delayHide={200}
                place="bottom"
                type="light"
                effect="solid"
                className="form-tooltip"
            >
                <div dangerouslySetInnerHTML={{ __html: t("contractorVisibilityAfterExchangeInfo") }}/>
            </ReactTooltip>
        </Box>
    );
}

export default VisibilityAfterExchange;
