import React, { useState } from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass"
import ReactTooltip from "react-tooltip";
import CollapsableSection from "../../../../atoms/shared/CollapsableSection";
import { StepWrapper, Button, ButtonContainer } from "../../components";
import { Icon } from "../../../../atoms/shared/Icon";
import Location from "./Location";
import TargetToNotify from "./TargetToNotify";
import AcceptanceCondition from "./AcceptanceCondition";
import Description from "./Description";
import RealStateDetails from "./RealStateDetails";
import TimingInfo from "./TimingInfo";
import ReferralFee from "./ReferralFee";
import ClientInformation from "./ClientInformation";
import Visibility from "./Visibility";
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup'
import { targetToNotifyOptions, visibilityOptions, retailSpaceOptions, REFERRAL_PRIVACY } from "../../constants"
import Profession from "./Profession";
import { useEffect } from "react";
import {DraftModal, useDraft} from "../../useDraft";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5em;
`;

const formInitialValues = {
    // Static fields
    profession: null,
    address: '',
    description: '',
    clientSource: null,
    currentSatus: null,
    lastContact: new Date(),
    referralFee: null,
    agreement: null,
    cannotFindProfession: false,
    locationIsRemote: null,

    // Required if profession is global.
    targetToNotify: targetToNotifyOptions[0],

    // Required if multiple candidates are selected for this referral.
    acceptanceCondition: null,

    // Required if profession is Real Estate Agent
    clientType: null,
    propertyType: null,
    propertySubType: null,

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: '',

    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: '',

    minBeds: '',

    // Required if clientType is 'BUYER'
    financingInfo: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: null,

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: null,
    pets: null,

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL' and propertySubtype is 'MULTI_FAMILY'
    minUnits: '',

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: null,

    realEstateFee: '',
    comments: '',
    flatFeeAmount: null,
    paymentDue: null,
    grossRevenueFee: null,
    feeDuration: null,
    feeFrequency: null,
    feeCommencement: null,
    visibility: visibilityOptions[0],
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    clientAddres: '',
    minPrice: '',
    maxBeds: '',
    minSquareFeet: '',
    maxSquareFeet: '',
    minAcres: '',
    maxAcres: '',
    maxUnits: '',
    units: '',
    acres: '',
    beds: '',
    baths: '',
    squareFeet: '',
    retailSpace: retailSpaceOptions[0],
    clientFirstName: '',
    clientLastName: '',
    clientEmail: '',
    clientMobileNumber: '',
    clientStreetAddress: '',
    clientCity: '',
    clientState: '',
    clientZipCode: '',
    clientInformation: null,
    searchUserMode: true,
    notifyToClient: false
};

const getFormSchema = (haveMoreThanOneCandidate = false, professionRequired = true, opMethod) => yup.object().shape({
    // Static fields
    profession: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
        global: yup.boolean().required(),
    }).nullable(!professionRequired)
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).nullable()
        }),
    cannotFindProfession: yup.boolean(),
    professionDescription: yup.string().nullable()
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.string().required()
        }),
    address: yup.object().nullable()
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
            then: yup.object().nullable()
        })
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
            then: yup.object({
                description: yup.string().required(),
                lat: yup.number().required(),
                lng: yup.number().required(),
            }).required()
        }),
    locationIsRemote: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    description: yup.string().required().min(opMethod === 'DIRECT' ? 31 : 129),
    clientSource: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    currentSatus: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    lastContact: yup.date().required(),
    referralFee: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),

    // Required if profession is global.
    targetToNotify: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
        // }).when("profession", {
        //     is: (profession) => profession && profession.global,
        //     then: yup.object({
        //         value: yup.string().required(),
        //         label: yup.string().required(),
        //     }).required()
    }).nullable().notRequired(),

    // Required if multiple candidates are selected for this referral.
    acceptanceCondition: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable(!haveMoreThanOneCandidate),

    // Required if profession is Real Estate Agent
    clientType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    })
        .nullable()
        .when("profession", {
            is: (profession) => profession && profession.value === '100',
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if profession is Real Estate Agent
    propertyType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when("profession", {
            is: (profession) => profession && profession.value === '100',
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if profession is Real Estate Agent
    propertySubType: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when("profession", {
            is: (profession) => profession && profession.value === '100',
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if clientType is 'BUYER' or 'TENANT'
    maxPrice: yup.string()
        .nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') || (clientType && clientType.value === 'TENANT')),
            then: yup.string().required()
        }),

    // Required if clientType is 'SELLER' or 'LANDLORD'
    estimatedPrice: yup.string()
        .nullable()
        .when(["clientType"], {
            is: (clientType) => ((clientType && clientType.value === 'SELLER') || (clientType && clientType.value === 'LANDLORD')),
            then: yup.string().required()
        }),

    minBeds: yup.number().nullable(),

    // Required if clientType is 'BUYER'
    financingInfo: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when(["clientType"], {
            is: (clientType) => ((clientType && clientType.value === 'BUYER')),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if clientType is 'BUYER' and propertyType is 'RESIDENTIAL'
    homeSaleContingency: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when(["clientType", "propertyType"], {
            is: (clientType, propertyType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL')),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    minSquareFeet: yup.number().nullable(),
    maxSquareFeet: yup.number(),
    maxUnits: yup.number(),

    minUnits: yup.object()
        .nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY')),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    credit: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL')),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if clientType is 'TENANT' and propertyType is 'RESIDENTIAL'
    pets: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'RESIDENTIAL')),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    // Required if clientType is 'TENANT' and propertType is 'LAND' or clientType is 'BUYER' and propertyType is 'LAND'
    minLotSize: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => (
                ((clientType && clientType.value === 'TENANT') && (propertyType && propertyType.value === 'LAND')) ||
                ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'LAND')) ||
                ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
            ),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),

    units: yup.object()
        .nullable()
        .when(["clientType", "propertyType", "propertySubType"], {
            is: (clientType, propertyType, propertySubType) => (
                (clientType && clientType.value === 'SELLER') &&
                (propertyType && propertyType.value === 'RESIDENTIAL') &&
                (propertySubType && propertySubType.value === 'MULTI_FAMILY')
            ),
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required()
        }),


    /*
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'APARTMENT')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'CONDO')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'MULTI_FAMILY')) ||
        ((clientType && clientType.value === 'BUYER') && (propertyType && propertyType.value === 'RESIDENTIAL') && (propertySubType && propertySubType.value === 'SINGLE_FAMILY'))
    */


    realEstateFee: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "REAL_ESTATE",
        then: yup.number().min(0).max(100).required()
    }),

    comments: yup.string().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "OTHER",
        then: yup.string().required()
    }).when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.string()
    }).when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.string()
    }),

    flatFeeAmount: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.number().min(0).required()
    }),

    paymentDue: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "FLAT",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    grossRevenueFee: yup.number().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.number().min(0).max(100).required()
    }),

    feeDuration: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    feeFrequency: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    feeCommencement: yup.object().nullable().when("referralFee", {
        is: (referralFee) => referralFee && referralFee.value === "GROSS_REVENUE",
        then: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
    }),

    agreement: yup.object()
        .nullable()
        .when("referralFee", {
            is: (referralFee) => referralFee && referralFee.value !== "NO_FEE",
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
            }).required(),
        }),

    visibility: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }),

    email: yup.string().nullable(),

    firstName: yup.string().nullable(),

    lastName: yup.string().nullable(),

    phone: yup.string().nullable(),

    clientAddres: yup.string().nullable(),

    minPrice: yup.number(),

    maxBeds: yup.number(),

    minAcres: yup.number(),

    maxAcres: yup.number(),

    acres: yup.number(),

    beds: yup.number(),

    baths: yup.number(),

    squareFeet: yup.number(),

    retailSpace: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).nullable(),

    clientFirstName: yup.string()
        .when(["agreement", "clientInformation"], {
            is: (agreement, clientInformation) => (agreement && agreement.label === 'Yes') && !clientInformation,
            then: yup.string().required(),
        }),

    clientLastName: yup.string()
        .when(["agreement", "clientInformation"], {
            is: (agreement, clientInformation) => (agreement && agreement.label === 'Yes') && !clientInformation,
            then: yup.string().required(),
        }),

    clientEmail: yup.string()
        .when(["agreement", "clientMobileNumber", "clientInformation"], {
            is: (agreement, clientMobileNumber, clientInformation) =>
                ((agreement && agreement.label === 'Yes') && !clientMobileNumber && !clientInformation),
            then: yup.string().required(),
        }),

    clientMobileNumber: yup.string()
        .when(["agreement", "clientEmail", "clientInformation"], {
            is: (agreement, clientEmail, clientInformation) => (agreement && agreement.label === 'Yes') && !clientEmail && !clientInformation,
            then: yup.string().required(),
        }),

    clientStreetAddress: yup.string(),

    clientCity: yup.string(),
    searchUserMode: yup.boolean(),

    //clientState: yup.string(),

    clientZipCode: yup.string(),

    clientInformation: yup.object().nullable(true)
        .when(["agreement", "clientEmail", "clientMobileNumber", "clientFirstName", "clientLastName"], {
            is: (agreement, clientEmail, clientMobileNumber, clientFirstName, clientLastName) =>
                (agreement && agreement.label === 'Yes') && !clientEmail && !clientMobileNumber && !clientFirstName && !clientLastName,
            then: yup.object(),
        }),
}, [
    ['clientFirstName', 'clientInformation'],
    ['clientLastName', 'clientInformation'],
    ['clientEmail', 'clientMobileNumber'],
    ['clientEmail', 'clientInformation'],
    ['clientMobileNumber', 'clientInformation'],
]);



const Listener = ({ saveStepData }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        saveStepData({ ...formInitialValues, ...values });
    }, [values])

    return null
}
const ReferralCompleteInformation = (props) => {

    const { t, theme, setStepData, stepData, opPrivacy, candidates, saveStepData, stepKey } = props;
    const opMethod = candidates.length === 1 ? 'DIRECT' : 'N0N-REDIRECT-OP';
    const haveCandidates = opPrivacy && opPrivacy.value === REFERRAL_PRIVACY.CANDIDATE && candidates.length >= 1;
    const shouldRenderProfession = (opPrivacy && opPrivacy.value === REFERRAL_PRIVACY.EVERYONE) || (haveCandidates && candidates.length > 1) || (candidates.length === 1 && candidates[0].type === "CONTACT");
    const formSchema = getFormSchema(haveCandidates && candidates.length > 1, shouldRenderProfession, opMethod);
    const haveOnlyOneCandidateRealEstate = haveCandidates && candidates.length === 1 && candidates[0] &&  candidates[0].user_type && candidates[0].user_type === 100;

    const sections = [
        {
            key: "profession",
            title: "Select the profession type you are seeking:",
            Component: Profession,
            info: t("professionTooltipInfo"),
            shouldRender: shouldRenderProfession,
            initCollapsed: false,
        },
        // {
        //     key: "targetToNotify",
        //     title: "Profession Notification Radius",
        //     Component: TargetToNotify,
        //     info: t("targetToNotify_tooltip"),
        //     shouldRender: true,
        //     initCollapsed: false,
        // },
        {
            key: "location",
            title: "Location",
            Component: Location,
            info: t("location_tooltip"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "timingInfo",
            title: "Timing Info",
            Component: TimingInfo,
            info: t("timingInfo_tooltip"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "realEstateDetails",
            title: "Real Estate Details",
            Component: RealStateDetails,
            info: null,
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "description",
            title: "Description",
            Component: Description,
            info: t("description_tooltip"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "referralFee",
            title: "Referral Fee",
            Component: ReferralFee,
            info: t("referralFee_tooltip"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "clientInfomation",
            title: "Client Information",
            Component: ClientInformation,
            info: t("clientInfomation_tooltip"),
            shouldRender: true,
            initCollapsed: false,
        },
        {
            key: "acceptanceCondition",
            title: "Who can accept this referral?",
            Component: AcceptanceCondition,
            info: t("acceptanceCondition_tooltip"),
            shouldRender: haveCandidates && candidates.length > 1,
            initCollapsed: false,
        },
      
        {
            key: "visibility",
            title: "Visibility After Exchange",
            Component: Visibility,
            info: t("visibility_tooltip"),
            shouldRender: haveCandidates,
            initCollapsed: false,
        }
    ];
    const { loading, saveDraft, modalMessage, setShowModal, showModal } = useDraft();

    const collapsibleInitialState = sections.reduce(
        (acc, curr) => ({ ...acc, [curr.key]: curr.initCollapsed }),
        {}
    );

    const [collapsibleSections, setCollapsibleSections] = useState(
        collapsibleInitialState
    );

    const onToogleCollapse = key =>
        setCollapsibleSections(prev => ({
            ...prev,
            [key]: !collapsibleSections[key]
        }));

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        // marginLeft: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    const displayRealStateDetailsSection = (profession, key) => {
        if (key === 'realEstateDetails')
            if ((profession && profession.value === '100') || haveOnlyOneCandidateRealEstate)
                return true
            else
                return false
        return true;
    }

    const displayTargetToNotify = (profession, key) => {
        if (key === 'targetToNotify')
            if (profession && profession.global)
                return true;
            else
                return false;
        return true;
    }

    const displayOptionalSectionLabel = (key, formValues) => {
        const clientInformationOptional = key === 'clientInfomation' && (!formValues.agreement || (formValues.agreement && !formValues.agreement.value));
        const realEstateInformationOptional = key === 'realEstateDetails' && haveOnlyOneCandidateRealEstate;

        if (realEstateInformationOptional || clientInformationOptional)
            return '(Optional)'

        return ''
    }

   
  
     
    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={stepData ?  ({ ...formInitialValues, ...stepData}) : formInitialValues}
                onSubmit={(values) => setStepData(values)}
                validationSchema={formSchema}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    /* and other goodies */
                }) => (
                    <Container>
                        {sections.map(
                            ({ key, title, Component, info, shouldRender }) =>
                                shouldRender && displayTargetToNotify(values.profession, key) && displayRealStateDetailsSection(values.profession, key) && (
                                    <Box key={key} width="100%">
                                        <CollapsableSection
                                            onClickCollapse={() => onToogleCollapse(key)}
                                            collapsed={collapsibleSections[key]}
                                            title={
                                                <Flex justifyContent={"space-between"}>
                                                    <Flex alignItems={"center"}>
                                                        <span style={titleStyles}>
                                                            {title} {displayOptionalSectionLabel(key, values)}
                                                        </span>
                                                        {info && (<Icon
                                                            icon="info"
                                                            size="md"
                                                            color="#057AFF"
                                                            data-tip
                                                            data-for={key}
                                                        />)}
                                                    </Flex>
                                                </Flex>
                                            }
                                            flexJustify={"space-between"}
                                            titleStyles={{
                                                ...titleStyles
                                            }}
                                            onClick={e => { }}
                                            arrowDimensions={{ height: 9, width: 15 }}
                                            arrowColor={theme.coolBlack}
                                            showArrow
                                            rootStyles={{
                                                marginBottom: theme.spacing[10],
                                                overflow: "inherit"
                                            }}
                                        >
                                            <Component
                                                formValues={values}
                                                theme={theme}
                                                onChange={handleChange}
                                                errors={errors}
                                                opMethod={opMethod}
                                            />
                                        </CollapsableSection>
                                        <ReactTooltip
                                            id={key}
                                            delayHide={200}
                                            place="bottom"
                                            type="light"
                                            effect="solid"
                                            className="form-tooltip"
                                        >
                                            <div dangerouslySetInnerHTML={{ __html: info }} />
                                        </ReactTooltip>
                                    </Box>
                                )
                        )}
                        <Listener saveStepData={saveStepData} stepData={stepData} />
                        <ButtonContainer>
                        {isValid && <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={saveDraft}
                                >
                                    Save Draft
                                </Button>
                            </Box>}
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default ReferralCompleteInformation;