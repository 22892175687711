import { Box } from 'rebass';
import styled from 'styled-components';

export const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    font-size: 1.5em;
    color: ${props => props.color || props.theme.coolBlack};
    padding-top: 10px;
    margin-bottom: 1rem;
    width: 100%;
    text-align: center;
`;

export const Status = styled.div`
    box-sizing: border-box;
    padding: 0.1em 1em 0.1em 1em; 
    border: ${props => `1px solid ${props.theme[props.statusColor]}`};
    background: ${props => `${props.theme[props.statusColor]}`};
    border-radius: 4px 4px;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: white;
    min-width: 80px;
`;

export const Container = styled(Box)`
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 1440px) {
        min-width: 100vw;
    }
`