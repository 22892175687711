import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WithoutBorderInput from "../Inputs/WithoutBordersInput/Input";
import { Icon } from "../Icon";
import Spinner from "../../../../../../assets/svg/Spinner";
import { Flex, Box } from "rebass";
import { withTheme } from "styled-components";
import SearchHook from './SearchHook'
import {
    SEARCH_TYPE_PROFILE/* , SEARCH_TYPE_LOCATION, SEARCH_SIZE_MOBILE,
    SEARCH_SIZE_DESKTOP */
} from '../../../views/Search/constants'
import {withRouter} from 'react-router-dom'

export const SearchBox = styled(Flex)`
    width: 25%;
    border-radius: ${({ theme }) => theme.spacing[1]};
    margin: 0 ${({ theme }) => theme.spacing[8]};
    background-color: ${({ theme }) => theme.clearGray};
    padding: ${({ theme }) => theme.spacing[1]}
        ${({ theme }) => theme.spacing[5]};
    border: 1px solid #D8D8D8;

    transition: all 0.3s ease;

    :focus-within {
    transition: all 0.3s ease;
        border: 1px solid ${({ theme }) => theme.newBlue};
        width: 75%;
    }
`;

const SearchUsersInner = props => {

    const { 
        theme, 
        history, 
        value, 
        onChange, 
        searchValue, 
        locationOrUser, 
        isLoading, 
        placeholderStyles, 
        inputStyles ,
        clearSearch,
        toggleSearchBar
    } = props

    let textInput = useRef(null);
    const [inputFocused, setInputFocused] = useState(false);

    // useEffect(() => {
    //     if(!theme.mobileDevice) textInput.current.focus();
    // }, []);
    // console.log(history)
    const onClose = () => {
        searchValue("");
        clearSearch();
        toggleSearchBar(false);
        // if (theme.mobileDevice || history.location.pathname === "/search")
        //     history.push("/home");
    }

    const onBlur = () => {
        setInputFocused(false);
        if (!theme.mobileDevice && history.location.pathname !== "/search")
            onClose();
    }

    const onFocus = () => {
        setInputFocused(true);
    }

    const LeftIcon = isLoading ? Spinner : Icon
    const iconName = !locationOrUser ? "search" : locationOrUser === SEARCH_TYPE_PROFILE ? "profile" : "location"


    if (theme.mobileDevice) {
        return (
            <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                bg={inputFocused ? theme.white : theme.clearGray}
                p={"0.75rem 1rem"}
                style={{
                    borderRadius: theme.spacing[1],
                    border: `1px solid ${
                        inputFocused ? theme.newBlue : "transparent"
                    }`,
                    transition: "all .3s ease",
                    height: "40px"
                }}
            >
                <Flex
                    flexDirection="row"
                    alignItems={["center"]}
                    justifyContent={["flex-start"]}
                    width={[0.9]}
                >
                    <LeftIcon
                        icon={iconName}
                        color={inputFocused ? theme.newBlue : theme.taupeGray}
                        width={theme.mobileDevice ? "22px" : "23px"}
                        height={theme.mobileDevice ? "22px" : "23px"}
                        style={{ transition: "all .2s ease" }}
                    />

                    <Box pl={["1em"]} width={"100%"}>
                        <WithoutBorderInput
                            onFocus={onFocus}
                            innerRef={textInput}
                            autocomplete="off"
                            autocorrect="off"
                            autocapitalize="off"
                            spellcheck="false"
                            fontSize={theme.mobileDevice ? "15px" : "16px"}
                            color={theme.coolBlack}
                            inputStyles={{
                                ...inputStyles,
                                background: "transparent"
                            }}
                            placeholder={"Search"}
                            placeholderStyles={
                                placeholderStyles
                                    ? placeholderStyles
                                    : {
                                          color: theme.darkGray
                                      }
                            }
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                        />
                    </Box>
                </Flex>
                <Icon
                    icon="close"
                    style={{ cursor: "pointer", transition: "all .3s ease" }}
                    onClick={onClose}
                    color={inputFocused ? theme.newBlue : theme.taupeGray}
                    width="20px"
                    height="20px"
                />
            </Flex>
        );
    }

    return (
        <SearchBox
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <Flex
                flexDirection="row"
                alignItems={["center"]}
                justifyContent={["flex-start"]}
                width={[0.9]}
            >
                <LeftIcon
                    icon={iconName}
                    color={theme.newBlue}
                    width={theme.mobileDevice ? "22px" : "17px"}
                    height={theme.mobileDevice ? "22px" : "17px"}
                />

                <Box pl={["1em"]} width={"100%"}>
                    <WithoutBorderInput
                        onFocus={() => props.history.push("/search")}
                        innerRef={textInput}
                        autocomplete="off"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="false"
                        fontSize={theme.mobileDevice ? "15px" : "16px"}
                        color={theme.coolBlack}
                        inputStyles={{
                            background: "transparent",
                            ...inputStyles
                        }}
                        placeholder={"Search..."}
                        placeholderStyles={
                            placeholderStyles
                                ? placeholderStyles
                                : {
                                      color: theme.darkGray
                                  }
                        }
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                    />
                </Box>
            </Flex>
            {value && (
                <Icon
                    icon="close"
                    style={{ cursor: "pointer" }}
                    onClick={onClose}
                    color={theme.newBlue}
                    width="20px"
                    height="20px"
                />
            )}
        </SearchBox>
    );
};

const SearchUsersWithTheme = withTheme(SearchUsersInner);
const InputWithHook = SearchHook(SearchUsersWithTheme)

export default withRouter(props => {
    const {history} = props
    const onDebouncedValChange = () => {
        if (history.location.pathname !== "/fill-profile") history.push('/search')
    }
return <InputWithHook {...props} onDebouncedValChange={onDebouncedValChange} isMegaSearch={true}/>
})