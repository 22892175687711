import { css } from "styled-components";

export const scrollBarStyles = css`
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.timberwolf};
        border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: ${({ theme }) => theme.taupeGray};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #787878;
    }
`