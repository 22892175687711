import React from "react";
import { Box, Flex } from "rebass";
import { Icon } from "../Icon";
import { Subtitle } from "../../../views/SendReferral/Layout/styles";
import { Container } from "./styles";
import { withTheme } from "styled-components";
import { Description } from "../Text";

const Collapsable = props => {
    const {
        title,
        subtitle,
        children,
        theme,
        arrowColor,
        collapsed,
        onClickCollapse,
        headerStyles,
        rootStyles,
        titleStyles,
        subtitleStyles,
        flexJustify,
        arrowDimensions,
        showArrow = true
    } = props;
    const toogleCollapsed = () => {
        if (onClickCollapse) onClickCollapse(!collapsed);
    };

    const arrowStyles = {
        transform: !collapsed ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform .5s"
    };

    return (
        <Container
            collapsed={collapsed}
            className="disable-select"
            style={rootStyles}
        >
            <Flex
                width={[1]}
                justifyContent={flexJustify || "space-between"}
                alignItems="center"
                style={{ cursor: "pointer" }}
                onClick={toogleCollapsed}
            >
                <Box style={{ headerStyles }}>
                    <Subtitle style={titleStyles}>{title}</Subtitle>
                    {subtitle && (
                        <Description style={subtitleStyles}>
                            {subtitle}
                        </Description>
                    )}
                </Box>
                <Box>
                    {showArrow && (
                        <Icon
                            icon="chevron-down"
                            height={
                                arrowDimensions ? arrowDimensions.height : null
                            }
                            width={
                                arrowDimensions ? arrowDimensions.width : null
                            }
                            color={arrowColor ? arrowColor : theme.blueMedium}
                            style={arrowStyles}
                        />
                    )}
                </Box>
            </Flex>

            {!collapsed && children}
        </Container>
    );
};

export default withTheme(Collapsable);
