import styled from "styled-components";

export const RightHandTitleLink = styled.a`
    color: ${({ theme, isSigninPage }) => isSigninPage ? theme.white : theme.coolBlack};
    font-size: ${({ theme}) => theme.mobileDevice ? theme.fonts.XXXS : theme.fonts.XXS};
    line-height: ${({ theme}) => theme.fonts.XS};
    font-weight: ${({ theme}) => theme.fontWeights.bold};
    text-decoration: none;
    display: block;
    margin-bottom: ${({ theme}) => theme.spacing[4]};
    flex-basis: ${({ isSigninPage }) => isSigninPage ? "33%" : null};
    padding-top: 0.15rem;

    @media (min-width: 768px) and (max-width: 1450px) {
        flex-basis: ${({ isSigninPage }) => isSigninPage ? "50%" : null};
    }​
`

export const Copyright = styled.span`
    display: block;
    color: ${({ isSigninPage }) => isSigninPage ? "#fafafa7d" : "grey"};
    font-size: ${({ theme, isSigninPage}) => isSigninPage ? "10px" : theme.fonts.XXXS};
    // width: ${({ theme, isSigninPage}) => isSigninPage ? null : "80%"};
    text-align: left;
    line-height: ${({ theme}) => theme.lineHeights.XS};
`

export const AppLink = styled.a`
    :first-child {
        margin-right: ${({ theme, isSigninPage }) => isSigninPage ? theme.spacing[6] : theme.spacing[5]};
    }

    svg {
        transition: all 0.3s ease-in-out;
        ${({ isSigninPage }) => isSigninPage && "filter: invert(1);"}
    }
    :hover {
        svg {
            filter:invert(1);
        }
    }

    @media (max-width: 1400px) {
        svg {
            width: ${({ theme, isSigninPage }) => !isSigninPage && "100px"};
        }
    }
`