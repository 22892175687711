import styled from "styled-components";
import { Box } from "rebass";
import { scrollBarStyles } from "../ScrollBar";
import { Title } from "../Text";

export const MobileWrapper = styled(Box)`
transition: all .3s ease-in-out;
height: 100%;
width: 100%;
position: fixed;
top: 0;
right: 0;
z-index: 10002;
padding: 20px;
padding-top: 0;
background-color: #fff;
overflow-y: auto;
transform: ${({ open }) => !open ? "translateY(105%)" : "translateY(0)"};
${scrollBarStyles};
`
export const ContactFormTitle = styled(Title)`
    color: ${props => (props.theme.coolBlack)};
    display: flex;
    justify-content: "start";
    align-items: "center";
    font-size: ${({ theme }) => theme.mobileDevice ? "16px" : "20px"};})}
`

export const ContactFormTitleContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: ${props => !props.theme.mobileDevice && props.theme.darkGray};
    margin-top: ${props => props.theme.mobileDevice ? "0px" : "20px"};
    margin-left: ${props => props.theme.mobileDevice ? "0px" : "88px"};
    margin-bottom:  16px;
    position: ${props => props.theme.mobileDevice ? "sticky" : ""};
    top: ${props => props.theme.mobileDevice ? "0" : ""};
    background-color: ${props => props.theme.mobileDevice ? "#fff" : ""};
    padding-top: ${props => props.theme.mobileDevice ? "20px" : ""};
    padding-bottom: ${props => props.theme.mobileDevice ? "20px" : ""};
    z-index: 1;
    
`

export const FormContainer = styled(Box)`
padding: 20px;
max-height: 560px;
overflow-y: auto;
padding-top: 0;
${scrollBarStyles}
`

export const InputLabel = styled.div`
    font-size: ${props => props.theme.fonts['XS']};
    margin-bottom: 8px;
`

export const HelpText = styled.div`
    color: #929292;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 16px 0;
`;