import React, { Component } from "react";
import { Box, Flex, Text } from "rebass";
import MaterilInput from "../Inputs/Material";
import NumberFormat from "react-number-format";
import { Icon } from "../Icon";
import { withTheme } from "styled-components";
import { Title, Description } from "../Text";
import Spinner from "../../../../../../assets/svg/Spinner";
import SquareButton2 from "../Buttons/SquareButton2";
import SquareOutlinedButton from "../Buttons/SquareOutlinedButton";

const initialState = {
    code: ""
};

class VerifyPhoneNumberUI extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.myRef = React.createRef();
    }

    componentDidMount() {
       
        this.myRef.current.focus();
       
    }

    componentWillUnmount() {
        this.myRef.current.value = "";
    }

    click = code => {
        if (this.props.exec) this.props.exec(code);
    };

    onChange = values => {
        const { value } = values;

        this.setState({
            code: value
        });
        if (value && value.toString().length === 6) {
            this.click(value);
        }
    };
    close = () => {
        this.setState({
            code: ""
        });
        if (this.props.close) this.props.close();
    };
    resend = () => {
        if (this.props.resendCode) this.props.resendCode();
    };
    render() {
        const {
            theme,
            title,
            subheader,
            loading,
            success,
            resendCode,
            hideSkipVerification
        } = this.props;
        const { code } = this.state;
        return (
            <Flex
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="flex-start"
                flexDirection="column"
                width="100%"
                p={["2em"]}
            >
                <Title style={{ marginBottom: "1em" }}>
                    {title ? title : "Confirm it's you"}
                </Title>
                <Description style={{ marginBottom: "1.5em" }}>
                    {subheader
                        ? subheader
                        : "To continue, please enter the verification code we’ve just sent you. This helps us protect your account."}
                </Description>
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    width={1}
                >
                    <Box width={"6em"}>
                        <NumberFormat
                            /*   ref={inst =>
                                (this.myRef = ReactDOM.findDOMNode(inst))
                            } */

                            disabled={loading}
                            bg="#fff"
                            format="######"
                            placeholder="Code"
                            name="code"
                            value={code}
                            onValueChange={this.onChange}
                            color={theme.coolBlack}
                            customInput={props => (
                                <MaterilInput
                                    innerRef={this.myRef}
                                    {...props}
                                    autoFocus={true}
                                />
                            )}
                            inputStyles={{
                                color: theme.coolBlack,
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "20px",
                                letterSpacing: "0.5px"
                            }}
                        />
                    </Box>
                    <Box mt={[theme.spacing[5]]}>
                        {success === true && (
                            <Icon
                                icon="check-filled"
                                color={theme.greenMedium}
                            />
                        )}
                        {success === false && (
                            <Icon
                                icon="times"
                                color={theme.redError}
                            />
                        )}
                        {loading && (
                            <Spinner
                                color={theme.blueMedium}
                            />
                        )}
                    </Box>
                </Flex>
                <Flex
                    justifyContent="space-between"
                    alignItems="flex-end"
                    flexDirection="row"
                    width={1}
                    mt={"1em"}
                >
                    <Box>
                        <SquareButton2
                            text="Resend"
                            bg={theme.blueMedium}
                            onClick={loading ? () => {} : resendCode}
                            disabled={loading}
                            small
                        />
                    </Box>
                    {!hideSkipVerification && (
                        <Box>
                            <SquareOutlinedButton
                                text="Skip verification"
                                onClick={loading ? () => {} : this.close}
                                disabled={loading}
                                small
                            />
                        </Box>
                    )}
                </Flex>
            </Flex>
        );
    }
}

export default withTheme(VerifyPhoneNumberUI);
