import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { StepWrapper, Button, selectStyle, ButtonContainer } from "../../components";
import { Box, Flex } from "rebass"
import CollapsableSection from "../../../../atoms/shared/CollapsableSection";
import ReactTooltip from "react-tooltip";
import * as yup from 'yup'
import { Formik, useFormikContext } from 'formik';
import Profession from "./_Profession";
import Location from "./_Location";
import Target from "./_Target";
import Description from "./_Description";
import { STATE_TYPES } from "../../../../../../../assets/strings";
import { targetToNotifyOptions } from '../../constants';
import { DraftModal, useDraft } from "../../useDraft";

const formSchema = (opMethod) => yup.object().shape({
    profession: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
        global: yup.boolean().required(),
    })
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).nullable()
        })
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === false,
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).required()
        }),
    cannotFindProfession: yup.boolean(),
    professionDescription: yup.string().nullable()
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.string().required()
        }),
    address: yup.object().nullable()
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
            then: yup.object().nullable()
        })
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
            then: yup.object({
                description: yup.string().required(),
                lat: yup.number().required(),
                lng: yup.number().required(),
            }).required()
        }),
    locationIsRemote: yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
    }).required(),
    description: yup.string().min(opMethod === 'DIRECT' ? 31 : 130).max(2000).required(),
});

const formInitialValues = {
    profession: null,
    address: '',
    description: '',
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Listener = ({ saveStepData }) => {
    const { values } = useFormikContext();

    useEffect(() => {
        saveStepData({ ...formInitialValues, ...values });
    }, [values])

    return null
}

const RecommendationInformation = ({ setStepData, stepData, saveStepData, professions, theme, t, stepKey , candidates }) => {
    const [professionOptions, setProfessionOptions] = useState([]);
    const { showModal, setShowModal, modalMessage, saveDraft } = useDraft(stepKey, stepData);
    const opMethod = candidates.length === 'DIRECT' ? 'DIRECT' : 'NON-DIRECT';
    const formSchemaBuilder = formSchema(opMethod)
    useEffect(() => {
        if (professions !== [] && professions.length) {
            setProfessionOptions(professions.map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);

    const onSubmitHandler = (values) => {
        setStepData(values);
    }

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={stepData ? stepData : formInitialValues}
                onSubmit={(values) => onSubmitHandler(values)}
                validationSchema={formSchema}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    /* and other goodies */
                }) => (
                    <Container>
                        <Listener saveStepData={saveStepData} stepData={stepData} />
                        <Profession
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                            stepData={stepData}
                        />

                        {/* I comment this because it's seems not be nescesary to get this section */}
                        {/* {values.profession && values.profession.global && (
                            <Target
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                            />
                        )} */}

                        <Location
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            stepData={stepData}
                            t={t}
                        />

                        <Description
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            stepData={stepData}
                            candidates={candidates}
                            t={t}
                        />

                        <ButtonContainer>
                        {isValid && <Box mr={16}>
                                <Button
                                    width="120px"
                                    onClick={() => saveDraft(true)}
                                >
                                    Save Draft
                                </Button>
                            </Box>}
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default RecommendationInformation;
