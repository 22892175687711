import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * useOnChangeRoute is a hook that executes a function whenever the route changes.
 * @param {function} callback - A function to execute when the route changes.
 */
const useOnChangeRoute = callback => {
    const location = useLocation();

    useEffect(() => {
        return callback();
    }, [location]);
};

export default useOnChangeRoute;
