import styled from "styled-components";
import { Flex } from "rebass";

export const Container = styled(Flex)`
    cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer' };
`;
export const CheckContainer = styled.span`
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 0;
    &:hover {
    }
`;
