import React, { Component } from "react";
import CompanyName from "../../../../../assets/svg/NuOpRectangleTransparent.svg";
import NotificationIcon from "../../../../../assets/svg/NotificationIcon";
import ChatIcon from "../../../../../assets/svg/ChatIcon";
import { Flex, Box } from "rebass";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    setNewBellNotification,
    toogleAvatarDropdown
} from "../../../../../redux/actions/creators/navbar";
import { signOut } from "../../../../../redux/actions/creators/user";
import { accept } from "../../../../../redux/actions/creators/relations";
import { goToUser } from "../../../../../redux/actions/creators/navigation";
import {
    currentSelectedTab,
    currentCreateDropdownOption
} from "../../../../../redux/actions/creators/navbar";
import Avatar from "../../../../ui/presentation/atoms/shared/AvatarCircle2";
import { TopbarContainer } from "./desktopNavBar-styles";
import SearchBar from "../../../../ui/presentation/atoms/shared/SearchBar/SearchBar";
import TopNavbar from "../../../../ui/presentation/atoms/shared/TopNavbar";
import { withRouter } from "react-router-dom";
import { toggleSidebar } from "../../../../../redux/actions/creators/navigation";
import { Link } from "react-router-dom";
import LayoutBusiness from "../../../../../business/global";
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import { withTheme } from "styled-components";
import {
    toggleSearchBar,
    clearSearch
} from "../../../../../redux/actions/creators/search";
import {
    AvatarContainer,
    NotificationContainer,
    SearchBox
} from "./desktopNavBar-styles";
import AvatarDropdown from "./AvatarDropDown";
import { settingsSelectSection } from "../../../../../redux/actions/creators/settings";
import { INVITES } from "../../../../ui/presentation/views/Settings/constants";
import UserBusiness from "../../../../../business/user";
import NotificationsBar from "./NotificationsBar";
import Tooltip from "../../../../ui/presentation/atoms/shared/Tooltip";
import PopupModal from "../../../../ui/presentation/atoms/shared/PopupBlurModal";
import SendInvite from "../../../../ui/presentation/views/Settings/Invites/SendInvite";
import { CreateEndorsementModal } from "../../../../ui/presentation/atoms/shared/EndorsementModal";
import { CreateGratitudeModal } from "../../../../ui/presentation/atoms/shared/GratitudeModal";
import { NotificationIconWithBaggeCount } from "../NotificationIcon/view";
import { CustomBellProvider } from "../../../../context/useKnockProvider";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import { knockEnv } from "../../../../../config";

export const TOPBAR_HEIGHT = "4.75rem";
export const TOPBAR_MAX_WIDTH = 1168;

const initialState = {
    profileMenuOpen: false,
    notificationMenuOpen: false,
    inviteAndEarnModalOpen: false,
    endorsementModalOpen: false,
    gratitudeModalOpen: false,
};

class DesktopNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.myRef = React.createRef();
        this.myRef2 = React.createRef();
    }

    componentDidMount = () => {
        this.setInitialCurrentTab(this.props.location.pathname);
    };

    componentDidUpdate = prevProps => {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setInitialCurrentTab(this.props.location.pathname);
            // Reset create dropdown options from selected to none
            // If there is no dropdown option selected, then we reset it to none
            if (
                !this.props.createDropdownOptions.find(
                    ({ url }) => url === this.props.location.pathname
                )
            ) {
                this.props.currentCreateDropdownOption();
            }
        }
    };

    handleClickOutside = event => {
        if (
            this.myRef &&
            !this.myRef.current.contains(event.target) &&
            this.myRef2 &&
            this.myRef2.current &&
            !this.myRef2.current.contains(event.target)
        ) {
            this.props.toggleSearchBar(false);
        }
    };

    setInitialCurrentTab = pathname => {
        const newSelectedTabOption = LayoutBusiness.getCurrentTab(
            this.props.tabsOptionsDesktop,
            pathname,
            this.props.meUid
        );

        this.props.currentSelectedTab(newSelectedTabOption);
    };
    onClickProfile = () => {
        const profileMenuOpen = !this.state.profileMenuOpen;
        this.setState({ profileMenuOpen: profileMenuOpen });
    };

    onClickNotification = () => {
        this.props.toggleSidebar();
        const notificationMenuOpen = !this.state.notificationMenuOpen;
        this.props.setNewBellNotification(this.props.meUid, false);
        this.setState({ notificationMenuOpen: notificationMenuOpen });
    };

    onClickTab = tab => {
        if (tab.text) {
            this.props.currentSelectedTab(tab.text);
            this.props.currentCreateDropdownOption(tab.text);
            let url = tab.url;
            if (url === "/profile") {
                url = `${url}/${this.props.user && this.props.user.userName}`;
                this.props.history.push(url);
            }
            else if (url === "/send-invitation") this.setState({ inviteAndEarnModalOpen: true });
            else if (url === "/send-endorsement") this.setState({ endorsementModalOpen: true });
            else if (url === "/send-gratitude") this.setState({ gratitudeModalOpen: true });
            else {
                this.props.history.push(url);
            }
        } else {
            this.props.history.push("/home");
        }
    };

    handleClickChat = () => {
        this.props.history.push("/chat");
    };

    // toggleSearchBar = () => {
    //     const open = !this.props.searchBarOpen;
    //     if (!open) {
    //         this.props.clearSearch();
    //     }
    //     this.props.toggleSearchBar(open);
    // };

    // goToSearch = () => {
    //     this.props.history.push("/search");
    // }

    toogleAvatarDropdown = () => {
        const open = !this.props.avatarDropDownOpen;
        this.props.toogleAvatarDropdown(open);
    };

    signOut = () => {
        this.props.signOut();
        this.props.toogleAvatarDropdown(false);
    };

    onClickInvitesIcon = () => {
        this.props.settingsSelectSection(INVITES);
        this.props.history.push("/settings");
    };

    render() {
        const {
            user,
            avatarDropDownOpen,
            searchBarOpen,
            notificationsOpen,
            turnOnBell,
            chatNewMessages,
            userTypeValidForSendReferrals,
            userTypeValidForWriteArticles,
            notifications
        } = this.props;
        const {
            inviteAndEarnModalOpen,
            endorsementModalOpen,
            gratitudeModalOpen
        } = this.state;
        const chatHaveNewMessages = chatNewMessages && Object.values(chatNewMessages).length > 0
        const { pathname } = this.props.history.location;

        return (
            <TopbarContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ width: "100%" }}
                ref={this.myRef}
            >
                {/*------------ Create options popups ------------*/}
                <PopupModal
                    open={inviteAndEarnModalOpen}
                    width={[0.8, 0.8, 0.5, 0.4]}
                    handleClose={() =>
                        this.setState({ inviteAndEarnModalOpen: false })
                    }
                >
                    <Box p={["2.5em 2em"]}>
                        <SendInvite isModalInvite />
                    </Box>
                </PopupModal>
                <CreateEndorsementModal open={endorsementModalOpen} handleClose={() => this.setState({ endorsementModalOpen: false })} />
                <CreateGratitudeModal open={gratitudeModalOpen} handleClose={() => this.setState({ gratitudeModalOpen: false })} />
                {/*------------ End Create options popups ------------*/}
                <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                        height: TOPBAR_HEIGHT,
                        width: "100%",
                        maxWidth: TOPBAR_MAX_WIDTH,
                        boxSizing: "border-box"
                    }}
                >
                    <Link
                        to="/home"
                        onClick={() => this.props.currentSelectedTab("Home")}
                    >
                        <Flex
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <img alt="NuOp" src={CompanyName} width={"100px"} />
                        </Flex>
                    </Link>
                    <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={1}
                        style={{ height: "100%" }}
                    >
                        {
                            <Box
                                // width={[0.5]}
                                style={{ height: "100%", minWidth: "50%" }}
                            >
                                <TopNavbar
                                    userType={user && user.type}
                                    height={"100%"}
                                    items={this.props.tabsOptionsDesktop}
                                    createOptions={
                                        this.props.createDropdownOptions
                                    }
                                    onClick={this.onClickTab}
                                    userTypeValidForSendReferrals={
                                        userTypeValidForSendReferrals
                                    }
                                    userTypeValidForWriteArticles={
                                        userTypeValidForWriteArticles
                                    }
                                />
                            </Box>
                        }
                        {/* {searchBarOpen && ( */}
                            <SearchBar />
                        {/* )} */}
                    </Flex>
                    <Flex
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        minWidth={"17.2%"}
                    >
                        {/* <Tooltip text="Search" noShow={searchBarOpen}>
                            <Icon
                                icon="search"
                                // onClick={this.toggleSearchBar}
                                onClick={this.goToSearch}
                                style={{
                                    cursor: "pointer",
                                    marginRight: 20,
                                    // visibility: !searchBarOpen
                                    //     ? "visible"
                                    //     : "hidden"
                                }}
                                width={20}
                                height={20}
                                color={this.props.theme.darkGray}
                                className="navbar-search-icon"
                            />
                        </Tooltip> */}

                        {user && (
                             <KnockFeedProvider
                             apiKey={knockEnv.knockPublicApiKey}
                             feedId={knockEnv.feedChannelId}
                             userId={this.props.meUid}
                            >
                            <NotificationContainer>
                                <Tooltip
                                    text={`${
                                        notifications.filter(
                                            ({ read }) => !read
                                        ).length
                                    } New Notifications`}
                                    noShow={searchBarOpen}
                                >
                                   {/* <NotificationIcon
                                        id="notification-icon"
                                        onMouseDown={this.onClickNotification}
                                        color={this.props.theme.darkGray}
                                        enabled={turnOnBell}
                                    />   */}
                                   
                                    <NotificationIconWithBaggeCount
                                       
                                       onMouseDown={this.onClickNotification}
                                       color={this.props.theme.darkGray}
                                       notificationsOpen={notificationsOpen}
                                    
                                   />
                             
                                    {notificationsOpen && (
                                        <NotificationsBar
                                            handleClickOutside={
                                                this.onClickNotification
                                            }
                                            notificationsOpen={
                                                notificationsOpen
                                            }
                                        />
                                    )}
                                </Tooltip>
                            </NotificationContainer>
                            </KnockFeedProvider>
                        )}
                        {/* <Flex
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ cursor: "pointer" }}
                            onClick={this.onClickInvitesIcon}
                            pr={20}
                        >
                            <MoneyIcon color={this.props.theme.blueMedium} />
                        </Flex> */}
                        <Flex
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            style={{ cursor: "pointer" }}
                            pr={4}
                        >
                            <ChatIcon
                                width="25px"
                                height="25px"
                                color={
                                    pathname.startsWith("/chat")
                                        ? this.props.theme.blueMedium
                                        : this.props.theme.darkGray
                                }
                                enabled={
                                    !pathname.startsWith("/chat") && chatHaveNewMessages
                                }
                                onClick={this.handleClickChat}
                            />
                        </Flex>
                        {user && (
                            <Tooltip
                                stylesTooltip={{ width: "150%" }}
                                noShow={avatarDropDownOpen}
                                text={
                                    <>
                                        {user.company_info
                                            ? user.company_info.name
                                            : ""}{" "}
                                        <br />
                                        {`${user.name} ${user.lastname}`} <br />
                                        {user.subscription
                                            ? user.subscription.plan
                                            : ""}
                                    </>
                                }
                            >
                                <AvatarContainer id="navbar-avatar-menu-button">
                                    <Avatar
                                        src={user.avatar}
                                        size={"42px"}
                                        onMouseDown={this.toogleAvatarDropdown}
                                        verified={UserBusiness.isPro(user)}
                                    />
                                    <div
                                        style={{ marginLeft: ".3em" }}
                                        onMouseDown={this.toogleAvatarDropdown}
                                    >
                                        <Icon
                                            icon="chevron-down"
                                            width={10}
                                            height={6}
                                            color={this.props.theme.darkGray}
                                        />
                                    </div>

                                    {avatarDropDownOpen && (
                                        <AvatarDropdown
                                            user={user}
                                            signOut={this.signOut}
                                            toogleAvatarDropdown={
                                                this.toogleAvatarDropdown
                                            }
                                        />
                                    )}
                                </AvatarContainer>
                            </Tooltip>
                        )}
                    </Flex>
                </Flex>
            </TopbarContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        searchBarOpen: state.main.ui.global.searchBar.isOpen,
        searchBarIsLoading:
            state.main.ui.global.searchBar.isLoading ||
            state.main.ui.global.searchBar.isLocationLoading,
        isLoading: state.main.ui.view.profile.accept.loading,
        notificationMenuTouched: state.main.ui.global.notifications.touched,
        meUid:
            state.main.user && state.main.user.uid ? state.main.user.uid : null,
        user:
            state.main.user && state.main.user.profile
                ? state.main.user.profile
                : {},
        tabsOptionsDesktop: state.main.ui.global.navbar.tabsOptionsDesktop,
        createDropdownOptions:
            state.main.ui.global.navbar.createDropdownOptions,
        avatarDropDownOpen: state.main.ui.global.navbar.avatarDropDownOpen,
        notificationsOpen: state.main.ui.global.notifications.open,
        turnOnBell: state.main.ui.global.notifications.turnOnBell,
        chatNewMessages: state.main.ui.global.navbar.chatNewMessages,
        userTypeValidForSendReferrals:
            state.main.user && state.main.user.userTypeValidForSendReferrals,
        userTypeValidForWriteArticles:
            state.main.user && state.main.user.userTypeValidForWriteArticles,
        notifications: state.main.ui.global.notifications.data
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            settingsSelectSection,
            toggleSearchBar,
            toggleSidebar,
            signOut,
            accept,
            goToUser,
            setNewBellNotification,
            currentSelectedTab,
            currentCreateDropdownOption,
            clearSearch,
            toogleAvatarDropdown
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTheme(DesktopNavBar))
);
