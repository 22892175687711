import React, { useState, useEffect } from 'react'
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";
import { Flex } from "rebass";
import { selectStyle } from "../../../components";
import Select from "react-select";
import ReferralBusiness from '../../../../../../../../business/referral';
import { InputContainer, InputLabel, Input } from '../../../../Settings/common/Components';
import { Text } from '../../../../../atoms/shared/Text';
import { PROPERTY_TYPES, } from "../../../../../../../../assets/strings";
import {
    PROPERTY_SUBTYPES,
    retailSpaceOptions,
    creditOptions,
    PetsOptions,
    LotSizeOptions,
    HomeSaleContingencyOptions,
    UnitsNumberOptions,
} from "../../../constants"
import RealEstateTypeField from './Fields/_RealStateTypeField';
import PriceField from './Fields/_PriceField';
import { MaxPriceFieldSelector } from './Fields/constants'
import ResidentialSingleFamily from './Fields/_ResidentialSingleFamily';

const RealStateDetails = (props) => {
    const [showInputs, setShowInputs] = useState({});
    const {
        theme,
        formValues,
        clientTypes,
        propertyTypes,
        financingInfoTypes,
        onChange,
        errors,
    } = props;

    useEffect(() => {
        const ctKeys = clientTypes ? clientTypes.optionsKeys : {}
        const showPropertySubType = !!(formValues.clientType && formValues.propertyType);
        const showRestOfForm = !!(showPropertySubType && formValues.propertySubType);

        const showBuyerResidentialSingleFamily = ReferralBusiness.showBuyerResidentialSingleFamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showBuyerResidentialCondoApartment = ReferralBusiness.showBuyerResidentialCondoApartment(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showBuyerResidentialMultifamily = ReferralBusiness.showBuyerResidentialMultifamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showBuyerLand = ReferralBusiness.showBuyerLand(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showBuyerCommercial  = ReferralBusiness.showBuyerCommercial(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showTenantResidentialSingleFamily = ReferralBusiness.showTenantResidentialSingleFamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showTenantResidentialCondoApartment = ReferralBusiness.showTenantResidentialCondoApartment(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showTenantLand = ReferralBusiness.showTenantLand(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showTenantCommercial = ReferralBusiness.showTenantCommercial(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showLanlordResidentialSingleFamily = ReferralBusiness.showLanlordResidentialSingleFamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showLanlordResidentialCondoApartment = ReferralBusiness.showLanlordResidentialCondoApartment(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showLanlordLand = ReferralBusiness.showLanlordLand(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showLanlordCommercial = ReferralBusiness.showLanlordCommercial(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showSellerResidentialSingleFamily = ReferralBusiness.showSellerResidentialSingleFamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showSellerCondoApartment = ReferralBusiness.showSellerCondoApartment(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showSellerResidentialMultifamily = ReferralBusiness.showSellerResidentialMultifamily(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showSellerLand = ReferralBusiness.showSellerLand(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        const showSellerCommercial = ReferralBusiness.showSellerCommercial(
            formValues['propertyType'] && formValues['propertyType'].value,
            formValues['clientType'] && formValues['clientType'].value,
            formValues['propertySubType'] && formValues['propertySubType'].value,
            ctKeys,
            PROPERTY_TYPES,
            PROPERTY_SUBTYPES,
        )

        setShowInputs({
            showPropertySubType,
            showRestOfForm,
            showBuyerResidentialSingleFamily,
            showBuyerResidentialCondoApartment,
            showBuyerResidentialMultifamily,
            showBuyerLand,
            showBuyerCommercial,
            showTenantCommercial,
            showTenantLand,
            showTenantResidentialSingleFamily,
            showTenantResidentialCondoApartment,
            showLanlordResidentialSingleFamily,
            showLanlordResidentialCondoApartment,
            showLanlordLand,
            showLanlordCommercial,
            showSellerResidentialSingleFamily,
            showSellerCondoApartment,
            showSellerResidentialMultifamily,
            showSellerLand,
            showSellerCommercial,
        });
    }, [JSON.stringify(formValues), clientTypes, propertyTypes]);

    const renderRetailSpace = () =>
        retailSpaceOptions.map(({ value, label }, index) => (
            <Flex mr={index === 0 && theme.spacing[6]}>
                <CheckBox
                    round
                    inverted
                    label={label}
                    onChange={ev =>
                        onChange({
                            target: {
                                name: "retailSpace",
                                value: { value, label }
                            }
                        })
                    }
                    checkBoxColor={theme.blueMedium}
                    checked={value === formValues.retailSpace.value}
                />
            </Flex>
        ));

    return (
        <>
            <RealEstateTypeField formValues={formValues} onChange={onChange} errors={errors} theme={theme} />
            <Flex flexDirection={"column"}>
                {
                    // BUYER - RESIDENTIAL - SINGLE FAMILY
                    showInputs.showRestOfForm && showInputs.showBuyerResidentialSingleFamily && (
                        <ResidentialSingleFamily
                            theme={theme}
                            formValues={formValues}
                            errors={errors}
                            onChange={onChange}
                        />
                    )
                }
                {
                    // BUYER - RESIDENTIAL - CONDO/APARTMENT
                    showInputs.showRestOfForm && showInputs.showBuyerResidentialCondoApartment && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minBeds"
                                        placeholder={"Min Beds"}
                                        required
                                        value={formValues.minBeds}
                                        onChange={onChange}
                                        error={!!errors.minBeds}
                                    />
                                    <InputLabel>Min Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minSquareFeet"
                                        placeholder={"Min SqFt"}
                                        required
                                        value={formValues.minSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.minSquareFeet}
                                    />
                                    <InputLabel>Min SqFt</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.financingInfo && formValues.financingInfo.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={financingInfoTypes.options}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "financingInfo",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="financingInfo"
                                        placeholder="Financing Info*"
                                        value={formValues.financingInfo}
                                        error={!!errors.financingInfo}
                                    />
                                    <InputLabel>Financing Info*</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.homeSaleContingency && formValues.homeSaleContingency.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={HomeSaleContingencyOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "homeSaleContingency",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="homeSaleContingency"
                                        placeholder="Home Sale Contingency*"
                                        value={formValues.homeSaleContingency}
                                        error={!!errors.homeSaleContingency}
                                    />
                                    <InputLabel>Home Sale Contingency*</InputLabel>
                                </InputContainer>
                            </Flex>
                        </>
                    )

                }
                {
                    // BUYER - RESIDENTIAL - MULTIFAMILY 2-4
                    showInputs.showRestOfForm && showInputs.showBuyerResidentialMultifamily && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price*'
                                />
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.minUnits && formValues.minUnits.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={UnitsNumberOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "minUnits",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="minUnits"
                                        placeholder="Units Min*"
                                        value={formValues.minUnits}
                                        error={!!errors.minUnits}
                                    />
                                    <InputLabel>Units Min*</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.financingInfo && formValues.financingInfo.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={financingInfoTypes.options}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "financingInfo",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="financingInfo"
                                        placeholder="Financing Info*"
                                        value={formValues.financingInfo}
                                        error={!!errors.financingInfo}
                                    />
                                    <InputLabel>Financing Info*</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.homeSaleContingency && formValues.homeSaleContingency.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={HomeSaleContingencyOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "homeSaleContingency",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="homeSaleContingency"
                                        placeholder="Home Sale Contingency*"
                                        value={formValues.homeSaleContingency}
                                        error={!!errors.homeSaleContingency}
                                    />
                                    <InputLabel>Home Sale Contingency*</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // BUYER - LAND
                    showInputs.showRestOfForm && showInputs.showBuyerLand && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                        style={{ width: "calc(50% - 1em)" }}
                                        triggerShowLabel={
                                            formValues.minLotSize && formValues.minLotSize.value
                                                ? "show"
                                                : "hide"
                                        }
                                        showLabelOnValue
                                    >
                                        <Select
                                            options={LotSizeOptions}
                                            isClearable
                                            onChange={option =>
                                                onChange({
                                                    target: {
                                                        name: "minLotSize",
                                                        value: option,
                                                    }
                                                })
                                            }
                                            styles={selectStyle}
                                            name="minLotSize"
                                            placeholder="Min Lot Size*"
                                            value={formValues.minLotSize}
                                            error={!!errors.minLotSize}
                                        />
                                        <InputLabel>Min Lot Size*</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.financingInfo && formValues.financingInfo.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={financingInfoTypes.options}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "financingInfo",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="financingInfo"
                                        placeholder="Financing Info*"
                                        value={formValues.financingInfo}
                                        error={!!errors.financingInfo}
                                    />
                                    <InputLabel>Financing Info*</InputLabel>
                                </InputContainer>
                                
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    showInputs.showRestOfForm && showInputs.showBuyerCommercial && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="minSquareFeet"
                                        placeholder={"Min SqFt"}
                                        required
                                        value={formValues.minSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.minSquareFeet}
                                    />
                                    <InputLabel>Min SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="maxSquareFeet"
                                        placeholder={"Max SqFt"}
                                        required
                                        value={formValues.maxSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.maxSquareFeet}
                                    />
                                    <InputLabel>Max SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                        style={{ width: "calc(25% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="minUnits"
                                        placeholder={"Units Min"}
                                        required
                                        value={formValues.minUnits}
                                        onChange={onChange}
                                        error={!!errors.minUnits}
                                    />
                                    <InputLabel>Units Min</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="maxUnits"
                                        placeholder={"Units Max"}
                                        required
                                        value={formValues.maxUnits}
                                        onChange={onChange}
                                        error={!!errors.maxUnits}
                                    />
                                    <InputLabel>Units Max</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]} width={"calc(51% - 1em)"} >
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.financingInfo && formValues.financingInfo.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={financingInfoTypes.options}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "financingInfo",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="financingInfo"
                                        placeholder="Financing Info*"
                                        value={formValues.financingInfo}
                                        error={!!errors.financingInfo}
                                    />
                                    <InputLabel>Financing Info*</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // TENANT - RESIDENTIAL - SINGLEFAMILY
                    showInputs.showRestOfForm && showInputs.showTenantResidentialSingleFamily && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price / Mo*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minBeds"
                                        placeholder={"Min Beds"}
                                        required
                                        value={formValues.minBeds}
                                        onChange={onChange}
                                        error={!!errors.minBeds}
                                    />
                                    <InputLabel>Min Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minSquareFeet"
                                        placeholder={"Min SqFt"}
                                        required
                                        value={formValues.minSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.minSquareFeet}
                                    />
                                    <InputLabel>Min SqFt</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                        style={{ width: "calc(51% - 1em)" }}
                                        triggerShowLabel={
                                            formValues.minLotSize && formValues.minLotSize.value
                                                ? "show"
                                                : "hide"
                                        }
                                        showLabelOnValue
                                    >
                                        <Select
                                            options={LotSizeOptions}
                                            isClearable
                                            onChange={option =>
                                                onChange({
                                                    target: {
                                                        name: "minLotSize",
                                                        value: option,
                                                    }
                                                })
                                            }
                                            styles={selectStyle}
                                            name="minLotSize"
                                            placeholder="Min Lot Size"
                                            value={formValues.minLotSize}
                                            error={!!errors.minLotSize}
                                        />
                                        <InputLabel>Min Lot Size</InputLabel>
                                </InputContainer>
                                <Flex mt={theme.spacing[3]} width={"calc(51% - 1em)"} style={{ marginLeft: '1em'}}>
                                    <InputContainer
                                        style={{ width: "calc(51% - 1em)" }}
                                        triggerShowLabel={
                                            formValues.credit && formValues.credit.value ? "show" : "hide"
                                        }
                                        showLabelOnValue
                                    >
                                        <Select
                                            options={creditOptions}
                                            onChange={option =>
                                                onChange({
                                                    target: {
                                                        name: "credit",
                                                        value: option,
                                                    }
                                                })
                                            }
                                            styles={selectStyle}
                                            name="credit"
                                            placeholder="Credit*"
                                            value={formValues.credit}
                                            error={!!errors.credit}
                                        />
                                        <InputLabel>Credit*</InputLabel>
                                    </InputContainer>
                                    <InputContainer
                                        style={{ width: "calc(51% - 1em)" }}
                                        triggerShowLabel={
                                            formValues.pets && formValues.pets.value ? "show" : "hide"
                                        }
                                        showLabelOnValue
                                    >
                                        <Select
                                            options={PetsOptions}
                                            onChange={option =>
                                                onChange({
                                                    target: {
                                                        name: "pets",
                                                        value: option,
                                                    }
                                                })
                                            }
                                            styles={selectStyle}
                                            name="pets"
                                            placeholder="Pets*"
                                            value={formValues.pets}
                                            error={!!errors.pets}
                                        />
                                        <InputLabel>Pets*</InputLabel>
                                    </InputContainer>
                                </Flex>
                            </Flex>
                        </>
                    )
                }
                {
                    // TENANT - RESIDENTIAL - CONDO/APARTMENT
                    showInputs.showRestOfForm && showInputs.showTenantResidentialCondoApartment && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price / Mo*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minBeds"
                                        placeholder={"Min Beds"}
                                        required
                                        value={formValues.minBeds}
                                        onChange={onChange}
                                        error={!!errors.minBeds}
                                    />
                                    <InputLabel>Min Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minSquareFeet"
                                        placeholder={"Min SqFt"}
                                        required
                                        value={formValues.minSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.minSquareFeet}
                                    />
                                    <InputLabel>Min SqFt</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.credit && formValues.credit.value ? "show" : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={creditOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "credit",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="credit"
                                        placeholder="Credit*"
                                        value={formValues.credit}
                                        error={!!errors.credit}
                                    />
                                    <InputLabel>Credit*</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.pets && formValues.pets.value ? "show" : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={PetsOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "pets",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="pets"
                                        placeholder="Pets*"
                                        value={formValues.pets}
                                        error={!!errors.pets}
                                    />
                                    <InputLabel>Pets*</InputLabel>
                                </InputContainer>
                            </Flex>
                        </>   
                    )
                }
                {
                    // TENANT - LAND
                    showInputs.showRestOfForm && showInputs.showTenantLand && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer showLabelOnValue style={{ width: "calc(51% - 1em)" }}>
                                    <Input
                                        type="text"
                                        name="maxPrice"
                                        placeholder={"Max Price / Mo*"}
                                        required
                                        value={formValues.maxPrice}
                                        onChange={onChange}
                                        error={!!errors.maxPrice}
                                    />
                                    <InputLabel>
                                        Max Price / Mo*
                                    </InputLabel>
                                </InputContainer>
                                <InputContainer style={{ width: "calc(51% - 1em)" }}
                                        triggerShowLabel={
                                            formValues.minLotSize && formValues.minLotSize.value
                                                ? "show"
                                                : "hide"
                                        }
                                        showLabelOnValue
                                    >
                                        <Select
                                            options={LotSizeOptions}
                                            isClearable
                                            onChange={option =>
                                                onChange({
                                                    target: {
                                                        name: "minLotSize",
                                                        value: option,
                                                    }
                                                })
                                            }
                                            styles={selectStyle}
                                            name="minLotSize"
                                            placeholder="Min Lot Size*"
                                            value={formValues.minLotSize}
                                            error={!!errors.minLotSize}
                                        />
                                        <InputLabel>Min Lot Size*</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // TENANT - COMMERCIAL
                    showInputs.showRestOfForm && showInputs.showTenantCommercial && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={MaxPriceFieldSelector}
                                    label='Max Price*'
                                />
                                
                                <InputContainer showLabelOnValue style={{ width: "calc(51% - 1em)" }}>
                                    <Flex mb={theme.spacing[2]}>
                                        { renderRetailSpace() }
                                    </Flex>
                                    <InputLabel />
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="text"
                                        name="minSquareFeet"
                                        placeholder={"Min SqFt"}
                                        required
                                        value={formValues.minSquareFeet}
                                        onChange={onChange}
                                        error={!!errors.minSquareFeet}
                                    />
                                    <InputLabel>Min SqFt</InputLabel>
                                </InputContainer>
                                <Flex mt={theme.spacing[3]} width={"calc(51% - 1em)"} style={{ marginLeft: '1em'}}>
                                    <InputContainer
                                        style={{ width: "calc(51% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="minUnits"
                                        placeholder={"Units Min"}
                                        required
                                        value={formValues.minUnits}
                                        onChange={onChange}
                                        error={!!errors.minUnits}
                                    />
                                    <InputLabel>Units Min</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="maxUnits"
                                        placeholder={"Units Max"}
                                        required
                                        value={formValues.maxUnits}
                                        onChange={onChange}
                                        error={!!errors.maxUnits}
                                    />
                                    <InputLabel>Units Max</InputLabel>
                                </InputContainer>
                                </Flex>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // LANLORD - RESIDENTIAL - SINGLE FAMILY
                    showInputs.showRestOfForm && showInputs.showLanlordResidentialSingleFamily && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="beds"
                                        placeholder={"Beds"}
                                        required
                                        value={formValues.beds}
                                        onChange={onChange}
                                        error={!!errors.beds}
                                    />
                                    <InputLabel>Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="baths"
                                        placeholder={"Baths"}
                                        required
                                        value={formValues.baths}
                                        onChange={onChange}
                                        error={!!errors.baths}
                                    />
                                    <InputLabel>Baths</InputLabel>
                                </InputContainer>
                                <InputContainer
                                        style={{ width: "calc(25% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="acres"
                                        placeholder={"Acres"}
                                        required
                                        value={formValues.acres}
                                        onChange={onChange}
                                        error={!!errors.acres}
                                    />
                                    <InputLabel>Acres</InputLabel>
                                </InputContainer>
                            </Flex>
                        </>
                    )
                }
                {
                    // LANLORD - RESIDENTIAL - CONDO/APARTMENT
                    showInputs.showRestOfForm && showInputs.showLanlordResidentialCondoApartment && (
                      <>
                        <Flex mt={theme.spacing[3]}>
                            <PriceField
                                width={1/2}
                                pr={2}
                                formValues={formValues}
                                onChange={onChange}
                                errors={errors}
                                fieldSelector={'estimatedPrice'}
                                label='Estimated Price*'
                            />
                        </Flex>
                        <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="beds"
                                        placeholder={"Beds"}
                                        required
                                        value={formValues.beds}
                                        onChange={onChange}
                                        error={!!errors.beds}
                                    />
                                    <InputLabel>Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="baths"
                                        placeholder={"Baths"}
                                        required
                                        value={formValues.baths}
                                        onChange={onChange}
                                        error={!!errors.baths}
                                    />
                                    <InputLabel>Baths</InputLabel>
                                </InputContainer>
                                <InputContainer
                                        style={{ width: "calc(25% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                </InputContainer>
                            </Flex>
                      </>  
                    )
                }
                {
                    // LANLORD - LAND
                    showInputs.showRestOfForm && showInputs.showLanlordLand && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="acres"
                                        placeholder={"Acres"}
                                        required
                                        value={formValues.acres}
                                        onChange={onChange}
                                        error={!!errors.acres}
                                    />
                                    <InputLabel>Acres</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // LANLORD - COMMERCIAL
                    showInputs.showRestOfForm && showInputs.showLanlordCommercial && (
                       <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                                
                                <InputContainer showLabelOnValue style={{ width: "calc(51% - 1em)" }}>
                                    <Flex mb={theme.spacing[2]}>
                                        { renderRetailSpace() }
                                    </Flex>
                                    <InputLabel />
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                        style={{ width: "calc(51% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="units"
                                        placeholder={"Units"}
                                        required
                                        value={formValues.units}
                                        onChange={onChange}
                                        error={!!errors.units}
                                    />
                                    <InputLabel>Units</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                       </> 
                    )
                }
                {
                    // SELLER - RESIDENTIAL - SINGLE FAMILY
                    showInputs.showRestOfForm && showInputs.showSellerResidentialSingleFamily && (
                        <>
                            <Flex p={`${theme.spacing[5]} 0`} flexDirection={"column"}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="beds"
                                        placeholder={"Beds"}
                                        required
                                        value={formValues.beds}
                                        onChange={onChange}
                                        error={!!errors.beds}
                                    />
                                    <InputLabel>Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="baths"
                                        placeholder={"Baths"}
                                        required
                                        value={formValues.baths}
                                        onChange={onChange}
                                        error={!!errors.baths}
                                    />
                                    <InputLabel>Baths</InputLabel>
                                </InputContainer>
                                <InputContainer
                                        style={{ width: "calc(25% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="acres"
                                        placeholder={"Acres"}
                                        required
                                        value={formValues.acres}
                                        onChange={onChange}
                                        error={!!errors.acres}
                                    />
                                    <InputLabel>Acres</InputLabel>
                                </InputContainer>
                            </Flex>
                        </>
                    )
                }
                {
                    // SELLER - RESIDENTIAL - MULTIFAMILY
                    showInputs.showRestOfForm && showInputs.showSellerResidentialMultifamily && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                                <InputContainer
                                    style={{ width: "calc(50% - 1em)" }}
                                    triggerShowLabel={
                                        formValues.units && formValues.units.value
                                            ? "show"
                                            : "hide"
                                    }
                                    showLabelOnValue
                                >
                                    <Select
                                        options={UnitsNumberOptions}
                                        onChange={option =>
                                            onChange({
                                                target: {
                                                    name: "units",
                                                    value: option,
                                                }
                                            })
                                        }
                                        styles={selectStyle}
                                        name="units"
                                        placeholder="Units*"
                                        value={formValues.units}
                                        error={!!errors.units}
                                    />
                                    <InputLabel>Units*</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // SELLER - RESIDENTIAL - CONDO APARTMENT
                    showInputs.showRestOfForm && showInputs.showSellerCondoApartment && (
                        <>
                        <Flex mt={theme.spacing[3]}>
                            <PriceField
                                width={1/2}
                                pr={2}
                                formValues={formValues}
                                onChange={onChange}
                                errors={errors}
                                fieldSelector={'estimatedPrice'}
                                label='Estimated Price*'
                            />
                        </Flex>
                        <Flex mt={theme.spacing[3]}>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="beds"
                                        placeholder={"Beds"}
                                        required
                                        value={formValues.beds}
                                        onChange={onChange}
                                        error={!!errors.beds}
                                    />
                                    <InputLabel>Beds</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        name="baths"
                                        placeholder={"Baths"}
                                        required
                                        value={formValues.baths}
                                        onChange={onChange}
                                        error={!!errors.baths}
                                    />
                                    <InputLabel>Baths</InputLabel>
                                </InputContainer>
                                <InputContainer
                                        style={{ width: "calc(25% - 1em)" }}
                                        showLabelOnValue
                                    >
                                    <Input
                                        type="number"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>SqFt</InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(25% - 1em)" }}
                                    showLabelOnValue
                                >
                                </InputContainer>
                            </Flex>
                        </>
                    )
                }
                {
                    // SELLER - LAND
                    showInputs.showRestOfForm && showInputs.showSellerLand && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="acres"
                                        placeholder={"Acres"}
                                        required
                                        value={formValues.acres}
                                        onChange={onChange}
                                        error={!!errors.acres}
                                    />
                                    <InputLabel>Acres</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
                {
                    // SELLER - COMMERCIAL
                    showInputs.showRestOfForm && showInputs.showSellerCommercial && (
                        <>
                            <Flex mt={theme.spacing[3]}>
                                <PriceField
                                    width={1/2}
                                    pr={2}
                                    formValues={formValues}
                                    onChange={onChange}
                                    errors={errors}
                                    fieldSelector={'estimatedPrice'}
                                    label='Estimated Price*'
                                />
                            </Flex>
                            <Flex mt={theme.spacing[3]}>
                                <InputContainer showLabelOnValue style={{ width: "calc(51% - 1em)" }}>
                                    <Input
                                        type="text"
                                        name="squareFeet"
                                        placeholder={"SqFt"}
                                        required
                                        value={formValues.squareFeet}
                                        onChange={onChange}
                                        error={!!errors.squareFeet}
                                    />
                                    <InputLabel>
                                        SqFt
                                    </InputLabel>
                                </InputContainer>
                                <InputContainer
                                    style={{ width: "calc(51% - 1em)" }}
                                    showLabelOnValue
                                >
                                    <Input
                                        type="number"
                                        name="units"
                                        placeholder={"Units"}
                                        required
                                        value={formValues.units}
                                        onChange={onChange}
                                        error={!!errors.units}
                                    />
                                    <InputLabel>Units</InputLabel>
                                </InputContainer>
                            </Flex>
                            <Flex mt={theme.spacing[6]}>
                                <Text fontSize="XXS" color={theme.taupeGray} >
                                    Please describe other specific needs that you may have in the "Description" section.  
                                </Text>
                            </Flex>
                        </>
                    )
                }
            </Flex>
        </>
    );
}

export default RealStateDetails;