import styled from 'styled-components';
import { Description2 } from '../../../../../ui/presentation/atoms/shared/Text';
import { Box } from 'rebass';
//import { getThemeColor, getThemeOnColor } from '../../../../../../../theme';
//import { Flex } from 'rebass';

export const CardContainer = styled.div`
    z-index: 999999;
    position: absolute;
    width: 15vw;
    top: 3.7rem;
    right: 0vw;
`

export const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5em;
`

export const MenuItemText = styled(Description2)`
    margin-left: 1em;
`
export const NameContainer = styled(Box)`
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`