import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import SplashScreen from "../../ui/presentation/atoms/SplashScreen";
import { connect } from "react-redux";
import UserBusiness from "../../../business/user";
import { withRouter } from "react-router-dom";


const PrivateRoute = props => {
    const [isLoggedIn, setIsLoggedIn] = useState(props.isLoggedIn);

    useEffect(() => {
        if (props.isLoggedIn !== isLoggedIn)
            localStorage.removeItem("comesFromUrl");
        else if (!props.isLoggedIn && !isLoggedIn) {
            localStorage.setItem(
                "comesFromUrl",
                JSON.stringify(props.location)
            );
        }
        setIsLoggedIn(props.isLoggedIn);
    }, [props.isLoggedIn]);

    const {
        component: Component,
        //isLoggedIn,
        blockedUser,
        geolocationLoading,
        user,
        isLoading,
        ...rest
    } = props;
    const { pathname } = props.location
    const minProfileComplete =
        // UserBusiness.minProfileStep1Complete(user) &&
        UserBusiness.minProfileStep2Complete(user) &&
        UserBusiness.minProfileStep3Complete(user);

    return (
        <Route
            {...rest}
            render={properties => {

                if (isLoggedIn && !isLoading && user && Object.keys(user).length > 0 && !minProfileComplete && pathname !== "/fill-profile") {
                    return <Redirect to={{ pathname: "/fill-profile" }} />;
                } 
                if (blockedUser) return <SplashScreen />;
                if (isLoggedIn /* || isLoading */)
                    return (
                        <Component
                            {...properties}
                            isLoggedIn={isLoggedIn}
                            geolocationLoading={geolocationLoading}
                        />
                    );
                else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/sign-in",
                                state: { from: properties.location, next: properties.location.pathname }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const mapStateToProps = state => {
    return {
        blockedUser: state.main.user.ipInfo
            ? state.main.user.ipInfo.blockedUser
            : false,
        user: state.main.user.profile,
        isLoading: state.main.user.isLoading || (state.main.ui.view.signUp.loading || state.main.ui.view.signIn.loading)
    };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
