import React from 'react'
import { Flex } from "rebass"
import CheckBox from "../../../../../atoms/shared/Inputs/Checkbox";
import { visibilityOptions } from "../../../constants"
import { Description } from "../../../../../atoms/shared/Text";

const Visibility = ({ theme, formValues, onChange }) => (
    <Flex p={`${theme.spacing[5]} 0`} flexDirection="column">
        <Description style={{ marginBottom: theme.spacing[4] }}>
            Do you want to display a successful exchange of this referral?
        </Description>
        {visibilityOptions.map(({ value, label }, index) => (
            <Flex key={value} mb={index === 0 ? theme.spacing[6]: 0}>
                <CheckBox
                    round
                    inverted
                    label={label}
                    onChange={ev =>
                        onChange({
                            target: {
                                name: "visibility",
                                value: { value, label },
                            }
                        })
                    }
                    checkBoxColor={theme.blueMedium}
                    checked={value === formValues.visibility.value}
                />
            </Flex>
        ))}
    </Flex>
)

export default Visibility;