import React, { Fragment, useState } from "react";
import { Flex, Box } from "rebass";
import { withTheme } from "styled-components";
import { Text } from '../../../../ui/presentation/atoms/shared/Text'
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import Avatar from '../../../../ui/presentation/atoms/shared/AvatarCircle2'
import { CollapsableContainer } from './styles'
//import { NOTIFICATIONS_BELL_TYPES } from '../../../../../assets/strings'
import NotificationItem from './NotificationItem'
import HorizontalLine from "../../../../ui/presentation/atoms/shared/HorizontalLine";

const ContactRequestsList = (props) => {
    const {
        theme,
        connectionRequests,
        handleAcceptConnection,
        handleDeclineConnection,
        handleRemoveNotification,
        handleClickUser,
        handleClickNotification,
        myId,
        acceptKnockConnectRequest,
        declineKnockRequest,
        setMarkThisNotificationAsRead
    } = props
    const icon= <Icon icon="add-user" color={theme.newBlue} width='10px' height='10px' />
    return connectionRequests && connectionRequests.map((notification, index) => {
        return (
            <Fragment>
                <NotificationItem
                    icon={icon}
                    key={`${index}_${notification.id}_notification`}
                    notification={notification}
                    handleAcceptConnection={handleAcceptConnection}
                    handleDeclineConnection={handleDeclineConnection}
                    handleRemoveNotification={handleRemoveNotification}
                    handleClickNotification={handleClickNotification}
                    handleClickUser={handleClickUser}
                    notificationId={notification.id}
                    myId={myId}
                    acceptKnockConnectRequest={acceptKnockConnectRequest}
                    declineKnockRequest={declineKnockRequest}
                    setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
                />
                <HorizontalLine />
            </Fragment>
        )
    })
}

const ConnectionRequests = (props) => {
    const [collapsed, toogleCollapsed] = useState(true)
    const {
        theme,
        connectionRequests,
        handleAcceptConnection,
        handleDeclineConnection,
        handleRemoveNotification,
        handleClickUser,
        handleClickNotification,
        myId,
        acceptKnockConnectRequest,
        declineKnockRequest,
        setMarkThisNotificationAsRead
    } = props

    const handleToogleCollapse = () => {
        toogleCollapsed(!collapsed)
    }
    const {  actors } =  connectionRequests ? connectionRequests[0] : {}
    const {  avatar } = actors
   
    const length = connectionRequests ? connectionRequests.length : 0

    const arrowStyles = {
        transform: !collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform .5s'
    }

    return length > 1 ? (
        <CollapsableContainer collapsed={collapsed} className='disable-select'>
            <Flex
                width={[1]}
                justifyContent='space-between'
                alignItems='center'
                style={{ cursor: 'pointer' }}
                onClick={handleToogleCollapse}
                mb={theme.spacing[2]}
            >
                <Flex alignItems='center'>
                    <Avatar src={avatar} size='40px' />
                    <Text style={{ marginLeft: theme.spacing['5'] }}>{`You have ${length} connection requests`}</Text>
                </Flex>
                <Box ml={theme.spacing['4']} flexGrow='1'>
                    <Icon
                        icon="chevron-down"
                        style={arrowStyles}
                        color={theme.blueMedium}
                        width='10px'
                        height='10px'
                    />
                </Box>
            </Flex>
            {!collapsed && <ContactRequestsList
                                connectionRequests={connectionRequests}
                                theme={theme}
                                handleAcceptConnection={handleAcceptConnection}
                                handleDeclineConnection={handleDeclineConnection}
                                handleRemoveNotification={handleRemoveNotification}
                                handleClickNotification={handleClickNotification}
                                handleClickUser={handleClickUser}
                                myId={myId}
                                acceptKnockConnectRequest={acceptKnockConnectRequest}
                                declineKnockRequest={declineKnockRequest}
                                setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
            />}
        </CollapsableContainer>
    ) : <ContactRequestsList
                    connectionRequests={connectionRequests}
                    theme={theme}
                    handleAcceptConnection={handleAcceptConnection}
                    handleDeclineConnection={handleDeclineConnection}
                    handleRemoveNotification={handleRemoveNotification}
                    handleClickNotification={handleClickNotification}
                    handleClickUser={handleClickUser}
                    myId={myId}
                    acceptKnockConnectRequest={acceptKnockConnectRequest}
                    declineKnockRequest={declineKnockRequest}
                    setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
    />
}

export default withTheme(ConnectionRequests)