const STATE_TYPES = [
    {
        value: "",
        label: "SELECT STATE"
    },
    {
        value: "Alabama",
        label: "Alabama",
        shortValue: "AL"
    },
    {
        value: "Alaska",
        label: "Alaska",
        shortValue: "AK"
    },
    {
        value: "Arizona",
        label: "Arizona",
        shortValue: "AZ"
    },
    {
        value: "Arkansas",
        label: "Arkansas",
        shortValue: "AR"
    },
    {
        value: "California",
        label: "California",
        shortValue: "CA"
    },
    {
        value: "Colorado",
        label: "Colorado",
        shortValue: "CO"
    },
    {
        value: "Connecticut",
        label: "Connecticut",
        shortValue: "CT"
    },
    {
        value: "Delaware",
        label: "Delaware",
        shortValue: "DE"
    },
    {
        value: "Florida",
        label: "Florida",
        shortValue: "FL"
    },
    {
        value: "Georgia",
        label: "Georgia",
        shortValue: "GA"
    },
    {
        value: "Hawaii",
        label: "Hawaii",
        shortValue: "HI"
    },
    {
        value: "Idaho",
        label: "Idaho",
        shortValue: "ID"
    },
    {
        value: "Illinois",
        label: "Illinois",
        shortValue: "IL"
    },
    {
        value: "Indiana",
        label: "Indiana",
        shortValue: "IN"
    },
    {
        value: "Iowa",
        label: "Iowa",
        shortValue: "IA"
    },
    {
        value: "Kansas",
        label: "Kansas",
        shortValue: "KS"
    },
    {
        value: "Kentucky",
        label: "Kentucky",
        shortValue: "KY"
    },
    {
        value: "Louisiana",
        label: "Louisiana",
        shortValue: "LA"
    },
    {
        value: "Maine",
        label: "Maine",
        shortValue: "ME"
    },
    {
        value: "Maryland",
        label: "Maryland",
        shortValue: "MD"
    },
    {
        value: "Massachusetts",
        label: "Massachusetts",
        shortValue: "MA"
    },
    {
        value: "Michigan",
        label: "Michigan",
        shortValue: "MI"
    },
    {
        value: "Minnesota",
        label: "Minnesota",
        shortValue: "MN"
    },
    {
        value: "Mississippi",
        label: "Mississippi",
        shortValue: "MS"
    },
    {
        value: "Missouri",
        label: "Missouri",
        shortValue: "MO"
    },
    {
        value: "Montana",
        label: "Montana",
        shortValue: "MT"
    },
    {
        value: "Nebraska",
        label: "Nebraska",
        shortValue: "NE"
    },
    {
        value: "Nevada",
        label: "Nevada",
        shortValue: "NV"
    },
    {
        value: "New Hampshire",
        label: "New Hampshire",
        shortValue: "NH"
    },
    {
        value: "New Jersey",
        label: "New Jersey",
        shortValue: "NJ"
    },
    {
        value: "New Mexico",
        label: "New Mexico",
        shortValue: "NM"
    },
    {
        value: "New York",
        label: "New York",
        shortValue: "NY"
    },
    {
        value: "North Carolina",
        label: "North Carolina",
        shortValue: "NC"
    },
    {
        value: "North Dakota",
        label: "North Dakota",
        shortValue: "ND"
    },
    {
        value: "Ohio",
        label: "Ohio",
        shortValue: "OH"
    },
    {
        value: "Oklahoma",
        label: "Oklahoma",
        shortValue: "OK"
    },
    {
        value: "Oregon",
        label: "Oregon",
        shortValue: "OR"
    },
    {
        value: "Pennsylvania",
        label: "Pennsylvania",
        shortValue: "PA"
    },
    {
        value: "Rhode Island",
        label: "Rhode Island",
        shortValue: "RI"
    },
    {
        value: "South Carolina",
        label: "South Carolina",
        shortValue: "SC"
    },
    {
        value: "South Dakota",
        label: "South Dakota",
        shortValue: "SD"
    },
    {
        value: "Tennessee",
        label: "Tennessee",
        shortValue: "TN"
    },
    {
        value: "Texas",
        label: "Texas",
        shortValue: "TX"
    },
    {
        value: "Utah",
        label: "Utah",
        shortValue: "UT"
    },
    {
        value: "Vermont",
        label: "Vermont",
        shortValue: "VT"
    },
    {
        value: "Virginia",
        label: "Virginia",
        shortValue: "VA"
    },
    {
        value: "Washington",
        label: "Washington",
        shortValue: "WA"
    },
    {
        value: "West Virginia",
        label: "West Virginia",
        shortValue: "WV"
    },
    {
        value: "Wisconsin",
        label: "Wisconsin",
        shortValue: "WI"
    },
    {
        value: "Wyoming",
        label: "Wyoming",
        shortValue: "WY"
    }
];

const RELATION_STATUS = {
    connected: "CONNECTED",
    sentPending: "SENT_PENDING",
    receivedPending: "RECEIVED_PENDING",
    blockedByMe: "BLOCKED_BY_ME",
    blockedByHim: "BLOCKED_BY_HIM",
    notConnected: "NOT_CONNECTED"
};

const USER_TO_CONNECT_SOURCE = {
    0: "NEW",
    1: "NEAR",
    2: "FEATURED"
};

const OWNER_OPTIONS = [
    {
        value: 0,
        label: "NO"
    },
    {
        value: 1,
        label: "YES"
    }
];

const SUBSCRIPTION_PLANS = {
    ESSENTIAL: "ESSENTIAL",
    ESSENTIAL_PLUS: "ESSENTIAL+",
    PRO: "PRO"
};

const CHECKOUT_SUBSCRIPTION_PLANS = {
    "ESSENTIAL+_ANNUAL": process.env.REACT_APP_PLAN_ESSENTIALPLUS_ANNUAL,
    "ESSENTIAL+_MONTHLY": process.env.REACT_APP_PLAN_ESSENTIALPLUS_MONTHLY,
    "PRO_ANNUAL": process.env.REACT_APP_PLAN_PRO_ANNUAL,
    "PRO_MONTHLY": process.env.REACT_APP_PLAN_PRO_MONTHLY
};

const SUBSCRIPTION_PLANS_PRICES = {
    ESSENTIAL_PLUS: 96,
    PRO_ANNUAL: 468,
    PRO_MONTHLY: 59
};

const PROFILE_EDITING_CARD_NAMES = {
    BASIC_INFO: "BASIC_INFO",
    SOCIAL_NETWORKS: "SOCIAL_NETWORKS",
    HONORS: "HONORS",
    LICENSES: "LICENSES",
    REAL_ESTATE_FOCUS: "REAL_ESTATE_FOCUS",
    SERVICE_AREAS: "SERVICE_AREAS",
    COMMUNITY_INVOLVEMENT: "COMMUNITY_INVOLVEMENT",
    ABOUT: "ABOUT"
};

const BILLINGDETAILS_STATUS = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED'
}

const IMAGES_SIZE_PREFIX = {
    SMALL: "_small",
    MEDIUM: "_medium",
    LARGE: "_large"
}

const REFERRALS_SORT = [
    {
        value: "desc",
        label: "Most recent"
    },
    {
        value: "asc",
        label: "Oldest"
    }
];
const SETTINGS_NOTIFICATIONS_SECTION = {
    POST: 'POST',
    COMMENT: 'COMMENT',
    LIKE: 'LIKE'
}

const NOTIFICATIONS_BELL_TYPES = {
    NEW_CONNECTION: "NEW_CONNECTION",
    CONNECTION_ACCEPTED: "CONNECTION_ACCEPTED",
    COMMUNITY_LIKE: "COMMUNITY_LIKE",
    COMMUNITY_COMMENT: "COMMUNITY_COMMENT",
    REFERRAL_CLAIM: "REFERRAL_CLAIM",
    NEW_REFERRAL: "NEW_REFERRAL",
    TIMELINE: "TIMELINE",
    OPPSHARE: "OPPSHARE",
    OPPSHARESHARER: "OPPSHARESHARER",
    OPPSHAREOWNER: "OPPSHAREOWNER",
    RECOMMENDED: "RECOMMENDED",
    RECOMMENDEDUP: "RECOMMENDEDUP",
    RECOMMENDOWNER: "RECOMMENDOWNER",
    ENDORSED: "ENDORSED",
    ENDORSER: "ENDORSER",
    ENDORSEDCONNECTIONS: "ENDORSEDCONNECTIONS",
    GRATITUDE: "GRATITUDE",
    FAVORITE: "FAVORITE",
};

const NOTIFICATION_RADIUS_VALUES = {
    "30_MILES": "30_MILES",
    "60_MILES": "60_MILES",
    "ALL": "ALL",
};

const TARGET_TO_NOTIFY_VALUES = {
    "ALL": "ALL",
    "60_MILES": "60_MILES",
    "300_MILES": "300_MILES",
}

const CANDIDATES_ACCEPTANCE_OTIONS = {
    "ALL": "ALL",
    "FIRST_COME_FIRST_SERVE": "FIRST_COME_FIRST_SERVE",
}

const PROPERTY_TYPES = {
    RESIDENTIAL: "RESIDENTIAL",
    COMMERCIAL: "COMMERCIAL",
    LAND: "LAND"
};

const RETAIL_SPACE_TYPES = {
    SQUARE_FEET_PER_YEAR: "SQUARE_FEET_PER_YEAR",
    SQUARE_FEET_PER_MONTH: "SQUARE_FEET_PER_MONTH"
}

const CREDIT_TYPES = {
    GREAT: "GREAT",
    GOOD: "GOOD",
    NOT_SO_GOOD: "NOT_SO_GOOD",
    NOT_SURE: "NOT_SURE"
}

const SOCIAL_URLS = {
    facebook: "https://www.facebook.com/",
    instagram: "https://www.instagram.com/",
    twitter: "https://www.twitter.com/",
    linkedin: "https://www.linkedin.com/in/"
};

const ONBOARDING_VIEWS = {
    bigBoard: "bigBoard",
    myNetwork: "myNetwork",
    profile: "profile",
    referralList: "referralList",
    inviteAndEarn: "inviteAndEarn"
};

const CLIENT_REVIEW_OPTIONS = {
    POSITIVE: "POSITIVE",
    NEUTRAL: "NEUTRAL",
    NEGATIVE: "NEGATIVE"
};
const OPPORTUNITIES_MODEL = {
    "REFERRAL": "REFERRAL",
    "RECOMMENDATION": "RECOMMENDATION",
    "JOB": "JOB",
}

const LEADERBOARD_DATE_RANGE_OPTIONS = {
    // "THIS_WEEK": "this_week",
    "THIS_MONTH": "this_month",
    "LAST_MONTH": "last_month",
    "THIS_QUARTER": "this_quarter",
    "THIS_YEAR": "this_year",
};

export {
    CREDIT_TYPES,
    RETAIL_SPACE_TYPES,
    PROPERTY_TYPES,
    CANDIDATES_ACCEPTANCE_OTIONS,
    TARGET_TO_NOTIFY_VALUES,
    NOTIFICATION_RADIUS_VALUES,
    NOTIFICATIONS_BELL_TYPES,
    SETTINGS_NOTIFICATIONS_SECTION,
    REFERRALS_SORT,
    IMAGES_SIZE_PREFIX,
    BILLINGDETAILS_STATUS,
    OWNER_OPTIONS,
    PROFILE_EDITING_CARD_NAMES,
    SUBSCRIPTION_PLANS_PRICES,
    SUBSCRIPTION_PLANS,
    CHECKOUT_SUBSCRIPTION_PLANS,
    USER_TO_CONNECT_SOURCE,
    RELATION_STATUS,
    STATE_TYPES,
    SOCIAL_URLS,
    ONBOARDING_VIEWS,
    CLIENT_REVIEW_OPTIONS,
    OPPORTUNITIES_MODEL,
    LEADERBOARD_DATE_RANGE_OPTIONS
};
