import {
    NEWARTICLE_ADDHASHTAG,
    NEWARTICLE_ADDIMAGE,
    NEWARTICLE_ADDIMAGESUCCESS,
    NEWARTICLE_ISLOADING,
    NEWARTICLE_REMOVEHASHTAG,
    NEWARTICLE_REMOVEIMAGE,
    NEWARTICLE_SETBODY,
    NEWARTICLE_SETTITLE,
    NEWARTICLE_FAIL,
    NEWARTICLE_SETPRIMARYIMAGE,
    NEWARTICLE_TOOGLEVISIBILITYMODAL,
    NEWARTICLE_SETVISIBILITY,
    NEWARTICLE_CLEARFORM
} from "../../../../actions/constants";
const initialState = {
    errors: [],
    isLoading: false,
    title: "",
    body: "",
    hashtags: [],
    images: {
        isLoading: false,
        data: []
    },
    visibility: {
        modalOpen: false,
        groupId: "public"
    }
};

function newArticleReducer(state = initialState, action) {
    switch (action.type) {
        case NEWARTICLE_TOOGLEVISIBILITYMODAL: {
            const _state = {
                ...state
            };
            _state.visibility = { ..._state.visibility };
            _state.visibility.modalOpen = action.payload.open;
            return _state;
        }
        case NEWARTICLE_SETVISIBILITY: {
            const _state = {
                ...state
            };
            _state.visibility = { ..._state.visibility };
            _state.visibility.groupId = action.payload.groupId;
            _state.visibility.modalOpen = false;
            return _state;
        }
        case NEWARTICLE_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case NEWARTICLE_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case NEWARTICLE_ADDIMAGE: {
            const _state = {
                ...state
            };
            _state.images.isLoading = true;
            return _state;
        }
        case NEWARTICLE_ADDIMAGESUCCESS: {
            const _state = {
                ...state
            };
            _state.images.isLoading = false;
            _state.images.data = [..._state.images.data];
            _state.images.data[action.payload.image.position] =
                action.payload.image.url;
            return _state;
        }

        case NEWARTICLE_SETPRIMARYIMAGE: {
            const _state = {
                ...state
            };
            _state.images.isLoading = false;
            _state.images.data = [..._state.images.data];
            const aux = _state.images.data[0];
            _state.images.data[0] = _state.images.data[action.payload.position];
            _state.images.data[action.payload.position] = aux;
            return _state;
        }

        case NEWARTICLE_REMOVEIMAGE: {
            const _state = {
                ...state
            };
            _state.images.isLoading = false;
            _state.images.data = _state.images.data
                .map((image, index) =>
                    index !== action.payload.imageIndex ? image : null
                )
                .filter(image => image);
            return _state;
        }
        case NEWARTICLE_ADDHASHTAG: {
            const _state = {
                ...state
            };
            _state.hashtags = [..._state.hashtags, action.payload.hashtag];
            return _state;
        }
        case NEWARTICLE_REMOVEHASHTAG: {
            const _state = {
                ...state
            };

            _state.hashtags = _state.hashtags.filter(
                hashtag => hashtag !== action.payload.hashtag
            );
            return _state;
        }
        case NEWARTICLE_SETBODY: {
            const _state = {
                ...state
            };
            _state.body = action.payload.body;
            return _state;
        }
        case NEWARTICLE_SETTITLE: {
            const _state = {
                ...state
            };
            _state.title = action.payload.title;
            return _state;
        }
        case NEWARTICLE_CLEARFORM: {
            const _state = {
                ...initialState
            };
            _state.images.data = [];
            _state.hashtags = [];

            return _state;
        }

        default:
            return state;
    }
}

export default newArticleReducer;
