import { push, replace } from "connected-react-router";
import {
    CHAT_CLEAN,
    CHAT_SETCONVERSATIONS,
    CHAT_UPDATECONVERSATION,
    CHAT_ISLOADING,
    CHAT_ADD_CONVERSATION_MESSAGE,
    CHAT_SET_CONVERSATION_MESSAGES,
    CHAT_SELECT_CONVERSATION,
    CHAT_CONVERSATION_SET_USER_STATUS,
    CHAT_OPEN_PROFILE_DETAILS,
    CHAT_RESET_CONVERSATIONS_MESSAGES,
    CHAT_FETCH_CONTACT_SUCCESS,
    CHAT_FETCH_CONTACT_FAIL,
    CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS,
    CHAT_CONVERSATIONS_LOADING
} from "../../constants";
import { handleError } from '../modal'
import {chatNewMessages} from '../navbar'
import Firebase from '../../../../libs/Firebase';

const normalizeUserForChat = (user, uid) => {
    const { userName, name, lastname, avatar, company_info, address, location, phone, email, bio, headline } = user ? user : {}
    return { uid, userName, name, lastname, avatar, company_info, address, location, phone, email, bio, headline }
}

const setIsLoading = isLoading => {
    return {
        type: CHAT_ISLOADING,
        payload: {
            isLoading
        }
    }
};

const cleanChat = () => {
    return {
        type: CHAT_CLEAN
    };
};

const setConversationsLoading = isLoading => {
    return {
        type: CHAT_CONVERSATIONS_LOADING,
        payload: {
            conversationsLoading: isLoading
        }
    };
};

const setConversations = conversations => {
    return {
        type: CHAT_SETCONVERSATIONS,
        payload: {
            conversations
        }
    }
};

const updateConversation = (uid, conversation) => {
    return {
        type: CHAT_UPDATECONVERSATION,
        payload: {
            uid,
            conversation
        }
    }
};

const setConversationMessages = (conversationId, messages) => {
    return {
        type: CHAT_SET_CONVERSATION_MESSAGES,
        payload: {
            conversationId,
            messages
        }
    }
};

const setMessage = (conversationId, message) => {
    return {
        type: CHAT_ADD_CONVERSATION_MESSAGE,
        payload: {
            conversationId,
            message
        }
    }
};
const resetConversationsMessages = () => {
    return {
        type: CHAT_RESET_CONVERSATIONS_MESSAGES,
    };
};

const removeNewChatsWithNoMessages = () => {
    return {
        type: CHAT_REMOVE_EMPTY_NEW_CONVERSATIONS,
    }
}

const selectConversation = (conversationId) => {
    return (dispatch, getState) => {
        dispatch(replace(`/chat/${conversationId}`));
        dispatch({
            type: CHAT_SELECT_CONVERSATION,
            payload: {
                conversationId
            }
        })
    }
};

const setUserStatus = (conversationId, user_status) => {
    return {
        type: CHAT_CONVERSATION_SET_USER_STATUS,
        payload: {
            conversationId,
            user_status
        }
    }
};

export const setOpenProfileDetails = (open) => {
    return {
        type: CHAT_OPEN_PROFILE_DETAILS,
        payload: {
            openProfileDetails: open
        }
    };
};
//TODO: MAKE A PAGINATION IN ORDER TO GET CONVERSATIONS BY INFINITE SCROLLING 
const subscribeToMyConversationsAdded = (uid) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            dispatch(setConversationsLoading(true));
            const conversations = await firebase.getUserConversations(uid);
            const normalizedConversations = {}
            for (const conversation of conversations) {
                const { key } = conversation
                const user = await firebase.fetchProfileWithCache(key)
                const normalizedUser = normalizeUserForChat(user, key)
                normalizedConversations[key] = {
                    data: conversation,
                    user: normalizedUser
                }
            }
            dispatch(setConversations(normalizedConversations))
            dispatch(setConversationsLoading(false));
            firebase.subscribeToMyConversationsAdded(uid, async (data, key) => {
             
                const newMessage = data && data.lastMessage && !data.lastMessage.isRead
                console.log('subscribeToMyConversationsAdded', key, data, newMessage)
                const user = await firebase.fetchProfileWithCache(key)
                const normalizedUser = normalizeUserForChat(user, key)
                dispatch(setConversations({
                    [key]: {
                        data: data,
                        user: normalizedUser
                    }
                }))
                if(newMessage) dispatch(chatNewMessages(key));
            })
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const subscribeToMyConversationsChanged = (uid) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            firebase.subscribeToMyConversationsChanged(uid, (data, key) => {
                console.log('subscribeToMyConversationsChanged', data, key)
                const newMessage =
                    data && data.lastMessage && !data.lastMessage.isRead;
                dispatch(updateConversation(key, data))
                if (newMessage) dispatch(chatNewMessages(key));
            })
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};


const subscribeToConversationMessagesAdded = (conversationId) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            dispatch(setIsLoading(true))
            // console.log('subscribeToConversationMessagesAdded', conversationId)
            const messagesLocationId = getState().main.ui.view.chat.conversations[conversationId].data.location
            const conversationMessages = await firebase.getChatMessages(
                messagesLocationId
            );
            dispatch(
                setConversationMessages(conversationId, conversationMessages)
            );
            dispatch(setIsLoading(false));
            firebase.subscribeToConversationMessagesAdded(messagesLocationId, async (data, key) => {
                // console.log('subscribeToConversationMessagesAdded', key, data, messagesLocationId)
                dispatch(setMessage(conversationId, data))
            })
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const unsubscribeToConversationMessagesAdded = (conversationId) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            firebase.unsubscribeToConversationMessagesAdded(conversationId)
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const subscribeToChatUserStatus = (conversationId, userId) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            console.log('subscribeToChatUserStatus', conversationId)
            firebase.subscribeToChatUserStatus(userId, async (data, key) => {
                console.log('subscribeToChatUserStatus', key, data)
                dispatch(setUserStatus(conversationId, data))
            })
        } catch (error) {
            return dispatch(handleError(error));

        }
    }
};

const unsubscribeToChatUserStatus = (userId) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            firebase.unsubscribeToChatUserStatus(userId)
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const startChatWithUser = (userToChat) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            const { uid } = getState().main.user
            const { conversations } = getState().main.ui.view.chat;
            const conversationsArray = Object.entries(conversations)
            let conversation = conversationsArray.find(([key, value]) => {
                const { uid } = value.user
                return uid === userToChat.uid
            })
            if (conversation) {
                dispatch(selectConversation(conversation[0]))  
            } else {
                const conversationKey = await firebase.generateRandomLocationIdForChat();
                conversation = {
                    location: conversationKey,
                    type: 0,
                    timestamp: Date.now()/1000,
                    newConversation: true,
                };
                const normalizedUser = normalizeUserForChat(
                    userToChat,
                    userToChat.uid
                );
                const selectedConversation = userToChat.uid;
                dispatch(
                    setConversations({
                        [selectedConversation]: {
                            data: conversation,
                            user: normalizedUser
                        }
                    })
                );
                dispatch(selectConversation(selectedConversation));
            }
            dispatch(push('/chat'))
            // dispatch(isLoading(true))
            // const conversations = await cloudfunctions.startChatWithUser(uid)
            // dispatch(isLoading(false))
            // dispatch(setConversations(conversations))
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
}


const messageModel = (content, type, fromID, isRead, toID) => {
    return {
        content,
        type,
        timestamp: Date.now()/1000,
        isRead,
        toID,
        fromID
    }
}
const sendMessage = (toId, locationId, type, content) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            const fromId = getState().main.user.uid
            const localConversation = getState().main.ui.view.chat
                .conversations[toId].data;
            // console.log("juas1", localConversation);
            if (localConversation.newConversation) {
                delete localConversation.newConversation;
                // console.log("juas2", localConversation)
                updateConversation(toId, localConversation);
                await firebase.createConversationForUsers(fromId, toId, localConversation);
            }
            const message = messageModel(content, type, fromId, false, toId)
            await firebase.chatSendMessage(locationId, message)
            await firebase.chatSetLastMessage(toId, fromId, message)
            await firebase.chatSetLastMessage(fromId, toId, message);
            return
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const setLastMessageRead = (toId, isRead) => {
    return async (dispatch, getState, {
        cloudfunctions,
        firebase
    }) => {
        try {
            const myId = getState().main.user.uid
            // console.log('setLastMessageRead', myId, toId)
            return await firebase.chatSetLastMessageRead(myId, toId, isRead)
        } catch (error) {
            return dispatch(handleError(error));
        }
    }
};

const fetchUserContactList = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const { contacts } = getState().main.user.profile;
            const uidsList = contacts && Object.keys(contacts);
            const uidsArray = Array.isArray(uidsList)
                ? uidsList
                : Firebase.flatArrayFromFirebaseList(uidsList);
            let data = await firebase.fetchUserList(uidsArray);
            data = data.filter(user => user);
            //TODO: Enjoy your life. 👽
            return dispatch({
                type: CHAT_FETCH_CONTACT_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch(handleError(error));
            return dispatch({
                type: CHAT_FETCH_CONTACT_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

export {
    unsubscribeToChatUserStatus,
    unsubscribeToConversationMessagesAdded,
    subscribeToMyConversationsAdded,
    subscribeToMyConversationsChanged,
    subscribeToConversationMessagesAdded,
    selectConversation,
    subscribeToChatUserStatus,
    sendMessage,
    setLastMessageRead,
    startChatWithUser,
    resetConversationsMessages,
    fetchUserContactList,
    removeNewChatsWithNoMessages,
    cleanChat,
    setConversationMessages
};