export default [
    {
      "city": "Alaska",
      "state": "",
      "shortState": "AK",
      "formatedCity": "Alaska"
    },
    {
      "city": "Alabama",
      "state": "",
      "shortState": "AL",
      "formatedCity": "Alabama"
    },
    {
      "city": "Arkansas",
      "state": "",
      "shortState": "AR",
      "formatedCity": "Arkansas"
    },
    {
      "city": "Arizona",
      "state": "",
      "shortState": "AZ",
      "formatedCity": "Arizona"
    },
    {
      "city": "California",
      "state": "",
      "shortState": "CA",
      "formatedCity": "California"
    },
    {
      "city": "Colorado",
      "state": "",
      "shortState": "CO",
      "formatedCity": "Colorado"
    },
    {
      "city": "Connecticut",
      "state": "",
      "shortState": "CT",
      "formatedCity": "Connecticut"
    },
    {
      "city": "District of Columbia",
      "state": "",
      "shortState": "DC",
      "formatedCity": "District of Columbia"
    },
    {
      "city": "Delaware",
      "state": "",
      "shortState": "DE",
      "formatedCity": "Delaware"
    },
    {
      "city": "Florida",
      "state": "",
      "shortState": "FL",
      "formatedCity": "Florida"
    },
    {
      "city": "Georgia",
      "state": "",
      "shortState": "GA",
      "formatedCity": "Georgia"
    },
    {
      "city": "Hawaii",
      "state": "",
      "shortState": "HI",
      "formatedCity": "Hawaii"
    },
    {
      "city": "Iowa",
      "state": "",
      "shortState": "IA",
      "formatedCity": "Iowa"
    },
    {
      "city": "Idaho",
      "state": "",
      "shortState": "ID",
      "formatedCity": "Idaho"
    },
    {
      "city": "Illinois",
      "state": "",
      "shortState": "IL",
      "formatedCity": "Illinois"
    },
    {
      "city": "Indiana",
      "state": "",
      "shortState": "IN",
      "formatedCity": "Indiana"
    },
    {
      "city": "Kansas",
      "state": "",
      "shortState": "KS",
      "formatedCity": "Kansas"
    },
    {
      "city": "Kentucky",
      "state": "",
      "shortState": "KY",
      "formatedCity": "Kentucky"
    },
    {
      "city": "Louisiana",
      "state": "",
      "shortState": "LA",
      "formatedCity": "Louisiana"
    },
    {
      "city": "Massachusetts",
      "state": "",
      "shortState": "MA",
      "formatedCity": "Massachusetts"
    },
    {
      "city": "Maryland",
      "state": "",
      "shortState": "MD",
      "formatedCity": "Maryland"
    },
    {
      "city": "Maine",
      "state": "",
      "shortState": "ME",
      "formatedCity": "Maine"
    },
    {
      "city": "Michigan",
      "state": "",
      "shortState": "MI",
      "formatedCity": "Michigan"
    },
    {
      "city": "Minnesota",
      "state": "",
      "shortState": "MN",
      "formatedCity": "Minnesota"
    },
    {
      "city": "Missouri",
      "state": "",
      "shortState": "MO",
      "formatedCity": "Missouri"
    },
    {
      "city": "Mississippi",
      "state": "",
      "shortState": "MS",
      "formatedCity": "Mississippi"
    },
    {
      "city": "Montana",
      "state": "",
      "shortState": "MT",
      "formatedCity": "Montana"
    },
    {
      "city": "North Carolina",
      "state": "",
      "shortState": "NC",
      "formatedCity": "North Carolina"
    },
    {
      "city": "North Dakota",
      "state": "",
      "shortState": "ND",
      "formatedCity": "North Dakota"
    },
    {
      "city": "Nebraska",
      "state": "",
      "shortState": "NE",
      "formatedCity": "Nebraska"
    },
    {
      "city": "New Hampshire",
      "state": "",
      "shortState": "NH",
      "formatedCity": "New Hampshire"
    },
    {
      "city": "New Jersey",
      "state": "",
      "shortState": "NJ",
      "formatedCity": "New Jersey"
    },
    {
      "city": "New Mexico",
      "state": "",
      "shortState": "NM",
      "formatedCity": "New Mexico"
    },
    {
      "city": "Nevada",
      "state": "",
      "shortState": "NV",
      "formatedCity": "Nevada"
    },
    {
      "city": "New York",
      "state": "",
      "shortState": "NY",
      "formatedCity": "New York"
    },
    {
      "city": "Ohio",
      "state": "",
      "shortState": "OH",
      "formatedCity": "Ohio"
    },
    {
      "city": "Oklahoma",
      "state": "",
      "shortState": "OK",
      "formatedCity": "Oklahoma"
    },
    {
      "city": "Oregon",
      "state": "",
      "shortState": "OR",
      "formatedCity": "Oregon"
    },
    {
      "city": "Pennsylvania",
      "state": "",
      "shortState": "PA",
      "formatedCity": "Pennsylvania"
    },
    {
      "city": "Rhode Island",
      "state": "",
      "shortState": "RI",
      "formatedCity": "Rhode Island"
    },
    {
      "city": "South Carolina",
      "state": "",
      "shortState": "SC",
      "formatedCity": "South Carolina"
    },
    {
      "city": "South Dakota",
      "state": "",
      "shortState": "SD",
      "formatedCity": "South Dakota"
    },
    {
      "city": "Tennessee",
      "state": "",
      "shortState": "TN",
      "formatedCity": "Tennessee"
    },
    {
      "city": "Texas",
      "state": "",
      "shortState": "TX",
      "formatedCity": "Texas"
    },
    {
      "city": "Utah",
      "state": "",
      "shortState": "UT",
      "formatedCity": "Utah"
    },
    {
      "city": "Virginia",
      "state": "",
      "shortState": "VA",
      "formatedCity": "Virginia"
    },
    {
      "city": "Vermont",
      "state": "",
      "shortState": "VT",
      "formatedCity": "Vermont"
    },
    {
      "city": "Washington",
      "state": "",
      "shortState": "WA",
      "formatedCity": "Washington"
    },
    {
      "city": "Wisconsin",
      "state": "",
      "shortState": "WI",
      "formatedCity": "Wisconsin"
    },
    {
      "city": "West Virginia",
      "state": "",
      "shortState": "WV",
      "formatedCity": "West Virginia"
    },
    {
      "city": "Wyoming",
      "state": "",
      "shortState": "WY",
      "formatedCity": "Wyoming"
    },
    {
      "city": "Akutan",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Akutan, Alaska"
    },
    {
      "city": "Cold Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Cold Bay, Alaska"
    },
    {
      "city": "False Pass",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "False Pass, Alaska"
    },
    {
      "city": "King Cove",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "King Cove, Alaska"
    },
    {
      "city": "Sand Point",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Sand Point, Alaska"
    },
    {
      "city": "Adak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Adak, Alaska"
    },
    {
      "city": "Atka",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Atka, Alaska"
    },
    {
      "city": "Saint George Island",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Saint George Island, Alaska"
    },
    {
      "city": "Nikolski",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nikolski, Alaska"
    },
    {
      "city": "Saint Paul Island",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Saint Paul Island, Alaska"
    },
    {
      "city": "Unalaska",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Unalaska, Alaska"
    },
    {
      "city": "Dutch Harbor",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Dutch Harbor, Alaska"
    },
    {
      "city": "Anchorage",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Anchorage, Alaska"
    },
    {
      "city": "Jber",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Jber, Alaska"
    },
    {
      "city": "Indian",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Indian, Alaska"
    },
    {
      "city": "Chugiak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chugiak, Alaska"
    },
    {
      "city": "Eagle River",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Eagle River, Alaska"
    },
    {
      "city": "Girdwood",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Girdwood, Alaska"
    },
    {
      "city": "Kongiganak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kongiganak, Alaska"
    },
    {
      "city": "Akiachak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Akiachak, Alaska"
    },
    {
      "city": "Akiak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Akiak, Alaska"
    },
    {
      "city": "Aniak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Aniak, Alaska"
    },
    {
      "city": "Bethel",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Bethel, Alaska"
    },
    {
      "city": "Chefornak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chefornak, Alaska"
    },
    {
      "city": "Crooked Creek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Crooked Creek, Alaska"
    },
    {
      "city": "Eek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Eek, Alaska"
    },
    {
      "city": "Goodnews Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Goodnews Bay, Alaska"
    },
    {
      "city": "Kalskag",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kalskag, Alaska"
    },
    {
      "city": "Kasigluk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kasigluk, Alaska"
    },
    {
      "city": "Kipnuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kipnuk, Alaska"
    },
    {
      "city": "Kwethluk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kwethluk, Alaska"
    },
    {
      "city": "Kwigillingok",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kwigillingok, Alaska"
    },
    {
      "city": "Lower Kalskag",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Lower Kalskag, Alaska"
    },
    {
      "city": "Mekoryuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Mekoryuk, Alaska"
    },
    {
      "city": "Napakiak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Napakiak, Alaska"
    },
    {
      "city": "Toksook Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Toksook Bay, Alaska"
    },
    {
      "city": "Nunapitchuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nunapitchuk, Alaska"
    },
    {
      "city": "Platinum",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Platinum, Alaska"
    },
    {
      "city": "Quinhagak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Quinhagak, Alaska"
    },
    {
      "city": "Red Devil",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Red Devil, Alaska"
    },
    {
      "city": "Sleetmute",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Sleetmute, Alaska"
    },
    {
      "city": "Tuluksak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tuluksak, Alaska"
    },
    {
      "city": "Tuntutuliak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tuntutuliak, Alaska"
    },
    {
      "city": "Tununak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tununak, Alaska"
    },
    {
      "city": "Nightmute",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nightmute, Alaska"
    },
    {
      "city": "King Salmon",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "King Salmon, Alaska"
    },
    {
      "city": "Naknek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Naknek, Alaska"
    },
    {
      "city": "South Naknek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "South Naknek, Alaska"
    },
    {
      "city": "Clear",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Clear, Alaska"
    },
    {
      "city": "Cantwell",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Cantwell, Alaska"
    },
    {
      "city": "Healy",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Healy, Alaska"
    },
    {
      "city": "Anderson",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Anderson, Alaska"
    },
    {
      "city": "Denali National Park",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Denali National Park, Alaska"
    },
    {
      "city": "Aleknagik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Aleknagik, Alaska"
    },
    {
      "city": "Clarks Point",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Clarks Point, Alaska"
    },
    {
      "city": "Dillingham",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Dillingham, Alaska"
    },
    {
      "city": "Ekwok",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ekwok, Alaska"
    },
    {
      "city": "Manokotak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Manokotak, Alaska"
    },
    {
      "city": "New Stuyahok",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "New Stuyahok, Alaska"
    },
    {
      "city": "Togiak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Togiak, Alaska"
    },
    {
      "city": "Fairbanks",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Fairbanks, Alaska"
    },
    {
      "city": "Eielson Afb",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Eielson Afb, Alaska"
    },
    {
      "city": "Fort Wainwright",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Fort Wainwright, Alaska"
    },
    {
      "city": "North Pole",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "North Pole, Alaska"
    },
    {
      "city": "Salcha",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Salcha, Alaska"
    },
    {
      "city": "Two Rivers",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Two Rivers, Alaska"
    },
    {
      "city": "Ester",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ester, Alaska"
    },
    {
      "city": "Haines",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Haines, Alaska"
    },
    {
      "city": "Juneau",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Juneau, Alaska"
    },
    {
      "city": "Auke Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Auke Bay, Alaska"
    },
    {
      "city": "Douglas",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Douglas, Alaska"
    },
    {
      "city": "Anchor Point",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Anchor Point, Alaska"
    },
    {
      "city": "Clam Gulch",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Clam Gulch, Alaska"
    },
    {
      "city": "Cooper Landing",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Cooper Landing, Alaska"
    },
    {
      "city": "Homer",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Homer, Alaska"
    },
    {
      "city": "Hope",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hope, Alaska"
    },
    {
      "city": "Kasilof",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kasilof, Alaska"
    },
    {
      "city": "Kenai",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kenai, Alaska"
    },
    {
      "city": "Moose Pass",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Moose Pass, Alaska"
    },
    {
      "city": "Nikiski",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nikiski, Alaska"
    },
    {
      "city": "Ninilchik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ninilchik, Alaska"
    },
    {
      "city": "Seldovia",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Seldovia, Alaska"
    },
    {
      "city": "Seward",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Seward, Alaska"
    },
    {
      "city": "Soldotna",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Soldotna, Alaska"
    },
    {
      "city": "Sterling",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Sterling, Alaska"
    },
    {
      "city": "Tyonek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tyonek, Alaska"
    },
    {
      "city": "Ketchikan",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ketchikan, Alaska"
    },
    {
      "city": "Ward Cove",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ward Cove, Alaska"
    },
    {
      "city": "Port Lions",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Port Lions, Alaska"
    },
    {
      "city": "Karluk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Karluk, Alaska"
    },
    {
      "city": "Kodiak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kodiak, Alaska"
    },
    {
      "city": "Larsen Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Larsen Bay, Alaska"
    },
    {
      "city": "Old Harbor",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Old Harbor, Alaska"
    },
    {
      "city": "Ouzinkie",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ouzinkie, Alaska"
    },
    {
      "city": "Alakanuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Alakanuk, Alaska"
    },
    {
      "city": "Chevak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chevak, Alaska"
    },
    {
      "city": "Emmonak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Emmonak, Alaska"
    },
    {
      "city": "Marshall",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Marshall, Alaska"
    },
    {
      "city": "Hooper Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hooper Bay, Alaska"
    },
    {
      "city": "Kotlik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kotlik, Alaska"
    },
    {
      "city": "Mountain Village",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Mountain Village, Alaska"
    },
    {
      "city": "Pilot Station",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Pilot Station, Alaska"
    },
    {
      "city": "Russian Mission",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Russian Mission, Alaska"
    },
    {
      "city": "Saint Marys",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Saint Marys, Alaska"
    },
    {
      "city": "Scammon Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Scammon Bay, Alaska"
    },
    {
      "city": "Nunam Iqua",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nunam Iqua, Alaska"
    },
    {
      "city": "Chignik Lake",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chignik Lake, Alaska"
    },
    {
      "city": "Port Heiden",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Port Heiden, Alaska"
    },
    {
      "city": "Chignik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chignik, Alaska"
    },
    {
      "city": "Chignik Lagoon",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chignik Lagoon, Alaska"
    },
    {
      "city": "Egegik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Egegik, Alaska"
    },
    {
      "city": "Iliamna",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Iliamna, Alaska"
    },
    {
      "city": "Levelock",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Levelock, Alaska"
    },
    {
      "city": "Nondalton",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nondalton, Alaska"
    },
    {
      "city": "Pedro Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Pedro Bay, Alaska"
    },
    {
      "city": "Perryville",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Perryville, Alaska"
    },
    {
      "city": "Pilot Point",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Pilot Point, Alaska"
    },
    {
      "city": "Port Alsworth",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Port Alsworth, Alaska"
    },
    {
      "city": "Wasilla",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Wasilla, Alaska"
    },
    {
      "city": "Palmer",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Palmer, Alaska"
    },
    {
      "city": "Big Lake",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Big Lake, Alaska"
    },
    {
      "city": "Skwentna",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Skwentna, Alaska"
    },
    {
      "city": "Sutton",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Sutton, Alaska"
    },
    {
      "city": "Talkeetna",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Talkeetna, Alaska"
    },
    {
      "city": "Trapper Creek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Trapper Creek, Alaska"
    },
    {
      "city": "Willow",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Willow, Alaska"
    },
    {
      "city": "Houston",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Houston, Alaska"
    },
    {
      "city": "Saint Michael",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Saint Michael, Alaska"
    },
    {
      "city": "Stebbins",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Stebbins, Alaska"
    },
    {
      "city": "Unalakleet",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Unalakleet, Alaska"
    },
    {
      "city": "Elim",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Elim, Alaska"
    },
    {
      "city": "Gambell",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Gambell, Alaska"
    },
    {
      "city": "Koyuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Koyuk, Alaska"
    },
    {
      "city": "Nome",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nome, Alaska"
    },
    {
      "city": "Savoonga",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Savoonga, Alaska"
    },
    {
      "city": "Shaktoolik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Shaktoolik, Alaska"
    },
    {
      "city": "Shishmaref",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Shishmaref, Alaska"
    },
    {
      "city": "Teller",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Teller, Alaska"
    },
    {
      "city": "Wales",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Wales, Alaska"
    },
    {
      "city": "White Mountain",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "White Mountain, Alaska"
    },
    {
      "city": "Brevig Mission",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Brevig Mission, Alaska"
    },
    {
      "city": "Anaktuvuk Pass",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Anaktuvuk Pass, Alaska"
    },
    {
      "city": "Barrow",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Barrow, Alaska"
    },
    {
      "city": "Prudhoe Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Prudhoe Bay, Alaska"
    },
    {
      "city": "Kaktovik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kaktovik, Alaska"
    },
    {
      "city": "Point Lay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Point Lay, Alaska"
    },
    {
      "city": "Point Hope",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Point Hope, Alaska"
    },
    {
      "city": "Wainwright",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Wainwright, Alaska"
    },
    {
      "city": "Nuiqsut",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nuiqsut, Alaska"
    },
    {
      "city": "Atqasuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Atqasuk, Alaska"
    },
    {
      "city": "Buckland",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Buckland, Alaska"
    },
    {
      "city": "Deering",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Deering, Alaska"
    },
    {
      "city": "Kiana",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kiana, Alaska"
    },
    {
      "city": "Kivalina",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kivalina, Alaska"
    },
    {
      "city": "Kobuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kobuk, Alaska"
    },
    {
      "city": "Kotzebue",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kotzebue, Alaska"
    },
    {
      "city": "Noatak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Noatak, Alaska"
    },
    {
      "city": "Noorvik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Noorvik, Alaska"
    },
    {
      "city": "Selawik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Selawik, Alaska"
    },
    {
      "city": "Shungnak",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Shungnak, Alaska"
    },
    {
      "city": "Ambler",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ambler, Alaska"
    },
    {
      "city": "Meyers Chuck",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Meyers Chuck, Alaska"
    },
    {
      "city": "Coffman Cove",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Coffman Cove, Alaska"
    },
    {
      "city": "Thorne Bay",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Thorne Bay, Alaska"
    },
    {
      "city": "Craig",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Craig, Alaska"
    },
    {
      "city": "Hydaburg",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hydaburg, Alaska"
    },
    {
      "city": "Hyder",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hyder, Alaska"
    },
    {
      "city": "Klawock",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Klawock, Alaska"
    },
    {
      "city": "Metlakatla",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Metlakatla, Alaska"
    },
    {
      "city": "Point Baker",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Point Baker, Alaska"
    },
    {
      "city": "Sitka",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Sitka, Alaska"
    },
    {
      "city": "Yakutat",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Yakutat, Alaska"
    },
    {
      "city": "Angoon",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Angoon, Alaska"
    },
    {
      "city": "Elfin Cove",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Elfin Cove, Alaska"
    },
    {
      "city": "Gustavus",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Gustavus, Alaska"
    },
    {
      "city": "Hoonah",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hoonah, Alaska"
    },
    {
      "city": "Pelican",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Pelican, Alaska"
    },
    {
      "city": "Skagway",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Skagway, Alaska"
    },
    {
      "city": "Tenakee Springs",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tenakee Springs, Alaska"
    },
    {
      "city": "Fort Greely",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Fort Greely, Alaska"
    },
    {
      "city": "Chicken",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chicken, Alaska"
    },
    {
      "city": "Delta Junction",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Delta Junction, Alaska"
    },
    {
      "city": "Eagle",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Eagle, Alaska"
    },
    {
      "city": "Northway",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Northway, Alaska"
    },
    {
      "city": "Tanacross",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tanacross, Alaska"
    },
    {
      "city": "Tok",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tok, Alaska"
    },
    {
      "city": "Chitina",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chitina, Alaska"
    },
    {
      "city": "Copper Center",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Copper Center, Alaska"
    },
    {
      "city": "Cordova",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Cordova, Alaska"
    },
    {
      "city": "Gakona",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Gakona, Alaska"
    },
    {
      "city": "Glennallen",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Glennallen, Alaska"
    },
    {
      "city": "Tatitlek",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tatitlek, Alaska"
    },
    {
      "city": "Valdez",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Valdez, Alaska"
    },
    {
      "city": "Whittier",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Whittier, Alaska"
    },
    {
      "city": "Kake",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kake, Alaska"
    },
    {
      "city": "Petersburg",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Petersburg, Alaska"
    },
    {
      "city": "Port Alexander",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Port Alexander, Alaska"
    },
    {
      "city": "Wrangell",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Wrangell, Alaska"
    },
    {
      "city": "Anvik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Anvik, Alaska"
    },
    {
      "city": "Grayling",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Grayling, Alaska"
    },
    {
      "city": "Holy Cross",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Holy Cross, Alaska"
    },
    {
      "city": "Mc Grath",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Mc Grath, Alaska"
    },
    {
      "city": "Shageluk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Shageluk, Alaska"
    },
    {
      "city": "Takotna",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Takotna, Alaska"
    },
    {
      "city": "Nikolai",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nikolai, Alaska"
    },
    {
      "city": "Allakaket",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Allakaket, Alaska"
    },
    {
      "city": "Arctic Village",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Arctic Village, Alaska"
    },
    {
      "city": "Beaver",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Beaver, Alaska"
    },
    {
      "city": "Bettles Field",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Bettles Field, Alaska"
    },
    {
      "city": "Central",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Central, Alaska"
    },
    {
      "city": "Circle",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Circle, Alaska"
    },
    {
      "city": "Fort Yukon",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Fort Yukon, Alaska"
    },
    {
      "city": "Galena",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Galena, Alaska"
    },
    {
      "city": "Hughes",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Hughes, Alaska"
    },
    {
      "city": "Huslia",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Huslia, Alaska"
    },
    {
      "city": "Kaltag",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Kaltag, Alaska"
    },
    {
      "city": "Koyukuk",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Koyukuk, Alaska"
    },
    {
      "city": "Manley Hot Springs",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Manley Hot Springs, Alaska"
    },
    {
      "city": "Lake Minchumina",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Lake Minchumina, Alaska"
    },
    {
      "city": "Minto",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Minto, Alaska"
    },
    {
      "city": "Nenana",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nenana, Alaska"
    },
    {
      "city": "Nulato",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Nulato, Alaska"
    },
    {
      "city": "Rampart",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Rampart, Alaska"
    },
    {
      "city": "Ruby",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Ruby, Alaska"
    },
    {
      "city": "Stevens Village",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Stevens Village, Alaska"
    },
    {
      "city": "Tanana",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Tanana, Alaska"
    },
    {
      "city": "Venetie",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Venetie, Alaska"
    },
    {
      "city": "Chalkyitsik",
      "state": "Alaska",
      "shortState": "AK",
      "formatedCity": "Chalkyitsik, Alaska"
    },
    {
      "city": "Autaugaville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Autaugaville, Alabama"
    },
    {
      "city": "Billingsley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Billingsley, Alabama"
    },
    {
      "city": "Booth",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Booth, Alabama"
    },
    {
      "city": "Marbury",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Marbury, Alabama"
    },
    {
      "city": "Prattville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Prattville, Alabama"
    },
    {
      "city": "Jones",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jones, Alabama"
    },
    {
      "city": "Bay Minette",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bay Minette, Alabama"
    },
    {
      "city": "Bon Secour",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bon Secour, Alabama"
    },
    {
      "city": "Daphne",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Daphne, Alabama"
    },
    {
      "city": "Spanish Fort",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Spanish Fort, Alabama"
    },
    {
      "city": "Elberta",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Elberta, Alabama"
    },
    {
      "city": "Fairhope",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fairhope, Alabama"
    },
    {
      "city": "Foley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Foley, Alabama"
    },
    {
      "city": "Gulf Shores",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gulf Shores, Alabama"
    },
    {
      "city": "Lillian",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lillian, Alabama"
    },
    {
      "city": "Little River",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Little River, Alabama"
    },
    {
      "city": "Loxley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Loxley, Alabama"
    },
    {
      "city": "Magnolia Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Magnolia Springs, Alabama"
    },
    {
      "city": "Montrose",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Montrose, Alabama"
    },
    {
      "city": "Orange Beach",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Orange Beach, Alabama"
    },
    {
      "city": "Perdido",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Perdido, Alabama"
    },
    {
      "city": "Point Clear",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Point Clear, Alabama"
    },
    {
      "city": "Robertsdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Robertsdale, Alabama"
    },
    {
      "city": "Seminole",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Seminole, Alabama"
    },
    {
      "city": "Silverhill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Silverhill, Alabama"
    },
    {
      "city": "Stapleton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Stapleton, Alabama"
    },
    {
      "city": "Stockton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Stockton, Alabama"
    },
    {
      "city": "Summerdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Summerdale, Alabama"
    },
    {
      "city": "Clayton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clayton, Alabama"
    },
    {
      "city": "Clio",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clio, Alabama"
    },
    {
      "city": "Eufaula",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eufaula, Alabama"
    },
    {
      "city": "Louisville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Louisville, Alabama"
    },
    {
      "city": "Brent",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brent, Alabama"
    },
    {
      "city": "Brierfield",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brierfield, Alabama"
    },
    {
      "city": "Centreville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Centreville, Alabama"
    },
    {
      "city": "Green Pond",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Green Pond, Alabama"
    },
    {
      "city": "West Blocton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "West Blocton, Alabama"
    },
    {
      "city": "Woodstock",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Woodstock, Alabama"
    },
    {
      "city": "Randolph",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Randolph, Alabama"
    },
    {
      "city": "Lawley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lawley, Alabama"
    },
    {
      "city": "Allgood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Allgood, Alabama"
    },
    {
      "city": "Blountsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Blountsville, Alabama"
    },
    {
      "city": "Cleveland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cleveland, Alabama"
    },
    {
      "city": "Hayden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hayden, Alabama"
    },
    {
      "city": "Locust Fork",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Locust Fork, Alabama"
    },
    {
      "city": "Oneonta",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Oneonta, Alabama"
    },
    {
      "city": "Remlap",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Remlap, Alabama"
    },
    {
      "city": "Fitzpatrick",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fitzpatrick, Alabama"
    },
    {
      "city": "Midway",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Midway, Alabama"
    },
    {
      "city": "Perote",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Perote, Alabama"
    },
    {
      "city": "Union Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Union Springs, Alabama"
    },
    {
      "city": "Chapman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Chapman, Alabama"
    },
    {
      "city": "Forest Home",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Forest Home, Alabama"
    },
    {
      "city": "Georgiana",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Georgiana, Alabama"
    },
    {
      "city": "Greenville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Greenville, Alabama"
    },
    {
      "city": "Mc Kenzie",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mc Kenzie, Alabama"
    },
    {
      "city": "Anniston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Anniston, Alabama"
    },
    {
      "city": "Oxford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Oxford, Alabama"
    },
    {
      "city": "Alexandria",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alexandria, Alabama"
    },
    {
      "city": "Bynum",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bynum, Alabama"
    },
    {
      "city": "Choccolocco",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Choccolocco, Alabama"
    },
    {
      "city": "De Armanville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "De Armanville, Alabama"
    },
    {
      "city": "Eastaboga",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eastaboga, Alabama"
    },
    {
      "city": "Jacksonville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jacksonville, Alabama"
    },
    {
      "city": "Ohatchee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ohatchee, Alabama"
    },
    {
      "city": "Piedmont",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Piedmont, Alabama"
    },
    {
      "city": "Weaver",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Weaver, Alabama"
    },
    {
      "city": "Wellington",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wellington, Alabama"
    },
    {
      "city": "Cusseta",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cusseta, Alabama"
    },
    {
      "city": "Valley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Valley, Alabama"
    },
    {
      "city": "Five Points",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Five Points, Alabama"
    },
    {
      "city": "Lafayette",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lafayette, Alabama"
    },
    {
      "city": "Lanett",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lanett, Alabama"
    },
    {
      "city": "Cedar Bluff",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cedar Bluff, Alabama"
    },
    {
      "city": "Centre",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Centre, Alabama"
    },
    {
      "city": "Gaylesville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gaylesville, Alabama"
    },
    {
      "city": "Leesburg",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Leesburg, Alabama"
    },
    {
      "city": "Spring Garden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Spring Garden, Alabama"
    },
    {
      "city": "Clanton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clanton, Alabama"
    },
    {
      "city": "Jemison",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jemison, Alabama"
    },
    {
      "city": "Thorsby",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Thorsby, Alabama"
    },
    {
      "city": "Verbena",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Verbena, Alabama"
    },
    {
      "city": "Maplesville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Maplesville, Alabama"
    },
    {
      "city": "Stanton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Stanton, Alabama"
    },
    {
      "city": "Butler",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Butler, Alabama"
    },
    {
      "city": "Gilbertown",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gilbertown, Alabama"
    },
    {
      "city": "Jachin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jachin, Alabama"
    },
    {
      "city": "Lisman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lisman, Alabama"
    },
    {
      "city": "Melvin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Melvin, Alabama"
    },
    {
      "city": "Needham",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Needham, Alabama"
    },
    {
      "city": "Pennington",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pennington, Alabama"
    },
    {
      "city": "Silas",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Silas, Alabama"
    },
    {
      "city": "Toxey",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Toxey, Alabama"
    },
    {
      "city": "Ward",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ward, Alabama"
    },
    {
      "city": "Dickinson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dickinson, Alabama"
    },
    {
      "city": "Fulton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fulton, Alabama"
    },
    {
      "city": "Grove Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Grove Hill, Alabama"
    },
    {
      "city": "Whatley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Whatley, Alabama"
    },
    {
      "city": "Coffeeville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coffeeville, Alabama"
    },
    {
      "city": "Gainestown",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gainestown, Alabama"
    },
    {
      "city": "Jackson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jackson, Alabama"
    },
    {
      "city": "Campbell",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Campbell, Alabama"
    },
    {
      "city": "Thomasville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Thomasville, Alabama"
    },
    {
      "city": "Hollins",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hollins, Alabama"
    },
    {
      "city": "Ashland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ashland, Alabama"
    },
    {
      "city": "Cragford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cragford, Alabama"
    },
    {
      "city": "Delta",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Delta, Alabama"
    },
    {
      "city": "Lineville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lineville, Alabama"
    },
    {
      "city": "Millerville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Millerville, Alabama"
    },
    {
      "city": "Edwardsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Edwardsville, Alabama"
    },
    {
      "city": "Fruithurst",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fruithurst, Alabama"
    },
    {
      "city": "Heflin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Heflin, Alabama"
    },
    {
      "city": "Muscadine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Muscadine, Alabama"
    },
    {
      "city": "Ranburne",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ranburne, Alabama"
    },
    {
      "city": "Elba",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Elba, Alabama"
    },
    {
      "city": "Enterprise",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Enterprise, Alabama"
    },
    {
      "city": "Jack",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jack, Alabama"
    },
    {
      "city": "New Brockton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "New Brockton, Alabama"
    },
    {
      "city": "Kinston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kinston, Alabama"
    },
    {
      "city": "Cherokee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cherokee, Alabama"
    },
    {
      "city": "Leighton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Leighton, Alabama"
    },
    {
      "city": "Sheffield",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sheffield, Alabama"
    },
    {
      "city": "Muscle Shoals",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Muscle Shoals, Alabama"
    },
    {
      "city": "Tuscumbia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tuscumbia, Alabama"
    },
    {
      "city": "Evergreen",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Evergreen, Alabama"
    },
    {
      "city": "Brooklyn",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brooklyn, Alabama"
    },
    {
      "city": "Castleberry",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Castleberry, Alabama"
    },
    {
      "city": "Lenox",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lenox, Alabama"
    },
    {
      "city": "Range",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Range, Alabama"
    },
    {
      "city": "Repton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Repton, Alabama"
    },
    {
      "city": "Goodwater",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Goodwater, Alabama"
    },
    {
      "city": "Kellyton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kellyton, Alabama"
    },
    {
      "city": "Rockford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Rockford, Alabama"
    },
    {
      "city": "Weogufka",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Weogufka, Alabama"
    },
    {
      "city": "Equality",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Equality, Alabama"
    },
    {
      "city": "Gantt",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gantt, Alabama"
    },
    {
      "city": "Andalusia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Andalusia, Alabama"
    },
    {
      "city": "Florala",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Florala, Alabama"
    },
    {
      "city": "Lockhart",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lockhart, Alabama"
    },
    {
      "city": "Opp",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Opp, Alabama"
    },
    {
      "city": "Red Level",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Red Level, Alabama"
    },
    {
      "city": "River Falls",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "River Falls, Alabama"
    },
    {
      "city": "Wing",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wing, Alabama"
    },
    {
      "city": "Brantley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brantley, Alabama"
    },
    {
      "city": "Dozier",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dozier, Alabama"
    },
    {
      "city": "Glenwood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Glenwood, Alabama"
    },
    {
      "city": "Highland Home",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Highland Home, Alabama"
    },
    {
      "city": "Honoraville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Honoraville, Alabama"
    },
    {
      "city": "Luverne",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Luverne, Alabama"
    },
    {
      "city": "Petrey",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Petrey, Alabama"
    },
    {
      "city": "Rutledge",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Rutledge, Alabama"
    },
    {
      "city": "Baileyton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Baileyton, Alabama"
    },
    {
      "city": "Bremen",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bremen, Alabama"
    },
    {
      "city": "Crane Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Crane Hill, Alabama"
    },
    {
      "city": "Cullman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cullman, Alabama"
    },
    {
      "city": "Garden City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Garden City, Alabama"
    },
    {
      "city": "Hanceville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hanceville, Alabama"
    },
    {
      "city": "Holly Pond",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Holly Pond, Alabama"
    },
    {
      "city": "Joppa",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Joppa, Alabama"
    },
    {
      "city": "Logan",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Logan, Alabama"
    },
    {
      "city": "Vinemont",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vinemont, Alabama"
    },
    {
      "city": "Ariton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ariton, Alabama"
    },
    {
      "city": "Clopton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clopton, Alabama"
    },
    {
      "city": "Daleville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Daleville, Alabama"
    },
    {
      "city": "Midland City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Midland City, Alabama"
    },
    {
      "city": "Newton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Newton, Alabama"
    },
    {
      "city": "Ozark",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ozark, Alabama"
    },
    {
      "city": "Fort Rucker",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fort Rucker, Alabama"
    },
    {
      "city": "Pinckard",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pinckard, Alabama"
    },
    {
      "city": "Skipperville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Skipperville, Alabama"
    },
    {
      "city": "Selma",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Selma, Alabama"
    },
    {
      "city": "Plantersville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Plantersville, Alabama"
    },
    {
      "city": "Marion Junction",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Marion Junction, Alabama"
    },
    {
      "city": "Minter",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Minter, Alabama"
    },
    {
      "city": "Orrville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Orrville, Alabama"
    },
    {
      "city": "Safford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Safford, Alabama"
    },
    {
      "city": "Sardis",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sardis, Alabama"
    },
    {
      "city": "Tyler",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tyler, Alabama"
    },
    {
      "city": "Collinsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Collinsville, Alabama"
    },
    {
      "city": "Crossville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Crossville, Alabama"
    },
    {
      "city": "Dawson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dawson, Alabama"
    },
    {
      "city": "Fort Payne",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fort Payne, Alabama"
    },
    {
      "city": "Fyffe",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fyffe, Alabama"
    },
    {
      "city": "Geraldine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Geraldine, Alabama"
    },
    {
      "city": "Groveoak",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Groveoak, Alabama"
    },
    {
      "city": "Henagar",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Henagar, Alabama"
    },
    {
      "city": "Ider",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ider, Alabama"
    },
    {
      "city": "Mentone",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mentone, Alabama"
    },
    {
      "city": "Rainsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Rainsville, Alabama"
    },
    {
      "city": "Sylvania",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sylvania, Alabama"
    },
    {
      "city": "Valley Head",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Valley Head, Alabama"
    },
    {
      "city": "Coosada",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coosada, Alabama"
    },
    {
      "city": "Deatsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Deatsville, Alabama"
    },
    {
      "city": "Eclectic",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eclectic, Alabama"
    },
    {
      "city": "Elmore",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Elmore, Alabama"
    },
    {
      "city": "Kent",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kent, Alabama"
    },
    {
      "city": "Millbrook",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Millbrook, Alabama"
    },
    {
      "city": "Tallassee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tallassee, Alabama"
    },
    {
      "city": "Titus",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Titus, Alabama"
    },
    {
      "city": "Wetumpka",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wetumpka, Alabama"
    },
    {
      "city": "Brewton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brewton, Alabama"
    },
    {
      "city": "Flomaton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Flomaton, Alabama"
    },
    {
      "city": "Atmore",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Atmore, Alabama"
    },
    {
      "city": "Huxford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Huxford, Alabama"
    },
    {
      "city": "Gadsden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gadsden, Alabama"
    },
    {
      "city": "Rainbow City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Rainbow City, Alabama"
    },
    {
      "city": "Altoona",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Altoona, Alabama"
    },
    {
      "city": "Attalla",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Attalla, Alabama"
    },
    {
      "city": "Gallant",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gallant, Alabama"
    },
    {
      "city": "Walnut Grove",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Walnut Grove, Alabama"
    },
    {
      "city": "Bankston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bankston, Alabama"
    },
    {
      "city": "Belk",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Belk, Alabama"
    },
    {
      "city": "Berry",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Berry, Alabama"
    },
    {
      "city": "Fayette",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fayette, Alabama"
    },
    {
      "city": "Glen Allen",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Glen Allen, Alabama"
    },
    {
      "city": "Hodges",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hodges, Alabama"
    },
    {
      "city": "Phil Campbell",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Phil Campbell, Alabama"
    },
    {
      "city": "Red Bay",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Red Bay, Alabama"
    },
    {
      "city": "Spruce Pine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Spruce Pine, Alabama"
    },
    {
      "city": "Vina",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vina, Alabama"
    },
    {
      "city": "Russellville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Russellville, Alabama"
    },
    {
      "city": "Bellwood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bellwood, Alabama"
    },
    {
      "city": "Black",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Black, Alabama"
    },
    {
      "city": "Chancellor",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Chancellor, Alabama"
    },
    {
      "city": "Coffee Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coffee Springs, Alabama"
    },
    {
      "city": "Geneva",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Geneva, Alabama"
    },
    {
      "city": "Hartford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hartford, Alabama"
    },
    {
      "city": "Malvern",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Malvern, Alabama"
    },
    {
      "city": "Slocomb",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Slocomb, Alabama"
    },
    {
      "city": "Samson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Samson, Alabama"
    },
    {
      "city": "Boligee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Boligee, Alabama"
    },
    {
      "city": "Clinton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clinton, Alabama"
    },
    {
      "city": "Eutaw",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eutaw, Alabama"
    },
    {
      "city": "Knoxville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Knoxville, Alabama"
    },
    {
      "city": "West Greene",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "West Greene, Alabama"
    },
    {
      "city": "Forkland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Forkland, Alabama"
    },
    {
      "city": "Akron",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Akron, Alabama"
    },
    {
      "city": "Moundville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Moundville, Alabama"
    },
    {
      "city": "Gallion",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gallion, Alabama"
    },
    {
      "city": "Greensboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Greensboro, Alabama"
    },
    {
      "city": "Newbern",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Newbern, Alabama"
    },
    {
      "city": "Sawyerville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sawyerville, Alabama"
    },
    {
      "city": "Abbeville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Abbeville, Alabama"
    },
    {
      "city": "Headland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Headland, Alabama"
    },
    {
      "city": "Newville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Newville, Alabama"
    },
    {
      "city": "Shorterville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Shorterville, Alabama"
    },
    {
      "city": "Dothan",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dothan, Alabama"
    },
    {
      "city": "Ashford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ashford, Alabama"
    },
    {
      "city": "Columbia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Columbia, Alabama"
    },
    {
      "city": "Cottonwood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cottonwood, Alabama"
    },
    {
      "city": "Cowarts",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cowarts, Alabama"
    },
    {
      "city": "Gordon",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gordon, Alabama"
    },
    {
      "city": "Pansey",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pansey, Alabama"
    },
    {
      "city": "Webb",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Webb, Alabama"
    },
    {
      "city": "Bridgeport",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bridgeport, Alabama"
    },
    {
      "city": "Dutton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dutton, Alabama"
    },
    {
      "city": "Estillfork",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Estillfork, Alabama"
    },
    {
      "city": "Fackler",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fackler, Alabama"
    },
    {
      "city": "Hollytree",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hollytree, Alabama"
    },
    {
      "city": "Hollywood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hollywood, Alabama"
    },
    {
      "city": "Langston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Langston, Alabama"
    },
    {
      "city": "Paint Rock",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Paint Rock, Alabama"
    },
    {
      "city": "Pisgah",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pisgah, Alabama"
    },
    {
      "city": "Princeton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Princeton, Alabama"
    },
    {
      "city": "Scottsboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Scottsboro, Alabama"
    },
    {
      "city": "Section",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Section, Alabama"
    },
    {
      "city": "Stevenson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Stevenson, Alabama"
    },
    {
      "city": "Trenton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Trenton, Alabama"
    },
    {
      "city": "Woodville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Woodville, Alabama"
    },
    {
      "city": "Bryant",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bryant, Alabama"
    },
    {
      "city": "Flat Rock",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Flat Rock, Alabama"
    },
    {
      "city": "Higdon",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Higdon, Alabama"
    },
    {
      "city": "Adamsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Adamsville, Alabama"
    },
    {
      "city": "Adger",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Adger, Alabama"
    },
    {
      "city": "Alton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alton, Alabama"
    },
    {
      "city": "Bessemer",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bessemer, Alabama"
    },
    {
      "city": "Brookside",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brookside, Alabama"
    },
    {
      "city": "Clay",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Clay, Alabama"
    },
    {
      "city": "Docena",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Docena, Alabama"
    },
    {
      "city": "Dolomite",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dolomite, Alabama"
    },
    {
      "city": "Fairfield",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fairfield, Alabama"
    },
    {
      "city": "Fultondale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fultondale, Alabama"
    },
    {
      "city": "Gardendale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gardendale, Alabama"
    },
    {
      "city": "Graysville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Graysville, Alabama"
    },
    {
      "city": "Kimberly",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kimberly, Alabama"
    },
    {
      "city": "Leeds",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Leeds, Alabama"
    },
    {
      "city": "Mc Calla",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mc Calla, Alabama"
    },
    {
      "city": "Morris",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Morris, Alabama"
    },
    {
      "city": "Mount Olive",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mount Olive, Alabama"
    },
    {
      "city": "Mulga",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mulga, Alabama"
    },
    {
      "city": "New Castle",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "New Castle, Alabama"
    },
    {
      "city": "Palmerdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Palmerdale, Alabama"
    },
    {
      "city": "Pinson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pinson, Alabama"
    },
    {
      "city": "Pleasant Grove",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pleasant Grove, Alabama"
    },
    {
      "city": "Sayre",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sayre, Alabama"
    },
    {
      "city": "Shannon",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Shannon, Alabama"
    },
    {
      "city": "Trafford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Trafford, Alabama"
    },
    {
      "city": "Trussville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Trussville, Alabama"
    },
    {
      "city": "Warrior",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Warrior, Alabama"
    },
    {
      "city": "Watson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Watson, Alabama"
    },
    {
      "city": "Birmingham",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Birmingham, Alabama"
    },
    {
      "city": "Beaverton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Beaverton, Alabama"
    },
    {
      "city": "Detroit",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Detroit, Alabama"
    },
    {
      "city": "Kennedy",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kennedy, Alabama"
    },
    {
      "city": "Millport",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Millport, Alabama"
    },
    {
      "city": "Sulligent",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sulligent, Alabama"
    },
    {
      "city": "Vernon",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vernon, Alabama"
    },
    {
      "city": "Anderson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Anderson, Alabama"
    },
    {
      "city": "Cloverdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cloverdale, Alabama"
    },
    {
      "city": "Florence",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Florence, Alabama"
    },
    {
      "city": "Killen",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Killen, Alabama"
    },
    {
      "city": "Lexington",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lexington, Alabama"
    },
    {
      "city": "Rogersville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Rogersville, Alabama"
    },
    {
      "city": "Waterloo",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Waterloo, Alabama"
    },
    {
      "city": "Courtland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Courtland, Alabama"
    },
    {
      "city": "Hillsboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hillsboro, Alabama"
    },
    {
      "city": "Moulton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Moulton, Alabama"
    },
    {
      "city": "Mount Hope",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mount Hope, Alabama"
    },
    {
      "city": "Town Creek",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Town Creek, Alabama"
    },
    {
      "city": "Opelika",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Opelika, Alabama"
    },
    {
      "city": "Auburn",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Auburn, Alabama"
    },
    {
      "city": "Auburn University",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Auburn University, Alabama"
    },
    {
      "city": "Loachapoka",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Loachapoka, Alabama"
    },
    {
      "city": "Salem",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Salem, Alabama"
    },
    {
      "city": "Smiths Station",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Smiths Station, Alabama"
    },
    {
      "city": "Waverly",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Waverly, Alabama"
    },
    {
      "city": "Athens",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Athens, Alabama"
    },
    {
      "city": "Belle Mina",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Belle Mina, Alabama"
    },
    {
      "city": "Elkmont",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Elkmont, Alabama"
    },
    {
      "city": "Lester",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lester, Alabama"
    },
    {
      "city": "Mooresville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mooresville, Alabama"
    },
    {
      "city": "Tanner",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tanner, Alabama"
    },
    {
      "city": "Ardmore",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ardmore, Alabama"
    },
    {
      "city": "Capshaw",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Capshaw, Alabama"
    },
    {
      "city": "Fort Deposit",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fort Deposit, Alabama"
    },
    {
      "city": "Hayneville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hayneville, Alabama"
    },
    {
      "city": "Letohatchee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Letohatchee, Alabama"
    },
    {
      "city": "Lowndesboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lowndesboro, Alabama"
    },
    {
      "city": "Fort Davis",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fort Davis, Alabama"
    },
    {
      "city": "Hardaway",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hardaway, Alabama"
    },
    {
      "city": "Shorter",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Shorter, Alabama"
    },
    {
      "city": "Tuskegee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tuskegee, Alabama"
    },
    {
      "city": "Tuskegee Institute",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tuskegee Institute, Alabama"
    },
    {
      "city": "Notasulga",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Notasulga, Alabama"
    },
    {
      "city": "Brownsboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brownsboro, Alabama"
    },
    {
      "city": "Gurley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gurley, Alabama"
    },
    {
      "city": "Harvest",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Harvest, Alabama"
    },
    {
      "city": "Hazel Green",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hazel Green, Alabama"
    },
    {
      "city": "Madison",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Madison, Alabama"
    },
    {
      "city": "Meridianville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Meridianville, Alabama"
    },
    {
      "city": "New Hope",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "New Hope, Alabama"
    },
    {
      "city": "New Market",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "New Market, Alabama"
    },
    {
      "city": "Normal",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Normal, Alabama"
    },
    {
      "city": "Owens Cross Roads",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Owens Cross Roads, Alabama"
    },
    {
      "city": "Ryland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ryland, Alabama"
    },
    {
      "city": "Toney",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Toney, Alabama"
    },
    {
      "city": "Huntsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Huntsville, Alabama"
    },
    {
      "city": "Demopolis",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Demopolis, Alabama"
    },
    {
      "city": "Dixons Mills",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dixons Mills, Alabama"
    },
    {
      "city": "Faunsdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Faunsdale, Alabama"
    },
    {
      "city": "Jefferson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jefferson, Alabama"
    },
    {
      "city": "Linden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Linden, Alabama"
    },
    {
      "city": "Magnolia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Magnolia, Alabama"
    },
    {
      "city": "Myrtlewood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Myrtlewood, Alabama"
    },
    {
      "city": "Nanafalia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Nanafalia, Alabama"
    },
    {
      "city": "Sweet Water",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sweet Water, Alabama"
    },
    {
      "city": "Thomaston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Thomaston, Alabama"
    },
    {
      "city": "Bear Creek",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bear Creek, Alabama"
    },
    {
      "city": "Brilliant",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brilliant, Alabama"
    },
    {
      "city": "Guin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Guin, Alabama"
    },
    {
      "city": "Hackleburg",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hackleburg, Alabama"
    },
    {
      "city": "Hamilton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hamilton, Alabama"
    },
    {
      "city": "Winfield",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Winfield, Alabama"
    },
    {
      "city": "Arab",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Arab, Alabama"
    },
    {
      "city": "Union Grove",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Union Grove, Alabama"
    },
    {
      "city": "Grant",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Grant, Alabama"
    },
    {
      "city": "Albertville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Albertville, Alabama"
    },
    {
      "city": "Boaz",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Boaz, Alabama"
    },
    {
      "city": "Douglas",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Douglas, Alabama"
    },
    {
      "city": "Guntersville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Guntersville, Alabama"
    },
    {
      "city": "Horton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Horton, Alabama"
    },
    {
      "city": "Axis",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Axis, Alabama"
    },
    {
      "city": "Bayou La Batre",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bayou La Batre, Alabama"
    },
    {
      "city": "Bucks",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bucks, Alabama"
    },
    {
      "city": "Chunchula",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Chunchula, Alabama"
    },
    {
      "city": "Citronelle",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Citronelle, Alabama"
    },
    {
      "city": "Coden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coden, Alabama"
    },
    {
      "city": "Creola",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Creola, Alabama"
    },
    {
      "city": "Dauphin Island",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dauphin Island, Alabama"
    },
    {
      "city": "Grand Bay",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Grand Bay, Alabama"
    },
    {
      "city": "Irvington",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Irvington, Alabama"
    },
    {
      "city": "Mount Vernon",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mount Vernon, Alabama"
    },
    {
      "city": "Saint Elmo",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Saint Elmo, Alabama"
    },
    {
      "city": "Saraland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Saraland, Alabama"
    },
    {
      "city": "Satsuma",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Satsuma, Alabama"
    },
    {
      "city": "Semmes",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Semmes, Alabama"
    },
    {
      "city": "Theodore",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Theodore, Alabama"
    },
    {
      "city": "Wilmer",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wilmer, Alabama"
    },
    {
      "city": "Mobile",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mobile, Alabama"
    },
    {
      "city": "Eight Mile",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eight Mile, Alabama"
    },
    {
      "city": "Beatrice",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Beatrice, Alabama"
    },
    {
      "city": "Excel",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Excel, Alabama"
    },
    {
      "city": "Franklin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Franklin, Alabama"
    },
    {
      "city": "Frisco City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Frisco City, Alabama"
    },
    {
      "city": "Goodway",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Goodway, Alabama"
    },
    {
      "city": "Megargel",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Megargel, Alabama"
    },
    {
      "city": "Mexia",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mexia, Alabama"
    },
    {
      "city": "Monroeville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Monroeville, Alabama"
    },
    {
      "city": "Perdue Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Perdue Hill, Alabama"
    },
    {
      "city": "Peterman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Peterman, Alabama"
    },
    {
      "city": "Uriah",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Uriah, Alabama"
    },
    {
      "city": "Vredenburgh",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vredenburgh, Alabama"
    },
    {
      "city": "Cecil",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cecil, Alabama"
    },
    {
      "city": "Grady",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Grady, Alabama"
    },
    {
      "city": "Hope Hull",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hope Hull, Alabama"
    },
    {
      "city": "Lapine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lapine, Alabama"
    },
    {
      "city": "Mathews",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mathews, Alabama"
    },
    {
      "city": "Mount Meigs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mount Meigs, Alabama"
    },
    {
      "city": "Pike Road",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pike Road, Alabama"
    },
    {
      "city": "Pine Level",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pine Level, Alabama"
    },
    {
      "city": "Ramer",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ramer, Alabama"
    },
    {
      "city": "Montgomery",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Montgomery, Alabama"
    },
    {
      "city": "Decatur",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Decatur, Alabama"
    },
    {
      "city": "Danville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Danville, Alabama"
    },
    {
      "city": "Eva",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eva, Alabama"
    },
    {
      "city": "Falkville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Falkville, Alabama"
    },
    {
      "city": "Hartselle",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hartselle, Alabama"
    },
    {
      "city": "Somerville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Somerville, Alabama"
    },
    {
      "city": "Trinity",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Trinity, Alabama"
    },
    {
      "city": "Laceys Spring",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Laceys Spring, Alabama"
    },
    {
      "city": "Valhermoso Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Valhermoso Springs, Alabama"
    },
    {
      "city": "Marion",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Marion, Alabama"
    },
    {
      "city": "Uniontown",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Uniontown, Alabama"
    },
    {
      "city": "Aliceville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Aliceville, Alabama"
    },
    {
      "city": "Carrollton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Carrollton, Alabama"
    },
    {
      "city": "Ethelsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ethelsville, Alabama"
    },
    {
      "city": "Gordo",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gordo, Alabama"
    },
    {
      "city": "Mc Shan",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mc Shan, Alabama"
    },
    {
      "city": "Reform",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Reform, Alabama"
    },
    {
      "city": "Banks",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Banks, Alabama"
    },
    {
      "city": "Brundidge",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brundidge, Alabama"
    },
    {
      "city": "Goshen",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Goshen, Alabama"
    },
    {
      "city": "Troy",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Troy, Alabama"
    },
    {
      "city": "Graham",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Graham, Alabama"
    },
    {
      "city": "Roanoke",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Roanoke, Alabama"
    },
    {
      "city": "Wadley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wadley, Alabama"
    },
    {
      "city": "Wedowee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wedowee, Alabama"
    },
    {
      "city": "Woodland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Woodland, Alabama"
    },
    {
      "city": "Cottonton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cottonton, Alabama"
    },
    {
      "city": "Fort Mitchell",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fort Mitchell, Alabama"
    },
    {
      "city": "Hatchechubbee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hatchechubbee, Alabama"
    },
    {
      "city": "Holy Trinity",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Holy Trinity, Alabama"
    },
    {
      "city": "Hurtsboro",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Hurtsboro, Alabama"
    },
    {
      "city": "Phenix City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Phenix City, Alabama"
    },
    {
      "city": "Pittsview",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pittsview, Alabama"
    },
    {
      "city": "Seale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Seale, Alabama"
    },
    {
      "city": "Moody",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Moody, Alabama"
    },
    {
      "city": "Cook Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cook Springs, Alabama"
    },
    {
      "city": "Cropwell",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cropwell, Alabama"
    },
    {
      "city": "Margaret",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Margaret, Alabama"
    },
    {
      "city": "Odenville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Odenville, Alabama"
    },
    {
      "city": "Pell City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pell City, Alabama"
    },
    {
      "city": "Ragland",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ragland, Alabama"
    },
    {
      "city": "Riverside",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Riverside, Alabama"
    },
    {
      "city": "Springville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Springville, Alabama"
    },
    {
      "city": "Wattsville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wattsville, Alabama"
    },
    {
      "city": "Ashville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ashville, Alabama"
    },
    {
      "city": "Steele",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Steele, Alabama"
    },
    {
      "city": "Alabaster",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alabaster, Alabama"
    },
    {
      "city": "Calera",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Calera, Alabama"
    },
    {
      "city": "Chelsea",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Chelsea, Alabama"
    },
    {
      "city": "Columbiana",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Columbiana, Alabama"
    },
    {
      "city": "Harpersville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Harpersville, Alabama"
    },
    {
      "city": "Helena",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Helena, Alabama"
    },
    {
      "city": "Maylene",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Maylene, Alabama"
    },
    {
      "city": "Montevallo",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Montevallo, Alabama"
    },
    {
      "city": "Pelham",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pelham, Alabama"
    },
    {
      "city": "Saginaw",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Saginaw, Alabama"
    },
    {
      "city": "Shelby",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Shelby, Alabama"
    },
    {
      "city": "Siluria",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Siluria, Alabama"
    },
    {
      "city": "Sterrett",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sterrett, Alabama"
    },
    {
      "city": "Vandiver",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vandiver, Alabama"
    },
    {
      "city": "Vincent",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vincent, Alabama"
    },
    {
      "city": "Westover",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Westover, Alabama"
    },
    {
      "city": "Wilsonville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wilsonville, Alabama"
    },
    {
      "city": "Wilton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wilton, Alabama"
    },
    {
      "city": "Emelle",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Emelle, Alabama"
    },
    {
      "city": "Epes",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Epes, Alabama"
    },
    {
      "city": "Gainesville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Gainesville, Alabama"
    },
    {
      "city": "Livingston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Livingston, Alabama"
    },
    {
      "city": "Panola",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Panola, Alabama"
    },
    {
      "city": "Bellamy",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bellamy, Alabama"
    },
    {
      "city": "Cuba",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cuba, Alabama"
    },
    {
      "city": "York",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "York, Alabama"
    },
    {
      "city": "Alpine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alpine, Alabama"
    },
    {
      "city": "Bon Air",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Bon Air, Alabama"
    },
    {
      "city": "Childersburg",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Childersburg, Alabama"
    },
    {
      "city": "Lincoln",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lincoln, Alabama"
    },
    {
      "city": "Sycamore",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sycamore, Alabama"
    },
    {
      "city": "Sylacauga",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sylacauga, Alabama"
    },
    {
      "city": "Talladega",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Talladega, Alabama"
    },
    {
      "city": "Munford",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Munford, Alabama"
    },
    {
      "city": "Alexander City",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alexander City, Alabama"
    },
    {
      "city": "East Tallassee",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "East Tallassee, Alabama"
    },
    {
      "city": "Daviston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Daviston, Alabama"
    },
    {
      "city": "Camp Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Camp Hill, Alabama"
    },
    {
      "city": "Dadeville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dadeville, Alabama"
    },
    {
      "city": "Jacksons Gap",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jacksons Gap, Alabama"
    },
    {
      "city": "Tuscaloosa",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tuscaloosa, Alabama"
    },
    {
      "city": "Abernant",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Abernant, Alabama"
    },
    {
      "city": "Brookwood",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Brookwood, Alabama"
    },
    {
      "city": "Buhl",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Buhl, Alabama"
    },
    {
      "city": "Coaling",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coaling, Alabama"
    },
    {
      "city": "Coker",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coker, Alabama"
    },
    {
      "city": "Cottondale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cottondale, Alabama"
    },
    {
      "city": "Duncanville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Duncanville, Alabama"
    },
    {
      "city": "Echola",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Echola, Alabama"
    },
    {
      "city": "Elrod",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Elrod, Alabama"
    },
    {
      "city": "Fosters",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fosters, Alabama"
    },
    {
      "city": "Kellerman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kellerman, Alabama"
    },
    {
      "city": "Northport",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Northport, Alabama"
    },
    {
      "city": "Peterson",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Peterson, Alabama"
    },
    {
      "city": "Ralph",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Ralph, Alabama"
    },
    {
      "city": "Samantha",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Samantha, Alabama"
    },
    {
      "city": "Vance",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vance, Alabama"
    },
    {
      "city": "Burnwell",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Burnwell, Alabama"
    },
    {
      "city": "Dora",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Dora, Alabama"
    },
    {
      "city": "Empire",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Empire, Alabama"
    },
    {
      "city": "Quinton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Quinton, Alabama"
    },
    {
      "city": "Sumiton",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sumiton, Alabama"
    },
    {
      "city": "Jasper",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Jasper, Alabama"
    },
    {
      "city": "Carbon Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Carbon Hill, Alabama"
    },
    {
      "city": "Cordova",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Cordova, Alabama"
    },
    {
      "city": "Eldridge",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Eldridge, Alabama"
    },
    {
      "city": "Goodsprings",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Goodsprings, Alabama"
    },
    {
      "city": "Kansas",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Kansas, Alabama"
    },
    {
      "city": "Nauvoo",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Nauvoo, Alabama"
    },
    {
      "city": "Oakman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Oakman, Alabama"
    },
    {
      "city": "Parrish",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Parrish, Alabama"
    },
    {
      "city": "Sipsey",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sipsey, Alabama"
    },
    {
      "city": "Townley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Townley, Alabama"
    },
    {
      "city": "Calvert",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Calvert, Alabama"
    },
    {
      "city": "Chatom",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Chatom, Alabama"
    },
    {
      "city": "Deer Park",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Deer Park, Alabama"
    },
    {
      "city": "Frankville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Frankville, Alabama"
    },
    {
      "city": "Fruitdale",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Fruitdale, Alabama"
    },
    {
      "city": "Leroy",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Leroy, Alabama"
    },
    {
      "city": "Mc Intosh",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mc Intosh, Alabama"
    },
    {
      "city": "Malcolm",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Malcolm, Alabama"
    },
    {
      "city": "Millry",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Millry, Alabama"
    },
    {
      "city": "Saint Stephens",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Saint Stephens, Alabama"
    },
    {
      "city": "Sunflower",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Sunflower, Alabama"
    },
    {
      "city": "Tibbie",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Tibbie, Alabama"
    },
    {
      "city": "Vinegar Bend",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Vinegar Bend, Alabama"
    },
    {
      "city": "Wagarville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Wagarville, Alabama"
    },
    {
      "city": "Coy",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Coy, Alabama"
    },
    {
      "city": "Alberta",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Alberta, Alabama"
    },
    {
      "city": "Arlington",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Arlington, Alabama"
    },
    {
      "city": "Boykin",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Boykin, Alabama"
    },
    {
      "city": "Camden",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Camden, Alabama"
    },
    {
      "city": "Catherine",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Catherine, Alabama"
    },
    {
      "city": "Furman",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Furman, Alabama"
    },
    {
      "city": "Lower Peach Tree",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lower Peach Tree, Alabama"
    },
    {
      "city": "Mc Williams",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Mc Williams, Alabama"
    },
    {
      "city": "Oak Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Oak Hill, Alabama"
    },
    {
      "city": "Pine Apple",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pine Apple, Alabama"
    },
    {
      "city": "Pine Hill",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Pine Hill, Alabama"
    },
    {
      "city": "Addison",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Addison, Alabama"
    },
    {
      "city": "Arley",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Arley, Alabama"
    },
    {
      "city": "Delmar",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Delmar, Alabama"
    },
    {
      "city": "Double Springs",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Double Springs, Alabama"
    },
    {
      "city": "Haleyville",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Haleyville, Alabama"
    },
    {
      "city": "Houston",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Houston, Alabama"
    },
    {
      "city": "Lynn",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Lynn, Alabama"
    },
    {
      "city": "Natural Bridge",
      "state": "Alabama",
      "shortState": "AL",
      "formatedCity": "Natural Bridge, Alabama"
    },
    {
      "city": "Almyra",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Almyra, Arkansas"
    },
    {
      "city": "Casscoe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Casscoe, Arkansas"
    },
    {
      "city": "Crocketts Bluff",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Crocketts Bluff, Arkansas"
    },
    {
      "city": "De Witt",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "De Witt, Arkansas"
    },
    {
      "city": "Ethel",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ethel, Arkansas"
    },
    {
      "city": "Gillett",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gillett, Arkansas"
    },
    {
      "city": "Humphrey",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Humphrey, Arkansas"
    },
    {
      "city": "Saint Charles",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saint Charles, Arkansas"
    },
    {
      "city": "Stuttgart",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Stuttgart, Arkansas"
    },
    {
      "city": "Tichnor",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tichnor, Arkansas"
    },
    {
      "city": "Crossett",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Crossett, Arkansas"
    },
    {
      "city": "Fountain Hill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fountain Hill, Arkansas"
    },
    {
      "city": "Hamburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hamburg, Arkansas"
    },
    {
      "city": "Montrose",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Montrose, Arkansas"
    },
    {
      "city": "Parkdale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Parkdale, Arkansas"
    },
    {
      "city": "Portland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Portland, Arkansas"
    },
    {
      "city": "Wilmot",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wilmot, Arkansas"
    },
    {
      "city": "Gamaliel",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gamaliel, Arkansas"
    },
    {
      "city": "Henderson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Henderson, Arkansas"
    },
    {
      "city": "Big Flat",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Big Flat, Arkansas"
    },
    {
      "city": "Clarkridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Clarkridge, Arkansas"
    },
    {
      "city": "Cotter",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cotter, Arkansas"
    },
    {
      "city": "Gassville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gassville, Arkansas"
    },
    {
      "city": "Lakeview",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lakeview, Arkansas"
    },
    {
      "city": "Midway",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Midway, Arkansas"
    },
    {
      "city": "Mountain Home",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mountain Home, Arkansas"
    },
    {
      "city": "Norfork",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Norfork, Arkansas"
    },
    {
      "city": "Avoca",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Avoca, Arkansas"
    },
    {
      "city": "Bentonville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bentonville, Arkansas"
    },
    {
      "city": "Bella Vista",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bella Vista, Arkansas"
    },
    {
      "city": "Cave Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cave Springs, Arkansas"
    },
    {
      "city": "Centerton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Centerton, Arkansas"
    },
    {
      "city": "Decatur",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Decatur, Arkansas"
    },
    {
      "city": "Garfield",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Garfield, Arkansas"
    },
    {
      "city": "Gateway",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gateway, Arkansas"
    },
    {
      "city": "Gentry",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gentry, Arkansas"
    },
    {
      "city": "Gravette",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gravette, Arkansas"
    },
    {
      "city": "Hiwasse",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hiwasse, Arkansas"
    },
    {
      "city": "Lowell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lowell, Arkansas"
    },
    {
      "city": "Maysville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Maysville, Arkansas"
    },
    {
      "city": "Pea Ridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pea Ridge, Arkansas"
    },
    {
      "city": "Rogers",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rogers, Arkansas"
    },
    {
      "city": "Siloam Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Siloam Springs, Arkansas"
    },
    {
      "city": "Sulphur Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sulphur Springs, Arkansas"
    },
    {
      "city": "Little Rock",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Little Rock, Arkansas"
    },
    {
      "city": "Harrison",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Harrison, Arkansas"
    },
    {
      "city": "Alpena",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alpena, Arkansas"
    },
    {
      "city": "Bergman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bergman, Arkansas"
    },
    {
      "city": "Diamond City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Diamond City, Arkansas"
    },
    {
      "city": "Everton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Everton, Arkansas"
    },
    {
      "city": "Lead Hill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lead Hill, Arkansas"
    },
    {
      "city": "Omaha",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Omaha, Arkansas"
    },
    {
      "city": "Valley Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Valley Springs, Arkansas"
    },
    {
      "city": "Banks",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Banks, Arkansas"
    },
    {
      "city": "Hermitage",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hermitage, Arkansas"
    },
    {
      "city": "Jersey",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jersey, Arkansas"
    },
    {
      "city": "Warren",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Warren, Arkansas"
    },
    {
      "city": "Hampton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hampton, Arkansas"
    },
    {
      "city": "Harrell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Harrell, Arkansas"
    },
    {
      "city": "Thornton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Thornton, Arkansas"
    },
    {
      "city": "Beaver",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Beaver, Arkansas"
    },
    {
      "city": "Berryville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Berryville, Arkansas"
    },
    {
      "city": "Eureka Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Eureka Springs, Arkansas"
    },
    {
      "city": "Green Forest",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Green Forest, Arkansas"
    },
    {
      "city": "Oak Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oak Grove, Arkansas"
    },
    {
      "city": "Dermott",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dermott, Arkansas"
    },
    {
      "city": "Eudora",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Eudora, Arkansas"
    },
    {
      "city": "Lake Village",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lake Village, Arkansas"
    },
    {
      "city": "Beirne",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Beirne, Arkansas"
    },
    {
      "city": "Curtis",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Curtis, Arkansas"
    },
    {
      "city": "Gurdon",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gurdon, Arkansas"
    },
    {
      "city": "Whelen Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Whelen Springs, Arkansas"
    },
    {
      "city": "Alpine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alpine, Arkansas"
    },
    {
      "city": "Amity",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Amity, Arkansas"
    },
    {
      "city": "Arkadelphia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Arkadelphia, Arkansas"
    },
    {
      "city": "Okolona",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Okolona, Arkansas"
    },
    {
      "city": "Corning",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Corning, Arkansas"
    },
    {
      "city": "Datto",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Datto, Arkansas"
    },
    {
      "city": "Greenway",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Greenway, Arkansas"
    },
    {
      "city": "Knobel",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Knobel, Arkansas"
    },
    {
      "city": "Mc Dougal",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Dougal, Arkansas"
    },
    {
      "city": "Peach Orchard",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Peach Orchard, Arkansas"
    },
    {
      "city": "Piggott",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Piggott, Arkansas"
    },
    {
      "city": "Pollard",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pollard, Arkansas"
    },
    {
      "city": "Rector",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rector, Arkansas"
    },
    {
      "city": "Saint Francis",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saint Francis, Arkansas"
    },
    {
      "city": "Success",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Success, Arkansas"
    },
    {
      "city": "Edgemont",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Edgemont, Arkansas"
    },
    {
      "city": "Higden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Higden, Arkansas"
    },
    {
      "city": "Prim",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Prim, Arkansas"
    },
    {
      "city": "Quitman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Quitman, Arkansas"
    },
    {
      "city": "Wilburn",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wilburn, Arkansas"
    },
    {
      "city": "Concord",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Concord, Arkansas"
    },
    {
      "city": "Drasco",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Drasco, Arkansas"
    },
    {
      "city": "Heber Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Heber Springs, Arkansas"
    },
    {
      "city": "Ida",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ida, Arkansas"
    },
    {
      "city": "Tumbling Shoals",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tumbling Shoals, Arkansas"
    },
    {
      "city": "Kingsland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Kingsland, Arkansas"
    },
    {
      "city": "New Edinburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "New Edinburg, Arkansas"
    },
    {
      "city": "Rison",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rison, Arkansas"
    },
    {
      "city": "Emerson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Emerson, Arkansas"
    },
    {
      "city": "Mc Neil",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Neil, Arkansas"
    },
    {
      "city": "Magnolia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Magnolia, Arkansas"
    },
    {
      "city": "Waldo",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Waldo, Arkansas"
    },
    {
      "city": "Taylor",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Taylor, Arkansas"
    },
    {
      "city": "Center Ridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Center Ridge, Arkansas"
    },
    {
      "city": "Cleveland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cleveland, Arkansas"
    },
    {
      "city": "Hattieville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hattieville, Arkansas"
    },
    {
      "city": "Jerusalem",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jerusalem, Arkansas"
    },
    {
      "city": "Menifee",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Menifee, Arkansas"
    },
    {
      "city": "Morrilton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Morrilton, Arkansas"
    },
    {
      "city": "Plumerville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Plumerville, Arkansas"
    },
    {
      "city": "Solgohachia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Solgohachia, Arkansas"
    },
    {
      "city": "Springfield",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Springfield, Arkansas"
    },
    {
      "city": "Jonesboro",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jonesboro, Arkansas"
    },
    {
      "city": "Bay",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bay, Arkansas"
    },
    {
      "city": "Black Oak",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Black Oak, Arkansas"
    },
    {
      "city": "Bono",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bono, Arkansas"
    },
    {
      "city": "Brookland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Brookland, Arkansas"
    },
    {
      "city": "Caraway",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Caraway, Arkansas"
    },
    {
      "city": "Cash",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cash, Arkansas"
    },
    {
      "city": "Egypt",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Egypt, Arkansas"
    },
    {
      "city": "Lake City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lake City, Arkansas"
    },
    {
      "city": "Monette",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Monette, Arkansas"
    },
    {
      "city": "State University",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "State University, Arkansas"
    },
    {
      "city": "Alma",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alma, Arkansas"
    },
    {
      "city": "Cedarville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cedarville, Arkansas"
    },
    {
      "city": "Chester",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Chester, Arkansas"
    },
    {
      "city": "Dyer",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dyer, Arkansas"
    },
    {
      "city": "Mountainburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mountainburg, Arkansas"
    },
    {
      "city": "Mulberry",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mulberry, Arkansas"
    },
    {
      "city": "Natural Dam",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Natural Dam, Arkansas"
    },
    {
      "city": "Rudy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rudy, Arkansas"
    },
    {
      "city": "Uniontown",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Uniontown, Arkansas"
    },
    {
      "city": "Van Buren",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Van Buren, Arkansas"
    },
    {
      "city": "West Memphis",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "West Memphis, Arkansas"
    },
    {
      "city": "Clarkedale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Clarkedale, Arkansas"
    },
    {
      "city": "Crawfordsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Crawfordsville, Arkansas"
    },
    {
      "city": "Earle",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Earle, Arkansas"
    },
    {
      "city": "Edmondson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Edmondson, Arkansas"
    },
    {
      "city": "Gilmore",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gilmore, Arkansas"
    },
    {
      "city": "Marion",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marion, Arkansas"
    },
    {
      "city": "Proctor",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Proctor, Arkansas"
    },
    {
      "city": "Turrell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Turrell, Arkansas"
    },
    {
      "city": "Cherry Valley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cherry Valley, Arkansas"
    },
    {
      "city": "Hickory Ridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hickory Ridge, Arkansas"
    },
    {
      "city": "Parkin",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Parkin, Arkansas"
    },
    {
      "city": "Vanndale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Vanndale, Arkansas"
    },
    {
      "city": "Wynne",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wynne, Arkansas"
    },
    {
      "city": "Carthage",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Carthage, Arkansas"
    },
    {
      "city": "Fordyce",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fordyce, Arkansas"
    },
    {
      "city": "Ivan",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ivan, Arkansas"
    },
    {
      "city": "Sparkman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sparkman, Arkansas"
    },
    {
      "city": "Arkansas City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Arkansas City, Arkansas"
    },
    {
      "city": "Dumas",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dumas, Arkansas"
    },
    {
      "city": "Mc Gehee",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Gehee, Arkansas"
    },
    {
      "city": "Pickens",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pickens, Arkansas"
    },
    {
      "city": "Rohwer",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rohwer, Arkansas"
    },
    {
      "city": "Tillar",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tillar, Arkansas"
    },
    {
      "city": "Watson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Watson, Arkansas"
    },
    {
      "city": "Snow Lake",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Snow Lake, Arkansas"
    },
    {
      "city": "Monticello",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Monticello, Arkansas"
    },
    {
      "city": "Wilmar",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wilmar, Arkansas"
    },
    {
      "city": "Winchester",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Winchester, Arkansas"
    },
    {
      "city": "Conway",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Conway, Arkansas"
    },
    {
      "city": "Damascus",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Damascus, Arkansas"
    },
    {
      "city": "Enola",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Enola, Arkansas"
    },
    {
      "city": "Greenbrier",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Greenbrier, Arkansas"
    },
    {
      "city": "Guy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Guy, Arkansas"
    },
    {
      "city": "Mayflower",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mayflower, Arkansas"
    },
    {
      "city": "Mount Vernon",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mount Vernon, Arkansas"
    },
    {
      "city": "Vilonia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Vilonia, Arkansas"
    },
    {
      "city": "Wooster",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wooster, Arkansas"
    },
    {
      "city": "Alix",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alix, Arkansas"
    },
    {
      "city": "Altus",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Altus, Arkansas"
    },
    {
      "city": "Branch",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Branch, Arkansas"
    },
    {
      "city": "Cecil",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cecil, Arkansas"
    },
    {
      "city": "Charleston",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Charleston, Arkansas"
    },
    {
      "city": "Ozark",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ozark, Arkansas"
    },
    {
      "city": "Bexar",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bexar, Arkansas"
    },
    {
      "city": "Camp",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Camp, Arkansas"
    },
    {
      "city": "Elizabeth",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Elizabeth, Arkansas"
    },
    {
      "city": "Gepp",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gepp, Arkansas"
    },
    {
      "city": "Glencoe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Glencoe, Arkansas"
    },
    {
      "city": "Mammoth Spring",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mammoth Spring, Arkansas"
    },
    {
      "city": "Salem",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Salem, Arkansas"
    },
    {
      "city": "Sturkie",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sturkie, Arkansas"
    },
    {
      "city": "Viola",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Viola, Arkansas"
    },
    {
      "city": "Hot Springs National Park",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hot Springs National Park, Arkansas"
    },
    {
      "city": "Hot Springs Village",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hot Springs Village, Arkansas"
    },
    {
      "city": "Jessieville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jessieville, Arkansas"
    },
    {
      "city": "Mountain Pine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mountain Pine, Arkansas"
    },
    {
      "city": "Pearcy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pearcy, Arkansas"
    },
    {
      "city": "Royal",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Royal, Arkansas"
    },
    {
      "city": "Lonsdale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lonsdale, Arkansas"
    },
    {
      "city": "Grapevine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Grapevine, Arkansas"
    },
    {
      "city": "Leola",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Leola, Arkansas"
    },
    {
      "city": "Poyen",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Poyen, Arkansas"
    },
    {
      "city": "Prattsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Prattsville, Arkansas"
    },
    {
      "city": "Sheridan",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sheridan, Arkansas"
    },
    {
      "city": "Beech Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Beech Grove, Arkansas"
    },
    {
      "city": "Delaplaine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Delaplaine, Arkansas"
    },
    {
      "city": "Lafe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lafe, Arkansas"
    },
    {
      "city": "Marmaduke",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marmaduke, Arkansas"
    },
    {
      "city": "Paragould",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Paragould, Arkansas"
    },
    {
      "city": "Walcott",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Walcott, Arkansas"
    },
    {
      "city": "Hope",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hope, Arkansas"
    },
    {
      "city": "Blevins",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Blevins, Arkansas"
    },
    {
      "city": "Columbus",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Columbus, Arkansas"
    },
    {
      "city": "Fulton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fulton, Arkansas"
    },
    {
      "city": "Mc Caskill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Caskill, Arkansas"
    },
    {
      "city": "Ozan",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ozan, Arkansas"
    },
    {
      "city": "Washington",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Washington, Arkansas"
    },
    {
      "city": "Bismarck",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bismarck, Arkansas"
    },
    {
      "city": "Bonnerdale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bonnerdale, Arkansas"
    },
    {
      "city": "Donaldson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Donaldson, Arkansas"
    },
    {
      "city": "Friendship",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Friendship, Arkansas"
    },
    {
      "city": "Benton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Benton, Arkansas"
    },
    {
      "city": "Malvern",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Malvern, Arkansas"
    },
    {
      "city": "Jones Mill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jones Mill, Arkansas"
    },
    {
      "city": "Dierks",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dierks, Arkansas"
    },
    {
      "city": "Mineral Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mineral Springs, Arkansas"
    },
    {
      "city": "Nashville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Nashville, Arkansas"
    },
    {
      "city": "Saratoga",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saratoga, Arkansas"
    },
    {
      "city": "Umpire",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Umpire, Arkansas"
    },
    {
      "city": "Thida",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Thida, Arkansas"
    },
    {
      "city": "Batesville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Batesville, Arkansas"
    },
    {
      "city": "Charlotte",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Charlotte, Arkansas"
    },
    {
      "city": "Cord",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cord, Arkansas"
    },
    {
      "city": "Cushman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cushman, Arkansas"
    },
    {
      "city": "Desha",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Desha, Arkansas"
    },
    {
      "city": "Floral",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Floral, Arkansas"
    },
    {
      "city": "Locust Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Locust Grove, Arkansas"
    },
    {
      "city": "Magness",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Magness, Arkansas"
    },
    {
      "city": "Newark",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Newark, Arkansas"
    },
    {
      "city": "Oil Trough",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oil Trough, Arkansas"
    },
    {
      "city": "Pleasant Plains",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pleasant Plains, Arkansas"
    },
    {
      "city": "Rosie",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rosie, Arkansas"
    },
    {
      "city": "Salado",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Salado, Arkansas"
    },
    {
      "city": "Sulphur Rock",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sulphur Rock, Arkansas"
    },
    {
      "city": "Horseshoe Bend",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Horseshoe Bend, Arkansas"
    },
    {
      "city": "Brockwell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Brockwell, Arkansas"
    },
    {
      "city": "Calico Rock",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Calico Rock, Arkansas"
    },
    {
      "city": "Dolph",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dolph, Arkansas"
    },
    {
      "city": "Franklin",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Franklin, Arkansas"
    },
    {
      "city": "Guion",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Guion, Arkansas"
    },
    {
      "city": "Melbourne",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Melbourne, Arkansas"
    },
    {
      "city": "Mount Pleasant",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mount Pleasant, Arkansas"
    },
    {
      "city": "Oxford",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oxford, Arkansas"
    },
    {
      "city": "Pineville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pineville, Arkansas"
    },
    {
      "city": "Sage",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sage, Arkansas"
    },
    {
      "city": "Violet Hill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Violet Hill, Arkansas"
    },
    {
      "city": "Wideman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wideman, Arkansas"
    },
    {
      "city": "Wiseman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wiseman, Arkansas"
    },
    {
      "city": "Amagon",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Amagon, Arkansas"
    },
    {
      "city": "Beedeville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Beedeville, Arkansas"
    },
    {
      "city": "Diaz",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Diaz, Arkansas"
    },
    {
      "city": "Jacksonport",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jacksonport, Arkansas"
    },
    {
      "city": "Newport",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Newport, Arkansas"
    },
    {
      "city": "Tupelo",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tupelo, Arkansas"
    },
    {
      "city": "Grubbs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Grubbs, Arkansas"
    },
    {
      "city": "Swifton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Swifton, Arkansas"
    },
    {
      "city": "Tuckerman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tuckerman, Arkansas"
    },
    {
      "city": "Pine Bluff",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pine Bluff, Arkansas"
    },
    {
      "city": "White Hall",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "White Hall, Arkansas"
    },
    {
      "city": "Moscow",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Moscow, Arkansas"
    },
    {
      "city": "Altheimer",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Altheimer, Arkansas"
    },
    {
      "city": "Jefferson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jefferson, Arkansas"
    },
    {
      "city": "Redfield",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Redfield, Arkansas"
    },
    {
      "city": "Reydell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Reydell, Arkansas"
    },
    {
      "city": "Sherrill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sherrill, Arkansas"
    },
    {
      "city": "Tucker",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tucker, Arkansas"
    },
    {
      "city": "Wabbaseka",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wabbaseka, Arkansas"
    },
    {
      "city": "Wright",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wright, Arkansas"
    },
    {
      "city": "Clarksville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Clarksville, Arkansas"
    },
    {
      "city": "Coal Hill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Coal Hill, Arkansas"
    },
    {
      "city": "Hagarville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hagarville, Arkansas"
    },
    {
      "city": "Hartman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hartman, Arkansas"
    },
    {
      "city": "Knoxville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Knoxville, Arkansas"
    },
    {
      "city": "Lamar",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lamar, Arkansas"
    },
    {
      "city": "Oark",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oark, Arkansas"
    },
    {
      "city": "Ozone",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ozone, Arkansas"
    },
    {
      "city": "Bradley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bradley, Arkansas"
    },
    {
      "city": "Buckner",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Buckner, Arkansas"
    },
    {
      "city": "Garland City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Garland City, Arkansas"
    },
    {
      "city": "Lewisville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lewisville, Arkansas"
    },
    {
      "city": "Stamps",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Stamps, Arkansas"
    },
    {
      "city": "Alicia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alicia, Arkansas"
    },
    {
      "city": "Black Rock",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Black Rock, Arkansas"
    },
    {
      "city": "Hoxie",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hoxie, Arkansas"
    },
    {
      "city": "Imboden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Imboden, Arkansas"
    },
    {
      "city": "Lynn",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lynn, Arkansas"
    },
    {
      "city": "Minturn",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Minturn, Arkansas"
    },
    {
      "city": "Portia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Portia, Arkansas"
    },
    {
      "city": "Powhatan",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Powhatan, Arkansas"
    },
    {
      "city": "Ravenden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ravenden, Arkansas"
    },
    {
      "city": "Sedgwick",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sedgwick, Arkansas"
    },
    {
      "city": "Smithville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Smithville, Arkansas"
    },
    {
      "city": "Strawberry",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Strawberry, Arkansas"
    },
    {
      "city": "Walnut Ridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Walnut Ridge, Arkansas"
    },
    {
      "city": "Saffell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saffell, Arkansas"
    },
    {
      "city": "Aubrey",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Aubrey, Arkansas"
    },
    {
      "city": "Brickeys",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Brickeys, Arkansas"
    },
    {
      "city": "Haynes",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Haynes, Arkansas"
    },
    {
      "city": "La Grange",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "La Grange, Arkansas"
    },
    {
      "city": "Marianna",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marianna, Arkansas"
    },
    {
      "city": "Moro",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Moro, Arkansas"
    },
    {
      "city": "Gould",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gould, Arkansas"
    },
    {
      "city": "Grady",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Grady, Arkansas"
    },
    {
      "city": "Star City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Star City, Arkansas"
    },
    {
      "city": "Yorktown",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Yorktown, Arkansas"
    },
    {
      "city": "Alleene",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alleene, Arkansas"
    },
    {
      "city": "Ashdown",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ashdown, Arkansas"
    },
    {
      "city": "Foreman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Foreman, Arkansas"
    },
    {
      "city": "Ogden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ogden, Arkansas"
    },
    {
      "city": "Wilton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wilton, Arkansas"
    },
    {
      "city": "Winthrop",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Winthrop, Arkansas"
    },
    {
      "city": "Blue Mountain",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Blue Mountain, Arkansas"
    },
    {
      "city": "Delaware",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Delaware, Arkansas"
    },
    {
      "city": "New Blaine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "New Blaine, Arkansas"
    },
    {
      "city": "Paris",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Paris, Arkansas"
    },
    {
      "city": "Scranton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Scranton, Arkansas"
    },
    {
      "city": "Subiaco",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Subiaco, Arkansas"
    },
    {
      "city": "Booneville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Booneville, Arkansas"
    },
    {
      "city": "Magazine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Magazine, Arkansas"
    },
    {
      "city": "Ratcliff",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ratcliff, Arkansas"
    },
    {
      "city": "Austin",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Austin, Arkansas"
    },
    {
      "city": "Cabot",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cabot, Arkansas"
    },
    {
      "city": "Carlisle",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Carlisle, Arkansas"
    },
    {
      "city": "Coy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Coy, Arkansas"
    },
    {
      "city": "England",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "England, Arkansas"
    },
    {
      "city": "Humnoke",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Humnoke, Arkansas"
    },
    {
      "city": "Keo",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Keo, Arkansas"
    },
    {
      "city": "Lonoke",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lonoke, Arkansas"
    },
    {
      "city": "Ward",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ward, Arkansas"
    },
    {
      "city": "Combs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Combs, Arkansas"
    },
    {
      "city": "Hindsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hindsville, Arkansas"
    },
    {
      "city": "Huntsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Huntsville, Arkansas"
    },
    {
      "city": "Kingston",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Kingston, Arkansas"
    },
    {
      "city": "Pettigrew",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pettigrew, Arkansas"
    },
    {
      "city": "Saint Paul",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saint Paul, Arkansas"
    },
    {
      "city": "Wesley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wesley, Arkansas"
    },
    {
      "city": "Witter",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Witter, Arkansas"
    },
    {
      "city": "Bull Shoals",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bull Shoals, Arkansas"
    },
    {
      "city": "Flippin",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Flippin, Arkansas"
    },
    {
      "city": "Oakland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oakland, Arkansas"
    },
    {
      "city": "Peel",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Peel, Arkansas"
    },
    {
      "city": "Pyatt",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pyatt, Arkansas"
    },
    {
      "city": "Summit",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Summit, Arkansas"
    },
    {
      "city": "Yellville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Yellville, Arkansas"
    },
    {
      "city": "Doddridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Doddridge, Arkansas"
    },
    {
      "city": "Fouke",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fouke, Arkansas"
    },
    {
      "city": "Genoa",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Genoa, Arkansas"
    },
    {
      "city": "Texarkana",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Texarkana, Arkansas"
    },
    {
      "city": "Armorel",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Armorel, Arkansas"
    },
    {
      "city": "Bassett",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bassett, Arkansas"
    },
    {
      "city": "Blytheville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Blytheville, Arkansas"
    },
    {
      "city": "Gosnell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gosnell, Arkansas"
    },
    {
      "city": "Burdette",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Burdette, Arkansas"
    },
    {
      "city": "Driver",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Driver, Arkansas"
    },
    {
      "city": "Dyess",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dyess, Arkansas"
    },
    {
      "city": "Frenchmans Bayou",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Frenchmans Bayou, Arkansas"
    },
    {
      "city": "Joiner",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Joiner, Arkansas"
    },
    {
      "city": "Keiser",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Keiser, Arkansas"
    },
    {
      "city": "Luxora",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Luxora, Arkansas"
    },
    {
      "city": "Osceola",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Osceola, Arkansas"
    },
    {
      "city": "West Ridge",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "West Ridge, Arkansas"
    },
    {
      "city": "Wilson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wilson, Arkansas"
    },
    {
      "city": "Dell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dell, Arkansas"
    },
    {
      "city": "Etowah",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Etowah, Arkansas"
    },
    {
      "city": "Leachville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Leachville, Arkansas"
    },
    {
      "city": "Manila",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Manila, Arkansas"
    },
    {
      "city": "Brinkley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Brinkley, Arkansas"
    },
    {
      "city": "Clarendon",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Clarendon, Arkansas"
    },
    {
      "city": "Holly Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Holly Grove, Arkansas"
    },
    {
      "city": "Monroe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Monroe, Arkansas"
    },
    {
      "city": "Roe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Roe, Arkansas"
    },
    {
      "city": "Caddo Gap",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Caddo Gap, Arkansas"
    },
    {
      "city": "Mount Ida",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mount Ida, Arkansas"
    },
    {
      "city": "Norman",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Norman, Arkansas"
    },
    {
      "city": "Oden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oden, Arkansas"
    },
    {
      "city": "Pencil Bluff",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pencil Bluff, Arkansas"
    },
    {
      "city": "Sims",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sims, Arkansas"
    },
    {
      "city": "Story",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Story, Arkansas"
    },
    {
      "city": "Bluff City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bluff City, Arkansas"
    },
    {
      "city": "Cale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cale, Arkansas"
    },
    {
      "city": "Emmet",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Emmet, Arkansas"
    },
    {
      "city": "Prescott",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Prescott, Arkansas"
    },
    {
      "city": "Rosston",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rosston, Arkansas"
    },
    {
      "city": "Willisville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Willisville, Arkansas"
    },
    {
      "city": "Compton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Compton, Arkansas"
    },
    {
      "city": "Deer",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Deer, Arkansas"
    },
    {
      "city": "Hasty",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hasty, Arkansas"
    },
    {
      "city": "Jasper",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jasper, Arkansas"
    },
    {
      "city": "Marble Falls",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marble Falls, Arkansas"
    },
    {
      "city": "Mount Judea",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mount Judea, Arkansas"
    },
    {
      "city": "Parthenon",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Parthenon, Arkansas"
    },
    {
      "city": "Ponca",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ponca, Arkansas"
    },
    {
      "city": "Vendor",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Vendor, Arkansas"
    },
    {
      "city": "Western Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Western Grove, Arkansas"
    },
    {
      "city": "Camden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Camden, Arkansas"
    },
    {
      "city": "Bearden",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bearden, Arkansas"
    },
    {
      "city": "Chidester",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Chidester, Arkansas"
    },
    {
      "city": "Louann",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Louann, Arkansas"
    },
    {
      "city": "Stephens",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Stephens, Arkansas"
    },
    {
      "city": "Adona",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Adona, Arkansas"
    },
    {
      "city": "Bigelow",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bigelow, Arkansas"
    },
    {
      "city": "Casa",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Casa, Arkansas"
    },
    {
      "city": "Houston",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Houston, Arkansas"
    },
    {
      "city": "Perry",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Perry, Arkansas"
    },
    {
      "city": "Perryville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Perryville, Arkansas"
    },
    {
      "city": "Barton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Barton, Arkansas"
    },
    {
      "city": "Crumrod",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Crumrod, Arkansas"
    },
    {
      "city": "Elaine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Elaine, Arkansas"
    },
    {
      "city": "Helena",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Helena, Arkansas"
    },
    {
      "city": "Lambrook",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lambrook, Arkansas"
    },
    {
      "city": "Lexa",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lexa, Arkansas"
    },
    {
      "city": "Marvell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marvell, Arkansas"
    },
    {
      "city": "Mellwood",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mellwood, Arkansas"
    },
    {
      "city": "Oneida",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Oneida, Arkansas"
    },
    {
      "city": "Poplar Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Poplar Grove, Arkansas"
    },
    {
      "city": "Turner",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Turner, Arkansas"
    },
    {
      "city": "Wabash",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wabash, Arkansas"
    },
    {
      "city": "West Helena",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "West Helena, Arkansas"
    },
    {
      "city": "Antoine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Antoine, Arkansas"
    },
    {
      "city": "Delight",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Delight, Arkansas"
    },
    {
      "city": "Glenwood",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Glenwood, Arkansas"
    },
    {
      "city": "Kirby",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Kirby, Arkansas"
    },
    {
      "city": "Langley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Langley, Arkansas"
    },
    {
      "city": "Murfreesboro",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Murfreesboro, Arkansas"
    },
    {
      "city": "Newhope",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Newhope, Arkansas"
    },
    {
      "city": "Lepanto",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lepanto, Arkansas"
    },
    {
      "city": "Marked Tree",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marked Tree, Arkansas"
    },
    {
      "city": "Rivervale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rivervale, Arkansas"
    },
    {
      "city": "Tyronza",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tyronza, Arkansas"
    },
    {
      "city": "Fisher",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fisher, Arkansas"
    },
    {
      "city": "Harrisburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Harrisburg, Arkansas"
    },
    {
      "city": "Trumann",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Trumann, Arkansas"
    },
    {
      "city": "Waldenburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Waldenburg, Arkansas"
    },
    {
      "city": "Weiner",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Weiner, Arkansas"
    },
    {
      "city": "Board Camp",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Board Camp, Arkansas"
    },
    {
      "city": "Cove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cove, Arkansas"
    },
    {
      "city": "Grannis",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Grannis, Arkansas"
    },
    {
      "city": "Hatfield",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hatfield, Arkansas"
    },
    {
      "city": "Mena",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mena, Arkansas"
    },
    {
      "city": "Vandervoort",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Vandervoort, Arkansas"
    },
    {
      "city": "Wickes",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wickes, Arkansas"
    },
    {
      "city": "Tilly",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tilly, Arkansas"
    },
    {
      "city": "Russellville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Russellville, Arkansas"
    },
    {
      "city": "Atkins",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Atkins, Arkansas"
    },
    {
      "city": "Dover",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dover, Arkansas"
    },
    {
      "city": "Hector",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hector, Arkansas"
    },
    {
      "city": "London",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "London, Arkansas"
    },
    {
      "city": "Pelsor",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pelsor, Arkansas"
    },
    {
      "city": "Pottsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pottsville, Arkansas"
    },
    {
      "city": "Biscoe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Biscoe, Arkansas"
    },
    {
      "city": "Des Arc",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Des Arc, Arkansas"
    },
    {
      "city": "De Valls Bluff",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "De Valls Bluff, Arkansas"
    },
    {
      "city": "Hazen",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hazen, Arkansas"
    },
    {
      "city": "Hickory Plains",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hickory Plains, Arkansas"
    },
    {
      "city": "Ulm",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ulm, Arkansas"
    },
    {
      "city": "Alexander",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Alexander, Arkansas"
    },
    {
      "city": "College Station",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "College Station, Arkansas"
    },
    {
      "city": "Hensley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hensley, Arkansas"
    },
    {
      "city": "Jacksonville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Jacksonville, Arkansas"
    },
    {
      "city": "Little Rock Air Force Base",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Little Rock Air Force Base, Arkansas"
    },
    {
      "city": "Mabelvale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mabelvale, Arkansas"
    },
    {
      "city": "Maumelle",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Maumelle, Arkansas"
    },
    {
      "city": "North Little Rock",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "North Little Rock, Arkansas"
    },
    {
      "city": "Sherwood",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sherwood, Arkansas"
    },
    {
      "city": "Roland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Roland, Arkansas"
    },
    {
      "city": "Scott",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Scott, Arkansas"
    },
    {
      "city": "Sweet Home",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sweet Home, Arkansas"
    },
    {
      "city": "Woodson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Woodson, Arkansas"
    },
    {
      "city": "Wrightsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wrightsville, Arkansas"
    },
    {
      "city": "Biggers",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Biggers, Arkansas"
    },
    {
      "city": "Maynard",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Maynard, Arkansas"
    },
    {
      "city": "O Kean",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "O Kean, Arkansas"
    },
    {
      "city": "Pocahontas",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pocahontas, Arkansas"
    },
    {
      "city": "Ravenden Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ravenden Springs, Arkansas"
    },
    {
      "city": "Reyno",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Reyno, Arkansas"
    },
    {
      "city": "Warm Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Warm Springs, Arkansas"
    },
    {
      "city": "Caldwell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Caldwell, Arkansas"
    },
    {
      "city": "Colt",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Colt, Arkansas"
    },
    {
      "city": "Forrest City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Forrest City, Arkansas"
    },
    {
      "city": "Goodwin",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Goodwin, Arkansas"
    },
    {
      "city": "Heth",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Heth, Arkansas"
    },
    {
      "city": "Hughes",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hughes, Arkansas"
    },
    {
      "city": "Madison",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Madison, Arkansas"
    },
    {
      "city": "Palestine",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Palestine, Arkansas"
    },
    {
      "city": "Wheatley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Wheatley, Arkansas"
    },
    {
      "city": "Widener",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Widener, Arkansas"
    },
    {
      "city": "Bauxite",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bauxite, Arkansas"
    },
    {
      "city": "Bryant",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bryant, Arkansas"
    },
    {
      "city": "Paron",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Paron, Arkansas"
    },
    {
      "city": "Traskwood",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Traskwood, Arkansas"
    },
    {
      "city": "Harvey",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Harvey, Arkansas"
    },
    {
      "city": "Boles",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Boles, Arkansas"
    },
    {
      "city": "Mansfield",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mansfield, Arkansas"
    },
    {
      "city": "Parks",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Parks, Arkansas"
    },
    {
      "city": "Waldron",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Waldron, Arkansas"
    },
    {
      "city": "Gilbert",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gilbert, Arkansas"
    },
    {
      "city": "Harriet",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Harriet, Arkansas"
    },
    {
      "city": "Leslie",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Leslie, Arkansas"
    },
    {
      "city": "Marshall",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marshall, Arkansas"
    },
    {
      "city": "Pindall",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pindall, Arkansas"
    },
    {
      "city": "Saint Joe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Saint Joe, Arkansas"
    },
    {
      "city": "Witts Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Witts Springs, Arkansas"
    },
    {
      "city": "Fort Smith",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fort Smith, Arkansas"
    },
    {
      "city": "Barling",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Barling, Arkansas"
    },
    {
      "city": "Greenwood",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Greenwood, Arkansas"
    },
    {
      "city": "Hackett",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hackett, Arkansas"
    },
    {
      "city": "Hartford",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hartford, Arkansas"
    },
    {
      "city": "Huntington",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Huntington, Arkansas"
    },
    {
      "city": "Lavaca",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lavaca, Arkansas"
    },
    {
      "city": "Midland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Midland, Arkansas"
    },
    {
      "city": "Ben Lomond",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ben Lomond, Arkansas"
    },
    {
      "city": "De Queen",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "De Queen, Arkansas"
    },
    {
      "city": "Gillham",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gillham, Arkansas"
    },
    {
      "city": "Horatio",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Horatio, Arkansas"
    },
    {
      "city": "Lockesburg",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lockesburg, Arkansas"
    },
    {
      "city": "Williford",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Williford, Arkansas"
    },
    {
      "city": "Ash Flat",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ash Flat, Arkansas"
    },
    {
      "city": "Cave City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cave City, Arkansas"
    },
    {
      "city": "Cherokee Village",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cherokee Village, Arkansas"
    },
    {
      "city": "Evening Shade",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Evening Shade, Arkansas"
    },
    {
      "city": "Hardy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hardy, Arkansas"
    },
    {
      "city": "Poughkeepsie",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Poughkeepsie, Arkansas"
    },
    {
      "city": "Sidney",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Sidney, Arkansas"
    },
    {
      "city": "Fox",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fox, Arkansas"
    },
    {
      "city": "Fifty Six",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fifty Six, Arkansas"
    },
    {
      "city": "Marcella",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Marcella, Arkansas"
    },
    {
      "city": "Mountain View",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mountain View, Arkansas"
    },
    {
      "city": "Pleasant Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pleasant Grove, Arkansas"
    },
    {
      "city": "Timbo",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Timbo, Arkansas"
    },
    {
      "city": "Onia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Onia, Arkansas"
    },
    {
      "city": "Calion",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Calion, Arkansas"
    },
    {
      "city": "El Dorado",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "El Dorado, Arkansas"
    },
    {
      "city": "Huttig",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Huttig, Arkansas"
    },
    {
      "city": "Junction City",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Junction City, Arkansas"
    },
    {
      "city": "Lawson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lawson, Arkansas"
    },
    {
      "city": "Mount Holly",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mount Holly, Arkansas"
    },
    {
      "city": "Norphlet",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Norphlet, Arkansas"
    },
    {
      "city": "Smackover",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Smackover, Arkansas"
    },
    {
      "city": "Strong",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Strong, Arkansas"
    },
    {
      "city": "Bee Branch",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bee Branch, Arkansas"
    },
    {
      "city": "Choctaw",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Choctaw, Arkansas"
    },
    {
      "city": "Clinton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Clinton, Arkansas"
    },
    {
      "city": "Fairfield Bay",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fairfield Bay, Arkansas"
    },
    {
      "city": "Scotland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Scotland, Arkansas"
    },
    {
      "city": "Shirley",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Shirley, Arkansas"
    },
    {
      "city": "Dennard",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dennard, Arkansas"
    },
    {
      "city": "Fayetteville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Fayetteville, Arkansas"
    },
    {
      "city": "Canehill",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Canehill, Arkansas"
    },
    {
      "city": "Elkins",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Elkins, Arkansas"
    },
    {
      "city": "Elm Springs",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Elm Springs, Arkansas"
    },
    {
      "city": "Evansville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Evansville, Arkansas"
    },
    {
      "city": "Farmington",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Farmington, Arkansas"
    },
    {
      "city": "Goshen",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Goshen, Arkansas"
    },
    {
      "city": "Greenland",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Greenland, Arkansas"
    },
    {
      "city": "Johnson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Johnson, Arkansas"
    },
    {
      "city": "Lincoln",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Lincoln, Arkansas"
    },
    {
      "city": "Morrow",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Morrow, Arkansas"
    },
    {
      "city": "Prairie Grove",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Prairie Grove, Arkansas"
    },
    {
      "city": "Springdale",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Springdale, Arkansas"
    },
    {
      "city": "Summers",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Summers, Arkansas"
    },
    {
      "city": "Tontitown",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Tontitown, Arkansas"
    },
    {
      "city": "West Fork",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "West Fork, Arkansas"
    },
    {
      "city": "Winslow",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Winslow, Arkansas"
    },
    {
      "city": "Bald Knob",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bald Knob, Arkansas"
    },
    {
      "city": "Beebe",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Beebe, Arkansas"
    },
    {
      "city": "Bradford",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bradford, Arkansas"
    },
    {
      "city": "El Paso",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "El Paso, Arkansas"
    },
    {
      "city": "Garner",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Garner, Arkansas"
    },
    {
      "city": "Griffithville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Griffithville, Arkansas"
    },
    {
      "city": "Higginson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Higginson, Arkansas"
    },
    {
      "city": "Judsonia",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Judsonia, Arkansas"
    },
    {
      "city": "Kensett",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Kensett, Arkansas"
    },
    {
      "city": "Letona",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Letona, Arkansas"
    },
    {
      "city": "Mc Rae",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Rae, Arkansas"
    },
    {
      "city": "Pangburn",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Pangburn, Arkansas"
    },
    {
      "city": "Romance",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Romance, Arkansas"
    },
    {
      "city": "Rose Bud",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rose Bud, Arkansas"
    },
    {
      "city": "Russell",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Russell, Arkansas"
    },
    {
      "city": "Searcy",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Searcy, Arkansas"
    },
    {
      "city": "West Point",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "West Point, Arkansas"
    },
    {
      "city": "Augusta",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Augusta, Arkansas"
    },
    {
      "city": "Cotton Plant",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Cotton Plant, Arkansas"
    },
    {
      "city": "Gregory",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gregory, Arkansas"
    },
    {
      "city": "Hunter",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Hunter, Arkansas"
    },
    {
      "city": "Mc Crory",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Mc Crory, Arkansas"
    },
    {
      "city": "Patterson",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Patterson, Arkansas"
    },
    {
      "city": "Belleville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Belleville, Arkansas"
    },
    {
      "city": "Bluffton",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Bluffton, Arkansas"
    },
    {
      "city": "Briggsville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Briggsville, Arkansas"
    },
    {
      "city": "Centerville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Centerville, Arkansas"
    },
    {
      "city": "Danville",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Danville, Arkansas"
    },
    {
      "city": "Dardanelle",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Dardanelle, Arkansas"
    },
    {
      "city": "Gravelly",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Gravelly, Arkansas"
    },
    {
      "city": "Havana",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Havana, Arkansas"
    },
    {
      "city": "Ola",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Ola, Arkansas"
    },
    {
      "city": "Plainview",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Plainview, Arkansas"
    },
    {
      "city": "Rover",
      "state": "Arkansas",
      "shortState": "AR",
      "formatedCity": "Rover, Arkansas"
    },
    {
      "city": "Alpine",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Alpine, Arizona"
    },
    {
      "city": "Concho",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Concho, Arizona"
    },
    {
      "city": "Eagar",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Eagar, Arizona"
    },
    {
      "city": "Greer",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Greer, Arizona"
    },
    {
      "city": "Mcnary",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mcnary, Arizona"
    },
    {
      "city": "Nutrioso",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Nutrioso, Arizona"
    },
    {
      "city": "Saint Johns",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Saint Johns, Arizona"
    },
    {
      "city": "Springerville",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Springerville, Arizona"
    },
    {
      "city": "Vernon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Vernon, Arizona"
    },
    {
      "city": "Petrified Forest Natl Pk",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Petrified Forest Natl Pk, Arizona"
    },
    {
      "city": "Chambers",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chambers, Arizona"
    },
    {
      "city": "Chinle",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chinle, Arizona"
    },
    {
      "city": "Fort Defiance",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Defiance, Arizona"
    },
    {
      "city": "Ganado",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Ganado, Arizona"
    },
    {
      "city": "Houck",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Houck, Arizona"
    },
    {
      "city": "Lukachukai",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lukachukai, Arizona"
    },
    {
      "city": "Lupton",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lupton, Arizona"
    },
    {
      "city": "Saint Michaels",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Saint Michaels, Arizona"
    },
    {
      "city": "Sanders",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sanders, Arizona"
    },
    {
      "city": "Teec Nos Pos",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Teec Nos Pos, Arizona"
    },
    {
      "city": "Window Rock",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Window Rock, Arizona"
    },
    {
      "city": "Dennehotso",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Dennehotso, Arizona"
    },
    {
      "city": "Many Farms",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Many Farms, Arizona"
    },
    {
      "city": "Nazlini",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Nazlini, Arizona"
    },
    {
      "city": "Red Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Red Valley, Arizona"
    },
    {
      "city": "Rock Point",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Rock Point, Arizona"
    },
    {
      "city": "Round Rock",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Round Rock, Arizona"
    },
    {
      "city": "Tsaile",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tsaile, Arizona"
    },
    {
      "city": "Benson",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Benson, Arizona"
    },
    {
      "city": "Bisbee",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bisbee, Arizona"
    },
    {
      "city": "Bowie",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bowie, Arizona"
    },
    {
      "city": "Cochise",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cochise, Arizona"
    },
    {
      "city": "Douglas",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Douglas, Arizona"
    },
    {
      "city": "Dragoon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Dragoon, Arizona"
    },
    {
      "city": "Elfrida",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Elfrida, Arizona"
    },
    {
      "city": "Fort Huachuca",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Huachuca, Arizona"
    },
    {
      "city": "Hereford",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Hereford, Arizona"
    },
    {
      "city": "Huachuca City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Huachuca City, Arizona"
    },
    {
      "city": "Mc Neal",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mc Neal, Arizona"
    },
    {
      "city": "Naco",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Naco, Arizona"
    },
    {
      "city": "Pearce",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pearce, Arizona"
    },
    {
      "city": "Pirtleville",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pirtleville, Arizona"
    },
    {
      "city": "Pomerene",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pomerene, Arizona"
    },
    {
      "city": "Saint David",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Saint David, Arizona"
    },
    {
      "city": "San Simon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "San Simon, Arizona"
    },
    {
      "city": "Sierra Vista",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sierra Vista, Arizona"
    },
    {
      "city": "Tombstone",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tombstone, Arizona"
    },
    {
      "city": "Willcox",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Willcox, Arizona"
    },
    {
      "city": "Forest Lakes",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Forest Lakes, Arizona"
    },
    {
      "city": "Flagstaff",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Flagstaff, Arizona"
    },
    {
      "city": "Bellemont",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bellemont, Arizona"
    },
    {
      "city": "Gray Mountain",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Gray Mountain, Arizona"
    },
    {
      "city": "Munds Park",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Munds Park, Arizona"
    },
    {
      "city": "Parks",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Parks, Arizona"
    },
    {
      "city": "Cameron",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cameron, Arizona"
    },
    {
      "city": "Fredonia",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fredonia, Arizona"
    },
    {
      "city": "Grand Canyon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Grand Canyon, Arizona"
    },
    {
      "city": "Happy Jack",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Happy Jack, Arizona"
    },
    {
      "city": "Leupp",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Leupp, Arizona"
    },
    {
      "city": "Marble Canyon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Marble Canyon, Arizona"
    },
    {
      "city": "Mormon Lake",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mormon Lake, Arizona"
    },
    {
      "city": "Page",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Page, Arizona"
    },
    {
      "city": "Tonalea",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tonalea, Arizona"
    },
    {
      "city": "Tuba City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tuba City, Arizona"
    },
    {
      "city": "Williams",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Williams, Arizona"
    },
    {
      "city": "North Rim",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "North Rim, Arizona"
    },
    {
      "city": "Kaibeto",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kaibeto, Arizona"
    },
    {
      "city": "Sedona",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sedona, Arizona"
    },
    {
      "city": "Supai",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Supai, Arizona"
    },
    {
      "city": "Hayden",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Hayden, Arizona"
    },
    {
      "city": "Winkelman",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Winkelman, Arizona"
    },
    {
      "city": "Globe",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Globe, Arizona"
    },
    {
      "city": "Claypool",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Claypool, Arizona"
    },
    {
      "city": "Miami",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Miami, Arizona"
    },
    {
      "city": "Payson",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Payson, Arizona"
    },
    {
      "city": "Peridot",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Peridot, Arizona"
    },
    {
      "city": "Pine",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pine, Arizona"
    },
    {
      "city": "Roosevelt",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Roosevelt, Arizona"
    },
    {
      "city": "San Carlos",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "San Carlos, Arizona"
    },
    {
      "city": "Tonto Basin",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tonto Basin, Arizona"
    },
    {
      "city": "Young",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Young, Arizona"
    },
    {
      "city": "Bylas",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bylas, Arizona"
    },
    {
      "city": "Central",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Central, Arizona"
    },
    {
      "city": "Eden",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Eden, Arizona"
    },
    {
      "city": "Fort Thomas",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Thomas, Arizona"
    },
    {
      "city": "Pima",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pima, Arizona"
    },
    {
      "city": "Safford",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Safford, Arizona"
    },
    {
      "city": "Solomon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Solomon, Arizona"
    },
    {
      "city": "Thatcher",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Thatcher, Arizona"
    },
    {
      "city": "Clifton",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Clifton, Arizona"
    },
    {
      "city": "Duncan",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Duncan, Arizona"
    },
    {
      "city": "Morenci",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Morenci, Arizona"
    },
    {
      "city": "Blue",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Blue, Arizona"
    },
    {
      "city": "Bouse",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bouse, Arizona"
    },
    {
      "city": "Cibola",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cibola, Arizona"
    },
    {
      "city": "Ehrenberg",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Ehrenberg, Arizona"
    },
    {
      "city": "Parker",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Parker, Arizona"
    },
    {
      "city": "Quartzsite",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Quartzsite, Arizona"
    },
    {
      "city": "Salome",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Salome, Arizona"
    },
    {
      "city": "Wenden",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Wenden, Arizona"
    },
    {
      "city": "Poston",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Poston, Arizona"
    },
    {
      "city": "Phoenix",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Phoenix, Arizona"
    },
    {
      "city": "New River",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "New River, Arizona"
    },
    {
      "city": "Chandler Heights",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chandler Heights, Arizona"
    },
    {
      "city": "Queen Creek",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Queen Creek, Arizona"
    },
    {
      "city": "Tortilla Flat",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tortilla Flat, Arizona"
    },
    {
      "city": "Mesa",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mesa, Arizona"
    },
    {
      "city": "Chandler",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chandler, Arizona"
    },
    {
      "city": "Gilbert",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Gilbert, Arizona"
    },
    {
      "city": "Higley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Higley, Arizona"
    },
    {
      "city": "Scottsdale",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Scottsdale, Arizona"
    },
    {
      "city": "Paradise Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Paradise Valley, Arizona"
    },
    {
      "city": "Rio Verde",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Rio Verde, Arizona"
    },
    {
      "city": "Fort Mcdowell",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Mcdowell, Arizona"
    },
    {
      "city": "Fountain Hills",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fountain Hills, Arizona"
    },
    {
      "city": "Tempe",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tempe, Arizona"
    },
    {
      "city": "Glendale",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Glendale, Arizona"
    },
    {
      "city": "Luke Air Force Base",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Luke Air Force Base, Arizona"
    },
    {
      "city": "Aguila",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Aguila, Arizona"
    },
    {
      "city": "Arlington",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Arlington, Arizona"
    },
    {
      "city": "Avondale",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Avondale, Arizona"
    },
    {
      "city": "Buckeye",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Buckeye, Arizona"
    },
    {
      "city": "Cave Creek",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cave Creek, Arizona"
    },
    {
      "city": "Cashion",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cashion, Arizona"
    },
    {
      "city": "El Mirage",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "El Mirage, Arizona"
    },
    {
      "city": "Gila Bend",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Gila Bend, Arizona"
    },
    {
      "city": "Goodyear",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Goodyear, Arizona"
    },
    {
      "city": "Laveen",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Laveen, Arizona"
    },
    {
      "city": "Litchfield Park",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Litchfield Park, Arizona"
    },
    {
      "city": "Morristown",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Morristown, Arizona"
    },
    {
      "city": "Palo Verde",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Palo Verde, Arizona"
    },
    {
      "city": "Peoria",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Peoria, Arizona"
    },
    {
      "city": "Sun City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sun City, Arizona"
    },
    {
      "city": "Tolleson",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tolleson, Arizona"
    },
    {
      "city": "Tonopah",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tonopah, Arizona"
    },
    {
      "city": "Waddell",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Waddell, Arizona"
    },
    {
      "city": "Wickenburg",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Wickenburg, Arizona"
    },
    {
      "city": "Wittmann",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Wittmann, Arizona"
    },
    {
      "city": "Youngtown",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Youngtown, Arizona"
    },
    {
      "city": "Surprise",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Surprise, Arizona"
    },
    {
      "city": "Sun City West",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sun City West, Arizona"
    },
    {
      "city": "Carefree",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Carefree, Arizona"
    },
    {
      "city": "Wikieup",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Wikieup, Arizona"
    },
    {
      "city": "Colorado City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Colorado City, Arizona"
    },
    {
      "city": "Kingman",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kingman, Arizona"
    },
    {
      "city": "Lake Havasu City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lake Havasu City, Arizona"
    },
    {
      "city": "Hackberry",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Hackberry, Arizona"
    },
    {
      "city": "Hualapai",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Hualapai, Arizona"
    },
    {
      "city": "Golden Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Golden Valley, Arizona"
    },
    {
      "city": "Fort Mohave",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Mohave, Arizona"
    },
    {
      "city": "Bullhead City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bullhead City, Arizona"
    },
    {
      "city": "Chloride",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chloride, Arizona"
    },
    {
      "city": "Littlefield",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Littlefield, Arizona"
    },
    {
      "city": "Oatman",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Oatman, Arizona"
    },
    {
      "city": "Peach Springs",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Peach Springs, Arizona"
    },
    {
      "city": "Topock",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Topock, Arizona"
    },
    {
      "city": "Valentine",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Valentine, Arizona"
    },
    {
      "city": "Yucca",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Yucca, Arizona"
    },
    {
      "city": "Mohave Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mohave Valley, Arizona"
    },
    {
      "city": "Dolan Springs",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Dolan Springs, Arizona"
    },
    {
      "city": "Temple Bar Marina",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Temple Bar Marina, Arizona"
    },
    {
      "city": "Meadview",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Meadview, Arizona"
    },
    {
      "city": "Willow Beach",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Willow Beach, Arizona"
    },
    {
      "city": "Show Low",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Show Low, Arizona"
    },
    {
      "city": "Cibecue",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cibecue, Arizona"
    },
    {
      "city": "White Mountain Lake",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "White Mountain Lake, Arizona"
    },
    {
      "city": "Clay Springs",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Clay Springs, Arizona"
    },
    {
      "city": "Fort Apache",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Fort Apache, Arizona"
    },
    {
      "city": "Heber",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Heber, Arizona"
    },
    {
      "city": "Lakeside",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lakeside, Arizona"
    },
    {
      "city": "Overgaard",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Overgaard, Arizona"
    },
    {
      "city": "Pinedale",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pinedale, Arizona"
    },
    {
      "city": "Pinetop",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pinetop, Arizona"
    },
    {
      "city": "Snowflake",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Snowflake, Arizona"
    },
    {
      "city": "Taylor",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Taylor, Arizona"
    },
    {
      "city": "Whiteriver",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Whiteriver, Arizona"
    },
    {
      "city": "Woodruff",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Woodruff, Arizona"
    },
    {
      "city": "Holbrook",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Holbrook, Arizona"
    },
    {
      "city": "Sun Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sun Valley, Arizona"
    },
    {
      "city": "Hotevilla",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Hotevilla, Arizona"
    },
    {
      "city": "Indian Wells",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Indian Wells, Arizona"
    },
    {
      "city": "Joseph City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Joseph City, Arizona"
    },
    {
      "city": "Kayenta",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kayenta, Arizona"
    },
    {
      "city": "Keams Canyon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Keams Canyon, Arizona"
    },
    {
      "city": "Kykotsmovi Village",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kykotsmovi Village, Arizona"
    },
    {
      "city": "Polacca",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Polacca, Arizona"
    },
    {
      "city": "Second Mesa",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Second Mesa, Arizona"
    },
    {
      "city": "Winslow",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Winslow, Arizona"
    },
    {
      "city": "Shonto",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Shonto, Arizona"
    },
    {
      "city": "Pinon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Pinon, Arizona"
    },
    {
      "city": "Blue Gap",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Blue Gap, Arizona"
    },
    {
      "city": "Ajo",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Ajo, Arizona"
    },
    {
      "city": "Lukeville",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lukeville, Arizona"
    },
    {
      "city": "Arivaca",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Arivaca, Arizona"
    },
    {
      "city": "Green Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Green Valley, Arizona"
    },
    {
      "city": "Mount Lemmon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mount Lemmon, Arizona"
    },
    {
      "city": "Sahuarita",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sahuarita, Arizona"
    },
    {
      "city": "Sasabe",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sasabe, Arizona"
    },
    {
      "city": "Sells",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sells, Arizona"
    },
    {
      "city": "Topawa",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Topawa, Arizona"
    },
    {
      "city": "Vail",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Vail, Arizona"
    },
    {
      "city": "Cortaro",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cortaro, Arizona"
    },
    {
      "city": "Marana",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Marana, Arizona"
    },
    {
      "city": "Rillito",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Rillito, Arizona"
    },
    {
      "city": "Tucson",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tucson, Arizona"
    },
    {
      "city": "Catalina",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Catalina, Arizona"
    },
    {
      "city": "Apache Junction",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Apache Junction, Arizona"
    },
    {
      "city": "Gold Canyon",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Gold Canyon, Arizona"
    },
    {
      "city": "Bapchule",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bapchule, Arizona"
    },
    {
      "city": "Casa Grande",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Casa Grande, Arizona"
    },
    {
      "city": "Arizona City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Arizona City, Arizona"
    },
    {
      "city": "Coolidge",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Coolidge, Arizona"
    },
    {
      "city": "Eloy",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Eloy, Arizona"
    },
    {
      "city": "Florence",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Florence, Arizona"
    },
    {
      "city": "Kearny",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kearny, Arizona"
    },
    {
      "city": "Maricopa",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Maricopa, Arizona"
    },
    {
      "city": "San Tan Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "San Tan Valley, Arizona"
    },
    {
      "city": "Picacho",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Picacho, Arizona"
    },
    {
      "city": "Red Rock",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Red Rock, Arizona"
    },
    {
      "city": "Sacaton",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sacaton, Arizona"
    },
    {
      "city": "Stanfield",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Stanfield, Arizona"
    },
    {
      "city": "Superior",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Superior, Arizona"
    },
    {
      "city": "Valley Farms",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Valley Farms, Arizona"
    },
    {
      "city": "Mammoth",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mammoth, Arizona"
    },
    {
      "city": "Oracle",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Oracle, Arizona"
    },
    {
      "city": "San Manuel",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "San Manuel, Arizona"
    },
    {
      "city": "Elgin",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Elgin, Arizona"
    },
    {
      "city": "Nogales",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Nogales, Arizona"
    },
    {
      "city": "Patagonia",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Patagonia, Arizona"
    },
    {
      "city": "Sonoita",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Sonoita, Arizona"
    },
    {
      "city": "Tumacacori",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tumacacori, Arizona"
    },
    {
      "city": "Amado",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Amado, Arizona"
    },
    {
      "city": "Tubac",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tubac, Arizona"
    },
    {
      "city": "Rio Rico",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Rio Rico, Arizona"
    },
    {
      "city": "Black Canyon City",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Black Canyon City, Arizona"
    },
    {
      "city": "Congress",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Congress, Arizona"
    },
    {
      "city": "Yarnell",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Yarnell, Arizona"
    },
    {
      "city": "Prescott",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Prescott, Arizona"
    },
    {
      "city": "Prescott Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Prescott Valley, Arizona"
    },
    {
      "city": "Ash Fork",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Ash Fork, Arizona"
    },
    {
      "city": "Bagdad",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Bagdad, Arizona"
    },
    {
      "city": "Camp Verde",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Camp Verde, Arizona"
    },
    {
      "city": "Chino Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Chino Valley, Arizona"
    },
    {
      "city": "Clarkdale",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Clarkdale, Arizona"
    },
    {
      "city": "Cornville",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cornville, Arizona"
    },
    {
      "city": "Cottonwood",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Cottonwood, Arizona"
    },
    {
      "city": "Dewey",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Dewey, Arizona"
    },
    {
      "city": "Humboldt",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Humboldt, Arizona"
    },
    {
      "city": "Jerome",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Jerome, Arizona"
    },
    {
      "city": "Kirkland",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Kirkland, Arizona"
    },
    {
      "city": "Mayer",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Mayer, Arizona"
    },
    {
      "city": "Paulden",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Paulden, Arizona"
    },
    {
      "city": "Rimrock",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Rimrock, Arizona"
    },
    {
      "city": "Seligman",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Seligman, Arizona"
    },
    {
      "city": "Skull Valley",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Skull Valley, Arizona"
    },
    {
      "city": "Lake Montezuma",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Lake Montezuma, Arizona"
    },
    {
      "city": "Crown King",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Crown King, Arizona"
    },
    {
      "city": "Dateland",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Dateland, Arizona"
    },
    {
      "city": "Gadsden",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Gadsden, Arizona"
    },
    {
      "city": "Roll",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Roll, Arizona"
    },
    {
      "city": "San Luis",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "San Luis, Arizona"
    },
    {
      "city": "Somerton",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Somerton, Arizona"
    },
    {
      "city": "Tacna",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Tacna, Arizona"
    },
    {
      "city": "Wellton",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Wellton, Arizona"
    },
    {
      "city": "Yuma",
      "state": "Arizona",
      "shortState": "AZ",
      "formatedCity": "Yuma, Arizona"
    },
    {
      "city": "Alameda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alameda, California"
    },
    {
      "city": "Fremont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fremont, California"
    },
    {
      "city": "Hayward",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hayward, California"
    },
    {
      "city": "Castro Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Castro Valley, California"
    },
    {
      "city": "Livermore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Livermore, California"
    },
    {
      "city": "Newark",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newark, California"
    },
    {
      "city": "Pleasanton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pleasanton, California"
    },
    {
      "city": "Dublin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dublin, California"
    },
    {
      "city": "San Leandro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Leandro, California"
    },
    {
      "city": "San Lorenzo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Lorenzo, California"
    },
    {
      "city": "Sunol",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sunol, California"
    },
    {
      "city": "Union City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Union City, California"
    },
    {
      "city": "Oakland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oakland, California"
    },
    {
      "city": "Emeryville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Emeryville, California"
    },
    {
      "city": "Piedmont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Piedmont, California"
    },
    {
      "city": "Berkeley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Berkeley, California"
    },
    {
      "city": "Albany",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Albany, California"
    },
    {
      "city": "Kirkwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kirkwood, California"
    },
    {
      "city": "Markleeville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Markleeville, California"
    },
    {
      "city": "Amador City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Amador City, California"
    },
    {
      "city": "Fiddletown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fiddletown, California"
    },
    {
      "city": "Ione",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ione, California"
    },
    {
      "city": "Jackson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jackson, California"
    },
    {
      "city": "Kit Carson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kit Carson, California"
    },
    {
      "city": "Martell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Martell, California"
    },
    {
      "city": "Pine Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pine Grove, California"
    },
    {
      "city": "Pioneer",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pioneer, California"
    },
    {
      "city": "Plymouth",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Plymouth, California"
    },
    {
      "city": "River Pines",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "River Pines, California"
    },
    {
      "city": "Sutter Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sutter Creek, California"
    },
    {
      "city": "Volcano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Volcano, California"
    },
    {
      "city": "Drytown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Drytown, California"
    },
    {
      "city": "Bangor",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bangor, California"
    },
    {
      "city": "Berry Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Berry Creek, California"
    },
    {
      "city": "Biggs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Biggs, California"
    },
    {
      "city": "Chico",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chico, California"
    },
    {
      "city": "Clipper Mills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clipper Mills, California"
    },
    {
      "city": "Durham",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Durham, California"
    },
    {
      "city": "Feather Falls",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Feather Falls, California"
    },
    {
      "city": "Forbestown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forbestown, California"
    },
    {
      "city": "Forest Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forest Ranch, California"
    },
    {
      "city": "Gridley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gridley, California"
    },
    {
      "city": "Magalia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Magalia, California"
    },
    {
      "city": "Nelson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nelson, California"
    },
    {
      "city": "Oroville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oroville, California"
    },
    {
      "city": "Paradise",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paradise, California"
    },
    {
      "city": "Palermo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palermo, California"
    },
    {
      "city": "Richvale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Richvale, California"
    },
    {
      "city": "Stirling City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stirling City, California"
    },
    {
      "city": "Altaville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Altaville, California"
    },
    {
      "city": "Angels Camp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Angels Camp, California"
    },
    {
      "city": "Arnold",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arnold, California"
    },
    {
      "city": "Avery",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Avery, California"
    },
    {
      "city": "Burson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burson, California"
    },
    {
      "city": "Campo Seco",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Campo Seco, California"
    },
    {
      "city": "Copperopolis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Copperopolis, California"
    },
    {
      "city": "Douglas Flat",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Douglas Flat, California"
    },
    {
      "city": "Glencoe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glencoe, California"
    },
    {
      "city": "Hathaway Pines",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hathaway Pines, California"
    },
    {
      "city": "Mokelumne Hill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mokelumne Hill, California"
    },
    {
      "city": "Mountain Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mountain Ranch, California"
    },
    {
      "city": "Murphys",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Murphys, California"
    },
    {
      "city": "Rail Road Flat",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rail Road Flat, California"
    },
    {
      "city": "San Andreas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Andreas, California"
    },
    {
      "city": "Vallecito",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vallecito, California"
    },
    {
      "city": "Valley Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valley Springs, California"
    },
    {
      "city": "Wallace",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wallace, California"
    },
    {
      "city": "West Point",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "West Point, California"
    },
    {
      "city": "Wilseyville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wilseyville, California"
    },
    {
      "city": "Arbuckle",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arbuckle, California"
    },
    {
      "city": "Colusa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Colusa, California"
    },
    {
      "city": "Grimes",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grimes, California"
    },
    {
      "city": "Maxwell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Maxwell, California"
    },
    {
      "city": "Princeton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Princeton, California"
    },
    {
      "city": "Stonyford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stonyford, California"
    },
    {
      "city": "Williams",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Williams, California"
    },
    {
      "city": "Discovery Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Discovery Bay, California"
    },
    {
      "city": "Danville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Danville, California"
    },
    {
      "city": "Alamo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alamo, California"
    },
    {
      "city": "Antioch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Antioch, California"
    },
    {
      "city": "Bethel Island",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bethel Island, California"
    },
    {
      "city": "Brentwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brentwood, California"
    },
    {
      "city": "Byron",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Byron, California"
    },
    {
      "city": "Canyon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Canyon, California"
    },
    {
      "city": "Clayton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clayton, California"
    },
    {
      "city": "Concord",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Concord, California"
    },
    {
      "city": "Pleasant Hill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pleasant Hill, California"
    },
    {
      "city": "Crockett",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Crockett, California"
    },
    {
      "city": "Diablo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Diablo, California"
    },
    {
      "city": "El Cerrito",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Cerrito, California"
    },
    {
      "city": "Hercules",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hercules, California"
    },
    {
      "city": "Knightsen",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Knightsen, California"
    },
    {
      "city": "Lafayette",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lafayette, California"
    },
    {
      "city": "Martinez",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Martinez, California"
    },
    {
      "city": "Moraga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moraga, California"
    },
    {
      "city": "Oakley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oakley, California"
    },
    {
      "city": "Orinda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orinda, California"
    },
    {
      "city": "Pinole",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pinole, California"
    },
    {
      "city": "Pittsburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pittsburg, California"
    },
    {
      "city": "Port Costa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Port Costa, California"
    },
    {
      "city": "Rodeo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rodeo, California"
    },
    {
      "city": "San Ramon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Ramon, California"
    },
    {
      "city": "Walnut Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Walnut Creek, California"
    },
    {
      "city": "Richmond",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Richmond, California"
    },
    {
      "city": "El Sobrante",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Sobrante, California"
    },
    {
      "city": "San Pablo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Pablo, California"
    },
    {
      "city": "Crescent City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Crescent City, California"
    },
    {
      "city": "Fort Dick",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fort Dick, California"
    },
    {
      "city": "Gasquet",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gasquet, California"
    },
    {
      "city": "Klamath",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Klamath, California"
    },
    {
      "city": "Smith River",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Smith River, California"
    },
    {
      "city": "Coloma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coloma, California"
    },
    {
      "city": "Cool",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cool, California"
    },
    {
      "city": "Diamond Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Diamond Springs, California"
    },
    {
      "city": "El Dorado",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Dorado, California"
    },
    {
      "city": "Garden Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Garden Valley, California"
    },
    {
      "city": "Georgetown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Georgetown, California"
    },
    {
      "city": "Greenwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Greenwood, California"
    },
    {
      "city": "Grizzly Flats",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grizzly Flats, California"
    },
    {
      "city": "Lotus",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lotus, California"
    },
    {
      "city": "Mount Aukum",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Aukum, California"
    },
    {
      "city": "Pilot Hill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pilot Hill, California"
    },
    {
      "city": "Placerville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Placerville, California"
    },
    {
      "city": "Rescue",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rescue, California"
    },
    {
      "city": "Shingle Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shingle Springs, California"
    },
    {
      "city": "Somerset",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Somerset, California"
    },
    {
      "city": "Camino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camino, California"
    },
    {
      "city": "Kyburz",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kyburz, California"
    },
    {
      "city": "Echo Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Echo Lake, California"
    },
    {
      "city": "Pollock Pines",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pollock Pines, California"
    },
    {
      "city": "Twin Bridges",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Twin Bridges, California"
    },
    {
      "city": "El Dorado Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Dorado Hills, California"
    },
    {
      "city": "Tahoma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tahoma, California"
    },
    {
      "city": "South Lake Tahoe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South Lake Tahoe, California"
    },
    {
      "city": "Coalinga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coalinga, California"
    },
    {
      "city": "Huron",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Huron, California"
    },
    {
      "city": "Laton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laton, California"
    },
    {
      "city": "Auberry",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Auberry, California"
    },
    {
      "city": "Big Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Creek, California"
    },
    {
      "city": "Biola",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Biola, California"
    },
    {
      "city": "Burrel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burrel, California"
    },
    {
      "city": "Cantua Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cantua Creek, California"
    },
    {
      "city": "Caruthers",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Caruthers, California"
    },
    {
      "city": "Clovis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clovis, California"
    },
    {
      "city": "Del Rey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Del Rey, California"
    },
    {
      "city": "Dunlap",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dunlap, California"
    },
    {
      "city": "Firebaugh",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Firebaugh, California"
    },
    {
      "city": "Five Points",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Five Points, California"
    },
    {
      "city": "Fowler",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fowler, California"
    },
    {
      "city": "Friant",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Friant, California"
    },
    {
      "city": "Helm",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Helm, California"
    },
    {
      "city": "Hume",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hume, California"
    },
    {
      "city": "Kerman",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kerman, California"
    },
    {
      "city": "Kingsburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kingsburg, California"
    },
    {
      "city": "Lakeshore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lakeshore, California"
    },
    {
      "city": "Mendota",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mendota, California"
    },
    {
      "city": "Miramonte",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Miramonte, California"
    },
    {
      "city": "Mono Hot Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mono Hot Springs, California"
    },
    {
      "city": "Orange Cove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orange Cove, California"
    },
    {
      "city": "Parlier",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Parlier, California"
    },
    {
      "city": "Piedra",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Piedra, California"
    },
    {
      "city": "Fresno",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fresno, California"
    },
    {
      "city": "Prather",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Prather, California"
    },
    {
      "city": "Raisin City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Raisin City, California"
    },
    {
      "city": "Reedley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Reedley, California"
    },
    {
      "city": "Riverdale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Riverdale, California"
    },
    {
      "city": "Sanger",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sanger, California"
    },
    {
      "city": "San Joaquin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Joaquin, California"
    },
    {
      "city": "Selma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Selma, California"
    },
    {
      "city": "Shaver Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shaver Lake, California"
    },
    {
      "city": "Tollhouse",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tollhouse, California"
    },
    {
      "city": "Tranquillity",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tranquillity, California"
    },
    {
      "city": "Squaw Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Squaw Valley, California"
    },
    {
      "city": "Artois",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Artois, California"
    },
    {
      "city": "Butte City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Butte City, California"
    },
    {
      "city": "Elk Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Elk Creek, California"
    },
    {
      "city": "Glenn",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glenn, California"
    },
    {
      "city": "Hamilton City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hamilton City, California"
    },
    {
      "city": "Orland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orland, California"
    },
    {
      "city": "Willows",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Willows, California"
    },
    {
      "city": "Eureka",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Eureka, California"
    },
    {
      "city": "Alderpoint",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alderpoint, California"
    },
    {
      "city": "Blocksburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Blocksburg, California"
    },
    {
      "city": "Arcata",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arcata, California"
    },
    {
      "city": "Mckinleyville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mckinleyville, California"
    },
    {
      "city": "Bayside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bayside, California"
    },
    {
      "city": "Blue Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Blue Lake, California"
    },
    {
      "city": "Bridgeville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bridgeville, California"
    },
    {
      "city": "Carlotta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carlotta, California"
    },
    {
      "city": "Cutten",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cutten, California"
    },
    {
      "city": "Ferndale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ferndale, California"
    },
    {
      "city": "Fields Landing",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fields Landing, California"
    },
    {
      "city": "Fortuna",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fortuna, California"
    },
    {
      "city": "Garberville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Garberville, California"
    },
    {
      "city": "Honeydew",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Honeydew, California"
    },
    {
      "city": "Hoopa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hoopa, California"
    },
    {
      "city": "Hydesville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hydesville, California"
    },
    {
      "city": "Kneeland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kneeland, California"
    },
    {
      "city": "Korbel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Korbel, California"
    },
    {
      "city": "Loleta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Loleta, California"
    },
    {
      "city": "Miranda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Miranda, California"
    },
    {
      "city": "Myers Flat",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Myers Flat, California"
    },
    {
      "city": "Orick",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orick, California"
    },
    {
      "city": "Orleans",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orleans, California"
    },
    {
      "city": "Petrolia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Petrolia, California"
    },
    {
      "city": "Phillipsville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Phillipsville, California"
    },
    {
      "city": "Redway",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redway, California"
    },
    {
      "city": "Rio Dell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rio Dell, California"
    },
    {
      "city": "Samoa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Samoa, California"
    },
    {
      "city": "Scotia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Scotia, California"
    },
    {
      "city": "Redcrest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redcrest, California"
    },
    {
      "city": "Trinidad",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Trinidad, California"
    },
    {
      "city": "Weott",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Weott, California"
    },
    {
      "city": "Willow Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Willow Creek, California"
    },
    {
      "city": "Whitethorn",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Whitethorn, California"
    },
    {
      "city": "Bard",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bard, California"
    },
    {
      "city": "Brawley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brawley, California"
    },
    {
      "city": "Calexico",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calexico, California"
    },
    {
      "city": "Calipatria",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calipatria, California"
    },
    {
      "city": "El Centro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Centro, California"
    },
    {
      "city": "Heber",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Heber, California"
    },
    {
      "city": "Holtville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Holtville, California"
    },
    {
      "city": "Imperial",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Imperial, California"
    },
    {
      "city": "Niland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Niland, California"
    },
    {
      "city": "Ocotillo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ocotillo, California"
    },
    {
      "city": "Palo Verde",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palo Verde, California"
    },
    {
      "city": "Seeley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Seeley, California"
    },
    {
      "city": "Salton City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Salton City, California"
    },
    {
      "city": "Westmorland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westmorland, California"
    },
    {
      "city": "Winterhaven",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Winterhaven, California"
    },
    {
      "city": "Fontana",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fontana, California"
    },
    {
      "city": "Death Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Death Valley, California"
    },
    {
      "city": "Shoshone",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shoshone, California"
    },
    {
      "city": "Tecopa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tecopa, California"
    },
    {
      "city": "Big Pine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Pine, California"
    },
    {
      "city": "Bishop",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bishop, California"
    },
    {
      "city": "Darwin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Darwin, California"
    },
    {
      "city": "Independence",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Independence, California"
    },
    {
      "city": "Keeler",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Keeler, California"
    },
    {
      "city": "Little Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Little Lake, California"
    },
    {
      "city": "Lone Pine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lone Pine, California"
    },
    {
      "city": "Olancha",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Olancha, California"
    },
    {
      "city": "Arvin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arvin, California"
    },
    {
      "city": "Bodfish",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bodfish, California"
    },
    {
      "city": "Buttonwillow",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Buttonwillow, California"
    },
    {
      "city": "Delano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Delano, California"
    },
    {
      "city": "Edison",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Edison, California"
    },
    {
      "city": "Pine Mountain Club",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pine Mountain Club, California"
    },
    {
      "city": "Fellows",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fellows, California"
    },
    {
      "city": "Frazier Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Frazier Park, California"
    },
    {
      "city": "Glennville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glennville, California"
    },
    {
      "city": "Kernville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kernville, California"
    },
    {
      "city": "Lake Isabella",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake Isabella, California"
    },
    {
      "city": "Lamont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lamont, California"
    },
    {
      "city": "Lebec",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lebec, California"
    },
    {
      "city": "Lost Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lost Hills, California"
    },
    {
      "city": "Mc Farland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mc Farland, California"
    },
    {
      "city": "Mc Kittrick",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mc Kittrick, California"
    },
    {
      "city": "Maricopa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Maricopa, California"
    },
    {
      "city": "Onyx",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Onyx, California"
    },
    {
      "city": "Shafter",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shafter, California"
    },
    {
      "city": "Taft",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Taft, California"
    },
    {
      "city": "Tupman",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tupman, California"
    },
    {
      "city": "Wasco",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wasco, California"
    },
    {
      "city": "Weldon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Weldon, California"
    },
    {
      "city": "Wofford Heights",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wofford Heights, California"
    },
    {
      "city": "Woody",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woody, California"
    },
    {
      "city": "Bakersfield",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bakersfield, California"
    },
    {
      "city": "Mojave",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mojave, California"
    },
    {
      "city": "California City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "California City, California"
    },
    {
      "city": "Boron",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Boron, California"
    },
    {
      "city": "Caliente",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Caliente, California"
    },
    {
      "city": "Cantil",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cantil, California"
    },
    {
      "city": "Edwards",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Edwards, California"
    },
    {
      "city": "Inyokern",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Inyokern, California"
    },
    {
      "city": "Johannesburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Johannesburg, California"
    },
    {
      "city": "Keene",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Keene, California"
    },
    {
      "city": "Randsburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Randsburg, California"
    },
    {
      "city": "Ridgecrest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ridgecrest, California"
    },
    {
      "city": "Rosamond",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rosamond, California"
    },
    {
      "city": "Tehachapi",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tehachapi, California"
    },
    {
      "city": "Armona",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Armona, California"
    },
    {
      "city": "Avenal",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Avenal, California"
    },
    {
      "city": "Corcoran",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Corcoran, California"
    },
    {
      "city": "Hanford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hanford, California"
    },
    {
      "city": "Kettleman City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kettleman City, California"
    },
    {
      "city": "Lemoore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lemoore, California"
    },
    {
      "city": "Stratford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stratford, California"
    },
    {
      "city": "Clearlake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clearlake, California"
    },
    {
      "city": "Clearlake Oaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clearlake Oaks, California"
    },
    {
      "city": "Clearlake Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clearlake Park, California"
    },
    {
      "city": "Cobb",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cobb, California"
    },
    {
      "city": "Finley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Finley, California"
    },
    {
      "city": "Glenhaven",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glenhaven, California"
    },
    {
      "city": "Kelseyville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kelseyville, California"
    },
    {
      "city": "Lakeport",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lakeport, California"
    },
    {
      "city": "Lower Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lower Lake, California"
    },
    {
      "city": "Lucerne",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lucerne, California"
    },
    {
      "city": "Middletown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Middletown, California"
    },
    {
      "city": "Nice",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nice, California"
    },
    {
      "city": "Hidden Valley Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hidden Valley Lake, California"
    },
    {
      "city": "Upper Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Upper Lake, California"
    },
    {
      "city": "Witter Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Witter Springs, California"
    },
    {
      "city": "Bieber",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bieber, California"
    },
    {
      "city": "Nubieber",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nubieber, California"
    },
    {
      "city": "Doyle",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Doyle, California"
    },
    {
      "city": "Herlong",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Herlong, California"
    },
    {
      "city": "Janesville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Janesville, California"
    },
    {
      "city": "Litchfield",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Litchfield, California"
    },
    {
      "city": "Madeline",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Madeline, California"
    },
    {
      "city": "Milford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Milford, California"
    },
    {
      "city": "Ravendale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ravendale, California"
    },
    {
      "city": "Susanville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Susanville, California"
    },
    {
      "city": "Standish",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Standish, California"
    },
    {
      "city": "Termo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Termo, California"
    },
    {
      "city": "Wendel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wendel, California"
    },
    {
      "city": "Westwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westwood, California"
    },
    {
      "city": "Los Angeles",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Angeles, California"
    },
    {
      "city": "West Hollywood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "West Hollywood, California"
    },
    {
      "city": "Dodgertown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dodgertown, California"
    },
    {
      "city": "Playa Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Playa Vista, California"
    },
    {
      "city": "Bell Gardens",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bell Gardens, California"
    },
    {
      "city": "Bell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bell, California"
    },
    {
      "city": "Beverly Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Beverly Hills, California"
    },
    {
      "city": "Compton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Compton, California"
    },
    {
      "city": "Culver City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Culver City, California"
    },
    {
      "city": "Downey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Downey, California"
    },
    {
      "city": "El Segundo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Segundo, California"
    },
    {
      "city": "Gardena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gardena, California"
    },
    {
      "city": "Hawthorne",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hawthorne, California"
    },
    {
      "city": "Hermosa Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hermosa Beach, California"
    },
    {
      "city": "Huntington Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Huntington Park, California"
    },
    {
      "city": "Lawndale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lawndale, California"
    },
    {
      "city": "Lynwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lynwood, California"
    },
    {
      "city": "Malibu",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Malibu, California"
    },
    {
      "city": "Manhattan Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Manhattan Beach, California"
    },
    {
      "city": "Maywood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Maywood, California"
    },
    {
      "city": "Pacific Palisades",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pacific Palisades, California"
    },
    {
      "city": "Palos Verdes Peninsula",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palos Verdes Peninsula, California"
    },
    {
      "city": "Rancho Palos Verdes",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Palos Verdes, California"
    },
    {
      "city": "Redondo Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redondo Beach, California"
    },
    {
      "city": "South Gate",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South Gate, California"
    },
    {
      "city": "Topanga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Topanga, California"
    },
    {
      "city": "Venice",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Venice, California"
    },
    {
      "city": "Marina Del Rey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Marina Del Rey, California"
    },
    {
      "city": "Playa Del Rey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Playa Del Rey, California"
    },
    {
      "city": "Inglewood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Inglewood, California"
    },
    {
      "city": "Santa Monica",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Monica, California"
    },
    {
      "city": "Torrance",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Torrance, California"
    },
    {
      "city": "Whittier",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Whittier, California"
    },
    {
      "city": "La Mirada",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Mirada, California"
    },
    {
      "city": "Montebello",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montebello, California"
    },
    {
      "city": "Norwalk",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Norwalk, California"
    },
    {
      "city": "Pico Rivera",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pico Rivera, California"
    },
    {
      "city": "Santa Fe Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Fe Springs, California"
    },
    {
      "city": "Artesia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Artesia, California"
    },
    {
      "city": "Cerritos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cerritos, California"
    },
    {
      "city": "Avalon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Avalon, California"
    },
    {
      "city": "Bellflower",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bellflower, California"
    },
    {
      "city": "Harbor City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Harbor City, California"
    },
    {
      "city": "Lakewood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lakewood, California"
    },
    {
      "city": "Hawaiian Gardens",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hawaiian Gardens, California"
    },
    {
      "city": "Lomita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lomita, California"
    },
    {
      "city": "Paramount",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paramount, California"
    },
    {
      "city": "San Pedro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Pedro, California"
    },
    {
      "city": "Wilmington",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wilmington, California"
    },
    {
      "city": "Carson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carson, California"
    },
    {
      "city": "Signal Hill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Signal Hill, California"
    },
    {
      "city": "Long Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Long Beach, California"
    },
    {
      "city": "Altadena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Altadena, California"
    },
    {
      "city": "Arcadia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arcadia, California"
    },
    {
      "city": "Duarte",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Duarte, California"
    },
    {
      "city": "La Canada Flintridge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Canada Flintridge, California"
    },
    {
      "city": "Monrovia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Monrovia, California"
    },
    {
      "city": "Montrose",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montrose, California"
    },
    {
      "city": "Mount Wilson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Wilson, California"
    },
    {
      "city": "Sierra Madre",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sierra Madre, California"
    },
    {
      "city": "South Pasadena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South Pasadena, California"
    },
    {
      "city": "Sunland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sunland, California"
    },
    {
      "city": "Tujunga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tujunga, California"
    },
    {
      "city": "Verdugo City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Verdugo City, California"
    },
    {
      "city": "Pasadena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pasadena, California"
    },
    {
      "city": "San Marino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Marino, California"
    },
    {
      "city": "Glendale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glendale, California"
    },
    {
      "city": "La Crescenta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Crescenta, California"
    },
    {
      "city": "Agoura Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Agoura Hills, California"
    },
    {
      "city": "Calabasas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calabasas, California"
    },
    {
      "city": "Canoga Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Canoga Park, California"
    },
    {
      "city": "Winnetka",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Winnetka, California"
    },
    {
      "city": "West Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "West Hills, California"
    },
    {
      "city": "Castaic",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Castaic, California"
    },
    {
      "city": "Chatsworth",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chatsworth, California"
    },
    {
      "city": "Encino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Encino, California"
    },
    {
      "city": "Newhall",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newhall, California"
    },
    {
      "city": "Northridge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Northridge, California"
    },
    {
      "city": "Porter Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Porter Ranch, California"
    },
    {
      "city": "Pacoima",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pacoima, California"
    },
    {
      "city": "Reseda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Reseda, California"
    },
    {
      "city": "San Fernando",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Fernando, California"
    },
    {
      "city": "Sylmar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sylmar, California"
    },
    {
      "city": "North Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North Hills, California"
    },
    {
      "city": "Granada Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Granada Hills, California"
    },
    {
      "city": "Mission Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mission Hills, California"
    },
    {
      "city": "Santa Clarita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Clarita, California"
    },
    {
      "city": "Canyon Country",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Canyon Country, California"
    },
    {
      "city": "Sun Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sun Valley, California"
    },
    {
      "city": "Valencia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valencia, California"
    },
    {
      "city": "Tarzana",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tarzana, California"
    },
    {
      "city": "Woodland Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woodland Hills, California"
    },
    {
      "city": "Stevenson Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stevenson Ranch, California"
    },
    {
      "city": "Van Nuys",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Van Nuys, California"
    },
    {
      "city": "Panorama City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Panorama City, California"
    },
    {
      "city": "Sherman Oaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sherman Oaks, California"
    },
    {
      "city": "Burbank",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burbank, California"
    },
    {
      "city": "North Hollywood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North Hollywood, California"
    },
    {
      "city": "Studio City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Studio City, California"
    },
    {
      "city": "Valley Village",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valley Village, California"
    },
    {
      "city": "Universal City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Universal City, California"
    },
    {
      "city": "Toluca Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Toluca Lake, California"
    },
    {
      "city": "Azusa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Azusa, California"
    },
    {
      "city": "Baldwin Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Baldwin Park, California"
    },
    {
      "city": "Claremont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Claremont, California"
    },
    {
      "city": "City Of Industry",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "City Of Industry, California"
    },
    {
      "city": "Covina",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Covina, California"
    },
    {
      "city": "El Monte",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Monte, California"
    },
    {
      "city": "South El Monte",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South El Monte, California"
    },
    {
      "city": "Glendora",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glendora, California"
    },
    {
      "city": "La Puente",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Puente, California"
    },
    {
      "city": "Hacienda Heights",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hacienda Heights, California"
    },
    {
      "city": "Rowland Heights",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rowland Heights, California"
    },
    {
      "city": "La Verne",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Verne, California"
    },
    {
      "city": "Monterey Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Monterey Park, California"
    },
    {
      "city": "Mt Baldy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mt Baldy, California"
    },
    {
      "city": "Diamond Bar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Diamond Bar, California"
    },
    {
      "city": "Pomona",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pomona, California"
    },
    {
      "city": "Rosemead",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rosemead, California"
    },
    {
      "city": "San Dimas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Dimas, California"
    },
    {
      "city": "San Gabriel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Gabriel, California"
    },
    {
      "city": "Temple City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Temple City, California"
    },
    {
      "city": "Walnut",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Walnut, California"
    },
    {
      "city": "West Covina",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "West Covina, California"
    },
    {
      "city": "Alhambra",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alhambra, California"
    },
    {
      "city": "Acton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Acton, California"
    },
    {
      "city": "Lake Hughes",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake Hughes, California"
    },
    {
      "city": "Lancaster",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lancaster, California"
    },
    {
      "city": "Littlerock",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Littlerock, California"
    },
    {
      "city": "Llano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Llano, California"
    },
    {
      "city": "Palmdale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palmdale, California"
    },
    {
      "city": "Pearblossom",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pearblossom, California"
    },
    {
      "city": "Valyermo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valyermo, California"
    },
    {
      "city": "Ahwahnee",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ahwahnee, California"
    },
    {
      "city": "Bass Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bass Lake, California"
    },
    {
      "city": "Chowchilla",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chowchilla, California"
    },
    {
      "city": "Coarsegold",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coarsegold, California"
    },
    {
      "city": "Madera",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Madera, California"
    },
    {
      "city": "North Fork",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North Fork, California"
    },
    {
      "city": "Oakhurst",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oakhurst, California"
    },
    {
      "city": "O Neals",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "O Neals, California"
    },
    {
      "city": "Raymond",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Raymond, California"
    },
    {
      "city": "Wishon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wishon, California"
    },
    {
      "city": "San Rafael",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Rafael, California"
    },
    {
      "city": "Greenbrae",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Greenbrae, California"
    },
    {
      "city": "Kentfield",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kentfield, California"
    },
    {
      "city": "Belvedere Tiburon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Belvedere Tiburon, California"
    },
    {
      "city": "Bolinas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bolinas, California"
    },
    {
      "city": "Corte Madera",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Corte Madera, California"
    },
    {
      "city": "Dillon Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dillon Beach, California"
    },
    {
      "city": "Fairfax",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fairfax, California"
    },
    {
      "city": "Forest Knolls",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forest Knolls, California"
    },
    {
      "city": "Inverness",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Inverness, California"
    },
    {
      "city": "Lagunitas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lagunitas, California"
    },
    {
      "city": "Larkspur",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Larkspur, California"
    },
    {
      "city": "Marshall",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Marshall, California"
    },
    {
      "city": "Mill Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mill Valley, California"
    },
    {
      "city": "Novato",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Novato, California"
    },
    {
      "city": "Nicasio",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nicasio, California"
    },
    {
      "city": "Olema",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Olema, California"
    },
    {
      "city": "Point Reyes Station",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Point Reyes Station, California"
    },
    {
      "city": "Ross",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ross, California"
    },
    {
      "city": "San Anselmo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Anselmo, California"
    },
    {
      "city": "San Geronimo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Geronimo, California"
    },
    {
      "city": "San Quentin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Quentin, California"
    },
    {
      "city": "Sausalito",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sausalito, California"
    },
    {
      "city": "Stinson Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stinson Beach, California"
    },
    {
      "city": "Tomales",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tomales, California"
    },
    {
      "city": "Woodacre",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woodacre, California"
    },
    {
      "city": "Fish Camp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fish Camp, California"
    },
    {
      "city": "Catheys Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Catheys Valley, California"
    },
    {
      "city": "Coulterville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coulterville, California"
    },
    {
      "city": "El Portal",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Portal, California"
    },
    {
      "city": "Hornitos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hornitos, California"
    },
    {
      "city": "Mariposa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mariposa, California"
    },
    {
      "city": "Midpines",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Midpines, California"
    },
    {
      "city": "Yosemite National Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yosemite National Park, California"
    },
    {
      "city": "Albion",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Albion, California"
    },
    {
      "city": "Boonville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Boonville, California"
    },
    {
      "city": "Branscomb",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Branscomb, California"
    },
    {
      "city": "Calpella",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calpella, California"
    },
    {
      "city": "Caspar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Caspar, California"
    },
    {
      "city": "Comptche",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Comptche, California"
    },
    {
      "city": "Covelo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Covelo, California"
    },
    {
      "city": "Dos Rios",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dos Rios, California"
    },
    {
      "city": "Elk",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Elk, California"
    },
    {
      "city": "Fort Bragg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fort Bragg, California"
    },
    {
      "city": "Gualala",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gualala, California"
    },
    {
      "city": "Hopland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hopland, California"
    },
    {
      "city": "Laytonville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laytonville, California"
    },
    {
      "city": "Little River",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Little River, California"
    },
    {
      "city": "Manchester",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Manchester, California"
    },
    {
      "city": "Mendocino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mendocino, California"
    },
    {
      "city": "Navarro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Navarro, California"
    },
    {
      "city": "Philo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Philo, California"
    },
    {
      "city": "Point Arena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Point Arena, California"
    },
    {
      "city": "Potter Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Potter Valley, California"
    },
    {
      "city": "Redwood Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redwood Valley, California"
    },
    {
      "city": "Talmage",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Talmage, California"
    },
    {
      "city": "Ukiah",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ukiah, California"
    },
    {
      "city": "Westport",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westport, California"
    },
    {
      "city": "Willits",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Willits, California"
    },
    {
      "city": "Yorkville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yorkville, California"
    },
    {
      "city": "Leggett",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Leggett, California"
    },
    {
      "city": "Piercy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Piercy, California"
    },
    {
      "city": "Dos Palos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dos Palos, California"
    },
    {
      "city": "Los Banos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Banos, California"
    },
    {
      "city": "Santa Rita Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Rita Park, California"
    },
    {
      "city": "South Dos Palos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South Dos Palos, California"
    },
    {
      "city": "Atwater",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Atwater, California"
    },
    {
      "city": "Ballico",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ballico, California"
    },
    {
      "city": "Cressey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cressey, California"
    },
    {
      "city": "Delhi",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Delhi, California"
    },
    {
      "city": "El Nido",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Nido, California"
    },
    {
      "city": "Gustine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gustine, California"
    },
    {
      "city": "Hilmar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hilmar, California"
    },
    {
      "city": "Le Grand",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Le Grand, California"
    },
    {
      "city": "Livingston",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Livingston, California"
    },
    {
      "city": "Merced",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Merced, California"
    },
    {
      "city": "Planada",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Planada, California"
    },
    {
      "city": "Snelling",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Snelling, California"
    },
    {
      "city": "Stevinson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stevinson, California"
    },
    {
      "city": "Winton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Winton, California"
    },
    {
      "city": "Adin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Adin, California"
    },
    {
      "city": "Canby",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Canby, California"
    },
    {
      "city": "Lookout",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lookout, California"
    },
    {
      "city": "Alturas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alturas, California"
    },
    {
      "city": "Cedarville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cedarville, California"
    },
    {
      "city": "Davis Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Davis Creek, California"
    },
    {
      "city": "Eagleville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Eagleville, California"
    },
    {
      "city": "Fort Bidwell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fort Bidwell, California"
    },
    {
      "city": "Lake City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake City, California"
    },
    {
      "city": "Likely",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Likely, California"
    },
    {
      "city": "Benton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Benton, California"
    },
    {
      "city": "Bridgeport",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bridgeport, California"
    },
    {
      "city": "June Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "June Lake, California"
    },
    {
      "city": "Lee Vining",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lee Vining, California"
    },
    {
      "city": "Mammoth Lakes",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mammoth Lakes, California"
    },
    {
      "city": "Coleville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coleville, California"
    },
    {
      "city": "Topaz",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Topaz, California"
    },
    {
      "city": "Bradley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bradley, California"
    },
    {
      "city": "San Ardo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Ardo, California"
    },
    {
      "city": "Salinas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Salinas, California"
    },
    {
      "city": "Big Sur",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Sur, California"
    },
    {
      "city": "Carmel By The Sea",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carmel By The Sea, California"
    },
    {
      "city": "Carmel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carmel, California"
    },
    {
      "city": "Carmel Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carmel Valley, California"
    },
    {
      "city": "Chualar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chualar, California"
    },
    {
      "city": "Gonzales",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gonzales, California"
    },
    {
      "city": "Greenfield",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Greenfield, California"
    },
    {
      "city": "Jolon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jolon, California"
    },
    {
      "city": "King City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "King City, California"
    },
    {
      "city": "Lockwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lockwood, California"
    },
    {
      "city": "Marina",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Marina, California"
    },
    {
      "city": "Monterey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Monterey, California"
    },
    {
      "city": "Pacific Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pacific Grove, California"
    },
    {
      "city": "Pebble Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pebble Beach, California"
    },
    {
      "city": "San Lucas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Lucas, California"
    },
    {
      "city": "Seaside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Seaside, California"
    },
    {
      "city": "Soledad",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Soledad, California"
    },
    {
      "city": "Spreckels",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Spreckels, California"
    },
    {
      "city": "Aromas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Aromas, California"
    },
    {
      "city": "Castroville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Castroville, California"
    },
    {
      "city": "Moss Landing",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moss Landing, California"
    },
    {
      "city": "American Canyon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "American Canyon, California"
    },
    {
      "city": "Angwin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Angwin, California"
    },
    {
      "city": "Calistoga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calistoga, California"
    },
    {
      "city": "Napa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Napa, California"
    },
    {
      "city": "Oakville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oakville, California"
    },
    {
      "city": "Pope Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pope Valley, California"
    },
    {
      "city": "Rutherford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rutherford, California"
    },
    {
      "city": "Saint Helena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Saint Helena, California"
    },
    {
      "city": "Deer Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Deer Park, California"
    },
    {
      "city": "Yountville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yountville, California"
    },
    {
      "city": "Chicago Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chicago Park, California"
    },
    {
      "city": "Norden",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Norden, California"
    },
    {
      "city": "Soda Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Soda Springs, California"
    },
    {
      "city": "Cedar Ridge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cedar Ridge, California"
    },
    {
      "city": "Grass Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grass Valley, California"
    },
    {
      "city": "Penn Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Penn Valley, California"
    },
    {
      "city": "Nevada City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nevada City, California"
    },
    {
      "city": "North San Juan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North San Juan, California"
    },
    {
      "city": "Rough And Ready",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rough And Ready, California"
    },
    {
      "city": "Smartsville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Smartsville, California"
    },
    {
      "city": "Washington",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Washington, California"
    },
    {
      "city": "Floriston",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Floriston, California"
    },
    {
      "city": "Truckee",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Truckee, California"
    },
    {
      "city": "Buena Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Buena Park, California"
    },
    {
      "city": "La Palma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Palma, California"
    },
    {
      "city": "Cypress",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cypress, California"
    },
    {
      "city": "La Habra",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Habra, California"
    },
    {
      "city": "Stanton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stanton, California"
    },
    {
      "city": "Los Alamitos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Alamitos, California"
    },
    {
      "city": "Seal Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Seal Beach, California"
    },
    {
      "city": "Sunset Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sunset Beach, California"
    },
    {
      "city": "Surfside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Surfside, California"
    },
    {
      "city": "Irvine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Irvine, California"
    },
    {
      "city": "Huntington Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Huntington Beach, California"
    },
    {
      "city": "Laguna Niguel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laguna Niguel, California"
    },
    {
      "city": "El Toro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Toro, California"
    },
    {
      "city": "Foothill Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Foothill Ranch, California"
    },
    {
      "city": "Capistrano Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Capistrano Beach, California"
    },
    {
      "city": "Corona Del Mar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Corona Del Mar, California"
    },
    {
      "city": "Costa Mesa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Costa Mesa, California"
    },
    {
      "city": "Dana Point",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dana Point, California"
    },
    {
      "city": "Lake Forest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake Forest, California"
    },
    {
      "city": "Laguna Woods",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laguna Woods, California"
    },
    {
      "city": "East Irvine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "East Irvine, California"
    },
    {
      "city": "Laguna Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laguna Beach, California"
    },
    {
      "city": "Laguna Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Laguna Hills, California"
    },
    {
      "city": "Midway City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Midway City, California"
    },
    {
      "city": "Aliso Viejo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Aliso Viejo, California"
    },
    {
      "city": "Newport Coast",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newport Coast, California"
    },
    {
      "city": "Newport Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newport Beach, California"
    },
    {
      "city": "San Clemente",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Clemente, California"
    },
    {
      "city": "San Juan Capistrano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Juan Capistrano, California"
    },
    {
      "city": "Silverado",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Silverado, California"
    },
    {
      "city": "Trabuco Canyon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Trabuco Canyon, California"
    },
    {
      "city": "Westminster",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westminster, California"
    },
    {
      "city": "Rancho Santa Margarita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Santa Margarita, California"
    },
    {
      "city": "Mission Viejo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mission Viejo, California"
    },
    {
      "city": "Ladera Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ladera Ranch, California"
    },
    {
      "city": "Santa Ana",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Ana, California"
    },
    {
      "city": "Fountain Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fountain Valley, California"
    },
    {
      "city": "Tustin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tustin, California"
    },
    {
      "city": "Anaheim",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Anaheim, California"
    },
    {
      "city": "Atwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Atwood, California"
    },
    {
      "city": "Brea",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brea, California"
    },
    {
      "city": "Fullerton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fullerton, California"
    },
    {
      "city": "Garden Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Garden Grove, California"
    },
    {
      "city": "Orange",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orange, California"
    },
    {
      "city": "Villa Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Villa Park, California"
    },
    {
      "city": "Placentia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Placentia, California"
    },
    {
      "city": "Yorba Linda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yorba Linda, California"
    },
    {
      "city": "Auburn",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Auburn, California"
    },
    {
      "city": "Foresthill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Foresthill, California"
    },
    {
      "city": "Lincoln",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lincoln, California"
    },
    {
      "city": "Loomis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Loomis, California"
    },
    {
      "city": "Newcastle",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newcastle, California"
    },
    {
      "city": "Roseville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Roseville, California"
    },
    {
      "city": "Penryn",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Penryn, California"
    },
    {
      "city": "Rocklin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rocklin, California"
    },
    {
      "city": "Sheridan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sheridan, California"
    },
    {
      "city": "Alta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alta, California"
    },
    {
      "city": "Applegate",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Applegate, California"
    },
    {
      "city": "Colfax",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Colfax, California"
    },
    {
      "city": "Dutch Flat",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dutch Flat, California"
    },
    {
      "city": "Emigrant Gap",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Emigrant Gap, California"
    },
    {
      "city": "Gold Run",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gold Run, California"
    },
    {
      "city": "Meadow Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Meadow Vista, California"
    },
    {
      "city": "Weimar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Weimar, California"
    },
    {
      "city": "Granite Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Granite Bay, California"
    },
    {
      "city": "Carnelian Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carnelian Bay, California"
    },
    {
      "city": "Homewood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Homewood, California"
    },
    {
      "city": "Kings Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kings Beach, California"
    },
    {
      "city": "Tahoe City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tahoe City, California"
    },
    {
      "city": "Olympic Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Olympic Valley, California"
    },
    {
      "city": "Tahoe Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tahoe Vista, California"
    },
    {
      "city": "Belden",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Belden, California"
    },
    {
      "city": "Canyon Dam",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Canyon Dam, California"
    },
    {
      "city": "Crescent Mills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Crescent Mills, California"
    },
    {
      "city": "Greenville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Greenville, California"
    },
    {
      "city": "Meadow Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Meadow Valley, California"
    },
    {
      "city": "Quincy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Quincy, California"
    },
    {
      "city": "Storrie",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Storrie, California"
    },
    {
      "city": "Taylorsville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Taylorsville, California"
    },
    {
      "city": "Twain",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Twain, California"
    },
    {
      "city": "Chester",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chester, California"
    },
    {
      "city": "Blairsden Graeagle",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Blairsden Graeagle, California"
    },
    {
      "city": "Chilcoot",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chilcoot, California"
    },
    {
      "city": "Clio",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clio, California"
    },
    {
      "city": "Portola",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Portola, California"
    },
    {
      "city": "Beckwourth",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Beckwourth, California"
    },
    {
      "city": "Vinton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vinton, California"
    },
    {
      "city": "Mira Loma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mira Loma, California"
    },
    {
      "city": "Indio",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Indio, California"
    },
    {
      "city": "Indian Wells",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Indian Wells, California"
    },
    {
      "city": "Palm Desert",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palm Desert, California"
    },
    {
      "city": "Banning",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Banning, California"
    },
    {
      "city": "Beaumont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Beaumont, California"
    },
    {
      "city": "Blythe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Blythe, California"
    },
    {
      "city": "Cabazon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cabazon, California"
    },
    {
      "city": "Cathedral City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cathedral City, California"
    },
    {
      "city": "Coachella",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coachella, California"
    },
    {
      "city": "Desert Center",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Desert Center, California"
    },
    {
      "city": "Desert Hot Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Desert Hot Springs, California"
    },
    {
      "city": "La Quinta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Quinta, California"
    },
    {
      "city": "Mecca",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mecca, California"
    },
    {
      "city": "North Palm Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North Palm Springs, California"
    },
    {
      "city": "Palm Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palm Springs, California"
    },
    {
      "city": "Rancho Mirage",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Mirage, California"
    },
    {
      "city": "Thermal",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Thermal, California"
    },
    {
      "city": "Thousand Palms",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Thousand Palms, California"
    },
    {
      "city": "Whitewater",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Whitewater, California"
    },
    {
      "city": "Calimesa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calimesa, California"
    },
    {
      "city": "Riverside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Riverside, California"
    },
    {
      "city": "March Air Reserve Base",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "March Air Reserve Base, California"
    },
    {
      "city": "Lake Elsinore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake Elsinore, California"
    },
    {
      "city": "Aguanga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Aguanga, California"
    },
    {
      "city": "Anza",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Anza, California"
    },
    {
      "city": "Hemet",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hemet, California"
    },
    {
      "city": "Homeland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Homeland, California"
    },
    {
      "city": "Idyllwild",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Idyllwild, California"
    },
    {
      "city": "Moreno Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moreno Valley, California"
    },
    {
      "city": "Mountain Center",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mountain Center, California"
    },
    {
      "city": "Murrieta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Murrieta, California"
    },
    {
      "city": "Nuevo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nuevo, California"
    },
    {
      "city": "Perris",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Perris, California"
    },
    {
      "city": "San Jacinto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Jacinto, California"
    },
    {
      "city": "Menifee",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Menifee, California"
    },
    {
      "city": "Sun City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sun City, California"
    },
    {
      "city": "Quail Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Quail Valley, California"
    },
    {
      "city": "Temecula",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Temecula, California"
    },
    {
      "city": "Wildomar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wildomar, California"
    },
    {
      "city": "Winchester",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Winchester, California"
    },
    {
      "city": "Norco",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Norco, California"
    },
    {
      "city": "Corona",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Corona, California"
    },
    {
      "city": "Sacramento",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sacramento, California"
    },
    {
      "city": "Carmichael",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carmichael, California"
    },
    {
      "city": "Citrus Heights",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Citrus Heights, California"
    },
    {
      "city": "Courtland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Courtland, California"
    },
    {
      "city": "Elk Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Elk Grove, California"
    },
    {
      "city": "Elverta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Elverta, California"
    },
    {
      "city": "Fair Oaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fair Oaks, California"
    },
    {
      "city": "Folsom",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Folsom, California"
    },
    {
      "city": "Galt",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Galt, California"
    },
    {
      "city": "Herald",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Herald, California"
    },
    {
      "city": "Hood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hood, California"
    },
    {
      "city": "Isleton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Isleton, California"
    },
    {
      "city": "Mcclellan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mcclellan, California"
    },
    {
      "city": "Mather",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mather, California"
    },
    {
      "city": "North Highlands",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "North Highlands, California"
    },
    {
      "city": "Orangevale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orangevale, California"
    },
    {
      "city": "Rancho Cordova",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Cordova, California"
    },
    {
      "city": "Represa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Represa, California"
    },
    {
      "city": "Rio Linda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rio Linda, California"
    },
    {
      "city": "Ryde",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ryde, California"
    },
    {
      "city": "Sloughhouse",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sloughhouse, California"
    },
    {
      "city": "Walnut Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Walnut Grove, California"
    },
    {
      "city": "Wilton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wilton, California"
    },
    {
      "city": "Antelope",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Antelope, California"
    },
    {
      "city": "Hollister",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hollister, California"
    },
    {
      "city": "Paicines",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paicines, California"
    },
    {
      "city": "San Juan Bautista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Juan Bautista, California"
    },
    {
      "city": "Tres Pinos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tres Pinos, California"
    },
    {
      "city": "Rancho Cucamonga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Cucamonga, California"
    },
    {
      "city": "Chino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chino, California"
    },
    {
      "city": "Chino Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chino Hills, California"
    },
    {
      "city": "Guasti",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Guasti, California"
    },
    {
      "city": "Ontario",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ontario, California"
    },
    {
      "city": "Montclair",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montclair, California"
    },
    {
      "city": "Upland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Upland, California"
    },
    {
      "city": "Earp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Earp, California"
    },
    {
      "city": "Joshua Tree",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Joshua Tree, California"
    },
    {
      "city": "Morongo Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Morongo Valley, California"
    },
    {
      "city": "Parker Dam",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Parker Dam, California"
    },
    {
      "city": "Pioneertown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pioneertown, California"
    },
    {
      "city": "Twentynine Palms",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Twentynine Palms, California"
    },
    {
      "city": "Vidal",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vidal, California"
    },
    {
      "city": "Yucca Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yucca Valley, California"
    },
    {
      "city": "Landers",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Landers, California"
    },
    {
      "city": "Adelanto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Adelanto, California"
    },
    {
      "city": "Amboy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Amboy, California"
    },
    {
      "city": "Angelus Oaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Angelus Oaks, California"
    },
    {
      "city": "Apple Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Apple Valley, California"
    },
    {
      "city": "Baker",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Baker, California"
    },
    {
      "city": "Fort Irwin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fort Irwin, California"
    },
    {
      "city": "Barstow",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Barstow, California"
    },
    {
      "city": "Grand Terrace",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grand Terrace, California"
    },
    {
      "city": "Big Bear City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Bear City, California"
    },
    {
      "city": "Big Bear Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Bear Lake, California"
    },
    {
      "city": "Bloomington",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bloomington, California"
    },
    {
      "city": "Blue Jay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Blue Jay, California"
    },
    {
      "city": "Bryn Mawr",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bryn Mawr, California"
    },
    {
      "city": "Cedar Glen",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cedar Glen, California"
    },
    {
      "city": "Cedarpines Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cedarpines Park, California"
    },
    {
      "city": "Cima",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cima, California"
    },
    {
      "city": "Colton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Colton, California"
    },
    {
      "city": "Crestline",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Crestline, California"
    },
    {
      "city": "Daggett",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Daggett, California"
    },
    {
      "city": "Phelan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Phelan, California"
    },
    {
      "city": "Essex",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Essex, California"
    },
    {
      "city": "Fawnskin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fawnskin, California"
    },
    {
      "city": "Ludlow",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ludlow, California"
    },
    {
      "city": "Forest Falls",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forest Falls, California"
    },
    {
      "city": "Hesperia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hesperia, California"
    },
    {
      "city": "Green Valley Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Green Valley Lake, California"
    },
    {
      "city": "Helendale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Helendale, California"
    },
    {
      "city": "Highland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Highland, California"
    },
    {
      "city": "Hinkley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hinkley, California"
    },
    {
      "city": "Loma Linda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Loma Linda, California"
    },
    {
      "city": "Lake Arrowhead",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lake Arrowhead, California"
    },
    {
      "city": "Lucerne Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lucerne Valley, California"
    },
    {
      "city": "Lytle Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lytle Creek, California"
    },
    {
      "city": "Mentone",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mentone, California"
    },
    {
      "city": "Needles",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Needles, California"
    },
    {
      "city": "Nipton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nipton, California"
    },
    {
      "city": "Newberry Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newberry Springs, California"
    },
    {
      "city": "Mountain Pass",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mountain Pass, California"
    },
    {
      "city": "Oro Grande",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oro Grande, California"
    },
    {
      "city": "Patton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Patton, California"
    },
    {
      "city": "Pinon Hills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pinon Hills, California"
    },
    {
      "city": "Redlands",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redlands, California"
    },
    {
      "city": "Rialto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rialto, California"
    },
    {
      "city": "Rimforest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rimforest, California"
    },
    {
      "city": "Running Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Running Springs, California"
    },
    {
      "city": "Skyforest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Skyforest, California"
    },
    {
      "city": "Sugarloaf",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sugarloaf, California"
    },
    {
      "city": "Twin Peaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Twin Peaks, California"
    },
    {
      "city": "Victorville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Victorville, California"
    },
    {
      "city": "Wrightwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wrightwood, California"
    },
    {
      "city": "Yermo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yermo, California"
    },
    {
      "city": "Yucaipa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yucaipa, California"
    },
    {
      "city": "San Bernardino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Bernardino, California"
    },
    {
      "city": "Red Mountain",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Red Mountain, California"
    },
    {
      "city": "Trona",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Trona, California"
    },
    {
      "city": "Alpine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alpine, California"
    },
    {
      "city": "Bonita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bonita, California"
    },
    {
      "city": "Boulevard",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Boulevard, California"
    },
    {
      "city": "Campo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Campo, California"
    },
    {
      "city": "Chula Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chula Vista, California"
    },
    {
      "city": "Descanso",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Descanso, California"
    },
    {
      "city": "Dulzura",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dulzura, California"
    },
    {
      "city": "Guatay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Guatay, California"
    },
    {
      "city": "Imperial Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Imperial Beach, California"
    },
    {
      "city": "Jacumba",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jacumba, California"
    },
    {
      "city": "Jamul",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jamul, California"
    },
    {
      "city": "La Mesa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Mesa, California"
    },
    {
      "city": "Lemon Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lemon Grove, California"
    },
    {
      "city": "Mount Laguna",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Laguna, California"
    },
    {
      "city": "National City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "National City, California"
    },
    {
      "city": "Pine Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pine Valley, California"
    },
    {
      "city": "Potrero",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Potrero, California"
    },
    {
      "city": "Spring Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Spring Valley, California"
    },
    {
      "city": "Tecate",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tecate, California"
    },
    {
      "city": "Bonsall",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bonsall, California"
    },
    {
      "city": "Borrego Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Borrego Springs, California"
    },
    {
      "city": "Cardiff By The Sea",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cardiff By The Sea, California"
    },
    {
      "city": "Carlsbad",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carlsbad, California"
    },
    {
      "city": "Del Mar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Del Mar, California"
    },
    {
      "city": "El Cajon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Cajon, California"
    },
    {
      "city": "Encinitas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Encinitas, California"
    },
    {
      "city": "Escondido",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Escondido, California"
    },
    {
      "city": "Fallbrook",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fallbrook, California"
    },
    {
      "city": "Julian",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Julian, California"
    },
    {
      "city": "La Jolla",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Jolla, California"
    },
    {
      "city": "Lakeside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lakeside, California"
    },
    {
      "city": "Oceanside",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oceanside, California"
    },
    {
      "city": "Camp Pendleton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camp Pendleton, California"
    },
    {
      "city": "Pala",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pala, California"
    },
    {
      "city": "Palomar Mountain",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palomar Mountain, California"
    },
    {
      "city": "Pauma Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pauma Valley, California"
    },
    {
      "city": "Poway",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Poway, California"
    },
    {
      "city": "Ramona",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ramona, California"
    },
    {
      "city": "Ranchita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ranchita, California"
    },
    {
      "city": "Rancho Santa Fe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rancho Santa Fe, California"
    },
    {
      "city": "San Luis Rey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Luis Rey, California"
    },
    {
      "city": "San Marcos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Marcos, California"
    },
    {
      "city": "Santa Ysabel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Ysabel, California"
    },
    {
      "city": "Santee",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santee, California"
    },
    {
      "city": "Solana Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Solana Beach, California"
    },
    {
      "city": "Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vista, California"
    },
    {
      "city": "Valley Center",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valley Center, California"
    },
    {
      "city": "Warner Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Warner Springs, California"
    },
    {
      "city": "San Diego",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Diego, California"
    },
    {
      "city": "Coronado",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coronado, California"
    },
    {
      "city": "San Ysidro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Ysidro, California"
    },
    {
      "city": "San Francisco",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Francisco, California"
    },
    {
      "city": "Stockton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stockton, California"
    },
    {
      "city": "Acampo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Acampo, California"
    },
    {
      "city": "Clements",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clements, California"
    },
    {
      "city": "Farmington",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Farmington, California"
    },
    {
      "city": "French Camp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "French Camp, California"
    },
    {
      "city": "Holt",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Holt, California"
    },
    {
      "city": "Linden",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Linden, California"
    },
    {
      "city": "Lockeford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lockeford, California"
    },
    {
      "city": "Lodi",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lodi, California"
    },
    {
      "city": "Victor",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Victor, California"
    },
    {
      "city": "Woodbridge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woodbridge, California"
    },
    {
      "city": "Tracy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tracy, California"
    },
    {
      "city": "Escalon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Escalon, California"
    },
    {
      "city": "Lathrop",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lathrop, California"
    },
    {
      "city": "Manteca",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Manteca, California"
    },
    {
      "city": "Ripon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ripon, California"
    },
    {
      "city": "Vernalis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vernalis, California"
    },
    {
      "city": "Thornton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Thornton, California"
    },
    {
      "city": "San Luis Obispo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Luis Obispo, California"
    },
    {
      "city": "Los Osos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Osos, California"
    },
    {
      "city": "Arroyo Grande",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Arroyo Grande, California"
    },
    {
      "city": "Atascadero",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Atascadero, California"
    },
    {
      "city": "Avila Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Avila Beach, California"
    },
    {
      "city": "Cambria",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cambria, California"
    },
    {
      "city": "Cayucos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cayucos, California"
    },
    {
      "city": "Creston",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Creston, California"
    },
    {
      "city": "Grover Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grover Beach, California"
    },
    {
      "city": "Harmony",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Harmony, California"
    },
    {
      "city": "Morro Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Morro Bay, California"
    },
    {
      "city": "Nipomo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nipomo, California"
    },
    {
      "city": "Oceano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oceano, California"
    },
    {
      "city": "Paso Robles",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paso Robles, California"
    },
    {
      "city": "Pismo Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pismo Beach, California"
    },
    {
      "city": "San Miguel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Miguel, California"
    },
    {
      "city": "San Simeon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Simeon, California"
    },
    {
      "city": "Santa Margarita",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Margarita, California"
    },
    {
      "city": "Shandon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shandon, California"
    },
    {
      "city": "Templeton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Templeton, California"
    },
    {
      "city": "Belmont",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Belmont, California"
    },
    {
      "city": "Brisbane",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brisbane, California"
    },
    {
      "city": "Burlingame",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burlingame, California"
    },
    {
      "city": "Daly City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Daly City, California"
    },
    {
      "city": "El Granada",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Granada, California"
    },
    {
      "city": "Half Moon Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Half Moon Bay, California"
    },
    {
      "city": "La Honda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Honda, California"
    },
    {
      "city": "Loma Mar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Loma Mar, California"
    },
    {
      "city": "Menlo Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Menlo Park, California"
    },
    {
      "city": "Atherton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Atherton, California"
    },
    {
      "city": "Portola Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Portola Valley, California"
    },
    {
      "city": "Millbrae",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Millbrae, California"
    },
    {
      "city": "Montara",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montara, California"
    },
    {
      "city": "Moss Beach",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moss Beach, California"
    },
    {
      "city": "Pacifica",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pacifica, California"
    },
    {
      "city": "Pescadero",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pescadero, California"
    },
    {
      "city": "Redwood City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redwood City, California"
    },
    {
      "city": "San Bruno",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Bruno, California"
    },
    {
      "city": "San Carlos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Carlos, California"
    },
    {
      "city": "San Gregorio",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Gregorio, California"
    },
    {
      "city": "South San Francisco",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "South San Francisco, California"
    },
    {
      "city": "Palo Alto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palo Alto, California"
    },
    {
      "city": "San Mateo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Mateo, California"
    },
    {
      "city": "Carpinteria",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Carpinteria, California"
    },
    {
      "city": "Summerland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Summerland, California"
    },
    {
      "city": "Santa Barbara",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Barbara, California"
    },
    {
      "city": "Goleta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Goleta, California"
    },
    {
      "city": "New Cuyama",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "New Cuyama, California"
    },
    {
      "city": "Buellton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Buellton, California"
    },
    {
      "city": "Casmalia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Casmalia, California"
    },
    {
      "city": "Guadalupe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Guadalupe, California"
    },
    {
      "city": "Lompoc",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lompoc, California"
    },
    {
      "city": "Los Alamos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Alamos, California"
    },
    {
      "city": "Los Olivos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Olivos, California"
    },
    {
      "city": "Santa Maria",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Maria, California"
    },
    {
      "city": "Santa Ynez",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Ynez, California"
    },
    {
      "city": "Solvang",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Solvang, California"
    },
    {
      "city": "Los Altos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Altos, California"
    },
    {
      "city": "Mountain View",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mountain View, California"
    },
    {
      "city": "Sunnyvale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sunnyvale, California"
    },
    {
      "city": "Stanford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stanford, California"
    },
    {
      "city": "Alviso",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alviso, California"
    },
    {
      "city": "Campbell",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Campbell, California"
    },
    {
      "city": "Coyote",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Coyote, California"
    },
    {
      "city": "Cupertino",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cupertino, California"
    },
    {
      "city": "Gilroy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gilroy, California"
    },
    {
      "city": "Holy City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Holy City, California"
    },
    {
      "city": "Los Gatos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Gatos, California"
    },
    {
      "city": "Milpitas",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Milpitas, California"
    },
    {
      "city": "Morgan Hill",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Morgan Hill, California"
    },
    {
      "city": "New Almaden",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "New Almaden, California"
    },
    {
      "city": "Redwood Estates",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redwood Estates, California"
    },
    {
      "city": "San Martin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Martin, California"
    },
    {
      "city": "Santa Clara",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Clara, California"
    },
    {
      "city": "Saratoga",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Saratoga, California"
    },
    {
      "city": "San Jose",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "San Jose, California"
    },
    {
      "city": "Mount Hamilton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Hamilton, California"
    },
    {
      "city": "Aptos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Aptos, California"
    },
    {
      "city": "Ben Lomond",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ben Lomond, California"
    },
    {
      "city": "Boulder Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Boulder Creek, California"
    },
    {
      "city": "Brookdale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brookdale, California"
    },
    {
      "city": "Capitola",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Capitola, California"
    },
    {
      "city": "Davenport",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Davenport, California"
    },
    {
      "city": "Felton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Felton, California"
    },
    {
      "city": "Freedom",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Freedom, California"
    },
    {
      "city": "Mount Hermon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Hermon, California"
    },
    {
      "city": "Santa Cruz",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Cruz, California"
    },
    {
      "city": "Scotts Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Scotts Valley, California"
    },
    {
      "city": "Soquel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Soquel, California"
    },
    {
      "city": "Watsonville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Watsonville, California"
    },
    {
      "city": "Redding",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Redding, California"
    },
    {
      "city": "Anderson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Anderson, California"
    },
    {
      "city": "Bella Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bella Vista, California"
    },
    {
      "city": "Big Bend",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Bend, California"
    },
    {
      "city": "Burney",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burney, California"
    },
    {
      "city": "Cassel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cassel, California"
    },
    {
      "city": "Castella",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Castella, California"
    },
    {
      "city": "Shasta Lake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shasta Lake, California"
    },
    {
      "city": "Cottonwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cottonwood, California"
    },
    {
      "city": "Fall River Mills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fall River Mills, California"
    },
    {
      "city": "French Gulch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "French Gulch, California"
    },
    {
      "city": "Hat Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hat Creek, California"
    },
    {
      "city": "Igo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Igo, California"
    },
    {
      "city": "Lakehead",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lakehead, California"
    },
    {
      "city": "Mcarthur",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mcarthur, California"
    },
    {
      "city": "Millville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Millville, California"
    },
    {
      "city": "Montgomery Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montgomery Creek, California"
    },
    {
      "city": "Oak Run",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oak Run, California"
    },
    {
      "city": "Obrien",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Obrien, California"
    },
    {
      "city": "Old Station",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Old Station, California"
    },
    {
      "city": "Palo Cedro",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Palo Cedro, California"
    },
    {
      "city": "Platina",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Platina, California"
    },
    {
      "city": "Round Mountain",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Round Mountain, California"
    },
    {
      "city": "Shasta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shasta, California"
    },
    {
      "city": "Shingletown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Shingletown, California"
    },
    {
      "city": "Whiskeytown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Whiskeytown, California"
    },
    {
      "city": "Whitmore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Whitmore, California"
    },
    {
      "city": "Alleghany",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alleghany, California"
    },
    {
      "city": "Downieville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Downieville, California"
    },
    {
      "city": "Goodyears Bar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Goodyears Bar, California"
    },
    {
      "city": "Loyalton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Loyalton, California"
    },
    {
      "city": "Calpine",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Calpine, California"
    },
    {
      "city": "Sierra City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sierra City, California"
    },
    {
      "city": "Sierraville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sierraville, California"
    },
    {
      "city": "Somes Bar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Somes Bar, California"
    },
    {
      "city": "Callahan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Callahan, California"
    },
    {
      "city": "Dorris",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dorris, California"
    },
    {
      "city": "Dunsmuir",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dunsmuir, California"
    },
    {
      "city": "Etna",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Etna, California"
    },
    {
      "city": "Forks Of Salmon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forks Of Salmon, California"
    },
    {
      "city": "Fort Jones",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fort Jones, California"
    },
    {
      "city": "Gazelle",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gazelle, California"
    },
    {
      "city": "Greenview",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Greenview, California"
    },
    {
      "city": "Grenada",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Grenada, California"
    },
    {
      "city": "Happy Camp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Happy Camp, California"
    },
    {
      "city": "Hornbrook",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hornbrook, California"
    },
    {
      "city": "Klamath River",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Klamath River, California"
    },
    {
      "city": "Mccloud",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mccloud, California"
    },
    {
      "city": "Macdoel",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Macdoel, California"
    },
    {
      "city": "Montague",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Montague, California"
    },
    {
      "city": "Mount Shasta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mount Shasta, California"
    },
    {
      "city": "Scott Bar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Scott Bar, California"
    },
    {
      "city": "Seiad Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Seiad Valley, California"
    },
    {
      "city": "Weed",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Weed, California"
    },
    {
      "city": "Yreka",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yreka, California"
    },
    {
      "city": "Tulelake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tulelake, California"
    },
    {
      "city": "Benicia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Benicia, California"
    },
    {
      "city": "Birds Landing",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Birds Landing, California"
    },
    {
      "city": "Fairfield",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fairfield, California"
    },
    {
      "city": "Travis Afb",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Travis Afb, California"
    },
    {
      "city": "Rio Vista",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rio Vista, California"
    },
    {
      "city": "Suisun City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Suisun City, California"
    },
    {
      "city": "Vallejo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vallejo, California"
    },
    {
      "city": "Dixon",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dixon, California"
    },
    {
      "city": "Elmira",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Elmira, California"
    },
    {
      "city": "Vacaville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vacaville, California"
    },
    {
      "city": "Bodega",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bodega, California"
    },
    {
      "city": "Bodega Bay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Bodega Bay, California"
    },
    {
      "city": "Rohnert Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rohnert Park, California"
    },
    {
      "city": "Cotati",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cotati, California"
    },
    {
      "city": "Penngrove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Penngrove, California"
    },
    {
      "city": "Petaluma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Petaluma, California"
    },
    {
      "city": "Valley Ford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Valley Ford, California"
    },
    {
      "city": "Santa Rosa",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Rosa, California"
    },
    {
      "city": "Annapolis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Annapolis, California"
    },
    {
      "city": "Boyes Hot Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Boyes Hot Springs, California"
    },
    {
      "city": "Camp Meeker",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camp Meeker, California"
    },
    {
      "city": "Cazadero",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cazadero, California"
    },
    {
      "city": "Cloverdale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cloverdale, California"
    },
    {
      "city": "Duncans Mills",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Duncans Mills, California"
    },
    {
      "city": "Eldridge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Eldridge, California"
    },
    {
      "city": "El Verano",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "El Verano, California"
    },
    {
      "city": "Forestville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Forestville, California"
    },
    {
      "city": "Fulton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fulton, California"
    },
    {
      "city": "Geyserville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Geyserville, California"
    },
    {
      "city": "Glen Ellen",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Glen Ellen, California"
    },
    {
      "city": "Graton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Graton, California"
    },
    {
      "city": "Guerneville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Guerneville, California"
    },
    {
      "city": "Healdsburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Healdsburg, California"
    },
    {
      "city": "Jenner",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jenner, California"
    },
    {
      "city": "Kenwood",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kenwood, California"
    },
    {
      "city": "Monte Rio",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Monte Rio, California"
    },
    {
      "city": "Occidental",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Occidental, California"
    },
    {
      "city": "Rio Nido",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rio Nido, California"
    },
    {
      "city": "Sebastopol",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sebastopol, California"
    },
    {
      "city": "Sonoma",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sonoma, California"
    },
    {
      "city": "Stewarts Point",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Stewarts Point, California"
    },
    {
      "city": "Villa Grande",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Villa Grande, California"
    },
    {
      "city": "Vineburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vineburg, California"
    },
    {
      "city": "Windsor",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Windsor, California"
    },
    {
      "city": "The Sea Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "The Sea Ranch, California"
    },
    {
      "city": "Ceres",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ceres, California"
    },
    {
      "city": "Crows Landing",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Crows Landing, California"
    },
    {
      "city": "Denair",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Denair, California"
    },
    {
      "city": "Empire",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Empire, California"
    },
    {
      "city": "Hickman",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hickman, California"
    },
    {
      "city": "Hughson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hughson, California"
    },
    {
      "city": "Keyes",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Keyes, California"
    },
    {
      "city": "La Grange",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "La Grange, California"
    },
    {
      "city": "Modesto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Modesto, California"
    },
    {
      "city": "Newman",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newman, California"
    },
    {
      "city": "Oakdale",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oakdale, California"
    },
    {
      "city": "Patterson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Patterson, California"
    },
    {
      "city": "Riverbank",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Riverbank, California"
    },
    {
      "city": "Salida",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Salida, California"
    },
    {
      "city": "Turlock",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Turlock, California"
    },
    {
      "city": "Waterford",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Waterford, California"
    },
    {
      "city": "Westley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westley, California"
    },
    {
      "city": "Nicolaus",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Nicolaus, California"
    },
    {
      "city": "Pleasant Grove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pleasant Grove, California"
    },
    {
      "city": "Rio Oso",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rio Oso, California"
    },
    {
      "city": "Robbins",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Robbins, California"
    },
    {
      "city": "Live Oak",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Live Oak, California"
    },
    {
      "city": "Meridian",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Meridian, California"
    },
    {
      "city": "Sutter",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sutter, California"
    },
    {
      "city": "Yuba City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yuba City, California"
    },
    {
      "city": "Corning",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Corning, California"
    },
    {
      "city": "Flournoy",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Flournoy, California"
    },
    {
      "city": "Gerber",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Gerber, California"
    },
    {
      "city": "Los Molinos",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Los Molinos, California"
    },
    {
      "city": "Manton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Manton, California"
    },
    {
      "city": "Mill Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mill Creek, California"
    },
    {
      "city": "Mineral",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mineral, California"
    },
    {
      "city": "Paskenta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paskenta, California"
    },
    {
      "city": "Paynes Creek",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Paynes Creek, California"
    },
    {
      "city": "Proberta",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Proberta, California"
    },
    {
      "city": "Red Bluff",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Red Bluff, California"
    },
    {
      "city": "Tehama",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tehama, California"
    },
    {
      "city": "Vina",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Vina, California"
    },
    {
      "city": "Burnt Ranch",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Burnt Ranch, California"
    },
    {
      "city": "Mad River",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mad River, California"
    },
    {
      "city": "Salyer",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Salyer, California"
    },
    {
      "city": "Zenia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Zenia, California"
    },
    {
      "city": "Big Bar",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Bar, California"
    },
    {
      "city": "Douglas City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Douglas City, California"
    },
    {
      "city": "Hayfork",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hayfork, California"
    },
    {
      "city": "Hyampom",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Hyampom, California"
    },
    {
      "city": "Junction City",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Junction City, California"
    },
    {
      "city": "Lewiston",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lewiston, California"
    },
    {
      "city": "Trinity Center",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Trinity Center, California"
    },
    {
      "city": "Weaverville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Weaverville, California"
    },
    {
      "city": "Alpaugh",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Alpaugh, California"
    },
    {
      "city": "California Hot Springs",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "California Hot Springs, California"
    },
    {
      "city": "Camp Nelson",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camp Nelson, California"
    },
    {
      "city": "Ducor",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ducor, California"
    },
    {
      "city": "Earlimart",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Earlimart, California"
    },
    {
      "city": "Exeter",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Exeter, California"
    },
    {
      "city": "Farmersville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Farmersville, California"
    },
    {
      "city": "Goshen",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Goshen, California"
    },
    {
      "city": "Ivanhoe",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ivanhoe, California"
    },
    {
      "city": "Kaweah",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kaweah, California"
    },
    {
      "city": "Lemon Cove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lemon Cove, California"
    },
    {
      "city": "Lindsay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Lindsay, California"
    },
    {
      "city": "Pixley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pixley, California"
    },
    {
      "city": "Porterville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Porterville, California"
    },
    {
      "city": "Posey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Posey, California"
    },
    {
      "city": "Richgrove",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Richgrove, California"
    },
    {
      "city": "Sequoia National Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sequoia National Park, California"
    },
    {
      "city": "Springville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Springville, California"
    },
    {
      "city": "Strathmore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Strathmore, California"
    },
    {
      "city": "Terra Bella",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Terra Bella, California"
    },
    {
      "city": "Three Rivers",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Three Rivers, California"
    },
    {
      "city": "Tipton",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tipton, California"
    },
    {
      "city": "Tulare",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tulare, California"
    },
    {
      "city": "Visalia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Visalia, California"
    },
    {
      "city": "Waukena",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Waukena, California"
    },
    {
      "city": "Woodlake",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woodlake, California"
    },
    {
      "city": "Badger",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Badger, California"
    },
    {
      "city": "Cutler",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Cutler, California"
    },
    {
      "city": "Dinuba",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dinuba, California"
    },
    {
      "city": "Kings Canyon National Pk",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Kings Canyon National Pk, California"
    },
    {
      "city": "Orosi",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Orosi, California"
    },
    {
      "city": "Sultana",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sultana, California"
    },
    {
      "city": "Yettem",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yettem, California"
    },
    {
      "city": "Traver",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Traver, California"
    },
    {
      "city": "Big Oak Flat",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Big Oak Flat, California"
    },
    {
      "city": "Chinese Camp",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Chinese Camp, California"
    },
    {
      "city": "Columbia",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Columbia, California"
    },
    {
      "city": "Groveland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Groveland, California"
    },
    {
      "city": "Jamestown",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Jamestown, California"
    },
    {
      "city": "Long Barn",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Long Barn, California"
    },
    {
      "city": "Mi Wuk Village",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Mi Wuk Village, California"
    },
    {
      "city": "Moccasin",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moccasin, California"
    },
    {
      "city": "Pinecrest",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Pinecrest, California"
    },
    {
      "city": "Sonora",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Sonora, California"
    },
    {
      "city": "Soulsbyville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Soulsbyville, California"
    },
    {
      "city": "Standard",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Standard, California"
    },
    {
      "city": "Strawberry",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Strawberry, California"
    },
    {
      "city": "Tuolumne",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Tuolumne, California"
    },
    {
      "city": "Twain Harte",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Twain Harte, California"
    },
    {
      "city": "Newbury Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Newbury Park, California"
    },
    {
      "city": "Thousand Oaks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Thousand Oaks, California"
    },
    {
      "city": "Westlake Village",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Westlake Village, California"
    },
    {
      "city": "Oak Park",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oak Park, California"
    },
    {
      "city": "Ventura",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ventura, California"
    },
    {
      "city": "Camarillo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camarillo, California"
    },
    {
      "city": "Fillmore",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Fillmore, California"
    },
    {
      "city": "Moorpark",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Moorpark, California"
    },
    {
      "city": "Oak View",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oak View, California"
    },
    {
      "city": "Ojai",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Ojai, California"
    },
    {
      "city": "Oxnard",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oxnard, California"
    },
    {
      "city": "Piru",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Piru, California"
    },
    {
      "city": "Port Hueneme",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Port Hueneme, California"
    },
    {
      "city": "Point Mugu Nawc",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Point Mugu Nawc, California"
    },
    {
      "city": "Port Hueneme Cbc Base",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Port Hueneme Cbc Base, California"
    },
    {
      "city": "Santa Paula",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Santa Paula, California"
    },
    {
      "city": "Simi Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Simi Valley, California"
    },
    {
      "city": "Brandeis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brandeis, California"
    },
    {
      "city": "Somis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Somis, California"
    },
    {
      "city": "West Sacramento",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "West Sacramento, California"
    },
    {
      "city": "Brooks",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brooks, California"
    },
    {
      "city": "Capay",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Capay, California"
    },
    {
      "city": "Clarksburg",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Clarksburg, California"
    },
    {
      "city": "Davis",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Davis, California"
    },
    {
      "city": "Esparto",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Esparto, California"
    },
    {
      "city": "Guinda",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Guinda, California"
    },
    {
      "city": "Knights Landing",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Knights Landing, California"
    },
    {
      "city": "Madison",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Madison, California"
    },
    {
      "city": "Rumsey",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rumsey, California"
    },
    {
      "city": "Winters",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Winters, California"
    },
    {
      "city": "Woodland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Woodland, California"
    },
    {
      "city": "Yolo",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Yolo, California"
    },
    {
      "city": "Zamora",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Zamora, California"
    },
    {
      "city": "Dunnigan",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dunnigan, California"
    },
    {
      "city": "Wheatland",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Wheatland, California"
    },
    {
      "city": "Marysville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Marysville, California"
    },
    {
      "city": "Beale Afb",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Beale Afb, California"
    },
    {
      "city": "Browns Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Browns Valley, California"
    },
    {
      "city": "Brownsville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Brownsville, California"
    },
    {
      "city": "Camptonville",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Camptonville, California"
    },
    {
      "city": "Challenge",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Challenge, California"
    },
    {
      "city": "Dobbins",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Dobbins, California"
    },
    {
      "city": "Olivehurst",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Olivehurst, California"
    },
    {
      "city": "Oregon House",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Oregon House, California"
    },
    {
      "city": "Rackerby",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Rackerby, California"
    },
    {
      "city": "Strawberry Valley",
      "state": "California",
      "shortState": "CA",
      "formatedCity": "Strawberry Valley, California"
    },
    {
      "city": "Aurora",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Aurora, Colorado"
    },
    {
      "city": "Commerce City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Commerce City, Colorado"
    },
    {
      "city": "Dupont",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dupont, Colorado"
    },
    {
      "city": "Westminster",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Westminster, Colorado"
    },
    {
      "city": "Bennett",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bennett, Colorado"
    },
    {
      "city": "Strasburg",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Strasburg, Colorado"
    },
    {
      "city": "Watkins",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Watkins, Colorado"
    },
    {
      "city": "Denver",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Denver, Colorado"
    },
    {
      "city": "Thornton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Thornton, Colorado"
    },
    {
      "city": "Brighton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Brighton, Colorado"
    },
    {
      "city": "Eastlake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eastlake, Colorado"
    },
    {
      "city": "Henderson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Henderson, Colorado"
    },
    {
      "city": "Alamosa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Alamosa, Colorado"
    },
    {
      "city": "Hooper",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hooper, Colorado"
    },
    {
      "city": "Mosca",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mosca, Colorado"
    },
    {
      "city": "Byers",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Byers, Colorado"
    },
    {
      "city": "Deer Trail",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Deer Trail, Colorado"
    },
    {
      "city": "Englewood",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Englewood, Colorado"
    },
    {
      "city": "Littleton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Littleton, Colorado"
    },
    {
      "city": "Arboles",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Arboles, Colorado"
    },
    {
      "city": "Chromo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Chromo, Colorado"
    },
    {
      "city": "Pagosa Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pagosa Springs, Colorado"
    },
    {
      "city": "Campo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Campo, Colorado"
    },
    {
      "city": "Pritchett",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pritchett, Colorado"
    },
    {
      "city": "Springfield",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Springfield, Colorado"
    },
    {
      "city": "Two Buttes",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Two Buttes, Colorado"
    },
    {
      "city": "Vilas",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Vilas, Colorado"
    },
    {
      "city": "Walsh",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Walsh, Colorado"
    },
    {
      "city": "Fort Lyon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fort Lyon, Colorado"
    },
    {
      "city": "Hasty",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hasty, Colorado"
    },
    {
      "city": "Las Animas",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Las Animas, Colorado"
    },
    {
      "city": "Mc Clave",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mc Clave, Colorado"
    },
    {
      "city": "Broomfield",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Broomfield, Colorado"
    },
    {
      "city": "Eldorado Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eldorado Springs, Colorado"
    },
    {
      "city": "Lafayette",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lafayette, Colorado"
    },
    {
      "city": "Louisville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Louisville, Colorado"
    },
    {
      "city": "Boulder",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Boulder, Colorado"
    },
    {
      "city": "Jamestown",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Jamestown, Colorado"
    },
    {
      "city": "Nederland",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Nederland, Colorado"
    },
    {
      "city": "Pinecliffe",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pinecliffe, Colorado"
    },
    {
      "city": "Ward",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ward, Colorado"
    },
    {
      "city": "Longmont",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Longmont, Colorado"
    },
    {
      "city": "Allenspark",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Allenspark, Colorado"
    },
    {
      "city": "Hygiene",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hygiene, Colorado"
    },
    {
      "city": "Lyons",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lyons, Colorado"
    },
    {
      "city": "Niwot",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Niwot, Colorado"
    },
    {
      "city": "Salida",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Salida, Colorado"
    },
    {
      "city": "Buena Vista",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Buena Vista, Colorado"
    },
    {
      "city": "Monarch",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Monarch, Colorado"
    },
    {
      "city": "Granite",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Granite, Colorado"
    },
    {
      "city": "Nathrop",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Nathrop, Colorado"
    },
    {
      "city": "Poncha Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Poncha Springs, Colorado"
    },
    {
      "city": "Arapahoe",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Arapahoe, Colorado"
    },
    {
      "city": "Cheyenne Wells",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cheyenne Wells, Colorado"
    },
    {
      "city": "Kit Carson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kit Carson, Colorado"
    },
    {
      "city": "Wild Horse",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wild Horse, Colorado"
    },
    {
      "city": "Dumont",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dumont, Colorado"
    },
    {
      "city": "Empire",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Empire, Colorado"
    },
    {
      "city": "Georgetown",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Georgetown, Colorado"
    },
    {
      "city": "Idaho Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Idaho Springs, Colorado"
    },
    {
      "city": "Silver Plume",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Silver Plume, Colorado"
    },
    {
      "city": "Antonito",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Antonito, Colorado"
    },
    {
      "city": "Capulin",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Capulin, Colorado"
    },
    {
      "city": "Conejos",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Conejos, Colorado"
    },
    {
      "city": "La Jara",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "La Jara, Colorado"
    },
    {
      "city": "Manassa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Manassa, Colorado"
    },
    {
      "city": "Romeo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Romeo, Colorado"
    },
    {
      "city": "Sanford",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sanford, Colorado"
    },
    {
      "city": "Blanca",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Blanca, Colorado"
    },
    {
      "city": "Chama",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Chama, Colorado"
    },
    {
      "city": "Fort Garland",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fort Garland, Colorado"
    },
    {
      "city": "Jaroso",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Jaroso, Colorado"
    },
    {
      "city": "San Luis",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "San Luis, Colorado"
    },
    {
      "city": "Crowley",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Crowley, Colorado"
    },
    {
      "city": "Olney Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Olney Springs, Colorado"
    },
    {
      "city": "Ordway",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ordway, Colorado"
    },
    {
      "city": "Sugar City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sugar City, Colorado"
    },
    {
      "city": "Westcliffe",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Westcliffe, Colorado"
    },
    {
      "city": "Wetmore",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wetmore, Colorado"
    },
    {
      "city": "Austin",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Austin, Colorado"
    },
    {
      "city": "Cedaredge",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cedaredge, Colorado"
    },
    {
      "city": "Cory",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cory, Colorado"
    },
    {
      "city": "Crawford",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Crawford, Colorado"
    },
    {
      "city": "Delta",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Delta, Colorado"
    },
    {
      "city": "Eckert",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eckert, Colorado"
    },
    {
      "city": "Hotchkiss",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hotchkiss, Colorado"
    },
    {
      "city": "Lazear",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lazear, Colorado"
    },
    {
      "city": "Paonia",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Paonia, Colorado"
    },
    {
      "city": "Cahone",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cahone, Colorado"
    },
    {
      "city": "Dove Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dove Creek, Colorado"
    },
    {
      "city": "Rico",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rico, Colorado"
    },
    {
      "city": "Castle Rock",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Castle Rock, Colorado"
    },
    {
      "city": "Franktown",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Franktown, Colorado"
    },
    {
      "city": "Larkspur",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Larkspur, Colorado"
    },
    {
      "city": "Lone Tree",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lone Tree, Colorado"
    },
    {
      "city": "Louviers",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Louviers, Colorado"
    },
    {
      "city": "Parker",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Parker, Colorado"
    },
    {
      "city": "Sedalia",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sedalia, Colorado"
    },
    {
      "city": "Bond",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bond, Colorado"
    },
    {
      "city": "Burns",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Burns, Colorado"
    },
    {
      "city": "Mc Coy",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mc Coy, Colorado"
    },
    {
      "city": "Avon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Avon, Colorado"
    },
    {
      "city": "Basalt",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Basalt, Colorado"
    },
    {
      "city": "Eagle",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eagle, Colorado"
    },
    {
      "city": "Edwards",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Edwards, Colorado"
    },
    {
      "city": "Gypsum",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gypsum, Colorado"
    },
    {
      "city": "Minturn",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Minturn, Colorado"
    },
    {
      "city": "Red Cliff",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Red Cliff, Colorado"
    },
    {
      "city": "Wolcott",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wolcott, Colorado"
    },
    {
      "city": "Vail",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Vail, Colorado"
    },
    {
      "city": "Agate",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Agate, Colorado"
    },
    {
      "city": "Elbert",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Elbert, Colorado"
    },
    {
      "city": "Elizabeth",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Elizabeth, Colorado"
    },
    {
      "city": "Kiowa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kiowa, Colorado"
    },
    {
      "city": "Matheson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Matheson, Colorado"
    },
    {
      "city": "Simla",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Simla, Colorado"
    },
    {
      "city": "Monument",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Monument, Colorado"
    },
    {
      "city": "Palmer Lake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Palmer Lake, Colorado"
    },
    {
      "city": "Calhan",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Calhan, Colorado"
    },
    {
      "city": "Cascade",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cascade, Colorado"
    },
    {
      "city": "Fountain",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fountain, Colorado"
    },
    {
      "city": "Green Mountain Falls",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Green Mountain Falls, Colorado"
    },
    {
      "city": "Manitou Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Manitou Springs, Colorado"
    },
    {
      "city": "Peyton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Peyton, Colorado"
    },
    {
      "city": "Ramah",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ramah, Colorado"
    },
    {
      "city": "Rush",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rush, Colorado"
    },
    {
      "city": "Usaf Academy",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Usaf Academy, Colorado"
    },
    {
      "city": "Yoder",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Yoder, Colorado"
    },
    {
      "city": "Colorado Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Colorado Springs, Colorado"
    },
    {
      "city": "Canon City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Canon City, Colorado"
    },
    {
      "city": "Coal Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Coal Creek, Colorado"
    },
    {
      "city": "Coaldale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Coaldale, Colorado"
    },
    {
      "city": "Cotopaxi",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cotopaxi, Colorado"
    },
    {
      "city": "Florence",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Florence, Colorado"
    },
    {
      "city": "Hillside",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hillside, Colorado"
    },
    {
      "city": "Howard",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Howard, Colorado"
    },
    {
      "city": "Penrose",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Penrose, Colorado"
    },
    {
      "city": "Rockvale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rockvale, Colorado"
    },
    {
      "city": "Glenwood Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Glenwood Springs, Colorado"
    },
    {
      "city": "Carbondale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Carbondale, Colorado"
    },
    {
      "city": "Parachute",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Parachute, Colorado"
    },
    {
      "city": "Battlement Mesa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Battlement Mesa, Colorado"
    },
    {
      "city": "New Castle",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "New Castle, Colorado"
    },
    {
      "city": "Rifle",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rifle, Colorado"
    },
    {
      "city": "Silt",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Silt, Colorado"
    },
    {
      "city": "Black Hawk",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Black Hawk, Colorado"
    },
    {
      "city": "Central City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Central City, Colorado"
    },
    {
      "city": "Rollinsville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rollinsville, Colorado"
    },
    {
      "city": "Fraser",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fraser, Colorado"
    },
    {
      "city": "Granby",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Granby, Colorado"
    },
    {
      "city": "Grand Lake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Grand Lake, Colorado"
    },
    {
      "city": "Hot Sulphur Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hot Sulphur Springs, Colorado"
    },
    {
      "city": "Kremmling",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kremmling, Colorado"
    },
    {
      "city": "Parshall",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Parshall, Colorado"
    },
    {
      "city": "Tabernash",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Tabernash, Colorado"
    },
    {
      "city": "Winter Park",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Winter Park, Colorado"
    },
    {
      "city": "Almont",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Almont, Colorado"
    },
    {
      "city": "Crested Butte",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Crested Butte, Colorado"
    },
    {
      "city": "Gunnison",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gunnison, Colorado"
    },
    {
      "city": "Ohio City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ohio City, Colorado"
    },
    {
      "city": "Parlin",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Parlin, Colorado"
    },
    {
      "city": "Pitkin",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pitkin, Colorado"
    },
    {
      "city": "Powderhorn",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Powderhorn, Colorado"
    },
    {
      "city": "Somerset",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Somerset, Colorado"
    },
    {
      "city": "Lake City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lake City, Colorado"
    },
    {
      "city": "Gardner",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gardner, Colorado"
    },
    {
      "city": "La Veta",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "La Veta, Colorado"
    },
    {
      "city": "Walsenburg",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Walsenburg, Colorado"
    },
    {
      "city": "Coalmont",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Coalmont, Colorado"
    },
    {
      "city": "Cowdrey",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cowdrey, Colorado"
    },
    {
      "city": "Rand",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rand, Colorado"
    },
    {
      "city": "Walden",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Walden, Colorado"
    },
    {
      "city": "Arvada",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Arvada, Colorado"
    },
    {
      "city": "Wheat Ridge",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wheat Ridge, Colorado"
    },
    {
      "city": "Golden",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Golden, Colorado"
    },
    {
      "city": "Buffalo Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Buffalo Creek, Colorado"
    },
    {
      "city": "Conifer",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Conifer, Colorado"
    },
    {
      "city": "Evergreen",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Evergreen, Colorado"
    },
    {
      "city": "Idledale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Idledale, Colorado"
    },
    {
      "city": "Indian Hills",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Indian Hills, Colorado"
    },
    {
      "city": "Kittredge",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kittredge, Colorado"
    },
    {
      "city": "Morrison",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Morrison, Colorado"
    },
    {
      "city": "Pine",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pine, Colorado"
    },
    {
      "city": "Arlington",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Arlington, Colorado"
    },
    {
      "city": "Eads",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eads, Colorado"
    },
    {
      "city": "Haswell",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Haswell, Colorado"
    },
    {
      "city": "Sheridan Lake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sheridan Lake, Colorado"
    },
    {
      "city": "Bethune",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bethune, Colorado"
    },
    {
      "city": "Burlington",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Burlington, Colorado"
    },
    {
      "city": "Flagler",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Flagler, Colorado"
    },
    {
      "city": "Seibert",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Seibert, Colorado"
    },
    {
      "city": "Stratton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Stratton, Colorado"
    },
    {
      "city": "Vona",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Vona, Colorado"
    },
    {
      "city": "Climax",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Climax, Colorado"
    },
    {
      "city": "Leadville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Leadville, Colorado"
    },
    {
      "city": "Twin Lakes",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Twin Lakes, Colorado"
    },
    {
      "city": "Bayfield",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bayfield, Colorado"
    },
    {
      "city": "Ignacio",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ignacio, Colorado"
    },
    {
      "city": "Durango",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Durango, Colorado"
    },
    {
      "city": "Hesperus",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hesperus, Colorado"
    },
    {
      "city": "Marvel",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Marvel, Colorado"
    },
    {
      "city": "Estes Park",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Estes Park, Colorado"
    },
    {
      "city": "Bellvue",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bellvue, Colorado"
    },
    {
      "city": "Berthoud",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Berthoud, Colorado"
    },
    {
      "city": "Drake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Drake, Colorado"
    },
    {
      "city": "Fort Collins",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fort Collins, Colorado"
    },
    {
      "city": "Glen Haven",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Glen Haven, Colorado"
    },
    {
      "city": "Laporte",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Laporte, Colorado"
    },
    {
      "city": "Livermore",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Livermore, Colorado"
    },
    {
      "city": "Loveland",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Loveland, Colorado"
    },
    {
      "city": "Masonville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Masonville, Colorado"
    },
    {
      "city": "Red Feather Lakes",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Red Feather Lakes, Colorado"
    },
    {
      "city": "Timnath",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Timnath, Colorado"
    },
    {
      "city": "Wellington",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wellington, Colorado"
    },
    {
      "city": "Aguilar",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Aguilar, Colorado"
    },
    {
      "city": "Boncarbo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Boncarbo, Colorado"
    },
    {
      "city": "Branson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Branson, Colorado"
    },
    {
      "city": "Hoehne",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hoehne, Colorado"
    },
    {
      "city": "Kim",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kim, Colorado"
    },
    {
      "city": "Model",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Model, Colorado"
    },
    {
      "city": "Trinchera",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Trinchera, Colorado"
    },
    {
      "city": "Trinidad",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Trinidad, Colorado"
    },
    {
      "city": "Weston",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Weston, Colorado"
    },
    {
      "city": "Arriba",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Arriba, Colorado"
    },
    {
      "city": "Genoa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Genoa, Colorado"
    },
    {
      "city": "Hugo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hugo, Colorado"
    },
    {
      "city": "Karval",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Karval, Colorado"
    },
    {
      "city": "Limon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Limon, Colorado"
    },
    {
      "city": "Atwood",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Atwood, Colorado"
    },
    {
      "city": "Crook",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Crook, Colorado"
    },
    {
      "city": "Fleming",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fleming, Colorado"
    },
    {
      "city": "Iliff",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Iliff, Colorado"
    },
    {
      "city": "Merino",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Merino, Colorado"
    },
    {
      "city": "Padroni",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Padroni, Colorado"
    },
    {
      "city": "Peetz",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Peetz, Colorado"
    },
    {
      "city": "Sterling",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sterling, Colorado"
    },
    {
      "city": "Grand Junction",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Grand Junction, Colorado"
    },
    {
      "city": "Clifton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Clifton, Colorado"
    },
    {
      "city": "Fruita",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fruita, Colorado"
    },
    {
      "city": "Gateway",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gateway, Colorado"
    },
    {
      "city": "Glade Park",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Glade Park, Colorado"
    },
    {
      "city": "Loma",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Loma, Colorado"
    },
    {
      "city": "Mack",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mack, Colorado"
    },
    {
      "city": "Palisade",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Palisade, Colorado"
    },
    {
      "city": "Whitewater",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Whitewater, Colorado"
    },
    {
      "city": "Collbran",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Collbran, Colorado"
    },
    {
      "city": "De Beque",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "De Beque, Colorado"
    },
    {
      "city": "Mesa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mesa, Colorado"
    },
    {
      "city": "Molina",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Molina, Colorado"
    },
    {
      "city": "Creede",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Creede, Colorado"
    },
    {
      "city": "Dinosaur",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dinosaur, Colorado"
    },
    {
      "city": "Craig",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Craig, Colorado"
    },
    {
      "city": "Hamilton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hamilton, Colorado"
    },
    {
      "city": "Maybell",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Maybell, Colorado"
    },
    {
      "city": "Slater",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Slater, Colorado"
    },
    {
      "city": "Cortez",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cortez, Colorado"
    },
    {
      "city": "Dolores",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dolores, Colorado"
    },
    {
      "city": "Lewis",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lewis, Colorado"
    },
    {
      "city": "Mancos",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mancos, Colorado"
    },
    {
      "city": "Mesa Verde National Park",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mesa Verde National Park, Colorado"
    },
    {
      "city": "Pleasant View",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pleasant View, Colorado"
    },
    {
      "city": "Towaoc",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Towaoc, Colorado"
    },
    {
      "city": "Yellow Jacket",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Yellow Jacket, Colorado"
    },
    {
      "city": "Cimarron",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cimarron, Colorado"
    },
    {
      "city": "Montrose",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Montrose, Colorado"
    },
    {
      "city": "Bedrock",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bedrock, Colorado"
    },
    {
      "city": "Naturita",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Naturita, Colorado"
    },
    {
      "city": "Nucla",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Nucla, Colorado"
    },
    {
      "city": "Olathe",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Olathe, Colorado"
    },
    {
      "city": "Paradox",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Paradox, Colorado"
    },
    {
      "city": "Redvale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Redvale, Colorado"
    },
    {
      "city": "Orchard",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Orchard, Colorado"
    },
    {
      "city": "Weldona",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Weldona, Colorado"
    },
    {
      "city": "Wiggins",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wiggins, Colorado"
    },
    {
      "city": "Fort Morgan",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fort Morgan, Colorado"
    },
    {
      "city": "Log Lane Village",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Log Lane Village, Colorado"
    },
    {
      "city": "Brush",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Brush, Colorado"
    },
    {
      "city": "Hillrose",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hillrose, Colorado"
    },
    {
      "city": "Snyder",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Snyder, Colorado"
    },
    {
      "city": "Cheraw",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cheraw, Colorado"
    },
    {
      "city": "Fowler",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fowler, Colorado"
    },
    {
      "city": "La Junta",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "La Junta, Colorado"
    },
    {
      "city": "Manzanola",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Manzanola, Colorado"
    },
    {
      "city": "Rocky Ford",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rocky Ford, Colorado"
    },
    {
      "city": "Swink",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Swink, Colorado"
    },
    {
      "city": "Ouray",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ouray, Colorado"
    },
    {
      "city": "Ridgway",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ridgway, Colorado"
    },
    {
      "city": "Alma",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Alma, Colorado"
    },
    {
      "city": "Bailey",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Bailey, Colorado"
    },
    {
      "city": "Como",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Como, Colorado"
    },
    {
      "city": "Fairplay",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fairplay, Colorado"
    },
    {
      "city": "Grant",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Grant, Colorado"
    },
    {
      "city": "Hartsel",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hartsel, Colorado"
    },
    {
      "city": "Jefferson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Jefferson, Colorado"
    },
    {
      "city": "Shawnee",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Shawnee, Colorado"
    },
    {
      "city": "Guffey",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Guffey, Colorado"
    },
    {
      "city": "Lake George",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lake George, Colorado"
    },
    {
      "city": "Amherst",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Amherst, Colorado"
    },
    {
      "city": "Haxtun",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Haxtun, Colorado"
    },
    {
      "city": "Holyoke",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Holyoke, Colorado"
    },
    {
      "city": "Paoli",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Paoli, Colorado"
    },
    {
      "city": "Aspen",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Aspen, Colorado"
    },
    {
      "city": "Snowmass Village",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Snowmass Village, Colorado"
    },
    {
      "city": "Meredith",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Meredith, Colorado"
    },
    {
      "city": "Snowmass",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Snowmass, Colorado"
    },
    {
      "city": "Woody Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Woody Creek, Colorado"
    },
    {
      "city": "Granada",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Granada, Colorado"
    },
    {
      "city": "Hartman",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hartman, Colorado"
    },
    {
      "city": "Holly",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Holly, Colorado"
    },
    {
      "city": "Lamar",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lamar, Colorado"
    },
    {
      "city": "Wiley",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wiley, Colorado"
    },
    {
      "city": "Pueblo",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pueblo, Colorado"
    },
    {
      "city": "Colorado City",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Colorado City, Colorado"
    },
    {
      "city": "Avondale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Avondale, Colorado"
    },
    {
      "city": "Beulah",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Beulah, Colorado"
    },
    {
      "city": "Boone",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Boone, Colorado"
    },
    {
      "city": "Rye",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rye, Colorado"
    },
    {
      "city": "Meeker",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Meeker, Colorado"
    },
    {
      "city": "Rangely",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Rangely, Colorado"
    },
    {
      "city": "Del Norte",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Del Norte, Colorado"
    },
    {
      "city": "Homelake",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Homelake, Colorado"
    },
    {
      "city": "Monte Vista",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Monte Vista, Colorado"
    },
    {
      "city": "South Fork",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "South Fork, Colorado"
    },
    {
      "city": "Clark",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Clark, Colorado"
    },
    {
      "city": "Oak Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Oak Creek, Colorado"
    },
    {
      "city": "Phippsburg",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Phippsburg, Colorado"
    },
    {
      "city": "Steamboat Springs",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Steamboat Springs, Colorado"
    },
    {
      "city": "Toponas",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Toponas, Colorado"
    },
    {
      "city": "Yampa",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Yampa, Colorado"
    },
    {
      "city": "Hayden",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hayden, Colorado"
    },
    {
      "city": "Center",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Center, Colorado"
    },
    {
      "city": "Crestone",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Crestone, Colorado"
    },
    {
      "city": "Moffat",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Moffat, Colorado"
    },
    {
      "city": "Saguache",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Saguache, Colorado"
    },
    {
      "city": "Villa Grove",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Villa Grove, Colorado"
    },
    {
      "city": "Sargents",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sargents, Colorado"
    },
    {
      "city": "Silverton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Silverton, Colorado"
    },
    {
      "city": "Egnar",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Egnar, Colorado"
    },
    {
      "city": "Norwood",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Norwood, Colorado"
    },
    {
      "city": "Ophir",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ophir, Colorado"
    },
    {
      "city": "Placerville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Placerville, Colorado"
    },
    {
      "city": "Telluride",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Telluride, Colorado"
    },
    {
      "city": "Julesburg",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Julesburg, Colorado"
    },
    {
      "city": "Ovid",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ovid, Colorado"
    },
    {
      "city": "Sedgwick",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Sedgwick, Colorado"
    },
    {
      "city": "Breckenridge",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Breckenridge, Colorado"
    },
    {
      "city": "Dillon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dillon, Colorado"
    },
    {
      "city": "Frisco",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Frisco, Colorado"
    },
    {
      "city": "Silverthorne",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Silverthorne, Colorado"
    },
    {
      "city": "Cripple Creek",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cripple Creek, Colorado"
    },
    {
      "city": "Divide",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Divide, Colorado"
    },
    {
      "city": "Florissant",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Florissant, Colorado"
    },
    {
      "city": "Victor",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Victor, Colorado"
    },
    {
      "city": "Woodland Park",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Woodland Park, Colorado"
    },
    {
      "city": "Akron",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Akron, Colorado"
    },
    {
      "city": "Lindon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lindon, Colorado"
    },
    {
      "city": "Otis",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Otis, Colorado"
    },
    {
      "city": "Woodrow",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Woodrow, Colorado"
    },
    {
      "city": "Anton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Anton, Colorado"
    },
    {
      "city": "Cope",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Cope, Colorado"
    },
    {
      "city": "Dacono",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Dacono, Colorado"
    },
    {
      "city": "Erie",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Erie, Colorado"
    },
    {
      "city": "Firestone",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Firestone, Colorado"
    },
    {
      "city": "Frederick",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Frederick, Colorado"
    },
    {
      "city": "Johnstown",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Johnstown, Colorado"
    },
    {
      "city": "Mead",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Mead, Colorado"
    },
    {
      "city": "Milliken",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Milliken, Colorado"
    },
    {
      "city": "Severance",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Severance, Colorado"
    },
    {
      "city": "Windsor",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Windsor, Colorado"
    },
    {
      "city": "Ault",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Ault, Colorado"
    },
    {
      "city": "Briggsdale",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Briggsdale, Colorado"
    },
    {
      "city": "Carr",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Carr, Colorado"
    },
    {
      "city": "Eaton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eaton, Colorado"
    },
    {
      "city": "Evans",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Evans, Colorado"
    },
    {
      "city": "Fort Lupton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Fort Lupton, Colorado"
    },
    {
      "city": "Galeton",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Galeton, Colorado"
    },
    {
      "city": "Gilcrest",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gilcrest, Colorado"
    },
    {
      "city": "Gill",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Gill, Colorado"
    },
    {
      "city": "Greeley",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Greeley, Colorado"
    },
    {
      "city": "Hudson",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hudson, Colorado"
    },
    {
      "city": "Keenesburg",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Keenesburg, Colorado"
    },
    {
      "city": "Kersey",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kersey, Colorado"
    },
    {
      "city": "La Salle",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "La Salle, Colorado"
    },
    {
      "city": "Lucerne",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Lucerne, Colorado"
    },
    {
      "city": "Nunn",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Nunn, Colorado"
    },
    {
      "city": "Pierce",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Pierce, Colorado"
    },
    {
      "city": "Platteville",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Platteville, Colorado"
    },
    {
      "city": "Roggen",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Roggen, Colorado"
    },
    {
      "city": "Grover",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Grover, Colorado"
    },
    {
      "city": "Hereford",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Hereford, Colorado"
    },
    {
      "city": "New Raymer",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "New Raymer, Colorado"
    },
    {
      "city": "Stoneham",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Stoneham, Colorado"
    },
    {
      "city": "Eckley",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Eckley, Colorado"
    },
    {
      "city": "Idalia",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Idalia, Colorado"
    },
    {
      "city": "Vernon",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Vernon, Colorado"
    },
    {
      "city": "Wray",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Wray, Colorado"
    },
    {
      "city": "Yuma",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Yuma, Colorado"
    },
    {
      "city": "Joes",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Joes, Colorado"
    },
    {
      "city": "Kirk",
      "state": "Colorado",
      "shortState": "CO",
      "formatedCity": "Kirk, Colorado"
    },
    {
      "city": "Botsford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Botsford, Connecticut"
    },
    {
      "city": "Hawleyville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hawleyville, Connecticut"
    },
    {
      "city": "Monroe",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Monroe, Connecticut"
    },
    {
      "city": "Newtown",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Newtown, Connecticut"
    },
    {
      "city": "Sandy Hook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Sandy Hook, Connecticut"
    },
    {
      "city": "Shelton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Shelton, Connecticut"
    },
    {
      "city": "Stevenson",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stevenson, Connecticut"
    },
    {
      "city": "Bridgeport",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bridgeport, Connecticut"
    },
    {
      "city": "Trumbull",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Trumbull, Connecticut"
    },
    {
      "city": "Easton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Easton, Connecticut"
    },
    {
      "city": "Stratford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stratford, Connecticut"
    },
    {
      "city": "Sherman",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Sherman, Connecticut"
    },
    {
      "city": "Bethel",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bethel, Connecticut"
    },
    {
      "city": "Brookfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Brookfield, Connecticut"
    },
    {
      "city": "Cos Cob",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cos Cob, Connecticut"
    },
    {
      "city": "Danbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Danbury, Connecticut"
    },
    {
      "city": "New Fairfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Fairfield, Connecticut"
    },
    {
      "city": "Darien",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Darien, Connecticut"
    },
    {
      "city": "Fairfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Fairfield, Connecticut"
    },
    {
      "city": "Georgetown",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Georgetown, Connecticut"
    },
    {
      "city": "Greenwich",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Greenwich, Connecticut"
    },
    {
      "city": "Greens Farms",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Greens Farms, Connecticut"
    },
    {
      "city": "New Canaan",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Canaan, Connecticut"
    },
    {
      "city": "Norwalk",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Norwalk, Connecticut"
    },
    {
      "city": "Old Greenwich",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Old Greenwich, Connecticut"
    },
    {
      "city": "Redding Center",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Redding Center, Connecticut"
    },
    {
      "city": "Redding Ridge",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Redding Ridge, Connecticut"
    },
    {
      "city": "Ridgefield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ridgefield, Connecticut"
    },
    {
      "city": "Riverside",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Riverside, Connecticut"
    },
    {
      "city": "Westport",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Westport, Connecticut"
    },
    {
      "city": "Weston",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Weston, Connecticut"
    },
    {
      "city": "Southport",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Southport, Connecticut"
    },
    {
      "city": "Redding",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Redding, Connecticut"
    },
    {
      "city": "Wilton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Wilton, Connecticut"
    },
    {
      "city": "Stamford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stamford, Connecticut"
    },
    {
      "city": "Avon",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Avon, Connecticut"
    },
    {
      "city": "Bloomfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bloomfield, Connecticut"
    },
    {
      "city": "Windsor",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Windsor, Connecticut"
    },
    {
      "city": "Bristol",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bristol, Connecticut"
    },
    {
      "city": "Burlington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Burlington, Connecticut"
    },
    {
      "city": "Broad Brook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Broad Brook, Connecticut"
    },
    {
      "city": "Canton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Canton, Connecticut"
    },
    {
      "city": "Canton Center",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Canton Center, Connecticut"
    },
    {
      "city": "Collinsville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Collinsville, Connecticut"
    },
    {
      "city": "East Berlin",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Berlin, Connecticut"
    },
    {
      "city": "East Glastonbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Glastonbury, Connecticut"
    },
    {
      "city": "East Granby",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Granby, Connecticut"
    },
    {
      "city": "East Hartland",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Hartland, Connecticut"
    },
    {
      "city": "East Windsor Hill",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Windsor Hill, Connecticut"
    },
    {
      "city": "Farmington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Farmington, Connecticut"
    },
    {
      "city": "Glastonbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Glastonbury, Connecticut"
    },
    {
      "city": "Granby",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Granby, Connecticut"
    },
    {
      "city": "Berlin",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Berlin, Connecticut"
    },
    {
      "city": "Manchester",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Manchester, Connecticut"
    },
    {
      "city": "New Britain",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Britain, Connecticut"
    },
    {
      "city": "North Canton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Canton, Connecticut"
    },
    {
      "city": "North Granby",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Granby, Connecticut"
    },
    {
      "city": "Plainville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Plainville, Connecticut"
    },
    {
      "city": "Poquonock",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Poquonock, Connecticut"
    },
    {
      "city": "Rocky Hill",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Rocky Hill, Connecticut"
    },
    {
      "city": "Simsbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Simsbury, Connecticut"
    },
    {
      "city": "South Glastonbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Glastonbury, Connecticut"
    },
    {
      "city": "South Windsor",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Windsor, Connecticut"
    },
    {
      "city": "Suffield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Suffield, Connecticut"
    },
    {
      "city": "Tariffville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Tariffville, Connecticut"
    },
    {
      "city": "Enfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Enfield, Connecticut"
    },
    {
      "city": "Unionville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Unionville, Connecticut"
    },
    {
      "city": "East Windsor",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Windsor, Connecticut"
    },
    {
      "city": "Weatogue",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Weatogue, Connecticut"
    },
    {
      "city": "West Granby",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Granby, Connecticut"
    },
    {
      "city": "West Hartland",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Hartland, Connecticut"
    },
    {
      "city": "West Simsbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Simsbury, Connecticut"
    },
    {
      "city": "West Suffield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Suffield, Connecticut"
    },
    {
      "city": "Windsor Locks",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Windsor Locks, Connecticut"
    },
    {
      "city": "Hartford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hartford, Connecticut"
    },
    {
      "city": "West Hartford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Hartford, Connecticut"
    },
    {
      "city": "East Hartford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Hartford, Connecticut"
    },
    {
      "city": "Wethersfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Wethersfield, Connecticut"
    },
    {
      "city": "Newington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Newington, Connecticut"
    },
    {
      "city": "Marion",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Marion, Connecticut"
    },
    {
      "city": "Marlborough",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Marlborough, Connecticut"
    },
    {
      "city": "Milldale",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Milldale, Connecticut"
    },
    {
      "city": "Plantsville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Plantsville, Connecticut"
    },
    {
      "city": "Southington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Southington, Connecticut"
    },
    {
      "city": "Canaan",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Canaan, Connecticut"
    },
    {
      "city": "Colebrook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Colebrook, Connecticut"
    },
    {
      "city": "East Canaan",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Canaan, Connecticut"
    },
    {
      "city": "Falls Village",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Falls Village, Connecticut"
    },
    {
      "city": "Lakeville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Lakeville, Connecticut"
    },
    {
      "city": "New Hartford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Hartford, Connecticut"
    },
    {
      "city": "Norfolk",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Norfolk, Connecticut"
    },
    {
      "city": "Pine Meadow",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Pine Meadow, Connecticut"
    },
    {
      "city": "Barkhamsted",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Barkhamsted, Connecticut"
    },
    {
      "city": "Riverton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Riverton, Connecticut"
    },
    {
      "city": "Salisbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Salisbury, Connecticut"
    },
    {
      "city": "Sharon",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Sharon, Connecticut"
    },
    {
      "city": "Taconic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Taconic, Connecticut"
    },
    {
      "city": "Winchester Center",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Winchester Center, Connecticut"
    },
    {
      "city": "Winsted",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Winsted, Connecticut"
    },
    {
      "city": "Bantam",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bantam, Connecticut"
    },
    {
      "city": "Bethlehem",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bethlehem, Connecticut"
    },
    {
      "city": "Bridgewater",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bridgewater, Connecticut"
    },
    {
      "city": "Cornwall",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cornwall, Connecticut"
    },
    {
      "city": "Cornwall Bridge",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cornwall Bridge, Connecticut"
    },
    {
      "city": "Gaylordsville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Gaylordsville, Connecticut"
    },
    {
      "city": "Goshen",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Goshen, Connecticut"
    },
    {
      "city": "Kent",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Kent, Connecticut"
    },
    {
      "city": "Lakeside",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Lakeside, Connecticut"
    },
    {
      "city": "Litchfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Litchfield, Connecticut"
    },
    {
      "city": "Morris",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Morris, Connecticut"
    },
    {
      "city": "New Milford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Milford, Connecticut"
    },
    {
      "city": "New Preston Marble Dale",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Preston Marble Dale, Connecticut"
    },
    {
      "city": "Northfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Northfield, Connecticut"
    },
    {
      "city": "Oakville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Oakville, Connecticut"
    },
    {
      "city": "Pequabuck",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Pequabuck, Connecticut"
    },
    {
      "city": "Plymouth",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Plymouth, Connecticut"
    },
    {
      "city": "Roxbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Roxbury, Connecticut"
    },
    {
      "city": "South Kent",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Kent, Connecticut"
    },
    {
      "city": "Terryville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Terryville, Connecticut"
    },
    {
      "city": "Thomaston",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Thomaston, Connecticut"
    },
    {
      "city": "Torrington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Torrington, Connecticut"
    },
    {
      "city": "Harwinton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Harwinton, Connecticut"
    },
    {
      "city": "Washington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Washington, Connecticut"
    },
    {
      "city": "Washington Depot",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Washington Depot, Connecticut"
    },
    {
      "city": "Watertown",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Watertown, Connecticut"
    },
    {
      "city": "West Cornwall",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Cornwall, Connecticut"
    },
    {
      "city": "Woodbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Woodbury, Connecticut"
    },
    {
      "city": "Centerbrook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Centerbrook, Connecticut"
    },
    {
      "city": "Chester",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Chester, Connecticut"
    },
    {
      "city": "Clinton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Clinton, Connecticut"
    },
    {
      "city": "Cobalt",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cobalt, Connecticut"
    },
    {
      "city": "Cromwell",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cromwell, Connecticut"
    },
    {
      "city": "Deep River",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Deep River, Connecticut"
    },
    {
      "city": "Killingworth",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Killingworth, Connecticut"
    },
    {
      "city": "Durham",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Durham, Connecticut"
    },
    {
      "city": "East Haddam",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Haddam, Connecticut"
    },
    {
      "city": "East Hampton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Hampton, Connecticut"
    },
    {
      "city": "Essex",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Essex, Connecticut"
    },
    {
      "city": "Haddam",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Haddam, Connecticut"
    },
    {
      "city": "Higganum",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Higganum, Connecticut"
    },
    {
      "city": "Ivoryton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ivoryton, Connecticut"
    },
    {
      "city": "Middlefield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Middlefield, Connecticut"
    },
    {
      "city": "Middle Haddam",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Middle Haddam, Connecticut"
    },
    {
      "city": "Middletown",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Middletown, Connecticut"
    },
    {
      "city": "Moodus",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Moodus, Connecticut"
    },
    {
      "city": "Old Saybrook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Old Saybrook, Connecticut"
    },
    {
      "city": "Portland",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Portland, Connecticut"
    },
    {
      "city": "Rockfall",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Rockfall, Connecticut"
    },
    {
      "city": "Westbrook",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Westbrook, Connecticut"
    },
    {
      "city": "Ansonia",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ansonia, Connecticut"
    },
    {
      "city": "Beacon Falls",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Beacon Falls, Connecticut"
    },
    {
      "city": "Branford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Branford, Connecticut"
    },
    {
      "city": "Cheshire",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Cheshire, Connecticut"
    },
    {
      "city": "Derby",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Derby, Connecticut"
    },
    {
      "city": "Guilford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Guilford, Connecticut"
    },
    {
      "city": "Madison",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Madison, Connecticut"
    },
    {
      "city": "Meriden",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Meriden, Connecticut"
    },
    {
      "city": "Milford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Milford, Connecticut"
    },
    {
      "city": "North Branford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Branford, Connecticut"
    },
    {
      "city": "Northford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Northford, Connecticut"
    },
    {
      "city": "North Haven",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Haven, Connecticut"
    },
    {
      "city": "Orange",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Orange, Connecticut"
    },
    {
      "city": "Oxford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Oxford, Connecticut"
    },
    {
      "city": "Seymour",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Seymour, Connecticut"
    },
    {
      "city": "South Britain",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Britain, Connecticut"
    },
    {
      "city": "Southbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Southbury, Connecticut"
    },
    {
      "city": "Wallingford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Wallingford, Connecticut"
    },
    {
      "city": "New Haven",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New Haven, Connecticut"
    },
    {
      "city": "Hamden",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hamden, Connecticut"
    },
    {
      "city": "West Haven",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Haven, Connecticut"
    },
    {
      "city": "Bethany",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bethany, Connecticut"
    },
    {
      "city": "Waterbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Waterbury, Connecticut"
    },
    {
      "city": "Prospect",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Prospect, Connecticut"
    },
    {
      "city": "Wolcott",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Wolcott, Connecticut"
    },
    {
      "city": "Middlebury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Middlebury, Connecticut"
    },
    {
      "city": "Naugatuck",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Naugatuck, Connecticut"
    },
    {
      "city": "Lebanon",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Lebanon, Connecticut"
    },
    {
      "city": "North Franklin",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Franklin, Connecticut"
    },
    {
      "city": "New London",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "New London, Connecticut"
    },
    {
      "city": "Baltic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Baltic, Connecticut"
    },
    {
      "city": "East Lyme",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Lyme, Connecticut"
    },
    {
      "city": "Bozrah",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bozrah, Connecticut"
    },
    {
      "city": "Gales Ferry",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Gales Ferry, Connecticut"
    },
    {
      "city": "Gilman",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Gilman, Connecticut"
    },
    {
      "city": "Mashantucket",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Mashantucket, Connecticut"
    },
    {
      "city": "Ledyard",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ledyard, Connecticut"
    },
    {
      "city": "Groton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Groton, Connecticut"
    },
    {
      "city": "Hanover",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hanover, Connecticut"
    },
    {
      "city": "Jewett City",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Jewett City, Connecticut"
    },
    {
      "city": "Montville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Montville, Connecticut"
    },
    {
      "city": "Mystic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Mystic, Connecticut"
    },
    {
      "city": "Niantic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Niantic, Connecticut"
    },
    {
      "city": "North Stonington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Stonington, Connecticut"
    },
    {
      "city": "Norwich",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Norwich, Connecticut"
    },
    {
      "city": "Preston",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Preston, Connecticut"
    },
    {
      "city": "Oakdale",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Oakdale, Connecticut"
    },
    {
      "city": "Old Lyme",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Old Lyme, Connecticut"
    },
    {
      "city": "Old Mystic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Old Mystic, Connecticut"
    },
    {
      "city": "Quaker Hill",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Quaker Hill, Connecticut"
    },
    {
      "city": "South Lyme",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Lyme, Connecticut"
    },
    {
      "city": "Stonington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stonington, Connecticut"
    },
    {
      "city": "Pawcatuck",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Pawcatuck, Connecticut"
    },
    {
      "city": "Taftville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Taftville, Connecticut"
    },
    {
      "city": "Uncasville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Uncasville, Connecticut"
    },
    {
      "city": "Versailles",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Versailles, Connecticut"
    },
    {
      "city": "Voluntown",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Voluntown, Connecticut"
    },
    {
      "city": "Waterford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Waterford, Connecticut"
    },
    {
      "city": "West Mystic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "West Mystic, Connecticut"
    },
    {
      "city": "Yantic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Yantic, Connecticut"
    },
    {
      "city": "Colchester",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Colchester, Connecticut"
    },
    {
      "city": "Salem",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Salem, Connecticut"
    },
    {
      "city": "Hadlyme",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hadlyme, Connecticut"
    },
    {
      "city": "North Westchester",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Westchester, Connecticut"
    },
    {
      "city": "Ellington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ellington, Connecticut"
    },
    {
      "city": "Bolton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Bolton, Connecticut"
    },
    {
      "city": "Vernon Rockville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Vernon Rockville, Connecticut"
    },
    {
      "city": "Somers",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Somers, Connecticut"
    },
    {
      "city": "Somersville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Somersville, Connecticut"
    },
    {
      "city": "Stafford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stafford, Connecticut"
    },
    {
      "city": "Stafford Springs",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Stafford Springs, Connecticut"
    },
    {
      "city": "Staffordville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Staffordville, Connecticut"
    },
    {
      "city": "Tolland",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Tolland, Connecticut"
    },
    {
      "city": "Amston",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Amston, Connecticut"
    },
    {
      "city": "Andover",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Andover, Connecticut"
    },
    {
      "city": "Columbia",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Columbia, Connecticut"
    },
    {
      "city": "Coventry",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Coventry, Connecticut"
    },
    {
      "city": "Hebron",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hebron, Connecticut"
    },
    {
      "city": "Mansfield Center",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Mansfield Center, Connecticut"
    },
    {
      "city": "Mansfield Depot",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Mansfield Depot, Connecticut"
    },
    {
      "city": "South Willington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Willington, Connecticut"
    },
    {
      "city": "Storrs Mansfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Storrs Mansfield, Connecticut"
    },
    {
      "city": "Willington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Willington, Connecticut"
    },
    {
      "city": "Willimantic",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Willimantic, Connecticut"
    },
    {
      "city": "Abington",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Abington, Connecticut"
    },
    {
      "city": "Ballouville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ballouville, Connecticut"
    },
    {
      "city": "Brooklyn",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Brooklyn, Connecticut"
    },
    {
      "city": "Chaplin",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Chaplin, Connecticut"
    },
    {
      "city": "Danielson",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Danielson, Connecticut"
    },
    {
      "city": "Dayville",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Dayville, Connecticut"
    },
    {
      "city": "Eastford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Eastford, Connecticut"
    },
    {
      "city": "East Killingly",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Killingly, Connecticut"
    },
    {
      "city": "East Woodstock",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "East Woodstock, Connecticut"
    },
    {
      "city": "Fabyan",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Fabyan, Connecticut"
    },
    {
      "city": "Grosvenor Dale",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Grosvenor Dale, Connecticut"
    },
    {
      "city": "Hampton",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Hampton, Connecticut"
    },
    {
      "city": "North Grosvenordale",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Grosvenordale, Connecticut"
    },
    {
      "city": "North Windham",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "North Windham, Connecticut"
    },
    {
      "city": "Pomfret",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Pomfret, Connecticut"
    },
    {
      "city": "Pomfret Center",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Pomfret Center, Connecticut"
    },
    {
      "city": "Putnam",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Putnam, Connecticut"
    },
    {
      "city": "Quinebaug",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Quinebaug, Connecticut"
    },
    {
      "city": "Rogers",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Rogers, Connecticut"
    },
    {
      "city": "Scotland",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Scotland, Connecticut"
    },
    {
      "city": "South Windham",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Windham, Connecticut"
    },
    {
      "city": "South Woodstock",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "South Woodstock, Connecticut"
    },
    {
      "city": "Thompson",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Thompson, Connecticut"
    },
    {
      "city": "Ashford",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Ashford, Connecticut"
    },
    {
      "city": "Windham",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Windham, Connecticut"
    },
    {
      "city": "Woodstock",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Woodstock, Connecticut"
    },
    {
      "city": "Woodstock Valley",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Woodstock Valley, Connecticut"
    },
    {
      "city": "Canterbury",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Canterbury, Connecticut"
    },
    {
      "city": "Central Village",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Central Village, Connecticut"
    },
    {
      "city": "Moosup",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Moosup, Connecticut"
    },
    {
      "city": "Oneco",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Oneco, Connecticut"
    },
    {
      "city": "Plainfield",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Plainfield, Connecticut"
    },
    {
      "city": "Sterling",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Sterling, Connecticut"
    },
    {
      "city": "Wauregan",
      "state": "Connecticut",
      "shortState": "CT",
      "formatedCity": "Wauregan, Connecticut"
    },
    {
      "city": "Washington",
      "state": "District of Columbia",
      "shortState": "DC",
      "formatedCity": "Washington, District of Columbia"
    },
    {
      "city": "Naval Anacost Annex",
      "state": "District of Columbia",
      "shortState": "DC",
      "formatedCity": "Naval Anacost Annex, District of Columbia"
    },
    {
      "city": "Washington Navy Yard",
      "state": "District of Columbia",
      "shortState": "DC",
      "formatedCity": "Washington Navy Yard, District of Columbia"
    },
    {
      "city": "Dover",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Dover, Delaware"
    },
    {
      "city": "Dover Afb",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Dover Afb, Delaware"
    },
    {
      "city": "Camden Wyoming",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Camden Wyoming, Delaware"
    },
    {
      "city": "Cheswold",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Cheswold, Delaware"
    },
    {
      "city": "Clayton",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Clayton, Delaware"
    },
    {
      "city": "Felton",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Felton, Delaware"
    },
    {
      "city": "Frederica",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Frederica, Delaware"
    },
    {
      "city": "Harrington",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Harrington, Delaware"
    },
    {
      "city": "Hartly",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Hartly, Delaware"
    },
    {
      "city": "Houston",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Houston, Delaware"
    },
    {
      "city": "Kenton",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Kenton, Delaware"
    },
    {
      "city": "Little Creek",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Little Creek, Delaware"
    },
    {
      "city": "Magnolia",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Magnolia, Delaware"
    },
    {
      "city": "Marydel",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Marydel, Delaware"
    },
    {
      "city": "Smyrna",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Smyrna, Delaware"
    },
    {
      "city": "Viola",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Viola, Delaware"
    },
    {
      "city": "Woodside",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Woodside, Delaware"
    },
    {
      "city": "Bear",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Bear, Delaware"
    },
    {
      "city": "Newark",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Newark, Delaware"
    },
    {
      "city": "Claymont",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Claymont, Delaware"
    },
    {
      "city": "Delaware City",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Delaware City, Delaware"
    },
    {
      "city": "Hockessin",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Hockessin, Delaware"
    },
    {
      "city": "Kirkwood",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Kirkwood, Delaware"
    },
    {
      "city": "Middletown",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Middletown, Delaware"
    },
    {
      "city": "Montchanin",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Montchanin, Delaware"
    },
    {
      "city": "New Castle",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "New Castle, Delaware"
    },
    {
      "city": "Odessa",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Odessa, Delaware"
    },
    {
      "city": "Port Penn",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Port Penn, Delaware"
    },
    {
      "city": "Rockland",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Rockland, Delaware"
    },
    {
      "city": "Saint Georges",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Saint Georges, Delaware"
    },
    {
      "city": "Townsend",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Townsend, Delaware"
    },
    {
      "city": "Winterthur",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Winterthur, Delaware"
    },
    {
      "city": "Yorklyn",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Yorklyn, Delaware"
    },
    {
      "city": "Wilmington",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Wilmington, Delaware"
    },
    {
      "city": "Bethany Beach",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Bethany Beach, Delaware"
    },
    {
      "city": "Bethel",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Bethel, Delaware"
    },
    {
      "city": "Bridgeville",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Bridgeville, Delaware"
    },
    {
      "city": "Dagsboro",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Dagsboro, Delaware"
    },
    {
      "city": "Delmar",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Delmar, Delaware"
    },
    {
      "city": "Ellendale",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Ellendale, Delaware"
    },
    {
      "city": "Fenwick Island",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Fenwick Island, Delaware"
    },
    {
      "city": "Frankford",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Frankford, Delaware"
    },
    {
      "city": "Georgetown",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Georgetown, Delaware"
    },
    {
      "city": "Greenwood",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Greenwood, Delaware"
    },
    {
      "city": "Harbeson",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Harbeson, Delaware"
    },
    {
      "city": "Laurel",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Laurel, Delaware"
    },
    {
      "city": "Lewes",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Lewes, Delaware"
    },
    {
      "city": "Lincoln",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Lincoln, Delaware"
    },
    {
      "city": "Milford",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Milford, Delaware"
    },
    {
      "city": "Millsboro",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Millsboro, Delaware"
    },
    {
      "city": "Millville",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Millville, Delaware"
    },
    {
      "city": "Milton",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Milton, Delaware"
    },
    {
      "city": "Nassau",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Nassau, Delaware"
    },
    {
      "city": "Ocean View",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Ocean View, Delaware"
    },
    {
      "city": "Rehoboth Beach",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Rehoboth Beach, Delaware"
    },
    {
      "city": "Seaford",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Seaford, Delaware"
    },
    {
      "city": "Selbyville",
      "state": "Delaware",
      "shortState": "DE",
      "formatedCity": "Selbyville, Delaware"
    },
    {
      "city": "Gainesville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gainesville, Florida"
    },
    {
      "city": "Alachua",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Alachua, Florida"
    },
    {
      "city": "Archer",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Archer, Florida"
    },
    {
      "city": "Earleton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Earleton, Florida"
    },
    {
      "city": "Evinston",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Evinston, Florida"
    },
    {
      "city": "Hawthorne",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hawthorne, Florida"
    },
    {
      "city": "High Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "High Springs, Florida"
    },
    {
      "city": "Island Grove",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Island Grove, Florida"
    },
    {
      "city": "La Crosse",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "La Crosse, Florida"
    },
    {
      "city": "Lochloosa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lochloosa, Florida"
    },
    {
      "city": "Micanopy",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Micanopy, Florida"
    },
    {
      "city": "Newberry",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Newberry, Florida"
    },
    {
      "city": "Waldo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Waldo, Florida"
    },
    {
      "city": "Glen Saint Mary",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Glen Saint Mary, Florida"
    },
    {
      "city": "Macclenny",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Macclenny, Florida"
    },
    {
      "city": "Olustee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Olustee, Florida"
    },
    {
      "city": "Sanderson",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sanderson, Florida"
    },
    {
      "city": "Panama City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Panama City, Florida"
    },
    {
      "city": "Panama City Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Panama City Beach, Florida"
    },
    {
      "city": "Mexico Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mexico Beach, Florida"
    },
    {
      "city": "Fountain",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fountain, Florida"
    },
    {
      "city": "Lynn Haven",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lynn Haven, Florida"
    },
    {
      "city": "Youngstown",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Youngstown, Florida"
    },
    {
      "city": "Graham",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Graham, Florida"
    },
    {
      "city": "Hampton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hampton, Florida"
    },
    {
      "city": "Lawtey",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lawtey, Florida"
    },
    {
      "city": "Starke",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Starke, Florida"
    },
    {
      "city": "Brooker",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Brooker, Florida"
    },
    {
      "city": "Mims",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mims, Florida"
    },
    {
      "city": "Scottsmoor",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Scottsmoor, Florida"
    },
    {
      "city": "Titusville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Titusville, Florida"
    },
    {
      "city": "Orlando",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Orlando, Florida"
    },
    {
      "city": "Melbourne",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Melbourne, Florida"
    },
    {
      "city": "Indialantic",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Indialantic, Florida"
    },
    {
      "city": "Palm Bay",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm Bay, Florida"
    },
    {
      "city": "Cape Canaveral",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cape Canaveral, Florida"
    },
    {
      "city": "Cocoa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cocoa, Florida"
    },
    {
      "city": "Patrick Afb",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Patrick Afb, Florida"
    },
    {
      "city": "Cocoa Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cocoa Beach, Florida"
    },
    {
      "city": "Satellite Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Satellite Beach, Florida"
    },
    {
      "city": "Grant",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Grant, Florida"
    },
    {
      "city": "Malabar",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Malabar, Florida"
    },
    {
      "city": "Melbourne Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Melbourne Beach, Florida"
    },
    {
      "city": "Merritt Island",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Merritt Island, Florida"
    },
    {
      "city": "Rockledge",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Rockledge, Florida"
    },
    {
      "city": "Sharpes",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sharpes, Florida"
    },
    {
      "city": "Sebastian",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sebastian, Florida"
    },
    {
      "city": "Dania",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dania, Florida"
    },
    {
      "city": "Hallandale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hallandale, Florida"
    },
    {
      "city": "Hollywood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hollywood, Florida"
    },
    {
      "city": "Pembroke Pines",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pembroke Pines, Florida"
    },
    {
      "city": "Pompano Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pompano Beach, Florida"
    },
    {
      "city": "Coral Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Coral Springs, Florida"
    },
    {
      "city": "Margate",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Margate, Florida"
    },
    {
      "city": "Coconut Creek",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Coconut Creek, Florida"
    },
    {
      "city": "Fort Lauderdale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Lauderdale, Florida"
    },
    {
      "city": "Plantation",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Plantation, Florida"
    },
    {
      "city": "Deerfield Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Deerfield Beach, Florida"
    },
    {
      "city": "Lehigh Acres",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lehigh Acres, Florida"
    },
    {
      "city": "Altha",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Altha, Florida"
    },
    {
      "city": "Blountstown",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Blountstown, Florida"
    },
    {
      "city": "Clarksville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clarksville, Florida"
    },
    {
      "city": "Wewahitchka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wewahitchka, Florida"
    },
    {
      "city": "El Jobean",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "El Jobean, Florida"
    },
    {
      "city": "Murdock",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Murdock, Florida"
    },
    {
      "city": "Placida",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Placida, Florida"
    },
    {
      "city": "Rotonda West",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Rotonda West, Florida"
    },
    {
      "city": "Port Charlotte",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Charlotte, Florida"
    },
    {
      "city": "Punta Gorda",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Punta Gorda, Florida"
    },
    {
      "city": "Crystal River",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crystal River, Florida"
    },
    {
      "city": "Dunnellon",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dunnellon, Florida"
    },
    {
      "city": "Floral City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Floral City, Florida"
    },
    {
      "city": "Hernando",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hernando, Florida"
    },
    {
      "city": "Holder",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Holder, Florida"
    },
    {
      "city": "Homosassa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Homosassa, Florida"
    },
    {
      "city": "Homosassa Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Homosassa Springs, Florida"
    },
    {
      "city": "Inverness",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Inverness, Florida"
    },
    {
      "city": "Lecanto",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lecanto, Florida"
    },
    {
      "city": "Beverly Hills",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Beverly Hills, Florida"
    },
    {
      "city": "Fleming Island",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fleming Island, Florida"
    },
    {
      "city": "Doctors Inlet",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Doctors Inlet, Florida"
    },
    {
      "city": "Green Cove Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Green Cove Springs, Florida"
    },
    {
      "city": "Middleburg",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Middleburg, Florida"
    },
    {
      "city": "Orange Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Orange Park, Florida"
    },
    {
      "city": "Penney Farms",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Penney Farms, Florida"
    },
    {
      "city": "Lake Geneva",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Geneva, Florida"
    },
    {
      "city": "Keystone Heights",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Keystone Heights, Florida"
    },
    {
      "city": "Naples",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Naples, Florida"
    },
    {
      "city": "Copeland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Copeland, Florida"
    },
    {
      "city": "Chokoloskee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Chokoloskee, Florida"
    },
    {
      "city": "Everglades City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Everglades City, Florida"
    },
    {
      "city": "Goodland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Goodland, Florida"
    },
    {
      "city": "Ochopee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ochopee, Florida"
    },
    {
      "city": "Immokalee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Immokalee, Florida"
    },
    {
      "city": "Marco Island",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Marco Island, Florida"
    },
    {
      "city": "Lake City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake City, Florida"
    },
    {
      "city": "Fort White",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort White, Florida"
    },
    {
      "city": "Lulu",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lulu, Florida"
    },
    {
      "city": "Arcadia",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Arcadia, Florida"
    },
    {
      "city": "Fort Ogden",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Ogden, Florida"
    },
    {
      "city": "Nocatee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Nocatee, Florida"
    },
    {
      "city": "Cross City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cross City, Florida"
    },
    {
      "city": "Horseshoe Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Horseshoe Beach, Florida"
    },
    {
      "city": "Old Town",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Old Town, Florida"
    },
    {
      "city": "Suwannee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Suwannee, Florida"
    },
    {
      "city": "Jacksonville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jacksonville, Florida"
    },
    {
      "city": "Atlantic Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Atlantic Beach, Florida"
    },
    {
      "city": "Jacksonville Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jacksonville Beach, Florida"
    },
    {
      "city": "Neptune Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Neptune Beach, Florida"
    },
    {
      "city": "Pensacola",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pensacola, Florida"
    },
    {
      "city": "Cantonment",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cantonment, Florida"
    },
    {
      "city": "Century",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Century, Florida"
    },
    {
      "city": "Gonzalez",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gonzalez, Florida"
    },
    {
      "city": "Mc David",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mc David, Florida"
    },
    {
      "city": "Molino",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Molino, Florida"
    },
    {
      "city": "Bunnell",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bunnell, Florida"
    },
    {
      "city": "Palm Coast",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm Coast, Florida"
    },
    {
      "city": "Flagler Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Flagler Beach, Florida"
    },
    {
      "city": "Tallahassee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tallahassee, Florida"
    },
    {
      "city": "Apalachicola",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Apalachicola, Florida"
    },
    {
      "city": "Carrabelle",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Carrabelle, Florida"
    },
    {
      "city": "Lanark Village",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lanark Village, Florida"
    },
    {
      "city": "Eastpoint",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eastpoint, Florida"
    },
    {
      "city": "Chattahoochee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Chattahoochee, Florida"
    },
    {
      "city": "Greensboro",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Greensboro, Florida"
    },
    {
      "city": "Gretna",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gretna, Florida"
    },
    {
      "city": "Havana",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Havana, Florida"
    },
    {
      "city": "Midway",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Midway, Florida"
    },
    {
      "city": "Quincy",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Quincy, Florida"
    },
    {
      "city": "Bell",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bell, Florida"
    },
    {
      "city": "Trenton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Trenton, Florida"
    },
    {
      "city": "Moore Haven",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Moore Haven, Florida"
    },
    {
      "city": "Palmdale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palmdale, Florida"
    },
    {
      "city": "Port Saint Joe",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Saint Joe, Florida"
    },
    {
      "city": "Jasper",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jasper, Florida"
    },
    {
      "city": "Jennings",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jennings, Florida"
    },
    {
      "city": "White Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "White Springs, Florida"
    },
    {
      "city": "Bowling Green",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bowling Green, Florida"
    },
    {
      "city": "Ona",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ona, Florida"
    },
    {
      "city": "Wauchula",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wauchula, Florida"
    },
    {
      "city": "Zolfo Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Zolfo Springs, Florida"
    },
    {
      "city": "Clewiston",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clewiston, Florida"
    },
    {
      "city": "Felda",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Felda, Florida"
    },
    {
      "city": "Labelle",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Labelle, Florida"
    },
    {
      "city": "Brooksville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Brooksville, Florida"
    },
    {
      "city": "Spring Hill",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Spring Hill, Florida"
    },
    {
      "city": "Istachatta",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Istachatta, Florida"
    },
    {
      "city": "Nobleton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Nobleton, Florida"
    },
    {
      "city": "Avon Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Avon Park, Florida"
    },
    {
      "city": "Lake Placid",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Placid, Florida"
    },
    {
      "city": "Lorida",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lorida, Florida"
    },
    {
      "city": "Sebring",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sebring, Florida"
    },
    {
      "city": "Venus",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Venus, Florida"
    },
    {
      "city": "Balm",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Balm, Florida"
    },
    {
      "city": "Brandon",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Brandon, Florida"
    },
    {
      "city": "Dover",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dover, Florida"
    },
    {
      "city": "Durant",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Durant, Florida"
    },
    {
      "city": "Gibsonton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gibsonton, Florida"
    },
    {
      "city": "Lithia",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lithia, Florida"
    },
    {
      "city": "Lutz",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lutz, Florida"
    },
    {
      "city": "Mango",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mango, Florida"
    },
    {
      "city": "Odessa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Odessa, Florida"
    },
    {
      "city": "Plant City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Plant City, Florida"
    },
    {
      "city": "Riverview",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Riverview, Florida"
    },
    {
      "city": "Ruskin",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ruskin, Florida"
    },
    {
      "city": "Sun City Center",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sun City Center, Florida"
    },
    {
      "city": "Apollo Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Apollo Beach, Florida"
    },
    {
      "city": "Seffner",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Seffner, Florida"
    },
    {
      "city": "Sun City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sun City, Florida"
    },
    {
      "city": "Sydney",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sydney, Florida"
    },
    {
      "city": "Thonotosassa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Thonotosassa, Florida"
    },
    {
      "city": "Valrico",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Valrico, Florida"
    },
    {
      "city": "Wimauma",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wimauma, Florida"
    },
    {
      "city": "Tampa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tampa, Florida"
    },
    {
      "city": "Bonifay",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bonifay, Florida"
    },
    {
      "city": "Noma",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Noma, Florida"
    },
    {
      "city": "Ponce De Leon",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ponce De Leon, Florida"
    },
    {
      "city": "Westville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Westville, Florida"
    },
    {
      "city": "Fellsmere",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fellsmere, Florida"
    },
    {
      "city": "Roseland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Roseland, Florida"
    },
    {
      "city": "Vero Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Vero Beach, Florida"
    },
    {
      "city": "Wabasso",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wabasso, Florida"
    },
    {
      "city": "Winter Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Winter Beach, Florida"
    },
    {
      "city": "Alford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Alford, Florida"
    },
    {
      "city": "Bascom",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bascom, Florida"
    },
    {
      "city": "Campbellton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Campbellton, Florida"
    },
    {
      "city": "Cottondale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cottondale, Florida"
    },
    {
      "city": "Cypress",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cypress, Florida"
    },
    {
      "city": "Graceville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Graceville, Florida"
    },
    {
      "city": "Grand Ridge",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Grand Ridge, Florida"
    },
    {
      "city": "Greenwood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Greenwood, Florida"
    },
    {
      "city": "Malone",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Malone, Florida"
    },
    {
      "city": "Marianna",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Marianna, Florida"
    },
    {
      "city": "Sneads",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sneads, Florida"
    },
    {
      "city": "Lamont",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lamont, Florida"
    },
    {
      "city": "Lloyd",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lloyd, Florida"
    },
    {
      "city": "Monticello",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Monticello, Florida"
    },
    {
      "city": "Wacissa",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wacissa, Florida"
    },
    {
      "city": "Debary",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Debary, Florida"
    },
    {
      "city": "Day",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Day, Florida"
    },
    {
      "city": "Mayo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mayo, Florida"
    },
    {
      "city": "Astor",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Astor, Florida"
    },
    {
      "city": "Lady Lake",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lady Lake, Florida"
    },
    {
      "city": "Altoona",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Altoona, Florida"
    },
    {
      "city": "Eustis",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eustis, Florida"
    },
    {
      "city": "Grand Island",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Grand Island, Florida"
    },
    {
      "city": "Mount Dora",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mount Dora, Florida"
    },
    {
      "city": "Paisley",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Paisley, Florida"
    },
    {
      "city": "Sorrento",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sorrento, Florida"
    },
    {
      "city": "Tavares",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tavares, Florida"
    },
    {
      "city": "Umatilla",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Umatilla, Florida"
    },
    {
      "city": "Astatula",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Astatula, Florida"
    },
    {
      "city": "Clermont",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clermont, Florida"
    },
    {
      "city": "Ferndale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ferndale, Florida"
    },
    {
      "city": "Fruitland Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fruitland Park, Florida"
    },
    {
      "city": "Groveland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Groveland, Florida"
    },
    {
      "city": "Howey In The Hills",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Howey In The Hills, Florida"
    },
    {
      "city": "Leesburg",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Leesburg, Florida"
    },
    {
      "city": "Mascotte",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mascotte, Florida"
    },
    {
      "city": "Minneola",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Minneola, Florida"
    },
    {
      "city": "Montverde",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Montverde, Florida"
    },
    {
      "city": "Okahumpka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Okahumpka, Florida"
    },
    {
      "city": "Yalaha",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Yalaha, Florida"
    },
    {
      "city": "Fort Myers",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Myers, Florida"
    },
    {
      "city": "North Fort Myers",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "North Fort Myers, Florida"
    },
    {
      "city": "Cape Coral",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cape Coral, Florida"
    },
    {
      "city": "Alva",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Alva, Florida"
    },
    {
      "city": "Boca Grande",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Boca Grande, Florida"
    },
    {
      "city": "Bokeelia",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bokeelia, Florida"
    },
    {
      "city": "Captiva",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Captiva, Florida"
    },
    {
      "city": "Estero",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Estero, Florida"
    },
    {
      "city": "Fort Myers Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Myers Beach, Florida"
    },
    {
      "city": "Pineland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pineland, Florida"
    },
    {
      "city": "Saint James City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint James City, Florida"
    },
    {
      "city": "Sanibel",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sanibel, Florida"
    },
    {
      "city": "Bonita Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bonita Springs, Florida"
    },
    {
      "city": "Woodville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Woodville, Florida"
    },
    {
      "city": "Bronson",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bronson, Florida"
    },
    {
      "city": "Cedar Key",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cedar Key, Florida"
    },
    {
      "city": "Chiefland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Chiefland, Florida"
    },
    {
      "city": "Gulf Hammock",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gulf Hammock, Florida"
    },
    {
      "city": "Morriston",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Morriston, Florida"
    },
    {
      "city": "Otter Creek",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Otter Creek, Florida"
    },
    {
      "city": "Williston",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Williston, Florida"
    },
    {
      "city": "Inglis",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Inglis, Florida"
    },
    {
      "city": "Yankeetown",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Yankeetown, Florida"
    },
    {
      "city": "Bristol",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bristol, Florida"
    },
    {
      "city": "Hosford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hosford, Florida"
    },
    {
      "city": "Sumatra",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sumatra, Florida"
    },
    {
      "city": "Telogia",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Telogia, Florida"
    },
    {
      "city": "Lee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lee, Florida"
    },
    {
      "city": "Greenville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Greenville, Florida"
    },
    {
      "city": "Madison",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Madison, Florida"
    },
    {
      "city": "Pinetta",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pinetta, Florida"
    },
    {
      "city": "Bradenton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bradenton, Florida"
    },
    {
      "city": "Cortez",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cortez, Florida"
    },
    {
      "city": "Anna Maria",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Anna Maria, Florida"
    },
    {
      "city": "Bradenton Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bradenton Beach, Florida"
    },
    {
      "city": "Holmes Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Holmes Beach, Florida"
    },
    {
      "city": "Parrish",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Parrish, Florida"
    },
    {
      "city": "Palmetto",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palmetto, Florida"
    },
    {
      "city": "Ellenton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ellenton, Florida"
    },
    {
      "city": "Longboat Key",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Longboat Key, Florida"
    },
    {
      "city": "Sarasota",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sarasota, Florida"
    },
    {
      "city": "Terra Ceia",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Terra Ceia, Florida"
    },
    {
      "city": "Myakka City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Myakka City, Florida"
    },
    {
      "city": "Manasota",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Manasota, Florida"
    },
    {
      "city": "Oneco",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oneco, Florida"
    },
    {
      "city": "Tallevast",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tallevast, Florida"
    },
    {
      "city": "Candler",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Candler, Florida"
    },
    {
      "city": "Citra",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Citra, Florida"
    },
    {
      "city": "Eastlake Weir",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eastlake Weir, Florida"
    },
    {
      "city": "Fort Mc Coy",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Mc Coy, Florida"
    },
    {
      "city": "Ocklawaha",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ocklawaha, Florida"
    },
    {
      "city": "Orange Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Orange Springs, Florida"
    },
    {
      "city": "Sparr",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sparr, Florida"
    },
    {
      "city": "Weirsdale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Weirsdale, Florida"
    },
    {
      "city": "Anthony",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Anthony, Florida"
    },
    {
      "city": "Fairfield",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fairfield, Florida"
    },
    {
      "city": "Lowell",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lowell, Florida"
    },
    {
      "city": "Mc Intosh",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mc Intosh, Florida"
    },
    {
      "city": "Orange Lake",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Orange Lake, Florida"
    },
    {
      "city": "Reddick",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Reddick, Florida"
    },
    {
      "city": "Belleview",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Belleview, Florida"
    },
    {
      "city": "Ocala",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ocala, Florida"
    },
    {
      "city": "Silver Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Silver Springs, Florida"
    },
    {
      "city": "Summerfield",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Summerfield, Florida"
    },
    {
      "city": "Hobe Sound",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hobe Sound, Florida"
    },
    {
      "city": "Indiantown",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Indiantown, Florida"
    },
    {
      "city": "Jensen Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jensen Beach, Florida"
    },
    {
      "city": "Palm City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm City, Florida"
    },
    {
      "city": "Port Salerno",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Salerno, Florida"
    },
    {
      "city": "Stuart",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Stuart, Florida"
    },
    {
      "city": "Hialeah",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hialeah, Florida"
    },
    {
      "city": "Homestead",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Homestead, Florida"
    },
    {
      "city": "Opa Locka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Opa Locka, Florida"
    },
    {
      "city": "Miami Gardens",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Miami Gardens, Florida"
    },
    {
      "city": "Miami",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Miami, Florida"
    },
    {
      "city": "Miami Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Miami Beach, Florida"
    },
    {
      "city": "Coral Gables",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Coral Gables, Florida"
    },
    {
      "city": "Key Biscayne",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Key Biscayne, Florida"
    },
    {
      "city": "North Miami Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "North Miami Beach, Florida"
    },
    {
      "city": "Long Key",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Long Key, Florida"
    },
    {
      "city": "Islamorada",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Islamorada, Florida"
    },
    {
      "city": "Key Largo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Key Largo, Florida"
    },
    {
      "city": "Key West",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Key West, Florida"
    },
    {
      "city": "Summerland Key",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Summerland Key, Florida"
    },
    {
      "city": "Big Pine Key",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Big Pine Key, Florida"
    },
    {
      "city": "Marathon",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Marathon, Florida"
    },
    {
      "city": "Key Colony Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Key Colony Beach, Florida"
    },
    {
      "city": "Marathon Shores",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Marathon Shores, Florida"
    },
    {
      "city": "Tavernier",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tavernier, Florida"
    },
    {
      "city": "Bryceville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bryceville, Florida"
    },
    {
      "city": "Callahan",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Callahan, Florida"
    },
    {
      "city": "Fernandina Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fernandina Beach, Florida"
    },
    {
      "city": "Yulee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Yulee, Florida"
    },
    {
      "city": "Hilliard",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hilliard, Florida"
    },
    {
      "city": "Baker",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Baker, Florida"
    },
    {
      "city": "Crestview",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crestview, Florida"
    },
    {
      "city": "Milligan",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Milligan, Florida"
    },
    {
      "city": "Destin",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Destin, Florida"
    },
    {
      "city": "Eglin Afb",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eglin Afb, Florida"
    },
    {
      "city": "Hurlburt Field",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hurlburt Field, Florida"
    },
    {
      "city": "Fort Walton Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Walton Beach, Florida"
    },
    {
      "city": "Holt",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Holt, Florida"
    },
    {
      "city": "Laurel Hill",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Laurel Hill, Florida"
    },
    {
      "city": "Mary Esther",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mary Esther, Florida"
    },
    {
      "city": "Niceville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Niceville, Florida"
    },
    {
      "city": "Shalimar",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Shalimar, Florida"
    },
    {
      "city": "Valparaiso",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Valparaiso, Florida"
    },
    {
      "city": "Okeechobee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Okeechobee, Florida"
    },
    {
      "city": "Apopka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Apopka, Florida"
    },
    {
      "city": "Christmas",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Christmas, Florida"
    },
    {
      "city": "Clarcona",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clarcona, Florida"
    },
    {
      "city": "Maitland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Maitland, Florida"
    },
    {
      "city": "Plymouth",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Plymouth, Florida"
    },
    {
      "city": "Tangerine",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tangerine, Florida"
    },
    {
      "city": "Winter Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Winter Park, Florida"
    },
    {
      "city": "Zellwood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Zellwood, Florida"
    },
    {
      "city": "Gotha",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gotha, Florida"
    },
    {
      "city": "Killarney",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Killarney, Florida"
    },
    {
      "city": "Oakland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oakland, Florida"
    },
    {
      "city": "Ocoee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ocoee, Florida"
    },
    {
      "city": "Winter Garden",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Winter Garden, Florida"
    },
    {
      "city": "Windermere",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Windermere, Florida"
    },
    {
      "city": "Intercession City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Intercession City, Florida"
    },
    {
      "city": "Kenansville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Kenansville, Florida"
    },
    {
      "city": "Kissimmee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Kissimmee, Florida"
    },
    {
      "city": "Saint Cloud",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Cloud, Florida"
    },
    {
      "city": "West Palm Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "West Palm Beach, Florida"
    },
    {
      "city": "North Palm Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "North Palm Beach, Florida"
    },
    {
      "city": "Palm Beach Gardens",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm Beach Gardens, Florida"
    },
    {
      "city": "Wellington",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wellington, Florida"
    },
    {
      "city": "Royal Palm Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Royal Palm Beach, Florida"
    },
    {
      "city": "Boynton Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Boynton Beach, Florida"
    },
    {
      "city": "Boca Raton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Boca Raton, Florida"
    },
    {
      "city": "Belle Glade",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Belle Glade, Florida"
    },
    {
      "city": "Canal Point",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Canal Point, Florida"
    },
    {
      "city": "Delray Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Delray Beach, Florida"
    },
    {
      "city": "Lake Worth",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Worth, Florida"
    },
    {
      "city": "Greenacres",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Greenacres, Florida"
    },
    {
      "city": "Jupiter",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jupiter, Florida"
    },
    {
      "city": "Lake Harbor",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Harbor, Florida"
    },
    {
      "city": "Loxahatchee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Loxahatchee, Florida"
    },
    {
      "city": "Pahokee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pahokee, Florida"
    },
    {
      "city": "Palm Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm Beach, Florida"
    },
    {
      "city": "South Bay",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "South Bay, Florida"
    },
    {
      "city": "Dade City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dade City, Florida"
    },
    {
      "city": "Crystal Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crystal Springs, Florida"
    },
    {
      "city": "Lacoochee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lacoochee, Florida"
    },
    {
      "city": "Zephyrhills",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Zephyrhills, Florida"
    },
    {
      "city": "Wesley Chapel",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wesley Chapel, Florida"
    },
    {
      "city": "Saint Leo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Leo, Florida"
    },
    {
      "city": "San Antonio",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "San Antonio, Florida"
    },
    {
      "city": "Trilby",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Trilby, Florida"
    },
    {
      "city": "Land O Lakes",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Land O Lakes, Florida"
    },
    {
      "city": "New Port Richey",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "New Port Richey, Florida"
    },
    {
      "city": "Hudson",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hudson, Florida"
    },
    {
      "city": "Port Richey",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Richey, Florida"
    },
    {
      "city": "Aripeka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Aripeka, Florida"
    },
    {
      "city": "Elfers",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Elfers, Florida"
    },
    {
      "city": "Holiday",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Holiday, Florida"
    },
    {
      "city": "Saint Petersburg",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Petersburg, Florida"
    },
    {
      "city": "Bay Pines",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bay Pines, Florida"
    },
    {
      "city": "Clearwater",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clearwater, Florida"
    },
    {
      "city": "Clearwater Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Clearwater Beach, Florida"
    },
    {
      "city": "Largo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Largo, Florida"
    },
    {
      "city": "Seminole",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Seminole, Florida"
    },
    {
      "city": "Pinellas Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pinellas Park, Florida"
    },
    {
      "city": "Indian Rocks Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Indian Rocks Beach, Florida"
    },
    {
      "city": "Belleair Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Belleair Beach, Florida"
    },
    {
      "city": "Ozona",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ozona, Florida"
    },
    {
      "city": "Oldsmar",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oldsmar, Florida"
    },
    {
      "city": "Crystal Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crystal Beach, Florida"
    },
    {
      "city": "Palm Harbor",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palm Harbor, Florida"
    },
    {
      "city": "Tarpon Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Tarpon Springs, Florida"
    },
    {
      "city": "Safety Harbor",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Safety Harbor, Florida"
    },
    {
      "city": "Dunedin",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dunedin, Florida"
    },
    {
      "city": "Lakeland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lakeland, Florida"
    },
    {
      "city": "Alturas",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Alturas, Florida"
    },
    {
      "city": "Auburndale",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Auburndale, Florida"
    },
    {
      "city": "Babson Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Babson Park, Florida"
    },
    {
      "city": "Bartow",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bartow, Florida"
    },
    {
      "city": "Bradley",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bradley, Florida"
    },
    {
      "city": "Davenport",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Davenport, Florida"
    },
    {
      "city": "Dundee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Dundee, Florida"
    },
    {
      "city": "Eagle Lake",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eagle Lake, Florida"
    },
    {
      "city": "Eaton Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Eaton Park, Florida"
    },
    {
      "city": "Fort Meade",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Meade, Florida"
    },
    {
      "city": "Frostproof",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Frostproof, Florida"
    },
    {
      "city": "Haines City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Haines City, Florida"
    },
    {
      "city": "Highland City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Highland City, Florida"
    },
    {
      "city": "Homeland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Homeland, Florida"
    },
    {
      "city": "Kathleen",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Kathleen, Florida"
    },
    {
      "city": "Lake Alfred",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Alfred, Florida"
    },
    {
      "city": "Lake Hamilton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Hamilton, Florida"
    },
    {
      "city": "Lake Wales",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Wales, Florida"
    },
    {
      "city": "Lakeshore",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lakeshore, Florida"
    },
    {
      "city": "Indian Lake Estates",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Indian Lake Estates, Florida"
    },
    {
      "city": "Nalcrest",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Nalcrest, Florida"
    },
    {
      "city": "Loughman",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Loughman, Florida"
    },
    {
      "city": "Mulberry",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mulberry, Florida"
    },
    {
      "city": "Nichols",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Nichols, Florida"
    },
    {
      "city": "River Ranch",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "River Ranch, Florida"
    },
    {
      "city": "Polk City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Polk City, Florida"
    },
    {
      "city": "Waverly",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Waverly, Florida"
    },
    {
      "city": "Winter Haven",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Winter Haven, Florida"
    },
    {
      "city": "Bostwick",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bostwick, Florida"
    },
    {
      "city": "Crescent City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crescent City, Florida"
    },
    {
      "city": "East Palatka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "East Palatka, Florida"
    },
    {
      "city": "Grandin",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Grandin, Florida"
    },
    {
      "city": "Georgetown",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Georgetown, Florida"
    },
    {
      "city": "Florahome",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Florahome, Florida"
    },
    {
      "city": "Hollister",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hollister, Florida"
    },
    {
      "city": "Interlachen",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Interlachen, Florida"
    },
    {
      "city": "Lake Como",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Como, Florida"
    },
    {
      "city": "Palatka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Palatka, Florida"
    },
    {
      "city": "Pomona Park",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pomona Park, Florida"
    },
    {
      "city": "Putnam Hall",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Putnam Hall, Florida"
    },
    {
      "city": "San Mateo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "San Mateo, Florida"
    },
    {
      "city": "Satsuma",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Satsuma, Florida"
    },
    {
      "city": "Welaka",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Welaka, Florida"
    },
    {
      "city": "Melrose",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Melrose, Florida"
    },
    {
      "city": "Ponte Vedra Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ponte Vedra Beach, Florida"
    },
    {
      "city": "Elkton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Elkton, Florida"
    },
    {
      "city": "Saint Augustine",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Augustine, Florida"
    },
    {
      "city": "Ponte Vedra",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ponte Vedra, Florida"
    },
    {
      "city": "Hastings",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Hastings, Florida"
    },
    {
      "city": "Saint Johns",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Johns, Florida"
    },
    {
      "city": "Fort Pierce",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Fort Pierce, Florida"
    },
    {
      "city": "Port Saint Lucie",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Saint Lucie, Florida"
    },
    {
      "city": "Bagdad",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bagdad, Florida"
    },
    {
      "city": "Gulf Breeze",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Gulf Breeze, Florida"
    },
    {
      "city": "Jay",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Jay, Florida"
    },
    {
      "city": "Navarre",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Navarre, Florida"
    },
    {
      "city": "Milton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Milton, Florida"
    },
    {
      "city": "Englewood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Englewood, Florida"
    },
    {
      "city": "Osprey",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Osprey, Florida"
    },
    {
      "city": "Laurel",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Laurel, Florida"
    },
    {
      "city": "Nokomis",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Nokomis, Florida"
    },
    {
      "city": "Venice",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Venice, Florida"
    },
    {
      "city": "North Port",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "North Port, Florida"
    },
    {
      "city": "Altamonte Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Altamonte Springs, Florida"
    },
    {
      "city": "Casselberry",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Casselberry, Florida"
    },
    {
      "city": "Winter Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Winter Springs, Florida"
    },
    {
      "city": "Geneva",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Geneva, Florida"
    },
    {
      "city": "Goldenrod",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Goldenrod, Florida"
    },
    {
      "city": "Lake Mary",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Mary, Florida"
    },
    {
      "city": "Lake Monroe",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Monroe, Florida"
    },
    {
      "city": "Longwood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Longwood, Florida"
    },
    {
      "city": "Oviedo",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oviedo, Florida"
    },
    {
      "city": "Sanford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sanford, Florida"
    },
    {
      "city": "Mid Florida",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mid Florida, Florida"
    },
    {
      "city": "The Villages",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "The Villages, Florida"
    },
    {
      "city": "Bushnell",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Bushnell, Florida"
    },
    {
      "city": "Center Hill",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Center Hill, Florida"
    },
    {
      "city": "Coleman",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Coleman, Florida"
    },
    {
      "city": "Lake Panasoffkee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Panasoffkee, Florida"
    },
    {
      "city": "Sumterville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sumterville, Florida"
    },
    {
      "city": "Webster",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Webster, Florida"
    },
    {
      "city": "Oxford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oxford, Florida"
    },
    {
      "city": "Wildwood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wildwood, Florida"
    },
    {
      "city": "Branford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Branford, Florida"
    },
    {
      "city": "Live Oak",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Live Oak, Florida"
    },
    {
      "city": "Mc Alpin",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mc Alpin, Florida"
    },
    {
      "city": "O Brien",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "O Brien, Florida"
    },
    {
      "city": "Wellborn",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wellborn, Florida"
    },
    {
      "city": "Perry",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Perry, Florida"
    },
    {
      "city": "Salem",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Salem, Florida"
    },
    {
      "city": "Shady Grove",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Shady Grove, Florida"
    },
    {
      "city": "Steinhatchee",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Steinhatchee, Florida"
    },
    {
      "city": "Raiford",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Raiford, Florida"
    },
    {
      "city": "Lake Butler",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Butler, Florida"
    },
    {
      "city": "Worthington Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Worthington Springs, Florida"
    },
    {
      "city": "Barberville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Barberville, Florida"
    },
    {
      "city": "Daytona Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Daytona Beach, Florida"
    },
    {
      "city": "Port Orange",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Port Orange, Florida"
    },
    {
      "city": "De Leon Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "De Leon Springs, Florida"
    },
    {
      "city": "Edgewater",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Edgewater, Florida"
    },
    {
      "city": "New Smyrna Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "New Smyrna Beach, Florida"
    },
    {
      "city": "Ormond Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ormond Beach, Florida"
    },
    {
      "city": "Pierson",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Pierson, Florida"
    },
    {
      "city": "Seville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Seville, Florida"
    },
    {
      "city": "Cassadaga",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Cassadaga, Florida"
    },
    {
      "city": "Deland",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Deland, Florida"
    },
    {
      "city": "Glenwood",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Glenwood, Florida"
    },
    {
      "city": "Deltona",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Deltona, Florida"
    },
    {
      "city": "Lake Helen",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Lake Helen, Florida"
    },
    {
      "city": "Oak Hill",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Oak Hill, Florida"
    },
    {
      "city": "Orange City",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Orange City, Florida"
    },
    {
      "city": "Osteen",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Osteen, Florida"
    },
    {
      "city": "Crawfordville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Crawfordville, Florida"
    },
    {
      "city": "Panacea",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Panacea, Florida"
    },
    {
      "city": "Saint Marks",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Saint Marks, Florida"
    },
    {
      "city": "Sopchoppy",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Sopchoppy, Florida"
    },
    {
      "city": "Argyle",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Argyle, Florida"
    },
    {
      "city": "Defuniak Springs",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Defuniak Springs, Florida"
    },
    {
      "city": "Mossy Head",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Mossy Head, Florida"
    },
    {
      "city": "Freeport",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Freeport, Florida"
    },
    {
      "city": "Santa Rosa Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Santa Rosa Beach, Florida"
    },
    {
      "city": "Rosemary Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Rosemary Beach, Florida"
    },
    {
      "city": "Paxton",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Paxton, Florida"
    },
    {
      "city": "Miramar Beach",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Miramar Beach, Florida"
    },
    {
      "city": "Caryville",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Caryville, Florida"
    },
    {
      "city": "Chipley",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Chipley, Florida"
    },
    {
      "city": "Ebro",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Ebro, Florida"
    },
    {
      "city": "Vernon",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Vernon, Florida"
    },
    {
      "city": "Wausau",
      "state": "Florida",
      "shortState": "FL",
      "formatedCity": "Wausau, Florida"
    },
    {
      "city": "Baxley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Baxley, Georgia"
    },
    {
      "city": "Saint George",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Saint George, Georgia"
    },
    {
      "city": "Surrency",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Surrency, Georgia"
    },
    {
      "city": "Axson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Axson, Georgia"
    },
    {
      "city": "Pearson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pearson, Georgia"
    },
    {
      "city": "Willacoochee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Willacoochee, Georgia"
    },
    {
      "city": "Alma",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alma, Georgia"
    },
    {
      "city": "Albany",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Albany, Georgia"
    },
    {
      "city": "Colquitt",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Colquitt, Georgia"
    },
    {
      "city": "Damascus",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Damascus, Georgia"
    },
    {
      "city": "Donalsonville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Donalsonville, Georgia"
    },
    {
      "city": "Leary",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Leary, Georgia"
    },
    {
      "city": "Newton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Newton, Georgia"
    },
    {
      "city": "Hardwick",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hardwick, Georgia"
    },
    {
      "city": "Milledgeville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Milledgeville, Georgia"
    },
    {
      "city": "Baldwin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Baldwin, Georgia"
    },
    {
      "city": "Homer",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Homer, Georgia"
    },
    {
      "city": "Maysville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Maysville, Georgia"
    },
    {
      "city": "Auburn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Auburn, Georgia"
    },
    {
      "city": "Bethlehem",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bethlehem, Georgia"
    },
    {
      "city": "Statham",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Statham, Georgia"
    },
    {
      "city": "Winder",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Winder, Georgia"
    },
    {
      "city": "Adairsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Adairsville, Georgia"
    },
    {
      "city": "Cartersville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cartersville, Georgia"
    },
    {
      "city": "Cassville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cassville, Georgia"
    },
    {
      "city": "Emerson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Emerson, Georgia"
    },
    {
      "city": "Kingston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kingston, Georgia"
    },
    {
      "city": "Rydal",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rydal, Georgia"
    },
    {
      "city": "Taylorsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Taylorsville, Georgia"
    },
    {
      "city": "White",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "White, Georgia"
    },
    {
      "city": "Fitzgerald",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fitzgerald, Georgia"
    },
    {
      "city": "Cuthbert",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cuthbert, Georgia"
    },
    {
      "city": "Alapaha",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alapaha, Georgia"
    },
    {
      "city": "Nashville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nashville, Georgia"
    },
    {
      "city": "Ray City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ray City, Georgia"
    },
    {
      "city": "Enigma",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Enigma, Georgia"
    },
    {
      "city": "Lizella",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lizella, Georgia"
    },
    {
      "city": "Macon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Macon, Georgia"
    },
    {
      "city": "Cochran",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cochran, Georgia"
    },
    {
      "city": "Hoboken",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hoboken, Georgia"
    },
    {
      "city": "Hortense",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hortense, Georgia"
    },
    {
      "city": "Nahunta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nahunta, Georgia"
    },
    {
      "city": "Waynesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waynesville, Georgia"
    },
    {
      "city": "Barney",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Barney, Georgia"
    },
    {
      "city": "Dixie",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dixie, Georgia"
    },
    {
      "city": "Morven",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Morven, Georgia"
    },
    {
      "city": "Quitman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Quitman, Georgia"
    },
    {
      "city": "Barwick",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Barwick, Georgia"
    },
    {
      "city": "Ellabell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellabell, Georgia"
    },
    {
      "city": "Pembroke",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pembroke, Georgia"
    },
    {
      "city": "Richmond Hill",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Richmond Hill, Georgia"
    },
    {
      "city": "Brooklet",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Brooklet, Georgia"
    },
    {
      "city": "Portal",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Portal, Georgia"
    },
    {
      "city": "Register",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Register, Georgia"
    },
    {
      "city": "Statesboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Statesboro, Georgia"
    },
    {
      "city": "Girard",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Girard, Georgia"
    },
    {
      "city": "Midville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Midville, Georgia"
    },
    {
      "city": "Sardis",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sardis, Georgia"
    },
    {
      "city": "Gough",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gough, Georgia"
    },
    {
      "city": "Keysville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Keysville, Georgia"
    },
    {
      "city": "Waynesboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waynesboro, Georgia"
    },
    {
      "city": "Flovilla",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Flovilla, Georgia"
    },
    {
      "city": "Jackson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jackson, Georgia"
    },
    {
      "city": "Jenkinsburg",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jenkinsburg, Georgia"
    },
    {
      "city": "Dawson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dawson, Georgia"
    },
    {
      "city": "Morgan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Morgan, Georgia"
    },
    {
      "city": "Shellman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Shellman, Georgia"
    },
    {
      "city": "Kings Bay",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kings Bay, Georgia"
    },
    {
      "city": "Kingsland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kingsland, Georgia"
    },
    {
      "city": "Saint Marys",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Saint Marys, Georgia"
    },
    {
      "city": "Waverly",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waverly, Georgia"
    },
    {
      "city": "White Oak",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "White Oak, Georgia"
    },
    {
      "city": "Woodbine",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Woodbine, Georgia"
    },
    {
      "city": "Metter",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Metter, Georgia"
    },
    {
      "city": "Pulaski",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pulaski, Georgia"
    },
    {
      "city": "Bowdon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bowdon, Georgia"
    },
    {
      "city": "Bowdon Junction",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bowdon Junction, Georgia"
    },
    {
      "city": "Carrollton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Carrollton, Georgia"
    },
    {
      "city": "Mount Zion",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mount Zion, Georgia"
    },
    {
      "city": "Roopville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Roopville, Georgia"
    },
    {
      "city": "Temple",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Temple, Georgia"
    },
    {
      "city": "Villa Rica",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Villa Rica, Georgia"
    },
    {
      "city": "Whitesburg",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Whitesburg, Georgia"
    },
    {
      "city": "Graysville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Graysville, Georgia"
    },
    {
      "city": "Ringgold",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ringgold, Georgia"
    },
    {
      "city": "Fort Oglethorpe",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fort Oglethorpe, Georgia"
    },
    {
      "city": "Folkston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Folkston, Georgia"
    },
    {
      "city": "Bloomingdale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bloomingdale, Georgia"
    },
    {
      "city": "Pooler",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pooler, Georgia"
    },
    {
      "city": "Tybee Island",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tybee Island, Georgia"
    },
    {
      "city": "Savannah",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Savannah, Georgia"
    },
    {
      "city": "Port Wentworth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Port Wentworth, Georgia"
    },
    {
      "city": "Cusseta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cusseta, Georgia"
    },
    {
      "city": "Lyerly",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lyerly, Georgia"
    },
    {
      "city": "Menlo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Menlo, Georgia"
    },
    {
      "city": "Summerville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Summerville, Georgia"
    },
    {
      "city": "Trion",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Trion, Georgia"
    },
    {
      "city": "Ball Ground",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ball Ground, Georgia"
    },
    {
      "city": "Canton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Canton, Georgia"
    },
    {
      "city": "Holly Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Holly Springs, Georgia"
    },
    {
      "city": "Lebanon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lebanon, Georgia"
    },
    {
      "city": "Nelson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nelson, Georgia"
    },
    {
      "city": "Waleska",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waleska, Georgia"
    },
    {
      "city": "Woodstock",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Woodstock, Georgia"
    },
    {
      "city": "Athens",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Athens, Georgia"
    },
    {
      "city": "Winterville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Winterville, Georgia"
    },
    {
      "city": "Blakely",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Blakely, Georgia"
    },
    {
      "city": "Bluffton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bluffton, Georgia"
    },
    {
      "city": "Coleman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Coleman, Georgia"
    },
    {
      "city": "Edison",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Edison, Georgia"
    },
    {
      "city": "Fort Gaines",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fort Gaines, Georgia"
    },
    {
      "city": "Georgetown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Georgetown, Georgia"
    },
    {
      "city": "Morris",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Morris, Georgia"
    },
    {
      "city": "Jonesboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jonesboro, Georgia"
    },
    {
      "city": "Lovejoy",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lovejoy, Georgia"
    },
    {
      "city": "Morrow",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Morrow, Georgia"
    },
    {
      "city": "Rex",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rex, Georgia"
    },
    {
      "city": "Riverdale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Riverdale, Georgia"
    },
    {
      "city": "Conley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Conley, Georgia"
    },
    {
      "city": "Ellenwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellenwood, Georgia"
    },
    {
      "city": "Forest Park",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Forest Park, Georgia"
    },
    {
      "city": "Argyle",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Argyle, Georgia"
    },
    {
      "city": "Du Pont",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Du Pont, Georgia"
    },
    {
      "city": "Fargo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fargo, Georgia"
    },
    {
      "city": "Homerville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Homerville, Georgia"
    },
    {
      "city": "Marietta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Marietta, Georgia"
    },
    {
      "city": "Smyrna",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Smyrna, Georgia"
    },
    {
      "city": "Acworth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Acworth, Georgia"
    },
    {
      "city": "Austell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Austell, Georgia"
    },
    {
      "city": "Clarkdale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clarkdale, Georgia"
    },
    {
      "city": "Mableton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mableton, Georgia"
    },
    {
      "city": "Powder Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Powder Springs, Georgia"
    },
    {
      "city": "Kennesaw",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kennesaw, Georgia"
    },
    {
      "city": "Ambrose",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ambrose, Georgia"
    },
    {
      "city": "Broxton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Broxton, Georgia"
    },
    {
      "city": "Douglas",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Douglas, Georgia"
    },
    {
      "city": "Nicholls",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nicholls, Georgia"
    },
    {
      "city": "West Green",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "West Green, Georgia"
    },
    {
      "city": "Berlin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Berlin, Georgia"
    },
    {
      "city": "Doerun",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Doerun, Georgia"
    },
    {
      "city": "Ellenton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellenton, Georgia"
    },
    {
      "city": "Funston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Funston, Georgia"
    },
    {
      "city": "Hartsfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hartsfield, Georgia"
    },
    {
      "city": "Moultrie",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Moultrie, Georgia"
    },
    {
      "city": "Norman Park",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Norman Park, Georgia"
    },
    {
      "city": "Appling",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Appling, Georgia"
    },
    {
      "city": "Evans",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Evans, Georgia"
    },
    {
      "city": "Grovetown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Grovetown, Georgia"
    },
    {
      "city": "Harlem",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Harlem, Georgia"
    },
    {
      "city": "Augusta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Augusta, Georgia"
    },
    {
      "city": "Arlington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Arlington, Georgia"
    },
    {
      "city": "Adel",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Adel, Georgia"
    },
    {
      "city": "Cecil",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cecil, Georgia"
    },
    {
      "city": "Lenox",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lenox, Georgia"
    },
    {
      "city": "Sparks",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sparks, Georgia"
    },
    {
      "city": "Grantville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Grantville, Georgia"
    },
    {
      "city": "Haralson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Haralson, Georgia"
    },
    {
      "city": "Moreland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Moreland, Georgia"
    },
    {
      "city": "Newnan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Newnan, Georgia"
    },
    {
      "city": "Sargent",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sargent, Georgia"
    },
    {
      "city": "Senoia",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Senoia, Georgia"
    },
    {
      "city": "Sharpsburg",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sharpsburg, Georgia"
    },
    {
      "city": "Turin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Turin, Georgia"
    },
    {
      "city": "Knoxville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Knoxville, Georgia"
    },
    {
      "city": "Musella",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Musella, Georgia"
    },
    {
      "city": "Roberta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Roberta, Georgia"
    },
    {
      "city": "Cordele",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cordele, Georgia"
    },
    {
      "city": "Arabi",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Arabi, Georgia"
    },
    {
      "city": "Rising Fawn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rising Fawn, Georgia"
    },
    {
      "city": "Trenton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Trenton, Georgia"
    },
    {
      "city": "Wildwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wildwood, Georgia"
    },
    {
      "city": "Dawsonville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dawsonville, Georgia"
    },
    {
      "city": "Attapulgus",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Attapulgus, Georgia"
    },
    {
      "city": "Bainbridge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bainbridge, Georgia"
    },
    {
      "city": "Fowlstown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fowlstown, Georgia"
    },
    {
      "city": "Avondale Estates",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Avondale Estates, Georgia"
    },
    {
      "city": "Clarkston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clarkston, Georgia"
    },
    {
      "city": "Decatur",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Decatur, Georgia"
    },
    {
      "city": "Lithonia",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lithonia, Georgia"
    },
    {
      "city": "Pine Lake",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pine Lake, Georgia"
    },
    {
      "city": "Redan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Redan, Georgia"
    },
    {
      "city": "Scottdale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Scottdale, Georgia"
    },
    {
      "city": "Stone Mountain",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stone Mountain, Georgia"
    },
    {
      "city": "Tucker",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tucker, Georgia"
    },
    {
      "city": "Atlanta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Atlanta, Georgia"
    },
    {
      "city": "Chauncey",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chauncey, Georgia"
    },
    {
      "city": "Chester",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chester, Georgia"
    },
    {
      "city": "Eastman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Eastman, Georgia"
    },
    {
      "city": "Rhine",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rhine, Georgia"
    },
    {
      "city": "Byromville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Byromville, Georgia"
    },
    {
      "city": "Lilly",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lilly, Georgia"
    },
    {
      "city": "Montezuma",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Montezuma, Georgia"
    },
    {
      "city": "Pinehurst",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pinehurst, Georgia"
    },
    {
      "city": "Unadilla",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Unadilla, Georgia"
    },
    {
      "city": "Vienna",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Vienna, Georgia"
    },
    {
      "city": "Putney",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Putney, Georgia"
    },
    {
      "city": "Lithia Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lithia Springs, Georgia"
    },
    {
      "city": "Douglasville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Douglasville, Georgia"
    },
    {
      "city": "Winston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Winston, Georgia"
    },
    {
      "city": "Cedar Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cedar Springs, Georgia"
    },
    {
      "city": "Climax",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Climax, Georgia"
    },
    {
      "city": "Statenville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Statenville, Georgia"
    },
    {
      "city": "Clyo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clyo, Georgia"
    },
    {
      "city": "Eden",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Eden, Georgia"
    },
    {
      "city": "Guyton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Guyton, Georgia"
    },
    {
      "city": "Meldrim",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Meldrim, Georgia"
    },
    {
      "city": "Rincon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rincon, Georgia"
    },
    {
      "city": "Springfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Springfield, Georgia"
    },
    {
      "city": "Bowman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bowman, Georgia"
    },
    {
      "city": "Dewy Rose",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dewy Rose, Georgia"
    },
    {
      "city": "Elberton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Elberton, Georgia"
    },
    {
      "city": "Swainsboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Swainsboro, Georgia"
    },
    {
      "city": "Garfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Garfield, Georgia"
    },
    {
      "city": "Norristown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Norristown, Georgia"
    },
    {
      "city": "Nunez",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nunez, Georgia"
    },
    {
      "city": "Stillmore",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stillmore, Georgia"
    },
    {
      "city": "Twin City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Twin City, Georgia"
    },
    {
      "city": "Adrian",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Adrian, Georgia"
    },
    {
      "city": "Bellville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bellville, Georgia"
    },
    {
      "city": "Claxton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Claxton, Georgia"
    },
    {
      "city": "Daisy",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Daisy, Georgia"
    },
    {
      "city": "Hagan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hagan, Georgia"
    },
    {
      "city": "Blue Ridge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Blue Ridge, Georgia"
    },
    {
      "city": "Epworth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Epworth, Georgia"
    },
    {
      "city": "Mc Caysville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mc Caysville, Georgia"
    },
    {
      "city": "Mineral Bluff",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mineral Bluff, Georgia"
    },
    {
      "city": "Morganton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Morganton, Georgia"
    },
    {
      "city": "Brooks",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Brooks, Georgia"
    },
    {
      "city": "Fayetteville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fayetteville, Georgia"
    },
    {
      "city": "Peachtree City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Peachtree City, Georgia"
    },
    {
      "city": "Tyrone",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tyrone, Georgia"
    },
    {
      "city": "Armuchee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Armuchee, Georgia"
    },
    {
      "city": "Cave Spring",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cave Spring, Georgia"
    },
    {
      "city": "Coosa",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Coosa, Georgia"
    },
    {
      "city": "Lindale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lindale, Georgia"
    },
    {
      "city": "Mount Berry",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mount Berry, Georgia"
    },
    {
      "city": "Rome",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rome, Georgia"
    },
    {
      "city": "Shannon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Shannon, Georgia"
    },
    {
      "city": "Silver Creek",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Silver Creek, Georgia"
    },
    {
      "city": "Cumming",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cumming, Georgia"
    },
    {
      "city": "Canon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Canon, Georgia"
    },
    {
      "city": "Carnesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Carnesville, Georgia"
    },
    {
      "city": "Lavonia",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lavonia, Georgia"
    },
    {
      "city": "Franklin Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Franklin Springs, Georgia"
    },
    {
      "city": "Royston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Royston, Georgia"
    },
    {
      "city": "Alpharetta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alpharetta, Georgia"
    },
    {
      "city": "Roswell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Roswell, Georgia"
    },
    {
      "city": "Fairburn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fairburn, Georgia"
    },
    {
      "city": "Palmetto",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Palmetto, Georgia"
    },
    {
      "city": "Red Oak",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Red Oak, Georgia"
    },
    {
      "city": "Union City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Union City, Georgia"
    },
    {
      "city": "Cherry Log",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cherry Log, Georgia"
    },
    {
      "city": "Ellijay",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellijay, Georgia"
    },
    {
      "city": "East Ellijay",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "East Ellijay, Georgia"
    },
    {
      "city": "Gibson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gibson, Georgia"
    },
    {
      "city": "Mitchell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mitchell, Georgia"
    },
    {
      "city": "Brunswick",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Brunswick, Georgia"
    },
    {
      "city": "Saint Simons Island",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Saint Simons Island, Georgia"
    },
    {
      "city": "Jekyll Island",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jekyll Island, Georgia"
    },
    {
      "city": "Sea Island",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sea Island, Georgia"
    },
    {
      "city": "Fairmount",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fairmount, Georgia"
    },
    {
      "city": "Calhoun",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Calhoun, Georgia"
    },
    {
      "city": "Oakman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oakman, Georgia"
    },
    {
      "city": "Plainville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Plainville, Georgia"
    },
    {
      "city": "Ranger",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ranger, Georgia"
    },
    {
      "city": "Resaca",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Resaca, Georgia"
    },
    {
      "city": "Sugar Valley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sugar Valley, Georgia"
    },
    {
      "city": "Cairo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cairo, Georgia"
    },
    {
      "city": "Calvary",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Calvary, Georgia"
    },
    {
      "city": "Whigham",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Whigham, Georgia"
    },
    {
      "city": "Greensboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Greensboro, Georgia"
    },
    {
      "city": "Siloam",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Siloam, Georgia"
    },
    {
      "city": "Union Point",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Union Point, Georgia"
    },
    {
      "city": "White Plains",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "White Plains, Georgia"
    },
    {
      "city": "Norcross",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Norcross, Georgia"
    },
    {
      "city": "Grayson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Grayson, Georgia"
    },
    {
      "city": "Dacula",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dacula, Georgia"
    },
    {
      "city": "Suwanee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Suwanee, Georgia"
    },
    {
      "city": "North Metro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "North Metro, Georgia"
    },
    {
      "city": "Snellville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Snellville, Georgia"
    },
    {
      "city": "Lawrenceville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lawrenceville, Georgia"
    },
    {
      "city": "Lilburn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lilburn, Georgia"
    },
    {
      "city": "Loganville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Loganville, Georgia"
    },
    {
      "city": "Peachtree Corners",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Peachtree Corners, Georgia"
    },
    {
      "city": "Duluth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Duluth, Georgia"
    },
    {
      "city": "Buford",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Buford, Georgia"
    },
    {
      "city": "Alto",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alto, Georgia"
    },
    {
      "city": "Clarkesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clarkesville, Georgia"
    },
    {
      "city": "Cornelia",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cornelia, Georgia"
    },
    {
      "city": "Demorest",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Demorest, Georgia"
    },
    {
      "city": "Mount Airy",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mount Airy, Georgia"
    },
    {
      "city": "Turnerville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Turnerville, Georgia"
    },
    {
      "city": "Gainesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gainesville, Georgia"
    },
    {
      "city": "Chestnut Mountain",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chestnut Mountain, Georgia"
    },
    {
      "city": "Clermont",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clermont, Georgia"
    },
    {
      "city": "Flowery Branch",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Flowery Branch, Georgia"
    },
    {
      "city": "Gillsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gillsville, Georgia"
    },
    {
      "city": "Lula",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lula, Georgia"
    },
    {
      "city": "Murrayville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Murrayville, Georgia"
    },
    {
      "city": "Oakwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oakwood, Georgia"
    },
    {
      "city": "Sparta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sparta, Georgia"
    },
    {
      "city": "Bremen",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bremen, Georgia"
    },
    {
      "city": "Buchanan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Buchanan, Georgia"
    },
    {
      "city": "Felton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Felton, Georgia"
    },
    {
      "city": "Tallapoosa",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tallapoosa, Georgia"
    },
    {
      "city": "Waco",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waco, Georgia"
    },
    {
      "city": "Cataula",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cataula, Georgia"
    },
    {
      "city": "Ellerslie",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellerslie, Georgia"
    },
    {
      "city": "Hamilton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hamilton, Georgia"
    },
    {
      "city": "Pine Mountain",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pine Mountain, Georgia"
    },
    {
      "city": "Pine Mountain Valley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pine Mountain Valley, Georgia"
    },
    {
      "city": "Shiloh",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Shiloh, Georgia"
    },
    {
      "city": "Waverly Hall",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waverly Hall, Georgia"
    },
    {
      "city": "Bowersville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bowersville, Georgia"
    },
    {
      "city": "Hartwell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hartwell, Georgia"
    },
    {
      "city": "Franklin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Franklin, Georgia"
    },
    {
      "city": "Hampton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hampton, Georgia"
    },
    {
      "city": "Locust Grove",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Locust Grove, Georgia"
    },
    {
      "city": "Mcdonough",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mcdonough, Georgia"
    },
    {
      "city": "Stockbridge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stockbridge, Georgia"
    },
    {
      "city": "Bonaire",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bonaire, Georgia"
    },
    {
      "city": "Clinchfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clinchfield, Georgia"
    },
    {
      "city": "Elko",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Elko, Georgia"
    },
    {
      "city": "Centerville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Centerville, Georgia"
    },
    {
      "city": "Kathleen",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kathleen, Georgia"
    },
    {
      "city": "Perry",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Perry, Georgia"
    },
    {
      "city": "Warner Robins",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Warner Robins, Georgia"
    },
    {
      "city": "Irwinville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Irwinville, Georgia"
    },
    {
      "city": "Mystic",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mystic, Georgia"
    },
    {
      "city": "Ocilla",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ocilla, Georgia"
    },
    {
      "city": "Wray",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wray, Georgia"
    },
    {
      "city": "Braselton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Braselton, Georgia"
    },
    {
      "city": "Commerce",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Commerce, Georgia"
    },
    {
      "city": "Hoschton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hoschton, Georgia"
    },
    {
      "city": "Jefferson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jefferson, Georgia"
    },
    {
      "city": "Nicholson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Nicholson, Georgia"
    },
    {
      "city": "Pendergrass",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pendergrass, Georgia"
    },
    {
      "city": "Talmo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Talmo, Georgia"
    },
    {
      "city": "Mansfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mansfield, Georgia"
    },
    {
      "city": "Hillsboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hillsboro, Georgia"
    },
    {
      "city": "Monticello",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Monticello, Georgia"
    },
    {
      "city": "Shady Dale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Shady Dale, Georgia"
    },
    {
      "city": "Denton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Denton, Georgia"
    },
    {
      "city": "Hazlehurst",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hazlehurst, Georgia"
    },
    {
      "city": "Bartow",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bartow, Georgia"
    },
    {
      "city": "Louisville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Louisville, Georgia"
    },
    {
      "city": "Wadley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wadley, Georgia"
    },
    {
      "city": "Avera",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Avera, Georgia"
    },
    {
      "city": "Matthews",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Matthews, Georgia"
    },
    {
      "city": "Stapleton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stapleton, Georgia"
    },
    {
      "city": "Wrens",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wrens, Georgia"
    },
    {
      "city": "Millen",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Millen, Georgia"
    },
    {
      "city": "Perkins",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Perkins, Georgia"
    },
    {
      "city": "Kite",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Kite, Georgia"
    },
    {
      "city": "Wrightsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wrightsville, Georgia"
    },
    {
      "city": "Gray",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gray, Georgia"
    },
    {
      "city": "Haddock",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Haddock, Georgia"
    },
    {
      "city": "Barnesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Barnesville, Georgia"
    },
    {
      "city": "Milner",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Milner, Georgia"
    },
    {
      "city": "Lakeland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lakeland, Georgia"
    },
    {
      "city": "Stockton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stockton, Georgia"
    },
    {
      "city": "Rockledge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rockledge, Georgia"
    },
    {
      "city": "Cadwell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cadwell, Georgia"
    },
    {
      "city": "Dexter",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dexter, Georgia"
    },
    {
      "city": "Dublin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dublin, Georgia"
    },
    {
      "city": "Dudley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dudley, Georgia"
    },
    {
      "city": "East Dublin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "East Dublin, Georgia"
    },
    {
      "city": "Montrose",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Montrose, Georgia"
    },
    {
      "city": "Rentz",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rentz, Georgia"
    },
    {
      "city": "Leesburg",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Leesburg, Georgia"
    },
    {
      "city": "Smithville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Smithville, Georgia"
    },
    {
      "city": "Allenhurst",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Allenhurst, Georgia"
    },
    {
      "city": "Fleming",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fleming, Georgia"
    },
    {
      "city": "Hinesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hinesville, Georgia"
    },
    {
      "city": "Fort Stewart",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fort Stewart, Georgia"
    },
    {
      "city": "Midway",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Midway, Georgia"
    },
    {
      "city": "Riceboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Riceboro, Georgia"
    },
    {
      "city": "Walthourville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Walthourville, Georgia"
    },
    {
      "city": "Lincolnton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lincolnton, Georgia"
    },
    {
      "city": "Ludowici",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ludowici, Georgia"
    },
    {
      "city": "Valdosta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Valdosta, Georgia"
    },
    {
      "city": "Hahira",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hahira, Georgia"
    },
    {
      "city": "Lake Park",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lake Park, Georgia"
    },
    {
      "city": "Naylor",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Naylor, Georgia"
    },
    {
      "city": "Moody Afb",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Moody Afb, Georgia"
    },
    {
      "city": "Dahlonega",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dahlonega, Georgia"
    },
    {
      "city": "Boneville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Boneville, Georgia"
    },
    {
      "city": "Dearing",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dearing, Georgia"
    },
    {
      "city": "Thomson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Thomson, Georgia"
    },
    {
      "city": "Crescent",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Crescent, Georgia"
    },
    {
      "city": "Darien",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Darien, Georgia"
    },
    {
      "city": "Meridian",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Meridian, Georgia"
    },
    {
      "city": "Sapelo Island",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sapelo Island, Georgia"
    },
    {
      "city": "Townsend",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Townsend, Georgia"
    },
    {
      "city": "Ideal",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ideal, Georgia"
    },
    {
      "city": "Marshallville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Marshallville, Georgia"
    },
    {
      "city": "Oglethorpe",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oglethorpe, Georgia"
    },
    {
      "city": "Carlton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Carlton, Georgia"
    },
    {
      "city": "Colbert",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Colbert, Georgia"
    },
    {
      "city": "Comer",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Comer, Georgia"
    },
    {
      "city": "Danielsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Danielsville, Georgia"
    },
    {
      "city": "Hull",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hull, Georgia"
    },
    {
      "city": "Ila",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ila, Georgia"
    },
    {
      "city": "Buena Vista",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Buena Vista, Georgia"
    },
    {
      "city": "Gay",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gay, Georgia"
    },
    {
      "city": "Greenville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Greenville, Georgia"
    },
    {
      "city": "Luthersville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Luthersville, Georgia"
    },
    {
      "city": "Woodbury",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Woodbury, Georgia"
    },
    {
      "city": "Manchester",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Manchester, Georgia"
    },
    {
      "city": "Warm Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Warm Springs, Georgia"
    },
    {
      "city": "Baconton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Baconton, Georgia"
    },
    {
      "city": "Camilla",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Camilla, Georgia"
    },
    {
      "city": "Cotton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cotton, Georgia"
    },
    {
      "city": "Pelham",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pelham, Georgia"
    },
    {
      "city": "Sale City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sale City, Georgia"
    },
    {
      "city": "Bolingbroke",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bolingbroke, Georgia"
    },
    {
      "city": "Culloden",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Culloden, Georgia"
    },
    {
      "city": "Forsyth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Forsyth, Georgia"
    },
    {
      "city": "Juliette",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Juliette, Georgia"
    },
    {
      "city": "Smarr",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Smarr, Georgia"
    },
    {
      "city": "Ailey",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ailey, Georgia"
    },
    {
      "city": "Alston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alston, Georgia"
    },
    {
      "city": "Mount Vernon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mount Vernon, Georgia"
    },
    {
      "city": "Tarrytown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tarrytown, Georgia"
    },
    {
      "city": "Uvalda",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Uvalda, Georgia"
    },
    {
      "city": "Bostwick",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bostwick, Georgia"
    },
    {
      "city": "Buckhead",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Buckhead, Georgia"
    },
    {
      "city": "High Shoals",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "High Shoals, Georgia"
    },
    {
      "city": "Madison",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Madison, Georgia"
    },
    {
      "city": "Rutledge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rutledge, Georgia"
    },
    {
      "city": "Chatsworth",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chatsworth, Georgia"
    },
    {
      "city": "Cisco",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cisco, Georgia"
    },
    {
      "city": "Crandall",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Crandall, Georgia"
    },
    {
      "city": "Eton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Eton, Georgia"
    },
    {
      "city": "Tennga",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tennga, Georgia"
    },
    {
      "city": "Fortson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fortson, Georgia"
    },
    {
      "city": "Midland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Midland, Georgia"
    },
    {
      "city": "Upatoi",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Upatoi, Georgia"
    },
    {
      "city": "Columbus",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Columbus, Georgia"
    },
    {
      "city": "Fort Benning",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fort Benning, Georgia"
    },
    {
      "city": "Covington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Covington, Georgia"
    },
    {
      "city": "Oxford",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oxford, Georgia"
    },
    {
      "city": "Newborn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Newborn, Georgia"
    },
    {
      "city": "Porterdale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Porterdale, Georgia"
    },
    {
      "city": "Bishop",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bishop, Georgia"
    },
    {
      "city": "Bogart",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bogart, Georgia"
    },
    {
      "city": "Farmington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Farmington, Georgia"
    },
    {
      "city": "Watkinsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Watkinsville, Georgia"
    },
    {
      "city": "Arnoldsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Arnoldsville, Georgia"
    },
    {
      "city": "Crawford",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Crawford, Georgia"
    },
    {
      "city": "Lexington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lexington, Georgia"
    },
    {
      "city": "Stephens",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Stephens, Georgia"
    },
    {
      "city": "Maxeys",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Maxeys, Georgia"
    },
    {
      "city": "Dallas",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dallas, Georgia"
    },
    {
      "city": "Hiram",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hiram, Georgia"
    },
    {
      "city": "Byron",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Byron, Georgia"
    },
    {
      "city": "Fort Valley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Fort Valley, Georgia"
    },
    {
      "city": "Jasper",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jasper, Georgia"
    },
    {
      "city": "Marble Hill",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Marble Hill, Georgia"
    },
    {
      "city": "Talking Rock",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Talking Rock, Georgia"
    },
    {
      "city": "Tate",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tate, Georgia"
    },
    {
      "city": "Blackshear",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Blackshear, Georgia"
    },
    {
      "city": "Bristol",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bristol, Georgia"
    },
    {
      "city": "Mershon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mershon, Georgia"
    },
    {
      "city": "Offerman",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Offerman, Georgia"
    },
    {
      "city": "Patterson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Patterson, Georgia"
    },
    {
      "city": "Concord",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Concord, Georgia"
    },
    {
      "city": "Meansville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Meansville, Georgia"
    },
    {
      "city": "Molena",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Molena, Georgia"
    },
    {
      "city": "Williamson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Williamson, Georgia"
    },
    {
      "city": "Zebulon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Zebulon, Georgia"
    },
    {
      "city": "Aragon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Aragon, Georgia"
    },
    {
      "city": "Cedartown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cedartown, Georgia"
    },
    {
      "city": "Esom Hill",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Esom Hill, Georgia"
    },
    {
      "city": "Rockmart",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rockmart, Georgia"
    },
    {
      "city": "Hawkinsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hawkinsville, Georgia"
    },
    {
      "city": "Eatonton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Eatonton, Georgia"
    },
    {
      "city": "Clayton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Clayton, Georgia"
    },
    {
      "city": "Dillard",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dillard, Georgia"
    },
    {
      "city": "Lakemont",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lakemont, Georgia"
    },
    {
      "city": "Mountain City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mountain City, Georgia"
    },
    {
      "city": "Rabun Gap",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rabun Gap, Georgia"
    },
    {
      "city": "Tallulah Falls",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tallulah Falls, Georgia"
    },
    {
      "city": "Tiger",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tiger, Georgia"
    },
    {
      "city": "Wiley",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Wiley, Georgia"
    },
    {
      "city": "Blythe",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Blythe, Georgia"
    },
    {
      "city": "Gracewood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gracewood, Georgia"
    },
    {
      "city": "Hephzibah",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hephzibah, Georgia"
    },
    {
      "city": "Conyers",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Conyers, Georgia"
    },
    {
      "city": "Ellaville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ellaville, Georgia"
    },
    {
      "city": "Dover",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dover, Georgia"
    },
    {
      "city": "Newington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Newington, Georgia"
    },
    {
      "city": "Oliver",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oliver, Georgia"
    },
    {
      "city": "Rocky Ford",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rocky Ford, Georgia"
    },
    {
      "city": "Sylvania",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sylvania, Georgia"
    },
    {
      "city": "Brinson",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Brinson, Georgia"
    },
    {
      "city": "Iron City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Iron City, Georgia"
    },
    {
      "city": "Jakin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jakin, Georgia"
    },
    {
      "city": "Experiment",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Experiment, Georgia"
    },
    {
      "city": "Griffin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Griffin, Georgia"
    },
    {
      "city": "Orchard Hill",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Orchard Hill, Georgia"
    },
    {
      "city": "Sunny Side",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sunny Side, Georgia"
    },
    {
      "city": "Eastanollee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Eastanollee, Georgia"
    },
    {
      "city": "Martin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Martin, Georgia"
    },
    {
      "city": "Toccoa",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Toccoa, Georgia"
    },
    {
      "city": "Toccoa Falls",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Toccoa Falls, Georgia"
    },
    {
      "city": "Louvale",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Louvale, Georgia"
    },
    {
      "city": "Lumpkin",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lumpkin, Georgia"
    },
    {
      "city": "Omaha",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Omaha, Georgia"
    },
    {
      "city": "Richland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Richland, Georgia"
    },
    {
      "city": "Americus",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Americus, Georgia"
    },
    {
      "city": "Andersonville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Andersonville, Georgia"
    },
    {
      "city": "Cobb",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cobb, Georgia"
    },
    {
      "city": "De Soto",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "De Soto, Georgia"
    },
    {
      "city": "Leslie",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Leslie, Georgia"
    },
    {
      "city": "Plains",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Plains, Georgia"
    },
    {
      "city": "Box Springs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Box Springs, Georgia"
    },
    {
      "city": "Geneva",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Geneva, Georgia"
    },
    {
      "city": "Junction City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Junction City, Georgia"
    },
    {
      "city": "Talbotton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Talbotton, Georgia"
    },
    {
      "city": "Woodland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Woodland, Georgia"
    },
    {
      "city": "Crawfordville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Crawfordville, Georgia"
    },
    {
      "city": "Sharon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sharon, Georgia"
    },
    {
      "city": "Cobbtown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cobbtown, Georgia"
    },
    {
      "city": "Collins",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Collins, Georgia"
    },
    {
      "city": "Glennville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Glennville, Georgia"
    },
    {
      "city": "Manassas",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Manassas, Georgia"
    },
    {
      "city": "Reidsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Reidsville, Georgia"
    },
    {
      "city": "Butler",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Butler, Georgia"
    },
    {
      "city": "Howard",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Howard, Georgia"
    },
    {
      "city": "Mauk",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mauk, Georgia"
    },
    {
      "city": "Reynolds",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Reynolds, Georgia"
    },
    {
      "city": "Rupert",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rupert, Georgia"
    },
    {
      "city": "Mc Rae Helena",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mc Rae Helena, Georgia"
    },
    {
      "city": "Milan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Milan, Georgia"
    },
    {
      "city": "Scotland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Scotland, Georgia"
    },
    {
      "city": "Jacksonville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jacksonville, Georgia"
    },
    {
      "city": "Lumber City",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lumber City, Georgia"
    },
    {
      "city": "Bronwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Bronwood, Georgia"
    },
    {
      "city": "Sasser",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sasser, Georgia"
    },
    {
      "city": "Boston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Boston, Georgia"
    },
    {
      "city": "Coolidge",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Coolidge, Georgia"
    },
    {
      "city": "Thomasville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Thomasville, Georgia"
    },
    {
      "city": "Meigs",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Meigs, Georgia"
    },
    {
      "city": "Ochlocknee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ochlocknee, Georgia"
    },
    {
      "city": "Pavo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pavo, Georgia"
    },
    {
      "city": "Brookfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Brookfield, Georgia"
    },
    {
      "city": "Chula",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chula, Georgia"
    },
    {
      "city": "Omega",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Omega, Georgia"
    },
    {
      "city": "Tifton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tifton, Georgia"
    },
    {
      "city": "Ty Ty",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ty Ty, Georgia"
    },
    {
      "city": "Lyons",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lyons, Georgia"
    },
    {
      "city": "Vidalia",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Vidalia, Georgia"
    },
    {
      "city": "Hiawassee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hiawassee, Georgia"
    },
    {
      "city": "Young Harris",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Young Harris, Georgia"
    },
    {
      "city": "Soperton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Soperton, Georgia"
    },
    {
      "city": "Hogansville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Hogansville, Georgia"
    },
    {
      "city": "Lagrange",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lagrange, Georgia"
    },
    {
      "city": "West Point",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "West Point, Georgia"
    },
    {
      "city": "Ashburn",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Ashburn, Georgia"
    },
    {
      "city": "Rebecca",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rebecca, Georgia"
    },
    {
      "city": "Sycamore",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sycamore, Georgia"
    },
    {
      "city": "Danville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Danville, Georgia"
    },
    {
      "city": "Dry Branch",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dry Branch, Georgia"
    },
    {
      "city": "Jeffersonville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jeffersonville, Georgia"
    },
    {
      "city": "Blairsville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Blairsville, Georgia"
    },
    {
      "city": "Suches",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Suches, Georgia"
    },
    {
      "city": "The Rock",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "The Rock, Georgia"
    },
    {
      "city": "Thomaston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Thomaston, Georgia"
    },
    {
      "city": "Yatesville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Yatesville, Georgia"
    },
    {
      "city": "Chickamauga",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Chickamauga, Georgia"
    },
    {
      "city": "Flintstone",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Flintstone, Georgia"
    },
    {
      "city": "La Fayette",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "La Fayette, Georgia"
    },
    {
      "city": "Rock Spring",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rock Spring, Georgia"
    },
    {
      "city": "Rossville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rossville, Georgia"
    },
    {
      "city": "Lookout Mountain",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Lookout Mountain, Georgia"
    },
    {
      "city": "Jersey",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jersey, Georgia"
    },
    {
      "city": "Social Circle",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Social Circle, Georgia"
    },
    {
      "city": "Good Hope",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Good Hope, Georgia"
    },
    {
      "city": "Monroe",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Monroe, Georgia"
    },
    {
      "city": "Waycross",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waycross, Georgia"
    },
    {
      "city": "Manor",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Manor, Georgia"
    },
    {
      "city": "Millwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Millwood, Georgia"
    },
    {
      "city": "Waresboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Waresboro, Georgia"
    },
    {
      "city": "Camak",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Camak, Georgia"
    },
    {
      "city": "Mesena",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mesena, Georgia"
    },
    {
      "city": "Norwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Norwood, Georgia"
    },
    {
      "city": "Warrenton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Warrenton, Georgia"
    },
    {
      "city": "Jewell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jewell, Georgia"
    },
    {
      "city": "Davisboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Davisboro, Georgia"
    },
    {
      "city": "Harrison",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Harrison, Georgia"
    },
    {
      "city": "Oconee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oconee, Georgia"
    },
    {
      "city": "Sandersville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sandersville, Georgia"
    },
    {
      "city": "Tennille",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tennille, Georgia"
    },
    {
      "city": "Warthen",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Warthen, Georgia"
    },
    {
      "city": "Jesup",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Jesup, Georgia"
    },
    {
      "city": "Odum",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Odum, Georgia"
    },
    {
      "city": "Screven",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Screven, Georgia"
    },
    {
      "city": "Preston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Preston, Georgia"
    },
    {
      "city": "Weston",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Weston, Georgia"
    },
    {
      "city": "Parrott",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Parrott, Georgia"
    },
    {
      "city": "Alamo",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Alamo, Georgia"
    },
    {
      "city": "Glenwood",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Glenwood, Georgia"
    },
    {
      "city": "Cleveland",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cleveland, Georgia"
    },
    {
      "city": "Helen",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Helen, Georgia"
    },
    {
      "city": "Sautee Nacoochee",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sautee Nacoochee, Georgia"
    },
    {
      "city": "Cohutta",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Cohutta, Georgia"
    },
    {
      "city": "Dalton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Dalton, Georgia"
    },
    {
      "city": "Rocky Face",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rocky Face, Georgia"
    },
    {
      "city": "Tunnel Hill",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tunnel Hill, Georgia"
    },
    {
      "city": "Varnell",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Varnell, Georgia"
    },
    {
      "city": "Abbeville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Abbeville, Georgia"
    },
    {
      "city": "Pineview",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pineview, Georgia"
    },
    {
      "city": "Pitts",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Pitts, Georgia"
    },
    {
      "city": "Rochelle",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rochelle, Georgia"
    },
    {
      "city": "Seville",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Seville, Georgia"
    },
    {
      "city": "Rayle",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Rayle, Georgia"
    },
    {
      "city": "Tignall",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Tignall, Georgia"
    },
    {
      "city": "Washington",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Washington, Georgia"
    },
    {
      "city": "Allentown",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Allentown, Georgia"
    },
    {
      "city": "Gordon",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Gordon, Georgia"
    },
    {
      "city": "Irwinton",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Irwinton, Georgia"
    },
    {
      "city": "Mc Intyre",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Mc Intyre, Georgia"
    },
    {
      "city": "Toomsboro",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Toomsboro, Georgia"
    },
    {
      "city": "Oakfield",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Oakfield, Georgia"
    },
    {
      "city": "Poulan",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Poulan, Georgia"
    },
    {
      "city": "Sumner",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sumner, Georgia"
    },
    {
      "city": "Sylvester",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Sylvester, Georgia"
    },
    {
      "city": "Warwick",
      "state": "Georgia",
      "shortState": "GA",
      "formatedCity": "Warwick, Georgia"
    },
    {
      "city": "Captain Cook",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Captain Cook, Hawaii"
    },
    {
      "city": "Hakalau",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hakalau, Hawaii"
    },
    {
      "city": "Hawaii National Park",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hawaii National Park, Hawaii"
    },
    {
      "city": "Hawi",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hawi, Hawaii"
    },
    {
      "city": "Hilo",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hilo, Hawaii"
    },
    {
      "city": "Holualoa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Holualoa, Hawaii"
    },
    {
      "city": "Honaunau",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Honaunau, Hawaii"
    },
    {
      "city": "Honokaa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Honokaa, Hawaii"
    },
    {
      "city": "Honomu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Honomu, Hawaii"
    },
    {
      "city": "Ocean View",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Ocean View, Hawaii"
    },
    {
      "city": "Waikoloa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waikoloa, Hawaii"
    },
    {
      "city": "Keauhou",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Keauhou, Hawaii"
    },
    {
      "city": "Kailua Kona",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kailua Kona, Hawaii"
    },
    {
      "city": "Kamuela",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kamuela, Hawaii"
    },
    {
      "city": "Keaau",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Keaau, Hawaii"
    },
    {
      "city": "Kealakekua",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kealakekua, Hawaii"
    },
    {
      "city": "Kapaau",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kapaau, Hawaii"
    },
    {
      "city": "Kurtistown",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kurtistown, Hawaii"
    },
    {
      "city": "Laupahoehoe",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Laupahoehoe, Hawaii"
    },
    {
      "city": "Mountain View",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Mountain View, Hawaii"
    },
    {
      "city": "Naalehu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Naalehu, Hawaii"
    },
    {
      "city": "Ninole",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Ninole, Hawaii"
    },
    {
      "city": "Ookala",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Ookala, Hawaii"
    },
    {
      "city": "Paauilo",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Paauilo, Hawaii"
    },
    {
      "city": "Pahala",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Pahala, Hawaii"
    },
    {
      "city": "Pahoa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Pahoa, Hawaii"
    },
    {
      "city": "Papaaloa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Papaaloa, Hawaii"
    },
    {
      "city": "Papaikou",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Papaikou, Hawaii"
    },
    {
      "city": "Pepeekeo",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Pepeekeo, Hawaii"
    },
    {
      "city": "Volcano",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Volcano, Hawaii"
    },
    {
      "city": "Aiea",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Aiea, Hawaii"
    },
    {
      "city": "Ewa Beach",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Ewa Beach, Hawaii"
    },
    {
      "city": "Kapolei",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kapolei, Hawaii"
    },
    {
      "city": "Haleiwa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Haleiwa, Hawaii"
    },
    {
      "city": "Hauula",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hauula, Hawaii"
    },
    {
      "city": "Kaaawa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kaaawa, Hawaii"
    },
    {
      "city": "Kahuku",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kahuku, Hawaii"
    },
    {
      "city": "Kailua",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kailua, Hawaii"
    },
    {
      "city": "Kaneohe",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kaneohe, Hawaii"
    },
    {
      "city": "Kunia",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kunia, Hawaii"
    },
    {
      "city": "Laie",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Laie, Hawaii"
    },
    {
      "city": "Pearl City",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Pearl City, Hawaii"
    },
    {
      "city": "Wahiawa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Wahiawa, Hawaii"
    },
    {
      "city": "Mililani",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Mililani, Hawaii"
    },
    {
      "city": "Waialua",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waialua, Hawaii"
    },
    {
      "city": "Waianae",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waianae, Hawaii"
    },
    {
      "city": "Waimanalo",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waimanalo, Hawaii"
    },
    {
      "city": "Waipahu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waipahu, Hawaii"
    },
    {
      "city": "Honolulu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Honolulu, Hawaii"
    },
    {
      "city": "Jbphh",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Jbphh, Hawaii"
    },
    {
      "city": "Wheeler Army Airfield",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Wheeler Army Airfield, Hawaii"
    },
    {
      "city": "Schofield Barracks",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Schofield Barracks, Hawaii"
    },
    {
      "city": "Fort Shafter",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Fort Shafter, Hawaii"
    },
    {
      "city": "Tripler Army Medical Center",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Tripler Army Medical Center, Hawaii"
    },
    {
      "city": "Camp H M Smith",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Camp H M Smith, Hawaii"
    },
    {
      "city": "Mcbh Kaneohe Bay",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Mcbh Kaneohe Bay, Hawaii"
    },
    {
      "city": "Wake Island",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Wake Island, Hawaii"
    },
    {
      "city": "Anahola",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Anahola, Hawaii"
    },
    {
      "city": "Eleele",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Eleele, Hawaii"
    },
    {
      "city": "Hanalei",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hanalei, Hawaii"
    },
    {
      "city": "Hanamaulu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hanamaulu, Hawaii"
    },
    {
      "city": "Hanapepe",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hanapepe, Hawaii"
    },
    {
      "city": "Princeville",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Princeville, Hawaii"
    },
    {
      "city": "Kalaheo",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kalaheo, Hawaii"
    },
    {
      "city": "Kapaa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kapaa, Hawaii"
    },
    {
      "city": "Kaumakani",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kaumakani, Hawaii"
    },
    {
      "city": "Kealia",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kealia, Hawaii"
    },
    {
      "city": "Kekaha",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kekaha, Hawaii"
    },
    {
      "city": "Kilauea",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kilauea, Hawaii"
    },
    {
      "city": "Koloa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Koloa, Hawaii"
    },
    {
      "city": "Lawai",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Lawai, Hawaii"
    },
    {
      "city": "Lihue",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Lihue, Hawaii"
    },
    {
      "city": "Makaweli",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Makaweli, Hawaii"
    },
    {
      "city": "Waimea",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Waimea, Hawaii"
    },
    {
      "city": "Haiku",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Haiku, Hawaii"
    },
    {
      "city": "Hana",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hana, Hawaii"
    },
    {
      "city": "Hoolehua",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Hoolehua, Hawaii"
    },
    {
      "city": "Kahului",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kahului, Hawaii"
    },
    {
      "city": "Kalaupapa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kalaupapa, Hawaii"
    },
    {
      "city": "Kaunakakai",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kaunakakai, Hawaii"
    },
    {
      "city": "Kihei",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kihei, Hawaii"
    },
    {
      "city": "Kualapuu",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kualapuu, Hawaii"
    },
    {
      "city": "Lahaina",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Lahaina, Hawaii"
    },
    {
      "city": "Lanai City",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Lanai City, Hawaii"
    },
    {
      "city": "Makawao",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Makawao, Hawaii"
    },
    {
      "city": "Maunaloa",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Maunaloa, Hawaii"
    },
    {
      "city": "Paia",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Paia, Hawaii"
    },
    {
      "city": "Puunene",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Puunene, Hawaii"
    },
    {
      "city": "Pukalani",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Pukalani, Hawaii"
    },
    {
      "city": "Kula",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Kula, Hawaii"
    },
    {
      "city": "Wailuku",
      "state": "Hawaii",
      "shortState": "HI",
      "formatedCity": "Wailuku, Hawaii"
    },
    {
      "city": "Adair",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Adair, Iowa"
    },
    {
      "city": "Bridgewater",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bridgewater, Iowa"
    },
    {
      "city": "Fontanelle",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fontanelle, Iowa"
    },
    {
      "city": "Greenfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Greenfield, Iowa"
    },
    {
      "city": "Orient",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Orient, Iowa"
    },
    {
      "city": "Carbon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carbon, Iowa"
    },
    {
      "city": "Corning",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Corning, Iowa"
    },
    {
      "city": "Nodaway",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nodaway, Iowa"
    },
    {
      "city": "Prescott",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Prescott, Iowa"
    },
    {
      "city": "Dorchester",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dorchester, Iowa"
    },
    {
      "city": "Harpers Ferry",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harpers Ferry, Iowa"
    },
    {
      "city": "Lansing",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lansing, Iowa"
    },
    {
      "city": "New Albin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Albin, Iowa"
    },
    {
      "city": "Postville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Postville, Iowa"
    },
    {
      "city": "Waterville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waterville, Iowa"
    },
    {
      "city": "Waukon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waukon, Iowa"
    },
    {
      "city": "Centerville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Centerville, Iowa"
    },
    {
      "city": "Cincinnati",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cincinnati, Iowa"
    },
    {
      "city": "Exline",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Exline, Iowa"
    },
    {
      "city": "Moravia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moravia, Iowa"
    },
    {
      "city": "Moulton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moulton, Iowa"
    },
    {
      "city": "Mystic",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mystic, Iowa"
    },
    {
      "city": "Plano",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Plano, Iowa"
    },
    {
      "city": "Udell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Udell, Iowa"
    },
    {
      "city": "Unionville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Unionville, Iowa"
    },
    {
      "city": "Audubon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Audubon, Iowa"
    },
    {
      "city": "Brayton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Brayton, Iowa"
    },
    {
      "city": "Exira",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Exira, Iowa"
    },
    {
      "city": "Gray",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gray, Iowa"
    },
    {
      "city": "Hamlin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hamlin, Iowa"
    },
    {
      "city": "Kimballton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kimballton, Iowa"
    },
    {
      "city": "Atkins",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Atkins, Iowa"
    },
    {
      "city": "Belle Plaine",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Belle Plaine, Iowa"
    },
    {
      "city": "Blairstown",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blairstown, Iowa"
    },
    {
      "city": "Garrison",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garrison, Iowa"
    },
    {
      "city": "Keystone",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Keystone, Iowa"
    },
    {
      "city": "Luzerne",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Luzerne, Iowa"
    },
    {
      "city": "Mount Auburn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Auburn, Iowa"
    },
    {
      "city": "Newhall",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Newhall, Iowa"
    },
    {
      "city": "Norway",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Norway, Iowa"
    },
    {
      "city": "Shellsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shellsburg, Iowa"
    },
    {
      "city": "Urbana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Urbana, Iowa"
    },
    {
      "city": "Van Horne",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Van Horne, Iowa"
    },
    {
      "city": "Vinton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Vinton, Iowa"
    },
    {
      "city": "Walford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Walford, Iowa"
    },
    {
      "city": "Watkins",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Watkins, Iowa"
    },
    {
      "city": "Cedar Falls",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cedar Falls, Iowa"
    },
    {
      "city": "Dewar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dewar, Iowa"
    },
    {
      "city": "Dunkerton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dunkerton, Iowa"
    },
    {
      "city": "Gilbertville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gilbertville, Iowa"
    },
    {
      "city": "Hudson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hudson, Iowa"
    },
    {
      "city": "La Porte City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "La Porte City, Iowa"
    },
    {
      "city": "Raymond",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Raymond, Iowa"
    },
    {
      "city": "Waterloo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waterloo, Iowa"
    },
    {
      "city": "Evansdale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Evansdale, Iowa"
    },
    {
      "city": "Beaver",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Beaver, Iowa"
    },
    {
      "city": "Boone",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Boone, Iowa"
    },
    {
      "city": "Boxholm",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Boxholm, Iowa"
    },
    {
      "city": "Luther",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Luther, Iowa"
    },
    {
      "city": "Madrid",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Madrid, Iowa"
    },
    {
      "city": "Ogden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ogden, Iowa"
    },
    {
      "city": "Pilot Mound",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pilot Mound, Iowa"
    },
    {
      "city": "Denver",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Denver, Iowa"
    },
    {
      "city": "Frederika",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Frederika, Iowa"
    },
    {
      "city": "Janesville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Janesville, Iowa"
    },
    {
      "city": "Plainfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Plainfield, Iowa"
    },
    {
      "city": "Readlyn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Readlyn, Iowa"
    },
    {
      "city": "Sumner",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sumner, Iowa"
    },
    {
      "city": "Tripoli",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tripoli, Iowa"
    },
    {
      "city": "Waverly",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waverly, Iowa"
    },
    {
      "city": "Aurora",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Aurora, Iowa"
    },
    {
      "city": "Fairbank",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fairbank, Iowa"
    },
    {
      "city": "Hazleton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hazleton, Iowa"
    },
    {
      "city": "Independence",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Independence, Iowa"
    },
    {
      "city": "Jesup",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Jesup, Iowa"
    },
    {
      "city": "Lamont",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lamont, Iowa"
    },
    {
      "city": "Stanley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stanley, Iowa"
    },
    {
      "city": "Winthrop",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Winthrop, Iowa"
    },
    {
      "city": "Brandon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Brandon, Iowa"
    },
    {
      "city": "Quasqueton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Quasqueton, Iowa"
    },
    {
      "city": "Rowley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rowley, Iowa"
    },
    {
      "city": "Albert City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Albert City, Iowa"
    },
    {
      "city": "Marathon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marathon, Iowa"
    },
    {
      "city": "Newell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Newell, Iowa"
    },
    {
      "city": "Rembrandt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rembrandt, Iowa"
    },
    {
      "city": "Sioux Rapids",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sioux Rapids, Iowa"
    },
    {
      "city": "Storm Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Storm Lake, Iowa"
    },
    {
      "city": "Truesdale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Truesdale, Iowa"
    },
    {
      "city": "Alta",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alta, Iowa"
    },
    {
      "city": "Linn Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Linn Grove, Iowa"
    },
    {
      "city": "Allison",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Allison, Iowa"
    },
    {
      "city": "Aplington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Aplington, Iowa"
    },
    {
      "city": "Aredale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Aredale, Iowa"
    },
    {
      "city": "Austinville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Austinville, Iowa"
    },
    {
      "city": "Bristow",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bristow, Iowa"
    },
    {
      "city": "Clarksville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clarksville, Iowa"
    },
    {
      "city": "Dumont",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dumont, Iowa"
    },
    {
      "city": "Greene",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Greene, Iowa"
    },
    {
      "city": "Kesley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kesley, Iowa"
    },
    {
      "city": "New Hartford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Hartford, Iowa"
    },
    {
      "city": "Parkersburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Parkersburg, Iowa"
    },
    {
      "city": "Shell Rock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shell Rock, Iowa"
    },
    {
      "city": "Farnhamville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Farnhamville, Iowa"
    },
    {
      "city": "Jolley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Jolley, Iowa"
    },
    {
      "city": "Knierim",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Knierim, Iowa"
    },
    {
      "city": "Lytton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lytton, Iowa"
    },
    {
      "city": "Manson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Manson, Iowa"
    },
    {
      "city": "Pomeroy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pomeroy, Iowa"
    },
    {
      "city": "Rockwell City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rockwell City, Iowa"
    },
    {
      "city": "Somers",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Somers, Iowa"
    },
    {
      "city": "Lake City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lake City, Iowa"
    },
    {
      "city": "Lohrville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lohrville, Iowa"
    },
    {
      "city": "Coon Rapids",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Coon Rapids, Iowa"
    },
    {
      "city": "Carroll",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carroll, Iowa"
    },
    {
      "city": "Arcadia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arcadia, Iowa"
    },
    {
      "city": "Breda",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Breda, Iowa"
    },
    {
      "city": "Dedham",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dedham, Iowa"
    },
    {
      "city": "Glidden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Glidden, Iowa"
    },
    {
      "city": "Halbur",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Halbur, Iowa"
    },
    {
      "city": "Lanesboro",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lanesboro, Iowa"
    },
    {
      "city": "Lidderdale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lidderdale, Iowa"
    },
    {
      "city": "Manning",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Manning, Iowa"
    },
    {
      "city": "Ralston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ralston, Iowa"
    },
    {
      "city": "Templeton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Templeton, Iowa"
    },
    {
      "city": "Anita",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Anita, Iowa"
    },
    {
      "city": "Atlantic",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Atlantic, Iowa"
    },
    {
      "city": "Wiota",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wiota, Iowa"
    },
    {
      "city": "Cumberland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cumberland, Iowa"
    },
    {
      "city": "Massena",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Massena, Iowa"
    },
    {
      "city": "Griswold",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Griswold, Iowa"
    },
    {
      "city": "Lewis",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lewis, Iowa"
    },
    {
      "city": "Marne",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marne, Iowa"
    },
    {
      "city": "Clarence",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clarence, Iowa"
    },
    {
      "city": "Lowden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lowden, Iowa"
    },
    {
      "city": "Mechanicsville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mechanicsville, Iowa"
    },
    {
      "city": "Stanwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stanwood, Iowa"
    },
    {
      "city": "West Branch",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Branch, Iowa"
    },
    {
      "city": "Bennett",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bennett, Iowa"
    },
    {
      "city": "Durant",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Durant, Iowa"
    },
    {
      "city": "Tipton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tipton, Iowa"
    },
    {
      "city": "Mason City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mason City, Iowa"
    },
    {
      "city": "Clear Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clear Lake, Iowa"
    },
    {
      "city": "Dougherty",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dougherty, Iowa"
    },
    {
      "city": "Meservey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Meservey, Iowa"
    },
    {
      "city": "Plymouth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Plymouth, Iowa"
    },
    {
      "city": "Rock Falls",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rock Falls, Iowa"
    },
    {
      "city": "Rockwell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rockwell, Iowa"
    },
    {
      "city": "Swaledale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Swaledale, Iowa"
    },
    {
      "city": "Thornton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thornton, Iowa"
    },
    {
      "city": "Ventura",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ventura, Iowa"
    },
    {
      "city": "Aurelia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Aurelia, Iowa"
    },
    {
      "city": "Cherokee",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cherokee, Iowa"
    },
    {
      "city": "Cleghorn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cleghorn, Iowa"
    },
    {
      "city": "Larrabee",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Larrabee, Iowa"
    },
    {
      "city": "Marcus",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marcus, Iowa"
    },
    {
      "city": "Meriden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Meriden, Iowa"
    },
    {
      "city": "Quimby",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Quimby, Iowa"
    },
    {
      "city": "Washta",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Washta, Iowa"
    },
    {
      "city": "Alta Vista",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alta Vista, Iowa"
    },
    {
      "city": "Fredericksburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fredericksburg, Iowa"
    },
    {
      "city": "Ionia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ionia, Iowa"
    },
    {
      "city": "Nashua",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nashua, Iowa"
    },
    {
      "city": "New Hampton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Hampton, Iowa"
    },
    {
      "city": "North Washington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "North Washington, Iowa"
    },
    {
      "city": "Lawler",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lawler, Iowa"
    },
    {
      "city": "Murray",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Murray, Iowa"
    },
    {
      "city": "Osceola",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Osceola, Iowa"
    },
    {
      "city": "Woodburn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Woodburn, Iowa"
    },
    {
      "city": "Peterson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Peterson, Iowa"
    },
    {
      "city": "Spencer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Spencer, Iowa"
    },
    {
      "city": "Dickens",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dickens, Iowa"
    },
    {
      "city": "Everly",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Everly, Iowa"
    },
    {
      "city": "Fostoria",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fostoria, Iowa"
    },
    {
      "city": "Gillett Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gillett Grove, Iowa"
    },
    {
      "city": "Greenville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Greenville, Iowa"
    },
    {
      "city": "Royal",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Royal, Iowa"
    },
    {
      "city": "Webb",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Webb, Iowa"
    },
    {
      "city": "Edgewood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Edgewood, Iowa"
    },
    {
      "city": "Elkader",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elkader, Iowa"
    },
    {
      "city": "Elkport",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elkport, Iowa"
    },
    {
      "city": "Farmersburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Farmersburg, Iowa"
    },
    {
      "city": "Garber",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garber, Iowa"
    },
    {
      "city": "Garnavillo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garnavillo, Iowa"
    },
    {
      "city": "Guttenberg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Guttenberg, Iowa"
    },
    {
      "city": "North Buena Vista",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "North Buena Vista, Iowa"
    },
    {
      "city": "Saint Olaf",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Olaf, Iowa"
    },
    {
      "city": "Strawberry Point",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Strawberry Point, Iowa"
    },
    {
      "city": "Volga",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Volga, Iowa"
    },
    {
      "city": "Luana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Luana, Iowa"
    },
    {
      "city": "Mc Gregor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mc Gregor, Iowa"
    },
    {
      "city": "Marquette",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marquette, Iowa"
    },
    {
      "city": "Monona",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Monona, Iowa"
    },
    {
      "city": "Delmar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Delmar, Iowa"
    },
    {
      "city": "Lost Nation",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lost Nation, Iowa"
    },
    {
      "city": "Andover",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Andover, Iowa"
    },
    {
      "city": "Bryant",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bryant, Iowa"
    },
    {
      "city": "Calamus",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Calamus, Iowa"
    },
    {
      "city": "Camanche",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Camanche, Iowa"
    },
    {
      "city": "Charlotte",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Charlotte, Iowa"
    },
    {
      "city": "Clinton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clinton, Iowa"
    },
    {
      "city": "De Witt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "De Witt, Iowa"
    },
    {
      "city": "Goose Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Goose Lake, Iowa"
    },
    {
      "city": "Grand Mound",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grand Mound, Iowa"
    },
    {
      "city": "Low Moor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Low Moor, Iowa"
    },
    {
      "city": "Teeds Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Teeds Grove, Iowa"
    },
    {
      "city": "Welton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Welton, Iowa"
    },
    {
      "city": "Wheatland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wheatland, Iowa"
    },
    {
      "city": "Aspinwall",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Aspinwall, Iowa"
    },
    {
      "city": "Charter Oak",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Charter Oak, Iowa"
    },
    {
      "city": "Deloit",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Deloit, Iowa"
    },
    {
      "city": "Denison",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Denison, Iowa"
    },
    {
      "city": "Kiron",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kiron, Iowa"
    },
    {
      "city": "Manilla",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Manilla, Iowa"
    },
    {
      "city": "Ricketts",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ricketts, Iowa"
    },
    {
      "city": "Schleswig",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Schleswig, Iowa"
    },
    {
      "city": "Vail",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Vail, Iowa"
    },
    {
      "city": "Westside",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Westside, Iowa"
    },
    {
      "city": "Arion",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arion, Iowa"
    },
    {
      "city": "Dow City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dow City, Iowa"
    },
    {
      "city": "Adel",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Adel, Iowa"
    },
    {
      "city": "Booneville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Booneville, Iowa"
    },
    {
      "city": "Bouton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bouton, Iowa"
    },
    {
      "city": "Dallas Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dallas Center, Iowa"
    },
    {
      "city": "Dawson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dawson, Iowa"
    },
    {
      "city": "De Soto",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "De Soto, Iowa"
    },
    {
      "city": "Dexter",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dexter, Iowa"
    },
    {
      "city": "Granger",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Granger, Iowa"
    },
    {
      "city": "Linden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Linden, Iowa"
    },
    {
      "city": "Minburn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Minburn, Iowa"
    },
    {
      "city": "Perry",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Perry, Iowa"
    },
    {
      "city": "Redfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Redfield, Iowa"
    },
    {
      "city": "Van Meter",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Van Meter, Iowa"
    },
    {
      "city": "Waukee",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waukee, Iowa"
    },
    {
      "city": "Woodward",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Woodward, Iowa"
    },
    {
      "city": "Bloomfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bloomfield, Iowa"
    },
    {
      "city": "Drakesville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Drakesville, Iowa"
    },
    {
      "city": "Floris",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Floris, Iowa"
    },
    {
      "city": "Pulaski",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pulaski, Iowa"
    },
    {
      "city": "Davis City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Davis City, Iowa"
    },
    {
      "city": "Decatur",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Decatur, Iowa"
    },
    {
      "city": "Garden Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garden Grove, Iowa"
    },
    {
      "city": "Grand River",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grand River, Iowa"
    },
    {
      "city": "Lamoni",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lamoni, Iowa"
    },
    {
      "city": "Leon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Leon, Iowa"
    },
    {
      "city": "Van Wert",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Van Wert, Iowa"
    },
    {
      "city": "Weldon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Weldon, Iowa"
    },
    {
      "city": "Masonville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Masonville, Iowa"
    },
    {
      "city": "Colesburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Colesburg, Iowa"
    },
    {
      "city": "Delaware",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Delaware, Iowa"
    },
    {
      "city": "Dundee",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dundee, Iowa"
    },
    {
      "city": "Earlville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Earlville, Iowa"
    },
    {
      "city": "Greeley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Greeley, Iowa"
    },
    {
      "city": "Manchester",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Manchester, Iowa"
    },
    {
      "city": "Delhi",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Delhi, Iowa"
    },
    {
      "city": "Hopkinton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hopkinton, Iowa"
    },
    {
      "city": "Ryan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ryan, Iowa"
    },
    {
      "city": "Burlington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Burlington, Iowa"
    },
    {
      "city": "Danville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Danville, Iowa"
    },
    {
      "city": "Mediapolis",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mediapolis, Iowa"
    },
    {
      "city": "Middletown",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Middletown, Iowa"
    },
    {
      "city": "Sperry",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sperry, Iowa"
    },
    {
      "city": "West Burlington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Burlington, Iowa"
    },
    {
      "city": "Yarmouth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Yarmouth, Iowa"
    },
    {
      "city": "Arnolds Park",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arnolds Park, Iowa"
    },
    {
      "city": "Lake Park",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lake Park, Iowa"
    },
    {
      "city": "Milford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Milford, Iowa"
    },
    {
      "city": "Okoboji",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Okoboji, Iowa"
    },
    {
      "city": "Spirit Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Spirit Lake, Iowa"
    },
    {
      "city": "Superior",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Superior, Iowa"
    },
    {
      "city": "Terril",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Terril, Iowa"
    },
    {
      "city": "Dubuque",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dubuque, Iowa"
    },
    {
      "city": "Bernard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bernard, Iowa"
    },
    {
      "city": "Cascade",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cascade, Iowa"
    },
    {
      "city": "Durango",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Durango, Iowa"
    },
    {
      "city": "Dyersville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dyersville, Iowa"
    },
    {
      "city": "Epworth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Epworth, Iowa"
    },
    {
      "city": "Farley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Farley, Iowa"
    },
    {
      "city": "Holy Cross",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Holy Cross, Iowa"
    },
    {
      "city": "Luxemburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Luxemburg, Iowa"
    },
    {
      "city": "New Vienna",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Vienna, Iowa"
    },
    {
      "city": "Peosta",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Peosta, Iowa"
    },
    {
      "city": "Sherrill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sherrill, Iowa"
    },
    {
      "city": "Worthington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Worthington, Iowa"
    },
    {
      "city": "Zwingle",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Zwingle, Iowa"
    },
    {
      "city": "Armstrong",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Armstrong, Iowa"
    },
    {
      "city": "Dolliver",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dolliver, Iowa"
    },
    {
      "city": "Ringsted",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ringsted, Iowa"
    },
    {
      "city": "Estherville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Estherville, Iowa"
    },
    {
      "city": "Wallingford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wallingford, Iowa"
    },
    {
      "city": "Arlington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arlington, Iowa"
    },
    {
      "city": "Maynard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Maynard, Iowa"
    },
    {
      "city": "Oelwein",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oelwein, Iowa"
    },
    {
      "city": "Oran",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oran, Iowa"
    },
    {
      "city": "Westgate",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Westgate, Iowa"
    },
    {
      "city": "Clermont",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clermont, Iowa"
    },
    {
      "city": "Elgin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elgin, Iowa"
    },
    {
      "city": "Fayette",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fayette, Iowa"
    },
    {
      "city": "Hawkeye",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hawkeye, Iowa"
    },
    {
      "city": "Randalia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Randalia, Iowa"
    },
    {
      "city": "Saint Lucas",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Lucas, Iowa"
    },
    {
      "city": "Wadena",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wadena, Iowa"
    },
    {
      "city": "Waucoma",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Waucoma, Iowa"
    },
    {
      "city": "West Union",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Union, Iowa"
    },
    {
      "city": "Floyd",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Floyd, Iowa"
    },
    {
      "city": "Nora Springs",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nora Springs, Iowa"
    },
    {
      "city": "Rockford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rockford, Iowa"
    },
    {
      "city": "Rudd",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rudd, Iowa"
    },
    {
      "city": "Charles City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Charles City, Iowa"
    },
    {
      "city": "Colwell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Colwell, Iowa"
    },
    {
      "city": "Marble Rock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marble Rock, Iowa"
    },
    {
      "city": "Bradford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bradford, Iowa"
    },
    {
      "city": "Popejoy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Popejoy, Iowa"
    },
    {
      "city": "Alexander",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alexander, Iowa"
    },
    {
      "city": "Chapin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chapin, Iowa"
    },
    {
      "city": "Coulter",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Coulter, Iowa"
    },
    {
      "city": "Hampton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hampton, Iowa"
    },
    {
      "city": "Latimer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Latimer, Iowa"
    },
    {
      "city": "Sheffield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sheffield, Iowa"
    },
    {
      "city": "Geneva",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Geneva, Iowa"
    },
    {
      "city": "Farragut",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Farragut, Iowa"
    },
    {
      "city": "Hamburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hamburg, Iowa"
    },
    {
      "city": "Imogene",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Imogene, Iowa"
    },
    {
      "city": "Percival",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Percival, Iowa"
    },
    {
      "city": "Randolph",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Randolph, Iowa"
    },
    {
      "city": "Riverton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Riverton, Iowa"
    },
    {
      "city": "Sidney",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sidney, Iowa"
    },
    {
      "city": "Tabor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tabor, Iowa"
    },
    {
      "city": "Thurman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thurman, Iowa"
    },
    {
      "city": "Churdan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Churdan, Iowa"
    },
    {
      "city": "Cooper",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cooper, Iowa"
    },
    {
      "city": "Dana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dana, Iowa"
    },
    {
      "city": "Grand Junction",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grand Junction, Iowa"
    },
    {
      "city": "Jefferson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Jefferson, Iowa"
    },
    {
      "city": "Paton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Paton, Iowa"
    },
    {
      "city": "Rippey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rippey, Iowa"
    },
    {
      "city": "Scranton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Scranton, Iowa"
    },
    {
      "city": "Beaman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Beaman, Iowa"
    },
    {
      "city": "Conrad",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Conrad, Iowa"
    },
    {
      "city": "Dike",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dike, Iowa"
    },
    {
      "city": "Grundy Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grundy Center, Iowa"
    },
    {
      "city": "Holland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Holland, Iowa"
    },
    {
      "city": "Morrison",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Morrison, Iowa"
    },
    {
      "city": "Reinbeck",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Reinbeck, Iowa"
    },
    {
      "city": "Stout",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stout, Iowa"
    },
    {
      "city": "Wellsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wellsburg, Iowa"
    },
    {
      "city": "Bagley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bagley, Iowa"
    },
    {
      "city": "Bayard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bayard, Iowa"
    },
    {
      "city": "Casey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Casey, Iowa"
    },
    {
      "city": "Guthrie Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Guthrie Center, Iowa"
    },
    {
      "city": "Jamaica",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Jamaica, Iowa"
    },
    {
      "city": "Menlo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Menlo, Iowa"
    },
    {
      "city": "Panora",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Panora, Iowa"
    },
    {
      "city": "Stuart",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stuart, Iowa"
    },
    {
      "city": "Yale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Yale, Iowa"
    },
    {
      "city": "Blairsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blairsburg, Iowa"
    },
    {
      "city": "Ellsworth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ellsworth, Iowa"
    },
    {
      "city": "Jewell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Jewell, Iowa"
    },
    {
      "city": "Kamrar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kamrar, Iowa"
    },
    {
      "city": "Randall",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Randall, Iowa"
    },
    {
      "city": "Stanhope",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stanhope, Iowa"
    },
    {
      "city": "Stratford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stratford, Iowa"
    },
    {
      "city": "Williams",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Williams, Iowa"
    },
    {
      "city": "Webster City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Webster City, Iowa"
    },
    {
      "city": "Britt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Britt, Iowa"
    },
    {
      "city": "Corwith",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Corwith, Iowa"
    },
    {
      "city": "Crystal Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Crystal Lake, Iowa"
    },
    {
      "city": "Garner",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garner, Iowa"
    },
    {
      "city": "Goodell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Goodell, Iowa"
    },
    {
      "city": "Kanawha",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kanawha, Iowa"
    },
    {
      "city": "Klemme",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Klemme, Iowa"
    },
    {
      "city": "Woden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Woden, Iowa"
    },
    {
      "city": "Alden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alden, Iowa"
    },
    {
      "city": "Buckeye",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Buckeye, Iowa"
    },
    {
      "city": "Garden City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garden City, Iowa"
    },
    {
      "city": "Hubbard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hubbard, Iowa"
    },
    {
      "city": "Iowa Falls",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Iowa Falls, Iowa"
    },
    {
      "city": "New Providence",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Providence, Iowa"
    },
    {
      "city": "Radcliffe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Radcliffe, Iowa"
    },
    {
      "city": "Union",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Union, Iowa"
    },
    {
      "city": "Gifford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gifford, Iowa"
    },
    {
      "city": "Whitten",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Whitten, Iowa"
    },
    {
      "city": "Ackley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ackley, Iowa"
    },
    {
      "city": "Eldora",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Eldora, Iowa"
    },
    {
      "city": "Steamboat Rock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Steamboat Rock, Iowa"
    },
    {
      "city": "Dunlap",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dunlap, Iowa"
    },
    {
      "city": "Little Sioux",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Little Sioux, Iowa"
    },
    {
      "city": "Logan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Logan, Iowa"
    },
    {
      "city": "Magnolia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Magnolia, Iowa"
    },
    {
      "city": "Missouri Valley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Missouri Valley, Iowa"
    },
    {
      "city": "Modale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Modale, Iowa"
    },
    {
      "city": "Mondamin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mondamin, Iowa"
    },
    {
      "city": "Persia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Persia, Iowa"
    },
    {
      "city": "Pisgah",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pisgah, Iowa"
    },
    {
      "city": "Woodbine",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Woodbine, Iowa"
    },
    {
      "city": "Hillsboro",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hillsboro, Iowa"
    },
    {
      "city": "Mount Pleasant",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Pleasant, Iowa"
    },
    {
      "city": "Rome",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rome, Iowa"
    },
    {
      "city": "Mount Union",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Union, Iowa"
    },
    {
      "city": "New London",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New London, Iowa"
    },
    {
      "city": "Olds",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Olds, Iowa"
    },
    {
      "city": "Salem",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Salem, Iowa"
    },
    {
      "city": "Swedesburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Swedesburg, Iowa"
    },
    {
      "city": "Wayland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wayland, Iowa"
    },
    {
      "city": "Winfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Winfield, Iowa"
    },
    {
      "city": "Riceville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Riceville, Iowa"
    },
    {
      "city": "Elma",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elma, Iowa"
    },
    {
      "city": "Chester",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chester, Iowa"
    },
    {
      "city": "Cresco",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cresco, Iowa"
    },
    {
      "city": "Lime Springs",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lime Springs, Iowa"
    },
    {
      "city": "Protivin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Protivin, Iowa"
    },
    {
      "city": "Bode",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bode, Iowa"
    },
    {
      "city": "Bradgate",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bradgate, Iowa"
    },
    {
      "city": "Dakota City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dakota City, Iowa"
    },
    {
      "city": "Gilmore City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gilmore City, Iowa"
    },
    {
      "city": "Hardy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hardy, Iowa"
    },
    {
      "city": "Humboldt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Humboldt, Iowa"
    },
    {
      "city": "Livermore",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Livermore, Iowa"
    },
    {
      "city": "Ottosen",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ottosen, Iowa"
    },
    {
      "city": "Renwick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Renwick, Iowa"
    },
    {
      "city": "Rutland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rutland, Iowa"
    },
    {
      "city": "Thor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thor, Iowa"
    },
    {
      "city": "Battle Creek",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Battle Creek, Iowa"
    },
    {
      "city": "Galva",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Galva, Iowa"
    },
    {
      "city": "Holstein",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Holstein, Iowa"
    },
    {
      "city": "Arthur",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arthur, Iowa"
    },
    {
      "city": "Ida Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ida Grove, Iowa"
    },
    {
      "city": "Amana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Amana, Iowa"
    },
    {
      "city": "Conroy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Conroy, Iowa"
    },
    {
      "city": "Homestead",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Homestead, Iowa"
    },
    {
      "city": "Ladora",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ladora, Iowa"
    },
    {
      "city": "Marengo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marengo, Iowa"
    },
    {
      "city": "Middle Amana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Middle Amana, Iowa"
    },
    {
      "city": "Millersburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Millersburg, Iowa"
    },
    {
      "city": "North English",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "North English, Iowa"
    },
    {
      "city": "Parnell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Parnell, Iowa"
    },
    {
      "city": "South Amana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "South Amana, Iowa"
    },
    {
      "city": "Victor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Victor, Iowa"
    },
    {
      "city": "Williamsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Williamsburg, Iowa"
    },
    {
      "city": "Andrew",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Andrew, Iowa"
    },
    {
      "city": "Bellevue",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bellevue, Iowa"
    },
    {
      "city": "La Motte",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "La Motte, Iowa"
    },
    {
      "city": "Maquoketa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Maquoketa, Iowa"
    },
    {
      "city": "Miles",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Miles, Iowa"
    },
    {
      "city": "Preston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Preston, Iowa"
    },
    {
      "city": "Sabula",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sabula, Iowa"
    },
    {
      "city": "Saint Donatus",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Donatus, Iowa"
    },
    {
      "city": "Spragueville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Spragueville, Iowa"
    },
    {
      "city": "Springbrook",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Springbrook, Iowa"
    },
    {
      "city": "Baldwin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Baldwin, Iowa"
    },
    {
      "city": "Monmouth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Monmouth, Iowa"
    },
    {
      "city": "Baxter",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Baxter, Iowa"
    },
    {
      "city": "Colfax",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Colfax, Iowa"
    },
    {
      "city": "Ira",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ira, Iowa"
    },
    {
      "city": "Kellogg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kellogg, Iowa"
    },
    {
      "city": "Killduff",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Killduff, Iowa"
    },
    {
      "city": "Lynnville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lynnville, Iowa"
    },
    {
      "city": "Mingo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mingo, Iowa"
    },
    {
      "city": "Monroe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Monroe, Iowa"
    },
    {
      "city": "Newton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Newton, Iowa"
    },
    {
      "city": "Prairie City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Prairie City, Iowa"
    },
    {
      "city": "Reasnor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Reasnor, Iowa"
    },
    {
      "city": "Sully",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sully, Iowa"
    },
    {
      "city": "Batavia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Batavia, Iowa"
    },
    {
      "city": "Fairfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fairfield, Iowa"
    },
    {
      "city": "Libertyville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Libertyville, Iowa"
    },
    {
      "city": "Packwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Packwood, Iowa"
    },
    {
      "city": "Lockridge",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lockridge, Iowa"
    },
    {
      "city": "Hills",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hills, Iowa"
    },
    {
      "city": "Iowa City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Iowa City, Iowa"
    },
    {
      "city": "Coralville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Coralville, Iowa"
    },
    {
      "city": "North Liberty",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "North Liberty, Iowa"
    },
    {
      "city": "Oxford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oxford, Iowa"
    },
    {
      "city": "Solon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Solon, Iowa"
    },
    {
      "city": "Swisher",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Swisher, Iowa"
    },
    {
      "city": "Tiffin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tiffin, Iowa"
    },
    {
      "city": "Lone Tree",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lone Tree, Iowa"
    },
    {
      "city": "Anamosa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Anamosa, Iowa"
    },
    {
      "city": "Center Junction",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Center Junction, Iowa"
    },
    {
      "city": "Langworthy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Langworthy, Iowa"
    },
    {
      "city": "Martelle",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Martelle, Iowa"
    },
    {
      "city": "Monticello",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Monticello, Iowa"
    },
    {
      "city": "Morley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Morley, Iowa"
    },
    {
      "city": "Olin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Olin, Iowa"
    },
    {
      "city": "Onslow",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Onslow, Iowa"
    },
    {
      "city": "Oxford Junction",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oxford Junction, Iowa"
    },
    {
      "city": "Wyoming",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wyoming, Iowa"
    },
    {
      "city": "Gibson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gibson, Iowa"
    },
    {
      "city": "Keswick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Keswick, Iowa"
    },
    {
      "city": "Thornburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thornburg, Iowa"
    },
    {
      "city": "What Cheer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "What Cheer, Iowa"
    },
    {
      "city": "Harper",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harper, Iowa"
    },
    {
      "city": "Keota",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Keota, Iowa"
    },
    {
      "city": "South English",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "South English, Iowa"
    },
    {
      "city": "Webster",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Webster, Iowa"
    },
    {
      "city": "Delta",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Delta, Iowa"
    },
    {
      "city": "Hayesville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hayesville, Iowa"
    },
    {
      "city": "Hedrick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hedrick, Iowa"
    },
    {
      "city": "Martinsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Martinsburg, Iowa"
    },
    {
      "city": "Ollie",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ollie, Iowa"
    },
    {
      "city": "Richland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Richland, Iowa"
    },
    {
      "city": "Sigourney",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sigourney, Iowa"
    },
    {
      "city": "Lakota",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lakota, Iowa"
    },
    {
      "city": "Titonka",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Titonka, Iowa"
    },
    {
      "city": "Wesley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wesley, Iowa"
    },
    {
      "city": "Algona",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Algona, Iowa"
    },
    {
      "city": "Bancroft",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bancroft, Iowa"
    },
    {
      "city": "Burt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Burt, Iowa"
    },
    {
      "city": "Fenton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fenton, Iowa"
    },
    {
      "city": "Ledyard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ledyard, Iowa"
    },
    {
      "city": "Lone Rock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lone Rock, Iowa"
    },
    {
      "city": "Lu Verne",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lu Verne, Iowa"
    },
    {
      "city": "Swea City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Swea City, Iowa"
    },
    {
      "city": "Whittemore",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Whittemore, Iowa"
    },
    {
      "city": "Argyle",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Argyle, Iowa"
    },
    {
      "city": "Denmark",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Denmark, Iowa"
    },
    {
      "city": "Donnellson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Donnellson, Iowa"
    },
    {
      "city": "Fort Madison",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fort Madison, Iowa"
    },
    {
      "city": "Houghton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Houghton, Iowa"
    },
    {
      "city": "Keokuk",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Keokuk, Iowa"
    },
    {
      "city": "Montrose",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Montrose, Iowa"
    },
    {
      "city": "Pilot Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pilot Grove, Iowa"
    },
    {
      "city": "West Point",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Point, Iowa"
    },
    {
      "city": "Saint Paul",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Paul, Iowa"
    },
    {
      "city": "Wever",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wever, Iowa"
    },
    {
      "city": "Alburnett",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alburnett, Iowa"
    },
    {
      "city": "Center Point",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Center Point, Iowa"
    },
    {
      "city": "Central City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Central City, Iowa"
    },
    {
      "city": "Coggon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Coggon, Iowa"
    },
    {
      "city": "Prairieburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Prairieburg, Iowa"
    },
    {
      "city": "Ely",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ely, Iowa"
    },
    {
      "city": "Fairfax",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fairfax, Iowa"
    },
    {
      "city": "Hiawatha",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hiawatha, Iowa"
    },
    {
      "city": "Lisbon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lisbon, Iowa"
    },
    {
      "city": "Marion",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marion, Iowa"
    },
    {
      "city": "Mount Vernon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Vernon, Iowa"
    },
    {
      "city": "Palo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Palo, Iowa"
    },
    {
      "city": "Robins",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Robins, Iowa"
    },
    {
      "city": "Springville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Springville, Iowa"
    },
    {
      "city": "Toddville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Toddville, Iowa"
    },
    {
      "city": "Troy Mills",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Troy Mills, Iowa"
    },
    {
      "city": "Walker",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Walker, Iowa"
    },
    {
      "city": "Cedar Rapids",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cedar Rapids, Iowa"
    },
    {
      "city": "Morning Sun",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Morning Sun, Iowa"
    },
    {
      "city": "Oakville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oakville, Iowa"
    },
    {
      "city": "Wapello",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wapello, Iowa"
    },
    {
      "city": "Columbus City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Columbus City, Iowa"
    },
    {
      "city": "Columbus Junction",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Columbus Junction, Iowa"
    },
    {
      "city": "Grandview",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grandview, Iowa"
    },
    {
      "city": "Letts",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Letts, Iowa"
    },
    {
      "city": "Chariton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chariton, Iowa"
    },
    {
      "city": "Derby",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Derby, Iowa"
    },
    {
      "city": "Lucas",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lucas, Iowa"
    },
    {
      "city": "Russell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Russell, Iowa"
    },
    {
      "city": "Williamson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Williamson, Iowa"
    },
    {
      "city": "Alvord",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alvord, Iowa"
    },
    {
      "city": "Doon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Doon, Iowa"
    },
    {
      "city": "George",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "George, Iowa"
    },
    {
      "city": "Inwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Inwood, Iowa"
    },
    {
      "city": "Larchwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Larchwood, Iowa"
    },
    {
      "city": "Lester",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lester, Iowa"
    },
    {
      "city": "Little Rock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Little Rock, Iowa"
    },
    {
      "city": "Rock Rapids",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rock Rapids, Iowa"
    },
    {
      "city": "Bevington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bevington, Iowa"
    },
    {
      "city": "Earlham",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Earlham, Iowa"
    },
    {
      "city": "Macksburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Macksburg, Iowa"
    },
    {
      "city": "Patterson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Patterson, Iowa"
    },
    {
      "city": "Peru",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Peru, Iowa"
    },
    {
      "city": "Saint Charles",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Charles, Iowa"
    },
    {
      "city": "Truro",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Truro, Iowa"
    },
    {
      "city": "Winterset",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Winterset, Iowa"
    },
    {
      "city": "Barnes City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Barnes City, Iowa"
    },
    {
      "city": "Leighton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Leighton, Iowa"
    },
    {
      "city": "New Sharon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Sharon, Iowa"
    },
    {
      "city": "Beacon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Beacon, Iowa"
    },
    {
      "city": "Cedar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cedar, Iowa"
    },
    {
      "city": "Fremont",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fremont, Iowa"
    },
    {
      "city": "Oskaloosa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oskaloosa, Iowa"
    },
    {
      "city": "Rose Hill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rose Hill, Iowa"
    },
    {
      "city": "University Park",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "University Park, Iowa"
    },
    {
      "city": "Bussey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bussey, Iowa"
    },
    {
      "city": "Columbia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Columbia, Iowa"
    },
    {
      "city": "Melcher Dallas",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Melcher Dallas, Iowa"
    },
    {
      "city": "Hamilton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hamilton, Iowa"
    },
    {
      "city": "Harvey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harvey, Iowa"
    },
    {
      "city": "Knoxville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Knoxville, Iowa"
    },
    {
      "city": "Otley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Otley, Iowa"
    },
    {
      "city": "Pella",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pella, Iowa"
    },
    {
      "city": "Pleasantville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pleasantville, Iowa"
    },
    {
      "city": "Swan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Swan, Iowa"
    },
    {
      "city": "Tracy",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tracy, Iowa"
    },
    {
      "city": "Albion",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Albion, Iowa"
    },
    {
      "city": "Clemons",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clemons, Iowa"
    },
    {
      "city": "Ferguson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ferguson, Iowa"
    },
    {
      "city": "Gilman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gilman, Iowa"
    },
    {
      "city": "Haverhill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Haverhill, Iowa"
    },
    {
      "city": "Laurel",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Laurel, Iowa"
    },
    {
      "city": "Le Grand",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Le Grand, Iowa"
    },
    {
      "city": "Liscomb",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Liscomb, Iowa"
    },
    {
      "city": "Marshalltown",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Marshalltown, Iowa"
    },
    {
      "city": "Melbourne",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Melbourne, Iowa"
    },
    {
      "city": "Rhodes",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rhodes, Iowa"
    },
    {
      "city": "Saint Anthony",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Anthony, Iowa"
    },
    {
      "city": "State Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "State Center, Iowa"
    },
    {
      "city": "Emerson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Emerson, Iowa"
    },
    {
      "city": "Glenwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Glenwood, Iowa"
    },
    {
      "city": "Hastings",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hastings, Iowa"
    },
    {
      "city": "Henderson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Henderson, Iowa"
    },
    {
      "city": "Malvern",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Malvern, Iowa"
    },
    {
      "city": "Mineola",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mineola, Iowa"
    },
    {
      "city": "Pacific Junction",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pacific Junction, Iowa"
    },
    {
      "city": "Silver City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Silver City, Iowa"
    },
    {
      "city": "Carpenter",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carpenter, Iowa"
    },
    {
      "city": "Little Cedar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Little Cedar, Iowa"
    },
    {
      "city": "Mc Intire",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mc Intire, Iowa"
    },
    {
      "city": "Orchard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Orchard, Iowa"
    },
    {
      "city": "Osage",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Osage, Iowa"
    },
    {
      "city": "Saint Ansgar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Ansgar, Iowa"
    },
    {
      "city": "Stacyville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stacyville, Iowa"
    },
    {
      "city": "Toeterville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Toeterville, Iowa"
    },
    {
      "city": "Castana",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Castana, Iowa"
    },
    {
      "city": "Mapleton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mapleton, Iowa"
    },
    {
      "city": "Onawa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Onawa, Iowa"
    },
    {
      "city": "Rodney",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rodney, Iowa"
    },
    {
      "city": "Ute",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ute, Iowa"
    },
    {
      "city": "Whiting",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Whiting, Iowa"
    },
    {
      "city": "Blencoe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blencoe, Iowa"
    },
    {
      "city": "Moorhead",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moorhead, Iowa"
    },
    {
      "city": "Soldier",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Soldier, Iowa"
    },
    {
      "city": "Lovilia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lovilia, Iowa"
    },
    {
      "city": "Albia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Albia, Iowa"
    },
    {
      "city": "Melrose",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Melrose, Iowa"
    },
    {
      "city": "Grant",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grant, Iowa"
    },
    {
      "city": "Villisca",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Villisca, Iowa"
    },
    {
      "city": "Elliott",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elliott, Iowa"
    },
    {
      "city": "Red Oak",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Red Oak, Iowa"
    },
    {
      "city": "Stanton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stanton, Iowa"
    },
    {
      "city": "Atalissa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Atalissa, Iowa"
    },
    {
      "city": "Conesville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Conesville, Iowa"
    },
    {
      "city": "Fruitland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fruitland, Iowa"
    },
    {
      "city": "Montpelier",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Montpelier, Iowa"
    },
    {
      "city": "Moscow",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moscow, Iowa"
    },
    {
      "city": "Muscatine",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Muscatine, Iowa"
    },
    {
      "city": "Nichols",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nichols, Iowa"
    },
    {
      "city": "Stockton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stockton, Iowa"
    },
    {
      "city": "West Liberty",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Liberty, Iowa"
    },
    {
      "city": "Wilton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wilton, Iowa"
    },
    {
      "city": "Calumet",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Calumet, Iowa"
    },
    {
      "city": "Paullina",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Paullina, Iowa"
    },
    {
      "city": "Sutherland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sutherland, Iowa"
    },
    {
      "city": "Sheldon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sheldon, Iowa"
    },
    {
      "city": "Archer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Archer, Iowa"
    },
    {
      "city": "Primghar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Primghar, Iowa"
    },
    {
      "city": "Sanborn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sanborn, Iowa"
    },
    {
      "city": "Hartley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hartley, Iowa"
    },
    {
      "city": "Ashton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ashton, Iowa"
    },
    {
      "city": "Sibley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sibley, Iowa"
    },
    {
      "city": "Harris",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harris, Iowa"
    },
    {
      "city": "Melvin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Melvin, Iowa"
    },
    {
      "city": "Ocheyedan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ocheyedan, Iowa"
    },
    {
      "city": "Shenandoah",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shenandoah, Iowa"
    },
    {
      "city": "Blanchard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blanchard, Iowa"
    },
    {
      "city": "Braddyville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Braddyville, Iowa"
    },
    {
      "city": "Clarinda",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clarinda, Iowa"
    },
    {
      "city": "Coin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Coin, Iowa"
    },
    {
      "city": "College Springs",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "College Springs, Iowa"
    },
    {
      "city": "Essex",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Essex, Iowa"
    },
    {
      "city": "Northboro",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Northboro, Iowa"
    },
    {
      "city": "Shambaugh",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shambaugh, Iowa"
    },
    {
      "city": "Yorktown",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Yorktown, Iowa"
    },
    {
      "city": "Ayrshire",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ayrshire, Iowa"
    },
    {
      "city": "Curlew",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Curlew, Iowa"
    },
    {
      "city": "Cylinder",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cylinder, Iowa"
    },
    {
      "city": "Emmetsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Emmetsburg, Iowa"
    },
    {
      "city": "Mallard",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mallard, Iowa"
    },
    {
      "city": "West Bend",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Bend, Iowa"
    },
    {
      "city": "Graettinger",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Graettinger, Iowa"
    },
    {
      "city": "Ruthven",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ruthven, Iowa"
    },
    {
      "city": "Akron",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Akron, Iowa"
    },
    {
      "city": "Brunsville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Brunsville, Iowa"
    },
    {
      "city": "Hinton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hinton, Iowa"
    },
    {
      "city": "Kingsley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kingsley, Iowa"
    },
    {
      "city": "Le Mars",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Le Mars, Iowa"
    },
    {
      "city": "Merrill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Merrill, Iowa"
    },
    {
      "city": "Oyens",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oyens, Iowa"
    },
    {
      "city": "Remsen",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Remsen, Iowa"
    },
    {
      "city": "Westfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Westfield, Iowa"
    },
    {
      "city": "Fonda",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fonda, Iowa"
    },
    {
      "city": "Havelock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Havelock, Iowa"
    },
    {
      "city": "Laurens",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Laurens, Iowa"
    },
    {
      "city": "Palmer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Palmer, Iowa"
    },
    {
      "city": "Plover",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Plover, Iowa"
    },
    {
      "city": "Pocahontas",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pocahontas, Iowa"
    },
    {
      "city": "Rolfe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rolfe, Iowa"
    },
    {
      "city": "Varina",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Varina, Iowa"
    },
    {
      "city": "Alleman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alleman, Iowa"
    },
    {
      "city": "Altoona",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Altoona, Iowa"
    },
    {
      "city": "Ankeny",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ankeny, Iowa"
    },
    {
      "city": "Berwick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Berwick, Iowa"
    },
    {
      "city": "Bondurant",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bondurant, Iowa"
    },
    {
      "city": "Elkhart",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elkhart, Iowa"
    },
    {
      "city": "Grimes",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grimes, Iowa"
    },
    {
      "city": "Johnston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Johnston, Iowa"
    },
    {
      "city": "Mitchellville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mitchellville, Iowa"
    },
    {
      "city": "Polk City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Polk City, Iowa"
    },
    {
      "city": "Runnells",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Runnells, Iowa"
    },
    {
      "city": "Sheldahl",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sheldahl, Iowa"
    },
    {
      "city": "West Des Moines",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Des Moines, Iowa"
    },
    {
      "city": "Des Moines",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Des Moines, Iowa"
    },
    {
      "city": "Urbandale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Urbandale, Iowa"
    },
    {
      "city": "Windsor Heights",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Windsor Heights, Iowa"
    },
    {
      "city": "Clive",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clive, Iowa"
    },
    {
      "city": "Pleasant Hill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pleasant Hill, Iowa"
    },
    {
      "city": "Council Bluffs",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Council Bluffs, Iowa"
    },
    {
      "city": "Carter Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carter Lake, Iowa"
    },
    {
      "city": "Avoca",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Avoca, Iowa"
    },
    {
      "city": "Carson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carson, Iowa"
    },
    {
      "city": "Crescent",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Crescent, Iowa"
    },
    {
      "city": "Hancock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hancock, Iowa"
    },
    {
      "city": "Honey Creek",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Honey Creek, Iowa"
    },
    {
      "city": "Mc Clelland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mc Clelland, Iowa"
    },
    {
      "city": "Macedonia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Macedonia, Iowa"
    },
    {
      "city": "Minden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Minden, Iowa"
    },
    {
      "city": "Neola",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Neola, Iowa"
    },
    {
      "city": "Oakland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oakland, Iowa"
    },
    {
      "city": "Shelby",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shelby, Iowa"
    },
    {
      "city": "Treynor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Treynor, Iowa"
    },
    {
      "city": "Underwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Underwood, Iowa"
    },
    {
      "city": "Walnut",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Walnut, Iowa"
    },
    {
      "city": "Harlan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harlan, Iowa"
    },
    {
      "city": "Grinnell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grinnell, Iowa"
    },
    {
      "city": "Malcom",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Malcom, Iowa"
    },
    {
      "city": "Montezuma",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Montezuma, Iowa"
    },
    {
      "city": "Searsboro",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Searsboro, Iowa"
    },
    {
      "city": "Brooklyn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Brooklyn, Iowa"
    },
    {
      "city": "Guernsey",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Guernsey, Iowa"
    },
    {
      "city": "Deep River",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Deep River, Iowa"
    },
    {
      "city": "Hartwick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hartwick, Iowa"
    },
    {
      "city": "Ellston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ellston, Iowa"
    },
    {
      "city": "Kellerton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kellerton, Iowa"
    },
    {
      "city": "Benton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Benton, Iowa"
    },
    {
      "city": "Diagonal",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Diagonal, Iowa"
    },
    {
      "city": "Mount Ayr",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Ayr, Iowa"
    },
    {
      "city": "Redding",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Redding, Iowa"
    },
    {
      "city": "Tingley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tingley, Iowa"
    },
    {
      "city": "Early",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Early, Iowa"
    },
    {
      "city": "Nemaha",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nemaha, Iowa"
    },
    {
      "city": "Sac City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sac City, Iowa"
    },
    {
      "city": "Schaller",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Schaller, Iowa"
    },
    {
      "city": "Auburn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Auburn, Iowa"
    },
    {
      "city": "Lake View",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lake View, Iowa"
    },
    {
      "city": "Odebolt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Odebolt, Iowa"
    },
    {
      "city": "Wall Lake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wall Lake, Iowa"
    },
    {
      "city": "Bettendorf",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bettendorf, Iowa"
    },
    {
      "city": "Blue Grass",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blue Grass, Iowa"
    },
    {
      "city": "Buffalo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Buffalo, Iowa"
    },
    {
      "city": "Dixon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dixon, Iowa"
    },
    {
      "city": "Donahue",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Donahue, Iowa"
    },
    {
      "city": "Eldridge",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Eldridge, Iowa"
    },
    {
      "city": "Le Claire",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Le Claire, Iowa"
    },
    {
      "city": "Long Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Long Grove, Iowa"
    },
    {
      "city": "Mc Causland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mc Causland, Iowa"
    },
    {
      "city": "New Liberty",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Liberty, Iowa"
    },
    {
      "city": "Pleasant Valley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pleasant Valley, Iowa"
    },
    {
      "city": "Princeton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Princeton, Iowa"
    },
    {
      "city": "Walcott",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Walcott, Iowa"
    },
    {
      "city": "Davenport",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Davenport, Iowa"
    },
    {
      "city": "Irwin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Irwin, Iowa"
    },
    {
      "city": "Kirkman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kirkman, Iowa"
    },
    {
      "city": "Defiance",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Defiance, Iowa"
    },
    {
      "city": "Earling",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Earling, Iowa"
    },
    {
      "city": "Elk Horn",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elk Horn, Iowa"
    },
    {
      "city": "Panama",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Panama, Iowa"
    },
    {
      "city": "Portsmouth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Portsmouth, Iowa"
    },
    {
      "city": "Westphalia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Westphalia, Iowa"
    },
    {
      "city": "Alton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Alton, Iowa"
    },
    {
      "city": "Chatsworth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chatsworth, Iowa"
    },
    {
      "city": "Granville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Granville, Iowa"
    },
    {
      "city": "Hawarden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hawarden, Iowa"
    },
    {
      "city": "Ireton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ireton, Iowa"
    },
    {
      "city": "Maurice",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Maurice, Iowa"
    },
    {
      "city": "Orange City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Orange City, Iowa"
    },
    {
      "city": "Boyden",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Boyden, Iowa"
    },
    {
      "city": "Hospers",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hospers, Iowa"
    },
    {
      "city": "Hull",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hull, Iowa"
    },
    {
      "city": "Matlock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Matlock, Iowa"
    },
    {
      "city": "Rock Valley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rock Valley, Iowa"
    },
    {
      "city": "Sioux Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sioux Center, Iowa"
    },
    {
      "city": "Ames",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ames, Iowa"
    },
    {
      "city": "Cambridge",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cambridge, Iowa"
    },
    {
      "city": "Collins",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Collins, Iowa"
    },
    {
      "city": "Colo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Colo, Iowa"
    },
    {
      "city": "Gilbert",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gilbert, Iowa"
    },
    {
      "city": "Huxley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Huxley, Iowa"
    },
    {
      "city": "Kelley",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kelley, Iowa"
    },
    {
      "city": "Mc Callsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mc Callsburg, Iowa"
    },
    {
      "city": "Maxwell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Maxwell, Iowa"
    },
    {
      "city": "Nevada",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Nevada, Iowa"
    },
    {
      "city": "Roland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Roland, Iowa"
    },
    {
      "city": "Slater",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Slater, Iowa"
    },
    {
      "city": "Story City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Story City, Iowa"
    },
    {
      "city": "Zearing",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Zearing, Iowa"
    },
    {
      "city": "Montour",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Montour, Iowa"
    },
    {
      "city": "Buckingham",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Buckingham, Iowa"
    },
    {
      "city": "Garwin",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Garwin, Iowa"
    },
    {
      "city": "Gladbrook",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gladbrook, Iowa"
    },
    {
      "city": "Lincoln",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lincoln, Iowa"
    },
    {
      "city": "Traer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Traer, Iowa"
    },
    {
      "city": "Chelsea",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chelsea, Iowa"
    },
    {
      "city": "Clutier",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clutier, Iowa"
    },
    {
      "city": "Dysart",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dysart, Iowa"
    },
    {
      "city": "Elberon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Elberon, Iowa"
    },
    {
      "city": "Tama",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Tama, Iowa"
    },
    {
      "city": "Toledo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Toledo, Iowa"
    },
    {
      "city": "Vining",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Vining, Iowa"
    },
    {
      "city": "Bedford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bedford, Iowa"
    },
    {
      "city": "Blockton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blockton, Iowa"
    },
    {
      "city": "Clearfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clearfield, Iowa"
    },
    {
      "city": "Gravity",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gravity, Iowa"
    },
    {
      "city": "Lenox",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lenox, Iowa"
    },
    {
      "city": "Sharpsburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sharpsburg, Iowa"
    },
    {
      "city": "New Market",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Market, Iowa"
    },
    {
      "city": "Lorimor",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lorimor, Iowa"
    },
    {
      "city": "Thayer",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thayer, Iowa"
    },
    {
      "city": "Creston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Creston, Iowa"
    },
    {
      "city": "Afton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Afton, Iowa"
    },
    {
      "city": "Arispe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Arispe, Iowa"
    },
    {
      "city": "Cromwell",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cromwell, Iowa"
    },
    {
      "city": "Shannon City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Shannon City, Iowa"
    },
    {
      "city": "Birmingham",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Birmingham, Iowa"
    },
    {
      "city": "Cantril",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cantril, Iowa"
    },
    {
      "city": "Douds",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Douds, Iowa"
    },
    {
      "city": "Keosauqua",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Keosauqua, Iowa"
    },
    {
      "city": "Milton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Milton, Iowa"
    },
    {
      "city": "Mount Sterling",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Mount Sterling, Iowa"
    },
    {
      "city": "Selma",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Selma, Iowa"
    },
    {
      "city": "Bonaparte",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bonaparte, Iowa"
    },
    {
      "city": "Farmington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Farmington, Iowa"
    },
    {
      "city": "Stockport",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Stockport, Iowa"
    },
    {
      "city": "Ottumwa",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ottumwa, Iowa"
    },
    {
      "city": "Agency",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Agency, Iowa"
    },
    {
      "city": "Blakesburg",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Blakesburg, Iowa"
    },
    {
      "city": "Chillicothe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Chillicothe, Iowa"
    },
    {
      "city": "Eddyville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Eddyville, Iowa"
    },
    {
      "city": "Eldon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Eldon, Iowa"
    },
    {
      "city": "Kirkville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kirkville, Iowa"
    },
    {
      "city": "Ackworth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ackworth, Iowa"
    },
    {
      "city": "Carlisle",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Carlisle, Iowa"
    },
    {
      "city": "Cumming",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cumming, Iowa"
    },
    {
      "city": "Hartford",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hartford, Iowa"
    },
    {
      "city": "Indianola",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Indianola, Iowa"
    },
    {
      "city": "Lacona",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lacona, Iowa"
    },
    {
      "city": "Liberty Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Liberty Center, Iowa"
    },
    {
      "city": "Martensdale",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Martensdale, Iowa"
    },
    {
      "city": "Milo",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Milo, Iowa"
    },
    {
      "city": "New Virginia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "New Virginia, Iowa"
    },
    {
      "city": "Norwalk",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Norwalk, Iowa"
    },
    {
      "city": "Prole",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Prole, Iowa"
    },
    {
      "city": "Saint Marys",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Saint Marys, Iowa"
    },
    {
      "city": "Ainsworth",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ainsworth, Iowa"
    },
    {
      "city": "Kalona",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kalona, Iowa"
    },
    {
      "city": "Riverside",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Riverside, Iowa"
    },
    {
      "city": "Washington",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Washington, Iowa"
    },
    {
      "city": "Wellman",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Wellman, Iowa"
    },
    {
      "city": "West Chester",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "West Chester, Iowa"
    },
    {
      "city": "Brighton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Brighton, Iowa"
    },
    {
      "city": "Crawfordsville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Crawfordsville, Iowa"
    },
    {
      "city": "Allerton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Allerton, Iowa"
    },
    {
      "city": "Clio",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clio, Iowa"
    },
    {
      "city": "Corydon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Corydon, Iowa"
    },
    {
      "city": "Humeston",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Humeston, Iowa"
    },
    {
      "city": "Lineville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lineville, Iowa"
    },
    {
      "city": "Millerton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Millerton, Iowa"
    },
    {
      "city": "Promise City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Promise City, Iowa"
    },
    {
      "city": "Seymour",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Seymour, Iowa"
    },
    {
      "city": "Fort Dodge",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fort Dodge, Iowa"
    },
    {
      "city": "Badger",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Badger, Iowa"
    },
    {
      "city": "Barnum",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Barnum, Iowa"
    },
    {
      "city": "Burnside",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Burnside, Iowa"
    },
    {
      "city": "Callender",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Callender, Iowa"
    },
    {
      "city": "Clare",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clare, Iowa"
    },
    {
      "city": "Dayton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dayton, Iowa"
    },
    {
      "city": "Duncombe",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Duncombe, Iowa"
    },
    {
      "city": "Gowrie",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Gowrie, Iowa"
    },
    {
      "city": "Harcourt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Harcourt, Iowa"
    },
    {
      "city": "Lehigh",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lehigh, Iowa"
    },
    {
      "city": "Moorland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moorland, Iowa"
    },
    {
      "city": "Otho",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Otho, Iowa"
    },
    {
      "city": "Vincent",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Vincent, Iowa"
    },
    {
      "city": "Buffalo Center",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Buffalo Center, Iowa"
    },
    {
      "city": "Forest City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Forest City, Iowa"
    },
    {
      "city": "Lake Mills",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lake Mills, Iowa"
    },
    {
      "city": "Leland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Leland, Iowa"
    },
    {
      "city": "Rake",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rake, Iowa"
    },
    {
      "city": "Scarville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Scarville, Iowa"
    },
    {
      "city": "Thompson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Thompson, Iowa"
    },
    {
      "city": "Decorah",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Decorah, Iowa"
    },
    {
      "city": "Calmar",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Calmar, Iowa"
    },
    {
      "city": "Castalia",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Castalia, Iowa"
    },
    {
      "city": "Fort Atkinson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fort Atkinson, Iowa"
    },
    {
      "city": "Highlandville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Highlandville, Iowa"
    },
    {
      "city": "Ossian",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ossian, Iowa"
    },
    {
      "city": "Ridgeway",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Ridgeway, Iowa"
    },
    {
      "city": "Spillville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Spillville, Iowa"
    },
    {
      "city": "Anthon",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Anthon, Iowa"
    },
    {
      "city": "Bronson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Bronson, Iowa"
    },
    {
      "city": "Climbing Hill",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Climbing Hill, Iowa"
    },
    {
      "city": "Correctionville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Correctionville, Iowa"
    },
    {
      "city": "Cushing",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Cushing, Iowa"
    },
    {
      "city": "Danbury",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Danbury, Iowa"
    },
    {
      "city": "Hornick",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hornick, Iowa"
    },
    {
      "city": "Lawton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Lawton, Iowa"
    },
    {
      "city": "Moville",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Moville, Iowa"
    },
    {
      "city": "Oto",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Oto, Iowa"
    },
    {
      "city": "Pierson",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Pierson, Iowa"
    },
    {
      "city": "Salix",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Salix, Iowa"
    },
    {
      "city": "Sergeant Bluff",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sergeant Bluff, Iowa"
    },
    {
      "city": "Sloan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sloan, Iowa"
    },
    {
      "city": "Smithland",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Smithland, Iowa"
    },
    {
      "city": "Sioux City",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Sioux City, Iowa"
    },
    {
      "city": "Fertile",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Fertile, Iowa"
    },
    {
      "city": "Grafton",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Grafton, Iowa"
    },
    {
      "city": "Hanlontown",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Hanlontown, Iowa"
    },
    {
      "city": "Joice",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Joice, Iowa"
    },
    {
      "city": "Kensett",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Kensett, Iowa"
    },
    {
      "city": "Manly",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Manly, Iowa"
    },
    {
      "city": "Northwood",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Northwood, Iowa"
    },
    {
      "city": "Dows",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Dows, Iowa"
    },
    {
      "city": "Galt",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Galt, Iowa"
    },
    {
      "city": "Belmond",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Belmond, Iowa"
    },
    {
      "city": "Rowan",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Rowan, Iowa"
    },
    {
      "city": "Clarion",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Clarion, Iowa"
    },
    {
      "city": "Eagle Grove",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Eagle Grove, Iowa"
    },
    {
      "city": "Goldfield",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Goldfield, Iowa"
    },
    {
      "city": "Woolstock",
      "state": "Iowa",
      "shortState": "IA",
      "formatedCity": "Woolstock, Iowa"
    },
    {
      "city": "Eagle",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Eagle, Idaho"
    },
    {
      "city": "Kuna",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kuna, Idaho"
    },
    {
      "city": "Meridian",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Meridian, Idaho"
    },
    {
      "city": "Star",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Star, Idaho"
    },
    {
      "city": "Boise",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Boise, Idaho"
    },
    {
      "city": "Garden City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Garden City, Idaho"
    },
    {
      "city": "Council",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Council, Idaho"
    },
    {
      "city": "Indian Valley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Indian Valley, Idaho"
    },
    {
      "city": "Mesa",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Mesa, Idaho"
    },
    {
      "city": "New Meadows",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "New Meadows, Idaho"
    },
    {
      "city": "Pocatello",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Pocatello, Idaho"
    },
    {
      "city": "Arimo",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Arimo, Idaho"
    },
    {
      "city": "Downey",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Downey, Idaho"
    },
    {
      "city": "Inkom",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Inkom, Idaho"
    },
    {
      "city": "Lava Hot Springs",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lava Hot Springs, Idaho"
    },
    {
      "city": "McCammon",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "McCammon, Idaho"
    },
    {
      "city": "Swanlake",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Swanlake, Idaho"
    },
    {
      "city": "Bern",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bern, Idaho"
    },
    {
      "city": "Bloomington",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bloomington, Idaho"
    },
    {
      "city": "Dingle",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Dingle, Idaho"
    },
    {
      "city": "Geneva",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Geneva, Idaho"
    },
    {
      "city": "Georgetown",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Georgetown, Idaho"
    },
    {
      "city": "Montpelier",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Montpelier, Idaho"
    },
    {
      "city": "Paris",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Paris, Idaho"
    },
    {
      "city": "Saint Charles",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Saint Charles, Idaho"
    },
    {
      "city": "Fish Haven",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fish Haven, Idaho"
    },
    {
      "city": "Desmet",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Desmet, Idaho"
    },
    {
      "city": "Fernwood",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fernwood, Idaho"
    },
    {
      "city": "Plummer",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Plummer, Idaho"
    },
    {
      "city": "Saint Maries",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Saint Maries, Idaho"
    },
    {
      "city": "Santa",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Santa, Idaho"
    },
    {
      "city": "Tensed",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Tensed, Idaho"
    },
    {
      "city": "Fort Hall",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fort Hall, Idaho"
    },
    {
      "city": "Aberdeen",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Aberdeen, Idaho"
    },
    {
      "city": "Atomic City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Atomic City, Idaho"
    },
    {
      "city": "Basalt",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Basalt, Idaho"
    },
    {
      "city": "Blackfoot",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Blackfoot, Idaho"
    },
    {
      "city": "Firth",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Firth, Idaho"
    },
    {
      "city": "Moreland",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Moreland, Idaho"
    },
    {
      "city": "Pingree",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Pingree, Idaho"
    },
    {
      "city": "Shelley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Shelley, Idaho"
    },
    {
      "city": "Springfield",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Springfield, Idaho"
    },
    {
      "city": "Bellevue",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bellevue, Idaho"
    },
    {
      "city": "Carey",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Carey, Idaho"
    },
    {
      "city": "Hailey",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hailey, Idaho"
    },
    {
      "city": "Ketchum",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ketchum, Idaho"
    },
    {
      "city": "Picabo",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Picabo, Idaho"
    },
    {
      "city": "Sun Valley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Sun Valley, Idaho"
    },
    {
      "city": "Banks",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Banks, Idaho"
    },
    {
      "city": "Garden Valley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Garden Valley, Idaho"
    },
    {
      "city": "Horseshoe Bend",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Horseshoe Bend, Idaho"
    },
    {
      "city": "Idaho City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Idaho City, Idaho"
    },
    {
      "city": "Lowman",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lowman, Idaho"
    },
    {
      "city": "Placerville",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Placerville, Idaho"
    },
    {
      "city": "Blanchard",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Blanchard, Idaho"
    },
    {
      "city": "Careywood",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Careywood, Idaho"
    },
    {
      "city": "Clark Fork",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Clark Fork, Idaho"
    },
    {
      "city": "Cocolalla",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cocolalla, Idaho"
    },
    {
      "city": "Coolin",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Coolin, Idaho"
    },
    {
      "city": "Oldtown",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Oldtown, Idaho"
    },
    {
      "city": "Dover",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Dover, Idaho"
    },
    {
      "city": "Hope",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hope, Idaho"
    },
    {
      "city": "Kootenai",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kootenai, Idaho"
    },
    {
      "city": "Laclede",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Laclede, Idaho"
    },
    {
      "city": "Nordman",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Nordman, Idaho"
    },
    {
      "city": "Ponderay",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ponderay, Idaho"
    },
    {
      "city": "Priest River",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Priest River, Idaho"
    },
    {
      "city": "Sagle",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Sagle, Idaho"
    },
    {
      "city": "Sandpoint",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Sandpoint, Idaho"
    },
    {
      "city": "Colburn",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Colburn, Idaho"
    },
    {
      "city": "Idaho Falls",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Idaho Falls, Idaho"
    },
    {
      "city": "Iona",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Iona, Idaho"
    },
    {
      "city": "Irwin",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Irwin, Idaho"
    },
    {
      "city": "Swan Valley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Swan Valley, Idaho"
    },
    {
      "city": "Ucon",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ucon, Idaho"
    },
    {
      "city": "Bonners Ferry",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bonners Ferry, Idaho"
    },
    {
      "city": "Eastport",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Eastport, Idaho"
    },
    {
      "city": "Moyie Springs",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Moyie Springs, Idaho"
    },
    {
      "city": "Naples",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Naples, Idaho"
    },
    {
      "city": "Porthill",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Porthill, Idaho"
    },
    {
      "city": "Arco",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Arco, Idaho"
    },
    {
      "city": "Howe",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Howe, Idaho"
    },
    {
      "city": "Moore",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Moore, Idaho"
    },
    {
      "city": "Corral",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Corral, Idaho"
    },
    {
      "city": "Fairfield",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fairfield, Idaho"
    },
    {
      "city": "Hill City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hill City, Idaho"
    },
    {
      "city": "Caldwell",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Caldwell, Idaho"
    },
    {
      "city": "Greenleaf",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Greenleaf, Idaho"
    },
    {
      "city": "Huston",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Huston, Idaho"
    },
    {
      "city": "Melba",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Melba, Idaho"
    },
    {
      "city": "Middleton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Middleton, Idaho"
    },
    {
      "city": "Nampa",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Nampa, Idaho"
    },
    {
      "city": "Notus",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Notus, Idaho"
    },
    {
      "city": "Parma",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Parma, Idaho"
    },
    {
      "city": "Wilder",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Wilder, Idaho"
    },
    {
      "city": "Bancroft",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bancroft, Idaho"
    },
    {
      "city": "Conda",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Conda, Idaho"
    },
    {
      "city": "Grace",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Grace, Idaho"
    },
    {
      "city": "Soda Springs",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Soda Springs, Idaho"
    },
    {
      "city": "Wayan",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Wayan, Idaho"
    },
    {
      "city": "Albion",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Albion, Idaho"
    },
    {
      "city": "Almo",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Almo, Idaho"
    },
    {
      "city": "Burley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Burley, Idaho"
    },
    {
      "city": "Declo",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Declo, Idaho"
    },
    {
      "city": "Malta",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Malta, Idaho"
    },
    {
      "city": "Oakley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Oakley, Idaho"
    },
    {
      "city": "Dubois",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Dubois, Idaho"
    },
    {
      "city": "Spencer",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Spencer, Idaho"
    },
    {
      "city": "Ahsahka",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ahsahka, Idaho"
    },
    {
      "city": "Lenore",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lenore, Idaho"
    },
    {
      "city": "Orofino",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Orofino, Idaho"
    },
    {
      "city": "Pierce",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Pierce, Idaho"
    },
    {
      "city": "Weippe",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Weippe, Idaho"
    },
    {
      "city": "Elk River",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Elk River, Idaho"
    },
    {
      "city": "Challis",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Challis, Idaho"
    },
    {
      "city": "Clayton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Clayton, Idaho"
    },
    {
      "city": "Ellis",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ellis, Idaho"
    },
    {
      "city": "Mackay",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Mackay, Idaho"
    },
    {
      "city": "Stanley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Stanley, Idaho"
    },
    {
      "city": "Atlanta",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Atlanta, Idaho"
    },
    {
      "city": "Glenns Ferry",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Glenns Ferry, Idaho"
    },
    {
      "city": "Hammett",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hammett, Idaho"
    },
    {
      "city": "King Hill",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "King Hill, Idaho"
    },
    {
      "city": "Mountain Home",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Mountain Home, Idaho"
    },
    {
      "city": "Mountain Home Afb",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Mountain Home Afb, Idaho"
    },
    {
      "city": "Clifton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Clifton, Idaho"
    },
    {
      "city": "Dayton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Dayton, Idaho"
    },
    {
      "city": "Franklin",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Franklin, Idaho"
    },
    {
      "city": "Preston",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Preston, Idaho"
    },
    {
      "city": "Thatcher",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Thatcher, Idaho"
    },
    {
      "city": "Weston",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Weston, Idaho"
    },
    {
      "city": "Ashton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ashton, Idaho"
    },
    {
      "city": "Chester",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Chester, Idaho"
    },
    {
      "city": "Island Park",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Island Park, Idaho"
    },
    {
      "city": "Macks Inn",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Macks Inn, Idaho"
    },
    {
      "city": "Newdale",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Newdale, Idaho"
    },
    {
      "city": "Parker",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Parker, Idaho"
    },
    {
      "city": "Saint Anthony",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Saint Anthony, Idaho"
    },
    {
      "city": "Teton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Teton, Idaho"
    },
    {
      "city": "Emmett",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Emmett, Idaho"
    },
    {
      "city": "Letha",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Letha, Idaho"
    },
    {
      "city": "Ola",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ola, Idaho"
    },
    {
      "city": "Sweet",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Sweet, Idaho"
    },
    {
      "city": "Bliss",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bliss, Idaho"
    },
    {
      "city": "Gooding",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Gooding, Idaho"
    },
    {
      "city": "Hagerman",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hagerman, Idaho"
    },
    {
      "city": "Wendell",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Wendell, Idaho"
    },
    {
      "city": "Cottonwood",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cottonwood, Idaho"
    },
    {
      "city": "Elk City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Elk City, Idaho"
    },
    {
      "city": "Ferdinand",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ferdinand, Idaho"
    },
    {
      "city": "Grangeville",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Grangeville, Idaho"
    },
    {
      "city": "Fenn",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fenn, Idaho"
    },
    {
      "city": "Greencreek",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Greencreek, Idaho"
    },
    {
      "city": "Kooskia",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kooskia, Idaho"
    },
    {
      "city": "Lucile",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lucile, Idaho"
    },
    {
      "city": "Pollock",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Pollock, Idaho"
    },
    {
      "city": "Riggins",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Riggins, Idaho"
    },
    {
      "city": "Stites",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Stites, Idaho"
    },
    {
      "city": "White Bird",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "White Bird, Idaho"
    },
    {
      "city": "Warren",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Warren, Idaho"
    },
    {
      "city": "Hamer",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hamer, Idaho"
    },
    {
      "city": "Lewisville",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lewisville, Idaho"
    },
    {
      "city": "Menan",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Menan, Idaho"
    },
    {
      "city": "Monteview",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Monteview, Idaho"
    },
    {
      "city": "Rigby",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rigby, Idaho"
    },
    {
      "city": "Ririe",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Ririe, Idaho"
    },
    {
      "city": "Roberts",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Roberts, Idaho"
    },
    {
      "city": "Terreton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Terreton, Idaho"
    },
    {
      "city": "Eden",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Eden, Idaho"
    },
    {
      "city": "Hazelton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hazelton, Idaho"
    },
    {
      "city": "Jerome",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Jerome, Idaho"
    },
    {
      "city": "Athol",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Athol, Idaho"
    },
    {
      "city": "Bayview",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bayview, Idaho"
    },
    {
      "city": "Cataldo",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cataldo, Idaho"
    },
    {
      "city": "Coeur d'Alene",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Coeur d'Alene, Idaho"
    },
    {
      "city": "Harrison",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Harrison, Idaho"
    },
    {
      "city": "Hayden",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hayden, Idaho"
    },
    {
      "city": "Medimont",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Medimont, Idaho"
    },
    {
      "city": "Post Falls",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Post Falls, Idaho"
    },
    {
      "city": "Rathdrum",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rathdrum, Idaho"
    },
    {
      "city": "Spirit Lake",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Spirit Lake, Idaho"
    },
    {
      "city": "Worley",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Worley, Idaho"
    },
    {
      "city": "Juliaetta",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Juliaetta, Idaho"
    },
    {
      "city": "Kendrick",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kendrick, Idaho"
    },
    {
      "city": "Bovill",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bovill, Idaho"
    },
    {
      "city": "Deary",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Deary, Idaho"
    },
    {
      "city": "Genesee",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Genesee, Idaho"
    },
    {
      "city": "Harvard",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Harvard, Idaho"
    },
    {
      "city": "Moscow",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Moscow, Idaho"
    },
    {
      "city": "Potlatch",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Potlatch, Idaho"
    },
    {
      "city": "Princeton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Princeton, Idaho"
    },
    {
      "city": "Troy",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Troy, Idaho"
    },
    {
      "city": "Viola",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Viola, Idaho"
    },
    {
      "city": "Cobalt",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cobalt, Idaho"
    },
    {
      "city": "May",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "May, Idaho"
    },
    {
      "city": "Carmen",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Carmen, Idaho"
    },
    {
      "city": "Gibbonsville",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Gibbonsville, Idaho"
    },
    {
      "city": "Leadore",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Leadore, Idaho"
    },
    {
      "city": "Lemhi",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lemhi, Idaho"
    },
    {
      "city": "North Fork",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "North Fork, Idaho"
    },
    {
      "city": "Salmon",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Salmon, Idaho"
    },
    {
      "city": "Tendoy",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Tendoy, Idaho"
    },
    {
      "city": "Shoup",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Shoup, Idaho"
    },
    {
      "city": "Craigmont",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Craigmont, Idaho"
    },
    {
      "city": "Kamiah",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kamiah, Idaho"
    },
    {
      "city": "Nezperce",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Nezperce, Idaho"
    },
    {
      "city": "Reubens",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Reubens, Idaho"
    },
    {
      "city": "Winchester",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Winchester, Idaho"
    },
    {
      "city": "Dietrich",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Dietrich, Idaho"
    },
    {
      "city": "Richfield",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Richfield, Idaho"
    },
    {
      "city": "Shoshone",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Shoshone, Idaho"
    },
    {
      "city": "Rexburg",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rexburg, Idaho"
    },
    {
      "city": "Sugar City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Sugar City, Idaho"
    },
    {
      "city": "Heyburn",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Heyburn, Idaho"
    },
    {
      "city": "Minidoka",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Minidoka, Idaho"
    },
    {
      "city": "Paul",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Paul, Idaho"
    },
    {
      "city": "Rupert",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rupert, Idaho"
    },
    {
      "city": "Lewiston",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lewiston, Idaho"
    },
    {
      "city": "Culdesac",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Culdesac, Idaho"
    },
    {
      "city": "Lapwai",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lapwai, Idaho"
    },
    {
      "city": "Peck",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Peck, Idaho"
    },
    {
      "city": "Holbrook",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Holbrook, Idaho"
    },
    {
      "city": "Malad City",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Malad City, Idaho"
    },
    {
      "city": "Bruneau",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Bruneau, Idaho"
    },
    {
      "city": "Grand View",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Grand View, Idaho"
    },
    {
      "city": "Homedale",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Homedale, Idaho"
    },
    {
      "city": "Marsing",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Marsing, Idaho"
    },
    {
      "city": "Murphy",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Murphy, Idaho"
    },
    {
      "city": "Fruitland",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Fruitland, Idaho"
    },
    {
      "city": "New Plymouth",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "New Plymouth, Idaho"
    },
    {
      "city": "Payette",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Payette, Idaho"
    },
    {
      "city": "American Falls",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "American Falls, Idaho"
    },
    {
      "city": "Arbon",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Arbon, Idaho"
    },
    {
      "city": "Rockland",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rockland, Idaho"
    },
    {
      "city": "Avery",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Avery, Idaho"
    },
    {
      "city": "Calder",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Calder, Idaho"
    },
    {
      "city": "Clarkia",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Clarkia, Idaho"
    },
    {
      "city": "Kellogg",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kellogg, Idaho"
    },
    {
      "city": "Kingston",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kingston, Idaho"
    },
    {
      "city": "Mullan",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Mullan, Idaho"
    },
    {
      "city": "Osburn",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Osburn, Idaho"
    },
    {
      "city": "Pinehurst",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Pinehurst, Idaho"
    },
    {
      "city": "Silverton",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Silverton, Idaho"
    },
    {
      "city": "Smelterville",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Smelterville, Idaho"
    },
    {
      "city": "Wallace",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Wallace, Idaho"
    },
    {
      "city": "Murray",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Murray, Idaho"
    },
    {
      "city": "Driggs",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Driggs, Idaho"
    },
    {
      "city": "Felt",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Felt, Idaho"
    },
    {
      "city": "Tetonia",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Tetonia, Idaho"
    },
    {
      "city": "Victor",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Victor, Idaho"
    },
    {
      "city": "Twin Falls",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Twin Falls, Idaho"
    },
    {
      "city": "Rogerson",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Rogerson, Idaho"
    },
    {
      "city": "Buhl",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Buhl, Idaho"
    },
    {
      "city": "Castleford",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Castleford, Idaho"
    },
    {
      "city": "Filer",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Filer, Idaho"
    },
    {
      "city": "Hansen",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Hansen, Idaho"
    },
    {
      "city": "Kimberly",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Kimberly, Idaho"
    },
    {
      "city": "Murtaugh",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Murtaugh, Idaho"
    },
    {
      "city": "Cascade",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cascade, Idaho"
    },
    {
      "city": "Donnelly",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Donnelly, Idaho"
    },
    {
      "city": "Lake Fork",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Lake Fork, Idaho"
    },
    {
      "city": "McCall",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "McCall, Idaho"
    },
    {
      "city": "Yellow Pine",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Yellow Pine, Idaho"
    },
    {
      "city": "Cambridge",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Cambridge, Idaho"
    },
    {
      "city": "Midvale",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Midvale, Idaho"
    },
    {
      "city": "Weiser",
      "state": "Idaho",
      "shortState": "ID",
      "formatedCity": "Weiser, Idaho"
    },
    {
      "city": "Quincy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Quincy, Illinois"
    },
    {
      "city": "Camp Point",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Camp Point, Illinois"
    },
    {
      "city": "Clayton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clayton, Illinois"
    },
    {
      "city": "Coatsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coatsburg, Illinois"
    },
    {
      "city": "Fowler",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fowler, Illinois"
    },
    {
      "city": "Golden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Golden, Illinois"
    },
    {
      "city": "La Prairie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Prairie, Illinois"
    },
    {
      "city": "Liberty",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Liberty, Illinois"
    },
    {
      "city": "Lima",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lima, Illinois"
    },
    {
      "city": "Loraine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Loraine, Illinois"
    },
    {
      "city": "Mendon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mendon, Illinois"
    },
    {
      "city": "Paloma",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Paloma, Illinois"
    },
    {
      "city": "Payson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Payson, Illinois"
    },
    {
      "city": "Plainville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Plainville, Illinois"
    },
    {
      "city": "Ursa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ursa, Illinois"
    },
    {
      "city": "Cairo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cairo, Illinois"
    },
    {
      "city": "Mc Clure",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mc Clure, Illinois"
    },
    {
      "city": "Miller City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Miller City, Illinois"
    },
    {
      "city": "Olive Branch",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Olive Branch, Illinois"
    },
    {
      "city": "Tamms",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tamms, Illinois"
    },
    {
      "city": "Thebes",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thebes, Illinois"
    },
    {
      "city": "Unity",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Unity, Illinois"
    },
    {
      "city": "Donnellson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Donnellson, Illinois"
    },
    {
      "city": "Sorento",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sorento, Illinois"
    },
    {
      "city": "Greenville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Greenville, Illinois"
    },
    {
      "city": "Mulberry Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mulberry Grove, Illinois"
    },
    {
      "city": "Pierron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pierron, Illinois"
    },
    {
      "city": "Pocahontas",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pocahontas, Illinois"
    },
    {
      "city": "Smithboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Smithboro, Illinois"
    },
    {
      "city": "Belvidere",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Belvidere, Illinois"
    },
    {
      "city": "Caledonia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Caledonia, Illinois"
    },
    {
      "city": "Capron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Capron, Illinois"
    },
    {
      "city": "Garden Prairie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Garden Prairie, Illinois"
    },
    {
      "city": "Poplar Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Poplar Grove, Illinois"
    },
    {
      "city": "Mount Sterling",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Sterling, Illinois"
    },
    {
      "city": "Timewell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Timewell, Illinois"
    },
    {
      "city": "Versailles",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Versailles, Illinois"
    },
    {
      "city": "Arlington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arlington, Illinois"
    },
    {
      "city": "Buda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buda, Illinois"
    },
    {
      "city": "Bureau",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bureau, Illinois"
    },
    {
      "city": "Cherry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cherry, Illinois"
    },
    {
      "city": "Dalzell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dalzell, Illinois"
    },
    {
      "city": "Depue",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Depue, Illinois"
    },
    {
      "city": "Dover",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dover, Illinois"
    },
    {
      "city": "Kasbeer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kasbeer, Illinois"
    },
    {
      "city": "Ladd",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ladd, Illinois"
    },
    {
      "city": "La Moille",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Moille, Illinois"
    },
    {
      "city": "Malden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Malden, Illinois"
    },
    {
      "city": "Manlius",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Manlius, Illinois"
    },
    {
      "city": "Mineral",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mineral, Illinois"
    },
    {
      "city": "Neponset",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Neponset, Illinois"
    },
    {
      "city": "New Bedford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Bedford, Illinois"
    },
    {
      "city": "Ohio",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ohio, Illinois"
    },
    {
      "city": "Princeton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Princeton, Illinois"
    },
    {
      "city": "Seatonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Seatonville, Illinois"
    },
    {
      "city": "Sheffield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sheffield, Illinois"
    },
    {
      "city": "Spring Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Spring Valley, Illinois"
    },
    {
      "city": "Tiskilwa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tiskilwa, Illinois"
    },
    {
      "city": "Van Orin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Van Orin, Illinois"
    },
    {
      "city": "Walnut",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Walnut, Illinois"
    },
    {
      "city": "Wyanet",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wyanet, Illinois"
    },
    {
      "city": "Batchtown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Batchtown, Illinois"
    },
    {
      "city": "Brussels",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brussels, Illinois"
    },
    {
      "city": "Golden Eagle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Golden Eagle, Illinois"
    },
    {
      "city": "Hamburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hamburg, Illinois"
    },
    {
      "city": "Hardin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hardin, Illinois"
    },
    {
      "city": "Kampsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kampsville, Illinois"
    },
    {
      "city": "Michael",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Michael, Illinois"
    },
    {
      "city": "Mozier",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mozier, Illinois"
    },
    {
      "city": "Chadwick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chadwick, Illinois"
    },
    {
      "city": "Lanark",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lanark, Illinois"
    },
    {
      "city": "Milledgeville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Milledgeville, Illinois"
    },
    {
      "city": "Mount Carroll",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Carroll, Illinois"
    },
    {
      "city": "Savanna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Savanna, Illinois"
    },
    {
      "city": "Shannon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shannon, Illinois"
    },
    {
      "city": "Thomson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thomson, Illinois"
    },
    {
      "city": "Arenzville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arenzville, Illinois"
    },
    {
      "city": "Ashland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ashland, Illinois"
    },
    {
      "city": "Beardstown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beardstown, Illinois"
    },
    {
      "city": "Bluff Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bluff Springs, Illinois"
    },
    {
      "city": "Chandlerville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chandlerville, Illinois"
    },
    {
      "city": "Virginia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Virginia, Illinois"
    },
    {
      "city": "Ludlow",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ludlow, Illinois"
    },
    {
      "city": "Urbana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Urbana, Illinois"
    },
    {
      "city": "Bondville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bondville, Illinois"
    },
    {
      "city": "Broadlands",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Broadlands, Illinois"
    },
    {
      "city": "Champaign",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Champaign, Illinois"
    },
    {
      "city": "Dewey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dewey, Illinois"
    },
    {
      "city": "Fisher",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fisher, Illinois"
    },
    {
      "city": "Foosland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Foosland, Illinois"
    },
    {
      "city": "Gifford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gifford, Illinois"
    },
    {
      "city": "Homer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Homer, Illinois"
    },
    {
      "city": "Ivesdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ivesdale, Illinois"
    },
    {
      "city": "Longview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Longview, Illinois"
    },
    {
      "city": "Mahomet",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mahomet, Illinois"
    },
    {
      "city": "Ogden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ogden, Illinois"
    },
    {
      "city": "Penfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Penfield, Illinois"
    },
    {
      "city": "Pesotum",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pesotum, Illinois"
    },
    {
      "city": "Philo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Philo, Illinois"
    },
    {
      "city": "Rantoul",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rantoul, Illinois"
    },
    {
      "city": "Royal",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Royal, Illinois"
    },
    {
      "city": "Sadorus",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sadorus, Illinois"
    },
    {
      "city": "Saint Joseph",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Joseph, Illinois"
    },
    {
      "city": "Savoy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Savoy, Illinois"
    },
    {
      "city": "Seymour",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Seymour, Illinois"
    },
    {
      "city": "Sidney",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sidney, Illinois"
    },
    {
      "city": "Thomasboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thomasboro, Illinois"
    },
    {
      "city": "Tolono",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tolono, Illinois"
    },
    {
      "city": "Rosamond",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rosamond, Illinois"
    },
    {
      "city": "Assumption",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Assumption, Illinois"
    },
    {
      "city": "Bulpitt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bulpitt, Illinois"
    },
    {
      "city": "Edinburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Edinburg, Illinois"
    },
    {
      "city": "Kincaid",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kincaid, Illinois"
    },
    {
      "city": "Morrisonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Morrisonville, Illinois"
    },
    {
      "city": "Mount Auburn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Auburn, Illinois"
    },
    {
      "city": "Owaneco",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Owaneco, Illinois"
    },
    {
      "city": "Palmer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palmer, Illinois"
    },
    {
      "city": "Pana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pana, Illinois"
    },
    {
      "city": "Stonington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stonington, Illinois"
    },
    {
      "city": "Taylorville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Taylorville, Illinois"
    },
    {
      "city": "Tovey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tovey, Illinois"
    },
    {
      "city": "Casey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Casey, Illinois"
    },
    {
      "city": "Dennison",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dennison, Illinois"
    },
    {
      "city": "Marshall",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marshall, Illinois"
    },
    {
      "city": "Martinsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Martinsville, Illinois"
    },
    {
      "city": "Westfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Westfield, Illinois"
    },
    {
      "city": "West Union",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Union, Illinois"
    },
    {
      "city": "Ingraham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ingraham, Illinois"
    },
    {
      "city": "Clay City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clay City, Illinois"
    },
    {
      "city": "Flora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Flora, Illinois"
    },
    {
      "city": "Louisville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Louisville, Illinois"
    },
    {
      "city": "Sailor Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sailor Springs, Illinois"
    },
    {
      "city": "Xenia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Xenia, Illinois"
    },
    {
      "city": "Albers",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Albers, Illinois"
    },
    {
      "city": "Aviston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Aviston, Illinois"
    },
    {
      "city": "Bartelso",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bartelso, Illinois"
    },
    {
      "city": "Beckemeyer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beckemeyer, Illinois"
    },
    {
      "city": "Breese",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Breese, Illinois"
    },
    {
      "city": "Carlyle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carlyle, Illinois"
    },
    {
      "city": "Germantown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Germantown, Illinois"
    },
    {
      "city": "Hoffman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hoffman, Illinois"
    },
    {
      "city": "Huey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Huey, Illinois"
    },
    {
      "city": "Keyesport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Keyesport, Illinois"
    },
    {
      "city": "New Baden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Baden, Illinois"
    },
    {
      "city": "New Memphis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Memphis, Illinois"
    },
    {
      "city": "Trenton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Trenton, Illinois"
    },
    {
      "city": "Ashmore",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ashmore, Illinois"
    },
    {
      "city": "Charleston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Charleston, Illinois"
    },
    {
      "city": "Humboldt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Humboldt, Illinois"
    },
    {
      "city": "Mattoon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mattoon, Illinois"
    },
    {
      "city": "Oakland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oakland, Illinois"
    },
    {
      "city": "Lerna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lerna, Illinois"
    },
    {
      "city": "Trilla",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Trilla, Illinois"
    },
    {
      "city": "Arlington Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arlington Heights, Illinois"
    },
    {
      "city": "Elk Grove Village",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elk Grove Village, Illinois"
    },
    {
      "city": "Rolling Meadows",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rolling Meadows, Illinois"
    },
    {
      "city": "Des Plaines",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Des Plaines, Illinois"
    },
    {
      "city": "Glencoe",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glencoe, Illinois"
    },
    {
      "city": "Glenview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glenview, Illinois"
    },
    {
      "city": "Golf",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Golf, Illinois"
    },
    {
      "city": "Palatine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palatine, Illinois"
    },
    {
      "city": "Kenilworth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kenilworth, Illinois"
    },
    {
      "city": "Morton Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Morton Grove, Illinois"
    },
    {
      "city": "Mount Prospect",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Prospect, Illinois"
    },
    {
      "city": "Northbrook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Northbrook, Illinois"
    },
    {
      "city": "Park Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Park Ridge, Illinois"
    },
    {
      "city": "Prospect Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Prospect Heights, Illinois"
    },
    {
      "city": "Skokie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Skokie, Illinois"
    },
    {
      "city": "Techny",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Techny, Illinois"
    },
    {
      "city": "Wheeling",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wheeling, Illinois"
    },
    {
      "city": "Wilmette",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wilmette, Illinois"
    },
    {
      "city": "Winnetka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winnetka, Illinois"
    },
    {
      "city": "Bartlett",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bartlett, Illinois"
    },
    {
      "city": "Bellwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bellwood, Illinois"
    },
    {
      "city": "Streamwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Streamwood, Illinois"
    },
    {
      "city": "Forest Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Forest Park, Illinois"
    },
    {
      "city": "Franklin Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Franklin Park, Illinois"
    },
    {
      "city": "Hines",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hines, Illinois"
    },
    {
      "city": "Maywood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maywood, Illinois"
    },
    {
      "city": "Westchester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Westchester, Illinois"
    },
    {
      "city": "Broadview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Broadview, Illinois"
    },
    {
      "city": "Schaumburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Schaumburg, Illinois"
    },
    {
      "city": "Melrose Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Melrose Park, Illinois"
    },
    {
      "city": "Hillside",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hillside, Illinois"
    },
    {
      "city": "Berkeley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Berkeley, Illinois"
    },
    {
      "city": "Stone Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stone Park, Illinois"
    },
    {
      "city": "Hoffman Estates",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hoffman Estates, Illinois"
    },
    {
      "city": "River Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "River Grove, Illinois"
    },
    {
      "city": "Schiller Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Schiller Park, Illinois"
    },
    {
      "city": "Evanston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Evanston, Illinois"
    },
    {
      "city": "Oak Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oak Park, Illinois"
    },
    {
      "city": "River Forest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "River Forest, Illinois"
    },
    {
      "city": "Berwyn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Berwyn, Illinois"
    },
    {
      "city": "Blue Island",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Blue Island, Illinois"
    },
    {
      "city": "Calumet City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Calumet City, Illinois"
    },
    {
      "city": "Chicago Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chicago Heights, Illinois"
    },
    {
      "city": "Chicago Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chicago Ridge, Illinois"
    },
    {
      "city": "Crestwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crestwood, Illinois"
    },
    {
      "city": "Dolton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dolton, Illinois"
    },
    {
      "city": "Flossmoor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Flossmoor, Illinois"
    },
    {
      "city": "Glenwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glenwood, Illinois"
    },
    {
      "city": "Harvey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harvey, Illinois"
    },
    {
      "city": "Hazel Crest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hazel Crest, Illinois"
    },
    {
      "city": "Homewood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Homewood, Illinois"
    },
    {
      "city": "Lansing",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lansing, Illinois"
    },
    {
      "city": "Matteson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Matteson, Illinois"
    },
    {
      "city": "Midlothian",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Midlothian, Illinois"
    },
    {
      "city": "Oak Forest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oak Forest, Illinois"
    },
    {
      "city": "Oak Lawn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oak Lawn, Illinois"
    },
    {
      "city": "Bridgeview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bridgeview, Illinois"
    },
    {
      "city": "Hometown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hometown, Illinois"
    },
    {
      "city": "Hickory Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hickory Hills, Illinois"
    },
    {
      "city": "Justice",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Justice, Illinois"
    },
    {
      "city": "Burbank",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Burbank, Illinois"
    },
    {
      "city": "Olympia Fields",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Olympia Fields, Illinois"
    },
    {
      "city": "Orland Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Orland Park, Illinois"
    },
    {
      "city": "Palos Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palos Heights, Illinois"
    },
    {
      "city": "Palos Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palos Park, Illinois"
    },
    {
      "city": "Palos Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palos Hills, Illinois"
    },
    {
      "city": "Park Forest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Park Forest, Illinois"
    },
    {
      "city": "Posen",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Posen, Illinois"
    },
    {
      "city": "Richton Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Richton Park, Illinois"
    },
    {
      "city": "Robbins",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Robbins, Illinois"
    },
    {
      "city": "South Holland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Holland, Illinois"
    },
    {
      "city": "Steger",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Steger, Illinois"
    },
    {
      "city": "Thornton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thornton, Illinois"
    },
    {
      "city": "Tinley Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tinley Park, Illinois"
    },
    {
      "city": "Country Club Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Country Club Hills, Illinois"
    },
    {
      "city": "Willow Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Willow Springs, Illinois"
    },
    {
      "city": "Worth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Worth, Illinois"
    },
    {
      "city": "Bedford Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bedford Park, Illinois"
    },
    {
      "city": "Summit Argo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Summit Argo, Illinois"
    },
    {
      "city": "Brookfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brookfield, Illinois"
    },
    {
      "city": "La Grange",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Grange, Illinois"
    },
    {
      "city": "La Grange Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Grange Park, Illinois"
    },
    {
      "city": "Lyons",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lyons, Illinois"
    },
    {
      "city": "Riverside",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Riverside, Illinois"
    },
    {
      "city": "Western Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Western Springs, Illinois"
    },
    {
      "city": "Chicago",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chicago, Illinois"
    },
    {
      "city": "Harwood Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harwood Heights, Illinois"
    },
    {
      "city": "Elmwood Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elmwood Park, Illinois"
    },
    {
      "city": "Lincolnwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lincolnwood, Illinois"
    },
    {
      "city": "Niles",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Niles, Illinois"
    },
    {
      "city": "Alsip",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alsip, Illinois"
    },
    {
      "city": "Cicero",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cicero, Illinois"
    },
    {
      "city": "Evergreen Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Evergreen Park, Illinois"
    },
    {
      "city": "Riverdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Riverdale, Illinois"
    },
    {
      "city": "Annapolis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Annapolis, Illinois"
    },
    {
      "city": "Flat Rock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Flat Rock, Illinois"
    },
    {
      "city": "Hutsonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hutsonville, Illinois"
    },
    {
      "city": "Oblong",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oblong, Illinois"
    },
    {
      "city": "Palestine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palestine, Illinois"
    },
    {
      "city": "Robinson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Robinson, Illinois"
    },
    {
      "city": "Stoy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stoy, Illinois"
    },
    {
      "city": "West York",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West York, Illinois"
    },
    {
      "city": "Greenup",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Greenup, Illinois"
    },
    {
      "city": "Janesville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Janesville, Illinois"
    },
    {
      "city": "Jewett",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Jewett, Illinois"
    },
    {
      "city": "Neoga",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Neoga, Illinois"
    },
    {
      "city": "Toledo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Toledo, Illinois"
    },
    {
      "city": "Clare",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clare, Illinois"
    },
    {
      "city": "Cortland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cortland, Illinois"
    },
    {
      "city": "Dekalb",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dekalb, Illinois"
    },
    {
      "city": "Esmond",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Esmond, Illinois"
    },
    {
      "city": "Genoa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Genoa, Illinois"
    },
    {
      "city": "Kingston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kingston, Illinois"
    },
    {
      "city": "Kirkland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kirkland, Illinois"
    },
    {
      "city": "Malta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Malta, Illinois"
    },
    {
      "city": "Sycamore",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sycamore, Illinois"
    },
    {
      "city": "Hinckley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hinckley, Illinois"
    },
    {
      "city": "Sandwich",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sandwich, Illinois"
    },
    {
      "city": "Shabbona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shabbona, Illinois"
    },
    {
      "city": "Somonauk",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Somonauk, Illinois"
    },
    {
      "city": "Waterman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waterman, Illinois"
    },
    {
      "city": "Clinton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clinton, Illinois"
    },
    {
      "city": "Dewitt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dewitt, Illinois"
    },
    {
      "city": "Kenney",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kenney, Illinois"
    },
    {
      "city": "Lane",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lane, Illinois"
    },
    {
      "city": "Wapella",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wapella, Illinois"
    },
    {
      "city": "Waynesville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waynesville, Illinois"
    },
    {
      "city": "Farmer City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Farmer City, Illinois"
    },
    {
      "city": "Weldon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Weldon, Illinois"
    },
    {
      "city": "Arcola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arcola, Illinois"
    },
    {
      "city": "Arthur",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arthur, Illinois"
    },
    {
      "city": "Atwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Atwood, Illinois"
    },
    {
      "city": "Camargo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Camargo, Illinois"
    },
    {
      "city": "Hindsboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hindsboro, Illinois"
    },
    {
      "city": "Murdock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Murdock, Illinois"
    },
    {
      "city": "Newman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Newman, Illinois"
    },
    {
      "city": "Tuscola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tuscola, Illinois"
    },
    {
      "city": "Villa Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Villa Grove, Illinois"
    },
    {
      "city": "Addison",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Addison, Illinois"
    },
    {
      "city": "Bensenville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bensenville, Illinois"
    },
    {
      "city": "Bloomingdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bloomingdale, Illinois"
    },
    {
      "city": "Carol Stream",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carol Stream, Illinois"
    },
    {
      "city": "Elmhurst",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elmhurst, Illinois"
    },
    {
      "city": "Hanover Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hanover Park, Illinois"
    },
    {
      "city": "Glen Ellyn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glen Ellyn, Illinois"
    },
    {
      "city": "Glendale Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glendale Heights, Illinois"
    },
    {
      "city": "Itasca",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Itasca, Illinois"
    },
    {
      "city": "Lombard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lombard, Illinois"
    },
    {
      "city": "Medinah",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Medinah, Illinois"
    },
    {
      "city": "Roselle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roselle, Illinois"
    },
    {
      "city": "Villa Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Villa Park, Illinois"
    },
    {
      "city": "Wayne",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wayne, Illinois"
    },
    {
      "city": "West Chicago",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Chicago, Illinois"
    },
    {
      "city": "Wheaton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wheaton, Illinois"
    },
    {
      "city": "Winfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winfield, Illinois"
    },
    {
      "city": "Wood Dale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wood Dale, Illinois"
    },
    {
      "city": "Lemont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lemont, Illinois"
    },
    {
      "city": "Aurora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Aurora, Illinois"
    },
    {
      "city": "Clarendon Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clarendon Hills, Illinois"
    },
    {
      "city": "Downers Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Downers Grove, Illinois"
    },
    {
      "city": "Woodridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodridge, Illinois"
    },
    {
      "city": "Eola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eola, Illinois"
    },
    {
      "city": "Hinsdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hinsdale, Illinois"
    },
    {
      "city": "Oak Brook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oak Brook, Illinois"
    },
    {
      "city": "Willowbrook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Willowbrook, Illinois"
    },
    {
      "city": "Lisle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lisle, Illinois"
    },
    {
      "city": "Naperville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Naperville, Illinois"
    },
    {
      "city": "Warrenville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Warrenville, Illinois"
    },
    {
      "city": "Westmont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Westmont, Illinois"
    },
    {
      "city": "Darien",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Darien, Illinois"
    },
    {
      "city": "Fox Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fox Valley, Illinois"
    },
    {
      "city": "Brocton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brocton, Illinois"
    },
    {
      "city": "Chrisman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chrisman, Illinois"
    },
    {
      "city": "Hume",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hume, Illinois"
    },
    {
      "city": "Kansas",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kansas, Illinois"
    },
    {
      "city": "Metcalf",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Metcalf, Illinois"
    },
    {
      "city": "Paris",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Paris, Illinois"
    },
    {
      "city": "Redmon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Redmon, Illinois"
    },
    {
      "city": "Vermilion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vermilion, Illinois"
    },
    {
      "city": "West Salem",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Salem, Illinois"
    },
    {
      "city": "Albion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Albion, Illinois"
    },
    {
      "city": "Bone Gap",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bone Gap, Illinois"
    },
    {
      "city": "Browns",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Browns, Illinois"
    },
    {
      "city": "Ellery",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ellery, Illinois"
    },
    {
      "city": "Effingham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Effingham, Illinois"
    },
    {
      "city": "Altamont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Altamont, Illinois"
    },
    {
      "city": "Beecher City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beecher City, Illinois"
    },
    {
      "city": "Dieterich",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dieterich, Illinois"
    },
    {
      "city": "Edgewood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Edgewood, Illinois"
    },
    {
      "city": "Mason",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mason, Illinois"
    },
    {
      "city": "Montrose",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Montrose, Illinois"
    },
    {
      "city": "Shumway",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shumway, Illinois"
    },
    {
      "city": "Teutopolis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Teutopolis, Illinois"
    },
    {
      "city": "Watson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Watson, Illinois"
    },
    {
      "city": "Bingham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bingham, Illinois"
    },
    {
      "city": "Ramsey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ramsey, Illinois"
    },
    {
      "city": "Hagarstown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hagarstown, Illinois"
    },
    {
      "city": "Brownstown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brownstown, Illinois"
    },
    {
      "city": "Saint Elmo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Elmo, Illinois"
    },
    {
      "city": "Vandalia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vandalia, Illinois"
    },
    {
      "city": "Farina",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Farina, Illinois"
    },
    {
      "city": "Saint Peter",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Peter, Illinois"
    },
    {
      "city": "Shobonier",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shobonier, Illinois"
    },
    {
      "city": "Cabery",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cabery, Illinois"
    },
    {
      "city": "Elliott",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elliott, Illinois"
    },
    {
      "city": "Gibson City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gibson City, Illinois"
    },
    {
      "city": "Kempton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kempton, Illinois"
    },
    {
      "city": "Melvin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Melvin, Illinois"
    },
    {
      "city": "Paxton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Paxton, Illinois"
    },
    {
      "city": "Piper City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Piper City, Illinois"
    },
    {
      "city": "Roberts",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roberts, Illinois"
    },
    {
      "city": "Sibley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sibley, Illinois"
    },
    {
      "city": "Benton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Benton, Illinois"
    },
    {
      "city": "Buckner",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buckner, Illinois"
    },
    {
      "city": "Christopher",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Christopher, Illinois"
    },
    {
      "city": "Coello",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coello, Illinois"
    },
    {
      "city": "Ewing",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ewing, Illinois"
    },
    {
      "city": "Frankfort Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Frankfort Heights, Illinois"
    },
    {
      "city": "Logan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Logan, Illinois"
    },
    {
      "city": "Mulkeytown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mulkeytown, Illinois"
    },
    {
      "city": "Orient",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Orient, Illinois"
    },
    {
      "city": "Sesser",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sesser, Illinois"
    },
    {
      "city": "Thompsonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thompsonville, Illinois"
    },
    {
      "city": "Valier",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Valier, Illinois"
    },
    {
      "city": "West Frankfort",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Frankfort, Illinois"
    },
    {
      "city": "Whittington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Whittington, Illinois"
    },
    {
      "city": "Royalton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Royalton, Illinois"
    },
    {
      "city": "Zeigler",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Zeigler, Illinois"
    },
    {
      "city": "Avon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Avon, Illinois"
    },
    {
      "city": "Cuba",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cuba, Illinois"
    },
    {
      "city": "Ellisville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ellisville, Illinois"
    },
    {
      "city": "Fairview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fairview, Illinois"
    },
    {
      "city": "Fiatt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fiatt, Illinois"
    },
    {
      "city": "Ipava",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ipava, Illinois"
    },
    {
      "city": "Marietta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marietta, Illinois"
    },
    {
      "city": "Smithfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Smithfield, Illinois"
    },
    {
      "city": "Table Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Table Grove, Illinois"
    },
    {
      "city": "Vermont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vermont, Illinois"
    },
    {
      "city": "Astoria",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Astoria, Illinois"
    },
    {
      "city": "Bryant",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bryant, Illinois"
    },
    {
      "city": "Canton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Canton, Illinois"
    },
    {
      "city": "Dunfermline",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dunfermline, Illinois"
    },
    {
      "city": "Farmington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Farmington, Illinois"
    },
    {
      "city": "Lewistown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lewistown, Illinois"
    },
    {
      "city": "Liverpool",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Liverpool, Illinois"
    },
    {
      "city": "London Mills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "London Mills, Illinois"
    },
    {
      "city": "Norris",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Norris, Illinois"
    },
    {
      "city": "Saint David",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint David, Illinois"
    },
    {
      "city": "New Haven",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Haven, Illinois"
    },
    {
      "city": "Omaha",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Omaha, Illinois"
    },
    {
      "city": "Equality",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Equality, Illinois"
    },
    {
      "city": "Junction",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Junction, Illinois"
    },
    {
      "city": "Ridgway",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ridgway, Illinois"
    },
    {
      "city": "Shawneetown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shawneetown, Illinois"
    },
    {
      "city": "Carrollton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carrollton, Illinois"
    },
    {
      "city": "Eldred",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eldred, Illinois"
    },
    {
      "city": "Greenfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Greenfield, Illinois"
    },
    {
      "city": "Hillview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hillview, Illinois"
    },
    {
      "city": "Kane",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kane, Illinois"
    },
    {
      "city": "Patterson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Patterson, Illinois"
    },
    {
      "city": "Rockbridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rockbridge, Illinois"
    },
    {
      "city": "Roodhouse",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roodhouse, Illinois"
    },
    {
      "city": "White Hall",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "White Hall, Illinois"
    },
    {
      "city": "Wrights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wrights, Illinois"
    },
    {
      "city": "Braceville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Braceville, Illinois"
    },
    {
      "city": "Coal City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coal City, Illinois"
    },
    {
      "city": "Gardner",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gardner, Illinois"
    },
    {
      "city": "Kinsman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kinsman, Illinois"
    },
    {
      "city": "Mazon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mazon, Illinois"
    },
    {
      "city": "Minooka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Minooka, Illinois"
    },
    {
      "city": "Morris",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Morris, Illinois"
    },
    {
      "city": "South Wilmington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Wilmington, Illinois"
    },
    {
      "city": "Verona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Verona, Illinois"
    },
    {
      "city": "Broughton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Broughton, Illinois"
    },
    {
      "city": "Dahlgren",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dahlgren, Illinois"
    },
    {
      "city": "Dale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dale, Illinois"
    },
    {
      "city": "Mc Leansboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mc Leansboro, Illinois"
    },
    {
      "city": "Macedonia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Macedonia, Illinois"
    },
    {
      "city": "La Harpe",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Harpe, Illinois"
    },
    {
      "city": "Augusta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Augusta, Illinois"
    },
    {
      "city": "Basco",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Basco, Illinois"
    },
    {
      "city": "Bowen",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bowen, Illinois"
    },
    {
      "city": "Carthage",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carthage, Illinois"
    },
    {
      "city": "Colusa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Colusa, Illinois"
    },
    {
      "city": "Dallas City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dallas City, Illinois"
    },
    {
      "city": "Elvaston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elvaston, Illinois"
    },
    {
      "city": "Ferris",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ferris, Illinois"
    },
    {
      "city": "Hamilton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hamilton, Illinois"
    },
    {
      "city": "Nauvoo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nauvoo, Illinois"
    },
    {
      "city": "Niota",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Niota, Illinois"
    },
    {
      "city": "Plymouth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Plymouth, Illinois"
    },
    {
      "city": "Sutter",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sutter, Illinois"
    },
    {
      "city": "Warsaw",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Warsaw, Illinois"
    },
    {
      "city": "West Point",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Point, Illinois"
    },
    {
      "city": "Cave In Rock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cave In Rock, Illinois"
    },
    {
      "city": "Elizabethtown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elizabethtown, Illinois"
    },
    {
      "city": "Karbers Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Karbers Ridge, Illinois"
    },
    {
      "city": "Rosiclare",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rosiclare, Illinois"
    },
    {
      "city": "Biggsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Biggsville, Illinois"
    },
    {
      "city": "Carman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carman, Illinois"
    },
    {
      "city": "Gladstone",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gladstone, Illinois"
    },
    {
      "city": "Lomax",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lomax, Illinois"
    },
    {
      "city": "Media",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Media, Illinois"
    },
    {
      "city": "Oquawka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oquawka, Illinois"
    },
    {
      "city": "Raritan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Raritan, Illinois"
    },
    {
      "city": "Stronghurst",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stronghurst, Illinois"
    },
    {
      "city": "Andover",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Andover, Illinois"
    },
    {
      "city": "Annawan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Annawan, Illinois"
    },
    {
      "city": "Atkinson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Atkinson, Illinois"
    },
    {
      "city": "Cambridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cambridge, Illinois"
    },
    {
      "city": "Colona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Colona, Illinois"
    },
    {
      "city": "Geneseo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Geneseo, Illinois"
    },
    {
      "city": "Hooppole",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hooppole, Illinois"
    },
    {
      "city": "Lynn Center",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lynn Center, Illinois"
    },
    {
      "city": "Orion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Orion, Illinois"
    },
    {
      "city": "Osco",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Osco, Illinois"
    },
    {
      "city": "Alpha",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alpha, Illinois"
    },
    {
      "city": "Bishop Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bishop Hill, Illinois"
    },
    {
      "city": "Galva",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Galva, Illinois"
    },
    {
      "city": "Kewanee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kewanee, Illinois"
    },
    {
      "city": "Ophiem",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ophiem, Illinois"
    },
    {
      "city": "Woodhull",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodhull, Illinois"
    },
    {
      "city": "Ashkum",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ashkum, Illinois"
    },
    {
      "city": "Beaverville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beaverville, Illinois"
    },
    {
      "city": "Buckley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buckley, Illinois"
    },
    {
      "city": "Chebanse",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chebanse, Illinois"
    },
    {
      "city": "Cissna Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cissna Park, Illinois"
    },
    {
      "city": "Claytonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Claytonville, Illinois"
    },
    {
      "city": "Clifton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Clifton, Illinois"
    },
    {
      "city": "Crescent City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crescent City, Illinois"
    },
    {
      "city": "Danforth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Danforth, Illinois"
    },
    {
      "city": "Donovan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Donovan, Illinois"
    },
    {
      "city": "Gilman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gilman, Illinois"
    },
    {
      "city": "Goodwine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Goodwine, Illinois"
    },
    {
      "city": "Iroquois",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Iroquois, Illinois"
    },
    {
      "city": "Loda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Loda, Illinois"
    },
    {
      "city": "Martinton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Martinton, Illinois"
    },
    {
      "city": "Milford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Milford, Illinois"
    },
    {
      "city": "Onarga",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Onarga, Illinois"
    },
    {
      "city": "Papineau",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Papineau, Illinois"
    },
    {
      "city": "Sheldon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sheldon, Illinois"
    },
    {
      "city": "Stockland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stockland, Illinois"
    },
    {
      "city": "Thawville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thawville, Illinois"
    },
    {
      "city": "Watseka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Watseka, Illinois"
    },
    {
      "city": "Wellington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wellington, Illinois"
    },
    {
      "city": "Woodland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodland, Illinois"
    },
    {
      "city": "Carbondale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carbondale, Illinois"
    },
    {
      "city": "Ava",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ava, Illinois"
    },
    {
      "city": "Campbell Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Campbell Hill, Illinois"
    },
    {
      "city": "De Soto",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "De Soto, Illinois"
    },
    {
      "city": "Dowell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dowell, Illinois"
    },
    {
      "city": "Elkville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elkville, Illinois"
    },
    {
      "city": "Gorham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gorham, Illinois"
    },
    {
      "city": "Grand Tower",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grand Tower, Illinois"
    },
    {
      "city": "Jacob",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Jacob, Illinois"
    },
    {
      "city": "Makanda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Makanda, Illinois"
    },
    {
      "city": "Murphysboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Murphysboro, Illinois"
    },
    {
      "city": "Oraville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oraville, Illinois"
    },
    {
      "city": "Pomona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pomona, Illinois"
    },
    {
      "city": "Vergennes",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vergennes, Illinois"
    },
    {
      "city": "Hidalgo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hidalgo, Illinois"
    },
    {
      "city": "Newton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Newton, Illinois"
    },
    {
      "city": "Sainte Marie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sainte Marie, Illinois"
    },
    {
      "city": "West Liberty",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Liberty, Illinois"
    },
    {
      "city": "Wheeler",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wheeler, Illinois"
    },
    {
      "city": "Willow Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Willow Hill, Illinois"
    },
    {
      "city": "Yale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Yale, Illinois"
    },
    {
      "city": "Belle Rive",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Belle Rive, Illinois"
    },
    {
      "city": "Bluford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bluford, Illinois"
    },
    {
      "city": "Bonnie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bonnie, Illinois"
    },
    {
      "city": "Dix",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dix, Illinois"
    },
    {
      "city": "Ina",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ina, Illinois"
    },
    {
      "city": "Mount Vernon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Vernon, Illinois"
    },
    {
      "city": "Nason",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nason, Illinois"
    },
    {
      "city": "Opdyke",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Opdyke, Illinois"
    },
    {
      "city": "Scheller",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Scheller, Illinois"
    },
    {
      "city": "Texico",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Texico, Illinois"
    },
    {
      "city": "Waltonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waltonville, Illinois"
    },
    {
      "city": "Woodlawn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodlawn, Illinois"
    },
    {
      "city": "Brighton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brighton, Illinois"
    },
    {
      "city": "Dow",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dow, Illinois"
    },
    {
      "city": "Elsah",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elsah, Illinois"
    },
    {
      "city": "Fidelity",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fidelity, Illinois"
    },
    {
      "city": "Fieldon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fieldon, Illinois"
    },
    {
      "city": "Grafton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grafton, Illinois"
    },
    {
      "city": "Jerseyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Jerseyville, Illinois"
    },
    {
      "city": "Medora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Medora, Illinois"
    },
    {
      "city": "Apple River",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Apple River, Illinois"
    },
    {
      "city": "East Dubuque",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Dubuque, Illinois"
    },
    {
      "city": "Elizabeth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elizabeth, Illinois"
    },
    {
      "city": "Galena",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Galena, Illinois"
    },
    {
      "city": "Hanover",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hanover, Illinois"
    },
    {
      "city": "Nora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nora, Illinois"
    },
    {
      "city": "Scales Mound",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Scales Mound, Illinois"
    },
    {
      "city": "Stockton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stockton, Illinois"
    },
    {
      "city": "Warren",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Warren, Illinois"
    },
    {
      "city": "Belknap",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Belknap, Illinois"
    },
    {
      "city": "Boles",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Boles, Illinois"
    },
    {
      "city": "Buncombe",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buncombe, Illinois"
    },
    {
      "city": "Cypress",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cypress, Illinois"
    },
    {
      "city": "Goreville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Goreville, Illinois"
    },
    {
      "city": "Grantsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grantsburg, Illinois"
    },
    {
      "city": "New Burnside",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Burnside, Illinois"
    },
    {
      "city": "Ozark",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ozark, Illinois"
    },
    {
      "city": "Simpson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Simpson, Illinois"
    },
    {
      "city": "Vienna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vienna, Illinois"
    },
    {
      "city": "Burlington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Burlington, Illinois"
    },
    {
      "city": "Carpentersville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carpentersville, Illinois"
    },
    {
      "city": "Dundee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dundee, Illinois"
    },
    {
      "city": "Elburn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elburn, Illinois"
    },
    {
      "city": "Elgin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elgin, Illinois"
    },
    {
      "city": "Geneva",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Geneva, Illinois"
    },
    {
      "city": "Gilberts",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gilberts, Illinois"
    },
    {
      "city": "Hampshire",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hampshire, Illinois"
    },
    {
      "city": "Kaneville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kaneville, Illinois"
    },
    {
      "city": "Lafox",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lafox, Illinois"
    },
    {
      "city": "Maple Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maple Park, Illinois"
    },
    {
      "city": "Saint Charles",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Charles, Illinois"
    },
    {
      "city": "South Elgin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Elgin, Illinois"
    },
    {
      "city": "Wasco",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wasco, Illinois"
    },
    {
      "city": "Batavia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Batavia, Illinois"
    },
    {
      "city": "Big Rock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Big Rock, Illinois"
    },
    {
      "city": "Mooseheart",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mooseheart, Illinois"
    },
    {
      "city": "North Aurora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "North Aurora, Illinois"
    },
    {
      "city": "Sugar Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sugar Grove, Illinois"
    },
    {
      "city": "Kankakee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kankakee, Illinois"
    },
    {
      "city": "Aroma Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Aroma Park, Illinois"
    },
    {
      "city": "Bonfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bonfield, Illinois"
    },
    {
      "city": "Bourbonnais",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bourbonnais, Illinois"
    },
    {
      "city": "Bradley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bradley, Illinois"
    },
    {
      "city": "Buckingham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buckingham, Illinois"
    },
    {
      "city": "Essex",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Essex, Illinois"
    },
    {
      "city": "Grant Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grant Park, Illinois"
    },
    {
      "city": "Herscher",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Herscher, Illinois"
    },
    {
      "city": "Hopkins Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hopkins Park, Illinois"
    },
    {
      "city": "Manteno",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Manteno, Illinois"
    },
    {
      "city": "Momence",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Momence, Illinois"
    },
    {
      "city": "Pembroke Township",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pembroke Township, Illinois"
    },
    {
      "city": "Reddick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Reddick, Illinois"
    },
    {
      "city": "Saint Anne",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Anne, Illinois"
    },
    {
      "city": "Union Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Union Hill, Illinois"
    },
    {
      "city": "Bristol",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bristol, Illinois"
    },
    {
      "city": "Millbrook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Millbrook, Illinois"
    },
    {
      "city": "Millington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Millington, Illinois"
    },
    {
      "city": "Montgomery",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Montgomery, Illinois"
    },
    {
      "city": "Newark",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Newark, Illinois"
    },
    {
      "city": "Oswego",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oswego, Illinois"
    },
    {
      "city": "Plano",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Plano, Illinois"
    },
    {
      "city": "Yorkville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Yorkville, Illinois"
    },
    {
      "city": "Galesburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Galesburg, Illinois"
    },
    {
      "city": "Abingdon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Abingdon, Illinois"
    },
    {
      "city": "Altona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Altona, Illinois"
    },
    {
      "city": "Dahinda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dahinda, Illinois"
    },
    {
      "city": "East Galesburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Galesburg, Illinois"
    },
    {
      "city": "Gilson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gilson, Illinois"
    },
    {
      "city": "Henderson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Henderson, Illinois"
    },
    {
      "city": "Knoxville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Knoxville, Illinois"
    },
    {
      "city": "Maquon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maquon, Illinois"
    },
    {
      "city": "Oneida",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oneida, Illinois"
    },
    {
      "city": "Rio",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rio, Illinois"
    },
    {
      "city": "Saint Augustine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Augustine, Illinois"
    },
    {
      "city": "Victoria",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Victoria, Illinois"
    },
    {
      "city": "Wataga",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wataga, Illinois"
    },
    {
      "city": "Williamsfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Williamsfield, Illinois"
    },
    {
      "city": "Yates City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Yates City, Illinois"
    },
    {
      "city": "Antioch",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Antioch, Illinois"
    },
    {
      "city": "Barrington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Barrington, Illinois"
    },
    {
      "city": "Deerfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Deerfield, Illinois"
    },
    {
      "city": "Fox Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fox Lake, Illinois"
    },
    {
      "city": "Grayslake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grayslake, Illinois"
    },
    {
      "city": "Gurnee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gurnee, Illinois"
    },
    {
      "city": "Highland Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Highland Park, Illinois"
    },
    {
      "city": "Fort Sheridan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fort Sheridan, Illinois"
    },
    {
      "city": "Highwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Highwood, Illinois"
    },
    {
      "city": "Ingleside",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ingleside, Illinois"
    },
    {
      "city": "Island Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Island Lake, Illinois"
    },
    {
      "city": "Lake Bluff",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake Bluff, Illinois"
    },
    {
      "city": "Lake Forest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake Forest, Illinois"
    },
    {
      "city": "Lake Villa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake Villa, Illinois"
    },
    {
      "city": "Lake Zurich",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake Zurich, Illinois"
    },
    {
      "city": "Libertyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Libertyville, Illinois"
    },
    {
      "city": "Mundelein",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mundelein, Illinois"
    },
    {
      "city": "Vernon Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vernon Hills, Illinois"
    },
    {
      "city": "North Chicago",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "North Chicago, Illinois"
    },
    {
      "city": "Lincolnshire",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lincolnshire, Illinois"
    },
    {
      "city": "Round Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Round Lake, Illinois"
    },
    {
      "city": "Russell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Russell, Illinois"
    },
    {
      "city": "Waukegan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waukegan, Illinois"
    },
    {
      "city": "Wadsworth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wadsworth, Illinois"
    },
    {
      "city": "Wauconda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wauconda, Illinois"
    },
    {
      "city": "Great Lakes",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Great Lakes, Illinois"
    },
    {
      "city": "Buffalo Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buffalo Grove, Illinois"
    },
    {
      "city": "Winthrop Harbor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winthrop Harbor, Illinois"
    },
    {
      "city": "Zion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Zion, Illinois"
    },
    {
      "city": "Ransom",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ransom, Illinois"
    },
    {
      "city": "Earlville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Earlville, Illinois"
    },
    {
      "city": "Leland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Leland, Illinois"
    },
    {
      "city": "Serena",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Serena, Illinois"
    },
    {
      "city": "Sheridan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sheridan, Illinois"
    },
    {
      "city": "Wedron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wedron, Illinois"
    },
    {
      "city": "La Salle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Salle, Illinois"
    },
    {
      "city": "Cedar Point",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cedar Point, Illinois"
    },
    {
      "city": "Dana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dana, Illinois"
    },
    {
      "city": "Grand Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grand Ridge, Illinois"
    },
    {
      "city": "Leonore",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Leonore, Illinois"
    },
    {
      "city": "Lostant",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lostant, Illinois"
    },
    {
      "city": "Marseilles",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marseilles, Illinois"
    },
    {
      "city": "Mendota",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mendota, Illinois"
    },
    {
      "city": "Oglesby",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oglesby, Illinois"
    },
    {
      "city": "Ottawa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ottawa, Illinois"
    },
    {
      "city": "Peru",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Peru, Illinois"
    },
    {
      "city": "Rutland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rutland, Illinois"
    },
    {
      "city": "Seneca",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Seneca, Illinois"
    },
    {
      "city": "Streator",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Streator, Illinois"
    },
    {
      "city": "Tonica",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tonica, Illinois"
    },
    {
      "city": "Triumph",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Triumph, Illinois"
    },
    {
      "city": "Troy Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Troy Grove, Illinois"
    },
    {
      "city": "Utica",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Utica, Illinois"
    },
    {
      "city": "Bridgeport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bridgeport, Illinois"
    },
    {
      "city": "Lawrenceville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lawrenceville, Illinois"
    },
    {
      "city": "Saint Francisville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Francisville, Illinois"
    },
    {
      "city": "Sumner",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sumner, Illinois"
    },
    {
      "city": "Lee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lee, Illinois"
    },
    {
      "city": "Steward",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Steward, Illinois"
    },
    {
      "city": "Ashton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ashton, Illinois"
    },
    {
      "city": "Dixon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dixon, Illinois"
    },
    {
      "city": "Franklin Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Franklin Grove, Illinois"
    },
    {
      "city": "Harmon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harmon, Illinois"
    },
    {
      "city": "Nachusa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nachusa, Illinois"
    },
    {
      "city": "Amboy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Amboy, Illinois"
    },
    {
      "city": "Compton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Compton, Illinois"
    },
    {
      "city": "Eldena",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eldena, Illinois"
    },
    {
      "city": "Lee Center",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lee Center, Illinois"
    },
    {
      "city": "Paw Paw",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Paw Paw, Illinois"
    },
    {
      "city": "Sublette",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sublette, Illinois"
    },
    {
      "city": "West Brooklyn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "West Brooklyn, Illinois"
    },
    {
      "city": "Dwight",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dwight, Illinois"
    },
    {
      "city": "Odell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Odell, Illinois"
    },
    {
      "city": "Campus",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Campus, Illinois"
    },
    {
      "city": "Chatsworth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chatsworth, Illinois"
    },
    {
      "city": "Cullom",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cullom, Illinois"
    },
    {
      "city": "Emington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Emington, Illinois"
    },
    {
      "city": "Ancona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ancona, Illinois"
    },
    {
      "city": "Blackstone",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Blackstone, Illinois"
    },
    {
      "city": "Cornell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cornell, Illinois"
    },
    {
      "city": "Long Point",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Long Point, Illinois"
    },
    {
      "city": "Fairbury",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fairbury, Illinois"
    },
    {
      "city": "Flanagan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Flanagan, Illinois"
    },
    {
      "city": "Forrest",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Forrest, Illinois"
    },
    {
      "city": "Graymont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Graymont, Illinois"
    },
    {
      "city": "Pontiac",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pontiac, Illinois"
    },
    {
      "city": "Saunemin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saunemin, Illinois"
    },
    {
      "city": "Strawn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Strawn, Illinois"
    },
    {
      "city": "Atlanta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Atlanta, Illinois"
    },
    {
      "city": "Lawndale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lawndale, Illinois"
    },
    {
      "city": "Beason",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beason, Illinois"
    },
    {
      "city": "Chestnut",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chestnut, Illinois"
    },
    {
      "city": "Cornland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cornland, Illinois"
    },
    {
      "city": "Lake Fork",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake Fork, Illinois"
    },
    {
      "city": "Latham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Latham, Illinois"
    },
    {
      "city": "Mount Pulaski",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Pulaski, Illinois"
    },
    {
      "city": "Elkhart",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elkhart, Illinois"
    },
    {
      "city": "Emden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Emden, Illinois"
    },
    {
      "city": "Hartsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hartsburg, Illinois"
    },
    {
      "city": "Lincoln",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lincoln, Illinois"
    },
    {
      "city": "Middletown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Middletown, Illinois"
    },
    {
      "city": "New Holland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Holland, Illinois"
    },
    {
      "city": "Adair",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Adair, Illinois"
    },
    {
      "city": "Bardolph",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bardolph, Illinois"
    },
    {
      "city": "Blandinsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Blandinsville, Illinois"
    },
    {
      "city": "Bushnell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bushnell, Illinois"
    },
    {
      "city": "Good Hope",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Good Hope, Illinois"
    },
    {
      "city": "Industry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Industry, Illinois"
    },
    {
      "city": "Macomb",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Macomb, Illinois"
    },
    {
      "city": "Prairie City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Prairie City, Illinois"
    },
    {
      "city": "Sciota",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sciota, Illinois"
    },
    {
      "city": "Colchester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Colchester, Illinois"
    },
    {
      "city": "Tennessee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tennessee, Illinois"
    },
    {
      "city": "Alden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alden, Illinois"
    },
    {
      "city": "Crystal Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crystal Lake, Illinois"
    },
    {
      "city": "Cary",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cary, Illinois"
    },
    {
      "city": "Fox River Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fox River Grove, Illinois"
    },
    {
      "city": "Harvard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harvard, Illinois"
    },
    {
      "city": "Hebron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hebron, Illinois"
    },
    {
      "city": "Mchenry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mchenry, Illinois"
    },
    {
      "city": "Richmond",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Richmond, Illinois"
    },
    {
      "city": "Ringwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ringwood, Illinois"
    },
    {
      "city": "Spring Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Spring Grove, Illinois"
    },
    {
      "city": "Wonder Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wonder Lake, Illinois"
    },
    {
      "city": "Woodstock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodstock, Illinois"
    },
    {
      "city": "Algonquin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Algonquin, Illinois"
    },
    {
      "city": "Huntley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Huntley, Illinois"
    },
    {
      "city": "Marengo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marengo, Illinois"
    },
    {
      "city": "Lake In The Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lake In The Hills, Illinois"
    },
    {
      "city": "Union",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Union, Illinois"
    },
    {
      "city": "Bloomington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bloomington, Illinois"
    },
    {
      "city": "Anchor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Anchor, Illinois"
    },
    {
      "city": "Arrowsmith",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Arrowsmith, Illinois"
    },
    {
      "city": "Bellflower",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bellflower, Illinois"
    },
    {
      "city": "Carlock",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carlock, Illinois"
    },
    {
      "city": "Chenoa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chenoa, Illinois"
    },
    {
      "city": "Colfax",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Colfax, Illinois"
    },
    {
      "city": "Cooksville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cooksville, Illinois"
    },
    {
      "city": "Cropsey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cropsey, Illinois"
    },
    {
      "city": "Danvers",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Danvers, Illinois"
    },
    {
      "city": "Downs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Downs, Illinois"
    },
    {
      "city": "Ellsworth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ellsworth, Illinois"
    },
    {
      "city": "Gridley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gridley, Illinois"
    },
    {
      "city": "Heyworth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Heyworth, Illinois"
    },
    {
      "city": "Hudson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hudson, Illinois"
    },
    {
      "city": "Le Roy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Le Roy, Illinois"
    },
    {
      "city": "Lexington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lexington, Illinois"
    },
    {
      "city": "Mc Lean",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mc Lean, Illinois"
    },
    {
      "city": "Merna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Merna, Illinois"
    },
    {
      "city": "Normal",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Normal, Illinois"
    },
    {
      "city": "Saybrook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saybrook, Illinois"
    },
    {
      "city": "Shirley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shirley, Illinois"
    },
    {
      "city": "Stanford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stanford, Illinois"
    },
    {
      "city": "Towanda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Towanda, Illinois"
    },
    {
      "city": "Maroa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maroa, Illinois"
    },
    {
      "city": "Argenta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Argenta, Illinois"
    },
    {
      "city": "Blue Mound",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Blue Mound, Illinois"
    },
    {
      "city": "Boody",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Boody, Illinois"
    },
    {
      "city": "Decatur",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Decatur, Illinois"
    },
    {
      "city": "Elwin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elwin, Illinois"
    },
    {
      "city": "Forsyth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Forsyth, Illinois"
    },
    {
      "city": "Harristown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harristown, Illinois"
    },
    {
      "city": "Macon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Macon, Illinois"
    },
    {
      "city": "Mt Zion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mt Zion, Illinois"
    },
    {
      "city": "Niantic",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Niantic, Illinois"
    },
    {
      "city": "Oreana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oreana, Illinois"
    },
    {
      "city": "Warrensburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Warrensburg, Illinois"
    },
    {
      "city": "Benld",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Benld, Illinois"
    },
    {
      "city": "Bunker Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bunker Hill, Illinois"
    },
    {
      "city": "Eagarville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eagarville, Illinois"
    },
    {
      "city": "Gillespie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gillespie, Illinois"
    },
    {
      "city": "Mount Olive",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Olive, Illinois"
    },
    {
      "city": "Piasa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Piasa, Illinois"
    },
    {
      "city": "Sawyerville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sawyerville, Illinois"
    },
    {
      "city": "Staunton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Staunton, Illinois"
    },
    {
      "city": "Wilsonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wilsonville, Illinois"
    },
    {
      "city": "Carlinville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carlinville, Illinois"
    },
    {
      "city": "Chesterfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chesterfield, Illinois"
    },
    {
      "city": "Girard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Girard, Illinois"
    },
    {
      "city": "Hettick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hettick, Illinois"
    },
    {
      "city": "Modesto",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Modesto, Illinois"
    },
    {
      "city": "Nilwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nilwood, Illinois"
    },
    {
      "city": "Palmyra",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Palmyra, Illinois"
    },
    {
      "city": "Scottville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Scottville, Illinois"
    },
    {
      "city": "Shipman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shipman, Illinois"
    },
    {
      "city": "Virden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Virden, Illinois"
    },
    {
      "city": "Alhambra",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alhambra, Illinois"
    },
    {
      "city": "Alton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alton, Illinois"
    },
    {
      "city": "Bethalto",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bethalto, Illinois"
    },
    {
      "city": "Cottage Hills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cottage Hills, Illinois"
    },
    {
      "city": "Dorsey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dorsey, Illinois"
    },
    {
      "city": "East Alton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Alton, Illinois"
    },
    {
      "city": "Edwardsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Edwardsville, Illinois"
    },
    {
      "city": "Glen Carbon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glen Carbon, Illinois"
    },
    {
      "city": "Godfrey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Godfrey, Illinois"
    },
    {
      "city": "Granite City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Granite City, Illinois"
    },
    {
      "city": "Hamel",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hamel, Illinois"
    },
    {
      "city": "Hartford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hartford, Illinois"
    },
    {
      "city": "Livingston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Livingston, Illinois"
    },
    {
      "city": "Madison",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Madison, Illinois"
    },
    {
      "city": "Marine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marine, Illinois"
    },
    {
      "city": "Maryville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maryville, Illinois"
    },
    {
      "city": "Moro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Moro, Illinois"
    },
    {
      "city": "New Douglas",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Douglas, Illinois"
    },
    {
      "city": "Roxana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roxana, Illinois"
    },
    {
      "city": "South Roxana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Roxana, Illinois"
    },
    {
      "city": "Venice",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Venice, Illinois"
    },
    {
      "city": "Wood River",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wood River, Illinois"
    },
    {
      "city": "Worden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Worden, Illinois"
    },
    {
      "city": "Collinsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Collinsville, Illinois"
    },
    {
      "city": "Highland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Highland, Illinois"
    },
    {
      "city": "Saint Jacob",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Jacob, Illinois"
    },
    {
      "city": "Troy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Troy, Illinois"
    },
    {
      "city": "Centralia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Centralia, Illinois"
    },
    {
      "city": "Alma",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alma, Illinois"
    },
    {
      "city": "Iuka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Iuka, Illinois"
    },
    {
      "city": "Kell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kell, Illinois"
    },
    {
      "city": "Kinmundy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kinmundy, Illinois"
    },
    {
      "city": "Odin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Odin, Illinois"
    },
    {
      "city": "Patoka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Patoka, Illinois"
    },
    {
      "city": "Salem",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Salem, Illinois"
    },
    {
      "city": "Sandoval",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sandoval, Illinois"
    },
    {
      "city": "Vernon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Vernon, Illinois"
    },
    {
      "city": "Walnut Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Walnut Hill, Illinois"
    },
    {
      "city": "Toluca",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Toluca, Illinois"
    },
    {
      "city": "Varna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Varna, Illinois"
    },
    {
      "city": "Wenona",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wenona, Illinois"
    },
    {
      "city": "Camp Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Camp Grove, Illinois"
    },
    {
      "city": "Henry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Henry, Illinois"
    },
    {
      "city": "Lacon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lacon, Illinois"
    },
    {
      "city": "La Rose",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Rose, Illinois"
    },
    {
      "city": "Sparland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sparland, Illinois"
    },
    {
      "city": "Washburn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Washburn, Illinois"
    },
    {
      "city": "Forest City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Forest City, Illinois"
    },
    {
      "city": "Manito",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Manito, Illinois"
    },
    {
      "city": "Topeka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Topeka, Illinois"
    },
    {
      "city": "Bath",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bath, Illinois"
    },
    {
      "city": "Easton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Easton, Illinois"
    },
    {
      "city": "Havana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Havana, Illinois"
    },
    {
      "city": "Kilbourne",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kilbourne, Illinois"
    },
    {
      "city": "Mason City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mason City, Illinois"
    },
    {
      "city": "San Jose",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "San Jose, Illinois"
    },
    {
      "city": "Brookport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brookport, Illinois"
    },
    {
      "city": "Joppa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Joppa, Illinois"
    },
    {
      "city": "Metropolis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Metropolis, Illinois"
    },
    {
      "city": "Athens",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Athens, Illinois"
    },
    {
      "city": "Greenview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Greenview, Illinois"
    },
    {
      "city": "Lincoln'S New Salem",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lincoln'S New Salem, Illinois"
    },
    {
      "city": "Oakford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oakford, Illinois"
    },
    {
      "city": "Petersburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Petersburg, Illinois"
    },
    {
      "city": "Tallula",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tallula, Illinois"
    },
    {
      "city": "Aledo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Aledo, Illinois"
    },
    {
      "city": "Joy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Joy, Illinois"
    },
    {
      "city": "Matherville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Matherville, Illinois"
    },
    {
      "city": "New Boston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Boston, Illinois"
    },
    {
      "city": "Preemption",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Preemption, Illinois"
    },
    {
      "city": "Sherrard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sherrard, Illinois"
    },
    {
      "city": "Alexis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alexis, Illinois"
    },
    {
      "city": "Keithsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Keithsburg, Illinois"
    },
    {
      "city": "New Windsor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Windsor, Illinois"
    },
    {
      "city": "North Henderson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "North Henderson, Illinois"
    },
    {
      "city": "Seaton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Seaton, Illinois"
    },
    {
      "city": "Viola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Viola, Illinois"
    },
    {
      "city": "Columbia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Columbia, Illinois"
    },
    {
      "city": "Fults",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fults, Illinois"
    },
    {
      "city": "Hecker",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hecker, Illinois"
    },
    {
      "city": "Maeystown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maeystown, Illinois"
    },
    {
      "city": "Renault",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Renault, Illinois"
    },
    {
      "city": "Valmeyer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Valmeyer, Illinois"
    },
    {
      "city": "Waterloo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waterloo, Illinois"
    },
    {
      "city": "Butler",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Butler, Illinois"
    },
    {
      "city": "Coffeen",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coffeen, Illinois"
    },
    {
      "city": "Fillmore",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fillmore, Illinois"
    },
    {
      "city": "Hillsboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hillsboro, Illinois"
    },
    {
      "city": "Irving",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Irving, Illinois"
    },
    {
      "city": "Litchfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Litchfield, Illinois"
    },
    {
      "city": "Nokomis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nokomis, Illinois"
    },
    {
      "city": "Ohlman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ohlman, Illinois"
    },
    {
      "city": "Panama",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Panama, Illinois"
    },
    {
      "city": "Taylor Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Taylor Springs, Illinois"
    },
    {
      "city": "Walshville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Walshville, Illinois"
    },
    {
      "city": "Witt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Witt, Illinois"
    },
    {
      "city": "Farmersville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Farmersville, Illinois"
    },
    {
      "city": "Harvel",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harvel, Illinois"
    },
    {
      "city": "Raymond",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Raymond, Illinois"
    },
    {
      "city": "Waggoner",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waggoner, Illinois"
    },
    {
      "city": "Alexander",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alexander, Illinois"
    },
    {
      "city": "Chapin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chapin, Illinois"
    },
    {
      "city": "Concord",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Concord, Illinois"
    },
    {
      "city": "Franklin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Franklin, Illinois"
    },
    {
      "city": "Jacksonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Jacksonville, Illinois"
    },
    {
      "city": "Literberry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Literberry, Illinois"
    },
    {
      "city": "Meredosia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Meredosia, Illinois"
    },
    {
      "city": "Murrayville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Murrayville, Illinois"
    },
    {
      "city": "Waverly",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Waverly, Illinois"
    },
    {
      "city": "Woodson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woodson, Illinois"
    },
    {
      "city": "Bethany",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bethany, Illinois"
    },
    {
      "city": "Dalton City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dalton City, Illinois"
    },
    {
      "city": "Gays",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gays, Illinois"
    },
    {
      "city": "Lovington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lovington, Illinois"
    },
    {
      "city": "Sullivan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sullivan, Illinois"
    },
    {
      "city": "Creston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Creston, Illinois"
    },
    {
      "city": "Baileyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Baileyville, Illinois"
    },
    {
      "city": "Byron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Byron, Illinois"
    },
    {
      "city": "Chana",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chana, Illinois"
    },
    {
      "city": "Davis Junction",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Davis Junction, Illinois"
    },
    {
      "city": "Forreston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Forreston, Illinois"
    },
    {
      "city": "Holcomb",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Holcomb, Illinois"
    },
    {
      "city": "Leaf River",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Leaf River, Illinois"
    },
    {
      "city": "Lindenwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lindenwood, Illinois"
    },
    {
      "city": "Monroe Center",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Monroe Center, Illinois"
    },
    {
      "city": "Mount Morris",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Morris, Illinois"
    },
    {
      "city": "Oregon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oregon, Illinois"
    },
    {
      "city": "Polo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Polo, Illinois"
    },
    {
      "city": "Rochelle",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rochelle, Illinois"
    },
    {
      "city": "Stillman Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stillman Valley, Illinois"
    },
    {
      "city": "Woosung",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Woosung, Illinois"
    },
    {
      "city": "Laura",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Laura, Illinois"
    },
    {
      "city": "Brimfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Brimfield, Illinois"
    },
    {
      "city": "Chillicothe",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chillicothe, Illinois"
    },
    {
      "city": "Dunlap",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dunlap, Illinois"
    },
    {
      "city": "Edelstein",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Edelstein, Illinois"
    },
    {
      "city": "Edwards",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Edwards, Illinois"
    },
    {
      "city": "Elmwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elmwood, Illinois"
    },
    {
      "city": "Glasford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glasford, Illinois"
    },
    {
      "city": "Hanna City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hanna City, Illinois"
    },
    {
      "city": "Kingston Mines",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kingston Mines, Illinois"
    },
    {
      "city": "Mapleton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mapleton, Illinois"
    },
    {
      "city": "Mossville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mossville, Illinois"
    },
    {
      "city": "Rome",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rome, Illinois"
    },
    {
      "city": "Trivoli",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Trivoli, Illinois"
    },
    {
      "city": "Peoria",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Peoria, Illinois"
    },
    {
      "city": "Peoria Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Peoria Heights, Illinois"
    },
    {
      "city": "Cutler",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cutler, Illinois"
    },
    {
      "city": "Pinckneyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pinckneyville, Illinois"
    },
    {
      "city": "Du Quoin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Du Quoin, Illinois"
    },
    {
      "city": "Tamaroa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tamaroa, Illinois"
    },
    {
      "city": "Willisville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Willisville, Illinois"
    },
    {
      "city": "Bement",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bement, Illinois"
    },
    {
      "city": "Cerro Gordo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cerro Gordo, Illinois"
    },
    {
      "city": "Cisco",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cisco, Illinois"
    },
    {
      "city": "De Land",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "De Land, Illinois"
    },
    {
      "city": "Mansfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mansfield, Illinois"
    },
    {
      "city": "Milmine",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Milmine, Illinois"
    },
    {
      "city": "Monticello",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Monticello, Illinois"
    },
    {
      "city": "White Heath",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "White Heath, Illinois"
    },
    {
      "city": "Hammond",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hammond, Illinois"
    },
    {
      "city": "La Place",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Place, Illinois"
    },
    {
      "city": "Barry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Barry, Illinois"
    },
    {
      "city": "Baylis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Baylis, Illinois"
    },
    {
      "city": "Chambersburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chambersburg, Illinois"
    },
    {
      "city": "Griggsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Griggsville, Illinois"
    },
    {
      "city": "Hull",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hull, Illinois"
    },
    {
      "city": "Kinderhook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kinderhook, Illinois"
    },
    {
      "city": "Milton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Milton, Illinois"
    },
    {
      "city": "Nebo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nebo, Illinois"
    },
    {
      "city": "New Canton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Canton, Illinois"
    },
    {
      "city": "New Salem",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Salem, Illinois"
    },
    {
      "city": "Pearl",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pearl, Illinois"
    },
    {
      "city": "Perry",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Perry, Illinois"
    },
    {
      "city": "Pittsfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pittsfield, Illinois"
    },
    {
      "city": "Pleasant Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pleasant Hill, Illinois"
    },
    {
      "city": "Rockport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rockport, Illinois"
    },
    {
      "city": "Eddyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eddyville, Illinois"
    },
    {
      "city": "Golconda",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Golconda, Illinois"
    },
    {
      "city": "Herod",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Herod, Illinois"
    },
    {
      "city": "Grand Chain",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grand Chain, Illinois"
    },
    {
      "city": "Karnak",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Karnak, Illinois"
    },
    {
      "city": "Mound City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mound City, Illinois"
    },
    {
      "city": "Mounds",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mounds, Illinois"
    },
    {
      "city": "Olmsted",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Olmsted, Illinois"
    },
    {
      "city": "Perks",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Perks, Illinois"
    },
    {
      "city": "Pulaski",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pulaski, Illinois"
    },
    {
      "city": "Ullin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ullin, Illinois"
    },
    {
      "city": "Villa Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Villa Ridge, Illinois"
    },
    {
      "city": "Granville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Granville, Illinois"
    },
    {
      "city": "Hennepin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hennepin, Illinois"
    },
    {
      "city": "Mc Nabb",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mc Nabb, Illinois"
    },
    {
      "city": "Magnolia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Magnolia, Illinois"
    },
    {
      "city": "Mark",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mark, Illinois"
    },
    {
      "city": "Standard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Standard, Illinois"
    },
    {
      "city": "Putnam",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Putnam, Illinois"
    },
    {
      "city": "Baldwin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Baldwin, Illinois"
    },
    {
      "city": "Chester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chester, Illinois"
    },
    {
      "city": "Coulterville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coulterville, Illinois"
    },
    {
      "city": "Ellis Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ellis Grove, Illinois"
    },
    {
      "city": "Evansville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Evansville, Illinois"
    },
    {
      "city": "Menard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Menard, Illinois"
    },
    {
      "city": "Modoc",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Modoc, Illinois"
    },
    {
      "city": "Percy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Percy, Illinois"
    },
    {
      "city": "Prairie Du Rocher",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Prairie Du Rocher, Illinois"
    },
    {
      "city": "Red Bud",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Red Bud, Illinois"
    },
    {
      "city": "Rockwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rockwood, Illinois"
    },
    {
      "city": "Sparta",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sparta, Illinois"
    },
    {
      "city": "Steeleville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Steeleville, Illinois"
    },
    {
      "city": "Tilden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tilden, Illinois"
    },
    {
      "city": "Walsh",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Walsh, Illinois"
    },
    {
      "city": "Calhoun",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Calhoun, Illinois"
    },
    {
      "city": "Claremont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Claremont, Illinois"
    },
    {
      "city": "Dundas",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dundas, Illinois"
    },
    {
      "city": "Olney",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Olney, Illinois"
    },
    {
      "city": "Parkersburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Parkersburg, Illinois"
    },
    {
      "city": "Noble",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Noble, Illinois"
    },
    {
      "city": "Rock Island",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rock Island, Illinois"
    },
    {
      "city": "Andalusia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Andalusia, Illinois"
    },
    {
      "city": "Barstow",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Barstow, Illinois"
    },
    {
      "city": "Buffalo Prairie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buffalo Prairie, Illinois"
    },
    {
      "city": "Carbon Cliff",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carbon Cliff, Illinois"
    },
    {
      "city": "Coal Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Coal Valley, Illinois"
    },
    {
      "city": "Cordova",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cordova, Illinois"
    },
    {
      "city": "East Moline",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Moline, Illinois"
    },
    {
      "city": "Hampton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hampton, Illinois"
    },
    {
      "city": "Hillsdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hillsdale, Illinois"
    },
    {
      "city": "Illinois City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Illinois City, Illinois"
    },
    {
      "city": "Milan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Milan, Illinois"
    },
    {
      "city": "Moline",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Moline, Illinois"
    },
    {
      "city": "Port Byron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Port Byron, Illinois"
    },
    {
      "city": "Rapids City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rapids City, Illinois"
    },
    {
      "city": "Reynolds",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Reynolds, Illinois"
    },
    {
      "city": "Silvis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Silvis, Illinois"
    },
    {
      "city": "Taylor Ridge",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Taylor Ridge, Illinois"
    },
    {
      "city": "Lovejoy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lovejoy, Illinois"
    },
    {
      "city": "National Stock Yards",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "National Stock Yards, Illinois"
    },
    {
      "city": "East Saint Louis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Saint Louis, Illinois"
    },
    {
      "city": "Fairview Heights",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fairview Heights, Illinois"
    },
    {
      "city": "Belleville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Belleville, Illinois"
    },
    {
      "city": "Scott Air Force Base",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Scott Air Force Base, Illinois"
    },
    {
      "city": "Caseyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Caseyville, Illinois"
    },
    {
      "city": "Dupo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dupo, Illinois"
    },
    {
      "city": "East Carondelet",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Carondelet, Illinois"
    },
    {
      "city": "Freeburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Freeburg, Illinois"
    },
    {
      "city": "Lebanon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lebanon, Illinois"
    },
    {
      "city": "Lenzburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lenzburg, Illinois"
    },
    {
      "city": "Marissa",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marissa, Illinois"
    },
    {
      "city": "Mascoutah",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mascoutah, Illinois"
    },
    {
      "city": "Millstadt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Millstadt, Illinois"
    },
    {
      "city": "New Athens",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Athens, Illinois"
    },
    {
      "city": "O Fallon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "O Fallon, Illinois"
    },
    {
      "city": "Saint Libory",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Saint Libory, Illinois"
    },
    {
      "city": "Smithton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Smithton, Illinois"
    },
    {
      "city": "Summerfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Summerfield, Illinois"
    },
    {
      "city": "Carrier Mills",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carrier Mills, Illinois"
    },
    {
      "city": "Eldorado",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eldorado, Illinois"
    },
    {
      "city": "Galatia",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Galatia, Illinois"
    },
    {
      "city": "Harrisburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Harrisburg, Illinois"
    },
    {
      "city": "Muddy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Muddy, Illinois"
    },
    {
      "city": "Raleigh",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Raleigh, Illinois"
    },
    {
      "city": "Stonefort",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stonefort, Illinois"
    },
    {
      "city": "Buffalo",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Buffalo, Illinois"
    },
    {
      "city": "Dawson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dawson, Illinois"
    },
    {
      "city": "Divernon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Divernon, Illinois"
    },
    {
      "city": "Glenarm",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Glenarm, Illinois"
    },
    {
      "city": "Illiopolis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Illiopolis, Illinois"
    },
    {
      "city": "Mechanicsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mechanicsburg, Illinois"
    },
    {
      "city": "Pawnee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pawnee, Illinois"
    },
    {
      "city": "Riverton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Riverton, Illinois"
    },
    {
      "city": "Rochester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rochester, Illinois"
    },
    {
      "city": "Auburn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Auburn, Illinois"
    },
    {
      "city": "Cantrall",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cantrall, Illinois"
    },
    {
      "city": "Chatham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Chatham, Illinois"
    },
    {
      "city": "Loami",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Loami, Illinois"
    },
    {
      "city": "Lowder",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lowder, Illinois"
    },
    {
      "city": "New Berlin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Berlin, Illinois"
    },
    {
      "city": "Pleasant Plains",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pleasant Plains, Illinois"
    },
    {
      "city": "Sherman",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sherman, Illinois"
    },
    {
      "city": "Thayer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Thayer, Illinois"
    },
    {
      "city": "Williamsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Williamsville, Illinois"
    },
    {
      "city": "Springfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Springfield, Illinois"
    },
    {
      "city": "Littleton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Littleton, Illinois"
    },
    {
      "city": "Camden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Camden, Illinois"
    },
    {
      "city": "Huntsville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Huntsville, Illinois"
    },
    {
      "city": "Browning",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Browning, Illinois"
    },
    {
      "city": "Frederick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Frederick, Illinois"
    },
    {
      "city": "Rushville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rushville, Illinois"
    },
    {
      "city": "Alsey",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alsey, Illinois"
    },
    {
      "city": "Bluffs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bluffs, Illinois"
    },
    {
      "city": "Manchester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Manchester, Illinois"
    },
    {
      "city": "Winchester",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winchester, Illinois"
    },
    {
      "city": "Windsor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Windsor, Illinois"
    },
    {
      "city": "Cowden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cowden, Illinois"
    },
    {
      "city": "Herrick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Herrick, Illinois"
    },
    {
      "city": "Lakewood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lakewood, Illinois"
    },
    {
      "city": "Mode",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mode, Illinois"
    },
    {
      "city": "Sigel",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sigel, Illinois"
    },
    {
      "city": "Stewardson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Stewardson, Illinois"
    },
    {
      "city": "Strasburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Strasburg, Illinois"
    },
    {
      "city": "Findlay",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Findlay, Illinois"
    },
    {
      "city": "Moweaqua",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Moweaqua, Illinois"
    },
    {
      "city": "Oconee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oconee, Illinois"
    },
    {
      "city": "Shelbyville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shelbyville, Illinois"
    },
    {
      "city": "Tower Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tower Hill, Illinois"
    },
    {
      "city": "Bradford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bradford, Illinois"
    },
    {
      "city": "Castleton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Castleton, Illinois"
    },
    {
      "city": "La Fayette",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "La Fayette, Illinois"
    },
    {
      "city": "Speer",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Speer, Illinois"
    },
    {
      "city": "Toulon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Toulon, Illinois"
    },
    {
      "city": "Wyoming",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wyoming, Illinois"
    },
    {
      "city": "Princeville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Princeville, Illinois"
    },
    {
      "city": "Cedarville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cedarville, Illinois"
    },
    {
      "city": "Dakota",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dakota, Illinois"
    },
    {
      "city": "Davis",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Davis, Illinois"
    },
    {
      "city": "Eleroy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eleroy, Illinois"
    },
    {
      "city": "Freeport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Freeport, Illinois"
    },
    {
      "city": "German Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "German Valley, Illinois"
    },
    {
      "city": "Kent",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kent, Illinois"
    },
    {
      "city": "Lena",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lena, Illinois"
    },
    {
      "city": "Mc Connell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mc Connell, Illinois"
    },
    {
      "city": "Orangeville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Orangeville, Illinois"
    },
    {
      "city": "Pearl City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pearl City, Illinois"
    },
    {
      "city": "Ridott",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ridott, Illinois"
    },
    {
      "city": "Rock City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rock City, Illinois"
    },
    {
      "city": "Winslow",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winslow, Illinois"
    },
    {
      "city": "Green Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Green Valley, Illinois"
    },
    {
      "city": "Groveland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Groveland, Illinois"
    },
    {
      "city": "Morton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Morton, Illinois"
    },
    {
      "city": "Pekin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pekin, Illinois"
    },
    {
      "city": "South Pekin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Pekin, Illinois"
    },
    {
      "city": "Tremont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tremont, Illinois"
    },
    {
      "city": "Washington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Washington, Illinois"
    },
    {
      "city": "Creve Coeur",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Creve Coeur, Illinois"
    },
    {
      "city": "East Peoria",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Peoria, Illinois"
    },
    {
      "city": "Armington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Armington, Illinois"
    },
    {
      "city": "Deer Creek",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Deer Creek, Illinois"
    },
    {
      "city": "Delavan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Delavan, Illinois"
    },
    {
      "city": "Hopedale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hopedale, Illinois"
    },
    {
      "city": "Mackinaw",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mackinaw, Illinois"
    },
    {
      "city": "Minier",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Minier, Illinois"
    },
    {
      "city": "Alto Pass",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alto Pass, Illinois"
    },
    {
      "city": "Anna",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Anna, Illinois"
    },
    {
      "city": "Cobden",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cobden, Illinois"
    },
    {
      "city": "Dongola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Dongola, Illinois"
    },
    {
      "city": "Jonesboro",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Jonesboro, Illinois"
    },
    {
      "city": "Millcreek",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Millcreek, Illinois"
    },
    {
      "city": "Wolf Lake",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wolf Lake, Illinois"
    },
    {
      "city": "East Lynn",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "East Lynn, Illinois"
    },
    {
      "city": "Hoopeston",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hoopeston, Illinois"
    },
    {
      "city": "Rankin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rankin, Illinois"
    },
    {
      "city": "Rossville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rossville, Illinois"
    },
    {
      "city": "Allerton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Allerton, Illinois"
    },
    {
      "city": "Alvin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Alvin, Illinois"
    },
    {
      "city": "Armstrong",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Armstrong, Illinois"
    },
    {
      "city": "Bismarck",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bismarck, Illinois"
    },
    {
      "city": "Catlin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Catlin, Illinois"
    },
    {
      "city": "Collison",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Collison, Illinois"
    },
    {
      "city": "Danville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Danville, Illinois"
    },
    {
      "city": "Tilton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tilton, Illinois"
    },
    {
      "city": "Fairmount",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fairmount, Illinois"
    },
    {
      "city": "Fithian",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fithian, Illinois"
    },
    {
      "city": "Georgetown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Georgetown, Illinois"
    },
    {
      "city": "Henning",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Henning, Illinois"
    },
    {
      "city": "Indianola",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Indianola, Illinois"
    },
    {
      "city": "Muncie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Muncie, Illinois"
    },
    {
      "city": "Oakwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oakwood, Illinois"
    },
    {
      "city": "Potomac",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Potomac, Illinois"
    },
    {
      "city": "Ridge Farm",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ridge Farm, Illinois"
    },
    {
      "city": "Sidell",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sidell, Illinois"
    },
    {
      "city": "Westville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Westville, Illinois"
    },
    {
      "city": "Allendale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Allendale, Illinois"
    },
    {
      "city": "Bellmont",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bellmont, Illinois"
    },
    {
      "city": "Keensburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Keensburg, Illinois"
    },
    {
      "city": "Lancaster",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lancaster, Illinois"
    },
    {
      "city": "Mount Carmel",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Carmel, Illinois"
    },
    {
      "city": "Berwick",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Berwick, Illinois"
    },
    {
      "city": "Cameron",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cameron, Illinois"
    },
    {
      "city": "Gerlaw",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Gerlaw, Illinois"
    },
    {
      "city": "Kirkwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Kirkwood, Illinois"
    },
    {
      "city": "Little York",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Little York, Illinois"
    },
    {
      "city": "Monmouth",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Monmouth, Illinois"
    },
    {
      "city": "Roseville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roseville, Illinois"
    },
    {
      "city": "Smithshire",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Smithshire, Illinois"
    },
    {
      "city": "Addieville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Addieville, Illinois"
    },
    {
      "city": "Nashville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Nashville, Illinois"
    },
    {
      "city": "Oakdale",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Oakdale, Illinois"
    },
    {
      "city": "Okawville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Okawville, Illinois"
    },
    {
      "city": "Hoyleton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hoyleton, Illinois"
    },
    {
      "city": "Ashley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Ashley, Illinois"
    },
    {
      "city": "Du Bois",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Du Bois, Illinois"
    },
    {
      "city": "Irvington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Irvington, Illinois"
    },
    {
      "city": "Radom",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Radom, Illinois"
    },
    {
      "city": "Richview",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Richview, Illinois"
    },
    {
      "city": "Mount Erie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mount Erie, Illinois"
    },
    {
      "city": "Barnhill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Barnhill, Illinois"
    },
    {
      "city": "Cisne",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cisne, Illinois"
    },
    {
      "city": "Fairfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fairfield, Illinois"
    },
    {
      "city": "Geff",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Geff, Illinois"
    },
    {
      "city": "Golden Gate",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Golden Gate, Illinois"
    },
    {
      "city": "Johnsonville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Johnsonville, Illinois"
    },
    {
      "city": "Keenes",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Keenes, Illinois"
    },
    {
      "city": "Rinard",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rinard, Illinois"
    },
    {
      "city": "Sims",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sims, Illinois"
    },
    {
      "city": "Wayne City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wayne City, Illinois"
    },
    {
      "city": "Burnt Prairie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Burnt Prairie, Illinois"
    },
    {
      "city": "Carmi",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carmi, Illinois"
    },
    {
      "city": "Crossville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crossville, Illinois"
    },
    {
      "city": "Emma",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Emma, Illinois"
    },
    {
      "city": "Enfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Enfield, Illinois"
    },
    {
      "city": "Grayville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Grayville, Illinois"
    },
    {
      "city": "Maunie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Maunie, Illinois"
    },
    {
      "city": "Mill Shoals",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mill Shoals, Illinois"
    },
    {
      "city": "Norris City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Norris City, Illinois"
    },
    {
      "city": "Springerton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Springerton, Illinois"
    },
    {
      "city": "Galt",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Galt, Illinois"
    },
    {
      "city": "Rock Falls",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rock Falls, Illinois"
    },
    {
      "city": "Sterling",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Sterling, Illinois"
    },
    {
      "city": "Albany",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Albany, Illinois"
    },
    {
      "city": "Deer Grove",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Deer Grove, Illinois"
    },
    {
      "city": "Erie",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Erie, Illinois"
    },
    {
      "city": "Fenton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fenton, Illinois"
    },
    {
      "city": "Fulton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Fulton, Illinois"
    },
    {
      "city": "Lyndon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lyndon, Illinois"
    },
    {
      "city": "Morrison",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Morrison, Illinois"
    },
    {
      "city": "Prophetstown",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Prophetstown, Illinois"
    },
    {
      "city": "Tampico",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Tampico, Illinois"
    },
    {
      "city": "Beecher",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Beecher, Illinois"
    },
    {
      "city": "Crest Hill",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crest Hill, Illinois"
    },
    {
      "city": "Shorewood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shorewood, Illinois"
    },
    {
      "city": "Braidwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Braidwood, Illinois"
    },
    {
      "city": "Channahon",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Channahon, Illinois"
    },
    {
      "city": "Crete",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Crete, Illinois"
    },
    {
      "city": "Elwood",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Elwood, Illinois"
    },
    {
      "city": "Frankfort",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Frankfort, Illinois"
    },
    {
      "city": "Markham",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Markham, Illinois"
    },
    {
      "city": "Joliet",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Joliet, Illinois"
    },
    {
      "city": "Bolingbrook",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Bolingbrook, Illinois"
    },
    {
      "city": "Lockport",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lockport, Illinois"
    },
    {
      "city": "Manhattan",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Manhattan, Illinois"
    },
    {
      "city": "Romeoville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Romeoville, Illinois"
    },
    {
      "city": "Mokena",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Mokena, Illinois"
    },
    {
      "city": "Monee",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Monee, Illinois"
    },
    {
      "city": "New Lenox",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "New Lenox, Illinois"
    },
    {
      "city": "Peotone",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Peotone, Illinois"
    },
    {
      "city": "Wilmington",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Wilmington, Illinois"
    },
    {
      "city": "University Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "University Park, Illinois"
    },
    {
      "city": "Homer Glen",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Homer Glen, Illinois"
    },
    {
      "city": "Plainfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Plainfield, Illinois"
    },
    {
      "city": "Freeman Spur",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Freeman Spur, Illinois"
    },
    {
      "city": "Cambria",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cambria, Illinois"
    },
    {
      "city": "Carterville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Carterville, Illinois"
    },
    {
      "city": "Colp",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Colp, Illinois"
    },
    {
      "city": "Creal Springs",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Creal Springs, Illinois"
    },
    {
      "city": "Energy",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Energy, Illinois"
    },
    {
      "city": "Herrin",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Herrin, Illinois"
    },
    {
      "city": "Hurst",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Hurst, Illinois"
    },
    {
      "city": "Johnston City",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Johnston City, Illinois"
    },
    {
      "city": "Marion",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Marion, Illinois"
    },
    {
      "city": "Pittsburg",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pittsburg, Illinois"
    },
    {
      "city": "Cherry Valley",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Cherry Valley, Illinois"
    },
    {
      "city": "Durand",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Durand, Illinois"
    },
    {
      "city": "Pecatonica",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Pecatonica, Illinois"
    },
    {
      "city": "Rockton",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rockton, Illinois"
    },
    {
      "city": "Roscoe",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roscoe, Illinois"
    },
    {
      "city": "Seward",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Seward, Illinois"
    },
    {
      "city": "Shirland",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Shirland, Illinois"
    },
    {
      "city": "South Beloit",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "South Beloit, Illinois"
    },
    {
      "city": "Winnebago",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Winnebago, Illinois"
    },
    {
      "city": "Rockford",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Rockford, Illinois"
    },
    {
      "city": "Loves Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Loves Park, Illinois"
    },
    {
      "city": "Machesney Park",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Machesney Park, Illinois"
    },
    {
      "city": "Benson",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Benson, Illinois"
    },
    {
      "city": "Eureka",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Eureka, Illinois"
    },
    {
      "city": "Lowpoint",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Lowpoint, Illinois"
    },
    {
      "city": "Metamora",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Metamora, Illinois"
    },
    {
      "city": "Roanoke",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Roanoke, Illinois"
    },
    {
      "city": "Congerville",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Congerville, Illinois"
    },
    {
      "city": "El Paso",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "El Paso, Illinois"
    },
    {
      "city": "Goodfield",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Goodfield, Illinois"
    },
    {
      "city": "Minonk",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Minonk, Illinois"
    },
    {
      "city": "Secor",
      "state": "Illinois",
      "shortState": "IL",
      "formatedCity": "Secor, Illinois"
    },
    {
      "city": "Berne",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Berne, Indiana"
    },
    {
      "city": "Decatur",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Decatur, Indiana"
    },
    {
      "city": "Geneva",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Geneva, Indiana"
    },
    {
      "city": "Linn Grove",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Linn Grove, Indiana"
    },
    {
      "city": "Monroe",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monroe, Indiana"
    },
    {
      "city": "Pleasant Mills",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pleasant Mills, Indiana"
    },
    {
      "city": "Preble",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Preble, Indiana"
    },
    {
      "city": "Arcola",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Arcola, Indiana"
    },
    {
      "city": "Grabill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grabill, Indiana"
    },
    {
      "city": "Harlan",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Harlan, Indiana"
    },
    {
      "city": "Hoagland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hoagland, Indiana"
    },
    {
      "city": "Huntertown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Huntertown, Indiana"
    },
    {
      "city": "Leo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leo, Indiana"
    },
    {
      "city": "Monroeville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monroeville, Indiana"
    },
    {
      "city": "New Haven",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Haven, Indiana"
    },
    {
      "city": "Woodburn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Woodburn, Indiana"
    },
    {
      "city": "Yoder",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Yoder, Indiana"
    },
    {
      "city": "Zanesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Zanesville, Indiana"
    },
    {
      "city": "Fort Wayne",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fort Wayne, Indiana"
    },
    {
      "city": "Columbus",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Columbus, Indiana"
    },
    {
      "city": "Clifford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clifford, Indiana"
    },
    {
      "city": "Elizabethtown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elizabethtown, Indiana"
    },
    {
      "city": "Grammer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grammer, Indiana"
    },
    {
      "city": "Hartsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hartsville, Indiana"
    },
    {
      "city": "Hope",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hope, Indiana"
    },
    {
      "city": "Jonesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jonesville, Indiana"
    },
    {
      "city": "Taylorsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Taylorsville, Indiana"
    },
    {
      "city": "Ambia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ambia, Indiana"
    },
    {
      "city": "Boswell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Boswell, Indiana"
    },
    {
      "city": "Earl Park",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Earl Park, Indiana"
    },
    {
      "city": "Fowler",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fowler, Indiana"
    },
    {
      "city": "Otterbein",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Otterbein, Indiana"
    },
    {
      "city": "Oxford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oxford, Indiana"
    },
    {
      "city": "Talbot",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Talbot, Indiana"
    },
    {
      "city": "Templeton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Templeton, Indiana"
    },
    {
      "city": "Hartford City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hartford City, Indiana"
    },
    {
      "city": "Montpelier",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Montpelier, Indiana"
    },
    {
      "city": "Lebanon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lebanon, Indiana"
    },
    {
      "city": "Thorntown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Thorntown, Indiana"
    },
    {
      "city": "Whitestown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Whitestown, Indiana"
    },
    {
      "city": "Zionsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Zionsville, Indiana"
    },
    {
      "city": "Advance",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Advance, Indiana"
    },
    {
      "city": "Jamestown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jamestown, Indiana"
    },
    {
      "city": "Morgantown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Morgantown, Indiana"
    },
    {
      "city": "Helmsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Helmsburg, Indiana"
    },
    {
      "city": "Nashville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Nashville, Indiana"
    },
    {
      "city": "Bringhurst",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bringhurst, Indiana"
    },
    {
      "city": "Burlington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Burlington, Indiana"
    },
    {
      "city": "Burrows",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Burrows, Indiana"
    },
    {
      "city": "Camden",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Camden, Indiana"
    },
    {
      "city": "Cutler",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cutler, Indiana"
    },
    {
      "city": "Delphi",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Delphi, Indiana"
    },
    {
      "city": "Flora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Flora, Indiana"
    },
    {
      "city": "Rockfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rockfield, Indiana"
    },
    {
      "city": "Yeoman",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Yeoman, Indiana"
    },
    {
      "city": "Galveston",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Galveston, Indiana"
    },
    {
      "city": "Lake Cicott",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lake Cicott, Indiana"
    },
    {
      "city": "Logansport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Logansport, Indiana"
    },
    {
      "city": "Lucerne",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lucerne, Indiana"
    },
    {
      "city": "New Waverly",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Waverly, Indiana"
    },
    {
      "city": "Onward",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Onward, Indiana"
    },
    {
      "city": "Royal Center",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Royal Center, Indiana"
    },
    {
      "city": "Twelve Mile",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Twelve Mile, Indiana"
    },
    {
      "city": "Walton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Walton, Indiana"
    },
    {
      "city": "Young America",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Young America, Indiana"
    },
    {
      "city": "Bethlehem",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bethlehem, Indiana"
    },
    {
      "city": "Borden",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Borden, Indiana"
    },
    {
      "city": "Charlestown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Charlestown, Indiana"
    },
    {
      "city": "Henryville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Henryville, Indiana"
    },
    {
      "city": "Clarksville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clarksville, Indiana"
    },
    {
      "city": "Jeffersonville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jeffersonville, Indiana"
    },
    {
      "city": "Marysville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Marysville, Indiana"
    },
    {
      "city": "Memphis",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Memphis, Indiana"
    },
    {
      "city": "Nabb",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Nabb, Indiana"
    },
    {
      "city": "New Washington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Washington, Indiana"
    },
    {
      "city": "Otisco",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Otisco, Indiana"
    },
    {
      "city": "Sellersburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sellersburg, Indiana"
    },
    {
      "city": "Underwood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Underwood, Indiana"
    },
    {
      "city": "Bowling Green",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bowling Green, Indiana"
    },
    {
      "city": "Brazil",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brazil, Indiana"
    },
    {
      "city": "Carbon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Carbon, Indiana"
    },
    {
      "city": "Centerpoint",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Centerpoint, Indiana"
    },
    {
      "city": "Clay City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clay City, Indiana"
    },
    {
      "city": "Coalmont",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Coalmont, Indiana"
    },
    {
      "city": "Cory",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cory, Indiana"
    },
    {
      "city": "Harmony",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Harmony, Indiana"
    },
    {
      "city": "Knightsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Knightsville, Indiana"
    },
    {
      "city": "Poland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Poland, Indiana"
    },
    {
      "city": "Staunton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Staunton, Indiana"
    },
    {
      "city": "Colfax",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Colfax, Indiana"
    },
    {
      "city": "Forest",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Forest, Indiana"
    },
    {
      "city": "Frankfort",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Frankfort, Indiana"
    },
    {
      "city": "Kirklin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kirklin, Indiana"
    },
    {
      "city": "Michigantown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Michigantown, Indiana"
    },
    {
      "city": "Mulberry",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mulberry, Indiana"
    },
    {
      "city": "Rossville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rossville, Indiana"
    },
    {
      "city": "Sedalia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sedalia, Indiana"
    },
    {
      "city": "Eckerty",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Eckerty, Indiana"
    },
    {
      "city": "English",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "English, Indiana"
    },
    {
      "city": "Grantsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grantsburg, Indiana"
    },
    {
      "city": "Leavenworth",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leavenworth, Indiana"
    },
    {
      "city": "Marengo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Marengo, Indiana"
    },
    {
      "city": "Milltown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Milltown, Indiana"
    },
    {
      "city": "Sulphur",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sulphur, Indiana"
    },
    {
      "city": "Taswell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Taswell, Indiana"
    },
    {
      "city": "Washington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Washington, Indiana"
    },
    {
      "city": "Cannelburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cannelburg, Indiana"
    },
    {
      "city": "Elnora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elnora, Indiana"
    },
    {
      "city": "Montgomery",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Montgomery, Indiana"
    },
    {
      "city": "Odon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Odon, Indiana"
    },
    {
      "city": "Plainville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Plainville, Indiana"
    },
    {
      "city": "Aurora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Aurora, Indiana"
    },
    {
      "city": "Dillsboro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dillsboro, Indiana"
    },
    {
      "city": "Guilford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Guilford, Indiana"
    },
    {
      "city": "Lawrenceburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lawrenceburg, Indiana"
    },
    {
      "city": "Moores Hill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Moores Hill, Indiana"
    },
    {
      "city": "West Harrison",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Harrison, Indiana"
    },
    {
      "city": "Clarksburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clarksburg, Indiana"
    },
    {
      "city": "Greensburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greensburg, Indiana"
    },
    {
      "city": "Millhousen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Millhousen, Indiana"
    },
    {
      "city": "New Point",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Point, Indiana"
    },
    {
      "city": "Saint Paul",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Paul, Indiana"
    },
    {
      "city": "Westport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Westport, Indiana"
    },
    {
      "city": "Ashley",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ashley, Indiana"
    },
    {
      "city": "Auburn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Auburn, Indiana"
    },
    {
      "city": "Butler",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Butler, Indiana"
    },
    {
      "city": "Corunna",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Corunna, Indiana"
    },
    {
      "city": "Garrett",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Garrett, Indiana"
    },
    {
      "city": "Saint Joe",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Joe, Indiana"
    },
    {
      "city": "Spencerville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Spencerville, Indiana"
    },
    {
      "city": "Waterloo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Waterloo, Indiana"
    },
    {
      "city": "Muncie",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Muncie, Indiana"
    },
    {
      "city": "Albany",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Albany, Indiana"
    },
    {
      "city": "Daleville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Daleville, Indiana"
    },
    {
      "city": "Eaton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Eaton, Indiana"
    },
    {
      "city": "Gaston",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gaston, Indiana"
    },
    {
      "city": "Oakville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oakville, Indiana"
    },
    {
      "city": "Selma",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Selma, Indiana"
    },
    {
      "city": "Yorktown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Yorktown, Indiana"
    },
    {
      "city": "Birdseye",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Birdseye, Indiana"
    },
    {
      "city": "Celestine",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Celestine, Indiana"
    },
    {
      "city": "Dubois",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dubois, Indiana"
    },
    {
      "city": "Ferdinand",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ferdinand, Indiana"
    },
    {
      "city": "Holland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Holland, Indiana"
    },
    {
      "city": "Huntingburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Huntingburg, Indiana"
    },
    {
      "city": "Ireland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ireland, Indiana"
    },
    {
      "city": "Jasper",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jasper, Indiana"
    },
    {
      "city": "Saint Anthony",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Anthony, Indiana"
    },
    {
      "city": "Schnellville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Schnellville, Indiana"
    },
    {
      "city": "Bristol",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bristol, Indiana"
    },
    {
      "city": "Elkhart",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elkhart, Indiana"
    },
    {
      "city": "Goshen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Goshen, Indiana"
    },
    {
      "city": "Middlebury",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Middlebury, Indiana"
    },
    {
      "city": "Millersburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Millersburg, Indiana"
    },
    {
      "city": "Nappanee",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Nappanee, Indiana"
    },
    {
      "city": "New Paris",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Paris, Indiana"
    },
    {
      "city": "Wakarusa",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wakarusa, Indiana"
    },
    {
      "city": "Glenwood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Glenwood, Indiana"
    },
    {
      "city": "Bentonville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bentonville, Indiana"
    },
    {
      "city": "Connersville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Connersville, Indiana"
    },
    {
      "city": "Floyds Knobs",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Floyds Knobs, Indiana"
    },
    {
      "city": "Georgetown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Georgetown, Indiana"
    },
    {
      "city": "Greenville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greenville, Indiana"
    },
    {
      "city": "Mount Saint Francis",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mount Saint Francis, Indiana"
    },
    {
      "city": "New Albany",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Albany, Indiana"
    },
    {
      "city": "Attica",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Attica, Indiana"
    },
    {
      "city": "Covington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Covington, Indiana"
    },
    {
      "city": "Hillsboro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hillsboro, Indiana"
    },
    {
      "city": "Kingman",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kingman, Indiana"
    },
    {
      "city": "Mellott",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mellott, Indiana"
    },
    {
      "city": "Newtown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Newtown, Indiana"
    },
    {
      "city": "Veedersburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Veedersburg, Indiana"
    },
    {
      "city": "Wallace",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wallace, Indiana"
    },
    {
      "city": "West College Corner",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West College Corner, Indiana"
    },
    {
      "city": "Bath",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bath, Indiana"
    },
    {
      "city": "Brookville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brookville, Indiana"
    },
    {
      "city": "Cedar Grove",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cedar Grove, Indiana"
    },
    {
      "city": "Laurel",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Laurel, Indiana"
    },
    {
      "city": "Metamora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Metamora, Indiana"
    },
    {
      "city": "New Trenton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Trenton, Indiana"
    },
    {
      "city": "Oldenburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oldenburg, Indiana"
    },
    {
      "city": "Akron",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Akron, Indiana"
    },
    {
      "city": "Athens",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Athens, Indiana"
    },
    {
      "city": "Delong",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Delong, Indiana"
    },
    {
      "city": "Fulton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fulton, Indiana"
    },
    {
      "city": "Grass Creek",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grass Creek, Indiana"
    },
    {
      "city": "Kewanna",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kewanna, Indiana"
    },
    {
      "city": "Leiters Ford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leiters Ford, Indiana"
    },
    {
      "city": "Rochester",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rochester, Indiana"
    },
    {
      "city": "Haubstadt",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Haubstadt, Indiana"
    },
    {
      "city": "Hazleton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hazleton, Indiana"
    },
    {
      "city": "Buckskin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Buckskin, Indiana"
    },
    {
      "city": "Fort Branch",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fort Branch, Indiana"
    },
    {
      "city": "Francisco",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Francisco, Indiana"
    },
    {
      "city": "Mackey",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mackey, Indiana"
    },
    {
      "city": "Oakland City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oakland City, Indiana"
    },
    {
      "city": "Owensville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Owensville, Indiana"
    },
    {
      "city": "Patoka",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Patoka, Indiana"
    },
    {
      "city": "Princeton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Princeton, Indiana"
    },
    {
      "city": "Somerville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Somerville, Indiana"
    },
    {
      "city": "Fairmount",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fairmount, Indiana"
    },
    {
      "city": "Fowlerton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fowlerton, Indiana"
    },
    {
      "city": "Gas City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gas City, Indiana"
    },
    {
      "city": "Jonesboro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jonesboro, Indiana"
    },
    {
      "city": "Marion",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Marion, Indiana"
    },
    {
      "city": "Matthews",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Matthews, Indiana"
    },
    {
      "city": "Swayzee",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Swayzee, Indiana"
    },
    {
      "city": "Sweetser",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sweetser, Indiana"
    },
    {
      "city": "Upland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Upland, Indiana"
    },
    {
      "city": "Van Buren",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Van Buren, Indiana"
    },
    {
      "city": "Bloomfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bloomfield, Indiana"
    },
    {
      "city": "Jasonville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Jasonville, Indiana"
    },
    {
      "city": "Koleen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Koleen, Indiana"
    },
    {
      "city": "Linton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Linton, Indiana"
    },
    {
      "city": "Lyons",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lyons, Indiana"
    },
    {
      "city": "Midland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Midland, Indiana"
    },
    {
      "city": "Newberry",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Newberry, Indiana"
    },
    {
      "city": "Owensburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Owensburg, Indiana"
    },
    {
      "city": "Scotland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Scotland, Indiana"
    },
    {
      "city": "Solsberry",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Solsberry, Indiana"
    },
    {
      "city": "Switz City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Switz City, Indiana"
    },
    {
      "city": "Worthington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Worthington, Indiana"
    },
    {
      "city": "Arcadia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Arcadia, Indiana"
    },
    {
      "city": "Atlanta",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Atlanta, Indiana"
    },
    {
      "city": "Carmel",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Carmel, Indiana"
    },
    {
      "city": "Cicero",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cicero, Indiana"
    },
    {
      "city": "Fishers",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fishers, Indiana"
    },
    {
      "city": "Noblesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Noblesville, Indiana"
    },
    {
      "city": "Sheridan",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sheridan, Indiana"
    },
    {
      "city": "Westfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Westfield, Indiana"
    },
    {
      "city": "Indianapolis",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Indianapolis, Indiana"
    },
    {
      "city": "Fortville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fortville, Indiana"
    },
    {
      "city": "Mccordsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mccordsville, Indiana"
    },
    {
      "city": "Charlottesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Charlottesville, Indiana"
    },
    {
      "city": "Finly",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Finly, Indiana"
    },
    {
      "city": "Greenfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greenfield, Indiana"
    },
    {
      "city": "Maxwell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Maxwell, Indiana"
    },
    {
      "city": "New Palestine",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Palestine, Indiana"
    },
    {
      "city": "Wilkinson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wilkinson, Indiana"
    },
    {
      "city": "Bradford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bradford, Indiana"
    },
    {
      "city": "Central",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Central, Indiana"
    },
    {
      "city": "Corydon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Corydon, Indiana"
    },
    {
      "city": "Crandall",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Crandall, Indiana"
    },
    {
      "city": "Depauw",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Depauw, Indiana"
    },
    {
      "city": "Elizabeth",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elizabeth, Indiana"
    },
    {
      "city": "Laconia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Laconia, Indiana"
    },
    {
      "city": "Lanesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lanesville, Indiana"
    },
    {
      "city": "Mauckport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mauckport, Indiana"
    },
    {
      "city": "New Middletown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Middletown, Indiana"
    },
    {
      "city": "New Salisbury",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Salisbury, Indiana"
    },
    {
      "city": "Palmyra",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Palmyra, Indiana"
    },
    {
      "city": "Ramsey",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ramsey, Indiana"
    },
    {
      "city": "Amo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Amo, Indiana"
    },
    {
      "city": "Brownsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brownsburg, Indiana"
    },
    {
      "city": "Clayton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clayton, Indiana"
    },
    {
      "city": "Coatesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Coatesville, Indiana"
    },
    {
      "city": "Danville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Danville, Indiana"
    },
    {
      "city": "Avon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Avon, Indiana"
    },
    {
      "city": "Lizton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lizton, Indiana"
    },
    {
      "city": "North Salem",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Salem, Indiana"
    },
    {
      "city": "Pittsboro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pittsboro, Indiana"
    },
    {
      "city": "Plainfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Plainfield, Indiana"
    },
    {
      "city": "Stilesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stilesville, Indiana"
    },
    {
      "city": "Knightstown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Knightstown, Indiana"
    },
    {
      "city": "Dunreith",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dunreith, Indiana"
    },
    {
      "city": "Greensboro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greensboro, Indiana"
    },
    {
      "city": "Kennard",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kennard, Indiana"
    },
    {
      "city": "Lewisville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lewisville, Indiana"
    },
    {
      "city": "Middletown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Middletown, Indiana"
    },
    {
      "city": "Mooreland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mooreland, Indiana"
    },
    {
      "city": "Mount Summit",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mount Summit, Indiana"
    },
    {
      "city": "New Castle",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Castle, Indiana"
    },
    {
      "city": "New Lisbon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Lisbon, Indiana"
    },
    {
      "city": "Shirley",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shirley, Indiana"
    },
    {
      "city": "Spiceland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Spiceland, Indiana"
    },
    {
      "city": "Springport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Springport, Indiana"
    },
    {
      "city": "Straughn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Straughn, Indiana"
    },
    {
      "city": "Sulphur Springs",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sulphur Springs, Indiana"
    },
    {
      "city": "Kokomo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kokomo, Indiana"
    },
    {
      "city": "Greentown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greentown, Indiana"
    },
    {
      "city": "Hemlock",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hemlock, Indiana"
    },
    {
      "city": "Oakford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oakford, Indiana"
    },
    {
      "city": "Russiaville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Russiaville, Indiana"
    },
    {
      "city": "West Middleton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Middleton, Indiana"
    },
    {
      "city": "Andrews",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Andrews, Indiana"
    },
    {
      "city": "Bippus",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bippus, Indiana"
    },
    {
      "city": "Huntington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Huntington, Indiana"
    },
    {
      "city": "Markle",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Markle, Indiana"
    },
    {
      "city": "Roanoke",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Roanoke, Indiana"
    },
    {
      "city": "Warren",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Warren, Indiana"
    },
    {
      "city": "Brownstown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brownstown, Indiana"
    },
    {
      "city": "Cortland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cortland, Indiana"
    },
    {
      "city": "Crothersville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Crothersville, Indiana"
    },
    {
      "city": "Freetown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Freetown, Indiana"
    },
    {
      "city": "Kurtz",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kurtz, Indiana"
    },
    {
      "city": "Medora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Medora, Indiana"
    },
    {
      "city": "Norman",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Norman, Indiana"
    },
    {
      "city": "Seymour",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Seymour, Indiana"
    },
    {
      "city": "Vallonia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Vallonia, Indiana"
    },
    {
      "city": "Demotte",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Demotte, Indiana"
    },
    {
      "city": "Tefft",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tefft, Indiana"
    },
    {
      "city": "Wheatfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wheatfield, Indiana"
    },
    {
      "city": "Fair Oaks",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fair Oaks, Indiana"
    },
    {
      "city": "Remington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Remington, Indiana"
    },
    {
      "city": "Rensselaer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rensselaer, Indiana"
    },
    {
      "city": "Bryant",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bryant, Indiana"
    },
    {
      "city": "Dunkirk",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dunkirk, Indiana"
    },
    {
      "city": "Pennville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pennville, Indiana"
    },
    {
      "city": "Portland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Portland, Indiana"
    },
    {
      "city": "Redkey",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Redkey, Indiana"
    },
    {
      "city": "Salamonia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Salamonia, Indiana"
    },
    {
      "city": "Canaan",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Canaan, Indiana"
    },
    {
      "city": "Deputy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Deputy, Indiana"
    },
    {
      "city": "Dupont",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dupont, Indiana"
    },
    {
      "city": "Hanover",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hanover, Indiana"
    },
    {
      "city": "Madison",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Madison, Indiana"
    },
    {
      "city": "Butlerville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Butlerville, Indiana"
    },
    {
      "city": "Commiskey",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Commiskey, Indiana"
    },
    {
      "city": "Hayden",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hayden, Indiana"
    },
    {
      "city": "North Vernon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Vernon, Indiana"
    },
    {
      "city": "Paris Crossing",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Paris Crossing, Indiana"
    },
    {
      "city": "Scipio",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Scipio, Indiana"
    },
    {
      "city": "Vernon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Vernon, Indiana"
    },
    {
      "city": "Bargersville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bargersville, Indiana"
    },
    {
      "city": "Edinburgh",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Edinburgh, Indiana"
    },
    {
      "city": "Franklin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Franklin, Indiana"
    },
    {
      "city": "Greenwood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greenwood, Indiana"
    },
    {
      "city": "Needham",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Needham, Indiana"
    },
    {
      "city": "Nineveh",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Nineveh, Indiana"
    },
    {
      "city": "Trafalgar",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Trafalgar, Indiana"
    },
    {
      "city": "Whiteland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Whiteland, Indiana"
    },
    {
      "city": "Bicknell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bicknell, Indiana"
    },
    {
      "city": "Bruceville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bruceville, Indiana"
    },
    {
      "city": "Decker",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Decker, Indiana"
    },
    {
      "city": "Edwardsport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Edwardsport, Indiana"
    },
    {
      "city": "Freelandville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Freelandville, Indiana"
    },
    {
      "city": "Monroe City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monroe City, Indiana"
    },
    {
      "city": "Oaktown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oaktown, Indiana"
    },
    {
      "city": "Ragsdale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ragsdale, Indiana"
    },
    {
      "city": "Sandborn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sandborn, Indiana"
    },
    {
      "city": "Vincennes",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Vincennes, Indiana"
    },
    {
      "city": "Westphalia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Westphalia, Indiana"
    },
    {
      "city": "Wheatland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wheatland, Indiana"
    },
    {
      "city": "Atwood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Atwood, Indiana"
    },
    {
      "city": "Burket",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Burket, Indiana"
    },
    {
      "city": "Claypool",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Claypool, Indiana"
    },
    {
      "city": "Etna Green",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Etna Green, Indiana"
    },
    {
      "city": "Leesburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leesburg, Indiana"
    },
    {
      "city": "Mentone",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mentone, Indiana"
    },
    {
      "city": "Milford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Milford, Indiana"
    },
    {
      "city": "North Webster",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Webster, Indiana"
    },
    {
      "city": "Pierceton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pierceton, Indiana"
    },
    {
      "city": "Syracuse",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Syracuse, Indiana"
    },
    {
      "city": "Warsaw",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Warsaw, Indiana"
    },
    {
      "city": "Winona Lake",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Winona Lake, Indiana"
    },
    {
      "city": "Silver Lake",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Silver Lake, Indiana"
    },
    {
      "city": "Shipshewana",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shipshewana, Indiana"
    },
    {
      "city": "Topeka",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Topeka, Indiana"
    },
    {
      "city": "Howe",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Howe, Indiana"
    },
    {
      "city": "Lagrange",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lagrange, Indiana"
    },
    {
      "city": "Mongo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mongo, Indiana"
    },
    {
      "city": "South Milford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "South Milford, Indiana"
    },
    {
      "city": "Stroh",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stroh, Indiana"
    },
    {
      "city": "Wolcottville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wolcottville, Indiana"
    },
    {
      "city": "Cedar Lake",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cedar Lake, Indiana"
    },
    {
      "city": "Crown Point",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Crown Point, Indiana"
    },
    {
      "city": "Dyer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dyer, Indiana"
    },
    {
      "city": "East Chicago",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "East Chicago, Indiana"
    },
    {
      "city": "Griffith",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Griffith, Indiana"
    },
    {
      "city": "Hammond",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hammond, Indiana"
    },
    {
      "city": "Munster",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Munster, Indiana"
    },
    {
      "city": "Highland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Highland, Indiana"
    },
    {
      "city": "Hobart",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hobart, Indiana"
    },
    {
      "city": "Leroy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leroy, Indiana"
    },
    {
      "city": "Lowell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lowell, Indiana"
    },
    {
      "city": "Saint John",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint John, Indiana"
    },
    {
      "city": "Schererville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Schererville, Indiana"
    },
    {
      "city": "Schneider",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Schneider, Indiana"
    },
    {
      "city": "Shelby",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shelby, Indiana"
    },
    {
      "city": "Whiting",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Whiting, Indiana"
    },
    {
      "city": "Gary",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gary, Indiana"
    },
    {
      "city": "Lake Station",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lake Station, Indiana"
    },
    {
      "city": "Merrillville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Merrillville, Indiana"
    },
    {
      "city": "Hanna",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hanna, Indiana"
    },
    {
      "city": "Kingsbury",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kingsbury, Indiana"
    },
    {
      "city": "Kingsford Heights",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kingsford Heights, Indiana"
    },
    {
      "city": "La Crosse",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "La Crosse, Indiana"
    },
    {
      "city": "La Porte",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "La Porte, Indiana"
    },
    {
      "city": "Michigan City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Michigan City, Indiana"
    },
    {
      "city": "Mill Creek",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mill Creek, Indiana"
    },
    {
      "city": "Rolling Prairie",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rolling Prairie, Indiana"
    },
    {
      "city": "Union Mills",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Union Mills, Indiana"
    },
    {
      "city": "Wanatah",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wanatah, Indiana"
    },
    {
      "city": "Westville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Westville, Indiana"
    },
    {
      "city": "Avoca",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Avoca, Indiana"
    },
    {
      "city": "Bedford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bedford, Indiana"
    },
    {
      "city": "Heltonville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Heltonville, Indiana"
    },
    {
      "city": "Huron",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Huron, Indiana"
    },
    {
      "city": "Mitchell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mitchell, Indiana"
    },
    {
      "city": "Oolitic",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Oolitic, Indiana"
    },
    {
      "city": "Springville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Springville, Indiana"
    },
    {
      "city": "Tunnelton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tunnelton, Indiana"
    },
    {
      "city": "Williams",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Williams, Indiana"
    },
    {
      "city": "Alexandria",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Alexandria, Indiana"
    },
    {
      "city": "Anderson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Anderson, Indiana"
    },
    {
      "city": "Elwood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elwood, Indiana"
    },
    {
      "city": "Frankton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Frankton, Indiana"
    },
    {
      "city": "Ingalls",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ingalls, Indiana"
    },
    {
      "city": "Lapel",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lapel, Indiana"
    },
    {
      "city": "Markleville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Markleville, Indiana"
    },
    {
      "city": "Orestes",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Orestes, Indiana"
    },
    {
      "city": "Pendleton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pendleton, Indiana"
    },
    {
      "city": "Summitville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Summitville, Indiana"
    },
    {
      "city": "Beech Grove",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Beech Grove, Indiana"
    },
    {
      "city": "Camby",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Camby, Indiana"
    },
    {
      "city": "West Newton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Newton, Indiana"
    },
    {
      "city": "Argos",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Argos, Indiana"
    },
    {
      "city": "Bourbon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bourbon, Indiana"
    },
    {
      "city": "Bremen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bremen, Indiana"
    },
    {
      "city": "Culver",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Culver, Indiana"
    },
    {
      "city": "Donaldson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Donaldson, Indiana"
    },
    {
      "city": "Lapaz",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lapaz, Indiana"
    },
    {
      "city": "Plymouth",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Plymouth, Indiana"
    },
    {
      "city": "Tippecanoe",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tippecanoe, Indiana"
    },
    {
      "city": "Tyner",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tyner, Indiana"
    },
    {
      "city": "Crane",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Crane, Indiana"
    },
    {
      "city": "Loogootee",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Loogootee, Indiana"
    },
    {
      "city": "Shoals",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shoals, Indiana"
    },
    {
      "city": "Amboy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Amboy, Indiana"
    },
    {
      "city": "Bunker Hill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bunker Hill, Indiana"
    },
    {
      "city": "Converse",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Converse, Indiana"
    },
    {
      "city": "Deedsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Deedsville, Indiana"
    },
    {
      "city": "Denver",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Denver, Indiana"
    },
    {
      "city": "Macy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Macy, Indiana"
    },
    {
      "city": "Mexico",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mexico, Indiana"
    },
    {
      "city": "Miami",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Miami, Indiana"
    },
    {
      "city": "Peru",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Peru, Indiana"
    },
    {
      "city": "Grissom Arb",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grissom Arb, Indiana"
    },
    {
      "city": "Bloomington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bloomington, Indiana"
    },
    {
      "city": "Clear Creek",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clear Creek, Indiana"
    },
    {
      "city": "Ellettsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ellettsville, Indiana"
    },
    {
      "city": "Harrodsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Harrodsburg, Indiana"
    },
    {
      "city": "Smithville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Smithville, Indiana"
    },
    {
      "city": "Stanford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stanford, Indiana"
    },
    {
      "city": "Stinesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stinesville, Indiana"
    },
    {
      "city": "Unionville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Unionville, Indiana"
    },
    {
      "city": "Alamo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Alamo, Indiana"
    },
    {
      "city": "Crawfordsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Crawfordsville, Indiana"
    },
    {
      "city": "Darlington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Darlington, Indiana"
    },
    {
      "city": "Ladoga",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ladoga, Indiana"
    },
    {
      "city": "Linden",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Linden, Indiana"
    },
    {
      "city": "New Market",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Market, Indiana"
    },
    {
      "city": "New Richmond",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Richmond, Indiana"
    },
    {
      "city": "New Ross",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Ross, Indiana"
    },
    {
      "city": "Waveland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Waveland, Indiana"
    },
    {
      "city": "Waynetown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Waynetown, Indiana"
    },
    {
      "city": "Wingate",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wingate, Indiana"
    },
    {
      "city": "Brooklyn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brooklyn, Indiana"
    },
    {
      "city": "Eminence",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Eminence, Indiana"
    },
    {
      "city": "Martinsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Martinsville, Indiana"
    },
    {
      "city": "Monrovia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monrovia, Indiana"
    },
    {
      "city": "Mooresville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mooresville, Indiana"
    },
    {
      "city": "Paragon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Paragon, Indiana"
    },
    {
      "city": "Lake Village",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lake Village, Indiana"
    },
    {
      "city": "Roselawn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Roselawn, Indiana"
    },
    {
      "city": "Sumava Resorts",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sumava Resorts, Indiana"
    },
    {
      "city": "Thayer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Thayer, Indiana"
    },
    {
      "city": "Brook",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brook, Indiana"
    },
    {
      "city": "Goodland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Goodland, Indiana"
    },
    {
      "city": "Kentland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kentland, Indiana"
    },
    {
      "city": "Morocco",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Morocco, Indiana"
    },
    {
      "city": "Mount Ayr",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mount Ayr, Indiana"
    },
    {
      "city": "Albion",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Albion, Indiana"
    },
    {
      "city": "Avilla",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Avilla, Indiana"
    },
    {
      "city": "Cromwell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cromwell, Indiana"
    },
    {
      "city": "Kendallville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kendallville, Indiana"
    },
    {
      "city": "Kimmell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kimmell, Indiana"
    },
    {
      "city": "Laotto",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Laotto, Indiana"
    },
    {
      "city": "Ligonier",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ligonier, Indiana"
    },
    {
      "city": "Rome City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rome City, Indiana"
    },
    {
      "city": "Wawaka",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wawaka, Indiana"
    },
    {
      "city": "Wolflake",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wolflake, Indiana"
    },
    {
      "city": "Rising Sun",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rising Sun, Indiana"
    },
    {
      "city": "French Lick",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "French Lick, Indiana"
    },
    {
      "city": "Orleans",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Orleans, Indiana"
    },
    {
      "city": "Paoli",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Paoli, Indiana"
    },
    {
      "city": "West Baden Springs",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Baden Springs, Indiana"
    },
    {
      "city": "Coal City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Coal City, Indiana"
    },
    {
      "city": "Freedom",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Freedom, Indiana"
    },
    {
      "city": "Gosport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gosport, Indiana"
    },
    {
      "city": "Patricksburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Patricksburg, Indiana"
    },
    {
      "city": "Quincy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Quincy, Indiana"
    },
    {
      "city": "Spencer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Spencer, Indiana"
    },
    {
      "city": "Bellmore",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bellmore, Indiana"
    },
    {
      "city": "Bloomingdale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bloomingdale, Indiana"
    },
    {
      "city": "Bridgeton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bridgeton, Indiana"
    },
    {
      "city": "Marshall",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Marshall, Indiana"
    },
    {
      "city": "Mecca",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mecca, Indiana"
    },
    {
      "city": "Montezuma",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Montezuma, Indiana"
    },
    {
      "city": "Rockville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rockville, Indiana"
    },
    {
      "city": "Rosedale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rosedale, Indiana"
    },
    {
      "city": "Branchville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Branchville, Indiana"
    },
    {
      "city": "Bristow",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bristow, Indiana"
    },
    {
      "city": "Cannelton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cannelton, Indiana"
    },
    {
      "city": "Derby",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Derby, Indiana"
    },
    {
      "city": "Leopold",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Leopold, Indiana"
    },
    {
      "city": "Rome",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rome, Indiana"
    },
    {
      "city": "Saint Croix",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Croix, Indiana"
    },
    {
      "city": "Tell City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tell City, Indiana"
    },
    {
      "city": "Otwell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Otwell, Indiana"
    },
    {
      "city": "Petersburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Petersburg, Indiana"
    },
    {
      "city": "Spurgeon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Spurgeon, Indiana"
    },
    {
      "city": "Stendal",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stendal, Indiana"
    },
    {
      "city": "Velpen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Velpen, Indiana"
    },
    {
      "city": "Winslow",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Winslow, Indiana"
    },
    {
      "city": "Beverly Shores",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Beverly Shores, Indiana"
    },
    {
      "city": "Boone Grove",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Boone Grove, Indiana"
    },
    {
      "city": "Chesterton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Chesterton, Indiana"
    },
    {
      "city": "Hebron",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hebron, Indiana"
    },
    {
      "city": "Kouts",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kouts, Indiana"
    },
    {
      "city": "Portage",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Portage, Indiana"
    },
    {
      "city": "Valparaiso",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Valparaiso, Indiana"
    },
    {
      "city": "Wheeler",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wheeler, Indiana"
    },
    {
      "city": "Cynthiana",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cynthiana, Indiana"
    },
    {
      "city": "Griffin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Griffin, Indiana"
    },
    {
      "city": "Mount Vernon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mount Vernon, Indiana"
    },
    {
      "city": "New Harmony",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Harmony, Indiana"
    },
    {
      "city": "Poseyville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Poseyville, Indiana"
    },
    {
      "city": "Wadesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wadesville, Indiana"
    },
    {
      "city": "Monterey",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monterey, Indiana"
    },
    {
      "city": "Star City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Star City, Indiana"
    },
    {
      "city": "Winamac",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Winamac, Indiana"
    },
    {
      "city": "Francesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Francesville, Indiana"
    },
    {
      "city": "Medaryville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Medaryville, Indiana"
    },
    {
      "city": "Bainbridge",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bainbridge, Indiana"
    },
    {
      "city": "Cloverdale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cloverdale, Indiana"
    },
    {
      "city": "Fillmore",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fillmore, Indiana"
    },
    {
      "city": "Greencastle",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greencastle, Indiana"
    },
    {
      "city": "Putnamville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Putnamville, Indiana"
    },
    {
      "city": "Reelsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Reelsville, Indiana"
    },
    {
      "city": "Roachdale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Roachdale, Indiana"
    },
    {
      "city": "Russellville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Russellville, Indiana"
    },
    {
      "city": "Farmland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Farmland, Indiana"
    },
    {
      "city": "Losantville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Losantville, Indiana"
    },
    {
      "city": "Lynn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lynn, Indiana"
    },
    {
      "city": "Modoc",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Modoc, Indiana"
    },
    {
      "city": "Parker City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Parker City, Indiana"
    },
    {
      "city": "Ridgeville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ridgeville, Indiana"
    },
    {
      "city": "Saratoga",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saratoga, Indiana"
    },
    {
      "city": "Union City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Union City, Indiana"
    },
    {
      "city": "Winchester",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Winchester, Indiana"
    },
    {
      "city": "Batesville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Batesville, Indiana"
    },
    {
      "city": "Cross Plains",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cross Plains, Indiana"
    },
    {
      "city": "Friendship",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Friendship, Indiana"
    },
    {
      "city": "Holton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Holton, Indiana"
    },
    {
      "city": "Milan",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Milan, Indiana"
    },
    {
      "city": "Morris",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Morris, Indiana"
    },
    {
      "city": "Napoleon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Napoleon, Indiana"
    },
    {
      "city": "Osgood",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Osgood, Indiana"
    },
    {
      "city": "Pierceville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pierceville, Indiana"
    },
    {
      "city": "Sunman",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sunman, Indiana"
    },
    {
      "city": "Versailles",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Versailles, Indiana"
    },
    {
      "city": "Arlington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Arlington, Indiana"
    },
    {
      "city": "Carthage",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Carthage, Indiana"
    },
    {
      "city": "Falmouth",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Falmouth, Indiana"
    },
    {
      "city": "Homer",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Homer, Indiana"
    },
    {
      "city": "Manilla",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Manilla, Indiana"
    },
    {
      "city": "Mays",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mays, Indiana"
    },
    {
      "city": "Milroy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Milroy, Indiana"
    },
    {
      "city": "Rushville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rushville, Indiana"
    },
    {
      "city": "Granger",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Granger, Indiana"
    },
    {
      "city": "Lakeville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lakeville, Indiana"
    },
    {
      "city": "Mishawaka",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mishawaka, Indiana"
    },
    {
      "city": "New Carlisle",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Carlisle, Indiana"
    },
    {
      "city": "North Liberty",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Liberty, Indiana"
    },
    {
      "city": "Notre Dame",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Notre Dame, Indiana"
    },
    {
      "city": "Osceola",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Osceola, Indiana"
    },
    {
      "city": "Walkerton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Walkerton, Indiana"
    },
    {
      "city": "Wyatt",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wyatt, Indiana"
    },
    {
      "city": "South Bend",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "South Bend, Indiana"
    },
    {
      "city": "Austin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Austin, Indiana"
    },
    {
      "city": "Lexington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lexington, Indiana"
    },
    {
      "city": "Scottsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Scottsburg, Indiana"
    },
    {
      "city": "Boggstown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Boggstown, Indiana"
    },
    {
      "city": "Fairland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fairland, Indiana"
    },
    {
      "city": "Fountaintown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fountaintown, Indiana"
    },
    {
      "city": "Gwynneville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gwynneville, Indiana"
    },
    {
      "city": "Morristown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Morristown, Indiana"
    },
    {
      "city": "Shelbyville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shelbyville, Indiana"
    },
    {
      "city": "Waldron",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Waldron, Indiana"
    },
    {
      "city": "Flat Rock",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Flat Rock, Indiana"
    },
    {
      "city": "Dale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dale, Indiana"
    },
    {
      "city": "Evanston",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Evanston, Indiana"
    },
    {
      "city": "Fulda",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fulda, Indiana"
    },
    {
      "city": "Gentryville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Gentryville, Indiana"
    },
    {
      "city": "Lamar",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lamar, Indiana"
    },
    {
      "city": "Lincoln City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lincoln City, Indiana"
    },
    {
      "city": "Mariah Hill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Mariah Hill, Indiana"
    },
    {
      "city": "Saint Meinrad",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Meinrad, Indiana"
    },
    {
      "city": "Santa Claus",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Santa Claus, Indiana"
    },
    {
      "city": "Troy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Troy, Indiana"
    },
    {
      "city": "Chrisney",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Chrisney, Indiana"
    },
    {
      "city": "Grandview",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grandview, Indiana"
    },
    {
      "city": "Hatfield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hatfield, Indiana"
    },
    {
      "city": "Richland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Richland, Indiana"
    },
    {
      "city": "Rockport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Rockport, Indiana"
    },
    {
      "city": "North Judson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Judson, Indiana"
    },
    {
      "city": "San Pierre",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "San Pierre, Indiana"
    },
    {
      "city": "Grovertown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Grovertown, Indiana"
    },
    {
      "city": "Hamlet",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hamlet, Indiana"
    },
    {
      "city": "Knox",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Knox, Indiana"
    },
    {
      "city": "Ora",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ora, Indiana"
    },
    {
      "city": "Angola",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Angola, Indiana"
    },
    {
      "city": "Fremont",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fremont, Indiana"
    },
    {
      "city": "Hamilton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hamilton, Indiana"
    },
    {
      "city": "Hudson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hudson, Indiana"
    },
    {
      "city": "Orland",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Orland, Indiana"
    },
    {
      "city": "Pleasant Lake",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pleasant Lake, Indiana"
    },
    {
      "city": "Carlisle",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Carlisle, Indiana"
    },
    {
      "city": "Dugger",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dugger, Indiana"
    },
    {
      "city": "Fairbanks",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fairbanks, Indiana"
    },
    {
      "city": "Farmersburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Farmersburg, Indiana"
    },
    {
      "city": "Graysville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Graysville, Indiana"
    },
    {
      "city": "Hymera",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hymera, Indiana"
    },
    {
      "city": "Merom",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Merom, Indiana"
    },
    {
      "city": "Paxton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Paxton, Indiana"
    },
    {
      "city": "Shelburn",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shelburn, Indiana"
    },
    {
      "city": "Sullivan",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sullivan, Indiana"
    },
    {
      "city": "Bennington",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bennington, Indiana"
    },
    {
      "city": "East Enterprise",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "East Enterprise, Indiana"
    },
    {
      "city": "Florence",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Florence, Indiana"
    },
    {
      "city": "Patriot",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Patriot, Indiana"
    },
    {
      "city": "Vevay",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Vevay, Indiana"
    },
    {
      "city": "Lafayette",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lafayette, Indiana"
    },
    {
      "city": "West Lafayette",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Lafayette, Indiana"
    },
    {
      "city": "Battle Ground",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Battle Ground, Indiana"
    },
    {
      "city": "Buck Creek",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Buck Creek, Indiana"
    },
    {
      "city": "Clarks Hill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clarks Hill, Indiana"
    },
    {
      "city": "Dayton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dayton, Indiana"
    },
    {
      "city": "Montmorenci",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Montmorenci, Indiana"
    },
    {
      "city": "Romney",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Romney, Indiana"
    },
    {
      "city": "Stockwell",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Stockwell, Indiana"
    },
    {
      "city": "Westpoint",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Westpoint, Indiana"
    },
    {
      "city": "Goldsmith",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Goldsmith, Indiana"
    },
    {
      "city": "Hobbs",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hobbs, Indiana"
    },
    {
      "city": "Kempton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Kempton, Indiana"
    },
    {
      "city": "Sharpsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Sharpsville, Indiana"
    },
    {
      "city": "Tipton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tipton, Indiana"
    },
    {
      "city": "Windfall",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Windfall, Indiana"
    },
    {
      "city": "Brownsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brownsville, Indiana"
    },
    {
      "city": "Liberty",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Liberty, Indiana"
    },
    {
      "city": "Inglefield",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Inglefield, Indiana"
    },
    {
      "city": "Evansville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Evansville, Indiana"
    },
    {
      "city": "Blanford",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Blanford, Indiana"
    },
    {
      "city": "Clinton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Clinton, Indiana"
    },
    {
      "city": "Dana",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dana, Indiana"
    },
    {
      "city": "Hillsdale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hillsdale, Indiana"
    },
    {
      "city": "Saint Bernice",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Bernice, Indiana"
    },
    {
      "city": "Universal",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Universal, Indiana"
    },
    {
      "city": "Cayuga",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cayuga, Indiana"
    },
    {
      "city": "Newport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Newport, Indiana"
    },
    {
      "city": "Perrysville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Perrysville, Indiana"
    },
    {
      "city": "Terre Haute",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Terre Haute, Indiana"
    },
    {
      "city": "Fontanet",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fontanet, Indiana"
    },
    {
      "city": "Lewis",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lewis, Indiana"
    },
    {
      "city": "New Goshen",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "New Goshen, Indiana"
    },
    {
      "city": "Pimento",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pimento, Indiana"
    },
    {
      "city": "Prairie Creek",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Prairie Creek, Indiana"
    },
    {
      "city": "Prairieton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Prairieton, Indiana"
    },
    {
      "city": "Riley",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Riley, Indiana"
    },
    {
      "city": "Saint Mary Of The Woods",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Saint Mary Of The Woods, Indiana"
    },
    {
      "city": "Seelyville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Seelyville, Indiana"
    },
    {
      "city": "Shepardsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Shepardsville, Indiana"
    },
    {
      "city": "West Terre Haute",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Terre Haute, Indiana"
    },
    {
      "city": "La Fontaine",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "La Fontaine, Indiana"
    },
    {
      "city": "Lagro",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lagro, Indiana"
    },
    {
      "city": "Laketon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Laketon, Indiana"
    },
    {
      "city": "Liberty Mills",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Liberty Mills, Indiana"
    },
    {
      "city": "North Manchester",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "North Manchester, Indiana"
    },
    {
      "city": "Roann",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Roann, Indiana"
    },
    {
      "city": "Servia",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Servia, Indiana"
    },
    {
      "city": "Somerset",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Somerset, Indiana"
    },
    {
      "city": "Urbana",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Urbana, Indiana"
    },
    {
      "city": "Wabash",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wabash, Indiana"
    },
    {
      "city": "Pine Village",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pine Village, Indiana"
    },
    {
      "city": "State Line",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "State Line, Indiana"
    },
    {
      "city": "West Lebanon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "West Lebanon, Indiana"
    },
    {
      "city": "Williamsport",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Williamsport, Indiana"
    },
    {
      "city": "Boonville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Boonville, Indiana"
    },
    {
      "city": "Chandler",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Chandler, Indiana"
    },
    {
      "city": "Elberfeld",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Elberfeld, Indiana"
    },
    {
      "city": "Lynnville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Lynnville, Indiana"
    },
    {
      "city": "Newburgh",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Newburgh, Indiana"
    },
    {
      "city": "Tennyson",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Tennyson, Indiana"
    },
    {
      "city": "Campbellsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Campbellsburg, Indiana"
    },
    {
      "city": "Fredericksburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fredericksburg, Indiana"
    },
    {
      "city": "Hardinsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hardinsburg, Indiana"
    },
    {
      "city": "Pekin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pekin, Indiana"
    },
    {
      "city": "Salem",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Salem, Indiana"
    },
    {
      "city": "Boston",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Boston, Indiana"
    },
    {
      "city": "Cambridge City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Cambridge City, Indiana"
    },
    {
      "city": "Centerville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Centerville, Indiana"
    },
    {
      "city": "Dublin",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Dublin, Indiana"
    },
    {
      "city": "Economy",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Economy, Indiana"
    },
    {
      "city": "Fountain City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Fountain City, Indiana"
    },
    {
      "city": "Greens Fork",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Greens Fork, Indiana"
    },
    {
      "city": "Hagerstown",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Hagerstown, Indiana"
    },
    {
      "city": "Milton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Milton, Indiana"
    },
    {
      "city": "Pershing",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Pershing, Indiana"
    },
    {
      "city": "Richmond",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Richmond, Indiana"
    },
    {
      "city": "Webster",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Webster, Indiana"
    },
    {
      "city": "Williamsburg",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Williamsburg, Indiana"
    },
    {
      "city": "Bluffton",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Bluffton, Indiana"
    },
    {
      "city": "Craigville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Craigville, Indiana"
    },
    {
      "city": "Keystone",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Keystone, Indiana"
    },
    {
      "city": "Liberty Center",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Liberty Center, Indiana"
    },
    {
      "city": "Ossian",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Ossian, Indiana"
    },
    {
      "city": "Petroleum",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Petroleum, Indiana"
    },
    {
      "city": "Poneto",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Poneto, Indiana"
    },
    {
      "city": "Uniondale",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Uniondale, Indiana"
    },
    {
      "city": "Brookston",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Brookston, Indiana"
    },
    {
      "city": "Buffalo",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Buffalo, Indiana"
    },
    {
      "city": "Burnettsville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Burnettsville, Indiana"
    },
    {
      "city": "Chalmers",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Chalmers, Indiana"
    },
    {
      "city": "Idaville",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Idaville, Indiana"
    },
    {
      "city": "Monon",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monon, Indiana"
    },
    {
      "city": "Monticello",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Monticello, Indiana"
    },
    {
      "city": "Reynolds",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Reynolds, Indiana"
    },
    {
      "city": "Wolcott",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Wolcott, Indiana"
    },
    {
      "city": "Churubusco",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Churubusco, Indiana"
    },
    {
      "city": "Columbia City",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Columbia City, Indiana"
    },
    {
      "city": "Larwill",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "Larwill, Indiana"
    },
    {
      "city": "South Whitley",
      "state": "Indiana",
      "shortState": "IN",
      "formatedCity": "South Whitley, Indiana"
    },
    {
      "city": "Elsmore",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elsmore, Kansas"
    },
    {
      "city": "Gas",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gas, Kansas"
    },
    {
      "city": "Humboldt",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Humboldt, Kansas"
    },
    {
      "city": "Iola",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Iola, Kansas"
    },
    {
      "city": "La Harpe",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "La Harpe, Kansas"
    },
    {
      "city": "Moran",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Moran, Kansas"
    },
    {
      "city": "Savonburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Savonburg, Kansas"
    },
    {
      "city": "Colony",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Colony, Kansas"
    },
    {
      "city": "Garnett",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Garnett, Kansas"
    },
    {
      "city": "Greeley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Greeley, Kansas"
    },
    {
      "city": "Kincaid",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kincaid, Kansas"
    },
    {
      "city": "Welda",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Welda, Kansas"
    },
    {
      "city": "Westphalia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Westphalia, Kansas"
    },
    {
      "city": "Atchison",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Atchison, Kansas"
    },
    {
      "city": "Cummings",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cummings, Kansas"
    },
    {
      "city": "Effingham",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Effingham, Kansas"
    },
    {
      "city": "Lancaster",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lancaster, Kansas"
    },
    {
      "city": "Muscotah",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Muscotah, Kansas"
    },
    {
      "city": "Hardtner",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hardtner, Kansas"
    },
    {
      "city": "Hazelton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hazelton, Kansas"
    },
    {
      "city": "Isabel",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Isabel, Kansas"
    },
    {
      "city": "Kiowa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kiowa, Kansas"
    },
    {
      "city": "Lake City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lake City, Kansas"
    },
    {
      "city": "Medicine Lodge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Medicine Lodge, Kansas"
    },
    {
      "city": "Sharon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sharon, Kansas"
    },
    {
      "city": "Sun City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sun City, Kansas"
    },
    {
      "city": "Albert",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Albert, Kansas"
    },
    {
      "city": "Claflin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Claflin, Kansas"
    },
    {
      "city": "Ellinwood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ellinwood, Kansas"
    },
    {
      "city": "Great Bend",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Great Bend, Kansas"
    },
    {
      "city": "Hoisington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hoisington, Kansas"
    },
    {
      "city": "Olmitz",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Olmitz, Kansas"
    },
    {
      "city": "Pawnee Rock",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pawnee Rock, Kansas"
    },
    {
      "city": "Fort Scott",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fort Scott, Kansas"
    },
    {
      "city": "Bronson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bronson, Kansas"
    },
    {
      "city": "Fulton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fulton, Kansas"
    },
    {
      "city": "Garland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Garland, Kansas"
    },
    {
      "city": "Mapleton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mapleton, Kansas"
    },
    {
      "city": "Redfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Redfield, Kansas"
    },
    {
      "city": "Uniontown",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Uniontown, Kansas"
    },
    {
      "city": "Everest",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Everest, Kansas"
    },
    {
      "city": "Fairview",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fairview, Kansas"
    },
    {
      "city": "Hiawatha",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hiawatha, Kansas"
    },
    {
      "city": "Horton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Horton, Kansas"
    },
    {
      "city": "Morrill",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Morrill, Kansas"
    },
    {
      "city": "Powhattan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Powhattan, Kansas"
    },
    {
      "city": "Robinson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Robinson, Kansas"
    },
    {
      "city": "Cassoday",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cassoday, Kansas"
    },
    {
      "city": "Andover",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Andover, Kansas"
    },
    {
      "city": "Augusta",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Augusta, Kansas"
    },
    {
      "city": "Beaumont",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Beaumont, Kansas"
    },
    {
      "city": "Benton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Benton, Kansas"
    },
    {
      "city": "Douglass",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Douglass, Kansas"
    },
    {
      "city": "Elbing",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elbing, Kansas"
    },
    {
      "city": "El Dorado",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "El Dorado, Kansas"
    },
    {
      "city": "Latham",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Latham, Kansas"
    },
    {
      "city": "Leon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Leon, Kansas"
    },
    {
      "city": "Potwin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Potwin, Kansas"
    },
    {
      "city": "Rosalia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rosalia, Kansas"
    },
    {
      "city": "Rose Hill",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rose Hill, Kansas"
    },
    {
      "city": "Towanda",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Towanda, Kansas"
    },
    {
      "city": "Whitewater",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Whitewater, Kansas"
    },
    {
      "city": "Cedar Point",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cedar Point, Kansas"
    },
    {
      "city": "Cottonwood Falls",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cottonwood Falls, Kansas"
    },
    {
      "city": "Elmdale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elmdale, Kansas"
    },
    {
      "city": "Matfield Green",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Matfield Green, Kansas"
    },
    {
      "city": "Strong City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Strong City, Kansas"
    },
    {
      "city": "Cedar Vale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cedar Vale, Kansas"
    },
    {
      "city": "Chautauqua",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Chautauqua, Kansas"
    },
    {
      "city": "Niotaze",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Niotaze, Kansas"
    },
    {
      "city": "Peru",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Peru, Kansas"
    },
    {
      "city": "Sedan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sedan, Kansas"
    },
    {
      "city": "Baxter Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Baxter Springs, Kansas"
    },
    {
      "city": "Columbus",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Columbus, Kansas"
    },
    {
      "city": "Crestline",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Crestline, Kansas"
    },
    {
      "city": "Galena",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Galena, Kansas"
    },
    {
      "city": "Riverton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Riverton, Kansas"
    },
    {
      "city": "Scammon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Scammon, Kansas"
    },
    {
      "city": "Treece",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Treece, Kansas"
    },
    {
      "city": "Weir",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Weir, Kansas"
    },
    {
      "city": "West Mineral",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "West Mineral, Kansas"
    },
    {
      "city": "Bird City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bird City, Kansas"
    },
    {
      "city": "Saint Francis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Saint Francis, Kansas"
    },
    {
      "city": "Ashland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ashland, Kansas"
    },
    {
      "city": "Englewood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Englewood, Kansas"
    },
    {
      "city": "Minneola",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Minneola, Kansas"
    },
    {
      "city": "Clay Center",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Clay Center, Kansas"
    },
    {
      "city": "Green",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Green, Kansas"
    },
    {
      "city": "Longford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Longford, Kansas"
    },
    {
      "city": "Morganville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Morganville, Kansas"
    },
    {
      "city": "Wakefield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wakefield, Kansas"
    },
    {
      "city": "Concordia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Concordia, Kansas"
    },
    {
      "city": "Clyde",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Clyde, Kansas"
    },
    {
      "city": "Jamestown",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Jamestown, Kansas"
    },
    {
      "city": "Aurora",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Aurora, Kansas"
    },
    {
      "city": "Glasco",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Glasco, Kansas"
    },
    {
      "city": "Miltonvale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Miltonvale, Kansas"
    },
    {
      "city": "Burlington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burlington, Kansas"
    },
    {
      "city": "Gridley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gridley, Kansas"
    },
    {
      "city": "Lebo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lebo, Kansas"
    },
    {
      "city": "Le Roy",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Le Roy, Kansas"
    },
    {
      "city": "Waverly",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Waverly, Kansas"
    },
    {
      "city": "Coldwater",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Coldwater, Kansas"
    },
    {
      "city": "Protection",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Protection, Kansas"
    },
    {
      "city": "Wilmore",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wilmore, Kansas"
    },
    {
      "city": "Arkansas City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Arkansas City, Kansas"
    },
    {
      "city": "Atlanta",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Atlanta, Kansas"
    },
    {
      "city": "Burden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burden, Kansas"
    },
    {
      "city": "Cambridge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cambridge, Kansas"
    },
    {
      "city": "Dexter",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dexter, Kansas"
    },
    {
      "city": "Maple City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Maple City, Kansas"
    },
    {
      "city": "Rock",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rock, Kansas"
    },
    {
      "city": "Udall",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Udall, Kansas"
    },
    {
      "city": "Winfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Winfield, Kansas"
    },
    {
      "city": "Arcadia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Arcadia, Kansas"
    },
    {
      "city": "Arma",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Arma, Kansas"
    },
    {
      "city": "Cherokee",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cherokee, Kansas"
    },
    {
      "city": "Farlington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Farlington, Kansas"
    },
    {
      "city": "Franklin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Franklin, Kansas"
    },
    {
      "city": "Girard",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Girard, Kansas"
    },
    {
      "city": "Hepler",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hepler, Kansas"
    },
    {
      "city": "Mc Cune",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mc Cune, Kansas"
    },
    {
      "city": "Mulberry",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mulberry, Kansas"
    },
    {
      "city": "Opolis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Opolis, Kansas"
    },
    {
      "city": "Pittsburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pittsburg, Kansas"
    },
    {
      "city": "Frontenac",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Frontenac, Kansas"
    },
    {
      "city": "Walnut",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Walnut, Kansas"
    },
    {
      "city": "Dresden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dresden, Kansas"
    },
    {
      "city": "Jennings",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Jennings, Kansas"
    },
    {
      "city": "Norcatur",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Norcatur, Kansas"
    },
    {
      "city": "Oberlin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oberlin, Kansas"
    },
    {
      "city": "Abilene",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Abilene, Kansas"
    },
    {
      "city": "Chapman",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Chapman, Kansas"
    },
    {
      "city": "Enterprise",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Enterprise, Kansas"
    },
    {
      "city": "Herington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Herington, Kansas"
    },
    {
      "city": "Hope",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hope, Kansas"
    },
    {
      "city": "Solomon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Solomon, Kansas"
    },
    {
      "city": "Talmage",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Talmage, Kansas"
    },
    {
      "city": "Woodbine",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Woodbine, Kansas"
    },
    {
      "city": "Bendena",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bendena, Kansas"
    },
    {
      "city": "Denton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Denton, Kansas"
    },
    {
      "city": "Elwood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elwood, Kansas"
    },
    {
      "city": "Highland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Highland, Kansas"
    },
    {
      "city": "Troy",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Troy, Kansas"
    },
    {
      "city": "Wathena",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wathena, Kansas"
    },
    {
      "city": "White Cloud",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "White Cloud, Kansas"
    },
    {
      "city": "Baldwin City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Baldwin City, Kansas"
    },
    {
      "city": "Eudora",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Eudora, Kansas"
    },
    {
      "city": "Lawrence",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lawrence, Kansas"
    },
    {
      "city": "Lecompton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lecompton, Kansas"
    },
    {
      "city": "Belpre",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Belpre, Kansas"
    },
    {
      "city": "Kinsley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kinsley, Kansas"
    },
    {
      "city": "Lewis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lewis, Kansas"
    },
    {
      "city": "Offerle",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Offerle, Kansas"
    },
    {
      "city": "Elk Falls",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elk Falls, Kansas"
    },
    {
      "city": "Grenola",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Grenola, Kansas"
    },
    {
      "city": "Howard",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Howard, Kansas"
    },
    {
      "city": "Longton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Longton, Kansas"
    },
    {
      "city": "Moline",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Moline, Kansas"
    },
    {
      "city": "Hays",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hays, Kansas"
    },
    {
      "city": "Catharine",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Catharine, Kansas"
    },
    {
      "city": "Ellis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ellis, Kansas"
    },
    {
      "city": "Pfeifer",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pfeifer, Kansas"
    },
    {
      "city": "Schoenchen",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Schoenchen, Kansas"
    },
    {
      "city": "Victoria",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Victoria, Kansas"
    },
    {
      "city": "Walker",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Walker, Kansas"
    },
    {
      "city": "Ellsworth",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ellsworth, Kansas"
    },
    {
      "city": "Holyrood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Holyrood, Kansas"
    },
    {
      "city": "Kanopolis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kanopolis, Kansas"
    },
    {
      "city": "Lorraine",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lorraine, Kansas"
    },
    {
      "city": "Wilson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wilson, Kansas"
    },
    {
      "city": "Garden City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Garden City, Kansas"
    },
    {
      "city": "Holcomb",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Holcomb, Kansas"
    },
    {
      "city": "Pierceville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pierceville, Kansas"
    },
    {
      "city": "Dodge City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dodge City, Kansas"
    },
    {
      "city": "Bucklin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bucklin, Kansas"
    },
    {
      "city": "Ford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ford, Kansas"
    },
    {
      "city": "Fort Dodge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fort Dodge, Kansas"
    },
    {
      "city": "Spearville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Spearville, Kansas"
    },
    {
      "city": "Wright",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wright, Kansas"
    },
    {
      "city": "Lane",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lane, Kansas"
    },
    {
      "city": "Ottawa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ottawa, Kansas"
    },
    {
      "city": "Pomona",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pomona, Kansas"
    },
    {
      "city": "Princeton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Princeton, Kansas"
    },
    {
      "city": "Rantoul",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rantoul, Kansas"
    },
    {
      "city": "Richmond",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Richmond, Kansas"
    },
    {
      "city": "Wellsville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wellsville, Kansas"
    },
    {
      "city": "Williamsburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Williamsburg, Kansas"
    },
    {
      "city": "Junction City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Junction City, Kansas"
    },
    {
      "city": "Fort Riley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fort Riley, Kansas"
    },
    {
      "city": "Milford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Milford, Kansas"
    },
    {
      "city": "Gove",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gove, Kansas"
    },
    {
      "city": "Grainfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Grainfield, Kansas"
    },
    {
      "city": "Grinnell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Grinnell, Kansas"
    },
    {
      "city": "Park",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Park, Kansas"
    },
    {
      "city": "Quinter",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Quinter, Kansas"
    },
    {
      "city": "Bogue",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bogue, Kansas"
    },
    {
      "city": "Hill City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hill City, Kansas"
    },
    {
      "city": "Morland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Morland, Kansas"
    },
    {
      "city": "Penokee",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Penokee, Kansas"
    },
    {
      "city": "Ulysses",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ulysses, Kansas"
    },
    {
      "city": "Cimarron",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cimarron, Kansas"
    },
    {
      "city": "Copeland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Copeland, Kansas"
    },
    {
      "city": "Ensign",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ensign, Kansas"
    },
    {
      "city": "Ingalls",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ingalls, Kansas"
    },
    {
      "city": "Montezuma",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Montezuma, Kansas"
    },
    {
      "city": "Tribune",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tribune, Kansas"
    },
    {
      "city": "Hamilton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hamilton, Kansas"
    },
    {
      "city": "Lamont",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lamont, Kansas"
    },
    {
      "city": "Madison",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Madison, Kansas"
    },
    {
      "city": "Neal",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Neal, Kansas"
    },
    {
      "city": "Virgil",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Virgil, Kansas"
    },
    {
      "city": "Eureka",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Eureka, Kansas"
    },
    {
      "city": "Fall River",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fall River, Kansas"
    },
    {
      "city": "Piedmont",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Piedmont, Kansas"
    },
    {
      "city": "Severy",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Severy, Kansas"
    },
    {
      "city": "Coolidge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Coolidge, Kansas"
    },
    {
      "city": "Kendall",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kendall, Kansas"
    },
    {
      "city": "Syracuse",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Syracuse, Kansas"
    },
    {
      "city": "Anthony",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Anthony, Kansas"
    },
    {
      "city": "Attica",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Attica, Kansas"
    },
    {
      "city": "Bluff City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bluff City, Kansas"
    },
    {
      "city": "Danville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Danville, Kansas"
    },
    {
      "city": "Freeport",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Freeport, Kansas"
    },
    {
      "city": "Harper",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Harper, Kansas"
    },
    {
      "city": "Waldron",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Waldron, Kansas"
    },
    {
      "city": "Burrton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burrton, Kansas"
    },
    {
      "city": "Halstead",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Halstead, Kansas"
    },
    {
      "city": "Hesston",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hesston, Kansas"
    },
    {
      "city": "Newton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Newton, Kansas"
    },
    {
      "city": "North Newton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "North Newton, Kansas"
    },
    {
      "city": "Sedgwick",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sedgwick, Kansas"
    },
    {
      "city": "Walton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Walton, Kansas"
    },
    {
      "city": "Satanta",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Satanta, Kansas"
    },
    {
      "city": "Sublette",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sublette, Kansas"
    },
    {
      "city": "Hanston",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hanston, Kansas"
    },
    {
      "city": "Jetmore",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Jetmore, Kansas"
    },
    {
      "city": "Circleville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Circleville, Kansas"
    },
    {
      "city": "Delia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Delia, Kansas"
    },
    {
      "city": "Denison",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Denison, Kansas"
    },
    {
      "city": "Holton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Holton, Kansas"
    },
    {
      "city": "Hoyt",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hoyt, Kansas"
    },
    {
      "city": "Mayetta",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mayetta, Kansas"
    },
    {
      "city": "Netawaka",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Netawaka, Kansas"
    },
    {
      "city": "Soldier",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Soldier, Kansas"
    },
    {
      "city": "Whiting",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Whiting, Kansas"
    },
    {
      "city": "Mc Louth",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mc Louth, Kansas"
    },
    {
      "city": "Nortonville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Nortonville, Kansas"
    },
    {
      "city": "Oskaloosa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oskaloosa, Kansas"
    },
    {
      "city": "Ozawkie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ozawkie, Kansas"
    },
    {
      "city": "Perry",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Perry, Kansas"
    },
    {
      "city": "Valley Falls",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Valley Falls, Kansas"
    },
    {
      "city": "Winchester",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Winchester, Kansas"
    },
    {
      "city": "Grantville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Grantville, Kansas"
    },
    {
      "city": "Meriden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Meriden, Kansas"
    },
    {
      "city": "Burr Oak",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burr Oak, Kansas"
    },
    {
      "city": "Esbon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Esbon, Kansas"
    },
    {
      "city": "Formoso",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Formoso, Kansas"
    },
    {
      "city": "Jewell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Jewell, Kansas"
    },
    {
      "city": "Mankato",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mankato, Kansas"
    },
    {
      "city": "Randall",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Randall, Kansas"
    },
    {
      "city": "Webber",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Webber, Kansas"
    },
    {
      "city": "De Soto",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "De Soto, Kansas"
    },
    {
      "city": "Edgerton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Edgerton, Kansas"
    },
    {
      "city": "Gardner",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gardner, Kansas"
    },
    {
      "city": "New Century",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "New Century, Kansas"
    },
    {
      "city": "Olathe",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Olathe, Kansas"
    },
    {
      "city": "Spring Hill",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Spring Hill, Kansas"
    },
    {
      "city": "Stilwell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Stilwell, Kansas"
    },
    {
      "city": "Mission",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mission, Kansas"
    },
    {
      "city": "Shawnee",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Shawnee, Kansas"
    },
    {
      "city": "Overland Park",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Overland Park, Kansas"
    },
    {
      "city": "Leawood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Leawood, Kansas"
    },
    {
      "city": "Prairie Village",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Prairie Village, Kansas"
    },
    {
      "city": "Lenexa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lenexa, Kansas"
    },
    {
      "city": "Shawnee Mission",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Shawnee Mission, Kansas"
    },
    {
      "city": "Deerfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Deerfield, Kansas"
    },
    {
      "city": "Lakin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lakin, Kansas"
    },
    {
      "city": "Cunningham",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cunningham, Kansas"
    },
    {
      "city": "Kingman",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kingman, Kansas"
    },
    {
      "city": "Murdock",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Murdock, Kansas"
    },
    {
      "city": "Nashville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Nashville, Kansas"
    },
    {
      "city": "Norwich",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Norwich, Kansas"
    },
    {
      "city": "Spivey",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Spivey, Kansas"
    },
    {
      "city": "Zenda",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Zenda, Kansas"
    },
    {
      "city": "Greensburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Greensburg, Kansas"
    },
    {
      "city": "Haviland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Haviland, Kansas"
    },
    {
      "city": "Mullinville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mullinville, Kansas"
    },
    {
      "city": "Altamont",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Altamont, Kansas"
    },
    {
      "city": "Bartlett",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bartlett, Kansas"
    },
    {
      "city": "Chetopa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Chetopa, Kansas"
    },
    {
      "city": "Dennis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dennis, Kansas"
    },
    {
      "city": "Edna",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Edna, Kansas"
    },
    {
      "city": "Mound Valley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mound Valley, Kansas"
    },
    {
      "city": "Oswego",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oswego, Kansas"
    },
    {
      "city": "Parsons",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Parsons, Kansas"
    },
    {
      "city": "Dighton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dighton, Kansas"
    },
    {
      "city": "Healy",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Healy, Kansas"
    },
    {
      "city": "Basehor",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Basehor, Kansas"
    },
    {
      "city": "Easton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Easton, Kansas"
    },
    {
      "city": "Fort Leavenworth",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fort Leavenworth, Kansas"
    },
    {
      "city": "Lansing",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lansing, Kansas"
    },
    {
      "city": "Leavenworth",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Leavenworth, Kansas"
    },
    {
      "city": "Linwood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Linwood, Kansas"
    },
    {
      "city": "Tonganoxie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tonganoxie, Kansas"
    },
    {
      "city": "Barnard",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Barnard, Kansas"
    },
    {
      "city": "Beverly",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Beverly, Kansas"
    },
    {
      "city": "Lincoln",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lincoln, Kansas"
    },
    {
      "city": "Sylvan Grove",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sylvan Grove, Kansas"
    },
    {
      "city": "Blue Mound",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Blue Mound, Kansas"
    },
    {
      "city": "Centerville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Centerville, Kansas"
    },
    {
      "city": "Lacygne",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lacygne, Kansas"
    },
    {
      "city": "Mound City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mound City, Kansas"
    },
    {
      "city": "Parker",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Parker, Kansas"
    },
    {
      "city": "Pleasanton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pleasanton, Kansas"
    },
    {
      "city": "Prescott",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Prescott, Kansas"
    },
    {
      "city": "Monument",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Monument, Kansas"
    },
    {
      "city": "Oakley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oakley, Kansas"
    },
    {
      "city": "Winona",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Winona, Kansas"
    },
    {
      "city": "Emporia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Emporia, Kansas"
    },
    {
      "city": "Admire",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Admire, Kansas"
    },
    {
      "city": "Allen",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Allen, Kansas"
    },
    {
      "city": "Americus",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Americus, Kansas"
    },
    {
      "city": "Hartford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hartford, Kansas"
    },
    {
      "city": "Neosho Rapids",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Neosho Rapids, Kansas"
    },
    {
      "city": "Olpe",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Olpe, Kansas"
    },
    {
      "city": "Reading",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Reading, Kansas"
    },
    {
      "city": "Moundridge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Moundridge, Kansas"
    },
    {
      "city": "Canton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Canton, Kansas"
    },
    {
      "city": "Galva",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Galva, Kansas"
    },
    {
      "city": "Lindsborg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lindsborg, Kansas"
    },
    {
      "city": "Mcpherson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mcpherson, Kansas"
    },
    {
      "city": "Marquette",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Marquette, Kansas"
    },
    {
      "city": "Roxbury",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Roxbury, Kansas"
    },
    {
      "city": "Windom",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Windom, Kansas"
    },
    {
      "city": "Inman",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Inman, Kansas"
    },
    {
      "city": "Burns",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burns, Kansas"
    },
    {
      "city": "Florence",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Florence, Kansas"
    },
    {
      "city": "Lincolnville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lincolnville, Kansas"
    },
    {
      "city": "Lost Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lost Springs, Kansas"
    },
    {
      "city": "Marion",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Marion, Kansas"
    },
    {
      "city": "Peabody",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Peabody, Kansas"
    },
    {
      "city": "Goessel",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Goessel, Kansas"
    },
    {
      "city": "Hillsboro",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hillsboro, Kansas"
    },
    {
      "city": "Lehigh",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lehigh, Kansas"
    },
    {
      "city": "Durham",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Durham, Kansas"
    },
    {
      "city": "Ramona",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ramona, Kansas"
    },
    {
      "city": "Tampa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tampa, Kansas"
    },
    {
      "city": "Axtell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Axtell, Kansas"
    },
    {
      "city": "Beattie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Beattie, Kansas"
    },
    {
      "city": "Blue Rapids",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Blue Rapids, Kansas"
    },
    {
      "city": "Bremen",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bremen, Kansas"
    },
    {
      "city": "Frankfort",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Frankfort, Kansas"
    },
    {
      "city": "Home",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Home, Kansas"
    },
    {
      "city": "Marysville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Marysville, Kansas"
    },
    {
      "city": "Oketo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oketo, Kansas"
    },
    {
      "city": "Summerfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Summerfield, Kansas"
    },
    {
      "city": "Vermillion",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Vermillion, Kansas"
    },
    {
      "city": "Waterville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Waterville, Kansas"
    },
    {
      "city": "Fowler",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fowler, Kansas"
    },
    {
      "city": "Meade",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Meade, Kansas"
    },
    {
      "city": "Plains",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Plains, Kansas"
    },
    {
      "city": "Bucyrus",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bucyrus, Kansas"
    },
    {
      "city": "Fontana",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fontana, Kansas"
    },
    {
      "city": "Hillsdale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hillsdale, Kansas"
    },
    {
      "city": "Louisburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Louisburg, Kansas"
    },
    {
      "city": "Osawatomie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Osawatomie, Kansas"
    },
    {
      "city": "Paola",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Paola, Kansas"
    },
    {
      "city": "Beloit",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Beloit, Kansas"
    },
    {
      "city": "Cawker City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cawker City, Kansas"
    },
    {
      "city": "Glen Elder",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Glen Elder, Kansas"
    },
    {
      "city": "Hunter",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hunter, Kansas"
    },
    {
      "city": "Simpson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Simpson, Kansas"
    },
    {
      "city": "Tipton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tipton, Kansas"
    },
    {
      "city": "Independence",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Independence, Kansas"
    },
    {
      "city": "Caney",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Caney, Kansas"
    },
    {
      "city": "Cherryvale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cherryvale, Kansas"
    },
    {
      "city": "Coffeyville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Coffeyville, Kansas"
    },
    {
      "city": "Dearing",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dearing, Kansas"
    },
    {
      "city": "Elk City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elk City, Kansas"
    },
    {
      "city": "Havana",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Havana, Kansas"
    },
    {
      "city": "Liberty",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Liberty, Kansas"
    },
    {
      "city": "Sycamore",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sycamore, Kansas"
    },
    {
      "city": "Tyro",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tyro, Kansas"
    },
    {
      "city": "Burdick",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burdick, Kansas"
    },
    {
      "city": "Council Grove",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Council Grove, Kansas"
    },
    {
      "city": "Dwight",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dwight, Kansas"
    },
    {
      "city": "White City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "White City, Kansas"
    },
    {
      "city": "Wilsey",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wilsey, Kansas"
    },
    {
      "city": "Elkhart",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Elkhart, Kansas"
    },
    {
      "city": "Richfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Richfield, Kansas"
    },
    {
      "city": "Rolla",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rolla, Kansas"
    },
    {
      "city": "Baileyville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Baileyville, Kansas"
    },
    {
      "city": "Bern",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bern, Kansas"
    },
    {
      "city": "Centralia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Centralia, Kansas"
    },
    {
      "city": "Corning",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Corning, Kansas"
    },
    {
      "city": "Goff",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Goff, Kansas"
    },
    {
      "city": "Oneida",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oneida, Kansas"
    },
    {
      "city": "Sabetha",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sabetha, Kansas"
    },
    {
      "city": "Seneca",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Seneca, Kansas"
    },
    {
      "city": "Wetmore",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wetmore, Kansas"
    },
    {
      "city": "Chanute",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Chanute, Kansas"
    },
    {
      "city": "Erie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Erie, Kansas"
    },
    {
      "city": "Galesburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Galesburg, Kansas"
    },
    {
      "city": "Saint Paul",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Saint Paul, Kansas"
    },
    {
      "city": "Stark",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Stark, Kansas"
    },
    {
      "city": "Thayer",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Thayer, Kansas"
    },
    {
      "city": "Arnold",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Arnold, Kansas"
    },
    {
      "city": "Bazine",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bazine, Kansas"
    },
    {
      "city": "Beeler",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Beeler, Kansas"
    },
    {
      "city": "Brownell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Brownell, Kansas"
    },
    {
      "city": "Ness City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ness City, Kansas"
    },
    {
      "city": "Ransom",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ransom, Kansas"
    },
    {
      "city": "Utica",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Utica, Kansas"
    },
    {
      "city": "Almena",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Almena, Kansas"
    },
    {
      "city": "Clayton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Clayton, Kansas"
    },
    {
      "city": "Lenora",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lenora, Kansas"
    },
    {
      "city": "Norton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Norton, Kansas"
    },
    {
      "city": "Burlingame",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burlingame, Kansas"
    },
    {
      "city": "Carbondale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Carbondale, Kansas"
    },
    {
      "city": "Lyndon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lyndon, Kansas"
    },
    {
      "city": "Melvern",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Melvern, Kansas"
    },
    {
      "city": "Osage City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Osage City, Kansas"
    },
    {
      "city": "Overbrook",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Overbrook, Kansas"
    },
    {
      "city": "Quenemo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Quenemo, Kansas"
    },
    {
      "city": "Scranton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Scranton, Kansas"
    },
    {
      "city": "Vassar",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Vassar, Kansas"
    },
    {
      "city": "Downs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Downs, Kansas"
    },
    {
      "city": "Osborne",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Osborne, Kansas"
    },
    {
      "city": "Portis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Portis, Kansas"
    },
    {
      "city": "Alton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Alton, Kansas"
    },
    {
      "city": "Natoma",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Natoma, Kansas"
    },
    {
      "city": "Bennington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bennington, Kansas"
    },
    {
      "city": "Delphos",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Delphos, Kansas"
    },
    {
      "city": "Minneapolis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Minneapolis, Kansas"
    },
    {
      "city": "Tescott",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tescott, Kansas"
    },
    {
      "city": "Burdett",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Burdett, Kansas"
    },
    {
      "city": "Garfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Garfield, Kansas"
    },
    {
      "city": "Larned",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Larned, Kansas"
    },
    {
      "city": "Rozel",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rozel, Kansas"
    },
    {
      "city": "Agra",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Agra, Kansas"
    },
    {
      "city": "Glade",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Glade, Kansas"
    },
    {
      "city": "Kirwin",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kirwin, Kansas"
    },
    {
      "city": "Logan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Logan, Kansas"
    },
    {
      "city": "Long Island",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Long Island, Kansas"
    },
    {
      "city": "Phillipsburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Phillipsburg, Kansas"
    },
    {
      "city": "Prairie View",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Prairie View, Kansas"
    },
    {
      "city": "Belvue",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Belvue, Kansas"
    },
    {
      "city": "Emmett",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Emmett, Kansas"
    },
    {
      "city": "Fostoria",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fostoria, Kansas"
    },
    {
      "city": "Havensville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Havensville, Kansas"
    },
    {
      "city": "Olsburg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Olsburg, Kansas"
    },
    {
      "city": "Onaga",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Onaga, Kansas"
    },
    {
      "city": "Saint George",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Saint George, Kansas"
    },
    {
      "city": "Saint Marys",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Saint Marys, Kansas"
    },
    {
      "city": "Wamego",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wamego, Kansas"
    },
    {
      "city": "Westmoreland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Westmoreland, Kansas"
    },
    {
      "city": "Byers",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Byers, Kansas"
    },
    {
      "city": "Coats",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Coats, Kansas"
    },
    {
      "city": "Iuka",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Iuka, Kansas"
    },
    {
      "city": "Pratt",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pratt, Kansas"
    },
    {
      "city": "Sawyer",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sawyer, Kansas"
    },
    {
      "city": "Atwood",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Atwood, Kansas"
    },
    {
      "city": "Herndon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Herndon, Kansas"
    },
    {
      "city": "Ludell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ludell, Kansas"
    },
    {
      "city": "Mc Donald",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mc Donald, Kansas"
    },
    {
      "city": "Hutchinson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hutchinson, Kansas"
    },
    {
      "city": "South Hutchinson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "South Hutchinson, Kansas"
    },
    {
      "city": "Abbyville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Abbyville, Kansas"
    },
    {
      "city": "Arlington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Arlington, Kansas"
    },
    {
      "city": "Buhler",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Buhler, Kansas"
    },
    {
      "city": "Haven",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Haven, Kansas"
    },
    {
      "city": "Nickerson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Nickerson, Kansas"
    },
    {
      "city": "Partridge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Partridge, Kansas"
    },
    {
      "city": "Plevna",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Plevna, Kansas"
    },
    {
      "city": "Pretty Prairie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Pretty Prairie, Kansas"
    },
    {
      "city": "Sylvia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sylvia, Kansas"
    },
    {
      "city": "Turon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Turon, Kansas"
    },
    {
      "city": "Yoder",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Yoder, Kansas"
    },
    {
      "city": "Agenda",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Agenda, Kansas"
    },
    {
      "city": "Belleville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Belleville, Kansas"
    },
    {
      "city": "Courtland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Courtland, Kansas"
    },
    {
      "city": "Cuba",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cuba, Kansas"
    },
    {
      "city": "Munden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Munden, Kansas"
    },
    {
      "city": "Narka",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Narka, Kansas"
    },
    {
      "city": "Norway",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Norway, Kansas"
    },
    {
      "city": "Republic",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Republic, Kansas"
    },
    {
      "city": "Scandia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Scandia, Kansas"
    },
    {
      "city": "Bushton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bushton, Kansas"
    },
    {
      "city": "Geneseo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Geneseo, Kansas"
    },
    {
      "city": "Little River",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Little River, Kansas"
    },
    {
      "city": "Alden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Alden, Kansas"
    },
    {
      "city": "Chase",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Chase, Kansas"
    },
    {
      "city": "Lyons",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lyons, Kansas"
    },
    {
      "city": "Raymond",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Raymond, Kansas"
    },
    {
      "city": "Sterling",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sterling, Kansas"
    },
    {
      "city": "Leonardville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Leonardville, Kansas"
    },
    {
      "city": "Manhattan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Manhattan, Kansas"
    },
    {
      "city": "Ogden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ogden, Kansas"
    },
    {
      "city": "Riley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Riley, Kansas"
    },
    {
      "city": "Randolph",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Randolph, Kansas"
    },
    {
      "city": "Damar",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Damar, Kansas"
    },
    {
      "city": "Palco",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Palco, Kansas"
    },
    {
      "city": "Plainville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Plainville, Kansas"
    },
    {
      "city": "Stockton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Stockton, Kansas"
    },
    {
      "city": "Woodston",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Woodston, Kansas"
    },
    {
      "city": "Alexander",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Alexander, Kansas"
    },
    {
      "city": "Bison",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bison, Kansas"
    },
    {
      "city": "La Crosse",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "La Crosse, Kansas"
    },
    {
      "city": "Liebenthal",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Liebenthal, Kansas"
    },
    {
      "city": "Mc Cracken",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mc Cracken, Kansas"
    },
    {
      "city": "Nekoma",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Nekoma, Kansas"
    },
    {
      "city": "Otis",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Otis, Kansas"
    },
    {
      "city": "Rush Center",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rush Center, Kansas"
    },
    {
      "city": "Bunker Hill",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bunker Hill, Kansas"
    },
    {
      "city": "Dorrance",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dorrance, Kansas"
    },
    {
      "city": "Gorham",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gorham, Kansas"
    },
    {
      "city": "Lucas",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lucas, Kansas"
    },
    {
      "city": "Luray",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Luray, Kansas"
    },
    {
      "city": "Paradise",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Paradise, Kansas"
    },
    {
      "city": "Russell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Russell, Kansas"
    },
    {
      "city": "Waldo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Waldo, Kansas"
    },
    {
      "city": "Salina",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Salina, Kansas"
    },
    {
      "city": "Assaria",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Assaria, Kansas"
    },
    {
      "city": "Brookville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Brookville, Kansas"
    },
    {
      "city": "Falun",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Falun, Kansas"
    },
    {
      "city": "Gypsum",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gypsum, Kansas"
    },
    {
      "city": "New Cambria",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "New Cambria, Kansas"
    },
    {
      "city": "Scott City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Scott City, Kansas"
    },
    {
      "city": "Andale",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Andale, Kansas"
    },
    {
      "city": "Bentley",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bentley, Kansas"
    },
    {
      "city": "Cheney",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cheney, Kansas"
    },
    {
      "city": "Clearwater",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Clearwater, Kansas"
    },
    {
      "city": "Colwich",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Colwich, Kansas"
    },
    {
      "city": "Derby",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Derby, Kansas"
    },
    {
      "city": "Garden Plain",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Garden Plain, Kansas"
    },
    {
      "city": "Goddard",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Goddard, Kansas"
    },
    {
      "city": "Greenwich",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Greenwich, Kansas"
    },
    {
      "city": "Haysville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Haysville, Kansas"
    },
    {
      "city": "Kechi",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kechi, Kansas"
    },
    {
      "city": "Maize",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Maize, Kansas"
    },
    {
      "city": "Mount Hope",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mount Hope, Kansas"
    },
    {
      "city": "Peck",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Peck, Kansas"
    },
    {
      "city": "Valley Center",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Valley Center, Kansas"
    },
    {
      "city": "Viola",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Viola, Kansas"
    },
    {
      "city": "Wichita",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wichita, Kansas"
    },
    {
      "city": "Mcconnell Afb",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mcconnell Afb, Kansas"
    },
    {
      "city": "Kismet",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kismet, Kansas"
    },
    {
      "city": "Liberal",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Liberal, Kansas"
    },
    {
      "city": "Auburn",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Auburn, Kansas"
    },
    {
      "city": "Berryton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Berryton, Kansas"
    },
    {
      "city": "Dover",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Dover, Kansas"
    },
    {
      "city": "Rossville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rossville, Kansas"
    },
    {
      "city": "Silver Lake",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Silver Lake, Kansas"
    },
    {
      "city": "Tecumseh",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Tecumseh, Kansas"
    },
    {
      "city": "Wakarusa",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wakarusa, Kansas"
    },
    {
      "city": "Topeka",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Topeka, Kansas"
    },
    {
      "city": "Hoxie",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hoxie, Kansas"
    },
    {
      "city": "Selden",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Selden, Kansas"
    },
    {
      "city": "Edson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Edson, Kansas"
    },
    {
      "city": "Goodland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Goodland, Kansas"
    },
    {
      "city": "Kanorado",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kanorado, Kansas"
    },
    {
      "city": "Athol",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Athol, Kansas"
    },
    {
      "city": "Kensington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kensington, Kansas"
    },
    {
      "city": "Lebanon",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Lebanon, Kansas"
    },
    {
      "city": "Smith Center",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Smith Center, Kansas"
    },
    {
      "city": "Cedar",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Cedar, Kansas"
    },
    {
      "city": "Gaylord",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gaylord, Kansas"
    },
    {
      "city": "Hudson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hudson, Kansas"
    },
    {
      "city": "Macksville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Macksville, Kansas"
    },
    {
      "city": "St John",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "St John, Kansas"
    },
    {
      "city": "Stafford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Stafford, Kansas"
    },
    {
      "city": "Johnson",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Johnson, Kansas"
    },
    {
      "city": "Manter",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Manter, Kansas"
    },
    {
      "city": "Hugoton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hugoton, Kansas"
    },
    {
      "city": "Moscow",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Moscow, Kansas"
    },
    {
      "city": "Argonia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Argonia, Kansas"
    },
    {
      "city": "Belle Plaine",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Belle Plaine, Kansas"
    },
    {
      "city": "Caldwell",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Caldwell, Kansas"
    },
    {
      "city": "Conway Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Conway Springs, Kansas"
    },
    {
      "city": "Geuda Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Geuda Springs, Kansas"
    },
    {
      "city": "Mayfield",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mayfield, Kansas"
    },
    {
      "city": "Milan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Milan, Kansas"
    },
    {
      "city": "Milton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Milton, Kansas"
    },
    {
      "city": "Mulvane",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mulvane, Kansas"
    },
    {
      "city": "Oxford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Oxford, Kansas"
    },
    {
      "city": "South Haven",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "South Haven, Kansas"
    },
    {
      "city": "Wellington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wellington, Kansas"
    },
    {
      "city": "Colby",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Colby, Kansas"
    },
    {
      "city": "Brewster",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Brewster, Kansas"
    },
    {
      "city": "Gem",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Gem, Kansas"
    },
    {
      "city": "Levant",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Levant, Kansas"
    },
    {
      "city": "Rexford",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Rexford, Kansas"
    },
    {
      "city": "Collyer",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Collyer, Kansas"
    },
    {
      "city": "Ogallah",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Ogallah, Kansas"
    },
    {
      "city": "Wakeeney",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wakeeney, Kansas"
    },
    {
      "city": "Alma",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Alma, Kansas"
    },
    {
      "city": "Eskridge",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Eskridge, Kansas"
    },
    {
      "city": "Harveyville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Harveyville, Kansas"
    },
    {
      "city": "Mc Farland",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mc Farland, Kansas"
    },
    {
      "city": "Maple Hill",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Maple Hill, Kansas"
    },
    {
      "city": "Paxico",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Paxico, Kansas"
    },
    {
      "city": "Alta Vista",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Alta Vista, Kansas"
    },
    {
      "city": "Sharon Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Sharon Springs, Kansas"
    },
    {
      "city": "Wallace",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Wallace, Kansas"
    },
    {
      "city": "Weskan",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Weskan, Kansas"
    },
    {
      "city": "Barnes",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Barnes, Kansas"
    },
    {
      "city": "Clifton",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Clifton, Kansas"
    },
    {
      "city": "Greenleaf",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Greenleaf, Kansas"
    },
    {
      "city": "Haddam",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Haddam, Kansas"
    },
    {
      "city": "Hanover",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hanover, Kansas"
    },
    {
      "city": "Hollenberg",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Hollenberg, Kansas"
    },
    {
      "city": "Linn",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Linn, Kansas"
    },
    {
      "city": "Mahaska",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Mahaska, Kansas"
    },
    {
      "city": "Morrowville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Morrowville, Kansas"
    },
    {
      "city": "Palmer",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Palmer, Kansas"
    },
    {
      "city": "Washington",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Washington, Kansas"
    },
    {
      "city": "Leoti",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Leoti, Kansas"
    },
    {
      "city": "Marienthal",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Marienthal, Kansas"
    },
    {
      "city": "Altoona",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Altoona, Kansas"
    },
    {
      "city": "Benedict",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Benedict, Kansas"
    },
    {
      "city": "Buffalo",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Buffalo, Kansas"
    },
    {
      "city": "Fredonia",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Fredonia, Kansas"
    },
    {
      "city": "Neodesha",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Neodesha, Kansas"
    },
    {
      "city": "New Albany",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "New Albany, Kansas"
    },
    {
      "city": "Neosho Falls",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Neosho Falls, Kansas"
    },
    {
      "city": "Piqua",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Piqua, Kansas"
    },
    {
      "city": "Toronto",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Toronto, Kansas"
    },
    {
      "city": "Yates Center",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Yates Center, Kansas"
    },
    {
      "city": "Bonner Springs",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Bonner Springs, Kansas"
    },
    {
      "city": "Kansas City",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Kansas City, Kansas"
    },
    {
      "city": "Edwardsville",
      "state": "Kansas",
      "shortState": "KS",
      "formatedCity": "Edwardsville, Kansas"
    },
    {
      "city": "Breeding",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Breeding, Kentucky"
    },
    {
      "city": "Cane Valley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cane Valley, Kentucky"
    },
    {
      "city": "Columbia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Columbia, Kentucky"
    },
    {
      "city": "Glens Fork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Glens Fork, Kentucky"
    },
    {
      "city": "Gradyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gradyville, Kentucky"
    },
    {
      "city": "Knifley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Knifley, Kentucky"
    },
    {
      "city": "Adolphus",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Adolphus, Kentucky"
    },
    {
      "city": "Holland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Holland, Kentucky"
    },
    {
      "city": "Scottsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Scottsville, Kentucky"
    },
    {
      "city": "Lawrenceburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lawrenceburg, Kentucky"
    },
    {
      "city": "Bandana",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bandana, Kentucky"
    },
    {
      "city": "Barlow",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Barlow, Kentucky"
    },
    {
      "city": "La Center",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "La Center, Kentucky"
    },
    {
      "city": "Lovelaceville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lovelaceville, Kentucky"
    },
    {
      "city": "Wickliffe",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wickliffe, Kentucky"
    },
    {
      "city": "Austin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Austin, Kentucky"
    },
    {
      "city": "Cave City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cave City, Kentucky"
    },
    {
      "city": "Eighty Eight",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eighty Eight, Kentucky"
    },
    {
      "city": "Etoile",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Etoile, Kentucky"
    },
    {
      "city": "Glasgow",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Glasgow, Kentucky"
    },
    {
      "city": "Hiseville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hiseville, Kentucky"
    },
    {
      "city": "Lucas",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lucas, Kentucky"
    },
    {
      "city": "Park City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Park City, Kentucky"
    },
    {
      "city": "Olympia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Olympia, Kentucky"
    },
    {
      "city": "Owingsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Owingsville, Kentucky"
    },
    {
      "city": "Preston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Preston, Kentucky"
    },
    {
      "city": "Salt Lick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Salt Lick, Kentucky"
    },
    {
      "city": "Sharpsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sharpsburg, Kentucky"
    },
    {
      "city": "Calvin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Calvin, Kentucky"
    },
    {
      "city": "Hulen",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hulen, Kentucky"
    },
    {
      "city": "Miracle",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Miracle, Kentucky"
    },
    {
      "city": "Arjay",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Arjay, Kentucky"
    },
    {
      "city": "Beverly",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beverly, Kentucky"
    },
    {
      "city": "Frakes",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Frakes, Kentucky"
    },
    {
      "city": "Ingram",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ingram, Kentucky"
    },
    {
      "city": "Kettle Island",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kettle Island, Kentucky"
    },
    {
      "city": "Middlesboro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Middlesboro, Kentucky"
    },
    {
      "city": "Pineville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pineville, Kentucky"
    },
    {
      "city": "Stoney Fork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stoney Fork, Kentucky"
    },
    {
      "city": "Burlington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burlington, Kentucky"
    },
    {
      "city": "Hebron",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hebron, Kentucky"
    },
    {
      "city": "Florence",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Florence, Kentucky"
    },
    {
      "city": "Petersburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Petersburg, Kentucky"
    },
    {
      "city": "Union",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Union, Kentucky"
    },
    {
      "city": "Verona",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Verona, Kentucky"
    },
    {
      "city": "Walton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Walton, Kentucky"
    },
    {
      "city": "Millersburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Millersburg, Kentucky"
    },
    {
      "city": "North Middletown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "North Middletown, Kentucky"
    },
    {
      "city": "Paris",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Paris, Kentucky"
    },
    {
      "city": "Ashland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ashland, Kentucky"
    },
    {
      "city": "Catlettsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Catlettsburg, Kentucky"
    },
    {
      "city": "Rush",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rush, Kentucky"
    },
    {
      "city": "Danville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Danville, Kentucky"
    },
    {
      "city": "Junction City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Junction City, Kentucky"
    },
    {
      "city": "Mitchellsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mitchellsburg, Kentucky"
    },
    {
      "city": "Parksville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Parksville, Kentucky"
    },
    {
      "city": "Perryville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Perryville, Kentucky"
    },
    {
      "city": "Augusta",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Augusta, Kentucky"
    },
    {
      "city": "Brooksville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Brooksville, Kentucky"
    },
    {
      "city": "Foster",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Foster, Kentucky"
    },
    {
      "city": "Germantown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Germantown, Kentucky"
    },
    {
      "city": "Milford",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Milford, Kentucky"
    },
    {
      "city": "Bays",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bays, Kentucky"
    },
    {
      "city": "Clayhole",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clayhole, Kentucky"
    },
    {
      "city": "Jackson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jackson, Kentucky"
    },
    {
      "city": "Lost Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lost Creek, Kentucky"
    },
    {
      "city": "Rousseau",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rousseau, Kentucky"
    },
    {
      "city": "Vancleve",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vancleve, Kentucky"
    },
    {
      "city": "Whick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Whick, Kentucky"
    },
    {
      "city": "Cloverport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cloverport, Kentucky"
    },
    {
      "city": "Custer",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Custer, Kentucky"
    },
    {
      "city": "Garfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Garfield, Kentucky"
    },
    {
      "city": "Hardinsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hardinsburg, Kentucky"
    },
    {
      "city": "Harned",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Harned, Kentucky"
    },
    {
      "city": "Hudson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hudson, Kentucky"
    },
    {
      "city": "Irvington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Irvington, Kentucky"
    },
    {
      "city": "Mc Daniels",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Daniels, Kentucky"
    },
    {
      "city": "Mc Quady",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Quady, Kentucky"
    },
    {
      "city": "Stephensport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stephensport, Kentucky"
    },
    {
      "city": "Union Star",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Union Star, Kentucky"
    },
    {
      "city": "Webster",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Webster, Kentucky"
    },
    {
      "city": "Westview",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Westview, Kentucky"
    },
    {
      "city": "Mount Washington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Washington, Kentucky"
    },
    {
      "city": "Brooks",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Brooks, Kentucky"
    },
    {
      "city": "Clermont",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clermont, Kentucky"
    },
    {
      "city": "Hillview",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hillview, Kentucky"
    },
    {
      "city": "Lebanon Junction",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lebanon Junction, Kentucky"
    },
    {
      "city": "Shepherdsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Shepherdsville, Kentucky"
    },
    {
      "city": "Aberdeen",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Aberdeen, Kentucky"
    },
    {
      "city": "Dunbar",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dunbar, Kentucky"
    },
    {
      "city": "Jetson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jetson, Kentucky"
    },
    {
      "city": "Morgantown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Morgantown, Kentucky"
    },
    {
      "city": "Rochester",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rochester, Kentucky"
    },
    {
      "city": "Woodbury",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Woodbury, Kentucky"
    },
    {
      "city": "Fredonia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fredonia, Kentucky"
    },
    {
      "city": "Princeton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Princeton, Kentucky"
    },
    {
      "city": "Almo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Almo, Kentucky"
    },
    {
      "city": "Dexter",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dexter, Kentucky"
    },
    {
      "city": "Hazel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hazel, Kentucky"
    },
    {
      "city": "Kirksey",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kirksey, Kentucky"
    },
    {
      "city": "Murray",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Murray, Kentucky"
    },
    {
      "city": "New Concord",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "New Concord, Kentucky"
    },
    {
      "city": "Alexandria",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Alexandria, Kentucky"
    },
    {
      "city": "California",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "California, Kentucky"
    },
    {
      "city": "Melbourne",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Melbourne, Kentucky"
    },
    {
      "city": "Newport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Newport, Kentucky"
    },
    {
      "city": "Bellevue",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bellevue, Kentucky"
    },
    {
      "city": "Dayton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dayton, Kentucky"
    },
    {
      "city": "Fort Thomas",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fort Thomas, Kentucky"
    },
    {
      "city": "Silver Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Silver Grove, Kentucky"
    },
    {
      "city": "Arlington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Arlington, Kentucky"
    },
    {
      "city": "Bardwell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bardwell, Kentucky"
    },
    {
      "city": "Cunningham",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cunningham, Kentucky"
    },
    {
      "city": "Milburn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Milburn, Kentucky"
    },
    {
      "city": "Carrollton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Carrollton, Kentucky"
    },
    {
      "city": "Ghent",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ghent, Kentucky"
    },
    {
      "city": "Sanders",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sanders, Kentucky"
    },
    {
      "city": "Worthville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Worthville, Kentucky"
    },
    {
      "city": "Carter",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Carter, Kentucky"
    },
    {
      "city": "Denton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Denton, Kentucky"
    },
    {
      "city": "Grahn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Grahn, Kentucky"
    },
    {
      "city": "Grayson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Grayson, Kentucky"
    },
    {
      "city": "Hitchins",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hitchins, Kentucky"
    },
    {
      "city": "Olive Hill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Olive Hill, Kentucky"
    },
    {
      "city": "Soldier",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Soldier, Kentucky"
    },
    {
      "city": "Willard",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Willard, Kentucky"
    },
    {
      "city": "Bethelridge",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bethelridge, Kentucky"
    },
    {
      "city": "Dunnville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dunnville, Kentucky"
    },
    {
      "city": "Liberty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Liberty, Kentucky"
    },
    {
      "city": "Middleburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Middleburg, Kentucky"
    },
    {
      "city": "Windsor",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Windsor, Kentucky"
    },
    {
      "city": "Yosemite",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Yosemite, Kentucky"
    },
    {
      "city": "Crofton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crofton, Kentucky"
    },
    {
      "city": "Fairview",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fairview, Kentucky"
    },
    {
      "city": "Fort Campbell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fort Campbell, Kentucky"
    },
    {
      "city": "Gracey",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gracey, Kentucky"
    },
    {
      "city": "Herndon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Herndon, Kentucky"
    },
    {
      "city": "Hopkinsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hopkinsville, Kentucky"
    },
    {
      "city": "La Fayette",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "La Fayette, Kentucky"
    },
    {
      "city": "Oak Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Oak Grove, Kentucky"
    },
    {
      "city": "Pembroke",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pembroke, Kentucky"
    },
    {
      "city": "Winchester",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Winchester, Kentucky"
    },
    {
      "city": "Big Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Big Creek, Kentucky"
    },
    {
      "city": "Fall Rock",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fall Rock, Kentucky"
    },
    {
      "city": "Garrard",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Garrard, Kentucky"
    },
    {
      "city": "Goose Rock",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Goose Rock, Kentucky"
    },
    {
      "city": "Hima",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hima, Kentucky"
    },
    {
      "city": "Manchester",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Manchester, Kentucky"
    },
    {
      "city": "Oneida",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Oneida, Kentucky"
    },
    {
      "city": "Sextons Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sextons Creek, Kentucky"
    },
    {
      "city": "Albany",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Albany, Kentucky"
    },
    {
      "city": "Alpha",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Alpha, Kentucky"
    },
    {
      "city": "Crayne",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crayne, Kentucky"
    },
    {
      "city": "Dycusburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dycusburg, Kentucky"
    },
    {
      "city": "Marion",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Marion, Kentucky"
    },
    {
      "city": "Burkesville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burkesville, Kentucky"
    },
    {
      "city": "Marrowbone",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Marrowbone, Kentucky"
    },
    {
      "city": "Owensboro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Owensboro, Kentucky"
    },
    {
      "city": "Curdsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Curdsville, Kentucky"
    },
    {
      "city": "Maceo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Maceo, Kentucky"
    },
    {
      "city": "Maple Mount",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Maple Mount, Kentucky"
    },
    {
      "city": "Philpot",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Philpot, Kentucky"
    },
    {
      "city": "Utica",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Utica, Kentucky"
    },
    {
      "city": "West Louisville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Louisville, Kentucky"
    },
    {
      "city": "Whitesville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Whitesville, Kentucky"
    },
    {
      "city": "Rocky Hill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rocky Hill, Kentucky"
    },
    {
      "city": "Bee Spring",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bee Spring, Kentucky"
    },
    {
      "city": "Brownsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Brownsville, Kentucky"
    },
    {
      "city": "Mammoth Cave",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mammoth Cave, Kentucky"
    },
    {
      "city": "Roundhill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Roundhill, Kentucky"
    },
    {
      "city": "Sweeden",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sweeden, Kentucky"
    },
    {
      "city": "Isonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Isonville, Kentucky"
    },
    {
      "city": "Sandy Hook",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sandy Hook, Kentucky"
    },
    {
      "city": "Irvine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Irvine, Kentucky"
    },
    {
      "city": "Ravenna",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ravenna, Kentucky"
    },
    {
      "city": "Lexington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lexington, Kentucky"
    },
    {
      "city": "Elizaville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elizaville, Kentucky"
    },
    {
      "city": "Ewing",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ewing, Kentucky"
    },
    {
      "city": "Flemingsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Flemingsburg, Kentucky"
    },
    {
      "city": "Hillsboro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hillsboro, Kentucky"
    },
    {
      "city": "Muses Mills",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Muses Mills, Kentucky"
    },
    {
      "city": "Plummers Landing",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Plummers Landing, Kentucky"
    },
    {
      "city": "Wallingford",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wallingford, Kentucky"
    },
    {
      "city": "Allen",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Allen, Kentucky"
    },
    {
      "city": "Auxier",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Auxier, Kentucky"
    },
    {
      "city": "Banner",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Banner, Kentucky"
    },
    {
      "city": "Beaver",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beaver, Kentucky"
    },
    {
      "city": "Betsy Layne",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Betsy Layne, Kentucky"
    },
    {
      "city": "Bevinsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bevinsville, Kentucky"
    },
    {
      "city": "Blue River",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Blue River, Kentucky"
    },
    {
      "city": "Bypro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bypro, Kentucky"
    },
    {
      "city": "Dana",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dana, Kentucky"
    },
    {
      "city": "David",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "David, Kentucky"
    },
    {
      "city": "Drift",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Drift, Kentucky"
    },
    {
      "city": "Dwale",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dwale, Kentucky"
    },
    {
      "city": "Eastern",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eastern, Kentucky"
    },
    {
      "city": "Garrett",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Garrett, Kentucky"
    },
    {
      "city": "Grethel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Grethel, Kentucky"
    },
    {
      "city": "Harold",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Harold, Kentucky"
    },
    {
      "city": "Hi Hat",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hi Hat, Kentucky"
    },
    {
      "city": "Hueysville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hueysville, Kentucky"
    },
    {
      "city": "Ivel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ivel, Kentucky"
    },
    {
      "city": "Lackey",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lackey, Kentucky"
    },
    {
      "city": "Langley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Langley, Kentucky"
    },
    {
      "city": "Mc Dowell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Dowell, Kentucky"
    },
    {
      "city": "Martin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Martin, Kentucky"
    },
    {
      "city": "Melvin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Melvin, Kentucky"
    },
    {
      "city": "Minnie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Minnie, Kentucky"
    },
    {
      "city": "Prestonsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Prestonsburg, Kentucky"
    },
    {
      "city": "Printer",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Printer, Kentucky"
    },
    {
      "city": "Stanville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stanville, Kentucky"
    },
    {
      "city": "Teaberry",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Teaberry, Kentucky"
    },
    {
      "city": "Tram",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tram, Kentucky"
    },
    {
      "city": "Wayland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wayland, Kentucky"
    },
    {
      "city": "Weeksbury",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Weeksbury, Kentucky"
    },
    {
      "city": "Wheelwright",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wheelwright, Kentucky"
    },
    {
      "city": "Frankfort",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Frankfort, Kentucky"
    },
    {
      "city": "Fulton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fulton, Kentucky"
    },
    {
      "city": "Hickman",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hickman, Kentucky"
    },
    {
      "city": "Glencoe",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Glencoe, Kentucky"
    },
    {
      "city": "Sparta",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sparta, Kentucky"
    },
    {
      "city": "Warsaw",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Warsaw, Kentucky"
    },
    {
      "city": "Bryantsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bryantsville, Kentucky"
    },
    {
      "city": "Lancaster",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lancaster, Kentucky"
    },
    {
      "city": "Paint Lick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Paint Lick, Kentucky"
    },
    {
      "city": "Corinth",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Corinth, Kentucky"
    },
    {
      "city": "Crittenden",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crittenden, Kentucky"
    },
    {
      "city": "Dry Ridge",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dry Ridge, Kentucky"
    },
    {
      "city": "Jonesville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jonesville, Kentucky"
    },
    {
      "city": "Mason",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mason, Kentucky"
    },
    {
      "city": "Williamstown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Williamstown, Kentucky"
    },
    {
      "city": "Boaz",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Boaz, Kentucky"
    },
    {
      "city": "Fancy Farm",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fancy Farm, Kentucky"
    },
    {
      "city": "Farmington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Farmington, Kentucky"
    },
    {
      "city": "Hickory",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hickory, Kentucky"
    },
    {
      "city": "Lowes",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lowes, Kentucky"
    },
    {
      "city": "Lynnville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lynnville, Kentucky"
    },
    {
      "city": "Mayfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mayfield, Kentucky"
    },
    {
      "city": "Melber",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Melber, Kentucky"
    },
    {
      "city": "Sedalia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sedalia, Kentucky"
    },
    {
      "city": "Symsonia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Symsonia, Kentucky"
    },
    {
      "city": "Water Valley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Water Valley, Kentucky"
    },
    {
      "city": "Wingo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wingo, Kentucky"
    },
    {
      "city": "Falls Of Rough",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Falls Of Rough, Kentucky"
    },
    {
      "city": "Big Clifty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Big Clifty, Kentucky"
    },
    {
      "city": "Caneyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Caneyville, Kentucky"
    },
    {
      "city": "Clarkson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clarkson, Kentucky"
    },
    {
      "city": "Leitchfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Leitchfield, Kentucky"
    },
    {
      "city": "Millwood",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Millwood, Kentucky"
    },
    {
      "city": "Greensburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Greensburg, Kentucky"
    },
    {
      "city": "Summersville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Summersville, Kentucky"
    },
    {
      "city": "Argillite",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Argillite, Kentucky"
    },
    {
      "city": "Flatwoods",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Flatwoods, Kentucky"
    },
    {
      "city": "Greenup",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Greenup, Kentucky"
    },
    {
      "city": "Russell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Russell, Kentucky"
    },
    {
      "city": "South Portsmouth",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "South Portsmouth, Kentucky"
    },
    {
      "city": "South Shore",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "South Shore, Kentucky"
    },
    {
      "city": "Worthington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Worthington, Kentucky"
    },
    {
      "city": "Hawesville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hawesville, Kentucky"
    },
    {
      "city": "Lewisport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lewisport, Kentucky"
    },
    {
      "city": "Pellville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pellville, Kentucky"
    },
    {
      "city": "Reynolds Station",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Reynolds Station, Kentucky"
    },
    {
      "city": "Fort Knox",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fort Knox, Kentucky"
    },
    {
      "city": "Radcliff",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Radcliff, Kentucky"
    },
    {
      "city": "Rineyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rineyville, Kentucky"
    },
    {
      "city": "Vine Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vine Grove, Kentucky"
    },
    {
      "city": "West Point",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Point, Kentucky"
    },
    {
      "city": "Elizabethtown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elizabethtown, Kentucky"
    },
    {
      "city": "Cecilia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cecilia, Kentucky"
    },
    {
      "city": "Eastview",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eastview, Kentucky"
    },
    {
      "city": "Glendale",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Glendale, Kentucky"
    },
    {
      "city": "Sonora",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sonora, Kentucky"
    },
    {
      "city": "Upton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Upton, Kentucky"
    },
    {
      "city": "White Mills",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "White Mills, Kentucky"
    },
    {
      "city": "Ages Brookside",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ages Brookside, Kentucky"
    },
    {
      "city": "Baxter",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Baxter, Kentucky"
    },
    {
      "city": "Benham",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Benham, Kentucky"
    },
    {
      "city": "Big Laurel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Big Laurel, Kentucky"
    },
    {
      "city": "Bledsoe",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bledsoe, Kentucky"
    },
    {
      "city": "Cawood",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cawood, Kentucky"
    },
    {
      "city": "Coalgood",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Coalgood, Kentucky"
    },
    {
      "city": "Coldiron",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Coldiron, Kentucky"
    },
    {
      "city": "Cranks",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cranks, Kentucky"
    },
    {
      "city": "Cumberland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cumberland, Kentucky"
    },
    {
      "city": "Dayhoit",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dayhoit, Kentucky"
    },
    {
      "city": "Evarts",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Evarts, Kentucky"
    },
    {
      "city": "Grays Knob",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Grays Knob, Kentucky"
    },
    {
      "city": "Gulston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gulston, Kentucky"
    },
    {
      "city": "Harlan",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Harlan, Kentucky"
    },
    {
      "city": "Holmes Mill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Holmes Mill, Kentucky"
    },
    {
      "city": "Kenvir",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kenvir, Kentucky"
    },
    {
      "city": "Lejunior",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lejunior, Kentucky"
    },
    {
      "city": "Loyall",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Loyall, Kentucky"
    },
    {
      "city": "Lynch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lynch, Kentucky"
    },
    {
      "city": "Pathfork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pathfork, Kentucky"
    },
    {
      "city": "Putney",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Putney, Kentucky"
    },
    {
      "city": "Totz",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Totz, Kentucky"
    },
    {
      "city": "Wallins Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wallins Creek, Kentucky"
    },
    {
      "city": "Closplint",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Closplint, Kentucky"
    },
    {
      "city": "Mary Alice",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mary Alice, Kentucky"
    },
    {
      "city": "Berry",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Berry, Kentucky"
    },
    {
      "city": "Cynthiana",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cynthiana, Kentucky"
    },
    {
      "city": "Bonnieville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bonnieville, Kentucky"
    },
    {
      "city": "Canmer",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Canmer, Kentucky"
    },
    {
      "city": "Cub Run",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cub Run, Kentucky"
    },
    {
      "city": "Hardyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hardyville, Kentucky"
    },
    {
      "city": "Horse Cave",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Horse Cave, Kentucky"
    },
    {
      "city": "Munfordville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Munfordville, Kentucky"
    },
    {
      "city": "Baskett",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Baskett, Kentucky"
    },
    {
      "city": "Corydon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Corydon, Kentucky"
    },
    {
      "city": "Henderson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Henderson, Kentucky"
    },
    {
      "city": "Reed",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Reed, Kentucky"
    },
    {
      "city": "Robards",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Robards, Kentucky"
    },
    {
      "city": "Smith Mills",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Smith Mills, Kentucky"
    },
    {
      "city": "Spottsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Spottsville, Kentucky"
    },
    {
      "city": "Bethlehem",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bethlehem, Kentucky"
    },
    {
      "city": "Campbellsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Campbellsburg, Kentucky"
    },
    {
      "city": "Eminence",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eminence, Kentucky"
    },
    {
      "city": "Lockport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lockport, Kentucky"
    },
    {
      "city": "New Castle",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "New Castle, Kentucky"
    },
    {
      "city": "Pendleton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pendleton, Kentucky"
    },
    {
      "city": "Pleasureville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pleasureville, Kentucky"
    },
    {
      "city": "Port Royal",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Port Royal, Kentucky"
    },
    {
      "city": "Smithfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Smithfield, Kentucky"
    },
    {
      "city": "Sulphur",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sulphur, Kentucky"
    },
    {
      "city": "Turners Station",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Turners Station, Kentucky"
    },
    {
      "city": "Clinton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clinton, Kentucky"
    },
    {
      "city": "Columbus",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Columbus, Kentucky"
    },
    {
      "city": "Dawson Springs",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dawson Springs, Kentucky"
    },
    {
      "city": "Earlington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Earlington, Kentucky"
    },
    {
      "city": "Hanson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hanson, Kentucky"
    },
    {
      "city": "Madisonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Madisonville, Kentucky"
    },
    {
      "city": "Manitou",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Manitou, Kentucky"
    },
    {
      "city": "Mortons Gap",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mortons Gap, Kentucky"
    },
    {
      "city": "Nebo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nebo, Kentucky"
    },
    {
      "city": "Nortonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nortonville, Kentucky"
    },
    {
      "city": "Saint Charles",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saint Charles, Kentucky"
    },
    {
      "city": "White Plains",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "White Plains, Kentucky"
    },
    {
      "city": "Annville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Annville, Kentucky"
    },
    {
      "city": "Gray Hawk",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gray Hawk, Kentucky"
    },
    {
      "city": "Mc Kee",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Kee, Kentucky"
    },
    {
      "city": "Sandgap",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sandgap, Kentucky"
    },
    {
      "city": "Tyner",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tyner, Kentucky"
    },
    {
      "city": "Waneta",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Waneta, Kentucky"
    },
    {
      "city": "Eastwood",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eastwood, Kentucky"
    },
    {
      "city": "Fisherville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fisherville, Kentucky"
    },
    {
      "city": "Glenview",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Glenview, Kentucky"
    },
    {
      "city": "Harrods Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Harrods Creek, Kentucky"
    },
    {
      "city": "Masonic Home",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Masonic Home, Kentucky"
    },
    {
      "city": "Prospect",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Prospect, Kentucky"
    },
    {
      "city": "Fairdale",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fairdale, Kentucky"
    },
    {
      "city": "Louisville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Louisville, Kentucky"
    },
    {
      "city": "Keene",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Keene, Kentucky"
    },
    {
      "city": "Nicholasville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nicholasville, Kentucky"
    },
    {
      "city": "Wilmore",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wilmore, Kentucky"
    },
    {
      "city": "Boons Camp",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Boons Camp, Kentucky"
    },
    {
      "city": "East Point",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "East Point, Kentucky"
    },
    {
      "city": "Flatgap",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Flatgap, Kentucky"
    },
    {
      "city": "Hagerhill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hagerhill, Kentucky"
    },
    {
      "city": "Keaton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Keaton, Kentucky"
    },
    {
      "city": "Meally",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Meally, Kentucky"
    },
    {
      "city": "Oil Springs",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Oil Springs, Kentucky"
    },
    {
      "city": "Paintsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Paintsville, Kentucky"
    },
    {
      "city": "River",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "River, Kentucky"
    },
    {
      "city": "Sitka",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sitka, Kentucky"
    },
    {
      "city": "Staffordsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Staffordsville, Kentucky"
    },
    {
      "city": "Stambaugh",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stambaugh, Kentucky"
    },
    {
      "city": "Thelma",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Thelma, Kentucky"
    },
    {
      "city": "Tutor Key",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tutor Key, Kentucky"
    },
    {
      "city": "Van Lear",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Van Lear, Kentucky"
    },
    {
      "city": "West Van Lear",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Van Lear, Kentucky"
    },
    {
      "city": "Williamsport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Williamsport, Kentucky"
    },
    {
      "city": "Wittensville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wittensville, Kentucky"
    },
    {
      "city": "Covington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Covington, Kentucky"
    },
    {
      "city": "Latonia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Latonia, Kentucky"
    },
    {
      "city": "Ft Mitchell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ft Mitchell, Kentucky"
    },
    {
      "city": "Erlanger",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Erlanger, Kentucky"
    },
    {
      "city": "Independence",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Independence, Kentucky"
    },
    {
      "city": "Kenton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kenton, Kentucky"
    },
    {
      "city": "Morning View",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Morning View, Kentucky"
    },
    {
      "city": "Carrie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Carrie, Kentucky"
    },
    {
      "city": "Emmalena",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Emmalena, Kentucky"
    },
    {
      "city": "Fisty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fisty, Kentucky"
    },
    {
      "city": "Sassafras",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sassafras, Kentucky"
    },
    {
      "city": "Vest",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vest, Kentucky"
    },
    {
      "city": "Garner",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Garner, Kentucky"
    },
    {
      "city": "Hindman",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hindman, Kentucky"
    },
    {
      "city": "Kite",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kite, Kentucky"
    },
    {
      "city": "Leburn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Leburn, Kentucky"
    },
    {
      "city": "Littcarr",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Littcarr, Kentucky"
    },
    {
      "city": "Mallie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mallie, Kentucky"
    },
    {
      "city": "Mousie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mousie, Kentucky"
    },
    {
      "city": "Pine Top",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pine Top, Kentucky"
    },
    {
      "city": "Pippa Passes",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pippa Passes, Kentucky"
    },
    {
      "city": "Redfox",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Redfox, Kentucky"
    },
    {
      "city": "Dema",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dema, Kentucky"
    },
    {
      "city": "Raven",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Raven, Kentucky"
    },
    {
      "city": "Topmost",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Topmost, Kentucky"
    },
    {
      "city": "Gray",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gray, Kentucky"
    },
    {
      "city": "Woodbine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Woodbine, Kentucky"
    },
    {
      "city": "Artemus",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Artemus, Kentucky"
    },
    {
      "city": "Barbourville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Barbourville, Kentucky"
    },
    {
      "city": "Bimble",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bimble, Kentucky"
    },
    {
      "city": "Bryants Store",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bryants Store, Kentucky"
    },
    {
      "city": "Cannon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cannon, Kentucky"
    },
    {
      "city": "Dewitt",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dewitt, Kentucky"
    },
    {
      "city": "Flat Lick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Flat Lick, Kentucky"
    },
    {
      "city": "Fourmile",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fourmile, Kentucky"
    },
    {
      "city": "Girdler",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Girdler, Kentucky"
    },
    {
      "city": "Green Road",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Green Road, Kentucky"
    },
    {
      "city": "Heidrick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Heidrick, Kentucky"
    },
    {
      "city": "Hinkle",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hinkle, Kentucky"
    },
    {
      "city": "Scalf",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Scalf, Kentucky"
    },
    {
      "city": "Trosper",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Trosper, Kentucky"
    },
    {
      "city": "Walker",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Walker, Kentucky"
    },
    {
      "city": "Buffalo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Buffalo, Kentucky"
    },
    {
      "city": "Hodgenville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hodgenville, Kentucky"
    },
    {
      "city": "Magnolia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Magnolia, Kentucky"
    },
    {
      "city": "Mount Sherman",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Sherman, Kentucky"
    },
    {
      "city": "Bush",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bush, Kentucky"
    },
    {
      "city": "East Bernstadt",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "East Bernstadt, Kentucky"
    },
    {
      "city": "Keavy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Keavy, Kentucky"
    },
    {
      "city": "Lily",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lily, Kentucky"
    },
    {
      "city": "London",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "London, Kentucky"
    },
    {
      "city": "Pittsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pittsburg, Kentucky"
    },
    {
      "city": "Blaine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Blaine, Kentucky"
    },
    {
      "city": "Martha",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Martha, Kentucky"
    },
    {
      "city": "Mazie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mazie, Kentucky"
    },
    {
      "city": "Webbville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Webbville, Kentucky"
    },
    {
      "city": "Adams",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Adams, Kentucky"
    },
    {
      "city": "Louisa",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Louisa, Kentucky"
    },
    {
      "city": "Lowmansville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lowmansville, Kentucky"
    },
    {
      "city": "Ulysses",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ulysses, Kentucky"
    },
    {
      "city": "Beattyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beattyville, Kentucky"
    },
    {
      "city": "Lone",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lone, Kentucky"
    },
    {
      "city": "Saint Helens",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saint Helens, Kentucky"
    },
    {
      "city": "Zoe",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Zoe, Kentucky"
    },
    {
      "city": "Asher",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Asher, Kentucky"
    },
    {
      "city": "Chappell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Chappell, Kentucky"
    },
    {
      "city": "Essie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Essie, Kentucky"
    },
    {
      "city": "Helton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Helton, Kentucky"
    },
    {
      "city": "Hoskinston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hoskinston, Kentucky"
    },
    {
      "city": "Mozelle",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mozelle, Kentucky"
    },
    {
      "city": "Stinnett",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stinnett, Kentucky"
    },
    {
      "city": "Warbranch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Warbranch, Kentucky"
    },
    {
      "city": "Roark",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Roark, Kentucky"
    },
    {
      "city": "Bear Branch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bear Branch, Kentucky"
    },
    {
      "city": "Hyden",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hyden, Kentucky"
    },
    {
      "city": "Sizerock",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sizerock, Kentucky"
    },
    {
      "city": "Smilax",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Smilax, Kentucky"
    },
    {
      "city": "Thousandsticks",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Thousandsticks, Kentucky"
    },
    {
      "city": "Wendover",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wendover, Kentucky"
    },
    {
      "city": "Wooton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wooton, Kentucky"
    },
    {
      "city": "Yeaddiss",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Yeaddiss, Kentucky"
    },
    {
      "city": "Eolia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eolia, Kentucky"
    },
    {
      "city": "Partridge",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Partridge, Kentucky"
    },
    {
      "city": "Burdine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burdine, Kentucky"
    },
    {
      "city": "Jenkins",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jenkins, Kentucky"
    },
    {
      "city": "Blackey",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Blackey, Kentucky"
    },
    {
      "city": "Cromona",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cromona, Kentucky"
    },
    {
      "city": "Deane",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Deane, Kentucky"
    },
    {
      "city": "Ermine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ermine, Kentucky"
    },
    {
      "city": "Gordon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gordon, Kentucky"
    },
    {
      "city": "Hallie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hallie, Kentucky"
    },
    {
      "city": "Isom",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Isom, Kentucky"
    },
    {
      "city": "Jackhorn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jackhorn, Kentucky"
    },
    {
      "city": "Jeremiah",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jeremiah, Kentucky"
    },
    {
      "city": "Letcher",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Letcher, Kentucky"
    },
    {
      "city": "Linefork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Linefork, Kentucky"
    },
    {
      "city": "Mc Roberts",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Roberts, Kentucky"
    },
    {
      "city": "Mayking",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mayking, Kentucky"
    },
    {
      "city": "Millstone",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Millstone, Kentucky"
    },
    {
      "city": "Neon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Neon, Kentucky"
    },
    {
      "city": "Premium",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Premium, Kentucky"
    },
    {
      "city": "Roxana",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Roxana, Kentucky"
    },
    {
      "city": "Seco",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Seco, Kentucky"
    },
    {
      "city": "Thornton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Thornton, Kentucky"
    },
    {
      "city": "Whitesburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Whitesburg, Kentucky"
    },
    {
      "city": "Emerson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Emerson, Kentucky"
    },
    {
      "city": "Garrison",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Garrison, Kentucky"
    },
    {
      "city": "Quincy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Quincy, Kentucky"
    },
    {
      "city": "Vanceburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vanceburg, Kentucky"
    },
    {
      "city": "Tollesboro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tollesboro, Kentucky"
    },
    {
      "city": "Crab Orchard",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crab Orchard, Kentucky"
    },
    {
      "city": "Hustonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hustonville, Kentucky"
    },
    {
      "city": "Kings Mountain",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kings Mountain, Kentucky"
    },
    {
      "city": "Mc Kinney",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Kinney, Kentucky"
    },
    {
      "city": "Stanford",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stanford, Kentucky"
    },
    {
      "city": "Waynesburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Waynesburg, Kentucky"
    },
    {
      "city": "Burna",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burna, Kentucky"
    },
    {
      "city": "Grand Rivers",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Grand Rivers, Kentucky"
    },
    {
      "city": "Hampton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hampton, Kentucky"
    },
    {
      "city": "Ledbetter",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ledbetter, Kentucky"
    },
    {
      "city": "Salem",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Salem, Kentucky"
    },
    {
      "city": "Smithland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Smithland, Kentucky"
    },
    {
      "city": "Tiline",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tiline, Kentucky"
    },
    {
      "city": "Adairville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Adairville, Kentucky"
    },
    {
      "city": "Auburn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Auburn, Kentucky"
    },
    {
      "city": "Lewisburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lewisburg, Kentucky"
    },
    {
      "city": "Olmstead",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Olmstead, Kentucky"
    },
    {
      "city": "Russellville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Russellville, Kentucky"
    },
    {
      "city": "Eddyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eddyville, Kentucky"
    },
    {
      "city": "Kuttawa",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kuttawa, Kentucky"
    },
    {
      "city": "Paducah",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Paducah, Kentucky"
    },
    {
      "city": "Kevil",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kevil, Kentucky"
    },
    {
      "city": "West Paducah",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Paducah, Kentucky"
    },
    {
      "city": "Marshes Siding",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Marshes Siding, Kentucky"
    },
    {
      "city": "Parkers Lake",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Parkers Lake, Kentucky"
    },
    {
      "city": "Pine Knot",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pine Knot, Kentucky"
    },
    {
      "city": "Revelo",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Revelo, Kentucky"
    },
    {
      "city": "Stearns",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stearns, Kentucky"
    },
    {
      "city": "Strunk",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Strunk, Kentucky"
    },
    {
      "city": "Whitley City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Whitley City, Kentucky"
    },
    {
      "city": "Beech Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beech Grove, Kentucky"
    },
    {
      "city": "Calhoun",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Calhoun, Kentucky"
    },
    {
      "city": "Island",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Island, Kentucky"
    },
    {
      "city": "Livermore",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Livermore, Kentucky"
    },
    {
      "city": "Rumsey",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rumsey, Kentucky"
    },
    {
      "city": "Sacramento",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sacramento, Kentucky"
    },
    {
      "city": "Waco",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Waco, Kentucky"
    },
    {
      "city": "Berea",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Berea, Kentucky"
    },
    {
      "city": "Bighill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bighill, Kentucky"
    },
    {
      "city": "Richmond",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Richmond, Kentucky"
    },
    {
      "city": "Falcon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Falcon, Kentucky"
    },
    {
      "city": "Royalton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Royalton, Kentucky"
    },
    {
      "city": "Salyersville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Salyersville, Kentucky"
    },
    {
      "city": "Gunlock",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gunlock, Kentucky"
    },
    {
      "city": "Bradfordsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bradfordsville, Kentucky"
    },
    {
      "city": "Lebanon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lebanon, Kentucky"
    },
    {
      "city": "Loretto",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Loretto, Kentucky"
    },
    {
      "city": "Nerinx",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nerinx, Kentucky"
    },
    {
      "city": "Raywick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Raywick, Kentucky"
    },
    {
      "city": "Saint Francis",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saint Francis, Kentucky"
    },
    {
      "city": "Saint Mary",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saint Mary, Kentucky"
    },
    {
      "city": "Gravel Switch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gravel Switch, Kentucky"
    },
    {
      "city": "Benton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Benton, Kentucky"
    },
    {
      "city": "Calvert City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Calvert City, Kentucky"
    },
    {
      "city": "Gilbertsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gilbertsville, Kentucky"
    },
    {
      "city": "Hardin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hardin, Kentucky"
    },
    {
      "city": "Beauty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beauty, Kentucky"
    },
    {
      "city": "Debord",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Debord, Kentucky"
    },
    {
      "city": "Inez",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Inez, Kentucky"
    },
    {
      "city": "Lovely",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lovely, Kentucky"
    },
    {
      "city": "Pilgrim",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pilgrim, Kentucky"
    },
    {
      "city": "Tomahawk",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tomahawk, Kentucky"
    },
    {
      "city": "Warfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Warfield, Kentucky"
    },
    {
      "city": "Dover",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dover, Kentucky"
    },
    {
      "city": "Mayslick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mayslick, Kentucky"
    },
    {
      "city": "Maysville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Maysville, Kentucky"
    },
    {
      "city": "Minerva",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Minerva, Kentucky"
    },
    {
      "city": "Washington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Washington, Kentucky"
    },
    {
      "city": "Battletown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Battletown, Kentucky"
    },
    {
      "city": "Brandenburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Brandenburg, Kentucky"
    },
    {
      "city": "Ekron",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ekron, Kentucky"
    },
    {
      "city": "Guston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Guston, Kentucky"
    },
    {
      "city": "Muldraugh",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Muldraugh, Kentucky"
    },
    {
      "city": "Payneville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Payneville, Kentucky"
    },
    {
      "city": "Rhodelia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rhodelia, Kentucky"
    },
    {
      "city": "Denniston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Denniston, Kentucky"
    },
    {
      "city": "Frenchburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Frenchburg, Kentucky"
    },
    {
      "city": "Means",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Means, Kentucky"
    },
    {
      "city": "Wellington",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wellington, Kentucky"
    },
    {
      "city": "Burgin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burgin, Kentucky"
    },
    {
      "city": "Harrodsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Harrodsburg, Kentucky"
    },
    {
      "city": "Salvisa",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Salvisa, Kentucky"
    },
    {
      "city": "Beaumont",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beaumont, Kentucky"
    },
    {
      "city": "Edmonton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Edmonton, Kentucky"
    },
    {
      "city": "Knob Lick",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Knob Lick, Kentucky"
    },
    {
      "city": "Summer Shade",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Summer Shade, Kentucky"
    },
    {
      "city": "Center",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Center, Kentucky"
    },
    {
      "city": "Fountain Run",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fountain Run, Kentucky"
    },
    {
      "city": "Gamaliel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gamaliel, Kentucky"
    },
    {
      "city": "Hestand",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hestand, Kentucky"
    },
    {
      "city": "Mount Hermon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Hermon, Kentucky"
    },
    {
      "city": "Tompkinsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tompkinsville, Kentucky"
    },
    {
      "city": "Hope",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hope, Kentucky"
    },
    {
      "city": "Jeffersonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jeffersonville, Kentucky"
    },
    {
      "city": "Mount Sterling",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Sterling, Kentucky"
    },
    {
      "city": "Mize",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mize, Kentucky"
    },
    {
      "city": "Cannel City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cannel City, Kentucky"
    },
    {
      "city": "Crockett",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crockett, Kentucky"
    },
    {
      "city": "Elkfork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elkfork, Kentucky"
    },
    {
      "city": "Ezel",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ezel, Kentucky"
    },
    {
      "city": "Malone",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Malone, Kentucky"
    },
    {
      "city": "West Liberty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Liberty, Kentucky"
    },
    {
      "city": "Wrigley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wrigley, Kentucky"
    },
    {
      "city": "Beech Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beech Creek, Kentucky"
    },
    {
      "city": "Beechmont",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beechmont, Kentucky"
    },
    {
      "city": "Belton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Belton, Kentucky"
    },
    {
      "city": "Bremen",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bremen, Kentucky"
    },
    {
      "city": "Browder",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Browder, Kentucky"
    },
    {
      "city": "Central City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Central City, Kentucky"
    },
    {
      "city": "Cleaton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cleaton, Kentucky"
    },
    {
      "city": "Drakesboro",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Drakesboro, Kentucky"
    },
    {
      "city": "Dunmor",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dunmor, Kentucky"
    },
    {
      "city": "Graham",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Graham, Kentucky"
    },
    {
      "city": "Greenville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Greenville, Kentucky"
    },
    {
      "city": "Powderly",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Powderly, Kentucky"
    },
    {
      "city": "South Carrollton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "South Carrollton, Kentucky"
    },
    {
      "city": "Bardstown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bardstown, Kentucky"
    },
    {
      "city": "Bloomfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bloomfield, Kentucky"
    },
    {
      "city": "Chaplin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Chaplin, Kentucky"
    },
    {
      "city": "Coxs Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Coxs Creek, Kentucky"
    },
    {
      "city": "Fairfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fairfield, Kentucky"
    },
    {
      "city": "Nazareth",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nazareth, Kentucky"
    },
    {
      "city": "New Haven",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "New Haven, Kentucky"
    },
    {
      "city": "New Hope",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "New Hope, Kentucky"
    },
    {
      "city": "Boston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Boston, Kentucky"
    },
    {
      "city": "Carlisle",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Carlisle, Kentucky"
    },
    {
      "city": "Moorefield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Moorefield, Kentucky"
    },
    {
      "city": "Beaver Dam",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Beaver Dam, Kentucky"
    },
    {
      "city": "Centertown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Centertown, Kentucky"
    },
    {
      "city": "Cromwell",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cromwell, Kentucky"
    },
    {
      "city": "Dundee",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dundee, Kentucky"
    },
    {
      "city": "Fordsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fordsville, Kentucky"
    },
    {
      "city": "Hartford",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hartford, Kentucky"
    },
    {
      "city": "Horse Branch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Horse Branch, Kentucky"
    },
    {
      "city": "Mc Henry",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Henry, Kentucky"
    },
    {
      "city": "Olaton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Olaton, Kentucky"
    },
    {
      "city": "Rockport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rockport, Kentucky"
    },
    {
      "city": "Rosine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rosine, Kentucky"
    },
    {
      "city": "Buckner",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Buckner, Kentucky"
    },
    {
      "city": "Crestwood",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Crestwood, Kentucky"
    },
    {
      "city": "Goshen",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Goshen, Kentucky"
    },
    {
      "city": "La Grange",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "La Grange, Kentucky"
    },
    {
      "city": "Pewee Valley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pewee Valley, Kentucky"
    },
    {
      "city": "Westport",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Westport, Kentucky"
    },
    {
      "city": "New Liberty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "New Liberty, Kentucky"
    },
    {
      "city": "Owenton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Owenton, Kentucky"
    },
    {
      "city": "Perry Park",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Perry Park, Kentucky"
    },
    {
      "city": "Booneville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Booneville, Kentucky"
    },
    {
      "city": "Mistletoe",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mistletoe, Kentucky"
    },
    {
      "city": "Ricetown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ricetown, Kentucky"
    },
    {
      "city": "Vincent",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vincent, Kentucky"
    },
    {
      "city": "Butler",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Butler, Kentucky"
    },
    {
      "city": "De Mossville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "De Mossville, Kentucky"
    },
    {
      "city": "Falmouth",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Falmouth, Kentucky"
    },
    {
      "city": "Saul",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saul, Kentucky"
    },
    {
      "city": "Rowdy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rowdy, Kentucky"
    },
    {
      "city": "Hazard",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hazard, Kentucky"
    },
    {
      "city": "Ary",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ary, Kentucky"
    },
    {
      "city": "Avawam",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Avawam, Kentucky"
    },
    {
      "city": "Bonnyman",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bonnyman, Kentucky"
    },
    {
      "city": "Buckhorn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Buckhorn, Kentucky"
    },
    {
      "city": "Bulan",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bulan, Kentucky"
    },
    {
      "city": "Busy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Busy, Kentucky"
    },
    {
      "city": "Chavies",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Chavies, Kentucky"
    },
    {
      "city": "Combs",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Combs, Kentucky"
    },
    {
      "city": "Cornettsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cornettsville, Kentucky"
    },
    {
      "city": "Delphia",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Delphia, Kentucky"
    },
    {
      "city": "Dice",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dice, Kentucky"
    },
    {
      "city": "Dwarf",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dwarf, Kentucky"
    },
    {
      "city": "Gays Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Gays Creek, Kentucky"
    },
    {
      "city": "Happy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Happy, Kentucky"
    },
    {
      "city": "Jeff",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jeff, Kentucky"
    },
    {
      "city": "Krypton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Krypton, Kentucky"
    },
    {
      "city": "Scuddy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Scuddy, Kentucky"
    },
    {
      "city": "Slemp",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Slemp, Kentucky"
    },
    {
      "city": "Vicco",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Vicco, Kentucky"
    },
    {
      "city": "Viper",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Viper, Kentucky"
    },
    {
      "city": "Yerkes",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Yerkes, Kentucky"
    },
    {
      "city": "Pikeville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pikeville, Kentucky"
    },
    {
      "city": "South Williamson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "South Williamson, Kentucky"
    },
    {
      "city": "Ashcamp",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ashcamp, Kentucky"
    },
    {
      "city": "Belcher",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Belcher, Kentucky"
    },
    {
      "city": "Belfry",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Belfry, Kentucky"
    },
    {
      "city": "Canada",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Canada, Kentucky"
    },
    {
      "city": "Dorton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dorton, Kentucky"
    },
    {
      "city": "Elkhorn City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elkhorn City, Kentucky"
    },
    {
      "city": "Fedscreek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fedscreek, Kentucky"
    },
    {
      "city": "Fords Branch",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Fords Branch, Kentucky"
    },
    {
      "city": "Forest Hills",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Forest Hills, Kentucky"
    },
    {
      "city": "Freeburn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Freeburn, Kentucky"
    },
    {
      "city": "Hardy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hardy, Kentucky"
    },
    {
      "city": "Hellier",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hellier, Kentucky"
    },
    {
      "city": "Huddy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Huddy, Kentucky"
    },
    {
      "city": "Jonancy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jonancy, Kentucky"
    },
    {
      "city": "Kimper",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Kimper, Kentucky"
    },
    {
      "city": "Lick Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lick Creek, Kentucky"
    },
    {
      "city": "Lookout",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Lookout, Kentucky"
    },
    {
      "city": "Mc Andrews",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Andrews, Kentucky"
    },
    {
      "city": "Mc Carr",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mc Carr, Kentucky"
    },
    {
      "city": "Majestic",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Majestic, Kentucky"
    },
    {
      "city": "Mouthcard",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mouthcard, Kentucky"
    },
    {
      "city": "Myra",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Myra, Kentucky"
    },
    {
      "city": "Phelps",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Phelps, Kentucky"
    },
    {
      "city": "Phyllis",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Phyllis, Kentucky"
    },
    {
      "city": "Pinsonfork",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pinsonfork, Kentucky"
    },
    {
      "city": "Raccoon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Raccoon, Kentucky"
    },
    {
      "city": "Ransom",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ransom, Kentucky"
    },
    {
      "city": "Regina",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Regina, Kentucky"
    },
    {
      "city": "Robinson Creek",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Robinson Creek, Kentucky"
    },
    {
      "city": "Rockhouse",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rockhouse, Kentucky"
    },
    {
      "city": "Shelbiana",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Shelbiana, Kentucky"
    },
    {
      "city": "Shelby Gap",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Shelby Gap, Kentucky"
    },
    {
      "city": "Sidney",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sidney, Kentucky"
    },
    {
      "city": "Steele",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Steele, Kentucky"
    },
    {
      "city": "Stone",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stone, Kentucky"
    },
    {
      "city": "Stopover",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stopover, Kentucky"
    },
    {
      "city": "Varney",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Varney, Kentucky"
    },
    {
      "city": "Virgie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Virgie, Kentucky"
    },
    {
      "city": "Clay City",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clay City, Kentucky"
    },
    {
      "city": "Slade",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Slade, Kentucky"
    },
    {
      "city": "Stanton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stanton, Kentucky"
    },
    {
      "city": "Somerset",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Somerset, Kentucky"
    },
    {
      "city": "Bronston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bronston, Kentucky"
    },
    {
      "city": "Burnside",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Burnside, Kentucky"
    },
    {
      "city": "Ferguson",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Ferguson, Kentucky"
    },
    {
      "city": "Nancy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Nancy, Kentucky"
    },
    {
      "city": "Science Hill",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Science Hill, Kentucky"
    },
    {
      "city": "Tateville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Tateville, Kentucky"
    },
    {
      "city": "West Somerset",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "West Somerset, Kentucky"
    },
    {
      "city": "Eubank",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Eubank, Kentucky"
    },
    {
      "city": "Mount Olivet",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Olivet, Kentucky"
    },
    {
      "city": "Brodhead",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Brodhead, Kentucky"
    },
    {
      "city": "Livingston",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Livingston, Kentucky"
    },
    {
      "city": "Mount Vernon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Vernon, Kentucky"
    },
    {
      "city": "Orlando",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Orlando, Kentucky"
    },
    {
      "city": "Renfro Valley",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Renfro Valley, Kentucky"
    },
    {
      "city": "Wildie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wildie, Kentucky"
    },
    {
      "city": "Clearfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clearfield, Kentucky"
    },
    {
      "city": "Elliottville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elliottville, Kentucky"
    },
    {
      "city": "Farmers",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Farmers, Kentucky"
    },
    {
      "city": "Morehead",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Morehead, Kentucky"
    },
    {
      "city": "Jamestown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Jamestown, Kentucky"
    },
    {
      "city": "Russell Springs",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Russell Springs, Kentucky"
    },
    {
      "city": "Georgetown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Georgetown, Kentucky"
    },
    {
      "city": "Sadieville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sadieville, Kentucky"
    },
    {
      "city": "Stamping Ground",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Stamping Ground, Kentucky"
    },
    {
      "city": "Bagdad",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bagdad, Kentucky"
    },
    {
      "city": "Finchville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Finchville, Kentucky"
    },
    {
      "city": "Shelbyville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Shelbyville, Kentucky"
    },
    {
      "city": "Simpsonville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Simpsonville, Kentucky"
    },
    {
      "city": "Waddy",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Waddy, Kentucky"
    },
    {
      "city": "Franklin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Franklin, Kentucky"
    },
    {
      "city": "Mount Eden",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mount Eden, Kentucky"
    },
    {
      "city": "Taylorsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Taylorsville, Kentucky"
    },
    {
      "city": "Campbellsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Campbellsville, Kentucky"
    },
    {
      "city": "Elk Horn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elk Horn, Kentucky"
    },
    {
      "city": "Mannsville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mannsville, Kentucky"
    },
    {
      "city": "Allensville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Allensville, Kentucky"
    },
    {
      "city": "Clifty",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clifty, Kentucky"
    },
    {
      "city": "Elkton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Elkton, Kentucky"
    },
    {
      "city": "Guthrie",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Guthrie, Kentucky"
    },
    {
      "city": "Sharon Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sharon Grove, Kentucky"
    },
    {
      "city": "Trenton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Trenton, Kentucky"
    },
    {
      "city": "Cadiz",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cadiz, Kentucky"
    },
    {
      "city": "Cerulean",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Cerulean, Kentucky"
    },
    {
      "city": "Bedford",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bedford, Kentucky"
    },
    {
      "city": "Milton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Milton, Kentucky"
    },
    {
      "city": "Morganfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Morganfield, Kentucky"
    },
    {
      "city": "Sturgis",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sturgis, Kentucky"
    },
    {
      "city": "Sullivan",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sullivan, Kentucky"
    },
    {
      "city": "Uniontown",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Uniontown, Kentucky"
    },
    {
      "city": "Waverly",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Waverly, Kentucky"
    },
    {
      "city": "Bowling Green",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Bowling Green, Kentucky"
    },
    {
      "city": "Alvaton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Alvaton, Kentucky"
    },
    {
      "city": "Drake",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Drake, Kentucky"
    },
    {
      "city": "Oakland",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Oakland, Kentucky"
    },
    {
      "city": "Woodburn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Woodburn, Kentucky"
    },
    {
      "city": "Smiths Grove",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Smiths Grove, Kentucky"
    },
    {
      "city": "Rockfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rockfield, Kentucky"
    },
    {
      "city": "Mackville",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Mackville, Kentucky"
    },
    {
      "city": "Saint Catharine",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Saint Catharine, Kentucky"
    },
    {
      "city": "Springfield",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Springfield, Kentucky"
    },
    {
      "city": "Willisburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Willisburg, Kentucky"
    },
    {
      "city": "Monticello",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Monticello, Kentucky"
    },
    {
      "city": "Clay",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Clay, Kentucky"
    },
    {
      "city": "Dixon",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Dixon, Kentucky"
    },
    {
      "city": "Poole",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Poole, Kentucky"
    },
    {
      "city": "Providence",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Providence, Kentucky"
    },
    {
      "city": "Sebree",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Sebree, Kentucky"
    },
    {
      "city": "Slaughters",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Slaughters, Kentucky"
    },
    {
      "city": "Wheatcroft",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Wheatcroft, Kentucky"
    },
    {
      "city": "Corbin",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Corbin, Kentucky"
    },
    {
      "city": "Emlyn",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Emlyn, Kentucky"
    },
    {
      "city": "Rockholds",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rockholds, Kentucky"
    },
    {
      "city": "Siler",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Siler, Kentucky"
    },
    {
      "city": "Williamsburg",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Williamsburg, Kentucky"
    },
    {
      "city": "Campton",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Campton, Kentucky"
    },
    {
      "city": "Hazel Green",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Hazel Green, Kentucky"
    },
    {
      "city": "Pine Ridge",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Pine Ridge, Kentucky"
    },
    {
      "city": "Rogers",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Rogers, Kentucky"
    },
    {
      "city": "Midway",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Midway, Kentucky"
    },
    {
      "city": "Versailles",
      "state": "Kentucky",
      "shortState": "KY",
      "formatedCity": "Versailles, Kentucky"
    },
    {
      "city": "Branch",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Branch, Louisiana"
    },
    {
      "city": "Church Point",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Church Point, Louisiana"
    },
    {
      "city": "Crowley",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Crowley, Louisiana"
    },
    {
      "city": "Egan",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Egan, Louisiana"
    },
    {
      "city": "Estherwood",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Estherwood, Louisiana"
    },
    {
      "city": "Evangeline",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Evangeline, Louisiana"
    },
    {
      "city": "Iota",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Iota, Louisiana"
    },
    {
      "city": "Mermentau",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mermentau, Louisiana"
    },
    {
      "city": "Morse",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Morse, Louisiana"
    },
    {
      "city": "Rayne",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rayne, Louisiana"
    },
    {
      "city": "Elizabeth",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Elizabeth, Louisiana"
    },
    {
      "city": "Grant",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grant, Louisiana"
    },
    {
      "city": "Kinder",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kinder, Louisiana"
    },
    {
      "city": "Leblanc",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Leblanc, Louisiana"
    },
    {
      "city": "Mittie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mittie, Louisiana"
    },
    {
      "city": "Oberlin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oberlin, Louisiana"
    },
    {
      "city": "Reeves",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Reeves, Louisiana"
    },
    {
      "city": "Oakdale",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oakdale, Louisiana"
    },
    {
      "city": "Donaldsonville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Donaldsonville, Louisiana"
    },
    {
      "city": "Gonzales",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gonzales, Louisiana"
    },
    {
      "city": "Brittany",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Brittany, Louisiana"
    },
    {
      "city": "Darrow",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Darrow, Louisiana"
    },
    {
      "city": "Duplessis",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Duplessis, Louisiana"
    },
    {
      "city": "Geismar",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Geismar, Louisiana"
    },
    {
      "city": "Burnside",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Burnside, Louisiana"
    },
    {
      "city": "Prairieville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Prairieville, Louisiana"
    },
    {
      "city": "Saint Amant",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Amant, Louisiana"
    },
    {
      "city": "Sorrento",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sorrento, Louisiana"
    },
    {
      "city": "Pierre Part",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pierre Part, Louisiana"
    },
    {
      "city": "Belle Rose",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Belle Rose, Louisiana"
    },
    {
      "city": "Labadieville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Labadieville, Louisiana"
    },
    {
      "city": "Napoleonville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Napoleonville, Louisiana"
    },
    {
      "city": "Paincourtville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Paincourtville, Louisiana"
    },
    {
      "city": "Plattenville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Plattenville, Louisiana"
    },
    {
      "city": "Bordelonville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bordelonville, Louisiana"
    },
    {
      "city": "Bunkie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bunkie, Louisiana"
    },
    {
      "city": "Center Point",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Center Point, Louisiana"
    },
    {
      "city": "Cottonport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cottonport, Louisiana"
    },
    {
      "city": "Dupont",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dupont, Louisiana"
    },
    {
      "city": "Effie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Effie, Louisiana"
    },
    {
      "city": "Evergreen",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Evergreen, Louisiana"
    },
    {
      "city": "Hamburg",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hamburg, Louisiana"
    },
    {
      "city": "Hessmer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hessmer, Louisiana"
    },
    {
      "city": "Mansura",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mansura, Louisiana"
    },
    {
      "city": "Marksville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Marksville, Louisiana"
    },
    {
      "city": "Moreauville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Moreauville, Louisiana"
    },
    {
      "city": "Plaucheville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Plaucheville, Louisiana"
    },
    {
      "city": "Simmesport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Simmesport, Louisiana"
    },
    {
      "city": "Deridder",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Deridder, Louisiana"
    },
    {
      "city": "Dry Creek",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dry Creek, Louisiana"
    },
    {
      "city": "Longville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Longville, Louisiana"
    },
    {
      "city": "Merryville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Merryville, Louisiana"
    },
    {
      "city": "Ragley",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ragley, Louisiana"
    },
    {
      "city": "Singer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Singer, Louisiana"
    },
    {
      "city": "Sugartown",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sugartown, Louisiana"
    },
    {
      "city": "Arcadia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Arcadia, Louisiana"
    },
    {
      "city": "Bienville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bienville, Louisiana"
    },
    {
      "city": "Castor",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Castor, Louisiana"
    },
    {
      "city": "Gibsland",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gibsland, Louisiana"
    },
    {
      "city": "Jamestown",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jamestown, Louisiana"
    },
    {
      "city": "Ringgold",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ringgold, Louisiana"
    },
    {
      "city": "Saline",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saline, Louisiana"
    },
    {
      "city": "Taylor",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Taylor, Louisiana"
    },
    {
      "city": "Benton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Benton, Louisiana"
    },
    {
      "city": "Haughton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Haughton, Louisiana"
    },
    {
      "city": "Elm Grove",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Elm Grove, Louisiana"
    },
    {
      "city": "Plain Dealing",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Plain Dealing, Louisiana"
    },
    {
      "city": "Princeton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Princeton, Louisiana"
    },
    {
      "city": "Barksdale Afb",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Barksdale Afb, Louisiana"
    },
    {
      "city": "Bossier City",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bossier City, Louisiana"
    },
    {
      "city": "Belcher",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Belcher, Louisiana"
    },
    {
      "city": "Bethany",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bethany, Louisiana"
    },
    {
      "city": "Blanchard",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Blanchard, Louisiana"
    },
    {
      "city": "Gilliam",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gilliam, Louisiana"
    },
    {
      "city": "Greenwood",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Greenwood, Louisiana"
    },
    {
      "city": "Hosston",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hosston, Louisiana"
    },
    {
      "city": "Ida",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ida, Louisiana"
    },
    {
      "city": "Keithville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Keithville, Louisiana"
    },
    {
      "city": "Mooringsport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mooringsport, Louisiana"
    },
    {
      "city": "Oil City",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oil City, Louisiana"
    },
    {
      "city": "Rodessa",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rodessa, Louisiana"
    },
    {
      "city": "Vivian",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Vivian, Louisiana"
    },
    {
      "city": "Shreveport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Shreveport, Louisiana"
    },
    {
      "city": "Lake Charles",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lake Charles, Louisiana"
    },
    {
      "city": "Bell City",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bell City, Louisiana"
    },
    {
      "city": "Dequincy",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dequincy, Louisiana"
    },
    {
      "city": "Hayes",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hayes, Louisiana"
    },
    {
      "city": "Iowa",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Iowa, Louisiana"
    },
    {
      "city": "Starks",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Starks, Louisiana"
    },
    {
      "city": "Sulphur",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sulphur, Louisiana"
    },
    {
      "city": "Vinton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Vinton, Louisiana"
    },
    {
      "city": "Westlake",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Westlake, Louisiana"
    },
    {
      "city": "Clarks",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Clarks, Louisiana"
    },
    {
      "city": "Columbia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Columbia, Louisiana"
    },
    {
      "city": "Grayson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grayson, Louisiana"
    },
    {
      "city": "Kelly",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kelly, Louisiana"
    },
    {
      "city": "Cameron",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cameron, Louisiana"
    },
    {
      "city": "Creole",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Creole, Louisiana"
    },
    {
      "city": "Grand Chenier",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grand Chenier, Louisiana"
    },
    {
      "city": "Hackberry",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hackberry, Louisiana"
    },
    {
      "city": "Harrisonburg",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Harrisonburg, Louisiana"
    },
    {
      "city": "Jonesville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jonesville, Louisiana"
    },
    {
      "city": "Rhinehart",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rhinehart, Louisiana"
    },
    {
      "city": "Sicily Island",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sicily Island, Louisiana"
    },
    {
      "city": "Aimwell",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Aimwell, Louisiana"
    },
    {
      "city": "Enterprise",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Enterprise, Louisiana"
    },
    {
      "city": "Athens",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Athens, Louisiana"
    },
    {
      "city": "Haynesville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Haynesville, Louisiana"
    },
    {
      "city": "Homer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Homer, Louisiana"
    },
    {
      "city": "Lisbon",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lisbon, Louisiana"
    },
    {
      "city": "Summerfield",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Summerfield, Louisiana"
    },
    {
      "city": "Acme",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Acme, Louisiana"
    },
    {
      "city": "Clayton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Clayton, Louisiana"
    },
    {
      "city": "Ferriday",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ferriday, Louisiana"
    },
    {
      "city": "Monterey",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Monterey, Louisiana"
    },
    {
      "city": "Vidalia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Vidalia, Louisiana"
    },
    {
      "city": "Wildsville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Wildsville, Louisiana"
    },
    {
      "city": "Frierson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Frierson, Louisiana"
    },
    {
      "city": "Gloster",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gloster, Louisiana"
    },
    {
      "city": "Grand Cane",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grand Cane, Louisiana"
    },
    {
      "city": "Keatchie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Keatchie, Louisiana"
    },
    {
      "city": "Logansport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Logansport, Louisiana"
    },
    {
      "city": "Longstreet",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Longstreet, Louisiana"
    },
    {
      "city": "Mansfield",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mansfield, Louisiana"
    },
    {
      "city": "Pelican",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pelican, Louisiana"
    },
    {
      "city": "Stonewall",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Stonewall, Louisiana"
    },
    {
      "city": "Baker",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Baker, Louisiana"
    },
    {
      "city": "Greenwell Springs",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Greenwell Springs, Louisiana"
    },
    {
      "city": "Pride",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pride, Louisiana"
    },
    {
      "city": "Zachary",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Zachary, Louisiana"
    },
    {
      "city": "Baton Rouge",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Baton Rouge, Louisiana"
    },
    {
      "city": "Lake Providence",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lake Providence, Louisiana"
    },
    {
      "city": "Sondheimer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sondheimer, Louisiana"
    },
    {
      "city": "Transylvania",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Transylvania, Louisiana"
    },
    {
      "city": "Clinton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Clinton, Louisiana"
    },
    {
      "city": "Ethel",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ethel, Louisiana"
    },
    {
      "city": "Jackson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jackson, Louisiana"
    },
    {
      "city": "Norwood",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Norwood, Louisiana"
    },
    {
      "city": "Slaughter",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Slaughter, Louisiana"
    },
    {
      "city": "Wilson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Wilson, Louisiana"
    },
    {
      "city": "Basile",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Basile, Louisiana"
    },
    {
      "city": "Chataignier",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Chataignier, Louisiana"
    },
    {
      "city": "Mamou",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mamou, Louisiana"
    },
    {
      "city": "Pine Prairie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pine Prairie, Louisiana"
    },
    {
      "city": "Reddell",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Reddell, Louisiana"
    },
    {
      "city": "Turkey Creek",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Turkey Creek, Louisiana"
    },
    {
      "city": "Ville Platte",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ville Platte, Louisiana"
    },
    {
      "city": "Saint Landry",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Landry, Louisiana"
    },
    {
      "city": "Baskin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Baskin, Louisiana"
    },
    {
      "city": "Crowville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Crowville, Louisiana"
    },
    {
      "city": "Fort Necessity",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fort Necessity, Louisiana"
    },
    {
      "city": "Jigger",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jigger, Louisiana"
    },
    {
      "city": "Winnsboro",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Winnsboro, Louisiana"
    },
    {
      "city": "Chase",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Chase, Louisiana"
    },
    {
      "city": "Gilbert",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gilbert, Louisiana"
    },
    {
      "city": "Wisner",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Wisner, Louisiana"
    },
    {
      "city": "Bentley",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bentley, Louisiana"
    },
    {
      "city": "Colfax",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Colfax, Louisiana"
    },
    {
      "city": "Dry Prong",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dry Prong, Louisiana"
    },
    {
      "city": "Georgetown",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Georgetown, Louisiana"
    },
    {
      "city": "Montgomery",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Montgomery, Louisiana"
    },
    {
      "city": "Pollock",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pollock, Louisiana"
    },
    {
      "city": "Avery Island",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Avery Island, Louisiana"
    },
    {
      "city": "Jeanerette",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jeanerette, Louisiana"
    },
    {
      "city": "Loreauville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Loreauville, Louisiana"
    },
    {
      "city": "New Iberia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "New Iberia, Louisiana"
    },
    {
      "city": "Lydia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lydia, Louisiana"
    },
    {
      "city": "Carville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Carville, Louisiana"
    },
    {
      "city": "Grosse Tete",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grosse Tete, Louisiana"
    },
    {
      "city": "Maringouin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Maringouin, Louisiana"
    },
    {
      "city": "Plaquemine",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Plaquemine, Louisiana"
    },
    {
      "city": "Rosedale",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rosedale, Louisiana"
    },
    {
      "city": "Saint Gabriel",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Gabriel, Louisiana"
    },
    {
      "city": "Sunshine",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sunshine, Louisiana"
    },
    {
      "city": "White Castle",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "White Castle, Louisiana"
    },
    {
      "city": "Chatham",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Chatham, Louisiana"
    },
    {
      "city": "Eros",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Eros, Louisiana"
    },
    {
      "city": "Hodge",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hodge, Louisiana"
    },
    {
      "city": "Jonesboro",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jonesboro, Louisiana"
    },
    {
      "city": "Quitman",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Quitman, Louisiana"
    },
    {
      "city": "Metairie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Metairie, Louisiana"
    },
    {
      "city": "Barataria",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Barataria, Louisiana"
    },
    {
      "city": "Gretna",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gretna, Louisiana"
    },
    {
      "city": "Harvey",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Harvey, Louisiana"
    },
    {
      "city": "Kenner",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kenner, Louisiana"
    },
    {
      "city": "Lafitte",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lafitte, Louisiana"
    },
    {
      "city": "Marrero",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Marrero, Louisiana"
    },
    {
      "city": "Westwego",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Westwego, Louisiana"
    },
    {
      "city": "New Orleans",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "New Orleans, Louisiana"
    },
    {
      "city": "Grand Isle",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grand Isle, Louisiana"
    },
    {
      "city": "Elton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Elton, Louisiana"
    },
    {
      "city": "Jennings",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jennings, Louisiana"
    },
    {
      "city": "Lake Arthur",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lake Arthur, Louisiana"
    },
    {
      "city": "Roanoke",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Roanoke, Louisiana"
    },
    {
      "city": "Welsh",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Welsh, Louisiana"
    },
    {
      "city": "Fenton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fenton, Louisiana"
    },
    {
      "city": "Lacassine",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lacassine, Louisiana"
    },
    {
      "city": "Lafayette",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lafayette, Louisiana"
    },
    {
      "city": "Broussard",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Broussard, Louisiana"
    },
    {
      "city": "Carencro",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Carencro, Louisiana"
    },
    {
      "city": "Duson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Duson, Louisiana"
    },
    {
      "city": "Milton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Milton, Louisiana"
    },
    {
      "city": "Scott",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Scott, Louisiana"
    },
    {
      "city": "Youngsville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Youngsville, Louisiana"
    },
    {
      "city": "Thibodaux",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Thibodaux, Louisiana"
    },
    {
      "city": "Cut Off",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cut Off, Louisiana"
    },
    {
      "city": "Galliano",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Galliano, Louisiana"
    },
    {
      "city": "Gheens",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gheens, Louisiana"
    },
    {
      "city": "Golden Meadow",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Golden Meadow, Louisiana"
    },
    {
      "city": "Kraemer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kraemer, Louisiana"
    },
    {
      "city": "Larose",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Larose, Louisiana"
    },
    {
      "city": "Lockport",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lockport, Louisiana"
    },
    {
      "city": "Mathews",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mathews, Louisiana"
    },
    {
      "city": "Raceland",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Raceland, Louisiana"
    },
    {
      "city": "Jena",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jena, Louisiana"
    },
    {
      "city": "Trout",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Trout, Louisiana"
    },
    {
      "city": "Olla",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Olla, Louisiana"
    },
    {
      "city": "Tullos",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tullos, Louisiana"
    },
    {
      "city": "Urania",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Urania, Louisiana"
    },
    {
      "city": "Choudrant",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Choudrant, Louisiana"
    },
    {
      "city": "Dubach",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dubach, Louisiana"
    },
    {
      "city": "Grambling",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grambling, Louisiana"
    },
    {
      "city": "Ruston",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ruston, Louisiana"
    },
    {
      "city": "Simsboro",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Simsboro, Louisiana"
    },
    {
      "city": "Maurepas",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Maurepas, Louisiana"
    },
    {
      "city": "Springfield",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Springfield, Louisiana"
    },
    {
      "city": "Denham Springs",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Denham Springs, Louisiana"
    },
    {
      "city": "Albany",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Albany, Louisiana"
    },
    {
      "city": "French Settlement",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "French Settlement, Louisiana"
    },
    {
      "city": "Holden",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Holden, Louisiana"
    },
    {
      "city": "Livingston",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Livingston, Louisiana"
    },
    {
      "city": "Walker",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Walker, Louisiana"
    },
    {
      "city": "Watson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Watson, Louisiana"
    },
    {
      "city": "Delta",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Delta, Louisiana"
    },
    {
      "city": "Tallulah",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tallulah, Louisiana"
    },
    {
      "city": "Bastrop",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bastrop, Louisiana"
    },
    {
      "city": "Bonita",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bonita, Louisiana"
    },
    {
      "city": "Collinston",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Collinston, Louisiana"
    },
    {
      "city": "Jones",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jones, Louisiana"
    },
    {
      "city": "Mer Rouge",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mer Rouge, Louisiana"
    },
    {
      "city": "Oak Ridge",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oak Ridge, Louisiana"
    },
    {
      "city": "Ashland",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ashland, Louisiana"
    },
    {
      "city": "Goldonna",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Goldonna, Louisiana"
    },
    {
      "city": "Powhatan",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Powhatan, Louisiana"
    },
    {
      "city": "Campti",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Campti, Louisiana"
    },
    {
      "city": "Clarence",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Clarence, Louisiana"
    },
    {
      "city": "Cloutierville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cloutierville, Louisiana"
    },
    {
      "city": "Flora",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Flora, Louisiana"
    },
    {
      "city": "Gorum",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gorum, Louisiana"
    },
    {
      "city": "Marthaville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Marthaville, Louisiana"
    },
    {
      "city": "Melrose",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Melrose, Louisiana"
    },
    {
      "city": "Mora",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mora, Louisiana"
    },
    {
      "city": "Natchez",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Natchez, Louisiana"
    },
    {
      "city": "Natchitoches",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Natchitoches, Louisiana"
    },
    {
      "city": "Provencal",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Provencal, Louisiana"
    },
    {
      "city": "Robeline",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Robeline, Louisiana"
    },
    {
      "city": "Monroe",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Monroe, Louisiana"
    },
    {
      "city": "Calhoun",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Calhoun, Louisiana"
    },
    {
      "city": "Fairbanks",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fairbanks, Louisiana"
    },
    {
      "city": "Sterlington",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sterlington, Louisiana"
    },
    {
      "city": "Swartz",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Swartz, Louisiana"
    },
    {
      "city": "West Monroe",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "West Monroe, Louisiana"
    },
    {
      "city": "Belle Chasse",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Belle Chasse, Louisiana"
    },
    {
      "city": "Boothville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Boothville, Louisiana"
    },
    {
      "city": "Braithwaite",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Braithwaite, Louisiana"
    },
    {
      "city": "Buras",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Buras, Louisiana"
    },
    {
      "city": "Empire",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Empire, Louisiana"
    },
    {
      "city": "Pilottown",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pilottown, Louisiana"
    },
    {
      "city": "Pointe A La Hache",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pointe A La Hache, Louisiana"
    },
    {
      "city": "Port Sulphur",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Port Sulphur, Louisiana"
    },
    {
      "city": "Venice",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Venice, Louisiana"
    },
    {
      "city": "Batchelor",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Batchelor, Louisiana"
    },
    {
      "city": "Fordoche",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fordoche, Louisiana"
    },
    {
      "city": "Glynn",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Glynn, Louisiana"
    },
    {
      "city": "Innis",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Innis, Louisiana"
    },
    {
      "city": "Jarreau",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Jarreau, Louisiana"
    },
    {
      "city": "Lakeland",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lakeland, Louisiana"
    },
    {
      "city": "Lettsworth",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lettsworth, Louisiana"
    },
    {
      "city": "Livonia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Livonia, Louisiana"
    },
    {
      "city": "Lottie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lottie, Louisiana"
    },
    {
      "city": "Morganza",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Morganza, Louisiana"
    },
    {
      "city": "New Roads",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "New Roads, Louisiana"
    },
    {
      "city": "Oscar",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oscar, Louisiana"
    },
    {
      "city": "Rougon",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rougon, Louisiana"
    },
    {
      "city": "Ventress",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ventress, Louisiana"
    },
    {
      "city": "Alexandria",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Alexandria, Louisiana"
    },
    {
      "city": "Cheneyville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cheneyville, Louisiana"
    },
    {
      "city": "Deville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Deville, Louisiana"
    },
    {
      "city": "Echo",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Echo, Louisiana"
    },
    {
      "city": "Lecompte",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lecompte, Louisiana"
    },
    {
      "city": "Libuse",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Libuse, Louisiana"
    },
    {
      "city": "Pineville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pineville, Louisiana"
    },
    {
      "city": "Ruby",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ruby, Louisiana"
    },
    {
      "city": "Ball",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ball, Louisiana"
    },
    {
      "city": "Boyce",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Boyce, Louisiana"
    },
    {
      "city": "Elmer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Elmer, Louisiana"
    },
    {
      "city": "Flatwoods",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Flatwoods, Louisiana"
    },
    {
      "city": "Forest Hill",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Forest Hill, Louisiana"
    },
    {
      "city": "Gardner",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gardner, Louisiana"
    },
    {
      "city": "Glenmora",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Glenmora, Louisiana"
    },
    {
      "city": "Hineston",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hineston, Louisiana"
    },
    {
      "city": "Lena",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lena, Louisiana"
    },
    {
      "city": "Longleaf",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Longleaf, Louisiana"
    },
    {
      "city": "Otis",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Otis, Louisiana"
    },
    {
      "city": "Sieper",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sieper, Louisiana"
    },
    {
      "city": "Tioga",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tioga, Louisiana"
    },
    {
      "city": "Woodworth",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Woodworth, Louisiana"
    },
    {
      "city": "Coushatta",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Coushatta, Louisiana"
    },
    {
      "city": "Hall Summit",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hall Summit, Louisiana"
    },
    {
      "city": "Archibald",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Archibald, Louisiana"
    },
    {
      "city": "Delhi",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Delhi, Louisiana"
    },
    {
      "city": "Mangham",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mangham, Louisiana"
    },
    {
      "city": "Rayville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rayville, Louisiana"
    },
    {
      "city": "Start",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Start, Louisiana"
    },
    {
      "city": "Pleasant Hill",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pleasant Hill, Louisiana"
    },
    {
      "city": "Belmont",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Belmont, Louisiana"
    },
    {
      "city": "Converse",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Converse, Louisiana"
    },
    {
      "city": "Fisher",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fisher, Louisiana"
    },
    {
      "city": "Florien",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Florien, Louisiana"
    },
    {
      "city": "Many",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Many, Louisiana"
    },
    {
      "city": "Negreet",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Negreet, Louisiana"
    },
    {
      "city": "Noble",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Noble, Louisiana"
    },
    {
      "city": "Zwolle",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Zwolle, Louisiana"
    },
    {
      "city": "Arabi",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Arabi, Louisiana"
    },
    {
      "city": "Chalmette",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Chalmette, Louisiana"
    },
    {
      "city": "Meraux",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Meraux, Louisiana"
    },
    {
      "city": "Saint Bernard",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Bernard, Louisiana"
    },
    {
      "city": "Violet",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Violet, Louisiana"
    },
    {
      "city": "Des Allemands",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Des Allemands, Louisiana"
    },
    {
      "city": "Ama",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ama, Louisiana"
    },
    {
      "city": "Boutte",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Boutte, Louisiana"
    },
    {
      "city": "Destrehan",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Destrehan, Louisiana"
    },
    {
      "city": "Hahnville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hahnville, Louisiana"
    },
    {
      "city": "Luling",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Luling, Louisiana"
    },
    {
      "city": "New Sarpy",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "New Sarpy, Louisiana"
    },
    {
      "city": "Norco",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Norco, Louisiana"
    },
    {
      "city": "Paradis",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Paradis, Louisiana"
    },
    {
      "city": "Saint Rose",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Rose, Louisiana"
    },
    {
      "city": "Greensburg",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Greensburg, Louisiana"
    },
    {
      "city": "Pine Grove",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pine Grove, Louisiana"
    },
    {
      "city": "Gramercy",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gramercy, Louisiana"
    },
    {
      "city": "Lutcher",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lutcher, Louisiana"
    },
    {
      "city": "Saint James",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint James, Louisiana"
    },
    {
      "city": "Vacherie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Vacherie, Louisiana"
    },
    {
      "city": "Convent",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Convent, Louisiana"
    },
    {
      "city": "Hester",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hester, Louisiana"
    },
    {
      "city": "Paulina",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Paulina, Louisiana"
    },
    {
      "city": "Uncle Sam",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Uncle Sam, Louisiana"
    },
    {
      "city": "Edgard",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Edgard, Louisiana"
    },
    {
      "city": "Garyville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Garyville, Louisiana"
    },
    {
      "city": "La Place",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "La Place, Louisiana"
    },
    {
      "city": "Mount Airy",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mount Airy, Louisiana"
    },
    {
      "city": "Reserve",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Reserve, Louisiana"
    },
    {
      "city": "Arnaudville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Arnaudville, Louisiana"
    },
    {
      "city": "Eunice",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Eunice, Louisiana"
    },
    {
      "city": "Grand Coteau",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Grand Coteau, Louisiana"
    },
    {
      "city": "Lawtell",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lawtell, Louisiana"
    },
    {
      "city": "Leonville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Leonville, Louisiana"
    },
    {
      "city": "Opelousas",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Opelousas, Louisiana"
    },
    {
      "city": "Port Barre",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Port Barre, Louisiana"
    },
    {
      "city": "Sunset",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sunset, Louisiana"
    },
    {
      "city": "Washington",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Washington, Louisiana"
    },
    {
      "city": "Krotz Springs",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Krotz Springs, Louisiana"
    },
    {
      "city": "Lebeau",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lebeau, Louisiana"
    },
    {
      "city": "Melville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Melville, Louisiana"
    },
    {
      "city": "Morrow",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Morrow, Louisiana"
    },
    {
      "city": "Palmetto",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Palmetto, Louisiana"
    },
    {
      "city": "Breaux Bridge",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Breaux Bridge, Louisiana"
    },
    {
      "city": "Cade",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cade, Louisiana"
    },
    {
      "city": "Cecilia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cecilia, Louisiana"
    },
    {
      "city": "Saint Martinville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Martinville, Louisiana"
    },
    {
      "city": "Amelia",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Amelia, Louisiana"
    },
    {
      "city": "Berwick",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Berwick, Louisiana"
    },
    {
      "city": "Morgan City",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Morgan City, Louisiana"
    },
    {
      "city": "Patterson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Patterson, Louisiana"
    },
    {
      "city": "Baldwin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Baldwin, Louisiana"
    },
    {
      "city": "Centerville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Centerville, Louisiana"
    },
    {
      "city": "Charenton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Charenton, Louisiana"
    },
    {
      "city": "Franklin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Franklin, Louisiana"
    },
    {
      "city": "Garden City",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Garden City, Louisiana"
    },
    {
      "city": "Abita Springs",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Abita Springs, Louisiana"
    },
    {
      "city": "Bush",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bush, Louisiana"
    },
    {
      "city": "Covington",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Covington, Louisiana"
    },
    {
      "city": "Folsom",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Folsom, Louisiana"
    },
    {
      "city": "Lacombe",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lacombe, Louisiana"
    },
    {
      "city": "Madisonville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Madisonville, Louisiana"
    },
    {
      "city": "Mandeville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mandeville, Louisiana"
    },
    {
      "city": "Pearl River",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pearl River, Louisiana"
    },
    {
      "city": "Saint Benedict",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Benedict, Louisiana"
    },
    {
      "city": "Slidell",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Slidell, Louisiana"
    },
    {
      "city": "Sun",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sun, Louisiana"
    },
    {
      "city": "Talisheek",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Talisheek, Louisiana"
    },
    {
      "city": "Hammond",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hammond, Louisiana"
    },
    {
      "city": "Akers",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Akers, Louisiana"
    },
    {
      "city": "Amite",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Amite, Louisiana"
    },
    {
      "city": "Fluker",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fluker, Louisiana"
    },
    {
      "city": "Husser",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Husser, Louisiana"
    },
    {
      "city": "Independence",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Independence, Louisiana"
    },
    {
      "city": "Kentwood",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kentwood, Louisiana"
    },
    {
      "city": "Loranger",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Loranger, Louisiana"
    },
    {
      "city": "Natalbany",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Natalbany, Louisiana"
    },
    {
      "city": "Ponchatoula",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Ponchatoula, Louisiana"
    },
    {
      "city": "Robert",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Robert, Louisiana"
    },
    {
      "city": "Roseland",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Roseland, Louisiana"
    },
    {
      "city": "Tangipahoa",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tangipahoa, Louisiana"
    },
    {
      "city": "Tickfaw",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tickfaw, Louisiana"
    },
    {
      "city": "Newellton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Newellton, Louisiana"
    },
    {
      "city": "Saint Joseph",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Joseph, Louisiana"
    },
    {
      "city": "Waterproof",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Waterproof, Louisiana"
    },
    {
      "city": "Bourg",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bourg, Louisiana"
    },
    {
      "city": "Chauvin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Chauvin, Louisiana"
    },
    {
      "city": "Donner",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Donner, Louisiana"
    },
    {
      "city": "Dulac",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dulac, Louisiana"
    },
    {
      "city": "Gibson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gibson, Louisiana"
    },
    {
      "city": "Gray",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gray, Louisiana"
    },
    {
      "city": "Houma",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Houma, Louisiana"
    },
    {
      "city": "Montegut",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Montegut, Louisiana"
    },
    {
      "city": "Schriever",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Schriever, Louisiana"
    },
    {
      "city": "Theriot",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Theriot, Louisiana"
    },
    {
      "city": "Bernice",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bernice, Louisiana"
    },
    {
      "city": "Downsville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Downsville, Louisiana"
    },
    {
      "city": "Farmerville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Farmerville, Louisiana"
    },
    {
      "city": "Lillie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Lillie, Louisiana"
    },
    {
      "city": "Marion",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Marion, Louisiana"
    },
    {
      "city": "Spearsville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Spearsville, Louisiana"
    },
    {
      "city": "Abbeville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Abbeville, Louisiana"
    },
    {
      "city": "Delcambre",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Delcambre, Louisiana"
    },
    {
      "city": "Erath",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Erath, Louisiana"
    },
    {
      "city": "Gueydan",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Gueydan, Louisiana"
    },
    {
      "city": "Kaplan",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kaplan, Louisiana"
    },
    {
      "city": "Maurice",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Maurice, Louisiana"
    },
    {
      "city": "Perry",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Perry, Louisiana"
    },
    {
      "city": "Evans",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Evans, Louisiana"
    },
    {
      "city": "Pitkin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pitkin, Louisiana"
    },
    {
      "city": "Rosepine",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Rosepine, Louisiana"
    },
    {
      "city": "Anacoco",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Anacoco, Louisiana"
    },
    {
      "city": "Hornbeck",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Hornbeck, Louisiana"
    },
    {
      "city": "Kurthwood",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kurthwood, Louisiana"
    },
    {
      "city": "Leesville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Leesville, Louisiana"
    },
    {
      "city": "Fort Polk",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Fort Polk, Louisiana"
    },
    {
      "city": "New Llano",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "New Llano, Louisiana"
    },
    {
      "city": "Simpson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Simpson, Louisiana"
    },
    {
      "city": "Slagle",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Slagle, Louisiana"
    },
    {
      "city": "Angie",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Angie, Louisiana"
    },
    {
      "city": "Bogalusa",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Bogalusa, Louisiana"
    },
    {
      "city": "Franklinton",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Franklinton, Louisiana"
    },
    {
      "city": "Mount Hermon",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Mount Hermon, Louisiana"
    },
    {
      "city": "Varnado",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Varnado, Louisiana"
    },
    {
      "city": "Cotton Valley",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cotton Valley, Louisiana"
    },
    {
      "city": "Cullen",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Cullen, Louisiana"
    },
    {
      "city": "Doyline",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Doyline, Louisiana"
    },
    {
      "city": "Dubberly",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dubberly, Louisiana"
    },
    {
      "city": "Heflin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Heflin, Louisiana"
    },
    {
      "city": "Minden",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Minden, Louisiana"
    },
    {
      "city": "Sarepta",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sarepta, Louisiana"
    },
    {
      "city": "Shongaloo",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Shongaloo, Louisiana"
    },
    {
      "city": "Sibley",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sibley, Louisiana"
    },
    {
      "city": "Springhill",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Springhill, Louisiana"
    },
    {
      "city": "Addis",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Addis, Louisiana"
    },
    {
      "city": "Brusly",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Brusly, Louisiana"
    },
    {
      "city": "Erwinville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Erwinville, Louisiana"
    },
    {
      "city": "Port Allen",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Port Allen, Louisiana"
    },
    {
      "city": "Epps",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Epps, Louisiana"
    },
    {
      "city": "Forest",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Forest, Louisiana"
    },
    {
      "city": "Kilbourne",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Kilbourne, Louisiana"
    },
    {
      "city": "Oak Grove",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Oak Grove, Louisiana"
    },
    {
      "city": "Pioneer",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Pioneer, Louisiana"
    },
    {
      "city": "Angola",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Angola, Louisiana"
    },
    {
      "city": "Saint Francisville",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Francisville, Louisiana"
    },
    {
      "city": "Tunica",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Tunica, Louisiana"
    },
    {
      "city": "Wakefield",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Wakefield, Louisiana"
    },
    {
      "city": "Weyanoke",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Weyanoke, Louisiana"
    },
    {
      "city": "Atlanta",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Atlanta, Louisiana"
    },
    {
      "city": "Calvin",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Calvin, Louisiana"
    },
    {
      "city": "Dodson",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Dodson, Louisiana"
    },
    {
      "city": "Joyce",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Joyce, Louisiana"
    },
    {
      "city": "Saint Maurice",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Saint Maurice, Louisiana"
    },
    {
      "city": "Sikes",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Sikes, Louisiana"
    },
    {
      "city": "Winnfield",
      "state": "Louisiana",
      "shortState": "LA",
      "formatedCity": "Winnfield, Louisiana"
    },
    {
      "city": "Buzzards Bay",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Buzzards Bay, Massachusetts"
    },
    {
      "city": "Cataumet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cataumet, Massachusetts"
    },
    {
      "city": "East Falmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Falmouth, Massachusetts"
    },
    {
      "city": "East Sandwich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Sandwich, Massachusetts"
    },
    {
      "city": "Falmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Falmouth, Massachusetts"
    },
    {
      "city": "Woods Hole",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Woods Hole, Massachusetts"
    },
    {
      "city": "Monument Beach",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Monument Beach, Massachusetts"
    },
    {
      "city": "North Falmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Falmouth, Massachusetts"
    },
    {
      "city": "Pocasset",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Pocasset, Massachusetts"
    },
    {
      "city": "Sagamore",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sagamore, Massachusetts"
    },
    {
      "city": "Sagamore Beach",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sagamore Beach, Massachusetts"
    },
    {
      "city": "Sandwich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sandwich, Massachusetts"
    },
    {
      "city": "West Falmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Falmouth, Massachusetts"
    },
    {
      "city": "Hyannis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hyannis, Massachusetts"
    },
    {
      "city": "Barnstable",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Barnstable, Massachusetts"
    },
    {
      "city": "Brewster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brewster, Massachusetts"
    },
    {
      "city": "Centerville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Centerville, Massachusetts"
    },
    {
      "city": "Chatham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chatham, Massachusetts"
    },
    {
      "city": "Cotuit",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cotuit, Massachusetts"
    },
    {
      "city": "Cummaquid",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cummaquid, Massachusetts"
    },
    {
      "city": "Dennis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dennis, Massachusetts"
    },
    {
      "city": "Dennis Port",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dennis Port, Massachusetts"
    },
    {
      "city": "East Dennis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Dennis, Massachusetts"
    },
    {
      "city": "Eastham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Eastham, Massachusetts"
    },
    {
      "city": "East Orleans",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Orleans, Massachusetts"
    },
    {
      "city": "Forestdale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Forestdale, Massachusetts"
    },
    {
      "city": "Harwich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Harwich, Massachusetts"
    },
    {
      "city": "Harwich Port",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Harwich Port, Massachusetts"
    },
    {
      "city": "Hyannis Port",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hyannis Port, Massachusetts"
    },
    {
      "city": "Marstons Mills",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marstons Mills, Massachusetts"
    },
    {
      "city": "Mashpee",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mashpee, Massachusetts"
    },
    {
      "city": "North Chatham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Chatham, Massachusetts"
    },
    {
      "city": "North Eastham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Eastham, Massachusetts"
    },
    {
      "city": "North Truro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Truro, Massachusetts"
    },
    {
      "city": "Orleans",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Orleans, Massachusetts"
    },
    {
      "city": "Osterville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Osterville, Massachusetts"
    },
    {
      "city": "Provincetown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Provincetown, Massachusetts"
    },
    {
      "city": "South Chatham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Chatham, Massachusetts"
    },
    {
      "city": "South Dennis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Dennis, Massachusetts"
    },
    {
      "city": "South Harwich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Harwich, Massachusetts"
    },
    {
      "city": "South Orleans",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Orleans, Massachusetts"
    },
    {
      "city": "South Wellfleet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Wellfleet, Massachusetts"
    },
    {
      "city": "South Yarmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Yarmouth, Massachusetts"
    },
    {
      "city": "Truro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Truro, Massachusetts"
    },
    {
      "city": "Wellfleet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wellfleet, Massachusetts"
    },
    {
      "city": "West Barnstable",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Barnstable, Massachusetts"
    },
    {
      "city": "West Chatham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Chatham, Massachusetts"
    },
    {
      "city": "West Dennis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Dennis, Massachusetts"
    },
    {
      "city": "West Harwich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Harwich, Massachusetts"
    },
    {
      "city": "West Hyannisport",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Hyannisport, Massachusetts"
    },
    {
      "city": "West Yarmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Yarmouth, Massachusetts"
    },
    {
      "city": "Yarmouth Port",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Yarmouth Port, Massachusetts"
    },
    {
      "city": "East Otis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Otis, Massachusetts"
    },
    {
      "city": "Pittsfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Pittsfield, Massachusetts"
    },
    {
      "city": "Adams",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Adams, Massachusetts"
    },
    {
      "city": "Ashley Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ashley Falls, Massachusetts"
    },
    {
      "city": "Becket",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Becket, Massachusetts"
    },
    {
      "city": "Berkshire",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Berkshire, Massachusetts"
    },
    {
      "city": "Cheshire",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cheshire, Massachusetts"
    },
    {
      "city": "Dalton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dalton, Massachusetts"
    },
    {
      "city": "Glendale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Glendale, Massachusetts"
    },
    {
      "city": "Great Barrington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Great Barrington, Massachusetts"
    },
    {
      "city": "Hinsdale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hinsdale, Massachusetts"
    },
    {
      "city": "Housatonic",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Housatonic, Massachusetts"
    },
    {
      "city": "Lanesborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lanesborough, Massachusetts"
    },
    {
      "city": "Lee",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lee, Massachusetts"
    },
    {
      "city": "Lenox",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lenox, Massachusetts"
    },
    {
      "city": "Lenox Dale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lenox Dale, Massachusetts"
    },
    {
      "city": "Mill River",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mill River, Massachusetts"
    },
    {
      "city": "Monterey",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Monterey, Massachusetts"
    },
    {
      "city": "North Adams",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Adams, Massachusetts"
    },
    {
      "city": "North Egremont",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Egremont, Massachusetts"
    },
    {
      "city": "Otis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Otis, Massachusetts"
    },
    {
      "city": "Richmond",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Richmond, Massachusetts"
    },
    {
      "city": "Sandisfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sandisfield, Massachusetts"
    },
    {
      "city": "Savoy",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Savoy, Massachusetts"
    },
    {
      "city": "Sheffield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sheffield, Massachusetts"
    },
    {
      "city": "South Egremont",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Egremont, Massachusetts"
    },
    {
      "city": "Southfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Southfield, Massachusetts"
    },
    {
      "city": "South Lee",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Lee, Massachusetts"
    },
    {
      "city": "Stockbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Stockbridge, Massachusetts"
    },
    {
      "city": "Tyringham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Tyringham, Massachusetts"
    },
    {
      "city": "West Stockbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Stockbridge, Massachusetts"
    },
    {
      "city": "Williamstown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Williamstown, Massachusetts"
    },
    {
      "city": "Windsor",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Windsor, Massachusetts"
    },
    {
      "city": "Drury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Drury, Massachusetts"
    },
    {
      "city": "Mansfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mansfield, Massachusetts"
    },
    {
      "city": "Easton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Easton, Massachusetts"
    },
    {
      "city": "North Easton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Easton, Massachusetts"
    },
    {
      "city": "South Easton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Easton, Massachusetts"
    },
    {
      "city": "Assonet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Assonet, Massachusetts"
    },
    {
      "city": "Attleboro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Attleboro, Massachusetts"
    },
    {
      "city": "Chartley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chartley, Massachusetts"
    },
    {
      "city": "Dartmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dartmouth, Massachusetts"
    },
    {
      "city": "Dighton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dighton, Massachusetts"
    },
    {
      "city": "East Freetown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Freetown, Massachusetts"
    },
    {
      "city": "East Taunton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Taunton, Massachusetts"
    },
    {
      "city": "Fairhaven",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Fairhaven, Massachusetts"
    },
    {
      "city": "Fall River",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Fall River, Massachusetts"
    },
    {
      "city": "Somerset",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Somerset, Massachusetts"
    },
    {
      "city": "New Bedford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "New Bedford, Massachusetts"
    },
    {
      "city": "Acushnet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Acushnet, Massachusetts"
    },
    {
      "city": "North Dartmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Dartmouth, Massachusetts"
    },
    {
      "city": "South Dartmouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Dartmouth, Massachusetts"
    },
    {
      "city": "North Attleboro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Attleboro, Massachusetts"
    },
    {
      "city": "Attleboro Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Attleboro Falls, Massachusetts"
    },
    {
      "city": "North Dighton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Dighton, Massachusetts"
    },
    {
      "city": "Norton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Norton, Massachusetts"
    },
    {
      "city": "Raynham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Raynham, Massachusetts"
    },
    {
      "city": "Raynham Center",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Raynham Center, Massachusetts"
    },
    {
      "city": "Rehoboth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rehoboth, Massachusetts"
    },
    {
      "city": "Seekonk",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Seekonk, Massachusetts"
    },
    {
      "city": "Swansea",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Swansea, Massachusetts"
    },
    {
      "city": "Berkley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Berkley, Massachusetts"
    },
    {
      "city": "Taunton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Taunton, Massachusetts"
    },
    {
      "city": "Westport",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westport, Massachusetts"
    },
    {
      "city": "Westport Point",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westport Point, Massachusetts"
    },
    {
      "city": "Chilmark",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chilmark, Massachusetts"
    },
    {
      "city": "Edgartown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Edgartown, Massachusetts"
    },
    {
      "city": "Menemsha",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Menemsha, Massachusetts"
    },
    {
      "city": "Oak Bluffs",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Oak Bluffs, Massachusetts"
    },
    {
      "city": "Vineyard Haven",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Vineyard Haven, Massachusetts"
    },
    {
      "city": "West Tisbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Tisbury, Massachusetts"
    },
    {
      "city": "Cuttyhunk",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cuttyhunk, Massachusetts"
    },
    {
      "city": "Andover",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Andover, Massachusetts"
    },
    {
      "city": "Haverhill",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Haverhill, Massachusetts"
    },
    {
      "city": "Georgetown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Georgetown, Massachusetts"
    },
    {
      "city": "Groveland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Groveland, Massachusetts"
    },
    {
      "city": "Lawrence",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lawrence, Massachusetts"
    },
    {
      "city": "Methuen",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Methuen, Massachusetts"
    },
    {
      "city": "North Andover",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Andover, Massachusetts"
    },
    {
      "city": "Merrimac",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Merrimac, Massachusetts"
    },
    {
      "city": "West Boxford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Boxford, Massachusetts"
    },
    {
      "city": "Lynn",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lynn, Massachusetts"
    },
    {
      "city": "Saugus",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Saugus, Massachusetts"
    },
    {
      "city": "Swampscott",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Swampscott, Massachusetts"
    },
    {
      "city": "Nahant",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Nahant, Massachusetts"
    },
    {
      "city": "Amesbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Amesbury, Massachusetts"
    },
    {
      "city": "Beverly",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Beverly, Massachusetts"
    },
    {
      "city": "Boxford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Boxford, Massachusetts"
    },
    {
      "city": "Byfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Byfield, Massachusetts"
    },
    {
      "city": "Danvers",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Danvers, Massachusetts"
    },
    {
      "city": "Essex",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Essex, Massachusetts"
    },
    {
      "city": "Gloucester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Gloucester, Massachusetts"
    },
    {
      "city": "Hamilton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hamilton, Massachusetts"
    },
    {
      "city": "Hathorne",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hathorne, Massachusetts"
    },
    {
      "city": "Ipswich",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ipswich, Massachusetts"
    },
    {
      "city": "Lynnfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lynnfield, Massachusetts"
    },
    {
      "city": "Manchester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Manchester, Massachusetts"
    },
    {
      "city": "Marblehead",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marblehead, Massachusetts"
    },
    {
      "city": "Middleton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Middleton, Massachusetts"
    },
    {
      "city": "Newburyport",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newburyport, Massachusetts"
    },
    {
      "city": "Newbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newbury, Massachusetts"
    },
    {
      "city": "Salisbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Salisbury, Massachusetts"
    },
    {
      "city": "Peabody",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Peabody, Massachusetts"
    },
    {
      "city": "Prides Crossing",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Prides Crossing, Massachusetts"
    },
    {
      "city": "Rockport",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rockport, Massachusetts"
    },
    {
      "city": "Rowley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rowley, Massachusetts"
    },
    {
      "city": "Salem",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Salem, Massachusetts"
    },
    {
      "city": "South Hamilton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Hamilton, Massachusetts"
    },
    {
      "city": "Topsfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Topsfield, Massachusetts"
    },
    {
      "city": "Wenham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wenham, Massachusetts"
    },
    {
      "city": "West Newbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Newbury, Massachusetts"
    },
    {
      "city": "Leverett",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Leverett, Massachusetts"
    },
    {
      "city": "Shutesbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Shutesbury, Massachusetts"
    },
    {
      "city": "Whately",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Whately, Massachusetts"
    },
    {
      "city": "Greenfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Greenfield, Massachusetts"
    },
    {
      "city": "Ashfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ashfield, Massachusetts"
    },
    {
      "city": "Bernardston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bernardston, Massachusetts"
    },
    {
      "city": "Buckland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Buckland, Massachusetts"
    },
    {
      "city": "Charlemont",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Charlemont, Massachusetts"
    },
    {
      "city": "Colrain",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Colrain, Massachusetts"
    },
    {
      "city": "Conway",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Conway, Massachusetts"
    },
    {
      "city": "Deerfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Deerfield, Massachusetts"
    },
    {
      "city": "Erving",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Erving, Massachusetts"
    },
    {
      "city": "Heath",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Heath, Massachusetts"
    },
    {
      "city": "Lake Pleasant",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lake Pleasant, Massachusetts"
    },
    {
      "city": "Millers Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Millers Falls, Massachusetts"
    },
    {
      "city": "Monroe Bridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Monroe Bridge, Massachusetts"
    },
    {
      "city": "Montague",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Montague, Massachusetts"
    },
    {
      "city": "Gill",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Gill, Massachusetts"
    },
    {
      "city": "New Salem",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "New Salem, Massachusetts"
    },
    {
      "city": "Northfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Northfield, Massachusetts"
    },
    {
      "city": "Orange",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Orange, Massachusetts"
    },
    {
      "city": "Rowe",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rowe, Massachusetts"
    },
    {
      "city": "Shelburne Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Shelburne Falls, Massachusetts"
    },
    {
      "city": "South Deerfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Deerfield, Massachusetts"
    },
    {
      "city": "Sunderland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sunderland, Massachusetts"
    },
    {
      "city": "Turners Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Turners Falls, Massachusetts"
    },
    {
      "city": "Warwick",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Warwick, Massachusetts"
    },
    {
      "city": "Wendell",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wendell, Massachusetts"
    },
    {
      "city": "Wendell Depot",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wendell Depot, Massachusetts"
    },
    {
      "city": "Agawam",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Agawam, Massachusetts"
    },
    {
      "city": "Blandford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Blandford, Massachusetts"
    },
    {
      "city": "Bondsville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bondsville, Massachusetts"
    },
    {
      "city": "Brimfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brimfield, Massachusetts"
    },
    {
      "city": "Chester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chester, Massachusetts"
    },
    {
      "city": "Chicopee",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chicopee, Massachusetts"
    },
    {
      "city": "East Longmeadow",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Longmeadow, Massachusetts"
    },
    {
      "city": "Feeding Hills",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Feeding Hills, Massachusetts"
    },
    {
      "city": "Granville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Granville, Massachusetts"
    },
    {
      "city": "Hampden",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hampden, Massachusetts"
    },
    {
      "city": "Holyoke",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Holyoke, Massachusetts"
    },
    {
      "city": "Ludlow",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ludlow, Massachusetts"
    },
    {
      "city": "Monson",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Monson, Massachusetts"
    },
    {
      "city": "Palmer",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Palmer, Massachusetts"
    },
    {
      "city": "Russell",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Russell, Massachusetts"
    },
    {
      "city": "Southwick",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Southwick, Massachusetts"
    },
    {
      "city": "Thorndike",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Thorndike, Massachusetts"
    },
    {
      "city": "Three Rivers",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Three Rivers, Massachusetts"
    },
    {
      "city": "Wales",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wales, Massachusetts"
    },
    {
      "city": "Westfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westfield, Massachusetts"
    },
    {
      "city": "West Springfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Springfield, Massachusetts"
    },
    {
      "city": "Wilbraham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wilbraham, Massachusetts"
    },
    {
      "city": "Woronoco",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Woronoco, Massachusetts"
    },
    {
      "city": "Springfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Springfield, Massachusetts"
    },
    {
      "city": "Longmeadow",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Longmeadow, Massachusetts"
    },
    {
      "city": "Indian Orchard",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Indian Orchard, Massachusetts"
    },
    {
      "city": "Holland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Holland, Massachusetts"
    },
    {
      "city": "Amherst",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Amherst, Massachusetts"
    },
    {
      "city": "Belchertown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Belchertown, Massachusetts"
    },
    {
      "city": "Chesterfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chesterfield, Massachusetts"
    },
    {
      "city": "Cummington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cummington, Massachusetts"
    },
    {
      "city": "Easthampton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Easthampton, Massachusetts"
    },
    {
      "city": "Goshen",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Goshen, Massachusetts"
    },
    {
      "city": "Granby",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Granby, Massachusetts"
    },
    {
      "city": "Hadley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hadley, Massachusetts"
    },
    {
      "city": "Hatfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hatfield, Massachusetts"
    },
    {
      "city": "Haydenville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Haydenville, Massachusetts"
    },
    {
      "city": "Huntington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Huntington, Massachusetts"
    },
    {
      "city": "Leeds",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Leeds, Massachusetts"
    },
    {
      "city": "North Amherst",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Amherst, Massachusetts"
    },
    {
      "city": "Northampton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Northampton, Massachusetts"
    },
    {
      "city": "Florence",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Florence, Massachusetts"
    },
    {
      "city": "North Hatfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Hatfield, Massachusetts"
    },
    {
      "city": "Plainfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Plainfield, Massachusetts"
    },
    {
      "city": "Southampton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Southampton, Massachusetts"
    },
    {
      "city": "South Hadley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Hadley, Massachusetts"
    },
    {
      "city": "Ware",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ware, Massachusetts"
    },
    {
      "city": "West Chesterfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Chesterfield, Massachusetts"
    },
    {
      "city": "West Hatfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Hatfield, Massachusetts"
    },
    {
      "city": "Williamsburg",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Williamsburg, Massachusetts"
    },
    {
      "city": "Worthington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Worthington, Massachusetts"
    },
    {
      "city": "Middlefield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Middlefield, Massachusetts"
    },
    {
      "city": "Ashby",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ashby, Massachusetts"
    },
    {
      "city": "Ayer",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ayer, Massachusetts"
    },
    {
      "city": "Groton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Groton, Massachusetts"
    },
    {
      "city": "Littleton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Littleton, Massachusetts"
    },
    {
      "city": "Pepperell",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Pepperell, Massachusetts"
    },
    {
      "city": "Shirley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Shirley, Massachusetts"
    },
    {
      "city": "Townsend",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Townsend, Massachusetts"
    },
    {
      "city": "West Groton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Groton, Massachusetts"
    },
    {
      "city": "West Townsend",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Townsend, Massachusetts"
    },
    {
      "city": "Framingham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Framingham, Massachusetts"
    },
    {
      "city": "Acton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Acton, Massachusetts"
    },
    {
      "city": "Boxborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Boxborough, Massachusetts"
    },
    {
      "city": "Ashland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ashland, Massachusetts"
    },
    {
      "city": "Bedford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bedford, Massachusetts"
    },
    {
      "city": "Hanscom Afb",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hanscom Afb, Massachusetts"
    },
    {
      "city": "Carlisle",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Carlisle, Massachusetts"
    },
    {
      "city": "Concord",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Concord, Massachusetts"
    },
    {
      "city": "Holliston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Holliston, Massachusetts"
    },
    {
      "city": "Hopkinton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hopkinton, Massachusetts"
    },
    {
      "city": "Hudson",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hudson, Massachusetts"
    },
    {
      "city": "Marlborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marlborough, Massachusetts"
    },
    {
      "city": "Maynard",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Maynard, Massachusetts"
    },
    {
      "city": "Natick",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Natick, Massachusetts"
    },
    {
      "city": "Sherborn",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sherborn, Massachusetts"
    },
    {
      "city": "Lincoln",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lincoln, Massachusetts"
    },
    {
      "city": "Stow",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Stow, Massachusetts"
    },
    {
      "city": "Sudbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sudbury, Massachusetts"
    },
    {
      "city": "Wayland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wayland, Massachusetts"
    },
    {
      "city": "Woodville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Woodville, Massachusetts"
    },
    {
      "city": "Woburn",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Woburn, Massachusetts"
    },
    {
      "city": "Burlington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Burlington, Massachusetts"
    },
    {
      "city": "Billerica",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Billerica, Massachusetts"
    },
    {
      "city": "Chelmsford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chelmsford, Massachusetts"
    },
    {
      "city": "Dracut",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dracut, Massachusetts"
    },
    {
      "city": "Dunstable",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dunstable, Massachusetts"
    },
    {
      "city": "Lowell",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lowell, Massachusetts"
    },
    {
      "city": "North Billerica",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Billerica, Massachusetts"
    },
    {
      "city": "North Chelmsford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Chelmsford, Massachusetts"
    },
    {
      "city": "North Reading",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Reading, Massachusetts"
    },
    {
      "city": "Nutting Lake",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Nutting Lake, Massachusetts"
    },
    {
      "city": "Pinehurst",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Pinehurst, Massachusetts"
    },
    {
      "city": "Reading",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Reading, Massachusetts"
    },
    {
      "city": "Tewksbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Tewksbury, Massachusetts"
    },
    {
      "city": "Tyngsboro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Tyngsboro, Massachusetts"
    },
    {
      "city": "Wakefield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wakefield, Massachusetts"
    },
    {
      "city": "Westford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westford, Massachusetts"
    },
    {
      "city": "Wilmington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wilmington, Massachusetts"
    },
    {
      "city": "Winchester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Winchester, Massachusetts"
    },
    {
      "city": "Cambridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cambridge, Massachusetts"
    },
    {
      "city": "Somerville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Somerville, Massachusetts"
    },
    {
      "city": "Malden",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Malden, Massachusetts"
    },
    {
      "city": "Everett",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Everett, Massachusetts"
    },
    {
      "city": "Medford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Medford, Massachusetts"
    },
    {
      "city": "West Medford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Medford, Massachusetts"
    },
    {
      "city": "Melrose",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Melrose, Massachusetts"
    },
    {
      "city": "Stoneham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Stoneham, Massachusetts"
    },
    {
      "city": "Boston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Boston, Massachusetts"
    },
    {
      "city": "Lexington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lexington, Massachusetts"
    },
    {
      "city": "Waltham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Waltham, Massachusetts"
    },
    {
      "city": "North Waltham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Waltham, Massachusetts"
    },
    {
      "city": "New Town",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "New Town, Massachusetts"
    },
    {
      "city": "Newton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newton, Massachusetts"
    },
    {
      "city": "Newton Center",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newton Center, Massachusetts"
    },
    {
      "city": "Newtonville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newtonville, Massachusetts"
    },
    {
      "city": "Newton Highlands",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newton Highlands, Massachusetts"
    },
    {
      "city": "Newton Lower Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newton Lower Falls, Massachusetts"
    },
    {
      "city": "Newton Upper Falls",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Newton Upper Falls, Massachusetts"
    },
    {
      "city": "West Newton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Newton, Massachusetts"
    },
    {
      "city": "Auburndale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Auburndale, Massachusetts"
    },
    {
      "city": "Chestnut Hill",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chestnut Hill, Massachusetts"
    },
    {
      "city": "Waban",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Waban, Massachusetts"
    },
    {
      "city": "Watertown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Watertown, Massachusetts"
    },
    {
      "city": "Arlington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Arlington, Massachusetts"
    },
    {
      "city": "Arlington Heights",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Arlington Heights, Massachusetts"
    },
    {
      "city": "Belmont",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Belmont, Massachusetts"
    },
    {
      "city": "Waverley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Waverley, Massachusetts"
    },
    {
      "city": "Weston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Weston, Massachusetts"
    },
    {
      "city": "Nonantum",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Nonantum, Massachusetts"
    },
    {
      "city": "Nantucket",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Nantucket, Massachusetts"
    },
    {
      "city": "Siasconset",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Siasconset, Massachusetts"
    },
    {
      "city": "Bellingham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bellingham, Massachusetts"
    },
    {
      "city": "Canton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Canton, Massachusetts"
    },
    {
      "city": "Cohasset",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cohasset, Massachusetts"
    },
    {
      "city": "Dedham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dedham, Massachusetts"
    },
    {
      "city": "Dover",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dover, Massachusetts"
    },
    {
      "city": "East Walpole",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Walpole, Massachusetts"
    },
    {
      "city": "Foxboro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Foxboro, Massachusetts"
    },
    {
      "city": "Franklin",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Franklin, Massachusetts"
    },
    {
      "city": "Medfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Medfield, Massachusetts"
    },
    {
      "city": "Medway",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Medway, Massachusetts"
    },
    {
      "city": "Millis",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Millis, Massachusetts"
    },
    {
      "city": "Norfolk",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Norfolk, Massachusetts"
    },
    {
      "city": "Norwood",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Norwood, Massachusetts"
    },
    {
      "city": "Sharon",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sharon, Massachusetts"
    },
    {
      "city": "Sheldonville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sheldonville, Massachusetts"
    },
    {
      "city": "South Walpole",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Walpole, Massachusetts"
    },
    {
      "city": "Stoughton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Stoughton, Massachusetts"
    },
    {
      "city": "Walpole",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Walpole, Massachusetts"
    },
    {
      "city": "Westwood",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westwood, Massachusetts"
    },
    {
      "city": "Wrentham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wrentham, Massachusetts"
    },
    {
      "city": "Quincy",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Quincy, Massachusetts"
    },
    {
      "city": "Braintree",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Braintree, Massachusetts"
    },
    {
      "city": "Milton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Milton, Massachusetts"
    },
    {
      "city": "Milton Village",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Milton Village, Massachusetts"
    },
    {
      "city": "Weymouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Weymouth, Massachusetts"
    },
    {
      "city": "East Weymouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Weymouth, Massachusetts"
    },
    {
      "city": "South Weymouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Weymouth, Massachusetts"
    },
    {
      "city": "North Weymouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Weymouth, Massachusetts"
    },
    {
      "city": "Avon",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Avon, Massachusetts"
    },
    {
      "city": "Holbrook",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Holbrook, Massachusetts"
    },
    {
      "city": "Randolph",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Randolph, Massachusetts"
    },
    {
      "city": "Brookline",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brookline, Massachusetts"
    },
    {
      "city": "Brookline Village",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brookline Village, Massachusetts"
    },
    {
      "city": "Babson Park",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Babson Park, Massachusetts"
    },
    {
      "city": "Wellesley Hills",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wellesley Hills, Massachusetts"
    },
    {
      "city": "Wellesley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wellesley, Massachusetts"
    },
    {
      "city": "Needham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Needham, Massachusetts"
    },
    {
      "city": "Needham Heights",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Needham Heights, Massachusetts"
    },
    {
      "city": "Plainville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Plainville, Massachusetts"
    },
    {
      "city": "Accord",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Accord, Massachusetts"
    },
    {
      "city": "Brant Rock",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brant Rock, Massachusetts"
    },
    {
      "city": "Greenbush",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Greenbush, Massachusetts"
    },
    {
      "city": "Green Harbor",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Green Harbor, Massachusetts"
    },
    {
      "city": "Hingham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hingham, Massachusetts"
    },
    {
      "city": "Hull",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hull, Massachusetts"
    },
    {
      "city": "Humarock",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Humarock, Massachusetts"
    },
    {
      "city": "Marshfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marshfield, Massachusetts"
    },
    {
      "city": "Marshfield Hills",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marshfield Hills, Massachusetts"
    },
    {
      "city": "Minot",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Minot, Massachusetts"
    },
    {
      "city": "North Marshfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Marshfield, Massachusetts"
    },
    {
      "city": "North Scituate",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Scituate, Massachusetts"
    },
    {
      "city": "Norwell",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Norwell, Massachusetts"
    },
    {
      "city": "Ocean Bluff",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ocean Bluff, Massachusetts"
    },
    {
      "city": "Scituate",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Scituate, Massachusetts"
    },
    {
      "city": "Brockton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brockton, Massachusetts"
    },
    {
      "city": "Bridgewater",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bridgewater, Massachusetts"
    },
    {
      "city": "Bryantville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bryantville, Massachusetts"
    },
    {
      "city": "Carver",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Carver, Massachusetts"
    },
    {
      "city": "Duxbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Duxbury, Massachusetts"
    },
    {
      "city": "East Bridgewater",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Bridgewater, Massachusetts"
    },
    {
      "city": "Elmwood",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Elmwood, Massachusetts"
    },
    {
      "city": "Halifax",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Halifax, Massachusetts"
    },
    {
      "city": "Hanover",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hanover, Massachusetts"
    },
    {
      "city": "Hanson",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hanson, Massachusetts"
    },
    {
      "city": "Middleboro",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Middleboro, Massachusetts"
    },
    {
      "city": "Manomet",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Manomet, Massachusetts"
    },
    {
      "city": "Lakeville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lakeville, Massachusetts"
    },
    {
      "city": "Monponsett",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Monponsett, Massachusetts"
    },
    {
      "city": "Abington",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Abington, Massachusetts"
    },
    {
      "city": "North Carver",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Carver, Massachusetts"
    },
    {
      "city": "North Pembroke",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Pembroke, Massachusetts"
    },
    {
      "city": "Pembroke",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Pembroke, Massachusetts"
    },
    {
      "city": "Plymouth",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Plymouth, Massachusetts"
    },
    {
      "city": "Kingston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Kingston, Massachusetts"
    },
    {
      "city": "South Carver",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Carver, Massachusetts"
    },
    {
      "city": "Plympton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Plympton, Massachusetts"
    },
    {
      "city": "Rockland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rockland, Massachusetts"
    },
    {
      "city": "West Bridgewater",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Bridgewater, Massachusetts"
    },
    {
      "city": "White Horse Beach",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "White Horse Beach, Massachusetts"
    },
    {
      "city": "Whitman",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Whitman, Massachusetts"
    },
    {
      "city": "East Wareham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Wareham, Massachusetts"
    },
    {
      "city": "Onset",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Onset, Massachusetts"
    },
    {
      "city": "Wareham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wareham, Massachusetts"
    },
    {
      "city": "West Wareham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Wareham, Massachusetts"
    },
    {
      "city": "Marion",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Marion, Massachusetts"
    },
    {
      "city": "Mattapoisett",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mattapoisett, Massachusetts"
    },
    {
      "city": "Rochester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rochester, Massachusetts"
    },
    {
      "city": "Roxbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Roxbury, Massachusetts"
    },
    {
      "city": "Roxbury Crossing",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Roxbury Crossing, Massachusetts"
    },
    {
      "city": "Dorchester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dorchester, Massachusetts"
    },
    {
      "city": "Dorchester Center",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dorchester Center, Massachusetts"
    },
    {
      "city": "Mattapan",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mattapan, Massachusetts"
    },
    {
      "city": "Charlestown",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Charlestown, Massachusetts"
    },
    {
      "city": "Jamaica Plain",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Jamaica Plain, Massachusetts"
    },
    {
      "city": "Roslindale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Roslindale, Massachusetts"
    },
    {
      "city": "West Roxbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Roxbury, Massachusetts"
    },
    {
      "city": "Allston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Allston, Massachusetts"
    },
    {
      "city": "Brighton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brighton, Massachusetts"
    },
    {
      "city": "Hyde Park",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hyde Park, Massachusetts"
    },
    {
      "city": "Readville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Readville, Massachusetts"
    },
    {
      "city": "Chelsea",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Chelsea, Massachusetts"
    },
    {
      "city": "Revere",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Revere, Massachusetts"
    },
    {
      "city": "Winthrop",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Winthrop, Massachusetts"
    },
    {
      "city": "Barre",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Barre, Massachusetts"
    },
    {
      "city": "Gilbertville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Gilbertville, Massachusetts"
    },
    {
      "city": "Hardwick",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hardwick, Massachusetts"
    },
    {
      "city": "Oakham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Oakham, Massachusetts"
    },
    {
      "city": "South Barre",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Barre, Massachusetts"
    },
    {
      "city": "Warren",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Warren, Massachusetts"
    },
    {
      "city": "West Warren",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Warren, Massachusetts"
    },
    {
      "city": "Wheelwright",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Wheelwright, Massachusetts"
    },
    {
      "city": "Athol",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Athol, Massachusetts"
    },
    {
      "city": "Petersham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Petersham, Massachusetts"
    },
    {
      "city": "Royalston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Royalston, Massachusetts"
    },
    {
      "city": "Fitchburg",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Fitchburg, Massachusetts"
    },
    {
      "city": "Ashburnham",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Ashburnham, Massachusetts"
    },
    {
      "city": "Devens",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Devens, Massachusetts"
    },
    {
      "city": "Baldwinville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Baldwinville, Massachusetts"
    },
    {
      "city": "East Templeton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Templeton, Massachusetts"
    },
    {
      "city": "Gardner",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Gardner, Massachusetts"
    },
    {
      "city": "Westminster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westminster, Massachusetts"
    },
    {
      "city": "Harvard",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Harvard, Massachusetts"
    },
    {
      "city": "Hubbardston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hubbardston, Massachusetts"
    },
    {
      "city": "Leominster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Leominster, Massachusetts"
    },
    {
      "city": "Lunenburg",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lunenburg, Massachusetts"
    },
    {
      "city": "Still River",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Still River, Massachusetts"
    },
    {
      "city": "Templeton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Templeton, Massachusetts"
    },
    {
      "city": "Winchendon",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Winchendon, Massachusetts"
    },
    {
      "city": "Auburn",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Auburn, Massachusetts"
    },
    {
      "city": "Berlin",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Berlin, Massachusetts"
    },
    {
      "city": "Blackstone",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Blackstone, Massachusetts"
    },
    {
      "city": "Boylston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Boylston, Massachusetts"
    },
    {
      "city": "Brookfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Brookfield, Massachusetts"
    },
    {
      "city": "Charlton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Charlton, Massachusetts"
    },
    {
      "city": "Charlton City",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Charlton City, Massachusetts"
    },
    {
      "city": "Charlton Depot",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Charlton Depot, Massachusetts"
    },
    {
      "city": "Clinton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Clinton, Massachusetts"
    },
    {
      "city": "East Brookfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "East Brookfield, Massachusetts"
    },
    {
      "city": "Douglas",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Douglas, Massachusetts"
    },
    {
      "city": "Fiskdale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Fiskdale, Massachusetts"
    },
    {
      "city": "Grafton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Grafton, Massachusetts"
    },
    {
      "city": "Holden",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Holden, Massachusetts"
    },
    {
      "city": "Jefferson",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Jefferson, Massachusetts"
    },
    {
      "city": "Lancaster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Lancaster, Massachusetts"
    },
    {
      "city": "Leicester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Leicester, Massachusetts"
    },
    {
      "city": "Linwood",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Linwood, Massachusetts"
    },
    {
      "city": "Manchaug",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Manchaug, Massachusetts"
    },
    {
      "city": "Millbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Millbury, Massachusetts"
    },
    {
      "city": "Millville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Millville, Massachusetts"
    },
    {
      "city": "New Braintree",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "New Braintree, Massachusetts"
    },
    {
      "city": "Northborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Northborough, Massachusetts"
    },
    {
      "city": "Northbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Northbridge, Massachusetts"
    },
    {
      "city": "North Brookfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Brookfield, Massachusetts"
    },
    {
      "city": "North Grafton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Grafton, Massachusetts"
    },
    {
      "city": "North Oxford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Oxford, Massachusetts"
    },
    {
      "city": "North Uxbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "North Uxbridge, Massachusetts"
    },
    {
      "city": "Oxford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Oxford, Massachusetts"
    },
    {
      "city": "Princeton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Princeton, Massachusetts"
    },
    {
      "city": "Rochdale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rochdale, Massachusetts"
    },
    {
      "city": "Rutland",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Rutland, Massachusetts"
    },
    {
      "city": "Shrewsbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Shrewsbury, Massachusetts"
    },
    {
      "city": "Southbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Southbridge, Massachusetts"
    },
    {
      "city": "South Grafton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Grafton, Massachusetts"
    },
    {
      "city": "South Lancaster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "South Lancaster, Massachusetts"
    },
    {
      "city": "Spencer",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Spencer, Massachusetts"
    },
    {
      "city": "Sterling",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sterling, Massachusetts"
    },
    {
      "city": "Sturbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sturbridge, Massachusetts"
    },
    {
      "city": "Upton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Upton, Massachusetts"
    },
    {
      "city": "Uxbridge",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Uxbridge, Massachusetts"
    },
    {
      "city": "Webster",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Webster, Massachusetts"
    },
    {
      "city": "Dudley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Dudley, Massachusetts"
    },
    {
      "city": "Westborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Westborough, Massachusetts"
    },
    {
      "city": "West Boylston",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Boylston, Massachusetts"
    },
    {
      "city": "West Brookfield",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Brookfield, Massachusetts"
    },
    {
      "city": "West Millbury",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "West Millbury, Massachusetts"
    },
    {
      "city": "Whitinsville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Whitinsville, Massachusetts"
    },
    {
      "city": "Sutton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Sutton, Massachusetts"
    },
    {
      "city": "Worcester",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Worcester, Massachusetts"
    },
    {
      "city": "Cherry Valley",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Cherry Valley, Massachusetts"
    },
    {
      "city": "Paxton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Paxton, Massachusetts"
    },
    {
      "city": "Bolton",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Bolton, Massachusetts"
    },
    {
      "city": "Fayville",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Fayville, Massachusetts"
    },
    {
      "city": "Hopedale",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Hopedale, Massachusetts"
    },
    {
      "city": "Mendon",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Mendon, Massachusetts"
    },
    {
      "city": "Milford",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Milford, Massachusetts"
    },
    {
      "city": "Southborough",
      "state": "Massachusetts",
      "shortState": "MA",
      "formatedCity": "Southborough, Massachusetts"
    },
    {
      "city": "Cumberland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cumberland, Maryland"
    },
    {
      "city": "Barton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Barton, Maryland"
    },
    {
      "city": "Corriganville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Corriganville, Maryland"
    },
    {
      "city": "Eckhart Mines",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Eckhart Mines, Maryland"
    },
    {
      "city": "Ellerslie",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ellerslie, Maryland"
    },
    {
      "city": "Flintstone",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Flintstone, Maryland"
    },
    {
      "city": "Frostburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Frostburg, Maryland"
    },
    {
      "city": "Lonaconing",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lonaconing, Maryland"
    },
    {
      "city": "Luke",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Luke, Maryland"
    },
    {
      "city": "Midland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Midland, Maryland"
    },
    {
      "city": "Midlothian",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Midlothian, Maryland"
    },
    {
      "city": "Mount Savage",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mount Savage, Maryland"
    },
    {
      "city": "Oldtown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Oldtown, Maryland"
    },
    {
      "city": "Pinto",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pinto, Maryland"
    },
    {
      "city": "Rawlings",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rawlings, Maryland"
    },
    {
      "city": "Spring Gap",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Spring Gap, Maryland"
    },
    {
      "city": "Westernport",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Westernport, Maryland"
    },
    {
      "city": "Little Orleans",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Little Orleans, Maryland"
    },
    {
      "city": "Lothian",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lothian, Maryland"
    },
    {
      "city": "Laurel",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Laurel, Maryland"
    },
    {
      "city": "Churchton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Churchton, Maryland"
    },
    {
      "city": "Deale",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Deale, Maryland"
    },
    {
      "city": "Fort George G Meade",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fort George G Meade, Maryland"
    },
    {
      "city": "Friendship",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Friendship, Maryland"
    },
    {
      "city": "Shady Side",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Shady Side, Maryland"
    },
    {
      "city": "Galesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Galesville, Maryland"
    },
    {
      "city": "Harwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Harwood, Maryland"
    },
    {
      "city": "West River",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "West River, Maryland"
    },
    {
      "city": "Tracys Landing",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tracys Landing, Maryland"
    },
    {
      "city": "Arnold",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Arnold, Maryland"
    },
    {
      "city": "Crownsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crownsville, Maryland"
    },
    {
      "city": "Davidsonville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Davidsonville, Maryland"
    },
    {
      "city": "Edgewater",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Edgewater, Maryland"
    },
    {
      "city": "Gambrills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Gambrills, Maryland"
    },
    {
      "city": "Gibson Island",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Gibson Island, Maryland"
    },
    {
      "city": "Glen Burnie",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glen Burnie, Maryland"
    },
    {
      "city": "Hanover",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hanover, Maryland"
    },
    {
      "city": "Harmans",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Harmans, Maryland"
    },
    {
      "city": "Linthicum Heights",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Linthicum Heights, Maryland"
    },
    {
      "city": "Mayo",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mayo, Maryland"
    },
    {
      "city": "Millersville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Millersville, Maryland"
    },
    {
      "city": "Odenton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Odenton, Maryland"
    },
    {
      "city": "Crofton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crofton, Maryland"
    },
    {
      "city": "Pasadena",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pasadena, Maryland"
    },
    {
      "city": "Riva",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Riva, Maryland"
    },
    {
      "city": "Severn",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Severn, Maryland"
    },
    {
      "city": "Severna Park",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Severna Park, Maryland"
    },
    {
      "city": "Brooklyn",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brooklyn, Maryland"
    },
    {
      "city": "Curtis Bay",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Curtis Bay, Maryland"
    },
    {
      "city": "Baltimore",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Baltimore, Maryland"
    },
    {
      "city": "Annapolis",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Annapolis, Maryland"
    },
    {
      "city": "Baldwin",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Baldwin, Maryland"
    },
    {
      "city": "Boring",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Boring, Maryland"
    },
    {
      "city": "Brooklandville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brooklandville, Maryland"
    },
    {
      "city": "Butler",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Butler, Maryland"
    },
    {
      "city": "Chase",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chase, Maryland"
    },
    {
      "city": "Cockeysville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cockeysville, Maryland"
    },
    {
      "city": "Hunt Valley",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hunt Valley, Maryland"
    },
    {
      "city": "Fork",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fork, Maryland"
    },
    {
      "city": "Fort Howard",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fort Howard, Maryland"
    },
    {
      "city": "Freeland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Freeland, Maryland"
    },
    {
      "city": "Glen Arm",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glen Arm, Maryland"
    },
    {
      "city": "Glyndon",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glyndon, Maryland"
    },
    {
      "city": "Hydes",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hydes, Maryland"
    },
    {
      "city": "Kingsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Kingsville, Maryland"
    },
    {
      "city": "Long Green",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Long Green, Maryland"
    },
    {
      "city": "Lutherville Timonium",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lutherville Timonium, Maryland"
    },
    {
      "city": "Maryland Line",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Maryland Line, Maryland"
    },
    {
      "city": "Monkton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Monkton, Maryland"
    },
    {
      "city": "Owings Mills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Owings Mills, Maryland"
    },
    {
      "city": "Parkton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Parkton, Maryland"
    },
    {
      "city": "Perry Hall",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Perry Hall, Maryland"
    },
    {
      "city": "Phoenix",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Phoenix, Maryland"
    },
    {
      "city": "Randallstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Randallstown, Maryland"
    },
    {
      "city": "Reisterstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Reisterstown, Maryland"
    },
    {
      "city": "Riderwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Riderwood, Maryland"
    },
    {
      "city": "Sparks Glencoe",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sparks Glencoe, Maryland"
    },
    {
      "city": "Stevenson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Stevenson, Maryland"
    },
    {
      "city": "Upperco",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Upperco, Maryland"
    },
    {
      "city": "Upper Falls",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Upper Falls, Maryland"
    },
    {
      "city": "White Marsh",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "White Marsh, Maryland"
    },
    {
      "city": "Woodstock",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Woodstock, Maryland"
    },
    {
      "city": "Towson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Towson, Maryland"
    },
    {
      "city": "Gwynn Oak",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Gwynn Oak, Maryland"
    },
    {
      "city": "Pikesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pikesville, Maryland"
    },
    {
      "city": "Sparrows Point",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sparrows Point, Maryland"
    },
    {
      "city": "Middle River",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Middle River, Maryland"
    },
    {
      "city": "Essex",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Essex, Maryland"
    },
    {
      "city": "Dundalk",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dundalk, Maryland"
    },
    {
      "city": "Halethorpe",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Halethorpe, Maryland"
    },
    {
      "city": "Catonsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Catonsville, Maryland"
    },
    {
      "city": "Parkville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Parkville, Maryland"
    },
    {
      "city": "Nottingham",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Nottingham, Maryland"
    },
    {
      "city": "Rosedale",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rosedale, Maryland"
    },
    {
      "city": "Windsor Mill",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Windsor Mill, Maryland"
    },
    {
      "city": "Barstow",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Barstow, Maryland"
    },
    {
      "city": "Broomes Island",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Broomes Island, Maryland"
    },
    {
      "city": "Dowell",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dowell, Maryland"
    },
    {
      "city": "Huntingtown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Huntingtown, Maryland"
    },
    {
      "city": "Lusby",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lusby, Maryland"
    },
    {
      "city": "Port Republic",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Port Republic, Maryland"
    },
    {
      "city": "Prince Frederick",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Prince Frederick, Maryland"
    },
    {
      "city": "Saint Leonard",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Saint Leonard, Maryland"
    },
    {
      "city": "Solomons",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Solomons, Maryland"
    },
    {
      "city": "Sunderland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sunderland, Maryland"
    },
    {
      "city": "North Beach",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "North Beach, Maryland"
    },
    {
      "city": "Chesapeake Beach",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chesapeake Beach, Maryland"
    },
    {
      "city": "Owings",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Owings, Maryland"
    },
    {
      "city": "Dunkirk",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dunkirk, Maryland"
    },
    {
      "city": "Bethlehem",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bethlehem, Maryland"
    },
    {
      "city": "Denton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Denton, Maryland"
    },
    {
      "city": "Federalsburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Federalsburg, Maryland"
    },
    {
      "city": "Goldsboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Goldsboro, Maryland"
    },
    {
      "city": "Greensboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Greensboro, Maryland"
    },
    {
      "city": "Henderson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Henderson, Maryland"
    },
    {
      "city": "Hillsboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hillsboro, Maryland"
    },
    {
      "city": "Marydel",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Marydel, Maryland"
    },
    {
      "city": "Preston",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Preston, Maryland"
    },
    {
      "city": "Ridgely",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ridgely, Maryland"
    },
    {
      "city": "Templeville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Templeville, Maryland"
    },
    {
      "city": "Finksburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Finksburg, Maryland"
    },
    {
      "city": "Hampstead",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hampstead, Maryland"
    },
    {
      "city": "Lineboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lineboro, Maryland"
    },
    {
      "city": "Manchester",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Manchester, Maryland"
    },
    {
      "city": "Marriottsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Marriottsville, Maryland"
    },
    {
      "city": "Westminster",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Westminster, Maryland"
    },
    {
      "city": "Keymar",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Keymar, Maryland"
    },
    {
      "city": "New Windsor",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "New Windsor, Maryland"
    },
    {
      "city": "Sykesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sykesville, Maryland"
    },
    {
      "city": "Taneytown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Taneytown, Maryland"
    },
    {
      "city": "Union Bridge",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Union Bridge, Maryland"
    },
    {
      "city": "Woodbine",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Woodbine, Maryland"
    },
    {
      "city": "North East",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "North East, Maryland"
    },
    {
      "city": "Perry Point",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Perry Point, Maryland"
    },
    {
      "city": "Perryville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Perryville, Maryland"
    },
    {
      "city": "Port Deposit",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Port Deposit, Maryland"
    },
    {
      "city": "Rising Sun",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rising Sun, Maryland"
    },
    {
      "city": "Warwick",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Warwick, Maryland"
    },
    {
      "city": "Cecilton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cecilton, Maryland"
    },
    {
      "city": "Charlestown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Charlestown, Maryland"
    },
    {
      "city": "Chesapeake City",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chesapeake City, Maryland"
    },
    {
      "city": "Childs",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Childs, Maryland"
    },
    {
      "city": "Colora",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Colora, Maryland"
    },
    {
      "city": "Conowingo",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Conowingo, Maryland"
    },
    {
      "city": "Earleville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Earleville, Maryland"
    },
    {
      "city": "Elk Mills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Elk Mills, Maryland"
    },
    {
      "city": "Elkton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Elkton, Maryland"
    },
    {
      "city": "Georgetown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Georgetown, Maryland"
    },
    {
      "city": "Waldorf",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Waldorf, Maryland"
    },
    {
      "city": "Bel Alton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bel Alton, Maryland"
    },
    {
      "city": "Benedict",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Benedict, Maryland"
    },
    {
      "city": "Bryans Road",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bryans Road, Maryland"
    },
    {
      "city": "Bryantown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bryantown, Maryland"
    },
    {
      "city": "Cobb Island",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cobb Island, Maryland"
    },
    {
      "city": "Faulkner",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Faulkner, Maryland"
    },
    {
      "city": "Hughesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hughesville, Maryland"
    },
    {
      "city": "Indian Head",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Indian Head, Maryland"
    },
    {
      "city": "Ironsides",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ironsides, Maryland"
    },
    {
      "city": "Issue",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Issue, Maryland"
    },
    {
      "city": "La Plata",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "La Plata, Maryland"
    },
    {
      "city": "Marbury",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Marbury, Maryland"
    },
    {
      "city": "Mount Victoria",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mount Victoria, Maryland"
    },
    {
      "city": "Nanjemoy",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Nanjemoy, Maryland"
    },
    {
      "city": "Newburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Newburg, Maryland"
    },
    {
      "city": "Pomfret",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pomfret, Maryland"
    },
    {
      "city": "Port Tobacco",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Port Tobacco, Maryland"
    },
    {
      "city": "Rock Point",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rock Point, Maryland"
    },
    {
      "city": "Welcome",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Welcome, Maryland"
    },
    {
      "city": "White Plains",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "White Plains, Maryland"
    },
    {
      "city": "Cambridge",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cambridge, Maryland"
    },
    {
      "city": "Church Creek",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Church Creek, Maryland"
    },
    {
      "city": "Crapo",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crapo, Maryland"
    },
    {
      "city": "Crocheron",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crocheron, Maryland"
    },
    {
      "city": "East New Market",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "East New Market, Maryland"
    },
    {
      "city": "Fishing Creek",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fishing Creek, Maryland"
    },
    {
      "city": "Hurlock",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hurlock, Maryland"
    },
    {
      "city": "Madison",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Madison, Maryland"
    },
    {
      "city": "Rhodesdale",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rhodesdale, Maryland"
    },
    {
      "city": "Secretary",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Secretary, Maryland"
    },
    {
      "city": "Taylors Island",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Taylors Island, Maryland"
    },
    {
      "city": "Toddville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Toddville, Maryland"
    },
    {
      "city": "Wingate",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Wingate, Maryland"
    },
    {
      "city": "Woolford",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Woolford, Maryland"
    },
    {
      "city": "Linkwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Linkwood, Maryland"
    },
    {
      "city": "Vienna",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Vienna, Maryland"
    },
    {
      "city": "Frederick",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Frederick, Maryland"
    },
    {
      "city": "Adamstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Adamstown, Maryland"
    },
    {
      "city": "Braddock Heights",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Braddock Heights, Maryland"
    },
    {
      "city": "Brunswick",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brunswick, Maryland"
    },
    {
      "city": "Buckeystown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Buckeystown, Maryland"
    },
    {
      "city": "Burkittsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Burkittsville, Maryland"
    },
    {
      "city": "Emmitsburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Emmitsburg, Maryland"
    },
    {
      "city": "Ijamsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ijamsville, Maryland"
    },
    {
      "city": "Jefferson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Jefferson, Maryland"
    },
    {
      "city": "Knoxville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Knoxville, Maryland"
    },
    {
      "city": "Ladiesburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ladiesburg, Maryland"
    },
    {
      "city": "Libertytown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Libertytown, Maryland"
    },
    {
      "city": "Middletown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Middletown, Maryland"
    },
    {
      "city": "Monrovia",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Monrovia, Maryland"
    },
    {
      "city": "Mount Airy",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mount Airy, Maryland"
    },
    {
      "city": "Myersville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Myersville, Maryland"
    },
    {
      "city": "New Market",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "New Market, Maryland"
    },
    {
      "city": "New Midway",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "New Midway, Maryland"
    },
    {
      "city": "Point Of Rocks",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Point Of Rocks, Maryland"
    },
    {
      "city": "Rocky Ridge",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rocky Ridge, Maryland"
    },
    {
      "city": "Sabillasville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sabillasville, Maryland"
    },
    {
      "city": "Thurmont",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Thurmont, Maryland"
    },
    {
      "city": "Tuscarora",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tuscarora, Maryland"
    },
    {
      "city": "Unionville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Unionville, Maryland"
    },
    {
      "city": "Walkersville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Walkersville, Maryland"
    },
    {
      "city": "Woodsboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Woodsboro, Maryland"
    },
    {
      "city": "Accident",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Accident, Maryland"
    },
    {
      "city": "Bittinger",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bittinger, Maryland"
    },
    {
      "city": "Bloomington",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bloomington, Maryland"
    },
    {
      "city": "Friendsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Friendsville, Maryland"
    },
    {
      "city": "Grantsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Grantsville, Maryland"
    },
    {
      "city": "Kitzmiller",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Kitzmiller, Maryland"
    },
    {
      "city": "Mc Henry",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mc Henry, Maryland"
    },
    {
      "city": "Oakland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Oakland, Maryland"
    },
    {
      "city": "Swanton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Swanton, Maryland"
    },
    {
      "city": "Aberdeen",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Aberdeen, Maryland"
    },
    {
      "city": "Aberdeen Proving Ground",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Aberdeen Proving Ground, Maryland"
    },
    {
      "city": "Abingdon",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Abingdon, Maryland"
    },
    {
      "city": "Gunpowder",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Gunpowder, Maryland"
    },
    {
      "city": "Bel Air",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bel Air, Maryland"
    },
    {
      "city": "Belcamp",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Belcamp, Maryland"
    },
    {
      "city": "Benson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Benson, Maryland"
    },
    {
      "city": "Churchville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Churchville, Maryland"
    },
    {
      "city": "Darlington",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Darlington, Maryland"
    },
    {
      "city": "Edgewood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Edgewood, Maryland"
    },
    {
      "city": "Fallston",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fallston, Maryland"
    },
    {
      "city": "Forest Hill",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Forest Hill, Maryland"
    },
    {
      "city": "Havre De Grace",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Havre De Grace, Maryland"
    },
    {
      "city": "Jarrettsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Jarrettsville, Maryland"
    },
    {
      "city": "Joppa",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Joppa, Maryland"
    },
    {
      "city": "Perryman",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Perryman, Maryland"
    },
    {
      "city": "Pylesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pylesville, Maryland"
    },
    {
      "city": "Street",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Street, Maryland"
    },
    {
      "city": "Whiteford",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Whiteford, Maryland"
    },
    {
      "city": "White Hall",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "White Hall, Maryland"
    },
    {
      "city": "Dhs",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dhs, Maryland"
    },
    {
      "city": "Annapolis Junction",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Annapolis Junction, Maryland"
    },
    {
      "city": "Fulton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fulton, Maryland"
    },
    {
      "city": "Savage",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Savage, Maryland"
    },
    {
      "city": "Highland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Highland, Maryland"
    },
    {
      "city": "Jessup",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Jessup, Maryland"
    },
    {
      "city": "Clarksville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Clarksville, Maryland"
    },
    {
      "city": "Dayton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dayton, Maryland"
    },
    {
      "city": "Ellicott City",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ellicott City, Maryland"
    },
    {
      "city": "Columbia",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Columbia, Maryland"
    },
    {
      "city": "Elkridge",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Elkridge, Maryland"
    },
    {
      "city": "Simpsonville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Simpsonville, Maryland"
    },
    {
      "city": "Cooksville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cooksville, Maryland"
    },
    {
      "city": "Glenelg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glenelg, Maryland"
    },
    {
      "city": "Glenwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glenwood, Maryland"
    },
    {
      "city": "Lisbon",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lisbon, Maryland"
    },
    {
      "city": "West Friendship",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "West Friendship, Maryland"
    },
    {
      "city": "Betterton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Betterton, Maryland"
    },
    {
      "city": "Chestertown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chestertown, Maryland"
    },
    {
      "city": "Galena",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Galena, Maryland"
    },
    {
      "city": "Kennedyville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Kennedyville, Maryland"
    },
    {
      "city": "Massey",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Massey, Maryland"
    },
    {
      "city": "Millington",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Millington, Maryland"
    },
    {
      "city": "Rock Hall",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rock Hall, Maryland"
    },
    {
      "city": "Still Pond",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Still Pond, Maryland"
    },
    {
      "city": "Worton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Worton, Maryland"
    },
    {
      "city": "Bethesda",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bethesda, Maryland"
    },
    {
      "city": "Glen Echo",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glen Echo, Maryland"
    },
    {
      "city": "Chevy Chase",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chevy Chase, Maryland"
    },
    {
      "city": "Cabin John",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cabin John, Maryland"
    },
    {
      "city": "Olney",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Olney, Maryland"
    },
    {
      "city": "Brookeville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brookeville, Maryland"
    },
    {
      "city": "Poolesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Poolesville, Maryland"
    },
    {
      "city": "Barnesville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Barnesville, Maryland"
    },
    {
      "city": "Beallsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Beallsville, Maryland"
    },
    {
      "city": "Boyds",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Boyds, Maryland"
    },
    {
      "city": "Dickerson",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dickerson, Maryland"
    },
    {
      "city": "Rockville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rockville, Maryland"
    },
    {
      "city": "Potomac",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Potomac, Maryland"
    },
    {
      "city": "Derwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Derwood, Maryland"
    },
    {
      "city": "Sandy Spring",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sandy Spring, Maryland"
    },
    {
      "city": "Ashton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ashton, Maryland"
    },
    {
      "city": "Brinklow",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brinklow, Maryland"
    },
    {
      "city": "Burtonsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Burtonsville, Maryland"
    },
    {
      "city": "Spencerville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Spencerville, Maryland"
    },
    {
      "city": "Clarksburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Clarksburg, Maryland"
    },
    {
      "city": "Damascus",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Damascus, Maryland"
    },
    {
      "city": "Germantown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Germantown, Maryland"
    },
    {
      "city": "Gaithersburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Gaithersburg, Maryland"
    },
    {
      "city": "Washington Grove",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Washington Grove, Maryland"
    },
    {
      "city": "Montgomery Village",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Montgomery Village, Maryland"
    },
    {
      "city": "Kensington",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Kensington, Maryland"
    },
    {
      "city": "Garrett Park",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Garrett Park, Maryland"
    },
    {
      "city": "Suburb Maryland Fac",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Suburb Maryland Fac, Maryland"
    },
    {
      "city": "Silver Spring",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Silver Spring, Maryland"
    },
    {
      "city": "Takoma Park",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Takoma Park, Maryland"
    },
    {
      "city": "Accokeek",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Accokeek, Maryland"
    },
    {
      "city": "Aquasco",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Aquasco, Maryland"
    },
    {
      "city": "Brandywine",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brandywine, Maryland"
    },
    {
      "city": "Cheltenham",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cheltenham, Maryland"
    },
    {
      "city": "Southern Md Facility",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Southern Md Facility, Maryland"
    },
    {
      "city": "Lanham",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lanham, Maryland"
    },
    {
      "city": "Beltsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Beltsville, Maryland"
    },
    {
      "city": "Bladensburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bladensburg, Maryland"
    },
    {
      "city": "Mount Rainier",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mount Rainier, Maryland"
    },
    {
      "city": "Bowie",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bowie, Maryland"
    },
    {
      "city": "Brentwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brentwood, Maryland"
    },
    {
      "city": "Capitol Heights",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Capitol Heights, Maryland"
    },
    {
      "city": "Clinton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Clinton, Maryland"
    },
    {
      "city": "Riverdale",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Riverdale, Maryland"
    },
    {
      "city": "College Park",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "College Park, Maryland"
    },
    {
      "city": "Fort Washington",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fort Washington, Maryland"
    },
    {
      "city": "Oxon Hill",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Oxon Hill, Maryland"
    },
    {
      "city": "Suitland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Suitland, Maryland"
    },
    {
      "city": "District Heights",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "District Heights, Maryland"
    },
    {
      "city": "Temple Hills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Temple Hills, Maryland"
    },
    {
      "city": "Andrews Air Force Base",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Andrews Air Force Base, Maryland"
    },
    {
      "city": "Greenbelt",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Greenbelt, Maryland"
    },
    {
      "city": "Glenn Dale",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Glenn Dale, Maryland"
    },
    {
      "city": "Upper Marlboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Upper Marlboro, Maryland"
    },
    {
      "city": "Hyattsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hyattsville, Maryland"
    },
    {
      "city": "Barclay",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Barclay, Maryland"
    },
    {
      "city": "Centreville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Centreville, Maryland"
    },
    {
      "city": "Chester",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chester, Maryland"
    },
    {
      "city": "Church Hill",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Church Hill, Maryland"
    },
    {
      "city": "Crumpton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crumpton, Maryland"
    },
    {
      "city": "Grasonville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Grasonville, Maryland"
    },
    {
      "city": "Ingleside",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ingleside, Maryland"
    },
    {
      "city": "Price",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Price, Maryland"
    },
    {
      "city": "Queen Anne",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Queen Anne, Maryland"
    },
    {
      "city": "Queenstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Queenstown, Maryland"
    },
    {
      "city": "Stevensville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Stevensville, Maryland"
    },
    {
      "city": "Sudlersville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sudlersville, Maryland"
    },
    {
      "city": "Abell",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Abell, Maryland"
    },
    {
      "city": "Avenue",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Avenue, Maryland"
    },
    {
      "city": "Bushwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bushwood, Maryland"
    },
    {
      "city": "California",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "California, Maryland"
    },
    {
      "city": "Callaway",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Callaway, Maryland"
    },
    {
      "city": "Chaptico",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chaptico, Maryland"
    },
    {
      "city": "Charlotte Hall",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Charlotte Hall, Maryland"
    },
    {
      "city": "Clements",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Clements, Maryland"
    },
    {
      "city": "Coltons Point",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Coltons Point, Maryland"
    },
    {
      "city": "Compton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Compton, Maryland"
    },
    {
      "city": "Dameron",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Dameron, Maryland"
    },
    {
      "city": "Drayden",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Drayden, Maryland"
    },
    {
      "city": "Great Mills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Great Mills, Maryland"
    },
    {
      "city": "Helen",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Helen, Maryland"
    },
    {
      "city": "Hollywood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hollywood, Maryland"
    },
    {
      "city": "Leonardtown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Leonardtown, Maryland"
    },
    {
      "city": "Lexington Park",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Lexington Park, Maryland"
    },
    {
      "city": "Loveville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Loveville, Maryland"
    },
    {
      "city": "Mechanicsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mechanicsville, Maryland"
    },
    {
      "city": "Morganza",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Morganza, Maryland"
    },
    {
      "city": "Park Hall",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Park Hall, Maryland"
    },
    {
      "city": "Patuxent River",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Patuxent River, Maryland"
    },
    {
      "city": "Piney Point",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Piney Point, Maryland"
    },
    {
      "city": "Ridge",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ridge, Maryland"
    },
    {
      "city": "Saint Inigoes",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Saint Inigoes, Maryland"
    },
    {
      "city": "Saint Marys City",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Saint Marys City, Maryland"
    },
    {
      "city": "Scotland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Scotland, Maryland"
    },
    {
      "city": "Tall Timbers",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tall Timbers, Maryland"
    },
    {
      "city": "Valley Lee",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Valley Lee, Maryland"
    },
    {
      "city": "Crisfield",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Crisfield, Maryland"
    },
    {
      "city": "Deal Island",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Deal Island, Maryland"
    },
    {
      "city": "Ewell",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ewell, Maryland"
    },
    {
      "city": "Manokin",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Manokin, Maryland"
    },
    {
      "city": "Marion Station",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Marion Station, Maryland"
    },
    {
      "city": "Princess Anne",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Princess Anne, Maryland"
    },
    {
      "city": "Rehobeth",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rehobeth, Maryland"
    },
    {
      "city": "Tylerton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tylerton, Maryland"
    },
    {
      "city": "Upper Fairmount",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Upper Fairmount, Maryland"
    },
    {
      "city": "Westover",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Westover, Maryland"
    },
    {
      "city": "Easton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Easton, Maryland"
    },
    {
      "city": "Bozman",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bozman, Maryland"
    },
    {
      "city": "Claiborne",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Claiborne, Maryland"
    },
    {
      "city": "Cordova",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cordova, Maryland"
    },
    {
      "city": "Mcdaniel",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mcdaniel, Maryland"
    },
    {
      "city": "Neavitt",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Neavitt, Maryland"
    },
    {
      "city": "Newcomb",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Newcomb, Maryland"
    },
    {
      "city": "Oxford",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Oxford, Maryland"
    },
    {
      "city": "Royal Oak",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Royal Oak, Maryland"
    },
    {
      "city": "Saint Michaels",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Saint Michaels, Maryland"
    },
    {
      "city": "Sherwood",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sherwood, Maryland"
    },
    {
      "city": "Tilghman",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tilghman, Maryland"
    },
    {
      "city": "Trappe",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Trappe, Maryland"
    },
    {
      "city": "Wittman",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Wittman, Maryland"
    },
    {
      "city": "Wye Mills",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Wye Mills, Maryland"
    },
    {
      "city": "Big Pool",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Big Pool, Maryland"
    },
    {
      "city": "Boonsboro",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Boonsboro, Maryland"
    },
    {
      "city": "Brownsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Brownsville, Maryland"
    },
    {
      "city": "Cascade",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cascade, Maryland"
    },
    {
      "city": "Cavetown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Cavetown, Maryland"
    },
    {
      "city": "Chewsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Chewsville, Maryland"
    },
    {
      "city": "Clear Spring",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Clear Spring, Maryland"
    },
    {
      "city": "Fairplay",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fairplay, Maryland"
    },
    {
      "city": "Funkstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Funkstown, Maryland"
    },
    {
      "city": "Hagerstown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hagerstown, Maryland"
    },
    {
      "city": "Hancock",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hancock, Maryland"
    },
    {
      "city": "Keedysville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Keedysville, Maryland"
    },
    {
      "city": "Maugansville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Maugansville, Maryland"
    },
    {
      "city": "Rohrersville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Rohrersville, Maryland"
    },
    {
      "city": "Saint James",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Saint James, Maryland"
    },
    {
      "city": "Sharpsburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sharpsburg, Maryland"
    },
    {
      "city": "Smithsburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Smithsburg, Maryland"
    },
    {
      "city": "Williamsport",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Williamsport, Maryland"
    },
    {
      "city": "Salisbury",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Salisbury, Maryland"
    },
    {
      "city": "Allen",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Allen, Maryland"
    },
    {
      "city": "Bivalve",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bivalve, Maryland"
    },
    {
      "city": "Fruitland",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Fruitland, Maryland"
    },
    {
      "city": "Hebron",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Hebron, Maryland"
    },
    {
      "city": "Mardela Springs",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Mardela Springs, Maryland"
    },
    {
      "city": "Nanticoke",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Nanticoke, Maryland"
    },
    {
      "city": "Parsonsburg",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Parsonsburg, Maryland"
    },
    {
      "city": "Pittsville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pittsville, Maryland"
    },
    {
      "city": "Powellville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Powellville, Maryland"
    },
    {
      "city": "Quantico",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Quantico, Maryland"
    },
    {
      "city": "Sharptown",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Sharptown, Maryland"
    },
    {
      "city": "Tyaskin",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Tyaskin, Maryland"
    },
    {
      "city": "Willards",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Willards, Maryland"
    },
    {
      "city": "Delmar",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Delmar, Maryland"
    },
    {
      "city": "Berlin",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Berlin, Maryland"
    },
    {
      "city": "Bishopville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Bishopville, Maryland"
    },
    {
      "city": "Eden",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Eden, Maryland"
    },
    {
      "city": "Girdletree",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Girdletree, Maryland"
    },
    {
      "city": "Newark",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Newark, Maryland"
    },
    {
      "city": "Ocean City",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Ocean City, Maryland"
    },
    {
      "city": "Pocomoke City",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Pocomoke City, Maryland"
    },
    {
      "city": "Showell",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Showell, Maryland"
    },
    {
      "city": "Snow Hill",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Snow Hill, Maryland"
    },
    {
      "city": "Stockton",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Stockton, Maryland"
    },
    {
      "city": "Whaleyville",
      "state": "Maryland",
      "shortState": "MD",
      "formatedCity": "Whaleyville, Maryland"
    },
    {
      "city": "Auburn",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Auburn, Maine"
    },
    {
      "city": "Durham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Durham, Maine"
    },
    {
      "city": "Danville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Danville, Maine"
    },
    {
      "city": "East Livermore",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Livermore, Maine"
    },
    {
      "city": "East Poland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Poland, Maine"
    },
    {
      "city": "Greene",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Greene, Maine"
    },
    {
      "city": "Lewiston",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lewiston, Maine"
    },
    {
      "city": "Lisbon",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lisbon, Maine"
    },
    {
      "city": "Lisbon Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lisbon Falls, Maine"
    },
    {
      "city": "Livermore",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Livermore, Maine"
    },
    {
      "city": "Livermore Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Livermore Falls, Maine"
    },
    {
      "city": "Mechanic Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mechanic Falls, Maine"
    },
    {
      "city": "Minot",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Minot, Maine"
    },
    {
      "city": "Leeds",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Leeds, Maine"
    },
    {
      "city": "North Turner",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Turner, Maine"
    },
    {
      "city": "Poland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Poland, Maine"
    },
    {
      "city": "Sabattus",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sabattus, Maine"
    },
    {
      "city": "Turner",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Turner, Maine"
    },
    {
      "city": "West Minot",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Minot, Maine"
    },
    {
      "city": "West Poland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Poland, Maine"
    },
    {
      "city": "Orient",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Orient, Maine"
    },
    {
      "city": "Wytopitlock",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wytopitlock, Maine"
    },
    {
      "city": "Houlton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Houlton, Maine"
    },
    {
      "city": "Ashland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Ashland, Maine"
    },
    {
      "city": "Benedicta",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Benedicta, Maine"
    },
    {
      "city": "Blaine",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Blaine, Maine"
    },
    {
      "city": "Bridgewater",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bridgewater, Maine"
    },
    {
      "city": "Caribou",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Caribou, Maine"
    },
    {
      "city": "Clayton Lake",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Clayton Lake, Maine"
    },
    {
      "city": "Crouseville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Crouseville, Maine"
    },
    {
      "city": "Eagle Lake",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Eagle Lake, Maine"
    },
    {
      "city": "Easton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Easton, Maine"
    },
    {
      "city": "Estcourt Station",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Estcourt Station, Maine"
    },
    {
      "city": "Fort Fairfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Fort Fairfield, Maine"
    },
    {
      "city": "Fort Kent",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Fort Kent, Maine"
    },
    {
      "city": "Fort Kent Mills",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Fort Kent Mills, Maine"
    },
    {
      "city": "Frenchville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Frenchville, Maine"
    },
    {
      "city": "Grand Isle",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Grand Isle, Maine"
    },
    {
      "city": "Island Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Island Falls, Maine"
    },
    {
      "city": "Limestone",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Limestone, Maine"
    },
    {
      "city": "Madawaska",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Madawaska, Maine"
    },
    {
      "city": "Mapleton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mapleton, Maine"
    },
    {
      "city": "Mars Hill",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mars Hill, Maine"
    },
    {
      "city": "Monticello",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Monticello, Maine"
    },
    {
      "city": "New Limerick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Limerick, Maine"
    },
    {
      "city": "New Sweden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Sweden, Maine"
    },
    {
      "city": "Oakfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Oakfield, Maine"
    },
    {
      "city": "Oxbow",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Oxbow, Maine"
    },
    {
      "city": "Perham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Perham, Maine"
    },
    {
      "city": "Portage",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Portage, Maine"
    },
    {
      "city": "Presque Isle",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Presque Isle, Maine"
    },
    {
      "city": "Saint Agatha",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Saint Agatha, Maine"
    },
    {
      "city": "Saint David",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Saint David, Maine"
    },
    {
      "city": "Saint Francis",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Saint Francis, Maine"
    },
    {
      "city": "Sheridan",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sheridan, Maine"
    },
    {
      "city": "Sherman",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sherman, Maine"
    },
    {
      "city": "Sinclair",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sinclair, Maine"
    },
    {
      "city": "Smyrna Mills",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Smyrna Mills, Maine"
    },
    {
      "city": "Wallagrass",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wallagrass, Maine"
    },
    {
      "city": "Stockholm",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stockholm, Maine"
    },
    {
      "city": "Van Buren",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Van Buren, Maine"
    },
    {
      "city": "Washburn",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Washburn, Maine"
    },
    {
      "city": "Westfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Westfield, Maine"
    },
    {
      "city": "Bailey Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bailey Island, Maine"
    },
    {
      "city": "Bridgton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bridgton, Maine"
    },
    {
      "city": "Brunswick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brunswick, Maine"
    },
    {
      "city": "Bustins Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bustins Island, Maine"
    },
    {
      "city": "Casco",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Casco, Maine"
    },
    {
      "city": "Chebeague Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Chebeague Island, Maine"
    },
    {
      "city": "Cliff Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cliff Island, Maine"
    },
    {
      "city": "Cumberland Center",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cumberland Center, Maine"
    },
    {
      "city": "East Baldwin",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Baldwin, Maine"
    },
    {
      "city": "Sebago",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sebago, Maine"
    },
    {
      "city": "Freeport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Freeport, Maine"
    },
    {
      "city": "Gorham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Gorham, Maine"
    },
    {
      "city": "Gray",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Gray, Maine"
    },
    {
      "city": "Harrison",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Harrison, Maine"
    },
    {
      "city": "Long Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Long Island, Maine"
    },
    {
      "city": "Naples",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Naples, Maine"
    },
    {
      "city": "North Bridgton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Bridgton, Maine"
    },
    {
      "city": "Windham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Windham, Maine"
    },
    {
      "city": "Orrs Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Orrs Island, Maine"
    },
    {
      "city": "Pownal",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Pownal, Maine"
    },
    {
      "city": "Scarborough",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Scarborough, Maine"
    },
    {
      "city": "Raymond",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Raymond, Maine"
    },
    {
      "city": "South Casco",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Casco, Maine"
    },
    {
      "city": "South Freeport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Freeport, Maine"
    },
    {
      "city": "Harpswell",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Harpswell, Maine"
    },
    {
      "city": "South Windham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Windham, Maine"
    },
    {
      "city": "Standish",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Standish, Maine"
    },
    {
      "city": "Steep Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Steep Falls, Maine"
    },
    {
      "city": "West Baldwin",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Baldwin, Maine"
    },
    {
      "city": "Westbrook",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Westbrook, Maine"
    },
    {
      "city": "Yarmouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Yarmouth, Maine"
    },
    {
      "city": "North Yarmouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Yarmouth, Maine"
    },
    {
      "city": "Portland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Portland, Maine"
    },
    {
      "city": "Falmouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Falmouth, Maine"
    },
    {
      "city": "South Portland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Portland, Maine"
    },
    {
      "city": "Cape Elizabeth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cape Elizabeth, Maine"
    },
    {
      "city": "Peaks Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Peaks Island, Maine"
    },
    {
      "city": "Cumberland Foreside",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cumberland Foreside, Maine"
    },
    {
      "city": "New Gloucester",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Gloucester, Maine"
    },
    {
      "city": "Dryden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dryden, Maine"
    },
    {
      "city": "East Dixfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Dixfield, Maine"
    },
    {
      "city": "East Wilton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Wilton, Maine"
    },
    {
      "city": "Jay",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Jay, Maine"
    },
    {
      "city": "North Jay",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Jay, Maine"
    },
    {
      "city": "Weld",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Weld, Maine"
    },
    {
      "city": "Wilton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wilton, Maine"
    },
    {
      "city": "Eustis",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Eustis, Maine"
    },
    {
      "city": "Farmington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Farmington, Maine"
    },
    {
      "city": "Farmington Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Farmington Falls, Maine"
    },
    {
      "city": "Kingfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kingfield, Maine"
    },
    {
      "city": "New Sharon",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Sharon, Maine"
    },
    {
      "city": "New Vineyard",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Vineyard, Maine"
    },
    {
      "city": "Oquossoc",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Oquossoc, Maine"
    },
    {
      "city": "Phillips",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Phillips, Maine"
    },
    {
      "city": "Rangeley",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Rangeley, Maine"
    },
    {
      "city": "Stratton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stratton, Maine"
    },
    {
      "city": "Strong",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Strong, Maine"
    },
    {
      "city": "Temple",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Temple, Maine"
    },
    {
      "city": "West Farmington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Farmington, Maine"
    },
    {
      "city": "Aurora",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Aurora, Maine"
    },
    {
      "city": "Bucksport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bucksport, Maine"
    },
    {
      "city": "Castine",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Castine, Maine"
    },
    {
      "city": "East Orland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Orland, Maine"
    },
    {
      "city": "Orland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Orland, Maine"
    },
    {
      "city": "Penobscot",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Penobscot, Maine"
    },
    {
      "city": "Ellsworth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Ellsworth, Maine"
    },
    {
      "city": "Gouldsboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Gouldsboro, Maine"
    },
    {
      "city": "Bar Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bar Harbor, Maine"
    },
    {
      "city": "Bernard",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bernard, Maine"
    },
    {
      "city": "Birch Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Birch Harbor, Maine"
    },
    {
      "city": "Blue Hill",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Blue Hill, Maine"
    },
    {
      "city": "Brooklin",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brooklin, Maine"
    },
    {
      "city": "Brooksville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brooksville, Maine"
    },
    {
      "city": "Corea",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Corea, Maine"
    },
    {
      "city": "Cranberry Isles",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cranberry Isles, Maine"
    },
    {
      "city": "Deer Isle",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Deer Isle, Maine"
    },
    {
      "city": "East Blue Hill",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Blue Hill, Maine"
    },
    {
      "city": "Franklin",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Franklin, Maine"
    },
    {
      "city": "Frenchboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Frenchboro, Maine"
    },
    {
      "city": "Hancock",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hancock, Maine"
    },
    {
      "city": "Harborside",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Harborside, Maine"
    },
    {
      "city": "Hulls Cove",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hulls Cove, Maine"
    },
    {
      "city": "Islesford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Islesford, Maine"
    },
    {
      "city": "Little Deer Isle",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Little Deer Isle, Maine"
    },
    {
      "city": "Bass Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bass Harbor, Maine"
    },
    {
      "city": "Mount Desert",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mount Desert, Maine"
    },
    {
      "city": "Northeast Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Northeast Harbor, Maine"
    },
    {
      "city": "Sullivan",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sullivan, Maine"
    },
    {
      "city": "Prospect Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Prospect Harbor, Maine"
    },
    {
      "city": "Salsbury Cove",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Salsbury Cove, Maine"
    },
    {
      "city": "Sargentville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sargentville, Maine"
    },
    {
      "city": "Seal Cove",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Seal Cove, Maine"
    },
    {
      "city": "Seal Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Seal Harbor, Maine"
    },
    {
      "city": "Sedgwick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sedgwick, Maine"
    },
    {
      "city": "Sorrento",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sorrento, Maine"
    },
    {
      "city": "Southwest Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Southwest Harbor, Maine"
    },
    {
      "city": "Stonington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stonington, Maine"
    },
    {
      "city": "Sunset",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sunset, Maine"
    },
    {
      "city": "Surry",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Surry, Maine"
    },
    {
      "city": "Swans Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Swans Island, Maine"
    },
    {
      "city": "Winter Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Winter Harbor, Maine"
    },
    {
      "city": "Monmouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Monmouth, Maine"
    },
    {
      "city": "North Monmouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Monmouth, Maine"
    },
    {
      "city": "Wayne",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wayne, Maine"
    },
    {
      "city": "Augusta",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Augusta, Maine"
    },
    {
      "city": "East Winthrop",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Winthrop, Maine"
    },
    {
      "city": "Farmingdale",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Farmingdale, Maine"
    },
    {
      "city": "Gardiner",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Gardiner, Maine"
    },
    {
      "city": "Randolph",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Randolph, Maine"
    },
    {
      "city": "Hallowell",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hallowell, Maine"
    },
    {
      "city": "Kents Hill",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kents Hill, Maine"
    },
    {
      "city": "Litchfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Litchfield, Maine"
    },
    {
      "city": "Manchester",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Manchester, Maine"
    },
    {
      "city": "Mount Vernon",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mount Vernon, Maine"
    },
    {
      "city": "Readfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Readfield, Maine"
    },
    {
      "city": "South China",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South China, Maine"
    },
    {
      "city": "South Gardiner",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Gardiner, Maine"
    },
    {
      "city": "Vienna",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Vienna, Maine"
    },
    {
      "city": "Windsor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Windsor, Maine"
    },
    {
      "city": "Winthrop",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Winthrop, Maine"
    },
    {
      "city": "Waterville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Waterville, Maine"
    },
    {
      "city": "Albion",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Albion, Maine"
    },
    {
      "city": "Belgrade",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Belgrade, Maine"
    },
    {
      "city": "Belgrade Lakes",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Belgrade Lakes, Maine"
    },
    {
      "city": "China Village",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "China Village, Maine"
    },
    {
      "city": "Clinton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Clinton, Maine"
    },
    {
      "city": "East Vassalboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Vassalboro, Maine"
    },
    {
      "city": "North Vassalboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Vassalboro, Maine"
    },
    {
      "city": "Oakland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Oakland, Maine"
    },
    {
      "city": "Vassalboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Vassalboro, Maine"
    },
    {
      "city": "Friendship",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Friendship, Maine"
    },
    {
      "city": "Cushing",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cushing, Maine"
    },
    {
      "city": "Washington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Washington, Maine"
    },
    {
      "city": "Isle Au Haut",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Isle Au Haut, Maine"
    },
    {
      "city": "Rockland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Rockland, Maine"
    },
    {
      "city": "Camden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Camden, Maine"
    },
    {
      "city": "Hope",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hope, Maine"
    },
    {
      "city": "Matinicus",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Matinicus, Maine"
    },
    {
      "city": "North Haven",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Haven, Maine"
    },
    {
      "city": "Owls Head",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Owls Head, Maine"
    },
    {
      "city": "Port Clyde",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Port Clyde, Maine"
    },
    {
      "city": "Rockport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Rockport, Maine"
    },
    {
      "city": "South Thomaston",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Thomaston, Maine"
    },
    {
      "city": "Spruce Head",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Spruce Head, Maine"
    },
    {
      "city": "Tenants Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Tenants Harbor, Maine"
    },
    {
      "city": "Thomaston",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Thomaston, Maine"
    },
    {
      "city": "Union",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Union, Maine"
    },
    {
      "city": "Vinalhaven",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Vinalhaven, Maine"
    },
    {
      "city": "Warren",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Warren, Maine"
    },
    {
      "city": "West Rockport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Rockport, Maine"
    },
    {
      "city": "Coopers Mills",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Coopers Mills, Maine"
    },
    {
      "city": "Dresden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dresden, Maine"
    },
    {
      "city": "Jefferson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Jefferson, Maine"
    },
    {
      "city": "Whitefield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Whitefield, Maine"
    },
    {
      "city": "Alna",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Alna, Maine"
    },
    {
      "city": "Boothbay",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Boothbay, Maine"
    },
    {
      "city": "Boothbay Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Boothbay Harbor, Maine"
    },
    {
      "city": "Bristol",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bristol, Maine"
    },
    {
      "city": "Chamberlain",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Chamberlain, Maine"
    },
    {
      "city": "Damariscotta",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Damariscotta, Maine"
    },
    {
      "city": "East Boothbay",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Boothbay, Maine"
    },
    {
      "city": "Isle Of Springs",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Isle Of Springs, Maine"
    },
    {
      "city": "Bremen",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bremen, Maine"
    },
    {
      "city": "Newcastle",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Newcastle, Maine"
    },
    {
      "city": "New Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Harbor, Maine"
    },
    {
      "city": "Nobleboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Nobleboro, Maine"
    },
    {
      "city": "Edgecomb",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Edgecomb, Maine"
    },
    {
      "city": "Pemaquid",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Pemaquid, Maine"
    },
    {
      "city": "Round Pond",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Round Pond, Maine"
    },
    {
      "city": "South Bristol",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Bristol, Maine"
    },
    {
      "city": "Squirrel Island",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Squirrel Island, Maine"
    },
    {
      "city": "Trevett",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Trevett, Maine"
    },
    {
      "city": "Waldoboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Waldoboro, Maine"
    },
    {
      "city": "Walpole",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Walpole, Maine"
    },
    {
      "city": "West Boothbay Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Boothbay Harbor, Maine"
    },
    {
      "city": "Southport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Southport, Maine"
    },
    {
      "city": "Wiscasset",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wiscasset, Maine"
    },
    {
      "city": "Monhegan",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Monhegan, Maine"
    },
    {
      "city": "Brownfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brownfield, Maine"
    },
    {
      "city": "Center Lovell",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Center Lovell, Maine"
    },
    {
      "city": "Denmark",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Denmark, Maine"
    },
    {
      "city": "Fryeburg",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Fryeburg, Maine"
    },
    {
      "city": "Hiram",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hiram, Maine"
    },
    {
      "city": "Lovell",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lovell, Maine"
    },
    {
      "city": "Porter",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Porter, Maine"
    },
    {
      "city": "Waterford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Waterford, Maine"
    },
    {
      "city": "Andover",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Andover, Maine"
    },
    {
      "city": "Bethel",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bethel, Maine"
    },
    {
      "city": "Bryant Pond",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bryant Pond, Maine"
    },
    {
      "city": "Buckfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Buckfield, Maine"
    },
    {
      "city": "Canton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Canton, Maine"
    },
    {
      "city": "Dixfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dixfield, Maine"
    },
    {
      "city": "East Andover",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Andover, Maine"
    },
    {
      "city": "Stoneham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stoneham, Maine"
    },
    {
      "city": "Hanover",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hanover, Maine"
    },
    {
      "city": "Hebron",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hebron, Maine"
    },
    {
      "city": "Greenwood",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Greenwood, Maine"
    },
    {
      "city": "Mexico",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mexico, Maine"
    },
    {
      "city": "Newry",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Newry, Maine"
    },
    {
      "city": "North Waterford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Waterford, Maine"
    },
    {
      "city": "Norway",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Norway, Maine"
    },
    {
      "city": "Oxford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Oxford, Maine"
    },
    {
      "city": "Paris",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Paris, Maine"
    },
    {
      "city": "Roxbury",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Roxbury, Maine"
    },
    {
      "city": "Rumford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Rumford, Maine"
    },
    {
      "city": "South Paris",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Paris, Maine"
    },
    {
      "city": "West Bethel",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Bethel, Maine"
    },
    {
      "city": "West Paris",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Paris, Maine"
    },
    {
      "city": "Peru",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Peru, Maine"
    },
    {
      "city": "Sumner",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sumner, Maine"
    },
    {
      "city": "Bangor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bangor, Maine"
    },
    {
      "city": "Bradford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bradford, Maine"
    },
    {
      "city": "Bradley",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bradley, Maine"
    },
    {
      "city": "Brewer",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brewer, Maine"
    },
    {
      "city": "Burlington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Burlington, Maine"
    },
    {
      "city": "Greenbush",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Greenbush, Maine"
    },
    {
      "city": "Carmel",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Carmel, Maine"
    },
    {
      "city": "Charleston",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Charleston, Maine"
    },
    {
      "city": "Corinth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Corinth, Maine"
    },
    {
      "city": "Eddington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Eddington, Maine"
    },
    {
      "city": "Holden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Holden, Maine"
    },
    {
      "city": "East Millinocket",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Millinocket, Maine"
    },
    {
      "city": "Etna",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Etna, Maine"
    },
    {
      "city": "Exeter",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Exeter, Maine"
    },
    {
      "city": "Hampden",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hampden, Maine"
    },
    {
      "city": "Howland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Howland, Maine"
    },
    {
      "city": "Hudson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hudson, Maine"
    },
    {
      "city": "Kenduskeag",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kenduskeag, Maine"
    },
    {
      "city": "Kingman",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kingman, Maine"
    },
    {
      "city": "Lagrange",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lagrange, Maine"
    },
    {
      "city": "Lee",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lee, Maine"
    },
    {
      "city": "Levant",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Levant, Maine"
    },
    {
      "city": "Lincoln",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lincoln, Maine"
    },
    {
      "city": "Mattawamkeag",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Mattawamkeag, Maine"
    },
    {
      "city": "Medway",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Medway, Maine"
    },
    {
      "city": "Milford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Milford, Maine"
    },
    {
      "city": "Millinocket",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Millinocket, Maine"
    },
    {
      "city": "Old Town",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Old Town, Maine"
    },
    {
      "city": "Orono",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Orono, Maine"
    },
    {
      "city": "Orrington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Orrington, Maine"
    },
    {
      "city": "Passadumkeag",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Passadumkeag, Maine"
    },
    {
      "city": "Springfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Springfield, Maine"
    },
    {
      "city": "Stetson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stetson, Maine"
    },
    {
      "city": "Stillwater",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stillwater, Maine"
    },
    {
      "city": "West Enfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Enfield, Maine"
    },
    {
      "city": "Winn",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Winn, Maine"
    },
    {
      "city": "Patten",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Patten, Maine"
    },
    {
      "city": "Stacyville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stacyville, Maine"
    },
    {
      "city": "Corinna",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Corinna, Maine"
    },
    {
      "city": "Dexter",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dexter, Maine"
    },
    {
      "city": "Dixmont",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dixmont, Maine"
    },
    {
      "city": "East Newport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Newport, Maine"
    },
    {
      "city": "Garland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Garland, Maine"
    },
    {
      "city": "Newport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Newport, Maine"
    },
    {
      "city": "Plymouth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Plymouth, Maine"
    },
    {
      "city": "Abbot",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Abbot, Maine"
    },
    {
      "city": "Brownville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brownville, Maine"
    },
    {
      "city": "Brownville Junction",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brownville Junction, Maine"
    },
    {
      "city": "Dover Foxcroft",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dover Foxcroft, Maine"
    },
    {
      "city": "Greenville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Greenville, Maine"
    },
    {
      "city": "Greenville Junction",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Greenville Junction, Maine"
    },
    {
      "city": "Guilford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Guilford, Maine"
    },
    {
      "city": "Milo",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Milo, Maine"
    },
    {
      "city": "Monson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Monson, Maine"
    },
    {
      "city": "Sangerville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sangerville, Maine"
    },
    {
      "city": "Sebec",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sebec, Maine"
    },
    {
      "city": "Shirley Mills",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Shirley Mills, Maine"
    },
    {
      "city": "Bowdoinham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bowdoinham, Maine"
    },
    {
      "city": "Topsham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Topsham, Maine"
    },
    {
      "city": "Bowdoin",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bowdoin, Maine"
    },
    {
      "city": "Richmond",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Richmond, Maine"
    },
    {
      "city": "Bath",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bath, Maine"
    },
    {
      "city": "Georgetown",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Georgetown, Maine"
    },
    {
      "city": "Phippsburg",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Phippsburg, Maine"
    },
    {
      "city": "Sebasco Estates",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sebasco Estates, Maine"
    },
    {
      "city": "Woolwich",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Woolwich, Maine"
    },
    {
      "city": "Rockwood",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Rockwood, Maine"
    },
    {
      "city": "Anson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Anson, Maine"
    },
    {
      "city": "Athens",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Athens, Maine"
    },
    {
      "city": "Bingham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bingham, Maine"
    },
    {
      "city": "Cambridge",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cambridge, Maine"
    },
    {
      "city": "Canaan",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Canaan, Maine"
    },
    {
      "city": "Caratunk",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Caratunk, Maine"
    },
    {
      "city": "Detroit",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Detroit, Maine"
    },
    {
      "city": "Fairfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Fairfield, Maine"
    },
    {
      "city": "Harmony",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Harmony, Maine"
    },
    {
      "city": "Hartland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hartland, Maine"
    },
    {
      "city": "Hinckley",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hinckley, Maine"
    },
    {
      "city": "Jackman",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Jackman, Maine"
    },
    {
      "city": "Madison",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Madison, Maine"
    },
    {
      "city": "New Portland",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "New Portland, Maine"
    },
    {
      "city": "Norridgewock",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Norridgewock, Maine"
    },
    {
      "city": "North Anson",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Anson, Maine"
    },
    {
      "city": "Palmyra",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Palmyra, Maine"
    },
    {
      "city": "Pittsfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Pittsfield, Maine"
    },
    {
      "city": "Saint Albans",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Saint Albans, Maine"
    },
    {
      "city": "Shawmut",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Shawmut, Maine"
    },
    {
      "city": "Skowhegan",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Skowhegan, Maine"
    },
    {
      "city": "Smithfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Smithfield, Maine"
    },
    {
      "city": "Solon",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Solon, Maine"
    },
    {
      "city": "West Forks",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Forks, Maine"
    },
    {
      "city": "Palermo",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Palermo, Maine"
    },
    {
      "city": "Frankfort",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Frankfort, Maine"
    },
    {
      "city": "Winterport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Winterport, Maine"
    },
    {
      "city": "Islesboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Islesboro, Maine"
    },
    {
      "city": "Lincolnville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lincolnville, Maine"
    },
    {
      "city": "Lincolnville Center",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lincolnville Center, Maine"
    },
    {
      "city": "Belfast",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Belfast, Maine"
    },
    {
      "city": "Brooks",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brooks, Maine"
    },
    {
      "city": "Burnham",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Burnham, Maine"
    },
    {
      "city": "Freedom",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Freedom, Maine"
    },
    {
      "city": "Liberty",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Liberty, Maine"
    },
    {
      "city": "Monroe",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Monroe, Maine"
    },
    {
      "city": "Morrill",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Morrill, Maine"
    },
    {
      "city": "Sandy Point",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sandy Point, Maine"
    },
    {
      "city": "Searsmont",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Searsmont, Maine"
    },
    {
      "city": "Searsport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Searsport, Maine"
    },
    {
      "city": "Stockton Springs",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Stockton Springs, Maine"
    },
    {
      "city": "Thorndike",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Thorndike, Maine"
    },
    {
      "city": "Troy",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Troy, Maine"
    },
    {
      "city": "Unity",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Unity, Maine"
    },
    {
      "city": "Brookton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Brookton, Maine"
    },
    {
      "city": "Danforth",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Danforth, Maine"
    },
    {
      "city": "Lambert Lake",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lambert Lake, Maine"
    },
    {
      "city": "Topsfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Topsfield, Maine"
    },
    {
      "city": "Vanceboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Vanceboro, Maine"
    },
    {
      "city": "Waite",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Waite, Maine"
    },
    {
      "city": "Addison",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Addison, Maine"
    },
    {
      "city": "Beals",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Beals, Maine"
    },
    {
      "city": "Calais",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Calais, Maine"
    },
    {
      "city": "Cherryfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cherryfield, Maine"
    },
    {
      "city": "Columbia Falls",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Columbia Falls, Maine"
    },
    {
      "city": "Cutler",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cutler, Maine"
    },
    {
      "city": "Dennysville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Dennysville, Maine"
    },
    {
      "city": "East Machias",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Machias, Maine"
    },
    {
      "city": "Eastport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Eastport, Maine"
    },
    {
      "city": "Grand Lake Stream",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Grand Lake Stream, Maine"
    },
    {
      "city": "Harrington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Harrington, Maine"
    },
    {
      "city": "Jonesboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Jonesboro, Maine"
    },
    {
      "city": "Jonesport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Jonesport, Maine"
    },
    {
      "city": "Lubec",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lubec, Maine"
    },
    {
      "city": "Machias",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Machias, Maine"
    },
    {
      "city": "Machiasport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Machiasport, Maine"
    },
    {
      "city": "Meddybemps",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Meddybemps, Maine"
    },
    {
      "city": "Milbridge",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Milbridge, Maine"
    },
    {
      "city": "Pembroke",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Pembroke, Maine"
    },
    {
      "city": "Perry",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Perry, Maine"
    },
    {
      "city": "Princeton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Princeton, Maine"
    },
    {
      "city": "Robbinston",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Robbinston, Maine"
    },
    {
      "city": "Steuben",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Steuben, Maine"
    },
    {
      "city": "Wesley",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wesley, Maine"
    },
    {
      "city": "Whiting",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Whiting, Maine"
    },
    {
      "city": "Baileyville",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Baileyville, Maine"
    },
    {
      "city": "Berwick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Berwick, Maine"
    },
    {
      "city": "Cape Neddick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cape Neddick, Maine"
    },
    {
      "city": "Eliot",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Eliot, Maine"
    },
    {
      "city": "Kittery",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kittery, Maine"
    },
    {
      "city": "Kittery Point",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kittery Point, Maine"
    },
    {
      "city": "North Berwick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Berwick, Maine"
    },
    {
      "city": "Ogunquit",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Ogunquit, Maine"
    },
    {
      "city": "South Berwick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "South Berwick, Maine"
    },
    {
      "city": "York",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "York, Maine"
    },
    {
      "city": "York Beach",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "York Beach, Maine"
    },
    {
      "city": "York Harbor",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "York Harbor, Maine"
    },
    {
      "city": "Acton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Acton, Maine"
    },
    {
      "city": "Alfred",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Alfred, Maine"
    },
    {
      "city": "Bar Mills",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Bar Mills, Maine"
    },
    {
      "city": "Biddeford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Biddeford, Maine"
    },
    {
      "city": "Biddeford Pool",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Biddeford Pool, Maine"
    },
    {
      "city": "Cape Porpoise",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cape Porpoise, Maine"
    },
    {
      "city": "Cornish",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Cornish, Maine"
    },
    {
      "city": "Lebanon",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Lebanon, Maine"
    },
    {
      "city": "East Parsonsfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Parsonsfield, Maine"
    },
    {
      "city": "East Waterboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "East Waterboro, Maine"
    },
    {
      "city": "Hollis Center",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Hollis Center, Maine"
    },
    {
      "city": "Kennebunk",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kennebunk, Maine"
    },
    {
      "city": "Kennebunkport",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Kennebunkport, Maine"
    },
    {
      "city": "Parsonsfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Parsonsfield, Maine"
    },
    {
      "city": "Limerick",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Limerick, Maine"
    },
    {
      "city": "Limington",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Limington, Maine"
    },
    {
      "city": "Moody",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Moody, Maine"
    },
    {
      "city": "Newfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Newfield, Maine"
    },
    {
      "city": "North Waterboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "North Waterboro, Maine"
    },
    {
      "city": "Ocean Park",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Ocean Park, Maine"
    },
    {
      "city": "Old Orchard Beach",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Old Orchard Beach, Maine"
    },
    {
      "city": "Saco",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Saco, Maine"
    },
    {
      "city": "Sanford",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Sanford, Maine"
    },
    {
      "city": "Shapleigh",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Shapleigh, Maine"
    },
    {
      "city": "Springvale",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Springvale, Maine"
    },
    {
      "city": "Waterboro",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Waterboro, Maine"
    },
    {
      "city": "Wells",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Wells, Maine"
    },
    {
      "city": "Buxton",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "Buxton, Maine"
    },
    {
      "city": "West Kennebunk",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Kennebunk, Maine"
    },
    {
      "city": "West Newfield",
      "state": "Maine",
      "shortState": "ME",
      "formatedCity": "West Newfield, Maine"
    },
    {
      "city": "Marshall Islands",
      "state": "",
      "shortState": "MH",
      "formatedCity": "Marshall Islands, "
    },
    {
      "city": "Ebeye",
      "state": "",
      "shortState": "MH",
      "formatedCity": "Ebeye, "
    },
    {
      "city": "Barton City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Barton City, Michigan"
    },
    {
      "city": "Black River",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Black River, Michigan"
    },
    {
      "city": "Curran",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Curran, Michigan"
    },
    {
      "city": "Glennie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Glennie, Michigan"
    },
    {
      "city": "Greenbush",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Greenbush, Michigan"
    },
    {
      "city": "Harrisville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harrisville, Michigan"
    },
    {
      "city": "Lincoln",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lincoln, Michigan"
    },
    {
      "city": "Mikado",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mikado, Michigan"
    },
    {
      "city": "Spruce",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Spruce, Michigan"
    },
    {
      "city": "Au Train",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Au Train, Michigan"
    },
    {
      "city": "Chatham",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chatham, Michigan"
    },
    {
      "city": "Deerton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Deerton, Michigan"
    },
    {
      "city": "Eben Junction",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eben Junction, Michigan"
    },
    {
      "city": "Rumely",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rumely, Michigan"
    },
    {
      "city": "Grand Marais",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Marais, Michigan"
    },
    {
      "city": "Munising",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Munising, Michigan"
    },
    {
      "city": "Shingleton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Shingleton, Michigan"
    },
    {
      "city": "Trenary",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Trenary, Michigan"
    },
    {
      "city": "Wetmore",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wetmore, Michigan"
    },
    {
      "city": "Allegan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allegan, Michigan"
    },
    {
      "city": "Martin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Martin, Michigan"
    },
    {
      "city": "Otsego",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Otsego, Michigan"
    },
    {
      "city": "Plainwell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Plainwell, Michigan"
    },
    {
      "city": "Bradley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bradley, Michigan"
    },
    {
      "city": "Burnips",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burnips, Michigan"
    },
    {
      "city": "Dorr",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dorr, Michigan"
    },
    {
      "city": "Hopkins",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hopkins, Michigan"
    },
    {
      "city": "Moline",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Moline, Michigan"
    },
    {
      "city": "Shelbyville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Shelbyville, Michigan"
    },
    {
      "city": "Wayland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wayland, Michigan"
    },
    {
      "city": "Douglas",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Douglas, Michigan"
    },
    {
      "city": "Fennville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fennville, Michigan"
    },
    {
      "city": "Glenn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Glenn, Michigan"
    },
    {
      "city": "Hamilton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hamilton, Michigan"
    },
    {
      "city": "Pullman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pullman, Michigan"
    },
    {
      "city": "Saugatuck",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saugatuck, Michigan"
    },
    {
      "city": "Alpena",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alpena, Michigan"
    },
    {
      "city": "Herron",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Herron, Michigan"
    },
    {
      "city": "Hubbard Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hubbard Lake, Michigan"
    },
    {
      "city": "Lachine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lachine, Michigan"
    },
    {
      "city": "Ossineke",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ossineke, Michigan"
    },
    {
      "city": "Alba",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alba, Michigan"
    },
    {
      "city": "Alden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alden, Michigan"
    },
    {
      "city": "Bellaire",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bellaire, Michigan"
    },
    {
      "city": "Central Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Central Lake, Michigan"
    },
    {
      "city": "Eastport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eastport, Michigan"
    },
    {
      "city": "Elk Rapids",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elk Rapids, Michigan"
    },
    {
      "city": "Kewadin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kewadin, Michigan"
    },
    {
      "city": "Mancelona",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mancelona, Michigan"
    },
    {
      "city": "Ellsworth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ellsworth, Michigan"
    },
    {
      "city": "Standish",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Standish, Michigan"
    },
    {
      "city": "Sterling",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sterling, Michigan"
    },
    {
      "city": "Au Gres",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Au Gres, Michigan"
    },
    {
      "city": "Omer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Omer, Michigan"
    },
    {
      "city": "Turner",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Turner, Michigan"
    },
    {
      "city": "Twining",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Twining, Michigan"
    },
    {
      "city": "Baraga",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Baraga, Michigan"
    },
    {
      "city": "Covington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Covington, Michigan"
    },
    {
      "city": "Lanse",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lanse, Michigan"
    },
    {
      "city": "Skanee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Skanee, Michigan"
    },
    {
      "city": "Watton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Watton, Michigan"
    },
    {
      "city": "Woodland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Woodland, Michigan"
    },
    {
      "city": "Cloverdale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cloverdale, Michigan"
    },
    {
      "city": "Delton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Delton, Michigan"
    },
    {
      "city": "Dowling",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dowling, Michigan"
    },
    {
      "city": "Hastings",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hastings, Michigan"
    },
    {
      "city": "Hickory Corners",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hickory Corners, Michigan"
    },
    {
      "city": "Nashville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nashville, Michigan"
    },
    {
      "city": "Freeport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Freeport, Michigan"
    },
    {
      "city": "Middleville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Middleville, Michigan"
    },
    {
      "city": "Auburn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Auburn, Michigan"
    },
    {
      "city": "Bentley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bentley, Michigan"
    },
    {
      "city": "Kawkawlin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kawkawlin, Michigan"
    },
    {
      "city": "Linwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Linwood, Michigan"
    },
    {
      "city": "Pinconning",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pinconning, Michigan"
    },
    {
      "city": "Bay City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bay City, Michigan"
    },
    {
      "city": "University Center",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "University Center, Michigan"
    },
    {
      "city": "Essexville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Essexville, Michigan"
    },
    {
      "city": "Munger",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Munger, Michigan"
    },
    {
      "city": "Benzonia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Benzonia, Michigan"
    },
    {
      "city": "Beulah",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Beulah, Michigan"
    },
    {
      "city": "Elberta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elberta, Michigan"
    },
    {
      "city": "Frankfort",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Frankfort, Michigan"
    },
    {
      "city": "Honor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Honor, Michigan"
    },
    {
      "city": "Lake Ann",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake Ann, Michigan"
    },
    {
      "city": "Thompsonville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Thompsonville, Michigan"
    },
    {
      "city": "Benton Harbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Benton Harbor, Michigan"
    },
    {
      "city": "Coloma",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Coloma, Michigan"
    },
    {
      "city": "Hagar Shores",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hagar Shores, Michigan"
    },
    {
      "city": "Riverside",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Riverside, Michigan"
    },
    {
      "city": "Saint Joseph",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Joseph, Michigan"
    },
    {
      "city": "Watervliet",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Watervliet, Michigan"
    },
    {
      "city": "Baroda",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Baroda, Michigan"
    },
    {
      "city": "Berrien Center",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Berrien Center, Michigan"
    },
    {
      "city": "Berrien Springs",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Berrien Springs, Michigan"
    },
    {
      "city": "Bridgman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bridgman, Michigan"
    },
    {
      "city": "Buchanan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Buchanan, Michigan"
    },
    {
      "city": "Eau Claire",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eau Claire, Michigan"
    },
    {
      "city": "Galien",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Galien, Michigan"
    },
    {
      "city": "Harbert",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harbert, Michigan"
    },
    {
      "city": "Lakeside",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lakeside, Michigan"
    },
    {
      "city": "New Buffalo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Buffalo, Michigan"
    },
    {
      "city": "New Troy",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Troy, Michigan"
    },
    {
      "city": "Niles",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Niles, Michigan"
    },
    {
      "city": "Sawyer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sawyer, Michigan"
    },
    {
      "city": "Sodus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sodus, Michigan"
    },
    {
      "city": "Stevensville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stevensville, Michigan"
    },
    {
      "city": "Three Oaks",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Three Oaks, Michigan"
    },
    {
      "city": "Union Pier",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Union Pier, Michigan"
    },
    {
      "city": "Bronson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bronson, Michigan"
    },
    {
      "city": "Coldwater",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Coldwater, Michigan"
    },
    {
      "city": "Quincy",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Quincy, Michigan"
    },
    {
      "city": "Sherwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sherwood, Michigan"
    },
    {
      "city": "Union City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Union City, Michigan"
    },
    {
      "city": "Montgomery",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Montgomery, Michigan"
    },
    {
      "city": "Athens",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Athens, Michigan"
    },
    {
      "city": "Battle Creek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Battle Creek, Michigan"
    },
    {
      "city": "Bedford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bedford, Michigan"
    },
    {
      "city": "Burlington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burlington, Michigan"
    },
    {
      "city": "Ceresco",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ceresco, Michigan"
    },
    {
      "city": "East Leroy",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "East Leroy, Michigan"
    },
    {
      "city": "Marshall",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marshall, Michigan"
    },
    {
      "city": "Tekonsha",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tekonsha, Michigan"
    },
    {
      "city": "Albion",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Albion, Michigan"
    },
    {
      "city": "Homer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Homer, Michigan"
    },
    {
      "city": "Cassopolis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cassopolis, Michigan"
    },
    {
      "city": "Dowagiac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dowagiac, Michigan"
    },
    {
      "city": "Jones",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jones, Michigan"
    },
    {
      "city": "Marcellus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marcellus, Michigan"
    },
    {
      "city": "Vandalia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vandalia, Michigan"
    },
    {
      "city": "Edwardsburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Edwardsburg, Michigan"
    },
    {
      "city": "Union",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Union, Michigan"
    },
    {
      "city": "Bay Shore",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bay Shore, Michigan"
    },
    {
      "city": "Boyne City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Boyne City, Michigan"
    },
    {
      "city": "Boyne Falls",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Boyne Falls, Michigan"
    },
    {
      "city": "Charlevoix",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Charlevoix, Michigan"
    },
    {
      "city": "East Jordan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "East Jordan, Michigan"
    },
    {
      "city": "Beaver Island",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Beaver Island, Michigan"
    },
    {
      "city": "Walloon Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Walloon Lake, Michigan"
    },
    {
      "city": "Mackinaw City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mackinaw City, Michigan"
    },
    {
      "city": "Afton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Afton, Michigan"
    },
    {
      "city": "Brutus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brutus, Michigan"
    },
    {
      "city": "Burt Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burt Lake, Michigan"
    },
    {
      "city": "Cheboygan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cheboygan, Michigan"
    },
    {
      "city": "Indian River",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Indian River, Michigan"
    },
    {
      "city": "Mullett Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mullett Lake, Michigan"
    },
    {
      "city": "Topinabee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Topinabee, Michigan"
    },
    {
      "city": "Tower",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tower, Michigan"
    },
    {
      "city": "Wolverine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wolverine, Michigan"
    },
    {
      "city": "Barbeau",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Barbeau, Michigan"
    },
    {
      "city": "Brimley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brimley, Michigan"
    },
    {
      "city": "Dafter",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dafter, Michigan"
    },
    {
      "city": "De Tour Village",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "De Tour Village, Michigan"
    },
    {
      "city": "Drummond Island",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Drummond Island, Michigan"
    },
    {
      "city": "Eckerman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eckerman, Michigan"
    },
    {
      "city": "Goetzville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Goetzville, Michigan"
    },
    {
      "city": "Hulbert",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hulbert, Michigan"
    },
    {
      "city": "Kinross",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kinross, Michigan"
    },
    {
      "city": "Paradise",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Paradise, Michigan"
    },
    {
      "city": "Pickford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pickford, Michigan"
    },
    {
      "city": "Rudyard",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rudyard, Michigan"
    },
    {
      "city": "Sault Sainte Marie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sault Sainte Marie, Michigan"
    },
    {
      "city": "Kincheloe",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kincheloe, Michigan"
    },
    {
      "city": "Trout Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Trout Lake, Michigan"
    },
    {
      "city": "Clare",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clare, Michigan"
    },
    {
      "city": "Farwell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Farwell, Michigan"
    },
    {
      "city": "Harrison",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harrison, Michigan"
    },
    {
      "city": "Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake, Michigan"
    },
    {
      "city": "Lake George",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake George, Michigan"
    },
    {
      "city": "Bath",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bath, Michigan"
    },
    {
      "city": "Dewitt",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dewitt, Michigan"
    },
    {
      "city": "Eagle",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eagle, Michigan"
    },
    {
      "city": "Elsie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elsie, Michigan"
    },
    {
      "city": "Eureka",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eureka, Michigan"
    },
    {
      "city": "Fowler",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fowler, Michigan"
    },
    {
      "city": "Maple Rapids",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Maple Rapids, Michigan"
    },
    {
      "city": "Ovid",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ovid, Michigan"
    },
    {
      "city": "Saint Johns",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Johns, Michigan"
    },
    {
      "city": "Westphalia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Westphalia, Michigan"
    },
    {
      "city": "Frederic",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Frederic, Michigan"
    },
    {
      "city": "Grayling",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grayling, Michigan"
    },
    {
      "city": "Bark River",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bark River, Michigan"
    },
    {
      "city": "Cornell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cornell, Michigan"
    },
    {
      "city": "Escanaba",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Escanaba, Michigan"
    },
    {
      "city": "Garden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Garden, Michigan"
    },
    {
      "city": "Gladstone",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gladstone, Michigan"
    },
    {
      "city": "Nahma",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nahma, Michigan"
    },
    {
      "city": "Perkins",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Perkins, Michigan"
    },
    {
      "city": "Rapid River",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rapid River, Michigan"
    },
    {
      "city": "Rock",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rock, Michigan"
    },
    {
      "city": "Wells",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wells, Michigan"
    },
    {
      "city": "Iron Mountain",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Iron Mountain, Michigan"
    },
    {
      "city": "Kingsford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kingsford, Michigan"
    },
    {
      "city": "Channing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Channing, Michigan"
    },
    {
      "city": "Felch",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Felch, Michigan"
    },
    {
      "city": "Foster City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Foster City, Michigan"
    },
    {
      "city": "Loretto",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Loretto, Michigan"
    },
    {
      "city": "Norway",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Norway, Michigan"
    },
    {
      "city": "Quinnesec",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Quinnesec, Michigan"
    },
    {
      "city": "Ralph",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ralph, Michigan"
    },
    {
      "city": "Sagola",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sagola, Michigan"
    },
    {
      "city": "Vulcan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vulcan, Michigan"
    },
    {
      "city": "Charlotte",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Charlotte, Michigan"
    },
    {
      "city": "Dimondale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dimondale, Michigan"
    },
    {
      "city": "Eaton Rapids",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eaton Rapids, Michigan"
    },
    {
      "city": "Grand Ledge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Ledge, Michigan"
    },
    {
      "city": "Mulliken",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mulliken, Michigan"
    },
    {
      "city": "Potterville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Potterville, Michigan"
    },
    {
      "city": "Sunfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sunfield, Michigan"
    },
    {
      "city": "Lansing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lansing, Michigan"
    },
    {
      "city": "Bellevue",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bellevue, Michigan"
    },
    {
      "city": "Olivet",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Olivet, Michigan"
    },
    {
      "city": "Vermontville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vermontville, Michigan"
    },
    {
      "city": "Alanson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alanson, Michigan"
    },
    {
      "city": "Carp Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carp Lake, Michigan"
    },
    {
      "city": "Conway",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Conway, Michigan"
    },
    {
      "city": "Cross Village",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cross Village, Michigan"
    },
    {
      "city": "Good Hart",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Good Hart, Michigan"
    },
    {
      "city": "Harbor Springs",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harbor Springs, Michigan"
    },
    {
      "city": "Levering",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Levering, Michigan"
    },
    {
      "city": "Oden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oden, Michigan"
    },
    {
      "city": "Pellston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pellston, Michigan"
    },
    {
      "city": "Petoskey",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Petoskey, Michigan"
    },
    {
      "city": "Atlas",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Atlas, Michigan"
    },
    {
      "city": "Clio",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clio, Michigan"
    },
    {
      "city": "Davison",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Davison, Michigan"
    },
    {
      "city": "Fenton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fenton, Michigan"
    },
    {
      "city": "Flushing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Flushing, Michigan"
    },
    {
      "city": "Gaines",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gaines, Michigan"
    },
    {
      "city": "Genesee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Genesee, Michigan"
    },
    {
      "city": "Goodrich",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Goodrich, Michigan"
    },
    {
      "city": "Grand Blanc",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Blanc, Michigan"
    },
    {
      "city": "Lennon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lennon, Michigan"
    },
    {
      "city": "Linden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Linden, Michigan"
    },
    {
      "city": "Montrose",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Montrose, Michigan"
    },
    {
      "city": "Mount Morris",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mount Morris, Michigan"
    },
    {
      "city": "Otisville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Otisville, Michigan"
    },
    {
      "city": "Swartz Creek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Swartz Creek, Michigan"
    },
    {
      "city": "Flint",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Flint, Michigan"
    },
    {
      "city": "Burton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burton, Michigan"
    },
    {
      "city": "Beaverton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Beaverton, Michigan"
    },
    {
      "city": "Gladwin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gladwin, Michigan"
    },
    {
      "city": "Saginaw",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saginaw, Michigan"
    },
    {
      "city": "Rhodes",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rhodes, Michigan"
    },
    {
      "city": "Bessemer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bessemer, Michigan"
    },
    {
      "city": "Ironwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ironwood, Michigan"
    },
    {
      "city": "Marenisco",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marenisco, Michigan"
    },
    {
      "city": "Ramsay",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ramsay, Michigan"
    },
    {
      "city": "Wakefield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wakefield, Michigan"
    },
    {
      "city": "Watersmeet",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Watersmeet, Michigan"
    },
    {
      "city": "Acme",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Acme, Michigan"
    },
    {
      "city": "Fife Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fife Lake, Michigan"
    },
    {
      "city": "Grawn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grawn, Michigan"
    },
    {
      "city": "Interlochen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Interlochen, Michigan"
    },
    {
      "city": "Kingsley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kingsley, Michigan"
    },
    {
      "city": "Mayfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mayfield, Michigan"
    },
    {
      "city": "Old Mission",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Old Mission, Michigan"
    },
    {
      "city": "Traverse City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Traverse City, Michigan"
    },
    {
      "city": "Williamsburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Williamsburg, Michigan"
    },
    {
      "city": "Breckenridge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Breckenridge, Michigan"
    },
    {
      "city": "Wheeler",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wheeler, Michigan"
    },
    {
      "city": "Alma",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alma, Michigan"
    },
    {
      "city": "Ashley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ashley, Michigan"
    },
    {
      "city": "Bannister",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bannister, Michigan"
    },
    {
      "city": "Elm Hall",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elm Hall, Michigan"
    },
    {
      "city": "Elwell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elwell, Michigan"
    },
    {
      "city": "Ithaca",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ithaca, Michigan"
    },
    {
      "city": "Middleton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Middleton, Michigan"
    },
    {
      "city": "North Star",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "North Star, Michigan"
    },
    {
      "city": "Perrinton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Perrinton, Michigan"
    },
    {
      "city": "Pompeii",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pompeii, Michigan"
    },
    {
      "city": "Riverdale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Riverdale, Michigan"
    },
    {
      "city": "Saint Louis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Louis, Michigan"
    },
    {
      "city": "Sumner",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sumner, Michigan"
    },
    {
      "city": "Allen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allen, Michigan"
    },
    {
      "city": "Camden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Camden, Michigan"
    },
    {
      "city": "Frontier",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Frontier, Michigan"
    },
    {
      "city": "Hillsdale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hillsdale, Michigan"
    },
    {
      "city": "Jerome",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jerome, Michigan"
    },
    {
      "city": "Jonesville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jonesville, Michigan"
    },
    {
      "city": "Litchfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Litchfield, Michigan"
    },
    {
      "city": "Moscow",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Moscow, Michigan"
    },
    {
      "city": "Mosherville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mosherville, Michigan"
    },
    {
      "city": "North Adams",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "North Adams, Michigan"
    },
    {
      "city": "Osseo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Osseo, Michigan"
    },
    {
      "city": "Pittsford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pittsford, Michigan"
    },
    {
      "city": "Reading",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Reading, Michigan"
    },
    {
      "city": "Somerset",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Somerset, Michigan"
    },
    {
      "city": "Somerset Center",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Somerset Center, Michigan"
    },
    {
      "city": "Waldron",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Waldron, Michigan"
    },
    {
      "city": "Atlantic Mine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Atlantic Mine, Michigan"
    },
    {
      "city": "Calumet",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Calumet, Michigan"
    },
    {
      "city": "Chassell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chassell, Michigan"
    },
    {
      "city": "Copper City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Copper City, Michigan"
    },
    {
      "city": "Dodgeville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dodgeville, Michigan"
    },
    {
      "city": "Dollar Bay",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dollar Bay, Michigan"
    },
    {
      "city": "Hancock",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hancock, Michigan"
    },
    {
      "city": "Houghton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Houghton, Michigan"
    },
    {
      "city": "Hubbell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hubbell, Michigan"
    },
    {
      "city": "Kearsarge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kearsarge, Michigan"
    },
    {
      "city": "Lake Linden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake Linden, Michigan"
    },
    {
      "city": "Nisula",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nisula, Michigan"
    },
    {
      "city": "Painesdale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Painesdale, Michigan"
    },
    {
      "city": "Pelkie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pelkie, Michigan"
    },
    {
      "city": "Sidnaw",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sidnaw, Michigan"
    },
    {
      "city": "South Range",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Range, Michigan"
    },
    {
      "city": "Toivola",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Toivola, Michigan"
    },
    {
      "city": "Bad Axe",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bad Axe, Michigan"
    },
    {
      "city": "Filion",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Filion, Michigan"
    },
    {
      "city": "Harbor Beach",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harbor Beach, Michigan"
    },
    {
      "city": "Kinde",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kinde, Michigan"
    },
    {
      "city": "Port Austin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Port Austin, Michigan"
    },
    {
      "city": "Port Hope",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Port Hope, Michigan"
    },
    {
      "city": "Ruth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ruth, Michigan"
    },
    {
      "city": "Ubly",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ubly, Michigan"
    },
    {
      "city": "Bay Port",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bay Port, Michigan"
    },
    {
      "city": "Caseville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Caseville, Michigan"
    },
    {
      "city": "Elkton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elkton, Michigan"
    },
    {
      "city": "Owendale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Owendale, Michigan"
    },
    {
      "city": "Pigeon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pigeon, Michigan"
    },
    {
      "city": "Sebewaing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sebewaing, Michigan"
    },
    {
      "city": "Okemos",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Okemos, Michigan"
    },
    {
      "city": "Dansville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dansville, Michigan"
    },
    {
      "city": "East Lansing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "East Lansing, Michigan"
    },
    {
      "city": "Haslett",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Haslett, Michigan"
    },
    {
      "city": "Holt",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Holt, Michigan"
    },
    {
      "city": "Mason",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mason, Michigan"
    },
    {
      "city": "Webberville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Webberville, Michigan"
    },
    {
      "city": "Williamston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Williamston, Michigan"
    },
    {
      "city": "Leslie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Leslie, Michigan"
    },
    {
      "city": "Onondaga",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Onondaga, Michigan"
    },
    {
      "city": "Stockbridge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stockbridge, Michigan"
    },
    {
      "city": "Belding",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Belding, Michigan"
    },
    {
      "city": "Clarksville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clarksville, Michigan"
    },
    {
      "city": "Hubbardston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hubbardston, Michigan"
    },
    {
      "city": "Ionia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ionia, Michigan"
    },
    {
      "city": "Lake Odessa",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake Odessa, Michigan"
    },
    {
      "city": "Lyons",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lyons, Michigan"
    },
    {
      "city": "Muir",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Muir, Michigan"
    },
    {
      "city": "Orleans",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Orleans, Michigan"
    },
    {
      "city": "Palo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Palo, Michigan"
    },
    {
      "city": "Pewamo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pewamo, Michigan"
    },
    {
      "city": "Portland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Portland, Michigan"
    },
    {
      "city": "Saranac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saranac, Michigan"
    },
    {
      "city": "Smyrna",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Smyrna, Michigan"
    },
    {
      "city": "East Tawas",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "East Tawas, Michigan"
    },
    {
      "city": "Hale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hale, Michigan"
    },
    {
      "city": "Long Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Long Lake, Michigan"
    },
    {
      "city": "National City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "National City, Michigan"
    },
    {
      "city": "Oscoda",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oscoda, Michigan"
    },
    {
      "city": "Tawas City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tawas City, Michigan"
    },
    {
      "city": "Whittemore",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Whittemore, Michigan"
    },
    {
      "city": "Alpha",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alpha, Michigan"
    },
    {
      "city": "Amasa",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Amasa, Michigan"
    },
    {
      "city": "Caspian",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Caspian, Michigan"
    },
    {
      "city": "Crystal Falls",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Crystal Falls, Michigan"
    },
    {
      "city": "Gaastra",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gaastra, Michigan"
    },
    {
      "city": "Iron River",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Iron River, Michigan"
    },
    {
      "city": "Stambaugh",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stambaugh, Michigan"
    },
    {
      "city": "Mount Pleasant",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mount Pleasant, Michigan"
    },
    {
      "city": "Rosebush",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rosebush, Michigan"
    },
    {
      "city": "Shepherd",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Shepherd, Michigan"
    },
    {
      "city": "Weidman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Weidman, Michigan"
    },
    {
      "city": "Winn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Winn, Michigan"
    },
    {
      "city": "Blanchard",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Blanchard, Michigan"
    },
    {
      "city": "Jackson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jackson, Michigan"
    },
    {
      "city": "Brooklyn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brooklyn, Michigan"
    },
    {
      "city": "Clarklake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clarklake, Michigan"
    },
    {
      "city": "Concord",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Concord, Michigan"
    },
    {
      "city": "Grass Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grass Lake, Michigan"
    },
    {
      "city": "Hanover",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hanover, Michigan"
    },
    {
      "city": "Horton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Horton, Michigan"
    },
    {
      "city": "Michigan Center",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Michigan Center, Michigan"
    },
    {
      "city": "Munith",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Munith, Michigan"
    },
    {
      "city": "Napoleon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Napoleon, Michigan"
    },
    {
      "city": "Norvell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Norvell, Michigan"
    },
    {
      "city": "Parma",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Parma, Michigan"
    },
    {
      "city": "Pleasant Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pleasant Lake, Michigan"
    },
    {
      "city": "Rives Junction",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rives Junction, Michigan"
    },
    {
      "city": "Spring Arbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Spring Arbor, Michigan"
    },
    {
      "city": "Springport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Springport, Michigan"
    },
    {
      "city": "Kalamazoo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kalamazoo, Michigan"
    },
    {
      "city": "Portage",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Portage, Michigan"
    },
    {
      "city": "Augusta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Augusta, Michigan"
    },
    {
      "city": "Climax",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Climax, Michigan"
    },
    {
      "city": "Comstock",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Comstock, Michigan"
    },
    {
      "city": "Fulton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fulton, Michigan"
    },
    {
      "city": "Galesburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Galesburg, Michigan"
    },
    {
      "city": "Nazareth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nazareth, Michigan"
    },
    {
      "city": "Oshtemo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oshtemo, Michigan"
    },
    {
      "city": "Richland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Richland, Michigan"
    },
    {
      "city": "Schoolcraft",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Schoolcraft, Michigan"
    },
    {
      "city": "Scotts",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Scotts, Michigan"
    },
    {
      "city": "Vicksburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vicksburg, Michigan"
    },
    {
      "city": "Kalkaska",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kalkaska, Michigan"
    },
    {
      "city": "Rapid City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rapid City, Michigan"
    },
    {
      "city": "South Boardman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Boardman, Michigan"
    },
    {
      "city": "Ada",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ada, Michigan"
    },
    {
      "city": "Alto",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alto, Michigan"
    },
    {
      "city": "Belmont",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Belmont, Michigan"
    },
    {
      "city": "Byron Center",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Byron Center, Michigan"
    },
    {
      "city": "Caledonia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Caledonia, Michigan"
    },
    {
      "city": "Cannonsburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cannonsburg, Michigan"
    },
    {
      "city": "Cedar Springs",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cedar Springs, Michigan"
    },
    {
      "city": "Comstock Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Comstock Park, Michigan"
    },
    {
      "city": "Kent City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kent City, Michigan"
    },
    {
      "city": "Lowell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lowell, Michigan"
    },
    {
      "city": "Rockford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rockford, Michigan"
    },
    {
      "city": "Sand Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sand Lake, Michigan"
    },
    {
      "city": "Sparta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sparta, Michigan"
    },
    {
      "city": "Grandville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grandville, Michigan"
    },
    {
      "city": "Grand Rapids",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Rapids, Michigan"
    },
    {
      "city": "Wyoming",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wyoming, Michigan"
    },
    {
      "city": "Allouez",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allouez, Michigan"
    },
    {
      "city": "Ahmeek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ahmeek, Michigan"
    },
    {
      "city": "Copper Harbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Copper Harbor, Michigan"
    },
    {
      "city": "Mohawk",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mohawk, Michigan"
    },
    {
      "city": "Baldwin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Baldwin, Michigan"
    },
    {
      "city": "Branch",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Branch, Michigan"
    },
    {
      "city": "Chase",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chase, Michigan"
    },
    {
      "city": "Idlewild",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Idlewild, Michigan"
    },
    {
      "city": "Irons",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Irons, Michigan"
    },
    {
      "city": "Luther",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Luther, Michigan"
    },
    {
      "city": "Almont",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Almont, Michigan"
    },
    {
      "city": "Attica",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Attica, Michigan"
    },
    {
      "city": "Columbiaville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Columbiaville, Michigan"
    },
    {
      "city": "Dryden",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dryden, Michigan"
    },
    {
      "city": "Hadley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hadley, Michigan"
    },
    {
      "city": "Imlay City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Imlay City, Michigan"
    },
    {
      "city": "Lapeer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lapeer, Michigan"
    },
    {
      "city": "Metamora",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Metamora, Michigan"
    },
    {
      "city": "North Branch",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "North Branch, Michigan"
    },
    {
      "city": "Otter Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Otter Lake, Michigan"
    },
    {
      "city": "Clifford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clifford, Michigan"
    },
    {
      "city": "Silverwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Silverwood, Michigan"
    },
    {
      "city": "Cedar",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cedar, Michigan"
    },
    {
      "city": "Empire",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Empire, Michigan"
    },
    {
      "city": "Glen Arbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Glen Arbor, Michigan"
    },
    {
      "city": "Lake Leelanau",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake Leelanau, Michigan"
    },
    {
      "city": "Leland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Leland, Michigan"
    },
    {
      "city": "Maple City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Maple City, Michigan"
    },
    {
      "city": "Northport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Northport, Michigan"
    },
    {
      "city": "Omena",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Omena, Michigan"
    },
    {
      "city": "Suttons Bay",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Suttons Bay, Michigan"
    },
    {
      "city": "Addison",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Addison, Michigan"
    },
    {
      "city": "Adrian",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Adrian, Michigan"
    },
    {
      "city": "Blissfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Blissfield, Michigan"
    },
    {
      "city": "Britton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Britton, Michigan"
    },
    {
      "city": "Cement City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cement City, Michigan"
    },
    {
      "city": "Clayton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clayton, Michigan"
    },
    {
      "city": "Clinton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clinton, Michigan"
    },
    {
      "city": "Deerfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Deerfield, Michigan"
    },
    {
      "city": "Hudson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hudson, Michigan"
    },
    {
      "city": "Jasper",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jasper, Michigan"
    },
    {
      "city": "Manitou Beach",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Manitou Beach, Michigan"
    },
    {
      "city": "Morenci",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Morenci, Michigan"
    },
    {
      "city": "Onsted",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Onsted, Michigan"
    },
    {
      "city": "Palmyra",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Palmyra, Michigan"
    },
    {
      "city": "Riga",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Riga, Michigan"
    },
    {
      "city": "Sand Creek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sand Creek, Michigan"
    },
    {
      "city": "Tecumseh",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tecumseh, Michigan"
    },
    {
      "city": "Tipton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tipton, Michigan"
    },
    {
      "city": "Weston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Weston, Michigan"
    },
    {
      "city": "Brighton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brighton, Michigan"
    },
    {
      "city": "Gregory",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gregory, Michigan"
    },
    {
      "city": "Hamburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hamburg, Michigan"
    },
    {
      "city": "Lakeland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lakeland, Michigan"
    },
    {
      "city": "Pinckney",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pinckney, Michigan"
    },
    {
      "city": "Hartland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hartland, Michigan"
    },
    {
      "city": "Cohoctah",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cohoctah, Michigan"
    },
    {
      "city": "Fowlerville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fowlerville, Michigan"
    },
    {
      "city": "Howell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Howell, Michigan"
    },
    {
      "city": "Mc Millan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mc Millan, Michigan"
    },
    {
      "city": "Newberry",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Newberry, Michigan"
    },
    {
      "city": "Cedarville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cedarville, Michigan"
    },
    {
      "city": "Hessel",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hessel, Michigan"
    },
    {
      "city": "Mackinac Island",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mackinac Island, Michigan"
    },
    {
      "city": "Moran",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Moran, Michigan"
    },
    {
      "city": "Naubinway",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Naubinway, Michigan"
    },
    {
      "city": "Pointe Aux Pins",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pointe Aux Pins, Michigan"
    },
    {
      "city": "Saint Ignace",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Ignace, Michigan"
    },
    {
      "city": "Curtis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Curtis, Michigan"
    },
    {
      "city": "Engadine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Engadine, Michigan"
    },
    {
      "city": "Gould City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gould City, Michigan"
    },
    {
      "city": "Armada",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Armada, Michigan"
    },
    {
      "city": "Center Line",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Center Line, Michigan"
    },
    {
      "city": "Eastpointe",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eastpointe, Michigan"
    },
    {
      "city": "Fraser",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fraser, Michigan"
    },
    {
      "city": "Clinton Township",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clinton Township, Michigan"
    },
    {
      "city": "Macomb",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Macomb, Michigan"
    },
    {
      "city": "Mount Clemens",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mount Clemens, Michigan"
    },
    {
      "city": "Harrison Township",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harrison Township, Michigan"
    },
    {
      "city": "New Baltimore",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Baltimore, Michigan"
    },
    {
      "city": "New Haven",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Haven, Michigan"
    },
    {
      "city": "Richmond",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Richmond, Michigan"
    },
    {
      "city": "Romeo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Romeo, Michigan"
    },
    {
      "city": "Roseville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Roseville, Michigan"
    },
    {
      "city": "Saint Clair Shores",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Clair Shores, Michigan"
    },
    {
      "city": "Warren",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Warren, Michigan"
    },
    {
      "city": "Washington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Washington, Michigan"
    },
    {
      "city": "Ray",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ray, Michigan"
    },
    {
      "city": "Sterling Heights",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sterling Heights, Michigan"
    },
    {
      "city": "Utica",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Utica, Michigan"
    },
    {
      "city": "Arcadia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Arcadia, Michigan"
    },
    {
      "city": "Bear Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bear Lake, Michigan"
    },
    {
      "city": "Brethren",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brethren, Michigan"
    },
    {
      "city": "Copemish",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Copemish, Michigan"
    },
    {
      "city": "Eastlake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Eastlake, Michigan"
    },
    {
      "city": "Filer City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Filer City, Michigan"
    },
    {
      "city": "Kaleva",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kaleva, Michigan"
    },
    {
      "city": "Manistee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Manistee, Michigan"
    },
    {
      "city": "Onekama",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Onekama, Michigan"
    },
    {
      "city": "Wellston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wellston, Michigan"
    },
    {
      "city": "Big Bay",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Big Bay, Michigan"
    },
    {
      "city": "Champion",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Champion, Michigan"
    },
    {
      "city": "Arnold",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Arnold, Michigan"
    },
    {
      "city": "Little Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Little Lake, Michigan"
    },
    {
      "city": "Gwinn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gwinn, Michigan"
    },
    {
      "city": "Ishpeming",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ishpeming, Michigan"
    },
    {
      "city": "Marquette",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marquette, Michigan"
    },
    {
      "city": "Michigamme",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Michigamme, Michigan"
    },
    {
      "city": "National Mine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "National Mine, Michigan"
    },
    {
      "city": "Negaunee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Negaunee, Michigan"
    },
    {
      "city": "Palmer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Palmer, Michigan"
    },
    {
      "city": "Republic",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Republic, Michigan"
    },
    {
      "city": "Skandia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Skandia, Michigan"
    },
    {
      "city": "Custer",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Custer, Michigan"
    },
    {
      "city": "Fountain",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fountain, Michigan"
    },
    {
      "city": "Free Soil",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Free Soil, Michigan"
    },
    {
      "city": "Ludington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ludington, Michigan"
    },
    {
      "city": "Scottville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Scottville, Michigan"
    },
    {
      "city": "Walhalla",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Walhalla, Michigan"
    },
    {
      "city": "Barryton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Barryton, Michigan"
    },
    {
      "city": "Big Rapids",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Big Rapids, Michigan"
    },
    {
      "city": "Chippewa Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chippewa Lake, Michigan"
    },
    {
      "city": "Mecosta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mecosta, Michigan"
    },
    {
      "city": "Morley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Morley, Michigan"
    },
    {
      "city": "Paris",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Paris, Michigan"
    },
    {
      "city": "Remus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Remus, Michigan"
    },
    {
      "city": "Rodney",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rodney, Michigan"
    },
    {
      "city": "Stanwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stanwood, Michigan"
    },
    {
      "city": "Carney",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carney, Michigan"
    },
    {
      "city": "Daggett",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Daggett, Michigan"
    },
    {
      "city": "Harris",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harris, Michigan"
    },
    {
      "city": "Hermansville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hermansville, Michigan"
    },
    {
      "city": "Ingalls",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ingalls, Michigan"
    },
    {
      "city": "Menominee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Menominee, Michigan"
    },
    {
      "city": "Nadeau",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nadeau, Michigan"
    },
    {
      "city": "Perronville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Perronville, Michigan"
    },
    {
      "city": "Powers",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Powers, Michigan"
    },
    {
      "city": "Spalding",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Spalding, Michigan"
    },
    {
      "city": "Stephenson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stephenson, Michigan"
    },
    {
      "city": "Wallace",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wallace, Michigan"
    },
    {
      "city": "Wilson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wilson, Michigan"
    },
    {
      "city": "Coleman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Coleman, Michigan"
    },
    {
      "city": "Edenville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Edenville, Michigan"
    },
    {
      "city": "Hope",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hope, Michigan"
    },
    {
      "city": "Midland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Midland, Michigan"
    },
    {
      "city": "Sanford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sanford, Michigan"
    },
    {
      "city": "Falmouth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Falmouth, Michigan"
    },
    {
      "city": "Lake City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake City, Michigan"
    },
    {
      "city": "Mc Bain",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mc Bain, Michigan"
    },
    {
      "city": "Merritt",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Merritt, Michigan"
    },
    {
      "city": "Azalia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Azalia, Michigan"
    },
    {
      "city": "Carleton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carleton, Michigan"
    },
    {
      "city": "Dundee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dundee, Michigan"
    },
    {
      "city": "Erie",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Erie, Michigan"
    },
    {
      "city": "Ida",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ida, Michigan"
    },
    {
      "city": "Lambertville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lambertville, Michigan"
    },
    {
      "city": "La Salle",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "La Salle, Michigan"
    },
    {
      "city": "Luna Pier",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Luna Pier, Michigan"
    },
    {
      "city": "Maybee",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Maybee, Michigan"
    },
    {
      "city": "Milan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Milan, Michigan"
    },
    {
      "city": "Monroe",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Monroe, Michigan"
    },
    {
      "city": "Newport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Newport, Michigan"
    },
    {
      "city": "Samaria",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Samaria, Michigan"
    },
    {
      "city": "South Rockwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Rockwood, Michigan"
    },
    {
      "city": "Temperance",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Temperance, Michigan"
    },
    {
      "city": "Ottawa Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ottawa Lake, Michigan"
    },
    {
      "city": "Petersburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Petersburg, Michigan"
    },
    {
      "city": "Carson City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carson City, Michigan"
    },
    {
      "city": "Cedar Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cedar Lake, Michigan"
    },
    {
      "city": "Crystal",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Crystal, Michigan"
    },
    {
      "city": "Edmore",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Edmore, Michigan"
    },
    {
      "city": "Fenwick",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fenwick, Michigan"
    },
    {
      "city": "Greenville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Greenville, Michigan"
    },
    {
      "city": "Lakeview",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lakeview, Michigan"
    },
    {
      "city": "Mcbrides",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mcbrides, Michigan"
    },
    {
      "city": "Sheridan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sheridan, Michigan"
    },
    {
      "city": "Sidney",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sidney, Michigan"
    },
    {
      "city": "Six Lakes",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Six Lakes, Michigan"
    },
    {
      "city": "Stanton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Stanton, Michigan"
    },
    {
      "city": "Vestaburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vestaburg, Michigan"
    },
    {
      "city": "Coral",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Coral, Michigan"
    },
    {
      "city": "Gowen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gowen, Michigan"
    },
    {
      "city": "Howard City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Howard City, Michigan"
    },
    {
      "city": "Pierson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pierson, Michigan"
    },
    {
      "city": "Trufant",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Trufant, Michigan"
    },
    {
      "city": "Atlanta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Atlanta, Michigan"
    },
    {
      "city": "Hillman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hillman, Michigan"
    },
    {
      "city": "Lewiston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lewiston, Michigan"
    },
    {
      "city": "Bailey",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bailey, Michigan"
    },
    {
      "city": "Casnovia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Casnovia, Michigan"
    },
    {
      "city": "Fruitport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fruitport, Michigan"
    },
    {
      "city": "Holton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Holton, Michigan"
    },
    {
      "city": "Montague",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Montague, Michigan"
    },
    {
      "city": "Muskegon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Muskegon, Michigan"
    },
    {
      "city": "Ravenna",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ravenna, Michigan"
    },
    {
      "city": "Twin Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Twin Lake, Michigan"
    },
    {
      "city": "Whitehall",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Whitehall, Michigan"
    },
    {
      "city": "Sylvan Beach",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sylvan Beach, Michigan"
    },
    {
      "city": "Bitely",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bitely, Michigan"
    },
    {
      "city": "Brohman",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brohman, Michigan"
    },
    {
      "city": "Grant",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grant, Michigan"
    },
    {
      "city": "Newaygo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Newaygo, Michigan"
    },
    {
      "city": "White Cloud",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "White Cloud, Michigan"
    },
    {
      "city": "Fremont",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fremont, Michigan"
    },
    {
      "city": "Troy",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Troy, Michigan"
    },
    {
      "city": "Birmingham",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Birmingham, Michigan"
    },
    {
      "city": "Clawson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clawson, Michigan"
    },
    {
      "city": "Franklin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Franklin, Michigan"
    },
    {
      "city": "Hazel Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hazel Park, Michigan"
    },
    {
      "city": "Southfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Southfield, Michigan"
    },
    {
      "city": "Royal Oak",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Royal Oak, Michigan"
    },
    {
      "city": "Pleasant Ridge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pleasant Ridge, Michigan"
    },
    {
      "city": "Huntington Woods",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Huntington Woods, Michigan"
    },
    {
      "city": "Madison Heights",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Madison Heights, Michigan"
    },
    {
      "city": "Berkley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Berkley, Michigan"
    },
    {
      "city": "New Hudson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Hudson, Michigan"
    },
    {
      "city": "South Lyon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Lyon, Michigan"
    },
    {
      "city": "Ferndale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ferndale, Michigan"
    },
    {
      "city": "Oak Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oak Park, Michigan"
    },
    {
      "city": "Bloomfield Hills",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bloomfield Hills, Michigan"
    },
    {
      "city": "Rochester",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rochester, Michigan"
    },
    {
      "city": "Keego Harbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Keego Harbor, Michigan"
    },
    {
      "city": "Auburn Hills",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Auburn Hills, Michigan"
    },
    {
      "city": "West Bloomfield",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "West Bloomfield, Michigan"
    },
    {
      "city": "Waterford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Waterford, Michigan"
    },
    {
      "city": "Drayton Plains",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Drayton Plains, Michigan"
    },
    {
      "city": "Farmington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Farmington, Michigan"
    },
    {
      "city": "Pontiac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pontiac, Michigan"
    },
    {
      "city": "Clarkston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Clarkston, Michigan"
    },
    {
      "city": "Davisburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Davisburg, Michigan"
    },
    {
      "city": "Highland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Highland, Michigan"
    },
    {
      "city": "Lake Orion",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lake Orion, Michigan"
    },
    {
      "city": "Oakland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oakland, Michigan"
    },
    {
      "city": "Lakeville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lakeville, Michigan"
    },
    {
      "city": "Leonard",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Leonard, Michigan"
    },
    {
      "city": "Oxford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oxford, Michigan"
    },
    {
      "city": "Novi",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Novi, Michigan"
    },
    {
      "city": "Milford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Milford, Michigan"
    },
    {
      "city": "Commerce Township",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Commerce Township, Michigan"
    },
    {
      "city": "White Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "White Lake, Michigan"
    },
    {
      "city": "Union Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Union Lake, Michigan"
    },
    {
      "city": "Walled Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Walled Lake, Michigan"
    },
    {
      "city": "Wixom",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wixom, Michigan"
    },
    {
      "city": "Holly",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Holly, Michigan"
    },
    {
      "city": "Ortonville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ortonville, Michigan"
    },
    {
      "city": "Hart",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hart, Michigan"
    },
    {
      "city": "Hesperia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hesperia, Michigan"
    },
    {
      "city": "Mears",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mears, Michigan"
    },
    {
      "city": "New Era",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Era, Michigan"
    },
    {
      "city": "Pentwater",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Pentwater, Michigan"
    },
    {
      "city": "Rothbury",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rothbury, Michigan"
    },
    {
      "city": "Shelby",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Shelby, Michigan"
    },
    {
      "city": "Walkerville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Walkerville, Michigan"
    },
    {
      "city": "Alger",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Alger, Michigan"
    },
    {
      "city": "Lupton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lupton, Michigan"
    },
    {
      "city": "Rose City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rose City, Michigan"
    },
    {
      "city": "West Branch",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "West Branch, Michigan"
    },
    {
      "city": "Prescott",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Prescott, Michigan"
    },
    {
      "city": "South Branch",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Branch, Michigan"
    },
    {
      "city": "Bergland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bergland, Michigan"
    },
    {
      "city": "Bruce Crossing",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bruce Crossing, Michigan"
    },
    {
      "city": "Ewen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ewen, Michigan"
    },
    {
      "city": "Greenland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Greenland, Michigan"
    },
    {
      "city": "Mass City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mass City, Michigan"
    },
    {
      "city": "Ontonagon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ontonagon, Michigan"
    },
    {
      "city": "Rockland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rockland, Michigan"
    },
    {
      "city": "Trout Creek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Trout Creek, Michigan"
    },
    {
      "city": "White Pine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "White Pine, Michigan"
    },
    {
      "city": "Evart",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Evart, Michigan"
    },
    {
      "city": "Hersey",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hersey, Michigan"
    },
    {
      "city": "Leroy",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Leroy, Michigan"
    },
    {
      "city": "Marion",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marion, Michigan"
    },
    {
      "city": "Reed City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Reed City, Michigan"
    },
    {
      "city": "Sears",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sears, Michigan"
    },
    {
      "city": "Tustin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Tustin, Michigan"
    },
    {
      "city": "Comins",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Comins, Michigan"
    },
    {
      "city": "Fairview",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fairview, Michigan"
    },
    {
      "city": "Luzerne",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Luzerne, Michigan"
    },
    {
      "city": "Mio",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mio, Michigan"
    },
    {
      "city": "Elmira",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Elmira, Michigan"
    },
    {
      "city": "Gaylord",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gaylord, Michigan"
    },
    {
      "city": "Johannesburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Johannesburg, Michigan"
    },
    {
      "city": "Vanderbilt",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vanderbilt, Michigan"
    },
    {
      "city": "Waters",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Waters, Michigan"
    },
    {
      "city": "Allendale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allendale, Michigan"
    },
    {
      "city": "Conklin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Conklin, Michigan"
    },
    {
      "city": "Coopersville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Coopersville, Michigan"
    },
    {
      "city": "Ferrysburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ferrysburg, Michigan"
    },
    {
      "city": "Grand Haven",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Haven, Michigan"
    },
    {
      "city": "Holland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Holland, Michigan"
    },
    {
      "city": "Hudsonville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hudsonville, Michigan"
    },
    {
      "city": "Jamestown",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jamestown, Michigan"
    },
    {
      "city": "Jenison",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jenison, Michigan"
    },
    {
      "city": "Lamont",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lamont, Michigan"
    },
    {
      "city": "Macatawa",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Macatawa, Michigan"
    },
    {
      "city": "Marne",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marne, Michigan"
    },
    {
      "city": "Nunica",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nunica, Michigan"
    },
    {
      "city": "Spring Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Spring Lake, Michigan"
    },
    {
      "city": "West Olive",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "West Olive, Michigan"
    },
    {
      "city": "Zeeland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Zeeland, Michigan"
    },
    {
      "city": "Hawks",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hawks, Michigan"
    },
    {
      "city": "Millersburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Millersburg, Michigan"
    },
    {
      "city": "Onaway",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Onaway, Michigan"
    },
    {
      "city": "Posen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Posen, Michigan"
    },
    {
      "city": "Presque Isle",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Presque Isle, Michigan"
    },
    {
      "city": "Rogers City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rogers City, Michigan"
    },
    {
      "city": "Higgins Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Higgins Lake, Michigan"
    },
    {
      "city": "Houghton Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Houghton Lake, Michigan"
    },
    {
      "city": "Houghton Lake Heights",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Houghton Lake Heights, Michigan"
    },
    {
      "city": "Prudenville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Prudenville, Michigan"
    },
    {
      "city": "Roscommon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Roscommon, Michigan"
    },
    {
      "city": "Saint Helen",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Helen, Michigan"
    },
    {
      "city": "Birch Run",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Birch Run, Michigan"
    },
    {
      "city": "Burt",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burt, Michigan"
    },
    {
      "city": "Brant",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brant, Michigan"
    },
    {
      "city": "Chesaning",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chesaning, Michigan"
    },
    {
      "city": "Freeland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Freeland, Michigan"
    },
    {
      "city": "Hemlock",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hemlock, Michigan"
    },
    {
      "city": "Merrill",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Merrill, Michigan"
    },
    {
      "city": "Oakley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Oakley, Michigan"
    },
    {
      "city": "Saint Charles",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Charles, Michigan"
    },
    {
      "city": "Bridgeport",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bridgeport, Michigan"
    },
    {
      "city": "Carrollton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carrollton, Michigan"
    },
    {
      "city": "Frankenmuth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Frankenmuth, Michigan"
    },
    {
      "city": "Algonac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Algonac, Michigan"
    },
    {
      "city": "Allenton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allenton, Michigan"
    },
    {
      "city": "Anchorville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Anchorville, Michigan"
    },
    {
      "city": "Avoca",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Avoca, Michigan"
    },
    {
      "city": "Capac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Capac, Michigan"
    },
    {
      "city": "Emmett",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Emmett, Michigan"
    },
    {
      "city": "Fair Haven",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fair Haven, Michigan"
    },
    {
      "city": "Goodells",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Goodells, Michigan"
    },
    {
      "city": "Harsens Island",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harsens Island, Michigan"
    },
    {
      "city": "Jeddo",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Jeddo, Michigan"
    },
    {
      "city": "Marine City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marine City, Michigan"
    },
    {
      "city": "Marysville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marysville, Michigan"
    },
    {
      "city": "Memphis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Memphis, Michigan"
    },
    {
      "city": "North Street",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "North Street, Michigan"
    },
    {
      "city": "East China",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "East China, Michigan"
    },
    {
      "city": "Fort Gratiot",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fort Gratiot, Michigan"
    },
    {
      "city": "Port Huron",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Port Huron, Michigan"
    },
    {
      "city": "Columbus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Columbus, Michigan"
    },
    {
      "city": "Casco",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Casco, Michigan"
    },
    {
      "city": "Smiths Creek",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Smiths Creek, Michigan"
    },
    {
      "city": "Saint Clair",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saint Clair, Michigan"
    },
    {
      "city": "Yale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Yale, Michigan"
    },
    {
      "city": "Burr Oak",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Burr Oak, Michigan"
    },
    {
      "city": "Centreville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Centreville, Michigan"
    },
    {
      "city": "Colon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Colon, Michigan"
    },
    {
      "city": "Constantine",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Constantine, Michigan"
    },
    {
      "city": "Leonidas",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Leonidas, Michigan"
    },
    {
      "city": "Mendon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mendon, Michigan"
    },
    {
      "city": "Nottawa",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Nottawa, Michigan"
    },
    {
      "city": "Sturgis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sturgis, Michigan"
    },
    {
      "city": "Three Rivers",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Three Rivers, Michigan"
    },
    {
      "city": "White Pigeon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "White Pigeon, Michigan"
    },
    {
      "city": "Applegate",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Applegate, Michigan"
    },
    {
      "city": "Argyle",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Argyle, Michigan"
    },
    {
      "city": "Brown City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Brown City, Michigan"
    },
    {
      "city": "Carsonville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Carsonville, Michigan"
    },
    {
      "city": "Croswell",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Croswell, Michigan"
    },
    {
      "city": "Decker",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Decker, Michigan"
    },
    {
      "city": "Deckerville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Deckerville, Michigan"
    },
    {
      "city": "Forestville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Forestville, Michigan"
    },
    {
      "city": "Lexington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lexington, Michigan"
    },
    {
      "city": "Marlette",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Marlette, Michigan"
    },
    {
      "city": "Melvin",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Melvin, Michigan"
    },
    {
      "city": "Minden City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Minden City, Michigan"
    },
    {
      "city": "Palms",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Palms, Michigan"
    },
    {
      "city": "Peck",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Peck, Michigan"
    },
    {
      "city": "Port Sanilac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Port Sanilac, Michigan"
    },
    {
      "city": "Sandusky",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Sandusky, Michigan"
    },
    {
      "city": "Snover",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Snover, Michigan"
    },
    {
      "city": "Cooks",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cooks, Michigan"
    },
    {
      "city": "Germfask",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Germfask, Michigan"
    },
    {
      "city": "Gulliver",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gulliver, Michigan"
    },
    {
      "city": "Manistique",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Manistique, Michigan"
    },
    {
      "city": "Seney",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Seney, Michigan"
    },
    {
      "city": "Bancroft",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bancroft, Michigan"
    },
    {
      "city": "Byron",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Byron, Michigan"
    },
    {
      "city": "Durand",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Durand, Michigan"
    },
    {
      "city": "New Lothrop",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Lothrop, Michigan"
    },
    {
      "city": "Vernon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vernon, Michigan"
    },
    {
      "city": "Corunna",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Corunna, Michigan"
    },
    {
      "city": "Henderson",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Henderson, Michigan"
    },
    {
      "city": "Laingsburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Laingsburg, Michigan"
    },
    {
      "city": "Morrice",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Morrice, Michigan"
    },
    {
      "city": "Owosso",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Owosso, Michigan"
    },
    {
      "city": "Perry",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Perry, Michigan"
    },
    {
      "city": "Shaftsburg",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Shaftsburg, Michigan"
    },
    {
      "city": "Fostoria",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fostoria, Michigan"
    },
    {
      "city": "Akron",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Akron, Michigan"
    },
    {
      "city": "Caro",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Caro, Michigan"
    },
    {
      "city": "Cass City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cass City, Michigan"
    },
    {
      "city": "Deford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Deford, Michigan"
    },
    {
      "city": "Fairgrove",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Fairgrove, Michigan"
    },
    {
      "city": "Gagetown",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gagetown, Michigan"
    },
    {
      "city": "Kingston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kingston, Michigan"
    },
    {
      "city": "Mayville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mayville, Michigan"
    },
    {
      "city": "Millington",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Millington, Michigan"
    },
    {
      "city": "Reese",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Reese, Michigan"
    },
    {
      "city": "Richville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Richville, Michigan"
    },
    {
      "city": "Unionville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Unionville, Michigan"
    },
    {
      "city": "Vassar",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Vassar, Michigan"
    },
    {
      "city": "Bangor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bangor, Michigan"
    },
    {
      "city": "Bloomingdale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bloomingdale, Michigan"
    },
    {
      "city": "Breedsville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Breedsville, Michigan"
    },
    {
      "city": "Covert",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Covert, Michigan"
    },
    {
      "city": "Decatur",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Decatur, Michigan"
    },
    {
      "city": "Gobles",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Gobles, Michigan"
    },
    {
      "city": "Grand Junction",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grand Junction, Michigan"
    },
    {
      "city": "Hartford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hartford, Michigan"
    },
    {
      "city": "Kendall",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Kendall, Michigan"
    },
    {
      "city": "Lacota",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lacota, Michigan"
    },
    {
      "city": "Lawrence",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lawrence, Michigan"
    },
    {
      "city": "Lawton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lawton, Michigan"
    },
    {
      "city": "Mattawan",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mattawan, Michigan"
    },
    {
      "city": "Paw Paw",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Paw Paw, Michigan"
    },
    {
      "city": "South Haven",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "South Haven, Michigan"
    },
    {
      "city": "Ann Arbor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ann Arbor, Michigan"
    },
    {
      "city": "Bridgewater",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Bridgewater, Michigan"
    },
    {
      "city": "Chelsea",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Chelsea, Michigan"
    },
    {
      "city": "Dexter",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dexter, Michigan"
    },
    {
      "city": "Manchester",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Manchester, Michigan"
    },
    {
      "city": "Salem",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Salem, Michigan"
    },
    {
      "city": "Saline",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Saline, Michigan"
    },
    {
      "city": "Whitmore Lake",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Whitmore Lake, Michigan"
    },
    {
      "city": "Whittaker",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Whittaker, Michigan"
    },
    {
      "city": "Willis",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Willis, Michigan"
    },
    {
      "city": "Ypsilanti",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ypsilanti, Michigan"
    },
    {
      "city": "Allen Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Allen Park, Michigan"
    },
    {
      "city": "Belleville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Belleville, Michigan"
    },
    {
      "city": "Dearborn",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dearborn, Michigan"
    },
    {
      "city": "Melvindale",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Melvindale, Michigan"
    },
    {
      "city": "Dearborn Heights",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Dearborn Heights, Michigan"
    },
    {
      "city": "Flat Rock",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Flat Rock, Michigan"
    },
    {
      "city": "Garden City",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Garden City, Michigan"
    },
    {
      "city": "Grosse Ile",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grosse Ile, Michigan"
    },
    {
      "city": "Inkster",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Inkster, Michigan"
    },
    {
      "city": "Lincoln Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Lincoln Park, Michigan"
    },
    {
      "city": "Livonia",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Livonia, Michigan"
    },
    {
      "city": "New Boston",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "New Boston, Michigan"
    },
    {
      "city": "Northville",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Northville, Michigan"
    },
    {
      "city": "Plymouth",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Plymouth, Michigan"
    },
    {
      "city": "Rockwood",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Rockwood, Michigan"
    },
    {
      "city": "Romulus",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Romulus, Michigan"
    },
    {
      "city": "Taylor",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Taylor, Michigan"
    },
    {
      "city": "Trenton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Trenton, Michigan"
    },
    {
      "city": "Wayne",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wayne, Michigan"
    },
    {
      "city": "Westland",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Westland, Michigan"
    },
    {
      "city": "Canton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Canton, Michigan"
    },
    {
      "city": "Wyandotte",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Wyandotte, Michigan"
    },
    {
      "city": "Riverview",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Riverview, Michigan"
    },
    {
      "city": "Southgate",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Southgate, Michigan"
    },
    {
      "city": "Detroit",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Detroit, Michigan"
    },
    {
      "city": "Highland Park",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Highland Park, Michigan"
    },
    {
      "city": "Hamtramck",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Hamtramck, Michigan"
    },
    {
      "city": "River Rouge",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "River Rouge, Michigan"
    },
    {
      "city": "Harper Woods",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harper Woods, Michigan"
    },
    {
      "city": "Ecorse",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Ecorse, Michigan"
    },
    {
      "city": "Grosse Pointe",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Grosse Pointe, Michigan"
    },
    {
      "city": "Redford",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Redford, Michigan"
    },
    {
      "city": "Cadillac",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Cadillac, Michigan"
    },
    {
      "city": "Boon",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Boon, Michigan"
    },
    {
      "city": "Buckley",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Buckley, Michigan"
    },
    {
      "city": "Harrietta",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Harrietta, Michigan"
    },
    {
      "city": "Manton",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Manton, Michigan"
    },
    {
      "city": "Mesick",
      "state": "Michigan",
      "shortState": "MI",
      "formatedCity": "Mesick, Michigan"
    },
    {
      "city": "Hill City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hill City, Minnesota"
    },
    {
      "city": "Jacobson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jacobson, Minnesota"
    },
    {
      "city": "Mcgregor",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mcgregor, Minnesota"
    },
    {
      "city": "Swatara",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Swatara, Minnesota"
    },
    {
      "city": "Tamarack",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tamarack, Minnesota"
    },
    {
      "city": "Mc Grath",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mc Grath, Minnesota"
    },
    {
      "city": "Aitkin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Aitkin, Minnesota"
    },
    {
      "city": "Palisade",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Palisade, Minnesota"
    },
    {
      "city": "Bethel",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bethel, Minnesota"
    },
    {
      "city": "Cedar",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cedar, Minnesota"
    },
    {
      "city": "Circle Pines",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Circle Pines, Minnesota"
    },
    {
      "city": "Saint Francis",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Francis, Minnesota"
    },
    {
      "city": "Anoka",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Anoka, Minnesota"
    },
    {
      "city": "Andover",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Andover, Minnesota"
    },
    {
      "city": "Minneapolis",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minneapolis, Minnesota"
    },
    {
      "city": "Detroit Lakes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Detroit Lakes, Minnesota"
    },
    {
      "city": "Audubon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Audubon, Minnesota"
    },
    {
      "city": "Callaway",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Callaway, Minnesota"
    },
    {
      "city": "Frazee",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Frazee, Minnesota"
    },
    {
      "city": "Lake Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Park, Minnesota"
    },
    {
      "city": "Ogema",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ogema, Minnesota"
    },
    {
      "city": "Osage",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Osage, Minnesota"
    },
    {
      "city": "Ponsford",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ponsford, Minnesota"
    },
    {
      "city": "Richwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Richwood, Minnesota"
    },
    {
      "city": "Rochert",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rochert, Minnesota"
    },
    {
      "city": "White Earth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "White Earth, Minnesota"
    },
    {
      "city": "Wolf Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wolf Lake, Minnesota"
    },
    {
      "city": "Bemidji",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bemidji, Minnesota"
    },
    {
      "city": "Blackduck",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Blackduck, Minnesota"
    },
    {
      "city": "Hines",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hines, Minnesota"
    },
    {
      "city": "Kelliher",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kelliher, Minnesota"
    },
    {
      "city": "Pennington",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pennington, Minnesota"
    },
    {
      "city": "Ponemah",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ponemah, Minnesota"
    },
    {
      "city": "Puposky",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Puposky, Minnesota"
    },
    {
      "city": "Redby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Redby, Minnesota"
    },
    {
      "city": "Redlake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Redlake, Minnesota"
    },
    {
      "city": "Solway",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Solway, Minnesota"
    },
    {
      "city": "Tenstrike",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tenstrike, Minnesota"
    },
    {
      "city": "Waskish",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waskish, Minnesota"
    },
    {
      "city": "Wilton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wilton, Minnesota"
    },
    {
      "city": "Foley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Foley, Minnesota"
    },
    {
      "city": "Gilman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gilman, Minnesota"
    },
    {
      "city": "Oak Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Oak Park, Minnesota"
    },
    {
      "city": "Rice",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rice, Minnesota"
    },
    {
      "city": "Sauk Rapids",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sauk Rapids, Minnesota"
    },
    {
      "city": "Barry",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Barry, Minnesota"
    },
    {
      "city": "Beardsley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Beardsley, Minnesota"
    },
    {
      "city": "Clinton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clinton, Minnesota"
    },
    {
      "city": "Correll",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Correll, Minnesota"
    },
    {
      "city": "Graceville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Graceville, Minnesota"
    },
    {
      "city": "Odessa",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Odessa, Minnesota"
    },
    {
      "city": "Ortonville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ortonville, Minnesota"
    },
    {
      "city": "Mankato",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mankato, Minnesota"
    },
    {
      "city": "Amboy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Amboy, Minnesota"
    },
    {
      "city": "Eagle Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eagle Lake, Minnesota"
    },
    {
      "city": "Garden City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Garden City, Minnesota"
    },
    {
      "city": "Good Thunder",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Good Thunder, Minnesota"
    },
    {
      "city": "Lake Crystal",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Crystal, Minnesota"
    },
    {
      "city": "Madison Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Madison Lake, Minnesota"
    },
    {
      "city": "Mapleton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mapleton, Minnesota"
    },
    {
      "city": "Pemberton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pemberton, Minnesota"
    },
    {
      "city": "Saint Clair",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Clair, Minnesota"
    },
    {
      "city": "Vernon Center",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Vernon Center, Minnesota"
    },
    {
      "city": "Comfrey",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Comfrey, Minnesota"
    },
    {
      "city": "Essig",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Essig, Minnesota"
    },
    {
      "city": "Hanska",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hanska, Minnesota"
    },
    {
      "city": "New Ulm",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Ulm, Minnesota"
    },
    {
      "city": "Searles",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Searles, Minnesota"
    },
    {
      "city": "Sleepy Eye",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sleepy Eye, Minnesota"
    },
    {
      "city": "Springfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Springfield, Minnesota"
    },
    {
      "city": "Barnum",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Barnum, Minnesota"
    },
    {
      "city": "Carlton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Carlton, Minnesota"
    },
    {
      "city": "Cloquet",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cloquet, Minnesota"
    },
    {
      "city": "Cromwell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cromwell, Minnesota"
    },
    {
      "city": "Esko",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Esko, Minnesota"
    },
    {
      "city": "Holyoke",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Holyoke, Minnesota"
    },
    {
      "city": "Kettle River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kettle River, Minnesota"
    },
    {
      "city": "Moose Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Moose Lake, Minnesota"
    },
    {
      "city": "Sawyer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sawyer, Minnesota"
    },
    {
      "city": "Wrenshall",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wrenshall, Minnesota"
    },
    {
      "city": "Wright",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wright, Minnesota"
    },
    {
      "city": "Carver",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Carver, Minnesota"
    },
    {
      "city": "Chanhassen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chanhassen, Minnesota"
    },
    {
      "city": "Chaska",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chaska, Minnesota"
    },
    {
      "city": "Cologne",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cologne, Minnesota"
    },
    {
      "city": "Hamburg",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hamburg, Minnesota"
    },
    {
      "city": "Mayer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mayer, Minnesota"
    },
    {
      "city": "New Germany",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Germany, Minnesota"
    },
    {
      "city": "Norwood Young America",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Norwood Young America, Minnesota"
    },
    {
      "city": "Norwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Norwood, Minnesota"
    },
    {
      "city": "Victoria",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Victoria, Minnesota"
    },
    {
      "city": "Waconia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waconia, Minnesota"
    },
    {
      "city": "Watertown",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Watertown, Minnesota"
    },
    {
      "city": "Young America",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Young America, Minnesota"
    },
    {
      "city": "Ah Gwah Ching",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ah Gwah Ching, Minnesota"
    },
    {
      "city": "Backus",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Backus, Minnesota"
    },
    {
      "city": "Hackensack",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hackensack, Minnesota"
    },
    {
      "city": "Pillager",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pillager, Minnesota"
    },
    {
      "city": "Pine River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pine River, Minnesota"
    },
    {
      "city": "Walker",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Walker, Minnesota"
    },
    {
      "city": "Bena",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bena, Minnesota"
    },
    {
      "city": "Cass Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cass Lake, Minnesota"
    },
    {
      "city": "Federal Dam",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Federal Dam, Minnesota"
    },
    {
      "city": "Longville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Longville, Minnesota"
    },
    {
      "city": "Outing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Outing, Minnesota"
    },
    {
      "city": "Remer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Remer, Minnesota"
    },
    {
      "city": "Clara City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clara City, Minnesota"
    },
    {
      "city": "Maynard",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Maynard, Minnesota"
    },
    {
      "city": "Milan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Milan, Minnesota"
    },
    {
      "city": "Montevideo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Montevideo, Minnesota"
    },
    {
      "city": "Watson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Watson, Minnesota"
    },
    {
      "city": "Almelund",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Almelund, Minnesota"
    },
    {
      "city": "Center City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Center City, Minnesota"
    },
    {
      "city": "Chisago City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chisago City, Minnesota"
    },
    {
      "city": "Harris",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Harris, Minnesota"
    },
    {
      "city": "Lindstrom",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lindstrom, Minnesota"
    },
    {
      "city": "North Branch",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "North Branch, Minnesota"
    },
    {
      "city": "Rush City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rush City, Minnesota"
    },
    {
      "city": "Shafer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Shafer, Minnesota"
    },
    {
      "city": "Stacy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stacy, Minnesota"
    },
    {
      "city": "Taylors Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Taylors Falls, Minnesota"
    },
    {
      "city": "Wyoming",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wyoming, Minnesota"
    },
    {
      "city": "Barnesville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Barnesville, Minnesota"
    },
    {
      "city": "Comstock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Comstock, Minnesota"
    },
    {
      "city": "Dilworth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dilworth, Minnesota"
    },
    {
      "city": "Felton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Felton, Minnesota"
    },
    {
      "city": "Georgetown",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Georgetown, Minnesota"
    },
    {
      "city": "Glyndon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Glyndon, Minnesota"
    },
    {
      "city": "Hawley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hawley, Minnesota"
    },
    {
      "city": "Hitterdal",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hitterdal, Minnesota"
    },
    {
      "city": "Moorhead",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Moorhead, Minnesota"
    },
    {
      "city": "Sabin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sabin, Minnesota"
    },
    {
      "city": "Ulen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ulen, Minnesota"
    },
    {
      "city": "Bagley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bagley, Minnesota"
    },
    {
      "city": "Clearbrook",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clearbrook, Minnesota"
    },
    {
      "city": "Gonvick",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gonvick, Minnesota"
    },
    {
      "city": "Leonard",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Leonard, Minnesota"
    },
    {
      "city": "Shevlin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Shevlin, Minnesota"
    },
    {
      "city": "Grand Marais",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grand Marais, Minnesota"
    },
    {
      "city": "Grand Portage",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grand Portage, Minnesota"
    },
    {
      "city": "Hovland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hovland, Minnesota"
    },
    {
      "city": "Lutsen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lutsen, Minnesota"
    },
    {
      "city": "Schroeder",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Schroeder, Minnesota"
    },
    {
      "city": "Tofte",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tofte, Minnesota"
    },
    {
      "city": "Windom",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Windom, Minnesota"
    },
    {
      "city": "Bingham Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bingham Lake, Minnesota"
    },
    {
      "city": "Jeffers",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jeffers, Minnesota"
    },
    {
      "city": "Mountain Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mountain Lake, Minnesota"
    },
    {
      "city": "Storden",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Storden, Minnesota"
    },
    {
      "city": "Westbrook",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Westbrook, Minnesota"
    },
    {
      "city": "Brainerd",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brainerd, Minnesota"
    },
    {
      "city": "Baxter",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Baxter, Minnesota"
    },
    {
      "city": "Crosby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Crosby, Minnesota"
    },
    {
      "city": "Crosslake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Crosslake, Minnesota"
    },
    {
      "city": "Deerwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Deerwood, Minnesota"
    },
    {
      "city": "Emily",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Emily, Minnesota"
    },
    {
      "city": "Fifty Lakes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fifty Lakes, Minnesota"
    },
    {
      "city": "Fort Ripley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fort Ripley, Minnesota"
    },
    {
      "city": "Garrison",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Garrison, Minnesota"
    },
    {
      "city": "Ironton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ironton, Minnesota"
    },
    {
      "city": "Jenkins",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jenkins, Minnesota"
    },
    {
      "city": "Lake Hubert",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Hubert, Minnesota"
    },
    {
      "city": "Merrifield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Merrifield, Minnesota"
    },
    {
      "city": "Nisswa",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nisswa, Minnesota"
    },
    {
      "city": "Pequot Lakes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pequot Lakes, Minnesota"
    },
    {
      "city": "Castle Rock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Castle Rock, Minnesota"
    },
    {
      "city": "Farmington",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Farmington, Minnesota"
    },
    {
      "city": "Hampton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hampton, Minnesota"
    },
    {
      "city": "Hastings",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hastings, Minnesota"
    },
    {
      "city": "Lakeville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lakeville, Minnesota"
    },
    {
      "city": "Randolph",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Randolph, Minnesota"
    },
    {
      "city": "Rosemount",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rosemount, Minnesota"
    },
    {
      "city": "South Saint Paul",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "South Saint Paul, Minnesota"
    },
    {
      "city": "Inver Grove Heights",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Inver Grove Heights, Minnesota"
    },
    {
      "city": "Vermillion",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Vermillion, Minnesota"
    },
    {
      "city": "Saint Paul",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Paul, Minnesota"
    },
    {
      "city": "Mendota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mendota, Minnesota"
    },
    {
      "city": "Burnsville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Burnsville, Minnesota"
    },
    {
      "city": "Claremont",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Claremont, Minnesota"
    },
    {
      "city": "Dodge Center",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dodge Center, Minnesota"
    },
    {
      "city": "Hayfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hayfield, Minnesota"
    },
    {
      "city": "Kasson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kasson, Minnesota"
    },
    {
      "city": "Mantorville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mantorville, Minnesota"
    },
    {
      "city": "West Concord",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "West Concord, Minnesota"
    },
    {
      "city": "Alexandria",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alexandria, Minnesota"
    },
    {
      "city": "Brandon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brandon, Minnesota"
    },
    {
      "city": "Carlos",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Carlos, Minnesota"
    },
    {
      "city": "Evansville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Evansville, Minnesota"
    },
    {
      "city": "Garfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Garfield, Minnesota"
    },
    {
      "city": "Holmes City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Holmes City, Minnesota"
    },
    {
      "city": "Kensington",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kensington, Minnesota"
    },
    {
      "city": "Miltona",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Miltona, Minnesota"
    },
    {
      "city": "Nelson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nelson, Minnesota"
    },
    {
      "city": "Osakis",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Osakis, Minnesota"
    },
    {
      "city": "Blue Earth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Blue Earth, Minnesota"
    },
    {
      "city": "Bricelyn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bricelyn, Minnesota"
    },
    {
      "city": "Delavan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Delavan, Minnesota"
    },
    {
      "city": "Easton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Easton, Minnesota"
    },
    {
      "city": "Elmore",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elmore, Minnesota"
    },
    {
      "city": "Frost",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Frost, Minnesota"
    },
    {
      "city": "Huntley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Huntley, Minnesota"
    },
    {
      "city": "Kiester",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kiester, Minnesota"
    },
    {
      "city": "Minnesota Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minnesota Lake, Minnesota"
    },
    {
      "city": "Wells",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wells, Minnesota"
    },
    {
      "city": "Winnebago",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winnebago, Minnesota"
    },
    {
      "city": "Canton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Canton, Minnesota"
    },
    {
      "city": "Chatfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chatfield, Minnesota"
    },
    {
      "city": "Fountain",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fountain, Minnesota"
    },
    {
      "city": "Harmony",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Harmony, Minnesota"
    },
    {
      "city": "Lanesboro",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lanesboro, Minnesota"
    },
    {
      "city": "Mabel",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mabel, Minnesota"
    },
    {
      "city": "Ostrander",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ostrander, Minnesota"
    },
    {
      "city": "Peterson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Peterson, Minnesota"
    },
    {
      "city": "Preston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Preston, Minnesota"
    },
    {
      "city": "Rushford",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rushford, Minnesota"
    },
    {
      "city": "Spring Valley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Spring Valley, Minnesota"
    },
    {
      "city": "Wykoff",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wykoff, Minnesota"
    },
    {
      "city": "Albert Lea",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Albert Lea, Minnesota"
    },
    {
      "city": "Alden",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alden, Minnesota"
    },
    {
      "city": "Clarks Grove",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clarks Grove, Minnesota"
    },
    {
      "city": "Conger",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Conger, Minnesota"
    },
    {
      "city": "Emmons",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Emmons, Minnesota"
    },
    {
      "city": "Freeborn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Freeborn, Minnesota"
    },
    {
      "city": "Geneva",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Geneva, Minnesota"
    },
    {
      "city": "Glenville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Glenville, Minnesota"
    },
    {
      "city": "Hartland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hartland, Minnesota"
    },
    {
      "city": "Hayward",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hayward, Minnesota"
    },
    {
      "city": "Hollandale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hollandale, Minnesota"
    },
    {
      "city": "Twin Lakes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Twin Lakes, Minnesota"
    },
    {
      "city": "Cannon Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cannon Falls, Minnesota"
    },
    {
      "city": "Dennison",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dennison, Minnesota"
    },
    {
      "city": "Frontenac",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Frontenac, Minnesota"
    },
    {
      "city": "Goodhue",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Goodhue, Minnesota"
    },
    {
      "city": "Red Wing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Red Wing, Minnesota"
    },
    {
      "city": "Welch",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Welch, Minnesota"
    },
    {
      "city": "Kenyon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kenyon, Minnesota"
    },
    {
      "city": "Pine Island",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pine Island, Minnesota"
    },
    {
      "city": "Wanamingo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wanamingo, Minnesota"
    },
    {
      "city": "Zumbrota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Zumbrota, Minnesota"
    },
    {
      "city": "Herman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Herman, Minnesota"
    },
    {
      "city": "Norcross",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Norcross, Minnesota"
    },
    {
      "city": "Ashby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ashby, Minnesota"
    },
    {
      "city": "Barrett",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Barrett, Minnesota"
    },
    {
      "city": "Hoffman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hoffman, Minnesota"
    },
    {
      "city": "Elbow Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elbow Lake, Minnesota"
    },
    {
      "city": "Wendell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wendell, Minnesota"
    },
    {
      "city": "Hopkins",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hopkins, Minnesota"
    },
    {
      "city": "Maple Grove",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Maple Grove, Minnesota"
    },
    {
      "city": "Champlin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Champlin, Minnesota"
    },
    {
      "city": "Crystal Bay",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Crystal Bay, Minnesota"
    },
    {
      "city": "Dayton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dayton, Minnesota"
    },
    {
      "city": "Excelsior",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Excelsior, Minnesota"
    },
    {
      "city": "Hamel",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hamel, Minnesota"
    },
    {
      "city": "Eden Prairie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eden Prairie, Minnesota"
    },
    {
      "city": "Minnetonka",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minnetonka, Minnesota"
    },
    {
      "city": "Maple Plain",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Maple Plain, Minnesota"
    },
    {
      "city": "Long Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Long Lake, Minnesota"
    },
    {
      "city": "Loretto",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Loretto, Minnesota"
    },
    {
      "city": "Minnetonka Beach",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minnetonka Beach, Minnesota"
    },
    {
      "city": "Mound",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mound, Minnesota"
    },
    {
      "city": "Osseo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Osseo, Minnesota"
    },
    {
      "city": "Rogers",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rogers, Minnesota"
    },
    {
      "city": "Saint Bonifacius",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Bonifacius, Minnesota"
    },
    {
      "city": "Spring Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Spring Park, Minnesota"
    },
    {
      "city": "Wayzata",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wayzata, Minnesota"
    },
    {
      "city": "Navarre",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Navarre, Minnesota"
    },
    {
      "city": "Rockford",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rockford, Minnesota"
    },
    {
      "city": "Howard Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Howard Lake, Minnesota"
    },
    {
      "city": "Brownsville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brownsville, Minnesota"
    },
    {
      "city": "Caledonia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Caledonia, Minnesota"
    },
    {
      "city": "Eitzen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eitzen, Minnesota"
    },
    {
      "city": "Hokah",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hokah, Minnesota"
    },
    {
      "city": "Houston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Houston, Minnesota"
    },
    {
      "city": "La Crescent",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "La Crescent, Minnesota"
    },
    {
      "city": "Spring Grove",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Spring Grove, Minnesota"
    },
    {
      "city": "Akeley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Akeley, Minnesota"
    },
    {
      "city": "Benedict",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Benedict, Minnesota"
    },
    {
      "city": "Lake George",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake George, Minnesota"
    },
    {
      "city": "Laporte",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Laporte, Minnesota"
    },
    {
      "city": "Nevis",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nevis, Minnesota"
    },
    {
      "city": "Park Rapids",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Park Rapids, Minnesota"
    },
    {
      "city": "Braham",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Braham, Minnesota"
    },
    {
      "city": "Cambridge",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cambridge, Minnesota"
    },
    {
      "city": "Dalbo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dalbo, Minnesota"
    },
    {
      "city": "Grandy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grandy, Minnesota"
    },
    {
      "city": "Isanti",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Isanti, Minnesota"
    },
    {
      "city": "Stanchfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stanchfield, Minnesota"
    },
    {
      "city": "Bovey",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bovey, Minnesota"
    },
    {
      "city": "Calumet",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Calumet, Minnesota"
    },
    {
      "city": "Cohasset",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cohasset, Minnesota"
    },
    {
      "city": "Coleraine",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Coleraine, Minnesota"
    },
    {
      "city": "Grand Rapids",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grand Rapids, Minnesota"
    },
    {
      "city": "Goodland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Goodland, Minnesota"
    },
    {
      "city": "Keewatin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Keewatin, Minnesota"
    },
    {
      "city": "Marble",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Marble, Minnesota"
    },
    {
      "city": "Nashwauk",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nashwauk, Minnesota"
    },
    {
      "city": "Pengilly",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pengilly, Minnesota"
    },
    {
      "city": "Swan River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Swan River, Minnesota"
    },
    {
      "city": "Taconite",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Taconite, Minnesota"
    },
    {
      "city": "Warba",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Warba, Minnesota"
    },
    {
      "city": "Bigfork",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bigfork, Minnesota"
    },
    {
      "city": "Bowstring",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bowstring, Minnesota"
    },
    {
      "city": "Deer River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Deer River, Minnesota"
    },
    {
      "city": "Talmoon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Talmoon, Minnesota"
    },
    {
      "city": "Effie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Effie, Minnesota"
    },
    {
      "city": "Marcell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Marcell, Minnesota"
    },
    {
      "city": "Max",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Max, Minnesota"
    },
    {
      "city": "Spring Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Spring Lake, Minnesota"
    },
    {
      "city": "Squaw Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Squaw Lake, Minnesota"
    },
    {
      "city": "Wirt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wirt, Minnesota"
    },
    {
      "city": "Alpha",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alpha, Minnesota"
    },
    {
      "city": "Heron Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Heron Lake, Minnesota"
    },
    {
      "city": "Jackson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jackson, Minnesota"
    },
    {
      "city": "Lakefield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lakefield, Minnesota"
    },
    {
      "city": "Okabena",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Okabena, Minnesota"
    },
    {
      "city": "Mora",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mora, Minnesota"
    },
    {
      "city": "Ogilvie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ogilvie, Minnesota"
    },
    {
      "city": "Willmar",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Willmar, Minnesota"
    },
    {
      "city": "Atwater",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Atwater, Minnesota"
    },
    {
      "city": "Blomkest",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Blomkest, Minnesota"
    },
    {
      "city": "Kandiyohi",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kandiyohi, Minnesota"
    },
    {
      "city": "Lake Lillian",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Lillian, Minnesota"
    },
    {
      "city": "New London",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New London, Minnesota"
    },
    {
      "city": "Pennock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pennock, Minnesota"
    },
    {
      "city": "Prinsburg",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Prinsburg, Minnesota"
    },
    {
      "city": "Raymond",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Raymond, Minnesota"
    },
    {
      "city": "Spicer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Spicer, Minnesota"
    },
    {
      "city": "Sunburg",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sunburg, Minnesota"
    },
    {
      "city": "Donaldson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Donaldson, Minnesota"
    },
    {
      "city": "Hallock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hallock, Minnesota"
    },
    {
      "city": "Halma",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Halma, Minnesota"
    },
    {
      "city": "Humboldt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Humboldt, Minnesota"
    },
    {
      "city": "Karlstad",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Karlstad, Minnesota"
    },
    {
      "city": "Kennedy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kennedy, Minnesota"
    },
    {
      "city": "Lake Bronson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Bronson, Minnesota"
    },
    {
      "city": "Lancaster",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lancaster, Minnesota"
    },
    {
      "city": "Noyes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Noyes, Minnesota"
    },
    {
      "city": "Saint Vincent",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Vincent, Minnesota"
    },
    {
      "city": "Big Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Big Falls, Minnesota"
    },
    {
      "city": "Birchdale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Birchdale, Minnesota"
    },
    {
      "city": "International Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "International Falls, Minnesota"
    },
    {
      "city": "Littlefork",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Littlefork, Minnesota"
    },
    {
      "city": "Loman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Loman, Minnesota"
    },
    {
      "city": "Margie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Margie, Minnesota"
    },
    {
      "city": "Mizpah",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mizpah, Minnesota"
    },
    {
      "city": "Northome",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Northome, Minnesota"
    },
    {
      "city": "Ranier",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ranier, Minnesota"
    },
    {
      "city": "Kabetogama",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kabetogama, Minnesota"
    },
    {
      "city": "South International Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "South International Falls, Minnesota"
    },
    {
      "city": "Bellingham",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bellingham, Minnesota"
    },
    {
      "city": "Boyd",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Boyd, Minnesota"
    },
    {
      "city": "Dawson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dawson, Minnesota"
    },
    {
      "city": "Madison",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Madison, Minnesota"
    },
    {
      "city": "Marietta",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Marietta, Minnesota"
    },
    {
      "city": "Beaver Bay",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Beaver Bay, Minnesota"
    },
    {
      "city": "Finland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Finland, Minnesota"
    },
    {
      "city": "Isabella",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Isabella, Minnesota"
    },
    {
      "city": "Knife River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Knife River, Minnesota"
    },
    {
      "city": "Silver Bay",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Silver Bay, Minnesota"
    },
    {
      "city": "Two Harbors",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Two Harbors, Minnesota"
    },
    {
      "city": "Baudette",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Baudette, Minnesota"
    },
    {
      "city": "Williams",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Williams, Minnesota"
    },
    {
      "city": "Angle Inlet",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Angle Inlet, Minnesota"
    },
    {
      "city": "Oak Island",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Oak Island, Minnesota"
    },
    {
      "city": "Cleveland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cleveland, Minnesota"
    },
    {
      "city": "Elysian",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elysian, Minnesota"
    },
    {
      "city": "Kasota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kasota, Minnesota"
    },
    {
      "city": "Kilkenny",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kilkenny, Minnesota"
    },
    {
      "city": "Le Center",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Le Center, Minnesota"
    },
    {
      "city": "Le Sueur",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Le Sueur, Minnesota"
    },
    {
      "city": "Montgomery",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Montgomery, Minnesota"
    },
    {
      "city": "New Prague",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Prague, Minnesota"
    },
    {
      "city": "Waterville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waterville, Minnesota"
    },
    {
      "city": "Arco",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Arco, Minnesota"
    },
    {
      "city": "Hendricks",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hendricks, Minnesota"
    },
    {
      "city": "Ivanhoe",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ivanhoe, Minnesota"
    },
    {
      "city": "Lake Benton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Benton, Minnesota"
    },
    {
      "city": "Tyler",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tyler, Minnesota"
    },
    {
      "city": "Balaton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Balaton, Minnesota"
    },
    {
      "city": "Garvin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Garvin, Minnesota"
    },
    {
      "city": "Lynd",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lynd, Minnesota"
    },
    {
      "city": "Russell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Russell, Minnesota"
    },
    {
      "city": "Tracy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tracy, Minnesota"
    },
    {
      "city": "Cottonwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cottonwood, Minnesota"
    },
    {
      "city": "Ghent",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ghent, Minnesota"
    },
    {
      "city": "Marshall",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Marshall, Minnesota"
    },
    {
      "city": "Minneota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minneota, Minnesota"
    },
    {
      "city": "Taunton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Taunton, Minnesota"
    },
    {
      "city": "Brownton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brownton, Minnesota"
    },
    {
      "city": "Glencoe",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Glencoe, Minnesota"
    },
    {
      "city": "Hutchinson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hutchinson, Minnesota"
    },
    {
      "city": "Lester Prairie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lester Prairie, Minnesota"
    },
    {
      "city": "Plato",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Plato, Minnesota"
    },
    {
      "city": "Silver Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Silver Lake, Minnesota"
    },
    {
      "city": "Stewart",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stewart, Minnesota"
    },
    {
      "city": "Winsted",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winsted, Minnesota"
    },
    {
      "city": "Bejou",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bejou, Minnesota"
    },
    {
      "city": "Mahnomen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mahnomen, Minnesota"
    },
    {
      "city": "Naytahwaush",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Naytahwaush, Minnesota"
    },
    {
      "city": "Waubun",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waubun, Minnesota"
    },
    {
      "city": "Alvarado",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alvarado, Minnesota"
    },
    {
      "city": "Argyle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Argyle, Minnesota"
    },
    {
      "city": "Gatzke",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gatzke, Minnesota"
    },
    {
      "city": "Grygla",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grygla, Minnesota"
    },
    {
      "city": "Middle River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Middle River, Minnesota"
    },
    {
      "city": "Newfolden",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Newfolden, Minnesota"
    },
    {
      "city": "Oslo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Oslo, Minnesota"
    },
    {
      "city": "Stephen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stephen, Minnesota"
    },
    {
      "city": "Strandquist",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Strandquist, Minnesota"
    },
    {
      "city": "Viking",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Viking, Minnesota"
    },
    {
      "city": "Warren",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Warren, Minnesota"
    },
    {
      "city": "Fairmont",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fairmont, Minnesota"
    },
    {
      "city": "Granada",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Granada, Minnesota"
    },
    {
      "city": "Northrop",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Northrop, Minnesota"
    },
    {
      "city": "Truman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Truman, Minnesota"
    },
    {
      "city": "Ceylon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ceylon, Minnesota"
    },
    {
      "city": "Dunnell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dunnell, Minnesota"
    },
    {
      "city": "Ormsby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ormsby, Minnesota"
    },
    {
      "city": "Sherburn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sherburn, Minnesota"
    },
    {
      "city": "Trimont",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Trimont, Minnesota"
    },
    {
      "city": "Welcome",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Welcome, Minnesota"
    },
    {
      "city": "Darwin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Darwin, Minnesota"
    },
    {
      "city": "Dassel",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dassel, Minnesota"
    },
    {
      "city": "Eden Valley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eden Valley, Minnesota"
    },
    {
      "city": "Litchfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Litchfield, Minnesota"
    },
    {
      "city": "Watkins",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Watkins, Minnesota"
    },
    {
      "city": "Cosmos",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cosmos, Minnesota"
    },
    {
      "city": "Grove City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grove City, Minnesota"
    },
    {
      "city": "Princeton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Princeton, Minnesota"
    },
    {
      "city": "Bock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bock, Minnesota"
    },
    {
      "city": "Foreston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Foreston, Minnesota"
    },
    {
      "city": "Isle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Isle, Minnesota"
    },
    {
      "city": "Milaca",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Milaca, Minnesota"
    },
    {
      "city": "Onamia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Onamia, Minnesota"
    },
    {
      "city": "Pease",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pease, Minnesota"
    },
    {
      "city": "Wahkon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wahkon, Minnesota"
    },
    {
      "city": "Bowlus",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bowlus, Minnesota"
    },
    {
      "city": "Buckman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Buckman, Minnesota"
    },
    {
      "city": "Flensburg",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Flensburg, Minnesota"
    },
    {
      "city": "Hillman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hillman, Minnesota"
    },
    {
      "city": "Lastrup",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lastrup, Minnesota"
    },
    {
      "city": "Little Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Little Falls, Minnesota"
    },
    {
      "city": "Pierz",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pierz, Minnesota"
    },
    {
      "city": "Royalton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Royalton, Minnesota"
    },
    {
      "city": "Swanville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Swanville, Minnesota"
    },
    {
      "city": "Upsala",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Upsala, Minnesota"
    },
    {
      "city": "Waite Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waite Park, Minnesota"
    },
    {
      "city": "Cushing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cushing, Minnesota"
    },
    {
      "city": "Motley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Motley, Minnesota"
    },
    {
      "city": "Randall",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Randall, Minnesota"
    },
    {
      "city": "Adams",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Adams, Minnesota"
    },
    {
      "city": "Austin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Austin, Minnesota"
    },
    {
      "city": "Brownsdale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brownsdale, Minnesota"
    },
    {
      "city": "Dexter",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dexter, Minnesota"
    },
    {
      "city": "Elkton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elkton, Minnesota"
    },
    {
      "city": "Grand Meadow",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grand Meadow, Minnesota"
    },
    {
      "city": "Lansing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lansing, Minnesota"
    },
    {
      "city": "Le Roy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Le Roy, Minnesota"
    },
    {
      "city": "Lyle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lyle, Minnesota"
    },
    {
      "city": "Racine",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Racine, Minnesota"
    },
    {
      "city": "Rose Creek",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rose Creek, Minnesota"
    },
    {
      "city": "Sargeant",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sargeant, Minnesota"
    },
    {
      "city": "Taopi",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Taopi, Minnesota"
    },
    {
      "city": "Waltham",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waltham, Minnesota"
    },
    {
      "city": "Avoca",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Avoca, Minnesota"
    },
    {
      "city": "Chandler",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chandler, Minnesota"
    },
    {
      "city": "Currie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Currie, Minnesota"
    },
    {
      "city": "Dovray",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dovray, Minnesota"
    },
    {
      "city": "Fulda",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fulda, Minnesota"
    },
    {
      "city": "Iona",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Iona, Minnesota"
    },
    {
      "city": "Lake Wilson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Wilson, Minnesota"
    },
    {
      "city": "Slayton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Slayton, Minnesota"
    },
    {
      "city": "Courtland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Courtland, Minnesota"
    },
    {
      "city": "Lafayette",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lafayette, Minnesota"
    },
    {
      "city": "Nicollet",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nicollet, Minnesota"
    },
    {
      "city": "Saint Peter",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Peter, Minnesota"
    },
    {
      "city": "Adrian",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Adrian, Minnesota"
    },
    {
      "city": "Bigelow",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bigelow, Minnesota"
    },
    {
      "city": "Brewster",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brewster, Minnesota"
    },
    {
      "city": "Ellsworth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ellsworth, Minnesota"
    },
    {
      "city": "Leota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Leota, Minnesota"
    },
    {
      "city": "Lismore",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lismore, Minnesota"
    },
    {
      "city": "Reading",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Reading, Minnesota"
    },
    {
      "city": "Round Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Round Lake, Minnesota"
    },
    {
      "city": "Rushmore",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rushmore, Minnesota"
    },
    {
      "city": "Wilmont",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wilmont, Minnesota"
    },
    {
      "city": "Worthington",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Worthington, Minnesota"
    },
    {
      "city": "Ada",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ada, Minnesota"
    },
    {
      "city": "Borup",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Borup, Minnesota"
    },
    {
      "city": "Flom",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Flom, Minnesota"
    },
    {
      "city": "Gary",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gary, Minnesota"
    },
    {
      "city": "Halstad",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Halstad, Minnesota"
    },
    {
      "city": "Hendrum",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hendrum, Minnesota"
    },
    {
      "city": "Perley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Perley, Minnesota"
    },
    {
      "city": "Shelly",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Shelly, Minnesota"
    },
    {
      "city": "Twin Valley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Twin Valley, Minnesota"
    },
    {
      "city": "Rochester",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rochester, Minnesota"
    },
    {
      "city": "Byron",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Byron, Minnesota"
    },
    {
      "city": "Dover",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dover, Minnesota"
    },
    {
      "city": "Eyota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eyota, Minnesota"
    },
    {
      "city": "Oronoco",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Oronoco, Minnesota"
    },
    {
      "city": "Stewartville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stewartville, Minnesota"
    },
    {
      "city": "Dalton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dalton, Minnesota"
    },
    {
      "city": "Parkers Prairie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Parkers Prairie, Minnesota"
    },
    {
      "city": "Battle Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Battle Lake, Minnesota"
    },
    {
      "city": "Bluffton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bluffton, Minnesota"
    },
    {
      "city": "Clitherall",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clitherall, Minnesota"
    },
    {
      "city": "Deer Creek",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Deer Creek, Minnesota"
    },
    {
      "city": "Dent",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dent, Minnesota"
    },
    {
      "city": "Elizabeth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elizabeth, Minnesota"
    },
    {
      "city": "Erhard",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Erhard, Minnesota"
    },
    {
      "city": "Fergus Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fergus Falls, Minnesota"
    },
    {
      "city": "Henning",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Henning, Minnesota"
    },
    {
      "city": "New York Mills",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New York Mills, Minnesota"
    },
    {
      "city": "Ottertail",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ottertail, Minnesota"
    },
    {
      "city": "Pelican Rapids",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pelican Rapids, Minnesota"
    },
    {
      "city": "Perham",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Perham, Minnesota"
    },
    {
      "city": "Richville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Richville, Minnesota"
    },
    {
      "city": "Underwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Underwood, Minnesota"
    },
    {
      "city": "Vergas",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Vergas, Minnesota"
    },
    {
      "city": "Vining",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Vining, Minnesota"
    },
    {
      "city": "Thief River Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Thief River Falls, Minnesota"
    },
    {
      "city": "Goodridge",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Goodridge, Minnesota"
    },
    {
      "city": "Saint Hilaire",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Hilaire, Minnesota"
    },
    {
      "city": "Brook Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brook Park, Minnesota"
    },
    {
      "city": "Grasston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grasston, Minnesota"
    },
    {
      "city": "Henriette",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Henriette, Minnesota"
    },
    {
      "city": "Hinckley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hinckley, Minnesota"
    },
    {
      "city": "Pine City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pine City, Minnesota"
    },
    {
      "city": "Rock Creek",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rock Creek, Minnesota"
    },
    {
      "city": "Sandstone",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sandstone, Minnesota"
    },
    {
      "city": "Askov",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Askov, Minnesota"
    },
    {
      "city": "Bruno",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bruno, Minnesota"
    },
    {
      "city": "Finlayson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Finlayson, Minnesota"
    },
    {
      "city": "Kerrick",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kerrick, Minnesota"
    },
    {
      "city": "Sturgeon Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sturgeon Lake, Minnesota"
    },
    {
      "city": "Willow River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Willow River, Minnesota"
    },
    {
      "city": "Edgerton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Edgerton, Minnesota"
    },
    {
      "city": "Holland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Holland, Minnesota"
    },
    {
      "city": "Ihlen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ihlen, Minnesota"
    },
    {
      "city": "Jasper",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jasper, Minnesota"
    },
    {
      "city": "Pipestone",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Pipestone, Minnesota"
    },
    {
      "city": "Ruthton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ruthton, Minnesota"
    },
    {
      "city": "Woodstock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Woodstock, Minnesota"
    },
    {
      "city": "Beltrami",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Beltrami, Minnesota"
    },
    {
      "city": "Climax",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Climax, Minnesota"
    },
    {
      "city": "Erskine",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Erskine, Minnesota"
    },
    {
      "city": "Fertile",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fertile, Minnesota"
    },
    {
      "city": "Fosston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fosston, Minnesota"
    },
    {
      "city": "Mcintosh",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mcintosh, Minnesota"
    },
    {
      "city": "Nielsville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nielsville, Minnesota"
    },
    {
      "city": "Winger",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winger, Minnesota"
    },
    {
      "city": "Gully",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gully, Minnesota"
    },
    {
      "city": "Lengby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lengby, Minnesota"
    },
    {
      "city": "Trail",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Trail, Minnesota"
    },
    {
      "city": "Crookston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Crookston, Minnesota"
    },
    {
      "city": "East Grand Forks",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "East Grand Forks, Minnesota"
    },
    {
      "city": "Euclid",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Euclid, Minnesota"
    },
    {
      "city": "Fisher",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fisher, Minnesota"
    },
    {
      "city": "Mentor",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mentor, Minnesota"
    },
    {
      "city": "Cyrus",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cyrus, Minnesota"
    },
    {
      "city": "Farwell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Farwell, Minnesota"
    },
    {
      "city": "Glenwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Glenwood, Minnesota"
    },
    {
      "city": "Lowry",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lowry, Minnesota"
    },
    {
      "city": "Starbuck",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Starbuck, Minnesota"
    },
    {
      "city": "Villard",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Villard, Minnesota"
    },
    {
      "city": "Brooks",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brooks, Minnesota"
    },
    {
      "city": "Oklee",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Oklee, Minnesota"
    },
    {
      "city": "Plummer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Plummer, Minnesota"
    },
    {
      "city": "Red Lake Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Red Lake Falls, Minnesota"
    },
    {
      "city": "Sanborn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sanborn, Minnesota"
    },
    {
      "city": "Lamberton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lamberton, Minnesota"
    },
    {
      "city": "Revere",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Revere, Minnesota"
    },
    {
      "city": "Walnut Grove",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Walnut Grove, Minnesota"
    },
    {
      "city": "Belview",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Belview, Minnesota"
    },
    {
      "city": "Clements",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clements, Minnesota"
    },
    {
      "city": "Lucan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lucan, Minnesota"
    },
    {
      "city": "Milroy",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Milroy, Minnesota"
    },
    {
      "city": "Morgan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Morgan, Minnesota"
    },
    {
      "city": "Redwood Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Redwood Falls, Minnesota"
    },
    {
      "city": "Seaforth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Seaforth, Minnesota"
    },
    {
      "city": "Vesta",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Vesta, Minnesota"
    },
    {
      "city": "Wabasso",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wabasso, Minnesota"
    },
    {
      "city": "Wanda",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wanda, Minnesota"
    },
    {
      "city": "Bird Island",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bird Island, Minnesota"
    },
    {
      "city": "Buffalo Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Buffalo Lake, Minnesota"
    },
    {
      "city": "Fairfax",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Fairfax, Minnesota"
    },
    {
      "city": "Franklin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Franklin, Minnesota"
    },
    {
      "city": "Hector",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hector, Minnesota"
    },
    {
      "city": "Danube",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Danube, Minnesota"
    },
    {
      "city": "Morton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Morton, Minnesota"
    },
    {
      "city": "Olivia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Olivia, Minnesota"
    },
    {
      "city": "Renville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Renville, Minnesota"
    },
    {
      "city": "Sacred Heart",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sacred Heart, Minnesota"
    },
    {
      "city": "Dundas",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dundas, Minnesota"
    },
    {
      "city": "Faribault",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Faribault, Minnesota"
    },
    {
      "city": "Lonsdale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lonsdale, Minnesota"
    },
    {
      "city": "Morristown",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Morristown, Minnesota"
    },
    {
      "city": "Nerstrand",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nerstrand, Minnesota"
    },
    {
      "city": "Northfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Northfield, Minnesota"
    },
    {
      "city": "Warsaw",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Warsaw, Minnesota"
    },
    {
      "city": "Webster",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Webster, Minnesota"
    },
    {
      "city": "Beaver Creek",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Beaver Creek, Minnesota"
    },
    {
      "city": "Hardwick",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hardwick, Minnesota"
    },
    {
      "city": "Hills",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hills, Minnesota"
    },
    {
      "city": "Kanaranzi",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kanaranzi, Minnesota"
    },
    {
      "city": "Kenneth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kenneth, Minnesota"
    },
    {
      "city": "Luverne",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Luverne, Minnesota"
    },
    {
      "city": "Magnolia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Magnolia, Minnesota"
    },
    {
      "city": "Steen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Steen, Minnesota"
    },
    {
      "city": "Roosevelt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Roosevelt, Minnesota"
    },
    {
      "city": "Swift",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Swift, Minnesota"
    },
    {
      "city": "Badger",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Badger, Minnesota"
    },
    {
      "city": "Greenbush",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Greenbush, Minnesota"
    },
    {
      "city": "Roseau",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Roseau, Minnesota"
    },
    {
      "city": "Salol",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Salol, Minnesota"
    },
    {
      "city": "Strathcona",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Strathcona, Minnesota"
    },
    {
      "city": "Wannaska",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wannaska, Minnesota"
    },
    {
      "city": "Warroad",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Warroad, Minnesota"
    },
    {
      "city": "Brimson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brimson, Minnesota"
    },
    {
      "city": "Alborn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alborn, Minnesota"
    },
    {
      "city": "Angora",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Angora, Minnesota"
    },
    {
      "city": "Aurora",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Aurora, Minnesota"
    },
    {
      "city": "Babbitt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Babbitt, Minnesota"
    },
    {
      "city": "Biwabik",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Biwabik, Minnesota"
    },
    {
      "city": "Britt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Britt, Minnesota"
    },
    {
      "city": "Brookston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brookston, Minnesota"
    },
    {
      "city": "Buhl",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Buhl, Minnesota"
    },
    {
      "city": "Canyon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Canyon, Minnesota"
    },
    {
      "city": "Chisholm",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chisholm, Minnesota"
    },
    {
      "city": "Cook",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cook, Minnesota"
    },
    {
      "city": "Cotton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cotton, Minnesota"
    },
    {
      "city": "Crane Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Crane Lake, Minnesota"
    },
    {
      "city": "Ely",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ely, Minnesota"
    },
    {
      "city": "Embarrass",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Embarrass, Minnesota"
    },
    {
      "city": "Eveleth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eveleth, Minnesota"
    },
    {
      "city": "Floodwood",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Floodwood, Minnesota"
    },
    {
      "city": "Forbes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Forbes, Minnesota"
    },
    {
      "city": "Gilbert",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gilbert, Minnesota"
    },
    {
      "city": "Hibbing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hibbing, Minnesota"
    },
    {
      "city": "Hoyt Lakes",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hoyt Lakes, Minnesota"
    },
    {
      "city": "Iron",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Iron, Minnesota"
    },
    {
      "city": "Kinney",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kinney, Minnesota"
    },
    {
      "city": "Makinen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Makinen, Minnesota"
    },
    {
      "city": "Meadowlands",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Meadowlands, Minnesota"
    },
    {
      "city": "Melrude",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Melrude, Minnesota"
    },
    {
      "city": "Mountain Iron",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mountain Iron, Minnesota"
    },
    {
      "city": "Orr",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Orr, Minnesota"
    },
    {
      "city": "Nett Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nett Lake, Minnesota"
    },
    {
      "city": "Virginia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Virginia, Minnesota"
    },
    {
      "city": "Saginaw",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saginaw, Minnesota"
    },
    {
      "city": "Side Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Side Lake, Minnesota"
    },
    {
      "city": "Soudan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Soudan, Minnesota"
    },
    {
      "city": "Tower",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tower, Minnesota"
    },
    {
      "city": "Twig",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Twig, Minnesota"
    },
    {
      "city": "Winton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winton, Minnesota"
    },
    {
      "city": "Duluth",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Duluth, Minnesota"
    },
    {
      "city": "Elko New Market",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elko New Market, Minnesota"
    },
    {
      "city": "Jordan",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Jordan, Minnesota"
    },
    {
      "city": "Prior Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Prior Lake, Minnesota"
    },
    {
      "city": "Savage",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Savage, Minnesota"
    },
    {
      "city": "Shakopee",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Shakopee, Minnesota"
    },
    {
      "city": "Belle Plaine",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Belle Plaine, Minnesota"
    },
    {
      "city": "Becker",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Becker, Minnesota"
    },
    {
      "city": "Big Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Big Lake, Minnesota"
    },
    {
      "city": "Clear Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clear Lake, Minnesota"
    },
    {
      "city": "Elk River",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elk River, Minnesota"
    },
    {
      "city": "Santiago",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Santiago, Minnesota"
    },
    {
      "city": "Zimmerman",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Zimmerman, Minnesota"
    },
    {
      "city": "Arlington",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Arlington, Minnesota"
    },
    {
      "city": "Gaylord",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gaylord, Minnesota"
    },
    {
      "city": "Gibbon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Gibbon, Minnesota"
    },
    {
      "city": "Green Isle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Green Isle, Minnesota"
    },
    {
      "city": "New Auburn",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Auburn, Minnesota"
    },
    {
      "city": "Winthrop",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winthrop, Minnesota"
    },
    {
      "city": "Henderson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Henderson, Minnesota"
    },
    {
      "city": "Kimball",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kimball, Minnesota"
    },
    {
      "city": "Saint Cloud",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Cloud, Minnesota"
    },
    {
      "city": "Albany",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Albany, Minnesota"
    },
    {
      "city": "Avon",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Avon, Minnesota"
    },
    {
      "city": "Belgrade",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Belgrade, Minnesota"
    },
    {
      "city": "Brooten",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Brooten, Minnesota"
    },
    {
      "city": "Cold Spring",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cold Spring, Minnesota"
    },
    {
      "city": "Collegeville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Collegeville, Minnesota"
    },
    {
      "city": "Elrosa",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elrosa, Minnesota"
    },
    {
      "city": "Freeport",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Freeport, Minnesota"
    },
    {
      "city": "Greenwald",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Greenwald, Minnesota"
    },
    {
      "city": "Holdingford",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Holdingford, Minnesota"
    },
    {
      "city": "Melrose",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Melrose, Minnesota"
    },
    {
      "city": "New Munich",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Munich, Minnesota"
    },
    {
      "city": "Paynesville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Paynesville, Minnesota"
    },
    {
      "city": "Richmond",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Richmond, Minnesota"
    },
    {
      "city": "Rockville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rockville, Minnesota"
    },
    {
      "city": "Roscoe",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Roscoe, Minnesota"
    },
    {
      "city": "Saint Joseph",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Joseph, Minnesota"
    },
    {
      "city": "Saint Stephen",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Stephen, Minnesota"
    },
    {
      "city": "Saint Martin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Martin, Minnesota"
    },
    {
      "city": "Sartell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sartell, Minnesota"
    },
    {
      "city": "Sauk Centre",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sauk Centre, Minnesota"
    },
    {
      "city": "Medford",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Medford, Minnesota"
    },
    {
      "city": "Owatonna",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Owatonna, Minnesota"
    },
    {
      "city": "Blooming Prairie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Blooming Prairie, Minnesota"
    },
    {
      "city": "Ellendale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Ellendale, Minnesota"
    },
    {
      "city": "Hope",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hope, Minnesota"
    },
    {
      "city": "Alberta",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Alberta, Minnesota"
    },
    {
      "city": "Chokio",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Chokio, Minnesota"
    },
    {
      "city": "Donnelly",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Donnelly, Minnesota"
    },
    {
      "city": "Hancock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hancock, Minnesota"
    },
    {
      "city": "Morris",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Morris, Minnesota"
    },
    {
      "city": "Appleton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Appleton, Minnesota"
    },
    {
      "city": "Benson",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Benson, Minnesota"
    },
    {
      "city": "Clontarf",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clontarf, Minnesota"
    },
    {
      "city": "Danvers",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Danvers, Minnesota"
    },
    {
      "city": "Holloway",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Holloway, Minnesota"
    },
    {
      "city": "Kerkhoven",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kerkhoven, Minnesota"
    },
    {
      "city": "Murdock",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Murdock, Minnesota"
    },
    {
      "city": "Burtrum",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Burtrum, Minnesota"
    },
    {
      "city": "Grey Eagle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Grey Eagle, Minnesota"
    },
    {
      "city": "Long Prairie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Long Prairie, Minnesota"
    },
    {
      "city": "West Union",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "West Union, Minnesota"
    },
    {
      "city": "Bertha",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bertha, Minnesota"
    },
    {
      "city": "Browerville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Browerville, Minnesota"
    },
    {
      "city": "Clarissa",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clarissa, Minnesota"
    },
    {
      "city": "Eagle Bend",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Eagle Bend, Minnesota"
    },
    {
      "city": "Hewitt",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hewitt, Minnesota"
    },
    {
      "city": "Staples",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Staples, Minnesota"
    },
    {
      "city": "Browns Valley",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Browns Valley, Minnesota"
    },
    {
      "city": "Dumont",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dumont, Minnesota"
    },
    {
      "city": "Wheaton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wheaton, Minnesota"
    },
    {
      "city": "Tintah",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Tintah, Minnesota"
    },
    {
      "city": "Lake City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake City, Minnesota"
    },
    {
      "city": "Elgin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Elgin, Minnesota"
    },
    {
      "city": "Kellogg",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kellogg, Minnesota"
    },
    {
      "city": "Mazeppa",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Mazeppa, Minnesota"
    },
    {
      "city": "Millville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Millville, Minnesota"
    },
    {
      "city": "Plainview",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Plainview, Minnesota"
    },
    {
      "city": "Reads Landing",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Reads Landing, Minnesota"
    },
    {
      "city": "Wabasha",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wabasha, Minnesota"
    },
    {
      "city": "Zumbro Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Zumbro Falls, Minnesota"
    },
    {
      "city": "Aldrich",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Aldrich, Minnesota"
    },
    {
      "city": "Menahga",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Menahga, Minnesota"
    },
    {
      "city": "Sebeka",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Sebeka, Minnesota"
    },
    {
      "city": "Verndale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Verndale, Minnesota"
    },
    {
      "city": "Wadena",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wadena, Minnesota"
    },
    {
      "city": "Janesville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Janesville, Minnesota"
    },
    {
      "city": "New Richland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "New Richland, Minnesota"
    },
    {
      "city": "Waldorf",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waldorf, Minnesota"
    },
    {
      "city": "Waseca",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waseca, Minnesota"
    },
    {
      "city": "Afton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Afton, Minnesota"
    },
    {
      "city": "Bayport",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Bayport, Minnesota"
    },
    {
      "city": "Cottage Grove",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cottage Grove, Minnesota"
    },
    {
      "city": "Forest Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Forest Lake, Minnesota"
    },
    {
      "city": "Hugo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hugo, Minnesota"
    },
    {
      "city": "Lake Elmo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lake Elmo, Minnesota"
    },
    {
      "city": "Lakeland",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lakeland, Minnesota"
    },
    {
      "city": "Marine On Saint Croix",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Marine On Saint Croix, Minnesota"
    },
    {
      "city": "Newport",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Newport, Minnesota"
    },
    {
      "city": "Saint Paul Park",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Paul Park, Minnesota"
    },
    {
      "city": "Scandia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Scandia, Minnesota"
    },
    {
      "city": "Stillwater",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stillwater, Minnesota"
    },
    {
      "city": "Willernie",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Willernie, Minnesota"
    },
    {
      "city": "Darfur",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Darfur, Minnesota"
    },
    {
      "city": "La Salle",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "La Salle, Minnesota"
    },
    {
      "city": "Lewisville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lewisville, Minnesota"
    },
    {
      "city": "Madelia",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Madelia, Minnesota"
    },
    {
      "city": "Saint James",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint James, Minnesota"
    },
    {
      "city": "Butterfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Butterfield, Minnesota"
    },
    {
      "city": "Odin",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Odin, Minnesota"
    },
    {
      "city": "Breckenridge",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Breckenridge, Minnesota"
    },
    {
      "city": "Campbell",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Campbell, Minnesota"
    },
    {
      "city": "Foxhome",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Foxhome, Minnesota"
    },
    {
      "city": "Kent",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Kent, Minnesota"
    },
    {
      "city": "Nashua",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Nashua, Minnesota"
    },
    {
      "city": "Rothsay",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rothsay, Minnesota"
    },
    {
      "city": "Wolverton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wolverton, Minnesota"
    },
    {
      "city": "Altura",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Altura, Minnesota"
    },
    {
      "city": "Dakota",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Dakota, Minnesota"
    },
    {
      "city": "Homer",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Homer, Minnesota"
    },
    {
      "city": "Lewiston",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Lewiston, Minnesota"
    },
    {
      "city": "Minnesota City",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Minnesota City, Minnesota"
    },
    {
      "city": "Rollingstone",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Rollingstone, Minnesota"
    },
    {
      "city": "Saint Charles",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Charles, Minnesota"
    },
    {
      "city": "Utica",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Utica, Minnesota"
    },
    {
      "city": "Winona",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Winona, Minnesota"
    },
    {
      "city": "Stockton",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Stockton, Minnesota"
    },
    {
      "city": "Albertville",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Albertville, Minnesota"
    },
    {
      "city": "Annandale",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Annandale, Minnesota"
    },
    {
      "city": "Buffalo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Buffalo, Minnesota"
    },
    {
      "city": "Clearwater",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clearwater, Minnesota"
    },
    {
      "city": "Cokato",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Cokato, Minnesota"
    },
    {
      "city": "Delano",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Delano, Minnesota"
    },
    {
      "city": "Hanover",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hanover, Minnesota"
    },
    {
      "city": "Maple Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Maple Lake, Minnesota"
    },
    {
      "city": "Monticello",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Monticello, Minnesota"
    },
    {
      "city": "Montrose",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Montrose, Minnesota"
    },
    {
      "city": "Saint Michael",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Saint Michael, Minnesota"
    },
    {
      "city": "Silver Creek",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Silver Creek, Minnesota"
    },
    {
      "city": "South Haven",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "South Haven, Minnesota"
    },
    {
      "city": "Waverly",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Waverly, Minnesota"
    },
    {
      "city": "Canby",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Canby, Minnesota"
    },
    {
      "city": "Clarkfield",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Clarkfield, Minnesota"
    },
    {
      "city": "Echo",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Echo, Minnesota"
    },
    {
      "city": "Granite Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Granite Falls, Minnesota"
    },
    {
      "city": "Hanley Falls",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Hanley Falls, Minnesota"
    },
    {
      "city": "Porter",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Porter, Minnesota"
    },
    {
      "city": "Wood Lake",
      "state": "Minnesota",
      "shortState": "MN",
      "formatedCity": "Wood Lake, Minnesota"
    },
    {
      "city": "Kirksville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kirksville, Missouri"
    },
    {
      "city": "Brashear",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brashear, Missouri"
    },
    {
      "city": "Gibbs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gibbs, Missouri"
    },
    {
      "city": "Greentop",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Greentop, Missouri"
    },
    {
      "city": "Novinger",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Novinger, Missouri"
    },
    {
      "city": "Amazonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Amazonia, Missouri"
    },
    {
      "city": "Bolckow",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bolckow, Missouri"
    },
    {
      "city": "Cosby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cosby, Missouri"
    },
    {
      "city": "Fillmore",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fillmore, Missouri"
    },
    {
      "city": "Helena",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Helena, Missouri"
    },
    {
      "city": "Rea",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rea, Missouri"
    },
    {
      "city": "Rosendale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rosendale, Missouri"
    },
    {
      "city": "Savannah",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Savannah, Missouri"
    },
    {
      "city": "Saint Joseph",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Joseph, Missouri"
    },
    {
      "city": "Fairfax",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fairfax, Missouri"
    },
    {
      "city": "Rock Port",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rock Port, Missouri"
    },
    {
      "city": "Tarkio",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tarkio, Missouri"
    },
    {
      "city": "Watson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Watson, Missouri"
    },
    {
      "city": "Westboro",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Westboro, Missouri"
    },
    {
      "city": "Farber",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Farber, Missouri"
    },
    {
      "city": "Laddonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Laddonia, Missouri"
    },
    {
      "city": "Vandalia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vandalia, Missouri"
    },
    {
      "city": "Benton City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Benton City, Missouri"
    },
    {
      "city": "Martinsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Martinsburg, Missouri"
    },
    {
      "city": "Mexico",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mexico, Missouri"
    },
    {
      "city": "Rush Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rush Hill, Missouri"
    },
    {
      "city": "Thompson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Thompson, Missouri"
    },
    {
      "city": "Wheaton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wheaton, Missouri"
    },
    {
      "city": "Butterfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Butterfield, Missouri"
    },
    {
      "city": "Cassville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cassville, Missouri"
    },
    {
      "city": "Eagle Rock",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eagle Rock, Missouri"
    },
    {
      "city": "Exeter",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Exeter, Missouri"
    },
    {
      "city": "Golden",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Golden, Missouri"
    },
    {
      "city": "Monett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Monett, Missouri"
    },
    {
      "city": "Purdy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Purdy, Missouri"
    },
    {
      "city": "Seligman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Seligman, Missouri"
    },
    {
      "city": "Shell Knob",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Shell Knob, Missouri"
    },
    {
      "city": "Washburn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Washburn, Missouri"
    },
    {
      "city": "Golden City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Golden City, Missouri"
    },
    {
      "city": "Lamar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lamar, Missouri"
    },
    {
      "city": "Liberal",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Liberal, Missouri"
    },
    {
      "city": "Milford",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Milford, Missouri"
    },
    {
      "city": "Mindenmines",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mindenmines, Missouri"
    },
    {
      "city": "Adrian",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Adrian, Missouri"
    },
    {
      "city": "Amoret",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Amoret, Missouri"
    },
    {
      "city": "Amsterdam",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Amsterdam, Missouri"
    },
    {
      "city": "Butler",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Butler, Missouri"
    },
    {
      "city": "Drexel",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Drexel, Missouri"
    },
    {
      "city": "Foster",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Foster, Missouri"
    },
    {
      "city": "Hume",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hume, Missouri"
    },
    {
      "city": "Rich Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rich Hill, Missouri"
    },
    {
      "city": "Rockville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rockville, Missouri"
    },
    {
      "city": "Cole Camp",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cole Camp, Missouri"
    },
    {
      "city": "Edwards",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Edwards, Missouri"
    },
    {
      "city": "Ionia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ionia, Missouri"
    },
    {
      "city": "Lincoln",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lincoln, Missouri"
    },
    {
      "city": "Warsaw",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Warsaw, Missouri"
    },
    {
      "city": "Patton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Patton, Missouri"
    },
    {
      "city": "Gipsy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gipsy, Missouri"
    },
    {
      "city": "Glenallen",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Glenallen, Missouri"
    },
    {
      "city": "Leopold",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leopold, Missouri"
    },
    {
      "city": "Marble Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marble Hill, Missouri"
    },
    {
      "city": "Sedgewickville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sedgewickville, Missouri"
    },
    {
      "city": "Sturdivant",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sturdivant, Missouri"
    },
    {
      "city": "Zalma",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Zalma, Missouri"
    },
    {
      "city": "Ashland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ashland, Missouri"
    },
    {
      "city": "Hartsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hartsburg, Missouri"
    },
    {
      "city": "Columbia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Columbia, Missouri"
    },
    {
      "city": "Centralia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Centralia, Missouri"
    },
    {
      "city": "Hallsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hallsville, Missouri"
    },
    {
      "city": "Harrisburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Harrisburg, Missouri"
    },
    {
      "city": "Rocheport",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rocheport, Missouri"
    },
    {
      "city": "Sturgeon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sturgeon, Missouri"
    },
    {
      "city": "Mid Missouri",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mid Missouri, Missouri"
    },
    {
      "city": "Agency",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Agency, Missouri"
    },
    {
      "city": "De Kalb",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "De Kalb, Missouri"
    },
    {
      "city": "Easton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Easton, Missouri"
    },
    {
      "city": "Faucett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Faucett, Missouri"
    },
    {
      "city": "Rushville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rushville, Missouri"
    },
    {
      "city": "Poplar Bluff",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Poplar Bluff, Missouri"
    },
    {
      "city": "Broseley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Broseley, Missouri"
    },
    {
      "city": "Fagus",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fagus, Missouri"
    },
    {
      "city": "Fisk",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fisk, Missouri"
    },
    {
      "city": "Harviell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Harviell, Missouri"
    },
    {
      "city": "Neelyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Neelyville, Missouri"
    },
    {
      "city": "Qulin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Qulin, Missouri"
    },
    {
      "city": "Rombauer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rombauer, Missouri"
    },
    {
      "city": "Braymer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Braymer, Missouri"
    },
    {
      "city": "Breckenridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Breckenridge, Missouri"
    },
    {
      "city": "Cowgill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cowgill, Missouri"
    },
    {
      "city": "Hamilton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hamilton, Missouri"
    },
    {
      "city": "Kidder",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kidder, Missouri"
    },
    {
      "city": "Kingston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kingston, Missouri"
    },
    {
      "city": "Polo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Polo, Missouri"
    },
    {
      "city": "Williamsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Williamsburg, Missouri"
    },
    {
      "city": "Holts Summit",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holts Summit, Missouri"
    },
    {
      "city": "Mokane",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mokane, Missouri"
    },
    {
      "city": "New Bloomfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Bloomfield, Missouri"
    },
    {
      "city": "Portland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Portland, Missouri"
    },
    {
      "city": "Steedman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Steedman, Missouri"
    },
    {
      "city": "Tebbetts",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tebbetts, Missouri"
    },
    {
      "city": "Auxvasse",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Auxvasse, Missouri"
    },
    {
      "city": "Fulton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fulton, Missouri"
    },
    {
      "city": "Kingdom City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kingdom City, Missouri"
    },
    {
      "city": "Camdenton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Camdenton, Missouri"
    },
    {
      "city": "Lake Ozark",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lake Ozark, Missouri"
    },
    {
      "city": "Linn Creek",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Linn Creek, Missouri"
    },
    {
      "city": "Osage Beach",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Osage Beach, Missouri"
    },
    {
      "city": "Sunrise Beach",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sunrise Beach, Missouri"
    },
    {
      "city": "Climax Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Climax Springs, Missouri"
    },
    {
      "city": "Stoutland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stoutland, Missouri"
    },
    {
      "city": "Montreal",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Montreal, Missouri"
    },
    {
      "city": "Macks Creek",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Macks Creek, Missouri"
    },
    {
      "city": "Roach",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Roach, Missouri"
    },
    {
      "city": "Cape Girardeau",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cape Girardeau, Missouri"
    },
    {
      "city": "Burfordville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Burfordville, Missouri"
    },
    {
      "city": "Daisy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Daisy, Missouri"
    },
    {
      "city": "Delta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Delta, Missouri"
    },
    {
      "city": "Dutchtown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dutchtown, Missouri"
    },
    {
      "city": "Friedheim",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Friedheim, Missouri"
    },
    {
      "city": "Gordonville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gordonville, Missouri"
    },
    {
      "city": "Jackson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jackson, Missouri"
    },
    {
      "city": "Millersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Millersville, Missouri"
    },
    {
      "city": "Oak Ridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oak Ridge, Missouri"
    },
    {
      "city": "Old Appleton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Old Appleton, Missouri"
    },
    {
      "city": "Pocahontas",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pocahontas, Missouri"
    },
    {
      "city": "Whitewater",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Whitewater, Missouri"
    },
    {
      "city": "Bogard",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bogard, Missouri"
    },
    {
      "city": "Bosworth",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bosworth, Missouri"
    },
    {
      "city": "Carrollton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Carrollton, Missouri"
    },
    {
      "city": "De Witt",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "De Witt, Missouri"
    },
    {
      "city": "Hale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hale, Missouri"
    },
    {
      "city": "Norborne",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Norborne, Missouri"
    },
    {
      "city": "Stet",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stet, Missouri"
    },
    {
      "city": "Tina",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tina, Missouri"
    },
    {
      "city": "Ellsinore",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ellsinore, Missouri"
    },
    {
      "city": "Fremont",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fremont, Missouri"
    },
    {
      "city": "Grandin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grandin, Missouri"
    },
    {
      "city": "Van Buren",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Van Buren, Missouri"
    },
    {
      "city": "Belton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Belton, Missouri"
    },
    {
      "city": "Peculiar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Peculiar, Missouri"
    },
    {
      "city": "Pleasant Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pleasant Hill, Missouri"
    },
    {
      "city": "Raymore",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Raymore, Missouri"
    },
    {
      "city": "Strasburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Strasburg, Missouri"
    },
    {
      "city": "Harrisonville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Harrisonville, Missouri"
    },
    {
      "city": "Archie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Archie, Missouri"
    },
    {
      "city": "Cleveland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cleveland, Missouri"
    },
    {
      "city": "Creighton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Creighton, Missouri"
    },
    {
      "city": "East Lynne",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "East Lynne, Missouri"
    },
    {
      "city": "Freeman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Freeman, Missouri"
    },
    {
      "city": "Garden City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Garden City, Missouri"
    },
    {
      "city": "El Dorado Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "El Dorado Springs, Missouri"
    },
    {
      "city": "Jerico Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jerico Springs, Missouri"
    },
    {
      "city": "Caplinger Mills",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Caplinger Mills, Missouri"
    },
    {
      "city": "Stockton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stockton, Missouri"
    },
    {
      "city": "Mendon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mendon, Missouri"
    },
    {
      "city": "Rothville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rothville, Missouri"
    },
    {
      "city": "Sumner",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sumner, Missouri"
    },
    {
      "city": "Brunswick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brunswick, Missouri"
    },
    {
      "city": "Dalton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dalton, Missouri"
    },
    {
      "city": "Keytesville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Keytesville, Missouri"
    },
    {
      "city": "Salisbury",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Salisbury, Missouri"
    },
    {
      "city": "Triplett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Triplett, Missouri"
    },
    {
      "city": "Billings",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Billings, Missouri"
    },
    {
      "city": "Bruner",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bruner, Missouri"
    },
    {
      "city": "Chadwick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chadwick, Missouri"
    },
    {
      "city": "Chestnutridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chestnutridge, Missouri"
    },
    {
      "city": "Clever",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clever, Missouri"
    },
    {
      "city": "Garrison",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Garrison, Missouri"
    },
    {
      "city": "Highlandville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Highlandville, Missouri"
    },
    {
      "city": "Nixa",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Nixa, Missouri"
    },
    {
      "city": "Oldfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oldfield, Missouri"
    },
    {
      "city": "Ozark",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ozark, Missouri"
    },
    {
      "city": "Sparta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sparta, Missouri"
    },
    {
      "city": "Spokane",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Spokane, Missouri"
    },
    {
      "city": "Alexandria",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Alexandria, Missouri"
    },
    {
      "city": "Kahoka",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kahoka, Missouri"
    },
    {
      "city": "Luray",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Luray, Missouri"
    },
    {
      "city": "Revere",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Revere, Missouri"
    },
    {
      "city": "Saint Patrick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Patrick, Missouri"
    },
    {
      "city": "Wayland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wayland, Missouri"
    },
    {
      "city": "Wyaconda",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wyaconda, Missouri"
    },
    {
      "city": "Excelsior Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Excelsior Springs, Missouri"
    },
    {
      "city": "Holt",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holt, Missouri"
    },
    {
      "city": "Kearney",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kearney, Missouri"
    },
    {
      "city": "Liberty",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Liberty, Missouri"
    },
    {
      "city": "Missouri City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Missouri City, Missouri"
    },
    {
      "city": "Mosby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mosby, Missouri"
    },
    {
      "city": "Smithville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Smithville, Missouri"
    },
    {
      "city": "Kansas City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kansas City, Missouri"
    },
    {
      "city": "Cameron",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cameron, Missouri"
    },
    {
      "city": "Gower",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gower, Missouri"
    },
    {
      "city": "Lathrop",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lathrop, Missouri"
    },
    {
      "city": "Plattsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Plattsburg, Missouri"
    },
    {
      "city": "Trimble",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Trimble, Missouri"
    },
    {
      "city": "Turney",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Turney, Missouri"
    },
    {
      "city": "Centertown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Centertown, Missouri"
    },
    {
      "city": "Eugene",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eugene, Missouri"
    },
    {
      "city": "Henley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Henley, Missouri"
    },
    {
      "city": "Lohman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lohman, Missouri"
    },
    {
      "city": "Russellville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Russellville, Missouri"
    },
    {
      "city": "Saint Thomas",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Thomas, Missouri"
    },
    {
      "city": "Jefferson City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jefferson City, Missouri"
    },
    {
      "city": "Prairie Home",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Prairie Home, Missouri"
    },
    {
      "city": "Boonville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Boonville, Missouri"
    },
    {
      "city": "Bunceton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bunceton, Missouri"
    },
    {
      "city": "Pilot Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pilot Grove, Missouri"
    },
    {
      "city": "Wooldridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wooldridge, Missouri"
    },
    {
      "city": "Blackwater",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blackwater, Missouri"
    },
    {
      "city": "Otterville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Otterville, Missouri"
    },
    {
      "city": "Bourbon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bourbon, Missouri"
    },
    {
      "city": "Cherryville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cherryville, Missouri"
    },
    {
      "city": "Cook Sta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cook Sta, Missouri"
    },
    {
      "city": "Cuba",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cuba, Missouri"
    },
    {
      "city": "Davisville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Davisville, Missouri"
    },
    {
      "city": "Leasburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leasburg, Missouri"
    },
    {
      "city": "Steelville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Steelville, Missouri"
    },
    {
      "city": "Wesco",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wesco, Missouri"
    },
    {
      "city": "Arcola",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arcola, Missouri"
    },
    {
      "city": "Dadeville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dadeville, Missouri"
    },
    {
      "city": "Everton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Everton, Missouri"
    },
    {
      "city": "Greenfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Greenfield, Missouri"
    },
    {
      "city": "Lockwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lockwood, Missouri"
    },
    {
      "city": "South Greenfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "South Greenfield, Missouri"
    },
    {
      "city": "Long Lane",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Long Lane, Missouri"
    },
    {
      "city": "Buffalo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Buffalo, Missouri"
    },
    {
      "city": "Louisburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Louisburg, Missouri"
    },
    {
      "city": "Tunas",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tunas, Missouri"
    },
    {
      "city": "Urbana",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Urbana, Missouri"
    },
    {
      "city": "Windyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Windyville, Missouri"
    },
    {
      "city": "Altamont",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Altamont, Missouri"
    },
    {
      "city": "Coffey",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Coffey, Missouri"
    },
    {
      "city": "Gallatin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gallatin, Missouri"
    },
    {
      "city": "Gilman City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gilman City, Missouri"
    },
    {
      "city": "Jameson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jameson, Missouri"
    },
    {
      "city": "Jamesport",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jamesport, Missouri"
    },
    {
      "city": "Lock Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lock Springs, Missouri"
    },
    {
      "city": "Pattonsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pattonsburg, Missouri"
    },
    {
      "city": "Winston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Winston, Missouri"
    },
    {
      "city": "Amity",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Amity, Missouri"
    },
    {
      "city": "Clarksdale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clarksdale, Missouri"
    },
    {
      "city": "Maysville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Maysville, Missouri"
    },
    {
      "city": "Osborn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Osborn, Missouri"
    },
    {
      "city": "Stewartsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stewartsville, Missouri"
    },
    {
      "city": "Union Star",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Union Star, Missouri"
    },
    {
      "city": "Weatherby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Weatherby, Missouri"
    },
    {
      "city": "Boss",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Boss, Missouri"
    },
    {
      "city": "Jadwin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jadwin, Missouri"
    },
    {
      "city": "Lake Spring",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lake Spring, Missouri"
    },
    {
      "city": "Lenox",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lenox, Missouri"
    },
    {
      "city": "Salem",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Salem, Missouri"
    },
    {
      "city": "Ava",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ava, Missouri"
    },
    {
      "city": "Drury",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Drury, Missouri"
    },
    {
      "city": "Squires",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Squires, Missouri"
    },
    {
      "city": "Vanzant",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vanzant, Missouri"
    },
    {
      "city": "Arbyrd",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arbyrd, Missouri"
    },
    {
      "city": "Cardwell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cardwell, Missouri"
    },
    {
      "city": "Clarkton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clarkton, Missouri"
    },
    {
      "city": "Gibson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gibson, Missouri"
    },
    {
      "city": "Holcomb",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holcomb, Missouri"
    },
    {
      "city": "Hornersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hornersville, Missouri"
    },
    {
      "city": "Kennett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kennett, Missouri"
    },
    {
      "city": "Malden",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Malden, Missouri"
    },
    {
      "city": "Rives",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rives, Missouri"
    },
    {
      "city": "Senath",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Senath, Missouri"
    },
    {
      "city": "Whiteoak",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Whiteoak, Missouri"
    },
    {
      "city": "Campbell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Campbell, Missouri"
    },
    {
      "city": "Beaufort",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Beaufort, Missouri"
    },
    {
      "city": "Berger",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Berger, Missouri"
    },
    {
      "city": "Catawissa",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Catawissa, Missouri"
    },
    {
      "city": "Gerald",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gerald, Missouri"
    },
    {
      "city": "Gray Summit",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gray Summit, Missouri"
    },
    {
      "city": "Labadie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Labadie, Missouri"
    },
    {
      "city": "Leslie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leslie, Missouri"
    },
    {
      "city": "Lonedell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lonedell, Missouri"
    },
    {
      "city": "Luebbering",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Luebbering, Missouri"
    },
    {
      "city": "New Haven",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Haven, Missouri"
    },
    {
      "city": "Pacific",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pacific, Missouri"
    },
    {
      "city": "Robertsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Robertsville, Missouri"
    },
    {
      "city": "Saint Albans",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Albans, Missouri"
    },
    {
      "city": "Saint Clair",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Clair, Missouri"
    },
    {
      "city": "Stanton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stanton, Missouri"
    },
    {
      "city": "Sullivan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sullivan, Missouri"
    },
    {
      "city": "Union",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Union, Missouri"
    },
    {
      "city": "Villa Ridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Villa Ridge, Missouri"
    },
    {
      "city": "Washington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Washington, Missouri"
    },
    {
      "city": "Rosebud",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rosebud, Missouri"
    },
    {
      "city": "Bland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bland, Missouri"
    },
    {
      "city": "Gasconade",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gasconade, Missouri"
    },
    {
      "city": "Hermann",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hermann, Missouri"
    },
    {
      "city": "Morrison",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Morrison, Missouri"
    },
    {
      "city": "Mount Sterling",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mount Sterling, Missouri"
    },
    {
      "city": "Owensville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Owensville, Missouri"
    },
    {
      "city": "Albany",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Albany, Missouri"
    },
    {
      "city": "Darlington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Darlington, Missouri"
    },
    {
      "city": "Gentry",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gentry, Missouri"
    },
    {
      "city": "King City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "King City, Missouri"
    },
    {
      "city": "Stanberry",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stanberry, Missouri"
    },
    {
      "city": "Mc Fall",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mc Fall, Missouri"
    },
    {
      "city": "Ash Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ash Grove, Missouri"
    },
    {
      "city": "Bois D Arc",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bois D Arc, Missouri"
    },
    {
      "city": "Brookline",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brookline, Missouri"
    },
    {
      "city": "Fair Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fair Grove, Missouri"
    },
    {
      "city": "Republic",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Republic, Missouri"
    },
    {
      "city": "Strafford",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Strafford, Missouri"
    },
    {
      "city": "Turners",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Turners, Missouri"
    },
    {
      "city": "Walnut Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Walnut Grove, Missouri"
    },
    {
      "city": "Willard",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Willard, Missouri"
    },
    {
      "city": "Springfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Springfield, Missouri"
    },
    {
      "city": "Galt",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Galt, Missouri"
    },
    {
      "city": "Laredo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Laredo, Missouri"
    },
    {
      "city": "Spickard",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Spickard, Missouri"
    },
    {
      "city": "Trenton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Trenton, Missouri"
    },
    {
      "city": "Bethany",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bethany, Missouri"
    },
    {
      "city": "Blythedale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blythedale, Missouri"
    },
    {
      "city": "Eagleville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eagleville, Missouri"
    },
    {
      "city": "Hatfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hatfield, Missouri"
    },
    {
      "city": "Martinsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Martinsville, Missouri"
    },
    {
      "city": "New Hampton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Hampton, Missouri"
    },
    {
      "city": "Ridgeway",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ridgeway, Missouri"
    },
    {
      "city": "Cainsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cainsville, Missouri"
    },
    {
      "city": "Blairstown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blairstown, Missouri"
    },
    {
      "city": "Clinton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clinton, Missouri"
    },
    {
      "city": "Deepwater",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Deepwater, Missouri"
    },
    {
      "city": "Montrose",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Montrose, Missouri"
    },
    {
      "city": "Urich",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Urich, Missouri"
    },
    {
      "city": "Calhoun",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Calhoun, Missouri"
    },
    {
      "city": "Windsor",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Windsor, Missouri"
    },
    {
      "city": "Cross Timbers",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cross Timbers, Missouri"
    },
    {
      "city": "Hermitage",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hermitage, Missouri"
    },
    {
      "city": "Pittsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pittsburg, Missouri"
    },
    {
      "city": "Preston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Preston, Missouri"
    },
    {
      "city": "Quincy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Quincy, Missouri"
    },
    {
      "city": "Weaubleau",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Weaubleau, Missouri"
    },
    {
      "city": "Wheatland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wheatland, Missouri"
    },
    {
      "city": "Craig",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Craig, Missouri"
    },
    {
      "city": "Forest City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Forest City, Missouri"
    },
    {
      "city": "Maitland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Maitland, Missouri"
    },
    {
      "city": "Mound City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mound City, Missouri"
    },
    {
      "city": "Oregon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oregon, Missouri"
    },
    {
      "city": "Armstrong",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Armstrong, Missouri"
    },
    {
      "city": "Fayette",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fayette, Missouri"
    },
    {
      "city": "Franklin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Franklin, Missouri"
    },
    {
      "city": "Glasgow",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Glasgow, Missouri"
    },
    {
      "city": "New Franklin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Franklin, Missouri"
    },
    {
      "city": "Mountain View",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mountain View, Missouri"
    },
    {
      "city": "Caulfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Caulfield, Missouri"
    },
    {
      "city": "Brandsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brandsville, Missouri"
    },
    {
      "city": "West Plains",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "West Plains, Missouri"
    },
    {
      "city": "Moody",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Moody, Missouri"
    },
    {
      "city": "Peace Valley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Peace Valley, Missouri"
    },
    {
      "city": "Pomona",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pomona, Missouri"
    },
    {
      "city": "Pottersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pottersville, Missouri"
    },
    {
      "city": "Willow Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Willow Springs, Missouri"
    },
    {
      "city": "Annapolis",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Annapolis, Missouri"
    },
    {
      "city": "Arcadia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arcadia, Missouri"
    },
    {
      "city": "Belleview",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Belleview, Missouri"
    },
    {
      "city": "Des Arc",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Des Arc, Missouri"
    },
    {
      "city": "Ironton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ironton, Missouri"
    },
    {
      "city": "Middle Brook",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Middle Brook, Missouri"
    },
    {
      "city": "Pilot Knob",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pilot Knob, Missouri"
    },
    {
      "city": "Vulcan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vulcan, Missouri"
    },
    {
      "city": "Bixby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bixby, Missouri"
    },
    {
      "city": "Viburnum",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Viburnum, Missouri"
    },
    {
      "city": "Lees Summit",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lees Summit, Missouri"
    },
    {
      "city": "Blue Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blue Springs, Missouri"
    },
    {
      "city": "Buckner",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Buckner, Missouri"
    },
    {
      "city": "Grain Valley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grain Valley, Missouri"
    },
    {
      "city": "Grandview",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grandview, Missouri"
    },
    {
      "city": "Greenwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Greenwood, Missouri"
    },
    {
      "city": "Independence",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Independence, Missouri"
    },
    {
      "city": "Levasy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Levasy, Missouri"
    },
    {
      "city": "Lone Jack",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lone Jack, Missouri"
    },
    {
      "city": "Oak Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oak Grove, Missouri"
    },
    {
      "city": "Sibley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sibley, Missouri"
    },
    {
      "city": "Jasper",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jasper, Missouri"
    },
    {
      "city": "Joplin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Joplin, Missouri"
    },
    {
      "city": "Alba",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Alba, Missouri"
    },
    {
      "city": "Asbury",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Asbury, Missouri"
    },
    {
      "city": "Avilla",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Avilla, Missouri"
    },
    {
      "city": "Carl Junction",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Carl Junction, Missouri"
    },
    {
      "city": "Carterville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Carterville, Missouri"
    },
    {
      "city": "Carthage",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Carthage, Missouri"
    },
    {
      "city": "Duenweg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Duenweg, Missouri"
    },
    {
      "city": "La Russell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "La Russell, Missouri"
    },
    {
      "city": "Neck City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Neck City, Missouri"
    },
    {
      "city": "Oronogo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oronogo, Missouri"
    },
    {
      "city": "Purcell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Purcell, Missouri"
    },
    {
      "city": "Reeds",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Reeds, Missouri"
    },
    {
      "city": "Sarcoxie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sarcoxie, Missouri"
    },
    {
      "city": "Webb City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Webb City, Missouri"
    },
    {
      "city": "Arnold",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arnold, Missouri"
    },
    {
      "city": "Barnhart",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Barnhart, Missouri"
    },
    {
      "city": "Cedar Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cedar Hill, Missouri"
    },
    {
      "city": "Crystal City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Crystal City, Missouri"
    },
    {
      "city": "De Soto",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "De Soto, Missouri"
    },
    {
      "city": "Dittmer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dittmer, Missouri"
    },
    {
      "city": "Festus",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Festus, Missouri"
    },
    {
      "city": "Fletcher",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fletcher, Missouri"
    },
    {
      "city": "Grubville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grubville, Missouri"
    },
    {
      "city": "Hematite",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hematite, Missouri"
    },
    {
      "city": "Herculaneum",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Herculaneum, Missouri"
    },
    {
      "city": "High Ridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "High Ridge, Missouri"
    },
    {
      "city": "Hillsboro",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hillsboro, Missouri"
    },
    {
      "city": "House Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "House Springs, Missouri"
    },
    {
      "city": "Imperial",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Imperial, Missouri"
    },
    {
      "city": "Kimmswick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kimmswick, Missouri"
    },
    {
      "city": "Liguori",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Liguori, Missouri"
    },
    {
      "city": "Mapaville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mapaville, Missouri"
    },
    {
      "city": "Morse Mill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Morse Mill, Missouri"
    },
    {
      "city": "Pevely",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pevely, Missouri"
    },
    {
      "city": "Centerview",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Centerview, Missouri"
    },
    {
      "city": "Holden",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holden, Missouri"
    },
    {
      "city": "Kingsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kingsville, Missouri"
    },
    {
      "city": "Warrensburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Warrensburg, Missouri"
    },
    {
      "city": "Chilhowee",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chilhowee, Missouri"
    },
    {
      "city": "Leeton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leeton, Missouri"
    },
    {
      "city": "Whiteman Air Force Base",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Whiteman Air Force Base, Missouri"
    },
    {
      "city": "Knob Noster",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Knob Noster, Missouri"
    },
    {
      "city": "Knox City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Knox City, Missouri"
    },
    {
      "city": "Newark",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Newark, Missouri"
    },
    {
      "city": "Novelty",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Novelty, Missouri"
    },
    {
      "city": "Plevna",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Plevna, Missouri"
    },
    {
      "city": "Baring",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Baring, Missouri"
    },
    {
      "city": "Edina",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Edina, Missouri"
    },
    {
      "city": "Hurdland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hurdland, Missouri"
    },
    {
      "city": "Eldridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eldridge, Missouri"
    },
    {
      "city": "Falcon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Falcon, Missouri"
    },
    {
      "city": "Lebanon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lebanon, Missouri"
    },
    {
      "city": "Lynchburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lynchburg, Missouri"
    },
    {
      "city": "Conway",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Conway, Missouri"
    },
    {
      "city": "Phillipsburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Phillipsburg, Missouri"
    },
    {
      "city": "Alma",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Alma, Missouri"
    },
    {
      "city": "Bates City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bates City, Missouri"
    },
    {
      "city": "Concordia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Concordia, Missouri"
    },
    {
      "city": "Corder",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Corder, Missouri"
    },
    {
      "city": "Dover",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dover, Missouri"
    },
    {
      "city": "Higginsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Higginsville, Missouri"
    },
    {
      "city": "Lexington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lexington, Missouri"
    },
    {
      "city": "Mayview",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mayview, Missouri"
    },
    {
      "city": "Napoleon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Napoleon, Missouri"
    },
    {
      "city": "Odessa",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Odessa, Missouri"
    },
    {
      "city": "Waverly",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Waverly, Missouri"
    },
    {
      "city": "Wellington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wellington, Missouri"
    },
    {
      "city": "Emma",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Emma, Missouri"
    },
    {
      "city": "Aurora",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Aurora, Missouri"
    },
    {
      "city": "Freistatt",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Freistatt, Missouri"
    },
    {
      "city": "Halltown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Halltown, Missouri"
    },
    {
      "city": "Marionville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marionville, Missouri"
    },
    {
      "city": "Miller",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Miller, Missouri"
    },
    {
      "city": "Mount Vernon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mount Vernon, Missouri"
    },
    {
      "city": "Pierce City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pierce City, Missouri"
    },
    {
      "city": "Stotts City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stotts City, Missouri"
    },
    {
      "city": "Verona",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Verona, Missouri"
    },
    {
      "city": "Canton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Canton, Missouri"
    },
    {
      "city": "Durham",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Durham, Missouri"
    },
    {
      "city": "Ewing",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ewing, Missouri"
    },
    {
      "city": "La Belle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "La Belle, Missouri"
    },
    {
      "city": "La Grange",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "La Grange, Missouri"
    },
    {
      "city": "Lewistown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lewistown, Missouri"
    },
    {
      "city": "Monticello",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Monticello, Missouri"
    },
    {
      "city": "Williamstown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Williamstown, Missouri"
    },
    {
      "city": "Elsberry",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Elsberry, Missouri"
    },
    {
      "city": "Foley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Foley, Missouri"
    },
    {
      "city": "Hawk Point",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hawk Point, Missouri"
    },
    {
      "city": "Moscow Mills",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Moscow Mills, Missouri"
    },
    {
      "city": "Old Monroe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Old Monroe, Missouri"
    },
    {
      "city": "Olney",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Olney, Missouri"
    },
    {
      "city": "Silex",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Silex, Missouri"
    },
    {
      "city": "Troy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Troy, Missouri"
    },
    {
      "city": "Truxton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Truxton, Missouri"
    },
    {
      "city": "Whiteside",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Whiteside, Missouri"
    },
    {
      "city": "Winfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Winfield, Missouri"
    },
    {
      "city": "New Boston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Boston, Missouri"
    },
    {
      "city": "Brookfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brookfield, Missouri"
    },
    {
      "city": "Browning",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Browning, Missouri"
    },
    {
      "city": "Bucklin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bucklin, Missouri"
    },
    {
      "city": "Laclede",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Laclede, Missouri"
    },
    {
      "city": "Linneus",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Linneus, Missouri"
    },
    {
      "city": "Marceline",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marceline, Missouri"
    },
    {
      "city": "Meadville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Meadville, Missouri"
    },
    {
      "city": "Purdin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Purdin, Missouri"
    },
    {
      "city": "Chillicothe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chillicothe, Missouri"
    },
    {
      "city": "Chula",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chula, Missouri"
    },
    {
      "city": "Dawn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dawn, Missouri"
    },
    {
      "city": "Ludlow",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ludlow, Missouri"
    },
    {
      "city": "Mooresville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mooresville, Missouri"
    },
    {
      "city": "Utica",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Utica, Missouri"
    },
    {
      "city": "Wheeling",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wheeling, Missouri"
    },
    {
      "city": "Anderson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Anderson, Missouri"
    },
    {
      "city": "Goodman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Goodman, Missouri"
    },
    {
      "city": "Lanagan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lanagan, Missouri"
    },
    {
      "city": "Noel",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Noel, Missouri"
    },
    {
      "city": "Pineville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pineville, Missouri"
    },
    {
      "city": "Rocky Comfort",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rocky Comfort, Missouri"
    },
    {
      "city": "South West City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "South West City, Missouri"
    },
    {
      "city": "Tiff City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tiff City, Missouri"
    },
    {
      "city": "Powell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Powell, Missouri"
    },
    {
      "city": "Anabel",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Anabel, Missouri"
    },
    {
      "city": "Atlanta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Atlanta, Missouri"
    },
    {
      "city": "Bevier",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bevier, Missouri"
    },
    {
      "city": "Callao",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Callao, Missouri"
    },
    {
      "city": "Elmer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Elmer, Missouri"
    },
    {
      "city": "Ethel",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ethel, Missouri"
    },
    {
      "city": "La Plata",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "La Plata, Missouri"
    },
    {
      "city": "Macon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Macon, Missouri"
    },
    {
      "city": "New Cambria",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Cambria, Missouri"
    },
    {
      "city": "Excello",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Excello, Missouri"
    },
    {
      "city": "Fredericktown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fredericktown, Missouri"
    },
    {
      "city": "Marquand",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marquand, Missouri"
    },
    {
      "city": "Belle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Belle, Missouri"
    },
    {
      "city": "Brinktown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brinktown, Missouri"
    },
    {
      "city": "Vichy",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vichy, Missouri"
    },
    {
      "city": "Vienna",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vienna, Missouri"
    },
    {
      "city": "Hannibal",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hannibal, Missouri"
    },
    {
      "city": "Maywood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Maywood, Missouri"
    },
    {
      "city": "Palmyra",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Palmyra, Missouri"
    },
    {
      "city": "Philadelphia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Philadelphia, Missouri"
    },
    {
      "city": "Taylor",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Taylor, Missouri"
    },
    {
      "city": "Mercer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mercer, Missouri"
    },
    {
      "city": "Princeton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Princeton, Missouri"
    },
    {
      "city": "Brumley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brumley, Missouri"
    },
    {
      "city": "Eldon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eldon, Missouri"
    },
    {
      "city": "Kaiser",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kaiser, Missouri"
    },
    {
      "city": "Olean",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Olean, Missouri"
    },
    {
      "city": "Saint Elizabeth",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Elizabeth, Missouri"
    },
    {
      "city": "Tuscumbia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tuscumbia, Missouri"
    },
    {
      "city": "Ulman",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ulman, Missouri"
    },
    {
      "city": "Iberia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Iberia, Missouri"
    },
    {
      "city": "Anniston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Anniston, Missouri"
    },
    {
      "city": "Bertrand",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bertrand, Missouri"
    },
    {
      "city": "Charleston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Charleston, Missouri"
    },
    {
      "city": "East Prairie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "East Prairie, Missouri"
    },
    {
      "city": "Wolf Island",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wolf Island, Missouri"
    },
    {
      "city": "Wyatt",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wyatt, Missouri"
    },
    {
      "city": "California",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "California, Missouri"
    },
    {
      "city": "Clarksburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clarksburg, Missouri"
    },
    {
      "city": "Fortuna",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fortuna, Missouri"
    },
    {
      "city": "High Point",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "High Point, Missouri"
    },
    {
      "city": "Jamestown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jamestown, Missouri"
    },
    {
      "city": "Latham",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Latham, Missouri"
    },
    {
      "city": "Mc Girk",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mc Girk, Missouri"
    },
    {
      "city": "Tipton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tipton, Missouri"
    },
    {
      "city": "Monroe City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Monroe City, Missouri"
    },
    {
      "city": "Holliday",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holliday, Missouri"
    },
    {
      "city": "Madison",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Madison, Missouri"
    },
    {
      "city": "Paris",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Paris, Missouri"
    },
    {
      "city": "Santa Fe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Santa Fe, Missouri"
    },
    {
      "city": "Stoutsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stoutsville, Missouri"
    },
    {
      "city": "Bellflower",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bellflower, Missouri"
    },
    {
      "city": "High Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "High Hill, Missouri"
    },
    {
      "city": "Jonesburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jonesburg, Missouri"
    },
    {
      "city": "Middletown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Middletown, Missouri"
    },
    {
      "city": "Montgomery City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Montgomery City, Missouri"
    },
    {
      "city": "New Florence",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Florence, Missouri"
    },
    {
      "city": "Wellsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wellsville, Missouri"
    },
    {
      "city": "Rhineland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rhineland, Missouri"
    },
    {
      "city": "Barnett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Barnett, Missouri"
    },
    {
      "city": "Gravois Mills",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gravois Mills, Missouri"
    },
    {
      "city": "Laurie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Laurie, Missouri"
    },
    {
      "city": "Rocky Mount",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rocky Mount, Missouri"
    },
    {
      "city": "Stover",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stover, Missouri"
    },
    {
      "city": "Versailles",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Versailles, Missouri"
    },
    {
      "city": "Florence",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Florence, Missouri"
    },
    {
      "city": "Syracuse",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Syracuse, Missouri"
    },
    {
      "city": "Canalou",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Canalou, Missouri"
    },
    {
      "city": "Catron",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Catron, Missouri"
    },
    {
      "city": "Gideon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gideon, Missouri"
    },
    {
      "city": "Kewanee",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kewanee, Missouri"
    },
    {
      "city": "Lilbourn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lilbourn, Missouri"
    },
    {
      "city": "Marston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marston, Missouri"
    },
    {
      "city": "Matthews",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Matthews, Missouri"
    },
    {
      "city": "Morehouse",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Morehouse, Missouri"
    },
    {
      "city": "New Madrid",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Madrid, Missouri"
    },
    {
      "city": "Parma",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Parma, Missouri"
    },
    {
      "city": "Portageville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Portageville, Missouri"
    },
    {
      "city": "Risco",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Risco, Missouri"
    },
    {
      "city": "Tallapoosa",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tallapoosa, Missouri"
    },
    {
      "city": "Diamond",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Diamond, Missouri"
    },
    {
      "city": "Fairview",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fairview, Missouri"
    },
    {
      "city": "Granby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Granby, Missouri"
    },
    {
      "city": "Neosho",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Neosho, Missouri"
    },
    {
      "city": "Newtonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Newtonia, Missouri"
    },
    {
      "city": "Racine",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Racine, Missouri"
    },
    {
      "city": "Saginaw",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saginaw, Missouri"
    },
    {
      "city": "Seneca",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Seneca, Missouri"
    },
    {
      "city": "Stark City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stark City, Missouri"
    },
    {
      "city": "Stella",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Stella, Missouri"
    },
    {
      "city": "Wentworth",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wentworth, Missouri"
    },
    {
      "city": "Barnard",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Barnard, Missouri"
    },
    {
      "city": "Burlington Junction",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Burlington Junction, Missouri"
    },
    {
      "city": "Clearmont",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clearmont, Missouri"
    },
    {
      "city": "Clyde",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clyde, Missouri"
    },
    {
      "city": "Conception",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Conception, Missouri"
    },
    {
      "city": "Conception Junction",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Conception Junction, Missouri"
    },
    {
      "city": "Elmo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Elmo, Missouri"
    },
    {
      "city": "Graham",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Graham, Missouri"
    },
    {
      "city": "Guilford",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Guilford, Missouri"
    },
    {
      "city": "Hopkins",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hopkins, Missouri"
    },
    {
      "city": "Maryville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Maryville, Missouri"
    },
    {
      "city": "Parnell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Parnell, Missouri"
    },
    {
      "city": "Pickering",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pickering, Missouri"
    },
    {
      "city": "Ravenwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ravenwood, Missouri"
    },
    {
      "city": "Skidmore",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Skidmore, Missouri"
    },
    {
      "city": "Alton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Alton, Missouri"
    },
    {
      "city": "Couch",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Couch, Missouri"
    },
    {
      "city": "Koshkonong",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Koshkonong, Missouri"
    },
    {
      "city": "Myrtle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Myrtle, Missouri"
    },
    {
      "city": "Thayer",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Thayer, Missouri"
    },
    {
      "city": "Argyle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Argyle, Missouri"
    },
    {
      "city": "Bonnots Mill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bonnots Mill, Missouri"
    },
    {
      "city": "Chamois",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chamois, Missouri"
    },
    {
      "city": "Freeburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Freeburg, Missouri"
    },
    {
      "city": "Koeltztown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Koeltztown, Missouri"
    },
    {
      "city": "Linn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Linn, Missouri"
    },
    {
      "city": "Loose Creek",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Loose Creek, Missouri"
    },
    {
      "city": "Meta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Meta, Missouri"
    },
    {
      "city": "Westphalia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Westphalia, Missouri"
    },
    {
      "city": "Bakersfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bakersfield, Missouri"
    },
    {
      "city": "Brixey",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brixey, Missouri"
    },
    {
      "city": "Dora",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dora, Missouri"
    },
    {
      "city": "Gainesville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gainesville, Missouri"
    },
    {
      "city": "Hardenville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hardenville, Missouri"
    },
    {
      "city": "Isabella",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Isabella, Missouri"
    },
    {
      "city": "Noble",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Noble, Missouri"
    },
    {
      "city": "Pontiac",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pontiac, Missouri"
    },
    {
      "city": "Rockbridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rockbridge, Missouri"
    },
    {
      "city": "Tecumseh",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tecumseh, Missouri"
    },
    {
      "city": "Theodosia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Theodosia, Missouri"
    },
    {
      "city": "Thornfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Thornfield, Missouri"
    },
    {
      "city": "Udall",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Udall, Missouri"
    },
    {
      "city": "Wasola",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wasola, Missouri"
    },
    {
      "city": "Zanoni",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Zanoni, Missouri"
    },
    {
      "city": "Braggadocio",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Braggadocio, Missouri"
    },
    {
      "city": "Bragg City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bragg City, Missouri"
    },
    {
      "city": "Caruthersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Caruthersville, Missouri"
    },
    {
      "city": "Cooter",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cooter, Missouri"
    },
    {
      "city": "Deering",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Deering, Missouri"
    },
    {
      "city": "Gobler",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gobler, Missouri"
    },
    {
      "city": "Hayti",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hayti, Missouri"
    },
    {
      "city": "Holland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Holland, Missouri"
    },
    {
      "city": "Steele",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Steele, Missouri"
    },
    {
      "city": "Wardell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wardell, Missouri"
    },
    {
      "city": "Altenburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Altenburg, Missouri"
    },
    {
      "city": "Brazeau",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brazeau, Missouri"
    },
    {
      "city": "Farrar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Farrar, Missouri"
    },
    {
      "city": "Frohna",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Frohna, Missouri"
    },
    {
      "city": "Perryville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Perryville, Missouri"
    },
    {
      "city": "Mc Bride",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mc Bride, Missouri"
    },
    {
      "city": "Uniontown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Uniontown, Missouri"
    },
    {
      "city": "Sedalia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sedalia, Missouri"
    },
    {
      "city": "Green Ridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Green Ridge, Missouri"
    },
    {
      "city": "Houstonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Houstonia, Missouri"
    },
    {
      "city": "Hughesville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hughesville, Missouri"
    },
    {
      "city": "La Monte",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "La Monte, Missouri"
    },
    {
      "city": "Mora",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mora, Missouri"
    },
    {
      "city": "Smithton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Smithton, Missouri"
    },
    {
      "city": "Rolla",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rolla, Missouri"
    },
    {
      "city": "Beulah",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Beulah, Missouri"
    },
    {
      "city": "Duke",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Duke, Missouri"
    },
    {
      "city": "Edgar Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Edgar Springs, Missouri"
    },
    {
      "city": "Jerome",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jerome, Missouri"
    },
    {
      "city": "Newburg",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Newburg, Missouri"
    },
    {
      "city": "Saint James",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint James, Missouri"
    },
    {
      "city": "Annada",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Annada, Missouri"
    },
    {
      "city": "Bowling Green",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bowling Green, Missouri"
    },
    {
      "city": "Clarksville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clarksville, Missouri"
    },
    {
      "city": "Curryville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Curryville, Missouri"
    },
    {
      "city": "Eolia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eolia, Missouri"
    },
    {
      "city": "Louisiana",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Louisiana, Missouri"
    },
    {
      "city": "Ashburn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ashburn, Missouri"
    },
    {
      "city": "Frankford",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Frankford, Missouri"
    },
    {
      "city": "Camden Point",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Camden Point, Missouri"
    },
    {
      "city": "Farley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Farley, Missouri"
    },
    {
      "city": "Platte City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Platte City, Missouri"
    },
    {
      "city": "Waldron",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Waldron, Missouri"
    },
    {
      "city": "Weston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Weston, Missouri"
    },
    {
      "city": "Riverside",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Riverside, Missouri"
    },
    {
      "city": "Dearborn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dearborn, Missouri"
    },
    {
      "city": "Edgerton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Edgerton, Missouri"
    },
    {
      "city": "Aldrich",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Aldrich, Missouri"
    },
    {
      "city": "Bolivar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bolivar, Missouri"
    },
    {
      "city": "Brighton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brighton, Missouri"
    },
    {
      "city": "Dunnegan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dunnegan, Missouri"
    },
    {
      "city": "Eudora",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eudora, Missouri"
    },
    {
      "city": "Fair Play",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fair Play, Missouri"
    },
    {
      "city": "Flemington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Flemington, Missouri"
    },
    {
      "city": "Half Way",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Half Way, Missouri"
    },
    {
      "city": "Humansville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Humansville, Missouri"
    },
    {
      "city": "Morrisville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Morrisville, Missouri"
    },
    {
      "city": "Pleasant Hope",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pleasant Hope, Missouri"
    },
    {
      "city": "Polk",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Polk, Missouri"
    },
    {
      "city": "Crocker",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Crocker, Missouri"
    },
    {
      "city": "Devils Elbow",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Devils Elbow, Missouri"
    },
    {
      "city": "Dixon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dixon, Missouri"
    },
    {
      "city": "Fort Leonard Wood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fort Leonard Wood, Missouri"
    },
    {
      "city": "Laquey",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Laquey, Missouri"
    },
    {
      "city": "Richland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Richland, Missouri"
    },
    {
      "city": "Waynesville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Waynesville, Missouri"
    },
    {
      "city": "Saint Robert",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Robert, Missouri"
    },
    {
      "city": "Livonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Livonia, Missouri"
    },
    {
      "city": "Unionville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Unionville, Missouri"
    },
    {
      "city": "Worthington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Worthington, Missouri"
    },
    {
      "city": "Lucerne",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lucerne, Missouri"
    },
    {
      "city": "Powersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Powersville, Missouri"
    },
    {
      "city": "Center",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Center, Missouri"
    },
    {
      "city": "New London",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New London, Missouri"
    },
    {
      "city": "Perry",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Perry, Missouri"
    },
    {
      "city": "Saverton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saverton, Missouri"
    },
    {
      "city": "Cairo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cairo, Missouri"
    },
    {
      "city": "Clark",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clark, Missouri"
    },
    {
      "city": "Clifton Hill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clifton Hill, Missouri"
    },
    {
      "city": "Higbee",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Higbee, Missouri"
    },
    {
      "city": "Huntsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Huntsville, Missouri"
    },
    {
      "city": "Jacksonville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Jacksonville, Missouri"
    },
    {
      "city": "Moberly",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Moberly, Missouri"
    },
    {
      "city": "Renick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Renick, Missouri"
    },
    {
      "city": "Camden",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Camden, Missouri"
    },
    {
      "city": "Hardin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hardin, Missouri"
    },
    {
      "city": "Henrietta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Henrietta, Missouri"
    },
    {
      "city": "Lawson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lawson, Missouri"
    },
    {
      "city": "Orrick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Orrick, Missouri"
    },
    {
      "city": "Rayville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rayville, Missouri"
    },
    {
      "city": "Richmond",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Richmond, Missouri"
    },
    {
      "city": "Black",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Black, Missouri"
    },
    {
      "city": "Bunker",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bunker, Missouri"
    },
    {
      "city": "Centerville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Centerville, Missouri"
    },
    {
      "city": "Ellington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ellington, Missouri"
    },
    {
      "city": "Lesterville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lesterville, Missouri"
    },
    {
      "city": "Redford",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Redford, Missouri"
    },
    {
      "city": "Reynolds",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Reynolds, Missouri"
    },
    {
      "city": "Briar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Briar, Missouri"
    },
    {
      "city": "Doniphan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Doniphan, Missouri"
    },
    {
      "city": "Fairdealing",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fairdealing, Missouri"
    },
    {
      "city": "Gatewood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gatewood, Missouri"
    },
    {
      "city": "Naylor",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Naylor, Missouri"
    },
    {
      "city": "Oxly",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oxly, Missouri"
    },
    {
      "city": "Saint Charles",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Charles, Missouri"
    },
    {
      "city": "Augusta",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Augusta, Missouri"
    },
    {
      "city": "Cottleville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cottleville, Missouri"
    },
    {
      "city": "Defiance",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Defiance, Missouri"
    },
    {
      "city": "Flinthill",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Flinthill, Missouri"
    },
    {
      "city": "Foristell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Foristell, Missouri"
    },
    {
      "city": "New Melle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "New Melle, Missouri"
    },
    {
      "city": "O Fallon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "O Fallon, Missouri"
    },
    {
      "city": "Lake Saint Louis",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lake Saint Louis, Missouri"
    },
    {
      "city": "Portage Des Sioux",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Portage Des Sioux, Missouri"
    },
    {
      "city": "Saint Peters",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Peters, Missouri"
    },
    {
      "city": "Wentzville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wentzville, Missouri"
    },
    {
      "city": "West Alton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "West Alton, Missouri"
    },
    {
      "city": "Appleton City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Appleton City, Missouri"
    },
    {
      "city": "Collins",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Collins, Missouri"
    },
    {
      "city": "Lowry City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lowry City, Missouri"
    },
    {
      "city": "Osceola",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Osceola, Missouri"
    },
    {
      "city": "Roscoe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Roscoe, Missouri"
    },
    {
      "city": "Bloomsdale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bloomsdale, Missouri"
    },
    {
      "city": "Sainte Genevieve",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sainte Genevieve, Missouri"
    },
    {
      "city": "Saint Mary",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Mary, Missouri"
    },
    {
      "city": "French Village",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "French Village, Missouri"
    },
    {
      "city": "Valles Mines",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Valles Mines, Missouri"
    },
    {
      "city": "Park Hills",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Park Hills, Missouri"
    },
    {
      "city": "Bismarck",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bismarck, Missouri"
    },
    {
      "city": "Blackwell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blackwell, Missouri"
    },
    {
      "city": "Bonne Terre",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bonne Terre, Missouri"
    },
    {
      "city": "Doe Run",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Doe Run, Missouri"
    },
    {
      "city": "Farmington",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Farmington, Missouri"
    },
    {
      "city": "Knob Lick",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Knob Lick, Missouri"
    },
    {
      "city": "Leadwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leadwood, Missouri"
    },
    {
      "city": "Chesterfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chesterfield, Missouri"
    },
    {
      "city": "Ballwin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ballwin, Missouri"
    },
    {
      "city": "Eureka",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eureka, Missouri"
    },
    {
      "city": "Fenton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fenton, Missouri"
    },
    {
      "city": "Florissant",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Florissant, Missouri"
    },
    {
      "city": "Glencoe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Glencoe, Missouri"
    },
    {
      "city": "Grover",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grover, Missouri"
    },
    {
      "city": "Hazelwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hazelwood, Missouri"
    },
    {
      "city": "Maryland Heights",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Maryland Heights, Missouri"
    },
    {
      "city": "Bridgeton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bridgeton, Missouri"
    },
    {
      "city": "Earth City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Earth City, Missouri"
    },
    {
      "city": "Saint Ann",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Ann, Missouri"
    },
    {
      "city": "Valley Park",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Valley Park, Missouri"
    },
    {
      "city": "Saint Louis",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Saint Louis, Missouri"
    },
    {
      "city": "Arrow Rock",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arrow Rock, Missouri"
    },
    {
      "city": "Blackburn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blackburn, Missouri"
    },
    {
      "city": "Gilliam",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gilliam, Missouri"
    },
    {
      "city": "Malta Bend",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Malta Bend, Missouri"
    },
    {
      "city": "Marshall",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marshall, Missouri"
    },
    {
      "city": "Miami",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Miami, Missouri"
    },
    {
      "city": "Nelson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Nelson, Missouri"
    },
    {
      "city": "Slater",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Slater, Missouri"
    },
    {
      "city": "Sweet Springs",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sweet Springs, Missouri"
    },
    {
      "city": "Coatsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Coatsville, Missouri"
    },
    {
      "city": "Downing",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Downing, Missouri"
    },
    {
      "city": "Glenwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Glenwood, Missouri"
    },
    {
      "city": "Lancaster",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lancaster, Missouri"
    },
    {
      "city": "Queen City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Queen City, Missouri"
    },
    {
      "city": "Arbela",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Arbela, Missouri"
    },
    {
      "city": "Granger",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Granger, Missouri"
    },
    {
      "city": "Gorin",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Gorin, Missouri"
    },
    {
      "city": "Memphis",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Memphis, Missouri"
    },
    {
      "city": "Rutledge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rutledge, Missouri"
    },
    {
      "city": "Benton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Benton, Missouri"
    },
    {
      "city": "Chaffee",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Chaffee, Missouri"
    },
    {
      "city": "Commerce",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Commerce, Missouri"
    },
    {
      "city": "Kelso",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kelso, Missouri"
    },
    {
      "city": "Morley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Morley, Missouri"
    },
    {
      "city": "Oran",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Oran, Missouri"
    },
    {
      "city": "Perkins",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Perkins, Missouri"
    },
    {
      "city": "Scott City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Scott City, Missouri"
    },
    {
      "city": "Vanduser",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Vanduser, Missouri"
    },
    {
      "city": "Sikeston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sikeston, Missouri"
    },
    {
      "city": "Blodgett",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blodgett, Missouri"
    },
    {
      "city": "Birch Tree",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Birch Tree, Missouri"
    },
    {
      "city": "Eminence",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eminence, Missouri"
    },
    {
      "city": "Montier",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Montier, Missouri"
    },
    {
      "city": "Winona",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Winona, Missouri"
    },
    {
      "city": "Bethel",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bethel, Missouri"
    },
    {
      "city": "Clarence",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clarence, Missouri"
    },
    {
      "city": "Emden",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Emden, Missouri"
    },
    {
      "city": "Hunnewell",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hunnewell, Missouri"
    },
    {
      "city": "Lentner",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lentner, Missouri"
    },
    {
      "city": "Leonard",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Leonard, Missouri"
    },
    {
      "city": "Shelbina",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Shelbina, Missouri"
    },
    {
      "city": "Shelbyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Shelbyville, Missouri"
    },
    {
      "city": "Advance",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Advance, Missouri"
    },
    {
      "city": "Bell City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bell City, Missouri"
    },
    {
      "city": "Brownwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Brownwood, Missouri"
    },
    {
      "city": "Bernie",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bernie, Missouri"
    },
    {
      "city": "Bloomfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bloomfield, Missouri"
    },
    {
      "city": "Dexter",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dexter, Missouri"
    },
    {
      "city": "Essex",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Essex, Missouri"
    },
    {
      "city": "Grayridge",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grayridge, Missouri"
    },
    {
      "city": "Dudley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dudley, Missouri"
    },
    {
      "city": "Puxico",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Puxico, Missouri"
    },
    {
      "city": "Blue Eye",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Blue Eye, Missouri"
    },
    {
      "city": "Cape Fair",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cape Fair, Missouri"
    },
    {
      "city": "Crane",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Crane, Missouri"
    },
    {
      "city": "Galena",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Galena, Missouri"
    },
    {
      "city": "Hurley",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hurley, Missouri"
    },
    {
      "city": "Lampe",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lampe, Missouri"
    },
    {
      "city": "Kimberling City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kimberling City, Missouri"
    },
    {
      "city": "Ponce De Leon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ponce De Leon, Missouri"
    },
    {
      "city": "Reeds Spring",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Reeds Spring, Missouri"
    },
    {
      "city": "Green Castle",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Green Castle, Missouri"
    },
    {
      "city": "Green City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Green City, Missouri"
    },
    {
      "city": "Milan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Milan, Missouri"
    },
    {
      "city": "Pollock",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Pollock, Missouri"
    },
    {
      "city": "Winigan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Winigan, Missouri"
    },
    {
      "city": "Harris",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Harris, Missouri"
    },
    {
      "city": "Humphreys",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Humphreys, Missouri"
    },
    {
      "city": "Newtown",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Newtown, Missouri"
    },
    {
      "city": "Bradleyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bradleyville, Missouri"
    },
    {
      "city": "Branson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Branson, Missouri"
    },
    {
      "city": "Cedarcreek",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cedarcreek, Missouri"
    },
    {
      "city": "Forsyth",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Forsyth, Missouri"
    },
    {
      "city": "Hollister",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hollister, Missouri"
    },
    {
      "city": "Kirbyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kirbyville, Missouri"
    },
    {
      "city": "Kissee Mills",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Kissee Mills, Missouri"
    },
    {
      "city": "Point Lookout",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Point Lookout, Missouri"
    },
    {
      "city": "Powersite",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Powersite, Missouri"
    },
    {
      "city": "Protem",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Protem, Missouri"
    },
    {
      "city": "Ridgedale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Ridgedale, Missouri"
    },
    {
      "city": "Rockaway Beach",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rockaway Beach, Missouri"
    },
    {
      "city": "Rueter",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rueter, Missouri"
    },
    {
      "city": "Taneyville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Taneyville, Missouri"
    },
    {
      "city": "Walnut Shade",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Walnut Shade, Missouri"
    },
    {
      "city": "Bucyrus",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bucyrus, Missouri"
    },
    {
      "city": "Elk Creek",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Elk Creek, Missouri"
    },
    {
      "city": "Eunice",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Eunice, Missouri"
    },
    {
      "city": "Hartshorn",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hartshorn, Missouri"
    },
    {
      "city": "Houston",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Houston, Missouri"
    },
    {
      "city": "Huggins",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Huggins, Missouri"
    },
    {
      "city": "Licking",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Licking, Missouri"
    },
    {
      "city": "Plato",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Plato, Missouri"
    },
    {
      "city": "Raymondville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Raymondville, Missouri"
    },
    {
      "city": "Roby",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Roby, Missouri"
    },
    {
      "city": "Solo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Solo, Missouri"
    },
    {
      "city": "Success",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Success, Missouri"
    },
    {
      "city": "Summersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Summersville, Missouri"
    },
    {
      "city": "Yukon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Yukon, Missouri"
    },
    {
      "city": "Cabool",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cabool, Missouri"
    },
    {
      "city": "Bronaugh",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Bronaugh, Missouri"
    },
    {
      "city": "Deerfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Deerfield, Missouri"
    },
    {
      "city": "Harwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Harwood, Missouri"
    },
    {
      "city": "Metz",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Metz, Missouri"
    },
    {
      "city": "Milo",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Milo, Missouri"
    },
    {
      "city": "Moundville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Moundville, Missouri"
    },
    {
      "city": "Nevada",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Nevada, Missouri"
    },
    {
      "city": "Richards",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Richards, Missouri"
    },
    {
      "city": "Schell City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Schell City, Missouri"
    },
    {
      "city": "Sheldon",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sheldon, Missouri"
    },
    {
      "city": "Walker",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Walker, Missouri"
    },
    {
      "city": "Dutzow",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Dutzow, Missouri"
    },
    {
      "city": "Marthasville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marthasville, Missouri"
    },
    {
      "city": "Treloar",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Treloar, Missouri"
    },
    {
      "city": "Truesdale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Truesdale, Missouri"
    },
    {
      "city": "Warrenton",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Warrenton, Missouri"
    },
    {
      "city": "Wright City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wright City, Missouri"
    },
    {
      "city": "Richwoods",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Richwoods, Missouri"
    },
    {
      "city": "Belgrade",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Belgrade, Missouri"
    },
    {
      "city": "Cadet",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cadet, Missouri"
    },
    {
      "city": "Caledonia",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Caledonia, Missouri"
    },
    {
      "city": "Irondale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Irondale, Missouri"
    },
    {
      "city": "Mineral Point",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mineral Point, Missouri"
    },
    {
      "city": "Potosi",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Potosi, Missouri"
    },
    {
      "city": "Tiff",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Tiff, Missouri"
    },
    {
      "city": "Cascade",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Cascade, Missouri"
    },
    {
      "city": "Mc Gee",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mc Gee, Missouri"
    },
    {
      "city": "Clubb",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Clubb, Missouri"
    },
    {
      "city": "Greenville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Greenville, Missouri"
    },
    {
      "city": "Lodi",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lodi, Missouri"
    },
    {
      "city": "Lowndes",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Lowndes, Missouri"
    },
    {
      "city": "Mill Spring",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mill Spring, Missouri"
    },
    {
      "city": "Patterson",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Patterson, Missouri"
    },
    {
      "city": "Piedmont",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Piedmont, Missouri"
    },
    {
      "city": "Silva",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Silva, Missouri"
    },
    {
      "city": "Wappapello",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Wappapello, Missouri"
    },
    {
      "city": "Williamsville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Williamsville, Missouri"
    },
    {
      "city": "Diggins",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Diggins, Missouri"
    },
    {
      "city": "Elkland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Elkland, Missouri"
    },
    {
      "city": "Fordland",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Fordland, Missouri"
    },
    {
      "city": "Marshfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Marshfield, Missouri"
    },
    {
      "city": "Niangua",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Niangua, Missouri"
    },
    {
      "city": "Rogersville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Rogersville, Missouri"
    },
    {
      "city": "Seymour",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Seymour, Missouri"
    },
    {
      "city": "Allendale",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Allendale, Missouri"
    },
    {
      "city": "Denver",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Denver, Missouri"
    },
    {
      "city": "Grant City",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grant City, Missouri"
    },
    {
      "city": "Sheridan",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Sheridan, Missouri"
    },
    {
      "city": "Worth",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Worth, Missouri"
    },
    {
      "city": "Graff",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Graff, Missouri"
    },
    {
      "city": "Grovespring",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Grovespring, Missouri"
    },
    {
      "city": "Hartville",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Hartville, Missouri"
    },
    {
      "city": "Macomb",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Macomb, Missouri"
    },
    {
      "city": "Mansfield",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mansfield, Missouri"
    },
    {
      "city": "Mountain Grove",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Mountain Grove, Missouri"
    },
    {
      "city": "Norwood",
      "state": "Missouri",
      "shortState": "MO",
      "formatedCity": "Norwood, Missouri"
    },
    {
      "city": "Natchez",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Natchez, Mississippi"
    },
    {
      "city": "Sibley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sibley, Mississippi"
    },
    {
      "city": "Washington",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Washington, Mississippi"
    },
    {
      "city": "Corinth",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Corinth, Mississippi"
    },
    {
      "city": "Glen",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Glen, Mississippi"
    },
    {
      "city": "Rienzi",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rienzi, Mississippi"
    },
    {
      "city": "Crosby",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Crosby, Mississippi"
    },
    {
      "city": "Gloster",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gloster, Mississippi"
    },
    {
      "city": "Liberty",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Liberty, Mississippi"
    },
    {
      "city": "Smithdale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Smithdale, Mississippi"
    },
    {
      "city": "Ethel",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ethel, Mississippi"
    },
    {
      "city": "Kosciusko",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Kosciusko, Mississippi"
    },
    {
      "city": "Mc Adams",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Adams, Mississippi"
    },
    {
      "city": "Mc Cool",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Cool, Mississippi"
    },
    {
      "city": "Sallis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sallis, Mississippi"
    },
    {
      "city": "Ashland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ashland, Mississippi"
    },
    {
      "city": "Hickory Flat",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hickory Flat, Mississippi"
    },
    {
      "city": "Michigan City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Michigan City, Mississippi"
    },
    {
      "city": "Alligator",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Alligator, Mississippi"
    },
    {
      "city": "Benoit",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Benoit, Mississippi"
    },
    {
      "city": "Beulah",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Beulah, Mississippi"
    },
    {
      "city": "Boyle",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Boyle, Mississippi"
    },
    {
      "city": "Cleveland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Cleveland, Mississippi"
    },
    {
      "city": "Duncan",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Duncan, Mississippi"
    },
    {
      "city": "Gunnison",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gunnison, Mississippi"
    },
    {
      "city": "Merigold",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Merigold, Mississippi"
    },
    {
      "city": "Mound Bayou",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mound Bayou, Mississippi"
    },
    {
      "city": "Pace",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pace, Mississippi"
    },
    {
      "city": "Rosedale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rosedale, Mississippi"
    },
    {
      "city": "Scott",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Scott, Mississippi"
    },
    {
      "city": "Shaw",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Shaw, Mississippi"
    },
    {
      "city": "Shelby",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Shelby, Mississippi"
    },
    {
      "city": "Winstonville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Winstonville, Mississippi"
    },
    {
      "city": "Derma",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Derma, Mississippi"
    },
    {
      "city": "Vardaman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vardaman, Mississippi"
    },
    {
      "city": "Banner",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Banner, Mississippi"
    },
    {
      "city": "Big Creek",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Big Creek, Mississippi"
    },
    {
      "city": "Bruce",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bruce, Mississippi"
    },
    {
      "city": "Calhoun City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Calhoun City, Mississippi"
    },
    {
      "city": "Pittsboro",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pittsboro, Mississippi"
    },
    {
      "city": "Slate Spring",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Slate Spring, Mississippi"
    },
    {
      "city": "Mantee",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mantee, Mississippi"
    },
    {
      "city": "Carrollton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Carrollton, Mississippi"
    },
    {
      "city": "Coila",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Coila, Mississippi"
    },
    {
      "city": "Mc Carley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Carley, Mississippi"
    },
    {
      "city": "North Carrollton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "North Carrollton, Mississippi"
    },
    {
      "city": "Sidon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sidon, Mississippi"
    },
    {
      "city": "Vaiden",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vaiden, Mississippi"
    },
    {
      "city": "Houlka",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Houlka, Mississippi"
    },
    {
      "city": "Houston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Houston, Mississippi"
    },
    {
      "city": "Okolona",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Okolona, Mississippi"
    },
    {
      "city": "Trebloc",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Trebloc, Mississippi"
    },
    {
      "city": "Van Vleet",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Van Vleet, Mississippi"
    },
    {
      "city": "Woodland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Woodland, Mississippi"
    },
    {
      "city": "Ackerman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ackerman, Mississippi"
    },
    {
      "city": "French Camp",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "French Camp, Mississippi"
    },
    {
      "city": "Weir",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Weir, Mississippi"
    },
    {
      "city": "Hermanville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hermanville, Mississippi"
    },
    {
      "city": "Pattison",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pattison, Mississippi"
    },
    {
      "city": "Port Gibson",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Port Gibson, Mississippi"
    },
    {
      "city": "Enterprise",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Enterprise, Mississippi"
    },
    {
      "city": "Pachuta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pachuta, Mississippi"
    },
    {
      "city": "Quitman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Quitman, Mississippi"
    },
    {
      "city": "Shubuta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Shubuta, Mississippi"
    },
    {
      "city": "Stonewall",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Stonewall, Mississippi"
    },
    {
      "city": "Cedarbluff",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Cedarbluff, Mississippi"
    },
    {
      "city": "Montpelier",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Montpelier, Mississippi"
    },
    {
      "city": "Pheba",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pheba, Mississippi"
    },
    {
      "city": "West Point",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "West Point, Mississippi"
    },
    {
      "city": "Clarksdale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Clarksdale, Mississippi"
    },
    {
      "city": "Coahoma",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Coahoma, Mississippi"
    },
    {
      "city": "Farrell",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Farrell, Mississippi"
    },
    {
      "city": "Friars Point",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Friars Point, Mississippi"
    },
    {
      "city": "Jonestown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Jonestown, Mississippi"
    },
    {
      "city": "Lula",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lula, Mississippi"
    },
    {
      "city": "Lyon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lyon, Mississippi"
    },
    {
      "city": "Sherard",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sherard, Mississippi"
    },
    {
      "city": "Dublin",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Dublin, Mississippi"
    },
    {
      "city": "Rena Lara",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rena Lara, Mississippi"
    },
    {
      "city": "Crystal Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Crystal Springs, Mississippi"
    },
    {
      "city": "Gallman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gallman, Mississippi"
    },
    {
      "city": "Georgetown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Georgetown, Mississippi"
    },
    {
      "city": "Hazlehurst",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hazlehurst, Mississippi"
    },
    {
      "city": "Wesson",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Wesson, Mississippi"
    },
    {
      "city": "Mount Olive",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mount Olive, Mississippi"
    },
    {
      "city": "Collins",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Collins, Mississippi"
    },
    {
      "city": "Seminary",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Seminary, Mississippi"
    },
    {
      "city": "Hernando",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hernando, Mississippi"
    },
    {
      "city": "Horn Lake",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Horn Lake, Mississippi"
    },
    {
      "city": "Lake Cormorant",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lake Cormorant, Mississippi"
    },
    {
      "city": "Nesbit",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Nesbit, Mississippi"
    },
    {
      "city": "Olive Branch",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Olive Branch, Mississippi"
    },
    {
      "city": "Southaven",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Southaven, Mississippi"
    },
    {
      "city": "Walls",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Walls, Mississippi"
    },
    {
      "city": "Hattiesburg",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hattiesburg, Mississippi"
    },
    {
      "city": "Brooklyn",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Brooklyn, Mississippi"
    },
    {
      "city": "Petal",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Petal, Mississippi"
    },
    {
      "city": "Bude",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bude, Mississippi"
    },
    {
      "city": "Mc Call Creek",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Call Creek, Mississippi"
    },
    {
      "city": "Meadville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Meadville, Mississippi"
    },
    {
      "city": "Roxie",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Roxie, Mississippi"
    },
    {
      "city": "Lucedale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lucedale, Mississippi"
    },
    {
      "city": "State Line",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "State Line, Mississippi"
    },
    {
      "city": "Leakesville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Leakesville, Mississippi"
    },
    {
      "city": "Mc Lain",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Lain, Mississippi"
    },
    {
      "city": "Neely",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Neely, Mississippi"
    },
    {
      "city": "Grenada",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Grenada, Mississippi"
    },
    {
      "city": "Elliott",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Elliott, Mississippi"
    },
    {
      "city": "Gore Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gore Springs, Mississippi"
    },
    {
      "city": "Holcomb",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Holcomb, Mississippi"
    },
    {
      "city": "Tie Plant",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tie Plant, Mississippi"
    },
    {
      "city": "Bay Saint Louis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bay Saint Louis, Mississippi"
    },
    {
      "city": "Stennis Space Center",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Stennis Space Center, Mississippi"
    },
    {
      "city": "Diamondhead",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Diamondhead, Mississippi"
    },
    {
      "city": "Kiln",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Kiln, Mississippi"
    },
    {
      "city": "Lakeshore",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lakeshore, Mississippi"
    },
    {
      "city": "Pearlington",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pearlington, Mississippi"
    },
    {
      "city": "Waveland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Waveland, Mississippi"
    },
    {
      "city": "Gulfport",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gulfport, Mississippi"
    },
    {
      "city": "Biloxi",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Biloxi, Mississippi"
    },
    {
      "city": "Diberville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Diberville, Mississippi"
    },
    {
      "city": "Long Beach",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Long Beach, Mississippi"
    },
    {
      "city": "Pass Christian",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pass Christian, Mississippi"
    },
    {
      "city": "Saucier",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Saucier, Mississippi"
    },
    {
      "city": "Bolton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bolton, Mississippi"
    },
    {
      "city": "Clinton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Clinton, Mississippi"
    },
    {
      "city": "Edwards",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Edwards, Mississippi"
    },
    {
      "city": "Raymond",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Raymond, Mississippi"
    },
    {
      "city": "Terry",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Terry, Mississippi"
    },
    {
      "city": "Tougaloo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tougaloo, Mississippi"
    },
    {
      "city": "Utica",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Utica, Mississippi"
    },
    {
      "city": "Jackson",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Jackson, Mississippi"
    },
    {
      "city": "Byram",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Byram, Mississippi"
    },
    {
      "city": "Cruger",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Cruger, Mississippi"
    },
    {
      "city": "Durant",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Durant, Mississippi"
    },
    {
      "city": "Goodman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Goodman, Mississippi"
    },
    {
      "city": "Lexington",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lexington, Mississippi"
    },
    {
      "city": "Pickens",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pickens, Mississippi"
    },
    {
      "city": "Tchula",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tchula, Mississippi"
    },
    {
      "city": "West",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "West, Mississippi"
    },
    {
      "city": "Isola",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Isola, Mississippi"
    },
    {
      "city": "Belzoni",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Belzoni, Mississippi"
    },
    {
      "city": "Louise",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Louise, Mississippi"
    },
    {
      "city": "Midnight",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Midnight, Mississippi"
    },
    {
      "city": "Silver City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Silver City, Mississippi"
    },
    {
      "city": "Grace",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Grace, Mississippi"
    },
    {
      "city": "Mayersville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mayersville, Mississippi"
    },
    {
      "city": "Valley Park",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Valley Park, Mississippi"
    },
    {
      "city": "Fulton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Fulton, Mississippi"
    },
    {
      "city": "Golden",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Golden, Mississippi"
    },
    {
      "city": "Mantachie",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mantachie, Mississippi"
    },
    {
      "city": "Nettleton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Nettleton, Mississippi"
    },
    {
      "city": "Tremont",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tremont, Mississippi"
    },
    {
      "city": "Escatawpa",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Escatawpa, Mississippi"
    },
    {
      "city": "Gautier",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gautier, Mississippi"
    },
    {
      "city": "Hurley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hurley, Mississippi"
    },
    {
      "city": "Moss Point",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Moss Point, Mississippi"
    },
    {
      "city": "Ocean Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ocean Springs, Mississippi"
    },
    {
      "city": "Vancleave",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vancleave, Mississippi"
    },
    {
      "city": "Pascagoula",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pascagoula, Mississippi"
    },
    {
      "city": "Louin",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Louin, Mississippi"
    },
    {
      "city": "Paulding",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Paulding, Mississippi"
    },
    {
      "city": "Rose Hill",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rose Hill, Mississippi"
    },
    {
      "city": "Vossburg",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vossburg, Mississippi"
    },
    {
      "city": "Bay Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bay Springs, Mississippi"
    },
    {
      "city": "Heidelberg",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Heidelberg, Mississippi"
    },
    {
      "city": "Moss",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Moss, Mississippi"
    },
    {
      "city": "Stringer",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Stringer, Mississippi"
    },
    {
      "city": "Fayette",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Fayette, Mississippi"
    },
    {
      "city": "Harriston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Harriston, Mississippi"
    },
    {
      "city": "Lorman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lorman, Mississippi"
    },
    {
      "city": "Union Church",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Union Church, Mississippi"
    },
    {
      "city": "Bassfield",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bassfield, Mississippi"
    },
    {
      "city": "Carson",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Carson, Mississippi"
    },
    {
      "city": "Prentiss",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Prentiss, Mississippi"
    },
    {
      "city": "Eastabuchie",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Eastabuchie, Mississippi"
    },
    {
      "city": "Ellisville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ellisville, Mississippi"
    },
    {
      "city": "Laurel",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Laurel, Mississippi"
    },
    {
      "city": "Moselle",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Moselle, Mississippi"
    },
    {
      "city": "Sandersville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sandersville, Mississippi"
    },
    {
      "city": "Soso",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Soso, Mississippi"
    },
    {
      "city": "De Kalb",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "De Kalb, Mississippi"
    },
    {
      "city": "Porterville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Porterville, Mississippi"
    },
    {
      "city": "Preston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Preston, Mississippi"
    },
    {
      "city": "Scooba",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Scooba, Mississippi"
    },
    {
      "city": "Abbeville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Abbeville, Mississippi"
    },
    {
      "city": "Oxford",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Oxford, Mississippi"
    },
    {
      "city": "Taylor",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Taylor, Mississippi"
    },
    {
      "city": "Tula",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tula, Mississippi"
    },
    {
      "city": "University",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "University, Mississippi"
    },
    {
      "city": "Waterford",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Waterford, Mississippi"
    },
    {
      "city": "Toccopola",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Toccopola, Mississippi"
    },
    {
      "city": "Paris",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Paris, Mississippi"
    },
    {
      "city": "Lumberton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lumberton, Mississippi"
    },
    {
      "city": "Purvis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Purvis, Mississippi"
    },
    {
      "city": "Sumrall",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sumrall, Mississippi"
    },
    {
      "city": "Meridian",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Meridian, Mississippi"
    },
    {
      "city": "Bailey",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bailey, Mississippi"
    },
    {
      "city": "Collinsville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Collinsville, Mississippi"
    },
    {
      "city": "Daleville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Daleville, Mississippi"
    },
    {
      "city": "Lauderdale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lauderdale, Mississippi"
    },
    {
      "city": "Marion",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Marion, Mississippi"
    },
    {
      "city": "Toomsuba",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Toomsuba, Mississippi"
    },
    {
      "city": "Newhebron",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Newhebron, Mississippi"
    },
    {
      "city": "Jayess",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Jayess, Mississippi"
    },
    {
      "city": "Monticello",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Monticello, Mississippi"
    },
    {
      "city": "Oak Vale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Oak Vale, Mississippi"
    },
    {
      "city": "Silver Creek",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Silver Creek, Mississippi"
    },
    {
      "city": "Sontag",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sontag, Mississippi"
    },
    {
      "city": "Carthage",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Carthage, Mississippi"
    },
    {
      "city": "Lena",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lena, Mississippi"
    },
    {
      "city": "Madden",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Madden, Mississippi"
    },
    {
      "city": "Thomastown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Thomastown, Mississippi"
    },
    {
      "city": "Walnut Grove",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Walnut Grove, Mississippi"
    },
    {
      "city": "Tupelo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tupelo, Mississippi"
    },
    {
      "city": "Baldwyn",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Baldwyn, Mississippi"
    },
    {
      "city": "Belden",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Belden, Mississippi"
    },
    {
      "city": "Guntown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Guntown, Mississippi"
    },
    {
      "city": "Mooreville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mooreville, Mississippi"
    },
    {
      "city": "Plantersville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Plantersville, Mississippi"
    },
    {
      "city": "Saltillo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Saltillo, Mississippi"
    },
    {
      "city": "Shannon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Shannon, Mississippi"
    },
    {
      "city": "Verona",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Verona, Mississippi"
    },
    {
      "city": "Greenwood",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Greenwood, Mississippi"
    },
    {
      "city": "Itta Bena",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Itta Bena, Mississippi"
    },
    {
      "city": "Minter City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Minter City, Mississippi"
    },
    {
      "city": "Money",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Money, Mississippi"
    },
    {
      "city": "Morgan City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Morgan City, Mississippi"
    },
    {
      "city": "Schlater",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Schlater, Mississippi"
    },
    {
      "city": "Swiftown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Swiftown, Mississippi"
    },
    {
      "city": "Brookhaven",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Brookhaven, Mississippi"
    },
    {
      "city": "Bogue Chitto",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bogue Chitto, Mississippi"
    },
    {
      "city": "Ruth",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ruth, Mississippi"
    },
    {
      "city": "Columbus",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Columbus, Mississippi"
    },
    {
      "city": "Artesia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Artesia, Mississippi"
    },
    {
      "city": "Crawford",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Crawford, Mississippi"
    },
    {
      "city": "Mayhew",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mayhew, Mississippi"
    },
    {
      "city": "Steens",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Steens, Mississippi"
    },
    {
      "city": "Camden",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Camden, Mississippi"
    },
    {
      "city": "Canton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Canton, Mississippi"
    },
    {
      "city": "Flora",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Flora, Mississippi"
    },
    {
      "city": "Madison",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Madison, Mississippi"
    },
    {
      "city": "Ridgeland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ridgeland, Mississippi"
    },
    {
      "city": "Sharon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sharon, Mississippi"
    },
    {
      "city": "Columbia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Columbia, Mississippi"
    },
    {
      "city": "Sandy Hook",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sandy Hook, Mississippi"
    },
    {
      "city": "Foxworth",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Foxworth, Mississippi"
    },
    {
      "city": "Kokomo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Kokomo, Mississippi"
    },
    {
      "city": "Byhalia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Byhalia, Mississippi"
    },
    {
      "city": "Holly Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Holly Springs, Mississippi"
    },
    {
      "city": "Lamar",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lamar, Mississippi"
    },
    {
      "city": "Mount Pleasant",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mount Pleasant, Mississippi"
    },
    {
      "city": "Potts Camp",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Potts Camp, Mississippi"
    },
    {
      "city": "Red Banks",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Red Banks, Mississippi"
    },
    {
      "city": "Victoria",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Victoria, Mississippi"
    },
    {
      "city": "Amory",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Amory, Mississippi"
    },
    {
      "city": "Becker",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Becker, Mississippi"
    },
    {
      "city": "Gattman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Gattman, Mississippi"
    },
    {
      "city": "Greenwood Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Greenwood Springs, Mississippi"
    },
    {
      "city": "Smithville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Smithville, Mississippi"
    },
    {
      "city": "Aberdeen",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Aberdeen, Mississippi"
    },
    {
      "city": "Caledonia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Caledonia, Mississippi"
    },
    {
      "city": "Hamilton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hamilton, Mississippi"
    },
    {
      "city": "Prairie",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Prairie, Mississippi"
    },
    {
      "city": "Duck Hill",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Duck Hill, Mississippi"
    },
    {
      "city": "Winona",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Winona, Mississippi"
    },
    {
      "city": "Kilmichael",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Kilmichael, Mississippi"
    },
    {
      "city": "Stewart",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Stewart, Mississippi"
    },
    {
      "city": "Philadelphia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Philadelphia, Mississippi"
    },
    {
      "city": "Union",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Union, Mississippi"
    },
    {
      "city": "Conehatta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Conehatta, Mississippi"
    },
    {
      "city": "Chunky",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Chunky, Mississippi"
    },
    {
      "city": "Decatur",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Decatur, Mississippi"
    },
    {
      "city": "Hickory",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hickory, Mississippi"
    },
    {
      "city": "Lawrence",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lawrence, Mississippi"
    },
    {
      "city": "Little Rock",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Little Rock, Mississippi"
    },
    {
      "city": "Newton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Newton, Mississippi"
    },
    {
      "city": "Macon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Macon, Mississippi"
    },
    {
      "city": "Shuqualak",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Shuqualak, Mississippi"
    },
    {
      "city": "Brooksville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Brooksville, Mississippi"
    },
    {
      "city": "Starkville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Starkville, Mississippi"
    },
    {
      "city": "Mississippi State",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mississippi State, Mississippi"
    },
    {
      "city": "Sturgis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sturgis, Mississippi"
    },
    {
      "city": "Batesville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Batesville, Mississippi"
    },
    {
      "city": "Como",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Como, Mississippi"
    },
    {
      "city": "Courtland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Courtland, Mississippi"
    },
    {
      "city": "Crenshaw",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Crenshaw, Mississippi"
    },
    {
      "city": "Pope",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pope, Mississippi"
    },
    {
      "city": "Sarah",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sarah, Mississippi"
    },
    {
      "city": "Sardis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sardis, Mississippi"
    },
    {
      "city": "Carriere",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Carriere, Mississippi"
    },
    {
      "city": "Mc Neill",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Neill, Mississippi"
    },
    {
      "city": "Nicholson",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Nicholson, Mississippi"
    },
    {
      "city": "Picayune",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Picayune, Mississippi"
    },
    {
      "city": "Poplarville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Poplarville, Mississippi"
    },
    {
      "city": "Beaumont",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Beaumont, Mississippi"
    },
    {
      "city": "New Augusta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "New Augusta, Mississippi"
    },
    {
      "city": "Ovett",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ovett, Mississippi"
    },
    {
      "city": "Richton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Richton, Mississippi"
    },
    {
      "city": "Chatawa",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Chatawa, Mississippi"
    },
    {
      "city": "Fernwood",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Fernwood, Mississippi"
    },
    {
      "city": "Mccomb",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mccomb, Mississippi"
    },
    {
      "city": "Magnolia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Magnolia, Mississippi"
    },
    {
      "city": "Osyka",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Osyka, Mississippi"
    },
    {
      "city": "Summit",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Summit, Mississippi"
    },
    {
      "city": "Algoma",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Algoma, Mississippi"
    },
    {
      "city": "Ecru",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ecru, Mississippi"
    },
    {
      "city": "Pontotoc",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pontotoc, Mississippi"
    },
    {
      "city": "Randolph",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Randolph, Mississippi"
    },
    {
      "city": "Sherman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sherman, Mississippi"
    },
    {
      "city": "Thaxton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Thaxton, Mississippi"
    },
    {
      "city": "Booneville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Booneville, Mississippi"
    },
    {
      "city": "Marietta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Marietta, Mississippi"
    },
    {
      "city": "New Site",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "New Site, Mississippi"
    },
    {
      "city": "Wheeler",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Wheeler, Mississippi"
    },
    {
      "city": "Belen",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Belen, Mississippi"
    },
    {
      "city": "Crowder",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Crowder, Mississippi"
    },
    {
      "city": "Darling",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Darling, Mississippi"
    },
    {
      "city": "Falcon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Falcon, Mississippi"
    },
    {
      "city": "Lambert",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lambert, Mississippi"
    },
    {
      "city": "Marks",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Marks, Mississippi"
    },
    {
      "city": "Vance",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vance, Mississippi"
    },
    {
      "city": "Brandon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Brandon, Mississippi"
    },
    {
      "city": "Florence",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Florence, Mississippi"
    },
    {
      "city": "Pelahatchie",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pelahatchie, Mississippi"
    },
    {
      "city": "Piney Woods",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Piney Woods, Mississippi"
    },
    {
      "city": "Puckett",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Puckett, Mississippi"
    },
    {
      "city": "Sandhill",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sandhill, Mississippi"
    },
    {
      "city": "Star",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Star, Mississippi"
    },
    {
      "city": "Whitfield",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Whitfield, Mississippi"
    },
    {
      "city": "Pearl",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pearl, Mississippi"
    },
    {
      "city": "Richland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Richland, Mississippi"
    },
    {
      "city": "Flowood",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Flowood, Mississippi"
    },
    {
      "city": "Forest",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Forest, Mississippi"
    },
    {
      "city": "Harperville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Harperville, Mississippi"
    },
    {
      "city": "Hillsboro",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hillsboro, Mississippi"
    },
    {
      "city": "Lake",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Lake, Mississippi"
    },
    {
      "city": "Ludlow",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ludlow, Mississippi"
    },
    {
      "city": "Morton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Morton, Mississippi"
    },
    {
      "city": "Pulaski",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pulaski, Mississippi"
    },
    {
      "city": "Sebastopol",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sebastopol, Mississippi"
    },
    {
      "city": "Anguilla",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Anguilla, Mississippi"
    },
    {
      "city": "Panther Burn",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Panther Burn, Mississippi"
    },
    {
      "city": "Cary",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Cary, Mississippi"
    },
    {
      "city": "Delta City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Delta City, Mississippi"
    },
    {
      "city": "Rolling Fork",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rolling Fork, Mississippi"
    },
    {
      "city": "Braxton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Braxton, Mississippi"
    },
    {
      "city": "D Lo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "D Lo, Mississippi"
    },
    {
      "city": "Harrisville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Harrisville, Mississippi"
    },
    {
      "city": "Magee",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Magee, Mississippi"
    },
    {
      "city": "Mendenhall",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mendenhall, Mississippi"
    },
    {
      "city": "Pinola",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Pinola, Mississippi"
    },
    {
      "city": "Mize",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mize, Mississippi"
    },
    {
      "city": "Raleigh",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Raleigh, Mississippi"
    },
    {
      "city": "Taylorsville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Taylorsville, Mississippi"
    },
    {
      "city": "Mc Henry",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mc Henry, Mississippi"
    },
    {
      "city": "Perkinston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Perkinston, Mississippi"
    },
    {
      "city": "Wiggins",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Wiggins, Mississippi"
    },
    {
      "city": "Doddsville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Doddsville, Mississippi"
    },
    {
      "city": "Drew",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Drew, Mississippi"
    },
    {
      "city": "Parchman",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Parchman, Mississippi"
    },
    {
      "city": "Indianola",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Indianola, Mississippi"
    },
    {
      "city": "Inverness",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Inverness, Mississippi"
    },
    {
      "city": "Moorhead",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Moorhead, Mississippi"
    },
    {
      "city": "Rome",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Rome, Mississippi"
    },
    {
      "city": "Ruleville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ruleville, Mississippi"
    },
    {
      "city": "Sunflower",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sunflower, Mississippi"
    },
    {
      "city": "Cascilla",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Cascilla, Mississippi"
    },
    {
      "city": "Charleston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Charleston, Mississippi"
    },
    {
      "city": "Enid",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Enid, Mississippi"
    },
    {
      "city": "Glendora",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Glendora, Mississippi"
    },
    {
      "city": "Philipp",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Philipp, Mississippi"
    },
    {
      "city": "Sumner",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sumner, Mississippi"
    },
    {
      "city": "Swan Lake",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Swan Lake, Mississippi"
    },
    {
      "city": "Tippo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tippo, Mississippi"
    },
    {
      "city": "Tutwiler",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tutwiler, Mississippi"
    },
    {
      "city": "Webb",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Webb, Mississippi"
    },
    {
      "city": "Arkabutla",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Arkabutla, Mississippi"
    },
    {
      "city": "Coldwater",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Coldwater, Mississippi"
    },
    {
      "city": "Independence",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Independence, Mississippi"
    },
    {
      "city": "Senatobia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Senatobia, Mississippi"
    },
    {
      "city": "Blue Mountain",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Blue Mountain, Mississippi"
    },
    {
      "city": "Dumas",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Dumas, Mississippi"
    },
    {
      "city": "Falkner",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Falkner, Mississippi"
    },
    {
      "city": "Ripley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Ripley, Mississippi"
    },
    {
      "city": "Tiplersville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tiplersville, Mississippi"
    },
    {
      "city": "Walnut",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Walnut, Mississippi"
    },
    {
      "city": "Belmont",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Belmont, Mississippi"
    },
    {
      "city": "Burnsville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Burnsville, Mississippi"
    },
    {
      "city": "Dennis",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Dennis, Mississippi"
    },
    {
      "city": "Iuka",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Iuka, Mississippi"
    },
    {
      "city": "Tishomingo",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tishomingo, Mississippi"
    },
    {
      "city": "Dundee",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Dundee, Mississippi"
    },
    {
      "city": "Robinsonville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Robinsonville, Mississippi"
    },
    {
      "city": "Sledge",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Sledge, Mississippi"
    },
    {
      "city": "Tunica",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tunica, Mississippi"
    },
    {
      "city": "Etta",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Etta, Mississippi"
    },
    {
      "city": "Myrtle",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Myrtle, Mississippi"
    },
    {
      "city": "New Albany",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "New Albany, Mississippi"
    },
    {
      "city": "Blue Springs",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Blue Springs, Mississippi"
    },
    {
      "city": "Tylertown",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tylertown, Mississippi"
    },
    {
      "city": "Redwood",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Redwood, Mississippi"
    },
    {
      "city": "Vicksburg",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vicksburg, Mississippi"
    },
    {
      "city": "Greenville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Greenville, Mississippi"
    },
    {
      "city": "Arcola",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Arcola, Mississippi"
    },
    {
      "city": "Avon",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Avon, Mississippi"
    },
    {
      "city": "Chatham",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Chatham, Mississippi"
    },
    {
      "city": "Glen Allan",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Glen Allan, Mississippi"
    },
    {
      "city": "Hollandale",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Hollandale, Mississippi"
    },
    {
      "city": "Leland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Leland, Mississippi"
    },
    {
      "city": "Metcalfe",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Metcalfe, Mississippi"
    },
    {
      "city": "Stoneville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Stoneville, Mississippi"
    },
    {
      "city": "Wayside",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Wayside, Mississippi"
    },
    {
      "city": "Winterville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Winterville, Mississippi"
    },
    {
      "city": "Buckatunna",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Buckatunna, Mississippi"
    },
    {
      "city": "Clara",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Clara, Mississippi"
    },
    {
      "city": "Waynesboro",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Waynesboro, Mississippi"
    },
    {
      "city": "Bellefontaine",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bellefontaine, Mississippi"
    },
    {
      "city": "Eupora",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Eupora, Mississippi"
    },
    {
      "city": "Maben",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Maben, Mississippi"
    },
    {
      "city": "Mathiston",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Mathiston, Mississippi"
    },
    {
      "city": "Walthall",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Walthall, Mississippi"
    },
    {
      "city": "Centreville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Centreville, Mississippi"
    },
    {
      "city": "Woodville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Woodville, Mississippi"
    },
    {
      "city": "Louisville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Louisville, Mississippi"
    },
    {
      "city": "Noxapater",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Noxapater, Mississippi"
    },
    {
      "city": "Coffeeville",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Coffeeville, Mississippi"
    },
    {
      "city": "Oakland",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Oakland, Mississippi"
    },
    {
      "city": "Scobey",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Scobey, Mississippi"
    },
    {
      "city": "Tillatoba",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tillatoba, Mississippi"
    },
    {
      "city": "Water Valley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Water Valley, Mississippi"
    },
    {
      "city": "Benton",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Benton, Mississippi"
    },
    {
      "city": "Bentonia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Bentonia, Mississippi"
    },
    {
      "city": "Holly Bluff",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Holly Bluff, Mississippi"
    },
    {
      "city": "Satartia",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Satartia, Mississippi"
    },
    {
      "city": "Tinsley",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Tinsley, Mississippi"
    },
    {
      "city": "Vaughan",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Vaughan, Mississippi"
    },
    {
      "city": "Yazoo City",
      "state": "Mississippi",
      "shortState": "MS",
      "formatedCity": "Yazoo City, Mississippi"
    },
    {
      "city": "Dell",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dell, Montana"
    },
    {
      "city": "Dillon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dillon, Montana"
    },
    {
      "city": "Glen",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Glen, Montana"
    },
    {
      "city": "Jackson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Jackson, Montana"
    },
    {
      "city": "Lima",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lima, Montana"
    },
    {
      "city": "Polaris",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Polaris, Montana"
    },
    {
      "city": "Wisdom",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wisdom, Montana"
    },
    {
      "city": "Wise River",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wise River, Montana"
    },
    {
      "city": "Busby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Busby, Montana"
    },
    {
      "city": "Crow Agency",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Crow Agency, Montana"
    },
    {
      "city": "Decker",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Decker, Montana"
    },
    {
      "city": "Garryowen",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Garryowen, Montana"
    },
    {
      "city": "Hardin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hardin, Montana"
    },
    {
      "city": "Fort Smith",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fort Smith, Montana"
    },
    {
      "city": "Lodge Grass",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lodge Grass, Montana"
    },
    {
      "city": "Pryor",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pryor, Montana"
    },
    {
      "city": "Saint Xavier",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saint Xavier, Montana"
    },
    {
      "city": "Wyola",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wyola, Montana"
    },
    {
      "city": "Chinook",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Chinook, Montana"
    },
    {
      "city": "Harlem",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Harlem, Montana"
    },
    {
      "city": "Hays",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hays, Montana"
    },
    {
      "city": "Hogeland",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hogeland, Montana"
    },
    {
      "city": "Lloyd",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lloyd, Montana"
    },
    {
      "city": "Turner",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Turner, Montana"
    },
    {
      "city": "Zurich",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Zurich, Montana"
    },
    {
      "city": "Radersburg",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Radersburg, Montana"
    },
    {
      "city": "Toston",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Toston, Montana"
    },
    {
      "city": "Townsend",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Townsend, Montana"
    },
    {
      "city": "Winston",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Winston, Montana"
    },
    {
      "city": "Bearcreek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bearcreek, Montana"
    },
    {
      "city": "Belfry",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Belfry, Montana"
    },
    {
      "city": "Boyd",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Boyd, Montana"
    },
    {
      "city": "Bridger",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bridger, Montana"
    },
    {
      "city": "Edgar",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Edgar, Montana"
    },
    {
      "city": "Fromberg",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fromberg, Montana"
    },
    {
      "city": "Joliet",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Joliet, Montana"
    },
    {
      "city": "Red Lodge",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Red Lodge, Montana"
    },
    {
      "city": "Roberts",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Roberts, Montana"
    },
    {
      "city": "Roscoe",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Roscoe, Montana"
    },
    {
      "city": "Alzada",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Alzada, Montana"
    },
    {
      "city": "Boyes",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Boyes, Montana"
    },
    {
      "city": "Capitol",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Capitol, Montana"
    },
    {
      "city": "Ekalaka",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ekalaka, Montana"
    },
    {
      "city": "Hammond",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hammond, Montana"
    },
    {
      "city": "Great Falls",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Great Falls, Montana"
    },
    {
      "city": "Malmstrom Afb",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Malmstrom Afb, Montana"
    },
    {
      "city": "Belt",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Belt, Montana"
    },
    {
      "city": "Black Eagle",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Black Eagle, Montana"
    },
    {
      "city": "Cascade",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cascade, Montana"
    },
    {
      "city": "Fort Shaw",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fort Shaw, Montana"
    },
    {
      "city": "Monarch",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Monarch, Montana"
    },
    {
      "city": "Neihart",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Neihart, Montana"
    },
    {
      "city": "Sand Coulee",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sand Coulee, Montana"
    },
    {
      "city": "Simms",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Simms, Montana"
    },
    {
      "city": "Stockett",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Stockett, Montana"
    },
    {
      "city": "Sun River",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sun River, Montana"
    },
    {
      "city": "Ulm",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ulm, Montana"
    },
    {
      "city": "Vaughn",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Vaughn, Montana"
    },
    {
      "city": "Carter",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Carter, Montana"
    },
    {
      "city": "Floweree",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Floweree, Montana"
    },
    {
      "city": "Fort Benton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fort Benton, Montana"
    },
    {
      "city": "Geraldine",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Geraldine, Montana"
    },
    {
      "city": "Highwood",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Highwood, Montana"
    },
    {
      "city": "Loma",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Loma, Montana"
    },
    {
      "city": "Big Sandy",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Big Sandy, Montana"
    },
    {
      "city": "Miles City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Miles City, Montana"
    },
    {
      "city": "Ismay",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ismay, Montana"
    },
    {
      "city": "Kinsey",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Kinsey, Montana"
    },
    {
      "city": "Volborg",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Volborg, Montana"
    },
    {
      "city": "Flaxville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Flaxville, Montana"
    },
    {
      "city": "Peerless",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Peerless, Montana"
    },
    {
      "city": "Scobey",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Scobey, Montana"
    },
    {
      "city": "Whitetail",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Whitetail, Montana"
    },
    {
      "city": "Richey",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Richey, Montana"
    },
    {
      "city": "Bloomfield",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bloomfield, Montana"
    },
    {
      "city": "Glendive",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Glendive, Montana"
    },
    {
      "city": "Lindsay",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lindsay, Montana"
    },
    {
      "city": "Anaconda",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Anaconda, Montana"
    },
    {
      "city": "Warm Springs",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Warm Springs, Montana"
    },
    {
      "city": "Baker",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Baker, Montana"
    },
    {
      "city": "Plevna",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Plevna, Montana"
    },
    {
      "city": "Willard",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Willard, Montana"
    },
    {
      "city": "Grass Range",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Grass Range, Montana"
    },
    {
      "city": "Buffalo",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Buffalo, Montana"
    },
    {
      "city": "Coffee Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Coffee Creek, Montana"
    },
    {
      "city": "Denton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Denton, Montana"
    },
    {
      "city": "Forest Grove",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Forest Grove, Montana"
    },
    {
      "city": "Hilger",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hilger, Montana"
    },
    {
      "city": "Lewistown",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lewistown, Montana"
    },
    {
      "city": "Moore",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Moore, Montana"
    },
    {
      "city": "Roy",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Roy, Montana"
    },
    {
      "city": "Winifred",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Winifred, Montana"
    },
    {
      "city": "Kalispell",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Kalispell, Montana"
    },
    {
      "city": "Bigfork",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bigfork, Montana"
    },
    {
      "city": "Columbia Falls",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Columbia Falls, Montana"
    },
    {
      "city": "Coram",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Coram, Montana"
    },
    {
      "city": "Essex",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Essex, Montana"
    },
    {
      "city": "Hungry Horse",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hungry Horse, Montana"
    },
    {
      "city": "Kila",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Kila, Montana"
    },
    {
      "city": "Lake Mc Donald",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lake Mc Donald, Montana"
    },
    {
      "city": "Lakeside",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lakeside, Montana"
    },
    {
      "city": "Marion",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Marion, Montana"
    },
    {
      "city": "Martin City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Martin City, Montana"
    },
    {
      "city": "Olney",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Olney, Montana"
    },
    {
      "city": "Polebridge",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Polebridge, Montana"
    },
    {
      "city": "Somers",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Somers, Montana"
    },
    {
      "city": "West Glacier",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "West Glacier, Montana"
    },
    {
      "city": "Whitefish",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Whitefish, Montana"
    },
    {
      "city": "Belgrade",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Belgrade, Montana"
    },
    {
      "city": "Bozeman",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bozeman, Montana"
    },
    {
      "city": "Big Sky",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Big Sky, Montana"
    },
    {
      "city": "Gallatin Gateway",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Gallatin Gateway, Montana"
    },
    {
      "city": "Manhattan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Manhattan, Montana"
    },
    {
      "city": "Three Forks",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Three Forks, Montana"
    },
    {
      "city": "West Yellowstone",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "West Yellowstone, Montana"
    },
    {
      "city": "Willow Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Willow Creek, Montana"
    },
    {
      "city": "Mosby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Mosby, Montana"
    },
    {
      "city": "Sand Springs",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sand Springs, Montana"
    },
    {
      "city": "Brusett",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Brusett, Montana"
    },
    {
      "city": "Cohagen",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cohagen, Montana"
    },
    {
      "city": "Jordan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Jordan, Montana"
    },
    {
      "city": "Babb",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Babb, Montana"
    },
    {
      "city": "Browning",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Browning, Montana"
    },
    {
      "city": "Cut Bank",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cut Bank, Montana"
    },
    {
      "city": "East Glacier Park",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "East Glacier Park, Montana"
    },
    {
      "city": "Lavina",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lavina, Montana"
    },
    {
      "city": "Ryegate",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ryegate, Montana"
    },
    {
      "city": "Drummond",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Drummond, Montana"
    },
    {
      "city": "Hall",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hall, Montana"
    },
    {
      "city": "Philipsburg",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Philipsburg, Montana"
    },
    {
      "city": "Havre",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Havre, Montana"
    },
    {
      "city": "Box Elder",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Box Elder, Montana"
    },
    {
      "city": "Gildford",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Gildford, Montana"
    },
    {
      "city": "Hingham",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hingham, Montana"
    },
    {
      "city": "Inverness",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Inverness, Montana"
    },
    {
      "city": "Kremlin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Kremlin, Montana"
    },
    {
      "city": "Rudyard",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Rudyard, Montana"
    },
    {
      "city": "Basin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Basin, Montana"
    },
    {
      "city": "Boulder",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Boulder, Montana"
    },
    {
      "city": "Clancy",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Clancy, Montana"
    },
    {
      "city": "Jefferson City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Jefferson City, Montana"
    },
    {
      "city": "Cardwell",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cardwell, Montana"
    },
    {
      "city": "Whitehall",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Whitehall, Montana"
    },
    {
      "city": "Geyser",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Geyser, Montana"
    },
    {
      "city": "Hobson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hobson, Montana"
    },
    {
      "city": "Moccasin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Moccasin, Montana"
    },
    {
      "city": "Raynesford",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Raynesford, Montana"
    },
    {
      "city": "Stanford",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Stanford, Montana"
    },
    {
      "city": "Arlee",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Arlee, Montana"
    },
    {
      "city": "Charlo",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Charlo, Montana"
    },
    {
      "city": "Pablo",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pablo, Montana"
    },
    {
      "city": "Polson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Polson, Montana"
    },
    {
      "city": "Ravalli",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ravalli, Montana"
    },
    {
      "city": "Ronan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ronan, Montana"
    },
    {
      "city": "Saint Ignatius",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saint Ignatius, Montana"
    },
    {
      "city": "Big Arm",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Big Arm, Montana"
    },
    {
      "city": "Dayton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dayton, Montana"
    },
    {
      "city": "Elmo",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Elmo, Montana"
    },
    {
      "city": "Proctor",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Proctor, Montana"
    },
    {
      "city": "Rollins",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Rollins, Montana"
    },
    {
      "city": "Augusta",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Augusta, Montana"
    },
    {
      "city": "Helena",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Helena, Montana"
    },
    {
      "city": "Canyon Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Canyon Creek, Montana"
    },
    {
      "city": "East Helena",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "East Helena, Montana"
    },
    {
      "city": "Fort Harrison",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fort Harrison, Montana"
    },
    {
      "city": "Lincoln",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lincoln, Montana"
    },
    {
      "city": "Marysville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Marysville, Montana"
    },
    {
      "city": "Wolf Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wolf Creek, Montana"
    },
    {
      "city": "Chester",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Chester, Montana"
    },
    {
      "city": "Joplin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Joplin, Montana"
    },
    {
      "city": "Whitlash",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Whitlash, Montana"
    },
    {
      "city": "Eureka",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Eureka, Montana"
    },
    {
      "city": "Fortine",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fortine, Montana"
    },
    {
      "city": "Libby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Libby, Montana"
    },
    {
      "city": "Rexford",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Rexford, Montana"
    },
    {
      "city": "Stryker",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Stryker, Montana"
    },
    {
      "city": "Trego",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Trego, Montana"
    },
    {
      "city": "Troy",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Troy, Montana"
    },
    {
      "city": "Brockway",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Brockway, Montana"
    },
    {
      "city": "Circle",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Circle, Montana"
    },
    {
      "city": "Vida",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Vida, Montana"
    },
    {
      "city": "Alder",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Alder, Montana"
    },
    {
      "city": "Cameron",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cameron, Montana"
    },
    {
      "city": "Ennis",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ennis, Montana"
    },
    {
      "city": "Harrison",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Harrison, Montana"
    },
    {
      "city": "Mc Allister",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Mc Allister, Montana"
    },
    {
      "city": "Norris",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Norris, Montana"
    },
    {
      "city": "Pony",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pony, Montana"
    },
    {
      "city": "Sheridan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sheridan, Montana"
    },
    {
      "city": "Silver Star",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Silver Star, Montana"
    },
    {
      "city": "Twin Bridges",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Twin Bridges, Montana"
    },
    {
      "city": "Virginia City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Virginia City, Montana"
    },
    {
      "city": "Martinsdale",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Martinsdale, Montana"
    },
    {
      "city": "Ringling",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ringling, Montana"
    },
    {
      "city": "White Sulphur Springs",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "White Sulphur Springs, Montana"
    },
    {
      "city": "Alberton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Alberton, Montana"
    },
    {
      "city": "De Borgia",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "De Borgia, Montana"
    },
    {
      "city": "Haugan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Haugan, Montana"
    },
    {
      "city": "Saint Regis",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saint Regis, Montana"
    },
    {
      "city": "Saltese",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saltese, Montana"
    },
    {
      "city": "Superior",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Superior, Montana"
    },
    {
      "city": "Missoula",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Missoula, Montana"
    },
    {
      "city": "Bonner",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bonner, Montana"
    },
    {
      "city": "Clinton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Clinton, Montana"
    },
    {
      "city": "Condon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Condon, Montana"
    },
    {
      "city": "Frenchtown",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Frenchtown, Montana"
    },
    {
      "city": "Huson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Huson, Montana"
    },
    {
      "city": "Lolo",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lolo, Montana"
    },
    {
      "city": "Milltown",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Milltown, Montana"
    },
    {
      "city": "Seeley Lake",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Seeley Lake, Montana"
    },
    {
      "city": "Melstone",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Melstone, Montana"
    },
    {
      "city": "Musselshell",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Musselshell, Montana"
    },
    {
      "city": "Roundup",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Roundup, Montana"
    },
    {
      "city": "Clyde Park",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Clyde Park, Montana"
    },
    {
      "city": "Cooke City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Cooke City, Montana"
    },
    {
      "city": "Emigrant",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Emigrant, Montana"
    },
    {
      "city": "Gardiner",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Gardiner, Montana"
    },
    {
      "city": "Livingston",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Livingston, Montana"
    },
    {
      "city": "Pray",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pray, Montana"
    },
    {
      "city": "Silver Gate",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Silver Gate, Montana"
    },
    {
      "city": "Springdale",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Springdale, Montana"
    },
    {
      "city": "Wilsall",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wilsall, Montana"
    },
    {
      "city": "Teigen",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Teigen, Montana"
    },
    {
      "city": "Winnett",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Winnett, Montana"
    },
    {
      "city": "Saco",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saco, Montana"
    },
    {
      "city": "Dodson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dodson, Montana"
    },
    {
      "city": "Loring",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Loring, Montana"
    },
    {
      "city": "Malta",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Malta, Montana"
    },
    {
      "city": "Whitewater",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Whitewater, Montana"
    },
    {
      "city": "Zortman",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Zortman, Montana"
    },
    {
      "city": "Brady",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Brady, Montana"
    },
    {
      "city": "Conrad",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Conrad, Montana"
    },
    {
      "city": "Dupuyer",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dupuyer, Montana"
    },
    {
      "city": "Heart Butte",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Heart Butte, Montana"
    },
    {
      "city": "Ledger",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ledger, Montana"
    },
    {
      "city": "Valier",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Valier, Montana"
    },
    {
      "city": "Otter",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Otter, Montana"
    },
    {
      "city": "Biddle",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Biddle, Montana"
    },
    {
      "city": "Broadus",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Broadus, Montana"
    },
    {
      "city": "Olive",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Olive, Montana"
    },
    {
      "city": "Powderville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Powderville, Montana"
    },
    {
      "city": "Avon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Avon, Montana"
    },
    {
      "city": "Deer Lodge",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Deer Lodge, Montana"
    },
    {
      "city": "Elliston",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Elliston, Montana"
    },
    {
      "city": "Garrison",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Garrison, Montana"
    },
    {
      "city": "Gold Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Gold Creek, Montana"
    },
    {
      "city": "Helmville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Helmville, Montana"
    },
    {
      "city": "Ovando",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ovando, Montana"
    },
    {
      "city": "Fallon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fallon, Montana"
    },
    {
      "city": "Mildred",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Mildred, Montana"
    },
    {
      "city": "Terry",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Terry, Montana"
    },
    {
      "city": "Conner",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Conner, Montana"
    },
    {
      "city": "Corvallis",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Corvallis, Montana"
    },
    {
      "city": "Darby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Darby, Montana"
    },
    {
      "city": "Florence",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Florence, Montana"
    },
    {
      "city": "Grantsdale",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Grantsdale, Montana"
    },
    {
      "city": "Hamilton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hamilton, Montana"
    },
    {
      "city": "Pinesdale",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pinesdale, Montana"
    },
    {
      "city": "Stevensville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Stevensville, Montana"
    },
    {
      "city": "Sula",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sula, Montana"
    },
    {
      "city": "Victor",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Victor, Montana"
    },
    {
      "city": "Crane",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Crane, Montana"
    },
    {
      "city": "Fairview",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fairview, Montana"
    },
    {
      "city": "Lambert",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lambert, Montana"
    },
    {
      "city": "Savage",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Savage, Montana"
    },
    {
      "city": "Sidney",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sidney, Montana"
    },
    {
      "city": "Wolf Point",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wolf Point, Montana"
    },
    {
      "city": "Bainville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bainville, Montana"
    },
    {
      "city": "Brockton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Brockton, Montana"
    },
    {
      "city": "Culbertson",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Culbertson, Montana"
    },
    {
      "city": "Froid",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Froid, Montana"
    },
    {
      "city": "Homestead",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Homestead, Montana"
    },
    {
      "city": "Poplar",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Poplar, Montana"
    },
    {
      "city": "Ashland",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ashland, Montana"
    },
    {
      "city": "Birney",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Birney, Montana"
    },
    {
      "city": "Ingomar",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ingomar, Montana"
    },
    {
      "city": "Lame Deer",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lame Deer, Montana"
    },
    {
      "city": "Sumatra",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sumatra, Montana"
    },
    {
      "city": "Angela",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Angela, Montana"
    },
    {
      "city": "Colstrip",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Colstrip, Montana"
    },
    {
      "city": "Forsyth",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Forsyth, Montana"
    },
    {
      "city": "Hathaway",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hathaway, Montana"
    },
    {
      "city": "Rosebud",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Rosebud, Montana"
    },
    {
      "city": "Dixon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dixon, Montana"
    },
    {
      "city": "Heron",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Heron, Montana"
    },
    {
      "city": "Hot Springs",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hot Springs, Montana"
    },
    {
      "city": "Lonepine",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lonepine, Montana"
    },
    {
      "city": "Noxon",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Noxon, Montana"
    },
    {
      "city": "Paradise",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Paradise, Montana"
    },
    {
      "city": "Plains",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Plains, Montana"
    },
    {
      "city": "Thompson Falls",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Thompson Falls, Montana"
    },
    {
      "city": "Trout Creek",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Trout Creek, Montana"
    },
    {
      "city": "Antelope",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Antelope, Montana"
    },
    {
      "city": "Dagmar",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dagmar, Montana"
    },
    {
      "city": "Medicine Lake",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Medicine Lake, Montana"
    },
    {
      "city": "Outlook",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Outlook, Montana"
    },
    {
      "city": "Plentywood",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Plentywood, Montana"
    },
    {
      "city": "Raymond",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Raymond, Montana"
    },
    {
      "city": "Redstone",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Redstone, Montana"
    },
    {
      "city": "Reserve",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Reserve, Montana"
    },
    {
      "city": "Westby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Westby, Montana"
    },
    {
      "city": "Butte",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Butte, Montana"
    },
    {
      "city": "Divide",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Divide, Montana"
    },
    {
      "city": "Melrose",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Melrose, Montana"
    },
    {
      "city": "Ramsay",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ramsay, Montana"
    },
    {
      "city": "Absarokee",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Absarokee, Montana"
    },
    {
      "city": "Columbus",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Columbus, Montana"
    },
    {
      "city": "Fishtail",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fishtail, Montana"
    },
    {
      "city": "Molt",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Molt, Montana"
    },
    {
      "city": "Nye",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Nye, Montana"
    },
    {
      "city": "Park City",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Park City, Montana"
    },
    {
      "city": "Rapelje",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Rapelje, Montana"
    },
    {
      "city": "Reed Point",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Reed Point, Montana"
    },
    {
      "city": "Big Timber",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Big Timber, Montana"
    },
    {
      "city": "Greycliff",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Greycliff, Montana"
    },
    {
      "city": "Mc Leod",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Mc Leod, Montana"
    },
    {
      "city": "Melville",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Melville, Montana"
    },
    {
      "city": "Bynum",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bynum, Montana"
    },
    {
      "city": "Choteau",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Choteau, Montana"
    },
    {
      "city": "Dutton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Dutton, Montana"
    },
    {
      "city": "Fairfield",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fairfield, Montana"
    },
    {
      "city": "Pendroy",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pendroy, Montana"
    },
    {
      "city": "Power",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Power, Montana"
    },
    {
      "city": "Ethridge",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ethridge, Montana"
    },
    {
      "city": "Galata",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Galata, Montana"
    },
    {
      "city": "Kevin",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Kevin, Montana"
    },
    {
      "city": "Lothair",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Lothair, Montana"
    },
    {
      "city": "Oilmont",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Oilmont, Montana"
    },
    {
      "city": "Shelby",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Shelby, Montana"
    },
    {
      "city": "Sunburst",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sunburst, Montana"
    },
    {
      "city": "Sweet Grass",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sweet Grass, Montana"
    },
    {
      "city": "Bighorn",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Bighorn, Montana"
    },
    {
      "city": "Hysham",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hysham, Montana"
    },
    {
      "city": "Sanders",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Sanders, Montana"
    },
    {
      "city": "Fort Peck",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Fort Peck, Montana"
    },
    {
      "city": "Frazer",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Frazer, Montana"
    },
    {
      "city": "Glasgow",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Glasgow, Montana"
    },
    {
      "city": "Saint Marie",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Saint Marie, Montana"
    },
    {
      "city": "Glentana",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Glentana, Montana"
    },
    {
      "city": "Hinsdale",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Hinsdale, Montana"
    },
    {
      "city": "Larslan",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Larslan, Montana"
    },
    {
      "city": "Nashua",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Nashua, Montana"
    },
    {
      "city": "Opheim",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Opheim, Montana"
    },
    {
      "city": "Richland",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Richland, Montana"
    },
    {
      "city": "Vandalia",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Vandalia, Montana"
    },
    {
      "city": "Harlowton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Harlowton, Montana"
    },
    {
      "city": "Shawmut",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Shawmut, Montana"
    },
    {
      "city": "Two Dot",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Two Dot, Montana"
    },
    {
      "city": "Judith Gap",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Judith Gap, Montana"
    },
    {
      "city": "Wibaux",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Wibaux, Montana"
    },
    {
      "city": "Acton",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Acton, Montana"
    },
    {
      "city": "Ballantine",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Ballantine, Montana"
    },
    {
      "city": "Broadview",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Broadview, Montana"
    },
    {
      "city": "Custer",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Custer, Montana"
    },
    {
      "city": "Huntley",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Huntley, Montana"
    },
    {
      "city": "Laurel",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Laurel, Montana"
    },
    {
      "city": "Pompeys Pillar",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Pompeys Pillar, Montana"
    },
    {
      "city": "Shepherd",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Shepherd, Montana"
    },
    {
      "city": "Worden",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Worden, Montana"
    },
    {
      "city": "Billings",
      "state": "Montana",
      "shortState": "MT",
      "formatedCity": "Billings, Montana"
    },
    {
      "city": "Alamance",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Alamance, North Carolina"
    },
    {
      "city": "Altamahaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Altamahaw, North Carolina"
    },
    {
      "city": "Burlington",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Burlington, North Carolina"
    },
    {
      "city": "Elon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elon, North Carolina"
    },
    {
      "city": "Graham",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Graham, North Carolina"
    },
    {
      "city": "Haw River",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Haw River, North Carolina"
    },
    {
      "city": "Mebane",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mebane, North Carolina"
    },
    {
      "city": "Saxapahaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Saxapahaw, North Carolina"
    },
    {
      "city": "Snow Camp",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Snow Camp, North Carolina"
    },
    {
      "city": "Swepsonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Swepsonville, North Carolina"
    },
    {
      "city": "Hiddenite",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hiddenite, North Carolina"
    },
    {
      "city": "Stony Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stony Point, North Carolina"
    },
    {
      "city": "Taylorsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Taylorsville, North Carolina"
    },
    {
      "city": "Ennice",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ennice, North Carolina"
    },
    {
      "city": "Glade Valley",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Glade Valley, North Carolina"
    },
    {
      "city": "Piney Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Piney Creek, North Carolina"
    },
    {
      "city": "Roaring Gap",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roaring Gap, North Carolina"
    },
    {
      "city": "Sparta",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sparta, North Carolina"
    },
    {
      "city": "Ansonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ansonville, North Carolina"
    },
    {
      "city": "Lilesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lilesville, North Carolina"
    },
    {
      "city": "Mc Farlan",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mc Farlan, North Carolina"
    },
    {
      "city": "Morven",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Morven, North Carolina"
    },
    {
      "city": "Peachland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Peachland, North Carolina"
    },
    {
      "city": "Polkton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Polkton, North Carolina"
    },
    {
      "city": "Wadesboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wadesboro, North Carolina"
    },
    {
      "city": "Creston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Creston, North Carolina"
    },
    {
      "city": "Crumpler",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Crumpler, North Carolina"
    },
    {
      "city": "Fleetwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fleetwood, North Carolina"
    },
    {
      "city": "Glendale Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Glendale Springs, North Carolina"
    },
    {
      "city": "Grassy Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grassy Creek, North Carolina"
    },
    {
      "city": "Jefferson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jefferson, North Carolina"
    },
    {
      "city": "Lansing",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lansing, North Carolina"
    },
    {
      "city": "Laurel Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Laurel Springs, North Carolina"
    },
    {
      "city": "Scottville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Scottville, North Carolina"
    },
    {
      "city": "Todd",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Todd, North Carolina"
    },
    {
      "city": "Warrensville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Warrensville, North Carolina"
    },
    {
      "city": "West Jefferson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "West Jefferson, North Carolina"
    },
    {
      "city": "Banner Elk",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Banner Elk, North Carolina"
    },
    {
      "city": "Crossnore",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Crossnore, North Carolina"
    },
    {
      "city": "Elk Park",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elk Park, North Carolina"
    },
    {
      "city": "Linville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Linville, North Carolina"
    },
    {
      "city": "Minneapolis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Minneapolis, North Carolina"
    },
    {
      "city": "Montezuma",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Montezuma, North Carolina"
    },
    {
      "city": "Newland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Newland, North Carolina"
    },
    {
      "city": "Pineola",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pineola, North Carolina"
    },
    {
      "city": "Plumtree",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Plumtree, North Carolina"
    },
    {
      "city": "Aurora",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Aurora, North Carolina"
    },
    {
      "city": "Bath",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bath, North Carolina"
    },
    {
      "city": "Belhaven",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Belhaven, North Carolina"
    },
    {
      "city": "Blounts Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Blounts Creek, North Carolina"
    },
    {
      "city": "Chocowinity",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Chocowinity, North Carolina"
    },
    {
      "city": "Edward",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Edward, North Carolina"
    },
    {
      "city": "Pantego",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pantego, North Carolina"
    },
    {
      "city": "Pinetown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pinetown, North Carolina"
    },
    {
      "city": "Washington",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Washington, North Carolina"
    },
    {
      "city": "Aulander",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Aulander, North Carolina"
    },
    {
      "city": "Kelford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kelford, North Carolina"
    },
    {
      "city": "Lewiston Woodville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lewiston Woodville, North Carolina"
    },
    {
      "city": "Roxobel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roxobel, North Carolina"
    },
    {
      "city": "Colerain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Colerain, North Carolina"
    },
    {
      "city": "Merry Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Merry Hill, North Carolina"
    },
    {
      "city": "Powellsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Powellsville, North Carolina"
    },
    {
      "city": "Windsor",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Windsor, North Carolina"
    },
    {
      "city": "Bladenboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bladenboro, North Carolina"
    },
    {
      "city": "Dublin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dublin, North Carolina"
    },
    {
      "city": "Elizabethtown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elizabethtown, North Carolina"
    },
    {
      "city": "Tar Heel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tar Heel, North Carolina"
    },
    {
      "city": "White Oak",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "White Oak, North Carolina"
    },
    {
      "city": "Clarkton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clarkton, North Carolina"
    },
    {
      "city": "Council",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Council, North Carolina"
    },
    {
      "city": "Kelly",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kelly, North Carolina"
    },
    {
      "city": "Wilmington",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wilmington, North Carolina"
    },
    {
      "city": "Ash",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ash, North Carolina"
    },
    {
      "city": "Bolivia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bolivia, North Carolina"
    },
    {
      "city": "Leland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Leland, North Carolina"
    },
    {
      "city": "Longwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Longwood, North Carolina"
    },
    {
      "city": "Shallotte",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Shallotte, North Carolina"
    },
    {
      "city": "Southport",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Southport, North Carolina"
    },
    {
      "city": "Supply",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Supply, North Carolina"
    },
    {
      "city": "Oak Island",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oak Island, North Carolina"
    },
    {
      "city": "Calabash",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Calabash, North Carolina"
    },
    {
      "city": "Sunset Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sunset Beach, North Carolina"
    },
    {
      "city": "Ocean Isle Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ocean Isle Beach, North Carolina"
    },
    {
      "city": "Winnabow",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Winnabow, North Carolina"
    },
    {
      "city": "Alexander",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Alexander, North Carolina"
    },
    {
      "city": "Arden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Arden, North Carolina"
    },
    {
      "city": "Barnardsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Barnardsville, North Carolina"
    },
    {
      "city": "Black Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Black Mountain, North Carolina"
    },
    {
      "city": "Candler",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Candler, North Carolina"
    },
    {
      "city": "Enka",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Enka, North Carolina"
    },
    {
      "city": "Fairview",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fairview, North Carolina"
    },
    {
      "city": "Leicester",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Leicester, North Carolina"
    },
    {
      "city": "Montreat",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Montreat, North Carolina"
    },
    {
      "city": "Mills River",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mills River, North Carolina"
    },
    {
      "city": "Ridgecrest",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ridgecrest, North Carolina"
    },
    {
      "city": "Skyland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Skyland, North Carolina"
    },
    {
      "city": "Swannanoa",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Swannanoa, North Carolina"
    },
    {
      "city": "Weaverville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Weaverville, North Carolina"
    },
    {
      "city": "Asheville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Asheville, North Carolina"
    },
    {
      "city": "Connelly Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Connelly Springs, North Carolina"
    },
    {
      "city": "Drexel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Drexel, North Carolina"
    },
    {
      "city": "Glen Alpine",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Glen Alpine, North Carolina"
    },
    {
      "city": "Hildebran",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hildebran, North Carolina"
    },
    {
      "city": "Jonas Ridge",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jonas Ridge, North Carolina"
    },
    {
      "city": "Linville Falls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Linville Falls, North Carolina"
    },
    {
      "city": "Morganton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Morganton, North Carolina"
    },
    {
      "city": "Icard",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Icard, North Carolina"
    },
    {
      "city": "Rutherford College",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rutherford College, North Carolina"
    },
    {
      "city": "Valdese",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Valdese, North Carolina"
    },
    {
      "city": "Concord",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Concord, North Carolina"
    },
    {
      "city": "Harrisburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harrisburg, North Carolina"
    },
    {
      "city": "Kannapolis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kannapolis, North Carolina"
    },
    {
      "city": "Midland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Midland, North Carolina"
    },
    {
      "city": "Mount Pleasant",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Pleasant, North Carolina"
    },
    {
      "city": "Collettsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Collettsville, North Carolina"
    },
    {
      "city": "Granite Falls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Granite Falls, North Carolina"
    },
    {
      "city": "Lenoir",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lenoir, North Carolina"
    },
    {
      "city": "Hudson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hudson, North Carolina"
    },
    {
      "city": "Patterson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Patterson, North Carolina"
    },
    {
      "city": "Rhodhiss",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rhodhiss, North Carolina"
    },
    {
      "city": "Camden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Camden, North Carolina"
    },
    {
      "city": "Shiloh",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Shiloh, North Carolina"
    },
    {
      "city": "South Mills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "South Mills, North Carolina"
    },
    {
      "city": "Atlantic",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Atlantic, North Carolina"
    },
    {
      "city": "Atlantic Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Atlantic Beach, North Carolina"
    },
    {
      "city": "Beaufort",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Beaufort, North Carolina"
    },
    {
      "city": "Cedar Island",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cedar Island, North Carolina"
    },
    {
      "city": "Davis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Davis, North Carolina"
    },
    {
      "city": "Gloucester",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gloucester, North Carolina"
    },
    {
      "city": "Harkers Island",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harkers Island, North Carolina"
    },
    {
      "city": "Marshallberg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marshallberg, North Carolina"
    },
    {
      "city": "Morehead City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Morehead City, North Carolina"
    },
    {
      "city": "Newport",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Newport, North Carolina"
    },
    {
      "city": "Salter Path",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Salter Path, North Carolina"
    },
    {
      "city": "Sealevel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sealevel, North Carolina"
    },
    {
      "city": "Smyrna",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Smyrna, North Carolina"
    },
    {
      "city": "Stacy",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stacy, North Carolina"
    },
    {
      "city": "Stella",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stella, North Carolina"
    },
    {
      "city": "Williston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Williston, North Carolina"
    },
    {
      "city": "Emerald Isle",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Emerald Isle, North Carolina"
    },
    {
      "city": "Blanch",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Blanch, North Carolina"
    },
    {
      "city": "Leasburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Leasburg, North Carolina"
    },
    {
      "city": "Milton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Milton, North Carolina"
    },
    {
      "city": "Pelham",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pelham, North Carolina"
    },
    {
      "city": "Prospect Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Prospect Hill, North Carolina"
    },
    {
      "city": "Providence",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Providence, North Carolina"
    },
    {
      "city": "Yanceyville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Yanceyville, North Carolina"
    },
    {
      "city": "Hickory",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hickory, North Carolina"
    },
    {
      "city": "Catawba",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Catawba, North Carolina"
    },
    {
      "city": "Claremont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Claremont, North Carolina"
    },
    {
      "city": "Conover",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Conover, North Carolina"
    },
    {
      "city": "Maiden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maiden, North Carolina"
    },
    {
      "city": "Newton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Newton, North Carolina"
    },
    {
      "city": "Sherrills Ford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sherrills Ford, North Carolina"
    },
    {
      "city": "Terrell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Terrell, North Carolina"
    },
    {
      "city": "Bear Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bear Creek, North Carolina"
    },
    {
      "city": "Bennett",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bennett, North Carolina"
    },
    {
      "city": "Bonlee",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bonlee, North Carolina"
    },
    {
      "city": "Bynum",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bynum, North Carolina"
    },
    {
      "city": "Goldston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Goldston, North Carolina"
    },
    {
      "city": "Gulf",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gulf, North Carolina"
    },
    {
      "city": "Pittsboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pittsboro, North Carolina"
    },
    {
      "city": "Siler City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Siler City, North Carolina"
    },
    {
      "city": "Moncure",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Moncure, North Carolina"
    },
    {
      "city": "Topton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Topton, North Carolina"
    },
    {
      "city": "Andrews",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Andrews, North Carolina"
    },
    {
      "city": "Culberson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Culberson, North Carolina"
    },
    {
      "city": "Marble",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marble, North Carolina"
    },
    {
      "city": "Murphy",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Murphy, North Carolina"
    },
    {
      "city": "Edenton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Edenton, North Carolina"
    },
    {
      "city": "Tyner",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tyner, North Carolina"
    },
    {
      "city": "Brasstown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Brasstown, North Carolina"
    },
    {
      "city": "Hayesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hayesville, North Carolina"
    },
    {
      "city": "Warne",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Warne, North Carolina"
    },
    {
      "city": "Boiling Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Boiling Springs, North Carolina"
    },
    {
      "city": "Casar",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Casar, North Carolina"
    },
    {
      "city": "Earl",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Earl, North Carolina"
    },
    {
      "city": "Fallston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fallston, North Carolina"
    },
    {
      "city": "Grover",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grover, North Carolina"
    },
    {
      "city": "Kings Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kings Mountain, North Carolina"
    },
    {
      "city": "Lattimore",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lattimore, North Carolina"
    },
    {
      "city": "Lawndale",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lawndale, North Carolina"
    },
    {
      "city": "Mooresboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mooresboro, North Carolina"
    },
    {
      "city": "Polkville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Polkville, North Carolina"
    },
    {
      "city": "Shelby",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Shelby, North Carolina"
    },
    {
      "city": "Waco",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Waco, North Carolina"
    },
    {
      "city": "Bolton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bolton, North Carolina"
    },
    {
      "city": "Brunswick",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Brunswick, North Carolina"
    },
    {
      "city": "Cerro Gordo",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cerro Gordo, North Carolina"
    },
    {
      "city": "Chadbourn",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Chadbourn, North Carolina"
    },
    {
      "city": "Clarendon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clarendon, North Carolina"
    },
    {
      "city": "Delco",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Delco, North Carolina"
    },
    {
      "city": "Evergreen",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Evergreen, North Carolina"
    },
    {
      "city": "Fair Bluff",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fair Bluff, North Carolina"
    },
    {
      "city": "Hallsboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hallsboro, North Carolina"
    },
    {
      "city": "Lake Waccamaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lake Waccamaw, North Carolina"
    },
    {
      "city": "Nakina",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Nakina, North Carolina"
    },
    {
      "city": "Riegelwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Riegelwood, North Carolina"
    },
    {
      "city": "Tabor City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tabor City, North Carolina"
    },
    {
      "city": "Whiteville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Whiteville, North Carolina"
    },
    {
      "city": "Bridgeton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bridgeton, North Carolina"
    },
    {
      "city": "Cove City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cove City, North Carolina"
    },
    {
      "city": "Dover",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dover, North Carolina"
    },
    {
      "city": "Ernul",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ernul, North Carolina"
    },
    {
      "city": "Havelock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Havelock, North Carolina"
    },
    {
      "city": "Cherry Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cherry Point, North Carolina"
    },
    {
      "city": "New Bern",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "New Bern, North Carolina"
    },
    {
      "city": "Vanceboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vanceboro, North Carolina"
    },
    {
      "city": "Fayetteville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fayetteville, North Carolina"
    },
    {
      "city": "Fort Bragg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fort Bragg, North Carolina"
    },
    {
      "city": "Pope Army Airfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pope Army Airfield, North Carolina"
    },
    {
      "city": "Cumberland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cumberland, North Carolina"
    },
    {
      "city": "Falcon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Falcon, North Carolina"
    },
    {
      "city": "Godwin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Godwin, North Carolina"
    },
    {
      "city": "Hope Mills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hope Mills, North Carolina"
    },
    {
      "city": "Linden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Linden, North Carolina"
    },
    {
      "city": "Spring Lake",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Spring Lake, North Carolina"
    },
    {
      "city": "Stedman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stedman, North Carolina"
    },
    {
      "city": "Wade",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wade, North Carolina"
    },
    {
      "city": "Aydlett",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Aydlett, North Carolina"
    },
    {
      "city": "Barco",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Barco, North Carolina"
    },
    {
      "city": "Coinjock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Coinjock, North Carolina"
    },
    {
      "city": "Corolla",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Corolla, North Carolina"
    },
    {
      "city": "Currituck",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Currituck, North Carolina"
    },
    {
      "city": "Grandy",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grandy, North Carolina"
    },
    {
      "city": "Harbinger",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harbinger, North Carolina"
    },
    {
      "city": "Jarvisburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jarvisburg, North Carolina"
    },
    {
      "city": "Knotts Island",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Knotts Island, North Carolina"
    },
    {
      "city": "Maple",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maple, North Carolina"
    },
    {
      "city": "Moyock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Moyock, North Carolina"
    },
    {
      "city": "Point Harbor",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Point Harbor, North Carolina"
    },
    {
      "city": "Poplar Branch",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Poplar Branch, North Carolina"
    },
    {
      "city": "Powells Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Powells Point, North Carolina"
    },
    {
      "city": "Shawboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Shawboro, North Carolina"
    },
    {
      "city": "Avon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Avon, North Carolina"
    },
    {
      "city": "Buxton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Buxton, North Carolina"
    },
    {
      "city": "Frisco",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Frisco, North Carolina"
    },
    {
      "city": "Hatteras",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hatteras, North Carolina"
    },
    {
      "city": "Kill Devil Hills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kill Devil Hills, North Carolina"
    },
    {
      "city": "Kitty Hawk",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kitty Hawk, North Carolina"
    },
    {
      "city": "Manns Harbor",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Manns Harbor, North Carolina"
    },
    {
      "city": "Manteo",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Manteo, North Carolina"
    },
    {
      "city": "Nags Head",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Nags Head, North Carolina"
    },
    {
      "city": "Rodanthe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rodanthe, North Carolina"
    },
    {
      "city": "Salvo",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Salvo, North Carolina"
    },
    {
      "city": "Stumpy Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stumpy Point, North Carolina"
    },
    {
      "city": "Wanchese",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wanchese, North Carolina"
    },
    {
      "city": "Waves",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Waves, North Carolina"
    },
    {
      "city": "Denton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Denton, North Carolina"
    },
    {
      "city": "Lexington",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lexington, North Carolina"
    },
    {
      "city": "Linwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Linwood, North Carolina"
    },
    {
      "city": "Southmont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Southmont, North Carolina"
    },
    {
      "city": "Thomasville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Thomasville, North Carolina"
    },
    {
      "city": "Wallburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wallburg, North Carolina"
    },
    {
      "city": "Welcome",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Welcome, North Carolina"
    },
    {
      "city": "Advance",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Advance, North Carolina"
    },
    {
      "city": "Cooleemee",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cooleemee, North Carolina"
    },
    {
      "city": "Mocksville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mocksville, North Carolina"
    },
    {
      "city": "Calypso",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Calypso, North Carolina"
    },
    {
      "city": "Faison",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Faison, North Carolina"
    },
    {
      "city": "Kenansville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kenansville, North Carolina"
    },
    {
      "city": "Warsaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Warsaw, North Carolina"
    },
    {
      "city": "Magnolia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Magnolia, North Carolina"
    },
    {
      "city": "Rose Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rose Hill, North Carolina"
    },
    {
      "city": "Teachey",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Teachey, North Carolina"
    },
    {
      "city": "Wallace",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wallace, North Carolina"
    },
    {
      "city": "Albertson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Albertson, North Carolina"
    },
    {
      "city": "Beulaville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Beulaville, North Carolina"
    },
    {
      "city": "Chinquapin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Chinquapin, North Carolina"
    },
    {
      "city": "Bahama",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bahama, North Carolina"
    },
    {
      "city": "Rougemont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rougemont, North Carolina"
    },
    {
      "city": "Durham",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Durham, North Carolina"
    },
    {
      "city": "Rocky Mount",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rocky Mount, North Carolina"
    },
    {
      "city": "Battleboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Battleboro, North Carolina"
    },
    {
      "city": "Conetoe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Conetoe, North Carolina"
    },
    {
      "city": "Macclesfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Macclesfield, North Carolina"
    },
    {
      "city": "Pinetops",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pinetops, North Carolina"
    },
    {
      "city": "Speed",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Speed, North Carolina"
    },
    {
      "city": "Tarboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tarboro, North Carolina"
    },
    {
      "city": "Belews Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Belews Creek, North Carolina"
    },
    {
      "city": "Bethania",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bethania, North Carolina"
    },
    {
      "city": "Clemmons",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clemmons, North Carolina"
    },
    {
      "city": "Lewisville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lewisville, North Carolina"
    },
    {
      "city": "Pfafftown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pfafftown, North Carolina"
    },
    {
      "city": "Rural Hall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rural Hall, North Carolina"
    },
    {
      "city": "Tobaccoville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tobaccoville, North Carolina"
    },
    {
      "city": "Walkertown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Walkertown, North Carolina"
    },
    {
      "city": "Winston-Salem",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Winston-Salem, North Carolina"
    },
    {
      "city": "Kernersville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kernersville, North Carolina"
    },
    {
      "city": "Bunn",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bunn, North Carolina"
    },
    {
      "city": "Franklinton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Franklinton, North Carolina"
    },
    {
      "city": "Louisburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Louisburg, North Carolina"
    },
    {
      "city": "Youngsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Youngsville, North Carolina"
    },
    {
      "city": "Alexis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Alexis, North Carolina"
    },
    {
      "city": "Belmont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Belmont, North Carolina"
    },
    {
      "city": "Bessemer City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bessemer City, North Carolina"
    },
    {
      "city": "Cherryville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cherryville, North Carolina"
    },
    {
      "city": "Cramerton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cramerton, North Carolina"
    },
    {
      "city": "Dallas",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dallas, North Carolina"
    },
    {
      "city": "Davidson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Davidson, North Carolina"
    },
    {
      "city": "Gastonia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gastonia, North Carolina"
    },
    {
      "city": "High Shoals",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "High Shoals, North Carolina"
    },
    {
      "city": "Lowell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lowell, North Carolina"
    },
    {
      "city": "Mc Adenville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mc Adenville, North Carolina"
    },
    {
      "city": "Mount Holly",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Holly, North Carolina"
    },
    {
      "city": "Stanley",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stanley, North Carolina"
    },
    {
      "city": "Corapeake",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Corapeake, North Carolina"
    },
    {
      "city": "Eure",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Eure, North Carolina"
    },
    {
      "city": "Gates",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gates, North Carolina"
    },
    {
      "city": "Gatesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gatesville, North Carolina"
    },
    {
      "city": "Hobbsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hobbsville, North Carolina"
    },
    {
      "city": "Roduco",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roduco, North Carolina"
    },
    {
      "city": "Sunbury",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sunbury, North Carolina"
    },
    {
      "city": "Fontana Dam",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fontana Dam, North Carolina"
    },
    {
      "city": "Robbinsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Robbinsville, North Carolina"
    },
    {
      "city": "Bullock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bullock, North Carolina"
    },
    {
      "city": "Butner",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Butner, North Carolina"
    },
    {
      "city": "Creedmoor",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Creedmoor, North Carolina"
    },
    {
      "city": "Clayton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clayton, North Carolina"
    },
    {
      "city": "Oxford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oxford, North Carolina"
    },
    {
      "city": "Roxboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roxboro, North Carolina"
    },
    {
      "city": "Stem",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stem, North Carolina"
    },
    {
      "city": "Stovall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stovall, North Carolina"
    },
    {
      "city": "Walstonburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Walstonburg, North Carolina"
    },
    {
      "city": "Hookerton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hookerton, North Carolina"
    },
    {
      "city": "Maury",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maury, North Carolina"
    },
    {
      "city": "Snow Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Snow Hill, North Carolina"
    },
    {
      "city": "Browns Summit",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Browns Summit, North Carolina"
    },
    {
      "city": "Climax",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Climax, North Carolina"
    },
    {
      "city": "Colfax",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Colfax, North Carolina"
    },
    {
      "city": "Gibsonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gibsonville, North Carolina"
    },
    {
      "city": "High Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "High Point, North Carolina"
    },
    {
      "city": "Jamestown",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jamestown, North Carolina"
    },
    {
      "city": "Julian",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Julian, North Carolina"
    },
    {
      "city": "Mc Leansville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mc Leansville, North Carolina"
    },
    {
      "city": "Oak Ridge",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oak Ridge, North Carolina"
    },
    {
      "city": "Pleasant Garden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pleasant Garden, North Carolina"
    },
    {
      "city": "Sedalia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sedalia, North Carolina"
    },
    {
      "city": "Stokesdale",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stokesdale, North Carolina"
    },
    {
      "city": "Summerfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Summerfield, North Carolina"
    },
    {
      "city": "Whitsett",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Whitsett, North Carolina"
    },
    {
      "city": "Greensboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Greensboro, North Carolina"
    },
    {
      "city": "Enfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Enfield, North Carolina"
    },
    {
      "city": "Halifax",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Halifax, North Carolina"
    },
    {
      "city": "Hobgood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hobgood, North Carolina"
    },
    {
      "city": "Hollister",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hollister, North Carolina"
    },
    {
      "city": "Littleton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Littleton, North Carolina"
    },
    {
      "city": "Roanoke Rapids",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roanoke Rapids, North Carolina"
    },
    {
      "city": "Scotland Neck",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Scotland Neck, North Carolina"
    },
    {
      "city": "Tillery",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tillery, North Carolina"
    },
    {
      "city": "Weldon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Weldon, North Carolina"
    },
    {
      "city": "Angier",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Angier, North Carolina"
    },
    {
      "city": "Buies Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Buies Creek, North Carolina"
    },
    {
      "city": "Coats",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Coats, North Carolina"
    },
    {
      "city": "Kipling",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kipling, North Carolina"
    },
    {
      "city": "Lillington",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lillington, North Carolina"
    },
    {
      "city": "Mamers",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mamers, North Carolina"
    },
    {
      "city": "Bunnlevel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bunnlevel, North Carolina"
    },
    {
      "city": "Dunn",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dunn, North Carolina"
    },
    {
      "city": "Erwin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Erwin, North Carolina"
    },
    {
      "city": "Olivia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Olivia, North Carolina"
    },
    {
      "city": "Canton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Canton, North Carolina"
    },
    {
      "city": "Clyde",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clyde, North Carolina"
    },
    {
      "city": "Hazelwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hazelwood, North Carolina"
    },
    {
      "city": "Lake Junaluska",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lake Junaluska, North Carolina"
    },
    {
      "city": "Maggie Valley",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maggie Valley, North Carolina"
    },
    {
      "city": "Waynesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Waynesville, North Carolina"
    },
    {
      "city": "Bat Cave",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bat Cave, North Carolina"
    },
    {
      "city": "Dana",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dana, North Carolina"
    },
    {
      "city": "East Flat Rock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "East Flat Rock, North Carolina"
    },
    {
      "city": "Edneyville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Edneyville, North Carolina"
    },
    {
      "city": "Etowah",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Etowah, North Carolina"
    },
    {
      "city": "Flat Rock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Flat Rock, North Carolina"
    },
    {
      "city": "Fletcher",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fletcher, North Carolina"
    },
    {
      "city": "Gerton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gerton, North Carolina"
    },
    {
      "city": "Hendersonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hendersonville, North Carolina"
    },
    {
      "city": "Horse Shoe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Horse Shoe, North Carolina"
    },
    {
      "city": "Mountain Home",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mountain Home, North Carolina"
    },
    {
      "city": "Naples",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Naples, North Carolina"
    },
    {
      "city": "Tuxedo",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tuxedo, North Carolina"
    },
    {
      "city": "Zirconia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Zirconia, North Carolina"
    },
    {
      "city": "Como",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Como, North Carolina"
    },
    {
      "city": "Murfreesboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Murfreesboro, North Carolina"
    },
    {
      "city": "Ahoskie",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ahoskie, North Carolina"
    },
    {
      "city": "Cofield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cofield, North Carolina"
    },
    {
      "city": "Harrellsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harrellsville, North Carolina"
    },
    {
      "city": "Winton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Winton, North Carolina"
    },
    {
      "city": "Raeford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Raeford, North Carolina"
    },
    {
      "city": "Engelhard",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Engelhard, North Carolina"
    },
    {
      "city": "Fairfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fairfield, North Carolina"
    },
    {
      "city": "Scranton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Scranton, North Carolina"
    },
    {
      "city": "Swanquarter",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Swanquarter, North Carolina"
    },
    {
      "city": "Ocracoke",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ocracoke, North Carolina"
    },
    {
      "city": "Barium Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Barium Springs, North Carolina"
    },
    {
      "city": "Mooresville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mooresville, North Carolina"
    },
    {
      "city": "Mount Mourne",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Mourne, North Carolina"
    },
    {
      "city": "Troutman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Troutman, North Carolina"
    },
    {
      "city": "Statesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Statesville, North Carolina"
    },
    {
      "city": "Harmony",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harmony, North Carolina"
    },
    {
      "city": "Olin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Olin, North Carolina"
    },
    {
      "city": "Turnersburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Turnersburg, North Carolina"
    },
    {
      "city": "Union Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Union Grove, North Carolina"
    },
    {
      "city": "Scotts",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Scotts, North Carolina"
    },
    {
      "city": "Balsam",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Balsam, North Carolina"
    },
    {
      "city": "Cashiers",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cashiers, North Carolina"
    },
    {
      "city": "Cullowhee",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cullowhee, North Carolina"
    },
    {
      "city": "Dillsboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dillsboro, North Carolina"
    },
    {
      "city": "Glenville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Glenville, North Carolina"
    },
    {
      "city": "Sylva",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sylva, North Carolina"
    },
    {
      "city": "Tuckasegee",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tuckasegee, North Carolina"
    },
    {
      "city": "Webster",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Webster, North Carolina"
    },
    {
      "city": "Whittier",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Whittier, North Carolina"
    },
    {
      "city": "Benson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Benson, North Carolina"
    },
    {
      "city": "Four Oaks",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Four Oaks, North Carolina"
    },
    {
      "city": "Kenly",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kenly, North Carolina"
    },
    {
      "city": "Micro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Micro, North Carolina"
    },
    {
      "city": "Middlesex",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Middlesex, North Carolina"
    },
    {
      "city": "Pine Level",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pine Level, North Carolina"
    },
    {
      "city": "Princeton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Princeton, North Carolina"
    },
    {
      "city": "Selma",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Selma, North Carolina"
    },
    {
      "city": "Smithfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Smithfield, North Carolina"
    },
    {
      "city": "Wilsons Mills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wilsons Mills, North Carolina"
    },
    {
      "city": "Comfort",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Comfort, North Carolina"
    },
    {
      "city": "Maysville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maysville, North Carolina"
    },
    {
      "city": "Pollocksville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pollocksville, North Carolina"
    },
    {
      "city": "Trenton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Trenton, North Carolina"
    },
    {
      "city": "Cumnock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cumnock, North Carolina"
    },
    {
      "city": "Sanford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sanford, North Carolina"
    },
    {
      "city": "Broadway",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Broadway, North Carolina"
    },
    {
      "city": "Lemon Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lemon Springs, North Carolina"
    },
    {
      "city": "Kinston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kinston, North Carolina"
    },
    {
      "city": "Deep Run",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Deep Run, North Carolina"
    },
    {
      "city": "La Grange",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "La Grange, North Carolina"
    },
    {
      "city": "Pink Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pink Hill, North Carolina"
    },
    {
      "city": "Crouse",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Crouse, North Carolina"
    },
    {
      "city": "Denver",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Denver, North Carolina"
    },
    {
      "city": "Iron Station",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Iron Station, North Carolina"
    },
    {
      "city": "Lincolnton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lincolnton, North Carolina"
    },
    {
      "city": "Vale",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vale, North Carolina"
    },
    {
      "city": "Glenwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Glenwood, North Carolina"
    },
    {
      "city": "Little Switzerland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Little Switzerland, North Carolina"
    },
    {
      "city": "Marion",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marion, North Carolina"
    },
    {
      "city": "Nebo",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Nebo, North Carolina"
    },
    {
      "city": "Old Fort",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Old Fort, North Carolina"
    },
    {
      "city": "Franklin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Franklin, North Carolina"
    },
    {
      "city": "Highlands",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Highlands, North Carolina"
    },
    {
      "city": "Otto",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Otto, North Carolina"
    },
    {
      "city": "Scaly Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Scaly Mountain, North Carolina"
    },
    {
      "city": "Hot Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hot Springs, North Carolina"
    },
    {
      "city": "Marshall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marshall, North Carolina"
    },
    {
      "city": "Mars Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mars Hill, North Carolina"
    },
    {
      "city": "Everetts",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Everetts, North Carolina"
    },
    {
      "city": "Hamilton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hamilton, North Carolina"
    },
    {
      "city": "Hassell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hassell, North Carolina"
    },
    {
      "city": "Jamesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jamesville, North Carolina"
    },
    {
      "city": "Oak City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oak City, North Carolina"
    },
    {
      "city": "Parmele",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Parmele, North Carolina"
    },
    {
      "city": "Robersonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Robersonville, North Carolina"
    },
    {
      "city": "Williamston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Williamston, North Carolina"
    },
    {
      "city": "Cornelius",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cornelius, North Carolina"
    },
    {
      "city": "Huntersville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Huntersville, North Carolina"
    },
    {
      "city": "Matthews",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Matthews, North Carolina"
    },
    {
      "city": "Newell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Newell, North Carolina"
    },
    {
      "city": "Paw Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Paw Creek, North Carolina"
    },
    {
      "city": "Pineville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pineville, North Carolina"
    },
    {
      "city": "Charlotte",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Charlotte, North Carolina"
    },
    {
      "city": "Bakersville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bakersville, North Carolina"
    },
    {
      "city": "Penland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Penland, North Carolina"
    },
    {
      "city": "Spruce Pine",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Spruce Pine, North Carolina"
    },
    {
      "city": "Biscoe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Biscoe, North Carolina"
    },
    {
      "city": "Candor",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Candor, North Carolina"
    },
    {
      "city": "Ether",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ether, North Carolina"
    },
    {
      "city": "Mount Gilead",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Gilead, North Carolina"
    },
    {
      "city": "Star",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Star, North Carolina"
    },
    {
      "city": "Troy",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Troy, North Carolina"
    },
    {
      "city": "Eagle Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Eagle Springs, North Carolina"
    },
    {
      "city": "Highfalls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Highfalls, North Carolina"
    },
    {
      "city": "Jackson Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jackson Springs, North Carolina"
    },
    {
      "city": "Robbins",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Robbins, North Carolina"
    },
    {
      "city": "West End",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "West End, North Carolina"
    },
    {
      "city": "Aberdeen",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Aberdeen, North Carolina"
    },
    {
      "city": "Cameron",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cameron, North Carolina"
    },
    {
      "city": "Carthage",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Carthage, North Carolina"
    },
    {
      "city": "Lakeview",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lakeview, North Carolina"
    },
    {
      "city": "Pinehurst",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pinehurst, North Carolina"
    },
    {
      "city": "Pinebluff",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pinebluff, North Carolina"
    },
    {
      "city": "Southern Pines",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Southern Pines, North Carolina"
    },
    {
      "city": "Vass",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vass, North Carolina"
    },
    {
      "city": "Bailey",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bailey, North Carolina"
    },
    {
      "city": "Castalia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Castalia, North Carolina"
    },
    {
      "city": "Nashville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Nashville, North Carolina"
    },
    {
      "city": "Red Oak",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Red Oak, North Carolina"
    },
    {
      "city": "Sharpsburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sharpsburg, North Carolina"
    },
    {
      "city": "Spring Hope",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Spring Hope, North Carolina"
    },
    {
      "city": "Whitakers",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Whitakers, North Carolina"
    },
    {
      "city": "Carolina Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Carolina Beach, North Carolina"
    },
    {
      "city": "Castle Hayne",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Castle Hayne, North Carolina"
    },
    {
      "city": "Kure Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kure Beach, North Carolina"
    },
    {
      "city": "Wrightsville Beach",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wrightsville Beach, North Carolina"
    },
    {
      "city": "Conway",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Conway, North Carolina"
    },
    {
      "city": "Garysburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Garysburg, North Carolina"
    },
    {
      "city": "Gaston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gaston, North Carolina"
    },
    {
      "city": "Henrico",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Henrico, North Carolina"
    },
    {
      "city": "Jackson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jackson, North Carolina"
    },
    {
      "city": "Margarettsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Margarettsville, North Carolina"
    },
    {
      "city": "Pendleton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pendleton, North Carolina"
    },
    {
      "city": "Pleasant Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pleasant Hill, North Carolina"
    },
    {
      "city": "Potecasi",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Potecasi, North Carolina"
    },
    {
      "city": "Rich Square",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rich Square, North Carolina"
    },
    {
      "city": "Seaboard",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Seaboard, North Carolina"
    },
    {
      "city": "Severn",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Severn, North Carolina"
    },
    {
      "city": "Woodland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Woodland, North Carolina"
    },
    {
      "city": "Holly Ridge",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Holly Ridge, North Carolina"
    },
    {
      "city": "Sneads Ferry",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sneads Ferry, North Carolina"
    },
    {
      "city": "Hubert",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hubert, North Carolina"
    },
    {
      "city": "Jacksonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jacksonville, North Carolina"
    },
    {
      "city": "Camp Lejeune",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Camp Lejeune, North Carolina"
    },
    {
      "city": "Tarawa Terrace",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tarawa Terrace, North Carolina"
    },
    {
      "city": "Midway Park",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Midway Park, North Carolina"
    },
    {
      "city": "Mccutcheon Field",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mccutcheon Field, North Carolina"
    },
    {
      "city": "Richlands",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Richlands, North Carolina"
    },
    {
      "city": "Swansboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Swansboro, North Carolina"
    },
    {
      "city": "Cedar Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cedar Grove, North Carolina"
    },
    {
      "city": "Efland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Efland, North Carolina"
    },
    {
      "city": "Hillsborough",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hillsborough, North Carolina"
    },
    {
      "city": "Carrboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Carrboro, North Carolina"
    },
    {
      "city": "Chapel Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Chapel Hill, North Carolina"
    },
    {
      "city": "Alliance",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Alliance, North Carolina"
    },
    {
      "city": "Arapahoe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Arapahoe, North Carolina"
    },
    {
      "city": "Bayboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bayboro, North Carolina"
    },
    {
      "city": "Grantsboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grantsboro, North Carolina"
    },
    {
      "city": "Hobucken",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hobucken, North Carolina"
    },
    {
      "city": "Lowland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lowland, North Carolina"
    },
    {
      "city": "Merritt",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Merritt, North Carolina"
    },
    {
      "city": "Oriental",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oriental, North Carolina"
    },
    {
      "city": "Stonewall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stonewall, North Carolina"
    },
    {
      "city": "Vandemere",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vandemere, North Carolina"
    },
    {
      "city": "Elizabeth City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elizabeth City, North Carolina"
    },
    {
      "city": "Atkinson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Atkinson, North Carolina"
    },
    {
      "city": "Burgaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Burgaw, North Carolina"
    },
    {
      "city": "Currie",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Currie, North Carolina"
    },
    {
      "city": "Hampstead",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hampstead, North Carolina"
    },
    {
      "city": "Maple Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maple Hill, North Carolina"
    },
    {
      "city": "Rocky Point",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rocky Point, North Carolina"
    },
    {
      "city": "Willard",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Willard, North Carolina"
    },
    {
      "city": "Belvidere",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Belvidere, North Carolina"
    },
    {
      "city": "Hertford",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hertford, North Carolina"
    },
    {
      "city": "Winfall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Winfall, North Carolina"
    },
    {
      "city": "Semora",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Semora, North Carolina"
    },
    {
      "city": "Hurdle Mills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hurdle Mills, North Carolina"
    },
    {
      "city": "Timberlake",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Timberlake, North Carolina"
    },
    {
      "city": "Bellarthur",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bellarthur, North Carolina"
    },
    {
      "city": "Bethel",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bethel, North Carolina"
    },
    {
      "city": "Falkland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Falkland, North Carolina"
    },
    {
      "city": "Farmville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Farmville, North Carolina"
    },
    {
      "city": "Fountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fountain, North Carolina"
    },
    {
      "city": "Greenville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Greenville, North Carolina"
    },
    {
      "city": "Grimesland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grimesland, North Carolina"
    },
    {
      "city": "Simpson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Simpson, North Carolina"
    },
    {
      "city": "Stokes",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stokes, North Carolina"
    },
    {
      "city": "Ayden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ayden, North Carolina"
    },
    {
      "city": "Grifton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Grifton, North Carolina"
    },
    {
      "city": "Winterville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Winterville, North Carolina"
    },
    {
      "city": "Columbus",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Columbus, North Carolina"
    },
    {
      "city": "Lynn",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lynn, North Carolina"
    },
    {
      "city": "Mill Spring",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mill Spring, North Carolina"
    },
    {
      "city": "Saluda",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Saluda, North Carolina"
    },
    {
      "city": "Tryon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Tryon, North Carolina"
    },
    {
      "city": "Asheboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Asheboro, North Carolina"
    },
    {
      "city": "Cedar Falls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cedar Falls, North Carolina"
    },
    {
      "city": "Franklinville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Franklinville, North Carolina"
    },
    {
      "city": "Liberty",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Liberty, North Carolina"
    },
    {
      "city": "Ramseur",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ramseur, North Carolina"
    },
    {
      "city": "Randleman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Randleman, North Carolina"
    },
    {
      "city": "Seagrove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Seagrove, North Carolina"
    },
    {
      "city": "Sophia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sophia, North Carolina"
    },
    {
      "city": "Staley",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Staley, North Carolina"
    },
    {
      "city": "Trinity",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Trinity, North Carolina"
    },
    {
      "city": "Cordova",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cordova, North Carolina"
    },
    {
      "city": "Ellerbe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ellerbe, North Carolina"
    },
    {
      "city": "Hamlet",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hamlet, North Carolina"
    },
    {
      "city": "Hoffman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hoffman, North Carolina"
    },
    {
      "city": "Marston",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marston, North Carolina"
    },
    {
      "city": "Norman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Norman, North Carolina"
    },
    {
      "city": "Rockingham",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rockingham, North Carolina"
    },
    {
      "city": "Barnesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Barnesville, North Carolina"
    },
    {
      "city": "Fairmont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fairmont, North Carolina"
    },
    {
      "city": "Lumber Bridge",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lumber Bridge, North Carolina"
    },
    {
      "city": "Lumberton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lumberton, North Carolina"
    },
    {
      "city": "Marietta",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marietta, North Carolina"
    },
    {
      "city": "Maxton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Maxton, North Carolina"
    },
    {
      "city": "Orrum",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Orrum, North Carolina"
    },
    {
      "city": "Parkton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Parkton, North Carolina"
    },
    {
      "city": "Pembroke",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pembroke, North Carolina"
    },
    {
      "city": "Proctorville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Proctorville, North Carolina"
    },
    {
      "city": "Red Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Red Springs, North Carolina"
    },
    {
      "city": "Rex",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rex, North Carolina"
    },
    {
      "city": "Rowland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rowland, North Carolina"
    },
    {
      "city": "Saint Pauls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Saint Pauls, North Carolina"
    },
    {
      "city": "Shannon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Shannon, North Carolina"
    },
    {
      "city": "Madison",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Madison, North Carolina"
    },
    {
      "city": "Mayodan",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mayodan, North Carolina"
    },
    {
      "city": "Stoneville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stoneville, North Carolina"
    },
    {
      "city": "Eden",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Eden, North Carolina"
    },
    {
      "city": "Reidsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Reidsville, North Carolina"
    },
    {
      "city": "Ruffin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ruffin, North Carolina"
    },
    {
      "city": "Wentworth",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wentworth, North Carolina"
    },
    {
      "city": "Cleveland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cleveland, North Carolina"
    },
    {
      "city": "Woodleaf",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Woodleaf, North Carolina"
    },
    {
      "city": "China Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "China Grove, North Carolina"
    },
    {
      "city": "East Spencer",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "East Spencer, North Carolina"
    },
    {
      "city": "Faith",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Faith, North Carolina"
    },
    {
      "city": "Gold Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gold Hill, North Carolina"
    },
    {
      "city": "Granite Quarry",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Granite Quarry, North Carolina"
    },
    {
      "city": "Landis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Landis, North Carolina"
    },
    {
      "city": "Mount Ulla",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Ulla, North Carolina"
    },
    {
      "city": "Rockwell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rockwell, North Carolina"
    },
    {
      "city": "Salisbury",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Salisbury, North Carolina"
    },
    {
      "city": "Spencer",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Spencer, North Carolina"
    },
    {
      "city": "Bostic",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bostic, North Carolina"
    },
    {
      "city": "Caroleen",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Caroleen, North Carolina"
    },
    {
      "city": "Cliffside",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cliffside, North Carolina"
    },
    {
      "city": "Ellenboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ellenboro, North Carolina"
    },
    {
      "city": "Forest City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Forest City, North Carolina"
    },
    {
      "city": "Harris",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harris, North Carolina"
    },
    {
      "city": "Henrietta",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Henrietta, North Carolina"
    },
    {
      "city": "Rutherfordton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rutherfordton, North Carolina"
    },
    {
      "city": "Spindale",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Spindale, North Carolina"
    },
    {
      "city": "Union Mills",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Union Mills, North Carolina"
    },
    {
      "city": "Chimney Rock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Chimney Rock, North Carolina"
    },
    {
      "city": "Lake Lure",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lake Lure, North Carolina"
    },
    {
      "city": "Autryville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Autryville, North Carolina"
    },
    {
      "city": "Clinton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Clinton, North Carolina"
    },
    {
      "city": "Newton Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Newton Grove, North Carolina"
    },
    {
      "city": "Roseboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roseboro, North Carolina"
    },
    {
      "city": "Salemburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Salemburg, North Carolina"
    },
    {
      "city": "Turkey",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Turkey, North Carolina"
    },
    {
      "city": "Garland",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Garland, North Carolina"
    },
    {
      "city": "Harrells",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Harrells, North Carolina"
    },
    {
      "city": "Ivanhoe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ivanhoe, North Carolina"
    },
    {
      "city": "Gibson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Gibson, North Carolina"
    },
    {
      "city": "Laurel Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Laurel Hill, North Carolina"
    },
    {
      "city": "Laurinburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Laurinburg, North Carolina"
    },
    {
      "city": "Wagram",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wagram, North Carolina"
    },
    {
      "city": "Albemarle",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Albemarle, North Carolina"
    },
    {
      "city": "Badin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Badin, North Carolina"
    },
    {
      "city": "Locust",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Locust, North Carolina"
    },
    {
      "city": "Misenheimer",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Misenheimer, North Carolina"
    },
    {
      "city": "New London",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "New London, North Carolina"
    },
    {
      "city": "Norwood",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Norwood, North Carolina"
    },
    {
      "city": "Oakboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Oakboro, North Carolina"
    },
    {
      "city": "Richfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Richfield, North Carolina"
    },
    {
      "city": "Stanfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stanfield, North Carolina"
    },
    {
      "city": "Danbury",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Danbury, North Carolina"
    },
    {
      "city": "Germanton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Germanton, North Carolina"
    },
    {
      "city": "King",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "King, North Carolina"
    },
    {
      "city": "Lawsonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lawsonville, North Carolina"
    },
    {
      "city": "Pine Hall",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pine Hall, North Carolina"
    },
    {
      "city": "Pinnacle",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pinnacle, North Carolina"
    },
    {
      "city": "Sandy Ridge",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sandy Ridge, North Carolina"
    },
    {
      "city": "Walnut Cove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Walnut Cove, North Carolina"
    },
    {
      "city": "Ararat",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ararat, North Carolina"
    },
    {
      "city": "Dobson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dobson, North Carolina"
    },
    {
      "city": "Lowgap",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lowgap, North Carolina"
    },
    {
      "city": "Mount Airy",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Airy, North Carolina"
    },
    {
      "city": "White Plains",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "White Plains, North Carolina"
    },
    {
      "city": "Pilot Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pilot Mountain, North Carolina"
    },
    {
      "city": "Siloam",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Siloam, North Carolina"
    },
    {
      "city": "Toast",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Toast, North Carolina"
    },
    {
      "city": "Westfield",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Westfield, North Carolina"
    },
    {
      "city": "Elkin",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elkin, North Carolina"
    },
    {
      "city": "State Road",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "State Road, North Carolina"
    },
    {
      "city": "Almond",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Almond, North Carolina"
    },
    {
      "city": "Bryson City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Bryson City, North Carolina"
    },
    {
      "city": "Cherokee",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cherokee, North Carolina"
    },
    {
      "city": "Balsam Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Balsam Grove, North Carolina"
    },
    {
      "city": "Brevard",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Brevard, North Carolina"
    },
    {
      "city": "Cedar Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cedar Mountain, North Carolina"
    },
    {
      "city": "Lake Toxaway",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lake Toxaway, North Carolina"
    },
    {
      "city": "Penrose",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Penrose, North Carolina"
    },
    {
      "city": "Pisgah Forest",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pisgah Forest, North Carolina"
    },
    {
      "city": "Rosman",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rosman, North Carolina"
    },
    {
      "city": "Sapphire",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sapphire, North Carolina"
    },
    {
      "city": "Columbia",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Columbia, North Carolina"
    },
    {
      "city": "Indian Trail",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Indian Trail, North Carolina"
    },
    {
      "city": "Marshville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Marshville, North Carolina"
    },
    {
      "city": "Mineral Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mineral Springs, North Carolina"
    },
    {
      "city": "Monroe",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Monroe, North Carolina"
    },
    {
      "city": "Waxhaw",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Waxhaw, North Carolina"
    },
    {
      "city": "Wingate",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wingate, North Carolina"
    },
    {
      "city": "Henderson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Henderson, North Carolina"
    },
    {
      "city": "Kittrell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Kittrell, North Carolina"
    },
    {
      "city": "Manson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Manson, North Carolina"
    },
    {
      "city": "Middleburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Middleburg, North Carolina"
    },
    {
      "city": "Townsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Townsville, North Carolina"
    },
    {
      "city": "Apex",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Apex, North Carolina"
    },
    {
      "city": "Cary",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Cary, North Carolina"
    },
    {
      "city": "Fuquay Varina",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fuquay Varina, North Carolina"
    },
    {
      "city": "Garner",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Garner, North Carolina"
    },
    {
      "city": "Holly Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Holly Springs, North Carolina"
    },
    {
      "city": "Knightdale",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Knightdale, North Carolina"
    },
    {
      "city": "Morrisville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Morrisville, North Carolina"
    },
    {
      "city": "New Hill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "New Hill, North Carolina"
    },
    {
      "city": "Rolesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Rolesville, North Carolina"
    },
    {
      "city": "Wake Forest",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wake Forest, North Carolina"
    },
    {
      "city": "Wendell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wendell, North Carolina"
    },
    {
      "city": "Willow Spring",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Willow Spring, North Carolina"
    },
    {
      "city": "Zebulon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Zebulon, North Carolina"
    },
    {
      "city": "Raleigh",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Raleigh, North Carolina"
    },
    {
      "city": "Macon",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Macon, North Carolina"
    },
    {
      "city": "Norlina",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Norlina, North Carolina"
    },
    {
      "city": "Ridgeway",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ridgeway, North Carolina"
    },
    {
      "city": "Vaughan",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vaughan, North Carolina"
    },
    {
      "city": "Warrenton",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Warrenton, North Carolina"
    },
    {
      "city": "Wise",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wise, North Carolina"
    },
    {
      "city": "Creswell",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Creswell, North Carolina"
    },
    {
      "city": "Plymouth",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Plymouth, North Carolina"
    },
    {
      "city": "Roper",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roper, North Carolina"
    },
    {
      "city": "Blowing Rock",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Blowing Rock, North Carolina"
    },
    {
      "city": "Boone",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Boone, North Carolina"
    },
    {
      "city": "Deep Gap",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Deep Gap, North Carolina"
    },
    {
      "city": "Sugar Grove",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sugar Grove, North Carolina"
    },
    {
      "city": "Valle Crucis",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Valle Crucis, North Carolina"
    },
    {
      "city": "Vilas",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Vilas, North Carolina"
    },
    {
      "city": "Zionville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Zionville, North Carolina"
    },
    {
      "city": "Goldsboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Goldsboro, North Carolina"
    },
    {
      "city": "Fremont",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Fremont, North Carolina"
    },
    {
      "city": "Pikeville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Pikeville, North Carolina"
    },
    {
      "city": "Dudley",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Dudley, North Carolina"
    },
    {
      "city": "Mount Olive",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mount Olive, North Carolina"
    },
    {
      "city": "Seven Springs",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Seven Springs, North Carolina"
    },
    {
      "city": "Boomer",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Boomer, North Carolina"
    },
    {
      "city": "Ferguson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ferguson, North Carolina"
    },
    {
      "city": "Hays",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hays, North Carolina"
    },
    {
      "city": "Mc Grady",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Mc Grady, North Carolina"
    },
    {
      "city": "Millers Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Millers Creek, North Carolina"
    },
    {
      "city": "Moravian Falls",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Moravian Falls, North Carolina"
    },
    {
      "city": "North Wilkesboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "North Wilkesboro, North Carolina"
    },
    {
      "city": "Purlear",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Purlear, North Carolina"
    },
    {
      "city": "Roaring River",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Roaring River, North Carolina"
    },
    {
      "city": "Ronda",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Ronda, North Carolina"
    },
    {
      "city": "Thurmond",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Thurmond, North Carolina"
    },
    {
      "city": "Traphill",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Traphill, North Carolina"
    },
    {
      "city": "Wilkesboro",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wilkesboro, North Carolina"
    },
    {
      "city": "Black Creek",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Black Creek, North Carolina"
    },
    {
      "city": "Elm City",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Elm City, North Carolina"
    },
    {
      "city": "Lucama",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Lucama, North Carolina"
    },
    {
      "city": "Saratoga",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Saratoga, North Carolina"
    },
    {
      "city": "Sims",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Sims, North Carolina"
    },
    {
      "city": "Stantonsburg",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Stantonsburg, North Carolina"
    },
    {
      "city": "Wilson",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Wilson, North Carolina"
    },
    {
      "city": "Boonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Boonville, North Carolina"
    },
    {
      "city": "East Bend",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "East Bend, North Carolina"
    },
    {
      "city": "Hamptonville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Hamptonville, North Carolina"
    },
    {
      "city": "Yadkinville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Yadkinville, North Carolina"
    },
    {
      "city": "Jonesville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Jonesville, North Carolina"
    },
    {
      "city": "Burnsville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Burnsville, North Carolina"
    },
    {
      "city": "Green Mountain",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Green Mountain, North Carolina"
    },
    {
      "city": "Micaville",
      "state": "North Carolina",
      "shortState": "NC",
      "formatedCity": "Micaville, North Carolina"
    },
    {
      "city": "Hettinger",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hettinger, North Dakota"
    },
    {
      "city": "Reeder",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Reeder, North Dakota"
    },
    {
      "city": "Fingal",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fingal, North Dakota"
    },
    {
      "city": "Kathryn",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kathryn, North Dakota"
    },
    {
      "city": "Nome",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Nome, North Dakota"
    },
    {
      "city": "Oriska",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Oriska, North Dakota"
    },
    {
      "city": "Pillsbury",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pillsbury, North Dakota"
    },
    {
      "city": "Valley City",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Valley City, North Dakota"
    },
    {
      "city": "Dazey",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dazey, North Dakota"
    },
    {
      "city": "Litchville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Litchville, North Dakota"
    },
    {
      "city": "Rogers",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rogers, North Dakota"
    },
    {
      "city": "Sanborn",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sanborn, North Dakota"
    },
    {
      "city": "Wimbledon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wimbledon, North Dakota"
    },
    {
      "city": "Esmond",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Esmond, North Dakota"
    },
    {
      "city": "Fort Totten",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fort Totten, North Dakota"
    },
    {
      "city": "Knox",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Knox, North Dakota"
    },
    {
      "city": "Leeds",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Leeds, North Dakota"
    },
    {
      "city": "Maddock",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Maddock, North Dakota"
    },
    {
      "city": "Minnewaukan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Minnewaukan, North Dakota"
    },
    {
      "city": "Oberon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Oberon, North Dakota"
    },
    {
      "city": "Saint Michael",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Saint Michael, North Dakota"
    },
    {
      "city": "Tokio",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tokio, North Dakota"
    },
    {
      "city": "Warwick",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Warwick, North Dakota"
    },
    {
      "city": "York",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "York, North Dakota"
    },
    {
      "city": "Fairfield",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fairfield, North Dakota"
    },
    {
      "city": "Medora",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Medora, North Dakota"
    },
    {
      "city": "Bottineau",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bottineau, North Dakota"
    },
    {
      "city": "Willow City",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Willow City, North Dakota"
    },
    {
      "city": "Antler",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Antler, North Dakota"
    },
    {
      "city": "Kramer",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kramer, North Dakota"
    },
    {
      "city": "Lansford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lansford, North Dakota"
    },
    {
      "city": "Maxbass",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Maxbass, North Dakota"
    },
    {
      "city": "Newburg",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Newburg, North Dakota"
    },
    {
      "city": "Souris",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Souris, North Dakota"
    },
    {
      "city": "Westhope",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Westhope, North Dakota"
    },
    {
      "city": "Bowman",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bowman, North Dakota"
    },
    {
      "city": "Rhame",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rhame, North Dakota"
    },
    {
      "city": "Scranton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Scranton, North Dakota"
    },
    {
      "city": "Bowbells",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bowbells, North Dakota"
    },
    {
      "city": "Columbus",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Columbus, North Dakota"
    },
    {
      "city": "Flaxton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Flaxton, North Dakota"
    },
    {
      "city": "Lignite",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lignite, North Dakota"
    },
    {
      "city": "Portal",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Portal, North Dakota"
    },
    {
      "city": "Powers Lake",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Powers Lake, North Dakota"
    },
    {
      "city": "Regan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Regan, North Dakota"
    },
    {
      "city": "Wing",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wing, North Dakota"
    },
    {
      "city": "Bismarck",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bismarck, North Dakota"
    },
    {
      "city": "Baldwin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Baldwin, North Dakota"
    },
    {
      "city": "Driscoll",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Driscoll, North Dakota"
    },
    {
      "city": "Menoken",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Menoken, North Dakota"
    },
    {
      "city": "Moffit",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Moffit, North Dakota"
    },
    {
      "city": "Sterling",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sterling, North Dakota"
    },
    {
      "city": "Absaraka",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Absaraka, North Dakota"
    },
    {
      "city": "Amenia",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Amenia, North Dakota"
    },
    {
      "city": "Argusville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Argusville, North Dakota"
    },
    {
      "city": "Arthur",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Arthur, North Dakota"
    },
    {
      "city": "Ayr",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ayr, North Dakota"
    },
    {
      "city": "Buffalo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Buffalo, North Dakota"
    },
    {
      "city": "Casselton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Casselton, North Dakota"
    },
    {
      "city": "Davenport",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Davenport, North Dakota"
    },
    {
      "city": "Erie",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Erie, North Dakota"
    },
    {
      "city": "Gardner",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Gardner, North Dakota"
    },
    {
      "city": "Grandin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grandin, North Dakota"
    },
    {
      "city": "Harwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Harwood, North Dakota"
    },
    {
      "city": "Horace",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Horace, North Dakota"
    },
    {
      "city": "Hunter",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hunter, North Dakota"
    },
    {
      "city": "Kindred",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kindred, North Dakota"
    },
    {
      "city": "Leonard",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Leonard, North Dakota"
    },
    {
      "city": "Mapleton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mapleton, North Dakota"
    },
    {
      "city": "Page",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Page, North Dakota"
    },
    {
      "city": "Tower City",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tower City, North Dakota"
    },
    {
      "city": "West Fargo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "West Fargo, North Dakota"
    },
    {
      "city": "Wheatland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wheatland, North Dakota"
    },
    {
      "city": "Fargo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fargo, North Dakota"
    },
    {
      "city": "Hannah",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hannah, North Dakota"
    },
    {
      "city": "Langdon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Langdon, North Dakota"
    },
    {
      "city": "Maida",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Maida, North Dakota"
    },
    {
      "city": "Milton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Milton, North Dakota"
    },
    {
      "city": "Osnabrock",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Osnabrock, North Dakota"
    },
    {
      "city": "Wales",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wales, North Dakota"
    },
    {
      "city": "Alsen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Alsen, North Dakota"
    },
    {
      "city": "Calvin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Calvin, North Dakota"
    },
    {
      "city": "Munich",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Munich, North Dakota"
    },
    {
      "city": "Nekoma",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Nekoma, North Dakota"
    },
    {
      "city": "Sarles",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sarles, North Dakota"
    },
    {
      "city": "Ellendale",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ellendale, North Dakota"
    },
    {
      "city": "Forbes",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Forbes, North Dakota"
    },
    {
      "city": "Fullerton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fullerton, North Dakota"
    },
    {
      "city": "Oakes",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Oakes, North Dakota"
    },
    {
      "city": "Crosby",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Crosby, North Dakota"
    },
    {
      "city": "Noonan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Noonan, North Dakota"
    },
    {
      "city": "Ambrose",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ambrose, North Dakota"
    },
    {
      "city": "Fortuna",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fortuna, North Dakota"
    },
    {
      "city": "Dodge",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dodge, North Dakota"
    },
    {
      "city": "Dunn Center",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dunn Center, North Dakota"
    },
    {
      "city": "Halliday",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Halliday, North Dakota"
    },
    {
      "city": "Killdeer",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Killdeer, North Dakota"
    },
    {
      "city": "Manning",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Manning, North Dakota"
    },
    {
      "city": "Marshall",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Marshall, North Dakota"
    },
    {
      "city": "New Rockford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "New Rockford, North Dakota"
    },
    {
      "city": "Sheyenne",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sheyenne, North Dakota"
    },
    {
      "city": "Braddock",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Braddock, North Dakota"
    },
    {
      "city": "Hague",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hague, North Dakota"
    },
    {
      "city": "Hazelton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hazelton, North Dakota"
    },
    {
      "city": "Kintyre",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kintyre, North Dakota"
    },
    {
      "city": "Linton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Linton, North Dakota"
    },
    {
      "city": "Strasburg",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Strasburg, North Dakota"
    },
    {
      "city": "Carrington",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Carrington, North Dakota"
    },
    {
      "city": "Glenfield",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Glenfield, North Dakota"
    },
    {
      "city": "Grace City",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grace City, North Dakota"
    },
    {
      "city": "Mchenry",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mchenry, North Dakota"
    },
    {
      "city": "Beach",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Beach, North Dakota"
    },
    {
      "city": "Golva",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Golva, North Dakota"
    },
    {
      "city": "Sentinel Butte",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sentinel Butte, North Dakota"
    },
    {
      "city": "Grand Forks",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grand Forks, North Dakota"
    },
    {
      "city": "Grand Forks Afb",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grand Forks Afb, North Dakota"
    },
    {
      "city": "Arvilla",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Arvilla, North Dakota"
    },
    {
      "city": "Emerado",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Emerado, North Dakota"
    },
    {
      "city": "Gilby",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Gilby, North Dakota"
    },
    {
      "city": "Inkster",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Inkster, North Dakota"
    },
    {
      "city": "Larimore",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Larimore, North Dakota"
    },
    {
      "city": "Manvel",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Manvel, North Dakota"
    },
    {
      "city": "Mekinock",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mekinock, North Dakota"
    },
    {
      "city": "Niagara",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Niagara, North Dakota"
    },
    {
      "city": "Northwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Northwood, North Dakota"
    },
    {
      "city": "Reynolds",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Reynolds, North Dakota"
    },
    {
      "city": "Thompson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Thompson, North Dakota"
    },
    {
      "city": "Carson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Carson, North Dakota"
    },
    {
      "city": "Elgin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Elgin, North Dakota"
    },
    {
      "city": "New Leipzig",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "New Leipzig, North Dakota"
    },
    {
      "city": "Raleigh",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Raleigh, North Dakota"
    },
    {
      "city": "Shields",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Shields, North Dakota"
    },
    {
      "city": "Binford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Binford, North Dakota"
    },
    {
      "city": "Cooperstown",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cooperstown, North Dakota"
    },
    {
      "city": "Hannaford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hannaford, North Dakota"
    },
    {
      "city": "Jessie",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Jessie, North Dakota"
    },
    {
      "city": "Sutton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sutton, North Dakota"
    },
    {
      "city": "Mott",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mott, North Dakota"
    },
    {
      "city": "New England",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "New England, North Dakota"
    },
    {
      "city": "Regent",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Regent, North Dakota"
    },
    {
      "city": "Dawson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dawson, North Dakota"
    },
    {
      "city": "Pettibone",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pettibone, North Dakota"
    },
    {
      "city": "Robinson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Robinson, North Dakota"
    },
    {
      "city": "Steele",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Steele, North Dakota"
    },
    {
      "city": "Tappen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tappen, North Dakota"
    },
    {
      "city": "Tuttle",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tuttle, North Dakota"
    },
    {
      "city": "Berlin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Berlin, North Dakota"
    },
    {
      "city": "Dickey",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dickey, North Dakota"
    },
    {
      "city": "Edgeley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Edgeley, North Dakota"
    },
    {
      "city": "Jud",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Jud, North Dakota"
    },
    {
      "city": "Kulm",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kulm, North Dakota"
    },
    {
      "city": "Lamoure",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lamoure, North Dakota"
    },
    {
      "city": "Marion",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Marion, North Dakota"
    },
    {
      "city": "Verona",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Verona, North Dakota"
    },
    {
      "city": "Fredonia",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fredonia, North Dakota"
    },
    {
      "city": "Gackle",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Gackle, North Dakota"
    },
    {
      "city": "Napoleon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Napoleon, North Dakota"
    },
    {
      "city": "Anamoose",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Anamoose, North Dakota"
    },
    {
      "city": "Balfour",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Balfour, North Dakota"
    },
    {
      "city": "Bantry",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bantry, North Dakota"
    },
    {
      "city": "Deering",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Deering, North Dakota"
    },
    {
      "city": "Drake",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Drake, North Dakota"
    },
    {
      "city": "Granville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Granville, North Dakota"
    },
    {
      "city": "Karlsruhe",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Karlsruhe, North Dakota"
    },
    {
      "city": "Norwich",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Norwich, North Dakota"
    },
    {
      "city": "Towner",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Towner, North Dakota"
    },
    {
      "city": "Upham",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Upham, North Dakota"
    },
    {
      "city": "Velva",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Velva, North Dakota"
    },
    {
      "city": "Voltaire",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Voltaire, North Dakota"
    },
    {
      "city": "Ashley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ashley, North Dakota"
    },
    {
      "city": "Lehr",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lehr, North Dakota"
    },
    {
      "city": "Wishek",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wishek, North Dakota"
    },
    {
      "city": "Zeeland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Zeeland, North Dakota"
    },
    {
      "city": "Grassy Butte",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grassy Butte, North Dakota"
    },
    {
      "city": "Mandaree",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mandaree, North Dakota"
    },
    {
      "city": "Alexander",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Alexander, North Dakota"
    },
    {
      "city": "Arnegard",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Arnegard, North Dakota"
    },
    {
      "city": "Cartwright",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cartwright, North Dakota"
    },
    {
      "city": "Keene",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Keene, North Dakota"
    },
    {
      "city": "Watford City",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Watford City, North Dakota"
    },
    {
      "city": "Coleharbor",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Coleharbor, North Dakota"
    },
    {
      "city": "Garrison",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Garrison, North Dakota"
    },
    {
      "city": "Mercer",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mercer, North Dakota"
    },
    {
      "city": "Riverdale",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Riverdale, North Dakota"
    },
    {
      "city": "Turtle Lake",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Turtle Lake, North Dakota"
    },
    {
      "city": "Underwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Underwood, North Dakota"
    },
    {
      "city": "Washburn",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Washburn, North Dakota"
    },
    {
      "city": "Wilton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wilton, North Dakota"
    },
    {
      "city": "Benedict",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Benedict, North Dakota"
    },
    {
      "city": "Butte",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Butte, North Dakota"
    },
    {
      "city": "Max",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Max, North Dakota"
    },
    {
      "city": "Roseglen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Roseglen, North Dakota"
    },
    {
      "city": "Ruso",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ruso, North Dakota"
    },
    {
      "city": "Beulah",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Beulah, North Dakota"
    },
    {
      "city": "Golden Valley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Golden Valley, North Dakota"
    },
    {
      "city": "Hazen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hazen, North Dakota"
    },
    {
      "city": "Stanton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Stanton, North Dakota"
    },
    {
      "city": "Zap",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Zap, North Dakota"
    },
    {
      "city": "Almont",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Almont, North Dakota"
    },
    {
      "city": "Flasher",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Flasher, North Dakota"
    },
    {
      "city": "Mandan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mandan, North Dakota"
    },
    {
      "city": "New Salem",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "New Salem, North Dakota"
    },
    {
      "city": "Saint Anthony",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Saint Anthony, North Dakota"
    },
    {
      "city": "Glen Ullin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Glen Ullin, North Dakota"
    },
    {
      "city": "Hebron",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hebron, North Dakota"
    },
    {
      "city": "New Town",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "New Town, North Dakota"
    },
    {
      "city": "Palermo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Palermo, North Dakota"
    },
    {
      "city": "Parshall",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Parshall, North Dakota"
    },
    {
      "city": "Plaza",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Plaza, North Dakota"
    },
    {
      "city": "Ross",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ross, North Dakota"
    },
    {
      "city": "Stanley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Stanley, North Dakota"
    },
    {
      "city": "White Earth",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "White Earth, North Dakota"
    },
    {
      "city": "Aneta",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Aneta, North Dakota"
    },
    {
      "city": "Dahlen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dahlen, North Dakota"
    },
    {
      "city": "Mcville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mcville, North Dakota"
    },
    {
      "city": "Michigan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Michigan, North Dakota"
    },
    {
      "city": "Petersburg",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Petersburg, North Dakota"
    },
    {
      "city": "Lakota",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lakota, North Dakota"
    },
    {
      "city": "Pekin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pekin, North Dakota"
    },
    {
      "city": "Tolna",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tolna, North Dakota"
    },
    {
      "city": "Center",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Center, North Dakota"
    },
    {
      "city": "Bathgate",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bathgate, North Dakota"
    },
    {
      "city": "Cavalier",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cavalier, North Dakota"
    },
    {
      "city": "Crystal",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Crystal, North Dakota"
    },
    {
      "city": "Drayton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Drayton, North Dakota"
    },
    {
      "city": "Glasston",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Glasston, North Dakota"
    },
    {
      "city": "Hamilton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hamilton, North Dakota"
    },
    {
      "city": "Hensel",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hensel, North Dakota"
    },
    {
      "city": "Mountain",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mountain, North Dakota"
    },
    {
      "city": "Neche",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Neche, North Dakota"
    },
    {
      "city": "Pembina",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pembina, North Dakota"
    },
    {
      "city": "Saint Thomas",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Saint Thomas, North Dakota"
    },
    {
      "city": "Walhalla",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Walhalla, North Dakota"
    },
    {
      "city": "Balta",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Balta, North Dakota"
    },
    {
      "city": "Rugby",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rugby, North Dakota"
    },
    {
      "city": "Wolford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wolford, North Dakota"
    },
    {
      "city": "Devils Lake",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Devils Lake, North Dakota"
    },
    {
      "city": "Brocket",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Brocket, North Dakota"
    },
    {
      "city": "Churchs Ferry",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Churchs Ferry, North Dakota"
    },
    {
      "city": "Crary",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Crary, North Dakota"
    },
    {
      "city": "Edmore",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Edmore, North Dakota"
    },
    {
      "city": "Hampden",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hampden, North Dakota"
    },
    {
      "city": "Lawton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lawton, North Dakota"
    },
    {
      "city": "Penn",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Penn, North Dakota"
    },
    {
      "city": "Starkweather",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Starkweather, North Dakota"
    },
    {
      "city": "Webster",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Webster, North Dakota"
    },
    {
      "city": "Enderlin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Enderlin, North Dakota"
    },
    {
      "city": "Fort Ransom",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fort Ransom, North Dakota"
    },
    {
      "city": "Lisbon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lisbon, North Dakota"
    },
    {
      "city": "Mcleod",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mcleod, North Dakota"
    },
    {
      "city": "Sheldon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sheldon, North Dakota"
    },
    {
      "city": "Glenburn",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Glenburn, North Dakota"
    },
    {
      "city": "Mohall",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mohall, North Dakota"
    },
    {
      "city": "Sherwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sherwood, North Dakota"
    },
    {
      "city": "Tolley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tolley, North Dakota"
    },
    {
      "city": "Abercrombie",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Abercrombie, North Dakota"
    },
    {
      "city": "Barney",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Barney, North Dakota"
    },
    {
      "city": "Christine",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Christine, North Dakota"
    },
    {
      "city": "Colfax",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Colfax, North Dakota"
    },
    {
      "city": "Fairmount",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fairmount, North Dakota"
    },
    {
      "city": "Hankinson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hankinson, North Dakota"
    },
    {
      "city": "Lidgerwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lidgerwood, North Dakota"
    },
    {
      "city": "Mantador",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mantador, North Dakota"
    },
    {
      "city": "Mooreton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mooreton, North Dakota"
    },
    {
      "city": "Wahpeton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wahpeton, North Dakota"
    },
    {
      "city": "Walcott",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Walcott, North Dakota"
    },
    {
      "city": "Wyndmere",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wyndmere, North Dakota"
    },
    {
      "city": "Agate",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Agate, North Dakota"
    },
    {
      "city": "Belcourt",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Belcourt, North Dakota"
    },
    {
      "city": "Dunseith",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dunseith, North Dakota"
    },
    {
      "city": "Mylo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mylo, North Dakota"
    },
    {
      "city": "Rolette",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rolette, North Dakota"
    },
    {
      "city": "Rolla",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rolla, North Dakota"
    },
    {
      "city": "Saint John",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Saint John, North Dakota"
    },
    {
      "city": "Cayuga",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cayuga, North Dakota"
    },
    {
      "city": "Cogswell",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cogswell, North Dakota"
    },
    {
      "city": "Forman",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Forman, North Dakota"
    },
    {
      "city": "Gwinner",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Gwinner, North Dakota"
    },
    {
      "city": "Havana",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Havana, North Dakota"
    },
    {
      "city": "Milnor",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Milnor, North Dakota"
    },
    {
      "city": "Rutland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rutland, North Dakota"
    },
    {
      "city": "Stirum",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Stirum, North Dakota"
    },
    {
      "city": "Denhoff",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Denhoff, North Dakota"
    },
    {
      "city": "Goodrich",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Goodrich, North Dakota"
    },
    {
      "city": "Mcclusky",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mcclusky, North Dakota"
    },
    {
      "city": "Martin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Martin, North Dakota"
    },
    {
      "city": "Cannon Ball",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cannon Ball, North Dakota"
    },
    {
      "city": "Fort Yates",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fort Yates, North Dakota"
    },
    {
      "city": "Selfridge",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Selfridge, North Dakota"
    },
    {
      "city": "Solen",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Solen, North Dakota"
    },
    {
      "city": "Amidon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Amidon, North Dakota"
    },
    {
      "city": "Marmarth",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Marmarth, North Dakota"
    },
    {
      "city": "Dickinson",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Dickinson, North Dakota"
    },
    {
      "city": "Belfield",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Belfield, North Dakota"
    },
    {
      "city": "Gladstone",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Gladstone, North Dakota"
    },
    {
      "city": "Lefor",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lefor, North Dakota"
    },
    {
      "city": "Richardton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Richardton, North Dakota"
    },
    {
      "city": "South Heart",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "South Heart, North Dakota"
    },
    {
      "city": "Taylor",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Taylor, North Dakota"
    },
    {
      "city": "Hope",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hope, North Dakota"
    },
    {
      "city": "Luverne",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Luverne, North Dakota"
    },
    {
      "city": "Finley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Finley, North Dakota"
    },
    {
      "city": "Sharon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sharon, North Dakota"
    },
    {
      "city": "Jamestown",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Jamestown, North Dakota"
    },
    {
      "city": "Buchanan",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Buchanan, North Dakota"
    },
    {
      "city": "Cleveland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cleveland, North Dakota"
    },
    {
      "city": "Courtenay",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Courtenay, North Dakota"
    },
    {
      "city": "Kensal",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kensal, North Dakota"
    },
    {
      "city": "Medina",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Medina, North Dakota"
    },
    {
      "city": "Montpelier",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Montpelier, North Dakota"
    },
    {
      "city": "Pingree",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pingree, North Dakota"
    },
    {
      "city": "Spiritwood",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Spiritwood, North Dakota"
    },
    {
      "city": "Streeter",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Streeter, North Dakota"
    },
    {
      "city": "Woodworth",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Woodworth, North Dakota"
    },
    {
      "city": "Ypsilanti",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ypsilanti, North Dakota"
    },
    {
      "city": "Bisbee",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bisbee, North Dakota"
    },
    {
      "city": "Cando",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cando, North Dakota"
    },
    {
      "city": "Egeland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Egeland, North Dakota"
    },
    {
      "city": "Hansboro",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hansboro, North Dakota"
    },
    {
      "city": "Perth",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Perth, North Dakota"
    },
    {
      "city": "Rocklake",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Rocklake, North Dakota"
    },
    {
      "city": "Blanchard",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Blanchard, North Dakota"
    },
    {
      "city": "Clifford",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Clifford, North Dakota"
    },
    {
      "city": "Galesburg",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Galesburg, North Dakota"
    },
    {
      "city": "Hillsboro",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hillsboro, North Dakota"
    },
    {
      "city": "Buxton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Buxton, North Dakota"
    },
    {
      "city": "Caledonia",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Caledonia, North Dakota"
    },
    {
      "city": "Cummings",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cummings, North Dakota"
    },
    {
      "city": "Hatton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hatton, North Dakota"
    },
    {
      "city": "Mayville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mayville, North Dakota"
    },
    {
      "city": "Portland",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Portland, North Dakota"
    },
    {
      "city": "Adams",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Adams, North Dakota"
    },
    {
      "city": "Edinburg",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Edinburg, North Dakota"
    },
    {
      "city": "Fairdale",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fairdale, North Dakota"
    },
    {
      "city": "Fordville",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fordville, North Dakota"
    },
    {
      "city": "Forest River",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Forest River, North Dakota"
    },
    {
      "city": "Grafton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grafton, North Dakota"
    },
    {
      "city": "Hoople",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hoople, North Dakota"
    },
    {
      "city": "Lankin",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Lankin, North Dakota"
    },
    {
      "city": "Minto",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Minto, North Dakota"
    },
    {
      "city": "Park River",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Park River, North Dakota"
    },
    {
      "city": "Pisek",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Pisek, North Dakota"
    },
    {
      "city": "Minot",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Minot, North Dakota"
    },
    {
      "city": "Minot Afb",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Minot Afb, North Dakota"
    },
    {
      "city": "Berthold",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Berthold, North Dakota"
    },
    {
      "city": "Burlington",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Burlington, North Dakota"
    },
    {
      "city": "Carpio",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Carpio, North Dakota"
    },
    {
      "city": "Des Lacs",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Des Lacs, North Dakota"
    },
    {
      "city": "Donnybrook",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Donnybrook, North Dakota"
    },
    {
      "city": "Douglas",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Douglas, North Dakota"
    },
    {
      "city": "Kenmare",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Kenmare, North Dakota"
    },
    {
      "city": "Makoti",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Makoti, North Dakota"
    },
    {
      "city": "Ryder",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ryder, North Dakota"
    },
    {
      "city": "Sawyer",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sawyer, North Dakota"
    },
    {
      "city": "Surrey",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Surrey, North Dakota"
    },
    {
      "city": "Harvey",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Harvey, North Dakota"
    },
    {
      "city": "Bowdon",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Bowdon, North Dakota"
    },
    {
      "city": "Cathay",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Cathay, North Dakota"
    },
    {
      "city": "Chaseley",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Chaseley, North Dakota"
    },
    {
      "city": "Fessenden",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Fessenden, North Dakota"
    },
    {
      "city": "Hurdsfield",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Hurdsfield, North Dakota"
    },
    {
      "city": "Sykeston",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Sykeston, North Dakota"
    },
    {
      "city": "Mcgregor",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Mcgregor, North Dakota"
    },
    {
      "city": "Wildrose",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Wildrose, North Dakota"
    },
    {
      "city": "Williston",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Williston, North Dakota"
    },
    {
      "city": "Alamo",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Alamo, North Dakota"
    },
    {
      "city": "Epping",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Epping, North Dakota"
    },
    {
      "city": "Grenora",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Grenora, North Dakota"
    },
    {
      "city": "Ray",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Ray, North Dakota"
    },
    {
      "city": "Tioga",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Tioga, North Dakota"
    },
    {
      "city": "Trenton",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Trenton, North Dakota"
    },
    {
      "city": "Zahl",
      "state": "North Dakota",
      "shortState": "ND",
      "formatedCity": "Zahl, North Dakota"
    },
    {
      "city": "Hastings",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hastings, Nebraska"
    },
    {
      "city": "Ayr",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ayr, Nebraska"
    },
    {
      "city": "Holstein",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Holstein, Nebraska"
    },
    {
      "city": "Juniata",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Juniata, Nebraska"
    },
    {
      "city": "Kenesaw",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Kenesaw, Nebraska"
    },
    {
      "city": "Roseland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Roseland, Nebraska"
    },
    {
      "city": "Elgin",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elgin, Nebraska"
    },
    {
      "city": "Brunswick",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brunswick, Nebraska"
    },
    {
      "city": "Clearwater",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Clearwater, Nebraska"
    },
    {
      "city": "Neligh",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Neligh, Nebraska"
    },
    {
      "city": "Oakdale",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oakdale, Nebraska"
    },
    {
      "city": "Orchard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Orchard, Nebraska"
    },
    {
      "city": "Royal",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Royal, Nebraska"
    },
    {
      "city": "Arthur",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Arthur, Nebraska"
    },
    {
      "city": "Harrisburg",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Harrisburg, Nebraska"
    },
    {
      "city": "Brewster",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brewster, Nebraska"
    },
    {
      "city": "Dunning",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dunning, Nebraska"
    },
    {
      "city": "Purdum",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Purdum, Nebraska"
    },
    {
      "city": "Albion",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Albion, Nebraska"
    },
    {
      "city": "Cedar Rapids",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cedar Rapids, Nebraska"
    },
    {
      "city": "Petersburg",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Petersburg, Nebraska"
    },
    {
      "city": "Primrose",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Primrose, Nebraska"
    },
    {
      "city": "Saint Edward",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Saint Edward, Nebraska"
    },
    {
      "city": "Alliance",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Alliance, Nebraska"
    },
    {
      "city": "Hemingford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hemingford, Nebraska"
    },
    {
      "city": "Bristow",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bristow, Nebraska"
    },
    {
      "city": "Butte",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Butte, Nebraska"
    },
    {
      "city": "Lynch",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lynch, Nebraska"
    },
    {
      "city": "Naper",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Naper, Nebraska"
    },
    {
      "city": "Spencer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Spencer, Nebraska"
    },
    {
      "city": "Ainsworth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ainsworth, Nebraska"
    },
    {
      "city": "Johnstown",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Johnstown, Nebraska"
    },
    {
      "city": "Long Pine",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Long Pine, Nebraska"
    },
    {
      "city": "Amherst",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Amherst, Nebraska"
    },
    {
      "city": "Elm Creek",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elm Creek, Nebraska"
    },
    {
      "city": "Gibbon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gibbon, Nebraska"
    },
    {
      "city": "Kearney",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Kearney, Nebraska"
    },
    {
      "city": "Miller",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Miller, Nebraska"
    },
    {
      "city": "Odessa",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Odessa, Nebraska"
    },
    {
      "city": "Pleasanton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pleasanton, Nebraska"
    },
    {
      "city": "Ravenna",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ravenna, Nebraska"
    },
    {
      "city": "Riverdale",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Riverdale, Nebraska"
    },
    {
      "city": "Shelton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Shelton, Nebraska"
    },
    {
      "city": "Craig",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Craig, Nebraska"
    },
    {
      "city": "Decatur",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Decatur, Nebraska"
    },
    {
      "city": "Lyons",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lyons, Nebraska"
    },
    {
      "city": "Oakland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oakland, Nebraska"
    },
    {
      "city": "Tekamah",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Tekamah, Nebraska"
    },
    {
      "city": "Abie",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Abie, Nebraska"
    },
    {
      "city": "Bruno",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bruno, Nebraska"
    },
    {
      "city": "Linwood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Linwood, Nebraska"
    },
    {
      "city": "Bellwood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bellwood, Nebraska"
    },
    {
      "city": "Brainard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brainard, Nebraska"
    },
    {
      "city": "David City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "David City, Nebraska"
    },
    {
      "city": "Dwight",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dwight, Nebraska"
    },
    {
      "city": "Rising City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rising City, Nebraska"
    },
    {
      "city": "Surprise",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Surprise, Nebraska"
    },
    {
      "city": "Ulysses",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ulysses, Nebraska"
    },
    {
      "city": "Cedar Creek",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cedar Creek, Nebraska"
    },
    {
      "city": "Louisville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Louisville, Nebraska"
    },
    {
      "city": "Plattsmouth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Plattsmouth, Nebraska"
    },
    {
      "city": "South Bend",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "South Bend, Nebraska"
    },
    {
      "city": "Alvo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Alvo, Nebraska"
    },
    {
      "city": "Avoca",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Avoca, Nebraska"
    },
    {
      "city": "Eagle",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Eagle, Nebraska"
    },
    {
      "city": "Elmwood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elmwood, Nebraska"
    },
    {
      "city": "Greenwood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Greenwood, Nebraska"
    },
    {
      "city": "Manley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Manley, Nebraska"
    },
    {
      "city": "Murdock",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Murdock, Nebraska"
    },
    {
      "city": "Murray",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Murray, Nebraska"
    },
    {
      "city": "Nehawka",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nehawka, Nebraska"
    },
    {
      "city": "Union",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Union, Nebraska"
    },
    {
      "city": "Weeping Water",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Weeping Water, Nebraska"
    },
    {
      "city": "Belden",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Belden, Nebraska"
    },
    {
      "city": "Coleridge",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Coleridge, Nebraska"
    },
    {
      "city": "Fordyce",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fordyce, Nebraska"
    },
    {
      "city": "Hartington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hartington, Nebraska"
    },
    {
      "city": "Laurel",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Laurel, Nebraska"
    },
    {
      "city": "Magnet",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Magnet, Nebraska"
    },
    {
      "city": "Randolph",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Randolph, Nebraska"
    },
    {
      "city": "Saint Helena",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Saint Helena, Nebraska"
    },
    {
      "city": "Wynot",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wynot, Nebraska"
    },
    {
      "city": "Champion",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Champion, Nebraska"
    },
    {
      "city": "Enders",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Enders, Nebraska"
    },
    {
      "city": "Imperial",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Imperial, Nebraska"
    },
    {
      "city": "Wauneta",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wauneta, Nebraska"
    },
    {
      "city": "Elsmere",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elsmere, Nebraska"
    },
    {
      "city": "Valentine",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Valentine, Nebraska"
    },
    {
      "city": "Cody",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cody, Nebraska"
    },
    {
      "city": "Crookston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Crookston, Nebraska"
    },
    {
      "city": "Kilgore",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Kilgore, Nebraska"
    },
    {
      "city": "Merriman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Merriman, Nebraska"
    },
    {
      "city": "Nenzel",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nenzel, Nebraska"
    },
    {
      "city": "Sparks",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sparks, Nebraska"
    },
    {
      "city": "Wood Lake",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wood Lake, Nebraska"
    },
    {
      "city": "Dalton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dalton, Nebraska"
    },
    {
      "city": "Gurley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gurley, Nebraska"
    },
    {
      "city": "Lodgepole",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lodgepole, Nebraska"
    },
    {
      "city": "Potter",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Potter, Nebraska"
    },
    {
      "city": "Sidney",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sidney, Nebraska"
    },
    {
      "city": "Ong",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ong, Nebraska"
    },
    {
      "city": "Clay Center",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Clay Center, Nebraska"
    },
    {
      "city": "Deweese",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Deweese, Nebraska"
    },
    {
      "city": "Edgar",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Edgar, Nebraska"
    },
    {
      "city": "Fairfield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fairfield, Nebraska"
    },
    {
      "city": "Glenvil",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Glenvil, Nebraska"
    },
    {
      "city": "Harvard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Harvard, Nebraska"
    },
    {
      "city": "Inland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Inland, Nebraska"
    },
    {
      "city": "Saronville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Saronville, Nebraska"
    },
    {
      "city": "Sutton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sutton, Nebraska"
    },
    {
      "city": "Trumbull",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Trumbull, Nebraska"
    },
    {
      "city": "Clarkson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Clarkson, Nebraska"
    },
    {
      "city": "Howells",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Howells, Nebraska"
    },
    {
      "city": "Leigh",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Leigh, Nebraska"
    },
    {
      "city": "Rogers",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rogers, Nebraska"
    },
    {
      "city": "Schuyler",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Schuyler, Nebraska"
    },
    {
      "city": "Bancroft",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bancroft, Nebraska"
    },
    {
      "city": "Beemer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Beemer, Nebraska"
    },
    {
      "city": "West Point",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "West Point, Nebraska"
    },
    {
      "city": "Wisner",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wisner, Nebraska"
    },
    {
      "city": "Anselmo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Anselmo, Nebraska"
    },
    {
      "city": "Ansley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ansley, Nebraska"
    },
    {
      "city": "Broken Bow",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Broken Bow, Nebraska"
    },
    {
      "city": "Callaway",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Callaway, Nebraska"
    },
    {
      "city": "Comstock",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Comstock, Nebraska"
    },
    {
      "city": "Mason City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mason City, Nebraska"
    },
    {
      "city": "Merna",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Merna, Nebraska"
    },
    {
      "city": "Oconto",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oconto, Nebraska"
    },
    {
      "city": "Sargent",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sargent, Nebraska"
    },
    {
      "city": "Westerville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Westerville, Nebraska"
    },
    {
      "city": "Arnold",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Arnold, Nebraska"
    },
    {
      "city": "Homer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Homer, Nebraska"
    },
    {
      "city": "Dakota City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dakota City, Nebraska"
    },
    {
      "city": "Emerson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Emerson, Nebraska"
    },
    {
      "city": "Hubbard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hubbard, Nebraska"
    },
    {
      "city": "Jackson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Jackson, Nebraska"
    },
    {
      "city": "South Sioux City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "South Sioux City, Nebraska"
    },
    {
      "city": "Chadron",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Chadron, Nebraska"
    },
    {
      "city": "Crawford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Crawford, Nebraska"
    },
    {
      "city": "Marsland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Marsland, Nebraska"
    },
    {
      "city": "Whitney",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Whitney, Nebraska"
    },
    {
      "city": "Eddyville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Eddyville, Nebraska"
    },
    {
      "city": "Lexington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lexington, Nebraska"
    },
    {
      "city": "Overton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Overton, Nebraska"
    },
    {
      "city": "Sumner",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sumner, Nebraska"
    },
    {
      "city": "Farnam",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Farnam, Nebraska"
    },
    {
      "city": "Cozad",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cozad, Nebraska"
    },
    {
      "city": "Gothenburg",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gothenburg, Nebraska"
    },
    {
      "city": "Willow Island",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Willow Island, Nebraska"
    },
    {
      "city": "Big Springs",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Big Springs, Nebraska"
    },
    {
      "city": "Chappell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Chappell, Nebraska"
    },
    {
      "city": "Allen",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Allen, Nebraska"
    },
    {
      "city": "Concord",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Concord, Nebraska"
    },
    {
      "city": "Dixon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dixon, Nebraska"
    },
    {
      "city": "Maskell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Maskell, Nebraska"
    },
    {
      "city": "Newcastle",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Newcastle, Nebraska"
    },
    {
      "city": "Ponca",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ponca, Nebraska"
    },
    {
      "city": "Wakefield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wakefield, Nebraska"
    },
    {
      "city": "Waterbury",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Waterbury, Nebraska"
    },
    {
      "city": "Fremont",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fremont, Nebraska"
    },
    {
      "city": "Hooper",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hooper, Nebraska"
    },
    {
      "city": "Nickerson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nickerson, Nebraska"
    },
    {
      "city": "Scribner",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Scribner, Nebraska"
    },
    {
      "city": "Uehling",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Uehling, Nebraska"
    },
    {
      "city": "Winslow",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Winslow, Nebraska"
    },
    {
      "city": "Ames",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ames, Nebraska"
    },
    {
      "city": "Dodge",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dodge, Nebraska"
    },
    {
      "city": "North Bend",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "North Bend, Nebraska"
    },
    {
      "city": "Snyder",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Snyder, Nebraska"
    },
    {
      "city": "Bennington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bennington, Nebraska"
    },
    {
      "city": "Boys Town",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Boys Town, Nebraska"
    },
    {
      "city": "Elkhorn",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elkhorn, Nebraska"
    },
    {
      "city": "Valley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Valley, Nebraska"
    },
    {
      "city": "Waterloo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Waterloo, Nebraska"
    },
    {
      "city": "Omaha",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Omaha, Nebraska"
    },
    {
      "city": "Benkelman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Benkelman, Nebraska"
    },
    {
      "city": "Haigler",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Haigler, Nebraska"
    },
    {
      "city": "Max",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Max, Nebraska"
    },
    {
      "city": "Parks",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Parks, Nebraska"
    },
    {
      "city": "Exeter",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Exeter, Nebraska"
    },
    {
      "city": "Fairmont",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fairmont, Nebraska"
    },
    {
      "city": "Geneva",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Geneva, Nebraska"
    },
    {
      "city": "Grafton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Grafton, Nebraska"
    },
    {
      "city": "Milligan",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Milligan, Nebraska"
    },
    {
      "city": "Ohiowa",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ohiowa, Nebraska"
    },
    {
      "city": "Shickley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Shickley, Nebraska"
    },
    {
      "city": "Strang",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Strang, Nebraska"
    },
    {
      "city": "Bloomington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bloomington, Nebraska"
    },
    {
      "city": "Campbell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Campbell, Nebraska"
    },
    {
      "city": "Franklin",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Franklin, Nebraska"
    },
    {
      "city": "Hildreth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hildreth, Nebraska"
    },
    {
      "city": "Naponee",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Naponee, Nebraska"
    },
    {
      "city": "Riverton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Riverton, Nebraska"
    },
    {
      "city": "Upland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Upland, Nebraska"
    },
    {
      "city": "Curtis",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Curtis, Nebraska"
    },
    {
      "city": "Eustis",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Eustis, Nebraska"
    },
    {
      "city": "Maywood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Maywood, Nebraska"
    },
    {
      "city": "Moorefield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Moorefield, Nebraska"
    },
    {
      "city": "Stockville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stockville, Nebraska"
    },
    {
      "city": "Arapahoe",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Arapahoe, Nebraska"
    },
    {
      "city": "Beaver City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Beaver City, Nebraska"
    },
    {
      "city": "Edison",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Edison, Nebraska"
    },
    {
      "city": "Hendley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hendley, Nebraska"
    },
    {
      "city": "Holbrook",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Holbrook, Nebraska"
    },
    {
      "city": "Oxford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oxford, Nebraska"
    },
    {
      "city": "Cambridge",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cambridge, Nebraska"
    },
    {
      "city": "Wilsonville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wilsonville, Nebraska"
    },
    {
      "city": "Adams",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Adams, Nebraska"
    },
    {
      "city": "Barneston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Barneston, Nebraska"
    },
    {
      "city": "Beatrice",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Beatrice, Nebraska"
    },
    {
      "city": "Blue Springs",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Blue Springs, Nebraska"
    },
    {
      "city": "Clatonia",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Clatonia, Nebraska"
    },
    {
      "city": "Cortland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cortland, Nebraska"
    },
    {
      "city": "Filley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Filley, Nebraska"
    },
    {
      "city": "Liberty",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Liberty, Nebraska"
    },
    {
      "city": "Odell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Odell, Nebraska"
    },
    {
      "city": "Pickrell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pickrell, Nebraska"
    },
    {
      "city": "Virginia",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Virginia, Nebraska"
    },
    {
      "city": "Wymore",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wymore, Nebraska"
    },
    {
      "city": "Lewellen",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lewellen, Nebraska"
    },
    {
      "city": "Lisco",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lisco, Nebraska"
    },
    {
      "city": "Oshkosh",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oshkosh, Nebraska"
    },
    {
      "city": "Burwell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Burwell, Nebraska"
    },
    {
      "city": "Elwood",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elwood, Nebraska"
    },
    {
      "city": "Smithfield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Smithfield, Nebraska"
    },
    {
      "city": "Ashby",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ashby, Nebraska"
    },
    {
      "city": "Hyannis",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hyannis, Nebraska"
    },
    {
      "city": "Whitman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Whitman, Nebraska"
    },
    {
      "city": "Spalding",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Spalding, Nebraska"
    },
    {
      "city": "Greeley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Greeley, Nebraska"
    },
    {
      "city": "Scotia",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Scotia, Nebraska"
    },
    {
      "city": "Wolbach",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wolbach, Nebraska"
    },
    {
      "city": "Grand Island",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Grand Island, Nebraska"
    },
    {
      "city": "Alda",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Alda, Nebraska"
    },
    {
      "city": "Cairo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cairo, Nebraska"
    },
    {
      "city": "Doniphan",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Doniphan, Nebraska"
    },
    {
      "city": "Wood River",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wood River, Nebraska"
    },
    {
      "city": "Aurora",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Aurora, Nebraska"
    },
    {
      "city": "Giltner",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Giltner, Nebraska"
    },
    {
      "city": "Hampton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hampton, Nebraska"
    },
    {
      "city": "Hordville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hordville, Nebraska"
    },
    {
      "city": "Marquette",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Marquette, Nebraska"
    },
    {
      "city": "Phillips",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Phillips, Nebraska"
    },
    {
      "city": "Alma",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Alma, Nebraska"
    },
    {
      "city": "Orleans",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Orleans, Nebraska"
    },
    {
      "city": "Ragan",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ragan, Nebraska"
    },
    {
      "city": "Republican City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Republican City, Nebraska"
    },
    {
      "city": "Stamford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stamford, Nebraska"
    },
    {
      "city": "Hayes Center",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hayes Center, Nebraska"
    },
    {
      "city": "Culbertson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Culbertson, Nebraska"
    },
    {
      "city": "Palisade",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Palisade, Nebraska"
    },
    {
      "city": "Stratton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stratton, Nebraska"
    },
    {
      "city": "Trenton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Trenton, Nebraska"
    },
    {
      "city": "Amelia",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Amelia, Nebraska"
    },
    {
      "city": "Atkinson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Atkinson, Nebraska"
    },
    {
      "city": "Chambers",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Chambers, Nebraska"
    },
    {
      "city": "Emmet",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Emmet, Nebraska"
    },
    {
      "city": "Ewing",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ewing, Nebraska"
    },
    {
      "city": "Inman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Inman, Nebraska"
    },
    {
      "city": "Oneill",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oneill, Nebraska"
    },
    {
      "city": "Page",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Page, Nebraska"
    },
    {
      "city": "Stuart",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stuart, Nebraska"
    },
    {
      "city": "Mullen",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mullen, Nebraska"
    },
    {
      "city": "Boelus",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Boelus, Nebraska"
    },
    {
      "city": "Dannebrog",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dannebrog, Nebraska"
    },
    {
      "city": "Elba",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elba, Nebraska"
    },
    {
      "city": "Farwell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Farwell, Nebraska"
    },
    {
      "city": "Saint Libory",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Saint Libory, Nebraska"
    },
    {
      "city": "Saint Paul",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Saint Paul, Nebraska"
    },
    {
      "city": "Daykin",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Daykin, Nebraska"
    },
    {
      "city": "Diller",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Diller, Nebraska"
    },
    {
      "city": "Endicott",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Endicott, Nebraska"
    },
    {
      "city": "Fairbury",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fairbury, Nebraska"
    },
    {
      "city": "Jansen",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Jansen, Nebraska"
    },
    {
      "city": "Plymouth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Plymouth, Nebraska"
    },
    {
      "city": "Steele City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Steele City, Nebraska"
    },
    {
      "city": "Cook",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cook, Nebraska"
    },
    {
      "city": "Crab Orchard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Crab Orchard, Nebraska"
    },
    {
      "city": "Elk Creek",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elk Creek, Nebraska"
    },
    {
      "city": "Sterling",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sterling, Nebraska"
    },
    {
      "city": "Tecumseh",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Tecumseh, Nebraska"
    },
    {
      "city": "Axtell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Axtell, Nebraska"
    },
    {
      "city": "Heartwell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Heartwell, Nebraska"
    },
    {
      "city": "Minden",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Minden, Nebraska"
    },
    {
      "city": "Wilcox",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wilcox, Nebraska"
    },
    {
      "city": "Brule",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brule, Nebraska"
    },
    {
      "city": "Keystone",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Keystone, Nebraska"
    },
    {
      "city": "Lemoyne",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lemoyne, Nebraska"
    },
    {
      "city": "Ogallala",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ogallala, Nebraska"
    },
    {
      "city": "Paxton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Paxton, Nebraska"
    },
    {
      "city": "Mills",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mills, Nebraska"
    },
    {
      "city": "Newport",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Newport, Nebraska"
    },
    {
      "city": "Springview",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Springview, Nebraska"
    },
    {
      "city": "Bushnell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bushnell, Nebraska"
    },
    {
      "city": "Dix",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dix, Nebraska"
    },
    {
      "city": "Kimball",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Kimball, Nebraska"
    },
    {
      "city": "Bloomfield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bloomfield, Nebraska"
    },
    {
      "city": "Center",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Center, Nebraska"
    },
    {
      "city": "Creighton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Creighton, Nebraska"
    },
    {
      "city": "Crofton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Crofton, Nebraska"
    },
    {
      "city": "Niobrara",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Niobrara, Nebraska"
    },
    {
      "city": "Verdigre",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Verdigre, Nebraska"
    },
    {
      "city": "Wausa",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wausa, Nebraska"
    },
    {
      "city": "Winnetoon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Winnetoon, Nebraska"
    },
    {
      "city": "Bennet",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bennet, Nebraska"
    },
    {
      "city": "Davey",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Davey, Nebraska"
    },
    {
      "city": "Denton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Denton, Nebraska"
    },
    {
      "city": "Firth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Firth, Nebraska"
    },
    {
      "city": "Hallam",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hallam, Nebraska"
    },
    {
      "city": "Hickman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hickman, Nebraska"
    },
    {
      "city": "Malcolm",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Malcolm, Nebraska"
    },
    {
      "city": "Martell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Martell, Nebraska"
    },
    {
      "city": "Panama",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Panama, Nebraska"
    },
    {
      "city": "Raymond",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Raymond, Nebraska"
    },
    {
      "city": "Roca",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Roca, Nebraska"
    },
    {
      "city": "Sprague",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sprague, Nebraska"
    },
    {
      "city": "Walton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Walton, Nebraska"
    },
    {
      "city": "Waverly",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Waverly, Nebraska"
    },
    {
      "city": "Lincoln",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lincoln, Nebraska"
    },
    {
      "city": "North Platte",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "North Platte, Nebraska"
    },
    {
      "city": "Brady",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brady, Nebraska"
    },
    {
      "city": "Dickens",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dickens, Nebraska"
    },
    {
      "city": "Hershey",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hershey, Nebraska"
    },
    {
      "city": "Maxwell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Maxwell, Nebraska"
    },
    {
      "city": "Sutherland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Sutherland, Nebraska"
    },
    {
      "city": "Wallace",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wallace, Nebraska"
    },
    {
      "city": "Wellfleet",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wellfleet, Nebraska"
    },
    {
      "city": "Stapleton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stapleton, Nebraska"
    },
    {
      "city": "Taylor",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Taylor, Nebraska"
    },
    {
      "city": "Tryon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Tryon, Nebraska"
    },
    {
      "city": "Norfolk",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Norfolk, Nebraska"
    },
    {
      "city": "Battle Creek",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Battle Creek, Nebraska"
    },
    {
      "city": "Madison",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Madison, Nebraska"
    },
    {
      "city": "Meadow Grove",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Meadow Grove, Nebraska"
    },
    {
      "city": "Newman Grove",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Newman Grove, Nebraska"
    },
    {
      "city": "Tilden",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Tilden, Nebraska"
    },
    {
      "city": "Clarks",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Clarks, Nebraska"
    },
    {
      "city": "Silver Creek",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Silver Creek, Nebraska"
    },
    {
      "city": "Archer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Archer, Nebraska"
    },
    {
      "city": "Central City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Central City, Nebraska"
    },
    {
      "city": "Chapman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Chapman, Nebraska"
    },
    {
      "city": "Palmer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Palmer, Nebraska"
    },
    {
      "city": "Broadwater",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Broadwater, Nebraska"
    },
    {
      "city": "Angora",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Angora, Nebraska"
    },
    {
      "city": "Bayard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bayard, Nebraska"
    },
    {
      "city": "Bridgeport",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bridgeport, Nebraska"
    },
    {
      "city": "Belgrade",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Belgrade, Nebraska"
    },
    {
      "city": "Fullerton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fullerton, Nebraska"
    },
    {
      "city": "Genoa",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Genoa, Nebraska"
    },
    {
      "city": "Auburn",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Auburn, Nebraska"
    },
    {
      "city": "Brock",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brock, Nebraska"
    },
    {
      "city": "Brownville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Brownville, Nebraska"
    },
    {
      "city": "Johnson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Johnson, Nebraska"
    },
    {
      "city": "Julian",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Julian, Nebraska"
    },
    {
      "city": "Nemaha",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nemaha, Nebraska"
    },
    {
      "city": "Peru",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Peru, Nebraska"
    },
    {
      "city": "Hardy",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hardy, Nebraska"
    },
    {
      "city": "Lawrence",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lawrence, Nebraska"
    },
    {
      "city": "Nelson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nelson, Nebraska"
    },
    {
      "city": "Oak",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Oak, Nebraska"
    },
    {
      "city": "Ruskin",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ruskin, Nebraska"
    },
    {
      "city": "Superior",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Superior, Nebraska"
    },
    {
      "city": "Burr",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Burr, Nebraska"
    },
    {
      "city": "Douglas",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Douglas, Nebraska"
    },
    {
      "city": "Dunbar",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dunbar, Nebraska"
    },
    {
      "city": "Lorton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lorton, Nebraska"
    },
    {
      "city": "Nebraska City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Nebraska City, Nebraska"
    },
    {
      "city": "Otoe",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Otoe, Nebraska"
    },
    {
      "city": "Palmyra",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Palmyra, Nebraska"
    },
    {
      "city": "Syracuse",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Syracuse, Nebraska"
    },
    {
      "city": "Talmage",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Talmage, Nebraska"
    },
    {
      "city": "Unadilla",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Unadilla, Nebraska"
    },
    {
      "city": "Burchard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Burchard, Nebraska"
    },
    {
      "city": "Du Bois",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Du Bois, Nebraska"
    },
    {
      "city": "Lewiston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lewiston, Nebraska"
    },
    {
      "city": "Pawnee City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pawnee City, Nebraska"
    },
    {
      "city": "Steinauer",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Steinauer, Nebraska"
    },
    {
      "city": "Table Rock",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Table Rock, Nebraska"
    },
    {
      "city": "Elsie",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elsie, Nebraska"
    },
    {
      "city": "Grant",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Grant, Nebraska"
    },
    {
      "city": "Madrid",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Madrid, Nebraska"
    },
    {
      "city": "Venango",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Venango, Nebraska"
    },
    {
      "city": "Atlanta",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Atlanta, Nebraska"
    },
    {
      "city": "Bertrand",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bertrand, Nebraska"
    },
    {
      "city": "Funk",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Funk, Nebraska"
    },
    {
      "city": "Holdrege",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Holdrege, Nebraska"
    },
    {
      "city": "Loomis",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Loomis, Nebraska"
    },
    {
      "city": "Hadar",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hadar, Nebraska"
    },
    {
      "city": "Mclean",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mclean, Nebraska"
    },
    {
      "city": "Osmond",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Osmond, Nebraska"
    },
    {
      "city": "Pierce",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pierce, Nebraska"
    },
    {
      "city": "Plainview",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Plainview, Nebraska"
    },
    {
      "city": "Columbus",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Columbus, Nebraska"
    },
    {
      "city": "Creston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Creston, Nebraska"
    },
    {
      "city": "Duncan",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Duncan, Nebraska"
    },
    {
      "city": "Humphrey",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Humphrey, Nebraska"
    },
    {
      "city": "Lindsay",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lindsay, Nebraska"
    },
    {
      "city": "Monroe",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Monroe, Nebraska"
    },
    {
      "city": "Platte Center",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Platte Center, Nebraska"
    },
    {
      "city": "Osceola",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Osceola, Nebraska"
    },
    {
      "city": "Polk",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Polk, Nebraska"
    },
    {
      "city": "Shelby",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Shelby, Nebraska"
    },
    {
      "city": "Stromsburg",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stromsburg, Nebraska"
    },
    {
      "city": "Mc Cook",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mc Cook, Nebraska"
    },
    {
      "city": "Bartley",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bartley, Nebraska"
    },
    {
      "city": "Danbury",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Danbury, Nebraska"
    },
    {
      "city": "Indianola",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Indianola, Nebraska"
    },
    {
      "city": "Lebanon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lebanon, Nebraska"
    },
    {
      "city": "Dawson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dawson, Nebraska"
    },
    {
      "city": "Falls City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Falls City, Nebraska"
    },
    {
      "city": "Humboldt",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Humboldt, Nebraska"
    },
    {
      "city": "Rulo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rulo, Nebraska"
    },
    {
      "city": "Salem",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Salem, Nebraska"
    },
    {
      "city": "Shubert",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Shubert, Nebraska"
    },
    {
      "city": "Stella",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stella, Nebraska"
    },
    {
      "city": "Verdon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Verdon, Nebraska"
    },
    {
      "city": "Bassett",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bassett, Nebraska"
    },
    {
      "city": "Crete",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Crete, Nebraska"
    },
    {
      "city": "De Witt",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "De Witt, Nebraska"
    },
    {
      "city": "Dorchester",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Dorchester, Nebraska"
    },
    {
      "city": "Friend",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Friend, Nebraska"
    },
    {
      "city": "Swanton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Swanton, Nebraska"
    },
    {
      "city": "Tobias",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Tobias, Nebraska"
    },
    {
      "city": "Western",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Western, Nebraska"
    },
    {
      "city": "Wilber",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wilber, Nebraska"
    },
    {
      "city": "Bellevue",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bellevue, Nebraska"
    },
    {
      "city": "Gretna",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gretna, Nebraska"
    },
    {
      "city": "Papillion",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Papillion, Nebraska"
    },
    {
      "city": "St Columbans",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "St Columbans, Nebraska"
    },
    {
      "city": "Springfield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Springfield, Nebraska"
    },
    {
      "city": "Offutt Afb",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Offutt Afb, Nebraska"
    },
    {
      "city": "La Vista",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "La Vista, Nebraska"
    },
    {
      "city": "Ashland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ashland, Nebraska"
    },
    {
      "city": "Cedar Bluffs",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cedar Bluffs, Nebraska"
    },
    {
      "city": "Ceresco",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ceresco, Nebraska"
    },
    {
      "city": "Colon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Colon, Nebraska"
    },
    {
      "city": "Ithaca",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ithaca, Nebraska"
    },
    {
      "city": "Malmo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Malmo, Nebraska"
    },
    {
      "city": "Mead",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mead, Nebraska"
    },
    {
      "city": "Memphis",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Memphis, Nebraska"
    },
    {
      "city": "Prague",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Prague, Nebraska"
    },
    {
      "city": "Valparaiso",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Valparaiso, Nebraska"
    },
    {
      "city": "Wahoo",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wahoo, Nebraska"
    },
    {
      "city": "Weston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Weston, Nebraska"
    },
    {
      "city": "Yutan",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Yutan, Nebraska"
    },
    {
      "city": "Morse Bluff",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Morse Bluff, Nebraska"
    },
    {
      "city": "Gering",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gering, Nebraska"
    },
    {
      "city": "Lyman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lyman, Nebraska"
    },
    {
      "city": "Mcgrew",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mcgrew, Nebraska"
    },
    {
      "city": "Melbeta",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Melbeta, Nebraska"
    },
    {
      "city": "Minatare",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Minatare, Nebraska"
    },
    {
      "city": "Mitchell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mitchell, Nebraska"
    },
    {
      "city": "Morrill",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Morrill, Nebraska"
    },
    {
      "city": "Scottsbluff",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Scottsbluff, Nebraska"
    },
    {
      "city": "Beaver Crossing",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Beaver Crossing, Nebraska"
    },
    {
      "city": "Bee",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bee, Nebraska"
    },
    {
      "city": "Cordova",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Cordova, Nebraska"
    },
    {
      "city": "Garland",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Garland, Nebraska"
    },
    {
      "city": "Goehner",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Goehner, Nebraska"
    },
    {
      "city": "Milford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Milford, Nebraska"
    },
    {
      "city": "Pleasant Dale",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pleasant Dale, Nebraska"
    },
    {
      "city": "Seward",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Seward, Nebraska"
    },
    {
      "city": "Staplehurst",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Staplehurst, Nebraska"
    },
    {
      "city": "Utica",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Utica, Nebraska"
    },
    {
      "city": "Bingham",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bingham, Nebraska"
    },
    {
      "city": "Ellsworth",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ellsworth, Nebraska"
    },
    {
      "city": "Gordon",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gordon, Nebraska"
    },
    {
      "city": "Hay Springs",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hay Springs, Nebraska"
    },
    {
      "city": "Lakeside",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Lakeside, Nebraska"
    },
    {
      "city": "Rushville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rushville, Nebraska"
    },
    {
      "city": "Whiteclay",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Whiteclay, Nebraska"
    },
    {
      "city": "Ashton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ashton, Nebraska"
    },
    {
      "city": "Hazard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hazard, Nebraska"
    },
    {
      "city": "Litchfield",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Litchfield, Nebraska"
    },
    {
      "city": "Loup City",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Loup City, Nebraska"
    },
    {
      "city": "Rockville",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rockville, Nebraska"
    },
    {
      "city": "Harrison",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Harrison, Nebraska"
    },
    {
      "city": "Pilger",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pilger, Nebraska"
    },
    {
      "city": "Stanton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Stanton, Nebraska"
    },
    {
      "city": "Alexandria",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Alexandria, Nebraska"
    },
    {
      "city": "Belvidere",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Belvidere, Nebraska"
    },
    {
      "city": "Bruning",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bruning, Nebraska"
    },
    {
      "city": "Byron",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Byron, Nebraska"
    },
    {
      "city": "Carleton",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Carleton, Nebraska"
    },
    {
      "city": "Chester",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Chester, Nebraska"
    },
    {
      "city": "Davenport",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Davenport, Nebraska"
    },
    {
      "city": "Deshler",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Deshler, Nebraska"
    },
    {
      "city": "Gilead",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gilead, Nebraska"
    },
    {
      "city": "Hebron",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hebron, Nebraska"
    },
    {
      "city": "Hubbell",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hubbell, Nebraska"
    },
    {
      "city": "Reynolds",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Reynolds, Nebraska"
    },
    {
      "city": "Halsey",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Halsey, Nebraska"
    },
    {
      "city": "Seneca",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Seneca, Nebraska"
    },
    {
      "city": "Thedford",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Thedford, Nebraska"
    },
    {
      "city": "Macy",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Macy, Nebraska"
    },
    {
      "city": "Pender",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Pender, Nebraska"
    },
    {
      "city": "Rosalie",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Rosalie, Nebraska"
    },
    {
      "city": "Thurston",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Thurston, Nebraska"
    },
    {
      "city": "Walthill",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Walthill, Nebraska"
    },
    {
      "city": "Winnebago",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Winnebago, Nebraska"
    },
    {
      "city": "Arcadia",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Arcadia, Nebraska"
    },
    {
      "city": "Elyria",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Elyria, Nebraska"
    },
    {
      "city": "North Loup",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "North Loup, Nebraska"
    },
    {
      "city": "Ord",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ord, Nebraska"
    },
    {
      "city": "Arlington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Arlington, Nebraska"
    },
    {
      "city": "Blair",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Blair, Nebraska"
    },
    {
      "city": "Fort Calhoun",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Fort Calhoun, Nebraska"
    },
    {
      "city": "Herman",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Herman, Nebraska"
    },
    {
      "city": "Kennard",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Kennard, Nebraska"
    },
    {
      "city": "Washington",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Washington, Nebraska"
    },
    {
      "city": "Carroll",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Carroll, Nebraska"
    },
    {
      "city": "Hoskins",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Hoskins, Nebraska"
    },
    {
      "city": "Wayne",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Wayne, Nebraska"
    },
    {
      "city": "Winside",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Winside, Nebraska"
    },
    {
      "city": "Bladen",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bladen, Nebraska"
    },
    {
      "city": "Blue Hill",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Blue Hill, Nebraska"
    },
    {
      "city": "Guide Rock",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Guide Rock, Nebraska"
    },
    {
      "city": "Inavale",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Inavale, Nebraska"
    },
    {
      "city": "Red Cloud",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Red Cloud, Nebraska"
    },
    {
      "city": "Bartlett",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bartlett, Nebraska"
    },
    {
      "city": "Ericson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Ericson, Nebraska"
    },
    {
      "city": "Benedict",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Benedict, Nebraska"
    },
    {
      "city": "Bradshaw",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Bradshaw, Nebraska"
    },
    {
      "city": "Gresham",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Gresham, Nebraska"
    },
    {
      "city": "Henderson",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Henderson, Nebraska"
    },
    {
      "city": "Mc Cool Junction",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Mc Cool Junction, Nebraska"
    },
    {
      "city": "Waco",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "Waco, Nebraska"
    },
    {
      "city": "York",
      "state": "Nebraska",
      "shortState": "NE",
      "formatedCity": "York, Nebraska"
    },
    {
      "city": "Barnstead",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Barnstead, New Hampshire"
    },
    {
      "city": "Belmont",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Belmont, New Hampshire"
    },
    {
      "city": "Center Barnstead",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Barnstead, New Hampshire"
    },
    {
      "city": "Center Harbor",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Harbor, New Hampshire"
    },
    {
      "city": "Gilmanton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Gilmanton, New Hampshire"
    },
    {
      "city": "Laconia",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Laconia, New Hampshire"
    },
    {
      "city": "Gilford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Gilford, New Hampshire"
    },
    {
      "city": "Lochmere",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lochmere, New Hampshire"
    },
    {
      "city": "Meredith",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Meredith, New Hampshire"
    },
    {
      "city": "New Hampton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New Hampton, New Hampshire"
    },
    {
      "city": "Sanbornton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sanbornton, New Hampshire"
    },
    {
      "city": "Tilton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Tilton, New Hampshire"
    },
    {
      "city": "Winnisquam",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Winnisquam, New Hampshire"
    },
    {
      "city": "Alton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Alton, New Hampshire"
    },
    {
      "city": "Alton Bay",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Alton Bay, New Hampshire"
    },
    {
      "city": "Gilmanton Iron Works",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Gilmanton Iron Works, New Hampshire"
    },
    {
      "city": "Center Sandwich",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Sandwich, New Hampshire"
    },
    {
      "city": "Moultonborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Moultonborough, New Hampshire"
    },
    {
      "city": "North Sandwich",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Sandwich, New Hampshire"
    },
    {
      "city": "Bartlett",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bartlett, New Hampshire"
    },
    {
      "city": "Center Conway",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Conway, New Hampshire"
    },
    {
      "city": "Center Ossipee",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Ossipee, New Hampshire"
    },
    {
      "city": "Center Tuftonboro",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Tuftonboro, New Hampshire"
    },
    {
      "city": "Chocorua",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Chocorua, New Hampshire"
    },
    {
      "city": "Conway",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Conway, New Hampshire"
    },
    {
      "city": "East Wakefield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Wakefield, New Hampshire"
    },
    {
      "city": "Eaton Center",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Eaton Center, New Hampshire"
    },
    {
      "city": "Freedom",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Freedom, New Hampshire"
    },
    {
      "city": "Glen",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Glen, New Hampshire"
    },
    {
      "city": "Intervale",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Intervale, New Hampshire"
    },
    {
      "city": "Jackson",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Jackson, New Hampshire"
    },
    {
      "city": "Kearsarge",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Kearsarge, New Hampshire"
    },
    {
      "city": "Madison",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Madison, New Hampshire"
    },
    {
      "city": "Melvin Village",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Melvin Village, New Hampshire"
    },
    {
      "city": "Mirror Lake",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Mirror Lake, New Hampshire"
    },
    {
      "city": "North Conway",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Conway, New Hampshire"
    },
    {
      "city": "Lee",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lee, New Hampshire"
    },
    {
      "city": "Ossipee",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Ossipee, New Hampshire"
    },
    {
      "city": "Sanbornville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sanbornville, New Hampshire"
    },
    {
      "city": "Silver Lake",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Silver Lake, New Hampshire"
    },
    {
      "city": "Effingham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Effingham, New Hampshire"
    },
    {
      "city": "South Tamworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "South Tamworth, New Hampshire"
    },
    {
      "city": "Tamworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Tamworth, New Hampshire"
    },
    {
      "city": "West Ossipee",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Ossipee, New Hampshire"
    },
    {
      "city": "Wolfeboro",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wolfeboro, New Hampshire"
    },
    {
      "city": "Wolfeboro Falls",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wolfeboro Falls, New Hampshire"
    },
    {
      "city": "Wonalancet",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wonalancet, New Hampshire"
    },
    {
      "city": "Keene",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Keene, New Hampshire"
    },
    {
      "city": "Ashuelot",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Ashuelot, New Hampshire"
    },
    {
      "city": "Chesterfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Chesterfield, New Hampshire"
    },
    {
      "city": "Dublin",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Dublin, New Hampshire"
    },
    {
      "city": "Sullivan",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sullivan, New Hampshire"
    },
    {
      "city": "Swanzey",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Swanzey, New Hampshire"
    },
    {
      "city": "Fitzwilliam",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Fitzwilliam, New Hampshire"
    },
    {
      "city": "Gilsum",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Gilsum, New Hampshire"
    },
    {
      "city": "Harrisville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Harrisville, New Hampshire"
    },
    {
      "city": "Hinsdale",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hinsdale, New Hampshire"
    },
    {
      "city": "Jaffrey",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Jaffrey, New Hampshire"
    },
    {
      "city": "Marlborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Marlborough, New Hampshire"
    },
    {
      "city": "Marlow",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Marlow, New Hampshire"
    },
    {
      "city": "Nelson",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Nelson, New Hampshire"
    },
    {
      "city": "Rindge",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rindge, New Hampshire"
    },
    {
      "city": "Spofford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Spofford, New Hampshire"
    },
    {
      "city": "Stoddard",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Stoddard, New Hampshire"
    },
    {
      "city": "Troy",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Troy, New Hampshire"
    },
    {
      "city": "West Chesterfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Chesterfield, New Hampshire"
    },
    {
      "city": "Westmoreland",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Westmoreland, New Hampshire"
    },
    {
      "city": "West Swanzey",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Swanzey, New Hampshire"
    },
    {
      "city": "Winchester",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Winchester, New Hampshire"
    },
    {
      "city": "Alstead",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Alstead, New Hampshire"
    },
    {
      "city": "Drewsville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Drewsville, New Hampshire"
    },
    {
      "city": "Walpole",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Walpole, New Hampshire"
    },
    {
      "city": "North Walpole",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Walpole, New Hampshire"
    },
    {
      "city": "Berlin",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Berlin, New Hampshire"
    },
    {
      "city": "Bretton Woods",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bretton Woods, New Hampshire"
    },
    {
      "city": "Colebrook",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Colebrook, New Hampshire"
    },
    {
      "city": "Errol",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Errol, New Hampshire"
    },
    {
      "city": "Gorham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Gorham, New Hampshire"
    },
    {
      "city": "Groveton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Groveton, New Hampshire"
    },
    {
      "city": "Jefferson",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Jefferson, New Hampshire"
    },
    {
      "city": "Lancaster",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lancaster, New Hampshire"
    },
    {
      "city": "Milan",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Milan, New Hampshire"
    },
    {
      "city": "Mount Washington",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Mount Washington, New Hampshire"
    },
    {
      "city": "North Stratford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Stratford, New Hampshire"
    },
    {
      "city": "Pittsburg",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Pittsburg, New Hampshire"
    },
    {
      "city": "Randolph",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Randolph, New Hampshire"
    },
    {
      "city": "Twin Mountain",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Twin Mountain, New Hampshire"
    },
    {
      "city": "West Stewartstown",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Stewartstown, New Hampshire"
    },
    {
      "city": "Whitefield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Whitefield, New Hampshire"
    },
    {
      "city": "Waterville Valley",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Waterville Valley, New Hampshire"
    },
    {
      "city": "Ashland",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Ashland, New Hampshire"
    },
    {
      "city": "Bristol",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bristol, New Hampshire"
    },
    {
      "city": "Campton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Campton, New Hampshire"
    },
    {
      "city": "Glencliff",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Glencliff, New Hampshire"
    },
    {
      "city": "Grafton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Grafton, New Hampshire"
    },
    {
      "city": "Hebron",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hebron, New Hampshire"
    },
    {
      "city": "Holderness",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Holderness, New Hampshire"
    },
    {
      "city": "Lincoln",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lincoln, New Hampshire"
    },
    {
      "city": "Chichester",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Chichester, New Hampshire"
    },
    {
      "city": "North Woodstock",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Woodstock, New Hampshire"
    },
    {
      "city": "Plymouth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Plymouth, New Hampshire"
    },
    {
      "city": "Rumney",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rumney, New Hampshire"
    },
    {
      "city": "Warren",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Warren, New Hampshire"
    },
    {
      "city": "Wentworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wentworth, New Hampshire"
    },
    {
      "city": "Thornton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Thornton, New Hampshire"
    },
    {
      "city": "Woodstock",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Woodstock, New Hampshire"
    },
    {
      "city": "Littleton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Littleton, New Hampshire"
    },
    {
      "city": "Bethlehem",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bethlehem, New Hampshire"
    },
    {
      "city": "Franconia",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Franconia, New Hampshire"
    },
    {
      "city": "Lisbon",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lisbon, New Hampshire"
    },
    {
      "city": "Sugar Hill",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sugar Hill, New Hampshire"
    },
    {
      "city": "Bath",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bath, New Hampshire"
    },
    {
      "city": "Canaan",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Canaan, New Hampshire"
    },
    {
      "city": "Enfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Enfield, New Hampshire"
    },
    {
      "city": "Enfield Center",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Enfield Center, New Hampshire"
    },
    {
      "city": "Etna",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Etna, New Hampshire"
    },
    {
      "city": "Hanover",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hanover, New Hampshire"
    },
    {
      "city": "Lebanon",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lebanon, New Hampshire"
    },
    {
      "city": "Haverhill",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Haverhill, New Hampshire"
    },
    {
      "city": "Lyme",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lyme, New Hampshire"
    },
    {
      "city": "Lyme Center",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lyme Center, New Hampshire"
    },
    {
      "city": "Monroe",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Monroe, New Hampshire"
    },
    {
      "city": "North Haverhill",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Haverhill, New Hampshire"
    },
    {
      "city": "Orford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Orford, New Hampshire"
    },
    {
      "city": "Piermont",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Piermont, New Hampshire"
    },
    {
      "city": "Pike",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Pike, New Hampshire"
    },
    {
      "city": "West Lebanon",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Lebanon, New Hampshire"
    },
    {
      "city": "Woodsville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Woodsville, New Hampshire"
    },
    {
      "city": "Amherst",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Amherst, New Hampshire"
    },
    {
      "city": "Brookline",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Brookline, New Hampshire"
    },
    {
      "city": "Francestown",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Francestown, New Hampshire"
    },
    {
      "city": "Goffstown",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Goffstown, New Hampshire"
    },
    {
      "city": "Greenfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Greenfield, New Hampshire"
    },
    {
      "city": "Greenville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Greenville, New Hampshire"
    },
    {
      "city": "Hollis",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hollis, New Hampshire"
    },
    {
      "city": "Hudson",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hudson, New Hampshire"
    },
    {
      "city": "Litchfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Litchfield, New Hampshire"
    },
    {
      "city": "Merrimack",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Merrimack, New Hampshire"
    },
    {
      "city": "Milford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Milford, New Hampshire"
    },
    {
      "city": "Mont Vernon",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Mont Vernon, New Hampshire"
    },
    {
      "city": "Nashua",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Nashua, New Hampshire"
    },
    {
      "city": "New Boston",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New Boston, New Hampshire"
    },
    {
      "city": "New Ipswich",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New Ipswich, New Hampshire"
    },
    {
      "city": "Pelham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Pelham, New Hampshire"
    },
    {
      "city": "Lyndeborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lyndeborough, New Hampshire"
    },
    {
      "city": "Temple",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Temple, New Hampshire"
    },
    {
      "city": "Wilton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wilton, New Hampshire"
    },
    {
      "city": "Manchester",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Manchester, New Hampshire"
    },
    {
      "city": "Bedford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bedford, New Hampshire"
    },
    {
      "city": "Hillsborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hillsborough, New Hampshire"
    },
    {
      "city": "Weare",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Weare, New Hampshire"
    },
    {
      "city": "Antrim",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Antrim, New Hampshire"
    },
    {
      "city": "Bennington",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bennington, New Hampshire"
    },
    {
      "city": "Hancock",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hancock, New Hampshire"
    },
    {
      "city": "Peterborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Peterborough, New Hampshire"
    },
    {
      "city": "West Peterborough",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Peterborough, New Hampshire"
    },
    {
      "city": "Dunbarton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Dunbarton, New Hampshire"
    },
    {
      "city": "Hooksett",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hooksett, New Hampshire"
    },
    {
      "city": "Andover",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Andover, New Hampshire"
    },
    {
      "city": "Bradford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bradford, New Hampshire"
    },
    {
      "city": "Canterbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Canterbury, New Hampshire"
    },
    {
      "city": "Contoocook",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Contoocook, New Hampshire"
    },
    {
      "city": "Danbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Danbury, New Hampshire"
    },
    {
      "city": "East Andover",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Andover, New Hampshire"
    },
    {
      "city": "Elkins",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Elkins, New Hampshire"
    },
    {
      "city": "Epsom",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Epsom, New Hampshire"
    },
    {
      "city": "Franklin",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Franklin, New Hampshire"
    },
    {
      "city": "Henniker",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Henniker, New Hampshire"
    },
    {
      "city": "Hill",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hill, New Hampshire"
    },
    {
      "city": "Newbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newbury, New Hampshire"
    },
    {
      "city": "New London",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New London, New Hampshire"
    },
    {
      "city": "North Sutton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Sutton, New Hampshire"
    },
    {
      "city": "Pittsfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Pittsfield, New Hampshire"
    },
    {
      "city": "Salisbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Salisbury, New Hampshire"
    },
    {
      "city": "South Newbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "South Newbury, New Hampshire"
    },
    {
      "city": "South Sutton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "South Sutton, New Hampshire"
    },
    {
      "city": "Suncook",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Suncook, New Hampshire"
    },
    {
      "city": "Warner",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Warner, New Hampshire"
    },
    {
      "city": "Wilmot",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Wilmot, New Hampshire"
    },
    {
      "city": "Concord",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Concord, New Hampshire"
    },
    {
      "city": "Bow",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Bow, New Hampshire"
    },
    {
      "city": "Loudon",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Loudon, New Hampshire"
    },
    {
      "city": "Auburn",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Auburn, New Hampshire"
    },
    {
      "city": "Candia",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Candia, New Hampshire"
    },
    {
      "city": "Chester",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Chester, New Hampshire"
    },
    {
      "city": "Deerfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Deerfield, New Hampshire"
    },
    {
      "city": "Derry",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Derry, New Hampshire"
    },
    {
      "city": "East Candia",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Candia, New Hampshire"
    },
    {
      "city": "East Derry",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Derry, New Hampshire"
    },
    {
      "city": "Epping",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Epping, New Hampshire"
    },
    {
      "city": "Fremont",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Fremont, New Hampshire"
    },
    {
      "city": "Londonderry",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Londonderry, New Hampshire"
    },
    {
      "city": "North Salem",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Salem, New Hampshire"
    },
    {
      "city": "Raymond",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Raymond, New Hampshire"
    },
    {
      "city": "Salem",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Salem, New Hampshire"
    },
    {
      "city": "Windham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Windham, New Hampshire"
    },
    {
      "city": "Northwood",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Northwood, New Hampshire"
    },
    {
      "city": "Nottingham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Nottingham, New Hampshire"
    },
    {
      "city": "West Nottingham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "West Nottingham, New Hampshire"
    },
    {
      "city": "Portsmouth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Portsmouth, New Hampshire"
    },
    {
      "city": "Atkinson",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Atkinson, New Hampshire"
    },
    {
      "city": "Danville",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Danville, New Hampshire"
    },
    {
      "city": "East Hampstead",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Hampstead, New Hampshire"
    },
    {
      "city": "East Kingston",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "East Kingston, New Hampshire"
    },
    {
      "city": "Exeter",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Exeter, New Hampshire"
    },
    {
      "city": "Greenland",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Greenland, New Hampshire"
    },
    {
      "city": "Hampstead",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hampstead, New Hampshire"
    },
    {
      "city": "Hampton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hampton, New Hampshire"
    },
    {
      "city": "Hampton Falls",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Hampton Falls, New Hampshire"
    },
    {
      "city": "Kingston",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Kingston, New Hampshire"
    },
    {
      "city": "New Castle",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New Castle, New Hampshire"
    },
    {
      "city": "Newfields",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newfields, New Hampshire"
    },
    {
      "city": "Newmarket",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newmarket, New Hampshire"
    },
    {
      "city": "Newton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newton, New Hampshire"
    },
    {
      "city": "Newton Junction",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newton Junction, New Hampshire"
    },
    {
      "city": "North Hampton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "North Hampton, New Hampshire"
    },
    {
      "city": "Plaistow",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Plaistow, New Hampshire"
    },
    {
      "city": "Rye",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rye, New Hampshire"
    },
    {
      "city": "Rye Beach",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rye Beach, New Hampshire"
    },
    {
      "city": "Sandown",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sandown, New Hampshire"
    },
    {
      "city": "Seabrook",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Seabrook, New Hampshire"
    },
    {
      "city": "Stratham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Stratham, New Hampshire"
    },
    {
      "city": "Center Strafford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Center Strafford, New Hampshire"
    },
    {
      "city": "Dover",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Dover, New Hampshire"
    },
    {
      "city": "Madbury",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Madbury, New Hampshire"
    },
    {
      "city": "Durham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Durham, New Hampshire"
    },
    {
      "city": "Barrington",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Barrington, New Hampshire"
    },
    {
      "city": "Farmington",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Farmington, New Hampshire"
    },
    {
      "city": "Rochester",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rochester, New Hampshire"
    },
    {
      "city": "Milton",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Milton, New Hampshire"
    },
    {
      "city": "Milton Mills",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Milton Mills, New Hampshire"
    },
    {
      "city": "New Durham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "New Durham, New Hampshire"
    },
    {
      "city": "Rollinsford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Rollinsford, New Hampshire"
    },
    {
      "city": "Somersworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Somersworth, New Hampshire"
    },
    {
      "city": "Strafford",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Strafford, New Hampshire"
    },
    {
      "city": "Union",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Union, New Hampshire"
    },
    {
      "city": "Washington",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Washington, New Hampshire"
    },
    {
      "city": "Springfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Springfield, New Hampshire"
    },
    {
      "city": "Acworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Acworth, New Hampshire"
    },
    {
      "city": "Charlestown",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Charlestown, New Hampshire"
    },
    {
      "city": "Lempster",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Lempster, New Hampshire"
    },
    {
      "city": "South Acworth",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "South Acworth, New Hampshire"
    },
    {
      "city": "Claremont",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Claremont, New Hampshire"
    },
    {
      "city": "Cornish",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Cornish, New Hampshire"
    },
    {
      "city": "Cornish Flat",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Cornish Flat, New Hampshire"
    },
    {
      "city": "Georges Mills",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Georges Mills, New Hampshire"
    },
    {
      "city": "Goshen",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Goshen, New Hampshire"
    },
    {
      "city": "Grantham",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Grantham, New Hampshire"
    },
    {
      "city": "Guild",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Guild, New Hampshire"
    },
    {
      "city": "Meriden",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Meriden, New Hampshire"
    },
    {
      "city": "Newport",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Newport, New Hampshire"
    },
    {
      "city": "Plainfield",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Plainfield, New Hampshire"
    },
    {
      "city": "Sunapee",
      "state": "New Hampshire",
      "shortState": "NH",
      "formatedCity": "Sunapee, New Hampshire"
    },
    {
      "city": "Hammonton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hammonton, New Jersey"
    },
    {
      "city": "Absecon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Absecon, New Jersey"
    },
    {
      "city": "Brigantine",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Brigantine, New Jersey"
    },
    {
      "city": "Cologne",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cologne, New Jersey"
    },
    {
      "city": "Egg Harbor City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Egg Harbor City, New Jersey"
    },
    {
      "city": "Elwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Elwood, New Jersey"
    },
    {
      "city": "Leeds Point",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Leeds Point, New Jersey"
    },
    {
      "city": "Linwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Linwood, New Jersey"
    },
    {
      "city": "Northfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Northfield, New Jersey"
    },
    {
      "city": "Oceanville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oceanville, New Jersey"
    },
    {
      "city": "Pleasantville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pleasantville, New Jersey"
    },
    {
      "city": "Egg Harbor Township",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Egg Harbor Township, New Jersey"
    },
    {
      "city": "Pomona",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pomona, New Jersey"
    },
    {
      "city": "Port Republic",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Republic, New Jersey"
    },
    {
      "city": "Somers Point",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Somers Point, New Jersey"
    },
    {
      "city": "Buena",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Buena, New Jersey"
    },
    {
      "city": "Dorothy",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dorothy, New Jersey"
    },
    {
      "city": "Estell Manor",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Estell Manor, New Jersey"
    },
    {
      "city": "Landisville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Landisville, New Jersey"
    },
    {
      "city": "Mays Landing",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mays Landing, New Jersey"
    },
    {
      "city": "Milmay",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Milmay, New Jersey"
    },
    {
      "city": "Minotola",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Minotola, New Jersey"
    },
    {
      "city": "Mizpah",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mizpah, New Jersey"
    },
    {
      "city": "Newtonville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newtonville, New Jersey"
    },
    {
      "city": "Richland",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Richland, New Jersey"
    },
    {
      "city": "Atlantic City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Atlantic City, New Jersey"
    },
    {
      "city": "Margate City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Margate City, New Jersey"
    },
    {
      "city": "Longport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Longport, New Jersey"
    },
    {
      "city": "Ventnor City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ventnor City, New Jersey"
    },
    {
      "city": "Cliffside Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cliffside Park, New Jersey"
    },
    {
      "city": "Edgewater",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Edgewater, New Jersey"
    },
    {
      "city": "Fairview",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fairview, New Jersey"
    },
    {
      "city": "Fort Lee",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fort Lee, New Jersey"
    },
    {
      "city": "Garfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Garfield, New Jersey"
    },
    {
      "city": "North Arlington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "North Arlington, New Jersey"
    },
    {
      "city": "Wallington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wallington, New Jersey"
    },
    {
      "city": "Rutherford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rutherford, New Jersey"
    },
    {
      "city": "Lyndhurst",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lyndhurst, New Jersey"
    },
    {
      "city": "Carlstadt",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Carlstadt, New Jersey"
    },
    {
      "city": "East Rutherford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "East Rutherford, New Jersey"
    },
    {
      "city": "Moonachie",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Moonachie, New Jersey"
    },
    {
      "city": "Wood Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wood Ridge, New Jersey"
    },
    {
      "city": "Allendale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Allendale, New Jersey"
    },
    {
      "city": "Elmwood Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Elmwood Park, New Jersey"
    },
    {
      "city": "Fair Lawn",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fair Lawn, New Jersey"
    },
    {
      "city": "Franklin Lakes",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Franklin Lakes, New Jersey"
    },
    {
      "city": "Ho Ho Kus",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ho Ho Kus, New Jersey"
    },
    {
      "city": "Mahwah",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mahwah, New Jersey"
    },
    {
      "city": "Midland Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Midland Park, New Jersey"
    },
    {
      "city": "Oakland",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oakland, New Jersey"
    },
    {
      "city": "Ramsey",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ramsey, New Jersey"
    },
    {
      "city": "Ridgewood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ridgewood, New Jersey"
    },
    {
      "city": "Glen Rock",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glen Rock, New Jersey"
    },
    {
      "city": "Saddle River",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Saddle River, New Jersey"
    },
    {
      "city": "Waldwick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Waldwick, New Jersey"
    },
    {
      "city": "Wyckoff",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wyckoff, New Jersey"
    },
    {
      "city": "Hackensack",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hackensack, New Jersey"
    },
    {
      "city": "Bogota",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bogota, New Jersey"
    },
    {
      "city": "Hasbrouck Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hasbrouck Heights, New Jersey"
    },
    {
      "city": "Leonia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Leonia, New Jersey"
    },
    {
      "city": "South Hackensack",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Hackensack, New Jersey"
    },
    {
      "city": "Maywood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Maywood, New Jersey"
    },
    {
      "city": "Teterboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Teterboro, New Jersey"
    },
    {
      "city": "Alpine",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Alpine, New Jersey"
    },
    {
      "city": "Bergenfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bergenfield, New Jersey"
    },
    {
      "city": "Closter",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Closter, New Jersey"
    },
    {
      "city": "Cresskill",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cresskill, New Jersey"
    },
    {
      "city": "Demarest",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Demarest, New Jersey"
    },
    {
      "city": "Dumont",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dumont, New Jersey"
    },
    {
      "city": "Emerson",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Emerson, New Jersey"
    },
    {
      "city": "Englewood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Englewood, New Jersey"
    },
    {
      "city": "Englewood Cliffs",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Englewood Cliffs, New Jersey"
    },
    {
      "city": "Harrington Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Harrington Park, New Jersey"
    },
    {
      "city": "Haworth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Haworth, New Jersey"
    },
    {
      "city": "Hillsdale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hillsdale, New Jersey"
    },
    {
      "city": "Little Ferry",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Little Ferry, New Jersey"
    },
    {
      "city": "Lodi",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lodi, New Jersey"
    },
    {
      "city": "Montvale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Montvale, New Jersey"
    },
    {
      "city": "New Milford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Milford, New Jersey"
    },
    {
      "city": "Northvale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Northvale, New Jersey"
    },
    {
      "city": "Norwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Norwood, New Jersey"
    },
    {
      "city": "Oradell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oradell, New Jersey"
    },
    {
      "city": "Palisades Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Palisades Park, New Jersey"
    },
    {
      "city": "Paramus",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Paramus, New Jersey"
    },
    {
      "city": "Park Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Park Ridge, New Jersey"
    },
    {
      "city": "Ridgefield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ridgefield, New Jersey"
    },
    {
      "city": "Ridgefield Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ridgefield Park, New Jersey"
    },
    {
      "city": "River Edge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "River Edge, New Jersey"
    },
    {
      "city": "Rochelle Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rochelle Park, New Jersey"
    },
    {
      "city": "Saddle Brook",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Saddle Brook, New Jersey"
    },
    {
      "city": "Teaneck",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Teaneck, New Jersey"
    },
    {
      "city": "Tenafly",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Tenafly, New Jersey"
    },
    {
      "city": "Westwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Westwood, New Jersey"
    },
    {
      "city": "Township Of Washington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Township Of Washington, New Jersey"
    },
    {
      "city": "Woodcliff Lake",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Woodcliff Lake, New Jersey"
    },
    {
      "city": "Beverly",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Beverly, New Jersey"
    },
    {
      "city": "Birmingham",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Birmingham, New Jersey"
    },
    {
      "city": "Browns Mills",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Browns Mills, New Jersey"
    },
    {
      "city": "Burlington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Burlington, New Jersey"
    },
    {
      "city": "Chatsworth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Chatsworth, New Jersey"
    },
    {
      "city": "Columbus",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Columbus, New Jersey"
    },
    {
      "city": "Hainesport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hainesport, New Jersey"
    },
    {
      "city": "Jobstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Jobstown, New Jersey"
    },
    {
      "city": "Juliustown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Juliustown, New Jersey"
    },
    {
      "city": "Willingboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Willingboro, New Jersey"
    },
    {
      "city": "Lumberton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lumberton, New Jersey"
    },
    {
      "city": "Maple Shade",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Maple Shade, New Jersey"
    },
    {
      "city": "Marlton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Marlton, New Jersey"
    },
    {
      "city": "Mount Laurel",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Laurel, New Jersey"
    },
    {
      "city": "Medford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Medford, New Jersey"
    },
    {
      "city": "Moorestown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Moorestown, New Jersey"
    },
    {
      "city": "Mount Holly",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Holly, New Jersey"
    },
    {
      "city": "New Lisbon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Lisbon, New Jersey"
    },
    {
      "city": "Palmyra",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Palmyra, New Jersey"
    },
    {
      "city": "Pemberton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pemberton, New Jersey"
    },
    {
      "city": "Rancocas",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rancocas, New Jersey"
    },
    {
      "city": "Riverside",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Riverside, New Jersey"
    },
    {
      "city": "Riverton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Riverton, New Jersey"
    },
    {
      "city": "Vincentown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Vincentown, New Jersey"
    },
    {
      "city": "New Gretna",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Gretna, New Jersey"
    },
    {
      "city": "Bordentown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bordentown, New Jersey"
    },
    {
      "city": "Cookstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cookstown, New Jersey"
    },
    {
      "city": "Chesterfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Chesterfield, New Jersey"
    },
    {
      "city": "Florence",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Florence, New Jersey"
    },
    {
      "city": "Roebling",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Roebling, New Jersey"
    },
    {
      "city": "Wrightstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wrightstown, New Jersey"
    },
    {
      "city": "Joint Base Mdl",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Joint Base Mdl, New Jersey"
    },
    {
      "city": "Cherry Hill",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cherry Hill, New Jersey"
    },
    {
      "city": "Atco",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Atco, New Jersey"
    },
    {
      "city": "Barrington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Barrington, New Jersey"
    },
    {
      "city": "Berlin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Berlin, New Jersey"
    },
    {
      "city": "Blackwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Blackwood, New Jersey"
    },
    {
      "city": "Cedar Brook",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cedar Brook, New Jersey"
    },
    {
      "city": "Clementon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clementon, New Jersey"
    },
    {
      "city": "Gibbsboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Gibbsboro, New Jersey"
    },
    {
      "city": "Glendora",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glendora, New Jersey"
    },
    {
      "city": "Gloucester City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Gloucester City, New Jersey"
    },
    {
      "city": "Bellmawr",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bellmawr, New Jersey"
    },
    {
      "city": "Haddonfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Haddonfield, New Jersey"
    },
    {
      "city": "Haddon Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Haddon Heights, New Jersey"
    },
    {
      "city": "Voorhees",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Voorhees, New Jersey"
    },
    {
      "city": "Lawnside",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lawnside, New Jersey"
    },
    {
      "city": "Magnolia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Magnolia, New Jersey"
    },
    {
      "city": "Mount Ephraim",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Ephraim, New Jersey"
    },
    {
      "city": "Runnemede",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Runnemede, New Jersey"
    },
    {
      "city": "Sicklerville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sicklerville, New Jersey"
    },
    {
      "city": "Somerdale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Somerdale, New Jersey"
    },
    {
      "city": "Stratford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stratford, New Jersey"
    },
    {
      "city": "Waterford Works",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Waterford Works, New Jersey"
    },
    {
      "city": "West Berlin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West Berlin, New Jersey"
    },
    {
      "city": "Winslow",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Winslow, New Jersey"
    },
    {
      "city": "Camden",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Camden, New Jersey"
    },
    {
      "city": "Audubon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Audubon, New Jersey"
    },
    {
      "city": "Oaklyn",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oaklyn, New Jersey"
    },
    {
      "city": "Collingswood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Collingswood, New Jersey"
    },
    {
      "city": "Merchantville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Merchantville, New Jersey"
    },
    {
      "city": "Pennsauken",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pennsauken, New Jersey"
    },
    {
      "city": "Avalon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Avalon, New Jersey"
    },
    {
      "city": "Cape May",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cape May, New Jersey"
    },
    {
      "city": "Cape May Court House",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cape May Court House, New Jersey"
    },
    {
      "city": "Cape May Point",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cape May Point, New Jersey"
    },
    {
      "city": "Dennisville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dennisville, New Jersey"
    },
    {
      "city": "Goshen",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Goshen, New Jersey"
    },
    {
      "city": "Green Creek",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Green Creek, New Jersey"
    },
    {
      "city": "Marmora",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Marmora, New Jersey"
    },
    {
      "city": "Ocean City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ocean City, New Jersey"
    },
    {
      "city": "Ocean View",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ocean View, New Jersey"
    },
    {
      "city": "Rio Grande",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rio Grande, New Jersey"
    },
    {
      "city": "Sea Isle City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sea Isle City, New Jersey"
    },
    {
      "city": "South Dennis",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Dennis, New Jersey"
    },
    {
      "city": "South Seaville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Seaville, New Jersey"
    },
    {
      "city": "Stone Harbor",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stone Harbor, New Jersey"
    },
    {
      "city": "Strathmere",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Strathmere, New Jersey"
    },
    {
      "city": "Tuckahoe",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Tuckahoe, New Jersey"
    },
    {
      "city": "Villas",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Villas, New Jersey"
    },
    {
      "city": "Whitesboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Whitesboro, New Jersey"
    },
    {
      "city": "Wildwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wildwood, New Jersey"
    },
    {
      "city": "Woodbine",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Woodbine, New Jersey"
    },
    {
      "city": "Bridgeton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bridgeton, New Jersey"
    },
    {
      "city": "Cedarville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cedarville, New Jersey"
    },
    {
      "city": "Deerfield Street",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Deerfield Street, New Jersey"
    },
    {
      "city": "Delmont",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Delmont, New Jersey"
    },
    {
      "city": "Dividing Creek",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dividing Creek, New Jersey"
    },
    {
      "city": "Dorchester",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dorchester, New Jersey"
    },
    {
      "city": "Fairton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fairton, New Jersey"
    },
    {
      "city": "Fortescue",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fortescue, New Jersey"
    },
    {
      "city": "Greenwich",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Greenwich, New Jersey"
    },
    {
      "city": "Heislerville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Heislerville, New Jersey"
    },
    {
      "city": "Leesburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Leesburg, New Jersey"
    },
    {
      "city": "Mauricetown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mauricetown, New Jersey"
    },
    {
      "city": "Millville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Millville, New Jersey"
    },
    {
      "city": "Newport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newport, New Jersey"
    },
    {
      "city": "Port Elizabeth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Elizabeth, New Jersey"
    },
    {
      "city": "Port Norris",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Norris, New Jersey"
    },
    {
      "city": "Rosenhayn",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rosenhayn, New Jersey"
    },
    {
      "city": "Shiloh",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Shiloh, New Jersey"
    },
    {
      "city": "Vineland",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Vineland, New Jersey"
    },
    {
      "city": "Bloomfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bloomfield, New Jersey"
    },
    {
      "city": "Fairfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fairfield, New Jersey"
    },
    {
      "city": "Caldwell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Caldwell, New Jersey"
    },
    {
      "city": "Cedar Grove",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cedar Grove, New Jersey"
    },
    {
      "city": "East Orange",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "East Orange, New Jersey"
    },
    {
      "city": "Essex Fells",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Essex Fells, New Jersey"
    },
    {
      "city": "Glen Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glen Ridge, New Jersey"
    },
    {
      "city": "Livingston",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Livingston, New Jersey"
    },
    {
      "city": "Maplewood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Maplewood, New Jersey"
    },
    {
      "city": "Millburn",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Millburn, New Jersey"
    },
    {
      "city": "Montclair",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Montclair, New Jersey"
    },
    {
      "city": "Verona",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Verona, New Jersey"
    },
    {
      "city": "Orange",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Orange, New Jersey"
    },
    {
      "city": "West Orange",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West Orange, New Jersey"
    },
    {
      "city": "Roseland",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Roseland, New Jersey"
    },
    {
      "city": "Short Hills",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Short Hills, New Jersey"
    },
    {
      "city": "South Orange",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Orange, New Jersey"
    },
    {
      "city": "Newark",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newark, New Jersey"
    },
    {
      "city": "Belleville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Belleville, New Jersey"
    },
    {
      "city": "Nutley",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Nutley, New Jersey"
    },
    {
      "city": "Irvington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Irvington, New Jersey"
    },
    {
      "city": "Bridgeport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bridgeport, New Jersey"
    },
    {
      "city": "Clarksboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clarksboro, New Jersey"
    },
    {
      "city": "Ewan",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ewan, New Jersey"
    },
    {
      "city": "Gibbstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Gibbstown, New Jersey"
    },
    {
      "city": "Glassboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glassboro, New Jersey"
    },
    {
      "city": "Grenloch",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Grenloch, New Jersey"
    },
    {
      "city": "Harrisonville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Harrisonville, New Jersey"
    },
    {
      "city": "Mantua",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mantua, New Jersey"
    },
    {
      "city": "Mickleton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mickleton, New Jersey"
    },
    {
      "city": "Mount Royal",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Royal, New Jersey"
    },
    {
      "city": "Mullica Hill",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mullica Hill, New Jersey"
    },
    {
      "city": "National Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "National Park, New Jersey"
    },
    {
      "city": "Paulsboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Paulsboro, New Jersey"
    },
    {
      "city": "Pitman",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pitman, New Jersey"
    },
    {
      "city": "Richwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Richwood, New Jersey"
    },
    {
      "city": "Sewell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sewell, New Jersey"
    },
    {
      "city": "Swedesboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Swedesboro, New Jersey"
    },
    {
      "city": "Thorofare",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Thorofare, New Jersey"
    },
    {
      "city": "Wenonah",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wenonah, New Jersey"
    },
    {
      "city": "Westville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Westville, New Jersey"
    },
    {
      "city": "Williamstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Williamstown, New Jersey"
    },
    {
      "city": "Deptford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Deptford, New Jersey"
    },
    {
      "city": "Woodbury Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Woodbury Heights, New Jersey"
    },
    {
      "city": "Clayton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clayton, New Jersey"
    },
    {
      "city": "Franklinville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Franklinville, New Jersey"
    },
    {
      "city": "Malaga",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Malaga, New Jersey"
    },
    {
      "city": "Monroeville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Monroeville, New Jersey"
    },
    {
      "city": "Newfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newfield, New Jersey"
    },
    {
      "city": "Bayonne",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bayonne, New Jersey"
    },
    {
      "city": "Harrison",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Harrison, New Jersey"
    },
    {
      "city": "Hoboken",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hoboken, New Jersey"
    },
    {
      "city": "Kearny",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Kearny, New Jersey"
    },
    {
      "city": "North Bergen",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "North Bergen, New Jersey"
    },
    {
      "city": "Weehawken",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Weehawken, New Jersey"
    },
    {
      "city": "Union City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Union City, New Jersey"
    },
    {
      "city": "West New York",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West New York, New Jersey"
    },
    {
      "city": "Secaucus",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Secaucus, New Jersey"
    },
    {
      "city": "Jersey City",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Jersey City, New Jersey"
    },
    {
      "city": "Califon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Califon, New Jersey"
    },
    {
      "city": "Pottersville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pottersville, New Jersey"
    },
    {
      "city": "Lambertville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lambertville, New Jersey"
    },
    {
      "city": "Ringoes",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ringoes, New Jersey"
    },
    {
      "city": "Rosemont",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rosemont, New Jersey"
    },
    {
      "city": "Sergeantsville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sergeantsville, New Jersey"
    },
    {
      "city": "Stockton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stockton, New Jersey"
    },
    {
      "city": "Annandale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Annandale, New Jersey"
    },
    {
      "city": "Baptistown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Baptistown, New Jersey"
    },
    {
      "city": "Bloomsbury",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bloomsbury, New Jersey"
    },
    {
      "city": "Clinton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clinton, New Jersey"
    },
    {
      "city": "Flemington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Flemington, New Jersey"
    },
    {
      "city": "Frenchtown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Frenchtown, New Jersey"
    },
    {
      "city": "Glen Gardner",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glen Gardner, New Jersey"
    },
    {
      "city": "Hampton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hampton, New Jersey"
    },
    {
      "city": "High Bridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "High Bridge, New Jersey"
    },
    {
      "city": "Lebanon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lebanon, New Jersey"
    },
    {
      "city": "Little York",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Little York, New Jersey"
    },
    {
      "city": "Milford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Milford, New Jersey"
    },
    {
      "city": "Oldwick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oldwick, New Jersey"
    },
    {
      "city": "Pittstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pittstown, New Jersey"
    },
    {
      "city": "Quakertown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Quakertown, New Jersey"
    },
    {
      "city": "Readington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Readington, New Jersey"
    },
    {
      "city": "Stanton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stanton, New Jersey"
    },
    {
      "city": "Three Bridges",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Three Bridges, New Jersey"
    },
    {
      "city": "Whitehouse",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Whitehouse, New Jersey"
    },
    {
      "city": "Whitehouse Station",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Whitehouse Station, New Jersey"
    },
    {
      "city": "Hightstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hightstown, New Jersey"
    },
    {
      "city": "Hopewell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hopewell, New Jersey"
    },
    {
      "city": "Pennington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pennington, New Jersey"
    },
    {
      "city": "Princeton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Princeton, New Jersey"
    },
    {
      "city": "Princeton Junction",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Princeton Junction, New Jersey"
    },
    {
      "city": "Titusville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Titusville, New Jersey"
    },
    {
      "city": "Windsor",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Windsor, New Jersey"
    },
    {
      "city": "Trenton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Trenton, New Jersey"
    },
    {
      "city": "Lawrence Township",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lawrence Township, New Jersey"
    },
    {
      "city": "Avenel",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Avenel, New Jersey"
    },
    {
      "city": "Carteret",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Carteret, New Jersey"
    },
    {
      "city": "Port Reading",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Reading, New Jersey"
    },
    {
      "city": "Colonia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Colonia, New Jersey"
    },
    {
      "city": "Sewaren",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sewaren, New Jersey"
    },
    {
      "city": "South Plainfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Plainfield, New Jersey"
    },
    {
      "city": "Woodbridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Woodbridge, New Jersey"
    },
    {
      "city": "Cranbury",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cranbury, New Jersey"
    },
    {
      "city": "Plainsboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Plainsboro, New Jersey"
    },
    {
      "city": "Dayton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dayton, New Jersey"
    },
    {
      "city": "Dunellen",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dunellen, New Jersey"
    },
    {
      "city": "East Brunswick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "East Brunswick, New Jersey"
    },
    {
      "city": "Edison",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Edison, New Jersey"
    },
    {
      "city": "Kendall Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Kendall Park, New Jersey"
    },
    {
      "city": "Helmetta",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Helmetta, New Jersey"
    },
    {
      "city": "Iselin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Iselin, New Jersey"
    },
    {
      "city": "Monroe Township",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Monroe Township, New Jersey"
    },
    {
      "city": "Keasbey",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Keasbey, New Jersey"
    },
    {
      "city": "Metuchen",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Metuchen, New Jersey"
    },
    {
      "city": "Middlesex",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Middlesex, New Jersey"
    },
    {
      "city": "Milltown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Milltown, New Jersey"
    },
    {
      "city": "Monmouth Junction",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Monmouth Junction, New Jersey"
    },
    {
      "city": "Piscataway",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Piscataway, New Jersey"
    },
    {
      "city": "Old Bridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Old Bridge, New Jersey"
    },
    {
      "city": "Parlin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Parlin, New Jersey"
    },
    {
      "city": "Perth Amboy",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Perth Amboy, New Jersey"
    },
    {
      "city": "Fords",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fords, New Jersey"
    },
    {
      "city": "Sayreville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sayreville, New Jersey"
    },
    {
      "city": "South Amboy",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Amboy, New Jersey"
    },
    {
      "city": "South River",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South River, New Jersey"
    },
    {
      "city": "Spotswood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Spotswood, New Jersey"
    },
    {
      "city": "New Brunswick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Brunswick, New Jersey"
    },
    {
      "city": "North Brunswick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "North Brunswick, New Jersey"
    },
    {
      "city": "Highland Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Highland Park, New Jersey"
    },
    {
      "city": "Red Bank",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Red Bank, New Jersey"
    },
    {
      "city": "Shrewsbury",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Shrewsbury, New Jersey"
    },
    {
      "city": "Fort Monmouth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fort Monmouth, New Jersey"
    },
    {
      "city": "Fair Haven",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fair Haven, New Jersey"
    },
    {
      "city": "Adelphia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Adelphia, New Jersey"
    },
    {
      "city": "Allenhurst",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Allenhurst, New Jersey"
    },
    {
      "city": "Asbury Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Asbury Park, New Jersey"
    },
    {
      "city": "Belmar",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Belmar, New Jersey"
    },
    {
      "city": "Atlantic Highlands",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Atlantic Highlands, New Jersey"
    },
    {
      "city": "Avon By The Sea",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Avon By The Sea, New Jersey"
    },
    {
      "city": "Belford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Belford, New Jersey"
    },
    {
      "city": "Bradley Beach",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bradley Beach, New Jersey"
    },
    {
      "city": "Cliffwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cliffwood, New Jersey"
    },
    {
      "city": "Colts Neck",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Colts Neck, New Jersey"
    },
    {
      "city": "Deal",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Deal, New Jersey"
    },
    {
      "city": "Eatontown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Eatontown, New Jersey"
    },
    {
      "city": "Englishtown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Englishtown, New Jersey"
    },
    {
      "city": "Farmingdale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Farmingdale, New Jersey"
    },
    {
      "city": "Freehold",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Freehold, New Jersey"
    },
    {
      "city": "Hazlet",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hazlet, New Jersey"
    },
    {
      "city": "Howell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Howell, New Jersey"
    },
    {
      "city": "Highlands",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Highlands, New Jersey"
    },
    {
      "city": "Holmdel",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Holmdel, New Jersey"
    },
    {
      "city": "Keansburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Keansburg, New Jersey"
    },
    {
      "city": "Keyport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Keyport, New Jersey"
    },
    {
      "city": "Leonardo",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Leonardo, New Jersey"
    },
    {
      "city": "Lincroft",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lincroft, New Jersey"
    },
    {
      "city": "Little Silver",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Little Silver, New Jersey"
    },
    {
      "city": "Long Branch",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Long Branch, New Jersey"
    },
    {
      "city": "Marlboro",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Marlboro, New Jersey"
    },
    {
      "city": "Matawan",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Matawan, New Jersey"
    },
    {
      "city": "Middletown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Middletown, New Jersey"
    },
    {
      "city": "Monmouth Beach",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Monmouth Beach, New Jersey"
    },
    {
      "city": "Morganville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Morganville, New Jersey"
    },
    {
      "city": "Navesink",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Navesink, New Jersey"
    },
    {
      "city": "Neptune",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Neptune, New Jersey"
    },
    {
      "city": "Oakhurst",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oakhurst, New Jersey"
    },
    {
      "city": "Ocean Grove",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ocean Grove, New Jersey"
    },
    {
      "city": "Oceanport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oceanport, New Jersey"
    },
    {
      "city": "Port Monmouth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Monmouth, New Jersey"
    },
    {
      "city": "Rumson",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rumson, New Jersey"
    },
    {
      "city": "Spring Lake",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Spring Lake, New Jersey"
    },
    {
      "city": "Tennent",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Tennent, New Jersey"
    },
    {
      "city": "West Long Branch",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West Long Branch, New Jersey"
    },
    {
      "city": "Wickatunk",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wickatunk, New Jersey"
    },
    {
      "city": "Allentown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Allentown, New Jersey"
    },
    {
      "city": "Millstone Township",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Millstone Township, New Jersey"
    },
    {
      "city": "Cream Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cream Ridge, New Jersey"
    },
    {
      "city": "Imlaystown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Imlaystown, New Jersey"
    },
    {
      "city": "Roosevelt",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Roosevelt, New Jersey"
    },
    {
      "city": "Allenwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Allenwood, New Jersey"
    },
    {
      "city": "Brielle",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Brielle, New Jersey"
    },
    {
      "city": "Manasquan",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Manasquan, New Jersey"
    },
    {
      "city": "Sea Girt",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sea Girt, New Jersey"
    },
    {
      "city": "Boonton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Boonton, New Jersey"
    },
    {
      "city": "Lake Hiawatha",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lake Hiawatha, New Jersey"
    },
    {
      "city": "Lincoln Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lincoln Park, New Jersey"
    },
    {
      "city": "Montville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Montville, New Jersey"
    },
    {
      "city": "Mountain Lakes",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mountain Lakes, New Jersey"
    },
    {
      "city": "Parsippany",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Parsippany, New Jersey"
    },
    {
      "city": "Pine Brook",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pine Brook, New Jersey"
    },
    {
      "city": "Towaco",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Towaco, New Jersey"
    },
    {
      "city": "Butler",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Butler, New Jersey"
    },
    {
      "city": "Pequannock",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pequannock, New Jersey"
    },
    {
      "city": "Pompton Plains",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pompton Plains, New Jersey"
    },
    {
      "city": "Riverdale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Riverdale, New Jersey"
    },
    {
      "city": "Dover",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Dover, New Jersey"
    },
    {
      "city": "Mine Hill",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mine Hill, New Jersey"
    },
    {
      "city": "Picatinny Arsenal",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Picatinny Arsenal, New Jersey"
    },
    {
      "city": "Budd Lake",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Budd Lake, New Jersey"
    },
    {
      "city": "Denville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Denville, New Jersey"
    },
    {
      "city": "Flanders",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Flanders, New Jersey"
    },
    {
      "city": "Hibernia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hibernia, New Jersey"
    },
    {
      "city": "Ironia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ironia, New Jersey"
    },
    {
      "city": "Kenvil",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Kenvil, New Jersey"
    },
    {
      "city": "Lake Hopatcong",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lake Hopatcong, New Jersey"
    },
    {
      "city": "Landing",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Landing, New Jersey"
    },
    {
      "city": "Ledgewood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ledgewood, New Jersey"
    },
    {
      "city": "Long Valley",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Long Valley, New Jersey"
    },
    {
      "city": "Mount Arlington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Arlington, New Jersey"
    },
    {
      "city": "Netcong",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Netcong, New Jersey"
    },
    {
      "city": "Rockaway",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rockaway, New Jersey"
    },
    {
      "city": "Randolph",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Randolph, New Jersey"
    },
    {
      "city": "Schooleys Mountain",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Schooleys Mountain, New Jersey"
    },
    {
      "city": "Succasunna",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Succasunna, New Jersey"
    },
    {
      "city": "Mount Tabor",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Tabor, New Jersey"
    },
    {
      "city": "Wharton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wharton, New Jersey"
    },
    {
      "city": "Brookside",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Brookside, New Jersey"
    },
    {
      "city": "Cedar Knolls",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cedar Knolls, New Jersey"
    },
    {
      "city": "Chatham",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Chatham, New Jersey"
    },
    {
      "city": "Chester",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Chester, New Jersey"
    },
    {
      "city": "Florham Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Florham Park, New Jersey"
    },
    {
      "city": "Gillette",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Gillette, New Jersey"
    },
    {
      "city": "Green Village",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Green Village, New Jersey"
    },
    {
      "city": "East Hanover",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "East Hanover, New Jersey"
    },
    {
      "city": "Madison",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Madison, New Jersey"
    },
    {
      "city": "Mendham",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mendham, New Jersey"
    },
    {
      "city": "Millington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Millington, New Jersey"
    },
    {
      "city": "Morris Plains",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Morris Plains, New Jersey"
    },
    {
      "city": "Morristown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Morristown, New Jersey"
    },
    {
      "city": "Convent Station",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Convent Station, New Jersey"
    },
    {
      "city": "Mount Freedom",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mount Freedom, New Jersey"
    },
    {
      "city": "New Vernon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Vernon, New Jersey"
    },
    {
      "city": "Stirling",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stirling, New Jersey"
    },
    {
      "city": "Whippany",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Whippany, New Jersey"
    },
    {
      "city": "Barnegat",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Barnegat, New Jersey"
    },
    {
      "city": "Barnegat Light",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Barnegat Light, New Jersey"
    },
    {
      "city": "Beach Haven",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Beach Haven, New Jersey"
    },
    {
      "city": "Manahawkin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Manahawkin, New Jersey"
    },
    {
      "city": "Tuckerton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Tuckerton, New Jersey"
    },
    {
      "city": "West Creek",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West Creek, New Jersey"
    },
    {
      "city": "Jackson",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Jackson, New Jersey"
    },
    {
      "city": "New Egypt",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Egypt, New Jersey"
    },
    {
      "city": "Lakewood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lakewood, New Jersey"
    },
    {
      "city": "Bayville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bayville, New Jersey"
    },
    {
      "city": "Beachwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Beachwood, New Jersey"
    },
    {
      "city": "Brick",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Brick, New Jersey"
    },
    {
      "city": "Forked River",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Forked River, New Jersey"
    },
    {
      "city": "Island Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Island Heights, New Jersey"
    },
    {
      "city": "Lakehurst",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lakehurst, New Jersey"
    },
    {
      "city": "Lanoka Harbor",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lanoka Harbor, New Jersey"
    },
    {
      "city": "Lavallette",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lavallette, New Jersey"
    },
    {
      "city": "Mantoloking",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mantoloking, New Jersey"
    },
    {
      "city": "Normandy Beach",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Normandy Beach, New Jersey"
    },
    {
      "city": "Ocean Gate",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ocean Gate, New Jersey"
    },
    {
      "city": "Pine Beach",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pine Beach, New Jersey"
    },
    {
      "city": "Point Pleasant Beach",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Point Pleasant Beach, New Jersey"
    },
    {
      "city": "Seaside Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Seaside Heights, New Jersey"
    },
    {
      "city": "Seaside Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Seaside Park, New Jersey"
    },
    {
      "city": "Toms River",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Toms River, New Jersey"
    },
    {
      "city": "Waretown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Waretown, New Jersey"
    },
    {
      "city": "Manchester Township",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Manchester Township, New Jersey"
    },
    {
      "city": "Clifton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clifton, New Jersey"
    },
    {
      "city": "Passaic",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Passaic, New Jersey"
    },
    {
      "city": "Bloomingdale",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bloomingdale, New Jersey"
    },
    {
      "city": "Haskell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Haskell, New Jersey"
    },
    {
      "city": "Hewitt",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hewitt, New Jersey"
    },
    {
      "city": "Little Falls",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Little Falls, New Jersey"
    },
    {
      "city": "Newfoundland",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newfoundland, New Jersey"
    },
    {
      "city": "Oak Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oak Ridge, New Jersey"
    },
    {
      "city": "Pompton Lakes",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pompton Lakes, New Jersey"
    },
    {
      "city": "Ringwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ringwood, New Jersey"
    },
    {
      "city": "Wanaque",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wanaque, New Jersey"
    },
    {
      "city": "Wayne",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wayne, New Jersey"
    },
    {
      "city": "West Milford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "West Milford, New Jersey"
    },
    {
      "city": "Paterson",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Paterson, New Jersey"
    },
    {
      "city": "Hawthorne",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hawthorne, New Jersey"
    },
    {
      "city": "Haledon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Haledon, New Jersey"
    },
    {
      "city": "Totowa",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Totowa, New Jersey"
    },
    {
      "city": "Alloway",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Alloway, New Jersey"
    },
    {
      "city": "Deepwater",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Deepwater, New Jersey"
    },
    {
      "city": "Hancocks Bridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hancocks Bridge, New Jersey"
    },
    {
      "city": "Pedricktown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pedricktown, New Jersey"
    },
    {
      "city": "Penns Grove",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Penns Grove, New Jersey"
    },
    {
      "city": "Pennsville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pennsville, New Jersey"
    },
    {
      "city": "Quinton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Quinton, New Jersey"
    },
    {
      "city": "Salem",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Salem, New Jersey"
    },
    {
      "city": "Woodstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Woodstown, New Jersey"
    },
    {
      "city": "Elmer",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Elmer, New Jersey"
    },
    {
      "city": "Norma",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Norma, New Jersey"
    },
    {
      "city": "Warren",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Warren, New Jersey"
    },
    {
      "city": "Basking Ridge",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Basking Ridge, New Jersey"
    },
    {
      "city": "Bedminster",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bedminster, New Jersey"
    },
    {
      "city": "Bernardsville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bernardsville, New Jersey"
    },
    {
      "city": "Far Hills",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Far Hills, New Jersey"
    },
    {
      "city": "Gladstone",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Gladstone, New Jersey"
    },
    {
      "city": "Liberty Corner",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Liberty Corner, New Jersey"
    },
    {
      "city": "Lyons",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lyons, New Jersey"
    },
    {
      "city": "Peapack",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Peapack, New Jersey"
    },
    {
      "city": "Pluckemin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Pluckemin, New Jersey"
    },
    {
      "city": "Belle Mead",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Belle Mead, New Jersey"
    },
    {
      "city": "Blawenburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Blawenburg, New Jersey"
    },
    {
      "city": "Kingston",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Kingston, New Jersey"
    },
    {
      "city": "Rocky Hill",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rocky Hill, New Jersey"
    },
    {
      "city": "Skillman",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Skillman, New Jersey"
    },
    {
      "city": "Bound Brook",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bound Brook, New Jersey"
    },
    {
      "city": "Bridgewater",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Bridgewater, New Jersey"
    },
    {
      "city": "Flagtown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Flagtown, New Jersey"
    },
    {
      "city": "Franklin Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Franklin Park, New Jersey"
    },
    {
      "city": "Manville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Manville, New Jersey"
    },
    {
      "city": "Martinsville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Martinsville, New Jersey"
    },
    {
      "city": "Hillsborough",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hillsborough, New Jersey"
    },
    {
      "city": "Neshanic Station",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Neshanic Station, New Jersey"
    },
    {
      "city": "Raritan",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Raritan, New Jersey"
    },
    {
      "city": "Somerset",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Somerset, New Jersey"
    },
    {
      "city": "Somerville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Somerville, New Jersey"
    },
    {
      "city": "South Bound Brook",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "South Bound Brook, New Jersey"
    },
    {
      "city": "Zarephath",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Zarephath, New Jersey"
    },
    {
      "city": "Franklin",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Franklin, New Jersey"
    },
    {
      "city": "Glenwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glenwood, New Jersey"
    },
    {
      "city": "Hamburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hamburg, New Jersey"
    },
    {
      "city": "Highland Lakes",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Highland Lakes, New Jersey"
    },
    {
      "city": "Mc Afee",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mc Afee, New Jersey"
    },
    {
      "city": "Ogdensburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Ogdensburg, New Jersey"
    },
    {
      "city": "Stockholm",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stockholm, New Jersey"
    },
    {
      "city": "Sussex",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sussex, New Jersey"
    },
    {
      "city": "Vernon",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Vernon, New Jersey"
    },
    {
      "city": "Andover",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Andover, New Jersey"
    },
    {
      "city": "Augusta",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Augusta, New Jersey"
    },
    {
      "city": "Branchville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Branchville, New Jersey"
    },
    {
      "city": "Montague",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Montague, New Jersey"
    },
    {
      "city": "Glasser",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Glasser, New Jersey"
    },
    {
      "city": "Greendell",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Greendell, New Jersey"
    },
    {
      "city": "Hopatcong",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hopatcong, New Jersey"
    },
    {
      "city": "Lafayette",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Lafayette, New Jersey"
    },
    {
      "city": "Layton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Layton, New Jersey"
    },
    {
      "city": "Middleville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Middleville, New Jersey"
    },
    {
      "city": "Newton",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Newton, New Jersey"
    },
    {
      "city": "Sparta",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Sparta, New Jersey"
    },
    {
      "city": "Stanhope",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stanhope, New Jersey"
    },
    {
      "city": "Stillwater",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stillwater, New Jersey"
    },
    {
      "city": "Swartswood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Swartswood, New Jersey"
    },
    {
      "city": "Tranquility",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Tranquility, New Jersey"
    },
    {
      "city": "Wallpack Center",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Wallpack Center, New Jersey"
    },
    {
      "city": "Cranford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Cranford, New Jersey"
    },
    {
      "city": "Fanwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Fanwood, New Jersey"
    },
    {
      "city": "Garwood",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Garwood, New Jersey"
    },
    {
      "city": "Kenilworth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Kenilworth, New Jersey"
    },
    {
      "city": "Linden",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Linden, New Jersey"
    },
    {
      "city": "Plainfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Plainfield, New Jersey"
    },
    {
      "city": "Rahway",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Rahway, New Jersey"
    },
    {
      "city": "Clark",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Clark, New Jersey"
    },
    {
      "city": "Watchung",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Watchung, New Jersey"
    },
    {
      "city": "Scotch Plains",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Scotch Plains, New Jersey"
    },
    {
      "city": "Springfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Springfield, New Jersey"
    },
    {
      "city": "Union",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Union, New Jersey"
    },
    {
      "city": "Vauxhall",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Vauxhall, New Jersey"
    },
    {
      "city": "Westfield",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Westfield, New Jersey"
    },
    {
      "city": "Mountainside",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Mountainside, New Jersey"
    },
    {
      "city": "Elizabeth",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Elizabeth, New Jersey"
    },
    {
      "city": "Roselle",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Roselle, New Jersey"
    },
    {
      "city": "Roselle Park",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Roselle Park, New Jersey"
    },
    {
      "city": "Hillside",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hillside, New Jersey"
    },
    {
      "city": "Elizabethport",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Elizabethport, New Jersey"
    },
    {
      "city": "Summit",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Summit, New Jersey"
    },
    {
      "city": "Berkeley Heights",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Berkeley Heights, New Jersey"
    },
    {
      "city": "New Providence",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "New Providence, New Jersey"
    },
    {
      "city": "Allamuchy",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Allamuchy, New Jersey"
    },
    {
      "city": "Belvidere",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Belvidere, New Jersey"
    },
    {
      "city": "Blairstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Blairstown, New Jersey"
    },
    {
      "city": "Buttzville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Buttzville, New Jersey"
    },
    {
      "city": "Changewater",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Changewater, New Jersey"
    },
    {
      "city": "Columbia",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Columbia, New Jersey"
    },
    {
      "city": "Delaware",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Delaware, New Jersey"
    },
    {
      "city": "Great Meadows",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Great Meadows, New Jersey"
    },
    {
      "city": "Hackettstown",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hackettstown, New Jersey"
    },
    {
      "city": "Hope",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Hope, New Jersey"
    },
    {
      "city": "Johnsonburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Johnsonburg, New Jersey"
    },
    {
      "city": "Oxford",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Oxford, New Jersey"
    },
    {
      "city": "Port Murray",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Port Murray, New Jersey"
    },
    {
      "city": "Vienna",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Vienna, New Jersey"
    },
    {
      "city": "Washington",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Washington, New Jersey"
    },
    {
      "city": "Asbury",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Asbury, New Jersey"
    },
    {
      "city": "Broadway",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Broadway, New Jersey"
    },
    {
      "city": "Phillipsburg",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Phillipsburg, New Jersey"
    },
    {
      "city": "Stewartsville",
      "state": "New Jersey",
      "shortState": "NJ",
      "formatedCity": "Stewartsville, New Jersey"
    },
    {
      "city": "Cedar Crest",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cedar Crest, New Mexico"
    },
    {
      "city": "Isleta",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Isleta, New Mexico"
    },
    {
      "city": "Sandia Park",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sandia Park, New Mexico"
    },
    {
      "city": "Tijeras",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tijeras, New Mexico"
    },
    {
      "city": "Albuquerque",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Albuquerque, New Mexico"
    },
    {
      "city": "Kirtland Afb",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Kirtland Afb, New Mexico"
    },
    {
      "city": "Rio Rancho",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rio Rancho, New Mexico"
    },
    {
      "city": "Aragon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Aragon, New Mexico"
    },
    {
      "city": "Datil",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Datil, New Mexico"
    },
    {
      "city": "Luna",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Luna, New Mexico"
    },
    {
      "city": "Pie Town",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pie Town, New Mexico"
    },
    {
      "city": "Quemado",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Quemado, New Mexico"
    },
    {
      "city": "Reserve",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Reserve, New Mexico"
    },
    {
      "city": "Glenwood",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Glenwood, New Mexico"
    },
    {
      "city": "Roswell",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Roswell, New Mexico"
    },
    {
      "city": "Dexter",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Dexter, New Mexico"
    },
    {
      "city": "Hagerman",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hagerman, New Mexico"
    },
    {
      "city": "Lake Arthur",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lake Arthur, New Mexico"
    },
    {
      "city": "Bluewater",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bluewater, New Mexico"
    },
    {
      "city": "Casa Blanca",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Casa Blanca, New Mexico"
    },
    {
      "city": "Cubero",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cubero, New Mexico"
    },
    {
      "city": "Grants",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Grants, New Mexico"
    },
    {
      "city": "Milan",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Milan, New Mexico"
    },
    {
      "city": "Laguna",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Laguna, New Mexico"
    },
    {
      "city": "New Laguna",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "New Laguna, New Mexico"
    },
    {
      "city": "Paguate",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Paguate, New Mexico"
    },
    {
      "city": "San Fidel",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Fidel, New Mexico"
    },
    {
      "city": "San Rafael",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Rafael, New Mexico"
    },
    {
      "city": "Fence Lake",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fence Lake, New Mexico"
    },
    {
      "city": "Pinehill",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pinehill, New Mexico"
    },
    {
      "city": "Angel Fire",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Angel Fire, New Mexico"
    },
    {
      "city": "Cimarron",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cimarron, New Mexico"
    },
    {
      "city": "Eagle Nest",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Eagle Nest, New Mexico"
    },
    {
      "city": "Maxwell",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Maxwell, New Mexico"
    },
    {
      "city": "Miami",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Miami, New Mexico"
    },
    {
      "city": "Raton",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Raton, New Mexico"
    },
    {
      "city": "Springer",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Springer, New Mexico"
    },
    {
      "city": "Ute Park",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ute Park, New Mexico"
    },
    {
      "city": "Clovis",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Clovis, New Mexico"
    },
    {
      "city": "Cannon Afb",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cannon Afb, New Mexico"
    },
    {
      "city": "Broadview",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Broadview, New Mexico"
    },
    {
      "city": "Grady",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Grady, New Mexico"
    },
    {
      "city": "Melrose",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Melrose, New Mexico"
    },
    {
      "city": "Saint Vrain",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Saint Vrain, New Mexico"
    },
    {
      "city": "Texico",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Texico, New Mexico"
    },
    {
      "city": "Fort Sumner",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fort Sumner, New Mexico"
    },
    {
      "city": "Taiban",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Taiban, New Mexico"
    },
    {
      "city": "Yeso",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Yeso, New Mexico"
    },
    {
      "city": "Garfield",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Garfield, New Mexico"
    },
    {
      "city": "Hatch",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hatch, New Mexico"
    },
    {
      "city": "Rincon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rincon, New Mexico"
    },
    {
      "city": "Salem",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Salem, New Mexico"
    },
    {
      "city": "Las Cruces",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Las Cruces, New Mexico"
    },
    {
      "city": "White Sands Missile Range",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "White Sands Missile Range, New Mexico"
    },
    {
      "city": "Santa Teresa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santa Teresa, New Mexico"
    },
    {
      "city": "Anthony",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Anthony, New Mexico"
    },
    {
      "city": "Berino",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Berino, New Mexico"
    },
    {
      "city": "Chamberino",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chamberino, New Mexico"
    },
    {
      "city": "Dona Ana",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Dona Ana, New Mexico"
    },
    {
      "city": "Fairacres",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fairacres, New Mexico"
    },
    {
      "city": "La Mesa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Mesa, New Mexico"
    },
    {
      "city": "Mesilla",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mesilla, New Mexico"
    },
    {
      "city": "Mesilla Park",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mesilla Park, New Mexico"
    },
    {
      "city": "Mesquite",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mesquite, New Mexico"
    },
    {
      "city": "Organ",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Organ, New Mexico"
    },
    {
      "city": "Radium Springs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Radium Springs, New Mexico"
    },
    {
      "city": "San Miguel",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Miguel, New Mexico"
    },
    {
      "city": "Sunland Park",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sunland Park, New Mexico"
    },
    {
      "city": "Vado",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Vado, New Mexico"
    },
    {
      "city": "Artesia",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Artesia, New Mexico"
    },
    {
      "city": "Carlsbad",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Carlsbad, New Mexico"
    },
    {
      "city": "Hope",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hope, New Mexico"
    },
    {
      "city": "Lakewood",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lakewood, New Mexico"
    },
    {
      "city": "Loco Hills",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Loco Hills, New Mexico"
    },
    {
      "city": "Loving",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Loving, New Mexico"
    },
    {
      "city": "Malaga",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Malaga, New Mexico"
    },
    {
      "city": "Whites City",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Whites City, New Mexico"
    },
    {
      "city": "Arenas Valley",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Arenas Valley, New Mexico"
    },
    {
      "city": "Bayard",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bayard, New Mexico"
    },
    {
      "city": "Buckhorn",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Buckhorn, New Mexico"
    },
    {
      "city": "Santa Clara",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santa Clara, New Mexico"
    },
    {
      "city": "Cliff",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cliff, New Mexico"
    },
    {
      "city": "Faywood",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Faywood, New Mexico"
    },
    {
      "city": "Fort Bayard",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fort Bayard, New Mexico"
    },
    {
      "city": "Gila",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Gila, New Mexico"
    },
    {
      "city": "Hachita",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hachita, New Mexico"
    },
    {
      "city": "Hanover",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hanover, New Mexico"
    },
    {
      "city": "Hurley",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hurley, New Mexico"
    },
    {
      "city": "Mimbres",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mimbres, New Mexico"
    },
    {
      "city": "Mule Creek",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mule Creek, New Mexico"
    },
    {
      "city": "Pinos Altos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pinos Altos, New Mexico"
    },
    {
      "city": "Redrock",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Redrock, New Mexico"
    },
    {
      "city": "Silver City",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Silver City, New Mexico"
    },
    {
      "city": "Tyrone",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tyrone, New Mexico"
    },
    {
      "city": "Anton Chico",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Anton Chico, New Mexico"
    },
    {
      "city": "La Loma",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Loma, New Mexico"
    },
    {
      "city": "Vaughn",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Vaughn, New Mexico"
    },
    {
      "city": "Cuervo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cuervo, New Mexico"
    },
    {
      "city": "Newkirk",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Newkirk, New Mexico"
    },
    {
      "city": "Santa Rosa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santa Rosa, New Mexico"
    },
    {
      "city": "Mills",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mills, New Mexico"
    },
    {
      "city": "Mosquero",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mosquero, New Mexico"
    },
    {
      "city": "Roy",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Roy, New Mexico"
    },
    {
      "city": "Solano",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Solano, New Mexico"
    },
    {
      "city": "Playas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Playas, New Mexico"
    },
    {
      "city": "Animas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Animas, New Mexico"
    },
    {
      "city": "Lordsburg",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lordsburg, New Mexico"
    },
    {
      "city": "Rodeo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rodeo, New Mexico"
    },
    {
      "city": "Crossroads",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Crossroads, New Mexico"
    },
    {
      "city": "Caprock",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Caprock, New Mexico"
    },
    {
      "city": "Eunice",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Eunice, New Mexico"
    },
    {
      "city": "Hobbs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hobbs, New Mexico"
    },
    {
      "city": "Jal",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Jal, New Mexico"
    },
    {
      "city": "Lovington",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lovington, New Mexico"
    },
    {
      "city": "Mcdonald",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mcdonald, New Mexico"
    },
    {
      "city": "Maljamar",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Maljamar, New Mexico"
    },
    {
      "city": "Monument",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Monument, New Mexico"
    },
    {
      "city": "Tatum",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tatum, New Mexico"
    },
    {
      "city": "Carrizozo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Carrizozo, New Mexico"
    },
    {
      "city": "Alto",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Alto, New Mexico"
    },
    {
      "city": "Capitan",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Capitan, New Mexico"
    },
    {
      "city": "Corona",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Corona, New Mexico"
    },
    {
      "city": "Fort Stanton",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fort Stanton, New Mexico"
    },
    {
      "city": "Glencoe",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Glencoe, New Mexico"
    },
    {
      "city": "Hondo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hondo, New Mexico"
    },
    {
      "city": "Lincoln",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lincoln, New Mexico"
    },
    {
      "city": "Nogal",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Nogal, New Mexico"
    },
    {
      "city": "Picacho",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Picacho, New Mexico"
    },
    {
      "city": "Ruidoso",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ruidoso, New Mexico"
    },
    {
      "city": "Ruidoso Downs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ruidoso Downs, New Mexico"
    },
    {
      "city": "San Patricio",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Patricio, New Mexico"
    },
    {
      "city": "Tinnie",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tinnie, New Mexico"
    },
    {
      "city": "Los Alamos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Los Alamos, New Mexico"
    },
    {
      "city": "White Rock",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "White Rock, New Mexico"
    },
    {
      "city": "Columbus",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Columbus, New Mexico"
    },
    {
      "city": "Deming",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Deming, New Mexico"
    },
    {
      "city": "Prewitt",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Prewitt, New Mexico"
    },
    {
      "city": "Gallup",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Gallup, New Mexico"
    },
    {
      "city": "Brimhall",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Brimhall, New Mexico"
    },
    {
      "city": "Church Rock",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Church Rock, New Mexico"
    },
    {
      "city": "Continental Divide",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Continental Divide, New Mexico"
    },
    {
      "city": "Crownpoint",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Crownpoint, New Mexico"
    },
    {
      "city": "Fort Wingate",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fort Wingate, New Mexico"
    },
    {
      "city": "Gamerco",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Gamerco, New Mexico"
    },
    {
      "city": "Mentmore",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mentmore, New Mexico"
    },
    {
      "city": "Mexican Springs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mexican Springs, New Mexico"
    },
    {
      "city": "Ramah",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ramah, New Mexico"
    },
    {
      "city": "Rehoboth",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rehoboth, New Mexico"
    },
    {
      "city": "Thoreau",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Thoreau, New Mexico"
    },
    {
      "city": "Tohatchi",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tohatchi, New Mexico"
    },
    {
      "city": "Vanderwagen",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Vanderwagen, New Mexico"
    },
    {
      "city": "Zuni",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Zuni, New Mexico"
    },
    {
      "city": "Navajo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Navajo, New Mexico"
    },
    {
      "city": "Jamestown",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Jamestown, New Mexico"
    },
    {
      "city": "Smith Lake",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Smith Lake, New Mexico"
    },
    {
      "city": "Yatahey",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Yatahey, New Mexico"
    },
    {
      "city": "Buena Vista",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Buena Vista, New Mexico"
    },
    {
      "city": "Chacon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chacon, New Mexico"
    },
    {
      "city": "Cleveland",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cleveland, New Mexico"
    },
    {
      "city": "Guadalupita",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Guadalupita, New Mexico"
    },
    {
      "city": "Holman",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Holman, New Mexico"
    },
    {
      "city": "Mora",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mora, New Mexico"
    },
    {
      "city": "Ocate",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ocate, New Mexico"
    },
    {
      "city": "Ojo Feliz",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ojo Feliz, New Mexico"
    },
    {
      "city": "Rainsville",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rainsville, New Mexico"
    },
    {
      "city": "Valmora",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Valmora, New Mexico"
    },
    {
      "city": "Wagon Mound",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Wagon Mound, New Mexico"
    },
    {
      "city": "Watrous",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Watrous, New Mexico"
    },
    {
      "city": "Chaparral",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chaparral, New Mexico"
    },
    {
      "city": "Alamogordo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Alamogordo, New Mexico"
    },
    {
      "city": "Bent",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bent, New Mexico"
    },
    {
      "city": "Cloudcroft",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cloudcroft, New Mexico"
    },
    {
      "city": "High Rolls Mountain Park",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "High Rolls Mountain Park, New Mexico"
    },
    {
      "city": "Holloman Air Force Base",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Holloman Air Force Base, New Mexico"
    },
    {
      "city": "La Luz",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Luz, New Mexico"
    },
    {
      "city": "Mayhill",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mayhill, New Mexico"
    },
    {
      "city": "Mescalero",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mescalero, New Mexico"
    },
    {
      "city": "Orogrande",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Orogrande, New Mexico"
    },
    {
      "city": "Pinon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pinon, New Mexico"
    },
    {
      "city": "Sacramento",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sacramento, New Mexico"
    },
    {
      "city": "Sunspot",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sunspot, New Mexico"
    },
    {
      "city": "Timberon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Timberon, New Mexico"
    },
    {
      "city": "Tularosa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tularosa, New Mexico"
    },
    {
      "city": "Weed",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Weed, New Mexico"
    },
    {
      "city": "House",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "House, New Mexico"
    },
    {
      "city": "Tucumcari",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tucumcari, New Mexico"
    },
    {
      "city": "Bard",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bard, New Mexico"
    },
    {
      "city": "Logan",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Logan, New Mexico"
    },
    {
      "city": "Mcalister",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mcalister, New Mexico"
    },
    {
      "city": "Nara Visa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Nara Visa, New Mexico"
    },
    {
      "city": "Quay",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Quay, New Mexico"
    },
    {
      "city": "San Jon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Jon, New Mexico"
    },
    {
      "city": "Coyote",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Coyote, New Mexico"
    },
    {
      "city": "Gallina",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Gallina, New Mexico"
    },
    {
      "city": "Lindrith",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lindrith, New Mexico"
    },
    {
      "city": "Youngsville",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Youngsville, New Mexico"
    },
    {
      "city": "Abiquiu",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Abiquiu, New Mexico"
    },
    {
      "city": "Alcalde",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Alcalde, New Mexico"
    },
    {
      "city": "Canjilon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Canjilon, New Mexico"
    },
    {
      "city": "Canones",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Canones, New Mexico"
    },
    {
      "city": "Cebolla",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cebolla, New Mexico"
    },
    {
      "city": "Chama",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chama, New Mexico"
    },
    {
      "city": "Chimayo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chimayo, New Mexico"
    },
    {
      "city": "Cordova",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cordova, New Mexico"
    },
    {
      "city": "Dixon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Dixon, New Mexico"
    },
    {
      "city": "Dulce",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Dulce, New Mexico"
    },
    {
      "city": "El Rito",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "El Rito, New Mexico"
    },
    {
      "city": "Embudo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Embudo, New Mexico"
    },
    {
      "city": "Espanola",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Espanola, New Mexico"
    },
    {
      "city": "Hernandez",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hernandez, New Mexico"
    },
    {
      "city": "La Madera",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Madera, New Mexico"
    },
    {
      "city": "Medanales",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Medanales, New Mexico"
    },
    {
      "city": "Los Ojos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Los Ojos, New Mexico"
    },
    {
      "city": "Petaca",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Petaca, New Mexico"
    },
    {
      "city": "Ohkay Owingeh",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ohkay Owingeh, New Mexico"
    },
    {
      "city": "Tierra Amarilla",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tierra Amarilla, New Mexico"
    },
    {
      "city": "Truchas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Truchas, New Mexico"
    },
    {
      "city": "Vallecitos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Vallecitos, New Mexico"
    },
    {
      "city": "Velarde",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Velarde, New Mexico"
    },
    {
      "city": "Causey",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Causey, New Mexico"
    },
    {
      "city": "Dora",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Dora, New Mexico"
    },
    {
      "city": "Elida",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Elida, New Mexico"
    },
    {
      "city": "Floyd",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Floyd, New Mexico"
    },
    {
      "city": "Kenna",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Kenna, New Mexico"
    },
    {
      "city": "Lingo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lingo, New Mexico"
    },
    {
      "city": "Milnesand",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Milnesand, New Mexico"
    },
    {
      "city": "Pep",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pep, New Mexico"
    },
    {
      "city": "Portales",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Portales, New Mexico"
    },
    {
      "city": "Rogers",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rogers, New Mexico"
    },
    {
      "city": "Algodones",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Algodones, New Mexico"
    },
    {
      "city": "Bernalillo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bernalillo, New Mexico"
    },
    {
      "city": "Cuba",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cuba, New Mexico"
    },
    {
      "city": "Counselor",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Counselor, New Mexico"
    },
    {
      "city": "Jemez Pueblo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Jemez Pueblo, New Mexico"
    },
    {
      "city": "Jemez Springs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Jemez Springs, New Mexico"
    },
    {
      "city": "La Jara",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Jara, New Mexico"
    },
    {
      "city": "Pena Blanca",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pena Blanca, New Mexico"
    },
    {
      "city": "Placitas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Placitas, New Mexico"
    },
    {
      "city": "Ponderosa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ponderosa, New Mexico"
    },
    {
      "city": "Regina",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Regina, New Mexico"
    },
    {
      "city": "Corrales",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Corrales, New Mexico"
    },
    {
      "city": "Santo Domingo Pueblo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santo Domingo Pueblo, New Mexico"
    },
    {
      "city": "San Ysidro",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Ysidro, New Mexico"
    },
    {
      "city": "Cochiti Pueblo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cochiti Pueblo, New Mexico"
    },
    {
      "city": "Cochiti Lake",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cochiti Lake, New Mexico"
    },
    {
      "city": "Nageezi",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Nageezi, New Mexico"
    },
    {
      "city": "Sheep Springs",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sheep Springs, New Mexico"
    },
    {
      "city": "Farmington",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Farmington, New Mexico"
    },
    {
      "city": "Aztec",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Aztec, New Mexico"
    },
    {
      "city": "Blanco",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Blanco, New Mexico"
    },
    {
      "city": "Bloomfield",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bloomfield, New Mexico"
    },
    {
      "city": "Flora Vista",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Flora Vista, New Mexico"
    },
    {
      "city": "Fruitland",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Fruitland, New Mexico"
    },
    {
      "city": "Kirtland",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Kirtland, New Mexico"
    },
    {
      "city": "La Plata",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Plata, New Mexico"
    },
    {
      "city": "Navajo Dam",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Navajo Dam, New Mexico"
    },
    {
      "city": "Shiprock",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Shiprock, New Mexico"
    },
    {
      "city": "Waterflow",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Waterflow, New Mexico"
    },
    {
      "city": "Newcomb",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Newcomb, New Mexico"
    },
    {
      "city": "Sanostee",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sanostee, New Mexico"
    },
    {
      "city": "Ilfeld",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ilfeld, New Mexico"
    },
    {
      "city": "Pecos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pecos, New Mexico"
    },
    {
      "city": "Ribera",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ribera, New Mexico"
    },
    {
      "city": "Rowe",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rowe, New Mexico"
    },
    {
      "city": "San Jose",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Jose, New Mexico"
    },
    {
      "city": "Serafina",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Serafina, New Mexico"
    },
    {
      "city": "Tererro",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tererro, New Mexico"
    },
    {
      "city": "Villanueva",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Villanueva, New Mexico"
    },
    {
      "city": "Las Vegas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Las Vegas, New Mexico"
    },
    {
      "city": "Montezuma",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Montezuma, New Mexico"
    },
    {
      "city": "Rociada",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Rociada, New Mexico"
    },
    {
      "city": "Sapello",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sapello, New Mexico"
    },
    {
      "city": "Conchas Dam",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Conchas Dam, New Mexico"
    },
    {
      "city": "Garita",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Garita, New Mexico"
    },
    {
      "city": "Trementina",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Trementina, New Mexico"
    },
    {
      "city": "Cerrillos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cerrillos, New Mexico"
    },
    {
      "city": "Edgewood",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Edgewood, New Mexico"
    },
    {
      "city": "Stanley",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Stanley, New Mexico"
    },
    {
      "city": "Santa Fe",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santa Fe, New Mexico"
    },
    {
      "city": "Glorieta",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Glorieta, New Mexico"
    },
    {
      "city": "Lamy",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lamy, New Mexico"
    },
    {
      "city": "Santa Cruz",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Santa Cruz, New Mexico"
    },
    {
      "city": "Tesuque",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tesuque, New Mexico"
    },
    {
      "city": "Spaceport City",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Spaceport City, New Mexico"
    },
    {
      "city": "Truth Or Consequences",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Truth Or Consequences, New Mexico"
    },
    {
      "city": "Arrey",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Arrey, New Mexico"
    },
    {
      "city": "Caballo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Caballo, New Mexico"
    },
    {
      "city": "Derry",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Derry, New Mexico"
    },
    {
      "city": "Elephant Butte",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Elephant Butte, New Mexico"
    },
    {
      "city": "Monticello",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Monticello, New Mexico"
    },
    {
      "city": "Williamsburg",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Williamsburg, New Mexico"
    },
    {
      "city": "Winston",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Winston, New Mexico"
    },
    {
      "city": "Hillsboro",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Hillsboro, New Mexico"
    },
    {
      "city": "Claunch",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Claunch, New Mexico"
    },
    {
      "city": "La Joya",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "La Joya, New Mexico"
    },
    {
      "city": "Veguita",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Veguita, New Mexico"
    },
    {
      "city": "Socorro",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Socorro, New Mexico"
    },
    {
      "city": "Lemitar",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Lemitar, New Mexico"
    },
    {
      "city": "Magdalena",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Magdalena, New Mexico"
    },
    {
      "city": "Polvadera",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Polvadera, New Mexico"
    },
    {
      "city": "San Acacia",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Acacia, New Mexico"
    },
    {
      "city": "San Antonio",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Antonio, New Mexico"
    },
    {
      "city": "Amalia",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Amalia, New Mexico"
    },
    {
      "city": "Arroyo Hondo",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Arroyo Hondo, New Mexico"
    },
    {
      "city": "Arroyo Seco",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Arroyo Seco, New Mexico"
    },
    {
      "city": "Carson",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Carson, New Mexico"
    },
    {
      "city": "Cerro",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cerro, New Mexico"
    },
    {
      "city": "Chamisal",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Chamisal, New Mexico"
    },
    {
      "city": "Costilla",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Costilla, New Mexico"
    },
    {
      "city": "Taos Ski Valley",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Taos Ski Valley, New Mexico"
    },
    {
      "city": "El Prado",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "El Prado, New Mexico"
    },
    {
      "city": "Llano",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Llano, New Mexico"
    },
    {
      "city": "Ojo Caliente",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ojo Caliente, New Mexico"
    },
    {
      "city": "Penasco",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Penasco, New Mexico"
    },
    {
      "city": "Questa",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Questa, New Mexico"
    },
    {
      "city": "Ranchos De Taos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Ranchos De Taos, New Mexico"
    },
    {
      "city": "Red River",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Red River, New Mexico"
    },
    {
      "city": "San Cristobal",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "San Cristobal, New Mexico"
    },
    {
      "city": "Taos",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Taos, New Mexico"
    },
    {
      "city": "Trampas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Trampas, New Mexico"
    },
    {
      "city": "Tres Piedras",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tres Piedras, New Mexico"
    },
    {
      "city": "Vadito",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Vadito, New Mexico"
    },
    {
      "city": "Valdez",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Valdez, New Mexico"
    },
    {
      "city": "Cedarvale",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Cedarvale, New Mexico"
    },
    {
      "city": "Estancia",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Estancia, New Mexico"
    },
    {
      "city": "Mcintosh",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mcintosh, New Mexico"
    },
    {
      "city": "Moriarty",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Moriarty, New Mexico"
    },
    {
      "city": "Mountainair",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Mountainair, New Mexico"
    },
    {
      "city": "Torreon",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Torreon, New Mexico"
    },
    {
      "city": "Willard",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Willard, New Mexico"
    },
    {
      "city": "Clines Corners",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Clines Corners, New Mexico"
    },
    {
      "city": "Encino",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Encino, New Mexico"
    },
    {
      "city": "Amistad",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Amistad, New Mexico"
    },
    {
      "city": "Capulin",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Capulin, New Mexico"
    },
    {
      "city": "Clayton",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Clayton, New Mexico"
    },
    {
      "city": "Des Moines",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Des Moines, New Mexico"
    },
    {
      "city": "Folsom",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Folsom, New Mexico"
    },
    {
      "city": "Gladstone",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Gladstone, New Mexico"
    },
    {
      "city": "Grenville",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Grenville, New Mexico"
    },
    {
      "city": "Sedan",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Sedan, New Mexico"
    },
    {
      "city": "Belen",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Belen, New Mexico"
    },
    {
      "city": "Bosque",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bosque, New Mexico"
    },
    {
      "city": "Jarales",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Jarales, New Mexico"
    },
    {
      "city": "Los Lunas",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Los Lunas, New Mexico"
    },
    {
      "city": "Pueblo Of Acoma",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Pueblo Of Acoma, New Mexico"
    },
    {
      "city": "Peralta",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Peralta, New Mexico"
    },
    {
      "city": "Tome",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Tome, New Mexico"
    },
    {
      "city": "Bosque Farms",
      "state": "New Mexico",
      "shortState": "NM",
      "formatedCity": "Bosque Farms, New Mexico"
    },
    {
      "city": "Fallon",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Fallon, Nevada"
    },
    {
      "city": "The Lakes",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "The Lakes, Nevada"
    },
    {
      "city": "Henderson",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Henderson, Nevada"
    },
    {
      "city": "Blue Diamond",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Blue Diamond, Nevada"
    },
    {
      "city": "Boulder City",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Boulder City, Nevada"
    },
    {
      "city": "Bunkerville",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Bunkerville, Nevada"
    },
    {
      "city": "Indian Springs",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Indian Springs, Nevada"
    },
    {
      "city": "Jean",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Jean, Nevada"
    },
    {
      "city": "Logandale",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Logandale, Nevada"
    },
    {
      "city": "Mesquite",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mesquite, Nevada"
    },
    {
      "city": "Moapa",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Moapa, Nevada"
    },
    {
      "city": "Laughlin",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Laughlin, Nevada"
    },
    {
      "city": "North Las Vegas",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "North Las Vegas, Nevada"
    },
    {
      "city": "Coyote Springs",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Coyote Springs, Nevada"
    },
    {
      "city": "Cal Nev Ari",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Cal Nev Ari, Nevada"
    },
    {
      "city": "Overton",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Overton, Nevada"
    },
    {
      "city": "Searchlight",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Searchlight, Nevada"
    },
    {
      "city": "Sloan",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Sloan, Nevada"
    },
    {
      "city": "Las Vegas",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Las Vegas, Nevada"
    },
    {
      "city": "Nellis Afb",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Nellis Afb, Nevada"
    },
    {
      "city": "Gardnerville",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Gardnerville, Nevada"
    },
    {
      "city": "Genoa",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Genoa, Nevada"
    },
    {
      "city": "Glenbrook",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Glenbrook, Nevada"
    },
    {
      "city": "Minden",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Minden, Nevada"
    },
    {
      "city": "Zephyr Cove",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Zephyr Cove, Nevada"
    },
    {
      "city": "Stateline",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Stateline, Nevada"
    },
    {
      "city": "Carson City",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Carson City, Nevada"
    },
    {
      "city": "Elko",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Elko, Nevada"
    },
    {
      "city": "Spring Creek",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Spring Creek, Nevada"
    },
    {
      "city": "Carlin",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Carlin, Nevada"
    },
    {
      "city": "Deeth",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Deeth, Nevada"
    },
    {
      "city": "Jackpot",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Jackpot, Nevada"
    },
    {
      "city": "Jarbidge",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Jarbidge, Nevada"
    },
    {
      "city": "Lamoille",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Lamoille, Nevada"
    },
    {
      "city": "Montello",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Montello, Nevada"
    },
    {
      "city": "Mountain City",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mountain City, Nevada"
    },
    {
      "city": "Owyhee",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Owyhee, Nevada"
    },
    {
      "city": "Ruby Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Ruby Valley, Nevada"
    },
    {
      "city": "Tuscarora",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Tuscarora, Nevada"
    },
    {
      "city": "Wells",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Wells, Nevada"
    },
    {
      "city": "West Wendover",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "West Wendover, Nevada"
    },
    {
      "city": "Dyer",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Dyer, Nevada"
    },
    {
      "city": "Goldfield",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Goldfield, Nevada"
    },
    {
      "city": "Silverpeak",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Silverpeak, Nevada"
    },
    {
      "city": "Eureka",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Eureka, Nevada"
    },
    {
      "city": "Crescent Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Crescent Valley, Nevada"
    },
    {
      "city": "Denio",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Denio, Nevada"
    },
    {
      "city": "Golconda",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Golconda, Nevada"
    },
    {
      "city": "Mc Dermitt",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mc Dermitt, Nevada"
    },
    {
      "city": "Orovada",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Orovada, Nevada"
    },
    {
      "city": "Paradise Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Paradise Valley, Nevada"
    },
    {
      "city": "Valmy",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Valmy, Nevada"
    },
    {
      "city": "Winnemucca",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Winnemucca, Nevada"
    },
    {
      "city": "Austin",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Austin, Nevada"
    },
    {
      "city": "Battle Mountain",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Battle Mountain, Nevada"
    },
    {
      "city": "Alamo",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Alamo, Nevada"
    },
    {
      "city": "Caliente",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Caliente, Nevada"
    },
    {
      "city": "Hiko",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Hiko, Nevada"
    },
    {
      "city": "Panaca",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Panaca, Nevada"
    },
    {
      "city": "Pioche",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Pioche, Nevada"
    },
    {
      "city": "Dayton",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Dayton, Nevada"
    },
    {
      "city": "Fernley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Fernley, Nevada"
    },
    {
      "city": "Silver City",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Silver City, Nevada"
    },
    {
      "city": "Silver Springs",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Silver Springs, Nevada"
    },
    {
      "city": "Smith",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Smith, Nevada"
    },
    {
      "city": "Wellington",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Wellington, Nevada"
    },
    {
      "city": "Yerington",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Yerington, Nevada"
    },
    {
      "city": "Hawthorne",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Hawthorne, Nevada"
    },
    {
      "city": "Luning",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Luning, Nevada"
    },
    {
      "city": "Mina",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mina, Nevada"
    },
    {
      "city": "Schurz",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Schurz, Nevada"
    },
    {
      "city": "Beatty",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Beatty, Nevada"
    },
    {
      "city": "Amargosa Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Amargosa Valley, Nevada"
    },
    {
      "city": "Manhattan",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Manhattan, Nevada"
    },
    {
      "city": "Mercury",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mercury, Nevada"
    },
    {
      "city": "Pahrump",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Pahrump, Nevada"
    },
    {
      "city": "Round Mountain",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Round Mountain, Nevada"
    },
    {
      "city": "Tonopah",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Tonopah, Nevada"
    },
    {
      "city": "Gabbs",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Gabbs, Nevada"
    },
    {
      "city": "Imlay",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Imlay, Nevada"
    },
    {
      "city": "Lovelock",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Lovelock, Nevada"
    },
    {
      "city": "Virginia City",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Virginia City, Nevada"
    },
    {
      "city": "Crystal Bay",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Crystal Bay, Nevada"
    },
    {
      "city": "Empire",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Empire, Nevada"
    },
    {
      "city": "Gerlach",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Gerlach, Nevada"
    },
    {
      "city": "Nixon",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Nixon, Nevada"
    },
    {
      "city": "Sparks",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Sparks, Nevada"
    },
    {
      "city": "Sun Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Sun Valley, Nevada"
    },
    {
      "city": "Verdi",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Verdi, Nevada"
    },
    {
      "city": "Wadsworth",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Wadsworth, Nevada"
    },
    {
      "city": "Incline Village",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Incline Village, Nevada"
    },
    {
      "city": "Reno",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Reno, Nevada"
    },
    {
      "city": "Washoe Valley",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Washoe Valley, Nevada"
    },
    {
      "city": "Ely",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Ely, Nevada"
    },
    {
      "city": "Baker",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Baker, Nevada"
    },
    {
      "city": "Duckwater",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Duckwater, Nevada"
    },
    {
      "city": "Lund",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Lund, Nevada"
    },
    {
      "city": "Mc Gill",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Mc Gill, Nevada"
    },
    {
      "city": "Ruth",
      "state": "Nevada",
      "shortState": "NV",
      "formatedCity": "Ruth, Nevada"
    },
    {
      "city": "Alcove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alcove, New York"
    },
    {
      "city": "Altamont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Altamont, New York"
    },
    {
      "city": "Berne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Berne, New York"
    },
    {
      "city": "Clarksville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clarksville, New York"
    },
    {
      "city": "Coeymans",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Coeymans, New York"
    },
    {
      "city": "Coeymans Hollow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Coeymans Hollow, New York"
    },
    {
      "city": "Cohoes",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cohoes, New York"
    },
    {
      "city": "Delmar",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delmar, New York"
    },
    {
      "city": "Dormansville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dormansville, New York"
    },
    {
      "city": "East Berne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Berne, New York"
    },
    {
      "city": "Feura Bush",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Feura Bush, New York"
    },
    {
      "city": "Glenmont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenmont, New York"
    },
    {
      "city": "Guilderland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Guilderland, New York"
    },
    {
      "city": "Guilderland Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Guilderland Center, New York"
    },
    {
      "city": "Knox",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Knox, New York"
    },
    {
      "city": "Latham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Latham, New York"
    },
    {
      "city": "Medusa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Medusa, New York"
    },
    {
      "city": "Newtonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newtonville, New York"
    },
    {
      "city": "Ravena",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ravena, New York"
    },
    {
      "city": "Rensselaerville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rensselaerville, New York"
    },
    {
      "city": "Selkirk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Selkirk, New York"
    },
    {
      "city": "Slingerlands",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Slingerlands, New York"
    },
    {
      "city": "South Bethlehem",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Bethlehem, New York"
    },
    {
      "city": "Troy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Troy, New York"
    },
    {
      "city": "Voorheesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Voorheesville, New York"
    },
    {
      "city": "Watervliet",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Watervliet, New York"
    },
    {
      "city": "Westerlo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westerlo, New York"
    },
    {
      "city": "Albany",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Albany, New York"
    },
    {
      "city": "Preston Hollow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Preston Hollow, New York"
    },
    {
      "city": "Centerville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Centerville, New York"
    },
    {
      "city": "Allentown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Allentown, New York"
    },
    {
      "city": "Alma",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alma, New York"
    },
    {
      "city": "Angelica",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Angelica, New York"
    },
    {
      "city": "Belfast",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Belfast, New York"
    },
    {
      "city": "Black Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Black Creek, New York"
    },
    {
      "city": "Bolivar",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bolivar, New York"
    },
    {
      "city": "Caneadea",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Caneadea, New York"
    },
    {
      "city": "Ceres",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ceres, New York"
    },
    {
      "city": "Cuba",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cuba, New York"
    },
    {
      "city": "Fillmore",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fillmore, New York"
    },
    {
      "city": "Friendship",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Friendship, New York"
    },
    {
      "city": "Houghton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Houghton, New York"
    },
    {
      "city": "Hume",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hume, New York"
    },
    {
      "city": "Little Genesee",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Little Genesee, New York"
    },
    {
      "city": "Richburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richburg, New York"
    },
    {
      "city": "Rushford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rushford, New York"
    },
    {
      "city": "West Clarksville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Clarksville, New York"
    },
    {
      "city": "Alfred",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alfred, New York"
    },
    {
      "city": "Alfred Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alfred Station, New York"
    },
    {
      "city": "Almond",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Almond, New York"
    },
    {
      "city": "Andover",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Andover, New York"
    },
    {
      "city": "Belmont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Belmont, New York"
    },
    {
      "city": "Canaseraga",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canaseraga, New York"
    },
    {
      "city": "Scio",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Scio, New York"
    },
    {
      "city": "Swain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Swain, New York"
    },
    {
      "city": "Wellsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wellsville, New York"
    },
    {
      "city": "Whitesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whitesville, New York"
    },
    {
      "city": "Bronx",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bronx, New York"
    },
    {
      "city": "Bible School Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bible School Park, New York"
    },
    {
      "city": "Castle Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Castle Creek, New York"
    },
    {
      "city": "Chenango Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chenango Bridge, New York"
    },
    {
      "city": "Chenango Forks",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chenango Forks, New York"
    },
    {
      "city": "Conklin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Conklin, New York"
    },
    {
      "city": "Corbettsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Corbettsville, New York"
    },
    {
      "city": "Deposit",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Deposit, New York"
    },
    {
      "city": "Endicott",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Endicott, New York"
    },
    {
      "city": "Endwell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Endwell, New York"
    },
    {
      "city": "Glen Aubrey",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Aubrey, New York"
    },
    {
      "city": "Harpursville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harpursville, New York"
    },
    {
      "city": "Johnson City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Johnson City, New York"
    },
    {
      "city": "Killawog",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Killawog, New York"
    },
    {
      "city": "Kirkwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kirkwood, New York"
    },
    {
      "city": "Lisle",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lisle, New York"
    },
    {
      "city": "Maine",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maine, New York"
    },
    {
      "city": "Nineveh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nineveh, New York"
    },
    {
      "city": "Ouaquaga",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ouaquaga, New York"
    },
    {
      "city": "Port Crane",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Crane, New York"
    },
    {
      "city": "Tunnel",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tunnel, New York"
    },
    {
      "city": "Vestal",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Vestal, New York"
    },
    {
      "city": "Whitney Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whitney Point, New York"
    },
    {
      "city": "Windsor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Windsor, New York"
    },
    {
      "city": "Binghamton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Binghamton, New York"
    },
    {
      "city": "Dayton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dayton, New York"
    },
    {
      "city": "Delevan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delevan, New York"
    },
    {
      "city": "Farmersville Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Farmersville Station, New York"
    },
    {
      "city": "Freedom",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Freedom, New York"
    },
    {
      "city": "Gowanda",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gowanda, New York"
    },
    {
      "city": "Machias",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Machias, New York"
    },
    {
      "city": "Perrysburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Perrysburg, New York"
    },
    {
      "city": "Sandusky",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sandusky, New York"
    },
    {
      "city": "South Dayton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Dayton, New York"
    },
    {
      "city": "Versailles",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Versailles, New York"
    },
    {
      "city": "West Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Valley, New York"
    },
    {
      "city": "Yorkshire",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yorkshire, New York"
    },
    {
      "city": "Allegany",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Allegany, New York"
    },
    {
      "city": "Cattaraugus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cattaraugus, New York"
    },
    {
      "city": "Conewango Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Conewango Valley, New York"
    },
    {
      "city": "East Otto",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Otto, New York"
    },
    {
      "city": "East Randolph",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Randolph, New York"
    },
    {
      "city": "Ellicottville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellicottville, New York"
    },
    {
      "city": "Franklinville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Franklinville, New York"
    },
    {
      "city": "Great Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Great Valley, New York"
    },
    {
      "city": "Hinsdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hinsdale, New York"
    },
    {
      "city": "Kill Buck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kill Buck, New York"
    },
    {
      "city": "Leon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Leon, New York"
    },
    {
      "city": "Limestone",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Limestone, New York"
    },
    {
      "city": "Little Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Little Valley, New York"
    },
    {
      "city": "Olean",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Olean, New York"
    },
    {
      "city": "Otto",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Otto, New York"
    },
    {
      "city": "Portville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Portville, New York"
    },
    {
      "city": "Randolph",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Randolph, New York"
    },
    {
      "city": "Saint Bonaventure",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saint Bonaventure, New York"
    },
    {
      "city": "Salamanca",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Salamanca, New York"
    },
    {
      "city": "Steamburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Steamburg, New York"
    },
    {
      "city": "Westons Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westons Mills, New York"
    },
    {
      "city": "Auburn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Auburn, New York"
    },
    {
      "city": "Aurora",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Aurora, New York"
    },
    {
      "city": "Cato",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cato, New York"
    },
    {
      "city": "Cayuga",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cayuga, New York"
    },
    {
      "city": "Genoa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Genoa, New York"
    },
    {
      "city": "King Ferry",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "King Ferry, New York"
    },
    {
      "city": "Locke",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Locke, New York"
    },
    {
      "city": "Martville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Martville, New York"
    },
    {
      "city": "Meridian",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Meridian, New York"
    },
    {
      "city": "Montezuma",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Montezuma, New York"
    },
    {
      "city": "Moravia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Moravia, New York"
    },
    {
      "city": "Poplar Ridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Poplar Ridge, New York"
    },
    {
      "city": "Port Byron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Byron, New York"
    },
    {
      "city": "Scipio Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Scipio Center, New York"
    },
    {
      "city": "Sterling",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sterling, New York"
    },
    {
      "city": "Union Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Union Springs, New York"
    },
    {
      "city": "Weedsport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Weedsport, New York"
    },
    {
      "city": "Dunkirk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dunkirk, New York"
    },
    {
      "city": "Forestville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Forestville, New York"
    },
    {
      "city": "Fredonia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fredonia, New York"
    },
    {
      "city": "Irving",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Irving, New York"
    },
    {
      "city": "Sheridan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sheridan, New York"
    },
    {
      "city": "Silver Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Silver Creek, New York"
    },
    {
      "city": "Van Buren Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Van Buren Point, New York"
    },
    {
      "city": "Jamestown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jamestown, New York"
    },
    {
      "city": "Ashville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ashville, New York"
    },
    {
      "city": "Bemus Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bemus Point, New York"
    },
    {
      "city": "Brocton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brocton, New York"
    },
    {
      "city": "Cassadaga",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cassadaga, New York"
    },
    {
      "city": "Celoron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Celoron, New York"
    },
    {
      "city": "Chautauqua",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chautauqua, New York"
    },
    {
      "city": "Cherry Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cherry Creek, New York"
    },
    {
      "city": "Clymer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clymer, New York"
    },
    {
      "city": "Dewittville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dewittville, New York"
    },
    {
      "city": "Ellington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellington, New York"
    },
    {
      "city": "Falconer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Falconer, New York"
    },
    {
      "city": "Findley Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Findley Lake, New York"
    },
    {
      "city": "Frewsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Frewsburg, New York"
    },
    {
      "city": "Gerry",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gerry, New York"
    },
    {
      "city": "Greenhurst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenhurst, New York"
    },
    {
      "city": "Kennedy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kennedy, New York"
    },
    {
      "city": "Lakewood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lakewood, New York"
    },
    {
      "city": "Lily Dale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lily Dale, New York"
    },
    {
      "city": "Maple Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maple Springs, New York"
    },
    {
      "city": "Mayville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mayville, New York"
    },
    {
      "city": "Niobe",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Niobe, New York"
    },
    {
      "city": "Panama",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Panama, New York"
    },
    {
      "city": "Portland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Portland, New York"
    },
    {
      "city": "Ripley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ripley, New York"
    },
    {
      "city": "Sherman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sherman, New York"
    },
    {
      "city": "Sinclairville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sinclairville, New York"
    },
    {
      "city": "Stockton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stockton, New York"
    },
    {
      "city": "Stow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stow, New York"
    },
    {
      "city": "Westfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westfield, New York"
    },
    {
      "city": "Big Flats",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Big Flats, New York"
    },
    {
      "city": "Breesport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Breesport, New York"
    },
    {
      "city": "Chemung",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chemung, New York"
    },
    {
      "city": "Erin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Erin, New York"
    },
    {
      "city": "Horseheads",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Horseheads, New York"
    },
    {
      "city": "Lowman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lowman, New York"
    },
    {
      "city": "Millport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Millport, New York"
    },
    {
      "city": "Pine City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine City, New York"
    },
    {
      "city": "Pine Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine Valley, New York"
    },
    {
      "city": "Van Etten",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Van Etten, New York"
    },
    {
      "city": "Wellsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wellsburg, New York"
    },
    {
      "city": "Elmira",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elmira, New York"
    },
    {
      "city": "North Pitcher",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Pitcher, New York"
    },
    {
      "city": "Pitcher",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pitcher, New York"
    },
    {
      "city": "South Otselic",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Otselic, New York"
    },
    {
      "city": "Earlville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Earlville, New York"
    },
    {
      "city": "New Berlin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Berlin, New York"
    },
    {
      "city": "Sherburne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sherburne, New York"
    },
    {
      "city": "Smyrna",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Smyrna, New York"
    },
    {
      "city": "Afton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Afton, New York"
    },
    {
      "city": "Bainbridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bainbridge, New York"
    },
    {
      "city": "East Pharsalia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Pharsalia, New York"
    },
    {
      "city": "Greene",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greene, New York"
    },
    {
      "city": "Guilford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Guilford, New York"
    },
    {
      "city": "Mc Donough",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mc Donough, New York"
    },
    {
      "city": "Mount Upton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Upton, New York"
    },
    {
      "city": "North Norwich",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Norwich, New York"
    },
    {
      "city": "Norwich",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Norwich, New York"
    },
    {
      "city": "Oxford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oxford, New York"
    },
    {
      "city": "Plymouth",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plymouth, New York"
    },
    {
      "city": "Smithville Flats",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Smithville Flats, New York"
    },
    {
      "city": "South New Berlin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South New Berlin, New York"
    },
    {
      "city": "South Plymouth",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Plymouth, New York"
    },
    {
      "city": "Plattsburgh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plattsburgh, New York"
    },
    {
      "city": "Altona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Altona, New York"
    },
    {
      "city": "Keeseville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Keeseville, New York"
    },
    {
      "city": "Au Sable Forks",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Au Sable Forks, New York"
    },
    {
      "city": "Cadyville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cadyville, New York"
    },
    {
      "city": "Champlain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Champlain, New York"
    },
    {
      "city": "Chazy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chazy, New York"
    },
    {
      "city": "Churubusco",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Churubusco, New York"
    },
    {
      "city": "Dannemora",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dannemora, New York"
    },
    {
      "city": "Ellenburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellenburg, New York"
    },
    {
      "city": "Ellenburg Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellenburg Center, New York"
    },
    {
      "city": "Ellenburg Depot",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellenburg Depot, New York"
    },
    {
      "city": "Lyon Mountain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lyon Mountain, New York"
    },
    {
      "city": "Mooers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mooers, New York"
    },
    {
      "city": "Mooers Forks",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mooers Forks, New York"
    },
    {
      "city": "Morrisonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Morrisonville, New York"
    },
    {
      "city": "Peru",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Peru, New York"
    },
    {
      "city": "Redford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Redford, New York"
    },
    {
      "city": "Rouses Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rouses Point, New York"
    },
    {
      "city": "Saranac",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saranac, New York"
    },
    {
      "city": "Schuyler Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schuyler Falls, New York"
    },
    {
      "city": "West Chazy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Chazy, New York"
    },
    {
      "city": "Austerlitz",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Austerlitz, New York"
    },
    {
      "city": "Canaan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canaan, New York"
    },
    {
      "city": "Chatham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chatham, New York"
    },
    {
      "city": "Columbiaville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Columbiaville, New York"
    },
    {
      "city": "East Chatham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Chatham, New York"
    },
    {
      "city": "Ghent",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ghent, New York"
    },
    {
      "city": "Kinderhook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kinderhook, New York"
    },
    {
      "city": "Malden Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Malden Bridge, New York"
    },
    {
      "city": "New Lebanon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Lebanon, New York"
    },
    {
      "city": "Niverville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Niverville, New York"
    },
    {
      "city": "North Chatham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Chatham, New York"
    },
    {
      "city": "Old Chatham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Old Chatham, New York"
    },
    {
      "city": "Spencertown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spencertown, New York"
    },
    {
      "city": "Stottville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stottville, New York"
    },
    {
      "city": "Stuyvesant",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stuyvesant, New York"
    },
    {
      "city": "Stuyvesant Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stuyvesant Falls, New York"
    },
    {
      "city": "Valatie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Valatie, New York"
    },
    {
      "city": "West Lebanon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Lebanon, New York"
    },
    {
      "city": "Ancram",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ancram, New York"
    },
    {
      "city": "Ancramdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ancramdale, New York"
    },
    {
      "city": "Claverack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Claverack, New York"
    },
    {
      "city": "Copake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Copake, New York"
    },
    {
      "city": "Copake Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Copake Falls, New York"
    },
    {
      "city": "Craryville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Craryville, New York"
    },
    {
      "city": "Elizaville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elizaville, New York"
    },
    {
      "city": "Germantown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Germantown, New York"
    },
    {
      "city": "Hillsdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hillsdale, New York"
    },
    {
      "city": "Hollowville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hollowville, New York"
    },
    {
      "city": "Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hudson, New York"
    },
    {
      "city": "Livingston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Livingston, New York"
    },
    {
      "city": "Mellenville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mellenville, New York"
    },
    {
      "city": "Philmont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Philmont, New York"
    },
    {
      "city": "Cincinnatus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cincinnatus, New York"
    },
    {
      "city": "Cortland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cortland, New York"
    },
    {
      "city": "East Homer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Homer, New York"
    },
    {
      "city": "Homer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Homer, New York"
    },
    {
      "city": "Little York",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Little York, New York"
    },
    {
      "city": "Mc Graw",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mc Graw, New York"
    },
    {
      "city": "Preble",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Preble, New York"
    },
    {
      "city": "Truxton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Truxton, New York"
    },
    {
      "city": "Blodgett Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blodgett Mills, New York"
    },
    {
      "city": "Harford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harford, New York"
    },
    {
      "city": "Marathon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marathon, New York"
    },
    {
      "city": "Willet",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Willet, New York"
    },
    {
      "city": "Stamford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stamford, New York"
    },
    {
      "city": "Arkville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Arkville, New York"
    },
    {
      "city": "Denver",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Denver, New York"
    },
    {
      "city": "Fleischmanns",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fleischmanns, New York"
    },
    {
      "city": "Grand Gorge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Grand Gorge, New York"
    },
    {
      "city": "Halcottsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Halcottsville, New York"
    },
    {
      "city": "Margaretville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Margaretville, New York"
    },
    {
      "city": "New Kingston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Kingston, New York"
    },
    {
      "city": "Roxbury",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roxbury, New York"
    },
    {
      "city": "Andes",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Andes, New York"
    },
    {
      "city": "Bloomville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bloomville, New York"
    },
    {
      "city": "Bovina Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bovina Center, New York"
    },
    {
      "city": "Davenport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Davenport, New York"
    },
    {
      "city": "Davenport Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Davenport Center, New York"
    },
    {
      "city": "Delancey",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delancey, New York"
    },
    {
      "city": "Delhi",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delhi, New York"
    },
    {
      "city": "Downsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Downsville, New York"
    },
    {
      "city": "East Branch",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Branch, New York"
    },
    {
      "city": "East Meredith",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Meredith, New York"
    },
    {
      "city": "Fishs Eddy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fishs Eddy, New York"
    },
    {
      "city": "Franklin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Franklin, New York"
    },
    {
      "city": "Hamden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hamden, New York"
    },
    {
      "city": "Hancock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hancock, New York"
    },
    {
      "city": "Harpersfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harpersfield, New York"
    },
    {
      "city": "Hobart",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hobart, New York"
    },
    {
      "city": "Masonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Masonville, New York"
    },
    {
      "city": "Meridale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Meridale, New York"
    },
    {
      "city": "Sidney",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sidney, New York"
    },
    {
      "city": "Sidney Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sidney Center, New York"
    },
    {
      "city": "South Kortright",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Kortright, New York"
    },
    {
      "city": "Treadwell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Treadwell, New York"
    },
    {
      "city": "Trout Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Trout Creek, New York"
    },
    {
      "city": "Walton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Walton, New York"
    },
    {
      "city": "West Davenport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Davenport, New York"
    },
    {
      "city": "Amenia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Amenia, New York"
    },
    {
      "city": "Annandale On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Annandale On Hudson, New York"
    },
    {
      "city": "Bangall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bangall, New York"
    },
    {
      "city": "Barrytown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Barrytown, New York"
    },
    {
      "city": "Beacon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Beacon, New York"
    },
    {
      "city": "Billings",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Billings, New York"
    },
    {
      "city": "Castle Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Castle Point, New York"
    },
    {
      "city": "Chelsea",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chelsea, New York"
    },
    {
      "city": "Clinton Corners",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clinton Corners, New York"
    },
    {
      "city": "Dover Plains",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dover Plains, New York"
    },
    {
      "city": "Fishkill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fishkill, New York"
    },
    {
      "city": "Glenham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenham, New York"
    },
    {
      "city": "Holmes",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holmes, New York"
    },
    {
      "city": "Hopewell Junction",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hopewell Junction, New York"
    },
    {
      "city": "Hughsonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hughsonville, New York"
    },
    {
      "city": "Hyde Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hyde Park, New York"
    },
    {
      "city": "Lagrangeville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lagrangeville, New York"
    },
    {
      "city": "Millbrook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Millbrook, New York"
    },
    {
      "city": "Millerton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Millerton, New York"
    },
    {
      "city": "Pawling",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pawling, New York"
    },
    {
      "city": "Pine Plains",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine Plains, New York"
    },
    {
      "city": "Pleasant Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pleasant Valley, New York"
    },
    {
      "city": "Poughquag",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Poughquag, New York"
    },
    {
      "city": "Red Hook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Red Hook, New York"
    },
    {
      "city": "Rhinebeck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rhinebeck, New York"
    },
    {
      "city": "Rhinecliff",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rhinecliff, New York"
    },
    {
      "city": "Salt Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Salt Point, New York"
    },
    {
      "city": "Staatsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Staatsburg, New York"
    },
    {
      "city": "Stanfordville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stanfordville, New York"
    },
    {
      "city": "Stormville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stormville, New York"
    },
    {
      "city": "Tivoli",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tivoli, New York"
    },
    {
      "city": "Verbank",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Verbank, New York"
    },
    {
      "city": "Wappingers Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wappingers Falls, New York"
    },
    {
      "city": "Wassaic",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wassaic, New York"
    },
    {
      "city": "Wingdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wingdale, New York"
    },
    {
      "city": "Poughkeepsie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Poughkeepsie, New York"
    },
    {
      "city": "Akron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Akron, New York"
    },
    {
      "city": "Alden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alden, New York"
    },
    {
      "city": "Angola",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Angola, New York"
    },
    {
      "city": "Athol Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Athol Springs, New York"
    },
    {
      "city": "Boston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Boston, New York"
    },
    {
      "city": "Bowmansville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bowmansville, New York"
    },
    {
      "city": "Brant",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brant, New York"
    },
    {
      "city": "Chaffee",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chaffee, New York"
    },
    {
      "city": "Clarence",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clarence, New York"
    },
    {
      "city": "Clarence Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clarence Center, New York"
    },
    {
      "city": "Colden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Colden, New York"
    },
    {
      "city": "Collins",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Collins, New York"
    },
    {
      "city": "Collins Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Collins Center, New York"
    },
    {
      "city": "Crittenden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Crittenden, New York"
    },
    {
      "city": "Depew",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Depew, New York"
    },
    {
      "city": "Derby",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Derby, New York"
    },
    {
      "city": "East Amherst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Amherst, New York"
    },
    {
      "city": "East Aurora",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Aurora, New York"
    },
    {
      "city": "East Concord",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Concord, New York"
    },
    {
      "city": "Eden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eden, New York"
    },
    {
      "city": "Elma",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elma, New York"
    },
    {
      "city": "Farnham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Farnham, New York"
    },
    {
      "city": "Getzville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Getzville, New York"
    },
    {
      "city": "Glenwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenwood, New York"
    },
    {
      "city": "Grand Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Grand Island, New York"
    },
    {
      "city": "Hamburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hamburg, New York"
    },
    {
      "city": "Holland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holland, New York"
    },
    {
      "city": "Lake View",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake View, New York"
    },
    {
      "city": "Lancaster",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lancaster, New York"
    },
    {
      "city": "Lawtons",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lawtons, New York"
    },
    {
      "city": "Marilla",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marilla, New York"
    },
    {
      "city": "North Boston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Boston, New York"
    },
    {
      "city": "North Collins",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Collins, New York"
    },
    {
      "city": "North Evans",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Evans, New York"
    },
    {
      "city": "Orchard Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Orchard Park, New York"
    },
    {
      "city": "Sardinia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sardinia, New York"
    },
    {
      "city": "South Wales",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Wales, New York"
    },
    {
      "city": "Spring Brook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spring Brook, New York"
    },
    {
      "city": "Springville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Springville, New York"
    },
    {
      "city": "Tonawanda",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tonawanda, New York"
    },
    {
      "city": "Wales Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wales Center, New York"
    },
    {
      "city": "West Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Falls, New York"
    },
    {
      "city": "Buffalo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Buffalo, New York"
    },
    {
      "city": "Minerva",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Minerva, New York"
    },
    {
      "city": "Newcomb",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newcomb, New York"
    },
    {
      "city": "North Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Hudson, New York"
    },
    {
      "city": "Olmstedville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Olmstedville, New York"
    },
    {
      "city": "Paradox",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Paradox, New York"
    },
    {
      "city": "Schroon Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schroon Lake, New York"
    },
    {
      "city": "Severance",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Severance, New York"
    },
    {
      "city": "Ticonderoga",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ticonderoga, New York"
    },
    {
      "city": "Bloomingdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bloomingdale, New York"
    },
    {
      "city": "Crown Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Crown Point, New York"
    },
    {
      "city": "Elizabethtown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elizabethtown, New York"
    },
    {
      "city": "Essex",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Essex, New York"
    },
    {
      "city": "Jay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jay, New York"
    },
    {
      "city": "Keene",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Keene, New York"
    },
    {
      "city": "Keene Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Keene Valley, New York"
    },
    {
      "city": "Lake Placid",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Placid, New York"
    },
    {
      "city": "Lewis",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lewis, New York"
    },
    {
      "city": "Mineville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mineville, New York"
    },
    {
      "city": "Moriah",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Moriah, New York"
    },
    {
      "city": "Moriah Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Moriah Center, New York"
    },
    {
      "city": "New Russia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Russia, New York"
    },
    {
      "city": "Port Henry",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Henry, New York"
    },
    {
      "city": "Port Kent",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Kent, New York"
    },
    {
      "city": "Ray Brook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ray Brook, New York"
    },
    {
      "city": "Upper Jay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Upper Jay, New York"
    },
    {
      "city": "Westport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westport, New York"
    },
    {
      "city": "Willsboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Willsboro, New York"
    },
    {
      "city": "Wilmington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wilmington, New York"
    },
    {
      "city": "Witherbee",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Witherbee, New York"
    },
    {
      "city": "Bombay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bombay, New York"
    },
    {
      "city": "Brainardsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brainardsville, New York"
    },
    {
      "city": "Brushton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brushton, New York"
    },
    {
      "city": "Burke",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burke, New York"
    },
    {
      "city": "Chateaugay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chateaugay, New York"
    },
    {
      "city": "Constable",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Constable, New York"
    },
    {
      "city": "Dickinson Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dickinson Center, New York"
    },
    {
      "city": "Fort Covington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Covington, New York"
    },
    {
      "city": "Gabriels",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gabriels, New York"
    },
    {
      "city": "Lake Clear",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Clear, New York"
    },
    {
      "city": "Malone",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Malone, New York"
    },
    {
      "city": "Moira",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Moira, New York"
    },
    {
      "city": "North Bangor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Bangor, New York"
    },
    {
      "city": "Owls Head",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Owls Head, New York"
    },
    {
      "city": "Paul Smiths",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Paul Smiths, New York"
    },
    {
      "city": "Rainbow Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rainbow Lake, New York"
    },
    {
      "city": "Saint Regis Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saint Regis Falls, New York"
    },
    {
      "city": "Saranac Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saranac Lake, New York"
    },
    {
      "city": "Tupper Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tupper Lake, New York"
    },
    {
      "city": "Vermontville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Vermontville, New York"
    },
    {
      "city": "Whippleville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whippleville, New York"
    },
    {
      "city": "Hogansburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hogansburg, New York"
    },
    {
      "city": "Broadalbin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Broadalbin, New York"
    },
    {
      "city": "Caroga Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Caroga Lake, New York"
    },
    {
      "city": "Gloversville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gloversville, New York"
    },
    {
      "city": "Johnstown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Johnstown, New York"
    },
    {
      "city": "Mayfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mayfield, New York"
    },
    {
      "city": "Northville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Northville, New York"
    },
    {
      "city": "Stratford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stratford, New York"
    },
    {
      "city": "Alexander",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alexander, New York"
    },
    {
      "city": "Basom",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Basom, New York"
    },
    {
      "city": "Batavia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Batavia, New York"
    },
    {
      "city": "Corfu",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Corfu, New York"
    },
    {
      "city": "Darien Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Darien Center, New York"
    },
    {
      "city": "East Bethany",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Bethany, New York"
    },
    {
      "city": "East Pembroke",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Pembroke, New York"
    },
    {
      "city": "Elba",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elba, New York"
    },
    {
      "city": "Oakfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oakfield, New York"
    },
    {
      "city": "Stafford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stafford, New York"
    },
    {
      "city": "Bergen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bergen, New York"
    },
    {
      "city": "Byron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Byron, New York"
    },
    {
      "city": "Le Roy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Le Roy, New York"
    },
    {
      "city": "Linwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Linwood, New York"
    },
    {
      "city": "Pavilion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pavilion, New York"
    },
    {
      "city": "South Byron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Byron, New York"
    },
    {
      "city": "Athens",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Athens, New York"
    },
    {
      "city": "Climax",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Climax, New York"
    },
    {
      "city": "Coxsackie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Coxsackie, New York"
    },
    {
      "city": "Earlton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Earlton, New York"
    },
    {
      "city": "Greenville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenville, New York"
    },
    {
      "city": "Hannacroix",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hannacroix, New York"
    },
    {
      "city": "New Baltimore",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Baltimore, New York"
    },
    {
      "city": "Surprise",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Surprise, New York"
    },
    {
      "city": "West Coxsackie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Coxsackie, New York"
    },
    {
      "city": "Acra",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Acra, New York"
    },
    {
      "city": "Ashland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ashland, New York"
    },
    {
      "city": "Cairo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cairo, New York"
    },
    {
      "city": "Catskill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Catskill, New York"
    },
    {
      "city": "Cornwallville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cornwallville, New York"
    },
    {
      "city": "Durham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Durham, New York"
    },
    {
      "city": "East Durham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Durham, New York"
    },
    {
      "city": "East Jewett",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Jewett, New York"
    },
    {
      "city": "Elka Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elka Park, New York"
    },
    {
      "city": "Freehold",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Freehold, New York"
    },
    {
      "city": "Haines Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Haines Falls, New York"
    },
    {
      "city": "Hensonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hensonville, New York"
    },
    {
      "city": "Hunter",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hunter, New York"
    },
    {
      "city": "Jewett",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jewett, New York"
    },
    {
      "city": "Lanesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lanesville, New York"
    },
    {
      "city": "Leeds",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Leeds, New York"
    },
    {
      "city": "Lexington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lexington, New York"
    },
    {
      "city": "Maplecrest",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maplecrest, New York"
    },
    {
      "city": "Oak Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oak Hill, New York"
    },
    {
      "city": "Palenville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Palenville, New York"
    },
    {
      "city": "Prattsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Prattsville, New York"
    },
    {
      "city": "Purling",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Purling, New York"
    },
    {
      "city": "Round Top",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Round Top, New York"
    },
    {
      "city": "South Cairo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Cairo, New York"
    },
    {
      "city": "Tannersville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tannersville, New York"
    },
    {
      "city": "West Kill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Kill, New York"
    },
    {
      "city": "Windham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Windham, New York"
    },
    {
      "city": "Lake Pleasant",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Pleasant, New York"
    },
    {
      "city": "Piseco",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Piseco, New York"
    },
    {
      "city": "Speculator",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Speculator, New York"
    },
    {
      "city": "Wells",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wells, New York"
    },
    {
      "city": "Blue Mountain Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blue Mountain Lake, New York"
    },
    {
      "city": "Indian Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Indian Lake, New York"
    },
    {
      "city": "Long Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Long Lake, New York"
    },
    {
      "city": "Sabael",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sabael, New York"
    },
    {
      "city": "Hoffmeister",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hoffmeister, New York"
    },
    {
      "city": "Inlet",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Inlet, New York"
    },
    {
      "city": "Raquette Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Raquette Lake, New York"
    },
    {
      "city": "Cold Brook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cold Brook, New York"
    },
    {
      "city": "Dolgeville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dolgeville, New York"
    },
    {
      "city": "Eagle Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eagle Bay, New York"
    },
    {
      "city": "Frankfort",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Frankfort, New York"
    },
    {
      "city": "Herkimer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Herkimer, New York"
    },
    {
      "city": "Ilion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ilion, New York"
    },
    {
      "city": "Jordanville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jordanville, New York"
    },
    {
      "city": "Little Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Little Falls, New York"
    },
    {
      "city": "Middleville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middleville, New York"
    },
    {
      "city": "Mohawk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mohawk, New York"
    },
    {
      "city": "Newport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newport, New York"
    },
    {
      "city": "Old Forge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Old Forge, New York"
    },
    {
      "city": "Poland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Poland, New York"
    },
    {
      "city": "Salisbury Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Salisbury Center, New York"
    },
    {
      "city": "Thendara",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thendara, New York"
    },
    {
      "city": "Van Hornesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Van Hornesville, New York"
    },
    {
      "city": "West Winfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Winfield, New York"
    },
    {
      "city": "Watertown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Watertown, New York"
    },
    {
      "city": "Fort Drum",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Drum, New York"
    },
    {
      "city": "Adams",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Adams, New York"
    },
    {
      "city": "Adams Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Adams Center, New York"
    },
    {
      "city": "Alexandria Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alexandria Bay, New York"
    },
    {
      "city": "Antwerp",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Antwerp, New York"
    },
    {
      "city": "Belleville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Belleville, New York"
    },
    {
      "city": "Black River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Black River, New York"
    },
    {
      "city": "Brownville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brownville, New York"
    },
    {
      "city": "Calcium",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Calcium, New York"
    },
    {
      "city": "Cape Vincent",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cape Vincent, New York"
    },
    {
      "city": "Carthage",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Carthage, New York"
    },
    {
      "city": "Chaumont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chaumont, New York"
    },
    {
      "city": "Clayton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clayton, New York"
    },
    {
      "city": "Deferiet",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Deferiet, New York"
    },
    {
      "city": "Depauville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Depauville, New York"
    },
    {
      "city": "Dexter",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dexter, New York"
    },
    {
      "city": "Ellisburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellisburg, New York"
    },
    {
      "city": "Evans Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Evans Mills, New York"
    },
    {
      "city": "Felts Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Felts Mills, New York"
    },
    {
      "city": "Wellesley Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wellesley Island, New York"
    },
    {
      "city": "Fishers Landing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fishers Landing, New York"
    },
    {
      "city": "Great Bend",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Great Bend, New York"
    },
    {
      "city": "Henderson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Henderson, New York"
    },
    {
      "city": "Henderson Harbor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Henderson Harbor, New York"
    },
    {
      "city": "La Fargeville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "La Fargeville, New York"
    },
    {
      "city": "Limerick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Limerick, New York"
    },
    {
      "city": "Lorraine",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lorraine, New York"
    },
    {
      "city": "Mannsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mannsville, New York"
    },
    {
      "city": "Natural Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Natural Bridge, New York"
    },
    {
      "city": "Oxbow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oxbow, New York"
    },
    {
      "city": "Philadelphia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Philadelphia, New York"
    },
    {
      "city": "Pierrepont Manor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pierrepont Manor, New York"
    },
    {
      "city": "Plessis",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plessis, New York"
    },
    {
      "city": "Redwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Redwood, New York"
    },
    {
      "city": "Rodman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rodman, New York"
    },
    {
      "city": "Sackets Harbor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sackets Harbor, New York"
    },
    {
      "city": "Theresa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Theresa, New York"
    },
    {
      "city": "Thousand Island Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thousand Island Park, New York"
    },
    {
      "city": "Three Mile Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Three Mile Bay, New York"
    },
    {
      "city": "Brooklyn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brooklyn, New York"
    },
    {
      "city": "Beaver Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Beaver Falls, New York"
    },
    {
      "city": "Brantingham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brantingham, New York"
    },
    {
      "city": "Constableville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Constableville, New York"
    },
    {
      "city": "Croghan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Croghan, New York"
    },
    {
      "city": "Glenfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenfield, New York"
    },
    {
      "city": "Greig",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greig, New York"
    },
    {
      "city": "Lowville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lowville, New York"
    },
    {
      "city": "Lyons Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lyons Falls, New York"
    },
    {
      "city": "Martinsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Martinsburg, New York"
    },
    {
      "city": "Port Leyden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Leyden, New York"
    },
    {
      "city": "Turin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Turin, New York"
    },
    {
      "city": "West Leyden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Leyden, New York"
    },
    {
      "city": "Castorland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Castorland, New York"
    },
    {
      "city": "Copenhagen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Copenhagen, New York"
    },
    {
      "city": "Deer River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Deer River, New York"
    },
    {
      "city": "Denmark",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Denmark, New York"
    },
    {
      "city": "Harrisville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harrisville, New York"
    },
    {
      "city": "Avon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Avon, New York"
    },
    {
      "city": "Caledonia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Caledonia, New York"
    },
    {
      "city": "Conesus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Conesus, New York"
    },
    {
      "city": "Dansville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dansville, New York"
    },
    {
      "city": "Geneseo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Geneseo, New York"
    },
    {
      "city": "Groveland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Groveland, New York"
    },
    {
      "city": "Hemlock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hemlock, New York"
    },
    {
      "city": "Lakeville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lakeville, New York"
    },
    {
      "city": "Leicester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Leicester, New York"
    },
    {
      "city": "Lima",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lima, New York"
    },
    {
      "city": "Livonia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Livonia, New York"
    },
    {
      "city": "Livonia Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Livonia Center, New York"
    },
    {
      "city": "Mount Morris",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Morris, New York"
    },
    {
      "city": "Nunda",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nunda, New York"
    },
    {
      "city": "Piffard",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Piffard, New York"
    },
    {
      "city": "Retsof",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Retsof, New York"
    },
    {
      "city": "Scottsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Scottsburg, New York"
    },
    {
      "city": "Sonyea",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sonyea, New York"
    },
    {
      "city": "South Lima",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Lima, New York"
    },
    {
      "city": "Springwater",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Springwater, New York"
    },
    {
      "city": "York",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "York, New York"
    },
    {
      "city": "Dalton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dalton, New York"
    },
    {
      "city": "Hunt",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hunt, New York"
    },
    {
      "city": "Canastota",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canastota, New York"
    },
    {
      "city": "Cazenovia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cazenovia, New York"
    },
    {
      "city": "Chittenango",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chittenango, New York"
    },
    {
      "city": "Clockville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clockville, New York"
    },
    {
      "city": "De Ruyter",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "De Ruyter, New York"
    },
    {
      "city": "Erieville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Erieville, New York"
    },
    {
      "city": "Georgetown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Georgetown, New York"
    },
    {
      "city": "New Woodstock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Woodstock, New York"
    },
    {
      "city": "Peterboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Peterboro, New York"
    },
    {
      "city": "Wampsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wampsville, New York"
    },
    {
      "city": "Bouckville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bouckville, New York"
    },
    {
      "city": "Brookfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brookfield, New York"
    },
    {
      "city": "Eaton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eaton, New York"
    },
    {
      "city": "Hamilton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hamilton, New York"
    },
    {
      "city": "Hubbardsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hubbardsville, New York"
    },
    {
      "city": "Leonardsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Leonardsville, New York"
    },
    {
      "city": "Madison",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Madison, New York"
    },
    {
      "city": "Morrisville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Morrisville, New York"
    },
    {
      "city": "Munnsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Munnsville, New York"
    },
    {
      "city": "North Brookfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Brookfield, New York"
    },
    {
      "city": "Oneida",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oneida, New York"
    },
    {
      "city": "Solsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Solsville, New York"
    },
    {
      "city": "West Eaton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Eaton, New York"
    },
    {
      "city": "Adams Basin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Adams Basin, New York"
    },
    {
      "city": "Brockport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brockport, New York"
    },
    {
      "city": "Churchville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Churchville, New York"
    },
    {
      "city": "Clarkson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clarkson, New York"
    },
    {
      "city": "East Rochester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Rochester, New York"
    },
    {
      "city": "Fairport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fairport, New York"
    },
    {
      "city": "Hamlin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hamlin, New York"
    },
    {
      "city": "Henrietta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Henrietta, New York"
    },
    {
      "city": "Hilton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hilton, New York"
    },
    {
      "city": "Honeoye Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Honeoye Falls, New York"
    },
    {
      "city": "Mendon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mendon, New York"
    },
    {
      "city": "Mumford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mumford, New York"
    },
    {
      "city": "North Chili",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Chili, New York"
    },
    {
      "city": "North Greece",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Greece, New York"
    },
    {
      "city": "Penfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Penfield, New York"
    },
    {
      "city": "Pittsford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pittsford, New York"
    },
    {
      "city": "Rush",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rush, New York"
    },
    {
      "city": "Scottsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Scottsville, New York"
    },
    {
      "city": "Spencerport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spencerport, New York"
    },
    {
      "city": "Webster",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Webster, New York"
    },
    {
      "city": "West Henrietta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Henrietta, New York"
    },
    {
      "city": "Rochester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rochester, New York"
    },
    {
      "city": "Amsterdam",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Amsterdam, New York"
    },
    {
      "city": "Auriesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Auriesville, New York"
    },
    {
      "city": "Esperance",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Esperance, New York"
    },
    {
      "city": "Fonda",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fonda, New York"
    },
    {
      "city": "Fort Hunter",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Hunter, New York"
    },
    {
      "city": "Fort Johnson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Johnson, New York"
    },
    {
      "city": "Fultonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fultonville, New York"
    },
    {
      "city": "Hagaman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hagaman, New York"
    },
    {
      "city": "Sprakers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sprakers, New York"
    },
    {
      "city": "Tribes Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tribes Hill, New York"
    },
    {
      "city": "Canajoharie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canajoharie, New York"
    },
    {
      "city": "Fort Plain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Plain, New York"
    },
    {
      "city": "Nelliston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nelliston, New York"
    },
    {
      "city": "Palatine Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Palatine Bridge, New York"
    },
    {
      "city": "Saint Johnsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saint Johnsville, New York"
    },
    {
      "city": "Floral Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Floral Park, New York"
    },
    {
      "city": "Elmont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elmont, New York"
    },
    {
      "city": "Franklin Square",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Franklin Square, New York"
    },
    {
      "city": "Great Neck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Great Neck, New York"
    },
    {
      "city": "Manhasset",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Manhasset, New York"
    },
    {
      "city": "New Hyde Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Hyde Park, New York"
    },
    {
      "city": "Port Washington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Washington, New York"
    },
    {
      "city": "Inwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Inwood, New York"
    },
    {
      "city": "Mineola",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mineola, New York"
    },
    {
      "city": "Albertson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Albertson, New York"
    },
    {
      "city": "Atlantic Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Atlantic Beach, New York"
    },
    {
      "city": "Baldwin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Baldwin, New York"
    },
    {
      "city": "Carle Place",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Carle Place, New York"
    },
    {
      "city": "Cedarhurst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cedarhurst, New York"
    },
    {
      "city": "East Rockaway",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Rockaway, New York"
    },
    {
      "city": "Freeport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Freeport, New York"
    },
    {
      "city": "Garden City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Garden City, New York"
    },
    {
      "city": "Glen Cove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Cove, New York"
    },
    {
      "city": "Glen Head",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Head, New York"
    },
    {
      "city": "Glenwood Landing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenwood Landing, New York"
    },
    {
      "city": "Greenvale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenvale, New York"
    },
    {
      "city": "Hempstead",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hempstead, New York"
    },
    {
      "city": "West Hempstead",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Hempstead, New York"
    },
    {
      "city": "Uniondale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Uniondale, New York"
    },
    {
      "city": "East Meadow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Meadow, New York"
    },
    {
      "city": "Hewlett",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hewlett, New York"
    },
    {
      "city": "Island Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Island Park, New York"
    },
    {
      "city": "Lawrence",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lawrence, New York"
    },
    {
      "city": "Locust Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Locust Valley, New York"
    },
    {
      "city": "Long Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Long Beach, New York"
    },
    {
      "city": "Lynbrook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lynbrook, New York"
    },
    {
      "city": "Malverne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Malverne, New York"
    },
    {
      "city": "Merrick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Merrick, New York"
    },
    {
      "city": "Old Westbury",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Old Westbury, New York"
    },
    {
      "city": "Point Lookout",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Point Lookout, New York"
    },
    {
      "city": "Rockville Centre",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rockville Centre, New York"
    },
    {
      "city": "Oceanside",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oceanside, New York"
    },
    {
      "city": "Roosevelt",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roosevelt, New York"
    },
    {
      "city": "Roslyn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roslyn, New York"
    },
    {
      "city": "Roslyn Heights",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roslyn Heights, New York"
    },
    {
      "city": "Sea Cliff",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sea Cliff, New York"
    },
    {
      "city": "Valley Stream",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Valley Stream, New York"
    },
    {
      "city": "Westbury",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westbury, New York"
    },
    {
      "city": "Williston Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Williston Park, New York"
    },
    {
      "city": "Woodmere",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodmere, New York"
    },
    {
      "city": "Bayville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bayville, New York"
    },
    {
      "city": "Bellmore",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bellmore, New York"
    },
    {
      "city": "Bethpage",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bethpage, New York"
    },
    {
      "city": "East Norwich",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Norwich, New York"
    },
    {
      "city": "Farmingdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Farmingdale, New York"
    },
    {
      "city": "Jericho",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jericho, New York"
    },
    {
      "city": "Levittown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Levittown, New York"
    },
    {
      "city": "Massapequa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Massapequa, New York"
    },
    {
      "city": "Massapequa Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Massapequa Park, New York"
    },
    {
      "city": "Mill Neck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mill Neck, New York"
    },
    {
      "city": "Oyster Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oyster Bay, New York"
    },
    {
      "city": "Syosset",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Syosset, New York"
    },
    {
      "city": "Seaford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Seaford, New York"
    },
    {
      "city": "Wantagh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wantagh, New York"
    },
    {
      "city": "Woodbury",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodbury, New York"
    },
    {
      "city": "Hicksville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hicksville, New York"
    },
    {
      "city": "Plainview",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plainview, New York"
    },
    {
      "city": "Old Bethpage",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Old Bethpage, New York"
    },
    {
      "city": "New York",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New York, New York"
    },
    {
      "city": "Appleton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Appleton, New York"
    },
    {
      "city": "Barker",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Barker, New York"
    },
    {
      "city": "Burt",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burt, New York"
    },
    {
      "city": "Gasport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gasport, New York"
    },
    {
      "city": "Lewiston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lewiston, New York"
    },
    {
      "city": "Lockport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lockport, New York"
    },
    {
      "city": "Middleport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middleport, New York"
    },
    {
      "city": "Model City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Model City, New York"
    },
    {
      "city": "Newfane",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newfane, New York"
    },
    {
      "city": "Niagara University",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Niagara University, New York"
    },
    {
      "city": "North Tonawanda",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Tonawanda, New York"
    },
    {
      "city": "Olcott",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Olcott, New York"
    },
    {
      "city": "Ransomville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ransomville, New York"
    },
    {
      "city": "Sanborn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sanborn, New York"
    },
    {
      "city": "Stella Niagara",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stella Niagara, New York"
    },
    {
      "city": "Wilson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wilson, New York"
    },
    {
      "city": "Youngstown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Youngstown, New York"
    },
    {
      "city": "Niagara Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Niagara Falls, New York"
    },
    {
      "city": "Durhamville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Durhamville, New York"
    },
    {
      "city": "North Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Bay, New York"
    },
    {
      "city": "Sylvan Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sylvan Beach, New York"
    },
    {
      "city": "Verona Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Verona Beach, New York"
    },
    {
      "city": "Alder Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alder Creek, New York"
    },
    {
      "city": "Ava",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ava, New York"
    },
    {
      "city": "Barneveld",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Barneveld, New York"
    },
    {
      "city": "Blossvale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blossvale, New York"
    },
    {
      "city": "Boonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Boonville, New York"
    },
    {
      "city": "Bridgewater",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bridgewater, New York"
    },
    {
      "city": "Camden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Camden, New York"
    },
    {
      "city": "Cassville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cassville, New York"
    },
    {
      "city": "Chadwicks",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chadwicks, New York"
    },
    {
      "city": "Clark Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clark Mills, New York"
    },
    {
      "city": "Clayville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clayville, New York"
    },
    {
      "city": "Clinton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clinton, New York"
    },
    {
      "city": "Deansboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Deansboro, New York"
    },
    {
      "city": "Forestport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Forestport, New York"
    },
    {
      "city": "Franklin Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Franklin Springs, New York"
    },
    {
      "city": "Hinckley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hinckley, New York"
    },
    {
      "city": "Holland Patent",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holland Patent, New York"
    },
    {
      "city": "Knoxboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Knoxboro, New York"
    },
    {
      "city": "Lee Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lee Center, New York"
    },
    {
      "city": "Mc Connellsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mc Connellsville, New York"
    },
    {
      "city": "Marcy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marcy, New York"
    },
    {
      "city": "New Hartford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Hartford, New York"
    },
    {
      "city": "New York Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New York Mills, New York"
    },
    {
      "city": "Oriskany",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oriskany, New York"
    },
    {
      "city": "Oriskany Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oriskany Falls, New York"
    },
    {
      "city": "Prospect",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Prospect, New York"
    },
    {
      "city": "Remsen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Remsen, New York"
    },
    {
      "city": "Rome",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rome, New York"
    },
    {
      "city": "Sangerfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sangerfield, New York"
    },
    {
      "city": "Sauquoit",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sauquoit, New York"
    },
    {
      "city": "Sherrill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sherrill, New York"
    },
    {
      "city": "Stittville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stittville, New York"
    },
    {
      "city": "Taberg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Taberg, New York"
    },
    {
      "city": "Vernon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Vernon, New York"
    },
    {
      "city": "Vernon Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Vernon Center, New York"
    },
    {
      "city": "Verona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Verona, New York"
    },
    {
      "city": "Washington Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Washington Mills, New York"
    },
    {
      "city": "Waterville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waterville, New York"
    },
    {
      "city": "Westdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westdale, New York"
    },
    {
      "city": "Westernville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westernville, New York"
    },
    {
      "city": "Westmoreland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westmoreland, New York"
    },
    {
      "city": "Whitesboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whitesboro, New York"
    },
    {
      "city": "Woodgate",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodgate, New York"
    },
    {
      "city": "Yorkville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yorkville, New York"
    },
    {
      "city": "Utica",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Utica, New York"
    },
    {
      "city": "Apulia Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Apulia Station, New York"
    },
    {
      "city": "Baldwinsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Baldwinsville, New York"
    },
    {
      "city": "Brewerton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brewerton, New York"
    },
    {
      "city": "Bridgeport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bridgeport, New York"
    },
    {
      "city": "Camillus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Camillus, New York"
    },
    {
      "city": "Cicero",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cicero, New York"
    },
    {
      "city": "Clay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clay, New York"
    },
    {
      "city": "Delphi Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delphi Falls, New York"
    },
    {
      "city": "East Syracuse",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Syracuse, New York"
    },
    {
      "city": "Elbridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elbridge, New York"
    },
    {
      "city": "Fabius",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fabius, New York"
    },
    {
      "city": "Fayetteville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fayetteville, New York"
    },
    {
      "city": "Jamesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jamesville, New York"
    },
    {
      "city": "Jordan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jordan, New York"
    },
    {
      "city": "Kirkville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kirkville, New York"
    },
    {
      "city": "La Fayette",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "La Fayette, New York"
    },
    {
      "city": "Liverpool",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Liverpool, New York"
    },
    {
      "city": "Manlius",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Manlius, New York"
    },
    {
      "city": "Marcellus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marcellus, New York"
    },
    {
      "city": "Marietta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marietta, New York"
    },
    {
      "city": "Memphis",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Memphis, New York"
    },
    {
      "city": "Minoa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Minoa, New York"
    },
    {
      "city": "Mottville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mottville, New York"
    },
    {
      "city": "Nedrow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nedrow, New York"
    },
    {
      "city": "Plainville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plainville, New York"
    },
    {
      "city": "Pompey",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pompey, New York"
    },
    {
      "city": "Skaneateles",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Skaneateles, New York"
    },
    {
      "city": "Skaneateles Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Skaneateles Falls, New York"
    },
    {
      "city": "Tully",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tully, New York"
    },
    {
      "city": "Warners",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Warners, New York"
    },
    {
      "city": "Syracuse",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Syracuse, New York"
    },
    {
      "city": "Canandaigua",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canandaigua, New York"
    },
    {
      "city": "Farmington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Farmington, New York"
    },
    {
      "city": "Clifton Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clifton Springs, New York"
    },
    {
      "city": "East Bloomfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Bloomfield, New York"
    },
    {
      "city": "Fishers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fishers, New York"
    },
    {
      "city": "Geneva",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Geneva, New York"
    },
    {
      "city": "Gorham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gorham, New York"
    },
    {
      "city": "Hall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hall, New York"
    },
    {
      "city": "Bloomfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bloomfield, New York"
    },
    {
      "city": "Honeoye",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Honeoye, New York"
    },
    {
      "city": "Ionia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ionia, New York"
    },
    {
      "city": "Manchester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Manchester, New York"
    },
    {
      "city": "Naples",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Naples, New York"
    },
    {
      "city": "Oaks Corners",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oaks Corners, New York"
    },
    {
      "city": "Phelps",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Phelps, New York"
    },
    {
      "city": "Port Gibson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Gibson, New York"
    },
    {
      "city": "Seneca Castle",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Seneca Castle, New York"
    },
    {
      "city": "Shortsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shortsville, New York"
    },
    {
      "city": "Stanley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stanley, New York"
    },
    {
      "city": "Victor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Victor, New York"
    },
    {
      "city": "West Bloomfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Bloomfield, New York"
    },
    {
      "city": "Arden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Arden, New York"
    },
    {
      "city": "Bellvale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bellvale, New York"
    },
    {
      "city": "Blooming Grove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blooming Grove, New York"
    },
    {
      "city": "Bullville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bullville, New York"
    },
    {
      "city": "Campbell Hall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Campbell Hall, New York"
    },
    {
      "city": "Central Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Central Valley, New York"
    },
    {
      "city": "Chester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chester, New York"
    },
    {
      "city": "Circleville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Circleville, New York"
    },
    {
      "city": "Florida",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Florida, New York"
    },
    {
      "city": "Fort Montgomery",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Montgomery, New York"
    },
    {
      "city": "Goshen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Goshen, New York"
    },
    {
      "city": "Greenwood Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenwood Lake, New York"
    },
    {
      "city": "Harriman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harriman, New York"
    },
    {
      "city": "Highland Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Highland Falls, New York"
    },
    {
      "city": "Highland Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Highland Mills, New York"
    },
    {
      "city": "Howells",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Howells, New York"
    },
    {
      "city": "Johnson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Johnson, New York"
    },
    {
      "city": "Middletown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middletown, New York"
    },
    {
      "city": "Monroe",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Monroe, New York"
    },
    {
      "city": "Mountainville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mountainville, New York"
    },
    {
      "city": "New Hampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Hampton, New York"
    },
    {
      "city": "New Milford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Milford, New York"
    },
    {
      "city": "Otisville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Otisville, New York"
    },
    {
      "city": "Pine Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine Island, New York"
    },
    {
      "city": "Slate Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Slate Hill, New York"
    },
    {
      "city": "Southfields",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Southfields, New York"
    },
    {
      "city": "Sterling Forest",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sterling Forest, New York"
    },
    {
      "city": "Sugar Loaf",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sugar Loaf, New York"
    },
    {
      "city": "Thompson Ridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thompson Ridge, New York"
    },
    {
      "city": "Tuxedo Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tuxedo Park, New York"
    },
    {
      "city": "Unionville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Unionville, New York"
    },
    {
      "city": "Warwick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Warwick, New York"
    },
    {
      "city": "Washingtonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Washingtonville, New York"
    },
    {
      "city": "West Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Point, New York"
    },
    {
      "city": "Westtown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westtown, New York"
    },
    {
      "city": "Cornwall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cornwall, New York"
    },
    {
      "city": "Cornwall On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cornwall On Hudson, New York"
    },
    {
      "city": "Maybrook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maybrook, New York"
    },
    {
      "city": "Montgomery",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Montgomery, New York"
    },
    {
      "city": "Newburgh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newburgh, New York"
    },
    {
      "city": "New Windsor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Windsor, New York"
    },
    {
      "city": "Pine Bush",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine Bush, New York"
    },
    {
      "city": "Rock Tavern",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rock Tavern, New York"
    },
    {
      "city": "Salisbury Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Salisbury Mills, New York"
    },
    {
      "city": "Vails Gate",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Vails Gate, New York"
    },
    {
      "city": "Walden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Walden, New York"
    },
    {
      "city": "Cuddebackville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cuddebackville, New York"
    },
    {
      "city": "Huguenot",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Huguenot, New York"
    },
    {
      "city": "Port Jervis",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Jervis, New York"
    },
    {
      "city": "Sparrow Bush",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sparrow Bush, New York"
    },
    {
      "city": "Lyndonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lyndonville, New York"
    },
    {
      "city": "Medina",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Medina, New York"
    },
    {
      "city": "Albion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Albion, New York"
    },
    {
      "city": "Clarendon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clarendon, New York"
    },
    {
      "city": "Fancher",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fancher, New York"
    },
    {
      "city": "Holley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holley, New York"
    },
    {
      "city": "Kendall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kendall, New York"
    },
    {
      "city": "Kent",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kent, New York"
    },
    {
      "city": "Knowlesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Knowlesville, New York"
    },
    {
      "city": "Morton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Morton, New York"
    },
    {
      "city": "Waterport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waterport, New York"
    },
    {
      "city": "Bernhards Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bernhards Bay, New York"
    },
    {
      "city": "Central Square",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Central Square, New York"
    },
    {
      "city": "Cleveland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cleveland, New York"
    },
    {
      "city": "Constantia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Constantia, New York"
    },
    {
      "city": "Fair Haven",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fair Haven, New York"
    },
    {
      "city": "Fulton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fulton, New York"
    },
    {
      "city": "Hannibal",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hannibal, New York"
    },
    {
      "city": "Hastings",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hastings, New York"
    },
    {
      "city": "Lacona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lacona, New York"
    },
    {
      "city": "Lycoming",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lycoming, New York"
    },
    {
      "city": "Mallory",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mallory, New York"
    },
    {
      "city": "Maple View",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maple View, New York"
    },
    {
      "city": "Mexico",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mexico, New York"
    },
    {
      "city": "Minetto",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Minetto, New York"
    },
    {
      "city": "New Haven",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Haven, New York"
    },
    {
      "city": "Oswego",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oswego, New York"
    },
    {
      "city": "Parish",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Parish, New York"
    },
    {
      "city": "Pennellville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pennellville, New York"
    },
    {
      "city": "Phoenix",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Phoenix, New York"
    },
    {
      "city": "Pulaski",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pulaski, New York"
    },
    {
      "city": "Richland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richland, New York"
    },
    {
      "city": "Sandy Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sandy Creek, New York"
    },
    {
      "city": "West Monroe",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Monroe, New York"
    },
    {
      "city": "Altmar",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Altmar, New York"
    },
    {
      "city": "Orwell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Orwell, New York"
    },
    {
      "city": "Redfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Redfield, New York"
    },
    {
      "city": "Williamstown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Williamstown, New York"
    },
    {
      "city": "East Worcester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Worcester, New York"
    },
    {
      "city": "Maryland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maryland, New York"
    },
    {
      "city": "Schenevus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schenevus, New York"
    },
    {
      "city": "Worcester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Worcester, New York"
    },
    {
      "city": "Burlington Flats",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burlington Flats, New York"
    },
    {
      "city": "Cherry Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cherry Valley, New York"
    },
    {
      "city": "Cooperstown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cooperstown, New York"
    },
    {
      "city": "East Springfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Springfield, New York"
    },
    {
      "city": "Edmeston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Edmeston, New York"
    },
    {
      "city": "Fly Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fly Creek, New York"
    },
    {
      "city": "Garrattsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Garrattsville, New York"
    },
    {
      "city": "Hartwick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hartwick, New York"
    },
    {
      "city": "New Lisbon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Lisbon, New York"
    },
    {
      "city": "Richfield Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richfield Springs, New York"
    },
    {
      "city": "Roseboom",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roseboom, New York"
    },
    {
      "city": "Schuyler Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schuyler Lake, New York"
    },
    {
      "city": "Springfield Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Springfield Center, New York"
    },
    {
      "city": "West Burlington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Burlington, New York"
    },
    {
      "city": "West Edmeston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Edmeston, New York"
    },
    {
      "city": "Westford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westford, New York"
    },
    {
      "city": "Colliersville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Colliersville, New York"
    },
    {
      "city": "Gilbertsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gilbertsville, New York"
    },
    {
      "city": "Laurens",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Laurens, New York"
    },
    {
      "city": "Milford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Milford, New York"
    },
    {
      "city": "Morris",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Morris, New York"
    },
    {
      "city": "Mount Vision",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Vision, New York"
    },
    {
      "city": "Oneonta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oneonta, New York"
    },
    {
      "city": "Otego",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Otego, New York"
    },
    {
      "city": "Portlandville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Portlandville, New York"
    },
    {
      "city": "Unadilla",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Unadilla, New York"
    },
    {
      "city": "Wells Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wells Bridge, New York"
    },
    {
      "city": "West Oneonta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Oneonta, New York"
    },
    {
      "city": "Brewster",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brewster, New York"
    },
    {
      "city": "Carmel",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Carmel, New York"
    },
    {
      "city": "Cold Spring",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cold Spring, New York"
    },
    {
      "city": "Garrison",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Garrison, New York"
    },
    {
      "city": "Lake Peekskill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Peekskill, New York"
    },
    {
      "city": "Mahopac",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mahopac, New York"
    },
    {
      "city": "Mahopac Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mahopac Falls, New York"
    },
    {
      "city": "Putnam Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Putnam Valley, New York"
    },
    {
      "city": "Patterson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Patterson, New York"
    },
    {
      "city": "Glen Oaks",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Oaks, New York"
    },
    {
      "city": "Long Island City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Long Island City, New York"
    },
    {
      "city": "Astoria",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Astoria, New York"
    },
    {
      "city": "Sunnyside",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sunnyside, New York"
    },
    {
      "city": "Flushing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Flushing, New York"
    },
    {
      "city": "College Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "College Point, New York"
    },
    {
      "city": "Whitestone",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whitestone, New York"
    },
    {
      "city": "Bayside",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bayside, New York"
    },
    {
      "city": "Little Neck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Little Neck, New York"
    },
    {
      "city": "Oakland Gardens",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oakland Gardens, New York"
    },
    {
      "city": "Fresh Meadows",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fresh Meadows, New York"
    },
    {
      "city": "Corona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Corona, New York"
    },
    {
      "city": "East Elmhurst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Elmhurst, New York"
    },
    {
      "city": "Jackson Heights",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jackson Heights, New York"
    },
    {
      "city": "Elmhurst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elmhurst, New York"
    },
    {
      "city": "Rego Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rego Park, New York"
    },
    {
      "city": "Forest Hills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Forest Hills, New York"
    },
    {
      "city": "Woodside",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodside, New York"
    },
    {
      "city": "Maspeth",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maspeth, New York"
    },
    {
      "city": "Middle Village",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middle Village, New York"
    },
    {
      "city": "Ridgewood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ridgewood, New York"
    },
    {
      "city": "Jamaica",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jamaica, New York"
    },
    {
      "city": "Cambria Heights",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cambria Heights, New York"
    },
    {
      "city": "Saint Albans",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saint Albans, New York"
    },
    {
      "city": "Springfield Gardens",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Springfield Gardens, New York"
    },
    {
      "city": "Howard Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Howard Beach, New York"
    },
    {
      "city": "Kew Gardens",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kew Gardens, New York"
    },
    {
      "city": "Ozone Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ozone Park, New York"
    },
    {
      "city": "Richmond Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richmond Hill, New York"
    },
    {
      "city": "South Richmond Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Richmond Hill, New York"
    },
    {
      "city": "South Ozone Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Ozone Park, New York"
    },
    {
      "city": "Woodhaven",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodhaven, New York"
    },
    {
      "city": "Rosedale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rosedale, New York"
    },
    {
      "city": "Hollis",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hollis, New York"
    },
    {
      "city": "Bellerose",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bellerose, New York"
    },
    {
      "city": "Queens Village",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Queens Village, New York"
    },
    {
      "city": "Far Rockaway",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Far Rockaway, New York"
    },
    {
      "city": "Arverne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Arverne, New York"
    },
    {
      "city": "Rockaway Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rockaway Park, New York"
    },
    {
      "city": "Breezy Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Breezy Point, New York"
    },
    {
      "city": "Averill Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Averill Park, New York"
    },
    {
      "city": "Berlin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Berlin, New York"
    },
    {
      "city": "Brainard",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brainard, New York"
    },
    {
      "city": "Buskirk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Buskirk, New York"
    },
    {
      "city": "Castleton On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Castleton On Hudson, New York"
    },
    {
      "city": "Cherry Plain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cherry Plain, New York"
    },
    {
      "city": "Cropseyville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cropseyville, New York"
    },
    {
      "city": "East Greenbush",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Greenbush, New York"
    },
    {
      "city": "East Nassau",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Nassau, New York"
    },
    {
      "city": "East Schodack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Schodack, New York"
    },
    {
      "city": "Grafton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Grafton, New York"
    },
    {
      "city": "Hoosick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hoosick, New York"
    },
    {
      "city": "Hoosick Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hoosick Falls, New York"
    },
    {
      "city": "Johnsonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Johnsonville, New York"
    },
    {
      "city": "Melrose",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Melrose, New York"
    },
    {
      "city": "Nassau",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nassau, New York"
    },
    {
      "city": "North Hoosick",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Hoosick, New York"
    },
    {
      "city": "Petersburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Petersburg, New York"
    },
    {
      "city": "Poestenkill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Poestenkill, New York"
    },
    {
      "city": "Rensselaer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rensselaer, New York"
    },
    {
      "city": "Sand Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sand Lake, New York"
    },
    {
      "city": "Schaghticoke",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schaghticoke, New York"
    },
    {
      "city": "Schodack Landing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schodack Landing, New York"
    },
    {
      "city": "Stephentown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stephentown, New York"
    },
    {
      "city": "Valley Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Valley Falls, New York"
    },
    {
      "city": "West Sand Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Sand Lake, New York"
    },
    {
      "city": "Wynantskill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wynantskill, New York"
    },
    {
      "city": "Staten Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Staten Island, New York"
    },
    {
      "city": "Suffern",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Suffern, New York"
    },
    {
      "city": "Bear Mountain",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bear Mountain, New York"
    },
    {
      "city": "Blauvelt",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blauvelt, New York"
    },
    {
      "city": "Congers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Congers, New York"
    },
    {
      "city": "Garnerville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Garnerville, New York"
    },
    {
      "city": "Haverstraw",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Haverstraw, New York"
    },
    {
      "city": "Hillburn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hillburn, New York"
    },
    {
      "city": "Monsey",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Monsey, New York"
    },
    {
      "city": "Nanuet",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nanuet, New York"
    },
    {
      "city": "New City",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New City, New York"
    },
    {
      "city": "Nyack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nyack, New York"
    },
    {
      "city": "Orangeburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Orangeburg, New York"
    },
    {
      "city": "Palisades",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Palisades, New York"
    },
    {
      "city": "Pearl River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pearl River, New York"
    },
    {
      "city": "Piermont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Piermont, New York"
    },
    {
      "city": "Pomona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pomona, New York"
    },
    {
      "city": "Sloatsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sloatsburg, New York"
    },
    {
      "city": "Sparkill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sparkill, New York"
    },
    {
      "city": "Spring Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spring Valley, New York"
    },
    {
      "city": "Stony Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stony Point, New York"
    },
    {
      "city": "Tallman",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tallman, New York"
    },
    {
      "city": "Tappan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tappan, New York"
    },
    {
      "city": "Thiells",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thiells, New York"
    },
    {
      "city": "Tomkins Cove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tomkins Cove, New York"
    },
    {
      "city": "Valley Cottage",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Valley Cottage, New York"
    },
    {
      "city": "West Haverstraw",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Haverstraw, New York"
    },
    {
      "city": "West Nyack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Nyack, New York"
    },
    {
      "city": "Childwold",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Childwold, New York"
    },
    {
      "city": "Cranberry Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cranberry Lake, New York"
    },
    {
      "city": "Lawrenceville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lawrenceville, New York"
    },
    {
      "city": "Nicholville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nicholville, New York"
    },
    {
      "city": "North Lawrence",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Lawrence, New York"
    },
    {
      "city": "Piercefield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Piercefield, New York"
    },
    {
      "city": "Brasher Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brasher Falls, New York"
    },
    {
      "city": "Brier Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brier Hill, New York"
    },
    {
      "city": "Canton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canton, New York"
    },
    {
      "city": "Chase Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chase Mills, New York"
    },
    {
      "city": "Chippewa Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chippewa Bay, New York"
    },
    {
      "city": "Colton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Colton, New York"
    },
    {
      "city": "De Kalb Junction",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "De Kalb Junction, New York"
    },
    {
      "city": "De Peyster",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "De Peyster, New York"
    },
    {
      "city": "Edwards",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Edwards, New York"
    },
    {
      "city": "Fine",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fine, New York"
    },
    {
      "city": "Gouverneur",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gouverneur, New York"
    },
    {
      "city": "Hailesboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hailesboro, New York"
    },
    {
      "city": "Hammond",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hammond, New York"
    },
    {
      "city": "Hannawa Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hannawa Falls, New York"
    },
    {
      "city": "Helena",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Helena, New York"
    },
    {
      "city": "Hermon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hermon, New York"
    },
    {
      "city": "Heuvelton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Heuvelton, New York"
    },
    {
      "city": "Lisbon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lisbon, New York"
    },
    {
      "city": "Madrid",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Madrid, New York"
    },
    {
      "city": "Massena",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Massena, New York"
    },
    {
      "city": "Morristown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Morristown, New York"
    },
    {
      "city": "Newton Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newton Falls, New York"
    },
    {
      "city": "Norfolk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Norfolk, New York"
    },
    {
      "city": "Norwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Norwood, New York"
    },
    {
      "city": "Ogdensburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ogdensburg, New York"
    },
    {
      "city": "Oswegatchie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oswegatchie, New York"
    },
    {
      "city": "Parishville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Parishville, New York"
    },
    {
      "city": "Potsdam",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Potsdam, New York"
    },
    {
      "city": "Pyrites",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pyrites, New York"
    },
    {
      "city": "Raymondville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Raymondville, New York"
    },
    {
      "city": "Rensselaer Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rensselaer Falls, New York"
    },
    {
      "city": "Richville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richville, New York"
    },
    {
      "city": "Rooseveltown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rooseveltown, New York"
    },
    {
      "city": "Russell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Russell, New York"
    },
    {
      "city": "South Colton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Colton, New York"
    },
    {
      "city": "Star Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Star Lake, New York"
    },
    {
      "city": "Waddington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waddington, New York"
    },
    {
      "city": "Wanakena",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wanakena, New York"
    },
    {
      "city": "West Stockholm",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Stockholm, New York"
    },
    {
      "city": "Winthrop",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Winthrop, New York"
    },
    {
      "city": "Ballston Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ballston Lake, New York"
    },
    {
      "city": "Ballston Spa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ballston Spa, New York"
    },
    {
      "city": "Burnt Hills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burnt Hills, New York"
    },
    {
      "city": "Clifton Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clifton Park, New York"
    },
    {
      "city": "Galway",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Galway, New York"
    },
    {
      "city": "Mechanicville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mechanicville, New York"
    },
    {
      "city": "Rexford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rexford, New York"
    },
    {
      "city": "Round Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Round Lake, New York"
    },
    {
      "city": "Stillwater",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stillwater, New York"
    },
    {
      "city": "Waterford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waterford, New York"
    },
    {
      "city": "South Glens Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Glens Falls, New York"
    },
    {
      "city": "Corinth",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Corinth, New York"
    },
    {
      "city": "Gansevoort",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gansevoort, New York"
    },
    {
      "city": "Greenfield Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenfield Center, New York"
    },
    {
      "city": "Hadley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hadley, New York"
    },
    {
      "city": "Middle Grove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middle Grove, New York"
    },
    {
      "city": "Porter Corners",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Porter Corners, New York"
    },
    {
      "city": "Rock City Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rock City Falls, New York"
    },
    {
      "city": "Saratoga Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saratoga Springs, New York"
    },
    {
      "city": "Schuylerville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schuylerville, New York"
    },
    {
      "city": "Victory Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Victory Mills, New York"
    },
    {
      "city": "Alplaus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alplaus, New York"
    },
    {
      "city": "Delanson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Delanson, New York"
    },
    {
      "city": "Duanesburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Duanesburg, New York"
    },
    {
      "city": "Pattersonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pattersonville, New York"
    },
    {
      "city": "Quaker Street",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Quaker Street, New York"
    },
    {
      "city": "Rotterdam Junction",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rotterdam Junction, New York"
    },
    {
      "city": "Schenectady",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schenectady, New York"
    },
    {
      "city": "Carlisle",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Carlisle, New York"
    },
    {
      "city": "Central Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Central Bridge, New York"
    },
    {
      "city": "Charlotteville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Charlotteville, New York"
    },
    {
      "city": "Cobleskill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cobleskill, New York"
    },
    {
      "city": "Fultonham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fultonham, New York"
    },
    {
      "city": "Gallupville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gallupville, New York"
    },
    {
      "city": "Gilboa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gilboa, New York"
    },
    {
      "city": "Howes Cave",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Howes Cave, New York"
    },
    {
      "city": "Jefferson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jefferson, New York"
    },
    {
      "city": "Middleburgh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middleburgh, New York"
    },
    {
      "city": "North Blenheim",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Blenheim, New York"
    },
    {
      "city": "Richmondville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richmondville, New York"
    },
    {
      "city": "Schoharie",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Schoharie, New York"
    },
    {
      "city": "Sloansville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sloansville, New York"
    },
    {
      "city": "Summit",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Summit, New York"
    },
    {
      "city": "Warnerville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Warnerville, New York"
    },
    {
      "city": "West Fulton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Fulton, New York"
    },
    {
      "city": "Sharon Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sharon Springs, New York"
    },
    {
      "city": "Alpine",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alpine, New York"
    },
    {
      "city": "Beaver Dams",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Beaver Dams, New York"
    },
    {
      "city": "Burdett",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burdett, New York"
    },
    {
      "city": "Cayuta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cayuta, New York"
    },
    {
      "city": "Hector",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hector, New York"
    },
    {
      "city": "Mecklenburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mecklenburg, New York"
    },
    {
      "city": "Montour Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Montour Falls, New York"
    },
    {
      "city": "Odessa",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Odessa, New York"
    },
    {
      "city": "Reading Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Reading Center, New York"
    },
    {
      "city": "Tyrone",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tyrone, New York"
    },
    {
      "city": "Watkins Glen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Watkins Glen, New York"
    },
    {
      "city": "Wayne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wayne, New York"
    },
    {
      "city": "Fayette",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fayette, New York"
    },
    {
      "city": "Seneca Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Seneca Falls, New York"
    },
    {
      "city": "Waterloo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waterloo, New York"
    },
    {
      "city": "Ovid",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ovid, New York"
    },
    {
      "city": "Romulus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Romulus, New York"
    },
    {
      "city": "Willard",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Willard, New York"
    },
    {
      "city": "Interlaken",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Interlaken, New York"
    },
    {
      "city": "Lodi",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lodi, New York"
    },
    {
      "city": "Perkinsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Perkinsville, New York"
    },
    {
      "city": "Wayland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wayland, New York"
    },
    {
      "city": "Addison",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Addison, New York"
    },
    {
      "city": "Arkport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Arkport, New York"
    },
    {
      "city": "Atlanta",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Atlanta, New York"
    },
    {
      "city": "Avoca",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Avoca, New York"
    },
    {
      "city": "Bath",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bath, New York"
    },
    {
      "city": "Bradford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bradford, New York"
    },
    {
      "city": "Cameron",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cameron, New York"
    },
    {
      "city": "Cameron Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cameron Mills, New York"
    },
    {
      "city": "Campbell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Campbell, New York"
    },
    {
      "city": "Canisteo",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Canisteo, New York"
    },
    {
      "city": "Cohocton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cohocton, New York"
    },
    {
      "city": "Coopers Plains",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Coopers Plains, New York"
    },
    {
      "city": "Corning",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Corning, New York"
    },
    {
      "city": "Greenwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenwood, New York"
    },
    {
      "city": "Hammondsport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hammondsport, New York"
    },
    {
      "city": "Hornell",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hornell, New York"
    },
    {
      "city": "Jasper",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jasper, New York"
    },
    {
      "city": "Kanona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kanona, New York"
    },
    {
      "city": "Lindley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lindley, New York"
    },
    {
      "city": "Painted Post",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Painted Post, New York"
    },
    {
      "city": "Prattsburgh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Prattsburgh, New York"
    },
    {
      "city": "Pulteney",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pulteney, New York"
    },
    {
      "city": "Rexville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rexville, New York"
    },
    {
      "city": "Savona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Savona, New York"
    },
    {
      "city": "Troupsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Troupsburg, New York"
    },
    {
      "city": "Woodhull",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodhull, New York"
    },
    {
      "city": "Holtsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holtsville, New York"
    },
    {
      "city": "Fishers Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fishers Island, New York"
    },
    {
      "city": "Amityville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Amityville, New York"
    },
    {
      "city": "Babylon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Babylon, New York"
    },
    {
      "city": "North Babylon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Babylon, New York"
    },
    {
      "city": "West Babylon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Babylon, New York"
    },
    {
      "city": "Bayport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bayport, New York"
    },
    {
      "city": "Bay Shore",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bay Shore, New York"
    },
    {
      "city": "Bellport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bellport, New York"
    },
    {
      "city": "Blue Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Blue Point, New York"
    },
    {
      "city": "Bohemia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bohemia, New York"
    },
    {
      "city": "Brentwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brentwood, New York"
    },
    {
      "city": "Brightwaters",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brightwaters, New York"
    },
    {
      "city": "Brookhaven",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brookhaven, New York"
    },
    {
      "city": "Centereach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Centereach, New York"
    },
    {
      "city": "Centerport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Centerport, New York"
    },
    {
      "city": "Central Islip",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Central Islip, New York"
    },
    {
      "city": "Cold Spring Harbor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cold Spring Harbor, New York"
    },
    {
      "city": "Commack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Commack, New York"
    },
    {
      "city": "Copiague",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Copiague, New York"
    },
    {
      "city": "Coram",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Coram, New York"
    },
    {
      "city": "Deer Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Deer Park, New York"
    },
    {
      "city": "East Islip",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Islip, New York"
    },
    {
      "city": "East Northport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Northport, New York"
    },
    {
      "city": "East Setauket",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Setauket, New York"
    },
    {
      "city": "Farmingville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Farmingville, New York"
    },
    {
      "city": "Great River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Great River, New York"
    },
    {
      "city": "Greenlawn",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenlawn, New York"
    },
    {
      "city": "Holbrook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Holbrook, New York"
    },
    {
      "city": "Huntington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Huntington, New York"
    },
    {
      "city": "Huntington Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Huntington Station, New York"
    },
    {
      "city": "Melville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Melville, New York"
    },
    {
      "city": "Islandia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Islandia, New York"
    },
    {
      "city": "Islip",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Islip, New York"
    },
    {
      "city": "Islip Terrace",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Islip Terrace, New York"
    },
    {
      "city": "Kings Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kings Park, New York"
    },
    {
      "city": "Lake Grove",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Grove, New York"
    },
    {
      "city": "Lindenhurst",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lindenhurst, New York"
    },
    {
      "city": "Medford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Medford, New York"
    },
    {
      "city": "Miller Place",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Miller Place, New York"
    },
    {
      "city": "Mount Sinai",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Sinai, New York"
    },
    {
      "city": "Nesconset",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nesconset, New York"
    },
    {
      "city": "Northport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Northport, New York"
    },
    {
      "city": "Oakdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Oakdale, New York"
    },
    {
      "city": "Ocean Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ocean Beach, New York"
    },
    {
      "city": "Patchogue",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Patchogue, New York"
    },
    {
      "city": "Port Jefferson Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Jefferson Station, New York"
    },
    {
      "city": "Port Jefferson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Jefferson, New York"
    },
    {
      "city": "Rocky Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rocky Point, New York"
    },
    {
      "city": "Ronkonkoma",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ronkonkoma, New York"
    },
    {
      "city": "Saint James",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saint James, New York"
    },
    {
      "city": "Sayville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sayville, New York"
    },
    {
      "city": "Selden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Selden, New York"
    },
    {
      "city": "Shoreham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shoreham, New York"
    },
    {
      "city": "Smithtown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Smithtown, New York"
    },
    {
      "city": "Hauppauge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hauppauge, New York"
    },
    {
      "city": "Sound Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sound Beach, New York"
    },
    {
      "city": "Stony Brook",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stony Brook, New York"
    },
    {
      "city": "Wading River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wading River, New York"
    },
    {
      "city": "West Islip",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Islip, New York"
    },
    {
      "city": "West Sayville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Sayville, New York"
    },
    {
      "city": "Wyandanch",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wyandanch, New York"
    },
    {
      "city": "Riverhead",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Riverhead, New York"
    },
    {
      "city": "Amagansett",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Amagansett, New York"
    },
    {
      "city": "Aquebogue",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Aquebogue, New York"
    },
    {
      "city": "Bridgehampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bridgehampton, New York"
    },
    {
      "city": "Calverton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Calverton, New York"
    },
    {
      "city": "Center Moriches",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Center Moriches, New York"
    },
    {
      "city": "Cutchogue",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cutchogue, New York"
    },
    {
      "city": "East Hampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Hampton, New York"
    },
    {
      "city": "East Marion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Marion, New York"
    },
    {
      "city": "East Moriches",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Moriches, New York"
    },
    {
      "city": "Eastport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eastport, New York"
    },
    {
      "city": "East Quogue",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Quogue, New York"
    },
    {
      "city": "Greenport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenport, New York"
    },
    {
      "city": "Hampton Bays",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hampton Bays, New York"
    },
    {
      "city": "Jamesport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jamesport, New York"
    },
    {
      "city": "Laurel",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Laurel, New York"
    },
    {
      "city": "Manorville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Manorville, New York"
    },
    {
      "city": "Mastic",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mastic, New York"
    },
    {
      "city": "Mastic Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mastic Beach, New York"
    },
    {
      "city": "Mattituck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mattituck, New York"
    },
    {
      "city": "Middle Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middle Island, New York"
    },
    {
      "city": "Montauk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Montauk, New York"
    },
    {
      "city": "Moriches",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Moriches, New York"
    },
    {
      "city": "New Suffolk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Suffolk, New York"
    },
    {
      "city": "Orient",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Orient, New York"
    },
    {
      "city": "Peconic",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Peconic, New York"
    },
    {
      "city": "Quogue",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Quogue, New York"
    },
    {
      "city": "Remsenburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Remsenburg, New York"
    },
    {
      "city": "Ridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ridge, New York"
    },
    {
      "city": "Sagaponack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sagaponack, New York"
    },
    {
      "city": "Sag Harbor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sag Harbor, New York"
    },
    {
      "city": "Shelter Island",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shelter Island, New York"
    },
    {
      "city": "Shelter Island Heights",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shelter Island Heights, New York"
    },
    {
      "city": "Shirley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shirley, New York"
    },
    {
      "city": "Southampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Southampton, New York"
    },
    {
      "city": "South Jamesport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Jamesport, New York"
    },
    {
      "city": "Southold",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Southold, New York"
    },
    {
      "city": "Speonk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Speonk, New York"
    },
    {
      "city": "Upton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Upton, New York"
    },
    {
      "city": "Wainscott",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wainscott, New York"
    },
    {
      "city": "Water Mill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Water Mill, New York"
    },
    {
      "city": "Westhampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westhampton, New York"
    },
    {
      "city": "Westhampton Beach",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westhampton Beach, New York"
    },
    {
      "city": "Yaphank",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yaphank, New York"
    },
    {
      "city": "Monticello",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Monticello, New York"
    },
    {
      "city": "Barryville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Barryville, New York"
    },
    {
      "city": "Bethel",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bethel, New York"
    },
    {
      "city": "Bloomingburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bloomingburg, New York"
    },
    {
      "city": "Burlingham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Burlingham, New York"
    },
    {
      "city": "Callicoon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Callicoon, New York"
    },
    {
      "city": "Callicoon Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Callicoon Center, New York"
    },
    {
      "city": "Claryville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Claryville, New York"
    },
    {
      "city": "Cochecton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cochecton, New York"
    },
    {
      "city": "Cochecton Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cochecton Center, New York"
    },
    {
      "city": "Eldred",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eldred, New York"
    },
    {
      "city": "Fallsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fallsburg, New York"
    },
    {
      "city": "Ferndale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ferndale, New York"
    },
    {
      "city": "Fremont Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fremont Center, New York"
    },
    {
      "city": "Glen Spey",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Spey, New York"
    },
    {
      "city": "Glen Wild",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glen Wild, New York"
    },
    {
      "city": "Grahamsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Grahamsville, New York"
    },
    {
      "city": "Hankins",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hankins, New York"
    },
    {
      "city": "Harris",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harris, New York"
    },
    {
      "city": "Highland Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Highland Lake, New York"
    },
    {
      "city": "Hortonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hortonville, New York"
    },
    {
      "city": "Hurleyville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hurleyville, New York"
    },
    {
      "city": "Jeffersonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jeffersonville, New York"
    },
    {
      "city": "Kauneonga Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kauneonga Lake, New York"
    },
    {
      "city": "Kenoza Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kenoza Lake, New York"
    },
    {
      "city": "Kiamesha Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kiamesha Lake, New York"
    },
    {
      "city": "Lake Huntington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Huntington, New York"
    },
    {
      "city": "Liberty",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Liberty, New York"
    },
    {
      "city": "Livingston Manor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Livingston Manor, New York"
    },
    {
      "city": "Loch Sheldrake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Loch Sheldrake, New York"
    },
    {
      "city": "Long Eddy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Long Eddy, New York"
    },
    {
      "city": "Mongaup Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mongaup Valley, New York"
    },
    {
      "city": "Mountain Dale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mountain Dale, New York"
    },
    {
      "city": "Narrowsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Narrowsburg, New York"
    },
    {
      "city": "Neversink",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Neversink, New York"
    },
    {
      "city": "North Branch",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Branch, New York"
    },
    {
      "city": "Obernburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Obernburg, New York"
    },
    {
      "city": "Parksville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Parksville, New York"
    },
    {
      "city": "Phillipsport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Phillipsport, New York"
    },
    {
      "city": "Pond Eddy",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pond Eddy, New York"
    },
    {
      "city": "Rock Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rock Hill, New York"
    },
    {
      "city": "Roscoe",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Roscoe, New York"
    },
    {
      "city": "Forestburgh",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Forestburgh, New York"
    },
    {
      "city": "Smallwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Smallwood, New York"
    },
    {
      "city": "South Fallsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Fallsburg, New York"
    },
    {
      "city": "Summitville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Summitville, New York"
    },
    {
      "city": "Swan Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Swan Lake, New York"
    },
    {
      "city": "Thompsonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thompsonville, New York"
    },
    {
      "city": "Westbrookville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Westbrookville, New York"
    },
    {
      "city": "White Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "White Lake, New York"
    },
    {
      "city": "White Sulphur Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "White Sulphur Springs, New York"
    },
    {
      "city": "Woodbourne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodbourne, New York"
    },
    {
      "city": "Woodridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodridge, New York"
    },
    {
      "city": "Wurtsboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wurtsboro, New York"
    },
    {
      "city": "Youngsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Youngsville, New York"
    },
    {
      "city": "Yulan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yulan, New York"
    },
    {
      "city": "Apalachin",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Apalachin, New York"
    },
    {
      "city": "Barton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Barton, New York"
    },
    {
      "city": "Berkshire",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Berkshire, New York"
    },
    {
      "city": "Candor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Candor, New York"
    },
    {
      "city": "Newark Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newark Valley, New York"
    },
    {
      "city": "Nichols",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Nichols, New York"
    },
    {
      "city": "Owego",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Owego, New York"
    },
    {
      "city": "Richford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Richford, New York"
    },
    {
      "city": "Smithboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Smithboro, New York"
    },
    {
      "city": "Tioga Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tioga Center, New York"
    },
    {
      "city": "Willseyville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Willseyville, New York"
    },
    {
      "city": "Lockwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lockwood, New York"
    },
    {
      "city": "Spencer",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spencer, New York"
    },
    {
      "city": "Waverly",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waverly, New York"
    },
    {
      "city": "Dryden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dryden, New York"
    },
    {
      "city": "Etna",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Etna, New York"
    },
    {
      "city": "Freeville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Freeville, New York"
    },
    {
      "city": "Groton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Groton, New York"
    },
    {
      "city": "Mc Lean",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mc Lean, New York"
    },
    {
      "city": "Brooktondale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brooktondale, New York"
    },
    {
      "city": "Ithaca",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ithaca, New York"
    },
    {
      "city": "Jacksonville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jacksonville, New York"
    },
    {
      "city": "Newfield",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newfield, New York"
    },
    {
      "city": "Slaterville Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Slaterville Springs, New York"
    },
    {
      "city": "Lansing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lansing, New York"
    },
    {
      "city": "Trumansburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Trumansburg, New York"
    },
    {
      "city": "Kingston",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kingston, New York"
    },
    {
      "city": "Accord",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Accord, New York"
    },
    {
      "city": "Bearsville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bearsville, New York"
    },
    {
      "city": "Big Indian",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Big Indian, New York"
    },
    {
      "city": "Bloomington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bloomington, New York"
    },
    {
      "city": "Boiceville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Boiceville, New York"
    },
    {
      "city": "Chichester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chichester, New York"
    },
    {
      "city": "Connelly",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Connelly, New York"
    },
    {
      "city": "Cottekill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cottekill, New York"
    },
    {
      "city": "Cragsmoor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cragsmoor, New York"
    },
    {
      "city": "Ellenville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ellenville, New York"
    },
    {
      "city": "Esopus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Esopus, New York"
    },
    {
      "city": "Glasco",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glasco, New York"
    },
    {
      "city": "Glenford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glenford, New York"
    },
    {
      "city": "Greenfield Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenfield Park, New York"
    },
    {
      "city": "High Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "High Falls, New York"
    },
    {
      "city": "Highmount",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Highmount, New York"
    },
    {
      "city": "Hurley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hurley, New York"
    },
    {
      "city": "Kerhonkson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kerhonkson, New York"
    },
    {
      "city": "Lake Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Hill, New York"
    },
    {
      "city": "Lake Katrine",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Katrine, New York"
    },
    {
      "city": "Malden On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Malden On Hudson, New York"
    },
    {
      "city": "Mount Marion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Marion, New York"
    },
    {
      "city": "Mount Tremper",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Tremper, New York"
    },
    {
      "city": "Napanoch",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Napanoch, New York"
    },
    {
      "city": "Olivebridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Olivebridge, New York"
    },
    {
      "city": "Phoenicia",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Phoenicia, New York"
    },
    {
      "city": "Pine Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pine Hill, New York"
    },
    {
      "city": "Port Ewen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Ewen, New York"
    },
    {
      "city": "Rifton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rifton, New York"
    },
    {
      "city": "Rosendale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rosendale, New York"
    },
    {
      "city": "Ruby",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ruby, New York"
    },
    {
      "city": "Saugerties",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Saugerties, New York"
    },
    {
      "city": "Shandaken",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shandaken, New York"
    },
    {
      "city": "Shokan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shokan, New York"
    },
    {
      "city": "Spring Glen",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Spring Glen, New York"
    },
    {
      "city": "Stone Ridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stone Ridge, New York"
    },
    {
      "city": "Tillson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tillson, New York"
    },
    {
      "city": "Ulster Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ulster Park, New York"
    },
    {
      "city": "Wawarsing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wawarsing, New York"
    },
    {
      "city": "West Camp",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Camp, New York"
    },
    {
      "city": "West Hurley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Hurley, New York"
    },
    {
      "city": "West Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Park, New York"
    },
    {
      "city": "West Shokan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Shokan, New York"
    },
    {
      "city": "Willow",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Willow, New York"
    },
    {
      "city": "Woodstock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Woodstock, New York"
    },
    {
      "city": "Clintondale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clintondale, New York"
    },
    {
      "city": "Gardiner",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gardiner, New York"
    },
    {
      "city": "Highland",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Highland, New York"
    },
    {
      "city": "Marlboro",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marlboro, New York"
    },
    {
      "city": "Milton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Milton, New York"
    },
    {
      "city": "Modena",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Modena, New York"
    },
    {
      "city": "New Paltz",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Paltz, New York"
    },
    {
      "city": "Plattekill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Plattekill, New York"
    },
    {
      "city": "Walker Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Walker Valley, New York"
    },
    {
      "city": "Wallkill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wallkill, New York"
    },
    {
      "city": "Glens Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Glens Falls, New York"
    },
    {
      "city": "Queensbury",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Queensbury, New York"
    },
    {
      "city": "Adirondack",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Adirondack, New York"
    },
    {
      "city": "Athol",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Athol, New York"
    },
    {
      "city": "Bakers Mills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bakers Mills, New York"
    },
    {
      "city": "Bolton Landing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bolton Landing, New York"
    },
    {
      "city": "Brant Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Brant Lake, New York"
    },
    {
      "city": "Chestertown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chestertown, New York"
    },
    {
      "city": "Cleverdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cleverdale, New York"
    },
    {
      "city": "Diamond Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Diamond Point, New York"
    },
    {
      "city": "Hague",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hague, New York"
    },
    {
      "city": "Johnsburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Johnsburg, New York"
    },
    {
      "city": "Kattskill Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Kattskill Bay, New York"
    },
    {
      "city": "Lake George",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake George, New York"
    },
    {
      "city": "Lake Luzerne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lake Luzerne, New York"
    },
    {
      "city": "North Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Creek, New York"
    },
    {
      "city": "North River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North River, New York"
    },
    {
      "city": "Pottersville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pottersville, New York"
    },
    {
      "city": "Riparius",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Riparius, New York"
    },
    {
      "city": "Silver Bay",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Silver Bay, New York"
    },
    {
      "city": "Stony Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Stony Creek, New York"
    },
    {
      "city": "Warrensburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Warrensburg, New York"
    },
    {
      "city": "Wevertown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wevertown, New York"
    },
    {
      "city": "Eagle Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eagle Bridge, New York"
    },
    {
      "city": "Argyle",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Argyle, New York"
    },
    {
      "city": "Cambridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cambridge, New York"
    },
    {
      "city": "Clemons",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clemons, New York"
    },
    {
      "city": "Comstock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Comstock, New York"
    },
    {
      "city": "Cossayuna",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cossayuna, New York"
    },
    {
      "city": "Fort Ann",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Ann, New York"
    },
    {
      "city": "Fort Edward",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Fort Edward, New York"
    },
    {
      "city": "Granville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Granville, New York"
    },
    {
      "city": "Greenwich",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Greenwich, New York"
    },
    {
      "city": "Hampton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hampton, New York"
    },
    {
      "city": "Hartford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hartford, New York"
    },
    {
      "city": "Hudson Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hudson Falls, New York"
    },
    {
      "city": "Huletts Landing",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Huletts Landing, New York"
    },
    {
      "city": "Middle Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middle Falls, New York"
    },
    {
      "city": "Middle Granville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middle Granville, New York"
    },
    {
      "city": "North Granville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Granville, New York"
    },
    {
      "city": "Putnam Station",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Putnam Station, New York"
    },
    {
      "city": "Salem",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Salem, New York"
    },
    {
      "city": "Shushan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shushan, New York"
    },
    {
      "city": "Whitehall",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Whitehall, New York"
    },
    {
      "city": "Red Creek",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Red Creek, New York"
    },
    {
      "city": "Savannah",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Savannah, New York"
    },
    {
      "city": "South Butler",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Butler, New York"
    },
    {
      "city": "Alton",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Alton, New York"
    },
    {
      "city": "Clyde",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Clyde, New York"
    },
    {
      "city": "East Williamson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "East Williamson, New York"
    },
    {
      "city": "Lyons",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lyons, New York"
    },
    {
      "city": "Macedon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Macedon, New York"
    },
    {
      "city": "Marion",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Marion, New York"
    },
    {
      "city": "Newark",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Newark, New York"
    },
    {
      "city": "North Rose",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Rose, New York"
    },
    {
      "city": "Ontario",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ontario, New York"
    },
    {
      "city": "Ontario Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ontario Center, New York"
    },
    {
      "city": "Palmyra",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Palmyra, New York"
    },
    {
      "city": "Pultneyville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pultneyville, New York"
    },
    {
      "city": "Rose",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rose, New York"
    },
    {
      "city": "Sodus",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sodus, New York"
    },
    {
      "city": "Sodus Point",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Sodus Point, New York"
    },
    {
      "city": "Union Hill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Union Hill, New York"
    },
    {
      "city": "Walworth",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Walworth, New York"
    },
    {
      "city": "Williamson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Williamson, New York"
    },
    {
      "city": "Wolcott",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wolcott, New York"
    },
    {
      "city": "Amawalk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Amawalk, New York"
    },
    {
      "city": "Ardsley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ardsley, New York"
    },
    {
      "city": "Ardsley On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ardsley On Hudson, New York"
    },
    {
      "city": "Armonk",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Armonk, New York"
    },
    {
      "city": "Baldwin Place",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Baldwin Place, New York"
    },
    {
      "city": "Bedford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bedford, New York"
    },
    {
      "city": "Bedford Hills",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bedford Hills, New York"
    },
    {
      "city": "Briarcliff Manor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Briarcliff Manor, New York"
    },
    {
      "city": "Buchanan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Buchanan, New York"
    },
    {
      "city": "Chappaqua",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Chappaqua, New York"
    },
    {
      "city": "Crompond",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Crompond, New York"
    },
    {
      "city": "Cross River",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cross River, New York"
    },
    {
      "city": "Croton Falls",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Croton Falls, New York"
    },
    {
      "city": "Croton On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Croton On Hudson, New York"
    },
    {
      "city": "Dobbs Ferry",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dobbs Ferry, New York"
    },
    {
      "city": "Elmsford",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Elmsford, New York"
    },
    {
      "city": "Goldens Bridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Goldens Bridge, New York"
    },
    {
      "city": "Granite Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Granite Springs, New York"
    },
    {
      "city": "Harrison",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Harrison, New York"
    },
    {
      "city": "Hartsdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hartsdale, New York"
    },
    {
      "city": "Hawthorne",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hawthorne, New York"
    },
    {
      "city": "Irvington",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Irvington, New York"
    },
    {
      "city": "Jefferson Valley",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Jefferson Valley, New York"
    },
    {
      "city": "Katonah",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Katonah, New York"
    },
    {
      "city": "Larchmont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Larchmont, New York"
    },
    {
      "city": "Lincolndale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lincolndale, New York"
    },
    {
      "city": "Mamaroneck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mamaroneck, New York"
    },
    {
      "city": "Maryknoll",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Maryknoll, New York"
    },
    {
      "city": "Millwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Millwood, New York"
    },
    {
      "city": "Mohegan Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mohegan Lake, New York"
    },
    {
      "city": "Montrose",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Montrose, New York"
    },
    {
      "city": "Mount Kisco",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Kisco, New York"
    },
    {
      "city": "Mount Vernon",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Mount Vernon, New York"
    },
    {
      "city": "North Salem",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Salem, New York"
    },
    {
      "city": "Ossining",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Ossining, New York"
    },
    {
      "city": "Peekskill",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Peekskill, New York"
    },
    {
      "city": "Cortlandt Manor",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cortlandt Manor, New York"
    },
    {
      "city": "Pleasantville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pleasantville, New York"
    },
    {
      "city": "Port Chester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Port Chester, New York"
    },
    {
      "city": "Pound Ridge",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pound Ridge, New York"
    },
    {
      "city": "Purchase",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Purchase, New York"
    },
    {
      "city": "Purdys",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Purdys, New York"
    },
    {
      "city": "Rye",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rye, New York"
    },
    {
      "city": "Scarsdale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Scarsdale, New York"
    },
    {
      "city": "Shenorock",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shenorock, New York"
    },
    {
      "city": "Shrub Oak",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Shrub Oak, New York"
    },
    {
      "city": "Somers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Somers, New York"
    },
    {
      "city": "South Salem",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "South Salem, New York"
    },
    {
      "city": "Tarrytown",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tarrytown, New York"
    },
    {
      "city": "Thornwood",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Thornwood, New York"
    },
    {
      "city": "Valhalla",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Valhalla, New York"
    },
    {
      "city": "Verplanck",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Verplanck, New York"
    },
    {
      "city": "Waccabuc",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Waccabuc, New York"
    },
    {
      "city": "Yorktown Heights",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yorktown Heights, New York"
    },
    {
      "city": "White Plains",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "White Plains, New York"
    },
    {
      "city": "West Harrison",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "West Harrison, New York"
    },
    {
      "city": "Yonkers",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Yonkers, New York"
    },
    {
      "city": "Hastings On Hudson",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Hastings On Hudson, New York"
    },
    {
      "city": "Tuckahoe",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Tuckahoe, New York"
    },
    {
      "city": "Bronxville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bronxville, New York"
    },
    {
      "city": "Eastchester",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Eastchester, New York"
    },
    {
      "city": "New Rochelle",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "New Rochelle, New York"
    },
    {
      "city": "Pelham",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pelham, New York"
    },
    {
      "city": "Arcade",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Arcade, New York"
    },
    {
      "city": "Attica",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Attica, New York"
    },
    {
      "city": "Bliss",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bliss, New York"
    },
    {
      "city": "Cowlesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Cowlesville, New York"
    },
    {
      "city": "Dale",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dale, New York"
    },
    {
      "city": "Gainesville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Gainesville, New York"
    },
    {
      "city": "Java Center",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Java Center, New York"
    },
    {
      "city": "Java Village",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Java Village, New York"
    },
    {
      "city": "North Java",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "North Java, New York"
    },
    {
      "city": "Pike",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Pike, New York"
    },
    {
      "city": "Strykersville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Strykersville, New York"
    },
    {
      "city": "Varysburg",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Varysburg, New York"
    },
    {
      "city": "Castile",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Castile, New York"
    },
    {
      "city": "Perry",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Perry, New York"
    },
    {
      "city": "Portageville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Portageville, New York"
    },
    {
      "city": "Silver Lake",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Silver Lake, New York"
    },
    {
      "city": "Silver Springs",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Silver Springs, New York"
    },
    {
      "city": "Warsaw",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Warsaw, New York"
    },
    {
      "city": "Wyoming",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Wyoming, New York"
    },
    {
      "city": "Bellona",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Bellona, New York"
    },
    {
      "city": "Branchport",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Branchport, New York"
    },
    {
      "city": "Dresden",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dresden, New York"
    },
    {
      "city": "Keuka Park",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Keuka Park, New York"
    },
    {
      "city": "Middlesex",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Middlesex, New York"
    },
    {
      "city": "Penn Yan",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Penn Yan, New York"
    },
    {
      "city": "Rushville",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rushville, New York"
    },
    {
      "city": "Dundee",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Dundee, New York"
    },
    {
      "city": "Himrod",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Himrod, New York"
    },
    {
      "city": "Lakemont",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Lakemont, New York"
    },
    {
      "city": "Rock Stream",
      "state": "New York",
      "shortState": "NY",
      "formatedCity": "Rock Stream, New York"
    },
    {
      "city": "Bentonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bentonville, Ohio"
    },
    {
      "city": "Manchester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Manchester, Ohio"
    },
    {
      "city": "Blue Creek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blue Creek, Ohio"
    },
    {
      "city": "Cherry Fork",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cherry Fork, Ohio"
    },
    {
      "city": "Lynx",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lynx, Ohio"
    },
    {
      "city": "Peebles",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Peebles, Ohio"
    },
    {
      "city": "Seaman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Seaman, Ohio"
    },
    {
      "city": "West Union",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Union, Ohio"
    },
    {
      "city": "Winchester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Winchester, Ohio"
    },
    {
      "city": "Lima",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lima, Ohio"
    },
    {
      "city": "Beaverdam",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beaverdam, Ohio"
    },
    {
      "city": "Gomer",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gomer, Ohio"
    },
    {
      "city": "Bluffton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bluffton, Ohio"
    },
    {
      "city": "Cairo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cairo, Ohio"
    },
    {
      "city": "Delphos",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Delphos, Ohio"
    },
    {
      "city": "Harrod",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harrod, Ohio"
    },
    {
      "city": "Lafayette",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lafayette, Ohio"
    },
    {
      "city": "Spencerville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Spencerville, Ohio"
    },
    {
      "city": "Ashland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ashland, Ohio"
    },
    {
      "city": "Hayesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hayesville, Ohio"
    },
    {
      "city": "Jeromesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jeromesville, Ohio"
    },
    {
      "city": "Loudonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Loudonville, Ohio"
    },
    {
      "city": "Nankin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nankin, Ohio"
    },
    {
      "city": "Nova",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nova, Ohio"
    },
    {
      "city": "Perrysville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Perrysville, Ohio"
    },
    {
      "city": "Polk",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Polk, Ohio"
    },
    {
      "city": "Savannah",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Savannah, Ohio"
    },
    {
      "city": "Sullivan",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sullivan, Ohio"
    },
    {
      "city": "Andover",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Andover, Ohio"
    },
    {
      "city": "Ashtabula",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ashtabula, Ohio"
    },
    {
      "city": "Austinburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Austinburg, Ohio"
    },
    {
      "city": "Conneaut",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Conneaut, Ohio"
    },
    {
      "city": "Dorset",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dorset, Ohio"
    },
    {
      "city": "Geneva",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Geneva, Ohio"
    },
    {
      "city": "Jefferson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jefferson, Ohio"
    },
    {
      "city": "Kingsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kingsville, Ohio"
    },
    {
      "city": "North Kingsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Kingsville, Ohio"
    },
    {
      "city": "Orwell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Orwell, Ohio"
    },
    {
      "city": "Pierpont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pierpont, Ohio"
    },
    {
      "city": "Rock Creek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rock Creek, Ohio"
    },
    {
      "city": "Rome",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rome, Ohio"
    },
    {
      "city": "Unionville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Unionville, Ohio"
    },
    {
      "city": "Williamsfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Williamsfield, Ohio"
    },
    {
      "city": "Windsor",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Windsor, Ohio"
    },
    {
      "city": "Athens",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Athens, Ohio"
    },
    {
      "city": "Albany",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Albany, Ohio"
    },
    {
      "city": "Amesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amesville, Ohio"
    },
    {
      "city": "Buchtel",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Buchtel, Ohio"
    },
    {
      "city": "Carbondale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Carbondale, Ohio"
    },
    {
      "city": "Chauncey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chauncey, Ohio"
    },
    {
      "city": "Coolville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Coolville, Ohio"
    },
    {
      "city": "Glouster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Glouster, Ohio"
    },
    {
      "city": "Guysville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Guysville, Ohio"
    },
    {
      "city": "Hockingport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hockingport, Ohio"
    },
    {
      "city": "Jacksonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jacksonville, Ohio"
    },
    {
      "city": "Millfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Millfield, Ohio"
    },
    {
      "city": "Nelsonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nelsonville, Ohio"
    },
    {
      "city": "New Marshfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Marshfield, Ohio"
    },
    {
      "city": "Shade",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shade, Ohio"
    },
    {
      "city": "Sharpsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sharpsburg, Ohio"
    },
    {
      "city": "Stewart",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stewart, Ohio"
    },
    {
      "city": "The Plains",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "The Plains, Ohio"
    },
    {
      "city": "Trimble",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Trimble, Ohio"
    },
    {
      "city": "Buckland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Buckland, Ohio"
    },
    {
      "city": "Minster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Minster, Ohio"
    },
    {
      "city": "New Bremen",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Bremen, Ohio"
    },
    {
      "city": "New Hampshire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Hampshire, Ohio"
    },
    {
      "city": "New Knoxville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Knoxville, Ohio"
    },
    {
      "city": "Saint Johns",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Johns, Ohio"
    },
    {
      "city": "Saint Marys",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Marys, Ohio"
    },
    {
      "city": "Uniopolis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Uniopolis, Ohio"
    },
    {
      "city": "Wapakoneta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wapakoneta, Ohio"
    },
    {
      "city": "Waynesfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waynesfield, Ohio"
    },
    {
      "city": "Barnesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Barnesville, Ohio"
    },
    {
      "city": "Belmont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Belmont, Ohio"
    },
    {
      "city": "Bethesda",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bethesda, Ohio"
    },
    {
      "city": "Morristown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Morristown, Ohio"
    },
    {
      "city": "Alledonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alledonia, Ohio"
    },
    {
      "city": "Barton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Barton, Ohio"
    },
    {
      "city": "Bellaire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bellaire, Ohio"
    },
    {
      "city": "Blaine",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blaine, Ohio"
    },
    {
      "city": "Bridgeport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bridgeport, Ohio"
    },
    {
      "city": "Colerain",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Colerain, Ohio"
    },
    {
      "city": "Fairpoint",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fairpoint, Ohio"
    },
    {
      "city": "Glencoe",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Glencoe, Ohio"
    },
    {
      "city": "Jacobsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jacobsburg, Ohio"
    },
    {
      "city": "Lansing",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lansing, Ohio"
    },
    {
      "city": "Martins Ferry",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Martins Ferry, Ohio"
    },
    {
      "city": "Maynard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maynard, Ohio"
    },
    {
      "city": "Neffs",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Neffs, Ohio"
    },
    {
      "city": "Powhatan Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Powhatan Point, Ohio"
    },
    {
      "city": "Shadyside",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shadyside, Ohio"
    },
    {
      "city": "Saint Clairsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Clairsville, Ohio"
    },
    {
      "city": "Lafferty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lafferty, Ohio"
    },
    {
      "city": "Warnock",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Warnock, Ohio"
    },
    {
      "city": "Bannock",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bannock, Ohio"
    },
    {
      "city": "Flushing",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Flushing, Ohio"
    },
    {
      "city": "Piedmont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Piedmont, Ohio"
    },
    {
      "city": "Holloway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Holloway, Ohio"
    },
    {
      "city": "Aberdeen",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Aberdeen, Ohio"
    },
    {
      "city": "Decatur",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Decatur, Ohio"
    },
    {
      "city": "Fayetteville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fayetteville, Ohio"
    },
    {
      "city": "Feesburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Feesburg, Ohio"
    },
    {
      "city": "Georgetown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Georgetown, Ohio"
    },
    {
      "city": "Hamersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hamersville, Ohio"
    },
    {
      "city": "Higginsport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Higginsport, Ohio"
    },
    {
      "city": "Mount Orab",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Orab, Ohio"
    },
    {
      "city": "Ripley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ripley, Ohio"
    },
    {
      "city": "Russellville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Russellville, Ohio"
    },
    {
      "city": "Sardinia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sardinia, Ohio"
    },
    {
      "city": "Cincinnati",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cincinnati, Ohio"
    },
    {
      "city": "College Corner",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "College Corner, Ohio"
    },
    {
      "city": "Collinsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Collinsville, Ohio"
    },
    {
      "city": "Hamilton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hamilton, Ohio"
    },
    {
      "city": "Fairfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fairfield, Ohio"
    },
    {
      "city": "Middletown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middletown, Ohio"
    },
    {
      "city": "Monroe",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Monroe, Ohio"
    },
    {
      "city": "Okeana",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Okeana, Ohio"
    },
    {
      "city": "Overpeck",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Overpeck, Ohio"
    },
    {
      "city": "Oxford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oxford, Ohio"
    },
    {
      "city": "Ross",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ross, Ohio"
    },
    {
      "city": "Seven Mile",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Seven Mile, Ohio"
    },
    {
      "city": "Shandon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shandon, Ohio"
    },
    {
      "city": "Somerville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Somerville, Ohio"
    },
    {
      "city": "Trenton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Trenton, Ohio"
    },
    {
      "city": "West Chester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Chester, Ohio"
    },
    {
      "city": "Augusta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Augusta, Ohio"
    },
    {
      "city": "Carrollton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Carrollton, Ohio"
    },
    {
      "city": "Dellroy",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dellroy, Ohio"
    },
    {
      "city": "Leesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Leesville, Ohio"
    },
    {
      "city": "Malvern",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Malvern, Ohio"
    },
    {
      "city": "Mechanicstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mechanicstown, Ohio"
    },
    {
      "city": "Sherrodsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sherrodsville, Ohio"
    },
    {
      "city": "Cable",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cable, Ohio"
    },
    {
      "city": "Mechanicsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mechanicsburg, Ohio"
    },
    {
      "city": "Mingo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mingo, Ohio"
    },
    {
      "city": "North Lewisburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Lewisburg, Ohio"
    },
    {
      "city": "Rosewood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rosewood, Ohio"
    },
    {
      "city": "Saint Paris",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Paris, Ohio"
    },
    {
      "city": "Urbana",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Urbana, Ohio"
    },
    {
      "city": "Westville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Westville, Ohio"
    },
    {
      "city": "Woodstock",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Woodstock, Ohio"
    },
    {
      "city": "Christiansburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Christiansburg, Ohio"
    },
    {
      "city": "Catawba",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Catawba, Ohio"
    },
    {
      "city": "Donnelsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Donnelsville, Ohio"
    },
    {
      "city": "Enon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Enon, Ohio"
    },
    {
      "city": "Medway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Medway, Ohio"
    },
    {
      "city": "New Carlisle",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Carlisle, Ohio"
    },
    {
      "city": "North Hampton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Hampton, Ohio"
    },
    {
      "city": "South Charleston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Charleston, Ohio"
    },
    {
      "city": "South Vienna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Vienna, Ohio"
    },
    {
      "city": "Tremont City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tremont City, Ohio"
    },
    {
      "city": "Springfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Springfield, Ohio"
    },
    {
      "city": "Amelia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amelia, Ohio"
    },
    {
      "city": "Batavia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Batavia, Ohio"
    },
    {
      "city": "Bethel",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bethel, Ohio"
    },
    {
      "city": "Chilo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chilo, Ohio"
    },
    {
      "city": "Felicity",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Felicity, Ohio"
    },
    {
      "city": "Goshen",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Goshen, Ohio"
    },
    {
      "city": "Loveland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Loveland, Ohio"
    },
    {
      "city": "Miamiville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Miamiville, Ohio"
    },
    {
      "city": "Milford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Milford, Ohio"
    },
    {
      "city": "Moscow",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Moscow, Ohio"
    },
    {
      "city": "Neville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Neville, Ohio"
    },
    {
      "city": "New Richmond",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Richmond, Ohio"
    },
    {
      "city": "Newtonsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newtonsville, Ohio"
    },
    {
      "city": "Owensville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Owensville, Ohio"
    },
    {
      "city": "Williamsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Williamsburg, Ohio"
    },
    {
      "city": "Blanchester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blanchester, Ohio"
    },
    {
      "city": "Clarksville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clarksville, Ohio"
    },
    {
      "city": "Cuba",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cuba, Ohio"
    },
    {
      "city": "Martinsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Martinsville, Ohio"
    },
    {
      "city": "Midland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Midland, Ohio"
    },
    {
      "city": "New Vienna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Vienna, Ohio"
    },
    {
      "city": "Port William",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Port William, Ohio"
    },
    {
      "city": "Reesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Reesville, Ohio"
    },
    {
      "city": "Sabina",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sabina, Ohio"
    },
    {
      "city": "Wilmington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wilmington, Ohio"
    },
    {
      "city": "East Liverpool",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Liverpool, Ohio"
    },
    {
      "city": "Salineville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Salineville, Ohio"
    },
    {
      "city": "Summitville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Summitville, Ohio"
    },
    {
      "city": "Wellsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wellsville, Ohio"
    },
    {
      "city": "Columbiana",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Columbiana, Ohio"
    },
    {
      "city": "East Palestine",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Palestine, Ohio"
    },
    {
      "city": "Elkton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Elkton, Ohio"
    },
    {
      "city": "Hanoverton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hanoverton, Ohio"
    },
    {
      "city": "Kensington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kensington, Ohio"
    },
    {
      "city": "Leetonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Leetonia, Ohio"
    },
    {
      "city": "Lisbon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lisbon, Ohio"
    },
    {
      "city": "Negley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Negley, Ohio"
    },
    {
      "city": "New Waterford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Waterford, Ohio"
    },
    {
      "city": "Rogers",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rogers, Ohio"
    },
    {
      "city": "Salem",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Salem, Ohio"
    },
    {
      "city": "Washingtonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Washingtonville, Ohio"
    },
    {
      "city": "West Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Point, Ohio"
    },
    {
      "city": "Winona",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Winona, Ohio"
    },
    {
      "city": "East Rochester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Rochester, Ohio"
    },
    {
      "city": "Homeworth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Homeworth, Ohio"
    },
    {
      "city": "North Georgetown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Georgetown, Ohio"
    },
    {
      "city": "Bakersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bakersville, Ohio"
    },
    {
      "city": "Blissfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blissfield, Ohio"
    },
    {
      "city": "Conesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Conesville, Ohio"
    },
    {
      "city": "Coshocton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Coshocton, Ohio"
    },
    {
      "city": "Fresno",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fresno, Ohio"
    },
    {
      "city": "Keene",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Keene, Ohio"
    },
    {
      "city": "Plainfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Plainfield, Ohio"
    },
    {
      "city": "Walhonding",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Walhonding, Ohio"
    },
    {
      "city": "Warsaw",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Warsaw, Ohio"
    },
    {
      "city": "West Lafayette",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Lafayette, Ohio"
    },
    {
      "city": "Bucyrus",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bucyrus, Ohio"
    },
    {
      "city": "Chatfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chatfield, Ohio"
    },
    {
      "city": "Crestline",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Crestline, Ohio"
    },
    {
      "city": "Galion",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Galion, Ohio"
    },
    {
      "city": "New Washington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Washington, Ohio"
    },
    {
      "city": "North Robinson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Robinson, Ohio"
    },
    {
      "city": "Oceola",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oceola, Ohio"
    },
    {
      "city": "Sulphur Springs",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sulphur Springs, Ohio"
    },
    {
      "city": "Tiro",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tiro, Ohio"
    },
    {
      "city": "Berea",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Berea, Ohio"
    },
    {
      "city": "Chagrin Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chagrin Falls, Ohio"
    },
    {
      "city": "Gates Mills",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gates Mills, Ohio"
    },
    {
      "city": "North Olmsted",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Olmsted, Ohio"
    },
    {
      "city": "Cleveland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cleveland, Ohio"
    },
    {
      "city": "Lakewood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lakewood, Ohio"
    },
    {
      "city": "Rocky River",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rocky River, Ohio"
    },
    {
      "city": "Euclid",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Euclid, Ohio"
    },
    {
      "city": "Beachwood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beachwood, Ohio"
    },
    {
      "city": "Independence",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Independence, Ohio"
    },
    {
      "city": "North Royalton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Royalton, Ohio"
    },
    {
      "city": "Strongsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Strongsville, Ohio"
    },
    {
      "city": "Maple Heights",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maple Heights, Ohio"
    },
    {
      "city": "Olmsted Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Olmsted Falls, Ohio"
    },
    {
      "city": "Solon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Solon, Ohio"
    },
    {
      "city": "Bay Village",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bay Village, Ohio"
    },
    {
      "city": "Brecksville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brecksville, Ohio"
    },
    {
      "city": "Brookpark",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brookpark, Ohio"
    },
    {
      "city": "Westlake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Westlake, Ohio"
    },
    {
      "city": "Bedford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bedford, Ohio"
    },
    {
      "city": "Broadview Heights",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Broadview Heights, Ohio"
    },
    {
      "city": "Ansonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ansonia, Ohio"
    },
    {
      "city": "Arcanum",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Arcanum, Ohio"
    },
    {
      "city": "Gettysburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gettysburg, Ohio"
    },
    {
      "city": "Greenville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Greenville, Ohio"
    },
    {
      "city": "Hollansburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hollansburg, Ohio"
    },
    {
      "city": "New Madison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Madison, Ohio"
    },
    {
      "city": "New Weston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Weston, Ohio"
    },
    {
      "city": "North Star",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Star, Ohio"
    },
    {
      "city": "Osgood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Osgood, Ohio"
    },
    {
      "city": "Palestine",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Palestine, Ohio"
    },
    {
      "city": "Pitsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pitsburg, Ohio"
    },
    {
      "city": "Rossburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rossburg, Ohio"
    },
    {
      "city": "Versailles",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Versailles, Ohio"
    },
    {
      "city": "Yorkshire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Yorkshire, Ohio"
    },
    {
      "city": "Union City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Union City, Ohio"
    },
    {
      "city": "Defiance",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Defiance, Ohio"
    },
    {
      "city": "Evansport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Evansport, Ohio"
    },
    {
      "city": "Farmer",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Farmer, Ohio"
    },
    {
      "city": "Hicksville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hicksville, Ohio"
    },
    {
      "city": "Jewell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jewell, Ohio"
    },
    {
      "city": "Mark Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mark Center, Ohio"
    },
    {
      "city": "Ney",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ney, Ohio"
    },
    {
      "city": "Sherwood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sherwood, Ohio"
    },
    {
      "city": "Ashley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ashley, Ohio"
    },
    {
      "city": "Delaware",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Delaware, Ohio"
    },
    {
      "city": "Galena",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Galena, Ohio"
    },
    {
      "city": "Kilbourne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kilbourne, Ohio"
    },
    {
      "city": "Lewis Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lewis Center, Ohio"
    },
    {
      "city": "Ostrander",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ostrander, Ohio"
    },
    {
      "city": "Powell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Powell, Ohio"
    },
    {
      "city": "Radnor",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Radnor, Ohio"
    },
    {
      "city": "Sunbury",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sunbury, Ohio"
    },
    {
      "city": "Westerville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Westerville, Ohio"
    },
    {
      "city": "Kelleys Island",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kelleys Island, Ohio"
    },
    {
      "city": "Vermilion",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vermilion, Ohio"
    },
    {
      "city": "Berlin Heights",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Berlin Heights, Ohio"
    },
    {
      "city": "Birmingham",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Birmingham, Ohio"
    },
    {
      "city": "Castalia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Castalia, Ohio"
    },
    {
      "city": "Huron",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Huron, Ohio"
    },
    {
      "city": "Milan",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Milan, Ohio"
    },
    {
      "city": "Sandusky",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sandusky, Ohio"
    },
    {
      "city": "Millersport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Millersport, Ohio"
    },
    {
      "city": "Amanda",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amanda, Ohio"
    },
    {
      "city": "Baltimore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Baltimore, Ohio"
    },
    {
      "city": "Bremen",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bremen, Ohio"
    },
    {
      "city": "Canal Winchester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Canal Winchester, Ohio"
    },
    {
      "city": "Carroll",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Carroll, Ohio"
    },
    {
      "city": "Lancaster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lancaster, Ohio"
    },
    {
      "city": "Lithopolis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lithopolis, Ohio"
    },
    {
      "city": "Pickerington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pickerington, Ohio"
    },
    {
      "city": "Pleasantville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pleasantville, Ohio"
    },
    {
      "city": "Rushville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rushville, Ohio"
    },
    {
      "city": "Stoutsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stoutsville, Ohio"
    },
    {
      "city": "Sugar Grove",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sugar Grove, Ohio"
    },
    {
      "city": "Thurston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Thurston, Ohio"
    },
    {
      "city": "Bloomingburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bloomingburg, Ohio"
    },
    {
      "city": "Jeffersonville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jeffersonville, Ohio"
    },
    {
      "city": "Milledgeville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Milledgeville, Ohio"
    },
    {
      "city": "Washington Court House",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Washington Court House, Ohio"
    },
    {
      "city": "Amlin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amlin, Ohio"
    },
    {
      "city": "Blacklick",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blacklick, Ohio"
    },
    {
      "city": "Dublin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dublin, Ohio"
    },
    {
      "city": "Hilliard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hilliard, Ohio"
    },
    {
      "city": "New Albany",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Albany, Ohio"
    },
    {
      "city": "Reynoldsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Reynoldsburg, Ohio"
    },
    {
      "city": "Columbus",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Columbus, Ohio"
    },
    {
      "city": "Brice",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brice, Ohio"
    },
    {
      "city": "Galloway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Galloway, Ohio"
    },
    {
      "city": "Grove City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grove City, Ohio"
    },
    {
      "city": "Groveport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Groveport, Ohio"
    },
    {
      "city": "Harrisburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harrisburg, Ohio"
    },
    {
      "city": "Lockbourne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lockbourne, Ohio"
    },
    {
      "city": "Archbold",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Archbold, Ohio"
    },
    {
      "city": "Delta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Delta, Ohio"
    },
    {
      "city": "Fayette",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fayette, Ohio"
    },
    {
      "city": "Lyons",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lyons, Ohio"
    },
    {
      "city": "Metamora",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Metamora, Ohio"
    },
    {
      "city": "Pettisville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pettisville, Ohio"
    },
    {
      "city": "Swanton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Swanton, Ohio"
    },
    {
      "city": "Wauseon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wauseon, Ohio"
    },
    {
      "city": "Bidwell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bidwell, Ohio"
    },
    {
      "city": "Cheshire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cheshire, Ohio"
    },
    {
      "city": "Crown City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Crown City, Ohio"
    },
    {
      "city": "Gallipolis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gallipolis, Ohio"
    },
    {
      "city": "Kerr",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kerr, Ohio"
    },
    {
      "city": "Patriot",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Patriot, Ohio"
    },
    {
      "city": "Rio Grande",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rio Grande, Ohio"
    },
    {
      "city": "Thurman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Thurman, Ohio"
    },
    {
      "city": "Vinton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vinton, Ohio"
    },
    {
      "city": "Burton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Burton, Ohio"
    },
    {
      "city": "Chardon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chardon, Ohio"
    },
    {
      "city": "Chesterland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chesterland, Ohio"
    },
    {
      "city": "East Claridon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Claridon, Ohio"
    },
    {
      "city": "Huntsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Huntsburg, Ohio"
    },
    {
      "city": "Middlefield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middlefield, Ohio"
    },
    {
      "city": "Montville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Montville, Ohio"
    },
    {
      "city": "Newbury",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newbury, Ohio"
    },
    {
      "city": "Novelty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Novelty, Ohio"
    },
    {
      "city": "Parkman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Parkman, Ohio"
    },
    {
      "city": "Thompson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Thompson, Ohio"
    },
    {
      "city": "Alpha",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alpha, Ohio"
    },
    {
      "city": "Bellbrook",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bellbrook, Ohio"
    },
    {
      "city": "Bowersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bowersville, Ohio"
    },
    {
      "city": "Cedarville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cedarville, Ohio"
    },
    {
      "city": "Clifton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clifton, Ohio"
    },
    {
      "city": "Fairborn",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fairborn, Ohio"
    },
    {
      "city": "Jamestown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jamestown, Ohio"
    },
    {
      "city": "Spring Valley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Spring Valley, Ohio"
    },
    {
      "city": "Wilberforce",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wilberforce, Ohio"
    },
    {
      "city": "Xenia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Xenia, Ohio"
    },
    {
      "city": "Yellow Springs",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Yellow Springs, Ohio"
    },
    {
      "city": "Dayton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dayton, Ohio"
    },
    {
      "city": "Buffalo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Buffalo, Ohio"
    },
    {
      "city": "Byesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Byesville, Ohio"
    },
    {
      "city": "Cambridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cambridge, Ohio"
    },
    {
      "city": "Cumberland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cumberland, Ohio"
    },
    {
      "city": "Derwent",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Derwent, Ohio"
    },
    {
      "city": "Fairview",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fairview, Ohio"
    },
    {
      "city": "Kimbolton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kimbolton, Ohio"
    },
    {
      "city": "Kipling",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kipling, Ohio"
    },
    {
      "city": "Lore City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lore City, Ohio"
    },
    {
      "city": "Old Washington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Old Washington, Ohio"
    },
    {
      "city": "Pleasant City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pleasant City, Ohio"
    },
    {
      "city": "Quaker City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Quaker City, Ohio"
    },
    {
      "city": "Salesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Salesville, Ohio"
    },
    {
      "city": "Senecaville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Senecaville, Ohio"
    },
    {
      "city": "Addyston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Addyston, Ohio"
    },
    {
      "city": "Cleves",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cleves, Ohio"
    },
    {
      "city": "Harrison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harrison, Ohio"
    },
    {
      "city": "Hooven",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hooven, Ohio"
    },
    {
      "city": "Miamitown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Miamitown, Ohio"
    },
    {
      "city": "Mount Saint Joseph",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Saint Joseph, Ohio"
    },
    {
      "city": "North Bend",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Bend, Ohio"
    },
    {
      "city": "Camp Dennison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Camp Dennison, Ohio"
    },
    {
      "city": "Terrace Park",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Terrace Park, Ohio"
    },
    {
      "city": "Arcadia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Arcadia, Ohio"
    },
    {
      "city": "Arlington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Arlington, Ohio"
    },
    {
      "city": "Benton Ridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Benton Ridge, Ohio"
    },
    {
      "city": "Findlay",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Findlay, Ohio"
    },
    {
      "city": "Jenera",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jenera, Ohio"
    },
    {
      "city": "Mc Comb",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Comb, Ohio"
    },
    {
      "city": "Mount Blanchard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Blanchard, Ohio"
    },
    {
      "city": "Mount Cory",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Cory, Ohio"
    },
    {
      "city": "Rawson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rawson, Ohio"
    },
    {
      "city": "Van Buren",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Van Buren, Ohio"
    },
    {
      "city": "Vanlue",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vanlue, Ohio"
    },
    {
      "city": "Williamstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Williamstown, Ohio"
    },
    {
      "city": "Kenton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kenton, Ohio"
    },
    {
      "city": "Mount Victory",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Victory, Ohio"
    },
    {
      "city": "Ridgeway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ridgeway, Ohio"
    },
    {
      "city": "Roundhead",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Roundhead, Ohio"
    },
    {
      "city": "Ada",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ada, Ohio"
    },
    {
      "city": "Alger",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alger, Ohio"
    },
    {
      "city": "Dola",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dola, Ohio"
    },
    {
      "city": "Dunkirk",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dunkirk, Ohio"
    },
    {
      "city": "Forest",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Forest, Ohio"
    },
    {
      "city": "Mc Guffey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Guffey, Ohio"
    },
    {
      "city": "Cadiz",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cadiz, Ohio"
    },
    {
      "city": "Freeport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Freeport, Ohio"
    },
    {
      "city": "Harrisville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harrisville, Ohio"
    },
    {
      "city": "Hopedale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hopedale, Ohio"
    },
    {
      "city": "New Athens",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Athens, Ohio"
    },
    {
      "city": "New Rumley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Rumley, Ohio"
    },
    {
      "city": "Jewett",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jewett, Ohio"
    },
    {
      "city": "Scio",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Scio, Ohio"
    },
    {
      "city": "Deersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Deersville, Ohio"
    },
    {
      "city": "Bowerston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bowerston, Ohio"
    },
    {
      "city": "Tippecanoe",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tippecanoe, Ohio"
    },
    {
      "city": "Colton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Colton, Ohio"
    },
    {
      "city": "Deshler",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Deshler, Ohio"
    },
    {
      "city": "Grelton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grelton, Ohio"
    },
    {
      "city": "Hamler",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hamler, Ohio"
    },
    {
      "city": "Holgate",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Holgate, Ohio"
    },
    {
      "city": "Liberty Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Liberty Center, Ohio"
    },
    {
      "city": "Mc Clure",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Clure, Ohio"
    },
    {
      "city": "Malinta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Malinta, Ohio"
    },
    {
      "city": "Napoleon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Napoleon, Ohio"
    },
    {
      "city": "New Bavaria",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Bavaria, Ohio"
    },
    {
      "city": "Okolona",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Okolona, Ohio"
    },
    {
      "city": "Ridgeville Corners",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ridgeville Corners, Ohio"
    },
    {
      "city": "Greenfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Greenfield, Ohio"
    },
    {
      "city": "Highland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Highland, Ohio"
    },
    {
      "city": "Hillsboro",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hillsboro, Ohio"
    },
    {
      "city": "Leesburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Leesburg, Ohio"
    },
    {
      "city": "Lynchburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lynchburg, Ohio"
    },
    {
      "city": "Mowrystown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mowrystown, Ohio"
    },
    {
      "city": "Sinking Spring",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sinking Spring, Ohio"
    },
    {
      "city": "Carbon Hill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Carbon Hill, Ohio"
    },
    {
      "city": "Haydenville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Haydenville, Ohio"
    },
    {
      "city": "Laurelville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Laurelville, Ohio"
    },
    {
      "city": "Logan",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Logan, Ohio"
    },
    {
      "city": "Murray City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Murray City, Ohio"
    },
    {
      "city": "Rockbridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rockbridge, Ohio"
    },
    {
      "city": "South Bloomingville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Bloomingville, Ohio"
    },
    {
      "city": "Union Furnace",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Union Furnace, Ohio"
    },
    {
      "city": "Berlin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Berlin, Ohio"
    },
    {
      "city": "Big Prairie",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Big Prairie, Ohio"
    },
    {
      "city": "Charm",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Charm, Ohio"
    },
    {
      "city": "Glenmont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Glenmont, Ohio"
    },
    {
      "city": "Holmesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Holmesville, Ohio"
    },
    {
      "city": "Killbuck",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Killbuck, Ohio"
    },
    {
      "city": "Lakeville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lakeville, Ohio"
    },
    {
      "city": "Millersburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Millersburg, Ohio"
    },
    {
      "city": "Mount Hope",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Hope, Ohio"
    },
    {
      "city": "Nashville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nashville, Ohio"
    },
    {
      "city": "Walnut Creek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Walnut Creek, Ohio"
    },
    {
      "city": "Winesburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Winesburg, Ohio"
    },
    {
      "city": "Bellevue",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bellevue, Ohio"
    },
    {
      "city": "Collins",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Collins, Ohio"
    },
    {
      "city": "Greenwich",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Greenwich, Ohio"
    },
    {
      "city": "Monroeville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Monroeville, Ohio"
    },
    {
      "city": "New Haven",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Haven, Ohio"
    },
    {
      "city": "New London",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New London, Ohio"
    },
    {
      "city": "North Fairfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Fairfield, Ohio"
    },
    {
      "city": "Norwalk",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Norwalk, Ohio"
    },
    {
      "city": "Willard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Willard, Ohio"
    },
    {
      "city": "Wakeman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wakeman, Ohio"
    },
    {
      "city": "Coalton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Coalton, Ohio"
    },
    {
      "city": "Jackson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jackson, Ohio"
    },
    {
      "city": "Oak Hill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oak Hill, Ohio"
    },
    {
      "city": "Wellston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wellston, Ohio"
    },
    {
      "city": "Adena",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Adena, Ohio"
    },
    {
      "city": "Amsterdam",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amsterdam, Ohio"
    },
    {
      "city": "Bergholz",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bergholz, Ohio"
    },
    {
      "city": "Bloomingdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bloomingdale, Ohio"
    },
    {
      "city": "Brilliant",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brilliant, Ohio"
    },
    {
      "city": "Dillonvale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dillonvale, Ohio"
    },
    {
      "city": "East Springfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Springfield, Ohio"
    },
    {
      "city": "Empire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Empire, Ohio"
    },
    {
      "city": "Hammondsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hammondsville, Ohio"
    },
    {
      "city": "Irondale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Irondale, Ohio"
    },
    {
      "city": "Mingo Junction",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mingo Junction, Ohio"
    },
    {
      "city": "Mount Pleasant",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Pleasant, Ohio"
    },
    {
      "city": "Piney Fork",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Piney Fork, Ohio"
    },
    {
      "city": "Rayland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rayland, Ohio"
    },
    {
      "city": "Richmond",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Richmond, Ohio"
    },
    {
      "city": "Smithfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Smithfield, Ohio"
    },
    {
      "city": "Steubenville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Steubenville, Ohio"
    },
    {
      "city": "Stratton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stratton, Ohio"
    },
    {
      "city": "Tiltonsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tiltonsville, Ohio"
    },
    {
      "city": "Toronto",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Toronto, Ohio"
    },
    {
      "city": "Wolf Run",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wolf Run, Ohio"
    },
    {
      "city": "Yorkville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Yorkville, Ohio"
    },
    {
      "city": "Bladensburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bladensburg, Ohio"
    },
    {
      "city": "Brinkhaven",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brinkhaven, Ohio"
    },
    {
      "city": "Centerburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Centerburg, Ohio"
    },
    {
      "city": "Danville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Danville, Ohio"
    },
    {
      "city": "Fredericktown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fredericktown, Ohio"
    },
    {
      "city": "Gambier",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gambier, Ohio"
    },
    {
      "city": "Howard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Howard, Ohio"
    },
    {
      "city": "Martinsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Martinsburg, Ohio"
    },
    {
      "city": "Mount Liberty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Liberty, Ohio"
    },
    {
      "city": "Mount Vernon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Vernon, Ohio"
    },
    {
      "city": "Grand River",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grand River, Ohio"
    },
    {
      "city": "Madison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Madison, Ohio"
    },
    {
      "city": "Mentor",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mentor, Ohio"
    },
    {
      "city": "Painesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Painesville, Ohio"
    },
    {
      "city": "Perry",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Perry, Ohio"
    },
    {
      "city": "Wickliffe",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wickliffe, Ohio"
    },
    {
      "city": "Willoughby",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Willoughby, Ohio"
    },
    {
      "city": "Eastlake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Eastlake, Ohio"
    },
    {
      "city": "Chesapeake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chesapeake, Ohio"
    },
    {
      "city": "Ironton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ironton, Ohio"
    },
    {
      "city": "Kitts Hill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kitts Hill, Ohio"
    },
    {
      "city": "Pedro",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pedro, Ohio"
    },
    {
      "city": "Proctorville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Proctorville, Ohio"
    },
    {
      "city": "Rock Camp",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rock Camp, Ohio"
    },
    {
      "city": "Scottown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Scottown, Ohio"
    },
    {
      "city": "South Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Point, Ohio"
    },
    {
      "city": "Waterloo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waterloo, Ohio"
    },
    {
      "city": "Willow Wood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Willow Wood, Ohio"
    },
    {
      "city": "Alexandria",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alexandria, Ohio"
    },
    {
      "city": "Buckeye Lake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Buckeye Lake, Ohio"
    },
    {
      "city": "Croton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Croton, Ohio"
    },
    {
      "city": "Etna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Etna, Ohio"
    },
    {
      "city": "Granville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Granville, Ohio"
    },
    {
      "city": "Hebron",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hebron, Ohio"
    },
    {
      "city": "Homer",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Homer, Ohio"
    },
    {
      "city": "Jacksontown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jacksontown, Ohio"
    },
    {
      "city": "Johnstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Johnstown, Ohio"
    },
    {
      "city": "Kirkersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kirkersville, Ohio"
    },
    {
      "city": "Newark",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newark, Ohio"
    },
    {
      "city": "Heath",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Heath, Ohio"
    },
    {
      "city": "Pataskala",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pataskala, Ohio"
    },
    {
      "city": "Saint Louisville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Louisville, Ohio"
    },
    {
      "city": "Summit Station",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Summit Station, Ohio"
    },
    {
      "city": "Utica",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Utica, Ohio"
    },
    {
      "city": "Brownsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brownsville, Ohio"
    },
    {
      "city": "Gratiot",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gratiot, Ohio"
    },
    {
      "city": "Belle Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Belle Center, Ohio"
    },
    {
      "city": "Bellefontaine",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bellefontaine, Ohio"
    },
    {
      "city": "De Graff",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "De Graff, Ohio"
    },
    {
      "city": "East Liberty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Liberty, Ohio"
    },
    {
      "city": "Huntsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Huntsville, Ohio"
    },
    {
      "city": "Lakeview",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lakeview, Ohio"
    },
    {
      "city": "Lewistown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lewistown, Ohio"
    },
    {
      "city": "Middleburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middleburg, Ohio"
    },
    {
      "city": "Quincy",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Quincy, Ohio"
    },
    {
      "city": "Rushsylvania",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rushsylvania, Ohio"
    },
    {
      "city": "Russells Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Russells Point, Ohio"
    },
    {
      "city": "West Liberty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Liberty, Ohio"
    },
    {
      "city": "West Mansfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Mansfield, Ohio"
    },
    {
      "city": "Zanesfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Zanesfield, Ohio"
    },
    {
      "city": "Amherst",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Amherst, Ohio"
    },
    {
      "city": "Avon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Avon, Ohio"
    },
    {
      "city": "Avon Lake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Avon Lake, Ohio"
    },
    {
      "city": "Columbia Station",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Columbia Station, Ohio"
    },
    {
      "city": "Elyria",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Elyria, Ohio"
    },
    {
      "city": "North Ridgeville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Ridgeville, Ohio"
    },
    {
      "city": "Grafton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grafton, Ohio"
    },
    {
      "city": "Kipton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kipton, Ohio"
    },
    {
      "city": "Lagrange",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lagrange, Ohio"
    },
    {
      "city": "Lorain",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lorain, Ohio"
    },
    {
      "city": "Sheffield Lake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sheffield Lake, Ohio"
    },
    {
      "city": "Oberlin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oberlin, Ohio"
    },
    {
      "city": "Wellington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wellington, Ohio"
    },
    {
      "city": "Harbor View",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harbor View, Ohio"
    },
    {
      "city": "Berkey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Berkey, Ohio"
    },
    {
      "city": "Holland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Holland, Ohio"
    },
    {
      "city": "Maumee",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maumee, Ohio"
    },
    {
      "city": "Monclova",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Monclova, Ohio"
    },
    {
      "city": "Neapolis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Neapolis, Ohio"
    },
    {
      "city": "Sylvania",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sylvania, Ohio"
    },
    {
      "city": "Waterville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waterville, Ohio"
    },
    {
      "city": "Whitehouse",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Whitehouse, Ohio"
    },
    {
      "city": "Toledo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Toledo, Ohio"
    },
    {
      "city": "Oregon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oregon, Ohio"
    },
    {
      "city": "Plain City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Plain City, Ohio"
    },
    {
      "city": "London",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "London, Ohio"
    },
    {
      "city": "Mount Sterling",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Sterling, Ohio"
    },
    {
      "city": "Sedalia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sedalia, Ohio"
    },
    {
      "city": "South Solon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Solon, Ohio"
    },
    {
      "city": "West Jefferson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Jefferson, Ohio"
    },
    {
      "city": "Berlin Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Berlin Center, Ohio"
    },
    {
      "city": "Campbell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Campbell, Ohio"
    },
    {
      "city": "Canfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Canfield, Ohio"
    },
    {
      "city": "Ellsworth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ellsworth, Ohio"
    },
    {
      "city": "Greenford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Greenford, Ohio"
    },
    {
      "city": "Lake Milton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lake Milton, Ohio"
    },
    {
      "city": "Lowellville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lowellville, Ohio"
    },
    {
      "city": "New Middletown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Middletown, Ohio"
    },
    {
      "city": "New Springfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Springfield, Ohio"
    },
    {
      "city": "North Benton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Benton, Ohio"
    },
    {
      "city": "North Jackson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Jackson, Ohio"
    },
    {
      "city": "North Lima",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Lima, Ohio"
    },
    {
      "city": "Petersburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Petersburg, Ohio"
    },
    {
      "city": "Struthers",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Struthers, Ohio"
    },
    {
      "city": "Youngstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Youngstown, Ohio"
    },
    {
      "city": "Beloit",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beloit, Ohio"
    },
    {
      "city": "Damascus",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Damascus, Ohio"
    },
    {
      "city": "Sebring",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sebring, Ohio"
    },
    {
      "city": "Marion",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Marion, Ohio"
    },
    {
      "city": "Caledonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Caledonia, Ohio"
    },
    {
      "city": "Green Camp",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Green Camp, Ohio"
    },
    {
      "city": "La Rue",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "La Rue, Ohio"
    },
    {
      "city": "Martel",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Martel, Ohio"
    },
    {
      "city": "Morral",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Morral, Ohio"
    },
    {
      "city": "New Bloomington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Bloomington, Ohio"
    },
    {
      "city": "Prospect",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Prospect, Ohio"
    },
    {
      "city": "Waldo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waldo, Ohio"
    },
    {
      "city": "Brunswick",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brunswick, Ohio"
    },
    {
      "city": "Chippewa Lake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chippewa Lake, Ohio"
    },
    {
      "city": "Hinckley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hinckley, Ohio"
    },
    {
      "city": "Homerville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Homerville, Ohio"
    },
    {
      "city": "Westfield Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Westfield Center, Ohio"
    },
    {
      "city": "Litchfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Litchfield, Ohio"
    },
    {
      "city": "Lodi",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lodi, Ohio"
    },
    {
      "city": "Medina",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Medina, Ohio"
    },
    {
      "city": "Seville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Seville, Ohio"
    },
    {
      "city": "Sharon Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sharon Center, Ohio"
    },
    {
      "city": "Spencer",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Spencer, Ohio"
    },
    {
      "city": "Valley City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Valley City, Ohio"
    },
    {
      "city": "Wadsworth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wadsworth, Ohio"
    },
    {
      "city": "Chester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chester, Ohio"
    },
    {
      "city": "Langsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Langsville, Ohio"
    },
    {
      "city": "Long Bottom",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Long Bottom, Ohio"
    },
    {
      "city": "Middleport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middleport, Ohio"
    },
    {
      "city": "Pomeroy",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pomeroy, Ohio"
    },
    {
      "city": "Portland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Portland, Ohio"
    },
    {
      "city": "Racine",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Racine, Ohio"
    },
    {
      "city": "Reedsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Reedsville, Ohio"
    },
    {
      "city": "Rutland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rutland, Ohio"
    },
    {
      "city": "Syracuse",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Syracuse, Ohio"
    },
    {
      "city": "Tuppers Plains",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tuppers Plains, Ohio"
    },
    {
      "city": "Burkettsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Burkettsville, Ohio"
    },
    {
      "city": "Celina",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Celina, Ohio"
    },
    {
      "city": "Chickasaw",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chickasaw, Ohio"
    },
    {
      "city": "Coldwater",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Coldwater, Ohio"
    },
    {
      "city": "Fort Recovery",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fort Recovery, Ohio"
    },
    {
      "city": "Maria Stein",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maria Stein, Ohio"
    },
    {
      "city": "Mendon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mendon, Ohio"
    },
    {
      "city": "Montezuma",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Montezuma, Ohio"
    },
    {
      "city": "Rockford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rockford, Ohio"
    },
    {
      "city": "Saint Henry",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Saint Henry, Ohio"
    },
    {
      "city": "Bradford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bradford, Ohio"
    },
    {
      "city": "Casstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Casstown, Ohio"
    },
    {
      "city": "Conover",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Conover, Ohio"
    },
    {
      "city": "Covington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Covington, Ohio"
    },
    {
      "city": "Fletcher",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fletcher, Ohio"
    },
    {
      "city": "Laura",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Laura, Ohio"
    },
    {
      "city": "Ludlow Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ludlow Falls, Ohio"
    },
    {
      "city": "Piqua",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Piqua, Ohio"
    },
    {
      "city": "Pleasant Hill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pleasant Hill, Ohio"
    },
    {
      "city": "Potsdam",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Potsdam, Ohio"
    },
    {
      "city": "Tipp City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tipp City, Ohio"
    },
    {
      "city": "Troy",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Troy, Ohio"
    },
    {
      "city": "West Milton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Milton, Ohio"
    },
    {
      "city": "Beallsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beallsville, Ohio"
    },
    {
      "city": "Jerusalem",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jerusalem, Ohio"
    },
    {
      "city": "Laings",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Laings, Ohio"
    },
    {
      "city": "Lewisville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lewisville, Ohio"
    },
    {
      "city": "Malaga",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Malaga, Ohio"
    },
    {
      "city": "Stafford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stafford, Ohio"
    },
    {
      "city": "Woodsfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Woodsfield, Ohio"
    },
    {
      "city": "Cameron",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cameron, Ohio"
    },
    {
      "city": "Clarington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clarington, Ohio"
    },
    {
      "city": "Hannibal",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hannibal, Ohio"
    },
    {
      "city": "Sardis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sardis, Ohio"
    },
    {
      "city": "Graysville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Graysville, Ohio"
    },
    {
      "city": "Brookville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brookville, Ohio"
    },
    {
      "city": "Clayton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clayton, Ohio"
    },
    {
      "city": "Englewood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Englewood, Ohio"
    },
    {
      "city": "Farmersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Farmersville, Ohio"
    },
    {
      "city": "Germantown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Germantown, Ohio"
    },
    {
      "city": "Miamisburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Miamisburg, Ohio"
    },
    {
      "city": "New Lebanon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Lebanon, Ohio"
    },
    {
      "city": "Phillipsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Phillipsburg, Ohio"
    },
    {
      "city": "Vandalia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vandalia, Ohio"
    },
    {
      "city": "Chesterhill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chesterhill, Ohio"
    },
    {
      "city": "Mcconnelsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mcconnelsville, Ohio"
    },
    {
      "city": "Malta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Malta, Ohio"
    },
    {
      "city": "Stockport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stockport, Ohio"
    },
    {
      "city": "Cardington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cardington, Ohio"
    },
    {
      "city": "Chesterville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chesterville, Ohio"
    },
    {
      "city": "Edison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Edison, Ohio"
    },
    {
      "city": "Fulton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fulton, Ohio"
    },
    {
      "city": "Iberia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Iberia, Ohio"
    },
    {
      "city": "Marengo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Marengo, Ohio"
    },
    {
      "city": "Mount Gilead",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Gilead, Ohio"
    },
    {
      "city": "Shauck",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shauck, Ohio"
    },
    {
      "city": "Sparta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sparta, Ohio"
    },
    {
      "city": "Zanesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Zanesville, Ohio"
    },
    {
      "city": "Blue Rock",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blue Rock, Ohio"
    },
    {
      "city": "Chandlersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chandlersville, Ohio"
    },
    {
      "city": "Duncan Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Duncan Falls, Ohio"
    },
    {
      "city": "East Fultonham",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Fultonham, Ohio"
    },
    {
      "city": "Fultonham",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fultonham, Ohio"
    },
    {
      "city": "Hopewell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hopewell, Ohio"
    },
    {
      "city": "New Concord",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Concord, Ohio"
    },
    {
      "city": "Norwich",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Norwich, Ohio"
    },
    {
      "city": "Philo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Philo, Ohio"
    },
    {
      "city": "Roseville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Roseville, Ohio"
    },
    {
      "city": "White Cottage",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "White Cottage, Ohio"
    },
    {
      "city": "Adamsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Adamsville, Ohio"
    },
    {
      "city": "Dresden",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dresden, Ohio"
    },
    {
      "city": "Frazeysburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Frazeysburg, Ohio"
    },
    {
      "city": "Nashport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nashport, Ohio"
    },
    {
      "city": "Trinway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Trinway, Ohio"
    },
    {
      "city": "Ava",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ava, Ohio"
    },
    {
      "city": "Belle Valley",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Belle Valley, Ohio"
    },
    {
      "city": "Caldwell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Caldwell, Ohio"
    },
    {
      "city": "Sarahsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sarahsville, Ohio"
    },
    {
      "city": "Summerfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Summerfield, Ohio"
    },
    {
      "city": "Dexter City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dexter City, Ohio"
    },
    {
      "city": "Clay Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clay Center, Ohio"
    },
    {
      "city": "Curtice",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Curtice, Ohio"
    },
    {
      "city": "Elmore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Elmore, Ohio"
    },
    {
      "city": "Genoa",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Genoa, Ohio"
    },
    {
      "city": "Graytown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Graytown, Ohio"
    },
    {
      "city": "Gypsum",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gypsum, Ohio"
    },
    {
      "city": "Isle Saint George",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Isle Saint George, Ohio"
    },
    {
      "city": "Lacarne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lacarne, Ohio"
    },
    {
      "city": "Lakeside Marblehead",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lakeside Marblehead, Ohio"
    },
    {
      "city": "Martin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Martin, Ohio"
    },
    {
      "city": "Middle Bass",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middle Bass, Ohio"
    },
    {
      "city": "Oak Harbor",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oak Harbor, Ohio"
    },
    {
      "city": "Port Clinton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Port Clinton, Ohio"
    },
    {
      "city": "Put In Bay",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Put In Bay, Ohio"
    },
    {
      "city": "Rocky Ridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rocky Ridge, Ohio"
    },
    {
      "city": "Williston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Williston, Ohio"
    },
    {
      "city": "Antwerp",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Antwerp, Ohio"
    },
    {
      "city": "Cecil",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cecil, Ohio"
    },
    {
      "city": "Grover Hill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grover Hill, Ohio"
    },
    {
      "city": "Haviland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Haviland, Ohio"
    },
    {
      "city": "Latty",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Latty, Ohio"
    },
    {
      "city": "Melrose",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Melrose, Ohio"
    },
    {
      "city": "Oakwood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oakwood, Ohio"
    },
    {
      "city": "Paulding",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Paulding, Ohio"
    },
    {
      "city": "Payne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Payne, Ohio"
    },
    {
      "city": "Thornville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Thornville, Ohio"
    },
    {
      "city": "Corning",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Corning, Ohio"
    },
    {
      "city": "Crooksville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Crooksville, Ohio"
    },
    {
      "city": "Glenford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Glenford, Ohio"
    },
    {
      "city": "Junction City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Junction City, Ohio"
    },
    {
      "city": "Mount Perry",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Perry, Ohio"
    },
    {
      "city": "Moxahala",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Moxahala, Ohio"
    },
    {
      "city": "New Lexington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Lexington, Ohio"
    },
    {
      "city": "New Straitsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Straitsville, Ohio"
    },
    {
      "city": "Shawnee",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shawnee, Ohio"
    },
    {
      "city": "Somerset",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Somerset, Ohio"
    },
    {
      "city": "Ashville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ashville, Ohio"
    },
    {
      "city": "Circleville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Circleville, Ohio"
    },
    {
      "city": "Commercial Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Commercial Point, Ohio"
    },
    {
      "city": "Derby",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Derby, Ohio"
    },
    {
      "city": "New Holland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Holland, Ohio"
    },
    {
      "city": "Orient",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Orient, Ohio"
    },
    {
      "city": "Tarlton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tarlton, Ohio"
    },
    {
      "city": "Williamsport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Williamsport, Ohio"
    },
    {
      "city": "Beaver",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beaver, Ohio"
    },
    {
      "city": "Cynthiana",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cynthiana, Ohio"
    },
    {
      "city": "Jasper",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jasper, Ohio"
    },
    {
      "city": "Latham",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Latham, Ohio"
    },
    {
      "city": "Piketon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Piketon, Ohio"
    },
    {
      "city": "Stockdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stockdale, Ohio"
    },
    {
      "city": "Wakefield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wakefield, Ohio"
    },
    {
      "city": "Waverly",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waverly, Ohio"
    },
    {
      "city": "Atwater",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Atwater, Ohio"
    },
    {
      "city": "Aurora",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Aurora, Ohio"
    },
    {
      "city": "Brady Lake",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brady Lake, Ohio"
    },
    {
      "city": "Garrettsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Garrettsville, Ohio"
    },
    {
      "city": "Hiram",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hiram, Ohio"
    },
    {
      "city": "Kent",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kent, Ohio"
    },
    {
      "city": "Streetsboro",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Streetsboro, Ohio"
    },
    {
      "city": "Mantua",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mantua, Ohio"
    },
    {
      "city": "Randolph",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Randolph, Ohio"
    },
    {
      "city": "Ravenna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ravenna, Ohio"
    },
    {
      "city": "Rootstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rootstown, Ohio"
    },
    {
      "city": "Wayland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wayland, Ohio"
    },
    {
      "city": "Windham",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Windham, Ohio"
    },
    {
      "city": "Deerfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Deerfield, Ohio"
    },
    {
      "city": "Diamond",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Diamond, Ohio"
    },
    {
      "city": "West Elkton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Elkton, Ohio"
    },
    {
      "city": "Camden",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Camden, Ohio"
    },
    {
      "city": "Eaton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Eaton, Ohio"
    },
    {
      "city": "Eldorado",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Eldorado, Ohio"
    },
    {
      "city": "Gratis",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gratis, Ohio"
    },
    {
      "city": "Lewisburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lewisburg, Ohio"
    },
    {
      "city": "New Paris",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Paris, Ohio"
    },
    {
      "city": "Verona",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Verona, Ohio"
    },
    {
      "city": "West Alexandria",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Alexandria, Ohio"
    },
    {
      "city": "West Manchester",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Manchester, Ohio"
    },
    {
      "city": "Belmore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Belmore, Ohio"
    },
    {
      "city": "Cloverdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cloverdale, Ohio"
    },
    {
      "city": "Columbus Grove",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Columbus Grove, Ohio"
    },
    {
      "city": "Continental",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Continental, Ohio"
    },
    {
      "city": "Dupont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dupont, Ohio"
    },
    {
      "city": "Fort Jennings",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fort Jennings, Ohio"
    },
    {
      "city": "Glandorf",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Glandorf, Ohio"
    },
    {
      "city": "Kalida",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kalida, Ohio"
    },
    {
      "city": "Leipsic",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Leipsic, Ohio"
    },
    {
      "city": "Miller City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Miller City, Ohio"
    },
    {
      "city": "Ottawa",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ottawa, Ohio"
    },
    {
      "city": "Ottoville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ottoville, Ohio"
    },
    {
      "city": "Pandora",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pandora, Ohio"
    },
    {
      "city": "Vaughnsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vaughnsville, Ohio"
    },
    {
      "city": "Bellville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bellville, Ohio"
    },
    {
      "city": "Butler",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Butler, Ohio"
    },
    {
      "city": "Lucas",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lucas, Ohio"
    },
    {
      "city": "Ontario",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ontario, Ohio"
    },
    {
      "city": "Plymouth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Plymouth, Ohio"
    },
    {
      "city": "Shelby",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shelby, Ohio"
    },
    {
      "city": "Shiloh",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shiloh, Ohio"
    },
    {
      "city": "Mansfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mansfield, Ohio"
    },
    {
      "city": "Adelphi",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Adelphi, Ohio"
    },
    {
      "city": "Clarksburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clarksburg, Ohio"
    },
    {
      "city": "Chillicothe",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Chillicothe, Ohio"
    },
    {
      "city": "Bainbridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bainbridge, Ohio"
    },
    {
      "city": "Bourneville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bourneville, Ohio"
    },
    {
      "city": "Frankfort",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Frankfort, Ohio"
    },
    {
      "city": "Hallsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hallsville, Ohio"
    },
    {
      "city": "Kingston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kingston, Ohio"
    },
    {
      "city": "Londonderry",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Londonderry, Ohio"
    },
    {
      "city": "Richmond Dale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Richmond Dale, Ohio"
    },
    {
      "city": "South Salem",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Salem, Ohio"
    },
    {
      "city": "Burgoon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Burgoon, Ohio"
    },
    {
      "city": "Clyde",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clyde, Ohio"
    },
    {
      "city": "Fremont",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fremont, Ohio"
    },
    {
      "city": "Gibsonburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gibsonburg, Ohio"
    },
    {
      "city": "Helena",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Helena, Ohio"
    },
    {
      "city": "Lindsey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lindsey, Ohio"
    },
    {
      "city": "Vickery",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vickery, Ohio"
    },
    {
      "city": "Woodville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Woodville, Ohio"
    },
    {
      "city": "Franklin Furnace",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Franklin Furnace, Ohio"
    },
    {
      "city": "Friendship",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Friendship, Ohio"
    },
    {
      "city": "Haverhill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Haverhill, Ohio"
    },
    {
      "city": "Lucasville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lucasville, Ohio"
    },
    {
      "city": "Mc Dermott",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Dermott, Ohio"
    },
    {
      "city": "Minford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Minford, Ohio"
    },
    {
      "city": "Otway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Otway, Ohio"
    },
    {
      "city": "Portsmouth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Portsmouth, Ohio"
    },
    {
      "city": "West Portsmouth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Portsmouth, Ohio"
    },
    {
      "city": "Rarden",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rarden, Ohio"
    },
    {
      "city": "Scioto Furnace",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Scioto Furnace, Ohio"
    },
    {
      "city": "South Webster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Webster, Ohio"
    },
    {
      "city": "Stout",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stout, Ohio"
    },
    {
      "city": "Wheelersburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wheelersburg, Ohio"
    },
    {
      "city": "Alvada",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alvada, Ohio"
    },
    {
      "city": "Attica",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Attica, Ohio"
    },
    {
      "city": "Bascom",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bascom, Ohio"
    },
    {
      "city": "Bettsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bettsville, Ohio"
    },
    {
      "city": "Bloomville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bloomville, Ohio"
    },
    {
      "city": "Flat Rock",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Flat Rock, Ohio"
    },
    {
      "city": "Fostoria",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fostoria, Ohio"
    },
    {
      "city": "Green Springs",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Green Springs, Ohio"
    },
    {
      "city": "Kansas",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kansas, Ohio"
    },
    {
      "city": "Melmore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Melmore, Ohio"
    },
    {
      "city": "New Riegel",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Riegel, Ohio"
    },
    {
      "city": "Old Fort",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Old Fort, Ohio"
    },
    {
      "city": "Republic",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Republic, Ohio"
    },
    {
      "city": "Tiffin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tiffin, Ohio"
    },
    {
      "city": "Anna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Anna, Ohio"
    },
    {
      "city": "Botkins",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Botkins, Ohio"
    },
    {
      "city": "Houston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Houston, Ohio"
    },
    {
      "city": "Jackson Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jackson Center, Ohio"
    },
    {
      "city": "Kettlersville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kettlersville, Ohio"
    },
    {
      "city": "Maplewood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maplewood, Ohio"
    },
    {
      "city": "Pemberton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pemberton, Ohio"
    },
    {
      "city": "Port Jefferson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Port Jefferson, Ohio"
    },
    {
      "city": "Russia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Russia, Ohio"
    },
    {
      "city": "Sidney",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sidney, Ohio"
    },
    {
      "city": "Fort Loramie",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fort Loramie, Ohio"
    },
    {
      "city": "Alliance",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alliance, Ohio"
    },
    {
      "city": "Beach City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beach City, Ohio"
    },
    {
      "city": "Brewster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brewster, Ohio"
    },
    {
      "city": "Canal Fulton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Canal Fulton, Ohio"
    },
    {
      "city": "East Sparta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Sparta, Ohio"
    },
    {
      "city": "Greentown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Greentown, Ohio"
    },
    {
      "city": "Hartville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hartville, Ohio"
    },
    {
      "city": "Limaville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Limaville, Ohio"
    },
    {
      "city": "Louisville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Louisville, Ohio"
    },
    {
      "city": "Magnolia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Magnolia, Ohio"
    },
    {
      "city": "Massillon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Massillon, Ohio"
    },
    {
      "city": "Maximo",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maximo, Ohio"
    },
    {
      "city": "Middlebranch",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middlebranch, Ohio"
    },
    {
      "city": "Minerva",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Minerva, Ohio"
    },
    {
      "city": "Navarre",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Navarre, Ohio"
    },
    {
      "city": "North Lawrence",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Lawrence, Ohio"
    },
    {
      "city": "Paris",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Paris, Ohio"
    },
    {
      "city": "Robertsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Robertsville, Ohio"
    },
    {
      "city": "Waynesburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waynesburg, Ohio"
    },
    {
      "city": "Wilmot",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wilmot, Ohio"
    },
    {
      "city": "Canton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Canton, Ohio"
    },
    {
      "city": "North Canton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Canton, Ohio"
    },
    {
      "city": "East Canton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "East Canton, Ohio"
    },
    {
      "city": "Macedonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Macedonia, Ohio"
    },
    {
      "city": "Northfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Northfield, Ohio"
    },
    {
      "city": "Twinsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Twinsburg, Ohio"
    },
    {
      "city": "Barberton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Barberton, Ohio"
    },
    {
      "city": "Bath",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bath, Ohio"
    },
    {
      "city": "Clinton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Clinton, Ohio"
    },
    {
      "city": "Cuyahoga Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cuyahoga Falls, Ohio"
    },
    {
      "city": "Stow",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stow, Ohio"
    },
    {
      "city": "Green",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Green, Ohio"
    },
    {
      "city": "Hudson",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hudson, Ohio"
    },
    {
      "city": "Lakemore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lakemore, Ohio"
    },
    {
      "city": "Mogadore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mogadore, Ohio"
    },
    {
      "city": "Munroe Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Munroe Falls, Ohio"
    },
    {
      "city": "Peninsula",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Peninsula, Ohio"
    },
    {
      "city": "Tallmadge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tallmadge, Ohio"
    },
    {
      "city": "Richfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Richfield, Ohio"
    },
    {
      "city": "Akron",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Akron, Ohio"
    },
    {
      "city": "Fairlawn",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fairlawn, Ohio"
    },
    {
      "city": "Uniontown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Uniontown, Ohio"
    },
    {
      "city": "Bristolville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bristolville, Ohio"
    },
    {
      "city": "Brookfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Brookfield, Ohio"
    },
    {
      "city": "Burghill",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Burghill, Ohio"
    },
    {
      "city": "Cortland",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cortland, Ohio"
    },
    {
      "city": "Farmdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Farmdale, Ohio"
    },
    {
      "city": "Fowler",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fowler, Ohio"
    },
    {
      "city": "Girard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Girard, Ohio"
    },
    {
      "city": "Hartford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hartford, Ohio"
    },
    {
      "city": "Hubbard",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hubbard, Ohio"
    },
    {
      "city": "Kinsman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kinsman, Ohio"
    },
    {
      "city": "Leavittsburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Leavittsburg, Ohio"
    },
    {
      "city": "Mc Donald",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Donald, Ohio"
    },
    {
      "city": "Masury",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Masury, Ohio"
    },
    {
      "city": "Mesopotamia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mesopotamia, Ohio"
    },
    {
      "city": "Mineral Ridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mineral Ridge, Ohio"
    },
    {
      "city": "Newton Falls",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newton Falls, Ohio"
    },
    {
      "city": "Niles",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Niles, Ohio"
    },
    {
      "city": "North Bloomfield",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Bloomfield, Ohio"
    },
    {
      "city": "Orangeville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Orangeville, Ohio"
    },
    {
      "city": "Southington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Southington, Ohio"
    },
    {
      "city": "Vienna",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vienna, Ohio"
    },
    {
      "city": "Warren",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Warren, Ohio"
    },
    {
      "city": "West Farmington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Farmington, Ohio"
    },
    {
      "city": "Baltic",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Baltic, Ohio"
    },
    {
      "city": "Newcomerstown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newcomerstown, Ohio"
    },
    {
      "city": "Port Washington",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Port Washington, Ohio"
    },
    {
      "city": "Stone Creek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stone Creek, Ohio"
    },
    {
      "city": "Bolivar",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bolivar, Ohio"
    },
    {
      "city": "Dennison",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dennison, Ohio"
    },
    {
      "city": "Dover",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dover, Ohio"
    },
    {
      "city": "Dundee",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dundee, Ohio"
    },
    {
      "city": "Gnadenhutten",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Gnadenhutten, Ohio"
    },
    {
      "city": "Midvale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Midvale, Ohio"
    },
    {
      "city": "Mineral City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mineral City, Ohio"
    },
    {
      "city": "New Philadelphia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Philadelphia, Ohio"
    },
    {
      "city": "Sandyville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sandyville, Ohio"
    },
    {
      "city": "Somerdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Somerdale, Ohio"
    },
    {
      "city": "Stillwater",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stillwater, Ohio"
    },
    {
      "city": "Strasburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Strasburg, Ohio"
    },
    {
      "city": "Sugarcreek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sugarcreek, Ohio"
    },
    {
      "city": "Tuscarawas",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tuscarawas, Ohio"
    },
    {
      "city": "Uhrichsville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Uhrichsville, Ohio"
    },
    {
      "city": "Zoar",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Zoar, Ohio"
    },
    {
      "city": "Broadway",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Broadway, Ohio"
    },
    {
      "city": "Irwin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Irwin, Ohio"
    },
    {
      "city": "Magnetic Springs",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Magnetic Springs, Ohio"
    },
    {
      "city": "Marysville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Marysville, Ohio"
    },
    {
      "city": "Milford Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Milford Center, Ohio"
    },
    {
      "city": "Raymond",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Raymond, Ohio"
    },
    {
      "city": "Unionville Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Unionville Center, Ohio"
    },
    {
      "city": "Richwood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Richwood, Ohio"
    },
    {
      "city": "Convoy",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Convoy, Ohio"
    },
    {
      "city": "Elgin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Elgin, Ohio"
    },
    {
      "city": "Middle Point",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Middle Point, Ohio"
    },
    {
      "city": "Ohio City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ohio City, Ohio"
    },
    {
      "city": "Scott",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Scott, Ohio"
    },
    {
      "city": "Van Wert",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Van Wert, Ohio"
    },
    {
      "city": "Venedocia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Venedocia, Ohio"
    },
    {
      "city": "Willshire",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Willshire, Ohio"
    },
    {
      "city": "Wren",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wren, Ohio"
    },
    {
      "city": "Creola",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Creola, Ohio"
    },
    {
      "city": "Hamden",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hamden, Ohio"
    },
    {
      "city": "Mc Arthur",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Arthur, Ohio"
    },
    {
      "city": "New Plymouth",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Plymouth, Ohio"
    },
    {
      "city": "Ray",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Ray, Ohio"
    },
    {
      "city": "Wilkesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wilkesville, Ohio"
    },
    {
      "city": "Zaleski",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Zaleski, Ohio"
    },
    {
      "city": "Franklin",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Franklin, Ohio"
    },
    {
      "city": "Harveysburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harveysburg, Ohio"
    },
    {
      "city": "Kings Mills",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kings Mills, Ohio"
    },
    {
      "city": "Lebanon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lebanon, Ohio"
    },
    {
      "city": "Maineville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Maineville, Ohio"
    },
    {
      "city": "Mason",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mason, Ohio"
    },
    {
      "city": "Oregonia",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Oregonia, Ohio"
    },
    {
      "city": "South Lebanon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "South Lebanon, Ohio"
    },
    {
      "city": "Springboro",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Springboro, Ohio"
    },
    {
      "city": "Waynesville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waynesville, Ohio"
    },
    {
      "city": "Morrow",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Morrow, Ohio"
    },
    {
      "city": "Pleasant Plain",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pleasant Plain, Ohio"
    },
    {
      "city": "Barlow",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Barlow, Ohio"
    },
    {
      "city": "Bartlett",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bartlett, Ohio"
    },
    {
      "city": "Belpre",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Belpre, Ohio"
    },
    {
      "city": "Beverly",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Beverly, Ohio"
    },
    {
      "city": "Coal Run",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Coal Run, Ohio"
    },
    {
      "city": "Cutler",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cutler, Ohio"
    },
    {
      "city": "Fleming",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fleming, Ohio"
    },
    {
      "city": "Little Hocking",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Little Hocking, Ohio"
    },
    {
      "city": "Lowell",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lowell, Ohio"
    },
    {
      "city": "Lower Salem",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lower Salem, Ohio"
    },
    {
      "city": "Macksburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Macksburg, Ohio"
    },
    {
      "city": "Marietta",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Marietta, Ohio"
    },
    {
      "city": "New Matamoras",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "New Matamoras, Ohio"
    },
    {
      "city": "Newport",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Newport, Ohio"
    },
    {
      "city": "Reno",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Reno, Ohio"
    },
    {
      "city": "Vincent",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Vincent, Ohio"
    },
    {
      "city": "Waterford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Waterford, Ohio"
    },
    {
      "city": "Watertown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Watertown, Ohio"
    },
    {
      "city": "Whipple",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Whipple, Ohio"
    },
    {
      "city": "Wingett Run",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wingett Run, Ohio"
    },
    {
      "city": "Burbank",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Burbank, Ohio"
    },
    {
      "city": "Creston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Creston, Ohio"
    },
    {
      "city": "Doylestown",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Doylestown, Ohio"
    },
    {
      "city": "Rittman",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rittman, Ohio"
    },
    {
      "city": "Sterling",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sterling, Ohio"
    },
    {
      "city": "West Salem",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Salem, Ohio"
    },
    {
      "city": "Apple Creek",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Apple Creek, Ohio"
    },
    {
      "city": "Dalton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dalton, Ohio"
    },
    {
      "city": "Fredericksburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Fredericksburg, Ohio"
    },
    {
      "city": "Kidron",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kidron, Ohio"
    },
    {
      "city": "Marshallville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Marshallville, Ohio"
    },
    {
      "city": "Mount Eaton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mount Eaton, Ohio"
    },
    {
      "city": "Orrville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Orrville, Ohio"
    },
    {
      "city": "Shreve",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Shreve, Ohio"
    },
    {
      "city": "Smithville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Smithville, Ohio"
    },
    {
      "city": "Wooster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wooster, Ohio"
    },
    {
      "city": "Alvordton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Alvordton, Ohio"
    },
    {
      "city": "Blakeslee",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Blakeslee, Ohio"
    },
    {
      "city": "Bryan",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bryan, Ohio"
    },
    {
      "city": "Edgerton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Edgerton, Ohio"
    },
    {
      "city": "Edon",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Edon, Ohio"
    },
    {
      "city": "Kunkle",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kunkle, Ohio"
    },
    {
      "city": "Montpelier",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Montpelier, Ohio"
    },
    {
      "city": "Pioneer",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pioneer, Ohio"
    },
    {
      "city": "Stryker",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stryker, Ohio"
    },
    {
      "city": "West Unity",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Unity, Ohio"
    },
    {
      "city": "Bowling Green",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bowling Green, Ohio"
    },
    {
      "city": "Bradner",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bradner, Ohio"
    },
    {
      "city": "Cygnet",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Cygnet, Ohio"
    },
    {
      "city": "Dunbridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Dunbridge, Ohio"
    },
    {
      "city": "Jerry City",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Jerry City, Ohio"
    },
    {
      "city": "Lemoyne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Lemoyne, Ohio"
    },
    {
      "city": "Luckey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Luckey, Ohio"
    },
    {
      "city": "Millbury",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Millbury, Ohio"
    },
    {
      "city": "Pemberville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Pemberville, Ohio"
    },
    {
      "city": "Portage",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Portage, Ohio"
    },
    {
      "city": "Risingsun",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Risingsun, Ohio"
    },
    {
      "city": "Rossford",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rossford, Ohio"
    },
    {
      "city": "Rudolph",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Rudolph, Ohio"
    },
    {
      "city": "Stony Ridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Stony Ridge, Ohio"
    },
    {
      "city": "Walbridge",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Walbridge, Ohio"
    },
    {
      "city": "Wayne",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wayne, Ohio"
    },
    {
      "city": "West Millgrove",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "West Millgrove, Ohio"
    },
    {
      "city": "Custar",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Custar, Ohio"
    },
    {
      "city": "Grand Rapids",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Grand Rapids, Ohio"
    },
    {
      "city": "Haskins",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Haskins, Ohio"
    },
    {
      "city": "Hoytville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Hoytville, Ohio"
    },
    {
      "city": "Milton Center",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Milton Center, Ohio"
    },
    {
      "city": "Perrysburg",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Perrysburg, Ohio"
    },
    {
      "city": "Tontogany",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Tontogany, Ohio"
    },
    {
      "city": "Weston",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Weston, Ohio"
    },
    {
      "city": "Northwood",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Northwood, Ohio"
    },
    {
      "city": "Bloomdale",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Bloomdale, Ohio"
    },
    {
      "city": "North Baltimore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "North Baltimore, Ohio"
    },
    {
      "city": "Carey",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Carey, Ohio"
    },
    {
      "city": "Harpster",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Harpster, Ohio"
    },
    {
      "city": "Kirby",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Kirby, Ohio"
    },
    {
      "city": "Upper Sandusky",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Upper Sandusky, Ohio"
    },
    {
      "city": "Wharton",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Wharton, Ohio"
    },
    {
      "city": "Mc Cutchenville",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Mc Cutchenville, Ohio"
    },
    {
      "city": "Nevada",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Nevada, Ohio"
    },
    {
      "city": "Sycamore",
      "state": "Ohio",
      "shortState": "OH",
      "formatedCity": "Sycamore, Ohio"
    },
    {
      "city": "Proctor",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Proctor, Oklahoma"
    },
    {
      "city": "Bunch",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bunch, Oklahoma"
    },
    {
      "city": "Stilwell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stilwell, Oklahoma"
    },
    {
      "city": "Watts",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Watts, Oklahoma"
    },
    {
      "city": "Westville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Westville, Oklahoma"
    },
    {
      "city": "Aline",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Aline, Oklahoma"
    },
    {
      "city": "Amorita",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Amorita, Oklahoma"
    },
    {
      "city": "Burlington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Burlington, Oklahoma"
    },
    {
      "city": "Carmen",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Carmen, Oklahoma"
    },
    {
      "city": "Cherokee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cherokee, Oklahoma"
    },
    {
      "city": "Goltry",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Goltry, Oklahoma"
    },
    {
      "city": "Helena",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Helena, Oklahoma"
    },
    {
      "city": "Jet",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Jet, Oklahoma"
    },
    {
      "city": "Atoka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Atoka, Oklahoma"
    },
    {
      "city": "Caney",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Caney, Oklahoma"
    },
    {
      "city": "Daisy",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Daisy, Oklahoma"
    },
    {
      "city": "Lane",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lane, Oklahoma"
    },
    {
      "city": "Stringtown",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stringtown, Oklahoma"
    },
    {
      "city": "Gate",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gate, Oklahoma"
    },
    {
      "city": "Balko",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Balko, Oklahoma"
    },
    {
      "city": "Beaver",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Beaver, Oklahoma"
    },
    {
      "city": "Forgan",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Forgan, Oklahoma"
    },
    {
      "city": "Turpin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Turpin, Oklahoma"
    },
    {
      "city": "Carter",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Carter, Oklahoma"
    },
    {
      "city": "Elk City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Elk City, Oklahoma"
    },
    {
      "city": "Erick",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Erick, Oklahoma"
    },
    {
      "city": "Sayre",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sayre, Oklahoma"
    },
    {
      "city": "Texola",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Texola, Oklahoma"
    },
    {
      "city": "Geary",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Geary, Oklahoma"
    },
    {
      "city": "Greenfield",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Greenfield, Oklahoma"
    },
    {
      "city": "Canton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Canton, Oklahoma"
    },
    {
      "city": "Hitchcock",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hitchcock, Oklahoma"
    },
    {
      "city": "Longdale",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Longdale, Oklahoma"
    },
    {
      "city": "Okeene",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Okeene, Oklahoma"
    },
    {
      "city": "Southard",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Southard, Oklahoma"
    },
    {
      "city": "Watonga",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Watonga, Oklahoma"
    },
    {
      "city": "Mead",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mead, Oklahoma"
    },
    {
      "city": "Durant",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Durant, Oklahoma"
    },
    {
      "city": "Achille",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Achille, Oklahoma"
    },
    {
      "city": "Albany",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Albany, Oklahoma"
    },
    {
      "city": "Bennington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bennington, Oklahoma"
    },
    {
      "city": "Bokchito",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bokchito, Oklahoma"
    },
    {
      "city": "Caddo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Caddo, Oklahoma"
    },
    {
      "city": "Calera",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Calera, Oklahoma"
    },
    {
      "city": "Cartwright",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cartwright, Oklahoma"
    },
    {
      "city": "Colbert",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Colbert, Oklahoma"
    },
    {
      "city": "Hendrix",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hendrix, Oklahoma"
    },
    {
      "city": "Kemp",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kemp, Oklahoma"
    },
    {
      "city": "Kenefic",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kenefic, Oklahoma"
    },
    {
      "city": "Platter",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Platter, Oklahoma"
    },
    {
      "city": "Albert",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Albert, Oklahoma"
    },
    {
      "city": "Anadarko",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Anadarko, Oklahoma"
    },
    {
      "city": "Apache",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Apache, Oklahoma"
    },
    {
      "city": "Binger",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Binger, Oklahoma"
    },
    {
      "city": "Carnegie",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Carnegie, Oklahoma"
    },
    {
      "city": "Cement",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cement, Oklahoma"
    },
    {
      "city": "Cyril",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cyril, Oklahoma"
    },
    {
      "city": "Eakly",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Eakly, Oklahoma"
    },
    {
      "city": "Fort Cobb",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fort Cobb, Oklahoma"
    },
    {
      "city": "Gracemont",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gracemont, Oklahoma"
    },
    {
      "city": "Hinton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hinton, Oklahoma"
    },
    {
      "city": "Hydro",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hydro, Oklahoma"
    },
    {
      "city": "Lookeba",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lookeba, Oklahoma"
    },
    {
      "city": "Calumet",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Calumet, Oklahoma"
    },
    {
      "city": "Concho",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Concho, Oklahoma"
    },
    {
      "city": "El Reno",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "El Reno, Oklahoma"
    },
    {
      "city": "Mustang",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mustang, Oklahoma"
    },
    {
      "city": "Piedmont",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Piedmont, Oklahoma"
    },
    {
      "city": "Yukon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Yukon, Oklahoma"
    },
    {
      "city": "Union City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Union City, Oklahoma"
    },
    {
      "city": "Ardmore",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ardmore, Oklahoma"
    },
    {
      "city": "Fox",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fox, Oklahoma"
    },
    {
      "city": "Gene Autry",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gene Autry, Oklahoma"
    },
    {
      "city": "Graham",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Graham, Oklahoma"
    },
    {
      "city": "Healdton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Healdton, Oklahoma"
    },
    {
      "city": "Lone Grove",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lone Grove, Oklahoma"
    },
    {
      "city": "Springer",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Springer, Oklahoma"
    },
    {
      "city": "Wilson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wilson, Oklahoma"
    },
    {
      "city": "Ratliff City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ratliff City, Oklahoma"
    },
    {
      "city": "Tatums",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tatums, Oklahoma"
    },
    {
      "city": "Tussy",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tussy, Oklahoma"
    },
    {
      "city": "Cookson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cookson, Oklahoma"
    },
    {
      "city": "Hulbert",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hulbert, Oklahoma"
    },
    {
      "city": "Moodys",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Moodys, Oklahoma"
    },
    {
      "city": "Park Hill",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Park Hill, Oklahoma"
    },
    {
      "city": "Peggs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Peggs, Oklahoma"
    },
    {
      "city": "Tahlequah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tahlequah, Oklahoma"
    },
    {
      "city": "Welling",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Welling, Oklahoma"
    },
    {
      "city": "Boswell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Boswell, Oklahoma"
    },
    {
      "city": "Fort Towson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fort Towson, Oklahoma"
    },
    {
      "city": "Grant",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Grant, Oklahoma"
    },
    {
      "city": "Hugo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hugo, Oklahoma"
    },
    {
      "city": "Sawyer",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sawyer, Oklahoma"
    },
    {
      "city": "Soper",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Soper, Oklahoma"
    },
    {
      "city": "Spencerville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Spencerville, Oklahoma"
    },
    {
      "city": "Swink",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Swink, Oklahoma"
    },
    {
      "city": "Boise City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Boise City, Oklahoma"
    },
    {
      "city": "Felt",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Felt, Oklahoma"
    },
    {
      "city": "Kenton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kenton, Oklahoma"
    },
    {
      "city": "Keyes",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Keyes, Oklahoma"
    },
    {
      "city": "Norman",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Norman, Oklahoma"
    },
    {
      "city": "Lexington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lexington, Oklahoma"
    },
    {
      "city": "Noble",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Noble, Oklahoma"
    },
    {
      "city": "Oklahoma City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oklahoma City, Oklahoma"
    },
    {
      "city": "Centrahoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Centrahoma, Oklahoma"
    },
    {
      "city": "Clarita",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Clarita, Oklahoma"
    },
    {
      "city": "Coalgate",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Coalgate, Oklahoma"
    },
    {
      "city": "Lehigh",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lehigh, Oklahoma"
    },
    {
      "city": "Tupelo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tupelo, Oklahoma"
    },
    {
      "city": "Lawton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lawton, Oklahoma"
    },
    {
      "city": "Fort Sill",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fort Sill, Oklahoma"
    },
    {
      "city": "Cache",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cache, Oklahoma"
    },
    {
      "city": "Chattanooga",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chattanooga, Oklahoma"
    },
    {
      "city": "Elgin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Elgin, Oklahoma"
    },
    {
      "city": "Faxon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Faxon, Oklahoma"
    },
    {
      "city": "Fletcher",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fletcher, Oklahoma"
    },
    {
      "city": "Geronimo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Geronimo, Oklahoma"
    },
    {
      "city": "Indiahoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Indiahoma, Oklahoma"
    },
    {
      "city": "Medicine Park",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Medicine Park, Oklahoma"
    },
    {
      "city": "Meers",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Meers, Oklahoma"
    },
    {
      "city": "Sterling",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sterling, Oklahoma"
    },
    {
      "city": "Devol",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Devol, Oklahoma"
    },
    {
      "city": "Randlett",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Randlett, Oklahoma"
    },
    {
      "city": "Temple",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Temple, Oklahoma"
    },
    {
      "city": "Walters",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Walters, Oklahoma"
    },
    {
      "city": "Vinita",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Vinita, Oklahoma"
    },
    {
      "city": "Big Cabin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Big Cabin, Oklahoma"
    },
    {
      "city": "Bluejacket",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bluejacket, Oklahoma"
    },
    {
      "city": "Welch",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Welch, Oklahoma"
    },
    {
      "city": "Bristow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bristow, Oklahoma"
    },
    {
      "city": "Depew",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Depew, Oklahoma"
    },
    {
      "city": "Drumright",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Drumright, Oklahoma"
    },
    {
      "city": "Kellyville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kellyville, Oklahoma"
    },
    {
      "city": "Kiefer",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kiefer, Oklahoma"
    },
    {
      "city": "Mannford",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mannford, Oklahoma"
    },
    {
      "city": "Milfay",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Milfay, Oklahoma"
    },
    {
      "city": "Mounds",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mounds, Oklahoma"
    },
    {
      "city": "Oilton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oilton, Oklahoma"
    },
    {
      "city": "Sapulpa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sapulpa, Oklahoma"
    },
    {
      "city": "Shamrock",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Shamrock, Oklahoma"
    },
    {
      "city": "Slick",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Slick, Oklahoma"
    },
    {
      "city": "Tulsa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tulsa, Oklahoma"
    },
    {
      "city": "Weatherford",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Weatherford, Oklahoma"
    },
    {
      "city": "Clinton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Clinton, Oklahoma"
    },
    {
      "city": "Arapaho",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Arapaho, Oklahoma"
    },
    {
      "city": "Butler",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Butler, Oklahoma"
    },
    {
      "city": "Custer City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Custer City, Oklahoma"
    },
    {
      "city": "Thomas",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Thomas, Oklahoma"
    },
    {
      "city": "Colcord",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Colcord, Oklahoma"
    },
    {
      "city": "Eucha",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Eucha, Oklahoma"
    },
    {
      "city": "Grove",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Grove, Oklahoma"
    },
    {
      "city": "Jay",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Jay, Oklahoma"
    },
    {
      "city": "Kansas",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kansas, Oklahoma"
    },
    {
      "city": "Oaks",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oaks, Oklahoma"
    },
    {
      "city": "Twin Oaks",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Twin Oaks, Oklahoma"
    },
    {
      "city": "Fay",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fay, Oklahoma"
    },
    {
      "city": "Leedey",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Leedey, Oklahoma"
    },
    {
      "city": "Oakwood",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oakwood, Oklahoma"
    },
    {
      "city": "Putnam",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Putnam, Oklahoma"
    },
    {
      "city": "Seiling",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Seiling, Oklahoma"
    },
    {
      "city": "Taloga",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Taloga, Oklahoma"
    },
    {
      "city": "Camargo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Camargo, Oklahoma"
    },
    {
      "city": "Vici",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Vici, Oklahoma"
    },
    {
      "city": "Arnett",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Arnett, Oklahoma"
    },
    {
      "city": "Fargo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fargo, Oklahoma"
    },
    {
      "city": "Gage",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gage, Oklahoma"
    },
    {
      "city": "Shattuck",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Shattuck, Oklahoma"
    },
    {
      "city": "Enid",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Enid, Oklahoma"
    },
    {
      "city": "Bison",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bison, Oklahoma"
    },
    {
      "city": "Carrier",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Carrier, Oklahoma"
    },
    {
      "city": "Covington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Covington, Oklahoma"
    },
    {
      "city": "Douglas",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Douglas, Oklahoma"
    },
    {
      "city": "Drummond",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Drummond, Oklahoma"
    },
    {
      "city": "Fairmont",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fairmont, Oklahoma"
    },
    {
      "city": "Garber",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Garber, Oklahoma"
    },
    {
      "city": "Hillsdale",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hillsdale, Oklahoma"
    },
    {
      "city": "Kremlin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kremlin, Oklahoma"
    },
    {
      "city": "Lahoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lahoma, Oklahoma"
    },
    {
      "city": "Waukomis",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Waukomis, Oklahoma"
    },
    {
      "city": "Hunter",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hunter, Oklahoma"
    },
    {
      "city": "Lindsay",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lindsay, Oklahoma"
    },
    {
      "city": "Maysville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Maysville, Oklahoma"
    },
    {
      "city": "Paoli",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Paoli, Oklahoma"
    },
    {
      "city": "Pauls Valley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pauls Valley, Oklahoma"
    },
    {
      "city": "Wynnewood",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wynnewood, Oklahoma"
    },
    {
      "city": "Elmore City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Elmore City, Oklahoma"
    },
    {
      "city": "Hennepin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hennepin, Oklahoma"
    },
    {
      "city": "Stratford",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stratford, Oklahoma"
    },
    {
      "city": "Alex",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Alex, Oklahoma"
    },
    {
      "city": "Amber",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Amber, Oklahoma"
    },
    {
      "city": "Bradley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bradley, Oklahoma"
    },
    {
      "city": "Chickasha",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chickasha, Oklahoma"
    },
    {
      "city": "Minco",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Minco, Oklahoma"
    },
    {
      "city": "Ninnekah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ninnekah, Oklahoma"
    },
    {
      "city": "Pocasset",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pocasset, Oklahoma"
    },
    {
      "city": "Rush Springs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rush Springs, Oklahoma"
    },
    {
      "city": "Tuttle",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tuttle, Oklahoma"
    },
    {
      "city": "Verden",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Verden, Oklahoma"
    },
    {
      "city": "Manchester",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Manchester, Oklahoma"
    },
    {
      "city": "Medford",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Medford, Oklahoma"
    },
    {
      "city": "Nash",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Nash, Oklahoma"
    },
    {
      "city": "Pond Creek",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pond Creek, Oklahoma"
    },
    {
      "city": "Wakita",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wakita, Oklahoma"
    },
    {
      "city": "Deer Creek",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Deer Creek, Oklahoma"
    },
    {
      "city": "Lamont",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lamont, Oklahoma"
    },
    {
      "city": "Granite",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Granite, Oklahoma"
    },
    {
      "city": "Mangum",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mangum, Oklahoma"
    },
    {
      "city": "Willow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Willow, Oklahoma"
    },
    {
      "city": "Gould",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gould, Oklahoma"
    },
    {
      "city": "Hollis",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hollis, Oklahoma"
    },
    {
      "city": "Vinson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Vinson, Oklahoma"
    },
    {
      "city": "Buffalo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Buffalo, Oklahoma"
    },
    {
      "city": "Laverne",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Laverne, Oklahoma"
    },
    {
      "city": "May",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "May, Oklahoma"
    },
    {
      "city": "Rosston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rosston, Oklahoma"
    },
    {
      "city": "Hoyt",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hoyt, Oklahoma"
    },
    {
      "city": "Stigler",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stigler, Oklahoma"
    },
    {
      "city": "Whitefield",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Whitefield, Oklahoma"
    },
    {
      "city": "Kinta",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kinta, Oklahoma"
    },
    {
      "city": "Keota",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Keota, Oklahoma"
    },
    {
      "city": "Lequire",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lequire, Oklahoma"
    },
    {
      "city": "Mccurtain",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mccurtain, Oklahoma"
    },
    {
      "city": "Calvin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Calvin, Oklahoma"
    },
    {
      "city": "Stuart",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stuart, Oklahoma"
    },
    {
      "city": "Atwood",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Atwood, Oklahoma"
    },
    {
      "city": "Dustin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dustin, Oklahoma"
    },
    {
      "city": "Holdenville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Holdenville, Oklahoma"
    },
    {
      "city": "Lamar",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lamar, Oklahoma"
    },
    {
      "city": "Wetumka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wetumka, Oklahoma"
    },
    {
      "city": "Altus",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Altus, Oklahoma"
    },
    {
      "city": "Altus Afb",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Altus Afb, Oklahoma"
    },
    {
      "city": "Blair",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Blair, Oklahoma"
    },
    {
      "city": "Duke",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Duke, Oklahoma"
    },
    {
      "city": "Eldorado",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Eldorado, Oklahoma"
    },
    {
      "city": "Elmer",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Elmer, Oklahoma"
    },
    {
      "city": "Headrick",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Headrick, Oklahoma"
    },
    {
      "city": "Martha",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Martha, Oklahoma"
    },
    {
      "city": "Olustee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Olustee, Oklahoma"
    },
    {
      "city": "Ringling",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ringling, Oklahoma"
    },
    {
      "city": "Addington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Addington, Oklahoma"
    },
    {
      "city": "Hastings",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hastings, Oklahoma"
    },
    {
      "city": "Oscar",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oscar, Oklahoma"
    },
    {
      "city": "Ryan",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ryan, Oklahoma"
    },
    {
      "city": "Terral",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Terral, Oklahoma"
    },
    {
      "city": "Waurika",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Waurika, Oklahoma"
    },
    {
      "city": "Coleman",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Coleman, Oklahoma"
    },
    {
      "city": "Mannsville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mannsville, Oklahoma"
    },
    {
      "city": "Milburn",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Milburn, Oklahoma"
    },
    {
      "city": "Ravia",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ravia, Oklahoma"
    },
    {
      "city": "Tishomingo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tishomingo, Oklahoma"
    },
    {
      "city": "Wapanucka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wapanucka, Oklahoma"
    },
    {
      "city": "Bromide",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bromide, Oklahoma"
    },
    {
      "city": "Connerville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Connerville, Oklahoma"
    },
    {
      "city": "Mill Creek",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mill Creek, Oklahoma"
    },
    {
      "city": "Ponca City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ponca City, Oklahoma"
    },
    {
      "city": "Blackwell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Blackwell, Oklahoma"
    },
    {
      "city": "Braman",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Braman, Oklahoma"
    },
    {
      "city": "Kaw City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kaw City, Oklahoma"
    },
    {
      "city": "Nardin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Nardin, Oklahoma"
    },
    {
      "city": "Newkirk",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Newkirk, Oklahoma"
    },
    {
      "city": "Tonkawa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tonkawa, Oklahoma"
    },
    {
      "city": "Cashion",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cashion, Oklahoma"
    },
    {
      "city": "Dover",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dover, Oklahoma"
    },
    {
      "city": "Hennessey",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hennessey, Oklahoma"
    },
    {
      "city": "Kingfisher",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kingfisher, Oklahoma"
    },
    {
      "city": "Loyal",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Loyal, Oklahoma"
    },
    {
      "city": "Okarche",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Okarche, Oklahoma"
    },
    {
      "city": "Omega",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Omega, Oklahoma"
    },
    {
      "city": "Gotebo",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gotebo, Oklahoma"
    },
    {
      "city": "Mountain View",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mountain View, Oklahoma"
    },
    {
      "city": "Mountain Park",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mountain Park, Oklahoma"
    },
    {
      "city": "Roosevelt",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Roosevelt, Oklahoma"
    },
    {
      "city": "Snyder",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Snyder, Oklahoma"
    },
    {
      "city": "Hobart",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hobart, Oklahoma"
    },
    {
      "city": "Lone Wolf",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lone Wolf, Oklahoma"
    },
    {
      "city": "Gowen",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gowen, Oklahoma"
    },
    {
      "city": "Panola",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Panola, Oklahoma"
    },
    {
      "city": "Red Oak",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Red Oak, Oklahoma"
    },
    {
      "city": "Wilburton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wilburton, Oklahoma"
    },
    {
      "city": "Honobia",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Honobia, Oklahoma"
    },
    {
      "city": "Talihina",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Talihina, Oklahoma"
    },
    {
      "city": "Whitesboro",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Whitesboro, Oklahoma"
    },
    {
      "city": "Arkoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Arkoma, Oklahoma"
    },
    {
      "city": "Pocola",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pocola, Oklahoma"
    },
    {
      "city": "Bokoshe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bokoshe, Oklahoma"
    },
    {
      "city": "Cameron",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cameron, Oklahoma"
    },
    {
      "city": "Fanshawe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fanshawe, Oklahoma"
    },
    {
      "city": "Heavener",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Heavener, Oklahoma"
    },
    {
      "city": "Hodgen",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hodgen, Oklahoma"
    },
    {
      "city": "Howe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Howe, Oklahoma"
    },
    {
      "city": "Leflore",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Leflore, Oklahoma"
    },
    {
      "city": "Monroe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Monroe, Oklahoma"
    },
    {
      "city": "Muse",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Muse, Oklahoma"
    },
    {
      "city": "Panama",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Panama, Oklahoma"
    },
    {
      "city": "Poteau",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Poteau, Oklahoma"
    },
    {
      "city": "Shady Point",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Shady Point, Oklahoma"
    },
    {
      "city": "Spiro",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Spiro, Oklahoma"
    },
    {
      "city": "Wister",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wister, Oklahoma"
    },
    {
      "city": "Davenport",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Davenport, Oklahoma"
    },
    {
      "city": "Stroud",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stroud, Oklahoma"
    },
    {
      "city": "Agra",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Agra, Oklahoma"
    },
    {
      "city": "Carney",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Carney, Oklahoma"
    },
    {
      "city": "Chandler",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chandler, Oklahoma"
    },
    {
      "city": "Meeker",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Meeker, Oklahoma"
    },
    {
      "city": "Prague",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Prague, Oklahoma"
    },
    {
      "city": "Sparks",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sparks, Oklahoma"
    },
    {
      "city": "Tryon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tryon, Oklahoma"
    },
    {
      "city": "Wellston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wellston, Oklahoma"
    },
    {
      "city": "Coyle",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Coyle, Oklahoma"
    },
    {
      "city": "Crescent",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Crescent, Oklahoma"
    },
    {
      "city": "Guthrie",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Guthrie, Oklahoma"
    },
    {
      "city": "Langston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Langston, Oklahoma"
    },
    {
      "city": "Marshall",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Marshall, Oklahoma"
    },
    {
      "city": "Meridian",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Meridian, Oklahoma"
    },
    {
      "city": "Mulhall",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mulhall, Oklahoma"
    },
    {
      "city": "Orlando",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Orlando, Oklahoma"
    },
    {
      "city": "Burneyville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Burneyville, Oklahoma"
    },
    {
      "city": "Leon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Leon, Oklahoma"
    },
    {
      "city": "Marietta",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Marietta, Oklahoma"
    },
    {
      "city": "Overbrook",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Overbrook, Oklahoma"
    },
    {
      "city": "Thackerville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Thackerville, Oklahoma"
    },
    {
      "city": "Blanchard",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Blanchard, Oklahoma"
    },
    {
      "city": "Dibble",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dibble, Oklahoma"
    },
    {
      "city": "Newcastle",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Newcastle, Oklahoma"
    },
    {
      "city": "Purcell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Purcell, Oklahoma"
    },
    {
      "city": "Washington",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Washington, Oklahoma"
    },
    {
      "city": "Wayne",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wayne, Oklahoma"
    },
    {
      "city": "Byars",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Byars, Oklahoma"
    },
    {
      "city": "Battiest",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Battiest, Oklahoma"
    },
    {
      "city": "Bethel",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bethel, Oklahoma"
    },
    {
      "city": "Broken Bow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Broken Bow, Oklahoma"
    },
    {
      "city": "Eagletown",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Eagletown, Oklahoma"
    },
    {
      "city": "Garvin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Garvin, Oklahoma"
    },
    {
      "city": "Golden",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Golden, Oklahoma"
    },
    {
      "city": "Haworth",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Haworth, Oklahoma"
    },
    {
      "city": "Idabel",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Idabel, Oklahoma"
    },
    {
      "city": "Millerton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Millerton, Oklahoma"
    },
    {
      "city": "Pickens",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pickens, Oklahoma"
    },
    {
      "city": "Ringold",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ringold, Oklahoma"
    },
    {
      "city": "Rufe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rufe, Oklahoma"
    },
    {
      "city": "Valliant",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Valliant, Oklahoma"
    },
    {
      "city": "Wright City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wright City, Oklahoma"
    },
    {
      "city": "Smithville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Smithville, Oklahoma"
    },
    {
      "city": "Watson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Watson, Oklahoma"
    },
    {
      "city": "Checotah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Checotah, Oklahoma"
    },
    {
      "city": "Eufaula",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Eufaula, Oklahoma"
    },
    {
      "city": "Hitchita",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hitchita, Oklahoma"
    },
    {
      "city": "Rentiesville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rentiesville, Oklahoma"
    },
    {
      "city": "Stidham",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stidham, Oklahoma"
    },
    {
      "city": "Hanna",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hanna, Oklahoma"
    },
    {
      "city": "Ames",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ames, Oklahoma"
    },
    {
      "city": "Cleo Springs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cleo Springs, Oklahoma"
    },
    {
      "city": "Fairview",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fairview, Oklahoma"
    },
    {
      "city": "Isabella",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Isabella, Oklahoma"
    },
    {
      "city": "Meno",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Meno, Oklahoma"
    },
    {
      "city": "Ringwood",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ringwood, Oklahoma"
    },
    {
      "city": "Chester",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chester, Oklahoma"
    },
    {
      "city": "Kingston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kingston, Oklahoma"
    },
    {
      "city": "Lebanon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lebanon, Oklahoma"
    },
    {
      "city": "Madill",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Madill, Oklahoma"
    },
    {
      "city": "Adair",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Adair, Oklahoma"
    },
    {
      "city": "Chouteau",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chouteau, Oklahoma"
    },
    {
      "city": "Disney",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Disney, Oklahoma"
    },
    {
      "city": "Ketchum",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ketchum, Oklahoma"
    },
    {
      "city": "Langley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Langley, Oklahoma"
    },
    {
      "city": "Locust Grove",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Locust Grove, Oklahoma"
    },
    {
      "city": "Pryor",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pryor, Oklahoma"
    },
    {
      "city": "Rose",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rose, Oklahoma"
    },
    {
      "city": "Salina",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Salina, Oklahoma"
    },
    {
      "city": "Spavinaw",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Spavinaw, Oklahoma"
    },
    {
      "city": "Strang",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Strang, Oklahoma"
    },
    {
      "city": "Davis",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Davis, Oklahoma"
    },
    {
      "city": "Dougherty",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dougherty, Oklahoma"
    },
    {
      "city": "Sulphur",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sulphur, Oklahoma"
    },
    {
      "city": "Muskogee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Muskogee, Oklahoma"
    },
    {
      "city": "Boynton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Boynton, Oklahoma"
    },
    {
      "city": "Braggs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Braggs, Oklahoma"
    },
    {
      "city": "Council Hill",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Council Hill, Oklahoma"
    },
    {
      "city": "Fort Gibson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fort Gibson, Oklahoma"
    },
    {
      "city": "Haskell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Haskell, Oklahoma"
    },
    {
      "city": "Oktaha",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oktaha, Oklahoma"
    },
    {
      "city": "Porum",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Porum, Oklahoma"
    },
    {
      "city": "Taft",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Taft, Oklahoma"
    },
    {
      "city": "Wainwright",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wainwright, Oklahoma"
    },
    {
      "city": "Warner",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Warner, Oklahoma"
    },
    {
      "city": "Webbers Falls",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Webbers Falls, Oklahoma"
    },
    {
      "city": "Morrison",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Morrison, Oklahoma"
    },
    {
      "city": "Perry",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Perry, Oklahoma"
    },
    {
      "city": "Lucien",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lucien, Oklahoma"
    },
    {
      "city": "Billings",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Billings, Oklahoma"
    },
    {
      "city": "Marland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Marland, Oklahoma"
    },
    {
      "city": "Red Rock",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Red Rock, Oklahoma"
    },
    {
      "city": "Delaware",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Delaware, Oklahoma"
    },
    {
      "city": "Lenapah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Lenapah, Oklahoma"
    },
    {
      "city": "Nowata",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Nowata, Oklahoma"
    },
    {
      "city": "S Coffeyville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "S Coffeyville, Oklahoma"
    },
    {
      "city": "Wann",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wann, Oklahoma"
    },
    {
      "city": "Boley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Boley, Oklahoma"
    },
    {
      "city": "Castle",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Castle, Oklahoma"
    },
    {
      "city": "Okemah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Okemah, Oklahoma"
    },
    {
      "city": "Paden",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Paden, Oklahoma"
    },
    {
      "city": "Weleetka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Weleetka, Oklahoma"
    },
    {
      "city": "Edmond",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Edmond, Oklahoma"
    },
    {
      "city": "Arcadia",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Arcadia, Oklahoma"
    },
    {
      "city": "Bethany",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bethany, Oklahoma"
    },
    {
      "city": "Choctaw",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Choctaw, Oklahoma"
    },
    {
      "city": "Harrah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Harrah, Oklahoma"
    },
    {
      "city": "Jones",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Jones, Oklahoma"
    },
    {
      "city": "Luther",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Luther, Oklahoma"
    },
    {
      "city": "Nicoma Park",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Nicoma Park, Oklahoma"
    },
    {
      "city": "Spencer",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Spencer, Oklahoma"
    },
    {
      "city": "Wheatland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wheatland, Oklahoma"
    },
    {
      "city": "Newalla",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Newalla, Oklahoma"
    },
    {
      "city": "Beggs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Beggs, Oklahoma"
    },
    {
      "city": "Dewar",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dewar, Oklahoma"
    },
    {
      "city": "Henryetta",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Henryetta, Oklahoma"
    },
    {
      "city": "Morris",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Morris, Oklahoma"
    },
    {
      "city": "Okmulgee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Okmulgee, Oklahoma"
    },
    {
      "city": "Preston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Preston, Oklahoma"
    },
    {
      "city": "Schulter",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Schulter, Oklahoma"
    },
    {
      "city": "Avant",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Avant, Oklahoma"
    },
    {
      "city": "Barnsdall",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Barnsdall, Oklahoma"
    },
    {
      "city": "Hominy",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hominy, Oklahoma"
    },
    {
      "city": "Osage",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Osage, Oklahoma"
    },
    {
      "city": "Pawhuska",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pawhuska, Oklahoma"
    },
    {
      "city": "Prue",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Prue, Oklahoma"
    },
    {
      "city": "Skiatook",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Skiatook, Oklahoma"
    },
    {
      "city": "Wynona",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wynona, Oklahoma"
    },
    {
      "city": "Burbank",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Burbank, Oklahoma"
    },
    {
      "city": "Fairfax",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fairfax, Oklahoma"
    },
    {
      "city": "Shidler",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Shidler, Oklahoma"
    },
    {
      "city": "Afton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Afton, Oklahoma"
    },
    {
      "city": "Cardin",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cardin, Oklahoma"
    },
    {
      "city": "Commerce",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Commerce, Oklahoma"
    },
    {
      "city": "Fairland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fairland, Oklahoma"
    },
    {
      "city": "Miami",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Miami, Oklahoma"
    },
    {
      "city": "North Miami",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "North Miami, Oklahoma"
    },
    {
      "city": "Picher",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Picher, Oklahoma"
    },
    {
      "city": "Quapaw",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Quapaw, Oklahoma"
    },
    {
      "city": "Wyandotte",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wyandotte, Oklahoma"
    },
    {
      "city": "Cleveland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cleveland, Oklahoma"
    },
    {
      "city": "Hallett",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hallett, Oklahoma"
    },
    {
      "city": "Jennings",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Jennings, Oklahoma"
    },
    {
      "city": "Maramec",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Maramec, Oklahoma"
    },
    {
      "city": "Pawnee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pawnee, Oklahoma"
    },
    {
      "city": "Terlton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Terlton, Oklahoma"
    },
    {
      "city": "Ralston",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ralston, Oklahoma"
    },
    {
      "city": "Cushing",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cushing, Oklahoma"
    },
    {
      "city": "Glencoe",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Glencoe, Oklahoma"
    },
    {
      "city": "Perkins",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Perkins, Oklahoma"
    },
    {
      "city": "Ripley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ripley, Oklahoma"
    },
    {
      "city": "Stillwater",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stillwater, Oklahoma"
    },
    {
      "city": "Yale",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Yale, Oklahoma"
    },
    {
      "city": "Canadian",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Canadian, Oklahoma"
    },
    {
      "city": "Crowder",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Crowder, Oklahoma"
    },
    {
      "city": "Indianola",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Indianola, Oklahoma"
    },
    {
      "city": "Mcalester",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mcalester, Oklahoma"
    },
    {
      "city": "Alderson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Alderson, Oklahoma"
    },
    {
      "city": "Blanco",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Blanco, Oklahoma"
    },
    {
      "city": "Blocker",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Blocker, Oklahoma"
    },
    {
      "city": "Haileyville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Haileyville, Oklahoma"
    },
    {
      "city": "Hartshorne",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hartshorne, Oklahoma"
    },
    {
      "city": "Kiowa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Kiowa, Oklahoma"
    },
    {
      "city": "Krebs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Krebs, Oklahoma"
    },
    {
      "city": "Pittsburg",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Pittsburg, Oklahoma"
    },
    {
      "city": "Quinton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Quinton, Oklahoma"
    },
    {
      "city": "Savanna",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Savanna, Oklahoma"
    },
    {
      "city": "Wardville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wardville, Oklahoma"
    },
    {
      "city": "Ada",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ada, Oklahoma"
    },
    {
      "city": "Allen",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Allen, Oklahoma"
    },
    {
      "city": "Fittstown",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fittstown, Oklahoma"
    },
    {
      "city": "Fitzhugh",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fitzhugh, Oklahoma"
    },
    {
      "city": "Francis",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Francis, Oklahoma"
    },
    {
      "city": "Roff",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Roff, Oklahoma"
    },
    {
      "city": "Stonewall",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Stonewall, Oklahoma"
    },
    {
      "city": "Shawnee",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Shawnee, Oklahoma"
    },
    {
      "city": "Asher",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Asher, Oklahoma"
    },
    {
      "city": "Earlsboro",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Earlsboro, Oklahoma"
    },
    {
      "city": "Mcloud",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mcloud, Oklahoma"
    },
    {
      "city": "Macomb",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Macomb, Oklahoma"
    },
    {
      "city": "Maud",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Maud, Oklahoma"
    },
    {
      "city": "Saint Louis",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Saint Louis, Oklahoma"
    },
    {
      "city": "Tecumseh",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tecumseh, Oklahoma"
    },
    {
      "city": "Wanette",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wanette, Oklahoma"
    },
    {
      "city": "Albion",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Albion, Oklahoma"
    },
    {
      "city": "Antlers",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Antlers, Oklahoma"
    },
    {
      "city": "Clayton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Clayton, Oklahoma"
    },
    {
      "city": "Finley",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Finley, Oklahoma"
    },
    {
      "city": "Moyers",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Moyers, Oklahoma"
    },
    {
      "city": "Nashoba",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Nashoba, Oklahoma"
    },
    {
      "city": "Rattan",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rattan, Oklahoma"
    },
    {
      "city": "Snow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Snow, Oklahoma"
    },
    {
      "city": "Tuskahoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tuskahoma, Oklahoma"
    },
    {
      "city": "Cheyenne",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cheyenne, Oklahoma"
    },
    {
      "city": "Crawford",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Crawford, Oklahoma"
    },
    {
      "city": "Durham",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Durham, Oklahoma"
    },
    {
      "city": "Hammon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hammon, Oklahoma"
    },
    {
      "city": "Reydon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Reydon, Oklahoma"
    },
    {
      "city": "Sweetwater",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sweetwater, Oklahoma"
    },
    {
      "city": "Catoosa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Catoosa, Oklahoma"
    },
    {
      "city": "Chelsea",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Chelsea, Oklahoma"
    },
    {
      "city": "Claremore",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Claremore, Oklahoma"
    },
    {
      "city": "Foyil",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Foyil, Oklahoma"
    },
    {
      "city": "Inola",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Inola, Oklahoma"
    },
    {
      "city": "Oologah",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oologah, Oklahoma"
    },
    {
      "city": "Talala",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Talala, Oklahoma"
    },
    {
      "city": "Seminole",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Seminole, Oklahoma"
    },
    {
      "city": "Bowlegs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bowlegs, Oklahoma"
    },
    {
      "city": "Cromwell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cromwell, Oklahoma"
    },
    {
      "city": "Konawa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Konawa, Oklahoma"
    },
    {
      "city": "Sasakwa",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sasakwa, Oklahoma"
    },
    {
      "city": "Wewoka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wewoka, Oklahoma"
    },
    {
      "city": "Gore",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gore, Oklahoma"
    },
    {
      "city": "Gans",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Gans, Oklahoma"
    },
    {
      "city": "Marble City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Marble City, Oklahoma"
    },
    {
      "city": "Moffett",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Moffett, Oklahoma"
    },
    {
      "city": "Muldrow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Muldrow, Oklahoma"
    },
    {
      "city": "Roland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Roland, Oklahoma"
    },
    {
      "city": "Sallisaw",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sallisaw, Oklahoma"
    },
    {
      "city": "Vian",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Vian, Oklahoma"
    },
    {
      "city": "Marlow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Marlow, Oklahoma"
    },
    {
      "city": "Countyline",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Countyline, Oklahoma"
    },
    {
      "city": "Foster",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Foster, Oklahoma"
    },
    {
      "city": "Loco",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Loco, Oklahoma"
    },
    {
      "city": "Velma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Velma, Oklahoma"
    },
    {
      "city": "Comanche",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Comanche, Oklahoma"
    },
    {
      "city": "Duncan",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Duncan, Oklahoma"
    },
    {
      "city": "Adams",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Adams, Oklahoma"
    },
    {
      "city": "Goodwell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Goodwell, Oklahoma"
    },
    {
      "city": "Guymon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Guymon, Oklahoma"
    },
    {
      "city": "Hardesty",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hardesty, Oklahoma"
    },
    {
      "city": "Hooker",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hooker, Oklahoma"
    },
    {
      "city": "Texhoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Texhoma, Oklahoma"
    },
    {
      "city": "Tyrone",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tyrone, Oklahoma"
    },
    {
      "city": "Davidson",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Davidson, Oklahoma"
    },
    {
      "city": "Frederick",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Frederick, Oklahoma"
    },
    {
      "city": "Grandfield",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Grandfield, Oklahoma"
    },
    {
      "city": "Hollister",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hollister, Oklahoma"
    },
    {
      "city": "Loveland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Loveland, Oklahoma"
    },
    {
      "city": "Manitou",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Manitou, Oklahoma"
    },
    {
      "city": "Tipton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Tipton, Oklahoma"
    },
    {
      "city": "Bixby",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bixby, Oklahoma"
    },
    {
      "city": "Broken Arrow",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Broken Arrow, Oklahoma"
    },
    {
      "city": "Collinsville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Collinsville, Oklahoma"
    },
    {
      "city": "Glenpool",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Glenpool, Oklahoma"
    },
    {
      "city": "Jenks",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Jenks, Oklahoma"
    },
    {
      "city": "Leonard",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Leonard, Oklahoma"
    },
    {
      "city": "Oakhurst",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Oakhurst, Oklahoma"
    },
    {
      "city": "Owasso",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Owasso, Oklahoma"
    },
    {
      "city": "Sand Springs",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sand Springs, Oklahoma"
    },
    {
      "city": "Sperry",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sperry, Oklahoma"
    },
    {
      "city": "Coweta",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Coweta, Oklahoma"
    },
    {
      "city": "Okay",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Okay, Oklahoma"
    },
    {
      "city": "Porter",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Porter, Oklahoma"
    },
    {
      "city": "Redbird",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Redbird, Oklahoma"
    },
    {
      "city": "Wagoner",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Wagoner, Oklahoma"
    },
    {
      "city": "Bartlesville",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bartlesville, Oklahoma"
    },
    {
      "city": "Copan",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Copan, Oklahoma"
    },
    {
      "city": "Dewey",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dewey, Oklahoma"
    },
    {
      "city": "Ochelata",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ochelata, Oklahoma"
    },
    {
      "city": "Ramona",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Ramona, Oklahoma"
    },
    {
      "city": "Vera",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Vera, Oklahoma"
    },
    {
      "city": "Colony",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Colony, Oklahoma"
    },
    {
      "city": "Corn",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Corn, Oklahoma"
    },
    {
      "city": "Bessie",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Bessie, Oklahoma"
    },
    {
      "city": "Burns Flat",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Burns Flat, Oklahoma"
    },
    {
      "city": "Canute",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Canute, Oklahoma"
    },
    {
      "city": "Cordell",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Cordell, Oklahoma"
    },
    {
      "city": "Dill City",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dill City, Oklahoma"
    },
    {
      "city": "Foss",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Foss, Oklahoma"
    },
    {
      "city": "Rocky",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Rocky, Oklahoma"
    },
    {
      "city": "Sentinel",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sentinel, Oklahoma"
    },
    {
      "city": "Alva",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Alva, Oklahoma"
    },
    {
      "city": "Dacoma",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Dacoma, Oklahoma"
    },
    {
      "city": "Hopeton",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Hopeton, Oklahoma"
    },
    {
      "city": "Freedom",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Freedom, Oklahoma"
    },
    {
      "city": "Waynoka",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Waynoka, Oklahoma"
    },
    {
      "city": "Woodward",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Woodward, Oklahoma"
    },
    {
      "city": "Fort Supply",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Fort Supply, Oklahoma"
    },
    {
      "city": "Mooreland",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mooreland, Oklahoma"
    },
    {
      "city": "Mutual",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Mutual, Oklahoma"
    },
    {
      "city": "Sharon",
      "state": "Oklahoma",
      "shortState": "OK",
      "formatedCity": "Sharon, Oklahoma"
    },
    {
      "city": "Baker City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Baker City, Oregon"
    },
    {
      "city": "Bridgeport",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bridgeport, Oregon"
    },
    {
      "city": "Haines",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Haines, Oregon"
    },
    {
      "city": "Halfway",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Halfway, Oregon"
    },
    {
      "city": "Hereford",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hereford, Oregon"
    },
    {
      "city": "Oxbow",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Oxbow, Oregon"
    },
    {
      "city": "Richland",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Richland, Oregon"
    },
    {
      "city": "Sumpter",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sumpter, Oregon"
    },
    {
      "city": "Unity",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Unity, Oregon"
    },
    {
      "city": "Durkee",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Durkee, Oregon"
    },
    {
      "city": "Huntington",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Huntington, Oregon"
    },
    {
      "city": "Alsea",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Alsea, Oregon"
    },
    {
      "city": "Blodgett",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Blodgett, Oregon"
    },
    {
      "city": "Corvallis",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Corvallis, Oregon"
    },
    {
      "city": "Philomath",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Philomath, Oregon"
    },
    {
      "city": "Monroe",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Monroe, Oregon"
    },
    {
      "city": "Beavercreek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Beavercreek, Oregon"
    },
    {
      "city": "Boring",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Boring, Oregon"
    },
    {
      "city": "Brightwood",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Brightwood, Oregon"
    },
    {
      "city": "Canby",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Canby, Oregon"
    },
    {
      "city": "Clackamas",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Clackamas, Oregon"
    },
    {
      "city": "Colton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Colton, Oregon"
    },
    {
      "city": "Eagle Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Eagle Creek, Oregon"
    },
    {
      "city": "Estacada",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Estacada, Oregon"
    },
    {
      "city": "Gladstone",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gladstone, Oregon"
    },
    {
      "city": "Government Camp",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Government Camp, Oregon"
    },
    {
      "city": "Lake Oswego",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lake Oswego, Oregon"
    },
    {
      "city": "Marylhurst",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Marylhurst, Oregon"
    },
    {
      "city": "Molalla",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Molalla, Oregon"
    },
    {
      "city": "Mulino",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mulino, Oregon"
    },
    {
      "city": "Oregon City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Oregon City, Oregon"
    },
    {
      "city": "Rhododendron",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rhododendron, Oregon"
    },
    {
      "city": "Sandy",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sandy, Oregon"
    },
    {
      "city": "Welches",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Welches, Oregon"
    },
    {
      "city": "West Linn",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "West Linn, Oregon"
    },
    {
      "city": "Wilsonville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wilsonville, Oregon"
    },
    {
      "city": "Damascus",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Damascus, Oregon"
    },
    {
      "city": "Portland",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Portland, Oregon"
    },
    {
      "city": "Arch Cape",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Arch Cape, Oregon"
    },
    {
      "city": "Astoria",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Astoria, Oregon"
    },
    {
      "city": "Cannon Beach",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cannon Beach, Oregon"
    },
    {
      "city": "Hammond",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hammond, Oregon"
    },
    {
      "city": "Seaside",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Seaside, Oregon"
    },
    {
      "city": "Tolovana Park",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tolovana Park, Oregon"
    },
    {
      "city": "Warrenton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Warrenton, Oregon"
    },
    {
      "city": "Clatskanie",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Clatskanie, Oregon"
    },
    {
      "city": "Columbia City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Columbia City, Oregon"
    },
    {
      "city": "Rainier",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rainier, Oregon"
    },
    {
      "city": "Saint Helens",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Saint Helens, Oregon"
    },
    {
      "city": "Warren",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Warren, Oregon"
    },
    {
      "city": "Deer Island",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Deer Island, Oregon"
    },
    {
      "city": "Scappoose",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Scappoose, Oregon"
    },
    {
      "city": "Vernonia",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Vernonia, Oregon"
    },
    {
      "city": "Allegany",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Allegany, Oregon"
    },
    {
      "city": "Bandon",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bandon, Oregon"
    },
    {
      "city": "Broadbent",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Broadbent, Oregon"
    },
    {
      "city": "Coos Bay",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Coos Bay, Oregon"
    },
    {
      "city": "Coquille",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Coquille, Oregon"
    },
    {
      "city": "Lakeside",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lakeside, Oregon"
    },
    {
      "city": "Myrtle Point",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Myrtle Point, Oregon"
    },
    {
      "city": "North Bend",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "North Bend, Oregon"
    },
    {
      "city": "Powers",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Powers, Oregon"
    },
    {
      "city": "Paulina",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Paulina, Oregon"
    },
    {
      "city": "Post",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Post, Oregon"
    },
    {
      "city": "Powell Butte",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Powell Butte, Oregon"
    },
    {
      "city": "Prineville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Prineville, Oregon"
    },
    {
      "city": "Agness",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Agness, Oregon"
    },
    {
      "city": "Brookings",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Brookings, Oregon"
    },
    {
      "city": "Gold Beach",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gold Beach, Oregon"
    },
    {
      "city": "Langlois",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Langlois, Oregon"
    },
    {
      "city": "Ophir",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ophir, Oregon"
    },
    {
      "city": "Port Orford",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Port Orford, Oregon"
    },
    {
      "city": "Sixes",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sixes, Oregon"
    },
    {
      "city": "Wedderburn",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wedderburn, Oregon"
    },
    {
      "city": "Bend",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bend, Oregon"
    },
    {
      "city": "Brothers",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Brothers, Oregon"
    },
    {
      "city": "La Pine",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "La Pine, Oregon"
    },
    {
      "city": "Redmond",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Redmond, Oregon"
    },
    {
      "city": "Sisters",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sisters, Oregon"
    },
    {
      "city": "Terrebonne",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Terrebonne, Oregon"
    },
    {
      "city": "Azalea",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Azalea, Oregon"
    },
    {
      "city": "Camas Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Camas Valley, Oregon"
    },
    {
      "city": "Canyonville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Canyonville, Oregon"
    },
    {
      "city": "Days Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Days Creek, Oregon"
    },
    {
      "city": "Dillard",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dillard, Oregon"
    },
    {
      "city": "Drain",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Drain, Oregon"
    },
    {
      "city": "Elkton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Elkton, Oregon"
    },
    {
      "city": "Gardiner",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gardiner, Oregon"
    },
    {
      "city": "Glendale",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Glendale, Oregon"
    },
    {
      "city": "Glide",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Glide, Oregon"
    },
    {
      "city": "Idleyld Park",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Idleyld Park, Oregon"
    },
    {
      "city": "Myrtle Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Myrtle Creek, Oregon"
    },
    {
      "city": "Oakland",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Oakland, Oregon"
    },
    {
      "city": "Reedsport",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Reedsport, Oregon"
    },
    {
      "city": "Riddle",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Riddle, Oregon"
    },
    {
      "city": "Roseburg",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Roseburg, Oregon"
    },
    {
      "city": "Scottsburg",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Scottsburg, Oregon"
    },
    {
      "city": "Sutherlin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sutherlin, Oregon"
    },
    {
      "city": "Tenmile",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tenmile, Oregon"
    },
    {
      "city": "Tiller",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tiller, Oregon"
    },
    {
      "city": "Umpqua",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Umpqua, Oregon"
    },
    {
      "city": "Wilbur",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wilbur, Oregon"
    },
    {
      "city": "Winchester",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Winchester, Oregon"
    },
    {
      "city": "Winston",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Winston, Oregon"
    },
    {
      "city": "Yoncalla",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Yoncalla, Oregon"
    },
    {
      "city": "Arlington",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Arlington, Oregon"
    },
    {
      "city": "Condon",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Condon, Oregon"
    },
    {
      "city": "Mikkalo",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mikkalo, Oregon"
    },
    {
      "city": "Bates",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bates, Oregon"
    },
    {
      "city": "Canyon City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Canyon City, Oregon"
    },
    {
      "city": "Dayville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dayville, Oregon"
    },
    {
      "city": "John Day",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "John Day, Oregon"
    },
    {
      "city": "Kimberly",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Kimberly, Oregon"
    },
    {
      "city": "Long Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Long Creek, Oregon"
    },
    {
      "city": "Monument",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Monument, Oregon"
    },
    {
      "city": "Mount Vernon",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mount Vernon, Oregon"
    },
    {
      "city": "Prairie City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Prairie City, Oregon"
    },
    {
      "city": "Seneca",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Seneca, Oregon"
    },
    {
      "city": "Fields",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fields, Oregon"
    },
    {
      "city": "Burns",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Burns, Oregon"
    },
    {
      "city": "Princeton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Princeton, Oregon"
    },
    {
      "city": "Diamond",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Diamond, Oregon"
    },
    {
      "city": "Crane",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Crane, Oregon"
    },
    {
      "city": "Frenchglen",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Frenchglen, Oregon"
    },
    {
      "city": "Hines",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hines, Oregon"
    },
    {
      "city": "Riley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Riley, Oregon"
    },
    {
      "city": "Drewsey",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Drewsey, Oregon"
    },
    {
      "city": "Cascade Locks",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cascade Locks, Oregon"
    },
    {
      "city": "Hood River",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hood River, Oregon"
    },
    {
      "city": "Mount Hood Parkdale",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mount Hood Parkdale, Oregon"
    },
    {
      "city": "Odell",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Odell, Oregon"
    },
    {
      "city": "Medford",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Medford, Oregon"
    },
    {
      "city": "Central Point",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Central Point, Oregon"
    },
    {
      "city": "White City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "White City, Oregon"
    },
    {
      "city": "Ashland",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ashland, Oregon"
    },
    {
      "city": "Butte Falls",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Butte Falls, Oregon"
    },
    {
      "city": "Eagle Point",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Eagle Point, Oregon"
    },
    {
      "city": "Gold Hill",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gold Hill, Oregon"
    },
    {
      "city": "Jacksonville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Jacksonville, Oregon"
    },
    {
      "city": "Phoenix",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Phoenix, Oregon"
    },
    {
      "city": "Prospect",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Prospect, Oregon"
    },
    {
      "city": "Rogue River",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rogue River, Oregon"
    },
    {
      "city": "Shady Cove",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Shady Cove, Oregon"
    },
    {
      "city": "Talent",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Talent, Oregon"
    },
    {
      "city": "Trail",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Trail, Oregon"
    },
    {
      "city": "Ashwood",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ashwood, Oregon"
    },
    {
      "city": "Camp Sherman",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Camp Sherman, Oregon"
    },
    {
      "city": "Culver",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Culver, Oregon"
    },
    {
      "city": "Madras",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Madras, Oregon"
    },
    {
      "city": "Warm Springs",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Warm Springs, Oregon"
    },
    {
      "city": "Wolf Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wolf Creek, Oregon"
    },
    {
      "city": "Cave Junction",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cave Junction, Oregon"
    },
    {
      "city": "Grants Pass",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Grants Pass, Oregon"
    },
    {
      "city": "Kerby",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Kerby, Oregon"
    },
    {
      "city": "Merlin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Merlin, Oregon"
    },
    {
      "city": "Murphy",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Murphy, Oregon"
    },
    {
      "city": "O Brien",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "O Brien, Oregon"
    },
    {
      "city": "Selma",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Selma, Oregon"
    },
    {
      "city": "Wilderville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wilderville, Oregon"
    },
    {
      "city": "Williams",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Williams, Oregon"
    },
    {
      "city": "Klamath Falls",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Klamath Falls, Oregon"
    },
    {
      "city": "Crater Lake",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Crater Lake, Oregon"
    },
    {
      "city": "Beatty",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Beatty, Oregon"
    },
    {
      "city": "Bly",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bly, Oregon"
    },
    {
      "city": "Bonanza",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bonanza, Oregon"
    },
    {
      "city": "Chiloquin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Chiloquin, Oregon"
    },
    {
      "city": "Dairy",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dairy, Oregon"
    },
    {
      "city": "Fort Klamath",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fort Klamath, Oregon"
    },
    {
      "city": "Keno",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Keno, Oregon"
    },
    {
      "city": "Malin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Malin, Oregon"
    },
    {
      "city": "Merrill",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Merrill, Oregon"
    },
    {
      "city": "Midland",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Midland, Oregon"
    },
    {
      "city": "Sprague River",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sprague River, Oregon"
    },
    {
      "city": "Chemult",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Chemult, Oregon"
    },
    {
      "city": "Crescent",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Crescent, Oregon"
    },
    {
      "city": "Gilchrist",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gilchrist, Oregon"
    },
    {
      "city": "Adel",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Adel, Oregon"
    },
    {
      "city": "Lakeview",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lakeview, Oregon"
    },
    {
      "city": "New Pine Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "New Pine Creek, Oregon"
    },
    {
      "city": "Paisley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Paisley, Oregon"
    },
    {
      "city": "Plush",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Plush, Oregon"
    },
    {
      "city": "Silver Lake",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Silver Lake, Oregon"
    },
    {
      "city": "Summer Lake",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Summer Lake, Oregon"
    },
    {
      "city": "Christmas Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Christmas Valley, Oregon"
    },
    {
      "city": "Fort Rock",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fort Rock, Oregon"
    },
    {
      "city": "Eugene",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Eugene, Oregon"
    },
    {
      "city": "Alvadore",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Alvadore, Oregon"
    },
    {
      "city": "Blachly",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Blachly, Oregon"
    },
    {
      "city": "Blue River",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Blue River, Oregon"
    },
    {
      "city": "Cheshire",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cheshire, Oregon"
    },
    {
      "city": "Cottage Grove",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cottage Grove, Oregon"
    },
    {
      "city": "Creswell",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Creswell, Oregon"
    },
    {
      "city": "Deadwood",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Deadwood, Oregon"
    },
    {
      "city": "Dexter",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dexter, Oregon"
    },
    {
      "city": "Dorena",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dorena, Oregon"
    },
    {
      "city": "Elmira",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Elmira, Oregon"
    },
    {
      "city": "Fall Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fall Creek, Oregon"
    },
    {
      "city": "Florence",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Florence, Oregon"
    },
    {
      "city": "Junction City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Junction City, Oregon"
    },
    {
      "city": "Lorane",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lorane, Oregon"
    },
    {
      "city": "Lowell",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lowell, Oregon"
    },
    {
      "city": "Mapleton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mapleton, Oregon"
    },
    {
      "city": "Marcola",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Marcola, Oregon"
    },
    {
      "city": "Pleasant Hill",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Pleasant Hill, Oregon"
    },
    {
      "city": "Noti",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Noti, Oregon"
    },
    {
      "city": "Oakridge",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Oakridge, Oregon"
    },
    {
      "city": "Springfield",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Springfield, Oregon"
    },
    {
      "city": "Swisshome",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Swisshome, Oregon"
    },
    {
      "city": "Veneta",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Veneta, Oregon"
    },
    {
      "city": "Vida",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Vida, Oregon"
    },
    {
      "city": "Walterville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Walterville, Oregon"
    },
    {
      "city": "Walton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Walton, Oregon"
    },
    {
      "city": "Westfir",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Westfir, Oregon"
    },
    {
      "city": "Westlake",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Westlake, Oregon"
    },
    {
      "city": "Depoe Bay",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Depoe Bay, Oregon"
    },
    {
      "city": "Eddyville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Eddyville, Oregon"
    },
    {
      "city": "Logsden",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Logsden, Oregon"
    },
    {
      "city": "Neotsu",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Neotsu, Oregon"
    },
    {
      "city": "Newport",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Newport, Oregon"
    },
    {
      "city": "South Beach",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "South Beach, Oregon"
    },
    {
      "city": "Lincoln City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lincoln City, Oregon"
    },
    {
      "city": "Otis",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Otis, Oregon"
    },
    {
      "city": "Otter Rock",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Otter Rock, Oregon"
    },
    {
      "city": "Seal Rock",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Seal Rock, Oregon"
    },
    {
      "city": "Siletz",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Siletz, Oregon"
    },
    {
      "city": "Gleneden Beach",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gleneden Beach, Oregon"
    },
    {
      "city": "Tidewater",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tidewater, Oregon"
    },
    {
      "city": "Toledo",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Toledo, Oregon"
    },
    {
      "city": "Waldport",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Waldport, Oregon"
    },
    {
      "city": "Yachats",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Yachats, Oregon"
    },
    {
      "city": "Albany",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Albany, Oregon"
    },
    {
      "city": "Brownsville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Brownsville, Oregon"
    },
    {
      "city": "Cascadia",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cascadia, Oregon"
    },
    {
      "city": "Crabtree",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Crabtree, Oregon"
    },
    {
      "city": "Crawfordsville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Crawfordsville, Oregon"
    },
    {
      "city": "Foster",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Foster, Oregon"
    },
    {
      "city": "Halsey",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Halsey, Oregon"
    },
    {
      "city": "Lebanon",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lebanon, Oregon"
    },
    {
      "city": "Lyons",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lyons, Oregon"
    },
    {
      "city": "Mill City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mill City, Oregon"
    },
    {
      "city": "Scio",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Scio, Oregon"
    },
    {
      "city": "Shedd",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Shedd, Oregon"
    },
    {
      "city": "Sweet Home",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sweet Home, Oregon"
    },
    {
      "city": "Tangent",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tangent, Oregon"
    },
    {
      "city": "Harrisburg",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Harrisburg, Oregon"
    },
    {
      "city": "Adrian",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Adrian, Oregon"
    },
    {
      "city": "Arock",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Arock, Oregon"
    },
    {
      "city": "Brogan",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Brogan, Oregon"
    },
    {
      "city": "Harper",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Harper, Oregon"
    },
    {
      "city": "Ironside",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ironside, Oregon"
    },
    {
      "city": "Jamieson",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Jamieson, Oregon"
    },
    {
      "city": "Jordan Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Jordan Valley, Oregon"
    },
    {
      "city": "Juntura",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Juntura, Oregon"
    },
    {
      "city": "Nyssa",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Nyssa, Oregon"
    },
    {
      "city": "Ontario",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ontario, Oregon"
    },
    {
      "city": "Riverside",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Riverside, Oregon"
    },
    {
      "city": "Vale",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Vale, Oregon"
    },
    {
      "city": "Westfall",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Westfall, Oregon"
    },
    {
      "city": "Aurora",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Aurora, Oregon"
    },
    {
      "city": "Donald",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Donald, Oregon"
    },
    {
      "city": "Gervais",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gervais, Oregon"
    },
    {
      "city": "Hubbard",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hubbard, Oregon"
    },
    {
      "city": "Woodburn",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Woodburn, Oregon"
    },
    {
      "city": "Saint Paul",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Saint Paul, Oregon"
    },
    {
      "city": "Salem",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Salem, Oregon"
    },
    {
      "city": "Keizer",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Keizer, Oregon"
    },
    {
      "city": "Aumsville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Aumsville, Oregon"
    },
    {
      "city": "Detroit",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Detroit, Oregon"
    },
    {
      "city": "Gates",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gates, Oregon"
    },
    {
      "city": "Idanha",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Idanha, Oregon"
    },
    {
      "city": "Jefferson",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Jefferson, Oregon"
    },
    {
      "city": "Mount Angel",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mount Angel, Oregon"
    },
    {
      "city": "Saint Benedict",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Saint Benedict, Oregon"
    },
    {
      "city": "Scotts Mills",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Scotts Mills, Oregon"
    },
    {
      "city": "Silverton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Silverton, Oregon"
    },
    {
      "city": "Stayton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Stayton, Oregon"
    },
    {
      "city": "Mehama",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mehama, Oregon"
    },
    {
      "city": "Sublimity",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sublimity, Oregon"
    },
    {
      "city": "Turner",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Turner, Oregon"
    },
    {
      "city": "Boardman",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Boardman, Oregon"
    },
    {
      "city": "Heppner",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Heppner, Oregon"
    },
    {
      "city": "Lexington",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lexington, Oregon"
    },
    {
      "city": "Ione",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ione, Oregon"
    },
    {
      "city": "Irrigon",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Irrigon, Oregon"
    },
    {
      "city": "Bridal Veil",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bridal Veil, Oregon"
    },
    {
      "city": "Corbett",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Corbett, Oregon"
    },
    {
      "city": "Fairview",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fairview, Oregon"
    },
    {
      "city": "Gresham",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gresham, Oregon"
    },
    {
      "city": "Troutdale",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Troutdale, Oregon"
    },
    {
      "city": "Happy Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Happy Valley, Oregon"
    },
    {
      "city": "Dallas",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dallas, Oregon"
    },
    {
      "city": "Falls City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Falls City, Oregon"
    },
    {
      "city": "Grand Ronde",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Grand Ronde, Oregon"
    },
    {
      "city": "Independence",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Independence, Oregon"
    },
    {
      "city": "Monmouth",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Monmouth, Oregon"
    },
    {
      "city": "Rickreall",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rickreall, Oregon"
    },
    {
      "city": "Grass Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Grass Valley, Oregon"
    },
    {
      "city": "Kent",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Kent, Oregon"
    },
    {
      "city": "Moro",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Moro, Oregon"
    },
    {
      "city": "Rufus",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rufus, Oregon"
    },
    {
      "city": "Wasco",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wasco, Oregon"
    },
    {
      "city": "Bay City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Bay City, Oregon"
    },
    {
      "city": "Beaver",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Beaver, Oregon"
    },
    {
      "city": "Cloverdale",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cloverdale, Oregon"
    },
    {
      "city": "Garibaldi",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Garibaldi, Oregon"
    },
    {
      "city": "Hebo",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hebo, Oregon"
    },
    {
      "city": "Manzanita",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Manzanita, Oregon"
    },
    {
      "city": "Nehalem",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Nehalem, Oregon"
    },
    {
      "city": "Oceanside",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Oceanside, Oregon"
    },
    {
      "city": "Pacific City",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Pacific City, Oregon"
    },
    {
      "city": "Rockaway Beach",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Rockaway Beach, Oregon"
    },
    {
      "city": "Tillamook",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tillamook, Oregon"
    },
    {
      "city": "Netarts",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Netarts, Oregon"
    },
    {
      "city": "Wheeler",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wheeler, Oregon"
    },
    {
      "city": "Neskowin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Neskowin, Oregon"
    },
    {
      "city": "Pendleton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Pendleton, Oregon"
    },
    {
      "city": "Adams",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Adams, Oregon"
    },
    {
      "city": "Athena",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Athena, Oregon"
    },
    {
      "city": "Echo",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Echo, Oregon"
    },
    {
      "city": "Helix",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Helix, Oregon"
    },
    {
      "city": "Hermiston",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hermiston, Oregon"
    },
    {
      "city": "Meacham",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Meacham, Oregon"
    },
    {
      "city": "Milton Freewater",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Milton Freewater, Oregon"
    },
    {
      "city": "Pilot Rock",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Pilot Rock, Oregon"
    },
    {
      "city": "Stanfield",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Stanfield, Oregon"
    },
    {
      "city": "Ukiah",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Ukiah, Oregon"
    },
    {
      "city": "Umatilla",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Umatilla, Oregon"
    },
    {
      "city": "Weston",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Weston, Oregon"
    },
    {
      "city": "Cove",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cove, Oregon"
    },
    {
      "city": "Elgin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Elgin, Oregon"
    },
    {
      "city": "Imbler",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Imbler, Oregon"
    },
    {
      "city": "La Grande",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "La Grande, Oregon"
    },
    {
      "city": "North Powder",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "North Powder, Oregon"
    },
    {
      "city": "Summerville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Summerville, Oregon"
    },
    {
      "city": "Union",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Union, Oregon"
    },
    {
      "city": "Enterprise",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Enterprise, Oregon"
    },
    {
      "city": "Imnaha",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Imnaha, Oregon"
    },
    {
      "city": "Joseph",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Joseph, Oregon"
    },
    {
      "city": "Lostine",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lostine, Oregon"
    },
    {
      "city": "Wallowa",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Wallowa, Oregon"
    },
    {
      "city": "Antelope",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Antelope, Oregon"
    },
    {
      "city": "Dufur",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dufur, Oregon"
    },
    {
      "city": "Maupin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Maupin, Oregon"
    },
    {
      "city": "Mosier",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mosier, Oregon"
    },
    {
      "city": "Shaniko",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Shaniko, Oregon"
    },
    {
      "city": "The Dalles",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "The Dalles, Oregon"
    },
    {
      "city": "Tygh Valley",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tygh Valley, Oregon"
    },
    {
      "city": "Beaverton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Beaverton, Oregon"
    },
    {
      "city": "Tualatin",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Tualatin, Oregon"
    },
    {
      "city": "Banks",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Banks, Oregon"
    },
    {
      "city": "Buxton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Buxton, Oregon"
    },
    {
      "city": "Cornelius",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Cornelius, Oregon"
    },
    {
      "city": "Forest Grove",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Forest Grove, Oregon"
    },
    {
      "city": "Gales Creek",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gales Creek, Oregon"
    },
    {
      "city": "Gaston",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Gaston, Oregon"
    },
    {
      "city": "Hillsboro",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Hillsboro, Oregon"
    },
    {
      "city": "Manning",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Manning, Oregon"
    },
    {
      "city": "North Plains",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "North Plains, Oregon"
    },
    {
      "city": "Sherwood",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sherwood, Oregon"
    },
    {
      "city": "Timber",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Timber, Oregon"
    },
    {
      "city": "Mitchell",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mitchell, Oregon"
    },
    {
      "city": "Fossil",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Fossil, Oregon"
    },
    {
      "city": "Spray",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Spray, Oregon"
    },
    {
      "city": "Amity",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Amity, Oregon"
    },
    {
      "city": "Carlton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Carlton, Oregon"
    },
    {
      "city": "Dayton",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dayton, Oregon"
    },
    {
      "city": "Dundee",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Dundee, Oregon"
    },
    {
      "city": "Lafayette",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Lafayette, Oregon"
    },
    {
      "city": "Mcminnville",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Mcminnville, Oregon"
    },
    {
      "city": "Newberg",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Newberg, Oregon"
    },
    {
      "city": "Yamhill",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Yamhill, Oregon"
    },
    {
      "city": "Sheridan",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Sheridan, Oregon"
    },
    {
      "city": "Willamina",
      "state": "Oregon",
      "shortState": "OR",
      "formatedCity": "Willamina, Oregon"
    },
    {
      "city": "Abbottstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Abbottstown, Pennsylvania"
    },
    {
      "city": "Arendtsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Arendtsville, Pennsylvania"
    },
    {
      "city": "Aspers",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aspers, Pennsylvania"
    },
    {
      "city": "Bendersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bendersville, Pennsylvania"
    },
    {
      "city": "Biglerville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Biglerville, Pennsylvania"
    },
    {
      "city": "Cashtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cashtown, Pennsylvania"
    },
    {
      "city": "East Berlin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Berlin, Pennsylvania"
    },
    {
      "city": "Fairfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairfield, Pennsylvania"
    },
    {
      "city": "Gardners",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gardners, Pennsylvania"
    },
    {
      "city": "Gettysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gettysburg, Pennsylvania"
    },
    {
      "city": "Idaville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Idaville, Pennsylvania"
    },
    {
      "city": "Littlestown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Littlestown, Pennsylvania"
    },
    {
      "city": "Mc Knightstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Knightstown, Pennsylvania"
    },
    {
      "city": "Mc Sherrystown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Sherrystown, Pennsylvania"
    },
    {
      "city": "New Oxford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Oxford, Pennsylvania"
    },
    {
      "city": "Orrtanna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orrtanna, Pennsylvania"
    },
    {
      "city": "York Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "York Springs, Pennsylvania"
    },
    {
      "city": "Peach Glen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Peach Glen, Pennsylvania"
    },
    {
      "city": "Bairdford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bairdford, Pennsylvania"
    },
    {
      "city": "Bakerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bakerstown, Pennsylvania"
    },
    {
      "city": "Brackenridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brackenridge, Pennsylvania"
    },
    {
      "city": "Bradfordwoods",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bradfordwoods, Pennsylvania"
    },
    {
      "city": "Bridgeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bridgeville, Pennsylvania"
    },
    {
      "city": "Buena Vista",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Buena Vista, Pennsylvania"
    },
    {
      "city": "Bunola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bunola, Pennsylvania"
    },
    {
      "city": "Cheswick",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cheswick, Pennsylvania"
    },
    {
      "city": "Clairton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clairton, Pennsylvania"
    },
    {
      "city": "Clinton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clinton, Pennsylvania"
    },
    {
      "city": "Coulters",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coulters, Pennsylvania"
    },
    {
      "city": "Creighton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Creighton, Pennsylvania"
    },
    {
      "city": "Cuddy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cuddy, Pennsylvania"
    },
    {
      "city": "Curtisville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Curtisville, Pennsylvania"
    },
    {
      "city": "Dravosburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dravosburg, Pennsylvania"
    },
    {
      "city": "East Mc Keesport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Mc Keesport, Pennsylvania"
    },
    {
      "city": "Elizabeth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elizabeth, Pennsylvania"
    },
    {
      "city": "Gibsonia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gibsonia, Pennsylvania"
    },
    {
      "city": "Glassport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glassport, Pennsylvania"
    },
    {
      "city": "Crescent",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crescent, Pennsylvania"
    },
    {
      "city": "Greenock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greenock, Pennsylvania"
    },
    {
      "city": "Harwick",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harwick, Pennsylvania"
    },
    {
      "city": "Indianola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Indianola, Pennsylvania"
    },
    {
      "city": "Leetsdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leetsdale, Pennsylvania"
    },
    {
      "city": "Morgan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morgan, Pennsylvania"
    },
    {
      "city": "Natrona Heights",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Natrona Heights, Pennsylvania"
    },
    {
      "city": "Oakdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oakdale, Pennsylvania"
    },
    {
      "city": "Rural Ridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rural Ridge, Pennsylvania"
    },
    {
      "city": "Russellton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Russellton, Pennsylvania"
    },
    {
      "city": "Sturgeon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sturgeon, Pennsylvania"
    },
    {
      "city": "Tarentum",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tarentum, Pennsylvania"
    },
    {
      "city": "Warrendale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warrendale, Pennsylvania"
    },
    {
      "city": "West Elizabeth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Elizabeth, Pennsylvania"
    },
    {
      "city": "Wexford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wexford, Pennsylvania"
    },
    {
      "city": "Wildwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wildwood, Pennsylvania"
    },
    {
      "city": "Allison Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allison Park, Pennsylvania"
    },
    {
      "city": "Bethel Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bethel Park, Pennsylvania"
    },
    {
      "city": "Braddock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Braddock, Pennsylvania"
    },
    {
      "city": "Carnegie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carnegie, Pennsylvania"
    },
    {
      "city": "Coraopolis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coraopolis, Pennsylvania"
    },
    {
      "city": "Duquesne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Duquesne, Pennsylvania"
    },
    {
      "city": "East Pittsburgh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Pittsburgh, Pennsylvania"
    },
    {
      "city": "Glenshaw",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glenshaw, Pennsylvania"
    },
    {
      "city": "Homestead",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Homestead, Pennsylvania"
    },
    {
      "city": "West Mifflin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Mifflin, Pennsylvania"
    },
    {
      "city": "Imperial",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Imperial, Pennsylvania"
    },
    {
      "city": "Ingomar",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ingomar, Pennsylvania"
    },
    {
      "city": "South Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Park, Pennsylvania"
    },
    {
      "city": "Mckeesport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mckeesport, Pennsylvania"
    },
    {
      "city": "Mc Kees Rocks",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Kees Rocks, Pennsylvania"
    },
    {
      "city": "North Versailles",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Versailles, Pennsylvania"
    },
    {
      "city": "Oakmont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oakmont, Pennsylvania"
    },
    {
      "city": "Pitcairn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pitcairn, Pennsylvania"
    },
    {
      "city": "Presto",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Presto, Pennsylvania"
    },
    {
      "city": "Sewickley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sewickley, Pennsylvania"
    },
    {
      "city": "Springdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springdale, Pennsylvania"
    },
    {
      "city": "Turtle Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Turtle Creek, Pennsylvania"
    },
    {
      "city": "Monroeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Monroeville, Pennsylvania"
    },
    {
      "city": "Verona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Verona, Pennsylvania"
    },
    {
      "city": "Wilmerding",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wilmerding, Pennsylvania"
    },
    {
      "city": "Pittsburgh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pittsburgh, Pennsylvania"
    },
    {
      "city": "Leechburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leechburg, Pennsylvania"
    },
    {
      "city": "North Apollo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Apollo, Pennsylvania"
    },
    {
      "city": "Schenley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schenley, Pennsylvania"
    },
    {
      "city": "Spring Church",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Church, Pennsylvania"
    },
    {
      "city": "Elderton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elderton, Pennsylvania"
    },
    {
      "city": "Shelocta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shelocta, Pennsylvania"
    },
    {
      "city": "Parker",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Parker, Pennsylvania"
    },
    {
      "city": "Kittanning",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kittanning, Pennsylvania"
    },
    {
      "city": "Adrian",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Adrian, Pennsylvania"
    },
    {
      "city": "Cadogan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cadogan, Pennsylvania"
    },
    {
      "city": "Cowansville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cowansville, Pennsylvania"
    },
    {
      "city": "Dayton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dayton, Pennsylvania"
    },
    {
      "city": "Distant",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Distant, Pennsylvania"
    },
    {
      "city": "Ford City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ford City, Pennsylvania"
    },
    {
      "city": "Ford Cliff",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ford Cliff, Pennsylvania"
    },
    {
      "city": "Freeport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Freeport, Pennsylvania"
    },
    {
      "city": "Mc Grann",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Grann, Pennsylvania"
    },
    {
      "city": "Manorville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Manorville, Pennsylvania"
    },
    {
      "city": "Nu Mine",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nu Mine, Pennsylvania"
    },
    {
      "city": "Oak Ridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oak Ridge, Pennsylvania"
    },
    {
      "city": "Rural Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rural Valley, Pennsylvania"
    },
    {
      "city": "Sagamore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sagamore, Pennsylvania"
    },
    {
      "city": "Seminole",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seminole, Pennsylvania"
    },
    {
      "city": "Templeton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Templeton, Pennsylvania"
    },
    {
      "city": "Widnoon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Widnoon, Pennsylvania"
    },
    {
      "city": "Worthington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Worthington, Pennsylvania"
    },
    {
      "city": "Yatesboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Yatesboro, Pennsylvania"
    },
    {
      "city": "Aliquippa",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aliquippa, Pennsylvania"
    },
    {
      "city": "Ambridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ambridge, Pennsylvania"
    },
    {
      "city": "Baden",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Baden, Pennsylvania"
    },
    {
      "city": "Beaver",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beaver, Pennsylvania"
    },
    {
      "city": "Beaver Falls",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beaver Falls, Pennsylvania"
    },
    {
      "city": "Conway",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conway, Pennsylvania"
    },
    {
      "city": "Freedom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Freedom, Pennsylvania"
    },
    {
      "city": "Georgetown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Georgetown, Pennsylvania"
    },
    {
      "city": "Hookstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hookstown, Pennsylvania"
    },
    {
      "city": "Industry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Industry, Pennsylvania"
    },
    {
      "city": "Midland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Midland, Pennsylvania"
    },
    {
      "city": "Monaca",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Monaca, Pennsylvania"
    },
    {
      "city": "New Brighton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Brighton, Pennsylvania"
    },
    {
      "city": "Rochester",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rochester, Pennsylvania"
    },
    {
      "city": "Shippingport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shippingport, Pennsylvania"
    },
    {
      "city": "South Heights",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Heights, Pennsylvania"
    },
    {
      "city": "Darlington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Darlington, Pennsylvania"
    },
    {
      "city": "Fombell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fombell, Pennsylvania"
    },
    {
      "city": "Koppel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Koppel, Pennsylvania"
    },
    {
      "city": "New Galilee",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Galilee, Pennsylvania"
    },
    {
      "city": "Alum Bank",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alum Bank, Pennsylvania"
    },
    {
      "city": "Bedford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bedford, Pennsylvania"
    },
    {
      "city": "Breezewood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Breezewood, Pennsylvania"
    },
    {
      "city": "Buffalo Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Buffalo Mills, Pennsylvania"
    },
    {
      "city": "Clearville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clearville, Pennsylvania"
    },
    {
      "city": "Everett",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Everett, Pennsylvania"
    },
    {
      "city": "Fishertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fishertown, Pennsylvania"
    },
    {
      "city": "Hyndman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hyndman, Pennsylvania"
    },
    {
      "city": "Manns Choice",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Manns Choice, Pennsylvania"
    },
    {
      "city": "New Paris",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Paris, Pennsylvania"
    },
    {
      "city": "Schellsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schellsburg, Pennsylvania"
    },
    {
      "city": "Defiance",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Defiance, Pennsylvania"
    },
    {
      "city": "Hopewell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hopewell, Pennsylvania"
    },
    {
      "city": "Imler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Imler, Pennsylvania"
    },
    {
      "city": "Loysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loysburg, Pennsylvania"
    },
    {
      "city": "New Enterprise",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Enterprise, Pennsylvania"
    },
    {
      "city": "Osterburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Osterburg, Pennsylvania"
    },
    {
      "city": "Queen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Queen, Pennsylvania"
    },
    {
      "city": "Riddlesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Riddlesburg, Pennsylvania"
    },
    {
      "city": "Saxton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saxton, Pennsylvania"
    },
    {
      "city": "Six Mile Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Six Mile Run, Pennsylvania"
    },
    {
      "city": "Wood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wood, Pennsylvania"
    },
    {
      "city": "Woodbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woodbury, Pennsylvania"
    },
    {
      "city": "Artemas",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Artemas, Pennsylvania"
    },
    {
      "city": "Hereford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hereford, Pennsylvania"
    },
    {
      "city": "Bally",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bally, Pennsylvania"
    },
    {
      "city": "Barto",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Barto, Pennsylvania"
    },
    {
      "city": "Bechtelsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bechtelsville, Pennsylvania"
    },
    {
      "city": "Bernville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bernville, Pennsylvania"
    },
    {
      "city": "Bethel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bethel, Pennsylvania"
    },
    {
      "city": "Birdsboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Birdsboro, Pennsylvania"
    },
    {
      "city": "Blandon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blandon, Pennsylvania"
    },
    {
      "city": "Bowers",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bowers, Pennsylvania"
    },
    {
      "city": "Boyertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boyertown, Pennsylvania"
    },
    {
      "city": "Centerport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Centerport, Pennsylvania"
    },
    {
      "city": "Douglassville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Douglassville, Pennsylvania"
    },
    {
      "city": "Earlville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Earlville, Pennsylvania"
    },
    {
      "city": "Fleetwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fleetwood, Pennsylvania"
    },
    {
      "city": "Geigertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Geigertown, Pennsylvania"
    },
    {
      "city": "Hamburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hamburg, Pennsylvania"
    },
    {
      "city": "Kempton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kempton, Pennsylvania"
    },
    {
      "city": "Kutztown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kutztown, Pennsylvania"
    },
    {
      "city": "Leesport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leesport, Pennsylvania"
    },
    {
      "city": "Lenhartsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lenhartsville, Pennsylvania"
    },
    {
      "city": "Limekiln",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Limekiln, Pennsylvania"
    },
    {
      "city": "Lyon Station",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lyon Station, Pennsylvania"
    },
    {
      "city": "Maxatawny",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Maxatawny, Pennsylvania"
    },
    {
      "city": "Mertztown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mertztown, Pennsylvania"
    },
    {
      "city": "Mohnton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mohnton, Pennsylvania"
    },
    {
      "city": "Mohrsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mohrsville, Pennsylvania"
    },
    {
      "city": "Morgantown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morgantown, Pennsylvania"
    },
    {
      "city": "Mount Aetna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Aetna, Pennsylvania"
    },
    {
      "city": "New Berlinville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Berlinville, Pennsylvania"
    },
    {
      "city": "Oley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oley, Pennsylvania"
    },
    {
      "city": "Pine Forge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pine Forge, Pennsylvania"
    },
    {
      "city": "Rehrersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rehrersburg, Pennsylvania"
    },
    {
      "city": "Robesonia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Robesonia, Pennsylvania"
    },
    {
      "city": "Shartlesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shartlesville, Pennsylvania"
    },
    {
      "city": "Shoemakersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shoemakersville, Pennsylvania"
    },
    {
      "city": "Strausstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Strausstown, Pennsylvania"
    },
    {
      "city": "Temple",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Temple, Pennsylvania"
    },
    {
      "city": "Topton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Topton, Pennsylvania"
    },
    {
      "city": "Virginville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Virginville, Pennsylvania"
    },
    {
      "city": "Wernersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wernersville, Pennsylvania"
    },
    {
      "city": "Womelsdorf",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Womelsdorf, Pennsylvania"
    },
    {
      "city": "Reading",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reading, Pennsylvania"
    },
    {
      "city": "Altoona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Altoona, Pennsylvania"
    },
    {
      "city": "Bellwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bellwood, Pennsylvania"
    },
    {
      "city": "Claysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Claysburg, Pennsylvania"
    },
    {
      "city": "Curryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Curryville, Pennsylvania"
    },
    {
      "city": "Duncansville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Duncansville, Pennsylvania"
    },
    {
      "city": "East Freedom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Freedom, Pennsylvania"
    },
    {
      "city": "Hollidaysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hollidaysburg, Pennsylvania"
    },
    {
      "city": "Martinsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Martinsburg, Pennsylvania"
    },
    {
      "city": "Newry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newry, Pennsylvania"
    },
    {
      "city": "Roaring Spring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Roaring Spring, Pennsylvania"
    },
    {
      "city": "Sproul",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sproul, Pennsylvania"
    },
    {
      "city": "Tipton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tipton, Pennsylvania"
    },
    {
      "city": "Tyrone",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tyrone, Pennsylvania"
    },
    {
      "city": "Williamsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Williamsburg, Pennsylvania"
    },
    {
      "city": "Alba",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alba, Pennsylvania"
    },
    {
      "city": "Columbia Cross Roads",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Columbia Cross Roads, Pennsylvania"
    },
    {
      "city": "Gillett",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gillett, Pennsylvania"
    },
    {
      "city": "Granville Summit",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Granville Summit, Pennsylvania"
    },
    {
      "city": "Sylvania",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sylvania, Pennsylvania"
    },
    {
      "city": "Troy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Troy, Pennsylvania"
    },
    {
      "city": "Canton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Canton, Pennsylvania"
    },
    {
      "city": "Grover",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grover, Pennsylvania"
    },
    {
      "city": "Athens",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Athens, Pennsylvania"
    },
    {
      "city": "Burlington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Burlington, Pennsylvania"
    },
    {
      "city": "Camptown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Camptown, Pennsylvania"
    },
    {
      "city": "East Smithfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Smithfield, Pennsylvania"
    },
    {
      "city": "Le Raysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Le Raysville, Pennsylvania"
    },
    {
      "city": "Milan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milan, Pennsylvania"
    },
    {
      "city": "Monroeton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Monroeton, Pennsylvania"
    },
    {
      "city": "New Albany",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Albany, Pennsylvania"
    },
    {
      "city": "Rome",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rome, Pennsylvania"
    },
    {
      "city": "Sayre",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sayre, Pennsylvania"
    },
    {
      "city": "Stevensville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stevensville, Pennsylvania"
    },
    {
      "city": "Sugar Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sugar Run, Pennsylvania"
    },
    {
      "city": "Towanda",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Towanda, Pennsylvania"
    },
    {
      "city": "Ulster",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ulster, Pennsylvania"
    },
    {
      "city": "Warren Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warren Center, Pennsylvania"
    },
    {
      "city": "Wyalusing",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wyalusing, Pennsylvania"
    },
    {
      "city": "Wysox",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wysox, Pennsylvania"
    },
    {
      "city": "Durham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Durham, Pennsylvania"
    },
    {
      "city": "Riegelsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Riegelsville, Pennsylvania"
    },
    {
      "city": "Springtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springtown, Pennsylvania"
    },
    {
      "city": "Doylestown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Doylestown, Pennsylvania"
    },
    {
      "city": "Bedminster",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bedminster, Pennsylvania"
    },
    {
      "city": "Blooming Glen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blooming Glen, Pennsylvania"
    },
    {
      "city": "Buckingham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Buckingham, Pennsylvania"
    },
    {
      "city": "Carversville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carversville, Pennsylvania"
    },
    {
      "city": "Chalfont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chalfont, Pennsylvania"
    },
    {
      "city": "Danboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Danboro, Pennsylvania"
    },
    {
      "city": "Dublin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dublin, Pennsylvania"
    },
    {
      "city": "Erwinna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Erwinna, Pennsylvania"
    },
    {
      "city": "Ferndale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ferndale, Pennsylvania"
    },
    {
      "city": "Forest Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Forest Grove, Pennsylvania"
    },
    {
      "city": "Fountainville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fountainville, Pennsylvania"
    },
    {
      "city": "Furlong",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Furlong, Pennsylvania"
    },
    {
      "city": "Hilltown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hilltown, Pennsylvania"
    },
    {
      "city": "Holicong",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Holicong, Pennsylvania"
    },
    {
      "city": "Jamison",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jamison, Pennsylvania"
    },
    {
      "city": "Kintnersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kintnersville, Pennsylvania"
    },
    {
      "city": "Lahaska",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lahaska, Pennsylvania"
    },
    {
      "city": "Line Lexington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Line Lexington, Pennsylvania"
    },
    {
      "city": "Lumberville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lumberville, Pennsylvania"
    },
    {
      "city": "Mechanicsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mechanicsville, Pennsylvania"
    },
    {
      "city": "Milford Square",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milford Square, Pennsylvania"
    },
    {
      "city": "New Hope",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Hope, Pennsylvania"
    },
    {
      "city": "Newtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newtown, Pennsylvania"
    },
    {
      "city": "Ottsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ottsville, Pennsylvania"
    },
    {
      "city": "Penns Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penns Park, Pennsylvania"
    },
    {
      "city": "Perkasie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Perkasie, Pennsylvania"
    },
    {
      "city": "Pineville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pineville, Pennsylvania"
    },
    {
      "city": "Pipersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pipersville, Pennsylvania"
    },
    {
      "city": "Plumsteadville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Plumsteadville, Pennsylvania"
    },
    {
      "city": "Point Pleasant",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Point Pleasant, Pennsylvania"
    },
    {
      "city": "Quakertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quakertown, Pennsylvania"
    },
    {
      "city": "Revere",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Revere, Pennsylvania"
    },
    {
      "city": "Richboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Richboro, Pennsylvania"
    },
    {
      "city": "Richlandtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Richlandtown, Pennsylvania"
    },
    {
      "city": "Rushland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rushland, Pennsylvania"
    },
    {
      "city": "Sellersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sellersville, Pennsylvania"
    },
    {
      "city": "Silverdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Silverdale, Pennsylvania"
    },
    {
      "city": "Solebury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Solebury, Pennsylvania"
    },
    {
      "city": "Southampton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Southampton, Pennsylvania"
    },
    {
      "city": "Spinnerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spinnerstown, Pennsylvania"
    },
    {
      "city": "Trumbauersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Trumbauersville, Pennsylvania"
    },
    {
      "city": "Upper Black Eddy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Upper Black Eddy, Pennsylvania"
    },
    {
      "city": "Warminster",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warminster, Pennsylvania"
    },
    {
      "city": "Warrington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warrington, Pennsylvania"
    },
    {
      "city": "Washington Crossing",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Washington Crossing, Pennsylvania"
    },
    {
      "city": "Wycombe",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wycombe, Pennsylvania"
    },
    {
      "city": "Zionhill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zionhill, Pennsylvania"
    },
    {
      "city": "Bristol",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bristol, Pennsylvania"
    },
    {
      "city": "Bensalem",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bensalem, Pennsylvania"
    },
    {
      "city": "Croydon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Croydon, Pennsylvania"
    },
    {
      "city": "Fairless Hills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairless Hills, Pennsylvania"
    },
    {
      "city": "Langhorne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Langhorne, Pennsylvania"
    },
    {
      "city": "Feasterville Trevose",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Feasterville Trevose, Pennsylvania"
    },
    {
      "city": "Levittown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Levittown, Pennsylvania"
    },
    {
      "city": "Morrisville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morrisville, Pennsylvania"
    },
    {
      "city": "Butler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Butler, Pennsylvania"
    },
    {
      "city": "Boyers",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boyers, Pennsylvania"
    },
    {
      "city": "Branchton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Branchton, Pennsylvania"
    },
    {
      "city": "Bruin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bruin, Pennsylvania"
    },
    {
      "city": "Cabot",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cabot, Pennsylvania"
    },
    {
      "city": "Callery",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Callery, Pennsylvania"
    },
    {
      "city": "Chicora",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chicora, Pennsylvania"
    },
    {
      "city": "Connoquenessing",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Connoquenessing, Pennsylvania"
    },
    {
      "city": "East Butler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Butler, Pennsylvania"
    },
    {
      "city": "Eau Claire",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Eau Claire, Pennsylvania"
    },
    {
      "city": "Evans City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Evans City, Pennsylvania"
    },
    {
      "city": "Fenelton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fenelton, Pennsylvania"
    },
    {
      "city": "Forestville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Forestville, Pennsylvania"
    },
    {
      "city": "Harmony",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harmony, Pennsylvania"
    },
    {
      "city": "Harrisville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harrisville, Pennsylvania"
    },
    {
      "city": "Herman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Herman, Pennsylvania"
    },
    {
      "city": "Hilliards",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hilliards, Pennsylvania"
    },
    {
      "city": "Karns City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Karns City, Pennsylvania"
    },
    {
      "city": "Lyndora",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lyndora, Pennsylvania"
    },
    {
      "city": "Mars",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mars, Pennsylvania"
    },
    {
      "city": "North Washington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Washington, Pennsylvania"
    },
    {
      "city": "Petrolia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Petrolia, Pennsylvania"
    },
    {
      "city": "Portersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Portersville, Pennsylvania"
    },
    {
      "city": "Prospect",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Prospect, Pennsylvania"
    },
    {
      "city": "Renfrew",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Renfrew, Pennsylvania"
    },
    {
      "city": "Sarver",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sarver, Pennsylvania"
    },
    {
      "city": "Saxonburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saxonburg, Pennsylvania"
    },
    {
      "city": "Slippery Rock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slippery Rock, Pennsylvania"
    },
    {
      "city": "Valencia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Valencia, Pennsylvania"
    },
    {
      "city": "West Sunbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Sunbury, Pennsylvania"
    },
    {
      "city": "Zelienople",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zelienople, Pennsylvania"
    },
    {
      "city": "Cranberry Township",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cranberry Township, Pennsylvania"
    },
    {
      "city": "Northern Cambria",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Northern Cambria, Pennsylvania"
    },
    {
      "city": "Carrolltown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carrolltown, Pennsylvania"
    },
    {
      "city": "Elmora",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elmora, Pennsylvania"
    },
    {
      "city": "Emeigh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Emeigh, Pennsylvania"
    },
    {
      "city": "Marsteller",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marsteller, Pennsylvania"
    },
    {
      "city": "Nicktown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nicktown, Pennsylvania"
    },
    {
      "city": "Saint Benedict",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Benedict, Pennsylvania"
    },
    {
      "city": "Spangler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spangler, Pennsylvania"
    },
    {
      "city": "Johnstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Johnstown, Pennsylvania"
    },
    {
      "city": "Beaverdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beaverdale, Pennsylvania"
    },
    {
      "city": "Belsano",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Belsano, Pennsylvania"
    },
    {
      "city": "Cassandra",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cassandra, Pennsylvania"
    },
    {
      "city": "Colver",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Colver, Pennsylvania"
    },
    {
      "city": "Dunlo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dunlo, Pennsylvania"
    },
    {
      "city": "Ebensburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ebensburg, Pennsylvania"
    },
    {
      "city": "Elton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elton, Pennsylvania"
    },
    {
      "city": "Lilly",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lilly, Pennsylvania"
    },
    {
      "city": "Loretto",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loretto, Pennsylvania"
    },
    {
      "city": "Mineral Point",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mineral Point, Pennsylvania"
    },
    {
      "city": "Nanty Glo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nanty Glo, Pennsylvania"
    },
    {
      "city": "Parkhill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Parkhill, Pennsylvania"
    },
    {
      "city": "Portage",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Portage, Pennsylvania"
    },
    {
      "city": "Revloc",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Revloc, Pennsylvania"
    },
    {
      "city": "Saint Michael",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Michael, Pennsylvania"
    },
    {
      "city": "Salix",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Salix, Pennsylvania"
    },
    {
      "city": "Sidman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sidman, Pennsylvania"
    },
    {
      "city": "South Fork",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Fork, Pennsylvania"
    },
    {
      "city": "Summerhill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Summerhill, Pennsylvania"
    },
    {
      "city": "Twin Rocks",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Twin Rocks, Pennsylvania"
    },
    {
      "city": "Vintondale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vintondale, Pennsylvania"
    },
    {
      "city": "Wilmore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wilmore, Pennsylvania"
    },
    {
      "city": "Ashville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ashville, Pennsylvania"
    },
    {
      "city": "Blandburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blandburg, Pennsylvania"
    },
    {
      "city": "Chest Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chest Springs, Pennsylvania"
    },
    {
      "city": "Coupon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coupon, Pennsylvania"
    },
    {
      "city": "Cresson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cresson, Pennsylvania"
    },
    {
      "city": "Dysart",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dysart, Pennsylvania"
    },
    {
      "city": "Fallentimber",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fallentimber, Pennsylvania"
    },
    {
      "city": "Flinton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Flinton, Pennsylvania"
    },
    {
      "city": "Gallitzin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gallitzin, Pennsylvania"
    },
    {
      "city": "Glasgow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glasgow, Pennsylvania"
    },
    {
      "city": "Hastings",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hastings, Pennsylvania"
    },
    {
      "city": "Patton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Patton, Pennsylvania"
    },
    {
      "city": "Saint Boniface",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Boniface, Pennsylvania"
    },
    {
      "city": "Driftwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Driftwood, Pennsylvania"
    },
    {
      "city": "Emporium",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Emporium, Pennsylvania"
    },
    {
      "city": "Sinnamahoning",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sinnamahoning, Pennsylvania"
    },
    {
      "city": "Aquashicola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aquashicola, Pennsylvania"
    },
    {
      "city": "Bowmanstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bowmanstown, Pennsylvania"
    },
    {
      "city": "Palmerton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Palmerton, Pennsylvania"
    },
    {
      "city": "Albrightsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Albrightsville, Pennsylvania"
    },
    {
      "city": "Ashfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ashfield, Pennsylvania"
    },
    {
      "city": "Beaver Meadows",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beaver Meadows, Pennsylvania"
    },
    {
      "city": "Jim Thorpe",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jim Thorpe, Pennsylvania"
    },
    {
      "city": "Junedale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Junedale, Pennsylvania"
    },
    {
      "city": "Lansford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lansford, Pennsylvania"
    },
    {
      "city": "Lehighton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lehighton, Pennsylvania"
    },
    {
      "city": "Nesquehoning",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nesquehoning, Pennsylvania"
    },
    {
      "city": "Parryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Parryville, Pennsylvania"
    },
    {
      "city": "Summit Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Summit Hill, Pennsylvania"
    },
    {
      "city": "Tresckow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tresckow, Pennsylvania"
    },
    {
      "city": "Weatherly",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Weatherly, Pennsylvania"
    },
    {
      "city": "Lake Harmony",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake Harmony, Pennsylvania"
    },
    {
      "city": "Sandy Ridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sandy Ridge, Pennsylvania"
    },
    {
      "city": "State College",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "State College, Pennsylvania"
    },
    {
      "city": "University Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "University Park, Pennsylvania"
    },
    {
      "city": "Aaronsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aaronsburg, Pennsylvania"
    },
    {
      "city": "Bellefonte",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bellefonte, Pennsylvania"
    },
    {
      "city": "Blanchard",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blanchard, Pennsylvania"
    },
    {
      "city": "Boalsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boalsburg, Pennsylvania"
    },
    {
      "city": "Centre Hall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Centre Hall, Pennsylvania"
    },
    {
      "city": "Clarence",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarence, Pennsylvania"
    },
    {
      "city": "Coburn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coburn, Pennsylvania"
    },
    {
      "city": "Fleming",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fleming, Pennsylvania"
    },
    {
      "city": "Howard",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Howard, Pennsylvania"
    },
    {
      "city": "Julian",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Julian, Pennsylvania"
    },
    {
      "city": "Lemont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lemont, Pennsylvania"
    },
    {
      "city": "Madisonburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Madisonburg, Pennsylvania"
    },
    {
      "city": "Milesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milesburg, Pennsylvania"
    },
    {
      "city": "Millheim",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millheim, Pennsylvania"
    },
    {
      "city": "Mingoville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mingoville, Pennsylvania"
    },
    {
      "city": "Moshannon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Moshannon, Pennsylvania"
    },
    {
      "city": "Orviston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orviston, Pennsylvania"
    },
    {
      "city": "Pennsylvania Furnace",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pennsylvania Furnace, Pennsylvania"
    },
    {
      "city": "Philipsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Philipsburg, Pennsylvania"
    },
    {
      "city": "Pine Grove Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pine Grove Mills, Pennsylvania"
    },
    {
      "city": "Port Matilda",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Matilda, Pennsylvania"
    },
    {
      "city": "Rebersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rebersburg, Pennsylvania"
    },
    {
      "city": "Snow Shoe",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Snow Shoe, Pennsylvania"
    },
    {
      "city": "Spring Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Mills, Pennsylvania"
    },
    {
      "city": "Woodward",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woodward, Pennsylvania"
    },
    {
      "city": "Paoli",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Paoli, Pennsylvania"
    },
    {
      "city": "Atglen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Atglen, Pennsylvania"
    },
    {
      "city": "Avondale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Avondale, Pennsylvania"
    },
    {
      "city": "Berwyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Berwyn, Pennsylvania"
    },
    {
      "city": "Brandamore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brandamore, Pennsylvania"
    },
    {
      "city": "Chatham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chatham, Pennsylvania"
    },
    {
      "city": "Coatesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coatesville, Pennsylvania"
    },
    {
      "city": "Cochranville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cochranville, Pennsylvania"
    },
    {
      "city": "Devon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Devon, Pennsylvania"
    },
    {
      "city": "Downingtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Downingtown, Pennsylvania"
    },
    {
      "city": "Exton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Exton, Pennsylvania"
    },
    {
      "city": "Glenmoore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glenmoore, Pennsylvania"
    },
    {
      "city": "Honey Brook",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Honey Brook, Pennsylvania"
    },
    {
      "city": "Immaculata",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Immaculata, Pennsylvania"
    },
    {
      "city": "Kelton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kelton, Pennsylvania"
    },
    {
      "city": "Kemblesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kemblesville, Pennsylvania"
    },
    {
      "city": "Kennett Square",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kennett Square, Pennsylvania"
    },
    {
      "city": "Landenberg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Landenberg, Pennsylvania"
    },
    {
      "city": "Lewisville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lewisville, Pennsylvania"
    },
    {
      "city": "Lincoln University",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lincoln University, Pennsylvania"
    },
    {
      "city": "Lionville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lionville, Pennsylvania"
    },
    {
      "city": "Lyndell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lyndell, Pennsylvania"
    },
    {
      "city": "Malvern",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Malvern, Pennsylvania"
    },
    {
      "city": "Mendenhall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mendenhall, Pennsylvania"
    },
    {
      "city": "Modena",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Modena, Pennsylvania"
    },
    {
      "city": "New London",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New London, Pennsylvania"
    },
    {
      "city": "Nottingham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nottingham, Pennsylvania"
    },
    {
      "city": "Oxford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oxford, Pennsylvania"
    },
    {
      "city": "Parkesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Parkesburg, Pennsylvania"
    },
    {
      "city": "Pocopson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocopson, Pennsylvania"
    },
    {
      "city": "Pomeroy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pomeroy, Pennsylvania"
    },
    {
      "city": "Sadsburyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sadsburyville, Pennsylvania"
    },
    {
      "city": "Suplee",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Suplee, Pennsylvania"
    },
    {
      "city": "Thorndale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Thorndale, Pennsylvania"
    },
    {
      "city": "Toughkenamon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Toughkenamon, Pennsylvania"
    },
    {
      "city": "Unionville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Unionville, Pennsylvania"
    },
    {
      "city": "Wagontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wagontown, Pennsylvania"
    },
    {
      "city": "West Chester",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Chester, Pennsylvania"
    },
    {
      "city": "West Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Grove, Pennsylvania"
    },
    {
      "city": "Westtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westtown, Pennsylvania"
    },
    {
      "city": "Southeastern",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Southeastern, Pennsylvania"
    },
    {
      "city": "Birchrunville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Birchrunville, Pennsylvania"
    },
    {
      "city": "Chester Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chester Springs, Pennsylvania"
    },
    {
      "city": "Devault",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Devault, Pennsylvania"
    },
    {
      "city": "Kimberton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kimberton, Pennsylvania"
    },
    {
      "city": "Parker Ford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Parker Ford, Pennsylvania"
    },
    {
      "city": "Phoenixville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Phoenixville, Pennsylvania"
    },
    {
      "city": "Pottstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pottstown, Pennsylvania"
    },
    {
      "city": "Saint Peters",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Peters, Pennsylvania"
    },
    {
      "city": "Spring City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring City, Pennsylvania"
    },
    {
      "city": "Uwchland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Uwchland, Pennsylvania"
    },
    {
      "city": "Valley Forge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Valley Forge, Pennsylvania"
    },
    {
      "city": "Elverson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elverson, Pennsylvania"
    },
    {
      "city": "East Brady",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Brady, Pennsylvania"
    },
    {
      "city": "Foxburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Foxburg, Pennsylvania"
    },
    {
      "city": "Saint Petersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Petersburg, Pennsylvania"
    },
    {
      "city": "Turkey City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Turkey City, Pennsylvania"
    },
    {
      "city": "Callensburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Callensburg, Pennsylvania"
    },
    {
      "city": "Clarion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarion, Pennsylvania"
    },
    {
      "city": "Crown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crown, Pennsylvania"
    },
    {
      "city": "Curllsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Curllsville, Pennsylvania"
    },
    {
      "city": "Fairmount City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairmount City, Pennsylvania"
    },
    {
      "city": "Fisher",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fisher, Pennsylvania"
    },
    {
      "city": "Hawthorn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hawthorn, Pennsylvania"
    },
    {
      "city": "Knox",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Knox, Pennsylvania"
    },
    {
      "city": "Leeper",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leeper, Pennsylvania"
    },
    {
      "city": "Limestone",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Limestone, Pennsylvania"
    },
    {
      "city": "Lucinda",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lucinda, Pennsylvania"
    },
    {
      "city": "Mayport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mayport, Pennsylvania"
    },
    {
      "city": "New Bethlehem",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Bethlehem, Pennsylvania"
    },
    {
      "city": "Rimersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rimersburg, Pennsylvania"
    },
    {
      "city": "Shippenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shippenville, Pennsylvania"
    },
    {
      "city": "Sligo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sligo, Pennsylvania"
    },
    {
      "city": "Snydersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Snydersburg, Pennsylvania"
    },
    {
      "city": "Strattanville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Strattanville, Pennsylvania"
    },
    {
      "city": "Vowinckel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vowinckel, Pennsylvania"
    },
    {
      "city": "Fryburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fryburg, Pennsylvania"
    },
    {
      "city": "Kossuth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kossuth, Pennsylvania"
    },
    {
      "city": "Lickingville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lickingville, Pennsylvania"
    },
    {
      "city": "Marble",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marble, Pennsylvania"
    },
    {
      "city": "Tylersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tylersburg, Pennsylvania"
    },
    {
      "city": "Lamartine",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lamartine, Pennsylvania"
    },
    {
      "city": "Burnside",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Burnside, Pennsylvania"
    },
    {
      "city": "La Jose",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "La Jose, Pennsylvania"
    },
    {
      "city": "Mahaffey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mahaffey, Pennsylvania"
    },
    {
      "city": "Du Bois",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Du Bois, Pennsylvania"
    },
    {
      "city": "Luthersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Luthersburg, Pennsylvania"
    },
    {
      "city": "Penfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penfield, Pennsylvania"
    },
    {
      "city": "Rockton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rockton, Pennsylvania"
    },
    {
      "city": "Troutville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Troutville, Pennsylvania"
    },
    {
      "city": "Beccaria",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beccaria, Pennsylvania"
    },
    {
      "city": "Brisbin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brisbin, Pennsylvania"
    },
    {
      "city": "Coalport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coalport, Pennsylvania"
    },
    {
      "city": "Glen Hope",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Hope, Pennsylvania"
    },
    {
      "city": "Houtzdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Houtzdale, Pennsylvania"
    },
    {
      "city": "Irvona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Irvona, Pennsylvania"
    },
    {
      "city": "Madera",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Madera, Pennsylvania"
    },
    {
      "city": "Morann",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morann, Pennsylvania"
    },
    {
      "city": "Osceola Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Osceola Mills, Pennsylvania"
    },
    {
      "city": "Ramey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ramey, Pennsylvania"
    },
    {
      "city": "Smithmill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smithmill, Pennsylvania"
    },
    {
      "city": "Smokerun",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smokerun, Pennsylvania"
    },
    {
      "city": "Westover",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westover, Pennsylvania"
    },
    {
      "city": "Allport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allport, Pennsylvania"
    },
    {
      "city": "Bigler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bigler, Pennsylvania"
    },
    {
      "city": "Clearfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clearfield, Pennsylvania"
    },
    {
      "city": "Curwensville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Curwensville, Pennsylvania"
    },
    {
      "city": "Drifting",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Drifting, Pennsylvania"
    },
    {
      "city": "Frenchville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Frenchville, Pennsylvania"
    },
    {
      "city": "Glen Richey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Richey, Pennsylvania"
    },
    {
      "city": "Grampian",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grampian, Pennsylvania"
    },
    {
      "city": "Grassflat",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grassflat, Pennsylvania"
    },
    {
      "city": "Hawk Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hawk Run, Pennsylvania"
    },
    {
      "city": "Hyde",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hyde, Pennsylvania"
    },
    {
      "city": "Karthaus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Karthaus, Pennsylvania"
    },
    {
      "city": "Kylertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kylertown, Pennsylvania"
    },
    {
      "city": "Lanse",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lanse, Pennsylvania"
    },
    {
      "city": "Lecontes Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lecontes Mills, Pennsylvania"
    },
    {
      "city": "Mineral Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mineral Springs, Pennsylvania"
    },
    {
      "city": "Morrisdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morrisdale, Pennsylvania"
    },
    {
      "city": "Munson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Munson, Pennsylvania"
    },
    {
      "city": "New Millport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Millport, Pennsylvania"
    },
    {
      "city": "Olanta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Olanta, Pennsylvania"
    },
    {
      "city": "Pottersdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pottersdale, Pennsylvania"
    },
    {
      "city": "Shawville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shawville, Pennsylvania"
    },
    {
      "city": "Wallaceton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wallaceton, Pennsylvania"
    },
    {
      "city": "West Decatur",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Decatur, Pennsylvania"
    },
    {
      "city": "Winburne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Winburne, Pennsylvania"
    },
    {
      "city": "Woodland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woodland, Pennsylvania"
    },
    {
      "city": "Beech Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beech Creek, Pennsylvania"
    },
    {
      "city": "Lamar",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lamar, Pennsylvania"
    },
    {
      "city": "Avis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Avis, Pennsylvania"
    },
    {
      "city": "Castanea",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Castanea, Pennsylvania"
    },
    {
      "city": "Lock Haven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lock Haven, Pennsylvania"
    },
    {
      "city": "Loganton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loganton, Pennsylvania"
    },
    {
      "city": "Mc Elhattan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Elhattan, Pennsylvania"
    },
    {
      "city": "Mackeyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mackeyville, Pennsylvania"
    },
    {
      "city": "Mill Hall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mill Hall, Pennsylvania"
    },
    {
      "city": "North Bend",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Bend, Pennsylvania"
    },
    {
      "city": "Renovo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Renovo, Pennsylvania"
    },
    {
      "city": "Westport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westport, Pennsylvania"
    },
    {
      "city": "Woolrich",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woolrich, Pennsylvania"
    },
    {
      "city": "Benton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Benton, Pennsylvania"
    },
    {
      "city": "Bloomsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bloomsburg, Pennsylvania"
    },
    {
      "city": "Catawissa",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Catawissa, Pennsylvania"
    },
    {
      "city": "Millville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millville, Pennsylvania"
    },
    {
      "city": "Numidia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Numidia, Pennsylvania"
    },
    {
      "city": "Orangeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orangeville, Pennsylvania"
    },
    {
      "city": "Stillwater",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stillwater, Pennsylvania"
    },
    {
      "city": "Wilburton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wilburton, Pennsylvania"
    },
    {
      "city": "Aristes",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aristes, Pennsylvania"
    },
    {
      "city": "Berwick",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Berwick, Pennsylvania"
    },
    {
      "city": "Mifflinville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mifflinville, Pennsylvania"
    },
    {
      "city": "Adamsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Adamsville, Pennsylvania"
    },
    {
      "city": "Atlantic",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Atlantic, Pennsylvania"
    },
    {
      "city": "Hartstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hartstown, Pennsylvania"
    },
    {
      "city": "Cochranton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cochranton, Pennsylvania"
    },
    {
      "city": "Conneaut Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conneaut Lake, Pennsylvania"
    },
    {
      "city": "Guys Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Guys Mills, Pennsylvania"
    },
    {
      "city": "Hydetown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hydetown, Pennsylvania"
    },
    {
      "city": "Meadville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Meadville, Pennsylvania"
    },
    {
      "city": "Titusville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Titusville, Pennsylvania"
    },
    {
      "city": "Townville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Townville, Pennsylvania"
    },
    {
      "city": "Cambridge Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cambridge Springs, Pennsylvania"
    },
    {
      "city": "Centerville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Centerville, Pennsylvania"
    },
    {
      "city": "Conneautville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conneautville, Pennsylvania"
    },
    {
      "city": "Harmonsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harmonsburg, Pennsylvania"
    },
    {
      "city": "Linesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Linesville, Pennsylvania"
    },
    {
      "city": "Riceville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Riceville, Pennsylvania"
    },
    {
      "city": "Saegertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saegertown, Pennsylvania"
    },
    {
      "city": "Spartansburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spartansburg, Pennsylvania"
    },
    {
      "city": "Springboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springboro, Pennsylvania"
    },
    {
      "city": "Venango",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Venango, Pennsylvania"
    },
    {
      "city": "Camp Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Camp Hill, Pennsylvania"
    },
    {
      "city": "Boiling Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boiling Springs, Pennsylvania"
    },
    {
      "city": "Carlisle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carlisle, Pennsylvania"
    },
    {
      "city": "Enola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Enola, Pennsylvania"
    },
    {
      "city": "Grantham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grantham, Pennsylvania"
    },
    {
      "city": "Lemoyne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lemoyne, Pennsylvania"
    },
    {
      "city": "Mechanicsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mechanicsburg, Pennsylvania"
    },
    {
      "city": "Mount Holly Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Holly Springs, Pennsylvania"
    },
    {
      "city": "New Cumberland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Cumberland, Pennsylvania"
    },
    {
      "city": "New Kingstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Kingstown, Pennsylvania"
    },
    {
      "city": "Plainfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Plainfield, Pennsylvania"
    },
    {
      "city": "Summerdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Summerdale, Pennsylvania"
    },
    {
      "city": "Newburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newburg, Pennsylvania"
    },
    {
      "city": "Newville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newville, Pennsylvania"
    },
    {
      "city": "Shippensburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shippensburg, Pennsylvania"
    },
    {
      "city": "Walnut Bottom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Walnut Bottom, Pennsylvania"
    },
    {
      "city": "Berrysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Berrysburg, Pennsylvania"
    },
    {
      "city": "Dauphin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dauphin, Pennsylvania"
    },
    {
      "city": "Elizabethville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elizabethville, Pennsylvania"
    },
    {
      "city": "Grantville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grantville, Pennsylvania"
    },
    {
      "city": "Gratz",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gratz, Pennsylvania"
    },
    {
      "city": "Halifax",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Halifax, Pennsylvania"
    },
    {
      "city": "Hershey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hershey, Pennsylvania"
    },
    {
      "city": "Highspire",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Highspire, Pennsylvania"
    },
    {
      "city": "Hummelstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hummelstown, Pennsylvania"
    },
    {
      "city": "Lykens",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lykens, Pennsylvania"
    },
    {
      "city": "Middletown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Middletown, Pennsylvania"
    },
    {
      "city": "Millersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millersburg, Pennsylvania"
    },
    {
      "city": "Pillow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pillow, Pennsylvania"
    },
    {
      "city": "Wiconisco",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wiconisco, Pennsylvania"
    },
    {
      "city": "Williamstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Williamstown, Pennsylvania"
    },
    {
      "city": "Harrisburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harrisburg, Pennsylvania"
    },
    {
      "city": "Ardmore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ardmore, Pennsylvania"
    },
    {
      "city": "Broomall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Broomall, Pennsylvania"
    },
    {
      "city": "Bryn Mawr",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bryn Mawr, Pennsylvania"
    },
    {
      "city": "Chester",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chester, Pennsylvania"
    },
    {
      "city": "Aston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aston, Pennsylvania"
    },
    {
      "city": "Brookhaven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brookhaven, Pennsylvania"
    },
    {
      "city": "Chester Heights",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chester Heights, Pennsylvania"
    },
    {
      "city": "Clifton Heights",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clifton Heights, Pennsylvania"
    },
    {
      "city": "Crum Lynne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crum Lynne, Pennsylvania"
    },
    {
      "city": "Darby",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Darby, Pennsylvania"
    },
    {
      "city": "Drexel Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Drexel Hill, Pennsylvania"
    },
    {
      "city": "Edgemont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Edgemont, Pennsylvania"
    },
    {
      "city": "Essington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Essington, Pennsylvania"
    },
    {
      "city": "Folcroft",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Folcroft, Pennsylvania"
    },
    {
      "city": "Folsom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Folsom, Pennsylvania"
    },
    {
      "city": "Glenolden",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glenolden, Pennsylvania"
    },
    {
      "city": "Glen Riddle Lima",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Riddle Lima, Pennsylvania"
    },
    {
      "city": "Gradyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gradyville, Pennsylvania"
    },
    {
      "city": "Haverford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Haverford, Pennsylvania"
    },
    {
      "city": "Holmes",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Holmes, Pennsylvania"
    },
    {
      "city": "Lansdowne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lansdowne, Pennsylvania"
    },
    {
      "city": "Lenni",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lenni, Pennsylvania"
    },
    {
      "city": "Garnet Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Garnet Valley, Pennsylvania"
    },
    {
      "city": "Marcus Hook",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marcus Hook, Pennsylvania"
    },
    {
      "city": "Media",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Media, Pennsylvania"
    },
    {
      "city": "Springfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springfield, Pennsylvania"
    },
    {
      "city": "Morton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morton, Pennsylvania"
    },
    {
      "city": "Newtown Square",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newtown Square, Pennsylvania"
    },
    {
      "city": "Norwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Norwood, Pennsylvania"
    },
    {
      "city": "Prospect Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Prospect Park, Pennsylvania"
    },
    {
      "city": "Ridley Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ridley Park, Pennsylvania"
    },
    {
      "city": "Sharon Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sharon Hill, Pennsylvania"
    },
    {
      "city": "Wayne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wayne, Pennsylvania"
    },
    {
      "city": "Swarthmore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Swarthmore, Pennsylvania"
    },
    {
      "city": "Upper Darby",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Upper Darby, Pennsylvania"
    },
    {
      "city": "Havertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Havertown, Pennsylvania"
    },
    {
      "city": "Villanova",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Villanova, Pennsylvania"
    },
    {
      "city": "Wallingford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wallingford, Pennsylvania"
    },
    {
      "city": "Woodlyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woodlyn, Pennsylvania"
    },
    {
      "city": "Philadelphia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Philadelphia, Pennsylvania"
    },
    {
      "city": "Chadds Ford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chadds Ford, Pennsylvania"
    },
    {
      "city": "Cheyney",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cheyney, Pennsylvania"
    },
    {
      "city": "Concordville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Concordville, Pennsylvania"
    },
    {
      "city": "Glen Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Mills, Pennsylvania"
    },
    {
      "city": "Thornton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Thornton, Pennsylvania"
    },
    {
      "city": "Benezett",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Benezett, Pennsylvania"
    },
    {
      "city": "Brandy Camp",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brandy Camp, Pennsylvania"
    },
    {
      "city": "Brockport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brockport, Pennsylvania"
    },
    {
      "city": "Byrnedale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Byrnedale, Pennsylvania"
    },
    {
      "city": "Dagus Mines",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dagus Mines, Pennsylvania"
    },
    {
      "city": "Force",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Force, Pennsylvania"
    },
    {
      "city": "Johnsonburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Johnsonburg, Pennsylvania"
    },
    {
      "city": "Kersey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kersey, Pennsylvania"
    },
    {
      "city": "Ridgway",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ridgway, Pennsylvania"
    },
    {
      "city": "Saint Marys",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Marys, Pennsylvania"
    },
    {
      "city": "Weedville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Weedville, Pennsylvania"
    },
    {
      "city": "Wilcox",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wilcox, Pennsylvania"
    },
    {
      "city": "De Young",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "De Young, Pennsylvania"
    },
    {
      "city": "James City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "James City, Pennsylvania"
    },
    {
      "city": "Albion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Albion, Pennsylvania"
    },
    {
      "city": "Corry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Corry, Pennsylvania"
    },
    {
      "city": "Cranesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cranesville, Pennsylvania"
    },
    {
      "city": "East Springfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Springfield, Pennsylvania"
    },
    {
      "city": "Edinboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Edinboro, Pennsylvania"
    },
    {
      "city": "Elgin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elgin, Pennsylvania"
    },
    {
      "city": "Fairview",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairview, Pennsylvania"
    },
    {
      "city": "Girard",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Girard, Pennsylvania"
    },
    {
      "city": "Harborcreek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harborcreek, Pennsylvania"
    },
    {
      "city": "Lake City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake City, Pennsylvania"
    },
    {
      "city": "Mc Kean",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Kean, Pennsylvania"
    },
    {
      "city": "Mill Village",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mill Village, Pennsylvania"
    },
    {
      "city": "North East",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North East, Pennsylvania"
    },
    {
      "city": "North Springfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Springfield, Pennsylvania"
    },
    {
      "city": "Union City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Union City, Pennsylvania"
    },
    {
      "city": "Waterford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waterford, Pennsylvania"
    },
    {
      "city": "Wattsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wattsburg, Pennsylvania"
    },
    {
      "city": "West Springfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Springfield, Pennsylvania"
    },
    {
      "city": "Erie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Erie, Pennsylvania"
    },
    {
      "city": "Belle Vernon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Belle Vernon, Pennsylvania"
    },
    {
      "city": "Uniontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Uniontown, Pennsylvania"
    },
    {
      "city": "Adah",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Adah, Pennsylvania"
    },
    {
      "city": "Allison",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allison, Pennsylvania"
    },
    {
      "city": "Brier Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brier Hill, Pennsylvania"
    },
    {
      "city": "Brownfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brownfield, Pennsylvania"
    },
    {
      "city": "Brownsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brownsville, Pennsylvania"
    },
    {
      "city": "Cardale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cardale, Pennsylvania"
    },
    {
      "city": "Chalk Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chalk Hill, Pennsylvania"
    },
    {
      "city": "Chestnut Ridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chestnut Ridge, Pennsylvania"
    },
    {
      "city": "Connellsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Connellsville, Pennsylvania"
    },
    {
      "city": "Dawson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dawson, Pennsylvania"
    },
    {
      "city": "Dickerson Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dickerson Run, Pennsylvania"
    },
    {
      "city": "Dunbar",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dunbar, Pennsylvania"
    },
    {
      "city": "East Millsboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Millsboro, Pennsylvania"
    },
    {
      "city": "Fairbank",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairbank, Pennsylvania"
    },
    {
      "city": "Fairchance",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairchance, Pennsylvania"
    },
    {
      "city": "Farmington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Farmington, Pennsylvania"
    },
    {
      "city": "Fayette City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fayette City, Pennsylvania"
    },
    {
      "city": "Gans",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gans, Pennsylvania"
    },
    {
      "city": "Gibbon Glade",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gibbon Glade, Pennsylvania"
    },
    {
      "city": "Grindstone",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grindstone, Pennsylvania"
    },
    {
      "city": "Hibbs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hibbs, Pennsylvania"
    },
    {
      "city": "Hiller",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hiller, Pennsylvania"
    },
    {
      "city": "Hopwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hopwood, Pennsylvania"
    },
    {
      "city": "Indian Head",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Indian Head, Pennsylvania"
    },
    {
      "city": "Isabella",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Isabella, Pennsylvania"
    },
    {
      "city": "Keisterville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Keisterville, Pennsylvania"
    },
    {
      "city": "La Belle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "La Belle, Pennsylvania"
    },
    {
      "city": "Lake Lynn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake Lynn, Pennsylvania"
    },
    {
      "city": "Leckrone",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leckrone, Pennsylvania"
    },
    {
      "city": "Leisenring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leisenring, Pennsylvania"
    },
    {
      "city": "Lemont Furnace",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lemont Furnace, Pennsylvania"
    },
    {
      "city": "Mc Clellandtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Clellandtown, Pennsylvania"
    },
    {
      "city": "Markleysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Markleysburg, Pennsylvania"
    },
    {
      "city": "Martin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Martin, Pennsylvania"
    },
    {
      "city": "Masontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Masontown, Pennsylvania"
    },
    {
      "city": "Melcroft",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Melcroft, Pennsylvania"
    },
    {
      "city": "Merrittstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Merrittstown, Pennsylvania"
    },
    {
      "city": "Mill Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mill Run, Pennsylvania"
    },
    {
      "city": "Mount Braddock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Braddock, Pennsylvania"
    },
    {
      "city": "Newell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newell, Pennsylvania"
    },
    {
      "city": "New Geneva",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Geneva, Pennsylvania"
    },
    {
      "city": "New Salem",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Salem, Pennsylvania"
    },
    {
      "city": "Normalville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Normalville, Pennsylvania"
    },
    {
      "city": "Ohiopyle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ohiopyle, Pennsylvania"
    },
    {
      "city": "Oliver",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oliver, Pennsylvania"
    },
    {
      "city": "Perryopolis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Perryopolis, Pennsylvania"
    },
    {
      "city": "Point Marion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Point Marion, Pennsylvania"
    },
    {
      "city": "Republic",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Republic, Pennsylvania"
    },
    {
      "city": "Ronco",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ronco, Pennsylvania"
    },
    {
      "city": "Smithfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smithfield, Pennsylvania"
    },
    {
      "city": "Smock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smock, Pennsylvania"
    },
    {
      "city": "Star Junction",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Star Junction, Pennsylvania"
    },
    {
      "city": "Uledi",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Uledi, Pennsylvania"
    },
    {
      "city": "Vanderbilt",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vanderbilt, Pennsylvania"
    },
    {
      "city": "Waltersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waltersburg, Pennsylvania"
    },
    {
      "city": "West Leisenring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Leisenring, Pennsylvania"
    },
    {
      "city": "White",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "White, Pennsylvania"
    },
    {
      "city": "Wickhaven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wickhaven, Pennsylvania"
    },
    {
      "city": "Everson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Everson, Pennsylvania"
    },
    {
      "city": "Clarington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarington, Pennsylvania"
    },
    {
      "city": "Cooksburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cooksburg, Pennsylvania"
    },
    {
      "city": "Marienville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marienville, Pennsylvania"
    },
    {
      "city": "East Hickory",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Hickory, Pennsylvania"
    },
    {
      "city": "Endeavor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Endeavor, Pennsylvania"
    },
    {
      "city": "Tionesta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tionesta, Pennsylvania"
    },
    {
      "city": "West Hickory",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Hickory, Pennsylvania"
    },
    {
      "city": "Chambersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chambersburg, Pennsylvania"
    },
    {
      "city": "Amberson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Amberson, Pennsylvania"
    },
    {
      "city": "Blue Ridge Summit",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blue Ridge Summit, Pennsylvania"
    },
    {
      "city": "Concord",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Concord, Pennsylvania"
    },
    {
      "city": "Doylesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Doylesburg, Pennsylvania"
    },
    {
      "city": "Dry Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dry Run, Pennsylvania"
    },
    {
      "city": "Fannettsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fannettsburg, Pennsylvania"
    },
    {
      "city": "Fayetteville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fayetteville, Pennsylvania"
    },
    {
      "city": "Fort Loudon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fort Loudon, Pennsylvania"
    },
    {
      "city": "Greencastle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greencastle, Pennsylvania"
    },
    {
      "city": "Lemasters",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lemasters, Pennsylvania"
    },
    {
      "city": "Lurgan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lurgan, Pennsylvania"
    },
    {
      "city": "Marion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marion, Pennsylvania"
    },
    {
      "city": "Mercersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mercersburg, Pennsylvania"
    },
    {
      "city": "Mont Alto",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mont Alto, Pennsylvania"
    },
    {
      "city": "Orrstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orrstown, Pennsylvania"
    },
    {
      "city": "Pleasant Hall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pleasant Hall, Pennsylvania"
    },
    {
      "city": "Quincy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quincy, Pennsylvania"
    },
    {
      "city": "Rouzerville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rouzerville, Pennsylvania"
    },
    {
      "city": "Roxbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Roxbury, Pennsylvania"
    },
    {
      "city": "Saint Thomas",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Thomas, Pennsylvania"
    },
    {
      "city": "Scotland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Scotland, Pennsylvania"
    },
    {
      "city": "Shady Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shady Grove, Pennsylvania"
    },
    {
      "city": "South Mountain",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Mountain, Pennsylvania"
    },
    {
      "city": "Spring Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Run, Pennsylvania"
    },
    {
      "city": "State Line",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "State Line, Pennsylvania"
    },
    {
      "city": "Upperstrasburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Upperstrasburg, Pennsylvania"
    },
    {
      "city": "Waynesboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waynesboro, Pennsylvania"
    },
    {
      "city": "Willow Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Willow Hill, Pennsylvania"
    },
    {
      "city": "Zullinger",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zullinger, Pennsylvania"
    },
    {
      "city": "Crystal Spring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crystal Spring, Pennsylvania"
    },
    {
      "city": "Waterfall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waterfall, Pennsylvania"
    },
    {
      "city": "Wells Tannery",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wells Tannery, Pennsylvania"
    },
    {
      "city": "Big Cove Tannery",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Big Cove Tannery, Pennsylvania"
    },
    {
      "city": "Burnt Cabins",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Burnt Cabins, Pennsylvania"
    },
    {
      "city": "Fort Littleton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fort Littleton, Pennsylvania"
    },
    {
      "city": "Harrisonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harrisonville, Pennsylvania"
    },
    {
      "city": "Hustontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hustontown, Pennsylvania"
    },
    {
      "city": "Mc Connellsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Connellsburg, Pennsylvania"
    },
    {
      "city": "Needmore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Needmore, Pennsylvania"
    },
    {
      "city": "Warfordsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warfordsburg, Pennsylvania"
    },
    {
      "city": "Aleppo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aleppo, Pennsylvania"
    },
    {
      "city": "Bobtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bobtown, Pennsylvania"
    },
    {
      "city": "Brave",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brave, Pennsylvania"
    },
    {
      "city": "Carmichaels",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carmichaels, Pennsylvania"
    },
    {
      "city": "Clarksville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarksville, Pennsylvania"
    },
    {
      "city": "Crucible",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crucible, Pennsylvania"
    },
    {
      "city": "Dilliner",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dilliner, Pennsylvania"
    },
    {
      "city": "Garards Fort",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Garards Fort, Pennsylvania"
    },
    {
      "city": "Graysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Graysville, Pennsylvania"
    },
    {
      "city": "Greensboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greensboro, Pennsylvania"
    },
    {
      "city": "Holbrook",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Holbrook, Pennsylvania"
    },
    {
      "city": "Jefferson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jefferson, Pennsylvania"
    },
    {
      "city": "Mather",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mather, Pennsylvania"
    },
    {
      "city": "Mount Morris",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Morris, Pennsylvania"
    },
    {
      "city": "Nemacolin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nemacolin, Pennsylvania"
    },
    {
      "city": "New Freeport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Freeport, Pennsylvania"
    },
    {
      "city": "Nineveh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nineveh, Pennsylvania"
    },
    {
      "city": "Rices Landing",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rices Landing, Pennsylvania"
    },
    {
      "city": "Rogersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rogersville, Pennsylvania"
    },
    {
      "city": "Spraggs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spraggs, Pennsylvania"
    },
    {
      "city": "Sycamore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sycamore, Pennsylvania"
    },
    {
      "city": "Waynesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waynesburg, Pennsylvania"
    },
    {
      "city": "Wind Ridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wind Ridge, Pennsylvania"
    },
    {
      "city": "Alexandria",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alexandria, Pennsylvania"
    },
    {
      "city": "Broad Top",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Broad Top, Pennsylvania"
    },
    {
      "city": "Calvin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Calvin, Pennsylvania"
    },
    {
      "city": "Cassville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cassville, Pennsylvania"
    },
    {
      "city": "Dudley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dudley, Pennsylvania"
    },
    {
      "city": "Entriken",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Entriken, Pennsylvania"
    },
    {
      "city": "Hesston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hesston, Pennsylvania"
    },
    {
      "city": "Huntingdon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Huntingdon, Pennsylvania"
    },
    {
      "city": "James Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "James Creek, Pennsylvania"
    },
    {
      "city": "Mc Connellstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Connellstown, Pennsylvania"
    },
    {
      "city": "Petersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Petersburg, Pennsylvania"
    },
    {
      "city": "Robertsdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Robertsdale, Pennsylvania"
    },
    {
      "city": "Spruce Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spruce Creek, Pennsylvania"
    },
    {
      "city": "Todd",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Todd, Pennsylvania"
    },
    {
      "city": "Warriors Mark",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warriors Mark, Pennsylvania"
    },
    {
      "city": "Mapleton Depot",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mapleton Depot, Pennsylvania"
    },
    {
      "city": "Mill Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mill Creek, Pennsylvania"
    },
    {
      "city": "Mount Union",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Union, Pennsylvania"
    },
    {
      "city": "Blairs Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blairs Mills, Pennsylvania"
    },
    {
      "city": "Neelyton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Neelyton, Pennsylvania"
    },
    {
      "city": "Orbisonia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orbisonia, Pennsylvania"
    },
    {
      "city": "Rockhill Furnace",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rockhill Furnace, Pennsylvania"
    },
    {
      "city": "Saltillo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saltillo, Pennsylvania"
    },
    {
      "city": "Shade Gap",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shade Gap, Pennsylvania"
    },
    {
      "city": "Shirleysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shirleysburg, Pennsylvania"
    },
    {
      "city": "Three Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Three Springs, Pennsylvania"
    },
    {
      "city": "Saltsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saltsburg, Pennsylvania"
    },
    {
      "city": "Indiana",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Indiana, Pennsylvania"
    },
    {
      "city": "Alverda",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alverda, Pennsylvania"
    },
    {
      "city": "Arcadia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Arcadia, Pennsylvania"
    },
    {
      "city": "Aultman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Aultman, Pennsylvania"
    },
    {
      "city": "Black Lick",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Black Lick, Pennsylvania"
    },
    {
      "city": "Blairsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blairsville, Pennsylvania"
    },
    {
      "city": "Brush Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brush Valley, Pennsylvania"
    },
    {
      "city": "Chambersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chambersville, Pennsylvania"
    },
    {
      "city": "Cherry Tree",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cherry Tree, Pennsylvania"
    },
    {
      "city": "Clarksburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarksburg, Pennsylvania"
    },
    {
      "city": "Clune",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clune, Pennsylvania"
    },
    {
      "city": "Clymer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clymer, Pennsylvania"
    },
    {
      "city": "Commodore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Commodore, Pennsylvania"
    },
    {
      "city": "Coral",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coral, Pennsylvania"
    },
    {
      "city": "Creekside",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Creekside, Pennsylvania"
    },
    {
      "city": "Dixonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dixonville, Pennsylvania"
    },
    {
      "city": "Ernest",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ernest, Pennsylvania"
    },
    {
      "city": "Gipsy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gipsy, Pennsylvania"
    },
    {
      "city": "Glen Campbell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Campbell, Pennsylvania"
    },
    {
      "city": "Heilwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Heilwood, Pennsylvania"
    },
    {
      "city": "Hillsdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hillsdale, Pennsylvania"
    },
    {
      "city": "Home",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Home, Pennsylvania"
    },
    {
      "city": "Homer City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Homer City, Pennsylvania"
    },
    {
      "city": "Josephine",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Josephine, Pennsylvania"
    },
    {
      "city": "Kent",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kent, Pennsylvania"
    },
    {
      "city": "Lucernemines",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lucernemines, Pennsylvania"
    },
    {
      "city": "Mc Intyre",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Intyre, Pennsylvania"
    },
    {
      "city": "Marchand",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marchand, Pennsylvania"
    },
    {
      "city": "Marion Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marion Center, Pennsylvania"
    },
    {
      "city": "Mentcle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mentcle, Pennsylvania"
    },
    {
      "city": "Northpoint",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Northpoint, Pennsylvania"
    },
    {
      "city": "Penn Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penn Run, Pennsylvania"
    },
    {
      "city": "Rochester Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rochester Mills, Pennsylvania"
    },
    {
      "city": "Rossiter",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rossiter, Pennsylvania"
    },
    {
      "city": "Starford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Starford, Pennsylvania"
    },
    {
      "city": "West Lebanon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Lebanon, Pennsylvania"
    },
    {
      "city": "Armagh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Armagh, Pennsylvania"
    },
    {
      "city": "Dilltown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dilltown, Pennsylvania"
    },
    {
      "city": "Robinson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Robinson, Pennsylvania"
    },
    {
      "city": "Strongstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Strongstown, Pennsylvania"
    },
    {
      "city": "Beyer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beyer, Pennsylvania"
    },
    {
      "city": "Plumville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Plumville, Pennsylvania"
    },
    {
      "city": "Smicksburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smicksburg, Pennsylvania"
    },
    {
      "city": "Anita",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Anita, Pennsylvania"
    },
    {
      "city": "Big Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Big Run, Pennsylvania"
    },
    {
      "city": "Coolspring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coolspring, Pennsylvania"
    },
    {
      "city": "De Lancey",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "De Lancey, Pennsylvania"
    },
    {
      "city": "Hamilton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hamilton, Pennsylvania"
    },
    {
      "city": "Oliveburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oliveburg, Pennsylvania"
    },
    {
      "city": "Punxsutawney",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Punxsutawney, Pennsylvania"
    },
    {
      "city": "Ringgold",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ringgold, Pennsylvania"
    },
    {
      "city": "Sprankle Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sprankle Mills, Pennsylvania"
    },
    {
      "city": "Timblin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Timblin, Pennsylvania"
    },
    {
      "city": "Valier",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Valier, Pennsylvania"
    },
    {
      "city": "Walston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Walston, Pennsylvania"
    },
    {
      "city": "Worthville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Worthville, Pennsylvania"
    },
    {
      "city": "Brockway",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brockway, Pennsylvania"
    },
    {
      "city": "Brookville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brookville, Pennsylvania"
    },
    {
      "city": "Corsica",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Corsica, Pennsylvania"
    },
    {
      "city": "Falls Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Falls Creek, Pennsylvania"
    },
    {
      "city": "Knox Dale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Knox Dale, Pennsylvania"
    },
    {
      "city": "Reynoldsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reynoldsville, Pennsylvania"
    },
    {
      "city": "Sigel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sigel, Pennsylvania"
    },
    {
      "city": "Stump Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stump Creek, Pennsylvania"
    },
    {
      "city": "Summerville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Summerville, Pennsylvania"
    },
    {
      "city": "Sykesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sykesville, Pennsylvania"
    },
    {
      "city": "Cocolamus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cocolamus, Pennsylvania"
    },
    {
      "city": "East Waterford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Waterford, Pennsylvania"
    },
    {
      "city": "Honey Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Honey Grove, Pennsylvania"
    },
    {
      "city": "Mc Alisterville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Alisterville, Pennsylvania"
    },
    {
      "city": "Mexico",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mexico, Pennsylvania"
    },
    {
      "city": "Mifflin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mifflin, Pennsylvania"
    },
    {
      "city": "Mifflintown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mifflintown, Pennsylvania"
    },
    {
      "city": "Oakland Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oakland Mills, Pennsylvania"
    },
    {
      "city": "Port Royal",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Royal, Pennsylvania"
    },
    {
      "city": "Richfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Richfield, Pennsylvania"
    },
    {
      "city": "Thompsontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Thompsontown, Pennsylvania"
    },
    {
      "city": "Archbald",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Archbald, Pennsylvania"
    },
    {
      "city": "Carbondale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carbondale, Pennsylvania"
    },
    {
      "city": "Chinchilla",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chinchilla, Pennsylvania"
    },
    {
      "city": "Clarks Summit",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarks Summit, Pennsylvania"
    },
    {
      "city": "Dalton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dalton, Pennsylvania"
    },
    {
      "city": "Elmhurst",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elmhurst, Pennsylvania"
    },
    {
      "city": "Fleetville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fleetville, Pennsylvania"
    },
    {
      "city": "Jermyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jermyn, Pennsylvania"
    },
    {
      "city": "Jessup",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jessup, Pennsylvania"
    },
    {
      "city": "La Plume",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "La Plume, Pennsylvania"
    },
    {
      "city": "Moscow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Moscow, Pennsylvania"
    },
    {
      "city": "Olyphant",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Olyphant, Pennsylvania"
    },
    {
      "city": "Peckville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Peckville, Pennsylvania"
    },
    {
      "city": "Waverly",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waverly, Pennsylvania"
    },
    {
      "city": "Scranton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Scranton, Pennsylvania"
    },
    {
      "city": "Moosic",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Moosic, Pennsylvania"
    },
    {
      "city": "Taylor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Taylor, Pennsylvania"
    },
    {
      "city": "Old Forge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Old Forge, Pennsylvania"
    },
    {
      "city": "Ransom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ransom, Pennsylvania"
    },
    {
      "city": "Elizabethtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elizabethtown, Pennsylvania"
    },
    {
      "city": "Akron",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Akron, Pennsylvania"
    },
    {
      "city": "Bainbridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bainbridge, Pennsylvania"
    },
    {
      "city": "Bart",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bart, Pennsylvania"
    },
    {
      "city": "Bausman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bausman, Pennsylvania"
    },
    {
      "city": "Bird In Hand",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bird In Hand, Pennsylvania"
    },
    {
      "city": "Blue Ball",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blue Ball, Pennsylvania"
    },
    {
      "city": "Bowmansville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bowmansville, Pennsylvania"
    },
    {
      "city": "Brownstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brownstown, Pennsylvania"
    },
    {
      "city": "Christiana",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Christiana, Pennsylvania"
    },
    {
      "city": "Columbia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Columbia, Pennsylvania"
    },
    {
      "city": "Conestoga",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conestoga, Pennsylvania"
    },
    {
      "city": "Denver",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Denver, Pennsylvania"
    },
    {
      "city": "Drumore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Drumore, Pennsylvania"
    },
    {
      "city": "East Earl",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Earl, Pennsylvania"
    },
    {
      "city": "East Petersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Petersburg, Pennsylvania"
    },
    {
      "city": "Elm",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elm, Pennsylvania"
    },
    {
      "city": "Ephrata",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ephrata, Pennsylvania"
    },
    {
      "city": "Gap",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gap, Pennsylvania"
    },
    {
      "city": "Goodville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Goodville, Pennsylvania"
    },
    {
      "city": "Gordonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gordonville, Pennsylvania"
    },
    {
      "city": "Holtwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Holtwood, Pennsylvania"
    },
    {
      "city": "Hopeland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hopeland, Pennsylvania"
    },
    {
      "city": "Intercourse",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Intercourse, Pennsylvania"
    },
    {
      "city": "Kinzers",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kinzers, Pennsylvania"
    },
    {
      "city": "Kirkwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kirkwood, Pennsylvania"
    },
    {
      "city": "Lampeter",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lampeter, Pennsylvania"
    },
    {
      "city": "Landisville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Landisville, Pennsylvania"
    },
    {
      "city": "Leola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leola, Pennsylvania"
    },
    {
      "city": "Lititz",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lititz, Pennsylvania"
    },
    {
      "city": "Manheim",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Manheim, Pennsylvania"
    },
    {
      "city": "Marietta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marietta, Pennsylvania"
    },
    {
      "city": "Martindale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Martindale, Pennsylvania"
    },
    {
      "city": "Maytown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Maytown, Pennsylvania"
    },
    {
      "city": "Millersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millersville, Pennsylvania"
    },
    {
      "city": "Mount Joy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Joy, Pennsylvania"
    },
    {
      "city": "Mountville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mountville, Pennsylvania"
    },
    {
      "city": "Narvon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Narvon, Pennsylvania"
    },
    {
      "city": "New Holland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Holland, Pennsylvania"
    },
    {
      "city": "New Providence",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Providence, Pennsylvania"
    },
    {
      "city": "Paradise",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Paradise, Pennsylvania"
    },
    {
      "city": "Peach Bottom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Peach Bottom, Pennsylvania"
    },
    {
      "city": "Penryn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penryn, Pennsylvania"
    },
    {
      "city": "Pequea",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pequea, Pennsylvania"
    },
    {
      "city": "Quarryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quarryville, Pennsylvania"
    },
    {
      "city": "Reamstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reamstown, Pennsylvania"
    },
    {
      "city": "Refton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Refton, Pennsylvania"
    },
    {
      "city": "Reinholds",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reinholds, Pennsylvania"
    },
    {
      "city": "Rheems",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rheems, Pennsylvania"
    },
    {
      "city": "Ronks",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ronks, Pennsylvania"
    },
    {
      "city": "Silver Spring",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Silver Spring, Pennsylvania"
    },
    {
      "city": "Smoketown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smoketown, Pennsylvania"
    },
    {
      "city": "Stevens",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stevens, Pennsylvania"
    },
    {
      "city": "Strasburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Strasburg, Pennsylvania"
    },
    {
      "city": "Talmage",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Talmage, Pennsylvania"
    },
    {
      "city": "Terre Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Terre Hill, Pennsylvania"
    },
    {
      "city": "Washington Boro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Washington Boro, Pennsylvania"
    },
    {
      "city": "West Willow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Willow, Pennsylvania"
    },
    {
      "city": "Willow Street",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Willow Street, Pennsylvania"
    },
    {
      "city": "Witmer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Witmer, Pennsylvania"
    },
    {
      "city": "Lancaster",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lancaster, Pennsylvania"
    },
    {
      "city": "Adamstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Adamstown, Pennsylvania"
    },
    {
      "city": "New Castle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Castle, Pennsylvania"
    },
    {
      "city": "Bessemer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bessemer, Pennsylvania"
    },
    {
      "city": "Edinburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Edinburg, Pennsylvania"
    },
    {
      "city": "Ellwood City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ellwood City, Pennsylvania"
    },
    {
      "city": "Enon Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Enon Valley, Pennsylvania"
    },
    {
      "city": "Hillsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hillsville, Pennsylvania"
    },
    {
      "city": "New Bedford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Bedford, Pennsylvania"
    },
    {
      "city": "New Wilmington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Wilmington, Pennsylvania"
    },
    {
      "city": "Pulaski",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pulaski, Pennsylvania"
    },
    {
      "city": "Villa Maria",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Villa Maria, Pennsylvania"
    },
    {
      "city": "Volant",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Volant, Pennsylvania"
    },
    {
      "city": "Wampum",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wampum, Pennsylvania"
    },
    {
      "city": "West Pittsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Pittsburg, Pennsylvania"
    },
    {
      "city": "Annville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Annville, Pennsylvania"
    },
    {
      "city": "Campbelltown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Campbelltown, Pennsylvania"
    },
    {
      "city": "Cornwall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cornwall, Pennsylvania"
    },
    {
      "city": "Fredericksburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fredericksburg, Pennsylvania"
    },
    {
      "city": "Jonestown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jonestown, Pennsylvania"
    },
    {
      "city": "Kleinfeltersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kleinfeltersville, Pennsylvania"
    },
    {
      "city": "Lawn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lawn, Pennsylvania"
    },
    {
      "city": "Lebanon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lebanon, Pennsylvania"
    },
    {
      "city": "Mount Gretna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Gretna, Pennsylvania"
    },
    {
      "city": "Myerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Myerstown, Pennsylvania"
    },
    {
      "city": "Newmanstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newmanstown, Pennsylvania"
    },
    {
      "city": "Ono",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ono, Pennsylvania"
    },
    {
      "city": "Palmyra",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Palmyra, Pennsylvania"
    },
    {
      "city": "Quentin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quentin, Pennsylvania"
    },
    {
      "city": "Rexmont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rexmont, Pennsylvania"
    },
    {
      "city": "Richland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Richland, Pennsylvania"
    },
    {
      "city": "Schaefferstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schaefferstown, Pennsylvania"
    },
    {
      "city": "Alburtis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alburtis, Pennsylvania"
    },
    {
      "city": "Bethlehem",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bethlehem, Pennsylvania"
    },
    {
      "city": "Breinigsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Breinigsville, Pennsylvania"
    },
    {
      "city": "Catasauqua",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Catasauqua, Pennsylvania"
    },
    {
      "city": "Center Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Center Valley, Pennsylvania"
    },
    {
      "city": "Coopersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coopersburg, Pennsylvania"
    },
    {
      "city": "Coplay",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coplay, Pennsylvania"
    },
    {
      "city": "East Texas",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Texas, Pennsylvania"
    },
    {
      "city": "Emmaus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Emmaus, Pennsylvania"
    },
    {
      "city": "Fogelsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fogelsville, Pennsylvania"
    },
    {
      "city": "Whitehall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Whitehall, Pennsylvania"
    },
    {
      "city": "Germansville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Germansville, Pennsylvania"
    },
    {
      "city": "Laurys Station",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Laurys Station, Pennsylvania"
    },
    {
      "city": "Limeport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Limeport, Pennsylvania"
    },
    {
      "city": "Macungie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Macungie, Pennsylvania"
    },
    {
      "city": "Neffs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Neffs, Pennsylvania"
    },
    {
      "city": "New Tripoli",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Tripoli, Pennsylvania"
    },
    {
      "city": "Old Zionsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Old Zionsville, Pennsylvania"
    },
    {
      "city": "Orefield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orefield, Pennsylvania"
    },
    {
      "city": "Schnecksville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schnecksville, Pennsylvania"
    },
    {
      "city": "Slatedale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slatedale, Pennsylvania"
    },
    {
      "city": "Slatington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slatington, Pennsylvania"
    },
    {
      "city": "Trexlertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Trexlertown, Pennsylvania"
    },
    {
      "city": "Zionsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zionsville, Pennsylvania"
    },
    {
      "city": "Allentown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allentown, Pennsylvania"
    },
    {
      "city": "Hazleton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hazleton, Pennsylvania"
    },
    {
      "city": "Conyngham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conyngham, Pennsylvania"
    },
    {
      "city": "Drifton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Drifton, Pennsylvania"
    },
    {
      "city": "Drums",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Drums, Pennsylvania"
    },
    {
      "city": "Ebervale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ebervale, Pennsylvania"
    },
    {
      "city": "Freeland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Freeland, Pennsylvania"
    },
    {
      "city": "Harleigh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harleigh, Pennsylvania"
    },
    {
      "city": "Lattimer Mines",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lattimer Mines, Pennsylvania"
    },
    {
      "city": "Milnesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milnesville, Pennsylvania"
    },
    {
      "city": "Rock Glen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rock Glen, Pennsylvania"
    },
    {
      "city": "Saint Johns",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Johns, Pennsylvania"
    },
    {
      "city": "Sugarloaf",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sugarloaf, Pennsylvania"
    },
    {
      "city": "Sybertsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sybertsville, Pennsylvania"
    },
    {
      "city": "Weston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Weston, Pennsylvania"
    },
    {
      "city": "Beach Haven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beach Haven, Pennsylvania"
    },
    {
      "city": "Bear Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bear Creek, Pennsylvania"
    },
    {
      "city": "Cambra",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cambra, Pennsylvania"
    },
    {
      "city": "Dallas",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dallas, Pennsylvania"
    },
    {
      "city": "Glen Lyon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Lyon, Pennsylvania"
    },
    {
      "city": "Harveys Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harveys Lake, Pennsylvania"
    },
    {
      "city": "Hunlock Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hunlock Creek, Pennsylvania"
    },
    {
      "city": "Huntington Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Huntington Mills, Pennsylvania"
    },
    {
      "city": "Lehman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lehman, Pennsylvania"
    },
    {
      "city": "Nanticoke",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nanticoke, Pennsylvania"
    },
    {
      "city": "Nescopeck",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nescopeck, Pennsylvania"
    },
    {
      "city": "Pittston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pittston, Pennsylvania"
    },
    {
      "city": "Duryea",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Duryea, Pennsylvania"
    },
    {
      "city": "Wyoming",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wyoming, Pennsylvania"
    },
    {
      "city": "Plymouth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Plymouth, Pennsylvania"
    },
    {
      "city": "Shawanese",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shawanese, Pennsylvania"
    },
    {
      "city": "Shickshinny",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shickshinny, Pennsylvania"
    },
    {
      "city": "Sweet Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sweet Valley, Pennsylvania"
    },
    {
      "city": "Wapwallopen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wapwallopen, Pennsylvania"
    },
    {
      "city": "White Haven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "White Haven, Pennsylvania"
    },
    {
      "city": "Wilkes Barre",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wilkes Barre, Pennsylvania"
    },
    {
      "city": "Kingston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kingston, Pennsylvania"
    },
    {
      "city": "Mountain Top",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mountain Top, Pennsylvania"
    },
    {
      "city": "Shavertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shavertown, Pennsylvania"
    },
    {
      "city": "Luzerne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Luzerne, Pennsylvania"
    },
    {
      "city": "Williamsport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Williamsport, Pennsylvania"
    },
    {
      "city": "Antes Fort",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Antes Fort, Pennsylvania"
    },
    {
      "city": "Jersey Shore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jersey Shore, Pennsylvania"
    },
    {
      "city": "Cedar Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cedar Run, Pennsylvania"
    },
    {
      "city": "Cogan Station",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cogan Station, Pennsylvania"
    },
    {
      "city": "Hughesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hughesville, Pennsylvania"
    },
    {
      "city": "Jersey Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jersey Mills, Pennsylvania"
    },
    {
      "city": "Lairdsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lairdsville, Pennsylvania"
    },
    {
      "city": "Linden",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Linden, Pennsylvania"
    },
    {
      "city": "Montgomery",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Montgomery, Pennsylvania"
    },
    {
      "city": "Montoursville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Montoursville, Pennsylvania"
    },
    {
      "city": "Muncy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Muncy, Pennsylvania"
    },
    {
      "city": "Picture Rocks",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Picture Rocks, Pennsylvania"
    },
    {
      "city": "Ralston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ralston, Pennsylvania"
    },
    {
      "city": "Slate Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slate Run, Pennsylvania"
    },
    {
      "city": "Trout Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Trout Run, Pennsylvania"
    },
    {
      "city": "Unityville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Unityville, Pennsylvania"
    },
    {
      "city": "Waterville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waterville, Pennsylvania"
    },
    {
      "city": "Ludlow",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ludlow, Pennsylvania"
    },
    {
      "city": "Bradford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bradford, Pennsylvania"
    },
    {
      "city": "Crosby",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crosby, Pennsylvania"
    },
    {
      "city": "Custer City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Custer City, Pennsylvania"
    },
    {
      "city": "Cyclone",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cyclone, Pennsylvania"
    },
    {
      "city": "Derrick City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Derrick City, Pennsylvania"
    },
    {
      "city": "Duke Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Duke Center, Pennsylvania"
    },
    {
      "city": "East Smethport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Smethport, Pennsylvania"
    },
    {
      "city": "Eldred",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Eldred, Pennsylvania"
    },
    {
      "city": "Gifford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gifford, Pennsylvania"
    },
    {
      "city": "Hazel Hurst",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hazel Hurst, Pennsylvania"
    },
    {
      "city": "Kane",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kane, Pennsylvania"
    },
    {
      "city": "Lewis Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lewis Run, Pennsylvania"
    },
    {
      "city": "Mount Jewett",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Jewett, Pennsylvania"
    },
    {
      "city": "Port Allegany",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Allegany, Pennsylvania"
    },
    {
      "city": "Rew",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rew, Pennsylvania"
    },
    {
      "city": "Rixford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rixford, Pennsylvania"
    },
    {
      "city": "Smethport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smethport, Pennsylvania"
    },
    {
      "city": "Turtlepoint",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Turtlepoint, Pennsylvania"
    },
    {
      "city": "Clark",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clark, Pennsylvania"
    },
    {
      "city": "Clarks Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarks Mills, Pennsylvania"
    },
    {
      "city": "Farrell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Farrell, Pennsylvania"
    },
    {
      "city": "Fredonia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fredonia, Pennsylvania"
    },
    {
      "city": "Greenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greenville, Pennsylvania"
    },
    {
      "city": "Grove City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grove City, Pennsylvania"
    },
    {
      "city": "Hadley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hadley, Pennsylvania"
    },
    {
      "city": "Jackson Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jackson Center, Pennsylvania"
    },
    {
      "city": "Jamestown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jamestown, Pennsylvania"
    },
    {
      "city": "Mercer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mercer, Pennsylvania"
    },
    {
      "city": "Sandy Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sandy Lake, Pennsylvania"
    },
    {
      "city": "Sharon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sharon, Pennsylvania"
    },
    {
      "city": "Hermitage",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hermitage, Pennsylvania"
    },
    {
      "city": "Sharpsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sharpsville, Pennsylvania"
    },
    {
      "city": "Sheakleyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sheakleyville, Pennsylvania"
    },
    {
      "city": "Stoneboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stoneboro, Pennsylvania"
    },
    {
      "city": "Transfer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Transfer, Pennsylvania"
    },
    {
      "city": "West Middlesex",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Middlesex, Pennsylvania"
    },
    {
      "city": "Wheatland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wheatland, Pennsylvania"
    },
    {
      "city": "Carlton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Carlton, Pennsylvania"
    },
    {
      "city": "Allensville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allensville, Pennsylvania"
    },
    {
      "city": "Belleville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Belleville, Pennsylvania"
    },
    {
      "city": "Burnham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Burnham, Pennsylvania"
    },
    {
      "city": "Granville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Granville, Pennsylvania"
    },
    {
      "city": "Lewistown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lewistown, Pennsylvania"
    },
    {
      "city": "Mc Veytown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Veytown, Pennsylvania"
    },
    {
      "city": "Mattawana",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mattawana, Pennsylvania"
    },
    {
      "city": "Milroy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milroy, Pennsylvania"
    },
    {
      "city": "Newton Hamilton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newton Hamilton, Pennsylvania"
    },
    {
      "city": "Reedsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reedsville, Pennsylvania"
    },
    {
      "city": "Yeagertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Yeagertown, Pennsylvania"
    },
    {
      "city": "Kunkletown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kunkletown, Pennsylvania"
    },
    {
      "city": "East Stroudsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Stroudsburg, Pennsylvania"
    },
    {
      "city": "Analomink",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Analomink, Pennsylvania"
    },
    {
      "city": "Bartonsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bartonsville, Pennsylvania"
    },
    {
      "city": "Brodheadsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brodheadsville, Pennsylvania"
    },
    {
      "city": "Buck Hill Falls",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Buck Hill Falls, Pennsylvania"
    },
    {
      "city": "Canadensis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Canadensis, Pennsylvania"
    },
    {
      "city": "Cresco",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cresco, Pennsylvania"
    },
    {
      "city": "Delaware Water Gap",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Delaware Water Gap, Pennsylvania"
    },
    {
      "city": "Effort",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Effort, Pennsylvania"
    },
    {
      "city": "Gilbert",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gilbert, Pennsylvania"
    },
    {
      "city": "Henryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Henryville, Pennsylvania"
    },
    {
      "city": "Kresgeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kresgeville, Pennsylvania"
    },
    {
      "city": "Long Pond",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Long Pond, Pennsylvania"
    },
    {
      "city": "Marshalls Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marshalls Creek, Pennsylvania"
    },
    {
      "city": "Minisink Hills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Minisink Hills, Pennsylvania"
    },
    {
      "city": "Mountainhome",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mountainhome, Pennsylvania"
    },
    {
      "city": "Mount Pocono",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Pocono, Pennsylvania"
    },
    {
      "city": "Pocono Summit",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocono Summit, Pennsylvania"
    },
    {
      "city": "Pocono Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocono Lake, Pennsylvania"
    },
    {
      "city": "Pocono Lake Preserve",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocono Lake Preserve, Pennsylvania"
    },
    {
      "city": "Pocono Manor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocono Manor, Pennsylvania"
    },
    {
      "city": "Pocono Pines",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pocono Pines, Pennsylvania"
    },
    {
      "city": "Reeders",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reeders, Pennsylvania"
    },
    {
      "city": "Saylorsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saylorsburg, Pennsylvania"
    },
    {
      "city": "Sciota",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sciota, Pennsylvania"
    },
    {
      "city": "Scotrun",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Scotrun, Pennsylvania"
    },
    {
      "city": "Shawnee On Delaware",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shawnee On Delaware, Pennsylvania"
    },
    {
      "city": "Skytop",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Skytop, Pennsylvania"
    },
    {
      "city": "Stroudsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stroudsburg, Pennsylvania"
    },
    {
      "city": "Swiftwater",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Swiftwater, Pennsylvania"
    },
    {
      "city": "Tannersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tannersville, Pennsylvania"
    },
    {
      "city": "Tobyhanna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tobyhanna, Pennsylvania"
    },
    {
      "city": "Blakeslee",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blakeslee, Pennsylvania"
    },
    {
      "city": "East Greenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Greenville, Pennsylvania"
    },
    {
      "city": "Green Lane",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Green Lane, Pennsylvania"
    },
    {
      "city": "Palm",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Palm, Pennsylvania"
    },
    {
      "city": "Pennsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pennsburg, Pennsylvania"
    },
    {
      "city": "Perkiomenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Perkiomenville, Pennsylvania"
    },
    {
      "city": "Red Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Red Hill, Pennsylvania"
    },
    {
      "city": "Sumneytown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sumneytown, Pennsylvania"
    },
    {
      "city": "Colmar",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Colmar, Pennsylvania"
    },
    {
      "city": "Earlington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Earlington, Pennsylvania"
    },
    {
      "city": "Franconia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Franconia, Pennsylvania"
    },
    {
      "city": "Montgomeryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Montgomeryville, Pennsylvania"
    },
    {
      "city": "Salford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Salford, Pennsylvania"
    },
    {
      "city": "Salfordville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Salfordville, Pennsylvania"
    },
    {
      "city": "Souderton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Souderton, Pennsylvania"
    },
    {
      "city": "Telford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Telford, Pennsylvania"
    },
    {
      "city": "Tylersport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tylersport, Pennsylvania"
    },
    {
      "city": "Woxall",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Woxall, Pennsylvania"
    },
    {
      "city": "Abington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Abington, Pennsylvania"
    },
    {
      "city": "Ambler",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ambler, Pennsylvania"
    },
    {
      "city": "Bala Cynwyd",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bala Cynwyd, Pennsylvania"
    },
    {
      "city": "Huntingdon Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Huntingdon Valley, Pennsylvania"
    },
    {
      "city": "Bryn Athyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bryn Athyn, Pennsylvania"
    },
    {
      "city": "Cheltenham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cheltenham, Pennsylvania"
    },
    {
      "city": "Dresher",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dresher, Pennsylvania"
    },
    {
      "city": "Elkins Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elkins Park, Pennsylvania"
    },
    {
      "city": "Flourtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Flourtown, Pennsylvania"
    },
    {
      "city": "Fort Washington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fort Washington, Pennsylvania"
    },
    {
      "city": "Gladwyne",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gladwyne, Pennsylvania"
    },
    {
      "city": "Glenside",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glenside, Pennsylvania"
    },
    {
      "city": "Hatboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hatboro, Pennsylvania"
    },
    {
      "city": "Horsham",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Horsham, Pennsylvania"
    },
    {
      "city": "Jenkintown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jenkintown, Pennsylvania"
    },
    {
      "city": "Merion Station",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Merion Station, Pennsylvania"
    },
    {
      "city": "Narberth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Narberth, Pennsylvania"
    },
    {
      "city": "Oreland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oreland, Pennsylvania"
    },
    {
      "city": "Willow Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Willow Grove, Pennsylvania"
    },
    {
      "city": "Wyncote",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wyncote, Pennsylvania"
    },
    {
      "city": "Wynnewood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wynnewood, Pennsylvania"
    },
    {
      "city": "Norristown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Norristown, Pennsylvania"
    },
    {
      "city": "Bridgeport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bridgeport, Pennsylvania"
    },
    {
      "city": "King Of Prussia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "King Of Prussia, Pennsylvania"
    },
    {
      "city": "Audubon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Audubon, Pennsylvania"
    },
    {
      "city": "Eagleville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Eagleville, Pennsylvania"
    },
    {
      "city": "Fairview Village",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairview Village, Pennsylvania"
    },
    {
      "city": "Blue Bell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blue Bell, Pennsylvania"
    },
    {
      "city": "Cedars",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cedars, Pennsylvania"
    },
    {
      "city": "Collegeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Collegeville, Pennsylvania"
    },
    {
      "city": "Conshohocken",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Conshohocken, Pennsylvania"
    },
    {
      "city": "Creamery",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Creamery, Pennsylvania"
    },
    {
      "city": "Frederick",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Frederick, Pennsylvania"
    },
    {
      "city": "Gwynedd",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gwynedd, Pennsylvania"
    },
    {
      "city": "Gwynedd Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gwynedd Valley, Pennsylvania"
    },
    {
      "city": "Harleysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harleysville, Pennsylvania"
    },
    {
      "city": "Hatfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hatfield, Pennsylvania"
    },
    {
      "city": "Kulpsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kulpsville, Pennsylvania"
    },
    {
      "city": "Lafayette Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lafayette Hill, Pennsylvania"
    },
    {
      "city": "Lansdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lansdale, Pennsylvania"
    },
    {
      "city": "Lederach",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lederach, Pennsylvania"
    },
    {
      "city": "Mainland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mainland, Pennsylvania"
    },
    {
      "city": "Mont Clare",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mont Clare, Pennsylvania"
    },
    {
      "city": "North Wales",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "North Wales, Pennsylvania"
    },
    {
      "city": "Oaks",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oaks, Pennsylvania"
    },
    {
      "city": "Plymouth Meeting",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Plymouth Meeting, Pennsylvania"
    },
    {
      "city": "Royersford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Royersford, Pennsylvania"
    },
    {
      "city": "Sassamansville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sassamansville, Pennsylvania"
    },
    {
      "city": "Schwenksville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schwenksville, Pennsylvania"
    },
    {
      "city": "Skippack",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Skippack, Pennsylvania"
    },
    {
      "city": "Spring House",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring House, Pennsylvania"
    },
    {
      "city": "Spring Mount",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Mount, Pennsylvania"
    },
    {
      "city": "West Point",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Point, Pennsylvania"
    },
    {
      "city": "Worcester",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Worcester, Pennsylvania"
    },
    {
      "city": "Zieglerville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zieglerville, Pennsylvania"
    },
    {
      "city": "Gilbertsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gilbertsville, Pennsylvania"
    },
    {
      "city": "Danville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Danville, Pennsylvania"
    },
    {
      "city": "Washingtonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Washingtonville, Pennsylvania"
    },
    {
      "city": "Lehigh Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lehigh Valley, Pennsylvania"
    },
    {
      "city": "Bangor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bangor, Pennsylvania"
    },
    {
      "city": "Bath",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bath, Pennsylvania"
    },
    {
      "city": "Cherryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cherryville, Pennsylvania"
    },
    {
      "city": "Danielsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Danielsville, Pennsylvania"
    },
    {
      "city": "Easton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Easton, Pennsylvania"
    },
    {
      "city": "Hellertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hellertown, Pennsylvania"
    },
    {
      "city": "Martins Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Martins Creek, Pennsylvania"
    },
    {
      "city": "Nazareth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nazareth, Pennsylvania"
    },
    {
      "city": "Northampton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Northampton, Pennsylvania"
    },
    {
      "city": "Pen Argyl",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pen Argyl, Pennsylvania"
    },
    {
      "city": "Stockertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stockertown, Pennsylvania"
    },
    {
      "city": "Tatamy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tatamy, Pennsylvania"
    },
    {
      "city": "Treichlers",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Treichlers, Pennsylvania"
    },
    {
      "city": "Walnutport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Walnutport, Pennsylvania"
    },
    {
      "city": "Wind Gap",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wind Gap, Pennsylvania"
    },
    {
      "city": "Mount Bethel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Bethel, Pennsylvania"
    },
    {
      "city": "Portland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Portland, Pennsylvania"
    },
    {
      "city": "Dalmatia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dalmatia, Pennsylvania"
    },
    {
      "city": "Dewart",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dewart, Pennsylvania"
    },
    {
      "city": "Mc Ewensville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Ewensville, Pennsylvania"
    },
    {
      "city": "Turbotville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Turbotville, Pennsylvania"
    },
    {
      "city": "Watsontown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Watsontown, Pennsylvania"
    },
    {
      "city": "Sunbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sunbury, Pennsylvania"
    },
    {
      "city": "Dornsife",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dornsife, Pennsylvania"
    },
    {
      "city": "Elysburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elysburg, Pennsylvania"
    },
    {
      "city": "Herndon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Herndon, Pennsylvania"
    },
    {
      "city": "Marion Heights",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marion Heights, Pennsylvania"
    },
    {
      "city": "Kulpmont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kulpmont, Pennsylvania"
    },
    {
      "city": "Leck Kill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Leck Kill, Pennsylvania"
    },
    {
      "city": "Locust Gap",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Locust Gap, Pennsylvania"
    },
    {
      "city": "Milton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milton, Pennsylvania"
    },
    {
      "city": "Montandon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Montandon, Pennsylvania"
    },
    {
      "city": "Mount Carmel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Carmel, Pennsylvania"
    },
    {
      "city": "Northumberland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Northumberland, Pennsylvania"
    },
    {
      "city": "Paxinos",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Paxinos, Pennsylvania"
    },
    {
      "city": "Potts Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Potts Grove, Pennsylvania"
    },
    {
      "city": "Coal Township",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coal Township, Pennsylvania"
    },
    {
      "city": "Rebuck",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rebuck, Pennsylvania"
    },
    {
      "city": "Riverside",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Riverside, Pennsylvania"
    },
    {
      "city": "Shamokin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shamokin, Pennsylvania"
    },
    {
      "city": "Snydertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Snydertown, Pennsylvania"
    },
    {
      "city": "Trevorton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Trevorton, Pennsylvania"
    },
    {
      "city": "Blain",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blain, Pennsylvania"
    },
    {
      "city": "Duncannon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Duncannon, Pennsylvania"
    },
    {
      "city": "Elliottsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elliottsburg, Pennsylvania"
    },
    {
      "city": "Ickesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ickesburg, Pennsylvania"
    },
    {
      "city": "Landisburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Landisburg, Pennsylvania"
    },
    {
      "city": "Liverpool",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Liverpool, Pennsylvania"
    },
    {
      "city": "Loysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loysville, Pennsylvania"
    },
    {
      "city": "Marysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marysville, Pennsylvania"
    },
    {
      "city": "Millerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millerstown, Pennsylvania"
    },
    {
      "city": "New Bloomfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Bloomfield, Pennsylvania"
    },
    {
      "city": "New Buffalo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Buffalo, Pennsylvania"
    },
    {
      "city": "New Germantown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Germantown, Pennsylvania"
    },
    {
      "city": "Newport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newport, Pennsylvania"
    },
    {
      "city": "Shermans Dale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shermans Dale, Pennsylvania"
    },
    {
      "city": "Bushkill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bushkill, Pennsylvania"
    },
    {
      "city": "Dingmans Ferry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dingmans Ferry, Pennsylvania"
    },
    {
      "city": "Matamoras",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Matamoras, Pennsylvania"
    },
    {
      "city": "Milford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milford, Pennsylvania"
    },
    {
      "city": "Millrift",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millrift, Pennsylvania"
    },
    {
      "city": "Tamiment",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tamiment, Pennsylvania"
    },
    {
      "city": "Greeley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greeley, Pennsylvania"
    },
    {
      "city": "Greentown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greentown, Pennsylvania"
    },
    {
      "city": "Lackawaxen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lackawaxen, Pennsylvania"
    },
    {
      "city": "Paupack",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Paupack, Pennsylvania"
    },
    {
      "city": "Rowland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rowland, Pennsylvania"
    },
    {
      "city": "Shohola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shohola, Pennsylvania"
    },
    {
      "city": "Tafton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tafton, Pennsylvania"
    },
    {
      "city": "Austin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Austin, Pennsylvania"
    },
    {
      "city": "Roulette",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Roulette, Pennsylvania"
    },
    {
      "city": "Shinglehouse",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shinglehouse, Pennsylvania"
    },
    {
      "city": "Coudersport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coudersport, Pennsylvania"
    },
    {
      "city": "Galeton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Galeton, Pennsylvania"
    },
    {
      "city": "Genesee",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Genesee, Pennsylvania"
    },
    {
      "city": "Harrison Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harrison Valley, Pennsylvania"
    },
    {
      "city": "Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mills, Pennsylvania"
    },
    {
      "city": "Ulysses",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ulysses, Pennsylvania"
    },
    {
      "city": "Cross Fork",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cross Fork, Pennsylvania"
    },
    {
      "city": "Pottsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pottsville, Pennsylvania"
    },
    {
      "city": "Ashland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ashland, Pennsylvania"
    },
    {
      "city": "Auburn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Auburn, Pennsylvania"
    },
    {
      "city": "Branchdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Branchdale, Pennsylvania"
    },
    {
      "city": "Brockton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brockton, Pennsylvania"
    },
    {
      "city": "Cressona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cressona, Pennsylvania"
    },
    {
      "city": "Cumbola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cumbola, Pennsylvania"
    },
    {
      "city": "Frackville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Frackville, Pennsylvania"
    },
    {
      "city": "Friedensburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Friedensburg, Pennsylvania"
    },
    {
      "city": "Gilberton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gilberton, Pennsylvania"
    },
    {
      "city": "Girardville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Girardville, Pennsylvania"
    },
    {
      "city": "Gordon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gordon, Pennsylvania"
    },
    {
      "city": "Hegins",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hegins, Pennsylvania"
    },
    {
      "city": "Klingerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Klingerstown, Pennsylvania"
    },
    {
      "city": "Lavelle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lavelle, Pennsylvania"
    },
    {
      "city": "Llewellyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Llewellyn, Pennsylvania"
    },
    {
      "city": "Locustdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Locustdale, Pennsylvania"
    },
    {
      "city": "Lost Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lost Creek, Pennsylvania"
    },
    {
      "city": "Mahanoy City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mahanoy City, Pennsylvania"
    },
    {
      "city": "Mahanoy Plane",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mahanoy Plane, Pennsylvania"
    },
    {
      "city": "Mar Lin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mar Lin, Pennsylvania"
    },
    {
      "city": "Mary D",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mary D, Pennsylvania"
    },
    {
      "city": "Middleport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Middleport, Pennsylvania"
    },
    {
      "city": "Minersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Minersville, Pennsylvania"
    },
    {
      "city": "Muir",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Muir, Pennsylvania"
    },
    {
      "city": "New Philadelphia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Philadelphia, Pennsylvania"
    },
    {
      "city": "New Ringgold",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Ringgold, Pennsylvania"
    },
    {
      "city": "Orwigsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orwigsburg, Pennsylvania"
    },
    {
      "city": "Pine Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pine Grove, Pennsylvania"
    },
    {
      "city": "Pitman",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pitman, Pennsylvania"
    },
    {
      "city": "Port Carbon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Carbon, Pennsylvania"
    },
    {
      "city": "Ravine",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ravine, Pennsylvania"
    },
    {
      "city": "Ringtown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ringtown, Pennsylvania"
    },
    {
      "city": "Sacramento",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sacramento, Pennsylvania"
    },
    {
      "city": "Saint Clair",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Saint Clair, Pennsylvania"
    },
    {
      "city": "Schuylkill Haven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Schuylkill Haven, Pennsylvania"
    },
    {
      "city": "Seltzer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seltzer, Pennsylvania"
    },
    {
      "city": "Shenandoah",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shenandoah, Pennsylvania"
    },
    {
      "city": "Spring Glen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Glen, Pennsylvania"
    },
    {
      "city": "Summit Station",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Summit Station, Pennsylvania"
    },
    {
      "city": "Tower City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tower City, Pennsylvania"
    },
    {
      "city": "Tremont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tremont, Pennsylvania"
    },
    {
      "city": "Tuscarora",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tuscarora, Pennsylvania"
    },
    {
      "city": "Valley View",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Valley View, Pennsylvania"
    },
    {
      "city": "Zion Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Zion Grove, Pennsylvania"
    },
    {
      "city": "Andreas",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Andreas, Pennsylvania"
    },
    {
      "city": "Barnesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Barnesville, Pennsylvania"
    },
    {
      "city": "Coaldale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coaldale, Pennsylvania"
    },
    {
      "city": "Delano",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Delano, Pennsylvania"
    },
    {
      "city": "Kelayres",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kelayres, Pennsylvania"
    },
    {
      "city": "Mcadoo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mcadoo, Pennsylvania"
    },
    {
      "city": "Nuremberg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nuremberg, Pennsylvania"
    },
    {
      "city": "Oneida",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oneida, Pennsylvania"
    },
    {
      "city": "Quakake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quakake, Pennsylvania"
    },
    {
      "city": "Sheppton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sheppton, Pennsylvania"
    },
    {
      "city": "Tamaqua",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tamaqua, Pennsylvania"
    },
    {
      "city": "Port Clinton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Clinton, Pennsylvania"
    },
    {
      "city": "Beaver Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beaver Springs, Pennsylvania"
    },
    {
      "city": "Beavertown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beavertown, Pennsylvania"
    },
    {
      "city": "Freeburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Freeburg, Pennsylvania"
    },
    {
      "city": "Hummels Wharf",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hummels Wharf, Pennsylvania"
    },
    {
      "city": "Kreamer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kreamer, Pennsylvania"
    },
    {
      "city": "Mc Clure",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Clure, Pennsylvania"
    },
    {
      "city": "Middleburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Middleburg, Pennsylvania"
    },
    {
      "city": "Mount Pleasant Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Pleasant Mills, Pennsylvania"
    },
    {
      "city": "Paxtonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Paxtonville, Pennsylvania"
    },
    {
      "city": "Penns Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penns Creek, Pennsylvania"
    },
    {
      "city": "Port Trevorton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Port Trevorton, Pennsylvania"
    },
    {
      "city": "Selinsgrove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Selinsgrove, Pennsylvania"
    },
    {
      "city": "Shamokin Dam",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shamokin Dam, Pennsylvania"
    },
    {
      "city": "Troxelville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Troxelville, Pennsylvania"
    },
    {
      "city": "Addison",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Addison, Pennsylvania"
    },
    {
      "city": "Confluence",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Confluence, Pennsylvania"
    },
    {
      "city": "Ursina",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ursina, Pennsylvania"
    },
    {
      "city": "Somerset",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Somerset, Pennsylvania"
    },
    {
      "city": "Hidden Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hidden Valley, Pennsylvania"
    },
    {
      "city": "Acosta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Acosta, Pennsylvania"
    },
    {
      "city": "Berlin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Berlin, Pennsylvania"
    },
    {
      "city": "Boswell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boswell, Pennsylvania"
    },
    {
      "city": "Boynton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Boynton, Pennsylvania"
    },
    {
      "city": "Fairhope",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fairhope, Pennsylvania"
    },
    {
      "city": "Fort Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fort Hill, Pennsylvania"
    },
    {
      "city": "Friedens",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Friedens, Pennsylvania"
    },
    {
      "city": "Garrett",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Garrett, Pennsylvania"
    },
    {
      "city": "Gray",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gray, Pennsylvania"
    },
    {
      "city": "Jenners",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jenners, Pennsylvania"
    },
    {
      "city": "Jennerstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jennerstown, Pennsylvania"
    },
    {
      "city": "Kantner",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kantner, Pennsylvania"
    },
    {
      "city": "Listie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Listie, Pennsylvania"
    },
    {
      "city": "Markleton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Markleton, Pennsylvania"
    },
    {
      "city": "Meyersdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Meyersdale, Pennsylvania"
    },
    {
      "city": "New Baltimore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Baltimore, Pennsylvania"
    },
    {
      "city": "Quecreek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Quecreek, Pennsylvania"
    },
    {
      "city": "Rockwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rockwood, Pennsylvania"
    },
    {
      "city": "Salisbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Salisbury, Pennsylvania"
    },
    {
      "city": "Shanksville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shanksville, Pennsylvania"
    },
    {
      "city": "Sipesville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sipesville, Pennsylvania"
    },
    {
      "city": "Springs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springs, Pennsylvania"
    },
    {
      "city": "Stoystown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stoystown, Pennsylvania"
    },
    {
      "city": "Wellersburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wellersburg, Pennsylvania"
    },
    {
      "city": "West Salisbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Salisbury, Pennsylvania"
    },
    {
      "city": "Cairnbrook",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cairnbrook, Pennsylvania"
    },
    {
      "city": "Central City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Central City, Pennsylvania"
    },
    {
      "city": "Davidsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Davidsville, Pennsylvania"
    },
    {
      "city": "Hollsopple",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hollsopple, Pennsylvania"
    },
    {
      "city": "Hooversville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hooversville, Pennsylvania"
    },
    {
      "city": "Jerome",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jerome, Pennsylvania"
    },
    {
      "city": "Seanor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seanor, Pennsylvania"
    },
    {
      "city": "Tire Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tire Hill, Pennsylvania"
    },
    {
      "city": "Windber",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Windber, Pennsylvania"
    },
    {
      "city": "Eagles Mere",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Eagles Mere, Pennsylvania"
    },
    {
      "city": "Muncy Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Muncy Valley, Pennsylvania"
    },
    {
      "city": "Shunk",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shunk, Pennsylvania"
    },
    {
      "city": "Dushore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dushore, Pennsylvania"
    },
    {
      "city": "Forksville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Forksville, Pennsylvania"
    },
    {
      "city": "Hillsgrove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hillsgrove, Pennsylvania"
    },
    {
      "city": "Laporte",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Laporte, Pennsylvania"
    },
    {
      "city": "Lopez",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lopez, Pennsylvania"
    },
    {
      "city": "Mildred",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mildred, Pennsylvania"
    },
    {
      "city": "Clifford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clifford, Pennsylvania"
    },
    {
      "city": "Forest City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Forest City, Pennsylvania"
    },
    {
      "city": "Herrick Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Herrick Center, Pennsylvania"
    },
    {
      "city": "Lenoxville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lenoxville, Pennsylvania"
    },
    {
      "city": "Thompson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Thompson, Pennsylvania"
    },
    {
      "city": "Union Dale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Union Dale, Pennsylvania"
    },
    {
      "city": "Montrose",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Montrose, Pennsylvania"
    },
    {
      "city": "Brackney",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brackney, Pennsylvania"
    },
    {
      "city": "Brooklyn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brooklyn, Pennsylvania"
    },
    {
      "city": "Dimock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dimock, Pennsylvania"
    },
    {
      "city": "Friendsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Friendsville, Pennsylvania"
    },
    {
      "city": "Gibson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gibson, Pennsylvania"
    },
    {
      "city": "Great Bend",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Great Bend, Pennsylvania"
    },
    {
      "city": "Hallstead",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hallstead, Pennsylvania"
    },
    {
      "city": "Harford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harford, Pennsylvania"
    },
    {
      "city": "Hop Bottom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hop Bottom, Pennsylvania"
    },
    {
      "city": "Jackson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jackson, Pennsylvania"
    },
    {
      "city": "Kingsley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kingsley, Pennsylvania"
    },
    {
      "city": "Lanesboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lanesboro, Pennsylvania"
    },
    {
      "city": "Lawton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lawton, Pennsylvania"
    },
    {
      "city": "Little Meadows",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Little Meadows, Pennsylvania"
    },
    {
      "city": "New Milford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Milford, Pennsylvania"
    },
    {
      "city": "South Gibson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Gibson, Pennsylvania"
    },
    {
      "city": "South Montrose",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Montrose, Pennsylvania"
    },
    {
      "city": "Springville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Springville, Pennsylvania"
    },
    {
      "city": "Susquehanna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Susquehanna, Pennsylvania"
    },
    {
      "city": "Wellsboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wellsboro, Pennsylvania"
    },
    {
      "city": "Arnot",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Arnot, Pennsylvania"
    },
    {
      "city": "Blossburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Blossburg, Pennsylvania"
    },
    {
      "city": "Covington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Covington, Pennsylvania"
    },
    {
      "city": "Elkland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elkland, Pennsylvania"
    },
    {
      "city": "Gaines",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gaines, Pennsylvania"
    },
    {
      "city": "Knoxville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Knoxville, Pennsylvania"
    },
    {
      "city": "Lawrenceville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lawrenceville, Pennsylvania"
    },
    {
      "city": "Liberty",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Liberty, Pennsylvania"
    },
    {
      "city": "Mainesburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mainesburg, Pennsylvania"
    },
    {
      "city": "Mansfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mansfield, Pennsylvania"
    },
    {
      "city": "Middlebury Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Middlebury Center, Pennsylvania"
    },
    {
      "city": "Millerton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millerton, Pennsylvania"
    },
    {
      "city": "Morris",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morris, Pennsylvania"
    },
    {
      "city": "Morris Run",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Morris Run, Pennsylvania"
    },
    {
      "city": "Nelson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nelson, Pennsylvania"
    },
    {
      "city": "Osceola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Osceola, Pennsylvania"
    },
    {
      "city": "Sabinsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sabinsville, Pennsylvania"
    },
    {
      "city": "Tioga",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tioga, Pennsylvania"
    },
    {
      "city": "Westfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westfield, Pennsylvania"
    },
    {
      "city": "Roaring Branch",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Roaring Branch, Pennsylvania"
    },
    {
      "city": "Allenwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allenwood, Pennsylvania"
    },
    {
      "city": "Hartleton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hartleton, Pennsylvania"
    },
    {
      "city": "Laurelton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Laurelton, Pennsylvania"
    },
    {
      "city": "Lewisburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lewisburg, Pennsylvania"
    },
    {
      "city": "Mifflinburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mifflinburg, Pennsylvania"
    },
    {
      "city": "Millmont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millmont, Pennsylvania"
    },
    {
      "city": "New Berlin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Berlin, Pennsylvania"
    },
    {
      "city": "New Columbia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Columbia, Pennsylvania"
    },
    {
      "city": "Swengel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Swengel, Pennsylvania"
    },
    {
      "city": "Vicksburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vicksburg, Pennsylvania"
    },
    {
      "city": "Weikert",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Weikert, Pennsylvania"
    },
    {
      "city": "West Milton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Milton, Pennsylvania"
    },
    {
      "city": "White Deer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "White Deer, Pennsylvania"
    },
    {
      "city": "Winfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Winfield, Pennsylvania"
    },
    {
      "city": "Oil City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Oil City, Pennsylvania"
    },
    {
      "city": "Cooperstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cooperstown, Pennsylvania"
    },
    {
      "city": "Cranberry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cranberry, Pennsylvania"
    },
    {
      "city": "Franklin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Franklin, Pennsylvania"
    },
    {
      "city": "Pleasantville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pleasantville, Pennsylvania"
    },
    {
      "city": "Polk",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Polk, Pennsylvania"
    },
    {
      "city": "Reno",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Reno, Pennsylvania"
    },
    {
      "city": "Rouseville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rouseville, Pennsylvania"
    },
    {
      "city": "Seneca",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seneca, Pennsylvania"
    },
    {
      "city": "Utica",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Utica, Pennsylvania"
    },
    {
      "city": "Venus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Venus, Pennsylvania"
    },
    {
      "city": "Clintonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clintonville, Pennsylvania"
    },
    {
      "city": "Emlenton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Emlenton, Pennsylvania"
    },
    {
      "city": "Kennerdell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Kennerdell, Pennsylvania"
    },
    {
      "city": "Chandlers Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Chandlers Valley, Pennsylvania"
    },
    {
      "city": "Clarendon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Clarendon, Pennsylvania"
    },
    {
      "city": "Irvine",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Irvine, Pennsylvania"
    },
    {
      "city": "Pittsfield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pittsfield, Pennsylvania"
    },
    {
      "city": "Russell",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Russell, Pennsylvania"
    },
    {
      "city": "Sheffield",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sheffield, Pennsylvania"
    },
    {
      "city": "Sugar Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sugar Grove, Pennsylvania"
    },
    {
      "city": "Tidioute",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tidioute, Pennsylvania"
    },
    {
      "city": "Tiona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tiona, Pennsylvania"
    },
    {
      "city": "Warren",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Warren, Pennsylvania"
    },
    {
      "city": "Youngsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Youngsville, Pennsylvania"
    },
    {
      "city": "Bear Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bear Lake, Pennsylvania"
    },
    {
      "city": "Columbus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Columbus, Pennsylvania"
    },
    {
      "city": "Garland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Garland, Pennsylvania"
    },
    {
      "city": "Grand Valley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grand Valley, Pennsylvania"
    },
    {
      "city": "Spring Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Creek, Pennsylvania"
    },
    {
      "city": "Atlasburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Atlasburg, Pennsylvania"
    },
    {
      "city": "Bulger",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bulger, Pennsylvania"
    },
    {
      "city": "Burgettstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Burgettstown, Pennsylvania"
    },
    {
      "city": "Charleroi",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Charleroi, Pennsylvania"
    },
    {
      "city": "Donora",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Donora, Pennsylvania"
    },
    {
      "city": "Elrama",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elrama, Pennsylvania"
    },
    {
      "city": "Joffre",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Joffre, Pennsylvania"
    },
    {
      "city": "Langeloth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Langeloth, Pennsylvania"
    },
    {
      "city": "Lawrence",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lawrence, Pennsylvania"
    },
    {
      "city": "Mc Donald",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mc Donald, Pennsylvania"
    },
    {
      "city": "Midway",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Midway, Pennsylvania"
    },
    {
      "city": "Monongahela",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Monongahela, Pennsylvania"
    },
    {
      "city": "New Eagle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Eagle, Pennsylvania"
    },
    {
      "city": "Slovan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slovan, Pennsylvania"
    },
    {
      "city": "Washington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Washington, Pennsylvania"
    },
    {
      "city": "Amity",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Amity, Pennsylvania"
    },
    {
      "city": "Avella",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Avella, Pennsylvania"
    },
    {
      "city": "Beallsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beallsville, Pennsylvania"
    },
    {
      "city": "Bentleyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bentleyville, Pennsylvania"
    },
    {
      "city": "Canonsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Canonsburg, Pennsylvania"
    },
    {
      "city": "Cecil",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cecil, Pennsylvania"
    },
    {
      "city": "Claysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Claysville, Pennsylvania"
    },
    {
      "city": "Cokeburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Cokeburg, Pennsylvania"
    },
    {
      "city": "Prosperity",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Prosperity, Pennsylvania"
    },
    {
      "city": "Eighty Four",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Eighty Four, Pennsylvania"
    },
    {
      "city": "Ellsworth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ellsworth, Pennsylvania"
    },
    {
      "city": "Finleyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Finleyville, Pennsylvania"
    },
    {
      "city": "Fredericktown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fredericktown, Pennsylvania"
    },
    {
      "city": "Gastonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gastonville, Pennsylvania"
    },
    {
      "city": "Hendersonville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hendersonville, Pennsylvania"
    },
    {
      "city": "Hickory",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hickory, Pennsylvania"
    },
    {
      "city": "Houston",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Houston, Pennsylvania"
    },
    {
      "city": "Marianna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Marianna, Pennsylvania"
    },
    {
      "city": "Meadow Lands",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Meadow Lands, Pennsylvania"
    },
    {
      "city": "Millsboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Millsboro, Pennsylvania"
    },
    {
      "city": "Muse",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Muse, Pennsylvania"
    },
    {
      "city": "Richeyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Richeyville, Pennsylvania"
    },
    {
      "city": "Scenery Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Scenery Hill, Pennsylvania"
    },
    {
      "city": "Southview",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Southview, Pennsylvania"
    },
    {
      "city": "Strabane",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Strabane, Pennsylvania"
    },
    {
      "city": "Taylorstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Taylorstown, Pennsylvania"
    },
    {
      "city": "Van Voorhis",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Van Voorhis, Pennsylvania"
    },
    {
      "city": "Venetia",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Venetia, Pennsylvania"
    },
    {
      "city": "Vestaburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vestaburg, Pennsylvania"
    },
    {
      "city": "West Alexander",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Alexander, Pennsylvania"
    },
    {
      "city": "West Finley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Finley, Pennsylvania"
    },
    {
      "city": "Westland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westland, Pennsylvania"
    },
    {
      "city": "West Middletown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Middletown, Pennsylvania"
    },
    {
      "city": "Allenport",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Allenport, Pennsylvania"
    },
    {
      "city": "California",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "California, Pennsylvania"
    },
    {
      "city": "Coal Center",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Coal Center, Pennsylvania"
    },
    {
      "city": "Daisytown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Daisytown, Pennsylvania"
    },
    {
      "city": "Denbo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Denbo, Pennsylvania"
    },
    {
      "city": "Dunlevy",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dunlevy, Pennsylvania"
    },
    {
      "city": "Elco",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Elco, Pennsylvania"
    },
    {
      "city": "Roscoe",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Roscoe, Pennsylvania"
    },
    {
      "city": "Stockdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stockdale, Pennsylvania"
    },
    {
      "city": "Beach Lake",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Beach Lake, Pennsylvania"
    },
    {
      "city": "Damascus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Damascus, Pennsylvania"
    },
    {
      "city": "Equinunk",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Equinunk, Pennsylvania"
    },
    {
      "city": "Gouldsboro",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Gouldsboro, Pennsylvania"
    },
    {
      "city": "Hamlin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hamlin, Pennsylvania"
    },
    {
      "city": "Hawley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hawley, Pennsylvania"
    },
    {
      "city": "Honesdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Honesdale, Pennsylvania"
    },
    {
      "city": "Lake Ariel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake Ariel, Pennsylvania"
    },
    {
      "city": "Lake Como",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake Como, Pennsylvania"
    },
    {
      "city": "Lakeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lakeville, Pennsylvania"
    },
    {
      "city": "Lakewood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lakewood, Pennsylvania"
    },
    {
      "city": "Milanville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Milanville, Pennsylvania"
    },
    {
      "city": "Newfoundland",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Newfoundland, Pennsylvania"
    },
    {
      "city": "Orson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Orson, Pennsylvania"
    },
    {
      "city": "Pleasant Mount",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pleasant Mount, Pennsylvania"
    },
    {
      "city": "Poyntelle",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Poyntelle, Pennsylvania"
    },
    {
      "city": "Preston Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Preston Park, Pennsylvania"
    },
    {
      "city": "Prompton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Prompton, Pennsylvania"
    },
    {
      "city": "South Canaan",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Canaan, Pennsylvania"
    },
    {
      "city": "South Sterling",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "South Sterling, Pennsylvania"
    },
    {
      "city": "Starlight",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Starlight, Pennsylvania"
    },
    {
      "city": "Starrucca",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Starrucca, Pennsylvania"
    },
    {
      "city": "Sterling",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sterling, Pennsylvania"
    },
    {
      "city": "Tyler Hill",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tyler Hill, Pennsylvania"
    },
    {
      "city": "Waymart",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Waymart, Pennsylvania"
    },
    {
      "city": "White Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "White Mills, Pennsylvania"
    },
    {
      "city": "Monessen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Monessen, Pennsylvania"
    },
    {
      "city": "New Kensington",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Kensington, Pennsylvania"
    },
    {
      "city": "Pricedale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pricedale, Pennsylvania"
    },
    {
      "city": "Sutersville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Sutersville, Pennsylvania"
    },
    {
      "city": "Trafford",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Trafford, Pennsylvania"
    },
    {
      "city": "Webster",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Webster, Pennsylvania"
    },
    {
      "city": "West Newton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "West Newton, Pennsylvania"
    },
    {
      "city": "Jacobs Creek",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jacobs Creek, Pennsylvania"
    },
    {
      "city": "Smithton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Smithton, Pennsylvania"
    },
    {
      "city": "Greensburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Greensburg, Pennsylvania"
    },
    {
      "city": "Acme",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Acme, Pennsylvania"
    },
    {
      "city": "Adamsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Adamsburg, Pennsylvania"
    },
    {
      "city": "Alverton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Alverton, Pennsylvania"
    },
    {
      "city": "Apollo",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Apollo, Pennsylvania"
    },
    {
      "city": "Ardara",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ardara, Pennsylvania"
    },
    {
      "city": "Armbrust",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Armbrust, Pennsylvania"
    },
    {
      "city": "Arona",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Arona, Pennsylvania"
    },
    {
      "city": "Avonmore",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Avonmore, Pennsylvania"
    },
    {
      "city": "Bovard",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bovard, Pennsylvania"
    },
    {
      "city": "Bradenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bradenville, Pennsylvania"
    },
    {
      "city": "Calumet",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Calumet, Pennsylvania"
    },
    {
      "city": "Champion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Champion, Pennsylvania"
    },
    {
      "city": "Claridge",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Claridge, Pennsylvania"
    },
    {
      "city": "Crabtree",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Crabtree, Pennsylvania"
    },
    {
      "city": "Darragh",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Darragh, Pennsylvania"
    },
    {
      "city": "Delmont",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Delmont, Pennsylvania"
    },
    {
      "city": "Derry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Derry, Pennsylvania"
    },
    {
      "city": "Donegal",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Donegal, Pennsylvania"
    },
    {
      "city": "East Vandergrift",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Vandergrift, Pennsylvania"
    },
    {
      "city": "Export",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Export, Pennsylvania"
    },
    {
      "city": "Forbes Road",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Forbes Road, Pennsylvania"
    },
    {
      "city": "Grapeville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Grapeville, Pennsylvania"
    },
    {
      "city": "Hannastown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hannastown, Pennsylvania"
    },
    {
      "city": "Harrison City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Harrison City, Pennsylvania"
    },
    {
      "city": "Herminie",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Herminie, Pennsylvania"
    },
    {
      "city": "Hostetter",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hostetter, Pennsylvania"
    },
    {
      "city": "Hunker",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hunker, Pennsylvania"
    },
    {
      "city": "Hutchinson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hutchinson, Pennsylvania"
    },
    {
      "city": "Hyde Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hyde Park, Pennsylvania"
    },
    {
      "city": "Irwin",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Irwin, Pennsylvania"
    },
    {
      "city": "Jeannette",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jeannette, Pennsylvania"
    },
    {
      "city": "Jones Mills",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Jones Mills, Pennsylvania"
    },
    {
      "city": "Larimer",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Larimer, Pennsylvania"
    },
    {
      "city": "Latrobe",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Latrobe, Pennsylvania"
    },
    {
      "city": "Laughlintown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Laughlintown, Pennsylvania"
    },
    {
      "city": "Ligonier",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ligonier, Pennsylvania"
    },
    {
      "city": "Lowber",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lowber, Pennsylvania"
    },
    {
      "city": "Loyalhanna",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loyalhanna, Pennsylvania"
    },
    {
      "city": "Luxor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Luxor, Pennsylvania"
    },
    {
      "city": "Madison",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Madison, Pennsylvania"
    },
    {
      "city": "Mammoth",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mammoth, Pennsylvania"
    },
    {
      "city": "Manor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Manor, Pennsylvania"
    },
    {
      "city": "Mount Pleasant",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Pleasant, Pennsylvania"
    },
    {
      "city": "Murrysville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Murrysville, Pennsylvania"
    },
    {
      "city": "New Alexandria",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Alexandria, Pennsylvania"
    },
    {
      "city": "New Derry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Derry, Pennsylvania"
    },
    {
      "city": "New Stanton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Stanton, Pennsylvania"
    },
    {
      "city": "Norvelt",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Norvelt, Pennsylvania"
    },
    {
      "city": "Penn",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Penn, Pennsylvania"
    },
    {
      "city": "Pleasant Unity",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Pleasant Unity, Pennsylvania"
    },
    {
      "city": "Rector",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rector, Pennsylvania"
    },
    {
      "city": "Rillton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rillton, Pennsylvania"
    },
    {
      "city": "Ruffs Dale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Ruffs Dale, Pennsylvania"
    },
    {
      "city": "Salina",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Salina, Pennsylvania"
    },
    {
      "city": "Scottdale",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Scottdale, Pennsylvania"
    },
    {
      "city": "Slickville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Slickville, Pennsylvania"
    },
    {
      "city": "Southwest",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Southwest, Pennsylvania"
    },
    {
      "city": "Stahlstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stahlstown, Pennsylvania"
    },
    {
      "city": "Tarrs",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tarrs, Pennsylvania"
    },
    {
      "city": "United",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "United, Pennsylvania"
    },
    {
      "city": "Vandergrift",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Vandergrift, Pennsylvania"
    },
    {
      "city": "Wendel",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wendel, Pennsylvania"
    },
    {
      "city": "Westmoreland City",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Westmoreland City, Pennsylvania"
    },
    {
      "city": "Whitney",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Whitney, Pennsylvania"
    },
    {
      "city": "Wyano",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wyano, Pennsylvania"
    },
    {
      "city": "Youngstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Youngstown, Pennsylvania"
    },
    {
      "city": "Youngwood",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Youngwood, Pennsylvania"
    },
    {
      "city": "Yukon",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Yukon, Pennsylvania"
    },
    {
      "city": "Torrance",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Torrance, Pennsylvania"
    },
    {
      "city": "Bolivar",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Bolivar, Pennsylvania"
    },
    {
      "city": "New Florence",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Florence, Pennsylvania"
    },
    {
      "city": "Seward",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seward, Pennsylvania"
    },
    {
      "city": "Factoryville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Factoryville, Pennsylvania"
    },
    {
      "city": "Nicholson",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Nicholson, Pennsylvania"
    },
    {
      "city": "Falls",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Falls, Pennsylvania"
    },
    {
      "city": "Laceyville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Laceyville, Pennsylvania"
    },
    {
      "city": "Lake Winola",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lake Winola, Pennsylvania"
    },
    {
      "city": "Mehoopany",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mehoopany, Pennsylvania"
    },
    {
      "city": "Meshoppen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Meshoppen, Pennsylvania"
    },
    {
      "city": "Noxen",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Noxen, Pennsylvania"
    },
    {
      "city": "Tunkhannock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Tunkhannock, Pennsylvania"
    },
    {
      "city": "Dillsburg",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dillsburg, Pennsylvania"
    },
    {
      "city": "Airville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Airville, Pennsylvania"
    },
    {
      "city": "Brogue",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Brogue, Pennsylvania"
    },
    {
      "city": "Codorus",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Codorus, Pennsylvania"
    },
    {
      "city": "Craley",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Craley, Pennsylvania"
    },
    {
      "city": "Dallastown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dallastown, Pennsylvania"
    },
    {
      "city": "Delta",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Delta, Pennsylvania"
    },
    {
      "city": "Dover",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Dover, Pennsylvania"
    },
    {
      "city": "East Prospect",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "East Prospect, Pennsylvania"
    },
    {
      "city": "Emigsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Emigsville, Pennsylvania"
    },
    {
      "city": "Etters",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Etters, Pennsylvania"
    },
    {
      "city": "Fawn Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Fawn Grove, Pennsylvania"
    },
    {
      "city": "Felton",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Felton, Pennsylvania"
    },
    {
      "city": "Franklintown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Franklintown, Pennsylvania"
    },
    {
      "city": "Glen Rock",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glen Rock, Pennsylvania"
    },
    {
      "city": "Glenville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Glenville, Pennsylvania"
    },
    {
      "city": "Hanover",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Hanover, Pennsylvania"
    },
    {
      "city": "Lewisberry",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Lewisberry, Pennsylvania"
    },
    {
      "city": "Loganville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Loganville, Pennsylvania"
    },
    {
      "city": "Manchester",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Manchester, Pennsylvania"
    },
    {
      "city": "Mount Wolf",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Mount Wolf, Pennsylvania"
    },
    {
      "city": "New Freedom",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Freedom, Pennsylvania"
    },
    {
      "city": "New Park",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "New Park, Pennsylvania"
    },
    {
      "city": "Porters Sideling",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Porters Sideling, Pennsylvania"
    },
    {
      "city": "Railroad",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Railroad, Pennsylvania"
    },
    {
      "city": "Red Lion",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Red Lion, Pennsylvania"
    },
    {
      "city": "Rossville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Rossville, Pennsylvania"
    },
    {
      "city": "Seven Valleys",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Seven Valleys, Pennsylvania"
    },
    {
      "city": "Shrewsbury",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Shrewsbury, Pennsylvania"
    },
    {
      "city": "Spring Grove",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Spring Grove, Pennsylvania"
    },
    {
      "city": "Stewartstown",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Stewartstown, Pennsylvania"
    },
    {
      "city": "Thomasville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Thomasville, Pennsylvania"
    },
    {
      "city": "Wellsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wellsville, Pennsylvania"
    },
    {
      "city": "Windsor",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Windsor, Pennsylvania"
    },
    {
      "city": "Wrightsville",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "Wrightsville, Pennsylvania"
    },
    {
      "city": "York Haven",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "York Haven, Pennsylvania"
    },
    {
      "city": "York New Salem",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "York New Salem, Pennsylvania"
    },
    {
      "city": "York",
      "state": "Pennsylvania",
      "shortState": "PA",
      "formatedCity": "York, Pennsylvania"
    },
    {
      "city": "Barrington",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Barrington, Rhode Island"
    },
    {
      "city": "Bristol",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Bristol, Rhode Island"
    },
    {
      "city": "Prudence Island",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Prudence Island, Rhode Island"
    },
    {
      "city": "Warren",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Warren, Rhode Island"
    },
    {
      "city": "Coventry",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Coventry, Rhode Island"
    },
    {
      "city": "West Greenwich",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "West Greenwich, Rhode Island"
    },
    {
      "city": "East Greenwich",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "East Greenwich, Rhode Island"
    },
    {
      "city": "Greene",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Greene, Rhode Island"
    },
    {
      "city": "Warwick",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Warwick, Rhode Island"
    },
    {
      "city": "West Warwick",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "West Warwick, Rhode Island"
    },
    {
      "city": "Adamsville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Adamsville, Rhode Island"
    },
    {
      "city": "Jamestown",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Jamestown, Rhode Island"
    },
    {
      "city": "Little Compton",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Little Compton, Rhode Island"
    },
    {
      "city": "Newport",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Newport, Rhode Island"
    },
    {
      "city": "Middletown",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Middletown, Rhode Island"
    },
    {
      "city": "Portsmouth",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Portsmouth, Rhode Island"
    },
    {
      "city": "Tiverton",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Tiverton, Rhode Island"
    },
    {
      "city": "Albion",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Albion, Rhode Island"
    },
    {
      "city": "Chepachet",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Chepachet, Rhode Island"
    },
    {
      "city": "Clayville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Clayville, Rhode Island"
    },
    {
      "city": "Fiskeville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Fiskeville, Rhode Island"
    },
    {
      "city": "Forestdale",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Forestdale, Rhode Island"
    },
    {
      "city": "Foster",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Foster, Rhode Island"
    },
    {
      "city": "Glendale",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Glendale, Rhode Island"
    },
    {
      "city": "Greenville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Greenville, Rhode Island"
    },
    {
      "city": "Harmony",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Harmony, Rhode Island"
    },
    {
      "city": "Harrisville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Harrisville, Rhode Island"
    },
    {
      "city": "Hope",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Hope, Rhode Island"
    },
    {
      "city": "Manville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Manville, Rhode Island"
    },
    {
      "city": "Mapleville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Mapleville, Rhode Island"
    },
    {
      "city": "North Scituate",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "North Scituate, Rhode Island"
    },
    {
      "city": "Oakland",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Oakland, Rhode Island"
    },
    {
      "city": "Pascoag",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Pascoag, Rhode Island"
    },
    {
      "city": "Pawtucket",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Pawtucket, Rhode Island"
    },
    {
      "city": "Central Falls",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Central Falls, Rhode Island"
    },
    {
      "city": "Cumberland",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Cumberland, Rhode Island"
    },
    {
      "city": "Lincoln",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Lincoln, Rhode Island"
    },
    {
      "city": "Slatersville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Slatersville, Rhode Island"
    },
    {
      "city": "Woonsocket",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Woonsocket, Rhode Island"
    },
    {
      "city": "North Smithfield",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "North Smithfield, Rhode Island"
    },
    {
      "city": "Providence",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Providence, Rhode Island"
    },
    {
      "city": "Cranston",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Cranston, Rhode Island"
    },
    {
      "city": "North Providence",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "North Providence, Rhode Island"
    },
    {
      "city": "East Providence",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "East Providence, Rhode Island"
    },
    {
      "city": "Riverside",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Riverside, Rhode Island"
    },
    {
      "city": "Rumford",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Rumford, Rhode Island"
    },
    {
      "city": "Smithfield",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Smithfield, Rhode Island"
    },
    {
      "city": "Johnston",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Johnston, Rhode Island"
    },
    {
      "city": "Ashaway",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Ashaway, Rhode Island"
    },
    {
      "city": "Block Island",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Block Island, Rhode Island"
    },
    {
      "city": "Bradford",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Bradford, Rhode Island"
    },
    {
      "city": "Carolina",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Carolina, Rhode Island"
    },
    {
      "city": "Charlestown",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Charlestown, Rhode Island"
    },
    {
      "city": "Exeter",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Exeter, Rhode Island"
    },
    {
      "city": "Hope Valley",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Hope Valley, Rhode Island"
    },
    {
      "city": "Hopkinton",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Hopkinton, Rhode Island"
    },
    {
      "city": "Kenyon",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Kenyon, Rhode Island"
    },
    {
      "city": "North Kingstown",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "North Kingstown, Rhode Island"
    },
    {
      "city": "Rockville",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Rockville, Rhode Island"
    },
    {
      "city": "Saunderstown",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Saunderstown, Rhode Island"
    },
    {
      "city": "Shannock",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Shannock, Rhode Island"
    },
    {
      "city": "Slocum",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Slocum, Rhode Island"
    },
    {
      "city": "Wakefield",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Wakefield, Rhode Island"
    },
    {
      "city": "Kingston",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Kingston, Rhode Island"
    },
    {
      "city": "Narragansett",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Narragansett, Rhode Island"
    },
    {
      "city": "Peace Dale",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Peace Dale, Rhode Island"
    },
    {
      "city": "Westerly",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Westerly, Rhode Island"
    },
    {
      "city": "West Kingston",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "West Kingston, Rhode Island"
    },
    {
      "city": "Wood River Junction",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Wood River Junction, Rhode Island"
    },
    {
      "city": "Wyoming",
      "state": "Rhode Island",
      "shortState": "RI",
      "formatedCity": "Wyoming, Rhode Island"
    },
    {
      "city": "Abbeville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Abbeville, South Carolina"
    },
    {
      "city": "Calhoun Falls",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Calhoun Falls, South Carolina"
    },
    {
      "city": "Donalds",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Donalds, South Carolina"
    },
    {
      "city": "Due West",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Due West, South Carolina"
    },
    {
      "city": "Lowndesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lowndesville, South Carolina"
    },
    {
      "city": "Salley",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Salley, South Carolina"
    },
    {
      "city": "Wagener",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Wagener, South Carolina"
    },
    {
      "city": "Aiken",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Aiken, South Carolina"
    },
    {
      "city": "New Ellenton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "New Ellenton, South Carolina"
    },
    {
      "city": "Bath",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bath, South Carolina"
    },
    {
      "city": "Clearwater",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clearwater, South Carolina"
    },
    {
      "city": "Gloverville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gloverville, South Carolina"
    },
    {
      "city": "Graniteville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Graniteville, South Carolina"
    },
    {
      "city": "Jackson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jackson, South Carolina"
    },
    {
      "city": "Langley",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Langley, South Carolina"
    },
    {
      "city": "Montmorenci",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Montmorenci, South Carolina"
    },
    {
      "city": "North Augusta",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "North Augusta, South Carolina"
    },
    {
      "city": "Beech Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Beech Island, South Carolina"
    },
    {
      "city": "Vaucluse",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Vaucluse, South Carolina"
    },
    {
      "city": "Warrenville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Warrenville, South Carolina"
    },
    {
      "city": "Windsor",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Windsor, South Carolina"
    },
    {
      "city": "Allendale",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Allendale, South Carolina"
    },
    {
      "city": "Fairfax",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fairfax, South Carolina"
    },
    {
      "city": "Martin",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Martin, South Carolina"
    },
    {
      "city": "Sycamore",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sycamore, South Carolina"
    },
    {
      "city": "Ulmer",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ulmer, South Carolina"
    },
    {
      "city": "Anderson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Anderson, South Carolina"
    },
    {
      "city": "Belton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Belton, South Carolina"
    },
    {
      "city": "Honea Path",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Honea Path, South Carolina"
    },
    {
      "city": "Iva",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Iva, South Carolina"
    },
    {
      "city": "La France",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "La France, South Carolina"
    },
    {
      "city": "Pelzer",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pelzer, South Carolina"
    },
    {
      "city": "Pendleton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pendleton, South Carolina"
    },
    {
      "city": "Sandy Springs",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sandy Springs, South Carolina"
    },
    {
      "city": "Starr",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Starr, South Carolina"
    },
    {
      "city": "Townville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Townville, South Carolina"
    },
    {
      "city": "Williamston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Williamston, South Carolina"
    },
    {
      "city": "Bamberg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bamberg, South Carolina"
    },
    {
      "city": "Denmark",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Denmark, South Carolina"
    },
    {
      "city": "Ehrhardt",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ehrhardt, South Carolina"
    },
    {
      "city": "Olar",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Olar, South Carolina"
    },
    {
      "city": "Barnwell",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Barnwell, South Carolina"
    },
    {
      "city": "Hilda",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hilda, South Carolina"
    },
    {
      "city": "Blackville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blackville, South Carolina"
    },
    {
      "city": "Elko",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Elko, South Carolina"
    },
    {
      "city": "Williston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Williston, South Carolina"
    },
    {
      "city": "Beaufort",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Beaufort, South Carolina"
    },
    {
      "city": "Parris Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Parris Island, South Carolina"
    },
    {
      "city": "Bluffton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bluffton, South Carolina"
    },
    {
      "city": "Dale",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Dale, South Carolina"
    },
    {
      "city": "Daufuskie Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Daufuskie Island, South Carolina"
    },
    {
      "city": "Saint Helena Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Saint Helena Island, South Carolina"
    },
    {
      "city": "Hilton Head Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hilton Head Island, South Carolina"
    },
    {
      "city": "Lobeco",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lobeco, South Carolina"
    },
    {
      "city": "Port Royal",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Port Royal, South Carolina"
    },
    {
      "city": "Seabrook",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Seabrook, South Carolina"
    },
    {
      "city": "Sheldon",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sheldon, South Carolina"
    },
    {
      "city": "Hanahan",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hanahan, South Carolina"
    },
    {
      "city": "Bonneau",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bonneau, South Carolina"
    },
    {
      "city": "Cordesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cordesville, South Carolina"
    },
    {
      "city": "Cross",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cross, South Carolina"
    },
    {
      "city": "Goose Creek",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Goose Creek, South Carolina"
    },
    {
      "city": "Huger",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Huger, South Carolina"
    },
    {
      "city": "Jamestown",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jamestown, South Carolina"
    },
    {
      "city": "Ladson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ladson, South Carolina"
    },
    {
      "city": "Moncks Corner",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Moncks Corner, South Carolina"
    },
    {
      "city": "Pineville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pineville, South Carolina"
    },
    {
      "city": "Pinopolis",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pinopolis, South Carolina"
    },
    {
      "city": "Russellville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Russellville, South Carolina"
    },
    {
      "city": "Saint Stephen",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Saint Stephen, South Carolina"
    },
    {
      "city": "Summerville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Summerville, South Carolina"
    },
    {
      "city": "Charleston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Charleston, South Carolina"
    },
    {
      "city": "Cameron",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cameron, South Carolina"
    },
    {
      "city": "Saint Matthews",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Saint Matthews, South Carolina"
    },
    {
      "city": "Charleston Afb",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Charleston Afb, South Carolina"
    },
    {
      "city": "North Charleston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "North Charleston, South Carolina"
    },
    {
      "city": "Adams Run",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Adams Run, South Carolina"
    },
    {
      "city": "Awendaw",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Awendaw, South Carolina"
    },
    {
      "city": "Folly Beach",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Folly Beach, South Carolina"
    },
    {
      "city": "Hollywood",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hollywood, South Carolina"
    },
    {
      "city": "Isle Of Palms",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Isle Of Palms, South Carolina"
    },
    {
      "city": "Johns Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Johns Island, South Carolina"
    },
    {
      "city": "Mc Clellanville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mc Clellanville, South Carolina"
    },
    {
      "city": "Mount Pleasant",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mount Pleasant, South Carolina"
    },
    {
      "city": "Ravenel",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ravenel, South Carolina"
    },
    {
      "city": "Sullivans Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sullivans Island, South Carolina"
    },
    {
      "city": "Wadmalaw Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Wadmalaw Island, South Carolina"
    },
    {
      "city": "Gaffney",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gaffney, South Carolina"
    },
    {
      "city": "Blacksburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blacksburg, South Carolina"
    },
    {
      "city": "Blackstock",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blackstock, South Carolina"
    },
    {
      "city": "Great Falls",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Great Falls, South Carolina"
    },
    {
      "city": "Chester",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Chester, South Carolina"
    },
    {
      "city": "Edgemoor",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Edgemoor, South Carolina"
    },
    {
      "city": "Fort Lawn",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fort Lawn, South Carolina"
    },
    {
      "city": "Lando",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lando, South Carolina"
    },
    {
      "city": "Richburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Richburg, South Carolina"
    },
    {
      "city": "Mc Bee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mc Bee, South Carolina"
    },
    {
      "city": "Cheraw",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cheraw, South Carolina"
    },
    {
      "city": "Patrick",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Patrick, South Carolina"
    },
    {
      "city": "Chesterfield",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Chesterfield, South Carolina"
    },
    {
      "city": "Jefferson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jefferson, South Carolina"
    },
    {
      "city": "Mount Croghan",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mount Croghan, South Carolina"
    },
    {
      "city": "Pageland",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pageland, South Carolina"
    },
    {
      "city": "Ruby",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ruby, South Carolina"
    },
    {
      "city": "Alcolu",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Alcolu, South Carolina"
    },
    {
      "city": "Davis Station",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Davis Station, South Carolina"
    },
    {
      "city": "Gable",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gable, South Carolina"
    },
    {
      "city": "Manning",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Manning, South Carolina"
    },
    {
      "city": "New Zion",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "New Zion, South Carolina"
    },
    {
      "city": "Sardinia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sardinia, South Carolina"
    },
    {
      "city": "Summerton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Summerton, South Carolina"
    },
    {
      "city": "Turbeville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Turbeville, South Carolina"
    },
    {
      "city": "Lodge",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lodge, South Carolina"
    },
    {
      "city": "Canadys",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Canadys, South Carolina"
    },
    {
      "city": "Cottageville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cottageville, South Carolina"
    },
    {
      "city": "Edisto Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Edisto Island, South Carolina"
    },
    {
      "city": "Green Pond",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Green Pond, South Carolina"
    },
    {
      "city": "Jacksonboro",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jacksonboro, South Carolina"
    },
    {
      "city": "Round O",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Round O, South Carolina"
    },
    {
      "city": "Ruffin",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ruffin, South Carolina"
    },
    {
      "city": "Smoaks",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Smoaks, South Carolina"
    },
    {
      "city": "Walterboro",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Walterboro, South Carolina"
    },
    {
      "city": "Williams",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Williams, South Carolina"
    },
    {
      "city": "Islandton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Islandton, South Carolina"
    },
    {
      "city": "Lamar",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lamar, South Carolina"
    },
    {
      "city": "Lydia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lydia, South Carolina"
    },
    {
      "city": "Darlington",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Darlington, South Carolina"
    },
    {
      "city": "Hartsville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hartsville, South Carolina"
    },
    {
      "city": "Society Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Society Hill, South Carolina"
    },
    {
      "city": "Dillon",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Dillon, South Carolina"
    },
    {
      "city": "Fork",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fork, South Carolina"
    },
    {
      "city": "Hamer",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hamer, South Carolina"
    },
    {
      "city": "Lake View",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lake View, South Carolina"
    },
    {
      "city": "Latta",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Latta, South Carolina"
    },
    {
      "city": "Little Rock",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Little Rock, South Carolina"
    },
    {
      "city": "Dorchester",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Dorchester, South Carolina"
    },
    {
      "city": "Grover",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Grover, South Carolina"
    },
    {
      "city": "Harleyville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Harleyville, South Carolina"
    },
    {
      "city": "Reevesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Reevesville, South Carolina"
    },
    {
      "city": "Ridgeville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ridgeville, South Carolina"
    },
    {
      "city": "Saint George",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Saint George, South Carolina"
    },
    {
      "city": "Edgefield",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Edgefield, South Carolina"
    },
    {
      "city": "Johnston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Johnston, South Carolina"
    },
    {
      "city": "Trenton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Trenton, South Carolina"
    },
    {
      "city": "Blair",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blair, South Carolina"
    },
    {
      "city": "Jenkinsville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jenkinsville, South Carolina"
    },
    {
      "city": "Ridgeway",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ridgeway, South Carolina"
    },
    {
      "city": "Rion",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Rion, South Carolina"
    },
    {
      "city": "Winnsboro",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Winnsboro, South Carolina"
    },
    {
      "city": "Olanta",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Olanta, South Carolina"
    },
    {
      "city": "Timmonsville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Timmonsville, South Carolina"
    },
    {
      "city": "Florence",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Florence, South Carolina"
    },
    {
      "city": "Coward",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Coward, South Carolina"
    },
    {
      "city": "Effingham",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Effingham, South Carolina"
    },
    {
      "city": "Johnsonville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Johnsonville, South Carolina"
    },
    {
      "city": "Lake City",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lake City, South Carolina"
    },
    {
      "city": "Pamplico",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pamplico, South Carolina"
    },
    {
      "city": "Scranton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Scranton, South Carolina"
    },
    {
      "city": "Georgetown",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Georgetown, South Carolina"
    },
    {
      "city": "Andrews",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Andrews, South Carolina"
    },
    {
      "city": "Murrells Inlet",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Murrells Inlet, South Carolina"
    },
    {
      "city": "Pawleys Island",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pawleys Island, South Carolina"
    },
    {
      "city": "Greenville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Greenville, South Carolina"
    },
    {
      "city": "Cleveland",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cleveland, South Carolina"
    },
    {
      "city": "Conestee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Conestee, South Carolina"
    },
    {
      "city": "Fountain Inn",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fountain Inn, South Carolina"
    },
    {
      "city": "Greer",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Greer, South Carolina"
    },
    {
      "city": "Marietta",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Marietta, South Carolina"
    },
    {
      "city": "Mauldin",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mauldin, South Carolina"
    },
    {
      "city": "Piedmont",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Piedmont, South Carolina"
    },
    {
      "city": "Simpsonville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Simpsonville, South Carolina"
    },
    {
      "city": "Slater",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Slater, South Carolina"
    },
    {
      "city": "Taylors",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Taylors, South Carolina"
    },
    {
      "city": "Tigerville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Tigerville, South Carolina"
    },
    {
      "city": "Travelers Rest",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Travelers Rest, South Carolina"
    },
    {
      "city": "Greenwood",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Greenwood, South Carolina"
    },
    {
      "city": "Hodges",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hodges, South Carolina"
    },
    {
      "city": "Ninety Six",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ninety Six, South Carolina"
    },
    {
      "city": "Ware Shoals",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ware Shoals, South Carolina"
    },
    {
      "city": "Bradley",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bradley, South Carolina"
    },
    {
      "city": "Troy",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Troy, South Carolina"
    },
    {
      "city": "Brunson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Brunson, South Carolina"
    },
    {
      "city": "Crocketville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Crocketville, South Carolina"
    },
    {
      "city": "Early Branch",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Early Branch, South Carolina"
    },
    {
      "city": "Estill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Estill, South Carolina"
    },
    {
      "city": "Furman",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Furman, South Carolina"
    },
    {
      "city": "Garnett",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Garnett, South Carolina"
    },
    {
      "city": "Gifford",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gifford, South Carolina"
    },
    {
      "city": "Hampton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hampton, South Carolina"
    },
    {
      "city": "Luray",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Luray, South Carolina"
    },
    {
      "city": "Miley",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Miley, South Carolina"
    },
    {
      "city": "Scotia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Scotia, South Carolina"
    },
    {
      "city": "Varnville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Varnville, South Carolina"
    },
    {
      "city": "Yemassee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Yemassee, South Carolina"
    },
    {
      "city": "Aynor",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Aynor, South Carolina"
    },
    {
      "city": "Conway",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Conway, South Carolina"
    },
    {
      "city": "Galivants Ferry",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Galivants Ferry, South Carolina"
    },
    {
      "city": "Green Sea",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Green Sea, South Carolina"
    },
    {
      "city": "Little River",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Little River, South Carolina"
    },
    {
      "city": "Longs",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Longs, South Carolina"
    },
    {
      "city": "Loris",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Loris, South Carolina"
    },
    {
      "city": "Myrtle Beach",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Myrtle Beach, South Carolina"
    },
    {
      "city": "Nichols",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Nichols, South Carolina"
    },
    {
      "city": "North Myrtle Beach",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "North Myrtle Beach, South Carolina"
    },
    {
      "city": "Okatie",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Okatie, South Carolina"
    },
    {
      "city": "Coosawhatchie",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Coosawhatchie, South Carolina"
    },
    {
      "city": "Hardeeville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hardeeville, South Carolina"
    },
    {
      "city": "Pineland",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pineland, South Carolina"
    },
    {
      "city": "Ridgeland",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ridgeland, South Carolina"
    },
    {
      "city": "Tillman",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Tillman, South Carolina"
    },
    {
      "city": "Bethune",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bethune, South Carolina"
    },
    {
      "city": "Camden",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Camden, South Carolina"
    },
    {
      "city": "Cassatt",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cassatt, South Carolina"
    },
    {
      "city": "Elgin",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Elgin, South Carolina"
    },
    {
      "city": "Liberty Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Liberty Hill, South Carolina"
    },
    {
      "city": "Lugoff",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lugoff, South Carolina"
    },
    {
      "city": "Westville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Westville, South Carolina"
    },
    {
      "city": "Heath Springs",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Heath Springs, South Carolina"
    },
    {
      "city": "Kershaw",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Kershaw, South Carolina"
    },
    {
      "city": "Fort Mill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fort Mill, South Carolina"
    },
    {
      "city": "Lancaster",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lancaster, South Carolina"
    },
    {
      "city": "Van Wyck",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Van Wyck, South Carolina"
    },
    {
      "city": "Clinton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clinton, South Carolina"
    },
    {
      "city": "Cross Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cross Hill, South Carolina"
    },
    {
      "city": "Joanna",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Joanna, South Carolina"
    },
    {
      "city": "Laurens",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Laurens, South Carolina"
    },
    {
      "city": "Mountville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mountville, South Carolina"
    },
    {
      "city": "Waterloo",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Waterloo, South Carolina"
    },
    {
      "city": "Gray Court",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gray Court, South Carolina"
    },
    {
      "city": "Bishopville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bishopville, South Carolina"
    },
    {
      "city": "Elliott",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Elliott, South Carolina"
    },
    {
      "city": "Lynchburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lynchburg, South Carolina"
    },
    {
      "city": "Batesburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Batesburg, South Carolina"
    },
    {
      "city": "Cayce",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cayce, South Carolina"
    },
    {
      "city": "Chapin",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Chapin, South Carolina"
    },
    {
      "city": "Gaston",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gaston, South Carolina"
    },
    {
      "city": "Gilbert",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gilbert, South Carolina"
    },
    {
      "city": "Leesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Leesville, South Carolina"
    },
    {
      "city": "Lexington",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lexington, South Carolina"
    },
    {
      "city": "Pelion",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pelion, South Carolina"
    },
    {
      "city": "Swansea",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Swansea, South Carolina"
    },
    {
      "city": "West Columbia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "West Columbia, South Carolina"
    },
    {
      "city": "Columbia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Columbia, South Carolina"
    },
    {
      "city": "Clarks Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clarks Hill, South Carolina"
    },
    {
      "city": "Mc Cormick",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mc Cormick, South Carolina"
    },
    {
      "city": "Modoc",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Modoc, South Carolina"
    },
    {
      "city": "Mount Carmel",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mount Carmel, South Carolina"
    },
    {
      "city": "Parksville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Parksville, South Carolina"
    },
    {
      "city": "Plum Branch",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Plum Branch, South Carolina"
    },
    {
      "city": "Centenary",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Centenary, South Carolina"
    },
    {
      "city": "Gresham",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gresham, South Carolina"
    },
    {
      "city": "Marion",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Marion, South Carolina"
    },
    {
      "city": "Mullins",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mullins, South Carolina"
    },
    {
      "city": "Rains",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Rains, South Carolina"
    },
    {
      "city": "Sellers",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sellers, South Carolina"
    },
    {
      "city": "Bennettsville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bennettsville, South Carolina"
    },
    {
      "city": "Blenheim",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blenheim, South Carolina"
    },
    {
      "city": "Clio",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clio, South Carolina"
    },
    {
      "city": "Mc Coll",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mc Coll, South Carolina"
    },
    {
      "city": "Tatum",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Tatum, South Carolina"
    },
    {
      "city": "Wallace",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Wallace, South Carolina"
    },
    {
      "city": "Chappells",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Chappells, South Carolina"
    },
    {
      "city": "Little Mountain",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Little Mountain, South Carolina"
    },
    {
      "city": "Newberry",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Newberry, South Carolina"
    },
    {
      "city": "Peak",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Peak, South Carolina"
    },
    {
      "city": "Pomaria",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pomaria, South Carolina"
    },
    {
      "city": "Prosperity",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Prosperity, South Carolina"
    },
    {
      "city": "Silverstreet",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Silverstreet, South Carolina"
    },
    {
      "city": "Whitmire",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Whitmire, South Carolina"
    },
    {
      "city": "Kinards",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Kinards, South Carolina"
    },
    {
      "city": "Fair Play",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fair Play, South Carolina"
    },
    {
      "city": "Long Creek",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Long Creek, South Carolina"
    },
    {
      "city": "Mountain Rest",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mountain Rest, South Carolina"
    },
    {
      "city": "Newry",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Newry, South Carolina"
    },
    {
      "city": "Seneca",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Seneca, South Carolina"
    },
    {
      "city": "Richland",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Richland, South Carolina"
    },
    {
      "city": "Salem",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Salem, South Carolina"
    },
    {
      "city": "Tamassee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Tamassee, South Carolina"
    },
    {
      "city": "Walhalla",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Walhalla, South Carolina"
    },
    {
      "city": "Westminster",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Westminster, South Carolina"
    },
    {
      "city": "West Union",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "West Union, South Carolina"
    },
    {
      "city": "Bowman",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bowman, South Carolina"
    },
    {
      "city": "Cope",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cope, South Carolina"
    },
    {
      "city": "Cordova",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cordova, South Carolina"
    },
    {
      "city": "Elloree",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Elloree, South Carolina"
    },
    {
      "city": "Eutawville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Eutawville, South Carolina"
    },
    {
      "city": "Holly Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Holly Hill, South Carolina"
    },
    {
      "city": "Neeses",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Neeses, South Carolina"
    },
    {
      "city": "North",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "North, South Carolina"
    },
    {
      "city": "Norway",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Norway, South Carolina"
    },
    {
      "city": "Orangeburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Orangeburg, South Carolina"
    },
    {
      "city": "Rowesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Rowesville, South Carolina"
    },
    {
      "city": "Santee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Santee, South Carolina"
    },
    {
      "city": "Springfield",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Springfield, South Carolina"
    },
    {
      "city": "Vance",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Vance, South Carolina"
    },
    {
      "city": "Branchville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Branchville, South Carolina"
    },
    {
      "city": "Central",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Central, South Carolina"
    },
    {
      "city": "Clemson",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clemson, South Carolina"
    },
    {
      "city": "Easley",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Easley, South Carolina"
    },
    {
      "city": "Liberty",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Liberty, South Carolina"
    },
    {
      "city": "Norris",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Norris, South Carolina"
    },
    {
      "city": "Pickens",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pickens, South Carolina"
    },
    {
      "city": "Six Mile",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Six Mile, South Carolina"
    },
    {
      "city": "Sunset",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sunset, South Carolina"
    },
    {
      "city": "Ballentine",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ballentine, South Carolina"
    },
    {
      "city": "Blythewood",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Blythewood, South Carolina"
    },
    {
      "city": "Eastover",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Eastover, South Carolina"
    },
    {
      "city": "Gadsden",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gadsden, South Carolina"
    },
    {
      "city": "Hopkins",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hopkins, South Carolina"
    },
    {
      "city": "Irmo",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Irmo, South Carolina"
    },
    {
      "city": "State Park",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "State Park, South Carolina"
    },
    {
      "city": "White Rock",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "White Rock, South Carolina"
    },
    {
      "city": "Monetta",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Monetta, South Carolina"
    },
    {
      "city": "Ridge Spring",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ridge Spring, South Carolina"
    },
    {
      "city": "Saluda",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Saluda, South Carolina"
    },
    {
      "city": "Ward",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Ward, South Carolina"
    },
    {
      "city": "Spartanburg",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Spartanburg, South Carolina"
    },
    {
      "city": "Boiling Springs",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Boiling Springs, South Carolina"
    },
    {
      "city": "Arcadia",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Arcadia, South Carolina"
    },
    {
      "city": "Campobello",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Campobello, South Carolina"
    },
    {
      "city": "Chesnee",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Chesnee, South Carolina"
    },
    {
      "city": "Clifton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clifton, South Carolina"
    },
    {
      "city": "Converse",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Converse, South Carolina"
    },
    {
      "city": "Cowpens",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cowpens, South Carolina"
    },
    {
      "city": "Cross Anchor",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cross Anchor, South Carolina"
    },
    {
      "city": "Drayton",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Drayton, South Carolina"
    },
    {
      "city": "Duncan",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Duncan, South Carolina"
    },
    {
      "city": "Enoree",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Enoree, South Carolina"
    },
    {
      "city": "Fairforest",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fairforest, South Carolina"
    },
    {
      "city": "Fingerville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Fingerville, South Carolina"
    },
    {
      "city": "Glendale",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Glendale, South Carolina"
    },
    {
      "city": "Gramling",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Gramling, South Carolina"
    },
    {
      "city": "Inman",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Inman, South Carolina"
    },
    {
      "city": "Landrum",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Landrum, South Carolina"
    },
    {
      "city": "Lyman",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lyman, South Carolina"
    },
    {
      "city": "Mayo",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mayo, South Carolina"
    },
    {
      "city": "Moore",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Moore, South Carolina"
    },
    {
      "city": "Pacolet",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pacolet, South Carolina"
    },
    {
      "city": "Pacolet Mills",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pacolet Mills, South Carolina"
    },
    {
      "city": "Pauline",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pauline, South Carolina"
    },
    {
      "city": "Reidville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Reidville, South Carolina"
    },
    {
      "city": "Roebuck",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Roebuck, South Carolina"
    },
    {
      "city": "Startex",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Startex, South Carolina"
    },
    {
      "city": "Una",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Una, South Carolina"
    },
    {
      "city": "Wellford",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Wellford, South Carolina"
    },
    {
      "city": "White Stone",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "White Stone, South Carolina"
    },
    {
      "city": "Woodruff",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Woodruff, South Carolina"
    },
    {
      "city": "Dalzell",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Dalzell, South Carolina"
    },
    {
      "city": "Horatio",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Horatio, South Carolina"
    },
    {
      "city": "Mayesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mayesville, South Carolina"
    },
    {
      "city": "Pinewood",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Pinewood, South Carolina"
    },
    {
      "city": "Rembert",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Rembert, South Carolina"
    },
    {
      "city": "Sumter",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sumter, South Carolina"
    },
    {
      "city": "Shaw Afb",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Shaw Afb, South Carolina"
    },
    {
      "city": "Wedgefield",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Wedgefield, South Carolina"
    },
    {
      "city": "Carlisle",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Carlisle, South Carolina"
    },
    {
      "city": "Buffalo",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Buffalo, South Carolina"
    },
    {
      "city": "Jonesville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Jonesville, South Carolina"
    },
    {
      "city": "Lockhart",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lockhart, South Carolina"
    },
    {
      "city": "Union",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Union, South Carolina"
    },
    {
      "city": "Greeleyville",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Greeleyville, South Carolina"
    },
    {
      "city": "Cades",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Cades, South Carolina"
    },
    {
      "city": "Hemingway",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hemingway, South Carolina"
    },
    {
      "city": "Kingstree",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Kingstree, South Carolina"
    },
    {
      "city": "Lane",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Lane, South Carolina"
    },
    {
      "city": "Nesmith",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Nesmith, South Carolina"
    },
    {
      "city": "Salters",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Salters, South Carolina"
    },
    {
      "city": "Bowling Green",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Bowling Green, South Carolina"
    },
    {
      "city": "Catawba",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Catawba, South Carolina"
    },
    {
      "city": "Clover",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Clover, South Carolina"
    },
    {
      "city": "Hickory Grove",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Hickory Grove, South Carolina"
    },
    {
      "city": "Mc Connells",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Mc Connells, South Carolina"
    },
    {
      "city": "Rock Hill",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Rock Hill, South Carolina"
    },
    {
      "city": "Sharon",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Sharon, South Carolina"
    },
    {
      "city": "Smyrna",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "Smyrna, South Carolina"
    },
    {
      "city": "York",
      "state": "South Carolina",
      "shortState": "SC",
      "formatedCity": "York, South Carolina"
    },
    {
      "city": "Plankinton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Plankinton, South Dakota"
    },
    {
      "city": "Stickney",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Stickney, South Dakota"
    },
    {
      "city": "White Lake",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "White Lake, South Dakota"
    },
    {
      "city": "Cavour",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Cavour, South Dakota"
    },
    {
      "city": "Hitchcock",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hitchcock, South Dakota"
    },
    {
      "city": "Huron",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Huron, South Dakota"
    },
    {
      "city": "Virgil",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Virgil, South Dakota"
    },
    {
      "city": "Wessington",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wessington, South Dakota"
    },
    {
      "city": "Wolsey",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wolsey, South Dakota"
    },
    {
      "city": "Yale",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Yale, South Dakota"
    },
    {
      "city": "Martin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Martin, South Dakota"
    },
    {
      "city": "Tuthill",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tuthill, South Dakota"
    },
    {
      "city": "Allen",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Allen, South Dakota"
    },
    {
      "city": "Scotland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Scotland, South Dakota"
    },
    {
      "city": "Springfield",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Springfield, South Dakota"
    },
    {
      "city": "Tabor",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tabor, South Dakota"
    },
    {
      "city": "Tyndall",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tyndall, South Dakota"
    },
    {
      "city": "Avon",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Avon, South Dakota"
    },
    {
      "city": "Aurora",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Aurora, South Dakota"
    },
    {
      "city": "Brookings",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Brookings, South Dakota"
    },
    {
      "city": "Elkton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Elkton, South Dakota"
    },
    {
      "city": "Sinai",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Sinai, South Dakota"
    },
    {
      "city": "Volga",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Volga, South Dakota"
    },
    {
      "city": "Bruce",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bruce, South Dakota"
    },
    {
      "city": "White",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "White, South Dakota"
    },
    {
      "city": "Aberdeen",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Aberdeen, South Dakota"
    },
    {
      "city": "Barnard",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Barnard, South Dakota"
    },
    {
      "city": "Bath",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bath, South Dakota"
    },
    {
      "city": "Claremont",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Claremont, South Dakota"
    },
    {
      "city": "Columbia",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Columbia, South Dakota"
    },
    {
      "city": "Ferney",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ferney, South Dakota"
    },
    {
      "city": "Frederick",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Frederick, South Dakota"
    },
    {
      "city": "Groton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Groton, South Dakota"
    },
    {
      "city": "Hecla",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hecla, South Dakota"
    },
    {
      "city": "Houghton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Houghton, South Dakota"
    },
    {
      "city": "Stratford",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Stratford, South Dakota"
    },
    {
      "city": "Warner",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Warner, South Dakota"
    },
    {
      "city": "Westport",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Westport, South Dakota"
    },
    {
      "city": "Chamberlain",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Chamberlain, South Dakota"
    },
    {
      "city": "Kimball",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kimball, South Dakota"
    },
    {
      "city": "Pukwana",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pukwana, South Dakota"
    },
    {
      "city": "Fort Thompson",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fort Thompson, South Dakota"
    },
    {
      "city": "Gann Valley",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Gann Valley, South Dakota"
    },
    {
      "city": "Belle Fourche",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Belle Fourche, South Dakota"
    },
    {
      "city": "Newell",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Newell, South Dakota"
    },
    {
      "city": "Nisland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Nisland, South Dakota"
    },
    {
      "city": "Vale",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Vale, South Dakota"
    },
    {
      "city": "Herreid",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Herreid, South Dakota"
    },
    {
      "city": "Mound City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mound City, South Dakota"
    },
    {
      "city": "Pollock",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pollock, South Dakota"
    },
    {
      "city": "Dante",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Dante, South Dakota"
    },
    {
      "city": "Geddes",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Geddes, South Dakota"
    },
    {
      "city": "Lake Andes",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lake Andes, South Dakota"
    },
    {
      "city": "Marty",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Marty, South Dakota"
    },
    {
      "city": "Pickstown",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pickstown, South Dakota"
    },
    {
      "city": "Platte",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Platte, South Dakota"
    },
    {
      "city": "Wagner",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wagner, South Dakota"
    },
    {
      "city": "Bradley",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bradley, South Dakota"
    },
    {
      "city": "Clark",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Clark, South Dakota"
    },
    {
      "city": "Garden City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Garden City, South Dakota"
    },
    {
      "city": "Raymond",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Raymond, South Dakota"
    },
    {
      "city": "Vienna",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Vienna, South Dakota"
    },
    {
      "city": "Willow Lake",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Willow Lake, South Dakota"
    },
    {
      "city": "Carpenter",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Carpenter, South Dakota"
    },
    {
      "city": "Burbank",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Burbank, South Dakota"
    },
    {
      "city": "Irene",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Irene, South Dakota"
    },
    {
      "city": "Vermillion",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Vermillion, South Dakota"
    },
    {
      "city": "Wakonda",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wakonda, South Dakota"
    },
    {
      "city": "Watertown",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Watertown, South Dakota"
    },
    {
      "city": "Florence",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Florence, South Dakota"
    },
    {
      "city": "Henry",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Henry, South Dakota"
    },
    {
      "city": "Kranzburg",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kranzburg, South Dakota"
    },
    {
      "city": "South Shore",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "South Shore, South Dakota"
    },
    {
      "city": "Wallace",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wallace, South Dakota"
    },
    {
      "city": "Bullhead",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bullhead, South Dakota"
    },
    {
      "city": "Keldron",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Keldron, South Dakota"
    },
    {
      "city": "Little Eagle",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Little Eagle, South Dakota"
    },
    {
      "city": "Mc Intosh",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mc Intosh, South Dakota"
    },
    {
      "city": "Mc Laughlin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mc Laughlin, South Dakota"
    },
    {
      "city": "Morristown",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Morristown, South Dakota"
    },
    {
      "city": "Trail City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Trail City, South Dakota"
    },
    {
      "city": "Wakpala",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wakpala, South Dakota"
    },
    {
      "city": "Walker",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Walker, South Dakota"
    },
    {
      "city": "Watauga",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Watauga, South Dakota"
    },
    {
      "city": "Buffalo Gap",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Buffalo Gap, South Dakota"
    },
    {
      "city": "Custer",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Custer, South Dakota"
    },
    {
      "city": "Fairburn",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fairburn, South Dakota"
    },
    {
      "city": "Hermosa",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hermosa, South Dakota"
    },
    {
      "city": "Pringle",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pringle, South Dakota"
    },
    {
      "city": "Mitchell",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mitchell, South Dakota"
    },
    {
      "city": "Ethan",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ethan, South Dakota"
    },
    {
      "city": "Mount Vernon",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mount Vernon, South Dakota"
    },
    {
      "city": "Bristol",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bristol, South Dakota"
    },
    {
      "city": "Grenville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Grenville, South Dakota"
    },
    {
      "city": "Roslyn",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Roslyn, South Dakota"
    },
    {
      "city": "Waubay",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Waubay, South Dakota"
    },
    {
      "city": "Webster",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Webster, South Dakota"
    },
    {
      "city": "Andover",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Andover, South Dakota"
    },
    {
      "city": "Pierpont",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pierpont, South Dakota"
    },
    {
      "city": "Astoria",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Astoria, South Dakota"
    },
    {
      "city": "Brandt",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Brandt, South Dakota"
    },
    {
      "city": "Clear Lake",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Clear Lake, South Dakota"
    },
    {
      "city": "Gary",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Gary, South Dakota"
    },
    {
      "city": "Goodwin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Goodwin, South Dakota"
    },
    {
      "city": "Toronto",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Toronto, South Dakota"
    },
    {
      "city": "Eagle Butte",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Eagle Butte, South Dakota"
    },
    {
      "city": "Glencross",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Glencross, South Dakota"
    },
    {
      "city": "Isabel",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Isabel, South Dakota"
    },
    {
      "city": "Lantry",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lantry, South Dakota"
    },
    {
      "city": "Ridgeview",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ridgeview, South Dakota"
    },
    {
      "city": "Timber Lake",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Timber Lake, South Dakota"
    },
    {
      "city": "Whitehorse",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Whitehorse, South Dakota"
    },
    {
      "city": "Armour",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Armour, South Dakota"
    },
    {
      "city": "Corsica",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Corsica, South Dakota"
    },
    {
      "city": "Delmont",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Delmont, South Dakota"
    },
    {
      "city": "Harrison",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Harrison, South Dakota"
    },
    {
      "city": "New Holland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "New Holland, South Dakota"
    },
    {
      "city": "Bowdle",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bowdle, South Dakota"
    },
    {
      "city": "Hosmer",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hosmer, South Dakota"
    },
    {
      "city": "Ipswich",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ipswich, South Dakota"
    },
    {
      "city": "Roscoe",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Roscoe, South Dakota"
    },
    {
      "city": "Edgemont",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Edgemont, South Dakota"
    },
    {
      "city": "Hot Springs",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hot Springs, South Dakota"
    },
    {
      "city": "Oelrichs",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Oelrichs, South Dakota"
    },
    {
      "city": "Oral",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Oral, South Dakota"
    },
    {
      "city": "Smithwick",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Smithwick, South Dakota"
    },
    {
      "city": "Cresbard",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Cresbard, South Dakota"
    },
    {
      "city": "Faulkton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Faulkton, South Dakota"
    },
    {
      "city": "Onaka",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Onaka, South Dakota"
    },
    {
      "city": "Orient",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Orient, South Dakota"
    },
    {
      "city": "Rockham",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Rockham, South Dakota"
    },
    {
      "city": "Seneca",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Seneca, South Dakota"
    },
    {
      "city": "Big Stone City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Big Stone City, South Dakota"
    },
    {
      "city": "Labolt",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Labolt, South Dakota"
    },
    {
      "city": "Marvin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Marvin, South Dakota"
    },
    {
      "city": "Milbank",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Milbank, South Dakota"
    },
    {
      "city": "Revillo",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Revillo, South Dakota"
    },
    {
      "city": "Stockholm",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Stockholm, South Dakota"
    },
    {
      "city": "Strandburg",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Strandburg, South Dakota"
    },
    {
      "city": "Twin Brooks",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Twin Brooks, South Dakota"
    },
    {
      "city": "Bonesteel",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bonesteel, South Dakota"
    },
    {
      "city": "Fairfax",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fairfax, South Dakota"
    },
    {
      "city": "Burke",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Burke, South Dakota"
    },
    {
      "city": "Dallas",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Dallas, South Dakota"
    },
    {
      "city": "Gregory",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Gregory, South Dakota"
    },
    {
      "city": "Herrick",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Herrick, South Dakota"
    },
    {
      "city": "Saint Charles",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Saint Charles, South Dakota"
    },
    {
      "city": "Midland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Midland, South Dakota"
    },
    {
      "city": "Milesville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Milesville, South Dakota"
    },
    {
      "city": "Philip",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Philip, South Dakota"
    },
    {
      "city": "Bryant",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bryant, South Dakota"
    },
    {
      "city": "Castlewood",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Castlewood, South Dakota"
    },
    {
      "city": "Estelline",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Estelline, South Dakota"
    },
    {
      "city": "Hayti",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hayti, South Dakota"
    },
    {
      "city": "Hazel",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hazel, South Dakota"
    },
    {
      "city": "Lake Norden",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lake Norden, South Dakota"
    },
    {
      "city": "Miller",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Miller, South Dakota"
    },
    {
      "city": "Ree Heights",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ree Heights, South Dakota"
    },
    {
      "city": "Saint Lawrence",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Saint Lawrence, South Dakota"
    },
    {
      "city": "Alexandria",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Alexandria, South Dakota"
    },
    {
      "city": "Emery",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Emery, South Dakota"
    },
    {
      "city": "Fulton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fulton, South Dakota"
    },
    {
      "city": "Ralph",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ralph, South Dakota"
    },
    {
      "city": "Reva",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Reva, South Dakota"
    },
    {
      "city": "Buffalo",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Buffalo, South Dakota"
    },
    {
      "city": "Camp Crook",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Camp Crook, South Dakota"
    },
    {
      "city": "Ludlow",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ludlow, South Dakota"
    },
    {
      "city": "Redig",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Redig, South Dakota"
    },
    {
      "city": "Pierre",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pierre, South Dakota"
    },
    {
      "city": "Blunt",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Blunt, South Dakota"
    },
    {
      "city": "Harrold",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Harrold, South Dakota"
    },
    {
      "city": "Freeman",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Freeman, South Dakota"
    },
    {
      "city": "Menno",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Menno, South Dakota"
    },
    {
      "city": "Olivet",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Olivet, South Dakota"
    },
    {
      "city": "Dimock",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Dimock, South Dakota"
    },
    {
      "city": "Kaylor",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kaylor, South Dakota"
    },
    {
      "city": "Parkston",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Parkston, South Dakota"
    },
    {
      "city": "Tripp",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tripp, South Dakota"
    },
    {
      "city": "Highmore",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Highmore, South Dakota"
    },
    {
      "city": "Stephan",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Stephan, South Dakota"
    },
    {
      "city": "Holabird",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Holabird, South Dakota"
    },
    {
      "city": "Belvidere",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Belvidere, South Dakota"
    },
    {
      "city": "Kadoka",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kadoka, South Dakota"
    },
    {
      "city": "Long Valley",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Long Valley, South Dakota"
    },
    {
      "city": "Wanblee",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wanblee, South Dakota"
    },
    {
      "city": "Interior",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Interior, South Dakota"
    },
    {
      "city": "Alpena",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Alpena, South Dakota"
    },
    {
      "city": "Lane",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lane, South Dakota"
    },
    {
      "city": "Wessington Springs",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wessington Springs, South Dakota"
    },
    {
      "city": "Draper",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Draper, South Dakota"
    },
    {
      "city": "Murdo",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Murdo, South Dakota"
    },
    {
      "city": "Okaton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Okaton, South Dakota"
    },
    {
      "city": "Oldham",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Oldham, South Dakota"
    },
    {
      "city": "Arlington",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Arlington, South Dakota"
    },
    {
      "city": "Badger",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Badger, South Dakota"
    },
    {
      "city": "De Smet",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "De Smet, South Dakota"
    },
    {
      "city": "Erwin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Erwin, South Dakota"
    },
    {
      "city": "Lake Preston",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lake Preston, South Dakota"
    },
    {
      "city": "Iroquois",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Iroquois, South Dakota"
    },
    {
      "city": "Chester",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Chester, South Dakota"
    },
    {
      "city": "Madison",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Madison, South Dakota"
    },
    {
      "city": "Nunda",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Nunda, South Dakota"
    },
    {
      "city": "Ramona",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ramona, South Dakota"
    },
    {
      "city": "Rutland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Rutland, South Dakota"
    },
    {
      "city": "Wentworth",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wentworth, South Dakota"
    },
    {
      "city": "Winfred",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Winfred, South Dakota"
    },
    {
      "city": "Deadwood",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Deadwood, South Dakota"
    },
    {
      "city": "Lead",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lead, South Dakota"
    },
    {
      "city": "Nemo",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Nemo, South Dakota"
    },
    {
      "city": "Saint Onge",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Saint Onge, South Dakota"
    },
    {
      "city": "Spearfish",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Spearfish, South Dakota"
    },
    {
      "city": "Whitewood",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Whitewood, South Dakota"
    },
    {
      "city": "Canton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Canton, South Dakota"
    },
    {
      "city": "Fairview",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fairview, South Dakota"
    },
    {
      "city": "Harrisburg",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Harrisburg, South Dakota"
    },
    {
      "city": "Hudson",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hudson, South Dakota"
    },
    {
      "city": "Lennox",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lennox, South Dakota"
    },
    {
      "city": "Tea",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tea, South Dakota"
    },
    {
      "city": "Worthing",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Worthing, South Dakota"
    },
    {
      "city": "Oacoma",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Oacoma, South Dakota"
    },
    {
      "city": "Kennebec",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kennebec, South Dakota"
    },
    {
      "city": "Lower Brule",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lower Brule, South Dakota"
    },
    {
      "city": "Presho",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Presho, South Dakota"
    },
    {
      "city": "Reliance",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Reliance, South Dakota"
    },
    {
      "city": "Vivian",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Vivian, South Dakota"
    },
    {
      "city": "Canistota",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Canistota, South Dakota"
    },
    {
      "city": "Montrose",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Montrose, South Dakota"
    },
    {
      "city": "Salem",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Salem, South Dakota"
    },
    {
      "city": "Bridgewater",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bridgewater, South Dakota"
    },
    {
      "city": "Spencer",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Spencer, South Dakota"
    },
    {
      "city": "Eureka",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Eureka, South Dakota"
    },
    {
      "city": "Leola",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Leola, South Dakota"
    },
    {
      "city": "Long Lake",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Long Lake, South Dakota"
    },
    {
      "city": "Eden",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Eden, South Dakota"
    },
    {
      "city": "Lake City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lake City, South Dakota"
    },
    {
      "city": "Veblen",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Veblen, South Dakota"
    },
    {
      "city": "Amherst",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Amherst, South Dakota"
    },
    {
      "city": "Britton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Britton, South Dakota"
    },
    {
      "city": "Langford",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Langford, South Dakota"
    },
    {
      "city": "Faith",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Faith, South Dakota"
    },
    {
      "city": "Ellsworth Afb",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ellsworth Afb, South Dakota"
    },
    {
      "city": "Black Hawk",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Black Hawk, South Dakota"
    },
    {
      "city": "Enning",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Enning, South Dakota"
    },
    {
      "city": "Fort Meade",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fort Meade, South Dakota"
    },
    {
      "city": "Howes",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Howes, South Dakota"
    },
    {
      "city": "Mud Butte",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mud Butte, South Dakota"
    },
    {
      "city": "Piedmont",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Piedmont, South Dakota"
    },
    {
      "city": "Sturgis",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Sturgis, South Dakota"
    },
    {
      "city": "Union Center",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Union Center, South Dakota"
    },
    {
      "city": "White Owl",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "White Owl, South Dakota"
    },
    {
      "city": "Norris",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Norris, South Dakota"
    },
    {
      "city": "White River",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "White River, South Dakota"
    },
    {
      "city": "Wood",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wood, South Dakota"
    },
    {
      "city": "Canova",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Canova, South Dakota"
    },
    {
      "city": "Carthage",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Carthage, South Dakota"
    },
    {
      "city": "Fedora",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fedora, South Dakota"
    },
    {
      "city": "Howard",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Howard, South Dakota"
    },
    {
      "city": "Baltic",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Baltic, South Dakota"
    },
    {
      "city": "Brandon",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Brandon, South Dakota"
    },
    {
      "city": "Colton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Colton, South Dakota"
    },
    {
      "city": "Crooks",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Crooks, South Dakota"
    },
    {
      "city": "Dell Rapids",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Dell Rapids, South Dakota"
    },
    {
      "city": "Garretson",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Garretson, South Dakota"
    },
    {
      "city": "Hartford",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hartford, South Dakota"
    },
    {
      "city": "Humboldt",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Humboldt, South Dakota"
    },
    {
      "city": "Lyons",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lyons, South Dakota"
    },
    {
      "city": "Renner",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Renner, South Dakota"
    },
    {
      "city": "Valley Springs",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Valley Springs, South Dakota"
    },
    {
      "city": "Sioux Falls",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Sioux Falls, South Dakota"
    },
    {
      "city": "Colman",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Colman, South Dakota"
    },
    {
      "city": "Egan",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Egan, South Dakota"
    },
    {
      "city": "Flandreau",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Flandreau, South Dakota"
    },
    {
      "city": "Trent",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Trent, South Dakota"
    },
    {
      "city": "Rapid City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Rapid City, South Dakota"
    },
    {
      "city": "Box Elder",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Box Elder, South Dakota"
    },
    {
      "city": "Caputa",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Caputa, South Dakota"
    },
    {
      "city": "Hill City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hill City, South Dakota"
    },
    {
      "city": "Keystone",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Keystone, South Dakota"
    },
    {
      "city": "New Underwood",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "New Underwood, South Dakota"
    },
    {
      "city": "Owanka",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Owanka, South Dakota"
    },
    {
      "city": "Quinn",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Quinn, South Dakota"
    },
    {
      "city": "Scenic",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Scenic, South Dakota"
    },
    {
      "city": "Wall",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wall, South Dakota"
    },
    {
      "city": "Wasta",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wasta, South Dakota"
    },
    {
      "city": "Bison",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Bison, South Dakota"
    },
    {
      "city": "Lemmon",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lemmon, South Dakota"
    },
    {
      "city": "Lodgepole",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lodgepole, South Dakota"
    },
    {
      "city": "Meadow",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Meadow, South Dakota"
    },
    {
      "city": "Prairie City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Prairie City, South Dakota"
    },
    {
      "city": "Gettysburg",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Gettysburg, South Dakota"
    },
    {
      "city": "Hoven",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hoven, South Dakota"
    },
    {
      "city": "Lebanon",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lebanon, South Dakota"
    },
    {
      "city": "Tolstoy",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tolstoy, South Dakota"
    },
    {
      "city": "Claire City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Claire City, South Dakota"
    },
    {
      "city": "Corona",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Corona, South Dakota"
    },
    {
      "city": "New Effington",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "New Effington, South Dakota"
    },
    {
      "city": "Ortley",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ortley, South Dakota"
    },
    {
      "city": "Peever",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Peever, South Dakota"
    },
    {
      "city": "Rosholt",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Rosholt, South Dakota"
    },
    {
      "city": "Sisseton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Sisseton, South Dakota"
    },
    {
      "city": "Summit",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Summit, South Dakota"
    },
    {
      "city": "Wilmot",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wilmot, South Dakota"
    },
    {
      "city": "Artesian",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Artesian, South Dakota"
    },
    {
      "city": "Letcher",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Letcher, South Dakota"
    },
    {
      "city": "Woonsocket",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Woonsocket, South Dakota"
    },
    {
      "city": "Batesland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Batesland, South Dakota"
    },
    {
      "city": "Kyle",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Kyle, South Dakota"
    },
    {
      "city": "Manderson",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Manderson, South Dakota"
    },
    {
      "city": "Oglala",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Oglala, South Dakota"
    },
    {
      "city": "Pine Ridge",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Pine Ridge, South Dakota"
    },
    {
      "city": "Porcupine",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Porcupine, South Dakota"
    },
    {
      "city": "Wounded Knee",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Wounded Knee, South Dakota"
    },
    {
      "city": "Ashton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ashton, South Dakota"
    },
    {
      "city": "Brentford",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Brentford, South Dakota"
    },
    {
      "city": "Conde",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Conde, South Dakota"
    },
    {
      "city": "Doland",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Doland, South Dakota"
    },
    {
      "city": "Frankfort",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Frankfort, South Dakota"
    },
    {
      "city": "Mansfield",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mansfield, South Dakota"
    },
    {
      "city": "Mellette",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mellette, South Dakota"
    },
    {
      "city": "Northville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Northville, South Dakota"
    },
    {
      "city": "Redfield",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Redfield, South Dakota"
    },
    {
      "city": "Tulare",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Tulare, South Dakota"
    },
    {
      "city": "Turton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Turton, South Dakota"
    },
    {
      "city": "Fort Pierre",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Fort Pierre, South Dakota"
    },
    {
      "city": "Hayes",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hayes, South Dakota"
    },
    {
      "city": "Agar",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Agar, South Dakota"
    },
    {
      "city": "Onida",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Onida, South Dakota"
    },
    {
      "city": "Mission",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mission, South Dakota"
    },
    {
      "city": "Okreek",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Okreek, South Dakota"
    },
    {
      "city": "Parmelee",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Parmelee, South Dakota"
    },
    {
      "city": "Rosebud",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Rosebud, South Dakota"
    },
    {
      "city": "Saint Francis",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Saint Francis, South Dakota"
    },
    {
      "city": "Colome",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Colome, South Dakota"
    },
    {
      "city": "Hamill",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hamill, South Dakota"
    },
    {
      "city": "Ideal",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Ideal, South Dakota"
    },
    {
      "city": "Winner",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Winner, South Dakota"
    },
    {
      "city": "Witten",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Witten, South Dakota"
    },
    {
      "city": "Centerville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Centerville, South Dakota"
    },
    {
      "city": "Chancellor",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Chancellor, South Dakota"
    },
    {
      "city": "Davis",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Davis, South Dakota"
    },
    {
      "city": "Hurley",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Hurley, South Dakota"
    },
    {
      "city": "Marion",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Marion, South Dakota"
    },
    {
      "city": "Monroe",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Monroe, South Dakota"
    },
    {
      "city": "Parker",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Parker, South Dakota"
    },
    {
      "city": "Viborg",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Viborg, South Dakota"
    },
    {
      "city": "Alcester",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Alcester, South Dakota"
    },
    {
      "city": "Beresford",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Beresford, South Dakota"
    },
    {
      "city": "Elk Point",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Elk Point, South Dakota"
    },
    {
      "city": "Jefferson",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Jefferson, South Dakota"
    },
    {
      "city": "North Sioux City",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "North Sioux City, South Dakota"
    },
    {
      "city": "Akaska",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Akaska, South Dakota"
    },
    {
      "city": "Java",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Java, South Dakota"
    },
    {
      "city": "Selby",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Selby, South Dakota"
    },
    {
      "city": "Mobridge",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mobridge, South Dakota"
    },
    {
      "city": "Glenham",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Glenham, South Dakota"
    },
    {
      "city": "Gayville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Gayville, South Dakota"
    },
    {
      "city": "Lesterville",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Lesterville, South Dakota"
    },
    {
      "city": "Mission Hill",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Mission Hill, South Dakota"
    },
    {
      "city": "Utica",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Utica, South Dakota"
    },
    {
      "city": "Volin",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Volin, South Dakota"
    },
    {
      "city": "Yankton",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Yankton, South Dakota"
    },
    {
      "city": "Cherry Creek",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Cherry Creek, South Dakota"
    },
    {
      "city": "Dupree",
      "state": "South Dakota",
      "shortState": "SD",
      "formatedCity": "Dupree, South Dakota"
    },
    {
      "city": "Andersonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Andersonville, Tennessee"
    },
    {
      "city": "Briceville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Briceville, Tennessee"
    },
    {
      "city": "Clinton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clinton, Tennessee"
    },
    {
      "city": "Lake City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lake City, Tennessee"
    },
    {
      "city": "Norris",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Norris, Tennessee"
    },
    {
      "city": "Oak Ridge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oak Ridge, Tennessee"
    },
    {
      "city": "Bell Buckle",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bell Buckle, Tennessee"
    },
    {
      "city": "Shelbyville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Shelbyville, Tennessee"
    },
    {
      "city": "Unionville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Unionville, Tennessee"
    },
    {
      "city": "Wartrace",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Wartrace, Tennessee"
    },
    {
      "city": "Normandy",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Normandy, Tennessee"
    },
    {
      "city": "Big Sandy",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Big Sandy, Tennessee"
    },
    {
      "city": "Camden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Camden, Tennessee"
    },
    {
      "city": "Eva",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eva, Tennessee"
    },
    {
      "city": "Holladay",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Holladay, Tennessee"
    },
    {
      "city": "Pikeville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pikeville, Tennessee"
    },
    {
      "city": "Alcoa",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Alcoa, Tennessee"
    },
    {
      "city": "Friendsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Friendsville, Tennessee"
    },
    {
      "city": "Louisville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Louisville, Tennessee"
    },
    {
      "city": "Maryville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Maryville, Tennessee"
    },
    {
      "city": "Rockford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rockford, Tennessee"
    },
    {
      "city": "Tallassee",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tallassee, Tennessee"
    },
    {
      "city": "Townsend",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Townsend, Tennessee"
    },
    {
      "city": "Walland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Walland, Tennessee"
    },
    {
      "city": "Charleston",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Charleston, Tennessee"
    },
    {
      "city": "Cleveland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cleveland, Tennessee"
    },
    {
      "city": "Mc Donald",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mc Donald, Tennessee"
    },
    {
      "city": "Caryville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Caryville, Tennessee"
    },
    {
      "city": "Duff",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Duff, Tennessee"
    },
    {
      "city": "Jacksboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jacksboro, Tennessee"
    },
    {
      "city": "Jellico",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jellico, Tennessee"
    },
    {
      "city": "La Follette",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "La Follette, Tennessee"
    },
    {
      "city": "Newcomb",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Newcomb, Tennessee"
    },
    {
      "city": "Pioneer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pioneer, Tennessee"
    },
    {
      "city": "Auburntown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Auburntown, Tennessee"
    },
    {
      "city": "Bradyville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bradyville, Tennessee"
    },
    {
      "city": "Readyville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Readyville, Tennessee"
    },
    {
      "city": "Woodbury",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Woodbury, Tennessee"
    },
    {
      "city": "Mc Kenzie",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mc Kenzie, Tennessee"
    },
    {
      "city": "Atwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Atwood, Tennessee"
    },
    {
      "city": "Mc Lemoresville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mc Lemoresville, Tennessee"
    },
    {
      "city": "Trezevant",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Trezevant, Tennessee"
    },
    {
      "city": "Bruceton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bruceton, Tennessee"
    },
    {
      "city": "Buena Vista",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Buena Vista, Tennessee"
    },
    {
      "city": "Cedar Grove",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cedar Grove, Tennessee"
    },
    {
      "city": "Clarksburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clarksburg, Tennessee"
    },
    {
      "city": "Hollow Rock",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hollow Rock, Tennessee"
    },
    {
      "city": "Huntingdon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Huntingdon, Tennessee"
    },
    {
      "city": "Lavinia",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lavinia, Tennessee"
    },
    {
      "city": "Westport",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Westport, Tennessee"
    },
    {
      "city": "Yuma",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Yuma, Tennessee"
    },
    {
      "city": "Elizabethton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Elizabethton, Tennessee"
    },
    {
      "city": "Hampton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hampton, Tennessee"
    },
    {
      "city": "Milligan College",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Milligan College, Tennessee"
    },
    {
      "city": "Roan Mountain",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Roan Mountain, Tennessee"
    },
    {
      "city": "Watauga",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Watauga, Tennessee"
    },
    {
      "city": "Ashland City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ashland City, Tennessee"
    },
    {
      "city": "Chapmansboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chapmansboro, Tennessee"
    },
    {
      "city": "Kingston Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kingston Springs, Tennessee"
    },
    {
      "city": "Pegram",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pegram, Tennessee"
    },
    {
      "city": "Pleasant View",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pleasant View, Tennessee"
    },
    {
      "city": "Enville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Enville, Tennessee"
    },
    {
      "city": "Henderson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Henderson, Tennessee"
    },
    {
      "city": "Jacks Creek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jacks Creek, Tennessee"
    },
    {
      "city": "Luray",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Luray, Tennessee"
    },
    {
      "city": "Arthur",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Arthur, Tennessee"
    },
    {
      "city": "Clairfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clairfield, Tennessee"
    },
    {
      "city": "Cumberland Gap",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cumberland Gap, Tennessee"
    },
    {
      "city": "Eagan",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eagan, Tennessee"
    },
    {
      "city": "Harrogate",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Harrogate, Tennessee"
    },
    {
      "city": "Lone Mountain",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lone Mountain, Tennessee"
    },
    {
      "city": "New Tazewell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "New Tazewell, Tennessee"
    },
    {
      "city": "Pruden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pruden, Tennessee"
    },
    {
      "city": "Shawanee",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Shawanee, Tennessee"
    },
    {
      "city": "Speedwell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Speedwell, Tennessee"
    },
    {
      "city": "Tazewell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tazewell, Tennessee"
    },
    {
      "city": "Celina",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Celina, Tennessee"
    },
    {
      "city": "Moss",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Moss, Tennessee"
    },
    {
      "city": "Bybee",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bybee, Tennessee"
    },
    {
      "city": "Cosby",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cosby, Tennessee"
    },
    {
      "city": "Del Rio",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Del Rio, Tennessee"
    },
    {
      "city": "Hartford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hartford, Tennessee"
    },
    {
      "city": "Newport",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Newport, Tennessee"
    },
    {
      "city": "Parrottsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Parrottsville, Tennessee"
    },
    {
      "city": "Beechgrove",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Beechgrove, Tennessee"
    },
    {
      "city": "Hillsboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hillsboro, Tennessee"
    },
    {
      "city": "Manchester",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Manchester, Tennessee"
    },
    {
      "city": "Summitville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Summitville, Tennessee"
    },
    {
      "city": "Tullahoma",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tullahoma, Tennessee"
    },
    {
      "city": "Arnold Afb",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Arnold Afb, Tennessee"
    },
    {
      "city": "Alamo",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Alamo, Tennessee"
    },
    {
      "city": "Bells",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bells, Tennessee"
    },
    {
      "city": "Crockett Mills",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Crockett Mills, Tennessee"
    },
    {
      "city": "Friendship",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Friendship, Tennessee"
    },
    {
      "city": "Maury City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Maury City, Tennessee"
    },
    {
      "city": "Fruitvale",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Fruitvale, Tennessee"
    },
    {
      "city": "Gadsden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gadsden, Tennessee"
    },
    {
      "city": "Crab Orchard",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Crab Orchard, Tennessee"
    },
    {
      "city": "Crossville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Crossville, Tennessee"
    },
    {
      "city": "Pleasant Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pleasant Hill, Tennessee"
    },
    {
      "city": "Antioch",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Antioch, Tennessee"
    },
    {
      "city": "Goodlettsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Goodlettsville, Tennessee"
    },
    {
      "city": "Hermitage",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hermitage, Tennessee"
    },
    {
      "city": "Joelton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Joelton, Tennessee"
    },
    {
      "city": "Madison",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Madison, Tennessee"
    },
    {
      "city": "Old Hickory",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Old Hickory, Tennessee"
    },
    {
      "city": "Whites Creek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whites Creek, Tennessee"
    },
    {
      "city": "Nashville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Nashville, Tennessee"
    },
    {
      "city": "Bath Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bath Springs, Tennessee"
    },
    {
      "city": "Decaturville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Decaturville, Tennessee"
    },
    {
      "city": "Parsons",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Parsons, Tennessee"
    },
    {
      "city": "Scotts Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Scotts Hill, Tennessee"
    },
    {
      "city": "Sugar Tree",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sugar Tree, Tennessee"
    },
    {
      "city": "Alexandria",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Alexandria, Tennessee"
    },
    {
      "city": "Dowelltown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dowelltown, Tennessee"
    },
    {
      "city": "Liberty",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Liberty, Tennessee"
    },
    {
      "city": "Smithville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Smithville, Tennessee"
    },
    {
      "city": "Burns",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Burns, Tennessee"
    },
    {
      "city": "Charlotte",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Charlotte, Tennessee"
    },
    {
      "city": "Cumberland Furnace",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cumberland Furnace, Tennessee"
    },
    {
      "city": "Dickson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dickson, Tennessee"
    },
    {
      "city": "Slayden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Slayden, Tennessee"
    },
    {
      "city": "Vanleer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Vanleer, Tennessee"
    },
    {
      "city": "White Bluff",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "White Bluff, Tennessee"
    },
    {
      "city": "Bogota",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bogota, Tennessee"
    },
    {
      "city": "Dyersburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dyersburg, Tennessee"
    },
    {
      "city": "Finley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Finley, Tennessee"
    },
    {
      "city": "Lenox",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lenox, Tennessee"
    },
    {
      "city": "Newbern",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Newbern, Tennessee"
    },
    {
      "city": "Tigrett",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tigrett, Tennessee"
    },
    {
      "city": "Trimble",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Trimble, Tennessee"
    },
    {
      "city": "Braden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Braden, Tennessee"
    },
    {
      "city": "Gallaway",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gallaway, Tennessee"
    },
    {
      "city": "Laconia",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Laconia, Tennessee"
    },
    {
      "city": "La Grange",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "La Grange, Tennessee"
    },
    {
      "city": "Macon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Macon, Tennessee"
    },
    {
      "city": "Moscow",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Moscow, Tennessee"
    },
    {
      "city": "Oakland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oakland, Tennessee"
    },
    {
      "city": "Rossville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rossville, Tennessee"
    },
    {
      "city": "Somerville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Somerville, Tennessee"
    },
    {
      "city": "Williston",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Williston, Tennessee"
    },
    {
      "city": "Allardt",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Allardt, Tennessee"
    },
    {
      "city": "Clarkrange",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clarkrange, Tennessee"
    },
    {
      "city": "Jamestown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jamestown, Tennessee"
    },
    {
      "city": "Grimsley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Grimsley, Tennessee"
    },
    {
      "city": "Pall Mall",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pall Mall, Tennessee"
    },
    {
      "city": "Wilder",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Wilder, Tennessee"
    },
    {
      "city": "Belvidere",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Belvidere, Tennessee"
    },
    {
      "city": "Cowan",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cowan, Tennessee"
    },
    {
      "city": "Decherd",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Decherd, Tennessee"
    },
    {
      "city": "Estill Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Estill Springs, Tennessee"
    },
    {
      "city": "Huntland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Huntland, Tennessee"
    },
    {
      "city": "Sewanee",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sewanee, Tennessee"
    },
    {
      "city": "Sherwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sherwood, Tennessee"
    },
    {
      "city": "Winchester",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Winchester, Tennessee"
    },
    {
      "city": "Kenton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kenton, Tennessee"
    },
    {
      "city": "Bradford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bradford, Tennessee"
    },
    {
      "city": "Dyer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dyer, Tennessee"
    },
    {
      "city": "Eaton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eaton, Tennessee"
    },
    {
      "city": "Gibson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gibson, Tennessee"
    },
    {
      "city": "Humboldt",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Humboldt, Tennessee"
    },
    {
      "city": "Idlewild",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Idlewild, Tennessee"
    },
    {
      "city": "Medina",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Medina, Tennessee"
    },
    {
      "city": "Milan",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Milan, Tennessee"
    },
    {
      "city": "Rutherford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rutherford, Tennessee"
    },
    {
      "city": "Trenton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Trenton, Tennessee"
    },
    {
      "city": "Yorkville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Yorkville, Tennessee"
    },
    {
      "city": "Ardmore",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ardmore, Tennessee"
    },
    {
      "city": "Elkton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Elkton, Tennessee"
    },
    {
      "city": "Frankewing",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Frankewing, Tennessee"
    },
    {
      "city": "Goodspring",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Goodspring, Tennessee"
    },
    {
      "city": "Lynnville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lynnville, Tennessee"
    },
    {
      "city": "Minor Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Minor Hill, Tennessee"
    },
    {
      "city": "Prospect",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Prospect, Tennessee"
    },
    {
      "city": "Pulaski",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pulaski, Tennessee"
    },
    {
      "city": "Bean Station",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bean Station, Tennessee"
    },
    {
      "city": "Blaine",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Blaine, Tennessee"
    },
    {
      "city": "Powder Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Powder Springs, Tennessee"
    },
    {
      "city": "Rutledge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rutledge, Tennessee"
    },
    {
      "city": "Thorn Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Thorn Hill, Tennessee"
    },
    {
      "city": "Washburn",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Washburn, Tennessee"
    },
    {
      "city": "Afton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Afton, Tennessee"
    },
    {
      "city": "Chuckey",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chuckey, Tennessee"
    },
    {
      "city": "Greeneville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Greeneville, Tennessee"
    },
    {
      "city": "Midway",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Midway, Tennessee"
    },
    {
      "city": "Mohawk",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mohawk, Tennessee"
    },
    {
      "city": "Mosheim",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mosheim, Tennessee"
    },
    {
      "city": "Altamont",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Altamont, Tennessee"
    },
    {
      "city": "Beersheba Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Beersheba Springs, Tennessee"
    },
    {
      "city": "Coalmont",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Coalmont, Tennessee"
    },
    {
      "city": "Gruetli Laager",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gruetli Laager, Tennessee"
    },
    {
      "city": "Monteagle",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Monteagle, Tennessee"
    },
    {
      "city": "Palmer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Palmer, Tennessee"
    },
    {
      "city": "Pelham",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pelham, Tennessee"
    },
    {
      "city": "Tracy City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tracy City, Tennessee"
    },
    {
      "city": "Lowland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lowland, Tennessee"
    },
    {
      "city": "Morristown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Morristown, Tennessee"
    },
    {
      "city": "Russellville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Russellville, Tennessee"
    },
    {
      "city": "Talbott",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Talbott, Tennessee"
    },
    {
      "city": "Whitesburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whitesburg, Tennessee"
    },
    {
      "city": "Apison",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Apison, Tennessee"
    },
    {
      "city": "Bakewell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bakewell, Tennessee"
    },
    {
      "city": "Birchwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Birchwood, Tennessee"
    },
    {
      "city": "Collegedale",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Collegedale, Tennessee"
    },
    {
      "city": "Harrison",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Harrison, Tennessee"
    },
    {
      "city": "Hixson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hixson, Tennessee"
    },
    {
      "city": "Lookout Mountain",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lookout Mountain, Tennessee"
    },
    {
      "city": "Lupton City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lupton City, Tennessee"
    },
    {
      "city": "Ooltewah",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ooltewah, Tennessee"
    },
    {
      "city": "Sale Creek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sale Creek, Tennessee"
    },
    {
      "city": "Signal Mountain",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Signal Mountain, Tennessee"
    },
    {
      "city": "Soddy Daisy",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Soddy Daisy, Tennessee"
    },
    {
      "city": "Chattanooga",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chattanooga, Tennessee"
    },
    {
      "city": "Kyles Ford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kyles Ford, Tennessee"
    },
    {
      "city": "Sneedville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sneedville, Tennessee"
    },
    {
      "city": "Bolivar",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bolivar, Tennessee"
    },
    {
      "city": "Grand Junction",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Grand Junction, Tennessee"
    },
    {
      "city": "Hickory Valley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hickory Valley, Tennessee"
    },
    {
      "city": "Hornsby",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hornsby, Tennessee"
    },
    {
      "city": "Middleton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Middleton, Tennessee"
    },
    {
      "city": "Pocahontas",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pocahontas, Tennessee"
    },
    {
      "city": "Saulsbury",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Saulsbury, Tennessee"
    },
    {
      "city": "Whiteville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whiteville, Tennessee"
    },
    {
      "city": "Silerton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Silerton, Tennessee"
    },
    {
      "city": "Toone",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Toone, Tennessee"
    },
    {
      "city": "Counce",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Counce, Tennessee"
    },
    {
      "city": "Crump",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Crump, Tennessee"
    },
    {
      "city": "Morris Chapel",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Morris Chapel, Tennessee"
    },
    {
      "city": "Pickwick Dam",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pickwick Dam, Tennessee"
    },
    {
      "city": "Saltillo",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Saltillo, Tennessee"
    },
    {
      "city": "Savannah",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Savannah, Tennessee"
    },
    {
      "city": "Shiloh",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Shiloh, Tennessee"
    },
    {
      "city": "Olivehill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Olivehill, Tennessee"
    },
    {
      "city": "Church Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Church Hill, Tennessee"
    },
    {
      "city": "Mount Carmel",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mount Carmel, Tennessee"
    },
    {
      "city": "Bulls Gap",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bulls Gap, Tennessee"
    },
    {
      "city": "Eidson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eidson, Tennessee"
    },
    {
      "city": "Mooresburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mooresburg, Tennessee"
    },
    {
      "city": "Rogersville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rogersville, Tennessee"
    },
    {
      "city": "Surgoinsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Surgoinsville, Tennessee"
    },
    {
      "city": "Brownsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Brownsville, Tennessee"
    },
    {
      "city": "Stanton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Stanton, Tennessee"
    },
    {
      "city": "Darden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Darden, Tennessee"
    },
    {
      "city": "Huron",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Huron, Tennessee"
    },
    {
      "city": "Lexington",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lexington, Tennessee"
    },
    {
      "city": "Reagan",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Reagan, Tennessee"
    },
    {
      "city": "Sardis",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sardis, Tennessee"
    },
    {
      "city": "Wildersville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Wildersville, Tennessee"
    },
    {
      "city": "Buchanan",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Buchanan, Tennessee"
    },
    {
      "city": "Como",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Como, Tennessee"
    },
    {
      "city": "Cottage Grove",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cottage Grove, Tennessee"
    },
    {
      "city": "Henry",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Henry, Tennessee"
    },
    {
      "city": "Mansfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mansfield, Tennessee"
    },
    {
      "city": "Paris",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Paris, Tennessee"
    },
    {
      "city": "Puryear",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Puryear, Tennessee"
    },
    {
      "city": "Springville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Springville, Tennessee"
    },
    {
      "city": "Bon Aqua",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bon Aqua, Tennessee"
    },
    {
      "city": "Centerville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Centerville, Tennessee"
    },
    {
      "city": "Lyles",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lyles, Tennessee"
    },
    {
      "city": "Nunnelly",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Nunnelly, Tennessee"
    },
    {
      "city": "Only",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Only, Tennessee"
    },
    {
      "city": "Duck River",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Duck River, Tennessee"
    },
    {
      "city": "Primm Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Primm Springs, Tennessee"
    },
    {
      "city": "Erin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Erin, Tennessee"
    },
    {
      "city": "Stewart",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Stewart, Tennessee"
    },
    {
      "city": "Tennessee Ridge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tennessee Ridge, Tennessee"
    },
    {
      "city": "Hurricane Mills",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hurricane Mills, Tennessee"
    },
    {
      "city": "Mc Ewen",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mc Ewen, Tennessee"
    },
    {
      "city": "New Johnsonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "New Johnsonville, Tennessee"
    },
    {
      "city": "Waverly",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Waverly, Tennessee"
    },
    {
      "city": "Gainesboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gainesboro, Tennessee"
    },
    {
      "city": "Granville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Granville, Tennessee"
    },
    {
      "city": "Whitleyville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whitleyville, Tennessee"
    },
    {
      "city": "Dandridge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dandridge, Tennessee"
    },
    {
      "city": "Jefferson City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jefferson City, Tennessee"
    },
    {
      "city": "New Market",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "New Market, Tennessee"
    },
    {
      "city": "Strawberry Plains",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Strawberry Plains, Tennessee"
    },
    {
      "city": "White Pine",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "White Pine, Tennessee"
    },
    {
      "city": "Butler",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Butler, Tennessee"
    },
    {
      "city": "Laurel Bloomery",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Laurel Bloomery, Tennessee"
    },
    {
      "city": "Mountain City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mountain City, Tennessee"
    },
    {
      "city": "Shady Valley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Shady Valley, Tennessee"
    },
    {
      "city": "Trade",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Trade, Tennessee"
    },
    {
      "city": "Corryton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Corryton, Tennessee"
    },
    {
      "city": "Heiskell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Heiskell, Tennessee"
    },
    {
      "city": "Mascot",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mascot, Tennessee"
    },
    {
      "city": "Powell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Powell, Tennessee"
    },
    {
      "city": "Knoxville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Knoxville, Tennessee"
    },
    {
      "city": "Wynnburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Wynnburg, Tennessee"
    },
    {
      "city": "Tiptonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tiptonville, Tennessee"
    },
    {
      "city": "Ridgely",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ridgely, Tennessee"
    },
    {
      "city": "Gates",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gates, Tennessee"
    },
    {
      "city": "Halls",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Halls, Tennessee"
    },
    {
      "city": "Henning",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Henning, Tennessee"
    },
    {
      "city": "Ripley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ripley, Tennessee"
    },
    {
      "city": "Ethridge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ethridge, Tennessee"
    },
    {
      "city": "Five Points",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Five Points, Tennessee"
    },
    {
      "city": "Iron City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Iron City, Tennessee"
    },
    {
      "city": "Lawrenceburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lawrenceburg, Tennessee"
    },
    {
      "city": "Leoma",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Leoma, Tennessee"
    },
    {
      "city": "Loretto",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Loretto, Tennessee"
    },
    {
      "city": "Saint Joseph",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Saint Joseph, Tennessee"
    },
    {
      "city": "Summertown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Summertown, Tennessee"
    },
    {
      "city": "Westpoint",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Westpoint, Tennessee"
    },
    {
      "city": "Hohenwald",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hohenwald, Tennessee"
    },
    {
      "city": "Petersburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Petersburg, Tennessee"
    },
    {
      "city": "Elora",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Elora, Tennessee"
    },
    {
      "city": "Fayetteville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Fayetteville, Tennessee"
    },
    {
      "city": "Flintville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Flintville, Tennessee"
    },
    {
      "city": "Kelso",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kelso, Tennessee"
    },
    {
      "city": "Mulberry",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mulberry, Tennessee"
    },
    {
      "city": "Dellrose",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dellrose, Tennessee"
    },
    {
      "city": "Taft",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Taft, Tennessee"
    },
    {
      "city": "Greenback",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Greenback, Tennessee"
    },
    {
      "city": "Lenoir City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lenoir City, Tennessee"
    },
    {
      "city": "Loudon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Loudon, Tennessee"
    },
    {
      "city": "Philadelphia",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Philadelphia, Tennessee"
    },
    {
      "city": "Athens",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Athens, Tennessee"
    },
    {
      "city": "Calhoun",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Calhoun, Tennessee"
    },
    {
      "city": "Englewood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Englewood, Tennessee"
    },
    {
      "city": "Etowah",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Etowah, Tennessee"
    },
    {
      "city": "Riceville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Riceville, Tennessee"
    },
    {
      "city": "Niota",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Niota, Tennessee"
    },
    {
      "city": "Adamsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Adamsville, Tennessee"
    },
    {
      "city": "Bethel Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bethel Springs, Tennessee"
    },
    {
      "city": "Finger",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Finger, Tennessee"
    },
    {
      "city": "Guys",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Guys, Tennessee"
    },
    {
      "city": "Michie",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Michie, Tennessee"
    },
    {
      "city": "Milledgeville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Milledgeville, Tennessee"
    },
    {
      "city": "Ramer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ramer, Tennessee"
    },
    {
      "city": "Selmer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Selmer, Tennessee"
    },
    {
      "city": "Stantonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Stantonville, Tennessee"
    },
    {
      "city": "Chewalla",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chewalla, Tennessee"
    },
    {
      "city": "Lafayette",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lafayette, Tennessee"
    },
    {
      "city": "Red Boiling Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Red Boiling Springs, Tennessee"
    },
    {
      "city": "Jackson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jackson, Tennessee"
    },
    {
      "city": "Beech Bluff",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Beech Bluff, Tennessee"
    },
    {
      "city": "Medon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Medon, Tennessee"
    },
    {
      "city": "Oakfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oakfield, Tennessee"
    },
    {
      "city": "Pinson",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pinson, Tennessee"
    },
    {
      "city": "Spring Creek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Spring Creek, Tennessee"
    },
    {
      "city": "Denmark",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Denmark, Tennessee"
    },
    {
      "city": "Mercer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mercer, Tennessee"
    },
    {
      "city": "Guild",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Guild, Tennessee"
    },
    {
      "city": "Jasper",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jasper, Tennessee"
    },
    {
      "city": "Sequatchie",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sequatchie, Tennessee"
    },
    {
      "city": "South Pittsburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "South Pittsburg, Tennessee"
    },
    {
      "city": "Whiteside",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whiteside, Tennessee"
    },
    {
      "city": "Whitwell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Whitwell, Tennessee"
    },
    {
      "city": "Belfast",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Belfast, Tennessee"
    },
    {
      "city": "Chapel Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chapel Hill, Tennessee"
    },
    {
      "city": "Cornersville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cornersville, Tennessee"
    },
    {
      "city": "Lewisburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lewisburg, Tennessee"
    },
    {
      "city": "Spring Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Spring Hill, Tennessee"
    },
    {
      "city": "Columbia",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Columbia, Tennessee"
    },
    {
      "city": "Culleoka",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Culleoka, Tennessee"
    },
    {
      "city": "Hampshire",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hampshire, Tennessee"
    },
    {
      "city": "Mount Pleasant",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mount Pleasant, Tennessee"
    },
    {
      "city": "Santa Fe",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Santa Fe, Tennessee"
    },
    {
      "city": "Williamsport",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Williamsport, Tennessee"
    },
    {
      "city": "Decatur",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Decatur, Tennessee"
    },
    {
      "city": "Georgetown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Georgetown, Tennessee"
    },
    {
      "city": "Ten Mile",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ten Mile, Tennessee"
    },
    {
      "city": "Cokercreek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cokercreek, Tennessee"
    },
    {
      "city": "Madisonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Madisonville, Tennessee"
    },
    {
      "city": "Tellico Plains",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tellico Plains, Tennessee"
    },
    {
      "city": "Sweetwater",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sweetwater, Tennessee"
    },
    {
      "city": "Vonore",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Vonore, Tennessee"
    },
    {
      "city": "Clarksville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clarksville, Tennessee"
    },
    {
      "city": "Cunningham",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cunningham, Tennessee"
    },
    {
      "city": "Palmyra",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Palmyra, Tennessee"
    },
    {
      "city": "Southside",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Southside, Tennessee"
    },
    {
      "city": "Woodlawn",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Woodlawn, Tennessee"
    },
    {
      "city": "Lynchburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lynchburg, Tennessee"
    },
    {
      "city": "Coalfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Coalfield, Tennessee"
    },
    {
      "city": "Deer Lodge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Deer Lodge, Tennessee"
    },
    {
      "city": "Rugby",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rugby, Tennessee"
    },
    {
      "city": "Lancing",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lancing, Tennessee"
    },
    {
      "city": "Oakdale",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oakdale, Tennessee"
    },
    {
      "city": "Oliver Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oliver Springs, Tennessee"
    },
    {
      "city": "Petros",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Petros, Tennessee"
    },
    {
      "city": "Sunbright",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sunbright, Tennessee"
    },
    {
      "city": "Wartburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Wartburg, Tennessee"
    },
    {
      "city": "Hornbeak",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hornbeak, Tennessee"
    },
    {
      "city": "Obion",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Obion, Tennessee"
    },
    {
      "city": "Rives",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rives, Tennessee"
    },
    {
      "city": "Samburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Samburg, Tennessee"
    },
    {
      "city": "South Fulton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "South Fulton, Tennessee"
    },
    {
      "city": "Troy",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Troy, Tennessee"
    },
    {
      "city": "Union City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Union City, Tennessee"
    },
    {
      "city": "Woodland Mills",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Woodland Mills, Tennessee"
    },
    {
      "city": "Allons",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Allons, Tennessee"
    },
    {
      "city": "Allred",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Allred, Tennessee"
    },
    {
      "city": "Alpine",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Alpine, Tennessee"
    },
    {
      "city": "Crawford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Crawford, Tennessee"
    },
    {
      "city": "Hilham",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hilham, Tennessee"
    },
    {
      "city": "Livingston",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Livingston, Tennessee"
    },
    {
      "city": "Monroe",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Monroe, Tennessee"
    },
    {
      "city": "Rickman",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rickman, Tennessee"
    },
    {
      "city": "Linden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Linden, Tennessee"
    },
    {
      "city": "Lobelville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lobelville, Tennessee"
    },
    {
      "city": "Byrdstown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Byrdstown, Tennessee"
    },
    {
      "city": "Benton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Benton, Tennessee"
    },
    {
      "city": "Conasauga",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Conasauga, Tennessee"
    },
    {
      "city": "Copperhill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Copperhill, Tennessee"
    },
    {
      "city": "Delano",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Delano, Tennessee"
    },
    {
      "city": "Ducktown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ducktown, Tennessee"
    },
    {
      "city": "Farner",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Farner, Tennessee"
    },
    {
      "city": "Ocoee",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ocoee, Tennessee"
    },
    {
      "city": "Old Fort",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Old Fort, Tennessee"
    },
    {
      "city": "Reliance",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Reliance, Tennessee"
    },
    {
      "city": "Turtletown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Turtletown, Tennessee"
    },
    {
      "city": "Cookeville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cookeville, Tennessee"
    },
    {
      "city": "Baxter",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Baxter, Tennessee"
    },
    {
      "city": "Bloomington Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bloomington Springs, Tennessee"
    },
    {
      "city": "Buffalo Valley",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Buffalo Valley, Tennessee"
    },
    {
      "city": "Monterey",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Monterey, Tennessee"
    },
    {
      "city": "Silver Point",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Silver Point, Tennessee"
    },
    {
      "city": "Dayton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dayton, Tennessee"
    },
    {
      "city": "Evensville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Evensville, Tennessee"
    },
    {
      "city": "Grandview",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Grandview, Tennessee"
    },
    {
      "city": "Graysville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Graysville, Tennessee"
    },
    {
      "city": "Spring City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Spring City, Tennessee"
    },
    {
      "city": "Harriman",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Harriman, Tennessee"
    },
    {
      "city": "Kingston",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kingston, Tennessee"
    },
    {
      "city": "Rockwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rockwood, Tennessee"
    },
    {
      "city": "Adams",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Adams, Tennessee"
    },
    {
      "city": "Cedar Hill",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cedar Hill, Tennessee"
    },
    {
      "city": "Cross Plains",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cross Plains, Tennessee"
    },
    {
      "city": "Greenbrier",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Greenbrier, Tennessee"
    },
    {
      "city": "Orlinda",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Orlinda, Tennessee"
    },
    {
      "city": "Ridgetop",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ridgetop, Tennessee"
    },
    {
      "city": "Springfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Springfield, Tennessee"
    },
    {
      "city": "White House",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "White House, Tennessee"
    },
    {
      "city": "Christiana",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Christiana, Tennessee"
    },
    {
      "city": "Eagleville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eagleville, Tennessee"
    },
    {
      "city": "Fosterville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Fosterville, Tennessee"
    },
    {
      "city": "Lascassas",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lascassas, Tennessee"
    },
    {
      "city": "La Vergne",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "La Vergne, Tennessee"
    },
    {
      "city": "Milton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Milton, Tennessee"
    },
    {
      "city": "Murfreesboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Murfreesboro, Tennessee"
    },
    {
      "city": "Rockvale",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rockvale, Tennessee"
    },
    {
      "city": "Smyrna",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Smyrna, Tennessee"
    },
    {
      "city": "Elgin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Elgin, Tennessee"
    },
    {
      "city": "Helenwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Helenwood, Tennessee"
    },
    {
      "city": "Huntsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Huntsville, Tennessee"
    },
    {
      "city": "Oneida",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Oneida, Tennessee"
    },
    {
      "city": "Robbins",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Robbins, Tennessee"
    },
    {
      "city": "Winfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Winfield, Tennessee"
    },
    {
      "city": "Dunlap",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dunlap, Tennessee"
    },
    {
      "city": "Gatlinburg",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gatlinburg, Tennessee"
    },
    {
      "city": "Kodak",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kodak, Tennessee"
    },
    {
      "city": "Sevierville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sevierville, Tennessee"
    },
    {
      "city": "Pigeon Forge",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pigeon Forge, Tennessee"
    },
    {
      "city": "Seymour",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Seymour, Tennessee"
    },
    {
      "city": "Memphis",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Memphis, Tennessee"
    },
    {
      "city": "Arlington",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Arlington, Tennessee"
    },
    {
      "city": "Brunswick",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Brunswick, Tennessee"
    },
    {
      "city": "Cordova",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cordova, Tennessee"
    },
    {
      "city": "Collierville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Collierville, Tennessee"
    },
    {
      "city": "Eads",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Eads, Tennessee"
    },
    {
      "city": "Ellendale",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Ellendale, Tennessee"
    },
    {
      "city": "Millington",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Millington, Tennessee"
    },
    {
      "city": "Germantown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Germantown, Tennessee"
    },
    {
      "city": "Carthage",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Carthage, Tennessee"
    },
    {
      "city": "Dixon Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dixon Springs, Tennessee"
    },
    {
      "city": "Pleasant Shade",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Pleasant Shade, Tennessee"
    },
    {
      "city": "Riddleton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Riddleton, Tennessee"
    },
    {
      "city": "Brush Creek",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Brush Creek, Tennessee"
    },
    {
      "city": "Chestnut Mound",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Chestnut Mound, Tennessee"
    },
    {
      "city": "Elmwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Elmwood, Tennessee"
    },
    {
      "city": "Gordonsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gordonsville, Tennessee"
    },
    {
      "city": "Hickman",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hickman, Tennessee"
    },
    {
      "city": "Lancaster",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lancaster, Tennessee"
    },
    {
      "city": "Big Rock",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Big Rock, Tennessee"
    },
    {
      "city": "Bumpus Mills",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bumpus Mills, Tennessee"
    },
    {
      "city": "Cumberland City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cumberland City, Tennessee"
    },
    {
      "city": "Dover",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dover, Tennessee"
    },
    {
      "city": "Indian Mound",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Indian Mound, Tennessee"
    },
    {
      "city": "Blountville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Blountville, Tennessee"
    },
    {
      "city": "Bluff City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bluff City, Tennessee"
    },
    {
      "city": "Bristol",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bristol, Tennessee"
    },
    {
      "city": "Kingsport",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Kingsport, Tennessee"
    },
    {
      "city": "Piney Flats",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Piney Flats, Tennessee"
    },
    {
      "city": "Bethpage",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Bethpage, Tennessee"
    },
    {
      "city": "Castalian Springs",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Castalian Springs, Tennessee"
    },
    {
      "city": "Cottontown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cottontown, Tennessee"
    },
    {
      "city": "Gallatin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gallatin, Tennessee"
    },
    {
      "city": "Hendersonville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hendersonville, Tennessee"
    },
    {
      "city": "Mitchellville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mitchellville, Tennessee"
    },
    {
      "city": "Portland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Portland, Tennessee"
    },
    {
      "city": "Westmoreland",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Westmoreland, Tennessee"
    },
    {
      "city": "Atoka",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Atoka, Tennessee"
    },
    {
      "city": "Brighton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Brighton, Tennessee"
    },
    {
      "city": "Burlison",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Burlison, Tennessee"
    },
    {
      "city": "Covington",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Covington, Tennessee"
    },
    {
      "city": "Drummonds",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Drummonds, Tennessee"
    },
    {
      "city": "Mason",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mason, Tennessee"
    },
    {
      "city": "Munford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Munford, Tennessee"
    },
    {
      "city": "Tipton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Tipton, Tennessee"
    },
    {
      "city": "Hartsville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Hartsville, Tennessee"
    },
    {
      "city": "Erwin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Erwin, Tennessee"
    },
    {
      "city": "Flag Pond",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Flag Pond, Tennessee"
    },
    {
      "city": "Unicoi",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Unicoi, Tennessee"
    },
    {
      "city": "Luttrell",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Luttrell, Tennessee"
    },
    {
      "city": "Maynardville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Maynardville, Tennessee"
    },
    {
      "city": "Sharps Chapel",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sharps Chapel, Tennessee"
    },
    {
      "city": "Spencer",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Spencer, Tennessee"
    },
    {
      "city": "Mcminnville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mcminnville, Tennessee"
    },
    {
      "city": "Morrison",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Morrison, Tennessee"
    },
    {
      "city": "Smartt",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Smartt, Tennessee"
    },
    {
      "city": "Viola",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Viola, Tennessee"
    },
    {
      "city": "Campaign",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Campaign, Tennessee"
    },
    {
      "city": "Rock Island",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Rock Island, Tennessee"
    },
    {
      "city": "Johnson City",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Johnson City, Tennessee"
    },
    {
      "city": "Fall Branch",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Fall Branch, Tennessee"
    },
    {
      "city": "Jonesborough",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Jonesborough, Tennessee"
    },
    {
      "city": "Limestone",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Limestone, Tennessee"
    },
    {
      "city": "Mountain Home",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mountain Home, Tennessee"
    },
    {
      "city": "Telford",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Telford, Tennessee"
    },
    {
      "city": "Clifton",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Clifton, Tennessee"
    },
    {
      "city": "Collinwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Collinwood, Tennessee"
    },
    {
      "city": "Cypress Inn",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Cypress Inn, Tennessee"
    },
    {
      "city": "Lutts",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lutts, Tennessee"
    },
    {
      "city": "Waynesboro",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Waynesboro, Tennessee"
    },
    {
      "city": "Dresden",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dresden, Tennessee"
    },
    {
      "city": "Dukedom",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Dukedom, Tennessee"
    },
    {
      "city": "Gleason",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gleason, Tennessee"
    },
    {
      "city": "Greenfield",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Greenfield, Tennessee"
    },
    {
      "city": "Martin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Martin, Tennessee"
    },
    {
      "city": "Palmersville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Palmersville, Tennessee"
    },
    {
      "city": "Sharon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sharon, Tennessee"
    },
    {
      "city": "Doyle",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Doyle, Tennessee"
    },
    {
      "city": "Quebeck",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Quebeck, Tennessee"
    },
    {
      "city": "Sparta",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Sparta, Tennessee"
    },
    {
      "city": "Walling",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Walling, Tennessee"
    },
    {
      "city": "Arrington",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Arrington, Tennessee"
    },
    {
      "city": "Brentwood",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Brentwood, Tennessee"
    },
    {
      "city": "College Grove",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "College Grove, Tennessee"
    },
    {
      "city": "Fairview",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Fairview, Tennessee"
    },
    {
      "city": "Franklin",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Franklin, Tennessee"
    },
    {
      "city": "Nolensville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Nolensville, Tennessee"
    },
    {
      "city": "Thompsons Station",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Thompsons Station, Tennessee"
    },
    {
      "city": "Gladeville",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Gladeville, Tennessee"
    },
    {
      "city": "Lebanon",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Lebanon, Tennessee"
    },
    {
      "city": "Mount Juliet",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Mount Juliet, Tennessee"
    },
    {
      "city": "Norene",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Norene, Tennessee"
    },
    {
      "city": "Watertown",
      "state": "Tennessee",
      "shortState": "TN",
      "formatedCity": "Watertown, Tennessee"
    },
    {
      "city": "Frankston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Frankston, Texas"
    },
    {
      "city": "Neches",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Neches, Texas"
    },
    {
      "city": "Palestine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Palestine, Texas"
    },
    {
      "city": "Cayuga",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cayuga, Texas"
    },
    {
      "city": "Elkhart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elkhart, Texas"
    },
    {
      "city": "Montalba",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Montalba, Texas"
    },
    {
      "city": "Tennessee Colony",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tennessee Colony, Texas"
    },
    {
      "city": "Andrews",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Andrews, Texas"
    },
    {
      "city": "Lufkin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lufkin, Texas"
    },
    {
      "city": "Diboll",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Diboll, Texas"
    },
    {
      "city": "Huntington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Huntington, Texas"
    },
    {
      "city": "Pollok",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pollok, Texas"
    },
    {
      "city": "Zavalla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Zavalla, Texas"
    },
    {
      "city": "Fulton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fulton, Texas"
    },
    {
      "city": "Rockport",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rockport, Texas"
    },
    {
      "city": "Archer City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Archer City, Texas"
    },
    {
      "city": "Holliday",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Holliday, Texas"
    },
    {
      "city": "Megargel",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Megargel, Texas"
    },
    {
      "city": "Scotland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Scotland, Texas"
    },
    {
      "city": "Windthorst",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Windthorst, Texas"
    },
    {
      "city": "Claude",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Claude, Texas"
    },
    {
      "city": "Wayside",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wayside, Texas"
    },
    {
      "city": "Campbellton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Campbellton, Texas"
    },
    {
      "city": "Charlotte",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Charlotte, Texas"
    },
    {
      "city": "Christine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Christine, Texas"
    },
    {
      "city": "Jourdanton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jourdanton, Texas"
    },
    {
      "city": "Leming",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leming, Texas"
    },
    {
      "city": "Lytle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lytle, Texas"
    },
    {
      "city": "Peggy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Peggy, Texas"
    },
    {
      "city": "Pleasanton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pleasanton, Texas"
    },
    {
      "city": "Poteet",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Poteet, Texas"
    },
    {
      "city": "Bellville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bellville, Texas"
    },
    {
      "city": "Kenney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kenney, Texas"
    },
    {
      "city": "San Felipe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Felipe, Texas"
    },
    {
      "city": "Sealy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sealy, Texas"
    },
    {
      "city": "Wallis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wallis, Texas"
    },
    {
      "city": "Bleiblerville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bleiblerville, Texas"
    },
    {
      "city": "Cat Spring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cat Spring, Texas"
    },
    {
      "city": "Industry",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Industry, Texas"
    },
    {
      "city": "New Ulm",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Ulm, Texas"
    },
    {
      "city": "Enochs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Enochs, Texas"
    },
    {
      "city": "Maple",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maple, Texas"
    },
    {
      "city": "Muleshoe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Muleshoe, Texas"
    },
    {
      "city": "Bandera",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bandera, Texas"
    },
    {
      "city": "Medina",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Medina, Texas"
    },
    {
      "city": "Pipe Creek",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pipe Creek, Texas"
    },
    {
      "city": "Tarpley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tarpley, Texas"
    },
    {
      "city": "Vanderpool",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vanderpool, Texas"
    },
    {
      "city": "Bastrop",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bastrop, Texas"
    },
    {
      "city": "Cedar Creek",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cedar Creek, Texas"
    },
    {
      "city": "Elgin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elgin, Texas"
    },
    {
      "city": "Mc Dade",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Dade, Texas"
    },
    {
      "city": "Paige",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paige, Texas"
    },
    {
      "city": "Red Rock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Red Rock, Texas"
    },
    {
      "city": "Rosanky",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosanky, Texas"
    },
    {
      "city": "Smithville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Smithville, Texas"
    },
    {
      "city": "Seymour",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seymour, Texas"
    },
    {
      "city": "Beeville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Beeville, Texas"
    },
    {
      "city": "Mineral",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mineral, Texas"
    },
    {
      "city": "Normanna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Normanna, Texas"
    },
    {
      "city": "Pawnee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pawnee, Texas"
    },
    {
      "city": "Pettus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pettus, Texas"
    },
    {
      "city": "Tuleta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tuleta, Texas"
    },
    {
      "city": "Skidmore",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Skidmore, Texas"
    },
    {
      "city": "Tynan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tynan, Texas"
    },
    {
      "city": "Temple",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Temple, Texas"
    },
    {
      "city": "Bartlett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bartlett, Texas"
    },
    {
      "city": "Belton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Belton, Texas"
    },
    {
      "city": "Heidenheimer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Heidenheimer, Texas"
    },
    {
      "city": "Holland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Holland, Texas"
    },
    {
      "city": "Killeen",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Killeen, Texas"
    },
    {
      "city": "Fort Hood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Hood, Texas"
    },
    {
      "city": "Harker Heights",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harker Heights, Texas"
    },
    {
      "city": "Little River Academy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Little River Academy, Texas"
    },
    {
      "city": "Nolanville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nolanville, Texas"
    },
    {
      "city": "Pendleton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pendleton, Texas"
    },
    {
      "city": "Rogers",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rogers, Texas"
    },
    {
      "city": "Salado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Salado, Texas"
    },
    {
      "city": "Troy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Troy, Texas"
    },
    {
      "city": "Atascosa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Atascosa, Texas"
    },
    {
      "city": "Boerne",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Boerne, Texas"
    },
    {
      "city": "Helotes",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Helotes, Texas"
    },
    {
      "city": "Macdona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Macdona, Texas"
    },
    {
      "city": "Somerset",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Somerset, Texas"
    },
    {
      "city": "Von Ormy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Von Ormy, Texas"
    },
    {
      "city": "Adkins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Adkins, Texas"
    },
    {
      "city": "Converse",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Converse, Texas"
    },
    {
      "city": "Elmendorf",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elmendorf, Texas"
    },
    {
      "city": "Universal City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Universal City, Texas"
    },
    {
      "city": "Jbsa Randolph",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jbsa Randolph, Texas"
    },
    {
      "city": "Saint Hedwig",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Saint Hedwig, Texas"
    },
    {
      "city": "San Antonio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Antonio, Texas"
    },
    {
      "city": "Jbsa Ft Sam Houston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jbsa Ft Sam Houston, Texas"
    },
    {
      "city": "Jbsa Lackland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jbsa Lackland, Texas"
    },
    {
      "city": "Blanco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blanco, Texas"
    },
    {
      "city": "Hye",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hye, Texas"
    },
    {
      "city": "Johnson City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Johnson City, Texas"
    },
    {
      "city": "Round Mountain",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Round Mountain, Texas"
    },
    {
      "city": "Gail",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gail, Texas"
    },
    {
      "city": "Clifton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clifton, Texas"
    },
    {
      "city": "Cranfills Gap",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cranfills Gap, Texas"
    },
    {
      "city": "Laguna Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Laguna Park, Texas"
    },
    {
      "city": "Iredell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Iredell, Texas"
    },
    {
      "city": "Kopperl",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kopperl, Texas"
    },
    {
      "city": "Meridian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Meridian, Texas"
    },
    {
      "city": "Morgan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Morgan, Texas"
    },
    {
      "city": "Valley Mills",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Valley Mills, Texas"
    },
    {
      "city": "Walnut Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Walnut Springs, Texas"
    },
    {
      "city": "Texarkana",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Texarkana, Texas"
    },
    {
      "city": "De Kalb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "De Kalb, Texas"
    },
    {
      "city": "Hooks",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hooks, Texas"
    },
    {
      "city": "Maud",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maud, Texas"
    },
    {
      "city": "Nash",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nash, Texas"
    },
    {
      "city": "New Boston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Boston, Texas"
    },
    {
      "city": "Redwater",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Redwater, Texas"
    },
    {
      "city": "Simms",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Simms, Texas"
    },
    {
      "city": "Brazoria",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brazoria, Texas"
    },
    {
      "city": "Damon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Damon, Texas"
    },
    {
      "city": "Danciger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Danciger, Texas"
    },
    {
      "city": "Old Ocean",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Old Ocean, Texas"
    },
    {
      "city": "Sweeny",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sweeny, Texas"
    },
    {
      "city": "West Columbia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "West Columbia, Texas"
    },
    {
      "city": "Alvin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alvin, Texas"
    },
    {
      "city": "Angleton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Angleton, Texas"
    },
    {
      "city": "Clute",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clute, Texas"
    },
    {
      "city": "Danbury",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Danbury, Texas"
    },
    {
      "city": "Freeport",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Freeport, Texas"
    },
    {
      "city": "Lake Jackson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lake Jackson, Texas"
    },
    {
      "city": "Liverpool",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Liverpool, Texas"
    },
    {
      "city": "Manvel",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Manvel, Texas"
    },
    {
      "city": "Pearland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pearland, Texas"
    },
    {
      "city": "Rosharon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosharon, Texas"
    },
    {
      "city": "Bryan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bryan, Texas"
    },
    {
      "city": "College Station",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "College Station, Texas"
    },
    {
      "city": "Kurten",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kurten, Texas"
    },
    {
      "city": "Millican",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Millican, Texas"
    },
    {
      "city": "Wellborn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wellborn, Texas"
    },
    {
      "city": "Alpine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alpine, Texas"
    },
    {
      "city": "Big Bend National Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Big Bend National Park, Texas"
    },
    {
      "city": "Marathon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marathon, Texas"
    },
    {
      "city": "Terlingua",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Terlingua, Texas"
    },
    {
      "city": "Quitaque",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quitaque, Texas"
    },
    {
      "city": "Silverton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Silverton, Texas"
    },
    {
      "city": "Encino",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Encino, Texas"
    },
    {
      "city": "Falfurrias",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Falfurrias, Texas"
    },
    {
      "city": "Blanket",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blanket, Texas"
    },
    {
      "city": "Brownwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brownwood, Texas"
    },
    {
      "city": "Early",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Early, Texas"
    },
    {
      "city": "Bangs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bangs, Texas"
    },
    {
      "city": "Brookesmith",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brookesmith, Texas"
    },
    {
      "city": "May",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "May, Texas"
    },
    {
      "city": "Zephyr",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Zephyr, Texas"
    },
    {
      "city": "Caldwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Caldwell, Texas"
    },
    {
      "city": "Chriesman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chriesman, Texas"
    },
    {
      "city": "Deanville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Deanville, Texas"
    },
    {
      "city": "Lyons",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lyons, Texas"
    },
    {
      "city": "Snook",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Snook, Texas"
    },
    {
      "city": "Somerville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Somerville, Texas"
    },
    {
      "city": "Bertram",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bertram, Texas"
    },
    {
      "city": "Briggs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Briggs, Texas"
    },
    {
      "city": "Burnet",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burnet, Texas"
    },
    {
      "city": "Marble Falls",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marble Falls, Texas"
    },
    {
      "city": "Dale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dale, Texas"
    },
    {
      "city": "Fentress",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fentress, Texas"
    },
    {
      "city": "Lockhart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lockhart, Texas"
    },
    {
      "city": "Luling",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Luling, Texas"
    },
    {
      "city": "Martindale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Martindale, Texas"
    },
    {
      "city": "Maxwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maxwell, Texas"
    },
    {
      "city": "Prairie Lea",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Prairie Lea, Texas"
    },
    {
      "city": "Point Comfort",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Point Comfort, Texas"
    },
    {
      "city": "Port Lavaca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Lavaca, Texas"
    },
    {
      "city": "Port O Connor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port O Connor, Texas"
    },
    {
      "city": "Seadrift",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seadrift, Texas"
    },
    {
      "city": "Cross Plains",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cross Plains, Texas"
    },
    {
      "city": "Putnam",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Putnam, Texas"
    },
    {
      "city": "Baird",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Baird, Texas"
    },
    {
      "city": "Clyde",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clyde, Texas"
    },
    {
      "city": "Brownsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brownsville, Texas"
    },
    {
      "city": "Combes",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Combes, Texas"
    },
    {
      "city": "Harlingen",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harlingen, Texas"
    },
    {
      "city": "La Feria",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Feria, Texas"
    },
    {
      "city": "Los Fresnos",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Los Fresnos, Texas"
    },
    {
      "city": "Los Indios",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Los Indios, Texas"
    },
    {
      "city": "Lozano",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lozano, Texas"
    },
    {
      "city": "Olmito",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Olmito, Texas"
    },
    {
      "city": "Port Isabel",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Isabel, Texas"
    },
    {
      "city": "Rio Hondo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rio Hondo, Texas"
    },
    {
      "city": "San Benito",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Benito, Texas"
    },
    {
      "city": "Santa Maria",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santa Maria, Texas"
    },
    {
      "city": "Santa Rosa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santa Rosa, Texas"
    },
    {
      "city": "South Padre Island",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "South Padre Island, Texas"
    },
    {
      "city": "Leesburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leesburg, Texas"
    },
    {
      "city": "Pittsburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pittsburg, Texas"
    },
    {
      "city": "Groom",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Groom, Texas"
    },
    {
      "city": "Panhandle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Panhandle, Texas"
    },
    {
      "city": "Skellytown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Skellytown, Texas"
    },
    {
      "city": "White Deer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "White Deer, Texas"
    },
    {
      "city": "Atlanta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Atlanta, Texas"
    },
    {
      "city": "Bivins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bivins, Texas"
    },
    {
      "city": "Bloomburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bloomburg, Texas"
    },
    {
      "city": "Douglassville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Douglassville, Texas"
    },
    {
      "city": "Kildare",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kildare, Texas"
    },
    {
      "city": "Linden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Linden, Texas"
    },
    {
      "city": "Mc Leod",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Leod, Texas"
    },
    {
      "city": "Marietta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marietta, Texas"
    },
    {
      "city": "Queen City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Queen City, Texas"
    },
    {
      "city": "Avinger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Avinger, Texas"
    },
    {
      "city": "Hughes Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hughes Springs, Texas"
    },
    {
      "city": "Dimmitt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dimmitt, Texas"
    },
    {
      "city": "Hart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hart, Texas"
    },
    {
      "city": "Nazareth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nazareth, Texas"
    },
    {
      "city": "Summerfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Summerfield, Texas"
    },
    {
      "city": "Anahuac",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anahuac, Texas"
    },
    {
      "city": "Baytown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Baytown, Texas"
    },
    {
      "city": "Hankamer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hankamer, Texas"
    },
    {
      "city": "Mont Belvieu",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mont Belvieu, Texas"
    },
    {
      "city": "Wallisville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wallisville, Texas"
    },
    {
      "city": "Stowell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stowell, Texas"
    },
    {
      "city": "Winnie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Winnie, Texas"
    },
    {
      "city": "Cuney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cuney, Texas"
    },
    {
      "city": "Gallatin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gallatin, Texas"
    },
    {
      "city": "Jacksonville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jacksonville, Texas"
    },
    {
      "city": "Maydelle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maydelle, Texas"
    },
    {
      "city": "New Summerfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Summerfield, Texas"
    },
    {
      "city": "Reklaw",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Reklaw, Texas"
    },
    {
      "city": "Rusk",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rusk, Texas"
    },
    {
      "city": "Alto",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alto, Texas"
    },
    {
      "city": "Wells",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wells, Texas"
    },
    {
      "city": "Childress",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Childress, Texas"
    },
    {
      "city": "Tell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tell, Texas"
    },
    {
      "city": "Bellevue",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bellevue, Texas"
    },
    {
      "city": "Bluegrove",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bluegrove, Texas"
    },
    {
      "city": "Byers",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Byers, Texas"
    },
    {
      "city": "Henrietta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Henrietta, Texas"
    },
    {
      "city": "Petrolia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Petrolia, Texas"
    },
    {
      "city": "Bledsoe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bledsoe, Texas"
    },
    {
      "city": "Morton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Morton, Texas"
    },
    {
      "city": "Whiteface",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whiteface, Texas"
    },
    {
      "city": "Bronte",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bronte, Texas"
    },
    {
      "city": "Robert Lee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Robert Lee, Texas"
    },
    {
      "city": "Silver",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Silver, Texas"
    },
    {
      "city": "Tennyson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tennyson, Texas"
    },
    {
      "city": "Burkett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burkett, Texas"
    },
    {
      "city": "Coleman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coleman, Texas"
    },
    {
      "city": "Gouldbusk",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gouldbusk, Texas"
    },
    {
      "city": "Rockwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rockwood, Texas"
    },
    {
      "city": "Santa Anna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santa Anna, Texas"
    },
    {
      "city": "Talpa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Talpa, Texas"
    },
    {
      "city": "Valera",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Valera, Texas"
    },
    {
      "city": "Voss",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Voss, Texas"
    },
    {
      "city": "Goldsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goldsboro, Texas"
    },
    {
      "city": "Novice",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Novice, Texas"
    },
    {
      "city": "Allen",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Allen, Texas"
    },
    {
      "city": "Celina",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Celina, Texas"
    },
    {
      "city": "Plano",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Plano, Texas"
    },
    {
      "city": "Frisco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Frisco, Texas"
    },
    {
      "city": "Mckinney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mckinney, Texas"
    },
    {
      "city": "Prosper",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Prosper, Texas"
    },
    {
      "city": "Weston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weston, Texas"
    },
    {
      "city": "Copeville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Copeville, Texas"
    },
    {
      "city": "Josephine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Josephine, Texas"
    },
    {
      "city": "Lavon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lavon, Texas"
    },
    {
      "city": "Nevada",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nevada, Texas"
    },
    {
      "city": "Princeton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Princeton, Texas"
    },
    {
      "city": "Anna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anna, Texas"
    },
    {
      "city": "Blue Ridge",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blue Ridge, Texas"
    },
    {
      "city": "Farmersville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Farmersville, Texas"
    },
    {
      "city": "Melissa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Melissa, Texas"
    },
    {
      "city": "Westminster",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Westminster, Texas"
    },
    {
      "city": "Samnorwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Samnorwood, Texas"
    },
    {
      "city": "Wellington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wellington, Texas"
    },
    {
      "city": "Dodson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dodson, Texas"
    },
    {
      "city": "Quail",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quail, Texas"
    },
    {
      "city": "Altair",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Altair, Texas"
    },
    {
      "city": "Eagle Lake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eagle Lake, Texas"
    },
    {
      "city": "Garwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Garwood, Texas"
    },
    {
      "city": "Nada",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nada, Texas"
    },
    {
      "city": "Rock Island",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rock Island, Texas"
    },
    {
      "city": "Sheridan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sheridan, Texas"
    },
    {
      "city": "Columbus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Columbus, Texas"
    },
    {
      "city": "Alleyton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alleyton, Texas"
    },
    {
      "city": "Glidden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Glidden, Texas"
    },
    {
      "city": "Oakland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oakland, Texas"
    },
    {
      "city": "Weimar",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weimar, Texas"
    },
    {
      "city": "Spring Branch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spring Branch, Texas"
    },
    {
      "city": "New Braunfels",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Braunfels, Texas"
    },
    {
      "city": "Canyon Lake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Canyon Lake, Texas"
    },
    {
      "city": "Bulverde",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bulverde, Texas"
    },
    {
      "city": "Fischer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fischer, Texas"
    },
    {
      "city": "Comanche",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Comanche, Texas"
    },
    {
      "city": "De Leon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "De Leon, Texas"
    },
    {
      "city": "Energy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Energy, Texas"
    },
    {
      "city": "Gustine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gustine, Texas"
    },
    {
      "city": "Proctor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Proctor, Texas"
    },
    {
      "city": "Sidney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sidney, Texas"
    },
    {
      "city": "Eden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eden, Texas"
    },
    {
      "city": "Lowake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lowake, Texas"
    },
    {
      "city": "Millersview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Millersview, Texas"
    },
    {
      "city": "Paint Rock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paint Rock, Texas"
    },
    {
      "city": "Eola",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eola, Texas"
    },
    {
      "city": "Era",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Era, Texas"
    },
    {
      "city": "Gainesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gainesville, Texas"
    },
    {
      "city": "Lindsay",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lindsay, Texas"
    },
    {
      "city": "Muenster",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Muenster, Texas"
    },
    {
      "city": "Myra",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Myra, Texas"
    },
    {
      "city": "Rosston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosston, Texas"
    },
    {
      "city": "Valley View",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Valley View, Texas"
    },
    {
      "city": "Copperas Cove",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Copperas Cove, Texas"
    },
    {
      "city": "Evant",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Evant, Texas"
    },
    {
      "city": "Flat",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flat, Texas"
    },
    {
      "city": "Gatesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gatesville, Texas"
    },
    {
      "city": "Jonesboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jonesboro, Texas"
    },
    {
      "city": "Mound",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mound, Texas"
    },
    {
      "city": "Oglesby",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oglesby, Texas"
    },
    {
      "city": "Purmela",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Purmela, Texas"
    },
    {
      "city": "Cee Vee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cee Vee, Texas"
    },
    {
      "city": "Paducah",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paducah, Texas"
    },
    {
      "city": "Crane",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crane, Texas"
    },
    {
      "city": "Ozona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ozona, Texas"
    },
    {
      "city": "Crosbyton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crosbyton, Texas"
    },
    {
      "city": "Lorenzo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lorenzo, Texas"
    },
    {
      "city": "Ralls",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ralls, Texas"
    },
    {
      "city": "Van Horn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Van Horn, Texas"
    },
    {
      "city": "Dalhart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dalhart, Texas"
    },
    {
      "city": "Kerrick",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kerrick, Texas"
    },
    {
      "city": "Texline",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Texline, Texas"
    },
    {
      "city": "Addison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Addison, Texas"
    },
    {
      "city": "Carrollton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carrollton, Texas"
    },
    {
      "city": "Irving",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Irving, Texas"
    },
    {
      "city": "Coppell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coppell, Texas"
    },
    {
      "city": "Rowlett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rowlett, Texas"
    },
    {
      "city": "Garland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Garland, Texas"
    },
    {
      "city": "Sachse",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sachse, Texas"
    },
    {
      "city": "Grand Prairie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grand Prairie, Texas"
    },
    {
      "city": "Richardson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Richardson, Texas"
    },
    {
      "city": "Wylie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wylie, Texas"
    },
    {
      "city": "Cedar Hill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cedar Hill, Texas"
    },
    {
      "city": "Desoto",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Desoto, Texas"
    },
    {
      "city": "Duncanville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Duncanville, Texas"
    },
    {
      "city": "Lancaster",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lancaster, Texas"
    },
    {
      "city": "Hutchins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hutchins, Texas"
    },
    {
      "city": "Mesquite",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mesquite, Texas"
    },
    {
      "city": "Seagoville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seagoville, Texas"
    },
    {
      "city": "Wilmer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wilmer, Texas"
    },
    {
      "city": "Balch Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Balch Springs, Texas"
    },
    {
      "city": "Sunnyvale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sunnyvale, Texas"
    },
    {
      "city": "Dallas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dallas, Texas"
    },
    {
      "city": "Lamesa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lamesa, Texas"
    },
    {
      "city": "Welch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Welch, Texas"
    },
    {
      "city": "Ackerly",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ackerly, Texas"
    },
    {
      "city": "Dawn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dawn, Texas"
    },
    {
      "city": "Hereford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hereford, Texas"
    },
    {
      "city": "Ben Franklin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ben Franklin, Texas"
    },
    {
      "city": "Cooper",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cooper, Texas"
    },
    {
      "city": "Enloe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Enloe, Texas"
    },
    {
      "city": "Klondike",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Klondike, Texas"
    },
    {
      "city": "Lake Creek",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lake Creek, Texas"
    },
    {
      "city": "Pecan Gap",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pecan Gap, Texas"
    },
    {
      "city": "Flower Mound",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flower Mound, Texas"
    },
    {
      "city": "Lewisville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lewisville, Texas"
    },
    {
      "city": "The Colony",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "The Colony, Texas"
    },
    {
      "city": "Lake Dallas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lake Dallas, Texas"
    },
    {
      "city": "Little Elm",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Little Elm, Texas"
    },
    {
      "city": "Denton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Denton, Texas"
    },
    {
      "city": "Argyle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Argyle, Texas"
    },
    {
      "city": "Aubrey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aubrey, Texas"
    },
    {
      "city": "Justin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Justin, Texas"
    },
    {
      "city": "Krum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Krum, Texas"
    },
    {
      "city": "Pilot Point",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pilot Point, Texas"
    },
    {
      "city": "Ponder",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ponder, Texas"
    },
    {
      "city": "Roanoke",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roanoke, Texas"
    },
    {
      "city": "Sanger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sanger, Texas"
    },
    {
      "city": "Cuero",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cuero, Texas"
    },
    {
      "city": "Hochheim",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hochheim, Texas"
    },
    {
      "city": "Meyersville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Meyersville, Texas"
    },
    {
      "city": "Thomaston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thomaston, Texas"
    },
    {
      "city": "Westhoff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Westhoff, Texas"
    },
    {
      "city": "Nordheim",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nordheim, Texas"
    },
    {
      "city": "Yorktown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Yorktown, Texas"
    },
    {
      "city": "Afton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Afton, Texas"
    },
    {
      "city": "Dickens",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dickens, Texas"
    },
    {
      "city": "Mcadoo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mcadoo, Texas"
    },
    {
      "city": "Spur",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spur, Texas"
    },
    {
      "city": "Asherton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Asherton, Texas"
    },
    {
      "city": "Big Wells",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Big Wells, Texas"
    },
    {
      "city": "Carrizo Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carrizo Springs, Texas"
    },
    {
      "city": "Catarina",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Catarina, Texas"
    },
    {
      "city": "Clarendon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clarendon, Texas"
    },
    {
      "city": "Hedley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hedley, Texas"
    },
    {
      "city": "Lelia Lake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lelia Lake, Texas"
    },
    {
      "city": "Benavides",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Benavides, Texas"
    },
    {
      "city": "Concepcion",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Concepcion, Texas"
    },
    {
      "city": "Freer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Freer, Texas"
    },
    {
      "city": "Realitos",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Realitos, Texas"
    },
    {
      "city": "San Diego",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Diego, Texas"
    },
    {
      "city": "Carbon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carbon, Texas"
    },
    {
      "city": "Cisco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cisco, Texas"
    },
    {
      "city": "Desdemona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Desdemona, Texas"
    },
    {
      "city": "Eastland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eastland, Texas"
    },
    {
      "city": "Gorman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gorman, Texas"
    },
    {
      "city": "Olden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Olden, Texas"
    },
    {
      "city": "Ranger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ranger, Texas"
    },
    {
      "city": "Rising Star",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rising Star, Texas"
    },
    {
      "city": "Goldsmith",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goldsmith, Texas"
    },
    {
      "city": "Gardendale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gardendale, Texas"
    },
    {
      "city": "Notrees",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Notrees, Texas"
    },
    {
      "city": "Odessa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Odessa, Texas"
    },
    {
      "city": "Penwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Penwell, Texas"
    },
    {
      "city": "Telegraph",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Telegraph, Texas"
    },
    {
      "city": "Barksdale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Barksdale, Texas"
    },
    {
      "city": "Rocksprings",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rocksprings, Texas"
    },
    {
      "city": "Bardwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bardwell, Texas"
    },
    {
      "city": "Ennis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ennis, Texas"
    },
    {
      "city": "Ferris",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ferris, Texas"
    },
    {
      "city": "Palmer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Palmer, Texas"
    },
    {
      "city": "Red Oak",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Red Oak, Texas"
    },
    {
      "city": "Rice",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rice, Texas"
    },
    {
      "city": "Waxahachie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waxahachie, Texas"
    },
    {
      "city": "Forreston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Forreston, Texas"
    },
    {
      "city": "Maypearl",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maypearl, Texas"
    },
    {
      "city": "Midlothian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Midlothian, Texas"
    },
    {
      "city": "Avalon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Avalon, Texas"
    },
    {
      "city": "Italy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Italy, Texas"
    },
    {
      "city": "Milford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Milford, Texas"
    },
    {
      "city": "Anthony",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anthony, Texas"
    },
    {
      "city": "Canutillo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Canutillo, Texas"
    },
    {
      "city": "Clint",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clint, Texas"
    },
    {
      "city": "Fabens",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fabens, Texas"
    },
    {
      "city": "San Elizario",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Elizario, Texas"
    },
    {
      "city": "Tornillo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tornillo, Texas"
    },
    {
      "city": "El Paso",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "El Paso, Texas"
    },
    {
      "city": "Fort Bliss",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Bliss, Texas"
    },
    {
      "city": "Stephenville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stephenville, Texas"
    },
    {
      "city": "Bluff Dale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bluff Dale, Texas"
    },
    {
      "city": "Dublin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dublin, Texas"
    },
    {
      "city": "Lingleville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lingleville, Texas"
    },
    {
      "city": "Morgan Mill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Morgan Mill, Texas"
    },
    {
      "city": "Rosebud",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosebud, Texas"
    },
    {
      "city": "Chilton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chilton, Texas"
    },
    {
      "city": "Lott",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lott, Texas"
    },
    {
      "city": "Marlin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marlin, Texas"
    },
    {
      "city": "Reagan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Reagan, Texas"
    },
    {
      "city": "Satin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Satin, Texas"
    },
    {
      "city": "Bailey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bailey, Texas"
    },
    {
      "city": "Bonham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bonham, Texas"
    },
    {
      "city": "Dodd City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dodd City, Texas"
    },
    {
      "city": "Ector",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ector, Texas"
    },
    {
      "city": "Gober",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gober, Texas"
    },
    {
      "city": "Honey Grove",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Honey Grove, Texas"
    },
    {
      "city": "Ivanhoe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ivanhoe, Texas"
    },
    {
      "city": "Ladonia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ladonia, Texas"
    },
    {
      "city": "Leonard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leonard, Texas"
    },
    {
      "city": "Randolph",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Randolph, Texas"
    },
    {
      "city": "Ravenna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ravenna, Texas"
    },
    {
      "city": "Savoy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Savoy, Texas"
    },
    {
      "city": "Telephone",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Telephone, Texas"
    },
    {
      "city": "Trenton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Trenton, Texas"
    },
    {
      "city": "Windom",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Windom, Texas"
    },
    {
      "city": "Carmine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carmine, Texas"
    },
    {
      "city": "Ellinger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ellinger, Texas"
    },
    {
      "city": "Fayetteville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fayetteville, Texas"
    },
    {
      "city": "Flatonia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flatonia, Texas"
    },
    {
      "city": "La Grange",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Grange, Texas"
    },
    {
      "city": "Ledbetter",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ledbetter, Texas"
    },
    {
      "city": "Muldoon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Muldoon, Texas"
    },
    {
      "city": "Plum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Plum, Texas"
    },
    {
      "city": "Round Top",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Round Top, Texas"
    },
    {
      "city": "Schulenburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Schulenburg, Texas"
    },
    {
      "city": "Warda",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Warda, Texas"
    },
    {
      "city": "West Point",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "West Point, Texas"
    },
    {
      "city": "Mc Caulley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Caulley, Texas"
    },
    {
      "city": "Roby",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roby, Texas"
    },
    {
      "city": "Rotan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rotan, Texas"
    },
    {
      "city": "Sylvester",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sylvester, Texas"
    },
    {
      "city": "Aiken",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aiken, Texas"
    },
    {
      "city": "Dougherty",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dougherty, Texas"
    },
    {
      "city": "Floydada",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Floydada, Texas"
    },
    {
      "city": "Lockney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lockney, Texas"
    },
    {
      "city": "South Plains",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "South Plains, Texas"
    },
    {
      "city": "Crowell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crowell, Texas"
    },
    {
      "city": "Richmond",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Richmond, Texas"
    },
    {
      "city": "Beasley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Beasley, Texas"
    },
    {
      "city": "Fulshear",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fulshear, Texas"
    },
    {
      "city": "Guy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Guy, Texas"
    },
    {
      "city": "Kendleton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kendleton, Texas"
    },
    {
      "city": "Missouri City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Missouri City, Texas"
    },
    {
      "city": "Needville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Needville, Texas"
    },
    {
      "city": "Orchard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Orchard, Texas"
    },
    {
      "city": "Rosenberg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosenberg, Texas"
    },
    {
      "city": "Simonton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Simonton, Texas"
    },
    {
      "city": "Stafford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stafford, Texas"
    },
    {
      "city": "Sugar Land",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sugar Land, Texas"
    },
    {
      "city": "Thompsons",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thompsons, Texas"
    },
    {
      "city": "Fresno",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fresno, Texas"
    },
    {
      "city": "Mount Vernon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mount Vernon, Texas"
    },
    {
      "city": "Scroggins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Scroggins, Texas"
    },
    {
      "city": "Talco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Talco, Texas"
    },
    {
      "city": "Donie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Donie, Texas"
    },
    {
      "city": "Fairfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fairfield, Texas"
    },
    {
      "city": "Kirvin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kirvin, Texas"
    },
    {
      "city": "Streetman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Streetman, Texas"
    },
    {
      "city": "Teague",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Teague, Texas"
    },
    {
      "city": "Wortham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wortham, Texas"
    },
    {
      "city": "Bigfoot",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bigfoot, Texas"
    },
    {
      "city": "Dilley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dilley, Texas"
    },
    {
      "city": "Moore",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Moore, Texas"
    },
    {
      "city": "Pearsall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pearsall, Texas"
    },
    {
      "city": "Loop",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Loop, Texas"
    },
    {
      "city": "Seagraves",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seagraves, Texas"
    },
    {
      "city": "Seminole",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seminole, Texas"
    },
    {
      "city": "Santa Fe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santa Fe, Texas"
    },
    {
      "city": "Bacliff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bacliff, Texas"
    },
    {
      "city": "Dickinson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dickinson, Texas"
    },
    {
      "city": "Friendswood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Friendswood, Texas"
    },
    {
      "city": "Galveston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Galveston, Texas"
    },
    {
      "city": "Hitchcock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hitchcock, Texas"
    },
    {
      "city": "Kemah",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kemah, Texas"
    },
    {
      "city": "La Marque",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Marque, Texas"
    },
    {
      "city": "League City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "League City, Texas"
    },
    {
      "city": "Texas City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Texas City, Texas"
    },
    {
      "city": "Gilchrist",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gilchrist, Texas"
    },
    {
      "city": "High Island",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "High Island, Texas"
    },
    {
      "city": "Port Bolivar",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Bolivar, Texas"
    },
    {
      "city": "Justiceburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Justiceburg, Texas"
    },
    {
      "city": "Post",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Post, Texas"
    },
    {
      "city": "Doss",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Doss, Texas"
    },
    {
      "city": "Fredericksburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fredericksburg, Texas"
    },
    {
      "city": "Harper",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harper, Texas"
    },
    {
      "city": "Stonewall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stonewall, Texas"
    },
    {
      "city": "Willow City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Willow City, Texas"
    },
    {
      "city": "Garden City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Garden City, Texas"
    },
    {
      "city": "Fannin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fannin, Texas"
    },
    {
      "city": "Goliad",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goliad, Texas"
    },
    {
      "city": "Weesatche",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weesatche, Texas"
    },
    {
      "city": "Berclair",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Berclair, Texas"
    },
    {
      "city": "Leesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leesville, Texas"
    },
    {
      "city": "Nixon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nixon, Texas"
    },
    {
      "city": "Smiley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Smiley, Texas"
    },
    {
      "city": "Belmont",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Belmont, Texas"
    },
    {
      "city": "Cost",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cost, Texas"
    },
    {
      "city": "Gonzales",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gonzales, Texas"
    },
    {
      "city": "Harwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harwood, Texas"
    },
    {
      "city": "Ottine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ottine, Texas"
    },
    {
      "city": "Wrightsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wrightsboro, Texas"
    },
    {
      "city": "Waelder",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waelder, Texas"
    },
    {
      "city": "Alanreed",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alanreed, Texas"
    },
    {
      "city": "Lefors",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lefors, Texas"
    },
    {
      "city": "Mclean",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mclean, Texas"
    },
    {
      "city": "Pampa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pampa, Texas"
    },
    {
      "city": "Denison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Denison, Texas"
    },
    {
      "city": "Gunter",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gunter, Texas"
    },
    {
      "city": "Pottsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pottsboro, Texas"
    },
    {
      "city": "Sherman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sherman, Texas"
    },
    {
      "city": "Bells",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bells, Texas"
    },
    {
      "city": "Howe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Howe, Texas"
    },
    {
      "city": "Tom Bean",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tom Bean, Texas"
    },
    {
      "city": "Whitewright",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitewright, Texas"
    },
    {
      "city": "Van Alstyne",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Van Alstyne, Texas"
    },
    {
      "city": "Collinsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Collinsville, Texas"
    },
    {
      "city": "Gordonville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gordonville, Texas"
    },
    {
      "city": "Sadler",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sadler, Texas"
    },
    {
      "city": "Southmayd",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Southmayd, Texas"
    },
    {
      "city": "Tioga",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tioga, Texas"
    },
    {
      "city": "Whitesboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitesboro, Texas"
    },
    {
      "city": "Longview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Longview, Texas"
    },
    {
      "city": "Easton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Easton, Texas"
    },
    {
      "city": "Gladewater",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gladewater, Texas"
    },
    {
      "city": "Judson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Judson, Texas"
    },
    {
      "city": "Kilgore",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kilgore, Texas"
    },
    {
      "city": "White Oak",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "White Oak, Texas"
    },
    {
      "city": "Plantersville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Plantersville, Texas"
    },
    {
      "city": "Anderson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anderson, Texas"
    },
    {
      "city": "Bedias",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bedias, Texas"
    },
    {
      "city": "Iola",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Iola, Texas"
    },
    {
      "city": "Navasota",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Navasota, Texas"
    },
    {
      "city": "Richards",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Richards, Texas"
    },
    {
      "city": "Roans Prairie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roans Prairie, Texas"
    },
    {
      "city": "Shiro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shiro, Texas"
    },
    {
      "city": "Cibolo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cibolo, Texas"
    },
    {
      "city": "Geronimo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Geronimo, Texas"
    },
    {
      "city": "Mc Queeney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Queeney, Texas"
    },
    {
      "city": "Marion",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marion, Texas"
    },
    {
      "city": "Schertz",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Schertz, Texas"
    },
    {
      "city": "Seguin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seguin, Texas"
    },
    {
      "city": "Kingsbury",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kingsbury, Texas"
    },
    {
      "city": "Staples",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Staples, Texas"
    },
    {
      "city": "Cotton Center",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cotton Center, Texas"
    },
    {
      "city": "Edmonson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edmonson, Texas"
    },
    {
      "city": "Hale Center",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hale Center, Texas"
    },
    {
      "city": "Plainview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Plainview, Texas"
    },
    {
      "city": "Petersburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Petersburg, Texas"
    },
    {
      "city": "Abernathy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Abernathy, Texas"
    },
    {
      "city": "Estelline",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Estelline, Texas"
    },
    {
      "city": "Lakeview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lakeview, Texas"
    },
    {
      "city": "Memphis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Memphis, Texas"
    },
    {
      "city": "Turkey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Turkey, Texas"
    },
    {
      "city": "Carlton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carlton, Texas"
    },
    {
      "city": "Hico",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hico, Texas"
    },
    {
      "city": "Hamilton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hamilton, Texas"
    },
    {
      "city": "Pottsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pottsville, Texas"
    },
    {
      "city": "Gruver",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gruver, Texas"
    },
    {
      "city": "Morse",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Morse, Texas"
    },
    {
      "city": "Spearman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spearman, Texas"
    },
    {
      "city": "Chillicothe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chillicothe, Texas"
    },
    {
      "city": "Quanah",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quanah, Texas"
    },
    {
      "city": "Thicket",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thicket, Texas"
    },
    {
      "city": "Votaw",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Votaw, Texas"
    },
    {
      "city": "Batson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Batson, Texas"
    },
    {
      "city": "Saratoga",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Saratoga, Texas"
    },
    {
      "city": "Kountze",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kountze, Texas"
    },
    {
      "city": "Silsbee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Silsbee, Texas"
    },
    {
      "city": "Lumberton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lumberton, Texas"
    },
    {
      "city": "Sour Lake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sour Lake, Texas"
    },
    {
      "city": "Village Mills",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Village Mills, Texas"
    },
    {
      "city": "Houston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Houston, Texas"
    },
    {
      "city": "North Houston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "North Houston, Texas"
    },
    {
      "city": "Kingwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kingwood, Texas"
    },
    {
      "city": "Huffman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Huffman, Texas"
    },
    {
      "city": "Hufsmith",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hufsmith, Texas"
    },
    {
      "city": "Humble",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Humble, Texas"
    },
    {
      "city": "Spring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spring, Texas"
    },
    {
      "city": "Tomball",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tomball, Texas"
    },
    {
      "city": "Bellaire",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bellaire, Texas"
    },
    {
      "city": "Cypress",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cypress, Texas"
    },
    {
      "city": "Alief",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alief, Texas"
    },
    {
      "city": "Barker",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Barker, Texas"
    },
    {
      "city": "Hockley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hockley, Texas"
    },
    {
      "city": "Katy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Katy, Texas"
    },
    {
      "city": "Pasadena",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pasadena, Texas"
    },
    {
      "city": "Channelview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Channelview, Texas"
    },
    {
      "city": "Crosby",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crosby, Texas"
    },
    {
      "city": "Deer Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Deer Park, Texas"
    },
    {
      "city": "Galena Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Galena Park, Texas"
    },
    {
      "city": "Highlands",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Highlands, Texas"
    },
    {
      "city": "La Porte",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Porte, Texas"
    },
    {
      "city": "Seabrook",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Seabrook, Texas"
    },
    {
      "city": "South Houston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "South Houston, Texas"
    },
    {
      "city": "Webster",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Webster, Texas"
    },
    {
      "city": "Elysian Fields",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elysian Fields, Texas"
    },
    {
      "city": "Hallsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hallsville, Texas"
    },
    {
      "city": "Harleton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harleton, Texas"
    },
    {
      "city": "Jonesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jonesville, Texas"
    },
    {
      "city": "Karnack",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Karnack, Texas"
    },
    {
      "city": "Marshall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marshall, Texas"
    },
    {
      "city": "Scottsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Scottsville, Texas"
    },
    {
      "city": "Waskom",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waskom, Texas"
    },
    {
      "city": "Woodlawn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodlawn, Texas"
    },
    {
      "city": "Channing",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Channing, Texas"
    },
    {
      "city": "Hartley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hartley, Texas"
    },
    {
      "city": "Weinert",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weinert, Texas"
    },
    {
      "city": "Haskell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Haskell, Texas"
    },
    {
      "city": "O Brien",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "O Brien, Texas"
    },
    {
      "city": "Rochester",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rochester, Texas"
    },
    {
      "city": "Rule",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rule, Texas"
    },
    {
      "city": "Buda",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buda, Texas"
    },
    {
      "city": "Driftwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Driftwood, Texas"
    },
    {
      "city": "Dripping Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dripping Springs, Texas"
    },
    {
      "city": "Kyle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kyle, Texas"
    },
    {
      "city": "San Marcos",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Marcos, Texas"
    },
    {
      "city": "Wimberley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wimberley, Texas"
    },
    {
      "city": "Canadian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Canadian, Texas"
    },
    {
      "city": "Eustace",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eustace, Texas"
    },
    {
      "city": "Kemp",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kemp, Texas"
    },
    {
      "city": "Malakoff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Malakoff, Texas"
    },
    {
      "city": "Trinidad",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Trinidad, Texas"
    },
    {
      "city": "Athens",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Athens, Texas"
    },
    {
      "city": "Brownsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brownsboro, Texas"
    },
    {
      "city": "Chandler",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chandler, Texas"
    },
    {
      "city": "Larue",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Larue, Texas"
    },
    {
      "city": "Murchison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Murchison, Texas"
    },
    {
      "city": "Poynor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Poynor, Texas"
    },
    {
      "city": "Mcallen",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mcallen, Texas"
    },
    {
      "city": "Alamo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alamo, Texas"
    },
    {
      "city": "Donna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Donna, Texas"
    },
    {
      "city": "Edcouch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edcouch, Texas"
    },
    {
      "city": "Edinburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edinburg, Texas"
    },
    {
      "city": "Elsa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elsa, Texas"
    },
    {
      "city": "Hargill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hargill, Texas"
    },
    {
      "city": "Hidalgo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hidalgo, Texas"
    },
    {
      "city": "La Blanca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Blanca, Texas"
    },
    {
      "city": "La Joya",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Joya, Texas"
    },
    {
      "city": "La Villa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Villa, Texas"
    },
    {
      "city": "Linn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Linn, Texas"
    },
    {
      "city": "Los Ebanos",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Los Ebanos, Texas"
    },
    {
      "city": "Mercedes",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mercedes, Texas"
    },
    {
      "city": "Mission",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mission, Texas"
    },
    {
      "city": "Penitas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Penitas, Texas"
    },
    {
      "city": "Pharr",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pharr, Texas"
    },
    {
      "city": "Progreso",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Progreso, Texas"
    },
    {
      "city": "San Juan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Juan, Texas"
    },
    {
      "city": "Sullivan City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sullivan City, Texas"
    },
    {
      "city": "Weslaco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weslaco, Texas"
    },
    {
      "city": "Itasca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Itasca, Texas"
    },
    {
      "city": "Abbott",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Abbott, Texas"
    },
    {
      "city": "Aquilla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aquilla, Texas"
    },
    {
      "city": "Blum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blum, Texas"
    },
    {
      "city": "Brandon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brandon, Texas"
    },
    {
      "city": "Bynum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bynum, Texas"
    },
    {
      "city": "Covington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Covington, Texas"
    },
    {
      "city": "Hillsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hillsboro, Texas"
    },
    {
      "city": "Hubbard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hubbard, Texas"
    },
    {
      "city": "Irene",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Irene, Texas"
    },
    {
      "city": "Malone",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Malone, Texas"
    },
    {
      "city": "Mertens",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mertens, Texas"
    },
    {
      "city": "Mount Calm",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mount Calm, Texas"
    },
    {
      "city": "Penelope",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Penelope, Texas"
    },
    {
      "city": "Whitney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitney, Texas"
    },
    {
      "city": "Anton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anton, Texas"
    },
    {
      "city": "Levelland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Levelland, Texas"
    },
    {
      "city": "Pep",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pep, Texas"
    },
    {
      "city": "Ropesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ropesville, Texas"
    },
    {
      "city": "Smyer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Smyer, Texas"
    },
    {
      "city": "Sundown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sundown, Texas"
    },
    {
      "city": "Whitharral",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitharral, Texas"
    },
    {
      "city": "Cresson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cresson, Texas"
    },
    {
      "city": "Granbury",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Granbury, Texas"
    },
    {
      "city": "Lipan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lipan, Texas"
    },
    {
      "city": "Paluxy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paluxy, Texas"
    },
    {
      "city": "Tolar",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tolar, Texas"
    },
    {
      "city": "Brashear",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brashear, Texas"
    },
    {
      "city": "Como",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Como, Texas"
    },
    {
      "city": "Cumby",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cumby, Texas"
    },
    {
      "city": "Dike",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dike, Texas"
    },
    {
      "city": "Pickton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pickton, Texas"
    },
    {
      "city": "Saltillo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Saltillo, Texas"
    },
    {
      "city": "Sulphur Bluff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sulphur Bluff, Texas"
    },
    {
      "city": "Sulphur Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sulphur Springs, Texas"
    },
    {
      "city": "Crockett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crockett, Texas"
    },
    {
      "city": "Grapeland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grapeland, Texas"
    },
    {
      "city": "Kennard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kennard, Texas"
    },
    {
      "city": "Latexo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Latexo, Texas"
    },
    {
      "city": "Lovelady",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lovelady, Texas"
    },
    {
      "city": "Ratcliff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ratcliff, Texas"
    },
    {
      "city": "Coahoma",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coahoma, Texas"
    },
    {
      "city": "Big Spring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Big Spring, Texas"
    },
    {
      "city": "Forsan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Forsan, Texas"
    },
    {
      "city": "Knott",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Knott, Texas"
    },
    {
      "city": "Dell City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dell City, Texas"
    },
    {
      "city": "Fort Hancock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Hancock, Texas"
    },
    {
      "city": "Salt Flat",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Salt Flat, Texas"
    },
    {
      "city": "Sierra Blanca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sierra Blanca, Texas"
    },
    {
      "city": "Caddo Mills",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Caddo Mills, Texas"
    },
    {
      "city": "Greenville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Greenville, Texas"
    },
    {
      "city": "Campbell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Campbell, Texas"
    },
    {
      "city": "Celeste",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Celeste, Texas"
    },
    {
      "city": "Commerce",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Commerce, Texas"
    },
    {
      "city": "Lone Oak",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lone Oak, Texas"
    },
    {
      "city": "Merit",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Merit, Texas"
    },
    {
      "city": "Quinlan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quinlan, Texas"
    },
    {
      "city": "Wolfe City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wolfe City, Texas"
    },
    {
      "city": "Borger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Borger, Texas"
    },
    {
      "city": "Fritch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fritch, Texas"
    },
    {
      "city": "Sanford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sanford, Texas"
    },
    {
      "city": "Stinnett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stinnett, Texas"
    },
    {
      "city": "Barnhart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Barnhart, Texas"
    },
    {
      "city": "Mertzon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mertzon, Texas"
    },
    {
      "city": "Bryson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bryson, Texas"
    },
    {
      "city": "Jacksboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jacksboro, Texas"
    },
    {
      "city": "Jermyn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jermyn, Texas"
    },
    {
      "city": "Perrin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Perrin, Texas"
    },
    {
      "city": "Edna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edna, Texas"
    },
    {
      "city": "Francitas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Francitas, Texas"
    },
    {
      "city": "Ganado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ganado, Texas"
    },
    {
      "city": "La Salle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Salle, Texas"
    },
    {
      "city": "La Ward",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Ward, Texas"
    },
    {
      "city": "Lolita",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lolita, Texas"
    },
    {
      "city": "Vanderbilt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vanderbilt, Texas"
    },
    {
      "city": "Jasper",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jasper, Texas"
    },
    {
      "city": "Kirbyville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kirbyville, Texas"
    },
    {
      "city": "Buna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buna, Texas"
    },
    {
      "city": "Evadale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Evadale, Texas"
    },
    {
      "city": "Fort Davis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Davis, Texas"
    },
    {
      "city": "Valentine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Valentine, Texas"
    },
    {
      "city": "China",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "China, Texas"
    },
    {
      "city": "Groves",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Groves, Texas"
    },
    {
      "city": "Hamshire",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hamshire, Texas"
    },
    {
      "city": "Nederland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nederland, Texas"
    },
    {
      "city": "Nome",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nome, Texas"
    },
    {
      "city": "Port Arthur",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Arthur, Texas"
    },
    {
      "city": "Port Neches",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Neches, Texas"
    },
    {
      "city": "Sabine Pass",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sabine Pass, Texas"
    },
    {
      "city": "Beaumont",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Beaumont, Texas"
    },
    {
      "city": "Guerra",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Guerra, Texas"
    },
    {
      "city": "Hebbronville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hebbronville, Texas"
    },
    {
      "city": "Alice",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alice, Texas"
    },
    {
      "city": "Ben Bolt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ben Bolt, Texas"
    },
    {
      "city": "Orange Grove",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Orange Grove, Texas"
    },
    {
      "city": "Premont",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Premont, Texas"
    },
    {
      "city": "Sandia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sandia, Texas"
    },
    {
      "city": "Alvarado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alvarado, Texas"
    },
    {
      "city": "Burleson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burleson, Texas"
    },
    {
      "city": "Cleburne",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cleburne, Texas"
    },
    {
      "city": "Godley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Godley, Texas"
    },
    {
      "city": "Grandview",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grandview, Texas"
    },
    {
      "city": "Joshua",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Joshua, Texas"
    },
    {
      "city": "Keene",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Keene, Texas"
    },
    {
      "city": "Lillian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lillian, Texas"
    },
    {
      "city": "Venus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Venus, Texas"
    },
    {
      "city": "Rio Vista",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rio Vista, Texas"
    },
    {
      "city": "Anson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Anson, Texas"
    },
    {
      "city": "Avoca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Avoca, Texas"
    },
    {
      "city": "Hamlin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hamlin, Texas"
    },
    {
      "city": "Hawley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hawley, Texas"
    },
    {
      "city": "Lueders",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lueders, Texas"
    },
    {
      "city": "Stamford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stamford, Texas"
    },
    {
      "city": "Ecleto",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ecleto, Texas"
    },
    {
      "city": "Falls City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Falls City, Texas"
    },
    {
      "city": "Gillett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gillett, Texas"
    },
    {
      "city": "Hobson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hobson, Texas"
    },
    {
      "city": "Karnes City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Karnes City, Texas"
    },
    {
      "city": "Kenedy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kenedy, Texas"
    },
    {
      "city": "Panna Maria",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Panna Maria, Texas"
    },
    {
      "city": "Runge",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Runge, Texas"
    },
    {
      "city": "Crandall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crandall, Texas"
    },
    {
      "city": "Elmo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elmo, Texas"
    },
    {
      "city": "Forney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Forney, Texas"
    },
    {
      "city": "Kaufman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kaufman, Texas"
    },
    {
      "city": "Mabank",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mabank, Texas"
    },
    {
      "city": "Rosser",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rosser, Texas"
    },
    {
      "city": "Scurry",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Scurry, Texas"
    },
    {
      "city": "Terrell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Terrell, Texas"
    },
    {
      "city": "Bergheim",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bergheim, Texas"
    },
    {
      "city": "Comfort",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Comfort, Texas"
    },
    {
      "city": "Kendalia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kendalia, Texas"
    },
    {
      "city": "Waring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waring, Texas"
    },
    {
      "city": "Armstrong",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Armstrong, Texas"
    },
    {
      "city": "Sarita",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sarita, Texas"
    },
    {
      "city": "Girard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Girard, Texas"
    },
    {
      "city": "Jayton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jayton, Texas"
    },
    {
      "city": "Center Point",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Center Point, Texas"
    },
    {
      "city": "Hunt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hunt, Texas"
    },
    {
      "city": "Ingram",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ingram, Texas"
    },
    {
      "city": "Kerrville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kerrville, Texas"
    },
    {
      "city": "Mountain Home",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mountain Home, Texas"
    },
    {
      "city": "Junction",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Junction, Texas"
    },
    {
      "city": "London",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "London, Texas"
    },
    {
      "city": "Roosevelt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roosevelt, Texas"
    },
    {
      "city": "Guthrie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Guthrie, Texas"
    },
    {
      "city": "Brackettville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brackettville, Texas"
    },
    {
      "city": "Kingsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kingsville, Texas"
    },
    {
      "city": "Riviera",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Riviera, Texas"
    },
    {
      "city": "Goree",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goree, Texas"
    },
    {
      "city": "Munday",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Munday, Texas"
    },
    {
      "city": "Benjamin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Benjamin, Texas"
    },
    {
      "city": "Knox City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Knox City, Texas"
    },
    {
      "city": "Arthur City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Arthur City, Texas"
    },
    {
      "city": "Blossom",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blossom, Texas"
    },
    {
      "city": "Brookston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brookston, Texas"
    },
    {
      "city": "Chicota",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chicota, Texas"
    },
    {
      "city": "Cunningham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cunningham, Texas"
    },
    {
      "city": "Deport",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Deport, Texas"
    },
    {
      "city": "Paris",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paris, Texas"
    },
    {
      "city": "Pattonville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pattonville, Texas"
    },
    {
      "city": "Petty",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Petty, Texas"
    },
    {
      "city": "Powderly",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Powderly, Texas"
    },
    {
      "city": "Roxton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roxton, Texas"
    },
    {
      "city": "Sumner",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sumner, Texas"
    },
    {
      "city": "Earth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Earth, Texas"
    },
    {
      "city": "Olton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Olton, Texas"
    },
    {
      "city": "Springlake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Springlake, Texas"
    },
    {
      "city": "Amherst",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Amherst, Texas"
    },
    {
      "city": "Fieldton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fieldton, Texas"
    },
    {
      "city": "Littlefield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Littlefield, Texas"
    },
    {
      "city": "Spade",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spade, Texas"
    },
    {
      "city": "Sudan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sudan, Texas"
    },
    {
      "city": "Kempner",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kempner, Texas"
    },
    {
      "city": "Lampasas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lampasas, Texas"
    },
    {
      "city": "Bend",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bend, Texas"
    },
    {
      "city": "Lometa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lometa, Texas"
    },
    {
      "city": "Artesia Wells",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Artesia Wells, Texas"
    },
    {
      "city": "Cotulla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cotulla, Texas"
    },
    {
      "city": "Encinal",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Encinal, Texas"
    },
    {
      "city": "Fowlerton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fowlerton, Texas"
    },
    {
      "city": "Hallettsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hallettsville, Texas"
    },
    {
      "city": "Moulton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Moulton, Texas"
    },
    {
      "city": "Shiner",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shiner, Texas"
    },
    {
      "city": "Sublime",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sublime, Texas"
    },
    {
      "city": "Sweet Home",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sweet Home, Texas"
    },
    {
      "city": "Yoakum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Yoakum, Texas"
    },
    {
      "city": "Dime Box",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dime Box, Texas"
    },
    {
      "city": "Giddings",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Giddings, Texas"
    },
    {
      "city": "Lexington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lexington, Texas"
    },
    {
      "city": "Lincoln",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lincoln, Texas"
    },
    {
      "city": "Buffalo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buffalo, Texas"
    },
    {
      "city": "Centerville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Centerville, Texas"
    },
    {
      "city": "Jewett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jewett, Texas"
    },
    {
      "city": "Leona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leona, Texas"
    },
    {
      "city": "Oakwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oakwood, Texas"
    },
    {
      "city": "Concord",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Concord, Texas"
    },
    {
      "city": "Flynn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flynn, Texas"
    },
    {
      "city": "Marquez",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marquez, Texas"
    },
    {
      "city": "Normangee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Normangee, Texas"
    },
    {
      "city": "Cleveland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cleveland, Texas"
    },
    {
      "city": "Romayor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Romayor, Texas"
    },
    {
      "city": "Rye",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rye, Texas"
    },
    {
      "city": "Daisetta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Daisetta, Texas"
    },
    {
      "city": "Dayton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dayton, Texas"
    },
    {
      "city": "Devers",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Devers, Texas"
    },
    {
      "city": "Hardin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hardin, Texas"
    },
    {
      "city": "Hull",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hull, Texas"
    },
    {
      "city": "Liberty",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Liberty, Texas"
    },
    {
      "city": "Raywood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Raywood, Texas"
    },
    {
      "city": "Coolidge",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coolidge, Texas"
    },
    {
      "city": "Groesbeck",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Groesbeck, Texas"
    },
    {
      "city": "Kosse",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kosse, Texas"
    },
    {
      "city": "Mexia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mexia, Texas"
    },
    {
      "city": "Prairie Hill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Prairie Hill, Texas"
    },
    {
      "city": "Tehuacana",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tehuacana, Texas"
    },
    {
      "city": "Thornton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thornton, Texas"
    },
    {
      "city": "Booker",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Booker, Texas"
    },
    {
      "city": "Darrouzett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Darrouzett, Texas"
    },
    {
      "city": "Follett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Follett, Texas"
    },
    {
      "city": "Higgins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Higgins, Texas"
    },
    {
      "city": "Lipscomb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lipscomb, Texas"
    },
    {
      "city": "George West",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "George West, Texas"
    },
    {
      "city": "Oakville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oakville, Texas"
    },
    {
      "city": "Three Rivers",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Three Rivers, Texas"
    },
    {
      "city": "Whitsett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitsett, Texas"
    },
    {
      "city": "Dinero",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dinero, Texas"
    },
    {
      "city": "Castell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Castell, Texas"
    },
    {
      "city": "Valley Spring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Valley Spring, Texas"
    },
    {
      "city": "Bluffton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bluffton, Texas"
    },
    {
      "city": "Buchanan Dam",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buchanan Dam, Texas"
    },
    {
      "city": "Kingsland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kingsland, Texas"
    },
    {
      "city": "Llano",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Llano, Texas"
    },
    {
      "city": "Horseshoe Bay",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Horseshoe Bay, Texas"
    },
    {
      "city": "Tow",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tow, Texas"
    },
    {
      "city": "Mentone",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mentone, Texas"
    },
    {
      "city": "Idalou",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Idalou, Texas"
    },
    {
      "city": "New Deal",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Deal, Texas"
    },
    {
      "city": "Shallowater",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shallowater, Texas"
    },
    {
      "city": "Slaton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Slaton, Texas"
    },
    {
      "city": "Ransom Canyon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ransom Canyon, Texas"
    },
    {
      "city": "Wolfforth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wolfforth, Texas"
    },
    {
      "city": "Lubbock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lubbock, Texas"
    },
    {
      "city": "Odonnell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Odonnell, Texas"
    },
    {
      "city": "Tahoka",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tahoka, Texas"
    },
    {
      "city": "Wilson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wilson, Texas"
    },
    {
      "city": "New Home",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Home, Texas"
    },
    {
      "city": "Brady",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brady, Texas"
    },
    {
      "city": "Doole",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Doole, Texas"
    },
    {
      "city": "Lohn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lohn, Texas"
    },
    {
      "city": "Melvin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Melvin, Texas"
    },
    {
      "city": "Rochelle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rochelle, Texas"
    },
    {
      "city": "Voca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Voca, Texas"
    },
    {
      "city": "Eddy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eddy, Texas"
    },
    {
      "city": "Moody",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Moody, Texas"
    },
    {
      "city": "Axtell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Axtell, Texas"
    },
    {
      "city": "Bruceville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bruceville, Texas"
    },
    {
      "city": "China Spring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "China Spring, Texas"
    },
    {
      "city": "Crawford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crawford, Texas"
    },
    {
      "city": "Elm Mott",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elm Mott, Texas"
    },
    {
      "city": "Hewitt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hewitt, Texas"
    },
    {
      "city": "Leroy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leroy, Texas"
    },
    {
      "city": "Lorena",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lorena, Texas"
    },
    {
      "city": "Mc Gregor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Gregor, Texas"
    },
    {
      "city": "Mart",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mart, Texas"
    },
    {
      "city": "Riesel",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Riesel, Texas"
    },
    {
      "city": "Ross",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ross, Texas"
    },
    {
      "city": "West",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "West, Texas"
    },
    {
      "city": "Waco",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waco, Texas"
    },
    {
      "city": "Woodway",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodway, Texas"
    },
    {
      "city": "Calliham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Calliham, Texas"
    },
    {
      "city": "Tilden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tilden, Texas"
    },
    {
      "city": "Midway",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Midway, Texas"
    },
    {
      "city": "Madisonville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Madisonville, Texas"
    },
    {
      "city": "North Zulch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "North Zulch, Texas"
    },
    {
      "city": "Lodi",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lodi, Texas"
    },
    {
      "city": "Jefferson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jefferson, Texas"
    },
    {
      "city": "Lenorah",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lenorah, Texas"
    },
    {
      "city": "Stanton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stanton, Texas"
    },
    {
      "city": "Tarzan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tarzan, Texas"
    },
    {
      "city": "Art",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Art, Texas"
    },
    {
      "city": "Fredonia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fredonia, Texas"
    },
    {
      "city": "Mason",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mason, Texas"
    },
    {
      "city": "Pontotoc",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pontotoc, Texas"
    },
    {
      "city": "Bay City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bay City, Texas"
    },
    {
      "city": "Cedar Lane",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cedar Lane, Texas"
    },
    {
      "city": "Blessing",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blessing, Texas"
    },
    {
      "city": "Collegeport",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Collegeport, Texas"
    },
    {
      "city": "Elmaton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Elmaton, Texas"
    },
    {
      "city": "Markham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Markham, Texas"
    },
    {
      "city": "Matagorda",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Matagorda, Texas"
    },
    {
      "city": "Midfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Midfield, Texas"
    },
    {
      "city": "Palacios",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Palacios, Texas"
    },
    {
      "city": "Pledger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pledger, Texas"
    },
    {
      "city": "Van Vleck",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Van Vleck, Texas"
    },
    {
      "city": "Wadsworth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wadsworth, Texas"
    },
    {
      "city": "Eagle Pass",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eagle Pass, Texas"
    },
    {
      "city": "El Indio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "El Indio, Texas"
    },
    {
      "city": "Quemado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quemado, Texas"
    },
    {
      "city": "Castroville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Castroville, Texas"
    },
    {
      "city": "Devine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Devine, Texas"
    },
    {
      "city": "La Coste",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Coste, Texas"
    },
    {
      "city": "Mico",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mico, Texas"
    },
    {
      "city": "Natalia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Natalia, Texas"
    },
    {
      "city": "Rio Medina",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rio Medina, Texas"
    },
    {
      "city": "D Hanis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "D Hanis, Texas"
    },
    {
      "city": "Hondo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hondo, Texas"
    },
    {
      "city": "Yancey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Yancey, Texas"
    },
    {
      "city": "Fort Mc Kavett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Mc Kavett, Texas"
    },
    {
      "city": "Hext",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hext, Texas"
    },
    {
      "city": "Menard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Menard, Texas"
    },
    {
      "city": "Midland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Midland, Texas"
    },
    {
      "city": "Buckholts",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buckholts, Texas"
    },
    {
      "city": "Burlington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burlington, Texas"
    },
    {
      "city": "Cameron",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cameron, Texas"
    },
    {
      "city": "Davilla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Davilla, Texas"
    },
    {
      "city": "Milano",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Milano, Texas"
    },
    {
      "city": "Rockdale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rockdale, Texas"
    },
    {
      "city": "Thorndale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thorndale, Texas"
    },
    {
      "city": "Gause",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gause, Texas"
    },
    {
      "city": "Goldthwaite",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goldthwaite, Texas"
    },
    {
      "city": "Mullin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mullin, Texas"
    },
    {
      "city": "Priddy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Priddy, Texas"
    },
    {
      "city": "Star",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Star, Texas"
    },
    {
      "city": "Colorado City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Colorado City, Texas"
    },
    {
      "city": "Loraine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Loraine, Texas"
    },
    {
      "city": "Westbrook",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Westbrook, Texas"
    },
    {
      "city": "Bowie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bowie, Texas"
    },
    {
      "city": "Forestburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Forestburg, Texas"
    },
    {
      "city": "Montague",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Montague, Texas"
    },
    {
      "city": "Nocona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nocona, Texas"
    },
    {
      "city": "Ringgold",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ringgold, Texas"
    },
    {
      "city": "Saint Jo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Saint Jo, Texas"
    },
    {
      "city": "Sunset",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sunset, Texas"
    },
    {
      "city": "Conroe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Conroe, Texas"
    },
    {
      "city": "Montgomery",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Montgomery, Texas"
    },
    {
      "city": "Willis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Willis, Texas"
    },
    {
      "city": "Dobbin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dobbin, Texas"
    },
    {
      "city": "Magnolia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Magnolia, Texas"
    },
    {
      "city": "New Caney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Caney, Texas"
    },
    {
      "city": "Pinehurst",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pinehurst, Texas"
    },
    {
      "city": "Porter",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Porter, Texas"
    },
    {
      "city": "Splendora",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Splendora, Texas"
    },
    {
      "city": "Cactus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cactus, Texas"
    },
    {
      "city": "Dumas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dumas, Texas"
    },
    {
      "city": "Masterson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Masterson, Texas"
    },
    {
      "city": "Sunray",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sunray, Texas"
    },
    {
      "city": "Naples",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Naples, Texas"
    },
    {
      "city": "Omaha",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Omaha, Texas"
    },
    {
      "city": "Cason",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cason, Texas"
    },
    {
      "city": "Daingerfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Daingerfield, Texas"
    },
    {
      "city": "Lone Star",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lone Star, Texas"
    },
    {
      "city": "Flomot",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flomot, Texas"
    },
    {
      "city": "Matador",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Matador, Texas"
    },
    {
      "city": "Roaring Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roaring Springs, Texas"
    },
    {
      "city": "Cushing",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cushing, Texas"
    },
    {
      "city": "Sacul",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sacul, Texas"
    },
    {
      "city": "Chireno",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chireno, Texas"
    },
    {
      "city": "Douglass",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Douglass, Texas"
    },
    {
      "city": "Etoile",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Etoile, Texas"
    },
    {
      "city": "Garrison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Garrison, Texas"
    },
    {
      "city": "Martinsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Martinsville, Texas"
    },
    {
      "city": "Nacogdoches",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nacogdoches, Texas"
    },
    {
      "city": "Woden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woden, Texas"
    },
    {
      "city": "Barry",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Barry, Texas"
    },
    {
      "city": "Chatfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chatfield, Texas"
    },
    {
      "city": "Corsicana",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Corsicana, Texas"
    },
    {
      "city": "Kerens",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kerens, Texas"
    },
    {
      "city": "Powell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Powell, Texas"
    },
    {
      "city": "Blooming Grove",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blooming Grove, Texas"
    },
    {
      "city": "Dawson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dawson, Texas"
    },
    {
      "city": "Frost",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Frost, Texas"
    },
    {
      "city": "Purdon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Purdon, Texas"
    },
    {
      "city": "Richland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Richland, Texas"
    },
    {
      "city": "Bon Wier",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bon Wier, Texas"
    },
    {
      "city": "Burkeville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burkeville, Texas"
    },
    {
      "city": "Call",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Call, Texas"
    },
    {
      "city": "Newton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Newton, Texas"
    },
    {
      "city": "Wiergate",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wiergate, Texas"
    },
    {
      "city": "Deweyville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Deweyville, Texas"
    },
    {
      "city": "Blackwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Blackwell, Texas"
    },
    {
      "city": "Maryneal",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Maryneal, Texas"
    },
    {
      "city": "Nolan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nolan, Texas"
    },
    {
      "city": "Roscoe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roscoe, Texas"
    },
    {
      "city": "Sweetwater",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sweetwater, Texas"
    },
    {
      "city": "Agua Dulce",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Agua Dulce, Texas"
    },
    {
      "city": "Banquete",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Banquete, Texas"
    },
    {
      "city": "Bishop",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bishop, Texas"
    },
    {
      "city": "Chapman Ranch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chapman Ranch, Texas"
    },
    {
      "city": "Driscoll",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Driscoll, Texas"
    },
    {
      "city": "Port Aransas",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Aransas, Texas"
    },
    {
      "city": "Robstown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Robstown, Texas"
    },
    {
      "city": "Corpus Christi",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Corpus Christi, Texas"
    },
    {
      "city": "Farnsworth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Farnsworth, Texas"
    },
    {
      "city": "Perryton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Perryton, Texas"
    },
    {
      "city": "Waka",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waka, Texas"
    },
    {
      "city": "Adrian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Adrian, Texas"
    },
    {
      "city": "Boys Ranch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Boys Ranch, Texas"
    },
    {
      "city": "Vega",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vega, Texas"
    },
    {
      "city": "Wildorado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wildorado, Texas"
    },
    {
      "city": "Bridge City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bridge City, Texas"
    },
    {
      "city": "Mauriceville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mauriceville, Texas"
    },
    {
      "city": "Orange",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Orange, Texas"
    },
    {
      "city": "Orangefield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Orangefield, Texas"
    },
    {
      "city": "Vidor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vidor, Texas"
    },
    {
      "city": "Mineral Wells",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mineral Wells, Texas"
    },
    {
      "city": "Graford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Graford, Texas"
    },
    {
      "city": "Gordon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gordon, Texas"
    },
    {
      "city": "Mingus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mingus, Texas"
    },
    {
      "city": "Santo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santo, Texas"
    },
    {
      "city": "Strawn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Strawn, Texas"
    },
    {
      "city": "Palo Pinto",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Palo Pinto, Texas"
    },
    {
      "city": "Beckville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Beckville, Texas"
    },
    {
      "city": "Carthage",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carthage, Texas"
    },
    {
      "city": "Clayton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clayton, Texas"
    },
    {
      "city": "De Berry",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "De Berry, Texas"
    },
    {
      "city": "Gary",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gary, Texas"
    },
    {
      "city": "Long Branch",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Long Branch, Texas"
    },
    {
      "city": "Panola",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Panola, Texas"
    },
    {
      "city": "Aledo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aledo, Texas"
    },
    {
      "city": "Millsap",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Millsap, Texas"
    },
    {
      "city": "Springtown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Springtown, Texas"
    },
    {
      "city": "Weatherford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weatherford, Texas"
    },
    {
      "city": "Azle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Azle, Texas"
    },
    {
      "city": "Dennis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dennis, Texas"
    },
    {
      "city": "Peaster",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Peaster, Texas"
    },
    {
      "city": "Poolville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Poolville, Texas"
    },
    {
      "city": "Whitt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitt, Texas"
    },
    {
      "city": "Bovina",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bovina, Texas"
    },
    {
      "city": "Friona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Friona, Texas"
    },
    {
      "city": "Lazbuddie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lazbuddie, Texas"
    },
    {
      "city": "Farwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Farwell, Texas"
    },
    {
      "city": "Coyanosa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coyanosa, Texas"
    },
    {
      "city": "Fort Stockton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Stockton, Texas"
    },
    {
      "city": "Girvin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Girvin, Texas"
    },
    {
      "city": "Imperial",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Imperial, Texas"
    },
    {
      "city": "Iraan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Iraan, Texas"
    },
    {
      "city": "Sheffield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sheffield, Texas"
    },
    {
      "city": "Camden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Camden, Texas"
    },
    {
      "city": "Corrigan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Corrigan, Texas"
    },
    {
      "city": "Moscow",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Moscow, Texas"
    },
    {
      "city": "Ace",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ace, Texas"
    },
    {
      "city": "Dallardsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dallardsville, Texas"
    },
    {
      "city": "Goodrich",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goodrich, Texas"
    },
    {
      "city": "Leggett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leggett, Texas"
    },
    {
      "city": "Livingston",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Livingston, Texas"
    },
    {
      "city": "Onalaska",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Onalaska, Texas"
    },
    {
      "city": "Bushland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bushland, Texas"
    },
    {
      "city": "Amarillo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Amarillo, Texas"
    },
    {
      "city": "Marfa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Marfa, Texas"
    },
    {
      "city": "Presidio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Presidio, Texas"
    },
    {
      "city": "Redford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Redford, Texas"
    },
    {
      "city": "Alba",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alba, Texas"
    },
    {
      "city": "Emory",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Emory, Texas"
    },
    {
      "city": "Point",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Point, Texas"
    },
    {
      "city": "Canyon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Canyon, Texas"
    },
    {
      "city": "Umbarger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Umbarger, Texas"
    },
    {
      "city": "Big Lake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Big Lake, Texas"
    },
    {
      "city": "Camp Wood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Camp Wood, Texas"
    },
    {
      "city": "Leakey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leakey, Texas"
    },
    {
      "city": "Rio Frio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rio Frio, Texas"
    },
    {
      "city": "Bagwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bagwell, Texas"
    },
    {
      "city": "Bogata",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bogata, Texas"
    },
    {
      "city": "Clarksville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Clarksville, Texas"
    },
    {
      "city": "Detroit",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Detroit, Texas"
    },
    {
      "city": "Annona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Annona, Texas"
    },
    {
      "city": "Avery",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Avery, Texas"
    },
    {
      "city": "Balmorhea",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Balmorhea, Texas"
    },
    {
      "city": "Orla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Orla, Texas"
    },
    {
      "city": "Pecos",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pecos, Texas"
    },
    {
      "city": "Saragosa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Saragosa, Texas"
    },
    {
      "city": "Toyah",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Toyah, Texas"
    },
    {
      "city": "Toyahvale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Toyahvale, Texas"
    },
    {
      "city": "Austwell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Austwell, Texas"
    },
    {
      "city": "Tivoli",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tivoli, Texas"
    },
    {
      "city": "Bayside",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bayside, Texas"
    },
    {
      "city": "Refugio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Refugio, Texas"
    },
    {
      "city": "Woodsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodsboro, Texas"
    },
    {
      "city": "Miami",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Miami, Texas"
    },
    {
      "city": "Bremond",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bremond, Texas"
    },
    {
      "city": "Calvert",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Calvert, Texas"
    },
    {
      "city": "Franklin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Franklin, Texas"
    },
    {
      "city": "Hearne",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hearne, Texas"
    },
    {
      "city": "Mumford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mumford, Texas"
    },
    {
      "city": "New Baden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Baden, Texas"
    },
    {
      "city": "Wheelock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wheelock, Texas"
    },
    {
      "city": "Rockwall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rockwall, Texas"
    },
    {
      "city": "Fate",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fate, Texas"
    },
    {
      "city": "Royse City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Royse City, Texas"
    },
    {
      "city": "Ballinger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ballinger, Texas"
    },
    {
      "city": "Miles",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Miles, Texas"
    },
    {
      "city": "Norton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Norton, Texas"
    },
    {
      "city": "Rowena",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rowena, Texas"
    },
    {
      "city": "Wingate",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wingate, Texas"
    },
    {
      "city": "Winters",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Winters, Texas"
    },
    {
      "city": "Henderson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Henderson, Texas"
    },
    {
      "city": "Joinerville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Joinerville, Texas"
    },
    {
      "city": "Laird Hill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Laird Hill, Texas"
    },
    {
      "city": "Laneville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Laneville, Texas"
    },
    {
      "city": "Minden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Minden, Texas"
    },
    {
      "city": "Mount Enterprise",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mount Enterprise, Texas"
    },
    {
      "city": "New London",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New London, Texas"
    },
    {
      "city": "Overton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Overton, Texas"
    },
    {
      "city": "Price",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Price, Texas"
    },
    {
      "city": "Selman City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Selman City, Texas"
    },
    {
      "city": "Tatum",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tatum, Texas"
    },
    {
      "city": "Bronson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bronson, Texas"
    },
    {
      "city": "Brookeland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brookeland, Texas"
    },
    {
      "city": "Hemphill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hemphill, Texas"
    },
    {
      "city": "Milam",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Milam, Texas"
    },
    {
      "city": "Pineland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pineland, Texas"
    },
    {
      "city": "Broaddus",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Broaddus, Texas"
    },
    {
      "city": "San Augustine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Augustine, Texas"
    },
    {
      "city": "Coldspring",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coldspring, Texas"
    },
    {
      "city": "Oakhurst",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oakhurst, Texas"
    },
    {
      "city": "Pointblank",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pointblank, Texas"
    },
    {
      "city": "Shepherd",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shepherd, Texas"
    },
    {
      "city": "Aransas Pass",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aransas Pass, Texas"
    },
    {
      "city": "Edroy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edroy, Texas"
    },
    {
      "city": "Gregory",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gregory, Texas"
    },
    {
      "city": "Ingleside",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ingleside, Texas"
    },
    {
      "city": "Mathis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mathis, Texas"
    },
    {
      "city": "Odem",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Odem, Texas"
    },
    {
      "city": "Portland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Portland, Texas"
    },
    {
      "city": "Sinton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sinton, Texas"
    },
    {
      "city": "Taft",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Taft, Texas"
    },
    {
      "city": "Cherokee",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cherokee, Texas"
    },
    {
      "city": "Richland Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Richland Springs, Texas"
    },
    {
      "city": "San Saba",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Saba, Texas"
    },
    {
      "city": "Eldorado",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Eldorado, Texas"
    },
    {
      "city": "Dunn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dunn, Texas"
    },
    {
      "city": "Fluvanna",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fluvanna, Texas"
    },
    {
      "city": "Hermleigh",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hermleigh, Texas"
    },
    {
      "city": "Ira",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ira, Texas"
    },
    {
      "city": "Snyder",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Snyder, Texas"
    },
    {
      "city": "Albany",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Albany, Texas"
    },
    {
      "city": "Moran",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Moran, Texas"
    },
    {
      "city": "Center",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Center, Texas"
    },
    {
      "city": "Joaquin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Joaquin, Texas"
    },
    {
      "city": "Shelbyville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shelbyville, Texas"
    },
    {
      "city": "Tenaha",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tenaha, Texas"
    },
    {
      "city": "Timpson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Timpson, Texas"
    },
    {
      "city": "Texhoma",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Texhoma, Texas"
    },
    {
      "city": "Stratford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stratford, Texas"
    },
    {
      "city": "Tyler",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tyler, Texas"
    },
    {
      "city": "Arp",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Arp, Texas"
    },
    {
      "city": "Bullard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bullard, Texas"
    },
    {
      "city": "Flint",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Flint, Texas"
    },
    {
      "city": "Lindale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lindale, Texas"
    },
    {
      "city": "Troup",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Troup, Texas"
    },
    {
      "city": "Whitehouse",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Whitehouse, Texas"
    },
    {
      "city": "Winona",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Winona, Texas"
    },
    {
      "city": "Glen Rose",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Glen Rose, Texas"
    },
    {
      "city": "Nemo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nemo, Texas"
    },
    {
      "city": "Rainbow",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rainbow, Texas"
    },
    {
      "city": "Delmita",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Delmita, Texas"
    },
    {
      "city": "Falcon Heights",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Falcon Heights, Texas"
    },
    {
      "city": "Garciasville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Garciasville, Texas"
    },
    {
      "city": "Grulla",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grulla, Texas"
    },
    {
      "city": "Rio Grande City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rio Grande City, Texas"
    },
    {
      "city": "Roma",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Roma, Texas"
    },
    {
      "city": "Salineno",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Salineno, Texas"
    },
    {
      "city": "San Isidro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Isidro, Texas"
    },
    {
      "city": "Santa Elena",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Santa Elena, Texas"
    },
    {
      "city": "Breckenridge",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Breckenridge, Texas"
    },
    {
      "city": "Caddo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Caddo, Texas"
    },
    {
      "city": "Sterling City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sterling City, Texas"
    },
    {
      "city": "Aspermont",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Aspermont, Texas"
    },
    {
      "city": "Old Glory",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Old Glory, Texas"
    },
    {
      "city": "Sonora",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sonora, Texas"
    },
    {
      "city": "Happy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Happy, Texas"
    },
    {
      "city": "Kress",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kress, Texas"
    },
    {
      "city": "Tulia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tulia, Texas"
    },
    {
      "city": "Arlington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Arlington, Texas"
    },
    {
      "city": "Bedford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bedford, Texas"
    },
    {
      "city": "Colleyville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Colleyville, Texas"
    },
    {
      "city": "Crowley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crowley, Texas"
    },
    {
      "city": "Euless",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Euless, Texas"
    },
    {
      "city": "Grapevine",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grapevine, Texas"
    },
    {
      "city": "Haslet",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Haslet, Texas"
    },
    {
      "city": "Hurst",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hurst, Texas"
    },
    {
      "city": "Kennedale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kennedale, Texas"
    },
    {
      "city": "Mansfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mansfield, Texas"
    },
    {
      "city": "Southlake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Southlake, Texas"
    },
    {
      "city": "Fort Worth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fort Worth, Texas"
    },
    {
      "city": "Haltom City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Haltom City, Texas"
    },
    {
      "city": "Naval Air Station Jrb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Naval Air Station Jrb, Texas"
    },
    {
      "city": "North Richland Hills",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "North Richland Hills, Texas"
    },
    {
      "city": "Keller",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Keller, Texas"
    },
    {
      "city": "Buffalo Gap",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Buffalo Gap, Texas"
    },
    {
      "city": "Lawn",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lawn, Texas"
    },
    {
      "city": "Merkel",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Merkel, Texas"
    },
    {
      "city": "Ovalo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ovalo, Texas"
    },
    {
      "city": "Trent",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Trent, Texas"
    },
    {
      "city": "Tuscola",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tuscola, Texas"
    },
    {
      "city": "Tye",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tye, Texas"
    },
    {
      "city": "Abilene",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Abilene, Texas"
    },
    {
      "city": "Dyess Afb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dyess Afb, Texas"
    },
    {
      "city": "Dryden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dryden, Texas"
    },
    {
      "city": "Sanderson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sanderson, Texas"
    },
    {
      "city": "Brownfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brownfield, Texas"
    },
    {
      "city": "Meadow",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Meadow, Texas"
    },
    {
      "city": "Wellman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wellman, Texas"
    },
    {
      "city": "Throckmorton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Throckmorton, Texas"
    },
    {
      "city": "Woodson",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodson, Texas"
    },
    {
      "city": "Mount Pleasant",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mount Pleasant, Texas"
    },
    {
      "city": "Winfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Winfield, Texas"
    },
    {
      "city": "Cookville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cookville, Texas"
    },
    {
      "city": "Veribest",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Veribest, Texas"
    },
    {
      "city": "San Angelo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Angelo, Texas"
    },
    {
      "city": "Goodfellow Afb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Goodfellow Afb, Texas"
    },
    {
      "city": "Carlsbad",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Carlsbad, Texas"
    },
    {
      "city": "Christoval",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Christoval, Texas"
    },
    {
      "city": "Knickerbocker",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Knickerbocker, Texas"
    },
    {
      "city": "Mereta",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mereta, Texas"
    },
    {
      "city": "Vancourt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vancourt, Texas"
    },
    {
      "city": "Wall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wall, Texas"
    },
    {
      "city": "Water Valley",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Water Valley, Texas"
    },
    {
      "city": "Austin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Austin, Texas"
    },
    {
      "city": "Del Valle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Del Valle, Texas"
    },
    {
      "city": "Leander",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Leander, Texas"
    },
    {
      "city": "Mc Neil",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Neil, Texas"
    },
    {
      "city": "Manchaca",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Manchaca, Texas"
    },
    {
      "city": "Manor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Manor, Texas"
    },
    {
      "city": "Pflugerville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pflugerville, Texas"
    },
    {
      "city": "Spicewood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spicewood, Texas"
    },
    {
      "city": "Centralia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Centralia, Texas"
    },
    {
      "city": "Groveton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Groveton, Texas"
    },
    {
      "city": "Pennington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pennington, Texas"
    },
    {
      "city": "Trinity",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Trinity, Texas"
    },
    {
      "city": "Woodlake",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodlake, Texas"
    },
    {
      "city": "Apple Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Apple Springs, Texas"
    },
    {
      "city": "Chester",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chester, Texas"
    },
    {
      "city": "Colmesneil",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Colmesneil, Texas"
    },
    {
      "city": "Doucette",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Doucette, Texas"
    },
    {
      "city": "Woodville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Woodville, Texas"
    },
    {
      "city": "Fred",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fred, Texas"
    },
    {
      "city": "Hillister",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hillister, Texas"
    },
    {
      "city": "Spurger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Spurger, Texas"
    },
    {
      "city": "Warren",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Warren, Texas"
    },
    {
      "city": "Diana",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Diana, Texas"
    },
    {
      "city": "Gilmer",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Gilmer, Texas"
    },
    {
      "city": "Ore City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ore City, Texas"
    },
    {
      "city": "Big Sandy",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Big Sandy, Texas"
    },
    {
      "city": "Mc Camey",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mc Camey, Texas"
    },
    {
      "city": "Midkiff",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Midkiff, Texas"
    },
    {
      "city": "Rankin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rankin, Texas"
    },
    {
      "city": "Uvalde",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Uvalde, Texas"
    },
    {
      "city": "Concan",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Concan, Texas"
    },
    {
      "city": "Knippa",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Knippa, Texas"
    },
    {
      "city": "Sabinal",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sabinal, Texas"
    },
    {
      "city": "Utopia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Utopia, Texas"
    },
    {
      "city": "Comstock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Comstock, Texas"
    },
    {
      "city": "Del Rio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Del Rio, Texas"
    },
    {
      "city": "Laughlin Afb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Laughlin Afb, Texas"
    },
    {
      "city": "Langtry",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Langtry, Texas"
    },
    {
      "city": "Canton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Canton, Texas"
    },
    {
      "city": "Edgewood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Edgewood, Texas"
    },
    {
      "city": "Fruitvale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Fruitvale, Texas"
    },
    {
      "city": "Grand Saline",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grand Saline, Texas"
    },
    {
      "city": "Wills Point",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wills Point, Texas"
    },
    {
      "city": "Ben Wheeler",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Ben Wheeler, Texas"
    },
    {
      "city": "Van",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Van, Texas"
    },
    {
      "city": "Victoria",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Victoria, Texas"
    },
    {
      "city": "Bloomington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bloomington, Texas"
    },
    {
      "city": "Inez",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Inez, Texas"
    },
    {
      "city": "Mcfaddin",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mcfaddin, Texas"
    },
    {
      "city": "Nursery",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Nursery, Texas"
    },
    {
      "city": "Placedo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Placedo, Texas"
    },
    {
      "city": "Telferner",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Telferner, Texas"
    },
    {
      "city": "Huntsville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Huntsville, Texas"
    },
    {
      "city": "Dodge",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Dodge, Texas"
    },
    {
      "city": "New Waverly",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "New Waverly, Texas"
    },
    {
      "city": "Riverside",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Riverside, Texas"
    },
    {
      "city": "Brookshire",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brookshire, Texas"
    },
    {
      "city": "Hempstead",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hempstead, Texas"
    },
    {
      "city": "Prairie View",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Prairie View, Texas"
    },
    {
      "city": "Pattison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pattison, Texas"
    },
    {
      "city": "Waller",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Waller, Texas"
    },
    {
      "city": "Barstow",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Barstow, Texas"
    },
    {
      "city": "Grandfalls",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Grandfalls, Texas"
    },
    {
      "city": "Monahans",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Monahans, Texas"
    },
    {
      "city": "Pyote",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pyote, Texas"
    },
    {
      "city": "Wickett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wickett, Texas"
    },
    {
      "city": "Chappell Hill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chappell Hill, Texas"
    },
    {
      "city": "Brenham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Brenham, Texas"
    },
    {
      "city": "Burton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burton, Texas"
    },
    {
      "city": "Washington",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Washington, Texas"
    },
    {
      "city": "Laredo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Laredo, Texas"
    },
    {
      "city": "Bruni",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bruni, Texas"
    },
    {
      "city": "Mirando City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mirando City, Texas"
    },
    {
      "city": "Oilton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oilton, Texas"
    },
    {
      "city": "Boling",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Boling, Texas"
    },
    {
      "city": "Danevang",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Danevang, Texas"
    },
    {
      "city": "East Bernard",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "East Bernard, Texas"
    },
    {
      "city": "Egypt",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Egypt, Texas"
    },
    {
      "city": "El Campo",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "El Campo, Texas"
    },
    {
      "city": "Glen Flora",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Glen Flora, Texas"
    },
    {
      "city": "Hungerford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hungerford, Texas"
    },
    {
      "city": "Lane City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lane City, Texas"
    },
    {
      "city": "Lissie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lissie, Texas"
    },
    {
      "city": "Louise",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Louise, Texas"
    },
    {
      "city": "Pierce",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pierce, Texas"
    },
    {
      "city": "Wharton",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wharton, Texas"
    },
    {
      "city": "Allison",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Allison, Texas"
    },
    {
      "city": "Briscoe",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Briscoe, Texas"
    },
    {
      "city": "Mobeetie",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mobeetie, Texas"
    },
    {
      "city": "Shamrock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Shamrock, Texas"
    },
    {
      "city": "Wheeler",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wheeler, Texas"
    },
    {
      "city": "Wichita Falls",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wichita Falls, Texas"
    },
    {
      "city": "Sheppard Afb",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sheppard Afb, Texas"
    },
    {
      "city": "Burkburnett",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Burkburnett, Texas"
    },
    {
      "city": "Electra",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Electra, Texas"
    },
    {
      "city": "Iowa Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Iowa Park, Texas"
    },
    {
      "city": "Kamay",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kamay, Texas"
    },
    {
      "city": "Harrold",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Harrold, Texas"
    },
    {
      "city": "Oklaunion",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Oklaunion, Texas"
    },
    {
      "city": "Vernon",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Vernon, Texas"
    },
    {
      "city": "Odell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Odell, Texas"
    },
    {
      "city": "Lasara",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lasara, Texas"
    },
    {
      "city": "Lyford",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lyford, Texas"
    },
    {
      "city": "Raymondville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Raymondville, Texas"
    },
    {
      "city": "San Perlita",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Perlita, Texas"
    },
    {
      "city": "Sebastian",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sebastian, Texas"
    },
    {
      "city": "Port Mansfield",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Port Mansfield, Texas"
    },
    {
      "city": "Florence",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Florence, Texas"
    },
    {
      "city": "Granger",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Granger, Texas"
    },
    {
      "city": "Jarrell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Jarrell, Texas"
    },
    {
      "city": "Schwertner",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Schwertner, Texas"
    },
    {
      "city": "Taylor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Taylor, Texas"
    },
    {
      "city": "Thrall",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Thrall, Texas"
    },
    {
      "city": "Cedar Park",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Cedar Park, Texas"
    },
    {
      "city": "Coupland",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Coupland, Texas"
    },
    {
      "city": "Georgetown",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Georgetown, Texas"
    },
    {
      "city": "Hutto",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hutto, Texas"
    },
    {
      "city": "Liberty Hill",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Liberty Hill, Texas"
    },
    {
      "city": "Round Rock",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Round Rock, Texas"
    },
    {
      "city": "Walburg",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Walburg, Texas"
    },
    {
      "city": "Weir",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Weir, Texas"
    },
    {
      "city": "Floresville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Floresville, Texas"
    },
    {
      "city": "La Vernia",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Vernia, Texas"
    },
    {
      "city": "Pandora",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Pandora, Texas"
    },
    {
      "city": "Poth",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Poth, Texas"
    },
    {
      "city": "Stockdale",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Stockdale, Texas"
    },
    {
      "city": "Sutherland Springs",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Sutherland Springs, Texas"
    },
    {
      "city": "Kermit",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Kermit, Texas"
    },
    {
      "city": "Wink",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Wink, Texas"
    },
    {
      "city": "Boyd",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Boyd, Texas"
    },
    {
      "city": "Newark",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Newark, Texas"
    },
    {
      "city": "Paradise",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Paradise, Texas"
    },
    {
      "city": "Rhome",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Rhome, Texas"
    },
    {
      "city": "Alvord",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Alvord, Texas"
    },
    {
      "city": "Decatur",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Decatur, Texas"
    },
    {
      "city": "Greenwood",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Greenwood, Texas"
    },
    {
      "city": "Slidell",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Slidell, Texas"
    },
    {
      "city": "Bridgeport",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Bridgeport, Texas"
    },
    {
      "city": "Chico",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Chico, Texas"
    },
    {
      "city": "Golden",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Golden, Texas"
    },
    {
      "city": "Winnsboro",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Winnsboro, Texas"
    },
    {
      "city": "Yantis",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Yantis, Texas"
    },
    {
      "city": "Hawkins",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Hawkins, Texas"
    },
    {
      "city": "Mineola",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Mineola, Texas"
    },
    {
      "city": "Quitman",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Quitman, Texas"
    },
    {
      "city": "Denver City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Denver City, Texas"
    },
    {
      "city": "Plains",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Plains, Texas"
    },
    {
      "city": "Tokio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Tokio, Texas"
    },
    {
      "city": "Newcastle",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Newcastle, Texas"
    },
    {
      "city": "Olney",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Olney, Texas"
    },
    {
      "city": "Graham",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Graham, Texas"
    },
    {
      "city": "Loving",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Loving, Texas"
    },
    {
      "city": "South Bend",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "South Bend, Texas"
    },
    {
      "city": "San Ygnacio",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "San Ygnacio, Texas"
    },
    {
      "city": "Zapata",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Zapata, Texas"
    },
    {
      "city": "Lopeno",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Lopeno, Texas"
    },
    {
      "city": "Batesville",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Batesville, Texas"
    },
    {
      "city": "Crystal City",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "Crystal City, Texas"
    },
    {
      "city": "La Pryor",
      "state": "Texas",
      "shortState": "TX",
      "formatedCity": "La Pryor, Texas"
    },
    {
      "city": "Beaver",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Beaver, Utah"
    },
    {
      "city": "Greenville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Greenville, Utah"
    },
    {
      "city": "Milford",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Milford, Utah"
    },
    {
      "city": "Minersville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Minersville, Utah"
    },
    {
      "city": "Bear River City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bear River City, Utah"
    },
    {
      "city": "Brigham City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Brigham City, Utah"
    },
    {
      "city": "Collinston",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Collinston, Utah"
    },
    {
      "city": "Corinne",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Corinne, Utah"
    },
    {
      "city": "Deweyville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Deweyville, Utah"
    },
    {
      "city": "Fielding",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fielding, Utah"
    },
    {
      "city": "Garland",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Garland, Utah"
    },
    {
      "city": "Grouse Creek",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Grouse Creek, Utah"
    },
    {
      "city": "Honeyville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Honeyville, Utah"
    },
    {
      "city": "Howell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Howell, Utah"
    },
    {
      "city": "Mantua",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mantua, Utah"
    },
    {
      "city": "Park Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Park Valley, Utah"
    },
    {
      "city": "Plymouth",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Plymouth, Utah"
    },
    {
      "city": "Portage",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Portage, Utah"
    },
    {
      "city": "Riverside",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Riverside, Utah"
    },
    {
      "city": "Snowville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Snowville, Utah"
    },
    {
      "city": "Tremonton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Tremonton, Utah"
    },
    {
      "city": "Willard",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Willard, Utah"
    },
    {
      "city": "Cache Junction",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cache Junction, Utah"
    },
    {
      "city": "Clarkston",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Clarkston, Utah"
    },
    {
      "city": "Cornish",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cornish, Utah"
    },
    {
      "city": "Hyde Park",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hyde Park, Utah"
    },
    {
      "city": "Hyrum",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hyrum, Utah"
    },
    {
      "city": "Lewiston",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lewiston, Utah"
    },
    {
      "city": "Logan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Logan, Utah"
    },
    {
      "city": "Mendon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mendon, Utah"
    },
    {
      "city": "Millville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Millville, Utah"
    },
    {
      "city": "Newton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Newton, Utah"
    },
    {
      "city": "Paradise",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Paradise, Utah"
    },
    {
      "city": "Providence",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Providence, Utah"
    },
    {
      "city": "Richmond",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Richmond, Utah"
    },
    {
      "city": "Smithfield",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Smithfield, Utah"
    },
    {
      "city": "Trenton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Trenton, Utah"
    },
    {
      "city": "Wellsville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Wellsville, Utah"
    },
    {
      "city": "Price",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Price, Utah"
    },
    {
      "city": "East Carbon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "East Carbon, Utah"
    },
    {
      "city": "Helper",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Helper, Utah"
    },
    {
      "city": "Kenilworth",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kenilworth, Utah"
    },
    {
      "city": "Sunnyside",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Sunnyside, Utah"
    },
    {
      "city": "Wellington",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Wellington, Utah"
    },
    {
      "city": "Dutch John",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Dutch John, Utah"
    },
    {
      "city": "Manila",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Manila, Utah"
    },
    {
      "city": "Bountiful",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bountiful, Utah"
    },
    {
      "city": "Centerville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Centerville, Utah"
    },
    {
      "city": "Clearfield",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Clearfield, Utah"
    },
    {
      "city": "Farmington",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Farmington, Utah"
    },
    {
      "city": "Kaysville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kaysville, Utah"
    },
    {
      "city": "Layton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Layton, Utah"
    },
    {
      "city": "North Salt Lake",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "North Salt Lake, Utah"
    },
    {
      "city": "Hill Afb",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hill Afb, Utah"
    },
    {
      "city": "Syracuse",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Syracuse, Utah"
    },
    {
      "city": "Woods Cross",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Woods Cross, Utah"
    },
    {
      "city": "Altamont",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Altamont, Utah"
    },
    {
      "city": "Altonah",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Altonah, Utah"
    },
    {
      "city": "Bluebell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bluebell, Utah"
    },
    {
      "city": "Duchesne",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Duchesne, Utah"
    },
    {
      "city": "Fruitland",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fruitland, Utah"
    },
    {
      "city": "Hanna",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hanna, Utah"
    },
    {
      "city": "Mountain Home",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mountain Home, Utah"
    },
    {
      "city": "Myton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Myton, Utah"
    },
    {
      "city": "Neola",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Neola, Utah"
    },
    {
      "city": "Roosevelt",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Roosevelt, Utah"
    },
    {
      "city": "Tabiona",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Tabiona, Utah"
    },
    {
      "city": "Talmage",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Talmage, Utah"
    },
    {
      "city": "Castle Dale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Castle Dale, Utah"
    },
    {
      "city": "Clawson",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Clawson, Utah"
    },
    {
      "city": "Cleveland",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cleveland, Utah"
    },
    {
      "city": "Elmo",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Elmo, Utah"
    },
    {
      "city": "Emery",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Emery, Utah"
    },
    {
      "city": "Ferron",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Ferron, Utah"
    },
    {
      "city": "Green River",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Green River, Utah"
    },
    {
      "city": "Huntington",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Huntington, Utah"
    },
    {
      "city": "Orangeville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Orangeville, Utah"
    },
    {
      "city": "Antimony",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Antimony, Utah"
    },
    {
      "city": "Boulder",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Boulder, Utah"
    },
    {
      "city": "Cannonville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cannonville, Utah"
    },
    {
      "city": "Escalante",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Escalante, Utah"
    },
    {
      "city": "Hatch",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hatch, Utah"
    },
    {
      "city": "Henrieville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Henrieville, Utah"
    },
    {
      "city": "Panguitch",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Panguitch, Utah"
    },
    {
      "city": "Bryce",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bryce, Utah"
    },
    {
      "city": "Tropic",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Tropic, Utah"
    },
    {
      "city": "Cisco",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cisco, Utah"
    },
    {
      "city": "Moab",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Moab, Utah"
    },
    {
      "city": "Thompson",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Thompson, Utah"
    },
    {
      "city": "Beryl",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Beryl, Utah"
    },
    {
      "city": "Brian Head",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Brian Head, Utah"
    },
    {
      "city": "Cedar City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cedar City, Utah"
    },
    {
      "city": "Kanarraville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kanarraville, Utah"
    },
    {
      "city": "Modena",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Modena, Utah"
    },
    {
      "city": "Newcastle",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Newcastle, Utah"
    },
    {
      "city": "Paragonah",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Paragonah, Utah"
    },
    {
      "city": "Parowan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Parowan, Utah"
    },
    {
      "city": "Summit",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Summit, Utah"
    },
    {
      "city": "Eureka",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Eureka, Utah"
    },
    {
      "city": "Levan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Levan, Utah"
    },
    {
      "city": "Mona",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mona, Utah"
    },
    {
      "city": "Nephi",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Nephi, Utah"
    },
    {
      "city": "Alton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Alton, Utah"
    },
    {
      "city": "Glendale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Glendale, Utah"
    },
    {
      "city": "Kanab",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kanab, Utah"
    },
    {
      "city": "Mount Carmel",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mount Carmel, Utah"
    },
    {
      "city": "Orderville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Orderville, Utah"
    },
    {
      "city": "Duck Creek Village",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Duck Creek Village, Utah"
    },
    {
      "city": "Delta",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Delta, Utah"
    },
    {
      "city": "Fillmore",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fillmore, Utah"
    },
    {
      "city": "Hinckley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hinckley, Utah"
    },
    {
      "city": "Holden",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Holden, Utah"
    },
    {
      "city": "Kanosh",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kanosh, Utah"
    },
    {
      "city": "Leamington",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Leamington, Utah"
    },
    {
      "city": "Lynndyl",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lynndyl, Utah"
    },
    {
      "city": "Meadow",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Meadow, Utah"
    },
    {
      "city": "Oak City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Oak City, Utah"
    },
    {
      "city": "Scipio",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Scipio, Utah"
    },
    {
      "city": "Garrison",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Garrison, Utah"
    },
    {
      "city": "Croydon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Croydon, Utah"
    },
    {
      "city": "Morgan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Morgan, Utah"
    },
    {
      "city": "Circleville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Circleville, Utah"
    },
    {
      "city": "Greenwich",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Greenwich, Utah"
    },
    {
      "city": "Junction",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Junction, Utah"
    },
    {
      "city": "Kingston",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kingston, Utah"
    },
    {
      "city": "Marysvale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Marysvale, Utah"
    },
    {
      "city": "Garden City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Garden City, Utah"
    },
    {
      "city": "Laketown",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Laketown, Utah"
    },
    {
      "city": "Randolph",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Randolph, Utah"
    },
    {
      "city": "Woodruff",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Woodruff, Utah"
    },
    {
      "city": "Bingham Canyon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bingham Canyon, Utah"
    },
    {
      "city": "South Jordan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "South Jordan, Utah"
    },
    {
      "city": "Draper",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Draper, Utah"
    },
    {
      "city": "Magna",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Magna, Utah"
    },
    {
      "city": "Midvale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Midvale, Utah"
    },
    {
      "city": "Riverton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Riverton, Utah"
    },
    {
      "city": "Sandy",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Sandy, Utah"
    },
    {
      "city": "West Jordan",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "West Jordan, Utah"
    },
    {
      "city": "Herriman",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Herriman, Utah"
    },
    {
      "city": "Salt Lake City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Salt Lake City, Utah"
    },
    {
      "city": "West Valley City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "West Valley City, Utah"
    },
    {
      "city": "Aneth",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Aneth, Utah"
    },
    {
      "city": "Blanding",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Blanding, Utah"
    },
    {
      "city": "Bluff",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bluff, Utah"
    },
    {
      "city": "La Sal",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "La Sal, Utah"
    },
    {
      "city": "Mexican Hat",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mexican Hat, Utah"
    },
    {
      "city": "Lake Powell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lake Powell, Utah"
    },
    {
      "city": "Montezuma Creek",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Montezuma Creek, Utah"
    },
    {
      "city": "Monticello",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Monticello, Utah"
    },
    {
      "city": "Monument Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Monument Valley, Utah"
    },
    {
      "city": "Axtell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Axtell, Utah"
    },
    {
      "city": "Centerfield",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Centerfield, Utah"
    },
    {
      "city": "Chester",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Chester, Utah"
    },
    {
      "city": "Ephraim",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Ephraim, Utah"
    },
    {
      "city": "Fairview",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fairview, Utah"
    },
    {
      "city": "Fayette",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fayette, Utah"
    },
    {
      "city": "Fountain Green",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fountain Green, Utah"
    },
    {
      "city": "Gunnison",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Gunnison, Utah"
    },
    {
      "city": "Manti",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Manti, Utah"
    },
    {
      "city": "Mayfield",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mayfield, Utah"
    },
    {
      "city": "Moroni",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Moroni, Utah"
    },
    {
      "city": "Mount Pleasant",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mount Pleasant, Utah"
    },
    {
      "city": "Spring City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Spring City, Utah"
    },
    {
      "city": "Sterling",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Sterling, Utah"
    },
    {
      "city": "Wales",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Wales, Utah"
    },
    {
      "city": "Aurora",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Aurora, Utah"
    },
    {
      "city": "Redmond",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Redmond, Utah"
    },
    {
      "city": "Salina",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Salina, Utah"
    },
    {
      "city": "Sigurd",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Sigurd, Utah"
    },
    {
      "city": "Richfield",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Richfield, Utah"
    },
    {
      "city": "Annabella",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Annabella, Utah"
    },
    {
      "city": "Elsinore",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Elsinore, Utah"
    },
    {
      "city": "Glenwood",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Glenwood, Utah"
    },
    {
      "city": "Joseph",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Joseph, Utah"
    },
    {
      "city": "Koosharem",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Koosharem, Utah"
    },
    {
      "city": "Monroe",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Monroe, Utah"
    },
    {
      "city": "Sevier",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Sevier, Utah"
    },
    {
      "city": "Coalville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Coalville, Utah"
    },
    {
      "city": "Echo",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Echo, Utah"
    },
    {
      "city": "Henefer",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Henefer, Utah"
    },
    {
      "city": "Kamas",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Kamas, Utah"
    },
    {
      "city": "Oakley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Oakley, Utah"
    },
    {
      "city": "Park City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Park City, Utah"
    },
    {
      "city": "Peoa",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Peoa, Utah"
    },
    {
      "city": "Dugway",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Dugway, Utah"
    },
    {
      "city": "Grantsville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Grantsville, Utah"
    },
    {
      "city": "Ibapah",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Ibapah, Utah"
    },
    {
      "city": "Rush Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Rush Valley, Utah"
    },
    {
      "city": "Stockton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Stockton, Utah"
    },
    {
      "city": "Tooele",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Tooele, Utah"
    },
    {
      "city": "Vernon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Vernon, Utah"
    },
    {
      "city": "Wendover",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Wendover, Utah"
    },
    {
      "city": "Bonanza",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bonanza, Utah"
    },
    {
      "city": "Fort Duchesne",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Fort Duchesne, Utah"
    },
    {
      "city": "Jensen",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Jensen, Utah"
    },
    {
      "city": "Lapoint",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lapoint, Utah"
    },
    {
      "city": "Randlett",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Randlett, Utah"
    },
    {
      "city": "Tridell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Tridell, Utah"
    },
    {
      "city": "Vernal",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Vernal, Utah"
    },
    {
      "city": "Whiterocks",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Whiterocks, Utah"
    },
    {
      "city": "American Fork",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "American Fork, Utah"
    },
    {
      "city": "Alpine",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Alpine, Utah"
    },
    {
      "city": "Eagle Mountain",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Eagle Mountain, Utah"
    },
    {
      "city": "Cedar Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Cedar Valley, Utah"
    },
    {
      "city": "Lindon",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lindon, Utah"
    },
    {
      "city": "Lehi",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lehi, Utah"
    },
    {
      "city": "Saratoga Springs",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Saratoga Springs, Utah"
    },
    {
      "city": "Orem",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Orem, Utah"
    },
    {
      "city": "Pleasant Grove",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Pleasant Grove, Utah"
    },
    {
      "city": "Provo",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Provo, Utah"
    },
    {
      "city": "Elberta",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Elberta, Utah"
    },
    {
      "city": "Goshen",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Goshen, Utah"
    },
    {
      "city": "Payson",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Payson, Utah"
    },
    {
      "city": "Salem",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Salem, Utah"
    },
    {
      "city": "Santaquin",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Santaquin, Utah"
    },
    {
      "city": "Spanish Fork",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Spanish Fork, Utah"
    },
    {
      "city": "Springville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Springville, Utah"
    },
    {
      "city": "Mapleton",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Mapleton, Utah"
    },
    {
      "city": "Heber City",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Heber City, Utah"
    },
    {
      "city": "Midway",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Midway, Utah"
    },
    {
      "city": "Wallsburg",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Wallsburg, Utah"
    },
    {
      "city": "Central",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Central, Utah"
    },
    {
      "city": "Enterprise",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Enterprise, Utah"
    },
    {
      "city": "Gunlock",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Gunlock, Utah"
    },
    {
      "city": "Hurricane",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hurricane, Utah"
    },
    {
      "city": "Ivins",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Ivins, Utah"
    },
    {
      "city": "La Verkin",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "La Verkin, Utah"
    },
    {
      "city": "Leeds",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Leeds, Utah"
    },
    {
      "city": "New Harmony",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "New Harmony, Utah"
    },
    {
      "city": "Rockville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Rockville, Utah"
    },
    {
      "city": "Santa Clara",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Santa Clara, Utah"
    },
    {
      "city": "Springdale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Springdale, Utah"
    },
    {
      "city": "Saint George",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Saint George, Utah"
    },
    {
      "city": "Toquerville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Toquerville, Utah"
    },
    {
      "city": "Virgin",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Virgin, Utah"
    },
    {
      "city": "Washington",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Washington, Utah"
    },
    {
      "city": "Pine Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Pine Valley, Utah"
    },
    {
      "city": "Veyo",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Veyo, Utah"
    },
    {
      "city": "Dammeron Valley",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Dammeron Valley, Utah"
    },
    {
      "city": "Hildale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hildale, Utah"
    },
    {
      "city": "Bicknell",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Bicknell, Utah"
    },
    {
      "city": "Hanksville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hanksville, Utah"
    },
    {
      "city": "Loa",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Loa, Utah"
    },
    {
      "city": "Lyman",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Lyman, Utah"
    },
    {
      "city": "Teasdale",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Teasdale, Utah"
    },
    {
      "city": "Torrey",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Torrey, Utah"
    },
    {
      "city": "Roy",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Roy, Utah"
    },
    {
      "city": "Ogden",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Ogden, Utah"
    },
    {
      "city": "Eden",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Eden, Utah"
    },
    {
      "city": "Hooper",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Hooper, Utah"
    },
    {
      "city": "Huntsville",
      "state": "Utah",
      "shortState": "UT",
      "formatedCity": "Huntsville, Utah"
    },
    {
      "city": "Accomac",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Accomac, Virginia"
    },
    {
      "city": "Assawoman",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Assawoman, Virginia"
    },
    {
      "city": "Atlantic",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Atlantic, Virginia"
    },
    {
      "city": "Belle Haven",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Belle Haven, Virginia"
    },
    {
      "city": "Bloxom",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bloxom, Virginia"
    },
    {
      "city": "Chincoteague Island",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chincoteague Island, Virginia"
    },
    {
      "city": "Wallops Island",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wallops Island, Virginia"
    },
    {
      "city": "Craddockville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Craddockville, Virginia"
    },
    {
      "city": "Davis Wharf",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Davis Wharf, Virginia"
    },
    {
      "city": "Greenbackville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Greenbackville, Virginia"
    },
    {
      "city": "Greenbush",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Greenbush, Virginia"
    },
    {
      "city": "Hacksneck",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hacksneck, Virginia"
    },
    {
      "city": "Hallwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hallwood, Virginia"
    },
    {
      "city": "Harborton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Harborton, Virginia"
    },
    {
      "city": "Horntown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Horntown, Virginia"
    },
    {
      "city": "Oak Hall",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oak Hall, Virginia"
    },
    {
      "city": "Jenkins Bridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jenkins Bridge, Virginia"
    },
    {
      "city": "Keller",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keller, Virginia"
    },
    {
      "city": "Locustville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Locustville, Virginia"
    },
    {
      "city": "Mappsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mappsville, Virginia"
    },
    {
      "city": "Mears",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mears, Virginia"
    },
    {
      "city": "Melfa",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Melfa, Virginia"
    },
    {
      "city": "Modest Town",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Modest Town, Virginia"
    },
    {
      "city": "Nelsonia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nelsonia, Virginia"
    },
    {
      "city": "New Church",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Church, Virginia"
    },
    {
      "city": "Onancock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Onancock, Virginia"
    },
    {
      "city": "Onley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Onley, Virginia"
    },
    {
      "city": "Painter",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Painter, Virginia"
    },
    {
      "city": "Parksley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Parksley, Virginia"
    },
    {
      "city": "Pungoteague",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pungoteague, Virginia"
    },
    {
      "city": "Quinby",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Quinby, Virginia"
    },
    {
      "city": "Sanford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sanford, Virginia"
    },
    {
      "city": "Saxis",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saxis, Virginia"
    },
    {
      "city": "Tangier",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tangier, Virginia"
    },
    {
      "city": "Tasley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tasley, Virginia"
    },
    {
      "city": "Temperanceville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Temperanceville, Virginia"
    },
    {
      "city": "Wachapreague",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wachapreague, Virginia"
    },
    {
      "city": "Wattsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wattsville, Virginia"
    },
    {
      "city": "Withams",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Withams, Virginia"
    },
    {
      "city": "Charlottesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Charlottesville, Virginia"
    },
    {
      "city": "Batesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Batesville, Virginia"
    },
    {
      "city": "Covesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Covesville, Virginia"
    },
    {
      "city": "Crozet",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crozet, Virginia"
    },
    {
      "city": "Earlysville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Earlysville, Virginia"
    },
    {
      "city": "Esmont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Esmont, Virginia"
    },
    {
      "city": "Free Union",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Free Union, Virginia"
    },
    {
      "city": "Greenwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Greenwood, Virginia"
    },
    {
      "city": "Ivy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ivy, Virginia"
    },
    {
      "city": "Keene",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keene, Virginia"
    },
    {
      "city": "Keswick",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keswick, Virginia"
    },
    {
      "city": "North Garden",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "North Garden, Virginia"
    },
    {
      "city": "White Hall",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "White Hall, Virginia"
    },
    {
      "city": "Howardsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Howardsville, Virginia"
    },
    {
      "city": "Scottsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Scottsville, Virginia"
    },
    {
      "city": "Clifton Forge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clifton Forge, Virginia"
    },
    {
      "city": "Iron Gate",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Iron Gate, Virginia"
    },
    {
      "city": "Low Moor",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Low Moor, Virginia"
    },
    {
      "city": "Selma",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Selma, Virginia"
    },
    {
      "city": "Amelia Court House",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Amelia Court House, Virginia"
    },
    {
      "city": "Jetersville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jetersville, Virginia"
    },
    {
      "city": "Mannboro",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mannboro, Virginia"
    },
    {
      "city": "Amherst",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Amherst, Virginia"
    },
    {
      "city": "Clifford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clifford, Virginia"
    },
    {
      "city": "Madison Heights",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Madison Heights, Virginia"
    },
    {
      "city": "Monroe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Monroe, Virginia"
    },
    {
      "city": "Sweet Briar",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sweet Briar, Virginia"
    },
    {
      "city": "Evergreen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Evergreen, Virginia"
    },
    {
      "city": "Pamplin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pamplin, Virginia"
    },
    {
      "city": "Appomattox",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Appomattox, Virginia"
    },
    {
      "city": "Spout Spring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Spout Spring, Virginia"
    },
    {
      "city": "Arlington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Arlington, Virginia"
    },
    {
      "city": "Fort Myer",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Myer, Virginia"
    },
    {
      "city": "Mount Solon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Solon, Virginia"
    },
    {
      "city": "Fishersville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fishersville, Virginia"
    },
    {
      "city": "Lyndhurst",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lyndhurst, Virginia"
    },
    {
      "city": "Augusta Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Augusta Springs, Virginia"
    },
    {
      "city": "Churchville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Churchville, Virginia"
    },
    {
      "city": "Craigsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Craigsville, Virginia"
    },
    {
      "city": "Crimora",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crimora, Virginia"
    },
    {
      "city": "Deerfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Deerfield, Virginia"
    },
    {
      "city": "Fort Defiance",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Defiance, Virginia"
    },
    {
      "city": "Greenville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Greenville, Virginia"
    },
    {
      "city": "Middlebrook",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Middlebrook, Virginia"
    },
    {
      "city": "Mint Spring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mint Spring, Virginia"
    },
    {
      "city": "Mount Sidney",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Sidney, Virginia"
    },
    {
      "city": "New Hope",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Hope, Virginia"
    },
    {
      "city": "Steeles Tavern",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Steeles Tavern, Virginia"
    },
    {
      "city": "Stuarts Draft",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stuarts Draft, Virginia"
    },
    {
      "city": "Swoope",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Swoope, Virginia"
    },
    {
      "city": "Verona",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Verona, Virginia"
    },
    {
      "city": "West Augusta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "West Augusta, Virginia"
    },
    {
      "city": "Weyers Cave",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Weyers Cave, Virginia"
    },
    {
      "city": "Bacova",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bacova, Virginia"
    },
    {
      "city": "Hot Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hot Springs, Virginia"
    },
    {
      "city": "Millboro",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Millboro, Virginia"
    },
    {
      "city": "Warm Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Warm Springs, Virginia"
    },
    {
      "city": "Williamsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Williamsville, Virginia"
    },
    {
      "city": "Goodview",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Goodview, Virginia"
    },
    {
      "city": "Hardy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hardy, Virginia"
    },
    {
      "city": "Huddleston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Huddleston, Virginia"
    },
    {
      "city": "Moneta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Moneta, Virginia"
    },
    {
      "city": "Montvale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Montvale, Virginia"
    },
    {
      "city": "Thaxton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Thaxton, Virginia"
    },
    {
      "city": "Villamont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Villamont, Virginia"
    },
    {
      "city": "Big Island",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Big Island, Virginia"
    },
    {
      "city": "Coleman Falls",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Coleman Falls, Virginia"
    },
    {
      "city": "Forest",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Forest, Virginia"
    },
    {
      "city": "Goode",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Goode, Virginia"
    },
    {
      "city": "Lowry",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lowry, Virginia"
    },
    {
      "city": "Bastian",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bastian, Virginia"
    },
    {
      "city": "Bland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bland, Virginia"
    },
    {
      "city": "Ceres",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ceres, Virginia"
    },
    {
      "city": "Rocky Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rocky Gap, Virginia"
    },
    {
      "city": "Roanoke",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Roanoke, Virginia"
    },
    {
      "city": "Blue Ridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blue Ridge, Virginia"
    },
    {
      "city": "Buchanan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Buchanan, Virginia"
    },
    {
      "city": "Cloverdale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cloverdale, Virginia"
    },
    {
      "city": "Daleville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Daleville, Virginia"
    },
    {
      "city": "Eagle Rock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Eagle Rock, Virginia"
    },
    {
      "city": "Fincastle",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fincastle, Virginia"
    },
    {
      "city": "Oriskany",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oriskany, Virginia"
    },
    {
      "city": "Troutville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Troutville, Virginia"
    },
    {
      "city": "Glen Wilton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glen Wilton, Virginia"
    },
    {
      "city": "Alberta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Alberta, Virginia"
    },
    {
      "city": "Dolphin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dolphin, Virginia"
    },
    {
      "city": "Ebony",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ebony, Virginia"
    },
    {
      "city": "Freeman",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Freeman, Virginia"
    },
    {
      "city": "Gasburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gasburg, Virginia"
    },
    {
      "city": "Lawrenceville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lawrenceville, Virginia"
    },
    {
      "city": "Meredithville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Meredithville, Virginia"
    },
    {
      "city": "Rawlings",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rawlings, Virginia"
    },
    {
      "city": "Valentines",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Valentines, Virginia"
    },
    {
      "city": "Warfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Warfield, Virginia"
    },
    {
      "city": "White Plains",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "White Plains, Virginia"
    },
    {
      "city": "Brodnax",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brodnax, Virginia"
    },
    {
      "city": "Davenport",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Davenport, Virginia"
    },
    {
      "city": "Big Rock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Big Rock, Virginia"
    },
    {
      "city": "Grundy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Grundy, Virginia"
    },
    {
      "city": "Hurley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hurley, Virginia"
    },
    {
      "city": "Keen Mountain",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keen Mountain, Virginia"
    },
    {
      "city": "Mavisdale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mavisdale, Virginia"
    },
    {
      "city": "Maxie",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Maxie, Virginia"
    },
    {
      "city": "Oakwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oakwood, Virginia"
    },
    {
      "city": "Pilgrims Knob",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pilgrims Knob, Virginia"
    },
    {
      "city": "Raven",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Raven, Virginia"
    },
    {
      "city": "Rowe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rowe, Virginia"
    },
    {
      "city": "Shortt Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Shortt Gap, Virginia"
    },
    {
      "city": "Vansant",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vansant, Virginia"
    },
    {
      "city": "Whitewood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Whitewood, Virginia"
    },
    {
      "city": "Wolford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wolford, Virginia"
    },
    {
      "city": "Arvonia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Arvonia, Virginia"
    },
    {
      "city": "New Canton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Canton, Virginia"
    },
    {
      "city": "Buckingham",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Buckingham, Virginia"
    },
    {
      "city": "Dillwyn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dillwyn, Virginia"
    },
    {
      "city": "Lynchburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lynchburg, Virginia"
    },
    {
      "city": "Altavista",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Altavista, Virginia"
    },
    {
      "city": "Brookneal",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brookneal, Virginia"
    },
    {
      "city": "Concord",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Concord, Virginia"
    },
    {
      "city": "Evington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Evington, Virginia"
    },
    {
      "city": "Gladys",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gladys, Virginia"
    },
    {
      "city": "Long Island",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Long Island, Virginia"
    },
    {
      "city": "Lynch Station",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lynch Station, Virginia"
    },
    {
      "city": "Naruna",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Naruna, Virginia"
    },
    {
      "city": "Rustburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rustburg, Virginia"
    },
    {
      "city": "Bowling Green",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bowling Green, Virginia"
    },
    {
      "city": "Corbin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Corbin, Virginia"
    },
    {
      "city": "Ladysmith",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ladysmith, Virginia"
    },
    {
      "city": "Milford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Milford, Virginia"
    },
    {
      "city": "Port Royal",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Port Royal, Virginia"
    },
    {
      "city": "Rappahannock Academy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rappahannock Academy, Virginia"
    },
    {
      "city": "Ruther Glen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ruther Glen, Virginia"
    },
    {
      "city": "Sparta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sparta, Virginia"
    },
    {
      "city": "Woodford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodford, Virginia"
    },
    {
      "city": "Cana",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cana, Virginia"
    },
    {
      "city": "Dugspur",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dugspur, Virginia"
    },
    {
      "city": "Fancy Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fancy Gap, Virginia"
    },
    {
      "city": "Hillsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hillsville, Virginia"
    },
    {
      "city": "Lambsburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lambsburg, Virginia"
    },
    {
      "city": "Laurel Fork",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Laurel Fork, Virginia"
    },
    {
      "city": "Woodlawn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodlawn, Virginia"
    },
    {
      "city": "Charles City",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Charles City, Virginia"
    },
    {
      "city": "Ruthville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ruthville, Virginia"
    },
    {
      "city": "Charlotte Court House",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Charlotte Court House, Virginia"
    },
    {
      "city": "Cullen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cullen, Virginia"
    },
    {
      "city": "Drakes Branch",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Drakes Branch, Virginia"
    },
    {
      "city": "Keysville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keysville, Virginia"
    },
    {
      "city": "Phenix",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Phenix, Virginia"
    },
    {
      "city": "Randolph",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Randolph, Virginia"
    },
    {
      "city": "Red House",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Red House, Virginia"
    },
    {
      "city": "Red Oak",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Red Oak, Virginia"
    },
    {
      "city": "Saxe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saxe, Virginia"
    },
    {
      "city": "Wylliesburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wylliesburg, Virginia"
    },
    {
      "city": "Midlothian",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Midlothian, Virginia"
    },
    {
      "city": "Moseley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Moseley, Virginia"
    },
    {
      "city": "Richmond",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Richmond, Virginia"
    },
    {
      "city": "Chester",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chester, Virginia"
    },
    {
      "city": "Chesterfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chesterfield, Virginia"
    },
    {
      "city": "Berryville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Berryville, Virginia"
    },
    {
      "city": "Boyce",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boyce, Virginia"
    },
    {
      "city": "Millwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Millwood, Virginia"
    },
    {
      "city": "White Post",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "White Post, Virginia"
    },
    {
      "city": "New Castle",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Castle, Virginia"
    },
    {
      "city": "Paint Bank",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Paint Bank, Virginia"
    },
    {
      "city": "Culpeper",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Culpeper, Virginia"
    },
    {
      "city": "Boston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boston, Virginia"
    },
    {
      "city": "Brandy Station",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brandy Station, Virginia"
    },
    {
      "city": "Elkwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Elkwood, Virginia"
    },
    {
      "city": "Jeffersonton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jeffersonton, Virginia"
    },
    {
      "city": "Lignum",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lignum, Virginia"
    },
    {
      "city": "Mitchells",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mitchells, Virginia"
    },
    {
      "city": "Rapidan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rapidan, Virginia"
    },
    {
      "city": "Reva",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Reva, Virginia"
    },
    {
      "city": "Richardsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Richardsville, Virginia"
    },
    {
      "city": "Rixeyville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rixeyville, Virginia"
    },
    {
      "city": "Stevensburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stevensburg, Virginia"
    },
    {
      "city": "Cartersville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cartersville, Virginia"
    },
    {
      "city": "Cumberland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cumberland, Virginia"
    },
    {
      "city": "Bee",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bee, Virginia"
    },
    {
      "city": "Birchleaf",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Birchleaf, Virginia"
    },
    {
      "city": "Clinchco",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clinchco, Virginia"
    },
    {
      "city": "Clintwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clintwood, Virginia"
    },
    {
      "city": "Haysi",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Haysi, Virginia"
    },
    {
      "city": "Mc Clure",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Clure, Virginia"
    },
    {
      "city": "Nora",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nora, Virginia"
    },
    {
      "city": "Breaks",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Breaks, Virginia"
    },
    {
      "city": "Ammon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ammon, Virginia"
    },
    {
      "city": "Carson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Carson, Virginia"
    },
    {
      "city": "Church Road",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Church Road, Virginia"
    },
    {
      "city": "Dewitt",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dewitt, Virginia"
    },
    {
      "city": "Dinwiddie",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dinwiddie, Virginia"
    },
    {
      "city": "Ford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ford, Virginia"
    },
    {
      "city": "Mc Kenney",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Kenney, Virginia"
    },
    {
      "city": "Sutherland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sutherland, Virginia"
    },
    {
      "city": "Wilsons",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wilsons, Virginia"
    },
    {
      "city": "Caret",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Caret, Virginia"
    },
    {
      "city": "Center Cross",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Center Cross, Virginia"
    },
    {
      "city": "Champlain",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Champlain, Virginia"
    },
    {
      "city": "Dunnsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dunnsville, Virginia"
    },
    {
      "city": "Hustle",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hustle, Virginia"
    },
    {
      "city": "Laneview",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Laneview, Virginia"
    },
    {
      "city": "Loretto",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Loretto, Virginia"
    },
    {
      "city": "Tappahannock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tappahannock, Virginia"
    },
    {
      "city": "Millers Tavern",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Millers Tavern, Virginia"
    },
    {
      "city": "Centreville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Centreville, Virginia"
    },
    {
      "city": "Clifton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clifton, Virginia"
    },
    {
      "city": "Chantilly",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chantilly, Virginia"
    },
    {
      "city": "Herndon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Herndon, Virginia"
    },
    {
      "city": "Reston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Reston, Virginia"
    },
    {
      "city": "Annandale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Annandale, Virginia"
    },
    {
      "city": "Burke",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Burke, Virginia"
    },
    {
      "city": "Dunn Loring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dunn Loring, Virginia"
    },
    {
      "city": "Fairfax",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fairfax, Virginia"
    },
    {
      "city": "Fairfax Station",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fairfax Station, Virginia"
    },
    {
      "city": "Falls Church",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Falls Church, Virginia"
    },
    {
      "city": "Fort Belvoir",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Belvoir, Virginia"
    },
    {
      "city": "Great Falls",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Great Falls, Virginia"
    },
    {
      "city": "Greenway",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Greenway, Virginia"
    },
    {
      "city": "Lorton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lorton, Virginia"
    },
    {
      "city": "Merrifield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Merrifield, Virginia"
    },
    {
      "city": "Mc Lean",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Lean, Virginia"
    },
    {
      "city": "West Mclean",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "West Mclean, Virginia"
    },
    {
      "city": "Mount Vernon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Vernon, Virginia"
    },
    {
      "city": "Newington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newington, Virginia"
    },
    {
      "city": "Oakton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oakton, Virginia"
    },
    {
      "city": "Springfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Springfield, Virginia"
    },
    {
      "city": "Vienna",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vienna, Virginia"
    },
    {
      "city": "Alexandria",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Alexandria, Virginia"
    },
    {
      "city": "Marshall",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Marshall, Virginia"
    },
    {
      "city": "Catlett",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Catlett, Virginia"
    },
    {
      "city": "Orlean",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Orlean, Virginia"
    },
    {
      "city": "Paris",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Paris, Virginia"
    },
    {
      "city": "Broad Run",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Broad Run, Virginia"
    },
    {
      "city": "Calverton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Calverton, Virginia"
    },
    {
      "city": "Casanova",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Casanova, Virginia"
    },
    {
      "city": "Rectortown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rectortown, Virginia"
    },
    {
      "city": "Delaplane",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Delaplane, Virginia"
    },
    {
      "city": "Upperville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Upperville, Virginia"
    },
    {
      "city": "Warrenton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Warrenton, Virginia"
    },
    {
      "city": "The Plains",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "The Plains, Virginia"
    },
    {
      "city": "Hume",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hume, Virginia"
    },
    {
      "city": "Markham",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Markham, Virginia"
    },
    {
      "city": "Bealeton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bealeton, Virginia"
    },
    {
      "city": "Goldvein",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Goldvein, Virginia"
    },
    {
      "city": "Midland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Midland, Virginia"
    },
    {
      "city": "Remington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Remington, Virginia"
    },
    {
      "city": "Somerville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Somerville, Virginia"
    },
    {
      "city": "Sumerduck",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sumerduck, Virginia"
    },
    {
      "city": "Check",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Check, Virginia"
    },
    {
      "city": "Copper Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Copper Hill, Virginia"
    },
    {
      "city": "Floyd",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Floyd, Virginia"
    },
    {
      "city": "Indian Valley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Indian Valley, Virginia"
    },
    {
      "city": "Willis",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Willis, Virginia"
    },
    {
      "city": "Palmyra",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Palmyra, Virginia"
    },
    {
      "city": "Troy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Troy, Virginia"
    },
    {
      "city": "Bremo Bluff",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bremo Bluff, Virginia"
    },
    {
      "city": "Fork Union",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fork Union, Virginia"
    },
    {
      "city": "Kents Store",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Kents Store, Virginia"
    },
    {
      "city": "Boones Mill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boones Mill, Virginia"
    },
    {
      "city": "Callaway",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Callaway, Virginia"
    },
    {
      "city": "Ferrum",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ferrum, Virginia"
    },
    {
      "city": "Glade Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glade Hill, Virginia"
    },
    {
      "city": "Henry",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Henry, Virginia"
    },
    {
      "city": "Penhook",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Penhook, Virginia"
    },
    {
      "city": "Redwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Redwood, Virginia"
    },
    {
      "city": "Rocky Mount",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rocky Mount, Virginia"
    },
    {
      "city": "Union Hall",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Union Hall, Virginia"
    },
    {
      "city": "Wirtz",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wirtz, Virginia"
    },
    {
      "city": "Winchester",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Winchester, Virginia"
    },
    {
      "city": "Brucetown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brucetown, Virginia"
    },
    {
      "city": "Clear Brook",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clear Brook, Virginia"
    },
    {
      "city": "Cross Junction",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cross Junction, Virginia"
    },
    {
      "city": "Gore",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gore, Virginia"
    },
    {
      "city": "Middletown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Middletown, Virginia"
    },
    {
      "city": "Star Tannery",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Star Tannery, Virginia"
    },
    {
      "city": "Stephens City",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stephens City, Virginia"
    },
    {
      "city": "Stephenson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stephenson, Virginia"
    },
    {
      "city": "Eggleston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Eggleston, Virginia"
    },
    {
      "city": "Glen Lyn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glen Lyn, Virginia"
    },
    {
      "city": "Narrows",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Narrows, Virginia"
    },
    {
      "city": "Newport",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newport, Virginia"
    },
    {
      "city": "Pearisburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pearisburg, Virginia"
    },
    {
      "city": "Pembroke",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pembroke, Virginia"
    },
    {
      "city": "Rich Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rich Creek, Virginia"
    },
    {
      "city": "Ripplemead",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ripplemead, Virginia"
    },
    {
      "city": "Staffordsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Staffordsville, Virginia"
    },
    {
      "city": "Achilles",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Achilles, Virginia"
    },
    {
      "city": "Ark",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ark, Virginia"
    },
    {
      "city": "Bena",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bena, Virginia"
    },
    {
      "city": "Dutton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dutton, Virginia"
    },
    {
      "city": "Gloucester",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gloucester, Virginia"
    },
    {
      "city": "Gloucester Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gloucester Point, Virginia"
    },
    {
      "city": "Hayes",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hayes, Virginia"
    },
    {
      "city": "Maryus",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Maryus, Virginia"
    },
    {
      "city": "Ordinary",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ordinary, Virginia"
    },
    {
      "city": "Schley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Schley, Virginia"
    },
    {
      "city": "Severn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Severn, Virginia"
    },
    {
      "city": "Ware Neck",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ware Neck, Virginia"
    },
    {
      "city": "White Marsh",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "White Marsh, Virginia"
    },
    {
      "city": "Wicomico",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wicomico, Virginia"
    },
    {
      "city": "Woods Cross Roads",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woods Cross Roads, Virginia"
    },
    {
      "city": "Beaumont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Beaumont, Virginia"
    },
    {
      "city": "Columbia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Columbia, Virginia"
    },
    {
      "city": "Crozier",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crozier, Virginia"
    },
    {
      "city": "Goochland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Goochland, Virginia"
    },
    {
      "city": "Gum Spring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gum Spring, Virginia"
    },
    {
      "city": "Hadensville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hadensville, Virginia"
    },
    {
      "city": "Maidens",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Maidens, Virginia"
    },
    {
      "city": "Manakin Sabot",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Manakin Sabot, Virginia"
    },
    {
      "city": "Oilville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oilville, Virginia"
    },
    {
      "city": "Sandy Hook",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sandy Hook, Virginia"
    },
    {
      "city": "State Farm",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "State Farm, Virginia"
    },
    {
      "city": "Whitetop",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Whitetop, Virginia"
    },
    {
      "city": "Elk Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Elk Creek, Virginia"
    },
    {
      "city": "Fries",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fries, Virginia"
    },
    {
      "city": "Independence",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Independence, Virginia"
    },
    {
      "city": "Mouth Of Wilson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mouth Of Wilson, Virginia"
    },
    {
      "city": "Troutdale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Troutdale, Virginia"
    },
    {
      "city": "Dyke",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dyke, Virginia"
    },
    {
      "city": "Quinque",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Quinque, Virginia"
    },
    {
      "city": "Ruckersville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ruckersville, Virginia"
    },
    {
      "city": "Stanardsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stanardsville, Virginia"
    },
    {
      "city": "Jarratt",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jarratt, Virginia"
    },
    {
      "city": "Skippers",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Skippers, Virginia"
    },
    {
      "city": "Alton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Alton, Virginia"
    },
    {
      "city": "Clover",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clover, Virginia"
    },
    {
      "city": "Cluster Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cluster Springs, Virginia"
    },
    {
      "city": "Crystal Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crystal Hill, Virginia"
    },
    {
      "city": "Halifax",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Halifax, Virginia"
    },
    {
      "city": "Nathalie",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nathalie, Virginia"
    },
    {
      "city": "Scottsburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Scottsburg, Virginia"
    },
    {
      "city": "South Boston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "South Boston, Virginia"
    },
    {
      "city": "Vernon Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vernon Hill, Virginia"
    },
    {
      "city": "Virgilina",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Virgilina, Virginia"
    },
    {
      "city": "Ashland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ashland, Virginia"
    },
    {
      "city": "Beaverdam",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Beaverdam, Virginia"
    },
    {
      "city": "Doswell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Doswell, Virginia"
    },
    {
      "city": "Glen Allen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glen Allen, Virginia"
    },
    {
      "city": "Hanover",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hanover, Virginia"
    },
    {
      "city": "Mechanicsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mechanicsville, Virginia"
    },
    {
      "city": "Rockville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rockville, Virginia"
    },
    {
      "city": "Studley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Studley, Virginia"
    },
    {
      "city": "Montpelier",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Montpelier, Virginia"
    },
    {
      "city": "Henrico",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Henrico, Virginia"
    },
    {
      "city": "Sandston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sandston, Virginia"
    },
    {
      "city": "Axton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Axton, Virginia"
    },
    {
      "city": "Bassett",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bassett, Virginia"
    },
    {
      "city": "Collinsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Collinsville, Virginia"
    },
    {
      "city": "Fieldale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fieldale, Virginia"
    },
    {
      "city": "Ridgeway",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ridgeway, Virginia"
    },
    {
      "city": "Spencer",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Spencer, Virginia"
    },
    {
      "city": "Stanleytown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stanleytown, Virginia"
    },
    {
      "city": "Blue Grass",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blue Grass, Virginia"
    },
    {
      "city": "Doe Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Doe Hill, Virginia"
    },
    {
      "city": "Head Waters",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Head Waters, Virginia"
    },
    {
      "city": "Mc Dowell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Dowell, Virginia"
    },
    {
      "city": "Monterey",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Monterey, Virginia"
    },
    {
      "city": "Mustoe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mustoe, Virginia"
    },
    {
      "city": "Battery Park",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Battery Park, Virginia"
    },
    {
      "city": "Carrollton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Carrollton, Virginia"
    },
    {
      "city": "Carrsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Carrsville, Virginia"
    },
    {
      "city": "Isle Of Wight",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Isle Of Wight, Virginia"
    },
    {
      "city": "Rescue",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rescue, Virginia"
    },
    {
      "city": "Smithfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Smithfield, Virginia"
    },
    {
      "city": "Windsor",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Windsor, Virginia"
    },
    {
      "city": "Zuni",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Zuni, Virginia"
    },
    {
      "city": "Jamestown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jamestown, Virginia"
    },
    {
      "city": "Norge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Norge, Virginia"
    },
    {
      "city": "Toano",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Toano, Virginia"
    },
    {
      "city": "Williamsburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Williamsburg, Virginia"
    },
    {
      "city": "Bruington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bruington, Virginia"
    },
    {
      "city": "King And Queen Court House",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "King And Queen Court House, Virginia"
    },
    {
      "city": "Little Plymouth",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Little Plymouth, Virginia"
    },
    {
      "city": "Mascot",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mascot, Virginia"
    },
    {
      "city": "Mattaponi",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mattaponi, Virginia"
    },
    {
      "city": "Newtown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newtown, Virginia"
    },
    {
      "city": "Saint Stephens Church",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saint Stephens Church, Virginia"
    },
    {
      "city": "Shacklefords",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Shacklefords, Virginia"
    },
    {
      "city": "Stevensville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stevensville, Virginia"
    },
    {
      "city": "Walkerton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Walkerton, Virginia"
    },
    {
      "city": "Dahlgren",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dahlgren, Virginia"
    },
    {
      "city": "Dogue",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dogue, Virginia"
    },
    {
      "city": "Jersey",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jersey, Virginia"
    },
    {
      "city": "King George",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "King George, Virginia"
    },
    {
      "city": "Ninde",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ninde, Virginia"
    },
    {
      "city": "Rollins Fork",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rollins Fork, Virginia"
    },
    {
      "city": "Sealston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sealston, Virginia"
    },
    {
      "city": "Aylett",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Aylett, Virginia"
    },
    {
      "city": "King William",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "King William, Virginia"
    },
    {
      "city": "Manquin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Manquin, Virginia"
    },
    {
      "city": "West Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "West Point, Virginia"
    },
    {
      "city": "Irvington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Irvington, Virginia"
    },
    {
      "city": "Kilmarnock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Kilmarnock, Virginia"
    },
    {
      "city": "Lancaster",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lancaster, Virginia"
    },
    {
      "city": "Lively",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lively, Virginia"
    },
    {
      "city": "Merry Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Merry Point, Virginia"
    },
    {
      "city": "Mollusk",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mollusk, Virginia"
    },
    {
      "city": "Morattico",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Morattico, Virginia"
    },
    {
      "city": "Nuttsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nuttsville, Virginia"
    },
    {
      "city": "Weems",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Weems, Virginia"
    },
    {
      "city": "White Stone",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "White Stone, Virginia"
    },
    {
      "city": "Ben Hur",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ben Hur, Virginia"
    },
    {
      "city": "Blackwater",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blackwater, Virginia"
    },
    {
      "city": "Dryden",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dryden, Virginia"
    },
    {
      "city": "Ewing",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ewing, Virginia"
    },
    {
      "city": "Jonesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jonesville, Virginia"
    },
    {
      "city": "Keokee",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keokee, Virginia"
    },
    {
      "city": "Pennington Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pennington Gap, Virginia"
    },
    {
      "city": "Rose Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rose Hill, Virginia"
    },
    {
      "city": "Saint Charles",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saint Charles, Virginia"
    },
    {
      "city": "Dulles",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dulles, Virginia"
    },
    {
      "city": "Aldie",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Aldie, Virginia"
    },
    {
      "city": "Middleburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Middleburg, Virginia"
    },
    {
      "city": "Paeonian Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Paeonian Springs, Virginia"
    },
    {
      "city": "Philomont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Philomont, Virginia"
    },
    {
      "city": "Purcellville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Purcellville, Virginia"
    },
    {
      "city": "Bluemont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bluemont, Virginia"
    },
    {
      "city": "Round Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Round Hill, Virginia"
    },
    {
      "city": "Ashburn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ashburn, Virginia"
    },
    {
      "city": "Hamilton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hamilton, Virginia"
    },
    {
      "city": "Lincoln",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lincoln, Virginia"
    },
    {
      "city": "Sterling",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sterling, Virginia"
    },
    {
      "city": "Leesburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Leesburg, Virginia"
    },
    {
      "city": "Lovettsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lovettsville, Virginia"
    },
    {
      "city": "Waterford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Waterford, Virginia"
    },
    {
      "city": "Dhs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dhs, Virginia"
    },
    {
      "city": "Bumpass",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bumpass, Virginia"
    },
    {
      "city": "Louisa",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Louisa, Virginia"
    },
    {
      "city": "Mineral",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mineral, Virginia"
    },
    {
      "city": "Trevilians",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Trevilians, Virginia"
    },
    {
      "city": "Dundas",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dundas, Virginia"
    },
    {
      "city": "Fort Mitchell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Mitchell, Virginia"
    },
    {
      "city": "Kenbridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Kenbridge, Virginia"
    },
    {
      "city": "Lunenburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lunenburg, Virginia"
    },
    {
      "city": "Victoria",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Victoria, Virginia"
    },
    {
      "city": "Aroda",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Aroda, Virginia"
    },
    {
      "city": "Banco",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Banco, Virginia"
    },
    {
      "city": "Brightwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brightwood, Virginia"
    },
    {
      "city": "Etlan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Etlan, Virginia"
    },
    {
      "city": "Haywood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Haywood, Virginia"
    },
    {
      "city": "Hood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hood, Virginia"
    },
    {
      "city": "Leon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Leon, Virginia"
    },
    {
      "city": "Madison",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Madison, Virginia"
    },
    {
      "city": "Oakpark",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oakpark, Virginia"
    },
    {
      "city": "Pratts",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pratts, Virginia"
    },
    {
      "city": "Radiant",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Radiant, Virginia"
    },
    {
      "city": "Rochelle",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rochelle, Virginia"
    },
    {
      "city": "Syria",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Syria, Virginia"
    },
    {
      "city": "Wolftown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wolftown, Virginia"
    },
    {
      "city": "Locust Dale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Locust Dale, Virginia"
    },
    {
      "city": "Woodberry Forest",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodberry Forest, Virginia"
    },
    {
      "city": "Bohannon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bohannon, Virginia"
    },
    {
      "city": "Cardinal",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cardinal, Virginia"
    },
    {
      "city": "Cobbs Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cobbs Creek, Virginia"
    },
    {
      "city": "Diggs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Diggs, Virginia"
    },
    {
      "city": "Foster",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Foster, Virginia"
    },
    {
      "city": "Grimstead",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Grimstead, Virginia"
    },
    {
      "city": "Gwynn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gwynn, Virginia"
    },
    {
      "city": "Hallieford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hallieford, Virginia"
    },
    {
      "city": "Hudgins",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hudgins, Virginia"
    },
    {
      "city": "Mathews",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mathews, Virginia"
    },
    {
      "city": "Moon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Moon, Virginia"
    },
    {
      "city": "New Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Point, Virginia"
    },
    {
      "city": "North",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "North, Virginia"
    },
    {
      "city": "Onemo",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Onemo, Virginia"
    },
    {
      "city": "Port Haywood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Port Haywood, Virginia"
    },
    {
      "city": "Susan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Susan, Virginia"
    },
    {
      "city": "Baskerville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Baskerville, Virginia"
    },
    {
      "city": "Boydton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boydton, Virginia"
    },
    {
      "city": "Bracey",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bracey, Virginia"
    },
    {
      "city": "Chase City",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chase City, Virginia"
    },
    {
      "city": "Clarksville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Clarksville, Virginia"
    },
    {
      "city": "La Crosse",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "La Crosse, Virginia"
    },
    {
      "city": "Skipwith",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Skipwith, Virginia"
    },
    {
      "city": "South Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "South Hill, Virginia"
    },
    {
      "city": "Buffalo Junction",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Buffalo Junction, Virginia"
    },
    {
      "city": "Nelson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nelson, Virginia"
    },
    {
      "city": "Christchurch",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Christchurch, Virginia"
    },
    {
      "city": "Church View",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Church View, Virginia"
    },
    {
      "city": "Deltaville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Deltaville, Virginia"
    },
    {
      "city": "Hardyville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hardyville, Virginia"
    },
    {
      "city": "Hartfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hartfield, Virginia"
    },
    {
      "city": "Jamaica",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jamaica, Virginia"
    },
    {
      "city": "Locust Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Locust Hill, Virginia"
    },
    {
      "city": "Saluda",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saluda, Virginia"
    },
    {
      "city": "Topping",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Topping, Virginia"
    },
    {
      "city": "Urbanna",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Urbanna, Virginia"
    },
    {
      "city": "Wake",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wake, Virginia"
    },
    {
      "city": "Water View",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Water View, Virginia"
    },
    {
      "city": "Blacksburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blacksburg, Virginia"
    },
    {
      "city": "Christiansburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Christiansburg, Virginia"
    },
    {
      "city": "Mc Coy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Coy, Virginia"
    },
    {
      "city": "Pilot",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pilot, Virginia"
    },
    {
      "city": "Riner",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Riner, Virginia"
    },
    {
      "city": "Shawsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Shawsville, Virginia"
    },
    {
      "city": "Afton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Afton, Virginia"
    },
    {
      "city": "Arrington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Arrington, Virginia"
    },
    {
      "city": "Faber",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Faber, Virginia"
    },
    {
      "city": "Lovingston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lovingston, Virginia"
    },
    {
      "city": "Nellysford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nellysford, Virginia"
    },
    {
      "city": "Piney River",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Piney River, Virginia"
    },
    {
      "city": "Roseland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Roseland, Virginia"
    },
    {
      "city": "Schuyler",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Schuyler, Virginia"
    },
    {
      "city": "Shipman",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Shipman, Virginia"
    },
    {
      "city": "Tyro",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tyro, Virginia"
    },
    {
      "city": "Montebello",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Montebello, Virginia"
    },
    {
      "city": "Gladstone",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gladstone, Virginia"
    },
    {
      "city": "Norwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Norwood, Virginia"
    },
    {
      "city": "Wingina",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wingina, Virginia"
    },
    {
      "city": "Barhamsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Barhamsville, Virginia"
    },
    {
      "city": "Lanexa",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lanexa, Virginia"
    },
    {
      "city": "New Kent",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Kent, Virginia"
    },
    {
      "city": "Providence Forge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Providence Forge, Virginia"
    },
    {
      "city": "Quinton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Quinton, Virginia"
    },
    {
      "city": "Birdsnest",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Birdsnest, Virginia"
    },
    {
      "city": "Cape Charles",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cape Charles, Virginia"
    },
    {
      "city": "Capeville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Capeville, Virginia"
    },
    {
      "city": "Cheriton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cheriton, Virginia"
    },
    {
      "city": "Eastville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Eastville, Virginia"
    },
    {
      "city": "Exmore",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Exmore, Virginia"
    },
    {
      "city": "Franktown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Franktown, Virginia"
    },
    {
      "city": "Jamesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jamesville, Virginia"
    },
    {
      "city": "Machipongo",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Machipongo, Virginia"
    },
    {
      "city": "Marionville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Marionville, Virginia"
    },
    {
      "city": "Nassawadox",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nassawadox, Virginia"
    },
    {
      "city": "Oyster",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oyster, Virginia"
    },
    {
      "city": "Seaview",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Seaview, Virginia"
    },
    {
      "city": "Townsend",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Townsend, Virginia"
    },
    {
      "city": "Wardtown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wardtown, Virginia"
    },
    {
      "city": "Willis Wharf",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Willis Wharf, Virginia"
    },
    {
      "city": "Burgess",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Burgess, Virginia"
    },
    {
      "city": "Callao",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Callao, Virginia"
    },
    {
      "city": "Edwardsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Edwardsville, Virginia"
    },
    {
      "city": "Heathsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Heathsville, Virginia"
    },
    {
      "city": "Lottsburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lottsburg, Virginia"
    },
    {
      "city": "Ophelia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ophelia, Virginia"
    },
    {
      "city": "Reedville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Reedville, Virginia"
    },
    {
      "city": "Wicomico Church",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wicomico Church, Virginia"
    },
    {
      "city": "Blackstone",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blackstone, Virginia"
    },
    {
      "city": "Burkeville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Burkeville, Virginia"
    },
    {
      "city": "Crewe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crewe, Virginia"
    },
    {
      "city": "Nottoway",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nottoway, Virginia"
    },
    {
      "city": "Burr Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Burr Hill, Virginia"
    },
    {
      "city": "Locust Grove",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Locust Grove, Virginia"
    },
    {
      "city": "Rhoadesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rhoadesville, Virginia"
    },
    {
      "city": "Unionville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Unionville, Virginia"
    },
    {
      "city": "Barboursville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Barboursville, Virginia"
    },
    {
      "city": "Gordonsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gordonsville, Virginia"
    },
    {
      "city": "Montpelier Station",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Montpelier Station, Virginia"
    },
    {
      "city": "Orange",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Orange, Virginia"
    },
    {
      "city": "Somerset",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Somerset, Virginia"
    },
    {
      "city": "Rileyville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rileyville, Virginia"
    },
    {
      "city": "Luray",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Luray, Virginia"
    },
    {
      "city": "Shenandoah",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Shenandoah, Virginia"
    },
    {
      "city": "Stanley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stanley, Virginia"
    },
    {
      "city": "Ararat",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ararat, Virginia"
    },
    {
      "city": "Claudville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Claudville, Virginia"
    },
    {
      "city": "Critz",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Critz, Virginia"
    },
    {
      "city": "Meadows Of Dan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Meadows Of Dan, Virginia"
    },
    {
      "city": "Patrick Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Patrick Springs, Virginia"
    },
    {
      "city": "Stuart",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stuart, Virginia"
    },
    {
      "city": "Vesta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vesta, Virginia"
    },
    {
      "city": "Woolwine",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woolwine, Virginia"
    },
    {
      "city": "Cascade",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cascade, Virginia"
    },
    {
      "city": "Pittsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pittsville, Virginia"
    },
    {
      "city": "Sandy Level",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sandy Level, Virginia"
    },
    {
      "city": "Blairs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Blairs, Virginia"
    },
    {
      "city": "Callands",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Callands, Virginia"
    },
    {
      "city": "Chatham",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chatham, Virginia"
    },
    {
      "city": "Dry Fork",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dry Fork, Virginia"
    },
    {
      "city": "Gretna",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gretna, Virginia"
    },
    {
      "city": "Hurt",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hurt, Virginia"
    },
    {
      "city": "Java",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Java, Virginia"
    },
    {
      "city": "Keeling",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keeling, Virginia"
    },
    {
      "city": "Ringgold",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ringgold, Virginia"
    },
    {
      "city": "Sutherlin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sutherlin, Virginia"
    },
    {
      "city": "Powhatan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Powhatan, Virginia"
    },
    {
      "city": "Farmville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Farmville, Virginia"
    },
    {
      "city": "Green Bay",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Green Bay, Virginia"
    },
    {
      "city": "Hampden Sydney",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hampden Sydney, Virginia"
    },
    {
      "city": "Meherrin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Meherrin, Virginia"
    },
    {
      "city": "Prospect",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Prospect, Virginia"
    },
    {
      "city": "Rice",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rice, Virginia"
    },
    {
      "city": "Fort Lee",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Lee, Virginia"
    },
    {
      "city": "Disputanta",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Disputanta, Virginia"
    },
    {
      "city": "Prince George",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Prince George, Virginia"
    },
    {
      "city": "Bristow",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bristow, Virginia"
    },
    {
      "city": "Catharpin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Catharpin, Virginia"
    },
    {
      "city": "Gainesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gainesville, Virginia"
    },
    {
      "city": "Haymarket",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Haymarket, Virginia"
    },
    {
      "city": "Nokesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nokesville, Virginia"
    },
    {
      "city": "Dumfries",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dumfries, Virginia"
    },
    {
      "city": "Occoquan",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Occoquan, Virginia"
    },
    {
      "city": "Quantico",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Quantico, Virginia"
    },
    {
      "city": "Triangle",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Triangle, Virginia"
    },
    {
      "city": "Woodbridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodbridge, Virginia"
    },
    {
      "city": "Belspring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Belspring, Virginia"
    },
    {
      "city": "Dublin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dublin, Virginia"
    },
    {
      "city": "Newbern",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newbern, Virginia"
    },
    {
      "city": "New River",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New River, Virginia"
    },
    {
      "city": "Parrott",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Parrott, Virginia"
    },
    {
      "city": "Pulaski",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pulaski, Virginia"
    },
    {
      "city": "Draper",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Draper, Virginia"
    },
    {
      "city": "Hiwassee",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hiwassee, Virginia"
    },
    {
      "city": "Amissville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Amissville, Virginia"
    },
    {
      "city": "Chester Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chester Gap, Virginia"
    },
    {
      "city": "Flint Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Flint Hill, Virginia"
    },
    {
      "city": "Huntly",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Huntly, Virginia"
    },
    {
      "city": "Castleton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Castleton, Virginia"
    },
    {
      "city": "Sperryville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sperryville, Virginia"
    },
    {
      "city": "Viewtown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Viewtown, Virginia"
    },
    {
      "city": "Washington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Washington, Virginia"
    },
    {
      "city": "Woodville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodville, Virginia"
    },
    {
      "city": "Farnham",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Farnham, Virginia"
    },
    {
      "city": "Haynesville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Haynesville, Virginia"
    },
    {
      "city": "Sharps",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sharps, Virginia"
    },
    {
      "city": "Village",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Village, Virginia"
    },
    {
      "city": "Warsaw",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Warsaw, Virginia"
    },
    {
      "city": "Bent Mountain",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bent Mountain, Virginia"
    },
    {
      "city": "Catawba",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Catawba, Virginia"
    },
    {
      "city": "Elliston",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Elliston, Virginia"
    },
    {
      "city": "Vinton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vinton, Virginia"
    },
    {
      "city": "Brownsburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brownsburg, Virginia"
    },
    {
      "city": "Fairfield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fairfield, Virginia"
    },
    {
      "city": "Goshen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Goshen, Virginia"
    },
    {
      "city": "Raphine",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Raphine, Virginia"
    },
    {
      "city": "Rockbridge Baths",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rockbridge Baths, Virginia"
    },
    {
      "city": "Vesuvius",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Vesuvius, Virginia"
    },
    {
      "city": "Glasgow",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glasgow, Virginia"
    },
    {
      "city": "Natural Bridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Natural Bridge, Virginia"
    },
    {
      "city": "Natural Bridge Station",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Natural Bridge Station, Virginia"
    },
    {
      "city": "Harrisonburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Harrisonburg, Virginia"
    },
    {
      "city": "Bergton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bergton, Virginia"
    },
    {
      "city": "Bridgewater",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bridgewater, Virginia"
    },
    {
      "city": "Broadway",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Broadway, Virginia"
    },
    {
      "city": "Criders",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Criders, Virginia"
    },
    {
      "city": "Dayton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dayton, Virginia"
    },
    {
      "city": "Elkton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Elkton, Virginia"
    },
    {
      "city": "Fulks Run",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fulks Run, Virginia"
    },
    {
      "city": "Hinton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hinton, Virginia"
    },
    {
      "city": "Keezletown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Keezletown, Virginia"
    },
    {
      "city": "Lacey Spring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lacey Spring, Virginia"
    },
    {
      "city": "Linville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Linville, Virginia"
    },
    {
      "city": "Mc Gaheysville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mc Gaheysville, Virginia"
    },
    {
      "city": "Mount Crawford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Crawford, Virginia"
    },
    {
      "city": "Penn Laird",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Penn Laird, Virginia"
    },
    {
      "city": "Pleasant Valley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pleasant Valley, Virginia"
    },
    {
      "city": "Singers Glen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Singers Glen, Virginia"
    },
    {
      "city": "Timberville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Timberville, Virginia"
    },
    {
      "city": "Grottoes",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Grottoes, Virginia"
    },
    {
      "city": "Port Republic",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Port Republic, Virginia"
    },
    {
      "city": "Castlewood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Castlewood, Virginia"
    },
    {
      "city": "Cleveland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cleveland, Virginia"
    },
    {
      "city": "Dante",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dante, Virginia"
    },
    {
      "city": "Honaker",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Honaker, Virginia"
    },
    {
      "city": "Lebanon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lebanon, Virginia"
    },
    {
      "city": "Rosedale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rosedale, Virginia"
    },
    {
      "city": "Swords Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Swords Creek, Virginia"
    },
    {
      "city": "Duffield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Duffield, Virginia"
    },
    {
      "city": "Dungannon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dungannon, Virginia"
    },
    {
      "city": "Fort Blackmore",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Blackmore, Virginia"
    },
    {
      "city": "Gate City",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Gate City, Virginia"
    },
    {
      "city": "Hiltons",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hiltons, Virginia"
    },
    {
      "city": "Nickelsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Nickelsville, Virginia"
    },
    {
      "city": "Weber City",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Weber City, Virginia"
    },
    {
      "city": "Fishers Hill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fishers Hill, Virginia"
    },
    {
      "city": "Strasburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Strasburg, Virginia"
    },
    {
      "city": "Maurertown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Maurertown, Virginia"
    },
    {
      "city": "Fort Valley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Valley, Virginia"
    },
    {
      "city": "Toms Brook",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Toms Brook, Virginia"
    },
    {
      "city": "Woodstock",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Woodstock, Virginia"
    },
    {
      "city": "Basye",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Basye, Virginia"
    },
    {
      "city": "Edinburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Edinburg, Virginia"
    },
    {
      "city": "Mount Jackson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Jackson, Virginia"
    },
    {
      "city": "New Market",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "New Market, Virginia"
    },
    {
      "city": "Orkney Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Orkney Springs, Virginia"
    },
    {
      "city": "Quicksburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Quicksburg, Virginia"
    },
    {
      "city": "Atkins",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Atkins, Virginia"
    },
    {
      "city": "Broadford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Broadford, Virginia"
    },
    {
      "city": "Chilhowie",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chilhowie, Virginia"
    },
    {
      "city": "Marion",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Marion, Virginia"
    },
    {
      "city": "Saltville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saltville, Virginia"
    },
    {
      "city": "Sugar Grove",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sugar Grove, Virginia"
    },
    {
      "city": "Boykins",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boykins, Virginia"
    },
    {
      "city": "Branchville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Branchville, Virginia"
    },
    {
      "city": "Capron",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Capron, Virginia"
    },
    {
      "city": "Courtland",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Courtland, Virginia"
    },
    {
      "city": "Drewryville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Drewryville, Virginia"
    },
    {
      "city": "Ivor",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ivor, Virginia"
    },
    {
      "city": "Newsoms",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newsoms, Virginia"
    },
    {
      "city": "Sedley",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sedley, Virginia"
    },
    {
      "city": "Fredericksburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fredericksburg, Virginia"
    },
    {
      "city": "Partlow",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Partlow, Virginia"
    },
    {
      "city": "Spotsylvania",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Spotsylvania, Virginia"
    },
    {
      "city": "Thornburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Thornburg, Virginia"
    },
    {
      "city": "Brooke",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Brooke, Virginia"
    },
    {
      "city": "Garrisonville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Garrisonville, Virginia"
    },
    {
      "city": "Hartwood",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hartwood, Virginia"
    },
    {
      "city": "Ruby",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ruby, Virginia"
    },
    {
      "city": "Stafford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stafford, Virginia"
    },
    {
      "city": "Dendron",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Dendron, Virginia"
    },
    {
      "city": "Elberon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Elberon, Virginia"
    },
    {
      "city": "Spring Grove",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Spring Grove, Virginia"
    },
    {
      "city": "Surry",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Surry, Virginia"
    },
    {
      "city": "Claremont",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Claremont, Virginia"
    },
    {
      "city": "Stony Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stony Creek, Virginia"
    },
    {
      "city": "Sussex",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sussex, Virginia"
    },
    {
      "city": "Wakefield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wakefield, Virginia"
    },
    {
      "city": "Waverly",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Waverly, Virginia"
    },
    {
      "city": "Yale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Yale, Virginia"
    },
    {
      "city": "Tannersville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tannersville, Virginia"
    },
    {
      "city": "Amonate",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Amonate, Virginia"
    },
    {
      "city": "Bandy",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bandy, Virginia"
    },
    {
      "city": "Bishop",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bishop, Virginia"
    },
    {
      "city": "Bluefield",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bluefield, Virginia"
    },
    {
      "city": "Boissevain",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Boissevain, Virginia"
    },
    {
      "city": "Burkes Garden",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Burkes Garden, Virginia"
    },
    {
      "city": "Cedar Bluff",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cedar Bluff, Virginia"
    },
    {
      "city": "Doran",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Doran, Virginia"
    },
    {
      "city": "Falls Mills",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Falls Mills, Virginia"
    },
    {
      "city": "Horsepen",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Horsepen, Virginia"
    },
    {
      "city": "Jewell Ridge",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Jewell Ridge, Virginia"
    },
    {
      "city": "North Tazewell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "North Tazewell, Virginia"
    },
    {
      "city": "Pocahontas",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pocahontas, Virginia"
    },
    {
      "city": "Pounding Mill",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pounding Mill, Virginia"
    },
    {
      "city": "Red Ash",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Red Ash, Virginia"
    },
    {
      "city": "Richlands",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Richlands, Virginia"
    },
    {
      "city": "Tazewell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Tazewell, Virginia"
    },
    {
      "city": "Bentonville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bentonville, Virginia"
    },
    {
      "city": "Front Royal",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Front Royal, Virginia"
    },
    {
      "city": "Linden",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Linden, Virginia"
    },
    {
      "city": "Abingdon",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Abingdon, Virginia"
    },
    {
      "city": "Damascus",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Damascus, Virginia"
    },
    {
      "city": "Mendota",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mendota, Virginia"
    },
    {
      "city": "Emory",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Emory, Virginia"
    },
    {
      "city": "Glade Spring",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Glade Spring, Virginia"
    },
    {
      "city": "Meadowview",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Meadowview, Virginia"
    },
    {
      "city": "Coles Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Coles Point, Virginia"
    },
    {
      "city": "Colonial Beach",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Colonial Beach, Virginia"
    },
    {
      "city": "Hague",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hague, Virginia"
    },
    {
      "city": "Kinsale",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Kinsale, Virginia"
    },
    {
      "city": "Montross",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Montross, Virginia"
    },
    {
      "city": "Mount Holly",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Mount Holly, Virginia"
    },
    {
      "city": "Oldhams",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Oldhams, Virginia"
    },
    {
      "city": "Stratford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Stratford, Virginia"
    },
    {
      "city": "Sandy Point",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Sandy Point, Virginia"
    },
    {
      "city": "Zacata",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Zacata, Virginia"
    },
    {
      "city": "Andover",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Andover, Virginia"
    },
    {
      "city": "Appalachia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Appalachia, Virginia"
    },
    {
      "city": "Big Stone Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Big Stone Gap, Virginia"
    },
    {
      "city": "Coeburn",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Coeburn, Virginia"
    },
    {
      "city": "East Stone Gap",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "East Stone Gap, Virginia"
    },
    {
      "city": "Pound",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Pound, Virginia"
    },
    {
      "city": "Saint Paul",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Saint Paul, Virginia"
    },
    {
      "city": "Wise",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wise, Virginia"
    },
    {
      "city": "Austinville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Austinville, Virginia"
    },
    {
      "city": "Barren Springs",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Barren Springs, Virginia"
    },
    {
      "city": "Cripple Creek",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Cripple Creek, Virginia"
    },
    {
      "city": "Crockett",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Crockett, Virginia"
    },
    {
      "city": "Ivanhoe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Ivanhoe, Virginia"
    },
    {
      "city": "Max Meadows",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Max Meadows, Virginia"
    },
    {
      "city": "Rural Retreat",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Rural Retreat, Virginia"
    },
    {
      "city": "Speedwell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Speedwell, Virginia"
    },
    {
      "city": "Wytheville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Wytheville, Virginia"
    },
    {
      "city": "Lightfoot",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lightfoot, Virginia"
    },
    {
      "city": "Yorktown",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Yorktown, Virginia"
    },
    {
      "city": "Lackey",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lackey, Virginia"
    },
    {
      "city": "Seaford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Seaford, Virginia"
    },
    {
      "city": "Bedford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bedford, Virginia"
    },
    {
      "city": "Bristol",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Bristol, Virginia"
    },
    {
      "city": "Buena Vista",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Buena Vista, Virginia"
    },
    {
      "city": "Chesapeake",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Chesapeake, Virginia"
    },
    {
      "city": "Colonial Heights",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Colonial Heights, Virginia"
    },
    {
      "city": "Covington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Covington, Virginia"
    },
    {
      "city": "Danville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Danville, Virginia"
    },
    {
      "city": "Emporia",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Emporia, Virginia"
    },
    {
      "city": "Franklin",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Franklin, Virginia"
    },
    {
      "city": "Galax",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Galax, Virginia"
    },
    {
      "city": "Hampton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hampton, Virginia"
    },
    {
      "city": "Fort Monroe",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Monroe, Virginia"
    },
    {
      "city": "Hopewell",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Hopewell, Virginia"
    },
    {
      "city": "Lexington",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Lexington, Virginia"
    },
    {
      "city": "Manassas",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Manassas, Virginia"
    },
    {
      "city": "Martinsville",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Martinsville, Virginia"
    },
    {
      "city": "Newport News",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Newport News, Virginia"
    },
    {
      "city": "Fort Eustis",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Fort Eustis, Virginia"
    },
    {
      "city": "Norfolk",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Norfolk, Virginia"
    },
    {
      "city": "Norton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Norton, Virginia"
    },
    {
      "city": "Petersburg",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Petersburg, Virginia"
    },
    {
      "city": "Virginia State University",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Virginia State University, Virginia"
    },
    {
      "city": "Poquoson",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Poquoson, Virginia"
    },
    {
      "city": "Portsmouth",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Portsmouth, Virginia"
    },
    {
      "city": "Radford",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Radford, Virginia"
    },
    {
      "city": "Salem",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Salem, Virginia"
    },
    {
      "city": "Staunton",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Staunton, Virginia"
    },
    {
      "city": "Suffolk",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Suffolk, Virginia"
    },
    {
      "city": "Virginia Beach",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Virginia Beach, Virginia"
    },
    {
      "city": "Waynesboro",
      "state": "Virginia",
      "shortState": "VA",
      "formatedCity": "Waynesboro, Virginia"
    },
    {
      "city": "Bristol",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bristol, Vermont"
    },
    {
      "city": "Ferrisburgh",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Ferrisburgh, Vermont"
    },
    {
      "city": "Monkton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Monkton, Vermont"
    },
    {
      "city": "New Haven",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "New Haven, Vermont"
    },
    {
      "city": "North Ferrisburgh",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Ferrisburgh, Vermont"
    },
    {
      "city": "Starksboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Starksboro, Vermont"
    },
    {
      "city": "Vergennes",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Vergennes, Vermont"
    },
    {
      "city": "Roxbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Roxbury, Vermont"
    },
    {
      "city": "Bridport",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bridport, Vermont"
    },
    {
      "city": "East Middlebury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Middlebury, Vermont"
    },
    {
      "city": "Granville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Granville, Vermont"
    },
    {
      "city": "Hancock",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hancock, Vermont"
    },
    {
      "city": "Middlebury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Middlebury, Vermont"
    },
    {
      "city": "Orwell",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Orwell, Vermont"
    },
    {
      "city": "Ripton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Ripton, Vermont"
    },
    {
      "city": "Salisbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Salisbury, Vermont"
    },
    {
      "city": "Shoreham",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Shoreham, Vermont"
    },
    {
      "city": "Whiting",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Whiting, Vermont"
    },
    {
      "city": "Peru",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Peru, Vermont"
    },
    {
      "city": "Bennington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bennington, Vermont"
    },
    {
      "city": "Arlington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Arlington, Vermont"
    },
    {
      "city": "Dorset",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Dorset, Vermont"
    },
    {
      "city": "East Arlington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Arlington, Vermont"
    },
    {
      "city": "East Dorset",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Dorset, Vermont"
    },
    {
      "city": "Manchester",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Manchester, Vermont"
    },
    {
      "city": "Manchester Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Manchester Center, Vermont"
    },
    {
      "city": "North Bennington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Bennington, Vermont"
    },
    {
      "city": "North Pownal",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Pownal, Vermont"
    },
    {
      "city": "Pownal",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Pownal, Vermont"
    },
    {
      "city": "Shaftsbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Shaftsbury, Vermont"
    },
    {
      "city": "Bondville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bondville, Vermont"
    },
    {
      "city": "Readsboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Readsboro, Vermont"
    },
    {
      "city": "Stamford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Stamford, Vermont"
    },
    {
      "city": "Rupert",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Rupert, Vermont"
    },
    {
      "city": "West Rupert",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Rupert, Vermont"
    },
    {
      "city": "East Ryegate",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Ryegate, Vermont"
    },
    {
      "city": "Groton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Groton, Vermont"
    },
    {
      "city": "Mc Indoe Falls",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Mc Indoe Falls, Vermont"
    },
    {
      "city": "South Ryegate",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Ryegate, Vermont"
    },
    {
      "city": "Saint Johnsbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Saint Johnsbury, Vermont"
    },
    {
      "city": "Barnet",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Barnet, Vermont"
    },
    {
      "city": "Danville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Danville, Vermont"
    },
    {
      "city": "East Burke",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Burke, Vermont"
    },
    {
      "city": "East Hardwick",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Hardwick, Vermont"
    },
    {
      "city": "East Saint Johnsbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Saint Johnsbury, Vermont"
    },
    {
      "city": "Hardwick",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hardwick, Vermont"
    },
    {
      "city": "Lower Waterford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lower Waterford, Vermont"
    },
    {
      "city": "Lyndon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lyndon, Vermont"
    },
    {
      "city": "Lyndon Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lyndon Center, Vermont"
    },
    {
      "city": "Lyndonville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lyndonville, Vermont"
    },
    {
      "city": "Passumpsic",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Passumpsic, Vermont"
    },
    {
      "city": "Peacham",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Peacham, Vermont"
    },
    {
      "city": "Saint Johnsbury Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Saint Johnsbury Center, Vermont"
    },
    {
      "city": "Sheffield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Sheffield, Vermont"
    },
    {
      "city": "Sutton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Sutton, Vermont"
    },
    {
      "city": "West Burke",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Burke, Vermont"
    },
    {
      "city": "West Danville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Danville, Vermont"
    },
    {
      "city": "Burlington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Burlington, Vermont"
    },
    {
      "city": "South Burlington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Burlington, Vermont"
    },
    {
      "city": "Winooski",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Winooski, Vermont"
    },
    {
      "city": "Colchester",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Colchester, Vermont"
    },
    {
      "city": "Charlotte",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Charlotte, Vermont"
    },
    {
      "city": "Essex",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Essex, Vermont"
    },
    {
      "city": "Essex Junction",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Essex Junction, Vermont"
    },
    {
      "city": "Hinesburg",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hinesburg, Vermont"
    },
    {
      "city": "Huntington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Huntington, Vermont"
    },
    {
      "city": "Jericho",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Jericho, Vermont"
    },
    {
      "city": "Jonesville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Jonesville, Vermont"
    },
    {
      "city": "Milton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Milton, Vermont"
    },
    {
      "city": "Richmond",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Richmond, Vermont"
    },
    {
      "city": "Shelburne",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Shelburne, Vermont"
    },
    {
      "city": "Underhill",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Underhill, Vermont"
    },
    {
      "city": "Underhill Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Underhill Center, Vermont"
    },
    {
      "city": "Westford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Westford, Vermont"
    },
    {
      "city": "Williston",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Williston, Vermont"
    },
    {
      "city": "Concord",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Concord, Vermont"
    },
    {
      "city": "East Haven",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Haven, Vermont"
    },
    {
      "city": "Granby",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Granby, Vermont"
    },
    {
      "city": "Island Pond",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Island Pond, Vermont"
    },
    {
      "city": "North Concord",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Concord, Vermont"
    },
    {
      "city": "Averill",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Averill, Vermont"
    },
    {
      "city": "Beecher Falls",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Beecher Falls, Vermont"
    },
    {
      "city": "Canaan",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Canaan, Vermont"
    },
    {
      "city": "Gilman",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Gilman, Vermont"
    },
    {
      "city": "Guildhall",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Guildhall, Vermont"
    },
    {
      "city": "Lunenburg",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lunenburg, Vermont"
    },
    {
      "city": "Norton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Norton, Vermont"
    },
    {
      "city": "Bakersfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bakersfield, Vermont"
    },
    {
      "city": "East Berkshire",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Berkshire, Vermont"
    },
    {
      "city": "East Fairfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Fairfield, Vermont"
    },
    {
      "city": "Enosburg Falls",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Enosburg Falls, Vermont"
    },
    {
      "city": "Fairfax",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Fairfax, Vermont"
    },
    {
      "city": "Fairfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Fairfield, Vermont"
    },
    {
      "city": "Franklin",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Franklin, Vermont"
    },
    {
      "city": "Highgate Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Highgate Center, Vermont"
    },
    {
      "city": "Highgate Springs",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Highgate Springs, Vermont"
    },
    {
      "city": "Montgomery",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Montgomery, Vermont"
    },
    {
      "city": "Montgomery Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Montgomery Center, Vermont"
    },
    {
      "city": "Richford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Richford, Vermont"
    },
    {
      "city": "Saint Albans",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Saint Albans, Vermont"
    },
    {
      "city": "Saint Albans Bay",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Saint Albans Bay, Vermont"
    },
    {
      "city": "Sheldon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Sheldon, Vermont"
    },
    {
      "city": "Sheldon Springs",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Sheldon Springs, Vermont"
    },
    {
      "city": "Swanton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Swanton, Vermont"
    },
    {
      "city": "Alburgh",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Alburgh, Vermont"
    },
    {
      "city": "Grand Isle",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Grand Isle, Vermont"
    },
    {
      "city": "Isle La Motte",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Isle La Motte, Vermont"
    },
    {
      "city": "North Hero",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Hero, Vermont"
    },
    {
      "city": "South Hero",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Hero, Vermont"
    },
    {
      "city": "Belvidere Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Belvidere Center, Vermont"
    },
    {
      "city": "Cambridge",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Cambridge, Vermont"
    },
    {
      "city": "Jeffersonville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Jeffersonville, Vermont"
    },
    {
      "city": "Waterville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Waterville, Vermont"
    },
    {
      "city": "Eden",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Eden, Vermont"
    },
    {
      "city": "Eden Mills",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Eden Mills, Vermont"
    },
    {
      "city": "Hyde Park",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hyde Park, Vermont"
    },
    {
      "city": "Johnson",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Johnson, Vermont"
    },
    {
      "city": "Lake Elmore",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lake Elmore, Vermont"
    },
    {
      "city": "Morrisville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Morrisville, Vermont"
    },
    {
      "city": "Moscow",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Moscow, Vermont"
    },
    {
      "city": "North Hyde Park",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Hyde Park, Vermont"
    },
    {
      "city": "Stowe",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Stowe, Vermont"
    },
    {
      "city": "Wolcott",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wolcott, Vermont"
    },
    {
      "city": "Bradford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bradford, Vermont"
    },
    {
      "city": "Brookfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Brookfield, Vermont"
    },
    {
      "city": "Chelsea",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Chelsea, Vermont"
    },
    {
      "city": "Corinth",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Corinth, Vermont"
    },
    {
      "city": "East Corinth",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Corinth, Vermont"
    },
    {
      "city": "East Randolph",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Randolph, Vermont"
    },
    {
      "city": "East Thetford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Thetford, Vermont"
    },
    {
      "city": "Fairlee",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Fairlee, Vermont"
    },
    {
      "city": "Newbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Newbury, Vermont"
    },
    {
      "city": "North Thetford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Thetford, Vermont"
    },
    {
      "city": "Post Mills",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Post Mills, Vermont"
    },
    {
      "city": "Randolph",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Randolph, Vermont"
    },
    {
      "city": "Randolph Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Randolph Center, Vermont"
    },
    {
      "city": "South Strafford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Strafford, Vermont"
    },
    {
      "city": "Strafford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Strafford, Vermont"
    },
    {
      "city": "Thetford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Thetford, Vermont"
    },
    {
      "city": "Thetford Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Thetford Center, Vermont"
    },
    {
      "city": "Topsham",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Topsham, Vermont"
    },
    {
      "city": "Tunbridge",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Tunbridge, Vermont"
    },
    {
      "city": "Vershire",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Vershire, Vermont"
    },
    {
      "city": "Wells River",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wells River, Vermont"
    },
    {
      "city": "West Fairlee",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Fairlee, Vermont"
    },
    {
      "city": "West Newbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Newbury, Vermont"
    },
    {
      "city": "West Topsham",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Topsham, Vermont"
    },
    {
      "city": "Washington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Washington, Vermont"
    },
    {
      "city": "Williamstown",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Williamstown, Vermont"
    },
    {
      "city": "Albany",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Albany, Vermont"
    },
    {
      "city": "Barton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Barton, Vermont"
    },
    {
      "city": "Beebe Plain",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Beebe Plain, Vermont"
    },
    {
      "city": "Coventry",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Coventry, Vermont"
    },
    {
      "city": "Craftsbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Craftsbury, Vermont"
    },
    {
      "city": "Craftsbury Common",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Craftsbury Common, Vermont"
    },
    {
      "city": "Derby",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Derby, Vermont"
    },
    {
      "city": "Derby Line",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Derby Line, Vermont"
    },
    {
      "city": "East Charleston",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Charleston, Vermont"
    },
    {
      "city": "Glover",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Glover, Vermont"
    },
    {
      "city": "Greensboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Greensboro, Vermont"
    },
    {
      "city": "Greensboro Bend",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Greensboro Bend, Vermont"
    },
    {
      "city": "Irasburg",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Irasburg, Vermont"
    },
    {
      "city": "Lowell",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Lowell, Vermont"
    },
    {
      "city": "Morgan",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Morgan, Vermont"
    },
    {
      "city": "Newport",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Newport, Vermont"
    },
    {
      "city": "Newport Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Newport Center, Vermont"
    },
    {
      "city": "North Troy",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Troy, Vermont"
    },
    {
      "city": "Orleans",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Orleans, Vermont"
    },
    {
      "city": "Troy",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Troy, Vermont"
    },
    {
      "city": "West Charleston",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Charleston, Vermont"
    },
    {
      "city": "Westfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Westfield, Vermont"
    },
    {
      "city": "Rutland",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Rutland, Vermont"
    },
    {
      "city": "Belmont",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Belmont, Vermont"
    },
    {
      "city": "Benson",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Benson, Vermont"
    },
    {
      "city": "Bomoseen",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bomoseen, Vermont"
    },
    {
      "city": "Brandon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Brandon, Vermont"
    },
    {
      "city": "Castleton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Castleton, Vermont"
    },
    {
      "city": "Center Rutland",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Center Rutland, Vermont"
    },
    {
      "city": "Chittenden",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Chittenden, Vermont"
    },
    {
      "city": "Cuttingsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Cuttingsville, Vermont"
    },
    {
      "city": "Danby",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Danby, Vermont"
    },
    {
      "city": "East Poultney",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Poultney, Vermont"
    },
    {
      "city": "East Wallingford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Wallingford, Vermont"
    },
    {
      "city": "Fair Haven",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Fair Haven, Vermont"
    },
    {
      "city": "Florence",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Florence, Vermont"
    },
    {
      "city": "Forest Dale",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Forest Dale, Vermont"
    },
    {
      "city": "Hydeville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hydeville, Vermont"
    },
    {
      "city": "Killington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Killington, Vermont"
    },
    {
      "city": "Middletown Springs",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Middletown Springs, Vermont"
    },
    {
      "city": "Mount Holly",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Mount Holly, Vermont"
    },
    {
      "city": "North Clarendon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Clarendon, Vermont"
    },
    {
      "city": "Pawlet",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Pawlet, Vermont"
    },
    {
      "city": "Pittsfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Pittsfield, Vermont"
    },
    {
      "city": "Pittsford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Pittsford, Vermont"
    },
    {
      "city": "Poultney",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Poultney, Vermont"
    },
    {
      "city": "Proctor",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Proctor, Vermont"
    },
    {
      "city": "Wallingford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wallingford, Vermont"
    },
    {
      "city": "Wells",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wells, Vermont"
    },
    {
      "city": "West Pawlet",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Pawlet, Vermont"
    },
    {
      "city": "West Rutland",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Rutland, Vermont"
    },
    {
      "city": "Montpelier",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Montpelier, Vermont"
    },
    {
      "city": "Adamant",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Adamant, Vermont"
    },
    {
      "city": "Barre",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Barre, Vermont"
    },
    {
      "city": "Cabot",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Cabot, Vermont"
    },
    {
      "city": "Calais",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Calais, Vermont"
    },
    {
      "city": "East Barre",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Barre, Vermont"
    },
    {
      "city": "East Calais",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Calais, Vermont"
    },
    {
      "city": "East Montpelier",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Montpelier, Vermont"
    },
    {
      "city": "Graniteville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Graniteville, Vermont"
    },
    {
      "city": "Marshfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Marshfield, Vermont"
    },
    {
      "city": "Moretown",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Moretown, Vermont"
    },
    {
      "city": "Northfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Northfield, Vermont"
    },
    {
      "city": "Northfield Falls",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Northfield Falls, Vermont"
    },
    {
      "city": "North Montpelier",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Montpelier, Vermont"
    },
    {
      "city": "Plainfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Plainfield, Vermont"
    },
    {
      "city": "South Barre",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Barre, Vermont"
    },
    {
      "city": "Waterbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Waterbury, Vermont"
    },
    {
      "city": "Waitsfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Waitsfield, Vermont"
    },
    {
      "city": "Warren",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Warren, Vermont"
    },
    {
      "city": "Waterbury Center",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Waterbury Center, Vermont"
    },
    {
      "city": "Websterville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Websterville, Vermont"
    },
    {
      "city": "Woodbury",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Woodbury, Vermont"
    },
    {
      "city": "Worcester",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Worcester, Vermont"
    },
    {
      "city": "Bellows Falls",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bellows Falls, Vermont"
    },
    {
      "city": "Cambridgeport",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Cambridgeport, Vermont"
    },
    {
      "city": "Grafton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Grafton, Vermont"
    },
    {
      "city": "Londonderry",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Londonderry, Vermont"
    },
    {
      "city": "Saxtons River",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Saxtons River, Vermont"
    },
    {
      "city": "South Londonderry",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Londonderry, Vermont"
    },
    {
      "city": "Westminster",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Westminster, Vermont"
    },
    {
      "city": "Westminster Station",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Westminster Station, Vermont"
    },
    {
      "city": "Brattleboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Brattleboro, Vermont"
    },
    {
      "city": "East Dover",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "East Dover, Vermont"
    },
    {
      "city": "Jacksonville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Jacksonville, Vermont"
    },
    {
      "city": "Jamaica",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Jamaica, Vermont"
    },
    {
      "city": "Marlboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Marlboro, Vermont"
    },
    {
      "city": "Newfane",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Newfane, Vermont"
    },
    {
      "city": "Putney",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Putney, Vermont"
    },
    {
      "city": "South Newfane",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Newfane, Vermont"
    },
    {
      "city": "Townshend",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Townshend, Vermont"
    },
    {
      "city": "Vernon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Vernon, Vermont"
    },
    {
      "city": "Wardsboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wardsboro, Vermont"
    },
    {
      "city": "West Dover",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Dover, Vermont"
    },
    {
      "city": "West Dummerston",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Dummerston, Vermont"
    },
    {
      "city": "West Halifax",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Halifax, Vermont"
    },
    {
      "city": "West Townshend",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Townshend, Vermont"
    },
    {
      "city": "West Wardsboro",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Wardsboro, Vermont"
    },
    {
      "city": "Whitingham",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Whitingham, Vermont"
    },
    {
      "city": "Williamsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Williamsville, Vermont"
    },
    {
      "city": "Wilmington",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wilmington, Vermont"
    },
    {
      "city": "White River Junction",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "White River Junction, Vermont"
    },
    {
      "city": "Ascutney",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Ascutney, Vermont"
    },
    {
      "city": "Barnard",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Barnard, Vermont"
    },
    {
      "city": "Bethel",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bethel, Vermont"
    },
    {
      "city": "Bridgewater",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bridgewater, Vermont"
    },
    {
      "city": "Bridgewater Corners",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Bridgewater Corners, Vermont"
    },
    {
      "city": "Brownsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Brownsville, Vermont"
    },
    {
      "city": "Hartford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hartford, Vermont"
    },
    {
      "city": "Hartland",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hartland, Vermont"
    },
    {
      "city": "Hartland Four Corners",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Hartland Four Corners, Vermont"
    },
    {
      "city": "North Hartland",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Hartland, Vermont"
    },
    {
      "city": "North Pomfret",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Pomfret, Vermont"
    },
    {
      "city": "Norwich",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Norwich, Vermont"
    },
    {
      "city": "Plymouth",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Plymouth, Vermont"
    },
    {
      "city": "Quechee",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Quechee, Vermont"
    },
    {
      "city": "Reading",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Reading, Vermont"
    },
    {
      "city": "Sharon",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Sharon, Vermont"
    },
    {
      "city": "South Pomfret",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Pomfret, Vermont"
    },
    {
      "city": "South Royalton",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Royalton, Vermont"
    },
    {
      "city": "South Woodstock",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "South Woodstock, Vermont"
    },
    {
      "city": "Taftsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Taftsville, Vermont"
    },
    {
      "city": "West Hartford",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "West Hartford, Vermont"
    },
    {
      "city": "Wilder",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Wilder, Vermont"
    },
    {
      "city": "Windsor",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Windsor, Vermont"
    },
    {
      "city": "Woodstock",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Woodstock, Vermont"
    },
    {
      "city": "Cavendish",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Cavendish, Vermont"
    },
    {
      "city": "Chester",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Chester, Vermont"
    },
    {
      "city": "Ludlow",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Ludlow, Vermont"
    },
    {
      "city": "North Springfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "North Springfield, Vermont"
    },
    {
      "city": "Perkinsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Perkinsville, Vermont"
    },
    {
      "city": "Proctorsville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Proctorsville, Vermont"
    },
    {
      "city": "Springfield",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Springfield, Vermont"
    },
    {
      "city": "Weston",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Weston, Vermont"
    },
    {
      "city": "Gaysville",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Gaysville, Vermont"
    },
    {
      "city": "Rochester",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Rochester, Vermont"
    },
    {
      "city": "Stockbridge",
      "state": "Vermont",
      "shortState": "VT",
      "formatedCity": "Stockbridge, Vermont"
    },
    {
      "city": "Benge",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Benge, Washington"
    },
    {
      "city": "Ritzville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ritzville, Washington"
    },
    {
      "city": "Lind",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lind, Washington"
    },
    {
      "city": "Othello",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Othello, Washington"
    },
    {
      "city": "Washtucna",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Washtucna, Washington"
    },
    {
      "city": "Anatone",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Anatone, Washington"
    },
    {
      "city": "Asotin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Asotin, Washington"
    },
    {
      "city": "Clarkston",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Clarkston, Washington"
    },
    {
      "city": "Benton City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Benton City, Washington"
    },
    {
      "city": "Kennewick",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kennewick, Washington"
    },
    {
      "city": "Paterson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Paterson, Washington"
    },
    {
      "city": "Plymouth",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Plymouth, Washington"
    },
    {
      "city": "Prosser",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Prosser, Washington"
    },
    {
      "city": "Richland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Richland, Washington"
    },
    {
      "city": "West Richland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "West Richland, Washington"
    },
    {
      "city": "Wenatchee",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wenatchee, Washington"
    },
    {
      "city": "Ardenvoir",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ardenvoir, Washington"
    },
    {
      "city": "Cashmere",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cashmere, Washington"
    },
    {
      "city": "Chelan",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chelan, Washington"
    },
    {
      "city": "Chelan Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chelan Falls, Washington"
    },
    {
      "city": "Dryden",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Dryden, Washington"
    },
    {
      "city": "Entiat",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Entiat, Washington"
    },
    {
      "city": "Leavenworth",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Leavenworth, Washington"
    },
    {
      "city": "Malaga",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Malaga, Washington"
    },
    {
      "city": "Manson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Manson, Washington"
    },
    {
      "city": "Monitor",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Monitor, Washington"
    },
    {
      "city": "Peshastin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Peshastin, Washington"
    },
    {
      "city": "Stehekin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Stehekin, Washington"
    },
    {
      "city": "Beaver",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Beaver, Washington"
    },
    {
      "city": "Carlsborg",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carlsborg, Washington"
    },
    {
      "city": "Clallam Bay",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Clallam Bay, Washington"
    },
    {
      "city": "Forks",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Forks, Washington"
    },
    {
      "city": "Joyce",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Joyce, Washington"
    },
    {
      "city": "La Push",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "La Push, Washington"
    },
    {
      "city": "Neah Bay",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Neah Bay, Washington"
    },
    {
      "city": "Port Angeles",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Angeles, Washington"
    },
    {
      "city": "Sekiu",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sekiu, Washington"
    },
    {
      "city": "Sequim",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sequim, Washington"
    },
    {
      "city": "Amboy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Amboy, Washington"
    },
    {
      "city": "Battle Ground",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Battle Ground, Washington"
    },
    {
      "city": "Brush Prairie",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Brush Prairie, Washington"
    },
    {
      "city": "Camas",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Camas, Washington"
    },
    {
      "city": "Heisson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Heisson, Washington"
    },
    {
      "city": "La Center",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "La Center, Washington"
    },
    {
      "city": "Ridgefield",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ridgefield, Washington"
    },
    {
      "city": "Vancouver",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Vancouver, Washington"
    },
    {
      "city": "Washougal",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Washougal, Washington"
    },
    {
      "city": "Yacolt",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Yacolt, Washington"
    },
    {
      "city": "Dayton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Dayton, Washington"
    },
    {
      "city": "Starbuck",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Starbuck, Washington"
    },
    {
      "city": "Ryderwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ryderwood, Washington"
    },
    {
      "city": "Ariel",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ariel, Washington"
    },
    {
      "city": "Carrolls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carrolls, Washington"
    },
    {
      "city": "Castle Rock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Castle Rock, Washington"
    },
    {
      "city": "Cougar",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cougar, Washington"
    },
    {
      "city": "Kalama",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kalama, Washington"
    },
    {
      "city": "Kelso",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kelso, Washington"
    },
    {
      "city": "Longview",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Longview, Washington"
    },
    {
      "city": "Silverlake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Silverlake, Washington"
    },
    {
      "city": "Toutle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Toutle, Washington"
    },
    {
      "city": "Woodland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Woodland, Washington"
    },
    {
      "city": "East Wenatchee",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "East Wenatchee, Washington"
    },
    {
      "city": "Bridgeport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bridgeport, Washington"
    },
    {
      "city": "Mansfield",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mansfield, Washington"
    },
    {
      "city": "Orondo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Orondo, Washington"
    },
    {
      "city": "Palisades",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Palisades, Washington"
    },
    {
      "city": "Rock Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rock Island, Washington"
    },
    {
      "city": "Waterville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Waterville, Washington"
    },
    {
      "city": "Boyds",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Boyds, Washington"
    },
    {
      "city": "Curlew",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Curlew, Washington"
    },
    {
      "city": "Danville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Danville, Washington"
    },
    {
      "city": "Inchelium",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Inchelium, Washington"
    },
    {
      "city": "Keller",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Keller, Washington"
    },
    {
      "city": "Laurier",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Laurier, Washington"
    },
    {
      "city": "Malo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Malo, Washington"
    },
    {
      "city": "Orient",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Orient, Washington"
    },
    {
      "city": "Republic",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Republic, Washington"
    },
    {
      "city": "Pasco",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pasco, Washington"
    },
    {
      "city": "Connell",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Connell, Washington"
    },
    {
      "city": "Eltopia",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Eltopia, Washington"
    },
    {
      "city": "Kahlotus",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kahlotus, Washington"
    },
    {
      "city": "Mesa",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mesa, Washington"
    },
    {
      "city": "Pomeroy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pomeroy, Washington"
    },
    {
      "city": "Ephrata",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ephrata, Washington"
    },
    {
      "city": "George",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "George, Washington"
    },
    {
      "city": "Marlin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Marlin, Washington"
    },
    {
      "city": "Moses Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Moses Lake, Washington"
    },
    {
      "city": "Quincy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Quincy, Washington"
    },
    {
      "city": "Soap Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Soap Lake, Washington"
    },
    {
      "city": "Stratford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Stratford, Washington"
    },
    {
      "city": "Warden",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Warden, Washington"
    },
    {
      "city": "Wilson Creek",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wilson Creek, Washington"
    },
    {
      "city": "Coulee City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Coulee City, Washington"
    },
    {
      "city": "Electric City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Electric City, Washington"
    },
    {
      "city": "Grand Coulee",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Grand Coulee, Washington"
    },
    {
      "city": "Hartline",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hartline, Washington"
    },
    {
      "city": "Beverly",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Beverly, Washington"
    },
    {
      "city": "Mattawa",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mattawa, Washington"
    },
    {
      "city": "Royal City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Royal City, Washington"
    },
    {
      "city": "Aberdeen",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Aberdeen, Washington"
    },
    {
      "city": "Amanda Park",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Amanda Park, Washington"
    },
    {
      "city": "Copalis Beach",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Copalis Beach, Washington"
    },
    {
      "city": "Copalis Crossing",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Copalis Crossing, Washington"
    },
    {
      "city": "Cosmopolis",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cosmopolis, Washington"
    },
    {
      "city": "Elma",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Elma, Washington"
    },
    {
      "city": "Grayland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Grayland, Washington"
    },
    {
      "city": "Hoquiam",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hoquiam, Washington"
    },
    {
      "city": "Humptulips",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Humptulips, Washington"
    },
    {
      "city": "Mccleary",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mccleary, Washington"
    },
    {
      "city": "Malone",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Malone, Washington"
    },
    {
      "city": "Moclips",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Moclips, Washington"
    },
    {
      "city": "Montesano",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Montesano, Washington"
    },
    {
      "city": "Neilton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Neilton, Washington"
    },
    {
      "city": "Oakville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Oakville, Washington"
    },
    {
      "city": "Ocean Shores",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ocean Shores, Washington"
    },
    {
      "city": "Pacific Beach",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pacific Beach, Washington"
    },
    {
      "city": "Quinault",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Quinault, Washington"
    },
    {
      "city": "Satsop",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Satsop, Washington"
    },
    {
      "city": "Taholah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Taholah, Washington"
    },
    {
      "city": "Westport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Westport, Washington"
    },
    {
      "city": "Clinton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Clinton, Washington"
    },
    {
      "city": "Coupeville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Coupeville, Washington"
    },
    {
      "city": "Freeland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Freeland, Washington"
    },
    {
      "city": "Greenbank",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Greenbank, Washington"
    },
    {
      "city": "Langley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Langley, Washington"
    },
    {
      "city": "Oak Harbor",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Oak Harbor, Washington"
    },
    {
      "city": "Brinnon",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Brinnon, Washington"
    },
    {
      "city": "Chimacum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chimacum, Washington"
    },
    {
      "city": "Port Hadlock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Hadlock, Washington"
    },
    {
      "city": "Nordland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Nordland, Washington"
    },
    {
      "city": "Port Ludlow",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Ludlow, Washington"
    },
    {
      "city": "Port Townsend",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Townsend, Washington"
    },
    {
      "city": "Quilcene",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Quilcene, Washington"
    },
    {
      "city": "Auburn",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Auburn, Washington"
    },
    {
      "city": "Federal Way",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Federal Way, Washington"
    },
    {
      "city": "Bellevue",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bellevue, Washington"
    },
    {
      "city": "Black Diamond",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Black Diamond, Washington"
    },
    {
      "city": "Bothell",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bothell, Washington"
    },
    {
      "city": "Burton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Burton, Washington"
    },
    {
      "city": "Carnation",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carnation, Washington"
    },
    {
      "city": "Duvall",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Duvall, Washington"
    },
    {
      "city": "Enumclaw",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Enumclaw, Washington"
    },
    {
      "city": "Fall City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Fall City, Washington"
    },
    {
      "city": "Hobart",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hobart, Washington"
    },
    {
      "city": "Issaquah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Issaquah, Washington"
    },
    {
      "city": "Kenmore",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kenmore, Washington"
    },
    {
      "city": "Kent",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kent, Washington"
    },
    {
      "city": "Kirkland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kirkland, Washington"
    },
    {
      "city": "Maple Valley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Maple Valley, Washington"
    },
    {
      "city": "Medina",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Medina, Washington"
    },
    {
      "city": "Mercer Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mercer Island, Washington"
    },
    {
      "city": "North Bend",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "North Bend, Washington"
    },
    {
      "city": "Pacific",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pacific, Washington"
    },
    {
      "city": "Preston",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Preston, Washington"
    },
    {
      "city": "Ravensdale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ravensdale, Washington"
    },
    {
      "city": "Redmond",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Redmond, Washington"
    },
    {
      "city": "Renton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Renton, Washington"
    },
    {
      "city": "Seahurst",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Seahurst, Washington"
    },
    {
      "city": "Snoqualmie",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Snoqualmie, Washington"
    },
    {
      "city": "Snoqualmie Pass",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Snoqualmie Pass, Washington"
    },
    {
      "city": "Vashon",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Vashon, Washington"
    },
    {
      "city": "Woodinville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Woodinville, Washington"
    },
    {
      "city": "Sammamish",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sammamish, Washington"
    },
    {
      "city": "Seattle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Seattle, Washington"
    },
    {
      "city": "Baring",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Baring, Washington"
    },
    {
      "city": "Skykomish",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Skykomish, Washington"
    },
    {
      "city": "Rollingbay",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rollingbay, Washington"
    },
    {
      "city": "Bainbridge Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bainbridge Island, Washington"
    },
    {
      "city": "Bremerton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bremerton, Washington"
    },
    {
      "city": "Silverdale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Silverdale, Washington"
    },
    {
      "city": "Burley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Burley, Washington"
    },
    {
      "city": "Hansville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hansville, Washington"
    },
    {
      "city": "Indianola",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Indianola, Washington"
    },
    {
      "city": "Keyport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Keyport, Washington"
    },
    {
      "city": "Kingston",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kingston, Washington"
    },
    {
      "city": "Manchester",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Manchester, Washington"
    },
    {
      "city": "Olalla",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Olalla, Washington"
    },
    {
      "city": "Port Gamble",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Gamble, Washington"
    },
    {
      "city": "Port Orchard",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Port Orchard, Washington"
    },
    {
      "city": "Poulsbo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Poulsbo, Washington"
    },
    {
      "city": "Retsil",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Retsil, Washington"
    },
    {
      "city": "Seabeck",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Seabeck, Washington"
    },
    {
      "city": "South Colby",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "South Colby, Washington"
    },
    {
      "city": "Southworth",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Southworth, Washington"
    },
    {
      "city": "Suquamish",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Suquamish, Washington"
    },
    {
      "city": "Tracyton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tracyton, Washington"
    },
    {
      "city": "Cle Elum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cle Elum, Washington"
    },
    {
      "city": "Easton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Easton, Washington"
    },
    {
      "city": "Ellensburg",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ellensburg, Washington"
    },
    {
      "city": "Kittitas",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kittitas, Washington"
    },
    {
      "city": "Ronald",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ronald, Washington"
    },
    {
      "city": "Roslyn",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Roslyn, Washington"
    },
    {
      "city": "South Cle Elum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "South Cle Elum, Washington"
    },
    {
      "city": "Thorp",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Thorp, Washington"
    },
    {
      "city": "Vantage",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Vantage, Washington"
    },
    {
      "city": "Appleton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Appleton, Washington"
    },
    {
      "city": "Bingen",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bingen, Washington"
    },
    {
      "city": "Centerville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Centerville, Washington"
    },
    {
      "city": "Dallesport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Dallesport, Washington"
    },
    {
      "city": "Glenwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Glenwood, Washington"
    },
    {
      "city": "Goldendale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Goldendale, Washington"
    },
    {
      "city": "Husum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Husum, Washington"
    },
    {
      "city": "Klickitat",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Klickitat, Washington"
    },
    {
      "city": "Lyle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lyle, Washington"
    },
    {
      "city": "Trout Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Trout Lake, Washington"
    },
    {
      "city": "Wahkiacus",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wahkiacus, Washington"
    },
    {
      "city": "White Salmon",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "White Salmon, Washington"
    },
    {
      "city": "Wishram",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wishram, Washington"
    },
    {
      "city": "Bickleton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bickleton, Washington"
    },
    {
      "city": "Roosevelt",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Roosevelt, Washington"
    },
    {
      "city": "Glenoma",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Glenoma, Washington"
    },
    {
      "city": "Mineral",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mineral, Washington"
    },
    {
      "city": "Morton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Morton, Washington"
    },
    {
      "city": "Packwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Packwood, Washington"
    },
    {
      "city": "Randle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Randle, Washington"
    },
    {
      "city": "Adna",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Adna, Washington"
    },
    {
      "city": "Centralia",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Centralia, Washington"
    },
    {
      "city": "Chehalis",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chehalis, Washington"
    },
    {
      "city": "Cinebar",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cinebar, Washington"
    },
    {
      "city": "Curtis",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Curtis, Washington"
    },
    {
      "city": "Doty",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Doty, Washington"
    },
    {
      "city": "Ethel",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ethel, Washington"
    },
    {
      "city": "Galvin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Galvin, Washington"
    },
    {
      "city": "Mossyrock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mossyrock, Washington"
    },
    {
      "city": "Napavine",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Napavine, Washington"
    },
    {
      "city": "Onalaska",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Onalaska, Washington"
    },
    {
      "city": "Pe Ell",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pe Ell, Washington"
    },
    {
      "city": "Salkum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Salkum, Washington"
    },
    {
      "city": "Silver Creek",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Silver Creek, Washington"
    },
    {
      "city": "Toledo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Toledo, Washington"
    },
    {
      "city": "Vader",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Vader, Washington"
    },
    {
      "city": "Winlock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Winlock, Washington"
    },
    {
      "city": "Edwall",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Edwall, Washington"
    },
    {
      "city": "Sprague",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sprague, Washington"
    },
    {
      "city": "Almira",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Almira, Washington"
    },
    {
      "city": "Creston",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Creston, Washington"
    },
    {
      "city": "Davenport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Davenport, Washington"
    },
    {
      "city": "Harrington",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Harrington, Washington"
    },
    {
      "city": "Lamona",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lamona, Washington"
    },
    {
      "city": "Lincoln",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lincoln, Washington"
    },
    {
      "city": "Mohler",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mohler, Washington"
    },
    {
      "city": "Odessa",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Odessa, Washington"
    },
    {
      "city": "Wilbur",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wilbur, Washington"
    },
    {
      "city": "Allyn",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Allyn, Washington"
    },
    {
      "city": "Belfair",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Belfair, Washington"
    },
    {
      "city": "Grapeview",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Grapeview, Washington"
    },
    {
      "city": "Hoodsport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hoodsport, Washington"
    },
    {
      "city": "Lilliwaup",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lilliwaup, Washington"
    },
    {
      "city": "Matlock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Matlock, Washington"
    },
    {
      "city": "Shelton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Shelton, Washington"
    },
    {
      "city": "Tahuya",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tahuya, Washington"
    },
    {
      "city": "Union",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Union, Washington"
    },
    {
      "city": "Brewster",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Brewster, Washington"
    },
    {
      "city": "Carlton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carlton, Washington"
    },
    {
      "city": "Conconully",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Conconully, Washington"
    },
    {
      "city": "Loomis",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Loomis, Washington"
    },
    {
      "city": "Malott",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Malott, Washington"
    },
    {
      "city": "Mazama",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mazama, Washington"
    },
    {
      "city": "Methow",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Methow, Washington"
    },
    {
      "city": "Okanogan",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Okanogan, Washington"
    },
    {
      "city": "Omak",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Omak, Washington"
    },
    {
      "city": "Oroville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Oroville, Washington"
    },
    {
      "city": "Pateros",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pateros, Washington"
    },
    {
      "city": "Riverside",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Riverside, Washington"
    },
    {
      "city": "Tonasket",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tonasket, Washington"
    },
    {
      "city": "Twisp",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Twisp, Washington"
    },
    {
      "city": "Wauconda",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wauconda, Washington"
    },
    {
      "city": "Winthrop",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Winthrop, Washington"
    },
    {
      "city": "Coulee Dam",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Coulee Dam, Washington"
    },
    {
      "city": "Elmer City",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Elmer City, Washington"
    },
    {
      "city": "Nespelem",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Nespelem, Washington"
    },
    {
      "city": "Bay Center",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bay Center, Washington"
    },
    {
      "city": "Lebam",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lebam, Washington"
    },
    {
      "city": "Menlo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Menlo, Washington"
    },
    {
      "city": "Raymond",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Raymond, Washington"
    },
    {
      "city": "South Bend",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "South Bend, Washington"
    },
    {
      "city": "Tokeland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tokeland, Washington"
    },
    {
      "city": "Chinook",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chinook, Washington"
    },
    {
      "city": "Ilwaco",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ilwaco, Washington"
    },
    {
      "city": "Long Beach",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Long Beach, Washington"
    },
    {
      "city": "Nahcotta",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Nahcotta, Washington"
    },
    {
      "city": "Naselle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Naselle, Washington"
    },
    {
      "city": "Ocean Park",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ocean Park, Washington"
    },
    {
      "city": "Oysterville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Oysterville, Washington"
    },
    {
      "city": "Seaview",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Seaview, Washington"
    },
    {
      "city": "Cusick",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cusick, Washington"
    },
    {
      "city": "Ione",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ione, Washington"
    },
    {
      "city": "Metaline",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Metaline, Washington"
    },
    {
      "city": "Metaline Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Metaline Falls, Washington"
    },
    {
      "city": "Newport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Newport, Washington"
    },
    {
      "city": "Usk",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Usk, Washington"
    },
    {
      "city": "Anderson Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Anderson Island, Washington"
    },
    {
      "city": "Ashford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ashford, Washington"
    },
    {
      "city": "Buckley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Buckley, Washington"
    },
    {
      "city": "Carbonado",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carbonado, Washington"
    },
    {
      "city": "Dupont",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Dupont, Washington"
    },
    {
      "city": "Eatonville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Eatonville, Washington"
    },
    {
      "city": "Gig Harbor",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Gig Harbor, Washington"
    },
    {
      "city": "Elbe",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Elbe, Washington"
    },
    {
      "city": "Fox Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Fox Island, Washington"
    },
    {
      "city": "Graham",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Graham, Washington"
    },
    {
      "city": "Kapowsin",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kapowsin, Washington"
    },
    {
      "city": "La Grande",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "La Grande, Washington"
    },
    {
      "city": "Lakebay",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lakebay, Washington"
    },
    {
      "city": "Longbranch",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Longbranch, Washington"
    },
    {
      "city": "Sumner",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sumner, Washington"
    },
    {
      "city": "Milton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Milton, Washington"
    },
    {
      "city": "Orting",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Orting, Washington"
    },
    {
      "city": "Puyallup",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Puyallup, Washington"
    },
    {
      "city": "South Prairie",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "South Prairie, Washington"
    },
    {
      "city": "Spanaway",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Spanaway, Washington"
    },
    {
      "city": "Steilacoom",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Steilacoom, Washington"
    },
    {
      "city": "Bonney Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bonney Lake, Washington"
    },
    {
      "city": "Vaughn",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Vaughn, Washington"
    },
    {
      "city": "Wauna",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wauna, Washington"
    },
    {
      "city": "Wilkeson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wilkeson, Washington"
    },
    {
      "city": "Longmire",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Longmire, Washington"
    },
    {
      "city": "Paradise Inn",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Paradise Inn, Washington"
    },
    {
      "city": "Tacoma",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tacoma, Washington"
    },
    {
      "city": "Camp Murray",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Camp Murray, Washington"
    },
    {
      "city": "Mcchord Afb",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mcchord Afb, Washington"
    },
    {
      "city": "Lakewood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lakewood, Washington"
    },
    {
      "city": "University Place",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "University Place, Washington"
    },
    {
      "city": "Mckenna",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mckenna, Washington"
    },
    {
      "city": "Roy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Roy, Washington"
    },
    {
      "city": "Blakely Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Blakely Island, Washington"
    },
    {
      "city": "Deer Harbor",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Deer Harbor, Washington"
    },
    {
      "city": "Eastsound",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Eastsound, Washington"
    },
    {
      "city": "Friday Harbor",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Friday Harbor, Washington"
    },
    {
      "city": "Lopez Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lopez Island, Washington"
    },
    {
      "city": "Olga",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Olga, Washington"
    },
    {
      "city": "Orcas",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Orcas, Washington"
    },
    {
      "city": "Shaw Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Shaw Island, Washington"
    },
    {
      "city": "Waldron",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Waldron, Washington"
    },
    {
      "city": "Anacortes",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Anacortes, Washington"
    },
    {
      "city": "Bow",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bow, Washington"
    },
    {
      "city": "Burlington",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Burlington, Washington"
    },
    {
      "city": "Clearlake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Clearlake, Washington"
    },
    {
      "city": "Concrete",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Concrete, Washington"
    },
    {
      "city": "Conway",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Conway, Washington"
    },
    {
      "city": "Hamilton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hamilton, Washington"
    },
    {
      "city": "La Conner",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "La Conner, Washington"
    },
    {
      "city": "Lyman",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lyman, Washington"
    },
    {
      "city": "Marblemount",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Marblemount, Washington"
    },
    {
      "city": "Mount Vernon",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mount Vernon, Washington"
    },
    {
      "city": "Rockport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rockport, Washington"
    },
    {
      "city": "Sedro Woolley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sedro Woolley, Washington"
    },
    {
      "city": "Carson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Carson, Washington"
    },
    {
      "city": "North Bonneville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "North Bonneville, Washington"
    },
    {
      "city": "Stevenson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Stevenson, Washington"
    },
    {
      "city": "Underwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Underwood, Washington"
    },
    {
      "city": "Edmonds",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Edmonds, Washington"
    },
    {
      "city": "Lynnwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lynnwood, Washington"
    },
    {
      "city": "Mountlake Terrace",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mountlake Terrace, Washington"
    },
    {
      "city": "Mill Creek",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mill Creek, Washington"
    },
    {
      "city": "Everett",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Everett, Washington"
    },
    {
      "city": "Arlington",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Arlington, Washington"
    },
    {
      "city": "Darrington",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Darrington, Washington"
    },
    {
      "city": "Gold Bar",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Gold Bar, Washington"
    },
    {
      "city": "Granite Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Granite Falls, Washington"
    },
    {
      "city": "Index",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Index, Washington"
    },
    {
      "city": "Lake Stevens",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lake Stevens, Washington"
    },
    {
      "city": "North Lakewood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "North Lakewood, Washington"
    },
    {
      "city": "Marysville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Marysville, Washington"
    },
    {
      "city": "Monroe",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Monroe, Washington"
    },
    {
      "city": "Mukilteo",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mukilteo, Washington"
    },
    {
      "city": "Camano Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Camano Island, Washington"
    },
    {
      "city": "Silvana",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Silvana, Washington"
    },
    {
      "city": "Snohomish",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Snohomish, Washington"
    },
    {
      "city": "Stanwood",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Stanwood, Washington"
    },
    {
      "city": "Startup",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Startup, Washington"
    },
    {
      "city": "Sultan",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sultan, Washington"
    },
    {
      "city": "Airway Heights",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Airway Heights, Washington"
    },
    {
      "city": "Chattaroy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chattaroy, Washington"
    },
    {
      "city": "Cheney",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cheney, Washington"
    },
    {
      "city": "Colbert",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Colbert, Washington"
    },
    {
      "city": "Deer Park",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Deer Park, Washington"
    },
    {
      "city": "Elk",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Elk, Washington"
    },
    {
      "city": "Fairchild Air Force Base",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Fairchild Air Force Base, Washington"
    },
    {
      "city": "Fairfield",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Fairfield, Washington"
    },
    {
      "city": "Four Lakes",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Four Lakes, Washington"
    },
    {
      "city": "Greenacres",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Greenacres, Washington"
    },
    {
      "city": "Latah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Latah, Washington"
    },
    {
      "city": "Liberty Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Liberty Lake, Washington"
    },
    {
      "city": "Marshall",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Marshall, Washington"
    },
    {
      "city": "Mead",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mead, Washington"
    },
    {
      "city": "Medical Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Medical Lake, Washington"
    },
    {
      "city": "Mica",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mica, Washington"
    },
    {
      "city": "Newman Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Newman Lake, Washington"
    },
    {
      "city": "Nine Mile Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Nine Mile Falls, Washington"
    },
    {
      "city": "Otis Orchards",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Otis Orchards, Washington"
    },
    {
      "city": "Reardan",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Reardan, Washington"
    },
    {
      "city": "Rockford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rockford, Washington"
    },
    {
      "city": "Spangle",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Spangle, Washington"
    },
    {
      "city": "Valleyford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Valleyford, Washington"
    },
    {
      "city": "Veradale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Veradale, Washington"
    },
    {
      "city": "Waverly",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Waverly, Washington"
    },
    {
      "city": "Spokane",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Spokane, Washington"
    },
    {
      "city": "Ford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ford, Washington"
    },
    {
      "city": "Tumtum",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tumtum, Washington"
    },
    {
      "city": "Wellpinit",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wellpinit, Washington"
    },
    {
      "city": "Addy",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Addy, Washington"
    },
    {
      "city": "Chewelah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Chewelah, Washington"
    },
    {
      "city": "Clayton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Clayton, Washington"
    },
    {
      "city": "Colville",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Colville, Washington"
    },
    {
      "city": "Evans",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Evans, Washington"
    },
    {
      "city": "Fruitland",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Fruitland, Washington"
    },
    {
      "city": "Gifford",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Gifford, Washington"
    },
    {
      "city": "Hunters",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hunters, Washington"
    },
    {
      "city": "Kettle Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Kettle Falls, Washington"
    },
    {
      "city": "Loon Lake",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Loon Lake, Washington"
    },
    {
      "city": "Marcus",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Marcus, Washington"
    },
    {
      "city": "Northport",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Northport, Washington"
    },
    {
      "city": "Rice",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rice, Washington"
    },
    {
      "city": "Springdale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Springdale, Washington"
    },
    {
      "city": "Valley",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Valley, Washington"
    },
    {
      "city": "Olympia",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Olympia, Washington"
    },
    {
      "city": "Lacey",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lacey, Washington"
    },
    {
      "city": "Tumwater",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tumwater, Washington"
    },
    {
      "city": "Bucoda",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bucoda, Washington"
    },
    {
      "city": "East Olympia",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "East Olympia, Washington"
    },
    {
      "city": "Littlerock",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Littlerock, Washington"
    },
    {
      "city": "Rainier",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rainier, Washington"
    },
    {
      "city": "Rochester",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rochester, Washington"
    },
    {
      "city": "Tenino",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tenino, Washington"
    },
    {
      "city": "Yelm",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Yelm, Washington"
    },
    {
      "city": "Cathlamet",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cathlamet, Washington"
    },
    {
      "city": "Grays River",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Grays River, Washington"
    },
    {
      "city": "Rosburg",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rosburg, Washington"
    },
    {
      "city": "Skamokawa",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Skamokawa, Washington"
    },
    {
      "city": "Burbank",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Burbank, Washington"
    },
    {
      "city": "College Place",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "College Place, Washington"
    },
    {
      "city": "Dixie",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Dixie, Washington"
    },
    {
      "city": "Prescott",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Prescott, Washington"
    },
    {
      "city": "Touchet",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Touchet, Washington"
    },
    {
      "city": "Waitsburg",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Waitsburg, Washington"
    },
    {
      "city": "Walla Walla",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Walla Walla, Washington"
    },
    {
      "city": "Wallula",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wallula, Washington"
    },
    {
      "city": "Acme",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Acme, Washington"
    },
    {
      "city": "Bellingham",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Bellingham, Washington"
    },
    {
      "city": "Blaine",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Blaine, Washington"
    },
    {
      "city": "Custer",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Custer, Washington"
    },
    {
      "city": "Deming",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Deming, Washington"
    },
    {
      "city": "Everson",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Everson, Washington"
    },
    {
      "city": "Ferndale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Ferndale, Washington"
    },
    {
      "city": "Lummi Island",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lummi Island, Washington"
    },
    {
      "city": "Lynden",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lynden, Washington"
    },
    {
      "city": "Maple Falls",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Maple Falls, Washington"
    },
    {
      "city": "Nooksack",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Nooksack, Washington"
    },
    {
      "city": "Point Roberts",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Point Roberts, Washington"
    },
    {
      "city": "Sumas",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sumas, Washington"
    },
    {
      "city": "Lamont",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lamont, Washington"
    },
    {
      "city": "Tekoa",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tekoa, Washington"
    },
    {
      "city": "Albion",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Albion, Washington"
    },
    {
      "city": "Belmont",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Belmont, Washington"
    },
    {
      "city": "Colfax",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Colfax, Washington"
    },
    {
      "city": "Colton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Colton, Washington"
    },
    {
      "city": "Endicott",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Endicott, Washington"
    },
    {
      "city": "Farmington",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Farmington, Washington"
    },
    {
      "city": "Garfield",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Garfield, Washington"
    },
    {
      "city": "Hay",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hay, Washington"
    },
    {
      "city": "Lacrosse",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Lacrosse, Washington"
    },
    {
      "city": "Malden",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Malden, Washington"
    },
    {
      "city": "Oakesdale",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Oakesdale, Washington"
    },
    {
      "city": "Palouse",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Palouse, Washington"
    },
    {
      "city": "Pullman",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Pullman, Washington"
    },
    {
      "city": "Rosalia",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Rosalia, Washington"
    },
    {
      "city": "Saint John",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Saint John, Washington"
    },
    {
      "city": "Steptoe",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Steptoe, Washington"
    },
    {
      "city": "Thornton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Thornton, Washington"
    },
    {
      "city": "Uniontown",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Uniontown, Washington"
    },
    {
      "city": "Hooper",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Hooper, Washington"
    },
    {
      "city": "Yakima",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Yakima, Washington"
    },
    {
      "city": "Brownstown",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Brownstown, Washington"
    },
    {
      "city": "Buena",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Buena, Washington"
    },
    {
      "city": "Cowiche",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Cowiche, Washington"
    },
    {
      "city": "Grandview",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Grandview, Washington"
    },
    {
      "city": "Granger",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Granger, Washington"
    },
    {
      "city": "Harrah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Harrah, Washington"
    },
    {
      "city": "Mabton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Mabton, Washington"
    },
    {
      "city": "Moxee",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Moxee, Washington"
    },
    {
      "city": "Naches",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Naches, Washington"
    },
    {
      "city": "Outlook",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Outlook, Washington"
    },
    {
      "city": "Parker",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Parker, Washington"
    },
    {
      "city": "Selah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Selah, Washington"
    },
    {
      "city": "Sunnyside",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Sunnyside, Washington"
    },
    {
      "city": "Tieton",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Tieton, Washington"
    },
    {
      "city": "Toppenish",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Toppenish, Washington"
    },
    {
      "city": "Wapato",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Wapato, Washington"
    },
    {
      "city": "White Swan",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "White Swan, Washington"
    },
    {
      "city": "Zillah",
      "state": "Washington",
      "shortState": "WA",
      "formatedCity": "Zillah, Washington"
    },
    {
      "city": "Adams",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Adams, Wisconsin"
    },
    {
      "city": "Dellwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dellwood, Wisconsin"
    },
    {
      "city": "Friendship",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Friendship, Wisconsin"
    },
    {
      "city": "Grand Marsh",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Grand Marsh, Wisconsin"
    },
    {
      "city": "Arkdale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arkdale, Wisconsin"
    },
    {
      "city": "Butternut",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Butternut, Wisconsin"
    },
    {
      "city": "Clam Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clam Lake, Wisconsin"
    },
    {
      "city": "Glidden",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Glidden, Wisconsin"
    },
    {
      "city": "Mellen",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mellen, Wisconsin"
    },
    {
      "city": "Ashland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ashland, Wisconsin"
    },
    {
      "city": "High Bridge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "High Bridge, Wisconsin"
    },
    {
      "city": "La Pointe",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "La Pointe, Wisconsin"
    },
    {
      "city": "Marengo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marengo, Wisconsin"
    },
    {
      "city": "Odanah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Odanah, Wisconsin"
    },
    {
      "city": "Chetek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Chetek, Wisconsin"
    },
    {
      "city": "Dallas",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dallas, Wisconsin"
    },
    {
      "city": "Prairie Farm",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Prairie Farm, Wisconsin"
    },
    {
      "city": "Almena",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Almena, Wisconsin"
    },
    {
      "city": "Barron",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Barron, Wisconsin"
    },
    {
      "city": "Barronett",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Barronett, Wisconsin"
    },
    {
      "city": "Brill",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brill, Wisconsin"
    },
    {
      "city": "Cameron",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cameron, Wisconsin"
    },
    {
      "city": "Comstock",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Comstock, Wisconsin"
    },
    {
      "city": "Cumberland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cumberland, Wisconsin"
    },
    {
      "city": "Haugen",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Haugen, Wisconsin"
    },
    {
      "city": "Mikana",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mikana, Wisconsin"
    },
    {
      "city": "Rice Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rice Lake, Wisconsin"
    },
    {
      "city": "Turtle Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Turtle Lake, Wisconsin"
    },
    {
      "city": "Bayfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bayfield, Wisconsin"
    },
    {
      "city": "Benoit",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Benoit, Wisconsin"
    },
    {
      "city": "Cable",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cable, Wisconsin"
    },
    {
      "city": "Cornucopia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cornucopia, Wisconsin"
    },
    {
      "city": "Drummond",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Drummond, Wisconsin"
    },
    {
      "city": "Grand View",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Grand View, Wisconsin"
    },
    {
      "city": "Herbster",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Herbster, Wisconsin"
    },
    {
      "city": "Iron River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Iron River, Wisconsin"
    },
    {
      "city": "Mason",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mason, Wisconsin"
    },
    {
      "city": "Port Wing",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Port Wing, Wisconsin"
    },
    {
      "city": "Washburn",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Washburn, Wisconsin"
    },
    {
      "city": "De Pere",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "De Pere, Wisconsin"
    },
    {
      "city": "Greenleaf",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Greenleaf, Wisconsin"
    },
    {
      "city": "Pulaski",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pulaski, Wisconsin"
    },
    {
      "city": "Suamico",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Suamico, Wisconsin"
    },
    {
      "city": "Wrightstown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wrightstown, Wisconsin"
    },
    {
      "city": "Denmark",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Denmark, Wisconsin"
    },
    {
      "city": "New Franken",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Franken, Wisconsin"
    },
    {
      "city": "Green Bay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Green Bay, Wisconsin"
    },
    {
      "city": "Alma",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Alma, Wisconsin"
    },
    {
      "city": "Cochrane",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cochrane, Wisconsin"
    },
    {
      "city": "Fountain City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fountain City, Wisconsin"
    },
    {
      "city": "Gilmanton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gilmanton, Wisconsin"
    },
    {
      "city": "Mondovi",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mondovi, Wisconsin"
    },
    {
      "city": "Nelson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Nelson, Wisconsin"
    },
    {
      "city": "Danbury",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Danbury, Wisconsin"
    },
    {
      "city": "Grantsburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Grantsburg, Wisconsin"
    },
    {
      "city": "Hertel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hertel, Wisconsin"
    },
    {
      "city": "Siren",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Siren, Wisconsin"
    },
    {
      "city": "Webster",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Webster, Wisconsin"
    },
    {
      "city": "Chilton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Chilton, Wisconsin"
    },
    {
      "city": "New Holstein",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Holstein, Wisconsin"
    },
    {
      "city": "Stockbridge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stockbridge, Wisconsin"
    },
    {
      "city": "Brillion",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brillion, Wisconsin"
    },
    {
      "city": "Forest Junction",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Forest Junction, Wisconsin"
    },
    {
      "city": "Hilbert",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hilbert, Wisconsin"
    },
    {
      "city": "Potter",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Potter, Wisconsin"
    },
    {
      "city": "Sherwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sherwood, Wisconsin"
    },
    {
      "city": "Bloomer",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bloomer, Wisconsin"
    },
    {
      "city": "Boyd",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Boyd, Wisconsin"
    },
    {
      "city": "Cadott",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cadott, Wisconsin"
    },
    {
      "city": "Chippewa Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Chippewa Falls, Wisconsin"
    },
    {
      "city": "Cornell",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cornell, Wisconsin"
    },
    {
      "city": "Holcombe",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Holcombe, Wisconsin"
    },
    {
      "city": "Jim Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Jim Falls, Wisconsin"
    },
    {
      "city": "New Auburn",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Auburn, Wisconsin"
    },
    {
      "city": "Stanley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stanley, Wisconsin"
    },
    {
      "city": "Abbotsford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Abbotsford, Wisconsin"
    },
    {
      "city": "Chili",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Chili, Wisconsin"
    },
    {
      "city": "Colby",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Colby, Wisconsin"
    },
    {
      "city": "Curtiss",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Curtiss, Wisconsin"
    },
    {
      "city": "Dorchester",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dorchester, Wisconsin"
    },
    {
      "city": "Granton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Granton, Wisconsin"
    },
    {
      "city": "Greenwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Greenwood, Wisconsin"
    },
    {
      "city": "Loyal",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Loyal, Wisconsin"
    },
    {
      "city": "Neillsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Neillsville, Wisconsin"
    },
    {
      "city": "Owen",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Owen, Wisconsin"
    },
    {
      "city": "Willard",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Willard, Wisconsin"
    },
    {
      "city": "Withee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Withee, Wisconsin"
    },
    {
      "city": "Humbird",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Humbird, Wisconsin"
    },
    {
      "city": "Thorp",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Thorp, Wisconsin"
    },
    {
      "city": "Lodi",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lodi, Wisconsin"
    },
    {
      "city": "Portage",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Portage, Wisconsin"
    },
    {
      "city": "Arlington",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arlington, Wisconsin"
    },
    {
      "city": "Cambria",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cambria, Wisconsin"
    },
    {
      "city": "Columbus",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Columbus, Wisconsin"
    },
    {
      "city": "Doylestown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Doylestown, Wisconsin"
    },
    {
      "city": "Fall River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fall River, Wisconsin"
    },
    {
      "city": "Friesland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Friesland, Wisconsin"
    },
    {
      "city": "Pardeeville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pardeeville, Wisconsin"
    },
    {
      "city": "Poynette",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Poynette, Wisconsin"
    },
    {
      "city": "Randolph",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Randolph, Wisconsin"
    },
    {
      "city": "Rio",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rio, Wisconsin"
    },
    {
      "city": "Wisconsin Dells",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wisconsin Dells, Wisconsin"
    },
    {
      "city": "Wyocena",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wyocena, Wisconsin"
    },
    {
      "city": "Prairie Du Chien",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Prairie Du Chien, Wisconsin"
    },
    {
      "city": "Wauzeka",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wauzeka, Wisconsin"
    },
    {
      "city": "Eastman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eastman, Wisconsin"
    },
    {
      "city": "Ferryville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ferryville, Wisconsin"
    },
    {
      "city": "Gays Mills",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gays Mills, Wisconsin"
    },
    {
      "city": "Lynxville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lynxville, Wisconsin"
    },
    {
      "city": "Mount Sterling",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mount Sterling, Wisconsin"
    },
    {
      "city": "Seneca",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Seneca, Wisconsin"
    },
    {
      "city": "Soldiers Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Soldiers Grove, Wisconsin"
    },
    {
      "city": "Steuben",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Steuben, Wisconsin"
    },
    {
      "city": "Belleville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Belleville, Wisconsin"
    },
    {
      "city": "Black Earth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Black Earth, Wisconsin"
    },
    {
      "city": "Blue Mounds",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Blue Mounds, Wisconsin"
    },
    {
      "city": "Cambridge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cambridge, Wisconsin"
    },
    {
      "city": "Cottage Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cottage Grove, Wisconsin"
    },
    {
      "city": "Cross Plains",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cross Plains, Wisconsin"
    },
    {
      "city": "Dane",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dane, Wisconsin"
    },
    {
      "city": "Deerfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Deerfield, Wisconsin"
    },
    {
      "city": "Deforest",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Deforest, Wisconsin"
    },
    {
      "city": "Mc Farland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mc Farland, Wisconsin"
    },
    {
      "city": "Marshall",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marshall, Wisconsin"
    },
    {
      "city": "Mazomanie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mazomanie, Wisconsin"
    },
    {
      "city": "Middleton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Middleton, Wisconsin"
    },
    {
      "city": "Morrisonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Morrisonville, Wisconsin"
    },
    {
      "city": "Mount Horeb",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mount Horeb, Wisconsin"
    },
    {
      "city": "Oregon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oregon, Wisconsin"
    },
    {
      "city": "Stoughton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stoughton, Wisconsin"
    },
    {
      "city": "Sun Prairie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sun Prairie, Wisconsin"
    },
    {
      "city": "Verona",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Verona, Wisconsin"
    },
    {
      "city": "Waunakee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waunakee, Wisconsin"
    },
    {
      "city": "Windsor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Windsor, Wisconsin"
    },
    {
      "city": "Madison",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Madison, Wisconsin"
    },
    {
      "city": "Ashippun",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ashippun, Wisconsin"
    },
    {
      "city": "Brownsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brownsville, Wisconsin"
    },
    {
      "city": "Clyman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clyman, Wisconsin"
    },
    {
      "city": "Horicon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Horicon, Wisconsin"
    },
    {
      "city": "Hustisford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hustisford, Wisconsin"
    },
    {
      "city": "Iron Ridge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Iron Ridge, Wisconsin"
    },
    {
      "city": "Juneau",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Juneau, Wisconsin"
    },
    {
      "city": "Lebanon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lebanon, Wisconsin"
    },
    {
      "city": "Lomira",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lomira, Wisconsin"
    },
    {
      "city": "Mayville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mayville, Wisconsin"
    },
    {
      "city": "Neosho",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Neosho, Wisconsin"
    },
    {
      "city": "Rubicon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rubicon, Wisconsin"
    },
    {
      "city": "Theresa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Theresa, Wisconsin"
    },
    {
      "city": "Watertown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Watertown, Wisconsin"
    },
    {
      "city": "Woodland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodland, Wisconsin"
    },
    {
      "city": "Lowell",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lowell, Wisconsin"
    },
    {
      "city": "Reeseville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Reeseville, Wisconsin"
    },
    {
      "city": "Beaver Dam",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Beaver Dam, Wisconsin"
    },
    {
      "city": "Burnett",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Burnett, Wisconsin"
    },
    {
      "city": "Fox Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fox Lake, Wisconsin"
    },
    {
      "city": "Waupun",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waupun, Wisconsin"
    },
    {
      "city": "Baileys Harbor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Baileys Harbor, Wisconsin"
    },
    {
      "city": "Brussels",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brussels, Wisconsin"
    },
    {
      "city": "Egg Harbor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Egg Harbor, Wisconsin"
    },
    {
      "city": "Ellison Bay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ellison Bay, Wisconsin"
    },
    {
      "city": "Ephraim",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ephraim, Wisconsin"
    },
    {
      "city": "Fish Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fish Creek, Wisconsin"
    },
    {
      "city": "Forestville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Forestville, Wisconsin"
    },
    {
      "city": "Maplewood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Maplewood, Wisconsin"
    },
    {
      "city": "Sister Bay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sister Bay, Wisconsin"
    },
    {
      "city": "Sturgeon Bay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sturgeon Bay, Wisconsin"
    },
    {
      "city": "Washington Island",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Washington Island, Wisconsin"
    },
    {
      "city": "Brule",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brule, Wisconsin"
    },
    {
      "city": "Foxboro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Foxboro, Wisconsin"
    },
    {
      "city": "Gordon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gordon, Wisconsin"
    },
    {
      "city": "Hawthorne",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hawthorne, Wisconsin"
    },
    {
      "city": "Lake Nebagamon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lake Nebagamon, Wisconsin"
    },
    {
      "city": "Maple",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Maple, Wisconsin"
    },
    {
      "city": "Poplar",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Poplar, Wisconsin"
    },
    {
      "city": "Solon Springs",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Solon Springs, Wisconsin"
    },
    {
      "city": "South Range",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "South Range, Wisconsin"
    },
    {
      "city": "Superior",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Superior, Wisconsin"
    },
    {
      "city": "Wascott",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wascott, Wisconsin"
    },
    {
      "city": "Boyceville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Boyceville, Wisconsin"
    },
    {
      "city": "Colfax",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Colfax, Wisconsin"
    },
    {
      "city": "Downing",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Downing, Wisconsin"
    },
    {
      "city": "Downsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Downsville, Wisconsin"
    },
    {
      "city": "Eau Galle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eau Galle, Wisconsin"
    },
    {
      "city": "Elk Mound",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elk Mound, Wisconsin"
    },
    {
      "city": "Knapp",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Knapp, Wisconsin"
    },
    {
      "city": "Menomonie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Menomonie, Wisconsin"
    },
    {
      "city": "Ridgeland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ridgeland, Wisconsin"
    },
    {
      "city": "Rock Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rock Falls, Wisconsin"
    },
    {
      "city": "Sand Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sand Creek, Wisconsin"
    },
    {
      "city": "Wheeler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wheeler, Wisconsin"
    },
    {
      "city": "Eau Claire",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eau Claire, Wisconsin"
    },
    {
      "city": "Altoona",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Altoona, Wisconsin"
    },
    {
      "city": "Augusta",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Augusta, Wisconsin"
    },
    {
      "city": "Fairchild",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fairchild, Wisconsin"
    },
    {
      "city": "Fall Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fall Creek, Wisconsin"
    },
    {
      "city": "Fence",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fence, Wisconsin"
    },
    {
      "city": "Florence",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Florence, Wisconsin"
    },
    {
      "city": "Long Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Long Lake, Wisconsin"
    },
    {
      "city": "Campbellsport",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Campbellsport, Wisconsin"
    },
    {
      "city": "Eden",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eden, Wisconsin"
    },
    {
      "city": "Malone",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Malone, Wisconsin"
    },
    {
      "city": "Mount Calvary",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mount Calvary, Wisconsin"
    },
    {
      "city": "Oakfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oakfield, Wisconsin"
    },
    {
      "city": "Saint Cloud",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saint Cloud, Wisconsin"
    },
    {
      "city": "Brandon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brandon, Wisconsin"
    },
    {
      "city": "Fairwater",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fairwater, Wisconsin"
    },
    {
      "city": "Eldorado",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eldorado, Wisconsin"
    },
    {
      "city": "Fond Du Lac",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fond Du Lac, Wisconsin"
    },
    {
      "city": "Ripon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ripon, Wisconsin"
    },
    {
      "city": "Rosendale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rosendale, Wisconsin"
    },
    {
      "city": "Van Dyne",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Van Dyne, Wisconsin"
    },
    {
      "city": "Armstrong Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Armstrong Creek, Wisconsin"
    },
    {
      "city": "Argonne",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Argonne, Wisconsin"
    },
    {
      "city": "Crandon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Crandon, Wisconsin"
    },
    {
      "city": "Laona",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Laona, Wisconsin"
    },
    {
      "city": "Wabeno",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wabeno, Wisconsin"
    },
    {
      "city": "Blue River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Blue River, Wisconsin"
    },
    {
      "city": "Livingston",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Livingston, Wisconsin"
    },
    {
      "city": "Montfort",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Montfort, Wisconsin"
    },
    {
      "city": "Muscoda",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Muscoda, Wisconsin"
    },
    {
      "city": "Bagley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bagley, Wisconsin"
    },
    {
      "city": "Beetown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Beetown, Wisconsin"
    },
    {
      "city": "Bloomington",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bloomington, Wisconsin"
    },
    {
      "city": "Boscobel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Boscobel, Wisconsin"
    },
    {
      "city": "Cassville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cassville, Wisconsin"
    },
    {
      "city": "Cuba City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cuba City, Wisconsin"
    },
    {
      "city": "Dickeyville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dickeyville, Wisconsin"
    },
    {
      "city": "Fennimore",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fennimore, Wisconsin"
    },
    {
      "city": "Glen Haven",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Glen Haven, Wisconsin"
    },
    {
      "city": "Hazel Green",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hazel Green, Wisconsin"
    },
    {
      "city": "Kieler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kieler, Wisconsin"
    },
    {
      "city": "Lancaster",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lancaster, Wisconsin"
    },
    {
      "city": "Mount Hope",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mount Hope, Wisconsin"
    },
    {
      "city": "Patch Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Patch Grove, Wisconsin"
    },
    {
      "city": "Platteville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Platteville, Wisconsin"
    },
    {
      "city": "Potosi",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Potosi, Wisconsin"
    },
    {
      "city": "Sinsinawa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sinsinawa, Wisconsin"
    },
    {
      "city": "Stitzer",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stitzer, Wisconsin"
    },
    {
      "city": "Woodman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodman, Wisconsin"
    },
    {
      "city": "Albany",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Albany, Wisconsin"
    },
    {
      "city": "Brodhead",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brodhead, Wisconsin"
    },
    {
      "city": "Brooklyn",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brooklyn, Wisconsin"
    },
    {
      "city": "Browntown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Browntown, Wisconsin"
    },
    {
      "city": "Juda",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Juda, Wisconsin"
    },
    {
      "city": "Monroe",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Monroe, Wisconsin"
    },
    {
      "city": "Monticello",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Monticello, Wisconsin"
    },
    {
      "city": "New Glarus",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Glarus, Wisconsin"
    },
    {
      "city": "Dalton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dalton, Wisconsin"
    },
    {
      "city": "Kingston",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kingston, Wisconsin"
    },
    {
      "city": "Markesan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Markesan, Wisconsin"
    },
    {
      "city": "Marquette",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marquette, Wisconsin"
    },
    {
      "city": "Berlin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Berlin, Wisconsin"
    },
    {
      "city": "Green Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Green Lake, Wisconsin"
    },
    {
      "city": "Princeton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Princeton, Wisconsin"
    },
    {
      "city": "Arena",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arena, Wisconsin"
    },
    {
      "city": "Avoca",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Avoca, Wisconsin"
    },
    {
      "city": "Barneveld",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Barneveld, Wisconsin"
    },
    {
      "city": "Cobb",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cobb, Wisconsin"
    },
    {
      "city": "Dodgeville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dodgeville, Wisconsin"
    },
    {
      "city": "Edmund",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Edmund, Wisconsin"
    },
    {
      "city": "Highland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Highland, Wisconsin"
    },
    {
      "city": "Hollandale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hollandale, Wisconsin"
    },
    {
      "city": "Linden",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Linden, Wisconsin"
    },
    {
      "city": "Mineral Point",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mineral Point, Wisconsin"
    },
    {
      "city": "Rewey",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rewey, Wisconsin"
    },
    {
      "city": "Ridgeway",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ridgeway, Wisconsin"
    },
    {
      "city": "Gile",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gile, Wisconsin"
    },
    {
      "city": "Hurley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hurley, Wisconsin"
    },
    {
      "city": "Iron Belt",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Iron Belt, Wisconsin"
    },
    {
      "city": "Mercer",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mercer, Wisconsin"
    },
    {
      "city": "Montreal",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Montreal, Wisconsin"
    },
    {
      "city": "Saxon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saxon, Wisconsin"
    },
    {
      "city": "Upson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Upson, Wisconsin"
    },
    {
      "city": "Alma Center",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Alma Center, Wisconsin"
    },
    {
      "city": "Black River Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Black River Falls, Wisconsin"
    },
    {
      "city": "Hixton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hixton, Wisconsin"
    },
    {
      "city": "Melrose",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Melrose, Wisconsin"
    },
    {
      "city": "Millston",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Millston, Wisconsin"
    },
    {
      "city": "Taylor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Taylor, Wisconsin"
    },
    {
      "city": "Merrillan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Merrillan, Wisconsin"
    },
    {
      "city": "Ixonia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ixonia, Wisconsin"
    },
    {
      "city": "Johnson Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Johnson Creek, Wisconsin"
    },
    {
      "city": "Helenville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Helenville, Wisconsin"
    },
    {
      "city": "Palmyra",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Palmyra, Wisconsin"
    },
    {
      "city": "Sullivan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sullivan, Wisconsin"
    },
    {
      "city": "Fort Atkinson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fort Atkinson, Wisconsin"
    },
    {
      "city": "Jefferson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Jefferson, Wisconsin"
    },
    {
      "city": "Lake Mills",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lake Mills, Wisconsin"
    },
    {
      "city": "Waterloo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waterloo, Wisconsin"
    },
    {
      "city": "Elroy",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elroy, Wisconsin"
    },
    {
      "city": "Lyndon Station",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lyndon Station, Wisconsin"
    },
    {
      "city": "Mauston",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mauston, Wisconsin"
    },
    {
      "city": "New Lisbon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Lisbon, Wisconsin"
    },
    {
      "city": "Union Center",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Union Center, Wisconsin"
    },
    {
      "city": "Wonewoc",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wonewoc, Wisconsin"
    },
    {
      "city": "Camp Douglas",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Camp Douglas, Wisconsin"
    },
    {
      "city": "Hustler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hustler, Wisconsin"
    },
    {
      "city": "Mather",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mather, Wisconsin"
    },
    {
      "city": "Necedah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Necedah, Wisconsin"
    },
    {
      "city": "Bassett",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bassett, Wisconsin"
    },
    {
      "city": "Benet Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Benet Lake, Wisconsin"
    },
    {
      "city": "Bristol",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bristol, Wisconsin"
    },
    {
      "city": "Camp Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Camp Lake, Wisconsin"
    },
    {
      "city": "Kenosha",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kenosha, Wisconsin"
    },
    {
      "city": "New Munster",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Munster, Wisconsin"
    },
    {
      "city": "Pleasant Prairie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pleasant Prairie, Wisconsin"
    },
    {
      "city": "Powers Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Powers Lake, Wisconsin"
    },
    {
      "city": "Salem",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Salem, Wisconsin"
    },
    {
      "city": "Silver Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Silver Lake, Wisconsin"
    },
    {
      "city": "Somers",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Somers, Wisconsin"
    },
    {
      "city": "Trevor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Trevor, Wisconsin"
    },
    {
      "city": "Twin Lakes",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Twin Lakes, Wisconsin"
    },
    {
      "city": "Wilmot",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wilmot, Wisconsin"
    },
    {
      "city": "Woodworth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodworth, Wisconsin"
    },
    {
      "city": "Algoma",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Algoma, Wisconsin"
    },
    {
      "city": "Casco",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Casco, Wisconsin"
    },
    {
      "city": "Kewaunee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kewaunee, Wisconsin"
    },
    {
      "city": "Luxemburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Luxemburg, Wisconsin"
    },
    {
      "city": "La Crosse",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "La Crosse, Wisconsin"
    },
    {
      "city": "Bangor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bangor, Wisconsin"
    },
    {
      "city": "Holmen",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Holmen, Wisconsin"
    },
    {
      "city": "Mindoro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mindoro, Wisconsin"
    },
    {
      "city": "Onalaska",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Onalaska, Wisconsin"
    },
    {
      "city": "Rockland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rockland, Wisconsin"
    },
    {
      "city": "West Salem",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "West Salem, Wisconsin"
    },
    {
      "city": "Argyle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Argyle, Wisconsin"
    },
    {
      "city": "Belmont",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Belmont, Wisconsin"
    },
    {
      "city": "Blanchardville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Blanchardville, Wisconsin"
    },
    {
      "city": "Darlington",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Darlington, Wisconsin"
    },
    {
      "city": "Gratiot",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gratiot, Wisconsin"
    },
    {
      "city": "Shullsburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Shullsburg, Wisconsin"
    },
    {
      "city": "South Wayne",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "South Wayne, Wisconsin"
    },
    {
      "city": "Woodford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodford, Wisconsin"
    },
    {
      "city": "Benton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Benton, Wisconsin"
    },
    {
      "city": "Antigo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Antigo, Wisconsin"
    },
    {
      "city": "Bryant",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bryant, Wisconsin"
    },
    {
      "city": "Deerbrook",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Deerbrook, Wisconsin"
    },
    {
      "city": "Elcho",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elcho, Wisconsin"
    },
    {
      "city": "Elton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elton, Wisconsin"
    },
    {
      "city": "Pearson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pearson, Wisconsin"
    },
    {
      "city": "Phlox",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Phlox, Wisconsin"
    },
    {
      "city": "Pickerel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pickerel, Wisconsin"
    },
    {
      "city": "Summit Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Summit Lake, Wisconsin"
    },
    {
      "city": "White Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "White Lake, Wisconsin"
    },
    {
      "city": "Gleason",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gleason, Wisconsin"
    },
    {
      "city": "Irma",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Irma, Wisconsin"
    },
    {
      "city": "Merrill",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Merrill, Wisconsin"
    },
    {
      "city": "Tomahawk",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tomahawk, Wisconsin"
    },
    {
      "city": "Heafford Junction",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Heafford Junction, Wisconsin"
    },
    {
      "city": "Cleveland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cleveland, Wisconsin"
    },
    {
      "city": "Kiel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kiel, Wisconsin"
    },
    {
      "city": "Newton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Newton, Wisconsin"
    },
    {
      "city": "Collins",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Collins, Wisconsin"
    },
    {
      "city": "Francis Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Francis Creek, Wisconsin"
    },
    {
      "city": "Kellnersville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kellnersville, Wisconsin"
    },
    {
      "city": "Manitowoc",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Manitowoc, Wisconsin"
    },
    {
      "city": "Maribel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Maribel, Wisconsin"
    },
    {
      "city": "Mishicot",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mishicot, Wisconsin"
    },
    {
      "city": "Reedsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Reedsville, Wisconsin"
    },
    {
      "city": "Saint Nazianz",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saint Nazianz, Wisconsin"
    },
    {
      "city": "Tisch Mills",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tisch Mills, Wisconsin"
    },
    {
      "city": "Two Rivers",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Two Rivers, Wisconsin"
    },
    {
      "city": "Valders",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Valders, Wisconsin"
    },
    {
      "city": "Whitelaw",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Whitelaw, Wisconsin"
    },
    {
      "city": "Wausau",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wausau, Wisconsin"
    },
    {
      "city": "Aniwa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Aniwa, Wisconsin"
    },
    {
      "city": "Athens",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Athens, Wisconsin"
    },
    {
      "city": "Brokaw",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brokaw, Wisconsin"
    },
    {
      "city": "Edgar",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Edgar, Wisconsin"
    },
    {
      "city": "Eland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eland, Wisconsin"
    },
    {
      "city": "Elderon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elderon, Wisconsin"
    },
    {
      "city": "Galloway",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Galloway, Wisconsin"
    },
    {
      "city": "Hatley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hatley, Wisconsin"
    },
    {
      "city": "Marathon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marathon, Wisconsin"
    },
    {
      "city": "Mosinee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mosinee, Wisconsin"
    },
    {
      "city": "Ringle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ringle, Wisconsin"
    },
    {
      "city": "Rothschild",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rothschild, Wisconsin"
    },
    {
      "city": "Schofield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Schofield, Wisconsin"
    },
    {
      "city": "Spencer",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Spencer, Wisconsin"
    },
    {
      "city": "Stratford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stratford, Wisconsin"
    },
    {
      "city": "Unity",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Unity, Wisconsin"
    },
    {
      "city": "Amberg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Amberg, Wisconsin"
    },
    {
      "city": "Athelstane",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Athelstane, Wisconsin"
    },
    {
      "city": "Coleman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Coleman, Wisconsin"
    },
    {
      "city": "Crivitz",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Crivitz, Wisconsin"
    },
    {
      "city": "Dunbar",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dunbar, Wisconsin"
    },
    {
      "city": "Goodman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Goodman, Wisconsin"
    },
    {
      "city": "Marinette",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marinette, Wisconsin"
    },
    {
      "city": "Niagara",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Niagara, Wisconsin"
    },
    {
      "city": "Pembine",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pembine, Wisconsin"
    },
    {
      "city": "Peshtigo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Peshtigo, Wisconsin"
    },
    {
      "city": "Porterfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Porterfield, Wisconsin"
    },
    {
      "city": "Pound",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pound, Wisconsin"
    },
    {
      "city": "Wausaukee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wausaukee, Wisconsin"
    },
    {
      "city": "Briggsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Briggsville, Wisconsin"
    },
    {
      "city": "Endeavor",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Endeavor, Wisconsin"
    },
    {
      "city": "Montello",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Montello, Wisconsin"
    },
    {
      "city": "Oxford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oxford, Wisconsin"
    },
    {
      "city": "Packwaukee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Packwaukee, Wisconsin"
    },
    {
      "city": "Westfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Westfield, Wisconsin"
    },
    {
      "city": "Neshkoro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Neshkoro, Wisconsin"
    },
    {
      "city": "Keshena",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Keshena, Wisconsin"
    },
    {
      "city": "Neopit",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Neopit, Wisconsin"
    },
    {
      "city": "Cudahy",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cudahy, Wisconsin"
    },
    {
      "city": "Greendale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Greendale, Wisconsin"
    },
    {
      "city": "Hales Corners",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hales Corners, Wisconsin"
    },
    {
      "city": "Franklin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Franklin, Wisconsin"
    },
    {
      "city": "Oak Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oak Creek, Wisconsin"
    },
    {
      "city": "South Milwaukee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "South Milwaukee, Wisconsin"
    },
    {
      "city": "Milwaukee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Milwaukee, Wisconsin"
    },
    {
      "city": "Saint Francis",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saint Francis, Wisconsin"
    },
    {
      "city": "Cashton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cashton, Wisconsin"
    },
    {
      "city": "Cataract",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cataract, Wisconsin"
    },
    {
      "city": "Kendall",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kendall, Wisconsin"
    },
    {
      "city": "Norwalk",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Norwalk, Wisconsin"
    },
    {
      "city": "Oakdale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oakdale, Wisconsin"
    },
    {
      "city": "Sparta",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sparta, Wisconsin"
    },
    {
      "city": "Tomah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tomah, Wisconsin"
    },
    {
      "city": "Tunnel City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tunnel City, Wisconsin"
    },
    {
      "city": "Warrens",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Warrens, Wisconsin"
    },
    {
      "city": "Wilton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wilton, Wisconsin"
    },
    {
      "city": "Abrams",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Abrams, Wisconsin"
    },
    {
      "city": "Gillett",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gillett, Wisconsin"
    },
    {
      "city": "Lakewood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lakewood, Wisconsin"
    },
    {
      "city": "Lena",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lena, Wisconsin"
    },
    {
      "city": "Little Suamico",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Little Suamico, Wisconsin"
    },
    {
      "city": "Mountain",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mountain, Wisconsin"
    },
    {
      "city": "Oconto",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oconto, Wisconsin"
    },
    {
      "city": "Oconto Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oconto Falls, Wisconsin"
    },
    {
      "city": "Sobieski",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sobieski, Wisconsin"
    },
    {
      "city": "Suring",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Suring, Wisconsin"
    },
    {
      "city": "Townsend",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Townsend, Wisconsin"
    },
    {
      "city": "Pelican Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pelican Lake, Wisconsin"
    },
    {
      "city": "Rhinelander",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rhinelander, Wisconsin"
    },
    {
      "city": "Harshaw",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Harshaw, Wisconsin"
    },
    {
      "city": "Hazelhurst",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hazelhurst, Wisconsin"
    },
    {
      "city": "Lake Tomahawk",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lake Tomahawk, Wisconsin"
    },
    {
      "city": "Mc Naughton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mc Naughton, Wisconsin"
    },
    {
      "city": "Minocqua",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Minocqua, Wisconsin"
    },
    {
      "city": "Three Lakes",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Three Lakes, Wisconsin"
    },
    {
      "city": "Tripoli",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tripoli, Wisconsin"
    },
    {
      "city": "Woodruff",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodruff, Wisconsin"
    },
    {
      "city": "Black Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Black Creek, Wisconsin"
    },
    {
      "city": "Combined Locks",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Combined Locks, Wisconsin"
    },
    {
      "city": "Kaukauna",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kaukauna, Wisconsin"
    },
    {
      "city": "Freedom",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Freedom, Wisconsin"
    },
    {
      "city": "Kimberly",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kimberly, Wisconsin"
    },
    {
      "city": "Little Chute",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Little Chute, Wisconsin"
    },
    {
      "city": "Nichols",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Nichols, Wisconsin"
    },
    {
      "city": "Oneida",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oneida, Wisconsin"
    },
    {
      "city": "Seymour",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Seymour, Wisconsin"
    },
    {
      "city": "Shiocton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Shiocton, Wisconsin"
    },
    {
      "city": "Appleton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Appleton, Wisconsin"
    },
    {
      "city": "Bear Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bear Creek, Wisconsin"
    },
    {
      "city": "Dale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dale, Wisconsin"
    },
    {
      "city": "Greenville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Greenville, Wisconsin"
    },
    {
      "city": "Hortonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hortonville, Wisconsin"
    },
    {
      "city": "Belgium",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Belgium, Wisconsin"
    },
    {
      "city": "Cedarburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cedarburg, Wisconsin"
    },
    {
      "city": "Fredonia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fredonia, Wisconsin"
    },
    {
      "city": "Grafton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Grafton, Wisconsin"
    },
    {
      "city": "Port Washington",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Port Washington, Wisconsin"
    },
    {
      "city": "Saukville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saukville, Wisconsin"
    },
    {
      "city": "Mequon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mequon, Wisconsin"
    },
    {
      "city": "Arkansaw",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arkansaw, Wisconsin"
    },
    {
      "city": "Durand",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Durand, Wisconsin"
    },
    {
      "city": "Pepin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pepin, Wisconsin"
    },
    {
      "city": "Stockholm",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stockholm, Wisconsin"
    },
    {
      "city": "Beldenville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Beldenville, Wisconsin"
    },
    {
      "city": "East Ellsworth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "East Ellsworth, Wisconsin"
    },
    {
      "city": "Ellsworth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ellsworth, Wisconsin"
    },
    {
      "city": "Hager City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hager City, Wisconsin"
    },
    {
      "city": "Prescott",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Prescott, Wisconsin"
    },
    {
      "city": "River Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "River Falls, Wisconsin"
    },
    {
      "city": "Bay City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bay City, Wisconsin"
    },
    {
      "city": "Elmwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elmwood, Wisconsin"
    },
    {
      "city": "Maiden Rock",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Maiden Rock, Wisconsin"
    },
    {
      "city": "Plum City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Plum City, Wisconsin"
    },
    {
      "city": "Spring Valley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Spring Valley, Wisconsin"
    },
    {
      "city": "Amery",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Amery, Wisconsin"
    },
    {
      "city": "Clayton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clayton, Wisconsin"
    },
    {
      "city": "Clear Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clear Lake, Wisconsin"
    },
    {
      "city": "Cushing",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cushing, Wisconsin"
    },
    {
      "city": "Dresser",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dresser, Wisconsin"
    },
    {
      "city": "Osceola",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Osceola, Wisconsin"
    },
    {
      "city": "Saint Croix Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saint Croix Falls, Wisconsin"
    },
    {
      "city": "Star Prairie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Star Prairie, Wisconsin"
    },
    {
      "city": "Balsam Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Balsam Lake, Wisconsin"
    },
    {
      "city": "Centuria",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Centuria, Wisconsin"
    },
    {
      "city": "Frederic",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Frederic, Wisconsin"
    },
    {
      "city": "Luck",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Luck, Wisconsin"
    },
    {
      "city": "Milltown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Milltown, Wisconsin"
    },
    {
      "city": "Amherst",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Amherst, Wisconsin"
    },
    {
      "city": "Amherst Junction",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Amherst Junction, Wisconsin"
    },
    {
      "city": "Custer",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Custer, Wisconsin"
    },
    {
      "city": "Junction City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Junction City, Wisconsin"
    },
    {
      "city": "Nelsonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Nelsonville, Wisconsin"
    },
    {
      "city": "Plover",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Plover, Wisconsin"
    },
    {
      "city": "Rosholt",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rosholt, Wisconsin"
    },
    {
      "city": "Stevens Point",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stevens Point, Wisconsin"
    },
    {
      "city": "Almond",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Almond, Wisconsin"
    },
    {
      "city": "Bancroft",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bancroft, Wisconsin"
    },
    {
      "city": "Ogema",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ogema, Wisconsin"
    },
    {
      "city": "Brantwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brantwood, Wisconsin"
    },
    {
      "city": "Catawba",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Catawba, Wisconsin"
    },
    {
      "city": "Fifield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fifield, Wisconsin"
    },
    {
      "city": "Kennan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kennan, Wisconsin"
    },
    {
      "city": "Park Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Park Falls, Wisconsin"
    },
    {
      "city": "Phillips",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Phillips, Wisconsin"
    },
    {
      "city": "Prentice",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Prentice, Wisconsin"
    },
    {
      "city": "Burlington",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Burlington, Wisconsin"
    },
    {
      "city": "Caledonia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Caledonia, Wisconsin"
    },
    {
      "city": "Franksville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Franksville, Wisconsin"
    },
    {
      "city": "Kansasville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kansasville, Wisconsin"
    },
    {
      "city": "Rochester",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rochester, Wisconsin"
    },
    {
      "city": "Sturtevant",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sturtevant, Wisconsin"
    },
    {
      "city": "Union Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Union Grove, Wisconsin"
    },
    {
      "city": "Waterford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waterford, Wisconsin"
    },
    {
      "city": "Racine",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Racine, Wisconsin"
    },
    {
      "city": "Gotham",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gotham, Wisconsin"
    },
    {
      "city": "Lone Rock",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lone Rock, Wisconsin"
    },
    {
      "city": "Richland Center",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Richland Center, Wisconsin"
    },
    {
      "city": "Sextonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sextonville, Wisconsin"
    },
    {
      "city": "Cazenovia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cazenovia, Wisconsin"
    },
    {
      "city": "Afton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Afton, Wisconsin"
    },
    {
      "city": "Avalon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Avalon, Wisconsin"
    },
    {
      "city": "Beloit",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Beloit, Wisconsin"
    },
    {
      "city": "Clinton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clinton, Wisconsin"
    },
    {
      "city": "Edgerton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Edgerton, Wisconsin"
    },
    {
      "city": "Evansville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Evansville, Wisconsin"
    },
    {
      "city": "Footville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Footville, Wisconsin"
    },
    {
      "city": "Hanover",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hanover, Wisconsin"
    },
    {
      "city": "Janesville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Janesville, Wisconsin"
    },
    {
      "city": "Milton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Milton, Wisconsin"
    },
    {
      "city": "Orfordville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Orfordville, Wisconsin"
    },
    {
      "city": "Glen Flora",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Glen Flora, Wisconsin"
    },
    {
      "city": "Hawkins",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hawkins, Wisconsin"
    },
    {
      "city": "Tony",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tony, Wisconsin"
    },
    {
      "city": "Conrath",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Conrath, Wisconsin"
    },
    {
      "city": "Sheldon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sheldon, Wisconsin"
    },
    {
      "city": "Bruce",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bruce, Wisconsin"
    },
    {
      "city": "Ladysmith",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ladysmith, Wisconsin"
    },
    {
      "city": "Weyerhaeuser",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Weyerhaeuser, Wisconsin"
    },
    {
      "city": "Baldwin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Baldwin, Wisconsin"
    },
    {
      "city": "Deer Park",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Deer Park, Wisconsin"
    },
    {
      "city": "Glenwood City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Glenwood City, Wisconsin"
    },
    {
      "city": "Hammond",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hammond, Wisconsin"
    },
    {
      "city": "Hudson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hudson, Wisconsin"
    },
    {
      "city": "New Richmond",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Richmond, Wisconsin"
    },
    {
      "city": "Roberts",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Roberts, Wisconsin"
    },
    {
      "city": "Somerset",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Somerset, Wisconsin"
    },
    {
      "city": "Wilson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wilson, Wisconsin"
    },
    {
      "city": "Woodville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Woodville, Wisconsin"
    },
    {
      "city": "Houlton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Houlton, Wisconsin"
    },
    {
      "city": "Merrimac",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Merrimac, Wisconsin"
    },
    {
      "city": "Plain",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Plain, Wisconsin"
    },
    {
      "city": "Prairie Du Sac",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Prairie Du Sac, Wisconsin"
    },
    {
      "city": "Sauk City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sauk City, Wisconsin"
    },
    {
      "city": "Spring Green",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Spring Green, Wisconsin"
    },
    {
      "city": "Baraboo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Baraboo, Wisconsin"
    },
    {
      "city": "Hillpoint",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hillpoint, Wisconsin"
    },
    {
      "city": "Lake Delton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lake Delton, Wisconsin"
    },
    {
      "city": "La Valle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "La Valle, Wisconsin"
    },
    {
      "city": "Lime Ridge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lime Ridge, Wisconsin"
    },
    {
      "city": "Loganville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Loganville, Wisconsin"
    },
    {
      "city": "North Freedom",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "North Freedom, Wisconsin"
    },
    {
      "city": "Reedsburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Reedsburg, Wisconsin"
    },
    {
      "city": "Rock Springs",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rock Springs, Wisconsin"
    },
    {
      "city": "Couderay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Couderay, Wisconsin"
    },
    {
      "city": "Edgewater",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Edgewater, Wisconsin"
    },
    {
      "city": "Exeland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Exeland, Wisconsin"
    },
    {
      "city": "Hayward",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hayward, Wisconsin"
    },
    {
      "city": "Ojibwa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ojibwa, Wisconsin"
    },
    {
      "city": "Radisson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Radisson, Wisconsin"
    },
    {
      "city": "Stone Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stone Lake, Wisconsin"
    },
    {
      "city": "Winter",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Winter, Wisconsin"
    },
    {
      "city": "Bonduel",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bonduel, Wisconsin"
    },
    {
      "city": "Cecil",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cecil, Wisconsin"
    },
    {
      "city": "Green Valley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Green Valley, Wisconsin"
    },
    {
      "city": "Gresham",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gresham, Wisconsin"
    },
    {
      "city": "Krakow",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Krakow, Wisconsin"
    },
    {
      "city": "Shawano",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Shawano, Wisconsin"
    },
    {
      "city": "Zachow",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Zachow, Wisconsin"
    },
    {
      "city": "Birnamwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Birnamwood, Wisconsin"
    },
    {
      "city": "Bowler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Bowler, Wisconsin"
    },
    {
      "city": "Mattoon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mattoon, Wisconsin"
    },
    {
      "city": "Tigerton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tigerton, Wisconsin"
    },
    {
      "city": "Wittenberg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wittenberg, Wisconsin"
    },
    {
      "city": "Caroline",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Caroline, Wisconsin"
    },
    {
      "city": "Leopolis",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Leopolis, Wisconsin"
    },
    {
      "city": "Tilleda",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Tilleda, Wisconsin"
    },
    {
      "city": "Adell",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Adell, Wisconsin"
    },
    {
      "city": "Cascade",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cascade, Wisconsin"
    },
    {
      "city": "Cedar Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Cedar Grove, Wisconsin"
    },
    {
      "city": "Elkhart Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elkhart Lake, Wisconsin"
    },
    {
      "city": "Glenbeulah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Glenbeulah, Wisconsin"
    },
    {
      "city": "Greenbush",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Greenbush, Wisconsin"
    },
    {
      "city": "Hingham",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hingham, Wisconsin"
    },
    {
      "city": "Kohler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kohler, Wisconsin"
    },
    {
      "city": "Oostburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oostburg, Wisconsin"
    },
    {
      "city": "Plymouth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Plymouth, Wisconsin"
    },
    {
      "city": "Random Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Random Lake, Wisconsin"
    },
    {
      "city": "Sheboygan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sheboygan, Wisconsin"
    },
    {
      "city": "Sheboygan Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sheboygan Falls, Wisconsin"
    },
    {
      "city": "Waldo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waldo, Wisconsin"
    },
    {
      "city": "Gilman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Gilman, Wisconsin"
    },
    {
      "city": "Jump River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Jump River, Wisconsin"
    },
    {
      "city": "Hannibal",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hannibal, Wisconsin"
    },
    {
      "city": "Lublin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lublin, Wisconsin"
    },
    {
      "city": "Medford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Medford, Wisconsin"
    },
    {
      "city": "Rib Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rib Lake, Wisconsin"
    },
    {
      "city": "Stetsonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stetsonville, Wisconsin"
    },
    {
      "city": "Westboro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Westboro, Wisconsin"
    },
    {
      "city": "Arcadia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arcadia, Wisconsin"
    },
    {
      "city": "Blair",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Blair, Wisconsin"
    },
    {
      "city": "Dodge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dodge, Wisconsin"
    },
    {
      "city": "Ettrick",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ettrick, Wisconsin"
    },
    {
      "city": "Galesville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Galesville, Wisconsin"
    },
    {
      "city": "Trempealeau",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Trempealeau, Wisconsin"
    },
    {
      "city": "Eleva",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eleva, Wisconsin"
    },
    {
      "city": "Independence",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Independence, Wisconsin"
    },
    {
      "city": "Osseo",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Osseo, Wisconsin"
    },
    {
      "city": "Pigeon Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pigeon Falls, Wisconsin"
    },
    {
      "city": "Strum",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Strum, Wisconsin"
    },
    {
      "city": "Whitehall",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Whitehall, Wisconsin"
    },
    {
      "city": "Chaseburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Chaseburg, Wisconsin"
    },
    {
      "city": "Coon Valley",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Coon Valley, Wisconsin"
    },
    {
      "city": "De Soto",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "De Soto, Wisconsin"
    },
    {
      "city": "Genoa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Genoa, Wisconsin"
    },
    {
      "city": "Hillsboro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hillsboro, Wisconsin"
    },
    {
      "city": "La Farge",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "La Farge, Wisconsin"
    },
    {
      "city": "Ontario",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ontario, Wisconsin"
    },
    {
      "city": "Readstown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Readstown, Wisconsin"
    },
    {
      "city": "Stoddard",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Stoddard, Wisconsin"
    },
    {
      "city": "Viola",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Viola, Wisconsin"
    },
    {
      "city": "Viroqua",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Viroqua, Wisconsin"
    },
    {
      "city": "Westby",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Westby, Wisconsin"
    },
    {
      "city": "Boulder Junction",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Boulder Junction, Wisconsin"
    },
    {
      "city": "Conover",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Conover, Wisconsin"
    },
    {
      "city": "Eagle River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eagle River, Wisconsin"
    },
    {
      "city": "Lac Du Flambeau",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lac Du Flambeau, Wisconsin"
    },
    {
      "city": "Land O Lakes",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Land O Lakes, Wisconsin"
    },
    {
      "city": "Manitowish Waters",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Manitowish Waters, Wisconsin"
    },
    {
      "city": "Phelps",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Phelps, Wisconsin"
    },
    {
      "city": "Presque Isle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Presque Isle, Wisconsin"
    },
    {
      "city": "Saint Germain",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saint Germain, Wisconsin"
    },
    {
      "city": "Sayner",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sayner, Wisconsin"
    },
    {
      "city": "Star Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Star Lake, Wisconsin"
    },
    {
      "city": "Darien",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Darien, Wisconsin"
    },
    {
      "city": "Delavan",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Delavan, Wisconsin"
    },
    {
      "city": "East Troy",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "East Troy, Wisconsin"
    },
    {
      "city": "Elkhorn",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elkhorn, Wisconsin"
    },
    {
      "city": "Fontana",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fontana, Wisconsin"
    },
    {
      "city": "Genoa City",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Genoa City, Wisconsin"
    },
    {
      "city": "Honey Creek",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Honey Creek, Wisconsin"
    },
    {
      "city": "Lake Geneva",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lake Geneva, Wisconsin"
    },
    {
      "city": "Lyons",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lyons, Wisconsin"
    },
    {
      "city": "Pell Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pell Lake, Wisconsin"
    },
    {
      "city": "Springfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Springfield, Wisconsin"
    },
    {
      "city": "Walworth",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Walworth, Wisconsin"
    },
    {
      "city": "Whitewater",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Whitewater, Wisconsin"
    },
    {
      "city": "Williams Bay",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Williams Bay, Wisconsin"
    },
    {
      "city": "Zenda",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Zenda, Wisconsin"
    },
    {
      "city": "Sharon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sharon, Wisconsin"
    },
    {
      "city": "Spooner",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Spooner, Wisconsin"
    },
    {
      "city": "Birchwood",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Birchwood, Wisconsin"
    },
    {
      "city": "Minong",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Minong, Wisconsin"
    },
    {
      "city": "Sarona",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sarona, Wisconsin"
    },
    {
      "city": "Shell Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Shell Lake, Wisconsin"
    },
    {
      "city": "Springbrook",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Springbrook, Wisconsin"
    },
    {
      "city": "Trego",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Trego, Wisconsin"
    },
    {
      "city": "Allenton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Allenton, Wisconsin"
    },
    {
      "city": "Colgate",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Colgate, Wisconsin"
    },
    {
      "city": "Germantown",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Germantown, Wisconsin"
    },
    {
      "city": "Hartford",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hartford, Wisconsin"
    },
    {
      "city": "Hubertus",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hubertus, Wisconsin"
    },
    {
      "city": "Jackson",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Jackson, Wisconsin"
    },
    {
      "city": "Kewaskum",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Kewaskum, Wisconsin"
    },
    {
      "city": "Newburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Newburg, Wisconsin"
    },
    {
      "city": "Richfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Richfield, Wisconsin"
    },
    {
      "city": "Slinger",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Slinger, Wisconsin"
    },
    {
      "city": "West Bend",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "West Bend, Wisconsin"
    },
    {
      "city": "Brookfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Brookfield, Wisconsin"
    },
    {
      "city": "Butler",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Butler, Wisconsin"
    },
    {
      "city": "Delafield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Delafield, Wisconsin"
    },
    {
      "city": "Hartland",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hartland, Wisconsin"
    },
    {
      "city": "Lannon",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Lannon, Wisconsin"
    },
    {
      "city": "Menomonee Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Menomonee Falls, Wisconsin"
    },
    {
      "city": "Merton",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Merton, Wisconsin"
    },
    {
      "city": "Nashotah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Nashotah, Wisconsin"
    },
    {
      "city": "North Lake",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "North Lake, Wisconsin"
    },
    {
      "city": "Oconomowoc",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oconomowoc, Wisconsin"
    },
    {
      "city": "Okauchee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Okauchee, Wisconsin"
    },
    {
      "city": "Pewaukee",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pewaukee, Wisconsin"
    },
    {
      "city": "Sussex",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Sussex, Wisconsin"
    },
    {
      "city": "Big Bend",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Big Bend, Wisconsin"
    },
    {
      "city": "Dousman",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Dousman, Wisconsin"
    },
    {
      "city": "Eagle",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eagle, Wisconsin"
    },
    {
      "city": "Elm Grove",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Elm Grove, Wisconsin"
    },
    {
      "city": "Genesee Depot",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Genesee Depot, Wisconsin"
    },
    {
      "city": "New Berlin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New Berlin, Wisconsin"
    },
    {
      "city": "Mukwonago",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Mukwonago, Wisconsin"
    },
    {
      "city": "Muskego",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Muskego, Wisconsin"
    },
    {
      "city": "North Prairie",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "North Prairie, Wisconsin"
    },
    {
      "city": "Wales",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wales, Wisconsin"
    },
    {
      "city": "Waukesha",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waukesha, Wisconsin"
    },
    {
      "city": "Big Falls",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Big Falls, Wisconsin"
    },
    {
      "city": "Clintonville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Clintonville, Wisconsin"
    },
    {
      "city": "Embarrass",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Embarrass, Wisconsin"
    },
    {
      "city": "Fremont",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Fremont, Wisconsin"
    },
    {
      "city": "Iola",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Iola, Wisconsin"
    },
    {
      "city": "King",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "King, Wisconsin"
    },
    {
      "city": "Manawa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Manawa, Wisconsin"
    },
    {
      "city": "Marion",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marion, Wisconsin"
    },
    {
      "city": "New London",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "New London, Wisconsin"
    },
    {
      "city": "Ogdensburg",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Ogdensburg, Wisconsin"
    },
    {
      "city": "Readfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Readfield, Wisconsin"
    },
    {
      "city": "Scandinavia",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Scandinavia, Wisconsin"
    },
    {
      "city": "Waupaca",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waupaca, Wisconsin"
    },
    {
      "city": "Weyauwega",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Weyauwega, Wisconsin"
    },
    {
      "city": "Coloma",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Coloma, Wisconsin"
    },
    {
      "city": "Hancock",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hancock, Wisconsin"
    },
    {
      "city": "Pine River",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pine River, Wisconsin"
    },
    {
      "city": "Plainfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Plainfield, Wisconsin"
    },
    {
      "city": "Poy Sippi",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Poy Sippi, Wisconsin"
    },
    {
      "city": "Redgranite",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Redgranite, Wisconsin"
    },
    {
      "city": "Saxeville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Saxeville, Wisconsin"
    },
    {
      "city": "Wautoma",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wautoma, Wisconsin"
    },
    {
      "city": "Wild Rose",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wild Rose, Wisconsin"
    },
    {
      "city": "Oshkosh",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Oshkosh, Wisconsin"
    },
    {
      "city": "Butte Des Morts",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Butte Des Morts, Wisconsin"
    },
    {
      "city": "Eureka",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Eureka, Wisconsin"
    },
    {
      "city": "Larsen",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Larsen, Wisconsin"
    },
    {
      "city": "Menasha",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Menasha, Wisconsin"
    },
    {
      "city": "Neenah",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Neenah, Wisconsin"
    },
    {
      "city": "Omro",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Omro, Wisconsin"
    },
    {
      "city": "Pickett",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pickett, Wisconsin"
    },
    {
      "city": "Waukau",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Waukau, Wisconsin"
    },
    {
      "city": "Winnebago",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Winnebago, Wisconsin"
    },
    {
      "city": "Winneconne",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Winneconne, Wisconsin"
    },
    {
      "city": "Marshfield",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Marshfield, Wisconsin"
    },
    {
      "city": "Arpin",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Arpin, Wisconsin"
    },
    {
      "city": "Auburndale",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Auburndale, Wisconsin"
    },
    {
      "city": "Babcock",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Babcock, Wisconsin"
    },
    {
      "city": "Blenker",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Blenker, Wisconsin"
    },
    {
      "city": "Hewitt",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Hewitt, Wisconsin"
    },
    {
      "city": "Milladore",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Milladore, Wisconsin"
    },
    {
      "city": "Nekoosa",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Nekoosa, Wisconsin"
    },
    {
      "city": "Pittsville",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Pittsville, Wisconsin"
    },
    {
      "city": "Port Edwards",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Port Edwards, Wisconsin"
    },
    {
      "city": "Rudolph",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Rudolph, Wisconsin"
    },
    {
      "city": "Vesper",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Vesper, Wisconsin"
    },
    {
      "city": "Wisconsin Rapids",
      "state": "Wisconsin",
      "shortState": "WI",
      "formatedCity": "Wisconsin Rapids, Wisconsin"
    },
    {
      "city": "Volga",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Volga, West Virginia"
    },
    {
      "city": "Belington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Belington, West Virginia"
    },
    {
      "city": "Junior",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Junior, West Virginia"
    },
    {
      "city": "Galloway",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Galloway, West Virginia"
    },
    {
      "city": "Moatsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Moatsville, West Virginia"
    },
    {
      "city": "Philippi",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Philippi, West Virginia"
    },
    {
      "city": "Martinsburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Martinsburg, West Virginia"
    },
    {
      "city": "Bunker Hill",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bunker Hill, West Virginia"
    },
    {
      "city": "Falling Waters",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Falling Waters, West Virginia"
    },
    {
      "city": "Gerrardstown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gerrardstown, West Virginia"
    },
    {
      "city": "Glengary",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glengary, West Virginia"
    },
    {
      "city": "Hedgesville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hedgesville, West Virginia"
    },
    {
      "city": "Inwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Inwood, West Virginia"
    },
    {
      "city": "Ridgeway",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ridgeway, West Virginia"
    },
    {
      "city": "Ashford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ashford, West Virginia"
    },
    {
      "city": "Bim",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bim, West Virginia"
    },
    {
      "city": "Bloomingrose",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bloomingrose, West Virginia"
    },
    {
      "city": "Bob White",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bob White, West Virginia"
    },
    {
      "city": "Comfort",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Comfort, West Virginia"
    },
    {
      "city": "Costa",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Costa, West Virginia"
    },
    {
      "city": "Danville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Danville, West Virginia"
    },
    {
      "city": "Foster",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Foster, West Virginia"
    },
    {
      "city": "Gordon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gordon, West Virginia"
    },
    {
      "city": "Hewett",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hewett, West Virginia"
    },
    {
      "city": "Jeffrey",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jeffrey, West Virginia"
    },
    {
      "city": "Madison",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Madison, West Virginia"
    },
    {
      "city": "Nellis",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nellis, West Virginia"
    },
    {
      "city": "Orgas",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Orgas, West Virginia"
    },
    {
      "city": "Ottawa",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ottawa, West Virginia"
    },
    {
      "city": "Peytona",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Peytona, West Virginia"
    },
    {
      "city": "Racine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Racine, West Virginia"
    },
    {
      "city": "Ridgeview",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ridgeview, West Virginia"
    },
    {
      "city": "Seth",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Seth, West Virginia"
    },
    {
      "city": "Sylvester",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sylvester, West Virginia"
    },
    {
      "city": "Turtle Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Turtle Creek, West Virginia"
    },
    {
      "city": "Twilight",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Twilight, West Virginia"
    },
    {
      "city": "Uneeda",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Uneeda, West Virginia"
    },
    {
      "city": "Van",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Van, West Virginia"
    },
    {
      "city": "Wharton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wharton, West Virginia"
    },
    {
      "city": "Whitesville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Whitesville, West Virginia"
    },
    {
      "city": "Julian",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Julian, West Virginia"
    },
    {
      "city": "Burnsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Burnsville, West Virginia"
    },
    {
      "city": "Sutton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sutton, West Virginia"
    },
    {
      "city": "Copen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Copen, West Virginia"
    },
    {
      "city": "Exchange",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Exchange, West Virginia"
    },
    {
      "city": "Flatwoods",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Flatwoods, West Virginia"
    },
    {
      "city": "Frametown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Frametown, West Virginia"
    },
    {
      "city": "Gassaway",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gassaway, West Virginia"
    },
    {
      "city": "Heaters",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Heaters, West Virginia"
    },
    {
      "city": "Little Birch",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Little Birch, West Virginia"
    },
    {
      "city": "Napier",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Napier, West Virginia"
    },
    {
      "city": "Beech Bottom",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Beech Bottom, West Virginia"
    },
    {
      "city": "Bethany",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bethany, West Virginia"
    },
    {
      "city": "Colliers",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Colliers, West Virginia"
    },
    {
      "city": "Follansbee",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Follansbee, West Virginia"
    },
    {
      "city": "Short Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Short Creek, West Virginia"
    },
    {
      "city": "Wellsburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wellsburg, West Virginia"
    },
    {
      "city": "Windsor Heights",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Windsor Heights, West Virginia"
    },
    {
      "city": "Barboursville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Barboursville, West Virginia"
    },
    {
      "city": "Culloden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Culloden, West Virginia"
    },
    {
      "city": "Lesage",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lesage, West Virginia"
    },
    {
      "city": "Milton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Milton, West Virginia"
    },
    {
      "city": "Ona",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ona, West Virginia"
    },
    {
      "city": "Salt Rock",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Salt Rock, West Virginia"
    },
    {
      "city": "Huntington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Huntington, West Virginia"
    },
    {
      "city": "Arnoldsburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Arnoldsburg, West Virginia"
    },
    {
      "city": "Chloe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Chloe, West Virginia"
    },
    {
      "city": "Millstone",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Millstone, West Virginia"
    },
    {
      "city": "Orma",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Orma, West Virginia"
    },
    {
      "city": "Big Bend",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Big Bend, West Virginia"
    },
    {
      "city": "Big Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Big Springs, West Virginia"
    },
    {
      "city": "Grantsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Grantsville, West Virginia"
    },
    {
      "city": "Mount Zion",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Zion, West Virginia"
    },
    {
      "city": "Bickmore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bickmore, West Virginia"
    },
    {
      "city": "Bomont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bomont, West Virginia"
    },
    {
      "city": "Clay",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clay, West Virginia"
    },
    {
      "city": "Duck",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Duck, West Virginia"
    },
    {
      "city": "Glen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen, West Virginia"
    },
    {
      "city": "Indore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Indore, West Virginia"
    },
    {
      "city": "Ivydale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ivydale, West Virginia"
    },
    {
      "city": "Lizemores",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lizemores, West Virginia"
    },
    {
      "city": "Maysel",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maysel, West Virginia"
    },
    {
      "city": "Nebo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nebo, West Virginia"
    },
    {
      "city": "Procious",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Procious, West Virginia"
    },
    {
      "city": "Widen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Widen, West Virginia"
    },
    {
      "city": "Wallback",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wallback, West Virginia"
    },
    {
      "city": "Dille",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dille, West Virginia"
    },
    {
      "city": "Center Point",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Center Point, West Virginia"
    },
    {
      "city": "New Milton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Milton, West Virginia"
    },
    {
      "city": "Smithburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Smithburg, West Virginia"
    },
    {
      "city": "West Union",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "West Union, West Virginia"
    },
    {
      "city": "Alloy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alloy, West Virginia"
    },
    {
      "city": "Boomer",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Boomer, West Virginia"
    },
    {
      "city": "Cannelton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cannelton, West Virginia"
    },
    {
      "city": "Charlton Heights",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Charlton Heights, West Virginia"
    },
    {
      "city": "Deep Water",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Deep Water, West Virginia"
    },
    {
      "city": "Gauley Bridge",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gauley Bridge, West Virginia"
    },
    {
      "city": "Glen Ferris",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Ferris, West Virginia"
    },
    {
      "city": "Kanawha Falls",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kanawha Falls, West Virginia"
    },
    {
      "city": "Kimberly",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kimberly, West Virginia"
    },
    {
      "city": "Kincaid",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kincaid, West Virginia"
    },
    {
      "city": "Montgomery",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Montgomery, West Virginia"
    },
    {
      "city": "Mount Carbon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Carbon, West Virginia"
    },
    {
      "city": "Page",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Page, West Virginia"
    },
    {
      "city": "Powellton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Powellton, West Virginia"
    },
    {
      "city": "Robson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Robson, West Virginia"
    },
    {
      "city": "Mount Olive",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Olive, West Virginia"
    },
    {
      "city": "Smithers",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Smithers, West Virginia"
    },
    {
      "city": "Ansted",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ansted, West Virginia"
    },
    {
      "city": "Danese",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Danese, West Virginia"
    },
    {
      "city": "Dothan",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dothan, West Virginia"
    },
    {
      "city": "Edmond",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Edmond, West Virginia"
    },
    {
      "city": "Fayetteville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fayetteville, West Virginia"
    },
    {
      "city": "Glen Jean",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Jean, West Virginia"
    },
    {
      "city": "Hico",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hico, West Virginia"
    },
    {
      "city": "Hilltop",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hilltop, West Virginia"
    },
    {
      "city": "Lansing",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lansing, West Virginia"
    },
    {
      "city": "Layland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Layland, West Virginia"
    },
    {
      "city": "Lochgelly",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lochgelly, West Virginia"
    },
    {
      "city": "Lookout",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lookout, West Virginia"
    },
    {
      "city": "Minden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Minden, West Virginia"
    },
    {
      "city": "Mount Hope",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Hope, West Virginia"
    },
    {
      "city": "Oak Hill",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Oak Hill, West Virginia"
    },
    {
      "city": "Pax",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pax, West Virginia"
    },
    {
      "city": "Prince",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Prince, West Virginia"
    },
    {
      "city": "Scarbro",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Scarbro, West Virginia"
    },
    {
      "city": "Thurmond",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Thurmond, West Virginia"
    },
    {
      "city": "Victor",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Victor, West Virginia"
    },
    {
      "city": "Winona",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Winona, West Virginia"
    },
    {
      "city": "Meadow Bridge",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Meadow Bridge, West Virginia"
    },
    {
      "city": "Spring Dale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Spring Dale, West Virginia"
    },
    {
      "city": "Nallen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nallen, West Virginia"
    },
    {
      "city": "Normantown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Normantown, West Virginia"
    },
    {
      "city": "Coxs Mills",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Coxs Mills, West Virginia"
    },
    {
      "city": "Glenville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glenville, West Virginia"
    },
    {
      "city": "Linn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Linn, West Virginia"
    },
    {
      "city": "Sand Fork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sand Fork, West Virginia"
    },
    {
      "city": "Troy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Troy, West Virginia"
    },
    {
      "city": "Cedarville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cedarville, West Virginia"
    },
    {
      "city": "Rosedale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rosedale, West Virginia"
    },
    {
      "city": "Shock",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shock, West Virginia"
    },
    {
      "city": "Bayard",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bayard, West Virginia"
    },
    {
      "city": "Gormania",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gormania, West Virginia"
    },
    {
      "city": "Lahmansville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lahmansville, West Virginia"
    },
    {
      "city": "Mount Storm",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Storm, West Virginia"
    },
    {
      "city": "Maysville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maysville, West Virginia"
    },
    {
      "city": "Petersburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Petersburg, West Virginia"
    },
    {
      "city": "Cabins",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cabins, West Virginia"
    },
    {
      "city": "Lewisburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lewisburg, West Virginia"
    },
    {
      "city": "Fairlea",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fairlea, West Virginia"
    },
    {
      "city": "Alderson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alderson, West Virginia"
    },
    {
      "city": "Asbury",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Asbury, West Virginia"
    },
    {
      "city": "Caldwell",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Caldwell, West Virginia"
    },
    {
      "city": "Crawley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Crawley, West Virginia"
    },
    {
      "city": "Frankford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Frankford, West Virginia"
    },
    {
      "city": "Grassy Meadows",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Grassy Meadows, West Virginia"
    },
    {
      "city": "Maxwelton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maxwelton, West Virginia"
    },
    {
      "city": "Renick",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Renick, West Virginia"
    },
    {
      "city": "Ronceverte",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ronceverte, West Virginia"
    },
    {
      "city": "Smoot",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Smoot, West Virginia"
    },
    {
      "city": "White Sulphur Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "White Sulphur Springs, West Virginia"
    },
    {
      "city": "Williamsburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Williamsburg, West Virginia"
    },
    {
      "city": "Charmco",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Charmco, West Virginia"
    },
    {
      "city": "Rainelle",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rainelle, West Virginia"
    },
    {
      "city": "Leslie",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Leslie, West Virginia"
    },
    {
      "city": "Quinwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Quinwood, West Virginia"
    },
    {
      "city": "Rupert",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rupert, West Virginia"
    },
    {
      "city": "Levels",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Levels, West Virginia"
    },
    {
      "city": "Points",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Points, West Virginia"
    },
    {
      "city": "Slanesville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Slanesville, West Virginia"
    },
    {
      "city": "Augusta",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Augusta, West Virginia"
    },
    {
      "city": "Capon Bridge",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Capon Bridge, West Virginia"
    },
    {
      "city": "Delray",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Delray, West Virginia"
    },
    {
      "city": "Green Spring",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Green Spring, West Virginia"
    },
    {
      "city": "Rio",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rio, West Virginia"
    },
    {
      "city": "Romney",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Romney, West Virginia"
    },
    {
      "city": "Shanks",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shanks, West Virginia"
    },
    {
      "city": "Springfield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Springfield, West Virginia"
    },
    {
      "city": "High View",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "High View, West Virginia"
    },
    {
      "city": "Bloomery",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bloomery, West Virginia"
    },
    {
      "city": "Capon Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Capon Springs, West Virginia"
    },
    {
      "city": "Purgitsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Purgitsville, West Virginia"
    },
    {
      "city": "Yellow Spring",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Yellow Spring, West Virginia"
    },
    {
      "city": "Chester",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Chester, West Virginia"
    },
    {
      "city": "New Cumberland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Cumberland, West Virginia"
    },
    {
      "city": "Newell",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Newell, West Virginia"
    },
    {
      "city": "New Manchester",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Manchester, West Virginia"
    },
    {
      "city": "Weirton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Weirton, West Virginia"
    },
    {
      "city": "Baker",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Baker, West Virginia"
    },
    {
      "city": "Lost City",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lost City, West Virginia"
    },
    {
      "city": "Mathias",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mathias, West Virginia"
    },
    {
      "city": "Fisher",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fisher, West Virginia"
    },
    {
      "city": "Moorefield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Moorefield, West Virginia"
    },
    {
      "city": "Milam",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Milam, West Virginia"
    },
    {
      "city": "Old Fields",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Old Fields, West Virginia"
    },
    {
      "city": "Wardensville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wardensville, West Virginia"
    },
    {
      "city": "Clarksburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clarksburg, West Virginia"
    },
    {
      "city": "Anmoore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Anmoore, West Virginia"
    },
    {
      "city": "Bridgeport",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bridgeport, West Virginia"
    },
    {
      "city": "Gypsy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gypsy, West Virginia"
    },
    {
      "city": "Haywood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Haywood, West Virginia"
    },
    {
      "city": "Hepzibah",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hepzibah, West Virginia"
    },
    {
      "city": "Lost Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lost Creek, West Virginia"
    },
    {
      "city": "Lumberport",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lumberport, West Virginia"
    },
    {
      "city": "Meadowbrook",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Meadowbrook, West Virginia"
    },
    {
      "city": "Mount Clare",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Clare, West Virginia"
    },
    {
      "city": "Reynoldsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Reynoldsville, West Virginia"
    },
    {
      "city": "Salem",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Salem, West Virginia"
    },
    {
      "city": "Shinnston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shinnston, West Virginia"
    },
    {
      "city": "Spelter",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Spelter, West Virginia"
    },
    {
      "city": "Wallace",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wallace, West Virginia"
    },
    {
      "city": "West Milford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "West Milford, West Virginia"
    },
    {
      "city": "Wyatt",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wyatt, West Virginia"
    },
    {
      "city": "Enterprise",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Enterprise, West Virginia"
    },
    {
      "city": "Advent",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Advent, West Virginia"
    },
    {
      "city": "Cottageville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cottageville, West Virginia"
    },
    {
      "city": "Evans",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Evans, West Virginia"
    },
    {
      "city": "Gay",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gay, West Virginia"
    },
    {
      "city": "Given",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Given, West Virginia"
    },
    {
      "city": "Kenna",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kenna, West Virginia"
    },
    {
      "city": "Le Roy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Le Roy, West Virginia"
    },
    {
      "city": "Millwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Millwood, West Virginia"
    },
    {
      "city": "Mount Alto",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Alto, West Virginia"
    },
    {
      "city": "Ripley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ripley, West Virginia"
    },
    {
      "city": "Sandyville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sandyville, West Virginia"
    },
    {
      "city": "Ravenswood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ravenswood, West Virginia"
    },
    {
      "city": "Bakerton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bakerton, West Virginia"
    },
    {
      "city": "Charles Town",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Charles Town, West Virginia"
    },
    {
      "city": "Halltown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Halltown, West Virginia"
    },
    {
      "city": "Harpers Ferry",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Harpers Ferry, West Virginia"
    },
    {
      "city": "Kearneysville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kearneysville, West Virginia"
    },
    {
      "city": "Millville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Millville, West Virginia"
    },
    {
      "city": "Ranson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ranson, West Virginia"
    },
    {
      "city": "Rippon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rippon, West Virginia"
    },
    {
      "city": "Shenandoah Junction",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shenandoah Junction, West Virginia"
    },
    {
      "city": "Shepherdstown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shepherdstown, West Virginia"
    },
    {
      "city": "Summit Point",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Summit Point, West Virginia"
    },
    {
      "city": "Alum Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alum Creek, West Virginia"
    },
    {
      "city": "Belle",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Belle, West Virginia"
    },
    {
      "city": "Blount",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Blount, West Virginia"
    },
    {
      "city": "Blue Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Blue Creek, West Virginia"
    },
    {
      "city": "Cabin Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cabin Creek, West Virginia"
    },
    {
      "city": "Cedar Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cedar Grove, West Virginia"
    },
    {
      "city": "Clendenin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clendenin, West Virginia"
    },
    {
      "city": "Dawes",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dawes, West Virginia"
    },
    {
      "city": "Drybranch",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Drybranch, West Virginia"
    },
    {
      "city": "Dunbar",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dunbar, West Virginia"
    },
    {
      "city": "East Bank",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "East Bank, West Virginia"
    },
    {
      "city": "Elkview",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elkview, West Virginia"
    },
    {
      "city": "Eskdale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Eskdale, West Virginia"
    },
    {
      "city": "Falling Rock",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Falling Rock, West Virginia"
    },
    {
      "city": "Gallagher",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gallagher, West Virginia"
    },
    {
      "city": "Glasgow",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glasgow, West Virginia"
    },
    {
      "city": "Handley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Handley, West Virginia"
    },
    {
      "city": "Hansford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hansford, West Virginia"
    },
    {
      "city": "Hernshaw",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hernshaw, West Virginia"
    },
    {
      "city": "Hugheston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hugheston, West Virginia"
    },
    {
      "city": "Institute",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Institute, West Virginia"
    },
    {
      "city": "London",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "London, West Virginia"
    },
    {
      "city": "Mammoth",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mammoth, West Virginia"
    },
    {
      "city": "Miami",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Miami, West Virginia"
    },
    {
      "city": "Nitro",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nitro, West Virginia"
    },
    {
      "city": "Pinch",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pinch, West Virginia"
    },
    {
      "city": "Pond Gap",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pond Gap, West Virginia"
    },
    {
      "city": "Pratt",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pratt, West Virginia"
    },
    {
      "city": "Saint Albans",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Saint Albans, West Virginia"
    },
    {
      "city": "Tad",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Tad, West Virginia"
    },
    {
      "city": "Tornado",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Tornado, West Virginia"
    },
    {
      "city": "Winifrede",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Winifrede, West Virginia"
    },
    {
      "city": "Charleston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Charleston, West Virginia"
    },
    {
      "city": "South Charleston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "South Charleston, West Virginia"
    },
    {
      "city": "Alum Bridge",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alum Bridge, West Virginia"
    },
    {
      "city": "Camden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Camden, West Virginia"
    },
    {
      "city": "Crawford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Crawford, West Virginia"
    },
    {
      "city": "Horner",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Horner, West Virginia"
    },
    {
      "city": "Ireland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ireland, West Virginia"
    },
    {
      "city": "Jane Lew",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jane Lew, West Virginia"
    },
    {
      "city": "Orlando",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Orlando, West Virginia"
    },
    {
      "city": "Walkersville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Walkersville, West Virginia"
    },
    {
      "city": "Weston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Weston, West Virginia"
    },
    {
      "city": "Alkol",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alkol, West Virginia"
    },
    {
      "city": "Branchland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Branchland, West Virginia"
    },
    {
      "city": "Griffithsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Griffithsville, West Virginia"
    },
    {
      "city": "Hamlin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hamlin, West Virginia"
    },
    {
      "city": "Harts",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Harts, West Virginia"
    },
    {
      "city": "Midkiff",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Midkiff, West Virginia"
    },
    {
      "city": "Myra",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Myra, West Virginia"
    },
    {
      "city": "Ranger",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ranger, West Virginia"
    },
    {
      "city": "Sod",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sod, West Virginia"
    },
    {
      "city": "Spurlockville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Spurlockville, West Virginia"
    },
    {
      "city": "Sumerco",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sumerco, West Virginia"
    },
    {
      "city": "West Hamlin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "West Hamlin, West Virginia"
    },
    {
      "city": "Woodville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Woodville, West Virginia"
    },
    {
      "city": "Yawkey",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Yawkey, West Virginia"
    },
    {
      "city": "Blair",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Blair, West Virginia"
    },
    {
      "city": "Clothier",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clothier, West Virginia"
    },
    {
      "city": "Ethel",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ethel, West Virginia"
    },
    {
      "city": "Lake",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lake, West Virginia"
    },
    {
      "city": "Sharples",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sharples, West Virginia"
    },
    {
      "city": "Big Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Big Creek, West Virginia"
    },
    {
      "city": "Chapmanville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Chapmanville, West Virginia"
    },
    {
      "city": "Pecks Mill",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pecks Mill, West Virginia"
    },
    {
      "city": "Logan",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Logan, West Virginia"
    },
    {
      "city": "Accoville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Accoville, West Virginia"
    },
    {
      "city": "Amherstdale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Amherstdale, West Virginia"
    },
    {
      "city": "Bruno",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bruno, West Virginia"
    },
    {
      "city": "Chauncey",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Chauncey, West Virginia"
    },
    {
      "city": "Cora",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cora, West Virginia"
    },
    {
      "city": "Davin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Davin, West Virginia"
    },
    {
      "city": "Henlawson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Henlawson, West Virginia"
    },
    {
      "city": "Holden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Holden, West Virginia"
    },
    {
      "city": "Kistler",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kistler, West Virginia"
    },
    {
      "city": "Lorado",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lorado, West Virginia"
    },
    {
      "city": "Lyburn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lyburn, West Virginia"
    },
    {
      "city": "Mallory",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mallory, West Virginia"
    },
    {
      "city": "Man",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Man, West Virginia"
    },
    {
      "city": "Mount Gay",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Gay, West Virginia"
    },
    {
      "city": "Omar",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Omar, West Virginia"
    },
    {
      "city": "Peach Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Peach Creek, West Virginia"
    },
    {
      "city": "Sarah Ann",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sarah Ann, West Virginia"
    },
    {
      "city": "Stollings",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Stollings, West Virginia"
    },
    {
      "city": "Switzer",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Switzer, West Virginia"
    },
    {
      "city": "Verdunville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Verdunville, West Virginia"
    },
    {
      "city": "Whitman",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Whitman, West Virginia"
    },
    {
      "city": "Wilkinson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wilkinson, West Virginia"
    },
    {
      "city": "Yolyn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Yolyn, West Virginia"
    },
    {
      "city": "Welch",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Welch, West Virginia"
    },
    {
      "city": "Anawalt",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Anawalt, West Virginia"
    },
    {
      "city": "Avondale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Avondale, West Virginia"
    },
    {
      "city": "Bartley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bartley, West Virginia"
    },
    {
      "city": "Berwind",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Berwind, West Virginia"
    },
    {
      "city": "Big Sandy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Big Sandy, West Virginia"
    },
    {
      "city": "Bradshaw",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bradshaw, West Virginia"
    },
    {
      "city": "Cucumber",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cucumber, West Virginia"
    },
    {
      "city": "Davy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Davy, West Virginia"
    },
    {
      "city": "Eckman",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Eckman, West Virginia"
    },
    {
      "city": "Elbert",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elbert, West Virginia"
    },
    {
      "city": "Elkhorn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elkhorn, West Virginia"
    },
    {
      "city": "Gary",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gary, West Virginia"
    },
    {
      "city": "Hensley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hensley, West Virginia"
    },
    {
      "city": "Iaeger",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Iaeger, West Virginia"
    },
    {
      "city": "Isaban",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Isaban, West Virginia"
    },
    {
      "city": "Jenkinjones",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jenkinjones, West Virginia"
    },
    {
      "city": "Jolo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jolo, West Virginia"
    },
    {
      "city": "Kimball",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kimball, West Virginia"
    },
    {
      "city": "Kyle",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kyle, West Virginia"
    },
    {
      "city": "Maybeury",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maybeury, West Virginia"
    },
    {
      "city": "Mohawk",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mohawk, West Virginia"
    },
    {
      "city": "Newhall",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Newhall, West Virginia"
    },
    {
      "city": "Northfork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Northfork, West Virginia"
    },
    {
      "city": "Pageton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pageton, West Virginia"
    },
    {
      "city": "Panther",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Panther, West Virginia"
    },
    {
      "city": "Paynesville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Paynesville, West Virginia"
    },
    {
      "city": "Premier",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Premier, West Virginia"
    },
    {
      "city": "Raysal",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Raysal, West Virginia"
    },
    {
      "city": "Roderfield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Roderfield, West Virginia"
    },
    {
      "city": "Squire",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Squire, West Virginia"
    },
    {
      "city": "Switchback",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Switchback, West Virginia"
    },
    {
      "city": "Thorpe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Thorpe, West Virginia"
    },
    {
      "city": "War",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "War, West Virginia"
    },
    {
      "city": "Warriormine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Warriormine, West Virginia"
    },
    {
      "city": "Wilcoe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wilcoe, West Virginia"
    },
    {
      "city": "Fairmont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fairmont, West Virginia"
    },
    {
      "city": "Barrackville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Barrackville, West Virginia"
    },
    {
      "city": "Baxter",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Baxter, West Virginia"
    },
    {
      "city": "Carolina",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Carolina, West Virginia"
    },
    {
      "city": "Colfax",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Colfax, West Virginia"
    },
    {
      "city": "Fairview",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fairview, West Virginia"
    },
    {
      "city": "Farmington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Farmington, West Virginia"
    },
    {
      "city": "Four States",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Four States, West Virginia"
    },
    {
      "city": "Grant Town",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Grant Town, West Virginia"
    },
    {
      "city": "Idamay",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Idamay, West Virginia"
    },
    {
      "city": "Kingmont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kingmont, West Virginia"
    },
    {
      "city": "Mannington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mannington, West Virginia"
    },
    {
      "city": "Metz",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Metz, West Virginia"
    },
    {
      "city": "Montana Mines",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Montana Mines, West Virginia"
    },
    {
      "city": "Rachel",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rachel, West Virginia"
    },
    {
      "city": "Rivesville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rivesville, West Virginia"
    },
    {
      "city": "Worthington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Worthington, West Virginia"
    },
    {
      "city": "Benwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Benwood, West Virginia"
    },
    {
      "city": "Cameron",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cameron, West Virginia"
    },
    {
      "city": "Dallas",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dallas, West Virginia"
    },
    {
      "city": "Glen Dale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Dale, West Virginia"
    },
    {
      "city": "Glen Easton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Easton, West Virginia"
    },
    {
      "city": "Mcmechen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mcmechen, West Virginia"
    },
    {
      "city": "Moundsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Moundsville, West Virginia"
    },
    {
      "city": "Proctor",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Proctor, West Virginia"
    },
    {
      "city": "Henderson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Henderson, West Virginia"
    },
    {
      "city": "Leon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Leon, West Virginia"
    },
    {
      "city": "Southside",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Southside, West Virginia"
    },
    {
      "city": "Hartford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hartford, West Virginia"
    },
    {
      "city": "Letart",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Letart, West Virginia"
    },
    {
      "city": "Mason",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mason, West Virginia"
    },
    {
      "city": "New Haven",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Haven, West Virginia"
    },
    {
      "city": "West Columbia",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "West Columbia, West Virginia"
    },
    {
      "city": "Apple Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Apple Grove, West Virginia"
    },
    {
      "city": "Ashton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ashton, West Virginia"
    },
    {
      "city": "Gallipolis Ferry",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gallipolis Ferry, West Virginia"
    },
    {
      "city": "Glenwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glenwood, West Virginia"
    },
    {
      "city": "Point Pleasant",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Point Pleasant, West Virginia"
    },
    {
      "city": "Bluefield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bluefield, West Virginia"
    },
    {
      "city": "Athens",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Athens, West Virginia"
    },
    {
      "city": "Beeson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Beeson, West Virginia"
    },
    {
      "city": "Bramwell",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bramwell, West Virginia"
    },
    {
      "city": "Freeman",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Freeman, West Virginia"
    },
    {
      "city": "Hiawatha",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hiawatha, West Virginia"
    },
    {
      "city": "Kegley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kegley, West Virginia"
    },
    {
      "city": "Kellysville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kellysville, West Virginia"
    },
    {
      "city": "Lashmeet",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lashmeet, West Virginia"
    },
    {
      "city": "Matoaka",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Matoaka, West Virginia"
    },
    {
      "city": "Montcalm",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Montcalm, West Virginia"
    },
    {
      "city": "Nemours",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nemours, West Virginia"
    },
    {
      "city": "Princeton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Princeton, West Virginia"
    },
    {
      "city": "Rock",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rock, West Virginia"
    },
    {
      "city": "Wolfe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wolfe, West Virginia"
    },
    {
      "city": "Camp Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Camp Creek, West Virginia"
    },
    {
      "city": "Flat Top",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Flat Top, West Virginia"
    },
    {
      "city": "Spanishburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Spanishburg, West Virginia"
    },
    {
      "city": "Lerona",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lerona, West Virginia"
    },
    {
      "city": "Burlington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Burlington, West Virginia"
    },
    {
      "city": "Elk Garden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elk Garden, West Virginia"
    },
    {
      "city": "Fort Ashby",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fort Ashby, West Virginia"
    },
    {
      "city": "Keyser",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Keyser, West Virginia"
    },
    {
      "city": "New Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Creek, West Virginia"
    },
    {
      "city": "Piedmont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Piedmont, West Virginia"
    },
    {
      "city": "Ridgeley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ridgeley, West Virginia"
    },
    {
      "city": "Wiley Ford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wiley Ford, West Virginia"
    },
    {
      "city": "Justice",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Justice, West Virginia"
    },
    {
      "city": "Baisden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Baisden, West Virginia"
    },
    {
      "city": "Gilbert",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gilbert, West Virginia"
    },
    {
      "city": "Verner",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Verner, West Virginia"
    },
    {
      "city": "Wharncliffe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wharncliffe, West Virginia"
    },
    {
      "city": "Williamson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Williamson, West Virginia"
    },
    {
      "city": "Borderland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Borderland, West Virginia"
    },
    {
      "city": "Breeden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Breeden, West Virginia"
    },
    {
      "city": "Chattaroy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Chattaroy, West Virginia"
    },
    {
      "city": "Delbarton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Delbarton, West Virginia"
    },
    {
      "city": "Dingess",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dingess, West Virginia"
    },
    {
      "city": "Edgarton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Edgarton, West Virginia"
    },
    {
      "city": "Kermit",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kermit, West Virginia"
    },
    {
      "city": "Lenore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lenore, West Virginia"
    },
    {
      "city": "Matewan",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Matewan, West Virginia"
    },
    {
      "city": "Naugatuck",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Naugatuck, West Virginia"
    },
    {
      "city": "Newtown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Newtown, West Virginia"
    },
    {
      "city": "North Matewan",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "North Matewan, West Virginia"
    },
    {
      "city": "Ragland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ragland, West Virginia"
    },
    {
      "city": "Rawl",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rawl, West Virginia"
    },
    {
      "city": "Red Jacket",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Red Jacket, West Virginia"
    },
    {
      "city": "Varney",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Varney, West Virginia"
    },
    {
      "city": "Morgantown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Morgantown, West Virginia"
    },
    {
      "city": "Blacksville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Blacksville, West Virginia"
    },
    {
      "city": "Cassville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cassville, West Virginia"
    },
    {
      "city": "Dellslow",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dellslow, West Virginia"
    },
    {
      "city": "Granville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Granville, West Virginia"
    },
    {
      "city": "Maidsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maidsville, West Virginia"
    },
    {
      "city": "Osage",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Osage, West Virginia"
    },
    {
      "city": "Pentress",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pentress, West Virginia"
    },
    {
      "city": "Pursglove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pursglove, West Virginia"
    },
    {
      "city": "Wana",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wana, West Virginia"
    },
    {
      "city": "Ballard",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ballard, West Virginia"
    },
    {
      "city": "Gap Mills",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gap Mills, West Virginia"
    },
    {
      "city": "Greenville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Greenville, West Virginia"
    },
    {
      "city": "Lindside",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lindside, West Virginia"
    },
    {
      "city": "Peterstown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Peterstown, West Virginia"
    },
    {
      "city": "Secondcreek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Secondcreek, West Virginia"
    },
    {
      "city": "Sinks Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sinks Grove, West Virginia"
    },
    {
      "city": "Union",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Union, West Virginia"
    },
    {
      "city": "Waiteville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Waiteville, West Virginia"
    },
    {
      "city": "Wayside",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wayside, West Virginia"
    },
    {
      "city": "Wolfcreek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wolfcreek, West Virginia"
    },
    {
      "city": "Berkeley Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Berkeley Springs, West Virginia"
    },
    {
      "city": "Great Cacapon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Great Cacapon, West Virginia"
    },
    {
      "city": "Paw Paw",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Paw Paw, West Virginia"
    },
    {
      "city": "Dixie",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dixie, West Virginia"
    },
    {
      "city": "Fenwick",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fenwick, West Virginia"
    },
    {
      "city": "Craigsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Craigsville, West Virginia"
    },
    {
      "city": "Richwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Richwood, West Virginia"
    },
    {
      "city": "Birch River",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Birch River, West Virginia"
    },
    {
      "city": "Summersville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Summersville, West Virginia"
    },
    {
      "city": "Belva",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Belva, West Virginia"
    },
    {
      "city": "Calvin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Calvin, West Virginia"
    },
    {
      "city": "Canvas",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Canvas, West Virginia"
    },
    {
      "city": "Drennen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Drennen, West Virginia"
    },
    {
      "city": "Gilboa",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gilboa, West Virginia"
    },
    {
      "city": "Keslers Cross Lanes",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Keslers Cross Lanes, West Virginia"
    },
    {
      "city": "Leivasy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Leivasy, West Virginia"
    },
    {
      "city": "Mount Lookout",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Lookout, West Virginia"
    },
    {
      "city": "Mount Nebo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mount Nebo, West Virginia"
    },
    {
      "city": "Nettie",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nettie, West Virginia"
    },
    {
      "city": "Pool",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pool, West Virginia"
    },
    {
      "city": "Swiss",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Swiss, West Virginia"
    },
    {
      "city": "Tioga",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Tioga, West Virginia"
    },
    {
      "city": "Wheeling",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wheeling, West Virginia"
    },
    {
      "city": "Triadelphia",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Triadelphia, West Virginia"
    },
    {
      "city": "Valley Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Valley Grove, West Virginia"
    },
    {
      "city": "West Liberty",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "West Liberty, West Virginia"
    },
    {
      "city": "Brandywine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Brandywine, West Virginia"
    },
    {
      "city": "Circleville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Circleville, West Virginia"
    },
    {
      "city": "Franklin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Franklin, West Virginia"
    },
    {
      "city": "Riverton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Riverton, West Virginia"
    },
    {
      "city": "Sugar Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sugar Grove, West Virginia"
    },
    {
      "city": "Upper Tract",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Upper Tract, West Virginia"
    },
    {
      "city": "Seneca Rocks",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Seneca Rocks, West Virginia"
    },
    {
      "city": "Onego",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Onego, West Virginia"
    },
    {
      "city": "Belmont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Belmont, West Virginia"
    },
    {
      "city": "Saint Marys",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Saint Marys, West Virginia"
    },
    {
      "city": "Arbovale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Arbovale, West Virginia"
    },
    {
      "city": "Bartow",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bartow, West Virginia"
    },
    {
      "city": "Buckeye",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Buckeye, West Virginia"
    },
    {
      "city": "Cass",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cass, West Virginia"
    },
    {
      "city": "Dunmore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dunmore, West Virginia"
    },
    {
      "city": "Green Bank",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Green Bank, West Virginia"
    },
    {
      "city": "Hillsboro",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hillsboro, West Virginia"
    },
    {
      "city": "Marlinton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Marlinton, West Virginia"
    },
    {
      "city": "Snowshoe",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Snowshoe, West Virginia"
    },
    {
      "city": "Durbin",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Durbin, West Virginia"
    },
    {
      "city": "Slatyfork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Slatyfork, West Virginia"
    },
    {
      "city": "Independence",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Independence, West Virginia"
    },
    {
      "city": "Newburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Newburg, West Virginia"
    },
    {
      "city": "Rowlesburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rowlesburg, West Virginia"
    },
    {
      "city": "Tunnelton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Tunnelton, West Virginia"
    },
    {
      "city": "Albright",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Albright, West Virginia"
    },
    {
      "city": "Arthurdale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Arthurdale, West Virginia"
    },
    {
      "city": "Bretz",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bretz, West Virginia"
    },
    {
      "city": "Bruceton Mills",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bruceton Mills, West Virginia"
    },
    {
      "city": "Kingwood",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kingwood, West Virginia"
    },
    {
      "city": "Masontown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Masontown, West Virginia"
    },
    {
      "city": "Reedsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Reedsville, West Virginia"
    },
    {
      "city": "Aurora",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Aurora, West Virginia"
    },
    {
      "city": "Eglon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Eglon, West Virginia"
    },
    {
      "city": "Terra Alta",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Terra Alta, West Virginia"
    },
    {
      "city": "Bancroft",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bancroft, West Virginia"
    },
    {
      "city": "Buffalo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Buffalo, West Virginia"
    },
    {
      "city": "Eleanor",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Eleanor, West Virginia"
    },
    {
      "city": "Fraziers Bottom",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fraziers Bottom, West Virginia"
    },
    {
      "city": "Hometown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hometown, West Virginia"
    },
    {
      "city": "Liberty",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Liberty, West Virginia"
    },
    {
      "city": "Poca",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Poca, West Virginia"
    },
    {
      "city": "Red House",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Red House, West Virginia"
    },
    {
      "city": "Winfield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Winfield, West Virginia"
    },
    {
      "city": "Hurricane",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hurricane, West Virginia"
    },
    {
      "city": "Scott Depot",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Scott Depot, West Virginia"
    },
    {
      "city": "Teays",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Teays, West Virginia"
    },
    {
      "city": "Arnett",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Arnett, West Virginia"
    },
    {
      "city": "Artie",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Artie, West Virginia"
    },
    {
      "city": "Clear Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clear Creek, West Virginia"
    },
    {
      "city": "Colcord",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Colcord, West Virginia"
    },
    {
      "city": "Dorothy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dorothy, West Virginia"
    },
    {
      "city": "Dry Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dry Creek, West Virginia"
    },
    {
      "city": "Naoma",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Naoma, West Virginia"
    },
    {
      "city": "Rock Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rock Creek, West Virginia"
    },
    {
      "city": "Saxon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Saxon, West Virginia"
    },
    {
      "city": "Beckley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Beckley, West Virginia"
    },
    {
      "city": "Beaver",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Beaver, West Virginia"
    },
    {
      "city": "Bolt",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bolt, West Virginia"
    },
    {
      "city": "Bradley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bradley, West Virginia"
    },
    {
      "city": "Coal City",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Coal City, West Virginia"
    },
    {
      "city": "Cool Ridge",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cool Ridge, West Virginia"
    },
    {
      "city": "Crab Orchard",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Crab Orchard, West Virginia"
    },
    {
      "city": "Daniels",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Daniels, West Virginia"
    },
    {
      "city": "Eccles",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Eccles, West Virginia"
    },
    {
      "city": "Fairdale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fairdale, West Virginia"
    },
    {
      "city": "Ghent",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ghent, West Virginia"
    },
    {
      "city": "Glen Daniel",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Daniel, West Virginia"
    },
    {
      "city": "Glen White",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen White, West Virginia"
    },
    {
      "city": "Harper",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Harper, West Virginia"
    },
    {
      "city": "Helen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Helen, West Virginia"
    },
    {
      "city": "Josephine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Josephine, West Virginia"
    },
    {
      "city": "Lanark",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lanark, West Virginia"
    },
    {
      "city": "Lester",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lester, West Virginia"
    },
    {
      "city": "Mabscott",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mabscott, West Virginia"
    },
    {
      "city": "Mac Arthur",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mac Arthur, West Virginia"
    },
    {
      "city": "Midway",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Midway, West Virginia"
    },
    {
      "city": "Odd",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Odd, West Virginia"
    },
    {
      "city": "Piney View",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Piney View, West Virginia"
    },
    {
      "city": "Princewick",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Princewick, West Virginia"
    },
    {
      "city": "Prosperity",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Prosperity, West Virginia"
    },
    {
      "city": "Raleigh",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Raleigh, West Virginia"
    },
    {
      "city": "Rhodell",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rhodell, West Virginia"
    },
    {
      "city": "Shady Spring",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shady Spring, West Virginia"
    },
    {
      "city": "Skelton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Skelton, West Virginia"
    },
    {
      "city": "Slab Fork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Slab Fork, West Virginia"
    },
    {
      "city": "Sophia",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sophia, West Virginia"
    },
    {
      "city": "Stanaford",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Stanaford, West Virginia"
    },
    {
      "city": "Surveyor",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Surveyor, West Virginia"
    },
    {
      "city": "White Oak",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "White Oak, West Virginia"
    },
    {
      "city": "Helvetia",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Helvetia, West Virginia"
    },
    {
      "city": "Pickens",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pickens, West Virginia"
    },
    {
      "city": "Elkins",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elkins, West Virginia"
    },
    {
      "city": "Beverly",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Beverly, West Virginia"
    },
    {
      "city": "Bowden",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bowden, West Virginia"
    },
    {
      "city": "Coalton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Coalton, West Virginia"
    },
    {
      "city": "Dailey",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dailey, West Virginia"
    },
    {
      "city": "Dryfork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dryfork, West Virginia"
    },
    {
      "city": "Ellamore",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ellamore, West Virginia"
    },
    {
      "city": "Glady",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glady, West Virginia"
    },
    {
      "city": "Harman",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Harman, West Virginia"
    },
    {
      "city": "Huttonsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Huttonsville, West Virginia"
    },
    {
      "city": "Kerens",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kerens, West Virginia"
    },
    {
      "city": "Mabie",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mabie, West Virginia"
    },
    {
      "city": "Mill Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mill Creek, West Virginia"
    },
    {
      "city": "Monterville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Monterville, West Virginia"
    },
    {
      "city": "Montrose",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Montrose, West Virginia"
    },
    {
      "city": "Norton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Norton, West Virginia"
    },
    {
      "city": "Valley Bend",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Valley Bend, West Virginia"
    },
    {
      "city": "Valley Head",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Valley Head, West Virginia"
    },
    {
      "city": "Whitmer",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Whitmer, West Virginia"
    },
    {
      "city": "Macfarlan",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Macfarlan, West Virginia"
    },
    {
      "city": "Petroleum",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Petroleum, West Virginia"
    },
    {
      "city": "Smithville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Smithville, West Virginia"
    },
    {
      "city": "Auburn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Auburn, West Virginia"
    },
    {
      "city": "Berea",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Berea, West Virginia"
    },
    {
      "city": "Cairo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cairo, West Virginia"
    },
    {
      "city": "Ellenboro",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ellenboro, West Virginia"
    },
    {
      "city": "Harrisville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Harrisville, West Virginia"
    },
    {
      "city": "Pennsboro",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pennsboro, West Virginia"
    },
    {
      "city": "Pullman",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pullman, West Virginia"
    },
    {
      "city": "Amma",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Amma, West Virginia"
    },
    {
      "city": "Gandeeville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Gandeeville, West Virginia"
    },
    {
      "city": "Left Hand",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Left Hand, West Virginia"
    },
    {
      "city": "Looneyville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Looneyville, West Virginia"
    },
    {
      "city": "Newton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Newton, West Virginia"
    },
    {
      "city": "Reedy",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Reedy, West Virginia"
    },
    {
      "city": "Spencer",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Spencer, West Virginia"
    },
    {
      "city": "Walton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Walton, West Virginia"
    },
    {
      "city": "Forest Hill",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Forest Hill, West Virginia"
    },
    {
      "city": "Pence Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pence Springs, West Virginia"
    },
    {
      "city": "Talcott",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Talcott, West Virginia"
    },
    {
      "city": "Hinton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hinton, West Virginia"
    },
    {
      "city": "Green Sulphur Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Green Sulphur Springs, West Virginia"
    },
    {
      "city": "Jumping Branch",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jumping Branch, West Virginia"
    },
    {
      "city": "Meadow Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Meadow Creek, West Virginia"
    },
    {
      "city": "Nimitz",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Nimitz, West Virginia"
    },
    {
      "city": "Pipestem",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pipestem, West Virginia"
    },
    {
      "city": "Sandstone",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sandstone, West Virginia"
    },
    {
      "city": "Flemington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Flemington, West Virginia"
    },
    {
      "city": "Grafton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Grafton, West Virginia"
    },
    {
      "city": "Rosemont",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rosemont, West Virginia"
    },
    {
      "city": "Simpson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Simpson, West Virginia"
    },
    {
      "city": "Thornton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Thornton, West Virginia"
    },
    {
      "city": "Davis",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Davis, West Virginia"
    },
    {
      "city": "Hambleton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hambleton, West Virginia"
    },
    {
      "city": "Hendricks",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hendricks, West Virginia"
    },
    {
      "city": "Parsons",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Parsons, West Virginia"
    },
    {
      "city": "Red Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Red Creek, West Virginia"
    },
    {
      "city": "Thomas",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Thomas, West Virginia"
    },
    {
      "city": "Friendly",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Friendly, West Virginia"
    },
    {
      "city": "Middlebourne",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Middlebourne, West Virginia"
    },
    {
      "city": "Sistersville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sistersville, West Virginia"
    },
    {
      "city": "Alma",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Alma, West Virginia"
    },
    {
      "city": "Shirley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shirley, West Virginia"
    },
    {
      "city": "Buckhannon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Buckhannon, West Virginia"
    },
    {
      "city": "Adrian",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Adrian, West Virginia"
    },
    {
      "city": "Cleveland",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cleveland, West Virginia"
    },
    {
      "city": "French Creek",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "French Creek, West Virginia"
    },
    {
      "city": "Frenchton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Frenchton, West Virginia"
    },
    {
      "city": "Kanawha Head",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kanawha Head, West Virginia"
    },
    {
      "city": "Lorentz",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lorentz, West Virginia"
    },
    {
      "city": "Rock Cave",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rock Cave, West Virginia"
    },
    {
      "city": "Selbyville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Selbyville, West Virginia"
    },
    {
      "city": "Tallmansville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Tallmansville, West Virginia"
    },
    {
      "city": "Ceredo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ceredo, West Virginia"
    },
    {
      "city": "Dunlow",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Dunlow, West Virginia"
    },
    {
      "city": "East Lynn",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "East Lynn, West Virginia"
    },
    {
      "city": "Fort Gay",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fort Gay, West Virginia"
    },
    {
      "city": "Genoa",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Genoa, West Virginia"
    },
    {
      "city": "Kenova",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kenova, West Virginia"
    },
    {
      "city": "Kiahsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kiahsville, West Virginia"
    },
    {
      "city": "Lavalette",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lavalette, West Virginia"
    },
    {
      "city": "Prichard",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Prichard, West Virginia"
    },
    {
      "city": "Shoals",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Shoals, West Virginia"
    },
    {
      "city": "Wayne",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wayne, West Virginia"
    },
    {
      "city": "Crum",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Crum, West Virginia"
    },
    {
      "city": "Wilsondale",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wilsondale, West Virginia"
    },
    {
      "city": "Erbacon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Erbacon, West Virginia"
    },
    {
      "city": "Cowen",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cowen, West Virginia"
    },
    {
      "city": "Camden On Gauley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Camden On Gauley, West Virginia"
    },
    {
      "city": "Diana",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Diana, West Virginia"
    },
    {
      "city": "Hacker Valley",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hacker Valley, West Virginia"
    },
    {
      "city": "Upperglade",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Upperglade, West Virginia"
    },
    {
      "city": "Webster Springs",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Webster Springs, West Virginia"
    },
    {
      "city": "Bergoo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bergoo, West Virginia"
    },
    {
      "city": "New Martinsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Martinsville, West Virginia"
    },
    {
      "city": "Paden City",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Paden City, West Virginia"
    },
    {
      "city": "Porters Falls",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Porters Falls, West Virginia"
    },
    {
      "city": "Reader",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Reader, West Virginia"
    },
    {
      "city": "Folsom",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Folsom, West Virginia"
    },
    {
      "city": "Jacksonburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jacksonburg, West Virginia"
    },
    {
      "city": "Pine Grove",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pine Grove, West Virginia"
    },
    {
      "city": "Smithfield",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Smithfield, West Virginia"
    },
    {
      "city": "Big Run",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Big Run, West Virginia"
    },
    {
      "city": "Burton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Burton, West Virginia"
    },
    {
      "city": "Hundred",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hundred, West Virginia"
    },
    {
      "city": "Littleton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Littleton, West Virginia"
    },
    {
      "city": "Brohard",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Brohard, West Virginia"
    },
    {
      "city": "Creston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Creston, West Virginia"
    },
    {
      "city": "Elizabeth",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Elizabeth, West Virginia"
    },
    {
      "city": "Munday",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Munday, West Virginia"
    },
    {
      "city": "Palestine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Palestine, West Virginia"
    },
    {
      "city": "Parkersburg",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Parkersburg, West Virginia"
    },
    {
      "city": "Vienna",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Vienna, West Virginia"
    },
    {
      "city": "Mineral Wells",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mineral Wells, West Virginia"
    },
    {
      "city": "Belleville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Belleville, West Virginia"
    },
    {
      "city": "Davisville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Davisville, West Virginia"
    },
    {
      "city": "Rockport",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rockport, West Virginia"
    },
    {
      "city": "Walker",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Walker, West Virginia"
    },
    {
      "city": "Washington",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Washington, West Virginia"
    },
    {
      "city": "Waverly",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Waverly, West Virginia"
    },
    {
      "city": "Williamstown",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Williamstown, West Virginia"
    },
    {
      "city": "Bud",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Bud, West Virginia"
    },
    {
      "city": "Covel",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Covel, West Virginia"
    },
    {
      "city": "Herndon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Herndon, West Virginia"
    },
    {
      "city": "Brenton",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Brenton, West Virginia"
    },
    {
      "city": "Clear Fork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Clear Fork, West Virginia"
    },
    {
      "city": "Coal Mountain",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Coal Mountain, West Virginia"
    },
    {
      "city": "Cyclone",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Cyclone, West Virginia"
    },
    {
      "city": "Fanrock",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Fanrock, West Virginia"
    },
    {
      "city": "Hanover",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Hanover, West Virginia"
    },
    {
      "city": "Ikes Fork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ikes Fork, West Virginia"
    },
    {
      "city": "Itmann",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Itmann, West Virginia"
    },
    {
      "city": "Jesse",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Jesse, West Virginia"
    },
    {
      "city": "Kopperston",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Kopperston, West Virginia"
    },
    {
      "city": "Lynco",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Lynco, West Virginia"
    },
    {
      "city": "Marianna",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Marianna, West Virginia"
    },
    {
      "city": "Matheny",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Matheny, West Virginia"
    },
    {
      "city": "New Richmond",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "New Richmond, West Virginia"
    },
    {
      "city": "North Spring",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "North Spring, West Virginia"
    },
    {
      "city": "Oceana",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Oceana, West Virginia"
    },
    {
      "city": "Pineville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Pineville, West Virginia"
    },
    {
      "city": "Rock View",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Rock View, West Virginia"
    },
    {
      "city": "Simon",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Simon, West Virginia"
    },
    {
      "city": "Wyoming",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wyoming, West Virginia"
    },
    {
      "city": "Allen Junction",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Allen Junction, West Virginia"
    },
    {
      "city": "Amigo",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Amigo, West Virginia"
    },
    {
      "city": "Corinne",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Corinne, West Virginia"
    },
    {
      "city": "Glen Fork",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Fork, West Virginia"
    },
    {
      "city": "Glen Rogers",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Glen Rogers, West Virginia"
    },
    {
      "city": "Maben",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Maben, West Virginia"
    },
    {
      "city": "Mc Graws",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mc Graws, West Virginia"
    },
    {
      "city": "Saulsville",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Saulsville, West Virginia"
    },
    {
      "city": "Mullens",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Mullens, West Virginia"
    },
    {
      "city": "Ravencliff",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Ravencliff, West Virginia"
    },
    {
      "city": "Sabine",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Sabine, West Virginia"
    },
    {
      "city": "Stephenson",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Stephenson, West Virginia"
    },
    {
      "city": "Wyco",
      "state": "West Virginia",
      "shortState": "WV",
      "formatedCity": "Wyco, West Virginia"
    },
    {
      "city": "Bosler",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Bosler, Wyoming"
    },
    {
      "city": "Buford",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Buford, Wyoming"
    },
    {
      "city": "Centennial",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Centennial, Wyoming"
    },
    {
      "city": "Garrett",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Garrett, Wyoming"
    },
    {
      "city": "Jelm",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Jelm, Wyoming"
    },
    {
      "city": "Laramie",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Laramie, Wyoming"
    },
    {
      "city": "Rock River",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Rock River, Wyoming"
    },
    {
      "city": "Tie Siding",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Tie Siding, Wyoming"
    },
    {
      "city": "Basin",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Basin, Wyoming"
    },
    {
      "city": "Burlington",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Burlington, Wyoming"
    },
    {
      "city": "Byron",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Byron, Wyoming"
    },
    {
      "city": "Cowley",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Cowley, Wyoming"
    },
    {
      "city": "Deaver",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Deaver, Wyoming"
    },
    {
      "city": "Emblem",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Emblem, Wyoming"
    },
    {
      "city": "Greybull",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Greybull, Wyoming"
    },
    {
      "city": "Hyattville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hyattville, Wyoming"
    },
    {
      "city": "Lovell",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lovell, Wyoming"
    },
    {
      "city": "Manderson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Manderson, Wyoming"
    },
    {
      "city": "Otto",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Otto, Wyoming"
    },
    {
      "city": "Shell",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Shell, Wyoming"
    },
    {
      "city": "Gillette",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Gillette, Wyoming"
    },
    {
      "city": "Recluse",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Recluse, Wyoming"
    },
    {
      "city": "Rozet",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Rozet, Wyoming"
    },
    {
      "city": "Weston",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Weston, Wyoming"
    },
    {
      "city": "Wright",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wright, Wyoming"
    },
    {
      "city": "Rawlins",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Rawlins, Wyoming"
    },
    {
      "city": "Baggs",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Baggs, Wyoming"
    },
    {
      "city": "Dixon",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Dixon, Wyoming"
    },
    {
      "city": "Elk Mountain",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Elk Mountain, Wyoming"
    },
    {
      "city": "Encampment",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Encampment, Wyoming"
    },
    {
      "city": "Hanna",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hanna, Wyoming"
    },
    {
      "city": "Medicine Bow",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Medicine Bow, Wyoming"
    },
    {
      "city": "Saratoga",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Saratoga, Wyoming"
    },
    {
      "city": "Savery",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Savery, Wyoming"
    },
    {
      "city": "Sinclair",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Sinclair, Wyoming"
    },
    {
      "city": "Walcott",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Walcott, Wyoming"
    },
    {
      "city": "Shirley Basin",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Shirley Basin, Wyoming"
    },
    {
      "city": "Lost Springs",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lost Springs, Wyoming"
    },
    {
      "city": "Shawnee",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Shawnee, Wyoming"
    },
    {
      "city": "Douglas",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Douglas, Wyoming"
    },
    {
      "city": "Glenrock",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Glenrock, Wyoming"
    },
    {
      "city": "Aladdin",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Aladdin, Wyoming"
    },
    {
      "city": "Alva",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Alva, Wyoming"
    },
    {
      "city": "Beulah",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Beulah, Wyoming"
    },
    {
      "city": "Devils Tower",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Devils Tower, Wyoming"
    },
    {
      "city": "Hulett",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hulett, Wyoming"
    },
    {
      "city": "Moorcroft",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Moorcroft, Wyoming"
    },
    {
      "city": "Sundance",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Sundance, Wyoming"
    },
    {
      "city": "Jeffrey City",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Jeffrey City, Wyoming"
    },
    {
      "city": "Riverton",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Riverton, Wyoming"
    },
    {
      "city": "Arapahoe",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Arapahoe, Wyoming"
    },
    {
      "city": "Crowheart",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Crowheart, Wyoming"
    },
    {
      "city": "Dubois",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Dubois, Wyoming"
    },
    {
      "city": "Fort Washakie",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Fort Washakie, Wyoming"
    },
    {
      "city": "Hudson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hudson, Wyoming"
    },
    {
      "city": "Kinnear",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Kinnear, Wyoming"
    },
    {
      "city": "Lander",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lander, Wyoming"
    },
    {
      "city": "Pavillion",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Pavillion, Wyoming"
    },
    {
      "city": "Saint Stephens",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Saint Stephens, Wyoming"
    },
    {
      "city": "Lysite",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lysite, Wyoming"
    },
    {
      "city": "Shoshoni",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Shoshoni, Wyoming"
    },
    {
      "city": "Fort Laramie",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Fort Laramie, Wyoming"
    },
    {
      "city": "Hawk Springs",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hawk Springs, Wyoming"
    },
    {
      "city": "Huntley",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Huntley, Wyoming"
    },
    {
      "city": "Jay Em",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Jay Em, Wyoming"
    },
    {
      "city": "Lagrange",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lagrange, Wyoming"
    },
    {
      "city": "Lingle",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lingle, Wyoming"
    },
    {
      "city": "Torrington",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Torrington, Wyoming"
    },
    {
      "city": "Veteran",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Veteran, Wyoming"
    },
    {
      "city": "Yoder",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Yoder, Wyoming"
    },
    {
      "city": "Kirby",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Kirby, Wyoming"
    },
    {
      "city": "Thermopolis",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Thermopolis, Wyoming"
    },
    {
      "city": "Kaycee",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Kaycee, Wyoming"
    },
    {
      "city": "Linch",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Linch, Wyoming"
    },
    {
      "city": "Buffalo",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Buffalo, Wyoming"
    },
    {
      "city": "Saddlestring",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Saddlestring, Wyoming"
    },
    {
      "city": "Cheyenne",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Cheyenne, Wyoming"
    },
    {
      "city": "Fe Warren Afb",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Fe Warren Afb, Wyoming"
    },
    {
      "city": "Albin",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Albin, Wyoming"
    },
    {
      "city": "Burns",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Burns, Wyoming"
    },
    {
      "city": "Carpenter",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Carpenter, Wyoming"
    },
    {
      "city": "Granite Canon",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Granite Canon, Wyoming"
    },
    {
      "city": "Hillsdale",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hillsdale, Wyoming"
    },
    {
      "city": "Horse Creek",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Horse Creek, Wyoming"
    },
    {
      "city": "Meriden",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Meriden, Wyoming"
    },
    {
      "city": "Pine Bluffs",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Pine Bluffs, Wyoming"
    },
    {
      "city": "Kemmerer",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Kemmerer, Wyoming"
    },
    {
      "city": "Afton",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Afton, Wyoming"
    },
    {
      "city": "Auburn",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Auburn, Wyoming"
    },
    {
      "city": "Bedford",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Bedford, Wyoming"
    },
    {
      "city": "Cokeville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Cokeville, Wyoming"
    },
    {
      "city": "Diamondville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Diamondville, Wyoming"
    },
    {
      "city": "Etna",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Etna, Wyoming"
    },
    {
      "city": "Fairview",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Fairview, Wyoming"
    },
    {
      "city": "Freedom",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Freedom, Wyoming"
    },
    {
      "city": "Frontier",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Frontier, Wyoming"
    },
    {
      "city": "Grover",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Grover, Wyoming"
    },
    {
      "city": "La Barge",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "La Barge, Wyoming"
    },
    {
      "city": "Opal",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Opal, Wyoming"
    },
    {
      "city": "Smoot",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Smoot, Wyoming"
    },
    {
      "city": "Thayne",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Thayne, Wyoming"
    },
    {
      "city": "Alpine",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Alpine, Wyoming"
    },
    {
      "city": "Casper",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Casper, Wyoming"
    },
    {
      "city": "Alcova",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Alcova, Wyoming"
    },
    {
      "city": "Arminto",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Arminto, Wyoming"
    },
    {
      "city": "Edgerton",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Edgerton, Wyoming"
    },
    {
      "city": "Evansville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Evansville, Wyoming"
    },
    {
      "city": "Hiland",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hiland, Wyoming"
    },
    {
      "city": "Midwest",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Midwest, Wyoming"
    },
    {
      "city": "Mills",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Mills, Wyoming"
    },
    {
      "city": "Natrona",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Natrona, Wyoming"
    },
    {
      "city": "Powder River",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Powder River, Wyoming"
    },
    {
      "city": "Lance Creek",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lance Creek, Wyoming"
    },
    {
      "city": "Lusk",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lusk, Wyoming"
    },
    {
      "city": "Manville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Manville, Wyoming"
    },
    {
      "city": "Van Tassell",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Van Tassell, Wyoming"
    },
    {
      "city": "Yellowstone National Park",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Yellowstone National Park, Wyoming"
    },
    {
      "city": "Cody",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Cody, Wyoming"
    },
    {
      "city": "Frannie",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Frannie, Wyoming"
    },
    {
      "city": "Meeteetse",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Meeteetse, Wyoming"
    },
    {
      "city": "Powell",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Powell, Wyoming"
    },
    {
      "city": "Ralston",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Ralston, Wyoming"
    },
    {
      "city": "Wapiti",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wapiti, Wyoming"
    },
    {
      "city": "Wheatland",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wheatland, Wyoming"
    },
    {
      "city": "Chugwater",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Chugwater, Wyoming"
    },
    {
      "city": "Glendo",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Glendo, Wyoming"
    },
    {
      "city": "Guernsey",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Guernsey, Wyoming"
    },
    {
      "city": "Hartville",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Hartville, Wyoming"
    },
    {
      "city": "Sheridan",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Sheridan, Wyoming"
    },
    {
      "city": "Arvada",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Arvada, Wyoming"
    },
    {
      "city": "Banner",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Banner, Wyoming"
    },
    {
      "city": "Big Horn",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Big Horn, Wyoming"
    },
    {
      "city": "Clearmont",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Clearmont, Wyoming"
    },
    {
      "city": "Dayton",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Dayton, Wyoming"
    },
    {
      "city": "Leiter",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Leiter, Wyoming"
    },
    {
      "city": "Parkman",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Parkman, Wyoming"
    },
    {
      "city": "Ranchester",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Ranchester, Wyoming"
    },
    {
      "city": "Story",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Story, Wyoming"
    },
    {
      "city": "Wolf",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wolf, Wyoming"
    },
    {
      "city": "Wyarno",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wyarno, Wyoming"
    },
    {
      "city": "Bondurant",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Bondurant, Wyoming"
    },
    {
      "city": "Boulder",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Boulder, Wyoming"
    },
    {
      "city": "Cora",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Cora, Wyoming"
    },
    {
      "city": "Pinedale",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Pinedale, Wyoming"
    },
    {
      "city": "Big Piney",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Big Piney, Wyoming"
    },
    {
      "city": "Daniel",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Daniel, Wyoming"
    },
    {
      "city": "Bairoil",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Bairoil, Wyoming"
    },
    {
      "city": "Wamsutter",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wamsutter, Wyoming"
    },
    {
      "city": "Rock Springs",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Rock Springs, Wyoming"
    },
    {
      "city": "Little America",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Little America, Wyoming"
    },
    {
      "city": "Farson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Farson, Wyoming"
    },
    {
      "city": "Granger",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Granger, Wyoming"
    },
    {
      "city": "Green River",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Green River, Wyoming"
    },
    {
      "city": "Mc Kinnon",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Mc Kinnon, Wyoming"
    },
    {
      "city": "Point Of Rocks",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Point Of Rocks, Wyoming"
    },
    {
      "city": "Reliance",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Reliance, Wyoming"
    },
    {
      "city": "Superior",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Superior, Wyoming"
    },
    {
      "city": "Jackson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Jackson, Wyoming"
    },
    {
      "city": "Kelly",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Kelly, Wyoming"
    },
    {
      "city": "Moose",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Moose, Wyoming"
    },
    {
      "city": "Moran",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Moran, Wyoming"
    },
    {
      "city": "Wilson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Wilson, Wyoming"
    },
    {
      "city": "Teton Village",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Teton Village, Wyoming"
    },
    {
      "city": "Alta",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Alta, Wyoming"
    },
    {
      "city": "Evanston",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Evanston, Wyoming"
    },
    {
      "city": "Fort Bridger",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Fort Bridger, Wyoming"
    },
    {
      "city": "Lonetree",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lonetree, Wyoming"
    },
    {
      "city": "Lyman",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Lyman, Wyoming"
    },
    {
      "city": "Mountain View",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Mountain View, Wyoming"
    },
    {
      "city": "Robertson",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Robertson, Wyoming"
    },
    {
      "city": "Worland",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Worland, Wyoming"
    },
    {
      "city": "Ten Sleep",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Ten Sleep, Wyoming"
    },
    {
      "city": "Newcastle",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Newcastle, Wyoming"
    },
    {
      "city": "Four Corners",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Four Corners, Wyoming"
    },
    {
      "city": "Osage",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Osage, Wyoming"
    },
    {
      "city": "Upton",
      "state": "Wyoming",
      "shortState": "WY",
      "formatedCity": "Upton, Wyoming"
    }
];