import {
    TARGET_TO_NOTIFY_VALUES,
    CANDIDATES_ACCEPTANCE_OTIONS,
    PROPERTY_TYPES,
    RETAIL_SPACE_TYPES,
    CREDIT_TYPES
} from "../../../../../assets/strings";

export const OPPORTUNITY_CREATION_STEPS = {
    OPPORTUNITY_TYPE_STEP: "OPPORTUNITY_TYPE_STEP",
    REFERRAL_PRIVACY_STEP: "REFERRAL_PRIVACY_STEP",
    REFERRAL_PROFESSION: "REFERRAL_PROFESSION",
    REFERRAL_COMPLETE_INFORMATION: "REFERRAL_COMPLETE_INFORMATION",
    REFERRAL_PREVIEW: "REFERRAL_PREVIEW",
    CANDIDATES_SELECTION: "CANDIDATES_SELECTION",
    RECOMMENDATION_TYPE: "RECOMMENDATION_TYPE",
    RECOMMENDATION_DIRECT_INFORMATION: "RECOMMENDATION_DIRECT_INFORMATION",
    RECOMMENDATION_REQUEST_INFORMATION: "RECOMMENDATION_REQUEST_INFORMATION",
    RECOMMENDATION_REQUEST_PREVIEW: "RECOMMENDATION_REQUEST_PREVIEW",
    REFERRAL_PAST_CANDIDATE: "REFERRAL_PAST_CANDIDATE",
    REFERRAL_PAST_COMPLETE_INFORMATION: "REFERRAL_PAST_COMPLETE_INFORMATION",
    REFERRAL_PAST_PREVIEW: "REFERRAL_PAST_PREVIEW",
    JOB_CURRENT_TYPE: "JOB_CURRENT_TYPE",
    JOB_CURRENT_PRIVACY: "JOB_CURRENT_PRIVACY",
    JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION: "JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION",
    JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION: "JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION",
    JOB_CURRENT_EMPLOYEE_PREVIEW: "JOB_CURRENT_EMPLOYEE_PREVIEW",
    JOB_CURRENT_CONTRACTOR_PREVIEW: "JOB_CURRENT_CONTRACTOR_PREVIEW",
    JOB_PAST_COMPLETE_INFORMATION: "JOB_PAST_COMPLETE_INFORMATION",
    JOB_PAST_PREVIEW: "JOB_PAST_PREVIEW"
};

export const OpportunityStepsOrder = {
    [OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP]: 0,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP]: 1,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_TYPE]: 1,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE]: 1,
    [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: 2,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_PRIVACY]: 2,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: 3,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION]: 3,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION]: 3,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION]: 3,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_INFORMATION]: 3,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PREVIEW]: 4,
    [OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_REQUEST_PREVIEW]: 4,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_PREVIEW]: 4,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_PREVIEW]: 4,
}

export const OPPORTUNITY_TYPES = {
    RECOMMENDATION: "RECOMMENDATION",
    REFERRAL: "REFERRAL",
    JOB: "JOB"
};

export const REFERRAL_PRIVACY = {
    EVERYONE: "EVERYONE",
    GROUPS: "GROUPS",
    CANDIDATE: "CANDIDATE",
    REFERRAL_PAST: "REFERRAL_PAST"
};

export const RECOMMENDATION_TYPE = {
    I_KNOW: "I_KNOW",
    PROVIDER: "PROVIDER",
}

export const OpportunityTypesOptions = [
    { value: OPPORTUNITY_TYPES.RECOMMENDATION, label: "Recommendation" },
];

export const ReferralPrivacyOptions = [
    { value: REFERRAL_PRIVACY.EVERYONE, label: "Everyone" },
    // { value: REFERRAL_PRIVACY.GROUPS, label: "Only the group(s) that I select" },
    { value: REFERRAL_PRIVACY.CANDIDATE, label: "Only the candidate(s) that I select" }
];

export const RecommendationTypeOptions = [
    { value: RECOMMENDATION_TYPE.I_KNOW, label: "I want to make a recommendation to someone I know" },
    { value: RECOMMENDATION_TYPE.PROVIDER, label: "I need other NuOp Members to recommend a service provider to me" }
];

export const targetToNotifyOptions = [
    { value: TARGET_TO_NOTIFY_VALUES.ALL, label: "All Members" },
    {
        value: TARGET_TO_NOTIFY_VALUES["60_MILES"],
        label: "Members within 60 miles"
    },
    {
        value: TARGET_TO_NOTIFY_VALUES["300_MILES"],
        label: "Members within 300 miles"
    }
];

export const candidatesAcceptanceOptions = [
    {
        value: CANDIDATES_ACCEPTANCE_OTIONS.FIRST_COME_FIRST_SERVE,
        label: "The first candidate to accept it"
    },
    {
        value: CANDIDATES_ACCEPTANCE_OTIONS.ALL,
        label: "All Candidates"
    }
];

export const visibilityOptions = [
    {
        value: "PUBLIC",
        label: "Yes, display a successful exchange to everyone!"
    },
    {
        value: "PRIVATE",
        label: "No, only allow the exchanging parties to have visibility."
    }
];

export const PROPERTY_SUBTYPES = {
    [PROPERTY_TYPES.RESIDENTIAL]: {
        APARTMENT: "APARTMENT",
        CONDO: "CONDO",
        SINGLE_FAMILY: "SINGLE_FAMILY",
        MULTI_FAMILY: "MULTI_FAMILY"
    },
    [PROPERTY_TYPES.COMMERCIAL]: {
        OFFICE: "OFFICE",
        INDUSTRIAL: "INDUSTRIAL",
        FLEX: "FLEX",
        HEALTH_CARE: "HEALTH_CARE",
        HOSPITALITY: "HOSPITALITY",
        MIXED_USE: "MIXED_USE",
        MULTI_FAMILY: "MULTI_FAMILY",
        RETAIL_RESTURANT: "RETAIL_RESTURANT",
        SPORTS_AND_ENTERTAINMENT: "SPORTS_AND_ENTERTAINMENT"
    },
    [PROPERTY_TYPES.LAND]: {
        AGRICULTURAL: "AGRICULTURAL",
        INDUSTRIAL: "INDUSTRIAL",
        COMMERCIAL: "COMMERCIAL",
        RESIDENTIAL: "RESIDENTIAL"
    }
};

export const PropertyTypesOptions = [
    { value: PROPERTY_TYPES.RESIDENTIAL, label: "Residential" },
    { value: PROPERTY_TYPES.COMMERCIAL, label: "Commercial" },
    { value: PROPERTY_TYPES.LAND, label: "Land" },
];

export const ClientTypesOptions = [
    { value: 'BUYER', label: "Buyer" },
    { value: 'SELLER', label: "Seller" },
    { value: 'TENANT', label: "Tenant" },
    { value: 'LANDLORD', label: "Landlord" },
];

export const retailSpaceOptions = [
    {
        value: RETAIL_SPACE_TYPES.SQUARE_FEET_PER_YEAR,
        label: "SF/Yr"
    },
    { value: RETAIL_SPACE_TYPES.SQUARE_FEET_PER_MONTH, label: "SF/Mo" }
];

export const creditOptions = [
    { value: CREDIT_TYPES.GREAT, label: "Great" },
    { value: CREDIT_TYPES.GOOD, label: "Good" },
    { value: CREDIT_TYPES.NOT_SO_GOOD, label: "Not so good" },
    { value: CREDIT_TYPES.NOT_SURE, label: "Not Sure" }
];

export const PetsOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
    { value: "NOT_SURE", label: "Not Sure"}
];

export const YesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

export const LotSizeOptions = [
    { value: 'NoMin', label: 'No Min'},
    { value: '1000sqft', label: '1,000 sqft'},
    { value: '2000sqft', label: '2,000 sqft'},
    { value: '3000sqft', label: '3,000 sqft'},
    { value: '4000sqft', label: '4,000 sqft'},
    { value: '5000sqft', label: '5,000 sqft'},
    { value: '7000sqft', label: '7,000 sqft'},
    { value: '1/4acres', label: '1/4 acres'},
    { value: '1/2acres', label: '1/2 acres'},
    { value: '1acres', label: '1 acres'},
    { value: '2acres', label: '2 acres'},
    { value: '5acres', label: '5 acres'},
    { value: '10acres', label: '10 acres'},
    { value: '20acres', label: '20 acres'},
    { value: '50acres', label: '50 acres'},
    { value: '100acres', label: '100 acres'},
];

export const HomeSaleContingencyOptions = [
    { label: 'Yes', value: 'YES'},
    { label: 'No', value: 'NO' },
    { label: 'Not Sure', value: 'NOT_SURE' },
];

export const FinancingInfoOptions = [
    { value: 'ALL_CASH_BUYER', label: 'All Cash Buyer' },
    { value: 'PRE_APPROVED', label: 'Pre-approved for amount listed' },
    { value: 'NOT_YET_PRE_APPROVED', label: 'Not yet pre-approved' },
    { value: 'NOT_SURE', label: 'Not sure' },
];

export const UnitsNumberOptions = [
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
];

export const LandOptions = [
    { value: 'AGRICULTURAL', label: "Agricultural" },
    { value: 'COMMERCIAL', label: "Commercial" },
    { value: 'INDUSTRIAL', label: "Industrial" },
    { value: 'RESIDENTIAL', label: "Residential" },
];

export const CommercialOptions = [
    { value: 'FLEX', label: "Flex" },
    { value: 'HEALTH_CARE', label: "Health Care" },
    { value: 'HOSPITALITY', label: "Hospitality" },
    { value: 'INDUSTRIAL', label: "Industrial" },
    { value: 'MIXED_USE', label: "Mixed Use" },
    { value: 'MULTI_FAMILY', label: "Multi-Family 5+" },
    { value: 'OFFICE', label: "Office" },
    { value: 'RETAIL_RESTURANT', label: "Retail / Restaurant" },
    { value: 'SPORTS_AND_ENTERTAINMENT', label: "Sports & Entertainment" },
];

export const ResidentialExtendedOptions = [
    { value: 'APARTMENT', label: "Apartment" },
    { value: 'CONDO', label: "Condo" },
    { value: 'MULTI_FAMILY', label: "Multi-Family 2-4" },
    { value: 'SINGLE_FAMILY', label: "Single-Family" },
];

export const ResidentialOptions = [
    { value: 'APARTMENT', label: "Apartment" },
    { value: 'CONDO', label: "Condo" },
    { value: 'SINGLE_FAMILY', label: "Single-Family" },
];