import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StepWrapper, Button, ButtonContainer } from "../../../components";
import * as yup from 'yup'
import { Formik } from 'formik';
import Profession from "./_Profession";
import JobTitle from "./_JobTitle";
import Location from './_Location';
import Compensation from "./_Compensation";
import Description from "./_Description";
import VisibilityAfterExchange from "./_VisibilityAfterExchange";
import { DraftModal, useDraft } from "../../../useDraft";
import { Box } from "rebass";

const formSchema = (opMethod) => {
    return yup.object().shape({
        jobTitle: yup.string().max(30).required(),
        compensation: yup.string().max(30).required(),
        profession: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
            global: yup.boolean().required(),
        })
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).nullable()
        })
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === false,
            then: yup.object({
                value: yup.string().required(),
                label: yup.string().required(),
                global: yup.boolean().required(),
            }).required()
        }),
        cannotFindProfession: yup.boolean(),
        professionDescription: yup.string().nullable()
        .when("cannotFindProfession", {
            is: (cannotFindProfession) => cannotFindProfession === true,
            then: yup.string().required()
        }),
        address: yup.object().nullable()
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'REMOTE',
            then: yup.object().nullable()
        })
        .when("locationIsRemote", {
            is: (locationIsRemote) => locationIsRemote && locationIsRemote.value === 'PARTICULAR',
            then: yup.object({
                description: yup.string().required(),
                lat: yup.number().required(),
                lng: yup.number().required(),
            }).required()
        }),
        locationIsRemote: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
        }).required(),
        description: yup.string().min(31).max(2000).required(),
        visibilityAfterExchange: yup.object({
            value: yup.string().required(),
            label: yup.string().required(),  
        }).required(),
    });
}

const formInitialValues = {
    profession: null,
    address:  '',
    description: '',
    visibilityAfterExchange: { value: 'EVERYONE', label: 'Yes, display the accepted job to everyone!' },
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const JobCurrentEmployeeCompleteInformation = ({
    setStepData,
    stepData,
    professions,
    theme,
    t,
    candidates, }) => {
    const [professionOptions, setProfessionOptions] = useState([]);
    const candidatesCount = candidates ? candidates.length : 0;
    const opMethod = candidates.length === 1 ? 'DIRECT' : 'NON-DIRECT-OP';
    const formSchemaBuilder = formSchema(opMethod)
    useEffect(() => {
        if (professions !== [] && professions.length) {
            setProfessionOptions(professions.map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);
    const {saveDraft, loading, showModal, setShowModal, modalMessage} = useDraft();
    const onSubmitHandler = (values) => {
        setStepData(values);
    }

    const titleStyles = {
        fontSize: theme.mobileDevice ? "16px" : "18px",
        marginRight: "0.5em",
        cursor: "pointer",
        color: theme.coolBlack,
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: "12px"
    };

    return (
        <StepWrapper>
            <DraftModal showModal={showModal} setShowModal={setShowModal} modalMessage={modalMessage}/>
            <Formik
                initialValues={stepData ?  ({ ...formInitialValues, ...stepData}) : formInitialValues}
                onSubmit={(values) => onSubmitHandler(values)}
                validationSchema={formSchemaBuilder}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Container>
                        <Profession
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />

                        <JobTitle
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />

                        <Location
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />

                        <Compensation
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            professionOptions={professionOptions}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />

                        <Description
                            errors={errors}
                            titleStyles={titleStyles}
                            theme={theme}
                            handleChange={handleChange}
                            values={values}
                            t={t}
                        />

                        { candidatesCount > 0 && (
                            <VisibilityAfterExchange
                                errors={errors}
                                titleStyles={titleStyles}
                                theme={theme}
                                handleChange={handleChange}
                                values={values}
                                t={t}
                            />
                        )}
        
                        <ButtonContainer>
                        <Box mr={16}>
                            <Button
                                width="120px"
                                onClick={saveDraft}
                                disabled={loading}
                            >
                                Save Draft
                            </Button>
                        </Box>
                            <Button
                                onClick={handleSubmit}
                                disabled={false}
                                type='submit'
                            >
                                Next
                            </Button>
                        </ButtonContainer>
                    </Container>
                )}
            </Formik>
        </StepWrapper>
    );
};

export default JobCurrentEmployeeCompleteInformation;
