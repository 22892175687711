import {
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCC,
    RESET_PASSWORD_ISLOADING,
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_CLOSE_MODAL
} from "../../../../actions/constants";

const initialState = {
    isLoading: false,
    emailSent: false,
    errors: [],
    openModal: false,
};

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case RESET_PASSWORD_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case RESET_PASSWORD_SUCC: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.emailSent = true;
            _state.openModal = true;
            return _state;
        }
        case RESET_PASSWORD_CLOSE_MODAL: {
            const _state = {
                ...state
            };
            _state.openModal = false;
            return _state;
        }
        case RESET_PASSWORD_INIT: {
            return {...initialState}
        }
        default:
            return state;
    }
}

export default resetPasswordReducer;
