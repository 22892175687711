
import React, { PureComponent } from 'react';
import DateSafari from './DateSafari/DateSafari'
//import PropTypes from 'prop-types';
import { FormConsumer } from "../../../../../../hocs/Form2";
//import { BlueInputField } from './datePicker-styles';
import Error from '../../Error/Error'
class SmartDatePicker extends PureComponent {

    componentDidMount() {
        const { defaultValue, name, rules } = this.props;
        if (typeof defaultValue !== "undefined")
            this.props._setValue(
                name,
                defaultValue,
                rules,
                false
            );
    }

    componentWillUnmount = () => {
        const { name } = this.props;
        this.props._unsetValue(name);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.defaultValue !== this.props.defaultValue) {
            const { defaultValue, name, rules } = this.props;
            this.props._setValue(
                name,
                defaultValue,
                rules,
                false
            );
        }

    }

    onClick = () => {
        this.setState({ showPlaceHolder: false }, () => {
            this.nameInput.focus();
            this.nameInput.click();
        });
    }

    render() {
        const props = this.props;
        const { _setValue, _blurInput, onChange, error, color, bg, inputStyles, placeHolderStyles, name, values, validation, placeholder, touched, leftIcon, customInput, ...others } = props;
        let { rules } = this.props;
        return (
            <React.Fragment>
                <DateSafari
                    {...others}
                    customInput={customInput}
                    inputStyles={inputStyles}
                    placeHolderStyles={placeHolderStyles}
                    autocomplete="false"
                    error={error}
                    ref={(input) => { this.nameInput = input; }}
                    leftIcon={leftIcon}
                    color={color}
                    bg={bg}
                    name={name}
                    value={values[name]}
                    onChange={(value) => {
                        _setValue(name, value, rules, true);
                        if (onChange) onChange(value, name)
                    }}
                    onBlur={() => {
                        _blurInput(values[name], name, rules);
                    }}
                    validation={touched ? validation : null}
                    placeholder={placeholder}
                    onFocus={this.onFocus}
                />
            </React.Fragment>
        )
    }
}


const InputField = (props) => {
    const { color, labelStyles, material, name, errors, errorStyles } = props
    const err = errors[name] &&
    !errors[name].isValid &&
    errors[name].showError
    ? errors[name]
    : null
    return (
        <React.Fragment>
            <SmartDatePicker {...props} error={err} />
            <Error
                color={color}
                labelStyles={labelStyles}
                id="error-card"
                material={material}
                name={name}
                error={
                    err
                }
                errorStyles={errorStyles ? errorStyles : {}}
            />
        </React.Fragment>
    )
};

export default ({ children, ...otherProps }) => (
    <FormConsumer>
        {({ ...consumerProps }) => {
            return <InputField {...otherProps} {...consumerProps} />;
        }}
    </FormConsumer>
);


