import { connect } from 'react-redux';
import { OPPORTUNITY_CREATION_STEPS } from '../../../constants';
import View from './view';
import { sendPastferral } from '../../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { bindActionCreators } from 'redux';


const mapStateToProps = (state) => {
    const statics = state.main.ui.global.statics;
    const feeDueOptions = statics.referralFeePaymentDue;
    const feeTypesOptions = statics.referralFeeTypes;
    const feeFrequencyOptions = statics.referralFeeFrequency;
    const feeDurationOptions = statics.referralFeeDuration;
    const feeCommencementOptions = statics.referralFeeCommencement;

    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION],
        clientTypes: state.main.ui.global.statics.clientTypes,
        propertyTypes: state.main.ui.global.statics.propertyTypes,
        financingInfoTypes: state.main.ui.global.statics.financingInfoTypes,
        referralIsLoading: state.main.ui.view.opportunityCreationFlow.referralIsLoading,
        candidates: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE],
        successReferral: state.main.ui.view.opportunityCreationFlow.successReferral,
        errorReferral: state.main.ui.view.opportunityCreationFlow.errorReferral,
        user: state.main.user && state.main.user.profile
        ? state.main.user.profile
        : {},
        referralFeeTypes: state.main.ui.global.statics.referralFeeTypes,
        feeDueOptions,
        feeTypesOptions,
        feeFrequencyOptions,
        feeDurationOptions,
        feeCommencementOptions
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    sendPastferral
} , dispatch)


const ReferralPastPreview = connect(mapStateToProps, mapDispatchToProps)(View);

export { ReferralPastPreview }