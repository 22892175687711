import React, { useState, useEffect } from "react";
import { toggleSidebar } from "../../../../../../redux/actions/creators/navigation";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import NotificationsList from '../../NotificationsList'
import { withTheme } from "styled-components";
const MobileNotificationSideBar = (props) => {
    const { sidebarIsOpen, theme } = props
    const [zIndex, setZIndex] = useState(sidebarIsOpen ? 1000 : -100);
    // This is to allow the animation to work
    useEffect(() => {
        if (sidebarIsOpen) {
            setZIndex(10000);
        } else {
            setTimeout(() => {
                setZIndex(-100);
            }, 300);
        }
    }, [sidebarIsOpen]);


    return (
        <Sidebar
            defaultSidebarWidth={'100%'}
            styles={{
                root: {
                    zIndex//: sidebarIsOpen ? 1000 : -100
                },
                sidebar: {
                    zIndex: 1000,
                    backgroundColor: "#FFF",
                    position: "fixed",
                    top: 0,
                    maxWidth: '100%',
                    minWidth: '100%',
                    overlay: {
                        backgroundColor: "rgba(0,0,0,0.3)"
                    }
                }
            }
            }
            open={sidebarIsOpen}
            //onSetOpen={onSidebarSetOpen}
            sidebar={<div style={{padding: theme.spacing['5'], paddingBottom: 100}}><NotificationsList /> </div> }
            pullRight
        />
    );
}

const mapStateToProps = state => {
    return {
        sidebarIsOpen: state.main.ui.global.notifications.open
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            toggleSidebar
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTheme(MobileNotificationSideBar) )
);
