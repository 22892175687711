import React from "react";
import { Heading, Text, Flex } from "rebass";
import styled from "styled-components";
import CompanyIcon from "../../../../../assets/svg/NuOpCircleGreen.svg";
import { MEDIABREAKPOINTS } from "../../../../../theme";
import { withTranslation } from "react-i18next";
const FlexGreenContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //background-color: #E8ECF2;
    background-color: white;
    min-height: 100vh;
`;
const Head = styled(Heading)`
    font-size: 6em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 2em;
    }
`;

const Description = styled(Text)`
    font-size: 2em;
    text-align: center;
    @media (max-width: ${MEDIABREAKPOINTS.tablet}px) {
        font-size: 1em;
    }
`;

class MaintenanceMode extends React.PureComponent {
    render() {
        const { t } = this.props;
        return (
            <FlexGreenContainer>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width={[1, 1, .5, .5]}
                >
                    <img
                        alt="Maitenance"
                        src={CompanyIcon}
                        style={{
                            width: "260px",
                            paddingLeft: "1rem"
                        }}
                    />
                    <br />
                    <Head color="#00A15F">{t("title")}</Head>
                    <br />
                    <Description color="#28372E">{t("body")}</Description>
                    {/*   <CompanyName width={"130px"} height={"130px"} /> */}
                </Flex>

            </FlexGreenContainer>
        );
    }
}
const viewWithTranslation = withTranslation("maintenanceMode")(MaintenanceMode);
export default viewWithTranslation;
