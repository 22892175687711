import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../../redux/actions/creators/opportunityCreationFlow';
import { OPPORTUNITY_CREATION_STEPS } from '../../../constants';
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.opportunityCreationFlow.currentStep,
        stepData: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION],
        candidates: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
        jobType: state.main.ui.view.opportunityCreationFlow.stepData[OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE],
        professions: state.main.ui.global.statics.userTypes.filter(filteredProfessions => filteredProfessions.status !== 'pending') || [],
        clientStatusOptions:
            state.main.ui.global.statics.clientStatusTypes &&
            state.main.ui.global.statics.clientStatusTypes.options
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withTranslation("referralJobCurrent")(View)));
