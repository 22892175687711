import React, { Fragment, useState } from "react";
import { Box, Flex } from "rebass";
import Card from "../Card";
import styled, { withTheme } from "styled-components";
import { RightHandTitleLink, Copyright, AppLink } from "./styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Link from "../../Link";
import HorizontalLine from "../HorizontalLine";
import { ReactComponent as DownloadAppStore } from "../../../../../../assets/svg/DownloadAppStore.svg";
import { ReactComponent as DownloadPlayStore } from "../../../../../../assets/svg/DownloadPlayStore.svg";
import CollapsableSection from "../CollapsableSection";
import { Icon } from "../Icon";
import { Text } from "../Text";
import PopupModal from "../PopupBlurModal";

const SideHandLinks = props => {
    const { t, theme, isSigninPage, setopenRighHandFooter } = props;
    const flexBasisShouldBe50 = window.innerWidth > 768 && window.innerWidth < 1450;

    const sections = [
        {
            key: "privacyTerms",
            title: "Legal Terms",
            links: [
                {
                    key: "tac",
                    title: t("tac"),
                    href: "https://site.nuop.com/terms-and-conditions/"
                },
                {
                    key: "privacyPolicy",
                    title: t("privacyPolicy"),
                    href: "https://site.nuop.com/privacy-policy/"
                },
                {
                    key: "eula",
                    title: "EULA",
                    href: "https://site.nuop.com/end-user-license-agreement/"
                },

                {
                    key: "coc",
                    title: t("coc"),
                    href: "https://site.nuop.com/code-of-conduct/"
                },
                {
                    key: "cookies",
                    title: "Cookie Policy",
                    href: "https://site.nuop.com/cookie-policy/"
                },
                {
                    key: "",
                    title: "Do Not Sell My Personal Information",
                    href: "https://site.nuop.com/do-not-sell-my-personal-information/"
                },
                {
                    key: "",
                    title: "Statement of Accessibility",
                    href: "https://site.nuop.com/statement-of-accessibility/"
                }


            ]
        },
        {
            key: "h&s",
            title: t("h&s"),
            links: [
                {
                    key: "contact",
                    title: t("contact"),
                    href: "https://nuop.com/contact-us/"
                },
                {
                    key: "support",
                    title: t("support"),
                    href: "https://support.nuop.com/portal/en/home"
                }
            ]
        },
        /*         {
                    key: "pricing",
                    title: t("pricing"),
                    href: "https://nuop.com/site/pricing/"
                },
                {
                    key: "story",
                    title: t("story"),
                    href: "https://nuop.com/site/our-story/"
                },
                {
                    key: "howItWorks",
                    title: t("howItWorks"),
                    links: [
                        {
                            key: "takeATour",
                            title: t("takeATour"),
                            href: "https://nuop.com/site/"
                        },
                        {
                            key: "referralExchange",
                            title: t("referralExchange"),
                            href: "https://nuop.com/site/referral-exchange/"
                        },
                        {
                            key: "pathToSuccess",
                            title: t("pathToSuccess"),
                            href: "https://nuop.com/site/the-path-to-success/"
                        }
                    ]
                },
                {
                    key: "resources",
                    title: t("resources"),
                    links: [
                        {
                            key: "blog",
                            title: t("blog"),
                            href: "https://nuop.com/site/auto-draft-4/nuop-blog/"
                        },
                        {
                            key: "ambassadorProgram",
                            title: t("ambassadorProgram"),
                            href: "https://nuop.com/site/ambassador-program/"
                        }
                    ]
                } */
    ];

    const socialMediaLinks = [
        {
            key: "linkedin",
            title: "LinkedIn",
            href: "https://www.linkedin.com/company/mynuop/",
            icon: "linkedin"
        },
        {
            key: "facebook",
            title: "Facebook",
            href: "https://www.facebook.com/mynuop",
            icon: "facebook"
        },
        {
            key: "twitter",
            title: "Twitter",
            href: "https://twitter.com/mynuop",
            icon: "twitter"
        },
        {
            key: "instagram",
            title: "Instagram",
            href: "https://www.instagram.com/mynuop/",
            icon: "instagram"
        },
        {
            key: "youtube",
            title: "YouTube",
            href: "https://www.youtube.com/channel/UCRWLILThYEaq74ohzx7qHvg",
            icon: "youtube"
        }
    ];

    const collapsibleInitialState = sections
        .filter(section => Array.isArray(section.links))
        .reduce((acc, curr) => ({ ...acc, [curr.key]: true }), {});

    const [collapsibleSections, setCollapsibleSections] = useState(
        collapsibleInitialState
    );

    const onToogleCollapse = key =>
        setCollapsibleSections({
            ...collapsibleInitialState,
            [key]: !collapsibleSections[key]
        });

    const titleStyles = {
        fontSize: theme.mobileDevice ? "14px" : "14px",
        marginRight: "0.5em",
        // marginLeft: "0.5em",
        cursor: "pointer",
        color: '#929292',
        fontWeight: 700,
        transition: "all .3s ease",
        alignSelf: "flex-end",
        lineHeight: 0,
        color: '#002954'
    };

    const renderCollapsableSection = ({ key, title, links }) => (
        <CollapsableSection
            onClickCollapse={() => onToogleCollapse(key)}
            collapsed={collapsibleSections[key]}
            title={title}
            flexJustify={"flex-start"}
            titleStyles={{
                ...titleStyles
            }}
            onClick={e => { }}
            arrowDimensions={{ height: 9, width: 10 }}
            arrowColor={isSigninPage ? theme.white : theme.coolBlack}
            showArrow
            rootStyles={{
                marginBottom: theme.spacing[4],
                flexBasis: isSigninPage ? flexBasisShouldBe50 ? "50%" : "33%" : null
            }}
        >
            {links.map(({ key, title, href }) => (
                <Box mb={theme.spacing[2]}>
                    <Link
                        color={isSigninPage ? theme.white : theme.text}
                        underlineColor={
                            isSigninPage ? theme.white : theme.newBlue
                        }
                        key={key}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        fontSize="XXXS"
                    >
                        {title}
                    </Link>
                </Box>
            ))}
        </CollapsableSection>
    );

    const renderLink = ({ key, title, href }) => (
        <RightHandTitleLink
            key={key}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            isSigninPage={isSigninPage}
        >
            {title}
        </RightHandTitleLink>
    );

    const renderSections = () =>
        sections.map(section =>
            section.links
                ? renderCollapsableSection(section)
                : renderLink(section)
        );

    const renderDownloadApp = () => (
        <Flex
            justifyContent={"center"}
            my={theme.spacing[4]}
            pr={theme.spacing[2]}
        >
            <AppLink
                isSigninPage={isSigninPage}
                href="https://play.google.com/store/apps/details?id=app.nuop.com"
                target="_blank"
                rel="noopener noreferer"
            >
                <DownloadPlayStore />
            </AppLink>
            {/* TODO: Add when react native app ready */}
            <AppLink
                isSigninPage={isSigninPage}
                href="https://support.nuop.com/portal/en/kb/articles/how-to-install-nuop-to-your-mobile-device"
                target="_blank"
                rel="noopener noreferer"
            >
                <DownloadAppStore />
            </AppLink> 
        </Flex>
    );

    const renderCopyright = () => (
        <>
            <Box>
                {/** eslint-disable-next-line */}
                <Copyright
                    style={{
                        textAlign: 'center'
                    }}
                    isSigninPage={isSigninPage}
                    ariaLabel={`Copyright 2022 Nuop, Inc. ALL RIGHTS RESERVED | The Business
                    Opportunity Exchange™ | 7 Main St., Warwick, NY | NuOp™,
                    and the phrase "The Business Opportunity Exchange" are
                    trademarks of Nuop, Inc.`}
                    role="img"
                >
                    ® Copyright 2018 - 2023 Nuop, Inc. ALL RIGHTS RESERVED - US PATENT PENDING
                </Copyright>
            </Box>
        </>
    );

    const renderSeparator = (
        <HorizontalLine
            style={{ marginBottom: theme.spacing[6] }}
        ></HorizontalLine>
    );

    const renderSocialMediaLinks = () => (
        <Flex flexDirection={"column"} mt={theme.spacing[6]}>
            <Text
                color={theme.white}
                fontSize="XXXS"
                style={{ marginRight: theme.spacing[4] }}
            >
                Find us on:
            </Text>
            <Flex mt={theme.spacing[5]}>
                {socialMediaLinks.map((link, index) => (
                    <a
                        key={index}
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={link.title}
                        style={{ marginRight: theme.spacing[4] }}
                    >
                        <Icon icon={link.icon} color={theme.white} height={18} width={18} />
                    </a>
                ))}
            </Flex>
        </Flex>
    );


    return isSigninPage ? (
        <Flex
            bg={"#000"}
            mt={theme.spacing[6]}
            flexDirection={"column"}
            p={`${theme.spacing[10]} ${theme.spacing[5]}`}
        >
            {renderSections()}
            {renderDownloadApp()}
            {renderSeparator}
            {renderCopyright()}
            {renderSocialMediaLinks()}
        </Flex>
    ) : (
        <PopupModal
            open={true}
            modalContentStyles={{
                background: "transparent"
            }}
        >
            <AttentionPopStyled>
                <figure onClick={setopenRighHandFooter} >
                    <Icon icon={"close"}
                        width={16}
                        height={16}
                        color="#929292"
                    />
                </figure>
                <ModalContainer>

                    {renderSections()}
                    {renderDownloadApp()}
                    {renderCopyright()}
                </ModalContainer>
            </AttentionPopStyled>

        </PopupModal>
    );
};

const SideHandLinksView = withRouter(withTheme(withTranslation("righthandLinks")(SideHandLinks)))

export { SideHandLinksView };

const ContainerLinks = styled.div`  
 display: flex;
 flex-direction: column;
 align-items: flex-start;
`
export const AttentionPopStyled = styled.div`
    max-height: 336px;
    background: #ffffff;
    border-radius: 8px;
     padding: 20px;
     position: relative;
     & figure {
        position: absolute;
        top: -10px;
        right: -34px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 6;
        width: 20px;
        height: 20px;
        :hover {
            background: #f2f2f2;
        }
     }
`;

export const ModalContainer = styled.div`
 position: relative;
 z-index: 4;
`;



