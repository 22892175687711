import CommunityBussiness from "../../../../business/community";
import {
    SHOW_MODAL,
    CLOSE_MODAL,
    FETCH_USER_LIST_FAIL,
    FETCH_USER_LIST_SUCC,
    FETCH_USER_LIST,
    TOGGLE_SIDEBAR,
    SHOW_TOAST,
    CLOSE_TOAST,
    NAVBAR_TOUCH_NOTIFICATION,
    NAVBAR_MOREOPTIONS_OPEN,
    NAVBAR_TOOGLE_BACKBUTTON,
    OPEN_POP_UP_MODAL,
    CLOSE_POP_UP_MODAL,
    SUBSCRIBED_TO_FIREABSE,
    RERZONEUSERS_ISLOADING,
    RERZONEUSERS_SUCCESS,
    RERZONEUSERS_FAIL,
    LOCATION_UPDATE,
    NAVBAR_CURRENT_TAB,
    CREATE_DROPDOWN_CURRENT_TAB,
    SHARED_REALSTATEFOCUS_SUCCESS,
    NEWPWAVERSION_AVAILABLE,
    SET_SERVICES,
    TOGGLE_SEARCHBAR,
    SEARCHBAR_LOADING,
    SEARCHBAR_SUCCESS,
    SEARCHBAR_FAIL,
    SEARCHBAR_CLEAR,
    SEARCHBAR_CLEAR_RESULTS,
    SEARCHBAR_VALUE,
    SEARCHBAR_LOCATION_FAIL,
    SEARCHBAR_LOCATION_SUCCESS,
    SEARCHBAR_LOCATION_LOADING,
    SEARCHBAR_BLUR_VALUE,
    SEARCHBAR_SET_LOCATION_VALUE,
    SEARCHBAR_SET_LOCATIONORPROFILE,
    SEARCHBAR_FILTER_OPEN,
    SEARCHBAR_SHOW_SUGGESTIONS,
    SEARCHBAR_SET_FILTER,
    NAVBAR_TOOGLE_AVATARDROPDOWN,
    NAVBAR_TOOGLE_MOBILESIDEMENU,
    GLOBAL_ADD_USER,
    LAYOUT_BACKGROUND_COLOR,
    CHATBOT_OPEN,
    GLOBAL_STATICS_FETCH_SUCCESS,
    VERYFYBYPHONE_ISLOADING,
    VERYFYBYPHONE_SENDVERIFICATIONCODE_SUCCESS,
    VERYFYBYPHONE_VALIDATE_FAILS,
    GLOBAL_MAITENANCE_MODE,
    GLOBAL_ALLOW_ACCESS_TO_DEV,
    GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING,
    NOTIFICATIONS_BELL_FETCH_SUCCESS,
    NOTIFICATIONS_BELL_ISLOADING,
    NOTIFICATIONS_BELL_REMOVE,
    NOTIFICATIONS_BELL_ADD,
    NOTIFICATIONS_BELL_READ,
    NOTIFICATIONS_BELL_ALL_READ,
    CONNECTION_REQUESTS_FETCH_SUCCESS,
    CONNECTION_REQUESTS_ACCEPT_DECLINE,
    MAINTENANCE_ALERT,
    NAVBAR_CHAT_NEWMESSAGE,
    NAVBAR_CHAT_REMOVE_NEWMESSAGE,
    MEGASEARCH_SUCCESS,
    MEGASEARCH_FAIL,
    MEGASEARCH_RESET_FILTERS,
    MEGASEARCH_UPDATE_FILTERS,
    COMMUNITY_UPVOTE,
    COMMUNITY_DOWNVOTE,
    COMMUNITY_REPLY_POST_SUCCESS,
    SHARE_OPEN,
    SHARE_CLOSE,
    PEOPLE_SEARCHBAR_LOADING,
    PEOPLE_SEARCHBAR_SUCCESS,
    PEOPLE_SEARCHBAR_FAIL,
    PEOPLE_SEARCHBAR_CLEAR,
    RECOMMEND_MODAL_OPEN,
    RECOMMEND_MODAL_CLOSE
} from "../../../actions/constants";
import { enviroment } from "../../../../config";

const searchBar = {
    value: "",
    page: 0,
    // size: 10,
    count: 0,
    isLoading: false,
    isOpen: false,
    isBlured: true,
    results: [],
    related: [],
    hasMore: true,
    location: {},
    locationOrUser: null,
    showSuggestions: false,
    filters: {},
    openFilter: null,
    selectedFilters: {},
    ambassadors: [],
    megaSearchResults: {
        community: [],
        opportunities: [],
        users: [],
        contacts: [],
    },
    megaSearchFilters: {
        profession: [],
        location_address: "",
        company_name: "",
        member_name: "",
        date_filters: {
            // from_: 0,
            // to: 0
        }
    },
    datesOptions: [
        { id: 0, label: "24 Hours" },
        { id: 1, label: "This Week" },
        { id: 2, label: "This Month" },
        { id: 3, label: "Last Month" }
    ]
};

const shareModal = {
    open: false,
    referralInfo: {
        url: "",
        opCode: "",
        ownerUID: "",
    },
}

const peopleSearchBar = {
    isLoading: false,
    errors: [],
    results: []
}

const recommendModal = {
    open: false,
    contacts: [],
    members: [],
    isLoading: false,
    recommendList: [],
}

const initialState = {
    userTypes: [],
    newPWAVersionAvailable: false,
    forceRefresh: false,
    sidebarIsOpen: true,
    layoutBackgroundColor: "#fafafa",
    modal: null,
    context: {},
    searchBar: Object.assign({}, searchBar),
    notifications: {
        connectionRequests: [],
        turnOnBell: false,
        open: false,
        isLoading: false,
        data: []
        // Deprecated as  we are now subscribed to firebase
        // lastSeenId: null,
        // hasMore: true
    },
    navbar: {
        tabsOptionsDesktop: [
            // {
            //     selected: true,
            //     text: "Home",
            //     url: "/home",
            //     tooltip: "Home"
            // },
            // {
            //     selected: false,
            //     text: "News",
            //     url: "/news",
            //     tooltip: "Create & Read News"
            // },
            {
                selected: false,
                text: "My Ops",
                url: "/referrals",
                tooltip: "View My Opportunities"
            },
            {
                selected: false,
                text: "My Network",
                url: "/my-network",
                tooltip: "View My Network"
            },
        ],
        tabsOptionsMobile: [
            {
                selected: true,
                text: "Home",
                url: "/home"
            },
            {
                selected: false,
                text: "My Network",
                url: "/my-network"
            },
            {
                selected: false,
                text: "My Ops",
                url: "/referrals"
            },
            {
                selected: false,
                text: "Notifications",
                url: "/notifications"
            },
            {
                selected: false,
                text: "Chat",
                url: "/chat"
            }
        ],
        createDropdownOptions: [
          
            {
                selected: false,
                text: "Referral",
                // This URL switches from OLD flow to NEW flow, maybe could be done with feature flag.
                // url: "/send-referral",
                // url: enviroment === "development" ? "/op-creation" : "/send-referral",
                url: "/create-referral"
            },
            {
                selected: false,
                text: "Job",
                // This URL switches from OLD flow to NEW flow, maybe could be done with feature flag.
                // url: "/send-referral",
                // url: enviroment === "development" ? "/op-creation" : "/send-referral",
                url: "/create-job"
            },
            {
                selected: false,
                text: "Intro",
                // This URL switches from OLD flow to NEW flow, maybe could be done with feature flag.
                // url: "/send-referral",
                // url: enviroment === "development" ? "/op-creation" : "/send-referral",
                url: "/create-introduction"
            },
            {
                selected: false,
                text: "Post",
                url: "/general-post"
            },
            {
                selected: false,
                text: "Invitation",
                url: "/send-invitation"
            },
            {
                selected: false,
                text: "Endorsement",
                url: "/send-endorsement"
            },
            {
                selected: false,
                text: "Gratitude",
                url: "/send-gratitude"
            },
        ],

        loading: false,
        errors: [],
        avatarDropDownOpen: false,
        mobileSideMenuOpen: false,
        moreOptionsMenuOpen: false,
        backButton: {
            enabled: false,
            action: () => { }
        },
        chatNewMessages: {}
    },
    search: {
        loading: false,
        errors: [],
        data: []
    },
    toast: {
        open: false,
        title: "",
        message: "",
        type: "error"
    },
    rerZoneUsers: {
        isLoading: false,
        users: [],
        errors: []
    },
    users: {
        isLoading: false,
        data: [],
        errors: []
    },
    realStateFocus: {},
    services: {
        subscribedToFirebase: false,
        subscribed: false,
        center: null,
        geolocationAvailiable: false,
        geolocationRealtimeSubscribed: false,
        geolocationProfileSubscribed: false,
        geolocationInitialized: false,
        geolocationError: false,
        geolocationLoading: true,
        getConnectedLoaded: false,
        getConnectedBrowserLoaded: false
    },
    chatBotOpen: false,
    verifyByPhone: {
        isLoading: false,
        verificationId: null,
        errors: []
    },
    statics: {},
    maitenanceMode: false,
    maintenanceAlert: false,
    shareModal: Object.assign({}, shareModal),
    peopleSearchBar: Object.assign({}, peopleSearchBar),
    allowAccessToDev: {
        isLoading: false,
        result: false,
    },
    recommendModal: Object.assign({}, recommendModal),
};

function globalReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_MAITENANCE_MODE: {
            const _state = {
                ...state
            };
            _state.maitenanceMode = action.payload.maitenanceMode;
            return _state;
        }
        case VERYFYBYPHONE_ISLOADING: {
            const _state = {
                ...state
            };
            _state.verifyByPhone.isLoading = action.payload.isLoading;
            return _state;
        }
        case VERYFYBYPHONE_SENDVERIFICATIONCODE_SUCCESS: {
            const _state = {
                ...state
            };
            _state.verifyByPhone.verificationId = action.payload.verificationId;
            return _state;
        }
        case VERYFYBYPHONE_VALIDATE_FAILS: {
            const _state = {
                ...state
            };
            _state.verifyByPhone.errors = action.payload.errors;
            return _state;
        }
        case GLOBAL_STATICS_FETCH_SUCCESS: {
            const _state = {
                ...state
            };
            _state.statics = { ...action.payload.statics };
            return _state;
        }
        case LAYOUT_BACKGROUND_COLOR: {
            const _state = {
                ...state
            };
            _state.layoutBackgroundColor = action.payload.layoutBackgroundColor;
            return _state;
        }
        case SHOW_TOAST: {
            const _state = {
                ...state
            };
            _state.toast = {
                open: true,
                title: action.payload.title,
                message: action.payload.message,
                type: action.payload.type
            };
            return _state;
        }
        case CLOSE_TOAST: {
            const _state = {
                ...state
            };
            _state.toast = {
                open: false
            };
            return _state;
        }
        case NAVBAR_TOOGLE_AVATARDROPDOWN: {
            const _state = {
                ...state
            };
            _state.navbar = {
                ..._state.navbar,
                avatarDropDownOpen: action.payload.avatarDropDownOpen
            };
            return _state;
        }
        case NAVBAR_TOOGLE_MOBILESIDEMENU: {
            const _state = {
                ...state
            };
            _state.navbar = {
                ..._state.navbar,
                mobileSideMenuOpen: action.payload.mobileSideMenuOpen
            };
            return _state;
        }
        case OPEN_POP_UP_MODAL: {
            const _state = {
                ...state
            };
            _state.bluerred = true;
            return _state;
        }
        case CLOSE_POP_UP_MODAL: {
            const _state = {
                ...state
            };
            _state.bluerred = false;
            return _state;
        }
        case SHOW_MODAL: {
            const _state = {
                ...state
            };
            _state.modal = action.modal;
            return _state;
        }
        case CLOSE_MODAL: {
            const _state = {
                ...state
            };
            _state.modal = null;
            return _state;
        }
        case TOGGLE_SIDEBAR: {
            const _state = {
                ...state
            };
            if (action.payload === "close") {
                _state.notifications.open = false;
            } else if (action.payload === "open") {
                _state.notifications.open = true;
            } else {
                _state.notifications.open = !_state.notifications.open;
            }
            return _state;
        }
        case FETCH_USER_LIST_FAIL: {
            const _state = {
                ...state
            };
            _state.search.loading = false;
            _state.search.errors = [action.errors];
            return _state;
        }
        case FETCH_USER_LIST_SUCC: {
            const _state = {
                ...state
            };
            _state.search.loading = false;
            _state.search.data = action.payload;
            return _state;
        }
        case FETCH_USER_LIST: {
            const _state = {
                ...state
            };
            _state.search.loading = true;
            return _state;
        }
        case NAVBAR_TOUCH_NOTIFICATION: {
            const _state = {
                ...state
            };
            _state.notifications.turnOnBell = action.payload.turnOnBell;
            return _state;
        }
        case NAVBAR_MOREOPTIONS_OPEN: {
            const _state = {
                ...state
            };
            _state.navbar.moreOptionsMenuOpen =
                action.payload.moreOptionsMenuOpen;
            return _state;
        }
        case SUBSCRIBED_TO_FIREABSE: {
            const _state = {
                ...state
            };
            _state.subscribedToFirebase = action.payload.subscribedToFirebase;
            return _state;
        }
        case LOCATION_UPDATE: {
            const _state = {
                ...state
            };
            _state.context[action.payload.name] = action.payload.value;
            _state.context[action.payload.selectionName] =
                action.payload.location;
            _state.context[action.payload.keyName] = action.payload.key;
            return _state;
        }

        case RERZONEUSERS_ISLOADING: {
            const _state = {
                ...state
            };
            _state.rerZoneUsers.isLoading = action.payload.isLoading;
            return _state;
        }

        case RERZONEUSERS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.rerZoneUsers.users = [...action.payload.users];
            _state.rerZoneUsers.isLoading = false;
            return _state;
        }
        case RERZONEUSERS_FAIL: {
            const _state = {
                ...state
            };
            _state.rerZoneUsers.errors = [action.errors];
            _state.rerZoneUsers.isLoading = false;
            return _state;
        }
        case NAVBAR_CURRENT_TAB: {
            const _state = {
                ...state
            };
            _state.navbar.tabsOptionsDesktop = _state.navbar.tabsOptionsDesktop.map(
                option =>
                    option.text === action.payload.currentTab
                        ? {
                            ...option,
                            selected: true
                        }
                        : {
                            ...option,
                            selected: false
                        }
            );
            _state.navbar.tabsOptionsMobile = _state.navbar.tabsOptionsMobile.map(
                option =>
                    option.text === action.payload.currentTab
                        ? {
                            ...option,
                            selected: true
                        }
                        : {
                            ...option,
                            selected: false
                        }
            );
            return _state;
        }
        case CREATE_DROPDOWN_CURRENT_TAB: {
            const _state = {
                ...state
            };
            _state.navbar.createDropdownOptions = _state.navbar.createDropdownOptions.map(
                option =>
                    option.text === action.payload.currentDropdownOption
                        ? {
                            ...option,
                            selected: true
                        }
                        : {
                            ...option,
                            selected: false
                        }
            );
            return _state;
        }
        case SHARED_REALSTATEFOCUS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.realStateFocus = action.payload.realStateFocus;
            return _state;
        }
        case NEWPWAVERSION_AVAILABLE: {
            const _state = {
                ...state
            };
            _state.newPWAVersionAvailable =
                action.payload.newPWAVersionAvailable;
            _state.forceRefresh = action.payload.forceRefresh;
            return _state;
        }
        case SET_SERVICES: {
            const _state = {
                ...state
            };
            _state.services = {
                ..._state.services,
                ...action.payload.services
            };
            return _state;
        }
        case TOGGLE_SEARCHBAR: {
            const _state = {
                ...state
            };
            _state.searchBar.isOpen = action.payload.isOpen;
            return _state;
        }
        case SEARCHBAR_LOADING: {
            const _state = {
                ...state
            };
            _state.searchBar.isLoading = action.payload.loading;
            return _state;
        }
        case SEARCHBAR_SUCCESS: {
            const _state = {
                ...state
            };
            _state.searchBar.ambassadors = action.payload.ambassadors
                ? [...action.payload.ambassadors]
                : [..._state.searchBar.ambassadors];
            const newResults = action.payload.results && action.payload.results;
            _state.searchBar.filters = action.payload.filters
                ? { ...action.payload.filters }
                : _state.searchBar.filters;
            _state.searchBar.results = newResults
                ? [..._state.searchBar.results, ...newResults]
                : [..._state.searchBar.results];
            _state.searchBar.related = action.payload.related
                ? [..._state.searchBar.related, ...action.payload.related]
                : [..._state.searchBar.related];
            _state.searchBar.count =
                action.payload.count && action.payload.count.value;
            _state.searchBar.hasMore =
                action.payload.results &&
                action.payload.results.length === action.payload.size;
            _state.searchBar.isLoading = false;
            _state.searchBar.page = _state.searchBar.page + 1;
            _state.searchBar.showSuggestions = false;
            return _state;
        }
        case SEARCHBAR_FAIL: {
            const _state = {
                ...state
            };
            _state.searchBar.errors = action.payload.errors
                ? [...action.payload.errors]
                : [];
            _state.searchBar.isLoading = false;
            return _state;
        }
        case SEARCHBAR_LOCATION_LOADING: {
            const _state = {
                ...state
            };
            _state.searchBar.isLocationLoading = action.payload.isLoading;
            return _state;
        }
        case SEARCHBAR_LOCATION_SUCCESS: {
            const _state = {
                ...state
            };
            _state.searchBar.locations = action.payload.locations
                ? [...action.payload.locations]
                : [];
            _state.searchBar.isLocationLoading = false;
            _state.searchBar.showSuggestions = true;
            return _state;
        }
        case SEARCHBAR_LOCATION_FAIL: {
            const _state = {
                ...state
            };
            _state.searchBar.errors = action.payload.errors
                ? [...action.payload.errors]
                : [];
            _state.searchBar.isLocationLoading = false;
            return _state;
        }
        case SEARCHBAR_SET_LOCATION_VALUE: {
            const _state = {
                ...state
            };
            _state.searchBar.location = action.payload.location
                ? action.payload.location
                : {};
            _state.searchBar.locations = [];
            _state.searchBar.isLocationLoading = false;
            _state.searchBar.value = action.payload.location.long_name;
            _state.searchBar.showSuggestions = false;
            return _state;
        }
        case SEARCHBAR_SET_LOCATIONORPROFILE: {
            const _state = {
                ...state
            };
            _state.searchBar.locationOrUser = action.payload.locationOrUser;

            return _state;
        }
        case SEARCHBAR_CLEAR: {
            const _state = {
                ...state
            };
            _state.searchBar = Object.assign({}, searchBar, {
                value: _state.searchBar.value
                // isOpen: _state.searchBar.isOpen,
                // isBlured: _state.searchBar.isBlured,
                // isLoading: _state.searchBar.isLoading,
                // isLocationLoading: _state.searchBar.isLocationLoading
            });
            _state.searchBar.isLoading = false;
            _state.searchBar.locationOrUser = null;
            return _state;
        }
        case SEARCHBAR_CLEAR_RESULTS: {
            const _state = {
                ...state
            };
            _state.searchBar.results = [];
            _state.searchBar.ambassadors = [];
            _state.searchBar.related = [];
            return _state;
        }
        case SEARCHBAR_VALUE: {
            const _state = {
                ...state
            };
            _state.searchBar.value = action.payload.value;
            _state.searchBar.results = [];
            _state.searchBar.ambassadors = [];
            _state.searchBar.related = [];
            _state.searchBar.page = 0;
            _state.searchBar.count = 0;
            _state.searchBar.hasMore = false;
            _state.searchBar.isLoading = false;
            _state.searchBar.showSuggestions = false;
            _state.searchBar.locationOrUser = null;
            _state.searchBar.location = {};
            _state.searchBar.selectedFilters = {};
            return _state;
        }
        case SEARCHBAR_BLUR_VALUE: {
            const _state = {
                ...state
            };
            _state.searchBar.isBlured = action.payload.value
                ? action.payload.value
                : false;
            return _state;
        }
        case SEARCHBAR_FILTER_OPEN: {
            const _state = {
                ...state
            };
            _state.searchBar.openFilter = action.payload.openFilter;
            return _state;
        }
        case SEARCHBAR_SET_FILTER: {
            const _state = {
                ...state
            };
            _state.searchBar.selectedFilters = {
                ..._state.searchBar.selectedFilters
            };
            _state.searchBar.selectedFilters[action.payload.filterName] = {
                ...action.payload.selectedFilters
            };
            return _state;
        }

        case SEARCHBAR_SHOW_SUGGESTIONS: {
            const _state = {
                ...state
            };
            _state.searchBar.showSuggestions = action.payload.showSuggestions;
            return _state;
        }
        case MEGASEARCH_SUCCESS: {
            const _state = {
                ...state
            };
            _state.searchBar.megaSearchResults = action.payload;
            _state.searchBar.isLoading = false;
            return _state;
        }
        case MEGASEARCH_FAIL: {
            const _state = {
                ...state
            };
            _state.searchBar.errors = action.payload.errors
                ? [...action.payload.errors]
                : [];
            _state.searchBar.isLoading = false;
            return _state;
        }
        case MEGASEARCH_UPDATE_FILTERS: {
            const megaSearchFilters = {
                ...state.searchBar.megaSearchFilters,
                ...action.payload.filters
                //[action.payload.key]: action.payload.value
            };

            const _state = {
                ...state,
                searchBar: {
                    ...state.searchBar,
                    megaSearchFilters
                }
            };

            return _state;
        }
        case MEGASEARCH_RESET_FILTERS: {
            const { megaSearchFilters } = searchBar;
            const _state = {
                ...state,
                searchBar: {
                    ...state.searchBar,
                    megaSearchFilters
                }
            };
            return _state;
        }
        case SHARE_OPEN: {
            const _state = {
                ...state
            };
            _state.shareModal.open = true;
            _state.shareModal.referralInfo = action.payload;
            return _state;
        }
        case SHARE_CLOSE: {
            const _state = {
                ...state
            };
            _state.shareModal.open = false;
            _state.shareModal.referralInfo = Object.assign(
                {},
                shareModal.referralInfo
            );
            return _state;
        }
        case RECOMMEND_MODAL_OPEN: {
            const _state = {
                ...state
            };
            _state.recommendModal.open = true;
            _state.recommendModal.recommendList = [];
            _state.recommendModal.contacts = [];
            _state.recommendModal.members = [];
            _state.recommendModal.isLoading = false;

            return _state;
        }

        case RECOMMEND_MODAL_CLOSE: {
            const _state = {
                ...state
            };

            _state.recommendModal.open = false;
            _state.recommendModal.recommendList = [];
            _state.recommendModal.contacts = [];
            _state.recommendModal.members = [];
            _state.recommendModal.isLoading = false;
            return _state;
        }

        case PEOPLE_SEARCHBAR_LOADING: {
            const _state = {
                ...state
            };
            _state.peopleSearchBar.isLoading = action.payload.loading;
            return _state;
        }
        case PEOPLE_SEARCHBAR_SUCCESS: {
            const _state = {
                ...state
            };
            const { results, contacts } = action.payload;
            _state.peopleSearchBar.results = results;
            _state.peopleSearchBar.contacts = contacts;
            _state.peopleSearchBar.isLoading = false;
            return _state;
        }
        case PEOPLE_SEARCHBAR_FAIL: {
            const _state = {
                ...state
            };
            _state.peopleSearchBar.errors = action.payload.errors
                ? [...action.payload.errors]
                : [];
            _state.peopleSearchBar.isLoading = false;
            return _state;
        }
        case PEOPLE_SEARCHBAR_CLEAR: {
            const _state = {
                ...state
            };
            _state.peopleSearchBar = Object.assign(
                {},
                peopleSearchBar
            );
            return _state;
        }
        case COMMUNITY_UPVOTE: {
            const _state = {
                ...state
            };

            const postIndex = _state.searchBar.megaSearchResults.community.findIndex(
                p => p._id === action.payload.postId
            );

            if (postIndex !== -1) {
                const newP = CommunityBussiness.upvote(
                    {
                        ..._state.searchBar.megaSearchResults.community[
                        postIndex
                        ]
                    },
                    action.payload.uid
                );
                _state.searchBar.megaSearchResults.community[postIndex] = newP;
            }

            return _state;
        }

        case COMMUNITY_DOWNVOTE: {
            const _state = {
                ...state
            };
            const postIndex = _state.searchBar.megaSearchResults.community.findIndex(
                p => p._id === action.payload.postId
            );

            if (postIndex !== -1) {
                const newP = CommunityBussiness.downVote(
                    {
                        ..._state.searchBar.megaSearchResults.community[
                        postIndex
                        ]
                    },
                    action.payload.uid
                );
                _state.searchBar.megaSearchResults.community[postIndex] = newP;
            }

            return _state;
        }
        case COMMUNITY_REPLY_POST_SUCCESS: {
            const _state = {
                ...state
            };

            _state.searchBar.megaSearchResults.community = _state.searchBar.megaSearchResults.community.map(
                post => {
                    let newPost = { ...post };
                    if (post._id === action.payload.postId)
                        newPost = CommunityBussiness.replyPost(
                            newPost,
                            action.payload.reply
                        );
                    return newPost;
                }
            );

            return _state;
        }
        case NAVBAR_CHAT_NEWMESSAGE: {
            const _state = {
                ...state
            };
            _state.navbar = {
                ..._state.navbar,
                chatNewMessages: {
                    ..._state.navbar.chatNewMessages,
                    [action.payload.chatNewMessages]: true
                }
            };
            return _state;
        }
        case NAVBAR_CHAT_REMOVE_NEWMESSAGE: {
            const _state = {
                ...state
            };
            delete _state.navbar.chatNewMessages[
                action.payload.chatNewMessages
            ];
            return _state;
        }
        case NAVBAR_TOOGLE_BACKBUTTON: {
            const _state = {
                ...state
            };
            _state.navbar.backButton = {
                enabled: action.payload.enabled,
                action: action.payload.action
            };
            return _state;
        }
        case GLOBAL_ALLOW_ACCESS_TO_DEV: {
            const _state = {
                ...state
            };
            _state.allowAccessToDev.isLoading = false;
            _state.allowAccessToDev.result = action.payload.allowAccessToDev;
            return _state;
        }
        case GLOBAL_ALLOW_ACCESS_TO_DEV_LOADING: {
            const _state = {
                ...state
            };
            _state.allowAccessToDev.isLoading = action.payload.isLoading;
            return _state;
        }
        case GLOBAL_ADD_USER: {
            const _state = {
                ...state
            };
            _state.users.data = [
                ..._state.users.data.filter(
                    user => user.uid !== action.payload.uid
                ),
                { ...action.payload.user, uid: action.payload.uid }
            ];
            _state.users.isLoading = false;
            return _state;
        }
        case CHATBOT_OPEN: {
            const _state = {
                ...state
            };
            _state.chatBotOpen = action.payload.chatBotOpen;
            return _state;
        }
        case NOTIFICATIONS_BELL_ISLOADING: {
            const _state = {
                ...state
            };
            _state.notifications.isLoading = action.payload.isLoading;
            return _state;
        }
        case NOTIFICATIONS_BELL_FETCH_SUCCESS: {
            const _state = {
                ...state
            };
            _state.notifications = {
                ..._state.notifications,
                isLoading: false,
                // Previous notifications with paginations,
                // data: [..._state.notifications.data, ...action.payload.notifications],
                // Now that we are subscribed we dont require pagination
                data: [...action.payload.notifications]
                // lastSeenId: action.payload.lastSeenId,
                // hasMore: action.payload.hasMore
            };
            return _state;
        }
        case NOTIFICATIONS_BELL_ADD: {
            const _state = {
                ...state
            };
            _state.notifications = {
                ..._state.notifications,
                isLoading: false,
                data: [
                    ...action.payload.notification,
                    ..._state.notifications.data
                ]
            };
            return _state;
        }
        case NOTIFICATIONS_BELL_REMOVE: {
            const _state = {
                ...state
            };

            _state.notifications = {
                ..._state.notifications,
                connectionRequests: _state.notifications.connectionRequests
                    ? _state.notifications.connectionRequests.filter(
                        noti => noti.id !== action.payload.notificationId
                    )
                    : [],
                data: _state.notifications.data
                    ? _state.notifications.data.filter(
                        noti => noti.id !== action.payload.notificationId
                    )
                    : []
            };


            return _state;
        }
        case CONNECTION_REQUESTS_FETCH_SUCCESS: {
            const _state = {
                ...state
            };

            _state.notifications = {
                ..._state.notifications,
                connectionRequests: [
                    ..._state.notifications.connectionRequests,
                    ...action.payload.connectionRequests
                ]
            };
            return _state;
        }
        case NOTIFICATIONS_BELL_READ: {
            const _state = {
                ...state
            };
            _state.notifications = {
                ..._state.notifications,
                data: _state.notifications.data.map(noti =>
                    noti.id === action.payload.notificationId
                        ? {
                            ...noti,
                            read: action.payload.read
                        }
                        : noti
                )
            };
            return _state;
        }
        case NOTIFICATIONS_BELL_ALL_READ: {
            const _state = {
                ...state
            };
            _state.notifications = {
                ..._state.notifications,
                data: _state.notifications.data.map(noti => ({
                    ...noti,
                    read: action.payload.read
                }))
            };
            return _state;
        }
        case CONNECTION_REQUESTS_ACCEPT_DECLINE: {
            const _state = {
                ...state
            };
            _state.notifications = {
                ..._state.notifications,
                connectionRequests: _state.notifications.connectionRequests.map(
                    noti =>
                        noti.id === action.payload.notificationId
                            ? {
                                ...noti,
                                status: action.payload.status
                            }
                            : noti
                )
            };
            return _state;
        }
        case MAINTENANCE_ALERT: {
            const _state = {
                ...state
            };
            _state.maintenanceAlert = action.payload.maintenanceAlert;
            return _state;
        }
        default:
            return state;
    }
}

export default globalReducer;
