import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../redux/actions/creators/recommendationCreation';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.recommendationCreationReducer.currentStep,
        stepData: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
        newContact: state.main.ui.view.recommendationCreationReducer.newContact,
        professions: state.main.ui.global.statics.userTypes || [],
        pastJob: state.main.ui.view.recommendationCreationReducer.stepData['PAST_JOB'],
        opType: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP],
        jobType: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE] ? state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE].jobType.value : '',
        stepKey: OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION,
        thisContactAlreadyExist: state.main.ui.view.recommendationCreationReducer.errorStoringSavingContact,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION, data)),
      //  setNewContacts: data => dispatch()
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("sendReferral")(View));