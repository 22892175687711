import React, { useState } from 'react'
import { withTheme } from 'styled-components';
import { MenuItemContainer } from '../../ProfileMenu/styles';
import { MobileSidebarLabel } from './styles';
import { Icon } from '../../../../../ui/presentation/atoms/shared/Icon';

const MenuSideBarItem = ({ theme, icon, text, onClick = () => {}, size }) => {
    const [hovered, setHovered] = useState(false);
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    const iconWidth = size === "small" ? "14px" : "18px";
    const iconHeight = size === "small" ? "14px" : "18px";;
    return (
        <MenuItemContainer
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Icon
                icon={icon}
                color={hovered ? theme.newBlue : theme.darkGray}
                width={iconWidth}
                height={iconHeight}
            />
            <MobileSidebarLabel size={size}>{text}</MobileSidebarLabel>
        </MenuItemContainer>
    );
}

export default withTheme(MenuSideBarItem);