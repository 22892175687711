// DEPRECATED ONE SIGNAL IMPLEMENTATION
// import OneSignal from "react-onesignal";
// import config from "../../../../config"

// export const initializeOneSignal = (uid) => {
//     return async (dispatch, getState) => {
//         try {
//             await OneSignal.init({
//                 appId: config.oneSignal.appId,
//                 //allowLocalhostAsSecureOrigin: config.enviroment === "development"
//             });
//             await OneSignal.showSlidedownPrompt()
//             await OneSignal.setExternalUserId(uid);
//         } catch (error) {
//             console.error("Error with One Signal", error)
//         }
//     }
// }

//import { handleError } from "../modal";
/* export const settingsIsLoading = isLoading => ({
    type: SETTINGS_ISLOADING,
    payload: {
        isLoading: isLoading
    }
}); */

export const getCurrentPushToken = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (firebase.fcmSupported()) {
                const currentToken = await firebase.getPushToken(uid);
                if (currentToken) {
                    await firebase.setDevicePushToken(uid, currentToken);
                } else {
                    // Show permission request.
                    console.log(
                        "No Instance ID token available. Request permission to generate one."
                    );
                    await firebase.requestPermission();
                    const currentToken = await firebase.getPushToken(uid);
                    await firebase.setDevicePushToken(uid, currentToken);
                }
            } else return;
        } catch (errors) {
            try {
                console.log("push error1", errors);
                await firebase.requestPermission();
                const currentToken = await firebase.getPushToken(uid);
                await firebase.setDevicePushToken(uid, currentToken);
            } catch (error) {
                console.log("push error2", error);
            }

            /* console.log("getCurrentPushToken", errors);
            dispatch(handleError(errors)); */
        }
    };
};

// export const onMessage = () => {
//     return async (dispatch, getState, { firebase, cloudfunctions }) => {
//         try {
//             firebase.onMessageListener().then((payload) => {
//                 console.log("onMessage", payload);
//             })
//         } catch (errors) {
//             console.log("onMessage", errors);
//             // dispatch(handleError(errors));
//         }
//     };
// }