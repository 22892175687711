import React, { Component } from "react";
import { Flex } from "rebass";
import CompanyIcon from "../../../../../assets/svg/NuOpCircleGreen.svg";
import Spinner from "../../../../../assets/svg/Spinner";

class SplashScreen extends Component {
    state = {
        colorIndex: 0,
        dx: true
    };

    render() {
        return (
            <Flex
                flexDirection='column'
                width="100%"
                style={{ height: "100vh" }}
                alignContent="center"
                alignItems="center"
                justifyContent="center"
            >
                <img alt='splash' src={CompanyIcon} width={"20%"} height={"20%"} /* color={greenShades[this.state.colorIndex]} */ />
                <Spinner color={"#062619"}/>
            </Flex>
        );
    }
}

export default SplashScreen;
