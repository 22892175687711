import styled, {css} from 'styled-components';

//TODO: Make this Input be reusable (alter colors and states by props)
export const BlueInputField = styled.div `
background: ${props => `url(${props.leftIcon}) no-repeat scroll 15px 13px`};
font-size: 16px;
height: 3em;
outline: none;
padding: 1em;
padding-left: ${props => props.leftIcon ? '2.5em;' : '1em'};
margin: 0;
border: 0;
width: 100%;
box-sizing: border-box;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 3px;
background-color:  ${props => props.theme.primary};
color: #DDEDFF;
position: relative;

::-webkit-input-placeholder { /* Edge */
    ${props => props.error ? css`color: #BD4C49;` : css`color: ${props.theme.disabled};`}
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    ${props => props.error ? css`color: #BD4C49;` : css`color: ${props.theme.disabled};`}
}

::placeholder {
    ${props => props.error ? css`color: #BD4C49;` : css`color: ${props.theme.disabled};`}
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
::-webkit-calendar-picker-indicator {
    ${props => props.validation && props.validation.isInvalid ? css`background-color: #FBE3E4;` : css`background-color: ${props.theme.primary};`} 
    }
::-webkit-inner-spin-button { display: none; }

${props => props.error ? css`background-color: #FBE3E4;` : css`background-color: ${props.theme.primary}`}
${props => props.error ? css`color: #BD4C49;` : css`color: ${props.theme.disabled};`}

`;

export const PlaceHolder = styled.div `
    background: ${props => `url(${props.leftIcon}) no-repeat scroll 15px 13px`};
    font-size: 16px;
    height: 3em;
    outline: none;
    padding: 1em;
    padding-left: ${props => props.leftIcon ? '2.5em;' : '1em'};
    margin: 0;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    background-color: ${props => props.theme.primary};
    color: #DDEDFF;
    ${props => props.validation && props.validation.isInvalid ? css`background-color: #FBE3E4;` : css`background-color: ${props.theme.primary}`};
    ${props => props.validation && props.validation.isInvalid ? css`color: #BD4C49;` : css`color: #CAE6D4;`}
`;
