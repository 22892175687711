import React, { useState } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { Container, Tab, Badge, NumberBadge } from "./styles";

const Tabs = (props) => {
    const { setSection, currentSection, sections } = props

    sections[0].first = true;
    sections[sections.length - 1].last = true;

    console.log('TABING', sections)

    return (
        <Container>
            {
                sections.map(section => {
                    return (
                        <Tab
                            key={section.key}
                            onClick={() => { setSection(section.key) }}
                            first={section.first} last={section.last}
                            current={currentSection === section.key}
                            className={`tab-${section.key}`}
                        >
                            {section.label}
                            {section.notificationEnabled && <Badge />}
                            {section.showBadgeNumber && <NumberBadge color={section.badgeColor}>{section.badgeNumber}</NumberBadge>}
                        </Tab>
                    )
                })
            }
        </Container>
    );
};

export default withTheme(withRouter(Tabs))