import MobileNavBar from './MobileNavBar/Topbar';
import DesktopNavBar from './DesktopNavBar/DesktopNavBar';
import React from 'react';
import { withTheme } from 'styled-components';
import UserVerifiedbadge from '../UserVerifiedbadge'


const NavBar = props => {
    return (
        <React.Fragment>
            {props.theme.width <= props.theme.mediaBreakpoints.tablet ? <MobileNavBar {...props} /> : <DesktopNavBar {...props} />}
            <UserVerifiedbadge />
        </React.Fragment>
    )
}


export default withTheme(NavBar)
