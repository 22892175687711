import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateContactModal from "./view";
import {
    createContact,
    updateContact,
    hideCreateContactErrorModal
} from "../../../../../../redux/actions/creators/myNetwork";
import { STATE_TYPES } from "../../../../../../assets/strings";
const mapStateToProps = state => {
    const { userTypes } = state.main.ui.global.statics;
    // states as label and value and labels are abbreviations
    const professionOptions =
        userTypes &&
        userTypes.map(item => {
            return {
                key: item.key,
                label: item.name
            };
        });

    // remove first element from STATES_TYPES array because it is not a state,
    // and pass it to the component as a prop

    return {
        states: STATE_TYPES.slice(1),
        professionOptions,
        openErrorModal: state.main.ui.view.myNetwork.createContactError.open,
        errorMessage: state.main.ui.view.myNetwork.createContactError.message
        // contactData: state.main.ui.view.myNetwork.contactData,
        // contactFormEnabled: state.main.ui.view.myNetwork.contactFormEnabled,
        // contactProfileEnabled:
        //     state.main.ui.view.myNetwork.contactProfileEnabled,
        // isLoading: state.main.ui.view.myNetwork.contactsIsLoading
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            createContact,
            updateContact,
            hideCreateContactErrorModal
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContactModal);
