import React, { useState, useMemo } from "react";
import MultiSelect, { components, createFilter } from "react-select";
import { withTheme } from "styled-components";
import { Box, Flex } from "rebass";
import ErrorCard from "../../Error/Error";
import { InputLabel, multiSelectStyles, NeedUpgradeLabel, UpgradeButton } from "./styles";
import { Text } from "../../Text";
import SquareButton from "../../Buttons/SquareButton2";
import { theme } from "../../../../../../../theme";
import { Link } from "react-router-dom";

const Control = ({ children, ...props }) => {
    const { leftIcon: LeftIcon, nuopTheme: theme } = props.selectProps;

    return (
        <components.Control {...props}>
            {LeftIcon && (
                <span style={{ marginLeft: "1rem" }}>
                    <LeftIcon color={props.isFocused ? theme.newBlue : theme.taupeGray} />
                </span>
            )}
            {children}
        </components.Control>
    );
}

const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0;
    const { maxMultiSelectCount, setMaxAmountMessageShouldRender } = props.selectProps;
    if (!maxMultiSelectCount) {
        return (
            <components.Menu {...props}>
                {props.children}
            </components.Menu>
        );
    }

    setMaxAmountMessageShouldRender(optionSelectedLength === maxMultiSelectCount);
    return optionSelectedLength < maxMultiSelectCount ? (
        <components.Menu {...props}>{props.children}</components.Menu>
    ) : null;
};

const groupStyles = {
    borderTop: "1px solid #e6e6e6",
    color: "#000",
    padding: '5px 0px',
    display: 'flex',
};

const Option = (props) => {
    const { needUpgrade } = props.data;
    return <components.Option {...props}>
        {props.children}
        {needUpgrade && <NeedUpgradeLabel style={{ color: theme.shamRockGreen }}>
            {needUpgrade}
        </NeedUpgradeLabel>}
    </components.Option>
}

const GroupHeading = (props) => {
    const { upgradeHeading } = props.data;

    return <div style={groupStyles}>
        <components.GroupHeading {...props} />
        {upgradeHeading && <Link style={{ textDecoration: 'none' }} to={"/settings?section=subscription"}>
            <UpgradeButton>
                Upgrade
            </UpgradeButton>
        </Link>}
    </div>
}

const Select = withTheme(props => {
    const {
        theme,
        type,
        required,
        value,
        name,
        innerRef,
        label,
        placeholder,
        icon,
        id,
        labelStyles,
        inputStyles,
        options,
        onChange,
        rightIcon,
        leftIcon,
        height,
        error,
        rightIconStyles,
        multiSelectValue,
        maxMultiSelectCount,
        renderMaxAmountMessage,
        ...other
    } = props;
    const [maxAmountMessageShouldRender, setMaxAmountMessageShouldRender] = useState(false);

    const onClickOption = option => {
        if (onChange) onChange(name, option);
    };

    const isValidNewOption = (inputValue, selectValue) =>
        inputValue.length > 0 && selectValue.length < maxMultiSelectCount;

    return (
        <React.Fragment>
            {label && (
                <InputLabel for={id} style={{ ...labelStyles }}>
                    {label}
                </InputLabel>
            )}

            <MultiSelect
                filterOption={createFilter({ matchFrom: "start" })}
                isMulti
                // onKeyDown={handleKeyPress}
                className="disable-select"
                onChange={onClickOption}
                value={multiSelectValue}
                {...other}
                options={options}
                // style={{ width: "100%", ...inputStyles }}
                isValidNewOption={isValidNewOption}
                maxMultiSelectCount={maxMultiSelectCount}
                setMaxAmountMessageShouldRender={
                    setMaxAmountMessageShouldRender
                }
                components={{ Control, Menu, GroupHeading, Option }}
                styles={multiSelectStyles(inputStyles)}
                required={required}
                ref={innerRef}
                placeholder={placeholder ? placeholder : ""}
                name={name}
                id={id}
                leftIcon={leftIcon}
                nuopTheme={theme}
            />
            {maxAmountMessageShouldRender &&
                renderMaxAmountMessage &&
                renderMaxAmountMessage()}
        </React.Fragment>
    );
});

export default props => {
    return (
        <React.Fragment>
            <Select {...props} />
            <ErrorCard
                id="error-card"
                material={props.material}
                name={props.name}
                error={props.error}
                errorStyles={props.errorStyles ? props.errorStyles : {}}
            />
        </React.Fragment>
    );
};
