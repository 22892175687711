import {
    NAVBAR_TOUCH_NOTIFICATION,
    NAVBAR_CURRENT_TAB,
    CREATE_DROPDOWN_CURRENT_TAB,
    NAVBAR_TOOGLE_AVATARDROPDOWN,
    NAVBAR_TOOGLE_MOBILESIDEMENU,
    NAVBAR_MOREOPTIONS_OPEN,
    NAVBAR_TOOGLE_BACKBUTTON,
    NOTIFICATIONS_BELL_FETCH_SUCCESS,
    NOTIFICATIONS_BELL_ISLOADING,
    NOTIFICATIONS_BELL_REMOVE,
    NOTIFICATIONS_BELL_ADD,
    CONNECTION_REQUESTS_FETCH_SUCCESS,
    NOTIFICATIONS_BELL_READ,
    NOTIFICATIONS_BELL_ALL_READ,
    CONNECTION_REQUESTS_ACCEPT_DECLINE,
    NAVBAR_CHAT_NEWMESSAGE,
    NAVBAR_CHAT_REMOVE_NEWMESSAGE
} from "../../constants";
import { accept, decline } from '../relations/index'
import { NOTIFICATIONS_BELL_TYPES } from '../../../../assets/strings'
import { handleError } from "../modal";
const notificationsFetchSuccess = (notifications) => ({
    type: NOTIFICATIONS_BELL_FETCH_SUCCESS,
    payload: {
        notifications: notifications,
        // lastSeenId: lastSeenId,
        // hasMore: hasMore
    }
});

const notificationsIsLoading = (isLoading) => ({
    type: NOTIFICATIONS_BELL_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

const notificationAdd = (notification) => ({
    type: NOTIFICATIONS_BELL_ADD,
    payload: {
        notification: notification
    }
});

const notificationsRemove = (notificationId) => ({
    type: NOTIFICATIONS_BELL_REMOVE,
    payload: {
        notificationId: notificationId
    }
});

const acceptDeclineConnectionRequest = (notificationId, status) => ({
    type: CONNECTION_REQUESTS_ACCEPT_DECLINE,
    payload: {
        notificationId: notificationId,
        status: status
    }
});

const normalizeNotificationsObject = (notifications = {}) => {
    return notifications && Object.keys(notifications) && Object.keys(notifications).map((k) => {
        return {
            id: k,
            ...notifications[k]
        }
    }).sort(function (a, b) {
        if (a.timestamp, b.timestamp) {
            return -1;
        }
        if (a.timestamp > b.timestamp) {
            return 1;
        }
        // a debe ser igual b
        return 0;
    })
}

const chatNewMessages = chatId => ({
    type: NAVBAR_CHAT_NEWMESSAGE,
    payload: {
        chatNewMessages: chatId
    }
});

const removeChatNewMessages = chatId => ({
    type: NAVBAR_CHAT_REMOVE_NEWMESSAGE,
    payload: {
        chatNewMessages: chatId
    }
});

const subscribeToNotifications = (userId) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            // dispatch(notificationsIsLoading(true))
            firebase.subscribeToNotifications(userId, (value) => {
                const notifications = value ? value : {}
                // const notifKeys = notifications && Object.keys(notifications)
                // const lastSeen = notifications && notifKeys && notifKeys[notifKeys.length - 1]
                const notifNormalized = normalizeNotificationsObject(notifications).filter(notif => notif.type !== NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION)
                // const hasMore = notifications && Object.keys(notifications).length >= 15
                // dispatch(notificationsFetchSuccess(notifNormalized, lastSeen, hasMore))
                dispatch(notificationsFetchSuccess(notifNormalized))
            })
        } catch (error) {
            dispatch(notificationsIsLoading(false))
            console.error('error', error)
        }
    }
}

const fetchConnectionRequests = (userId) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationsIsLoading(true))
            const response = await firebase.fetchConnectionRequests(userId)
            const notifications = response && response.exists() ? response.val() : {}
            console.log('fetchConnectionRequests', notifications)
            const notifNormalized = normalizeNotificationsObject(notifications)
            return dispatch(fetchConnectionRequestsSuccess(notifNormalized))
        } catch (error) {
            dispatch(notificationsIsLoading(false))
            console.error('error', error)
        }

    }
}

const removeNotification = (notificationId) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationsIsLoading(true))
            const myId = getState().main.user.uid
            dispatch(notificationsRemove(notificationId))
            await firebase.removeNotification(myId, notificationId)
            dispatch(notificationsIsLoading(false))
        } catch (error) {
            dispatch(notificationsIsLoading(false))
            console.error('error', error)
        }

    }
}

const acceptContactRequest = (userId, notificationId) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationsIsLoading(true))
            const myId = getState().main.user.uid
            dispatch(acceptDeclineConnectionRequest(notificationId, 'ACCEPTED'))
            return await dispatch(accept(userId, myId, null, notificationId))
        } catch (error) {
            dispatch(notificationsIsLoading(false))
            console.error('error', error)
        }
    }
}

const declineContactRequest = (userId, notificationId) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationsIsLoading(true))
            //const myId = getState().main.user.uid
            dispatch(acceptDeclineConnectionRequest(notificationId, 'DECLINED'))
            // dispatch(removeNotification(notificationId))
            await dispatch(decline(userId))
        } catch (error) {
            dispatch(notificationsIsLoading(false))
            console.error('error', error)
        }
    }
}

const subscribeToBellNotficationsAdded = userId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        firebase.subscribeToBellNotificationsAdded(userId, (notification, notificationId) => {
            // console.log('subscribeToBellNotficationsAdded notification', notification)
            const notifNormalized = normalizeNotificationsObject(
                {
                    [notificationId]: {
                        ...notification
                    }
                }
            )
            // console.log('subscribeToBellNotficationsAdded notifNormalized', notifNormalized)
            if (notifNormalized) {
                if (notifNormalized[0].type !== NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION) dispatch(notificationAdd(notifNormalized))
                else dispatch(fetchConnectionRequestsSuccess(notifNormalized))
            }
        })
    }
}

const subscribeToBellOpened = userId => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        firebase.subscribeBellOpened(userId, (value) => {
            // console.log('subscribeBellOpened', value)
            dispatch(turnOnNotificationBell(value))
        })
    }
}

const setNewBellNotification = (userId, value) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(turnOnNotificationBell(value))
            return await firebase.setNewBellNotification(userId, value)
        } catch (error) {
            console.error('setNotificationRead', error)
            dispatch(handleError(error))
            dispatch(turnOnNotificationBell(!value))
            return
        }

    }
}

const setNotificationRead = (userId, notificationId, read) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationRead(notificationId, read))
            return await firebase.setNotificationRead(userId, notificationId, read)
        } catch (error) {
            console.error('setNotificationRead', error)
            dispatch(handleError(error))
            dispatch(notificationRead(notificationId, !read))
            return
        }
    }
}

const markAllNotificationsAsRead = (userId, read) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(notificationAllRead(read))
            return await firebase.markAllNotificationsAsRead(userId, read)
        } catch (error) {
            console.error('markAllNotificationsAsRead', error)
            dispatch(notificationAllRead(!read))
            dispatch(handleError(error))
            return
        }
    }
}

const notificationAllRead = (read) => ({
    type: NOTIFICATIONS_BELL_ALL_READ,
    payload: {
        read: read
    }
});

const notificationRead = (notificationId, read) => ({
    type: NOTIFICATIONS_BELL_READ,
    payload: {
        notificationId: notificationId,
        read: read
    }
});

const fetchConnectionRequestsSuccess = connectionRequests => ({
    type: CONNECTION_REQUESTS_FETCH_SUCCESS,
    payload: {
        connectionRequests: connectionRequests
    }
});

const turnOnNotificationBell = touched => ({
    type: NAVBAR_TOUCH_NOTIFICATION,
    payload: {
        turnOnBell: touched
    }
});

const currentSelectedTab = currentTab => ({
    type: NAVBAR_CURRENT_TAB,
    payload: {
        currentTab: currentTab
    }
});

const currentCreateDropdownOption = currentDropdownOption => ({
    type: CREATE_DROPDOWN_CURRENT_TAB,
    payload: {
        currentDropdownOption
    }
});

const toogleAvatarDropdown = open => ({
    type: NAVBAR_TOOGLE_AVATARDROPDOWN,
    payload: {
        avatarDropDownOpen: open
    }
});

const toogleMobileSideMenu = open => ({
    type: NAVBAR_TOOGLE_MOBILESIDEMENU,
    payload: {
        mobileSideMenuOpen: open
    }
});


const toogleBackButton = (enabled, action) => ({
    type: NAVBAR_TOOGLE_BACKBUTTON,
    payload: {
        enabled: enabled,
        action: action
    }
});

export {
    setNotificationRead,
    setNewBellNotification,
    subscribeToBellOpened,
    toogleMobileSideMenu,
    toogleAvatarDropdown,
    toogleBackButton,
    currentSelectedTab,
    currentCreateDropdownOption,
    turnOnNotificationBell,
    subscribeToBellNotficationsAdded,
    subscribeToNotifications,
    acceptContactRequest,
    declineContactRequest,
    removeNotification,
    fetchConnectionRequests,
    markAllNotificationsAsRead,
    chatNewMessages,
    removeChatNewMessages
};
