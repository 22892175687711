import React, { useEffect, useState } from 'react'
import { Flex, Box } from 'rebass'
import styled, { withTheme } from 'styled-components'
import SquareButton from '../../Buttons/SquareButton2'
import EndorsementSuccessMessage from "../SendEndorsementModal/EndorsementSuccessMessage"
import EndorsementFailMessage from '../SendEndorsementModal/EndorsementFailMessage'
import PopupModal from '../../PopupBlurModal'
import PeopleSearchBar from '../../PeopleSearchBar'
import { TextArea } from '../../../../views/Settings/common/Components'
import useForm from '../../../../views/Settings/common/useForm'
import { InputCounter } from '../../../../views/Profile/Profile'
import { Icon } from '../../Icon'
import ReactTooltip from 'react-tooltip'
//import CreateContactModal from '../../ShareModal/CreateContact'
import Utils, { mapContactToUserInfo } from '../../../../../../../libs/Utils'
import useOnChangeRoute from '../../../../../../../libs/useOnRouteChange'
import { useToggle } from '../../../../../../../libs/useToggle'
import UserInfo from '../../PeopleSearchBar/UserInfo'
import CreateContactModal from '../../CreateContactModal'


const SearchSuggestionWrapper = styled.div`
   
    border: 1px solid #929292;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 50px;
    max-width: 651.5px;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.text};
    font-size: ${({ theme }) =>
        theme.mobileDevice ? theme.fonts.XS : theme.fonts.L};
    font-weight: ${({ theme }) => theme.fonts.bold};
    text-align: center;
`;

const Label = styled.label`
    font-weight: 700;
    font-size: 16px;
    color: #333333;
    margin-top: ${({ theme }) => theme.spacing[13]};
    margin-bottom: 0.5em;
`;

const Disclaimer = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #929292;
    flex-basis: 80%;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        margin-bottom: 1rem;
    }
`;

const ActionLabel = styled.span`
    color: #1fc07f;
    text-decoration: underline;
    cursor: pointer;
    margin: 0 0.5em;
`;

const CreateEndorsements = ({ t, theme, user, open, handleClose, addEndorsement }) => {
    const { formValues, reset, update, initialize } = useForm();
    const [createContactModal, setCreateContactModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useToggle(false);
    const [showFailMessage, setShowFailMessage] = useToggle(false);
    const [suggestion, setSuggestion] = useState(null);
  

    useEffect(() => {
        initialize({
            selectedUser: null,
            message: ""
        });
        setSuggestion(null)
    }, []);

    useOnChangeRoute(() => {
        if (!open) return;
        handleClose();
        reset();
        setShowSuccessMessage.off();
        setShowFailMessage.off();
    });

    const onChange = ev => update({ key: ev.name, value: ev.value });

    const handleSendOtherEndorsement = () => {
        reset();
        setShowSuccessMessage.off();
        setShowFailMessage.off();
    };



    const onClickSuggestionHandler = (suggestion) => {
        setSuggestion(suggestion)

        onChange({
            target: {
                name: "clientInformation",
                value: suggestion,
            }
        })
    }

  

    const onSave = async () => {
        if (!formValues.selectedUser || !formValues.message.length) return;
        setIsLoading(true);
        const response = await addEndorsement({
            addressed_user: formValues.selectedUser.uid,
            content: formValues.message
        }, formValues.selectedUser);
        setIsLoading(false);
        const success = response && response.status === 200;
        if (success) {
            setShowSuccessMessage.on();
        } else {
            setShowFailMessage.on();
        }
    };

    const getFirstName = () => {
        const { first_name, name } = formValues.selectedUser;
        const firstName = first_name || name;
        // Add s to the end of the name if it ends with s
        return Utils.getNameWithAnSAtTheEnd(firstName);
    };
    const submitDisabled =
        !formValues.selectedUser || !formValues.message.length;


        const onContactCreatedHandler = (contact) => {
            console.log('DONRAMON', contact)
            contact = mapContactToUserInfo(contact);
           
           // onClickSuggestionHandler(contact)
            setSuggestion(suggestion)
            onChange({ name: "selectedUser", value: contact })
        };   



        const createContact = ({ email, first_name, last_name, phone, contact_id, work_info }) => {
            console.log('createContact')
          const contact = {
              email,
              first_name,
              last_name,
              phone,
              contact_id,
              uid: contact_id,
              company_name: work_info && work_info.company,
              locationAddress: work_info && work_info.address && work_info.address && work_info.address.city ? work_info.address.city : '',
              subscription: {
                  plan: ""
              },
              type: "CONTACT"
          }
    
          setSuggestion(contact)
          onChange({ name: "selectedUser", value: contact })
      };   
    
     

    const renderForm = () => (
        <Flex
            flexDirection={"column"}
            alignItems="center"
            pt={[
                theme.spacing[3],
                theme.spacing[3],
                theme.spacing[10],
                theme.spacing[10]
            ]}
            pb={[
                theme.spacing[6],
                theme.spacing[6],
                theme.spacing[24],
                theme.spacing[24]
            ]}
            px={[
                theme.spacing[6],
                theme.spacing[6],
                theme.spacing[24],
                theme.spacing[24]
            ]}
        >
            <Title>{t("title")}</Title>
            <Flex width={[1]} flexDirection="column" alignItems={"flex-start"}>
                <Flex alignItems={"center"}>
                    <Label>{t("label")}</Label>
                    <Flex
                        ml={theme.spacing[2]}
                        mb="0.5rem"
                        style={{ cursor: "pointer" }}
                        alignItems="center"
                    >
                        <Icon
                            icon="info"
                            color={theme.newBlue}
                            size="md"
                            data-tip
                            data-for={"endorsement-info"}
                        />
                        {open && (
                            <ReactTooltip
                                id={"endorsement-info"}
                                delayHide={200}
                                place="bottom"
                                type="light"
                                effect="solid"
                                className="form-tooltip"
                                clickable
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: t("tooltip")
                                    }}
                                />
                            </ReactTooltip>
                        )}
                    </Flex>
                </Flex>
                {!suggestion &&
                    <PeopleSearchBar
                        triggerCreactContact={setCreateContactModal}
                        clearCondition={open}

                        includeContacts
                        onClickSuggestion={user =>
                            onChange({ name: "selectedUser", value: user })
                        }
                        onClearSuggestion={() =>
                            onChange({ name: "selectedUser", value: null })
                        }
                    />
                }
                {suggestion && (
                     <Box width={[1]} >
                        <SearchSuggestionWrapper>
                        <UserInfo
                            key={suggestion._id}
                            id={suggestion._id}
                            avatarUrl={suggestion.avatar}
                            name={suggestion.full_name}
                            organization={suggestion.company_name}
                            tier={suggestion.subscription.plan}
                            location={suggestion.locationAddress}
                            background="#FAFAFA"
                            firstName={suggestion.first_name}
                            lastName={suggestion.last_name}
                        />
                        <Icon
                            icon="close"
                            color={"#929292"}
                            onClick={() => setSuggestion(null)}
                        />
                    </SearchSuggestionWrapper>
                     </Box>
                )}
            </Flex>
            <Flex width={[1]} flexDirection="column" mt={[theme.spacing[5]]}>
                <TextArea
                    type="text"
                    name="message"
                    value={formValues.message}
                    onChange={({ target }) => onChange(target)}
                    maxLength="5000"
                    rows={6}
                    style={{ resize: "vertical", height: "100px" }}
                    placeholder={t("placeholder")}
                />
                <Flex
                    mt={theme.spacing[2]}
                    justifyContent={"flex-end"}
                    style={{ minHeight: "24px" }}
                >
                    {!!formValues.message && !!formValues.message.length && (
                        <InputCounter>
                            {t("characters_left", {
                                charactersLeft: 5000 - formValues.message.length
                            })}
                        </InputCounter>
                    )}
                </Flex>
            </Flex>
            <Flex
                width={[1]}
                mt={[theme.spacing[6]]}
                flexDirection={["column", "column", "row", "row"]}
                justifyContent={"space-between"}
                alignItems={["flex-end", "flex-end", "center", "center"]}
            >
                <Disclaimer>
                    {t("disclaimer_label")}
                    <ActionLabel onClick={() => setCreateContactModal(true)}>
                        {t("disclaimer_action_label")}
                    </ActionLabel>
                    {t("disclaimer_action_label_2")}
                </Disclaimer>
                <SquareButton
                    disabled={submitDisabled}
                    onClick={onSave}
                    bg={theme.shamRockGreen}
                    isLoading={isLoading}
                    fontSize="XS"
                    text={t("button")}
                />
            </Flex>
        </Flex>
    );

    const commonPropsModalMessages = {
        fullName: formValues.selectedUser && formValues.selectedUser.full_name,
        userName: formValues.selectedUser && formValues.selectedUser.userName,
        onClick: () => handleSendOtherEndorsement()
    };

    return (
        <>
            <PopupModal
                open={open && !createContactModal}
                handleClose={handleClose}
                width={
                    !showSuccessMessage &&
                    !showFailMessage && [0.9, 0.9, 0.7, 0.7]
                }
            >
                {showSuccessMessage && (
                    <EndorsementSuccessMessage
                        {...commonPropsModalMessages}
                        firstName={formValues.selectedUser && getFirstName()}
                        buttonLabel={t("send_another_endorsement")}
                    />
                )}
                {showFailMessage && (
                    <EndorsementFailMessage
                        {...commonPropsModalMessages}
                        firstName={
                            formValues.selectedUser &&
                            (formValues.selectedUser.first_name || formValues.selectedUser.name)
                        }
                    />
                )}
                {!showSuccessMessage && !showFailMessage && renderForm()}
            </PopupModal>
            
            <CreateContactModal
                open={createContactModal}
                onClose={() => setCreateContactModal(false)}
                onContactCreated={createContact}
            />
          
        </>
    );
};

export default withTheme(CreateEndorsements);