import {
    CURRENT_REFERRAL_LOADING,
    CURRENT_REFERRAL_SUCCESS,
    CURRENT_REFERRAL_ERROR,
  } from '../../constants';
  import { cleaningObject   } from '../../../../libs/Utils';
import { getMembersAndContactsFromCandidates } from '.';




export const sendingJobData = () => {
    return async(dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            dispatch({ type: CURRENT_REFERRAL_LOADING })
            const meId =         getState().main.user.uid
            const { stepData, draftId } = getState().main.ui.view.opportunityCreationFlow;
            const {  
                JOB_CURRENT_TYPE, 
                JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION,
                JOB_CURRENT_PRIVACY,
                CANDIDATES_SELECTION,
                JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION
            } = stepData;
              
            //MARK: DEFINING THE CURRENT JOB TYPE (CONTRACTOR OR EMPLOYEE)
            const isContractorType = JOB_CURRENT_TYPE.jobType.value === 'CONTRACTOR'
            const isEmployeetype =   JOB_CURRENT_TYPE.jobType.value === 'EMPLOYEE'

            // MARK: DEFINING IF THE JOB CURRENT IS OPEN OR DIRECT 
            const currentJobType =   CANDIDATES_SELECTION.length > 0 ? 'DIRECT' : 'OPEN';

            //MARK: GETTING CANDIDATES UID OR MY NETWORK CONTACT ID
//const selectedCandidate =    (CANDIDATES_SELECTION.length > 0) ? extractCandidateData(CANDIDATES_SELECTION).justCandidate.map( getJustUid => getJustUid.uid) : [];
           // const newContactsToStorage = (CANDIDATES_SELECTION.length > 0) ? extractCandidateData(CANDIDATES_SELECTION).justContact : [];
            // Get all the recommended members and contacts
            const { members: toMembers, contacts: toContacts } = getMembersAndContactsFromCandidates(CANDIDATES_SELECTION, true);
            //MARK: STORING NEW CONTACTS INTO MY NETWORK
        
            
         const getDirecteOpProfession = ( CANDIDATES_SELECTION.length === 1 && CANDIDATES_SELECTION ) ? CANDIDATES_SELECTION.map( idx => idx.user_type)[0] : null                                                                   
                                                                             
            
            // MARK: LOCATION ( REMOTE OR PARTICULAR )                                                                    
            const locationIsRemote =        (JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION)  && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.locationIsRemote.value === 'REMOTE'
            const employeeLocationRemote =  (JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION) && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.locationIsRemote.value === 'REMOTE'
            const contractorLocation =     {
                    coords:                ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address) ? [JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.lat,  JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.lng ] : [],
                    fullAddress:           ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address) ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.description : "",
                    state:                 ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address) ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.state : "",
                    stateShort:            ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION &&  JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address) ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.stateShort : "",
                    subAdministrativeArea: ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION &&  JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address) ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.subAdministrativeArea : "",
                    zipcode:                ( JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.address.zipCode : "",
            }
            const employeeLocation = {
                coords:                ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? [JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.lat,  JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.lng ] : [],
                fullAddress:           ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.description : "",
                state:                 ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.state : "",
                stateShort:            ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.stateShort : "",
                subAdministrativeArea: ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.subAdministrativeArea : "",
                zipcode:                ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address ) ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.address.zipCode : "",
            }

            const professionFromContractorFlow =  JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION && JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.profession ? parseInt(JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.profession.value) : null
            const professionFromEmployeeFlow =    JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION   && JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.profession ? parseInt(JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.profession.value) : null
            const professionFromDirectCandite =   CANDIDATES_SELECTION.length === 1 ? parseInt(getDirecteOpProfession) : null;
                           
             
            const professions =  professionFromContractorFlow || professionFromEmployeeFlow || professionFromDirectCandite
            
            
            // MARK: OBJECT MEANT TO BE USE JUST IN CONTRACTOR DATA
            const contractorData = (isContractorType) && 
            {
                jobType:      JOB_CURRENT_TYPE.jobType.value,
                jobSubType:   JOB_CURRENT_TYPE.contractorType.value,
                type:         currentJobType,
                profession:   professions,
                describedProfession: stepData.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.profession === null ?
                                     stepData.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.professionDescription : null,
                isRemote:     locationIsRemote,
                location:     (!locationIsRemote) ? contractorLocation : null,
                compensation: (JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.compensation )? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.compensation: null,
                toMembers,
                toContacts,
                timingInfo:   JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.timingInfo.value,
                description:  JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.description ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.description : "",
                from:         meId,
                candidateAcceptance: CANDIDATES_SELECTION.length > 1 ? JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION.acceptance.value : null
             };

              // MARK: OBJECT MEANT TO BE USE JUST TO EMPLOYEE FLOW
             const employeeData = (isEmployeetype) &&
             {
                jobType:      JOB_CURRENT_TYPE.jobType.value,
                jobSubType:   JOB_CURRENT_TYPE.employeeType.value,
                type:         currentJobType,
                profession:   professions,
                describedProfession: stepData.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.profession === null ?
                                     stepData.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.professionDescription : null,
                isRemote:     employeeLocationRemote,
                location:     (!employeeLocationRemote) ? employeeLocation : null,
                compensation: ( JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.compensation )? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.compensation: null,
                toMembers,
                toContacts,
            //    timingInfo:   JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.timingInfo.value,
                description:  JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.description ? JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.description : "",
                from:         meId,
                jobTitle:     JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION.jobTitle,
           
                
             }
             
            const neededEndpointData = ( isContractorType ) ? contractorData : 
                                       ( isEmployeetype )   ? employeeData   : false;
          
                                    
           if (neededEndpointData) {
               await cloudfunctions.sendJob(cleaningObject(neededEndpointData))
               // Delete the draft if it exists
                if (draftId) {
                    await firebase.deleteDraftReferral(meId, draftId);
                }
               dispatch({ type: CURRENT_REFERRAL_SUCCESS })
           } else {
              dispatch({ type: CURRENT_REFERRAL_ERROR });
           }
        } catch(error) {
            dispatch({ type: CURRENT_REFERRAL_ERROR });
             console.error('job flow error', error)
        }
    }
}