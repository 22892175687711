import {
  OPPORTUNITY_FLOW_SET_ACTIVE_STEP,
  OPPORTUNITY_FLOW_RESET,
  SAVE_REFERRAL_DRAFT,
  LOAD_REFERRAL_DRAFT,
  CURRENT_REFERRAL_LOADING,
  CURRENT_REFERRAL_SUCCESS,
  CURRENT_REFERRAL_ERROR,
  NEW_CONTACT_SAVED,
  CONTACT_IS_LOADING,
  CONTACT_ERROR,
  CONTACT_SAVED,
  CANCEL_CONTACT_ERROR
} from "../../../../actions/constants";

import {
  OPPORTUNITY_CREATION_STEPS,
  REFERRAL_PRIVACY,
  RECOMMENDATION_TYPE,
  OPPORTUNITY_TYPES,
} from "../../../../../components/ui/presentation/views/OpportunityCreation/constants";
import {
  OPPORTUNITY_CREATION_STEPS_ITEMS,
  FLOW_STEPS_ITEMS,
  REFERRAL_FLOW_STEPS_ITEMS,
  JOB_FLOW_STEPS_ITEMS,
  JOB_FLOW_CANDIDATES_STEPS_ITEMS,
  JOB_FLOW_CONTRACTOR_STEPS_ITEMS,
  JOB_FLOW_CONTRACTOR_CANDIDATES_STEPS_ITEMS,
  JOB_PAST_STEPS_ITEMS,
} from "../../../../../components/ui/presentation/views/OpportunityCreation/Steps/steps";

const initialState = {
  currentStep: OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP,
  navbarItems: [OPPORTUNITY_CREATION_STEPS_ITEMS[OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP]],
  stepData: {},
  newContact: {},
  draftId: null,
  referralIsLoading: false,
  successReferral: false,
  contactIsSaving: false,
  errorStoringSavingContact: false,
  errorReferral: false
};

function opportunityCreationFlowReducer(state = initialState, action) {
  switch (action.type) {

    case OPPORTUNITY_FLOW_RESET:
      return initialState;

    case OPPORTUNITY_FLOW_SET_ACTIVE_STEP:
      const _state = {
        ...state,
        currentStep: action.payload.step,
      };
      return _state;

    case SAVE_REFERRAL_DRAFT:
         return loadDraft(state, action.payload.draftId);

    case OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP:
         return opportunityTypeSelectionStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP:
         return referralPrivacySelectionStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION:
         return referralProfessionStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION:
         return referralCompleteInformationStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION:
         return referralCandidatesSelectionStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE:
         return referralCandidatesSelectionStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION:
         return referralCompleteInformationStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE:
         return jobCurrentTypeStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_PRIVACY:
         return jobCurrentPrivacyStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION:
         return jobCurrentEmployeeCompleteInformationStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION:
         return jobCurrentContractorCompleteInformationStateTransform(state, action.payload.data);

    case OPPORTUNITY_CREATION_STEPS.JOB_PAST_COMPLETE_INFORMATION:
         return jobPastCompleteInformationStateTransform(state, action.payload.data);

    case 'PAST_JOB':
          return jobPastStateTransform(state, action.payload.data);

    case 'SAVE_STEP_DATA':
         return saveStepData(state, action.payload.data);

    case LOAD_REFERRAL_DRAFT:
         return loadDraft(state, action.payload.draftId);

    case CURRENT_REFERRAL_LOADING:
         return loadingReferralCurrent(state, action.payload);

    case CURRENT_REFERRAL_SUCCESS:
         return successReferral(state, action.payload);

    case CURRENT_REFERRAL_ERROR:
         return referralErrror(state, action.payload);

    case CONTACT_IS_LOADING: 
         return contactIslogading(state, action.payload);

    case CONTACT_SAVED: 
         return contactSavedSuccess(state, action.payload);

    case CONTACT_ERROR:
         return contactError(state, action.payload);

    case CANCEL_CONTACT_ERROR:
         return cancelError(state, action.payload);

    case NEW_CONTACT_SAVED:
         return loadNewContactData(state, action.payload);

    default:
      return state
  }
}

const contactSavedSuccess = (state, action) => {
  const _state = {
    ...state,
    contactIsSaving: false,
  }

  return _state
}

const contactIslogading = (state, action) => {
  const _state = {
    ...state,
    contactIsSaving: true,
    errorStoringSavingContact: false
  }

  return _state
}

const contactError = (state, action) => {
  const _state = {
    ...state,
    errorStoringSavingContact: true,
    contactIsSaving: false
  }

  return _state
}

const cancelError = (state, action) => {
  const _state = {
    ...state,
    errorStoringSavingContact: false,
    contactIsSaving: false
  }

  return _state
}

const loadNewContactData = (state, contacts) => {
  const _state = {
    ...state,
     newContact: contacts
  }

  return _state
}

const loadingReferralCurrent = (state, action) => {
  const _state = {
    ...state,
    referralIsLoading: true,
  }

  return _state
}

const successReferral = (state, action) => {
  const _state = {
    ...state,
    referralIsLoading: false,
    successReferral: true
  }

  return _state
}


const referralErrror = (state, action) => {
  const _state = {
    ...state,
    referralIsLoading: false,
    errorReferral: true

  }

  return _state
}


const loadDraft = (state, draftId) => {
  const _state = {
    ...state,
    draftId
  }

  return _state;
}

const saveStepData = (state, data) => {
  const _state = {
    ...state,
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
    }
  }

  return _state
}

const jobPastCompleteInformationStateTransform = (state, data) => {
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
    },
  }

  return _state;
};

const jobCurrentContractorCompleteInformationStateTransform = (state, data) => {

  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
    },
  }
  return _state;
};

const jobCurrentEmployeeCompleteInformationStateTransform = (state, data) => {
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
    },
  }

  return _state;
};

const jobCurrentTypeStateTransform = (state, data) => {
  // const jobType = `JOB_${data.jobType.value}`;
  // const flow = [...FLOW_STEPS_ITEMS[jobType]];
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
      'PAST_JOB': false,
      [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION]: null,
      [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION]: null,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
    },
  };

  return _state;
};

const referralCandidatesSelectionStateTransform = (state, data) => {
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
      [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: null,
    },
  };
  return _state;
};

const referralCompleteInformationStateTransform = (state, data) => {
  console.log('CURRENT STEP_', {
    state, data
  })
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
    },
  };
  return _state;
};

const jobCurrentPrivacyStateTransform = (state, data) => {

  let flow = [...JOB_FLOW_STEPS_ITEMS]

  const isCandidates = data.jobPrivacy.value === 'CANDIDATES';
  const isContractor = state.stepData[OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE].jobType.value === 'CONTRACTOR';

  if (isCandidates && !isContractor) {
    flow = [...JOB_FLOW_CANDIDATES_STEPS_ITEMS];
  } else if (isCandidates && isContractor) {
    flow = [...JOB_FLOW_CONTRACTOR_CANDIDATES_STEPS_ITEMS]
  } else if (!isCandidates && isContractor) {
    flow = [...JOB_FLOW_CONTRACTOR_STEPS_ITEMS]
  }

  const _state = {
    ...state,
    navbarItems: flow,
    currentStep: getNextStep(state.currentStep, flow),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
    },
  };
  return _state;
};

const jobPastStateTransform = (state, data) => {
  const _state = {
    ...state,
    navbarItems: [...JOB_PAST_STEPS_ITEMS],
    currentStep: OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION,
    stepData: {
      ...state.stepData,
      'PAST_JOB': true,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
    },
  };
  return _state;
}

const referralProfessionStateTransform = (state, data) => {
  const _state = {
    ...state,
    currentStep: getNextStep(state.currentStep, state.navbarItems),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
    },
  };
  return _state;
};

const referralPrivacySelectionStateTransform = (state, data) => {

  let flow = [...REFERRAL_FLOW_STEPS_ITEMS]

  if (data.value === REFERRAL_PRIVACY.CANDIDATE) {
    flow = [...FLOW_STEPS_ITEMS[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]]
  }
  // Go to referral past flow steps
  if (data === REFERRAL_PRIVACY.REFERRAL_PAST) {
    flow = [...FLOW_STEPS_ITEMS[OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE]]
  }

  const _state = {
    ...state,
    navbarItems: flow,
    currentStep: getNextStep(state.currentStep, flow),
    stepData: {
      ...state.stepData,
      [state.currentStep]: data,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
      [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: null,
    },
  };
  return _state;
};



const opportunityTypeSelectionStateTransform = (state, data) => { 

  let flow;

 

  if ( data.type === 'REFERRAL' && data.opTime === 'CURRENT') {
     flow = [...FLOW_STEPS_ITEMS[OPPORTUNITY_TYPES.REFERRAL]];
      
  }

  if ( data.type === 'REFERRAL' && data.opTime === 'PAST') {
    flow = [...FLOW_STEPS_ITEMS[REFERRAL_PRIVACY.REFERRAL_PAST]];
     
 }

 if ( data.type === 'JOB' && data.opTime === 'CURRENT') {
  flow = [...FLOW_STEPS_ITEMS[OPPORTUNITY_TYPES.JOB]];
  }

  if ( data.type === 'JOB' && data.opTime === 'PAST') {
    flow = [...FLOW_STEPS_ITEMS['PAST_JOB']];
  }



  const _state = {
    ...state,
    navbarItems: flow,
    currentStep: getNextStep(state.currentStep, flow),
    stepData: {
      ...state.stepData,
      [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: [],
      [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE]: [],
      [state.currentStep]: data,
    },
  };

  return _state;
};





const getNextStep = (currentStep, flow) => {

  const currentIndex = flow.findIndex((s) => s.code === currentStep);
  const nextIndex = (currentIndex + 1) % flow.length;
  return flow[nextIndex].code;
};

export default opportunityCreationFlowReducer;
