import {
    SEND_REFERRAL_USER_LIST_FETCH_USERS_FAIL,
    SEND_REFERRAL_USER_LIST_FETCH_USERS_SUCC,
    SEND_REFERRAL_USER_LIST_FETCH_USERS,
    USER_LIST_FETCH_USERS_SUCC
} from "../../../../actions/constants";

const initialState = {
    loading: false,
    errors: [],
    data: []
};

function sendReferralUserListReducer(state = initialState, action) {
    switch (action.type) {
        case USER_LIST_FETCH_USERS_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.referralsUsers[action.payload.uid] = {...action.payload.referralsUsers};
                return _state;
            }
        case SEND_REFERRAL_USER_LIST_FETCH_USERS_FAIL:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.errors = [action.errors];
                return _state;
            }
        case SEND_REFERRAL_USER_LIST_FETCH_USERS_SUCC:
            {
                const _state = {
                    ...state
                };
                _state.loading = false;
                _state.data = action.payload;
                return _state;
            }
        case SEND_REFERRAL_USER_LIST_FETCH_USERS:
            {
                const _state = {
                    ...state
                };
                _state.loading = true;
                return _state;
            }
        default:
            return state
    }
}

export default sendReferralUserListReducer;