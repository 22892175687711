import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Flex, Box } from "rebass";
import { SubTitle, Title, ReplyDate, Text } from "../../../../atoms/shared/Text";
import { withTranslation } from "react-i18next";
import { Icon } from "../../../../atoms/shared/Icon";
import { withTheme } from "styled-components";
import SquareButton from "../../../../atoms/shared/Buttons/SquareButton2";
import {
    sendInviteEmails
} from "../../../../../../../redux/actions/creators/settings";
import SuccessModal from "../InvitationSentSuccessModal/index";
import InvitationSentSuccess from "../InvitationSentSuccessModal/InvitationSentSuccess";
import { Input, InputContainer, InputLabel } from "../../common/Components";
import useForm from "../../common/useForm"
import validator from "validator";
import styled from "styled-components";
import HorizontalLine from "../../../../atoms/shared/HorizontalLine";
import Tooltip from "../../../../atoms/shared/Tooltip";
import Link from "../../../../atoms/Link";

export const ShareLinkContainer = styled.div`
    border-radius: ${({ theme }) => theme.spacing[1]};
    background-color: ${({ theme }) => theme.clearGray};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[4]}`};
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[4]};
`;

export const CopyButton = styled(Text)`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    :hover {
        color: ${({ theme }) => theme.shamRockGreen};
        transition: all 0.2s ease-in-out;
    }
`;

const Invites = props => {
    const [urlToShare, setUrlToShare] = useState(null);
    const [invitationsSentSuccessModalOpen, setModalOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [currentInvitationData, setCurrentInvitationData] = useState({
        fullName: "",
        firstname: "",
        email: ""
    });
    const [alreadyRegisteredUser, setAlreadyRegisteredUser] = useState({});
    const {
        t,
        theme,
        qr_profile,
        sendInviteEmails,
        isLoading,
        payoutMethodStatus,
        email,
        uid,
        isModalInvite,
        userName,
        ...otherProps
    } = props;

    const { formValues, touched, reset, initialize, update } = useForm();

    useEffect(() => {
        initialize({
            firstName: "",
            lastName: "",
            email: ""
        });
    }, []);

    const isValidForm = useMemo(() => {
        const requiredFields = ["firstName", "lastName", "email"];
        return (
            formValues &&
            requiredFields.every(field => formValues[field]) &&
            validator.isEmail(formValues.email) &&
            formValues.email !== email
        );
    }, [JSON.stringify(formValues)]);

    const onChange = ev => update({ key: ev.name, value: ev.value });


    const makeShareUri = () => {
        const newUrl = `${process.env.REACT_APP_INVITESURL_SHORTER}${userName}`;
        const encodedUrl = encodeURIComponent(newUrl);
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        const twitterUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
        const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=Sign+Up+NuOp&summary=NuOp&source=nuop.com`;
        const instagramUrl = `https://www.instagram.com?url=${encodedUrl}`;
        setUrlToShare({
            urlToShare: newUrl,
            facebookUrl,
            twitterUrl,
            linkedinUrl,
            instagramUrl
        });
        return;
    };

    useEffect(makeShareUri, [props.userName]);

    const copyToClipboard = e => {
        navigator.clipboard.writeText(urlToShare.urlToShare);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    const onCloseSuccessModal = () => {
        setModalOpen(false);
        setCurrentInvitationData({ fullName: "", firstname: "", email: "" });
    };

    const sendInvites = async e => {
        e.preventDefault();
        try {
            const response = await sendInviteEmails([formValues]);

            const { members } = response;
            setAlreadyRegisteredUser(
                members && members.length > 0 && members[0]
            );

            setCurrentInvitationData({
                firstname: formValues.firstName,
                email: formValues.email,
                fullName: `${formValues.firstName} ${formValues.lastName}`
            });
            setModalOpen(true);
            reset();
        } catch (error) {
            console.error("callback error", error);
        }
    };

    const socialItem = (social = "", onClickHandler) => {
        let socialLow = social.toLowerCase();
        const size = 20;
        const noShowLabel = isModalInvite ? theme.mobileDevice : false;

        const shareLinkHandler = url => {
            window.open(url);
        };
        return (
            <Flex
                alignItems="center"
                justifyContent="center"
                onClick={e => shareLinkHandler(onClickHandler)}
                style={{ cursor: "pointer" }}
                mt={[16, 16, 0, 0]}
            >
                <Icon
                    icon={socialLow}
                    color={props.theme.blueMedium}
                    width={size}
                    height={size}
                />
                {!noShowLabel && (
                    <Link
                        style={{ marginLeft: ".8em" }}
                        fontSize="XS"
                        color={theme.text}
                        underlineColor={theme.blueMedium}
                    >
                        {social}
                    </Link>
                )}
            </Flex>
        );
    };

    const allFieldsRequired = () => <Text color={theme.taupeGray} fontSize="XXS">*All fields are required</Text>

    const renderForm = () => (
        <>
            <Title>{t("sendInvites_title")}</Title>
            <Box mt={["1em"]}>
                <SubTitle style={{ textAlign: "left" }}>
                    {t("sendInvites_subtitle")}
                </SubTitle>
            </Box>
            <Flex
                mt={["2em"]}
                flexDirection="row"
                flexWrap="wrap"
                width={isModalInvite ? [1] : [1, 1, 0.75, 0.75]}
                justifyContent="space-between"
            // alignItems="center"
            >
                <Box
                    width={isModalInvite ? [.47] : [1, 1, 0.47, 0.47]}
                    mr={isModalInvite ? [".9rem", ".9rem", "1.25rem", "1.25rem"] : [0, 0, "1.25rem", "1.25rem"]}
                >
                    <InputContainer>
                        <InputLabel>First Name</InputLabel>
                        <Input
                            type="text"
                            name="firstName"
                            required
                            value={formValues.firstName}
                            onChange={ev => onChange(ev.target)}
                        />
                    </InputContainer>
                </Box>
                <Box
                    width={isModalInvite ? [.47] : [1, 1, 0.47, 0.47]}
                >
                    <InputContainer>
                        <InputLabel>Last Name</InputLabel>
                        <Input
                            type="text"
                            name="lastName"
                            required
                            value={formValues.lastName}
                            onChange={ev => onChange(ev.target)}
                        />
                    </InputContainer>
                </Box>

                <Box
                    width={isModalInvite ? [1] : [1, 1, 0.47, 0.47]}
                    mt={[0, 0, "1.25rem", "1.25rem"]}
                >
                    <InputContainer>
                        <InputLabel>Email</InputLabel>
                        <Input
                            type="text"
                            name="email"
                            required
                            value={formValues.email}
                            onChange={ev => onChange(ev.target)}
                        />
                    </InputContainer>
                    {formValues.email === email && (
                        <Text
                            style={{ marginTop: theme.spacing[2] }}
                            color={theme.redError}
                            fontSize="XXS"
                        >
                            You can't invite yourself
                        </Text>
                    )}
                </Box>
            </Flex>
            {!isModalInvite && (
                <Flex mt={theme.spacing[2]} mb={theme.spacing[4]}>
                    {allFieldsRequired()}
                </Flex>
            )}
            <Flex
                width={[1]}
                alignItems="center"
                justifyContent="space-between"
                mt={isModalInvite && theme.spacing[2]}
                mb={["2em"]}
            >
                {isModalInvite && allFieldsRequired()}
                <Box>
                    <SquareButton
                        style={!theme.mobileDevice ? { width: "100px", minWidth: "100px" } : {}}
                        isLoading={isLoading}
                        height={"40px"}
                        text={t("sendInvites_button")}
                        bg={theme.greenMedium}
                        disabled={!isValidForm}
                        callback={sendInvites}
                    />
                </Box>
            </Flex>
        </>
    );

    return <>
        {!isModalInvite && invitationsSentSuccessModalOpen &&
            <SuccessModal
                open={invitationsSentSuccessModalOpen}
                onClose={onCloseSuccessModal}
                invitationValue={currentInvitationData}
                alreadyRegisteredUser={alreadyRegisteredUser || {}}
            />}
        <>
            {isModalInvite && invitationsSentSuccessModalOpen ? (
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    mb="2em"
                >
                    <InvitationSentSuccess
                        onClose={onCloseSuccessModal}
                        invitationValue={currentInvitationData}
                        alreadyRegisteredUser={alreadyRegisteredUser || {}}
                    />
                </Flex>
            ) : (
                renderForm()
            )}
            <HorizontalLine />
            <Box my={["2em"]} id={!isModalInvite ? "inviteViaSocialMedia" : undefined}>
                <Title>Invite via social media</Title>
                <Flex
                    mt={["1rem"]}
                    width={isModalInvite ? [0.5, 0.5, .75, .75] : [1, 1, 0.5, 0.5]}
                    flexDirection={isModalInvite ? ["row"] : ["column", "column", "row", "row"]}
                    alignItems={[
                        "flex-start",
                        "flex-start",
                        "center",
                        "center"
                    ]}
                    justifyContent="space-between"
                >
                    {socialItem(
                        "Facebook",
                        urlToShare && urlToShare.facebookUrl
                    )}
                    {socialItem("Twitter", urlToShare && urlToShare.twitterUrl)}
                    {socialItem(
                        "Linkedin",
                        urlToShare && urlToShare.linkedinUrl
                    )}
                    {/* socialItem("Instagram", instagramUrl) */}
                </Flex>
            </Box>
            <HorizontalLine />
            <Box mt={["2em"]} width={[1, 1, 0.75, 0.75]} id={!isModalInvite ? "shareYourLink" : undefined}>
                < Title > {t("sendInvites_shareLabel")}</Title>
                <ShareLinkContainer>
                    <Text color={theme.taupeGray} fontSize="XXS">
                        {urlToShare && urlToShare.urlToShare}
                    </Text>

                    <Tooltip text={copied ? "Copied!" : "Copy to clipboard"}>
                        <CopyButton
                            onClick={copyToClipboard}
                            color={theme.greenMedium}
                            fontSize="XS"
                            fontWeight="bold"
                        >
                            Copy
                        </CopyButton>
                    </Tooltip>
                </ShareLinkContainer>
            </Box>
        </>
    </>
};

const mapStateToProps = state => {
    return {
        email: state.main.user.profile.email,
        uid: state.main.user.uid,
        selectedSection: state.main.ui.view.settings.selectedSection,
        isLoading: state.main.ui.view.settings.isLoading,
        selectedSubscriptionOption:
            state.main.ui.view.settings.selectedSubscriptionOption,
        subscriptionStep: state.main.ui.view.settings.subscriptionStep,
        qr_profile:
            state.main.user.profile && state.main.user.profile.qr_profile,
        userName: state.main.user.profile && state.main.user.profile.userName,
        payout_acct:
            state.main.user.profile && state.main.user.profile.payout_acct,
        payoutMethodStatus:
            state.main.user.profile &&
            state.main.user.profile.payout_method &&
            state.main.user.profile.payout_method.status
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            sendInviteEmails,
        },
        dispatch
    );
};

const viewWithTranslation = withTranslation("settings_invites")(Invites);
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTheme(viewWithTranslation))
);
