import { Flex } from "rebass";
import styled, { keyframes } from "styled-components";

const fadeInUp  = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 40%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;


export const InputContainer = styled(Flex)`
    animation: ${fadeInUp} .7s linear .5s;
    animation-fill-mode: both;
`

export const VisibilityIconContainer = styled.div`
    position: absolute;
    right: 2.75em;
    bottom: 0.6em;
    cursor: pointer;
`