import React, { Fragment, useEffect, useState, useRef } from "react";
import { Box, Flex } from "rebass";
import { Text } from '../../../../ui/presentation/atoms/shared/Text'
import { Container, MarkAllAsRead } from './styles'
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import ChatIcon from '../../../../../assets/svg/ChatIcon'
import Spinner from "../../../../../assets/svg/Spinner";
import NotificationItem from './NotificationItem'
import HorizontalLine from '../../../../ui/presentation/atoms/shared/HorizontalLine'
import InfiniteScroll from "react-infinite-scroller";
import { NOTIFICATIONS_BELL_TYPES } from '../../../../../assets/strings'
import ConnectionRequests from './ConnectionRequests'
import { AlertText, UnverifiedEmailContainer } from "../../../../ui/presentation/atoms/shared/UnverifiedEmailAlert";

import {
    KnockFeedProvider,
    NotificationIconButton,
    NotificationFeedPopover,
    useKnockFeed,
    useAuthenticatedKnockClient,
    useNotifications
  } from "@knocklabs/react-notification-feed";
import { useKnockConsumer } from "../../../../context/useKnockProvider";
import CloudFunctions from "../../../../../libs/CloudFunctions";
import { knockEnv } from "../../../../../config";
import { NotificationsFeed } from "./NotificationsFeed";



const notificationIcon = (theme, type, icon) => {
    // If icon is passed, use it
    // This was implemented on May 2023, so older notifications may not have an icon
    if (icon && icon.icon_name && icon.icon_color) {
        return (
            <Icon
                icon={icon.icon_name}
                color={icon.icon_color}
                width="10px"
                height="10px"
            />
        );
    }

    // If not, use the default icon for the type
    switch (type) {
        // Connections
        case NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION:
        case NOTIFICATIONS_BELL_TYPES.CONNECTION_ACCEPTED:
            return <Icon icon="add-user" color={theme.newBlue} width='10px' height='10px' />
        // Community
        case NOTIFICATIONS_BELL_TYPES.COMMUNITY_LIKE:
            return <Icon icon="heart-filled" color={theme.DECLINED} width='10px' height='10px' />
        // Chat
        case NOTIFICATIONS_BELL_TYPES.COMMUNITY_COMMENT:
            return <ChatIcon color={theme.newBlue} width='10px' height='10px' />
        // Referrals
        case NOTIFICATIONS_BELL_TYPES.REFERRAL_CLAIM:
        case NOTIFICATIONS_BELL_TYPES.NEW_REFERRAL:
        // Recommendations
        case NOTIFICATIONS_BELL_TYPES.RECOMMENDED:
        case NOTIFICATIONS_BELL_TYPES.RECOMMENDEDUP:
        case NOTIFICATIONS_BELL_TYPES.RECOMMENDOWNER:
            return <Icon icon="opportunity" color={theme.greenMedium} width='10px' height='10px' />
        // Endorsements
        case NOTIFICATIONS_BELL_TYPES.ENDORSED:
        case NOTIFICATIONS_BELL_TYPES.ENDORSER:
        case NOTIFICATIONS_BELL_TYPES.ENDORSEDCONNECTIONS:
            return <Icon icon="endorsement" color={theme.newBlue} width='10px' height='10px' />
        // Gratitude
        case NOTIFICATIONS_BELL_TYPES.GRATITUDE:
            return <Icon icon="gratitude" color={theme.newBlue} width='10px' height='10px' />
        // Favorites
        case NOTIFICATIONS_BELL_TYPES.FAVORITE:
            return <Icon icon="heart-filled" color={theme.DECLINED} width='10px' height='10px' />
        default:
            return <Icon icon="opportunity" color={theme.greenMedium} width='10px' height='10px' />
    }
}

const EmptyStatus = (props) => {
    const { theme } = props
    return (
        <Flex width={[1]} justifyContent='center' p={[theme.spacing['16']]}>
            <Text fontSize={theme.fonts['M']} color={theme.taupeGray} fontWeight='normal' style={{ textAlign: 'center' }}>
                For now you don’t have any notification.
            </Text>
        </Flex>

    )
}
const NotificationsList = (props) => {


    const [scrollRef, setScrollRef] = useState('')
    const {
        theme,
        myId,
        history,
        notifications,
        acceptContactRequest,
        declineContactRequest,
        setNotificationRead,
        toggleSidebar,
        markAllNotificationsAsRead,
     //   removeNotification,
        connectionRequests,
        handleClickOutside,
        user,
        emailIsNotVerified,
        tab,
        acceptKnockConnectRequest,
        declineKnockRequest,
        setRemoveNotification,
        _new_items,
        setMarkThisNotificationAsRead
    } = props;
    
 

     
    


   
    

    const unReadList = _new_items.filter(i => i.read_at === null);

    const tabs = tab === 'unread' ? unReadList : _new_items 
    const takingKnockNotifications =  tabs ? tabs : [];  //notificationFeed.store.getState().items;
    const knockConnectRequest = takingKnockNotifications.filter(notification => notification.source.key === "connection-request");
    const allKnockNotifications = takingKnockNotifications.filter(notification => notification.source.key !== "connection-request");
    const [notificationsPaginated, setNotificationsPaginated] = useState(allKnockNotifications.slice(0, 15))
    const isLoading =  false;  //newNotifications.loading;

   
    
 
 
    /* const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(notificationsPaginated.length >= 15);
    useEffect(() => {
        if (theme.mobileDevice && !notificationsPaginated.length) {
                setNotificationsPaginated(allKnockNotifications)
                setHasMore(false);
        }
    }, [allKnockNotifications]); */
   /* 
    useEffect(() => {
        setNotificationsPaginated(allKnockNotifications.slice(0, page * 15));
        setHasMore(allKnockNotifications.length >= page * 15);
    }, [JSON.stringify(allKnockNotifications)]);
    useEffect(() => {
        if (!theme.mobileDevice) {
            const elem = document.getElementById('scrollContainer');
            setScrollRef(elem)
        }

        return () => {

        }
    }, []) */

  
    /* const loadMore = (page) => {
        if (hasMore) {
            setPage(page);
            setTimeout(() => {
                setNotificationsPaginated((prevNotificationsPaginated) => {
                    const newPage = notifications.slice(notificationsPaginated.length, notificationsPaginated.length + 15)
                    const newNoticiationsPaginated = prevNotificationsPaginated.concat(newPage)
                    setHasMore(newPage.length >= 15);
                    return newNoticiationsPaginated
                })
            }, 1000);
        }
        // if (!isLoading && hasMore) fetchNotifications(myId, lastSeenId)
    }
 */
   

    const handleAcceptConnection = async (uid, notificationId) => {
        //  accept = (uid, notificationId) =>
        try { 
         
        } catch (e) {}
        acceptContactRequest(uid, notificationId)
    }
    const handleDeclineConnection = (uid, notificationId) => {
        declineContactRequest(uid, notificationId)
    }
    const handleRemoveNotification = (notificationId) => setRemoveNotification(notificationId);


    const handleClickUser = (userName) => {
        history.push(`/profile/${userName}`)
        toggleSidebar(false)
    }

     console.log('takingKnockNotifications', takingKnockNotifications)

     const handleClickNotification = (notification = {}) => {
        console.log('handleClickNotification', notification)
        let url = ''
        setNotificationRead(myId, notification.id, true)
        // If notification has a url, use it
        // This was implemented on May 2023, so older notifications may not have a url
        if (notification.data && (notification.data.op_type === 'referral' || notification.data.op_type === 'job' || notification.data.op_type === 'recommendation' )) {
            //url = notification.url
            const { data } = notification;
            const { op_code, op_type } = data;
            url = `/${op_type}/${op_code}`;
            
        } else if (notification.source.key === 'past-op-confirmed') {
            const { data } = notification;
            const { op_code } = data;
            url = `/referral/${op_code}`;
       }
        
        else if (notification.source.key === 'chat-notification') {
            const { actors } = notification;
            const { id  } = actors && actors[0]
            url = `/chat/${id}`;
       }
       else if (notification.source.key === 'op-review-to-counter-party') {
        const { actors } = notification;
        const { user_name  } = actors && actors[0]
        url = `/profile/${user_name}`;
   }
        
        else if (notification.source.key === 'member-endorsement-to-endorsed') {
             const { actors } = notification;
             const { user_name  } = actors && actors[0]
             const userName = user_name
             url = `/profile/${user.userName}#endorsement`;
        }
        else if (notification.source.key === 'member-receiving-gratitude') {
            const { actors } = notification;
            const { user_name  } = actors && actors[0]
            const userName = user_name
            url = `/profile/${userName}#gratitude`;
       }

       else if (notification.source.key === 'member-been-made-favorite') {
        const { actors } = notification;
        const { user_name  } = actors && actors[0]
        const userName = user_name
        url = `/profile/${userName}`;
   }

   else if (notification.source.key === 'connection-acceptance') {
    const { actors } = notification;
    const { user_name  } = actors && actors[0]
    const userName = user_name
    url = `/profile/${userName}`;
}

   
       
        
        else { // Otherwise, use the notification type to build the url
            switch (notification.source.key) {
                case "connection-request": {
                    const { actors } = notification;
                    const { user_name  } = actors && actors[0]
                    const userName = user_name
                    url = `/profile/${userName}?respond-connection-request=true`;
                    break;
                }
                case "open-op-available-for-members": {
                    const { data } = notification;
                    const { op_code } = data
                    url = `/referral/${op_code}`;
                    break;
                }
                case "direct-op-creation-creator": {
                    const { data } = notification;
                    const { op_code } = data
                    url = `/referral/${op_code}`;
                    break;
                }
                case "open-op-awarded-to-candidate": {
                    const { data } = notification;
                    const { op_code } = data
                    url = `/referral/${op_code}`;
                    break;
                } 
                default:
                    break;
            }
        }

        toggleSidebar(false)
        return history.push(url)
    } 

    const handleGoToNotificationSettings = () => {
        handleClickOutside();
        history.push(`/settings?section=notifications`)
    }



     const handleGoToSettings = () => {
        handleClickOutside();
        history.push(`/settings`)
     }



    return (

        <Container  style={{ marginTop: 20 }}>
            {!emailIsNotVerified && 

                 <Box>
                    <div onClick={handleGoToSettings} >
                    <UnverifiedEmailContainer>
                        <Icon
                            icon="mail"
                            color={`#E56767`} width='18px' height='18px' 
                        />
                        <AlertText underlineColor={theme.coolBlack} >Please verify your email</AlertText>
                    </UnverifiedEmailContainer>
                    </div>
                </Box>
             }
             {knockConnectRequest && knockConnectRequest.length > 0 && (
                <ConnectionRequests
                    connectionRequests={knockConnectRequest}
                    handleAcceptConnection={handleAcceptConnection}
                    handleDeclineConnection={handleDeclineConnection}
                    handleRemoveNotification={handleRemoveNotification}
                    handleClickNotification={handleClickNotification}
                    handleClickUser={handleClickUser}
                    acceptKnockConnectRequest={acceptKnockConnectRequest}
                    declineKnockRequest={declineKnockRequest}
                    myId={myId}
                    setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
                />
            )} 
                 
    
               
                    {allKnockNotifications &&
                        allKnockNotifications.map((notification, index) => {
                            return (
                                <Fragment>
                                    <NotificationItem
                                        key={`${index}_${notification.id}_notification`}
                                        icon={notificationIcon(
                                            theme,
                                            notification["type"],
                                            notification["icon"],
                                        )}
                                        notification={notification}
                                        handleAcceptConnection={
                                            handleAcceptConnection
                                        }
                                        handleDeclineConnection={
                                            handleDeclineConnection
                                        }
                                        handleRemoveNotification={
                                            handleRemoveNotification
                                        }
                                        handleClickNotification={
                                            handleClickNotification
                                        }
                                        handleClickUser={handleClickUser}
                                        notificationId={notification.id}
                                        currentUser={user}
                                        myId={myId}
                                        acceptKnockConnectRequest={acceptKnockConnectRequest}
                                        declineKnockRequest={declineKnockRequest}
                                        setMarkThisNotificationAsRead={setMarkThisNotificationAsRead}
                                    />
                                    <HorizontalLine />
                                </Fragment>
                            );
                        })}  
                  {/*     <NotificationsFeed 
                            handleAcceptConnection={handleAcceptConnection}
                            handleDeclineConnection={handleDeclineConnection}
                            handleRemoveNotification={handleRemoveNotification}
                            handleClickNotification={handleClickNotification}
                            handleClickUser={handleClickUser}
                            items={_new_items}
                            tab={tab}
                            acceptKnockConnectRequest={acceptKnockConnectRequest}
                            declineKnockRequest={declineKnockRequest}
                            myId={myId}
                            theme={theme}
                            user={user}
                      />   */}
          
      
        </Container>
       
    );
}

export default NotificationsList