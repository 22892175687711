export const fingersCrossed = [
    "48 48",
    `
<path d="M31.5398 4.99848C31.2848 4.16848 30.7908 3.63648 30.1488 3.49548C29.5168 3.35748 28.8348 3.63548 28.2588 4.28448C27.7528 4.85548 27.3658 5.66448 27.1758 6.54048L21.9648 25.6845L29.0888 25.3635C29.0888 25.3635 31.5628 7.67248 31.5828 7.52448C31.7128 6.55548 31.7638 5.72848 31.5398 4.99848Z" fill="url(#paint0_radial_15350_109613)"/>
<path d="M31.5398 4.99848C31.2848 4.16848 30.7908 3.63648 30.1488 3.49548C29.5168 3.35748 28.8348 3.63548 28.2588 4.28448C27.7528 4.85548 27.3658 5.66448 27.1758 6.54048L21.9648 25.6845L29.0888 25.3635C29.0888 25.3635 31.5628 7.67248 31.5828 7.52448C31.7128 6.55548 31.7638 5.72848 31.5398 4.99848Z" fill="url(#paint1_radial_15350_109613)"/>
<path opacity="0.15" d="M26.0438 12.4853C24.9688 17.3493 25.0438 21.5013 26.2118 21.7593C27.3798 22.0173 29.1978 18.2833 30.2728 13.4193C31.3478 8.55533 31.2728 4.40332 30.1048 4.14532C28.9368 3.88832 27.1188 7.62133 26.0438 12.4853Z" fill="url(#paint2_radial_15350_109613)"/>
<path d="M38.1818 26.1845C38.1818 24.7165 37.7278 23.2875 36.8808 22.0885C33.7188 17.6115 26.2538 8.2355 26.1588 8.1065C25.6458 7.3425 24.9878 6.7315 24.3058 6.3885C23.5328 5.9985 22.8048 5.9845 22.2588 6.3525C21.7148 6.7195 21.4498 7.3965 21.5208 8.2625C21.5808 9.0225 21.8988 9.8625 22.4038 10.6115L30.5318 25.3095C30.5318 25.3095 13.5638 25.9935 13.1788 26.3185C12.4658 26.9195 12.0478 27.8075 11.9408 28.7345C11.8058 29.9065 11.7368 31.1195 11.7368 32.3425C11.7368 35.1785 12.1068 37.9305 12.8068 40.3015C13.5318 42.7575 15.8318 44.4735 18.4028 44.4735L30.0308 44.4585C32.3918 44.3495 34.8758 42.7925 36.5128 40.3965C37.5858 38.8265 38.1988 37.0275 38.2698 35.3405L38.2858 29.2725C38.2948 29.1405 38.1818 26.1845 38.1818 26.1845Z" fill="url(#paint3_radial_15350_109613)"/>
<path opacity="0.2" d="M13.6658 37.3589C15.2508 41.3529 18.5368 43.7969 21.0068 42.8169C23.4768 41.8369 24.1938 37.8049 22.6098 33.8109C21.0258 29.8169 17.7388 27.3729 15.2688 28.3529C12.7988 29.3329 12.0808 33.3639 13.6658 37.3589Z" fill="url(#paint4_radial_15350_109613)"/>
<g opacity="0.9">
<path opacity="0.063" d="M13.5173 28.0332C12.8043 28.6342 12.3853 29.5232 12.2793 30.4492C12.1583 31.5022 11.7523 30.2442 11.7413 31.3242C11.7393 31.3422 11.7793 31.7862 11.8953 32.4242C11.8963 32.4322 11.8983 32.4392 11.8993 32.4472C12.1913 33.9822 13.0133 36.8442 15.1373 36.7372C16.7383 36.6572 17.3063 35.5442 17.4893 34.5392C17.5633 34.1312 18.0073 33.8932 18.3623 34.0502C18.4073 34.0702 18.4503 34.0952 18.4923 34.1262C18.8423 34.3912 19.2353 34.5272 19.7223 34.5672C21.0423 34.6752 22.5693 33.7642 22.7763 31.2482C22.7763 31.2482 23.2373 25.5152 23.4183 24.2892C23.3263 24.2852 22.9793 24.3752 22.4833 24.5512C20.0463 25.4132 13.8393 27.7612 13.5173 28.0332Z" fill="#D9AC86"/>
<path opacity="0.125" d="M13.488 27.895C12.775 28.496 12.356 29.385 12.25 30.311C12.132 31.333 11.75 30.179 11.738 31.209C11.735 31.244 11.772 31.679 11.878 32.309C11.879 32.317 11.88 32.324 11.882 32.332C12.147 33.78 12.935 36.708 15.108 36.599C16.716 36.518 17.281 35.398 17.462 34.389C17.535 33.98 17.982 33.747 18.335 33.911C18.379 33.932 18.422 33.957 18.464 33.989C18.813 34.254 19.206 34.389 19.693 34.429C21.013 34.537 22.54 33.626 22.747 31.11C22.747 31.11 23.199 25.48 23.383 24.202C23.3 24.173 22.951 24.237 22.464 24.409C20.033 25.268 13.81 27.623 13.488 27.895Z" fill="#D8AA84"/>
<path opacity="0.188" d="M13.4583 27.7592C12.7453 28.3602 12.3263 29.2492 12.2203 30.1752C12.1063 31.1652 11.7483 30.1152 11.7343 31.0962C11.7293 31.1492 11.7653 31.5742 11.8613 32.1962C11.8623 32.2042 11.8633 32.2112 11.8653 32.2192C12.1053 33.5802 12.8583 36.5742 15.0793 36.4632C16.6933 36.3822 17.2563 35.2532 17.4353 34.2422C17.5073 33.8322 17.9573 33.6032 18.3083 33.7752C18.3523 33.7962 18.3953 33.8222 18.4363 33.8542C18.7853 34.1192 19.1783 34.2542 19.6643 34.2932C20.9843 34.4012 22.5113 33.4902 22.7183 30.9742C22.7183 30.9742 23.1623 25.4462 23.3483 24.1172C23.2743 24.0622 22.9243 24.1002 22.4453 24.2702C20.0203 25.1262 13.7813 27.4872 13.4583 27.7592Z" fill="#D6A881"/>
<path opacity="0.25" d="M13.4284 27.6248C12.7154 28.2258 12.2964 29.1148 12.1904 30.0408C12.0794 30.9998 11.7454 30.0538 11.7304 30.9848C11.7234 31.0558 11.7574 31.4708 11.8434 32.0848C11.8444 32.0928 11.8454 32.0998 11.8464 32.1088C12.0624 33.3818 12.7794 36.4428 15.0474 36.3288C16.6674 36.2478 17.2284 35.1098 17.4054 34.0958C17.4774 33.6848 17.9294 33.4598 18.2784 33.6398C18.3214 33.6618 18.3644 33.6888 18.4054 33.7198C18.7544 33.9838 19.1464 34.1188 19.6324 34.1578C20.9524 34.2658 22.4794 33.3548 22.6864 30.8388C22.6864 30.8388 23.1224 25.4128 23.3104 24.0328C23.2454 23.9518 22.8934 23.9648 22.4224 24.1308C20.0074 24.9858 13.7524 27.3528 13.4284 27.6248Z" fill="#D5A67F"/>
<path opacity="0.313" d="M13.3991 27.4878C12.6861 28.0888 12.2671 28.9778 12.1611 29.9038C12.0541 30.8308 11.7431 29.9898 11.7271 30.8698C11.7181 30.9588 11.7491 31.3648 11.8271 31.9698C11.8281 31.9778 11.8291 31.9848 11.8301 31.9938C12.0231 33.1788 12.7031 36.3078 15.0191 36.1908C16.6451 36.1088 17.2041 34.9638 17.3781 33.9468C17.4491 33.5348 17.9041 33.3147 18.2511 33.5017C18.2941 33.5247 18.3361 33.5518 18.3771 33.5828C18.7261 33.8468 19.1181 33.9808 19.6031 34.0208C20.9231 34.1288 22.4501 33.2178 22.6571 30.7018C22.6571 30.7018 23.0851 25.3778 23.2751 23.9468C23.2191 23.8408 22.8651 23.8268 22.4031 23.9908C19.9941 24.8418 13.7221 27.2148 13.3991 27.4878Z" fill="#D4A47D"/>
<path opacity="0.375" d="M13.3692 27.3519C12.6562 27.9529 12.2372 28.8419 12.1312 29.7679C12.0282 30.6639 11.7412 29.9259 11.7232 30.7569C11.7132 30.8629 11.7402 31.2599 11.8102 31.8569C11.8112 31.8659 11.8112 31.8719 11.8132 31.8809C11.9842 32.9779 12.6262 36.1739 14.9902 36.0549C16.6222 35.9729 17.1792 34.8189 17.3512 33.7989C17.4212 33.3869 17.8792 33.1699 18.2242 33.3649C18.2672 33.3889 18.3092 33.4159 18.3492 33.4469C18.6972 33.7099 19.0892 33.8439 19.5742 33.8839C20.8942 33.9919 22.4212 33.0809 22.6282 30.5649C22.6282 30.5649 23.0482 25.3429 23.2392 23.8619C23.1912 23.7299 22.8362 23.6909 22.3832 23.8509C19.9812 24.6999 13.6932 27.0779 13.3692 27.3519Z" fill="#D3A27B"/>
<path opacity="0.438" d="M13.3393 27.2157C12.6263 27.8167 12.2073 28.7057 12.1013 29.6317C12.0013 30.4957 11.7383 29.8627 11.7193 30.6437C11.7073 30.7677 11.7313 31.1547 11.7923 31.7437C11.7933 31.7527 11.7933 31.7587 11.7953 31.7677C11.9463 32.7767 12.5483 36.0397 14.9593 35.9187C16.5973 35.8367 17.1523 34.6737 17.3223 33.6517C17.3913 33.2387 17.8523 33.0257 18.1953 33.2287C18.2373 33.2537 18.2793 33.2817 18.3193 33.3117C18.6673 33.5747 19.0593 33.7087 19.5433 33.7477C20.8633 33.8557 22.3903 32.9447 22.5973 30.4287C22.5973 30.4287 23.0083 25.3087 23.2023 23.7767C23.1633 23.6197 22.8063 23.5537 22.3613 23.7117C19.9683 24.5567 13.6643 26.9417 13.3393 27.2157Z" fill="#D1A079"/>
<path opacity="0.5" d="M13.3101 27.0803C12.5971 27.6813 12.1781 28.5703 12.0721 29.4963C11.9761 30.3293 11.7371 29.8003 11.7161 30.5313C11.7021 30.6733 11.7231 31.0513 11.7761 31.6323C11.7771 31.6413 11.7771 31.6473 11.7781 31.6563C11.9101 32.5763 12.4711 35.9073 14.9301 35.7843C16.5741 35.7013 17.1281 34.5303 17.2951 33.5063C17.3631 33.0923 17.8271 32.8833 18.1681 33.0943C18.2101 33.1203 18.2511 33.1483 18.2921 33.1783C18.6401 33.4403 19.0311 33.5743 19.5151 33.6143C20.8351 33.7223 22.3621 32.8113 22.5691 30.2953C22.5691 30.2953 22.9721 25.2773 23.1681 23.6943C23.1381 23.5113 22.7791 23.4203 22.3431 23.5743C19.9551 24.4153 13.6351 26.8063 13.3101 27.0803Z" fill="#D09E77"/>
<path opacity="0.563" d="M13.2803 26.9446C12.5673 27.5456 12.1483 28.4346 12.0423 29.3606C11.9503 30.1616 11.7343 29.7376 11.7123 30.4186C11.6963 30.5786 11.7143 30.9466 11.7583 31.5196C11.7593 31.5286 11.7593 31.5346 11.7603 31.5446C11.8733 32.3756 12.3933 35.7756 14.9003 35.6496C16.5503 35.5666 17.1023 34.3876 17.2673 33.3596C17.3343 32.9446 17.8013 32.7396 18.1403 32.9586C18.1813 32.9856 18.2223 33.0136 18.2633 33.0436C18.6113 33.3056 19.0023 33.4386 19.4853 33.4786C20.8053 33.5866 22.3323 32.6756 22.5393 30.1596C22.5393 30.1596 22.9343 25.2446 23.1323 23.6096C23.1113 23.4016 22.7503 23.2846 22.3233 23.4356C19.9423 24.2736 13.6063 26.6706 13.2803 26.9446Z" fill="#CF9B74"/>
<path opacity="0.625" d="M13.2501 26.8076C12.5371 27.4086 12.1181 28.2976 12.0121 29.2236C11.9231 29.9936 11.7321 29.6726 11.7081 30.3036C11.6911 30.4806 11.7041 30.8406 11.7411 31.4046C11.7421 31.4136 11.7421 31.4196 11.7431 31.4296C11.8391 32.1726 12.3161 35.6396 14.8711 35.5116C16.5271 35.4286 17.0771 34.2406 17.2401 33.2106C17.3061 32.7946 17.7761 32.5936 18.1131 32.8206C18.1541 32.8486 18.1951 32.8766 18.2351 32.9076C18.5821 33.1686 18.9731 33.3026 19.4561 33.3416C20.7761 33.4496 22.3031 32.5386 22.5101 30.0226C22.5101 30.0226 22.8971 25.2096 23.0971 23.5236C23.0851 23.2896 22.7221 23.1466 22.3031 23.2946C19.9301 24.1306 13.5761 26.5326 13.2501 26.8076Z" fill="#CE9972"/>
<path opacity="0.688" d="M13.2213 26.6744C12.5083 27.2754 12.0893 28.1644 11.9833 29.0904C11.8983 29.8294 11.7303 29.6124 11.7053 30.1934C11.6863 30.3884 11.6953 30.7384 11.7243 31.2944C11.7253 31.3044 11.7243 31.3094 11.7263 31.3194C11.8063 31.9734 12.2393 35.5084 14.8413 35.3784C16.5043 35.2954 17.0513 34.0984 17.2123 33.0654C17.2773 32.6484 17.7513 32.4514 18.0853 32.6874C18.1253 32.7154 18.1663 32.7454 18.2063 32.7754C18.5533 33.0364 18.9443 33.1694 19.4263 33.2084C20.7463 33.3164 22.2733 32.4054 22.4803 29.8894C22.4803 29.8894 22.8593 25.1784 23.0603 23.4424C23.0573 23.1824 22.6923 23.0144 22.2823 23.1584C19.9173 23.9914 13.5473 26.3994 13.2213 26.6744Z" fill="#CC9770"/>
<path opacity="0.75" d="M13.1914 26.5392C12.4784 27.1402 12.0594 28.0292 11.9534 28.9552C11.8714 29.6622 11.7274 29.5502 11.7014 30.0812C11.6804 30.2942 11.6854 30.6352 11.7074 31.1822C11.7074 31.1922 11.7074 31.1972 11.7084 31.2072C11.7724 31.7712 12.1614 35.3762 14.8114 35.2422C16.4804 35.1582 17.0254 33.9542 17.1844 32.9182C17.2484 32.5002 17.7254 32.3062 18.0574 32.5512C18.0974 32.5802 18.1374 32.6102 18.1774 32.6402C18.5244 32.9002 18.9144 33.0332 19.3964 33.0732C20.7164 33.1812 22.2434 32.2702 22.4504 29.7542C22.4504 29.7542 22.8204 25.1452 23.0244 23.3582C23.0304 23.0732 22.6634 22.8782 22.2614 23.0202C19.9044 23.8492 13.5184 26.2632 13.1914 26.5392Z" fill="#CB956E"/>
<path opacity="0.813" d="M13.1611 26.401C12.4481 27.002 12.0291 27.891 11.9231 28.817C11.8451 29.493 11.7251 29.485 11.6971 29.966C11.6751 30.197 11.6741 30.528 11.6901 31.067C11.6901 31.077 11.6901 31.082 11.6911 31.092C11.7401 31.567 12.0841 35.24 14.7821 35.104C16.4571 35.02 17.0001 33.807 17.1561 32.769C17.2191 32.351 17.6991 32.16 18.0291 32.413C18.0691 32.443 18.1081 32.473 18.1481 32.503C18.4951 32.763 18.8851 32.895 19.3661 32.935C20.6861 33.043 22.2131 32.132 22.4201 29.616C22.4201 29.616 22.7821 25.109 22.9881 23.271C23.0031 22.96 22.6341 22.74 22.2411 22.878C19.8911 23.704 13.4891 26.125 13.1611 26.401Z" fill="#CA936C"/>
<path opacity="0.875" d="M13.132 26.2672C12.419 26.8682 12 27.7572 11.894 28.6832C11.82 29.3272 11.723 29.4232 11.694 29.8552C11.67 30.1032 11.665 30.4262 11.673 30.9562C11.673 30.9662 11.673 30.9712 11.674 30.9822C11.709 31.3682 12.007 35.1092 14.752 34.9712C16.433 34.8872 16.974 33.6652 17.128 32.6242C17.19 32.2052 17.673 32.0192 18.001 32.2792C18.04 32.3102 18.08 32.3402 18.119 32.3702C18.465 32.6292 18.855 32.7622 19.336 32.8012C20.656 32.9092 22.183 31.9982 22.39 29.4822C22.39 29.4822 22.744 25.0772 22.952 23.1882C22.975 22.8522 22.605 22.6052 22.221 22.7412C19.878 23.5642 13.459 25.9902 13.132 26.2672Z" fill="#C99169"/>
<path opacity="0.938" d="M13.102 26.1313C12.389 26.7323 11.97 27.6213 11.864 28.5473C11.793 29.1603 11.721 29.3603 11.69 29.7413C11.664 30.0073 11.654 30.3203 11.656 30.8423C11.656 30.8523 11.656 30.8583 11.656 30.8683C11.678 31.1653 11.929 34.9743 14.722 34.8343C16.409 34.7493 16.948 33.5203 17.1 32.4763C17.161 32.0563 17.648 31.8743 17.973 32.1423C18.012 32.1743 18.051 32.2053 18.091 32.2343C18.437 32.4933 18.827 32.6253 19.307 32.6643C20.627 32.7723 22.154 31.8613 22.361 29.3453C22.361 29.3453 22.707 25.0433 22.917 23.1023C22.949 22.7403 22.578 22.4683 22.201 22.6003C19.865 23.4223 13.43 25.8543 13.102 26.1313Z" fill="#C78F67"/>
<path opacity="0.9" d="M22.3312 29.2082C22.3312 29.2082 22.6692 25.0082 22.8812 23.0162C22.9222 22.6292 22.5492 22.3312 22.1812 22.4602C19.8522 23.2782 13.4012 25.7172 13.0732 25.9942C12.3602 26.5952 11.9412 27.4842 11.8352 28.4102C11.7112 29.4892 11.6492 29.6152 11.6392 30.7292C11.6392 30.7402 11.6392 30.7452 11.6392 30.7552C11.6482 30.9632 11.8522 34.8412 14.6932 34.6982C16.3862 34.6132 16.9232 33.3752 17.0732 32.3282C17.1332 31.9072 17.6232 31.7292 17.9462 32.0052C18.3212 32.3252 18.7432 32.4842 19.2782 32.5282C20.5972 32.6352 22.1242 31.7242 22.3312 29.2082Z" fill="#C68D65"/>
</g>
<path opacity="0.063" d="M27.455 22.5566L26.7 22.5656L24.249 17.3006L24.519 16.3086L27.455 22.5566Z" fill="url(#paint5_linear_15350_109613)"/>
<path opacity="0.125" d="M27.5589 22.5576L26.7999 22.5656L24.2769 17.2026L24.5419 16.2266L27.5589 22.5576Z" fill="url(#paint6_linear_15350_109613)"/>
<path opacity="0.188" d="M27.6622 22.5536L26.9002 22.5616L24.3062 17.1006L24.5662 16.1406L27.6622 22.5536Z" fill="url(#paint7_linear_15350_109613)"/>
<path opacity="0.25" d="M27.765 22.5546L27 22.5616L24.334 17.0026L24.59 16.0586L27.765 22.5546Z" fill="url(#paint8_linear_15350_109613)"/>
<path opacity="0.313" d="M27.8688 22.5556L27.1008 22.5616L24.3628 16.9046L24.6138 15.9766L27.8688 22.5556Z" fill="url(#paint9_linear_15350_109613)"/>
<path opacity="0.375" d="M27.9721 22.5565L27.2011 22.5615L24.3911 16.8055L24.6371 15.8945L27.9721 22.5565Z" fill="url(#paint10_linear_15350_109613)"/>
<path opacity="0.438" d="M28.0759 22.5555L27.3009 22.5615L24.4199 16.7065L24.6609 15.8125L28.0759 22.5555Z" fill="url(#paint11_linear_15350_109613)"/>
<path opacity="0.5" d="M28.1792 22.5526L27.4012 22.5576L24.4482 16.6046L24.6852 15.7266L28.1792 22.5526Z" fill="url(#paint12_linear_15350_109613)"/>
<path opacity="0.563" d="M28.2831 22.5535L27.5011 22.5575L24.4761 16.5065L24.7091 15.6445L28.2831 22.5535Z" fill="url(#paint13_linear_15350_109613)"/>
<path opacity="0.625" d="M28.3859 22.5535L27.6009 22.5575L24.5049 16.4085L24.7319 15.5625L28.3859 22.5535Z" fill="url(#paint14_linear_15350_109613)"/>
<path opacity="0.688" d="M28.4902 22.5545L27.7012 22.5575L24.5332 16.3105L24.7562 15.4805L28.4902 22.5545Z" fill="url(#paint15_linear_15350_109613)"/>
<path opacity="0.75" d="M28.593 22.5515L27.802 22.5535L24.562 16.2085L24.78 15.3945L28.593 22.5515Z" fill="url(#paint16_linear_15350_109613)"/>
<path opacity="0.813" d="M28.6968 22.5515L27.9018 22.5535L24.5898 16.1105L24.8038 15.3125L28.6968 22.5515Z" fill="url(#paint17_linear_15350_109613)"/>
<path opacity="0.875" d="M28.8001 22.5525L28.0021 22.5535L24.6191 16.0125L24.8271 15.2305L28.8001 22.5525Z" fill="url(#paint18_linear_15350_109613)"/>
<path opacity="0.938" d="M28.904 22.5534H28.102L24.647 15.9144L24.851 15.1484L28.904 22.5534Z" fill="url(#paint19_linear_15350_109613)"/>
<path d="M24.8748 15.0664L29.0068 22.5524H28.2018L24.6758 15.8154L24.8748 15.0664Z" fill="url(#paint20_linear_15350_109613)"/>
<path d="M22.559 22.4337H22.555C22.534 20.7957 21.202 19.4727 19.559 19.4727C17.916 19.4727 16.458 20.7337 16.563 22.4337L17.049 29.7217C17.162 31.2637 18.237 32.4777 19.562 32.4777C20.886 32.4777 21.961 31.2637 22.074 29.7217L22.559 22.4337Z" fill="url(#paint21_radial_15350_109613)"/>
<path d="M16.7052 24.4295C16.5842 22.7555 15.0502 21.6265 13.4152 21.7985C11.7802 21.9705 10.4572 23.3295 10.7452 25.0565L11.9912 32.2535C12.2652 33.7755 13.4612 34.8695 14.7782 34.7315C16.0952 34.5925 17.0372 33.2735 16.9882 31.7275L16.7052 24.4295Z" fill="url(#paint22_radial_15350_109613)"/>
<path opacity="0.15" d="M26.7278 16.4377C29.7178 20.7577 32.9048 23.7317 33.8478 23.0787C34.7908 22.4257 33.1318 18.3947 30.1418 14.0747C27.1518 9.75466 23.9648 6.78066 23.0218 7.43366C22.0798 8.08566 23.7388 12.1177 26.7278 16.4377Z" fill="url(#paint23_radial_15350_109613)"/>
<path opacity="0.2" d="M19.5578 31.3414C20.817 31.3414 21.8378 28.9389 21.8378 25.9754C21.8378 23.0118 20.817 20.6094 19.5578 20.6094C18.2986 20.6094 17.2778 23.0118 17.2778 25.9754C17.2778 28.9389 18.2986 31.3414 19.5578 31.3414Z" fill="url(#paint24_radial_15350_109613)"/>
<path opacity="0.2" d="M11.6198 28.5783C11.9658 31.5213 13.2598 33.7883 14.5098 33.6413C15.7608 33.4943 16.4938 30.9893 16.1488 28.0463C15.8038 25.1033 14.5088 22.8363 13.2588 22.9833C12.0078 23.1303 11.2738 25.6353 11.6198 28.5783Z" fill="url(#paint25_radial_15350_109613)"/>
<path opacity="0.091" d="M34.8772 32.5289C34.8362 29.9459 33.5472 27.9169 32.3392 26.4939C31.1562 25.1019 29.6302 24.0349 28.1012 23.4499C27.6102 23.2619 27.1332 23.1149 26.6372 23.0499L23.0452 21.8039C21.8392 21.6429 20.7612 22.3539 20.5012 23.5419C19.9772 25.9599 20.8342 28.6209 24.6382 29.2009C25.2442 29.2929 25.6012 30.0279 25.2192 30.4339C23.4792 32.2749 22.8172 34.4399 22.6722 35.3079C21.8292 38.6989 23.8292 42.2429 27.3132 43.5539C28.0552 43.8329 28.8122 43.9829 29.5602 44.0229C30.3512 43.5249 31.1022 42.9089 31.7652 42.1729C33.0372 40.7589 33.8832 39.4209 34.1782 37.7589L34.8722 32.6759L34.8772 32.5289Z" fill="url(#paint26_linear_15350_109613)"/>
<path opacity="0.182" d="M35.1231 32.4845C35.0821 29.9015 33.7931 27.8725 32.5851 26.4495C31.4021 25.0575 29.8761 23.9905 28.3471 23.4055C27.8561 23.2175 27.3791 23.0705 26.8831 23.0055L23.0461 21.8035C21.8401 21.6425 20.7591 22.3675 20.5091 23.5575C19.9981 26.0285 21.0511 28.5575 24.8551 29.1375C25.4611 29.2295 25.9671 29.9125 25.6261 30.2805C23.8781 32.1715 23.2561 34.2145 23.0771 35.1575C22.2341 38.5485 24.0741 41.8335 27.5581 43.1455C28.3001 43.4245 29.0571 43.5745 29.8051 43.6145C30.5961 43.1165 31.3471 42.5005 32.0101 41.7645C33.2821 40.3505 34.1281 39.3765 34.4231 37.7135L35.1171 32.6305L35.1231 32.4845Z" fill="url(#paint27_linear_15350_109613)"/>
<path opacity="0.273" d="M35.3683 32.4392C35.3273 29.8562 34.0383 27.8272 32.8303 26.4042C31.6473 25.0122 30.1213 23.9452 28.5923 23.3602C28.1013 23.1722 27.6243 23.0252 27.1283 22.9602L23.0453 21.8032C21.8393 21.6422 20.7553 22.3802 20.5153 23.5722C20.0173 26.0962 21.2673 28.4942 25.0703 29.0732C25.6763 29.1652 26.3323 29.7962 26.0323 30.1272C24.2753 32.0692 23.6933 33.9892 23.4823 35.0072C22.6393 38.3982 24.3183 41.4242 27.8033 42.7362C28.5453 43.0152 29.3023 43.1652 30.0503 43.2052C30.8413 42.7072 31.5923 42.0912 32.2553 41.3552C33.5273 39.9412 34.3733 39.3312 34.6683 37.6682L35.3623 32.5852L35.3683 32.4392Z" fill="url(#paint28_linear_15350_109613)"/>
<path opacity="0.364" d="M35.6144 32.3939C35.5734 29.8109 34.2844 27.7819 33.0764 26.3589C31.8934 24.9669 30.3674 23.8999 28.8384 23.3149C28.3474 23.1269 27.8704 22.9799 27.3744 22.9149L23.0464 21.8029C21.8404 21.6419 20.7504 22.3929 20.5234 23.5869C20.0324 26.1629 21.4844 28.4299 25.2884 29.0099C25.8944 29.1019 26.7004 29.6819 26.4414 29.9739C24.6754 31.9679 24.1334 33.7639 23.8904 34.8579C23.0474 38.2489 24.5664 41.0169 28.0504 42.3279C28.7924 42.6069 29.5494 42.7569 30.2974 42.7969C31.0884 42.2989 31.8394 41.6829 32.5024 40.9469C33.7744 39.5329 34.6204 39.2859 34.9154 37.6239L35.6094 32.5409L35.6144 32.3939Z" fill="url(#paint29_linear_15350_109613)"/>
<path opacity="0.455" d="M35.8593 32.3496C35.8183 29.7666 34.5293 27.7376 33.3213 26.3146C32.1383 24.9226 30.6123 23.8556 29.0833 23.2706C28.5923 23.0826 28.1153 22.9356 27.6193 22.8706L23.0453 21.8026C21.8393 21.6416 20.7463 22.4066 20.5293 23.6016C20.0513 26.2306 21.6993 28.3656 25.5033 28.9456C26.1093 29.0376 27.0663 29.5676 26.8473 29.8206C25.0713 31.8666 24.5703 33.5376 24.2953 34.7076C23.4523 38.0986 24.8113 40.6076 28.2953 41.9186C29.0373 42.1976 29.7943 42.3476 30.5423 42.3876C31.3333 41.8896 32.0843 41.2736 32.7473 40.5376C34.0193 39.1236 34.8653 39.2406 35.1603 37.5776L35.8543 32.4946L35.8593 32.3496Z" fill="url(#paint30_linear_15350_109613)"/>
<path opacity="0.545" d="M36.1054 32.3043C36.0644 29.7213 34.7754 27.6923 33.5674 26.2693C32.3844 24.8773 30.8584 23.8103 29.3294 23.2253C28.8384 23.0373 28.3614 22.8903 27.8654 22.8253L23.0464 21.8023C21.8404 21.6413 20.7444 22.4193 20.5374 23.6173C20.0724 26.2993 21.9164 28.3033 25.7204 28.8823C26.3264 28.9743 27.4364 29.4553 27.2554 29.6673C25.4684 31.7653 25.0094 33.3123 24.7024 34.5573C23.8594 37.9483 25.0584 40.1983 28.5424 41.5103C29.2844 41.7893 30.0414 41.9393 30.7894 41.9793C31.5804 41.4813 32.3314 40.8653 32.9944 40.1293C34.2664 38.7153 35.1124 39.1963 35.4074 37.5333L36.1014 32.4503L36.1054 32.3043Z" fill="url(#paint31_linear_15350_109613)"/>
<path opacity="0.636" d="M36.35 32.2629C36.309 29.6799 35.02 27.6509 33.812 26.2279C32.629 24.8359 31.103 23.7689 29.574 23.1839C29.083 22.9959 28.606 22.8489 28.11 22.7839L23.045 21.8059C21.839 21.6449 20.741 22.4369 20.542 23.6359C20.09 26.3709 22.13 28.2429 25.934 28.8229C26.54 28.9149 27.803 29.3469 27.66 29.5189C25.862 31.6699 25.445 33.0919 25.106 34.4129C24.263 37.8039 25.302 39.7949 28.786 41.1069C29.528 41.3859 30.285 41.5359 31.033 41.5759C31.824 41.0779 32.575 40.4619 33.238 39.7259C34.51 38.3119 35.356 39.1559 35.651 37.4939L36.345 32.4109L36.35 32.2629Z" fill="url(#paint32_linear_15350_109613)"/>
<path opacity="0.727" d="M36.596 32.2176C36.555 29.6346 35.266 27.6056 34.058 26.1826C32.875 24.7906 31.349 23.7236 29.82 23.1386C29.329 22.9506 28.852 22.8036 28.356 22.7386L23.046 21.8056C21.84 21.6446 20.741 22.4506 20.55 23.6506C20.117 26.4396 22.347 28.1786 26.151 28.7586C26.757 28.8506 28.174 29.2356 28.068 29.3646C26.259 31.5696 25.884 32.8656 25.513 34.2616C24.67 37.6526 25.549 39.3856 29.033 40.6966C29.775 40.9756 30.532 41.1256 31.28 41.1656C32.071 40.6676 32.822 40.0516 33.485 39.3156C34.757 37.9016 35.603 39.1096 35.898 37.4466L36.592 32.3636L36.596 32.2176Z" fill="url(#paint33_linear_15350_109613)"/>
<path opacity="0.818" d="M36.8414 32.1733C36.8004 29.5903 35.5114 27.5613 34.3034 26.1383C33.1204 24.7463 31.5944 23.6793 30.0654 23.0943C29.5744 22.9063 29.0974 22.7593 28.6014 22.6943L23.0454 21.8053C21.8394 21.6443 20.7374 22.4633 20.5564 23.6653C20.1364 26.5073 22.5624 28.1153 26.3664 28.6943C26.9724 28.7863 28.5444 29.1243 28.4744 29.2103C26.6534 31.4693 26.3214 32.6393 25.9174 34.1103C25.0744 37.5013 25.7924 38.9753 29.2764 40.2863C30.0184 40.5653 30.7754 40.7153 31.5234 40.7553C32.3144 40.2573 33.0654 39.6413 33.7284 38.9053C35.0004 37.4913 35.8464 39.0633 36.1414 37.4003L36.8354 32.3173L36.8414 32.1733Z" fill="url(#paint34_linear_15350_109613)"/>
<path opacity="0.909" d="M37.0872 32.1281C37.0462 29.5451 35.7572 27.5161 34.5492 26.0931C33.3662 24.7011 31.8402 23.6341 30.3112 23.0491C29.8202 22.8611 29.3432 22.7141 28.8472 22.6491L23.0462 21.8051C21.8402 21.6441 20.7352 22.4771 20.5642 23.6811C20.1562 26.5761 22.7792 28.0521 26.5832 28.6321C27.1892 28.7241 28.9162 29.0151 28.8812 29.0591C27.0482 31.3731 26.7592 32.4151 26.3232 33.9631C25.4802 37.3541 26.0382 38.5691 29.5222 39.8801C30.2642 40.1591 31.0212 40.3091 31.7692 40.3491C32.5602 39.8511 33.3112 39.2351 33.9742 38.4991C35.2462 37.0851 36.0922 39.0201 36.3872 37.3571L37.0812 32.2741L37.0872 32.1281Z" fill="url(#paint35_linear_15350_109613)"/>
<path d="M37.3331 32.0828C37.2921 29.4998 36.0031 27.4708 34.7951 26.0478C33.6121 24.6558 32.0861 23.5888 30.5571 23.0038C30.0661 22.8158 29.5891 22.6688 29.0931 22.6038L23.0461 21.8048C21.8401 21.6438 20.7321 22.4898 20.5711 23.6958C20.1761 26.6448 22.9961 27.9878 26.7991 28.5678C27.4051 28.6598 29.2881 28.9048 29.2881 28.9048C27.7051 31.4568 27.1971 32.1888 26.7291 33.8118C25.8861 37.2028 26.2841 38.1588 29.7681 39.4708C30.5101 39.7498 31.2671 39.8998 32.0151 39.9398C32.8061 39.4418 33.5571 38.8258 34.2201 38.0898C35.4921 36.6758 36.3381 38.9748 36.6331 37.3118L37.3271 32.2288L37.3331 32.0828Z" fill="url(#paint36_linear_15350_109613)"/>
<path d="M29.6611 43.3746C29.5061 43.4176 29.3311 43.3856 29.1991 43.2706C27.7431 42.0086 26.6821 40.2936 26.1301 38.3096C25.0201 34.3196 26.3061 30.2346 29.2581 28.3756C29.4951 28.2246 29.8011 28.2986 29.9471 28.5316C30.0951 28.7656 30.0251 29.0736 29.7911 29.2206C27.2111 30.8466 26.1011 34.4736 27.0931 38.0416C27.5851 39.8096 28.5651 41.3976 29.8541 42.5146C30.0631 42.6956 30.0861 43.0116 29.9041 43.2206C29.8381 43.2966 29.7531 43.3486 29.6611 43.3746Z" fill="url(#paint37_linear_15350_109613)"/>
<path d="M38.271 30.0666C37.887 27.5116 36.341 25.6716 34.954 24.4226C33.597 23.1996 31.943 22.3456 30.349 21.9676C29.837 21.8466 29.345 21.7646 28.844 21.7656L22.745 21.7756C21.529 21.7756 20.543 22.7616 20.543 23.9776C20.543 27.9436 28.443 28.3826 28.443 28.3826C28.986 28.4816 29.5 28.5366 29.993 28.5666C29.434 29.0866 28.926 29.6606 28.5 30.2946C27.112 32.3576 26.673 33.7706 26.664 34.7286C26.279 38.2016 27.906 41.8056 31.393 43.1086C33.329 43.8316 33.907 43.0716 34.573 42.5246C35.291 41.9266 35.953 41.2156 36.513 40.3976C37.586 38.8276 38.199 37.0286 38.27 35.3416L38.284 30.2116L38.271 30.0666Z" fill="#DAAE88"/>
<path opacity="0.15" d="M27.9062 22.4354C24.5272 21.8254 21.5762 22.5014 21.3162 23.9444C21.0562 25.3874 23.5842 27.0524 26.9642 27.6614C30.3442 28.2704 33.2942 27.5954 33.5542 26.1524C33.8142 24.7084 31.2852 23.0444 27.9062 22.4354Z" fill="url(#paint38_radial_15350_109613)"/>
<g opacity="0.3">
<path opacity="0.091" d="M21.4502 22.209C20.9842 22.738 20.6142 23.349 20.6042 23.806C20.6012 23.932 20.6082 24.054 20.6222 24.174C20.7172 25.01 21.1762 25.683 21.8272 26.224C22.5622 26.835 23.5432 27.279 24.5232 27.598C26.4842 28.237 28.4442 28.38 28.4442 28.38C28.9872 28.479 29.4982 28.536 29.9922 28.567C30.1622 28.374 30.2262 28.299 30.4132 28.111C30.5912 27.909 30.7742 27.699 31.0302 27.419C30.8632 27.409 30.5752 27.397 30.2002 27.373C29.4102 27.322 28.2502 27.218 27.0382 26.971C26.7292 26.908 26.4172 26.836 26.1072 26.753C25.3292 26.545 24.5632 26.27 23.8902 25.905C23.6362 25.767 23.3962 25.616 23.1742 25.451C22.5722 25.005 22.1012 24.455 21.8482 23.777C21.7612 23.543 21.7012 23.295 21.6712 23.034C21.6262 22.63 21.7462 22.267 21.9292 21.957C21.7522 22.013 21.5922 22.098 21.4502 22.209Z" fill="url(#paint39_linear_15350_109613)"/>
<path opacity="0.182" d="M21.483 22.188C21.002 22.695 20.622 23.302 20.602 23.783C20.597 23.909 20.602 24.033 20.614 24.154C20.7 25.005 21.162 25.688 21.822 26.235C22.558 26.845 23.539 27.287 24.52 27.604C26.482 28.239 28.444 28.379 28.444 28.379C28.987 28.478 29.496 28.537 29.99 28.568C30.18 28.353 30.169 28.353 30.394 28.132C30.536 27.949 30.665 27.759 30.848 27.504C30.673 27.494 30.385 27.481 30.017 27.457C29.218 27.403 28.066 27.296 26.869 27.048C26.56 26.984 26.249 26.91 25.94 26.826C25.166 26.615 24.407 26.335 23.744 25.965C23.493 25.824 23.256 25.671 23.037 25.503C22.447 25.051 21.99 24.496 21.752 23.812C21.67 23.576 21.617 23.329 21.594 23.07C21.56 22.668 21.684 22.306 21.872 22C21.723 22.033 21.594 22.097 21.483 22.188Z" fill="url(#paint40_linear_15350_109613)"/>
<path opacity="0.273" d="M21.5149 22.169C21.0199 22.653 20.6289 23.257 20.5989 23.761C20.5909 23.887 20.5949 24.012 20.6059 24.135C20.6819 25.002 21.1479 25.694 21.8169 26.247C22.5529 26.856 23.5349 27.296 24.5169 27.612C26.4809 28.244 28.4439 28.381 28.4439 28.381C28.9869 28.48 29.4939 28.542 29.9869 28.572C30.1969 28.335 30.1119 28.41 30.3729 28.156C30.4779 27.992 30.5529 27.822 30.6639 27.592C30.4809 27.581 30.1929 27.568 29.8319 27.543C29.0229 27.487 27.8799 27.376 26.6969 27.126C26.3889 27.06 26.0779 26.985 25.7709 26.899C25.0009 26.684 24.2489 26.4 23.5949 26.025C23.3469 25.882 23.1119 25.726 22.8969 25.555C22.3189 25.098 21.8759 24.537 21.6529 23.847C21.5759 23.609 21.5289 23.362 21.5139 23.106C21.4899 22.705 21.6199 22.345 21.8109 22.043C21.6949 22.057 21.5969 22.099 21.5149 22.169Z" fill="url(#paint41_linear_15350_109613)"/>
<path opacity="0.364" d="M21.548 22.1467C21.038 22.6077 20.638 23.2077 20.597 23.7357C20.587 23.8617 20.589 23.9877 20.598 24.1127C20.665 24.9947 21.134 25.6977 21.812 26.2557C22.549 26.8637 23.531 27.3017 24.514 27.6157C26.479 28.2447 28.444 28.3777 28.444 28.3777C28.987 28.4767 29.491 28.5407 29.985 28.5707C30.214 28.3117 30.055 28.4617 30.353 28.1747C30.422 28.0297 30.443 27.8807 30.48 27.6757C30.288 27.6647 30.002 27.6507 29.647 27.6257C28.829 27.5667 27.693 27.4527 26.526 27.2007C26.218 27.1337 25.909 27.0577 25.602 26.9697C24.836 26.7507 24.091 26.4627 23.446 26.0827C23.2 25.9377 22.969 25.7787 22.757 25.6047C22.191 25.1427 21.761 24.5757 21.553 23.8797C21.481 23.6407 21.441 23.3927 21.433 23.1397C21.421 22.7407 21.554 22.3817 21.75 22.0837C21.666 22.0757 21.599 22.0967 21.548 22.1467Z" fill="url(#paint42_linear_15350_109613)"/>
<path opacity="0.455" d="M21.581 22.1276C21.056 22.5666 20.647 23.1626 20.594 23.7146C20.582 23.8416 20.582 23.9686 20.59 24.0946C20.647 24.9926 21.12 25.7046 21.806 26.2686C22.543 26.8746 23.527 27.3116 24.51 27.6246C26.476 28.2506 28.443 28.3796 28.443 28.3796C28.986 28.4786 29.488 28.5446 29.982 28.5746C30.231 28.2936 29.997 28.5186 30.333 28.1986C30.366 28.0726 30.332 27.9436 30.297 27.7636C30.097 27.7516 29.811 27.7376 29.464 27.7126C28.636 27.6516 27.509 27.5336 26.357 27.2796C26.05 27.2116 25.741 27.1336 25.436 27.0446C24.674 26.8226 23.936 26.5296 23.3 26.1446C23.057 25.9966 22.829 25.8346 22.62 25.6586C22.066 25.1906 21.65 24.6186 21.457 23.9156C21.391 23.6746 21.357 23.4266 21.356 23.1766C21.355 22.7796 21.493 22.4216 21.692 22.1276C21.637 22.0986 21.601 22.0976 21.581 22.1276Z" fill="url(#paint43_linear_15350_109613)"/>
<path opacity="0.545" d="M21.613 22.1074C21.073 22.5234 20.655 23.1164 20.591 23.6914C20.577 23.8184 20.575 23.9464 20.582 24.0744C20.63 24.9884 21.106 25.7104 21.801 26.2794C22.539 26.8844 23.523 27.3194 24.507 27.6304C26.475 28.2524 28.443 28.3794 28.443 28.3794C28.986 28.4784 29.486 28.5464 29.979 28.5774C30.247 28.2734 29.939 28.5744 30.312 28.2204C30.309 28.1134 30.221 28.0044 30.113 27.8504C29.904 27.8384 29.619 27.8224 29.279 27.7974C28.442 27.7334 27.323 27.6124 26.186 27.3564C25.879 27.2864 25.571 27.2074 25.268 27.1164C24.51 26.8904 23.779 26.5934 23.152 26.2034C22.911 26.0534 22.686 25.8884 22.481 25.7094C21.939 25.2364 21.537 24.6584 21.359 23.9494C21.298 23.7064 21.27 23.4594 21.277 23.2114C21.287 22.8154 21.43 22.4594 21.633 22.1694C21.609 22.1204 21.603 22.0984 21.613 22.1074Z" fill="url(#paint44_linear_15350_109613)"/>
<path opacity="0.636" d="M21.6458 22.0859C21.0908 22.4799 20.6648 23.0689 20.5888 23.6679C20.5728 23.7949 20.5688 23.9239 20.5738 24.0539C20.6118 24.9829 21.0918 25.7149 21.7958 26.2909C22.5338 26.8949 23.5188 27.3279 24.5038 27.6379C26.4728 28.2569 28.4428 28.3799 28.4428 28.3799C28.9858 28.4789 29.4828 28.5489 29.9768 28.5799C30.2648 28.2539 29.8818 28.6299 30.2918 28.2429C30.2528 28.1559 30.1108 28.0659 29.9288 27.9369C29.7118 27.9239 29.4268 27.9079 29.0938 27.8829C28.2478 27.8169 27.1358 27.6909 26.0148 27.4339C25.7088 27.3629 25.4018 27.2819 25.0988 27.1899C24.3448 26.9599 23.6208 26.6589 23.0028 26.2649C22.7648 26.1119 22.5428 25.9449 22.3408 25.7629C21.8108 25.2849 21.4228 24.7009 21.2598 23.9859C21.2038 23.7409 21.1828 23.4939 21.1968 23.2489C21.2188 22.8549 21.3648 22.4999 21.5718 22.2139C21.5798 22.1409 21.6048 22.0979 21.6458 22.0859Z" fill="url(#paint45_linear_15350_109613)"/>
<path opacity="0.727" d="M21.6791 22.0664C21.1091 22.4374 20.6751 23.0234 20.5871 23.6454C20.5691 23.7734 20.5631 23.9024 20.5671 24.0334C20.5961 24.9784 21.0791 25.7194 21.7921 26.3014C22.5311 26.9044 23.5161 27.3354 24.5021 27.6434C26.4731 28.2594 28.4431 28.3784 28.4431 28.3784C28.9861 28.4774 29.4811 28.5494 29.9751 28.5804C30.2831 28.2324 29.8251 28.6844 30.2731 28.2634C30.1971 28.1954 30.0011 28.1254 29.7471 28.0224C29.5221 28.0094 29.2371 27.9924 28.9111 27.9664C28.0551 27.8974 26.9521 27.7684 25.8451 27.5094C25.5391 27.4374 25.2331 27.3554 24.9321 27.2614C24.1831 27.0284 23.4651 26.7234 22.8561 26.3234C22.6201 26.1684 22.4011 25.9984 22.2041 25.8134C21.6861 25.3304 21.3121 24.7394 21.1631 24.0194C21.1121 23.7734 21.0981 23.5254 21.1191 23.2834C21.1521 22.8914 21.3031 22.5374 21.5141 22.2554C21.5511 22.1634 21.6081 22.0984 21.6791 22.0664Z" fill="url(#paint46_linear_15350_109613)"/>
<path opacity="0.818" d="M21.7122 22.0469C21.1272 22.3949 20.6862 22.9779 20.5852 23.6239C20.5652 23.7519 20.5572 23.8829 20.5602 24.0149C20.5792 24.9749 21.0662 25.7269 21.7882 26.3139C22.5282 26.9149 23.5142 27.3449 24.5002 27.6509C26.4722 28.2629 28.4442 28.3799 28.4442 28.3799C28.9872 28.4789 29.4802 28.5539 29.9732 28.5839C30.3002 28.2139 29.7692 28.7409 30.2532 28.2869C30.1412 28.2379 29.8912 28.1879 29.5642 28.1099C29.3302 28.0969 29.0472 28.0789 28.7272 28.0529C27.8622 27.9809 26.7672 27.8489 25.6752 27.5879C25.3692 27.5139 25.0642 27.4309 24.7642 27.3349C24.0192 27.0979 23.3082 26.7889 22.7092 26.3839C22.4762 26.2259 22.2602 26.0539 22.0662 25.8659C21.5602 25.3769 21.1992 24.7809 21.0662 24.0539C21.0202 23.8059 21.0142 23.5579 21.0412 23.3189C21.0862 22.9279 21.2402 22.5759 21.4562 22.2979C21.5222 22.1849 21.6102 22.0989 21.7122 22.0469Z" fill="url(#paint47_linear_15350_109613)"/>
<path opacity="0.909" d="M21.7438 22.0273C21.1448 22.3533 20.6958 22.9323 20.5818 23.6013C20.5598 23.7293 20.5498 23.8613 20.5508 23.9953C20.5608 24.9713 21.0508 25.7323 21.7818 26.3253C22.5218 26.9253 23.5088 27.3533 24.4958 27.6583C26.4698 28.2673 28.4428 28.3803 28.4428 28.3803C28.9858 28.4793 29.4758 28.5563 29.9698 28.5863C30.3168 28.1943 29.7108 28.7963 30.2328 28.3093C30.0848 28.2793 29.7808 28.2493 29.3808 28.1973C29.1388 28.1833 28.8558 28.1653 28.5428 28.1383C27.6678 28.0643 26.5818 27.9283 25.5048 27.6653C25.1998 27.5903 24.8948 27.5053 24.5968 27.4083C23.8558 27.1673 23.1518 26.8543 22.5618 26.4443C22.3318 26.2843 22.1188 26.1093 21.9278 25.9183C21.4338 25.4243 21.0868 24.8223 20.9688 24.0893C20.9288 23.8393 20.9288 23.5913 20.9628 23.3553C21.0198 22.9663 21.1778 22.6153 21.3968 22.3423C21.4938 22.2073 21.6118 22.1003 21.7438 22.0273Z" fill="url(#paint48_linear_15350_109613)"/>
<path opacity="0.3" d="M30.213 28.3328C29.931 28.3158 29.242 28.3018 28.358 28.2248C27.474 28.1478 26.395 28.0088 25.334 27.7438C24.272 27.4788 23.228 27.0868 22.413 26.5058C21.598 25.9238 21.013 25.1518 20.869 24.1258C20.742 23.2168 21.162 22.3608 21.776 22.0078C21.048 22.3668 20.542 23.1098 20.542 23.9768C20.542 24.9678 21.036 25.7388 21.776 26.3378C22.517 26.9368 23.504 27.3638 24.492 27.6658C26.467 28.2718 28.442 28.3808 28.442 28.3808C28.985 28.4798 29.473 28.5588 29.967 28.5888C30.335 28.1748 29.654 28.8528 30.213 28.3328Z" fill="#C68D65"/>
</g>
<g opacity="0.23">
<path opacity="0.091" d="M20.729 23.2625C20.753 23.2255 22.39 23.2605 22.422 23.2605L27.783 23.5125C28.274 23.5115 28.79 23.5985 29.306 23.7195C29.441 23.7515 29.568 23.7835 29.687 23.8155C30.984 24.1705 31.673 24.7185 32.896 25.7845C33.094 25.9565 33.282 26.1275 33.446 26.2905C34.726 27.5525 35.915 29.2615 36.244 31.4725C36.256 31.5615 36.29 32.6445 36.088 34.1065C35.995 34.7775 35.829 35.7885 35.6 36.5715C35.557 36.7175 35.508 36.9185 35.459 37.0655C34.68 39.5105 32.898 42.1875 29.81 43.0335C29.902 43.0505 30.092 43.2375 30.302 43.4555C30.616 43.7825 31.076 44.2145 31.313 44.2005C33.874 43.6635 35.764 41.4865 36.318 40.6745C37.391 39.1045 38.035 37.4935 38.106 35.8065L38.12 30.6765L38.107 30.5315C37.723 27.9765 36.177 26.1365 34.79 24.8875C33.433 23.6645 31.779 22.8105 30.185 22.4325C29.673 22.3115 29.181 22.2295 28.68 22.2305L22.71 22.2405C22.705 22.2405 22.571 22.2405 22.567 22.2415C21.793 22.2475 21.117 22.6535 20.729 23.2625Z" fill="url(#paint49_linear_15350_109613)"/>
<path opacity="0.182" d="M20.7329 23.2346C20.7809 23.1596 22.3529 23.1396 22.4159 23.1396L27.8359 23.3606C28.3179 23.3596 28.8579 23.4516 29.3769 23.5726C29.5119 23.6036 29.6399 23.6356 29.7569 23.6676C31.0349 24.0126 31.8159 24.6186 33.0159 25.6336C33.2339 25.8176 33.4309 25.9906 33.5799 26.1356C34.9149 27.4376 36.0739 29.1706 36.3969 31.3646C36.4209 31.5426 36.4569 32.6156 36.2659 34.0246C36.1839 34.6286 36.0129 35.8136 35.8069 36.5176C35.7689 36.6486 35.7199 36.8906 35.6759 37.0226C34.9399 39.4226 32.9929 42.2096 29.9839 43.0736C30.0389 43.1006 30.1859 43.2766 30.3499 43.4776C30.5939 43.7766 31.0819 44.2026 31.3649 44.1906C33.9489 43.6106 35.7869 41.4516 36.3369 40.6466C37.4099 39.0766 38.0509 37.4466 38.1219 35.7596L38.1359 30.6296L38.1229 30.4846C37.7389 27.9296 36.1929 26.0896 34.8059 24.8406C33.4489 23.6176 31.7949 22.7636 30.2009 22.3856C29.6889 22.2646 29.1969 22.1826 28.6959 22.1836L22.7129 22.1936C22.7029 22.1936 22.5779 22.1946 22.5679 22.1946C21.7929 22.2036 21.1169 22.6186 20.7329 23.2346Z" fill="url(#paint50_linear_15350_109613)"/>
<path opacity="0.273" d="M20.7378 23.2097C20.8098 23.0977 22.3168 23.0217 22.4118 23.0217L27.8908 23.2107C28.3628 23.2097 28.9278 23.3067 29.4498 23.4267C29.5858 23.4577 29.7128 23.4897 29.8298 23.5207C31.0878 23.8567 31.9648 24.5177 33.1388 25.4827C33.3778 25.6787 33.5828 25.8537 33.7158 25.9817C35.1058 27.3227 36.2338 29.0807 36.5518 31.2567C36.5878 31.5247 36.6248 32.5877 36.4458 33.9417C36.3748 34.4787 36.1988 35.8377 36.0158 36.4637C35.9818 36.5807 35.9338 36.8617 35.8948 36.9787C35.2028 39.3337 33.0898 42.2307 30.1598 43.1127C30.1778 43.1507 30.2818 43.3157 30.3988 43.4987C30.5728 43.7697 31.0888 44.1907 31.4188 44.1797C34.0258 43.5567 35.8128 41.4157 36.3578 40.6177C37.4308 39.0477 38.0688 37.3997 38.1398 35.7127L38.1538 30.5827L38.1408 30.4377C37.7568 27.8827 36.2108 26.0427 34.8238 24.7937C33.4668 23.5707 31.8128 22.7167 30.2188 22.3387C29.7068 22.2177 29.2148 22.1357 28.7138 22.1367L22.7178 22.1467C22.7038 22.1467 22.5858 22.1477 22.5718 22.1487C21.7928 22.1627 21.1158 22.5857 20.7378 23.2097Z" fill="url(#paint51_linear_15350_109613)"/>
<path opacity="0.364" d="M20.7432 23.1868C20.8392 23.0368 22.2802 22.9058 22.4072 22.9058L27.9452 23.0628C28.4082 23.0618 28.9962 23.1638 29.5222 23.2838C29.6592 23.3148 29.7862 23.3458 29.9012 23.3768C31.1392 23.7038 32.1132 24.4178 33.2592 25.3358C33.5192 25.5438 33.7322 25.7198 33.8492 25.8318C35.2932 27.2128 36.3922 28.9948 36.7042 31.1538C36.7522 31.5108 36.7912 32.5638 36.6222 33.8648C36.5612 34.3348 36.3822 35.8668 36.2212 36.4148C36.1912 36.5168 36.1442 36.8378 36.1102 36.9408C35.4622 39.2508 33.1832 42.2578 30.3312 43.1578C30.3132 43.2058 30.3732 43.3608 30.4442 43.5258C30.5492 43.7708 31.0912 44.1838 31.4682 44.1738C34.0992 43.5078 35.8332 41.3848 36.3742 40.5938C37.4472 39.0238 38.0822 37.3568 38.1532 35.6698L38.1672 30.5398L38.1542 30.3948C37.7702 27.8398 36.2242 25.9998 34.8372 24.7508C33.4802 23.5278 31.8262 22.6738 30.2322 22.2958C29.7202 22.1748 29.2282 22.0928 28.7272 22.0938L22.7182 22.1038C22.6992 22.1038 22.5892 22.1048 22.5712 22.1058C21.7922 22.1238 21.1162 22.5548 20.7432 23.1868Z" fill="url(#paint52_linear_15350_109613)"/>
<path opacity="0.455" d="M20.748 23.159C20.867 22.972 22.244 22.785 22.403 22.785L28 22.91C28.454 22.909 29.066 23.016 29.595 23.136C29.733 23.167 29.86 23.198 29.974 23.227C31.193 23.544 32.264 24.31 33.382 25.183C33.663 25.403 33.884 25.58 33.985 25.675C35.484 27.096 36.552 28.902 36.859 31.043C36.919 31.489 36.959 32.533 36.802 33.779C36.751 34.182 36.568 35.888 36.43 36.358C36.404 36.446 36.358 36.807 36.328 36.895C35.724 39.16 33.279 42.277 30.506 43.194C30.451 43.253 30.469 43.398 30.493 43.544C30.53 43.764 31.098 44.168 31.522 44.16C34.176 43.451 35.859 41.346 36.395 40.562C37.468 38.992 38.1 37.306 38.171 35.619L38.185 30.489L38.172 30.344C37.788 27.789 36.242 25.949 34.855 24.7C33.498 23.477 31.844 22.623 30.25 22.245C29.738 22.124 29.246 22.042 28.745 22.043L22.723 22.053C22.699 22.053 22.598 22.054 22.574 22.056C21.792 22.082 21.116 22.521 20.748 23.159Z" fill="url(#paint53_linear_15350_109613)"/>
<path opacity="0.545" d="M20.7529 23.136C20.8959 22.911 22.2069 22.669 22.3979 22.669L28.0539 22.762C28.4979 22.761 29.1349 22.873 29.6679 22.993C29.8059 23.024 29.9339 23.054 30.0459 23.083C31.2449 23.391 32.4159 24.207 33.5039 25.036C33.8069 25.267 34.0359 25.446 34.1199 25.525C35.6739 26.985 36.7109 28.816 37.0129 30.94C37.0849 31.475 37.1269 32.509 36.9809 33.702C36.9399 34.038 36.7529 35.918 36.6389 36.31C36.6179 36.383 36.5709 36.784 36.5469 36.857C35.9859 39.077 33.3759 42.304 30.6819 43.239C30.5899 43.309 30.5639 43.446 30.5429 43.571C30.5089 43.769 31.1059 44.162 31.5759 44.155C34.2529 43.403 35.8839 41.317 36.4159 40.538C37.4889 38.968 38.1179 37.263 38.1889 35.576L38.2029 30.446L38.1899 30.301C37.8059 27.746 36.2599 25.906 34.8729 24.657C33.5159 23.434 31.8619 22.58 30.2679 22.202C29.7559 22.081 29.2639 21.999 28.7629 22L22.7279 22.01C22.6989 22.01 22.6059 22.011 22.5779 22.013C21.7919 22.043 21.1159 22.49 20.7529 23.136Z" fill="url(#paint54_linear_15350_109613)"/>
<path opacity="0.636" d="M20.7578 23.1111C20.9248 22.8491 22.1708 22.5511 22.3928 22.5511L28.1068 22.6121C28.5418 22.6111 29.2018 22.7291 29.7388 22.8471C29.8778 22.8781 30.0058 22.9081 30.1168 22.9361C31.2968 23.2351 32.5668 24.0991 33.6248 24.8861C33.9498 25.1271 34.1868 25.3091 34.2538 25.3721C35.8628 26.8721 36.8698 28.7271 37.1658 30.8331C37.2498 31.4571 37.2928 32.4811 37.1578 33.6201C37.1258 33.8891 36.9358 35.9431 36.8448 36.2561C36.8278 36.3141 36.7818 36.7551 36.7628 36.8141C36.2458 38.9891 33.4698 42.3261 30.8548 43.2791C30.7258 43.3591 30.6538 43.4881 30.5898 43.5931C30.4818 43.7691 31.1108 44.1501 31.6278 44.1451C34.3288 43.3511 35.9078 41.2821 36.4348 40.5101C37.5078 38.9401 38.1328 37.2171 38.2048 35.5291L38.2188 30.3991L38.2058 30.2541C37.8218 27.6991 36.2758 25.8591 34.8888 24.6101C33.5318 23.3871 31.8778 22.5331 30.2838 22.1551C29.7718 22.0341 29.2798 21.9521 28.7788 21.9531L22.7308 21.9631C22.6968 21.9631 22.6118 21.9641 22.5788 21.9671C21.7908 22.0021 21.1148 22.4571 20.7578 23.1111Z" fill="url(#paint55_linear_15350_109613)"/>
<path opacity="0.727" d="M20.7632 23.0833C20.9542 22.7833 22.1352 22.4293 22.3892 22.4293L28.1622 22.4583C28.5882 22.4573 29.2722 22.5803 29.8122 22.6983C29.9522 22.7293 30.0802 22.7593 30.1892 22.7863C31.3492 23.0753 32.7202 23.9883 33.7462 24.7333C34.0932 24.9853 34.3372 25.1683 34.3882 25.2153C36.0522 26.7553 37.0282 28.6333 37.3192 30.7233C37.4152 31.4373 37.4602 32.4513 37.3362 33.5363C37.3132 33.7383 37.1212 35.9663 37.0522 36.2013C37.0392 36.2453 36.9942 36.7263 36.9802 36.7703C36.5072 38.9003 33.5652 42.3473 31.0292 43.3183C30.8642 43.4093 30.7432 43.5283 30.6382 43.6143C30.4532 43.7643 31.1162 44.1383 31.6802 44.1343C34.4042 43.2973 35.9312 41.2463 36.4542 40.4813C37.5272 38.9113 38.1492 37.1693 38.2212 35.4823L38.2352 30.3523L38.2222 30.2073C37.8382 27.6523 36.2922 25.8123 34.9052 24.5633C33.5482 23.3403 31.8942 22.4863 30.3002 22.1083C29.7882 21.9873 29.2962 21.9053 28.7952 21.9063L22.7342 21.9163C22.6962 21.9163 22.6192 21.9173 22.5812 21.9203C21.7912 21.9583 21.1152 22.4223 20.7632 23.0833Z" fill="url(#paint56_linear_15350_109613)"/>
<path opacity="0.818" d="M20.7671 23.0604C20.9821 22.7234 22.0971 22.3134 22.3831 22.3134L28.2151 22.3104C28.6311 22.3094 29.3401 22.4374 29.8831 22.5554C30.0241 22.5864 30.1521 22.6154 30.2601 22.6424C31.4011 22.9224 32.8731 23.8824 33.8671 24.5854C34.2361 24.8464 34.4881 25.0334 34.5221 25.0644C36.2421 26.6444 37.1871 28.5464 37.4731 30.6184C37.5811 31.4214 37.6271 32.4254 37.5151 33.4574C37.5001 33.5924 37.3061 35.9944 37.2601 36.1504C37.2511 36.1794 37.2071 36.7004 37.1981 36.7294C36.7681 38.8144 33.6611 42.3714 31.2031 43.3594C31.0011 43.4604 30.8331 43.5684 30.6851 43.6374C30.4251 43.7584 31.1211 44.1274 31.7321 44.1244C34.4791 43.2444 35.9551 41.2114 36.4731 40.4534C37.5461 38.8834 38.1651 37.1224 38.2371 35.4354L38.2511 30.3054L38.2381 30.1604C37.8541 27.6054 36.3081 25.7654 34.9211 24.5164C33.5641 23.2934 31.9101 22.4394 30.3161 22.0614C29.8041 21.9404 29.3121 21.8584 28.8111 21.8594L22.7371 21.8694C22.6941 21.8694 22.6251 21.8714 22.5831 21.8744C21.7901 21.9204 21.1151 22.3914 20.7671 23.0604Z" fill="url(#paint57_linear_15350_109613)"/>
<path opacity="0.909" d="M20.772 23.0345C21.011 22.6605 22.061 22.1945 22.379 22.1945L28.27 22.1595C28.677 22.1585 29.409 22.2915 29.956 22.4085C30.097 22.4385 30.226 22.4685 30.332 22.4935C31.453 22.7635 33.027 23.7695 33.989 24.4335C34.381 24.7035 34.64 24.8935 34.657 24.9095C36.432 26.5295 37.346 28.4555 37.627 30.5105C37.747 31.4025 37.795 32.3965 37.694 33.3745C37.687 33.4415 37.491 36.0185 37.468 36.0965C37.464 36.1115 37.42 36.6715 37.415 36.6865C37.029 38.7265 33.756 42.3935 31.377 43.3995C31.138 43.5115 30.924 43.6065 30.733 43.6595C30.399 43.7505 31.127 44.1165 31.784 44.1145C34.555 43.1915 35.978 41.1765 36.492 40.4255C37.565 38.8555 38.181 37.0755 38.252 35.3885L38.266 30.2585L38.253 30.1135C37.869 27.5585 36.323 25.7185 34.936 24.4695C33.579 23.2465 31.925 22.3925 30.331 22.0145C29.819 21.8935 29.327 21.8115 28.826 21.8125L22.74 21.8225C22.692 21.8225 22.631 21.8245 22.584 21.8275C21.79 21.8785 21.115 22.3575 20.772 23.0345Z" fill="url(#paint58_linear_15350_109613)"/>
<path opacity="0.23" d="M38.2709 30.0666C37.8869 27.5116 36.3409 25.6716 34.9539 24.4226C33.5969 23.1996 31.9429 22.3456 30.3489 21.9676C29.8369 21.8466 29.3449 21.7646 28.8439 21.7656L22.7449 21.7756C21.8779 21.7756 21.1359 22.2806 20.7759 23.0096C21.0389 22.5976 22.0239 22.0766 22.3729 22.0766L28.3229 22.0096C28.8229 22.0086 29.8919 22.2266 30.4029 22.3476C31.9969 22.7256 34.7909 24.7576 34.7909 24.7576C37.6169 27.0846 38.0259 30.4396 37.8709 33.2946L37.6319 36.6456C37.2489 38.8796 32.9829 43.3476 30.7809 43.6826C30.3739 43.7446 31.1329 44.1056 31.8369 44.1056C34.6309 43.1396 36.0029 41.1426 36.5119 40.3976C37.5849 38.8276 38.1979 37.0286 38.2689 35.3416L38.2829 30.2116L38.2709 30.0666Z" fill="#C68D65"/>
</g>
<path opacity="0.15" d="M28.094 33.7244C26.826 37.2264 27.59 40.7134 29.799 41.5124C32.009 42.3124 34.827 40.1224 36.095 36.6204C37.363 33.1184 36.599 29.6314 34.39 28.8324C32.181 28.0334 29.362 30.2234 28.094 33.7244Z" fill="url(#paint59_radial_15350_109613)"/>
<path opacity="0.091" d="M38.2831 30.832C36.7561 32.991 35.4261 35.015 35.2721 35.289C33.5871 38.282 30.4681 40.096 27.5121 40.209L16.5261 40.189C15.0781 40.185 13.6901 39.709 12.5371 38.905C12.5901 39.228 12.6371 39.555 12.7071 39.86C12.7531 40.055 12.8121 40.244 12.8831 40.425C13.5281 41.904 14.7701 43.038 16.2651 43.581C16.9281 43.792 17.6251 43.915 18.3431 43.917L24.7291 43.928L29.4491 43.922C31.4101 43.803 33.4271 42.853 35.1321 41.321C35.5381 40.91 36.3721 40.295 36.7881 39.779C36.8231 39.736 36.8551 39.693 36.8841 39.651C37.8941 38.171 38.1541 36.646 38.2531 34.931C38.2701 34.84 38.2901 30.952 38.2831 30.832Z" fill="url(#paint60_linear_15350_109613)"/>
<path opacity="0.182" d="M38.282 31.0352C36.894 32.9982 35.699 35.0802 35.433 35.5722C33.829 38.5442 30.783 40.4342 27.709 40.5362L16.723 40.5162C15.283 40.5122 13.89 40.0302 12.73 39.2232C12.744 39.4912 12.754 39.7622 12.789 40.0092C12.811 40.1692 12.848 40.3222 12.896 40.4682C13.539 41.9522 14.783 43.0912 16.281 43.6342C16.944 43.8452 17.641 43.9682 18.359 43.9702L24.745 43.9812L29.465 43.9752C31.426 43.8562 33.443 42.9062 35.148 41.3742C35.557 40.9602 36.347 40.3562 36.76 39.8402C36.793 39.7982 36.824 39.7572 36.852 39.7162C37.8 38.3252 38.106 36.8172 38.233 35.0732C38.269 34.8912 38.296 31.2742 38.282 31.0352Z" fill="url(#paint61_linear_15350_109613)"/>
<path opacity="0.273" d="M38.2811 31.2383C37.0321 33.0043 35.9681 35.1443 35.5951 35.8543C34.0541 38.7943 31.1001 40.7723 27.9071 40.8623L16.9211 40.8423C15.4891 40.8383 14.0901 40.3503 12.9231 39.5413C12.8991 39.7543 12.8721 39.9673 12.8711 40.1593C12.8701 40.2833 12.8841 40.4013 12.9091 40.5123C13.5511 42.0013 14.7951 43.1443 16.2971 43.6883C16.9601 43.8993 17.6571 44.0223 18.3751 44.0243L24.7611 44.0353L29.4811 44.0293C31.4421 43.9103 33.4591 42.9603 35.1641 41.4283C35.5761 41.0103 36.3241 40.4203 36.7321 39.9023C36.7641 39.8623 36.7941 39.8223 36.8211 39.7823C37.7061 38.4813 38.0591 36.9893 38.2151 35.2173C38.2671 34.9423 38.3031 31.5973 38.2811 31.2383Z" fill="url(#paint62_linear_15350_109613)"/>
<path opacity="0.364" d="M38.2811 31.4414C37.1711 33.0104 36.2341 35.2054 35.7581 36.1374C34.2731 39.0434 31.4171 41.1104 28.1071 41.1894L17.1211 41.1694C15.6981 41.1654 14.2931 40.6704 13.1191 39.8594C13.0561 40.0184 12.9921 40.1734 12.9561 40.3084C12.9321 40.3964 12.9241 40.4794 12.9261 40.5554C13.5671 42.0494 14.8111 43.1964 16.3161 43.7424C16.9791 43.9534 17.6761 44.0764 18.3941 44.0784L24.7801 44.0894L29.5001 44.0834C31.4611 43.9644 33.4781 43.0144 35.1831 41.4824C35.5991 41.0614 36.3031 40.4834 36.7071 39.9644C36.7371 39.9254 36.7661 39.8874 36.7921 39.8484C37.6141 38.6374 38.0151 37.1614 38.1981 35.3604C38.2661 34.9944 38.3101 31.9204 38.2811 31.4414Z" fill="url(#paint63_linear_15350_109613)"/>
<path opacity="0.455" d="M38.28 31.6445C37.309 33.0175 36.495 35.2645 35.92 36.4205C34.489 39.2925 31.734 41.4485 28.305 41.5165L17.319 41.4965C15.904 41.4925 14.494 40.9915 13.313 40.1785C13.212 40.2825 13.111 40.3785 13.038 40.4595C12.992 40.5115 12.96 40.5595 12.939 40.6005C13.578 42.0995 14.824 43.2515 16.332 43.7975C16.995 44.0085 17.692 44.1315 18.41 44.1335L24.796 44.1445L29.516 44.1385C31.477 44.0195 33.494 43.0695 35.199 41.5375C35.618 41.1135 36.28 40.5485 36.679 40.0275C36.707 39.9905 36.735 39.9535 36.76 39.9155C37.519 38.7935 37.967 37.3345 38.178 35.5055C38.264 35.0455 38.316 32.2435 38.28 31.6445Z" fill="url(#paint64_linear_15350_109613)"/>
<path opacity="0.545" d="M38.2801 31.8477C37.4481 33.0237 36.7531 35.3227 36.0831 36.7027C34.7041 39.5397 32.0511 41.7857 28.5041 41.8417L17.5181 41.8217C16.1121 41.8177 14.6961 41.3097 13.5071 40.4947C13.3671 40.5437 13.2321 40.5817 13.1211 40.6067C13.0531 40.6217 12.9981 40.6357 12.9531 40.6417C13.5911 42.1447 14.8371 43.3017 16.3491 43.8497C17.0121 44.0607 17.7091 44.1837 18.4271 44.1857L24.8131 44.1967L29.5331 44.1907C31.4941 44.0717 33.5111 43.1217 35.2161 41.5897C35.6381 41.1627 36.2581 40.6107 36.6511 40.0887C36.6781 40.0537 36.7041 40.0177 36.7291 39.9807C37.4251 38.9487 37.9201 37.5057 38.1591 35.6477C38.2631 35.0977 38.3231 32.5667 38.2801 31.8477Z" fill="url(#paint65_linear_15350_109613)"/>
<path opacity="0.636" d="M38.2793 32.0508C37.5863 33.0308 37.0063 35.3778 36.2453 36.9858C34.9173 39.7888 32.3673 42.1238 28.7023 42.1688L17.7163 42.1488C16.3183 42.1448 14.8973 41.6308 13.7013 40.8128C13.5233 40.8078 13.3533 40.7888 13.2043 40.7558C13.1153 40.7368 13.0353 40.7138 12.9673 40.6848C13.6043 42.1928 14.8513 43.3538 16.3653 43.9028C17.0283 44.1138 17.7253 44.2368 18.4433 44.2388L24.8293 44.2498L29.5493 44.2438C31.5103 44.1248 33.5273 43.1748 35.2323 41.6428C35.6573 41.2118 36.2353 40.6728 36.6233 40.1498C36.6483 40.1158 36.6733 40.0818 36.6973 40.0458C37.3303 39.1038 37.8723 37.6768 38.1393 35.7908C38.2613 35.1488 38.3303 32.8898 38.2793 32.0508Z" fill="url(#paint66_linear_15350_109613)"/>
<path opacity="0.727" d="M38.279 32.2539C37.725 33.0369 37.255 35.4319 36.408 37.2679C35.131 40.0369 32.685 42.4609 28.901 42.4949L17.915 42.4749C16.525 42.4709 15.098 41.9509 13.895 41.1309C13.678 41.0709 13.472 40.9959 13.287 40.9059C13.175 40.8519 13.073 40.7929 12.981 40.7289C13.616 42.2419 14.864 43.4069 16.382 43.9579C17.045 44.1689 17.742 44.2919 18.46 44.2939L24.846 44.3049L29.566 44.2989C31.527 44.1799 33.544 43.2299 35.249 41.6979C35.677 41.2639 36.213 40.7389 36.596 40.2129C36.619 40.1809 36.643 40.1469 36.666 40.1119C37.236 39.2589 37.825 37.8489 38.12 35.9339C38.259 35.2009 38.336 33.2129 38.279 32.2539Z" fill="url(#paint67_linear_15350_109613)"/>
<path opacity="0.818" d="M38.2781 32.4531C37.8631 33.0391 37.4971 35.4791 36.5691 37.5471C35.3411 40.2831 33.0001 42.7961 29.0991 42.8181L18.1131 42.7991C16.7321 42.7951 15.2991 42.2681 14.0891 41.4461C13.8341 41.3311 13.5911 41.2001 13.3691 41.0521C13.2351 40.9631 13.1091 40.8691 12.9941 40.7681C13.6281 42.2861 14.8761 43.4561 16.3981 44.0071C17.0611 44.2181 17.7581 44.3411 18.4761 44.3431L24.8621 44.3541L29.5821 44.3481C31.5431 44.2291 33.5601 43.2791 35.2651 41.7471C35.6971 41.3101 36.1911 40.7981 36.5681 40.2701C36.5901 40.2391 36.6121 40.2071 36.6351 40.1731C37.1421 39.4101 37.7791 38.0161 38.1021 36.0731C38.2581 35.2481 38.3431 33.5321 38.2781 32.4531Z" fill="url(#paint68_linear_15350_109613)"/>
<path opacity="0.909" d="M38.2783 32.6562C38.0023 33.0462 37.7363 35.5283 36.7323 37.8293C35.5533 40.5323 33.3183 43.1332 29.2983 43.1442L18.3123 43.1242C16.9393 43.1202 15.5013 42.5872 14.2843 41.7632C13.9903 41.5942 13.7113 41.4062 13.4533 41.2012C13.2963 41.0772 13.1483 40.9472 13.0093 40.8112C13.6423 42.3342 14.8913 43.5082 16.4153 44.0612C17.0783 44.2722 17.7753 44.3952 18.4933 44.3972L24.8793 44.4082L29.5993 44.4022C31.5603 44.2832 33.5773 43.3332 35.2823 41.8012C35.7173 41.3612 36.1703 40.8622 36.5413 40.3322C36.5613 40.3032 36.5823 40.2723 36.6043 40.2393C37.0483 39.5663 37.7323 38.1882 38.0833 36.2162C38.2563 35.2992 38.3503 33.8553 38.2783 32.6562Z" fill="url(#paint69_linear_15350_109613)"/>
<path d="M35.2989 41.8564C35.7369 41.4124 36.1489 40.9284 36.5139 40.3954C37.0449 39.6184 38.5219 36.9304 38.2779 32.8594C37.9979 33.2564 37.5649 43.4714 29.4969 43.4714L18.5109 43.4514C16.5499 43.4464 14.4259 42.3334 13.0239 40.8554C13.6549 42.3834 14.9049 43.5614 16.4329 44.1154C17.0959 44.3264 17.7929 44.4494 18.5109 44.4514L24.8969 44.4624L29.6169 44.4564C31.5759 44.3384 33.5939 43.3884 35.2989 41.8564Z" fill="#C68D65"/>
<defs>
<radialGradient id="paint0_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.8938 11.9575) rotate(12.3977) scale(6.16612 13.5297)">
<stop stop-color="#FFE16E"/>
<stop offset="0.629" stop-color="#FFD226"/>
<stop offset="0.75" stop-color="#FDCD23"/>
<stop offset="0.899" stop-color="#F6BD1B"/>
<stop offset="0.999" stop-color="#F0AF13"/>
</radialGradient>
<radialGradient id="paint1_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.2749 11.9774) rotate(14.3184) scale(8.57668 15.7459)">
<stop stop-color="#DAAE88"/>
<stop offset="0.53" stop-color="#DEB993"/>
<stop offset="1" stop-color="#E3C7A1"/>
</radialGradient>
<radialGradient id="paint2_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.1612 12.9524) rotate(-167.54) scale(1.94495 7.93301)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint3_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.9968 22.4225) scale(21.406)">
<stop stop-color="#E3C7A1"/>
<stop offset="0.629" stop-color="#DAAE88"/>
<stop offset="0.75" stop-color="#D7A983"/>
<stop offset="0.899" stop-color="#CE9A73"/>
<stop offset="0.999" stop-color="#C68D65"/>
</radialGradient>
<radialGradient id="paint4_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.1401 35.5866) rotate(158.363) scale(4.32161 6.84359)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint5_linear_15350_109613" x1="27.1277" y1="22.4016" x2="24.1318" y2="16.4794" gradientUnits="userSpaceOnUse">
<stop stop-color="#E2B491"/>
<stop offset="1" stop-color="#D8A17B" stop-opacity="0.981"/>
</linearGradient>
<linearGradient id="paint6_linear_15350_109613" x1="27.4184" y1="22.3262" x2="23.9995" y2="16.5209" gradientUnits="userSpaceOnUse">
<stop stop-color="#E0B28E"/>
<stop offset="1" stop-color="#D8A27C" stop-opacity="0.963"/>
</linearGradient>
<linearGradient id="paint7_linear_15350_109613" x1="27.7035" y1="22.2188" x2="23.8784" y2="16.5824" gradientUnits="userSpaceOnUse">
<stop stop-color="#DEAF8B"/>
<stop offset="1" stop-color="#D8A37D" stop-opacity="0.944"/>
</linearGradient>
<linearGradient id="paint8_linear_15350_109613" x1="27.9808" y1="22.0889" x2="23.7719" y2="16.6691" gradientUnits="userSpaceOnUse">
<stop stop-color="#DDAD88"/>
<stop offset="1" stop-color="#D9A37E" stop-opacity="0.925"/>
</linearGradient>
<linearGradient id="paint9_linear_15350_109613" x1="28.2446" y1="21.9329" x2="23.6828" y2="16.7754" gradientUnits="userSpaceOnUse">
<stop stop-color="#DBAA85"/>
<stop offset="1" stop-color="#D9A47E" stop-opacity="0.906"/>
</linearGradient>
<linearGradient id="paint10_linear_15350_109613" x1="28.4933" y1="21.7524" x2="23.6138" y2="16.8998" gradientUnits="userSpaceOnUse">
<stop stop-color="#D9A782"/>
<stop offset="1" stop-color="#D9A57F" stop-opacity="0.887"/>
</linearGradient>
<linearGradient id="paint11_linear_15350_109613" x1="28.7216" y1="21.5478" x2="23.5663" y2="17.0394" gradientUnits="userSpaceOnUse">
<stop stop-color="#D7A57F"/>
<stop offset="1" stop-color="#D9A680" stop-opacity="0.869"/>
</linearGradient>
<linearGradient id="paint12_linear_15350_109613" x1="28.9279" y1="21.321" x2="23.5438" y2="17.1878" gradientUnits="userSpaceOnUse">
<stop stop-color="#D5A27D"/>
<stop offset="1" stop-color="#D9A781" stop-opacity="0.85"/>
</linearGradient>
<linearGradient id="paint13_linear_15350_109613" x1="29.1072" y1="21.079" x2="23.5476" y2="17.351" gradientUnits="userSpaceOnUse">
<stop stop-color="#D39F7A"/>
<stop offset="1" stop-color="#D9A882" stop-opacity="0.831"/>
</linearGradient>
<linearGradient id="paint14_linear_15350_109613" x1="29.259" y1="20.824" x2="23.5787" y2="17.5201" gradientUnits="userSpaceOnUse">
<stop stop-color="#D19D77"/>
<stop offset="1" stop-color="#D9A983" stop-opacity="0.813"/>
</linearGradient>
<linearGradient id="paint15_linear_15350_109613" x1="29.3808" y1="20.5566" x2="23.6403" y2="17.6926" gradientUnits="userSpaceOnUse">
<stop stop-color="#CF9A74"/>
<stop offset="1" stop-color="#D9AA84" stop-opacity="0.794"/>
</linearGradient>
<linearGradient id="paint16_linear_15350_109613" x1="29.4696" y1="20.2795" x2="23.7307" y2="17.8603" gradientUnits="userSpaceOnUse">
<stop stop-color="#CE9871"/>
<stop offset="1" stop-color="#DAAB85" stop-opacity="0.775"/>
</linearGradient>
<linearGradient id="paint17_linear_15350_109613" x1="29.5256" y1="20.0026" x2="23.85" y2="18.028" gradientUnits="userSpaceOnUse">
<stop stop-color="#CC956E"/>
<stop offset="1" stop-color="#DAAB85" stop-opacity="0.756"/>
</linearGradient>
<linearGradient id="paint18_linear_15350_109613" x1="29.5474" y1="19.7279" x2="24.0003" y2="18.1869" gradientUnits="userSpaceOnUse">
<stop stop-color="#CA926B"/>
<stop offset="1" stop-color="#DAAC86" stop-opacity="0.738"/>
</linearGradient>
<linearGradient id="paint19_linear_15350_109613" x1="29.5335" y1="19.4591" x2="24.1359" y2="18.3256" gradientUnits="userSpaceOnUse">
<stop stop-color="#C89068"/>
<stop offset="1" stop-color="#DAAD87" stop-opacity="0.719"/>
</linearGradient>
<linearGradient id="paint20_linear_15350_109613" x1="29.4858" y1="19.1997" x2="24.2933" y2="18.4523" gradientUnits="userSpaceOnUse">
<stop stop-color="#C68D65"/>
<stop offset="1" stop-color="#DAAE88" stop-opacity="0.7"/>
</linearGradient>
<radialGradient id="paint21_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.283 25.4752) rotate(-1.21473) scale(3.43509 7.92289)">
<stop stop-color="#E3C7A1"/>
<stop offset="0.629" stop-color="#DAAE88"/>
<stop offset="0.75" stop-color="#D7A983"/>
<stop offset="0.899" stop-color="#CE9A73"/>
<stop offset="0.999" stop-color="#C68D65"/>
</radialGradient>
<radialGradient id="paint22_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.7985 27.3253) rotate(-6.47686) scale(3.39594 7.96519)">
<stop stop-color="#E3C7A1"/>
<stop offset="0.629" stop-color="#DAAE88"/>
<stop offset="0.75" stop-color="#D7A983"/>
<stop offset="0.899" stop-color="#CE9A73"/>
<stop offset="0.999" stop-color="#C68D65"/>
</radialGradient>
<radialGradient id="paint23_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.4308 15.2549) rotate(145.324) scale(1.86517 8.36724)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint24_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.5653 25.9749) rotate(180) scale(2.04789 4.71914)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<radialGradient id="paint25_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.8746 28.3107) rotate(173.302) scale(2.04811 4.71916)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint26_linear_15350_109613" x1="27.6222" y1="28.2419" x2="27.5602" y2="38.1299" gradientUnits="userSpaceOnUse">
<stop stop-color="#E0C29C" stop-opacity="0.091"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint27_linear_15350_109613" x1="27.7441" y1="28.2025" x2="28.0141" y2="38.5775" gradientUnits="userSpaceOnUse">
<stop stop-color="#DEBC96" stop-opacity="0.182"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint28_linear_15350_109613" x1="27.8673" y1="28.1522" x2="28.5003" y2="38.9922" gradientUnits="userSpaceOnUse">
<stop stop-color="#DBB791" stop-opacity="0.273"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint29_linear_15350_109613" x1="27.9904" y1="28.0959" x2="29.0144" y2="39.3759" gradientUnits="userSpaceOnUse">
<stop stop-color="#D8B28B" stop-opacity="0.364"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint30_linear_15350_109613" x1="28.1143" y1="28.0346" x2="29.5563" y2="39.7276" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6AD86" stop-opacity="0.455"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint31_linear_15350_109613" x1="28.2374" y1="27.9643" x2="30.1244" y2="40.0453" gradientUnits="userSpaceOnUse">
<stop stop-color="#D3A780" stop-opacity="0.545"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint32_linear_15350_109613" x1="28.359" y1="27.8909" x2="30.716" y2="40.3309" gradientUnits="userSpaceOnUse">
<stop stop-color="#D1A27B" stop-opacity="0.636"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint33_linear_15350_109613" x1="28.481" y1="27.8086" x2="31.329" y2="40.5806" gradientUnits="userSpaceOnUse">
<stop stop-color="#CE9D75" stop-opacity="0.727"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint34_linear_15350_109613" x1="28.5994" y1="27.7213" x2="31.9614" y2="40.7973" gradientUnits="userSpaceOnUse">
<stop stop-color="#CB9870" stop-opacity="0.818"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint35_linear_15350_109613" x1="28.7152" y1="27.6281" x2="32.6102" y2="40.9781" gradientUnits="userSpaceOnUse">
<stop stop-color="#C9926A" stop-opacity="0.909"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint36_linear_15350_109613" x1="28.8281" y1="27.5318" x2="33.2751" y2="41.1268" gradientUnits="userSpaceOnUse">
<stop stop-color="#C68D65"/>
<stop offset="1" stop-color="#DAAE88"/>
</linearGradient>
<linearGradient id="paint37_linear_15350_109613" x1="26.5839" y1="28.2171" x2="29.06" y2="37.1161" gradientUnits="userSpaceOnUse">
<stop offset="0.011" stop-color="#DAAE88"/>
<stop offset="0.433" stop-color="#C68D65"/>
<stop offset="0.92" stop-color="#C68D65"/>
<stop offset="1" stop-color="#C68D65"/>
</linearGradient>
<radialGradient id="paint38_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.4453 25.0493) rotate(-79.7759) scale(2.33134 6.04248)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint39_linear_15350_109613" x1="20.6032" y1="25.262" x2="31.0302" y2="25.262" gradientUnits="userSpaceOnUse">
<stop stop-color="#E0C29C" stop-opacity="0.545"/>
<stop offset="1" stop-color="#D8AB85"/>
</linearGradient>
<linearGradient id="paint40_linear_15350_109613" x1="20.599" y1="25.283" x2="30.846" y2="25.283" gradientUnits="userSpaceOnUse">
<stop stop-color="#DEBC96" stop-opacity="0.591"/>
<stop offset="1" stop-color="#D6A882"/>
</linearGradient>
<linearGradient id="paint41_linear_15350_109613" x1="20.5949" y1="25.308" x2="30.6629" y2="25.308" gradientUnits="userSpaceOnUse">
<stop stop-color="#DBB791" stop-opacity="0.636"/>
<stop offset="1" stop-color="#D5A57E"/>
</linearGradient>
<linearGradient id="paint42_linear_15350_109613" x1="20.59" y1="25.3277" x2="30.48" y2="25.3277" gradientUnits="userSpaceOnUse">
<stop stop-color="#D8B28B" stop-opacity="0.682"/>
<stop offset="1" stop-color="#D3A27B"/>
</linearGradient>
<linearGradient id="paint43_linear_15350_109613" x1="20.585" y1="25.3406" x2="30.346" y2="25.3406" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6AD86" stop-opacity="0.727"/>
<stop offset="1" stop-color="#D19F78"/>
</linearGradient>
<linearGradient id="paint44_linear_15350_109613" x1="20.579" y1="25.3414" x2="30.313" y2="25.3414" gradientUnits="userSpaceOnUse">
<stop stop-color="#D3A780" stop-opacity="0.773"/>
<stop offset="1" stop-color="#CF9C75"/>
</linearGradient>
<linearGradient id="paint45_linear_15350_109613" x1="20.5718" y1="25.3329" x2="30.2928" y2="25.3329" gradientUnits="userSpaceOnUse">
<stop stop-color="#D1A27B" stop-opacity="0.818"/>
<stop offset="1" stop-color="#CD9972"/>
</linearGradient>
<linearGradient id="paint46_linear_15350_109613" x1="20.5661" y1="25.3244" x2="30.2731" y2="25.3244" gradientUnits="userSpaceOnUse">
<stop stop-color="#CE9D75" stop-opacity="0.864"/>
<stop offset="1" stop-color="#CB966F"/>
</linearGradient>
<linearGradient id="paint47_linear_15350_109613" x1="20.5582" y1="25.3159" x2="30.2532" y2="25.3159" gradientUnits="userSpaceOnUse">
<stop stop-color="#CB9870" stop-opacity="0.909"/>
<stop offset="1" stop-color="#CA936B"/>
</linearGradient>
<linearGradient id="paint48_linear_15350_109613" x1="20.5508" y1="25.3073" x2="30.2328" y2="25.3073" gradientUnits="userSpaceOnUse">
<stop stop-color="#C9926A" stop-opacity="0.955"/>
<stop offset="1" stop-color="#C89068"/>
</linearGradient>
<linearGradient id="paint49_linear_15350_109613" x1="20.729" y1="33.2165" x2="38.12" y2="33.2165" gradientUnits="userSpaceOnUse">
<stop stop-color="#E0C29C" stop-opacity="0.545"/>
<stop offset="1" stop-color="#D8AB85"/>
</linearGradient>
<linearGradient id="paint50_linear_15350_109613" x1="20.7329" y1="33.1856" x2="38.1369" y2="33.1856" gradientUnits="userSpaceOnUse">
<stop stop-color="#DEBC96" stop-opacity="0.591"/>
<stop offset="1" stop-color="#D6A882"/>
</linearGradient>
<linearGradient id="paint51_linear_15350_109613" x1="20.7378" y1="33.1577" x2="38.1528" y2="33.1577" gradientUnits="userSpaceOnUse">
<stop stop-color="#DBB791" stop-opacity="0.636"/>
<stop offset="1" stop-color="#D5A57E"/>
</linearGradient>
<linearGradient id="paint52_linear_15350_109613" x1="20.7432" y1="33.1318" x2="38.1692" y2="33.1318" gradientUnits="userSpaceOnUse">
<stop stop-color="#D8B28B" stop-opacity="0.682"/>
<stop offset="1" stop-color="#D3A27B"/>
</linearGradient>
<linearGradient id="paint53_linear_15350_109613" x1="20.748" y1="33.102" x2="38.186" y2="33.102" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6AD86" stop-opacity="0.727"/>
<stop offset="1" stop-color="#D19F78"/>
</linearGradient>
<linearGradient id="paint54_linear_15350_109613" x1="20.7529" y1="33.076" x2="38.2019" y2="33.076" gradientUnits="userSpaceOnUse">
<stop stop-color="#D3A780" stop-opacity="0.773"/>
<stop offset="1" stop-color="#CF9C75"/>
</linearGradient>
<linearGradient id="paint55_linear_15350_109613" x1="20.7578" y1="33.0481" x2="38.2188" y2="33.0481" gradientUnits="userSpaceOnUse">
<stop stop-color="#D1A27B" stop-opacity="0.818"/>
<stop offset="1" stop-color="#CD9972"/>
</linearGradient>
<linearGradient id="paint56_linear_15350_109613" x1="20.7632" y1="33.0173" x2="38.2352" y2="33.0173" gradientUnits="userSpaceOnUse">
<stop stop-color="#CE9D75" stop-opacity="0.864"/>
<stop offset="1" stop-color="#CB966F"/>
</linearGradient>
<linearGradient id="paint57_linear_15350_109613" x1="20.7671" y1="32.9914" x2="38.2511" y2="32.9914" gradientUnits="userSpaceOnUse">
<stop stop-color="#CB9870" stop-opacity="0.909"/>
<stop offset="1" stop-color="#CA936B"/>
</linearGradient>
<linearGradient id="paint58_linear_15350_109613" x1="20.772" y1="32.9635" x2="38.268" y2="32.9635" gradientUnits="userSpaceOnUse">
<stop stop-color="#C9926A" stop-opacity="0.955"/>
<stop offset="1" stop-color="#C89068"/>
</linearGradient>
<radialGradient id="paint59_radial_15350_109613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.0949 35.171) rotate(-160.097) scale(4.19774 7.3474)">
<stop offset="0.425" stop-color="white"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
<linearGradient id="paint60_linear_15350_109613" x1="10.3821" y1="37.38" x2="36.1301" y2="37.38" gradientUnits="userSpaceOnUse">
<stop stop-color="#D8AB85"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.273"/>
<stop offset="0.684" stop-color="#C9926A" stop-opacity="0.522"/>
<stop offset="0.889" stop-color="#D2A07A" stop-opacity="0.833"/>
<stop offset="0.999" stop-color="#D8AB85"/>
</linearGradient>
<linearGradient id="paint61_linear_15350_109613" x1="10.592" y1="37.5082" x2="36.148" y2="37.5082" gradientUnits="userSpaceOnUse">
<stop stop-color="#D6A882"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.345"/>
<stop offset="0.694" stop-color="#C9926A" stop-opacity="0.584"/>
<stop offset="0.911" stop-color="#D1A07A" stop-opacity="0.88"/>
<stop offset="0.999" stop-color="#D6A882"/>
</linearGradient>
<linearGradient id="paint62_linear_15350_109613" x1="10.7461" y1="37.6363" x2="36.1621" y2="37.6363" gradientUnits="userSpaceOnUse">
<stop stop-color="#D5A57E"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.418"/>
<stop offset="0.71" stop-color="#C9926A" stop-opacity="0.65"/>
<stop offset="0.948" stop-color="#D2A17A" stop-opacity="0.938"/>
<stop offset="0.999" stop-color="#D5A57E"/>
</linearGradient>
<linearGradient id="paint63_linear_15350_109613" x1="10.8021" y1="37.7654" x2="36.1681" y2="37.7654" gradientUnits="userSpaceOnUse">
<stop stop-color="#D3A27B"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.491"/>
<stop offset="0.725" stop-color="#C9926A" stop-opacity="0.71"/>
<stop offset="0.982" stop-color="#D2A17A" stop-opacity="0.982"/>
<stop offset="0.999" stop-color="#D3A27B"/>
</linearGradient>
<linearGradient id="paint64_linear_15350_109613" x1="10.818" y1="37.8935" x2="36.171" y2="37.8935" gradientUnits="userSpaceOnUse">
<stop stop-color="#D19F78"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.564"/>
<stop offset="0.744" stop-color="#C9926A" stop-opacity="0.768"/>
<stop offset="0.999" stop-color="#D19F78"/>
</linearGradient>
<linearGradient id="paint65_linear_15350_109613" x1="10.8321" y1="38.0227" x2="36.1741" y2="38.0227" gradientUnits="userSpaceOnUse">
<stop stop-color="#CF9C75"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.636"/>
<stop offset="0.768" stop-color="#C9926A" stop-opacity="0.825"/>
<stop offset="0.999" stop-color="#CF9C75"/>
</linearGradient>
<linearGradient id="paint66_linear_15350_109613" x1="10.8483" y1="38.1518" x2="36.1773" y2="38.1518" gradientUnits="userSpaceOnUse">
<stop stop-color="#CD9972"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.709"/>
<stop offset="0.801" stop-color="#C9926A" stop-opacity="0.88"/>
<stop offset="0.999" stop-color="#CD9972"/>
</linearGradient>
<linearGradient id="paint67_linear_15350_109613" x1="10.863" y1="38.2799" x2="36.18" y2="38.2799" gradientUnits="userSpaceOnUse">
<stop stop-color="#CB966F"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.782"/>
<stop offset="0.849" stop-color="#C9926A" stop-opacity="0.932"/>
<stop offset="0.999" stop-color="#CB966F"/>
</linearGradient>
<linearGradient id="paint68_linear_15350_109613" x1="10.8781" y1="38.4051" x2="36.1831" y2="38.4051" gradientUnits="userSpaceOnUse">
<stop stop-color="#CA936B"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.855"/>
<stop offset="0.968" stop-color="#CA926A" stop-opacity="0.991"/>
<stop offset="0.999" stop-color="#CA936B"/>
</linearGradient>
<linearGradient id="paint69_linear_15350_109613" x1="10.8933" y1="38.5332" x2="36.1863" y2="38.5332" gradientUnits="userSpaceOnUse">
<stop stop-color="#C89068"/>
<stop offset="0.519" stop-color="#C68D65" stop-opacity="0.927"/>
<stop offset="0.999" stop-color="#C89068"/>
</linearGradient>
</defs>`
];
