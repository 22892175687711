import {
    searchBlurValue,
    toggleSearchBar
} from '../../actions/creators/search';
import {
    SEARCHBAR_BLUR_VALUE
} from '../../actions/constants';
import { MEDIABREAKPOINTS } from '../../../theme';

export default (store) => next => action => {
    const isBlured = store.getState().main.ui.global.searchBar.isBlured;
    if (action.type === SEARCHBAR_BLUR_VALUE) {
        return next(action);
    }

    if (action.payload && action.payload.location && action.payload.location.pathname) {
        if (action.payload.location.pathname.indexOf('search') !== -1) {
            /* GOING INTO SEARCH VIEW */
            store.dispatch(toggleSearchBar(true));
            store.dispatch(searchBlurValue(false));
        } else if ( action.payload.location.pathname.indexOf('search') !== 1 && !isBlured) {
            /* GOING OUT SEARCH VIEW */
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            if (w > MEDIABREAKPOINTS.tablet) {
                store.dispatch(searchBlurValue(true));
            }
        }   
        return next(action);
    } else {
        return next(action);
    }
};