import React from "react";
import styled from "styled-components";
import {
    MainText,
    StepWrapper,
    selectStyle,
    Button,
    ButtonContainer
} from "../../../components";
import Select from "react-select";
import { Icon } from "../../../../../atoms/shared/Icon";
import ReactTooltip from "react-tooltip";
import * as yup from "yup";
import { Formik } from "formik";
import Card from "../../../../../atoms/shared/Card2";
import { Box, Flex } from "rebass";
import { Title } from "../../../../../atoms/shared/Text";
import Avatar from "../../../../../atoms/shared/AvatarCircle2";
import {
    DataTitle,
    DataValue,
    Desc,
    DataPlan
} from "../../../../ReferralInformation/GeneralInfo/styles";
import { Status } from "../../../../ReferralInformation/referralInformation-styles";
import moment from "moment";
import { DraftModal, useDraft } from "../../../useDraft";
//import {  ErrorMessage, SuccesReferralSent } from '../JobSuccessMessages'

import { PopupLoader } from "../../../../../atoms/shared/PopupLoader";
import Spinner from "../../../../../../../../assets/svg/Spinner";
import { SuccesReferralSent, ErrorMessage } from "../../JobCurrent/JobSuccessMessages";


const st = {
    "clientType": null,
    "propertyType": null,
    "propertySubType": null,
    "maxPrice": "",
    "estimatedPrice": "",
    "minBeds": "",
    "financingInfo": null,
    "homeSaleContingency": null,
    "credit": null,
    "pets": null,
    "minUnits": "",
    "minLotSize": null,
    "maxBeds": "",
    "minSquareFeet": "",
    "maxSquareFeet": "",
    "minAcres": "",
    "maxAcres": "",
    "maxUnits": "",
    "units": "",
    "acres": "",
    "beds": "",
    "baths": "",
    "squareFeet": "",
    "visibilityAfterExchange": {
        "value": "EVERYONE",
        "label": "Yes, display the accepted job to everyone!"
    },
    "pastJobType": {
        "value": "SHORTTERM",
        "label": "Short-term - less than 6 months"
    },
    "locationIsRemote": {
        "value": "PARTICULAR",
        "label": "This job is for a particular location."
    },
    "address": {
        "description": "Ottawa, ON, Canada",
        "lat": 45.4215296,
        "lng": -75.69719309999999,
        "state": "Ontario",
        "stateShort": "ON",
        "subAdministrativeArea": "Ottawa"
    },
    "jobCreationDate": "2023-06-07T04:00:00.000Z",
    "creationOption": {
        "value": "I_CREATED",
        "label": "I created it."
    },
    "currentStatus": {
        "value": "AWARDED",
        "label": "Awarded"
    },
    "description": "JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATIONJOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATIONJOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATIONJOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION"
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`;

const InfoIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5em;
    cursor: pointer;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const Spacer = styled.div`
    margin: 1em 0;
`;

const JobPastPreview = ({ 
    setStepData,
     stepData, 
     t, 
     sendingPastJobData,  
      referralIsLoading,
    successReferral,
    errorReferral,
    candidates
}) => {
    
    const {
        saveDraft,
        loading,
        showModal,
        setShowModal,
        modalMessage
    } = useDraft();

    const { jobCreationDate, creationOption, locationIsRemote, address, pastReferralStatus, pastJobType, description,   } = stepData && stepData


    const addressLabel = `${
        locationIsRemote.value === "PARTICULAR" ? address.description : "Remote"
    }`;

    // const professionLabel = `${
    //     cannotFindProfession ? professionDescription : profession.label
    // }`;

    return (
        <StepWrapper>
            <DraftModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalMessage={modalMessage}
            />
            <MainText>Please review your Job information</MainText>
            <Spacer>
                <Card
                    borderRadius={"8px 8px 0px 0px"}
                    status="ASSIGNED"
                    padding="30px"
                >
                    <Title>General Information</Title>
                    <Flex>
                    <Box mt={["1.5em"]}>
                        <Flex
                            alignItems="flex-start"
                            flexDirection={["column", "column", "row", "row"]}
                          // flexWrap="wrap"
                            justifyContent={'space-between'}
                            style={{
                                gap: '50px'
                            }}
                        >
                            <Box width={[1, 1, 1, 1]}>
                                <Flex  mb={[".5em"]}>
                                    <DataTitle>Method:</DataTitle>
                                    <DataValue>
                                        Job - Contractor ({stepData.pastJobType.value})
                                    </DataValue>
                                </Flex>
                          {/*       <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Job Title:</DataTitle>
                                    <DataValue>{jobTitle}</DataValue>
                                </Flex> */}
                               {/*  <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Looking For:</DataTitle>
                                    <DataValue>{professionLabel}</DataValue>
                                </Flex> */}
                                <Flex  mb={[".5em"]}>
                                    <DataTitle>Location:</DataTitle>
                                    <DataValue>{addressLabel}</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Created:</DataTitle>
                                    <DataValue>{`${moment(new Date()).format(
                                        "MMMM Do YYYY"
                                    )}`}</DataValue>
                                </Flex>
                             {/*    <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Compensation:</DataTitle>
                                    <DataValue>{'compensation'}</DataValue>
                                </Flex> */}
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Visibility:</DataTitle>
                                    <DataValue>
                                        {candidates.length === 1 ? "Direct" : "Open"}
                                    </DataValue>
                                </Flex>
                            </Box>
                            <Box width={[1, 1, 1, 1]}>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Code:</DataTitle>
                                    <DataValue>To be defined</DataValue>
                                </Flex>
                                <Flex width={[1]} mb={[".5em"]}>
                                    <DataTitle>Past Status:</DataTitle>
                                    <Status statusColor={"ASSIGNED"}>
                                        { pastReferralStatus.label }
                                    </Status>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    </Flex>
                    <Box mt={["1.5em"]}>
                        <Flex width={[1]} mb={[".5em"]}>
                            <DataValue>{description}</DataValue>
                        </Flex>
                    </Box>
                </Card>
            </Spacer>
            <ButtonContainer>
                <Box mr={16}>
                    <Button
                        width="120px"
                        onClick={saveDraft}
                        disabled={loading}
                    >
                        Save Draft
                    </Button>
                </Box>
                <Button onClick={() => sendingPastJobData()} disabled={false} type="submit">
                    Submit
                </Button>
            </ButtonContainer>
            {referralIsLoading && <PopupLoader />}
            {successReferral && <SuccesReferralSent/>}
            {errorReferral && <ErrorMessage />}
        </StepWrapper>
    );
};

export default JobPastPreview;
