import {
    OPEN_MODAL,
    CLOSE_MODAL,
    SHOW_TOAST,
    CLOSE_TOAST,
    UNSUCCESS_LOGIN
} from "../../constants";
import { signOut } from "../user";

function openModal(modal) {
    return {
        type: OPEN_MODAL,
        modal
    };
}

function closeModal(modal) {
    return {
        type: CLOSE_MODAL
    };
}

function setMessage(title, message, type = "error") {
    return {
        type: SHOW_TOAST,
        payload: {
            title: title,
            message: message,
            type: type
        }
    };
}

function handleError(error, type = "error", show = true) {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        console.log('handleError', error, '');
        
        dispatch( handleSignInErrors( error ) )

        if (
            error &&
            error.response &&
            error.response.status &&
            (error.response.status === 401 || error.response.status === 403)
        ) {
            dispatch(signOut());
            dispatch(
                setMessage("Expired session", "Login again to get into the app.")
            );
            return setTimeout(() => {
                dispatch(closeToast());
            }, 5000);
        }
        const parsedError = cloudfunctions.parseRequestError(error);
        if (show) {
            dispatch(setMessage(parsedError.title, parsedError.message, type));
            setTimeout(() => {
                dispatch(closeToast());
            }, 5000);
        }
    };
}

const handleSignInErrors = error => {
    return async (dispatch, getState, { cloudfunctions, firebase, LocalStorage }) => {
        if (error && error.code) {
            if (error.code === "auth/too-many-requests") {
                dispatch({
                    type: UNSUCCESS_LOGIN,
                    payload: {
                        captchaEnabled: true
                    }
                })
                }
        }
    };
};
function closeToast() {
    return {
        type: CLOSE_TOAST,
        payload: {}
    };
}

export {
    openModal,
    closeModal,
    handleError,
    closeToast
};
