import { Link } from "react-router-dom";
import styled from "styled-components";

export const AttentionPopStyled = styled.div`
max-width: 1035px;
background: #ffffff;
border-radius: 8px;
@media screen and (min-width: ${({ theme }) => theme.breakpoints["0"]}) {
   padding: 20px 20px;
}
@media screen and (min-width: ${({ theme }) => theme.breakpoints["1"]}) {
   padding: 40px 40px;
}
@media screen and (min-width: ${({ theme }) => theme.breakpoints["2"]}) {
   padding: 140px 60px;
}
`;

export const ContainerStyled = styled.div`
max-width: 1035px;
background: #ffffff;
border-radius: 8px;
padding: 80px;
`;

export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
gap: 48px;
align-items: center;
justify-content: center;
`;

export const AttentionText = styled.div`
font-size: 16px;
line-height: 24px;
text-align: center;
color: #333333;
`;

export const HorizontalLine = styled.div`
border-top: 1px solid #C4C4C4;
width: 100%;
`

export const ButtonContainer = styled.div`
min-width: 214px;
height: 40px;
display: flex;
gap: 24px;
position: relative;
z-index: 9999;
`;

export const Button = styled(Link)`
width: 160px;
// height: 100%;
cursor: pointer;
background-color: ${props => (props.continue ? "#00A15F" : "white")};
color: ${props => (props.continue ? "white" : "#00A15F")};
border-radius: 4px;
font-style: normal;
font-weight: 700;
font-weight: 700;
font-size: 16px;
line-height: 24px;
${props => (props.close ? "border: 2px solid #00A15F;" : "border: none;")};
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
`;

export const Title = styled.h1`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 40px;
text-align: center;
`