import { connect } from 'react-redux';
import View from './view';
import { setStepData } from '../../../../../../../redux/actions/creators/recommendationCreation';
import { OPPORTUNITY_CREATION_STEPS } from '../../constants';
import { withTheme } from 'styled-components'
const mapStateToProps = (state) => {
    return {
        currentStep: state.main.ui.view.recommendationCreationReducer.currentStep,
        stepData: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION],
        candidates: state.main.ui.view.recommendationCreationReducer.stepData[OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION],
        professions: state.main.ui.global.statics.userTypes.filter(filteredProfessions => filteredProfessions.status !== 'pending') || [],
        stepKey: OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setStepData: data => dispatch(setStepData(OPPORTUNITY_CREATION_STEPS.RECOMMENDATION_DIRECT_INFORMATION, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(View));
