import styled, { css } from "styled-components";
import { getThemeColor, getThemeOnColor } from "../../../../../../../theme";

export const InputSquare = styled.input`
    box-sizing: border-box;
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: ${props => `${props.height}px` || "46px"};
    border: ${props => props.border || `1px solid ${props.theme.greyLight7}`};
    border-radius: 3px;
    font-size: ${props => props.theme.mobileDevice ? props.theme.fonts['XXS'] : props.theme.fonts['XS']};
    line-height: ${props => props.theme.mobileDevice ? props.theme.lineHeights['XXS'] : props.inputStyles && props.inputStyles.lineHeight ? props.inputStyles.lineHeight : '22px'}; 
    margin-top: ${props => props.label ? '8px' : '0px'};
    padding: ${props => props.theme.mobileDebice ? props.theme.spacing['2'] : props.theme.spacing['3']};
    padding-left: ${props => (props.leftIcon ? "3em" : "1em")};
    padding-right: ${props => (props.rightIcon ? "3em" : "1em")};
    color: ${props =>
        props.inputStyles && props.inputStyles.color
            ? props.inputStyles.color
            : `${props.theme.blueMedium}`};
    background-color: ${props =>
        props.inputStyles && props.inputStyles.backgroundColor
            ? props.inputStyles.backgroundColor
            : `white`};
    & :focus {
        outline: none;
        border: ${props =>
        `1px solid ${props.inputStyles && props.inputStyles.color
            ? props.inputStyles.color
            : props.theme.blueMedium
        }`};
    }
    ${props =>
        props.highlightOnHover && css`
        & :hover {
            outline: none;
            border: ${props =>
                `1px solid ${props.inputStyles && props.inputStyles.color
                    ? props.inputStyles.color
                    : props.theme.blueMedium
                }`};
        }`
    }

    ${props =>
        props.error
            ? css`
                      color: ${getThemeOnColor({ invalid: true })};
                      
                      border: ${props => `1px solid ${props.theme.onInvalid}`};
                  `
            : null}
        :disabled {
        ${props =>
        props.error
            ? css`
                      background-color: ${getThemeColor({ invalid: true })};
                      color: ${getThemeOnColor({ invalid: true })};
                  `
            : css`
                      background-color: ${getThemeColor({ disabled: true })};
                      color: ${getThemeOnColor({ disabled: true })};
                  `}
    }

    ::placeholder {
        color: ${props =>
        props.error
            ? props.theme.onInvalid
            : props.placeHolderStyles && props.placeHolderStyles.color
                ? props.placeHolderStyles.color
                : props.theme.placeHolder};
    }

    :-ms-input-placeholder {
        color: ${props =>
        props.error
            ? props.theme.onInvalid
            : props.placeHolderStyles && props.placeHolderStyles.color
                ? props.placeHolderStyles.color
                : props.theme.placeHolder};
    }
`;

export const InputLabel = styled.label`
   /*  position: absolute;
    top: -8px; */
    color: white;
    font-weight: ${props => props.theme.fontWeights['medium']};
    font-size: ${props => props.theme.fonts['XS']};
    /* line-height: ${props => props.theme.lineHeights['XS']}; */
    ${InputSquare}:focus ~ & {
        color: ${props => props.theme.blueMedium};
    }
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
`;

export const IconContainer = styled.div`
    position: absolute;
    right: 1em;
    bottom: 0.7em;
    cursor: pointer;
    z-index: 999;
`;

export const LeftIconContainer = styled.div`
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 1em;
    bottom: ${props => `calc(50% - ${props.height / 2 + 2}px)`};
    cursor: pointer;
    z-index: 999;
`;
