import React, { createContext, Component } from "react";
const ValuesContext = createContext({});
const ErrorsContext = createContext({});
const SetValueContext = createContext(() => {});

export class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.defaultValues ? props.defaultValues : {},
            touched: {},
            errors: {},
            isValidForm: props.isValidForm ? props.isValidForm : false
        };
    }

    render() {
        return (
            <ValuesContext.Provider
                value={{
                    values: this.state.values,
                    isValidForm: this.state.isValidForm
                }}
            >
                <ErrorsContext.Provider value={this.state.errors}>
                    <SetValueContext.Provider
                        value={{
                            _setValues: this._setValues.bind(this),
                            _setValue: this._setValue.bind(this),
                            _unsetValue: this._unsetValue.bind(this),
                            // _setNumberInputValue: this._setNumberInputValue.bind(this),
                            _setAgencyValue: this._setAgencyValue.bind(this),
                            _onChangeNumberInputValue: this._onChangeNumberInputValue.bind(
                                this
                            ),
                            _blurInput: this._blurInput.bind(this),
                            _onChangeInput: this._onChangeInput.bind(this),
                            _onSelectUserLocationAddressAutocomplete: this._onSelectUserLocationAddressAutocomplete.bind(
                                this
                            )
                        }}
                    >
                        <form>{this.props.children}</form>
                    </SetValueContext.Provider>
                </ErrorsContext.Provider>
            </ValuesContext.Provider>
        );
    }

    _setValue(name, value, rules = [], showError, inputType = null, ) {
     
        this.setState(
            (prevState, props) => {
                let newValues = { ...prevState.values, [name]: value };

                return {
                    values: newValues
                };
            },
            () => {
              
                this._validate(name, value, rules, showError, inputType);
                
            }
        );
    }

    _unsetValue = name => {
        this.setState((prevState, props) => {
            let values = { ...prevState.values };
            let touched = { ...prevState.touched };
            let errors = { ...prevState.errors };
            delete errors[name];
            delete values[name];
            delete touched[name];
            return { values: values, touched: touched, errors: errors };
        });
    };

    _validate(name, value, rules = [], showError, inputType = null) {
        this.setState((prevState, props) => {
            
            let newErrors = { ...prevState.errors };

            let isValid, message;

            try {
                ({ isValid, message } = this._validateInputRules(
                    rules,
                    value,
                    newErrors[name],
                    name,
                    inputType
                ));
                newErrors = {
                    ...newErrors,
                    [name]: {
                        isValid,
                        message,
                        showError: showError
                    }
                };
            } catch (error) {
                console.log(error);
                newErrors = {
                    ...newErrors,
                    [name]: {
                        isValid: false,
                        message: error.message,
                        showError: true
                    }
                };
            }

            const isValidForm = this._isValidForm(newErrors)
          
            return {
                errors: newErrors,
                isValidForm: isValidForm
            };
        });
    }

    _isValidForm = errors => {
        return (
            Object.keys(errors)
                .filter(key => !key.includes("FormattedValue"))
                .map(key => errors[key])

                //There is any errors?
                .reduce((prev, current) => prev && current.isValid, true)
        );
    };

    _onChangeInput = (value, name, rules, showError) => {
        this._setValue(name, value, rules, showError ? showError : false);
    };

    _onChangeNumberInputValue = (
        formattedValue,
        value,
        name,
        rules,
        showError
    ) => {
        this._setValue(name, value, rules, false);
        this._setValue(`${name}FormattedValue`, formattedValue, rules, false);
    };

    _blurInput = (value, name, rules) => {
        this._validate(name, value, rules, true);
    };

    _setAgencyValue = (item, name, rules, showError = true) => {
        this._setValue(name, item.name, rules, true);
        this._setValue(item.key, item, rules, true);
    };

    autocompleteExtraRule = name => {
        return {
            key: "selection",
            method: () =>
                typeof this.state.values[`${name}Selection`] !== "undefined" &&
                this.state.values[`${name}Selection`] !== null,
            validWhen: true,
            message: "You must select an option"
        };
    };

    _validateInputRules = (
        rules = [],
        value,
        error,
        name = null,
        inputType = null
    ) => {
        let message;
        value = value && typeof value !== undefined ? value : "";

        const isAutocomplete =
            inputType && name && inputType === "autocomplete";
        if (isAutocomplete) {
            if (rules.find(rule => rule.key === "selection")) rules.pop();
            rules.push(this.autocompleteExtraRule(name));
        }

        const isValid = rules
            .map(rule => {
                return rule.method(value, ...(rule.args || [])) ===
                    rule.validWhen
                    ? true
                    : (message = rule.message) && false;
            })
            .reduce((current, prev) => current && prev, true);

        /**
         * @param {boolean} isValid Represents the validity of the input value for the set of rules passed.
         * @param {string} Contains An error message, if isValid is true, returns undefined if not if isValid is false.
         */
        return {
            ...error,
            isValid,
            message
        };
    };

    _onSelectUserLocationAddressAutocomplete = (item, name, rules, showError = true) => {
        this._setValues(name, item, rules, showError);
    };

    _setValues = (name, newValues, rules = [], showError) => {
        let values = { ...this.state.values };
        let errors = { ...this.state.errors };
        let touched = { ...this.state.touched };
        // ** MULTIPLE VALUES FOR EACH
        newValues.forEach(el => {
            // ** MULTIPLE VALUES FOR EACH
            values[el.name] = el.value;
            touched[el.name] = true;

            if (name === el.name) {
                try {
                    const { isValid, message } = this._validateInputRules(
                        rules,
                        el.value,
                        errors[el.name]
                    );

                    errors = {
                        ...errors,
                        [el.name]: {
                            isValid,
                            message,
                            showError:
                                errors[el.name] &&
                                (errors[el.name].showError === true ||
                                    showError)
                        }
                    };
                } catch (error) {
                    errors[el.name] = {
                        isValid: false,
                        message: error.message,
                        showError: true
                    };
                }
            }
        });
        if (!newValues || newValues.length === 0)
            this._validate(name, "", rules);
        else {
            const isValidForm = this._isValidForm(errors)
            this.setState({ values, touched, errors, isValidForm }, () => {});
        }
    };
}

export const FormConsumer = ({ children }) => {
    return (
        <ErrorsContext.Consumer>
            {errors => (
                <ValuesContext.Consumer>
                    {values => (
                        <SetValueContext.Consumer>
                            {functions =>
                                children({ errors, ...values, ...functions })
                            }
                        </SetValueContext.Consumer>
                    )}
                </ValuesContext.Consumer>
            )}
        </ErrorsContext.Consumer>
    );
};

export const FormWithConsumer = Component => {
    return otherProps => (
        <Form>
            <FormConsumer>
                {({ isValidForm, values, ...other }) => {
                    return (
                        <Component
                        {...other}
                            {...otherProps}
                            values={values}
                            isValidForm={isValidForm}
                        />
                    );
                }}
            </FormConsumer>
        </Form>
    );
};

export const withForm = Component => {
    return otherProps => (
        <ErrorsContext.Consumer>
            {errors => (
                <ValuesContext.Consumer>
                    {values => (
                        <SetValueContext.Consumer>
                            {functions => {
                                return (
                                    <Component
                                        {...errors}
                                        {...values}
                                        {...functions}
                                        {...otherProps}
                                    />
                                );
                            }}
                        </SetValueContext.Consumer>
                    )}
                </ValuesContext.Consumer>
            )}
        </ErrorsContext.Consumer>
    );
};
