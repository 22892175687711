import React from 'react'
import { Flex } from "rebass"
import Select from "react-select";
import {
    Input,
    InputContainer,
    InputLabel,
} from "../../../../Settings/common/Components";
import {  selectStyle } from "../../../components";

import { candidatesAcceptanceOptions } from "../../../constants"

const AcceptanceCondition = ({ theme, formValues, onChange, errors }) => (
    <Flex mt={theme.spacing[3]}>
        <InputContainer
            triggerShowLabel={
                formValues.state ? "show" : "hide"
            }
            showLabelOnValue
        >
            <Select
                options={candidatesAcceptanceOptions}
                onChange={option =>
                    onChange({
                        target: {
                            name: "acceptanceCondition",
                            value: option,
                        }
                    })
                }
                styles={selectStyle}
                name="acceptanceCondition"
                placeholder="Select... *"
                value={formValues.acceptanceCondition}
                error={!!errors.acceptanceCondition}
            />
        </InputContainer>
    </Flex>
)

export default AcceptanceCondition;