import React, { Fragment, useEffect, useState } from "react";
import { Flex, Box } from "rebass";
import { withTheme } from "styled-components";
import { Text } from "../../../../ui/presentation/atoms/shared/Text";
import Button from "../../../../ui/presentation/atoms/shared/Buttons/Button";
import { Icon } from "../../../../ui/presentation/atoms/shared/Icon";
import Avatar from "../../../../ui/presentation/atoms/shared/AvatarCircle2";
import {
    AvatarContainer,
    AvatarIconContainer,
    NotificationBody
} from "./styles";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { NOTIFICATIONS_BELL_TYPES } from "../../../../../assets/strings";
import Skeleton from "react-loading-skeleton";


const AvatarWithIcon = props => {
    const { avatar = "", icon: Icon, onClick, read, type } = props;
    return (
        <AvatarContainer onClick={onClick} read={read} type={type}>
            <Avatar src={avatar} size="40px" />
            <AvatarIconContainer>{Icon}</AvatarIconContainer>
        </AvatarContainer>
    );
};
const NotificationsItem = props => {
    const [ loadingConnect, setLoadingConnect ] = useState(false)
    const {
        theme,
        t,
        notification,
        icon,
        handleAcceptConnection,
        handleDeclineConnection,
        handleRemoveNotification,
        handleClickUser,
        handleClickNotification,
        currentUser,
        notificationId,
        myId,
        acceptKnockConnectRequest,
        declineKnockRequest,
        setMarkThisNotificationAsRead
    } = props;

    // Notification info

    const { actors, inserted_at, source, blocks } = notification  ? notification : {};


   

    const name =     actors.length > 0  && actors[0].name      ? actors[0].name  : '';
    const avatar =   actors.length > 0  && actors[0].avatar    ? actors[0].avatar  : ''
    const userName = actors.length > 0  && actors[0].user_name  ? actors[0].user_name  : '';
    const id =       actors.length > 0  && actors[0].id        ? actors[0].id  : ''
    const type =     source && source.key;

    const rendered  = blocks && blocks.length > 0 ? blocks[0].rendered :  '';
    // User info: when the notification is op sharer, current user should be displayed, for the rest of the notifications, the user who triggered the notification should be displayed
/*     const { name, avatar, uid, userName } =
        from
            ? from
            : user && type !== NOTIFICATIONS_BELL_TYPES.OPPSHARESHARER
                ? user
                : currentUser; */
   /*  const description = t(type, {
        code: refID,
        name: name && Utils.getNameWithAnSAtTheEnd(name)
    }); */
    const date = new Date(inserted_at);
    const getTimestamp = date.getTime()
    const createdAt = moment(getTimestamp).fromNow();

    const onClickUser = () => {
        handleClickUser(userName);
    };





    const nameShouldRender =
        type !== NOTIFICATIONS_BELL_TYPES.FAVORITE &&
        type !== NOTIFICATIONS_BELL_TYPES.OPPSHARESHARER &&
        !!name;

    const renderName = () =>
        nameShouldRender && (
            <Text fontSize="XXS" fontWeight="bold" color={''}>
                {`${name} `}
            </Text>
        );

    /* const shouldNameGoLast =
        type === NOTIFICATIONS_BELL_TYPES.OPPSHAREOWNER ||
        type === NOTIFICATIONS_BELL_TYPES.ENDORSER;

    const nameColor =
        !read || type === NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION
            ? theme.text
            : theme.taupeGray;
 */
    // If the notification has a body, it should be rendered as the main content of the notification,
    // otherwise, the notification message will be built with the description and the name of the user
    const renderNotificationBody = () => rendered ? (
        <NotificationBody
            fontSize="XXS"
            fontWeight="normal"
            color={theme.taupeGray}
            style={{ wordBreak: "break-line" }}
            dangerouslySetInnerHTML={{ __html: rendered }}
        />
    ) : (
        <>
         {/*    {!shouldNameGoLast && renderName()}
            <Text
                fontSize="XXS"
                fontWeight="normal"
                color={nameColor}
                style={{ wordBreak: "break-line", marginRight: shouldNameGoLast && theme.spacing["1"] }}
                dangerouslySetInnerHTML={{ __html: '' }}
            />
            {shouldNameGoLast && renderName()} */}
        </>
    )

    return (
        <Fragment>
            <Flex
                alignItems="center"
                justifyContent="flex-start"
                onClick={() => setMarkThisNotificationAsRead(notificationId)}
                mb={
                    type === NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION
                        ? theme.spacing["2"]
                        : theme.spacing["4"]
                }
                mt={theme.spacing["4"]}
            >
                <AvatarWithIcon
                    theme={theme}
                    avatar={avatar}
                    icon={icon}
                    onClick={onClickUser}
                    read={false}
                />
                <div
                    style={{ marginLeft: theme.spacing["5"] }}
                    onClick={() => handleClickNotification(notification)}
                >
                    <div style={{ display: "inline-block" }}>
                        {renderNotificationBody()}
                        <Text
                            fontSize="XXS"
                            fontWeight="normal"
                            color={theme.taupeGray}
                            style={{ whiteSpace: "nowrap", marginLeft: theme.spacing[1] }}
                        >
                            {createdAt}
                        </Text>
                    </div>
                </div>
                <Box ml={theme.spacing["4"]} flexGrow="1">
                    <Icon
                        icon="close"
                        color={theme.taupeGray}
                        width="20px"
                        height="20px"
                        onClick={() => {
                            handleRemoveNotification(notificationId);
                        }}
                    />
                </Box>
            </Flex>
            {source.key === "connection-request"  && (
                <Flex
                    width={[1]}
                    alignItems="center"
                    justifyContent="center"
                    mb={theme.spacing["4"]}
                >
                   {loadingConnect ?
                     <>
                        <Skeleton width="240px" height={'42px'} />
                     </>

                     :

                     <>
                       {/*  <Button
                        type="PRIMARY"
                        size="S"
                        label="Accept"
                        style={{ marginRight: theme.spacing["2"] }}
                        onClick={actors.length > 0 ? () =>  {
                            setLoadingConnect(true)
                            acceptKnockConnectRequest(actors[0].id, notification.id, handleRemoveNotification)
                           // setLoadingConnect(false)
                        } : {}}
                        onMouseDown={e => {
                            e.preventDefault();
                        }}
                    /> */}
                   {/*  <Button
                        type="BORDER"
                        size="S"
                        label="Decline"
                        style={{ marginLeft: theme.spacing["2"] }}
                        onClick={actors.length > 0 ? () => 
                            declineKnockRequest(actors[0].id, notification.id, handleRemoveNotification)
                            : {}
                        }
                        onMouseDown={e => {
                            e.preventDefault();
                        }}
                    /> */}

                     </>

                   }
                </Flex>
            )}
     {/*        {type === NOTIFICATIONS_BELL_TYPES.NEW_CONNECTION && status && (
                <Flex
                    width={[1]}
                    alignItems="center"
                    justifyContent="center"
                    mb={theme.spacing["4"]}
                >
                    <Icon
                        icon={"check-filled"}
                        width="12px"
                        height="12px"
                        color={theme.greenMedium}
                    />
                    <Text
                        fontSize="XXS"
                        fontWeight="normal"
                        color={theme.taupeGray}
                        style={{
                            marginLeft: theme.spacing["2"],
                            whiteSpace: "nowrap"
                        }}
                    >
                        {status === "ACCEPTED" ? "Accepted" : "Declined"}
                    </Text>
                </Flex>
            )} */}
        </Fragment>
    );
};

export default withTheme(withTranslation("notifications")(NotificationsItem));