import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from 'react-select';
import { StepWrapper, Button, selectStyle, ButtonContainer, MainText } from "../../components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 1em;
`;

const ReferralProffesion = ({ setStepData, stepData, professions }) => {
    const [selectedProfession, setSelectedProfession] = useState(stepData);
    const [professionOptions, setProfessionOptions] = useState([]);

    const onClickHandler = () => {
        setStepData(selectedProfession);
    };

    useEffect(() => {
        if (professions !== [] && professions.length) {
            setProfessionOptions(professions.map(p => ({ value: p.key, label: p.name, global: !!p.global })));
        }
    }, [professions]);

    return (
        <StepWrapper>
            <Container>
                <MainText>Select the profession type you are seeking:</MainText>
                <SelectContainer>
                    <Select
                        options={professionOptions}
                        onChange={(ev) => {setSelectedProfession(ev)}}
                        styles={selectStyle}
                        value={selectedProfession}
                        isClearable
                        placeholder="Please pick something"
                    />
                </SelectContainer>
                <ButtonContainer>
                    <Button
                        onClick={onClickHandler}
                        disabled={selectedProfession === {} || selectedProfession === null || selectedProfession === undefined}
                    >
                        Next
                    </Button>
                </ButtonContainer>
            </Container>
        </StepWrapper>
    );
};

export default ReferralProffesion;
