//import Ajv from "ajv";
import {
    SEND_REFERRAL_IS_LOADING,
    FETCH_REFERRALS,
    OPEN_REFERRAL,
    OPEN_REFERRAL_FAIL,
    OPEN_REFERRAL_SUCC,
    FETCH_REFERRALS_FAIL,
    FETCH_REFERRALSUSERS_SUCC,
    REFERRALS_INIT,
    REFERRALS_SET_SELECTED_TAB,
    REFERRALS_DELETE_SENT,
    REFERRALS_ADD_SENT,
    REFERRALS_DELETE_INCOMING,
    SEND_REFERRAL_TOOGLEMESSAGEMODAL,
    REFERRALS_ONVALUE,
    REFERRALS_FETCH_SUCCESS,
    REFERRALS_IS_LOADING,
    REFERRALS_FOOTER_OPEN,
    SEND_REFERRAL_CLEAN_FORM,
    REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL,
    SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO,
    REFERRALS_UPDATE_FILTERS,
    REFERRALS_RESET_FILTERS,
    REFERRALS_NOT_AUTHORIZED_MODAL,
    SHOW_REFERRALS_FILTERS,
    HIDE_REFERRALS_FILTERS,
    REFERRALS_APPLY_FILTERS,
    FETCH_DRAFT_REFERRALS,
    FETCH_MYOPS_ANALYTICS_LOADING,
    FETCH_MYOPS_ANALYTICS_SUCCESS,
    FETCH_MYOPS_ANALYTICS_FAIL
} from "../../constants";

import { batch } from 'react-redux'
import { handleError } from "../modal";
import { push } from "connected-react-router";
import { addGlobaluser } from "../user";
import Utils from '../../../../libs/Utils'
import ReferralBusiness from "../../../../business/referral";
import { SECTION_REVIEW } from '../../../../components/ui/presentation/views/SendReferral/constants'
import { sendReferralSuccess, sendReferralPreventPrompt } from '../sendReferral'
import moment from "moment";
import _ from "lodash";


const cleanForm = () => {
    return {
        type: SEND_REFERRAL_CLEAN_FORM
    };
};

const deleteDraftReferral = (referralId) => {
    return async (dispatch, getState, { firebase }) => {
        const uid = getState().main.user.uid
        try {
            await firebase.deleteDraftReferral(uid, referralId);
            dispatch(getDraftReferrals())
        } catch (error) {
            dispatch(handleError(error))
        }
    }
}

const getDraftReferrals = () => {
    return async (dispatch, getState, { firebase }) => {
        const uid = getState().main.user.uid
        try {
            let draftReferrals = await firebase.getDraftReferrals(uid)
            draftReferrals = Object.values(draftReferrals || {})
            draftReferrals = draftReferrals.map(draftReferral => {

                return {
                    ...draftReferral,
                    fromUser: {
                        ...draftReferral.from
                    }
                };
            })

            dispatch({
                type: FETCH_DRAFT_REFERRALS,
                payload: {
                    draftReferrals: draftReferrals || []
                }
            })
        } catch (error) {
            dispatch(handleError(error))
        }
    }
}

const sendReferral = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: SEND_REFERRAL_IS_LOADING,
            payload: {
                section: SECTION_REVIEW,
                isLoading: true
            }
        });

        try {
            const { SHARE, FORM, SELECT_CANDIDATES, SIGNATORY, PROFESSION_TYPE } = getState().main.ui.view.sendReferral
            const statics = getState().main.ui.global.statics
            const referralHowToSendKeys = statics.referralHowToSend.optionsKeys
            const clientTypesKeys = statics.clientTypes.optionsKeys
            const propertyTypesKeys = statics.propertyTypes.optionsKeys
            const shareWithKeys = statics.referralShareWith && statics.referralShareWith.optionsKeys
            const feeTypesKeys = statics.referralFeeTypes.optionsKeys
            const signatoryTypeKeys = statics.referralSignatoryTypes.optionsKeys
            const me = getState().main.user.profile
            const meId = getState().main.user.uid
            const from = ReferralBusiness.referralUser(
                meId,
                me.address,
                me.company_info.id,
                me.company_info.name,
                me.email,
                me.lastname,
                me.name,
                me.phone,
                me.state,
                me.type,
                me.avatar,
                me.userName
            )

            const shareWith = SHARE.values && SHARE.values.shareWith
            const openReferral = shareWith === shareWithKeys.ALL
            const type = openReferral ? referralHowToSendKeys.OPEN : referralHowToSendKeys.FIRST_COME
            let profession = []
            const toNormalized = openReferral ? null : []
            const referralLocation = FORM.referralLocation.values.referralLocation
            const locationNormalized = {
                coords: referralLocation && referralLocation.location_coords,
                fullAddress: referralLocation && referralLocation.address,
                state: referralLocation && referralLocation.state,
                stateShort: referralLocation && referralLocation.state_,
                subAdministrativeArea: referralLocation && referralLocation.subAdministrativeArea,
                zipcode: referralLocation && referralLocation.zipcode
            }
            if (!openReferral) {
                const candidates = SELECT_CANDIDATES.candidates
                candidates.forEach(candidate => {
                    const rUser = ReferralBusiness.referralUser(
                        candidate.uid,
                        candidate.locationAddress,
                        candidate.company_id ? candidate.company_id : ' ',
                        candidate.company_name,
                        candidate.email,
                        candidate.last_name,
                        candidate.first_name,
                        candidate.phone,
                        candidate.state ? candidate.state : ' ',
                        candidate.user_type,
                        candidate.avatar,
                        candidate.userName
                    )
                    toNormalized.push(rUser)
                    profession.push(candidate.user_type)
                })

            } else {
                profession = PROFESSION_TYPE.values.profession ? PROFESSION_TYPE.values.profession : null
            }
            const client = FORM.client.values
            const clientNormalized = {
                address: client.address,
                fullName: client.fullName,
                email: client.email,
                phone: client.phone && client.phone.value
            }

            const fee = FORM.fee.values
            let feeNormalized = {
                type: fee.feeType
            }

            switch (fee.feeType) {
                case feeTypesKeys.REAL_ESTATE: {
                    feeNormalized.fee = fee.realEstateFee && fee.realEstateFee.value
                    break;
                }
                case feeTypesKeys.FLAT: {
                    feeNormalized.fee = fee.flatFeeAmount && fee.flatFeeAmount.value
                    feeNormalized.due = fee.paymentDue
                    feeNormalized.comments = fee.comments
                    break;
                }

                case feeTypesKeys.GROSS_REVENUE: {
                    feeNormalized.fee = fee.realEstateFee && fee.realEstateFee.value
                    feeNormalized.duration = fee.feeDuration
                    feeNormalized.frequency = fee.feeFrequency
                    feeNormalized.commencement = fee.feeCommencement
                    feeNormalized.comments = fee.comments
                    break;
                }

                case feeTypesKeys.OTHER: {
                    feeNormalized.comments = fee.comments
                    break;
                }
                default:
                    break;
            }

            const realEstateDetails = FORM.realStateDetails.values
            let realEstateDetailsNormalized
            if (realEstateDetails && realEstateDetails.clientType) {
                const showAcres = ReferralBusiness.showAcres(realEstateDetails.propertyType, realEstateDetails.clientType, clientTypesKeys, propertyTypesKeys)
                const showMinMaxAcres = ReferralBusiness.showMinMaxAcres(realEstateDetails.propertyType, realEstateDetails.clientType, clientTypesKeys, propertyTypesKeys)
                const showMinMaxPrice = ReferralBusiness.showMinPrice(realEstateDetails.clientType, clientTypesKeys)
                const showEstimatedPrice = ReferralBusiness.showEstimatedPrice(realEstateDetails.clientType, clientTypesKeys)
                const showBedsBaths = ReferralBusiness.showBeds(realEstateDetails.propertyType, realEstateDetails.clientType, clientTypesKeys, propertyTypesKeys)
                const showMinmaxSize = ReferralBusiness.showMinSize(realEstateDetails.propertyType, realEstateDetails.clientType, clientTypesKeys, propertyTypesKeys)
                const showFinancingInfo = ReferralBusiness.showFinancingInfo(realEstateDetails.clientType, clientTypesKeys)
                const showSize = ReferralBusiness.showSize(realEstateDetails.propertyType, realEstateDetails.clientType, clientTypesKeys, propertyTypesKeys)

                realEstateDetailsNormalized = {
                    minPrice: showMinMaxPrice ? realEstateDetails.minPrice && realEstateDetails.minPrice.value : null,
                    maxPrice: showMinMaxPrice ? realEstateDetails.maxPrice && realEstateDetails.maxPrice.value : null,
                    estimatedPrice: showEstimatedPrice ? realEstateDetails.estimatedPrice && realEstateDetails.estimatedPrice.value : null,
                    beds: showBedsBaths ? realEstateDetails.beds && realEstateDetails.beds.value : null,
                    baths: showBedsBaths ? realEstateDetails.baths && realEstateDetails.baths.value : null,
                    size: showSize ? realEstateDetails.size && realEstateDetails.size.value : null,
                    minSize: showMinmaxSize ? realEstateDetails.minSize && realEstateDetails.minSize.value : null,
                    maxSize: showMinmaxSize ? realEstateDetails.maxSize && realEstateDetails.maxSize.value : null,
                    sizeUnit: (showMinmaxSize || showSize) ? realEstateDetails.sizeUnit : null,
                    clientType: realEstateDetails.clientType,
                    propertyType: realEstateDetails.propertyType,
                    financingInfo: showFinancingInfo ? realEstateDetails.financingInfo : null,
                    minAcres: showMinMaxAcres ? realEstateDetails.minAcres && realEstateDetails.minAcres.value : null,
                    maxAcres: showMinMaxAcres ? realEstateDetails.maxAcres && realEstateDetails.maxAcres.value : null,
                    acres: showAcres ? realEstateDetails.acres && realEstateDetails.acres.value : null,
                }
            }


            const signatoryType = FORM.signatory.values.signatory
            let signatory = null
            if (signatoryType) {
                signatory = {
                    type: signatoryType
                }
            }
            const needSignatory = signatoryType === signatoryTypeKeys.I_WILL
            if (needSignatory) {

                signatory.insideReally = SIGNATORY.insideReally
                if (signatory.insideReally) {
                    const selectedUser = SIGNATORY.selectedUser
                    signatory.info = ReferralBusiness.referralUser(
                        selectedUser.uid,
                        selectedUser.locationAddress,
                        selectedUser.company_id ? selectedUser.company_id : '',
                        selectedUser.company_name,
                        selectedUser.email,
                        selectedUser.last_name,
                        selectedUser.first_name,
                        selectedUser.phone,
                        selectedUser.state ? selectedUser.state : '',
                        selectedUser.user_type,
                        selectedUser.userName
                    )
                } else signatory.info = signatory.emails
            }
            let timingInfoNormalized = { ...FORM.timingInfo.values }
            const lastContactNormalized = Math.round(new Date(FORM.timingInfo.values.lastContact).getTime() / 1000)
            timingInfoNormalized.lastContact = lastContactNormalized

            const notificationRadius = FORM.notificationRadius.values.notificationRadius
            const payload = Utils.cleanObject({
                from: Utils.cleanObject(from),
                notificationRadius: notificationRadius,
                fromId: from.uid,
                type: type,
                profession: profession,
                location: Utils.cleanObject(locationNormalized),
                description: FORM.description.values.description,
                to: toNormalized,
                toExternal: SELECT_CANDIDATES.emails,
                realEstateDetails: realEstateDetailsNormalized ? Utils.cleanObject(realEstateDetailsNormalized) : null,
                client: Utils.cleanObject(clientNormalized),
                timingInfo: Utils.cleanObject(timingInfoNormalized),
                fee: Utils.cleanObject(feeNormalized),
                createAt: Date.now(),
                //otherInfo: FORM.otherInfo.values.otherInfo,
                signatory: Utils.cleanObject(signatory)
            })


            await cloudfunctions.sendReferral(
                'PWA',
                payload.type,
                payload.profession,
                payload.fromId,
                payload.from,
                payload.to,
                payload.toExternal,
                payload.description,
                payload.client.fullName,
                payload.client.address,
                payload.client.phone,
                payload.client.email,
                payload.timingInfo.clientSource,
                payload.timingInfo.currentStatus,
                payload.timingInfo.lastContact,
                payload.realEstateDetails ? payload.realEstateDetails.financingInfo : null,
                payload.realEstateDetails ? payload.realEstateDetails.minPrice : null,
                payload.realEstateDetails ? payload.realEstateDetails.maxPrice : null,
                payload.realEstateDetails ? payload.realEstateDetails.estimatedPrice : null,
                payload.realEstateDetails ? payload.realEstateDetails.clientType : null,
                payload.realEstateDetails ? payload.realEstateDetails.propertyType : null,
                payload.realEstateDetails ? payload.realEstateDetails.minSize : null,
                payload.realEstateDetails ? payload.realEstateDetails.maxSize : null,
                payload.realEstateDetails ? payload.realEstateDetails.size : null,
                payload.realEstateDetails ? payload.realEstateDetails.beds : null,
                payload.realEstateDetails ? payload.realEstateDetails.baths : null,
                payload.realEstateDetails ? payload.realEstateDetails.minAcres : null,
                payload.realEstateDetails ? payload.realEstateDetails.maxAcres : null,
                payload.realEstateDetails ? payload.realEstateDetails.acres : null,
                // payload.otherInfo,
                payload.location.state,
                payload.location.stateShort,
                payload.location.zipcode,
                payload.location.subAdministrativeArea,
                payload.location.coords,
                payload.location.fullAddress,
                payload.fee.type,
                payload.fee.fee,
                payload.fee.duration,
                payload.fee.frequency,
                payload.fee.commencement,
                payload.fee.due,
                payload.fee.comments,
                payload.notificationRadius,
                payload.signatory ? payload.signatory.type : null,
                payload.signatory ? payload.signatory.insideReally : null,
                payload.signatory ? payload.signatory.info : null,
                payload.realEstateDetails ? payload.realEstateDetails.sizeUnit : null,
            )
            dispatch(sendReferralPreventPrompt(false))
            // batch(() => {

            dispatch({
                type: SEND_REFERRAL_IS_LOADING,
                payload: {
                    section: SECTION_REVIEW,
                    isLoading: false
                }
            });
            dispatch(push('/referrals'));
            dispatch(sendReferralSuccess(true));
            dispatch({
                type: SEND_REFERRAL_SAVE_CONGRATS_MODAL_INFO,
                payload: {
                    isOnlyOneMember: toNormalized
                        ? toNormalized.length === 1
                        : false,
                    theOnlyMember: toNormalized ? toNormalized[0] : null,
                    shareWith
                }
            });
            // })

        } catch (error) {
            console.error('sendReferral', error)
            dispatch(handleError(error));
            dispatch({
                type: SEND_REFERRAL_IS_LOADING,
                payload: {
                    section: SECTION_REVIEW,
                    isLoading: false
                }
            });
        }
    };
};

const fetchIncomingOpenReferrals = (lat, lon) => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: OPEN_REFERRAL
        });
        try {
            const meId = getState().main.user.uid
            const response = await cloudfunctions.fetchIncomingOpenReferrals(lat, lon)
            const currentOpenReferrals = getState().main.ui.view.referrals.openReferrals
            const referralStatusKeys = getState().main.ui.global.statics.referralStatus && getState().main.ui.global.statics.referralStatus.optionsKeys
            let openReferrals = [...currentOpenReferrals]
            if (response && response.data) {
                Object.keys(response.data).forEach(referralKey => {
                    const referral = response.data[referralKey]
                    const myReferralStatus = ReferralBusiness.getStatus(referral, meId)
                    if (referral.fromId !== meId && referral.status !== referralStatusKeys.ASSIGNED && myReferralStatus === referralStatusKeys.SUBMITTED && myReferralStatus !== referralStatusKeys.REJECTED && !openReferrals.find(rer => rer.id === referralKey)) openReferrals.push({ ...response.data[referralKey], id: referralKey })
                })

                dispatch({
                    type: OPEN_REFERRAL_SUCC,
                    payload: {
                        openReferrals: openReferrals
                    }
                });
            }
        } catch (error) {
            dispatch({
                type: OPEN_REFERRAL_FAIL,
                //payload: id
            });
        }
    };
};

const deleteIncomingOpenReferral = refId => ({
    type: REFERRALS_DELETE_INCOMING,
    payload: {
        refId: refId
    }
});

const subscribeToMyReferrals = uid => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: FETCH_REFERRALS
        });
        try {

            await firebase.subscribeToMyReferrals(
                uid,
                (referrals, referralsKey) => {
                    let sent = []
                    let received = []
                    if (referrals && referrals.sent) {
                        Object.keys(referrals.sent).forEach(referralKey => sent.push({ ...referrals.sent[referralKey], id: referralKey }))
                    }
                    if (referrals && referrals.received) {
                        Object.keys(referrals.received).forEach(referralKey => received.push({ ...referrals.received[referralKey], id: referralKey }))
                    }
                    dispatch(onReferralValue({ sent, received }));
                }
            );
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
            return dispatch({
                type: FETCH_REFERRALS_FAIL,
                errors: cloudfunctions.parseRequestError(error)
            });
        }
    };
};

const onReferralValue = (referrals) => {
    return {
        type: REFERRALS_ONVALUE,
        payload: {
            referrals: referrals
        }
    };
};

const fetchUser = uid => {
    return async (dispatch, getState, { firebase, LocalStorage }) => {
        try {
            if (uid) {
                //let data = LocalStorage.getUser(uid);
                let data = getState().main.ui.global.users.data.find(
                    user => user.uid === uid
                );

                if (!data) {
                    data = await firebase.fetchProfile(uid);
                    dispatch(addGlobaluser(uid, data));
                }
                return dispatch({
                    type: FETCH_REFERRALSUSERS_SUCC,
                    payload: {
                        uid: uid,
                        referralsUsers: data
                    }
                });
            }
        } catch (errors) {
            dispatch(handleError(errors));
        }
    };
};

const cleanReferrals = () => {
    return {
        type: REFERRALS_INIT
    };
};

const redeemReferral = code => {
    return async (
        dispatch
    ) => {
        try {
            if (code) {
                dispatch(push(`/referral/${code}`));
            }
        } catch (errors) {
            dispatch(handleError(errors));
        }
    };
};

const applyFilters = () => {
    fetchMyReferrals()
    return {
        type: REFERRALS_APPLY_FILTERS
    }
}

const fetchMyReferrals = (from = 0, size = 100, search, order = '', order_direction = '') => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: REFERRALS_IS_LOADING,
            payload: {
                isLoading: true
            }
        });
        try {
            const meId = getState().main.user.uid;
            const dates = getState().main.ui.view.referrals.dateOptions["PRO"];
            const dateCasesHandler = {
                [dates[0].value]: () => {
                    const thisWeek = moment().subtract(7, 'days')
                    const parsed = Date.parse(thisWeek._d);
                    return { from: parsed };
                },
                [dates[1].value]: () => {
                    const thisMonth = moment().subtract(30, 'days')
                    const parsed = Date.parse(thisMonth._d);
                    return { from: parsed };
                },
                [dates[2].value]: () => {
                    //last 90 days
                    const from = moment().subtract(90, 'days')
                    const parsed = Date.parse(from._d);
                    return { from: parsed };
                },
                [dates[3].value]: () => {
                    //last 1 year
                    const from = moment().subtract(365, 'days')
                    const parsed = Date.parse(from._d);
                    return { from: parsed };
                },
                [dates[4].value]: ({ from, to }) => {
                    //for between dates
                    const parsedFrom = Date.parse(from);
                    const nextDay = moment(to).add(1, "days");
                    const parsedTo = Date.parse(nextDay);
                    return { from: parsedFrom, to: parsedTo };
                }
            };
            let filters = getState().main.ui.view.referrals.filters
            if (filters.date_created) filters.date_created = dateCasesHandler[filters.date_created.value](filters.date_created)
            if (filters.status_change_date) filters.status_change_date = dateCasesHandler[filters.status_change_date.value](filters.status_change_date)
            const filtersFormatted = Utils.formatFilters(filters)
            if (filtersFormatted.profession) {
                filtersFormatted.profession = [filtersFormatted.profession.key]
            }
            if (filtersFormatted.status) {
                filtersFormatted.status = [filtersFormatted.status]
            }
            if (filtersFormatted.opportunity_type) {
                filtersFormatted.opportunity_type = [filtersFormatted.opportunity_type]
            }
            if (filtersFormatted.search && filtersFormatted.search.length > 0) {
                filtersFormatted.search = filtersFormatted.search.trim()
            } else {
                delete filtersFormatted.search
            }
            const response = await cloudfunctions.fetchMyReferrals(
                from,
                size,
                filtersFormatted,
                meId,
                order,
                order_direction
            );
            let referrals = []
            let count = 0
            let hasMore = true
            let awardedsCount = 0
            let pendingsCount = 0
            if (response && response.data) {
                count = response.data.total;
                awardedsCount = response.data.awarded_count
                pendingsCount = response.data.submitted_count
                const refe = response.data.data ? response.data.data : {}
                Object.keys(refe).forEach(referralKey => referrals.push({ ...refe[referralKey], id: referralKey }))
                hasMore = referrals.length >= size
            }
            dispatch({
                type: HIDE_REFERRALS_FILTERS
            })
            dispatch(fetchReferralsSuccess(count, referrals, hasMore, awardedsCount, pendingsCount))
            dispatch({
                type: REFERRALS_IS_LOADING,
                payload: {
                    isLoading: false
                }
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: REFERRALS_IS_LOADING,
                payload: {
                    isLoading: false
                }
            });
        }
    };
};

const fetchReferralsSuccess = (count, data, hasMore, awardedsCount, pendingsCount, page) => {
    return {
        type: REFERRALS_FETCH_SUCCESS,
        payload: {
            count: count,
            data: data,
            hasMore: hasMore,
            awardedsCount: awardedsCount,
            pendingsCount: pendingsCount,
            page: page
        }
    }
}

const deleteSentReferral = refId => {
    return {
        type: REFERRALS_DELETE_SENT,
        payload: {
            refId: refId
        }
    };
};

const addSentReferral = referral => {
    return {
        type: REFERRALS_ADD_SENT,
        payload: {
            referral: referral
        }
    };
};

const toogleSentReferralMessageModal = open => ({
    type: SEND_REFERRAL_TOOGLEMESSAGEMODAL,
    payload: {
        open: open
    }
});

const setSelectedTab = selectedTab => ({
    type: REFERRALS_SET_SELECTED_TAB,
    payload: {
        selectedTab: selectedTab
    }
});

const referralsFooterOpen = footerOpen => ({
    type: REFERRALS_FOOTER_OPEN,
    payload: {
        footerOpen: footerOpen
    }
});

const toggleOpportunityNoLongerAvailableModal = () => ({
    type: REFERRALS_NO_LONGER_AVAILABLE_TOGGLE_MODAL
});

const toggleNotAuhorizedModal = () => ({
    type: REFERRALS_NOT_AUTHORIZED_MODAL
});

const resetFilters = () => ({
    type: REFERRALS_RESET_FILTERS,
})

const updateFilters = (filters) => {
    return async (dispatch) => {
        dispatch({
            type: REFERRALS_UPDATE_FILTERS,
            payload: {
                filters
            }
        });
    };
};

const showFilters = () => {
    return async (dispatch) => {
        dispatch({
            type: SHOW_REFERRALS_FILTERS,
        });
    };
}

const hideFilters = () => {
    return async (dispatch) => {
        dispatch({
            type: HIDE_REFERRALS_FILTERS,
        });
    };
}

const upvoteCandidate = (referralId, candidateId) => {
    console.log("upvoteCandidate", referralId, candidateId);
};

const fetchMyOpsAnalyticsSummary = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch({
                type: FETCH_MYOPS_ANALYTICS_LOADING
            });
            const meId = getState().main.user.uid;
            const response = await cloudfunctions.fetchMyOpsAnalyticsSummary(
                meId
            );
            if (response && response.data) {
                dispatch({
                    type: FETCH_MYOPS_ANALYTICS_SUCCESS,
                    payload: {
                        data: response.data.data
                    }
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: FETCH_MYOPS_ANALYTICS_FAIL
            });
        }
    };
}

export {
    showFilters,
    hideFilters,
    updateFilters,
    resetFilters,
    applyFilters,
    referralsFooterOpen,
    fetchMyReferrals,
    setSelectedTab,
    subscribeToMyReferrals,
    toogleSentReferralMessageModal,
    sendReferral,
    deleteIncomingOpenReferral,
    fetchUser,
    cleanReferrals,
    redeemReferral,
    deleteSentReferral,
    addSentReferral,
    fetchIncomingOpenReferrals,
    cleanForm,
    toggleOpportunityNoLongerAvailableModal,
    toggleNotAuhorizedModal,
    getDraftReferrals,
    deleteDraftReferral,
    upvoteCandidate,
    fetchMyOpsAnalyticsSummary
};
