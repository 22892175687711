import React from "react";
import { Redirect, Route } from "react-router-dom";
import SplashScreen from "../../ui/presentation/atoms/SplashScreen";
import { connect } from "react-redux";

const PublicRoute = ({ component: Component, isLoggedIn, blockedUser, isLoading, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {

                if(blockedUser) return <SplashScreen />;
                 return (!isLoggedIn || isLoading) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/home",
                            state: { from: props.location }
                        }}
                    />
                ); 
            }}
        />
    );
};

const mapStateToProps = state => {
    return {
        blockedUser: state.main.user.ipInfo.blockedUser,
        isLoading: state.main.user.isLoading || (state.main.ui.view.signUp.loading || state.main.ui.view.signIn.loading)
    };
};
export default connect(mapStateToProps)(PublicRoute);
