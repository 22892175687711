import {
    SETTINGS_SELECTSECTION,
    SETTINGS_BLOCKEDCONTACTS_SUCCESS,
    SETTINGS_ISLOADING,
    SETTINGS_SETPHONE,
    SETTINGS_UNBLOCKCONTACT_SUCCESS,
    SETTINGS_SET_SUBSCRIPTIONSTEP,
    SETTINGS_SET_SUBSCRIPTIONOPTION,
    SETTINGS_CHANGE_PASSWORD_SUCCESS,
    SETTINGS_BILLINGDETAILS_SUCCESS,
    SETTINGS_INVITEEMAILS_SUCCESS,
    SETTINGS_UNBLOCK_CONTACTS_LOADING
} from "../../../../actions/constants";
const initialState = {
    errors: [],
    isLoading: false,
    selectedSection: "MAIN",
    blockedContacts: [],
    toVerifyPhone: null,
    subscriptionStep: 1,
    selectedSubscriptionOption: "FREE",
    changePasswordSuccess: null,
    billingDetailsSuccess: false,
    inviteEmailsSuccess: false,
    unblockContactsLoading: {}
};

function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case SETTINGS_BILLINGDETAILS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.billingDetailsSuccess = action.payload.success;
            return _state;
        }

        case SETTINGS_INVITEEMAILS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.inviteEmailsSuccess = action.payload.success;
            return _state;
        }
        case SETTINGS_CHANGE_PASSWORD_SUCCESS: {
            const _state = {
                ...state
            };
            _state.changePasswordSuccess = action.payload.changePasswordSuccess;
            return _state;
        }
        case SETTINGS_SETPHONE: {
            const _state = {
                ...state
            };
            _state.toVerifyPhone = action.payload.phone;
            return _state;
        }
        case SETTINGS_SELECTSECTION: {
            const _state = {
                ...state
            };

            _state.selectedSection = action.payload.selectedSection;
            return _state;
        }
        case SETTINGS_BLOCKEDCONTACTS_SUCCESS: {
            const _state = {
                ...state
            };

            _state.blockedContacts = [...action.payload.blockedContacts];
            return _state;
        }

        case SETTINGS_UNBLOCKCONTACT_SUCCESS: {
            const _state = {
                ...state
            };

            delete _state.unblockContactsLoading[action.payload.uid];

            _state.blockedContacts = _state.blockedContacts.filter(
                contact => contact.uid !== action.payload.uid
            );
            _state.isLoading = false;
            return _state;
        }
        case SETTINGS_UNBLOCK_CONTACTS_LOADING: {
            const _state = {
                ...state
            };
            _state.unblockContactsLoading = {
                ..._state.unblockContactsLoading,
                [action.payload.uid]: true
            };
            return _state;
        }
        case SETTINGS_SET_SUBSCRIPTIONSTEP: {
            const _state = {
                ...state
            };

            _state.subscriptionStep = action.payload.subscriptionStep;
            return _state;
        }
        case SETTINGS_SET_SUBSCRIPTIONOPTION: {
            const _state = {
                ...state
            };

            _state.selectedSubscriptionOption =
                action.payload.selectedSubscriptionOption;
            return _state;
        }
        default:
            return state;
    }
}

export default settingsReducer;
