export const theme = {
    caribbeanGreen: '#02DC83',
    greenMedium: '#1FC07F',
    shamRockGreen: '#00A15F',
    bottleGreen: '#396347',
    darkestGreen: '#28372d',
    greenPro: "#377A4C",
    blueLight: '#69ACFA',
    blueMedium: '#057AFF',
    blueDark: '#006DE8',
    golden: '#FFC700',
    greyLight2: '#E8ECF2',
    darkGray: '#c4c4c4',
    text: '#333333',
    clearGray: '#fafafa',
    greyLight5: '#EDEDED',
    timberwolf: '#D8D8D8',
    greyLight7: '#DDE3E8',
    taupeGray: "#929292",
    red: '#D0021B',
    redError: '#D0021B',
    lightCarminePink: '#E56767',
    mayaBlue: "#6DBEF6",
    newBlue: '#057AFF',
    darkBlue: '#1D55B6',
    mountainMeadow: '#1FC07F',
    primary: '#286dbe',
    primaryLight: '#e8ecf2',
    coolBlack: '#002954',
    secondary: '#50AD85',
    background: '#ffffff',
    disabled: '#929292',
    invalid: '#FBE3E4',
    placeHolder: '#929292',
    onGolden: '#7B6526',
    white: '#ffffff',
    onBackground: '#546173',
    onInvalid: '#D0021B',
    PENDING: '#1D55B6',
    SUBMITTED: '#1D55B6',
    CLAIMED: '#FFA057',
    UNCONFIRMED: '#FFA057',
    DECLINED: '#E56767',
    REJECTED: '#E56767',
    CANCELED: '#E56767',
    INVALID: '#E56767',
    ON_HOLD: '#E56767',
    EXPIRED: '#FFC700',
    DEAD: '#5DB8AB',
    ASSIGNED: '#5DB8AB',
    COMPLETED: '#5DB8AB',
    IN_PROGRESS: '#5DB8AB',
    CLOSED: '#5DB8AB',
    fontSizes: [8, 10, 12, 16, 20, 24, 28, 36],
    fontWeights: {
        bold: 'bold',
        medium: '500',
        light: 'normal'
    },
    fonts: {
        'XXXL': '48px',
        'XXL': '36px',
        'XL': '28px',
        'L': '24px',
        'M': '20px',
        'S': '18px',
        'XS': '16px',
        'XXS': '14px',
        'XXXS': '12px',
    },
    lineHeights: {
        'XXXL': '64px',
        'XXL': '56px',
        'XL': '48px',
        'L': '48px',
        'M': '40px',
        'S': '36px',
        'XS': '24px',
        'XXS': '20px',
        'XXXS': '20px',
    },
    spacing: {
        0: '0px',
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '20px',
        6: '24px',
        7: '28px',
        8: '32px',
        9: '36px',
        10: '40px',
        11: '44px',
        12: '48px',
        16: '64px',
        20: '80px',
        24: '96px',
        32: '128px',
        40: '160px',
        48: '192px',
        56: '224px',
        64: '256px'
    },
    breakpoints: ['368px', '769px', '1210px'],
    sizes: {
        TOPBAR_HEIGHT_DESKTOP: '4.75rem',
        TOPBAR_HEIGHT_MOBILE: '3.25rem',
    },
    
};

export const MEDIABREAKPOINTS = {
    tablet : 768
}

export const getThemeColor = (props) => {
    if (props.disabled) {
        return theme.disabled;
    }
    return (
        props.golden ? theme.golden :
        props.primary ? theme.primary :
        props.secondary ? theme.secondary :
        props.disabled ? theme.disabled :
        props.invalid ? theme.invalid :
        theme.greenMedium);
};


export const getThemeOnColor = (props) => {
    if (props.disabled) {
        return theme.white;
    }
    return (
        props.golden ? theme.onGolden :
        props.primary ? theme.white :
        props.secondary ? theme.white :
        props.disabled ? theme.white :
        props.invalid ? theme.onInvalid :
        '#ffffff'
    );
}
//TODO: use the theme throughout the application