import { LEADERBOARD_DATE_RANGE_OPTIONS } from "../../../../../assets/strings";
import {
    FETCH_LEADERBOARD_ISLOADING,
    FETCH_LEADERBOARD_SUCCESS,
    FETCH_LEADERBOARD_FAIL,
    LEADERBOARD_UPDATE_FILTERS,
    LEADERBOARD_RESET_FILTERS,
    LEADERBOARD_SET_PAGE,
    FETCH_USER_LEADERBOARD_STATS_LOADING,
    FETCH_USER_LEADERBOARD_STATS_SUCCESS,
    FETCH_USER_LEADERBOARD_STATS_FAIL,
} from "../../../../actions/constants";


const initialState = {
    isLoading: false,
    errors: [],
    data: [],
    page: 0,
    filters: {
        from: 1,
        size: 20,
        date_range: LEADERBOARD_DATE_RANGE_OPTIONS.THIS_YEAR,
        search: ""
    },
    hasMore: true,
    last_update: null,
    userLeaderboardStats: {
        isLoading: false,
        errors: [],
        data: {}
    }
};

function leaderboardReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_LEADERBOARD_ISLOADING: {
            const _state = {
                ...state
            };
            _state.isLoading = action.payload.isLoading;
            return _state;
        }
        case FETCH_LEADERBOARD_FAIL: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            _state.errors = [action.errors];
            return _state;
        }
        case FETCH_LEADERBOARD_SUCCESS: {
            const _state = {
                ...state
            };
            _state.isLoading = false;
            if (_state.filters.from === 1) {
                // If it is the first query
                _state.data = action.payload.data;
            } else {
                // If it is a pagination query
                _state.data = _state.data.concat(action.payload.data);
            }
            _state.hasMore = action.payload.hasMore;
            _state.last_update = action.payload.last_update;
            return _state;
        }
        case LEADERBOARD_UPDATE_FILTERS: {
            const filters = {
                ...state.filters,
                ...action.payload.filters
                //[action.payload.key]: action.payload.value
            };
            const _state = {
                ...state,
                filters
            };

            return _state;
        }
        case LEADERBOARD_RESET_FILTERS: {
            const { filters } = initialState;
            const _state = {
                ...state,
                filters
            };

            return _state;
        }
        case FETCH_USER_LEADERBOARD_STATS_LOADING: {
            const _state = {
                ...state
            };
            _state.userLeaderboardStats.isLoading = true;
            return _state;
        }
        case FETCH_USER_LEADERBOARD_STATS_SUCCESS: {
            const _state = {
                ...state
            };
            _state.userLeaderboardStats.isLoading = false;
            _state.userLeaderboardStats.data = action.payload;
            return _state;
        }
        case FETCH_USER_LEADERBOARD_STATS_FAIL: {
            const _state = {
                ...state
            };
            _state.userLeaderboardStats.isLoading = false;
            _state.userLeaderboardStats.errors = action.payload;
            return _state;
        }
        case LEADERBOARD_SET_PAGE: {
            const _state = {
                ...state
            };
            _state.page = action.payload.page;
            return _state;
        }
        default:
            return state;
    }
}

export default leaderboardReducer;