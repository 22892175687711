import { useState } from "react";

const useForm = () => {
    const [formValues, setFormValues] = useState({});
    const [originalValues, setOriginalValues] = useState({});
    const [touched, setTouched] = useState(false);

    /** Reset the values to the original state provided. */
    const reset = () => {
        setFormValues(originalValues);
        setTouched(false);
    };

    /** Initialize the state of the values */
    const initialize = (values = {}) => {
        setTouched(false);
        setOriginalValues(values);
        setFormValues(values);
    };

    /** Updates a field in the state */
    const update = ({ key, value }) => {
        setTouched(true);
        setFormValues((curr) => ({ ...curr, [key]: value }));
    };
    
    return {
        formValues,
        touched,
        reset,
        initialize,
        update
    };
};

export default useForm;
