import React from 'react'
import { Flex } from 'rebass'
import { theme } from '../../../../../../../../theme'
import { Icon } from '../../../../../atoms/shared/Icon'
import { CandidateCardContainer, CandidateAddress, CandidateAvatar, CandidateInfo, CandidateName, Badge, CandidateCard, CandidatesView, Container, Tab } from './CandidatePreview.styles'
import { ContactAvatar } from '../../../../../atoms/shared/ContactAvatar'


export const CandidateCardView = ({ avatar, firstName, lastName, address, email, mobile_phone, phone, privateEmail, privatePhone }) => {

    const unableMessage = privateEmail && privatePhone && "Contact info is unable to be shared due to this Member's settings."
    const doNotShowBothEmailAndPhone = privateEmail && privatePhone


    return (
        <CandidateCard>
            <CandidateAvatar>
                {avatar ?
                    <img src={avatar} alt={`${firstName} ${lastName}`} />
                    : (
                        <ContactAvatar name={firstName} lastName={lastName} />
                    )}
            </CandidateAvatar>
            <CandidateInfo>
                <CandidateName>{`${firstName} ${lastName}`}</CandidateName>
                <Flex alignItems='center' justifyContent='flex-start' style={{ gap: 16 }}>
                    {address &&
                        <>
                            <Icon icon="location" width='20px' height='20px' color={theme.blueMedium} style={{ minWidth: 20 }} />
                            <CandidateAddress>
                                {address}
                            </CandidateAddress>
                        </>

                    }
                    {doNotShowBothEmailAndPhone ?

                        <>
                            <CandidateAddress>
                                Contact info is unable to be shared due to this Member's settings.
                            </CandidateAddress>
                        </>



                        :
                        <>
                        </>



                    }

                    {privateEmail && doNotShowBothEmailAndPhone === false ?

                        <>
                            <CandidateAddress>
                                Email info is unable to be shared due to this Member's settings.

                            </CandidateAddress>
                        </>
                        :
                        (!privateEmail && email) ?
                            <>
                                <Icon icon="mail" width='20px' height='20px' color={theme.blueMedium} style={{ minWidth: 20 }} />
                                <CandidateAddress>
                                    {email}
                                </CandidateAddress>
                            </>
                            :
                            <></>

                    }
                    {privatePhone && doNotShowBothEmailAndPhone === false ?

                        <>
                            <CandidateAddress>
                                Phone info is unable to be shared due to this Member's settings.


                            </CandidateAddress>
                        </>
                        :
                        (!privatePhone && phone) ?
                            <>
                                <Icon icon="phone" width='20px' height='20px' color={theme.blueMedium} style={{ minWidth: 20 }} />
                                <CandidateAddress>
                                    {phone}
                                </CandidateAddress>
                            </>
                            :

                            <></>

                    }




                </Flex>
                {mobile_phone &&
                    <Flex>
                        <>
                            <Icon icon="phone" width='20px' height='20px' color={theme.blueMedium} style={{ minWidth: 20 }} />
                            <CandidateAddress>
                                {mobile_phone}
                            </CandidateAddress>

                        </>

                    </Flex>

                }


            </CandidateInfo>
        </CandidateCard>
    )
}

export const CandidateTabs = () => {
    const path = window.location.pathname;
    return (
        <Container>
            <Tab>
                {(path === '/create-recommendation') ? 'Recommendations' : 'Parties'}
            </Tab>
        </Container>
    )
}
