import {
    SIGN_UP,
    SIGN_UP_SUCC,
    SIGN_UP_FAIL,
    SIGN_IN,
    SIGN_OUT,
    SIGN_IN_SUCC,
    SIGN_IN_FAIL,
    REHYDRATE_FIREBASE_AUTH,
    EDIT_PROFILE_SAVE,
    EDIT_PROFILE_SAVE_SUCC,
    EDIT_PROFILE_SAVE_FAIL,
    FETCH_PROFILE,
    FETCH_PROFILE_SUCC,
    FETCH_PROFILE_FAIL,
    FETCH_USER_LIST,
    FETCH_USER_LIST_SUCC,
    FETCH_USER_LIST_FAIL,
    USER_STORE_SUCC,
    USER_STORE_FAIL,
    EDITPROFILE_UPLOADAVATARSUCCESS,
    REHYDRATE_USER_IS_LOADING,
    CLEAN_USER,
    IS_SOCIAL_LOGIN_FLAG_ON,
    SUBSCRIBED_TO_FIREABSE,
    USER_FOCUS_SUCCESS,
    USER_SERVICEAREAS_SUCCESS,
    USER_HONORS_SUCCESS,
    USER_LICENCES_SUCCESS,
    USER_SOCIAL_SUCCESS,
    USER_COMMUNITY_SUCCESS,
    EDITPROFILE_ISLOADING,
    PROFILE_COUNTERS_LOADING,
    PROFILE_COUNTERS_SUCC,
    SET_VERIFIED_PHONE,
    USER_IPINFO_SUCCESS,
    GLOBAL_ADD_USER,
    USER_SETSETTING,
    PROFILE_CLEAR,
    USER_FETCH_SUBSCRIPTION_SUCCESS,
    USER_FETCH_CREDITS_SUCCESS,
    USER_FETCH_CREDITS_CHANGED_SUCCESS,
    FETCH_PROFILE_SET,
    USER_FETCHINVITES_SUCCESS,
    USER_INVITES_DISMISS_SUCCESS,
    USER_INVITES_ACCOUNTBALANCE_SUCCESS,
    USER_INVITES_EARNING_HISTORY_SUCCESS,
    USER_INVITES_INVITESCOUNT_SUCCESS,
    USER_EXTRAINFO_SUCCESS,
    VALIDATE_PHONE,
    VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE,
    VALIDATE_PHONE_FAIL,
    VALIDATE_PHONE_CODE_FAIL,
    VALIDATE_PHONE_CODE_SUCC,
    VALIDATE_PHONE_SUCC,
    USER_SET_ONBOARDING,
    USER_GOOD_OPS_FOR_ME_SUCCESS,
    USER_EXPERIENCE_SUCCESS,
    CHECK_USERNAME_IS_LOADING,
    WRONG_PASSWORD
} from "../../constants";
import { batch } from "react-redux";
import { push } from "connected-react-router";
import axios from "axios";
import { handleError } from "../modal";
import { cleanForm } from "../sendReferral";
import { cleanCommunity } from "../community";
import config from "../../../../config";
import { rehydrateProfileOnStore, setServices } from "../shared";
import { profileEditing } from "../editProfile";
import { cleanReferrals } from "../referrals";
import { toogleMobileSideMenu } from "../navbar";
import Utils from "../../../../libs/Utils";
import { PROFILE_EDITING_CARD_NAMES } from "../../../../assets/strings";
import { sendVerificationEmail, settingsIsLoading } from "../settings";
import UserBussiness from "../../../../business/user";
import ReferralBusiness from "../../../../business/referral";
import { cleanChat } from "../chat";
import moment from "moment";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";
//import { hasToxicity } from '../../../../libs/TensorFlow'

function setVerifiedPhone(verified_phone) {
    return {
        type: SET_VERIFIED_PHONE,
        payload: verified_phone
    };
}

const fetchTotalInvitesSuccess = total => ({
    type: USER_INVITES_INVITESCOUNT_SUCCESS,
    payload: {
        totalInvites: total
    }
});

const invitesAccountBalanceSuccess = accountBalance => ({
    type: USER_INVITES_ACCOUNTBALANCE_SUCCESS,
    payload: {
        invitesAccountBalance: accountBalance
    }
});
const earningHistorySuccess = earningHistory => ({
    type: USER_INVITES_EARNING_HISTORY_SUCCESS,
    payload: {
        earningHistory
    }
});

export const dismissInviteSuccess = (uid, dismissed) => ({
    type: USER_INVITES_DISMISS_SUCCESS,
    payload: {
        uid: uid
    }
});

export const fetchInvitesSuccess = invitedPeople => ({
    type: USER_FETCHINVITES_SUCCESS,
    payload: {
        invitedPeople: invitedPeople
    }
});

const uploadAvatarSuccess = avatarUrl => ({
    type: EDITPROFILE_UPLOADAVATARSUCCESS,
    payload: {
        avatarUrl: avatarUrl
    }
});

const subscribedToFirebase = subscribedToFirebase => ({
    type: SUBSCRIBED_TO_FIREABSE,
    payload: {
        subscribedToFirebase: subscribedToFirebase
    }
});

const editProfileIsLoading = isLoading => ({
    type: EDITPROFILE_ISLOADING,
    payload: {
        isLoading: isLoading
    }
});

const addGlobaluser = (uid, user) => ({
    type: GLOBAL_ADD_USER,
    payload: {
        uid: uid,
        user: user
    }
});

const setUserSetting = (setting, section) => ({
    type: USER_SETSETTING,
    payload: {
        setting: setting,
        section: section
    }
});
const setOnboarding = (view, completed) => ({
    type: USER_SET_ONBOARDING,
    payload: {
        [view]: completed
    }
});
const cleanProfile = () => ({
    type: PROFILE_CLEAR
});

const verifyUserEmailVerificated = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        const user = getState().main.user;
        let verified_email = user.profile ? user.profile.verified_email : false;
        if (!verified_email)
            verified_email = await firebase.checkEmailVerified(uid);
        if (verified_email !== user.profile.verified_email)
            dispatch({
                type: USER_STORE_SUCC,
                payload: {
                    verified_email
                }
            });
    };
};

function signUp(email, password, invitedByQRProfile) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: SIGN_UP,
            logs: {
                email
            }
        });
        let uid;
        try {
            if (email && email.toString().length > 0 && password) {
                const response = await firebase.createUserWithEmailAndPassword(
                    email,
                    password
                );
                const token = await firebase.getIdToken();
                uid = response && response.user && response.user.uid;
                if (!uid)
                    throw new Error("We have a problem, please retry later");

                await dispatch(
                    newUserProcess(
                        "",
                        "",
                        email,
                        token,
                        uid,
                        invitedByQRProfile
                    )
                );

                dispatch(push("/fill-profile"));
            } else {
                throw Error("Incomplete necesary data to create a user");
            }
        } catch (errors) {
            console.error(errors);
            cloudfunctions.revokeToken(uid);
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            dispatch(handleError(errors));
            dispatch({
                type: USER_STORE_FAIL
            });
            return dispatch({
                type: SIGN_UP_FAIL,
                errors
            });
        }
    };
}

function socialSignUp(invited_by, providerId) {
    return async (
        dispatch,
        getState,
        { firebase, cloudfunctions, LocalStorage }
    ) => {
        dispatch({
            type: SIGN_UP,
            logs: {
                provider: providerId
            }
        });
        try {
            if (invited_by) localStorage.setItem("invited_by", invited_by);
            LocalStorage.setComeFromGoogle(true);
            let provider;
            if (providerId === "google.com")
                provider = firebase.getGoogleAuthProvider();
            else provider = firebase.AppleAuthProvider();
            // if (isMacintosh()) {
            //     await firebase.signInWithPopup(provider);
            // } else {
                await firebase.signInWithRedirect(provider);
            // }
        } catch (errors) {
            cloudfunctions.revokeToken();
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            dispatch(handleError(errors));
            dispatch({
                type: USER_STORE_FAIL
            });
            return dispatch({
                type: SIGN_UP_FAIL,
                errors
            });
        }
    };
}

const newUserProcess = (
    name,
    lastname,
    email,
    token,
    uid,
    invitedByQRProfile = null,
    avatar = "",
    emailVerified = false
) => {
    return async (
        dispatch,
        getState,
        { firebase, cloudfunctions, LocalStorage }
    ) => {
        console.log("newUserProcess", name, lastname, email, token, uid)
        await cloudfunctions.createUser(
            name,
            lastname,
            email,
            token,
            uid,
            avatar,
            emailVerified
        );

        // const invited = invitedByQRProfile
        //     ? invitedByQRProfile
        //     : localStorage.getItem("invited_by");

        // if (invited) {
        //     await cloudfunctions.gamificationLogAppInvite(invited);
        //     localStorage.removeItem("invited_by");
        // }

        await dispatch(rehydrateProfileOnStore(uid, token, null, true, { invited_by: invitedByQRProfile }));

        if (
            ["PRODUCTION", "STAGING"].includes(
                process.env.REACT_APP_ENVIRONMENT
            )
        ) {
            const analytics = firebase.googleAnalytics();
            const analyticsEventsName = firebase.getAnalyticsEventNames();
            const eventName =
                analytics && analyticsEventsName && analyticsEventsName.SIGN_UP; //analytics.EventName && analytics.EventName.SIGN_UP
            if (eventName) {
                analytics.logEvent(eventName, { id: uid, source: "PWA" });
            }
        }

        dispatch({
            type: SIGN_UP_SUCC
        });
        return;
    };
};
function setSocialSignUpCallback() {
    return async (
        dispatch,
        getState,
        { firebase, cloudfunctions, LocalStorage }
    ) => {
        /* Google sign in handling */
        try {
            dispatch({
                type: SIGN_IN
            });
            firebase
                .getRedirectResult()
                .then(async function(result) {
                    const user = result && result.user;
                    const profile =
                        result &&
                        result._tokenResponse;

                    if (user) {
                        const uid = user.uid;
                        if (!uid)
                            throw new Error(
                                "We have a problem, please retry later"
                            );
                        const firebaseUser = await firebase.fetchProfileById(uid);

                        const isNewUser =
                            (result._tokenResponse &&
                                result._tokenResponse.isNewUser) ||
                            !firebaseUser;

                        const credential = OAuthProvider.credentialFromResult(
                            result
                        );
                        await firebase.signInWithCredential(credential);

                        dispatch({
                            type: IS_SOCIAL_LOGIN_FLAG_ON,
                            payload: {
                                idToken: credential.idToken
                            }
                        });

                        LocalStorage.setComeFromGoogle(true);
                        const token = await firebase.getIdToken();

                        cloudfunctions.appendToken(token, uid);

                        if (isNewUser) {
                            let name = profile.given_name
                                ? profile.given_name
                                : profile.firstName
                                ? profile.firstName
                                : /* : user.displayName
                                        ? user.displayName */
                                "";
                            let lastname = profile.lastName
                                ? profile.lastName
                                : "";
                            let email = profile.email ? profile.email : "";
                            const avatar = user.photoURL ? user.photoURL : "";
                            const invitedByQRProfile = localStorage.getItem(
                                "invited_by"
                            );
                            await dispatch(
                                newUserProcess(
                                    name,
                                    lastname,
                                    email,
                                    token,
                                    uid,
                                    invitedByQRProfile,
                                    avatar,
                                    user.emailVerified
                                )
                            );

                            LocalStorage.setComeFromGoogle(true);

                            return dispatch(push("/fill-profile"));
                        } else {
                            const next = JSON.parse(
                                localStorage.getItem("comesFromUrl")
                            );
                            localStorage.removeItem("comesFromUrl");

                            await dispatch(
                                rehydrateProfileOnStore(
                                    uid,
                                    token,
                                    next ? next : "/home",
                                    true
                                )
                            );

                            dispatch({
                                type: SIGN_IN_SUCC
                            });
                        }
                    } else {
                        dispatch({
                            type: SIGN_IN,
                            payload: {
                                isLoading: false
                            }
                        });
                    }
                })
                .catch(function(error) {
                    console.log('WRONG_PASSWORD',error)
                    LocalStorage.setComeFromGoogle(false);
                    dispatch({
                        type: WRONG_PASSWORD
                    })
                    dispatch({
                        type: SIGN_IN_FAIL
                    });
                    dispatch({
                        type: REHYDRATE_FIREBASE_AUTH,
                        payload: false
                    });
                });

            firebase.isLoggedIn(user => {
                if (user) {
                    dispatch({
                        type: REHYDRATE_FIREBASE_AUTH,
                        payload: true
                    });
                } else {
                    LocalStorage.setComeFromGoogle(false);
                    dispatch({
                        type: REHYDRATE_FIREBASE_AUTH,
                        payload: false
                    });
                    dispatch({
                        type: REHYDRATE_USER_IS_LOADING,
                        payload: {
                            isLoading: false
                        }
                    });
                    // dispatch(signOut());
                }
            });
        } catch (error) {
            dispatch(handleError(error));
            console.log("signUpGoogle error", error);
            LocalStorage.setComeFromGoogle(false);
            dispatch({
                type: SIGN_IN_FAIL
            });
            dispatch(signOut());
        }
    };
}

/* function getHiddenToken(tokenKey) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            const token = await firebase.getHiddenToken(tokenKey)
            return token
        } catch (error) {
            return null
        }

    }
} */

function logLastLogin() {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            await cloudfunctions.logLastLogin();
        } catch (error) {
            dispatch(handleError(error));
            console.error(error);
        }
    };
}

function rehydrateUser(hiddenTokenKey) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        let uid;
        let stayLoggedIn;
        let token;
        try {
            dispatch({
                type: REHYDRATE_USER_IS_LOADING,
                payload: {
                    isLoading: true
                }
            });

            if (hiddenTokenKey) {
                const newToken = await firebase.getHiddenToken(hiddenTokenKey);

                const exchangedToken = await firebase.exchangeRefreshToken(
                    newToken
                );

                uid = exchangedToken.data && exchangedToken.data.user_id;
                stayLoggedIn = true;
                token = exchangedToken.data && exchangedToken.data.id_token;

                if (token) firebase.deleteHiddenToken(hiddenTokenKey);
            } else {
                const currentUser = localStorage.getItem(
                    config.localStorage.USER_PROFILE
                );
                let payload = JSON.parse(currentUser);
                token = payload && payload.token ? payload.token : null;
                uid = payload && payload.uid ? payload.uid : null;

                stayLoggedIn = JSON.parse(
                    localStorage.getItem(config.localStorage.IS_LOGGED_IN)
                );
            }

            await dispatch(
                rehydrateProfileOnStore(uid, token, undefined, stayLoggedIn)
            );
            dispatch({
                type: REHYDRATE_USER_IS_LOADING,
                payload: {
                    isLoading: false
                }
            });
        } catch (errors) {
            dispatch(handleError(errors));
            cloudfunctions.revokeToken(uid);
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            localStorage.setItem(config.localStorage.IS_LOGGED_IN, "false");
            dispatch({
                type: REHYDRATE_USER_IS_LOADING,
                payload: {
                    isLoading: false
                }
            });
        }
    };
}

function signIn(email, password, next = "/home") {
    return async (
        dispatch,
        getState,
        { firebase, cloudfunctions, LocalStorage }
    ) => {
        dispatch({
            type: SIGN_IN
        });
        let uid;
        try {
            const response = await firebase.signInWithEmailAndPassword(
                email,
                password
            );
            const token = await firebase.getIdToken();
            uid = response.user.uid;

            await dispatch(rehydrateProfileOnStore(uid, token, next, true));

            dispatch({
                type: SIGN_IN_SUCC
            });
            return dispatch(push(next));
        } catch (errors) {
            console.log('WRONG_PASSWORD', errors)
            cloudfunctions.revokeToken(uid);
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            localStorage.setItem(config.localStorage.IS_LOGGED_IN, "false");
            dispatch(handleError(errors));
            dispatch({
                type: WRONG_PASSWORD,
                
            })
            return dispatch({
                type: SIGN_IN_FAIL,
                errors
            });
        }
    };
}

function cleanUser() {
    return {
        type: CLEAN_USER
    };
}

function signOut() {
    return async (
        dispatch,
        getState,
        { firebase, cloudfunctions, LocalStorage }
    ) => {
        let uid;
        try {
            dispatch({
                type: SIGN_OUT
            });
            await cloudfunctions.logLastLogin();
            uid = getState().main.user.uid;
            localStorage.setItem(config.localStorage.IS_LOGGED_IN, "false");
            cloudfunctions.revokeToken(uid);
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            LocalStorage.setComeFromGoogle(false);

            batch(
                () => {
                    dispatch(cleanUser());
                    dispatch(cleanForm());
                    dispatch(cleanProfile());
                    dispatch(cleanCommunity());
                    dispatch(removeFirebaseSubscriptions(uid));
                    dispatch(cleanReferrals());
                    dispatch(cleanChat());
                    dispatch(toogleMobileSideMenu(false));
                    dispatch(
                        setServices({
                            subscribedToFirebase: false,
                            subscribed: false,
                            geolocationRealtimeSubscribed: false,
                            geolocationProfileSubscribed: false,
                            getConnectedBrowserLoaded: false,
                            getConnectedLoaded: false
                        })
                    );
                },
                () => {}
            );
            setTimeout(() => {
                localStorage.removeItem("comesFromUrl");
            }, 2000);
            // dispatch(push(`/sign-in`));
        } catch (errors) {
            cloudfunctions.revokeToken(uid);
            dispatch(handleError(errors));
            localStorage.removeItem(config.localStorage.USER_PROFILE);
            localStorage.setItem(config.localStorage.IS_LOGGED_IN, "false");
            return dispatch({
                type: SIGN_IN_FAIL,
                errors
            });
        }
    };
}

const removeFirebaseSubscriptions = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            firebase.removeSubscriptions(uid);
        } catch (error) {
            console.error(error);
        }
    };
};

function updateProfileOpsCounter({ sent, received }) {
    return {
        type: PROFILE_COUNTERS_SUCC,
        payload: {
            sent,
            received
        }
    };
}

function countersLoading() {
    return {
        type: PROFILE_COUNTERS_LOADING
    };
}

//MOVE THIS TO CF AND FETCH JUST THE PUBLIC DATA
function fetchProfile(userName) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        if (userName) {
            dispatch({
                type: FETCH_PROFILE
            });
            dispatch(countersLoading());
            try {
                // const data = await firebase.fetchProfile(userName);
                let data;
                try {
                    data = await cloudfunctions.getUser(userName);
                } catch (error) {
                    // In case parameter is an uid and not a username
                    data = await cloudfunctions.getUserByUid(userName);
                }
                let inviter;
                data = data && data.data;

                const uid = data && data.uid;
                const meId = getState().main.user.uid;
                const myContacts =
                    getState().main.user && getState().main.user.profile
                        ? getState().main.user.profile.contacts
                        : null;
                const hisContacts =
                    data && data.contacts ? data.contacts : null;
                const itsMe = meId === uid;

                if (data.invited_by) {
                    try {
                        const {
                            name,
                            lastname
                        } = await firebase.fetchProfileById(data.invited_by);
                        inviter = name + " " + lastname;
                    } catch (error) {
                        console.error(
                            "Error while fetching user's inviter",
                            error
                        );
                    }
                }

                let payload;
                if (!itsMe) {
                    let weAreFriends = false;
                    try {
                        weAreFriends =
                            myContacts &&
                            Object.keys(myContacts).indexOf(uid) !== -1;
                    } catch (error) {}
                    payload = Object.assign({}, data, {
                        uid,
                        itsMe,
                        commonContacts: commonContacts(myContacts, hisContacts),
                        weAreFriends
                    });
                } else {
                    payload = Object.assign({}, data, {
                        uid,
                        itsMe
                    });
                }
                if (inviter) {
                    payload = Object.assign({}, payload, {
                        inviter
                    });
                }
                // console.log("payload", payload);
                const {
                    data: { sent, received }
                } =
                    !itsMe && payload.relationStatus.includes("BLOCKED")
                        ? { data:  { sent: 0, received: 0 } }
                        : await cloudfunctions.fetchProfileOpsCounters(uid);

                // Updating profile referral counters
                dispatch(updateProfileOpsCounter({ sent, received }));

                return dispatch({
                    type: FETCH_PROFILE_SET,
                    payload
                });
            } catch (errors) {
                console.error("errors", errors);
                dispatch(handleError(errors));
                return dispatch({
                    type: FETCH_PROFILE_FAIL,
                    errors
                });
            }
        } else {
            dispatch(handleError({ message: "Not Uid" }));
            return dispatch({
                type: FETCH_PROFILE_FAIL,
                errors: { message: "Not Uid" }
            });
        }
    };
}

const fetchProfileSubscription = uid => {
    return async (dispatch, getState, { firebase }) => {
        if (uid) {
            try {
                const data = await firebase.fetchProfileSubscription(uid);

                return dispatch({
                    type: USER_FETCH_SUBSCRIPTION_SUCCESS,
                    payload: {
                        subscription: data
                    }
                });
            } catch (errors) {
                dispatch(handleError(errors));
                return dispatch({
                    type: FETCH_PROFILE_FAIL,
                    errors
                });
            }
        } else {
            dispatch(handleError({ message: "Not Uid" }));
            return dispatch({
                type: FETCH_PROFILE_FAIL,
                errors: { message: "Not Uid" }
            });
        }
    };
};

const fetchProfileCredits = uid => {
    return async (dispatch, getState, { firebase }) => {
        if (uid) {
            try {
                const data = await firebase.fetchProfileCredits(uid);

                return dispatch({
                    type: USER_FETCH_CREDITS_SUCCESS,
                    payload: {
                        credits: data
                    }
                });
            } catch (errors) {
                dispatch(handleError(errors));
                return dispatch({
                    type: FETCH_PROFILE_FAIL,
                    errors
                });
            }
        } else {
            dispatch(handleError({ message: "Not Uid" }));
            return dispatch({
                type: FETCH_PROFILE_FAIL,
                errors: { message: "Not Uid" }
            });
        }
    };
};

const commonContacts = (myContacts, hisContacts) => {
    try {
        const mykeys = Object.keys(myContacts);
        const hisKeys = Object.keys(hisContacts);
        return mykeys.filter(value => hisKeys.includes(value));
    } catch (error) {
        return [];
    }
};

function editProfile(
    {
        uid,
        name,
        lastname,
        phone,
        headLine,
        location,
        address,
        company_info,
        avatar,
        zipcode,
        state,
        locationAddress,
        verified_phone,
        type,
        userName,
        other_phone
    },
    cb
) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");

            const actualUser = getState().main.user.profile;
            const actualPhone = actualUser.phone;
            const phoneUpdated = actualPhone !== phone;

            //   let toxic = await hasToxicity([headLine, userName, name, lastname])
            //    if (toxic) throw new Error("Toxicity detected")
            let newProfile = {
                uid,
                name,
                lastname,
                phone,
                headLine,
                location,
                address,
                company_info,
                avatar,
                zipcode,
                state,
                locationAddress,
                type,
                userName,
                other_phone
            };
            //saving not verified phone as default
            if (phoneUpdated) newProfile.verified_phone = false;
            await cloudfunctions.updateUser(
                PROFILE_EDITING_CARD_NAMES.BASIC_INFO,
                newProfile
            );

            let payload = {
                uid,
                phone,
                name,
                lastname,
                headLine,
                location,
                address,
                company_info,
                avatar,
                type,
                userName,
                other_phone
            };

            if (phoneUpdated) payload.verified_phone = false;
            dispatch({
                type: EDIT_PROFILE_SAVE_SUCC
            });

            dispatch({
                type: USER_STORE_SUCC,
                payload
            });

            payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                ...payload
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            const openPhoneModal =
                (phone && actualPhone && phoneUpdated) || !verified_phone;
            // TODO: Uncomment Verify Phone Number when Twilio was working
            dispatch(showPhoneValidationModal(true));
            if (cb) cb(openPhoneModal);
            dispatch(profileEditing(""));
        } catch (errors) {
            console.error(errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
}

const updateUser = (userData, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            const uid = getState().main.user.uid;
            if (!uid) throw new Error("We have a problem, please retry later");
            const cleanData = Utils.cleanObject(userData);
            /* UPDATING PROFILE */
            await firebase.updateProfileData(uid, cleanData);

            dispatch({
                type: USER_STORE_SUCC,
                payload: cleanData
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload: cleanData
            });

            dispatch({
                type: EDIT_PROFILE_SAVE_SUCC
            });

            dispatch(editProfileIsLoading(false));
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const editProfileRealStateFocus = (uid, focus, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;
            let normalizedFocus = {};
            for (const key in focus) {
                normalizedFocus[key] = firebase.arrayToFirebaseObject(
                    focus[key]
                );
            }
            /* UPDATING PROFILE */
            await firebase.updateUserProfileRealStateFocus(
                uid,
                normalizedFocus
            );

            dispatch({
                type: USER_FOCUS_SUCCESS,
                payload: {
                    focus: normalizedFocus
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                focus: normalizedFocus
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));

            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const editProfileServiceAreas = (uid, serviceAreas, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;
            let normalizedAreas = {};

            normalizedAreas = firebase.arrayToFirebaseObject(serviceAreas);

            await firebase.updateUserProfileServiceAreas(uid, normalizedAreas);

            dispatch({
                type: USER_SERVICEAREAS_SUCCESS,
                payload: {
                    service_areas: normalizedAreas
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                service_areas: normalizedAreas
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

// const editCommunityInvolvement = (uid, community = {}, cb) => {
//     return async (dispatch, getState, { firebase, cloudfunctions }) => {
//         dispatch({
//             type: EDIT_PROFILE_SAVE
//         });
//         try {
//             if (!uid) throw new Error("We have a problem, please retry later");
//             const actualUser = getState().main.ui.view.profile.user.data;

//             let normalizedCommunity = [];
//             Object.keys(community).forEach(key => {
//                 normalizedCommunity.push(community[key]);
//             });

//             normalizedCommunity = firebase.arrayToFirebaseObject(
//                 normalizedCommunity
//             );

//             await firebase.updateUserProfileCommunity(uid, normalizedCommunity);

//             dispatch({
//                 type: USER_COMMUNITY_SUCCESS,
//                 payload: {
//                     other_titles: normalizedCommunity
//                 }
//             });

//             const payload = Object.assign({}, actualUser, {
//                 uid,
//                 itsMe: true,
//                 other_titles: normalizedCommunity
//             });

//             dispatch({
//                 type: FETCH_PROFILE_SUCC,
//                 payload
//             });

//             dispatch(editProfileIsLoading(false));

//             if (cb) cb();
//         } catch (errors) {
//             console.error("editCommunityInvolvement", errors);

//             const parsedErrors = cloudfunctions.parseRequestError(errors);
//             dispatch(handleError(errors));
//             return dispatch({
//                 type: EDIT_PROFILE_SAVE_FAIL,
//                 payload: {
//                     errors: parsedErrors
//                 }
//             });
//         }
//     };
// };

const editCommunityInvolvement = (uid, community, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newCommunity = {};
            for (const item of community) {
                const { id, title, description, isNew, index } = item;
                if (isNew) {
                    // const firebaseKey = await firebase.database
                    //     .ref(`users/${uid}/community_involvement`)
                    //     .push().key;
                    const firebaseKey = await firebase.generateRandomFirebaseKey(
                        `users/${uid}/community_involvement`
                    );
                    newCommunity[firebaseKey] = { title, description, index };
                } else {
                    newCommunity[id] = { title, description, index };
                }
            }

            dispatch({
                type: USER_COMMUNITY_SUCCESS,
                payload: {
                    community_involvement: newCommunity
                }
            });
            // console.log('newCommunity', newCommunity)
            const profileSection = "COMMUNITY_INVOLVEMENT";
            await cloudfunctions.updateUser2(profileSection, {
                community_involvement: newCommunity
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                community_involvement: newCommunity
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const updateCommunityInvolvementIndex = (uid, community) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newCommunity = community.reduce(
                (acc, current) => ({ ...acc, [current.id]: current }),
                {}
            );

            for (const item of community) {
                await firebase.updateUserProfileCommunityIndex(uid, item);
            }

            dispatch({
                type: USER_COMMUNITY_SUCCESS,
                payload: {
                    community_involvement: newCommunity
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                community_involvement: newCommunity
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const editProfileAbout = (uid, about = "", cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            await firebase.updateUserProfileAbout(uid, about);

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                bio: about
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));

            if (cb) cb();
        } catch (errors) {
            console.error("editProfileAbout", errors);

            const parsedErrors = cloudfunctions.parseRequestError(errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                payload: {
                    errors: parsedErrors
                }
            });
        }
    };
};

const editProfileProfessionsUserReferTo = (
    uid,
    professionsUserReferTo = {},
    cb
) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            await firebase.updateUserProfileProfessionsUserReferTo(
                uid,
                professionsUserReferTo
            );

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                professionsUserReferTo
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));

            if (cb) cb();
        } catch (errors) {
            console.error("editProfileAbout", errors);

            const parsedErrors = cloudfunctions.parseRequestError(errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                payload: {
                    errors: parsedErrors
                }
            });
        }
    };
};

const editProfilePrivacy = (uid, privacy, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;
            const profileSection = "PRIVACY";
            await cloudfunctions.updateUser2(profileSection, privacy);

            const payload = {
                ...actualUser,
                uid,
                itsMe: true,
                settings: {
                    privacy
                }
            };

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const checkUserNameLoading = isLoading => ({
    type: CHECK_USERNAME_IS_LOADING,
    payload: {
        isLoading
    }
});

const checkUserNameAvailability = (uid, userName) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            dispatch(checkUserNameLoading(true));
            const isAvailable = await firebase.userNameAvailable(uid, userName);
            dispatch(checkUserNameLoading(false));
            return isAvailable;
        } catch (errors) {
            dispatch(handleError(errors));
            return false;
        }
        
    }
}

const updateUserName = (uid, userName, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;
            const profileSection = "BASIC_INFO";
            await cloudfunctions.updateUser2(profileSection, { userName, phone: actualUser.phone });

            const payload = {
                ...actualUser,
                uid,
                itsMe: true,
                userName
            };

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const editProfileGoodOpsForMe = (uid, goodOpsForMe, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newGoodOpsForMe = goodOpsForMe.reduce(
                (acc, { id, title, createdAt, isNew }) => ({
                    ...acc,
                    [id]: { title, createdAt, ...(isNew ? { isNew } : {}) }
                }),
                {}
            );

            dispatch({
                type: USER_GOOD_OPS_FOR_ME_SUCCESS,
                payload: {
                    goodOpsForMe: newGoodOpsForMe
                }
            });

            const profileSection = "GOOD_OPS_FOR_ME";
            await cloudfunctions.updateUser2(profileSection, {
                goodOpsForMe: newGoodOpsForMe
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                goodOpsForMe: newGoodOpsForMe
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};
const editProfileExperience = (uid, experience, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newExperience = {};
            for (const exp of experience) {
                const { id, title, description, year, isNew } = exp;
                if (isNew) {
                    // const firebaseKey = await firebase.database
                    //     .ref(`users/${uid}/experience`)
                    //     .push().key;
                    const firebaseKey = await firebase.generateRandomFirebaseKey(
                        `users/${uid}/experience`
                    );
                    newExperience[firebaseKey] = { title, description, year };
                } else {
                    newExperience[id] = { title, description, year };
                }
            }

            dispatch({
                type: USER_EXPERIENCE_SUCCESS,
                payload: {
                    experience: newExperience
                }
            });

            const profileSection = "EXPERIENCE";
            await cloudfunctions.updateUser2(profileSection, {
                experience: newExperience
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                experience: newExperience
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};
const editProfileHonors = (uid, honors, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newHonors = {};
            for (const honor of honors) {
                const { id, title, description, year, isNew } = honor;
                if (isNew) {
                    const firebaseKey = await firebase.generateRandomFirebaseKey(
                        `users/${uid}/honors`
                    );
                    newHonors[firebaseKey] = { title, description, year };
                } else {
                    newHonors[id] = { title, description, year };
                }
            }

            dispatch({
                type: USER_HONORS_SUCCESS,
                payload: {
                    honors: newHonors
                }
            });
            // console.log('newHonors', newHonors)
            const profileSection = "HONORS";
            await cloudfunctions.updateUser2(profileSection, {
                honors: newHonors
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                honors: newHonors
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));
            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const updateHonorsIndex = (uid, honors) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newHonors = honors.reduce(
                (acc, current) => ({ ...acc, [current.id]: current }),
                {}
            );

            for (const honor of honors) {
                await firebase.updateUserProfileHonorIndex(uid, honor);
            }

            dispatch({
                type: USER_HONORS_SUCCESS,
                payload: {
                    honor: newHonors
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                honor: newHonors
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

// Deprecated old version of Licenses
// const editProfileLicenses = (uid, licenses, primaryLicenseIndex, cb) => {
//     return async (dispatch, getState, { firebase, cloudfunctions }) => {
//         dispatch({
//             type: EDIT_PROFILE_SAVE
//         });
//         try {
//             if (!uid) throw new Error("We have a problem, please retry later");
//             const actualUser = getState().main.ui.view.profile.user.data;
//             let normalizedLicenses = {};

//             const keys = Object.keys(licenses);

//             keys.forEach(key => {
//                 const keyValue = key.split("_");
//                 if (keyValue[0] && keyValue[1]) {
//                     normalizedLicenses[keyValue[0]] = {
//                         ...normalizedLicenses[keyValue[0]]
//                     };

//                     if (keyValue[1])
//                         normalizedLicenses[keyValue[0]][keyValue[1]] =
//                             licenses[key];
//                 }
//             });

//             let firebaseLicenses = {};

//             let primaryLicense = {};
//             // console.log('licences', licenses)
//             Object.keys(normalizedLicenses).forEach(licenseKey => {
//                 const expirationTimestamp =
//                     new Date(
//                         normalizedLicenses[licenseKey]["expirationDate"]
//                     ).getTime() / 1000;
//                 const primary = licenseKey === primaryLicenseIndex;
//                 firebaseLicenses[
//                     normalizedLicenses[licenseKey]["licenseNumber"]
//                 ] = {
//                     number: normalizedLicenses[licenseKey]["licenseNumber"],
//                     expiration:
//                         normalizedLicenses[licenseKey]["expirationDate"],
//                     expirationTimestamp: expirationTimestamp,
//                     state: normalizedLicenses[licenseKey]["agencyAddress"],
//                     primary: primary
//                 };
//                 if (primary)
//                     primaryLicense = {
//                         license_number:
//                             normalizedLicenses[licenseKey]["licenseNumber"],
//                         license_state:
//                             normalizedLicenses[licenseKey]["agencyAddress"],
//                         license_expiration:
//                             normalizedLicenses[licenseKey]["expirationDate"]
//                     };
//             });
//             //firebaseLicenses.filter(license => license);

//             await firebase.updateUserProfileLicenses(uid, firebaseLicenses);
//             await firebase.updateUserPrimaryLicense(
//                 uid,
//                 primaryLicense.license_number,
//                 primaryLicense.license_state,
//                 primaryLicense.license_expiration
//             );
//             dispatch({
//                 type: USER_LICENCES_SUCCESS,
//                 payload: {
//                     licenses: firebaseLicenses,
//                     ...primaryLicense
//                 }
//             });

//             const payload = Object.assign({}, actualUser, {
//                 uid,
//                 itsMe: true,
//                 licenses: firebaseLicenses,
//                 ...primaryLicense
//             });

//             dispatch({
//                 type: FETCH_PROFILE_SUCC,
//                 payload
//             });

//             dispatch(editProfileIsLoading(false));

//             if (cb) cb();
//         } catch (errors) {
//             console.error("error", errors);
//             dispatch(handleError(errors));
//             return dispatch({
//                 type: EDIT_PROFILE_SAVE_FAIL,
//                 errors: cloudfunctions.parseRequestError(errors)
//             });
//         }
//     };
// };

const editProfileLicenses = (uid, licenses, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            let newLicenses = {};

            for (const lic of licenses) {
                const {
                    id,
                    title,
                    number,
                    state,
                    issueDateTimestamp,
                    index,
                    isNew
                } = lic;
                let issueDate;
                let issueDateObject = {};

                if (issueDateTimestamp) {
                    const timestamp = new Date(issueDateTimestamp).getTime();
                    issueDate = moment(timestamp).format("MMMM, YYYY");

                    issueDateObject = {
                        issueDate,
                        issueDateTimestamp: timestamp
                    };
                }
                if (isNew) {
                    const firebaseKey = await firebase.generateRandomFirebaseKey(
                        `users/${uid}/licenses`
                    );
                    newLicenses[firebaseKey] = {
                        title,
                        number,
                        state,
                        index,
                        ...issueDateObject
                    };
                } else {
                    const licenseId = id || number;
                    newLicenses[licenseId] = {
                        title,
                        number,
                        state,
                        index,
                        ...issueDateObject
                    };
                }
            }

            dispatch({
                type: USER_LICENCES_SUCCESS,
                payload: {
                    licenses: newLicenses
                }
            });

            const profileSection = "LICENSES";
            await cloudfunctions.updateUser2(profileSection, {
                licenses: newLicenses
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                licenses: newLicenses
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));

            if (cb) cb();
            return;
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const updateLicensesIndex = (uid, licenses) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            const newLicenses = licenses.reduce(
                (acc, current) => ({ ...acc, [current.id]: current }),
                {}
            );

            for (const lic of licenses) {
                await firebase.updateUserProfileLicenseIndex(uid, lic);
            }

            dispatch({
                type: USER_LICENCES_SUCCESS,
                payload: {
                    licenses: newLicenses
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                licenses: newLicenses
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const editProfileSocialNetworks = (uid, social, cb) => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: EDIT_PROFILE_SAVE
        });
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            const actualUser = getState().main.ui.view.profile.user.data;

            await firebase.updateUserProfileSocialNetworks(uid, social);

            dispatch({
                type: USER_SOCIAL_SUCCESS,
                payload: {
                    social: social
                }
            });

            const payload = Object.assign({}, actualUser, {
                uid,
                itsMe: true,
                social: social
            });

            dispatch({
                type: FETCH_PROFILE_SUCC,
                payload
            });

            dispatch(editProfileIsLoading(false));

            if (cb) cb();
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

let call;

function fetchUserList(text) {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        dispatch({
            type: FETCH_USER_LIST
        });
        try {
            let response;
            if (call) {
                call.cancel();
            }

            call = axios.CancelToken.source();
            try {
                if (text === "") return;
                response = await cloudfunctions.fetchUserList(text, call);
                const data =
                    response.data.users_dev || response.data.users || [];
                const payload = data.map(user => {
                    return Object.assign({}, user, {
                        key: user._id,
                        name: user._source
                            ? `${
                                  user._source.first_name
                                      ? user._source.first_name
                                            .charAt(0)
                                            .toUpperCase() +
                                        user._source.first_name.slice(1)
                                      : ""
                              } 
                                                                                                                                                    ${
                                                                                                                                                        user
                                                                                                                                                            ._source
                                                                                                                                                            .last_name
                                                                                                                                                            ? user._source.last_name
                                                                                                                                                                  .charAt(
                                                                                                                                                                      0
                                                                                                                                                                  )
                                                                                                                                                                  .toUpperCase() +
                                                                                                                                                              user._source.last_name.slice(
                                                                                                                                                                  1
                                                                                                                                                              )
                                                                                                                                                            : ""
                                                                                                                                                    }`
                            : ""
                    });
                });
                return dispatch({
                    type: FETCH_USER_LIST_SUCC,
                    payload
                });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    dispatch(handleError(error, "error", false));
                }
            }
        } catch (errors) {
            dispatch(handleError(errors));
            return dispatch({
                type: FETCH_USER_LIST_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
}

const fetchUserIpData = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_IPAPI_REST}/`
            );
            let ipInfo = await response.json();
            const blockedCountries = getState().main.user.blockedCountries;
            const userCountry = ipInfo.country_name;

            const blockedUser = userCountry
                ? blockedCountries
                      .map(country => country.toUpperCase())
                      .includes(userCountry.toUpperCase())
                : false;

            const payload = {
                ipInfo: { ...ipInfo, blockedUser: blockedUser }
            };

            dispatch({
                type: USER_IPINFO_SUCCESS,
                payload
            });

            dispatch(editProfileIsLoading(false));
        } catch (errors) {
            console.error("error", errors);
            // dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

const subscribeToUserDataChanged = uid => {
    return async (dispatch, getState, { firebase }) => {
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            firebase.subscribeToUserChanged(uid, data => {
                batch(() => {
                    const prevUser = getState().main.user;
                    const userExtraInfo = {};

                    dispatch({
                        type: USER_STORE_SUCC,
                        payload: {
                            ...data
                        }
                    });
                    if (
                        (data &&
                            data.type !== null &&
                            data.type !== prevUser.profile.type) ||
                        typeof prevUser.userTypeValidForSendReferrals ===
                            "undefined"
                    ) {
                        userExtraInfo[
                            "userTypeValidForSendReferrals"
                        ] = UserBussiness.userTypeValidForSendReferrals(
                            data.type
                        );
                        userExtraInfo[
                            "userTypeValidForReceiveReferrals"
                        ] = UserBussiness.userTypeValidForReceiveReferrals(
                            data.type
                        );
                        userExtraInfo[
                            "userTypeValidForWriteArticles"
                        ] = UserBussiness.userTypeValidForWriteArticles(
                            data.type
                        );
                        dispatch({
                            type: USER_EXTRAINFO_SUCCESS,
                            payload: {
                                ...userExtraInfo
                            }
                        });
                    }
                });
            });
        } catch (errors) {
            console.error("error", errors);
            dispatch(handleError(errors));
        }
    };
};

const subscribeToUserCreditsDataChanged = uid => {
    return async (dispatch, getState, { firebase }) => {
        try {
            if (!uid) throw new Error("We have a problem, please retry later");
            firebase.subscribeToCreditsChanged(uid, data => {
                dispatch({
                    type: USER_FETCH_CREDITS_CHANGED_SUCCESS,
                    payload: {
                        credits: { ...data }
                    }
                });
            });
        } catch (errors) {
            console.log("error", errors);
            dispatch(handleError(errors));
        }
    };
};

const updateEmail = (uid, password, email, newEmail) => {
    return async (dispatch, getState, { firebase }) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!uid)
                    throw new Error("We have a problem, please retry later");
                dispatch(settingsIsLoading(true));
                const { name, lastname } = getState().main.user.profile;

                await firebase.updateUserEmail(uid, password, email, newEmail);
                await firebase.setVerifiedEmail(uid, false);
                dispatch(sendVerificationEmail(newEmail, uid, name, lastname));
                dispatch(settingsIsLoading(false));
                resolve();
            } catch (errors) {
                dispatch(settingsIsLoading(false));
                console.error("updateEmail error", JSON.stringify(errors));
                dispatch(handleError(errors));
                reject();
            }
        });
    };
};

export const fetchUserInvites = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (uid) {
                firebase.subscribeToUserInvitesChanged(uid, async result => {
                    let subscribed = [];
                    let accepted = [];
                    let users = {};
                    let notDismisseds = [];
                    if (result) {
                        const actualInvitedPeople = getState().main.user
                            .invitedPeople;
                        const asyncForEach = async (array, callback) => {
                            for (let index = 0; index < array.length; index++) {
                                await callback(array[index], index, array);
                            }
                        };

                        const start = async () => {
                            await asyncForEach(
                                Object.keys(result),
                                async userId => {
                                    users[
                                        userId
                                    ] = await firebase.fetchProfileWithCache(
                                        userId
                                    );
                                }
                            );
                        };
                        await start();

                        accepted = Object.keys(result).filter(
                            userId => !result[userId].subscribed
                        );

                        subscribed = Object.keys(result).filter(
                            userId => result[userId].subscribed
                        );
                        notDismisseds = Object.keys(result).filter(
                            userId =>
                                !result[userId].notification ||
                                !result[userId].notification.dismissed
                        );
                        const newSubscribed =
                            subscribed.length > 0 &&
                            (!actualInvitedPeople.subscribed ||
                                subscribed.length >
                                    actualInvitedPeople.subscribed.length);
                        const newNotification =
                            notDismisseds.length > 0 &&
                            (!actualInvitedPeople.notDismisseds ||
                                notDismisseds.length >
                                    actualInvitedPeople.notDismisseds.length);
                        if (newSubscribed || newNotification)
                            if (newSubscribed)
                                //  dispatch(navbarNotificationTouched(false));
                                dispatch(setInvitesAccountBalance());
                        dispatch(
                            fetchInvitesSuccess({
                                //invited: invited,
                                accepted: accepted,
                                subscribed: subscribed,
                                data: result,
                                users: users,
                                notDismisseds: notDismisseds
                            })
                        );
                    } else {
                        dispatch(
                            fetchInvitesSuccess({
                                //invited: invited,
                                accepted: accepted,
                                subscribed: subscribed,
                                data: result,
                                users: users
                            })
                        );
                    }
                });
            } else {
                throw new Error("Incomplete Payload for fetch invites");
            }
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
        }
    };
};

export const fetchInviterUser = uid => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            if (uid) {
                const inviter = await firebase.fetchProfileById(uid);
                console.log(inviter);
            } else {
                throw new Error("Incomplete Payload for fetch");
            }
        } catch (error) {
            console.error(error);
            dispatch(handleError(error));
        }
    };
};

const setDismissInviteNotification = uid => {
    return async (dispatch, getState, { firebase }) => {
        try {
            const meId = getState().main.user.uid;
            if (!uid || !meId)
                throw new Error("We have a problem, please retry later");
            dispatch(dismissInviteSuccess(uid));
            //await cloudfunctions.setDismissInviteNotification(uid);
            firebase.setDismissInvitationNotification(meId, uid);
        } catch (errors) {
            console.error("setDismissInviteNotification error", errors);
            dispatch(handleError(errors));
        }
    };
};

const setInvitesAccountBalance = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const meId = getState().main.user.uid;
            if (!meId) throw new Error("We have a problem, please retry later");
            const balance = await cloudfunctions.getInvitesAccountBalance();
            dispatch(invitesAccountBalanceSuccess(balance && balance.data));
        } catch (errors) {
            console.error("setInvitesAccountBalance error", errors);
            dispatch(handleError(errors));
        }
    };
};

const getEarningHistory = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const meId = getState().main.user.uid;
            if (!meId) throw new Error("We have a problem, please retry later");
            const earningHistory = await cloudfunctions.getEarningHistory();
            // console.log(earningHistory, "earningHistory");
            dispatch(
                earningHistorySuccess(
                    earningHistory &&
                        earningHistory.data &&
                        earningHistory.data.result
                )
            );
        } catch (errors) {
            console.error("getEarningHistory error", errors);
            dispatch(handleError(errors));
        }
    };
};

const fetchTotalInvites = () => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            const meId = getState().main.user.uid;
            if (!meId) throw new Error("We have a problem, please retry later");
            const result = await cloudfunctions.fetchTotalInvites(meId);
            dispatch(fetchTotalInvitesSuccess(result && result.data));
        } catch (errors) {
            console.error("fetchTotalInvites error", errors);
            dispatch(handleError(errors));
        }
    };
};

const addProfessions = professions => {
    return async (dispatch, getState, { firebase, cloudfunctions }) => {
        try {
            await firebase.addUserTypes(professions);
        } catch (errors) {
            console.error("addProfessions error", errors);
        }
    };
};

const checkStripeCustomerId = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const meId = getState().main.user.uid;
            const {
                name,
                lastname,
                email,
                customer_id
            } = getState().main.user.profile;

            if (!meId) throw new Error("We have a problem, please retry later");
            if (!customer_id) {
                console.log("user dosnt have customer_id");
                const stripeBody = {
                    name: name,
                    lastname: lastname,
                    email: email
                };

                const stripeResponse = await cloudfunctions.getStripeCustomerId(
                    meId,
                    stripeBody
                );

                const custId =
                    stripeResponse &&
                    stripeResponse.data &&
                    stripeResponse.data.custid;
                if (custId) await firebase.setStripeCustomerId(meId, custId);
            }
        } catch (errors) {
            console.error("fetchTotalInvites error", errors);
            dispatch(handleError(errors));
        }
    };
};

const onIdTokenChanged = () => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            firebase.onIdTokenChanged(user => {
                if (user) {
                    user.getIdToken().then(token => {
                        const { uid } = user;
                        cloudfunctions.appendToken(token, uid);

                        localStorage.setItem(
                            config.localStorage.IS_LOGGED_IN,
                            "true"
                        );

                        let newStorage;

                        const currentUser = localStorage.getItem(
                            config.localStorage.USER_PROFILE
                        );

                        newStorage = Object.assign(
                            {},
                            JSON.parse(currentUser),
                            {
                                idToken: token
                            }
                        );

                        localStorage.setItem(
                            config.localStorage.USER_PROFILE,
                            JSON.stringify(newStorage)
                        );

                        dispatch({
                            type: USER_EXTRAINFO_SUCCESS,
                            payload: {
                                idToken: token
                            }
                        });
                    });
                }
            });
        } catch (errors) {
            console.error("fetchTotalInvites error", errors);
            dispatch(handleError(errors));
        }
    };
};

const showPhoneValidationModal = open => ({
    type: VALIDATE_PHONE_MODALOPEN_ONFILLPROFILE,
    payload: {
        open: open
    }
});

const verifyPhoneNumber = phone => {
    return async (dispatch, getState, { cloudfunctions }) => {
        dispatch({
            type: VALIDATE_PHONE
        });
        try {
            dispatch(showPhoneValidationModal(true));
            // const nahuiPhone = "+18455336917"
            let response = await cloudfunctions.sendVerificationCode(phone);
            //let response = await cloudfunctions.sendVerificationCode(nahuiPhone);
            return dispatch({
                type: VALIDATE_PHONE_SUCC,
                payload: {
                    phone
                }
            });
        } catch (errors) {
            console.error("verifyPhone", errors);
           // If error is phone number already exists, we should show the error
            if (errors.response && errors.response.status === 409) {
                dispatch({
                    type: VALIDATE_PHONE_FAIL,
                    payload: {
                        phoneAlreadyInUse: true
                    }
                });
                return false;
            }
            dispatch(handleError(errors));
            return dispatch({
                type: VALIDATE_PHONE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

function verifyPhoneNumberCode(code, onSuccess, onFail) {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: VALIDATE_PHONE
        });
        try {
            const state = await getState();
            const { phone } = state.main.user.phoneValidation;
            // console.log("user reducer", phone)
            await cloudfunctions.validateVerificationCode(code, phone);
            const uid = state.main.user.uid;
            firebase.userPhoneValid(uid, true);
            dispatch(setVerifiedPhone(true));
            dispatch(showPhoneValidationModal(false));
            dispatch({
                type: VALIDATE_PHONE_CODE_SUCC,
                code: {
                    phone
                }
            });
            if (onSuccess) onSuccess();
            return;
        } catch (errors) {
            console.log("errors", errors);
            dispatch(handleError(errors));
            if (onFail) onFail();
            return dispatch({
                type: VALIDATE_PHONE_CODE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
}

function _devValidatePhoneNumber(){
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        dispatch({
            type: VALIDATE_PHONE
        });
        try {
            const state = await getState();
            const { phone } = state.main.user.phoneValidation;
            const uid = state.main.user.uid;
            firebase.userPhoneValid(uid, true);
            dispatch(setVerifiedPhone(true));
            dispatch(showPhoneValidationModal(false));
            dispatch({
                type: VALIDATE_PHONE_CODE_SUCC,
                code: {
                    phone
                }
            });
            return;
        } catch (errors) {
            console.log("errors", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: VALIDATE_PHONE_CODE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    }
}

const userNameAvailable = userName => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const myUserName = getState().main.user.profile.userName;
            const available = await firebase.userNameAvailable(userName);
            return available || userName === myUserName;
        } catch (errors) {
            console.error("userNameAvailable", errors);
            dispatch(handleError(errors));
        }
    };
};

const setOnboardingComplete = onboardingView => {
    return async (dispatch, getState, { cloudfunctions }) => {
        try {
            dispatch(setOnboarding(onboardingView, true));
            await cloudfunctions.setOnboardingComplete(onboardingView);
        } catch (errors) {
            console.error("setOnboardingComplete", errors);
            dispatch(setOnboarding(onboardingView, false));
            dispatch(handleError(errors));
        }
    };
};

const updateFirstProfileDetails = ({
    headline: headLine,
    username: userName,
    professionsUserReferTo,
    goodOpsForMe
}) => {
    return async (dispatch, getState, { cloudfunctions, firebase }) => {
        try {
            const uid = getState().main.user.uid;
            const profile = getState().main.user.profile;

            // Convert professionsUserReferTo to object of form { [professionKey]: true }
            professionsUserReferTo = professionsUserReferTo.reduce(
                (acc, { value }) => ({
                    ...acc,
                    [value]: true
                }),
                {}
            );
            // Convert goodOpsForMe to object of form { [goodOpKey]: { title, createdAt, isNew } }
            goodOpsForMe = goodOpsForMe.reduce(
                (acc, { id, title, createdAt, isNew }) => ({
                    ...acc,
                    [id]: {
                        title,
                        createdAt,
                        ...(isNew ? { isNew } : {})
                    }
                }),
                {}
            );

            const promises = [
                cloudfunctions.updateUser2("BASIC_INFO", {
                    ...profile,
                    headLine,
                    userName
                }),
                firebase.updateUserProfileProfessionsUserReferTo(
                    uid,
                    professionsUserReferTo
                ),
                cloudfunctions.updateUser2("GOOD_OPS_FOR_ME", {
                    goodOpsForMe
                })
            ];
            await Promise.all(promises);
            dispatch({
                type: EDIT_PROFILE_SAVE_SUCC
            });
        } catch (errors) {
            console.error("updateFirstProfileDetails", errors);
            dispatch(handleError(errors));
            return dispatch({
                type: EDIT_PROFILE_SAVE_FAIL,
                errors: cloudfunctions.parseRequestError(errors)
            });
        }
    };
};

export {
    userNameAvailable,
    showPhoneValidationModal,
    verifyPhoneNumber,
    verifyPhoneNumberCode,
    editProfileAbout,
    addProfessions,
    onIdTokenChanged,
    checkStripeCustomerId,
    fetchTotalInvites,
    setInvitesAccountBalance,
    setDismissInviteNotification,
    updateEmail,
    subscribeToUserCreditsDataChanged,
    setUserSetting,
    updateUser,
    addGlobaluser,
    fetchUserIpData,
    uploadAvatarSuccess,
    signUp,
    signIn,
    fetchProfile,
    editProfile,
    rehydrateUser,
    fetchUserList,
    signOut,
    socialSignUp,
    setSocialSignUpCallback,
    subscribedToFirebase,
    removeFirebaseSubscriptions,
    editProfileProfessionsUserReferTo,
    editProfileGoodOpsForMe,
    editProfileExperience,
    editProfileRealStateFocus,
    editProfileServiceAreas,
    editProfileHonors,
    editProfileSocialNetworks,
    editProfileLicenses,
    updateLicensesIndex,
    updateHonorsIndex,
    editCommunityInvolvement,
    updateCommunityInvolvementIndex,
    cleanUser,
    setVerifiedPhone,
    verifyUserEmailVerificated,
    subscribeToUserDataChanged,
    fetchProfileSubscription,
    fetchProfileCredits,
    logLastLogin,
    editProfilePrivacy,
    checkUserNameAvailability,
    updateUserName,
    getEarningHistory,
    setOnboarding,
    setOnboardingComplete,
    _devValidatePhoneNumber,
    updateFirstProfileDetails
    //getUnsuccessfullLoginsFromLocal
};
