import React, { useState } from "react";
import { Flex } from "rebass";
import { withTheme } from "styled-components";
import { Button } from "./button-styles";

export default withTheme(
    ({
        theme,
        onClick,
        label,
        icon: Icon,
        type,
        size,
        disabled,
        ...otherProps
    }) => {
        const [hover, setHover] = useState(false);
        const handleHover = hover => {
            setHover(hover);
        };
        const getColor = (theme, type, hover, disabled) =>
            type === "PRIMARY"
                ? theme.white
                : type === "BORDER"
                ? disabled
                    ? theme.taupeGray
                    : hover
                    ? theme.bottleGreen
                    : theme.shamRockGreen
                : theme.shamRockGreen;
        const iconColor = getColor(theme, type, hover, disabled);
        return (
            <Button
                disabled={disabled}
                className="disable-select"
                type={type}
                size={size}
                onClick={onClick}
                {...otherProps}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
            >
                <Flex alignItems="center" justifyContent="center">
                    {Icon && (
                        <Icon
                            width="13px"
                            height="13px"
                            style={{ marginRight: 8 }}
                            color={iconColor}
                        />
                    )}
                    {label}
                </Flex>
            </Button>
        );
    }
);
