import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import PrivateRoute from "../../hocs/PrivateRoute";
import PublicRoute from "../../hocs/PublicRoute";
import { history } from "../../../redux/store";
import Layout from "../../hocs/Layout";
import PublicLayout from '../../hocs/SignInLayout'
import Spinner from '../../../assets/svg/Spinner'
import Utils from "../../../libs/Utils";
const SignUp = React.lazy(() => import('../../ui/presentation/views/SignUp'));
const SignIn = React.lazy(() => import('../../ui/presentation/views/SignIn'));
const FillProfile = React.lazy(() => import('../../ui/presentation/views/FillProfile'));
const CompleteProfile = React.lazy(() => import('../../ui/presentation/views/CompleteProfile'));
const PasswordRecovery = React.lazy(() => import('../../ui/presentation/views/PasswordRecovery'));
const Profile = React.lazy(() => import('../../ui/presentation/views/Profile'));
const SendReferral = React.lazy(() => import('../../ui/presentation/views/SendReferral'));
const OpportunityCreation = React.lazy(() => import('../../ui/presentation/views/OpportunityCreation'));
const ReferralCreation = React.lazy(() => import('../../ui/presentation/views/ReferralCreation'));
const Intro = React.lazy(() => import('../../ui/presentation/views/Intros'));
const JobPost = React.lazy(() => import('../../ui/presentation/views/JobPost'));
const GeneralPost = React.lazy(() => import('../../ui/presentation/views/GeneralPost'));
const RecommendationCreation = React.lazy(() => import('../../ui/presentation/views/RecommendationCreation'))
const Referrals = React.lazy(() => import('../../ui/presentation/views/Referrals'));
const Contacts = React.lazy(() => import('../../ui/presentation/views/Contacts'));
const NotFoundRoute = React.lazy(() => import('../../ui/presentation/views/NotFoundExceptionHandler'));
const ReferralInformation = React.lazy(() => import('../../ui/presentation/views/ReferralInformation'));
const ResetPassword = React.lazy(() => import('../../ui/presentation/views/ResetPassword'));
const Settings = React.lazy(() => import('../../ui/presentation/views/Settings'));
const Home = React.lazy(() => import('../../ui/presentation/views/Home'));
const News = React.lazy(() => import('../../ui/presentation/views/News'));
const BigBoard = React.lazy(() => import('../../ui/presentation/views/BigBoard'));
const Search = React.lazy(() => import('../../ui/presentation/views/Search'));
const NewArticle = React.lazy(() => import('../../ui/presentation/views/NewArticle'));
const Pricing = React.lazy(() => import('../../ui/presentation/views/Pricing'));
const Chat = React.lazy(() => import('../../ui/presentation/views/Chat'));
const ThankYou = React.lazy(() => import("../../ui/presentation/views/ThankYou"));
const Notifications = React.lazy(() => import("../../ui/presentation/views/Notifications"));
const MyNetwork = React.lazy(() => import('../../ui/presentation/views/MyNetwork'));
const Icons = React.lazy(() => import('../../ui/presentation/atoms/shared/Icon/AllIcons'));
const RecommendationInformation = React.lazy(() => import('../../ui/presentation/views/RecommendationInformation'));
const JobInformation = React.lazy(() => import('../../ui/presentation/views/JobInformation'));
const Update = React.lazy(() => import('../../ui/presentation/views/Update'));
const isLocalhost = Utils.isLocalhost();

const Router = (props) => {
    const { isLoading, isLoggedIn, token, geolocationLoading } = props
    const renderRoutes = () => {
        return (
            <Suspense
                fallback={
                    <div
                        style={{
                            position: "absolute",
                            right: "49%",
                            top: "49%"
                        }}
                    >
                        <Spinner color="black" />
                    </div>
                }
            >
                <Switch>
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path={["/", "/home", "/leaderboard"]}
                        component={Home}
                    />
                    {/* <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/news"
                        component={News}
                    /> */}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/profile/:userName"
                        component={Profile}
                    />

                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/settings/:defaultSubscription?"
                        component={Settings}
                    />

                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/articles/add"
                        component={NewArticle}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/articles/edit/:articleId"
                        component={NewArticle}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/articles/:articleId"
                        component={News}
                    />

                    {/*  <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/send-referral/add-user"
                        component={SendReferralUserList}
                    /> */}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/referral/:referralCode"
                        component={ReferralInformation}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/referral/:referralId/:toIndex"
                        component={ReferralInformation}
                    />
                    {/* This is fallback route when the type of Op has not been specified in the route */}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="//:referralCode"
                        component={({ history }) => {
                            history.push(`/`);
                            return null;
                        }}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        geolocationLoading={geolocationLoading}
                        exact
                        path="/referrals"
                        component={Referrals}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/contacts"
                        component={Contacts}
                    />
                    {/* <PrivateRoute isLoggedIn={isLoggedIn} exact path="/send-referral/:uid" component={SendReferral} /> */}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/send-referral"
                        component={SendReferral}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/op-creation"
                        component={OpportunityCreation}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/create-referral"
                        component={ReferralCreation}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/create-job"
                        component={JobPost}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/create-introduction"
                        component={Intro}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/general-post"
                        component={GeneralPost}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/intro-creation"
                        component={OpportunityCreation}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/create-recommendation"
                        component={RecommendationCreation}
                        easy={false}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/easy-referral"
                        component={SendReferral}
                        easy={true}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/search"
                        component={Search}
                    />
                    {/*  <Route exact path="/all-done" component={AllDone} /> */}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/fill-profile"
                        component={FillProfile}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/thankyou"
                        component={ThankYou}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/complete-profile"
                        component={CompleteProfile}
                    />
                    <PublicRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/password-recovery"
                        component={PasswordRecovery}
                    />
                    {/* <PrivateRoute
                            isLoggedIn={isLoggedIn}
                            exact
                            path="/verify-user"
                            component={VerifyUser}
                        /> */}
                    <PublicRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/sign-up"
                        component={SignUp}
                    />
                    <PublicRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/sign-in"
                        component={SignIn}
                    />
                    <PublicRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/reset-password"
                        component={ResetPassword}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/home"
                        component={BigBoard}
                    />

                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/articles/add"
                        component={NewArticle}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/articles/:articleId"
                        component={News}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/pricing"
                        component={Pricing}
                    />

                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/chat"
                        component={Chat}
                        exact
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/chat/:conversationId"
                        component={Chat}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/notifications"
                        component={Notifications}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        path="/my-network"
                        component={MyNetwork}
                    />
                    {isLocalhost && (
                        <Route
                            isLoggedIn={isLoggedIn}
                            exact
                            path="/icons"
                            component={Icons}
                        />
                    )}
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path={[
                            "/intro/:referralCode",
                            "/recommendation/:referralCode"
                        ]}
                        component={RecommendationInformation}
                    />
                    <PrivateRoute
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/job/:jobCode"
                        component={JobInformation}
                    />
                    <Route exact path="/update" component={Update} />
                    <Route
                        isLoggedIn={isLoggedIn}
                        exact
                        path="/invite/:profileQR"
                        component={() => {
                            window.location.href =
                                process.env.REACT_APP_INVITE_DEEPLINK;
                            return null;
                        }}
                    />
                    <NotFoundRoute isLoggedIn={isLoggedIn} />
                </Switch>
            </Suspense>
        );
    }

    return (
        <ConnectedRouter history={history}>

            {isLoading ||
                (isLoggedIn && token) ? (
                <WithLayout>{renderRoutes()}</WithLayout>
            ) : (
                <PublicLayout>
                    {renderRoutes()}
                </PublicLayout>
            )}


        </ConnectedRouter>
    );
}

const WithLayout = props => {
    return (
        <div id="body">
            <Layout>{props.children}</Layout>
        </div>
    )
};

const mapStateToProps = state => {

    return {
        isLoggedIn: state.main.user.isLoggedIn,
        isLoading: state.main.user.isLoading,
        token: state.main.user.token
    };
};
export default connect(mapStateToProps)(Router);
