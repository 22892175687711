import { connect } from "react-redux";
import NotificationsList from "./NotificationsList";
import { subscribeToNotifications } from "../../../../../redux/actions/creators/navbar";
import { 
    acceptContactRequest, 
    declineContactRequest, 
    removeNotification, 
    setNotificationRead,
    markAllNotificationsAsRead 
} from '../../../../../redux/actions/creators/navbar'
import { toggleSidebar } from '../../../../../redux/actions/creators/navigation'
import { withTheme } from "styled-components";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { acceptKnockConnectRequest,  declineKnockRequest} from "../../../../../redux/actions/creators/relations";

const mapStateToProps = state => {

    return {
        connectionRequests: state.main.ui.global.notifications.connectionRequests,
        notifications: state.main.ui.global.notifications.data,
        // hasMore: state.main.ui.global.notifications.hasMore,
        isLoading: state.main.ui.global.notifications.isLoading,
        // lastSeenId: state.main.ui.global.notifications.lastSeenId,
        myId: state.main.user.uid,
        user: state.main.user.profile,
        emailIsNotVerified: state.main.user.profile.verified_email,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            subscribeToNotifications,
            acceptContactRequest,
            declineContactRequest,
            removeNotification,
            toggleSidebar,
            setNotificationRead,
            markAllNotificationsAsRead,
            acceptKnockConnectRequest,
            declineKnockRequest
        },
        dispatch
    );
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTheme(NotificationsList))
);
