import React from 'react';
import { Icon } from '../../../atoms/shared/Icon';
import OpportunityType from "./OpportunityType/index";
import { OPPORTUNITY_CREATION_STEPS, OPPORTUNITY_TYPES } from '../constants';
import ReferralPrivacy from './ReferralPrivacy/index';
import ReferralCompleteInformation from './ReferralCompleteInformation/index';
import ReferralProfession from './ReferralProfession/index';
import OpportunityPreview from './Preview/index';
import CandidateSelection from './CandidateSelection';
import JobCurrentType from './JobCurrent/Type/index';
import JobCurrentPrivacy from './JobCurrent/Privacy/index';
import JobCurrentEmployeeCompleteInformation from './JobCurrent/EmployeeCompleteInformation/index';
import JobEmployeePreview from './JobCurrent/EmployeePreview/index'
import JobContractorPreview from './JobCurrent/ContractorPreview/index';
import JobCurrentContractorCompleteInformation from './JobCurrent/ContractorCompleteInformation/index';
// Referral Past Views
import { Receiver, ReferralPastCompleteInformation, ReferralPastPreview } from './ReferralPastSteps';
import JobPastCompleteInformation from './JobPast/JobPastCompleteInformation/index';
import JobPastPreview from './JobPast/JobPastPreview/index'

export const OPPORTUNITY_TYPE_STEP_ITEM = {
    icon: <Icon icon="my-ops" color="white" />,
    title: 'Create a Referral',
    code: OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP,
};

export const REFERRAL_PRIVACY_STEP_ITEM = {
    icon: <Icon icon="hand-speaker" color="white" />,
    title: 'Referral',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP,
};

export const CANDIDATES_SELECTION_STEP_ITEM = {
    icon: <Icon icon="profile" color="white" />,
    title: 'Select candidate(s)',
    code: OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION,
};

export const REFERRAL_PROFESSION_STEP_ITEM = {
    icon: <Icon icon="profile" color="white" />,
    title: 'Profession',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION,
};

export const REFERRAL_COMPLETE_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Op Details',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION,
};

export const REFERRAL_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PREVIEW,
};


export const JOB_CURRENT_TYPE_STEP_ITEM = {
    icon: <Icon icon="hand-speaker" color="white" />,
    title: 'Job',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE,
};

export const JOB_CURRENT_PRIVACY_STEP_ITEM = {
    icon: <Icon icon="lock" color="white" />,
    title: 'Privacy',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_PRIVACY,
};

export const JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Job information',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION,
};

export const JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Job information',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION,
};

export const JOB_CURRENT_EMPLOYEE_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_PREVIEW,
};

export const JOB_CURRENT_CONTRACTOR_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_PREVIEW,
};

export const JOB_PAST_COMPLETE_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Job information',
    code: OPPORTUNITY_CREATION_STEPS.JOB_PAST_COMPLETE_INFORMATION,
};

export const JOB_PAST_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.JOB_PAST_PREVIEW,
};


export const REFERRAL_FLOW_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    REFERRAL_PRIVACY_STEP_ITEM,
    REFERRAL_COMPLETE_INFORMATION_STEP_ITEM,
    REFERRAL_PREVIEW_STEP_ITEM,
];

export const JOB_FLOW_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    JOB_CURRENT_TYPE_STEP_ITEM,
    JOB_CURRENT_PRIVACY_STEP_ITEM,
    JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION_STEP_ITEM,
    JOB_CURRENT_EMPLOYEE_PREVIEW_STEP_ITEM,
];

export const JOB_FLOW_CONTRACTOR_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    JOB_CURRENT_TYPE_STEP_ITEM,
    JOB_CURRENT_PRIVACY_STEP_ITEM,
    JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION_STEP_ITEM,
    JOB_CURRENT_CONTRACTOR_PREVIEW_STEP_ITEM,
];

export const JOB_FLOW_CONTRACTOR_CANDIDATES_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    JOB_CURRENT_TYPE_STEP_ITEM,
    JOB_CURRENT_PRIVACY_STEP_ITEM,
    CANDIDATES_SELECTION_STEP_ITEM,
    JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION_STEP_ITEM,
    JOB_CURRENT_CONTRACTOR_PREVIEW_STEP_ITEM,
];

export const JOB_FLOW_CANDIDATES_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    JOB_CURRENT_TYPE_STEP_ITEM,
    JOB_CURRENT_PRIVACY_STEP_ITEM,
    CANDIDATES_SELECTION_STEP_ITEM,
    JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION_STEP_ITEM,
    JOB_CURRENT_EMPLOYEE_PREVIEW_STEP_ITEM,
];

export const JOB_PAST_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    CANDIDATES_SELECTION_STEP_ITEM,
    JOB_PAST_COMPLETE_INFORMATION_STEP_ITEM,
    JOB_PAST_PREVIEW_STEP_ITEM,
];

export const REFERRAL_CANDIDATES_FLOW_STEPS_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
    REFERRAL_PRIVACY_STEP_ITEM,
    CANDIDATES_SELECTION_STEP_ITEM,
    REFERRAL_COMPLETE_INFORMATION_STEP_ITEM,
    REFERRAL_PREVIEW_STEP_ITEM,
];





// MARK: This Item is meant just  Referral Past Flow Candidate
export const PAST_CANDIDATES_SELECTION_STEP_ITEM = {
    icon: <Icon icon="profile" color="white" />,
    title: 'Select Candidate(s)',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE,
};

// MARK: This Item is meant just  Referral Past Flow Candidate
export const REFERRAL_PAST_COMPLETE_INFORMATION_STEP_ITEM = {
    icon: <Icon icon="clipboard" color="white" />,
    title: 'Op Details',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION,
};
//MARK: The Referral Preview Step Item
export const REFERRAL_PAST_PREVIEW_STEP_ITEM = {
    icon: <Icon icon="send" color="white" />,
    title: 'Preview',
    code: OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_PREVIEW,
};


//MARK: The whole Referral Past Flow Steps Items
export const REFERRAL_PAST_FLOW_ITEMS = [
    OPPORTUNITY_TYPE_STEP_ITEM,
  //  REFERRAL_PRIVACY_STEP_ITEM,
    PAST_CANDIDATES_SELECTION_STEP_ITEM,
    REFERRAL_PAST_COMPLETE_INFORMATION_STEP_ITEM,
    REFERRAL_PAST_PREVIEW_STEP_ITEM, 
];

export const OPPORTUNITY_CREATION_STEPS_VIEWS = {
    [OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP]: <OpportunityType />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP]: <ReferralPrivacy />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION]: <ReferralProfession />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: <ReferralCompleteInformation />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PREVIEW]: <OpportunityPreview />,
    [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: <CandidateSelection />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE]: <Receiver />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION]: <ReferralPastCompleteInformation />,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_PREVIEW]: <ReferralPastPreview />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE]: <JobCurrentType />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_PRIVACY]: <JobCurrentPrivacy />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_COMPLETE_INFORMATION]: <JobCurrentEmployeeCompleteInformation />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_EMPLOYEE_PREVIEW]: <JobEmployeePreview />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_COMPLETE_INFORMATION]: <JobCurrentContractorCompleteInformation />,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_CONTRACTOR_PREVIEW]: <JobContractorPreview />,
    [OPPORTUNITY_CREATION_STEPS.JOB_PAST_COMPLETE_INFORMATION]: <JobPastCompleteInformation />,
    [OPPORTUNITY_CREATION_STEPS.JOB_PAST_PREVIEW]: <JobPastPreview />
}; 

export const OPPORTUNITY_CREATION_STEPS_ITEMS = {
    [OPPORTUNITY_CREATION_STEPS.OPPORTUNITY_TYPE_STEP]: OPPORTUNITY_TYPE_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PRIVACY_STEP]: REFERRAL_PRIVACY_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PROFESSION]: REFERRAL_PROFESSION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_COMPLETE_INFORMATION]: REFERRAL_COMPLETE_INFORMATION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PREVIEW]: REFERRAL_PREVIEW_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: CANDIDATES_SELECTION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE]: PAST_CANDIDATES_SELECTION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_COMPLETE_INFORMATION]: REFERRAL_PAST_COMPLETE_INFORMATION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_PREVIEW]: REFERRAL_PAST_PREVIEW_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_TYPE]: JOB_CURRENT_TYPE_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.JOB_CURRENT_PRIVACY]: JOB_CURRENT_PRIVACY_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.JOB_PAST_COMPLETE_INFORMATION]: JOB_PAST_COMPLETE_INFORMATION_STEP_ITEM,
    [OPPORTUNITY_CREATION_STEPS.JOB_PAST_PREVIEW]: JOB_PAST_PREVIEW_STEP_ITEM
};

export const FLOW_STEPS_ITEMS = {
  [OPPORTUNITY_TYPES.REFERRAL]: REFERRAL_FLOW_STEPS_ITEMS,
  [OPPORTUNITY_TYPES.JOB]: JOB_FLOW_STEPS_ITEMS,
  [OPPORTUNITY_CREATION_STEPS.CANDIDATES_SELECTION]: REFERRAL_CANDIDATES_FLOW_STEPS_ITEMS,
  [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST_CANDIDATE]: REFERRAL_PAST_FLOW_ITEMS,
  [OPPORTUNITY_CREATION_STEPS.REFERRAL_PAST]: REFERRAL_PAST_FLOW_ITEMS,
   ['PAST_JOB']: JOB_PAST_STEPS_ITEMS
};  