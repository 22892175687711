import React, { useState, useEffect } from 'react'
import NotificationIcon from '../../../../../assets/svg/NotificationIcon'
import { useKnockFeed } from '@knocklabs/react-notification-feed'


export const NotificationIconWithBaggeCount = ({ onMouseDown, color, notificationsOpen }) => {
    const [turnOnBell, setTurnOnBell] = useState(false);
    const { feedClient } = useKnockFeed();
    const [notificationCounter, setNotificationCounter] = useState(0)


    useEffect(() => {
        feedClient.fetch()
            .then((response) => {
                if (response !== undefined) {
                    const { data } = response;
                    const { meta } = data && data;
                    const { unseen_count } = meta && meta
                    if (unseen_count > 0) setNotificationCounter(unseen_count)
                }
            })
        // return () => feedClient.teardown()
    }, [feedClient])


    useEffect(() => {
        if (notificationCounter > 0) document.title = `(${notificationCounter}) NuOp - The Business Opportunity Exchange ™`;
        if (notificationCounter === 0) document.title = `NuOp - The Business Opportunity Exchange ™`;
    }, [notificationCounter])


    useEffect(() => {
        if (notificationsOpen) {
            feedClient.markAllAsSeen()
                .then((_) => {

                })
        }
        else {
            feedClient.fetch()
                .then((response) => {
                    if (response !== undefined) {
                        const { data } = response && response;
                        const { meta } = data && data;
                        const { unseen_count } = meta && meta
                        if (unseen_count === 0) {
                            //  setNotificationCounter(0)
                        }

                    }
                })
        }
        setNotificationCounter(0)
        setTurnOnBell(false)
    }, [notificationsOpen])

    // console.log('notificationCounter', notificationCounter)


    useEffect(() => {
        feedClient.on("items.received.realtime", ({ items }) => {
            setTurnOnBell(true)
            feedClient.fetch()
                .then((response) => {
                    if (response !== null) {
                        const { data } = response && response;
                        const { meta } = data && data;
                        const { unseen_count } = meta && meta
                        if (unseen_count > 0) {
                            setNotificationCounter(unseen_count)
                        }
                    }
                })
        });
       // return () => feedClient.listenForUpdates()
    }, [feedClient]);

    return (
        <div>
            <NotificationIcon
                id="notification-icon"
                onMouseDown={onMouseDown}
                color={color}
                enabled={notificationCounter > 0 || turnOnBell}
            />
        </div>
    )
}